/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback, useRef } from 'react';

import dayjs from 'dayjs';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import { useSelector } from 'react-redux';
import { instanceOf, shape, string } from 'prop-types';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import SummaryPerformanceView from './SummaryPerformanceView';
import SummaryInventoryView from './SummaryInventoryView/SummaryInventoryView';
import SummarySellerHealthView from './SummaryHealthView/SummarySellerHealthView';
import {
  getSalesAndAdMinDate,
  getSummaryPerformanceData,
} from '../../../../api';
import { metricsResponseKey, tooltipNames } from '../../../../constants';
import {
  WhiteCard,
  CustomDateModal,
  DropdownIndicator,
  ViewData,
  SingleSelectDropdown,
} from '../../../../common';

const initialDisabledMetrics = {
  revenueTotal: true,
  revenueOrganic: true,
  revenuePpc: true,
  revenueDsp: true,
  sponsoredAdSpend: true,
  dspSpend: true,
  unitsSold: true,
  traffic: true,
  conversion: true,
};

const getSymbolFromCurrency = require('currency-symbol-map');

am4core.useTheme(am4themes_dataviz);
const _ = require('lodash');

export default function SummaryViewContainer({ marketplaceChoices, id }) {
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const customerState = useSelector((state) => state?.customerState?.data);
  const isSuperAdmin = userInfo?.role === 'Super Admin';
  const isThirdPartyBrand = customerState?.is_third_party_potential_brand;
  const hidePerformanceSection = !isSuperAdmin && !isThirdPartyBrand;

  const mounted = useRef(false);
  const [isCustomDateApply, setIsCustomDateApply] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [responseId, setResponseId] = useState(null);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 2);
  const [customDateState, setCustomDateState] = useState([
    {
      startDate: currentDate,
      endDate: currentDate,
      key: 'selection',
    },
  ]);
  const [customDate, setCustomDate] = useState({
    startDate: currentDate,
    endDate: currentDate,
  });
  const [summaryPerformanceGraphData, setSummaryPerformanceGraphData] =
    useState([]);
  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [groupBy, setGroupBy] = useState('daily');
  const [marketplaceOptions, setMarketplaceOptions] = useState([]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState({
    value: 'week',
    label: 'Current 7 days',
    sub: 'vs Previous 7 days',
  });

  const [performanceCurrentTotal, setPerformanceCurrentTotal] = useState({});
  const [performancePreviousTotal, setPerformancePreviousTotal] = useState({});
  const [performanceDifference, setPerformanceDifference] = useState({});
  const [performanceGraphLoader, setPerformanceGraphLoader] = useState(false);
  const [isDisabledTimeFrame, setIsDisabledTimeFrame] = useState(false);
  const [isMetricsDisabled, setIsMetricsDisabled] = useState(
    initialDisabledMetrics,
  );
  const [isAppliedCustom, setIsAppliedCustom] = useState(false);
  const [selectedPeformanceMetrics, setSelectedPeformanceMetrics] = useState({
    revenueTotal: true,
  });
  const [selectedMarketplaceOption, setSelectedMarketplaceOption] = useState({
    label: 'United states',
    value: 'Amazon.com',
  });

  const [summaryMinDate, setSummaryMinDate] = useState(null);
  const [minDateLoader, setMinDateLoader] = useState(false);

  // API to get the data available date fro sales, sposnored, dsp
  const getMinDate = useCallback(
    (marketplace) => {
      setMinDateLoader(true);
      getSalesAndAdMinDate(id, marketplace).then((res) => {
        if (res?.status === 200) {
          if (res?.data[0]) {
            setSummaryMinDate(res?.data[0].sales_performance_date);
          } else {
            setSummaryMinDate(null);
          }
          setMinDateLoader(false);
        } else {
          setSummaryMinDate(null);
          setMinDateLoader(false);
        }
      });
    },
    [id],
  );

  useEffect(() => {
    if (selectedMarketplaceOption?.value)
      getMinDate(selectedMarketplaceOption.value);
  }, [getMinDate, selectedMarketplaceOption]);

  const bindPerformanceResponseData = (
    response,
    selectedMetrics,
    timeFrame,
  ) => {
    const tempData = [];

    // get a last date of the week
    const getOneDayBack = (reportDateTemp) => {
      const reportDateResultTemp = new Date(reportDateTemp);
      reportDateResultTemp.setDate(reportDateResultTemp.getDate() - 1);
      // return the date
      return reportDateResultTemp;
    };

    const nextReportDate = (indexTemp, graphDataArray, dateParam) => {
      return dayjs(
        indexTemp + 1 < graphDataArray?.length
          ? getOneDayBack(graphDataArray[indexTemp + 1]?.report_date)
          : response?.dates?.[
              dateParam === 'current' ? 'current_end_date' : 'prevous_end_date'
            ],
      ).format('MMM D YYYY');
    };

    // filterout previous data in one temporary object.
    if (
      response?.previous &&
      response?.previous !== null &&
      response?.previous.length
    ) {
      response.previous.forEach((item, index) => {
        const previousDate = dayjs(item.report_date).format('MMM D YYYY');
        tempData.push({
          revenueTotalPrevious: item.revenue,
          revenueOrganicPrevious: item.revenue_organic,
          revenuePpcPrevious: item.revenue_ppc,
          revenueDspPrevious: item.revenue_dsp,
          sponsoredAdSpendPrevious: item.sponsored_ad_spend,
          dspSpendPrevious: item.dsp_spends,
          adSpendTotalPrevious: item.ad_spend_total,
          unitsSoldPrevious: item.units_sold,
          trafficPrevious: item.traffic,
          conversionPrevious: item.conversion,
          previousDate,
          previousEndDate: nextReportDate(
            index,
            response?.previous,
            'previous',
          ),

          revenueTotalPreviousLabel:
            item.revenue !== null ? item.revenue.toFixed(2) : '0.00',
          revenueOrganicPreviousLabel:
            item.revenue_organic !== null
              ? item.revenue_organic.toFixed(2)
              : '0.00',
          revenuePpcPreviousLabel:
            item.revenue_ppc !== null ? item.revenue_ppc.toFixed(2) : '0.00',
          revenueDspPreviousLabel:
            item.revenue_dsp !== null ? item.revenue_dsp.toFixed(2) : '0.00',
          sponsoredAdSpendPreviousLabel:
            item.sponsored_ad_spend !== null
              ? item.sponsored_ad_spend.toFixed(2)
              : '0.00',
          dspSpendPreviousLabel:
            item.dsp_spends !== null ? item.dsp_spends.toFixed(2) : '0.00',
          adSpendTotalPreviousLabel:
            item.ad_spend_total !== null
              ? item.ad_spend_total.toFixed(2)
              : '0.00',
          unitsSoldPreviousLabel:
            item.units_sold !== null ? item.units_sold.toFixed(0) : '0',
          trafficPreviousLabel:
            item.traffic !== null ? item.traffic.toFixed(0) : '0',
          conversionPreviousLabel:
            item.conversion !== null ? item.conversion.toFixed(2) : '0',
        });
      });
    }

    // filterout current data in one temporary object.
    if (
      response?.current &&
      response?.current !== null &&
      response.current.length
    ) {
      response.current.forEach((item, index) => {
        const currentReportDate = dayjs(item.report_date).format('MMM D YYYY');
        let indexNumber = index;
        // add the current data at same index of prevoius in temporary object
        if (response.previous && index < response.previous.length) {
          tempData[index].date = currentReportDate;
          tempData[index].revenueTotalCurrent = item.revenue;
          tempData[index].revenueOrganicCurrent = item.revenue_organic;
          tempData[index].revenuePpcCurrent = item.revenue_ppc;
          tempData[index].revenueDspCurrent = item.revenue_dsp;
          tempData[index].sponsoredAdSpendCurrent = item.sponsored_ad_spend;
          tempData[index].dspSpendCurrent = item.dsp_spends;
          tempData[index].adSpendTotalCurrent = item.ad_spend_total;
          tempData[index].unitsSoldCurrent = item.units_sold;
          tempData[index].trafficCurrent = item.traffic;
          tempData[index].conversionCurrent = item.conversion;
          tempData[index].endDate = nextReportDate(
            index,
            response?.current,
            'current',
          );

          tempData[index].revenueTotalCurrentLabel =
            item.revenue !== null ? item.revenue.toFixed(2) : '0.00';
          tempData[index].revenueOrganicCurrentLabel =
            item.revenue_organic !== null
              ? item.revenue_organic.toFixed(2)
              : '0.00';
          tempData[index].revenuePpcCurrentLabel =
            item.revenue_ppc !== null ? item.revenue_ppc.toFixed(2) : '0.00';
          tempData[index].revenueDspCurrentLabel =
            item.revenue_dsp !== null ? item.revenue_dsp.toFixed(2) : '0.00';
          tempData[index].sponsoredAdSpendCurrentLabel =
            item.sponsored_ad_spend !== null
              ? item.sponsored_ad_spend.toFixed(2)
              : '0.00';
          tempData[index].dspSpendCurrentLabel =
            item.dsp_spends !== null ? item.dsp_spends.toFixed(2) : '0.00';
          tempData[index].adSpendTotalCurrentLabel =
            item.ad_spend_total !== null
              ? item.ad_spend_total.toFixed(2)
              : '0.00';

          tempData[index].unitsSoldCurrentLabel =
            item.units_sold !== null ? item.units_sold.toFixed(0) : '0';
          tempData[index].trafficCurrentLabel =
            item.traffic !== null ? item.traffic.toFixed(0) : '0';
          tempData[index].conversionCurrentLabel =
            item.conversion !== null ? item.conversion.toFixed(2) : '0';

          // to add the dotted line. we have to check null matrix and add the dummy number like 8
          if (index > 0) {
            indexNumber = index - 1;
          } else {
            indexNumber = index;
          }

          tempData[indexNumber].revenueTotalDashLength =
            item.revenue === null ? 8 : null;
          tempData[indexNumber].revenueOrganicDashLength =
            item.revenue_organic === null ? 8 : null;
          tempData[indexNumber].revenuePpcDashLength =
            item.revenue_ppc === null ? 8 : null;
          tempData[indexNumber].revenueDspDashLength =
            item.revenue_dsp === null ? 8 : null;
          tempData[indexNumber].sponsoredAdSpendDashLength =
            item.sponsored_ad_spend === null ? 8 : null;
          tempData[indexNumber].dspSpendDashLength =
            item.dsp_spends === null ? 8 : null;
          tempData[indexNumber].adSpendTotalDashLength =
            item.ad_spend_total === null ? 8 : null;
          tempData[indexNumber].unitsSoldDashLength =
            item.units_sold === null ? 8 : null;
          tempData[indexNumber].trafficDashLength =
            item.traffic === null ? 8 : null;
          tempData[indexNumber].conversionDashLength =
            item.conversion === null ? 8 : null;
        } else {
          // if current data count is larger than previous count then
          // generate separate key for current data and defien previous value null and previous label 0
          tempData.push({
            revenueTotalCurrent: item.revenue,
            revenueOrganicCurrent: item.revenue_organic,
            revenuePpcCurrent: item.revenue_ppc,
            revenueDspCurrent: item.revenue_dsp,
            sponsoredAdSpendCurrent: item.sponsored_ad_spend,
            dspSpendCurrent: item.dsp_spends,
            adSpendTotalCurrent: item.ad_spend_total,
            unitsSoldCurrent: item.units_sold,
            trafficCurrent: item.traffic,
            conversionCurrent: item.conversion,
            date: currentReportDate,
            endDate: nextReportDate(index, response?.current, 'current'),

            revenueTotalPrevious: null,
            revenueOrganicPrevious: null,
            revenuePpcPrevious: null,
            revenueDspPrevious: null,
            sponsoredAdSpendPrevious: null,
            dspSpendPrevious: null,
            adSpendTotalPrevious: null,
            unitsSoldPrevious: null,
            trafficPrevious: null,
            conversionPrevious: null,

            revenueTotalCurrentLabel:
              item.revenue !== null ? item.revenue.toFixed(2) : '0.00',
            revenueOrganicCurrentLabel:
              item.revenue_organic !== null
                ? item.revenue_organic.toFixed(2)
                : '0.00',
            revenuePpcCurrentLabel:
              item.revenue_ppc !== null ? item.revenue_ppc.toFixed(2) : '0.00',
            revenueDspCurrentLabel:
              item.revenue_dsp !== null ? item.revenue_dsp.toFixed(2) : '0.00',
            sponsoredAdSpendCurrentLabel:
              item.sponsored_ad_spend !== null
                ? item.sponsored_ad_spend.toFixed(2)
                : '0.00',
            dspSpendCurrentLabel:
              item.dsp_spends !== null ? item.dsp_spends.toFixed(2) : '0.00',
            adSpendTotalCurrentLabel:
              item.ad_spend_total !== null
                ? item.ad_spend_total.toFixed(2)
                : '0.00',
            unitsSoldCurrentLabel:
              item.units_sold !== null ? item.units_sold.toFixed(0) : '0',
            trafficCurrentLabel:
              item.traffic !== null ? item.traffic.toFixed(0) : '0',
            conversionCurrentLabel:
              item.conversion !== null ? item.conversion.toFixed(2) : '0',

            revenueTotalPreviousLabel: '0.0',
            revenueOrganicPreviousLabel: '0.0',
            revenuePpcPreviousLabel: '0.0',
            revenueDspPreviousLabel: '0.0',
            sponsoredAdSpendPreviousLabel: '0.0',
            dspSpendPreviousLabel: '0.0',
            adSpendTotalPreviousLabel: '0.0',
            unitsSoldPreviousLabel: '0',
            trafficPreviousLabel: '0',
            conversionPreviousLabel: '0',
          });
        }
      });
    }

    // filterout the dsp current total, previous total, and diffrence
    if (response?.current_sum && response?.current_sum !== null) {
      setPerformanceCurrentTotal(response.current_sum);
    } else {
      setPerformanceCurrentTotal({});
    }
    if (response?.previous_sum && response?.previous_sum !== null) {
      setPerformancePreviousTotal(response.previous_sum);
    } else {
      setPerformancePreviousTotal({});
    }
    if (response?.difference_data && response?.difference_data !== null) {
      setPerformanceDifference(response.difference_data);
    } else {
      setPerformanceDifference({});
    }

    setIsDisabledTimeFrame(false);

    // if we get total value of any metrics is 0, then disabled that metrics
    // we setting the disable flag for each metrics
    let localMetricDisabled = {};
    _.keys(tooltipNames).filter((option) => {
      if (
        (timeFrame === 'custom' &&
          response?.current_sum[metricsResponseKey[option]] === 0) ||
        (response?.current_sum[metricsResponseKey[option]] === 0 &&
          response?.previous_sum[metricsResponseKey[option]] === 0) ||
        (option === 'revenueDsp' &&
          customerState?.is_active_dspagreement === false)
      ) {
        localMetricDisabled = { ...localMetricDisabled, [option]: true };
      } else if (timeFrame === 'year') {
        // PDV-6572 If year_to_date option selected then disabled PPC and DSP
        localMetricDisabled = { ...localMetricDisabled, revenuePpc: true };
        localMetricDisabled = { ...localMetricDisabled, revenueDsp: true };
      } else {
        localMetricDisabled = { ...localMetricDisabled, [option]: false };
      }
      return null;
    });

    // if we don't have data for PPC then we need to disabled the PPC and organic also.

    if (
      (timeFrame === 'custom' &&
        response?.current_sum[metricsResponseKey.revenuePpc] === 0) ||
      (response?.current_sum[metricsResponseKey.revenuePpc] === 0 &&
        response?.previous_sum[metricsResponseKey.revenuePpc] === 0)
    ) {
      localMetricDisabled = {
        ...localMetricDisabled,
        revenueOrganic: true,
      };
    }
    // Below Commented this code beacuase PDV-6723
    // if we get total value of any metrics is 0 when we change the Time frame, then disabled that Tiem frame filter
    // _.keys(tooltipNames).filter((option) => {
    //   if (
    //     _.keys(selectedMetrics).includes(option) &&
    //     localMetricDisabled[option] === true
    //   ) {
    //     setIsDisabledTimeFrame(true);
    //   }
    //   return null;
    // });

    setIsMetricsDisabled(localMetricDisabled);
    return tempData;
  };

  const getPerformanceData = useCallback(
    (
      selectedDailyFact,
      selectedGroupBy,
      marketplace,
      selectedMetrics,
      startDate = null,
      endDate = null,
    ) => {
      setPerformanceGraphLoader(true);
      getSummaryPerformanceData(
        id,
        selectedDailyFact,
        selectedGroupBy,
        marketplace,
        startDate,
        endDate,
      ).then((res) => {
        if (mounted.current) {
          if (res?.status === 400) {
            setPerformanceGraphLoader(false);
          }
          if (res?.status === 200) {
            if (res?.data?.result) {
              const performanceGraphData = bindPerformanceResponseData(
                res?.data?.result,
                selectedMetrics,
                selectedDailyFact,
              );
              setSummaryPerformanceGraphData(performanceGraphData);
            } else {
              setSummaryPerformanceGraphData([]);
              setPerformanceCurrentTotal({});
              setPerformancePreviousTotal({});
              setPerformanceDifference({});
              setIsMetricsDisabled({
                revenueTotal: true,
                revenueOrganic: true,
                revenuePpc: true,
                revenueDsp: true,
                sponsoredAdSpend: true,
                dspSpend: true,
                unitsSold: true,
                traffic: true,
                conversion: true,
              });
            }

            setPerformanceGraphLoader(false);
          }
        }
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  useEffect(() => {
    mounted.current = true;
    let marketPlaceList = [];
    if (mounted.current && marketplaceChoices.length) {
      for (const option of marketplaceChoices) {
        marketPlaceList.push({
          value: option.name,
          label: option.country_currency.country,
          currency: option.country_currency.currency,
        });
      }

      if (marketPlaceList.length === 2) {
        marketPlaceList = marketPlaceList.filter(
          (item) => item.value !== 'all',
        );
      }
      setMarketplaceOptions(marketPlaceList);
      if (responseId === null) {
        const initialMarketplace =
          marketPlaceList.length > 1 ? marketPlaceList[1] : marketPlaceList[0];
        setSelectedMarketplaceOption(initialMarketplace);
        setCurrencySymbol(getSymbolFromCurrency(initialMarketplace?.currency));
        getPerformanceData(
          selectedTimeFrame.value,
          groupBy,
          initialMarketplace?.value,
          selectedPeformanceMetrics,
        );
        setResponseId('12345');
      }
    }
    return () => {
      mounted.current = false;
    };
  }, [
    selectedPeformanceMetrics,
    getPerformanceData,
    groupBy,
    marketplaceChoices,
    selectedTimeFrame,
    responseId,
  ]);

  const renderCustomDateSubLabel = (props, flag) => {
    if (flag === 'sp') {
      if (selectedTimeFrame.value === 'custom' && isCustomDateApply) {
        return `From - ${dayjs(customDateState[0].startDate).format(
          'MMM D, YYYY',
        )}  To - ${dayjs(customDateState[0].endDate).format('MMM D, YYYY')}`;
      }
    }
    return props.data.sub;
  };

  // function call only when selected option is custom
  const checkDifferenceBetweenDates = (
    startDate,
    endDate,
    flag = null,
    marketplace = selectedMarketplaceOption?.value,
  ) => {
    const temp = 'daily';
    let sd = startDate;
    let ed = endDate;
    setGroupBy(temp);
    sd = `${startDate.getDate()}-${
      startDate.getMonth() + 1
    }-${startDate.getFullYear()}`;
    ed = `${endDate.getDate()}-${
      endDate.getMonth() + 1
    }-${endDate.getFullYear()}`;
    setCustomDate({ startDate: sd, endDate: ed });
    getPerformanceData(
      flag,
      temp,
      marketplace,
      selectedPeformanceMetrics,
      sd,
      ed,
    );
  };

  const handleTimeFrameFilter = (event) => {
    const { value } = event;
    setIsAppliedCustom(false);
    setSelectedTimeFrame(event);
    if (value !== 'custom') {
      setIsCustomDateApply(false);
      setCustomDateState([
        {
          startDate: currentDate,
          endDate: currentDate,
          key: 'selection',
        },
      ]);
    }

    if (value === 'custom') {
      setShowCustomDateModal(true);
    } else {
      let groupByOption = 'daily';
      if (value === 'year') {
        groupByOption = 'weekly';
      }
      setGroupBy(groupByOption);
      getPerformanceData(
        value,
        groupByOption,
        selectedMarketplaceOption?.value,
        selectedPeformanceMetrics,
      );
    }
  };

  const handleMarketplaceOptions = (event) => {
    if (event?.value !== selectedMarketplaceOption?.value) {
      setSelectedMarketplaceOption(event);
      setCurrencySymbol(getSymbolFromCurrency(event.currency));

      if (selectedTimeFrame.value === 'custom') {
        checkDifferenceBetweenDates(
          customDateState[0].startDate,
          customDateState[0].endDate,
          'custom',
          event.value,
        );
      } else {
        getPerformanceData(
          selectedTimeFrame.value,
          groupBy,
          event.value,
          selectedPeformanceMetrics,
        );
      }
    }
  };

  const handleGroupBy = (value) => {
    if (value !== groupBy) {
      setGroupBy(value);
      if (selectedTimeFrame.value === 'custom') {
        getPerformanceData(
          selectedTimeFrame.value,
          value,
          selectedMarketplaceOption?.value,
          selectedPeformanceMetrics,
          customDate.startDate,
          customDate.endDate,
        );
      } else {
        getPerformanceData(
          selectedTimeFrame.value,
          value,
          selectedMarketplaceOption?.value,
          selectedPeformanceMetrics,
        );
      }
    }
  };

  const applyCustomDate = () => {
    setSelectedTimeFrame({
      value: 'custom',
      label: 'Custom Range',
      sub: 'Select start & end date',
    });

    checkDifferenceBetweenDates(
      customDateState[0].startDate,
      customDateState[0].endDate,
      'custom',
    );
    setShowCustomDateModal(false);
    setIsCustomDateApply(true);
  };

  // get dropdwon indicator for dropdown filter
  const getSelectDropdownIndicator = () => {
    return {
      DropdownIndicator,
    };
  };

  // generate html for single select dropdown
  const generateSingleSelectDropdownHTML = (
    filterId,
    className,
    dropdownOptions,
    selectedValue,
    onChangeHandler,
    dropdownComponents,
    isApiCall,
    isSearchable = false,
  ) => {
    return (
      <div style={{ position: 'relative' }}>
        <SingleSelectDropdown
          filterId={filterId}
          className={className}
          isApiCall={isApiCall}
          dropdownOptions={dropdownOptions}
          selectedValue={selectedValue}
          onChangeHandler={onChangeHandler}
          dropdownComponents={dropdownComponents}
          isSearchable={isSearchable}
        />
      </div>
    );
  };

  const renderMarketplaceDropDown = () => {
    return (
      <WhiteCard className="mb-3">
        <ViewData>
          <div className="row">
            <div className="col-md-4  col-sm-12 ">
              <div className="view-data-for mt-2 pt-1 ">View data for:</div>{' '}
            </div>
            <div className="col-md-4  mt-2 pt-1 pl-0"> </div>
            <div className="col-md-4 col-12 pl-0">
              {generateSingleSelectDropdownHTML(
                'BT-summaryperformancedata-countryfilter',
                '',
                marketplaceOptions,
                selectedMarketplaceOption,
                handleMarketplaceOptions,
                getSelectDropdownIndicator,
                performanceGraphLoader,
                true,
              )}
            </div>
          </div>
        </ViewData>
      </WhiteCard>
    );
  };

  const daysDiffForSummary = dayjs(currentDate).diff(
    dayjs(summaryMinDate),
    'days',
  );

  const returnMinDate = () =>
    summaryMinDate === null ? new Date('01/01/1901') : new Date(summaryMinDate);

  return (
    <div className="col-12">
      {renderMarketplaceDropDown()}
      {/* https://bbe.atlassian.net/browse/PDV-9525 - FE 2 - BP / Internal /
      Partner List Views - Stop pulling Seller and Vendor data via Amazon APIs
       Logic - If user is not Super Admin AND BP is BBE BP i.e. not Spreetail
      bp then hide performnce section. */}
      {hidePerformanceSection ? null : (
        <SummaryPerformanceView
          renderCustomDateSubLabel={renderCustomDateSubLabel}
          selectedPeformanceMetrics={selectedPeformanceMetrics}
          currencySymbol={currencySymbol}
          setSelectedPeformanceMetrics={setSelectedPeformanceMetrics}
          handleTimeFrameFilter={handleTimeFrameFilter}
          selectedTimeFrame={selectedTimeFrame}
          showCustomDateModal={showCustomDateModal}
          customDateState={customDateState}
          setShowCustomDateModal={setShowCustomDateModal}
          setCustomDateState={setCustomDateState}
          currentDate={currentDate}
          applyCustomDate={applyCustomDate}
          handleGroupBy={handleGroupBy}
          groupBy={groupBy}
          performanceCurrentTotal={performanceCurrentTotal}
          performanceDifference={performanceDifference}
          performancePreviousTotal={performancePreviousTotal}
          performanceGraphLoader={performanceGraphLoader || minDateLoader}
          summaryPerformanceGraphData={summaryPerformanceGraphData}
          isApliedCustom={isAppliedCustom}
          id={id}
          marketplace={selectedMarketplaceOption?.value}
          isMetricsDisabled={isMetricsDisabled}
          isDisabledTimeFrame={isDisabledTimeFrame}
          setIsDisabledTimeFrame={setIsDisabledTimeFrame}
          daysDiff={daysDiffForSummary}
          generateSingleSelectDropdownHTML={generateSingleSelectDropdownHTML}
        />
      )}
      <SummaryInventoryView
        id={id}
        selectedMarketplace={selectedMarketplaceOption?.value}
      />
      <SummarySellerHealthView
        selectedMarketplace={selectedMarketplaceOption?.value}
        id={id}
      />
      {/* custom date modal for performance graph */}
      <CustomDateModal
        id="BT-SummaryPerformanceView-daterange-SPdate"
        isOpen={showCustomDateModal}
        ranges={customDateState}
        onClick={() => {
          setShowCustomDateModal(false);
          setCustomDateState([
            {
              startDate: currentDate,
              endDate: currentDate,
              key: 'selection',
            },
          ]);
        }}
        onChange={(item) => {
          setCustomDateState([item.selection]);
        }}
        onApply={() => {
          setIsAppliedCustom(true);
          applyCustomDate('SPDate');
        }}
        currentDate={currentDate}
        minDate={returnMinDate()}
      />
    </div>
  );
}

SummaryViewContainer.defaultProps = {
  marketplaceChoices: {},
  id: '',
  data: {},
};

SummaryViewContainer.propTypes = {
  marketplaceChoices: instanceOf(Object),
  id: string,
  data: shape({}),
};
