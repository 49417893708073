import styled from 'styled-components';
import Theme from '../theme/Theme';

const CustomDropDown = styled.div`
  width: 100%;
  cursor: pointer;
  position:relative;
  &.un-searchable {
    .css-b8ldur-Input {
      caret-color: transparent;
    }
  }

  &.revenue-sub-type {
    float: right;
    min-height: 32px;
    clear: both;
    .css-2b097c-container {
      .css-yk16xz-control {
        min-height: 32px;
      }
      .css-uz51yo-control {
        min-height: 32px;
      }
    }
  }

  &.days-performance {
    max-width: 259px;
    float: right;
    text-align: initial;
    @media only screen and (max-width: 768px) {
      &.days-performance {
        max-width: 100%;
        float: left;
        text-align: initial;
      }
    }
  }
  max-width: 100%;
  font-size: 14px;
  color: ${Theme.gray90};
  .css-2b097c-container {
    font-size: ${Theme.extraNormal};

    .css-yk16xz-control {
      cursor: pointer;
      background-color: ${Theme.white} !important;
      border-radius: 6px;
      border: 1px solid ${Theme.gray45};
      min-height: 40px;
      color: ${Theme.gray90};
      .css-g1d714-ValueContainer {
        .css-tlfecz-indicatorContainer {
          .fa-angle-down {
            color: ${Theme.gray45} !important;
          }
        }
      }
    }

    .css-185uon1-control {
      cursor: pointer;
      border-radius: 6px;
      border: 1px solid ${Theme.gray45};
      min-height: 32px;
      color: ${Theme.gray90};
      .css-1gtu0rj-indicatorContainer {
        .fa-angle-down {
          color: ${Theme.gray90} !important;
        .react-select__placeholder {
          color: ${Theme.gray90};
        }
      }
    }
  }
  .css-rbsjjc-placeholder {
    color: ${Theme.gray90};
  }

    .css-rbsjjc-placeholder {
      color: ${Theme.gray90};
    }
  .css-ycpoe4-placeholder {
    color: ${Theme.gray90};
  }

  .css-185uon1-control {
    border-radius: 6px;
    border: 1px solid ${Theme.gray45};
    min-height: 40px;
    font-size: ${Theme.extraNormal};
    color: ${Theme.gray90};
    .css-1gtu0rj-indicatorContainer {
      .fa-angle-down {
        color: ${Theme.gray90} !important;
      }
    }
    .css-uz51yo-control {
      min-height: 40px;
      font-size: ${Theme.extraNormal};
    }
  }

  .css-1pahdxg-control {
    background-color: #fafafb;
    border: 1px solid ${Theme.gray45};
    box-shadow: none;
    color: ${Theme.gray90};
    outline: none;
    border-radius: 6px;
    min-height: 40px;
    font-size: ${Theme.extraNormal};
    .css-b8ldur-Input,
    .css-1g6gooi {
      color: ${Theme.black};
      font-size: ${Theme.extraNormal};
    }

    .css-1gtu0rj-indicatorContainer {
      .fa-angle-down {
        color: ${Theme.gray90} !important;
      }
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .react-select__value-container--is-multi {
    span {
      max-width: calc(100% - 8px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .css-26l3qy-menu {
    background: ${Theme.white};
    border-radius: 8px;
    color: ${Theme.gray85} !important;
    border: 1px solid ${Theme.gray45};
    min-height: 40px;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
    .css-4ljt47-MenuList {
      padding-bottom: 4px;
      padding-top: 0;
      border-radius: 8px;
      color: ${Theme.gray85}  !important;
    }

    .react-select__option {
      color: ${Theme.black} !important ;
      cursor: pointer;
      text-align: left; 
    }

    .react-select__menu-list { 
      .react-select__option--is-selected {
        color: ${Theme.white} !important;
        background:${Theme.orange} !important
      }
      .option-with-checkbox{
        .react-select__option--is-selected {
          color: ${Theme.black} !important;
          background:none !important
        }   
      }
    }  
  }

  /* .css-1b29jj1 {
    .css-26l3qy-menu {
      .css-4ljt47-MenuList {
        padding-bottom: 4px;
        padding-top: 0;
        border-radius: 8px;
      }
    }
  } */

  &.single-select-dropdown {
    .react-select__value-container {
      min-height:30px;
    }
  }

  &.multi-select-searchable-dropdown {
    .css-b8ldur-Input, .css-1g6gooi {
      padding:0;
      margin:0;
    }
    .react-select__input{
      position:absolute;
      top:0px;     
    }
    .react-select__menu-list{
      background:#ffffff;
    }
  }
  &.dropdown-disabled {
    .react-select__control{
      opacity: 0.5;
      cursor: not-allowed !important;
      pointer-events: none !important;      
    }
  }
`;
export default CustomDropDown;
