import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, shape, bool, func } from 'prop-types';

import {
  createNewQuery,
  updateQuery,
  deleteQuery,
} from '../../../../../../api';
import {
  ErrorMsg,
  FormField,
  InputFormField,
  PageLoader,
  SingleSelectDropdown,
} from '../../../../../../common';

function AddUpdateNewQuery({
  setShowAddNewQueryModal,
  showAddNewQueryModal,
  QueryOptions,
  getSelectDropdownIndicator,
  getQueriesData,
}) {
  const [loader, setLoader] = useState({ flag: false, btnType: '' });
  const [queryData, setQueryData] = useState({});
  const [error, setError] = useState({});
  const [selectedQuery, setSelectedQuery] = useState({
    label: 'New Query',
    value: 'new',
  });

  const QueryOptionsOnModal = [
    ...QueryOptions,
    { label: 'New Query', value: 'new' },
  ];

  const saveNewQuery = () => {
    setLoader({ flag: true, btnType: 'add' });
    if (selectedQuery?.value === 'new') {
      createNewQuery(queryData).then((res) => {
        if (res?.status === 201) {
          toast.success('Query added successfully!');
          setShowAddNewQueryModal(false);
        }
        if (res?.status === 400) {
          setError({ ...error, ...res?.data });
        }
        setLoader({ flag: false, btnType: 'add' });
      });
    } else {
      updateQuery(selectedQuery?.value, queryData).then((res) => {
        if (res?.status === 200) {
          toast.success('Query updated successfully!');
          setShowAddNewQueryModal(false);
        }
        setLoader({ flag: false, btnType: 'add' });
      });
    }
  };

  const deleteSelectedQuery = () => {
    setLoader({ flag: true, btnType: 'delete' });
    deleteQuery(selectedQuery?.value).then((res) => {
      if (res?.status === 204) {
        toast.success('Query Deleted successfully!');
        setShowAddNewQueryModal(false);
      }
      setLoader({ flag: false, btnType: 'delete' });
    });
  };

  const handleChange = (event) => {
    setError({ ...error, [event?.target?.name]: '' });
    setQueryData({
      ...queryData,
      [event?.target?.name]: event?.target?.value,
    });
  };

  return (
    <>
      <div className="add-new-query">
        <span
          role="presentation"
          onClick={() => {
            setShowAddNewQueryModal(!showAddNewQueryModal);
            getQueriesData();
            setSelectedQuery({ label: 'New Query', value: 'new' });
            setQueryData({});
          }}
        >
          <FontAwesomeIcon
            icon="fa-regular fa-plus"
            className="add-icon ml-2"
          />
          Add/Update Query
        </span>
      </div>
      {showAddNewQueryModal ? (
        <>
          <div className="new-query-div">
            <SingleSelectDropdown
              id="select-query-to-edit"
              placeholder={`Select `}
              filterId="select-query-to-edit"
              className="single-select-dropdown mb-3"
              isApiCall={false}
              dropdownOptions={QueryOptionsOnModal}
              selectedValue={selectedQuery}
              onChangeHandler={(event) => {
                setSelectedQuery(event);
                setQueryData({});
              }}
              dropdownComponents={getSelectDropdownIndicator}
              isSearchable={false}
            />
            <FormField>
              <InputFormField backgroundTransparent="transparent">
                <label
                  htmlFor={`dsp-audience-query-name ${selectedQuery?.value}`}
                  key={`dsp-audience-query-name ${selectedQuery?.value}`}
                >
                  <input
                    id={`dsp-audience-query-name ${selectedQuery?.value}`}
                    className={`form-control dsp-audience-new-query-label ${
                      error?.name ? 'form-control-error' : ''
                    }`}
                    type="text"
                    name="name"
                    defaultValue={
                      selectedQuery?.value === 'new' ? '' : selectedQuery?.label
                    }
                    placeholder="Query Name"
                    maxLength="50"
                    onChange={(event) => handleChange(event)}
                  />
                </label>
              </InputFormField>
              <ErrorMsg>{error?.name}</ErrorMsg>
              <textarea
                className={` text-area mt-3  dsp-audience-new-query-string ${
                  error?.query ? 'form-control-error' : ''
                }`}
                rows="6"
                name="query"
                defaultValue={
                  selectedQuery?.value === 'new' ? '' : selectedQuery?.query
                }
                placeholder="SQL Query"
                onChange={(event) => handleChange(event)}
              />
              <ErrorMsg>{error?.query}</ErrorMsg>
            </FormField>
            <div className="DSP-audience-new-query-footer mt-3">
              <div className="letter-count"> </div>
              <div className="action-btn">
                <span
                  className="mr-2 cursor"
                  onClick={() => saveNewQuery()}
                  role="presentation"
                  style={{ color: '#FF5933', display: 'inline-flex' }}
                >
                  {loader?.flag && loader?.btnType === 'add' ? (
                    <PageLoader color="#FF5933" type="button" />
                  ) : selectedQuery?.value === 'new' ? (
                    'Save'
                  ) : (
                    'Update'
                  )}
                </span>
                <span
                  className="mr-2 cursor"
                  onClick={() => deleteSelectedQuery()}
                  role="presentation"
                  style={{ color: '#FF5933', display: 'inline-flex' }}
                >
                  {loader?.flag && loader?.btnType === 'delete' ? (
                    <PageLoader color="#FF5933" type="button" />
                  ) : selectedQuery?.value === 'new' ? (
                    ''
                  ) : (
                    'Delete'
                  )}
                </span>

                <span
                  className="mr-2 cursor"
                  onClick={() => setShowAddNewQueryModal(false)}
                  role="presentation"
                >
                  Cancel
                </span>
              </div>
              <div className="clear-fix" />
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
}

export default AddUpdateNewQuery;

AddUpdateNewQuery.defaultProps = {
  setShowAddNewQueryModal: () => {},
  showAddNewQueryModal: false,
  QueryOptions: [],
  getSelectDropdownIndicator: () => {},
  getQueriesData: () => {},
};

AddUpdateNewQuery.propTypes = {
  setShowAddNewQueryModal: func,
  showAddNewQueryModal: bool,
  QueryOptions: arrayOf(shape({})),
  getSelectDropdownIndicator: func,
  getQueriesData: () => {},
};
