/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useRef, Fragment } from 'react';

import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { components } from 'react-select';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  shape,
  string,
} from 'prop-types';

import ASINPerformance from './ASINPerformance';
import Theme from '../../../../../../theme/Theme';
import SalesPerformanceChart from './SalePerformanceChart';
import SalesPerformanceGoalsChart from './SalesPerformanceGoalsChart';
import useFormatNumber from '../../../../../../hooks/useFormatNumber';
import { ArrowUpIcon, ArrowDownIcon } from '../../../../../../theme/images';
import {
  WhiteCard,
  PageLoader,
  NoData,
  DropdownIndicator,
  ToggleButton,
  CardMetrics,
  CheckBox,
  DropDowns,
  ToggleSwitch,
  GrayMessageBar,
  MultipleDashedLineLabel,
} from '../../../../../../common';
import {
  dateOptionsWithYear,
  metricsResponseKey,
  metricsTootlNames,
  noGraphDataMessage,
  performanceInsightMessage,
  revenuMetricsSubTypes,
  revenuSubTypeKeys,
  salesMetricsClasses,
  showMetricsTooltipForSales,
  adSpendMetricsSubTypesOptions,
  dailyWeeklyMonthlyOptions,
  SALEPERFORMANCE,
  SPONSOREDADSPEND,
  DSPSPEND,
  ADSPENDTOTAL,
  UNITSSOLD,
  TRAFFIC,
  CONVERSION,
  ADSPEND,
  TACOS,
  ADSPENDPPC,
  ADSPENDDSP,
  REVENUETOTAL,
  REVENUEPPC,
  REVENUEDSP,
  REVENUEORGANIC,
  CUSTOM,
  SELLERSMALL,
} from '../../../../../../constants';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const _ = require('lodash');

export default function SalesPerformancePanel({
  renderCustomDateSubLabel,
  selectedSalesMetrics,
  currencySymbol,
  setSelectedSalesMetrics,
  handleSalesDailyFact,
  isApiCall,
  selectedTimeFrame,
  state,
  handleGroupBy,
  groupBy,
  salesCurrentTotal,
  salesDifference,
  salesPreviousTotal,
  currency,
  salesGraphLoader,
  salesChartData,
  isAppliedCustom,
  id,
  marketplace,
  isMetricsDisabled,
  setIsDisabledTimeFrame,
  customDateError,
  daysDiff,
  currentDate,
  goalsMetricData,
  salesGoalsChartData,
  goalsToggle,
  setGoalsToggle,
  renderDataAvailableChildList,
  selectedChildBrand,
  isAsinApiCallCustom,
  selecteAdSpendSubType,
  setSelectedAdSpendSubType,
}) {
  const { Option, SingleValue } = components;

  /* ********** PDV-7917 - Expose AMC and other Sections to BPs ********** */
  /* ********** PLEASE DO NOT REMOVE BELOW CODE. ********** */
  // const userInfo = useSelector((stateData) => stateData?.userState?.userInfo);
  // const customer = useSelector((stateData) => stateData.customerState.data);

  const [isRevenueDropDownOpen, setIsRevenueDropDownOpen] = useState(false);
  const [selectedRevenueSubType, setSelectedRevenueSubType] = useState({
    revenueTotal: true,
    revenuePpc: false,
    revenueDsp: false,
  });
  const [isAdSpendDropDownOpen, setIsAdSpendDropDownOpen] = useState(false);

  const dashboardDynamicError = useSelector(
    (newState) => newState.userState?.showDynamicMsg,
  );

  const shortTileName = (tileName) => {
    let tileNameTemp = tileName;
    if (
      window.screen.width < 1500 &&
      window.screen.width > 992 &&
      tileName?.length > 8
    ) {
      tileNameTemp = `${tileName?.substring(0, 8)}...`;
    }
    return tileNameTemp;
  };

  function useOutsideAlerter(ref, type) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (type === 'revenue') {
            setIsRevenueDropDownOpen(false);
          } else {
            setIsAdSpendDropDownOpen(false);
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, type]);
  }

  const revenueRef = useRef(null);
  useOutsideAlerter(revenueRef, 'revenue');

  const adSpendRef = useRef(null);
  useOutsideAlerter(adSpendRef, ADSPEND);

  const filterOption = (props) => (
    <Option {...props}>
      <div className="pb-2">
        <span style={{ fontSize: '14px' }}>
          {props.data.label}
          {props?.isDisabled === true ? (
            <>
              <FontAwesomeIcon
                icon=" fal fa-info-circle"
                color={Theme.orange}
                fontSize="13px"
                style={{ marginLeft: '20px' }}
                data-tip="Selected metrics has no data for this period."
                data-for="adSalesTotalInfo"
              />
              <ReactTooltip
                class="initialTextTransform"
                id="adSalesTotalInfo"
                aria-haspopup="true"
                place="right"
                effect="solid"
              />
            </>
          ) : null}
        </span>

        <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
      </div>
    </Option>
  );

  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props.data.label}</span>

      <div style={{ fontSize: '10px' }}>
        {renderCustomDateSubLabel(props, 'sp')}
      </div>
    </SingleValue>
  );

  const getSelectComponents = () => {
    return {
      Option: filterOption,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  const bindValues = (value) => {
    const decimal = _.split(value, '.', 2);
    if (decimal[1] !== undefined) {
      return (
        <span style={{ fontSize: '26px' }}>
          {currencySymbol}
          {decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          <span style={{ fontSize: '16px' }}>.{decimal[1].slice(0, 2)}</span>
        </span>
      );
    }
    return (
      <span style={{ fontSize: '26px' }}>
        {currencySymbol}
        {decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    );
  };

  const setSalesMetricsClass = (name, classValue) => {
    let selectedClass = '';
    if (Object.prototype.hasOwnProperty.call(selectedSalesMetrics, name)) {
      selectedClass = `order-chart-box ${classValue} fix-height`;
    } else if (
      _.size(selectedSalesMetrics) === 4 ||
      (isMetricsDisabled[name] &&
        ![REVENUETOTAL, REVENUEORGANIC, REVENUEPPC, REVENUEDSP].includes(
          name,
        )) ||
      ([REVENUETOTAL, REVENUEORGANIC, REVENUEPPC, REVENUEDSP].includes(name) &&
        isMetricsDisabled.revenueTotal &&
        isMetricsDisabled.revenueOrganic &&
        isMetricsDisabled.revenuePpc &&
        isMetricsDisabled.revenueDsp)
    ) {
      selectedClass = 'order-chart-box fix-height disabled';
    } else if (
      goalsToggle &&
      (_.size(selectedSalesMetrics) === 2 ||
        (isMetricsDisabled[name] &&
          ![
            REVENUETOTAL,
            REVENUEORGANIC,
            REVENUEPPC,
            REVENUEDSP,
            TACOS,
          ].includes(name)) ||
        ([REVENUETOTAL, REVENUEORGANIC, REVENUEPPC, REVENUEDSP, TACOS].includes(
          name,
        ) &&
          isMetricsDisabled.revenueTotal &&
          isMetricsDisabled.revenueOrganic &&
          isMetricsDisabled.revenuePpc &&
          isMetricsDisabled.revenueDsp))
    ) {
      selectedClass = 'order-chart-box fix-height disabled';
    } else {
      selectedClass = 'order-chart-box fix-height';
    }
    return selectedClass;
  };

  const setPerformanceMetricsClass = (name, classValue) => {
    let selectedClass = '';
    if (!salesCurrentTotal?.revenue && !salesPreviousTotal?.revenue) {
      selectedClass = `order-chart-box ${classValue} fix-height disabled`;
    } else if (
      Object.prototype.hasOwnProperty.call(selectedSalesMetrics, name)
    ) {
      selectedClass = `order-chart-box ${classValue} fix-height`;
    } else if (
      _.size(selectedSalesMetrics) === 4 ||
      (isMetricsDisabled[name] &&
        ![REVENUETOTAL, REVENUEORGANIC, REVENUEPPC, REVENUEDSP].includes(
          name,
        )) ||
      ([REVENUETOTAL, REVENUEORGANIC, REVENUEPPC, REVENUEDSP].includes(name) &&
        isMetricsDisabled.revenueTotal &&
        isMetricsDisabled.revenueOrganic &&
        isMetricsDisabled.revenuePpc &&
        isMetricsDisabled.revenueDsp) ||
      (goalsToggle && _.size(selectedSalesMetrics) === 2)
    ) {
      selectedClass = 'order-chart-box fix-height disabled';
    } else {
      selectedClass = 'order-chart-box fix-height';
    }
    return selectedClass;
  };

  const setBoxToggle = (name) => {
    // Below Commented this code beacuase PDV-6723
    // const localMetrics = selectedSalesMetrics;
    if (
      Object.prototype.hasOwnProperty.call(selectedSalesMetrics, name) &&
      _.size(selectedSalesMetrics) > 1
    ) {
      setSelectedSalesMetrics(_.omit(selectedSalesMetrics, [name]));

      // enable the time frame filter when we don't have a disabled selected metrics.
      // const commanElement = _.intersectionWith(
      //   _.keys(localMetrics),
      //   _.keys(_.pickBy(isMetricsDisabled)),
      //   _.isEqual,
      // );

      // if (commanElement.length === 1 && name === commanElement[0]) {
      //   setIsDisabledTimeFrame(false);
      // }
    } else if (_.size(selectedSalesMetrics) < 4) {
      if (
        _.size(_.assign(selectedSalesMetrics, { [name]: true })) === 2 &&
        goalsToggle
      ) {
        toast.info('Maximum two goals at a time');
      }
      setSelectedSalesMetrics(
        _.omit(_.assign(selectedSalesMetrics, { [name]: true })),
      );
    }
  };

  useEffect(() => {}, [selectedSalesMetrics]);

  // display goal target html
  const displayGoalTargetHTML = (sign, target, value) => {
    return (
      <div className="metric-taget">
        <div className="target-percent-capsule">
          Target {sign === 'dollar' ? '$' : null}
          {value
            ?.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          {sign === 'percentage' ? '%' : null}
        </div>
        <div
          className={`${
            target === 'on target' || target === 'on track'
              ? 'on-target'
              : target === 'underspend' || target === 'underspending'
              ? 'underspend'
              : 'under-target'
          } mt-1`}
        >
          {target}
        </div>
      </div>
    );
  };

  const setRevenueMetricsToggle = (name) => {
    const localMetrics = { ...selectedSalesMetrics };
    const otherMetrics = [
      SPONSOREDADSPEND,
      DSPSPEND,
      ADSPENDTOTAL,
      UNITSSOLD,
      TRAFFIC,
      CONVERSION,
      ADSPEND,
      TACOS,
      ADSPENDPPC,
      ADSPENDDSP,
    ];
    // remove the metrics
    if (
      Object.prototype.hasOwnProperty.call(selectedSalesMetrics, name) &&
      _.keys(selectedSalesMetrics).some((item) => otherMetrics.includes(item))
    ) {
      // enable the time frame filter when we don't have a disabled selected metrics.
      const commanElement = _.intersectionWith(
        _.keys(localMetrics),
        _.keys(_.pickBy(isMetricsDisabled)),
        _.isEqual,
      );

      if (commanElement.length === 1) {
        setIsDisabledTimeFrame(false);
      }

      // set the default drop-down value as- revenueTotal
      delete localMetrics.revenueTotal;
      delete localMetrics.revenueDsp;
      delete localMetrics.revenuePpc;

      setSelectedSalesMetrics(localMetrics);
      setSelectedRevenueSubType({
        revenueTotal: true,
        revenuePpc: false,
        revenueDsp: false,
      });
    } else if (_.size(selectedSalesMetrics) < 4) {
      // select the metrics
      setSelectedSalesMetrics(
        _.omit(_.assign(selectedSalesMetrics, { [name]: true })),
      );
    }
  };

  const onHandleRevenueSubType = (event) => {
    // get selected dropdown keys
    let localSelectedSubype = _.keys(
      _.pickBy(selectedRevenueSubType, _.identity),
    );

    // at least one option is selected
    if (
      localSelectedSubype.includes(event.target.id) &&
      _.size(localSelectedSubype) === 1
    ) {
      return;
    }

    if (
      _.size(selectedSalesMetrics) === 3 &&
      !_.keys(selectedSalesMetrics).some((item) =>
        localSelectedSubype.includes(item),
      )
    ) {
      setSelectedRevenueSubType({
        ...selectedRevenueSubType,
        revenueTotal: false,
        [event.target.id]: !selectedRevenueSubType[event.target.id],
      });
      localSelectedSubype = _.drop(localSelectedSubype);
    } else {
      setSelectedRevenueSubType({
        ...selectedRevenueSubType,
        [event.target.id]: !selectedRevenueSubType[event.target.id],
      });
    }

    // if metrics is not selected and we directly clicked on dropdown option
    // then we we need to set the others option too
    if (!_.keys(selectedSalesMetrics).includes(event.target.id)) {
      for (const option of localSelectedSubype) {
        if (!_.keys(selectedSalesMetrics).includes(option)) {
          setBoxToggle(option);
        }
      }
    }

    setBoxToggle(event.target.id);
  };

  const renderFilterDropDown = () => {
    const localDateOptionsWithYear = dateOptionsWithYear;

    // PDV-6572 If PPC or DSP selected then disabled the year_to_date option
    localDateOptionsWithYear[3].isDisabled = _.keys(selectedSalesMetrics).some(
      (item) => [REVENUEPPC, REVENUEDSP].includes(item),
    );

    let options = [...localDateOptionsWithYear];
    if (daysDiff && options) {
      const updatedOption = options.map((obj) => {
        if (obj.value === 'week' && daysDiff < 7) {
          obj.isDisabled = true;
        }
        if (obj.value === 'month' && daysDiff < currentDate?.getDate()) {
          obj.isDisabled = true;
        }
        if (obj.value === '30days' && daysDiff < 30) {
          obj.isDisabled = true;
        }

        if (
          obj.value === 'year' &&
          daysDiff <
            dayjs(currentDate).diff(
              dayjs(`${currentDate?.getFullYear()}-01-01`),
              'days',
            )
        ) {
          obj.isDisabled = true;
        }

        return obj;
      });
      options = [...updatedOption];
    }

    return (
      <div className="col-md-6 col-sm1-12  mb-3">
        {DropDowns(
          'BT-SalesPerformanceTimeFrame',
          'days-performance',
          options,
          options[0].label,
          getSelectComponents,
          options[0],
          handleSalesDailyFact,
          isApiCall,
          null,
          selectedTimeFrame,
          null,
          isApiCall,
        )}
        <div className="clear-fix" />
      </div>
    );
  };

  const rednerSaleGroupBy = () => {
    const borderColor =
      _.keys(selectedSalesMetrics)[0] === ADSPEND
        ? 'salesAdSpend'
        : _.keys(selectedSalesMetrics)[0];
    const previousClassName =
      _.size(selectedSalesMetrics) === 1
        ? `${borderColor} block`
        : 'darkGray block';
    return (
      <div className="row mt-4 mb-3">
        {_.size(selectedSalesMetrics) <= 2 ? (
          <div className="col-md-5 col-sm-12 order-md-1 order-2 mt-2 pr-0">
            <ul className="rechart-item">
              <li key="recent">
                <div className="weeks">
                  <span
                    className={
                      _.size(selectedSalesMetrics) === 1
                        ? `${borderColor} adSales circle`
                        : 'darkGray circle'
                    }
                  />
                  <span> {goalsToggle ? 'Metrics' : 'Recent'}</span>
                </div>
              </li>
              {selectedTimeFrame?.value !== CUSTOM ? (
                <li key="previous">
                  <MultipleDashedLineLabel
                    label="Previous"
                    className={previousClassName}
                  />
                </li>
              ) : goalsToggle ? (
                <li key="goals">
                  <MultipleDashedLineLabel
                    label="Goals"
                    className={previousClassName}
                  />
                </li>
              ) : null}
            </ul>
          </div>
        ) : (
          <div className="col-md-5 col-sm-12 order-md-1 order-2 mt-2 " />
        )}
        <div className="col-md-7 col-sm-12 order-md-2 order-1 pl-0">
          <ToggleButton>
            <div className="days-container ">
              <ul className={isApiCall ? 'disabled-tab days-tab' : 'days-tab'}>
                {dailyWeeklyMonthlyOptions?.map((item) => {
                  return (
                    <li key={item?.id}>
                      <input
                        className="d-none"
                        type="radio"
                        id={item?.id}
                        name="flexRadioDefault"
                        value={item?.value}
                        checked={groupBy === item?.value}
                        onChange={() => handleGroupBy(item?.value)}
                      />
                      <label htmlFor={item?.id}>{item?.label}</label>
                    </li>
                  );
                })}
              </ul>
            </div>
          </ToggleButton>
        </div>
      </div>
    );
  };

  const returnTooltipMessge = (key = '') => {
    return `No ${key === 'Tacos' ? 'TACOS' : key} available for this period.`;
  };

  const renderRevenueMetrics = (metricsName, formatNumber) => {
    let currentTotal = 0;
    let previousTotal = 0;
    let difference = 0;
    let boxActiveClass = salesMetricsClasses[metricsName];
    let name = metricsName;
    let dropdownName = '';

    let responseKey = metricsResponseKey[name];
    // find out keys which is having true value OR seperate out selected option.
    const localSelectedSubType = _.keys(
      _.pickBy(selectedRevenueSubType, _.identity),
    );
    [name] = localSelectedSubType;
    boxActiveClass = salesMetricsClasses[localSelectedSubType[0]];

    // If the user selects multiple metrics with “Total” being one of them then display the total value
    if (selectedRevenueSubType.revenueTotal) {
      dropdownName = 'Total Sales';
      responseKey = metricsResponseKey.revenueTotal;
      currentTotal =
        salesCurrentTotal && salesCurrentTotal[responseKey]
          ? salesCurrentTotal[responseKey]
          : 0;
      previousTotal =
        salesPreviousTotal && salesPreviousTotal[responseKey]
          ? salesPreviousTotal[responseKey]
          : 0;
      difference =
        salesDifference && salesDifference[responseKey]
          ? salesDifference[responseKey]
          : 'N/A';
    } // If the user selects both PPC and DSP then sum up both values
    else if (
      !selectedRevenueSubType.revenueTotal &&
      localSelectedSubType.length > 1
    ) {
      dropdownName = 'PPC & DSP Sales';
      const dspCurrentTotal =
        salesCurrentTotal && salesCurrentTotal.revenue_dsp
          ? salesCurrentTotal.revenue_dsp
          : 0;

      const dspPreviousTotal =
        salesPreviousTotal && salesPreviousTotal.revenue_dsp
          ? salesPreviousTotal.revenue_dsp
          : 0;

      const ppcCurrentTotal =
        salesCurrentTotal && salesCurrentTotal.revenue_ppc
          ? salesCurrentTotal.revenue_ppc
          : 0;

      const ppcPreviousTotal =
        salesPreviousTotal && salesPreviousTotal.revenue_ppc
          ? salesPreviousTotal.revenue_ppc
          : 0;

      currentTotal = dspCurrentTotal + ppcCurrentTotal;
      previousTotal = dspPreviousTotal + ppcPreviousTotal;
      difference = ((currentTotal - previousTotal) * 100) / previousTotal;
    } // If the user selects a single metric just display the value for this metric
    else {
      if (localSelectedSubType[0] === REVENUEPPC) {
        dropdownName = 'PPC Sales';
      } else if (localSelectedSubType[0] === REVENUEDSP) {
        dropdownName = 'DSP Sales';
      }

      responseKey = metricsResponseKey[localSelectedSubType[0]];
      currentTotal =
        salesCurrentTotal && salesCurrentTotal[responseKey]
          ? salesCurrentTotal[responseKey]
          : 0;
      previousTotal =
        salesPreviousTotal && salesPreviousTotal[responseKey]
          ? salesPreviousTotal[responseKey]
          : 0;
      difference =
        salesDifference && salesDifference[responseKey]
          ? salesDifference[responseKey]
          : 'N/A';
    }

    const firstSelectedMetrics = Object.keys(selectedRevenueSubType).find(
      (i) => selectedRevenueSubType[i] === true,
    );

    return (
      <li key={metricsName}>
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(
            metricsTootlNames[firstSelectedMetrics],
          )}
          data-for={firstSelectedMetrics}
        >
          <div
            className={`${setPerformanceMetricsClass(name, boxActiveClass)} ${
              goalsToggle
                ? name === REVENUETOTAL ||
                  name === REVENUEPPC ||
                  name === REVENUEDSP
                  ? ''
                  : 'disabled'
                : ''
            }`}
            onClick={() => {
              setRevenueMetricsToggle(name);
            }}
            role="presentation"
          >
            {' '}
            <>
              <div className="row ">
                <div
                  id="total-revenue"
                  className="chart-name col-5 pr-0"
                  data-tip={`TOTAL REVENUE (${currency})`}
                  data-for="total-revenue"
                >{`${shortTileName(`TOTAL REVENUE (${currency})`)}`}</div>
                <ReactTooltip
                  class="initialTextTransform"
                  id="total-revenue"
                  aria-haspopup="true"
                  place="right"
                  effect="solid"
                />
                <div className="col-7 mt-n2 pl-1 text-right">
                  <BreakDown
                    id="BT-RevenuBreakDownBP"
                    ref={revenueRef}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div
                      className={`dropdown-revenue-input ${
                        isApiCall ? `disabled` : ''
                      }`}
                      role="presentation"
                      onClick={() => {
                        setIsRevenueDropDownOpen(!isRevenueDropDownOpen);
                      }}
                      id="clickbox"
                    >
                      <span className="selected-list"> {dropdownName}</span>
                      <FontAwesomeIcon
                        icon="fa-regular fa-angle-down"
                        className="dropdown-arrow-icon"
                        style={{
                          transform: isRevenueDropDownOpen
                            ? 'rotate(180deg)'
                            : '',
                        }}
                      />
                    </div>

                    {isRevenueDropDownOpen ? (
                      <div
                        className="dropdown-revenue-filter "
                        style={{ cursor: 'initial' }}
                      >
                        <ul className="options-list">
                          {revenuMetricsSubTypes.map((item) => {
                            return (
                              <li
                                key={item.id}
                                className={
                                  isMetricsDisabled[item.value] ||
                                  (_.size(selectedSalesMetrics) === 4 &&
                                    !selectedRevenueSubType[item.value]) ||
                                  (goalsToggle &&
                                    _.size(selectedSalesMetrics) === 2 &&
                                    !selectedRevenueSubType[item.value])
                                    ? 'disabled'
                                    : ''
                                }
                              >
                                {' '}
                                <CheckBox className="mt-3 mb-3">
                                  <label
                                    className="check-container customer-pannel "
                                    id={item.label}
                                  >
                                    <input
                                      type="checkbox"
                                      id={item.value}
                                      checked={
                                        selectedRevenueSubType[item.value]
                                      }
                                      onChange={(event) => {
                                        onHandleRevenueSubType(event);
                                      }}
                                      disabled={
                                        isMetricsDisabled[item.value] ||
                                        (_.size(selectedSalesMetrics) === 4 &&
                                          !selectedRevenueSubType[item.value])
                                        // (goalsToggle &&
                                        //   _.size(selectedSalesMetrics) === 2)
                                      }
                                    />
                                    <span className="checkmark" />
                                    {item.label}
                                  </label>
                                </CheckBox>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </BreakDown>
                </div>
              </div>
            </>
            <div className="number-rate">{bindValues(currentTotal)}</div>
            <div className="vs">
              {' '}
              vs {formatNumber(previousTotal, currencySymbol, '')}
            </div>
            <div className="justify-space-between">
              <div
                className={
                  difference > 0
                    ? 'perentage-value mt-3 pt-1'
                    : 'perentage-value down mt-3 pt-1'
                }
              >
                {!Number.isNaN(difference) && difference > 0 ? (
                  <img
                    className="green-arrow"
                    src={ArrowUpIcon}
                    alt="arrow-up"
                  />
                ) : !Number.isNaN(difference) && difference < 0 ? (
                  <img
                    className="red-arrow"
                    src={ArrowDownIcon}
                    alt="arrow-down"
                  />
                ) : null}

                {difference !== 'N/A'
                  ? `${difference.toFixed(2).toString().replace('-', '')}%`
                  : 'N/A'}
              </div>
              {goalsMetricData?.revenue
                ? displayGoalTargetHTML(
                    goalsMetricData?.revenue?.sign,
                    goalsMetricData?.revenue?.target,
                    goalsMetricData?.revenue?.value,
                  )
                : null}
            </div>
          </div>
        </CardMetrics>
      </li>
    );
  };

  // Ad spend metrics start

  const setAdSpendMetricsClass = (name, classValue) => {
    let selectedClass = '';
    if (
      !salesCurrentTotal?.ad_spend_total &&
      !salesPreviousTotal?.ad_spend_total
    ) {
      selectedClass = `order-chart-box ${classValue} fix-height disabled`;
    } else if (
      Object.prototype.hasOwnProperty.call(selectedSalesMetrics, name)
    ) {
      selectedClass = `order-chart-box ${classValue} fix-height`;
    } else if (
      _.size(selectedSalesMetrics) === 4 ||
      (isMetricsDisabled[name] &&
        ![ADSPENDTOTAL, ADSPENDPPC, ADSPENDDSP].includes(name)) ||
      ([ADSPENDTOTAL, ADSPENDPPC, ADSPENDDSP].includes(name) &&
        isMetricsDisabled.adSpendTotal &&
        isMetricsDisabled.adSpendPpc &&
        isMetricsDisabled.adSpendDsp) ||
      (goalsToggle && _.size(selectedSalesMetrics) === 2)
    ) {
      selectedClass = 'order-chart-box fix-height disabled';
    } else {
      selectedClass = 'order-chart-box fix-height';
    }
    return selectedClass;
  };

  const setAdSpendMetricsToggle = (name) => {
    const localMetrics = { ...selectedSalesMetrics };
    const otherMetrics = [
      SPONSOREDADSPEND,
      DSPSPEND,
      // 'adSpendTotal',
      UNITSSOLD,
      TRAFFIC,
      CONVERSION,
      ADSPEND,
      TACOS,
      REVENUETOTAL,
      REVENUEPPC,
      REVENUEDSP,
    ];
    // remove the metrics
    if (
      Object.prototype.hasOwnProperty.call(selectedSalesMetrics, name) &&
      _.keys(selectedSalesMetrics).some((item) => otherMetrics.includes(item))
    ) {
      // enable the time frame filter when we don't have a disabled selected metrics.
      const commanElement = _.intersectionWith(
        _.keys(localMetrics),
        _.keys(_.pickBy(isMetricsDisabled)),
        _.isEqual,
      );

      if (commanElement.length === 1) {
        setIsDisabledTimeFrame(false);
      }

      // set the default drop-down value as- adSpendTotal
      delete localMetrics.adSpendTotal;
      delete localMetrics.adSpendPpc;
      delete localMetrics.adSpendDsp;

      setSelectedSalesMetrics(localMetrics);

      setSelectedAdSpendSubType({
        adSpendTotal: true,
        adSpendPpc: false,
        adSpendDsp: false,
      });
    } else if (_.size(selectedSalesMetrics) < 4) {
      // select the metrics
      setSelectedSalesMetrics(
        _.omit(_.assign(selectedSalesMetrics, { [name]: true })),
      );
    }
  };

  const onHandleAdSpendSubType = (event) => {
    // get selected dropdown keys
    let localSelectedSubype = _.keys(
      _.pickBy(selecteAdSpendSubType, _.identity),
    );

    // at least one option is selected
    if (
      localSelectedSubype.includes(event.target.id) &&
      _.size(localSelectedSubype) === 1
    ) {
      return;
    }

    if (
      _.size(selectedSalesMetrics) === 3 &&
      !_.keys(selectedSalesMetrics).some((item) =>
        localSelectedSubype.includes(item),
      )
    ) {
      setSelectedAdSpendSubType({
        ...selecteAdSpendSubType,
        adSpendTotal: false,
        [event.target.id]: !selecteAdSpendSubType[event.target.id],
      });
      localSelectedSubype = _.drop(localSelectedSubype);
    } else {
      setSelectedAdSpendSubType({
        ...selecteAdSpendSubType,
        [event.target.id]: !selecteAdSpendSubType[event.target.id],
      });
    }

    // if metrics is not selected and we directly clicked on dropdown option
    // then we we need to set the others option too
    if (!_.keys(selectedSalesMetrics).includes(event.target.id)) {
      for (const option of localSelectedSubype) {
        if (!_.keys(selectedSalesMetrics).includes(option)) {
          setBoxToggle(option);
        }
      }
    }

    setBoxToggle(event.target.id);
  };

  const renderAdSpendMetrics = (metricsName, formatNumber) => {
    let currentTotal = 0;
    let previousTotal = 0;
    let difference = 0;
    let boxActiveClass = selecteAdSpendSubType[metricsName];
    let name = metricsName;

    let dropdownName = '';

    let responseKey = metricsResponseKey[name];
    // find out keys which is having true value OR seperate out selected option.
    const localSelectedSubType = _.keys(
      _.pickBy(selecteAdSpendSubType, _.identity),
    );
    [name] = localSelectedSubType;
    boxActiveClass = salesMetricsClasses[localSelectedSubType[0]];

    // If the user selects multiple metrics with “Total” being one of them then display the total value
    if (selecteAdSpendSubType.adSpendTotal) {
      dropdownName = 'Total Ad Spend';
      responseKey = metricsResponseKey.adSpendTotal;
      currentTotal =
        salesCurrentTotal && salesCurrentTotal[responseKey]
          ? salesCurrentTotal[responseKey]
          : 0;
      previousTotal =
        salesPreviousTotal && salesPreviousTotal[responseKey]
          ? salesPreviousTotal[responseKey]
          : 0;
      difference =
        salesDifference && salesDifference[responseKey]
          ? salesDifference[responseKey]
          : 'N/A';
    } // If the user selects both PPC and DSP then sum up both values
    else if (
      !selecteAdSpendSubType.adSpendTotal &&
      localSelectedSubType.length > 1
    ) {
      dropdownName = 'PPC & DSP Sales';
      const dspCurrentTotal =
        salesCurrentTotal && salesCurrentTotal?.dsp_spends
          ? salesCurrentTotal?.dsp_spends
          : 0;

      const dspPreviousTotal =
        salesPreviousTotal && salesPreviousTotal?.dsp_spends
          ? salesPreviousTotal?.dsp_spends
          : 0;

      const ppcCurrentTotal =
        salesCurrentTotal && salesCurrentTotal?.sponsored_ad_spend
          ? salesCurrentTotal?.sponsored_ad_spend
          : 0;

      const ppcPreviousTotal =
        salesPreviousTotal && salesPreviousTotal?.sponsored_ad_spend
          ? salesPreviousTotal?.sponsored_ad_spend
          : 0;

      currentTotal = dspCurrentTotal + ppcCurrentTotal;
      previousTotal = dspPreviousTotal + ppcPreviousTotal;
      difference = ((currentTotal - previousTotal) * 100) / previousTotal;
    } // If the user selects a single metric just display the value for this metric
    else {
      if (localSelectedSubType[0] === ADSPENDPPC) {
        dropdownName = 'PPC Sales';
      } else if (localSelectedSubType[0] === ADSPENDDSP) {
        dropdownName = 'DSP Sales';
      }
      responseKey = metricsResponseKey[localSelectedSubType[0]];
      currentTotal =
        salesCurrentTotal && salesCurrentTotal[responseKey]
          ? salesCurrentTotal[responseKey]
          : 0;
      previousTotal =
        salesPreviousTotal && salesPreviousTotal[responseKey]
          ? salesPreviousTotal[responseKey]
          : 0;
      difference =
        salesDifference && salesDifference[responseKey]
          ? salesDifference[responseKey]
          : 'N/A';
    }

    const firstSelectedMetrics = Object.keys(selecteAdSpendSubType).find(
      (i) => selecteAdSpendSubType[i] === true,
    );

    const isAdSpendTotalSelected =
      selecteAdSpendSubType?.adSpendTotal ||
      (selecteAdSpendSubType?.adSpendPpc && selecteAdSpendSubType?.adSpendDsp);

    return (
      <li key={metricsName} style={{ verticalAlign: 'top' }}>
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(
            metricsTootlNames[firstSelectedMetrics],
          )}
          data-for={firstSelectedMetrics}
        >
          <div
            className={`${setAdSpendMetricsClass(name, boxActiveClass)} ${
              goalsToggle
                ? name === ADSPENDTOTAL ||
                  name === ADSPENDPPC ||
                  name === ADSPENDDSP
                  ? ''
                  : 'disabled'
                : ''
            }`}
            onClick={() => {
              setAdSpendMetricsToggle(name);
            }}
            role="presentation"
          >
            {' '}
            <>
              <div className="row ">
                <div
                  id="total-ad-spend"
                  data-tip="TOTAL AD SPEND"
                  data-for="total-ad-spend"
                  className="chart-name col-5 pr-0"
                >
                  {shortTileName('TOTAL AD SPEND')}
                </div>
                <ReactTooltip
                  class="initialTextTransform"
                  id="total-ad-spend"
                  aria-haspopup="true"
                  place="right"
                  effect="solid"
                />
                <div className="col-7 mt-n2 pl-1 text-right">
                  <BreakDown
                    id="BT-RevenuBreakDownBP"
                    ref={adSpendRef}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div
                      className={`dropdown-revenue-input ${
                        isApiCall ? `disabled` : ''
                      }`}
                      role="presentation"
                      onClick={() => {
                        setIsAdSpendDropDownOpen(!isAdSpendDropDownOpen);
                      }}
                      id="clickbox"
                    >
                      <span className="selected-list">{dropdownName}</span>
                      <FontAwesomeIcon
                        icon="fa-regular fa-angle-down"
                        className="dropdown-arrow-icon"
                        style={{
                          transform: isAdSpendDropDownOpen
                            ? 'rotate(180deg)'
                            : '',
                        }}
                      />
                    </div>

                    {isAdSpendDropDownOpen ? (
                      <div
                        className="dropdown-revenue-filter "
                        style={{ cursor: 'initial' }}
                      >
                        <ul className="options-list">
                          {adSpendMetricsSubTypesOptions.map((item) => {
                            return (
                              <li
                                key={item.id}
                                className={
                                  isMetricsDisabled[item.value] ||
                                  (_.size(selectedSalesMetrics) === 4 &&
                                    !selecteAdSpendSubType[item.value]) ||
                                  (goalsToggle &&
                                    _.size(selectedSalesMetrics) === 2 &&
                                    !selecteAdSpendSubType[item.value])
                                    ? 'disabled'
                                    : ''
                                }
                              >
                                {' '}
                                <CheckBox className="mt-3 mb-3">
                                  <label
                                    className="check-container customer-pannel "
                                    id={item.label}
                                  >
                                    <input
                                      type="checkbox"
                                      id={item.value}
                                      checked={
                                        selecteAdSpendSubType[item.value]
                                      }
                                      onChange={(event) => {
                                        onHandleAdSpendSubType(event);
                                      }}
                                      disabled={
                                        isMetricsDisabled[item.value] ||
                                        (_.size(selectedSalesMetrics) === 4 &&
                                          !selecteAdSpendSubType[item.value])
                                        // (goalsToggle &&
                                        //   _.size(selectedSalesMetrics) === 2)
                                      }
                                    />
                                    <span className="checkmark" />
                                    {item.label}
                                  </label>
                                </CheckBox>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </BreakDown>
                </div>
              </div>
            </>
            <div className="number-rate">{bindValues(currentTotal)}</div>
            <div className="vs">
              {' '}
              vs {formatNumber(previousTotal, currencySymbol, '')}
            </div>
            <div className="justify-space-between">
              <div
                className={
                  difference > 0
                    ? 'perentage-value mt-3 pt-1'
                    : 'perentage-value down mt-3 pt-1'
                }
              >
                {!Number.isNaN(difference) && difference > 0 ? (
                  <img
                    className="green-arrow"
                    src={ArrowUpIcon}
                    alt="arrow-up"
                  />
                ) : !Number.isNaN(difference) && difference < 0 ? (
                  <img
                    className="red-arrow"
                    src={ArrowDownIcon}
                    alt="arrow-down"
                  />
                ) : null}

                {difference !== 'N/A'
                  ? `${difference.toFixed(2).toString().replace('-', '')}%`
                  : 'N/A'}
              </div>
              {isAdSpendTotalSelected && goalsMetricData?.ad_spend_total
                ? displayGoalTargetHTML(
                    goalsMetricData?.ad_spend_total?.sign,
                    goalsMetricData?.ad_spend_total?.target,
                    goalsMetricData?.ad_spend_total?.value,
                  )
                : null}
              {!isAdSpendTotalSelected &&
              selecteAdSpendSubType?.adSpendPpc &&
              goalsMetricData?.sponsored_ad_spend
                ? displayGoalTargetHTML(
                    goalsMetricData?.sponsored_ad_spend?.sign,
                    goalsMetricData?.sponsored_ad_spend?.target,
                    goalsMetricData?.sponsored_ad_spend?.value,
                  )
                : null}
              {!isAdSpendTotalSelected &&
              selecteAdSpendSubType?.adSpendDsp &&
              goalsMetricData?.dsp_spends
                ? displayGoalTargetHTML(
                    goalsMetricData?.dsp_spends?.sign,
                    goalsMetricData?.dsp_spends?.target,
                    goalsMetricData?.dsp_spends?.value,
                  )
                : null}
            </div>
          </div>
        </CardMetrics>
      </li>
    );
  };

  // Ad spend metrics Ends

  const showGoalsToggle = () => {
    return (
      <ToggleSwitch
        className="custom-switch mt-2"
        // key={`sellerDetails${graphIndex}${option.id}`}
      >
        <label
          htmlFor="goalsToggle"
          className={`label-name ${
            selectedTimeFrame.value === CUSTOM
              ? Object.keys(goalsMetricData)?.length
                ? ''
                : 'disabled'
              : 'disabled'
          }`}
          // key={`${idName}${graphIndex}`}
        >
          Goals
          <input
            type="checkbox"
            className="label-name"
            id="goalsToggle"
            onClick={() => {
              setGoalsToggle(!goalsToggle);
              setSelectedSalesMetrics({
                revenueTotal: true,
              });
              setSelectedRevenueSubType({
                revenueTotal: true,
                revenuePpc: false,
                revenueDsp: false,
              });
            }}
            onChange={() => {}}
            checked={goalsToggle}
          />
          <span className="slider round" />
        </label>
      </ToggleSwitch>
    );
  };

  const renderSalesBox = (
    metricsLabel,
    matricsKey,
    currentValue,
    previousValue,
    differenceValue,
    valueSymbol,
    boxActiveClass,
    formatNumber,
  ) => {
    const showGoalsTarget =
      matricsKey === TACOS ||
      matricsKey === TRAFFIC ||
      matricsKey === CONVERSION;

    return (
      <li key={matricsKey}>
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(metricsLabel)}
          data-for={metricsResponseKey[matricsKey]}
        >
          <div
            className={`${setSalesMetricsClass(matricsKey, boxActiveClass)} ${
              goalsToggle ? (showGoalsTarget ? '' : 'disabled') : ''
            }`}
            onClick={() => setBoxToggle(matricsKey)}
            role="presentation"
          >
            <div className="chart-name">{metricsLabel.toUpperCase()}</div>
            <div className="number-rate">
              {formatNumber(currentValue, '', valueSymbol)}
            </div>
            <div className="vs">
              vs {formatNumber(previousValue, '', valueSymbol)}
            </div>
            <div className="justify-space-between">
              <div
                className={
                  differenceValue > 0
                    ? 'perentage-value mt-3 pt-1'
                    : 'perentage-value down mt-3 pt-1'
                }
              >
                {!Number.isNaN(differenceValue) && differenceValue > 0 ? (
                  <img
                    className="green-arrow"
                    src={ArrowUpIcon}
                    alt="arrow-up"
                  />
                ) : !Number.isNaN(differenceValue) && differenceValue < 0 ? (
                  <img
                    className="red-arrow"
                    src={ArrowDownIcon}
                    alt="arrow-down"
                  />
                ) : (
                  ''
                )}

                {differenceValue || differenceValue === 0
                  ? `${differenceValue.toString().replace('-', '')}%`
                  : 'N/A'}
              </div>

              <div>
                {showGoalsTarget && goalsMetricData?.[matricsKey]
                  ? displayGoalTargetHTML(
                      goalsMetricData?.[matricsKey]?.sign,
                      goalsMetricData?.[matricsKey]?.target,
                      goalsMetricData?.[matricsKey]?.value,
                    )
                  : null}
              </div>
            </div>
          </div>
        </CardMetrics>
      </li>
    );
  };

  const renderErrorMessage = () => {
    let { fromDate } = customDateError;
    let { toDate } = customDateError;

    fromDate = dayjs(fromDate).format('MM/DD/YYYY');
    toDate = dayjs(toDate).subtract(1, 'day').format('MM/DD/YYYY');

    return (
      <div className="row">
        <div className="col-12 mt-2 mb-3">
          <div className="normal-text-black first-white-card">
            <GrayMessageBar
              message={`There is no sales data available from ${fromDate}
      to ${toDate}.`}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSalePerformancePanel = () => {
    return (
      <WhiteCard>
        {/* ********** PDV-7917 - Expose AMC and other Sections to BPs ********** */}
        {/* ********** PLEASE DO NOT REMOVE BELOW CODE. ********** */}
        {/* {(userInfo?.role?.includes('Customer') &&
          customer?.display_sales_performance) ||
        !userInfo?.role?.includes('Customer') ? (
          <> */}
        <div className="row">
          <div className="col-md-6 col-sm1-12">
            {' '}
            <p className="black-heading-title mt-2 pt-1 mb-4">
              {' '}
              Sales Performance
            </p>
          </div>
          {renderFilterDropDown()}
          <div className="col-12">
            {dashboardDynamicError?.sales_performance_seller?.placeholder ? (
              <GrayMessageBar
                message={
                  dashboardDynamicError?.sales_performance_seller?.placeholder
                }
              />
            ) : null}
          </div>
        </div>

        {renderDataAvailableChildList('sales')}
        {selectedTimeFrame.value !== CUSTOM ? (
          <GrayMessageBar message={performanceInsightMessage.sales} />
        ) : null}
        <FiveCardMetrics>
          <ul className="five-card-metrics metric-card-scroll ">
            {renderRevenueMetrics('revenue', useFormatNumber)}
            {renderSalesBox(
              'Tacos',
              TACOS,
              salesCurrentTotal?.tacos,
              salesPreviousTotal?.tacos,
              salesDifference?.tacos,
              '%',
              'sales-tacos-active',
              useFormatNumber,
            )}
            {renderSalesBox(
              'Units sold',
              UNITSSOLD,
              salesCurrentTotal?.units_sold,
              salesPreviousTotal?.units_sold,
              salesDifference?.units_sold,
              '',
              'ad-spend-active',
              useFormatNumber,
            )}
            {renderSalesBox(
              'Traffic',
              TRAFFIC,
              salesCurrentTotal?.traffic,
              salesPreviousTotal?.traffic,
              salesDifference?.traffic,
              '',
              'ad-conversion-active',
              useFormatNumber,
            )}
            {renderSalesBox(
              'Conversion',
              CONVERSION,
              salesCurrentTotal?.conversion,
              salesPreviousTotal?.conversion,
              salesDifference?.conversion,
              '%',
              'impression-active',
              useFormatNumber,
            )}

            {renderAdSpendMetrics(ADSPEND, useFormatNumber)}
          </ul>
        </FiveCardMetrics>
        {showGoalsToggle()}

        {selectedTimeFrame.value === CUSTOM &&
        customDateError.accountType === SELLERSMALL &&
        customDateError.selectedTab === SALEPERFORMANCE
          ? renderErrorMessage()
          : null}
        {rednerSaleGroupBy()}
        <div className="clear-fix" />

        {salesGraphLoader ? (
          <PageLoader
            component="performance-graph"
            color={Theme.orange}
            type="detail"
            width={40}
            height={40}
          />
        ) : salesChartData.length !== 0 && !goalsToggle ? (
          <SalesPerformanceChart
            chartId="chartdiv"
            chartData={salesChartData}
            currencySymbol={currencySymbol}
            selectedMetrics={selectedSalesMetrics}
            selectedDF={selectedTimeFrame.value}
            groupBy={groupBy}
          />
        ) : salesGoalsChartData?.length && goalsToggle ? (
          <SalesPerformanceGoalsChart
            chartId="goalsSalesChart"
            chartData={salesGoalsChartData}
            currencySymbol={currencySymbol}
            selectedMetrics={selectedSalesMetrics}
            selectedDF={selectedTimeFrame.value}
            groupBy={groupBy}
          />
        ) : (
          <NoData>{noGraphDataMessage}</NoData>
        )}
        {/* </>
        ) : null} */}

        <ASINPerformance
          selectedPeriod={selectedTimeFrame}
          customDate={state}
          isSelectCustom={isAppliedCustom}
          currencySymbol={currencySymbol}
          customerId={id}
          marketplace={marketplace}
          selectedChildBrand={selectedChildBrand}
          selectedAccountType="seller"
          isAsinApiCallCustom={isAsinApiCallCustom}
        />
      </WhiteCard>
    );
  };

  return (
    <>
      {showMetricsTooltipForSales.map((key) => {
        let keyName = key;
        let dataForMetric = key;
        const revenueKeys = [
          'revenue',
          'revenue_ppc',
          'revenue_dsp',
          'revenue_organic',
        ];
        const adSpendKeys = [
          'ad_spend_total',
          'sponsored_ad_spend',
          'dsp_spends',
        ];

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= 2; i++) {
          const exixtingKeys = i === 1 ? revenueKeys : adSpendKeys;
          const alreadySelectedSubType =
            i === 1 ? selectedRevenueSubType : selecteAdSpendSubType;

          if (exixtingKeys?.includes(key)) {
            dataForMetric = Object.keys(alreadySelectedSubType).find(
              (item) => alreadySelectedSubType[item] === true,
            );
            keyName = revenuSubTypeKeys[dataForMetric];
          }
        }
        return (
          <Fragment key={`${key}sale-performance`}>
            {!salesCurrentTotal[keyName] &&
            !salesPreviousTotal[keyName] &&
            !isApiCall ? (
              <ReactTooltip
                id={dataForMetric}
                class="initialTextTransform"
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />
            ) : null}
          </Fragment>
        );
      })}

      {renderSalePerformancePanel()}
    </>
  );
}

SalesPerformancePanel.defaultProps = {
  isApiCall: false,
  salesGraphLoader: false,
  isDisabledTimeFrame: false,
  isDisabled: false,
  isAsinApiCallCustom: 0,
  groupBy: '',
  currencySymbol: '',
  marketplace: '',
  daysDiff: 0,
  currentDate: null,
  selectedValue: {},
  currency: '',
  selectedRevenueType: '',
  salesMinDate: '',
  selectedTimeFrame: {},
  selectedSalesMetrics: {},
  salesDifference: {},
  isMetricsDisabled: {},
  salesCurrentTotal: {},
  salesPreviousTotal: {},
  data: {},
  salesChartData: {},
  goalsMetricData: {},
  customDateError: {},
  state: [],
  salesGoalsChartData: [],
  selectedChildBrand: {},
  setSelectedSalesMetrics: () => {},
  handleSalesDailyFact: () => {},
  renderCustomDateSubLabel: () => {},
  handleGroupBy: () => {},
  setSelectedRevenueType: () => {},
  setIsDisabledTimeFrame: () => {},
  setSelectedRevenueDropDownOption: () => {},
  goalsToggle: false,
  setGoalsToggle: () => {},
  renderDataAvailableChildList: () => {},
  selecteAdSpendSubType: {},
  setSelectedAdSpendSubType: () => {},
};

SalesPerformancePanel.propTypes = {
  isApiCall: bool,
  salesGraphLoader: bool,
  isDisabledTimeFrame: bool,
  isDisabled: bool,
  isAppliedCustom: bool.isRequired,
  isAsinApiCallCustom: number,
  currencySymbol: string,
  groupBy: string,
  currency: string,
  selectedRevenueType: string,
  salesMinDate: string,
  id: string.isRequired,
  selectedTimeFrame: shape({}),
  selectedSalesMetrics: shape({}),
  data: shape({}),
  selectedValue: shape({}),
  goalsMetricData: shape({}),
  salesChartData: arrayOf(shape({})),
  state: arrayOf(shape({})),
  salesDifference: shape({}),
  salesCurrentTotal: shape({}),
  salesPreviousTotal: shape({}),
  isMetricsDisabled: shape({}),
  customDateError: shape({}),
  selectedChildBrand: shape({}),
  marketplace: string,
  daysDiff: number,
  currentDate: instanceOf(Date),
  renderCustomDateSubLabel: func,
  setSelectedSalesMetrics: func,
  handleSalesDailyFact: func,
  handleGroupBy: func,
  setSelectedRevenueType: func,
  setIsDisabledTimeFrame: func,
  setSelectedRevenueDropDownOption: func,
  salesGoalsChartData: arrayOf(shape({})),
  goalsToggle: bool,
  setGoalsToggle: func,
  renderDataAvailableChildList: func,
  selecteAdSpendSubType: shape({}),
  setSelectedAdSpendSubType: func,
};

const BreakDown = styled.div`
  .dropdown-revenue-input {
    border-radius: 6px;
    position: relative;
    border: 1px solid ${Theme.gray45};
    width: 75%;
    min-width: 93px;
    text-align: left;
    font-size: ${Theme.extraNormal};
    border-radius: 6px;
    min-height: 32px;
    color: ${Theme.gray85};
    padding: 7px;
    cursor: pointer;

    span.selected-list {
      position: fixed;
      color: ${Theme.black};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 65%;
      margin-left: 4px;
      position: absolute;
    }

    .dropdown-arrow-icon {
      position: absolute;
      right: 9px;
      top: 9px;
    }
    @media only screen and (max-width: 776px) {
      min-width: 70px;
    }
  }
  .dropdown-revenue-filter {
    background-color: ${Theme.white};
    border-radius: 8px;
    box-shadow: 0 5px 15px 0 rgba(68, 68, 79, 0.4);
    padding: 0 14px;
    position: absolute;
    z-index: 99999;
    top: 35px;
    color: ${Theme.gray85};
    text-align: left;
    top: 37px;
    right: 15px;
    width: 100%;

    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }

    .options-list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: block;
        padding: 0;
      }
    }
  }
`;

const FiveCardMetrics = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${Theme.gray2};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Theme.gray25};
  }

  .five-card-metrics {
    padding: 0;
    margin: 0 -5px 15px -5px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    &.metric-card-scroll {
      display: flex;
      overflow: auto;
      flex-wrap: nowrap;
    }

    li {
      width: 100%;
      display: inline-block;
      flex: 0 0 25%;
      max-width: 25%;
      position: relative;
      padding-right: 5px;
      padding-left: 5px;
      margin-bottom: 15px;

      .justify-space-between {
        align-items: flex-end;
      }
    }
    @media only screen and (max-width: 991px) {
      li {
        max-width: 33.33% !important;
        flex: 0 0 33.33% !important;
      }
    }

    @media only screen and (max-width: 776px) {
      li {
        max-width: 50% !important;
        flex: 0 0 50% !important;
      }
    }

    @media only screen and (min-width: 779px) {
      // Add Horizontal scroll
      display: inline-block;
      // overflow: auto;
      // overflow-y: hidden;
      white-space: nowrap;
      width: 100%;

      /* width */
      ::-webkit-scrollbar {
        width: none !important;
        height: 8px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #bfc3d2;
        border-radius: 10px;
      }
    }
  }
`;
