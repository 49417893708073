import queryString from 'query-string';
import axiosInstance from '../axios';
import { API_COS, API_CUSTOMER, API_COS_ASIN } from '../constants';

// get BP contract start and end date and get flag to show edit btn ot not for cos.
export async function getBPsCOSAccess(customerID) {
  const params = {
    customer: customerID,
  };
  const result = await axiosInstance
    .get(`${API_COS}dates/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get COS
export async function getCOS(customerID, startDate, endDate) {
  const params = {
    start_date: startDate,
    end_date: endDate,
  };
  const result = await axiosInstance
    .get(`${API_CUSTOMER + customerID + API_COS}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateCOS(customerID, startDate, endDate, data) {
  const startDateStr = queryString.stringify({ start_date: startDate });
  const endDateStr = queryString.stringify({ end_date: endDate });

  const result = await axiosInstance
    .patch(
      `${API_CUSTOMER + customerID + API_COS}?${
        startDateStr ? `&${startDateStr}` : ''
      }${endDateStr ? `&${endDateStr}` : ''}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// download report
export async function downloadCosReport(customerID, startDate, endDate) {
  const params = {
    customer: customerID,
    start_date: startDate,
    end_date: endDate,
  };
  const result = await axiosInstance
    .get(`${API_COS}download/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getTotalAsinsCount(customerID, startDate, endDate) {
  const params = {
    customer: customerID,
    start_date: startDate,
    end_date: endDate,
  };
  const result = await axiosInstance
    .get(`${API_COS}total-asin/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function deleteCOS(id) {
  const result = await axiosInstance
    .delete(`${API_COS_ASIN + id}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
