import React from 'react';

import Modal from 'react-modal';
import { bool, func } from 'prop-types';

import { CloseIcon } from '../../../../../theme/images';
import { ModalBox, Button } from '../../../../../common';

export default function DeleteConfirmationModal({
  showModal,
  setShowModal,
  handleDeleteSelection,
}) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal
      isOpen={showModal}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Profile modal"
    >
      <img
        src={CloseIcon}
        alt="close"
        className="float-right cross-icon cursor"
        onClick={() => {
          setShowModal({ flag: false, selectedCosId: null, index: null });
        }}
        role="presentation"
      />
      <ModalBox>
        <div className="modal-body">
          <div className="alert-msg  ">
            <div className="sure-to-proceed">
              Are you sure you want to Delete?
            </div>
          </div>
          <div className="text-center ">
            <Button
              onClick={() => handleDeleteSelection(true)}
              type="button"
              className="btn-primary on-boarding  mr-2 pb-2 mb-1"
            >
              Delete
            </Button>
            <Button
              onClick={() => handleDeleteSelection(false)}
              type="button"
              className=" btn-transparent w-50 on-boarding "
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}

DeleteConfirmationModal.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  handleDeleteSelection: () => {},
};

DeleteConfirmationModal.propTypes = {
  showModal: bool,
  setShowModal: func,
  handleDeleteSelection: func,
};
