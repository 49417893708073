import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import queryString from 'query-string';
import { useHistory, useParams } from 'react-router-dom';

import UnauthorizedHeader from '../../common/UnauthorizedHeader';
import Theme from '../../theme/Theme';
import { updateBillingOffBoardingApproveReject } from '../../api';
import { PageLoader } from '../../common';
import { AccountSetupIcon, PageNotFoundImg } from '../../theme/images';
import { PageNotFounds, ThanksPage } from './Styles';
import { billingOffboardErrorMsg } from '../../constants';

export default function OffBoardingApproveReject() {
  const mounted = useRef(false);
  const history = useHistory();
  const { id } = useParams();
  const params = queryString.parse(history.location.search);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState();

  const userInfo = useSelector((state) => state.userState.userInfo);

  const firstRequestApprovalUser = userInfo?.is_primary_request_approvers;

  const onUpdateBillingOffboardingApproveReject = useCallback(() => {
    setLoader(true);
    setApiError('');
    updateBillingOffBoardingApproveReject(
      firstRequestApprovalUser
        ? params?.status === 'rejected'
          ? params?.status
          : 'partially approved'
        : params?.status,
      id,
    ).then((res) => {
      if (mounted.current) {
        if (res && res.status === 200) {
          setLoader(false);
        }
        if (res && res.status === 400) {
          if (billingOffboardErrorMsg.includes(res?.data?.request_status[0])) {
            setApiError(res?.data?.request_status[0]);
          } else if (
            res?.data?.request_status[0] ===
            'This pause request has been already voided'
          ) {
            setApiError('This link is expired');
          } else {
            setApiError('Something Went Wrong');
          }
        }

        if (res && res.status === 403) {
          setApiError('You do not have permission to perform this action.');
        }

        if ((res && res?.status === 404) || res?.status === 401) {
          setApiError('Something Went Wrong');
        }

        setLoader(false);
      }
    });
  }, [id, firstRequestApprovalUser, params.status]);

  useEffect(() => {
    mounted.current = true;

    onUpdateBillingOffboardingApproveReject();

    return () => {
      mounted.current = false;
    };
  }, [onUpdateBillingOffboardingApproveReject]);

  return (
    <>
      <UnauthorizedHeader />
      <ThanksPage>
        {loader ? (
          <PageLoader color={Theme.orange} type="page" />
        ) : !apiError ? (
          <>
            <img className="mb-3" src={AccountSetupIcon} alt="check" />
            {params?.status === 'approved' ? (
              <>
                <h5>Thanks!</h5>
                <p className="info">
                  Your approval has been processed successfully and all team
                  members will be informed.
                </p>
                <p className="info">
                  You can close this tab when you’re ready.
                </p>
              </>
            ) : (
              <>
                <h5>Thanks!</h5>
                <p className="info">
                  Your rejection has been processed successfully.
                  <br /> Please reach out to the BGS if you wish for them to
                  submit another request.
                </p>
                <p className="info">
                  You can close this tab when you’re ready.
                </p>
              </>
            )}
          </>
        ) : (
          <PageNotFounds>
            <img className="mb-3" src={PageNotFoundImg} alt="not-found" />
            <h5>{apiError}</h5>
            {apiError !== 'This link is expired' ? (
              <p className="not-found">
                The page you’re looking for can’t be found. Double check the URL
                and try again.
              </p>
            ) : null}
          </PageNotFounds>
        )}
      </ThanksPage>
    </>
  );
}
