import React from 'react';

import Select, { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import Theme from '../../../../../theme/Theme';
import { vendorReportTypes } from '../../../../../constants';
import {
  ViewData,
  WhiteCard,
  MultiSelectDropdown,
  DropdownIndicator,
  Button,
  CustomDropDown,
} from '../../../../../common';

export default function CreativeProjectFilters({
  isApiCall,
  hideFilters,
  isDropDownDisabled,
  selectedAccountType,
  globalFiltersOptions,
  multiSelectedValues,
  setMultiSelectedValues,
  selectedVendorReporting,
  setSelectedVendorReporting,
  handleMultiSelectChange,
  getProjectsFilter,
  handleApplyOnClick,
  displayAccountTypeFilter,
  handleMonthFilterOnMenuClose,
}) {
  const accountBasedCSS = (type) => {
    return type === 'btn'
      ? `col-xl-2 pl-md-0 apply-btn ${isDropDownDisabled ? 'isDisabled' : ''} ${
          hideFilters ? 'd-none' : ''
        }`
      : type === 'account-type'
      ? `col-xl-2 col-md-4 col-12 pl-md-0 mb-3`
      : `col-xl-2 col-md-4 col-12 pl-md-0 mb-3 ${
          isDropDownDisabled ? 'isDisabled' : ''
        } ${hideFilters ? 'd-none' : ''}`;
  };

  const isTablet = useMediaQuery({ minWidth: 768 });

  const { Option, SingleValue } = components;
  const generateViewData = () => {
    const handleOnChange = (e) => {
      setSelectedVendorReporting(e.value);
    };

    const dropdownSingleFilterOption = (props) => (
      <SingleValue {...props}>
        <span style={{ fontSize: '14px' }}>{props.data.label}</span>

        <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
      </SingleValue>
    );

    const dropdownFilterOption = (props) => (
      <Option {...props}>
        <div>
          <span style={{ fontSize: '14px' }}>{props.data.label}</span>
          <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
        </div>
      </Option>
    );

    const getDropdDownComponents = () => {
      return {
        Option: dropdownFilterOption,
        SingleValue: dropdownSingleFilterOption,
        DropdownIndicator,
      };
    };

    // display vendor type filter
    const displayVendorTypeFilter = () => {
      return (
        <CustomDropDown
          id="vendor-type-creative"
          className={isDropDownDisabled ? `cursor disabled` : 'cursor'}
        >
          <Select
            classNamePrefix="react-select"
            className="active"
            options={vendorReportTypes}
            isSearchable={false}
            placeholder={selectedVendorReporting}
            components={getDropdDownComponents()}
            onChange={(event) => handleOnChange(event)}
            value={vendorReportTypes.find(
              (op) => op.value === selectedVendorReporting,
            )}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral50: '#fff',
                primary25: Theme.lightOrange,
                primary: Theme.orange,
                primary50: Theme.lightOrange,
              },
            })}
          />
        </CustomDropDown>
      );
    };

    // display month dropdown filter
    const displayMonthDropdownfilter = () => {
      return (
        <MultiSelectDropdown
          options={globalFiltersOptions?.months}
          label="Months"
          value="months"
          multiSelectedValues={multiSelectedValues}
          setMultiSelectedValues={setMultiSelectedValues}
          handleMultiSelectChange={handleMultiSelectChange}
          isDisabled={isDropDownDisabled}
          handleOnMenuClose={() => {
            handleMonthFilterOnMenuClose();
          }}
        />
      );
    };

    // display marketplace dropdown filter
    const displayMarketplaceDropdownFilter = () => {
      return (
        <MultiSelectDropdown
          options={globalFiltersOptions?.marketplaces}
          label="Marketplaces"
          value="marketplaces"
          multiSelectedValues={multiSelectedValues}
          setMultiSelectedValues={setMultiSelectedValues}
          handleMultiSelectChange={handleMultiSelectChange}
          isDisabled={isDropDownDisabled}
        />
      );
    };

    // display project type dropdown filter
    const displayProjectTypeDropdownFilter = () => {
      return (
        <MultiSelectDropdown
          options={globalFiltersOptions?.project_type}
          label="Project Type"
          value="project_type"
          multiSelectedValues={multiSelectedValues}
          setMultiSelectedValues={setMultiSelectedValues}
          handleMultiSelectChange={handleMultiSelectChange}
          isDisabled={isDropDownDisabled}
        />
      );
    };

    // display project dropdown filter
    const displayProjectDropdownFilter = () => {
      return (
        <MultiSelectDropdown
          options={getProjectsFilter?.projects}
          label="Projects"
          value="projects"
          multiSelectedValues={multiSelectedValues}
          setMultiSelectedValues={setMultiSelectedValues}
          handleMultiSelectChange={handleMultiSelectChange}
          isDisabled={isDropDownDisabled}
        />
      );
    };

    return (
      <WhiteCard className="mb-3">
        <ViewData>
          <div className="row">
            <div className="col-xl-1 col-md-2 col-sm-12 pr-0 view-data-box">
              <div className="view-data-for">View data for:</div>
            </div>
            <div className="col-xl-11 col-md-10">
              <div className={`row ${isTablet ? 'justify-content-end' : ''}`}>
                <div className={accountBasedCSS('account-type')}>
                  {displayAccountTypeFilter(isApiCall)}
                </div>
                <div className={accountBasedCSS()}>
                  {displayMonthDropdownfilter()}
                </div>
                {selectedAccountType?.value === 'vendor' ? (
                  <div className={accountBasedCSS()}>
                    {displayVendorTypeFilter()}
                  </div>
                ) : null}
                <div className={accountBasedCSS()}>
                  {displayMarketplaceDropdownFilter()}
                </div>
                <div className={accountBasedCSS('project_type')}>
                  {displayProjectTypeDropdownFilter()}
                </div>
                <div className={accountBasedCSS('project')}>
                  {displayProjectDropdownFilter()}
                </div>
                <div className={`${accountBasedCSS('btn')}`}>
                  <Button
                    className={`btn-primary w-100 ${
                      isDropDownDisabled ? 'disabled' : ''
                    }`}
                    onClick={() => handleApplyOnClick()}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ViewData>
      </WhiteCard>
    );
  };

  return <>{generateViewData()}</>;
}

CreativeProjectFilters.defaultProps = {
  isApiCall: false,
  hideFilters: false,
  isDropDownDisabled: false,
  selectedVendorReporting: '',
  globalFiltersOptions: [],
  data: {},
  multiSelectedValues: {},
  getProjectsFilter: {},
  selectedAccountType: {},
  setMultiSelectedValues: () => {},
  setSelectedVendorReporting: () => {},
  handleMultiSelectChange: () => {},
  handleApplyOnClick: () => {},
  displayAccountTypeFilter: () => {},
  handleMonthFilterOnMenuClose: () => {},
};

CreativeProjectFilters.propTypes = {
  isApiCall: bool,
  hideFilters: bool,
  isDropDownDisabled: bool,
  selectedVendorReporting: string,
  globalFiltersOptions: arrayOf(shape({})),
  multiSelectedValues: shape({}),
  getProjectsFilter: shape({}),
  selectedAccountType: shape({}),
  data: shape({}),
  setMultiSelectedValues: func,
  setSelectedVendorReporting: func,
  handleMultiSelectChange: func,
  handleApplyOnClick: func,
  displayAccountTypeFilter: func,
  handleMonthFilterOnMenuClose: func,
};
