import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes, { shape } from 'prop-types';
import NumberFormat from 'react-number-format';

import { InputFormField, Button, ErrorMsg } from '../../common';
import { billingInformation } from '../../constants';
import { saveBillingInfo } from '../../api';

export default function AdditionalBillingContact({
  data,
  formData,
  setFormData,
  apiError,
  setApiError,
  disabledConditionForBillingContact,
  contactApiErrors,
  setContactApiErrors,
}) {
  const tempContact = {
    uniqueKey: Math.random(),
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  };

  useEffect(() => {
    if (!formData?.billing_contact?.length) {
      tempContact.primary_contact = true;
      setFormData({
        ...formData,
        billing_contact: [tempContact],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.billing_contact]);

  const addNewContact = () => {
    setFormData({
      ...formData,
      // eslint-disable-next-line react/prop-types
      billing_contact: [...formData?.billing_contact, tempContact],
    });
  };

  const handleChange = (event, item, type, contact, contactIndex) => {
    const tempBillingContact = formData?.billing_contact;

    if (tempBillingContact?.length) {
      tempBillingContact[contactIndex][item?.key] = event.target.value;

      setFormData({
        ...formData,
        billing_contact: tempBillingContact,
      });
    }

    setApiError({
      ...apiError,
      [type]: {
        ...apiError[type],
        [contactIndex]: {
          ...apiError[type]?.[contactIndex],
          [item.key]: '',
        },
      },
    });

    setContactApiErrors({
      ...contactApiErrors,
      [type]: {
        ...contactApiErrors[type],
        [contactIndex]: {
          ...contactApiErrors[type]?.[contactIndex],
          [item.key]: '',
        },
      },
    });
  };

  const generateNumeric = (item, type, contact, contactIndex) => {
    return (
      <NumberFormat
        format={item.format}
        className="form-control"
        onChange={(event) =>
          handleChange(event, item, type, contact, contactIndex)
        }
        placeholder={`Enter ${item.label}`}
        value={contact[item.key]}
        isNumericString
      />
    );
  };

  const generateInput = (item, type, contact, contactIndex) => {
    return (
      <input
        className="form-control"
        placeholder={`Enter ${item.label}`}
        type={item.type}
        defaultValue={contact[item.key]}
        onChange={(event) =>
          handleChange(event, item, type, contact, contactIndex)
        }
        maxLength={item.key === 'postal_code' ? 10 : ''}
      />
    );
  };

  const deleteContact = (contactIndex, id, type) => {
    const tempBillingContactForDelete = [...formData?.billing_contact];
    if (tempBillingContactForDelete?.length) {
      setApiError({
        ...apiError,
        [type]: {
          ...apiError[type],
          [contactIndex]: {},
        },
      });

      setContactApiErrors({
        ...contactApiErrors,
        [type]: {
          ...contactApiErrors[type],
          [contactIndex]: {},
        },
      });

      // eslint-disable-next-line no-unused-vars
      const tempRemainingBillingContact = tempBillingContactForDelete.splice(
        contactIndex,
        1,
      );
      setFormData({
        ...formData,
        billing_contact: [...tempBillingContactForDelete],
      });
      saveBillingInfo({ billing_contact_id: id }, data?.id);
    }
  };

  const disableAddContactButton = () => {
    if (
      formData?.billing_contact?.length &&
      formData?.billing_contact?.length < 6
    ) {
      return false;
    }
    return true;
  };

  const returnError = (param) => {
    return param;
  };

  const showAPIErrors = (contactIndex, label) => {
    const uniqueKeyNew =
      formData?.billing_contact?.length &&
      formData?.billing_contact?.filter((item) => item?.uniqueKey);

    if (uniqueKeyNew?.length) {
      const displayNewErrorMessage =
        contactApiErrors?.billing_contact?.[contactIndex]?.[label]?.[0];
      if (displayNewErrorMessage) {
        return returnError(displayNewErrorMessage);
      }
    }

    const oldContacts =
      formData?.billing_contact?.length &&
      formData?.billing_contact?.filter((item) => item?.id);
    if (oldContacts?.length) {
      const displayOldErrorMessage =
        apiError?.billing_contact?.[contactIndex]?.[label]?.[0];
      if (displayOldErrorMessage) {
        return returnError(displayOldErrorMessage);
      }
    }

    return '';
  };

  const generateBillingContactHTML = () => {
    return (
      <>
        {formData?.billing_contact?.length &&
          formData?.billing_contact.map((contact, contactIndex) => {
            return billingInformation.map((field) =>
              field?.label === 'Billing Contact' ? (
                <fieldset
                  className="shape-without-border  w-430 mt-3"
                  // eslint-disable-next-line react/no-array-index-key
                  key={contact?.id ? contact?.id : contact?.uniqueKey}
                >
                  {contactIndex <= 0 ? (
                    <p className="account-steps m-0">{field.part}</p>
                  ) : (
                    ''
                  )}
                  <div className="row ">
                    <div className="col-10">
                      <div className="billing-address">
                        {' '}
                        {contactIndex <= 0
                          ? field.label
                          : field.additional_label}
                      </div>
                    </div>
                    <div className="col-2">
                      {contactIndex > 0 ? (
                        <div
                          className="text-right"
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() =>
                            deleteContact(
                              contactIndex,
                              contact?.id ? contact?.id : null,
                              'billing_contact',
                            )
                          }
                          aria-hidden="true"
                        >
                          <FontAwesomeIcon icon="fa-regular fa-trash" />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <p className="mt-2">{field.subTitle}</p>
                  <div className="row">
                    {field.array.map((item) => (
                      <div className={item.property} key={item.key}>
                        <InputFormField className="mt-3">
                          <label htmlFor={item.key}>
                            {item.label}
                            <br />
                            {item.type === 'number' ? (
                              <>
                                {generateNumeric(
                                  item,
                                  field.key,
                                  contact,
                                  contactIndex,
                                )}
                              </>
                            ) : (
                              <>
                                {generateInput(
                                  item,
                                  field.key,
                                  contact,
                                  contactIndex,
                                )}
                              </>
                            )}
                          </label>
                          <ErrorMsg>
                            {showAPIErrors(contactIndex, item.key)}
                          </ErrorMsg>
                        </InputFormField>
                      </div>
                    ))}
                  </div>
                </fieldset>
              ) : (
                ''
              ),
            );
          })}
      </>
    );
  };

  return (
    <>
      {generateBillingContactHTML()}
      <fieldset className="shape-without-border  w-430 mt-3">
        <div className="billing-address">
          <FontAwesomeIcon
            icon="fa-light fa-user-plus"
            style={{ fontSize: '16px', marginRight: '6px' }}
          />
          Additional Billing Contact
        </div>
        <div className="row align-items-center">
          <div className="col-9">
            <div
              style={{
                color: '#171725',
                fontSize: '14px',
                marginTop: '8px',
                lineHeight: '20px',
              }}
            >
              You can add a maximum of 5 team members to receive invoices.
              Billing contacts can be updated anytime from your dashboard.
            </div>
          </div>
          <div className="col-3">
            <Button
              className="btn-orange-border border-radius-19"
              style={{ marginBottom: '10px' }}
              onClick={addNewContact}
              disabled={
                disableAddContactButton() ||
                disabledConditionForBillingContact()
              }
            >
              Add
            </Button>
          </div>
        </div>
      </fieldset>
    </>
  );
}

AdditionalBillingContact.defaultProps = {
  data: {},
  formData: {},
  setFormData: () => {},
  apiError: {},
  setApiError: () => {},
  disabledConditionForBillingContact: () => {},
  contactApiErrors: {},
  setContactApiErrors: () => {},
};

AdditionalBillingContact.propTypes = {
  data: shape({}),
  formData: shape({}),
  setFormData: PropTypes.func,
  apiError: shape({}),
  setApiError: PropTypes.func,
  disabledConditionForBillingContact: PropTypes.func,
  contactApiErrors: shape({}),
  setContactApiErrors: PropTypes.func,
};
