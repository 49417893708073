import React, { useState, useRef, useEffect } from 'react';

import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import DatePicker from 'react-date-picker';
import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, shape, bool, func, string } from 'prop-types';

import AddAssignee from './AddAssignee';
import COSMobileView from './COSMobileView';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { downloadCosReport, deleteCOS } from '../../../../../api';
import { InfoIcons, TrashIcons } from '../../../../../theme/images';
import { TaskStatus } from '../../Playbook/PlaybookStyle';
import { TableWrapper, AsinStatus, DesktopStickyTable } from '../GoalsStyle';
import {
  tableHeaders,
  defaultCOSData,
  stickyColumnHeaders,
} from '../../../../../constants';
import {
  Button,
  WhiteCard,
  DesktopTable,
  SingleSelectDropdown,
  DropdownIndicator,
  ToggleSwitch,
  ErrorMsg,
  PageLoader,
  InputFormField,
} from '../../../../../common';

function COSTable({
  isEditFlagOn,
  cosData,
  asinsList,
  setAsinsList,
  selectedViewType,
  isSingleMonthSelected,
  cosAccessOfBP,
  errors,
  setError,
  id,
  customStartDate,
  customEndDate,
  setNewRowFlag,
  getAsinCountData,
}) {
  const isDesktop = useMediaQuery({ minWidth: 767 });

  const [downloadLoader, setDownloadLoader] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteconfirmationModal] =
    useState({ flag: false, selectedCosId: null, index: null });
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [showStatusDropdown, setShowStatusDropdown] = useState({
    index: null,
    viewFor: '',
  });
  const dropdownRef = useRef(null);

  const handleClickOutside = () => {
    setShowStatusDropdown({ index: null, viewFor: '' });
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const tableColumns = tableHeaders
    ?.map((headers) => [...headers?.columns])
    .flat();
  const stickyColumns = stickyColumnHeaders
    ?.map((headers) => [...headers?.columns])
    .flat();

  const handleDownloadBtn = () => {
    setDownloadLoader(true);
    const link = document.createElement('a');
    downloadCosReport(id, customStartDate, customEndDate).then((res) => {
      setDownloadLoader(false);

      if (res?.status === 200) {
        setDownloadUrl(res?.data?.url);
        link.href = res?.data?.url;
        link.click();
      }
    });
  };

  const displayDownloadBtn = () => {
    return (
      <a
        className="download-pdf-link"
        href={downloadUrl}
        rel="noreferrer"
        download
      >
        <Button
          type="button"
          onClick={() => {
            handleDownloadBtn();
          }}
          value="download"
          className={` btn-primary  extraNormal-text w-25`}
        >
          {downloadLoader ? (
            <PageLoader color="#fff" type="button" />
          ) : (
            'Download'
          )}
        </Button>
      </a>
    );
  };

  // get dropdwon indicator for  dropdown filter
  const getSelectDropdownIndicator = () => {
    return {
      DropdownIndicator,
    };
  };

  const handleChange = (event, item, asinData, cosIndex) => {
    asinData[item?.key] = event?.value;

    const list = [...asinsList];
    list[cosIndex] = asinData;
    setAsinsList([...list]);
  };

  const handleError = (cosIndex, item) => {
    if (Object.keys(errors)?.length) {
      const list = { ...errors };

      if (
        list &&
        Object.keys(list) &&
        Object.keys(list)?.includes(cosIndex?.toString())
      )
        delete list[cosIndex][item?.key];
      setError({ ...list });
    }
  };

  const handleInputChange = (event, asinData, cosIndex) => {
    asinData[event?.target?.name] = event?.target?.value;

    const list = [...asinsList];
    list[cosIndex] = asinData;
    setAsinsList([...list]);
  };

  const handleDateChange = (event, item, asinData, cosIndex) => {
    const formattedDate = dayjs(event).format('YYYY-MM-DD');
    asinData[item?.key] = formattedDate;

    const list = [...asinsList];
    list[cosIndex] = asinData;
    setAsinsList([...list]);
  };
  const handleAddAssignee = (event, item, asinData, cosIndex) => {
    asinData[item?.key] = event?.id;
    asinData[item?.valueKey] = {
      id: event?.id,
      first_name: event?.first_name,
      last_name: event?.last_name,
    };

    const list = [...asinsList];
    list[cosIndex] = asinData;
    setAsinsList([...list]);
  };

  const handleRadioBtn = (event, item, asinData, cosIndex) => {
    asinData[item?.key] = event;

    const list = [...asinsList];
    list[cosIndex] = asinData;
    setAsinsList([...list]);
  };

  const applyClassNameOfStatus = (key) => {
    let className = '';
    if (key === 'waiting on assets') {
      className = 'selected-asin-status waiting-for-assets';
    }
    if (key === 'in progress - creative') {
      className = 'selected-asin-status in-progress-creative';
    }
    if (key === 'ready for upload') {
      className = 'selected-asin-status ready-for-upload';
    }
    if (key === 'uploaded') {
      className = 'selected-asin-status uploaded';
    }
    return className;
  };

  const applyStatusIcons = (key) => {
    let iconName = '';
    if (key === 'waiting on assets') {
      iconName = 'fa-exclamation';
    }
    if (key === 'in progress - creative') {
      iconName = 'fa-bars-progress';
    }
    if (key === 'ready for upload') {
      iconName = 'fa-upload';
    }
    if (key === 'uploaded') {
      iconName = 'fa-check';
    }
    return iconName;
  };

  const onCancelOfAddNewRow = (selectedDeleteCosId) => {
    if (selectedDeleteCosId) {
      const list = [...asinsList];
      const filterListofCOSWithIds = list.filter(
        (item) => item?.id !== selectedDeleteCosId,
      );
      setAsinsList([...filterListofCOSWithIds]);
    } else {
      setNewRowFlag(false);
      const list = [...asinsList];
      list.splice(showDeleteConfirmationModal?.index, 1);
      setAsinsList([...list]);
    }
    setShowDeleteconfirmationModal({
      flag: false,
      selectedCosId: null,
      index: null,
    });
  };

  const deleteCos = () => {
    if (showDeleteConfirmationModal?.selectedCosId) {
      deleteCOS(showDeleteConfirmationModal?.selectedCosId).then(() => {
        toast.success('COS Deleted successfully!');
        getAsinCountData();
        onCancelOfAddNewRow(showDeleteConfirmationModal?.selectedCosId);
      });
    } else {
      onCancelOfAddNewRow();
    }
  };

  const displayInputBox = (item, asinData, cosIndex) => {
    switch (item?.type) {
      case 'radio':
        return (
          <div id={`${cosIndex}${item?.key}`}>
            <ToggleSwitch className="custom-switch marginTop10 ml-4">
              <label
                className={`label-name px-0 `}
                htmlFor={`${item?.key}${cosIndex}`}
              >
                <span
                  className={`ml-n4 ${
                    asinsList[cosIndex][item.key] ? 'disabled' : ''
                  } `}
                >
                  {' '}
                  No
                </span>
                <input
                  type="checkbox"
                  className="label-name"
                  id={`${item?.key}${cosIndex}`}
                  onClick={() => {
                    handleRadioBtn(
                      !asinData[item?.key],
                      item,
                      asinData,
                      cosIndex,
                    );
                  }}
                  defaultChecked={asinsList[cosIndex][item.key]}
                />
                <span className="slider round" />
                <span
                  className={`ml-4 pl-1 ${
                    asinsList[cosIndex][item.key] ? '' : 'disabled'
                  }`}
                >
                  {' '}
                  Yes
                </span>
              </label>
            </ToggleSwitch>
          </div>
        );

      case 'choice':
        return (
          <SingleSelectDropdown
            id={`${cosIndex}${item?.key}`}
            placeholder={`Select `}
            filterId={`${cosIndex}${item?.key}`}
            className="single-select-dropdown"
            isApiCall={false}
            dropdownOptions={item?.options}
            selectedValue={item?.options?.find(
              (option) => option.value === asinsList[cosIndex][item.key],
            )}
            onChangeHandler={(event) =>
              handleChange(event, item, asinData, cosIndex)
            }
            dropdownComponents={getSelectDropdownIndicator}
            isSearchable={false}
          />
        );

      case 'checkbox':
        return (
          <div className="display-flex" style={{ marginTop: '10px' }}>
            {item.key === 'parent_status' ? (
              <span className="asin-trash">
                <img
                  src={TrashIcons}
                  alt="delete"
                  width="20px"
                  height="20px"
                  className="trash cursor"
                  onClick={() =>
                    setShowDeleteconfirmationModal({
                      flag: true,
                      selectedCosId: asinData?.id,
                      index: cosIndex,
                    })
                  }
                  role="presentation"
                />{' '}
              </span>
            ) : (
              ''
            )}
            <AsinStatus>
              <div
                className={`asin-status ${applyClassNameOfStatus(
                  asinsList[cosIndex][item.key],
                )} ${item?.key === 'parent_status' ? 'parent-status' : ''}`}
                role="presentation"
                key={`${item?.key}${cosIndex}`}
                id={`${item?.key}${cosIndex}`}
                onClick={() => {
                  setShowStatusDropdown({
                    index: cosIndex,
                    viewFor: item?.key,
                  });
                }}
              >
                <FontAwesomeIcon
                  color={`asin-status ${applyClassNameOfStatus(
                    asinsList[cosIndex][item.key],
                  )}`}
                  fontSize="12px"
                  icon={`fa-regular ${applyStatusIcons(
                    asinsList[cosIndex][item.key],
                  )}`}
                />
              </div>
              <div
                ref={dropdownRef}
                id={`${item?.key}${cosIndex}`}
                key={`${item?.key}${cosIndex}`}
              >
                <div
                  className="dropdown-select-all-notes"
                  role="presentation"
                  id="clickbox"
                />
                <div
                  className={
                    showStatusDropdown.index === cosIndex &&
                    showStatusDropdown.viewFor === item?.key
                      ? 'dropdown-notes-filter react-select__menu'
                      : 'dropdown-notes-filter hide'
                  }
                >
                  <ul className="notes-option">
                    {item?.options.map((statusOption) => {
                      return (
                        <li
                          key={`${statusOption.label}${item?.key}${cosIndex}`}
                          id={`${statusOption.label}${item?.key}${cosIndex}`}
                          role="presentation"
                          onClick={() =>
                            handleChange(statusOption, item, asinData, cosIndex)
                          }
                        >
                          <div>
                            <div
                              className={`asin-status ${applyClassNameOfStatus(
                                statusOption?.value,
                              )} option-icon`}
                              role="presentation"
                            >
                              <FontAwesomeIcon
                                color={`asin-status ${applyClassNameOfStatus(
                                  statusOption?.value,
                                )}`}
                                fontSize="12px"
                                icon={`fa-regular ${applyStatusIcons(
                                  statusOption?.value,
                                )}`}
                              />
                            </div>

                            <div className="option-label">
                              {statusOption.label}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </AsinStatus>
          </div>
        );

      case 'date':
        return (
          <InputFormField backgroundTransparent="transparent">
            <DatePicker
              className="form-control modal-input-control mt-0 openCalendar"
              placeholderText="Select Date"
              id={`${cosIndex}${item?.key}`}
              value={
                asinsList[cosIndex][item.key]
                  ? new Date(asinsList[cosIndex][item.key])
                  : null
              }
              format="MM/dd/yyyy"
              clearIcon={null}
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              onChange={(date) => {
                handleDateChange(date, item, asinData, cosIndex);
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </InputFormField>
        );

      case 'avtar':
        return (
          <AddAssignee
            id={`${cosIndex}${item?.key}`}
            assigneeData={asinsList[cosIndex][item.valueKey]}
            handleAddAssignee={(event) =>
              handleAddAssignee(event, item, asinData, cosIndex)
            }
            asinData={asinData}
            header={item}
            cosIndex={cosIndex}
            isEditFlagOn={isEditFlagOn}
          />
        );

      default:
        return (
          <>
            <InputFormField backgroundTransparent="transparent">
              <label htmlFor="asin">
                <input
                  id={`${cosIndex}${item?.key}`}
                  key={`${cosIndex}${item?.key}`}
                  className={`form-control modal-input-control mt-0 ${
                    errors?.[cosIndex]?.[item?.key] ? 'form-control-error' : ''
                  }`}
                  type="text"
                  placeholder={`Enter ${
                    item?.key === 'parent_asin' || item?.key === 'child_asin'
                      ? 'Asin'
                      : item?.label
                  }`}
                  maxLength={item?.charLimit}
                  name={`${item?.key}`}
                  value={asinsList[cosIndex][item.key]}
                  onChange={(event) => {
                    handleInputChange(event, asinData, cosIndex);
                    handleError(cosIndex, item);
                  }}
                />
              </label>
            </InputFormField>

            {errors?.[cosIndex]?.[item.key] ? (
              <ErrorMsg>{errors?.[cosIndex]?.[item.key]}</ErrorMsg>
            ) : null}
          </>
        );
    }
  };

  const calculateHeight = (index = 0) => {
    const right = document
      .getElementById(`${index}non-sticky`)
      ?.getBoundingClientRect().height;
    const left = document
      .getElementById(`${index}sticky`)
      ?.getBoundingClientRect().height;
    if (!left && !right) return '64px';
    return left > right ? `${left}px` : `${right}px`;
  };

  const displayStickyEditableTable = (asinData, cosIndex, tableType) => {
    return (
      <tr
        id={`${cosIndex}${tableType}`}
        style={
          errors && errors?.[cosIndex] && Object.keys(errors?.[cosIndex]).length
            ? { height: calculateHeight(cosIndex) }
            : null
        }
      >
        {stickyColumns?.map((item) => (
          <td
            id={`${cosIndex}stickyTd${item?.key}`}
            className={` ${item?.className} `}
            style={{ verticalAlign: 'top' }}
          >
            {displayInputBox(item, asinData, cosIndex)}
          </td>
        ))}
      </tr>
    );
  };

  const displayEditableTable = (asinData, cosIndex, tableType) => {
    return (
      <tr
        id={`${cosIndex}${tableType}`}
        style={
          errors && errors?.[cosIndex] && Object.keys(errors?.[cosIndex]).length
            ? { height: calculateHeight(cosIndex) }
            : null
        }
      >
        {tableColumns?.map((item) => (
          <td
            id={`${cosIndex}scrollableTd${item?.key}`}
            className={` ${item?.className} `}
            style={{ verticalAlign: 'top' }}
          >
            {displayInputBox(item, asinData, cosIndex)}
          </td>
        ))}
      </tr>
    );
  };

  const displayValuesForTextInput = (item, asinData, cosIndex) => {
    const linkfields = [
      'pdp_url',
      'bp_url',
      'link_to_assets',
      'link_to_completed_design_deliverables',
      'design_link_to_jira_project',
      'link_to_completed_copy_deliverables',
      'copy_link_to_jira_project',
    ];

    if (linkfields.includes(item.key)) {
      return asinsList[cosIndex][item.key] ? (
        <a
          href={asinsList[cosIndex][item.key]}
          target="_blank"
          rel="noopener noreferrer"
        >
          Link
        </a>
      ) : (
        <span style={{ color: '#FF5933' }}>-</span>
      );
    }
    if (item.key === 'design_project_status') {
      return <span>{asinsList[cosIndex][item.key]}</span>;
    }
    if (item.key === 'copy_project_status') {
      return <span>{asinsList[cosIndex][item.key]}</span>;
    }
    return (
      <>
        <span
          data-tip={asinsList[cosIndex][item.key]}
          data-for={`${cosIndex}${item?.key}`}
        >
          {asinsList[cosIndex][item.key]?.length > 11
            ? asinsList[cosIndex][item.key]?.slice(0, 11)
            : asinsList[cosIndex][item.key]}
          {asinsList[cosIndex][item.key]?.length > 11 ? (
            <span style={{ color: 'black' }}>...</span>
          ) : (
            ''
          )}
        </span>
        <ReactTooltip
          id={`${cosIndex}${item?.key}`}
          aria-haspopup="true"
          place="top"
        />
      </>
    );
  };

  // render progress label className
  const renderProgressLabelClassName = (status) => {
    let className = '';
    switch (status) {
      case 'Keyword Research':
        className = 'progress-label gray-label';
        break;

      case 'Copywriting':
        className = 'progress-label blue-label';
        break;

      case 'Sub Editing':
        className = 'progress-label voilet-label';
        break;

      case 'Copy/Proof Editing':
        className = 'progress-label orange-label';
        break;
      case 'Completed':
        className = 'progress-label green-label';
        break;
      case 'On Hold':
        className = 'progress-label yellow-label';
        break;
      case 'Waiting for Design':
        className = 'progress-label red-label';
        break;
      case 'In Process':
        className = 'progress-label orange-label';
        break;

      case 'Sent to Copyeditor':
        className = 'progress-label blue-label';
        break;
      case 'Sent to Copywriter':
        className = 'progress-label blue-label';
        break;
      case 'Revision':
        className = 'progress-label red-label';
        break;

      default:
        break;
    }
    return className;
  };

  const displayValues = (item, asinData, cosIndex) => {
    const status = item?.options?.find(
      (option) => option.value === asinsList[cosIndex][item.key],
    );

    switch (item?.type) {
      case 'radio':
        return (
          <div id={`${cosIndex}${item?.key}`}>
            {asinsList[cosIndex][item.key] ? 'Yes' : 'No'}
          </div>
        );

      case 'choice':
        return (
          <div>
            {item?.key === 'design_project_status' ||
            item?.key === 'copy_project_status' ? (
              <TaskStatus
                className={`${renderProgressLabelClassName(status?.label)} `}
                role="presentation"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <div>{status?.label}</div>
              </TaskStatus>
            ) : (
              status?.label
            )}
          </div>
        );

      case 'checkbox':
        return (
          <AsinStatus>
            <div
              data-tip={asinsList[cosIndex][item.key]}
              data-for={`${cosIndex}${item?.key}`}
              className={`asin-status ${applyClassNameOfStatus(
                asinsList[cosIndex][item.key],
              )}`}
              role="presentation"
            >
              <FontAwesomeIcon
                color={`asin-status ${applyClassNameOfStatus(
                  asinsList[cosIndex][item.key],
                )}`}
                fontSize="12px"
                icon={`fa-regular ${applyStatusIcons(
                  asinsList[cosIndex][item.key],
                )}`}
              />
            </div>

            <ReactTooltip
              id={`${cosIndex}${item?.key}`}
              aria-haspopup="true"
              place="top"
            />
          </AsinStatus>
        );

      case 'date':
        return (
          <div>
            {asinsList[cosIndex][item.key]
              ? dayjs(asinsList[cosIndex][item.key]).format('DD MMM YYYY')
              : ''}
          </div>
        );

      case 'avtar':
        return (
          <AddAssignee
            id={`${cosIndex}${item?.key}`}
            assigneeData={asinsList[cosIndex][item.valueKey]}
            handleAddAssignee={(event) =>
              handleAddAssignee(event, item, asinData, cosIndex)
            }
            asinData={asinData}
            header={item}
            cosIndex={cosIndex}
            isEditFlagOn={isEditFlagOn}
          />
        );

      default:
        return <>{displayValuesForTextInput(item, asinData, cosIndex)}</>;
    }
  };
  const displayStickyNonEditableTable = (asinData, cosIndex, tableType) => {
    return (
      <tr
        id={`${cosIndex}${tableType}`}
        style={{ height: calculateHeight(cosIndex) }}
      >
        {stickyColumns?.map((item) => (
          <td className={item?.className}>
            {displayValues(item, asinData, cosIndex)}
          </td>
        ))}
      </tr>
    );
  };

  const displayNonEditableTable = (asinData, cosIndex, tableType) => {
    return (
      <tr
        id={`${cosIndex}${tableType}`}
        style={{ height: calculateHeight(cosIndex) }}
      >
        {tableColumns?.map((item) => (
          <td className={item?.className}>
            {displayValues(item, asinData, cosIndex)}
          </td>
        ))}
      </tr>
    );
  };

  const displayTableData = (asinData, cosIndex, tableType) => {
    return isEditFlagOn
      ? tableType === 'sticky'
        ? displayStickyEditableTable(asinData, cosIndex, tableType)
        : displayEditableTable(asinData, cosIndex, tableType)
      : tableType === 'sticky'
      ? displayStickyNonEditableTable(asinData, cosIndex, tableType)
      : displayNonEditableTable(asinData, cosIndex, tableType);
  };

  const addNewRow = () => {
    setAsinsList([...asinsList, { ...defaultCOSData }]);
    setNewRowFlag(true);
  };

  const displayAddNewRowBtn = () => {
    return (
      <div className="sticky-column add-new-cos-btn ">
        <Button
          className="btn-add-contact extraNormal-text"
          onClick={() => {
            addNewRow();
          }}
          style={{ textTransform: 'uppercase', minHeight: '0' }}
        >
          <FontAwesomeIcon
            color="#FF5933"
            fontSize="12px"
            icon="fa-regular fa-plus"
          />{' '}
          Add New Row
        </Button>
      </div>
    );
  };

  const displayTableHeaders = (item, index) => {
    return (
      <th id={item?.id} width={item?.width} className={`${item?.className} `}>
        {item?.label}
        {item?.info ? (
          <>
            {' '}
            <img
              className="ml-1 cursor"
              style={{
                verticalAlign: 'text-bottom',
                fontWeight: '300',
              }}
              width="14px"
              src={InfoIcons}
              alt="info"
              data-tip={item?.info}
              data-for={`${item?.label}${index}`}
            />{' '}
            <ReactTooltip
              id={`${item?.label}${index}`}
              aria-haspopup="true"
              place="top"
              effect="solid"
              html
            />{' '}
          </>
        ) : (
          ''
        )}
      </th>
    );
  };

  const calculateHeaderHeight = () => {
    const right = document
      .getElementById(`non-sticky-header`)
      ?.getBoundingClientRect().height;
    const left = document
      .getElementById(`sticky-header`)
      ?.getBoundingClientRect().height;
    if (!left && !right) return '61px';
    return left > right ? `${left}px` : `${right}px`;
  };

  const displayStickyTable = () => {
    return (
      <>
        {cosData?.length && asinsList?.length ? (
          <TableWrapper>
            <div>
              <DesktopStickyTable
                style={{ tableLayout: 'fixed', width: '100%' }}
              >
                <thead>
                  <tr
                    id="sticky-header"
                    className="gray-border-radius"
                    style={{ height: calculateHeaderHeight() }}
                  >
                    {stickyColumns?.map((item, index) => {
                      return displayTableHeaders(item, index);
                    })}
                  </tr>
                </thead>
                <tbody>
                  {cosData?.length ? (
                    asinsList?.map((cos, cosIndex) => {
                      return displayTableData(cos, cosIndex, 'sticky');
                    })
                  ) : (
                    <tr>No Data Available</tr>
                  )}
                </tbody>
              </DesktopStickyTable>
            </div>
          </TableWrapper>
        ) : null}
        {cosData?.length &&
        isEditFlagOn &&
        cosAccessOfBP?.show &&
        isSingleMonthSelected()
          ? displayAddNewRowBtn()
          : null}
      </>
    );
  };

  const displayTable = () => {
    return (
      <>
        {cosData?.length && asinsList?.length ? (
          <TableWrapper>
            <div className="modal-body">
              <DesktopTable style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr id="non-sticky-header">
                    {tableColumns?.map((item, index) => {
                      return displayTableHeaders(item, index);
                    })}
                  </tr>
                </thead>
                <tbody>
                  {cosData?.length ? (
                    asinsList?.map((cos, cosIndex) => {
                      return displayTableData(cos, cosIndex, 'non-sticky');
                    })
                  ) : (
                    <tr>No Data Available</tr>
                  )}
                </tbody>
              </DesktopTable>
            </div>
          </TableWrapper>
        ) : (
          <p className=" gray-medium-text my-2 ml-5">No COS Data</p>
        )}
      </>
    );
  };

  const handleDeleteSelection = (flag) => {
    if (flag) {
      deleteCos();
    } else {
      setShowDeleteconfirmationModal({
        flag: false,
        selectedCosId: null,
        index: null,
      });
    }
  };

  return isDesktop ? (
    <>
      {' '}
      {showDeleteConfirmationModal?.flag ? (
        <DeleteConfirmationModal
          showModal={showDeleteConfirmationModal}
          setShowModal={setShowDeleteconfirmationModal}
          handleDeleteSelection={handleDeleteSelection}
        />
      ) : null}
      <WhiteCard className=" mb-1">
        <div className="row mb-3">
          <div className="col-6">
            <div className="COS-view-label" id="generalLabel">
              {selectedViewType?.label}
            </div>
          </div>
          <div className="col-6 text-right">
            {cosData?.length && cosAccessOfBP?.show ? displayDownloadBtn() : ''}
          </div>
        </div>
        <div className="row m-0">
          <div className="col-4 p-0">{displayStickyTable()}</div>
          <div className="col-8 p-0">{displayTable()}</div>
        </div>
      </WhiteCard>
    </>
  ) : (
    <COSMobileView
      cosData={cosData}
      asinsList={asinsList}
      stickyColumns={stickyColumns}
      tableColumns={tableColumns}
      isEditFlagOn={isEditFlagOn}
      displayInputBox={displayInputBox}
      displayValues={displayValues}
      selectedViewType={selectedViewType}
      cosAccessOfBP={cosAccessOfBP}
      displayDownloadBtn={displayDownloadBtn}
      isSingleMonthSelected={isSingleMonthSelected}
      displayAddNewRowBtn={displayAddNewRowBtn}
    />
  );
}

export default COSTable;

COSTable.defaultProps = {
  isEditFlagOn: false,
  cosData: [],
  asinsList: [],
  setAsinsList: () => {},
  selectedViewType: {},
  isSingleMonthSelected: () => {},
  cosAccessOfBP: {},
  errors: {},
  setError: () => {},
  id: '',
  customStartDate: '',
  customEndDate: '',
  setNewRowFlag: () => {},
  getAsinCountData: () => {},
};

COSTable.propTypes = {
  isEditFlagOn: bool,
  cosData: arrayOf(shape({})),
  asinsList: arrayOf(shape({})),
  setAsinsList: func,
  selectedViewType: shape({}),
  isSingleMonthSelected: func,
  cosAccessOfBP: shape({}),
  errors: shape({}),
  setError: func,
  id: string,
  customStartDate: string,
  customEndDate: string,
  setNewRowFlag: func,
  getAsinCountData: func,
};
