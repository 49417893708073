import dayjs from 'dayjs';

// constant for dsp-ppc overlap analysis graphs headings and sub headings
export const dspPpcOverlapGraphHeader = {
  LineGraphHeader: {
    heading: 'Ad Attributed Sales by Ad Exposure Type',
    subHeading:
      'Sales generated per 1,000 impressions for shoppers that engaged in PPC only, DSP only, and both DSP and PPC ads.',
  },
  PieChartHeader: {
    heading: 'Share of Conversions and Sales by Ad Exposure Type',
    subHeading:
      'This analysis shows what percentage of total conversions and total sales are attributed to each ad exposure type.',
  },
  BarGraphHeader: {
    heading: 'Conversion Rate by ad Exposure Type',
    subHeading: 'Shows the conversion rate for each ad exposure type.',
  },
};

// constant for triple overlap analysis graphs headings and sub headings
export const tripleOverlapGraphHeader = {
  LineGraphHeader: {
    heading: 'Triple Overlap Analysis',
    subHeading:
      'Ad attributed sales by ad exposure type. Sales generated per 1,000 impressions for shoppers that engaged in PPC only, DSP only, STV only, both PPC and DSP, both PPC and STV, both DSP and STV, and PPC, DSP and STV (Triple) ads.',
  },
  PieChartHeader: {
    heading: 'Unique Reach by Ad Exposure Type',
    subHeading: 'Percentage of impressions delivered by ad exposure type.',
  },
  BarGraphHeader: {
    heading: 'Conversion Rate by Ad Exposure Type',
    subHeading: 'Shows the conversion rate for each ad exposure type.',
  },
};

// constant for triple overlap value graphs headings and sub headings
export const tripleValueGraphHeader = {
  BarGraphHeader: {
    heading: 'ASP and AOV by Ad Exposure type with Triple Overlap',
    subHeading:
      'Avg. Sales Price (ASP) and Avg. Order Value (AOV) based on ad exposure type including Triple (SP, DSP and STV).',
  },
  tableHeader: {
    heading: 'Sortable Metrics by Ad Exposure Type',
    subHeading: 'Sortable sales data by ad exposure type.',
  },
};

// constant for triple overlap value graphs headings and sub headings
export const ntbAnalysisGraphHeader = {
  BarGraphHeader: {
    heading: 'Top 10 NTB Sales Generators',
    subHeading:
      'What products are receiving the highest new to brand sales across all ad exposure types.',
  },
  LineGraphHeader: {
    heading: 'New to Brand Sales by Month',
    subHeading: 'New to Brand sales trends based on all ad exposure types.',
  },
};

// constant for triple overlap value graphs headings and sub headings
export const ntbGeographicGraphHeader = {
  BarGraphHeader: {
    heading: 'Top 5 States with the highest Avg NTB Order Value',
    subHeading:
      'Top 5 states delivering the highest average new to brand order value.',
  },
  tableHeader: {
    heading: 'Sortable Data by State',
    subHeading: 'Sortable new to brand sales data by state.',
  },
  USAMapHeader: {
    heading: 'New to Brand Sales by Geography',
    subHeading: 'Snapshot view of new to brand sales by state.',
  },
};

// constant for life time value graphs headings and sub headings
export const ltvGraphHeader = {
  LineGraphHeader: {
    heading: 'Lifetime Value (LTV) from New to Brand Customers',
    subHeading:
      'New to brand lifetime value trends by month. Lifetime value includes the value of the new to brand sale and any additional brand halo sales that occur after the new to brand purchase is made.',
  },
  top5BarGraphHeader: {
    heading: 'Top 10 NTB lifetime value generators',
    subHeading:
      'Shows the top 10 products delivering the highest new to brand lifetime value.',
  },
  monthlyBarGraphHeader: {
    heading: 'Lifetime Sales value by Month',
    subHeading: 'Shows the total new to brand lifetime value trends by months.',
  },
};

export const unknownValueInfo =
  'of impressions are classified as unknown due to Amazon privacy restrictions on data, which might cause a slight discrepancy if similar data is pulled from other sources.';

export const analysisOptions = [
  {
    value: 'dsp-ppc-overlap-analysis',
    label: 'DSP-PPC Overlap Analysis',
    graphs: [
      {
        value: 'dsp-ppc-overlap-analysis1',
        label: 'Ad Attributed Sales by Ad Exposure Type',
      },
      {
        value: 'dsp-ppc-overlap-analysis2',
        label: 'Share of Conversions and Sales by ad Exposure Type',
      },
      {
        value: 'dsp-ppc-overlap-analysis3',
        label: 'Conversion Rate by Ad Exposure Type',
      },
    ],
  },
  {
    value: 'triple-overlap-analysis',
    label: 'Triple Overlap Analysis',
    graphs: [
      { value: 'triple-overlap-analysis1', label: 'Triple Overlap Analysis' },
      {
        value: 'triple-overlap-analysis2',
        label: 'Unique Reach by Ad Exposure Type',
      },
      {
        value: 'triple-overlap-analysis3',
        label: 'Conversion Rate by Ad Exposure Type',
      },
    ],
  },
  {
    value: 'triple-overlap-value',
    label: 'Triple Overlap Value',
    graphs: [
      {
        value: 'triple-overlap-value1',
        label: 'ASP and AOV by Ad Exposure type with Triple Overlap',
      },
      {
        value: 'triple-overlap-value3',
        label: 'Sortable Metrics by Ad Exposure Type',
      },
    ],
  },
  {
    value: 'new_to_brand_analysis',
    label: 'New to Brand Analysis',
    graphs: [
      { value: 'new_to_brand_analysis1', label: 'Top 10 NTB Sales Generators' },
      { value: 'new_to_brand_analysis2', label: 'New to Brand Sales by Month' },
    ],
  },
  {
    value: 'ntb_geographic_analysis',
    label: 'NTB Geo Analysis- DSP Only',
    graphs: [
      {
        value: 'ntb_geographic_analysis1',
        label: 'Top 5 States with the highest Avg. NTB Order Value',
      },
      { value: 'ntb_geographic_analysis2', label: 'Sortable Data by State' },
      {
        value: 'ntb_geographic_analysis3',
        label: 'New to Brand Sales by Geography',
      },
    ],
  },
  {
    value: 'lifetime_value',
    label: 'Lifetime Value From NTB',
    graphs: [
      {
        value: 'lifetime_value1',
        label: 'Lifetime Value(LTV) from New to Brand Customers',
      },
      {
        value: 'lifetime_value2',
        label: 'Top 10 NTB lifetime value generators',
      },
    ],
  },
];

export const adExposureDspPpcOptions = [
  { value: 'all', label: 'Ad Exposure Type' },
  { value: 'DSP&SP', label: 'DSP&SP' },
  { value: 'DSP', label: 'DSP' },
  { value: 'SP', label: 'SP' },
];

export const adExposureTripleOverlapOptions = [
  { value: 'all', label: 'Ad Exposure Type' },
  { value: 'DSP', label: 'DSP' },
  { value: 'SP', label: 'SP' },
  { value: 'DSP&SP', label: 'DSP&SP' },
  { value: 'STV&SP', label: 'STV&SP' },
  { value: 'DSP&STV', label: 'DSP&STV' },
  { value: 'DSP&STV&SP', label: 'DSP&STV&SP' },
];

const currentEndMonth = new Date();
const currentStartMonth = new Date();
const getDate = currentEndMonth.getDate();

if (getDate > 17) {
  currentEndMonth.setMonth(currentEndMonth.getMonth() - 1);
  currentStartMonth.setMonth(currentStartMonth.getMonth() - 3);
} else {
  currentEndMonth.setMonth(currentEndMonth.getMonth() - 2);
  currentStartMonth.setMonth(currentStartMonth.getMonth() - 4);
}

const currentStartMonthName = dayjs(currentStartMonth).format('YYYY MMM');
const currentEndMonthName = dayjs(currentEndMonth).format('YYYY MMM');
export const amcDateOptions = [
  {
    value: '12months',
    label: `Recent Months (${currentStartMonthName} - ${currentEndMonthName})`,
  },
  {
    value: 'custom',
    label: 'Custom Range',
    sub: 'Select start and end dates',
  },
];

export const DoubleOverlapAnalysisTableHeaders = [
  { label: 'DSP&SP', value: 'DSP&SP', color: '#f7c448', property: 'dsp-sp' },
  { label: 'SP', value: 'SP', color: '#8d6fca', property: 'sp' },
  {
    label: 'DSP',
    value: 'DSP',
    color: '#407ca5',
    property: 'dsp',
  },
];

export const TripleOverlapAnalysisTableHeaders = [
  { label: 'DSP&SP', value: 'DSP&SP', color: '#f7c448', property: 'dsp-sp' },
  { label: 'SP', value: 'SP', color: '#8d6fca', property: 'sp' },
  {
    label: 'DSP',
    value: 'DSP',
    color: '#407ca5',
    property: 'dsp',
  },
  {
    label: 'DSP&STV&SP',
    value: 'DSP&STV&SP',
    width: '13%',
    color: '#d63649',
    property: 'stv-dsp-sp',
  },
  { label: 'STV', value: 'STV', color: '#81be5a', property: 'stv' },
  { label: 'STV&DSP', value: 'DSP&STV', color: '#0045b4', property: 'stv-dsp' },
  { label: 'STV&SP', value: 'STV&SP', color: '#30a8bd', property: 'stv-sp' },
];

export const LTVTableHeaders = [
  {
    label: 'NTB',
    color: '#f7c137',
    property: 'NTB-sales',
  },
  {
    label: 'LTV',
    color: '#407b00',
    property: 'NTB-sales-lifetime-value',
  },
];

export const TipleOverlapValueTableHeaders = [
  { label: 'Ad Type', key: 'ad_type', order_by: false, width: '10%' },
  { label: 'Exposure', key: 'exposure', order_by: false, width: '10%' },
  { label: 'Unique Reach', key: 'unique_reach', order_by: true, width: '12%' },
  {
    label: 'Total Impressions',
    key: 'total_impressions',
    order_by: true,
    width: '13%',
  },
  { label: 'Total Units', key: 'total_units', order_by: true, width: '10%' },
  {
    label: 'Total Purchases',
    key: 'total_purchases',
    order_by: true,
    width: '12%',
  },
  {
    label: 'Sales Revenue',
    key: 'total_sale',
    order_by: true,
    width: '12%',
  },
  { label: 'ASP', key: 'asp', order_by: true, width: '7%' },
  { label: 'AOV', key: 'aov', order_by: true, width: '7%' },
];

export const TipleOverlapValueMobileTableHeaders = [
  { label: 'Unique Reach', key: 'unique_reach' },
  { label: 'Total Impressions', key: 'total_impressions' },
  { label: 'Total Units', key: 'total_units' },
  { label: 'Total Purchases', key: 'total_purchases' },
  { label: 'Sales Revenue', key: 'total_sale' },
  { label: 'ASP', key: 'asp' },
  { label: 'AOV', key: 'aov' },
];

export const ntbGeographicTableHeaders = [
  { label: 'STATES', key: 'state', order_by: false },
  { label: 'NTB ORDERS', key: 'ntb_orders', order_by: true },
  { label: 'NTB SALES', key: 'ntb_sales', order_by: true },
  { label: 'NTB Units', key: 'ntb_units', order_by: true },
  { label: 'NTB AOV', key: 'ntb_aov', order_by: true },
];

export const ntbGeographicMobileTableHeaders = [
  { label: 'NTB ORDERS', key: 'ntb_orders' },
  { label: 'NTB SALES', key: 'sales' },
  { label: 'NTB AOV', key: 'ntb_aov' },
];

export const USAStateNames = [
  { state: 'Alabama', key: 'US-AL' },
  { state: 'Alaska', key: 'US-AK' },
  { state: 'Arizona', key: 'US-AZ' },
  { state: 'Arkansas', key: 'US-AR' },
  { state: 'California', key: 'US-CA' },
  { state: 'Colorado', key: 'US-CO' },
  { state: 'Connecticut', key: 'US-CT' },
  { state: 'Delaware', key: 'US-DE' },
  { state: 'District of Columbia', key: 'US-DC' },
  { state: 'Florida', key: 'US-FL' },
  { state: 'Georgia', key: 'US-GA' },
  { state: 'Hawaii', key: 'US-HI' },
  { state: 'Idaho', key: 'US-ID' },
  { state: 'Illinois', key: 'US-IL' },
  { state: 'Indiana', key: 'US-IN' },
  { state: 'Iowa', key: 'US-IA' },
  { state: 'Kansas', key: 'US-KS' },
  { state: 'Kentucky', key: 'US-KY' },
  { state: 'Louisiana', key: 'US-LA' },
  { state: 'Maine', key: 'US-ME' },
  { state: 'Maryland', key: 'US-MD' },
  { state: 'Massachusetts', key: 'US-MA' },
  { state: 'Michigan', key: 'US-MI' },
  { state: 'Minnesota', key: 'US-MN' },
  { state: 'Mississippi', key: 'US-MS' },
  { state: 'Missouri', key: 'US-MO' },
  { state: 'Montana', key: 'US-MT' },
  { state: 'Nebraska', key: 'US-NE' },
  { state: 'Nevada', key: 'US-NV' },
  { state: 'New Hampshire', key: 'US-NH' },
  { state: 'New Jersey', key: 'US-NJ' },
  { state: 'New Mexico', key: 'US-NM' },
  { state: 'New York', key: 'US-NY' },
  { state: 'North Carolina', key: 'US-NC' },
  { state: 'North Dakota', key: 'US-ND' },
  { state: 'Ohio', key: 'US-OH' },
  { state: 'Oklahoma', key: 'US-OK' },
  { state: 'Oregon', key: 'US-OR' },
  { state: 'Pennsylvania', key: 'US-PA' },
  { state: 'Rhode Island', key: 'US-RI' },
  { state: 'South Carolina', key: 'US-SC' },
  { state: 'South Dakota', key: 'US-SD' },
  { state: 'Tennessee', key: 'US-TN' },
  { state: 'Texas', key: 'US-TX' },
  { state: 'Utah', key: 'US-UT' },
  { state: 'Vermont', key: 'US-VT' },
  { state: 'Virginia', key: 'US-VA' },
  { state: 'Washington', key: 'US-WA' },
  { state: 'West Virginia', key: 'US-WV' },
  { state: 'Wisconsin', key: 'US-WI' },
  { state: 'Wyoming', key: 'US-WY' },
];

// Start DSP Audience constants

export const secondLevelNavBar = [
  { label: 'Standard', value: 'standard' },
  { label: 'Advanced', value: 'advanced' },
  { label: 'Customized', value: 'customized' },
  { label: 'AMC Audience', value: 'dsp-audience' },
  { label: 'Sample', value: 'sample' },
];

export const secondLevelNavBarForCustomer = [
  { label: 'Standard', value: 'standard' },
  { label: 'Advanced', value: 'advanced' },
  { label: 'Customized', value: 'customized' },
  // { label: 'DSP Audience' },
  // { label: 'Sample', value: 'sample' },
];

export const DSPAudienceHeaders = [
  {
    label: 'Advertiser ID',
    charLimit: 30,
    type: 'text',
    key: 'advertiser_id',
    autoPopulatedField: true,
  },
  {
    label: 'Audience Description',
    charLimit: 50,
    type: 'text',
    key: 'description',
  },
  { label: 'Audience Name', charLimit: 50, type: 'text', key: 'name' },
  {
    label: 'Created Time',
    type: 'date',
    key: 'created_at',
    info: 'Date and Time will be converted in MST timezone.',
    autoPopulatedField: true,
  },
  {
    label: 'Instance ID',
    charLimit: 30,
    type: 'text',
    key: 'instance_id',
    autoPopulatedField: true,
  },
  { label: 'Query Name', type: 'choice', charLimit: 500, key: 'query' },
  { label: 'Refresh Time Window', type: 'radio', key: 'refresh_time_window' },
  {
    label: 'Refresh Rate Days',
    type: 'choice',
    key: 'refresh_rate_days',
    info: 'If refresh rate days is set to 0, the created audience will automatically be set to INACTIVE after 30 days.',
  },

  {
    label: 'Time Window Start',
    type: 'date',
    key: 'time_window_start',
    info: 'Date and Time will be converted in MST timezone.',
  },
  {
    label: 'Time Window End',
    type: 'date',
    key: 'time_window_end',
    info: 'Date and Time will be converted in MST timezone.',
  },
  { label: 'Status', type: 'status', key: 'status' },
];

export const refreshRateDaysOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
];

// audience expansion type options
export const audienceExpansionTypeOptions = [
  {
    label: 'Most Similar',
    value: 'MOST_SIMILAR',
    tooltipInfo:
      'Most Similar: The most similar audience will have between 500K & 1MM members.',
  },
  {
    label: 'Similar',
    value: 'SIMILAR',
    tooltipInfo:
      'Similar: The size of this audience will be between 1.5MM-2.5MM, and will include "Most similar" lookalike population.',
  },
  {
    label: 'Balanced',
    value: 'BALANCED',
    tooltipInfo:
      'Balanced: The size of this audience will be between 2MM-5MM, and will include "Similar" lookalike population.',
  },
  {
    label: 'Broad',
    value: 'BROAD',
    tooltipInfo:
      'Broad: The size of this audience will be between 2.5MM-7.5MM, and will include "Balanced" lookalike population.',
  },
  {
    label: 'Most Broad',
    value: 'MOST_BROAD',
    tooltipInfo:
      'Most Broad: This size of this audience will be between 3MM-10MM, and will include "Broad" lookalike population.',
  },
];

export const AMCDashboardNav = [
  { label: 'BBE-AMC Summary', value: 'amc-summary' },
  { label: 'Spreetail-AMC Summary tab', value: 'spreetail-amc-summary-tab' },
  { label: 'AMC Audience Summary', value: 'amc-audience-summary' },
  { label: 'Sample', value: 'sample' },
];

export const orderByFilterOptions = [
  {
    label: 'Most Recent to Oldest',
    value: '-created_at',
  },
  {
    label: 'Most Oldest to Recent',
    value: 'created_at',
  },
];

export const statusFilterOptions = [
  {
    label: 'All Statuses',
    value: 'all',
  },
  {
    label: 'Started',
    value: 'started',
  },
  {
    label: 'Running',
    value: 'running',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
  {
    label: 'Successful',
    value: 'successful',
  },
];

export const timeFrameOptionsOfAMCAudience = [
  {
    value: 'current_month',
    label: 'Current Month',
    sub: '',
    isDisabled: false,
  },
  {
    value: 'month',
    label: 'Current Month',
    sub: 'vs Previous month',
    isDisabled: false,
  },
  {
    value: 'current_week',
    label: 'Current Week',
    sub: '',
    isDisabled: false,
  },
  {
    value: 'week',
    label: 'Current Week',
    sub: 'vs Previous Week',
    isDisabled: false,
  },
  {
    value: 'custom',
    label: 'Custom Range',
    sub: 'Select start and end dates',
  },
];

export const AMCAudienceSummaryHeaders = [
  {
    label: 'BP Name',
    charLimit: 30,
    type: 'text',
    key: 'bp_name',
  },
  {
    label: 'Advertiser ID',
    charLimit: 30,
    type: 'text',
    key: 'advertiser_id',
    autoPopulatedField: true,
  },
  {
    label: 'Audience Description',
    charLimit: 50,
    type: 'text',
    key: 'description',
  },
  { label: 'Audience Name', charLimit: 50, type: 'text', key: 'name' },
  {
    label: 'Created Time',
    type: 'date',
    key: 'created_at',
    info: 'Date and Time will be converted in MST timezone.',
    autoPopulatedField: true,
  },
  {
    label: 'Instance ID',
    charLimit: 30,
    type: 'text',
    key: 'instance_id',
    autoPopulatedField: true,
  },
  { label: 'Query Name', type: 'choice', charLimit: 500, key: 'query' },
  { label: 'Refresh Time Window', type: 'radio', key: 'refresh_time_window' },
  {
    label: 'Refresh Rate Days',
    type: 'choice',
    key: 'refresh_rate_days',
    info: 'If refresh rate days is set to 0, the created audience will automatically be set to INACTIVE after 30 days.',
  },
  {
    label: 'Time Window Start',
    type: 'date',
    key: 'time_window_start',
    info: 'Date and Time will be converted in MST timezone.',
  },
  {
    label: 'Time Window End',
    type: 'date',
    key: 'time_window_end',
    info: 'Date and Time will be converted in MST timezone.',
  },
  { label: 'Status', type: 'status', key: 'status' },
];

export const AMCAudienceMetricsConstants = [
  {
    className: 'pr-1 pl-0',
    metricsId: 'BT-AmcAudience-All',
    metricsLabel: 'Total No. of AMC Audiences',
    metricsKey: 'total_dsp_audience',
    prefix: '',
    suffix: '',
    decimalDigits: 0,
  },
  {
    className: 'pr-0 pr-md-1 pl-1',
    metricsId: 'BT-AmcAudience-Active',
    metricsLabel: 'Total No. of Active AMC Audiences',
    metricsKey: 'total_active_dsp_audience',
    prefix: '',
    suffix: '',
    decimalDigits: 0,
  },
  {
    className: 'pr-1 pl-0 pl-md-1',
    metricsId: 'BT-AmcAudience-Failed',
    metricsLabel: 'Total No. of Failed AMC Audiences',
    metricsKey: 'total_failed_dsp_audience',
    prefix: '',
    suffix: '',
    decimalDigits: 0,
  },
];
