import styled from 'styled-components';
import Theme from '../theme/Theme';

const CheckBox = styled.div`
  .check-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    color: ${Theme.gray90};
    font-size: ${Theme.normal};

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.customer-pannel {
      color: ${Theme.gray85};
      font-size: ${Theme.extraNormal};
    }

    &.font-weight-medium {
      font-family: ${Theme.baseMediumFontFamily}
      color: ${Theme.black};
      font-size: ${Theme.extraNormal};
      width: fit-content;
    }
    &.font-weight-regular {
      font-weight: 300;
      color: ${Theme.black};
      font-size: ${Theme.extraNormal};
      width: fit-content;
    }

    &.term-condition {
      font-size: ${Theme.extraSmall};
    }

    &.contract-sign {
      color: ${Theme.gray35};
    }
    &.selected-list {
      color: ${Theme.gray35};
      font-size: ${Theme.extraNormal};
    }
    &.additional-place {
      color: ${Theme.black};
      font-size: ${Theme.textFontSize};
      font-family: ${Theme.regularArialTextFontFamily};
    }

    &.is-option-disabled {
      cursor: not-allowed;
    }
  }

  &.selected-img {
    .check-container {
      padding-left: 0px;
    }
    .checkmark {
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 2;
      width: 30px;
      height: 30px;
    }
    .check-container .checkmark:after {
      left: 11px;
      top: 4px;
      width: 7px;
      height: 17px;
    }
  }

  .check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    width: 20px;
    background-color: ${Theme.white};
    height: 20px;
    border: 1px solid ${Theme.gray35};

    &.checkmark-small {
      width: 16px;
      height: 16px;
    }
     &.checkmark-small:after {
      left: 5px !important;
      top: 3px !important;
      width: 5px !important;
      height: 9px !important;
    }

    &.left-content {
      right: 20px;
      width: 25px;
      height: 25px;
      left: unset;
    }
  }

  .check-container:hover input ~ .checkmark {
    background-color: ${Theme.white};
  }

  .check-container input:checked ~ .checkmark {
    background-color: ${Theme.orange};
    color: white;
    border: none;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .check-container input:checked ~ .checkmark:after {
    display: block;
  }

  .check-container 
  .checkmark:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid ${Theme.white};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .checkmark-large:after {
    left: 9px !important;
    top: 4px !important;
    width: 7px !important;
    height: 14px !important;
  }
`;

export default CheckBox;
