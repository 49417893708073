/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Collapse } from 'react-collapse';
import {
  bool,
  number,
  func,
  arrayOf,
  shape,
  string,
  oneOfType,
  instanceOf,
} from 'prop-types';

import { Advertise } from '../../../../theme/images';
import { DSPAddendumDetails } from '../../../../constants';
import {
  Button,
  InputFormField,
  ContractInputSelect,
  CheckBox,
} from '../../../../common';
import AdditionalDspMarketplaces from './AdditionalDspMarketplaces';
import DspMarketplaceBudgetAllocations from './DspMarketplaceBudgetAllocations';
import MonthlyDSPBudgetAllocation from './MonthlyDSPBudgetAllocation';
import AdvertisingServices from '../StatementOfWork/AdvertisingServices';

function DspAdvertising({
  executeScroll,
  loader,
  showSection,
  setOpenCollapse,
  openCollapse,
  dspErrCount,
  renderCollapseBtnErrorHtml,
  showRightTick,
  generateHTML,
  displayError,
  formData,
  nextStep,
  dspAdditionalMarketplaces,
  showFooter,
  setFormData,
  setUpdatedFormData,
  updatedFormData,
  originalData,
  sectionError,
  setSectionError,
  contractAddendumDetails,
  addendumDetails,
  setCalculatedDate,
  setFirstMonthDate,
  selectedCurrency,
  runValidateBudget,
  setDspAdvertisingMaxDate,
  changedDspStartDateFlag,
  setChangedDspStartDateFlag,
  dspMarketpalcesError,
  setDspMarketpalcesError,
  displayMarketplaceName,
  savedMarketplaces,
  returnDspMonthlyDates,
  firstMonthDate,
  secondMonthDate,
  thirdMonthDate,
  advertisingServices,
  details,
  setShowCollpase,
  amcServiceError,
  setAmcServiceError,
  onAddDiscount,
  discountData,
}) {
  const dynamicMonthlyBudget = [
    {
      key: 'dsp_fee',
      label: !formData?.changed_dsp_start_date
        ? dayjs(firstMonthDate).format('MMMM YYYY')
        : returnDspMonthlyDates(new Date(firstMonthDate), 1),
      type: 'number-currency',
      placeholder: `Enter ${dayjs(
        !formData?.changed_dsp_start_date
          ? dayjs(firstMonthDate).format('MMMM YYYY')
          : returnDspMonthlyDates(new Date(firstMonthDate), 1),
      ).format('MMMM')}`,
      isMandatory: true,
      part: 'dsp',
      isDynamicMonthlyBudget: true,
    },
    {
      key: 'dsp_fee_second',
      label: !formData?.changed_dsp_start_date
        ? dayjs(secondMonthDate).format('MMMM YYYY')
        : returnDspMonthlyDates(new Date(secondMonthDate), 1),

      type: 'number-currency',
      placeholder: `Enter ${dayjs(
        !formData?.changed_dsp_start_date
          ? dayjs(secondMonthDate).format('MMMM YYYY')
          : returnDspMonthlyDates(new Date(secondMonthDate), 1),
      ).format('MMMM')}`,
      isMandatory: true,
      part: 'dsp',
      isDynamicMonthlyBudget: true,
    },
    {
      key: 'dsp_fee_third',
      label: !formData?.changed_dsp_start_date
        ? dayjs(thirdMonthDate).format('MMMM YYYY')
        : returnDspMonthlyDates(new Date(thirdMonthDate), 1),

      type: 'number-currency',
      placeholder: `Enter ${dayjs(
        !formData?.changed_dsp_start_date
          ? dayjs(thirdMonthDate).format('MMMM YYYY')
          : returnDspMonthlyDates(new Date(thirdMonthDate), 1),
      ).format('MMMM')}`,
      isMandatory: true,
      part: 'dsp',
      isDynamicMonthlyBudget: true,
    },
  ];

  const setDspFields = () => {
    const list = [...DSPAddendumDetails];
    if (
      formData?.contract_type?.includes('dsp') &&
      !list.find((item) => item?.key === 'dsp_amc_additional_serivces')
    ) {
      DSPAddendumDetails.push({
        key: 'dsp_amc_additional_serivces',
        label: 'AMC Services',
        type: '',
        // isMandatory: true,
        part: 'dsp',
      });
    }
    return list;
  };

  const contractType = formData?.contract_type;
  const [updatedDspDetails, setupdatedDspDetails] = useState(setDspFields());
  const [showError, setShowError] = useState({
    monthly: false,
    oneTime: false,
  });
  const d = new Date();
  const currentMonthsFirstDate = new Date(d.getFullYear(), d.getMonth(), 1);

  useEffect(() => {
    const updatedDSPAddendumDetails = [...DSPAddendumDetails];
    if (formData?.changed_dsp_start_date) {
      updatedDSPAddendumDetails.splice(2, 0, {
        key: 'dsp_one_time_fee',
        label: 'One Time Ad Budget',
        type: 'number-currency',
        placeholder: 'Enter budget',
        isMandatory: true,
        part: 'dsp',
        zeroAcceptableField: true,
      });
    }
    if (formData?.is_dynamic_dsp_fee) {
      if (formData?.changed_dsp_start_date) {
        updatedDSPAddendumDetails.splice(5, 0, ...dynamicMonthlyBudget);
      } else {
        updatedDSPAddendumDetails.splice(4, 0, ...dynamicMonthlyBudget);
      }
    }
    setupdatedDspDetails(updatedDSPAddendumDetails);
  }, [
    formData?.changed_dsp_start_date,
    formData?.is_dynamic_dsp_fee,
    firstMonthDate,
    secondMonthDate,
    thirdMonthDate,
  ]);

  // New Logic for Dsp Date
  // 1-20 , then: 1st of next month
  // 20-31 , then:  1st of next to next month

  const addDaysNew = (theDate) => {
    const tempStartDate = theDate.getDate();
    if (tempStartDate >= 1 && tempStartDate <= 20) {
      return new Date(theDate.getFullYear(), theDate.getMonth() + 1, 1);
    }
    return new Date(theDate.getFullYear(), theDate.getMonth() + 2, 1);
  };

  const calculateDspAdvertisingMaxDate = () => {
    const tempLastDate = addDaysNew(new Date(formData && formData.start_date));
    tempLastDate.setHours(-1);
    setDspAdvertisingMaxDate(tempLastDate);
  };

  useEffect(() => {
    if (formData?.start_date) {
      calculateDspAdvertisingMaxDate();
    }
  }, [formData?.start_date]);

  useEffect(() => {
    const tempLastDate = addDaysNew(new Date(formData && formData.start_date));
    if (
      formData?.changed_dsp_start_date &&
      new Date(tempLastDate) < new Date()
    ) {
      const tempLastDSPDate = addDaysNew(new Date());
      tempLastDSPDate.setHours(-1);
      setDspAdvertisingMaxDate(tempLastDSPDate);
    }
  }, [formData?.changed_dsp_start_date]);

  useEffect(() => {
    if (
      formData?.contract_type === 'dsp only' ||
      (formData?.contract_type?.includes('recurring') &&
        formData?.is_dsp_advertising)
    ) {
      if (formData?.start_date && !formData?.changed_dsp_start_date) {
        const calculateDate =
          new Date(formData && formData.start_date) < currentMonthsFirstDate
            ? addDaysNew(new Date())
            : addDaysNew(new Date(formData && formData.start_date));
        setCalculatedDate(dayjs(calculateDate).format('MM-DD-YYYY'));

        if (!formData?.dsp_start_date) {
          const newDate =
            contractAddendumDetails?.notActive ||
            new Date(formData && formData.start_date) < currentMonthsFirstDate
              ? addDaysNew(new Date())
              : calculateDate;
          setFirstMonthDate(newDate);
          setFormData({
            ...formData,
            dsp_start_date: dayjs(newDate).format('YYYY-MM-DD'),
          });
          setUpdatedFormData({
            ...updatedFormData,
            dsp_start_date: dayjs(newDate).format('YYYY-MM-DD'),
          });
          showFooter(true);
        } else if (
          formData?.dsp_start_date !== dayjs(calculateDate).format('YYYY-MM-DD')
        ) {
          setFirstMonthDate(calculateDate);
          setFormData({
            ...formData,
            dsp_start_date: dayjs(calculateDate).format('YYYY-MM-DD'),
          });
          setUpdatedFormData({
            ...updatedFormData,
            dsp_start_date: dayjs(calculateDate).format('YYYY-MM-DD'),
          });
          showFooter(true);
        }
      }

      // when we checked change_advertising_strat_date
      if (formData?.changed_dsp_start_date && changedDspStartDateFlag) {
        setFirstMonthDate(new Date());
        setFormData({
          ...formData,
          dsp_start_date: dayjs(new Date()).format('YYYY-MM-DD'),
        });
        setUpdatedFormData({
          ...updatedFormData,
          dsp_start_date: dayjs(new Date()).format('YYYY-MM-DD'),
        });
      }
    }
  }, [
    formData?.start_date,
    formData?.changed_dsp_start_date,
    formData?.additional_monthly_services,
    changedDspStartDateFlag,
  ]);

  const handleCheckboxChange = (event, item) => {
    setFormData({
      ...formData,
      [item.key]: event.target.checked,
    });
    setUpdatedFormData({
      ...updatedFormData,
      [item.key]: event.target.checked,
    });
    if (item?.key === 'changed_dsp_start_date') {
      setChangedDspStartDateFlag(event.target.checked);
      if (!event.target.checked) {
        if (formData?.dsp_marketplaces?.length) {
          const marketplaces = [...formData?.dsp_marketplaces];
          const removeOneTime = marketplaces.map(
            ({ one_time_budget, ...rest }) => {
              return rest;
            },
          );
          setFormData({
            ...formData,
            dsp_one_time_fee: null,
            dsp_marketplaces: removeOneTime,
            [item.key]: event.target.checked,
          });
          setUpdatedFormData({
            ...updatedFormData,
            dsp_one_time_fee: null,
            [item.key]: event.target.checked,
          });
        }
      }
    }
    if (item?.key === 'is_dynamic_dsp_fee') {
      setFormData({
        ...formData,
        [item.key]: event.target.checked,
      });
      setUpdatedFormData({
        ...updatedFormData,
        [item.key]: event.target.checked,
      });
    }
    showFooter(true);
  };

  return loader ? null : contractType?.toLowerCase()?.includes('dsp') ||
    (showSection?.dspAddendum &&
      !contractType?.toLowerCase()?.includes('one')) ? (
    <>
      <div className="straight-line horizontal-line  " />

      <div
        className="collapse-btn "
        role="presentation"
        type="button"
        onClick={() => {
          if (contractAddendumDetails?.notActive)
            executeScroll('draft-addendum');
          else executeScroll('dspAddendum');
          setOpenCollapse({
            dspAddendum: !openCollapse.dspAddendum,
          });
        }}
      >
        <img className="service-agre" src={Advertise} alt="pdf" />
        <h4
          className={
            sectionError?.dsp
              ? 'sendar-details error-container '
              : 'sendar-details'
          }
        >
          DSP Advertising
          {renderCollapseBtnErrorHtml(
            false,
            dspErrCount,
            openCollapse.dspAddendum,
            'dspAddendum',
          )}
        </h4>
        <div className="clear-fix" />
      </div>

      <Collapse isOpened={openCollapse.dspAddendum}>
        <ul className={`collapse-inner `}>
          {updatedDspDetails?.map((item) =>
            item.key === 'dsp_marketplaces' ? (
              <>
                <li>
                  <ContractInputSelect>
                    <AdditionalDspMarketplaces
                      dspAdditionalMarketplaces={dspAdditionalMarketplaces}
                      formData={formData}
                      item={item}
                      showFooter={showFooter}
                      setFormData={setFormData}
                      setUpdatedFormData={setUpdatedFormData}
                      updatedFormData={updatedFormData}
                      originalData={originalData}
                      sectionError={sectionError}
                      setSectionError={setSectionError}
                      contractAddendumDetails={contractAddendumDetails}
                      addendumDetails={addendumDetails}
                      dspMarketpalcesError={dspMarketpalcesError}
                      setDspMarketpalcesError={setDspMarketpalcesError}
                      displayMarketplaceName={displayMarketplaceName}
                      savedMarketplaces={savedMarketplaces}
                    />
                  </ContractInputSelect>
                </li>
                {formData?.dsp_fee ||
                formData?.dsp_fee_second ||
                formData?.dsp_fee_third ? (
                  <li>
                    <MonthlyDSPBudgetAllocation
                      item={item}
                      formData={formData}
                      type="monthly"
                      selectedCurrency={selectedCurrency}
                      setSectionError={setSectionError}
                      sectionError={sectionError}
                      showError={showError}
                      setShowError={setShowError}
                      updatedFormData={updatedFormData}
                      setUpdatedFormData={setUpdatedFormData}
                      runValidateBudget={runValidateBudget}
                      showFooter={showFooter}
                      setFormData={setFormData}
                      showRightTick={showRightTick}
                      dspMarketpalcesError={dspMarketpalcesError}
                      setDspMarketpalcesError={setDspMarketpalcesError}
                      displayMarketplaceName={displayMarketplaceName}
                      firstMonthDate={firstMonthDate}
                      secondMonthDate={secondMonthDate}
                      thirdMonthDate={thirdMonthDate}
                      returnDspMonthlyDates={returnDspMonthlyDates}
                    />
                  </li>
                ) : null}
                {formData?.changed_dsp_start_date &&
                formData?.dsp_one_time_fee ? (
                  <li>
                    <DspMarketplaceBudgetAllocations
                      item={item}
                      formData={formData}
                      type="oneTime"
                      selectedCurrency={selectedCurrency}
                      setSectionError={setSectionError}
                      sectionError={sectionError}
                      showError={showError}
                      setShowError={setShowError}
                      updatedFormData={updatedFormData}
                      setUpdatedFormData={setUpdatedFormData}
                      runValidateBudget={runValidateBudget}
                      showFooter={showFooter}
                      setFormData={setFormData}
                      showRightTick={showRightTick}
                      dspMarketpalcesError={dspMarketpalcesError}
                      setDspMarketpalcesError={setDspMarketpalcesError}
                      displayMarketplaceName={displayMarketplaceName}
                    />
                  </li>
                ) : null}
              </>
            ) : item.type === 'checkbox' ? (
              <li>
                <CheckBox>
                  <label
                    className="check-container customer-pannel"
                    htmlFor={item.key}
                  >
                    {item.label}
                    <input
                      type="checkbox"
                      name={item.key}
                      id={item.key}
                      checked={formData && formData?.[item.key]}
                      onChange={(event) => {
                        handleCheckboxChange(event, item);
                      }}
                    />
                    <span className="checkmark" />
                  </label>
                </CheckBox>
              </li>
            ) : item?.isDynamicMonthlyBudget ? (
              <li>
                <InputFormField key={item.key}>
                  <div className="row">
                    <div className="col-4 pr-0 mt-3">
                      <label className="quartely-threshold" htmlFor={item?.key}>
                        {item.label}
                      </label>
                    </div>
                    <div className="col-8">
                      <div className="input-container">
                        <span className="input-icon">{selectedCurrency} </span>
                        {generateHTML(item)}
                      </div>
                    </div>
                  </div>
                </InputFormField>
              </li>
            ) : item?.key === 'dsp_amc_additional_serivces' ? (
              <li>
                <AdvertisingServices
                  advertisingDashboards={advertisingServices}
                  formData={formData}
                  setFormData={setFormData}
                  updatedFormData={updatedFormData}
                  setUpdatedFormData={setUpdatedFormData}
                  selectedCurrency={selectedCurrency}
                  contractAddendumDetails={contractAddendumDetails}
                  details={details}
                  setShowCollpase={setShowCollpase}
                  showSection={showSection}
                  showFooter={showFooter}
                  amcServiceError={amcServiceError}
                  setAmcServiceError={setAmcServiceError}
                  onAddDiscount={onAddDiscount}
                  discountData={discountData}
                />
              </li>
            ) : (
              <li key={item.key}>
                <InputFormField>
                  <label htmlFor={item.key}>{item.label}</label>
                  {generateHTML(item, 'dsp')}
                  {displayError(item, 'dsp')}
                </InputFormField>
                <p className="m-0  pt-1 small-para">
                  {item.info ? item.info : ''}
                </p>
              </li>
            ),
          )}

          <li>
            <Button
              className={
                formData.additional_one_time_services
                  ? 'btn-primary btn-next-section sidepanel mt-1 mb-3 w-100 '
                  : 'btn-primary btn-next-section  mt-1 mb-3 w-100 '
              }
              disabled={!showRightTick('dspAddendum')}
              onClick={() => nextStep('addendum')}
            >
              Proceed to Next Section
            </Button>
          </li>
        </ul>
      </Collapse>
    </>
  ) : (
    ''
  );
}

export default DspAdvertising;

DspAdvertising.defaultProps = {
  executeScroll: () => {},
  loader: false,
  showSection: {},
  setOpenCollapse: () => {},
  openCollapse: {},
  dspErrCount: 0,
  renderCollapseBtnErrorHtml: () => {},
  showRightTick: () => {},
  generateHTML: () => {},
  displayError: () => {},
  formData: {},
  nextStep: () => {},
  contractAddendumDetails: {},
  details: {},
  selectedCurrency: '$ (USD)',
  runValidateBudget: {},
  setDspAdvertisingMaxDate: () => {},
  changedDspStartDateFlag: false,
  setChangedDspStartDateFlag: () => {},
  dspMarketpalcesError: {},
  setDspMarketpalcesError: () => {},
  dspAdvertisingMaxDate: '',
  displayMarketplaceName: () => {},
  savedMarketplaces: [],
};
DspAdvertising.propTypes = {
  executeScroll: func,
  loader: bool,
  showSection: shape({
    addendum: bool,
    dspAddendum: bool,
  }),
  setOpenCollapse: func,
  openCollapse: shape({
    agreement: bool,
    statement: bool,
    addendum: bool,
    dspAddendum: bool,
  }),
  dspErrCount: number,
  renderCollapseBtnErrorHtml: func,
  showRightTick: func,
  generateHTML: func,
  displayError: func,
  formData: shape({
    additional_one_time_services: arrayOf(shape({})),
  }),
  nextStep: func,
  contractAddendumDetails: shape({}),
  details: shape({}),
  selectedCurrency: string,
  runValidateBudget: shape({}),
  setDspAdvertisingMaxDate: func,
  changedDspStartDateFlag: bool,
  setChangedDspStartDateFlag: func,
  dspMarketpalcesError: shape({}),
  setDspMarketpalcesError: func,
  dspAdvertisingMaxDate: oneOfType([string, instanceOf(Date)]),
  displayMarketplaceName: func,
  savedMarketplaces: arrayOf(shape({})),
};
