import React from 'react';

import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import { number, func, arrayOf, shape, oneOfType, string } from 'prop-types';

import { InputFormField, CheckBox } from '../../../../common';
import { PlusIcon, MinusIcon } from '../../../../theme/images';
import { checkDisabledConditionForServices } from '../../../../common/ConditionsForStatementOfWork';
import MonthlyServicesSubTabs from './MonthlyServicesSubTabs';

function AdditionalMonthlyServices({
  onAddDiscount,
  formData,
  monthlyService,
  accountType,
  showFooter,
  setShowCollpase,
  showSection,
  originalData,
  additionalMonthlyServices,
  setFormData,
  updateAdditionalMonthlyServices,
  setMonthlyAdditionalServices,
  fetchUncommonOptions,
  discountData,
  updatedFormData,
  setUpdatedFormData,
  contractAddendumDetails,
  addendumDetails,
}) {
  const handleChange = (event, key, type, val) => {
    showFooter(true);

    const comparableArray = contractAddendumDetails?.notActive
      ? addendumDetails
      : originalData;

    if (key === 'additional_monthly_services') {
      const itemInFormData =
        comparableArray?.additional_monthly_services?.length &&
        comparableArray.additional_monthly_services?.find(
          (item) => item && item?.service && item?.service?.id === val?.id,
        );
      if (type === 'quantity') {
        const selectedItem = formData?.additional_monthly_services?.filter(
          (item) => {
            if (
              item.name
                ? item.name === event.target.name
                : item.service.name === event.target.name
            ) {
              if (event.target.value) {
                item.quantity = parseInt(event.target.value, 10);
              } else {
                item.quantity = 1;
              }
            }
            return item;
          },
        );

        if (selectedItem) {
          setFormData({
            ...formData,
            additional_monthly_services: selectedItem,
          });

          setUpdatedFormData({
            ...updatedFormData,
            additional_monthly_services: {
              ...updatedFormData?.additional_monthly_services,
              create: selectedItem,
            },
          });
        }
      }

      // if item checked
      else if (event.target.checked) {
        if (event.target.name === 'DSP Advertising') {
          setShowCollpase({ ...showSection, dspAddendum: true });
        }
        // checked whether checked item present in newly created list
        if (
          additionalMonthlyServices?.create?.length &&
          additionalMonthlyServices.create.find((item) =>
            item?.service_id
              ? item?.service_id === val?.id
              : item?.service?.id === val?.id,
          )
        ) {
          // if checked item is already present in newly created list then don't do anything
        } else {
          // if checked item not found in newly created list then  again check whether it is present in original formData variable because if it is found in formData then we need to add that found item in newly created list bcoz we need id and all of that item to push in newly created list.
          // here we check whether checked item present in orginal formDAta list then add that found item in newly created list
          if (itemInFormData) {
            additionalMonthlyServices.create.push(itemInFormData);
            const list = formData.additional_monthly_services;
            list.push(itemInFormData);
            setFormData({
              ...formData,
              additional_monthly_services: list,
            });

            updateAdditionalMonthlyServices();
          }
          // else we create dict as BE required for new item and we push that in newly created list
          else {
            const newData = {
              name: event.target.name,
              service_id: val?.id,
              contract_id: originalData?.id,
              account_type: accountType,
            };

            const addendumNewData = {
              name: event.target.name,
              service_id: val?.id,
              contract_addendum_id: contractAddendumDetails?.id,
              account_type: accountType,
            };

            if (event.target.name === 'A+ Content') {
              newData.quantity = 1;
              addendumNewData.quantity = 1;
            }
            additionalMonthlyServices.create.push(
              contractAddendumDetails?.notActive ? addendumNewData : newData,
            );

            let list = formData.additional_monthly_services;
            if (!list) {
              list = [];
            }
            list.push(
              contractAddendumDetails?.notActive ? addendumNewData : newData,
            );
            setFormData({
              ...formData,
              additional_monthly_services: list,
            });

            updateAdditionalMonthlyServices();
          }
          // here we fnally update state variable
          setMonthlyAdditionalServices({
            ...additionalMonthlyServices,
          });
        }
        // suppose checked item present in original formData then we have to remove its id from newly created delete list.
        if (itemInFormData) {
          const updatedDeleteList = additionalMonthlyServices.delete?.filter(
            (item) => item !== itemInFormData.id,
          );
          additionalMonthlyServices.delete = updatedDeleteList;
        }

        setMonthlyAdditionalServices({
          ...additionalMonthlyServices,
        });

        fetchUncommonOptions(
          monthlyService,
          additionalMonthlyServices.create,
          'monthly_service',
        );
      }
      // if item unchecked or removed
      else {
        // if unchecked item found in original list then add its id to newly created delte list
        if (itemInFormData) {
          additionalMonthlyServices.delete.push(itemInFormData.id);
        }
        // now we filter newly created list with removed unchecked item from it
        const updatedCreateList = additionalMonthlyServices.create?.filter(
          (item) =>
            item?.service_id
              ? item?.service_id !== val?.id
              : item?.service?.id !== val?.id,
        );

        additionalMonthlyServices.create = updatedCreateList;

        const list = formData.additional_monthly_services;
        const deletedUncheckedItemList = list?.filter((item) =>
          item?.service_id
            ? item?.service_id !== val?.id
            : item?.service?.id !== val?.id,
        );

        setFormData({
          ...formData,
          additional_monthly_services: deletedUncheckedItemList,
        });
        updateAdditionalMonthlyServices();
        setMonthlyAdditionalServices({
          ...additionalMonthlyServices,
        });

        if (event.target.name === 'DSP Advertising') {
          if (
            additionalMonthlyServices?.create?.length &&
            additionalMonthlyServices.create.find((item) =>
              item?.name
                ? item?.name === 'DSP Advertising'
                : item?.service?.name === 'DSP Advertising',
            )
          ) {
            //
          } else {
            setShowCollpase({ ...showSection, dspAddendum: false });
          }
        }
        fetchUncommonOptions(
          monthlyService,
          additionalMonthlyServices.create,
          'monthly_service',
        );
      }
    }
  };

  const showDiscountLabel = () => {
    const discount =
      discountData?.length &&
      discountData?.filter(
        (item) =>
          item?.service_type === 'monthly service' &&
          item?.account_type === accountType,
      );
    if (discount?.length && discount[0]?.type) {
      return 'Edit Discount';
    }
    return 'Add Discount';
  };
  const changeQuantity = (serviceData, flag) => {
    showFooter(true);

    if (formData?.additional_monthly_services?.length) {
      const changedService = formData?.additional_monthly_services?.filter(
        (item) => {
          if (
            item.service_id
              ? item.service_id === serviceData.id
              : item.service?.id === serviceData.id
          ) {
            let quantity = 0;
            if (item.quantity) {
              quantity = parseInt(item.quantity, 10);
            }
            if (flag === 'add') {
              if (quantity < 999) {
                quantity += 1;
                item.quantity = quantity;
              }
            }
            if (flag === 'minus') {
              if (quantity > 1) {
                quantity -= 1;
                item.quantity = quantity;
              }
            }
          }
          return item;
        },
      );

      setFormData({
        ...formData,
        additional_monthly_services: changedService,
      });
      setUpdatedFormData({
        ...updatedFormData,
        additional_monthly_services: {
          ...updatedFormData?.additional_monthly_services,
          create: changedService,
        },
      });
    }
  };

  const checkDspAdvertisingDisabledCondition = (serviceData) => {
    if (
      originalData?.additional_monthly_services?.length &&
      originalData?.additional_monthly_services?.find(
        (item) =>
          item?.service?.id === serviceData?.id ||
          item?.service_id === serviceData?.id,
      )
    ) {
      return false;
    }
    if (
      contractAddendumDetails?.notActive &&
      serviceData?.name === 'DSP Advertising'
    ) {
      return !formData?.can_add_dsp_service;
    }
    return false;
  };

  return (
    <>
      <InputFormField className="mb-3">
        <label htmlFor="additional_one_time_services ">Monthly Services</label>
        <div
          className={
            contractAddendumDetails?.notActive ? 'add-discount' : 'add-discount'
          }
          role="presentation"
          onClick={() => onAddDiscount('monthly service', accountType)}
        >
          {showDiscountLabel()}
        </div>
      </InputFormField>
      <div className="row">
        {monthlyService &&
          monthlyService
            ?.filter((item) => !item?.name?.includes('Inventory Management'))
            ?.map((serviceData) => (
              <React.Fragment key={serviceData?.id}>
                <div
                  data-tip={
                    checkDspAdvertisingDisabledCondition(serviceData)
                      ? 'Adding DSP Service is not permitted as existing active contract includes this service.'
                      : ''
                  }
                  data-for="dsp-advertising"
                  className="col-7"
                >
                  <CheckBox key={serviceData?.id}>
                    <label
                      className={`check-container customer-pannel ${
                        checkDspAdvertisingDisabledCondition(serviceData)
                          ? 'disabled'
                          : ''
                      }`}
                      htmlFor={serviceData?.id}
                    >
                      {serviceData?.name}
                      <input
                        type="checkbox"
                        name={serviceData?.name}
                        id={serviceData?.id}
                        onClick={(event) =>
                          handleChange(
                            event,
                            'additional_monthly_services',
                            'checkbox',
                            serviceData,
                          )
                        }
                        defaultChecked={
                          formData?.additional_monthly_services?.length &&
                          formData?.additional_monthly_services?.find(
                            (item) =>
                              item?.service?.id === serviceData?.id ||
                              item?.service_id === serviceData?.id,
                          )
                        }
                      />
                      <span className="checkmark" />
                    </label>

                    {contractAddendumDetails?.notActive &&
                    serviceData?.name === 'DSP Advertising' ? (
                      <ReactTooltip
                        id="dsp-advertising"
                        aria-haspopup="true"
                        place="top"
                      />
                    ) : null}
                  </CheckBox>
                </div>
                {serviceData?.name === 'A+ Content' &&
                formData?.additional_monthly_services?.find(
                  (item) =>
                    item?.service?.id === serviceData?.id ||
                    item?.service_id === serviceData?.id,
                ) ? (
                  <>
                    <div className="col-5 pl-0 text-end">
                      <button
                        type="button"
                        className="decrement"
                        onClick={() => {
                          changeQuantity(serviceData, 'minus');
                        }}
                        disabled={checkDisabledConditionForServices(
                          formData?.additional_monthly_services,
                          serviceData?.id,
                          1,
                        )}
                      >
                        {' '}
                        <img className="minus-icon" src={MinusIcon} alt="" />
                      </button>

                      <NumberFormat
                        name={serviceData.name}
                        className="form-control max-min-number"
                        value={
                          formData?.additional_monthly_services?.find((item) =>
                            item.service_id
                              ? item.service_id === serviceData.id
                              : item.service?.id === serviceData.id,
                          )?.quantity || 1
                        }
                        id={serviceData.id}
                        onChange={(event) =>
                          handleChange(
                            event,
                            'additional_monthly_services',
                            'quantity',
                            serviceData,
                          )
                        }
                        isAllowed={(values) => {
                          const { formattedValue, floatValue } = values;
                          if (floatValue == null) {
                            return formattedValue === '';
                          }
                          return floatValue <= 999;
                        }}
                        allowNegative={false}
                      />

                      <button
                        type="button"
                        className="increment"
                        disabled={
                          formData?.additional_monthly_services?.find((item) =>
                            item?.service_id
                              ? item?.service_id === serviceData?.id
                              : item?.service?.id === serviceData?.id,
                          )?.quantity
                            ? parseInt(
                                formData?.additional_monthly_services?.find(
                                  (item) =>
                                    item.service_id
                                      ? item?.service_id === serviceData?.id
                                      : item?.service?.id === serviceData?.id,
                                )?.quantity,
                                10,
                              ) === 999
                            : false
                        }
                        onClick={() => {
                          changeQuantity(serviceData, 'add');
                        }}
                      >
                        <img className="plus-icon" src={PlusIcon} alt="" />
                      </button>
                    </div>
                  </>
                ) : (
                  ''
                )}
                {serviceData?.name === 'Logistics Management' ? (
                  <MonthlyServicesSubTabs
                    tabName="Inventory Management"
                    accountType={accountType}
                    monthlyService={monthlyService}
                    formData={formData}
                    setFormData={setFormData}
                    parentServiceName="additional_monthly_services"
                    contractAddendumDetails={contractAddendumDetails}
                    updatedFormData={updatedFormData}
                    setUpdatedFormData={setUpdatedFormData}
                    originalData={originalData}
                    additionalMonthlyServices={additionalMonthlyServices}
                    setMonthlyAdditionalServices={setMonthlyAdditionalServices}
                    updateAdditionalMonthlyServices={
                      updateAdditionalMonthlyServices
                    }
                    showFooter={showFooter}
                    fetchUncommonOptions={fetchUncommonOptions}
                  />
                ) : (
                  ''
                )}
              </React.Fragment>
            ))}
      </div>
    </>
  );
}

export default AdditionalMonthlyServices;
AdditionalMonthlyServices.defaultProps = {
  onAddDiscount: () => {},
  formData: {},
  monthlyService: [],
  accountType: '',
  showFooter: () => {},
  setShowCollpase: () => {},
  showSection: () => {},
  originalData: {},
  additionalMonthlyServices: {},
  setFormData: () => {},
  updateAdditionalMonthlyServices: () => {},
  setMonthlyAdditionalServices: () => {},
  fetchUncommonOptions: () => {},
  discountData: [],
  updatedFormData: {},
  setUpdatedFormData: () => {},
  contractAddendumDetails: {},
  addendumDetails: {},
};

AdditionalMonthlyServices.propTypes = {
  onAddDiscount: func,
  formData: shape({
    additional_monthly_services: arrayOf(shape({})),
    monthly_discount_amount: oneOfType([string, number]),
    monthly_discount_type: oneOfType([string, number]),
  }),
  monthlyService: arrayOf(shape({})),
  accountType: string,
  showFooter: func,
  setShowCollpase: func,
  showSection: func,
  originalData: shape({}),
  additionalMonthlyServices: shape({}),
  setFormData: () => {},
  updateAdditionalMonthlyServices: () => {},
  setMonthlyAdditionalServices: () => {},
  fetchUncommonOptions: () => {},
  discountData: arrayOf(shape()),
  updatedFormData: shape({}),
  setUpdatedFormData: func,
  contractAddendumDetails: shape({}),
  addendumDetails: shape({}),
};
