import React, { useState, useEffect, useCallback } from 'react';

import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams, useHistory } from 'react-router-dom';

import Article from './Article';
import ArticleSearch from './ArticleSearch';
import { GrayBody } from './ArticleKBaseStyle';
import { LeftArrowIcon } from '../../theme/images';
import { ArticleDetail } from './ArticleDetailsStyle';
import { setKBtitle } from '../../store/actions/userState';
import { PATH_ARTICLE_LIST, PATH_ARTICLE_DETAILS } from '../../constants';
import { getArticleBoards, getArticleCards } from '../../api';
import { PageLoader, BackToTop } from '../../common';

function ArticleDetails() {
  const { collectionId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [tabView, setTabView] = useState(false);
  const [boardData, setBoardData] = useState([]);
  const [folderData, setFolderData] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [subFolderData, setSubFolderData] = useState([]);
  const [selectedArticle, setKbArticleData] = useState({});
  const [subSubFolderData, setSubSubFolderData] = useState([]);
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [sectionLoader, setSectionLoader] = useState({
    boardLoader: false,
    folderLoader: false,
    subFolderLoader: false,
    articleLoader: false,
  });
  const [mobileView, setMobileView] = useState({
    board: true,
    section: false,
    article: false,
  });

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991.98 });
  const params = queryString?.parse(history?.location?.search);
  const isSubFolderExists =
    (subFolderData?.length || sectionLoader?.folderLoader) &&
    (isDesktop || (isTablet && !tabView) || (isMobile && mobileView.section));

  const getArticle = useCallback(
    (articleItem) => {
      setSectionLoader({ ...sectionLoader, articleLoader: true });

      getArticleCards(articleItem).then((cardResponse) => {
        setSectionLoader({ ...sectionLoader, articleLoader: false });
        if (cardResponse?.status === 200) {
          setKbArticleData(cardResponse?.data);
        } else {
          toast.error('Something went wrong.');
        }
      });
    },
    [sectionLoader],
  );

  const setLoaderFlag = useCallback(
    (folderType, flag) => {
      switch (folderType) {
        case 'folder':
          setSectionLoader({ ...sectionLoader, boardLoader: flag });
          break;
        case 'subFolder':
          setSectionLoader({ ...sectionLoader, folderLoader: flag });
          break;
        case 'subSubFolder':
          setSectionLoader({ ...sectionLoader, subFolderLoader: flag });
          break;

        default:
          setSectionLoader({
            ...sectionLoader,
            boardLoader: false,
            folderLoader: false,
            subFolderLoader: false,
          });
      }
    },
    [sectionLoader],
  );

  const getBoardData = useCallback(
    (boardId, folderType = 'folder') => {
      setLoaderFlag(folderType, true);
      getArticleBoards(boardId, 'board').then((res) => {
        if (res?.status === 200) {
          if (folderType === 'folder') {
            setFolderData(res && res?.data);
            if (params?.folder) {
              getBoardData(params?.folder, 'subFolder');
            }

            if (params?.folder) {
              const board = res?.data?.find(
                (ele) => ele?.id === params?.folder,
              );
              dispatch(setKBtitle(board));
            }
          }

          if (folderType === 'subFolder') {
            setSubFolderData(res && res?.data);

            if (params?.subFolder) {
              getBoardData(params?.subFolder, 'subSubFolder');
            }

            const board = folderData?.find((ele) => ele?.id === boardId);
            if (board) dispatch(setKBtitle(board));
          }

          if (folderType === 'subSubFolder') {
            setSubSubFolderData(res && res?.data);
          }
          if (params?.article) {
            getArticle(params?.article);
          }
        } else {
          toast.error('Something went wrong.');
        }

        setLoaderFlag(folderType, false);
      });
    },
    [
      params.folder,
      params.subFolder,
      params.article,
      getArticle,
      setLoaderFlag,
      dispatch,
      folderData,
    ],
  );

  const getSubFolderData = (selectedFolder, folderLevel) => {
    if (folderLevel === 'subFolder') {
      setSubFolderData([]);
      setSubSubFolderData([]);
    }

    getBoardData(selectedFolder, folderLevel);
  };

  const getCollectionData = useCallback(
    (collection) => {
      getArticleBoards(collection, 'collection').then((response) => {
        if (response?.status === 200) {
          setBoardData(response && response.data);
          setSelectedItems((prevSelectedItems) => ({
            ...prevSelectedItems,
            board: params?.board || response?.data?.[0]?.id,
            folder: params?.folder,
            subFolder: params?.subFolder,
            article: params?.article,
          }));

          // PDV-8766 - set the KB artical title ---START---
          if (!params?.board && !params?.folder)
            dispatch(setKBtitle(response?.data?.[0]));
          // PDV-8766 - set the KB artical title ---END---

          const boardId = params?.board || response?.data?.[0]?.id;

          getBoardData(boardId);
        } else {
          toast.error('Something went wrong.');
        }
        setIsLoading({ loader: false, type: 'page' });
      });
    },
    [
      getBoardData,
      dispatch,
      params.article,
      params.board,
      params.folder,
      params.subFolder,
    ],
  );

  useEffect(() => {
    if (!boardData?.length && !folderData?.length)
      getCollectionData(collectionId);
  }, [collectionId, getCollectionData, boardData, folderData]);

  const setParams = (paramString) => {
    history.push({
      pathname: `${PATH_ARTICLE_DETAILS.replace(
        ':collectionId',
        collectionId,
      )}`,
      search: `${paramString}`,
    });
  };

  const handleBoard = (board) => {
    setSelectedItems({
      ...selectedItems,
      board: board?.id,
    });
    setFolderData([]);
    setSubSubFolderData([]);
    getBoardData(board?.id);

    const stringified = queryString.stringify({
      board: board?.id,
    });
    setParams(stringified);

    // PDV-8766 - set the KB artical title ---START---
    dispatch(setKBtitle(board));
    // PDV-8766 - set the KB artical title ---END---

    if (isMobile)
      setMobileView({ board: true, section: false, article: false });
  };

  const handleSubFolderClick = (sub) => {
    setSubSubFolderData([]);

    setSelectedItems({
      ...selectedItems,
      subFolder: sub?.id,
    });
    getSubFolderData(sub?.id, 'subSubFolder');

    const stringified = queryString.stringify({
      board: params?.board,
      folder: params?.folder,
      subFolder: sub?.id,
    });
    setParams(stringified);
  };

  const handleArticleOnClick = (sub, type) => {
    const selectedItemsToUpdate = {
      article: sub?.id,
    };
    if (type === 'article') {
      selectedItemsToUpdate.folder = '';
      setSubFolderData([]);
      setSubSubFolderData([]);
    }

    setSelectedItems({
      ...selectedItems,
      ...selectedItemsToUpdate,
    });

    setKbArticleData({});
    getArticle(sub?.id);
    if (isMobile)
      setMobileView({
        board: false,
        section: false,
        article: true,
      });
    if (isTablet) setTabView(true);

    const queryStringData = {
      board: params?.board,
      article: sub?.id,
    };

    if (type === 'subArticle') {
      queryStringData.folder = params?.folder;
      queryStringData.subFolder = params?.subFolder;
    }
    const stringified = queryString.stringify({
      ...queryStringData,
    });
    setParams(stringified);
  };

  const showSubFolders = (field, folderType) => {
    return (
      field?.map((sub) => (
        <React.Fragment key={sub.id}>
          <li
            className={`${
              folderType === 'subFolder' &&
              (sub?.id === selectedItems?.subFolder ||
                sub?.id === selectedItems?.article)
                ? 'active'
                : ''
            } ${
              folderType === 'subSubFolder' &&
              sub?.id === selectedItems?.article
                ? 'active'
                : ''
            } `}
            role="presentation"
          >
            {sub?.type === 'folder' ? (
              <>
                <div
                  className={`  label ${
                    folderType === 'subSubFolder' ? 'ml-4' : ''
                  }`}
                  role="presentation"
                  onClick={() => {
                    handleSubFolderClick(sub);
                  }}
                >
                  {sub?.title}
                </div>
              </>
            ) : (
              <div
                className={` category ${
                  folderType === 'subSubFolder' ? 'ml-4' : ''
                }`}
                role="presentation"
                onClick={() => {
                  handleArticleOnClick(sub, 'subArticle');
                }}
              >
                <FontAwesomeIcon
                  className="mr-2"
                  icon="fa-regular fa-check"
                  color="green"
                  fontSize="12px"
                />
                {sub?.preferredPhrase}
              </div>
            )}
          </li>
          {sub.id === selectedItems?.subFolder ? (
            sectionLoader?.subFolderLoader ? (
              <PageLoader color="red" type="button" />
            ) : (
              showSubFolders(subSubFolderData, 'subSubFolder')
            )
          ) : (
            ''
          )}
        </React.Fragment>
      )) || null
    );
  };

  const handleFolderOnClick = (field, board) => {
    setSelectedItems({
      ...selectedItems,
      folder: field?.id,
    });
    if (isMobile)
      setMobileView({
        board: false,
        section: true,
        article: false,
      });

    const stringified = queryString.stringify({
      board: board?.id,
      folder: field?.id,
    });
    setParams(stringified);
    getSubFolderData(field?.id, 'subFolder');
  };

  return (
    <GrayBody className="container-fluid">
      <div className="graycontainer-fluid ">
        <div className="row">
          <div className="col-lg-2 col-md-3 col-12  pt-2 pl-lg-0 pl-md-0  ">
            <div className="small-title">
              {' '}
              <Link className="link" to={PATH_ARTICLE_LIST}>
                <div className="back-to-pervious mb-2 cursor">
                  <img className="left-arrow" src={LeftArrowIcon} alt="" /> Back
                  to All Collections
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-10 col-md-9 col-12 pt-3  ">
            <ArticleSearch
              setParams={setParams}
              setSubFolderData={setSubFolderData}
              getArticle={getArticle}
              setSelectedItems={setSelectedItems}
              isMobile={isMobile}
              setMobileView={setMobileView}
              isTablet={isTablet}
              setTabView={setTabView}
              setLoaderFlag={setLoaderFlag}
              setFolderData={setFolderData}
              setBoardData={setBoardData}
            />
          </div>
        </div>
      </div>
      <div className="straight-line horizontal-line" />
      {isLoading.loader && isLoading.type === 'page' ? (
        <PageLoader color="#FF5933" type="page" width={40} />
      ) : (
        <div className="graycontainer-fluid ">
          <div className="row">
            <div className=" col-lg-2 col-sm-6 col-12 pl-lg-0 pl-md-0 pr-0 ">
              <ArticleDetail>
                {isDesktop ||
                (isTablet && !tabView) ||
                (isMobile && mobileView.board) ? (
                  <div className="board" id="boardBox">
                    <ul className="acc-management-list">
                      <li>
                        <div className="small-title">Boards</div>
                      </li>
                      {boardData?.length &&
                        boardData.map((board) => (
                          <React.Fragment key={board.id}>
                            <li
                              className={
                                board.id === selectedItems?.board
                                  ? 'active'
                                  : ''
                              }
                              key={board.id}
                              role="presentation"
                              onClick={() => handleBoard(board)}
                            >
                              <div className="category">
                                {board && board.title}
                              </div>
                            </li>
                            {board.id === selectedItems?.board ? (
                              sectionLoader?.boardLoader ? (
                                <PageLoader color="red" type="button" />
                              ) : (
                                folderData &&
                                folderData.map((field) => (
                                  <React.Fragment key={field.id}>
                                    {field.type === 'folder' ? (
                                      <li
                                        className={`${
                                          field.id === selectedItems.folder
                                            ? 'active'
                                            : ''
                                        }`}
                                      >
                                        <div
                                          className={` label ml-4`}
                                          role="presentation"
                                          onClick={() => {
                                            handleFolderOnClick(field, board);
                                          }}
                                        >
                                          {field.title}
                                        </div>
                                      </li>
                                    ) : (
                                      <li
                                        className={`${
                                          field.id === selectedItems?.article
                                            ? 'active'
                                            : ''
                                        } ml-4`}
                                        role="presentation"
                                        onClick={() => {
                                          handleArticleOnClick(
                                            field,
                                            'article',
                                          );
                                        }}
                                      >
                                        <div className="category">
                                          <FontAwesomeIcon
                                            className="mr-2"
                                            icon="fa-regular fa-check"
                                            color="green"
                                            fontSize="12px"
                                          />
                                          {field.preferredPhrase}
                                        </div>
                                      </li>
                                    )}
                                  </React.Fragment>
                                ))
                              )
                            ) : (
                              ''
                            )}
                          </React.Fragment>
                        ))}
                    </ul>
                  </div>
                ) : (
                  ''
                )}

                {isMobile && mobileView.section ? (
                  <>
                    <div className="row">
                      <div className=" d-sm-block col-12  pt-2  mb-3 ">
                        <div className="small-title">
                          {' '}
                          <div
                            className="back-to-pervious mb-2 cursor"
                            role="presentation"
                            onClick={() =>
                              isMobile
                                ? setMobileView({
                                    board: true,
                                    section: false,
                                    article: false,
                                  })
                                : ''
                            }
                          >
                            <img
                              className="left-arrow"
                              src={LeftArrowIcon}
                              alt=""
                            />{' '}
                            Back to All Boards
                          </div>
                          {selectedArticle?.boards?.[0]?.title}
                        </div>
                      </div>
                    </div>
                    <div className="straight-line horizontal-line" />
                  </>
                ) : (
                  ''
                )}
              </ArticleDetail>
            </div>{' '}
            <div
              className={` ${
                isSubFolderExists
                  ? 'col-lg-2 col-sm-6 col-12 pl-lg-0 pl-md-0 pr-0'
                  : ''
              }  `}
            >
              <ArticleDetail>
                {isSubFolderExists ? (
                  <>
                    <div className="articles" id="articleBox">
                      <ul className="acc-management-list">
                        <li>
                          <div className="small-title">Articles</div>
                        </li>
                        {sectionLoader?.folderLoader ? (
                          <PageLoader color="red" type="button" />
                        ) : (
                          showSubFolders(subFolderData, 'subFolder')
                        )}
                      </ul>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </ArticleDetail>
            </div>
            <div
              className={` ${
                isSubFolderExists ? 'col-lg-8' : 'col-lg-10'
              }   col-12 `}
            >
              {(isTablet && tabView) || (isMobile && mobileView.article) ? (
                <>
                  <div className="row">
                    <div className="col-12  pt-2  mb-3 ">
                      <div className="small-title">
                        {' '}
                        <div
                          className="back-to-pervious-page mb-2 cursor"
                          onClick={() =>
                            isTablet
                              ? setTabView(false)
                              : isMobile
                              ? setMobileView({
                                  board: false,
                                  section: true,
                                  article: false,
                                })
                              : ''
                          }
                          role="presentation"
                        >
                          <img
                            className="left-arrow"
                            src={LeftArrowIcon}
                            alt=""
                          />{' '}
                          Back to All Articles
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="straight-line horizontal-line" />
                </>
              ) : (
                ''
              )}

              <Article
                selectedArticle={selectedArticle}
                sectionLoader={sectionLoader}
                isDesktop={isDesktop}
                isTablet={isTablet}
                isMobile={isMobile}
                tabView={tabView}
                mobileView={mobileView}
              />
              <div className="col-12 mt-5">
                <BackToTop />
              </div>
            </div>
          </div>
        </div>
      )}
    </GrayBody>
  );
}

export default ArticleDetails;
