import React from 'react';

import styled from 'styled-components';
import { func, shape, string } from 'prop-types';

import Theme from '../../../../../theme/Theme';
import { ArrowUpIcon, ArrowDownIcon } from '../../../../../theme/images';

const TableMobileView = ({
  className,
  utilization,
  utlPercent,
  maxShip,
  maxShipPercent,
  openShip,
  openShipPercent,
  onhand,
  onhandPercent,
  onClick,
  formatNumber,
  timeframe,
  label,
}) => {
  // <div className="straight-line horizontal-line mb-3 mt-2" />
  const col = 'col-6';
  return (
    <TableMobileViewWrapper className={[className]} onClick={onClick}>
      <div className="straight-line horizontal-line mt-3 mb-3" />{' '}
      <div className="row">
        <div className={`${col} mb-3`}>
          <div className="label">Storage Type</div>
          <div className="label-info label-bold">{label}</div>
        </div>
        <div className={`${col} mb-3`}>
          <div className="label">utilization</div>
          <div className="label-info label-bold">
            {formatNumber(utilization, '', '%')}

            {timeframe?.value === 'custom' || utlPercent === null ? (
              <span className="ml-1">N/A</span>
            ) : utlPercent === 0 ? (
              <span className="increase-rate gray80 large text-extra-medium ml-1">
                {formatNumber(utlPercent, '', '%')}
              </span>
            ) : utlPercent > 0 ? (
              <span className="increase-rate large text-extra-medium ml-1">
                <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
                {formatNumber(utlPercent, '', '%')}
              </span>
            ) : (
              <span className="decrease-rate large text-extra-medium ml-1">
                <img className="red-arrow" src={ArrowDownIcon} alt="arrow-up" />
                {formatNumber(Math.abs(utlPercent), '', '%')}
              </span>
            )}
          </div>
        </div>

        <div className={`${col} mb-3`}>
          <div className="label">Max Shipment</div>
          <div className="label-info label-bold">
            {maxShip}

            {timeframe?.value === 'custom' || maxShipPercent === null ? (
              <span className="ml-1">N/A</span>
            ) : maxShipPercent === 0 ? (
              <span className="increase-rate gray80 large text-extra-medium ml-1">
                {formatNumber(maxShipPercent, '', '%')}
              </span>
            ) : maxShipPercent > 0 ? (
              <span className="increase-rate large text-extra-medium ml-1">
                <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
                {formatNumber(maxShipPercent, '', '%')}
              </span>
            ) : (
              <span className="decrease-rate large text-extra-medium ml-1">
                <img className="red-arrow" src={ArrowDownIcon} alt="arrow-up" />
                {formatNumber(Math.abs(maxShipPercent), '', '%')}
              </span>
            )}
          </div>
        </div>

        <div className={`${col} mb-3`}>
          <div className="label">Open Inventory</div>
          <div className="label-info label-bold">
            {openShip}

            {timeframe?.value === 'custom' || openShipPercent === null ? (
              <span className="ml-1">N/A</span>
            ) : openShipPercent === 0 ? (
              <span className="increase-rate gray80 large text-extra-medium ml-1">
                {formatNumber(openShipPercent, '', '%')}
              </span>
            ) : openShipPercent > 0 ? (
              <span className="increase-rate large text-extra-medium ml-1">
                <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
                {formatNumber(openShipPercent, '', '%')}
              </span>
            ) : (
              <span className="decrease-rate large text-extra-medium ml-1">
                <img className="red-arrow" src={ArrowDownIcon} alt="arrow-up" />
                {formatNumber(Math.abs(openShipPercent), '', '%')}
              </span>
            )}
          </div>
        </div>

        <div className={`${col} mb-3`}>
          <div className="label">Onhand</div>
          <div className="label-info label-bold">
            {onhand}

            {timeframe?.value === 'custom' || onhandPercent === null ? (
              <span className="ml-1">N/A</span>
            ) : onhandPercent === 0 ? (
              <span className="increase-rate gray80 large text-extra-medium ml-1">
                {formatNumber(onhandPercent, '', '%')}
              </span>
            ) : onhandPercent > 0 ? (
              <span className="increase-rate large text-extra-medium ml-1">
                <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
                {formatNumber(onhandPercent, '', '%')}
              </span>
            ) : (
              <span className="decrease-rate large text-extra-medium ml-1">
                <img className="red-arrow" src={ArrowDownIcon} alt="arrow-up" />
                {formatNumber(Math.abs(onhandPercent), '', '%')}
              </span>
            )}
          </div>
        </div>
      </div>
    </TableMobileViewWrapper>
  );
};

export default TableMobileView;

const TableMobileViewWrapper = styled.div`
  .card-staus-right {
    float: right;
  }

  .label-bold {
    font-weight: bold;
  }
  .sub-label-text {
    font-size: ${Theme.extraNormal};
    color: ${Theme.black};
  }
`;

TableMobileView.defaultProps = {
  className: '',
  utilization: '',
  utlPercent: '',
  openShip: '',
  openShipPercent: '',
  onhand: '',
  onhandPercent: '',
  maxShip: '',
  maxShipPercent: '',
  label: '',
  onClick: () => {},
  formatNumber: () => {},
};

TableMobileView.propTypes = {
  className: string,
  utilization: string,
  utlPercent: string,
  openShip: string,
  openShipPercent: string,
  onhand: string,
  onhandPercent: string,
  maxShip: string,
  maxShipPercent: string,
  label: string,
  onClick: func,
  formatNumber: func,
  timeframe: shape({}).isRequired,
};
