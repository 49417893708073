import React, { useState } from 'react';

import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Select, { components } from 'react-select';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import Theme from '../../theme/Theme';
import { DropDown } from './CustomizedReports/ReportDropDown';

import {
  SpreetailRole,
  SpreetailRoleRestrictMessage,
  bpAccountType,
  buttonDisabledMsg,
  timeFrameOptions,
} from '../../constants';
import {
  WhiteCard,
  DropdownIndicator,
  Button,
  CustomDropDown,
  CheckBox,
  ModalRadioCheck,
} from '../../common';

const ReportSectionFilter = ({
  bpList,
  handleBPList,
  isApiCall,
  handleTimeFrame,
  selectedTimeFrame,
  getTimeFrameSelectComponents,
  selectedBp,
  marketplaceChoices,
  marketplaceDefaultValue,
  selectedMarketplaceOption,
  isCustomer,
  handleMarketplace,
  marketplaceLoader,
  handleAccountType,
  selectedAccountType,
  reportTypeLoader,
  reportTypeOptions,
  selectedReportType,
  selectedReportTab,
  handleReportType,
  isUserAssignedToBP,
  handleResetFilter,
  onCreateReport,
  bpLoader,
  isUsedReportTemplate,
  setIsUsedReportTemplate,
  selectedReportTemplate,
  setSelectedReportTemplate,
  reportTemplateOption,
}) => {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const { Option, SingleValue } = components;
  const isFinanceReportSelected =
    selectedReportTab === 'standard' && selectedReportType?.value === 'finance';

  const customDropdownFilterOption = (props) => (
    <Option {...props}>
      <div>
        <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
      </div>
    </Option>
  );

  const customDropdownSingleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px', fontWeight: '500' }}>
        {props?.data?.label}
      </span>
    </SingleValue>
  );

  const customDropdownFilterComponents = () => {
    return {
      Option: customDropdownFilterOption,
      SingleValue: customDropdownSingleFilterOption,
      DropdownIndicator,
    };
  };

  // component for marketplace dropdown
  // PDV-7818 - handeling of active and inactive marketplaces
  const marketplaceDropdownFilterOption = (props) => (
    <Option {...props}>
      <div>
        <span style={{ fontSize: '14px' }}>
          {props?.data?.label}
          {props?.data?.isActive ||
          props?.data?.value === 'all' ||
          props?.data?.value === 'select' ||
          isFinanceReportSelected
            ? ''
            : '( Inactive )'}
        </span>
      </div>
    </Option>
  );

  // component - single values for marketplace dropdown
  // PDV-7818 - handeling of active and inactive marketplaces
  const marketplaceDropdownSingleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px', fontWeight: '500' }}>
        {props.data.label}{' '}
        {props?.data?.isActive ||
        props?.data?.value === 'all' ||
        props?.data?.value === 'select' ||
        isFinanceReportSelected
          ? ''
          : '( Inactive )'}
      </span>
    </SingleValue>
  );

  // component - option for marketplace dropdown
  // PDV-7818 - handeling of active and inactive marketplaces
  const marketplaceDropdownComponent = () => {
    return {
      Option: marketplaceDropdownFilterOption,
      SingleValue: marketplaceDropdownSingleFilterOption,
      DropdownIndicator,
    };
  };

  const renderBPDropdown = (className) => {
    return (
      <CustomDropDown
        id="BT-ReportBPDropdown"
        className={
          isFinanceReportSelected ? 'w-100 disabled' : ` w-100 ${className} `
        }
        inputColor={Theme.black}
      >
        <Select
          classNamePrefix="react-select"
          className="active "
          components={customDropdownFilterComponents()}
          options={bpList}
          defaultValue={bpList && bpList[0]}
          value={
            isFinanceReportSelected
              ? { label: 'BP Name', value: 'Select-BP' }
              : selectedBp
          }
          onChange={(event) => handleBPList(event)}
          placeholder={bpList && bpList[0] && bpList[0].label}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
          isLoading={bpLoader}
        />
      </CustomDropDown>
    );
  };

  const renderAccountTypeDropdown = () => {
    return (
      <CustomDropDown
        id="BT-ReportAccountTypeDropdown"
        className={
          selectedAccountType?.value === 'accountType'
            ? `cursor disabled w-100`
            : 'cursor w-100 '
        }
        inputColor={Theme.black}
      >
        <Select
          classNamePrefix="react-select"
          className="active "
          components={customDropdownFilterComponents()}
          options={bpAccountType[selectedBp?.account_type]}
          defaultValue={bpAccountType[selectedBp?.account_type]?.[0]}
          placeholder={bpAccountType[selectedBp?.account_type]?.[0]?.label}
          value={selectedAccountType}
          onChange={(event) => handleAccountType(event)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
          isLoading={isApiCall}
        />
      </CustomDropDown>
    );
  };

  const renderReportTypeDropdown = () => {
    return (
      <CustomDropDown
        id="BT-ReportTypeDropdown"
        className="cursor w-100"
        inputColor={Theme.black}
      >
        <Select
          classNamePrefix="react-select"
          className="active "
          components={customDropdownFilterComponents()}
          options={reportTypeOptions}
          defaultValue={reportTypeOptions?.[0]}
          placeholder={reportTypeOptions?.[0]?.label}
          value={selectedReportType}
          onChange={(event) => handleReportType(event)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
          isLoading={reportTypeLoader}
        />
      </CustomDropDown>
    );
  };

  const renderTimeFrameFilter = () => {
    return DropDown(
      'BT-ReportBPDropdownTimeFilter',
      '',
      timeFrameOptions,
      timeFrameOptions?.[0]?.label,
      getTimeFrameSelectComponents,
      timeFrameOptions?.[0],
      handleTimeFrame,
      isApiCall,
      null,
      selectedTimeFrame,
      false,
      null,
      selectedReportType,
    );
  };

  const renderMarketplaceDropDown = () => {
    return (
      <CustomDropDown
        id="BT-reports-countryfilter"
        className={
          marketplaceLoader ||
          !marketplaceChoices ||
          (selectedReportTab === 'standard' &&
            selectedReportType?.value === 'finance') ||
          (marketplaceChoices && marketplaceChoices.length === 0)
            ? `cursor  disabled w-100`
            : 'cursor w-100'
        }
        inputColor={Theme.black}
      >
        <Select
          classNamePrefix="react-select"
          className="active"
          value={
            selectedReportTab === 'standard' &&
            selectedReportType?.value === 'finance'
              ? { label: 'Select Marketplace', value: 'Select Marketplace' }
              : selectedMarketplaceOption
          }
          components={marketplaceDropdownComponent()}
          options={marketplaceChoices}
          onChange={handleMarketplace}
          placeholder={
            marketplaceChoices && marketplaceChoices?.length
              ? marketplaceDefaultValue?.label
              : 'Select Marketplace'
          }
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
          isLoading={marketplaceLoader}
        />
      </CustomDropDown>
    );
  };

  const renderReportTemplate = () => {
    return (
      <CheckBox>
        <div style={{ position: 'relative' }}>
          <label
            htmlFor="useReportTemplate"
            className={`check-container checkboxes-std-services customer-pannel ${
              selectedAccountType?.value === 'accountType' ? `disabled` : ''
            }`}
            key="useReportTemplate"
          >
            Use Report Template
            <input
              type="checkbox"
              id="useReportTemplate"
              checked={isUsedReportTemplate}
              onClick={() => {
                setIsUsedReportTemplate(!isUsedReportTemplate);
              }}
              onChange={() => {
                setSelectedReportTemplate(null);
              }}
            />
            <span className="checkmark" />
          </label>
        </div>
      </CheckBox>
    );
  };

  const renderReportTemplateOptions = () => {
    return (
      <>
        {reportTemplateOption.map((item) => {
          return (
            <ModalRadioCheck key={item.id}>
              <label
                className="checkboxes radio-container customer-list mb-3"
                htmlFor={item.id}
              >
                {item.name}
                <input
                  type="radio"
                  name="template"
                  id={item.id}
                  value={item.id}
                  checked={selectedReportTemplate === item.id}
                  onChange={(event) => {
                    setSelectedReportTemplate(event.target.id);
                  }}
                />
                <span className="checkmark checkmark-customer-list" />
              </label>
            </ModalRadioCheck>
          );
        })}
      </>
    );
  };

  const renderDekstopView = () => {
    return (
      <WhiteCard className="mb-3 d-lg-block d-none">
        <div className="justify-space-between">
          <p className="black-heading-title mt-2 pt-1">
            {selectedReportTab === 'standard' ? 'Download' : 'Create'} Reports
            for
          </p>
          <p
            className="gray-normal-text cursor"
            aria-hidden="true"
            onClick={handleResetFilter}
          >
            Reset filters
          </p>
        </div>
        <div className="row">
          {!isCustomer ? (
            <div className={isCustomer ? 'col-lg-4' : 'col-lg-12 mb-3'}>
              {renderBPDropdown()}
            </div>
          ) : null}
          {selectedReportTab === 'standard' ? (
            <div className={isCustomer ? 'col-lg-4' : 'col-lg-12 mb-3'}>
              {renderTimeFrameFilter('cursor')}
            </div>
          ) : null}

          <div className={isCustomer ? 'col-lg-4' : 'col-lg-12 mb-3'}>
            {renderMarketplaceDropDown()}
          </div>

          <div className={isCustomer ? 'col-lg-4' : 'col-lg-12 mb-3'}>
            {selectedReportTab === 'standard'
              ? renderReportTypeDropdown()
              : renderAccountTypeDropdown()}
          </div>

          {reportTemplateOption?.length ? (
            <div className={isCustomer ? 'col-lg-4' : 'col-lg-12 ml-2'}>
              {selectedReportTab === 'customized'
                ? renderReportTemplate()
                : null}
            </div>
          ) : null}

          <div className={isCustomer ? 'col-lg-4' : 'col-lg-12 ml-4'}>
            {selectedReportTab === 'customized' && isUsedReportTemplate
              ? renderReportTemplateOptions()
              : null}
          </div>

          {selectedReportTab === 'customized' ? (
            <div className={isCustomer ? 'col-lg-4' : 'col-lg-12'}>
              <Button
                data-tip={SpreetailRoleRestrictMessage}
                data-for={isSpreetailUser ? 'spreetailRetrictCreateReport' : ''}
                disabled={
                  isSpreetailUser ||
                  (isUsedReportTemplate && selectedReportTemplate === null) ||
                  selectedBp?.value === 'Select-BP' ||
                  selectedMarketplaceOption?.value === 'select' ||
                  !isUserAssignedToBP
                }
                className="btn-primary sticky-btn-primary sidepanel mt-3 w-100 ml-0"
                onClick={onCreateReport}
              >
                Create Report
              </Button>
              {isSpreetailUser ? (
                <ReactTooltip
                  id="spreetailRetrictCreateReport"
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                  html
                />
              ) : null}
              {selectedBp?.value === 'Select-BP' || !isUserAssignedToBP ? (
                <div className="sub-heading extra-small-text text-medium-font mt-2">
                  {buttonDisabledMsg}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </WhiteCard>
    );
  };

  const renderTabletView = () => {
    return (
      <WhiteCard
        onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        className="mb-3 d-lg-none d-block"
      >
        <div className="row ">
          <div className="col-12">
            <div className="justify-space-between">
              <p className="black-heading-title mt-2 pt-1">
                Create Reports for
              </p>
              <p
                className="gray-normal-text cursor"
                aria-hidden="true"
                onClick={handleResetFilter}
              >
                Reset filters
              </p>
            </div>
          </div>

          {!isCustomer ? (
            <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
              {renderBPDropdown('customer-list-header')}
            </div>
          ) : null}

          <div className="col-md-4 col-sm-12 pb-3">
            {renderTimeFrameFilter('customer-list-header')}
          </div>

          <div className="col-md-4  col-sm-12 pb-3 ">
            {renderMarketplaceDropDown()}
          </div>

          <div className="col-md-4 col-sm-12 pb-3">
            {selectedReportTab === 'standard'
              ? renderReportTypeDropdown()
              : renderAccountTypeDropdown()}
          </div>

          {reportTemplateOption?.length ? (
            <div className="col-md-4 col-sm-12 pb-2">
              {selectedReportTab === 'customized'
                ? renderReportTemplate()
                : null}
            </div>
          ) : null}

          {selectedReportTab === 'customized' && isUsedReportTemplate ? (
            <div className="col-md-4 col-sm-12 pb-2">
              {renderReportTemplateOptions()}
            </div>
          ) : null}

          {selectedReportTab === 'customized' ? (
            <div className="col-md-4 col-sm-12 pb-3">
              <Button
                data-tip={SpreetailRoleRestrictMessage}
                data-for={
                  isSpreetailUser ? 'spreetailRetrictCreateReportTablet' : ''
                }
                disabled={
                  isSpreetailUser ||
                  (isUsedReportTemplate && selectedReportTemplate === null) ||
                  selectedBp?.value === 'Select-BP' ||
                  !isUserAssignedToBP
                }
                className="btn-primary sticky-btn-primary sidepanel mt-2 w-100 ml-0"
                onClick={onCreateReport}
              >
                Create Report
              </Button>
              {isSpreetailUser ? (
                <ReactTooltip
                  id="spreetailRetrictCreateReportTablet"
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                  html
                />
              ) : null}
              {selectedBp?.value === 'Select-BP' || !isUserAssignedToBP ? (
                <div className="sub-heading extra-small-text text-medium-font mt-2">
                  {buttonDisabledMsg}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </WhiteCard>
    );
  };

  return <>{isDesktop ? renderDekstopView() : renderTabletView()}</>;
};

export default ReportSectionFilter;

ReportSectionFilter.defaultProps = {
  marketplaceChoices: [],
  marketplaceDefaultValue: null,
  isApiCall: false,
  isCustomer: false,
  marketplaceLoader: false,
  isSelected: true,
  reportTypeLoader: false,
  bpLoader: false,
  isUsedReportTemplate: false,
  selectedBp: {},
  data: {},
  selectedTimeFrame: {},
  selectedMarketplaceOption: {},
  bpList: null,
  selectedReportTemplate: '',
  reportTypeOptions: [{}],
  reportTemplateOption: [],
  handleBPList: () => {},
  handleTimeFrame: () => {},
  getTimeFrameSelectComponents: () => {},
  handleMarketplace: () => {},
  handleAccountType: () => {},
  handleReportType: () => {},
  handleResetFilter: () => {},
  onCreateReport: () => {},
  setIsUsedReportTemplate: () => {},
  setSelectedReportTemplate: () => {},
};

ReportSectionFilter.propTypes = {
  selectedReportTab: string.isRequired,
  isApiCall: bool,
  isCustomer: bool,
  marketplaceLoader: bool,
  isSelected: bool,
  reportTypeLoader: bool,
  bpLoader: bool,
  isUserAssignedToBP: bool.isRequired,
  isUsedReportTemplate: bool,
  selectedReportTemplate: string,
  selectedMarketplaceOption: shape({}),
  selectedBp: shape({}),
  selectedTimeFrame: shape({}),
  bpList: arrayOf(shape({})),
  marketplaceChoices: arrayOf(shape({})),
  reportTypeOptions: arrayOf(shape({})),
  reportTemplateOption: arrayOf(shape({})),
  marketplaceDefaultValue: shape({}),
  data: shape({}),
  selectedAccountType: shape({}).isRequired,
  selectedReportType: shape({}).isRequired,
  handleBPList: func,
  handleTimeFrame: func,
  getTimeFrameSelectComponents: func,
  handleMarketplace: func,
  handleAccountType: func,
  handleReportType: func,
  handleResetFilter: func,
  onCreateReport: func,
  setIsUsedReportTemplate: func,
  setSelectedReportTemplate: func,
};
