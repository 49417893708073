// third party imports
import dayjs from 'dayjs';

const BindSponsoredAdData = (response) => {
  const tempData = [];

  // get a last date of the week
  const getOneDayBack = (reportDateTemp) => {
    const reportDateResultTemp = new Date(reportDateTemp);
    reportDateResultTemp.setDate(reportDateResultTemp.getDate() - 1);
    return reportDateResultTemp;
  };
  const nextReportDate = (indexTemp, graphDataArray, dateParam) => {
    return dayjs(
      indexTemp + 1 < graphDataArray?.length
        ? getOneDayBack(graphDataArray[indexTemp + 1]?.report_date)
        : response?.dates?.[
            dateParam === 'current' ? 'current_end_date' : 'prevous_end_date'
          ],
    ).format('MMM D YYYY');
  };

  // filterout previous data in one temporary object.
  if (response?.previous?.length) {
    response.previous.forEach((item, index) => {
      const previousDate = dayjs(item?.report_date).format('MMM D YYYY');
      tempData.push({
        adSalesPrevious: item?.ad_sales,
        adSpendPrevious: item?.ad_spend,
        adConversionPrevious: item?.ad_conversion_rate,
        impressionsPrevious: item?.impressions,
        adCosPrevious: item?.acos,
        adRoasPrevious: item?.roas,
        adClicksPrevious: item?.clicks,
        adClickRatePrevious: item?.ctr,
        costPerClickPrevious: item?.cost_per_click,
        salesPerClickPrevious: item?.sales_per_click,
        percentageNtbOrdersPrevious: item?.ntb_orders_percent,
        percentageNtbSalesPrevious: item?.ntb_sales_percent,
        ntbOrdersPrevious: item?.ntb_orders,
        ntbSalesPrevious: item?.ntb_sales,

        previousDate,
        previousEndDate: nextReportDate(index, response?.previous, 'previous'),

        adSalesPreviousLabel:
          item?.ad_sales !== null ? item.ad_sales.toFixed(2) : '0.00',
        adSpendPreviousLabel:
          item?.ad_spend !== null ? item.ad_spend.toFixed(2) : '0.00',
        adConversionPreviousLabel:
          item?.ad_conversion_rate !== null
            ? item.ad_conversion_rate.toFixed(2)
            : '0.00',
        impressionsPreviousLabel:
          item?.impressions !== null ? item.impressions : '0',
        adCosPreviousLabel: item?.acos !== null ? item.acos.toFixed(2) : '0.00',
        adRoasPreviousLabel:
          item?.roas !== null ? item.roas.toFixed(2) : '0.00',
        adClicksPreviousLabel: item?.clicks !== null ? item.clicks : '0',
        adClickRatePreviousLabel:
          item?.ctr !== null ? item.ctr.toFixed(2) : '0.00',
        costPerClickPreviousLabel:
          item?.cost_per_click !== null
            ? item?.cost_per_click.toFixed(2)
            : '0.00',
        salesPerClickPreviousLabel:
          item?.sales_per_click !== null
            ? item?.sales_per_click.toFixed(2)
            : '0.00',
        percentageNtbOrdersPreviousLabel:
          item?.ntb_orders_percent !== null
            ? item?.ntb_orders_percent.toFixed(2)
            : '0',
        percentageNtbSalesPreviousLabel:
          item?.ntb_sales_percent !== null
            ? item?.ntb_sales_percent.toFixed(2)
            : '0',
        ntbOrdersPreviousLabel:
          item?.ntb_orders !== null ? item?.ntb_orders.toFixed(2) : '0',
        ntbSalesPreviousLabel:
          item?.ntb_sales !== null ? item?.ntb_sales.toFixed(2) : '0',
      });
    });
  }

  // filterout current data in one temporary object.
  if (response?.current?.length) {
    response.current.forEach((item, index) => {
      const currentReportDate = dayjs(item?.report_date).format('MMM D YYYY');
      // add the current data at same index of prevoius in temporary object
      if (response?.previous && index < response?.previous?.length) {
        tempData[index].date = currentReportDate;
        tempData[index].adSalesCurrent = item?.ad_sales;
        tempData[index].adSpendCurrent = item?.ad_spend;
        tempData[index].adConversionCurrent = item?.ad_conversion_rate;
        tempData[index].impressionsCurrent = item?.impressions;
        tempData[index].adCosCurrent = item?.acos;
        tempData[index].adRoasCurrent = item?.roas;
        tempData[index].adClicksCurrent = item?.clicks;
        tempData[index].adClickRateCurrent = item?.ctr;
        tempData[index].costPerClickCurrent = item?.cost_per_click;
        tempData[index].salesPerClickCurrent = item?.cost_per_click;
        tempData[index].percentageNtbOrdersCurrent = item?.ntb_orders_percent;
        tempData[index].percentageNtbSalesCurrent = item?.ntb_sales_percent;
        tempData[index].ntbOrdersCurrent = item?.ntb_orders;
        tempData[index].ntbSalesCurrent = item?.ntb_sales;
        tempData[index].endDate = nextReportDate(
          index,
          response?.current,
          'current',
        );

        tempData[index].adSalesCurrentLabel =
          item?.ad_sales !== null ? item.ad_sales.toFixed(2) : '0.00';
        tempData[index].adSpendCurrentLabel =
          item?.ad_spend !== null ? item.ad_spend.toFixed(2) : '0.00';
        tempData[index].adConversionCurrentLabel =
          item?.ad_conversion_rate !== null
            ? item.ad_conversion_rate.toFixed(2)
            : '0.00';
        tempData[index].impressionsCurrentLabel =
          item?.impressions !== null ? item.impressions : '0';
        tempData[index].adCosCurrentLabel =
          item?.acos !== null ? item.acos.toFixed(2) : '0.00';
        tempData[index].adRoasCurrentLabel =
          item?.roas !== null ? item.roas.toFixed(2) : '0.00';
        tempData[index].adClicksCurrentLabel =
          item?.clicks !== null ? item.clicks : '0';
        tempData[index].adClickRateCurrentLabel =
          item?.ctr !== null ? item.ctr.toFixed(2) : '0.00';
        tempData[index].costPerClickCurrentLabel =
          item?.cost_per_click !== null
            ? item.cost_per_click.toFixed(2)
            : '0.00';
        tempData[index].salesPerClickCurrentLabel =
          item?.sales_per_click !== null
            ? item.sales_per_click.toFixed(2)
            : '0.00';
        tempData[index].percentageNtbOrdersCurrentLabel =
          item?.ntb_orders_percent ? item.ntb_orders_percent.toFixed(2) : '0';
        tempData[index].percentageNtbSalesCurrentLabel = item?.ntb_sales_percent
          ? item?.ntb_sales_percent.toFixed(2)
          : '0';
        tempData[index].ntbOrdersCurrentLabel = item?.ntb_orders
          ? item?.ntb_orders.toFixed(2)
          : '0';
        tempData[index].ntbSalesCurrentLabel = item?.ntb_sales
          ? item?.ntb_sales.toFixed(2)
          : '0';
      } else {
        // if current data count is larger than previous count then
        // generate separate key for current data and defien previou value null and previous label 0
        tempData.push({
          adSalesCurrent: item?.ad_sales,
          adSpendCurrent: item?.ad_spend,
          adConversionCurrent: item?.ad_conversion_rate,
          impressionsCurrent: item?.impressions,
          adCosCurrent: item?.acos,
          adRoasCurrent: item?.roas,
          adClicksCurrent: item?.clicks,
          adClickRateCurrent: item?.ctr,
          costPerClickCurrent: item?.cost_per_click,
          salesPerClickCurrent: item?.sales_per_click,
          percentageNtbOrdersCurrent: item?.ntb_orders_percent,
          percentageNtbSalesCurrent: item?.ntb_sales_percent,
          ntbOrdersCurrent: item?.ntb_orders,
          ntbSalesCurrent: item?.ntb_sales,
          date: currentReportDate,
          endDate: nextReportDate(
            index,
            response?.daily_facts?.current,
            'current',
          ),

          adSalesPrevious: null,
          adSpendPrevious: null,
          adConversionPrevious: null,
          impressionsPrevious: null,
          adCosPrevious: null,
          adRoasPrevious: null,
          adClicksPrevious: null,
          adClickRatePrevious: null,
          costPerClickPrevious: null,
          salesPerClickPrevious: null,
          percentageNtbOrders: null,
          percentageNtbSales: null,
          ntbOrders: null,
          ntbSales: null,

          adSalesCurrentLabel:
            item?.ad_sales !== null ? item.ad_sales.toFixed(2) : '0.00',
          adSpendCurrentLabel:
            item?.ad_spend !== null ? item.ad_spend.toFixed(2) : '0.00',
          adConversionCurrentLabel:
            item?.ad_conversion_rate !== null
              ? item.ad_conversion_rate.toFixed(2)
              : '0.00',
          impressionsCurrentLabel:
            item?.impressions !== null ? item.impressions : '0',
          adCosCurrentLabel:
            item?.acos !== null ? item.acos.toFixed(2) : '0.00',
          adRoasCurrentLabel:
            item?.roas !== null ? item.roas.toFixed(2) : '0.00',
          adClicksCurrentLabel: item?.clicks !== null ? item.clicks : '0',
          adClickRateCurrentLabel:
            item?.ctr !== null ? item.ctr.toFixed(2) : '0.00',
          costPerClickCurrentLabel:
            item?.cost_per_click !== null
              ? item.cost_per_click.toFixed(2)
              : '0.00',
          salesPerClickCurrentLabel:
            item?.sales_per_click !== null
              ? item.sales_per_click.toFixed(2)
              : '0.00',
          percentageNtbOrdersCurrentLabel: item?.ntb_orders_percent
            ? item?.ntb_orders_percent.toFixed(2)
            : '0',
          percentageNtbSalesCurrentLabel: item?.ntb_sales_percent
            ? item?.ntb_sales_percent.toFixed(2)
            : '0',
          ntbOrdersCurrentLabel: item?.ntb_orders
            ? item?.ntb_orders.toFixed(2)
            : '0',
          ntbSalesCurrentLabel: item?.ntb_sales
            ? item?.ntb_sales.toFixed(2)
            : '0',

          adSalesPreviousLabel: '0.00',
          adSpendPreviousLabel: '0.00',
          adConversionPreviousLabel: '0.00',
          impressionsPreviousLabel: '0',
          adCosPreviousLabel: '0.00',
          adRoasPreviousLabel: '0.00',
          adClicksPreviousLabel: '0',
          adClickRatePreviousLabel: '0.00',
          costPerClickPreviousLabel: '0.00',
          salesPerClickPreviousLabel: '0.00',
          percentageNtbOrdersPreviousLabel: '0',
          percentageNtbSalesPreviousLabel: '0',
          ntbOrdersPreviousLabel: '0',
          ntbSalesPreviousLabel: '0',
        });
      }
    });
  }

  return tempData;
};

export default BindSponsoredAdData;
