/* ********** React imports ********** */
import React, { useState } from 'react';

/* ********** Third party package imports ********** */
import { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, bool, func, shape, string } from 'prop-types';

/* ********** Local component file imports ********** */
import { CaretUp } from '../../../../theme/images';
import {
  SELLER,
  SELLERSMALL,
  VENDOR,
  VENDORSMALL,
} from '../../../../constants';
import {
  DropdownIndicator,
  MultiSelectSearchableDropdown,
  SingleSelectDropdown,
  WhiteCard,
} from '../../../../common';

/* ********** MAIN FUNCTION START HERE ********** */
const SalesFilter = ({
  // boolean
  isApiCall,
  isBGSAdmin,
  isBGSManager,
  isAdTeamManager,
  isSpreetailRole,
  isAdManagerAdmin,
  selectedBrandPartner,
  // objects
  selectedMarketplace,
  selectedAccountType,
  // array
  userList,
  selectedUser,
  managerUserList,
  marketplaceOptions,
  brandPartnerOptions,
  selectedManagerUser,
  // function
  setSelectedUser,
  handleResetFilter,
  handleMarketplace,
  setSelectedManagerUser,
  handleBgsUserFilterEvents,
  handleUserOnMenuCloseEvents,
  handleManagerUserFilterEvents,
  handleSellerVendorFilterEvents,
  handleBrandPartnerFilterEvents,
  handleManagerUserOnMenuCloseEvents,
}) => {
  // media query
  const isDesktop = useMediaQuery({ minWidth: 992 });

  // components for dropdown
  const { Option, SingleValue } = components;

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  // condition for show manager dropdown filter
  const showManagerUserFilter =
    isBGSAdmin || isAdManagerAdmin || isSpreetailRole;

  // condition for show user dropdown filter
  const showUserDropdownfilter =
    isBGSManager ||
    isBGSAdmin ||
    isAdManagerAdmin ||
    isAdTeamManager ||
    isSpreetailRole;

  // https://bbe.atlassian.net/browse/PDV-8757 - leadership dashboard improvements
  // Declaring account type options
  const bpAccountType = selectedBrandPartner?.accountType;
  const accountTypeOptions = [
    {
      label: 'Seller Reporting',
      value: SELLERSMALL,
      isDisabled: bpAccountType === VENDOR,
    },
    {
      label: 'Vendor Reporting',
      value: VENDORSMALL,
      isDisabled: bpAccountType === SELLER,
    },
  ];

  // get SingleValue component for selected value from dropdown options
  const dropdownFilterSelectedValue = (props) => {
    return (
      <SingleValue {...props}>
        <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
      </SingleValue>
    );
  };

  // get Option component for dropdown options
  const dropdownFilterOptions = (props) => {
    return (
      <Option {...props}>
        <div className={props?.isDisabled ? 'disabled' : ''}>
          <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
        </div>
      </Option>
    );
  };

  // configuring the dropdown select components
  const getDropdownSelectComponents = () => {
    return {
      Option: dropdownFilterOptions,
      SingleValue: dropdownFilterSelectedValue,
      DropdownIndicator,
    };
  };

  // generate html for single select dropdown filter
  const generateSingleSelectDropdownFilterHTML = (
    filterLabel,
    filterId,
    className,
    dropdownOptions,
    selectedValue,
    onChangeHandler,
    isSearchable,
  ) => {
    return (
      <>
        <div className="label mt-3">{filterLabel}</div>
        <div style={{ position: 'relative' }}>
          <SingleSelectDropdown
            filterId={filterId}
            className={className}
            dropdownOptions={dropdownOptions}
            selectedValue={selectedValue}
            onChangeHandler={onChangeHandler}
            dropdownComponents={getDropdownSelectComponents}
            isApiCall={isApiCall}
            isSearchable={isSearchable}
          />
        </div>
      </>
    );
  };

  // https://bbe.atlassian.net/browse/PDV-8757 - leadership dashboard improvements
  // display Brand Partner type dropdwon filter
  const displayBrandPartnerFilter = () => {
    return generateSingleSelectDropdownFilterHTML(
      'Brand Partner',
      'BT-SalesBrandPartnerTypeFilter',
      '',
      brandPartnerOptions,
      selectedBrandPartner,
      handleBrandPartnerFilterEvents,
      true,
    );
  };

  // render performance type dropdwon filter
  const displayAccountTypeFilter = () => {
    return generateSingleSelectDropdownFilterHTML(
      'Performance',
      'BT-SalesPerformanceTypeFilter',
      '',
      accountTypeOptions,
      selectedAccountType,
      handleSellerVendorFilterEvents,
      false,
    );
  };

  // render marketp[lace dropdown filter
  const displayMarketplaceFilter = () => {
    return generateSingleSelectDropdownFilterHTML(
      'Marketplace',
      'BT-SalesDashboardMarketplaceFilter',
      '',
      marketplaceOptions,
      selectedMarketplace,
      handleMarketplace,
      true,
    );
  };

  // render multi select dropdown for bgs and ad manager
  const renderUserDropdown = () => {
    return (
      <>
        <div className="label mt-3">
          {isBGSAdmin || isBGSManager || isSpreetailRole ? 'BGS' : 'Ad Manager'}
        </div>

        <div id="user-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-SalesUserFilter"
            options={userList?.filter((option) => option?.value !== 'all')}
            label=""
            value="user"
            multiSelectedValues={{ user: selectedUser }}
            setMultiSelectedValues={setSelectedUser}
            handleMultiSelectChange={handleBgsUserFilterEvents}
            handleOnMenuClose={handleUserOnMenuCloseEvents}
            isLoading={isApiCall}
            isSearchable
          />
        </div>
      </>
    );
  };

  // render multi select dropdown for bgs manager and ad team manager
  const renderManagerUserDropdown = () => {
    return (
      <>
        <div className="label mt-3">
          {isBGSAdmin || isSpreetailRole ? 'BGS Manager' : 'Ad Team Manager'}
        </div>
        <div id="manager-user-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-SalesManagerUserFilter"
            options={managerUserList?.filter(
              (option) => option?.value !== 'all',
            )}
            label=""
            value="managerUser"
            multiSelectedValues={{ managerUser: selectedManagerUser }}
            setMultiSelectedValues={setSelectedManagerUser}
            handleMultiSelectChange={handleManagerUserFilterEvents}
            handleOnMenuClose={handleManagerUserOnMenuCloseEvents}
            isLoading={isApiCall}
            isSearchable
          />
        </div>
      </>
    );
  };

  // desktop view of filters
  const displaySalesFiltersDesktopView = () => {
    return (
      <WhiteCard className="mb-3 d-lg-block d-none">
        <div className="row">
          <div className="col-6">
            <div className="black-heading-title">Filters</div>
          </div>
          <div className="col-6 text-right">
            <p
              role="presentation"
              onClick={() => handleResetFilter()}
              className={`gray-normal-text m-0 cursor ${
                isApiCall ? 'disabled' : ''
              }`}
            >
              Reset filters
            </p>
          </div>
          <div className="col-12">{displayBrandPartnerFilter()}</div>
          <div className="col-12">{displayAccountTypeFilter()}</div>
          <div className="col-12">{displayMarketplaceFilter()}</div>
          {showManagerUserFilter ? (
            <div className="col-12">
              {renderManagerUserDropdown(
                isBGSAdmin || isSpreetailRole
                  ? 'BGS Manager'
                  : 'Ad Team Manager',
              )}
            </div>
          ) : null}
          {showUserDropdownfilter ? (
            <div className="col-12">{renderUserDropdown()}</div>
          ) : null}
        </div>
      </WhiteCard>
    );
  };

  // mobile and tablet view of filters
  const displaySalesFiltersMobileView = () => {
    return (
      <WhiteCard
        onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        className="mb-3 d-lg-none d-block"
      >
        <div className="row">
          <div className="col-6">
            <div className="black-heading-title">Filters</div>
          </div>
          <div className="col-6 text-right">
            <p
              className={`gray-normal-text m-0 cursor ${
                isApiCall ? 'disabled' : ''
              }`}
              role="presentation"
              onClick={() => handleResetFilter()}
            >
              Reset filters
              <span
                style={{
                  borderLeft: '1px solid #e2e2ea',
                  marginLeft: '10px',
                  marginRight: '20px',
                }}
              />
              <span
                role="presentation"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCollapseOpen(!isCollapseOpen);
                }}
              >
                <img
                  src={CaretUp}
                  alt="caret"
                  style={{
                    transform: isCollapseOpen ? 'rotate(180deg)' : '',
                    width: '25px',
                    height: '25px',
                    position: 'absolute',
                    right: '5px',
                    top: '-3px',
                  }}
                />
              </span>
            </p>
          </div>
        </div>
        {isCollapseOpen ? (
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              {displayBrandPartnerFilter()}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {displayAccountTypeFilter()}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {displayMarketplaceFilter()}
            </div>
            {showManagerUserFilter ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                {renderManagerUserDropdown()}
              </div>
            ) : null}

            {showUserDropdownfilter ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                {renderUserDropdown()}
              </div>
            ) : null}
          </div>
        ) : null}
      </WhiteCard>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      {isDesktop
        ? displaySalesFiltersDesktopView()
        : displaySalesFiltersMobileView()}
    </>
  );
};

export default SalesFilter;
/* ********** MAIN FUNCTION END HERE ********** */

/* ********** Declaring prop types ********** */
SalesFilter.defaultProps = {
  // boolean
  isApiCall: false,
  isDisabled: false,
  isBGSAdmin: false,
  isBGSManager: false,
  isSpreetailRole: false,
  isAdTeamManager: false,
  isAdManagerAdmin: false,
  // objects
  data: {},
  selectedAccountType: {},
  selectedMarketplace: {},
  selectedBrandPartner: {},
  // array
  userList: [],
  selectedUser: [],
  managerUserList: [],
  marketplaceOptions: [],
  selectedManagerUser: [],
  brandPartnerOptions: [],
  // function
  setSelectedUser: () => {},
  handleResetFilter: () => {},
  handleMarketplace: () => {},
  getSelectComponents: () => {},
  setSelectedManagerUser: () => {},
  handleBgsUserFilterEvents: () => {},
  handleUserOnMenuCloseEvents: () => {},
  handleManagerUserFilterEvents: () => {},
  handleSellerVendorFilterEvents: () => {},
  handleBrandPartnerFilterEvents: () => {},
  handleManagerUserOnMenuCloseEvents: () => {},
};

SalesFilter.propTypes = {
  // boolean
  isApiCall: bool,
  isDisabled: bool,
  isBGSAdmin: bool,
  isBGSManager: bool,
  isAdTeamManager: bool,
  isSpreetailRole: bool,
  isAdManagerAdmin: bool,
  // objects
  selectedAccountType: shape({}),
  selectedMarketplace: shape({}),
  selectedBrandPartner: shape({}),
  data: shape({ sub: string, label: string }),
  // array
  userList: arrayOf(shape({})),
  selectedUser: arrayOf(shape({})),
  managerUserList: arrayOf(shape({})),
  marketplaceOptions: arrayOf(shape({})),
  selectedManagerUser: arrayOf(shape({})),
  brandPartnerOptions: arrayOf(shape({})),
  // function
  setSelectedUser: func,
  handleResetFilter: func,
  handleMarketplace: func,
  getSelectComponents: func,
  setSelectedManagerUser: func,
  handleBgsUserFilterEvents: func,
  handleUserOnMenuCloseEvents: func,
  handleManagerUserFilterEvents: func,
  handleSellerVendorFilterEvents: func,
  handleBrandPartnerFilterEvents: func,
  handleManagerUserOnMenuCloseEvents: func,
};
