import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Select, { components } from 'react-select';
import { func, shape, arrayOf } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../theme/Theme';
import { SpreetailRole, SpreetailRoleRestrictMessage } from '../constants';

const DropDownUncontained = ({
  options,
  setSelectedOption,
  extraAction,
  DropdownIndicator,
  multipleAgreement,
}) => {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const { Option, SingleValue } = components;
  const [disableAgreementOption, setDisableAgreementOption] = useState({
    recurring: false,
    ninetydays: false,
    dsp: false,
  });

  const checkAgreementType = useCallback(
    (type) => {
      return multipleAgreement.filter(
        (op) =>
          op.contract_type === type &&
          (op.contract_status?.value === 'pending contract' ||
            op.contract_status?.value === 'pending contract approval' ||
            op.contract_status?.value === 'pending contract signature'),
      );
    },
    [multipleAgreement],
  );

  useEffect(() => {
    const dsp = checkAgreementType('dsp only');
    const recurring = checkAgreementType('recurring');
    const recurring90days = checkAgreementType('recurring (90 day notice)');
    setDisableAgreementOption({
      recurring: recurring?.length > 0,
      ninetydays: recurring90days?.length > 0,
      dsp: dsp?.length > 0,
    });
  }, [checkAgreementType, setDisableAgreementOption]);

  const disableOption = (value) => {
    if (disableAgreementOption[value]) return true;
    return false;
  };

  const onChange = (selected) => {
    if (!disableOption(selected.type)) {
      setSelectedOption(selected);
      extraAction(selected);
    }
  };

  const IconOption = (dataProps) => (
    <Option {...dataProps}>
      <div
        className={disableOption(dataProps.data.type) ? 'inactive-option' : ''}
        style={{
          fontSize: Theme.extraMedium,
          fontWeight: '500',
          color: disableOption(dataProps.data.type) ? '#8798AD' : '#000',
          cursor: !disableOption(dataProps.data.type)
            ? 'pointer'
            : 'not-allowed',
        }}
        data-tip="The same type of agreement has already been drafted/created.<br/>
        ● Option 1: Proceed to edit the previously created agreement.<br/>
        ● Option 2: Delete the previously created agreement in order to create an new agreement. "
        data-for={dataProps.data.type}
      >
        {dataProps.data.label}
        <div
          style={{ fontSize: '12px', color: '#556178', paddingBottom: '8px' }}
          className="sub-title"
        >
          {' '}
          {dataProps.data.sub}
        </div>{' '}
        {disableOption(dataProps.data.type) ? (
          <ReactTooltip
            id={dataProps.data.type}
            aria-haspopup="true"
            place="bottom"
            effect="solid"
            html
          />
        ) : null}
      </div>
    </Option>
  );

  const IconSingleOption = (dataProps) => (
    <SingleValue {...dataProps}>
      <AddNewAgreement className=" checkbox" role="presentation">
        <span className="cursor check-container-label">
          <FontAwesomeIcon
            icon="fa-regular fa-plus"
            className="add-icon ml-2"
          />
          Add New Agreement
        </span>
      </AddNewAgreement>
    </SingleValue>
  );

  const getSelectComponents = () => {
    return {
      Option: IconOption,
      SingleValue: IconSingleOption,
      DropdownIndicator,
    };
  };

  return (
    <>
      <div
        data-tip={SpreetailRoleRestrictMessage}
        data-for={isSpreetailUser ? 'spreetailRetrictCreateAgreement' : ''}
      >
        <AddNewAgreementDropDown
          className={`w-50 ${isSpreetailUser ? 'disabled' : ''}`}
        >
          <Select
            classNamePrefix="react-select"
            options={options}
            value={{
              value: 'standard_cancellation',
              label: 'Recurring Service Agreement',
              sub: 'Standard Cancellation',
            }}
            isSearchable={false}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: Theme.lightOrange,
                primary50: Theme.lightOrange,
              },
            })}
            onChange={onChange}
            components={getSelectComponents()}
            componentsValue={{ Option: IconOption }}
          />
        </AddNewAgreementDropDown>
      </div>
      <ReactTooltip
        id="spreetailRetrictCreateAgreement"
        aria-haspopup="true"
        place="top"
        effect="solid"
        html
      />
    </>
  );
};

export default DropDownUncontained;

DropDownUncontained.defaultProps = {
  extraAction: () => {},
  multipleAgreement: {},
};

DropDownUncontained.propTypes = {
  options: arrayOf(shape({})).isRequired,
  extraAction: func,
  DropdownIndicator: func.isRequired,
  setSelectedOption: func.isRequired,
  multipleAgreement: shape({}),
};

const AddNewAgreement = styled.div`
  color: ${Theme.gray85};
  font-size: ${Theme.extraNormal};

  .dropdown-arrow-icon {
    vertical-align: middle;
    margin-left: 6px;
    transform: ${(props) =>
      props.showAgreementOptions ? 'rotate(180deg)' : ''};
  }
  .check-container-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    height: 10px;
    width: 170px;
  }
  .add-icon {
    font-size: ${Theme.extraSmall};
    color: ${Theme.orange};
    margin-right: 7px;
  }
`;

const AddNewAgreementDropDown = styled.div`
  &.w-50 {
    width: 175px;
  }
  .css-2b097c-container {
    .css-yk16xz-control {
      background-color: transparent;
      border-style: none;
      min-height: 40px;
      margin: 0;
      border-width: 0px;
      .css-g1d714-ValueContainer {
        padding: 4px 0;
      }
      .css-tlfecz-indicatorContainer {
        padding: 10px 0 0 0;
        cursor: pointer;
        img {
          width: 18px !important;
          height: 18px !important;
        }
      }
      &:hover {
        border-color: none !important;
      }
    }
    .css-1pahdxg-control {
      border-color: transparent !important;
      box-shadow: none !important;
      outline: none;
      background-color: transparent;
      border-width: 0px;
      margin: 0;
      caret-color: transparent;
      min-height: 40px;
      .css-g1d714-ValueContainer {
        padding: 4px 0;
      }
      .css-1gtu0rj-indicatorContainer {
        padding: 10px 0 0 0;
        cursor: pointer;
        img {
          width: 18px !important;
          height: 18px !important;
        }
      }
      /* .css-1uccc91-singleValue {
        margin: 0;
      } */
      &:hover {
        border-color: none !important;
      }
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .react-select__menu {
      width: 220px !important;
      width: 100%;
      .css-26l3qy-menu {
        max-width: 220px !important;
        width: 100%;
      }
    }
  }
`;
