import React, { useEffect, useState } from 'react';

// third party Imports
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Local files imports
import NavigationHeader from './NavigationHeader';
import { showOnboardingMsg } from '../../store/actions/userState';
import { logout, userMe } from '../../store/actions';
import { accountSummary, updateUserMe, getAmazonAccount } from '../../api';
import { GrayClockIcon, OrangeCheckMark } from '../../theme/images';
import { OnBoardingBody, GreyCard, Button, PageLoader } from '../../common';
import {
  pageTitleConditions,
  PATH_AMAZON_ACCOUNT,
  PATH_BILLING_DETAILS,
  PATH_CUSTOMER_DETAILS,
  stepPath,
} from '../../constants';

export default function Summary() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [data, setData] = useState([]);
  const [showDashboard, setShowDashboard] = useState(false);
  const userInfo = useSelector((state) => state.userState.userInfo);
  const [skipAmazonAccount, setSkipAmazonAccount] = useState(false);

  // Call the function if only page url is get changed (Code to add page title)
  useEffect(() => {
    pageTitleConditions(history.location);
    history.listen((location) => {
      pageTitleConditions(location);
    });
  }, [history]);

  useEffect(() => {
    let customerOnboardingId = userInfo.customer_onboarding;
    if (Array.isArray(customerOnboardingId)) {
      const ele = customerOnboardingId?.find(
        (item) => Object.values(item)?.[0] === localStorage.getItem('customer'),
      );
      customerOnboardingId = Object.keys(ele)?.[0];
    }

    // this function call is just for DSPN only BP.
    // to enabled Submit button for DSP only BP whether they skip step 4 and 5 or complete
    // to know BP type below api call used. in the response of this api if account type is null
    // means this is DSP only BP. and set showDashboard variable to true so that submit btn will be visible.
    getAmazonAccount(localStorage.getItem('customer')).then((res) => {
      if (res?.status === 200) {
        accountSummary(customerOnboardingId).then((response) => {
          setData(response?.data);
          const skip = response?.data?.find((op) => op.step === 'merchant id');
          setSkipAmazonAccount(skip?.step_not_applicable);
          setIsLoading({ loader: false, type: 'page' });
          // to get to know DSP only BP .. for DSP only BP account type is null
          if (res?.data?.results?.[0]?.account_type === null) {
            setShowDashboard(true);
          } else if (response?.data?.length < 4) {
            setShowDashboard(false);
          } else if (
            response?.data?.some((item) => item.is_completed === false)
          ) {
            setShowDashboard(false);
          } else {
            setShowDashboard(true);
          }
        });
      }
    });
  }, [userInfo.customer_onboarding]);

  const getPath = (step, type) => {
    for (const item of stepPath) {
      if (item.key === step) {
        if (type === 'label') {
          return item.title;
        }
        return item.view;
      }
    }
    return '';
  };

  const redirect = () => {
    setIsLoading({ loader: true, type: 'button' });
    updateUserMe(userInfo.id, {
      step: { ...userInfo.step, [userInfo.customer]: 6 },
    }).then((user) => {
      if (user && user.status === 200) {
        dispatch(userMe());
        dispatch(showOnboardingMsg(true));
        history.push(PATH_CUSTOMER_DETAILS.replace(':id', userInfo.customer));
        setIsLoading({ loader: false, type: 'button' });
      } else {
        setIsLoading({ loader: false, type: 'button' });
      }
    });
    localStorage.removeItem('match');
  };

  return (
    <>
      <NavigationHeader
        bar="95"
        backStep={
          skipAmazonAccount ? PATH_BILLING_DETAILS : PATH_AMAZON_ACCOUNT
        }
        userInfo={userInfo}
        verifiedStepData={{}}
        stepData={{}}
        stepName="summary"
      />
      {isLoading.loader && isLoading.type === 'page' ? (
        <PageLoader color="#FF5933" type="page" />
      ) : (
        <OnBoardingBody className="body-white pt-5">
          <div className="white-card-base panel pb-4">
            <h3 className="page-heading ">Account Summary</h3>

            <div className="sub-information mb-3">
              Thanks! Please review the information below and click
              <strong> Submit & Complete Onboarding</strong> to complete this
              process.
            </div>

            <div className="sub-information mt-1 mb-3">
              Within 24 hours of completing your account setup, expect to hear
              from your On-boarding Specialist. They will be walking you through
              those final set up items and getting you in contact with your
              Brand Growth Strategist.
            </div>
            <GreyCard>
              <div className="information-text mt-2">
                Create Your Account
                <div className="completed-status">
                  <img
                    className="orange-check"
                    src={OrangeCheckMark}
                    alt="check"
                  />
                  Completed
                </div>
              </div>

              {data?.map((item) => (
                <div className="information-text mt-1" key={item.step}>
                  {getPath(item?.step, 'label')}
                  {item?.is_completed ? (
                    <div
                      className={
                        skipAmazonAccount &&
                        (item?.step === 'merchant id' ||
                          item?.step === 'amazon account')
                          ? 'pending-status'
                          : 'completed-status'
                      }
                    >
                      {skipAmazonAccount &&
                      (item?.step === 'merchant id' ||
                        item?.step === 'amazon account') ? (
                        ''
                      ) : (
                        <img
                          className="orange-check"
                          src={OrangeCheckMark}
                          alt="check"
                        />
                      )}
                      {skipAmazonAccount &&
                      (item?.step === 'merchant id' ||
                        item?.step === 'amazon account')
                        ? 'Step not applicable for your contract type'
                        : userInfo?.email === item?.email || item?.email === ''
                        ? ' Completed'
                        : `Provided by ${item?.email}`}
                    </div>
                  ) : (
                    <div className="pending-status">
                      <img
                        className="pending-icon"
                        src={GrayClockIcon}
                        alt="clock"
                      />

                      {item?.email === userInfo?.email || item?.email === ''
                        ? 'Skipped'
                        : `Assigned to ${item?.email}`}
                    </div>
                  )}

                  {skipAmazonAccount &&
                  (item?.step === 'merchant id' ||
                    item?.step === 'amazon account') ? (
                    ''
                  ) : (
                    <div
                      className="view-details"
                      onClick={() => history.push(getPath(item?.step, 'path'))}
                      role="presentation"
                    >
                      {' '}
                      View
                    </div>
                  )}
                </div>
              ))}
            </GreyCard>

            {showDashboard ? (
              <Button
                className="btn-primary w-100 mt-4 "
                onClick={() => redirect()}
              >
                {isLoading.loader && isLoading.type === 'button' ? (
                  <PageLoader color="#fff" type="button" />
                ) : (
                  'Submit & Complete Onboarding'
                )}
              </Button>
            ) : (
              <GreyCard className="yellow-card mt-2  ">
                Once all the sections above have been completed you’ll get full
                access to your account dashboard. Your progress so far has been
                saved so you can{' '}
                <u
                  onClick={() => dispatch(logout())}
                  role="presentation"
                  style={{ fontSize: '15px' }}
                  className="cursor link-url"
                >
                  sign out
                </u>{' '}
                or close this tab until you or an assignee provides the
                remaining information.
              </GreyCard>
            )}
          </div>
        </OnBoardingBody>
      )}
    </>
  );
}
