import dayjs from 'dayjs';

const BindSellerSalesPerformanceData = (response) => {
  const tempData = [];

  // get a last date of the week
  const getOneDayBack = (reportDateTemp) => {
    const reportDateResultTemp = new Date(reportDateTemp);
    reportDateResultTemp.setDate(reportDateResultTemp.getDate() - 1);
    return reportDateResultTemp;
  };

  const nextReportDate = (indexTemp, graphDataArray, dateParam) => {
    return dayjs(
      indexTemp + 1 < graphDataArray?.length
        ? getOneDayBack(graphDataArray[indexTemp + 1]?.report_date)
        : response?.dates?.[
            dateParam === 'current' ? 'current_end_date' : 'prevous_end_date'
          ],
    ).format('MMM D YYYY');
  };

  // filterout previous data in one temporary object.
  if (response?.previous?.length) {
    response.previous.forEach((item, index) => {
      const previousDate = dayjs(item?.report_date).format('MMM D YYYY');
      tempData.push({
        revenueTotalPrevious: item?.revenue,
        revenueOrganicPrevious: item?.revenue_organic,
        revenuePpcPrevious: item?.revenue_ppc,
        revenueDspPrevious: item?.revenue_dsp,
        unitsSoldPrevious: item?.units_sold,
        trafficPrevious: item?.traffic,
        conversionPrevious: item?.conversion,
        // ad spend and tacos start
        adSpendTotalPrevious: item?.ad_spend_total,
        adSpendPpcPrevious: item?.sponsored_ad_spend,
        adSpendDspPrevious: item?.dsp_spends,
        tacosPrevious: item?.tacos,
        // ad spend and tacos ends
        previousDate,
        previousEndDate: nextReportDate(index, response?.previous, 'previous'),

        revenueTotalPreviousLabel:
          item?.revenue !== null ? item.revenue.toFixed(2) : '0.00',
        revenueOrganicPreviousLabel:
          item?.revenue_organic !== null
            ? item.revenue_organic?.toFixed(2)
            : '0.00',
        revenuePpcPreviousLabel:
          item?.revenue_ppc !== null ? item.revenue_ppc?.toFixed(2) : '0.00',
        revenueDspPreviousLabel:
          item?.revenue_dsp !== null ? item.revenue_dsp?.toFixed(2) : '0.00',
        unitsSoldPreviousLabel:
          item?.units_sold !== null ? item.units_sold?.toFixed(2) : '0.00',
        trafficPreviousLabel:
          item?.traffic !== null ? item.traffic?.toFixed(2) : '0.00',
        conversionPreviousLabel:
          item?.conversion !== null ? item.conversion?.toFixed(2) : '0',
        // ad spend and tacos start
        adSpendTotalPreviousLabel:
          item?.ad_spend_total !== null
            ? item.ad_spend_total.toFixed(2)
            : '0.00',
        adSpendPpcPreviousLabel:
          item?.sponsored_ad_spend !== null
            ? item.sponsored_ad_spend?.toFixed(2)
            : '0.00',
        adSpendDspPreviousLabel:
          item?.dsp_spends !== null ? item.dsp_spends?.toFixed(2) : '0.00',
        tacosPreviousLabel: item.tacos !== null ? item.tacos?.toFixed(2) : '0',
        // ad spend and tacos ends
      });
    });
  }

  // filterout current data in one temporary object.
  if (response?.current?.length) {
    response.current.forEach((item, index) => {
      const currentReportDate = dayjs(item?.report_date).format('MMM D YYYY');
      // add the current data at same index of prevoius in temporary object
      if (response.previous && index < response.previous.length) {
        tempData[index].date = currentReportDate;
        tempData[index].revenueTotalCurrent = item?.revenue;
        tempData[index].revenueOrganicCurrent = item?.revenue_organic;
        tempData[index].revenuePpcCurrent = item?.revenue_ppc;
        tempData[index].revenueDspCurrent = item?.revenue_dsp;
        tempData[index].unitsSoldCurrent = item?.units_sold;
        tempData[index].trafficCurrent = item?.traffic;
        tempData[index].conversionCurrent = item?.conversion;
        // ad spend and tacos start
        tempData[index].adSpendTotalCurrent = item?.ad_spend_total;
        tempData[index].adSpendPpcCurrent = item?.sponsored_ad_spend;
        tempData[index].adSpendDspCurrent = item?.dsp_spends;
        tempData[index].tacosCurrent = item?.tacos;
        // ad spend and tacos ends
        tempData[index].endDate = nextReportDate(
          index,
          response?.current,
          'current',
        );

        tempData[index].revenueTotalCurrentLabel =
          item?.revenue !== null ? item.revenue.toFixed(2) : '0.00';
        tempData[index].revenueOrganicCurrentLabel =
          item?.revenue_organic !== null
            ? item.revenue_organic?.toFixed(2)
            : '0.00';
        tempData[index].revenuePpcCurrentLabel =
          item?.revenue_ppc !== null ? item.revenue_ppc?.toFixed(2) : '0.00';
        tempData[index].revenueDspCurrentLabel =
          item?.revenue_dsp !== null ? item.revenue_dsp?.toFixed(2) : '0.00';
        tempData[index].unitsSoldCurrentLabel =
          item?.units_sold !== null ? item.units_sold?.toFixed(2) : '0.00';
        tempData[index].trafficCurrentLabel =
          item?.traffic !== null ? item.traffic?.toFixed(2) : '0.00';
        tempData[index].conversionCurrentLabel =
          item?.conversion !== null ? item.conversion?.toFixed(2) : '0';
        // ad spend and tacos start
        tempData[index].adSpendTotalCurrentLabel =
          item?.ad_spend_total !== null
            ? item.ad_spend_total.toFixed(2)
            : '0.00';
        tempData[index].adSpendPpcCurrentLabel =
          item?.sponsored_ad_spend !== null
            ? item.sponsored_ad_spend?.toFixed(2)
            : '0.00';
        tempData[index].adSpendDspCurrentLabel =
          item?.dsp_spends !== null ? item.dsp_spends?.toFixed(2) : '0.00';
        tempData[index].tacosCurrentLabel =
          item?.tacos !== null ? item.tacos?.toFixed(2) : '0';
        // ad spend and tacos ends
      } else {
        // if current data count is larger than previous count then
        // generate separate key for current data and defien previous value null and previous label 0
        tempData.push({
          revenueTotalCurrent: item?.revenue,
          revenueOrganicCurrent: item?.revenue_organic,
          revenuePpcCurrent: item?.revenue_ppc,
          revenueDspCurrent: item?.revenue_dsp,
          unitsSoldCurrent: item?.units_sold,
          trafficCurrent: item?.traffic,
          conversionCurrent: item?.conversion,
          // ad spend and tacos start
          adSpendTotalCurrent: item?.ad_spend_total,
          adSpendPpcCurrent: item?.sponsored_ad_spend,
          adSpendDspCurrent: item?.dsp_spends,
          tacosCurrent: item?.tacos,
          // ad spend and tacos ends
          date: currentReportDate,
          endDate: nextReportDate(
            index,
            response?.daily_facts?.current,
            'current',
          ),

          revenueTotalPrevious: null,
          revenueOrganicPrevious: null,
          revenuePpcPrevious: null,
          revenueDspPrevious: null,
          unitsSoldPrevious: null,
          trafficPrevious: null,
          conversionPrevious: null,
          // ad spend and tacos start
          adSpendTotalPrevious: null,
          adSpendPpcPrevious: null,
          adSpendDspPrevious: null,
          tacosPrevious: null,
          // ad spend and tacos ends

          revenueTotalCurrentLabel:
            item?.revenue !== null ? item.revenue.toFixed(2) : '0.00',
          revenueOrganicCurrentLabel:
            item?.revenue_organic !== null
              ? item.revenue_organic?.toFixed(2)
              : '0.00',
          revenuePpcCurrentLabel:
            item?.revenue_ppc !== null ? item.revenue_ppc?.toFixed(2) : '0.00',
          revenueDspCurrentLabel:
            item?.revenue_dsp !== null ? item.revenue_dsp?.toFixed(2) : '0.00',
          unitsSoldCurrentLabel:
            item?.units_sold !== null ? item.units_sold?.toFixed(2) : '0.00',
          trafficCurrentLabel:
            item?.traffic !== null ? item.traffic?.toFixed(2) : '0.00',
          conversionCurrentLabel:
            item?.conversion !== null ? item.conversion?.toFixed(2) : '0',
          // ad spend and tacos start
          adSpendTotalCurrentLabel:
            item?.ad_spend_total !== null
              ? item.ad_spend_total.toFixed(2)
              : '0.00',
          adSpendPpcCurrentLabel:
            item?.sponsored_ad_spend !== null
              ? item.sponsored_ad_spend?.toFixed(2)
              : '0.00',
          adSpendDspCurrentLabel:
            item?.dsp_spends !== null ? item.dsp_spends?.toFixed(2) : '0.00',
          tacosCurrentLabel:
            item?.tacos !== null ? item.tacos?.toFixed(2) : '0',
          // ad spend and tacos ends

          revenueTotalPreviousLabel: '0.0',
          revenueOrganicPreviousLabel: '0.0',
          revenuePpcPreviousLabel: '0.0',
          revenueDspPreviousLabel: '0.0',
          unitsSoldPreviousLabel: '0.00',
          trafficPreviousLabel: '0.00',
          conversionPreviousLabel: '0',
          // ad spend and tacos start
          adSpendTotalPreviousLabel: '0.0',
          adSpendPpcPreviousLabel: '0.0',
          adSpendDspPreviousLabel: '0.0',
          tacosPreviousLabel: '0',
          // ad spend and tacos ends
        });
      }
    });
  }

  return tempData;
};

export default BindSellerSalesPerformanceData;
