/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';

import Select, { components } from 'react-select';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import Theme from '../theme/Theme';
import CheckBox from './CheckBox';
import CustomDropDown from './CustomDropDown';
import DropDownIndicator from './DropDownIndicator';

export default function MultiSelectDropdownWithoutAll({
  options,
  label,
  multiSelectedValues,
  setMultiSelectedValues,
  handleMultiSelectChange,
  isDisabled = false,
  handleOnMenuClose,
  defaultSelectionCount = 0,
  multiselectLabel = null,
}) {
  const { Option, ValueContainer } = components;

  const selectOption = (dataProps) => {
    return (
      <div className="option-with-checkbox">
        <Option {...dataProps}>
          <CheckBox>
            <label
              htmlFor={dataProps.value}
              className="check-container customer-pannel"
            >
              <input
                type="checkbox"
                name={dataProps?.value}
                id={dataProps?.value}
                checked={dataProps?.isSelected}
                disabled={multiSelectedValues?.length === 1}
                onChange={() => {}}
              />
              <span className="checkmark" />
              {dataProps?.label}
            </label>
          </CheckBox>
        </Option>
      </div>
    );
  };

  const valueContainer = useCallback(
    ({ children, getValue, ...dataProps }) => {
      const updatedValue = [...children];
      const { length } = getValue();
      switch (length) {
        case 0:
          updatedValue[0] = `Select ${label}`;
          break;
        case 1:
          updatedValue[0] = dataProps?.selectProps?.value?.[0]?.label;
          break;
        default:
          updatedValue[0] =
            multiselectLabel !== null
              ? multiselectLabel
              : `${`Multiple ${label}`}`;
          break;
      }
      return (
        <ValueContainer {...dataProps}>
          <span>{updatedValue}</span>
        </ValueContainer>
      );
    },
    [label],
  );

  const getAllComponents = () => {
    return {
      Option: selectOption,
      ValueContainer: valueContainer,
      DropdownIndicator: DropDownIndicator,
    };
  };

  const handleChange = (event, values) => {
    if (values.action === 'select-option') {
      setMultiSelectedValues(event);
    }

    // event.length >= defaultSelectionCount - for - atleat 1 option should be selected
    if (
      values.action === 'deselect-option' &&
      event.length >= defaultSelectionCount
    ) {
      setMultiSelectedValues(event);
    }
  };

  return (
    <>
      <CustomDropDown>
        <Select
          classNamePrefix="react-select"
          className={isDisabled ? 'disabled' : ''}
          options={options}
          isMulti
          value={multiSelectedValues}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={getAllComponents()}
          styles={{
            option: ({ isSelected }) => {
              return {
                backgroundColor: isSelected ? 'white' : null,
                margin: ' 15px',
              };
            },
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.white,
              primary: Theme.orange,
              primary50: Theme.white,
            },
          })}
          isClearable={false}
          isSearchable={false}
          isLoading={isDisabled}
          placeholder={`Select ${label}`}
          onChange={(event, values) => {
            handleChange(event, values);
            handleMultiSelectChange(event, values);
          }}
          isOptionDisabled={(op) => op.isDisabled}
          onMenuClose={() => handleOnMenuClose()}
        />
      </CustomDropDown>
    </>
  );
}

MultiSelectDropdownWithoutAll.defaultProps = {
  isDisabled: false,
  defaultSelectionCount: 0,
  multiselectLabel: '',
  handleMultiSelectChange: () => {},
  handleOnMenuClose: () => {},
};

MultiSelectDropdownWithoutAll.propTypes = {
  isDisabled: bool,
  defaultSelectionCount: number,
  multiselectLabel: string,
  options: arrayOf(shape({})).isRequired,
  label: string.isRequired,
  multiSelectedValues: arrayOf(shape({})).isRequired,
  setMultiSelectedValues: func.isRequired,
  handleMultiSelectChange: func,
  handleOnMenuClose: func,
};
