import styled from 'styled-components';
import Theme from '../theme/Theme';

const DesktopTable = styled.table`
  position: relative;
  width: 100%;
  border-spacing: 0;
  table {
    position: relative;
  }
  thead {
    tr {
      text-align: left;
      background: ${Theme.gray8};
      font-family: ${Theme.baseFontFamily};
      width: 100%;
      th {
        padding: 17px 0px 17px 16px;
        text-transform: uppercase;
        color: ${Theme.gray40};
        font-size: 11px;
        font-family: ${Theme.titleFontFamily};
        min-height: 48px;

        &:last-child {
          padding-right: 16px;
        }
      }
      &.gray-border-radius {
        th {
          &:first-child {
            border-top-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 10px;
          }
        }
      }
    }
  }
  tbody {
    position: relative;

    tr {
      background: ${Theme.white};
      /* &:hover {
        background: ${Theme.gray8};
      } */
      &.bg-orange-light {
        background: ${Theme.lightOrange};
        td {
          color: ${Theme.orange};
          background: ${Theme.lightOrange};
          text-transform: uppercase;
          font-size: ${Theme.extraNormal};
          font-family: ${Theme.titleFontFamily} !important;

          &.sticky-collapse {
            position: sticky;
            left: 0;
            z-index: 9;
          }
        }
      }
      &.bg-green-light {
        background: ${Theme.lightGreenBg};
      }
      td {
        color: ${Theme.black};
        padding: 22px 10px 22px 16px;
        font-size: ${Theme.extraNormal};
        border-bottom: 1px solid ${Theme.gray11};

        &.sticky-column {
          position: sticky;
          left: 0;
          z-index: 9;
        }

        .creative-input {
          border: none;
          font-size: 14px;
          border: 1px solid ${Theme.gray12};
          padding: 8px;
          &:focus {
            border: 1px solid ${Theme.gray12} !important;
          }
          &.creative-input-error {
            border: 1px solid ${Theme.red};
            background: ${Theme.lightRed};
          }
        }

        &.border-none {
          border-bottom: none;
        }

        &.BPLogo {
          .company-logo {
            border-radius: 10px;
            width: 45px;
            height: 45px;
            margin-right: 14px;
            float: left;
          }

          .company-name {
            min-height: 20px;
            text-transform: capitalize;
            word-break: break-all;
          }

          .status {
            color: ${Theme.gray85};
            font-size: ${Theme.extraNormal};
            font-weight: 300;
            word-break: break-all;
            text-transform: capitalize;
          }
        }

        &.goals-no-data {
          vertical-align: top;
        }

        span.collapse-icon {
          width: 16px;
          height: 16px;
          background: rgb(244, 246, 252);
          margin-right: 10px;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
        }
        .large-text {
          font-size: ${Theme.extraMedium};
          font-family: ${Theme.baseMediumFontFamily};
        }
        .small-text {
          font-size: ${Theme.extraSmall};
        }
        &.text-bold {
          font-family: ${Theme.titleFontFamily};
        }

        .text-extra-small {
          font-size: ${Theme.textExtraSmall};
        }
        &.word-break {
          word-break: break-all;
        }

        div.range {
          font-size: ${Theme.extraSmall};
          font-family: ${Theme.baseMediumFontFamily};
          &.increase-rate {
            color: ${Theme.lighterGreen};
          }
          &.decrease-rate {
            color: ${Theme.red};
          }
          &.normal-font {
            font-size: ${Theme.extraNormal};
          }
        }

        .sub-label {
          color: ${Theme.gray40};
        }

        &.bg-green-light {
          background: ${Theme.lightGreenBg};
        }
      }
    }
  }
  &.metrics-table {
    tbody {
      tr td {
        font-family: ${Theme.baseMediumFontFamily};
      }
      tr:last-child td {
        border-bottom: 2px solid #e0e6e8;
      }
    }
  }

  &.services-playbook-table {
    tbody {
      position: relative;
      tr {
        td {
          a {
            color: #000;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &.dsp-audience-table {
    tr {
      width: 100%;
    }

    thead {
      .sticky-header {
        position: sticky;
        top: 0;
      }
    }

    thead:first-child {
      left: 0;
      /* z-index: 100; */
    }

    .rh,
    .th-sticky {
      position: sticky;
      left: 0;
      z-index: 9;
      background-color: white;
      border-right: 1px solid #e0e6e8;
    }

    .last-column,
    .last-header {
      position: sticky;
      right: 0;
      z-index: 9;
      background-color: white;
      border-left: 1px solid #e0e6e8;
    }

    .th-sticky,
    .last-header {
      background-color: #f4f6fc;
    }
    tbody {
      overflow: auto;
    }
    .dsp-audience-inputbox {
      display: inline-flex;
    }
    .dsp-audienece-trash-icon {
      margin-right: 5px;
    }
    .dsp-audience-createdBy-user {
      background-color: ${Theme?.gray8};
      font-size: ${Theme?.textSmall};
      line-height: 12px;
      max-width: fit-content;
      padding: 2px 10px 2px 2px;
    }
  }
`;

export default DesktopTable;
