// React Imports
import React from 'react';

// Third party imports
import Modal from 'react-modal';
import { bool, func } from 'prop-types';

// local imports
import { CloseIcon } from '../../../theme/images';
import { ModalBox, Button, PageLoader } from '../../../common';

function OneTimeAgreementCompletionDateComfirmationModal({
  showModal,
  setShowModal,
  updateAgreement,
  saveCompletionDateLoader,
}) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal
      isOpen={showModal?.show}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Profile modal"
    >
      <img
        src={CloseIcon}
        alt="close"
        className="float-right cross-icon cursor"
        onClick={() => {
          setShowModal({ show: false, contractId: null });
        }}
        role="presentation"
      />
      <ModalBox>
        <div className="modal-body">
          <div className="alert-msg  ">
            <div className="sure-to-proceed primary-text-of-modal">
              Are you sure you want to Save?
            </div>
            <div className="mt-2">
              You can no longer edit the completion date once it has been saved.
            </div>
          </div>
          <div className="text-center ">
            <Button
              onClick={() => {
                updateAgreement(showModal?.contractId);
              }}
              type="button"
              className="btn-primary on-boarding  mr-2 pb-2 mb-1"
            >
              {saveCompletionDateLoader ? (
                <PageLoader color="#fff" type="button" />
              ) : (
                'Confirm'
              )}
            </Button>
            <Button
              onClick={() => setShowModal({ show: false, contractId: null })}
              type="button"
              className=" btn-transparent w-50 on-boarding "
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}

export default OneTimeAgreementCompletionDateComfirmationModal;

OneTimeAgreementCompletionDateComfirmationModal.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  updateAgreement: () => {},
  saveCompletionDateLoader: false,
};

OneTimeAgreementCompletionDateComfirmationModal.propTypes = {
  showModal: bool,
  setShowModal: func,
  updateAgreement: func,
  saveCompletionDateLoader: bool,
};
