import { useEffect } from 'react';

import ReactGA from 'react-ga';

import { useLocation } from 'react-router-dom';

export default function useGoogleAnalytics() {
  const location = useLocation();
  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);

  return null;
}
