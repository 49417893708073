import React, { useState, useCallback, useEffect } from 'react';

import Modal from 'react-modal';
import { DebounceInput } from 'react-debounce-input';
import { toast, ToastContainer } from 'react-toastify';
import { string, bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CloseIcon, SearchIcon } from '../../../../../theme/images';
import {
  getBBEUsersData,
  updatePlaybookTaskAssignee,
} from '../../../../../api';
import {
  Button,
  CommonPagination,
  GetInitialName,
  InputSearchWithRadius,
  ModalBox,
  PageLoader,
} from '../../../../../common';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '600px ',
    minHeight: '220px',
    width: '100% ',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function ChangeTaskAssigneeModal({
  ID,
  modalId,
  isOpen,
  taskId,
  customerId,
  playbookTab,
  afterSubmitAPI,
}) {
  const [isSubmitApiCall, setIsSubmitApiCall] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [bbeUsersData, setBbeUsersData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedAssignee, setSelectedAssignee] = useState({});

  // get assigned members of customer
  const getBBEUsers = useCallback(
    (query, page) => {
      setIsDataLoading(true);
      setBbeUsersData([]);
      setTotalCount(0);
      getBBEUsersData(customerId, query, page).then((response) => {
        if (response?.status === 200) {
          setBbeUsersData(response?.data?.results);
          setTotalCount(response?.data?.count);
        } else {
          setBbeUsersData([]);
          setTotalCount(0);
        }
        setIsDataLoading(false);
      });
    },
    [customerId],
  );

  useEffect(() => {
    getBBEUsers('', 1);
  }, [getBBEUsers]);

  // before and after calling api
  const eventsBeforeAndAfterApi = useCallback(() => {
    setBbeUsersData([]);
    setTotalCount(0);
    afterSubmitAPI();
  }, [afterSubmitAPI]);

  // save change playbook task assignee
  const updateNewPlaybookTaskAssignee = useCallback(() => {
    const payloadData = {
      task: taskId,
      assignee_email: selectedAssignee?.email,
    };
    let payloadData2 = {};
    let API = '';

    if (playbookTab === 'playbook') {
      API = updatePlaybookTaskAssignee(ID, payloadData);
    } else if (playbookTab === 'onboarding') {
      payloadData2 = {
        ...payloadData,
        customer: customerId,
        onboarding: 'true',
      };
      API = updatePlaybookTaskAssignee(ID, payloadData2);
    } else {
      API = '';
    }
    setIsSubmitApiCall(true);

    API.then((response) => {
      if (response?.status === 200) {
        eventsBeforeAndAfterApi();
        toast.success(`Assignee updated succesfully`);
      } else {
        eventsBeforeAndAfterApi();
        toast.error(`Oops! something went wrong...`);
      }
      setIsSubmitApiCall(false);
    });
  }, [
    ID,
    customerId,
    eventsBeforeAndAfterApi,
    playbookTab,
    selectedAssignee,
    taskId,
  ]);

  // Handle Search events
  const handleSearch = (event) => {
    event.persist();
    setSearchQuery(event.target.value);
    setPageNumber(1);
    setTimeout(() => {
      getBBEUsers(event?.target?.value, 1);
    }, 1000);
    setSelectedAssignee({});
  };

  // Handle Pagination events
  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getBBEUsers(searchQuery, currentPage);
  };

  // render Input Search With Radius
  const renderInputSearchWithRadius = () => {
    return (
      <InputSearchWithRadius
        id="BT-bbeuser-search-input"
        className="customer-list-header w-80 mt-3"
      >
        <DebounceInput
          className="form-control search-filter"
          placeholder="Search"
          onChange={(event) => {
            handleSearch(event);
          }}
          value={searchQuery || ''}
          debounceTimeout={600}
        />
        <img src={SearchIcon} alt="search" className="search-input-icon" />
      </InputSearchWithRadius>
    );
  };

  // render bbe assigned and unassigned users list
  const renderBBEUsersList = () => {
    return bbeUsersData?.map((user) => {
      return (
        <div
          className="row cursor mb-3"
          key={user?.user_id}
          role="presentation"
          onClick={() => {
            setSelectedAssignee(user);
          }}
        >
          <div className="col-8">
            <div className="edit-profile-text" role="presentation">
              <GetInitialName
                userInfo={user}
                property={
                  selectedAssignee?.user_id === user?.user_id
                    ? 'active-user mr-3'
                    : 'mr-3'
                }
              />
              <div className="name-email">
                <div className="team-user-name text-medium-font">
                  {`${user?.first_name} ${user?.last_name}`}
                </div>
                {user?.role}
              </div>
            </div>
          </div>
          <div className="col-4 text-right">
            {selectedAssignee?.user_id === user?.user_id ? (
              <div className="pt-2">
                <FontAwesomeIcon
                  icon="fa-regular fa-check"
                  style={{ color: '#ff5933', width: '20px', height: '20px' }}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    });
  };

  // render submit button html
  const renderSubmitButton = () => {
    return (
      <Button
        className={
          isDataLoading || bbeUsersData?.length < 1 || !selectedAssignee?.email
            ? 'btn-primary on-boarding w-100 disabled'
            : 'btn-primary on-boarding w-100'
        }
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          updateNewPlaybookTaskAssignee();
        }}
      >
        {isSubmitApiCall ? <PageLoader color="#fff" type="button" /> : 'Submit'}
      </Button>
    );
  };

  // render cancel button html
  const renderCancelButton = () => {
    return (
      <Button
        className={
          isDataLoading || bbeUsersData?.length < 1 || !selectedAssignee?.email
            ? 'btn-transparent w-100 disabled'
            : 'btn-transparent w-100'
        }
        onClick={(e) => {
          e.stopPropagation();
          eventsBeforeAndAfterApi();
        }}
      >
        Cancel
      </Button>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      <Modal
        id={modalId}
        isOpen={isOpen}
        style={{ ...customStyles }}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            afterSubmitAPI();
            setBbeUsersData([]);
          }}
          role="presentation"
        />
        <ModalBox>
          <>
            <div className="modal-body pb-0">
              <h4>Change Task Assignee</h4>

              {renderInputSearchWithRadius()}
              <div className="horizontal-line straight-line mt-3 mb-3" />
              <div
                className="body-content mt-2"
                style={{ minHeight: '50px', maxHeight: '400px' }}
              >
                <div>
                  {isDataLoading ? (
                    <PageLoader
                      component="member"
                      color="#FF5933"
                      type="page"
                      height={40}
                    />
                  ) : bbeUsersData?.length === 0 ? (
                    <div className="text-center no-result-found my-3">
                      No User Found.
                    </div>
                  ) : (
                    <>{renderBBEUsersList()}</>
                  )}
                </div>
              </div>
              {totalCount > 10 ? (
                <>
                  <div className="horizontal-line straight-line my-3" />
                  <div style={{ margin: '-5px 0 -10px 0' }}>
                    <CommonPagination
                      count={totalCount}
                      pageNumber={pageNumber}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div className="footer-line" />
            <div className="modal-footer">
              <div className="row">
                <div className="col-6">{renderSubmitButton()}</div>
                <div className="col-6">{renderCancelButton()}</div>
              </div>
            </div>
          </>
        </ModalBox>
      </Modal>
    </>
  );
}

export default ChangeTaskAssigneeModal;

ChangeTaskAssigneeModal.defaultProps = {
  modalId: '',
  isOpen: false,
  afterSubmitAPI: () => {},
};

ChangeTaskAssigneeModal.propTypes = {
  modalId: string,
  ID: string.isRequired,
  taskId: string.isRequired,
  customerId: string.isRequired,
  playbookTab: string.isRequired,
  isOpen: bool,
  afterSubmitAPI: func,
};
