// react imports
import React from 'react';

// third party imports
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

// local file imports
import Theme from '../../../theme/Theme';
import { Activity } from '../../../constants';
import PageLoader from '../../../common/PageLoader';
import { CommonPagination, Status } from '../../../common/index';
import {
  FileIcon,
  CheckFileIcon,
  EditFileIcon,
  SignatureIcon,
  NextActivityLogo,
  ContractEmailIcon,
} from '../../../theme/images/index';

// define third party variable
const _ = require('lodash');

function ContractActivityLog({
  loader,
  isApicalled,
  activityLoader,
  pageNumber,
  role,
  contractAddendumDetails,
  images,
  activityData,
  activityCount,
  agreementData,
  setPageNumber,
  checkContractStatus,
  getContractActivityLogInfo,
}) {
  const loggedInuserInfo = useSelector((state) => state?.userState?.userInfo);

  // handle pagination events
  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getContractActivityLogInfo(currentPage);
  };

  // get contract status, icon image src and status classname
  const getContractStatusData = (type) => {
    const status = contractAddendumDetails?.notActive
      ? contractAddendumDetails?.status
      : agreementData?.contract_status?.value;

    let statusClass = '';
    let statusSrc = '';
    let dispalyStatus = '';

    if (status !== '') {
      switch (status) {
        case 'pending contract':
          statusClass = 'pending-contract';
          statusSrc = FileIcon;
          dispalyStatus = 'Pending Agreement';
          break;

        case 'pending contract approval':
          statusClass = '';
          statusSrc = CheckFileIcon;
          dispalyStatus = 'Pending Agreement Approval';
          break;

        case 'pending contract signature':
          statusClass = 'pending-signature';
          statusSrc = EditFileIcon;
          dispalyStatus = 'Pending Agreement Signature';
          break;

        case 'pending account setup':
          statusClass = 'signature';
          statusSrc = SignatureIcon;
          dispalyStatus = 'Signed';
          break;

        case 'active':
          statusClass = 'signature';
          statusSrc = SignatureIcon;
          dispalyStatus = 'Signed';
          break;

        case 'inactive':
          statusClass = '';
          statusSrc = '';
          dispalyStatus = 'Inactive';
          break;

        case 'cancel':
          statusClass = '';
          statusSrc = '';
          dispalyStatus = 'Cancelled';
          break;

        case 'pending cancellation approval':
          statusClass = '';
          statusSrc = '';
          dispalyStatus = 'Pending Cancellation Approval';
          break;

        case 'pending for cancellation':
          statusClass = '';
          statusSrc = '';
          dispalyStatus = 'Cancelled - Pending for Billing';
          break;

        case 'pause':
          statusClass = '';
          statusSrc = '';
          dispalyStatus = 'Pause';
          break;

        case 'pending addendum':
          statusClass = 'pending-contract';
          statusSrc = FileIcon;
          dispalyStatus = 'Pending Addendum';
          break;

        case 'pending addendum approval':
          statusClass = '';
          statusSrc = CheckFileIcon;
          dispalyStatus = 'Pending Addendum Approval';
          break;

        case 'pending addendum signature':
          statusClass = 'pending-signature';
          statusSrc = EditFileIcon;
          dispalyStatus = 'Pending Addendum Signature';
          break;

        case 'renewed':
          statusClass = 'signature';
          statusSrc = SignatureIcon;
          dispalyStatus = 'Signed';
          break;

        case 'completed':
          statusClass = 'signature';
          statusSrc = SignatureIcon;
          dispalyStatus = 'Completed';
          break;

        default:
          statusClass = 'pending-contract';
          statusSrc = 'FileIcon';
          dispalyStatus = '';
          break;
      }
    }

    if (type === 'class') {
      return statusClass;
    }
    if (type === 'src') {
      return statusSrc;
    }
    if (type === 'status') {
      return dispalyStatus;
    }
    return '';
  };

  // display user profile image
  const displayUserProfileImage = (item, reason, presignedURL) => {
    return (
      <div className={item?.status !== undefined ? 'contract-email' : ''}>
        <img
          src={
            reason?.split(' ')?.slice(0, 2) &&
            reason?.split(' ')?.slice(0, 2)[0] === 'System'
              ? NextActivityLogo
              : item?.status !== undefined
              ? ContractEmailIcon
              : presignedURL
          }
          className={
            item?.status !== undefined
              ? 'default-user-activity contract-mail'
              : 'default-user-activity '
          }
          alt="pic"
        />
      </div>
    );
  };

  // get first letter of firname and last name of user
  const getActivityInitials = (userInfo) => {
    if (userInfo && userInfo === 'Contract initiated') {
      return 'SU';
    }
    const firstName =
      (userInfo &&
        userInfo?.split(' ')?.slice(0, 2) &&
        userInfo?.split(' ')?.slice(0, 2)[0]?.charAt(0)) ||
      '';
    const lastName =
      (userInfo &&
        userInfo?.split(' ')?.slice(0, 2) &&
        userInfo?.split(' ')?.slice(0, 2)[1]?.charAt(0)) ||
      '';

    return firstName + lastName;
  };

  // display formatted activity details
  const displayFormattedActivityDetail = (item) => {
    let activityMessage = '';
    const reason = item?.history_change_reason;

    if (
      reason?.includes('added') ||
      reason?.includes('approved') ||
      reason?.includes('approved and sent') ||
      reason?.includes('changed') ||
      reason?.includes('created new record by company name') ||
      reason?.includes('completion date') ||
      reason?.includes('deleted') ||
      reason?.includes('downloaded') ||
      reason?.includes('granted') ||
      reason?.includes('resumed') ||
      reason?.includes('requested for') ||
      reason?.includes('sent') ||
      reason?.includes('signed') ||
      reason?.includes('removed') ||
      reason?.includes('updated addendum') ||
      reason?.includes('updated')
    ) {
      // https://bbe.atlassian.net/browse/PDV-9227 - Capture DSP Detail Company Details Page in Activity Logs
      // Words to wrap with span tags
      const wordsToWrap = [
        'added',
        'approved',
        'approved and sent',
        'changed',
        'created new record by company name',
        'deleted',
        'downloaded',
        'for',
        'from',
        'granted',
        'updated addendum',
        'resumed',
        'requested for',
        'removed',
        'sent',
        'signed',
        'updated',
        'to',
      ];
      // Regular expression to match the words
      const regex = new RegExp(`\\b(${wordsToWrap.join('|')})\\b`, 'gi');
      // Replace the matched words with span tags
      const log = reason?.replace(regex, '<span>$1</span>');

      return (
        <div
          dangerouslySetInnerHTML={{
            __html: log,
          }}
        />
      );
    }

    const highLightLogs = Activity?.dspSignOfflog
      ?.concat(Activity?.cancelPauseAggrementLog)
      ?.filter((logItem) => reason?.includes(logItem));
    if (
      reason?.includes(
        highLightLogs.length > 0
          ? highLightLogs.length === 1
            ? highLightLogs[0]
            : highLightLogs[highLightLogs.length - 1]
          : null,
      )
    ) {
      activityMessage = reason?.split(
        highLightLogs[highLightLogs.length > 0 ? highLightLogs.length - 1 : 0],
      );

      return (
        <>
          {activityMessage[0]}
          <span>
            {
              highLightLogs[
                highLightLogs.length > 0 ? highLightLogs.length - 1 : 0
              ]
            }
          </span>
          {activityMessage[1]}
        </>
      );
    }
    return reason || '';
  };

  // display activity status
  const displayActivityStatus = (item) => {
    return (
      <>
        <Status
          label={item?.status}
          backgroundColor={
            item?.status === 'delivered'
              ? Theme.lightGreen
              : item?.status === 'processed'
              ? Theme.lightYellow
              : Theme.lightRed
          }
          pointColor={
            item?.status === 'delivered'
              ? Theme.green
              : item?.status === 'processed'
              ? Theme.yellow
              : Theme.red
          }
        />
        {item?.status === 'delivered' ? (
          <div className="email-clicks">
            <span className="email-opens">Opens: {item?.opens || 0}</span>
            <span className="email-opens">Clicks: {item?.clicks || 0}</span>
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  // display contract status
  const displayContractStatus = () => {
    return (
      <div
        className={`contract-status ${getContractStatusData('class')}`}
        key="contract-status"
      >
        <img
          width="16px"
          className="contract-file-icon"
          src={getContractStatusData('src')}
          alt=""
        />
        {_.startCase(getContractStatusData('status'))}
      </div>
    );
  };

  // display activity Log details
  const displayActivityLogDetails = (item, index) => {
    const reason = item?.history_change_reason;
    const presignedURL = images?.find(
      (op) => op?.entity_id === item?.history_user_id,
    )?.presigned_url;

    return (
      <li key={`activitLog-${index}`}>
        {presignedURL ||
        (reason?.split(' ')?.slice(0, 2) &&
          reason?.split(' ')?.slice(0, 2)[0] === 'System' &&
          reason?.split(' ')?.slice(0, 2)[1]?.toLowerCase() === 'user') ||
        item?.status !== undefined ? (
          displayUserProfileImage(item, reason, presignedURL)
        ) : (
          <div className="avatarName float-left mr-3">
            {getActivityInitials(reason)}
          </div>
        )}
        <div className="activity-user">
          {displayFormattedActivityDetail(item)}
          <div className="time-date mt-1">{item?.history_date || ''}</div>
          {item?.status ? displayActivityStatus(item) : ''}
        </div>
        <div className="clear-fix" />
      </li>
    );
  };

  // display activty log footer
  const displayActivityLogFooter = () => {
    return (
      <Footer
        className="pdf-footer"
        bottom={
          contractAddendumDetails?.notActive
            ? '80px'
            : checkContractStatus()
            ? (agreementData?.contract_status?.value ===
                'pending cancellation approval' ||
                agreementData?.contract_status?.value ===
                  'pending for cancellation' ||
                agreementData?.contract_status?.value ===
                  'active pending for pause') &&
              loggedInuserInfo &&
              loggedInuserInfo?.role?.includes('BGS Manager')
              ? '80px'
              : '0px'
            : '80px'
        }
      >
        <CommonPagination
          count={activityCount}
          pageNumber={pageNumber || 1}
          handlePageChange={handlePageChange}
          showLessItems
        />
      </Footer>
    );
  };

  // display contract activity log
  const renderContractActivityPanel = () => {
    return (
      <>
        {/* contract status */}
        {agreementData?.contract_status?.value ? displayContractStatus() : null}
        {role !== 'Customer' ? (
          <div key="contract-activityDetails">
            <div className="activity-log">Agreement Activity</div>
            {activityLoader === true || loader ? (
              <PageLoader component="activityLog" color="#FF5933" type="page" />
            ) : activityData?.length ? (
              <>
                {/* contract activity details */}
                <ul className="menu">
                  {activityData.map((item, index) => {
                    return displayActivityLogDetails(item, index);
                  })}
                </ul>
                {/* contract activity foooter */}
                {activityCount > 10 ? displayActivityLogFooter() : null}
              </>
            ) : isApicalled ? (
              <div className="ml-3 mt-3">No activity log found.</div>
            ) : null}
          </div>
        ) : null}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div key="contract-activityDetailSection">
      {renderContractActivityPanel()}
    </div>
  );
}

export default ContractActivityLog;

ContractActivityLog.defaultProps = {
  loader: false,
  isApicalled: false,
  activityLoader: false,
  pageNumber: 1,
  activityCount: 0,
  agreementData: {
    contract_status: {
      value: '',
      label: '',
    },
    addendum_status: {
      value: '',
    },
  },
  contractAddendumDetails: {
    notActive: false,
    status: '',
  },
  images: [],
  activityData: [],
  setPageNumber: () => {},
  checkContractStatus: () => {},
  getContractActivityLogInfo: () => {},
};

ContractActivityLog.propTypes = {
  loader: bool,
  isApicalled: bool,
  activityLoader: bool,
  pageNumber: number,
  activityCount: number,
  role: string.isRequired,
  agreementData: shape({
    contract_status: shape({
      value: string,
      label: string,
    }),
    addendum_status: shape({
      value: string,
    }),
  }),
  contractAddendumDetails: shape({
    notActive: bool,
    status: string,
  }),
  images: arrayOf(shape({})),
  activityData: arrayOf(shape({})),
  setPageNumber: func,
  checkContractStatus: func,
  getContractActivityLogInfo: func,
};

const Footer = styled.div`
  border-top: 1px solid ${Theme.gray7};
  bottom: ${(props) => (props.bottom ? props.bottom : '0px')};
  background: ${Theme.white};
  box-shadow: ${Theme.boxShadow};
  position: fixed;
  min-height: 60px;
  z-index: 2;
  width: 336px;

  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  @media only screen and (min-width: 1500px) {
    width: 405px;
  }
`;
