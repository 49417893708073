import React, { useState } from 'react';

import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, shape, string, bool, func } from 'prop-types';

import ChangeAssigneeModal from './ChangeAssigneeModal';
import { GetInitialName } from '../../../../../common';

function AddAssignee({ id, assigneeData, handleAddAssignee, isEditFlagOn }) {
  const [showChangeCOSAssigneeModal, setShowChangeCOSAssigneeModal] =
    useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState({});

  // render assignee tooltip
  const renderAssigneeTooltip = (item) => {
    return (
      <ReactTooltip
        id={item?.id}
        aria-haspopup="true"
        place="bottom"
        effect="solid"
        backgroundColor="#2e384d"
        fontSize="14px"
        className="assigneetooltip"
      >
        <strong>
          {item?.first_name || ' '} {item?.last_name || ' '}
        </strong>
      </ReactTooltip>
    );
  };

  // get initial letter from first name and last name of user name
  const renderGetInitialName = (item) => {
    return (
      <GetInitialName
        id={id}
        userInfo={item}
        property="small-AvatarName ml-0"
      />
    );
  };

  // render COS assignee
  const renderCOSAssignee = (item) => {
    const firstName = item?.first_name;
    const lastName = item?.last_name;
    const showAssigneeTooltip = firstName !== null && lastName !== null;

    return showAssigneeTooltip ? (
      <div
        id={id}
        key={item?.id}
        className={`task-assignee cursor `}
        style={{
          display: 'inline-block',
          marginTop: '5px',
        }}
        role="presentation"
        onClick={() => {
          setShowChangeCOSAssigneeModal(true);
        }}
        data-tip
        data-for={item?.id}
      >
        {renderGetInitialName(item)}
        {renderAssigneeTooltip(item)}
      </div>
    ) : null;
  };

  // render plus sign circle
  const renderPlusSign = () => {
    return (
      <div
        id={id}
        className="cursor task-assignee add-task-assignee"
        style={{
          display: 'inline-block',
          padding: '2px 0px',
          textAlign: 'center',
          marginTop: '7px',
        }}
        role="presentation"
        onClick={() => {
          setShowChangeCOSAssigneeModal(true);
        }}
      >
        <FontAwesomeIcon
          icon="fa-regular fa-plus "
          color="#2E384D"
          fontSize="10px"
        />
      </div>
    );
  };

  // render task assignee and plus sign
  const renderCOSAssigneeData = () => {
    return assigneeData
      ? renderCOSAssignee(assigneeData)
      : isEditFlagOn
      ? renderPlusSign()
      : '';
  };

  // render change assignee modal
  const renderChangeCOSAssigneeModal = () => {
    return (
      <ChangeAssigneeModal
        id={id}
        modalId="COS-Designer-assignee"
        isOpen={showChangeCOSAssigneeModal}
        afterSubmitAPI={() => {
          setShowChangeCOSAssigneeModal(false);
        }}
        handleAddAssignee={(selectedMember) => {
          handleAddAssignee(selectedMember);
          setShowChangeCOSAssigneeModal(false);
        }}
        selectedAssignee={selectedAssignee}
        setSelectedAssignee={setSelectedAssignee}
      />
    );
  };

  return (
    <>
      {renderCOSAssigneeData()}
      {showChangeCOSAssigneeModal ? renderChangeCOSAssigneeModal() : null}
    </>
  );
}

export default AddAssignee;

AddAssignee.defaultProps = {
  assigneeData: [],
  id: '',
  handleAddAssignee: () => {},

  isEditFlagOn: false,
};

AddAssignee.propTypes = {
  assigneeData: arrayOf(shape({})),
  id: string,
  handleAddAssignee: func,
  isEditFlagOn: bool,
};
