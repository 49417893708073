import styled from 'styled-components';
import Theme from '../theme/Theme';

const DropDownStatus = styled.div`
  display: inline-flex;
  vertical-align: top;
  margin-top: 7px;

  .css-2b097c-container {
    width: 100%;
    color: ${Theme.gray6};
    font-size: ${Theme.extraNormal};
    cursor: pointer;

    .react-select__control {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
      .css-tlfecz-indicatorContainer {
        padding: 2px 8px;
        color: white !important;
      }
      .css-1gtu0rj-indicatorContainer {
        padding: 4px 8px;
      }
      .css-1wa3eu0-placeholder {
        color: ${Theme.gray6};
      }
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    /* .react-select__menu {
      width: 300px !important;
      width: 100%;
      .css-26l3qy-menu {
        max-width: 300px !important;
        width: 100%;
      }
    } */
  }
  &.dropdown-borderless {
    width: 100%;
    cursor: pointer;
    .css-1hwfws3 {
      padding: 0;
      -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
    }
    .css-yk16xz-control {
      font-size: ${Theme.normal};
      color: ${Theme.gray80} !important;
      min-height: 40px;
      cursor: pointer;
      border: none;
      background: none;
      outline: none;
      -webkit-justify-content: space-evenly;
      justify-content: space-evenly;
      .css-g1d714-ValueContainer {
        padding: 0;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        .css-1wa3eu0-placeholder {
          color: ${Theme.gray80} !important;
        }
      }
      .react-select-4-input {
        color: black !important;
      }
    }
    .css-1pahdxg-control {
      min-height: 40px;
      cursor: pointer;
      border: none;
      background: none;
      outline: none;
      box-shadow: none;
      .css-g1d714-ValueContainer {
        padding: 0;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        .css-1wa3eu0-placeholder {
          color: ${Theme.gray80} !important;
        }
      }
      .css-1gtu0rj-indicatorContainer {
        padding: 0px;
      }

      &.react-select__control {
        padding: 0 !important;
        border: none;
        background: none;
        outline: none !important;
      }
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-tlfecz-indicatorContainer {
      padding: 0 !important;
      color: white !important;
    }
  }
  @media only screen and (max-width: 991px) {
    &.customer-details {
      float: right;
      margin-top: -64px;
    }
  }
  @media only screen and (max-width: 767px) {
    &.customer-details {
      float: inherit;
      margin-top: 0;
    }
  }
`;

export default DropDownStatus;
