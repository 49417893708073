import React from 'react';

import Modal from 'react-modal';
import { bool, func } from 'prop-types';

import { Button, ModalBox } from '../../../common';

import { customStyles } from '../../../constants';

export default function ConfirmCloseReportModal({
  isModalOpen,
  afterModalCloseAPI,
  modalConfirmButtonEvents,
}) {
  // render modal body content
  const renderModalBody = () => {
    return (
      <div className="modal-body pb-0">
        <h4>CONFIRM NAVIGATION</h4>

        <div className="horizontal-line straight-line mt-3" />
        <div className="body-content modal-body-inner-container mt-1">
          <p className="normal-text-black my-3" style={{ lineHeight: '24px' }}>
            Do you want to leave this page? Changes you made will not be saved.
            If you would like to save your changes, click on the “Keep editing”
            button.
          </p>
        </div>
        <div className="horizontal-line straight-line mb-3" />
      </div>
    );
  };

  // render modal footer
  const renderModalFooter = () => {
    return (
      <div className="modal-footer">
        <div className="row">
          <div className="col-md-6 col-12 col-sm-12 mb-2">
            <Button
              className="btn-transparent w-100"
              type="button"
              onClick={() => {
                modalConfirmButtonEvents();
              }}
            >
              Close without saving
            </Button>
          </div>
          <div className="col-md-6 col-12 col-sm-12">
            <Button
              className="btn-primary on-boarding w-100"
              onClick={() => {
                afterModalCloseAPI();
              }}
            >
              Keep editing
            </Button>
          </div>
        </div>
      </div>
    );
  };

  // render modal content
  const renderModalContent = () => {
    return (
      <>
        <Modal
          id="confirm-close-report-modal"
          isOpen={isModalOpen}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="confirm modal"
        >
          <ModalBox>
            {renderModalBody()}
            {renderModalFooter()}
          </ModalBox>
        </Modal>
      </>
    );
  };

  /* ********** main return statement of this component ********** */
  return <div>{renderModalContent()}</div>;
}

ConfirmCloseReportModal.defaultProps = {
  isModalOpen: false,
  afterModalCloseAPI: () => {},
  modalConfirmButtonEvents: () => {},
};

ConfirmCloseReportModal.propTypes = {
  isModalOpen: bool,
  afterModalCloseAPI: func,
  modalConfirmButtonEvents: func,
};
