import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { shape, func, bool, string } from 'prop-types';

import {
  InputFormField,
  CheckBox,
  DropdownIndicator,
  Button,
  ContractInputSelect,
  ErrorMsg,
} from '../../../../common';
import {
  getNonUSStandardServicesOptions,
  getNonUSStandardServicesMarketplaces,
  getNonUSStandardServicesFrequency,
} from '../../../../api';
import { AddNewIcons, TrashIcons } from '../../../../theme/images';

function StandardServicesForNonUS({
  formData,
  setFormData,
  updatedFormData,
  setUpdatedFormData,
  originalData,
  showFooter,
  setNonUSserviceError,
  nonUsServiceError,
  updateStatementErrorValue,
  sectionError,
  contractAddendumDetails,
  addendumDetails,
  displayMarketplaceName,
  setVatMarketplaces,
}) {
  const [nonUSStandardServices, setNonUSStandardServices] = useState([]);
  const [
    nonUSStandardServiceMarketplaces,
    setNonUSStandardServicesMarketplaces,
  ] = useState([]);
  const [originalVATMarketplaces, setOriginalVATMarketplaces] = useState([]);
  const [
    nonUSMarketplacesForVATRegistration,
    setNonUSSMarketplacesForVATRegistration,
  ] = useState([]);
  const [selectedStandardServicesFrequency, setNonUSStandardServicesFrequency] =
    useState([]);
  const [vatFilingData, setVATFilingData] = useState([]);

  const [isCheckedService, setIsChecked] = useState({});

  useEffect(() => {
    const tempVATFillingData = formData?.standard_non_us_services?.length
      ? formData?.standard_non_us_services?.filter(
          (item) => item.name === 'VAT Filing',
        )
      : [];
    setVATFilingData(tempVATFillingData);

    const uniqueResult = originalVATMarketplaces?.filter((obj) => {
      return !tempVATFillingData?.some((obj2) => {
        return obj.marketplace === obj2.marketplace;
      });
    });
    setNonUSStandardServicesMarketplaces(uniqueResult);
  }, [formData.standard_non_us_services, originalVATMarketplaces]);

  useEffect(() => {
    getNonUSStandardServicesOptions().then((res) => {
      setNonUSStandardServices(res);
      const result = res?.filter(
        (item) =>
          item.value === 'VAT Registration' || item.value === 'VAT Filing',
      );
      const checkedServices = {};
      if (result) {
        result.map((item) => {
          if (
            formData?.standard_non_us_services?.find(
              (service) => service.name === item.value,
            )
          ) {
            checkedServices[item.value] = true;
          } else {
            checkedServices[item.value] = false;
          }
          return null;
        });
      }
      setIsChecked(checkedServices);
    });
    getNonUSStandardServicesMarketplaces().then((res) => {
      setVatMarketplaces(res);
      const uniqueResult = res?.filter((obj) => {
        obj.marketplace = obj.value;
        return !vatFilingData?.some((obj2) => {
          return obj.marketplace === obj2.marketplace;
        });
      });
      setNonUSStandardServicesMarketplaces(uniqueResult);
      setOriginalVATMarketplaces(
        res?.filter((option) => {
          option.marketplace = option.value;
          return option;
        }),
      );
      setNonUSSMarketplacesForVATRegistration(res);
    });

    getNonUSStandardServicesFrequency().then((res) => {
      setNonUSStandardServicesFrequency(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCheckStatusOfService = (service, event) => {
    if (
      service?.value === 'VAT Registration' ||
      service?.value === 'VAT Filing'
    ) {
      setIsChecked({
        ...isCheckedService,
        [service?.value]: event.target.checked,
      });
    }
  };

  const handleChangeOfStandardServices = (event, service) => {
    showFooter(true);

    if (event.target.checked) {
      if (
        service?.value === 'International Customer Service' ||
        service?.value === 'International Logistics'
      ) {
        // code for Addendum
        const contractData = contractAddendumDetails?.notActive
          ? addendumDetails
          : originalData;
        const itemInFormData =
          contractData?.standard_non_us_services?.length &&
          contractData?.standard_non_us_services?.find(
            (item) => item && item?.name === service?.value,
          );
        if (itemInFormData) {
          setFormData({
            ...formData,
            standard_non_us_services: [
              ...formData?.standard_non_us_services,
              itemInFormData,
            ],
          });

          setUpdatedFormData({
            ...updatedFormData,
            standard_non_us_services: {
              ...updatedFormData?.standard_non_us_services,
              create: [
                ...updatedFormData?.standard_non_us_services?.create,
                itemInFormData,
              ],
              delete: updatedFormData?.standard_non_us_services?.delete?.filter(
                (item) => item !== itemInFormData.id,
              ),
            },
          });
        } else {
          const newContractData = {
            contract_id: formData?.id,
            name: service?.value,
            frequency: null,
            marketplace: null,
          };
          const newAddendumData = {
            contract_addendum: contractAddendumDetails?.id,
            name: service?.value,
            frequency: null,
            marketplace: null,
          };

          setFormData({
            ...formData,
            standard_non_us_services: formData?.standard_non_us_services?.length
              ? [
                  ...formData?.standard_non_us_services,
                  contractAddendumDetails.notActive
                    ? newAddendumData
                    : newContractData,
                ]
              : [
                  contractAddendumDetails.notActive
                    ? newAddendumData
                    : newContractData,
                ],
          });

          setUpdatedFormData({
            ...updatedFormData,
            standard_non_us_services: {
              ...updatedFormData?.standard_non_us_services,
              create: formData?.standard_non_us_services?.length
                ? [
                    ...formData?.standard_non_us_services,
                    contractAddendumDetails.notActive
                      ? newAddendumData
                      : newContractData,
                  ]
                : [
                    contractAddendumDetails.notActive
                      ? newAddendumData
                      : newContractData,
                  ],
            },
          });
        }
      } else {
        // 'VAT Registration', 'VAT Filing'
        // code for Addendum
        const contractData = contractAddendumDetails?.notActive
          ? addendumDetails
          : originalData;
        const itemsInFormData = contractData?.standard_non_us_services?.filter(
          (item) => item.name === service.value,
        );

        if (itemsInFormData?.length) {
          setFormData({
            ...formData,
            standard_non_us_services: [
              ...formData?.standard_non_us_services,
              ...itemsInFormData,
            ],
          });

          setUpdatedFormData({
            ...updatedFormData,
            standard_non_us_services: {
              ...updatedFormData?.standard_non_us_services,
              create: [
                ...formData?.standard_non_us_services,
                ...itemsInFormData,
              ],
              delete: updatedFormData?.standard_non_us_services?.delete?.filter(
                (id) => {
                  return !itemsInFormData.find((item) => item.id === id);
                },
              ),
            },
          });

          setVATFilingData(itemsInFormData);
          const uniqueResult = originalVATMarketplaces?.filter((obj) => {
            return !itemsInFormData?.some((obj2) => {
              return obj.marketplace === obj2.marketplace;
            });
          });
          setNonUSStandardServicesMarketplaces(uniqueResult);
        }
        setCheckStatusOfService(service, event);
      }
    }
    if (!event.target.checked) {
      const contractData = contractAddendumDetails?.notActive
        ? addendumDetails
        : originalData;
      if (
        service?.value === 'International Customer Service' ||
        service?.value === 'International Logistics'
      ) {
        // when unchecked the service
        const itemInFormData =
          contractData?.standard_non_us_services?.length &&
          contractData?.standard_non_us_services?.find(
            (item) => item && item?.name === service?.value,
          );

        if (itemInFormData) {
          const deletedID = contractData?.standard_non_us_services?.filter(
            (item) => item.name === service?.value,
          );

          setFormData({
            ...formData,
            standard_non_us_services:
              formData?.standard_non_us_services?.filter(
                (item) => item.name !== service?.value,
              ),
          });

          setUpdatedFormData({
            ...updatedFormData,
            standard_non_us_services: {
              ...updatedFormData?.standard_non_us_services,
              create: formData?.standard_non_us_services?.filter(
                (item) => item.name !== service?.value,
              ),
              delete: updatedFormData?.standard_non_us_services?.delete?.length
                ? [
                    ...updatedFormData?.standard_non_us_services?.delete,
                    deletedID?.[0].id,
                  ]
                : [deletedID?.[0].id],
            },
          });
        } else {
          setFormData({
            ...formData,
            standard_non_us_services:
              formData?.standard_non_us_services?.filter(
                (item) => item.name !== service?.value,
              ),
          });

          setUpdatedFormData({
            ...updatedFormData,
            standard_non_us_services: {
              ...updatedFormData?.standard_non_us_services,
              create: formData?.standard_non_us_services?.filter(
                (item) => item.name !== service?.value,
              ),
            },
          });
        }
      } else {
        const itemsInFormData = contractData?.standard_non_us_services?.filter(
          (item) => item.name === service.value,
        );

        if (itemsInFormData?.length) {
          setFormData({
            ...formData,
            standard_non_us_services:
              formData?.standard_non_us_services?.filter(
                (item) => item.name !== service?.value,
              ),
          });

          setUpdatedFormData({
            ...updatedFormData,
            standard_non_us_services: {
              ...updatedFormData?.standard_non_us_services,
              create: formData?.standard_non_us_services?.filter(
                (item) => item.name !== service?.value,
              ),
              delete: updatedFormData?.standard_non_us_services?.delete?.length
                ? [
                    ...updatedFormData?.standard_non_us_services?.delete,
                    ...itemsInFormData?.map((item) => item.id),
                  ]
                : itemsInFormData?.map((item) => item.id),
            },
          });
        } else {
          setFormData({
            ...formData,
            standard_non_us_services:
              formData?.standard_non_us_services?.filter(
                (item) => item.name !== service?.value,
              ),
          });

          setUpdatedFormData({
            ...updatedFormData,
            standard_non_us_services: {
              ...updatedFormData?.standard_non_us_services,
              create: formData?.standard_non_us_services?.filter(
                (item) => item.name !== service?.value,
              ),
            },
          });
          setVATFilingData([]);
        }
        setCheckStatusOfService(service, event);
      }
    }
  };

  const setDefaultValueToVATREgistration = () => {
    const VATRegistrationServices = formData?.standard_non_us_services?.length
      ? formData?.standard_non_us_services.filter(
          (item) => item.name === 'VAT Registration',
        )
      : null;
    const vatRegistrationMarketplaces = [];

    if (VATRegistrationServices?.length) {
      for (const item of VATRegistrationServices) {
        if (item?.marketplace) {
          vatRegistrationMarketplaces.push({
            label:
              displayMarketplaceName(item?.marketplace, 'vat') ||
              item.marketplace,
            value: item.marketplace,
          });
        }
      }
    }
    return vatRegistrationMarketplaces;
  };

  const checkClickedElementAlreadyExists = (event, value) => {
    let clickedValue = '';
    if (value.action === 'select-option') {
      clickedValue = value?.option?.value;
    }
    if (value.action === 'remove-value') {
      clickedValue = value?.removedValue.value;
    }

    // code for Addendum
    const contractData = contractAddendumDetails?.notActive
      ? addendumDetails
      : originalData;

    const itemInFormData =
      contractData?.standard_non_us_services?.length &&
      contractData?.standard_non_us_services?.find(
        (item) =>
          item &&
          item?.name === 'VAT Registration' &&
          item?.marketplace === clickedValue,
      );
    return itemInFormData;
  };

  const handleVATRegistrationMarketplaceChange = (event, value) => {
    showFooter(true);
    if (value.action === 'select-option') {
      const itemInFormData = checkClickedElementAlreadyExists(event, value);
      // checked whether checked item present in newly created list
      if (
        !(
          updatedFormData?.standard_non_us_services?.create &&
          updatedFormData?.standard_non_us_services.create.find(
            (item) =>
              item &&
              item?.name === 'VAT Registration' &&
              item?.marketplace === value?.option?.value,
          )
        )
      ) {
        // if checked item not found in newly created list then  again check whether it is present in original formData variable because if it is found in formData then we need to add that found item in newly created list bcoz we need id and all of that item to push in newly created list.
        // here we check whether checked item present in orginal formDAta list then add that found item in newly created list
        if (itemInFormData) {
          setFormData({
            ...formData,
            standard_non_us_services: [
              ...formData?.standard_non_us_services,
              itemInFormData,
            ],
          });

          setUpdatedFormData({
            ...updatedFormData,
            standard_non_us_services: {
              ...updatedFormData?.standard_non_us_services,
              create: [...formData?.standard_non_us_services, itemInFormData],
              delete: updatedFormData?.standard_non_us_services?.delete?.filter(
                (item) => item !== itemInFormData.id,
              ),
            },
          });
        }
        // else we create dict as BE required for new item and we push that in newly created list
        else {
          const newContractData = {
            name: 'VAT Registration',
            contract_id: originalData && originalData.id,
            marketplace: value?.option?.value,
            frequency: null,
          };
          const newAddendumData = {
            name: 'VAT Registration',
            contract_addendum: contractAddendumDetails?.id,
            marketplace: value?.option?.value,
            frequency: null,
          };
          setFormData({
            ...formData,
            standard_non_us_services: formData?.standard_non_us_services?.length
              ? [
                  ...formData?.standard_non_us_services,
                  contractAddendumDetails?.notActive
                    ? newAddendumData
                    : newContractData,
                ]
              : [
                  contractAddendumDetails?.notActive
                    ? newAddendumData
                    : newContractData,
                ],
          });

          setUpdatedFormData({
            ...updatedFormData,
            standard_non_us_services: {
              ...updatedFormData?.standard_non_us_services,
              create: formData?.standard_non_us_services?.length
                ? [
                    ...formData?.standard_non_us_services,
                    contractAddendumDetails?.notActive
                      ? newAddendumData
                      : newContractData,
                  ]
                : [
                    contractAddendumDetails?.notActive
                      ? newAddendumData
                      : newContractData,
                  ],
            },
          });
        }
      }
    }
    if (value.action === 'remove-value') {
      const itemInFormData = checkClickedElementAlreadyExists(event, value);

      // if unchecked item found in original list then add its id to newly created delte list
      if (itemInFormData?.id) {
        setFormData({
          ...formData,
          standard_non_us_services: formData?.standard_non_us_services.filter(
            (item) => item.id !== itemInFormData?.id,
          ),
        });

        setUpdatedFormData({
          ...updatedFormData,
          standard_non_us_services: {
            ...updatedFormData?.standard_non_us_services,
            create: formData?.standard_non_us_services.filter(
              (item) => item.id !== itemInFormData?.id,
            ),
            delete: updatedFormData?.standard_non_us_services?.delete?.length
              ? [
                  ...updatedFormData?.standard_non_us_services?.delete,
                  itemInFormData.id,
                ]
              : [itemInFormData.id],
          },
        });
      } else {
        const updatedCreateList =
          updatedFormData?.standard_non_us_services?.create
            .filter((item) => item.name === 'VAT Registration')
            .filter((item) => item.marketplace !== value.removedValue.value);

        const otherList =
          updatedFormData?.standard_non_us_services?.create.filter(
            (item) => item.name !== 'VAT Registration',
          );
        const updatedVATData = updatedCreateList.concat(otherList);

        setFormData({
          ...formData,
          standard_non_us_services: updatedVATData,
        });

        setUpdatedFormData({
          ...updatedFormData,
          standard_non_us_services: {
            ...updatedFormData?.standard_non_us_services,
            create: updatedVATData,
          },
        });
      }
    }
  };

  const displayVATRegistrationField = () => {
    return (
      <div>
        <ContractInputSelect>
          <Select
            classNamePrefix="react-select"
            options={nonUSMarketplacesForVATRegistration}
            isMulti
            name="non-us-marketplaces"
            onChange={(event, value) =>
              handleVATRegistrationMarketplaceChange(event, value)
            }
            value={setDefaultValueToVATREgistration()}
            components={{ DropdownIndicator }}
            isClearable={false}
          />
        </ContractInputSelect>
      </div>
    );
  };
  const errorHandling = () => {
    setNonUSserviceError({});
    updateStatementErrorValue(
      sectionError?.statement ? sectionError?.statement - 1 : 0,
    );
  };

  const removeService = (service) => {
    showFooter(true);
    errorHandling();
    const list = vatFilingData;

    if (service?.id) {
      setVATFilingData(list.filter((item) => item.id !== service?.id));

      setFormData({
        ...formData,
        standard_non_us_services: formData?.standard_non_us_services?.length
          ? formData?.standard_non_us_services?.filter(
              (item) => item.id !== service?.id,
            )
          : [],
      });

      setUpdatedFormData({
        ...updatedFormData,
        standard_non_us_services: {
          ...updatedFormData?.standard_non_us_services,
          create: formData?.standard_non_us_services?.length
            ? formData?.standard_non_us_services?.filter(
                (item) => item.id !== service?.id,
              )
            : [],
          delete: updatedFormData?.standard_non_us_services?.delete?.length
            ? [...updatedFormData?.standard_non_us_services?.delete, service.id]
            : [service.id],
        },
      });
    } else {
      setVATFilingData(
        list.filter((item) => item.unique_id !== service?.unique_id),
      );

      setFormData({
        ...formData,
        standard_non_us_services: formData?.standard_non_us_services?.length
          ? formData?.standard_non_us_services?.filter(
              (item) => item.unique_id !== service?.unique_id,
            )
          : [],
      });

      setUpdatedFormData({
        ...updatedFormData,
        standard_non_us_services: {
          ...updatedFormData?.standard_non_us_services,
          create: formData?.standard_non_us_services?.length
            ? formData?.standard_non_us_services?.filter(
                (item) => item.unique_id !== service?.unique_id,
              )
            : [],
        },
      });
    }
    setNonUSStandardServicesMarketplaces([
      ...nonUSStandardServiceMarketplaces,
      {
        label: service?.marketplace,
        value: service?.marketplace,
        marketplace: service?.marketplace,
      },
    ]);
  };

  const handleVATFiling = (event, value, service) => {
    showFooter(true);

    if (nonUsServiceError?.['VAT Filing']) {
      errorHandling();
    }

    const updatedVATFilingData = vatFilingData.map((item) =>
      item?.unique_id === service?.unique_id &&
      item?.marketplace === service?.marketplace
        ? { ...item, [value.name]: event.value }
        : item,
    );

    const servicesExceptVATFiling = formData?.standard_non_us_services?.length
      ? formData?.standard_non_us_services?.filter(
          (item) => item.name !== 'VAT Filing',
        )
      : [];

    setVATFilingData(updatedVATFilingData);

    setFormData({
      ...formData,
      standard_non_us_services: [
        ...servicesExceptVATFiling,
        ...updatedVATFilingData,
      ],
    });

    setUpdatedFormData({
      ...updatedFormData,
      standard_non_us_services: {
        ...updatedFormData?.standard_non_us_services,
        create: [...servicesExceptVATFiling, ...updatedVATFilingData],
      },
    });

    if (value?.name === 'marketplace') {
      const updatedMarketplace = originalVATMarketplaces.filter(
        (op) => op.marketplace !== event.marketplace,
      );
      const uniqueResult = updatedMarketplace?.filter((obj) => {
        return !updatedVATFilingData?.some((obj2) => {
          return obj.marketplace === obj2.marketplace;
        });
      });
      setNonUSStandardServicesMarketplaces(uniqueResult);
    }
  };

  const displayVATFillingField = () => {
    return (
      vatFilingData?.length &&
      vatFilingData?.map((service, index) => {
        return (
          <div className="row mb-1" key={Math.random()}>
            <div className="col-6 pr-2">
              <ContractInputSelect>
                <Select
                  classNamePrefix="react-select"
                  options={nonUSStandardServiceMarketplaces}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      background:
                        nonUsServiceError?.['VAT Filing'] &&
                        !service?.marketplace
                          ? '#FBF2F2'
                          : '#F4F6FC',
                      // match with the menu
                      // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
                      // Overwrittes the different states of border
                      borderColor:
                        nonUsServiceError?.['VAT Filing'] &&
                        !service?.marketplace
                          ? '#D63649'
                          : '#D5D8E1',

                      // Removes weird border around container
                      boxShadow: state.isFocused ? null : null,
                      '&:hover': {
                        // Overwrittes the different states of border
                        boxShadow: state.isFocused ? null : null,
                        outlineColor: state.isFocused ? null : null,
                      },
                    }),
                  }}
                  name="marketplace"
                  onChange={(event, value) =>
                    handleVATFiling(event, value, service, service.id, index)
                  }
                  defaultValue={{
                    label: displayMarketplaceName(service?.marketplace, 'vat'),
                    value: service?.marketplace,
                  }}
                  components={{ DropdownIndicator }}
                  isClearable={false}
                />
              </ContractInputSelect>
            </div>
            <div className="col-5 pl-0 pr-1 ">
              <ContractInputSelect>
                <Select
                  classNamePrefix="react-select"
                  options={selectedStandardServicesFrequency}
                  name="frequency"
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      background:
                        nonUsServiceError?.['VAT Filing'] && !service?.frequency
                          ? '#FBF2F2'
                          : '#F4F6FC',
                      // match with the menu
                      // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
                      // Overwrittes the different states of border
                      borderColor:
                        nonUsServiceError?.['VAT Filing'] && !service?.frequency
                          ? '#D63649'
                          : '#D5D8E1',

                      // Removes weird border around container
                      boxShadow: state.isFocused ? null : null,
                      '&:hover': {
                        // Overwrittes the different states of border
                        boxShadow: state.isFocused ? null : null,
                        outlineColor: state.isFocused ? null : null,
                      },
                    }),
                  }}
                  onChange={(event, value) =>
                    handleVATFiling(event, value, service, service.id, index)
                  }
                  defaultValue={{
                    label: service?.frequency,
                    value: service?.frequency,
                  }}
                  components={{ DropdownIndicator }}
                  isClearable={false}
                />
              </ContractInputSelect>
            </div>
            <div
              className="col-1 mt-3 pt-1 pl-1 text-right"
              role="presentation"
              onClick={() => removeService(service, index)}
            >
              <img
                className="cursor"
                width="16px"
                src={TrashIcons}
                alt="delete"
              />
            </div>
          </div>
        );
      })
    );
  };

  const onAddMoreService = () => {
    showFooter(true);
    const newContractData = {
      contract_id: formData?.id,
      frequency: null,
      marketplace: null,
      name: 'VAT Filing',
      unique_id: Math.random(),
    };

    const newAddendumData = {
      contract_addendum: contractAddendumDetails?.id,
      frequency: null,
      marketplace: null,
      name: 'VAT Filing',
      unique_id: Math.random(),
    };

    setFormData({
      ...formData,
      standard_non_us_services: formData?.standard_non_us_services?.length
        ? [
            ...formData?.standard_non_us_services,
            contractAddendumDetails?.notActive
              ? newAddendumData
              : newContractData,
          ]
        : [
            contractAddendumDetails?.notActive
              ? newAddendumData
              : newContractData,
          ],
    });
    setVATFilingData([
      ...vatFilingData,
      contractAddendumDetails?.notActive ? newAddendumData : newContractData,
    ]);

    setUpdatedFormData({
      ...updatedFormData,
      standard_non_us_services: {
        ...updatedFormData?.standard_non_us_services,
        create: formData?.standard_non_us_services?.length
          ? [
              ...formData?.standard_non_us_services,
              contractAddendumDetails?.notActive
                ? newAddendumData
                : newContractData,
            ]
          : [
              contractAddendumDetails?.notActive
                ? newAddendumData
                : newContractData,
            ],
      },
    });

    const uniqueResult = originalVATMarketplaces?.filter((obj) => {
      return !vatFilingData?.some((obj2) => {
        return obj.marketplace === obj2.marketplace;
      });
    });
    setNonUSStandardServicesMarketplaces(uniqueResult);
  };

  const setDefaultCheckToService = (serviceData) => {
    return (
      formData?.standard_non_us_services?.length &&
      formData?.standard_non_us_services?.find(
        (item) => item?.name === serviceData?.value,
      )
    );
  };

  const displayStandardServiceForNonUSMarketplace = () => {
    return (
      <>
        <InputFormField className="mb-3">
          <div className="row">
            <div className="col-6">
              {' '}
              <label htmlFor="additional_one_time_services ">
                Standard Services
              </label>{' '}
            </div>

            <div className="col-6 text-right">
              <div className="label-card-text ">For Non-US Marketplaces</div>
            </div>
          </div>
        </InputFormField>
        {nonUSStandardServices &&
          nonUSStandardServices?.map((serviceData) => (
            <>
              <CheckBox className="mt-3 mb-3" key={serviceData?.label}>
                <label
                  className="check-container customer-pannel"
                  htmlFor={serviceData?.value}
                >
                  {serviceData?.label}
                  <input
                    type="checkbox"
                    name={serviceData?.name}
                    id={serviceData?.value}
                    onClick={(event) =>
                      handleChangeOfStandardServices(event, serviceData)
                    }
                    defaultChecked={setDefaultCheckToService(serviceData)}
                  />
                  <span className="checkmark" />
                </label>
              </CheckBox>
              {isCheckedService?.['VAT Registration'] &&
              serviceData.value === 'VAT Registration'
                ? displayVATRegistrationField()
                : null}
              {isCheckedService?.['VAT Filing'] &&
              serviceData?.value === 'VAT Filing' ? (
                <>
                  {vatFilingData?.length ? (
                    <>
                      {' '}
                      {displayVATFillingField()}{' '}
                      <ErrorMsg className="mb-3">
                        {nonUsServiceError?.['VAT Filing']
                          ? nonUsServiceError?.['VAT Filing'] ===
                            'Please select marketplace and frequency for VAT Filing'
                            ? 'Please fill all details of VAT Filing'
                            : nonUsServiceError?.['VAT Filing']
                          : ''}
                      </ErrorMsg>{' '}
                    </>
                  ) : null}

                  {vatFilingData?.length < 9 ? (
                    <Button
                      className="btn-add-contact"
                      role="presentation"
                      type="button"
                      disabled={vatFilingData.find(
                        (item) =>
                          item.marketplace === null || item.frequency === null,
                      )}
                      onClick={() => {
                        onAddMoreService();
                      }}
                    >
                      <img
                        className="mr-1 add-new-icon "
                        src={AddNewIcons}
                        alt="add"
                      />{' '}
                      Add New Marketplace
                    </Button>
                  ) : null}
                </>
              ) : null}
            </>
          ))}
      </>
    );
  };
  return <div>{displayStandardServiceForNonUSMarketplace()}</div>;
}

export default StandardServicesForNonUS;

StandardServicesForNonUS.defaultProps = {
  formData: {},
  setFormData: () => {},
  updatedFormData: {},
  setUpdatedFormData: () => {},
  originalData: {},
  showFooter: () => {},
  setNonUSserviceError: () => {},
  nonUsServiceError: {},
  updateStatementErrorValue: () => {},
  sectionError: {},
  contractAddendumDetails: {},
  addendumDetails: {},
  displayMarketplaceName: () => {},
  setVatMarketplaces: () => {},
};
StandardServicesForNonUS.propTypes = {
  formData: shape({}),
  setFormData: func,
  updatedFormData: shape({}),
  setUpdatedFormData: func,
  originalData: shape({}),
  showFooter: func,
  setNonUSserviceError: func,
  nonUsServiceError: shape({}),
  updateStatementErrorValue: func,
  sectionError: shape({}),
  contractAddendumDetails: shape({
    notActive: bool,
    id: string,
  }),
  addendumDetails: shape({}),
  displayMarketplaceName: func,
  setVatMarketplaces: func,
};
