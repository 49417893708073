import styled from 'styled-components';

const AdPerformanceNav = styled.div`
  .ad-performance-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: right;

    li {
      display: inline-block;
      padding: 0 0px 15px 8px;

      &.ad-performance {
        max-width: 260px;
        width: 100%;
        vertical-align: top;
      }
      .days-performance {
        max-width: 260px;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .ad-performance-nav {
      text-align: left;
      li {
        &.ad-performance {
          max-width: 50%;
        }
        .days-performance {
          max-width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .ad-performance-nav {
      li {
        padding-left: 0;
        &.ad-performance {
          max-width: 100%;
        }
        .days-performance {
          max-width: 100%;
        }
      }
    }
  }
`;
export default AdPerformanceNav;
