import dayjs from 'dayjs';
import { ORDEREDREVENUE } from '../../../../../../constants';

const BindVendorSalesGoalsGraphData = (response, selectedMetrics) => {
  const finalData = [];

  // get a last date of the week
  const getOneDayBack = (reportDateTemp) => {
    const reportDateResultTemp = new Date(reportDateTemp);
    reportDateResultTemp.setDate(reportDateResultTemp.getDate() - 1);
    return reportDateResultTemp;
  };

  const nextReportDate = (indexTemp, graphDataArray, dateParam) => {
    return dayjs(
      indexTemp + 1 < graphDataArray?.length
        ? getOneDayBack(graphDataArray[indexTemp + 1]?.report_date)
        : response?.dates?.[
            dateParam === 'current' ? 'current_end_date' : 'prevous_end_date'
          ],
    ).format('MMM D YYYY');
  };

  // filterout current data in one temporary object.
  if (response?.result?.current?.length) {
    response.result.current.forEach((item, index) => {
      const tempData = {};
      const currentReportDate = dayjs(item?.report_date).format('MMM D YYYY');
      tempData.endDate = nextReportDate(
        index,
        response?.result?.current,
        'previous',
      );
      tempData.date = currentReportDate;
      if (selectedMetrics === ORDEREDREVENUE) {
        tempData.orderedRevenueCurrent = item?.ordered_revenue;
        tempData.orderedRevenueGoals = item?.goal_ordered_revenue;

        tempData.glanceViewsCurrent = item?.glance_views;
        tempData.glanceViewsGoals = item?.goal_glance_views;

        tempData.conversionRateCurrent = item?.conversion_rate;
        tempData.conversionRateGoals = item?.goal_conversion_rate;

        tempData.orderedRevenueCurrentLabel =
          item?.ordered_revenue !== null
            ? item.ordered_revenue.toFixed(2)
            : '0.00';
        tempData.orderedRevenueGoalsLabel = item?.goal_ordered_revenue
          ? item?.goal_ordered_revenue?.toFixed(2)
          : '0.00';

        tempData.glanceViewsCurrentLabel =
          item?.glance_views !== null ? item.glance_views.toFixed(2) : '0.00';
        tempData.glanceViewsGoalsLabel = item?.goal_glance_views
          ? item?.goal_glance_views?.toFixed(2)
          : '0.00';

        tempData.conversionRateCurrentLabel =
          item?.conversion_rate !== null
            ? item.conversion_rate.toFixed(2)
            : '0.00';
        tempData.conversionRateGoalsLabel = item?.goal_conversion_rate
          ? item?.goal_conversion_rate?.toFixed(2)
          : '0.00';
      } else {
        tempData.shippedCOGsCurrent = item?.shipped_cogs;
        tempData.shippedCOGsGoals = item?.goal_shipped_cogs;

        tempData.glanceViewsCurrent = item?.glance_views;
        tempData.glanceViewsGoals = item?.goal_glance_views;

        tempData.conversionRateCurrent = item?.conversion_rate + 200;
        tempData.conversionRateGoals = item?.goal_conversion_rate + 200;

        tempData.shippedCOGsCurrentLabel =
          item?.shipped_cogs !== null ? item.shipped_cogs.toFixed(2) : '0.00';
        tempData.shippedCOGsGoalsLabel = item?.goal_shipped_cogs
          ? item?.goal_shipped_cogs?.toFixed(2)
          : '0.00';

        tempData.glanceViewsCurrentLabel =
          item?.glance_views !== null ? item.glance_views.toFixed(2) : '0.00';
        tempData.glanceViewsGoalsLabel = item?.goal_glance_views
          ? item?.goal_glance_views?.toFixed(2)
          : '0.00';

        tempData.conversionRateCurrentLabel =
          item?.conversion_rate !== null
            ? item.conversion_rate.toFixed(2)
            : '0.00';
        tempData.conversionRateGoalsLabel = item?.goal_conversion_rate
          ? item?.goal_conversion_rate?.toFixed(2)
          : '0.00';
      }

      finalData.push(tempData);
    });
  }

  return finalData;
};

export default BindVendorSalesGoalsGraphData;
