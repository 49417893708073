import React, { useState, useEffect, useRef } from 'react';

import { string, func, shape, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { jiraStatus } from '../../../../../constants';
import { TaskStatus } from '../PlaybookStyle';
import PlaybookChangeStatusModal from '../PlaybookModals/PlaybookChangeStatusModal';

function PlaybookTaskStatus({
  ID,
  taskData,
  customerId,
  isCustomer,
  playbookTab,

  playbookAPICall,
}) {
  const dropdownRef = useRef(null);
  const noAccess = isCustomer || playbookTab === 'onboarding-gray';
  const [showChangeStatusDropdown, setShowChangeStatusDropdown] =
    useState(false);

  const [showChangeTaskStatusModal, setShowChangeTaskStatusModal] =
    useState(false);
  const [changedTaskStatus, setChangedTaskStatus] = useState({});

  // handle click outside to the dropdown list
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowChangeStatusDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  // render progress label className
  const renderProgressLabelClassName = (status) => {
    const className =
      status === 'Done'
        ? 'progress-label green-label'
        : status === 'In Progress'
        ? 'progress-label orange-label'
        : status === 'Review'
        ? 'progress-label blue-label'
        : 'progress-label gray-label';
    return className;
  };

  // handle change jira status
  const handleChangeStatus = (item) => {
    setChangedTaskStatus({
      taskId: taskData?.taskId,
      taskName: taskData?.taskName,
      prevStatus: taskData?.jiraStatus,
      newStatus: item.status,
      newStatusLabel: item.label,
    });
  };

  // render change status dropdown
  const renderChangeStatusDropdown = (status) => {
    const iconColor =
      status === 'Done'
        ? '#407b00'
        : status === 'To Do'
        ? '#2E384D'
        : status === 'In Progress'
        ? '#FF5933'
        : status === 'Review'
        ? '#2e5bff'
        : null;

    return (
      <div
        role="presentation"
        className={
          showChangeStatusDropdown
            ? 'change-status-dropdown show'
            : 'change-status-dropdown hide'
        }
      >
        <ul>
          {jiraStatus?.map((item) => {
            return (
              <li
                key={item.label}
                className={status === item.label ? 'current-status' : 'cursor'}
                role="presentation"
                onClick={(event) => {
                  event.stopPropagation();
                  setShowChangeTaskStatusModal(true);
                  handleChangeStatus(item);
                }}
              >
                <span className={renderProgressLabelClassName(item.label)}>
                  {item.label}
                </span>
                {status === item.label ? (
                  <span className="progress-icon">
                    <FontAwesomeIcon
                      icon="fa-regular fa-check"
                      color={iconColor}
                      fontSize="14px"
                    />
                  </span>
                ) : null}
                <div className="clear-fix" />
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // calling the playbook change status modal component
  const renderChangeStatusModal = () => {
    return (
      <PlaybookChangeStatusModal
        ID={ID}
        customerId={customerId}
        playbookTab={playbookTab}
        changedTaskStatus={changedTaskStatus}
        showChangeStatusModal={showChangeTaskStatusModal}
        setShowChangeStatusModal={setShowChangeTaskStatusModal}
        renderProgressLabelClassName={renderProgressLabelClassName}
        afterSubmitAPI={() => {
          playbookAPICall();
          setShowChangeTaskStatusModal(false);
        }}
      />
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      <TaskStatus
        className={`${renderProgressLabelClassName(taskData?.jiraStatus)} ${
          noAccess ? '' : 'cursor'
        }`}
        ref={dropdownRef}
        role="presentation"
        onClick={(event) => {
          event.stopPropagation();
          setShowChangeStatusDropdown(
            noAccess ? false : !showChangeStatusDropdown,
          );
        }}
      >
        <div>{taskData?.jiraStatus}</div>
        {noAccess ? null : (
          <FontAwesomeIcon
            icon="fa-regular fa-angle-down"
            fontSize="12px"
            style={{
              transform: showChangeStatusDropdown ? 'rotate(180deg)' : '',
            }}
          />
        )}

        {noAccess ? null : renderChangeStatusDropdown(taskData?.jiraStatus)}
      </TaskStatus>
      {showChangeTaskStatusModal ? renderChangeStatusModal() : null}
    </>
  );
}

export default PlaybookTaskStatus;

PlaybookTaskStatus.defaultProps = {
  playbookTab: '',
  customerId: null,
  taskData: [],
  playbookAPICall: () => {},
};

PlaybookTaskStatus.propTypes = {
  playbookTab: string,
  customerId: string,
  ID: string.isRequired,
  isCustomer: bool.isRequired,
  playbookAPICall: func,
  taskData: shape(),
};
