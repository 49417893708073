import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Collapse } from 'react-collapse';
import { arrayOf, func, number, shape, string } from 'prop-types';

import {
  CheckBox,
  InfoMsg,
  ToggleButton,
  ToggleSwitch,
} from '../../../../common';
import {
  AdTypesOptions,
  graphToggleOptions,
  groupByFields,
  sellerDspMetricsFields,
  vendorSalesMetricsFields,
  sellerSponsoredMetricsFields,
  inventoryManagementFields,
  inventoryProductNameToggleOption,
} from '../../../../constants';
import Theme from '../../../../theme/Theme';

const RenderVendorFields = ({
  graphData,
  graphIndex,
  accountType,
  handleGraphDetailsOnChange,
  renderCheckBox,
  getAdTypesSelectComponents,
  renderRadioButtons,
  selectedTimeFrame,
  enabledGroupFrequencyList,
  reportStatus,
  checkSelectedKeys,
  renderErrorMessage,
  renderDataTableViewButton,
}) => {
  const renderHeadingCollape = () => {
    return (
      <>
        <div className="collapse-btn">
          <h4 className="graph-title text-bold">Vendor</h4>
          {graphData?.isVendorOpen ? (
            <FontAwesomeIcon
              className="icon-collapse"
              icon="fa-regular fa-minus"
              onClick={() => {
                handleGraphDetailsOnChange(
                  graphIndex,
                  'vendorDetails',
                  'isVendorOpen',
                  false,
                  false, // isMetrics
                );
              }}
            />
          ) : (
            <FontAwesomeIcon
              className="icon-collapse"
              icon="fa-regular fa-plus"
              onClick={() => {
                handleGraphDetailsOnChange(
                  graphIndex,
                  'vendorDetails',
                  'isVendorOpen',
                  true,
                  false, // isMetrics
                );
              }}
            />
          )}
          <div className=" clear-fix" />
        </div>
      </>
    );
  };

  const rednerGraphFrequency = () => {
    return (
      <li>
        <div className="label" htmlFor="">
          Graph Frequency
        </div>
        <ToggleButton className="mb-4 pb-1">
          <div className="days-container ">
            <ul className="days-tab">
              {groupByFields.map((option) => {
                return (
                  <li
                    className={`p-0 ${
                      !enabledGroupFrequencyList.includes(option.name) ||
                      (reportStatus === 'readyToEdit' &&
                        graphData?.graphFrequency !== option.name)
                        ? 'disabled'
                        : ''
                    } ${
                      graphData?.graphFrequency === option.name
                        ? 'activeGroupBy'
                        : ''
                    }`}
                    key={`vendor${graphIndex}${option.name}`}
                  >
                    <input
                      className="d-none"
                      type="radio"
                      id={`vendor${graphIndex}${option.id}`}
                      name={`vendorGraphFrequency${graphIndex}`}
                      checked={graphData?.graphFrequency === option.name}
                      onChange={() => {
                        handleGraphDetailsOnChange(
                          graphIndex,
                          'vendorDetails',
                          'graphFrequency',
                          option.name,
                          false, // isMetrics
                        );
                      }}
                    />
                    <label htmlFor={`vendor${graphIndex}${option.id}`}>
                      {option.label}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </ToggleButton>
      </li>
    );
  };

  const renderSalesPanelAndToggleOptions = () => {
    return (
      <React.Fragment key={`vendor${graphIndex}`}>
        {graphToggleOptions.map((option) => {
          if (
            selectedTimeFrame?.value === 'custom' &&
            option.id === 'previous'
          ) {
            return null;
          }
          return (
            <ToggleSwitch
              className="custom-switch mt-2"
              key={`vendorDetails${graphIndex}${option.id}`}
            >
              {renderCheckBox(
                graphIndex,
                graphData,
                `vendor${graphIndex}${option.id}`,
                option.label,
                'vendorDetails',
                option.keyName,
                option.className,
                option.spanClass,
                false,
              )}
            </ToggleSwitch>
          );
        })}

        {checkSelectedKeys(graphData, ['showDataTable'])?.length === 0
          ? null
          : renderDataTableViewButton(
              graphData,
              graphIndex,
              'vendorDetails',
              'graphDataTableView',
              'metrics-table',
            )}

        {reportStatus === 'readyToEdit' ? null : (
          <InfoMsg className="mt-2 mb-2">
            You can select a maximum of 4 metrics only in one graph.
          </InfoMsg>
        )}
        {reportStatus === 'readyToEdit' &&
        checkSelectedKeys(graphData, [
          'salesShippedCogs',
          'salesShippedGlanceViews',
          'salesShippedConversionRate',
          'salesShippedUnits',
          'salesOrderedRevenue',
          'salesOrderedGlanceViews',
          'salesOrderedConversionRate',
          'salesOrderedUnits',
        ])?.length === 0 ? null : (
          <>
            <div className="label mt-2" htmlFor="">
              Sales Performance metrics
            </div>

            <div className="thershold mb-2">
              <ul className="days-tab">
                <li
                  role="presentation"
                  style={{ width: '50%' }}
                  className={`p-0 ${
                    graphData?.saleType === 'shippedCogs'
                      ? 'thresholdChecked'
                      : ''
                  }`}
                  onClick={() =>
                    handleGraphDetailsOnChange(
                      graphIndex,
                      'vendorDetails',
                      'saleType',
                      'shippedCogs',
                      false, // isMetrics
                    )
                  }
                >
                  {renderRadioButtons(
                    `vendorSalesType${graphIndex}`,
                    `${graphIndex}vendorSalesType`,
                    graphData?.saleType,
                    graphData?.saleType === 'shippedCogs',
                    'Shipped COGs',
                    'radio-container customer-list',
                  )}
                </li>
                <li
                  role="presentation"
                  style={{ width: '50%' }}
                  className={`p-0 ${
                    graphData?.saleType === 'orderedRevenue'
                      ? 'thresholdChecked'
                      : ''
                  }`}
                  onClick={() =>
                    handleGraphDetailsOnChange(
                      graphIndex,
                      'vendorDetails',
                      'saleType',
                      'orderedRevenue',
                      false, // isMetrics
                    )
                  }
                >
                  {renderRadioButtons(
                    `orderedRevenue${graphIndex}`,
                    `${graphIndex}orderedRevenue`,
                    graphData?.saleType,
                    graphData?.saleType === 'orderedRevenue',
                    'Ordered Revenue',
                    'radio-container customer-list',
                  )}
                </li>
              </ul>
            </div>
          </>
        )}

        {renderErrorMessage(
          'vendorSalesPeformance',
          graphData,
          graphIndex,
          'vendorDetails',
        )}

        {graphData.saleType === 'shippedCogs'
          ? vendorSalesMetricsFields?.shippedCogs.map((option) => {
              return (
                <CheckBox key={option.id}>
                  {renderCheckBox(
                    graphIndex,
                    graphData,
                    option.id,
                    option.label,
                    option.fieldName,
                    option.keyName,
                    option.className,
                    option.spanClass,
                    option.isMetrics,
                  )}
                </CheckBox>
              );
            })
          : vendorSalesMetricsFields?.orderedRevenue.map((option) => {
              return (
                <CheckBox key={option.id}>
                  {renderCheckBox(
                    graphIndex,
                    graphData,
                    option.id,
                    option.label,
                    option.fieldName,
                    option.keyName,
                    option.className,
                    option.spanClass,
                    option.isMetrics,
                  )}
                </CheckBox>
              );
            })}
      </React.Fragment>
    );
  };

  const renderAdPerformancePanel = () => {
    return reportStatus === 'readyToEdit' &&
      checkSelectedKeys(graphData, [
        'adSales',
        'adSpend',
        'adConversionRate',
        'adImpressions',
        'adAcos',
        'adRoas',
        'adCostPerClick',
        'adClicks',
        'adClickThroughRate',

        'adSalesSP',
        'adSpendSP',
        'adConversionRateSP',
        'adImpressionsSP',
        'adAcosSP',
        'adRoasSP',
        'adClicksSP',
        'adClickThroughRateSP',
        'adCostPerClickSP',

        'adSalesSB',
        'adSpendSB',
        'adConversionRateSB',
        'adImpressionsSB',
        'adAcosSB',
        'adRoasSB',
        'adClicksSB',
        'adClickThroughRateSB',
        'adCostPerClickSB',

        'adSalesSBV',
        'adSpendSBV',
        'adConversionRateSBV',
        'adImpressionsSBV',
        'adAcosSBV',
        'adRoasSBV',
        'adClicksSBV',
        'adClickThroughRateSBV',
        'adCostPerClickSBV',

        'adSalesSD',
        'adSpendSD',
        'adConversionRateSD',
        'adImpressionsSD',
        'adAcosSD',
        'adRoasSD',
        'adClicksSD',
        'adClickThroughRateSD',
        'adCostPerClickSD',

        'dspImpressions',
        'dspSpend',
        'dspTotalProductSales',
        'dspTotalRoas',
        'dspTotalDpvr',
        'dspTotalNewBrandPurchases',
        'dspProductSales',
        'dspRoas',
      ])?.length === 0 ? null : (
      <div
        className={
          selectedTimeFrame && selectedTimeFrame.value === 'year'
            ? 'disabled'
            : ''
        }
      >
        <div className="label mt-4" htmlFor="">
          Ad performance metrics
        </div>
        {['year'].includes(selectedTimeFrame?.value) ? (
          <InfoMsg className="mb-2">
            We don&apos;t have data for selected time frame filter.
          </InfoMsg>
        ) : null}

        <div className="thershold mb-2">
          <ul className="days-tab">
            <li
              role="presentation"
              style={{ width: '50%' }}
              className={`p-0 
              ${
                graphData?.adPerformance === 'sponsored'
                  ? 'thresholdChecked'
                  : ''
              }`}
              onClick={() =>
                handleGraphDetailsOnChange(
                  graphIndex,
                  'vendorDetails',
                  'adPerformance',
                  'sponsored',
                  false, // isMetrics
                )
              }
            >
              {renderRadioButtons(
                `vendorSponsored${graphIndex}`,
                `${graphIndex}vendorAdype`,
                graphData?.adPerformance,
                graphData?.adPerformance === 'sponsored',
                'Sponsored Ad',
                'radio-container customer-list',
              )}
            </li>
            <li
              role="presentation"
              style={{ width: '50%' }}
              className={`p-0 ${
                graphData?.adPerformance === 'dsp' ? 'thresholdChecked' : ''
              }`}
              onClick={() =>
                handleGraphDetailsOnChange(
                  graphIndex,
                  'vendorDetails',
                  'adPerformance',
                  'dsp',
                  false, // isMetrics
                )
              }
            >
              {renderRadioButtons(
                `vendorDsp${graphIndex}`,
                `${graphIndex}vendorAdype`,
                graphData?.adPerformance,
                graphData?.adPerformance === 'dsp',
                'DSP Ad',
                'radio-container customer-list',
              )}
            </li>
          </ul>
        </div>
        {renderErrorMessage(
          'adPeformance',
          graphData,
          graphIndex,
          'vendorDetails',
        )}

        {graphData.adPerformance === 'sponsored' ? (
          <>
            <div className="label mt-4" htmlFor="">
              Sponsored Ad type filter
            </div>
            <div className="thershold mb-3">
              <Select
                classNamePrefix="react-select form-control-error"
                options={AdTypesOptions}
                placeholder={graphData.sponsoredType.label}
                components={getAdTypesSelectComponents()}
                value={graphData.sponsoredType}
                isSearchable={false}
                onChange={(event) => {
                  handleGraphDetailsOnChange(
                    graphIndex,
                    'vendorDetails',
                    'sponsoredType',
                    event,
                    false, // isMetrics
                  );
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: '#fff',
                    primary25: Theme.lightOrange,
                    primary: Theme.orange,
                    primary50: Theme.lightOrange,
                  },
                })}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    background: '#F4F6FC',
                    borderColor: '#D5D8E1',
                    boxShadow: state.isFocused ? null : null,
                    '&:hover': {
                      boxShadow: state.isFocused ? null : null,
                      outlineColor: state.isFocused ? null : null,
                    },
                  }),
                  placeholder: (defaultStyles) => {
                    return {
                      ...defaultStyles,
                      color: '556178',
                    };
                  },
                }}
              />
            </div>

            {sellerSponsoredMetricsFields.map((option) => {
              return (
                <CheckBox key={option.id}>
                  {renderCheckBox(
                    graphIndex,
                    graphData,
                    `vendor${option.id}${graphData?.sponsoredType?.key}`,
                    option.label,
                    'vendorDetails',
                    `${option.id}${graphData?.sponsoredType?.key}`,
                    option.className,
                    option.spanClass,
                    option.isMetrics,
                  )}
                </CheckBox>
              );
            })}
          </>
        ) : (
          sellerDspMetricsFields.map((option) => {
            return (
              <CheckBox key={option.id}>
                {renderCheckBox(
                  graphIndex,
                  graphData,
                  `vendor${option.id}`,
                  option.label,
                  'vendorDetails',
                  option.keyName,
                  option.className,
                  option.spanClass,
                  option.isMetrics,
                )}
              </CheckBox>
            );
          })
        )}
      </div>
    );
  };

  const renderInventoryManagement = () => {
    return reportStatus === 'readyToEdit' &&
      checkSelectedKeys(graphData, [
        'strandedInventory',
        'overstockInventory',
        'understockInventory',
      ])?.length === 0 ? null : (
      <div
        className={
          selectedTimeFrame.value === 'year' ||
          selectedTimeFrame.value === 'month'
            ? 'disabled'
            : ''
        }
      >
        <div
          className={`label-heading  mt-4 ${
            ['year', 'month'].includes(selectedTimeFrame?.value) ? '' : 'mb-3'
          }`}
        >
          Inventory Management
        </div>
        {['year', 'month'].includes(selectedTimeFrame?.value) ? (
          <InfoMsg className="mb-3">
            We don&apos;t have data for selected time frame filter.
          </InfoMsg>
        ) : null}

        {inventoryProductNameToggleOption.map((option) => {
          return (
            <ToggleSwitch
              className={`custom-switch mb-2 ${
                graphData?.strandedInventory ||
                graphData?.overstockInventory ||
                graphData?.understockInventory
                  ? ''
                  : 'disabled'
              }`}
              key={`vendorDetails${graphIndex}${option.id}`}
            >
              {renderCheckBox(
                graphIndex,
                graphData,
                `vendor${graphIndex}${option.id}`,
                option.label,
                'vendorDetails',
                option.keyName,
                option.className,
                option.spanClass,
                option.isMetrics,
              )}
            </ToggleSwitch>
          );
        })}

        {inventoryManagementFields.map((option) => {
          return (
            <CheckBox key={`vendor${option.id}${graphIndex}`}>
              {renderCheckBox(
                graphIndex,
                graphData,
                `vendor${option.id}${graphIndex}`,
                option.label,
                'vendorDetails',
                option.keyName,
                option.className,
                option.spanClass,
                option.isMetrics,
              )}
            </CheckBox>
          );
        })}
        {accountType.isHybrid ? (
          <div className="horizontal-line straight-line mt-3" />
        ) : null}
      </div>
    );
  };

  return (
    <React.Fragment key={`vendor${graphIndex}`}>
      {accountType.isHybrid ? renderHeadingCollape() : null}

      {graphData !== null ? (
        <Collapse
          isOpened={graphData?.isVendorOpen}
          key={`vendor${graphIndex}`}
        >
          <ul className="collapse-inner">
            {rednerGraphFrequency()}
            <li>
              {renderSalesPanelAndToggleOptions()}
              {renderAdPerformancePanel()}
              {accountType.isVendor ? renderInventoryManagement() : null}
            </li>
          </ul>
        </Collapse>
      ) : null}
    </React.Fragment>
  );
};

export default RenderVendorFields;

RenderVendorFields.defaultProps = {
  graphData: {},
  graphIndex: 0,
  reportStatus: '',
  accountType: {},
  enabledGroupFrequencyList: [],
  selectedTimeFrame: {},
  handleGraphDetailsOnChange: () => {},
  renderCheckBox: () => {},
  getAdTypesSelectComponents: () => {},
  checkSelectedKeys: () => {},
  renderRadioButtons: () => {},
  renderErrorMessage: () => {},
  renderDataTableViewButton: () => {},
};

RenderVendorFields.propTypes = {
  graphIndex: number,
  reportStatus: string,
  graphData: shape({}),
  accountType: shape({}),
  selectedTimeFrame: shape({}),
  enabledGroupFrequencyList: arrayOf(Array),
  handleGraphDetailsOnChange: func,
  renderCheckBox: func,
  getAdTypesSelectComponents: func,
  checkSelectedKeys: func,
  renderRadioButtons: func,
  renderErrorMessage: func,
  renderDataTableViewButton: func,
};
