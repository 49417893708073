// Auth
export const USER_REQUEST_INITIATED = 'USER_REQUEST_INITIATED';
export const USER_REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS';
export const USER_REQUEST_FAIL = 'USER_REQUEST_FAIL';
export const USER_ME = 'USER_ME';
export const USER_ME_SUCCESS = 'USER_ME_SUCCESS';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const SHOW_FORGOT_PASSWORD_ERROR = 'SHOW_FORGOT_PASSWORD_ERROR';
export const SHOW_RESET_PASSWORD_ERROR = 'SHOW_RESET_PASSWORD_ERROR';

// Customer
export const CUSTOMER_REQUEST_INITIATED = 'CUSTOMER_REQUEST_INITIATED';
export const CUSTOMER_REQUEST_SUCCESS = 'CUSTOMER_REQUEST_SUCCESS';
export const CUSTOMER_REQUEST_FAIL = 'CUSTOMER_REQUEST_FAIL';
export const CUSTOMER_SELECTED_TAB = 'CUSTOMER_SELECTED_TAB';
export const SHOW_BRAND_ASSET_MSG = 'SHOW_BRAND_ASSET_MSG';
export const SHOW_GOALS_MODAL = 'SHOW_GOALS_MODAL';
export const CUSTOMER_MEMBERS = 'CUSTOMER_MEMBERS';

// Account
export const CUSTOMER_ACCOUNT_REQUEST_INITIATED =
  'CUSTOMER_ACCOUNT_REQUEST_INITIATED';
export const CUSTOMER_ACCOUNT_REQUEST_SUCCESS =
  'CUSTOMER_ACCOUNT_REQUEST_SUCCESS';
export const CUSTOMER_ACCOUNT_REQUEST_FAIL = 'CUSTOMER_ACCOUNT_REQUEST_FAIL';

// Contact
export const CONTACT_REQUEST_INITIATED = 'CONTACT_REQUEST_INITIATED';
export const CONTACT_REQUEST_SUCCESS = 'CONTACT_REQUEST_SUCCESS';
export const CONTACT_REQUEST_FAIL = 'CONTACT_REQUEST_FAIL';

// show/hide header
export const SHOW_HEADER = 'SHOW_HEADER';
export const HIDE_HEADER = 'HIDE_HEADER';
// Dynamic
export const SHOW_DYNAMIC_ERROR_MESSAGE = 'SHOW_DYNAMIC_ERROR_MESSAGE';

// KB title
export const SET_KNOWLEDGE_BASE_TITLE = 'SET_KNOWLEDGE_BASE_TITLE';
