/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */

import React from 'react';

import dayjs from 'dayjs';
import {
  string,
  func,
  oneOfType,
  arrayOf,
  shape,
  instanceOf,
} from 'prop-types';

import { DspAddendumParagraph } from '../../../theme/AgreementStyle';
import { DigitalSignature } from '../../../theme/images';
import { thadDetails } from '../../../constants';

export default function DSPAddendum({
  formData,
  templateData,
  firstMonthDate,
  secondMonthDate,
  thirdMonthDate,
  endMonthDate,
  selectedCurrency,
  displayFirstMonthDateRange,
  returnDspMonthlyDates,
  returnDspMonthAndDays,
  displayMarketplaceName,
  displayBBELogo,
  startDate,
  mapMonthlyServices,
  mapMonthlyServiceTotal,
}) {
  const handleNewDate = (monthDateOrder) => {
    const newDate = new Date(
      new Date(
        new Date(monthDateOrder).getFullYear(),
        new Date(monthDateOrder).getMonth() + 1,
        0,
      ),
    );
    return newDate;
  };

  const calculateTotalDays = (flag = '') => {
    let firstMonthdays = 0;
    if (new Date(firstMonthDate).getDate() !== 1) {
      const totalDays = handleNewDate(firstMonthDate).getDate();
      const currentDate = new Date(firstMonthDate).getDate();
      firstMonthdays = totalDays - currentDate;
    } else {
      firstMonthdays = handleNewDate(firstMonthDate).getDate();
    }
    let extraDays = 0;
    if (new Date(firstMonthDate).getDate() !== 1) {
      extraDays = handleNewDate(endMonthDate).getDate();
    }
    const secondMonthdays = handleNewDate(secondMonthDate).getDate();
    const thirdMonthdays = handleNewDate(thirdMonthDate).getDate();
    const totaldays =
      firstMonthdays + extraDays + secondMonthdays + thirdMonthdays;
    if (flag === 'initial') {
      if (totaldays < 105) {
        return '3';
      }
      return '3.5';
    }

    if (totaldays < 105) {
      return `90 days (3 months)`;
    }
    return `105 days (3.5 months)`;
  };

  const mapDefaultValues = (key, label, type) => {
    if (key === 'current_date') {
      return dayjs(new Date()).format('MM / DD / YYYY');
    }
    if (key === 'company_name') {
      return formData && formData.customer_id && formData.customer_id[key]
        ? formData && formData.customer_id && formData.customer_id[key]
        : `Client Name`;
    }
    if (key === 'calculated_no_of_days') {
      return calculateTotalDays();
    }
    if (key === 'start_date') {
      return startDate !== null
        ? `<span style="padding: 4px 9px; font-weight: bold";>${
            startDate && dayjs(startDate).format('MM / DD / YYYY')
          }</span>`
        : 'Select Date';
    }

    if (key === 'dsp_length') {
      if (formData?.contract_type?.toLowerCase().includes('dsp')) {
        return calculateTotalDays('initial');
      }
      if (
        formData[key] === undefined ||
        formData[key] === '' ||
        formData[key] === null
      ) {
        return `Enter ${label}`;
      }
      return formData?.dsp_length?.label
        ? parseInt(formData.dsp_length.label, 10)
        : parseInt(formData.dsp_length, 10);
    }
    if (
      formData[key] === undefined ||
      formData[key] === '' ||
      formData[key] === null
    ) {
      if (
        label === 'Dsp Fee' ||
        label === 'Dsp Fee Second' ||
        label === 'Dsp Fee Third'
      ) {
        return `Enter DSP Budget`;
      }
      return `Enter ${label}`;
    }

    if (type && type.includes('number')) {
      return `
      
      ${type === 'number-currency' ? selectedCurrency : '%'}${
        formData && formData[key]
          ? `${formData[key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
          : `Enter ${label}.`
      }`;
    }
    return formData[key];
  };

  const displayNumber = (num) => {
    const res = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return res;
  };

  const mapDspDetails = () => {
    return `<tr>
        <td style="border: 1px solid black;
    padding: 13px;"><span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
          ${
            firstMonthDate
              ? dayjs(firstMonthDate).format('MM-DD-YYYY')
              : 'MM-DD-YYYY'
          }
          </span>
        </td>
        <td style="border: 1px solid black;
    padding: 13px;"><span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
          ${
            formData?.dsp_length
              ? formData?.dsp_length?.value || formData?.dsp_length
              : `<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold">Enter DSP Service Length </span>`
          }
    </td>
        ${
          formData?.is_dynamic_dsp_fee
            ? `
          <td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     DSP_FEE
     </span>
        </td>
        <td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     SECOND_DSP
     </span>
        </td>
        <td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     THIRD_DSP
     </span>
        </td>
        `
            : `<td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     DSP_FEE
     </span>
        </td>`
        }
           ${
             formData?.changed_dsp_start_date
               ? `<td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     DSP_ONE_TIME_FEE
     </span>
        </td>`
               : ''
           }
      </tr>`;
  };

  const mapBudgetBreakdownTable = () => {
    return `  
    <div class="table-responsive">  <table class="contact-list " style="width: 100%;
    border-collapse: collapse;"><tr>
    ${
      formData?.changed_dsp_start_date
        ? `<th style="text-align: left; border: 1px solid black;
    padding: 13px;">
    ${displayFirstMonthDateRange()} </th>`
        : ``
    } 
    <th style="text-align: left; border: 1px solid black;
    padding: 13px;">
    ${
      !formData?.changed_dsp_start_date
        ? dayjs(firstMonthDate).format('MMMM YYYY')
        : returnDspMonthlyDates(new Date(firstMonthDate), 1)
    }
    </th>
    <th style="text-align: left; border: 1px solid black;
    padding: 13px;">${
      secondMonthDate
        ? !formData?.changed_dsp_start_date
          ? dayjs(secondMonthDate).format('MMMM YYYY')
          : returnDspMonthlyDates(new Date(secondMonthDate), 1)
        : 'MM-DD-YYYY'
    }</th><th style="text-align: left; border: 1px solid black;
    padding: 13px;">${
      thirdMonthDate
        ? !formData?.changed_dsp_start_date
          ? dayjs(thirdMonthDate).format('MMMM YYYY')
          : returnDspMonthlyDates(new Date(thirdMonthDate), 1)
        : 'MM-DD-YYYY'
    } </th></tr>
    <tr>
    ${
      formData?.changed_dsp_start_date
        ? `<td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
               DSP_ONE_TIME_FEE
    </span>
    </td>`
        : ''
    }
        <td style="border: 1px solid black;
    padding: 13px;"> <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";> DSP_FEE </span></td>
        <td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
               ${formData?.is_dynamic_dsp_fee ? 'SECOND_DSP' : 'DSP_FEE'}
               </span>

        </td><td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
                             ${
                               formData?.is_dynamic_dsp_fee
                                 ? 'THIRD_DSP'
                                 : 'DSP_FEE'
                             }

        </td>
      </tr>
      
      </table>
     </div>`;
  };

  const mapThadSignImg = () => {
    const data = `<p style="margin:0" class="long-text">
        <span style="font-weight: 300;font-family: Helvetica-Regular;">
          <img
            style="width:120px; margin-top: -5px;"
            src = ${DigitalSignature}
            alt="sig"
          />
        </span>
      </p>`;
    return data;
  };

  const renderDspMarketplaces = (marketplaces) => {
    const fields = [];
    for (const item of marketplaces) {
      fields.push(
        `<th ${
          formData?.changed_dsp_start_date ? `colspan="2"` : ''
        } width: 33%  style="border: 1px solid black;padding: 13px; ">
            <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
              ${displayMarketplaceName(item?.name, 'dsp', 'lhs')}
            </span>
          </th>`,
      );
    }

    return fields;
  };

  const renderDspBudgetMarketplaces = (marketplaces) => {
    const fields = [];
    for (const item of marketplaces) {
      fields.push(
        `${
          formData?.changed_dsp_start_date
            ? `<td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="text-align: center!important; margin-bottom: 3rem!important;">One Time</span> <br>
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     ${selectedCurrency}${displayNumber(item.one_time_budget || 0)}
     </span>
        </td>`
            : ''
        }
        <td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="text-align: center!important; margin-bottom: 3rem!important; line-height: 1.5;">Monthly</span> <br>
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     ${selectedCurrency}${displayNumber(item.monthly_budget || 0)}
     </span>
        </td>`,
      );
    }

    return fields;
  };
  const showDynamicBudgetAllocation = () => {
    const fields = [];
    for (const item of formData?.dsp_marketplaces) {
      fields.push(`
          <tr><td
          style="border: 1px solid black;
    padding: 13px;">
   
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
                   ${displayMarketplaceName(item?.name, 'dsp', 'lhs')}

     </span>
        </td>
       
       ${
         formData?.changed_dsp_start_date
           ? `<td
          style="border: 1px solid black;
    padding: 13px;">
    
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     ${selectedCurrency}${displayNumber(item.one_time_budget || 0)}
     </span>
        </td>`
           : ''
       }
        <td
          style="border: 1px solid black;
    padding: 13px;">

    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     ${selectedCurrency}${displayNumber(item.monthly_budget || 0)}
     </span>
        </td>
        <td
          style="border: 1px solid black;
    padding: 13px;">

    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     ${selectedCurrency}${displayNumber(item.monthly_budget_second || 0)}
     </span>
        </td>
        <td
          style="border: 1px solid black;
    padding: 13px;">

    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     ${selectedCurrency}${displayNumber(item.monthly_budget_third || 0)}
     </span>
        </td></tr> 
        `);
    }
    return fields;
  };

  const displayMarketplace = () => {
    const result = [];

    const temp = [...formData?.dsp_marketplaces];
    if (formData?.dsp_marketplaces?.length) {
      if (formData?.is_dynamic_dsp_fee) {
        result.push(
          `<tr><th style="text-align: left; border: 1px solid black;
                      padding: 13px;">DSP Marketplace</th>
                      
                      ${
                        formData?.changed_dsp_start_date
                          ? `<th style="text-align: left; border: 1px solid black;
                      padding: 13px;">One Time Ad Budget</th>`
                          : ''
                      }
                       <th style="text-align: left; border: 1px solid black;
                      padding: 13px;">${
                        !formData?.changed_dsp_start_date
                          ? dayjs(firstMonthDate).format('MMMM YYYY')
                          : returnDspMonthlyDates(new Date(firstMonthDate), 1)
                      }</th> <th style="text-align: left; border: 1px solid black;
                      padding: 13px;">${
                        !formData?.changed_dsp_start_date
                          ? dayjs(secondMonthDate).format('MMMM YYYY')
                          : returnDspMonthlyDates(new Date(secondMonthDate), 1)
                      }</th> <th style="text-align: left; border: 1px solid black;
                      padding: 13px;">${
                        !formData?.changed_dsp_start_date
                          ? dayjs(thirdMonthDate).format('MMMM YYYY')
                          : returnDspMonthlyDates(new Date(thirdMonthDate), 1)
                      }</th></tr>${showDynamicBudgetAllocation()}`,
        );
      } else {
        const formattedData = new Array(Math.ceil(temp.length / 3))
          .fill()
          .map(() => temp.splice(0, 3));

        for (const item of formattedData) {
          result.push(`
          <tr>${renderDspMarketplaces(item)}</tr> 
          <tr>${renderDspBudgetMarketplaces(item)}</tr> 
        `);
        }
      }
    }
    return result.length ? result.toString().replaceAll('>,', '>') : '';
  };

  const displayMarketplacesTable = () => {
    return formData?.dsp_marketplaces?.length
      ? `
      <div className=" text-center mt-5 mb-4 " style="text-align: center; margin-top: 3rem!important; margin-bottom: 1.5rem!important; " >
                    <span  style="font-weight: 800;
    font-family: Helvetica-bold;"> Marketplace(s) Details </span></br>
    The initial budget for every marketplace will be set in advance by the Parties without adjustments during the initial period ("Initial Period") 
    based on start date, unless the Client wishes to increase the ad spend from the original budget at any point during that Initial Period. 
    The Parties agree that the initial budget breakdown for every marketplace will be as follows:
    </div> <table class="contact-list " style="width: 100%;
    border-collapse: collapse;">
    <tbody>${displayMarketplace()}</tbody>
    </table><p style="margin-top: 12px;">The Agency fee for Amazon DSP advertising is <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";> ${
      formData?.agency_fee?.label
        ? formData?.agency_fee?.label
        : `${formData?.agency_fee}%`
    } </span> of ad spend. Amazon charges their own platform and audience fees.</p>
    `
      : '';
  };
  const showAMCServiceTable = () => {
    if (formData?.contract_type?.includes('dsp')) {
      if (formData?.is_amc_management) {
        return `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center">
      <span style="font-weight: 800; font-family: Helvetica-bold;">Additional Advertising Services </span>
      <br> The following additional monthly services will be provided to Client in addition to the Monthly Retainer.</div><br>
      <div class="table-responsive">
        <table class="contact-list " style="width: 100%;
                      border-collapse: collapse;">
          <tr>
            <th>Service</th>
            <th>Service Fee</th>
          </tr>
            ${mapMonthlyServices('amc_services', formData?.amc_services)}
          ${mapMonthlyServiceTotal(formData?.amc_services, '', '')}

        </table>
      </div>`;
      }
      return '';
    }
    return '';
  };

  return (
    <div>
      <DspAddendumParagraph>
        <>
          <p
            className="long-text"
            dangerouslySetInnerHTML={{
              __html:
                templateData.dsp_addendum &&
                templateData.dsp_addendum[0]
                  .replace('BBE_LOGO', displayBBELogo())
                  .replace(
                    'CUSTOMER_NAME',
                    mapDefaultValues('company_name', 'Customer Name'),
                  )
                  .replace(
                    '{{Signdate}}',
                    mapDefaultValues('start_date', 'Start Date'),
                  )
                  .replace(
                    'DSP_DETAIL_TABLE',
                    `<table class="contact-list " style="width: 100%;
                      border-collapse: collapse;"><tr><th style="text-align: left; border: 1px solid black;
                      padding: 13px;">Start Date</th>
                      <th style="text-align: left; border: 1px solid black;
                      padding: 13px;">DSP Service Length</th>
                 ${
                   formData?.is_dynamic_dsp_fee
                     ? `
                       <th
                         style="text-align: left; border: 1px solid black;padding: 13px;">
    ${
      !formData?.changed_dsp_start_date
        ? dayjs(firstMonthDate).format('MMMM YYYY')
        : returnDspMonthlyDates(new Date(firstMonthDate), 1)
    }<br /> Monthly Ad Budget
                       </th>
                       <th
                         style="text-align: left; border: 1px solid black;
                  padding: 13px;">
                            ${
                              !formData?.changed_dsp_start_date
                                ? dayjs(secondMonthDate).format('MMMM YYYY')
                                : returnDspMonthlyDates(
                                    new Date(secondMonthDate),
                                    1,
                                  )
                            }<br /> Monthly Ad Budget
                       </th>
                       <th
                         style="text-align: left; border: 1px solid black;
                  padding: 13px;">
                           ${
                             !formData?.changed_dsp_start_date
                               ? dayjs(thirdMonthDate).format('MMMM YYYY')
                               : returnDspMonthlyDates(
                                   new Date(thirdMonthDate),
                                   1,
                                 )
                           } <br /> Monthly Ad Budget
                       </th>
                     `
                     : `<th
                       style="text-align: left; border: 1px solid black;
                  padding: 13px;">
                       Monthly Ad Budget
                     </th>`
                 }
                  ${
                    formData?.changed_dsp_start_date
                      ? `<th style="text-align: left; border: 1px solid black;
                  padding: 13px;">One Time Ad Budget</th>`
                      : ``
                  } </tr>${mapDspDetails()
                      .replaceAll(
                        'DSP_FEE',
                        mapDefaultValues(
                          'dsp_fee',
                          'Dsp Fee',
                          'number-currency',
                        ),
                      )
                      .replaceAll(
                        'SECOND_DSP',
                        mapDefaultValues(
                          'dsp_fee_second',
                          'Dsp Fee',
                          'number-currency',
                        ),
                      )
                      .replaceAll(
                        'THIRD_DSP',
                        mapDefaultValues(
                          'dsp_fee_third',
                          'Dsp Fee',
                          'number-currency',
                        ),
                      )
                      .replace(
                        'DSP_ONE_TIME_FEE',
                        mapDefaultValues(
                          'dsp_one_time_fee',
                          'Dsp Fee',
                          'number-currency',
                        ),
                      )}</table>`,
                  )
                  .replace(
                    'BUDGET_BREAKDOWN_TABLE',
                    `${mapBudgetBreakdownTable()
                      .replaceAll(
                        'DSP_FEE',
                        mapDefaultValues(
                          'dsp_fee',
                          'Dsp Fee',
                          'number-currency',
                        ),
                      )
                      .replaceAll(
                        'SECOND_DSP',
                        mapDefaultValues(
                          'dsp_fee_second',
                          'Dsp Fee',
                          'number-currency',
                        ),
                      )
                      .replaceAll(
                        'THIRD_DSP',
                        mapDefaultValues(
                          'dsp_fee_third',
                          'Dsp Fee',
                          'number-currency',
                        ),
                      )
                      .replace(
                        'DSP_ONE_TIME_FEE',
                        mapDefaultValues(
                          'dsp_one_time_fee',
                          'Dsp Fee',
                          'number-currency',
                        ),
                      )}${displayMarketplacesTable().replaceAll(' ,', '')}`,
                  )
                  .replace('AMC_SERVICE_TABLE', showAMCServiceTable())
                  .replace(
                    'NO_OF_DAYS_BASED_ON_DATE',
                    returnDspMonthAndDays(true),
                  )
                  .replaceAll('CONTRACT_LENGTH', returnDspMonthAndDays(false)),
            }}
          />
          <p
            className="long-text"
            dangerouslySetInnerHTML={{
              __html:
                templateData.dsp_addendum &&
                templateData.dsp_addendum[1]
                  .replace(
                    'CUSTOMER_NAME',
                    mapDefaultValues('company_name', 'Customer Name'),
                  )
                  .replace(
                    '{{Signdate}}',
                    mapDefaultValues('start_date', 'Start Date'),
                  )
                  .replace(
                    'BBE_DATE',
                    mapDefaultValues('current_date', 'Current Date'),
                  )
                  .replace('THAD_SIGN', mapThadSignImg())
                  .replace(
                    'PRINTED_NAME',
                    `${thadDetails?.firstName} ${thadDetails?.lastName} `,
                  ),
            }}
          />
        </>
      </DspAddendumParagraph>
    </div>
  );
}

DSPAddendum.defaultProps = {
  templateData: {},
  formData: {},
  firstMonthDate: '',
  secondMonthDate: '',
  thirdMonthDate: '',
  endMonthDate: '',
  selectedCurrency: '$ (USD)',
  displayFirstMonthDateRange: () => {},
  returnDspMonthlyDates: () => {},
  returnDspMonthAndDays: () => {},
  displayMarketplaceName: () => {},
  displayBBELogo: () => {},
  startDate: '',
  mapMonthlyServices: () => {},
  mapMonthlyServiceTotal: () => {},
};

DSPAddendum.propTypes = {
  templateData: shape({
    dsp_addendum: arrayOf(string),
  }),
  firstMonthDate: oneOfType([string, instanceOf(Date)]),
  secondMonthDate: oneOfType([string, instanceOf(Date)]),
  thirdMonthDate: oneOfType([string, instanceOf(Date)]),
  endMonthDate: oneOfType([string, instanceOf(Date)]),
  selectedCurrency: string,
  formData: shape({
    dsp_fee: string,
    dsp_length: oneOfType([
      string,
      shape({
        value: string,
        label: string,
      }),
    ]),
    contract_status: shape({
      label: string,
      value: string,
    }),
    start_date: oneOfType([string, instanceOf(Date)]),
    contract_type: string,
    customer_id: shape({
      address: string,
      city: string,
      state: string,
      zip_code: string,
    }),
  }),
  displayFirstMonthDateRange: func,
  returnDspMonthlyDates: func,
  returnDspMonthAndDays: func,
  displayMarketplaceName: func,
  displayBBELogo: func,
  startDate: instanceOf(Date),
  mapMonthlyServices: func,
  mapMonthlyServiceTotal: func,
};
