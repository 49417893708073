import React from 'react';

import { func, shape } from 'prop-types';

import { BBEStandardLogo } from '../../../../theme/images';
import { ReportPDFHeader, ReportPDFBody } from './CreateReportStyle';

function ReportPDFWithHperlink({
  html2pdfRef,
  pdfHeader,
  renderReportPDFBodyContent,
}) {
  // generate pdf header html
  const renderReportPDFHeaderHTML = () => {
    return (
      <ReportPDFHeader>
        <div className="report-pdf-header">
          <div className="row">
            <div className="col-8">
              <div className="pdf-header-list">
                <ul>
                  <li>
                    <strong>Customer Name:</strong>&nbsp;&nbsp;
                    {pdfHeader?.customerName}
                  </li>
                  <li>
                    <strong>Marketplace:</strong>&nbsp;&nbsp;
                    {pdfHeader?.marketplace}
                  </li>
                  <li>
                    <strong>Account Type:</strong>&nbsp;&nbsp;
                    {pdfHeader?.accountType}
                  </li>
                  <li>
                    <strong>Date Range:</strong>&nbsp;&nbsp;
                    {pdfHeader?.dateRange}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-4 text-right">
              <img
                src={BBEStandardLogo}
                alt="logo"
                style={{ width: '100%', height: 'auto', maxWidth: '120px' }}
              />
            </div>
            <div className="col-12 mb-3">
              <div className="pdf-header-list">
                <ul>
                  <li>
                    <strong>Report Summary:</strong>&nbsp;&nbsp;
                    {pdfHeader?.reportSummary}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ReportPDFHeader>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '755px',
        overflowX: 'auto',
        margin: '0px auto',
        background: '#ffffff',
      }}
    >
      <div ref={html2pdfRef}>
        <div className="row">
          <div className="col-12">
            <ReportPDFBody>
              {renderReportPDFHeaderHTML()}
              {renderReportPDFBodyContent()}
            </ReportPDFBody>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportPDFWithHperlink;

ReportPDFWithHperlink.defaultProps = {
  pdfHeader: {},
  html2pdfRef: {},
  renderReportPDFBodyContent: () => {},
};

ReportPDFWithHperlink.propTypes = {
  renderReportPDFBodyContent: func,
  html2pdfRef: shape({}),
  pdfHeader: shape(),
};
