/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import queryString from 'query-string';
import styled from 'styled-components';
// import HelloSign from 'hellosign-embedded';
import { useHistory } from 'react-router-dom';

import { PageLoader } from '../../../common';
import { PATH_WARNING } from '../../../constants';
import { transactionalSignUp } from '../../../api';

function HelloSignComponent() {
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [signURL, setSignURL] = useState(null);

  useEffect(() => {
    transactionalSignUp({
      request_id: params.key,
    }).then((res) => {
      if (res && res.status === 400) {
        history.push({
          pathname: PATH_WARNING,
          state: { error: res?.data?.request_id },
        });
        setIsLoading({ loader: false, type: 'page' });
      } else {
        setSignURL(res?.data?.sign_url);
        setIsLoading({ loader: false, type: 'page' });
      }
    });
  }, []);

  return (
    <div>
      {isLoading.loader && isLoading.type === 'page' ? (
        <PageLoader color="#FF5933" type="page" />
      ) : (
        <SignPanel>
          <iframe
            src={signURL}
            title="zoho-sign"
            className="zoho-sign-panel"
            width="100%"
            min-height="100vh"
            allowFullScreen
          />
          {/* <Header /> */}
          {/* <Footer className="sticky">
            <p>Last updated by You on Dec 1, 4:18 PM</p>
          </Footer> */}
        </SignPanel>
      )}
    </div>
  );
}

export default HelloSignComponent;
const SignPanel = styled.div`
  .zoho-sign-panel {
    min-height: 100vh !important;
  }
`;
