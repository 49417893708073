// react imports
import React from 'react';

// third party imports
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// local file imports
import {
  DailyWeeklyMonthlyToggle,
  DropdownIndicator,
  DropdownOptions,
  GrayMessageBar,
  MultiSelectDropdownWithoutAll,
  NoData,
  PageLoader,
  SingleSelectDropdown,
  WhiteCard,
} from '../../../../common';
import {
  CUSTOM,
  dailyWeeklyMonthlyOptions,
  dateOptions,
  noGraphDataMessage,
  performanceInsightMessage,
  SELLERSMALL,
  sponoredMetricsList,
  sponsoredTooltipDataHTML,
} from '../../../../constants';
import SponsoredAdMetrics from './SponsoredAdMetrics';
import AdPerformanceChart from '../../../Customer/CustomerDetailView/CompanyPerformance/AdPerformanceView/SponsoredAdPerformance/AdPerformanceChart';
import SponsoredKeyContribution from './SponsoredKeyContribution';
import {
  SponsoredRightHandSideSectionDefaultProps,
  SponsoredRightHandSideSectionPropTypes,
} from './SponsoredDashboardPropTypes';

// third party variables
const _ = require('lodash');

function SponsoredRightHandSideSection({
  // bool
  isCustomDateApply,
  adGraphLoader,
  downloadReportLoader,
  keyContributionLoader,
  // string
  adGroupBy,
  currencySymbol,
  selectedTabMetrics,
  // number
  pageNumber,
  contributionCount,
  // object
  selectedAdDF,
  selectedAdType,
  selectedAdMetrics,
  customDateError,
  selectedPerformanceType,
  // array
  adState,
  adChartData,
  adDifference,
  adCurrentTotal,
  adPreviousTotal,
  contributionData,
  sponsoredAdMultiSelected,
  // function
  handleAdGroupBy,
  handlePageChange,
  handleAdDailyFact,
  setSelectedAdMetrics,
  handleOnMetricsTabChange,
  displayContributionTable,
  setSponsoredAdMultiSelected,
  renderRecentAndPreviousLabel,
  handledSponsoredMetricsOnMenuClose,
  handleKeyContributorsDownloadReport,
}) {
  const { SingleValue } = components;
  const dashboardDynamicError = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );

  const addThousandComma = (value, decimalDigits = 2) => {
    if (value !== undefined && value !== null) {
      return value.toFixed(decimalDigits).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return value;
  };

  // get sponsored metric options
  const getSponsoredMetricOptions = () => {
    let localAdMetricsOptions = sponoredMetricsList;

    localAdMetricsOptions = sponoredMetricsList?.map((ele) => {
      // PDV-9017  NTBOrders, NTBSales, percentageNtbSales, percentageNtbOrders will only be enabled
      //  if ad type filter is set to ALL, Sponsored Brands+Video, Sponsored Display otherwise, it will be grayed out.
      if (
        ([
          'ntbOrders',
          'ntbSales',
          'percentageNtbSales',
          'percentageNtbOrders',
        ].includes(ele?.value) &&
          ['product_campaign_report', 'television_campaign_report'].includes(
            selectedAdType?.value,
          )) ||
        // at least four option should be selected.
        (sponsoredAdMultiSelected?.length === 4 &&
          (sponsoredAdMultiSelected[0]?.value === ele?.value ||
            sponsoredAdMultiSelected[1]?.value === ele?.value ||
            sponsoredAdMultiSelected[2]?.value === ele?.value ||
            sponsoredAdMultiSelected[3]?.value === ele?.value))
      ) {
        return {
          value: ele?.value,
          label: ele?.label,
          isDisabled: true,
        };
      }

      // PDV-9017- if 8 metrics are already selected, the other metrics will be grayed out
      const isDisabled =
        sponsoredAdMultiSelected.length === 8 &&
        sponsoredAdMultiSelected.find((o) => o?.value === ele?.value) ===
          undefined;

      return {
        value: ele?.value,
        label: ele?.label,
        isDisabled,
      };
    });

    return localAdMetricsOptions;
  };

  const renderCustomDateSubLabel = (props) => {
    if (selectedAdDF?.value === CUSTOM && isCustomDateApply) {
      return `From- ${dayjs(adState[0]?.startDate).format(
        'MMM D, YYYY',
      )}  To- ${dayjs(adState[0]?.endDate).format('MMM D, YYYY')}`;
    }

    return props?.data?.sub;
  };

  // react select - single value
  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
      <div style={{ fontSize: '10px' }}>{renderCustomDateSubLabel(props)}</div>
    </SingleValue>
  );

  // react select components
  const getSelectComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  // display error message
  const renderErrorMessage = () => {
    let { fromDate } = customDateError;
    let { toDate } = customDateError;

    fromDate = dayjs(fromDate).format('MM/DD/YYYY');
    toDate = dayjs(toDate).subtract(1, 'day').format('MM/DD/YYYY');

    return (
      <GrayMessageBar
        message={`There is no sponsored advertising data available from ${fromDate}
      to ${toDate}.`}
      />
    );
  };

  // display sp ad performance section header
  const displaySpAdPerformanceSectionHeader = () => {
    const isApiCall = adGraphLoader || keyContributionLoader;
    return (
      <div className="row">
        <div className="col-md-6 col-sm1-12">
          <div className="black-heading-title mt-2 mb-4">
            Sponsored Ad Performance
            <FontAwesomeIcon
              icon="fal fa-info-circle"
              fontSize="13px"
              style={{ marginLeft: '3px' }}
              data-tip={sponsoredTooltipDataHTML}
              data-for="adSponsored"
            />
            <ReactTooltip
              class="initialTextTransform"
              id="adSponsored"
              aria-haspopup="true"
              place="top"
              effect="solid"
              multiline
              html
            />
          </div>
        </div>
        {/* need to reset the multi-selected drop-down and selected metrics, 
         when we changed the account type filter to vendor- because metrics multi
         select functionality only for seller (PDV-9017) */}
        <div className="col-md-3 col-sm1-12 mb-3 pr-md-0">
          {selectedPerformanceType?.value === SELLERSMALL ? (
            <div id="BT-SponsoredMultiselectFilter">
              <MultiSelectDropdownWithoutAll
                options={getSponsoredMetricOptions()}
                label="Metrics"
                multiSelectedValues={sponsoredAdMultiSelected}
                setMultiSelectedValues={setSponsoredAdMultiSelected}
                isDisabled={isApiCall}
                handleOnMenuClose={() => {
                  handledSponsoredMetricsOnMenuClose();
                }}
                multiselectLabel={null}
              />
            </div>
          ) : null}
        </div>
        <div className="col-md-3 col-sm1-12 mb-3">
          <div style={{ position: 'relative' }}>
            <SingleSelectDropdown
              filterId="BT-SponsoredDashboardTimeFrame"
              className="single-select-dropdown"
              dropdownOptions={dateOptions}
              selectedValue={selectedAdDF}
              onChangeHandler={handleAdDailyFact}
              dropdownComponents={getSelectComponents}
              isApiCall={isApiCall}
              isSearchable={false}
            />
          </div>
        </div>
        <div className="col-12">
          {dashboardDynamicError?.sponsored_performance?.placeholder ? (
            <GrayMessageBar
              message={
                dashboardDynamicError?.sponsored_performance?.placeholder
              }
            />
          ) : null}
          {selectedAdDF?.value !== CUSTOM ? (
            <GrayMessageBar message={performanceInsightMessage?.sponsored} />
          ) : null}
          {selectedAdDF?.value === CUSTOM && customDateError !== null
            ? renderErrorMessage()
            : null}
        </div>
      </div>
    );
  };

  // render sponsored metrics section
  const renderSponsoredMetricsSection = () => {
    return (
      <SponsoredAdMetrics
        currencySymbol={currencySymbol}
        setSelectedAdMetrics={setSelectedAdMetrics}
        selectedAdMetrics={selectedAdMetrics}
        adCurrentTotal={adCurrentTotal}
        addThousandComma={addThousandComma}
        adPreviousTotal={adPreviousTotal}
        adDifference={adDifference}
        isApiCall={adGraphLoader}
        sponsoredAdMultiSelected={sponsoredAdMultiSelected}
      />
    );
  };

  // display sponsored add daily-weekly-monthly toggle button
  const displaySpAdGroupBySection = () => {
    return (
      <div className="row mt-4 mb-3">
        {_.size(selectedAdMetrics) <= 2 ? (
          <div className="col-md-6 col-sm-12 order-md-1 order-2 mt-2">
            {renderRecentAndPreviousLabel(
              selectedAdMetrics,
              selectedAdDF?.value,
            )}
          </div>
        ) : (
          <div className="col-md-6 col-sm-12 order-md-1 order-2 mt-2" />
        )}
        <div className="col-md-6 col-sm-12 order-md-2 order-1">
          <DailyWeeklyMonthlyToggle
            // boolean
            loader={adGraphLoader}
            // string
            toggleId="BT-DspDWM-Toggle"
            toggleState={adGroupBy}
            // array
            toggleOptions={dailyWeeklyMonthlyOptions}
            // function
            toggleEventHandler={handleAdGroupBy}
          />
        </div>
      </div>
    );
  };

  // render sponsored sales performance graph
  const renderSponsoredSalesPerformanceGraph = () => {
    return adGraphLoader ? (
      <PageLoader
        component="performance-graph"
        color="#FF5933"
        type="detail"
        width={40}
        height={40}
      />
    ) : adChartData.length >= 1 ? (
      <AdPerformanceChart
        chartId="adChart"
        chartData={adChartData}
        currencySymbol={currencySymbol}
        selectedMetrics={selectedAdMetrics}
        selectedDF={selectedAdDF?.value}
        groupBy={adGroupBy}
      />
    ) : (
      <NoData>{noGraphDataMessage}</NoData>
    );
  };

  // render sposored contribution section
  const renderSponsoredContributionSection = () => {
    return (
      <SponsoredKeyContribution
        isApiCall={keyContributionLoader}
        downloadReportLoader={downloadReportLoader}
        contributionData={contributionData}
        selectedAdMetrics={selectedAdMetrics}
        selectedTabMetrics={selectedTabMetrics}
        handleOnMetricsTabChange={handleOnMetricsTabChange}
        currencySymbol={currencySymbol}
        selectedAdDF={selectedAdDF}
        handlePageChange={handlePageChange}
        contributionCount={contributionCount}
        pageNumber={pageNumber}
        count={contributionCount}
        displayContributionTable={displayContributionTable}
        handleKeyContributorsDownloadReport={
          handleKeyContributorsDownloadReport
        }
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      <WhiteCard id="BT-sponsored-Performance-Section" className="mb-3">
        {displaySpAdPerformanceSectionHeader()}
        {renderSponsoredMetricsSection()}
        {displaySpAdGroupBySection()}
        {renderSponsoredSalesPerformanceGraph()}
      </WhiteCard>
      {renderSponsoredContributionSection()}
    </>
  );
}

export default SponsoredRightHandSideSection;

/* ********** Declaring prop types ********** */
SponsoredRightHandSideSection.defaultProps =
  SponsoredRightHandSideSectionDefaultProps;

SponsoredRightHandSideSection.propTypes =
  SponsoredRightHandSideSectionPropTypes;
