// react imports
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// third party imports
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { getDate, getMonth } from 'date-fns';
import { bool, func, string } from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';

// local imports
import Theme from '../../../../theme/Theme';
import useFormatNumber from '../../../../hooks/useFormatNumber';
import { EditInput, InputDateDropDown } from '../CustomerDetailStyles';
import {
  errorMessages,
  invoiceConstants,
  invoiceTooltipMessages,
  pauseAgreementStaticMesssages,
  pauseRequestNote,
  pendingPauseRequestOptions,
} from '../../../../constants';
import {
  getAgreementData,
  agreementCancelPauseRequest,
  getPauseAgreementDSPAmounts,
  updateBillingOffBoardingApproveReject,
  getCancellationBillingData,
} from '../../../../api';
import {
  EditExternalLinkOrange,
  InfoIcons,
  LeftArrowIcon,
  OrangeCheckMark,
} from '../../../../theme/images';
import {
  ModalBox,
  Button,
  CheckBox,
  InputFormField,
  PageLoader,
  ErrorMsg,
  ModalRadioCheck,
} from '../../../../common';

export default function AgreementPauseModal({
  agreementId,
  isBgs,
  isBgsManager,
  saveDetails,
  bgsManagerEmail,
  addBGSMangerEmail,
  setShowModal,
}) {
  const mounted = useRef(false);
  const modalContentRef = useRef();
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const [modalContentHeight, setModalContentHeight] = useState(0);
  const [bgsManagerCheck, setBgsManagerCheck] = useState(true);
  const [loader, setLoader] = useState({ page: false });
  const [isDisabledRetainerEditOption, setIsDisabledRetainerEditOption] =
    useState(true);
  const [isAllowToEditRetainer, setIsAllowToEditRetainer] = useState(false);
  const [isAlreadyPaused, setIsAlreadyPaused] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showPendingPauseRequestModal, setShowPendingPauseRequestModal] =
    useState(false);
  const [agreementData, setAgreementData] = useState({});
  const [largerAmountErrorRetainer, setLargerAmountErrorRetainer] =
    useState(false);
  const [pauseModalStep, setPauseModalStep] = useState(1);
  const [monthlyRetainerAmount, setMonthlyRetainerAmount] = useState(0);
  const [dspAmount, setDspAmount] = useState({
    startAmount: null,
    endAmount: null,
  });
  const [
    selectedPendingPauseRequestOption,
    setSelectedPendingPauseRequestOption,
  ] = useState(null);
  const retainerDates = [29, 30, 31, 1];
  const nullDateObj = useMemo(() => {
    return {
      fromDate: null,
      toDate: null,
    };
  }, []);
  const initialPasueDate = useMemo(() => {
    return {
      monthlyRetainer: nullDateObj,
      revshare: nullDateObj,
      dsp: nullDateObj,
      amcService: nullDateObj,
    };
  }, [nullDateObj]);
  const [pauseDateDetails, setPauseDateDetails] = useState(initialPasueDate);
  const [pendingPauseRequestData, setPendingPauseRequestData] = useState([]);

  // use effect for modal content height
  useEffect(() => {
    // get updated modal content height
    const updateContentHeight = () => {
      if (modalContentRef.current) {
        setModalContentHeight(modalContentRef.current.clientHeight);
      }
    };

    // Set initial height
    updateContentHeight();

    // Create a new MutationObserver instance and set up a mutation observer to update the height when content changes
    const observer = new MutationObserver((mutationsList) => {
      // This callback runs whenever a mutation is observed
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'subtree') {
          // Update the height when children or subtree are modified
          updateContentHeight();
        }
      }
    });

    // set childList and subtree as true in observer to observe the mutation
    if (modalContentRef.current) {
      observer.observe(modalContentRef.current, {
        childList: true,
        subtree: true,
      });
    }

    // Cleanup observer on component unmount
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [modalContentRef]);

  // function - get pending agreement data
  const getPendingPauseRequestData = useCallback((suspendContractId) => {
    setLoader({ page: true });
    getCancellationBillingData(suspendContractId).then((response) => {
      if (response?.status === 200) {
        const tempInvoiceData = [];
        const invoiceData = response?.data?.invoice_type;

        if (invoiceData?.length) {
          for (const item of invoiceData) {
            const invoiceObj = invoiceConstants?.find(
              (obj) => obj?.invoiceType === item?.invoice_type,
            );

            tempInvoiceData.push({
              fromDate: item?.start_date ? new Date(item?.start_date) : null,
              toDate: item?.end_date ? new Date(item?.end_date) : null,
              invoiceType: item?.invoice_type,
              isPause: item?.is_pause,
              invoiceLabel: invoiceObj?.invoiceLabel,
              invoiceKey: invoiceObj?.invoiceKey,
              infoId: invoiceObj?.infoId,
            });
          }
        }

        setLoader({ page: false });
        setPendingPauseRequestData(tempInvoiceData);
      } else {
        setPendingPauseRequestData([]);
        setLoader({ page: false });
      }
    });
  }, []);

  // function - bind invoice api data of agreement
  const bindInvoiceDataOfAgreement = useCallback(
    (fromDate, toDate, dataItem) => {
      const invoiceObj = {
        fromDate,
        toDate,
        invoiceAmount: dataItem?.invoice_amount,
        currency: dataItem?.currency || '$',
        // isFromDateDisabled:
        //   dataItem?.start_date !== null && dataItem?.end_date !== null,
        isToDateDisabled:
          toDate !== null && dayjs(toDate).diff(new Date(), 'day') < 0,
        id: dataItem?.id || '',
      };

      return invoiceObj;
    },
    [],
  );

  // function - bind pause agreement api data
  const bindAgreementDataResponse = useCallback(
    (resData) => {
      let dummyAgreementData = {};
      const invoiceTypes = resData?.invoice_type;
      const contractStartDate = resData?.contract_start_date;
      let setDefaultDate = { ...initialPasueDate };
      dummyAgreementData = {
        ...dummyAgreementData,
        suspendContractId: resData?.suspend_contract_id,
      };

      for (const item of invoiceTypes) {
        const fromDate = item?.start_date
          ? new Date(item?.start_date)
          : new Date();
        const toDate = item?.end_date ? new Date(item?.end_date) : null;
        const dateObj = {
          fromDate: fromDate || null,
          toDate: toDate || null,
        };

        if (item?.invoice_type === 'retainer') {
          if (item?.start_date === null && contractStartDate !== null) {
            const tempContractStartDate = new Date(contractStartDate);
            if (new Date(contractStartDate).getDate() === 1) {
              tempContractStartDate.setDate(2);
            } else if (new Date(contractStartDate).getDate() > 28) {
              tempContractStartDate.setDate(28);
            }

            fromDate.setDate(tempContractStartDate.getDate());
          }

          dummyAgreementData = {
            ...dummyAgreementData,
            monthlyRetainer: bindInvoiceDataOfAgreement(fromDate, toDate, item),
          };

          if (item?.invoice_amount !== null) {
            setMonthlyRetainerAmount(item?.invoice_amount);
          } else {
            setMonthlyRetainerAmount(0);
          }

          if (item?.start_date !== null && item?.end_date !== null) {
            setDefaultDate = {
              ...setDefaultDate,
              monthlyRetainer: dateObj,
            };
          }
        }

        if (item?.invoice_type === 'rev share') {
          if (toDate !== null) toDate.setDate(9);
          dummyAgreementData = {
            ...dummyAgreementData,
            revShare: bindInvoiceDataOfAgreement(fromDate, toDate, item),
          };

          if (item?.start_date !== null && item?.end_date !== null) {
            setDefaultDate = {
              ...setDefaultDate,
              revShare: dateObj,
            };
          }
        }

        if (item?.invoice_type === 'dsp service') {
          if (toDate !== null) toDate.setDate(11);
          dummyAgreementData = {
            ...dummyAgreementData,
            dsp: bindInvoiceDataOfAgreement(fromDate, toDate, item),
          };

          if (item?.start_date !== null && item?.end_date !== null) {
            setDefaultDate = {
              ...setDefaultDate,
              dsp: dateObj,
            };
          }
        }

        // PDV-8973- AMC service type added in pause modal
        if (item?.invoice_type === 'amc service') {
          // after testing change 'retianer' value. set as per api
          if (item?.start_date === null && contractStartDate !== null) {
            const tempContractStartDate = new Date(contractStartDate);
            if (new Date(contractStartDate).getDate() === 1) {
              tempContractStartDate.setDate(2);
            } else if (new Date(contractStartDate).getDate() > 28) {
              tempContractStartDate.setDate(28);
            }

            fromDate.setDate(tempContractStartDate.getDate());
          }

          dummyAgreementData = {
            ...dummyAgreementData,
            amcService: bindInvoiceDataOfAgreement(fromDate, toDate, item),
          };

          if (item?.start_date !== null && item?.end_date !== null) {
            setDefaultDate = {
              ...setDefaultDate,
              amcService: dateObj,
            };
          }
        }
      }

      if (resData?.request_status === 'pending') {
        setIsRequestPending(true);
        if (isBgs) {
          setShowConfirmationModal(true);
        } else {
          getPendingPauseRequestData(resData?.suspend_contract_id);
          setShowPendingPauseRequestModal(true);
        }
      }

      if (resData?.request_status === 'approved') {
        setIsAlreadyPaused(true);
        setPauseDateDetails(setDefaultDate);
      }

      return dummyAgreementData;
    },
    [
      bindInvoiceDataOfAgreement,
      getPendingPauseRequestData,
      initialPasueDate,
      isBgs,
    ],
  );

  // function - get pause agreement api data
  const getPauseAgreementData = useCallback(
    (id) => {
      setLoader({ page: true });
      getAgreementData(id, 'pause').then((res) => {
        if (mounted.current) {
          if (res && res.status === 400) {
            setLoader({ page: false });
          }
          if (res && res.status === 200) {
            if (res?.data?.invoice_type && res?.data?.invoice_type.length) {
              const resData = bindAgreementDataResponse(res?.data);
              setAgreementData(resData);
            } else {
              setAgreementData([]);
            }
          }

          setLoader({ page: false });
        }
      });
    },
    [bindAgreementDataResponse],
  );

  useEffect(() => {
    mounted.current = true;
    getPauseAgreementData(agreementId);
    return () => {
      mounted.current = false;
    };
  }, [agreementId, getPauseAgreementData]);

  // function - det dsp amount
  const getDspAmounts = useCallback((id, startDate, endDate) => {
    setLoader({ page: true });
    getPauseAgreementDSPAmounts(id, startDate, endDate).then((res) => {
      if (mounted.current) {
        if (res && res.status === 400) {
          setLoader({ page: false });
        }
        if (res && res.status === 200) {
          setDspAmount({
            startAmount: res.data.start_date_amount,
            endAmount: res.data.end_date_amount,
          });
        }

        setLoader({ page: false });
      }
    });
  }, []);

  // function - agreement pause request
  const agreementPauseRequest = useCallback(
    (id, data) => {
      setLoader({ ...loader, button: true });
      agreementCancelPauseRequest(id, data, null, 'pause').then((res) => {
        if (mounted.current) {
          if (res && res.status === 400) {
            setLoader({ ...loader, button: false });
          }
          if (res && res.status === 201) {
            saveDetails();
          }
          setLoader({ ...loader, button: false });
        }
      });
    },
    [loader, saveDetails],
  );

  // https://bbe.atlassian.net/browse/PDV-9280 - Billing Pausing Improvement Logic
  // submit pending pause request
  const submitPendingPauseRequest = (status) => {
    setLoader({ ...loader, button: true });

    updateBillingOffBoardingApproveReject(
      status,
      agreementData?.suspendContractId,
    ).then((response) => {
      if (response?.status === 200) {
        saveDetails(status);
      } else if (response?.status === 403) {
        toast.error(response?.data?.detail);
        setShowModal(false);
      } else {
        toast.error('Oops... Something went wrong!');
        setShowModal(false);
      }
      setLoader({ ...loader, button: false });
    });
  };

  // function - bind pause request final invoice data
  const bindFinalInvoiceData = (invoiceType, invoiceKey) => {
    const invoiceObj = {
      invoice_type: invoiceType,
      start_date: dayjs(pauseDateDetails?.[invoiceKey]?.fromDate).format(
        'YYYY-MM-DD',
      ),
      end_date: dayjs(pauseDateDetails?.[invoiceKey]?.toDate).format(
        'YYYY-MM-DD',
      ),
    };
    let finalInvoiceObj = { ...invoiceObj };

    if (invoiceKey === 'monthlyRetainer') {
      finalInvoiceObj = {
        ...invoiceObj,
        invoice_amount: monthlyRetainerAmount,
      };
    }
    if (invoiceKey === 'amcService') {
      finalInvoiceObj = {
        ...invoiceObj,
        invoice_amount: agreementData?.amcService?.invoiceAmount,
      };
    }

    return finalInvoiceObj;
  };

  // function - bind agreement pause request data
  const bindPauseRequest = () => {
    const retainer = pauseDateDetails?.monthlyRetainer;
    const revShare = pauseDateDetails?.revShare;
    const dsp = pauseDateDetails?.dsp;
    const amcService = pauseDateDetails?.amcService;
    // if curreent screen is 1 - dates screen, then go to screen 2 - amount screen and get the dsp amount
    if (pauseModalStep === 1) {
      setPauseModalStep(2);
      if (dsp?.fromDate && dsp?.toDate) {
        getDspAmounts(
          agreementId,
          dayjs(pauseDateDetails?.dsp?.fromDate).format('YYYY-MM-DD'),
          dayjs(pauseDateDetails?.dsp?.toDate).format('YYYY-MM-DD'),
        );
      }
      return;
    }
    // if curreent screen 2 - amount screen, then bind the final pause agreement invoice data and submit
    const finalRequestData = [];
    if (retainer?.fromDate && retainer?.toDate) {
      finalRequestData.push(
        bindFinalInvoiceData('retainer', 'monthlyRetainer'),
      );
    }
    if (revShare?.fromDate && revShare?.toDate) {
      finalRequestData.push(bindFinalInvoiceData('rev share', 'revShare'));
    }
    if (dsp?.fromDate && dsp?.toDate) {
      finalRequestData.push(bindFinalInvoiceData('dsp service', 'dsp'));
    }
    if (amcService?.fromDate && amcService?.toDate) {
      finalRequestData.push(bindFinalInvoiceData('amc service', 'amcService'));
    }

    agreementPauseRequest(agreementId, finalRequestData);
  };

  // function that return Min_date for all date picker.
  const setMinDate = (invoiceType, dateType) => {
    const agreement = agreementData[invoiceType];
    const selectedFromDate = pauseDateDetails[invoiceType]?.fromDate;

    // set min date for Monthly retainer
    if (invoiceType === 'monthlyRetainer') {
      // TO_DATE should be greater that 2 month from FROM_DATE - PDV-8441
      if (dateType === 'toDate' && selectedFromDate !== null) {
        return new Date(selectedFromDate).setMonth(
          getMonth(selectedFromDate) + 2,
        );
      }

      // dateType is fromDate OR (dateType is toDate && fromDate is null)
      // condition trigger- when last_invoice_date is greater than todays date
      if (new Date(agreement[dateType]).getDate() >= new Date().getDate()) {
        const tempDate = new Date();
        tempDate.setDate(getDate(agreement[dateType]));
        tempDate.setMonth(new Date().getMonth() - 1);
        return tempDate;
      }

      // return current month
      return new Date(agreement[dateType]).setMonth(getMonth(new Date()));
    }

    // set min date for rev share
    if (invoiceType === 'revShare') {
      // TO_DATE should be greater that 2 month from FROM_DATE - PDV-8441
      if (dateType === 'toDate' && selectedFromDate !== null) {
        return new Date(selectedFromDate).setMonth(
          getMonth(selectedFromDate) + 2,
        );
      }

      // dateType is fromDate OR (dateType is toDate && fromDate is null)
      // condition trigger- todays date is between 1-8, then return 9th of previous month
      if (new Date().getDate() < 9) {
        const tempDate = new Date();
        tempDate.setDate(getDate(9));
        tempDate.setMonth(new Date().getMonth() - 1);
        return tempDate;
      }

      // else - todays date is greater than =9, return 9th of current month
      const tempDate = new Date();
      tempDate.setDate(9);
      tempDate.setMonth(new Date().getMonth());
      return tempDate;
    }

    // set min date for DSP
    if (invoiceType === 'dsp') {
      // TO_DATE should be greater that 2 month from FROM_DATE - - PDV-8441
      if (dateType === 'toDate' && selectedFromDate !== null) {
        return new Date(selectedFromDate).setMonth(
          getMonth(selectedFromDate) + 2,
        );
      }

      // dateType is fromDate OR dateType is toDate && fromDate is null
      // condition trigger- todays date is between 1-10, then return 11th of previous month
      if (new Date().getDate() < 11) {
        const tempDate = new Date();
        tempDate.setDate(getDate(11));
        tempDate.setMonth(new Date().getMonth() - 1);
        return tempDate;
      }

      // else - todays date is greater than =11th, return 11th of current month
      const tempDate = new Date();
      tempDate.setDate(11);
      tempDate.setMonth(new Date().getMonth());
      return tempDate;
    }

    // set min date for AMC
    if (invoiceType === 'amcService') {
      if (dateType === 'toDate' && selectedFromDate !== null) {
        return new Date(selectedFromDate).setMonth(
          getMonth(selectedFromDate) + 2,
        );
      }

      // dateType is fromDate OR (dateType is toDate && fromDate is null)
      // condition trigger- when last_invoice_date is greater than todays date
      if (new Date(agreement[dateType]).getDate() >= new Date().getDate()) {
        const tempDate = new Date();
        tempDate.setDate(getDate(agreement[dateType]));
        tempDate.setMonth(new Date().getMonth() - 1);
        return tempDate;
      }

      // return current month
      return new Date(agreement[dateType]).setMonth(getMonth(new Date()));
    }

    return new Date();
  };

  // function - get retainer ans amc invoice day
  const filterRetainerAndAmcBillingDate = (date, invoiceKey) => {
    const day = getDate(date);
    const fromDate = agreementData?.[invoiceKey]?.fromDate.getDate();
    if (retainerDates?.includes(fromDate)) return day === 2;
    return day === fromDate;
  };

  // function - get rev share invoice day
  const filterRevShareBillingDate = (date) => {
    const day = getDate(date);
    return day === 9;
  };

  // function - get dsp invoice day
  const filterDSPBillingDate = (date) => {
    const day = getDate(date);
    return day === 11;
  };

  // function - check submit button validation
  const checkButtonValidation = () => {
    if (pauseModalStep === 1) {
      if (
        (agreementData?.monthlyRetainer &&
          pauseDateDetails?.monthlyRetainer?.fromDate !== null &&
          pauseDateDetails?.monthlyRetainer?.toDate !== null) ||
        (agreementData?.revShare &&
          pauseDateDetails?.revShare?.fromDate !== null &&
          pauseDateDetails?.revShare?.toDate !== null) ||
        (agreementData?.dsp &&
          pauseDateDetails?.dsp?.fromDate !== null &&
          agreementData?.dsp &&
          pauseDateDetails?.dsp.toDate !== null) ||
        (agreementData?.amcService &&
          pauseDateDetails?.amcService?.fromDate !== null &&
          pauseDateDetails?.amcService?.toDate !== null)
      ) {
        return false;
      }

      return true;
    }

    // pause modal step 2
    if (
      !bgsManagerCheck ||
      largerAmountErrorRetainer ||
      monthlyRetainerAmount === undefined ||
      monthlyRetainerAmount === ''
    ) {
      return true;
    }
    return false;
  };

  // function - handle date change events
  const handleDateChange = (date, invoiceType, dateType) => {
    const selectedDate = new Date(date);
    const selectedToDate = pauseDateDetails[invoiceType]?.toDate;

    if (invoiceType === 'revShare') {
      selectedDate.setDate(9);
    } else if (invoiceType === 'dsp') {
      selectedDate.setDate(11);
    } else if (invoiceType === 'amcService') {
      // only set fromDate, because for toDate we can select any date not billed date.
      if (dateType === 'fromDate') {
        const agreement = agreementData?.amcService;
        const lastBillDate = new Date(agreement?.[dateType]);
        selectedDate.setDate(
          invoiceType === 'amcService' &&
            [(29, 30, 31, 1)].includes(lastBillDate.getDate())
            ? 2
            : lastBillDate.getDate(),
        );
      }
    } else {
      // else - retainer invoice type
      if (dateType === 'fromDate' && date < new Date()) {
        setIsDisabledRetainerEditOption(true);
        setMonthlyRetainerAmount(
          agreementData?.monthlyRetainer?.invoiceAmount || 0,
        );
        setIsAllowToEditRetainer(false);
        setLargerAmountErrorRetainer(false);
      } else if (dateType === 'fromDate') {
        setIsDisabledRetainerEditOption(false);
      }

      // only set fromDate, because for toDate we can select any date not billed date.
      if (dateType === 'fromDate') {
        const agreement = agreementData?.monthlyRetainer;
        const lastBillDate = new Date(agreement?.[dateType]);
        selectedDate.setDate(
          invoiceType === 'monthlyRetainer' &&
            [(29, 30, 31, 1)].includes(lastBillDate.getDate())
            ? 2
            : lastBillDate.getDate(),
        );
      }
    }

    // check to_date is greater than from_date
    if (
      dateType === 'fromDate' &&
      selectedToDate !== null &&
      dayjs(selectedToDate).diff(selectedDate, 'month') < 2
    ) {
      setPauseDateDetails({
        ...pauseDateDetails,
        [invoiceType]: {
          ...pauseDateDetails[invoiceType],
          toDate: null,
          fromDate: selectedDate,
        },
      });
    } else {
      setPauseDateDetails({
        ...pauseDateDetails,
        [invoiceType]: {
          ...pauseDateDetails[invoiceType],
          [dateType]: selectedDate,
        },
      });
    }
  };

  // function - handle invoice amount change
  const handleAmountChange = (event) => {
    event.preventDefault();
    const actualAmount = agreementData?.monthlyRetainer?.invoiceAmount;
    let { value } = event.target;
    value = value.replace(agreementData?.monthlyRetainer?.currency, '');
    value = value.replaceAll(',', '');
    setMonthlyRetainerAmount(value);
    if (value > actualAmount) {
      setLargerAmountErrorRetainer(true);
    } else {
      setLargerAmountErrorRetainer(false);
    }
  };

  // display discard button
  const displayDiscardButton = () => {
    return (
      <Button
        onClick={() => {
          setShowModal(false);
        }}
        type="button"
        className="btn-transparent w-50 on-boarding"
      >
        Discard
      </Button>
    );
  };

  // function - display bgs manager modal screen
  const renderBGSManagerModal = () => {
    return (
      <div className="modal-body pb-1">
        <div className="uppercase-text mb-3">Add BGS Manager</div>

        <p className="long-text mb-2 text-center ">
          For approval please add the{' '}
          <span
            className="cursor"
            style={{ color: Theme.orange }}
            onClick={() => addBGSMangerEmail()}
            role="presentation"
          >
            BGS Manager
          </span>{' '}
          first.
        </p>
      </div>
    );
  };

  // get tooltip DataTip
  const getTooltipDataTip = (tooltipMessage) => {
    return `<div style="max-width:300px">${tooltipMessage}</div>`;
  };

  // display react tooltip with info icon
  const displayTooltipWithInfoIcon = (dataTip, tooltipId) => {
    return (
      <>
        <img
          width="12px"
          src={InfoIcons}
          className="ml-1 cursor"
          alt={tooltipId}
          style={{
            verticalAlign: 'middle',
            fontWeight: '300',
          }}
          data-tip={getTooltipDataTip(dataTip)}
          data-for={tooltipId}
        />
        <ReactTooltip
          className="cancel-agr-tooltip"
          id={tooltipId}
          aria-haspopup="true"
          place="bottom"
          effect="solid"
          insecure
          html
        />
      </>
    );
  };

  // display invoice Dates header html
  const displayInvoiceDatesHeaderHTML = () => {
    return (
      <>
        <div className="col-6">
          <div className="label" htmlFor="amount">
            invoice type
          </div>
        </div>

        <div className="col-3 pl-0 text-left">
          <div className="label" htmlFor="amount text-left">
            From
            {displayTooltipWithInfoIcon(
              invoiceTooltipMessages?.fromDate,
              'fromDateInfo',
            )}
          </div>
        </div>

        <div className="col-3 pl-0 text-left">
          <div className="label" htmlFor="amount text-right">
            To
            {displayTooltipWithInfoIcon(
              invoiceTooltipMessages?.toDate,
              'toDateInfo',
            )}
          </div>
        </div>
      </>
    );
  };

  // display invoice label with tooltip
  const displayInvoiceLabelWithTooltip = (
    label,
    invoiceKey,
    tooltipId,
    showPastDateMessage,
  ) => {
    return (
      <div
        className={`text-bold normal-text-black ${
          showPastDateMessage ? '' : 'mt-3'
        }`}
      >
        <label htmlFor="amount">
          {label}
          {displayTooltipWithInfoIcon(
            getTooltipDataTip(invoiceTooltipMessages?.[invoiceKey]),
            tooltipId,
          )}
          {showPastDateMessage &&
          pauseDateDetails?.[invoiceKey]?.fromDate !== null &&
          pauseDateDetails?.[invoiceKey]?.fromDate <= new Date() ? (
            <>
              <br />
              <small className="my-3 text-regular" style={{ color: 'red' }}>
                {pauseAgreementStaticMesssages?.[invoiceKey]}
              </small>
            </>
          ) : null}
        </label>
      </div>
    );
  };

  // display invoice type with paused dates
  const displayInvoiceTypeWithPausedDates = (
    label,
    invoiceKey,
    fromDate,
    toDate,
    tooltipId,
  ) => {
    return (
      <>
        <div className="row">
          <div className="col-6">
            {displayInvoiceLabelWithTooltip(
              label,
              invoiceKey,
              tooltipId,
              false,
            )}
          </div>
          <div className="col-3 pl-0 text-left">
            <div className="label">
              <div className="text-medium-font gray-box">
                {fromDate ? dayjs(fromDate).format('MM/DD/YYYY') : 'MM/DD/YYYY'}
              </div>
            </div>
          </div>
          <div className="col-3 pl-0 text-left">
            <div className="label">
              <div className="text-medium-font gray-box">
                {toDate ? dayjs(toDate).format('MM/DD/YYYY') : 'MM/DD/YYYY'}
              </div>
            </div>
          </div>
        </div>
        <div className="horizontal-line straight-line my-3" />
      </>
    );
  };

  // display paused request option
  const displayPauseRequestOptions = () => {
    return pendingPauseRequestOptions?.map((item) => {
      return (
        <ModalRadioCheck className="mt-3 mb-3 mr-3" key={item?.value}>
          <label className="checkboxes radio-container" htmlFor={item?.value}>
            {item?.label}
            <input
              type="radio"
              name={item?.label}
              id={item?.value}
              label={item?.label}
              value={item?.value}
              checked={item?.value === selectedPendingPauseRequestOption}
              onChange={() => setSelectedPendingPauseRequestOption(item?.value)}
            />
            <span className="checkmark checkmark-customer-list" />
          </label>
        </ModalRadioCheck>
      );
    });
  };

  // display confirmation modal screen
  const displayConfirmationScreen = () => {
    return (
      <>
        <div className="modal-body pb-1">
          <div className="uppercase-text">Confirmation</div>
          <div className="horizontal-line straight-line my-3" />
          <p className="long-text text-center">
            {`There is already a ${
              isRequestPending ? 'pending' : 'approved'
            } pause request for an agreement. Would
            you like to proceed with canceling the old request and submitting a
            new one?`}
          </p>
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              onClick={() => {
                if (isBgs && isRequestPending) {
                  setShowConfirmationModal(false);
                } else {
                  bindPauseRequest();
                }
              }}
              type="button"
              className="btn-primary on-boarding  mr-2 pb-2 mb-1"
            >
              {loader.button ? (
                <PageLoader color={Theme.white} type="button" />
              ) : (
                'Proceed'
              )}
            </Button>

            {displayDiscardButton()}
          </div>
        </div>
      </>
    );
  };

  // display pending pause request modal
  const renderPendingRequestModal = () => {
    return (
      <>
        <div className="modal-body pb-1">
          <div className="uppercase-text">Confirmation</div>
          <div className="horizontal-line straight-line my-3" />

          <div className="row">{displayInvoiceDatesHeaderHTML()}</div>
          <div className="horizontal-line straight-line mt-2 mb-3" />
          {loader.page ? (
            <div className="mt-5">
              <PageLoader
                component="modal"
                type="page"
                width={40}
                height={40}
                color="#FF5933"
              />
            </div>
          ) : pendingPauseRequestData?.length ? (
            <>
              {pendingPauseRequestData?.map((item) => {
                return displayInvoiceTypeWithPausedDates(
                  item?.invoiceLabel,
                  item?.invoiceKey,
                  item?.fromDate,
                  item?.toDate,
                  item?.infoId,
                );
              })}
              <p className="long-text">
                There is already a pending pause request for an agreement. Would
                you like to proceed with:
              </p>
              {displayPauseRequestOptions()}
              <div>
                <small className="my-3 text-regular" style={{ color: 'red' }}>
                  <b>Note:</b> {pauseRequestNote}
                </small>
              </div>
            </>
          ) : null}
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              onClick={() => {
                if (selectedPendingPauseRequestOption === 'cancelled') {
                  setShowPendingPauseRequestModal(false);
                } else {
                  submitPendingPauseRequest(selectedPendingPauseRequestOption);
                }
              }}
              type="button"
              className={`btn-primary on-boarding  mr-2 pb-2 mb-1 ${
                selectedPendingPauseRequestOption ? '' : 'disabled'
              }`}
            >
              {loader.button ? (
                <PageLoader color={Theme.white} type="button" />
              ) : (
                'Proceed'
              )}
            </Button>
            {displayDiscardButton()}
          </div>
        </div>
      </>
    );
  };

  // get date picker for invoice
  const getDatePickerforInvoices = (
    invoiceKey,
    dateType,
    invoiceType,
    filterDate,
  ) => {
    return (
      <DatePicker
        selected={pauseDateDetails?.[invoiceKey]?.[dateType]}
        onChange={(date) => handleDateChange(date, invoiceKey, dateType)}
        filterDate={(date) => filterDate(date, invoiceKey)}
        minDate={setMinDate(invoiceKey, dateType)}
        dateFormat="MM/dd/yyyy"
        previousMonthAriaLabel={invoiceType}
        placeholderText="MM/DD/YY"
      />
    );
  };

  // display input date picker dropdown for invoices
  const displayInputDatePickerDropdownForInvoice = (
    invoiceKey,
    filterDate,
    invoiceType,
  ) => {
    return (
      <>
        <div className="col-md-3 col-3 pl-0 text-left">
          <InputDateDropDown
            className={
              agreementData?.[invoiceKey]?.isFromDateDisabled ? 'disabled' : ''
            }
          >
            {getDatePickerforInvoices(
              invoiceKey,
              'fromDate',
              invoiceType,
              filterDate,
            )}
          </InputDateDropDown>
        </div>
        <div className="col-md-3 col-3 pl-0 text-right">
          <InputDateDropDown
            className={
              agreementData?.[invoiceKey]?.isToDateDisabled ? 'disabled' : ''
            }
          >
            {getDatePickerforInvoices(
              invoiceKey,
              'toDate',
              invoiceType,
              filterDate,
            )}
          </InputDateDropDown>
        </div>
        <div className="horizontal-line straight-line mb-3 mt-3" />
      </>
    );
  };

  // step 1 - pause agreement modal - dates screen
  const renderAgreementDatesModal = () => {
    // get html of InvoiceLabelWithTooltip
    const getInvoiceLabelWithTooltipHTML = (invoiceKey, filterDate) => {
      const invoiceObj = invoiceConstants?.find(
        (obj) => obj?.invoiceKey === invoiceKey,
      );

      return (
        <>
          <div className="col-md-6 col-6 text-left">
            {displayInvoiceLabelWithTooltip(
              invoiceObj?.invoiceLabel,
              invoiceObj?.invoiceKey,
              invoiceObj?.infoId,
              true,
            )}
          </div>
          {displayInputDatePickerDropdownForInvoice(
            invoiceObj?.invoiceKey,
            filterDate,
            invoiceObj?.invoiceType,
          )}
        </>
      );
    };
    // function - html for monthly retainer dates
    const renderMonthlyRetainerDateDetails = () => {
      return agreementData?.monthlyRetainer
        ? getInvoiceLabelWithTooltipHTML(
            'monthlyRetainer',
            filterRetainerAndAmcBillingDate,
          )
        : null;
    };

    // function - html for rev share dates
    const renderRavShareDateDetails = () => {
      return agreementData?.revShare
        ? getInvoiceLabelWithTooltipHTML('revShare', filterRevShareBillingDate)
        : null;
    };

    // function - html for dsp dates
    const renderDSPDateDetails = () => {
      return agreementData?.dsp ? (
        <>{getInvoiceLabelWithTooltipHTML('dsp', filterDSPBillingDate)}</>
      ) : null;
    };

    // function - html for amc dates
    const renderAmcServiceDateDetails = () => {
      return agreementData?.amcService
        ? getInvoiceLabelWithTooltipHTML(
            'amcService',
            filterRetainerAndAmcBillingDate,
          )
        : null;
    };

    return (
      <div className="row align-items-center">
        {displayInvoiceDatesHeaderHTML()}
        <div className="horizontal-line straight-line mb-3 mt-2" />
        {renderMonthlyRetainerDateDetails()}
        {renderRavShareDateDetails()}
        {renderDSPDateDetails()}
        {renderAmcServiceDateDetails()}
      </div>
    );
  };

  // step 2 - pause agreement modal - amount screen
  const renderAgreementAmountsModal = (formatNumber) => {
    // display invoive amount
    const displayInvoiveAmount = (invoiceKey) => {
      if (invoiceKey === 'revShare') {
        return (
          <div className="col-md-4 col-4 pl-0 text-left">
            <div className="normal-text-black">Dependent on Sales </div>
          </div>
        );
      }
      return (
        <div className="col-md-4 col-4 pl-0 text-left">
          <div className="normal-text-black">
            {agreementData?.[invoiceKey]?.invoiceAmount !== null
              ? formatNumber(
                  agreementData?.[invoiceKey]?.invoiceAmount,
                  agreementData?.[invoiceKey]?.currency,
                )
              : '-'}
          </div>
        </div>
      );
    };

    // function - html for amount header
    const renderAmountHeader = () => {
      return (
        <>
          <div className="col-4">
            <div className="label" htmlFor="amount">
              invoice type{' '}
            </div>
          </div>

          <div className="col-4 pl-0 text-left ">
            <div className="label" htmlFor="amount text-left">
              Last Bill Before Pause
            </div>
          </div>

          <div className="col-4 pl-0 text-left ">
            <div className="label" htmlFor="amount text-right">
              First Bill After Pause
            </div>
          </div>
        </>
      );
    };

    // display icons for invoice
    const displayIconForInvoice = (isDisabled, iconLink, type) => {
      return (
        <img
          className={`external-edit ${isDisabled ? 'disabled ' : ''}`}
          role="presentation"
          onClick={() => setIsAllowToEditRetainer(type === 'edit')}
          src={iconLink}
          alt={type}
          style={{ marginLeft: '75px' }}
        />
      );
    };

    // function - html for monthly retainer amount details
    const renderMonthlyRetainerAmountDetails = () => {
      return (
        <>
          {agreementData?.monthlyRetainer &&
          pauseDateDetails?.monthlyRetainer?.fromDate !== null &&
          pauseDateDetails?.monthlyRetainer?.toDate !== null ? (
            <>
              <div className="col-md-4 col-4 pr-0 text-left">
                {displayInvoiceLabelWithTooltip(
                  'Monthly Retainer',
                  'monthlyRetainer',
                  'retainerInfo',
                  true,
                )}
              </div>

              <div className="col-md-4 col-4 pl-0 text-left">
                <InputFormField>
                  <div
                    className={`input-date-container ${
                      !isAllowToEditRetainer ? 'disabled' : ''
                    }`}
                  >
                    <NumberFormat
                      name="amount"
                      className="form-control modal-input-control  borderless-input"
                      prefix={agreementData?.monthlyRetainer?.currency}
                      placeholder="Enter Value"
                      onChange={(event) => handleAmountChange(event)}
                      value={monthlyRetainerAmount}
                      defaultValue={
                        agreementData?.monthlyRetainer?.invoiceAmount
                      }
                      thousandSeparator
                      allowNegative={false}
                    />
                  </div>
                  <EditInput>
                    {!isAllowToEditRetainer
                      ? displayIconForInvoice(
                          isDisabledRetainerEditOption,
                          EditExternalLinkOrange,
                          'edit',
                        )
                      : displayIconForInvoice(
                          largerAmountErrorRetainer,
                          OrangeCheckMark,
                          'check',
                        )}
                  </EditInput>
                </InputFormField>
              </div>

              {displayInvoiveAmount('monthlyRetainer')}
              {largerAmountErrorRetainer ? (
                <div className="col-12">
                  <ErrorMsg>{errorMessages.retainerAmount}</ErrorMsg>
                </div>
              ) : null}
              <div className="horizontal-line straight-line mb-3 mt-3" />
            </>
          ) : null}
        </>
      );
    };

    // function - html for rev share amount details
    const renderRavShareAmountDetails = () => {
      return (
        <>
          {agreementData?.revShare &&
          pauseDateDetails?.revShare?.fromDate !== null &&
          pauseDateDetails?.revShare?.toDate !== null ? (
            <>
              <div className="col-md-4 col-4 text-left">
                {displayInvoiceLabelWithTooltip(
                  'Rev Share',
                  'revShare',
                  'revShareInfo',
                  true,
                )}
              </div>
              {displayInvoiveAmount('revShare')}
              {displayInvoiveAmount('revShare')}
              <div className="horizontal-line straight-line mb-3 mt-3" />
            </>
          ) : null}
        </>
      );
    };

    // function - html for dsp service amount details
    const renderDSPAmountDetails = () => {
      return (
        <>
          {agreementData?.dsp &&
          pauseDateDetails?.dsp?.fromDate !== null &&
          pauseDateDetails?.dsp?.toDate !== null ? (
            <>
              <div className="col-md-4 col-4 text-left">
                {displayInvoiceLabelWithTooltip('DSP', 'dsp', 'dspInfo', true)}
              </div>

              <div className="col-md-4 col-4 pl-0 text-left">
                <div className="normal-text-black">
                  {dspAmount?.startAmount !== null
                    ? formatNumber(
                        dspAmount?.startAmount,
                        agreementData?.dsp?.currency,
                      )
                    : '-'}
                </div>
              </div>

              <div className="col-md-4 col-4 pl-0 text-left">
                <div className="normal-text-black">
                  {dspAmount?.endAmount !== null
                    ? formatNumber(
                        dspAmount?.endAmount,
                        agreementData?.dsp?.currency,
                      )
                    : '-'}
                </div>
              </div>

              <div className="horizontal-line straight-line mb-3 mt-3 " />
            </>
          ) : null}
        </>
      );
    };

    // function - html for amc service amount details
    const renderAmcServiceAmountDetails = () => {
      return (
        <>
          {agreementData?.amcService &&
          pauseDateDetails?.amcService?.fromDate !== null &&
          pauseDateDetails?.amcService?.toDate !== null ? (
            <>
              <div className="col-md-4 col-4 pr-0 text-left">
                {displayInvoiceLabelWithTooltip(
                  'AMC',
                  'amcService',
                  'amcInfo',
                  true,
                )}
              </div>
              {displayInvoiveAmount('amcService')}
              {displayInvoiveAmount('amcService')}
              <div className="horizontal-line straight-line mb-3 mt-3" />
            </>
          ) : null}
        </>
      );
    };

    return (
      <div className="row align-items-center">
        {renderAmountHeader()}
        <div className="horizontal-line straight-line mb-3 mt-2" />
        {renderMonthlyRetainerAmountDetails()}
        {renderRavShareAmountDetails()}
        {renderDSPAmountDetails()}
        {renderAmcServiceAmountDetails()}
      </div>
    );
  };

  // function modal footer
  const renderFooter = () => {
    return (
      <div className="modal-footer">
        {!isBgsManager &&
        userInfo?.role !== 'BGS Admin' &&
        pauseModalStep === 2 ? (
          <CheckBox className="mb-4">
            <label
              className="check-container customer-pannel"
              style={{ width: 'fit-content' }}
              htmlFor="checkbgsManager"
            >
              Request approval from BGS Manager
              <input
                type="checkbox"
                id="checkbgsManager"
                name="checkbgsManager"
                checked={bgsManagerCheck}
                onChange={() => {
                  setBgsManagerCheck(!bgsManagerCheck);
                }}
              />
              <span className="checkmark" />
            </label>
          </CheckBox>
        ) : null}

        <div className="text-center">
          <Button
            onClick={() => {
              if (pauseModalStep === 2 && isAlreadyPaused) {
                setShowConfirmationModal(true);
              } else {
                bindPauseRequest();
              }
            }}
            type="button"
            className={`btn-primary on-boarding w-100 ${
              checkButtonValidation() || loader.page ? 'disabled' : ''
            }`}
          >
            {loader.button ? (
              <PageLoader color={Theme.white} type="button" />
            ) : pauseModalStep === 1 ? (
              'Next'
            ) : (
              'Confirm and Pause Agreement'
            )}
          </Button>
        </div>
      </div>
    );
  };

  // function - html for approved request modal
  const renderApprovedRequestModal = () => {
    return (
      <>
        <div className="modal-body pb-1">
          <div
            className={modalContentHeight > 400 ? 'addendum-service-modal' : ''}
            ref={modalContentRef}
          >
            <div className="uppercase-text">
              {pauseModalStep === 1 ? (
                <>
                  {isRequestPending ? (
                    <img
                      role="presentation"
                      onClick={() => {
                        setShowPendingPauseRequestModal(true);
                        setPauseDateDetails(initialPasueDate);
                      }}
                      className="modal-back-arrow"
                      src={LeftArrowIcon}
                      alt="back-arrow"
                    />
                  ) : null}
                  Step 1/2: pause agreement: Dates
                </>
              ) : (
                <>
                  <img
                    role="presentation"
                    onClick={() => {
                      setPauseModalStep(1);
                      setMonthlyRetainerAmount(
                        agreementData?.monthlyRetainer?.invoiceAmount || 0,
                      );
                      setLargerAmountErrorRetainer(false);
                      setIsAllowToEditRetainer(false);
                    }}
                    className="modal-back-arrow"
                    src={LeftArrowIcon}
                    alt="back-arrow"
                  />{' '}
                  Step 2/2: pause agreement: Amounts
                </>
              )}
            </div>

            {!loader.page ? (
              <p className="normal-text-black mb-3">
                {pauseModalStep === 1
                  ? 'Please select the pause duration for the following invoice types'
                  : 'Please confirm the amounts for the following invoice types'}
              </p>
            ) : null}

            {loader.page ? (
              <div className="mt-5">
                <PageLoader
                  component="modal"
                  type="page"
                  width={40}
                  height={40}
                  color="#FF5933"
                />
              </div>
            ) : pauseModalStep === 1 ? (
              renderAgreementDatesModal() // step 1
            ) : (
              renderAgreementAmountsModal(useFormatNumber) // step 2
            )}
          </div>
        </div>
        {renderFooter()}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      <ModalBox>
        {!bgsManagerEmail
          ? renderBGSManagerModal()
          : showPendingPauseRequestModal
          ? renderPendingRequestModal()
          : showConfirmationModal
          ? displayConfirmationScreen()
          : renderApprovedRequestModal()}
      </ModalBox>
    </>
  );
}

// prop types
AgreementPauseModal.defaultProps = {
  isBgs: false,
  isBgsManager: false,
  agreementId: '',
  bgsManagerEmail: '',
  saveDetails: () => {},
  addBGSMangerEmail: () => {},
  setShowModal: () => {},
};

AgreementPauseModal.propTypes = {
  isBgs: bool,
  isBgsManager: bool,
  agreementId: string,
  bgsManagerEmail: string,
  saveDetails: func,
  addBGSMangerEmail: func,
  setShowModal: func,
};
