/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */

import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import {
  string,
  bool,
  number,
  func,
  oneOfType,
  arrayOf,
  shape,
  object,
  instanceOf,
} from 'prop-types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { thadDetails } from '../../../constants';
import { DigitalSignature } from '../../../theme/images';
import { AddendumParagraph, EditorToll } from '../../../theme/AgreementStyle';
import { getNonUSStandardServicesMarketplaces } from '../../../api';

export default function Addendum({
  formData,
  templateData,
  newAddendumData,
  setNewAddendum,
  showEditor,
  setUpdatedFormData,
  updatedFormData,
  contractAddendumDetails,
  originalData,
  selectedCurrency,
  getServicesAccordingToAccType,
  mapMonthlyServices,
  mapAdditionalMarketPlaces,
  mapMonthlyServiceTotal,
  mapVariableMonthlyService,
  displayMarketplaceName,
  setVatMarketplaces,
  discountData,
  displayBBELogo,
  startDate,
  firstMonthDate,
  secondMonthDate,
  thirdMonthDate,
  returnDspMonthlyDates,
  getAddedOrRemovedAMCServices,
}) {
  const formattedFirstDateForBE = !formData?.changed_dsp_start_date
    ? dayjs(firstMonthDate).format('YYYY-MM-DD')
    : dayjs(returnDspMonthlyDates(new Date(firstMonthDate), 1)).format(
        'YYYY-MM-DD',
      );

  const formattedSecondDateForBE = !formData?.changed_dsp_start_date
    ? dayjs(secondMonthDate).format('YYYY-MM-DD')
    : dayjs(returnDspMonthlyDates(new Date(secondMonthDate), 1)).format(
        'YYYY-MM-DD',
      );
  const formattedThirdDateForBE = !formData?.changed_dsp_start_date
    ? dayjs(thirdMonthDate).format('YYYY-MM-DD')
    : dayjs(returnDspMonthlyDates(new Date(thirdMonthDate), 1)).format(
        'YYYY-MM-DD',
      );

  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const [editorState, setEditorState] = useState(null);
  const [contentState, setContentState] = useState(null);
  const styleForChangedValues = `font-weight: 800; font-family: Helvetica-bold; background: #ffe5df; padding: 4px 9px;`;
  const defaultEffectivityDateText =
    '[choose either date of addendum or custom date]';
  useEffect(() => {
    if (editorState === null) {
      let contentBlock;

      if (newAddendumData && newAddendumData.addendum) {
        contentBlock = htmlToDraft(newAddendumData.addendum);
      } else {
        contentBlock = htmlToDraft('<p></p>');
      }
      const content = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      setEditorState(EditorState.createWithContent(content));
      setContentState(newAddendumData);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (newAddendumData && !Object.keys(newAddendumData).length) {
      setEditorState(EditorState.createEmpty());
    }
  }, [newAddendumData]);

  const mapThadSignImg = () => {
    const data = `<p style="margin:0" class="long-text">
        <span style="font-weight: 300;font-family: Helvetica-Regular;">
          <img
            style="width:120px; margin-top: -5px;"
            src = ${DigitalSignature}
            alt="sig"
          />
        </span>
      </p>`;
    return data;
  };

  useEffect(() => {
    if (contractAddendumDetails?.notActive) {
      getNonUSStandardServicesMarketplaces().then((res) => {
        setVatMarketplaces(res);
      });
    }
  }, []);

  const onEditorStateChange = (editorData) => {
    setEditorState(editorData);
    const content = editorData.getCurrentContent();
    const info = draftToHtml(convertToRaw(content));
    const updatedAddendumm = { ...newAddendumData, addendum: info };
    setNewAddendum(updatedAddendumm);
    setUpdatedFormData({ ...updatedFormData, addendum: info });
  };

  const mapDefaultValues = (key) => {
    if (key === 'company_name') {
      return formData?.customer_id?.[key]
        ? formData?.customer_id?.[key]
        : `Client Name`;
    }
    if (key === 'start_date') {
      return `<span style="padding: 4px 9px; font-weight: bold";>${
        contractAddendumDetails?.notActive
          ? formData?.start_date
            ? dayjs(formData?.start_date).format('MM / DD / YYYY')
            : 'Enter Date'
          : startDate
          ? dayjs(startDate).format('MM / DD / YYYY')
          : 'Enter Date'
      }</span>`;
    }
    if (key === 'current_date') {
      return dayjs(Date()).format('MM / DD / YYYY');
    }
    if (key === 'addendum_start_date') {
      return formData?.addendum_start_date !== null
        ? `<span style="padding: 4px 9px; font-weight: bold";>${dayjs(
            formData?.addendum_start_date,
          ).format('MM / DD / YYYY')}</span>`
        : 'Select Date';
    }
    return formData && formData[key];
  };

  const displayEditor = () => {
    return (
      <EditorToll>
        <Editor
          editorState={editorState}
          contentState={contentState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          stripPastedStyles
          placeholder="   Add the points you would like to edit in the Standard Agreement"
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'remove',
              'history',
            ],
            fontFamily: {
              options: [
                'Arial',
                'Georgia',
                'Impact',
                'Tahoma',
                'Times New Roman',
                'Verdana',
                'Open Sans',
                'Helvetica-Regular',
              ],
            },
          }}
        />
      </EditorToll>
    );
  };

  const displayNumber = (num) => {
    const res = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return res;
  };

  // integrate the change in retainer threshold values
  const ShowRetainerThresholdValues = (
    type,
    quartelyKey,
    monthlyKey,
    option,
  ) => {
    return `${
      formData?.fee_structure?.[type]?.[option] === 'quarterly'
        ? `<li>1st Quarter from <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[quartelyKey]?.[
                '1st quarterly'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['1st quarterly'],
            ) || 0
          }</span> 
          </li>
          <li>2nd Quarter from <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[quartelyKey]?.[
                '2nd quarterly'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['2nd quarterly'],
            ) || 0
          }</span>
          </li>
          <li> 3rd Quarter from <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[quartelyKey]?.[
                '3rd quarterly'
              ],
            ) || 0
          }</span> to  <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['3rd quarterly'],
            ) || 0
          } </span> 
          </li>
          <li>4th Quarter from <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[quartelyKey]?.[
                '4th quarterly'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['4th quarterly'],
            ) || 0
          }</span> 
          </li>`
        : formData?.fee_structure?.[type]?.[option] === 'monthly'
        ? `<li>January from <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'january month'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['january month'],
            ) || 0
          } </span> 
          </li>
          <li>February from <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'february month'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['february month'],
            ) || 0
          }</span> 
          </li>
          <li>March from <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'march month'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['march month'],
            ) || 0
          } </span> 
          </li>
          <li>April from <span style="${styleForChangedValues}">  ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'april month'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['april month'],
            ) || 0
          }</span> 
          </li>
          <li>May from <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.['may month'],
            ) || 0
          }</span> to <span style="${styleForChangedValues}">  ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['may month'],
            ) || 0
          }  </span> 
          </li>
          <li>June from <span <span style="${styleForChangedValues}">  ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.['june month'],
            ) || 0
          }</span> to <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['june month'],
            ) || 0
          } </span> 
          </li>
          <li>July from <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.['july month'],
            ) || 0
          }</span> to <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['july month'],
            ) || 0
          } </span> 
          </li>
          <li>August from <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'august month'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['august month'],
            ) || 0
          } </span> 
          </li>
          <li> September from <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'september month'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.[
                'september month'
              ],
            ) || 0
          } </span> 
          </li>
          <li>October from <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'october month'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}""> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['october month'],
            ) || 0
          } </span> 
          </li>
          <li>November from <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'november month'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}""> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['november month'],
            ) || 0
          } </span> 
          </li>
          <li>December from <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'december month'
              ],
            ) || 0
          }</span> to <span style="${styleForChangedValues}"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['december month'],
            ) || 0
          } </span> 
          </li>`
        : ''
    }`;
  };

  const mapStandardServicesTable = (servicesData, tableType) => {
    const fields = [];
    servicesData.map((item) => {
      fields.push(`<tr>
                <td style="border: 1px solid black;padding: 13px;">${
                  item.name
                } </td>
                 ${
                   tableType === 'added'
                     ? `<td style="border: 1px solid black;padding: 13px;">
                       ${
                         item?.id && item?.effective_date
                           ? item?.effective_date
                           : item?.id && !item?.effective_date
                           ? formData?.contract_signed_date
                           : defaultEffectivityDateText
                       }
                     </td>`
                     : ''
                 }
                </tr>`);
      return null;
    });
    return fields;
  };

  // Display list of Addendum standard US Services
  const renderAddendumStandardUsServices = (result, tableType) => {
    const returnData = [];
    returnData.push(
      `<div class="table-responsive" style="margin-top:10px;">
        <table class="contact-list">
           <tr>
            <th>Services</th>
            ${tableType === 'added' ? `<th>Effective Date</th>` : ''}
          </tr>
               
          ${result?.length ? mapStandardServicesTable(result, tableType) : ''}
          </table>
          </div>`,
    );
    return returnData;
  };

  const renderListingOptimizationServices = (param, service1, service2) => {
    if (service1 !== service2) {
      return `<li><p>${param} changed from <span style="${styleForChangedValues}"">${
        service1 !== null ? service1 : 0
      }</span> to <span style="${styleForChangedValues}">${
        service2 !== null ? service2 : 0
      }</span></p>
     </li>`;
    }
    return '';
  };

  function getDifference(array1, array2, field) {
    return array1?.filter((object1) => {
      return !array2?.some((object2) => {
        if (field === 'marketplace') {
          return object1?.name === object2?.name;
        }
        if (object1?.quantity !== object2?.quantity)
          return object1?.quantity === object2?.quantity;
        return object1?.service?.id === object2?.service?.id;
      });
    });
  }

  const getMarketplaces = (type, status) => {
    let result = [];
    const accountType = type === 'seller' ? 'Seller' : 'Vendor';

    const addendumService = getServicesAccordingToAccType(
      formData?.additional_marketplaces,
      accountType,
    );
    const originalservice = getServicesAccordingToAccType(
      originalData?.additional_marketplaces,
      accountType,
    );

    if (status === 'added') {
      result = getDifference(addendumService, originalservice, 'marketplace');
    } else {
      result = getDifference(originalservice, addendumService, 'marketplace');
    }

    return result;
  };

  const getMonthlyServices = (type, status) => {
    let result = [];
    const accountType = type === 'seller' ? 'Seller' : 'Vendor';

    const addendumService = getServicesAccordingToAccType(
      formData?.additional_monthly_services,
      accountType,
    );

    const originalservice = getServicesAccordingToAccType(
      originalData?.additional_monthly_services,
      accountType,
    );

    if (status === 'added') {
      result = getDifference(addendumService, originalservice);
    } else {
      result = getDifference(originalservice, addendumService);
    }
    return result;
  };

  const displayMonthlyServices = (result, type) => {
    if (result?.length) {
      const list = result.map(
        (item) =>
          `<li>${
            type === 'dsp'
              ? displayMarketplaceName(item?.service?.name, 'dsp', 'lhs') ||
                displayMarketplaceName(item?.name, 'dsp', 'lhs')
              : item?.service?.name || item?.name
          } ${
            item?.service?.name === 'A+ Content' || item?.name === 'A+ Content'
              ? `(${item?.quantity || 1})`
              : ''
          }</li>`,
      );
      return list.toString().replaceAll(',', '');
    }
    return '';
  };

  const displayMonthlyServiceTable = (
    serviceAddedResult,
    marketplacesAddedResult,
    serviceRemovedResult,
    marketplacesRemovedResult,
    accntTYpe,
    tableType,
  ) => {
    const accountType = accntTYpe === 'seller' ? 'Seller' : 'Vendor';

    const originalservice = getServicesAccordingToAccType(
      originalData?.additional_monthly_services,
      accountType,
    );
    const addendumService = getServicesAccordingToAccType(
      formData?.additional_monthly_services,
      accountType,
    );
    const addendumMarketplaces = getServicesAccordingToAccType(
      formData?.additional_marketplaces,
      accountType,
    );
    const originalMarketplaces = getServicesAccordingToAccType(
      originalData?.additional_marketplaces,
      accountType,
    );

    const returnData = [];
    returnData.push(
      `<div class="table-responsive" style="margin-top:10px;">
        <table class="contact-list">
          ${
            tableType !== 'removed'
              ? getDifference(originalservice, serviceRemovedResult)?.length ||
                getDifference(
                  originalMarketplaces,
                  marketplacesRemovedResult,
                  'marketplace',
                )?.length
                ? `<tr>
            <th>Existing Service</th>
            <th>Effective Date</th>
            <th>Service Fee</th>
          </tr>`
                : ''
              : `<tr>
            <th>Existing Service</th>
            <th>Service Fee</th>
          </tr>`
          }
          ${
            tableType !== 'removed'
              ? mapMonthlyServices(
                  'additional_monthly_services',
                  getDifference(originalservice, serviceRemovedResult),
                )
              : ''
          }
         ${
           tableType !== 'removed'
             ? mapAdditionalMarketPlaces(
                 getDifference(
                   originalMarketplaces,
                   marketplacesRemovedResult,
                   'marketplace',
                 ),
               )
             : ''
         }
        <tr>
           ${
             (serviceAddedResult?.length || marketplacesAddedResult?.length) &&
             tableType !== 'removed'
               ? ` <th>Additional Service</th>
            <th>Effective Date</th>

            <th>Service Fee</th>
          `
               : ''
           }
           </tr>
          ${mapMonthlyServices(
            'additional_monthly_services',
            tableType !== 'removed' ? serviceAddedResult : serviceRemovedResult,
            tableType,
          )}
         ${mapAdditionalMarketPlaces(
           tableType !== 'removed'
             ? marketplacesAddedResult
             : marketplacesRemovedResult,
           tableType,
         )}

         ${
           tableType !== 'removed'
             ? getDifference(originalservice, serviceRemovedResult)?.length ===
                 0 &&
               getDifference(
                 originalMarketplaces,
                 marketplacesRemovedResult,
                 'marketplace',
               )?.length === 0 &&
               marketplacesAddedResult?.length === 0 &&
               serviceAddedResult?.length === 0
               ? ''
               : mapMonthlyServiceTotal(
                   addendumService,
                   addendumMarketplaces,
                   accntTYpe,
                 )
             : ''
         }
          ${mapVariableMonthlyService(
            tableType !== 'removed'
              ? getDifference(addendumService, serviceRemovedResult)
              : serviceRemovedResult,
          )} 
        </table>
      </div>`,
    );
    return returnData.length
      ? returnData.toString().replaceAll('>,<', '><')
      : '';
  };

  const renderAddendumAMCServices = (serviceData, flag) => {
    const returnData = [];
    const removedAMCServices = getAddedOrRemovedAMCServices(
      originalData?.amc_services,
      formData?.amc_services,
    );
    const existingServices = getAddedOrRemovedAMCServices(
      originalData?.amc_services,
      removedAMCServices,
    );

    returnData.push(
      `<div class="table-responsive" style="margin-top:10px;">
        <table class="contact-list">
          ${
            flag === 'added'
              ? existingServices?.length ||
                (originalData?.is_dsp_advertising &&
                  formData?.is_dsp_advertising !==
                    originalData?.is_dsp_advertising)
                ? `<tr>
            <th>Existing Service</th>
            <th>Effective Date</th>
            <th>Service Fee</th>
          </tr>`
                : ''
              : `<tr>
            <th>Existing Service</th>
            <th>Service Fee</th>
          </tr>`
          }
            ${
              flag !== 'removed'
                ? mapMonthlyServices('amc_services', existingServices, flag)
                : ''
            }

             <tr>
           ${
             serviceData?.length && flag === 'added'
               ? ` <th>Additional Service</th>
            <th>Effective Date</th>

            <th>Service Fee</th>
          `
               : ''
           }
            ${mapMonthlyServices('amc_services', serviceData, flag)}

           </tr>
          ${
            flag === 'added'
              ? mapMonthlyServiceTotal(formData?.amc_services, '', '')
              : ''
          }
        </table>
      </div>`,
    );
    return returnData.length
      ? returnData.toString().replaceAll('>,<', '><')
      : '';
  };

  // integrate the changes done in monthly services of sifned contract
  const displayMontlyServiceAddendum = (type, fields) => {
    const serviceAddedResult = getMonthlyServices(type, 'added');
    const serviceRemovedResult = getMonthlyServices(type, 'removed');
    const marketplacesAddedResult = getMarketplaces(type, 'added');
    const marketplacesRemovedResult = getMarketplaces(type, 'removed');
    const accountType = type === 'seller' ? 'Seller' : 'Vendor';

    const isDiscountAdded = discountData?.filter((item) => {
      return item?.account_type === accountType;
    });
    const originalservice = getServicesAccordingToAccType(
      originalData?.additional_monthly_services,
      accountType,
    );

    const originalMarketplaces = getServicesAccordingToAccType(
      originalData?.additional_marketplaces,
      accountType,
    );

    if (
      serviceAddedResult?.length ||
      serviceRemovedResult?.length ||
      marketplacesAddedResult?.length ||
      marketplacesRemovedResult?.length ||
      (isDiscountAdded?.length &&
        isDiscountAdded?.[0]?.is_addendum_discount_changed)
    ) {
      fields.push(
        `<div class="text-center mt-4" style="margin-top: 1.5rem !important; text-align: center;">
        <span style="font-weight: 800; font-family: Helvetica-bold;">Monthly Services for Amazon ${
          type === 'seller' ? 'Seller' : 'Vendor'
        } Account</span></div>`,
      );
    }
    if (
      serviceAddedResult?.length === 0 &&
      marketplacesAddedResult?.length === 0 &&
      getDifference(originalservice, serviceRemovedResult)?.length === 0 &&
      getDifference(
        originalMarketplaces,
        marketplacesRemovedResult,
        'marketplace',
      )?.length === 0
    ) {
      if (
        !(
          isDiscountAdded?.length &&
          isDiscountAdded?.[0]?.is_addendum_discount_changed
        )
      )
        fields.push(``);
    } else if (
      (isDiscountAdded?.length &&
        isDiscountAdded?.[0]?.is_addendum_discount_changed) ||
      serviceAddedResult?.length ||
      marketplacesAddedResult?.length
    ) {
      fields.push(`
       • The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">Monthly Service(s)</span>.<br>
          
         ${displayMonthlyServiceTable(
           serviceAddedResult,
           marketplacesAddedResult,
           serviceRemovedResult,
           marketplacesRemovedResult,
           type,
         )}
          `);
    }

    if (serviceRemovedResult?.length || marketplacesRemovedResult?.length) {
      fields.push(
        `• The Service Order / Statement of Work is updated to remove the following <span style="${styleForChangedValues}">Monthly Service(s)</span>. This change is effective on the addendum signature date.<br>
        
        ${displayMonthlyServiceTable(
          serviceAddedResult,
          marketplacesAddedResult,
          serviceRemovedResult,
          marketplacesRemovedResult,
          type,
          'removed',
        )} 
        `,
      );
    }
    return fields.length
      ? fields
          .toString()
          .replaceAll('>,<', '><')
          .replaceAll('.,', '.')
          .replaceAll(' ,', '')
          .replaceAll('•', '<br>&bull;')
          .replaceAll(', <br>', '<br>')
          .replaceAll(',<br>', '<br>')
      : '';
  };

  const getObjectDifference = (obj1, obj2) => {
    const combinedObj = { ...obj1, ...obj2 };
    const diff = Object?.entries(combinedObj)?.reduce((acc, [key, value]) => {
      if (
        (obj1 && !Object.values(obj1).includes(value)) ||
        (obj2 && !Object.values(obj2).includes(value))
      )
        acc[key] = value;
      return acc;
    }, {});
    if (diff && Object?.values(diff)?.length > 0) {
      return true;
    }
    return false;
  };

  const checkConditions = (param1, param2) => {
    param1 = Number(param1);
    param2 = Number(param2);
    if (
      (!param1 ? 0 : parseFloat(param1, 10)) !==
      (!param2 ? 0 : parseFloat(param2, 10))
    ) {
      return true;
    }
    return false;
  };

  // integrate the changes done in fee type of signed contract
  const accountContractAddendum = (type) => {
    const fields = [];
    // if fee type is changed.
    if (
      originalData?.fee_structure?.[type]?.fee_type === 'Retainer Only' &&
      formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share'
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to add the <span style="${styleForChangedValues}">Revenue Share</span> invoices value of <span style="${styleForChangedValues}"> ${selectedCurrency}${displayNumber(
          formData?.fee_structure?.[type]?.monthly_retainer || 0,
        )}</span> for managing ${type} account. This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }. That's parallel to the Retainer invoices that are issued monthly for ${type} account.<br>`,
      );
    }
    if (
      originalData.fee_structure?.[type]?.fee_type === 'Revenue Share Only' &&
      formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share'
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to add the <span style="${styleForChangedValues}">Retainer invoices</span> value of <span style="${styleForChangedValues}"> ${selectedCurrency}${displayNumber(
          formData?.fee_structure?.[type]?.monthly_retainer || 0,
        )}</span> for managing ${type} account. This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }. That's parallel to the Rev Share invoices that are issued monthly for ${type} account.<br>`,
      );
    }

    // if retainer value is changed.
    if (
      (formData?.fee_structure?.[type]?.retainer_type === 'Fixed' ||
        formData?.fee_structure?.[type]?.retainer_type === null ||
        !formData?.fee_structure?.[type]?.retainer_type) &&
      checkConditions(
        originalData?.fee_structure?.[type]?.monthly_retainer,
        formData?.fee_structure?.[type]?.monthly_retainer,
      )
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to change the Monthly Retainer to <span style="${styleForChangedValues}"> ${selectedCurrency}${displayNumber(
          formData?.fee_structure?.[type]?.monthly_retainer || 0,
        )}</span> This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>`,
      );
    }

    if (
      (formData?.fee_structure?.[type]?.retainer_type === 'quarterly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.quarterly_monthly_retainer || {},
          formData?.fee_structure?.[type]?.quarterly_monthly_retainer || {},
        )) ||
      (formData?.fee_structure?.[type]?.retainer_type === 'monthly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.monthly_monthly_retainer || {},
          formData?.fee_structure?.[type]?.monthly_monthly_retainer || {},
        ))
    ) {
      fields.push(
        ` • The Service Order / Statement of Work is updated to change the  <span style="${styleForChangedValues}">${
          formData?.fee_structure?.[type]?.retainer_type === 'quarterly'
            ? 'quarterly'
            : 'monthly'
        } retainer</span> invoice value for ${type} account.This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br> <ul style="list-style-type: circle;">${ShowRetainerThresholdValues(
          type,
          'quarterly_monthly_retainer',
          'monthly_monthly_retainer',
          'retainer_type',
        )}</ul><br>`,
      );
    }

    // if rev share changed.
    if (
      checkConditions(
        originalData?.fee_structure?.[type]?.rev_share,
        formData?.fee_structure?.[type]?.rev_share,
      )
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to change the Monthly Revenue share to <span style="${styleForChangedValues}"> ${displayNumber(
          formData?.fee_structure?.[type]?.rev_share || 0,
        )}%</span> This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>`,
      );
    }

    if (
      formData?.fee_structure?.[type]?.threshold_type === 'None' &&
      originalData?.fee_structure?.[type]?.threshold_type !== 'None' &&
      originalData?.fee_structure?.[type]?.threshold_type !== null
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to change the Revenue Share Threshold to <span style="${styleForChangedValues}">None </span>. This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>`,
      );
    }
    const formDataFixedThresholdType =
      (formData?.fee_structure?.[type]?.threshold_type === 'Fixed' ||
        formData?.fee_structure?.[type]?.threshold_type === null) &&
      originalData?.fee_structure?.[type]?.sales_threshold;
    if (
      checkConditions(
        formDataFixedThresholdType,
        formData?.fee_structure?.[type]?.sales_threshold,
      ) &&
      formData?.fee_structure?.[type]?.threshold_type === 'Fixed'
    ) {
      fields.push(
        `• To The Service Order / Statement of Work is updated to change the fixed threshold to <span style="${styleForChangedValues}"> ${selectedCurrency}${displayNumber(
          formData?.fee_structure?.[type]?.sales_threshold || 0,
        )}</span> for managing ${type} account.  This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>`,
      );
    }

    if (
      (formData?.fee_structure?.[type]?.threshold_type === 'quarterly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.quarterly_rev_share || {},
          formData?.fee_structure?.[type]?.quarterly_rev_share || {},
        )) ||
      (formData?.fee_structure?.[type]?.threshold_type === 'monthly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.monthly_rev_share || {},
          formData?.fee_structure?.[type]?.monthly_rev_share || {},
        ))
    ) {
      fields.push(
        `• To The Service Order / Statement of Work is updated to change the  <span style="${styleForChangedValues}"> ${
          formData?.fee_structure?.[type]?.threshold_type === 'quarterly'
            ? 'quarterly'
            : 'monthly'
        } revenue share </span> invoice value for ${type} account.This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br> <ul style="list-style-type: circle;">${ShowRetainerThresholdValues(
          type,
          'quarterly_rev_share',
          'monthly_rev_share',
          'threshold_type',
        )}</ul><br>`,
      );
    }

    // if billing cap/minimum changed.
    if (
      checkConditions(
        originalData?.fee_structure?.[type]?.billing_cap,
        formData?.fee_structure?.[type]?.billing_cap,
      )
    ) {
      fields.push(
        `• To The Service Order / Statement of Work is updated to change the  billing cap to <span style="${styleForChangedValues}"> ${selectedCurrency}${displayNumber(
          formData?.fee_structure?.[type]?.billing_cap || 0,
        )}</span> for managing ${type} account; This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>`,
      );
    }

    if (
      checkConditions(
        originalData?.fee_structure?.[type]?.billing_minimum,
        formData?.fee_structure?.[type]?.billing_minimum,
      )
    ) {
      fields.push(
        `• To The Service Order / Statement of Work is updated to change the billing minimum to <span style="${styleForChangedValues}"> ${selectedCurrency}${displayNumber(
          formData?.fee_structure?.[type]?.billing_minimum || 0,
        )}</span> for managing ${type} account; This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>`,
      );
    }

    // if combined rev share is changed.
    if (
      originalData?.combined_rev_share !== formData?.combined_rev_share &&
      type === 'seller'
    ) {
      if (formData?.combined_rev_share) {
        fields.push(
          `• BBE is going to calculate the <span style="${styleForChangedValues}">Revenue Share by combining</span> the seller and vendor accounts sales revenue. This is starting from 3rd of month.`,
        );
      } else {
        fields.push(`• BBE is going to calculate the <span style="${styleForChangedValues}">Revenue Share</span> of the seller account sales <span style="${styleForChangedValues}">separately</span> 
          from the vendor accounts sales revenue for managing  ${selectedCurrency}${displayNumber(
          formData?.fee_structure?.[type]?.sales_threshold || 0,
        )}</span> account. This is starting from 3rd of every month.
        `);
      }
    }

    // 2.1 If monthly services are added
    displayMontlyServiceAddendum(type, fields);

    return fields.length
      ? fields
          .toString()
          .replaceAll('>,<', '><')
          .replaceAll('.,', '.')
          .replaceAll(' ,', '')
          .replaceAll('•', '<br>&bull;')
          .replaceAll(', <br>', '<br>')
          .replaceAll(',<br>', '<br>')
          .replaceAll('</div>,', '</div>')
      : '';
  };

  const checkIsMonthSame = (originalBudgetDate, formDataBudgetDate) => {
    const originalDate = originalBudgetDate || formDataBudgetDate;

    if (
      new Date(originalDate)?.getMonth() ===
      new Date(formDataBudgetDate)?.getMonth()
    ) {
      return true;
    }
    return false;
  };

  const displayOneTimebudgetAllocation = (marketplace) => {
    const fields = [];
    let dspMarketplaceOfOriginalContract = null;
    for (const originaldataDspMarketplace of originalData?.dsp_marketplaces) {
      if (
        originaldataDspMarketplace?.country_currency?.country ===
        marketplace?.country_currency?.country
      ) {
        dspMarketplaceOfOriginalContract = originaldataDspMarketplace;
      }
    }

    if (
      Number(dspMarketplaceOfOriginalContract?.one_time_budget) !==
      Number(marketplace?.one_time_budget)
    ) {
      fields.push(`<li>One Time Fee Budget from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
        dspMarketplaceOfOriginalContract?.one_time_budget || 0,
      )}</span> to <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
        marketplace?.one_time_budget || 0,
      )}</span></li>`);
    }
    return fields;
  };

  const displayDynamicMonthlyBudgetAllocation = (marketplace) => {
    const fields = [];
    let dspMarketplaceOfOriginalContract = null;
    if (originalData?.dsp_marketplaces) {
      for (const originaldataDspMarketplace of originalData?.dsp_marketplaces) {
        if (
          originaldataDspMarketplace?.country_currency?.country ===
          marketplace?.country_currency?.country
        ) {
          dspMarketplaceOfOriginalContract = originaldataDspMarketplace;
        }
      }
    }

    if (
      Number(dspMarketplaceOfOriginalContract?.monthly_budget) !==
      Number(marketplace?.monthly_budget)
    ) {
      if (
        checkIsMonthSame(
          dspMarketplaceOfOriginalContract?.monthly_budget_date,
          formattedFirstDateForBE,
        )
      ) {
        fields.push(`<li>Fee Budget for ${dayjs(formattedFirstDateForBE).format(
          'MMMM YY',
        )} from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          dspMarketplaceOfOriginalContract?.monthly_budget || 0,
        )}</span> to <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          marketplace?.monthly_budget,
        )}</span></li>`);
      } else {
        fields.push(`<li>Fee Budget for ${dayjs(
          dspMarketplaceOfOriginalContract?.monthly_budget_date,
        ).format('MMMM YY')} from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          dspMarketplaceOfOriginalContract?.monthly_budget || 0,
        )}</span> to ${dayjs(formattedFirstDateForBE).format(
          'MMMM YY',
        )} <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          marketplace?.monthly_budget,
        )}</span></li>`);
      }
    }

    if (
      Number(dspMarketplaceOfOriginalContract?.monthly_budget_second) !==
      Number(marketplace?.monthly_budget_second)
    ) {
      if (
        checkIsMonthSame(
          dspMarketplaceOfOriginalContract?.monthly_budget_second_date,
          formattedSecondDateForBE,
        )
      ) {
        fields.push(`<li>Fee Budget for ${dayjs(
          formattedSecondDateForBE,
        ).format('MMMM YY')} from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          dspMarketplaceOfOriginalContract?.monthly_budget_second || 0,
        )}</span> to <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          marketplace?.monthly_budget_second,
        )}</span></li>`);
      } else {
        fields.push(`<li>Fee Budget for ${dayjs(
          dspMarketplaceOfOriginalContract?.monthly_budget_second_date,
        ).format('MMMM YY')} from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          dspMarketplaceOfOriginalContract?.monthly_budget_second || 0,
        )}</span> to ${dayjs(formattedSecondDateForBE).format(
          'MMMM YY',
        )} <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          marketplace?.monthly_budget_second,
        )}</span></li>`);
      }
    }

    if (
      Number(dspMarketplaceOfOriginalContract?.monthly_budget_third) !==
      Number(marketplace?.monthly_budget_third)
    ) {
      if (
        checkIsMonthSame(
          dspMarketplaceOfOriginalContract?.monthly_budget_third_date,
          formattedThirdDateForBE,
        )
      ) {
        fields.push(`<li>Fee Budget for ${dayjs(formattedThirdDateForBE).format(
          'MMMM YY',
        )} from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          dspMarketplaceOfOriginalContract?.monthly_budget_third || 0,
        )}</span> to <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          marketplace?.monthly_budget_third,
        )}</span></li>`);
      } else {
        fields.push(`<li>Fee Budget for ${dayjs(
          dspMarketplaceOfOriginalContract?.monthly_budget_third_date,
        ).format('MMMM YY')} from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          dspMarketplaceOfOriginalContract?.monthly_budget_third || 0,
        )}</span> to ${dayjs(formattedThirdDateForBE).format(
          'MMMM YY',
        )} <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          marketplace?.monthly_budget_third,
        )}</span></li>`);
      }
    }
    return fields;
  };

  const displayDynamicDspFeesForAddendum = () => {
    const fields = [];
    if (
      checkConditions(originalData?.dsp_fee, formData?.dsp_fee) ||
      checkConditions(originalData?.dsp_fee_second, formData?.dsp_fee_second) ||
      checkConditions(originalData?.dsp_fee_third, formData?.dsp_fee_third)
    ) {
      if (originalData?.dsp_fee !== formData?.dsp_fee) {
        fields.push(`<li>Fee Budget for ${dayjs(formattedFirstDateForBE).format(
          'MMMM YY',
        )} from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          originalData?.dsp_fee || 0,
        )}</span> to <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          formData?.dsp_fee,
        )}</span></li>`);
      }

      if (originalData?.dsp_fee_second !== formData?.dsp_fee_second) {
        fields.push(`<li>Fee Budget for ${dayjs(
          formattedSecondDateForBE,
        ).format('MMMM YY')} from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          originalData?.dsp_fee_second || 0,
        )}</span> to <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          formData?.dsp_fee_second,
        )}</span></li>`);
      }

      if (originalData?.dsp_fee_third !== formData?.dsp_fee_third) {
        fields.push(`<li>Fee Budget for ${dayjs(formattedThirdDateForBE).format(
          'MMMM YY',
        )} from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          originalData?.dsp_fee_third || 0,
        )}</span> to <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
          formData?.dsp_fee_third,
        )}</span></li>`);
      }
      return fields;
    }
    return '';
  };

  const displayDspFeesForAddendum = () => {
    if (checkConditions(originalData?.dsp_fee, formData?.dsp_fee)) {
      if (!originalData?.dsp_fee) {
        return originalData?.dsp_fee !== formData?.dsp_fee
          ? `<li>Fee Budget from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
              originalData?.dsp_fee || 0,
            )}</span> to <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
              formData?.dsp_fee,
            )}</span></li>`
          : '';
      }
      return originalData?.dsp_fee !== formData?.dsp_fee
        ? `<li>Fee Budget from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
            originalData?.dsp_fee || 0,
          )}</span> to <span style="font-weight: 800;
            font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            formData?.dsp_fee ? displayNumber(formData?.dsp_fee) : 0
          }</span></li>`
        : '';
    }
    return '';
  };

  const nonAccountContractAddendum = () => {
    const fields = [];

    // If LISTING OPTIMIZATIONS (ASIN’S PER MONTH) changed
    if (
      checkConditions(
        originalData?.content_optimization,
        formData?.content_optimization,
      ) ||
      checkConditions(
        originalData?.design_optimization,
        formData?.design_optimization,
      ) ||
      checkConditions(
        originalData?.photography_optimization,
        formData?.photography_optimization,
      )
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to change the following <span style="${styleForChangedValues}">Listing Optimizations</span> (asin’s per month). This change is effective from ${
          formData?.listing_optimization_effective_date
            ? dayjs(formData?.listing_optimization_effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.
        <ol>
        ${renderListingOptimizationServices(
          'Copy Optimization',
          originalData?.content_optimization,
          formData?.content_optimization,
        )}
        ${renderListingOptimizationServices(
          'Design Optimization',
          originalData?.design_optimization,
          formData?.design_optimization,
        )}
        </ol>`,
      );
    }

    // If LISTING TRANSLATION changed
    if (
      checkConditions(
        originalData?.copy_listing_translation,
        formData?.copy_listing_translation,
      ) ||
      checkConditions(
        originalData?.creative_listing_translation,
        formData?.creative_listing_translation,
      )
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to change the following <span style="${styleForChangedValues}">Listing Translation</span>(asin’s per month). This change is effective from ${
          formData?.listing_translation_effective_date
            ? dayjs(formData?.listing_translation_effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.
      <ol>
      ${renderListingOptimizationServices(
        'Copy Translation',
        originalData?.copy_listing_translation,
        formData?.copy_listing_translation,
      )}
      ${renderListingOptimizationServices(
        'Creative Translation',
        originalData?.creative_listing_translation,
        formData?.creative_listing_translation,
      )}
      </ol>`,
      );
    }
    // 1.2. If Standard services are Removed
    const removedStandardUsServices =
      originalData?.standard_us_services?.filter((obj) => {
        return !formData?.standard_us_services?.some((obj2) => {
          return obj?.name === obj2?.name;
        });
      });

    if (removedStandardUsServices && removedStandardUsServices?.length) {
      fields.push(
        `• The Service Order / Statement of Work is updated to remove the following <span style="${styleForChangedValues}">Standard Service(s)</span>. This change is effective on the addendum signature date.
        ${renderAddendumStandardUsServices(
          removedStandardUsServices,
          'removed',
        )}`,
      );
    }

    // if Statement Of Work are changed.
    // 1.1 If Standard services are Added

    const standardUsServicesUniqueResult =
      formData?.standard_us_services?.filter((obj) => {
        return !originalData?.standard_us_services?.some((obj2) => {
          return obj.name === obj2.name;
        });
      });

    if (
      standardUsServicesUniqueResult &&
      standardUsServicesUniqueResult?.length
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">Standard Service(s)</span>.
        ${renderAddendumStandardUsServices(
          standardUsServicesUniqueResult,
          'added',
        )}
        `,
      );
    }

    // If Standard non-us services are changed
    // for Non Us Services
    const saperateFormDataNonUsmarketplaces = (data) => {
      return (
        data?.length &&
        data
          .filter((item) => item.name === 'VAT Registration')
          .map((filteredItem) => filteredItem)
      );
    };

    const seperateVATFilingMarketplaces = (data) => {
      const marketplaces =
        data?.length &&
        data.filter(
          (item) =>
            item.name === 'VAT Filing' &&
            item.marketplace !== null &&
            item.frequency !== null,
        );
      return marketplaces?.length ? marketplaces : [];
    };

    const seperateFormDataInternationalNonUsServices = (data) => {
      return (
        data?.length &&
        data.filter((item) => item?.name?.includes('International'))
      );
    };

    const addedRemovedStandardServices = (data1, data2) => {
      return data1?.filter((item) => {
        return !data2.some((item1) => {
          return item?.marketplace === item1?.marketplace;
        });
      });
    };

    const uniqueInternationalNonUsServices = (data1, data2) =>
      data1?.filter((item) => {
        return !data2.some((item1) => {
          return item.name === item1.name;
        });
      });
    // 1. for Vat Regrestration
    const formDataNonUsmarketplaces =
      saperateFormDataNonUsmarketplaces(
        formData?.standard_non_us_services?.length &&
          formData?.standard_non_us_services,
      ) || [];

    const originalDataNonUsmarketplaces =
      saperateFormDataNonUsmarketplaces(
        originalData?.standard_non_us_services?.length &&
          originalData?.standard_non_us_services,
      ) || [];

    const uniqueNonUsServices = addedRemovedStandardServices(
      formDataNonUsmarketplaces,
      originalDataNonUsmarketplaces,
    );

    const removedNonUsServices = addedRemovedStandardServices(
      originalDataNonUsmarketplaces,
      formDataNonUsmarketplaces,
    );

    // 2. for Vat Filling
    const formDataVATFilingMarketplaces =
      seperateVATFilingMarketplaces(
        formData?.standard_non_us_services?.length &&
          formData?.standard_non_us_services,
      ) || [];

    const originalDataVATFilingMarketplaces =
      seperateVATFilingMarketplaces(
        originalData?.standard_non_us_services?.length &&
          originalData?.standard_non_us_services,
      ) || [];

    const addedVatFillingNonUsServices = addedRemovedStandardServices(
      formDataVATFilingMarketplaces,
      originalDataVATFilingMarketplaces,
    );
    const removedVatFillingNonUsServices = addedRemovedStandardServices(
      originalDataVATFilingMarketplaces,
      formDataVATFilingMarketplaces,
    );

    // 3. for international service
    const formDataInternationalNonUsServices =
      seperateFormDataInternationalNonUsServices(
        formData?.standard_non_us_services?.length &&
          formData?.standard_non_us_services,
      ) || [];

    const originalDataInternationalNonUsServices =
      seperateFormDataInternationalNonUsServices(
        originalData?.standard_non_us_services?.length &&
          originalData?.standard_non_us_services,
      ) || [];

    const addedInternationalNonUsServices = uniqueInternationalNonUsServices(
      formDataInternationalNonUsServices,
      originalDataInternationalNonUsServices,
    );
    const remmovedInternationalNonUsServices = uniqueInternationalNonUsServices(
      originalDataInternationalNonUsServices,
      formDataInternationalNonUsServices,
    );

    // DPS service length (MONTHS), field,  in DSP Advertising
    const showOneTimeBudget = () => {
      if (formData?.dsp_one_time_fee) {
        return `${
          originalData?.dsp_one_time_fee !== formData?.dsp_one_time_fee
            ? `<li>One time Ad Budget from <span style="${styleForChangedValues}"> ${selectedCurrency}${displayNumber(
                originalData?.dsp_one_time_fee || 0,
              )}</span> to <span style="${styleForChangedValues}">${selectedCurrency}${displayNumber(
                formData?.dsp_one_time_fee || 0,
              )}</span></li>`
            : ''
        }`;
      }
      if (!formData?.dsp_one_time_fee) {
        return `${
          (!originalData?.dsp_one_time_fee
            ? 0
            : originalData?.dsp_one_time_fee) !==
          (!formData?.dsp_one_time_fee ? 0 : formData?.dsp_one_time_fee)
            ? `<li>DSP service length from <span style="${styleForChangedValues}">${selectedCurrency}${displayNumber(
                originalData?.dsp_one_time_fee || 0,
              )}</span> to <span style=" ${styleForChangedValues}"> ${selectedCurrency}${displayNumber(
                formData?.dsp_one_time_fee || 0,
              )}</span></li>`
            : ''
        }`;
      }
      return '';
    };

    // DPS service length (MONTHS), field,  in DSP Advertising
    const showDspLength = () => {
      if (formData?.dsp_length?.value) {
        return `${
          originalData?.dsp_length?.value !== formData?.dsp_length?.value
            ? `<li>DSP service length from <span style="${styleForChangedValues}">${
                originalData?.dsp_length?.value || originalData?.dsp_length || 0
              }</span> to <span style="${styleForChangedValues}">${
                formData?.dsp_length?.value || formData?.dsp_length || 0
              }</span></li>`
            : ''
        }`;
      }
      if (!formData?.dsp_length?.value) {
        return `${
          (!originalData?.dsp_length?.value
            ? 0
            : originalData?.dsp_length?.value) !==
          (!formData?.dsp_length ? 0 : formData?.dsp_length)
            ? `<li>DSP service length from <span style="${styleForChangedValues}">${
                originalData?.dsp_length?.value || originalData?.dsp_length || 0
              }</span> to <span style="${styleForChangedValues}">${
                formData?.dsp_length || formData?.dsp_length || 0
              }</span></li>`
            : ''
        }`;
      }
      return '';
    };

    const addedAMCServices = getAddedOrRemovedAMCServices(
      formData?.amc_services,
      originalData?.amc_services,
    );
    const removedAMCServices = getAddedOrRemovedAMCServices(
      originalData?.amc_services,
      formData?.amc_services,
    );

    // DSP Advertising service and AMC services
    if (
      (formData?.is_dsp_advertising &&
        formData?.is_dsp_advertising !== originalData?.is_dsp_advertising) ||
      addedAMCServices?.length ||
      discountData?.find(
        (item) =>
          item?.is_addendum_discount_changed &&
          item?.amount !== null &&
          item?.type !== null,
      )
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">Advertising Service(s)</span>.
        ${renderAddendumAMCServices(addedAMCServices, 'added')}
        `,
      );
    }
    if (
      (!formData?.is_dsp_advertising &&
        formData?.is_dsp_advertising !== originalData?.is_dsp_advertising) ||
      removedAMCServices?.length
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to remove the following <span style="${styleForChangedValues}">Advertising Service(s)</span>.
        ${renderAddendumAMCServices(removedAMCServices, 'removed')}
        `,
      );
    }

    const setDspAdvertisingEffectiveDate = () => {
      if (formData?.dsp_start_date) {
        return dayjs(formData?.dsp_start_date).format('MM / DD / YYYY');
      }
      return defaultEffectivityDateText;
    };

    const mapVatRegistrationTable = (servicesData, type, tableType) => {
      const field = [];
      servicesData.map((item) => {
        field.push(`<tr>
                <td style="border: 1px solid black;padding: 13px;">${
                  type === 'internationService' ? item.name : ''
                }
                 ${
                   type === 'vatRegistration'
                     ? `VAT Registration: ${displayMarketplaceName(
                         item?.marketplace,
                         'vat',
                         'lhs',
                       )}`
                     : ''
                 } 
                 ${
                   type === 'vatFiling'
                     ? `VAT Filing: ${displayMarketplaceName(
                         item?.marketplace,
                         'vat',
                         'lhs',
                       )} (${item?.frequency})`
                     : ''
                 } 
                </td>
                 ${
                   tableType === 'added'
                     ? `<td style="border: 1px solid black;padding: 13px;">${
                         item?.id && item?.effective_date
                           ? item?.effective_date
                           : item?.id && !item?.effective_date
                           ? formData?.contract_signed_date
                           : defaultEffectivityDateText
                       } </td>`
                     : ''
                 }
                </tr>`);
        return null;
      });
      return field;
    };

    const renderNonUSStandardServices = (
      vatReg,
      vatFiling,
      internationService,
      tableType,
    ) => {
      const returnData = [];
      returnData.push(
        `<div class="table-responsive" style="margin-top:10px;">
        <table class="contact-list">
           <tr>
            <th>Services</th>
           ${tableType === 'added' ? `<th>Effective Date</th>` : ''}
          </tr>
               
          ${
            vatReg?.length
              ? mapVatRegistrationTable(vatReg, 'vatRegistration', tableType)
              : ''
          }
          ${
            vatFiling?.length
              ? mapVatRegistrationTable(vatFiling, 'vatFiling', tableType)
              : ''
          }
          ${
            internationService?.length
              ? mapVatRegistrationTable(
                  internationService,
                  'internationService',
                  tableType,
                )
              : ''
          }
          </table>
          </div>`,
      );
      return returnData;
    };

    const showDspAgencyFees = (param1, param2) => {
      if (param1?.value) {
        return `${(param2?.value !== param1?.value
          ? `<li>Agency Fee from <span style="${styleForChangedValues}">${
              param2?.value >= 0
                ? `${param2?.value}%`
                : param2
                ? `${param2}%`
                : 0
            }</span> to <span style="${styleForChangedValues}">${
              param1?.value >= 0
                ? `${param1?.value}%`
                : param1
                ? `${param1}%`
                : 0
            }</span></li>`
          : ''
        ).replaceAll('[object Object]', 0)}`;
      }
      if (!param1?.value) {
        return `${((!param2?.value ? 0 : param2?.value) !==
        (!param1 ? 0 : param1)
          ? `<li>Agency Fee from <span style="${styleForChangedValues}">${
              param2?.value ? `${param2?.value}%` : param2 ? `${param2}%` : 0
            }</span> to <span style="${styleForChangedValues}">${
              `${param1}%` ? `${param1}%` : 0
            }</span></li>`
          : ''
        ).replaceAll('[object Object]', 0)}`;
      }
      return '';
    };

    // Display result if Standard non us services are added
    if (
      uniqueNonUsServices?.length ||
      addedVatFillingNonUsServices?.length ||
      addedInternationalNonUsServices?.length
    ) {
      fields.push(`• The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">Standard Services</span>
      (for non-us marketplaces).
      ${renderNonUSStandardServices(
        uniqueNonUsServices,
        addedVatFillingNonUsServices,
        addedInternationalNonUsServices,
        'added',
      )}
      `);
    }

    // Display result if standard non us services are removed
    if (
      removedNonUsServices?.length ||
      removedVatFillingNonUsServices?.length ||
      remmovedInternationalNonUsServices?.length
    ) {
      fields.push(`• The Service Order / Statement of Work is updated to remove the following <span style="${styleForChangedValues}">Standard Services</span> (for non-us marketplaces). This change is effective on the addendum signature date.
       ${renderNonUSStandardServices(
         removedNonUsServices,
         removedVatFillingNonUsServices,
         remmovedInternationalNonUsServices,
         'removed',
       )}
    `);
    }

    // if dsp Advertising
    if (
      originalData?.dsp_fee !== formData?.dsp_fee ||
      formData?.dsp_one_time_fee !== originalData?.dsp_one_time_fee ||
      (formData?.is_dynamic_dsp_fee &&
        (originalData?.dsp_fee_second !== formData?.dsp_fee_second ||
          originalData?.dsp_fee_third !== formData?.dsp_fee_third)) ||
      (originalData?.dsp_length?.value
        ? originalData?.dsp_length?.value
        : 0) !==
        (formData?.dsp_length?.value
          ? formData?.dsp_length?.value
          : formData?.dsp_length
          ? formData?.dsp_length
          : 0) ||
      (originalData?.agency_fee?.value
        ? originalData?.agency_fee?.value
        : 0) !==
        (formData?.agency_fee?.value
          ? formData?.agency_fee?.value
          : formData?.agency_fee
          ? formData?.agency_fee
          : 0)
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">DSP Advertising</span>, for 
        <ol> ${
          formData?.is_dynamic_dsp_fee
            ? displayDynamicDspFeesForAddendum()
            : displayDspFeesForAddendum()
        }
        ${
          formData?.dsp_one_time_fee !== originalData?.dsp_one_time_fee
            ? // dsp service length (MONTHS), field,  in DSP Advertising
              showOneTimeBudget(formData?.dsp_fee, originalData?.dsp_fee)
            : ''
        }
        ${
          formData?.contract_type?.toLowerCase()?.includes('recurring')
            ? // dsp service length (MONTHS), field,  in DSP Advertising
              showDspLength(formData?.dsp_fee, originalData?.dsp_fee)
            : ''
        }
       ${showDspAgencyFees(
         formData?.agency_fee,
         originalData?.agency_fee,
       )}</ol> This change is effective from ${setDspAdvertisingEffectiveDate()}.<br>.
        `,
      );
    }

    if (
      originalData?.changed_dsp_start_date !==
        formData?.changed_dsp_start_date ||
      originalData?.one_time_budget_distribution_equally !==
        formData?.one_time_budget_distribution_equally ||
      originalData?.dsp_one_time_fee !== formData?.dsp_one_time_fee
    ) {
      let dspMarketplaceOfOriginalContract = null;
      if (formData?.dsp_marketplaces && originalData?.dsp_marketplaces) {
        for (const marketplace of formData?.dsp_marketplaces) {
          for (const originaldataDspMarketplace of originalData?.dsp_marketplaces) {
            if (
              originaldataDspMarketplace?.country_currency?.country ===
              marketplace?.country_currency?.country
            ) {
              dspMarketplaceOfOriginalContract = originaldataDspMarketplace;
            }
          }
          if (
            Number(dspMarketplaceOfOriginalContract?.one_time_budget) !==
            Number(marketplace?.one_time_budget)
          ) {
            fields.push(
              `• The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">One time Budget Allocation </span>, for <span style="${styleForChangedValues}">${
                marketplace?.country_currency?.country
                  ? marketplace?.country_currency?.country
                  : displayMarketplaceName(marketplace?.name, '', '')
              } </span>Marketplace, for
         <ol> ${displayOneTimebudgetAllocation(marketplace)}
        </ol> This change is effective from ${setDspAdvertisingEffectiveDate()}.<br>.
        `,
            );
          }
        }
      }
    }

    if (
      (originalData?.dsp_fee !== formData?.dsp_fee ||
        (formData?.is_dynamic_dsp_fee &&
          (originalData?.dsp_fee_second !== formData?.dsp_fee_second ||
            originalData?.dsp_fee_third !== formData?.dsp_fee_third))) &&
      formData?.dsp_marketplaces
    ) {
      for (const marketplace of formData?.dsp_marketplaces) {
        let dspMarketplaceOfOriginalContract = null;
        if (originalData?.dsp_marketplaces) {
          for (const originaldataDspMarketplace of originalData?.dsp_marketplaces) {
            if (
              originaldataDspMarketplace?.country_currency?.country ===
              marketplace?.country_currency?.country
            ) {
              dspMarketplaceOfOriginalContract = originaldataDspMarketplace;
            }
          }
        }
        if (
          Number(dspMarketplaceOfOriginalContract?.monthly_budget) !==
            Number(marketplace?.monthly_budget) ||
          Number(dspMarketplaceOfOriginalContract?.monthly_budget_second) !==
            Number(marketplace?.monthly_budget_second) ||
          Number(dspMarketplaceOfOriginalContract?.monthly_budget_third) !==
            Number(marketplace?.monthly_budget_third)
        ) {
          fields.push(
            `• The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">Monthly Budget Allocation </span>, for <span style="${styleForChangedValues}">${
              marketplace?.country_currency?.country
                ? marketplace?.country_currency?.country
                : displayMarketplaceName(marketplace?.name, '', '')
            } </span>Marketplace, for
         <ol> ${
           formData?.is_dynamic_dsp_fee
             ? displayDynamicMonthlyBudgetAllocation(marketplace)
             : displayDynamicMonthlyBudgetAllocation(marketplace)
         }
        </ol> This change is effective from ${setDspAdvertisingEffectiveDate()}.<br>.
        `,
          );
        }
      }
    }

    // if dsp marketplaces
    const serviceAddedResult = getDifference(
      formData?.dsp_marketplaces,
      originalData?.dsp_marketplaces,
      'marketplace',
    );
    const serviceRemovedResult = getDifference(
      originalData?.dsp_marketplaces,
      formData?.dsp_marketplaces,
      'marketplace',
    );

    if (serviceAddedResult?.length || serviceRemovedResult?.length) {
      fields.push(
        `<div class=" text-center mt-4" style="margin-top: 1.5rem!important; text-align: center;">
        <span style="font-weight: 800; font-family: Helvetica-bold;">DSP Marketplaces</span></div>`,
      );
    }
    if (serviceAddedResult?.length) {
      fields.push(` • The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">DSP marketplaces</span>, This change is effective from ${setDspAdvertisingEffectiveDate()}.<br>.
        <ol>
         ${displayMonthlyServices(serviceAddedResult, 'dsp')}
           </ol>`);
    }

    if (serviceRemovedResult?.length) {
      fields.push(
        `• The Service Order / Statement of Work is updated to remove the following <span style="${styleForChangedValues}">DSP marketplaces</span>, This change is effective on the addendum signature date.
         <ol>
        ${displayMonthlyServices(serviceRemovedResult, 'dsp')} </ol>      
        `,
      );
    }
    return fields.length
      ? fields
          .toString()
          .replaceAll('>,<', '><')
          .replaceAll('.,', '.')
          .replaceAll(' ,', '')
          .replaceAll('•', '<br>&bull;')
          .replaceAll(', <br>', '<br>')
          .replaceAll(',<br>', '')
      : '';
  };

  // handle contract addedndum changes
  const contractAddendumChanges = () => {
    if (contractAddendumDetails?.notActive) {
      if (formData?.seller_type?.label === 'Hybrid') {
        return `${accountContractAddendum('seller')}
          ${accountContractAddendum('vendor')} ${nonAccountContractAddendum()}`;
      }
      if (formData?.seller_type?.label === 'Seller') {
        return `${accountContractAddendum(
          'seller',
        )} ${nonAccountContractAddendum()}`;
      }
      if (formData?.seller_type?.label === 'Vendor') {
        return `${accountContractAddendum(
          'vendor',
        )} ${nonAccountContractAddendum()}`;
      }
      if (formData?.contract_type?.includes('dsp')) {
        return ` ${nonAccountContractAddendum()}`;
      }
    }
    return '';
  };

  return (
    <div>
      <AddendumParagraph>
        <p
          className="long-text"
          dangerouslySetInnerHTML={{
            __html: templateData?.addendum?.[0]
              .replace('BBE_LOGO', displayBBELogo())
              .replace('CUSTOMER_NAME', mapDefaultValues('company_name'))
              .replace('AGREEMENT_DATE', mapDefaultValues('start_date'))
              .replace(
                '{{Signdate}}',
                mapDefaultValues('addendum_start_date', 'Start Date'),
              ),
          }}
        />
        {showEditor ? displayEditor() : ''}

        {showEditor ? (
          ''
        ) : (
          <p
            className="long-text"
            dangerouslySetInnerHTML={{
              __html: newAddendumData?.addendum || '',
            }}
          />
        )}

        <p
          className="long-text"
          dangerouslySetInnerHTML={{
            __html: templateData?.addendum?.[1]
              .replace('CUSTOMER_NAME', mapDefaultValues('company_name'))
              .replace('{{Signdate}}', mapDefaultValues('start_date'))
              .replace('BBE_DATE', mapDefaultValues('current_date'))
              .replace('THAD_SIGN', mapThadSignImg())
              .replace(
                'PRINTED_NAME',
                `${thadDetails?.firstName} ${thadDetails?.lastName} `,
              )
              .replace(
                'CONTRACT_ADDENDUM_CHANGES',
                contractAddendumChanges('addendum_changes'),
              ),
          }}
        />
      </AddendumParagraph>
    </div>
  );
}

Addendum.defaultProps = {
  formData: {},
  templateData: {},
  newAddendumData: {},
  setNewAddendum: () => {},
  showEditor: false,
  setUpdatedFormData: () => {},
  updatedFormData: {},
  contractAddendumDetails: {},
  originalData: {},
  selectedCurrency: '$ (USD)',
  getServicesAccordingToAccType: () => {},
  mapMonthlyServices: () => {},
  mapAdditionalMarketPlaces: () => {},
  mapMonthlyServiceTotal: () => {},
  mapVariableMonthlyService: () => {},
  displayMarketplaceName: () => {},
  setVatMarketplaces: () => {},
  discountData: [],
  displayBBELogo: () => {},
  startDate: '',
  firstMonthDate: '',
  secondMonthDate: '',
  thirdMonthDate: '',
  returnDspMonthlyDates: () => {},
  getAddedOrRemovedAMCServices: () => {},
};

Addendum.propTypes = {
  formData: shape({
    length: oneOfType([
      string,
      shape({
        value: string,
        label: string,
      }),
    ]),
    primary_marketplace: oneOfType([
      string,
      shape({
        fee: number,
        name: string,
        id: string,
      }),
    ]),
    customer_id: shape({
      address: string,
      city: string,
      state: string,
      zip_code: string,
    }),
  }),
  templateData: shape({
    addendum: arrayOf(string),
  }),
  newAddendumData: shape({
    addendum: string,
    id: string,
  }),
  setNewAddendum: func,
  showEditor: bool,
  setUpdatedFormData: func,
  updatedFormData: shape({
    additional_services: arrayOf(arrayOf(shape({}))),
    company_name: string,
    primary_marketplace: oneOfType([
      string,
      shape({
        fee: number,
        name: string,
        id: string,
      }),
    ]),
    additional_marketplaces: oneOfType([object, arrayOf(object)]),
    additional_monthly_services: oneOfType([object, arrayOf(object)]),
    additional_one_time_services: oneOfType([object, arrayOf(object)]),
    quantity: number,
  }),
  contractAddendumDetails: shape({}),
  originalData: shape({}),
  selectedCurrency: string,
  getServicesAccordingToAccType: func,
  mapMonthlyServices: func,
  mapAdditionalMarketPlaces: func,
  mapMonthlyServiceTotal: func,
  mapVariableMonthlyService: func,
  displayMarketplaceName: func,
  setVatMarketplaces: func,
  discountData: arrayOf(shape({})),
  displayBBELogo: func,
  startDate: instanceOf(Date),
  firstMonthDate: instanceOf(Date),
  secondMonthDate: instanceOf(Date),
  thirdMonthDate: instanceOf(Date),
  returnDspMonthlyDates: func,
  getAddedOrRemovedAMCServices: func,
};
