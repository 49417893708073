import styled from 'styled-components';

import Theme from '../theme/Theme';

const VisibleScrollableTable = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    margin-top: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${Theme.gray25} !important;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    background: ${Theme.gray6} !important;
  }
  table {
    min-width: ${(props) => props?.tableMinWidth};
  }

  .sticky-column {
    position: sticky;
    left: 0;
    z-index: 9;
    background: ${Theme.white};
    &.header {
      background: ${Theme.gray8};
    }
  }
  .border-right-gray {
    border-right: ${(props) =>
      props?.tableMinWidth !== null ? `2px solid ${Theme.gray25}` : 'none'};
  }
  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-ellipsis-200 {
    max-width: 200px;
  }
`;

export default VisibleScrollableTable;
