import React from 'react';

import ReactTooltip from 'react-tooltip';
import { DebounceInput } from 'react-debounce-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import Theme from '../../../../../../theme/Theme';
import { Icon } from '../../../../../../theme/Global';
import {
  ASIN,
  CAMPAIGN,
  asinStatiMessages,
  campaignSortingOptions,
  dspCampaignAsinToggleOptions,
} from '../../../../../../constants';
import {
  PageLoader,
  ToggleButton,
  InputSearchWithRadius,
  ViewData,
  DropdownOptions,
  DropdownSingleValue,
  DropdownIndicator,
  SingleSelectDropdown,
  GrayMessageBar,
} from '../../../../../../common';

function DspAsinAndCampaignFilters({
  dspAsinData,
  asinPerformanceLoader,
  handleDownloadReportEvents,
  downloadReportLoader,
  showAsinTooltipMessage,
  todaysDate,
  oneOctober2023,
  handleSortByFilter,
  handleCampaignAsinToogle,
  isCallDSPAsinApi,
  selectedCampaignAsinToggle,
  selectedSortByOrder,
  dspCampaignSearchQuery,
  setDspCampaignSearchQuery,
  handleCampaignSearch,
}) {
  // https://bbe.atlassian.net/browse/PDV-8930
  // Show dynamic messages in DSP CAMPAIGN & ASIN performance
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  const dspAsinMessage =
    dynamicMessages?.dsp_performance_asin?.placeholder || null;
  const dspCampaignMessage =
    dynamicMessages?.dsp_performance_campaign?.placeholder || null;

  // display react tooltip
  const displayReactTooltip = (id, place) => {
    return (
      <ReactTooltip
        className="asian-info-icon"
        id={id}
        aria-haspopup="true"
        place={place}
        effect="solid"
        style={{ maxWidth: '300px' }}
        html
      />
    );
  };

  // display input dearch with radius
  const displayInputSearchWithRadius = () => {
    return (
      <InputSearchWithRadius
        id="BT-order-customerlist-search-input"
        className="customer-list-header"
        data-cy="BT-dsp-campaignSearch-filter"
      >
        <DebounceInput
          // minLength={3}
          debounceTimeout={600}
          className="form-control search-filter form-control-info"
          placeholder="Search By Campaign Name"
          onChange={(event) => {
            handleCampaignSearch(event);
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleCampaignSearch(event);
            }
          }}
          value={dspCampaignSearchQuery || ''}
        />
        <FontAwesomeIcon
          icon="fa-regular fa-magnifying-glass"
          className="search-icon"
        />{' '}
        {dspCampaignSearchQuery !== '' ? (
          <FontAwesomeIcon
            icon="fal fa-times"
            className="close-icon cursor"
            onClick={() => setDspCampaignSearchQuery('')}
          />
        ) : null}
      </InputSearchWithRadius>
    );
  };

  // get dropdown components
  const getDropdDownComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: DropdownSingleValue,
      DropdownIndicator,
    };
  };

  // display campaign status filters
  const displayCampaignSortByFilter = () => {
    return (
      <div
        id="dsp-campaign-sorting-filter"
        style={{ position: 'relative', minWidth: '200px' }}
        data-cy="BT-dsp-campaignSorting-filter"
      >
        <SingleSelectDropdown
          filterId="BT-CampaignSortingFilter"
          isApiCall={isCallDSPAsinApi}
          dropdownOptions={campaignSortingOptions}
          selectedValue={selectedSortByOrder}
          onChangeHandler={handleSortByFilter}
          dropdownComponents={getDropdDownComponents}
          isSearchable={false}
        />
      </div>
    );
  };

  // display campaign/asin toggle button
  const displayCampaignAsinToggleButton = () => {
    return (
      <ToggleButton>
        <div className="days-container asin-toggle-button">
          <ul className="days-tab">
            {dspCampaignAsinToggleOptions?.map((option) => {
              return (
                <li
                  id={`BT-dsp-${option?.value}-toggle`}
                  data-cy={option?.dataCy}
                >
                  <input
                    className="d-none"
                    type="radio"
                    id={option?.id}
                    name={`${option?.value}RadioDefault`}
                    value={option?.value}
                    checked={option?.value === selectedCampaignAsinToggle}
                    onClick={() => handleCampaignAsinToogle(option?.value)}
                  />
                  <label htmlFor={option?.id}>{option?.label}</label>
                </li>
              );
            })}
          </ul>
        </div>
      </ToggleButton>
    );
  };

  // display download report button
  const displayDownloadReportButton = () => {
    return (
      <div
        className={`black-heading-title text-medium-font m-0 cursor ${
          dspAsinData?.length === 0 || asinPerformanceLoader ? 'disabled' : ''
        }`}
        role="presentation"
        onClick={() => {
          handleDownloadReportEvents();
        }}
        style={{ maxWidth: '150px' }}
      >
        {downloadReportLoader ? (
          <PageLoader
            component="sidebar-loader"
            type="button"
            color={Theme?.orange}
          />
        ) : (
          <>
            <FontAwesomeIcon
              className="mr-2"
              color={Theme?.orange}
              icon="far fa-arrow-to-bottom"
            />
            Download Report
          </>
        )}
      </div>
    );
  };

  // display asin performance header
  const displayAsinPerformanceHeader = () => {
    return (
      <>
        <ViewData>
          <div className="row">
            <div className="col-6 col-lg-3 mb-2 pr-0">
              <div className="black-heading-title d-inline-block mt-md-2">
                {selectedCampaignAsinToggle === CAMPAIGN
                  ? 'Campaign and ASIN Performance'
                  : 'ASIN Performance'}
              </div>
              {todaysDate < oneOctober2023 ? (
                <Icon>
                  <FontAwesomeIcon
                    data-for="info-icon"
                    data-tip={asinStatiMessages?.asinOneJuneMessage}
                    data-html
                    icon="fa-regular fa-circle-info"
                  />
                  {displayReactTooltip('info-icon', 'top')}
                </Icon>
              ) : null}
              {showAsinTooltipMessage ? (
                <Icon>
                  <FontAwesomeIcon
                    data-for="info-icon"
                    data-tip={asinStatiMessages?.asinTooltipMessage}
                    data-html
                    icon="fa-regular fa-circle-info"
                  />
                  {displayReactTooltip('info-icon', 'top')}
                </Icon>
              ) : null}
            </div>

            <div className="col-6 d-lg-none pl-0 text-right">
              {displayDownloadReportButton()}
            </div>
            <div className="col-12 col-lg-9">
              <div className="view-data-filter-list">
                <ul>
                  {selectedCampaignAsinToggle === CAMPAIGN ? (
                    <>
                      {/* According to Amber's feedback(pdv-8693), we don't have campaign status for the DSP 
                    so, instead of removing we just hide that using d-none. (because that might be use in future)  */}
                      <li>
                        <ul className="inline-list d-none">
                          <li>Sort By</li>
                          <li>{displayCampaignSortByFilter()}</li>
                        </ul>
                      </li>
                      <li>{displayInputSearchWithRadius()}</li>
                    </>
                  ) : null}

                  <li>{displayCampaignAsinToggleButton()}</li>
                  <li className="asin-download-button">
                    {displayDownloadReportButton()}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in DSP CAMPAIGN & ASIN performance */}
          {selectedCampaignAsinToggle === ASIN && dspAsinMessage ? (
            <GrayMessageBar message={dspAsinMessage} />
          ) : null}
          {selectedCampaignAsinToggle === CAMPAIGN && dspCampaignMessage ? (
            <GrayMessageBar message={dspCampaignMessage} />
          ) : null}
        </ViewData>
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return <div>{displayAsinPerformanceHeader()}</div>;
}

export default DspAsinAndCampaignFilters;

DspAsinAndCampaignFilters.defaultProps = {
  downloadReportLoader: false,
  isCallDSPAsinApi: false,
  asinPerformanceLoader: false,
  showAsinTooltipMessage: false,
  selectedCampaignAsinToggle: '',
  todaysDate: '',
  oneOctober2023: '',
  dspCampaignSearchQuery: '',
  dspAsinData: [],
  selectedSortByOrder: {},
  handleDownloadReportEvents: () => {},
  handleSortByFilter: () => {},
  handleCampaignAsinToogle: () => {},
  setDspCampaignSearchQuery: () => {},
  handleCampaignSearch: () => {},
};
DspAsinAndCampaignFilters.propTypes = {
  downloadReportLoader: bool,
  isCallDSPAsinApi: bool,
  asinPerformanceLoader: bool,
  showAsinTooltipMessage: bool,
  selectedCampaignAsinToggle: string,
  todaysDate: string,
  oneOctober2023: string,
  dspCampaignSearchQuery: string,
  dspAsinData: arrayOf(shape({})),
  selectedSortByOrder: shape({}),
  handleDownloadReportEvents: func,
  handleSortByFilter: func,
  handleCampaignAsinToogle: func,
  setDspCampaignSearchQuery: func,
  handleCampaignSearch: func,
};
