import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import {
  string,
  arrayOf,
  shape,
  oneOfType,
  object,
  bool,
  func,
} from 'prop-types';

import PlaybookSubTask from './PlaybookSubTask';
import PlaybookTaskAssignee from './PlaybookTaskAssignee';
import PlaybookTaskStatus from './PlaybookTaskStatus';
import { InnerCollapseRow } from '../PlaybookStyle';
import { JIRA_TICKET_BASE_URL } from '../../../../../constants';

function PlaybookTaskCollapse({
  ID,
  taskData,
  customerId,
  isCustomer,
  playbookTab,
  playbookAPICall,
  isUserAssignedToBP,
  renderCollapseDropdownIcon,
  renderPlaybookTaskMobileDataHTML,
  isCurrentMonthTask,
}) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const [showSubTaskCollapse, setShowSubTaskCollpase] = useState(false);
  const taskID = taskData?.id;
  const taskDataObj = {
    taskId: taskID,
    taskName: taskData?.task_name,
    jiraStatus: taskData?.jira_status,
  };
  // render  playbook task data
  const renderPlaybookTaskData = () => {
    return (
      <>
        <InnerCollapseRow
          key={taskData?.id}
          className={
            playbookTab === 'playbook'
              ? `second-layer-collapse`
              : 'first-layer-collapse'
          }
        >
          <td
            width={playbookTab === 'playbook' ? '40%' : '50%'}
            className={`${taskData?.sub_tasks?.length ? 'cursor' : null}`}
            role="presentation"
            onClick={() => {
              setShowSubTaskCollpase(!showSubTaskCollapse);
            }}
          >
            <span
              className={
                playbookTab === 'playbook'
                  ? 'curve-line curve-line-child'
                  : 'curve-line curve-line-parent'
              }
            />
            {taskData?.sub_tasks?.length
              ? renderCollapseDropdownIcon(showSubTaskCollapse)
              : null}
            {taskData?.jira_id !== null ? (
              <a
                href={`${JIRA_TICKET_BASE_URL}${taskData?.jira_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{
                    __html: taskData?.task_name,
                  }}
                />
              </a>
            ) : (
              <span>
                {' '}
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{
                    __html: taskData?.task_name,
                  }}
                />
              </span>
            )}
          </td>
          {playbookTab === 'playbook' ? (
            <td width="15%">{taskData?.marketplace}</td>
          ) : null}

          <td>{taskData?.team}</td>
          {isCustomer ? null : <td>{taskData?.due_date}</td>}
          {isCustomer ? null : (
            <td className={isCurrentMonthTask ? '' : 'disabled'}>
              <PlaybookTaskAssignee
                ID={ID}
                taskId={taskID}
                customerId={customerId}
                playbookTab={playbookTab}
                playbookAPICall={playbookAPICall}
                assigneeData={taskData?.assignee}
                isUserAssignedToBP={isUserAssignedToBP}
              />
            </td>
          )}
          <td className={isCurrentMonthTask ? '' : 'disabled'}>
            <PlaybookTaskStatus
              ID={ID}
              isCustomer={isCustomer}
              customerId={customerId}
              taskData={taskDataObj}
              playbookTab={playbookTab}
              playbookAPICall={playbookAPICall}
            />
          </td>
        </InnerCollapseRow>
      </>
    );
  };

  // render playbook sub task collapse
  const renderPlaybookSubTaskCollapse = () => {
    return taskData?.sub_tasks?.length && showSubTaskCollapse ? (
      <>
        {taskData?.sub_tasks?.map((item) => {
          return (
            <PlaybookSubTask
              ID={ID}
              subTaskData={item}
              isCustomer={isCustomer}
              customerId={customerId}
              playbookTab={playbookTab}
              playbookAPICall={playbookAPICall}
              isUserAssignedToBP={isUserAssignedToBP}
              renderPlaybookTaskMobileDataHTML={
                renderPlaybookTaskMobileDataHTML
              }
              isCurrentMonthTask={isCurrentMonthTask}
            />
          );
        })}
      </>
    ) : null;
  };

  // render playbook task table mobile fields
  const renderPlaybookTaskMobileData = (key) => {
    return (
      <>
        <div className="row" key={key}>
          <div
            className="col-12 mb-3"
            role="presentation"
            onClick={() => {
              setShowSubTaskCollpase(!showSubTaskCollapse);
            }}
          >
            <div className="label">Deliverables</div>
            {playbookTab === 'playbook' && taskData?.jira_id !== null ? (
              <a
                href={`${JIRA_TICKET_BASE_URL}${taskData?.jira_id}`}
                target="_blank"
                rel=" noopener noreferrer"
                className="normal-text-black capitalize"
              >
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{
                    __html: taskData?.task_name,
                  }}
                />
              </a>
            ) : (
              <div className="normal-text-black">
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{
                    __html: taskData?.task_name,
                  }}
                />
              </div>
            )}
          </div>
          {playbookTab === 'playbook'
            ? renderPlaybookTaskMobileDataHTML(
                'Marketplace',
                taskData?.marketplace,
              )
            : null}
          {renderPlaybookTaskMobileDataHTML('Team', taskData?.team)}
          {isCustomer
            ? null
            : renderPlaybookTaskMobileDataHTML('Due Date', taskData?.due_date)}
          {isCustomer
            ? null
            : renderPlaybookTaskMobileDataHTML(
                'Assignee',
                taskData?.assignee,
                taskID,
              )}
          {renderPlaybookTaskMobileDataHTML('Status', taskDataObj)}
        </div>
        {taskData?.sub_tasks?.length && showSubTaskCollapse
          ? taskData?.sub_tasks?.map((item) => {
              return (
                <PlaybookSubTask
                  ID={ID}
                  subTaskData={item}
                  isCustomer={isCustomer}
                  customerId={customerId}
                  playbookTab={playbookTab}
                  playbookAPICall={playbookAPICall}
                  isUserAssignedToBP={isUserAssignedToBP}
                  renderPlaybookTaskMobileDataHTML={
                    renderPlaybookTaskMobileDataHTML
                  }
                  isCurrentMonthTask={isCurrentMonthTask}
                />
              );
            })
          : null}
      </>
    );
  };

  // render playbook task collapse movile view
  const renderPlaybookTaskMobileView = () => {
    return (
      <>
        {taskData?.sub_tasks?.length ? (
          <div className="playbook-collapse-grid">
            <div
              className={`${taskData?.sub_tasks?.length ? 'cursor' : null}`}
              role="presentation"
              onClick={() => {
                setShowSubTaskCollpase(!showSubTaskCollapse);
              }}
            >
              {renderCollapseDropdownIcon(showSubTaskCollapse)}
            </div>
            <span className=" parent-collapse nested-collapse" />
            <div>{renderPlaybookTaskMobileData(taskID)}</div>
          </div>
        ) : (
          <div className="mt-2">{renderPlaybookTaskMobileData(taskID)}</div>
        )}
      </>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      {isTablet ? (
        <>
          {renderPlaybookTaskData()}
          {renderPlaybookSubTaskCollapse()}
        </>
      ) : (
        renderPlaybookTaskMobileView()
      )}
    </>
  );
}

export default PlaybookTaskCollapse;

PlaybookTaskCollapse.defaultProps = {
  taskData: [],
  playbookAPICall: () => {},
  renderCollapseDropdownIcon: () => {},
  renderPlaybookTaskMobileDataHTML: () => {},
};

PlaybookTaskCollapse.propTypes = {
  ID: string.isRequired,
  customerId: string.isRequired,
  playbookTab: string.isRequired,
  isCustomer: bool.isRequired,
  isUserAssignedToBP: bool.isRequired,
  isCurrentMonthTask: bool.isRequired,
  playbookAPICall: func,
  renderCollapseDropdownIcon: func,
  renderPlaybookTaskMobileDataHTML: func,
  taskData: oneOfType([arrayOf(shape()), object]),
};
