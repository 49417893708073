import React, { useState, useCallback } from 'react';

import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import { toast, ToastContainer } from 'react-toastify';
import { bool, func, shape, string } from 'prop-types';

import { Button, ModalBox, PageLoader } from '../../../../../common';
import { CloseIcon } from '../../../../../theme/images';
import { updateTaskStatus } from '../../../../../api/PlaybookApi';

function PlaybookChangeStatusModal({
  ID,
  customerId,
  playbookTab,
  afterSubmitAPI,
  changedTaskStatus,
  showChangeStatusModal,
  setShowChangeStatusModal,
  renderProgressLabelClassName,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [changeStatusLoader, setChangeStatusLoader] = useState(false);

  // save changed task status
  const saveTaskStatus = useCallback(() => {
    const payloadData = {
      task: changedTaskStatus?.taskId,
      status: changedTaskStatus?.newStatus,
    };
    let payloadData1 = {};
    let payloadData2 = {};
    let API = '';

    if (playbookTab === 'playbook') {
      payloadData1 = { ...payloadData, onboarding: 'false' };
      API = updateTaskStatus(ID, payloadData1);
    } else if (playbookTab === 'onboarding') {
      payloadData2 = {
        ...payloadData,
        customer: customerId,
        onboarding: 'true',
      };
      API = updateTaskStatus(ID, payloadData2);
    } else {
      API = '';
    }

    setChangeStatusLoader(true);
    // calling api and handeling response
    API.then((response) => {
      if (response?.status === 200) {
        toast.success(`Jira ticket status updated succesfully`);
        setChangeStatusLoader(false);
        afterSubmitAPI();
      } else if (response?.status === 400 || response?.status === 403) {
        toast.error(response?.data?.message || response?.data?.detail);
        afterSubmitAPI();
      } else {
        toast.error(`Oops! something went wrong.`);
        afterSubmitAPI();
      }
      setShowChangeStatusModal(false);
      setChangeStatusLoader(false);
    });
  }, [
    changedTaskStatus,
    playbookTab,
    ID,
    customerId,
    setShowChangeStatusModal,
    afterSubmitAPI,
  ]);

  // render task status html
  const renderTaskStatusHTML = (label, value) => {
    return (
      <div className="col-md-3 col-6">
        <p className="gray-normal-text my-2">{label}</p>
        <span className={renderProgressLabelClassName(value)}>{value}</span>
      </div>
    );
  };

  // render Playbook Change Status Modal
  const renderPlaybookChangeStatusModal = () => {
    return (
      <>
        <Modal
          id="change-status-modal"
          isOpen={showChangeStatusModal}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              maxWidth: '540px ',
              minHeight: '200px',
              width: '100% ',
              overlay: ' {zIndex: 1000}',
              // marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
            },
          }}
          ariaHideApp={false}
          contentLabel="Edit modal"
        >
          <img
            src={CloseIcon}
            alt="close"
            className="float-right cursor cross-icon"
            onClick={() => {
              afterSubmitAPI();
            }}
            role="presentation"
          />
          <ModalBox>
            <>
              <div className="modal-body pb-0">
                <h4>Confirm Task Status</h4>
                {changeStatusLoader ? (
                  <div className="mt-3 mb-4">
                    <PageLoader
                      component="performance-graph"
                      color="#FF5933"
                      type="detail"
                      width={40}
                      height={40}
                    />
                  </div>
                ) : (
                  <>
                    <div className="horizontal-line straight-line mt-3 mb-2" />
                    <div className="body-content modal-body-inner-container mb-3">
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <p className="gray-normal-text my-2">Tasks</p>
                          <p className="normal-text-black capitalize my-2">
                            {changedTaskStatus?.taskName}
                          </p>
                        </div>
                        {renderTaskStatusHTML(
                          'Previous Status',
                          changedTaskStatus?.prevStatus,
                        )}
                        {renderTaskStatusHTML(
                          'New Status',
                          changedTaskStatus?.newStatusLabel,
                        )}
                      </div>
                      <p className="normal-text-black mt-4 mb-2">
                        Note that clicking Confirm button below will change the
                        status of above task in Jira. Are you sure you want to
                        proceed?
                      </p>
                    </div>
                    <div className="horizontal-line straight-line mt-3 mb-2" />
                  </>
                )}
              </div>

              <div className="modal-footer">
                <div className="row">
                  <div
                    className={`col-md-6 col-12 col-sm-12 ${
                      isMobile ? 'mb-3' : ''
                    }`}
                  >
                    <Button
                      className={
                        changeStatusLoader
                          ? 'btn-primary on-boarding w-100 disabled'
                          : 'btn-primary on-boarding w-100'
                      }
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        saveTaskStatus();
                      }}
                    >
                      {changeStatusLoader ? (
                        <PageLoader color="#fff" type="button" />
                      ) : (
                        'Confirm'
                      )}
                    </Button>
                  </div>
                  <div className="col-md-6 col-12 col-sm-12">
                    <Button
                      className={
                        changeStatusLoader
                          ? 'btn-transparent w-100 disabled'
                          : 'btn-transparent w-100'
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowChangeStatusModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </>
          </ModalBox>
        </Modal>
      </>
    );
  };
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      {renderPlaybookChangeStatusModal()}
    </div>
  );
}

export default PlaybookChangeStatusModal;

PlaybookChangeStatusModal.defaultProps = {
  customerId: null,
  showChangeStatusModal: false,
  changedTaskStatus: [],
  afterSubmitAPI: () => {},
};

PlaybookChangeStatusModal.propTypes = {
  ID: string.isRequired,
  customerId: string,
  playbookTab: string.isRequired,
  showChangeStatusModal: bool,
  renderProgressLabelClassName: func.isRequired,
  setShowChangeStatusModal: func.isRequired,
  afterSubmitAPI: func,
  changedTaskStatus: shape(),
};
