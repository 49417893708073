import React, { useCallback, useState } from 'react';

import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import SponsoredAsinPerformance from './SponsoredAsinPerformance';
import SponsoredCampaignReport from './SponsoredCampaignReport';
import Theme from '../../../../../../theme/Theme';
import SpCampaignAsinFilters from './SpCampaignAsinFilters';
import {
  ASIN,
  CAMPAIGN,
  campaignSortingOptions,
} from '../../../../../../constants';

function SpAsinAndCampaignContainer({
  isCallSPAsinApi,
  customerId,
  childBrandId,
  selectedDF,
  accountType,
  currencySymbol,
  selectedMarketplace,
  sponsoredAdType,
  adState,
  setIsCallSPAsinApi,
}) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isCustom = selectedDF === 'custom';
  const [campaignSearchQuery, setCampaignSearchQuery] = useState('');
  const [selectedCampaignSortBy, setSelectedCampaignSortBy] = useState(
    campaignSortingOptions?.[0],
  );
  const [selectedAsinToggle, setSelectedAsinToggle] = useState(CAMPAIGN);
  const [orderBy, setOrderBy] = useState('ad_sales');
  const [orderByKey, setOrderByKey] = useState('-ad_sales');
  const [campaignDataLoader, setCampaignDataLoader] = useState(false);
  const [campaignAsinDataLoader, setCampaignAsinDataLoader] = useState(false);
  const [asinPerformanceLoader, setAsinPerformanceLoader] = useState(false);
  const [isAsinDataAvailable, setIsAsinDataAvailable] = useState(false);
  const [downloadReportLoader, setDownloadReportLoader] = useState(false);

  // bind sponsored ad asin performance data
  const bindSpAsinPerformanceData = useCallback((responseData) => {
    const tempAsinData = [];

    if (responseData?.length > 0) {
      responseData.forEach((item) => {
        tempAsinData.push({
          asin: item?.asin,
          productName: item?.product_name || 'Not available',
          pdpLink: item?.pdp_link,

          adSpendCurrent: item?.current?.ad_spend,
          adSalesCurrent: item?.current?.ad_sales,
          acosCurrent: item?.current?.acos,
          roasCurrent: item?.current?.roas,
          ctrCurrent: item?.current?.ctr,
          adConversionCurrent: item?.current?.ad_conversion,
          clicksCurrent: item?.current?.clicks,
          costPerClickCurrent: item?.current?.cost_per_click,
          salesPerClickCurrent: item?.current?.sales_per_click,
          impressionsCurrent: item?.current?.impressions,
          percentageNtbOrdersCurrent: item?.current?.ntb_orders_percent,
          percentageNtbSalesCurrent: item?.current?.ntb_sales_percent,
          ntbOrdersCurrent: item?.current?.ntb_orders,
          ntbSalesCurrent: item?.current?.ntb_sales,

          adSpendChangeInValue: item?.change?.ad_spend,
          adSalesChangeInValue: item?.change?.ad_sales,
          acosChangeInValue: item?.change?.acos,
          roasChangeInValue: item?.change?.roas,
          ctrChangeInValue: item?.change?.ctr,
          adConversionChangeInValue: item?.change?.ad_conversion,
          clicksChangeInValue: item?.change?.clicks,
          costPerClickChangeInValue: item?.change?.cost_per_click,
          salesPerClickChangeInValue: item?.change?.sales_per_click,
          impressionsChangeInValue: item?.change?.impressions,
          percentageNtbOrdersChangeInValue: item?.change?.ntb_orders_percent,
          percentageNtbSalesChangeInValue: item?.change?.ntb_sales_percent,
          ntbOrdersChangeInValue: item?.change?.ntb_orders,
          ntbSalesChangeInValue: item?.change?.ntb_sales,

          adSpendChangeInPercent: item?.per_change?.ad_spend,
          adSalesChangeInPercent: item?.per_change?.ad_sales,
          acosChangeInPercent: item?.per_change?.acos,
          roasChangeInPercent: item?.per_change?.roas,
          ctrChangeInPercent: item?.per_change?.ctr,
          adConversionChangeInPercent: item?.per_change?.ad_conversion,
          clicksChangeInPercent: item?.per_change?.clicks,
          costPerClickChangeInPercent: item?.per_change?.cost_per_click,
          salesPerClickChangeInPercent: item?.per_change?.sales_per_click,
          impressionsChangeInPercent: item?.per_change?.impressions,
          percentageNtbOrdersChangeInPercent:
            item?.per_change?.ntb_orders_percent,
          percentageNtbSalesChangeInPercent:
            item?.per_change?.ntb_sales_percent,
          ntbOrdersChangeInPercent: item?.per_change?.ntb_orders,
          ntbSalesChangeInPercent: item?.per_change?.ntb_sales,
        });
        return tempAsinData;
      });
    }
    return tempAsinData;
  }, []);

  // handle campaign search events
  const handleCampaignSearchEvents = (event) => {
    setCampaignSearchQuery(event.target.value);
  };

  // handle campaign status change events
  const handleCampaignStatusChangeEvents = (event) => {
    setSelectedCampaignSortBy(event);
  };

  // handle campaign asin toggle button events
  const handleCampaignAsinToggleEvents = (key) => {
    setSelectedAsinToggle(key);
    if (key === CAMPAIGN) {
      setSelectedCampaignSortBy(campaignSortingOptions?.[0]);
      setCampaignSearchQuery('');
    }
  };

  // display react tooltip
  const displayReactTooltip = (id, place) => {
    return (
      <ReactTooltip
        className="asian-info-icon"
        id={id}
        aria-haspopup="true"
        place={place}
        effect="solid"
        style={{ maxWidth: '300px' }}
        html
      />
    );
  };

  // display product name with tooltip
  const displayProductNameWithTooltip = (productName, index, link) => {
    const textLimit = 50;
    return (
      <div
        className={isTablet ? 'pr-3' : 'pr-4'}
        style={{ position: 'relative' }}
      >
        <div
          className="text-ellipsis text-ellipsis-200"
          data-tip={`<div style="max-width:500px">${productName}</div>`}
          data-for={`product${index}`}
        >
          {productName?.length > textLimit
            ? `${productName?.slice(0, textLimit)}...`
            : productName}
        </div>
        {displayReactTooltip(`product${index}`, 'top')}
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <FontAwesomeIcon
            className="ml-2 cursor"
            color={Theme.orange}
            icon="far fa-arrow-up-right-from-square"
            style={{ fontSize: '14px' }}
          />
        </a>
      </div>
    );
  };

  // display sponsored asin table
  const displaySponsoredAsinTable = (item, index, numberFormatter) => {
    const pdpLink = `https://www.${
      selectedMarketplace === 'all' || selectedMarketplace === 'Amazon.com'
        ? 'amazon.com'
        : selectedMarketplace?.toLowerCase()
    }/dp/${item?.asin}`;

    return (
      <tr key={item?.asin}>
        <td className="sticky-column border-right-gray">{item?.asin}</td>
        <td>
          {displayProductNameWithTooltip(item?.productName, index, pdpLink)}
        </td>
        <td>
          {numberFormatter(
            item?.adSpendCurrent,
            item?.adSpendChangeInValue,
            item?.adSpendChangeInPercent,
            2,
            isCustom,
            currencySymbol,
            '',
          )}
        </td>
        <td>
          {numberFormatter(
            item?.adSalesCurrent,
            item?.adSalesChangeInValue,
            item?.adSalesChangeInPercent,
            2,
            isCustom,
            currencySymbol,
            '',
          )}
        </td>
        <td>
          {numberFormatter(
            item?.acosCurrent,
            item?.acosChangeInValue,
            item?.acosChangeInPercent,
            2,
            isCustom,
            '',
            '%',
          )}
        </td>
        <td>
          {numberFormatter(
            item?.roasCurrent,
            item?.roasChangeInValue,
            item?.roasChangeInPercent,
            2,
            isCustom,
            currencySymbol,
            '',
          )}
        </td>
        <td>
          {numberFormatter(
            item?.ctrCurrent,
            item?.ctrChangeInValue,
            item?.ctrChangeInPercent,
            2,
            isCustom,
            '',
            '%',
          )}
        </td>
        <td>
          {numberFormatter(
            item?.adConversionCurrent,
            item?.adConversionChangeInValue,
            item?.adConversionChangeInPercent,
            2,
            isCustom,
            '',
            '%',
          )}
        </td>
        <td>
          {numberFormatter(
            item?.costPerClickCurrent,
            item?.costPerClickChangeInValue,
            item?.costPerClickChangeInPercent,
            2,
            isCustom,
            currencySymbol,
            '',
          )}
        </td>
        <td>
          {numberFormatter(
            item?.salesPerClickCurrent,
            item?.salesPerClickChangeInValue,
            item?.salesPerClickChangeInPercent,
            2,
            isCustom,
            currencySymbol,
            '',
          )}
        </td>
        <td>
          {numberFormatter(
            item?.clicksCurrent,
            item?.clicksChangeInValue,
            item?.clicksChangeInPercent,
            0,
            isCustom,
            '',
            '',
          )}
        </td>
        <td>
          {numberFormatter(
            item?.impressionsCurrent,
            item?.impressionsChangeInValue,
            item?.impressionsChangeInPercent,
            0,
            isCustom,
            '',
            '',
          )}
        </td>
        {selectedAsinToggle === ASIN &&
        sponsoredAdType === 'sponsored_display_campaign_report' ? (
          <>
            <td>
              {numberFormatter(
                item?.percentageNtbOrdersCurrent,
                item?.percentageNtbOrdersChangeInValue,
                item?.percentageNtbOrdersChangeInPercent,
                0,
                isCustom,
                '',
                '',
              )}
            </td>
            <td>
              {numberFormatter(
                item?.percentageNtbSalesCurrent,
                item?.percentageNtbSalesChangeInValue,
                item?.percentageNtbSalesChangeInPercent,
                0,
                isCustom,
                '',
                '',
              )}
            </td>
            <td>
              {numberFormatter(
                item?.ntbOrdersCurrent,
                item?.ntbOrdersChangeInValue,
                item?.ntbOrdersChangeInPercent,
                0,
                isCustom,
                '',
                '',
              )}
            </td>
            <td>
              {numberFormatter(
                item?.ntbSalesCurrent,
                item?.ntbSalesChangeInValue,
                item?.ntbSalesChangeInPercent,
                0,
                isCustom,
                '',
                '',
              )}
            </td>
          </>
        ) : null}
      </tr>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      <SpCampaignAsinFilters
        isAsinDataAvailable={isAsinDataAvailable}
        downloadReportLoader={downloadReportLoader}
        asinPerformanceLoader={
          selectedAsinToggle === CAMPAIGN
            ? campaignAsinDataLoader || campaignDataLoader
            : asinPerformanceLoader
        }
        accountType={accountType}
        sponsoredAdType={sponsoredAdType}
        selectedAsinToggle={selectedAsinToggle}
        campaignSearchQuery={campaignSearchQuery}
        selectedCampaignSortBy={selectedCampaignSortBy}
        displayReactTooltip={displayReactTooltip}
        setCampaignSearchQuery={setCampaignSearchQuery}
        setDownloadReportLoader={setDownloadReportLoader}
        handleCampaignSearchEvents={handleCampaignSearchEvents}
        handleCampaignAsinToggleEvents={handleCampaignAsinToggleEvents}
        handleCampaignStatusChangeEvents={handleCampaignStatusChangeEvents}
      />
      {/* ********** PDV-8693 Campaign report in sponsored ad section ********** */}
      {selectedAsinToggle === CAMPAIGN ? (
        <SponsoredCampaignReport
          isCallSPAsinApi={isCallSPAsinApi}
          campaignDataLoader={campaignDataLoader}
          downloadReportLoader={downloadReportLoader}
          campaignAsinDataLoader={campaignAsinDataLoader}
          orderBy={orderBy}
          orderByKey={orderByKey}
          customerId={customerId}
          childBrandId={childBrandId}
          selectedDF={selectedDF}
          accountType={accountType}
          currencySymbol={currencySymbol}
          campaignSearchQuery={campaignSearchQuery}
          selectedCampaignSortBy={selectedCampaignSortBy}
          selectedMarketplace={selectedMarketplace}
          sponsoredAdType={sponsoredAdType}
          adState={adState}
          setOrderBy={setOrderBy}
          setOrderByKey={setOrderByKey}
          setIsCallSPAsinApi={setIsCallSPAsinApi}
          setCampaignDataLoader={setCampaignDataLoader}
          setIsAsinDataAvailable={setIsAsinDataAvailable}
          setDownloadReportLoader={setDownloadReportLoader}
          setCampaignAsinDataLoader={setCampaignAsinDataLoader}
          displaySponsoredAsinTable={displaySponsoredAsinTable}
          bindSpAsinPerformanceData={bindSpAsinPerformanceData}
        />
      ) : null}
      {/* ********** PDV-8401 Asin performance in sponsored ad section ********** */}
      {selectedAsinToggle === ASIN ? (
        <SponsoredAsinPerformance
          isCallSPAsinApi={isCallSPAsinApi}
          downloadReportLoader={downloadReportLoader}
          asinPerformanceLoader={asinPerformanceLoader}
          customerId={customerId}
          childBrandId={childBrandId}
          selectedDF={selectedDF}
          accountType={accountType}
          currencySymbol={currencySymbol}
          selectedMarketplace={selectedMarketplace}
          sponsoredAdType={sponsoredAdType}
          adState={adState}
          setIsCallSPAsinApi={setIsCallSPAsinApi}
          setIsAsinDataAvailable={setIsAsinDataAvailable}
          setDownloadReportLoader={setDownloadReportLoader}
          setAsinPerformanceLoader={setAsinPerformanceLoader}
          displaySponsoredAsinTable={displaySponsoredAsinTable}
          bindSpAsinPerformanceData={bindSpAsinPerformanceData}
          displayProductNameWithTooltip={displayProductNameWithTooltip}
        />
      ) : null}
    </div>
  );
}

export default SpAsinAndCampaignContainer;

SpAsinAndCampaignContainer.defaultProps = {
  isCallSPAsinApi: false,
  childBrandId: null,
  selectedDF: '',
  accountType: '',
  currencySymbol: '$',
  selectedMarketplace: 'all',
  sponsoredAdType: 'all',
  adState: [],
  setIsCallSPAsinApi: () => {},
};

SpAsinAndCampaignContainer.propTypes = {
  isCallSPAsinApi: bool,
  childBrandId: string,
  selectedDF: string,
  accountType: string,
  currencySymbol: string,
  selectedMarketplace: string,
  sponsoredAdType: string,
  customerId: string.isRequired,
  adState: arrayOf(shape()),
  setIsCallSPAsinApi: func,
};
