import styled from 'styled-components';
import Theme from '../../../../theme/Theme';

export const DropDownFiltersList = styled.div`
  ul {
    margin: 0;
    padding: 0;

    @media only screen and (min-width: 320px) {
      li {
        display: block;
        &.download-report {
          display: none;
        }
      }
    }
    @media only screen and (min-width: 768px) {
      text-align: right;
      li {
        display: inline-block;
        width: 100%;
        max-width: 160px;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
        &.download-report {
          max-width: 150px;
          display: inline-block;
        }
      }
    }
  }
`;

export const SingleMonthFilter = styled.div`
  cursor: pointer;
  background-color: #ffffff !important;
  border-radius: 6px;
  border: 1px solid ${Theme.gray45};
  min-height: 40px;
  color: ${Theme.gray90};
  text-align: left;
  padding: 10px 8px 5px 8px;
`;

export const IventoryTableWrapper = styled.div`
  .horizontal-scroll-visible {
    overflow: auto;
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      margin-top: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${Theme.gray25} !important;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    .action-item {
      color: ${Theme.orange};
      margin-right: 16px;
      font-size: 14px;
      a {
        font-size: 14px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
