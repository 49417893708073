// React imports
import React from 'react';

// Third party imports
import { arrayOf, bool, func, shape } from 'prop-types';

const StrandedAndRestockInventoryMobileView = ({
  loader,
  strandedInventoryData,
  formatNumber,
  displayCompanyNameLogoAndBGS,
  displayMetricsValueWithChange,
  redirectingToCustomerDetailview,
}) => {
  // display metrics name, value with percent
  const displayMetricsNameAndValue = (label, value, percentValue) => {
    return (
      <>
        <div className="label">{label}</div>
        <div className="label-info label-bold">
          {displayMetricsValueWithChange(value, percentValue)}
        </div>
      </>
    );
  };

  // display inventory mobile view
  const displayInventoryMobileView = () => {
    return strandedInventoryData?.map((item) => {
      return (
        <>
          <div className="straight-line horizontal-line mb-3" />
          <div
            role="presentation"
            onClick={() => {
              redirectingToCustomerDetailview(item?.customer_id);
            }}
          >
            {displayCompanyNameLogoAndBGS(item)}
            <div className="row">
              <div className="col-7 mb-3">
                {displayMetricsNameAndValue(
                  'Understocked ASINs',
                  formatNumber(item?.understocked, '', '', true),
                  item?.understocked_change,
                )}
              </div>
              <div className="col-5 mb-3">
                {displayMetricsNameAndValue(
                  'Overstocked ASINs',
                  item?.overstocked,
                  item?.overstocked_change,
                )}
              </div>
              <div className="col-6 mb-3">
                {displayMetricsNameAndValue(
                  'Stranded ASINs',
                  item?.available,
                  item?.change,
                )}
              </div>
            </div>
          </div>
        </>
      );
    });
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div className={loader ? 'mb-1 disabled' : 'mb-1'}>
      {displayInventoryMobileView()}
    </div>
  );
};

export default StrandedAndRestockInventoryMobileView;

StrandedAndRestockInventoryMobileView.defaultProps = {
  loader: false,
  strandedInventoryData: [],
  formatNumber: () => {},
  displayCompanyNameLogoAndBGS: () => {},
  displayMetricsValueWithChange: () => {},
  redirectingToCustomerDetailview: () => {},
};

StrandedAndRestockInventoryMobileView.propTypes = {
  loader: bool,
  strandedInventoryData: arrayOf(shape({})),
  formatNumber: func,
  displayCompanyNameLogoAndBGS: func,
  displayMetricsValueWithChange: func,
  redirectingToCustomerDetailview: func,
};
