/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from 'react';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { arrayOf, bool, func, instanceOf, shape, string } from 'prop-types';

import BindSalesGraphResponse from './BindSalesGraphResponse';
import SalesPerformancePanel from './SalesPerformancePanel';
import BuyBoxPercentPanel from './BuyBoxPercentPanel';
import InventoryScoreGraph from './InventoryScoreGraph';
import BindSalesGoalsGraphResponse from './BindSalesGoalsGraphResponse';
import { SellerHealth } from './ASINPerformanceStyle';
import { getPerformance, getBuyBoxChartData } from '../../../../../../api';
import {
  CUSTOM,
  PARENT,
  REVENUEDSP,
  SALEPERFORMANCE,
  SELLERSMALL,
  dataScrappedMsg,
  metricsResponseKey,
  salesMetricsResponseKey,
} from '../../../../../../constants';
import {
  WhiteCard,
  CustomDateModal,
  DropdownIndicator,
  ViewData,
  DropDowns,
  GrayMessageBar,
  DropdownOptions,
  DropdownSingleValue,
} from '../../../../../../common';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const getSymbolFromCurrency = require('currency-symbol-map');
const _ = require('lodash');

const initialDisabledMetrics = {
  revenueTotal: true,
  revenueOrganic: true,
  revenuePpc: true,
  revenueDsp: true,
  unitsSold: true,
  traffic: true,
  conversion: true,
  adSpendTotal: true,
  adSpendPpc: true,
  adSpendDsp: true,
  adSpend: true,
  tacos: true,
};
export default function PerformanceReport({
  id,
  dspPpcFlag,
  selectedTimeFrame,
  setSelectedTimeFrame,
  selectedState,
  setSelectedState,
  currentDate,
  selectedMarketplace,
  setSelectedMarketplace,
  marketplaceOptions,
  currency,
  setCurrency,
  currencySymbol,
  setCurrencySymbol,
  salesMinDate,
  customDateError,
  bindErrorMessage,
  minDateLoader,
  goalsMarketplaceIds,
  goalsMarketplaceOptions,
  createMarektplaceIdsListOfGoals,
  childBrandOptions,
  selectedChildBrand,
  setSelectedChildBrand,
  displayAccountTypeFilter,
  renderDataAvailableChildList,
  getDataAvailabeleChildBrandList,
}) {
  const customerState = useSelector((state) => state.customerState.data);

  const mounted = useRef(false);
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const isSuperAdmin = userInfo?.role?.includes('Super Admin');
  const isDashboardDisabled =
    dspPpcFlag?.isHavingDsp && !dspPpcFlag?.isHavingPpc;
  const [bBChartData, setBBChartData] = useState([{}]);
  const [dspData, setDspData] = useState(null);
  const [isSPCustomDateApply, setIsSPCustomDateApply] = useState(
    selectedTimeFrame.value === CUSTOM,
  );
  const [isBBCustomDateApply, setIsBBCustomDateApply] = useState(false);
  const [salesChartData, setSalesChartData] = useState([]);
  const [performanceData, setPerformanceResponse] = useState({});
  const [goalsMetricData, setGoalsMetricData] = useState({});
  const [goalsToggle, setGoalsToggle] = useState(false);
  const [salesGoalsChartData, setSalesGoalChartData] = useState([]);
  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [showBBCustomDateModal, setShowBBCustomDateModal] = useState(false);
  const [groupBy, setGroupBy] = useState('daily');
  const [BBGroupBy, setBBGroupBy] = useState('daily');
  const [salesCurrentTotal, setSalesCurrentTotal] = useState({});
  const [salesPreviousTotal, setSalesPreviousTotal] = useState({});
  const [salesDifference, setSalesDifference] = useState({});
  const [salesGraphLoader, setSalesGraphLoader] = useState(false);
  const [bBGraphLoader, setBbGraphLoader] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [isAsinApiCallCustom, setIsAsinApiCallCustom] = useState(0);
  const [isDisabledTimeFrame, setIsDisabledTimeFrame] = useState(false);
  const [isMetricsDisabled, setIsMetricsDisabled] = useState(
    initialDisabledMetrics,
  );
  const [selectedSalesMetrics, setSelectedSalesMetrics] = useState({
    revenueTotal: true,
  });
  const [bBDailyFact, setBBDailyFact] = useState({
    value: 'week',
    label: 'Current 7 days',
  });
  const [pieData, setPieData] = useState([
    { name: 'Inventory', value: 'N/A' },
    { name: 'Total', value: 1000 },
  ]);

  const [customDate, setCustomDate] = useState({
    startDate: currentDate,
    endDate: currentDate,
  });
  const [BBstate, setBBState] = useState([
    {
      startDate: currentDate,
      endDate: currentDate,
      key: 'BBselection',
    },
  ]);
  const dashboardDynamicError = useSelector(
    (newState) => newState.userState?.showDynamicMsg,
  );
  const [selecteAdSpendSubType, setSelectedAdSpendSubType] = useState({
    adSpendTotal: true,
    adSpendPpc: false,
    adSpendDsp: false,
  });

  const bindSalesResponseData = useCallback(
    (response, timeFrame) => {
      // filterout the dsp current total, previous total, and diffrence
      if (response?.daily_facts?.current_sum) {
        setSalesCurrentTotal(response.daily_facts.current_sum);
      } else {
        setSalesCurrentTotal({});
      }
      if (response?.daily_facts?.previous_sum) {
        setSalesPreviousTotal(response.daily_facts.previous_sum);
      } else {
        setSalesPreviousTotal({});
      }
      if (response?.daily_facts?.difference_data) {
        setSalesDifference(response.daily_facts.difference_data);
      } else {
        setSalesDifference({});
      }

      // if we get total value of any metrics is 0, then disabled that metrics
      // we setting the disable flag for each metrics
      let localMetricDisabled = {};
      const resData = response?.daily_facts;
      _.keys(salesMetricsResponseKey).filter((option) => {
        if (
          (timeFrame === CUSTOM &&
            resData?.current_sum[metricsResponseKey[option]] === 0) ||
          (resData?.current_sum[metricsResponseKey[option]] === 0 &&
            resData?.previous_sum[metricsResponseKey[option]] === 0) ||
          (option === REVENUEDSP &&
            customerState?.is_active_dspagreement === false)
        ) {
          localMetricDisabled = { ...localMetricDisabled, [option]: true };
        } else if (timeFrame === 'year') {
          // PDV-6572 If year_to_date option selected then disabled PPC and DSP
          localMetricDisabled = { ...localMetricDisabled, revenuePpc: true };
          localMetricDisabled = { ...localMetricDisabled, revenueDsp: true };
        } else {
          localMetricDisabled = { ...localMetricDisabled, [option]: false };
        }
        return null;
      });

      // if we don't have data for PPC then we need to disabled the PPC and organic also.

      if (
        (timeFrame === CUSTOM &&
          resData?.current_sum[metricsResponseKey.revenuePpc] === 0) ||
        (resData?.current_sum[metricsResponseKey.revenuePpc] === 0 &&
          resData?.previous_sum[metricsResponseKey.revenuePpc] === 0)
      ) {
        localMetricDisabled = {
          ...localMetricDisabled,
          revenueOrganic: true,
        };
      }
      // Commented this code beacuase PDV-6723
      // if we get total value of any metrics is 0 when we change the Time frame, then disabled that Tiem frame filter
      setIsDisabledTimeFrame(false);
      // _.keys(salesMetricsResponseKey).filter((option) => {
      //   if (
      //     _.keys(selectedMetrics).includes(option) &&
      //     localMetricDisabled[option] === true
      //   ) {
      //     setIsDisabledTimeFrame(true);
      //   }
      //   return null;
      // });

      // Disabled tacos if selected date is less than 1st August 2022
      if (response?.daily_facts?.current?.length) {
        const isHavingDspPPC = {
          isHavingDsp: response?.having_contract_type?.is_having_dsp,
          isHavingPpc: response?.having_contract_type?.is_having_ppc,
        };

        const lastElement = response?.daily_facts?.current?.at(-1);
        if (
          timeFrame === CUSTOM &&
          new Date(dayjs(lastElement.report_date).format('MM D YYYY')) <
            new Date(dayjs('2022-08-01').format('MM D YYYY'))
        ) {
          const tacosData = response?.daily_facts?.current_sum?.tacos;

          if (isHavingDspPPC?.isHavingDsp && !isHavingDspPPC?.isHavingPpc) {
            if (tacosData === 0) {
              localMetricDisabled = {
                ...localMetricDisabled,
                tacos: true,
              };
            } else {
              localMetricDisabled = {
                ...localMetricDisabled,
                tacos: false,
              };
            }
          } else {
            localMetricDisabled = {
              ...localMetricDisabled,
              tacos: true,
            };
          }

          if (isHavingDspPPC?.isHavingDsp && isHavingDspPPC?.isHavingPpc) {
            localMetricDisabled = {
              ...localMetricDisabled,
              adSpendPpc: true,
              adSpendTotal: true,
            };
          }
        }
      }

      setIsMetricsDisabled(localMetricDisabled);
    },
    [customerState],
  );

  // determine goal target label
  const getGoalTargetLabel = useCallback((goalValue, metricValue) => {
    const twoPercentOfGoalValue = (goalValue * 2) / 100;
    const minValue = goalValue - twoPercentOfGoalValue;
    const maxValue = goalValue + twoPercentOfGoalValue;
    const goalTargetLabel =
      metricValue < minValue
        ? 'underspending'
        : metricValue > maxValue
        ? 'overspending'
        : 'on track';
    return goalTargetLabel;
  }, []);

  // calculate ad spend total goals
  const calculateAdSpendTotalGoal = useCallback(
    (goalMetric, metricsValue) => {
      const ppcGoalValue = goalMetric?.sponsored_ad_spend?.value || null;
      const dspGoalValue = goalMetric?.dsp_spends?.value || null;

      const totalGoalValue =
        ppcGoalValue !== null && dspGoalValue !== null
          ? ppcGoalValue + dspGoalValue
          : ppcGoalValue !== null && dspGoalValue === null
          ? ppcGoalValue
          : ppcGoalValue === null && dspGoalValue !== null
          ? dspGoalValue
          : null;
      let adSpendTotal = {};
      if (totalGoalValue !== null) {
        adSpendTotal = {
          sign:
            goalMetric?.sponsored_ad_spend?.sign ||
            goalMetric?.dsp_spends?.sign,
          target: getGoalTargetLabel(totalGoalValue, metricsValue),
          value: totalGoalValue,
        };
      }

      return adSpendTotal;
    },
    [getGoalTargetLabel],
  );

  const getBBData = useCallback(
    (
      marketplace,
      BBdailyFact,
      bBGroupBy,
      childBrandId,
      startDate = null,
      endDate = null,
    ) => {
      setIsApiCall(true);
      setBbGraphLoader(true);
      getBuyBoxChartData(
        id,
        marketplace,
        BBdailyFact,
        bBGroupBy,
        childBrandId,
        startDate,
        endDate,
      ).then((res) => {
        if (mounted.current) {
          if (res?.status === 200 && res.data?.bbep) {
            const avg =
              res.data.bbep
                .filter((record) => record.bbep)
                .reduce((acc, record) => acc + record.bbep, 0) /
                res.data.bbep.length || 0;

            const tempBBData = res.data.bbep.map((data) => {
              return {
                date: dayjs(data?.report_date).format('MMM D YYYY'),
                value: data?.bbep,
                avg: avg.toFixed(2),
              };
            });
            const total = tempBBData?.length ? tempBBData.length : 0;
            for (let i = 0; i <= Math.floor((total * 10) / 100); i += 1) {
              tempBBData.push({ avg: avg.toFixed(2) });
            }
            setBBChartData(tempBBData);
            setIsApiCall(false);
            setBbGraphLoader(false);
          } else {
            setBBChartData([{}]);
            setIsApiCall(false);
            setBbGraphLoader(false);
          }
        }
      });
    },
    [id],
  );

  // get seller sales performance data - metrics and line graph data
  const getSalesPerformanceData = useCallback(
    (
      selectedDailyFact,
      selectedGroupBy,
      marketplace,
      selectedMetrics,
      goalsMarketplaces,
      onGroupByClick = false,
      childBrand,
      startDate = null,
      endDate = null,
    ) => {
      setIsApiCall(true);
      setSalesGraphLoader(true);
      getDataAvailabeleChildBrandList(
        id,
        'sales',
        SELLERSMALL,
        marketplace,
        null,
        selectedDailyFact,
        startDate,
        endDate,
      );
      getPerformance(
        id,
        selectedDailyFact,
        selectedGroupBy,
        marketplace,
        goalsMarketplaces,
        childBrand,
        startDate,
        endDate,
      ).then((res) => {
        if (mounted.current) {
          if (res?.status === 200) {
            if (res?.data?.daily_facts) {
              setPerformanceResponse(res.data);

              const salesGraphData = BindSalesGraphResponse(
                res?.data,
                selectedDailyFact,
              );
              setSalesChartData(salesGraphData);
              bindSalesResponseData(res?.data, selectedDailyFact);

              // get goal matrics data
              if (!onGroupByClick) {
                const adSpendTotalMetricsValue =
                  res?.data?.daily_facts?.current_sum?.ad_spend_total;
                const goalMetric = res?.data?.daily_facts?.goal_metric;
                const goalMetricLength = Object.keys(goalMetric)?.length;

                if (goalMetricLength > 0) {
                  const adSpendTotalGoals = calculateAdSpendTotalGoal(
                    goalMetric,
                    adSpendTotalMetricsValue,
                  );
                  const tempGoalMetricData =
                    Object.keys(adSpendTotalGoals)?.length > 0
                      ? {
                          ...goalMetric,
                          ad_spend_total: adSpendTotalGoals,
                        }
                      : goalMetric;

                  setGoalsMetricData(tempGoalMetricData);
                } else {
                  setGoalsMetricData({});
                }
              }
              if (selectedDailyFact === CUSTOM) {
                const salesGoalsGraphData = BindSalesGoalsGraphResponse(
                  res?.data,
                  selectedDailyFact,
                  selectedMetrics,
                );
                setSalesGoalChartData(salesGoalsGraphData);
              }
            } else {
              setGoalsMetricData({});
              setSalesChartData([]);
              setSalesGoalChartData([]);
              setIsMetricsDisabled({
                revenueTotal: true,
                revenueOrganic: true,
                revenuePpc: true,
                revenueDsp: true,
                unitsSold: true,
                traffic: true,
                conversion: true,
                adSpendTotal: true,
                adSpendPpc: true,
                adSpendDsp: true,
                tacos: true,
              });
            }

            if (res?.data?.pf_oi_is?.length) {
              const lastUpdated = res?.data?.pf_oi_is[0]?.latest_date;
              res.data.pf_oi_is[0].latest_date =
                dayjs(lastUpdated).format('MMM DD YYYY');

              setDspData(res.data.pf_oi_is[0]);
              const ipiValue = parseFloat(
                res?.data?.pf_oi_is[0]?.inventory_performance_index,
              );
              if (Number.isNaN(ipiValue)) {
                setPieData([
                  {
                    name: 'Inventory',
                    value: 'N/A',
                  },
                  {
                    name: 'Total',
                    value: 1000,
                  },
                ]);
              } else {
                setPieData([
                  {
                    name: 'Inventory',
                    value: ipiValue,
                  },
                  {
                    name: 'Total',
                    value: 1000 - ipiValue,
                  },
                ]);
              }
            } else {
              setDspData(null);
              setPieData([
                { name: 'Inventory', value: 'N/A' },
                { name: 'Total', value: 1000 },
              ]);
            }
            setIsApiCall(false);
            setSalesGraphLoader(false);
          } else {
            setIsApiCall(false);
            setSalesGraphLoader(false);
          }
        }
      });
    },

    [
      getDataAvailabeleChildBrandList,
      id,
      bindSalesResponseData,
      calculateAdSpendTotalGoal,
    ],
  );

  // function call only when selected option is custom
  const checkDifferenceBetweenDates = (
    startDate,
    endDate,
    flag = null,
    marketplace = selectedMarketplace?.value,
  ) => {
    const temp = 'daily';
    let sd = startDate;
    let ed = endDate;
    setGroupBy(temp);
    sd = `${startDate.getDate()}-${
      startDate.getMonth() + 1
    }-${startDate.getFullYear()}`;
    ed = `${endDate.getDate()}-${
      endDate.getMonth() + 1
    }-${endDate.getFullYear()}`;
    setCustomDate({ startDate, endDate });

    getSalesPerformanceData(
      flag,
      temp,
      marketplace,
      selectedSalesMetrics,
      goalsMarketplaceIds,
      false,
      selectedChildBrand,
      sd,
      ed,
    );
  };

  useEffect(() => {
    mounted.current = true;
    if (
      mounted.current &&
      (selectedMarketplace?.value || goalsMarketplaceIds?.length)
    ) {
      if (selectedTimeFrame.value === CUSTOM) {
        setIsSPCustomDateApply(true);
        checkDifferenceBetweenDates(
          selectedState[0].startDate,
          selectedState[0].endDate,
          CUSTOM,
          selectedMarketplace?.value,
        );
      } else {
        getSalesPerformanceData(
          selectedTimeFrame.value,
          groupBy,
          selectedMarketplace?.value,
          selectedSalesMetrics,
          goalsMarketplaceIds,
          false,
          selectedChildBrand,
        );
      }
      getBBData(
        selectedMarketplace?.value,
        bBDailyFact.value,
        BBGroupBy || 'daily',
        selectedChildBrand,
      );
    }
    return () => {
      mounted.current = false;
    };
  }, [selectedMarketplace, selectedChildBrand, goalsMarketplaceIds]);

  const renderCustomDateSubLabel = (props, flag) => {
    if (flag === 'sp') {
      if (selectedTimeFrame.value === CUSTOM && isSPCustomDateApply) {
        return `From - ${dayjs(selectedState[0].startDate).format(
          'MMM D, YYYY',
        )}  To - ${dayjs(selectedState[0].endDate).format('MMM D, YYYY')}`;
      }
    } else if (bBDailyFact.value === CUSTOM && isBBCustomDateApply) {
      return `From - ${dayjs(BBstate[0].startDate).format(
        'MMM D, YYYY',
      )}  To - ${dayjs(BBstate[0].endDate).format('MMM D, YYYY')}`;
    }

    return props.data.sub;
  };

  const getDays = (startDate, endDate) => {
    const diffTime = Math.abs(startDate - endDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const BBYearAndCustomDateFilter = (startDate, endDate, value) => {
    let temp = '';

    let sd = startDate;
    let ed = endDate;
    const diffDays = getDays(startDate, endDate);

    if (diffDays <= 60) {
      temp = 'daily';
      setBBGroupBy('daily');
    } else if (diffDays > 60 && diffDays <= 180) {
      temp = 'weekly';
      setBBGroupBy('weekly');
    } else if (diffDays > 180) {
      temp = 'monthly';
      setBBGroupBy('monthly');
    }

    if (value === CUSTOM) {
      sd = `${startDate.getDate()}-${
        startDate.getMonth() + 1
      }-${startDate.getFullYear()}`;
      ed = `${endDate.getDate()}-${
        endDate.getMonth() + 1
      }-${endDate.getFullYear()}`;
      getBBData(
        selectedMarketplace?.value,
        value,
        temp,
        selectedChildBrand,
        sd,
        ed,
      );
    } else {
      getBBData(selectedMarketplace?.value, value, temp, selectedChildBrand);
    }
  };

  const handleSalesDailyFact = (event) => {
    const { value } = event;
    if (value !== CUSTOM) {
      setGoalsToggle(false);
      let groupByOption = 'daily';
      if (value === 'year') {
        groupByOption = 'weekly';
      }
      setIsSPCustomDateApply(false);
      setSelectedTimeFrame(event);
      setSelectedState([
        {
          startDate: currentDate,
          endDate: currentDate,
          key: 'selection',
        },
      ]);
      setGroupBy(groupByOption);

      getSalesPerformanceData(
        value,
        groupByOption,
        selectedMarketplace?.value,
        selectedSalesMetrics,
        goalsMarketplaceIds,
        false,
        selectedChildBrand,
      );
      bindErrorMessage(SALEPERFORMANCE, salesMinDate);
      setIsAsinApiCallCustom(0);
    } else {
      setShowCustomDateModal(true);
    }
  };

  const handleBBDailyFact = (event) => {
    const { value } = event;

    if (value !== CUSTOM) {
      setBBDailyFact(event);
      setIsBBCustomDateApply(false);
      setBBState([
        {
          startDate: currentDate,
          endDate: currentDate,
          key: 'BBselection',
        },
      ]);
      getBBData(selectedMarketplace?.value, value, 'daily', selectedChildBrand);
      setBBGroupBy('daily');
    } else if (value === CUSTOM) {
      setShowBBCustomDateModal(true);
    }
  };

  const handleMarketplaceOptions = (event) => {
    if (event.value !== selectedMarketplace?.value) {
      setSelectedMarketplace(event);
      createMarektplaceIdsListOfGoals(event, goalsMarketplaceOptions);
      setCurrency(event.currency);
      setCurrencySymbol(getSymbolFromCurrency(event.currency));
    }
  };

  const handleChildBrandOptions = (event) => {
    if (event.value !== selectedChildBrand?.value) {
      setSelectedChildBrand(event);
    }
  };

  const handleGroupBy = (value) => {
    if (value !== groupBy) {
      setGroupBy(value);
      if (selectedTimeFrame.value === CUSTOM) {
        let sd = customDate.startDate;
        let ed = customDate.endDate;

        sd = `${customDate.startDate.getDate()}-${
          customDate.startDate.getMonth() + 1
        }-${customDate.startDate.getFullYear()}`;
        ed = `${customDate.endDate.getDate()}-${
          customDate.endDate.getMonth() + 1
        }-${customDate.endDate.getFullYear()}`;

        getSalesPerformanceData(
          selectedTimeFrame.value,
          value,
          selectedMarketplace?.value,
          selectedSalesMetrics,
          goalsMarketplaceIds,
          true,
          selectedChildBrand,
          sd,
          ed,
        );
      } else {
        getSalesPerformanceData(
          selectedTimeFrame.value,
          value,
          selectedMarketplace?.value,
          selectedSalesMetrics,
          goalsMarketplaceIds,
          true,
          selectedChildBrand,
        );
      }
    }
  };

  const applyCustomDate = (flag) => {
    if (flag === 'BBDate') {
      if (bBDailyFact.value !== CUSTOM)
        setBBDailyFact({
          value: CUSTOM,
          label: 'Custom Range',
          sub: 'Select start & end date',
        });
      BBYearAndCustomDateFilter(
        BBstate[0].startDate,
        BBstate[0].endDate,
        CUSTOM,
      );
      setShowBBCustomDateModal(false);
      setIsBBCustomDateApply(true);
    } else {
      if (selectedTimeFrame.value !== CUSTOM)
        setSelectedTimeFrame({
          value: CUSTOM,
          label: 'Custom Range',
          sub: 'Select start & end date',
        });
      checkDifferenceBetweenDates(
        selectedState[0].startDate,
        selectedState[0].endDate,
        CUSTOM,
      );
      bindErrorMessage(SALEPERFORMANCE, salesMinDate);
      setShowCustomDateModal(false);
      setIsSPCustomDateApply(true);
      setIsAsinApiCallCustom(Math.random());
    }
  };

  // get dropdown components
  const getDropdDownComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: DropdownSingleValue,
      DropdownIndicator,
    };
  };

  const renderSalesPerformanceFilter = () => {
    return (
      <WhiteCard className="mb-3">
        <ViewData>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="view-data-for mt-2">View data for:</div>
            </div>
            <div className="col-12 col-md-8">
              <div className="row">
                {customerState?.account_type === PARENT ? (
                  <div className="col-md-4 col-12 pl-md-0 mb-3 mb-xl-0">
                    {DropDowns(
                      'BT-salesperformancedata-childBrandFilter',
                      'cursor',
                      childBrandOptions,
                      selectedChildBrand?.label,
                      getDropdDownComponents,
                      '',
                      handleChildBrandOptions,
                      salesGraphLoader || bBGraphLoader,
                      null,
                      selectedChildBrand,
                    )}
                  </div>
                ) : (
                  <div className="col-md-4 col-12 pl-md-0 mb-3 mb-xl-0" />
                )}
                <div className="col-md-4 col-12 pl-md-0 mb-3 mb-xl-0">
                  {displayAccountTypeFilter(isApiCall)}
                </div>
                {dspPpcFlag?.isHavingDsp && !dspPpcFlag?.isHavingPpc ? null : (
                  <div className="col-md-4 col-12 pl-md-0 mb-3 mb-xl-0">
                    {DropDowns(
                      'BT-salesperformancedata-countryfilter',
                      'cursor',
                      marketplaceOptions,
                      selectedMarketplace?.label,
                      getDropdDownComponents,
                      '',
                      handleMarketplaceOptions,
                      salesGraphLoader || bBGraphLoader || minDateLoader,
                      null,
                      selectedMarketplace,
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </ViewData>
      </WhiteCard>
    );
  };

  const renderPositiveFeedbackPanel = () => {
    return (
      <div className="col-md-4 col-sm-12 mb-3 border-line">
        <div className="black-heading-title mt-0">Positive Feedback</div>
        <div className="sub-heading extra-small-text text-medium pt-1  mb-3">
          {dataScrappedMsg}
        </div>
        <div className="seller-health positive">
          {dspData?.feedback_30
            ? dspData.feedback_30 === 'nan'
              ? 'N/A'
              : `${dspData?.feedback_30}%`
            : 'N/A'}
        </div>
        <div className="seller-update mb-3">Last 30 days</div>
        <div className="seller-health positive ">
          {dspData?.feedback_365
            ? dspData.feedback_365 === 'nan'
              ? 'N/A'
              : `${dspData?.feedback_365}%`
            : 'N/A'}
        </div>
        <div className="seller-update mb-5">Last 12 months</div>
        <div className="last-update ">Last updated: {dspData?.latest_date}</div>
      </div>
    );
  };

  const renderOrderIssuesPanel = () => {
    return (
      <div className="col-md-4 col-sm-12 mb-3">
        {' '}
        <div className="black-heading-title mt-0 ">Order Issues</div>
        <div className="sub-heading extra-small-text text-medium pt-1  mb-3">
          {dataScrappedMsg}
        </div>
        <div className="seller-health">
          {dspData?.order_defect_fba
            ? dspData.order_defect_fba === 'nan'
              ? 'N/A'
              : `${dspData.order_defect_fba}`
            : 'N/A'}
        </div>
        <div className="seller-update mb-3">Order Defect Rate</div>
        <div className="seller-health  ">
          {dspData?.policy_issues
            ? dspData.policy_issues === 'nan'
              ? 'N/A'
              : dspData.policy_issues
            : 'N/A'}
        </div>
        <div className="seller-update mb-5">Policy Violations</div>
        <div className="last-update ">Last updated: {dspData?.latest_date}</div>
      </div>
    );
  };

  const renderInventoryScorePanel = () => {
    return (
      <div className="col-md-4 col-sm-12 mb-3 border-line">
        <div className="black-heading-title mt-0">Inventory Score (IPI)</div>
        <div className="sub-heading extra-small-text text-medium pt-1  mb-3">
          {dataScrappedMsg}
        </div>
        <InventoryScoreGraph
          chartId="inventory-score-graph"
          pieData={pieData}
          dspData={dspData}
        />
        <div className="average">
          {pieData?.length && !Number.isNaN(pieData[0].value)
            ? pieData[0].value
            : 'N/A'}
          <div className="out-off">Out of 1000</div>
        </div>
        <div className="last-update mt-3 ">
          Last updated: {dspData?.latest_date}
        </div>
      </div>
    );
  };

  const returnMinDate = () => {
    return salesMinDate === null
      ? new Date('01/01/1901')
      : new Date(salesMinDate);
  };

  const daysDiffForSales = dayjs(currentDate).diff(dayjs(salesMinDate), 'days');

  useEffect(() => {
    if (
      new Date(dayjs(selectedState[0].endDate).format('MM D YYYY')) <
      new Date(dayjs('2022-08-01').format('MM D YYYY'))
    ) {
      if (performanceData?.having_contract_type?.is_having_dsp) {
        setSelectedAdSpendSubType({
          adSpendTotal: false,
          adSpendPpc: false,
          adSpendDsp: true,
        });

        setIsMetricsDisabled({
          ...isMetricsDisabled,
          adSpendTotal: false,
          adSpendPpc: true,
          adSpendDsp: false,
        });
      }
      if (performanceData?.having_contract_type?.is_having_ppc) {
        setSelectedAdSpendSubType({
          adSpendTotal: true,
          adSpendPpc: false,
          adSpendDsp: false,
        });

        setIsMetricsDisabled({
          ...isMetricsDisabled,
          adSpendTotal: false,
          adSpendPpc: false,
          adSpendDsp: true,
        });
      }
      if (
        performanceData?.having_contract_type?.is_having_ppc &&
        performanceData?.having_contract_type?.is_having_dsp
      ) {
        setSelectedAdSpendSubType({
          adSpendTotal: false,
          adSpendPpc: false,
          adSpendDsp: true,
        });

        setIsMetricsDisabled({
          ...isMetricsDisabled,
          adSpendPpc: true,
          adSpendTotal: true,
          adSpendDsp: false,
        });
      }
    } else {
      if (performanceData?.having_contract_type?.is_having_dsp) {
        setSelectedAdSpendSubType({
          adSpendTotal: false,
          adSpendPpc: false,
          adSpendDsp: true,
        });

        setIsMetricsDisabled({
          ...isMetricsDisabled,
          adSpendTotal: true,
          adSpendPpc: true,
          adSpendDsp: false,
        });
      }
      if (performanceData?.having_contract_type?.is_having_ppc) {
        setSelectedAdSpendSubType({
          adSpendTotal: false,
          adSpendPpc: true,
          adSpendDsp: false,
        });

        setIsMetricsDisabled({
          ...isMetricsDisabled,
          adSpendTotal: true,
          adSpendPpc: false,
          adSpendDsp: true,
        });
      }
      if (
        performanceData?.having_contract_type?.is_having_ppc &&
        performanceData?.having_contract_type?.is_having_dsp
      ) {
        setSelectedAdSpendSubType({
          adSpendTotal: true,
          adSpendPpc: false,
          adSpendDsp: false,
        });

        setIsMetricsDisabled({
          ...isMetricsDisabled,
          adSpendTotal: false,
          adSpendPpc: false,
          adSpendDsp: false,
        });
      }
    }
  }, [selectedState]);

  return (
    <>
      <div className={isDashboardDisabled ? 'disabled' : ''}>
        {renderSalesPerformanceFilter()}
        {/* ********** PDV-7917 - Expose AMC and other Sections to BPs ********** */}
        {/* ********** PLEASE DO NOT REMOVE BELOW CODE. ********** */}
        {/* {!userInfo?.role?.includes('Customer') ||
        (userInfo?.role?.includes('Customer') &&
          customerState?.display_sales_performance) ? ( */}
        <SalesPerformancePanel
          isDashboardDisabled={isDashboardDisabled}
          renderCustomDateSubLabel={renderCustomDateSubLabel}
          selectedSalesMetrics={selectedSalesMetrics}
          currencySymbol={currencySymbol}
          setSelectedSalesMetrics={setSelectedSalesMetrics}
          handleSalesDailyFact={handleSalesDailyFact}
          isApiCall={isApiCall || minDateLoader}
          selectedTimeFrame={selectedTimeFrame}
          state={selectedState}
          handleGroupBy={handleGroupBy}
          groupBy={groupBy}
          salesCurrentTotal={salesCurrentTotal}
          salesDifference={salesDifference}
          salesPreviousTotal={salesPreviousTotal}
          currency={currency}
          salesGraphLoader={salesGraphLoader}
          salesChartData={salesChartData}
          isAppliedCustom={isSPCustomDateApply}
          id={id}
          marketplace={selectedMarketplace?.value}
          isMetricsDisabled={isMetricsDisabled}
          setIsDisabledTimeFrame={setIsDisabledTimeFrame}
          customDateError={customDateError}
          daysDiff={daysDiffForSales}
          currentDate={currentDate}
          goalsMetricData={goalsMetricData}
          salesGoalsChartData={salesGoalsChartData}
          goalsToggle={goalsToggle}
          setGoalsToggle={setGoalsToggle}
          renderDataAvailableChildList={renderDataAvailableChildList}
          selectedChildBrand={selectedChildBrand}
          isAsinApiCallCustom={isAsinApiCallCustom}
          isDisabledTimeFrame={isDisabledTimeFrame}
          selecteAdSpendSubType={selecteAdSpendSubType}
          setSelectedAdSpendSubType={setSelectedAdSpendSubType}
        />
        {/* ) : null} */}

        {/* ********** PDV-7917 - Expose AMC and other Sections to BPs ********** */}
        {/* ********** PLEASE DO NOT REMOVE BELOW CODE. ********** */}
        {/* {!userInfo?.role?.includes('Customer') ||
        (userInfo?.role?.includes('Customer') &&
          customerState?.display_seller_health) ? ( */}
        {/* https://bbe.atlassian.net/browse/PDV-9229 - hide seller health
            data for user other than super Admin */}
        <>
          {isSuperAdmin ? (
            <WhiteCard className="mt-3">
              <p className="black-heading-title">Seller Health</p>
              <div className="row">
                <div className="col-12">
                  {dashboardDynamicError?.sales_performance_seller
                    ?.seller_health ? (
                    <GrayMessageBar
                      message={
                        dashboardDynamicError?.sales_performance_seller
                          ?.seller_health
                      }
                    />
                  ) : null}
                </div>
              </div>

              <SellerHealth>
                <div className="row ">
                  {renderInventoryScorePanel()}
                  {renderPositiveFeedbackPanel()}
                  {renderOrderIssuesPanel()}
                </div>
              </SellerHealth>
            </WhiteCard>
          ) : null}
          <BuyBoxPercentPanel
            bBChartData={bBChartData}
            bBGraphLoader={bBGraphLoader}
            bBDailyFact={bBDailyFact}
            handleBBDailyFact={handleBBDailyFact}
            isApiCall={isApiCall}
            setShowBBCustomDateModal={setShowBBCustomDateModal}
            dspData={dspData}
            showBBCustomDateModal={showBBCustomDateModal}
            BBstate={BBstate}
            setBBState={setBBState}
            applyCustomDate={applyCustomDate}
            currentDate={currentDate}
            renderCustomDateSubLabel={renderCustomDateSubLabel}
          />
        </>
        {/* ) : null} */}
      </div>
      {/* custom date modal for sale performance graph */}
      <CustomDateModal
        id="BT-performancereport-daterange-SPdate"
        isOpen={showCustomDateModal}
        ranges={selectedState}
        onClick={() => {
          setShowCustomDateModal(false);
          if (selectedTimeFrame.value !== CUSTOM) {
            setSelectedState([
              {
                startDate: currentDate,
                endDate: currentDate,
                key: 'selection',
              },
            ]);
          }
        }}
        onChange={(item) => {
          setSelectedState([item.selection]);
        }}
        onApply={() => {
          applyCustomDate('SPDate');
        }}
        currentDate={currentDate}
        minDate={returnMinDate()}
      />
    </>
  );
}

PerformanceReport.defaultProps = {
  id: '',
  data: {},
  minDateLoader: false,
  dspPpcFlag: {},
  selectedTimeFrame: {},
  setSelectedTimeFrame: () => {},
  selectedState: [],
  setSelectedState: () => {},
  currentDate: new Date(),
  selectedMarketplace: {},
  customDateError: {},
  childBrandOptions: [],
  selectedChildBrand: {},
  setSelectedMarketplace: () => {},
  createMarektplaceIdsListOfGoals: () => {},
  marketplaceOptions: [],
  salesMinDate: '',
  currency: '',
  setCurrency: () => {},
  currencySymbol: '',
  setCurrencySymbol: () => {},
  bindErrorMessage: () => {},
  goalsMarketplaceIds: [],
  goalsMarketplaceOptions: [],
  setSelectedChildBrand: () => {},
  displayAccountTypeFilter: () => {},
  renderDataAvailableChildList: () => {},
  getDataAvailabeleChildBrandList: () => {},
};

PerformanceReport.propTypes = {
  id: string,
  data: shape({}),
  minDateLoader: bool,
  dspPpcFlag: shape({}),
  selectedTimeFrame: shape({}),
  setSelectedTimeFrame: func,
  selectedState: arrayOf(shape()),
  setSelectedState: func,
  currentDate: instanceOf(Date),
  selectedMarketplace: shape({}),
  createMarektplaceIdsListOfGoals: func,
  customDateError: shape({}),
  selectedChildBrand: shape({}),
  setSelectedMarketplace: func,
  marketplaceOptions: arrayOf(shape({})),
  goalsMarketplaceOptions: arrayOf(shape({})),
  childBrandOptions: arrayOf(shape({})),
  salesMinDate: string,
  currency: string,
  setCurrency: func,
  currencySymbol: string,
  setCurrencySymbol: func,
  bindErrorMessage: func,
  goalsMarketplaceIds: arrayOf(string),
  setSelectedChildBrand: func,
  displayAccountTypeFilter: func,
  renderDataAvailableChildList: func,
  getDataAvailabeleChildBrandList: func,
};
