import styled from 'styled-components';
import Theme from '../theme/Theme';

const ViewData = styled.div`
  .view-data-for {
    margin-right: 60px;
    font-weight: normal;
    color: ${Theme.black};
    font-size: ${Theme.extraMedium};
    font-family: ${Theme.baseFontFamily};
    width: 100%;
  }
  .sort-label {
    &.text-md-right {
      text-align: right;
    }
  }

  @media only screen and (max-width: 767px) {
    .tabs {
      li {
        font-size: 14px;
        margin-right: 25px;
      }
    }
    .view-data-for {
      text-align: left;
      padding-bottom: 10px;
    }
  }
  @media only screen and (min-width: 768px) {
    .apply-btn {
      flex: 0 0 100px;
      max-width: 100px;
    }
  }

  .view-data-box {
    display: flex;
    flex-direction: column;
    felx-wrap: nowrap;
    justify-content: center;
  }

  .view-data-filter-list {
    ul {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        margin: 0 15px 15px 0;

        :last-child {
          margin-right: 0;
        }

        @media only screen and (max-width: 767px) {
          display: block;
        }

        .inline-list li {
          display: inline-block;
          margin-bottom: 0;
        }
        &.sales-Performance-download-btn {
          vertical-align: top;
          margin-top: 10px;
        }
        &.asin-download-button {
          display: none;
        }

        @media only screen and (min-width: 992px) {
          &.asin-download-button {
            display: inline-block;
          }
        }
      }
    }
    @media only screen and (min-width: 992px) {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export default ViewData;
