// playbook tabs constant
export const playbookTabsConstants = [
  {
    label: 'Onboarding',
    key: 'onboarding',
    type: 'onboarding-playbook',
  },
  {
    label: 'Playbook',
    key: 'playbook',
    type: 'main-playbook',
  },
  {
    label: 'Standard Monthly',
    key: 'standard-monthly',
    type: 'main-playbook',
  },
  {
    label: 'Additional One-Time',
    key: 'additional-one-time',
    type: 'main-playbook',
  },
  {
    label: 'Onboarding',
    key: 'onboarding-gray',
    type: 'onboarding-playbook',
  },
];

// account type constants
export const playbookAccountTypeOptions = [
  { label: 'Seller', value: 'Seller' },
  { label: 'Vendor', value: 'Vendor' },
];

// jira status constant
export const jiraStatus = [
  {
    label: 'To Do',
    status: 'to_do',
  },
  {
    label: 'In Progress',
    status: 'in_progress',
  },
  {
    label: 'Review',
    status: 'review',
  },
  {
    label: 'Done',
    status: 'done',
  },
];

// playbook quarter user messages
export const pastPlaybookTaskMessage =
  'There are no tasks created for this period.';
export const currentPlaybookTaskMessage =
  'There are no tasks created for this month.';
export const noTaskAssignedMessage =
  'There are no tasks assigned to the team member selected.';

// no ervice available message for playbook task
export const noStandardServiceMessage =
  'BP doesn’t have any Standard Monthly service for this month yet. Please check the agreement section';

export const noOneTimeServiceMessage =
  'BP doesn’t have any Additional One-Time service for this month yet. Please check the agreement section';

export const playbookTableHeader = [
  {
    label: 'Services',
    key: 'services',
    width: '40%',
  },
  {
    label: 'Marketplace',
    key: 'marketplace',
    width: '15%',
  },
  {
    label: 'TEAM',
    key: 'team',
    width: '10%',
  },
  {
    label: 'DUE DATE',
    key: 'due-date',
    width: '10%',
  },
  {
    label: 'ASSIGNEE',
    key: 'assignee',
    width: '10%',
  },
  {
    label: 'STATUS',
    key: 'status',
    width: '15%',
  },
];

export const playbookTableHeaderBPView = [
  {
    label: 'DELIVERABLES',
    key: 'deliverables',
    width: '50%',
  },

  {
    label: 'TEAM',
    key: 'team',
    width: '15%',
  },

  {
    label: 'STATUS',
    key: 'status',
    width: '15%',
  },
];

export const onboardingPlaybookTableHeader = [
  {
    label: 'DELIVERABLES',
    key: 'deliverables',
    width: '50%',
  },
  {
    label: 'TEAM',
    key: 'team',
    width: '15%',
  },
  {
    label: 'DUE DATE',
    key: 'due-date',
    width: '10%',
  },
  {
    label: 'ASSIGNEE',
    key: 'assignee',
    width: '10%',
  },
  {
    label: 'STATUS',
    key: 'status',
    width: '15%',
  },
];
export const playbookSummaryMonthOptions = [
  {
    label: '1 Month',
    value: '1',
  },
  {
    label: '2 Month',
    value: '2',
  },
  {
    label: '3 Month',
    value: '3',
  },
  {
    label: 'Current',
    value: 'current',
  },
];
