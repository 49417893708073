import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Modal from 'react-modal';
import NumberFormat from 'react-number-format';
import { bool, func, shape, string } from 'prop-types';
import { toast } from 'react-toastify';

import Theme from '../../../../../theme/Theme';
import { GroupUser } from '../../../../../theme/Global';
import { SpreetailRole, billingAddress } from '../../../../../constants';
import { CloseIcon } from '../../../../../theme/images';
import { saveBillingInfo, getBillingDetails } from '../../../../../api';

import {
  Button,
  ErrorMsg,
  ModalBox,
  PageLoader,
  InputFormField,
  GetInitialName,
  ModalRadioCheck,
  WhiteCard,
} from '../../../../../common';

export default function BillingContactList({
  id,
  userInfo,
  onBoardingId,
  showContactModal,
  setShowContactModal,
  data,
  setData,
  additionContactFormData,
  setAdditionContactFormData,
}) {
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const [selectedContact, setSelectedContact] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [apiError, setApiError] = useState({});
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [contactForDelete, setContactForDelete] = useState(null);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      minHeight: '200px',
      overlay: ' {zIndex: 1000}',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const tempContact = {
    uniqueKey: Math.random(),
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  };

  useEffect(() => {
    getBillingDetails(id).then((response) => {
      setData(response?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(selectedContact)?.length !== 0) {
      setAdditionContactFormData(selectedContact);
    } else {
      if (!data?.billing_contact?.length) {
        tempContact.primary_contact = true;
      }
      setAdditionContactFormData(tempContact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact, data]);

  useEffect(() => {
    if (
      additionContactFormData?.first_name &&
      additionContactFormData?.last_name &&
      additionContactFormData?.email
    ) {
      setShowBtn(true);
    }
  }, [additionContactFormData]);

  const handleChange = (event, item, type) => {
    if (type === 'primaryContact') {
      setAdditionContactFormData({
        ...additionContactFormData,
        [item.key]: true,
      });
    } else {
      setAdditionContactFormData({
        ...additionContactFormData,
        [item.key]: event.target.value,
      });
    }
    setApiError({
      ...apiError,
      [type]: {
        ...apiError[type],
        [item.key]: '',
      },
      0: '',
    });
  };

  const saveBillingData = () => {
    setIsLoading({ loader: true, type: 'button' });

    const details = {
      billing_contact: [additionContactFormData],
      billing_address: data?.billing_address?.[0],
      customer_onboarding:
        data?.customer_onboarding ||
        userInfo.customer_onboarding ||
        onBoardingId,
    };

    const contactType = !additionContactFormData?.id
      ? 'newContactDataDetails'
      : null;
    const toastTextMessage = contactType
      ? 'Contact added successfully'
      : 'Contact updated successfully';
    saveBillingInfo(details, data?.id ? data?.id : null, contactType).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading({ loader: false, type: 'button' });
          setShowContactModal(false);
          toast.success(toastTextMessage);
          getBillingDetails(id).then((response) => {
            setData(response?.data);
          });
          setAdditionContactFormData({});
        }
        if (res.status === 400) {
          setIsLoading({ loader: false, type: 'button' });
          let contact = {};
          contact = res?.data?.billing_contact?.[0];

          setApiError({
            billing_contact: contact,
          });
        }
      },
    );
  };

  const generateInput = (item, type) => {
    return (
      <input
        className="form-control"
        placeholder={`Enter ${item.label}`}
        type={item.type}
        defaultValue={additionContactFormData?.[item.key]}
        onChange={(event) => handleChange(event, item, type)}
        maxLength={item.key === 'postal_code' ? 10 : ''}
      />
    );
  };

  const generateNumeric = (item, type) => {
    return (
      <NumberFormat
        format={item.format}
        className="form-control"
        onChange={(event) => handleChange(event, item, type)}
        placeholder={`Enter ${item.label}`}
        defaultValue={additionContactFormData?.[item.key]}
        isNumericString
      />
    );
  };

  const mapContactDetails = () => {
    return (
      <>
        <div className="row pt-3">
          <div className="col-md-6">
            <ModalRadioCheck>
              <label
                className="checkboxes radio-container customer-list"
                htmlFor="primaryContact"
              >
                Set as default
                <input
                  type="radio"
                  name="radio1"
                  id="primaryContact"
                  value="primaryContact"
                  onChange={(event) =>
                    handleChange(
                      event,
                      { key: 'primary_contact' },
                      'primaryContact',
                    )
                  }
                  checked={additionContactFormData?.primary_contact}
                />
                <span className="checkmark checkmark-customer-list" />
              </label>
            </ModalRadioCheck>
          </div>
        </div>
        <div className="row">
          {billingAddress
            .filter((op) => op.section === 'contact')
            .map((item) => {
              return (
                <div className="col-md-6" key={item.key}>
                  <InputFormField className="mt-3">
                    <label htmlFor={item.label}>
                      {item.label}
                      <br />
                      {item.type === 'number' ? (
                        <>{generateNumeric(item, 'billing_contact')}</>
                      ) : (
                        <>{generateInput(item, 'billing_contact')}</>
                      )}
                    </label>
                  </InputFormField>
                  <ErrorMsg className="mb-2">
                    {apiError?.billing_contact?.[item.key]?.[0]}
                  </ErrorMsg>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  const deleteContact = () => {
    setIsLoading({ loader: true, type: 'button' });
    saveBillingInfo(
      { billing_contact_id: contactForDelete?.id },
      data?.id,
    ).then((res) => {
      if (res.status === 200) {
        setIsLoading({ loader: false, type: 'button' });
        setShowDeleteModal(false);
        setAdditionContactFormData({});
        toast.success('Contact deleted successfully');
        getBillingDetails(id).then((response) => {
          setData(response?.data);
        });
      }
    });
  };

  const renderContacts = () => {
    const primaryContacts =
      data?.billing_contact?.length &&
      data?.billing_contact?.filter((item) => item?.primary_contact);

    if (data?.billing_contact?.length) {
      return data?.billing_contact?.map((item) => {
        return (
          <CardOuter>
            <div className="row mt-3">
              <div className="col-9">
                <GroupUser>
                  {data?.id ? (
                    <GetInitialName
                      property="float-left mr-3"
                      userInfo={item}
                    />
                  ) : (
                    ''
                  )}
                  <div className="activity-user">
                    {`${item?.first_name} ${item?.last_name}`}
                    {item?.primary_contact ? (
                      <span className="default-label ml-1">Default</span>
                    ) : (
                      ''
                    )}
                    <br />
                    <div className="user-email-address">{item?.email}</div>
                    <br />
                    <div className="user-email-address">
                      {item?.phone_number}
                    </div>
                  </div>
                  <div className="clear-fix" />
                </GroupUser>
              </div>
              <div className="col-3 text-right">
                {!isSpreetailUser ? (
                  <ul className="edit-delete-icons">
                    <li
                      onClick={() => {
                        setSelectedContact(item);
                        setAdditionContactFormData(item);
                        setShowContactModal(true);
                      }}
                      role="presentation"
                    >
                      <FontAwesomeIcon
                        icon="fa-regular fa-pen"
                        color="#FF5933"
                        fontSize="12px"
                        className="mr-2"
                      />{' '}
                      Edit
                    </li>
                    {(item?.primary_contact && primaryContacts?.length <= 1) ||
                    data?.billing_contact?.length < 1 ? null : (
                      <li
                        onClick={() => {
                          setShowDeleteModal(true);
                          setContactForDelete(item);
                        }}
                        role="presentation"
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-trash"
                          color="#FF5933"
                          fontSize="12px"
                          className="mr-2"
                        />
                        Delete
                      </li>
                    )}
                  </ul>
                ) : null}
              </div>
            </div>
            <div className=" straight-line light-line   mt-3" />
          </CardOuter>
        );
      });
    }
    return '';
  };

  const renderContactEditModal = () => {
    return (
      <Modal
        isOpen={showContactModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <ModalBox>
          {' '}
          <img
            src={CloseIcon}
            alt="close"
            className="float-right cursor cross-icon"
            onClick={() => {
              setShowDeleteModal(false);
              setApiError({});
              setShowBtn(false);
              setAdditionContactFormData(null);
              setShowContactModal(false);
            }}
            role="presentation"
          />{' '}
          <div className="modal-body">
            {showContactModal ? (
              <>
                <h4>Add Additional Billing Contact</h4>
                <div className="body-content  ">
                  <ErrorMsg style={{ textAlign: 'center' }}>
                    {apiError && apiError[0]}
                  </ErrorMsg>
                  {mapContactDetails()}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
          {showBtn ? (
            <>
              <div className=" col-12  modal-footer">
                <Button
                  className="btn-primary w-100"
                  onClick={() => saveBillingData()}
                >
                  {isLoading.loader && isLoading.type === 'button' ? (
                    <PageLoader color={Theme.white} type="button" />
                  ) : (
                    `${
                      !additionContactFormData?.id
                        ? 'Confirm and Add New Contact'
                        : 'Confirm and Save'
                    }`
                  )}
                </Button>
              </div>
            </>
          ) : (
            ''
          )}
        </ModalBox>
      </Modal>
    );
  };

  const renderContactDeleteModal = () => {
    return (
      <Modal
        isOpen={showDeleteModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <ModalBox>
          <img
            src={CloseIcon}
            alt="close"
            className="float-right cursor cross-icon"
            onClick={() => {
              setShowDeleteModal(false);
              setApiError({});
              setAdditionContactFormData(null);
            }}
            role="presentation"
          />{' '}
          <div className="modal-body">
            {showDeleteModal ? (
              <>
                <h4>CONFIRM CONTACT DELETE</h4>
                <div className="body-content">
                  <DeleteContent>
                    {`Are you sure you would like to delete ${contactForDelete?.first_name} ${contactForDelete?.last_name}
                  from your Contacts List? This action cannot be undone.`}
                  </DeleteContent>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
          <div className=" col-12  modal-footer">
            <Button
              className="btn-primary w-100"
              onClick={() => deleteContact()}
            >
              {isLoading.loader && isLoading.type === 'button' ? (
                <PageLoader color={Theme.white} type="button" />
              ) : (
                'Confirm & Delete'
              )}
            </Button>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  return (
    <div className="col-md-6 col-sm-12 mb-3">
      <WhiteCard>
        <p className="black-heading-title mt-0 mb-4">Billing Contacts</p>
        {renderContacts()}

        {renderContactEditModal()}

        {renderContactDeleteModal()}

        {!isSpreetailUser ? (
          <>
            {data?.billing_contact?.find((item) => item.primary_contact) ? (
              <AddContactButton
                className="mt-3"
                onClick={() => {
                  setShowContactModal(true);
                  setAdditionContactFormData({});
                }}
              >
                <FontAwesomeIcon
                  color="#FF5933"
                  fontSize="12px"
                  className="mr-2"
                  icon="fa-regular fa-plus"
                />
                Add Additional Billing Contact
              </AddContactButton>
            ) : (
              ''
            )}
          </>
        ) : null}
      </WhiteCard>
    </div>
  );
}

BillingContactList.defaultProps = {
  onBoardingId: '',
  setShowContactModal: () => {},
  showContactModal: false,
  setData: () => {},
  setAdditionContactFormData: () => {},
  additionContactFormData: {},
};

BillingContactList.propTypes = {
  id: string.isRequired,
  userInfo: shape({
    customer_onboarding: string,
  }).isRequired,
  onBoardingId: string,
  setShowContactModal: func,
  showContactModal: bool,
  setData: func,
  data: shape({
    billing_contact: shape({}),
  }).isRequired,
  setAdditionContactFormData: func,
  additionContactFormData: shape({}),
};

const CardOuter = styled.div`
  .edit-delete-icons {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: none;

    li {
      margin-right: 10px;
      display: block;
      color: #171725;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 3px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  :hover {
    .edit-delete-icons {
      display: block;
    }
  }
`;

const DeleteContent = styled.div`
  margin-top: 20px;
  font-size: ${Theme.extraMedium};
  color: ${Theme.black};
`;

const AddContactButton = styled.div`
  color: ${Theme.gray85};
  font-size: ${Theme.extraNormal};

  /* .fa-plus {
    margin-right: 9px;
    color: ${Theme.baseColor};
  } */

  :hover {
    color: #ff5933;
    cursor: pointer;
  }
`;
