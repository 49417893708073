/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react';

import Modal from 'react-modal';
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { components } from 'react-select';
import { useSelector } from 'react-redux';
import { shape, arrayOf, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../../../theme/Theme';
import EditAmazonAccountDetails from './EditAmazonAccountDetails';
import {
  getMaketplaceCode,
  getMaketplaceRegion,
} from '../../../Utils/MarketplaceFunctions';

import { Icon } from '../../../theme/Global';
import {
  getAmazonDspDetails,
  getAmazonSeller,
  getAmazonVendor,
} from '../../../api';
import {
  SpreetailRole,
  amazonDspAccDetails,
  amazonSellerAccDetails,
  amazonVendorAccDetails,
} from '../../../constants';
import {
  AddIcons,
  EditOrangeIcon,
  CopyLinkIcon,
  CloseIcon,
  TrashIcons,
} from '../../../theme/images';
import {
  InputFormField,
  ErrorMsg,
  WhiteCard,
  DropdownIndicator,
  Button,
  SingleSelectDropdown,
} from '../../../common';

export default function AmazonAccount({
  marketplaceData,
  customStyles,
  isAuthorized,
  marketplaceList,
  id,
}) {
  const mounted = useRef(false);
  const { Option, SingleValue } = components;
  const userInfo = useSelector((state) => state.userState.userInfo);
  const customerInfo = useSelector((state) => state.customerState);
  const isSpreetailUser = userInfo?.role === SpreetailRole;

  const [marketplaceChoices, setMarketplaceChoices] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);
  const [amazonDetails, setAmazonDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState([]);
  const [showBtn, setShowBtn] = useState(false);
  const [apiError, setApiError] = useState([]);
  const [dspApiError, setDspApiError] = useState({});
  const [sellerAdvertiserIds, setSellerAdvertiserIds] = useState([]);
  const [vendorAdvertiserIds, setVendorAdvertiserIds] = useState([]);
  const [sellerAdvertiserIdsError, setSellerAdvertiserIdsError] = useState([]);
  const [vendorAdvertiserIdsError, setVendorAdvertiserIdsError] = useState([]);
  const [amazonDspDetails, setAmazonDspDetails] = useState({});
  const [dspData, setDspData] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);

  const baseURL = 'https://authorize.biusers.com';
  const url = 'bispoke.io';
  const userEmail = userInfo?.email;
  const apiType = 'spapi';
  const agencyName = 'BBE';

  const authenticationURL = `${baseURL}?&url=${url}&email=${userEmail}&api_type=${apiType}&agency_name=${agencyName}`;

  // pdv-7948 remove hardcoded LWA authenticator list.
  const showSPButton = userInfo?.lwa_authentication_button;

  const displayMarketplaceURL = (label) => {
    return marketplaceList?.find((op) => label === op.label)?.value;
  };

  const convertDomainToName = (label) => {
    return marketplaceList?.find((op) => label === op.label)?.label;
  };

  const checkLWAAuthorizationMsg = (type) => {
    if (
      typeof isAuthorized[convertDomainToName(selectedMarketplace?.label)] ===
      'object'
    ) {
      return isAuthorized[convertDomainToName(selectedMarketplace?.label)]?.[
        type
      ];
    }
    return isAuthorized[convertDomainToName(selectedMarketplace?.label)];
  };

  // bind multiple seller advertiser ids data
  const bindMultipleAdvertiserIdsData = useCallback((AdvIdsData, type) => {
    const tempData = [];

    if (AdvIdsData?.length) {
      AdvIdsData.forEach((obj, index) => {
        const tempIdObj = {
          index,
          key: `${type}_advertiser_id_${index}`,
          label: `Advertiser ID`,
          section: 2,
          advertiser_id: obj,
          type: 'advIds',
        };
        tempData.push(tempIdObj);

        return tempData;
      });
    }
    return tempData;
  }, []);

  // get amazon vendor central account detail
  const getVendorDetails = (marketplace, sellerData) => {
    getAmazonVendor(marketplace?.value, marketplace?.mp_name, id).then(
      (vendor) => {
        if (mounted.current) {
          setAmazonDetails({
            ...amazonDetails,
            Seller: sellerData,
            Vendor: vendor?.data?.results?.[0],
          });
          setFormData({
            ...formData,
            Seller: sellerData,
            Vendor: vendor?.data?.results?.[0],
          });

          const vendorAdvIds = vendor?.data?.results?.[0]?.advertiser_ids;
          const getVendorAdvIds = bindMultipleAdvertiserIdsData(
            vendorAdvIds,
            'vendor',
          );
          setVendorAdvertiserIds(getVendorAdvIds);
          setDataLoader(false);
        }
      },
    );
  };

  // get amazon seller central account detail
  const getSellerDetails = useCallback(
    (marketplace) => {
      getAmazonSeller(marketplace?.value, marketplace?.mp_name, id).then(
        (seller) => {
          if (mounted.current) {
            setAmazonDetails({
              ...amazonDetails,
              Seller: seller?.data?.results?.[0],
            });
            setFormData({
              ...formData,
              Seller: seller?.data?.results?.[0],
            });

            const sellerAdvIds = seller?.data?.results?.[0]?.advertiser_ids;
            const getSellerAdvIds = bindMultipleAdvertiserIdsData(
              sellerAdvIds,
              'seller',
            );
            setSellerAdvertiserIds(getSellerAdvIds);

            if (marketplace?.account_type === 'Hybrid') {
              getVendorDetails(marketplace, seller?.data?.results?.[0]);
            }
            setDataLoader(false);
          }
        },
      );
    },
    [amazonDetails],
  );

  // get dsp account details
  const getDspDetailsOfBP = useCallback((marketplace) => {
    getAmazonDspDetails(marketplace).then((response) => {
      if (mounted.current) {
        if (response?.status === 200) {
          setDspData({ Dsp: response?.data?.results?.[0] });
          setAmazonDspDetails({ Dsp: response?.data?.results?.[0] });
        }
        setDataLoader(false);
      }
    });
  }, []);

  // seller vendor api call
  const sellerVendorCall = (marketplace) => {
    setDataLoader(true);
    setApiError({});
    setFormData({});
    setAmazonDetails({});
    setDspData({});
    setAmazonDspDetails({});
    setDspApiError({});
    setSellerAdvertiserIdsError([]);
    setVendorAdvertiserIdsError([]);
    setSelectedMarketplace(marketplace);

    if (marketplace?.value === null) {
      marketplace.value = marketplace?.mp_name;
    }

    if (marketplace?.value || marketplace?.mp_name) {
      if (
        marketplace?.account_type === 'Seller' ||
        marketplace?.account_type === 'Hybrid'
      ) {
        getSellerDetails(marketplace);
      } else if (marketplace?.account_type === 'Vendor') {
        getVendorDetails(marketplace, '');
      }
    }

    if (marketplace?.dspvalue) {
      getDspDetailsOfBP(marketplace?.dspvalue);
    }
  };

  // get MarketPlace Choices and Serrer and or vendor data
  const initialGetCallOfUseEffect = () => {
    mounted.current = true;
    setMarketplaceChoices(marketplaceData);
    const defaultMarketplace =
      marketplaceData?.find((value) => value?.is_primary) ||
      marketplaceData?.[0];

    if (defaultMarketplace?.value === null)
      defaultMarketplace.value = defaultMarketplace?.mp_name;

    sellerVendorCall(defaultMarketplace);

    return () => {
      mounted.current = false;
    };
  };

  useEffect(() => {
    initialGetCallOfUseEffect();
  }, [marketplaceData]);

  const singleMarketplaceOption = (dataProps) => (
    <SingleValue {...dataProps}>
      <span style={{ fontSize: '15px', color: '#000000' }}>
        {dataProps.data.label} {dataProps.data.is_primary}
        {/* {dataProps.data.label} {dataProps.data.is_primary ? '(primary)' : ''} */}
      </span>
    </SingleValue>
  );

  const IconOption = (dataProps) => (
    <Option {...dataProps}>
      {dataProps.data.label} {dataProps.data.is_primary}
      {/* {dataProps.data.label} {dataProps.data.is_primary ? '(primary)' : ''} */}
    </Option>
  );

  // handeling the advertiser ids input changes events
  const handleAdvIdsChange = (mapData, index, key, value) => {
    return mapData?.map((obj, indexOfobj) => {
      if (indexOfobj === index) {
        return {
          ...obj,
          [key]: value,
        };
      }
      return {
        ...obj,
      };
    });
  };

  // handeling the events when user changes the input value
  const handleChange = (event, item, part, index) => {
    setShowBtn(true);
    if (part === 3) {
      setDspData({
        ...dspData,
        Dsp: {
          ...dspData.Dsp,
          [item?.key]: event?.target?.value,
        },
      });
      setDspApiError({
        ...dspApiError,
        Dsp: {
          ...dspApiError.Dsp,
          [item.key]: '',
        },
      });
    } else {
      if (item?.type !== 'advIds') {
        setApiError({
          ...apiError,
          [item?.key]: '',
        });
      }

      if (item?.type === 'advIds') {
        if (part === 1) {
          const updatedId = handleAdvIdsChange(
            sellerAdvertiserIds,
            index,
            'advertiser_id',
            event.target.value,
          );
          setSellerAdvertiserIds(updatedId);

          const updatedError = handleAdvIdsChange(
            sellerAdvertiserIdsError,
            index,
            'error',
            '',
          );
          setSellerAdvertiserIdsError(updatedError);
        }

        if (part === 2) {
          const updatedId = handleAdvIdsChange(
            vendorAdvertiserIds,
            index,
            'advertiser_id',
            event.target.value,
          );
          setVendorAdvertiserIds(updatedId);

          const updatedError = handleAdvIdsChange(
            vendorAdvertiserIdsError,
            index,
            'error',
            '',
          );
          setVendorAdvertiserIdsError(updatedError);
        }
      } else {
        if (selectedMarketplace?.account_type === 'Hybrid' && part === 1) {
          setFormData({
            ...formData,
            Seller: {
              ...formData.Seller,
              [item.key]: event.target.value,
            },
          });
          setApiError({
            ...apiError,
            Seller: {
              ...apiError.Seller,
              [item.key]: '',
            },
          });
        }
        if (selectedMarketplace?.account_type === 'Hybrid' && part === 2) {
          setFormData({
            ...formData,
            Vendor: {
              ...formData.Vendor,
              [item.key]: event.target.value,
            },
          });
          setApiError({
            ...apiError,
            Vendor: {
              ...apiError.Vendor,
              [item.key]: '',
            },
          });
        } else if (selectedMarketplace?.account_type !== 'Hybrid')
          setFormData({
            ...formData,
            [selectedMarketplace.account_type]: {
              ...formData[selectedMarketplace.account_type],
              [item.key]: event.target.value,
            },
          });
      }
    }
  };

  // map api error for multiple advertier id input field
  const renderAdvertiserIdsError = (item, part) => {
    let error = [];
    if (part === 1) {
      const errorObj = sellerAdvertiserIdsError?.filter((obj, index) => {
        if (index === item) {
          return obj;
        }
        return '';
      });

      error = errorObj?.[0]?.error;
      return error;
    }
    if (part === 2) {
      const errorObj = vendorAdvertiserIdsError?.filter((obj, index) => {
        if (index === item) {
          return obj;
        }
        return '';
      });

      error = errorObj?.[0]?.error;
      return error;
    }

    return error || '';
  };

  // Remove the additional advertiser id field added inside seller and vendor details
  const removeAdvertiserIdInputField = (index, part) => {
    setShowBtn(true);
    if (part === 1) {
      const sellerAdvIds = [...sellerAdvertiserIds];
      sellerAdvIds.splice(index, 1);
      setSellerAdvertiserIds(sellerAdvIds);

      const sellerAdvIdsError = [...sellerAdvertiserIdsError];
      sellerAdvIdsError.splice(index, 1);
      setSellerAdvertiserIdsError(sellerAdvIdsError);
    }
    if (part === 2) {
      const vendorAdvIds = [...vendorAdvertiserIds];
      vendorAdvIds.splice(index, 1);
      setVendorAdvertiserIds(vendorAdvIds);

      const vendorAdvIdsError = [...vendorAdvertiserIdsError];
      vendorAdvIdsError.splice(index, 1);
      setVendorAdvertiserIdsError(vendorAdvIdsError);
    }
  };

  // additional advertiser ids object
  const addAdvertiserIdsObject = (index, type) => {
    const advId = {
      index,
      key: `${type}_advertiser_id_${index}`,
      label: `Advertiser ID`,
      section: 2,
      advertiser_id: '',
      type: 'advIds',
    };
    return advId;
  };

  // render additional advertiser id html input field inside seller and vendor detail
  const renderAdvertiserIdInputField = (part) => {
    let tempData = [];
    if (part === 1) {
      const index = sellerAdvertiserIds?.length;
      const advId = addAdvertiserIdsObject(index, 'seller');
      tempData = setSellerAdvertiserIds([...sellerAdvertiserIds, advId]);
    } else if (part === 2) {
      const index = vendorAdvertiserIds?.length;
      const advId = addAdvertiserIdsObject(index, 'vendor');
      tempData = setVendorAdvertiserIds([...vendorAdvertiserIds, advId]);
    }
    return tempData;
  };

  // map default values to seller and vendor details inside modal body
  const mapDefaultValues = (item, part) => {
    if (part === 3) {
      return dspData?.Dsp?.[item] || '';
    }
    if (selectedMarketplace?.account_type === 'Hybrid') {
      if (part === 1) {
        return formData?.Seller?.[item];
      }
      if (part === 2) {
        return formData?.Vendor?.[item];
      }
    }
    return formData?.[selectedMarketplace?.account_type]?.[item] || '';
  };

  // generate input html for edit account deatil modal
  const generateInput = (item, part, index) => {
    return (
      <div style={{ position: 'relative' }} key={item.key}>
        <input
          className="form-control extra-space"
          type={part === 3 && item?.key === 'advertiser_id' ? 'number' : 'text'}
          name={item.key}
          id={item.key}
          placeholder={item.label}
          value={
            item?.type === 'advIds'
              ? item.advertiser_id
              : mapDefaultValues(item.key, part)
          }
          onChange={(event) => handleChange(event, item, part, index)}
        />
        {item?.type === 'advIds' ? (
          <img
            className="trash cursor"
            src={TrashIcons}
            alt="delete"
            role="presentation"
            onClick={() => removeAdvertiserIdInputField(index, part)}
          />
        ) : null}
      </div>
    );
  };

  // render additional advertising entity id button for seller and vendor detail
  const renderAdditionalAdvertiserIdButton = (part) => {
    return (
      <div className="row">
        <div className="col-12 mt-2">
          <Button
            style={{ textTransform: 'uppercase' }}
            className="btn-add-contact"
            onClick={() => {
              renderAdvertiserIdInputField(part);
            }}
          >
            <img
              width="14px"
              style={{ verticalAlign: 'middle' }}
              src={AddIcons}
              className="ml-1 add-icon"
              alt="add"
            />{' '}
            Add Additional Advertiser Id
          </Button>
        </div>
      </div>
    );
  };

  // display error message for seller and vendor input fields
  const displaySellerVendorErrorMessage = (item, index, part) => {
    return item?.type === 'advIds' ? (
      <ErrorMsg>{renderAdvertiserIdsError(index, part)}</ErrorMsg>
    ) : (
      <ErrorMsg>
        {part === 1
          ? apiError?.Seller?.[item?.key]
          : apiError?.Vendor?.[item?.key]}
      </ErrorMsg>
    );
  };

  // display error message dor dsp input fields
  const displayDspErrorMessage = (errorMessage) => {
    return errorMessage ? (
      <ErrorMsg>
        <Icon className="mr-1">
          <FontAwesomeIcon
            icon="fa-regular fa-circle-info"
            style={{
              color: Theme.red,
            }}
          />
        </Icon>
        {errorMessage}
      </ErrorMsg>
    ) : null;
  };

  // generate the html inside edit amazon details modal
  const generateEditHTML = (mapData, part) => {
    return mapData?.map((item, index) => {
      return (
        <div className={item?.property} id={item?.key} key={item?.key}>
          <InputFormField className="mt-3">
            <label htmlFor={item?.key}>
              {item?.label}
              <br />
              {generateInput(item, part, index)}
            </label>
            {/* <ErrorMsg>{apiError?.[item?.key]}</ErrorMsg> */}
            {part === 1 || part === 2
              ? displaySellerVendorErrorMessage(item, index, part)
              : null}

            {part === 3
              ? displayDspErrorMessage(dspApiError?.Dsp?.[item?.key])
              : null}
          </InputFormField>
        </div>
      );
    });
  };

  // generate the html inside amazaon accounts names and ids
  const generateHTML = (mapData, part) => {
    return (
      <>
        {mapData?.map((item) => {
          return (
            <div className="copy-info" key={item.key}>
              <div className="label mt-3">{item.label}</div>
              <div className="label-info card-title">
                {item?.type === 'advIds'
                  ? item.advertiser_id || 'None'
                  : mapDefaultValues(item.key, part) || 'None'}
              </div>
              <div
                className="copy-text"
                onClick={() =>
                  copy(
                    item?.type === 'advIds'
                      ? item.advertiser_id
                      : mapDefaultValues(item.key, part),
                  )
                }
                role="presentation"
              >
                <img src={CopyLinkIcon} alt="" />
                Copy
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const getMarketplaceDetails = () => {
    const marketplaceURL = displayMarketplaceURL(selectedMarketplace?.label);
    return {
      ...selectedMarketplace,
      label: marketplaceURL,
    };
  };

  const generateAccountHTML = (html) => {
    return (
      <>
        {selectedMarketplace?.account_type === 'Hybrid' ? (
          <div className=" liner-titles spacing mt-4 mb-3">Seller Central</div>
        ) : (
          ''
        )}
        {selectedMarketplace?.account_type === 'Seller' ||
        selectedMarketplace?.account_type === 'Hybrid' ? (
          <>
            {html === 'edit' ? (
              <>
                {generateEditHTML(amazonSellerAccDetails, 1)}
                {generateEditHTML(sellerAdvertiserIds, 1)}
                {renderAdditionalAdvertiserIdButton(1)}
              </>
            ) : (
              <>
                {generateHTML(amazonSellerAccDetails, 1)}
                {generateHTML(sellerAdvertiserIds)}
                {showSPButton && userInfo?.role !== 'Customer' ? (
                  <div
                    data-tip={
                      !formData?.Seller?.merchant_id
                        ? 'Please add Merchant ID to enable the button.'
                        : checkLWAAuthorizationMsg('seller')
                        ? 'This marketplace is Authorized.'
                        : ''
                    }
                    data-for="disabledBtn"
                    className="mt-3"
                  >
                    <ReactTooltip
                      id="disabledBtn"
                      aria-haspopup="true"
                      place="bottom"
                    />
                    <a
                      href={encodeURI(
                        `${authenticationURL}&type=seller_central&companyName=${
                          customerInfo?.data?.company_name
                        }&region=${getMaketplaceRegion(
                          selectedMarketplace?.mp_name,
                        )}&country=${getMaketplaceCode(
                          selectedMarketplace?.mp_name,
                        )}&source=${process.env.REACT_APP_SP_API_URL}`,
                      )}
                      id="LoginWithAmazon"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        !formData?.Seller?.merchant_id ||
                        checkLWAAuthorizationMsg('seller')
                          ? 'isDisabled'
                          : ''
                      }
                      onClick={() =>
                        localStorage.setItem(
                          'spApi',
                          JSON.stringify({
                            customer_id: id,
                            marketplace: getMarketplaceDetails(),
                            type: 'seller',
                          }),
                        )
                      }
                    >
                      <img
                        border="0"
                        alt="Login with Amazon"
                        src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                        width="156"
                        height="32"
                      />
                    </a>
                  </div>
                ) : null}
              </>
            )}
          </>
        ) : (
          ''
        )}
        {selectedMarketplace?.account_type === 'Hybrid' ? (
          <div className=" liner-titles spacing mt-4 mb-3"> Vendor Central</div>
        ) : (
          ''
        )}
        {selectedMarketplace?.account_type === 'Vendor' ||
        selectedMarketplace?.account_type === 'Hybrid' ? (
          <>
            {html === 'edit' ? (
              <>
                {generateEditHTML(amazonVendorAccDetails, 2)}
                {generateEditHTML(vendorAdvertiserIds, 2)}
                {renderAdditionalAdvertiserIdButton(2)}
              </>
            ) : (
              <>
                {generateHTML(amazonVendorAccDetails, 2)}
                {generateHTML(vendorAdvertiserIds)}

                {showSPButton && userInfo?.role !== 'Customer' ? (
                  <div
                    data-tip={
                      checkLWAAuthorizationMsg('vendor')
                        ? 'This marketplace is Authorized.'
                        : ''
                    }
                    data-for="disabledBtn"
                    className="mt-3"
                  >
                    <ReactTooltip
                      id="disabledBtn"
                      aria-haspopup="true"
                      place="bottom"
                    />

                    <a
                      href={encodeURI(
                        `${authenticationURL}&type=vendor_central&companyName=${
                          customerInfo?.data?.company_name
                        }&region=${getMaketplaceRegion(
                          selectedMarketplace?.mp_name,
                        )}&country=${getMaketplaceCode(
                          selectedMarketplace?.mp_name,
                        )}&source=${process.env.REACT_APP_SP_API_URL}`,
                      )}
                      id="LoginWithAmazon"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        checkLWAAuthorizationMsg('vendor') ? 'isDisabled' : ''
                      }
                      onClick={() =>
                        localStorage.setItem(
                          'spApi',
                          JSON.stringify({
                            customer_id: id,
                            marketplace: getMarketplaceDetails(),
                            type: 'vendor',
                          }),
                        )
                      }
                    >
                      <img
                        border="0"
                        alt="Login with Amazon"
                        src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                        width="156"
                        height="32"
                      />
                    </a>
                  </div>
                ) : null}
              </>
            )}
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  // https://bbe.atlassian.net/browse/PDV-9099
  // Capture DSP Details on Company details section - Single BP / BP View
  const generateDspAccountHTML = (html) => {
    return (
      <>
        <div className="liner-titles spacing mt-4 mb-3">DSP</div>
        {html === 'edit'
          ? generateEditHTML(amazonDspAccDetails, 3)
          : generateHTML(amazonDspAccDetails, 3)}
      </>
    );
  };

  // get dropdown select components
  const getDropdownSelectComponents = () => {
    return {
      Option: IconOption,
      SingleValue: singleMarketplaceOption,
      DropdownIndicator,
    };
  };

  const generateDropdown = () => {
    return (
      <>
        <div className="label mt-3">Marketplace</div>
        <div style={{ position: 'relative' }}>
          <SingleSelectDropdown
            filterId="BT-AmazonAccountDetailsMarketplaceFilter"
            className=""
            dropdownOptions={marketplaceChoices}
            selectedValue={selectedMarketplace}
            onChangeHandler={sellerVendorCall}
            dropdownComponents={getDropdownSelectComponents}
            isApiCall={false}
            isSearchable={false}
          />
        </div>
      </>
    );
  };

  return (
    <WhiteCard>
      <p className="black-heading-title card-title mt-0 ">
        Amazon Account Names & IDs
      </p>
      {(selectedMarketplace?.dspvalue === null ||
        selectedMarketplace?.dspvalue === '') &&
      (isSpreetailUser ||
        marketplaceChoices?.length === 0 ||
        selectedMarketplace?.account_type === null ||
        selectedMarketplace?.account_type === '') ? (
        ''
      ) : (
        <div
          className="edit-details"
          onClick={() => {
            setShowModal(true);
            setShowBtn(false);
          }}
          role="presentation"
        >
          <img src={EditOrangeIcon} alt="" />
          Edit
        </div>
      )}
      {marketplaceChoices?.length === 0 ? (
        'Marketplace not found.'
      ) : (
        <div className={dataLoader ? 'disabled' : ''}>
          {generateDropdown()}
          {selectedMarketplace?.account_type ? (
            <>{generateAccountHTML('label')}</>
          ) : (
            <div className="mt-3">Account type not found.</div>
          )}
          {selectedMarketplace?.dspvalue
            ? generateDspAccountHTML('label')
            : null}
        </div>
      )}

      <Modal
        isOpen={showModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Add team modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            setShowModal(false);
            initialGetCallOfUseEffect();
          }}
          role="presentation"
        />
        <EditAmazonAccountDetails
          dataLoader={dataLoader}
          setShowModal={setShowModal}
          selectedMarketplace={selectedMarketplace}
          generateDropdown={generateDropdown}
          generateAccountHTML={generateAccountHTML}
          formData={formData}
          dspData={dspData}
          showBtn={showBtn}
          amazonDetails={amazonDetails}
          sellerVendorCall={sellerVendorCall}
          setApiError={setApiError}
          setDspApiError={setDspApiError}
          amazonDspDetails={amazonDspDetails}
          sellerAdvertiserIds={sellerAdvertiserIds}
          vendorAdvertiserIds={vendorAdvertiserIds}
          generateDspAccountHTML={generateDspAccountHTML}
          setSellerAdvertiserIdsError={setSellerAdvertiserIdsError}
          setVendorAdvertiserIdsError={setVendorAdvertiserIdsError}
          initialGetCallOfUseEffect={initialGetCallOfUseEffect}
        />
      </Modal>
    </WhiteCard>
  );
}

AmazonAccount.defaultProps = {
  customStyles: {},
  isAuthorized: {},
};

AmazonAccount.propTypes = {
  id: string.isRequired,
  customStyles: shape({}),
  marketplaceData: arrayOf(shape({})).isRequired,
  isAuthorized: shape({}),
  marketplaceList: arrayOf(shape({})).isRequired,
};
