// react imports
import React, { useState } from 'react';

// third party imports
import { useMediaQuery } from 'react-responsive';
import { components } from 'react-select';
import { arrayOf, bool, func, shape, string } from 'prop-types';

// local file imports
import { CaretUp } from '../../../../theme/images';
import { performanceTypeOptions } from '../../../../constants';
import {
  WhiteCard,
  MultiSelectSearchableDropdown,
  DropdownIndicator,
  SingleSelectDropdown,
  DropdownOptions,
} from '../../../../common';

const SponsoredFilter = ({
  // boolean
  isApiCall,
  isBGSAdmin,
  isSpreetailRole,
  isBGSManager,
  isAdManagerAdmin,
  isAdTeamManager,
  // objects
  selectedAdType,
  selectedMarketplace,
  selectedBrandPartner,
  selectedPerformanceType,
  // array
  userList,
  managerUserList,
  selectedUser,
  marketplaceOptions,
  brandPartnerOptions,
  selectedManagerUser,
  sponsoredAdTypeOptions,
  // function
  setSelectedUser,
  handleResetFilter,
  handleMarketplace,
  setSelectedAdType,
  setSelectedManagerUser,
  handleUserFilterEvents,
  handleUserOnMenuCloseEvents,
  handleManagerUserFilterEvents,
  handleSellerVendorFilterEvents,
  handledBrandPartnerFilterEvents,
  handleSponsoredAdTypeFilterEvents,
  handleAdTypeFilterOnMenuCloseEvents,
  handleManagerUserOnMenuCloseEvents,
}) => {
  const { SingleValue } = components;
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  // react select - single value
  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
    </SingleValue>
  );

  // react select - get dropdown components
  const getSelectComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  // render multi select dropdown for bgs and ad manager
  const renderUserDropdown = () => {
    return (
      <div className="col-12">
        <div className="label mt-3">
          {isBGSAdmin || isBGSManager || isSpreetailRole ? 'BGS' : 'Ad Manager'}
        </div>

        <div id="user-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-sponsoredBgsFilter"
            options={userList?.filter((option) => option?.value !== 'all')}
            label=""
            value="user"
            multiSelectedValues={{ user: selectedUser }}
            setMultiSelectedValues={setSelectedUser}
            handleMultiSelectChange={handleUserFilterEvents}
            handleOnMenuClose={handleUserOnMenuCloseEvents}
            isLoading={isApiCall}
            isSearchable
          />
        </div>
      </div>
    );
  };

  // render multi select dropdown for bgs manager and ad team manager
  const renderManagerUserDropdown = (label) => {
    return (
      <div className="col-12">
        <div className="label mt-3">{label}</div>
        <div id="manager-user-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-sponsoredManagerFilter"
            options={managerUserList?.filter(
              (option) => option?.value !== 'all',
            )}
            label=""
            value="managerUser"
            multiSelectedValues={{ managerUser: selectedManagerUser }}
            setMultiSelectedValues={setSelectedManagerUser}
            handleMultiSelectChange={handleManagerUserFilterEvents}
            handleOnMenuClose={handleManagerUserOnMenuCloseEvents}
            isLoading={isApiCall}
            isSearchable
          />
        </div>
      </div>
    );
  };

  // render custom dropdown filter components
  const renderCustomDropdownFilter = (
    filterId,
    optionsList,
    selectedValue,
    onChangeHandler,
  ) => {
    return (
      <div style={{ position: 'relative' }}>
        <SingleSelectDropdown
          filterId={filterId}
          className="single-select-dropdown"
          dropdownOptions={optionsList}
          selectedValue={selectedValue}
          onChangeHandler={onChangeHandler}
          dropdownComponents={getSelectComponents}
          isApiCall={isApiCall}
          isSearchable
        />
      </div>
    );
  };

  // render Brand Partner type dropdwon filter
  const renderBrandPartnerDropdownFilter = () => {
    return (
      <div className="col-12">
        <div className="label mt-3">Brand Partner</div>
        {renderCustomDropdownFilter(
          'BT-SalesBrandPartnerTypeFilter',
          brandPartnerOptions,
          selectedBrandPartner,
          handledBrandPartnerFilterEvents,
        )}
      </div>
    );
  };

  // render performance type dropdwon filter
  const renderPerformanceDropdownFilter = () => {
    return (
      <div className="col-12">
        <div className="label mt-3">Performnace</div>
        {renderCustomDropdownFilter(
          'BT-SponsoredPerformanceFilter',
          performanceTypeOptions,
          selectedPerformanceType,
          handleSellerVendorFilterEvents,
        )}
      </div>
    );
  };

  // render marketplace dropdown filter
  const renderMarketplaceDropdownFilter = () => {
    return (
      <div className="col-12">
        <div className="label mt-3">Marketplace</div>
        {renderCustomDropdownFilter(
          'BT-sponsored-countryfilter',
          marketplaceOptions,
          selectedMarketplace,
          handleMarketplace,
        )}
      </div>
    );
  };

  const getAdTypeOptions = () => {
    const afterRomeAlloption = sponsoredAdTypeOptions?.filter(
      (option) => option?.value !== 'all',
    );

    // PDV-8954
    // when Non-US markeplace selected then need to disabled the
    // television_campaign_report and all_ad _type option of sponsred ad type filter.
    // here we are set the isDisabled Flag.
    // ---------------------start---------------------
    let localAdtypes = afterRomeAlloption;
    if (
      selectedMarketplace?.value !== 'amazon.com' &&
      selectedMarketplace?.value !== 'all'
    ) {
      localAdtypes = afterRomeAlloption.map((ele) => {
        return {
          value: ele?.value,
          label: ele.label,
          key: ele?.key,
          isDisabled: ele.value === 'television_campaign_report',
        };
      });
    }
    // ---------------------end---------------------
    return localAdtypes;
  };

  // render sponsored ad types multi select filter
  const renderSponsoredAdTypesFilter = () => {
    return (
      <div className="col-12">
        <div className="label mt-3">Sponsored Ad Type</div>
        <div id="sponsored-ad-type-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-sponsoredAdTypeFilter"
            options={getAdTypeOptions()}
            label="Campaigns"
            value="sponsoredAdType"
            allObject={{ label: 'All Campaigns', value: 'all' }}
            multiSelectedValues={{ sponsoredAdType: selectedAdType }}
            setMultiSelectedValues={setSelectedAdType}
            handleMultiSelectChange={handleSponsoredAdTypeFilterEvents}
            handleOnMenuClose={handleAdTypeFilterOnMenuCloseEvents}
            isLoading={isApiCall}
            isSearchable
            allObjectOptionDisabled={
              selectedMarketplace?.value !== 'amazon.com' &&
              selectedMarketplace?.value !== 'all'
            }
          />
        </div>
      </div>
    );
  };

  // display sp filter desktop view
  const displaySpFilterDekstopView = () => {
    return (
      <WhiteCard className="mb-3 d-lg-block d-none">
        <div className="row ">
          <div className="col-6">
            <div className="black-heading-title ">Filters</div>
          </div>
          <div className="col-6 text-right">
            <p
              role="presentation"
              onClick={() => handleResetFilter()}
              className={`gray-normal-text m-0 cursor ${
                isApiCall ? 'disabled' : ''
              }`}
            >
              Reset filters
            </p>
          </div>
          {renderBrandPartnerDropdownFilter()}
          {renderPerformanceDropdownFilter()}
          {renderMarketplaceDropdownFilter()}
          {isBGSAdmin || isAdManagerAdmin || isSpreetailRole
            ? renderManagerUserDropdown(
                isBGSAdmin || isSpreetailRole
                  ? 'BGS Manager'
                  : 'Ad Team Manager',
              )
            : null}
          {isBGSManager ||
          isBGSAdmin ||
          isAdManagerAdmin ||
          isAdTeamManager ||
          isSpreetailRole
            ? renderUserDropdown(
                isBGSAdmin || isBGSManager || isSpreetailRole
                  ? 'BGS'
                  : 'Ad Manager',
              )
            : null}
          {renderSponsoredAdTypesFilter()}
        </div>
      </WhiteCard>
    );
  };

  // display sp filter mobile view
  const displaySpFilterMobileView = () => {
    return (
      <WhiteCard
        onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        className="mb-3 d-lg-none d-block"
      >
        <div className="row ">
          <div className="col-6">
            <div className="black-heading-title ">Filters</div>
          </div>
          <div className="col-6 text-right">
            <p
              className={`gray-normal-text m-0 cursor ${
                isApiCall ? 'disabled' : ''
              }`}
              role="presentation"
              onClick={() => handleResetFilter()}
            >
              Reset filters
              <span
                style={{
                  borderLeft: '1px solid #e2e2ea',
                  marginLeft: '10px',
                  marginRight: '20px',
                }}
              />
              <span
                role="presentation"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCollapseOpen(!isCollapseOpen);
                }}
              >
                <img
                  src={CaretUp}
                  alt="caret"
                  style={{
                    transform: isCollapseOpen ? 'rotate(180deg)' : '',
                    width: '25px',
                    height: '25px',
                    position: 'absolute',
                    right: '5px',
                    top: '-3px',
                  }}
                />
              </span>
            </p>
          </div>
        </div>
        {isCollapseOpen ? (
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">{renderBrandPartnerDropdownFilter()}</div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">{renderPerformanceDropdownFilter()}</div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">{renderMarketplaceDropdownFilter()}</div>
            </div>
            {isBGSAdmin || isAdManagerAdmin || isSpreetailRole ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row ">
                  {renderManagerUserDropdown(
                    isBGSAdmin || isSpreetailRole
                      ? 'BGS Manager'
                      : 'Ad Team Manager',
                  )}
                </div>
              </div>
            ) : null}

            {isBGSManager ||
            isBGSAdmin ||
            isAdManagerAdmin ||
            isSpreetailRole ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row ">
                  {renderUserDropdown('customer-list-header')}
                </div>
              </div>
            ) : null}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">{renderSponsoredAdTypesFilter()}</div>
            </div>
          </div>
        ) : null}
      </WhiteCard>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      {isDesktop ? displaySpFilterDekstopView() : displaySpFilterMobileView()}
    </>
  );
};

export default SponsoredFilter;

SponsoredFilter.defaultProps = {
  // boolean
  isApiCall: false,
  isBGSAdmin: false,
  isSpreetailRole: false,
  isBGSManager: false,
  isAdManagerAdmin: false,
  isAdTeamManager: false,
  // objects
  data: {},
  selectedMarketplace: {},
  selectedBrandPartner: {},
  selectedPerformanceType: {},
  // array
  userList: [],
  selectedUser: [],
  selectedAdType: [],
  managerUserList: [],
  marketplaceOptions: [],
  brandPartnerOptions: [],
  selectedManagerUser: [],
  sponsoredAdTypeOptions: [],
  // function
  setSelectedUser: () => {},
  handleResetFilter: () => {},
  handleMarketplace: () => {},
  setSelectedAdType: () => {},
  setSelectedManagerUser: () => {},
  handleUserFilterEvents: () => {},
  handleUserOnMenuCloseEvents: () => {},
  handleManagerUserFilterEvents: () => {},
  handleSellerVendorFilterEvents: () => {},
  handledBrandPartnerFilterEvents: () => {},
  handleSponsoredAdTypeFilterEvents: () => {},
  handleAdTypeFilterOnMenuCloseEvents: () => {},
  handleManagerUserOnMenuCloseEvents: () => {},
};

SponsoredFilter.propTypes = {
  // boolean
  isApiCall: bool,
  isBGSAdmin: bool,
  isSpreetailRole: bool,
  isBGSManager: bool,
  isAdManagerAdmin: bool,
  isAdTeamManager: bool,
  // objects
  data: shape({ sub: string, label: string }),
  selectedMarketplace: shape({}),
  selectedPerformanceType: shape({}),
  selectedBrandPartner: shape({}),
  // array
  selectedAdType: arrayOf(shape({})),
  userList: arrayOf(shape({})),
  managerUserList: arrayOf(shape({})),
  selectedUser: arrayOf(shape({})),
  selectedManagerUser: arrayOf(shape({})),
  marketplaceOptions: arrayOf(shape({})),
  sponsoredAdTypeOptions: arrayOf(shape({})),
  brandPartnerOptions: arrayOf(shape({})),
  // function
  handleResetFilter: func,
  handleMarketplace: func,
  setSelectedAdType: func,
  setSelectedUser: func,
  setSelectedManagerUser: func,
  handleUserFilterEvents: func,
  handleUserOnMenuCloseEvents: func,
  handleManagerUserFilterEvents: func,
  handleManagerUserOnMenuCloseEvents: func,
  handleSellerVendorFilterEvents: func,
  handleSponsoredAdTypeFilterEvents: func,
  handleAdTypeFilterOnMenuCloseEvents: func,
  handledBrandPartnerFilterEvents: func,
};
