import React, { Fragment } from 'react';

import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { bool, func, instanceOf, shape, string } from 'prop-types';

import Theme from '../../../../../theme/Theme';
import useFormatNumber from '../../../../../hooks/useFormatNumber';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../../theme/images';
import {
  CardMetrics,
  CustomDropDown,
  DropdownIndicator,
} from '../../../../../common';
import {
  adSpendMetricsSubTypesOptions,
  metricsResponseKey,
  metricsTootlNames,
  revenuMetricsSubTypes,
  revenuSubTypeKeys,
  showMetricsTooltipForSales,
} from '../../../../../constants';

const _ = require('lodash');

const SalesSellerMetrics = ({
  currencySymbol,
  selectedSalesMetrics,
  setSelectedSalesMetrics,
  salesCurrentTotal,
  salesPreviousTotal,
  salesDifference,
  selectedRevenueType,
  setSelectedRevenueType,
  isMetricsDisabled,
  setIsDisabledTimeFrame,
  selectedRevenueDropDownOption,
  setSelectedRevenueDropDownOption,
  salesGraphLoader,
  selectedAdSpendDropDownOption,
  setSelectedAdSpendDropDownOption,
  selectedAdSpendType,
  setSelectedAdSpendType,
}) => {
  const { Option, SingleValue } = components;

  const shortTileName = (tileName) => {
    let tileNameTemp = tileName;
    if (
      window.screen.width < 1920 &&
      window.screen.width > 779 &&
      tileName?.length > 8
    ) {
      tileNameTemp = `${tileName?.substring(0, 8)}...`;
    }
    return tileNameTemp;
  };

  const revenueSubtypeComponentFilterOption = (props) => (
    <Option {...props}>
      <div>
        <span>{props?.data?.label}</span>
      </div>
    </Option>
  );

  const revenueSubtypeSingleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '11px', color: '#000000' }}>
        {props?.data?.label}
      </span>
    </SingleValue>
  );

  const revenueSubtypeComponent = () => {
    return {
      Option: revenueSubtypeComponentFilterOption,
      SingleValue: revenueSubtypeSingleFilterOption,
      DropdownIndicator,
    };
  };

  const setBoxClasses = (name, classValue) => {
    let selectedClass = '';
    if (Object.prototype.hasOwnProperty.call(selectedSalesMetrics, name)) {
      selectedClass = `order-chart-box ${classValue} fix-height`;
    } else if (
      _.size(selectedSalesMetrics) === 4 ||
      (isMetricsDisabled[name] &&
        ![
          'revenueTotal',
          'revenueOrganic',
          'revenuePpc',
          'revenueDsp',
        ].includes(name)) ||
      (['revenueTotal', 'revenueOrganic', 'revenuePpc', 'revenueDsp'].includes(
        name,
      ) &&
        isMetricsDisabled.revenueTotal &&
        isMetricsDisabled.revenueOrganic &&
        isMetricsDisabled.revenuePpc &&
        isMetricsDisabled.revenueDsp)
    ) {
      selectedClass = 'order-chart-box fix-height disabled';
    } else {
      selectedClass = 'order-chart-box fix-height';
    }
    return selectedClass;
  };

  const setBoxToggle = (name) => {
    const localMetrics = selectedSalesMetrics;
    if (
      Object.prototype.hasOwnProperty.call(selectedSalesMetrics, name) &&
      _.size(selectedSalesMetrics) > 1
    ) {
      setSelectedSalesMetrics(_.omit(selectedSalesMetrics, [name]));
      // enable the time frame filter when we don't have a disabled selected metrics.
      const commanElement = _.intersectionWith(
        _.keys(localMetrics),
        _.keys(_.pickBy(isMetricsDisabled)),
        _.isEqual,
      );

      if (commanElement.length === 1 && name === commanElement[0]) {
        setIsDisabledTimeFrame(false);
      }
      if (name === 'revenuePpc' || name === 'revenueDsp') {
        setSelectedRevenueDropDownOption({
          id: 1,
          label: 'Total',
          value: 'revenueTotal',
        });
        setSelectedRevenueType('revenueTotal');
      }
    } else if (_.size(selectedSalesMetrics) < 4) {
      setSelectedSalesMetrics(
        _.omit(_.assign(selectedSalesMetrics, { [name]: true })),
      );
    }
  };

  const onHandleRevenueSubType = (event) => {
    setSelectedRevenueDropDownOption(event);
    setSelectedRevenueType(event.value);

    let metrics = selectedSalesMetrics;
    metrics = _.omit(metrics, [selectedRevenueType]);
    metrics = _.assign(metrics, { [event.value]: true });
    setSelectedSalesMetrics(metrics);
  };

  const returnDisbaledClass = (current, previous) => {
    return !current && !previous ? 'disabled' : null;
  };

  const returnTooltipMessge = (key = '') => {
    return `No ${key === 'Tacos' ? 'TACOS' : key} available for this period.`;
  };

  // Ad spend code starts
  const onHandleAdSpendSubType = (event) => {
    setSelectedAdSpendDropDownOption(event);
    setSelectedAdSpendType(event.value);

    let metrics = selectedSalesMetrics;
    metrics = _.omit(metrics, [selectedAdSpendType]);
    metrics = _.assign(metrics, { [event.value]: true });
    setSelectedSalesMetrics(metrics);
  };
  // Ad spend code ends

  // display metrics box html
  const displayMetricsBoxHTML = (
    id,
    metricsName,
    metricsKey,
    metricsNameKey,
    classNameKey,
    currentValue,
    previousValue,
    differenceValue,
    prefix,
    suffix,
    formatNumber,
  ) => {
    return (
      <CardMetrics
        data-tip={returnTooltipMessge(metricsName)}
        data-for={metricsKey}
        className="fix-height"
      >
        <div
          id={id}
          role="presentation"
          onClick={() => setBoxToggle(metricsNameKey)}
          className={setBoxClasses(metricsNameKey, classNameKey)}
        >
          <div className="chart-name">{metricsName}</div>
          <div className="number-rate">
            {currentValue === 0
              ? `${prefix}0${suffix}`
              : currentValue
              ? `${prefix}${formatNumber(currentValue, '')}${suffix}`
              : 'N/A'}
          </div>
          <div className="vs">
            vs{' '}
            {previousValue === 0
              ? `${prefix}0${suffix}`
              : previousValue
              ? `${prefix}${formatNumber(previousValue, '')}${suffix}`
              : 'N/A'}
          </div>
          {differenceValue === 0 ? (
            <div className="perentage-value mt-3 pt-1">0%</div>
          ) : differenceValue ? (
            differenceValue > 0 ? (
              <div className="perentage-value mt-3 pt-1">
                <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
                {differenceValue}%
              </div>
            ) : (
              <div className="perentage-value down mt-3 pt-1">
                <img
                  className="red-arrow"
                  src={ArrowDownIcon}
                  alt="arrow-down"
                />
                {differenceValue.toString().replace('-', '')}%
              </div>
            )
          ) : (
            <div className="perentage-value grey mt-3 pt-1">N/A</div>
          )}
        </div>
      </CardMetrics>
    );
  };

  const renderSalesMetrics = (formatNumber) => {
    const currencySign = currencySymbol !== null ? currencySymbol : '';
    const name = selectedRevenueType;
    const responseKey = metricsResponseKey[selectedRevenueType];

    const adSpendName = selectedAdSpendType;
    const adSpendresponseKey = metricsResponseKey[selectedAdSpendType];
    const salesCurrentValue =
      salesCurrentTotal && salesCurrentTotal[responseKey];
    const salesPreviousValue =
      salesPreviousTotal && salesPreviousTotal[responseKey];
    const adSpendCurrentValue =
      salesCurrentTotal && salesCurrentTotal[adSpendresponseKey];
    const adSpendPreviousValue =
      salesPreviousTotal && salesPreviousTotal[adSpendresponseKey];

    return (
      <div>
        <CardMetricsOuter className="row mr-1 ml-1">
          <div className="cardMetricsInner col-lg-3 col-md-3 pr-1 pl-0 col-6 mb-2">
            <CardMetrics
              data-tip={returnTooltipMessge(
                metricsTootlNames[selectedRevenueDropDownOption?.value],
              )}
              data-for={selectedRevenueDropDownOption?.value}
              className="fix-height"
            >
              <div
                id="BT-sale-revenuecard"
                onClick={() => setBoxToggle(name)}
                role="presentation"
                className={`${setBoxClasses(
                  name,
                  'ad-sales-active',
                )} ${returnDisbaledClass(
                  salesCurrentValue,
                  salesCurrentValue,
                )}`}
              >
                <div className="row">
                  <div
                    className="chart-name col-5 pr-0 "
                    data-tip="Total Sales"
                    data-for="total-sales"
                  >
                    {shortTileName('Total Sales')}
                  </div>
                  <div className="col-7 mt-n2 pl-1">
                    <CustomDropDown
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="revenue-sub-type"
                      id="BT-SalesPerformanceTimeFrame"
                      inputColor={Theme.white}
                      style={{
                        marginTop: '-2px',
                      }}
                    >
                      <Select
                        fontSize="12px"
                        classNamePrefix="react-select"
                        className={`active ${
                          salesGraphLoader ? `disabled` : ''
                        }`}
                        options={revenuMetricsSubTypes}
                        placeholder={revenuMetricsSubTypes[0].label}
                        components={revenueSubtypeComponent()}
                        value={selectedRevenueDropDownOption}
                        isSearchable={false}
                        onChange={(event) => onHandleRevenueSubType(event)}
                        isOptionDisabled={(option) => {
                          return isMetricsDisabled[option.value];
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            neutral50: '#fff',
                            primary25: Theme.lightOrange,
                            primary: Theme.orange,
                            primary50: Theme.lightOrange,
                          },
                        })}
                      />
                    </CustomDropDown>
                  </div>
                </div>
                <div className="number-rate">
                  {salesCurrentValue === 0
                    ? `${currencySign}0`
                    : salesCurrentValue
                    ? formatNumber(salesCurrentValue, currencySign)
                    : 'N/A'}
                </div>
                <div className="vs">
                  vs{' '}
                  {salesPreviousValue === 0
                    ? `${currencySign}0`
                    : salesPreviousValue
                    ? formatNumber(salesPreviousValue, currencySign)
                    : 'N/A'}
                </div>
                {salesDifference && salesDifference[responseKey] ? (
                  salesDifference[responseKey] >= 0 ? (
                    <div className="perentage-value mt-3 pt-1">
                      <img
                        className="green-arrow"
                        src={ArrowUpIcon}
                        alt="arrow-down"
                      />
                      {salesDifference[responseKey]}%
                    </div>
                  ) : (
                    <div className="perentage-value down mt-3 pt-1">
                      <img
                        className="red-arrow"
                        src={ArrowDownIcon}
                        alt="arrow-down"
                      />
                      {salesDifference[responseKey].toString().replace('-', '')}
                      %
                    </div>
                  )
                ) : (
                  <div className="perentage-value down mt-3 pt-1">N/A</div>
                )}
              </div>
            </CardMetrics>
            <ReactTooltip
              class="initialTextTransform"
              id="total-sales"
              aria-haspopup="true"
              place="right"
              effect="solid"
            />
          </div>

          {/* tacos start */}
          <div className="cardMetricsInner col-lg-3 col-md-3 pr-1 pl-1 col-6 mb-2">
            {displayMetricsBoxHTML(
              'BT-sale-tacoscard',
              'Tacos',
              'tacos',
              'tacos',
              'sales-tacos-active',
              salesCurrentTotal?.tacos,
              salesPreviousTotal?.tacos,
              salesDifference?.tacos,
              '',
              '%',
              formatNumber,
            )}
          </div>
          {/* tacos Ends */}
          <div className="cardMetricsInner col-lg-3 col-md-3 pr-1 pl-1 col-6 mb-2">
            {displayMetricsBoxHTML(
              'BT-sale-trafficcard',
              'Traffic',
              'traffic',
              'traffic',
              'ad-conversion-active',
              salesCurrentTotal?.traffic,
              salesPreviousTotal?.traffic,
              salesDifference?.traffic,
              '',
              '',
              formatNumber,
            )}
          </div>
          <div className="cardMetricsInner col-lg-3 col-md-3 pr-1 pl-1 col-6 mb-2">
            {displayMetricsBoxHTML(
              'BT-sale-conversioncard',
              'Conversion',
              'conversion',
              'conversion',
              'impression-active',
              salesCurrentTotal?.conversion,
              salesPreviousTotal?.conversion,
              salesDifference?.conversion,
              '',
              '%',
              formatNumber,
            )}
          </div>
          <div className="cardMetricsInner col-lg-3 col-md-3 pr-1 pl-1 col-6 mb-2">
            {displayMetricsBoxHTML(
              'BT-sale-unitsoldcard',
              'Unit Sold',
              'units_sold',
              'unitsSold',
              'ad-spend-active',
              salesCurrentTotal?.units_sold,
              salesPreviousTotal?.units_sold,
              salesDifference?.units_sold,
              '',
              '',
              formatNumber,
            )}
          </div>

          {/* ad spend start */}
          <div className="cardMetricsInner col-lg-3 col-md-3 pr-1 pl-0 col-6 mb-2">
            <CardMetrics
              data-tip={returnTooltipMessge(
                metricsTootlNames[selectedAdSpendDropDownOption?.value],
              )}
              data-for={selectedAdSpendDropDownOption.value}
              className="fix-height"
            >
              <div
                id="BT-sale-adspendcard"
                onClick={() => setBoxToggle(adSpendName)}
                role="presentation"
                className={`${setBoxClasses(
                  adSpendName,
                  'ad-spend-total-active',
                )} ${returnDisbaledClass(
                  adSpendCurrentValue,
                  adSpendCurrentValue,
                )}`}
              >
                <div className="row">
                  <div
                    className="chart-name col-5 pr-0 "
                    data-tip="Total Ad Spend"
                    data-for="total-ad-spend"
                  >
                    {shortTileName('Total Ad Spend')}
                  </div>
                  <div className="col-7 mt-n2 pl-1">
                    <CustomDropDown
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="revenue-sub-type"
                      id="BT-SalesPerformanceTimeFrame"
                      inputColor={Theme.white}
                      style={{
                        marginTop: '-2px',
                      }}
                    >
                      <Select
                        fontSize="12px"
                        classNamePrefix="react-select"
                        className={`active ${
                          salesGraphLoader ? `disabled` : ''
                        }`}
                        options={adSpendMetricsSubTypesOptions}
                        placeholder={adSpendMetricsSubTypesOptions[0].label}
                        components={revenueSubtypeComponent()}
                        value={selectedAdSpendDropDownOption}
                        isSearchable={false}
                        onChange={(event) => onHandleAdSpendSubType(event)}
                        isOptionDisabled={(option) => {
                          return isMetricsDisabled[option.value];
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            neutral50: '#fff',
                            primary25: Theme.lightOrange,
                            primary: Theme.orange,
                            primary50: Theme.lightOrange,
                          },
                        })}
                      />
                    </CustomDropDown>
                  </div>
                </div>
                <div className="number-rate">
                  {adSpendCurrentValue === 0
                    ? `${currencySign}0`
                    : adSpendCurrentValue
                    ? formatNumber(adSpendCurrentValue, currencySign)
                    : 'N/A'}
                </div>
                <div className="vs">
                  vs{' '}
                  {adSpendPreviousValue === 0
                    ? `${currencySign}0`
                    : adSpendPreviousValue
                    ? formatNumber(adSpendPreviousValue, currencySign)
                    : 'N/A'}
                </div>
                {salesDifference && salesDifference[adSpendresponseKey] ? (
                  salesDifference[adSpendresponseKey] >= 0 ? (
                    <div className="perentage-value mt-3 pt-1">
                      <img
                        className="green-arrow"
                        src={ArrowUpIcon}
                        alt="arrow-down"
                      />
                      {salesDifference[adSpendresponseKey]}%
                    </div>
                  ) : (
                    <div className="perentage-value down mt-3 pt-1">
                      <img
                        className="red-arrow"
                        src={ArrowDownIcon}
                        alt="arrow-down"
                      />
                      {salesDifference[adSpendresponseKey]
                        .toString()
                        .replace('-', '')}
                      %
                    </div>
                  )
                ) : (
                  <div className="perentage-value down mt-3 pt-1">N/A</div>
                )}
              </div>
            </CardMetrics>
            <ReactTooltip
              class="initialTextTransform"
              id="total-ad-spend"
              aria-haspopup="true"
              place="right"
              effect="solid"
            />
          </div>
          {/* ad spend ends */}
        </CardMetricsOuter>
      </div>
    );
  };

  return (
    <>
      {showMetricsTooltipForSales.map((key) => {
        let keyName = key;
        const revenueKeys = [
          'revenue',
          'revenue_ppc',
          'revenue_dsp',
          'revenue_organic',
        ];
        const adSpendKeys = [
          'ad_spend_total',
          'sponsored_ad_spend',
          'dsp_spends',
        ];

        const keyNameOptions = key?.includes('revenue')
          ? revenueKeys
          : adSpendKeys;
        if (keyNameOptions.includes(key)) {
          keyName =
            revenuSubTypeKeys[
              key?.includes('revenue')
                ? selectedRevenueDropDownOption.value
                : selectedAdSpendDropDownOption.value
            ];
        }

        return (
          <Fragment key={key}>
            {!salesCurrentTotal[keyName] &&
            !salesPreviousTotal[keyName] &&
            !salesGraphLoader ? (
              <ReactTooltip
                id={
                  [...keyNameOptions].includes(key)
                    ? key?.includes('revenue')
                      ? selectedRevenueDropDownOption.value
                      : selectedAdSpendDropDownOption.value
                    : key
                }
                class="initialTextTransform"
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />
            ) : null}
          </Fragment>
        );
      })}
      {renderSalesMetrics(useFormatNumber)}
    </>
  );
};

export default SalesSellerMetrics;

SalesSellerMetrics.defaultProps = {
  salesGraphLoader: false,
  selectedRevenueType: '',
  currencySymbol: '',
  selectedAdSpendType: '',
  data: {},
  selectedSalesMetrics: {},
  isMetricsDisabled: {},
  salesCurrentTotal: {},
  salesPreviousTotal: {},
  salesDifference: {},
  selectedRevenueDropDownOption: {},
  setSelectedSalesMetrics: () => {},
  setSelectedRevenueType: () => {},
  setIsDisabledTimeFrame: () => {},
  setSelectedRevenueDropDownOption: () => {},
  selectedAdSpendDropDownOption: {},
  setSelectedAdSpendDropDownOption: () => {},
  setSelectedAdSpendType: () => {},
};

SalesSellerMetrics.propTypes = {
  salesGraphLoader: bool,
  currencySymbol: string,
  selectedRevenueType: string,
  selectedAdSpendType: string,
  isMetricsDisabled: shape({}),
  data: shape({}),
  selectedRevenueDropDownOption: shape({}),
  selectedSalesMetrics: instanceOf(Object),
  salesCurrentTotal: instanceOf(Object),
  salesPreviousTotal: instanceOf(Object),
  salesDifference: instanceOf(Object),
  setSelectedRevenueType: func,
  setSelectedSalesMetrics: func,
  setIsDisabledTimeFrame: func,
  setSelectedRevenueDropDownOption: func,
  selectedAdSpendDropDownOption: shape({}),
  setSelectedAdSpendDropDownOption: func,
  setSelectedAdSpendType: func,
};

const CardMetricsOuter = styled.div`
  @media only screen and (min-width: 779px) {
    // Add Horizontal scroll
    display: inline-block;
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;

    /* width */
    ::-webkit-scrollbar {
      width: none !important;
      height: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #bfc3d2;
      border-radius: 10px;
    }

    /* Handle on hover */
    /* ::-webkit-scrollbar-thumb:hover {
      background: #555;
    } */

    .cardMetricsInner {
      display: inline-block;
    }
  }
`;
