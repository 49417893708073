import React, { useState, useEffect, Fragment } from 'react';

import Select from 'react-select';
import Modal from 'react-modal';
import styled from 'styled-components';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../../theme/Theme';
import {
  CloseIcon,
  DSPAdPerformance,
  InventoryManagement,
  NextWhiteLogo,
  OverallSalesPerformance,
  SponsoredAdPerformance,
} from '../../theme/images';
import { FormContainer } from '../../theme/Global';
import { getCustomerNames, getEmail, saveRequestDemo } from '../../api';
import { clearErrorMessage, login } from '../../store/actions/userState';
import {
  Button,
  ErrorMsg,
  InputFormField,
  PageLoader,
  SuccessMsg,
  ContractInputSelect,
  ModalBox,
} from '../../common';
import {
  PATH_AMAZON_ACCOUNT,
  PATH_AMAZON_MERCHANT,
  PATH_BILLING_DETAILS,
  PATH_COMPANY_DETAILS,
  PATH_CUSTOMER_DETAILS,
  PATH_CUSTOMER_LIST,
  PATH_SUMMARY,
  requestDemoDetails,
} from '../../constants';

export default function AmazonLogin() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, clearErrors } = useForm();
  const {
    register: registerForDemo,
    errors: errorsForDemo,
    handleSubmit: handleSubmitForDemo,
  } = useForm();
  const loader = useSelector((state) => state.userState.isLoading);
  const apiError = useSelector((state) => state.userState.error);
  const resetPasswordMsg = useSelector((state) => state.userState.showResetMsg);
  const [showPassword, setShowPassword] = useState({
    password: false,
    name: false,
    email: true,
  });
  const [customerApiError, setCustomerApiError] = useState([]);
  const [isLoading, setIsLoading] = useState({ loader: false, type: 'button' });
  const [customerNames, setCustomerNames] = useState([]);
  const [getName, setGetName] = useState({ email: '', customer: '' });
  const [forgotApiError, setforgotApiError] = useState([]);
  const [requestDemo, setRequestDemo] = useState(false);
  const params = queryString.parse(history.location.search);
  const role = localStorage.getItem('role') || '';
  const customerId = localStorage.getItem('customer') || '';
  const step = JSON.parse(localStorage.getItem('step')) || '';
  const customStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '440px ',
      width: '100% ',
      minHeight: '494px',
      overlay: ' {zIndex: 1000}',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (role.includes('Customer') || role === 'Customer') {
        const id =
          step &&
          Object.keys(step) &&
          Object.keys(step).find((op) => op === customerId);
        if (step === null || id === '' || id === null)
          history.push(PATH_COMPANY_DETAILS);
        if (step[id] === 1) history.push(PATH_COMPANY_DETAILS);
        if (step[id] === 2) history.push(PATH_BILLING_DETAILS);
        if (step[id] === 3) history.push(PATH_AMAZON_MERCHANT);
        if (step[id] === 4) history.push(PATH_AMAZON_ACCOUNT);
        if (step[id] === 5) history.push(PATH_SUMMARY);
        if (step[id] === 6)
          history.push(PATH_CUSTOMER_DETAILS.replace(':id', id));
      } else history.push(PATH_CUSTOMER_LIST);
    }
  }, [history, role, customerId, step, dispatch]);

  const onSubmit = (data) => {
    setIsLoading({ loader: true, type: 'button' });
    if (showPassword.email && !showPassword.password) {
      setGetName({ ...getName, email: data.email });
      if (params && params.customer && params.step) {
        setGetName({
          email: data.email,
          customer: params && params.customer,
        });
        setShowPassword({ email: false, name: false, password: true });
        localStorage.setItem('customer', params?.customer);
        setIsLoading({ loader: false, type: 'button' });
      } else {
        getCustomerNames(data.email).then((response) => {
          if (response?.status === 200) {
            if (response?.data?.length === 0 || response?.data === '') {
              setShowPassword({ name: false, password: true });
            } else if (response?.data?.length === 1) {
              setCustomerNames(response?.data);
              setGetName({
                email: data.email,
                customer: response?.data?.[0]?.value,
              });
              setShowPassword({ email: false, name: false, password: true });
            } else {
              setCustomerNames(response?.data);
              setShowPassword({ name: true, password: false });
            }
            setIsLoading({ loader: false, type: 'button' });
          }
          if (response?.status === 400) {
            setCustomerApiError(response?.data);
            setIsLoading({ loader: false, type: 'button' });
          }
        });
      }
    } else if (showPassword.name) {
      setShowPassword({ password: true, email: false, name: false });
      setIsLoading({ loader: false, type: 'button' });
    } else {
      const detail = {
        ...data,
        email: getName.email,
      };
      localStorage.setItem('customer', getName.customer);
      dispatch(login(history, detail, { customer: getName.customer }, ''));
      setIsLoading({ loader: false, type: 'button' });
    }
  };

  const handleChange = () => {
    dispatch(clearErrorMessage());
    setCustomerApiError([]);
    setforgotApiError([]);
  };

  const forgotPassword = () => {
    setIsLoading({ loader: true, type: 'forgot' });
    getEmail({ email: getName.email }).then((response) => {
      if (response?.status === 400) {
        setforgotApiError(response?.data?.[0]);
        setIsLoading({ loader: false, type: 'forgot' });
        setShowPassword({ email: true, name: false, password: false });
      } else {
        toast.success(
          'We have emailed you a reset link, please check your email.',
        );
        setShowPassword({ email: false, name: false, password: true });
        setIsLoading({ loader: false, type: 'forgot' });
      }
    });
  };

  const onDemoSubmit = (data) => {
    setIsLoading({ loader: true, type: 'demo' });
    saveRequestDemo(data, requestDemo.type).then((res) => {
      if (res?.status === 201) {
        if (requestDemo.type === 'demo') {
          toast.success(
            'Request demo for you is in progress. We will get back to you shortly.',
          );
        }
        if (requestDemo.type === 'sign up') {
          toast.success(
            'We will be in touch soon to help you set up your account.',
          );
        }
        setRequestDemo(false);
        setIsLoading({ loader: false, type: 'demo' });
      }
    });
  };

  return (
    <>
      <FormContainer style={{ height: 'auto' }}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          pauseOnFocusLoss={false}
        />
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-6 col-12  pl-0 pr-0  ">
              <div className="banner-bg   " />
            </div>
            <div className="col-lg-6 col-12  amazon-mobile-overlay">
              <div className="inner-container">
                <div className={showPassword.email ? ' ' : ' '}>
                  <div className={showPassword.email ? ' ' : ' '}>
                    <div className="amazon-white-logo">
                      <img
                        width="100px"
                        height="32px"
                        src={NextWhiteLogo}
                        alt="logo "
                      />
                    </div>

                    <div className="login-success-msg">
                      {resetPasswordMsg ? (
                        <SuccessMsg message="New Password set!" />
                      ) : (
                        ''
                      )}
                    </div>

                    {!showPassword.email ? (
                      <>
                        {' '}
                        <div className="row">
                          <div className="col-12">
                            <div
                              style={{ color: Theme.white }}
                              role="presentation"
                              className="back-link"
                              onClick={() =>
                                customerNames?.length === 0 ||
                                customerNames?.length === 1 ||
                                customerNames === ''
                                  ? setShowPassword({
                                      email: true,
                                      name: false,
                                      password: false,
                                    })
                                  : setShowPassword({
                                      email: !!showPassword.name,
                                      name: !!showPassword.password,
                                    })
                              }>
                              <FontAwesomeIcon
                                icon="fa-regular fa-arrow-left"
                                className="ml-1 mr-2"
                                style={{ fontSize: '12px', color: '#ff4817' }}
                              />
                              Back a step
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    {!showPassword.email &&
                    (showPassword.name || showPassword.password) ? (
                      <h2 className="mb-0 mt-3" style={{ color: Theme.white }}>
                        {showPassword.name ? 'Select Account' : 'Welcome Back!'}
                      </h2>
                    ) : (
                      ''
                    )}
                    {showPassword.password ? (
                      <p className=" sub-text">
                        Please verify that it&apos;s you.
                      </p>
                    ) : showPassword.name ? (
                      <p className=" sub-text">
                        Please select the account you want to sign into.
                      </p>
                    ) : (
                      ''
                    )}

                    {showPassword?.email ? (
                      <>
                        <div className="status-label">
                          for amazon sellers and vendors
                        </div>
                        <div className="page-title" style={{ color: 'white' }}>
                          One tool to track your sales, advertising & inventory
                          performance.{' '}
                        </div>
                        <div
                          className="black-heading-title mt-4 text-medium mb-4"
                          style={{ color: 'white' }}>
                          Ideal for any seller or vendor on the Amazon
                          marketplace who wants to gain a deep understanding of
                          how their accounts are performing.
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <form key={1} onSubmit={handleSubmit(onSubmit)}>
                      {showPassword.email ? (
                        <>
                          <InputFormField style={{ marginTop: '86px' }}>
                            <label htmlFor="emailAddress">
                              Login with email
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Enter your Email Address"
                                onChange={() => handleChange()}
                                id="emailAddress"
                                name="email"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: 'This field is required.',
                                  },
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address.',
                                  },
                                })}
                              />
                            </label>
                          </InputFormField>
                          <ErrorMsg>
                            {errors?.email?.message || customerApiError?.[0]}
                          </ErrorMsg>
                          <ErrorMsg>{forgotApiError}</ErrorMsg>
                        </>
                      ) : (
                        <>
                          {showPassword.name ? (
                            <ContractInputSelect>
                              <label htmlFor="account" className="mb-2">
                                Select Account
                                <Select
                                  options={customerNames}
                                  onChange={(event) =>
                                    setGetName({
                                      ...getName,
                                      customer: event.value,
                                    })
                                  }
                                  defaultValue={getName.customer}
                                />
                              </label>
                            </ContractInputSelect>
                          ) : (
                            <>
                              {showPassword.password ? (
                                <>
                                  <InputFormField>
                                    <label htmlFor="password">
                                      Enter password
                                      <br />
                                      <input
                                        className="form-control"
                                        type="password"
                                        name="password"
                                        onChange={() => handleChange()}
                                        placeholder="Enter your Password"
                                        id="password"
                                        ref={register({
                                          required: {
                                            value: true,
                                            message: 'This field is required.',
                                          },
                                          minLength: {
                                            value: 8,
                                            message:
                                              'Password must have at least 8 characters.',
                                          },
                                        })}
                                      />
                                    </label>
                                  </InputFormField>
                                  <ErrorMsg>
                                    {errors?.password?.message}
                                  </ErrorMsg>
                                  <ErrorMsg>
                                    {apiError?.data?.non_field_errors?.[0]}
                                  </ErrorMsg>
                                  <ErrorMsg>{forgotApiError}</ErrorMsg>
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </>
                      )}

                      <Button
                        className="btn-primary w-100 mt-3 mb-3"
                        disabled={
                          (showPassword.name && getName.customer === '') ||
                          (isLoading.loader && isLoading.type === 'forgot')
                        }>
                        {loader ||
                        (isLoading.loader && isLoading.type === 'button') ? (
                          <PageLoader color="#fff" type="button" />
                        ) : (
                          <>{showPassword.password ? 'Sign In' : 'Continue'}</>
                        )}
                      </Button>
                      {showPassword?.email ? (
                        <Button
                          className="btn-add-items w-100 text-bold uppercase"
                          login
                          type="button"
                          onClick={() => {
                            setRequestDemo({ value: true, type: 'sign up' });
                            clearErrors();
                          }}>
                          Sign up
                        </Button>
                      ) : null}
                      {showPassword.password ? (
                        <span
                          className=" forgot-pswd-link cursor "
                          onClick={() => forgotPassword()}
                          role="presentation">
                          Forgot password?
                        </span>
                      ) : (
                        ''
                      )}
                      {showPassword.email ? (
                        <div
                          className="orange-text-label cursor mt-4 mb-5"
                          onClick={() => {
                            setRequestDemo({ value: true, type: 'demo' });
                            clearErrors();
                          }}
                          role="presentation">
                          Request a demo
                          <FontAwesomeIcon
                            icon="fa-regular fa-arrow-right"
                            className="ml-1"
                            style={{ fontSize: '12px' }}
                          />
                        </div>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
              {showPassword?.email ? (
                <LandingFooter className="d-none">
                  <div className="light-line straight-line my-3" />
                  <div className=" inner-langing-form">
                    <div className="label-heading large-label">learn more</div>
                    <p
                      className="normal-text-black mt-2 mb-2"
                      style={{ lineHeight: '19px' }}>
                      {' '}
                      We’re a team of expert Amazon consultants who specialize
                      in scaling mid-to-large product brands.
                    </p>

                    <div
                      className="orange-text-label cursor"
                      onClick={() => {
                        setRequestDemo({ value: true, type: 'demo' });
                        clearErrors();
                      }}
                      role="presentation">
                      Request a demo
                      <FontAwesomeIcon
                        icon="fa-regular fa-arrow-right"
                        className="ml-1"
                        style={{ fontSize: '12px' }}
                      />
                    </div>
                  </div>
                  <div className="light-line straight-line my-3" />
                  <div className=" inner-langing-form">
                    <p className="normal-text-black">
                      {' '}
                      {`© ${new Date().getFullYear()}  Buy Box Experts`}
                    </p>
                  </div>
                </LandingFooter>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </FormContainer>

      <FormContainer>
        <div className="amazon-screen-container">
          <div className="amazon-screen-inner-container">
            <div className="what-next-conatiner">
              <div className="page-title mt-4">
                What is NE<span style={{ color: Theme.orange }}>X</span>T?{' '}
              </div>
              <h1 className="medium-size-page-heading text-regular mt-3">
                NEXT provides reporting features to track how your account is
                performing on Amazon&apos;s Seller and Vendor Central in terms
                of sales, advertising campaigns, and Inventory
              </h1>
            </div>
          </div>
        </div>
        <div className="next-features-bg">
          <div className="amazon-screen-inner-container">
            <div className="container-fluid">
              <div
                className="page-title mt-4 text-center"
                style={{ color: Theme.white }}>
                What features can you expect in NE
                <span style={{ color: Theme.orange }}>X</span>T?{' '}
              </div>
              <div className="normal-text-black text-center">
                Dashboards allowing users to track their performance in the
                following areas
              </div>
              <div className="row py-5">
                <div className="col-md-6 col-12 pt-5">
                  <img
                    height="84px"
                    width="96px"
                    src={SponsoredAdPerformance}
                    alt="sponsoredAdPer "
                  />
                  <h5 className="sub-title-text my-3">
                    Sponsored Ad Performance
                  </h5>
                  <div className="normal-text-black  mb-4">
                    Users can track their sponsored advertising campaigns&apos;
                    performance
                  </div>
                </div>
                <div className="col-md-6 col-12 pt-5">
                  <img
                    height="84px"
                    width="96px"
                    src={DSPAdPerformance}
                    alt="DSPAdPer "
                  />
                  <h5 className="sub-title-text my-3">DSP Ad Performance</h5>
                  <div className="normal-text-black  mb-4">
                    Users can track their DSP advertising campaigns&apos;
                    performance
                  </div>
                </div>
                <div className="col-md-6 col-12 pt-5">
                  <img
                    height="84px"
                    width="96px"
                    src={InventoryManagement}
                    alt="InventoryManagement "
                  />
                  <h5 className="sub-title-text my-3">Inventory Management</h5>
                  <div className="normal-text-black  mb-4">
                    Users can track their inventory to manage it effectively
                  </div>
                </div>
                <div className="col-md-6 col-12 pt-5">
                  <img
                    height="84px"
                    width="96px"
                    src={OverallSalesPerformance}
                    alt="OverallSalesPerformance "
                  />
                  <h5 className="sub-title-text my-3">Sales Performance</h5>
                  <div className="normal-text-black  mb-4">
                    Users can track both overall sales performance and ASIN
                    level sales performance
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="amazon-screen-container">
          <div className="amazon-screen-inner-container">
            <div className="container-fluid">
              <div className="what-next-conatiner">
                <h1 className="page-title mt-4 text-center">
                  Simple, transparent{' '}
                  <span style={{ color: Theme.orange }}>pricing</span>.
                  <br />
                  Always know what you’ll pay.
                </h1>

                <div className="row pt-5 mt-3">
                  <div className="col-md-6 col-12 mb-3">
                    <div className="card-container">
                      <h3 className=" mt-1">Monthly Subscription</h3>

                      <div
                        className="page-title my-3"
                        style={{ color: Theme.orange }}>
                        $499
                        <span
                          style={{
                            fontSize: Theme.secondaryTitleFontSize,
                            color: Theme.black,
                          }}>
                          /month
                        </span>{' '}
                      </div>
                      <Button
                        className="btn-primary w-100 mt-2 "
                        onClick={() => {
                          setRequestDemo({ value: true, type: 'demo' });
                          clearErrors();
                        }}>
                        REQUEST A DEMO
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 ">
                    <div className="card-container">
                      <h3 className=" mt-1">Annual Subscription</h3>

                      <div
                        className="page-title my-3"
                        style={{ color: Theme.orange }}>
                        $2,499
                        <span
                          style={{
                            fontSize: Theme.secondaryTitleFontSize,
                            color: Theme.black,
                          }}>
                          /year
                        </span>{' '}
                      </div>
                      <Button
                        className="btn-primary w-100 mt-2"
                        onClick={() => {
                          setRequestDemo({ value: true, type: 'demo' });
                          clearErrors();
                        }}>
                        REQUEST A DEMO
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="next-features-bg">
          <div className="amazon-screen-inner-container text-center py-4">
            <div className="amazon-white-logo ">
              <img
                width="100px"
                height="32px"
                src={NextWhiteLogo}
                alt="logo "
              />
            </div>
            <div className="copy-right pt-3">© 2022 Buy Box Experts</div>
          </div>
        </div>
      </FormContainer>
      <Modal isOpen={requestDemo.value} ariaHideApp={false} style={customStyle}>
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => setRequestDemo(false)}
          role="presentation"
        />
        <ModalBox>
          <form key={2} onSubmit={handleSubmitForDemo(onDemoSubmit)}>
            <div className="modal-body ">
              <div className="row">
                <div className="col-12 modal-heading">
                  <h4>
                    {requestDemo?.type === 'demo'
                      ? 'Request a Demo'
                      : 'Sign Up'}
                  </h4>
                </div>
              </div>
              {requestDemoDetails.map((item) => (
                <Fragment key={item.key}>
                  <InputFormField className="mt-3">
                    <label htmlFor={item.key}>
                      {item.label}
                      <br />
                      <input
                        className="form-control"
                        type="text"
                        placeholder={`Enter your ${item.label}`}
                        onChange={() => handleChange()}
                        id={item.key}
                        name={item.key}
                        ref={registerForDemo({
                          required: {
                            value: true,
                            message: 'This field is required.',
                          },
                          pattern: item.pattern,
                        })}
                      />
                    </label>
                  </InputFormField>
                  <ErrorMsg>{errorsForDemo?.[item.key]?.message}</ErrorMsg>
                </Fragment>
              ))}
              <Button className="btn-primary w-100 mt-4 mb-3">
                {loader || (isLoading.loader && isLoading.type === 'demo') ? (
                  <PageLoader color="#fff" type="button" />
                ) : (
                  'Submit'
                )}
              </Button>
            </div>
          </form>
        </ModalBox>
      </Modal>
    </>
  );
}

const LandingFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 50%;
  background: #fff;
  @media only screen and (max-width: 991.98px) {
    width: 100%;
    color: ${Theme.white};
    background: transparent;
    .large-label {
      color: ${Theme.white};
    }
    .normal-text-black {
      color: ${Theme.white};
    }
    .normal-text-black {
      color: ${Theme.white};
    }
  }
`;
