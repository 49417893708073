import React, { useEffect, useState, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import Select from 'react-select';
import { useSelector } from 'react-redux';
import { arrayOf, string, shape, bool, func } from 'prop-types';
import { toast } from 'react-toastify';
import {
  getPlaybookServicesData,
  sendForApprovalOfPlaybook,
} from '../../../../api';
import { playbookSummaryMonthOptions } from '../../../../constants';
import {
  CheckBox,
  DesktopTable,
  WhiteCard,
  Button,
  PageLoader,
  ErrorMsg,
} from '../../../../common';
import Theme from '../../../../theme/Theme';
import {
  BorderLessDropDown,
  TaskStatus,
  SummaryMobileSelect,
  PlaybookSummaryMobileView,
} from './PlaybookStyle';
import { renderAssigneeTooltip } from './CommonFunctions';

function PlaybookServicesTab({
  customerId,
  playbookTab,
  selectedMarketplaces,
  selectedTeamMembers,
  customStartDate,
  accountType,
  onCreatePlaybookClick,
  createPlaybookBtnLoader,
  singleSelectedMarketplaces,
}) {
  const isDesktop = useMediaQuery({ maxWidth: 767 });
  const userInfo = useSelector((state) => state.userState.userInfo);
  const [playbookServicesData, setPlaybookServicesData] = useState({});
  const [playbookServicesLoader, setPlaybookServicesLoader] = useState(false);
  const [sendForApprovalBtnLoader, setSendForApprovalBtnLoader] =
    useState(false);
  const [selectedServicesForApproval, setSelectedServicesForApproval] =
    useState([]);

  const isUserHaveAccessOtherServices =
    userInfo?.role === 'BGS' ||
    userInfo?.role === 'TAM' ||
    userInfo?.role === 'BGS Admin' ||
    userInfo?.role === 'Ad Manager Admin';

  const playbookCreateAccess =
    userInfo?.role === 'BGS' ||
    userInfo?.role === 'BGS Admin' ||
    userInfo?.role === 'Ad Manager Admin';

  const getPlaybookServices = useCallback(() => {
    setPlaybookServicesLoader(true);

    getPlaybookServicesData(
      customerId,
      playbookTab,
      singleSelectedMarketplaces,
      selectedTeamMembers,
      customStartDate,
      accountType?.value,
    ).then((res) => {
      setPlaybookServicesLoader(false);
      setPlaybookServicesData(res?.data?.tasks);
    });
  }, [
    customerId,
    playbookTab,
    singleSelectedMarketplaces,
    selectedTeamMembers,
    customStartDate,
    accountType,
  ]);

  useEffect(() => {
    getPlaybookServices();
  }, [getPlaybookServices]);

  const handleChangeOfServiceCheckbox = (serviceData, isChecked) => {
    const list = [...selectedServicesForApproval];

    const marketplace = selectedMarketplaces
      ?.filter((mrktplc) => {
        if (mrktplc?.value) return mrktplc;
        return null;
      })
      ?.map((item) => {
        return item.value;
      });

    let startDate = '';
    const updatedDate = new Date(customStartDate);
    updatedDate.setMonth(updatedDate.getMonth() + 1);
    startDate = `${updatedDate.getFullYear()}-${updatedDate.getMonth() + 1}-01`;

    if (isChecked) {
      list.push({
        id: serviceData?.id,
        frequency: '3',
        marketplaces: marketplace,
        account_type: accountType?.value,
        start_date: startDate,
      });
      setSelectedServicesForApproval(list);
    } else {
      const index = list?.findIndex((item) => item?.id === serviceData?.id);
      if (index > -1) {
        list.splice(index, 1);
      }
      setSelectedServicesForApproval(list);
    }
  };

  // const handleCheckAllServices = (isChecked) => {
  //   let marketplacesData = {};

  //   const markeplaceList = [];
  //   // eslint-disable-next-line no-unused-vars
  //   const marketplace = selectedMarketplaces
  //     ?.filter((mrktplc) => {
  //       if (mrktplc?.value) return mrktplc;
  //       return null;
  //     })
  //     ?.map((item) => {
  //       markeplaceList.push(item.value);
  //       return item;
  //     });

  //   if (markeplaceList?.length) {
  //     marketplacesData = markeplaceList?.toString();
  //   }

  //   let startDate = '';
  //   const updatedDate = new Date(customStartDate);
  //   updatedDate.setMonth(updatedDate.getMonth() + 1);
  //   startDate = `${updatedDate.getFullYear()}-${updatedDate.getMonth() + 1}-01`;

  //   if (isChecked) {
  //     setSelectedServicesForApproval(
  //       playbookServicesData
  //         ?.filter((item) => !item?.created)
  //         ?.filter((item) => {
  //           if (isUserHaveAccessOtherServices) {
  //             return item;
  //           }
  //           if (userInfo?.role?.includes(item?.team)) {
  //             return item;
  //           }

  //           return null;
  //         })
  //         ?.map((item) => {
  //           return {
  //             id: item?.id,
  //             frequency: '3',
  //             marketplaces: marketplacesData,
  //             account_type: accountType?.value,
  //             start_date: startDate,
  //           };
  //         }),
  //     );
  //   } else {
  //     setSelectedServicesForApproval([]);
  //   }
  // };

  const handleMonthDropdown = (event, serviceData) => {
    const list = [...selectedServicesForApproval];
    const index = list?.findIndex((item) => item?.id === serviceData?.id);
    let startDate = '';

    if (index > -1) {
      if (event?.value === 'current') {
        startDate = customStartDate;
      } else {
        const updatedDate = new Date(customStartDate);
        updatedDate.setMonth(updatedDate.getMonth() + 1);
        startDate = `${updatedDate.getFullYear()}-${
          updatedDate.getMonth() + 1
        }-01`;
      }
      list[index] = {
        ...list[index],
        frequency: event?.value,
        start_date: startDate,
      };
    }
    setSelectedServicesForApproval(list);
  };

  const setMonthDropdownValue = (serviceData) => {
    const value = selectedServicesForApproval.find(
      (service) => service.id === serviceData?.id,
    )?.frequency;
    if (value) {
      return playbookSummaryMonthOptions?.find((item) => item?.value === value);
    }
    if (serviceData?.created) {
      return playbookSummaryMonthOptions?.find(
        (item) => item?.value === serviceData?.task_frequency,
      );
    }

    return { label: `3 Month`, value: '3' };
  };

  const getListOfTeams = (teams) => {
    let list = [];
    if (teams !== null) {
      if (teams?.includes('/')) {
        list = teams?.split('/');
      } else {
        list = [teams];
      }
    }

    return list.map((item) => item.toLowerCase().trim());
  };

  const setDisabledClass = (serviceData) => {
    let classLabel = '';
    if (serviceData?.created) {
      classLabel = 'disabled';
    } else if (isUserHaveAccessOtherServices) {
      classLabel = '';
    } else if (getListOfTeams(serviceData?.team)?.length) {
      // userInfo?.role === serviceData?.team
      if (
        getListOfTeams(serviceData?.team)?.includes(
          userInfo?.role?.toLowerCase(),
        )
      ) {
        classLabel = '';
      } else {
        classLabel = 'disabled';
      }
    } else {
      classLabel = 'disabled';
    }
    return classLabel;
  };

  // render progress label className
  const renderProgressLabelClassName = (status) => {
    const className =
      status === 'Done'
        ? 'progress-label green-label'
        : status === 'In Progress'
        ? 'progress-label orange-label'
        : status === 'Review'
        ? 'progress-label blue-label'
        : 'progress-label gray-label';
    return className;
  };

  const getInitials = (item) => {
    const firstName =
      (item &&
        item.assignee_first_name &&
        item.assignee_first_name.charAt(0)) ||
      '';
    const lastName =
      (item && item.assignee_last_name && item.assignee_last_name.charAt(0)) ||
      '';
    return firstName + lastName;
  };

  // get initial letter from first name and last name of user name
  const renderGetInitialName = (item) => {
    return (
      <div
        className={`${
          item?.created ? 'avatarName' : ''
        } small-AvatarName ml-0 mb-1`}
      >
        {getInitials(item)}
      </div>
    );
  };

  const renderAssignee = (item) => {
    // render task assignee
    const firstName = item?.assignee_first_name;
    const lastName = item?.assignee_last_name;
    const showAssigneeTooltip = firstName !== null && lastName !== null;

    return showAssigneeTooltip ? (
      <div
        key={item?.id}
        className={`task-assignee ${
          playbookTab === 'onboarding-gray' ? '' : 'cursor'
        }`}
        style={{
          display: 'inline-block',
        }}
        role="presentation"
        data-tip
        data-for={item?.id}
      >
        {renderGetInitialName(item)}
        {renderAssigneeTooltip(item, item?.id, 'services-task-assignee')}
      </div>
    ) : null;
  };

  // render deliverable collapse
  const renderPlaybookServiceCollapse = (serviceData) => {
    return (
      <tr className={setDisabledClass(serviceData)}>
        <td className="pt-2">
          <CheckBox>
            <label className="check-container mb-0 " htmlFor={serviceData?.id}>
              <input
                className="checkboxes"
                type="checkbox"
                id={serviceData?.id}
                onClick={(event) => {
                  handleChangeOfServiceCheckbox(
                    serviceData,
                    event?.target?.checked,
                  );
                }}
                checked={selectedServicesForApproval.find(
                  (item) => item.id === serviceData?.id,
                )}
              />
              <span className="checkmark " />
            </label>
          </CheckBox>
        </td>
        <td className="pl-1" role="presentation">
          <span className="text-bold">{serviceData?.name} </span>
        </td>
        <td>
          <BorderLessDropDown>
            <Select
              classNamePrefix="react-select"
              options={playbookSummaryMonthOptions}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: Theme.lightOrange,
                  primary: Theme.orange,
                  primary50: Theme.lightOrange,
                },
              })}
              placeholder="Select.."
              value={setMonthDropdownValue(serviceData)}
              onChange={(event) => handleMonthDropdown(event, serviceData)}
              isOptionDisabled={(option) =>
                option?.value === 'current' &&
                new Date(customStartDate) > new Date()
              }
            />
          </BorderLessDropDown>
        </td>
        <td>{serviceData?.team}</td>
        <td> {renderAssignee(serviceData)} </td>
        <td>
          {serviceData?.status ? (
            <TaskStatus
              className={`${renderProgressLabelClassName(
                serviceData?.status,
              )} `}
            >
              <span
                className={renderProgressLabelClassName(serviceData?.status)}
              >
                {serviceData?.status}
              </span>
            </TaskStatus>
          ) : null}
        </td>
      </tr>
    );
  };

  const onClicksendForApproval = () => {
    setSendForApprovalBtnLoader(true);
    const playbookData = {
      customer: customerId,
      services: selectedServicesForApproval,
    };

    sendForApprovalOfPlaybook(playbookData).then((res) => {
      if (res?.status === 200) {
        getPlaybookServices();
        setSelectedServicesForApproval([]);
        toast.info('Waiting for BGS approval.');
      } else if (res?.status === 500) {
        toast.error('Something went wrong.');
      } else {
        toast.error(res?.data?.message);
      }
      setSendForApprovalBtnLoader(false);
    });
  };

  return playbookServicesLoader ? (
    <PageLoader
      component="performance-graph"
      color="#FF5933"
      type="detail"
      width={40}
      height={40}
    />
  ) : playbookServicesData?.length ? (
    <div>
      {isDesktop ? (
        <PlaybookSummaryMobileView>
          <div className="row">
            <div className="col-6">
              <div className="black-heading-title mt-2">Playbook Summary</div>
            </div>
            <div className="col-6 text-right">
              {playbookCreateAccess ? (
                <>
                  <div />
                  {!selectedServicesForApproval?.every(
                    (item) => item.frequency !== '',
                  ) ? (
                    <ErrorMsg className="mt-2">
                      Please make sure you have selected months for all selected
                      services.
                    </ErrorMsg>
                  ) : null}
                  <Button
                    className="btn-primary mb-3"
                    disabled={
                      !selectedServicesForApproval?.every(
                        (item) => item.frequency !== '',
                      ) || !selectedServicesForApproval?.length
                    }
                    onClick={() =>
                      onCreatePlaybookClick({
                        customer: customerId,
                        'create-playbook': 'true',
                        services: selectedServicesForApproval,
                      })
                    }
                  >
                    {createPlaybookBtnLoader ? (
                      <PageLoader color="#fff" type="button" />
                    ) : (
                      'Create'
                    )}
                  </Button>
                </>
              ) : (
                <>
                  <div />
                  {!selectedServicesForApproval?.every(
                    (item) => item.frequency !== '',
                  ) ? (
                    <ErrorMsg className="mt-2">
                      Please make sure you have selected months for all selected
                      services.
                    </ErrorMsg>
                  ) : null}
                  <Button
                    className={`btn-primary mb-3 `}
                    disabled={
                      !selectedServicesForApproval?.every(
                        (item) => item.frequency !== '',
                      ) || !selectedServicesForApproval?.length
                    }
                    onClick={() => onClicksendForApproval()}
                  >
                    {sendForApprovalBtnLoader ? (
                      <PageLoader color="#fff" type="button" />
                    ) : (
                      'Send for Approval'
                    )}
                  </Button>
                </>
              )}
            </div>
          </div>
          {playbookServicesData?.map((customerPlaybook) => {
            return (
              <WhiteCard
                className={`mb-3 ${setDisabledClass(customerPlaybook)}`}
              >
                <div className="row">
                  <div className="col-12">
                    <CheckBox>
                      <label
                        className="check-container mb-0 "
                        htmlFor={customerPlaybook?.id}
                      >
                        <input
                          className="checkboxes"
                          type="checkbox"
                          id={customerPlaybook?.id}
                          onClick={(event) => {
                            handleChangeOfServiceCheckbox(
                              customerPlaybook,
                              event?.target?.checked,
                            );
                          }}
                          checked={selectedServicesForApproval.find(
                            (item) => item.id === customerPlaybook?.id,
                          )}
                        />
                        <span className="checkmark " />
                      </label>
                    </CheckBox>
                    <span className="ml-3 pl-3 text-bold uppercase-text">
                      {customerPlaybook?.name}
                    </span>
                  </div>
                  <div className="horizontal-line straight-line " />
                  <div className="col-6">
                    <div className="label mb-0">MONTHS</div>
                  </div>
                  <div className="col-6">
                    <BorderLessDropDown>
                      <SummaryMobileSelect
                        classNamePrefix="react-select"
                        options={playbookSummaryMonthOptions}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: Theme.lightOrange,
                            primary: Theme.orange,
                            primary50: Theme.lightOrange,
                          },
                        })}
                        placeholder="Select.."
                        value={setMonthDropdownValue(customerPlaybook)}
                        onChange={(event) =>
                          handleMonthDropdown(event, customerPlaybook)
                        }
                      />
                    </BorderLessDropDown>
                  </div>
                  <div className="horizontal-line straight-line " />
                  <div className="col-6">
                    <div className="label mb-0">TEAM</div>
                  </div>
                  <div className="col-6">{customerPlaybook?.team}</div>
                  <div className="horizontal-line straight-line " />
                  <div className="col-6">
                    <div className="label mb-0">ASSIGNEE</div>
                  </div>
                  <div className="col-6">
                    {renderAssignee(customerPlaybook)}{' '}
                  </div>
                  <div className="horizontal-line straight-line " />
                  <div className="col-6">
                    <div className="label mb-0">STATUS</div>
                  </div>
                  <div className="col-6">
                    {customerPlaybook?.status ? (
                      <TaskStatus
                        className={`${renderProgressLabelClassName(
                          customerPlaybook?.status,
                        )} `}
                      >
                        <span
                          className={renderProgressLabelClassName(
                            customerPlaybook?.status,
                          )}
                        >
                          {customerPlaybook?.status}
                        </span>
                      </TaskStatus>
                    ) : null}
                  </div>
                </div>
              </WhiteCard>
            );
          })}
        </PlaybookSummaryMobileView>
      ) : (
        <WhiteCard>
          <div className="justify-space-between ">
            <>
              {playbookCreateAccess ? (
                <>
                  <div />
                  {!selectedServicesForApproval?.every(
                    (item) => item.frequency !== '',
                  ) ? (
                    <ErrorMsg className="mt-2">
                      Please make sure you have selected months for all selected
                      services.
                    </ErrorMsg>
                  ) : null}
                  <Button
                    className="btn-primary mb-3"
                    disabled={
                      !selectedServicesForApproval?.every(
                        (item) => item.frequency !== '',
                      ) || !selectedServicesForApproval?.length
                    }
                    onClick={() =>
                      onCreatePlaybookClick({
                        customer: customerId,
                        'create-playbook': 'true',
                        services: selectedServicesForApproval,
                      })
                    }
                  >
                    {createPlaybookBtnLoader ? (
                      <PageLoader color="#fff" type="button" />
                    ) : (
                      'Create'
                    )}
                  </Button>
                </>
              ) : (
                <>
                  <div />
                  {!selectedServicesForApproval?.every(
                    (item) => item.frequency !== '',
                  ) ? (
                    <ErrorMsg className="mt-2">
                      Please make sure you have selected months for all selected
                      services.
                    </ErrorMsg>
                  ) : null}
                  <Button
                    className={`btn-primary mb-3 `}
                    disabled={
                      !selectedServicesForApproval?.every(
                        (item) => item.frequency !== '',
                      ) || !selectedServicesForApproval?.length
                    }
                    onClick={() => onClicksendForApproval()}
                  >
                    {sendForApprovalBtnLoader ? (
                      <PageLoader color="#fff" type="button" />
                    ) : (
                      'Send for Approval'
                    )}
                  </Button>
                </>
              )}
            </>
          </div>
          <DesktopTable>
            <thead>
              <tr>
                <th>
                  {' '}
                  {/* <CheckBox>
                  <label className="check-container mb-0 " htmlFor="step">
                    <input
                      className="checkboxes"
                      type="checkbox"
                      id="step"
                      onClick={(event) => {
                        handleCheckAllServices(event?.target?.checked);
                      }}
                    />
                    <span className="checkmark mt-n1" />
                  </label>
                </CheckBox>{' '} */}
                </th>
                <th width="30%" className="pl-1">
                  <span className="">SERVICES </span>
                </th>
                <th width="20%">MONTHS</th>
                <th width="20%">TEAM</th>
                <th width="15%">ASSIGNEE</th>
                <th width="15%">STATUS</th>
              </tr>
            </thead>
            <tbody>
              {playbookServicesData?.map((customerPlaybook) => {
                return renderPlaybookServiceCollapse(customerPlaybook);
              })}
            </tbody>
          </DesktopTable>
        </WhiteCard>
      )}
    </div>
  ) : (
    <WhiteCard className="pt-2">
      <p className="black-heading-title text-regular mt-2 mb-0 text-center">
        No Services Found
      </p>
    </WhiteCard>
  );
}

export default PlaybookServicesTab;

PlaybookServicesTab.defaultProps = {
  customerId: '',
  playbookTab: '',
  selectedMarketplaces: [],
  selectedTeamMembers: [],
  customStartDate: '',
  accountType: {},
  onCreatePlaybookClick: () => {},
  createPlaybookBtnLoader: false,
  singleSelectedMarketplaces: {},
};

PlaybookServicesTab.propTypes = {
  customerId: string,
  playbookTab: string,
  selectedMarketplaces: arrayOf(shape({})),
  selectedTeamMembers: arrayOf(shape({})),
  customStartDate: string,
  accountType: shape({}),
  onCreatePlaybookClick: func,
  createPlaybookBtnLoader: bool,
  singleSelectedMarketplaces: shape({}),
};
