import React, { useState } from 'react';

import NumberFormat from 'react-number-format';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Select, { components } from 'react-select';
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, instanceOf, shape, string } from 'prop-types';

import RenderSellerFields from './RenderSellerFields';
import Theme from '../../../../theme/Theme';
import RenderVendorFields from './RenderVendorFields';
import { CreateReportSidePanel } from './CreateReportStyle';
import { SidePanel } from '../../../../theme/AgreementStyle';

import {
  CloseIcon,
  MinusIcon,
  PlusIcon,
  ServiceAgreementIcon,
} from '../../../../theme/images';
import {
  commanFieldConstant,
  dateOptionsWithYear,
  customStyles,
  saveReportOptions,
  sponoredDspKeys,
  inventoryKeys,
  selleCollapseIds,
  vendorCollapseIds,
} from '../../../../constants';
import {
  FormField,
  InputFormField,
  Button,
  DropdownIndicator,
  ModalBox,
  CustomDateModal,
  ErrorMsg,
  ModalRadioCheck,
  PageLoader,
  CustomDropDown,
  InfoMsg,
} from '../../../../common';

const _ = require('lodash');

const ReportRightSidePanel = ({
  graphDetails,
  setGraphDetails,
  commanFieldsDetails,
  reportStatus,
  reportSummary,
  setReportSummary,
  selectedState,
  dateToShow,
  setSelectedState,
  currentDate,
  isTimeFrameDisabled,
  showMetricsTooltip,
  setIsTimeFrameDisabled,
  setShowReportPDFTemplate,
  templateTimeFrame,
  templateReportTitle,
  templateReportDescrition,
}) => {
  const { Option, SingleValue } = components;
  const [showCustomDateModal, setShowCustomDateModal] = useState(false);

  const [indexToDeleteGraph, setIndexToDeleteGraph] = useState(null);
  const [editReportOptions, setEditReportOptions] = useState('keepExisitng');
  const [showSaveModalErrorMessage, setShowSaveModalErrorMessage] =
    useState(false);
  const [
    showDeleteGraphConfirmationModal,
    setShowDeleteGraphConfirmationModal,
  ] = useState(false);

  const handleGraphDetailsOnChange = (
    indexNumber,
    fieldName,
    keyName,
    value,
    isMetrics,
  ) => {
    // copy existing graph deatial and set value - start
    const newGraph = JSON.parse(JSON.stringify(graphDetails));
    newGraph[indexNumber][fieldName][keyName] = value;
    // copy existing graph deatial and set value- end

    // if showDataTable is false then clear the data table view
    if (keyName === 'showDataTable') {
      if (value) {
        newGraph[indexNumber][fieldName].graphDataTableView = 'detailedView';
      } else {
        newGraph[indexNumber][fieldName].graphDataTableView = null;
      }
    }
    // if buy box % option is deslected then buy box graph and buy box table should be deselected.
    if (keyName === 'isBuyBoxGraph' && !value) {
      newGraph[indexNumber][fieldName].buyBoxGraph = false;
      newGraph[indexNumber][fieldName].showBuyBoxGraphTable = false;
    }

    // if all child asin options deslected then  'show child product name' should be deselected.
    if (
      keyName === 'isChildBottomAsins' ||
      keyName === 'isChildTopAsins' ||
      (keyName === 'isChildAllAsins' && !value)
    ) {
      if (
        !newGraph[indexNumber][fieldName].isChildBottomAsins &&
        !newGraph[indexNumber][fieldName].isChildTopAsins &&
        !newGraph[indexNumber][fieldName].isChildAllAsins
      )
        newGraph[indexNumber][fieldName].showChildProductName = false;
    }

    // if all invemtory management options deslected then  'show inventory product name' should be deselected.
    if (
      keyName === 'strandedInventory' ||
      keyName === 'overstockInventory' ||
      (keyName === 'understockInventory' && !value)
    ) {
      if (
        !newGraph[indexNumber][fieldName].strandedInventory &&
        !newGraph[indexNumber][fieldName].overstockInventory &&
        !newGraph[indexNumber][fieldName].understockInventory
      )
        newGraph[indexNumber][fieldName].showInventoryProductName = false;
    }

    // if showBuyBoxGraphTable is false then clear the buybox percent table view
    if (keyName === 'showBuyBoxGraphTable') {
      if (value) {
        newGraph[indexNumber][fieldName].bbpTableView = 'detailedView';
      } else {
        newGraph[indexNumber][fieldName].bbpTableView = null;
      }
    }

    // auto scroll page when select field - start
    if (fieldName === 'sellerDetails' && value === true) {
      if (
        ['inventoryScore', 'positiveFeedback', 'orderIssue'].includes(
          keyName,
        ) &&
        [
          newGraph[indexNumber]?.sellerDetails?.inventoryScore,
          newGraph[indexNumber]?.sellerDetails?.positiveFeedback,
          newGraph[indexNumber]?.sellerDetails?.orderIssue,
        ].filter(Boolean).length === 1
      ) {
        commanFieldsDetails.setAutoScrollId(
          `collapseSellerBBEPgraph${indexNumber}`,
        );
      } else {
        commanFieldsDetails.setAutoScrollId(
          `${selleCollapseIds[keyName]}${indexNumber}`,
        );
      }
    }

    if (fieldName === 'vendorDetails' && value === true) {
      commanFieldsDetails.setAutoScrollId(
        `${vendorCollapseIds[keyName]}${indexNumber}`,
      );
    }
    // auto scroll page when select field- end

    // add and remove selected metrics
    if (isMetrics) {
      let localSelectedMetrics =
        newGraph[indexNumber][fieldName].selectedMetrics;

      // max 4 metrics selection logic
      if (Object.prototype.hasOwnProperty.call(localSelectedMetrics, keyName)) {
        localSelectedMetrics = _.omit(localSelectedMetrics, [keyName]);
      } else if (_.size(localSelectedMetrics) < 4) {
        localSelectedMetrics = _.omit(
          _.assign(localSelectedMetrics, { [keyName]: true }),
        );
      }
      newGraph[indexNumber][fieldName].selectedMetrics = localSelectedMetrics;
    }

    // if user unselect the asin field, then number_of_asin set to 0 for that field
    // and remove the data of that asin also from data object
    const localAsinObject = {
      isParentTopAsins: 'parentTopAsins',
      isParentBottomAsins: 'parentBottomAsins',
      isChildTopAsins: 'childTopAsins',
      isChildBottomAsins: 'childBottomAsins',
    };
    if (_.keys(localAsinObject).includes(keyName) && !value) {
      newGraph[indexNumber][fieldName][localAsinObject[keyName]] = 0;
      const graphApiResponce = JSON.parse(
        JSON.stringify(commanFieldsDetails.graphApiResponseData),
      );
      if (keyName === 'isParentTopAsins') {
        graphApiResponce[indexNumber].sellerDetails.topParentAsins = [];
      }
      if (keyName === 'isParentBottomAsins') {
        graphApiResponce[indexNumber].sellerDetails.bottomParentAsins = [];
      }
      if (keyName === 'isChildTopAsins') {
        graphApiResponce[indexNumber].sellerDetails.topChildAsins = [];
      }
      if (keyName === 'isChildBottomAsins') {
        graphApiResponce[indexNumber].sellerDetails.bottomChildAsins = [];
      }
      commanFieldsDetails.setGraphApiResponseData(graphApiResponce);
    }

    // call ASIN api when user select asin
    if (_.keys(localAsinObject).includes(keyName) && value) {
      newGraph[indexNumber][fieldName][localAsinObject[keyName]] = 1;
      commanFieldsDetails.getSalesAsinsReportData(
        indexNumber,
        localAsinObject[keyName],
        1,
        commanFieldsDetails?.selectedTimeFrame?.value,
      );
    }

    // call ASIN api when user set/change the asin count
    if (
      [
        'parentTopAsins',
        'parentBottomAsins',
        'isParentAllAsins',
        'childBottomAsins',
        'childTopAsins',
        'isChildAllAsins',
      ].includes(keyName)
    ) {
      commanFieldsDetails.getSalesAsinsReportData(
        indexNumber,
        keyName,
        value,
        commanFieldsDetails?.selectedTimeFrame?.value,
      );
    }
    // if (!isTimeFrameDisabled)
    //   // once user start filling options, then disabled the timeframe option
    //   setIsTimeFrameDisabled(true);

    setGraphDetails(newGraph);

    // if we chnaged the graph frequency, then call the metrics data API to get data
    if (keyName === 'graphFrequency') {
      commanFieldsDetails.getMetricsReportData(
        indexNumber,
        value, // graph frequency
        // graphDetails[indexNumber][fieldName]?.sponsoredType?.value,
        commanFieldsDetails?.selectedTimeFrame?.value,
        fieldName,
      );
    }
  };

  // if sponsored, dsp and inventory field selected before selecting timeframe.
  // And user select year to date option then unselect that selected options
  // because for year to date time frame option, sponsored, dsp and inventory field is disabled.
  const unselectSelctedFields = (value, graphData) => {
    if (value === 'year') {
      const newGraph = JSON.parse(JSON.stringify(graphData));
      const sponoredDspInventoryKeys = { ...sponoredDspKeys, ...inventoryKeys };
      graphData.forEach((element, indexNumber) => {
        _.keys(sponoredDspInventoryKeys).map((option) => {
          if (
            (commanFieldsDetails.accountType.isSeller ||
              commanFieldsDetails.accountType.isHybrid) &&
            element.sellerDetails[option]
          ) {
            // set field as false- seller
            newGraph[indexNumber].sellerDetails[option] = false;

            // remove from selected metrics object - seller
            let localSelectedMetrics =
              newGraph[indexNumber]?.sellerDetails?.selectedMetrics;
            if (
              Object.prototype.hasOwnProperty.call(localSelectedMetrics, option)
            ) {
              localSelectedMetrics = _.omit(localSelectedMetrics, [option]);
            }
            newGraph[indexNumber].sellerDetails.selectedMetrics =
              localSelectedMetrics;
          }
          if (
            (commanFieldsDetails.accountType.isVendor ||
              commanFieldsDetails.accountType.isHybrid) &&
            element.vendorDetails[option]
          ) {
            // set field as false-vendor
            newGraph[indexNumber].vendorDetails[option] = false;

            // remove from selected metrics object- vendor
            let localSelectedMetrics =
              newGraph[indexNumber]?.vendorDetails?.selectedMetrics;
            if (
              Object.prototype.hasOwnProperty.call(localSelectedMetrics, option)
            ) {
              localSelectedMetrics = _.omit(localSelectedMetrics, [option]);
            }
            newGraph[indexNumber].vendorDetails.selectedMetrics =
              localSelectedMetrics;
          }
          return null;
        });
      });
      return newGraph;
    }

    if (value === 'month') {
      const newGraph = JSON.parse(JSON.stringify(graphData));
      graphData.forEach((element, indexNumber) => {
        _.keys(inventoryKeys).map((option) => {
          if (
            (commanFieldsDetails.accountType.isSeller ||
              commanFieldsDetails.accountType.isHybrid) &&
            element.sellerDetails[option]
          ) {
            newGraph[indexNumber].sellerDetails[option] = false;
          }
          if (
            commanFieldsDetails.accountType.isVendor &&
            element.vendorDetails[option]
          ) {
            newGraph[indexNumber].vendorDetails[option] = false;
          }
          return null;
        });
      });
      return newGraph;
    }

    if (value === 'custom') {
      const newGraph = JSON.parse(JSON.stringify(graphData));
      graphData.forEach((element, indexNumber) => {
        if (
          commanFieldsDetails.accountType.isSeller ||
          commanFieldsDetails.accountType.isHybrid
        ) {
          newGraph[indexNumber].sellerDetails.showPreviousDataPoint = false;
        }
        if (
          commanFieldsDetails.accountType.isVendor ||
          commanFieldsDetails.accountType.isHybrid
        ) {
          newGraph[indexNumber].vendorDetails.showPreviousDataPoint = false;
        }
      });
      return newGraph;
    }
    return graphData;
  };

  const handleTimeFramefilter = (event) => {
    const { value } = event;

    if (value !== 'custom') {
      const groupByOptions =
        commanFieldsDetails.checkIsGroupFrequencyDisabled(value);
      commanFieldsDetails.setEnabledGroupFrequencyList(
        groupByOptions.enabledList,
      );
      setGraphDetails(unselectSelctedFields(value, groupByOptions.newGraph));

      commanFieldsDetails.setSelectedTimeFrame(event);

      commanFieldsDetails.bindMultipleApiRequest(
        groupByOptions.newGraph,
        value,
        null,
      );
      setSelectedState([
        {
          startDate: currentDate,
          endDate: currentDate,
          key: 'reportSelection',
        },
      ]);

      // if user used template and changed the time_frame_filter
      // 1. then we need to reset the report_title and report_description.
      // 2. if user again set the same tiem_frame (which we got from template)
      // then set the predefine report_title and report_description.
      // (this logic is not applicable to custom_time_frame).

      if (
        reportStatus === 'new' &&
        templateReportTitle !== null &&
        templateTimeFrame.value !== value
      ) {
        commanFieldsDetails.setReportTitle('');
        commanFieldsDetails.setReportDescription('');
      } else if (
        reportStatus === 'new' &&
        templateReportTitle !== null &&
        templateTimeFrame.value === value
      ) {
        commanFieldsDetails.setReportTitle(templateReportTitle);
        commanFieldsDetails.setReportDescription(templateReportDescrition);
      }
    } else {
      setShowCustomDateModal(true);
    }
  };

  const handleOnProceedClick = () => {
    commanFieldsDetails.setShowSaveReportModal(true);
    const newGraph = JSON.parse(JSON.stringify(graphDetails));
    for (
      let index = 0;
      index < commanFieldsDetails.editorDetails.length;
      index += 1
    ) {
      if (
        commanFieldsDetails.accountType.isSeller ||
        commanFieldsDetails.accountType.isHybrid
      ) {
        newGraph[index].sellerDetails.graphTitle =
          commanFieldsDetails.editorDetails[index].sellerDetails.graphTitle;
        newGraph[index].sellerDetails.metricMessage =
          commanFieldsDetails.editorDetails[index].sellerDetails.metricMessage;
        newGraph[index].sellerDetails.parentAsinMessage =
          commanFieldsDetails.editorDetails[
            index
          ].sellerDetails.parentAsinMessage;
        newGraph[index].sellerDetails.childAsinMessage =
          commanFieldsDetails.editorDetails[
            index
          ].sellerDetails.childAsinMessage;
        newGraph[index].sellerDetails.sellerHeathMessge =
          commanFieldsDetails.editorDetails[
            index
          ].sellerDetails.sellerHeathMessge;
        newGraph[index].sellerDetails.inventoryManagementMessage =
          commanFieldsDetails.editorDetails[
            index
          ].sellerDetails.inventoryManagementMessage;
      }
      if (
        commanFieldsDetails.accountType.isVendor ||
        commanFieldsDetails.accountType.isHybrid
      ) {
        newGraph[index].vendorDetails.graphTitle =
          commanFieldsDetails.editorDetails[index].vendorDetails.graphTitle;
        newGraph[index].vendorDetails.metricMessage =
          commanFieldsDetails.editorDetails[index].vendorDetails.metricMessage;
        newGraph[index].vendorDetails.inventoryManagementMessage =
          commanFieldsDetails.editorDetails[
            index
          ].vendorDetails.inventoryManagementMessage;
      }
    }
    setGraphDetails(newGraph, commanFieldsDetails.editorDetails);
  };

  const applyCustomDate = () => {
    if (
      commanFieldsDetails?.selectedTimeFrame === undefined ||
      commanFieldsDetails?.selectedTimeFrame.value !== 'custom'
    )
      commanFieldsDetails.setSelectedTimeFrame({
        value: 'custom',
        label: 'Custom Range',
        sub: 'Select start & end date',
      });

    const groupByOptions =
      commanFieldsDetails.checkIsGroupFrequencyDisabled('custom');
    commanFieldsDetails.setEnabledGroupFrequencyList(
      groupByOptions.enabledList,
    );

    setGraphDetails(unselectSelctedFields('custom', groupByOptions.newGraph));

    commanFieldsDetails.bindMultipleApiRequest(
      groupByOptions.newGraph,
      'custom',
      null,
    );

    setShowCustomDateModal(false);
  };

  // check the key is slected or not
  const checkSelectedKeys = (objectName, arrayName) => {
    const availebleKeys = Object.keys(objectName).filter((key) => {
      return objectName[key] && arrayName.includes(key);
    });
    return availebleKeys;
  };

  const renderCustomDateSubLabel = (props) => {
    if (commanFieldsDetails?.selectedTimeFrame === undefined) {
      return null;
    }
    if (
      commanFieldsDetails?.selectedTimeFrame !== undefined &&
      commanFieldsDetails?.selectedTimeFrame.value === 'custom'
    ) {
      return `From - ${dayjs(selectedState[0].startDate).format(
        'MMM D, YYYY',
      )}  To - ${dayjs(selectedState[0].endDate).format('MMM D, YYYY')}`;
    }
    return props.data.sub;
  };

  const filterOption = (props) => (
    <Option {...props}>
      <div>
        <span style={{ fontSize: '14px' }}>{props.data.label}</span>

        <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
      </div>
    </Option>
  );

  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>
        {commanFieldsDetails?.selectedTimeFrame !== undefined
          ? props.data.label
          : 'Select Date'}
      </span>

      <div style={{ fontSize: '10px' }}>{renderCustomDateSubLabel(props)}</div>
    </SingleValue>
  );

  const adTypesSingleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props.data.label}</span>

      <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
    </SingleValue>
  );

  const getAdTypesSelectComponents = () => {
    return {
      Option: filterOption,
      SingleValue: adTypesSingleFilterOption,
      DropdownIndicator,
    };
  };

  const CalenderIndicator = ({ selectProps, ...props }) => (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon
        icon="fa-regular fa-calendar"
        style={{
          fontSize: selectProps?.fontSize ? selectProps?.fontSize : '16px',
          color: '#000',
        }}
      />
    </components.DropdownIndicator>
  );

  const getSelectComponents = () => {
    return {
      Option: filterOption,
      SingleValue: singleFilterOption,
      DropdownIndicator: CalenderIndicator,
    };
  };

  const renderTimeFilterDropDown = () => {
    return (
      <CustomDropDown className="w-100 un-searchable">
        <Select
          classNamePrefix="react-select "
          options={dateOptionsWithYear}
          placeholder="Select Date"
          components={getSelectComponents()}
          onChange={(event) => {
            handleTimeFramefilter(event);
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: Theme.white,
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
          value={commanFieldsDetails?.selectedTimeFrame}
          styles={{
            placeholder: (defaultStyles) => {
              return {
                ...defaultStyles,
                color: '#2E384D',
              };
            },
          }}
        />
        <>
          {commanFieldsDetails?.selectedTimeFrame === undefined &&
          reportStatus !== 'readyToEdit' ? (
            <ErrorMsg>This is a required field.</ErrorMsg>
          ) : null}
          {reportStatus === 'readyToEdit'
            ? `From - ${dayjs(dateToShow.startDate).format(
                'MMM D, YYYY',
              )} To - ${dayjs(dateToShow.endDate).format('MMM D, YYYY')}`
            : null}
        </>
      </CustomDropDown>
    );
  };

  const renderCheckBox = (
    graphIndex,
    graphData,
    idName,
    labelName,
    fieldName,
    keyName,
    className,
    spanClass,
    isMetrics,
    isSubType = false,
  ) => {
    let isDisabled = false;

    // you can select upto 4 metrics at a time, other will disabled
    if (isMetrics) {
      isDisabled =
        _.size(graphData?.selectedMetrics) === 4 &&
        !Object.prototype.hasOwnProperty.call(
          graphData?.selectedMetrics,
          keyName,
        );
    }

    // disabled the metric if data is not avalible for that metric
    if (
      isMetrics &&
      !isDisabled &&
      commanFieldsDetails?.metricsDataAvailabilityFlag.length
    ) {
      isDisabled =
        !commanFieldsDetails?.metricsDataAvailabilityFlag[graphIndex]?.[
          fieldName
        ]?.[keyName];

      if (isDisabled && graphData[keyName]) {
        handleGraphDetailsOnChange(
          graphIndex,
          fieldName,
          keyName,
          false,
          isMetrics,
        );
      }
    }

    if (commanFieldsDetails.metricDataLoader) {
      isDisabled = true;
    }

    if (keyName === 'showPreviousDataPoint') {
      isDisabled =
        _.size(graphData?.selectedMetrics) > 2 ||
        commanFieldsDetails?.selectedTimeFrame?.value === 'custom';
      if (isDisabled && graphData?.showPreviousDataPoint) {
        handleGraphDetailsOnChange(
          graphIndex,
          fieldName,
          keyName,
          false,
          isMetrics,
        );
      }
    }

    // if isBuyBoxGraph is not selected then, diabled the show graph and show table option  of BB%
    if (
      (keyName === 'showBuyBoxGraphTable' || keyName === 'buyBoxGraph') &&
      !graphDetails[graphIndex][fieldName]?.isBuyBoxGraph
    ) {
      isDisabled = true;
    }

    // Work for Existing report- If Buy Box Graph Table OR buy Box Graph selected then set the isBuyBoxGraph as TRUE
    if (
      keyName === 'isBuyBoxGraph' &&
      !graphDetails[graphIndex][fieldName]?.isBuyBoxGraph &&
      (graphDetails[graphIndex][fieldName]?.showBuyBoxGraphTable ||
        graphDetails[graphIndex][fieldName]?.buyBoxGraph)
    ) {
      handleGraphDetailsOnChange(
        graphIndex,
        fieldName,
        keyName,
        true,
        isMetrics,
      );
    }

    if (
      reportStatus === 'readyToEdit' &&
      !graphDetails[graphIndex][fieldName][keyName]
    ) {
      return null;
    }

    return (
      <div style={{ position: 'relative' }}>
        <label
          htmlFor={`${idName}${graphIndex}`}
          className={`${className} ${isDisabled ? 'disabled' : ''} ${
            isSubType ? 'ml-4' : ''
          }`}
          key={`${idName}${graphIndex}`}
        >
          {labelName}
          <input
            type="checkbox"
            id={`${idName}${graphIndex}`}
            onClick={(event) => {
              if (reportStatus !== 'readyToEdit') {
                handleGraphDetailsOnChange(
                  graphIndex,
                  fieldName,
                  keyName,
                  event.target.checked,
                  isMetrics,
                );
              }
            }}
            onChange={() => {}}
            checked={graphData[keyName]}
          />
          <span className={spanClass} />
        </label>
      </div>
    );
  };

  const renderRadioButtons = (
    id,
    name,
    value,
    isChecked,
    labelName,
    labelClass,
  ) => {
    return (
      <>
        <input
          className="d-none "
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={isChecked}
          onChange={() => {}}
        />
        <label htmlFor={id} className={labelClass}>
          {labelName}
        </label>
      </>
    );
  };

  // render rectangular shaped toggle button html
  const renderDataTableViewButton = (
    graphData,
    graphIndex,
    fieldName,
    keyName,
    tableName,
  ) => {
    const value = graphData?.[keyName];

    return (
      <div className="thershold mb-2">
        <ul className="days-tab">
          <li
            role="presentation"
            style={{ width: '50%' }}
            className={`p-0 ${
              value === 'detailedView' ? 'thresholdChecked' : ''
            }`}
            onClick={() => {
              if (reportStatus === 'edit' || reportStatus === 'new') {
                handleGraphDetailsOnChange(
                  graphIndex,
                  fieldName,
                  keyName,
                  'detailedView',
                  false, // isMetrics
                );
              }
            }}
          >
            {renderRadioButtons(
              `${tableName}DetailedView${graphIndex}`,
              `${tableName}-detailed-view-${graphIndex}`,
              value,
              value === 'detailedView',
              'Detailed View',
              'radio-container customer-list',
            )}
          </li>
          <li
            role="presentation"
            style={{ width: '50%' }}
            className={`p-0 ${
              value === 'summaryView' ? 'thresholdChecked' : ''
            }`}
            onClick={() => {
              if (reportStatus === 'edit' || reportStatus === 'new') {
                handleGraphDetailsOnChange(
                  graphIndex,
                  fieldName,
                  keyName,
                  'summaryView',
                  false, // isMetrics
                );
              }
            }}
          >
            {renderRadioButtons(
              `${tableName}SummaryView${graphIndex}`,
              `${tableName}-summary-view-${graphIndex}`,
              value,
              value === 'summaryView',
              'Summary View',
              'radio-container customer-list',
            )}
          </li>
        </ul>
      </div>
    );
  };

  const renderNumberOfAsins = (index, data, fieldName, keyName) => {
    if (
      reportStatus === 'readyToEdit' &&
      !graphDetails[index][fieldName][keyName]
    ) {
      return null;
    }

    let numberLimit = 1;
    if (keyName === 'parentTopAsins' || keyName === 'parentBottomAsins') {
      numberLimit = commanFieldsDetails?.asinDataCount?.parent_asin || 1;
    } else if (keyName === 'childTopAsins' || keyName === 'childBottomAsins') {
      numberLimit = commanFieldsDetails?.asinDataCount?.child_asin || 1;
    }

    return (
      <>
        <button
          type="button"
          className="decrement"
          onClick={() => {
            if (reportStatus !== 'readyToEdit')
              handleGraphDetailsOnChange(
                index,
                fieldName,
                keyName,
                parseInt(data[keyName], 10) - 1
                  ? parseInt(data[keyName], 10) - 1
                  : 0,
                false, // isMetrics
              );
          }}
          disabled={
            parseInt(data[keyName], 10) <= 1 ||
            commanFieldsDetails.metricDataLoader
          }
        >
          <img className="minus-icon" src={MinusIcon} alt="" />
        </button>

        <NumberFormat
          name="topParent"
          className={`form-control max-min-number ${
            reportStatus === 'readyToEdit' ? 'disabled' : ''
          }`}
          value={data[keyName] || 0}
          onChange={(event) => {
            handleGraphDetailsOnChange(
              index,
              fieldName,
              keyName,
              parseInt(event.target.value, 10),
              false, // isMetrics
            );
          }}
          isAllowed={(values) => {
            const { floatValue } = values;
            return floatValue >= 1 && floatValue <= numberLimit;
          }}
          allowNegative={false}
        />

        <button
          type="button"
          className="increment"
          onClick={() => {
            if (reportStatus !== 'readyToEdit')
              handleGraphDetailsOnChange(
                index,
                fieldName,
                keyName,
                parseInt(data[keyName], 10) + 1,
                false, // isMetrics
              );
          }}
          disabled={
            parseInt(data[keyName], 10) >= numberLimit ||
            commanFieldsDetails.metricDataLoader
          }
        >
          <img className="plus-icon" src={PlusIcon} alt="" />
        </button>
      </>
    );
  };

  const renderReportHeader = () => {
    return (
      <div className="collapse-btn">
        <img className="service-agre" src={ServiceAgreementIcon} alt="pdf" />
        <h4 className="sendar-details ">Report 1 </h4>
        {reportStatus === 'readyToEdit' || reportStatus === 'view' ? (
          <FontAwesomeIcon
            className="float-right"
            icon="fa-regular fa-check"
            color="#008762"
            fontSize="12px"
          />
        ) : (
          <FontAwesomeIcon
            className=" cursor red-cross-icon"
            icon="fa-light fa-times"
            style={{ color: '#d60000', fontSize: '16px' }}
          />
        )}
        <div className=" clear-fix" />
      </div>
    );
  };

  const renderReportCommanFieldsDetails = () => {
    return (
      <>
        {reportStatus !== 'view' ? (
          <ul className="collapse-inner">
            <li
              className={
                reportStatus === 'readyToEdit' ||
                (commanFieldsDetails?.selectedTimeFrame !== undefined &&
                  isTimeFrameDisabled) ||
                commanFieldsDetails.metricDataLoader
                  ? 'disabled'
                  : ''
              }
            >
              {renderTimeFilterDropDown()}
            </li>
            {commanFieldConstant.map((field) => {
              return (
                <li key={field.id}>
                  <InputFormField>
                    <label htmlFor={field.id}>{field.label}</label>
                    <input
                      id={field.id}
                      className="form-control disabled"
                      type="text"
                      placeholder={field.placeholder}
                      value={commanFieldsDetails[field.value]}
                      onChange={() => {}}
                    />
                  </InputFormField>
                </li>
              );
            })}
            <li>
              <FormField
                className={`mt-2 ${
                  commanFieldsDetails.metricDataLoader ||
                  reportStatus === 'readyToEdit'
                    ? 'disabled'
                    : ''
                }`}
              >
                <label className="label-textarea" htmlFor="#">
                  Report Summary
                </label>
                <textarea
                  className=" text-area-box mt-2"
                  rows="4"
                  placeholder="Text Comes Here"
                  value={reportSummary}
                  onChange={(event) => {
                    setReportSummary(event.target.value);
                  }}
                />
              </FormField>
              {reportSummary === '' && reportStatus !== 'readyToEdit' ? (
                <ErrorMsg>This is a required field.</ErrorMsg>
              ) : null}
              <div className="horizontal-line straight-line mt-3" />
            </li>
          </ul>
        ) : null}
      </>
    );
  };

  const renderReportFooter = () => {
    return (
      <div style={{ padding: '0 20px' }}>
        <div className="horizontal-line straight-line mt-3" />
        {reportStatus === 'readyToEdit' ? (
          <Button
            className=" btn-transparent sidepanel mt-3 mb-3 w-100"
            onClick={() => {
              commanFieldsDetails.checkReportStatus();
            }}
          >
            <FontAwesomeIcon
              icon="fa-regular fa-pen-to-square"
              className="mr-2"
            />
            Edit Graph
          </Button>
        ) : (
          <>
            <Button
              className={` btn-add-items text-center w-100 mt-3 ${
                commanFieldsDetails?.selectedTimeFrame === undefined ||
                reportSummary === '' ||
                commanFieldsDetails?.metricDataLoader
                  ? 'disabled'
                  : ''
              }`}
              onClick={() => {
                commanFieldsDetails.handleAddAdditonalGraph();
                // disabled the timeframe option
                setIsTimeFrameDisabled(true);
              }}
            >
              <FontAwesomeIcon
                color="#FF5933"
                fontSize="12px"
                className="mr-2"
                icon="fa-regular fa-plus"
              />{' '}
              Add Additional Graph
            </Button>
            <Button
              className={`btn-primary  mt-3 mb-3 w-100 ${
                commanFieldsDetails?.selectedTimeFrame === undefined ||
                reportSummary === '' ||
                commanFieldsDetails?.metricDataLoader
                  ? 'disabled'
                  : ''
              }`}
              onClick={() => {
                handleOnProceedClick();
              }}
            >
              Proceed
            </Button>
          </>
        )}
      </div>
    );
  };

  const renderErrorMessage = (flag, selectedGraph, index, fieldName) => {
    if (
      reportStatus !== 'readyToEdit' &&
      !commanFieldsDetails.metricDataLoader
    ) {
      if (flag === 'adPeformance') {
        if (selectedGraph?.adPerformance === 'sponsored') {
          if (
            [
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.adAcos,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.adSpend,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.adConversionRate,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.adImpressions,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.adAcos,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.adRoas,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.adCostPerClick,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.adClicks,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.adClickThroughRate,
            ].filter(Boolean).length === 0
          ) {
            return (
              <InfoMsg>
                No sponsored advertising data available for this BP.
              </InfoMsg>
            );
          }
        }

        if (selectedGraph?.adPerformance === 'dsp') {
          if (
            [
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.dspImpressions,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.dspSpend,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.dspTotalProductSales,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.dspTotalRoas,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.dspTotalDpvr,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.dspTotalNewBrandPurchases,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.dspProductSales,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]?.[
                fieldName
              ]?.dspRoas,
            ].filter(Boolean).length === 0
          ) {
            return (
              <InfoMsg className="pb-3">
                No DSP advertising data available for this BP.
              </InfoMsg>
            );
          }
        }
      }
      if (flag === 'salesPeformance') {
        if (
          [
            commanFieldsDetails?.metricsDataAvailabilityFlag[index]
              ?.sellerDetails?.salesRevenueTotal,
            commanFieldsDetails?.metricsDataAvailabilityFlag[index]
              ?.sellerDetails?.salesRevenuePpc,
            commanFieldsDetails?.metricsDataAvailabilityFlag[index]
              ?.sellerDetails?.salesRevenueDsp,
            commanFieldsDetails?.metricsDataAvailabilityFlag[index]
              ?.sellerDetails?.salesUnitSold,
            commanFieldsDetails?.metricsDataAvailabilityFlag[index]
              ?.sellerDetails?.salesTraffic,
            commanFieldsDetails?.metricsDataAvailabilityFlag[index]
              ?.sellerDetails?.salesConversion,
          ].filter(Boolean).length === 0
        ) {
          return (
            <InfoMsg className="pb-3">
              No sales performance data available for this BP.
            </InfoMsg>
          );
        }
      }

      if (flag === 'vendorSalesPeformance') {
        if (selectedGraph?.saleType === 'shippedCogs') {
          if (
            [
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]
                ?.vendorDetails?.salesShippedCogs,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]
                ?.vendorDetails?.salesShippedGlanceViews,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]
                ?.vendorDetails?.salesShippedConversionRate,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]
                ?.vendorDetails?.salesShippedUnits,
            ].filter(Boolean).length === 0
          ) {
            return (
              <InfoMsg className="pb-3">
                No shipped cogs data available for this BP.
              </InfoMsg>
            );
          }
        } else if (selectedGraph?.saleType === 'orderedRevenue') {
          if (
            [
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]
                ?.vendorDetails?.salesOrderedRevenue,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]
                ?.vendorDetails?.salesOrderedGlanceViews,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]
                ?.vendorDetails?.salesOrderedConversionRate,
              commanFieldsDetails?.metricsDataAvailabilityFlag[index]
                ?.vendorDetails?.salesOrderedUnits,
            ].filter(Boolean).length === 0
          ) {
            return (
              <InfoMsg className="pb-3">
                No ordered revenue data available for this BP.
              </InfoMsg>
            );
          }
        }
      }
    }
    return null;
  };

  const handledSaveModal = () => {
    if (
      commanFieldsDetails?.reportTitle === '' ||
      commanFieldsDetails?.reportDescription === ''
    ) {
      setShowSaveModalErrorMessage(true);
      return null;
    }
    commanFieldsDetails.saveReportDetails(editReportOptions);
    return null;
  };

  const renderGraphFields = (value, graphIndex) => {
    let graphName = '';
    if (commanFieldsDetails.accountType.isSeller) {
      graphName = value?.sellerDetails?.graphTitle.replace(/(<([^>]+)>)/gi, '');
    } else if (commanFieldsDetails.accountType.isVendor) {
      graphName = value?.vendorDetails?.graphTitle.replace(/(<([^>]+)>)/gi, '');
    } else {
      // for hybrid account type
      graphName = value?.sellerDetails?.graphTitle.replace(/(<([^>]+)>)/gi, '');
      // if seller title not set then get the vendor title.
      if (graphName?.length <= 1) {
        graphName = value?.vendorDetails?.graphTitle.replace(
          /(<([^>]+)>)/gi,
          '',
        );
      }
    }

    return (
      <div
        key={graphIndex}
        className={
          commanFieldsDetails?.metricDataLoader ||
          reportSummary === '' ||
          commanFieldsDetails.selectedTimeFrame === undefined
            ? 'disabled'
            : null
        }
      >
        <div className="collapse-btn">
          <h4
            className="graph-title textLimit11 text-bold"
            data-tip={
              graphName?.length > 1 ? graphName : `GRAPH ${graphIndex + 1}`
            }
            data-for="graphTitleTooltip"
          >
            {graphName?.length > 1 ? graphName : `GRAPH ${graphIndex + 1}`}{' '}
          </h4>
          {graphDetails.length !== 1 ? (
            <FontAwesomeIcon
              icon="fa-regular fa-trash"
              color="#FF5933"
              fontSize="16px"
              className="mr-2 delete-graph"
              onClick={() => {
                if (reportStatus !== 'readyToEdit') {
                  setIndexToDeleteGraph(graphIndex);
                  setShowDeleteGraphConfirmationModal(true);
                }
              }}
            />
          ) : null}
          {value.isOpen ? (
            <FontAwesomeIcon
              className="icon-collapse"
              icon="fa-regular fa-minus"
              onClick={() => {
                setGraphDetails(
                  commanFieldsDetails.handleGraphCollapse(graphIndex, false),
                );
              }}
            />
          ) : (
            <FontAwesomeIcon
              className="icon-collapse"
              icon="fa-regular fa-plus"
              onClick={() => {
                setGraphDetails(
                  commanFieldsDetails.handleGraphCollapse(graphIndex, true),
                );
              }}
            />
          )}
          <div className=" clear-fix" />
          <div className="horizontal-line straight-line mt-3" />
        </div>
        <Collapse isOpened={value.isOpen}>
          {commanFieldsDetails.accountType.isSeller ||
          commanFieldsDetails.accountType.isHybrid ? (
            <RenderSellerFields
              graphData={value?.sellerDetails}
              graphIndex={graphIndex}
              accountType={commanFieldsDetails.accountType}
              handleGraphDetailsOnChange={handleGraphDetailsOnChange}
              renderCheckBox={renderCheckBox}
              getAdTypesSelectComponents={getAdTypesSelectComponents}
              renderRadioButtons={renderRadioButtons}
              renderNumberOfAsins={renderNumberOfAsins}
              selectedTimeFrame={commanFieldsDetails?.selectedTimeFrame}
              metricsDataAvailabilityFlag={
                commanFieldsDetails?.metricsDataAvailabilityFlag
              }
              enabledGroupFrequencyList={
                commanFieldsDetails.enabledGroupFrequencyList
              }
              reportStatus={reportStatus}
              checkSelectedKeys={checkSelectedKeys}
              renderErrorMessage={renderErrorMessage}
              showMetricsTooltip={showMetricsTooltip}
              renderDataTableViewButton={renderDataTableViewButton}
              metricsLoader={commanFieldsDetails?.metricDataLoader}
            />
          ) : null}

          {commanFieldsDetails.accountType.isVendor ||
          commanFieldsDetails.accountType.isHybrid ? (
            <RenderVendorFields
              graphData={value?.vendorDetails}
              graphIndex={graphIndex}
              accountType={commanFieldsDetails.accountType}
              handleGraphDetailsOnChange={handleGraphDetailsOnChange}
              renderCheckBox={renderCheckBox}
              getAdTypesSelectComponents={getAdTypesSelectComponents}
              renderRadioButtons={renderRadioButtons}
              selectedTimeFrame={commanFieldsDetails?.selectedTimeFrame}
              enabledGroupFrequencyList={
                commanFieldsDetails.enabledGroupFrequencyList
              }
              reportStatus={reportStatus}
              checkSelectedKeys={checkSelectedKeys}
              renderErrorMessage={renderErrorMessage}
              renderDataTableViewButton={renderDataTableViewButton}
            />
          ) : null}
        </Collapse>
        <ReactTooltip
          id="graphTitleTooltip"
          aria-haspopup="true"
          place="bottom"
          effect="solid"
          html
        />
      </div>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        isOpen={showDeleteGraphConfirmationModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            setIndexToDeleteGraph(null);
            setShowDeleteGraphConfirmationModal(false);
          }}
          role="presentation"
        />
        <ModalBox>
          <div className="modal-body">
            <p className="black-heading-title text-center  mt-2">
              Delete Graph
            </p>
            <div className="alert-msg pb-3 ">
              Are you sure you would like to delete this graph?
              <div className="sure-to-proceed">
                This action cannot be undone.
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-6 mt-4">
                <Button
                  className="btn-primary w-100"
                  onClick={() => {
                    graphDetails.splice(indexToDeleteGraph, 1);
                    commanFieldsDetails.editorDetails.splice(
                      indexToDeleteGraph,
                      1,
                    );
                    commanFieldsDetails.graphApiResponseData.splice(
                      indexToDeleteGraph,
                      1,
                    );
                    commanFieldsDetails.metricsDataAvailabilityFlag.splice(
                      indexToDeleteGraph,
                      1,
                    );
                    if (graphDetails.length === 1) {
                      setIsTimeFrameDisabled(false);
                    }
                    setShowDeleteGraphConfirmationModal(false);
                  }}
                >
                  Confirm Delete
                </Button>
              </div>
              <div className="col-6 mt-4">
                <Button
                  className="btn-transparent w-100"
                  onClick={() => {
                    setIndexToDeleteGraph(null);
                    setShowDeleteGraphConfirmationModal(false);
                  }}
                >
                  Don&apos;t Delete
                </Button>
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  const renderSaveDetailsModal = () => {
    const splitedTimer = commanFieldsDetails?.countDownTimer.split(':');
    return (
      <Modal
        isOpen={commanFieldsDetails.showSaveReportModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        {!commanFieldsDetails.isRemainderUp ? (
          <img
            src={CloseIcon}
            alt="close"
            className={`float-right cursor cross-icon ${
              commanFieldsDetails.saveDetailsLoader ? 'disabled' : ''
            }`}
            onClick={() => {
              commanFieldsDetails.setShowSaveReportModal(false);
              setShowReportPDFTemplate(false);
            }}
            role="presentation"
          />
        ) : null}
        <ModalBox>
          <div
            className={`modal-body ${
              commanFieldsDetails.saveDetailsLoader ? 'disabled' : ''
            }`}
          >
            {(commanFieldsDetails.customReportId !== null ||
              commanFieldsDetails.customReportId !== '') &&
            reportStatus === 'edit' &&
            commanFieldsDetails.isRemainderUp ? (
              <TimmerOut
                className={`${
                  reportStatus === 'readyToEdit' ? 'disabled' : ''
                } mb-4`}
              >
                <div className="label text-center"> TIME REMAINING</div>
                <div className="timmer-block">
                  <span className="px-2">{splitedTimer[1]}</span>MINUTES{' '}
                  <span className="px-2">:</span>
                  <span className="px-2">{splitedTimer[2]}</span>SECONDS
                  <br />
                  <div className="px-2 error-message">
                    Your session is expiring. If your session expires, all of
                    your unsaved data will be lost. To save your data, click
                    save.
                  </div>
                </div>
              </TimmerOut>
            ) : null}

            <p className="black-heading-title">
              {(commanFieldsDetails.customReportId !== null ||
                commanFieldsDetails.customReportId !== '') &&
              reportStatus === 'edit'
                ? 'Edit Downloaded Report'
                : 'Save Report'}
            </p>

            {(commanFieldsDetails.customReportId !== null ||
              commanFieldsDetails.customReportId !== '') &&
            reportStatus === 'edit' &&
            commanFieldsDetails.isRemainderUp ? (
              <div className="normal-text-black mt-1">
                You may continue to edit after saving your progress
              </div>
            ) : null}

            <div className="horizontal-line straight-line mt-3 mb-3" />

            {(commanFieldsDetails.customReportId !== null ||
              commanFieldsDetails.customReportId !== '') &&
            reportStatus === 'edit' ? (
              <ul className="inline-content mb-3">
                {saveReportOptions.map((option) => {
                  return (
                    <li
                      role="presentation"
                      style={{ width: '50%' }}
                      key={`${option.id}`}
                    >
                      <ModalRadioCheck key={option.id}>
                        <label className={option.className} htmlFor={option.id}>
                          {option.label}
                          <input
                            type="radio"
                            id={option.id}
                            name={option.name}
                            onChange={() => setEditReportOptions(option.value)}
                            defaultChecked={option.isDefaultChecked}
                          />
                          <span className="checkmark checkmark-customer-list" />
                        </label>
                      </ModalRadioCheck>
                    </li>
                  );
                })}
              </ul>
            ) : null}

            <div className="pb-3">
              <InputFormField>
                <label htmlFor="reportTitle">Report Title</label>
                <input
                  id="reportTitle"
                  className="form-control"
                  type="text"
                  placeholder="Title Comes Here"
                  value={commanFieldsDetails?.reportTitle}
                  onChange={(event) => {
                    commanFieldsDetails.setReportTitle(event.target.value);
                  }}
                />
              </InputFormField>
              {showSaveModalErrorMessage &&
              commanFieldsDetails?.reportTitle === '' ? (
                <ErrorMsg>This is a required field.</ErrorMsg>
              ) : null}

              <FormField className="mt-2">
                <textarea
                  className=" text-area-box mt-2"
                  rows="4"
                  placeholder="Descripton of the report"
                  value={commanFieldsDetails?.reportDescription}
                  onChange={(event) => {
                    commanFieldsDetails.setReportDescription(
                      event.target.value,
                    );
                  }}
                />
              </FormField>
              {showSaveModalErrorMessage &&
              commanFieldsDetails?.reportDescription === '' ? (
                <ErrorMsg>This is a required field.</ErrorMsg>
              ) : null}
            </div>

            {templateReportTitle !== null ? (
              <div className="row mt-1 mb-2">
                <div className="col-12">
                  <div className="normal-text-black d-flex align-items-center">
                    <FontAwesomeIcon
                      icon=" fa-solid fa-exclamation-circle"
                      className="mr-2"
                      color={Theme.red}
                      fontSize="25px"
                    />
                    Report created is based on a template. Ensure that the
                    necessary guide questions has been deleted and required
                    information has been added.{' '}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="row mt-1">
              <div className="col-12 mt-4">
                <Button
                  className="btn-primary w-100"
                  onClick={() => {
                    handledSaveModal();
                    setShowReportPDFTemplate(true);
                  }}
                >
                  {commanFieldsDetails?.saveDetailsLoader ? (
                    <PageLoader color="#fff" type="button" />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  const renderCustomDateModal = () => {
    return (
      <CustomDateModal
        id="BT-performancereport-daterange-SPdate"
        isOpen={showCustomDateModal}
        ranges={selectedState}
        onClick={() => {
          setShowCustomDateModal(false);
          if (
            commanFieldsDetails?.selectedTimeFrame !== undefined &&
            commanFieldsDetails?.selectedTimeFrame.value !== 'custom'
          ) {
            setSelectedState([
              {
                startDate: currentDate,
                endDate: currentDate,
                key: 'reportSelection',
              },
            ]);
          }
        }}
        onChange={(item) => {
          setSelectedState([item.reportSelection]);
        }}
        onApply={() => {
          applyCustomDate();
        }}
        currentDate={currentDate}
        minDate={new Date('01/01/1901')}
      />
    );
  };

  return (
    <div id="ReportRightSidePanel">
      <CreateReportSidePanel>
        <SidePanel
          className={
            reportStatus === 'edit' || reportStatus === 'readyToEdit'
              ? 'report-side-panel'
              : ''
          }
        >
          {renderReportHeader()}
          {reportStatus !== 'view' ? (
            <>
              {renderReportCommanFieldsDetails()}
              {graphDetails &&
                graphDetails.map((value, index) => {
                  return renderGraphFields(value, index);
                })}
              {renderReportFooter()}
            </>
          ) : null}
        </SidePanel>

        {renderDeleteModal()}

        {renderSaveDetailsModal()}

        {/* custom date modal for sale performance graph */}
        {renderCustomDateModal()}
      </CreateReportSidePanel>
    </div>
  );
};

export default ReportRightSidePanel;

ReportRightSidePanel.defaultProps = {
  customerName: '',
  reportStatus: '',
  templateTimeFrame: '',
  templateReportTitle: '',
  templateReportDescrition: '',
  isTimeFrameDisabled: false,
  graphDetails: [],
  commanFieldsDetails: {},
  data: {},
  selectProps: {},
  selectedState: {},
  dateToShow: {},
  reportSummary: '',
  setSelectedState: [],
  showMetricsTooltip: [],
  currentDate: new Date(),
  setGraphDetails: () => {},
  setReportStatus: () => {},
  setReportSummary: () => {},
  setIsTimeFrameDisabled: () => {},
  setShowReportPDFTemplate: () => {},
};

ReportRightSidePanel.propTypes = {
  customerName: string,
  reportStatus: string,
  reportSummary: string,
  templateTimeFrame: string,
  templateReportTitle: string,
  templateReportDescrition: string,
  isTimeFrameDisabled: bool,
  setGraphDetails: func,
  setReportStatus: func,
  setSelectedState: func,
  setReportSummary: func,
  setIsTimeFrameDisabled: func,
  setShowReportPDFTemplate: func,
  currentDate: instanceOf(Date),
  data: shape({}),
  dateToShow: shape({}),
  commanFieldsDetails: shape({}),
  selectProps: shape({}),
  selectedState: arrayOf(shape({})),
  graphDetails: arrayOf(shape({})),
  showMetricsTooltip: arrayOf(shape({})),
};

const TimmerOut = styled.div`
  margin: 0 auto;
  .timmer-block {
    border-radius: 4px;
    background: ${Theme.lightOrange};
    color: ${Theme.orange};
    padding: 4px 16px;
    color: ${Theme.gray40};
    font-size: ${Theme.extraSmall};
    font-family: ${Theme.titleFontFamily};
    text-transform: uppercase;
    margin: 0 auto;
    width: fit-content;

    span {
      color: ${Theme.orange};
      font-size: ${Theme.secondaryTitleFontSize};
      vertical-align: sub;
    }
    .error-message {
      color: ${Theme.orange};
      font-size: ${Theme.extraNormal};
      text-transform: none;
      vertical-align: sub;
      padding: 5px 0;
      font-family: ${Theme.baseFontFamily};
    }
  }
`;
