import React from 'react';

import styled from 'styled-components';

import { WhiteCard } from '../../../common';

function DashboardReportContainer() {
  return (
    <WhiteCard className="mb-5">
      <AWSQuicksightsDashboard>
        <div style={{ height: '100vh' }}>
          <iframe
            title="Dashboard report"
            width="100%"
            height="100%"
            src="https://lookerstudio.google.com/embed/reporting/402e8d14-2690-4519-9382-b6cb830214a1/page/p_7qaflefe6c"
            frameBorder="0"
            allowFullScreen
          />
        </div>
        <div id="footer-strip" />
      </AWSQuicksightsDashboard>
    </WhiteCard>
  );
}

export default DashboardReportContainer;
const AWSQuicksightsDashboard = styled.div`
  #footer-strip {
    height: 30px;
    background: #ffffff;
    margin-top: -25px;
    position: relative;
  }
`;
