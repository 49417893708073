import React, { useState } from 'react';

// third party imports
import dayjs from 'dayjs';
import styled from 'styled-components';

// local file imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, func, shape, bool, objectOf } from 'prop-types';
import { Collapse } from 'react-collapse';

import Theme from '../../../../../theme/Theme';
import { WhiteCard } from '../../../../../common';

const GoalMobileView = ({
  selectedMarketplaces,
  editedGoalsData,
  marketplaceCollapse,
  changeCollapseFlag,
  monthNames,
  newGoalsData,
  isEditGoals,
  customerData,
  displayAddNewGoalBtn,
  displaySaveAndCancelBtn,
  marketplaceForNewGoal,
  showAddNewGoalRow,
  displayGoalMilestoneLabel,
  displayGoalValueAndInputbox,
  displayMarketplaceCollapseIcon,
}) => {
  const [viewMoreDetailsCount, setViewMoreDetailsCount] = useState({
    milestoneId: null,
    count: 2,
    message: `View ${monthNames.length - 2} more details`,
  });

  const showMonthValues = (
    milestones,
    selectedMarketplace,
    goal,
    monthData,
    index,
  ) => {
    return milestones?.milestone
      .slice(0, viewMoreDetailsCount?.count)
      ?.filter(
        (milestone) =>
          milestone?.year === monthData?.year &&
          milestone?.month === monthData?.month,
      )
      ?.map((item) => {
        return displayGoalValueAndInputbox(
          item,
          index,
          selectedMarketplace,
          milestones,
          goal,
        );
      });
  };

  // display view more detail message in capsule collapse
  const viewMoreDetailsMessage = (milestoneId) => {
    if (viewMoreDetailsCount?.count === 2) {
      setViewMoreDetailsCount({
        milestoneId,
        count: monthNames.length,
        message: 'View less',
      });
    } else {
      setViewMoreDetailsCount({
        milestoneId,
        count: 2,
        message: `View ${monthNames.length - 2} more details`,
      });
    }
  };

  // display goal milestones with capsule collapse
  const displayMilestones = (goal, selectedMarketplace) => {
    return goal?.milestones?.map((milestone) => {
      const isCollapseOpened = marketplaceCollapse?.find(
        (item) => item?.marketplace === selectedMarketplace?.id,
      )?.collapse;

      return (
        <div className={isCollapseOpened ? 'py-1' : ''}>
          <Collapse isOpened={isCollapseOpened} key={milestone?.id}>
            <fieldset className="performance-fielset w-430 mb-4">
              <div className="row" id={milestone.id}>
                <div className="col-12">
                  {displayGoalMilestoneLabel(milestone)}
                </div>
                {viewMoreDetailsCount?.milestoneId === milestone?.id
                  ? monthNames
                      .slice(0, viewMoreDetailsCount?.count)
                      ?.map((item, index) => {
                        return (
                          <div className="col-6 mt-3" key={item?.label}>
                            <div className="label mb-0" id={item?.key}>
                              {dayjs(item?.label).format('MMMM')}
                            </div>
                            <div className="normal-text-black text-medium">
                              {showMonthValues(
                                milestone,
                                selectedMarketplace,
                                goal,
                                item,
                                index,
                              )}
                            </div>
                          </div>
                        );
                      })
                  : monthNames.slice(0, 2)?.map((item, index) => {
                      return (
                        <div className="col-6 mt-3" key={item?.label}>
                          <div className="label mb-0" id={item?.key}>
                            {dayjs(item?.label).format('MMMM')}
                          </div>
                          <div className="normal-text-black text-medium">
                            {showMonthValues(
                              milestone,
                              selectedMarketplace,
                              goal,
                              item,
                              index,
                            )}
                          </div>
                        </div>
                      );
                    })}
              </div>
              {monthNames.length > 2 ? (
                <div
                  className="capsule-collpase"
                  role="presentation"
                  onClick={() => {
                    viewMoreDetailsMessage(milestone?.id);
                  }}
                >
                  {viewMoreDetailsCount?.milestoneId === milestone?.id
                    ? viewMoreDetailsCount?.message
                    : `View ${monthNames.length - 2} more details`}

                  <FontAwesomeIcon
                    icon="fa-regular fa-angle-down"
                    className="dropdown-arrow-icon"
                    style={{
                      transform:
                        viewMoreDetailsCount.count === monthNames?.length
                          ? 'rotate(180deg)'
                          : '',
                      marginLeft: '10px',
                      verticalAlign: 'middle',
                    }}
                  />
                </div>
              ) : (
                ''
              )}
            </fieldset>
          </Collapse>
        </div>
      );
    });
  };

  // display goals marketplace collapse head
  const displayGoalMarketplaceCollapseHead = (marketplace) => {
    return (
      <div
        className="row mb-3"
        role="button"
        tabIndex={0}
        onClick={() => {
          changeCollapseFlag(marketplace);
        }}
        onKeyDown={() => {
          changeCollapseFlag(marketplace);
        }}
      >
        <div className="col-9">
          <div className="black-heading-title">{marketplace?.label}</div>
        </div>
        <div className="col-3 text-right">
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              changeCollapseFlag(marketplace);
            }}
            onKeyDown={() => {
              changeCollapseFlag(marketplace);
            }}
          >
            {displayMarketplaceCollapseIcon(
              marketplace?.id,
              '5px',
              '15px',
              null,
            )}
          </span>
        </div>
      </div>
    );
  };

  // display goals mobile view
  const displayGoalsMobileView = () => {
    return selectedMarketplaces?.map((marketplace) => {
      return editedGoalsData
        ?.filter((goal) => goal?.marketplace === marketplace?.id)
        .map((goal) => {
          return (
            <WhiteCard className="mb-3" key={goal?.id}>
              {displayGoalMarketplaceCollapseHead(marketplace)}
              {displayMilestones(goal, marketplace)}
              {/* Add goal */}
              {isEditGoals
                ? newGoalsData
                    ?.filter((newGoal) =>
                      Object.keys(newGoal)?.includes(marketplace?.id),
                    )?.[0]
                    ?.[marketplace?.id]?.map((item, index) => (
                      <div key={item}>
                        {showAddNewGoalRow(marketplace, goal, item, index)}
                      </div>
                    ))
                : null}
              {!customerData?.goal_set?.show || !isEditGoals
                ? null
                : displayAddNewGoalBtn(marketplace, goal)}
              {marketplaceForNewGoal?.id === marketplace?.id &&
              newGoalsData?.length
                ? displaySaveAndCancelBtn(marketplace)
                : null}
            </WhiteCard>
          );
        });
    });
  };

  return <GoalMobileViewStyle>{displayGoalsMobileView()}</GoalMobileViewStyle>;
};

export default GoalMobileView;
GoalMobileView.defaultProps = {
  isEditGoals: false,
  selectedMarketplaces: [],
  editedGoalsData: [],
  marketplaceCollapse: [],
  changeCollapseFlag: () => {},
  newGoalsData: [],
  showAddNewGoalRow: () => {},
  marketplaceForNewGoal: {},
  displaySaveAndCancelBtn: () => {},
  displayAddNewGoalBtn: () => {},
  customerData: {},
  displayGoalMilestoneLabel: () => {},
  displayGoalValueAndInputbox: () => {},
  displayMarketplaceCollapseIcon: () => {},
};

GoalMobileView.propTypes = {
  isEditGoals: bool,
  selectedMarketplaces: arrayOf(shape({})),
  editedGoalsData: arrayOf(shape({})),
  marketplaceCollapse: arrayOf(shape({})),
  changeCollapseFlag: func,
  showAddNewGoalRow: func,
  monthNames: arrayOf(shape({})).isRequired,
  marketplaceForNewGoal: shape({}),
  newGoalsData: arrayOf(shape({})),
  displaySaveAndCancelBtn: func,
  displayAddNewGoalBtn: func,
  customerData: objectOf({ goal_set: objectOf({ show: bool }) }),
  displayGoalMilestoneLabel: func,
  displayGoalValueAndInputbox: func,
  displayMarketplaceCollapseIcon: func,
};

const GoalMobileViewStyle = styled.div`
  .performance-fielset {
    position: relative;
  }
  .capsule-collpase {
    border-radius: 16px;
    bottom: -16px;
    border: 1px solid ${Theme.gray11};
    color: ${Theme.black};
    font-size: ${Theme.extraNormal};
    font-family: Noah-Medium;
    background: ${Theme.white};
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 6px 20px;
    text-align: center;
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
  }
`;
