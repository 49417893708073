/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, shape, func, string, bool } from 'prop-types';

import Theme from '../../../../../theme/Theme';
import { CloseIcon } from '../../../../../theme/images';
import { deleteCreativeProject, uploadCreativeSheet } from '../../../../../api';
import {
  PerformanceSumarryTiles,
  asinsDeleteMessage,
  customStyles,
  orderedVendorPerformanceSumarryTiles,
  projectDeleteMessage,
  shippedVendorPerformanceSumarryTiles,
} from '../../../../../constants';
import {
  CardMetrics,
  CheckBox,
  InputSearchWithRadius,
  PageLoader,
  ModalBox,
  Button,
} from '../../../../../common';

const CardMetricsGraph = ({
  projectDetails,
  selectedCreativeProjectMetrics,
  setSelectedCreativeProjectMetrics,
  creativeProjectData,
  multiSelectedValues,
  setMultiSelectedValues,
  isLoadingGraph,
  accountType,
  selectedVendorReporting,
  isAllowToDeleteProject,
  getCreativeMonths,
  customerId,
  setIsSelectProjectDropdownOption,
  globalFiltersOptions,
  setGlobalFiltersOptions,
  setCallGraphApi,
}) => {
  const [disableMetrics, setDisableMetrics] = useState(false);
  const [selectedSearchQuery, setSelectedSearchQuery] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [asinData, setAsinData] = useState([
    { asinIds: [], originalAsinIds: [] },
  ]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  let accountBasedArray = [];
  // check the account type and change the card metrics based on it.
  if (accountType === 'vendor') {
    if (selectedVendorReporting === 'ordered_revenue') {
      accountBasedArray = orderedVendorPerformanceSumarryTiles;
    } else if (selectedVendorReporting === 'shipped_cogs') {
      accountBasedArray = shippedVendorPerformanceSumarryTiles;
    }
  } else accountBasedArray = PerformanceSumarryTiles;

  // Set currency symbol as per selected marketplaces.
  // If more than two marketplaces are selected the show the dollor symbol
  useEffect(() => {
    if (multiSelectedValues?.marketplaces?.length === 1) {
      const projectObject = multiSelectedValues?.projects?.find(
        (item) =>
          item?.marketplace_id ===
          multiSelectedValues?.marketplaces?.[0]?.value,
      );
      setCurrencySymbol(projectObject?.currency);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (multiSelectedValues?.asin?.length > 0) {
        if (multiSelectedValues?.asin?.length === 1) {
          const projectObject = multiSelectedValues?.projects?.find(
            (item) =>
              item?.asin_checksum ===
              multiSelectedValues?.asin?.[0]?.split('-')?.[1],
          );
          setCurrencySymbol(projectObject?.currency);
        } else {
          const fields = [];
          // if single/multiple asins selected
          for (const item of multiSelectedValues?.asin) {
            fields.push(item.split('-')?.[1]);
            const totalChecksums = [...new Set(fields)];
            if (totalChecksums?.length === 1) {
              // if single asins selected then show its currency
              const projectObject = multiSelectedValues?.projects?.find(
                (check) => check?.asin_checksum === totalChecksums[0],
              );
              setCurrencySymbol(projectObject?.currency);
            } else {
              // if multiple asins selected then show its currency
              let marketplaceNames = [];
              for (const check of totalChecksums) {
                for (const project of multiSelectedValues?.projects) {
                  if (check === project?.asin_checksum) {
                    marketplaceNames.push(project?.marketplace_id);
                  }
                }
              }
              marketplaceNames = marketplaceNames?.length
                ? [...new Set(marketplaceNames)]
                : [];
              if (marketplaceNames?.length === 1) {
                // if multiple asins selected of the same project and marketplace then show its currency
                const projectForCurrency = multiSelectedValues?.projects?.find(
                  (pro) => pro?.marketplace_id === marketplaceNames[0],
                );
                setCurrencySymbol(projectForCurrency?.currency);
              } else {
                // if multiple asins of different marketplaces are selected then show dollor
                setCurrencySymbol('$');
              }
            }
          }
        }
      } else {
        // const fields = [];
        // for (const item of multiSelectedValues?.asin) {
        //   fields.push(item.split('-')?.[1]);
        //   const totalChecksums = [...new Set(fields)];
        //   if (totalChecksums?.length === 1) {
        //     const projectObject = multiSelectedValues?.projects?.find(
        //       (check) => check?.asin_checksum === totalChecksums[0],
        //     );
        //     setCurrencySymbol(projectObject?.currency);
        //   } else {
        //     console.log('11111');
        //     setCurrencySymbol('$');
        //   }
        // }
        // eslint-disable-next-line no-lonely-if
        if (multiSelectedValues?.projects?.length === 1) {
          setCurrencySymbol(multiSelectedValues?.projects?.[0]?.currency);
        } else {
          let projectCurrencies = [];
          for (const project of multiSelectedValues?.projects) {
            projectCurrencies.push(project?.currency);
          }
          projectCurrencies = projectCurrencies?.length
            ? [...new Set(projectCurrencies)]
            : [];
          if (projectCurrencies?.length > 1) {
            setCurrencySymbol('$');
          } else {
            setCurrencySymbol(projectCurrencies?.[0]);
          }
        }
      }
    }
  }, [multiSelectedValues]);

  useEffect(() => {
    // This useEffect is for, to get all the asins having same completion date and same project and keep in one object
    const fields = [];
    for (const details of projectDetails) {
      // Initially all asins are saved as a string and saperated by quammas, so first we saves that asins into an array
      const fieldsTemp = {
        id: details?.id,
        completion_date: details.completion_date,
        asin_checksum: details.asin_checksum,
        project_type: details.project_type,
        asins_listings: [],
        label: details.label,
      };
      for (const asin of details?.asins_listings?.split(',')) {
        if (asin) fieldsTemp.asins_listings.push(asin);
      }
      fields.push(fieldsTemp);
    }

    // Marge all the asins in to one array under project, whose completion date and project type are same, and keep its asin checksum which is of first project
    const combinedItems = (arr = []) => {
      const res = arr.reduce((acc, obj) => {
        let found = false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < acc.length; i++) {
          if (
            acc[i].completion_date === obj.completion_date &&
            acc[i].project_type === obj.project_type &&
            acc[i].label === obj.label
          ) {
            found = true;
            acc[i].asins_listings = [
              ...acc[i].asins_listings,
              ...obj.asins_listings,
            ];
            // eslint-disable-next-line no-plusplus
            acc[i].count++;
          }
        }
        if (!found) {
          obj.count = 1;
          acc.push(obj);
        }
        return acc;
      }, []);
      return res;
    };

    const combinedAsins = combinedItems(fields);
    if (combinedAsins?.length && deleteInfo === null) {
      if (selectedSearchQuery?.length > 0) {
        setAsinData({ ...asinData, originalAsinIds: combinedAsins });
      } else {
        setAsinData({ asinIds: combinedAsins, originalAsinIds: combinedAsins });
      }
    } else if (deleteInfo === null) {
      setAsinData({ asinIds: [], originalAsinIds: [] });
    }
    if (!deleteLoader) setDeleteInfo(null);
  }, [projectDetails]);

  useEffect(() => {
    // When the component loads first time then select the first card metric
    if (accountType === 'seller')
      setSelectedCreativeProjectMetrics({ ordered_product_sales: true });
    else if (accountType !== 'seller') {
      if (selectedVendorReporting === 'ordered_revenue')
        setSelectedCreativeProjectMetrics({ ordered_revenue: true });
      else setSelectedCreativeProjectMetrics({ shipped_cogs: true });
    }
  }, [accountType, selectedVendorReporting]);

  useEffect(() => {
    // Set disabled flag, if 4 card metrics are selected then disabled other metrics
    if (Object.keys(selectedCreativeProjectMetrics).length === 4) {
      setDisableMetrics(true);
    } else {
      setDisableMetrics(false);
    }
  }, [selectedCreativeProjectMetrics]);

  // Handle change function to select/ deselect card metrics on click
  const handleMetricsChange = (metricName) => {
    const selectedMetrics = Object.keys(selectedCreativeProjectMetrics);
    if (selectedMetrics.includes(metricName) && selectedMetrics?.length > 1) {
      const removeMetricName = { ...selectedCreativeProjectMetrics };
      delete removeMetricName[metricName];
      setSelectedCreativeProjectMetrics({
        ...removeMetricName,
      });
    } else {
      setSelectedCreativeProjectMetrics({
        ...selectedCreativeProjectMetrics,
        [metricName]: true,
      });
    }
  };

  // add quamma into number
  const displayNumber = (num) => {
    const res = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return res;
  };

  // Map each card metrics and its data
  const renderCardMetrics = () => {
    return (
      <>
        {accountBasedArray?.map((metric) => {
          const currentSumValue =
            creativeProjectData?.current_sum?.[metric?.key];
          const previousSumValue =
            creativeProjectData?.previous_sum?.[metric?.key];
          const differenceValue =
            creativeProjectData?.difference?.[metric?.key];
          return (
            <>
              <div
                className="col-6 col-md-4 col-lg-3 pr-2 mb-3"
                key={metric?.name}
                data-for={metric?.key}
                data-tip={
                  !currentSumValue && !previousSumValue
                    ? `No ${metric?.name} available for this period`
                    : metric?.dataAvailabletoolTip
                }
              >
                <div
                  className={`${
                    (disableMetrics &&
                      !Object?.keys(selectedCreativeProjectMetrics)?.includes(
                        metric?.key,
                      )) ||
                    (!currentSumValue && !previousSumValue)
                      ? 'disabled'
                      : ''
                  }`}
                  role="presentation"
                  onClick={() => handleMetricsChange(metric?.key)}
                >
                  <CardMetrics className="fix-height">
                    <div
                      className="order-chart-box  fix-height "
                      style={
                        Object?.keys(selectedCreativeProjectMetrics)?.includes(
                          metric?.key,
                        )
                          ? {
                              border: `1px solid ${metric?.color}`,
                              borderRadius: '8px',
                            }
                          : {
                              border: `1px solid ${Theme.gray45}`,
                              borderRadius: '8px',
                            }
                      }
                    >
                      <div className="chart-name">{metric?.name}</div>
                      <div className="number-rate">
                        {metric?.symbol === 'currency' ? currencySymbol : ''}
                        {currentSumValue
                          ? displayNumber(
                              metric?.decimal
                                ? currentSumValue?.toFixed(2)
                                : currentSumValue,
                            )
                          : 0}
                        {metric?.symbol === 'percentage' ? '%' : ''}
                      </div>
                      <div className="vs">
                        vs {metric?.symbol === 'currency' ? currencySymbol : ''}
                        {previousSumValue
                          ? displayNumber(
                              metric.decimal
                                ? previousSumValue?.toFixed(2)
                                : previousSumValue,
                            )
                          : 0}
                        {metric?.symbol === 'percentage' ? '%' : ''}
                      </div>
                      {differenceValue === 0 ? (
                        <div className="perentage-value mt-3 pt-1">0%</div>
                      ) : differenceValue ? (
                        Number(differenceValue) > 0 ? (
                          <div className="perentage-value up mt-3 pt-1">
                            <FontAwesomeIcon
                              icon="fa-light fa-arrow-up"
                              className="mr-2"
                            />
                            {Number(differenceValue)
                              ? Number(differenceValue)
                              : 0}
                            %
                          </div>
                        ) : (
                          <div className="perentage-value down mt-3 pt-1">
                            <FontAwesomeIcon
                              icon="fa-light fa-arrow-down"
                              className="mr-2"
                            />
                            {Math?.abs(Number(differenceValue))
                              ? Math?.abs(
                                  Number(
                                    displayNumber(
                                      creativeProjectData?.difference?.[
                                        metric?.key
                                      ]?.toFixed(2),
                                    ),
                                  ),
                                )
                              : 0}
                            %
                          </div>
                        )
                      ) : (
                        <div className="perentage-value down mt-3 pt-1">
                          N/A
                        </div>
                      )}
                    </div>
                  </CardMetrics>
                </div>
              </div>
              <ReactTooltip
                id={metric?.key}
                aria-haspopup="true"
                place="top"
                effect="solid"
              />
            </>
          );
        })}
      </>
    );
  };

  const parentAsinHandleChange = (
    completionDate,
    projectType,
    isChecked,
    isParentAsinChecked,
    asinChecksum,
  ) => {
    const selectedProjectAsin = asinData?.asinIds?.filter(
      (op) =>
        op?.completion_date === completionDate &&
        op?.project_type === projectType,
    );
    let selectedAsins = [];
    let selectedProjectLabels = [];
    if (multiSelectedValues?.asin?.length) {
      selectedAsins = [...multiSelectedValues.asin];
    }
    if (multiSelectedValues?.projectLabels?.length) {
      selectedProjectLabels = [...multiSelectedValues.projectLabels];
    }
    if (isChecked) {
      selectedProjectLabels = [...selectedProjectLabels, asinChecksum];
      if (selectedAsins?.length) {
        selectedAsins = [
          ...selectedAsins,
          ...selectedProjectAsin[0].asins_listings?.map(
            (item) => `${item}-${asinChecksum}`,
          ),
        ];
      } else {
        selectedAsins = [
          ...selectedProjectAsin[0].asins_listings?.map(
            (item) => `${item}-${asinChecksum}`,
          ),
        ];
      }
    } else {
      const uncheckedParentAsin = selectedProjectAsin.filter(
        (op) => op.asin_checksum === asinChecksum,
      );
      if (isParentAsinChecked) {
        selectedAsins = selectedAsins.filter(
          (val) =>
            !(
              uncheckedParentAsin?.[0]?.asins_listings.includes(
                val?.split('-')?.[0],
              ) &&
              uncheckedParentAsin?.[0]?.asin_checksum === val?.split('-')?.[1]
            ),
        );
        $('.checkboxes input:checkbox').prop('checked', false);
      }
      selectedProjectLabels = selectedProjectLabels.filter(
        (op) => asinChecksum !== op,
      );
    }

    setMultiSelectedValues({
      ...multiSelectedValues,
      asin: [...new Set(selectedAsins)],
      projectLabels: selectedProjectLabels,
    });
    setCallGraphApi(true);
  };

  // Handle asins on checkbox
  const childAsinHandleChange = (asin, asinChecksum) => {
    let selectedAsin = [];
    let selectedProjectLabels = multiSelectedValues?.projectLabels?.length
      ? [...multiSelectedValues?.projectLabels]
      : [];
    selectedProjectLabels = selectedProjectLabels?.filter(
      (op) => op !== asinChecksum,
    );
    if (multiSelectedValues?.asin?.length) {
      selectedAsin = [...multiSelectedValues?.asin];
      if (selectedAsin?.find((item) => item === `${asin}-${asinChecksum}`)) {
        selectedAsin = selectedAsin.filter(
          (op) => op !== `${asin}-${asinChecksum}`,
        );
      } else {
        selectedAsin = [...selectedAsin, `${asin}-${asinChecksum}`];
      }
    } else {
      selectedAsin = [`${asin}-${asinChecksum}`];
    }
    setMultiSelectedValues({
      ...multiSelectedValues,
      asin: selectedAsin,
      projectLabels: selectedProjectLabels,
    });
    setCallGraphApi(true);
  };

  const handledOnDeleteIconClick = (whatToDelete, deleteItemInfo, asinInfo) => {
    setShowDeleteConfirmationModal(true);
    setDeleteInfo({
      type: whatToDelete,
      projectInfo: deleteItemInfo,
      asinInfo,
    });
  };

  const handledOnConfirmDeleteClick = () => {
    setDeleteLoader(true);
    // If project is deleted.

    if (deleteInfo?.type === 'project') {
      // call this because to uncheck the selected project.
      parentAsinHandleChange(
        deleteInfo?.projectInfo?.completion_date,
        deleteInfo?.projectInfo?.project_type,
        false, // is checked
        true, // is parent
        deleteInfo?.projectInfo?.asin_checksum,
      );
      deleteCreativeProject(deleteInfo?.projectInfo?.id).then(() => {
        toast.success(`Project is deleted successfully.`);
        setShowDeleteConfirmationModal(false);
        setDeleteLoader(false);
        getCreativeMonths();
      });
    } else {
      // If asin is deleted.

      // to delete the asins we are calling the patch method of creative-project.
      // and removeing the deleted asin from parameters.
      const apiParameter = {
        customer: customerId,
        asins_listings: deleteInfo?.projectInfo?.asins_listings.filter(
          (ele) => ele !== deleteInfo?.asinInfo,
        ),
      };

      // removeing the asin from the display list "asinData"
      const local = JSON.parse(JSON.stringify(asinData.asinIds));
      local.forEach((ele) => {
        if (ele?.id === deleteInfo?.projectInfo?.id) {
          ele.asins_listings = ele.asins_listings.filter(
            (item) => item !== deleteInfo?.asinInfo,
          );
        }
      });
      setAsinData({ ...asinData, asinIds: local });

      // Patch API call to save the asin details.
      uploadCreativeSheet(
        deleteInfo?.projectInfo?.id || null,
        apiParameter,
      ).then((creative) => {
        if (creative?.status === 200) {
          // uncheck the selected asin from list
          childAsinHandleChange(
            deleteInfo?.asinInfo,
            deleteInfo?.projectInfo?.asin_checksum,
          );
          toast.success('ASIN is deleted successfully.');

          // removing the asin from main object
          const result = [];
          globalFiltersOptions.projects.forEach((ele) => {
            if (ele.id === deleteInfo?.projectInfo?.id) {
              const localProject = ele;
              localProject.asins_listings = ele?.asins_listings
                .replace(`${deleteInfo?.asinInfo}`, '')
                .replace(`,,`, ',');
              result.push(localProject);
            } else {
              result.push(ele);
            }
          });
          setGlobalFiltersOptions({
            ...globalFiltersOptions,
            projects: result,
          });
          setShowDeleteConfirmationModal(false);
          setDeleteLoader(false);
          setIsSelectProjectDropdownOption(true);
        } else {
          toast.error('Something went wrong.');
          setShowDeleteConfirmationModal(false);
          setDeleteLoader(false);
        }
      });
    }
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        id="asinsDeleteModal"
        isOpen={showDeleteConfirmationModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            setDeleteInfo(null);
            setShowDeleteConfirmationModal(false);
          }}
          role="presentation"
        />
        <ModalBox>
          <div className="modal-body">
            <p className="black-heading-title text-center  mt-2">
              {deleteInfo?.type === 'project'
                ? 'Delete Project'
                : 'Delete ASIN'}
            </p>
            <div className="alert-msg pb-3 ">
              {deleteInfo?.type === 'project'
                ? projectDeleteMessage
                : asinsDeleteMessage}
              <div className="sure-to-proceed">
                Please confirm if you would like to proceed.
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-6 mt-4">
                <Button
                  className="btn-primary w-100"
                  onClick={() => {
                    handledOnConfirmDeleteClick();
                  }}
                >
                  {deleteLoader ? (
                    <PageLoader color="#fff" type="button" />
                  ) : (
                    'Delete'
                  )}
                </Button>
              </div>
              <div className="col-6 mt-4">
                <Button
                  className="btn-transparent w-100"
                  onClick={() => {
                    setDeleteInfo(null);
                    setShowDeleteConfirmationModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  // Show asin's completion date and asin ids on checkbox
  const renderAsinCheckboxex = () => {
    if (asinData?.asinIds?.length) {
      return asinData?.asinIds?.map((item) => {
        return (
          <>
            <CheckBox className="mb-3 d-flex justify-content-between">
              <label
                className="checkboxes check-container customer-pannel check-main-label mb-0"
                htmlFor={item?.asin_checksum}
              >
                {item?.label}
                <input
                  className="checkboxes"
                  type="checkbox"
                  id={item?.asin_checksum}
                  // name={item?.asin_checksum}
                  onChange={(event) => {
                    parentAsinHandleChange(
                      item?.completion_date,
                      item?.project_type,
                      event.target.checked,
                      event.target.id === item?.asin_checksum,
                      event.target.id,
                    );
                  }}
                  checked={multiSelectedValues?.projectLabels?.find(
                    (selectedLabel) =>
                      selectedLabel?.toLowerCase() === item.asin_checksum,
                  )}
                />

                <span className="checkmark" />
              </label>
              {isAllowToDeleteProject &&
              multiSelectedValues?.projectLabels?.find(
                (selectedLabel) =>
                  selectedLabel?.toLowerCase() === item.asin_checksum,
              ) ? (
                <FontAwesomeIcon
                  onClick={() => {
                    handledOnDeleteIconClick('project', item, null);
                  }}
                  icon="fa-regular fa-trash"
                  style={{
                    color: '#000',
                    fontSize: '14px',
                  }}
                  className="cursor  pl-1"
                />
              ) : null}
            </CheckBox>
            {item?.asins_listings?.map((asin) => {
              return (
                <CheckBox className="mb-3 d-flex justify-content-between">
                  <label
                    className="checkboxes check-container customer-pannel check-child-label mb-0"
                    htmlFor={`${item.label}_${asin}`}
                  >
                    {asin}
                    <input
                      className="checkboxes"
                      type="checkbox"
                      id={`${item.label}_${asin}`}
                      // checked={multiSelectedValues?.asin?.find(
                      //   (selectedAsin) =>
                      //     selectedAsin?.toLowerCase() === asin?.toLowerCase(),
                      // )}
                      checked={multiSelectedValues?.asin?.find(
                        (selectedAsin) =>
                          selectedAsin?.toLowerCase() ===
                          `${asin}-${item?.asin_checksum}`?.toLowerCase(),
                      )}
                      onChange={() => {
                        childAsinHandleChange(asin, item.asin_checksum);
                      }}
                    />

                    <span className="checkmark" />
                  </label>
                  {isAllowToDeleteProject &&
                  multiSelectedValues?.asin?.find(
                    (selectedAsin) =>
                      selectedAsin?.toLowerCase() ===
                      `${asin}-${item?.asin_checksum}`?.toLowerCase(),
                  ) ? (
                    <FontAwesomeIcon
                      onClick={() => {
                        handledOnDeleteIconClick('asins', item, asin);
                      }}
                      icon="fa-regular fa-trash"
                      style={{
                        color: '#000',
                        fontSize: '14px',
                      }}
                      className="cursor  pl-1"
                    />
                  ) : null}
                </CheckBox>
              );
            })}
          </>
        );
      });
    }
    return <div className="text-center">No record found.</div>;
  };

  // To search proper asin according to input
  const searchAsinIds = (value) => {
    // first we filter the projects which haves that searched asin
    let searchedAsin = asinData?.originalAsinIds?.map((op) => {
      return op?.asins_listings?.filter((op1) =>
        op1.toLowerCase().includes(value) ? op : null,
      )?.length
        ? op
        : null;
    });
    searchedAsin = searchedAsin?.filter(Boolean);
    // Then we filter only that searched asin into the asin array of project
    const finalSearchedAsin = searchedAsin?.map((item) => {
      return {
        asin_checksum: item?.asin_checksum,
        completion_date: item?.completion_date,
        project_type: item?.project_type,
        label: item?.label,
        asins_listings: item?.asins_listings?.filter((op1) =>
          op1.toLowerCase().includes(value),
        ),
      };
    });
    setAsinData({ ...asinData, asinIds: finalSearchedAsin });
  };

  useEffect(() => {
    if (selectedSearchQuery?.length > 0) {
      searchAsinIds(selectedSearchQuery);
    }
  }, [selectedSearchQuery, multiSelectedValues]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-9 order-2 order-sm-1 ">
          {!isLoadingGraph?.loader ? (
            <div className="row">{renderCardMetrics()}</div>
          ) : (
            <PageLoader
              component="creative-project-graph"
              color="#FF5933"
              type="detail"
              width={40}
              height={40}
            />
          )}
        </div>
        <div className="col-12 col-sm-3 order-1 order-sm-2 pl-2 mb-2 mb-sm-0">
          <ProjectCheckList
            className={`${isLoadingGraph?.loader ? 'disabled' : ''}`}
          >
            <InputSearchWithRadius className="mb-3">
              <input
                className=" form-control height-32"
                placeholder="Search"
                name="search"
                onChange={(event) => {
                  searchAsinIds(event.target.value.toLowerCase());
                  setSelectedSearchQuery(event.target.value.toLowerCase());
                }}
              />
              <FontAwesomeIcon
                icon="fa-regular fa-search"
                style={{
                  color: Theme.black,
                  fontSize: Theme.extraNormal,
                  left: '12px',
                  top: '13px',
                  position: 'absolute',
                }}
              />
            </InputSearchWithRadius>
            {renderAsinCheckboxex()}
          </ProjectCheckList>
        </div>
      </div>
      {renderDeleteModal()}
    </>
  );
};

export default CardMetricsGraph;

CardMetricsGraph.defaultProps = {
  isAllowToDeleteProject: false,
  accountType: 'seller',
  selectedVendorReporting: 'ordered_revenue',
  customerId: '',
  projectDetails: [],
  globalFiltersOptions: [],
  selectedCreativeProjectMetrics: {},
  creativeProjectData: {},
  multiSelectedValues: {},
  isLoadingGraph: {},
  setMultiSelectedValues: () => {},
  setSelectedCreativeProjectMetrics: () => {},
  getCreativeMonths: () => {},
  setIsSelectProjectDropdownOption: () => {},
  setGlobalFiltersOptions: () => {},
  setCallGraphApi: () => {},
};

CardMetricsGraph.propTypes = {
  isAllowToDeleteProject: bool,
  accountType: string,
  customerId: string,
  selectedVendorReporting: string,
  projectDetails: arrayOf(shape({})),
  globalFiltersOptions: arrayOf(shape({})),
  selectedCreativeProjectMetrics: shape({}),
  creativeProjectData: shape({}),
  multiSelectedValues: shape({}),
  isLoadingGraph: shape({}),
  setSelectedCreativeProjectMetrics: func,
  setMultiSelectedValues: func,
  getCreativeMonths: func,
  setIsSelectProjectDropdownOption: func,
  setGlobalFiltersOptions: func,
  setCallGraphApi: func,
};

const ProjectCheckList = styled.div`
  border-radius: 8px;
  background: ${Theme.gray8};
  padding: 24px 12px 9px 12px;
  max-height: 300px;
  overflow: auto;

  .check-main-label {
    font-size: ${Theme.extraMedium} !important;
    font-family: ${Theme.titleFontFamily};
  }

  .check-child-label {
    font-size: ${Theme.extraMedium} !important;
    font-family: ${Theme.baseFontFamily};
  }
`;
