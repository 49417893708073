import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// render collapse dropdown icon
export const renderCollapseDropdownIcon = (showCollapse) => {
  return (
    <span className="collapse-icon">
      <FontAwesomeIcon
        icon="fa-regular fa-angle-down"
        fontSize="12px"
        style={{
          transform: showCollapse ? '' : 'rotate(270deg)',
        }}
      />
    </span>
  );
};

// handle month dropdown
export const handleMonthDropdown = (
  event,
  listData,
  setUpdatedServicesOfPlaybookSummary,
  updatedServicesOfPlaybookSummary,
) => {
  const list = [...updatedServicesOfPlaybookSummary?.create];
  const index = list?.findIndex((item) => item?.id === listData?.id);

  if (index > -1) {
    list[index] = {
      ...list[index],
      frequency: event?.value,
    };
  }
  setUpdatedServicesOfPlaybookSummary({
    create: list,
    delete: updatedServicesOfPlaybookSummary?.delete,
  });
};

// render assignee tooltip
export const renderAssigneeTooltip = (item, id, tooltipComponent) => {
  return (
    <ReactTooltip
      id={id}
      aria-haspopup="true"
      place="bottom"
      effect="solid"
      backgroundColor="#2e384d"
      fontSize="14px"
      className="assigneetooltip"
    >
      {tooltipComponent === 'services-task-assignee' ? (
        <strong>
          {item?.assignee_first_name || ' '} {item?.assignee_last_name || ' '}
        </strong>
      ) : (
        <strong>
          {item?.first_name || ' '} {item?.last_name || ' '}
        </strong>
      )}
    </ReactTooltip>
  );
};
