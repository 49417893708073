import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';
import getSymbolFromCurrency from 'currency-symbol-map';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import useFormatNumber from '../../../../hooks/useFormatNumber';
import Theme from '../../../../theme/Theme';
import UnderStockOverStockASINMobileView from './UnderStockOverStockASINMobileView';
import {
  AllocateBar,
  CommonPagination,
  NoData,
  PageLoader,
  Table,
  Tabs,
  WhiteCard,
} from '../../../../common';
import {
  OverStockTableheader,
  stockedTooltip,
  underStockTableheader,
} from '../../../../constants';
import { IventoryTableWrapper } from './InventoryStyle';
import InventoryManagementInfoIcon from './InventoryManagementInfoIcon';

const UnderStockOverStockASIN = ({
  stockedData,
  isDesktop,
  isLoader,
  count,
  pageNumber,
  noDataMessage,
  stockedOrderByFlag,
  stockedOrderByKey,
  handlePageChange,
  handleSortFilters,
  activeASINTab,
  setActiveASINTab,
  marketPlace,
  setStockedOrderByKey,
  setStockedOrderBy,
  reportLoader,
  onDownloadStockedReport,
  stockedRef,
}) => {
  const tabOptions = [
    {
      value: 'understocked',
      label: 'Understocked ASINs',
      id: 'understocked_asins',
    },
    {
      value: 'overstocked',
      label: 'Overstocked ASINs',
      id: 'overstocked_asins',
    },
  ];

  const dashboardDynamicError = useSelector(
    (newState) => newState.userState?.showDynamicMsg,
  );

  const renderKeyContributionTabs = () => {
    const selectedTab = activeASINTab;

    return (
      <Tabs>
        <ul
          className={`tabs scrollable-container ${
            isLoader ? 'disabled' : null
          }`}
        >
          {tabOptions.map((item) => (
            <Fragment key={item.value}>
              <li
                key={item.value}
                className={`scrollable-tab ${
                  selectedTab === item.value ? 'active' : null
                }`}
                onClick={() => {
                  setActiveASINTab(item.value);
                  if (item.value === 'overstocked') {
                    setStockedOrderBy(false);
                    setStockedOrderByKey('sales_last_30_days');
                  } else {
                    setStockedOrderBy(true);
                    setStockedOrderByKey('poos_date');
                  }
                }}
                role="presentation"
              >
                {item.label}
                <InventoryManagementInfoIcon infoIconId={item?.id} />
              </li>
            </Fragment>
          ))}
        </ul>
      </Tabs>
    );
  };

  const renderTableHeader = () => {
    const tableHeader =
      activeASINTab === 'understocked'
        ? underStockTableheader
        : OverStockTableheader;
    return (
      <thead>
        <tr>
          {tableHeader.map((header) => (
            <th
              style={{
                wordBreak: 'break-word',
                paddingRight: '12px',
                cursor: header.sort ? 'pointer' : 'auto',
                top: 0,
              }}
              width={header.width}
              onClick={() => {
                if (
                  header.key !== 'asin' &&
                  header.key !== 'recommended_action' &&
                  header.key !== 'merchant_sku' &&
                  header.key !== 'product_name' &&
                  header.key !== 'action'
                ) {
                  handleSortFilters(header.key);
                }
              }}
              className="product-header"
              key={header.key}
            >
              {header.label}
              {header.sort ? (
                <FontAwesomeIcon
                  icon="far fa-chevron-down"
                  style={{
                    marginLeft: '4px',
                    fontSize: '12px',
                    cursor: 'pointer',
                    transform:
                      stockedOrderByKey === header.key && stockedOrderByFlag
                        ? 'rotate(180deg)'
                        : '',
                    color:
                      stockedOrderByKey === header.key
                        ? Theme.orange
                        : Theme.gray40,
                  }}
                />
              ) : null}
              {header.key === 'recommended_replenishment_quantity' ||
              header.key === 'recommended_ship_date' ? (
                <FontAwesomeIcon
                  color={Theme.gray40}
                  icon="far fa-question-circle"
                  style={{
                    marginLeft: '4px',
                    cursor: 'pointer',
                    fontSize: '14px',
                  }}
                  data-tip={stockedTooltip[header.key]}
                  data-for="restockedInfo"
                />
              ) : null}
              {header.key === 'recommended_replenishment_quantity' ||
              header.key === 'recommended_ship_date' ? (
                <ReactTooltip
                  class="initialTextTransform"
                  id="restockedInfo"
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                  html
                />
              ) : null}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const renderTableData = (itemData, numberFormat) => {
    return (
      <tr key={itemData?.asin} className="product-body">
        <td className="small-label-text ">
          <div className="marketplace">{itemData?.merchant_sku || 'N/A'}</div>
        </td>
        <td className="small-label-text ">{itemData?.asin || 'N/A'}</td>
        <td
          className="small-label-text"
          data-tip={itemData?.product_name}
          data-for="productname"
        >
          <ReactTooltip
            class="initialTextTransform"
            id="productname"
            aria-haspopup="true"
            place="bottom"
            effect="solid"
          />
          <div className="marketplace textLimit11">
            {itemData?.product_name || 'N/A'}
          </div>
        </td>
        <td className="product-table-body light-font  ">
          <div className="marketplace">
            {numberFormat(
              itemData?.sales_last_30_days,
              getSymbolFromCurrency(marketPlace?.currency),
            ) || 'N/A'}
          </div>
        </td>
        <td className="small-label-text ">
          <div className="marketplace">
            {itemData?.units_sold_last_30_days || 'N/A'}
          </div>
        </td>
        <td className="small-label-text ">
          <div className="marketplace">
            {itemData?.total_days_of_supply || 'N/A'}
          </div>
        </td>
        {activeASINTab === 'understocked' ? (
          <>
            <td className="product-table-body light-font">
              {itemData?.available_in_stock || 'N/A'}
            </td>
            <td className="product-table-body light-font">
              {itemData?.poos_date
                ? dayjs(itemData.poos_date).format('MM/DD/YYYY')
                : 'N/A'}
            </td>
            <td className="product-table-body light-font">
              <div className="marketplace">
                {itemData?.recommended_replenishment_quantity || 'N/A'}
              </div>
            </td>
            <td className="product-table-body light-font">
              <div className="marketplace">
                {itemData?.recommended_ship_date !== null
                  ? dayjs(itemData?.recommended_ship_date).format('MM/DD/YYYY')
                  : 'N/A'}
              </div>
            </td>
          </>
        ) : null}
        <td>
          <span className="action-item text-regular">
            <a
              href={`https://www.amazon.com/dp/${itemData?.asin}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Amazon
            </a>
          </span>
        </td>
      </tr>
    );
  };

  const renderMobileView = (numberFormat) => {
    if (activeASINTab === 'understocked') {
      return stockedData?.map((itemData, index) => (
        <UnderStockOverStockASINMobileView
          dataIndex={index}
          showBorder
          className="mb-3"
          label="ASIN"
          labelInfo={itemData?.asin}
          label1="SKU"
          labelInfo1={itemData?.merchant_sku || 'N/A'}
          labelProductName="Product Name"
          labelInfoProductName={itemData?.product_name || 'N/A'}
          label2="Sales for last 30 days"
          labelInfo2={
            numberFormat(
              itemData?.sales_last_30_days,
              getSymbolFromCurrency(marketPlace?.currency),
            ) || 'N/A'
          }
          label3="Units Sold
          (last 30 days)"
          labelInfo3={itemData?.units_sold_last_30_days || 'N/A'}
          label4="Total days of 
            Supply"
          labelInfo4={itemData?.total_days_of_supply || 'N/A'}
          label5="Available in Stock"
          labelInfo5={itemData?.available_in_stock || 'N/A'}
          label6="Potential OOS Date"
          labelInfo6={
            itemData?.poos_date
              ? dayjs(itemData.poos_date).format('MM/DD/YYYY')
              : 'N/A'
          }
          label7="Rec. Replenishment 
            Quantity"
          labelInfo7={itemData?.recommended_replenishment_quantity || 'N/A'}
          label8="Recommended 
            Ship Date"
          labelInfo8={
            itemData?.recommended_ship_date !== null
              ? dayjs(itemData?.recommended_ship_date).format('MM/DD/YYYY')
              : 'N/A'
          }
          label9="Actions"
          labelInfo9={itemData?.asin}
        />
      ));
    }
    return stockedData.map((itemData) => (
      <UnderStockOverStockASINMobileView
        showBorder
        className="mb-3"
        label="ASIN"
        labelInfo={itemData?.asin}
        label1="SKU"
        labelInfo1={itemData?.merchant_sku || 'N/A'}
        labelProductName="Product Name"
        labelInfoProductName={itemData?.product_name || 'N/A'}
        label2="Sales for last 30 days"
        labelInfo2={numberFormat(
          itemData?.sales_last_30_days,
          getSymbolFromCurrency(marketPlace?.currency),
        )}
        label3="Total days of 
            Supply"
        labelInfo3={itemData?.total_days_of_supply || 'N/A'}
        label4="Units Sold
          (last 30 days)"
        labelInfo4={itemData?.units_sold_last_30_days || 'N/A'}
        label9="Actions"
        labelInfo9={itemData?.asin}
        s
      />
    ));
  };

  return (
    <>
      <WhiteCard ref={stockedRef} className="mt-3 mb-3 ">
        <Wrapper>
          <div className="row">
            <div className="col-12  ">
              <div className="underOverstockedDownloadBtn">
                {renderKeyContributionTabs()}

                {reportLoader ? (
                  <PageLoader
                    component="stock-download-report"
                    type="button"
                    color={Theme.orange}
                  />
                ) : (
                  <div
                    className="cursor"
                    onClick={onDownloadStockedReport}
                    onKeyPress={() => {}}
                    tabIndex={0}
                    role="button"
                  >
                    <p className="black-heading-title text-medium-font m-0 cursor">
                      <FontAwesomeIcon
                        className="mr-2"
                        color={Theme.orange}
                        icon="far fa-arrow-to-bottom"
                      />
                      Download Report
                    </p>
                  </div>
                )}
              </div>
            </div>

            {dashboardDynamicError?.inventory?.under_over_stock ? (
              <div className="col-12">
                <AllocateBar className="mb-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="remaing-label">
                        <span className="orange-dot" />
                        {dashboardDynamicError?.inventory?.under_over_stock}
                      </div>
                    </div>
                  </div>
                </AllocateBar>
              </div>
            ) : null}
          </div>

          {isDesktop ? (
            <IventoryTableWrapper style={{ overflow: 'auto' }} className="mt-3">
              <div className="horizontal-scroll-visible">
                <Table
                  className="mt-0 scrollable-table "
                  style={{ tableLayout: 'fixed' }}
                >
                  {renderTableHeader()}
                  {stockedData.length >= 1 ? (
                    <>
                      <tbody>
                        {stockedData &&
                          stockedData.map((item) =>
                            renderTableData(item, useFormatNumber),
                          )}
                      </tbody>
                    </>
                  ) : null}
                </Table>
              </div>
            </IventoryTableWrapper>
          ) : stockedData.length >= 1 ? (
            renderMobileView(useFormatNumber)
          ) : null}

          {isLoader ? (
            <PageLoader
              component="performance-graph"
              color="#FF5933"
              type="detail"
              width={40}
              height={40}
            />
          ) : stockedData.length === 0 ? (
            <NoData>{noDataMessage}</NoData>
          ) : null}
        </Wrapper>

        {count > 10 ? (
          <>
            <div className="straight-line horizontal-line mt-3" />
            <CommonPagination
              count={count}
              pageNumber={pageNumber}
              handlePageChange={handlePageChange}
            />
          </>
        ) : null}
      </WhiteCard>
    </>
  );
};

export default UnderStockOverStockASIN;

UnderStockOverStockASIN.defaultProps = {
  isDesktop: false,
  isLoader: false,
  stockedOrderByFlag: false,
  count: 1,
  pageNumber: 1,
  stockedOrderByKey: '',
  activeASINTab: 'underStocked',
  noDataMessage: 'No Data Found.',
  handlePageChange: () => {},
  handleSortFilters: () => {},
  setActiveASINTab: () => {},
  setStockedOrderByKey: () => {},
  setStockedOrderBy: () => {},
  onDownloadStockedReport: () => {},
};

UnderStockOverStockASIN.propTypes = {
  isDesktop: bool,
  isLoader: bool,
  stockedOrderByFlag: bool,
  reportLoader: bool.isRequired,
  count: number,
  pageNumber: number,
  stockedOrderByKey: string,
  activeASINTab: string,
  noDataMessage: string,
  stockedData: arrayOf(shape({})).isRequired,
  stockedRef: shape({}).isRequired,
  handlePageChange: func,
  handleSortFilters: func,
  setActiveASINTab: func,
  marketPlace: shape({}).isRequired,
  setStockedOrderByKey: func,
  setStockedOrderBy: func,
  onDownloadStockedReport: func,
};
const Wrapper = styled.div`
  td {
    padding: 20px 10px 3px 0px !important;
  }
  tr {
    th {
      .caretUpDown {
        vertical-align: middle;
        position: absolute;
        margin-left: 2px;
        margin-top: -2px;
      }
    }
  }

  .tabs.scrollable-container li:first-child {
    margin-left: 0px;
  }

  .scrollable-table {
    table-layout: fixed;
  }

  .underOverstockedDownloadBtn {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${Theme.gray11};

    .scrollable-container {
      border-bottom: 0;
    }
    @media only screen and (max-width: 767px) {
      text-align: left;
      flex-direction: column;
      border-bottom: none;

      .scrollable-container {
        border-bottom: 1px solid ${Theme.gray11};
      }

      .black-heading-title {
        padding: 15px 0;
      }
    }
  }
`;
