import React, { useCallback, useEffect, useRef, useState } from 'react';

import { arrayOf, bool, func, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { getReportsSectionData } from '../../../api';

import ReportSectionList from './ReportSectionList';

const StandardReportContainer = ({
  selectedBp,
  selectedTimeFrame,
  generateCustomDate,
  isCustomDateApply,
  selectedMarketplaceOption,
  customDateState,
  selectedReportType,
  searchQuery,
}) => {
  const mounted = useRef(false);

  const userInfo = useSelector((state) => state.userState.userInfo);
  const customer = useSelector((state) => state.customerState.data);
  const customerId = customer?.id;
  const isCustomer = userInfo?.role?.includes('Customer');

  const [reportsData, setReportsData] = useState([]);
  const [reportsLoader, setReportsLoader] = useState(false);

  const [reportCount, setReportCount] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const getReportsData = useCallback(
    (
      reportType,
      searchString,
      page,
      id,
      dailyFact,
      marketplace,
      startDate,
      endDate,
    ) => {
      setReportsLoader(true);
      getReportsSectionData(
        reportType,
        searchString,
        page,
        id,
        dailyFact,
        marketplace,
        startDate,
        endDate,
      ).then((res) => {
        if (mounted.current) {
          if (res?.status === 200) {
            if (res?.data?.results) {
              setReportsData(res?.data?.results);
              setReportCount(res?.data?.count);
            } else {
              setReportsData([]);
            }
          } else {
            setReportsData([]);
            setReportCount(0);
          }

          setReportsLoader(false);
        }
      });
    },
    [],
  );

  useEffect(() => {
    mounted.current = true;

    const userId = isCustomer ? customerId : selectedBp;
    if (selectedMarketplaceOption !== '') {
      if (isCustomDateApply && selectedTimeFrame?.value === 'custom') {
        let startDate = null;
        let endDate = null;
        startDate = generateCustomDate(customDateState[0]?.startDate);
        endDate = generateCustomDate(customDateState[0]?.endDate);
        getReportsData(
          selectedReportType?.value,
          searchQuery,
          1,
          userId,
          selectedTimeFrame?.value,
          selectedMarketplaceOption,
          startDate,
          endDate,
        );
      }
      if (!isCustomDateApply && selectedTimeFrame?.value !== 'custom') {
        getReportsData(
          selectedReportType?.value,
          searchQuery,
          1,
          userId,
          selectedTimeFrame?.value,
          selectedMarketplaceOption,
        );
      }
    }
    return () => {
      mounted.current = false;
    };
  }, [
    customDateState,
    customerId,
    generateCustomDate,
    getReportsData,
    isCustomDateApply,
    isCustomer,
    searchQuery,
    selectedBp,
    selectedMarketplaceOption,
    selectedReportType,
    selectedTimeFrame,
  ]);

  const handlePageChange = (currentPage) => {
    if (isCustomDateApply && selectedTimeFrame.value === 'custom') {
      let startDate = null;
      let endDate = null;
      startDate = generateCustomDate(customDateState[0].startDate);
      endDate = generateCustomDate(customDateState[0].endDate);
      getReportsData(
        selectedReportType?.value,
        searchQuery,
        currentPage,
        isCustomer ? customerId : selectedBp,
        selectedTimeFrame.value,
        selectedMarketplaceOption,
        startDate,
        endDate,
      );
    }
    if (!isCustomDateApply && selectedTimeFrame.value !== 'custom') {
      getReportsData(
        selectedReportType?.value,
        searchQuery,
        currentPage,
        isCustomer ? customerId : selectedBp,
        selectedTimeFrame.value,
        selectedMarketplaceOption,
      );
    }
    setPageNumber(currentPage);
  };

  return (
    <>
      <ReportSectionList
        loader={reportsLoader}
        selectedReportTypeValue={selectedReportType?.value}
        reportsData={reportsData}
        selectedBp={selectedBp}
        selectedTimeFrame={selectedTimeFrame}
        generateCustomDate={generateCustomDate}
        count={reportCount}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
        isCustomer={isCustomer}
        customerId={customerId}
        selectedMarketplaceOption={selectedMarketplaceOption}
        customDateState={customDateState}
      />
    </>
  );
};

export default StandardReportContainer;

StandardReportContainer.defaultProps = {
  generateCustomDate: () => {},
  isCustomDateApply: false,
  searchQuery: '',
};

StandardReportContainer.propTypes = {
  isCustomDateApply: bool,
  searchQuery: string,
  selectedBp: string.isRequired,
  generateCustomDate: func,
  selectedTimeFrame: shape({}).isRequired,
  selectedReportType: shape({}).isRequired,
  selectedMarketplaceOption: string.isRequired,
  customDateState: arrayOf(shape({})).isRequired,
};
