import styled from 'styled-components';
import Select from 'react-select';
import Theme from '../../../../theme/Theme';

export const PlaybookStyle = styled.div`
  .add-task-assignee {
    background-color: #ffffff;
    border: 1px solid #e0e7ff;
    border-radius: 100%;
    width: 24px;
    height: 24px;

    img {
      width: 10px;
      margin: 6px 0 0 6px;
    }
  }
  .seller-vendor-btn {
    color: ${Theme.gray90};
    text-align: left;
    border-radius: 6px;
    border: 1px solid ${Theme.gray45};
    background-color: #fafafb !important;
    min-height: 32px;
    padding: 6px 0 0 8px;
  }
`;

export const TaskStatus = styled.div`
  position: relative;
  width: 90%;
  max-width: 160px;
  display: flex;
  justify-content: space-between;

  &.progress-label {
    color: ${Theme.black};
    font-size: ${Theme.verySmall};
    font-family: ${Theme.titleFontFamily};
    text-transform: uppercase;
    // width: fit-content;
    padding: 6px 5px 6px 8px;
    border-radius: 5px;

    &.gray-label {
      color: ${Theme.black};
      background: #f4f6fc;
    }
    &.orange-label {
      color: ${Theme.orange};
      background: ${Theme.lighterOrange};
    }
    &.green-label {
      color: ${Theme.lighterGreen};
      background: #e1f2ce;
    }
    &.blue-label {
      color: #0045b4;
      background: #0045b445;
    }
    &.yellow-label {
      color: ${Theme.yellow};
      background: ${Theme.extraLightYellow};
    }
    &.voilet-label {
      color: #8d6fca;
      background: #d1c5e9;
    }
    &.red-label {
      color: ${Theme.red};
      background: ${Theme.lightRed};
    }

    .progress-icon {
      vertical-align: text-bottom;
      margin-right: 2px;
      width: 15px;
    }
    .collapse-arrow-icon {
      width: 18px;
      vertical-align: middle;
      margin: -2px 0 0 5px;
    }
  }
  .change-status-dropdown {
    width: 160px;
    box-shadow: 0 0 10px 5px rgb(248 248 250);
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 9;
    background: #ffffff;
    right: 0px;
    top: 30px;

    &.show {
      display: block;
    }
    &.hide {
      display: none;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        text-align: left;
        margin: 15px 5px;

        &.current-status {
          cursor: not-allowed !important;
          pointer-events: none !important;
        }
        .progress-label {
          padding: 5px 8px;
          border-radius: 5px;
          display: inline-block;
          width: 100px;

          &.gray-label {
            color: ${Theme.black};
            background: #f4f6fc;
          }
          &.orange-label {
            color: ${Theme.orange};
            background: ${Theme.lighterOrange};
          }
          &.green-label {
            color: ${Theme.lighterGreen};
            background: #e1f2ce;
          }
          &.blue-label {
            color: #0045b4;
            background: #0045b445;
          }
        }
        .progress-icon {
          float: right;
          width: 15px;
          margin-top: 4px;
        }
      }
    }
  }
`;
export const PlaybookNavbar = styled.div`
  .playbook-nav {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: inline-block;
      max-width: 150px;
      width: 100%;
      margin-left: 8px;
      position: relative;
      text-align: left;
      &:first-child {
        margin-left: 0;
      }

      &.refresh-btn {
        width: unset;
      }
      &.mobile-view {
        display: none;
      }
      &.dateFilterWidth {
        max-width: 200px;
      }
      &.playbookMultiselectFilter {
        vertical-align: middle;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .playbook-nav {
      margin-top: 15px;
      li {
        max-width: 43%;
        margin-bottom: 15px;

        &:last-child {
          margin-right: 0;
        }
        &.filter-by-service {
          max-width: 88%;
        }
        &.desktop-view {
          display: none;
        }
        &.mobile-view {
          display: inline-block;
          max-width: 10%;
          margin-right: 0;
        }
      }
    }
  }
`;

export const TableWrapper = styled.div`
  tbody {
    tr {
      td {
        &.product-body {
          padding: 8px 15px 4px 0;
        }

        a {
          color: #000;
        }
      }
    }
  }
`;

export const PlaybookMobileView = styled.div`
  .playbook-card {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: inline-block;
      margin-right: 20px;
      vertical-align: middle;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .playbook-collapse-grid {
    display: flex;
    margin-top: 10px;
    position: relative;

    a.normal-text-black {
      color: ${Theme.black};
    }
  }
  .parent-collapse {
    border-left: 1px solid ${Theme.gray11};
    height: 98%;
    position: absolute;
    left: 5px;
    top: 19px;
    z-index: 1;

    &.nested-collapse {
      height: 86%;
    }
  }
  .collapse-icon {
    width: 16px;
    background: rgb(244, 246, 252);
    margin-right: 10px;
    display: block;
    text-align: center;
    vertical-align: middle;
  }
`;

export const RefreshButton = styled.div`
  .refresh-icon {
    background: ${Theme.gray8};
    border-radius: 6px;
    width: 32px;
    text-align: center;
    height: 32px;
    padding: 5px 7px;
    border: 1px solid #bfc5d2;
    color: ${Theme.black};
    border: 1px solid ${Theme.gray25};
    .refresh-arrow-icon {
      vertical-align: text-top;
      margin-right: 4px;
    }

    @media only screen and (max-width: 767px) {
      width: auto;
    }
  }
`;

export const ContractServicesList = styled.div`
  .services-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      padding: 12px 0;
      border-bottom: 1px solid ${Theme.gray11};

      &:last-child {
        border-bottom: 0px;
      }
    }
  }
`;
export const LeftLine = styled.div`
  position: absolute;
  top: 34px;
  left: 15px;
  height: 100%;
  border: 1px solid #f6fafb;
`;

export const InnerCollapseRow = styled.tr`
  position: relative;

  
    td {
      position: relative;
      padding: 14px 10px 14px 16px;

      &:first-child::before {
        top: 0;
        width: 41px;
        height: 15px;
        border-bottom-right-radius: 12px;
        position: absolute;
        border: 1px solid #e0e6e8;
        border: solid ${Theme.white} border-width: 0 2px 2px 0;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(-271deg);
        content: ""; 
        opacity:0;
      }
    }
    &.first-layer-collapse {
      td {
        border: 0;

        &:first-child {
            padding-left: 42px;

            &::before {
              left: 3px;
              opacity:1;
            }
        }
      }
    }

    &.checkbox-column-table {
      &:after {
        display: none;
      }
      td {
        border: 0;

        &:nth-child(2) {
            padding-left: 42px;

            &::before {
              width: 41px;
              height: 15px;
              border-bottom-right-radius: 12px;
              position: absolute;
              border: 1px solid #e0e6e8;
              border: solid ${Theme.white} border-width: 0 2px 2px 0;
              border-width: 0 1px 1px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(-271deg);
              content: ""; 
              opacity:1;
              left: -8px;
              top: -10px;
          }
        }
      }
    }
    &.second-layer-collapse {
      td {
        border: 0;
        &:first-child {
            padding-left: 69px;

            &::before {
              left: 30px;
              opacity:1;

            }
        }
      }
    }

    &.third-layer-collapse {
      td {
        border: 0;
        &:first-child {
            padding-left: 89px;

            &::before {
              left: 55px;
              opacity:1;
            }
        }
      }
}`;
export const InnerCollapse = styled.div`
  .parent-nested-collapse {
    position: relative;
    width: 100%;
    border-spacing: 0;

    .parent-collapse {
      border-left: 1px solid #e0e6e8;
      // height: 99%;
      position: absolute;
      left: 17px;
      top: -22px;
      z-index: 1;
    }

    tbody {
      tr {
        td {
          position: relative;
          padding: 12px 10px 12px 16px;
          border-bottom: none;

          &:first-child {
            padding-left: 42px;
          }

          span.collapse-icon {
            width: 16px;
            height: 16px;
            background: rgb(244, 246, 252);
            margin-right: 10px;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
          }
          span.curve-line {
            width: 41px;
            height: 15px;
            border-bottom-right-radius: 4px;
            position: absolute;
            border: 1px solid #e0e6e8;
            border: solid ${Theme.white};
            border-width: 0 2px 2px 0;
            border-width: 0 1px 1px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(-271deg);
            content: '';
          }
          span.curve-line-parent {
            left: 4px;
            top: -2px;
          }
          span.curve-line-child {
            left: -5px;
            top: 1px;
          }
        }
      }
    }
  }

  .nested-collapse {
    position: relative;
    width: 100%;
    border-spacing: 0;
    tbody {
      tr {
        td {
          position: relative;
          padding: 12px 0px 12px 12px;
          border-bottom: none;

          &:first-child {
            padding-left: 42px;
          }
        }
      }
    }

    span.curve-line-parent {
      left: 4px;
      top: -2px;
    }
    span.curve-line-child {
      left: -5px;
      top: 1px;
    }
  }
`;

export const BorderLessDropDown = styled.div`
  .css-2b097c-container {
    max-width: 110px;
    width: 100%;
    min-height: 28px;
    .css-yk16xz-control {
      border: none;
    }
    .css-uz51yo-control:hover {
      border: none;
      background: ${Theme.gray8} !important;
      border-radius: 4px;
      border-color: none;
    }
    .css-uz51yo-control {
      background: ${Theme.gray8} !important;
      box-shadow: none;
      outline: none;
      border-color: none !important;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
`;

export const PlaybookSummaryMobileView = styled.div`
  .collapse-icon {
    font-size: ${Theme.textSmall};
    background: ${Theme.gray8};
    border-radius: 2px;
    padding: 4px;
    margin-right: 10px;
  }
  .left-line {
    margin-top: 7px;
    margin-left: 39px;
    height: 23px;
    width: 16px;
    border-left: 1px solid #ccc;
  }

  .sub-task-outer {
    position: relative;
    margin-left:  55px;
  }
  .straight-line {
    margin: 12px 0;
  }

  .btn-primary {
    width: 100px;
  }

  span.curve-line{
    width: 25px;
    height: 14px;
    border-bottom-right-radius: 4px;
    position: absolute;
    border: 1px solid #e0e6e8;
    border: solid ${Theme.white} border-width: 0 2px 2px 0;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(-271deg);
    content: ""; 
  }

  span.curve-line-child {
    left: -27px;
    top: -9px;
    }
`;

export const PlaybookSummaryLink = styled.div`
  font-size: ${Theme.extraMedium};
  color: ${Theme.orange};
  font-family: ${Theme.titleFontFamily};
  cursor: pointer;
  width: fit-content;

  .chevron-icon {
    margin-left: 4px;
    vertical-align: middle;
    font-size: ${Theme.extraSmall};
  }
`;

export const TableSummaryWrapper = styled.div`
  tr {
    td {
      .left-line {
        height: 35px;
        width: 25px;
        margin-left: 16px;
      }
    }
  }
`;

export const SummaryMobileSelect = styled(Select)`
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 0;
  }
  .css-yk16xz-control,
  .css-uz51yo-control {
    min-height: unset;
  }

  .css-g1d714-ValueContainer {
    padding: 0;
  }
  &.css-2b097c-container {
    min-height: unset !important;
    max-width: 80px !important;
  }
`;
