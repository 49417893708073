import React from 'react';

import { bool, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { components } from 'react-select';

const DropDownIndicator = ({ selectProps, indicatorColor, ...props }) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-down"
          style={{
            fontSize: selectProps?.fontSize ? selectProps?.fontSize : '16px',
            color: indicatorColor || '#000',
            transform: selectProps.menuIsOpen ? 'rotate(180deg)' : '',
          }}
        />
      </components.DropdownIndicator>
    )
  );
};

export default DropDownIndicator;

DropDownIndicator.defaultProps = {
  indicatorColor: '#000',
};

DropDownIndicator.propTypes = {
  selectProps: shape({
    menuIsOpen: bool,
  }).isRequired,
  indicatorColor: string,
};
