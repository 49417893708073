/* ********** Third party package imports ********** */
import React from 'react';

/* ********** Third party package imports ********** */
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { arrayOf, bool, func, string, shape } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* ********** Local component file imports ********** */
import Theme from '../../../../../theme/Theme';
import { Icon, TabletViewManager } from '../../../../../theme/Global';
import { CompanyDefaultUser } from '../../../../../theme/images';
import {
  Tabs,
  WhiteCard,
  PageLoader,
  NoData,
  DesktopTable,
  GrayMessageBar,
} from '../../../../../common';
import {
  noGraphDataMessage,
  PATH_CUSTOMER_DETAILS,
  metricsCurrency,
  salesContributionTabOptions,
  salesGoalsTableHeader,
  SALEPERFORMANCE,
  CUSTOM,
  HYBRID,
  SELLER,
  SELLERSMALL,
  VENDORSMALL,
  OVERALLSALESGOALS,
  PPCADBUDGET,
  DSPADBUDGET,
  TACOS,
  REVSHARE,
  TRAFFIC,
  CONVERSION,
} from '../../../../../constants';

/* ********** MAIN FUNCTION START HERE ********** */
const SalesSellerContribution = ({
  // string
  currencySymbol,
  selectedTabMetrics,
  // boolean
  isDesktop,
  isApiCall,
  // object
  selectedSalesDF,
  selectedMarketplace,
  // array
  contributionData,
  goalsPerformanceData,
  // function
  displaySalesPagination,
  handleOnMetricsTabChange,
  displayContributionTable,
  dispalyContributionSectionHeader,
}) => {
  const history = useHistory();

  const isBBEInternalUser = useSelector(
    (state) => state?.userState?.userInfo?.isInternalUser,
  );
  const isBBEInternalUserStatus = isBBEInternalUser ? 'internal' : 'external';

  // display loader during api calls
  const displayLoader = () => {
    return (
      <PageLoader
        component="performance-graph"
        color="#FF5933"
        type="detail"
        width={40}
        height={40}
      />
    );
  };

  // display react tooltip
  const displayReactTooltip = (id, place) => {
    return (
      <ReactTooltip
        className="asian-info-icon"
        id={id}
        aria-haspopup="true"
        place={place}
        effect="solid"
        style={{ maxWidth: '300px' }}
        html
      />
    );
  };

  // display info icon
  const displayInfoIcon = (tooltipId, tooltipInfo) => {
    return (
      <Icon>
        <FontAwesomeIcon
          data-for={tooltipId}
          data-tip={tooltipInfo}
          data-html
          icon="fal fa-circle-info"
        />
        {displayReactTooltip(tooltipId, 'top')}
      </Icon>
    );
  };

  // redirect to customer details view
  const redirectToCutomerDetailsView = (itemData, key) => {
    history.push(
      `${PATH_CUSTOMER_DETAILS.replace(
        ':id',
        key === 'goal' ? itemData?.customer_id : itemData?.id,
      )}?tab=performance${
        itemData?.customer_account_type
          ? `&sub=${
              itemData.customer_account_type === HYBRID ||
              itemData.customer_account_type === SELLER
                ? SELLERSMALL
                : VENDORSMALL
            }`
          : ''
      }&nav=salePerformance&access=${isBBEInternalUserStatus}`,
      SALEPERFORMANCE,
    );
  };

  // display company logo
  const displayCompanyLogo = (imageLink) => {
    return (
      <img
        className="company-logo"
        src={imageLink || CompanyDefaultUser}
        alt="logo"
      />
    );
  };

  // display BP name, logo, status
  const displayCustomerProfile = (itemData) => {
    return (
      <div>
        {displayCompanyLogo(itemData?.document || null)}
        <div className="company-name">{itemData?.customer_name}</div>
        <div className="status">{itemData?.customer_status}</div>
        <div className="clear-fix" />
      </div>
    );
  };

  const displayFormattedValues = (value, sign) => {
    const decimalDigits = 2;
    if (value) {
      return `${sign === '$' ? '$' : ''}${value
        .toFixed(decimalDigits)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${sign === '%' ? '%' : ''}`;
    }
    return `${sign === '$' ? '$' : ''}0${sign === '%' ? '%' : ''}`;
  };

  const isRevShareDataAvailable = () => {
    const currentDate = new Date().getUTCDate();
    if (currentDate >= 1 && currentDate <= 8) {
      return false;
    }
    return true;
  };

  // display metrics goals value with currency
  const displayGoalsCellValue = (goalsValue, matricValue, type) => {
    let diffPercentage = null;
    let label = '';
    let labelClassColor = 'grey';
    let diffArrowClass = '';
    let diffPercentageClassColor = '';

    if ((goalsValue === '' || goalsValue === 0) && type !== DSPADBUDGET) {
      label = 'No Forecast';
      labelClassColor = 'grey';
    }
    if (type === DSPADBUDGET) {
      if (goalsValue === 0) {
        label = 'N/A';
        labelClassColor = 'grey';
      }
      if (goalsValue === '') {
        label = 'No Forecast';
        labelClassColor = 'grey';
      }
    }

    if (goalsValue && matricValue) {
      const diff = (matricValue * 100) / goalsValue;
      diffPercentage = diff - 100;

      if (diffPercentage === 0) {
        labelClassColor = 'green';
        if (type === PPCADBUDGET || type === DSPADBUDGET) {
          label = 'On Target';
        } else {
          label = 'On Target';
        }
      } else if (diffPercentage > 0) {
        labelClassColor = 'green';
        diffArrowClass = 'fa-arrow-up';
        diffPercentageClassColor = 'green';
        if (type === PPCADBUDGET || type === DSPADBUDGET) {
          label = 'Over Spend';
          labelClassColor = 'red';
          diffPercentageClassColor = 'red';
        } else {
          label = 'On Target';
        }
      } else if (diffPercentage < 0) {
        diffArrowClass = 'fa-arrow-down';

        if (type === PPCADBUDGET || type === DSPADBUDGET) {
          label = 'Under Spend';
          labelClassColor = 'yellow';
          diffPercentageClassColor = 'yellow';
        } else {
          label = 'Under Target';
          labelClassColor = 'red';
          diffPercentageClassColor = 'red';
        }
      }
    }

    return (
      <GoalsPerformance>
        <GoalBubbles className={labelClassColor}>
          <span className="text-regular">{label}</span>{' '}
          <span className="text-bold">
            {goalsValue === 0 || goalsValue === ''
              ? ''
              : displayFormattedValues(goalsValue, type === TACOS ? '%' : '$')}
          </span>
        </GoalBubbles>
        <GoalCount>
          <span className="text-medium-font ">
            {(type === REVSHARE && !isRevShareDataAvailable()) ||
            (goalsValue === 0 && type === DSPADBUDGET)
              ? 'N/A'
              : matricValue === 0
              ? 'No Forecast'
              : displayFormattedValues(
                  matricValue,
                  type === TACOS ? '%' : '$',
                )}{' '}
          </span>
          <PercentValue className={`${diffPercentageClassColor} ml-1`}>
            <FontAwesomeIconStyle icon={`fa-light ${diffArrowClass} `} />
            <span className="ml-1">
              {diffPercentage === null
                ? 'N/A'
                : displayFormattedValues(diffPercentage, '%')}
            </span>
          </PercentValue>
        </GoalCount>
      </GoalsPerformance>
    );
  };

  // display metrics no goals data massage
  const displayNoDataLabel = () => {
    return (
      <GoalsPerformance>
        <GoalBubbles className="grey">
          <span className="text-regular ">No Forecast</span>{' '}
        </GoalBubbles>
        <GoalCount>
          <div />
        </GoalCount>
      </GoalsPerformance>
    );
  };

  // display metrics and goals value
  const displayMetricsAndGoalsValue = (itemData, metricsKey) => {
    const metricsValue = itemData?.[metricsKey]?.metric_value;
    const goalsValue = itemData?.[metricsKey]?.goal_value;

    return !goalsValue && !metricsValue
      ? displayNoDataLabel()
      : displayGoalsCellValue(goalsValue, metricsValue, metricsKey);
  };

  // display sales metrics goals data
  const renderGoalsTableData = (itemData) => {
    return (
      <tr
        key={itemData?.customer_id}
        className="cursor"
        onClick={() => redirectToCutomerDetailsView(itemData, 'goal')}
      >
        <td className="BPLogo goals-no-data">
          {displayCustomerProfile(itemData)}
        </td>
        <td className="goals-no-data">
          {displayMetricsAndGoalsValue(itemData, OVERALLSALESGOALS)}
        </td>
        <td className="goals-no-data">
          {displayMetricsAndGoalsValue(itemData, PPCADBUDGET)}
        </td>
        <td className="goals-no-data">
          {displayMetricsAndGoalsValue(itemData, DSPADBUDGET)}
        </td>
        <td className="goals-no-data">
          {displayMetricsAndGoalsValue(itemData, TACOS)}
        </td>
        <td className="goals-no-data">
          {displayMetricsAndGoalsValue(itemData, REVSHARE)}
        </td>
        <td className="goals-no-data">
          {displayMetricsAndGoalsValue(itemData, TRAFFIC)}
        </td>
        <td className="goals-no-data">
          {displayMetricsAndGoalsValue(itemData, CONVERSION)}
        </td>
      </tr>
    );
  };

  // display goals desktop table
  const displayGoalsDesktopTable = () => {
    return (
      <div style={{ overflowX: 'auto' }}>
        <DesktopTable className="mt-0" style={{ minWidth: '1440px' }}>
          <thead>
            <tr>
              {salesGoalsTableHeader?.map((item) => {
                return (
                  <th width={item?.width} key={item?.key}>
                    {item?.label}
                    {item?.showTooltip
                      ? displayInfoIcon(item?.tooltipId, item?.tooltipInfo)
                      : null}
                  </th>
                );
              })}
            </tr>
          </thead>
          {goalsPerformanceData?.length >= 1 ? (
            <>
              <tbody>
                {goalsPerformanceData?.map((item) =>
                  renderGoalsTableData(item),
                )}
              </tbody>
            </>
          ) : null}
        </DesktopTable>
      </div>
    );
  };

  // display metrics label with goals value
  const displayMetricsLabelWithGoalsValue = (
    itemData,
    metricsKey,
    metricsLabel,
  ) => {
    const tooltipObject = salesGoalsTableHeader?.find(
      (item) =>
        item?.key === metricsKey &&
        item?.key !== 'customer_name' &&
        item?.showTooltip,
    );

    return (
      <div className="col-6 pb-3">
        <div className="label">
          {metricsLabel}
          {tooltipObject
            ? displayInfoIcon(
                tooltipObject?.tooltipId,
                tooltipObject?.tooltipInfo,
              )
            : null}
        </div>
        <div className="label-info">
          {displayMetricsAndGoalsValue(itemData, metricsKey)}
        </div>
      </div>
    );
  };

  // display goals mobile and tablet data
  const displayTabletGoalsView = () => {
    return (
      <TabletViewManager className="d-lg-none d-md-block d-sm-block">
        <div className="container-fluid">
          <div className="row cursor">
            {goalsPerformanceData?.map((itemData) => (
              <div
                className="col-md-6 col-12 mt-4"
                role="presentation"
                key={itemData?.customer_id}
                onClick={() => redirectToCutomerDetailsView(itemData, 'goal')}
              >
                {displayCustomerProfile(itemData)}
                <div className="straight-line horizontal-line pt-3 mb-3 " />
                <div className="row">
                  {displayMetricsLabelWithGoalsValue(
                    itemData,
                    OVERALLSALESGOALS,
                    'Sales',
                  )}

                  {displayMetricsLabelWithGoalsValue(
                    itemData,
                    DSPADBUDGET,
                    'DSP AD SPEND',
                  )}

                  {displayMetricsLabelWithGoalsValue(
                    itemData,
                    PPCADBUDGET,
                    'PPC AD SPEND',
                  )}

                  {displayMetricsLabelWithGoalsValue(itemData, TACOS, 'TACOS')}

                  {displayMetricsLabelWithGoalsValue(
                    itemData,
                    REVSHARE,
                    'REV SHARE',
                  )}

                  {displayMetricsLabelWithGoalsValue(
                    itemData,
                    TRAFFIC,
                    'TRAFFIC',
                  )}

                  {displayMetricsLabelWithGoalsValue(
                    itemData,
                    CONVERSION,
                    'CONVERSION',
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </TabletViewManager>
    );
  };

  // display goals table
  const displayGoalsTable = () => {
    const message =
      'Performance to Forecast Dashboard only displays information on a monthly basis, for this reason comparison filter won’t impact this dashboard. Please select a specific month to visualize the data.';

    return isApiCall ? (
      displayLoader()
    ) : (
      <div>
        <GrayMessageBar message={message} className="my-3" />
        {goalsPerformanceData?.length ? (
          isDesktop ? (
            displayGoalsDesktopTable()
          ) : (
            displayTabletGoalsView()
          )
        ) : (
          <NoData>{noGraphDataMessage}</NoData>
        )}
      </div>
    );
  };

  // display contribution tabs
  const renderKeyContributionTabs = () => {
    const selectedTab = selectedTabMetrics;

    return (
      <Tabs>
        <ul className={isApiCall ? 'tabs disabled' : 'tabs'}>
          {salesContributionTabOptions?.map((item) => (
            <li
              id={`BT-${item?.value}-tab`}
              key={item?.value}
              className={`${selectedTab === item?.value ? 'active ' : ''} ${
                selectedMarketplace?.value !== 'all' && item?.value === 'goals'
                  ? 'disabled'
                  : ''
              }`}
              onClick={() => handleOnMetricsTabChange(item?.value)}
              role="presentation"
            >
              {item?.label}
            </li>
          ))}
        </ul>
      </Tabs>
    );
  };

  // display seller contribution table
  const dispalySellerContributionTable = () => {
    let prefix = '';
    let suffix = '';
    let decimalDigits = 2;
    if (metricsCurrency[selectedTabMetrics]?.type === 'currency') {
      decimalDigits = 2;
      prefix = currencySymbol;
      suffix = '';
    }
    if (metricsCurrency[selectedTabMetrics]?.type === 'percentage') {
      decimalDigits = 2;
      prefix = '';
      suffix = '%';
    }
    if (metricsCurrency[selectedTabMetrics]?.type === 'nocurrency') {
      decimalDigits = 0;
      prefix = '';
      suffix = '';
    }

    return isApiCall ? (
      displayLoader()
    ) : (
      <div>
        {contributionData?.length ? (
          displayContributionTable(
            contributionData,
            prefix,
            suffix,
            decimalDigits,
            false,
            SALEPERFORMANCE,
          )
        ) : (
          <NoData>{noGraphDataMessage}</NoData>
        )}
      </div>
    );
  };

  const renderKeyContributors = () => {
    return (
      <WhiteCard className="mb-3">
        {dispalyContributionSectionHeader()}
        {selectedSalesDF?.value === CUSTOM ? (
          <NoData>
            Top contributors cannot be calculated when using custom dates.
          </NoData>
        ) : (
          <>
            {renderKeyContributionTabs()}
            {selectedTabMetrics === 'goals'
              ? displayGoalsTable()
              : dispalySellerContributionTable()}
            {displaySalesPagination()}
          </>
        )}
      </WhiteCard>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <Wrapper id="BT-sales-seller-contribution">
      {renderKeyContributors()}
    </Wrapper>
  );
};

export default SalesSellerContribution;
/* ********** MAIN FUNCTION END HERE ********** */

/* ********** Declaring prop types ********** */
SalesSellerContribution.defaultProps = {
  isDesktop: false,
  isApiCall: false,

  currencySymbol: '',
  selectedTabMetrics: '',

  selectedSalesDF: {},
  selectedMarketplace: {},

  contributionData: [],
  goalsPerformanceData: [],

  displaySalesPagination: () => {},
  handleOnMetricsTabChange: () => {},
  displayContributionTable: () => {},
  dispalyContributionSectionHeader: () => {},
};

SalesSellerContribution.propTypes = {
  isDesktop: bool,
  isApiCall: bool,

  currencySymbol: string,
  selectedTabMetrics: string,

  selectedSalesDF: shape({}),
  selectedMarketplace: shape({}),

  contributionData: arrayOf(shape({})),
  goalsPerformanceData: arrayOf(shape({})),

  displaySalesPagination: func,
  handleOnMetricsTabChange: func,
  displayContributionTable: func,
  dispalyContributionSectionHeader: func,
};

const Wrapper = styled.div`
  /* td {
    padding: 20px 10px 3px 0px !important;
  } */
  .statusContainer {
    margin-top: 0px !important;
  }
`;
const GoalsPerformance = styled.div`
  max-width: 85%;
  text-align: center;
`;

const GoalBubbles = styled.div`
  color: ${Theme.black};
  text-align: center;
  width: 100%;
  min-height: 24px;
  font-size: ${Theme.extraNormal};
  border-radius: 5px;
  padding: 4px;

  &.green {
    background: ${Theme.green};
    color: ${Theme.white};
  }
  &.grey {
    background: ${Theme.gray9};
    color: ${Theme.black};
  }
  &.red {
    background: ${Theme.darkRed};
    color: ${Theme.white};
  }
  &.yellow {
    background: ${Theme.yellow};
    color: ${Theme.white};
  }
`;

const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  font-size: 12px;
`;

const PercentValue = styled.span`
  font-size: ${Theme.extraSmall};
  font-family: ${Theme.baseFontFamily};
  word-break: break-all;
  white-space: nowrap;

  &.green {
    color: ${Theme.lighterGreen};
  }
  &.red {
    color: ${Theme.red};
  }
  &.yellow {
    color: ${Theme.yellow};
  }
`;

const GoalCount = styled.div`
  font-size: ${Theme.extraSmall};
  font-family: ${Theme.baseMediumFontFamily};
  color: ${Theme.black};
  text-align: center;
  margin-top: 4px;
`;
