import styled from 'styled-components';

import Theme from '../../../../../../theme/Theme';

export const TableWrapper = styled.div`
  th.product-header {
    position: relative;
    img {
      position: absolute;
      top: 10px;
      transform: rotate(270deg);
    }
    .rotate {
      transform: rotate(90deg);
      top: 10px;
    }
  }
`;

export const SellerHealth = styled.div`
  .border-line {
    border-right: 1px solid ${Theme.gray45};

    &:last-child {
      border-right: none;
    }
    @media only screen and (max-width: 767px) {
      border-right: none;
      border-bottom: 1px solid ${Theme.gray45};
      &:last-child {
        border-right: none;
      }
    }
  }
`;
