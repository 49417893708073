import React from 'react';

import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { bool, func, shape, string } from 'prop-types';

import { dspPacingTooltipConstants } from '../../../../../../constants';
import Theme from '../../../../../../theme/Theme';
import { InfoIcons, LeftArrowIcon } from '../../../../../../theme/images';
import { AllocateBar, PageLoader } from '../../../../../../common';

export default function DspAdPacing({
  dspData,
  isDspPacingLoading,
  currencySymbol,
  isAllowToSplitBalance,
  setShowEscrowReallocationModal,
  marketplace,
}) {
  const dspPacing = dspData?.dsp_pacing;

  const addThousandSeperator = (value, type = '', showMinus = true) => {
    if (value && value !== null && value !== 0) {
      value = Number(value.toFixed(2));
      return `${value < 0 && showMinus ? '-' : ''}${
        type === 'currency' ? currencySymbol : ''
      }${value
        .toString()
        .replace('-', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }
    return 0;
  };

  const calculateDspDiff = () => {
    const result = dspPacing?.planed_to_spend - dspPacing?.total_spend;

    if (Math.sign(result) === 1) {
      return 'Underspend to Date';
    }
    if (Math.sign(result) === -1) {
      return 'Overspend to Date';
    }
    return '';
  };

  const displayMonth = () => {
    const currentDateOfMonth = new Date().getDate();
    if (currentDateOfMonth === 1) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1, 1);
      return dayjs(new Date(currentDate)).format('MMMM');
    }
    return dayjs(new Date()).format('MMMM');
  };

  const renderToolTipIcon = (dataText, dataFor) => {
    return (
      <img
        src={InfoIcons}
        alt="helpCircleIcon"
        style={{
          width: '12px',
          verticalAlign: 'middle',
          paddingBottom: '3px',
        }}
        data-tip={dataText}
        data-for={dataFor}
      />
    );
  };
  const renderToolTipInfo = (dataId) => {
    return (
      <ReactTooltip
        id={dataId}
        aria-haspopup="true"
        place="right"
        effect="solid"
        backgroundColor="#2e384d"
        fontSize="14px"
        className="dspPacingTooltip"
        html
      />
    );
  };
  const renderDspPacingLabel = () => {
    const dspPacingFlag = dspData?.dsp_pacing?.dsp_pacing_flag;
    const pacingLabel =
      dspPacingFlag === '1'
        ? 'Overspending'
        : dspPacingFlag === '0'
        ? 'On Track'
        : dspPacingFlag === '-1'
        ? 'Underspending'
        : null;

    return (
      <>
        <div
          className={`status-heading-red ${
            dspPacingFlag === '0' ? 'green' : ''
          }`}
          data-cy="BT-dspAdPacingOverlay-spendStatus"
        >
          {pacingLabel}
        </div>
        <p
          className="basic-text"
          data-cy="BT-dspAdPacingOverlay-spendStatusDays"
        >
          {dspPacingFlag === '0'
            ? 'You are currently on track to hit your monthly DSP budget.'
            : `You are currently ${
                pacingLabel === 'Overspending'
                  ? 'overspending'
                  : 'underspending'
              } by an average of ${addThousandSeperator(
                dspPacing?.current_spend_status,
                'currency',
              )} per day.`}
        </p>
      </>
    );
  };

  // display html of dsp breakdown filed
  const displayDspBreakdownFieldsHTML = (
    fieldLabel,
    fieldInfoKey,
    fieldAmount,
    percentValue = null,
    showBoldLabel = false,
    useNumberFormatter = true,
  ) => {
    const showBoldValue = fieldAmount === 'escrow';
    const dataCyLabel = `${dspPacingTooltipConstants?.[fieldInfoKey]?.dataCy}-label`;
    const dataCyValue = `${dspPacingTooltipConstants?.[fieldInfoKey]?.dataCy}-value`;
    return (
      <>
        <div className="col-7">
          <div
            className={`label-info mt-2 ${showBoldLabel ? 'text-bold' : ''}`}
            data-cy={dataCyLabel}
          >
            {fieldLabel}&nbsp;
            {dspPacingTooltipConstants?.[fieldInfoKey]?.text
              ? renderToolTipIcon(
                  dspPacingTooltipConstants?.[fieldInfoKey]?.text,
                  dspPacingTooltipConstants?.[fieldInfoKey]?.id,
                )
              : null}
          </div>
          {dspPacingTooltipConstants?.[fieldInfoKey]?.text
            ? renderToolTipInfo(dspPacingTooltipConstants?.[fieldInfoKey]?.id)
            : null}
        </div>
        <div className="col-5">
          <div
            className={`label-info text-right mt-2 ${
              showBoldValue ? 'text-bold' : ''
            }`}
            data-cy={dataCyValue}
          >
            {useNumberFormatter
              ? `${addThousandSeperator(dspPacing?.[fieldAmount], 'currency')}${
                  percentValue !== null
                    ? ` (${addThousandSeperator(
                        dspPacing?.[percentValue],
                        '',
                      )}%)`
                    : ''
                }`
              : dspPacing?.[fieldAmount]}
          </div>
        </div>
      </>
    );
  };

  const renderBreakdownFields = () => {
    return (
      <div className="row">
        {displayDspBreakdownFieldsHTML(
          'Total DSP invested till date',
          'totalAmountPaidTillDateInfo',
          'total_amount_paid_till_date',
          null,
          false,
          true,
        )}
        {displayDspBreakdownFieldsHTML(
          'Invoice Paid',
          'invoicePaidInfo',
          'invoice_amount',
          null,
          false,
          true,
        )}
        {displayDspBreakdownFieldsHTML(
          'Carry-over',
          'carryOverInfo',
          'carry_over',
          null,
          false,
          true,
        )}
        {displayDspBreakdownFieldsHTML(
          `Initial Total Budget for ${displayMonth()}`,
          'initialTotalbudgetInfo',
          'total_budget',
          null,
          true,
          true,
        )}
        {displayDspBreakdownFieldsHTML(
          `Initial Daily Budget ${displayMonth()}`,
          'initialDailyBudgetInfo',
          'daily_budget',
          null,
          true,
          true,
        )}
        {displayDspBreakdownFieldsHTML(
          'Days Passed',
          'dayPassedInfo',
          'days_passed',
          null,
          false,
          false,
        )}
      </div>
    );
  };

  const renderSpendDetails = () => {
    return (
      <div className="row">
        {displayDspBreakdownFieldsHTML(
          'Planned Spend to Date',
          'plannedSpendToDateInfo',
          'planed_to_spend',
          'planed_to_spend_percentage',
          false,
          true,
        )}
        {displayDspBreakdownFieldsHTML(
          'Actual Spend to Date',
          'actualSpendToDateInfo',
          'total_spend',
          'total_spend_percentage',
          false,
          true,
        )}

        <div className="col-7">
          <div
            className={
              dspData?.dsp_pacing?.dsp_pacing_flag === '0'
                ? 'label-info mt-2'
                : 'label-info text-red mt-2'
            }
            data-cy="BT-dspAdPacingOverlay-spendStatusToDate-label"
          >
            {calculateDspDiff()}
          </div>
        </div>
        <div className="col-5">
          <div
            className={
              dspData?.dsp_pacing?.dsp_pacing_flag === '0'
                ? 'label-info text-right mt-2'
                : 'label-info text-right text-red mt-2'
            }
            data-cy="BT-dspAdPacingOverlay-spendStatusToDate-value"
          >
            {addThousandSeperator(dspPacing?.dsp_pacing_diff, 'currency')}
            &nbsp;(
            {addThousandSeperator(dspPacing?.dsp_pacing_diff_percentage, '')}
            %)
          </div>
        </div>

        {displayDspBreakdownFieldsHTML(
          `Remaining Budget for ${displayMonth()}`,
          'remainingBudgetInfo',
          'remaining_budget',
          null,
          true,
          true,
        )}
      </div>
    );
  };

  const renderDailyBudget = () => {
    return (
      <div className="row">
        <div className="col-7">
          <div
            className="label-info text-bold "
            data-cy="BT-dspAdPacingOverlay-suggestedDailyBudget-label"
          >
            Suggested Daily Budget
          </div>
        </div>
        <div className="col-5">
          <div
            className="label-info text-bold text-right"
            data-cy="BT-dspAdPacingOverlay-suggestedDailyBudget-value"
          >
            {dspPacing?.suggested_spend === 0 ||
            dspPacing?.suggested_spend === 0.0
              ? 'N/A'
              : addThousandSeperator(dspPacing?.suggested_spend, 'currency')}
          </div>
        </div>
        <div className="col-7">
          <div className="label-info  budget-text mt-1">
            For rest of month to hit budget
          </div>
        </div>
        {isNaN(dspPacing?.suggested_spend) ? (
          ''
        ) : (
          <div className="col-5">
            <div className="label-info budget-text text-right mt-1">
              Per day
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderEscrowBalance = () => {
    return (
      <>
        <div className="row mb-3">
          {displayDspBreakdownFieldsHTML(
            'Escrow Balance',
            'escrowBalanceInfo',
            'escrow',
            null,
            true,
            true,
          )}
        </div>

        {isAllowToSplitBalance ? (
          <AllocateBar
            id="BT-escrow-dspPacing-AllocateBalance"
            className="mb-4"
          >
            <div className="row">
              <div className="col-8">
                <div
                  className="remaing-label"
                  data-cy="BT-dspAdPacingOverlay-allocateBalanceInfo"
                >
                  {dspPacing?.escrow_sum < 0
                    ? `${addThousandSeperator(
                        dspPacing?.escrow_sum,
                        'currency',
                        false,
                      )} of the escrow planned for future month was allocated to current month`
                    : `${addThousandSeperator(
                        dspPacing?.escrow_sum,
                        'currency',
                      )} of the escrow is planned carry-over for future months`}
                </div>
              </div>
              <div className="col-4">
                {marketplace !== 'all' ? (
                  <div
                    className="allocate-balance cursor"
                    onClick={() => setShowEscrowReallocationModal(true)}
                    role="presentation"
                  >
                    Allocate Balance
                    <img
                      className="orange-left-arrow"
                      src={LeftArrowIcon}
                      alt=""
                    />
                  </div>
                ) : null}
                <div className="clear-fix" />
              </div>
            </div>
          </AllocateBar>
        ) : null}
      </>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      {isDspPacingLoading ? (
        <PageLoader
          component="Notes-modal-loader"
          color="#FF5933"
          type="page"
        />
      ) : Object.keys(dspData).length ? (
        <>
          <DspAdPacingModal
            id="BT-dsp-adpacing"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header">
              <h4 className="on-boarding">DSP Monthly Budget Pacing</h4>
              <p
                className="basic-text"
                data-cy="BT-dspAdPacingOverlay-remainingDays"
              >
                {displayMonth()} 1 - {dspPacing?.last_day}{' '}
                <span className="dot" /> &nbsp;&nbsp;&nbsp;
                {dspPacing?.days_remains} days remaining
              </p>
            </div>
            <div className="straight-line horizontal-line mt-3 mb-3" />
            <div className="modal-body-section">
              <div className="label-heading mb-2 pb-1">Spend Status</div>
              {renderDspPacingLabel()}
              <div className="straight-line horizontal-line mt-3 mb-3" />
              <div className="label-heading mb-2">Breakdown</div>
              {renderBreakdownFields()}
              <div className="dotted-horizontal-line mt-3 mb-3" />
              {renderSpendDetails()}
              <div className="straight-line horizontal-line mt-3 mb-3" />
              {renderDailyBudget()}
              <div className="straight-line horizontal-line mt-3 mb-3" />
              {renderEscrowBalance()}
              <div className="straight-line horizontal-line mt-3 mb-3" />
            </div>
          </DspAdPacingModal>
        </>
      ) : (
        <PageLoader
          component="Notes-modal-loader"
          color="#FF5933"
          type="page"
        />
      )}
    </>
  );
}

const DspAdPacingModal = styled.div`
  top: 0;
  background: ${Theme.white};
  height: 100%;
  .modal-header {
    padding: 15px 15px 0 15px;

    .dot {
      background-color: ${Theme.gray35};
      border-radius: 50%;
      width: 3px;
      height: 3px;
      position: absolute;
      top: 38px;
      margin-left: 3px;
    }
  }
  .modal-body-section {
    padding: 0 15px;
    height: 85vh;
    overflow: scroll;

    .status-heading-red {
      color: ${Theme.red};
      font-size: 26px;

      &.green {
        color: ${Theme.lighterGreen};
      }
    }
    .dotted-horizontal-line {
      border-bottom: 2px dotted ${Theme.gray4};
    }

    .text-red {
      color: ${Theme.red};
    }
    .text-bold {
      font-weight: bold;
    }
    .budget-text {
      color: ${Theme.gray85};
      font-size: ${Theme.extraSmall};
    }
    .dspPacingTooltip {
      width: 240px;
      font-size: 14px;
    }
  }
`;

DspAdPacing.defaultProps = {
  isDspPacingLoading: false,
  isAllowToSplitBalance: false,
  currencySymbol: '',
  marketplace: '',
  dspData: {},
  setShowEscrowReallocationModal: () => {},
};

DspAdPacing.propTypes = {
  isDspPacingLoading: bool,
  isAllowToSplitBalance: bool,
  currencySymbol: string,
  marketplace: string,
  dspData: shape({}),
  setShowEscrowReallocationModal: func,
};
