// react imports
import React from 'react';

// third party imports
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, string, shape, number } from 'prop-types';

// local file imports
import Theme from '../../../../theme/Theme';
import {
  Tabs,
  WhiteCard,
  PageLoader,
  CommonPagination,
  NoData,
  GrayMessageBar,
} from '../../../../common';
import {
  noGraphDataMessage,
  metricsCurrency,
  sponsoredTabOptions,
  ADPERFORMANCE,
  ADSPEND,
  CUSTOM,
} from '../../../../constants';

// third party variable
const _ = require('lodash');

const SponsoredKeyContribution = ({
  pageNumber,
  count,
  isApiCall,
  downloadReportLoader,
  currencySymbol,
  contributionData,
  selectedAdMetrics,
  selectedTabMetrics,
  selectedAdDF,
  handlePageChange,
  handleOnMetricsTabChange,
  displayContributionTable,
  handleKeyContributorsDownloadReport,
}) => {
  const dashboardDynamicError = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );

  // display sp key contribution tab
  const renderKeyContributionTabs = () => {
    let selectedTab = selectedTabMetrics;
    if (selectedAdMetrics[selectedTabMetrics] === undefined) {
      const selctedArray = _.keys(selectedAdMetrics);
      // eslint-disable-next-line prefer-destructuring
      selectedTab = selctedArray[0];
      handleOnMetricsTabChange(selectedTab);
    }

    return (
      <Tabs>
        <ul className={isApiCall ? 'tabs disabled' : 'tabs'}>
          {_.keys(selectedAdMetrics)?.map((item) => (
            <li
              id={`BT-${item}-tab`}
              key={Math.random()}
              className={selectedTab === item ? 'active' : ''}
              onClick={() => handleOnMetricsTabChange(item)}
              role="presentation"
            >
              {sponsoredTabOptions[item]}
            </li>
          ))}
        </ul>
      </Tabs>
    );
  };

  // display contribution data table
  const dispalyContributionDataTable = () => {
    let prefix = '';
    let suffix = '';
    let decimalDigits = 2;
    if (metricsCurrency[selectedTabMetrics]?.type === 'currency') {
      decimalDigits = 2;
      prefix = currencySymbol;
      suffix = '';
    }
    if (metricsCurrency[selectedTabMetrics]?.type === 'percentage') {
      decimalDigits = 2;
      prefix = '';
      suffix = '%';
    }
    if (metricsCurrency[selectedTabMetrics]?.type === 'nocurrency') {
      decimalDigits = 0;
      prefix = '';
      suffix = '';
    }

    const isGrayMetrics = selectedTabMetrics === ADSPEND;

    return isApiCall ? (
      <PageLoader
        component="performance-graph"
        color="#FF5933"
        type="detail"
        width={40}
        height={40}
      />
    ) : (
      <div>
        {displayContributionTable(
          contributionData,
          prefix,
          suffix,
          decimalDigits,
          isGrayMetrics,
          ADPERFORMANCE,
        )}
        {contributionData?.length >= 1 ? (
          <CommonPagination
            count={count}
            pageNumber={pageNumber}
            handlePageChange={handlePageChange}
          />
        ) : null}
        {!contributionData || contributionData?.length === 0 ? (
          <NoData>{noGraphDataMessage}</NoData>
        ) : null}
      </div>
    );
  };

  // display sp ad Key Contributor Section;
  const displayKeyContributorSection = () => {
    return (
      <WhiteCard className="mb-3">
        <div className="row">
          <div className="col-md-6 col-sm1-12">
            <p className="black-heading-title mt-2 mb-4">Contributors</p>
          </div>
          <div className="col-md-6 col-12 pr-2 text-right">
            <div
              className={`black-heading-title text-medium-font m-0 cursor ${
                isApiCall ||
                downloadReportLoader ||
                selectedAdDF?.value === CUSTOM
                  ? 'disabled'
                  : ''
              }`}
              role="presentation"
              onClick={() => {
                handleKeyContributorsDownloadReport();
              }}
              style={{ maxWidth: '150px' }}
            >
              {downloadReportLoader ? (
                <PageLoader
                  component="sidebar-loader"
                  type="button"
                  color={Theme?.orange}
                />
              ) : (
                <>
                  <FontAwesomeIcon
                    className="mr-2"
                    color={Theme?.orange}
                    icon="far fa-arrow-to-bottom"
                  />
                  Download Report
                </>
              )}
            </div>
          </div>
          <div className="col-12">
            {dashboardDynamicError?.sponsored_performance?.contribution ? (
              <GrayMessageBar
                message={
                  dashboardDynamicError?.sponsored_performance?.contribution
                }
              />
            ) : null}
          </div>
        </div>
        {selectedAdDF?.value === CUSTOM ? (
          <NoData>
            Top contributors cannot be calculated when using custom dates.
          </NoData>
        ) : (
          <>
            {renderKeyContributionTabs()}
            {dispalyContributionDataTable()}
          </>
        )}
      </WhiteCard>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <Wrapper id="BT-sponsored-key-contributors">
      {displayKeyContributorSection()}
    </Wrapper>
  );
};

export default SponsoredKeyContribution;

SponsoredKeyContribution.defaultProps = {
  downloadReportLoader: bool,
  isApiCall: false,
  contributionData: {},
  currencySymbol: '',
  count: null,
  pageNumber: 1,
  selectedAdMetrics: {},
  selectedTabMetrics: {},
  selectedAdDF: {},
  handlePageChange: () => {},
  handleOnMetricsTabChange: () => {},
  displayContributionTable: () => {},
  handleKeyContributorsDownloadReport: () => {},
};

SponsoredKeyContribution.propTypes = {
  downloadReportLoader: bool,
  isApiCall: bool,
  selectedTabMetrics: string,
  currencySymbol: string,
  count: number,
  pageNumber: number,
  selectedAdDF: shape({}),
  selectedAdMetrics: shape({}),
  contributionData: arrayOf(shape({})),
  handleOnMetricsTabChange: func,
  handlePageChange: func,
  displayContributionTable: func,
  handleKeyContributorsDownloadReport: func,
};

const Wrapper = styled.div`
  td {
    padding: 20px 10px 3px 0px !important;
  }
  .statusContainer {
    margin-top: 0px !important;
  }
`;
