import React from 'react';

import { useMediaQuery } from 'react-responsive';
import {
  string,
  arrayOf,
  shape,
  oneOfType,
  object,
  bool,
  func,
} from 'prop-types';

import PlaybookDeliverableCollapse from './PlaybookDataTable/PlaybookDeliverableCollapse';
import { PlaybookMobileView } from './PlaybookStyle';
import { DesktopTable, WhiteCard } from '../../../../common';
import {
  onboardingPlaybookTableHeader,
  playbookTableHeaderBPView,
} from '../../../../constants';

export default function OnboardingPlaybook({
  isCustomer,
  customerId,
  playbookTab,
  playbookAPICall,
  isUserAssignedToBP,
  onboardingPlaybookData,
  onboardingPBTaskUpdateID,
}) {
  const isTablet = useMediaQuery({ minWidth: 768 });

  // this variable is actually for main playbook but for onboarding playbook we need to pass this as a props
  // by default pass as a true.
  const isCurrentMonthTask = true;

  // render deliverable collapse
  const renderPlaybookDeliverableCollapse = (dataItem) => {
    return (
      <PlaybookDeliverableCollapse
        ID={onboardingPBTaskUpdateID}
        deliverableData={dataItem}
        isCustomer={isCustomer}
        customerId={customerId}
        playbookTab={playbookTab}
        playbookAPICall={playbookAPICall}
        isUserAssignedToBP={isUserAssignedToBP}
        isCurrentMonthTask={isCurrentMonthTask}
      />
    );
  };

  // render onboarding playbook table for desktop view
  const renderOnboardingPlaybookTable = () => {
    return (
      <div>
        <DesktopTable className="services-playbook-table">
          <thead>
            <tr className="gray-border-radius">
              {isCustomer
                ? playbookTableHeaderBPView?.map((item) => {
                    return (
                      <th key={item?.label} width={item?.width}>
                        {item?.label}
                      </th>
                    );
                  })
                : onboardingPlaybookTableHeader?.map((item) => {
                    return (
                      <th key={item?.label} width={item?.width}>
                        {item?.label}
                      </th>
                    );
                  })}
            </tr>
          </thead>
          <tbody>
            {onboardingPlaybookData?.map((item) => {
              return renderPlaybookDeliverableCollapse(item);
            })}
          </tbody>
        </DesktopTable>
      </div>
    );
  };

  // onboarding playbook mobile view
  const renderOnboardingPlaybookMobileView = () => {
    return (
      <PlaybookMobileView>
        {onboardingPlaybookData?.map((item) => {
          return (
            <WhiteCard
              className="card-with-border mb-3"
              key={item?.deliverableData?.deliverable_id}
            >
              {renderPlaybookDeliverableCollapse(item)}
            </WhiteCard>
          );
        })}
      </PlaybookMobileView>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <div>
      {isTablet
        ? renderOnboardingPlaybookTable()
        : renderOnboardingPlaybookMobileView()}
    </div>
  );
}

OnboardingPlaybook.defaultProps = {
  onboardingPBTaskUpdateID: '',
  onboardingPlaybookData: [],
  playbookAPICall: () => {},
};

OnboardingPlaybook.propTypes = {
  customerId: string.isRequired,
  onboardingPBTaskUpdateID: string,
  playbookTab: string.isRequired,
  isCustomer: bool.isRequired,
  isUserAssignedToBP: bool.isRequired,
  playbookAPICall: func,
  onboardingPlaybookData: oneOfType([arrayOf(shape()), object]),
};
