// react imports
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';

// third party imports
import { toast } from 'react-toastify';
import { arrayOf, shape, bool, func } from 'prop-types';

// local file imports
import { InventoryStyle } from './InventoryStyle';
import InventoryFilter from './InventoryFilter';
import InventoryRightHandSideSection from './InventoryRightHandSideSection';
import {
  CustomSingleDateModal,
  MultiSelectSearchableDropdown,
} from '../../../../common';
import {
  CUSTOM,
  SELLER,
  allBPObj,
  allValueObj,
  defaultRestockInventoryOrderBy,
  defaultStrandedInventoryOrderBy,
  defaultTimeFrameForInventory,
  performanceTypeOptions,
  performanceTypeOptionsForInventory,
  storageTypeOptions,
} from '../../../../constants';
import {
  getBgsUserList,
  getRestockData,
  getStrandedInventoryTableData,
  getLeadershipBrandPartners,
} from '../../../../api';

const InventoryDashboardContainer = ({
  // boolean
  isBGS,
  isBGSAdmin,
  isBGSManager,
  isSpreetailRole,
  includeInactiveBPs,
  includeSpreetailBrands,
  // object
  userInfo,
  selectedMarketplace,
  selectedBrandPartner,
  // array
  loggedInUser,
  selectedUser,
  selectedManagerUser,
  marketplaceChoices,
  // function
  setSelectedUser,
  checkIsUserExist,
  bindUserListData,
  bindBrandPartners,
  setSelectedManagerUser,
  setSelectedMarketplace,
  generateStringOfValues,
  setSelectedBrandPartner,
  handleMultiSelectDropdownFilterEvents,
}) => {
  const mounted = useRef(false);
  // Note -> advertisisng user do not have access to inventory dashboard

  const isRestockInventory = userInfo?.is_restockinventory_visible || false;
  const inventoryMindate = new Date('2022-03-21');
  const currentDate = new Date();

  const [responseId, setResponseId] = useState(null);
  const [customDateState, setCustomDateState] = useState(currentDate);
  const [selectedCustomDateState, setSelectedCustomDateState] =
    useState(currentDate);
  const [dataAvailableDate, setDataAvailableDate] = useState(currentDate);
  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [isCustomDateApply, setIsCustomDateApply] = useState(false);

  const [selectedTimeFrame, setSelectedTimeFrame] = useState(
    defaultTimeFrameForInventory,
  );
  const [orderBy, setOrderBy] = useState(defaultRestockInventoryOrderBy);
  const defaultRestockOrderByApi =
    selectedTimeFrame?.value === CUSTOM ? 'company_name' : '-utilization';
  const [orderByApi, setOrderByApi] = useState(defaultRestockOrderByApi);
  const [selectedStorageType, setSelectedStorageType] =
    useState(storageTypeOptions);
  const [isStorageTypeSelected, setIsStorageTypeSelected] = useState(false);
  const [selectedPerformanceType, setSelectedPerformanceType] = useState(
    performanceTypeOptionsForInventory?.[0],
  );
  const [downloadReportLoader, setDownloadReportLoader] = useState({
    tab: '',
    loader: false,
  });

  const [isManagerAPICalled, setIsManagerAPICalled] = useState(
    !(isBGSAdmin || isSpreetailRole),
  );
  const [isBGSAPICalled, setIsBGSAPICalled] = useState(
    !(isBGSAdmin || isBGSManager || isSpreetailRole),
  );

  // use state for bgs list, bgs manager list, ad manager list, ad team manager list,
  const [brandPartnerOptions, setBrandPartnerOptions] = useState([]);
  const [marketplaceOptions, setMarketplaceOptions] =
    useState(marketplaceChoices);
  const [userList, setUserList] = useState([]);
  const [userApiList, setUserApiList] = useState([]);
  const [managerUserList, setManagerUserList] = useState([]);
  const [managerUserApiList, setManagerUserApiList] = useState([]);
  const [isUsersSelected, setIsUsersSelected] = useState(false);
  const [isManagerUsersSelected, setIsManagerUsersSelected] = useState(false);

  const [restockLoader, setRestockLoader] = useState(false);
  const [restockData, setRestockData] = useState(null);
  const [restockCount, setRestockCount] = useState(null);
  const [restockPageNumber, setRestockPageNumber] = useState(1);

  const [strandedInventoryLoader, setStrandedInventoryLoader] = useState(false);
  const [strandedInventoryData, setStrandedInventoryData] = useState(null);
  const [strandedInventoryCount, setStrandedInventoryCount] = useState(null);
  const [strandedInventoryPageNumber, setStrandedInventoryPageNumber] =
    useState(1);

  const [strandedInventoryOrderBy, setStrandedInventoryOrderBy] = useState(
    defaultStrandedInventoryOrderBy,
  );
  const defaultStrandedOrderByKey = isRestockInventory
    ? 'company_name'
    : '-percent_change';
  const [strandedInventoryOrderByApi, setStrandedInventoryOrderByApi] =
    useState(defaultStrandedOrderByKey);

  // all brand partner object
  const allBrandPartnerObj = {
    ...allBPObj,
    accountType: SELLER,
    marketplaces: marketplaceChoices,
  };

  // default all value array of object
  const allObj = useMemo(() => {
    return allValueObj;
  }, []);

  // use effect for set marketplace options
  useEffect(() => {
    setMarketplaceOptions(marketplaceChoices);
  }, [marketplaceChoices]);

  // https://bbe.atlassian.net/browse/PDV-8757 - Leadership dashboard improvements
  // callback functions for get brand partners list
  const getLeadershipBrandPartnersList = useCallback(
    (callType, managersUser, bgsUser, selectedBp) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);

      getLeadershipBrandPartners(
        userInfo?.role,
        'inventory',
        managerUserString,
        bgsUserString,
        includeInactiveBPs,
        includeSpreetailBrands,
      ).then((response) => {
        if (response?.status === 200 && response?.data?.length) {
          const bpList = bindBrandPartners(response?.data);

          setBrandPartnerOptions(bpList);
          const brandPartner =
            callType === 'useEffect' ? selectedBp : bpList?.[0];
          setSelectedBrandPartner(brandPartner);

          setSelectedPerformanceType(performanceTypeOptions?.[0]);
        } else {
          setBrandPartnerOptions([]);
          setSelectedBrandPartner({});
          setSelectedPerformanceType({});
        }
      });
    },
    [
      bindBrandPartners,
      generateStringOfValues,
      includeInactiveBPs,
      includeSpreetailBrands,
      setSelectedBrandPartner,
      setSelectedPerformanceType,
      userInfo,
    ],
  );

  // get user list - bgs
  const getBgsList = useCallback(
    (role, managers, users, callType) => {
      setRestockLoader(true);
      setStrandedInventoryLoader(true);

      const managersString = generateStringOfValues(managers);

      let API = '';
      if (role === 'BGS') {
        API = getBgsUserList(role, managersString);
      }

      API.then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const formatedApiData = bindUserListData(response?.data, role);
            setUserList(formatedApiData?.userListWithAll);
            setUserApiList(formatedApiData?.userList);

            const isUserExist = checkIsUserExist(
              formatedApiData?.userList,
              users,
            );

            if (callType !== 'bgsFilter') {
              if (
                isUserExist &&
                callType !== 'resetFilter' &&
                callType !== 'managerUserFilter'
              ) {
                setSelectedUser(users);
              } else {
                setSelectedUser(formatedApiData?.userListWithAll);
              }
            }

            setIsBGSAPICalled(true);
          } else {
            setUserList([]);
            setSelectedUser([]);
            setUserApiList([]);
          }
        }
      });
    },

    [
      bindUserListData,
      checkIsUserExist,
      generateStringOfValues,
      setSelectedUser,
    ],
  );

  // get manager user list - bgs manager
  const getManagerUserList = useCallback(
    (role, managerUsers, users, callType) => {
      setRestockLoader(true);
      setStrandedInventoryLoader(true);

      let API = '';
      if (role === 'BGS Manager') {
        API = getBgsUserList(role);
      }

      API.then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const formatedApiData = bindUserListData(response?.data);
            setManagerUserApiList(formatedApiData?.userList);
            setManagerUserList(formatedApiData?.userListWithAll);

            const isManagerUserExist = checkIsUserExist(
              formatedApiData?.userList,
              managerUsers,
            );
            let managerUser = null;

            if (
              (isBGSAdmin || isSpreetailRole) &&
              isManagerUserExist &&
              callType !== 'resetFilter'
            ) {
              setSelectedManagerUser(managerUsers);
              managerUser = managerUsers;
            } else {
              setSelectedManagerUser(formatedApiData?.userListWithAll);
              managerUser =
                formatedApiData?.userList?.length > 1
                  ? null
                  : formatedApiData?.userList;
            }
            if (isBGSAdmin || isSpreetailRole) {
              getBgsList('BGS', managerUser, users, callType);
            }

            setIsManagerAPICalled(true);
          } else {
            setManagerUserApiList([]);
            setManagerUserList([]);
            setSelectedManagerUser([]);
          }
        }
      });
    },

    [
      bindUserListData,
      checkIsUserExist,
      getBgsList,
      isBGSAdmin,
      isSpreetailRole,
      setSelectedManagerUser,
    ],
  );

  // calling bgs and advertising user api
  const callingBgsAndAdUserApi = useCallback(
    (callType, managerUsers, users) => {
      // bgs api
      if (isBGSAdmin || isSpreetailRole) {
        getManagerUserList('BGS Manager', managerUsers, users, callType);
      }

      if (isBGSManager) {
        getBgsList('BGS', loggedInUser, users, callType);
      }
    },
    [
      isBGSAdmin,
      isSpreetailRole,
      isBGSManager,
      getManagerUserList,
      getBgsList,
      loggedInUser,
    ],
  );

  // use effect for bgs team
  useEffect(() => {
    mounted.current = true;
    callingBgsAndAdUserApi('useEffect', selectedManagerUser, selectedUser);
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingBgsAndAdUserApi]);

  // callback function for GET Restock inventory data
  const onGetRestockData = useCallback(
    (
      brandPartner,
      marketplace,
      storageTypes,
      managerUsers,
      users,
      timeFrame,
      customDate,
      orderByApiKey,
      pageNumber,
      isDownloadReport = false,
    ) => {
      const usersString = generateStringOfValues(users);
      const managerUsersString = generateStringOfValues(managerUsers);
      const storageTypeString = generateStringOfValues(storageTypes);

      setRestockLoader(true);
      setRestockData(null);
      setIsUsersSelected(false);
      setIsManagerUsersSelected(false);

      let orderByFlag = '';
      if (timeFrame?.value === CUSTOM) {
        orderByFlag = 'company_name';
      } else {
        orderByFlag = orderByApiKey;
      }

      getRestockData(
        isBGS,
        isBGSAdmin || isSpreetailRole,
        isBGSManager,
        brandPartner,
        marketplace,
        storageTypeString,
        usersString,
        managerUsersString,
        timeFrame?.value,
        customDate,
        orderByFlag,
        pageNumber,
        isDownloadReport,
      ).then((response) => {
        if (response?.status === 200) {
          setRestockData(response?.data?.results);
          setRestockCount(response?.data?.count || 0);
          if (response?.data?.available_date !== null) {
            setDataAvailableDate(new Date(response?.data?.available_date));
            if (timeFrame?.value !== CUSTOM)
              setSelectedCustomDateState(
                new Date(response?.data?.available_date),
              );
          } else {
            setDataAvailableDate(new Date());
            setSelectedCustomDateState(new Date());
          }
          setRestockLoader(false);
        } else {
          setRestockData(null);
          setRestockCount(0);
          setRestockLoader(false);
        }
      });
    },

    [generateStringOfValues, isBGS, isBGSAdmin, isSpreetailRole, isBGSManager],
  );

  // callback function for get stranded inventory data
  const onGetStrandedInventoryData = useCallback(
    (
      brandPartner,
      marketplace,
      managerUsers,
      users,
      timeFrame,
      customDate,
      orderByApiKey,
      pageNumber,
    ) => {
      const usersString = generateStringOfValues(users);
      const managerUsersString = generateStringOfValues(managerUsers);

      setStrandedInventoryLoader(true);
      setStrandedInventoryData(null);

      setIsUsersSelected(false);
      setIsManagerUsersSelected(false);

      const orderByKey =
        orderByApiKey === 'sku_change'
          ? 'change'
          : orderByApiKey === '-sku_change'
          ? '-change'
          : orderByApiKey;

      getStrandedInventoryTableData(
        isBGS,
        isBGSAdmin || isSpreetailRole,
        isBGSManager,
        brandPartner,
        marketplace,
        managerUsersString,
        usersString,
        timeFrame?.value,
        customDate,
        orderByKey,
        pageNumber,
        isRestockInventory,
      ).then((response) => {
        if (response?.status === 200) {
          setStrandedInventoryData(response?.data?.results);
          setStrandedInventoryCount(response?.data?.count || 0);

          setStrandedInventoryLoader(false);
        } else {
          setStrandedInventoryData(null);
          setStrandedInventoryCount(0);
          setStrandedInventoryLoader(false);
        }
      });
    },

    [
      generateStringOfValues,
      isBGS,
      isBGSAdmin,
      isBGSManager,
      isRestockInventory,
      isSpreetailRole,
    ],
  );

  // - called only at the begining.
  useEffect(() => {
    mounted.current = true;

    if (responseId === null && isManagerAPICalled && isBGSAPICalled) {
      // get brand partners list
      getLeadershipBrandPartnersList(
        'useEffect',
        selectedManagerUser,
        selectedUser,
        selectedBrandPartner,
      );
      onGetRestockData(
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedStorageType,
        selectedManagerUser,
        selectedUser,
        selectedTimeFrame,
        customDateState,
        orderByApi,
        1,
      );
      onGetStrandedInventoryData(
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        selectedTimeFrame,
        customDateState,
        strandedInventoryOrderByApi,
        1,
      );

      setRestockPageNumber(1);
      setStrandedInventoryPageNumber(1);
      setResponseId('12345');
    }
    return () => {
      mounted.current = false;
    };
  }, [
    customDateState,
    getLeadershipBrandPartnersList,
    isBGSAPICalled,
    isManagerAPICalled,
    onGetRestockData,
    onGetStrandedInventoryData,
    orderByApi,
    responseId,
    selectedBrandPartner,
    selectedManagerUser,
    selectedMarketplace,
    selectedStorageType,
    selectedTimeFrame,
    selectedUser,
    strandedInventoryOrderByApi,
  ]);

  // function - handle download report api response
  const handleDownloadApiRes = (tab, response) => {
    if (response?.status === 200) {
      const csvLink = response?.data?.url;
      if (csvLink) window.open(csvLink);
      if (response?.data?.message) toast.success(response?.data?.message);
      setDownloadReportLoader({ tab, loader: false });
    } else {
      toast.error('Oops! Something went wrong...');
      setDownloadReportLoader({ tab, loader: false });
    }
  };

  // https://bbe.atlassian.net/browse/PDV-8757 - Leadership dashboard improvements
  // handle download restock inventory report
  const handleDownloadBtn = (tab) => {
    setDownloadReportLoader({ tab, loader: true });

    const usersString = generateStringOfValues(selectedUser);
    const managerUsersString = generateStringOfValues(selectedManagerUser);
    const storageTypeString = generateStringOfValues(selectedStorageType);

    let orderByFlag = '';
    if (selectedTimeFrame?.value === CUSTOM) {
      orderByFlag = 'company_name';
    } else {
      orderByFlag = orderByApi;
    }
    if (tab === 'stranded-restock') {
      getStrandedInventoryTableData(
        isBGS,
        isBGSAdmin || isSpreetailRole,
        isBGSManager,
        selectedBrandPartner?.value,

        selectedMarketplace?.value,
        managerUsersString,
        usersString,
        selectedTimeFrame?.value,
        customDateState,
        strandedInventoryOrderByApi,
        strandedInventoryPageNumber,
        isRestockInventory,
        true,
      ).then((response) => {
        handleDownloadApiRes(tab, response);
      });
    }
    if (tab === 'restock-limit') {
      getRestockData(
        isBGS,
        isBGSAdmin || isSpreetailRole,
        isBGSManager,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        storageTypeString,
        usersString,
        managerUsersString,
        selectedTimeFrame?.value,
        customDateState,
        orderByFlag,
        restockPageNumber,
        true,
      ).then((response) => {
        handleDownloadApiRes(tab, response);
      });
    }
  };

  // handeling default states
  const ResetFiltersToDefaultStates = () => {
    setRestockPageNumber(1);
    setStrandedInventoryPageNumber(1);
    setCustomDateState(currentDate);
    setOrderByApi(defaultRestockOrderByApi);
    setOrderBy(defaultRestockInventoryOrderBy);
    setSelectedTimeFrame(defaultTimeFrameForInventory);
    setStrandedInventoryOrderByApi(defaultStrandedOrderByKey);
    setStrandedInventoryOrderBy(defaultStrandedInventoryOrderBy);
  };

  // calling leadership BP and dashboard performance api when filter value changes
  const callingBPandDashboardAPI = (
    callType,
    managerUsers,
    users,
    selectedBp,
  ) => {
    let brandPartner = {};
    let marketplace = {};

    const managerUserString = generateStringOfValues(managerUsers);
    const userString = generateStringOfValues(users);

    getLeadershipBrandPartners(
      userInfo?.role,
      'inventory',
      managerUserString,
      userString,
      includeInactiveBPs,
      includeSpreetailBrands,
    ).then((response) => {
      if (response?.status === 200 && response?.data?.length) {
        ResetFiltersToDefaultStates();

        const bpList = bindBrandPartners(response?.data);
        setBrandPartnerOptions(bpList);

        if (callType === 'bpFilter') {
          if (selectedBp?.value === 'all') {
            brandPartner = allBrandPartnerObj;
          } else {
            brandPartner = selectedBp;
          }
        } else if (bpList?.length === 1) {
          brandPartner = bpList?.[0];
        } else {
          brandPartner = allBrandPartnerObj;
        }

        marketplace = brandPartner?.marketplaces?.[0];
        setSelectedBrandPartner(brandPartner);
        setSelectedMarketplace(marketplace);
        setMarketplaceOptions(brandPartner?.marketplaces);

        // calling dashboard api calls
        onGetRestockData(
          brandPartner?.value,
          marketplace?.value,
          selectedStorageType,
          managerUsers,
          users,
          defaultTimeFrameForInventory,
          currentDate,
          defaultRestockOrderByApi,
          1,
        );

        onGetStrandedInventoryData(
          brandPartner?.value,
          marketplace?.value,
          managerUsers,
          users,
          defaultTimeFrameForInventory,
          currentDate,
          defaultStrandedOrderByKey,
          1,
        );
      } else {
        setIsUsersSelected(false);
        setIsManagerUsersSelected(false);
        setRestockLoader(false);
        setStrandedInventoryLoader(false);
        setRestockData([]);
        setStrandedInventoryData([]);
        setBrandPartnerOptions([]);
        setSelectedBrandPartner({});
        setSelectedPerformanceType({});
      }
    });
  };

  // https://bbe.atlassian.net/browse/PDV-8757 - leadership dashboard improvements
  // handle brand partner reporting filter events
  const handleBrandPartnerFilterEvents = (event) => {
    // Handeling the states and API calls when user select specific Brand Partner
    if (event?.value !== selectedBrandPartner?.value) {
      setSelectedBrandPartner(event);

      // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
      let assignedUser = [];
      let assignedMangerUser = [];

      if (event?.value === 'all') {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        const userApiListWithAll = [...userApiList];
        if (userApiListWithAll?.length) {
          userApiListWithAll.unshift(allObj?.[0]);
        }

        const managerUserApiListWithAll = [...managerUserApiList];
        if (managerUserApiListWithAll?.length)
          managerUserApiListWithAll.unshift(allObj?.[0]);

        assignedUser = selectedUser?.length ? selectedUser : userApiListWithAll;
        assignedMangerUser = selectedManagerUser?.length
          ? selectedManagerUser
          : managerUserApiListWithAll;

        if (selectedManagerUser?.length === 0)
          setManagerUserList(managerUserApiListWithAll);
        if (selectedUser?.length === 0) setUserList(userApiListWithAll);
      } else {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        assignedUser = event?.bgs !== null ? [event?.bgs] : [];
        assignedMangerUser =
          event?.bgsManager !== null ? [event?.bgsManager] : [];
      }

      // set assigned bgs manager/ad team manager, bgs/ad manager of selected BP to multi-select bgs manager/ad team manager, bgs/ad manager dropdown filter.
      setSelectedManagerUser(assignedMangerUser);
      setSelectedUser(assignedUser);

      // if selected brand partner do not have assigned bgs manager/ad team manager and bgs/ad manager then clear the multiselect manager user and user dropdown filter list

      if (assignedMangerUser?.length === 0) {
        setManagerUserList([]);
      } else {
        const managerUserApiListWithAll = [...managerUserApiList];
        managerUserApiListWithAll.unshift(allObj?.[0]);
        setManagerUserList(managerUserApiListWithAll);
      }
      if (assignedUser?.length === 0) setUserList([]);

      // if selected brand partner have assigned bgs manager/ad team manager and bgs/ad manager then get its the bgs/ad manager list
      if (assignedMangerUser?.length) {
        if (isBGSAdmin || isSpreetailRole) {
          getBgsList('BGS', assignedMangerUser, assignedUser, 'BPFilter');
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI(
        'bpFilter',
        assignedMangerUser?.length ? assignedMangerUser : allObj,
        assignedUser?.length ? assignedUser : allObj,
        event,
      );
    }
  };
  // handle seller & vendor reporting filter events
  const handleSellerVendorFilterEvents = (event) => {
    setSelectedPerformanceType(event);
  };

  // handle marketplace filter events
  const handleMarketplace = (event) => {
    if (event?.value !== selectedMarketplace?.value) {
      setSelectedMarketplace(event);

      onGetRestockData(
        selectedBrandPartner?.value,
        event?.value,
        selectedStorageType,
        selectedManagerUser,
        selectedUser,
        selectedTimeFrame,
        customDateState,
        orderByApi,
        1,
      );

      onGetStrandedInventoryData(
        selectedBrandPartner?.value,
        event?.value,
        selectedManagerUser,
        selectedUser,
        selectedTimeFrame,
        customDateState,
        strandedInventoryOrderByApi,
        1,
      );

      setRestockPageNumber(1);
      setStrandedInventoryPageNumber(1);
    }
  };

  // handle inventory date filter events
  const handleTimeFrame = (event) => {
    const { value } = event;
    if (value !== selectedTimeFrame?.value) {
      setIsCustomDateApply(false);
      setSelectedTimeFrame(event);
      if (value !== CUSTOM) {
        setCustomDateState(currentDate);
        setSelectedCustomDateState(dataAvailableDate);

        onGetRestockData(
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedStorageType,
          selectedManagerUser,
          selectedUser,
          event,
          currentDate,
          orderByApi,
          restockPageNumber,
        );
        onGetStrandedInventoryData(
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          event,
          currentDate,
          strandedInventoryOrderByApi,
          strandedInventoryPageNumber,
        );
      }
    }
    if (value === CUSTOM) {
      setShowCustomDateModal(true);
    }
  };

  // apply selected custom date
  const applyCustomDate = () => {
    setIsCustomDateApply(true);
    setOrderByApi('company_name');
    setOrderBy({
      utilization: false,
      max_shipment: false,
      open_shipment: false,
      on_hand: false,
      company_name: true,
    });

    setCustomDateState(selectedCustomDateState);
    setShowCustomDateModal(false);
    onGetRestockData(
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedStorageType,
      selectedManagerUser,
      selectedUser,
      selectedTimeFrame,
      selectedCustomDateState,
      orderByApi,
      restockPageNumber,
    );
    onGetStrandedInventoryData(
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedManagerUser,
      selectedUser,
      selectedTimeFrame,
      selectedCustomDateState,
      strandedInventoryOrderByApi,
      strandedInventoryPageNumber,
    );
  };

  // handle bgs manager/ad team managerfilter events
  const handleManagerUserFilterEvents = (event, action) => {
    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedManagerUser,
      managerUserApiList,
    );
    setSelectedManagerUser(selectedValues);
    setIsManagerUsersSelected(true);
  };

  // handle bgs manager/ad team manager on dropdwon menu close events
  const handleManagerUserOnMenuCloseEvents = () => {
    if (isManagerUsersSelected) {
      const managerUsers =
        selectedManagerUser?.length > 0 ? selectedManagerUser : managerUserList;
      setSelectedManagerUser(managerUsers);
      const isAllUser = managerUsers?.some((user) => user?.value === 'all');
      const users = allObj;

      if (isBGSAdmin || isSpreetailRole) {
        if (isAllUser) {
          getBgsList('BGS', null, users, 'managerUserFilter');
        } else {
          getBgsList('BGS', managerUsers, users, 'managerUserFilter');
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI(
        'managerUsersFilter',
        isAllUser ? allObj : managerUsers,
        users,
        null,
      );
    }
  };

  // handle bgs/ad manager filter events
  const handleBgsUserFilterEvents = (event, action) => {
    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedUser,
      userApiList,
    );

    setSelectedUser(selectedValues);
    setIsUsersSelected(true);
  };

  // handle bgs/ad manager on dropdwon menu close events
  const handleUserOnMenuCloseEvents = () => {
    if (isUsersSelected) {
      let managerUsers = selectedManagerUser;
      const users = selectedUser?.length > 0 ? selectedUser : userList;

      setSelectedUser(users);

      // When bgs manager and ad team manager filter set to all then Automatically set the bgs manager or ad team manager assigned to selected bgs or ad team manager
      if ((isBGSAdmin || isSpreetailRole) && selectedUser?.length === 1) {
        const userExisted = userList?.find(
          (bgsUser) =>
            bgsUser?.value === selectedUser?.[0]?.value &&
            bgsUser?.managerUser !== null,
        );

        if (userExisted !== undefined) {
          const managerUserObject = {
            value: userExisted?.managerUser?.id,
            label: `${userExisted?.managerUser?.first_name} ${userExisted?.managerUser?.last_name}`,
          };
          managerUsers = [managerUserObject];
          setSelectedManagerUser([managerUserObject]);
          if (isBGSAdmin || isSpreetailRole) {
            getBgsList('BGS', managerUsers, users, 'bgsFilter');
          }
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI('usersFilter', managerUsers, users, null);
    }
  };

  // handle StorageType Filter Events
  const handleStorageTypeFilterEvents = (event, action) => {
    const optionsWithoutAll = storageTypeOptions?.filter(
      (option) => option?.value !== 'all',
    );

    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedStorageType,
      optionsWithoutAll,
    );

    setSelectedStorageType(selectedValues);
    setIsStorageTypeSelected(true);
  };

  // handle storage type filter on menu close events
  const handleStorageTypeFilterOnMenuCloseEvents = () => {
    if (isStorageTypeSelected) {
      const stotageType =
        selectedStorageType?.length > 0
          ? selectedStorageType
          : storageTypeOptions;

      onGetRestockData(
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        stotageType,
        selectedManagerUser,
        selectedUser,
        selectedTimeFrame,
        selectedCustomDateState,
        orderByApi,
        restockPageNumber,
      );
    }
  };

  // handle reset filter events
  const handleResetFilter = () => {
    // Reset the LHS filters
    setSelectedMarketplace(marketplaceChoices?.[0]);
    ResetFiltersToDefaultStates();
    setSelectedBrandPartner(allBrandPartnerObj);
    setOrderBy(defaultRestockInventoryOrderBy);
    setOrderByApi(defaultRestockOrderByApi);
    setStrandedInventoryOrderBy(defaultStrandedInventoryOrderBy);
    setStrandedInventoryOrderByApi(defaultStrandedOrderByKey);

    // set assigned bgs manager/ad team manager, bgs/ad manager of selected BP to multi-select bgs manager/ad team manager, bgs/ad manager dropdown filter.
    const managerUser = isBGSManager ? loggedInUser : allObj;
    const user = isBGS ? loggedInUser : allObj;

    setSelectedUser(user);
    setSelectedManagerUser(managerUser);
    // calling Leadership-customer api
    getLeadershipBrandPartnersList(
      'resetFilter',
      managerUser,
      user,
      allBrandPartnerObj,
    );
    // calling bgs and advertising user api
    callingBgsAndAdUserApi('resetFilter', managerUser, user);

    // calling dashboard api
    onGetRestockData(
      'all',
      'all',
      selectedStorageType,
      managerUser,
      user,
      defaultTimeFrameForInventory,
      currentDate,
      defaultRestockOrderByApi,
      1,
    );

    onGetStrandedInventoryData(
      'all',
      'all',
      managerUser,
      user,
      defaultTimeFrameForInventory,
      currentDate,
      defaultStrandedOrderByKey,
      1,
    );
  };

  // handle on click order of restock limit inventory
  const handleOnClickOrderOfRestockLimit = (value) => {
    const finalOrder = {
      utilization: false,
      max_shipment: false,
      open_shipment: false,
      on_hand: false,
    };
    let orderByApiKey = orderByApi;
    for (const property in orderBy) {
      if (value === property) {
        orderByApiKey = orderBy[property] ? value : `-${value}`;
        setOrderByApi(orderByApiKey);
        finalOrder[property] = !orderBy[property];
      }
    }

    setOrderBy(finalOrder);

    // calling dashboard api
    onGetRestockData(
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedStorageType,
      selectedManagerUser,
      selectedUser,
      selectedTimeFrame,
      customDateState,
      orderByApiKey,
      restockPageNumber,
    );
  };

  // handle on click order of Stranded And Restock Inventory
  const handleOnClickOrderOfStrandedAndRestockInventory = (value) => {
    const finalOrder = isRestockInventory
      ? {
          understocked: false,
          overstocked: false,
          available: false,
          company_name: true,
        }
      : {
          available: false,
          sku_change: false,
          percent_change: false,
        };
    let orderByApiKey = strandedInventoryOrderByApi;
    for (const property in strandedInventoryOrderBy) {
      if (value === property) {
        orderByApiKey = strandedInventoryOrderBy[property]
          ? value
          : `-${value}`;

        setStrandedInventoryOrderByApi(orderByApiKey);
        finalOrder[property] = !strandedInventoryOrderBy[property];
      }
    }

    setStrandedInventoryOrderBy(finalOrder);

    // calling dashboard api calls
    onGetStrandedInventoryData(
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedManagerUser,
      selectedUser,
      selectedTimeFrame,
      customDateState,
      orderByApiKey,
      strandedInventoryPageNumber,
    );
  };

  // handle restock inventory pagination events
  const handleRestockInventoryPagination = (currentPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setRestockPageNumber(currentPage);
    // calling dashboard api calls
    onGetRestockData(
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedStorageType,
      selectedManagerUser,
      selectedUser,
      selectedTimeFrame,
      customDateState,
      orderByApi,
      currentPage,
    );
  };

  // handle restock inventory pagination events
  const handleStrandedInventoryPagination = (currentPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setStrandedInventoryPageNumber(currentPage);

    // calling dashboard api calls
    onGetStrandedInventoryData(
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedManagerUser,
      selectedUser,
      selectedTimeFrame,
      customDateState,
      strandedInventoryOrderByApi,
      currentPage,
    );
  };

  // render storage type filter
  const renderStorageTypeFilter = () => {
    const isApiCall = restockLoader || strandedInventoryLoader;
    return (
      <div id="BT-InventoryStorageTypeFilter" style={{ position: 'relative' }}>
        <MultiSelectSearchableDropdown
          filterId="BT-StoragetypeFilter"
          options={storageTypeOptions?.filter(
            (option) => option?.value !== 'all',
          )}
          label=""
          value="storage"
          multiSelectedValues={{ storage: selectedStorageType }}
          setMultiSelectedValues={setSelectedStorageType}
          handleMultiSelectChange={handleStorageTypeFilterEvents}
          handleOnMenuClose={handleStorageTypeFilterOnMenuCloseEvents}
          isLoading={isApiCall}
          isSearchable={false}
        />
      </div>
    );
  };

  // display inventory filter section
  const displayInventoryFilterSection = () => {
    return (
      <InventoryFilter
        // boolean
        isApiCall={restockLoader || strandedInventoryLoader}
        isBGSAdmin={isBGSAdmin}
        isSpreetailRole={isSpreetailRole}
        isBGSManager={isBGSManager}
        isCustomDateApply={isCustomDateApply}
        // objects
        customDateState={customDateState}
        selectedTimeFrame={selectedTimeFrame}
        selectedMarketplace={selectedMarketplace}
        selectedPerformanceType={selectedPerformanceType}
        selectedBrandPartner={selectedBrandPartner}
        // array
        userList={userList}
        managerUserList={managerUserList}
        selectedUser={selectedUser}
        selectedManagerUser={selectedManagerUser}
        marketplaceOptions={marketplaceOptions}
        brandPartnerOptions={brandPartnerOptions}
        // function
        handleDailyFact={handleTimeFrame}
        handleResetFilter={handleResetFilter}
        handleMarketplace={handleMarketplace}
        setSelectedUser={setSelectedUser}
        setSelectedManagerUser={setSelectedManagerUser}
        handleBgsUserFilterEvents={handleBgsUserFilterEvents}
        handleUserOnMenuCloseEvents={handleUserOnMenuCloseEvents}
        handleManagerUserFilterEvents={handleManagerUserFilterEvents}
        handleManagerUserOnMenuCloseEvents={handleManagerUserOnMenuCloseEvents}
        handleSellerVendorFilterEvents={handleSellerVendorFilterEvents}
        handleBrandPartnerFilterEvents={handleBrandPartnerFilterEvents}
      />
    );
  };

  // render modals
  const renderModals = () => {
    return (
      <CustomSingleDateModal
        id="BT-inventoryDashboard-daterange"
        isOpen={showCustomDateModal}
        selectedDate={selectedCustomDateState}
        minDate={inventoryMindate}
        onClick={() => {
          setShowCustomDateModal(false);
          if (selectedTimeFrame.value !== CUSTOM)
            setSelectedCustomDateState(dataAvailableDate);
        }}
        onChange={(item) => {
          setSelectedCustomDateState(item);
        }}
        onApply={() => applyCustomDate()}
        currentDate={dataAvailableDate}
      />
    );
  };

  // display inventory right hand side section
  const displayInventoryRHSSection = () => {
    return (
      <InventoryRightHandSideSection
        // number
        restockPageNumber={restockPageNumber}
        restockCount={restockCount}
        strandedInventoryCount={strandedInventoryCount}
        strandedInventoryPageNumber={strandedInventoryPageNumber}
        // string
        orderBy={orderBy}
        orderByApi={orderByApi}
        strandedInventoryOrderBy={strandedInventoryOrderBy}
        strandedInventoryOrderByApi={strandedInventoryOrderByApi}
        // boolean
        restockLoader={restockLoader}
        strandedInventoryLoader={strandedInventoryLoader}
        // object
        userInfo={userInfo}
        selectedTimeFrame={selectedTimeFrame}
        // array
        restockData={restockData}
        strandedInventoryData={strandedInventoryData}
        // function
        handleDownloadBtn={handleDownloadBtn}
        downloadReportLoader={downloadReportLoader}
        renderStorageTypeFilter={renderStorageTypeFilter}
        handleOnClickOrderOfRestockLimit={handleOnClickOrderOfRestockLimit}
        handleRestockInventoryPagination={handleRestockInventoryPagination}
        handleStrandedInventoryPagination={handleStrandedInventoryPagination}
        handleOnClickOrderOfStrandedAndRestockInventory={
          handleOnClickOrderOfStrandedAndRestockInventory
        }
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <InventoryStyle>
      <div className="row mt-3">
        <div className="col-xl-2 col-lg-3 col-md-12 col-12">
          {displayInventoryFilterSection()}
        </div>
        <div className="col-xl-10 col-lg-9 col-md-12 col-12">
          {displayInventoryRHSSection()}
        </div>
        {renderModals()}
      </div>
    </InventoryStyle>
  );
};

export default InventoryDashboardContainer;

InventoryDashboardContainer.defaultProps = {
  // boolean
  isBGS: false,
  isBGSAdmin: false,
  isBGSManager: false,
  isSpreetailRole: false,
  includeInactiveBPs: false,
  includeSpreetailBrands: false,
  // object
  userInfo: {},
  selectedMarketplace: {},
  selectedBrandPartner: {},
  // array
  loggedInUser: [],
  selectedUser: [],
  selectedManagerUser: [],
  marketplaceChoices: [],
  // function
  setSelectedUser: () => {},
  checkIsUserExist: () => {},
  bindUserListData: () => {},
  bindBrandPartners: () => {},
  setSelectedMarketplace: () => {},
  setSelectedManagerUser: () => {},
  generateStringOfValues: () => {},
  setSelectedBrandPartner: () => {},
  handleMultiSelectDropdownFilterEvents: () => {},
};

InventoryDashboardContainer.propTypes = {
  // boolean
  isBGS: bool,
  isBGSAdmin: bool,
  isBGSManager: bool,
  isSpreetailRole: bool,
  includeInactiveBPs: bool,
  includeSpreetailBrands: bool,
  // object
  userInfo: shape({}),
  selectedMarketplace: shape({}),
  selectedBrandPartner: shape({}),
  // array
  loggedInUser: arrayOf(shape({})),
  selectedUser: arrayOf(shape({})),
  selectedManagerUser: arrayOf(shape({})),
  marketplaceChoices: arrayOf(shape({})),
  // function
  setSelectedUser: func,
  checkIsUserExist: func,
  bindUserListData: func,
  bindBrandPartners: func,
  setSelectedMarketplace: func,
  setSelectedManagerUser: func,
  generateStringOfValues: func,
  setSelectedBrandPartner: func,
  handleMultiSelectDropdownFilterEvents: func,
};
