import React from 'react';

import { arrayOf, bool, func, shape } from 'prop-types';

import { PARENT } from '../../../../../constants';
import {
  WhiteCard,
  ViewData,
  DropdownIndicator,
  DropDowns,
  DropdownOptions,
  DropdownSingleValue,
} from '../../../../../common';

const AdPerformanceFilters = ({
  dspPpcFlag,
  marketplaceOptions,
  handleMarketplaceOptions,
  isApiCall,
  selectedMarketplace,
  customerState,
  childBrandOptions,
  selectedChildBrand,
  setSelectedChildBrand,
  displayAccountTypeFilter,
}) => {
  // get dropdown components
  const getSelectComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: DropdownSingleValue,
      DropdownIndicator,
    };
  };

  const handleChildBrandOptions = (event) => {
    if (event.value !== selectedChildBrand?.value) {
      setSelectedChildBrand(event);
    }
  };

  return (
    <WhiteCard className="mb-3">
      <ViewData>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="view-data-for mt-2">View data for:</div>
          </div>
          <div className="col-12 col-md-8">
            <div className="row">
              {customerState?.account_type === PARENT ? (
                <div className="col-md-4 col-12 pl-md-0 mb-3 mb-xl-0">
                  {DropDowns(
                    'BT-adperformancedata-childBrandFilter',
                    'cursor',
                    childBrandOptions,
                    selectedChildBrand?.label,
                    getSelectComponents,
                    '',
                    handleChildBrandOptions,
                    isApiCall,
                    null,
                    selectedChildBrand,
                  )}
                </div>
              ) : (
                <div className="col-md-4 col-12 pl-md-0 mb-3 mb-xl-0" />
              )}
              <div className="col-md-4 col-12 pl-md-0 mb-3 mb-xl-0">
                {displayAccountTypeFilter(isApiCall)}
              </div>
              {dspPpcFlag?.isHavingDsp && !dspPpcFlag?.isHavingPpc ? null : (
                <div className="col-md-4 col-12 pl-md-0 mb-3 mb-xl-0">
                  {DropDowns(
                    'BT-adperformancedata-countryfilter',
                    'cursor ',
                    marketplaceOptions,
                    selectedMarketplace?.label,
                    getSelectComponents,
                    '',
                    handleMarketplaceOptions,
                    isApiCall,
                    null,
                    selectedMarketplace,
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </ViewData>
    </WhiteCard>
  );
};

export default AdPerformanceFilters;

AdPerformanceFilters.defaultProps = {
  marketplaceOptions: {},
  selectedMarketplace: {},
  dspPpcFlag: {},
  isApiCall: false,
  customerState: {},
  childBrandOptions: [],
  selectedChildBrand: {},
  setSelectedChildBrand: () => {},
  handleMarketplaceOptions: () => {},
  displayAccountTypeFilter: () => {},
};

AdPerformanceFilters.propTypes = {
  marketplaceOptions: arrayOf(Array),
  selectedMarketplace: shape({}),
  dspPpcFlag: shape({}),
  isApiCall: bool,
  customerState: shape({}),
  childBrandOptions: arrayOf(shape({})),
  selectedChildBrand: shape({}),
  setSelectedChildBrand: func,
  handleMarketplaceOptions: func,
  displayAccountTypeFilter: func,
};
