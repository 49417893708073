import React, { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import Theme from '../../../../../../theme/Theme';
import useValueWithChangeAndPercent from '../../../../../../hooks/useValueWithChangeAndPercent';
import { getSponsoredAdAsinPerformanceData } from '../../../../../../api';
import {
  asinStatiMessages,
  sponsoredAsinTableConstant,
} from '../../../../../../constants';
import {
  CommonPagination,
  DesktopTable,
  NoData,
  PageLoader,
  VisibleScrollableTable,
} from '../../../../../../common';

function SponsoredAsinPerformance({
  isCallSPAsinApi,
  downloadReportLoader,
  asinPerformanceLoader,
  customerId,
  childBrandId,
  selectedDF,
  accountType,
  currencySymbol,
  selectedMarketplace,
  sponsoredAdType,
  adState,
  setIsCallSPAsinApi,
  setDownloadReportLoader,
  setAsinPerformanceLoader,
  displayProductNameWithTooltip,
  displaySponsoredAsinTable,
  bindSpAsinPerformanceData,
}) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isCustom = selectedDF === 'custom';
  const [orderBy, setOrderBy] = useState('ad_sales');
  const [orderByKey, setOrderByKey] = useState('-ad_sales');
  const [pageCount, setPageCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [fieldsetCollapse, setFieldsetCollapse] = useState({
    id: 0,
    isOpen: true,
  });
  const [sponsoredAsinData, setSponsoredAsinData] = useState([]);
  const [asinMessage, setAsinMessage] = useState(
    asinStatiMessages?.asinDataAvailabilityMessage,
  );

  // callback function for get sposored ad asin performance data
  const getAsinPerformanceData = useCallback(
    (orderByApiKey, page, startDate, endDate) => {
      setAsinPerformanceLoader(true);
      setSponsoredAsinData([]);

      const startDateLocal = dayjs(startDate).format('DD-MM-YYYY');
      const endDateLocal = dayjs(endDate).format('DD-MM-YYYY');

      // PDV-8401 - Logic - point no. 2 - when user select custom end date 4 months before today
      const fourMonthBeforeToday = new Date();
      fourMonthBeforeToday.setMonth(fourMonthBeforeToday.getMonth() - 4);

      if (endDate <= fourMonthBeforeToday) {
        setSponsoredAsinData([]);
        setAsinMessage('Available historical data is up to 4 months only');
        setAsinPerformanceLoader(false);
      } else {
        getSponsoredAdAsinPerformanceData(
          customerId,
          accountType,
          selectedMarketplace,
          sponsoredAdType,
          orderByApiKey,
          page,
          selectedDF,
          startDateLocal,
          endDateLocal,
          childBrandId,
          null,
        ).then((response) => {
          if (response?.status === 200 && response?.data?.results?.length > 0) {
            setPageCount(response?.data?.count);
            setSponsoredAsinData(
              bindSpAsinPerformanceData(response?.data?.results),
            );
          } else {
            setSponsoredAsinData([]);
            setAsinMessage(asinStatiMessages?.asinDataAvailabilityMessage);
          }

          setAsinPerformanceLoader(false);
        });
      }
    },

    [
      accountType,
      bindSpAsinPerformanceData,
      childBrandId,
      customerId,
      selectedDF,
      selectedMarketplace,
      setAsinPerformanceLoader,
      sponsoredAdType,
    ],
  );

  // use effect
  useEffect(() => {
    if (!isCallSPAsinApi) {
      getAsinPerformanceData(
        orderByKey,
        1,
        adState?.[0]?.startDate,
        adState?.[0]?.endDate,
      );
    }
    setOrderBy('ad_sales');
    setOrderByKey('-ad_sales');
    setPageNumber(1);
    setIsCallSPAsinApi(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAsinPerformanceData, isCallSPAsinApi]);

  // const handle download report events
  const handleDownloadReportEvents = useCallback(
    (
      selectedAccountType,
      marketplace,
      spAdType,
      timeframe,
      orderByApiKey,
      customDate,
      selectedChildBrand,
    ) => {
      const startDate = dayjs(customDate?.[0]?.startDate).format('DD-MM-YYYY');
      const endDate = dayjs(customDate?.[0]?.endDate).format('DD-MM-YYYY');

      getSponsoredAdAsinPerformanceData(
        customerId,
        selectedAccountType,
        marketplace,
        spAdType,
        orderByApiKey,
        null,
        timeframe,
        startDate,
        endDate,
        selectedChildBrand,
        true,
      ).then((response) => {
        if (response?.status === 200) {
          const csvLink = response?.data?.url;
          if (csvLink) window.open(csvLink);
          if (response?.data?.message) toast.success(response?.data?.message);
          setDownloadReportLoader(false);
        } else {
          toast.error('Oops! Something went wrong...');
          setDownloadReportLoader(false);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (downloadReportLoader) {
      handleDownloadReportEvents(
        accountType,
        selectedMarketplace,
        sponsoredAdType,
        selectedDF,
        orderByKey,
        adState,
        childBrandId,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adState, downloadReportLoader, handleDownloadReportEvents, selectedDF]);

  // handle sorting events of asin table
  const handleSortingEvents = (key) => {
    let orderByApiKey = key;
    setOrderBy(key);
    if (orderByKey === `-${key}`) {
      setOrderByKey(key);
      orderByApiKey = key;
    } else {
      setOrderByKey(`-${key}`);
      orderByApiKey = `-${key}`;
    }
    getAsinPerformanceData(
      orderByApiKey,
      pageNumber,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
    );
  };

  // handle fieldset collapse events
  const handleFieldsetCollapse = (id) => {
    if (id === fieldsetCollapse?.id) {
      if (fieldsetCollapse?.isOpen) {
        setFieldsetCollapse({ id, isOpen: false });
      } else {
        setFieldsetCollapse({ id, isOpen: true });
      }
    } else {
      setFieldsetCollapse({ id, isOpen: true });
    }
  };

  // handle pagination events
  const handlePaginationEvents = (currentPage) => {
    setPageNumber(currentPage);
    getAsinPerformanceData(
      orderByKey,
      currentPage,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
    );
  };

  // display sortable up and down arrow icon
  const displaySortableUpDownArrow = (key) => {
    return (
      <FontAwesomeIcon
        className="ml-2 cursor"
        color={orderBy === key ? Theme.orange : Theme.gray40}
        icon={`far ${
          orderByKey === `-${key}` ? 'fa-chevron-up' : 'fa-chevron-down'
        }`}
        style={{
          fontSize: '12px',
          verticalAlign: isTablet ? 'bottom' : 'middle',
        }}
      />
    );
  };

  // display asin performance table
  const DisplayAsinPerformnaceTable = (numberFormatter) => {
    const tableDivId = 'visible-scrollable-table';
    const tableMinWidth =
      sponsoredAdType === 'sponsored_display_campaign_report'
        ? '2600px'
        : '1800px';
    const tableCols =
      sponsoredAdType === 'sponsored_display_campaign_report'
        ? sponsoredAsinTableConstant
        : sponsoredAsinTableConstant.slice(0, 12);
    return (
      <VisibleScrollableTable id={tableDivId} tableMinWidth={tableMinWidth}>
        <DesktopTable
          className="metrics-table"
          style={{ tableLayout: 'fixed' }}
        >
          <thead>
            <tr>
              {tableCols?.map((item) => {
                return (
                  <th
                    className={`${item?.isSortable ? 'cursor' : ''} ${
                      item?.className
                    }`}
                    key={item?.key}
                    onClick={() => {
                      if (item?.isSortable) handleSortingEvents(item?.key);
                    }}
                    style={{ width: item?.width }}
                  >
                    {item?.label}
                    {item?.isSortable
                      ? displaySortableUpDownArrow(item?.key)
                      : null}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {sponsoredAsinData?.map((item, index) => {
              return displaySponsoredAsinTable(item, index, numberFormatter);
            })}
          </tbody>
        </DesktopTable>
      </VisibleScrollableTable>
    );
  };

  // display asin performance mobile view
  const displayAsinPerformanceMobileView = (numberFormatter) => {
    return (
      <div className="mt-3">
        {sponsoredAsinData?.map((item, index) => {
          const openCollapse =
            fieldsetCollapse?.id === index && fieldsetCollapse?.isOpen;
          return (
            <div className="pt-2">
              <fieldset className="performance-fielset mb-4">
                <div className="row">
                  <div className="col-5 pr-0">
                    <div className="label">Asin</div>
                    <div className="normal-text-black text-medium">
                      {item?.asin}
                    </div>
                  </div>
                  <div className="col-7 text-right">
                    <div className="label">Product Name</div>
                    <div
                      className="normal-text-black text-medium"
                      style={{ position: 'relative' }}
                    >
                      {displayProductNameWithTooltip(
                        item?.productName,
                        index,
                        item?.pdpLink,
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6 pr-0">
                    <div className="label">
                      Ad Spend {displaySortableUpDownArrow('ad_spend')}
                    </div>
                    <div className="normal-text-black text-medium">
                      {numberFormatter(
                        item?.adSpendCurrent,
                        item?.adSpendChangeInValue,
                        item?.adSpendChangeInPercent,
                        2,
                        isCustom,
                        currencySymbol,
                        '',
                      )}
                    </div>
                  </div>
                  <div className="col-6 text-right">
                    <div className="label">
                      Ad Sales {displaySortableUpDownArrow('ad_sales')}
                    </div>
                    <div className="normal-text-black text-medium">
                      {numberFormatter(
                        item?.adSalesCurrent,
                        item?.adSalesChangeInValue,
                        item?.adSalesChangeInPercent,
                        2,
                        isCustom,
                        currencySymbol,
                        '',
                      )}
                    </div>
                  </div>
                </div>
                {openCollapse ? (
                  <div className="fieldset-collapse">
                    <div className="row mt-3">
                      <div className="col-6 pr-0">
                        <div className="label">
                          Acos {displaySortableUpDownArrow('acos')}
                        </div>
                        <div className="normal-text-black text-medium">
                          {numberFormatter(
                            item?.acosCurrent,
                            item?.acosChangeInValue,
                            item?.acosChangeInPercent,
                            2,
                            isCustom,
                            '',
                            '%',
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-right">
                        <div className="label">
                          Roas {displaySortableUpDownArrow('roas')}
                        </div>
                        <div className="normal-text-black text-medium">
                          {numberFormatter(
                            item?.roasCurrent,
                            item?.roasChangeInValue,
                            item?.roasChangeInPercent,
                            2,
                            isCustom,
                            currencySymbol,
                            '',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6 pr-0">
                        <div className="label">
                          Click Throught Rate
                          {displaySortableUpDownArrow('ctr')}
                        </div>
                        <div className="normal-text-black text-medium">
                          {numberFormatter(
                            item?.ctrCurrent,
                            item?.ctrChangeInValue,
                            item?.ctrChangeInPercent,
                            2,
                            isCustom,
                            '',
                            '%',
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-right">
                        <div className="label">
                          Ad Conversion Rate
                          {displaySortableUpDownArrow('ad_conversion_rate')}
                        </div>
                        <div className="normal-text-black text-medium">
                          {numberFormatter(
                            item?.adConversionCurrent,
                            item?.adConversionChangeInValue,
                            item?.adConversionChangeInPercent,
                            2,
                            isCustom,
                            '',
                            '%',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6 pr-0">
                        <div className="label">
                          Cost Per Clicks{' '}
                          {displaySortableUpDownArrow('cost_per_click')}
                        </div>
                        <div className="normal-text-black text-medium">
                          {numberFormatter(
                            item?.costPerClickCurrent,
                            item?.costPerClickChangeInValue,
                            item?.costPerClickChangeInPercent,
                            2,
                            isCustom,
                            currencySymbol,
                            '',
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-right">
                        <div className="label">
                          Sales Per Clicks{' '}
                          {displaySortableUpDownArrow('sales_per_click')}
                        </div>
                        <div className="normal-text-black text-medium">
                          {numberFormatter(
                            item?.salesPerClickCurrent,
                            item?.salesPerClickChangeInValue,
                            item?.salesPerClickChangeInPercent,
                            2,
                            isCustom,
                            currencySymbol,
                            '',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6 pr-0">
                        <div className="label">
                          Clicks {displaySortableUpDownArrow('clicks')}
                        </div>
                        <div className="normal-text-black text-medium">
                          {numberFormatter(
                            item?.clicksCurrent,
                            item?.clicksChangeInValue,
                            item?.clicksChangeInPercent,
                            2,
                            isCustom,
                            '',
                            '',
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-right">
                        <div className="label">
                          Impressions{' '}
                          {displaySortableUpDownArrow('impressions')}
                        </div>
                        <div className="normal-text-black text-medium">
                          {numberFormatter(
                            item?.impressionsCurrent,
                            item?.impressionsChangeInValue,
                            item?.impressionsChangeInPercent,
                            2,
                            isCustom,
                            '',
                            '',
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <legend
                  role="presentation"
                  className="cursor"
                  onClick={() => {
                    handleFieldsetCollapse(index);
                  }}
                >
                  {openCollapse ? 'View less' : 'View 7 more details'}
                  <FontAwesomeIcon
                    icon="fa-regular fa-angle-down"
                    className="dropdown-arrow-icon"
                    style={{
                      transform: openCollapse ? 'rotate(180deg)' : '',
                      marginLeft: '10px',
                      verticalAlign: 'middle',
                    }}
                  />
                </legend>
              </fieldset>
            </div>
          );
        })}
      </div>
    );
  };

  // display asin performance content
  const displayAsinPerformanceContent = () => {
    return (
      <>
        {asinPerformanceLoader ? (
          <PageLoader
            component="performance-graph"
            color={Theme.orange}
            type="detail"
            width={40}
            height={40}
          />
        ) : sponsoredAsinData?.length > 0 ? (
          <>
            {isTablet
              ? DisplayAsinPerformnaceTable(useValueWithChangeAndPercent)
              : displayAsinPerformanceMobileView(useValueWithChangeAndPercent)}
            {!asinPerformanceLoader && pageCount > 10 ? (
              <>
                <CommonPagination
                  count={pageCount}
                  pageNumber={pageNumber}
                  handlePageChange={handlePaginationEvents}
                />
              </>
            ) : null}
          </>
        ) : (
          <NoData>{asinMessage}</NoData>
        )}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return <div>{displayAsinPerformanceContent()}</div>;
}

export default SponsoredAsinPerformance;

SponsoredAsinPerformance.defaultProps = {
  isCallSPAsinApi: false,
  downloadReportLoader: false,
  asinPerformanceLoader: false,
  childBrandId: null,
  selectedDF: '',
  accountType: '',
  currencySymbol: '$',
  selectedMarketplace: 'all',
  sponsoredAdType: 'all',
  adState: [],
  setIsCallSPAsinApi: () => {},
  setDownloadReportLoader: () => {},
  setAsinPerformanceLoader: () => {},
  displaySponsoredAsinTable: () => {},
  bindSpAsinPerformanceData: () => {},
  displayProductNameWithTooltip: () => {},
};
SponsoredAsinPerformance.propTypes = {
  isCallSPAsinApi: bool,
  downloadReportLoader: bool,
  asinPerformanceLoader: bool,
  childBrandId: string,
  selectedDF: string,
  accountType: string,
  currencySymbol: string,
  selectedMarketplace: string,
  sponsoredAdType: string,
  customerId: string.isRequired,
  adState: arrayOf(shape()),
  setIsCallSPAsinApi: func,
  setDownloadReportLoader: func,
  setAsinPerformanceLoader: func,
  displaySponsoredAsinTable: func,
  bindSpAsinPerformanceData: func,
  displayProductNameWithTooltip: func,
};
