// react imports
import React from 'react';

// third party imports
import styled from 'styled-components';
import { string } from 'prop-types';

// local file imports
import Theme from '../theme/Theme';
import { CompanyDefaultUser } from '../theme/images';

function BrandPartnerProfile({ bpName, assignedUser, logoURL }) {
  /* ********** Main return statement of this component ********** */
  return (
    <ProfileStyle className="bp-profile">
      <img
        className="companyLogo"
        src={logoURL || CompanyDefaultUser}
        alt="bp-logo"
      />
      <div className="companyName mw-80">{bpName}</div>
      <div className="companyStatus">{assignedUser}</div>
      <div className="clear-fix" />
    </ProfileStyle>
  );
}

export default BrandPartnerProfile;

BrandPartnerProfile.defaultProps = {
  bpName: '',
  assignedUser: '',
  logoURL: '',
};
BrandPartnerProfile.propTypes = {
  bpName: string,
  assignedUser: string,
  logoURL: string,
};

const ProfileStyle = styled.div`
  .companyLogo {
    border-radius: 10px;
    width: 45px;
    height: 45px;
    margin-bottom: 8px;
    float: left;
    margin-right: 14px;
    clear: both;
  }

  .companyName {
    vertical-align: middle;
    position: relative;
    color: ${Theme.black};
    font-size: ${Theme.extraMedium};
    font-weight: 600;
    min-height: 20px;
    text-transform: capitalize;

    &.LargecompanyName {
      font-size: ${Theme.title};
      margin-bottom: 10px;
    }
    &.text-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .companyStatus {
    color: ${Theme.gray85};
    font-size: ${Theme.extraNormal};
    font-weight: 300;
    word-break: break-all;
    text-transform: capitalize;
  }
  @media only screen and (min-width: 768px) {
    .mw-80 {
      max-width: 80%;
    }
  }
`;
