import React, { useState, useEffect, useCallback, useRef } from 'react';

import dayjs from 'dayjs';
import Modal from 'react-modal';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useHistory } from 'react-router-dom';
import { bool, func, shape, string } from 'prop-types';
import { toast } from 'react-toastify';

import InvoiceAdjust from './InvoiceAdjust';
import InvoicePause from './InvoicePause';
import InvoiceAdjustConfirm from './InvoiceAdjustPauseConfirm/InvoiceAdjustConfirm';
import Theme from '../../../../../../theme/Theme';
import { CloseIcon, helpCircleIcon } from '../../../../../../theme/images';
import {
  getDSPBudgetAdjustData,
  postDSPBudgetAdjustPauseInvoiceData,
} from '../../../../../../api';
import {
  ModalBox,
  Button,
  Tabs,
  ModalRadioCheck,
  ContractInputSelect,
  DropdownIndicator,
  PageLoader,
  FormField,
} from '../../../../../../common';
import { adjustInvoiceChoices } from '../../../../../../constants/CustomerConstants';
import { PATH_CUSTOMER_DETAILS } from '../../../../../../constants';
import InvoicePauseConfirm from './InvoiceAdjustPauseConfirm/InvoicePauseConfirm';
import useFormatNumber from '../../../../../../hooks/useFormatNumber';

const todaysDate = new Date();
const day = todaysDate.getDate();

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '600px ',
    width: '100% ',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const InvoiceAdjustPauseModal = ({
  id,
  customerId,
  isOpen,
  style,
  onModalClose,
  onApply,
  bpName,
}) => {
  const history = useHistory();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [invoiceInputs, setInvoiceInputs] = useState([]);
  const [invoiceType, setInvoiceType] = useState('standard');
  const [viewComponent, setViewComponent] = useState('adjustInvoice');
  const [monthRange, setMonthRange] = useState(12);
  const [selectedMonthYear, setselectedMonthYear] = useState({
    value: dayjs().format('MMMM YYYY'),
    label: dayjs().format('MMMM YYYY'),
  });
  const mounted = useRef(false);
  const [monthsYears, setMonthsYears] = useState([
    {
      value: dayjs().format('MMMM YYYY'),
      label: dayjs().format('MMMM YYYY'),
    },
  ]);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [dspInvoiceNote, setDspInvoiceNote] = useState('');

  // fetch latest stadandard invoice data
  const getAdjustInvoices = useCallback(
    (date) => {
      setLoader(true);
      setInvoiceInputs([]);

      getDSPBudgetAdjustData(
        invoiceType,
        customerId,
        viewComponent,
        dayjs(date).format('YYYY-MM-DD'),
      ).then((res) => {
        if (mounted.current) {
          if (res && res.status === 200) {
            setCurrencySymbol(
              getSymbolFromCurrency(
                res?.data?.global_currency ? res?.data?.global_currency : 'USD',
              ),
            );

            // PDV-8701  restric user to select future month- its as per contract end date (BE key- max_end_date)
            const newDate = new Date();
            const maxEndDate = new Date(res?.data?.max_end_date);

            // PDV-9118 -fixed time zone issue- if it's MST time zone then added 12 hr. in that date
            // so that we will get accurate date.
            if (maxEndDate && maxEndDate?.getTimezoneOffset() > 0) {
              maxEndDate.setHours(maxEndDate?.getHours() + 12);
            }

            let totalMonths = 1;

            if (
              maxEndDate !== null &&
              maxEndDate !== undefined &&
              res?.data?.max_end_date
            ) {
              totalMonths =
                maxEndDate.getMonth() -
                newDate.getMonth() +
                12 * (maxEndDate.getFullYear() - newDate.getFullYear());

              if (invoiceType !== 'standard') {
                // for one time invoice
                totalMonths += 1;
              } else {
                //  for standard invoice
                totalMonths -= 1;
              }
            }

            setMonthRange(totalMonths <= 0 ? 1 : totalMonths);
            setInvoiceInputs(res?.data?.adjustments);
            // https://bbe.atlassian.net/browse/PDV-6849
            setDspInvoiceNote(res?.data?.notes || '');
            setLoader(false);
          } else {
            setLoader(false);
          }
          setLoader(false);
        }
      });
    },
    [customerId, invoiceType, viewComponent],
  );

  const returnMonthStart = useCallback(
    (monthIndex) => {
      if (invoiceType !== 'standard') {
        return monthIndex - 1;
      }
      if (day > 10 && invoiceType === 'standard') {
        return monthIndex + 1;
      }
      return monthIndex - 0;
    },
    [invoiceType],
  );

  // PDV-8701  restric user to select future month- its as per contract end date (BE key- max_end_date)
  useEffect(() => {
    mounted.current = true;
    getAdjustInvoices(
      dayjs().add(returnMonthStart(1), 'M').format('MMMM YYYY'),
    );
    return () => {
      mounted.current = false;
    };
  }, [getAdjustInvoices, returnMonthStart]);

  // add Month year (from currrent month to 12 months) data for applies from dropdown
  useEffect(() => {
    mounted.current = true;
    const monthsDropDown = [];
    for (let monthIndex = 1; monthIndex <= monthRange; monthIndex += 1) {
      monthsDropDown.push({
        value: dayjs()
          .add(returnMonthStart(monthIndex), 'M')
          .format('MMMM YYYY'),
        label: dayjs()
          .add(returnMonthStart(monthIndex), 'M')
          .format('MMMM YYYY'),
      });
    }
    setMonthsYears(monthsDropDown);
    setselectedMonthYear(monthsDropDown[0]);
    return () => {
      mounted.current = false;
    };
  }, [monthRange, returnMonthStart]);

  const renderToastMessage = useCallback(
    (message) => {
      toast.success(message);
      setTimeout(() => {
        history.push(
          PATH_CUSTOMER_DETAILS.replace(':id', customerId),
          'dsp service',
        );
        onApply(true);
      }, 2000);
    },
    [customerId, history, onApply],
  );

  // submit standard, onetime & pause invoice
  const onSendDSPBudgetAdjustInvoice = useCallback(() => {
    const toastMsg = `${bpName} invoice ${
      viewComponent === 'adjustInvoice' ? 'Adjustment created' : 'Pause'
    } successfully for ${selectedMonthYear.value}`;
    setLoader(true);
    postDSPBudgetAdjustPauseInvoiceData(
      invoiceInputs,
      selectedMonthYear,
      invoiceType,
      customerId,
      viewComponent === 'adjustInvoice' ? 'adjust' : 'pause',
      dspInvoiceNote,
    ).then((res) => {
      if (res && res.status === 500) {
        setLoader(false);
        toast.error('Something went wrong');
      }
      if (res && res.status === 400) {
        setLoader(false);
        toast.error('Something went wrong');
      }
      if (res && res.status === 201) {
        renderToastMessage(toastMsg);
        setLoader(false);
      }
      setLoader(false);
    });
  }, [
    bpName,
    customerId,
    dspInvoiceNote,
    invoiceInputs,
    invoiceType,
    renderToastMessage,
    selectedMonthYear,
    viewComponent,
  ]);

  const onSendInvoice = () => {
    onSendDSPBudgetAdjustInvoice();
    onApply();
  };

  // Removed commas & convert  string to number
  const parseNumber = (value) => {
    return value
      ? isNaN(Number(value.toString().replace(/,/g, '')))
        ? 0
        : Number(value.toString().replace(/,/g, ''))
      : 0;
  };

  // based on user filled the inputs calculate total for exstitng  amoount & new amount total.
  const returnTotalAmount = useCallback(() => {
    if (invoiceInputs && invoiceInputs.length > 0) {
      const totalAmount = { totalCurrentBudget: 0, totalNewBudget: 0 };
      invoiceInputs.forEach((item) => {
        if (item && item.new_budget && ['standard'].includes(invoiceType)) {
          totalAmount.totalCurrentBudget += parseNumber(
            item.new_budget.toString(),
          );
        }
        if (Object.prototype.hasOwnProperty.call(item, 'newAmount')) {
          totalAmount.totalNewBudget += parseNumber(
            item.newAmount === '' && ['standard'].includes(invoiceType)
              ? item.new_budget
              : item.newAmount,
          );
        } else if (
          Object.prototype.hasOwnProperty.call(item, 'new_budget') &&
          ['standard', 'permanent additional'].includes(invoiceType)
        ) {
          totalAmount.totalNewBudget += parseNumber(item.new_budget.toString());
        }
      });

      return totalAmount;
    }
    return 0;
  }, [invoiceInputs, invoiceType]);

  const renderSubmitAndPauseTab = () => {
    return (
      <Tabs className="mt-3">
        {' '}
        <ul className="tabs">
          <li
            className={`modal-tab ${
              viewComponent === 'adjustInvoice'
                ? 'active'
                : loader
                ? 'disabled'
                : ''
            }`}
            role="presentation"
            onClick={() => {
              setViewComponent('adjustInvoice');
            }}
          >
            Submit Invoice
          </li>
          <li
            className={`modal-tab ${
              viewComponent === 'pauseInvoice'
                ? 'active'
                : loader
                ? 'disabled'
                : ''
            }`}
            role="presentation"
            onClick={() => {
              setInvoiceType('standard');
              setViewComponent('pauseInvoice');
            }}
          >
            Pause Invoice
          </li>
        </ul>
      </Tabs>
    );
  };

  const renderStandardAndOneTimeOptions = () => {
    return (
      <>
        {viewComponent === 'adjustInvoice' ? (
          <ul className="invoice-adj-radio mt-4">
            {adjustInvoiceChoices.map((item) => {
              return (
                <li key={item.id}>
                  <ModalRadioCheck className="mb-2">
                    <label
                      className=" checkboxes radio-container customer-list"
                      htmlFor={item.id}
                    >
                      <input
                        type="radio"
                        name={item.name}
                        checked={invoiceType === item.name}
                        onChange={(e) => {
                          setInvoiceType(e.target.name);
                        }}
                        id={item.id}
                      />
                      <span className="checkmark checkmark-customer-list" />
                      {item.label}
                    </label>
                  </ModalRadioCheck>
                </li>
              );
            })}
          </ul>
        ) : null}
      </>
    );
  };

  const renderMonthYearDropDown = () => {
    return (
      <div className=" pb-1 pt-3 mb-2">
        <ContractInputSelect>
          <label htmlFor="amount">
            applies from{' '}
            <img
              src={helpCircleIcon}
              alt="helpCircleIcon"
              style={{
                width: '14px',
                verticalAlign: 'middle',
                paddingBottom: '3px',
              }}
              data-tip="Current value will be populated <br> based on selected month "
              data-for="dspAdditionalInfo"
            />
            <ReactTooltip
              id="dspAdditionalInfo"
              aria-haspopup="true"
              place="bottom"
              effect="solid"
              html
            />
          </label>
          {monthsYears && (
            <Select
              classNamePrefix="react-select"
              isSearchable={false}
              defaultValue={monthsYears[0]}
              value={selectedMonthYear}
              options={monthsYears}
              name="applies_month_year"
              components={{ DropdownIndicator }}
              onChange={(event) => {
                getAdjustInvoices(event.value);
                setselectedMonthYear(event);
              }}
              placeholder={monthsYears[0].label}
            />
          )}
        </ContractInputSelect>
      </div>
    );
  };

  // https://bbe.atlassian.net/browse/PDV-6849 - Enable Next user to add a note in the DSP invoice request to be added in the next DSP invoice
  // displat text area for invoice note
  const displayInvoiceNoteTextArea = () => {
    return (
      <FormField>
        <textarea
          className="text-area dsp-note"
          type="text"
          rows="5"
          name="note"
          placeholder="Add note to the brand partner"
          value={dspInvoiceNote}
          onChange={(event) => {
            setDspInvoiceNote(event?.target?.value);
          }}
        />
      </FormField>
    );
  };

  return (
    <>
      <Modal
        id={id}
        isOpen={isOpen}
        style={{ ...customStyles, ...style }}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={onModalClose}
          role="presentation"
        />
        {!showConfirmationModal ? (
          <ModalBox>
            <div className="modal-body pb-1">
              <h4>Invoice Adjustment</h4>

              {renderSubmitAndPauseTab()}

              {renderStandardAndOneTimeOptions()}

              {loader ? (
                <PageLoader
                  color={Theme.orange}
                  component="performance-graph"
                  type="detail"
                  width={40}
                  height={40}
                />
              ) : null}

              {renderMonthYearDropDown()}
              {/* https://bbe.atlassian.net/browse/PDV-6849  */}
              {viewComponent === 'adjustInvoice'
                ? displayInvoiceNoteTextArea()
                : null}
            </div>

            {!loader ? (
              <div className="inner-body-content mt-3">
                {viewComponent === 'adjustInvoice' ? (
                  <InvoiceAdjust
                    invoiceInputs={invoiceInputs}
                    setInvoiceInputs={setInvoiceInputs}
                    returnTotalAmount={returnTotalAmount}
                    parseNumber={parseNumber}
                    invoiceType={invoiceType}
                    selectedMonthYear={selectedMonthYear}
                    loading={loader}
                    formatNumber={useFormatNumber}
                    currencySymbol={currencySymbol}
                  />
                ) : (
                  <InvoicePause
                    invoiceChoices={invoiceInputs}
                    setInvoiceChoices={setInvoiceInputs}
                    returnTotalAmount={returnTotalAmount}
                    parseNumber={parseNumber}
                    loading={loader}
                    formatNumber={useFormatNumber}
                    currencySymbol={currencySymbol}
                  />
                )}
              </div>
            ) : null}

            <div className="modal-footer">
              <div className="footer-line" />
              <div className="text-center">
                <Button
                  disabled={
                    loader ||
                    returnTotalAmount().totalCurrentBudget ===
                      returnTotalAmount().totalNewBudget
                  }
                  onClick={() => {
                    setShowConfirmationModal(true);
                  }}
                  type="button"
                  className="btn-primary on-boarding   w-100"
                >
                  {loader ? (
                    <PageLoader color={Theme.white} type="button" />
                  ) : (
                    'Continue'
                  )}
                </Button>
              </div>
            </div>
          </ModalBox>
        ) : viewComponent === 'pauseInvoice' ? (
          <InvoicePauseConfirm
            adjustmentData={invoiceInputs}
            onBackClick={() => {
              setShowConfirmationModal(false);
            }}
            returnTotalAmount={returnTotalAmount}
            selectedMonthYear={selectedMonthYear}
            invoiceType={invoiceType}
            onApply={() => {
              onSendInvoice();
            }}
            formatNumber={useFormatNumber}
            currencySymbol={currencySymbol}
          />
        ) : (
          <InvoiceAdjustConfirm
            dspInvoiceNote={dspInvoiceNote}
            adjustmentData={invoiceInputs}
            onBackClick={() => {
              setShowConfirmationModal(false);
            }}
            returnTotalAmount={returnTotalAmount}
            selectedMonthYear={selectedMonthYear}
            invoiceType={invoiceType}
            onApply={() => {
              onSendInvoice();
            }}
            today={day}
            formatNumber={useFormatNumber}
            currencySymbol={currencySymbol}
          />
        )}
      </Modal>
    </>
  );
};

export default InvoiceAdjustPauseModal;

InvoiceAdjustPauseModal.defaultProps = {
  isOpen: false,
  id: '',
  style: {},
  onModalClose: () => {},
  onApply: () => {},
  bpName: '',
};

InvoiceAdjustPauseModal.propTypes = {
  isOpen: bool,
  id: string,
  customerId: string.isRequired,
  style: shape({}),
  onModalClose: func,
  onApply: func,
  bpName: string,
};
