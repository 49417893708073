import React from 'react';

import { bool, func, number, shape, string } from 'prop-types';

import { WhiteCard } from '../../../../../../common';
import { PARENT } from '../../../../../../constants';

const SellerASINPerformanceMobileView = ({
  index,
  isParentASINActive,
  formatNumber,
  asin,
  pdpLink,
  childBpName,
  revenue,
  revenueChange,
  revenuePercentChange,
  traffic,
  trafficChange,
  trafficPercentChange,
  units,
  unitsChange,
  unitsPercentChange,
  conversion,
  conversionChange,
  conversionPercentChange,
  currencySymbol,
  isAppliedCustom,
  productName,
  buyBox,
  buyBoxChange,
  buyBoxPercentChange,
  customerState,
  displayProductNameWithTooltip,
}) => {
  return (
    <WhiteCard className="card-with-border mb-3">
      <div className="row text-medium-font">
        <div className="col-6 mb-3">
          <div className="label">
            {isParentASINActive ? 'Parent ASIN' : 'Child ASIN'}
          </div>
          <div className="normal-text-black ">
            {isParentASINActive
              ? displayProductNameWithTooltip(asin, index, pdpLink, false, true)
              : asin}
          </div>
          {customerState?.account_type === PARENT ? (
            <div className="text-extra-small text-medium-font">
              {childBpName}
            </div>
          ) : null}
        </div>
        {!isParentASINActive ? (
          <div className="col-6 mb-3">
            <div className="label">Product Name</div>
            <div className="normal-text-black">
              {displayProductNameWithTooltip(productName, index)}
            </div>
          </div>
        ) : null}

        <div className="col-6 mb-3">
          <div className="label">Sales</div>
          <div className="normal-text-black ">
            {revenue == null
              ? 'N/A'
              : formatNumber(
                  revenue,
                  isAppliedCustom ? null : revenueChange,
                  isAppliedCustom ? null : revenuePercentChange,
                  2,
                  currencySymbol,
                  '',
                  'small-font mt-1',
                )}
          </div>
        </div>
        <div className="col-6 mb-3">
          <div className="label">Traffic</div>
          <div className="normal-text-black ">
            {traffic == null
              ? 'N/A'
              : formatNumber(
                  traffic,
                  isAppliedCustom ? null : trafficChange,
                  isAppliedCustom ? null : trafficPercentChange,
                  0,
                  '',
                  '',
                  'small-font mt-1',
                )}
          </div>
        </div>
        <div className="col-6 mb-3">
          <div className="label">units</div>
          <div className="normal-text-black ">
            {units == null
              ? 'N/A'
              : formatNumber(
                  units,
                  isAppliedCustom ? null : unitsChange,
                  isAppliedCustom ? null : unitsPercentChange,
                  0,
                  '',
                  '',
                  'small-font mt-1',
                )}
          </div>
        </div>
        <div className="col-6 mb-3">
          <div className="label">Conversion</div>
          <div className="normal-text-black ">
            {conversion == null
              ? 'N/A'
              : formatNumber(
                  conversion,
                  isAppliedCustom ? null : conversionChange,
                  isAppliedCustom ? null : conversionPercentChange,
                  0,
                  '',
                  '%',
                  'small-font mt-1',
                )}
          </div>
        </div>
        <div className="col-6 mb-3">
          <div className="label">Buy Box</div>
          <div className="normal-text-black ">
            {buyBox == null
              ? 'N/A'
              : formatNumber(
                  buyBox,
                  isAppliedCustom ? null : buyBoxChange,
                  isAppliedCustom ? null : buyBoxPercentChange,
                  0,
                  '',
                  '%',
                  'small-font mt-1',
                )}
          </div>
        </div>
      </div>
    </WhiteCard>
  );
};

export default SellerASINPerformanceMobileView;

SellerASINPerformanceMobileView.defaultProps = {
  index: '',
  formatNumber: () => {},
  asin: '',
  pdpLink: '',
  childBpName: '',
  currencySymbol: null,
  revenue: null,
  revenueChange: null,
  revenuePercentChange: null,
  traffic: null,
  trafficChange: null,
  trafficPercentChange: null,
  units: null,
  unitsChange: null,
  unitsPercentChange: null,
  conversion: null,
  conversionChange: null,
  conversionPercentChange: null,
  isAppliedCustom: false,
  customerState: {},
  productName: '',
  buyBox: null,
  buyBoxChange: null,
  buyBoxPercentChange: null,
  displayProductNameWithTooltip: () => {},
};

SellerASINPerformanceMobileView.propTypes = {
  index: number,
  formatNumber: func,
  asin: string,
  pdpLink: string,
  childBpName: string,
  currencySymbol: string,
  revenue: number,
  revenueChange: number,
  revenuePercentChange: number,
  traffic: number,
  trafficChange: number,
  trafficPercentChange: number,
  units: number,
  unitsChange: number,
  unitsPercentChange: number,
  conversion: number,
  conversionChange: number,
  conversionPercentChange: number,
  isParentASINActive: bool.isRequired,
  isAppliedCustom: bool,
  customerState: shape({}),
  productName: string,
  buyBox: number,
  buyBoxChange: number,
  buyBoxPercentChange: number,
  displayProductNameWithTooltip: func,
};
