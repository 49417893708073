/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { shape, func, string } from 'prop-types';

import { InputFormField, ErrorMsg } from '../../../../common';
import {
  monthlyRetainerOptions,
  quarterlyRetainerOptions,
} from '../../../../constants';

export default function RetainerOnly({
  formData,
  handleChange,
  generateHTML,
  section,
  feeStructureErrors,
  selectedCurrency,
}) {
  const retainerOptions = [
    { value: 'Fixed', label: 'Fixed', type: `${section}_retainer_fixed` },
    {
      value: 'quarterly',
      label: 'Quarterly',
      type: `${section}_retainer_quarterly`,
    },
    { value: 'monthly', label: 'Monthly', type: `${section}_retainer_monthly` },
  ];

  const generateRetainerOptions = () => {
    if (
      formData?.fee_structure?.[section]?.retainer_type === 'Fixed' ||
      !formData?.fee_structure?.[section]?.retainer_type
    ) {
      return (
        <InputFormField className="mt-3">
          <div className="input-container">
            <span className="input-icon ">{selectedCurrency} </span>
            {generateHTML({
              key: 'monthly_retainer',
              type: 'number-currency',
              fieldOf: 'retainer',
              isMandatory: true,
              placeholder: 'Enter Fixed Retainer',
            })}
          </div>
        </InputFormField>
      );
    }
    if (formData?.fee_structure?.[section]?.retainer_type === 'quarterly') {
      return (
        <>
          {quarterlyRetainerOptions.map((item) => (
            <InputFormField key={item.key}>
              <div className="row">
                <div className="col-4 pr-0 mt-3">
                  <label
                    className="quartely-threshold"
                    htmlFor="Monthly Retainer"
                  >
                    {item.label}
                  </label>
                </div>
                <div className="col-8">
                  <div className="input-container">
                    <span className="input-icon">{selectedCurrency} </span>
                    {generateHTML(item)}
                  </div>
                </div>
              </div>
            </InputFormField>
          ))}
        </>
      );
    }
    if (formData?.fee_structure?.[section]?.retainer_type === 'monthly') {
      return (
        <>
          {monthlyRetainerOptions.map((item) => (
            <InputFormField key={item.key}>
              <div className="row">
                <div className="col-4 pr-0 mt-3">
                  <label
                    className="quartely-threshold"
                    htmlFor="Monthly Retainer"
                  >
                    {item.label}
                  </label>
                </div>
                <div className="col-8">
                  <div className="input-container">
                    <span className="input-icon">{selectedCurrency} </span>
                    {generateHTML(item)}
                  </div>
                </div>
              </div>
            </InputFormField>
          ))}
        </>
      );
    }

    return null;
  };

  return (
    <>
      <div className="thershold mb-2">
        <ul className="days-tab">
          <>
            {retainerOptions?.map((retainer) => {
              return (
                <li
                  style={{ width: '33.3%' }}
                  key={Math.random()}
                  className={
                    formData?.fee_structure?.[section]?.retainer_type ===
                      retainer.value ||
                    (!formData?.fee_structure?.[section]?.retainer_type &&
                      retainer.value === 'Fixed')
                      ? 'p-0 thresholdChecked'
                      : 'p-0'
                  }
                >
                  <input
                    className="d-none "
                    type="radio"
                    id={retainer.type}
                    name="monthly_retainer"
                    value={retainer.value}
                    onClick={(event) =>
                      handleChange(event, 'retainer_type', section)
                    }
                  />
                  <label
                    className="radio-container customer-list"
                    htmlFor={retainer.type}
                  >
                    {retainer.label}
                  </label>
                </li>
              );
            })}
          </>
        </ul>
      </div>
      {generateRetainerOptions()}
      <ErrorMsg>{feeStructureErrors?.[section]?.monthly_retainer}</ErrorMsg>
      <ErrorMsg>
        {feeStructureErrors?.[section]?.quarterly_monthly_retainer}
      </ErrorMsg>
      <ErrorMsg>
        {feeStructureErrors?.[section]?.monthly_monthly_retainer}
      </ErrorMsg>
    </>
  );
}

RetainerOnly.defaultProps = {
  handleChange: () => {},
  generateHTML: () => {},
  formData: {},
  feeStructureErrors: {},
  selectedCurrency: '$ (USD)',
};

RetainerOnly.propTypes = {
  formData: shape({
    fee_structure: shape({}),
  }),
  handleChange: func,
  generateHTML: func,
  section: string.isRequired,
  feeStructureErrors: shape({}),
  selectedCurrency: string,
};
