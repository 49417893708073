import React, { useState } from 'react';

import { shape, string, func, bool, arrayOf } from 'prop-types';

import {
  getMaketplaceCode,
  getMaketplaceRegion,
} from '../../../Utils/MarketplaceFunctions';
import {
  InputFormField,
  ErrorMsg,
  CheckBox,
  Status,
  Button,
} from '../../../common';
import {
  amazonSellerAccountDetails,
  amazonVendorAccountDetails,
} from '../../../constants';

const SingleAccountMarketplaces = ({
  accountDetails,
  mapSecondaryMarketplaceValues,
  setDefaultForSecondary,
  accountType,
  advertiseForMarketplace,
  sellerMarketplaces,
  vendorMarketplaces,
  apiError,
  mapApiErrors,
  isAuthorized,
  localVendor,
  localSeller,
  accountRedirectURL,
  isChecked,
  authenticationURL,
  customerData,
}) => {
  const [doesntAdvertise, setDoesntAdvertise] = useState(
    advertiseForMarketplace,
  );

  const arrayToMap =
    accountType === 'Seller'
      ? amazonSellerAccountDetails
      : amazonVendorAccountDetails;

  // map secondary marketplaces Values of input field
  const mapMarketPlaceValues = (marketplaceID, key) => {
    let marketplaceValue = null;
    let tempValue = null;
    if (accountType === 'Seller') {
      const sellerArray = localSeller?.length
        ? localSeller
        : sellerMarketplaces;
      marketplaceValue = sellerArray.find(
        (entry) => entry?.marketplace === marketplaceID,
      );
      tempValue = marketplaceValue?.[key];
    }

    if (accountType === 'Vendor') {
      const vendorArray = localVendor?.length
        ? localVendor
        : vendorMarketplaces;
      marketplaceValue = vendorArray.find(
        (entry) => entry?.marketplace === marketplaceID,
      );
      tempValue = marketplaceValue?.[key];
    }

    return tempValue === ' ' ? '' : tempValue;
  };

  const checkStatus = () => {
    const result =
      isAuthorized[
        accountType === 'Seller'
          ? accountDetails?.seller_marketplace?.name?.label
          : accountDetails?.vendor_marketplace?.name?.label
      ];
    const smallcaseAccountType = accountType?.toLowerCase();
    return result?.[smallcaseAccountType];
  };

  return (
    <>
      <h6 className="small-title mt-3">{accountDetails.name}</h6>
      <p className="normal-text-grey">
        Log into your Amazon Seller Central admin account for your additional
        marketplace.
        <br />
        <span className=" open-link cursor">
          {checkStatus() ? (
            <>
              <Status
                className=""
                label="Authorization Successful."
                backgroundColor="#e3f2d2"
                icon
                labelColor="#33ac2e"
                width="100%"
                marginBottom="10px"
                marginTop="10px"
                fontIcon="far fa-check"
              />
              <div className="horizontal-line straight-line mt-5" />
            </>
          ) : (
            <>
              <a
                href={
                  accountType === 'Seller'
                    ? encodeURI(
                        `${authenticationURL}&type=seller_central&companyName=${
                          customerData?.company_name
                        }&region=${getMaketplaceRegion(
                          accountDetails?.seller_marketplace?.name?.value,
                        )}&country=${getMaketplaceCode(
                          accountDetails?.seller_marketplace?.name?.value,
                        )}&source=${accountRedirectURL}`,
                      )
                    : encodeURI(
                        `${authenticationURL}&type=vendor_central&companyName=${
                          customerData?.company_name
                        }&region=${getMaketplaceRegion(
                          accountDetails?.vendor_marketplace?.name?.value,
                        )}&country=${getMaketplaceCode(
                          accountDetails?.vendor_marketplace?.name?.value,
                        )}&source=${accountRedirectURL}`,
                      )
                }
                // target="_blank"
                // rel="noopener noreferrer"
                onClick={() =>
                  localStorage.setItem(
                    'spAPI',
                    JSON.stringify({
                      type: accountType === 'Seller' ? 'seller' : 'vendor',
                      marketplace:
                        accountType === 'Seller'
                          ? accountDetails?.seller_marketplace?.name?.value
                          : accountDetails?.vendor_marketplace?.name?.value,
                    }),
                  )
                }
              >
                <Button
                  className={
                    isChecked
                      ? 'btn-primary font-style-regular w-100 mt-4 isDisabled'
                      : 'btn-primary font-style-regular w-100 mt-4'
                  }
                >
                  {`Log into Amazon ${
                    accountType === 'Seller' ? 'Seller' : 'Vendor'
                  } Central Account`}
                </Button>
              </a>
              <div className="horizontal-line straight-line mt-3" />
            </>
          )}
        </span>
      </p>
      {arrayToMap.map((item) => (
        <>
          <InputFormField className="mt-3" key={item.key}>
            <label htmlFor={item.label}>
              {item.label}

              <input
                placeholder={`Enter ${item.label}`}
                className="form-control"
                onChange={(event) => {
                  setDefaultForSecondary(
                    item.key,
                    event.target.value,
                    accountType === 'Seller'
                      ? accountDetails.seller_marketplace.id
                      : accountDetails.vendor_marketplace.id,
                    accountType,
                    accountType === 'Seller'
                      ? accountDetails?.seller_marketplace?.[accountType]?.id ||
                          localSeller?.filter((op) => op.id)?.[0]?.id
                      : accountDetails?.vendor_marketplace?.[accountType]?.id ||
                          localVendor?.filter((op) => op.id)?.[0]?.id,
                  );
                }}
                value={mapMarketPlaceValues(
                  accountType === 'Seller'
                    ? accountDetails.seller_marketplace?.id
                    : accountDetails.vendor_marketplace?.id,
                  item.key,
                  item.label,
                )}
                defaultValue={mapSecondaryMarketplaceValues(
                  item.key,
                  accountType === 'Seller'
                    ? accountDetails.seller_marketplace?.id
                    : accountDetails.vendor_marketplace?.id,
                  accountType,
                  accountType === 'Seller'
                    ? accountDetails.seller_marketplace?.name?.value
                    : accountDetails.vendor_marketplace?.name?.value,
                )}
                readOnly={doesntAdvertise}
                // readOnly={isChecked}
              />
            </label>
            {Object.keys(apiError).length > 0 ? (
              <>
                <ErrorMsg>
                  {mapApiErrors(
                    item.key,
                    accountType === 'Seller'
                      ? accountDetails.seller_marketplace?.id
                      : accountDetails.vendor_marketplace?.id,
                    accountType,
                  )}
                </ErrorMsg>
              </>
            ) : null}
          </InputFormField>
        </>
      ))}
      <CheckBox className="mt-3 mb-4">
        <label
          className="check-container customer-pannel"
          htmlFor={accountDetails.name}
        >
          I do not advertise on this marketplace
          <input
            type="checkbox"
            id={accountDetails.name}
            name={accountDetails.name}
            onChange={() => {
              setDoesntAdvertise(!doesntAdvertise);
              setDefaultForSecondary(
                'doesnt_advertise',
                !doesntAdvertise,
                accountType === 'Seller'
                  ? accountDetails.seller_marketplace?.id
                  : accountDetails.vendor_marketplace?.id,
                accountType,
                accountType === 'Seller'
                  ? accountDetails?.seller_marketplace?.Seller?.id
                  : accountDetails?.vendor_marketplace?.Vendor?.id,
              );
            }}
            defaultChecked={mapSecondaryMarketplaceValues(
              'doesnt_advertise',
              accountType === 'Seller'
                ? accountDetails.seller_marketplace?.id
                : accountDetails.vendor_marketplace?.id,
              accountType,
              accountType === 'Seller'
                ? accountDetails.seller_marketplace?.name?.value
                : accountDetails.vendor_marketplace?.name?.value,
            )}
          />
          <span className="checkmark" />
        </label>
      </CheckBox>
      <div className="straight-line horizontal-line  mt-3 " />
    </>
  );
};

SingleAccountMarketplaces.propTypes = {
  accountDetails: shape({}).isRequired,
  mapSecondaryMarketplaceValues: func.isRequired,
  setDefaultForSecondary: func.isRequired,
  accountType: string.isRequired,
  advertiseForMarketplace: bool.isRequired,
  apiError: shape({}).isRequired,
  mapApiErrors: func.isRequired,
  sellerMarketplaces: arrayOf(shape({})).isRequired,
  vendorMarketplaces: arrayOf(shape({})).isRequired,
  isAuthorized: shape({}).isRequired,
  localVendor: shape({}).isRequired,
  localSeller: shape({}).isRequired,
  accountRedirectURL: string.isRequired,
  isChecked: bool.isRequired,
  authenticationURL: string.isRequired,
  customerData: shape({}).isRequired,
};

export default SingleAccountMarketplaces;
