import React from 'react';

// Third Party Imports
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { Collapse } from 'react-collapse';
import { arrayOf, func, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Local Imports
import Theme from '../../../../theme/Theme';
import { contractStatusIcons } from '../../../../constants';
import { Status, WhiteCard } from '../../../../common';
import {
  BrokenFile,
  CaretUp,
  ClockIcon,
  DisabledRecurring,
  DspOnlyIcon,
  RecurringIcon,
} from '../../../../theme/images';

export default function AgreementDetailTabMobView({
  agreementDetailsCollapse,
  setAgreementDetailsCollapse,
  multipleAgreement,
  countDays,
  toggleAccountType,
  showActionDropdown,
  generateBasicInfo,
  getPauseHTML,
  generateCardHTML,
  generateDSPAdvHTML,
  displayMarketplaces,
  displayOptimization,
  displayStdServices,
  displayMonthlyServices,
  displayNonUSServices,
  OptimizationArray,
  checkTheCount,
  displayOneTimeServices,
  displayAddendumStatus,
  accountType,
  handleOnAgreementStatusClick,
  getAgreementName,
}) {
  return (
    <>
      {multipleAgreement?.map((agreement) => {
        const contractType = agreement?.contract_type?.toLowerCase();
        const marketplaceArray = contractType.includes('dsp')
          ? agreement?.dsp_marketplaces
          : agreement?.additional_marketplaces;
        return (
          <>
            <WhiteCard className="mt-4">
              <div className="row">
                <div className="col-md-5 col-12">
                  {' '}
                  <p className="black-heading-title mt-0 mb-0 mr-4">
                    <img
                      width="48px"
                      className="solid-icon"
                      src={
                        agreement?.contract_status?.value === 'pause' ||
                        agreement?.contract_status?.value === 'draft'
                          ? DisabledRecurring
                          : contractType?.includes('dsp')
                          ? DspOnlyIcon
                          : RecurringIcon
                      }
                      alt="logo"
                    />{' '}
                    {contractType?.includes('90')
                      ? 'Recurring (90 day notice) Service Agreement'
                      : contractType?.includes('dsp')
                      ? 'DSP Service Agreement'
                      : 'Recurring Service Agreement'}
                    {/* PDV-8634 condition to display icon only for collection and out of contract. */}
                    {agreement?.in_collections ? (
                      <FontAwesomeIcon
                        icon="fal fa-coins"
                        style={{
                          // marginBottom: '-3px',
                          color: Theme.blue,
                        }}
                        className="ml-2"
                        data-for="in-colletion-tooltip"
                        data-tip="In Collections"
                      />
                    ) : null}
                    {agreement?.out_of_contract ? (
                      <img
                        className="ml-2"
                        width="14px"
                        style={{ marginBottom: '-3px' }}
                        src={BrokenFile} // PDV-8634 change the icon as per BP status ()coin OR broken file
                        alt=""
                        data-for="out-of-contract-tooltip"
                        data-tip="Out Of Contract"
                      />
                    ) : null}
                  </p>
                  <ReactTooltip
                    className="asian-info-icon"
                    id="in-colletion-tooltip"
                    aria-haspopup="true"
                    place="bottom"
                    effect="solid"
                  />
                  <ReactTooltip
                    className="asian-info-icon"
                    id="out-of-contract-tooltip"
                    aria-haspopup="true"
                    place="bottom"
                    effect="solid"
                  />
                  <div style={{ display: 'flow-root' }}>
                    <Status
                      className="mr-2 mb-1 text-medium-font mt-2"
                      icon={
                        !!contractStatusIcons[agreement?.contract_status?.value]
                      }
                      fontIcon={
                        contractStatusIcons[agreement?.contract_status?.value]
                      }
                      label={getAgreementName(
                        agreement?.contract_status?.value,
                      )}
                      backgroundColor={
                        agreement?.contract_status?.value === 'completed'
                          ? Theme.lightGreen
                          : Theme.gray8
                      }
                      labelColor={
                        agreement?.contract_status?.value === 'completed'
                          ? Theme.green
                          : Theme.black
                      }
                      cursor={
                        [
                          'pending for cancellation',
                          'pending cancellation approval',
                          'active partially pause',
                        ].includes(agreement?.contract_status?.value)
                          ? 'pointer'
                          : 'default'
                      }
                      handleOnClick={() =>
                        handleOnAgreementStatusClick(agreement)
                      }
                    />
                    {!agreement.contract_status?.value?.includes('cancel') &&
                    agreement?.end_date &&
                    countDays(agreement?.end_date) <= 90 ? (
                      <Status
                        className="mr-2 mb-1 text-medium-font mt-2"
                        label={`${countDays(agreement?.end_date)} days`}
                        labelColor={Theme.orange}
                        icon
                        iconType={ClockIcon}
                        backgroundColor={Theme.lighterOrange}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 col-12 my-2 order-md-1 order-2">
                  {contractType?.includes('recurring') &&
                  agreement?.seller_type?.label === 'Hybrid' ? (
                    <>{toggleAccountType(agreement.id)}</>
                  ) : null}
                </div>
                <div className="col-md-3 col-12 my-2 order-md-2 order-1">
                  {' '}
                  {showActionDropdown(agreement)}
                </div>
              </div>{' '}
              <ul className="agreement-type">
                {generateBasicInfo('Duration', agreement?.length?.label)}
                {agreement?.contract_status?.value !== 'pause' ? (
                  <>
                    {generateBasicInfo(
                      'Original Start',
                      dayjs(agreement?.start_date).format('MMM DD, YYYY'),
                    )}
                    {agreement?.is_renewed &&
                    agreement?.contract_status.value === 'renewed' ? (
                      <>
                        {generateBasicInfo(
                          'Last Renewed',
                          dayjs(agreement?.renewed_date).format('MMM DD, YYYY'),
                        )}
                      </>
                    ) : null}
                    {generateBasicInfo(
                      'Expires',
                      dayjs(agreement?.end_date).format('MMM DD, YYYY'),
                    )}
                  </>
                ) : (
                  <>{getPauseHTML(agreement && agreement.pause_contract)}</>
                )}
                {contractType?.includes('recurring') &&
                agreement?.seller_type?.label !== 'Hybrid' ? (
                  <li className="other-details">
                    <div className="label">Agreement Type</div>
                    <div className="label-info text-medium-font">
                      {agreement?.seller_type?.label}
                    </div>
                  </li>
                ) : null}
              </ul>
              <div className="metrics-card">
                <ul className="metrics-columns">
                  {(contractType.includes('recurring') &&
                    agreement?.fee_structure &&
                    Object.keys(agreement?.fee_structure)?.length &&
                    !agreement?.fee_structure?.fee_type) ||
                  contractType.includes('dsp') ? (
                    <>
                      {agreement?.fee_structure?.[accountType[agreement.id]]
                        ?.fee_type ? (
                        <div className="straight-line light-line mt-3 mb-lg-0 mb-3" />
                      ) : null}
                      {generateCardHTML(agreement, contractType)}
                    </>
                  ) : null}
                </ul>
              </div>
              {/* <div className="straight-line light-line  mb-3" /> */}
              {agreement?.additional_monthly_services?.find(
                (op) => op?.service?.name === 'DSP Advertising',
              ) ? (
                <>
                  <div className="straight-line light-line  mb-3" />
                  <div className="metrics-card">
                    <ul className="metrics-columns">
                      {generateDSPAdvHTML(agreement)}
                    </ul>
                  </div>
                </>
              ) : null}
              {agreement?.contract_addendum?.length ||
              (contractType?.includes('dsp') &&
                agreement?.dsp_marketplaces &&
                Object.keys(agreement?.dsp_marketplaces)?.length) ||
              (contractType?.includes('recurring') &&
                agreement?.additional_marketplaces &&
                Object.keys(agreement?.additional_marketplaces)?.length) ||
              agreement?.primary_marketplace?.name ? (
                <div className="straight-line light-line  mb-3" />
              ) : null}
              <div className="row">
                {displayMarketplaces(
                  marketplaceArray,
                  agreement,
                  contractType,
                  contractType?.includes('dsp')
                    ? 'DSP Marketplace(s)'
                    : 'Marketplace(s)',
                )}

                {agreement?.contract_addendum?.length ? (
                  <>{displayAddendumStatus(agreement?.contract_addendum)}</>
                ) : null}
              </div>
              {contractType?.includes('recurring') ? (
                <Collapse isOpened={agreementDetailsCollapse[agreement.id]}>
                  {displayOptimization(
                    'Optimizations / Month',
                    agreement,
                    OptimizationArray,
                  )}
                  {displayStdServices(
                    'Standard Services',
                    agreement?.standard_us_services,
                    agreement?.standard_non_us_services?.filter(
                      (op) =>
                        op.name !== 'VAT Registration' &&
                        op.name !== 'VAT Filing',
                    ),
                    agreement?.id,
                  )}
                  {displayMonthlyServices(
                    'Additional Monthly Services',
                    agreement?.additional_monthly_services,
                    agreement?.id,
                  )}
                  {displayOneTimeServices(
                    'One Time Services',
                    agreement?.additional_one_time_services,
                    agreement?.id,
                  )}
                  {displayNonUSServices(
                    'VAT Registration',
                    agreement?.standard_non_us_services?.filter(
                      (op) => op.name === 'VAT Registration',
                    ),
                    agreement?.id,
                  )}
                  {displayNonUSServices(
                    'VAT Filing',
                    agreement?.standard_non_us_services?.filter(
                      (op) => op.name === 'VAT Filing',
                    ),
                    agreement?.id,
                  )}
                </Collapse>
              ) : null}
            </WhiteCard>
            {contractType?.includes('recurring') &&
            checkTheCount(agreement.id) > 0 ? (
              <div
                className="collapse-in-out"
                onClick={() =>
                  setAgreementDetailsCollapse({
                    [agreement.id]: !agreementDetailsCollapse[agreement.id],
                  })
                }
                role="presentation"
              >
                {!agreementDetailsCollapse[agreement.id]
                  ? `Expand Agreement`
                  : 'Collapse Agreement'}
                <img
                  src={CaretUp}
                  alt="caret"
                  style={{
                    transform: agreementDetailsCollapse[agreement.id]
                      ? 'rotate(180deg)'
                      : '',

                    width: '20px',
                    height: '20px',
                    verticalAlign: 'middle',
                  }}
                />{' '}
              </div>
            ) : null}
          </>
        );
      })}
    </>
  );
}

AgreementDetailTabMobView.defaultProps = {
  agreementDetailsCollapse: {},
  setAgreementDetailsCollapse: () => {},
  multipleAgreement: {},
  countDays: () => {},
  toggleAccountType: () => {},
  showActionDropdown: () => {},
  generateBasicInfo: () => {},
  getPauseHTML: () => {},
  generateCardHTML: () => {},
  generateDSPAdvHTML: () => {},
  displayMarketplaces: () => {},
  displayOptimization: () => {},
  displayStdServices: () => {},
  displayMonthlyServices: () => {},
  displayNonUSServices: () => {},
  OptimizationArray: [],
  checkTheCount: () => {},
  displayOneTimeServices: () => {},
  displayAddendumStatus: () => {},
  accountType: '',
  handleOnAgreementStatusClick: () => {},
  getAgreementName: () => {},
};

AgreementDetailTabMobView.propTypes = {
  agreementDetailsCollapse: shape({}),
  setAgreementDetailsCollapse: func,
  multipleAgreement: shape({}),
  countDays: func,
  toggleAccountType: func,
  showActionDropdown: func,
  generateBasicInfo: func,
  getPauseHTML: func,
  generateCardHTML: func,
  generateDSPAdvHTML: func,
  displayMarketplaces: func,
  displayOptimization: func,
  displayStdServices: func,
  displayMonthlyServices: func,
  displayNonUSServices: func,
  OptimizationArray: arrayOf(shape({})),
  checkTheCount: func,
  displayOneTimeServices: func,
  displayAddendumStatus: func,
  accountType: string,
  handleOnAgreementStatusClick: func,
  getAgreementName: func,
};
