// react imports
import React from 'react';

// third party imports
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

// local file imports
import Theme from '../../../../theme/Theme';
import StrandedInventory from './StrandedInventory';
import StrandedAndRestockInventory from './StrandedAndRestockInventory';
import RestockLimits from './RestockLimits';
import useFormatNumber from '../../../../hooks/useFormatNumber';
import { setCustomerSelectedTab } from '../../../../store/actions/customerState';
import { BrandPartnerProfile, NoData, PageLoader } from '../../../../common';
import { CUSTOM, PATH_CUSTOMER_DETAILS } from '../../../../constants';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../theme/images';

function InventoryRightHandSideSection({
  // number
  restockPageNumber,
  restockCount,
  strandedInventoryCount,
  strandedInventoryPageNumber,
  // string
  orderByApi,
  strandedInventoryOrderByApi,
  // boolean
  restockLoader,
  strandedInventoryLoader,
  // object
  userInfo,
  orderBy,
  strandedInventoryOrderBy,
  selectedTimeFrame,
  downloadReportLoader,
  // array
  restockData,
  strandedInventoryData,
  // function
  handleDownloadBtn,
  renderStorageTypeFilter,
  handleOnClickOrderOfRestockLimit,
  handleRestockInventoryPagination,
  handleStrandedInventoryPagination,
  handleOnClickOrderOfStrandedAndRestockInventory,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isRestockInventory = userInfo?.is_restockinventory_visible || false;

  // function - redirecting to customer detail view
  const redirectingToCustomerDetailview = (customerId) => {
    dispatch(setCustomerSelectedTab('inventoryManagement'));
    history.push(
      `${PATH_CUSTOMER_DETAILS.replace(
        ':id',
        customerId,
      )}?tab=inventoryManagement&access=internal`,
    );
  };

  // display no data message
  const displayNoDataMessage = (message) => {
    return <NoData className="text-center mt-5">{message}</NoData>;
  };

  // fucntion - performance loader
  const displayPerformanceLoader = () => {
    return (
      <PageLoader
        component="performance-graph"
        type="detail"
        color={Theme.orange}
        width={40}
        height={40}
      />
    );
  };

  // display company name, logo and bgs
  const displayCompanyNameLogoAndBGS = (itemData) => {
    return (
      <BrandPartnerProfile
        bpName={itemData?.company_name}
        assignedUser={itemData?.bgs}
        logoURL={itemData?.document?.[0]?.presigned_url}
      />
    );
  };

  // display value with green, red, gray color
  const displayNumberWithArrow = (
    percentValue,
    arrowColor,
    className,
    formatNumber,
    suffix,
  ) => {
    return arrowColor === 'green' ? (
      <span className={`percent-change-value increase-rate large ${className}`}>
        <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
        {formatNumber(percentValue, '', suffix)}
      </span>
    ) : arrowColor === 'red' ? (
      <span className={`percent-change-value decrease-rate large ${className}`}>
        <img className="red-arrow" src={ArrowDownIcon} alt="arrow-down" />
        {formatNumber(Math.abs(percentValue), '', suffix)}
      </span>
    ) : (
      <span
        className={`percent-change-value increase-rate large gray80 ${className}`}
      >
        {formatNumber(percentValue, '', suffix)}
      </span>
    );
  };

  // function - display metrics change value with arrow
  const displayMetricsChangeValueWithArrow = (
    percentValue,
    formatNumber,
    valueClassName,
    suffix,
  ) => {
    return selectedTimeFrame?.value === CUSTOM || percentValue === null ? (
      <span
        className={`percent-change-value increase-rate large gray80 ${valueClassName}`}
      >
        N/A
      </span>
    ) : percentValue === 0 ? (
      displayNumberWithArrow(
        percentValue,
        'gray',
        valueClassName,
        formatNumber,
        suffix,
      )
    ) : percentValue > 0 ? (
      displayNumberWithArrow(
        percentValue,
        'green',
        valueClassName,
        formatNumber,
        suffix,
      )
    ) : (
      displayNumberWithArrow(
        percentValue,
        'red',
        valueClassName,
        formatNumber,
        suffix,
      )
    );
  };

  // function - display download report loader
  const displayDownloadReportLoader = (inventoryType) => {
    return (
      <div
        className="align-self-center black-heading-title text-medium-font m-0 cursor"
        onClick={() => {
          handleDownloadBtn(inventoryType);
        }}
        role="presentation"
      >
        {downloadReportLoader?.tab === inventoryType &&
        downloadReportLoader?.loader ? (
          <PageLoader
            component="sidebar-loader"
            type="button"
            color={Theme?.orange}
          />
        ) : (
          <>
            <FontAwesomeIcon
              className="mr-2"
              color={Theme?.orange}
              icon="far fa-arrow-to-bottom"
            />
            Download Report
          </>
        )}
      </div>
    );
  };

  // disply up down arrow for table header
  const displayUpDownArrowforTableHeader = (key, keyBy, keyByApi) => {
    return (
      <FontAwesomeIcon
        className="ml-2 cursor"
        color={keyByApi?.includes(key) ? Theme.orange : Theme.gray40}
        icon={`far ${keyBy?.[key] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
        style={{ fontSize: '12px' }}
      />
    );
  };

  // display restock inventory section
  const displayRestockInventorySection = () => {
    return (
      <div id="BT-InventoryRestockLimit">
        <RestockLimits
          formatNumber={useFormatNumber}
          restockLoader={restockLoader}
          restockData={restockData}
          restockCount={restockCount}
          onClickOrder={(value) => {
            handleOnClickOrderOfRestockLimit(value);
          }}
          orderBy={orderBy}
          orderByApi={orderByApi}
          pageNumber={restockPageNumber}
          onHandlePageChange={(value) =>
            handleRestockInventoryPagination(value)
          }
          displayNoDataMessage={displayNoDataMessage}
          renderStorageTypeFilter={renderStorageTypeFilter}
          displayPerformanceLoader={displayPerformanceLoader}
          displayDownloadReportLoader={displayDownloadReportLoader}
          displayCompanyNameLogoAndBGS={displayCompanyNameLogoAndBGS}
          redirectingToCustomerDetailview={redirectingToCustomerDetailview}
          displayUpDownArrowforTableHeader={displayUpDownArrowforTableHeader}
          displayMetricsChangeValueWithArrow={
            displayMetricsChangeValueWithArrow
          }
        />
      </div>
    );
  };

  // display stranded and restock inventory section
  const displayStrandedAndRestockInventorySection = () => {
    return (
      <div id="BT-InventoryStrandedRestock">
        <StrandedAndRestockInventory
          orderBy={strandedInventoryOrderBy}
          orderByApi={strandedInventoryOrderByApi}
          loader={strandedInventoryLoader}
          count={strandedInventoryCount}
          pageNumber={strandedInventoryPageNumber}
          strandedInventoryData={strandedInventoryData}
          formatNumber={useFormatNumber}
          onHandlePageChange={(value) =>
            handleStrandedInventoryPagination(value)
          }
          onClickOrder={(value) => {
            handleOnClickOrderOfStrandedAndRestockInventory(value);
          }}
          displayNoDataMessage={displayNoDataMessage}
          displayNumberWithArrow={displayNumberWithArrow}
          displayPerformanceLoader={displayPerformanceLoader}
          displayDownloadReportLoader={displayDownloadReportLoader}
          displayCompanyNameLogoAndBGS={displayCompanyNameLogoAndBGS}
          redirectingToCustomerDetailview={redirectingToCustomerDetailview}
          displayUpDownArrowforTableHeader={displayUpDownArrowforTableHeader}
          displayMetricsChangeValueWithArrow={
            displayMetricsChangeValueWithArrow
          }
        />
      </div>
    );
  };

  // display stranded inventory section
  const displayStrandedInventorySection = () => {
    return (
      <div id="BT-InventoryStranded">
        <StrandedInventory
          loader={strandedInventoryLoader}
          count={strandedInventoryCount}
          pageNumber={strandedInventoryPageNumber}
          strandedInventoryData={strandedInventoryData}
          formatNumber={useFormatNumber}
          onHandlePageChange={(value) =>
            handleStrandedInventoryPagination(value)
          }
          onClickOrder={(value) => {
            handleOnClickOrderOfStrandedAndRestockInventory(value);
          }}
          orderBy={strandedInventoryOrderBy}
          orderByApi={strandedInventoryOrderByApi}
          displayNoDataMessage={displayNoDataMessage}
          displayNumberWithArrow={displayNumberWithArrow}
          displayPerformanceLoader={displayPerformanceLoader}
          displayDownloadReportLoader={displayDownloadReportLoader}
          displayCompanyNameLogoAndBGS={displayCompanyNameLogoAndBGS}
          redirectingToCustomerDetailview={redirectingToCustomerDetailview}
          displayUpDownArrowforTableHeader={displayUpDownArrowforTableHeader}
          displayMetricsChangeValueWithArrow={
            displayMetricsChangeValueWithArrow
          }
        />
      </div>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      {displayRestockInventorySection()}
      {isRestockInventory
        ? displayStrandedAndRestockInventorySection()
        : displayStrandedInventorySection()}
    </div>
  );
}

export default InventoryRightHandSideSection;

InventoryRightHandSideSection.defaultProps = {
  // number
  restockPageNumber: 0,
  restockCount: 0,
  strandedInventoryCount: 0,
  strandedInventoryPageNumber: 0,
  // string
  orderByApi: '',
  strandedInventoryOrderByApi: '',
  // boolean
  restockLoader: false,
  strandedInventoryLoader: false,
  // object
  userInfo: {},
  orderBy: {},
  strandedInventoryOrderBy: {},
  selectedTimeFrame: {},
  downloadReportLoader: {},
  // array
  restockData: [],
  strandedInventoryData: [],
  // function
  handleDownloadBtn: () => {},
  renderStorageTypeFilter: () => {},
  handleOnClickOrderOfRestockLimit: () => {},
  handleRestockInventoryPagination: () => {},
  handleStrandedInventoryPagination: () => {},
  handleOnClickOrderOfStrandedAndRestockInventory: () => {},
};

InventoryRightHandSideSection.propTypes = {
  // number
  restockPageNumber: number,
  restockCount: number,
  strandedInventoryCount: number,
  strandedInventoryPageNumber: number,
  // string
  orderByApi: string,
  strandedInventoryOrderByApi: string,
  // boolean
  restockLoader: bool,
  strandedInventoryLoader: bool,
  // object
  userInfo: shape({}),
  orderBy: shape({}),
  strandedInventoryOrderBy: shape({}),
  selectedTimeFrame: shape({}),
  downloadReportLoader: shape({}),
  // array
  restockData: arrayOf(shape({})),
  strandedInventoryData: arrayOf(shape({})),
  // function
  handleDownloadBtn: func,
  renderStorageTypeFilter: func,
  handleOnClickOrderOfRestockLimit: func,
  handleRestockInventoryPagination: func,
  handleStrandedInventoryPagination: func,
  handleOnClickOrderOfStrandedAndRestockInventory: func,
};
