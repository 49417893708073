/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bool, func, shape, string } from 'prop-types';

import ProjectAsinsTable from './ProjectAsinsTable';
import UploadCreativeAsins from './UploadCreativeAsins';
import CreativeProjectFilters from './CreativeProjectFilters';
import CreativeProjectPerformanceSummary from './CreativeProjectPerformanceSummary';
import NoRecordFound from '../../../../../common/NoRecordFound';
import { WhiteCard, PageLoader } from '../../../../../common';
import { SpreetailRole } from '../../../../../constants';
import {
  getCreativeProjectGlobalFilters,
  getCreativeProjectMonths,
  getCreativeProjectTableInfo,
  getCreativeProjectGraphData,
} from '../../../../../api/CompanyPerformanceApi';

const getSymbolFromCurrency = require('currency-symbol-map');

export default function CreativeProjectContainer({
  id,
  resetCreativeProjectFilters,
  setResetCreativeProjectFilters,
  setShowCreativeUploadScreen,
  selectedAccountType,
  assignedBGSManager,
  loggedInRole,
  setIsDisabledRefreshBtn,
  displayAccountTypeFilter,
}) {
  // https://bbe.atlassian.net/browse/PDV-8278
  // Removing the hard coded user emails and making it as dynamic access for authorized users.
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const customerState = useSelector((state) => state?.customerState?.data);
  const isSuperAdmin = userInfo?.role === 'Super Admin';
  const isThirdPartyBrand = customerState?.is_third_party_potential_brand;
  const hideUploadCreativeProjectCTA = !isSuperAdmin && !isThirdPartyBrand;
  const allowedToUploadDeleteProject =
    userInfo?.allowed_to_upload_delete_creative_project_fe;

  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [callGraphApi, setCallGraphApi] = useState(false);
  const [callTableApi, setCallTableApi] = useState(true);
  const [isItFirstTimeRender, setIsItFirstTimeRender] = useState(true);
  const [globalFiltersOptions, setGlobalFiltersOptions] = useState([]);
  const [getMonthsList, setGetMonthsList] = useState([]);
  const [tableInfoDetails, setTableInfoDetails] = useState([]);
  const [currencySymbolForGraph, setCurrencySymbolForGraph] = useState(null);
  const [getTotalOfTable, setGetTotalOfTable] = useState({});
  const [paginationChecksum, setPaginationChecksum] = useState();
  const [getProjectsFilter, setGetProjectsFilter] = useState({});
  const [creativeProjectData, setCreativeProjectData] = useState();
  const [creativeProjectChartData, setCreativeProjectChartData] = useState();
  const [groupBy, setGroupBy] = useState('weekly');
  const [afterDaysValue, setAfterDaysValue] = useState('30');
  const [afterDaysDateDifference, setAfterDaysDateDifference] = useState();
  const [asinCounts, setAsinCounts] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [isSelectProjectDropdownOption, setIsSelectProjectDropdownOption] =
    useState(false);
  const [selectedVendorReporting, setSelectedVendorReporting] =
    useState('ordered_revenue');
  const [selectedCreativeProjectMetrics, setSelectedCreativeProjectMetrics] =
    useState({});
  const [selectedProjects, setSelectedProjects] = useState({
    table: [],
    search: [],
  });
  const [isTableLoading, setIsTableLoading] = useState({
    loader: true,
    type: 'table',
  });
  const [isLoadingGraph, setIsLoadingGraph] = useState({
    loader: true,
    type: 'graph',
  });
  const [showProjectAsinTable, setShowProjectAsinTable] = useState({
    project_view: true,
    asins_view: false,
  });

  const [multiSelectedValues, setMultiSelectedValues] = useState({
    months: [],
    marketplaces: [],
    project_type: [],
    projects: [],
    asins: [],
    projectLabels: [],
  });

  // delete access permission
  const isAllowToDeleteProject =
    allowedToUploadDeleteProject ||
    assignedBGSManager ||
    loggedInRole === 'BGS Admin';

  // create short name of project to display in dropdown
  const getShortProjectNames = (projects) => {
    const projectArray = projects || globalFiltersOptions?.projects;
    for (const item of projectArray) {
      if (item?.project_type?.includes('Brand Story - A+ Content')) {
        item.value = item.asin_checksum;
        item.label = `A+ BS ${item.marketplace_id} (${dayjs(
          item.completion_date,
        ).format('MM/DD/YYYY')})`;
      } else if (item?.project_type?.includes('Regular - A+ Content')) {
        item.value = item.asin_checksum;
        item.label = `A+ R ${item.marketplace_id} (${dayjs(
          item.completion_date,
        ).format('MM/DD/YYYY')})`;
      } else if (item?.project_type?.includes('Premium - A+ Content')) {
        item.value = item.asin_checksum;
        item.label = `A+ P ${item.marketplace_id} (${dayjs(
          item.completion_date,
        ).format('MM/DD/YYYY')})`;
      } else if (item?.project_type?.includes('Listing Updates')) {
        item.value = item.asin_checksum;
        item.label = `Listing ${item.marketplace_id} (${dayjs(
          item.completion_date,
        ).format('MM/DD/YYYY')})`;
      } else {
        item.value = item.asin_checksum;
        item.label = `${item.project_type} ${item.marketplace_id} (${dayjs(
          item.completion_date,
        ).format('MM/DD/YYYY')})`;
      }
    }
  };

  // handle toogle btn- weekly, monthly, quarterly
  const handleGroupBy = (value) => {
    if (value !== groupBy) {
      setGroupBy(value);
      setCallGraphApi(true);
    }
  };

  // bind the graph api response data to dispaly graph
  const bindCreativeProjectResponseData = (response) => {
    const tempData = [];

    // get a last date of the week
    const getOneDayBack = (reportDateTemp) => {
      const reportDateResultTemp = new Date(reportDateTemp);
      reportDateResultTemp.setDate(reportDateResultTemp.getDate() - 1);
      return reportDateResultTemp;
    };

    const nextReportDate = (indexTemp, graphDataArray, dateParam) => {
      return dayjs(
        indexTemp + 1 < graphDataArray?.length
          ? getOneDayBack(graphDataArray[indexTemp + 1]?.report_date)
          : response?.dates?.[
              dateParam === 'current' ? 'current_end_date' : 'prevous_end_date'
            ],
      ).format('MMM D YYYY');
    };

    // filterout Current data in one temporary object.
    if (response?.current?.length) {
      response.current.forEach((item, index) => {
        const currentReportDate = dayjs(item.report_date).format('MMM D YYYY');
        if (selectedAccountType?.value === 'seller') {
          tempData.push({
            buy_box_percentageCurrent: item.buy_box_percentage,
            conversionCurrent: item.conversion,
            ordered_product_salesCurrent: item.ordered_product_sales,
            page_viewsCurrent: item.page_views,
            sessionsCurrent: item.sessions,
            units_orderedCurrent: item.units_ordered,
            units_soldCurrent: item.units_sold,
            date: currentReportDate,
            endDate: nextReportDate(index, response?.current, 'current'),

            buy_box_percentageCurrentLabel:
              item.buy_box_percentage !== null
                ? item.buy_box_percentage?.toFixed(2)
                : '0.00',
            conversionCurrentLabel:
              item.conversion !== null ? item.conversion?.toFixed(2) : '0.00',
            ordered_product_salesCurrentLabel:
              item.ordered_product_sales !== null
                ? item.ordered_product_sales?.toFixed(2)
                : '0.00',
            page_viewsCurrentLabel:
              item.page_views !== null ? item.page_views : '0',
            sessionsCurrentLabel:
              item.sessions !== null ? item.sessions?.toFixed(2) : '0.00',
            units_orderedCurrentLabel:
              item.units_ordered !== null ? item.units_ordered : '0',
            units_soldCurrentLabel:
              item.units_sold !== null ? item.units_sold?.toFixed(2) : '0.00',
          });
        } else if (selectedVendorReporting === 'ordered_revenue') {
          tempData.push({
            conversionRateCurrent: item.conversion_rate,
            ordered_revenueCurrent: item.ordered_revenue,
            glance_viewsCurrent: item.glance_views,
            ordered_unitsCurrent: item.ordered_units,
            date: currentReportDate,
            endDate: nextReportDate(index, response?.current, 'current'),

            conversionRateCurrentLabel:
              item.conversion_rate !== null
                ? item.conversion_rate?.toFixed(2)
                : '0.00',
            ordered_revenueCurrentLabel:
              item.ordered_revenue !== null
                ? item.ordered_revenue?.toFixed(2)
                : '0.00',
            glance_viewsCurrentLabel:
              item.glance_views !== null ? item.glance_views : '0',
            ordered_unitsCurrentLabel:
              item.ordered_units !== null
                ? item.ordered_units?.toFixed(2)
                : '0',
          });
        } else if (selectedVendorReporting === 'shipped_cogs') {
          tempData.push({
            conversionRateCurrent: item.conversion_rate,
            shipped_cogsCurrent: item.shipped_cogs,
            glance_viewsCurrent: item.glance_views,
            shipped_unitsCurrent: item.shipped_units,
            date: currentReportDate,
            endDate: nextReportDate(index, response?.current, 'current'),

            conversionRateCurrentLabel:
              item.conversion_rate !== null
                ? item.conversion_rate?.toFixed(2)
                : '0.00',
            shipped_cogsCurrentLabel:
              item.shipped_cogs !== null
                ? item.shipped_cogs?.toFixed(2)
                : '0.00',
            glance_viewsCurrentLabel:
              item.glance_views !== null ? item.glance_views : '0',
            shipped_unitsCurrentLabel:
              item.shipped_units !== null
                ? item.shipped_units?.toFixed(2)
                : '0',
          });
        }
      });
    }

    return tempData;
  };

  // Disabled after 30, 60, 90, 120 days dropdown option if completion date and todays date difference is more
  // eg": if completion date is after or before 45 days from todays date then enabe 30,60 and disabled 90, 120
  useEffect(() => {
    // a and b are javascript Date objects
    const a = new Date(); // todays date
    const b = new Date(creativeProjectData?.dates?.current_start_date); // completion date

    const MSPERDAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    const dateDifference = Math.abs(Math.floor((utc2 - utc1) / MSPERDAY));
    setAfterDaysDateDifference(dateDifference);
  }, [creativeProjectData]);

  // Graph data api call
  useEffect(() => {
    if (callGraphApi) {
      setIsLoadingGraph({
        loader: true,
        type: 'graph',
      });
      getCreativeProjectGraphData(
        id,
        multiSelectedValues,
        groupBy,
        afterDaysValue,
        params.sub || 'seller',
        selectedAccountType?.value === 'vendor' ? selectedVendorReporting : '',
      ).then((response) => {
        const creativeProjectGraphData = bindCreativeProjectResponseData(
          response?.data,
        );
        setCreativeProjectChartData(creativeProjectGraphData);
        setCreativeProjectData(response?.data);
        // Set country symbol for graph according to lattest project selected
        setCurrencySymbolForGraph(
          getSymbolFromCurrency(
            response?.data?.graph_country_currency?.currency,
          ),
        );
        setIsLoadingGraph({
          loader: false,
          type: 'graph',
        });
        setCallGraphApi(false);
      });
    }
  }, [multiSelectedValues, groupBy, afterDaysValue, selectedVendorReporting]);

  // trigger when cganged the account type - seller or vendor
  useEffect(() => {
    setCallGraphApi(true);
  }, [selectedAccountType]);

  // trigger to enable OR disable refresh btn according to API call. (on loader)
  useEffect(() => {
    setIsDisabledRefreshBtn(
      isLoading?.loader || isTableLoading?.loader || isLoadingGraph.loader,
    );
  }, [isLoading, isTableLoading, isLoadingGraph]);

  const checkProjectList = (project) => {
    const fields = [];
    const search = [];
    for (const item of project) {
      for (const label of multiSelectedValues?.projects) {
        if (item?.label === label?.label) search.push(item);
      }
      // get the month
      for (const months of multiSelectedValues?.months) {
        if (item?.month === months?.value) {
          for (const market of multiSelectedValues?.marketplaces) {
            if (item?.marketplace_id === market?.value) {
              for (const type of multiSelectedValues?.project_type) {
                if (item?.project_type === type?.value) {
                  fields.push(item);
                }
              }
            }
          }
        }
      }
    }
    setSelectedProjects({ table: fields, search });
  };

  useEffect(() => {
    if (globalFiltersOptions?.projects?.length)
      checkProjectList(globalFiltersOptions?.projects);
  }, [multiSelectedValues, globalFiltersOptions]);

  useEffect(() => {
    const isAllMonths = multiSelectedValues?.months?.filter(
      (op) => op.value === 'all',
    );

    if (
      (globalFiltersOptions?.months?.length &&
        multiSelectedValues?.marketplaces?.length === 0) ||
      isAllMonths?.length ||
      multiSelectedValues?.months?.length
    ) {
      setMultiSelectedValues({
        months: multiSelectedValues?.months || [
          globalFiltersOptions?.months?.[0],
        ],
        marketplaces: [globalFiltersOptions?.marketplaces?.[0]],
        project_type: [globalFiltersOptions?.project_type?.[0]],
        projects: [getProjectsFilter?.projects?.[0]],
      });
      if (isItFirstTimeRender) {
        setCallGraphApi(true);
      }
    }
    if (globalFiltersOptions?.projects?.length) {
      checkProjectList(globalFiltersOptions?.projects);
      getShortProjectNames(globalFiltersOptions?.projects);
    }
  }, [globalFiltersOptions]);

  // get filters option accordign to selected date
  const getCreativeDataOnMonths = (
    filterData,
    monthOptions,
    currentPage = 1,
    callTableAPIFlag = false,
  ) => {
    setIsLoading({ loader: true, type: 'page' });

    getCreativeProjectGlobalFilters(
      id,
      params?.sub || 'seller',
      filterData,
    ).then((response) => {
      if (response?.status === 200) {
        const uniqueProjectArray = Object.values(
          response?.data?.projects?.reduce(
            (acc, cur) =>
              Object.assign(acc, {
                [cur.project_type + cur.completion_date + cur.marketplace_id]:
                  cur,
              }),
            {},
          ),
        );
        const projectsBasedOnMarketplaces = [];
        for (const pro of uniqueProjectArray) {
          if (
            response?.data?.marketplaces?.[0]?.value === pro.marketplace_id &&
            response?.data?.project_type?.[0]?.value === pro.project_type
          ) {
            projectsBasedOnMarketplaces.push(pro);
          }
          for (const currency of response?.data?.marketplaces) {
            if (pro.marketplace_id === currency.value) {
              pro.currency = getSymbolFromCurrency(
                currency?.country_currency?.currency,
              );
            }
          }
        }
        setGlobalFiltersOptions({
          ...response?.data,
          months: monthOptions,
          projects: uniqueProjectArray,
        });
        setGetProjectsFilter({ projects: projectsBasedOnMarketplaces });
        setMultiSelectedValues({
          ...filterData,
          marketplaces: [response?.data?.marketplaces?.[0]],
          project_type: [response?.data?.project_type?.[0]],
          projects: projectsBasedOnMarketplaces || [
            response?.data?.projects?.[0],
          ],
        });

        getShortProjectNames(response?.data?.projects);
        setIsLoading({ loader: false, type: 'page' });
        setPageNumber(currentPage || 1);
        setIsItFirstTimeRender(false);

        if (callTableApi || callTableAPIFlag) {
          setIsTableLoading({ loader: true, type: 'table' });
          const localMultiselectProject =
            projectsBasedOnMarketplaces?.[0]?.asin_checksum ||
            response?.data?.projects?.[0]?.asin_checksum;

          // get the table info
          getCreativeProjectTableInfo(
            id,
            params?.sub === 'seller' ? null : selectedVendorReporting,
            params?.sub || 'seller',
            filterData,
            pageNumber,
            paginationChecksum,
            [localMultiselectProject],
          ).then((res) => {
            if (res?.status === 200) {
              const total = [];
              for (const [key, value] of Object.entries(
                res?.data?.project_view,
              )) {
                total.push({ [key]: value.total });
              }
              setGetTotalOfTable(total);
              const counts = [];
              for (const [key, value] of Object.entries(
                res?.data?.asins_view,
              )) {
                counts.push({ [key]: value.count });
              }
              setAsinCounts(counts);
              setPageNumber(currentPage || 1);
              setTableInfoDetails(res?.data);

              setIsTableLoading({ loader: false, type: 'table' });
            } else {
              setGetTotalOfTable({});
              setAsinCounts();
              setTableInfoDetails([]);

              setIsTableLoading({ loader: false, type: 'table' });
              if (res?.status === 504)
                toast.error('Failed to fetch the data. Please try again.');
            }
            setCallTableApi(false);
          });
        }
      } else {
        setIsLoading({ loader: false, type: 'page' });
      }
    });
  };

  // when click on applay CTA-  call graph API and table info API
  const handleApplyOnClick = () => {
    setIsLoading({ loader: true, type: 'page' });
    setIsTableLoading({ loader: true, type: 'table' });

    // get graph data
    getCreativeProjectGraphData(
      id,
      multiSelectedValues,
      groupBy,
      afterDaysValue,
      params.sub || 'seller',
      selectedAccountType?.value === 'vendor' ? selectedVendorReporting : '',
    ).then((response) => {
      const creativeProjectGraphData = bindCreativeProjectResponseData(
        response?.data,
      );
      setCreativeProjectChartData(creativeProjectGraphData);
      setCreativeProjectData(response?.data);
      // Set country symbol for graph according to lattest project selected
      setCurrencySymbolForGraph(
        getSymbolFromCurrency(response?.data?.graph_country_currency?.currency),
      );
      setIsLoadingGraph({
        loader: false,
        type: 'graph',
      });
      setIsLoading({ loader: false, type: 'page' });
    });

    // get the table info
    getCreativeProjectTableInfo(
      id,
      params?.sub === 'seller' ? null : selectedVendorReporting,
      params?.sub || 'seller',
      multiSelectedValues?.months,
      pageNumber,
      paginationChecksum,
      multiSelectedValues.projects.map((element) => {
        if (element.value !== 'all') return element.asin_checksum;
        return null;
      }),
    ).then((res) => {
      if (res?.status === 200) {
        const total = [];
        for (const [key, value] of Object.entries(res?.data?.project_view)) {
          total.push({ [key]: value.total });
        }
        setGetTotalOfTable(total);
        const counts = [];
        for (const [key, value] of Object.entries(res?.data?.asins_view)) {
          counts.push({ [key]: value.count });
        }
        setAsinCounts(counts);
        setTableInfoDetails(res?.data);

        setIsTableLoading({ loader: false, type: 'table' });
      } else {
        setGetTotalOfTable({});
        setAsinCounts();
        setTableInfoDetails([]);

        setIsTableLoading({ loader: false, type: 'table' });
        if (res?.status === 504)
          toast.error('Failed to fetch the data. Please try again.');
      }
    });
  };

  // get the month dropdown options
  const getMonthsDropwonOptions = (callTableAPIFlag = false) => {
    setIsLoading({ loader: true, type: 'page' });
    getCreativeProjectMonths(id, params?.sub || 'seller').then((month) => {
      setGetMonthsList(month?.data);
      if (month?.data?.length) {
        getCreativeDataOnMonths(
          { months: [month?.data?.[0]] },
          month?.data,
          1,
          callTableAPIFlag,
        );
        setMultiSelectedValues({
          ...multiSelectedValues,
          months: [month?.data?.[0]],
        });
      } else {
        setIsLoading({ loader: false, type: 'page' });
      }
    });
  };

  // triger when click on refresh btn
  useEffect(() => {
    if (resetCreativeProjectFilters) {
      setCallGraphApi(true);
      getMonthsDropwonOptions(true);
      setResetCreativeProjectFilters(false);
    }
  }, [resetCreativeProjectFilters]);

  // get table info - triger at 1st time and when delete the asin
  useEffect(() => {
    if (multiSelectedValues?.months?.length || isSelectProjectDropdownOption) {
      setIsTableLoading({ loader: true, type: 'table' });
      getCreativeProjectTableInfo(
        id,
        params?.sub === 'seller' ? null : selectedVendorReporting,
        params?.sub || 'seller',
        multiSelectedValues,
        1,
        paginationChecksum,
        multiSelectedValues.projects.map((element) => {
          if (element.value !== 'all') return element.asin_checksum;
          return null;
        }), // asin
      ).then((res) => {
        if (res?.status === 200) {
          const total = [];
          for (const [key, value] of Object.entries(res?.data?.project_view)) {
            total.push({ [key]: value.total });
          }
          setGetTotalOfTable(total);
          const counts = [];
          for (const [key, value] of Object.entries(res?.data?.asins_view)) {
            counts.push({ [key]: value.count });
          }
          setAsinCounts(counts);
          setPageNumber(1);
          setTableInfoDetails(res?.data);
          setIsLoading({ loader: false, type: 'page' });
          // setShowProjectAsinTable({ project_view: true, asins_view: false });
        } else {
          setGetTotalOfTable({});
          setAsinCounts();
          setTableInfoDetails([]);
          setIsLoading({ loader: false, type: 'page' });
          if (res?.status === 504)
            toast.error('Failed to fetch the data. Please try again.');
        }
        setIsTableLoading({ loader: false, type: 'table' });
        setIsSelectProjectDropdownOption(false);
      });
    }
  }, [isSelectProjectDropdownOption]);

  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    setIsLoading({ loader: true, type: 'page' });
    setIsTableLoading({ loader: true, type: 'table' });
    getCreativeProjectTableInfo(
      id,
      params?.sub === 'seller' ? null : selectedVendorReporting,
      params?.sub || 'seller',
      multiSelectedValues,
      currentPage,
      paginationChecksum,
      multiSelectedValues.projects.map((element) => {
        if (element.value !== 'all') return element.asin_checksum;
        return null;
      }),
    ).then((res) => {
      if (res?.status === 200) {
        const counts = [];
        for (const [key, value] of Object.entries(res?.data?.asins_view)) {
          counts.push({ [key]: value.count });
        }
        setAsinCounts(counts);
        setPageNumber(currentPage);
        setTableInfoDetails(res?.data);
        setIsLoading({ loader: false, type: 'page' });
        setShowProjectAsinTable({ project_view: false, asins_view: true });
      } else {
        setAsinCounts();
        setTableInfoDetails([]);
        setIsLoading({ loader: false, type: 'page' });
        if (res?.status === 504)
          toast.error('Failed to fetch the data. Please try again.');
      }
      setIsTableLoading({ loader: false, type: 'table' });
    });
  };

  useEffect(() => {
    getMonthsDropwonOptions();
  }, [id]);

  // Remove asin and projectLabels from multiselected values if project is unselected from projects dropdown
  const removeAsinsonProjectChange = (asinChecksumParam) => {
    let multiSelectedValuesAsinTemp = multiSelectedValues?.asin?.length
      ? [...multiSelectedValues?.asin]
      : [];
    let multiSelectedValuesProjectLabelsTemp = multiSelectedValues
      ?.projectLabels?.length
      ? [...multiSelectedValues?.projectLabels]
      : [];
    multiSelectedValuesAsinTemp = multiSelectedValuesAsinTemp?.filter(
      (op) => op?.split('-')?.[1] !== asinChecksumParam,
    );
    multiSelectedValuesProjectLabelsTemp =
      multiSelectedValuesProjectLabelsTemp?.filter(
        (op) => op !== asinChecksumParam,
      );
    setMultiSelectedValues((prevState) => {
      return {
        ...prevState,
        asin: multiSelectedValuesAsinTemp,
        projectLabels: multiSelectedValuesProjectLabelsTemp,
      };
    });
  };

  // Trigger when changed value of any dropdown
  const handleMultiSelectChange = (event, type, action) => {
    if (type === 'projects' && action.action === 'deselect-option') {
      removeAsinsonProjectChange(action?.option?.asin_checksum);
    }

    if (type === 'project_type' || type === 'marketplaces') {
      const projectsBasedOnMarketplaces = [];
      if (
        action.option?.value === 'all' &&
        action.name === type &&
        action.action === 'deselect-option'
      ) {
        for (const item of getProjectsFilter?.projects) {
          for (const month of multiSelectedValues?.months) {
            if (item.month === month.value) {
              if (
                item?.[
                  action.name === 'marketplaces'
                    ? 'marketplace_id'
                    : action.name
                ] === event?.[0]?.value
              ) {
                projectsBasedOnMarketplaces.push(item);
              }
            }
          }
        }
      } else {
        for (const project of globalFiltersOptions?.projects) {
          if (
            action.option?.value === 'all' &&
            action.name === type &&
            action.action === 'select-option'
          ) {
            projectsBasedOnMarketplaces.push(project);
          } else {
            for (const value of event) {
              if (
                value?.value ===
                project?.[
                  action.name === 'marketplaces'
                    ? 'marketplace_id'
                    : action.name
                ]
              ) {
                projectsBasedOnMarketplaces.push(project);
              }
            }
          }
        }
      }
      setGetProjectsFilter({ projects: projectsBasedOnMarketplaces });
      setMultiSelectedValues((prevState) => {
        return {
          ...prevState,
          asin: [],
          projectLabels: [],
          projects: [projectsBasedOnMarketplaces?.[0]],
        };
      });
    }
    checkProjectList(globalFiltersOptions?.projects);
  };

  const handleMonthFilterOnMenuClose = () => {
    const months = multiSelectedValues?.months;
    getCreativeDataOnMonths({ months }, getMonthsList);
  };

  return (
    <>
      {/* render filters */}
      {/* {getMonthsList?.length ? ( */}
      <CreativeProjectFilters
        isApiCall={isLoading?.loader}
        hideFilters={!getMonthsList?.length > 0}
        isDropDownDisabled={
          isLoading?.loader || isTableLoading?.loader || isLoadingGraph?.loader
        }
        selectedAccountType={selectedAccountType}
        globalFiltersOptions={globalFiltersOptions}
        multiSelectedValues={multiSelectedValues}
        setMultiSelectedValues={setMultiSelectedValues}
        selectedVendorReporting={selectedVendorReporting}
        setSelectedVendorReporting={setSelectedVendorReporting}
        handleMultiSelectChange={handleMultiSelectChange}
        getProjectsFilter={getProjectsFilter}
        handleApplyOnClick={handleApplyOnClick}
        displayAccountTypeFilter={displayAccountTypeFilter}
        handleMonthFilterOnMenuClose={handleMonthFilterOnMenuClose}
      />
      {/* ) : null} */}
      <>
        {isLoading.loader ? (
          <WhiteCard>
            <PageLoader
              className="pb-2"
              component="creative-project-graph"
              color="#FF5933"
              type="detail"
              width={40}
              height={40}
            />
          </WhiteCard>
        ) : (
          <>
            {getMonthsList?.length ? (
              <>
                {/* render performance tab */}
                <CreativeProjectPerformanceSummary
                  hideUploadCreativeProjectCTA={hideUploadCreativeProjectCTA}
                  afterDaysDateDifference={afterDaysDateDifference}
                  afterDaysValue={afterDaysValue}
                  setAfterDaysValue={setAfterDaysValue}
                  isLoadingGraph={isLoadingGraph}
                  groupBy={groupBy}
                  multiSelectedValues={multiSelectedValues}
                  creativeProjectData={creativeProjectData}
                  creativeProjectChartData={creativeProjectChartData}
                  allowedToUploadDeleteProject={allowedToUploadDeleteProject}
                  assignedBGSManager={assignedBGSManager}
                  loggedInRole={loggedInRole}
                  setShowCreativeUploadScreen={setShowCreativeUploadScreen}
                  selectedProjects={selectedProjects}
                  setMultiSelectedValues={setMultiSelectedValues}
                  accountType={selectedAccountType?.value}
                  selectedVendorReporting={selectedVendorReporting}
                  currencySymbolForGraph={currencySymbolForGraph}
                  handleGroupBy={handleGroupBy}
                  params={params}
                  isAllowToDeleteProject={isAllowToDeleteProject}
                  getCreativeMonths={getMonthsDropwonOptions}
                  customerId={id}
                  globalFiltersOptions={globalFiltersOptions}
                  setGlobalFiltersOptions={setGlobalFiltersOptions}
                  setCallGraphApi={setCallGraphApi}
                  selectedCreativeProjectMetrics={
                    selectedCreativeProjectMetrics
                  }
                  setSelectedCreativeProjectMetrics={
                    setSelectedCreativeProjectMetrics
                  }
                  setIsSelectProjectDropdownOption={
                    setIsSelectProjectDropdownOption
                  }
                  isSpreetailUser={isSpreetailUser}
                />

                {/* render project and asin table */}
                <WhiteCard className="mb-3">
                  {isTableLoading?.loader ? (
                    <PageLoader
                      component="performance-graph"
                      color="#FF5933"
                      type="detail"
                      width={40}
                      height={40}
                    />
                  ) : (
                    <ProjectAsinsTable
                      multiSelectedValues={multiSelectedValues}
                      tableInfoDetails={tableInfoDetails}
                      projectDetails={selectedProjects.table}
                      id={id}
                      params={params}
                      filterationData={multiSelectedValues}
                      pageNumber={pageNumber}
                      asinCounts={asinCounts}
                      handlePageChange={handlePageChange}
                      showProjectAsinTable={showProjectAsinTable}
                      setShowProjectAsinTable={setShowProjectAsinTable}
                      getTotalOfTable={getTotalOfTable}
                      setPaginationChecksum={setPaginationChecksum}
                      setPageNumber={setPageNumber}
                      paginationChecksum={paginationChecksum}
                      selectedVendorReporting={selectedVendorReporting}
                    />
                  )}
                </WhiteCard>
              </>
            ) : (
              //  project upload functionality
              <>
                <div className="text-center">
                  <NoRecordFound
                    type={
                      allowedToUploadDeleteProject ||
                      assignedBGSManager ||
                      loggedInRole === 'BGS Admin'
                        ? 'creative-noData'
                        : 'creative'
                    }
                    title="We looked high and low, but…"
                    subTitle="No records found."
                  />
                </div>
                {hideUploadCreativeProjectCTA ? null : allowedToUploadDeleteProject ||
                  assignedBGSManager ||
                  loggedInRole === 'BGS Admin' ? (
                  <UploadCreativeAsins
                    id={id}
                    setShowCreativeUploadScreen={setShowCreativeUploadScreen}
                    accountType={selectedAccountType?.value}
                    type="noData"
                    getMonthsDropwonOptions={getMonthsDropwonOptions}
                  />
                ) : null}
              </>
            )}
          </>
        )}
      </>
    </>
  );
}

CreativeProjectContainer.defaultProps = {
  assignedBGSManager: false,
  resetCreativeProjectFilters: false,

  id: '',
  loggedInRole: '',

  selectedAccountType: {},

  setResetCreativeProjectFilters: () => {},
  setShowCreativeUploadScreen: () => {},
  setIsDisabledRefreshBtn: () => {},
  displayAccountTypeFilter: () => {},
};

CreativeProjectContainer.propTypes = {
  assignedBGSManager: bool,
  resetCreativeProjectFilters: bool,

  id: string,
  loggedInRole: string,

  selectedAccountType: shape({}),

  setShowCreativeUploadScreen: func,
  setResetCreativeProjectFilters: func,
  setIsDisabledRefreshBtn: func,
  displayAccountTypeFilter: func,
};
