/* eslint-disable no-lonely-if */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import NumberFormat from 'react-number-format';
import { shape, string, func } from 'prop-types';

import { CheckBox, ErrorMsg, InputFormField } from '../../../../common';

export default function DspMarketplaceBudgetAllocations({
  item,
  formData,
  type,
  selectedCurrency,
  setSectionError,
  sectionError,
  showError,
  setShowError,
  updatedFormData,
  setUpdatedFormData,
  runValidateBudget,
  showFooter,
  setFormData,
  dspMarketpalcesError,
  setDspMarketpalcesError,
  displayMarketplaceName,
}) {
  const budgetType = 'One time';
  const oneTimeBudget = [];
  const totalOneTimeBudget = 0;

  useEffect(() => {
    if (formData?.dsp_marketplaces?.length) {
      for (const market of formData?.dsp_marketplaces) {
        market.dsp_fee = formData?.dsp_fee || null;
        market.dsp_one_time_fee = formData?.dsp_one_time_fee || null;
        market.monthly_budget_distribution_equally =
          formData?.monthly_budget_distribution_equally || false;
        market.one_time_budget_distribution_equally =
          formData?.one_time_budget_distribution_equally || false;
        market.changed_dsp_start_date =
          formData.changed_dsp_start_date || false;

        if (formData?.dsp_marketplaces?.length === 1) {
          market.one_time_budget = formData?.dsp_one_time_fee || null;
        } else {
          const budgetValue = market.one_time_budget
            ? Number(market.one_time_budget)
            : formData?.dsp_one_time_fee / formData?.dsp_marketplaces?.length;
          market.one_time_budget = budgetValue
            ?.toFixed(2)
            ?.replaceAll('.00', '');
        }
      }
    }
  }, [
    formData.dsp_marketplaces,
    formData.changed_dsp_start_date,
    formData.dsp_one_time_fee,
    formData.one_time_budget_distribution_equally,
  ]);

  const getDspNumber = (section) => {
    if (section.dsp === 0) return 1;
    return section.dsp;
  };

  const getErrorCount = () => {
    if (
      showError[`${type}_length`] ||
      showError?.oneTime ||
      showError?.firstBudgetAllocationError ||
      showError?.secondBudgetAllocationError ||
      showError?.thirdBudgetAllocationError
    ) {
      setSectionError((prevState) => ({
        ...prevState,
        dsp: prevState.dsp + 1,
      }));
    }
    if (
      !showError[`${type}_length`] ||
      (!showError?.firstBudgetAllocationError &&
        !showError?.secondBudgetAllocationError &&
        !showError?.thirdBudgetAllocationError) ||
      !showError?.oneTime
    )
      setSectionError((prevState) => ({
        ...prevState,
        dsp: getDspNumber(prevState) - 1,
      }));
  };

  const validateBudget = (key, budgetArray, totalBudget, adBudget) => {
    if (formData?.dsp_marketplaces?.length) {
      for (const market of formData?.dsp_marketplaces) {
        market[key] =
          typeof market[key] === 'string'
            ? market?.[key]?.replaceAll(',', '')
            : market[key];
        budgetArray.push(Number(market?.[key]) || 0);
      }

      totalBudget = budgetArray.reduce((temp1, temp2) => temp1 + temp2);
      if (formData?.dsp_marketplaces?.filter((op) => !op?.[key])?.length) {
        setShowError((prevState) => ({
          ...prevState,
          [type]: false,
        }));
      } else {
        if (totalBudget !== Number(adBudget || 0)) {
          // check the total budget matches the allocated budget
          setShowError((prevState) => ({
            ...prevState,
            [type]: true,
          }));
          getErrorCount();
        } else {
          setShowError((prevState) => ({
            ...prevState,
            [type]: false,
          }));
          getErrorCount();
        }
      }
    }

    // check the allocation bugdet lengths
    if (formData?.dsp_marketplaces?.filter((op) => !op?.[key])?.length !== 0) {
      setShowError((prevState) => ({
        ...prevState,
        [`${type}_length`]: true,
      }));
      getErrorCount();
    } else {
      setShowError((prevState) => ({
        ...prevState,
        [`${type}_length`]: false,
      }));
      getErrorCount();
    }
  };

  const budgetTypeValidation = () => {
    if (type === 'oneTime') {
      validateBudget(
        'one_time_budget',
        oneTimeBudget,
        totalOneTimeBudget,
        formData?.dsp_one_time_fee,
      );
    }
  };

  useEffect(() => {
    budgetTypeValidation();
    getErrorCount();
  }, [
    runValidateBudget,
    formData.dsp_marketplaces,
    formData.dsp_one_time_fee,
    formData.changed_dsp_start_date,
    formData.dsp_fee,
    formData?.one_time_budget_distribution_equally,
  ]);

  const convertMarketplacesToNumber = (key, updatedDspMarketplace) => {
    return updatedDspMarketplace.filter((market) => {
      market[key] =
        typeof market[key] === 'string'
          ? market[key]?.replaceAll(',', '')
          : market[key];
      return Number(market[key]);
    });
  };

  const handleChange = (field, key, value, marketplace, budget) => {
    showFooter(true);
    if (dspMarketpalcesError && Object.keys(dspMarketpalcesError).length)
      setDspMarketpalcesError({ ...dspMarketpalcesError, [key]: '' });
    const updatedDspMarketplace = [...formData?.dsp_marketplaces];
    if (field === 'budget') {
      for (const market of formData?.dsp_marketplaces) {
        if (market.name === marketplace) {
          market[key] = value;
        }
        market.dsp_fee = formData?.dsp_fee || null;
        market.dsp_one_time_fee = formData?.dsp_one_time_fee || null;
        market.monthly_budget_distribution_equally =
          formData?.monthly_budget_distribution_equally || false;
        market.one_time_budget_distribution_equally =
          formData?.one_time_budget_distribution_equally || false;
        market.changed_dsp_start_date =
          formData.changed_dsp_start_date || false;
      }
      convertMarketplacesToNumber(key, updatedDspMarketplace);
      setUpdatedFormData({
        ...updatedFormData,
        dsp_marketplaces: {
          ...updatedFormData.dsp_marketplaces,
          create: updatedDspMarketplace,
        },
      });
    }
    if (field === 'checkbox') {
      const budgetValue =
        formData?.[marketplace] / formData?.dsp_marketplaces?.length;
      for (const market of formData?.dsp_marketplaces) {
        market[budget] = budgetValue?.toFixed(2)?.replaceAll('.00', '');
      }
      convertMarketplacesToNumber(key, updatedDspMarketplace);
      setUpdatedFormData({
        ...updatedFormData,
        [key]: value,
        dsp_marketplaces: {
          ...updatedFormData.dsp_marketplaces,
          create: updatedDspMarketplace,
        },
      });
      setFormData({ ...formData, [key]: value });
    }
  };

  const generateInput = () => {
    return formData?.dsp_marketplaces?.map((field) => (
      <>
        <InputFormField className="mt-2">
          <label htmlFor={`${type}_${field.name}`}>
            {displayMarketplaceName(field.name, 'dsp', 'lhs')}
            <div className="input-container">
              <span className="input-icon">{selectedCurrency} </span>
              <NumberFormat
                name={`${type}_${field.name}`}
                className={`${
                  formData?.one_time_budget_distribution_equally
                    ? 'disabled'
                    : ''
                } ${
                  (sectionError?.dsp &&
                    (showError[type] || showError[`${type}_length`])) ||
                  showError[type] ||
                  showError[`${type}_length`]
                    ? 'form-control form-control-error modal-input-control'
                    : 'form-control modal-input-control'
                }
                `}
                placeholder="Enter Budget"
                onChange={(event) => {
                  handleChange(
                    'budget',
                    type === 'monthly' ? 'monthly_budget' : 'one_time_budget',
                    event.target.value,
                    field.name,
                  );
                  budgetTypeValidation();
                }}
                value={
                  formData?.dsp_marketplaces?.length === 1
                    ? type === 'monthly'
                      ? formData?.dsp_fee
                      : formData?.dsp_one_time_fee
                    : type === 'monthly'
                    ? field.monthly_budget
                    : field.one_time_budget
                }
                thousandSeparator
                allowNegative={false}
                disabled={formData?.dsp_marketplaces?.length === 1}
                decimalScale={2}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  if (floatValue == null) {
                    return formattedValue === '';
                  }
                  return floatValue > 0;
                }}
              />
            </div>
          </label>
        </InputFormField>
      </>
    ));
  };

  return (
    <>
      {!formData?.dsp_marketplaces?.length ? null : (
        <>
          <div className=" liner-titles spacing mt-3 mb-3" key={item.key}>
            {`${budgetType} Budget Allocation`}
          </div>
          <p>
            {`Allocate the ${budgetType} budget over the DSP marketplaces to create
        the DSP invoice accordingly.`}
          </p>
          {formData?.dsp_marketplaces?.length === 1 ? null : (
            <CheckBox>
              <label
                className="check-container customer-pannel"
                htmlFor={budgetType}
              >
                Distribute Budget Equally
                <input
                  type="checkbox"
                  name={budgetType}
                  id={budgetType}
                  onChange={(event) => {
                    handleChange(
                      'checkbox',
                      type === 'monthly'
                        ? 'monthly_budget_distribution_equally'
                        : 'one_time_budget_distribution_equally',
                      event.target.checked,
                      type === 'monthly' ? 'dsp_fee' : 'dsp_one_time_fee',
                      type === 'monthly' ? 'monthly_budget' : 'one_time_budget',
                    );
                    if (
                      dspMarketpalcesError &&
                      Object.keys(dspMarketpalcesError).length
                    )
                      setDspMarketpalcesError({
                        ...dspMarketpalcesError,
                        [type === 'monthly'
                          ? 'monthly_budget'
                          : 'one_time_budget']: '',
                      });
                  }}
                  defaultChecked={
                    formData?.one_time_budget_distribution_equally
                  }
                />
                <span className="checkmark" />
              </label>
            </CheckBox>
          )}

          {generateInput()}
          <ErrorMsg>{dspMarketpalcesError?.one_time_budget}</ErrorMsg>

          {dspMarketpalcesError &&
          Object.keys(dspMarketpalcesError).length === 0 &&
          showError[type] ? (
            <ErrorMsg>
              {`Please allocate marketplaces amount equal to the ${budgetType} budget amount.`}
            </ErrorMsg>
          ) : null}
        </>
      )}
    </>
  );
}

DspMarketplaceBudgetAllocations.defaultProps = {
  formData: {},
  item: {},
  type: 'monthly',
  selectedCurrency: '$ (USD)',
  setFormData: () => {},
  sectionError: {},
  setSectionError: () => {},
  showError: {
    monthly: false,
    oneTime: false,
  },
  setShowError: () => {},
  updatedFormData: {},
  setUpdatedFormData: () => {},
  runValidateBudget: {},
  showFooter: () => {},
  dspMarketpalcesError: {},
  setDspMarketpalcesError: () => {},
  displayMarketplaceName: () => {},
};
DspMarketplaceBudgetAllocations.propTypes = {
  formData: shape({}),
  item: shape({}),
  type: string,
  selectedCurrency: string,
  setFormData: func,
  sectionError: shape({}),
  setSectionError: func,
  showError: shape({}),
  setShowError: func,
  updatedFormData: shape({}),
  setUpdatedFormData: func,
  runValidateBudget: shape({}),
  showFooter: func,
  dspMarketpalcesError: shape({}),
  setDspMarketpalcesError: func,
  displayMarketplaceName: func,
};
