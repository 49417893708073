import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import PendingApprovalsDashboard from '../AdManagerAdminDashboard/PendingApprovalsDashboard/PendingApprovalsDashboardContainer';
import { getPendingApprovalRequests } from '../../../api/PendingApprovalsDashboardApi';
import { Tabs } from '../../../common';
import { DashboardCard } from '../../../theme/Global';
import {
  PATH_DASHBOARD,
  pendingApprovalOrderOptions,
  pendingApprovalTab,
} from '../../../constants';

export default function PendingApprovalsContainerForOtherRole() {
  const mounted = useRef(false);
  const history = useHistory();

  const [viewComponent, setViewComponent] = useState('pendingApprovals');
  const [pendingApprovalListDataCount, setPendingApprovalListDataCount] =
    useState(0);
  const [callPendingApprovalAPI, setCallPendingApprovalAPI] = useState(true);

  // to disaply the pending aprroval count, we need to call API
  const getPendingApprovalData = useCallback(() => {
    getPendingApprovalRequests(pendingApprovalOrderOptions[0]?.value, '', 1)
      .then((res) => {
        if (mounted.current) {
          if (res?.status === 200) {
            setPendingApprovalListDataCount(res?.data?.count || 0);
          } else {
            setPendingApprovalListDataCount(0);
          }
          setCallPendingApprovalAPI(false);
        }
      })
      .catch(() => {
        setPendingApprovalListDataCount(0);
      });
  }, []);

  useEffect(() => {
    if (callPendingApprovalAPI) {
      mounted.current = true;
      getPendingApprovalData();
    }
    return () => {
      mounted.current = false;
    };
  }, [getPendingApprovalData, callPendingApprovalAPI]);

  return (
    <DashboardCard>
      <div className="dashboard-container-body">
        <Tabs className="mb-3">
          <ul className="tabs">
            {pendingApprovalTab.map((navigation) => (
              <>
                <li
                  key={navigation.key}
                  className={viewComponent === navigation.key ? 'active' : ''}
                  onClick={() => {
                    setViewComponent(navigation.key);
                    history.push(`${PATH_DASHBOARD}pendingApprovals`);
                  }}
                  role="presentation"
                >
                  {navigation.value}
                  {navigation.key === 'pendingApprovals' ? (
                    <span className="circle-dot">
                      {pendingApprovalListDataCount}
                    </span>
                  ) : null}
                </li>
              </>
            ))}
          </ul>

          <Switch>
            <Route path={`${PATH_DASHBOARD}pendingApprovals`}>
              <PendingApprovalsDashboard
                setCallPendingApprovalAPI={setCallPendingApprovalAPI}
              />
            </Route>

            <Route
              path={`${PATH_DASHBOARD}`}
              render={() => (
                <Redirect to={`${PATH_DASHBOARD}pendingApprovals`}>
                  <PendingApprovalsDashboard
                    setCallPendingApprovalAPI={setCallPendingApprovalAPI}
                  />
                </Redirect>
              )}
            />
          </Switch>
        </Tabs>
      </div>
    </DashboardCard>
  );
}

PendingApprovalsContainerForOtherRole.defaultProps = {};
PendingApprovalsContainerForOtherRole.propTypes = {};
