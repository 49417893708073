// react imports
import React from 'react';

// third party imports
import _ from 'lodash';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

// local file imports
import Theme from '../../../../theme/Theme';
import {
  PageLoader,
  Tabs,
  WhiteCard,
  CommonPagination,
  GrayMessageBar,
  NoData,
} from '../../../../common';
import {
  dspTabOptions,
  noGraphDataMessage,
  metricsCurrency,
  CUSTOM,
  DSPPERFORMANCE,
} from '../../../../constants';

const DSPKeyContributors = ({
  isApiCall,
  downloadReportLoader,
  count,
  pageNumber,
  currencySymbol,
  selectedTabMetrics,
  selectedDspDF,
  selectedDSPMatrics,
  selectedBrandPartner,
  contributionData,
  handlePageChange,
  handleOnMatricsTabChange,
  displayContributionTable,
  handleKeyContributorsDownloadReport,
}) => {
  const dashboardDynamicError = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  // function - display dsp key contributor tabs
  const renderKeyContributionTabs = () => {
    let selectedTab = selectedTabMetrics;
    if (selectedDSPMatrics[selectedTabMetrics] === undefined) {
      const selctedArray = _.keys(selectedDSPMatrics);
      selectedTab = selctedArray?.[0];
      handleOnMatricsTabChange(selectedTab);
    }

    return (
      <Tabs>
        <ul className={isApiCall ? 'tabs disabled' : 'tabs'}>
          {_.keys(selectedDSPMatrics).map((item) => (
            <li
              id={`BT-${item}-tab`}
              key={item}
              className={selectedTabMetrics === item ? 'active' : ''}
              onClick={() => handleOnMatricsTabChange(item)}
              role="presentation"
            >
              {dspTabOptions[item]}
            </li>
          ))}
        </ul>
      </Tabs>
    );
  };

  // display contribution data table
  const dispalyContributionDataTable = () => {
    let prefix = '';
    let suffix = '';
    let decimalDigits = 2;
    if (metricsCurrency[selectedTabMetrics]?.type === 'currency') {
      decimalDigits = 2;
      prefix = currencySymbol;
      suffix = '';
    }
    if (metricsCurrency[selectedTabMetrics]?.type === 'percentage') {
      decimalDigits = 2;
      prefix = '';
      suffix = '%';
    }
    if (metricsCurrency[selectedTabMetrics].type === 'nocurrency') {
      decimalDigits = 0;
      prefix = '';
      suffix = '';
    }
    const isGrayMetrics = selectedTabMetrics === 'dspSpend';

    return isApiCall ? (
      <PageLoader
        component="performance-graph"
        color="#FF5933"
        type="detail"
        width={40}
        height={40}
      />
    ) : (
      <>
        {displayContributionTable(
          contributionData,
          prefix,
          suffix,
          decimalDigits,
          isGrayMetrics,
          DSPPERFORMANCE,
          selectedBrandPartner?.value === 'all',
        )}
        {contributionData?.length >= 1 ? (
          <CommonPagination
            count={count}
            pageNumber={pageNumber}
            handlePageChange={handlePageChange}
          />
        ) : null}
        {!contributionData || contributionData?.length === 0 ? (
          <NoData>{noGraphDataMessage}</NoData>
        ) : null}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <Wrapper id="BT-dsp-key-contributors">
      <WhiteCard className="mb-3">
        <div className="row">
          <div className="col-md-6 col-sm1-12">
            <p className="black-heading-title mt-2 mb-4">Contributors</p>
          </div>
          <div className="col-md-6 col-12 pr-2 text-right">
            <div
              className={`black-heading-title text-medium-font m-0 cursor ${
                isApiCall ||
                downloadReportLoader ||
                selectedDspDF?.value === CUSTOM
                  ? 'disabled'
                  : ''
              }`}
              role="presentation"
              onClick={() => {
                handleKeyContributorsDownloadReport();
              }}
              style={{ maxWidth: '150px' }}
            >
              {downloadReportLoader ? (
                <PageLoader
                  component="sidebar-loader"
                  type="button"
                  color={Theme?.orange}
                />
              ) : (
                <>
                  <FontAwesomeIcon
                    className="mr-2"
                    color={Theme?.orange}
                    icon="far fa-arrow-to-bottom"
                  />
                  Download Report
                </>
              )}
            </div>
          </div>

          <div className="col-12">
            {dashboardDynamicError?.dsp_performance?.contribution ? (
              <GrayMessageBar
                message={dashboardDynamicError?.dsp_performance?.contribution}
              />
            ) : null}
          </div>
        </div>
        {selectedDspDF.value === CUSTOM ? (
          <NoData>
            Top contributors cannot be calculated when using custom dates.
          </NoData>
        ) : (
          <>
            {renderKeyContributionTabs()}
            {dispalyContributionDataTable()}
          </>
        )}
      </WhiteCard>
    </Wrapper>
  );
};

export default DSPKeyContributors;

DSPKeyContributors.defaultProps = {
  isApiCall: false,
  downloadReportLoader: false,
  count: 0,
  pageNumber: 1,
  currencySymbol: '$',
  selectedTabMetrics: 'dspImpression',
  selectedDspDF: {},
  selectedDSPMatrics: {},
  selectedBrandPartner: {},
  contributionData: [],
  handlePageChange: () => {},
  handleOnMatricsTabChange: () => {},
  displayContributionTable: () => {},
  handleKeyContributorsDownloadReport: () => {},
};

DSPKeyContributors.propTypes = {
  isApiCall: bool,
  downloadReportLoader: bool,
  count: number,
  pageNumber: number,
  currencySymbol: string,
  selectedTabMetrics: string,
  selectedBrandPartner: shape({}),
  selectedDSPMatrics: shape({}),
  selectedDspDF: shape({}),
  contributionData: arrayOf(shape({})),
  handlePageChange: func,
  handleOnMatricsTabChange: func,
  displayContributionTable: func,
  handleKeyContributorsDownloadReport: func,
};

const Wrapper = styled.div`
  td {
    padding: 20px 10px 3px 0px !important;
  }
  .statusContainer {
    margin-top: 0px !important;
  }
`;
