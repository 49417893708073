import dayjs from 'dayjs';
import { CUSTOM, ORDEREDREVENUE } from '../../../../../constants';

const BindVendorSalesPerformanceData = (
  responseData,
  vendorMetricsType,
  selectedDailyFact,
) => {
  const tempMetricsData = [];
  const previousData =
    selectedDailyFact !== CUSTOM ? responseData?.previous : [];
  const currentdata = responseData?.current;
  const salesCurrentTotal = responseData?.current_sum
    ? responseData.current_sum
    : null;
  const salesPreviousTotal = responseData?.previous_sum
    ? responseData.previous_sum
    : null;
  const salesDifference = responseData?.difference_data
    ? responseData.difference_data
    : null;

  // filterout previous data in one temporary object.
  if (previousData?.length) {
    previousData.forEach((item) => {
      const previousDate = dayjs(item?.report_date).format('MMM D YYYY');
      if (vendorMetricsType === ORDEREDREVENUE) {
        tempMetricsData.push({
          orderedRevenuePrevious: item?.ordered_revenue
            ? item.ordered_revenue
            : null,
          glanceViewsPrevious: item?.glance_views ? item.glance_views : null,
          conversionRatePrevious: item?.conversion_rate
            ? item.conversion_rate
            : null,
          orderedUnitsPrevious: item?.ordered_units ? item.ordered_units : null,
          previousDate,

          orderedRevenuePreviousLabel: item?.ordered_revenue
            ? item.ordered_revenue.toFixed(2)
            : '0.00',
          glanceViewsPreviousLabel: item?.glance_views
            ? item.glance_views.toFixed(0)
            : '0',
          orderedUnitsPreviousLabel: item?.ordered_units
            ? item.ordered_units.toFixed(0)
            : '0',
          conversionRatePreviousLabel: item?.conversion_rate
            ? item.conversion_rate.toFixed(2)
            : '0',
        });
      } else {
        tempMetricsData.push({
          shippedCOGsPrevious: item?.shipped_cogs ? item.shipped_cogs : null,
          glanceViewsPrevious: item?.glance_views ? item.glance_views : null,
          conversionRatePrevious: item?.conversion_rate
            ? item.conversion_rate
            : null,
          shippedUnitsPrevious: item?.shipped_units ? item.shipped_units : null,
          previousDate,

          shippedCOGsPreviousLabel: item?.shipped_cogs
            ? item.shipped_cogs.toFixed(2)
            : '0.00',
          glanceViewsPreviousLabel: item?.glance_views
            ? item.glance_views.toFixed(0)
            : '0',
          shippedUnitsPreviousLabel: item?.shipped_units
            ? item.shipped_units.toFixed(0)
            : '0',
          conversionRatePreviousLabel: item?.conversion_rate
            ? item.conversion_rate.toFixed(2)
            : '0',
        });
      }
    });
  }

  // filterout current data in one temporary object.
  if (currentdata?.length) {
    currentdata.forEach((item, index) => {
      const currentReportDate = dayjs(item?.report_date).format('MMM D YYYY');
      // let indexNumber = index;
      // add the current data at same index of prevoius in temporary object
      if (previousData && index < previousData?.length) {
        if (vendorMetricsType === ORDEREDREVENUE) {
          tempMetricsData[index].orderedRevenueCurrent = item?.ordered_revenue
            ? item.ordered_revenue
            : null;
          tempMetricsData[index].glanceViewsCurrent = item?.glance_views
            ? item.glance_views
            : null;
          tempMetricsData[index].orderedUnitsCurrent = item?.ordered_units
            ? item.ordered_units
            : null;
          tempMetricsData[index].conversionRateCurrent = item?.conversion_rate
            ? item.conversion_rate
            : null;

          tempMetricsData[index].date = currentReportDate;

          tempMetricsData[index].orderedRevenueCurrentLabel =
            item?.ordered_revenue ? item.ordered_revenue.toFixed(2) : '0.00';
          tempMetricsData[index].glanceViewsCurrentLabel = item?.glance_views
            ? item.glance_views.toFixed(0)
            : '0';
          tempMetricsData[index].orderedUnitsCurrentLabel = item?.ordered_units
            ? item.ordered_units.toFixed(0)
            : '0';
          tempMetricsData[index].conversionRateCurrentLabel =
            item?.conversion_rate ? item.conversion_rate.toFixed(2) : '0';
        } else {
          tempMetricsData[index].shippedCOGsCurrent = item?.shipped_cogs
            ? item.shipped_cogs
            : null;
          tempMetricsData[index].glanceViewsCurrent = item?.glance_views
            ? item.glance_views
            : null;
          tempMetricsData[index].shippedUnitsCurrent = item?.shipped_units
            ? item.shipped_units
            : null;
          tempMetricsData[index].conversionRateCurrent = item?.conversion_rate
            ? item.conversion_rate
            : null;

          tempMetricsData[index].date = currentReportDate;

          tempMetricsData[index].shippedCOGsCurrentLabel = item?.shipped_cogs
            ? item.shipped_cogs.toFixed(2)
            : '0.00';
          tempMetricsData[index].glanceViewsCurrentLabel = item?.glance_views
            ? item.glance_views.toFixed(0)
            : '0';
          tempMetricsData[index].shippedUnitsCurrentLabel = item?.shipped_units
            ? item.shipped_units.toFixed(0)
            : '0';
          tempMetricsData[index].conversionRateCurrentLabel =
            item?.conversion_rate ? item.conversion_rate.toFixed(2) : '0';
        }
      } else if (vendorMetricsType === ORDEREDREVENUE) {
        // if current data count is larger than previous count then
        // generate separate key for current data and defien previous value null and previous label 0

        tempMetricsData.push({
          orderedRevenueCurrent: item?.ordered_revenue
            ? item.ordered_revenue
            : null,
          glanceViewsCurrent: item?.glance_views ? item.glance_views : null,
          orderedUnitsCurrent: item?.ordered_units ? item.ordered_units : null,
          conversionRateCurrent: item?.conversion_rate
            ? item.conversion_rate
            : null,

          date: currentReportDate,

          orderedRevenuePrevious: null,
          glanceViewsPrevious: null,
          orderedUnitsPrevious: null,
          conversionRatePrevious: null,

          orderedRevenueCurrentLabel: item?.ordered_revenue
            ? item.ordered_revenue.toFixed(2)
            : '0.00',
          glanceViewsCurrentLabel: item?.glance_views
            ? item.glance_views.toFixed(0)
            : '0',
          orderedUnitsCurrentLabel: item?.ordered_units
            ? item.ordered_units.toFixed(0)
            : '0',
          conversionRateCurrentLabel: item?.conversion_rate
            ? item.conversion_rate.toFixed(2)
            : '0',

          orderedRevenuePreviousLabel: '0.00',
          glanceViewsPreviousLabel: '0',
          orderedUnitsPreviousLabel: '0',
          conversionRatePreviousLabel: '0',
        });
      } else {
        tempMetricsData.push({
          shippedCOGsCurrent: item?.shipped_cogs ? item.shipped_cogs : null,
          glanceViewsCurrent: item?.glance_views ? item.glance_views : null,
          conversionRateCurrent: item?.conversion_rate
            ? item.conversion_rate
            : null,
          shippedUnitsCurrent: item?.shipped_units ? item.shipped_units : null,

          date: currentReportDate,

          shippedCOGsPrevious: null,
          glanceViewsPrevious: null,
          shippedUnitsPrevious: null,
          conversionRatePrevious: null,

          shippedCOGsCurrentLabel: item?.shipped_cogs
            ? item.shipped_cogs.toFixed(2)
            : '0.00',
          glanceViewsCurrentLabel: item?.glance_views
            ? item.glance_views.toFixed(0)
            : '0',
          shippedUnitsCurrentLabel: item?.shipped_units
            ? item.shipped_units.toFixed(0)
            : '0',
          conversionRateCurrentLabel: item?.conversion_rate
            ? item.conversion_rate.toFixed(2)
            : '0',

          shippedCOGsPreviousLabel: '0.00',
          glanceViewsPreviousLabel: '0',
          shippedUnitsPreviousLabel: '0',
          conversionRatePreviousLabel: '0',
        });
      }
    });
  }

  return {
    metricsData: tempMetricsData,
    salesCurrentTotal,
    salesPreviousTotal,
    salesDifference,
  };
};
export default BindVendorSalesPerformanceData;
