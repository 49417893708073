/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { string, shape, bool, func, number, arrayOf } from 'prop-types';

import AmazonVideo from './Modals/AmazonVideo';
import {
  getMaketplaceCode,
  getMaketplaceRegion,
} from '../../Utils/MarketplaceFunctions';

import { userMe } from '../../store/actions';
import { VideoCall } from '../../theme/images';
import {
  SingleAccountMarketplaces,
  HybridAccountMarketplaces,
} from './Marketplaces';
import {
  askSomeoneData,
  updateAskSomeoneData,
  updateUserMe,
  saveSellerMarketplaces,
  saveVendorMarketplaces,
  saveSPData,
  getSPData,
  getCustomerDetails,
} from '../../api';
import {
  PATH_AMAZON_MERCHANT,
  PATH_SUMMARY,
  PATH_THANKS,
  PATH_UNAUTHORIZED_AMAZON_MERCHANT,
  amazonSellerAccountDetails,
  amazonVendorAccountDetails,
  stepPath,
} from '../../constants';
import {
  OnBoardingBody,
  InputFormField,
  Button,
  PageLoader,
  ErrorMsg,
  Status,
} from '../../common';

export default function AmazonMerchant({
  setIsLoading,
  assignedToSomeone,
  stepData,
  verifiedStepData,
  userInfo,
  isLoading,
  isChecked,
  noAmazonAccount,
  showVideo,
  marketplaceDetails,
  videoData,
  setShowVideo,
  apiError,
  setApiError,
  setFormData,
  secondaryMarketplaces,
  sellerMarketplaces,
  setSellerMarketplaces,
  vendorMarketplaces,
  setVendorMarketplaces,
  localSeller,
  localVendor,
  summaryData,
  localNoAmazonAccount,
}) {
  const customVideostyle = {
    width: '16px',
    marginRight: '6px',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
  };

  const baseURL = 'https://authorize.biusers.com';
  const url = 'bispoke.io';
  const userEmail = userInfo?.email || verifiedStepData?.reassigned_email;
  const apiType = 'spapi';
  const agencyName = 'BBE';

  const authenticationURL = `${baseURL}?&url=${url}&email=${userEmail}&api_type=${apiType}&agency_name=${agencyName}`;

  const history = useHistory();
  const dispatch = useDispatch();
  const params = queryString.parse(history.location.search);
  const [isAuthorized, setIsAuthorized] = useState({});
  const [customerData, setCustomerData] = useState({});

  const accountRedirectURL = history?.location?.pathname.includes(
    PATH_AMAZON_MERCHANT,
  )
    ? process.env.REACT_APP_SP_API_ONBOARDING_URL
    : process.env.REACT_APP_SP_API_ONBOARDING_ASSIGNED_URL;

  const getIncompleteStep = summaryData.find(
    (op) =>
      Object.keys(op)[0] !== 'merchant id' && Object.values(op)[0] === false,
  );

  const CheckStep = (step) => {
    if (step === 'amazon account' || getIncompleteStep === undefined) {
      history.push(PATH_SUMMARY);
    } else {
      for (const item of stepPath) {
        if (Object.keys(getIncompleteStep)[0] === item.key) {
          history.push(item.view);
        }
      }
    }
  };
  useEffect(() => {
    getCustomerDetails(
      userInfo?.customer || verifiedStepData?.customer_id,
    ).then((res) => {
      setCustomerData(res?.data);
    });
  }, []);

  useEffect(() => {
    setFormData({
      Seller: marketplaceDetails.Seller,
      Vendor: marketplaceDetails.Vendor,
    });

    // get seller and vendor market places from localstorage if available
    if (localSeller?.length) {
      setSellerMarketplaces(localSeller);
    }
    if (localVendor?.length) {
      setVendorMarketplaces(localVendor);
    }
  }, [marketplaceDetails]);

  useEffect(() => {
    // get sp data to check if already authorized
    if (userInfo?.customer || verifiedStepData.customer_id) {
      getSPData(userInfo?.customer || verifiedStepData.customer_id).then(
        (res) => {
          if (res.status === 200) {
            setIsAuthorized(res?.data);
          }
        },
      );
      // save the SP API data for authorization
      if (params && Object.keys(params)?.length > 1) {
        const spData =
          localStorage.getItem('spAPI') &&
          JSON.parse(localStorage.getItem('spAPI'));
        const data = {
          customer: userInfo?.customer || verifiedStepData.customer_id,
          marketplace: spData?.marketplace,
          account_type: spData?.type,
          spapi_oauth_code: params.spapi_oauth_code,
          state: params.state,
          selling_partner_id: params.selling_partner_id,
          redirct_uri: history.location.pathname,
        };

        saveSPData(data).then((res) => {
          if (res.status === 201) {
            getSPData(userInfo?.customer || verifiedStepData.customer_id).then(
              (r) => {
                if (r.status === 200) {
                  setIsAuthorized(r?.data);
                }
              },
            );
            if (history?.location?.pathname.includes(PATH_AMAZON_MERCHANT)) {
              history.push(PATH_AMAZON_MERCHANT);
            } else {
              history.push({
                pathname: PATH_UNAUTHORIZED_AMAZON_MERCHANT,
                search: `?key=${localStorage?.getItem('match')}`,
              });
            }
          }
        });
      }
    }
  }, [userInfo?.customer]);

  const saveDetails = () => {
    setIsLoading({ loader: true, type: 'button' });
    if (
      (stepData === undefined ||
        (stepData &&
          Object.keys(stepData) &&
          Object.keys(stepData).length === 0)) &&
      verifiedStepData &&
      Object.keys(verifiedStepData) &&
      Object.keys(verifiedStepData).length === 0
    ) {
      const detail = {
        is_completed: true,
        email: userInfo.email,
        step: 'merchant id',
        customer_onboarding: userInfo.customer_onboarding,
      };
      askSomeoneData(detail).then((stepResponse) => {
        if (stepResponse && stepResponse.status === 201) {
          if (assignedToSomeone) {
            const stringified =
              queryString &&
              queryString.stringify({
                name: verifiedStepData.user_name,
              });
            history.push({
              pathname: PATH_THANKS,
              search: `${stringified}`,
            });
          } else {
            CheckStep('merchant id');
          }
          updateUserMe(userInfo.id || verifiedStepData.user_id, {
            step: {
              ...(userInfo.step || verifiedStepData.user_step),
              [userInfo.customer || verifiedStepData.customer_id]: 4,
            },
          }).then((user) => {
            if (user && user.status === 200) {
              if (assignedToSomeone) {
                localStorage.removeItem('match');
              } else dispatch(userMe());
            }
          });
          setIsLoading({ loader: false, type: 'button' });
          localStorage.removeItem('seller');
          localStorage.removeItem('vendor');
          // localStorage.removeItem('noAmazonAccount');
        }
      });
    } else {
      updateAskSomeoneData(
        (stepData && stepData.id) || verifiedStepData.step_id,
        {
          token: assignedToSomeone ? params && params.key : '',
          is_completed: true,
        },
      ).then((response) => {
        if (response && response.status === 200) {
          if (assignedToSomeone) {
            const stringified =
              queryString &&
              queryString.stringify({
                name: verifiedStepData.user_name,
              });
            history.push({
              pathname: PATH_THANKS,
              search: `${stringified}`,
            });
          } else {
            CheckStep('merchant id');
          }
          updateUserMe(userInfo.id || verifiedStepData.user_id, {
            step: {
              ...(userInfo.step || verifiedStepData.user_step),
              [userInfo.customer || verifiedStepData.customer_id]: 4,
            },
          }).then((user) => {
            if (user && user.status === 200) {
              if (assignedToSomeone) {
                localStorage.removeItem('match');
              } else dispatch(userMe());
            }
          });
          setIsLoading({ loader: false, type: 'button' });
          localStorage.removeItem('seller');
          localStorage.removeItem('vendor');
          // localStorage.removeItem('noAmazonAccount');
        }
      });
    }
  };

  // Save Seller Account details
  const sellerAccount = (seller) => {
    saveSellerMarketplaces(seller, noAmazonAccount).then((res) => {
      if ((res && res.status === 201) || (res && res.status === 200))
        saveDetails();

      if (res && res.status === 400) {
        setApiError({ ...apiError, Seller: [...res?.data] });
        setIsLoading({ loader: false, type: 'button' });
      }
    });
  };

  // Save Vendor Account details
  const vendorAccount = (vendor) => {
    saveVendorMarketplaces(vendor, noAmazonAccount).then((res) => {
      if ((res && res.status === 201) || (res && res.status === 200))
        saveDetails();
      if (res && res.status === 400) {
        setApiError({ ...apiError, Vendor: [...res?.data] });
        setIsLoading({ loader: false, type: 'button' });
      }
    });
  };

  // Save complete account details (Seller & Vendor)
  const saveAccountDetails = (sellerID, vendorID) => {
    setIsLoading({ loader: true, type: 'button' });
    let seller = [];
    let vendor = [];

    // If don't have Amazon account yet then make all entries null except mentioned in condition for seller & vendor
    if (noAmazonAccount) {
      sellerMarketplaces.forEach((marketplace) => {
        Object.keys(marketplace).forEach((key) => {
          if (
            key !== 'marketplace' &&
            key !== 'id' &&
            key !== 'marketplace_name' &&
            key !== 'customer'
          ) {
            marketplace[key] = null;
            marketplace.merchant_id = null;
            marketplace.seller_central_name = null;
          }
          if (key === 'doesnt_advertise') {
            marketplace[key] = false;
          }
        });
      });
      vendorMarketplaces.forEach((marketplace) => {
        Object.keys(marketplace).forEach((key) => {
          if (
            key !== 'marketplace' &&
            key !== 'id' &&
            key !== 'marketplace_name' &&
            key !== 'customer'
          ) {
            marketplace[key] = null;
            marketplace.vendor_central_name = null;
            marketplace.vendor_code = null;
          }
          if (key === 'doesnt_advertise') {
            marketplace[key] = false;
          }
        });
      });
    }

    // Filter all the updated seller marketplaces from list as we only send those (patch)
    seller = sellerID
      ? sellerMarketplaces.filter((entry) => entry?.id)
      : [...sellerMarketplaces];

    // Filter all the updated vendors marketplaces from list as we only send those (patch)
    vendor = vendorID
      ? vendorMarketplaces.filter((entry) => entry?.id)
      : [...vendorMarketplaces];

    // Only if the account is Seller
    if (marketplaceDetails.type === 'Seller')
      sellerAccount(noAmazonAccount ? sellerMarketplaces : seller);

    // Only if the account is Vendor
    if (marketplaceDetails.type === 'Vendor')
      vendorAccount(noAmazonAccount ? vendorMarketplaces : vendor);

    // Only if the account is Hybrid
    if (
      marketplaceDetails.type === 'Hybrid' ||
      marketplaceDetails.type === null
    ) {
      const apiCalls = [];
      const sellerApi = saveSellerMarketplaces(
        noAmazonAccount ? sellerMarketplaces : seller,
        noAmazonAccount,
      );
      const vendorApi = saveVendorMarketplaces(
        noAmazonAccount ? vendorMarketplaces : vendor,
        noAmazonAccount,
      );

      apiCalls.push(sellerApi, vendorApi);

      axios.all(apiCalls).then(
        axios.spread((...res) => {
          if (
            ((res[0] && res[0].status === 201) ||
              (res[0] && res[0].status === 200)) &&
            ((res[1] && res[1].status === 201) ||
              (res[1] && res[1].status === 200))
          ) {
            saveDetails();
            setApiError({});
          }

          if (
            (res[0] && res[0].status === 400) ||
            (res[1] && res[1].status === 400)
          ) {
            let sellerErrors = [];
            let vendorErrors = [];
            if (res[0] && res[0].status === 400) {
              sellerErrors = res[0]?.data;
            }
            if (res[1] && res[1].status === 400) {
              vendorErrors = res[1]?.data;
            }
            if (sellerErrors?.length || vendorErrors?.length)
              setApiError({
                Seller: [...sellerErrors],
                Vendor: [...vendorErrors],
              });

            setIsLoading({ loader: false, type: 'button' });
            if (
              (marketplaceDetails.type === 'Hybrid' ||
                marketplaceDetails.type === 'Hybrid') &&
              res[0] &&
              res[0].status === 400
            ) {
              document.body.scrollTop = 500; // For Safari
              document.documentElement.scrollTop = 500; // For Chrome, Firefox, IE and Opera
            }
          }
        }),
      );
    }
  };

  const checkSuccessMsg = (part) => {
    if (
      (marketplaceDetails.type === 'Hybrid' ||
        marketplaceDetails.type === null ||
        marketplaceDetails.type === 'Seller') &&
      part === 1
    )
      return isAuthorized[marketplaceDetails?.marketplace]?.seller;
    return isAuthorized[marketplaceDetails?.marketplace]?.vendor;
  };

  // Generate Login in to your account step
  const generateAmazon = (part) => {
    return (
      <fieldset className="shape-without-border  w-430 mt-3 mb-2">
        <p className="account-steps m-0">Part {part}</p>
        Log into your Amazon{' '}
        {(marketplaceDetails.type === 'Hybrid' && part === 1) ||
        marketplaceDetails.type === 'Seller'
          ? 'Seller'
          : 'Vendor'}{' '}
        Central admin account for your{' '}
        {marketplaceDetails.marketplace ? (
          <strong>
            primary marketplace ({marketplaceDetails.marketplace}
            ).
          </strong>
        ) : (
          <strong>primary marketplace</strong>
        )}
        {checkSuccessMsg(part) ? (
          <>
            <Status
              className=""
              label="Authorization Successful."
              backgroundColor="#e3f2d2"
              icon
              labelColor="#33ac2e"
              width="100%"
              marginBottom="10px"
              marginTop="10px"
              fontIcon="far fa-check"
            />
            <div className="horizontal-line straight-line mt-5" />
          </>
        ) : (
          <a
            href={
              (marketplaceDetails.type === 'Hybrid' && part === 1) ||
              marketplaceDetails.type === 'Seller'
                ? encodeURI(
                    `${authenticationURL}&type=seller_central&companyName=${
                      customerData?.company_name
                    }&region=${getMaketplaceRegion(
                      marketplaceDetails?.marketplace_name,
                    )}&country=${getMaketplaceCode(
                      marketplaceDetails?.marketplace_name,
                    )}&source=${accountRedirectURL}`,
                  )
                : encodeURI(
                    `${authenticationURL}&type=vendor_central&companyName=${
                      customerData?.company_name
                    }&region=${getMaketplaceRegion(
                      marketplaceDetails?.marketplace_name,
                    )}&country=${getMaketplaceCode(
                      marketplaceDetails?.marketplace_name,
                    )}&source=${accountRedirectURL}`,
                  )
            }
            onClick={() =>
              localStorage.setItem(
                'spAPI',
                JSON.stringify({
                  type:
                    (marketplaceDetails.type === 'Hybrid' && part === 1) ||
                    marketplaceDetails.type === 'Seller'
                      ? 'seller'
                      : 'vendor',
                  marketplace: marketplaceDetails?.marketplaceURL,
                }),
              )
            }
            // target="_blank"
            // rel="noopener noreferrer"
          >
            <Button
              className={
                isChecked
                  ? 'btn-primary font-style-regular w-100 mt-4 isDisabled'
                  : 'btn-primary font-style-regular w-100 mt-4'
              }
            >
              Log into Amazon{' '}
              {(marketplaceDetails.type === 'Hybrid' && part === 1) ||
              marketplaceDetails.type === 'Seller'
                ? 'Seller'
                : 'Vendor'}{' '}
              Account
            </Button>
          </a>
        )}
      </fieldset>
    );
  };

  // Use this method to map errors
  const mapApiErrors = (key, marketplaceID, type, marketplaceName) => {
    const errors = apiError?.[type]?.find(
      (entry) =>
        entry?.marketplace_name?.[0] === marketplaceName ||
        entry?.marketplace?.[0] === marketplaceID,
    );
    return errors?.[key]?.[0];
  };

  // Use this methid to map basic details (Primary marketplace)
  const mapDefaultValues = (item, part) => {
    if (marketplaceDetails.type === 'Hybrid') {
      if (part === 2 || part === 3) {
        if (localSeller?.length) {
          if (marketplaceDetails?.Seller?.length)
            return marketplaceDetails?.Seller?.filter(
              (op) => op?.doesnt_advertise === undefined,
            )?.[0]?.[item];
        }
        return (
          marketplaceDetails &&
          marketplaceDetails.Seller &&
          marketplaceDetails.Seller[item]
        );
      }
    }
    if (part === 5 || part === 6) {
      if (localVendor?.length) {
        if (marketplaceDetails?.Vendor?.length)
          return marketplaceDetails?.Vendor?.filter(
            (op) => op?.doesnt_advertise === undefined,
          )?.[0]?.[item];
      }
      return (
        marketplaceDetails &&
        marketplaceDetails.Vendor &&
        marketplaceDetails.Vendor[item]
      );
    }
    if (localSeller?.length || localVendor?.length) {
      if (marketplaceDetails?.[marketplaceDetails.type]?.length)
        return marketplaceDetails?.[marketplaceDetails.type]?.filter(
          (op) => op?.doesnt_advertise === undefined,
        )?.[0]?.[item];
    }
    return (
      marketplaceDetails &&
      marketplaceDetails[marketplaceDetails.type] &&
      marketplaceDetails[marketplaceDetails.type][item]
    );
  };

  // Use this methid to map extra details (Secondary marketplaces)
  const mapSecondaryMarketplaceValues = (
    item,
    marketplaceID,
    type,
    marketplaceName,
  ) => {
    const accountType =
      type === 'Seller' ? 'seller_marketplace' : 'vendor_marketplace';
    const marketplace = secondaryMarketplaces.find(
      (entry) =>
        entry[accountType]?.id === marketplaceID &&
        entry[accountType]?.[type]?.marketplace_name === marketplaceName,
    );

    return marketplace?.[accountType]?.[type]?.[item];
  };

  const mapAccountAdvertiseNames = (item, value, accountID, entry, type) => {
    if (type === 'seller') {
      if (!accountID && item === 'seller_central_name')
        entry.seller_central_name = value;
      if (
        accountID &&
        item === 'seller_central_name' &&
        !entry?.doesnt_advertise
      )
        entry.seller_central_name = value;
      if (!accountID && item === 'advertiser_name')
        entry.advertiser_name = value;
      if (accountID && item === 'advertiser_name' && !entry?.doesnt_advertise)
        entry.advertiser_name = value;
    } else {
      if (!accountID && item === 'vendor_central_name')
        entry.vendor_central_name = value;
      if (
        accountID &&
        item === 'vendor_central_name' &&
        !entry?.doesnt_advertise
      )
        entry.vendor_central_name = value;
      if (!accountID && item === 'advertiser_name')
        entry.advertiser_name = value;
      if (accountID && item === 'advertiser_name' && !entry?.doesnt_advertise)
        entry.advertiser_name = value;
    }
  };

  // Add/Update Seller Marketplaces
  const updateSellerMarketplaces = (item, value, accountID) => {
    if (Object.keys(sellerMarketplaces).length > 0) {
      const updatedMarketplaces = sellerMarketplaces.map((entry) => {
        mapAccountAdvertiseNames(item, value, accountID, entry, 'seller');

        if (entry.marketplace === marketplaceDetails.marketplaceId)
          return accountID
            ? { ...entry, id: accountID, [item]: value }
            : { ...entry, [item]: value };
        return entry;
      });
      localStorage.setItem('seller', JSON.stringify([...updatedMarketplaces]));
      setSellerMarketplaces([...updatedMarketplaces]);
    } else {
      setSellerMarketplaces([
        ...sellerMarketplaces,
        { marketplace: marketplaceDetails.marketplaceId, [item]: value },
      ]);
      localStorage.setItem(
        'seller',
        JSON.stringify([
          ...sellerMarketplaces,
          { marketplace: marketplaceDetails.marketplaceId, [item]: value },
        ]),
      );
    }
  };

  // Add/Update Vendor Marketplaces
  const updateVendorMarketplaces = (item, value, accountID) => {
    if (Object.keys(vendorMarketplaces).length > 0) {
      const updatedMarketplaces = vendorMarketplaces.map((entry) => {
        mapAccountAdvertiseNames(item, value, accountID, entry, 'vendor');
        if (entry.marketplace === marketplaceDetails.marketplaceId)
          return accountID
            ? { ...entry, id: accountID, [item]: value }
            : { ...entry, [item]: value };

        return entry;
      });
      localStorage.setItem('vendor', JSON.stringify([...updatedMarketplaces]));
      setVendorMarketplaces([...updatedMarketplaces]);
    } else {
      setVendorMarketplaces([
        ...vendorMarketplaces,
        { marketplace: marketplaceDetails.marketplaceId, [item]: value },
      ]);
      localStorage.setItem(
        'vendor',
        JSON.stringify([
          ...vendorMarketplaces,
          { marketplace: marketplaceDetails.marketplaceId, [item]: value },
        ]),
      );
    }
  };

  const clearErrors = (AccType, marketplaceID, marketplaceName) => {
    const errorEleToBeChanged = apiError?.[AccType]?.find(
      (errorEle) =>
        errorEle?.marketplace_name?.[0] === marketplaceName ||
        errorEle?.marketplace?.[0] === marketplaceID,
    );
    if (errorEleToBeChanged) {
      const updatedSellerError = apiError?.[AccType]?.filter(
        (errEle) =>
          errEle?.marketplace_name?.[0] !== marketplaceName &&
          errEle?.marketplace?.[0] !== marketplaceID,
      );
      setApiError({ ...apiError, [AccType]: updatedSellerError });
    }
  };

  // setting default value for primary marketplace only
  const setDefaultValues = (item, value, part, accountID) => {
    if (
      marketplaceDetails.type === 'Hybrid' ||
      marketplaceDetails.type === null
    ) {
      if (part === 2 || part === 3) {
        clearErrors(
          'Seller',
          marketplaceDetails?.marketplaceID,
          marketplaceDetails?.marketplace_name,
        );

        updateSellerMarketplaces(item, value, accountID);
      }

      if (part === 5 || part === 6) {
        clearErrors(
          'Vendor',
          marketplaceDetails?.marketplaceID,
          marketplaceDetails?.marketplace_name,
        );
        updateVendorMarketplaces(item, value, accountID);
      }
    }
    if (marketplaceDetails.type === 'Seller') {
      clearErrors(
        'Seller',
        marketplaceDetails?.marketplaceID,
        marketplaceDetails?.marketplace_name,
      );
      updateSellerMarketplaces(item, value, accountID);
    }
    if (marketplaceDetails.type === 'Vendor') {
      clearErrors(
        'Vendor',
        marketplaceDetails?.marketplaceID,
        marketplaceDetails?.marketplace_name,
      );
      updateVendorMarketplaces(item, value, accountID);
    }
  };

  // setting default value for secondary marketplace only
  const setDefaultForSecondary = (
    item,
    value,
    marketplaceID,
    section,
    accountID,
    marketplaceName,
  ) => {
    const isDspOnlyBP = marketplaceID === null;

    // Set the values of Seller marketplace fields
    if (section === 'Seller') {
      clearErrors('Seller', marketplaceID, marketplaceName);

      if (
        Object.keys(sellerMarketplaces).length > 0 &&
        sellerMarketplaces.some((entry) =>
          isDspOnlyBP
            ? entry.marketplace === marketplaceID &&
              entry.marketplace_name === marketplaceName
            : entry.marketplace === marketplaceID ||
              entry.marketplace_name === marketplaceName,
        )
      ) {
        const updatedMarketplaces = sellerMarketplaces.map((entry) => {
          // If the do not advertise on marketplace then make two fields blank

          const isSameMarketplace = isDspOnlyBP
            ? entry.marketplace === marketplaceID &&
              entry.marketplace_name === marketplaceName
            : entry.marketplace === marketplaceID ||
              entry.marketplace_name === marketplaceName;
          if (isSameMarketplace && item === 'doesnt_advertise' && value) {
            return accountID
              ? {
                  ...entry,
                  id: accountID,
                  advertiser_name: '',
                  advertiser_id: '',
                  seller_central_name: '',
                  merchant_id: '',
                  [item]: value,
                  customer:
                    userInfo?.customer ||
                    verifiedStepData.customer_id ||
                    localStorage.getItem('customer'),
                }
              : {
                  ...entry,
                  advertiser_name: '',
                  advertiser_id: '',
                  seller_central_name: '',
                  merchant_id: '',
                  [item]: value,
                  customer:
                    userInfo?.customer ||
                    verifiedStepData.customer_id ||
                    localStorage.getItem('customer'),
                };
          }

          // Check If that markeplace already has a Seller or vendor account with accountID
          if (isSameMarketplace) {
            return accountID
              ? {
                  ...entry,
                  id: accountID,
                  [item]: value,
                  customer:
                    userInfo?.customer ||
                    verifiedStepData.customer_id ||
                    localStorage.getItem('customer'),
                }
              : {
                  ...entry,
                  [item]: value,
                  customer:
                    userInfo?.customer ||
                    verifiedStepData.customer_id ||
                    localStorage.getItem('customer'),
                };
          }

          return entry;
        });

        localStorage.setItem(
          'seller',
          JSON.stringify([...updatedMarketplaces]),
        );

        setSellerMarketplaces([...updatedMarketplaces]);
      } else {
        setSellerMarketplaces([
          ...sellerMarketplaces,
          {
            marketplace: marketplaceID,
            marketplace_name: marketplaceName,
            [item]: value,
            customer:
              userInfo?.customer ||
              verifiedStepData.customer_id ||
              localStorage.getItem('customer'),
          },
        ]);
        localStorage.setItem(
          'seller',
          JSON.stringify([
            ...sellerMarketplaces,
            {
              marketplace: marketplaceID,
              marketplace_name: marketplaceName,
              [item]: value,
              customer:
                userInfo?.customer ||
                verifiedStepData.customer_id ||
                localStorage.getItem('customer'),
            },
          ]),
        );
      }
    }

    // Set the values of Vendor marketplace fields
    if (section === 'Vendor') {
      // Check if there are any marketplaces + current marketplace is already avaiailable then update it
      clearErrors('Vendor', marketplaceID, marketplaceName);

      if (
        Object.keys(vendorMarketplaces).length > 0 &&
        vendorMarketplaces.some((entry) =>
          isDspOnlyBP
            ? entry.marketplace === marketplaceID &&
              entry.marketplace_name === marketplaceName
            : entry.marketplace === marketplaceID ||
              entry.marketplace_name === marketplaceName,
        )
      ) {
        const updatedMarketplaces = vendorMarketplaces.map((entry) => {
          const isSameMarketplace = isDspOnlyBP
            ? entry.marketplace === marketplaceID &&
              entry.marketplace_name === marketplaceName
            : entry.marketplace === marketplaceID ||
              entry.marketplace_name === marketplaceName;

          // if marketplace is mathced + key is doesnt_advertise + doesnt_advertise value is true
          if (isSameMarketplace && item === 'doesnt_advertise' && value) {
            // if seller account is already there
            return accountID
              ? {
                  ...entry,
                  id: accountID,
                  advertiser_name: '',
                  advertiser_id: '',
                  vendor_central_name: '',
                  vendor_code: '',
                  [item]: value,
                  customer:
                    userInfo?.customer ||
                    verifiedStepData.customer_id ||
                    localStorage.getItem('customer'),
                }
              : {
                  ...entry,
                  advertiser_name: '',
                  advertiser_id: '',
                  vendor_central_name: '',
                  vendor_code: '',
                  [item]: value,
                  customer:
                    userInfo?.customer ||
                    verifiedStepData.customer_id ||
                    localStorage.getItem('customer'),
                };
          }

          // If only marketplace is matched
          if (isSameMarketplace)
            return accountID
              ? {
                  ...entry,
                  id: accountID,
                  [item]: value,
                  customer:
                    userInfo?.customer ||
                    verifiedStepData.customer_id ||
                    localStorage.getItem('customer'),
                }
              : {
                  ...entry,
                  [item]: value,
                  customer:
                    userInfo?.customer ||
                    verifiedStepData.customer_id ||
                    localStorage.getItem('customer'),
                };

          return entry;
        });
        localStorage.setItem(
          'vendor',
          JSON.stringify([...updatedMarketplaces]),
        );
        setVendorMarketplaces([...updatedMarketplaces]);
      } else {
        // if marketplace is not present at all
        setVendorMarketplaces([
          ...vendorMarketplaces,

          {
            marketplace: marketplaceID,
            [item]: value,
            marketplace_name: marketplaceName,
            customer:
              userInfo?.customer ||
              verifiedStepData.customer_id ||
              localStorage.getItem('customer'),
          },
        ]);

        localStorage.setItem(
          'vendor',
          JSON.stringify([
            ...vendorMarketplaces,
            {
              marketplace: marketplaceID,
              [item]: value,
              marketplace_name: marketplaceName,
              customer:
                userInfo?.customer ||
                verifiedStepData.customer_id ||
                localStorage.getItem('customer'),
            },
          ]),
        );
      }
    }
  };

  // (Clock or Check) For Hybrid account check if the Seller or Vendor Central details are filled
  const checkCentralDetails = (accountType, marketplaceID, maketplaceName) => {
    const isDspOnlyBP = marketplaceID === null;

    // Get account specific marketplace
    const sortedMarketplace =
      accountType === 'Seller'
        ? sellerMarketplaces.find((entry) =>
            isDspOnlyBP
              ? entry.marketplace === marketplaceID &&
                entry.marketplace_name === maketplaceName
              : entry.marketplace === marketplaceID ||
                entry.marketplace_name === maketplaceName,
          )
        : vendorMarketplaces.find((entry) =>
            isDspOnlyBP
              ? entry.marketplace === marketplaceID &&
                entry.marketplace_name === maketplaceName
              : entry.marketplace === marketplaceID ||
                entry.marketplace_name === maketplaceName,
          );

    // If doesn't advertise for that marketplace then that section is filled
    if (sortedMarketplace?.doesnt_advertise === true) return true;

    // For that markeplace for both this two fields following condtions should be satisfied
    if (
      sortedMarketplace?.advertiser_name !== '' &&
      sortedMarketplace?.advertiser_name !== ' ' &&
      sortedMarketplace?.advertiser_name !== null &&
      sortedMarketplace?.advertiser_name !== undefined &&
      sortedMarketplace?.advertiser_id !== '' &&
      sortedMarketplace?.advertiser_id !== ' ' &&
      sortedMarketplace?.advertiser_id !== null &&
      sortedMarketplace?.advertiser_id !== undefined
    )
      return true;

    return false;
  };

  const generateAccountType = (part, mapData, accountType) => {
    return (
      <fieldset className="shape-without-border  w-430 mt-3 mb-2">
        <p className="account-steps m-0">Part {part}</p>
        Navigate to Settings &gt; Account Info and enter your{' '}
        <strong>
          {((marketplaceDetails.type === 'Hybrid' ||
            marketplaceDetails.type === null) &&
            part === 2) ||
          marketplaceDetails.type === 'Seller'
            ? 'Seller'
            : 'Vendor'}{' '}
          Display Name
        </strong>{' '}
        and{' '}
        <strong>
          {((marketplaceDetails.type === 'Hybrid' ||
            marketplaceDetails.type === null) &&
            part === 2) ||
          marketplaceDetails.type === 'Seller'
            ? 'Merchant Token ID'
            : 'Vendor Code'}{' '}
        </strong>{' '}
        below.
        <p className="info-text-gray mt-1 mb-4 ">
          <img style={customVideostyle} src={VideoCall} alt="video" />
          <span
            className="video-link cursor"
            onClick={() => setShowVideo({ [part]: true })}
            role="presentation"
          >
            Click here to watch the video.
          </span>
        </p>
        {mapData
          .filter((op) => op.section === 1)
          .map((item) => (
            <InputFormField className="mt-4" key={item.key}>
              <label htmlFor={item.label}>
                {item.label}
                <input
                  placeholder={`Enter ${item.label}`}
                  className="form-control"
                  onChange={(event) => {
                    setDefaultValues(
                      item.key,
                      event.target.value,
                      part,
                      marketplaceDetails?.[accountType]?.id,
                    );
                  }}
                  defaultValue={mapDefaultValues(item.key, part)}
                  readOnly={isChecked}
                />
              </label>
              {Object.keys(apiError).length > 0 ? (
                <>
                  <ErrorMsg>
                    {mapApiErrors(
                      item.key,
                      marketplaceDetails?.[accountType]?.marketplace ||
                        marketplaceDetails?.marketplaceId,
                      accountType,
                    )}
                  </ErrorMsg>
                </>
              ) : null}
            </InputFormField>
          ))}
      </fieldset>
    );
  };

  // Validation logic to check if all the required fields are filled
  const validateMarketplaces = () => {
    const allEntries = [];

    // Get all the fields that are required for seller
    if (
      marketplaceDetails.type === 'Seller' ||
      marketplaceDetails.type === 'Hybrid'
    ) {
      if (noAmazonAccount) return false;

      for (const accountDetails of sellerMarketplaces) {
        if (accountDetails?.doesnt_advertise) {
          allEntries.push({
            isMandetoryFieldsFilled: true,
            maketplace_name: accountDetails?.marketplace_name,
            type: accountDetails?.type,
          });
        } else if (
          !accountDetails?.doesnt_advertise &&
          accountDetails?.advertiser_id &&
          accountDetails?.advertiser_name &&
          accountDetails?.seller_central_name &&
          accountDetails?.merchant_id
        ) {
          allEntries.push({
            isMandetoryFieldsFilled: true,
            maketplace_name: accountDetails?.marketplace_name,
            type: accountDetails?.type,
          });
        } else
          allEntries.push({
            isMandetoryFieldsFilled: false,
            maketplace_name: accountDetails?.marketplace_name,
            type: accountDetails?.type,
          });
      }
    }

    // Get all the fields that are required for Vendor
    if (
      marketplaceDetails.type === 'Vendor' ||
      marketplaceDetails.type === 'Hybrid'
    ) {
      if (noAmazonAccount) return false;

      for (const accountDetails of vendorMarketplaces) {
        if (accountDetails?.doesnt_advertise) {
          allEntries.push({
            isMandetoryFieldsFilled: true,
            maketplace_name: accountDetails?.marketplace_name,
            type: accountDetails?.type,
          });
        } else if (
          accountDetails?.advertiser_id &&
          accountDetails?.advertiser_name &&
          !accountDetails?.doesnt_advertise &&
          accountDetails?.vendor_central_name &&
          accountDetails?.vendor_code
        ) {
          allEntries.push({
            isMandetoryFieldsFilled: true,
            maketplace_name: accountDetails?.marketplace_name,
            type: accountDetails?.type,
          });
        } else {
          allEntries.push({
            isMandetoryFieldsFilled: false,
            maketplace_name: accountDetails?.marketplace_name,
            type: accountDetails?.type,
          });
        }
      }
    }

    // if any item not fullfilled mandetory fields then disable the button
    if (allEntries.some((item) => item?.isMandetoryFieldsFilled === false)) {
      return true;
    }
    return false;
  };

  // Show this Btn if account is either Seller or Vendor
  const generateSaveBtn = (part) => {
    return (
      <>
        {(marketplaceDetails.type === 'Hybrid' && part === 7) ||
        marketplaceDetails.type === 'Vendor' ||
        marketplaceDetails.type === 'Seller' ? (
          <Button
            className="btn-primary w-100 mt-3"
            disabled={validateMarketplaces()}
            onClick={() =>
              saveAccountDetails(
                marketplaceDetails?.Seller?.id ||
                  localSeller?.filter((op) => op.id)?.[0]?.id,
                marketplaceDetails?.Vendor?.id ||
                  localVendor?.filter((op) => op.id)?.[0]?.id,
              )
            }
          >
            {' '}
            {isLoading.loader && isLoading.type === 'button' ? (
              <PageLoader color="#fff" type="button" />
            ) : (
              <>{assignedToSomeone ? 'Submit' : 'Continue'} </>
            )}
          </Button>
        ) : (
          ''
        )}
      </>
    );
  };

  // Generate Advertiser details section (Part 2 & Part 6) for Primary marketplace
  const generateAdvertiser = (part, mapData, accountType) => {
    return (
      <fieldset className="shape-without-border  w-430 mt-3 mb-1">
        <p className="account-steps m-0">Part {part}</p>
        Navigate to Settings &gt; Account Info and enter your{' '}
        <strong>Advertiser Name</strong> and <strong>ID</strong> below.
        <p className="info-text-gray mt-1 mb-4 ">
          <img style={customVideostyle} src={VideoCall} alt="video" />
          <span
            className="video-link cursor"
            onClick={() => setShowVideo({ [part]: true })}
            role="presentation"
          >
            Click here to watch the video.
          </span>
        </p>
        {mapData &&
          mapData
            .filter((op) => op.section === 2)
            .map((item) => (
              <InputFormField className="mt-4" key={item.key}>
                <label htmlFor={item.label}>
                  {item.label}
                  <input
                    placeholder={`Enter ${item.label}`}
                    className="form-control"
                    onChange={(event) => {
                      setDefaultValues(
                        item.key,
                        event.target.value,
                        part,
                        marketplaceDetails?.[accountType]?.id,
                      );
                    }}
                    defaultValue={mapDefaultValues(item.key, part)}
                    readOnly={isChecked}
                  />
                </label>
                {Object.keys(apiError).length > 0 ? (
                  <>
                    <ErrorMsg>
                      {mapApiErrors(
                        item.key,
                        marketplaceDetails?.[accountType]?.marketplace ||
                          marketplaceDetails?.marketplaceId,
                        accountType,
                      )}
                    </ErrorMsg>
                  </>
                ) : null}
              </InputFormField>
            ))}
      </fieldset>
    );
  };

  // Generate Secondary Marketplaces depending on the type of account (Seller, Vendor & Hybrid)
  const generateMarketplaces = (part) => {
    return (
      <fieldset className="shape-without-border  w-430 mt-3 mb-1">
        <p className="account-steps m-0">Part {part}</p>
        Please log into the marketplaces listed below and enter your{' '}
        <strong>Advertiser Name</strong> and <strong>ID</strong> below.
        {marketplaceDetails.type === 'Hybrid' ||
        marketplaceDetails.type === null
          ? secondaryMarketplaces.map((accountDetails) => {
              return (
                <HybridAccountMarketplaces
                  accountDetails={accountDetails}
                  mapSecondaryMarketplaceValues={mapSecondaryMarketplaceValues}
                  setDefaultForSecondary={setDefaultForSecondary}
                  checkCentralDetails={checkCentralDetails}
                  sellerAdvertiseForMarketplace={mapSecondaryMarketplaceValues(
                    'doesnt_advertise',
                    accountDetails?.seller_marketplace?.id,
                    'Seller',
                    accountDetails?.seller_marketplace?.name?.value,
                  )}
                  vendorAdvertiseForMarketplace={mapSecondaryMarketplaceValues(
                    'doesnt_advertise',
                    accountDetails?.vendor_marketplace?.id,
                    'Vendor',
                    accountDetails?.vendor_marketplace?.name?.value,
                  )}
                  sellerMarketplaces={sellerMarketplaces}
                  vendorMarketplaces={vendorMarketplaces}
                  apiError={apiError}
                  mapApiErrors={mapApiErrors}
                  isAuthorized={isAuthorized}
                  localSeller={localSeller}
                  localVendor={localVendor}
                  accountRedirectURL={accountRedirectURL}
                  isChecked={isChecked}
                  authenticationURL={authenticationURL}
                  customerData={customerData}
                />
              );
            })
          : secondaryMarketplaces.map((accountDetails) => {
              return (
                <SingleAccountMarketplaces
                  accountDetails={accountDetails}
                  mapSecondaryMarketplaceValues={mapSecondaryMarketplaceValues}
                  setDefaultForSecondary={setDefaultForSecondary}
                  accountType={marketplaceDetails.type}
                  advertiseForMarketplace={mapSecondaryMarketplaceValues(
                    'doesnt_advertise',
                    marketplaceDetails.type === 'Seller'
                      ? accountDetails?.seller_marketplace?.id
                      : accountDetails?.vendor_marketplace?.id,
                    marketplaceDetails.type,
                    marketplaceDetails.type === 'Seller'
                      ? accountDetails?.seller_marketplace?.name?.value
                      : accountDetails?.vendor_marketplace?.name?.value,
                  )}
                  sellerMarketplaces={sellerMarketplaces}
                  vendorMarketplaces={vendorMarketplaces}
                  apiError={apiError}
                  mapApiErrors={mapApiErrors}
                  isAuthorized={isAuthorized}
                  localVendor={localVendor}
                  accountRedirectURL={accountRedirectURL}
                  isChecked={isChecked}
                  authenticationURL={authenticationURL}
                  customerData={customerData}
                />
              );
            })}
      </fieldset>
    );
  };

  const btnDesign = () => {
    return (
      <Button
        className="btn-primary w-100 mt-3"
        disabled={validateMarketplaces()}
        onClick={() =>
          saveAccountDetails(
            marketplaceDetails?.Seller?.id ||
              localSeller?.filter((op) => op.id)?.[0]?.id,
            marketplaceDetails?.Vendor?.id ||
              localVendor?.filter((op) => op.id)?.[0]?.id,
          )
        }
      >
        {' '}
        {isLoading.loader && isLoading.type === 'button' ? (
          <PageLoader color="#fff" type="button" />
        ) : (
          <>{assignedToSomeone ? 'Submit' : 'Continue'} </>
        )}
      </Button>
    );
  };

  const generateBtn = () => {
    // Hybrid
    if (
      marketplaceDetails.type === 'Hybrid' ||
      marketplaceDetails.type === null
    ) {
      // Ask someone else
      if (isChecked) return '';
      return <>{btnDesign()}</>;
    }
    if (
      // logged in user & don't have seller or vendor account
      history.location.pathname.includes(PATH_AMAZON_MERCHANT) &&
      noAmazonAccount
    ) {
      if (isChecked) return '';
      return <>{btnDesign()}</>;
    }

    // unauthorized user & don't have seller or vendor account
    if (
      history.location.pathname.includes(PATH_UNAUTHORIZED_AMAZON_MERCHANT) &&
      noAmazonAccount
    )
      return <>{btnDesign()}</>;
    return '';
  };

  return (
    <>
      <OnBoardingBody
        className={assignedToSomeone ? 'body-white' : 'body-white pb-1'}
      >
        {(
          Object.keys(localNoAmazonAccount)?.length
            ? localNoAmazonAccount?.noAmazonAccount
            : noAmazonAccount
        ) ? (
          ''
        ) : (
          <>
            {marketplaceDetails.type === 'Hybrid' ||
            marketplaceDetails.type === null ? (
              <>
                {marketplaceDetails.type === 'Hybrid' ? (
                  <>
                    <p className="text-detail text-bold ml-3">
                      Seller Central Details
                    </p>
                    {generateAmazon(1)}
                    {generateAccountType(
                      2,
                      amazonSellerAccountDetails,
                      'Seller',
                    )}
                    {generateAdvertiser(
                      3,
                      amazonSellerAccountDetails,
                      'Seller',
                    )}
                  </>
                ) : null}

                {marketplaceDetails.type === 'Hybrid' ? (
                  <>
                    <p className="text-detail text-bold ml-3">
                      Vendor Central Details
                    </p>
                    {generateAmazon(4)}
                    {generateAccountType(
                      5,
                      amazonVendorAccountDetails,
                      'Vendor',
                    )}
                    {generateAdvertiser(
                      6,
                      amazonVendorAccountDetails,
                      'Vendor',
                    )}
                  </>
                ) : null}

                {secondaryMarketplaces.length > 0 ? (
                  <>
                    <p className="text-detail text-bold ml-3">
                      {marketplaceDetails.type === 'Hybrid'
                        ? 'Additional Marketplaces'
                        : 'Amazon Marketplaces'}
                    </p>
                    {/* Step 7 for Hybrid account secondary marketplaces */}
                    {generateMarketplaces(
                      marketplaceDetails.type === 'Hybrid' ? 7 : 1,
                      amazonVendorAccountDetails,
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <>
                {generateAmazon(1)}
                {generateAccountType(
                  2,
                  marketplaceDetails.type === 'Seller'
                    ? amazonSellerAccountDetails
                    : amazonVendorAccountDetails,
                  marketplaceDetails.type === 'Seller' ? 'Seller' : 'Vendor',
                )}
                {generateAdvertiser(
                  3,
                  marketplaceDetails.type === 'Seller'
                    ? amazonSellerAccountDetails
                    : amazonVendorAccountDetails,
                  marketplaceDetails.type === 'Seller' ? 'Seller' : 'Vendor',
                )}

                {/* Add a step 4 for seller and vendor secondary marketplaces */}
                {secondaryMarketplaces.length > 0 ? (
                  <>
                    {generateMarketplaces(
                      4,
                      marketplaceDetails.type === 'Seller'
                        ? amazonSellerAccountDetails
                        : amazonVendorAccountDetails,
                    )}
                  </>
                ) : null}
              </>
            )}
            <AmazonVideo
              showVideo={showVideo}
              setShowVideo={setShowVideo}
              isLoading={isLoading}
              videoData={videoData}
              marketplaceDetails={marketplaceDetails}
            />
          </>
        )}

        {!isChecked ? (
          <>
            <p className="text-detail ml-3">
              To continue, please fill up all the fields above. Please review
              the information for each marketplace before continuing.
            </p>

            {marketplaceDetails.type === 'Hybrid' ||
            marketplaceDetails.type === null
              ? generateBtn()
              : generateSaveBtn()}
          </>
        ) : null}
      </OnBoardingBody>
    </>
  );
}

AmazonMerchant.defaultProps = {
  stepData: {},
  apiError: {},
  showVideo: {},
  setShowVideo: () => {},
  setApiError: () => {},
  setFormData: () => {},
  localSeller: [],
  localVendor: [],
  localNoAmazonAccount: {},
};

AmazonMerchant.propTypes = {
  userInfo: shape({
    id: string,
    email: string,
    customer: string,
    customer_onboarding: string,
    step: shape({
      step: number,
    }),
  }).isRequired,
  setIsLoading: func.isRequired,
  assignedToSomeone: bool.isRequired,
  stepData: shape({
    id: string,
  }),
  verifiedStepData: shape(
    shape({
      user_name: string,
      user_step: shape({}),
    }),
  ).isRequired,
  isLoading: shape({
    loader: bool,
    type: string,
  }).isRequired,
  isChecked: bool.isRequired,
  noAmazonAccount: bool.isRequired,
  setShowVideo: func,
  showVideo: shape(shape({})),
  marketplaceDetails: shape({
    type: string,
    marketplace: string,
    marketplaceId: string,
    Seller: shape({
      id: string,
    }),
    Vendor: shape({
      id: string,
    }),
  }).isRequired,
  videoData: shape({
    seller_central_info: string,
    seller_ad_info: string,
    vendor_central_info: string,
    vendor_ad_info: string,
  }).isRequired,
  setApiError: func,
  apiError: shape([]),
  setFormData: func,
  secondaryMarketplaces: arrayOf(shape({})).isRequired,
  vendorMarketplaces: arrayOf(shape({})).isRequired,
  sellerMarketplaces: arrayOf(shape({})).isRequired,
  setSellerMarketplaces: func.isRequired,
  setVendorMarketplaces: func.isRequired,
  localSeller: shape([]),
  localVendor: shape([]),
  summaryData: arrayOf(shape({})).isRequired,
  localNoAmazonAccount: shape({}),
};
