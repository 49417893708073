import styled from 'styled-components';

import Theme from '../../../../theme/Theme';

const AgreementDetailOuterContainer = styled.div`
  .agreement-type {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-table;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        border-right: 1px solid ${Theme.gray3};
      }

      &.other-details {
        margin-top: 5px;
      }
    }

    @media only screen and (max-width: 991px) {
      li {
        margin-right: 50px;

        &:first-child {
          border-right: none;
        }
      }
    }
  }

  .in-collcetion {
    list-style-type: none;
    padding: 10px;

    li {
      display: flow-root;
    }
  }

  .metrics-card {
    .metrics-columns {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 100%;
        display: inline-block;
        max-width: 20%;
        flex: 0 0 20%;
        position: relative;
        padding-right: 5px;
        padding-left: 5px;

        .breakdown-label {
          color: ${Theme.orange};
          font-size: ${Theme.extraSmall};
          cursor: pointer;
        }

        .metrics-right-label {
          position: absolute;
          top: 0;
          right: 16px;
          word-break: break-all;
        }
      }
      @media only screen and (max-width: 991px) {
        li {
          max-width: 33.33%;
          flex: 0 0 33.33%;
          margin-bottom: 0;
        }
      }
      @media only screen and (max-width: 767px) {
        li {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }
  .collapse-in-out {
    border-radius: 20px;
    border: 1px solid ${Theme.gray11};
    color: ${Theme.black};
    font-size: ${Theme.extraNormal};
    font-family: ${Theme.baseMediumFontFamily};
    background: ${Theme.white};
    width: fit-content;
    padding: 5px 10px 3px 12px;
    text-align: center;
    position: absolute;
    margin: -18px auto;
    right: 0;
    left: 0;
    cursor: pointer;
  }

  .right-side-content {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .toggle-dropdown {
      display: inline-table;
      margin-right: 20px;
      width: 38%;
      vertical-align: top;
      &:last-child {
        margin-right: 0;
      }

      &.w-50 {
        width: 70%;
      }

      &.w-70 {
        width: 55%;
        text-align: -webkit-right;
      }
    }
    @media only screen and (max-width: 1200px) and (min-width: 1042px) {
      .toggle-dropdown {
        .days-tab {
          li.account-type {
            padding: 9px 5px;
          }
        }
        &.w-70 {
          width: 58% !important;
          margin-right: 10px;
        }
      }
    }
    @media only screen and (max-width: 1630px) {
      .toggle-dropdown {
        &.w-70 {
          width: 46%;
          text-align: -webkit-right;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      .toggle-dropdown {
        width: 100%;
        &:last-child {
          margin-right: 0;
        }
        &.w-50 {
          width: 100%;
        }

        &.w-70 {
          width: 100%;
          text-align: -webkit-right;
        }
      }
    }
  }
`;

export default AgreementDetailOuterContainer;
