import React, { useState, useEffect, useCallback } from 'react';

import styled from 'styled-components';
import { string } from 'prop-types';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';

import { getEmbeddedQuicksightDashboardURL } from '../../../../../api/AmcInsightsApi';
import { PageLoader, WhiteCard } from '../../../../../common';

function AwsQuicksightContainer({ customerId, dashboardId, id }) {
  const [dashboardLoader, setDashboardLoader] = useState(true);

  // async function for embedding the quicksight dashboard automatically
  const getQuicksightDashboard = useCallback(
    async (URL) => {
      let embeddedDashboardExperience = null;
      // get context for dashboard
      const { createEmbeddingContext } = QuickSightEmbedding;

      const embeddingContext = await createEmbeddingContext();

      // Note - this ID should be unique
      const containerDiv = document.getElementById(id);

      // basic dashboard options
      const dashboardOptions = {
        url: URL,
        container: containerDiv,
        scrolling: 'no',
        width: '100%',
        height: '1280px',
      };

      // set parameters
      const dashboardParams = [
        {
          Name: 'nextID',
          Values: [customerId],
        },
      ];

      // filtered dashboard params
      const otherOptions = {
        parameters: dashboardParams,
        toolbarOptions: {
          export: true,
        },
      };

      // get filtered embedded dashboard
      embeddedDashboardExperience = await embeddingContext
        .embedDashboard(dashboardOptions, otherOptions)
        .then((response) => {
          embeddedDashboardExperience.setParameters(dashboardParams);
          return response;
        })
        .catch((error) => {
          return error.response;
        });
      return embeddedDashboardExperience;
    },
    [customerId, id],
  );

  // get embedded url of quicksight dashboard
  const getDashboardURL = useCallback(() => {
    setDashboardLoader(true);
    const params = {
      dashboard_id: dashboardId,
    };
    getEmbeddedQuicksightDashboardURL(params).then((response) => {
      if (response?.status === 200) {
        setDashboardLoader(false);
        getQuicksightDashboard(response?.data?.EmbedUrl);
      } else {
        setDashboardLoader(false);
      }
    });
  }, [getQuicksightDashboard, dashboardId]);

  useEffect(() => {
    getDashboardURL();
  }, [getDashboardURL]);

  /* ********** Main return statement of this component ********** */
  return (
    <WhiteCard className="mb-5">
      <AWSQuicksightsDashboard style={{ minHeight: '600px' }}>
        <>
          {dashboardLoader ? (
            <PageLoader
              component="performance-graph"
              type="detail"
              width={40}
              height={40}
              color="#FF5933"
            />
          ) : (
            <>
              <div id={id} />
            </>
          )}
          <div id="footer-strip" />
        </>
      </AWSQuicksightsDashboard>
    </WhiteCard>
  );
}

AwsQuicksightContainer.defaultProps = {
  customerId: '',
  dashboardId: '',
};

AwsQuicksightContainer.propTypes = {
  customerId: string,
  dashboardId: string,
  id: string.isRequired,
};

export default AwsQuicksightContainer;
const AWSQuicksightsDashboard = styled.div`
  #footer-strip {
    height: 30px;
    background: #ffffff;
    margin-top: -25px;
    position: relative;
  }
`;
