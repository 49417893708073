import React from 'react';

import Modal from 'react-modal';
import { bool, func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ModalBox } from '../../../common';

function ReportPDFStatusModal({
  isModalOpen,
  currentPDFUser,
  afterModalCloseAPI,
}) {
  // render report pdf status modal
  const renderReportPDFStatusModalContent = () => {
    return (
      <>
        <Modal
          id="report-pdf-status-modal"
          isOpen={isModalOpen}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              maxWidth: '540px ',
              minHeight: '150px',
              width: '100% ',
              overlay: ' {zIndex: 1000}',
              transform: 'translate(-50%, -50%)',
            },
          }}
          ariaHideApp={false}
          contentLabel="Edit modal"
        >
          <FontAwesomeIcon
            className="cursor red-cross-icon"
            icon="fa-regular fa-times"
            style={{
              color: '#000000',
              fontSize: '14px',
              position: 'absolute',
              right: '32px',
              top: '25px',
            }}
            onClick={() => {
              afterModalCloseAPI();
            }}
            role="presentation"
          />

          <ModalBox>
            <>
              <div className="modal-body pb-0">
                <h4>Report Status</h4>

                <div className="horizontal-line straight-line mt-3" />
                <div className="body-content modal-body-inner-container mt-1">
                  <p
                    className="normal-text-black my-3"
                    style={{ lineHeight: '24px' }}
                  >
                    As {currentPDFUser} is currently editing this report, you
                    cannot <strong>edit</strong> the report until&nbsp;
                    {currentPDFUser} is done editing it.
                  </p>
                </div>
              </div>
            </>
          </ModalBox>
        </Modal>
      </>
    );
  };
  /* ********** main return statement of this component ********** */
  return <div>{renderReportPDFStatusModalContent()}</div>;
}

export default ReportPDFStatusModal;

ReportPDFStatusModal.defaultProps = {
  currentPDFUser: '',
  isModalOpen: false,
  afterModalCloseAPI: () => {},
};

ReportPDFStatusModal.propTypes = {
  currentPDFUser: string,
  isModalOpen: bool,
  afterModalCloseAPI: func,
};
