// react imports
import React from 'react';

// third party imports
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';

// local file imports
import Theme from '../../../../theme/Theme';
import DspMetrics from './DspMetrics';
import DSPPacing from './DspPacing';
import DSPKeyContributors from './DspKeyContributors';
import DspPerformanceChart from '../../../Customer/CustomerDetailView/CompanyPerformance/DspAdPerformance/DspAdvertising/DspPerformanceChart';
import {
  CustomDateModal,
  DailyWeeklyMonthlyToggle,
  DropdownIndicator,
  DropdownOptions,
  GrayMessageBar,
  NoData,
  PageLoader,
  SingleSelectDropdown,
  WhiteCard,
} from '../../../../common';
import {
  CUSTOM,
  dailyWeeklyMonthlyOptions,
  dateOptions,
  noGraphDataMessage,
  performanceInsightMessage,
} from '../../../../constants';
import {
  DspRightHandSideSectionDefaultProps,
  DspRightHandSideSectionPropTypes,
} from './DspPropTypes';

function DspRightHandSideSection({
  // number
  pageNumber,
  contributionCount,
  // boolean
  isCustomDateApply,
  dspGraphLoader,
  dspPacingLoader,
  downloadReportLoader,
  keyContributionLoader,
  showAdCustomDateModal,
  // string
  currencySymbol,
  dspGroupBy,
  selectedTabMetrics,
  selectedSpendingOption,
  // object
  selectedDspDF,
  customDateError,
  selectedDspMetrics,
  selectedBrandPartner,
  // array
  dspPacingData,
  dspChartData,
  dspCurrentTotal,
  dspPreviousTotal,
  dspDifferenceData,
  contributionData,
  dspCustomDateState,
  // function
  applyCustomDate,
  handleDSPGroupBy,
  handlePageChange,
  handleDspDailyFact,
  handleSpendingOptions,
  setDspCustomDateState,
  setSelectedDspMetrics,
  setShowAdCustomDateModal,
  handleOnMatricsTabChange,
  displayContributionTable,
  renderRecentAndPreviousLabel,
  handleKeyContributorsDownloadReport,
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const { SingleValue } = components;
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const month = dayjs(currentDate).format('MMMM');
  const dashboardDynamicError = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );

  const renderCustomDateSubLabel = (data) => {
    if (selectedDspDF.value === CUSTOM && isCustomDateApply) {
      return `From- ${dayjs(dspCustomDateState[0]?.startDate).format(
        'MMM D, YYYY',
      )}  To- ${dayjs(dspCustomDateState[0]?.endDate).format('MMM D, YYYY')}`;
    }
    return data.sub;
  };

  // react select dropdown components - selected option
  const singleFilterOption = ({ data, ...props }) => {
    return (
      <SingleValue {...props}>
        <span style={{ fontSize: '14px' }}>{data?.label}</span>
        <div style={{ fontSize: '10px' }}>{renderCustomDateSubLabel(data)}</div>
      </SingleValue>
    );
  };

  // get react select components
  const getSelectComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  // display dsp timeframe dropdwon filter
  const displayDspTimeFrameFilter = () => {
    return (
      <div style={{ position: 'relative' }}>
        <SingleSelectDropdown
          filterId="BT-DSPDashboardTimeFrame"
          className="single-select-dropdown"
          dropdownOptions={dateOptions}
          selectedValue={selectedDspDF}
          onChangeHandler={handleDspDailyFact}
          dropdownComponents={getSelectComponents}
          isApiCall={dspGraphLoader || keyContributionLoader}
          isSearchable
        />
      </div>
    );
  };

  // function - dsp time frame filter
  const renderDspDailyFact = () => {
    return (
      <div className="row">
        <div className="col-xl-9 col-md-6 col-12">
          <p className="black-heading-title mt-2 mb-4">DSP Ad Performance</p>
        </div>
        <div className="col-xl-3 col-md-6 col-12 mb-3">
          {displayDspTimeFrameFilter()}
        </div>
        <div className="col-12">
          {dashboardDynamicError?.dsp_performance?.placeholder ? (
            <GrayMessageBar
              message={dashboardDynamicError?.dsp_performance?.placeholder}
            />
          ) : null}
        </div>
      </div>
    );
  };

  // function - display dsp error message
  const renderErrorMessage = () => {
    let { fromDate } = customDateError;
    let { toDate } = customDateError;

    fromDate = dayjs(fromDate).format('MM/DD/YYYY');
    toDate = dayjs(toDate).subtract(1, 'day').format('MM/DD/YYYY');

    return (
      <div className="row">
        <div className="col-12 mt-2 mb-3">
          <div className="normal-text-black first-white-card">
            <GrayMessageBar
              message={`There is no DSP advertising data available from ${fromDate}
      to ${toDate}.`}
            />
          </div>
        </div>
      </div>
    );
  };

  // function handle box toggle events
  const setBoxToggle = (name) => {
    if (
      Object.prototype.hasOwnProperty.call(selectedDspMetrics, name) &&
      _.size(selectedDspMetrics) > 1
    ) {
      setSelectedDspMetrics(_.omit(selectedDspMetrics, [name]));
    } else if (_.size(selectedDspMetrics) < 4) {
      setSelectedDspMetrics(
        _.omit(_.assign(selectedDspMetrics, { [name]: true })),
      );
    }
  };

  // function - set box toggle classes
  const setBoxClasses = (name, classValue) => {
    let selectedClass = '';
    if (Object.prototype.hasOwnProperty.call(selectedDspMetrics, name)) {
      selectedClass = `order-chart-box ${classValue} fix-height`;
    } else if (_.size(selectedDspMetrics) === 4) {
      selectedClass = 'order-chart-box fix-height disabled';
    } else {
      selectedClass = 'order-chart-box fix-height';
    }
    return selectedClass;
  };

  // render dsp metrics section
  const renderDspMetricsSection = () => {
    return (
      <DspMetrics
        currencySymbol={currencySymbol}
        dspCurrentTotal={dspCurrentTotal}
        dspDifference={dspDifferenceData}
        dspPreviousTotal={dspPreviousTotal}
        setBoxToggle={setBoxToggle}
        setBoxClasses={setBoxClasses}
        isApiCall={dspGraphLoader}
      />
    );
  };

  // render dsp group by section
  const renderDspGroupBysection = () => {
    return (
      <div className="row mt-4 mb-3">
        {_.size(selectedDspMetrics) <= 2 ? (
          <div className="col-md-6 col-sm-12 order-md-1 order-2 mt-2">
            {renderRecentAndPreviousLabel(
              selectedDspMetrics,
              selectedDspDF?.value,
            )}
          </div>
        ) : (
          <div className="col-md-6 col-sm-12 order-md-1 order-2 mt-2" />
        )}
        <div className="col-md-6 col-sm-12 order-md-2 order-1">
          <DailyWeeklyMonthlyToggle
            // boolean
            loader={dspGraphLoader}
            // string
            toggleId="BT-DspDWM-Toggle"
            toggleState={dspGroupBy}
            // array
            toggleOptions={dailyWeeklyMonthlyOptions}
            // function
            toggleEventHandler={handleDSPGroupBy}
          />
        </div>
      </div>
    );
  };

  // render dsp sales performance graph
  const renderDspSalesPerformanceGraph = () => {
    return dspGraphLoader ? (
      <PageLoader
        component="performance-graph"
        color={Theme.orange}
        type="detail"
        width={40}
        height={40}
      />
    ) : dspChartData?.length ? (
      <DspPerformanceChart
        chartId="dspChart"
        chartData={dspChartData}
        currencySymbol={currencySymbol}
        selectedMetrics={selectedDspMetrics}
        selectedDF={selectedDspDF?.value}
        isDashboard
        groupBy={dspGroupBy}
      />
    ) : (
      <NoData>{noGraphDataMessage}</NoData>
    );
  };

  // render dsp contribution section
  const renderDspContributionSection = () => {
    return (
      <DSPKeyContributors
        selectedDSPMatrics={selectedDspMetrics}
        selectedTabMetrics={selectedTabMetrics}
        downloadReportLoader={downloadReportLoader}
        isApiCall={keyContributionLoader}
        currencySymbol={currencySymbol}
        isDesktop={isDesktop}
        selectedDspDF={selectedDspDF}
        contributionCount={contributionCount}
        pageNumber={pageNumber}
        count={contributionCount}
        contributionData={contributionData}
        selectedBrandPartner={selectedBrandPartner}
        handlePageChange={handlePageChange}
        handleOnMatricsTabChange={handleOnMatricsTabChange}
        displayContributionTable={displayContributionTable}
        handleKeyContributorsDownloadReport={
          handleKeyContributorsDownloadReport
        }
      />
    );
  };

  // render dsp pacing section
  const renderDspPacingSection = () => {
    return (
      <DSPPacing
        currencySymbol={currencySymbol}
        loader={dspPacingLoader}
        dspPacingData={dspPacingData}
        selectedOption={selectedSpendingOption}
        month={month}
        handleSpendingOptions={handleSpendingOptions}
      />
    );
  };

  // display dsp dashboard section
  const displayDspDashboardSection = () => {
    return (
      <>
        <WhiteCard id="BT-dsp-Performance-Section" className="mb-3">
          {renderDspDailyFact()}
          {selectedDspDF.value !== CUSTOM ? (
            <GrayMessageBar message={performanceInsightMessage?.dsp} />
          ) : null}
          {selectedDspDF.value === CUSTOM && customDateError !== null
            ? renderErrorMessage()
            : null}
          {renderDspMetricsSection()}
          {renderDspGroupBysection()}
          {renderDspSalesPerformanceGraph()}
        </WhiteCard>
        {renderDspContributionSection()}
        {renderDspPacingSection()}
      </>
    );
  };

  // render dsp modals
  const renderDspModals = () => {
    return (
      <CustomDateModal
        id="BT-dspdashboard-daterange"
        isOpen={showAdCustomDateModal}
        range={dspCustomDateState}
        onClick={() => {
          setShowAdCustomDateModal(false);
          if (selectedDspDF.value !== CUSTOM) {
            setDspCustomDateState([
              {
                startDate: currentDate,
                endDate: currentDate,
                key: 'adSelection',
              },
            ]);
          }
        }}
        onChange={(item) => {
          setDspCustomDateState([item.adSelection]);
        }}
        onApply={() => applyCustomDate()}
        currentDate={currentDate}
        minDate={new Date('01/01/2019')}
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div id="BT-DspRightHandSideSection">
      {displayDspDashboardSection()}
      {renderDspModals()}
    </div>
  );
}

export default DspRightHandSideSection;
/* ********** Declaring prop types ********** */
DspRightHandSideSection.defaultProps = DspRightHandSideSectionDefaultProps;
DspRightHandSideSection.propTypes = DspRightHandSideSectionPropTypes;
