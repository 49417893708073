/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import PropTypes, { instanceOf } from 'prop-types';

import {
  ADCLICKRATE,
  ADCONVERSION,
  ADCOS,
  ADROAS,
  ADSALES,
  ADSPEND,
  COSTPERCLICK,
  CUSTOM,
  NTBSALES,
  PERCENTAGENTBORDERS,
  PERCENTAGENTBSALES,
  SALESPERCLICK,
  customReportGraphColorSet,
} from '../../../../../../constants';

am4core.useTheme(am4themes_dataviz);
am4core.color('red');
const _ = require('lodash');

export default function AdPerformanceChart({
  chartId,
  chartData,
  currencySymbol,
  selectedMetrics,
  selectedDF,
  groupBy,
}) {
  const chart = useRef(null);
  useEffect(() => {
    const currencyAxisValue = [
      ADSALES,
      ADSPEND,
      COSTPERCLICK,
      SALESPERCLICK,
      ADROAS,
      NTBSALES,
    ];
    const percentageAxis = [
      ADCONVERSION,
      ADCLICKRATE,
      ADCOS,
      PERCENTAGENTBORDERS,
      PERCENTAGENTBSALES,
    ];
    // const colorSet = {
    //   adSales: '#0045B4',
    //   adSpend: '#8C54FF',
    //   adConversion: '#30A8BD',
    //   impressions: '#D6A307',
    //   adCos: '#E05D37',
    //   adRoas: '#E05D37',
    //   adClicks: '#C84EC6',
    //   adClickRate: '#A04848',
    //   costPerClick: '#89A43C',
    //   salesPerClick: '#89A43C',
    //   ntbSales: '#89A43C',
    //   ntbOrders: '#89A43C',
    //   percentageNtbOrders: '#89A43C',
    //   percentageNtbSales: '#89A43C',
    // };

    const tooltipNames = {
      adSales: 'AD SALES',
      adSpend: 'AD SPEND',
      adConversion: 'AD CONVERSION RATE',
      impressions: 'IMPRESSIONS',
      adCos: 'ACOS',
      adRoas: 'ROAS',
      adClicks: 'CLICKS',
      adClickRate: 'CLICK THROUGH RATE',
      costPerClick: 'COST PER CLICK',
      salesPerClick: 'SALES PER CLICK',
      ntbSales: 'NEW-TO-BRAND (NTB) SALES',
      ntbOrders: 'NEW-TO-BRAND (NTB) ORDERS',
      percentageNtbOrders: '% Of ORDERS NEW-TO-BRAND (NTB)',
      percentageNtbSales: '% Of SALES NEW-TO-BRAND (NTB)',
    };

    // create value axis function
    function createValueAxis(axisColor) {
      const localValueAxis = chart.current.yAxes.push(
        new am4charts.ValueAxis(),
      );
      localValueAxis.renderer.grid.template.disabled = true;
      localValueAxis.cursorTooltipEnabled = false;
      localValueAxis.renderer.baseGrid.disabled = true;
      localValueAxis.numberFormatter = new am4core.NumberFormatter();
      localValueAxis.numberFormatter.numberFormat = `#.#a`;
      localValueAxis.extraMax = 0.005;
      localValueAxis.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: 'K' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
      ];
      localValueAxis.numberFormatter.smallNumberPrefixes = [];
      localValueAxis.stroke = am4core.color(axisColor);
      localValueAxis.strokeOpacity = 0.5;
      return localValueAxis;
    }

    chart.current = am4core.create(chartId, am4charts.XYChart);
    chart.current.data = chartData;
    chart.current.padding(20, 0, 10, 0);
    chart.current.logo.disabled = true; // disable amchart logo
    chart.current.zoomOutButton.disabled = true; // disabled zoomout button
    // render X axis
    const dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.labels.template.location = 0.5;
    dateAxis.dy = 10;
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.periodChangeDateFormats.setKey('month', 'MMM');
    dateAxis.gridIntervals.setAll([
      { timeUnit: 'day', count: 1 },
      { timeUnit: 'day', count: 5 },
      { timeUnit: 'day', count: 10 },
      { timeUnit: 'day', count: 15 },
      { timeUnit: 'day', count: 20 },
      { timeUnit: 'week', count: 1 },
      { timeUnit: 'week', count: 5 },
      { timeUnit: 'week', count: 10 },
      { timeUnit: 'month', count: 1 },
      { timeUnit: 'month', count: 2 },
      { timeUnit: 'month', count: 3 },
      { timeUnit: 'month', count: 6 },
    ]);

    let valueAxis = createValueAxis();

    // Add cursor
    chart.current.cursor = new am4charts.XYCursor();
    chart.current.cursor.lineY.disabled = true;
    chart.current.cursor.lineX.disabled = true;
    chart.current.cursor.behavior = 'none';

    // function to generate complete tooltip html
    function generateToolTipHTML(tooltipValue, startDate, endDate) {
      let html = '';
      if (groupBy === 'weekly' || groupBy === 'monthly') {
        html = `<div style="font-size: 14px; font-family: Noah-Medium; padding:5px 0">
          From - ${startDate} To - ${endDate}
        </div>
        ${tooltipValue}`;
      } else {
        html = tooltipValue;
      }

      return html;
    }

    function renderTooltip(name, color, value, currency, percent, formatter) {
      const tooltipText = ` <ul style="padding:0; margin: 0 0 4px 0; max-width: 320px;">
      <li style="display: inline-block;">
        {' '}
        <div style="background-color: ${color};
        border: 1px solid white;
        border-radius: ${name === 'Previous' ? '2px' : '100%'};
        width: 10px;
        height: 10px;" />
      </li>
      <li style="display: inline-block;">
        <div style="color: #f4f6fc;
        text-transform: uppercase;
        font-size: 11px;
        padding-left: 5px;">${name} </div>
      </li>
      <li style="display: inline-block; float: right; margin-left: 25px;">
        <div style=" color: white;
        font-size: 16px; text-align: right;
       
        ">${currency !== null ? currency : ''}${
        formatter !== null
          ? `{${value}.formatNumber('${formatter}')}`
          : `{${value}}`
      }${percent !== null ? percent : ''}
      </div>
      </li>
    </ul>
    `;
      return tooltipText;
    }

    function bindValueAxisFormatter(item) {
      let format = '';
      if (currencyAxisValue.includes(item)) {
        format = `${currencySymbol}#.#a`;
      } else if (percentageAxis.includes(item)) {
        format = `#.#'%'`;
      } else {
        // for unit axis
        format = `#.#a`;
      }
      return format;
    }

    const selectedMatricsFlag = _.size(selectedMetrics) <= 2;

    if (selectedMatricsFlag) {
      // create object of 2nd value axis
      const snapToSeries = [];
      let tooltipValue = '';
      const dashLine = ``;

      // loop for genearate tooltip
      _.keys(selectedMetrics).map((item, index) => {
        const currentLabel = `${item}CurrentLabel`;
        const previousLabel = `${item}PreviousLabel`;
        const colorCode = customReportGraphColorSet[index];
        tooltipValue = `${tooltipValue} ${tooltipNames[item]}`;
        if (currencyAxisValue.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            'Recent',
            colorCode,
            currentLabel,
            currencySymbol,
            null,
            '#,###',
          )}`;
          if (selectedDF !== CUSTOM) {
            tooltipValue = `${tooltipValue} ${renderTooltip(
              'Previous',
              colorCode,
              previousLabel,
              currencySymbol,
              null,
              '#,###',
            )}`;
          }
        } else if (percentageAxis.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            'Recent',
            colorCode,
            currentLabel,
            null,
            '%',
            null,
          )}`;
          if (selectedDF !== CUSTOM) {
            tooltipValue = `${tooltipValue} ${renderTooltip(
              'Previous',
              colorCode,
              previousLabel,
              null,
              '%',
              null,
            )}`;
          }
        } else {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            'Recent',
            colorCode,
            currentLabel,
            null,
            null,
            '#.#a',
          )}`;
          if (selectedDF !== CUSTOM) {
            tooltipValue = `${tooltipValue} ${renderTooltip(
              'Previous',
              colorCode,
              previousLabel,
              null,
              null,
              '#.#a',
            )}`;
          }
        }
        return '';
      });

      _.keys(selectedMetrics).map((item, index) => {
        const series = chart.current.series.push(new am4charts.LineSeries());
        const series2 = chart.current.series.push(new am4charts.LineSeries());
        const currentValue = `${item}Current`;
        const previousValue = `${item}Previous`;
        const seriesName = `${item}Series`;
        const colorCode = customReportGraphColorSet[index];

        valueAxis = createValueAxis(colorCode);
        series.yAxis = valueAxis;
        series2.yAxis = valueAxis;
        valueAxis.numberFormatter.numberFormat = bindValueAxisFormatter(item);

        if (index === 1) {
          valueAxis.renderer.opposite = true;
        }

        series.dataFields.valueY = currentValue;
        series.dataFields.dateX = 'date';
        series.name = seriesName;
        series.strokeWidth = 2;
        // series.tooltipHTML = `${tooltipValue}`;
        series.stroke = am4core.color(colorCode);
        series.fill = am4core.color('#2e384d');
        series.adapter.add('tooltipHTML', (text, target) => {
          const dateRange = target.tooltipDataItem.dataContext;
          return generateToolTipHTML(
            tooltipValue,
            dateRange?.date,
            dateRange?.endDate,
          );
        });

        const circleBullet = series.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.fill = am4core.color(colorCode);
        circleBullet.circle.strokeWidth = 1;
        circleBullet.circle.radius = 5;

        series2.dataFields.valueY = previousValue;
        series2.name = previousValue;
        series2.dataFields.dateX = 'date';
        series2.strokeWidth = 2;
        series2.stroke = am4core.color(colorCode);
        // series2.tooltipHTML = `${toolti pValue}`;
        series2.fill = am4core.color('#2e384d');
        series2.strokeDasharray = '8,4';
        series2.propertyFields.strokeDasharray = dashLine;
        series2.adapter.add('tooltipHTML', (text, target) => {
          const dateRange = target.tooltipDataItem.dataContext;
          return generateToolTipHTML(
            tooltipValue,
            dateRange?.previousDate,
            dateRange?.previousEndDate,
          );
        });

        const bullet = series2.bullets.push(new am4charts.Bullet());
        const square = bullet.createChild(am4core.Rectangle);
        square.fill = am4core.color(colorCode);
        square.width = 8;
        square.height = 8;
        square.horizontalCenter = 'middle';
        square.verticalCenter = 'middle';

        snapToSeries.push(series);
        snapToSeries.push(series2);
        return '';
      });

      chart.current.cursor.snapToSeries = snapToSeries;
    } else {
      // else part- for multiple metrics selected

      const snapToSeries = [];
      let tooltipValue = '';

      // loop for genearate tooltip
      _.keys(selectedMetrics).map((item, index) => {
        const value = `${item}CurrentLabel`;
        if (currencyAxisValue.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            customReportGraphColorSet[index],
            value,
            currencySymbol,
            null,
            '#,###',
          )}`;
        } else if (percentageAxis.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            customReportGraphColorSet[index],
            value,
            null,
            '%',
            null,
          )}`;
        } else {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            customReportGraphColorSet[index],
            value,
            null,
            null,
            '#.#a',
          )}`;
        }
        return '';
      });

      _.keys(selectedMetrics).map((item, index) => {
        const series = chart.current.series.push(new am4charts.LineSeries());

        valueAxis = createValueAxis(customReportGraphColorSet[index]);
        series.yAxis = valueAxis;
        valueAxis.numberFormatter.numberFormat = bindValueAxisFormatter(item);

        if (index === 1 || index === 3) {
          valueAxis.renderer.opposite = true;
        }
        const currentValue = `${item}Current`;
        const seriesName = `${item}Series`;

        series.dataFields.valueY = currentValue;
        series.dataFields.dateX = 'date';
        series.name = seriesName;
        series.strokeWidth = 2;
        // series.tooltipHTML = `${tooltipValue}`;
        series.stroke = am4core.color(customReportGraphColorSet[index]);
        series.fill = am4core.color('#2e384d');
        series.adapter.add('tooltipHTML', (text, target) => {
          const dateRange = target.tooltipDataItem.dataContext;
          return generateToolTipHTML(
            tooltipValue,
            dateRange?.date,
            dateRange?.endDate,
          );
        });

        const circleBullet = series.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.fill = am4core.color(
          customReportGraphColorSet[index],
        );
        circleBullet.circle.strokeWidth = 1;
        circleBullet.circle.radius = 5;

        snapToSeries.push(series);
        return '';
      });

      chart.current.cursor.snapToSeries = snapToSeries;
    }
    return () => chart.current && chart.current.dispose();
  }, [
    chartId,
    chartData,
    currencySymbol,
    selectedMetrics,
    selectedDF,
    groupBy,
  ]);

  return <div id={chartId} style={{ width: '100%', height: '500px' }} />;
}

AdPerformanceChart.defaultProps = {
  chartData: [],
  currencySymbol: '',
  selectedMetrics: {},
  selectedDF: '',
  groupBy: '',
};

AdPerformanceChart.propTypes = {
  chartId: PropTypes.string.isRequired,
  chartData: PropTypes.arrayOf(PropTypes.object),
  currencySymbol: PropTypes.string,
  selectedMetrics: instanceOf(Object),
  selectedDF: PropTypes.string,
  groupBy: PropTypes.string,
};
