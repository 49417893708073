/* ********** Third party package imports ********** */
import React from 'react';

/* ********** Third party package imports ********** */
import { bool, func, string, shape, arrayOf } from 'prop-types';

/* ********** Local component file imports ********** */
import { WhiteCard } from '../../../../../common';
import VendorSalesPerformance from './VendorSalesPerformance';
import SalesVendorContribution from './SalesVendorContribution';

/* ********** MAIN FUNCTION START HERE ********** */
function SalesVendorReporting({
  // string
  currencySymbol,
  selectedTabMetrics,
  // boolean
  salesGraphLoader,
  contributionLoader,
  // object
  selectedSalesDF,
  vendorSalesDifference,
  selectedVendorMetrics,
  vendorSalesCurrentTotal,
  vendorSalesPreviousTotal,
  selectedVendorMetricsType,
  // array
  contributionData,
  vendorSalesMetricsData,
  // function
  displaySalesPagination,
  handleOnMetricsTabChange,
  setSelectedVendorMetrics,
  displayContributionTable,
  renderRecentAndPreviousLabel,
  displayPerformanceSectionHeader,
  dispalyContributionSectionHeader,
  displayDailyWeeklyMonthlyToggleButton,
}) {
  // render vendor sales performance
  const renderVendorSalesPerformance = () => {
    return (
      <VendorSalesPerformance
        // string
        currencySymbol={currencySymbol}
        // boolean
        isApiCall={salesGraphLoader}
        // object
        selectedSalesDF={selectedSalesDF}
        vendorSalesDifference={vendorSalesDifference}
        vendorSalesCurrentTotal={vendorSalesCurrentTotal}
        vendorSalesPreviousTotal={vendorSalesPreviousTotal}
        selectedVendorMetrics={selectedVendorMetrics}
        selectedVendorMetricsType={selectedVendorMetricsType}
        // array
        vendorSalesMetricsData={vendorSalesMetricsData}
        // function
        setSelectedVendorMetrics={setSelectedVendorMetrics}
        renderRecentAndPreviousLabel={renderRecentAndPreviousLabel}
        displayDailyWeeklyMonthlyToggleButton={
          displayDailyWeeklyMonthlyToggleButton
        }
      />
    );
  };

  // render sales vendor contribution
  const renderSalesVendorContribution = () => {
    return (
      <SalesVendorContribution
        // string
        selectedTabMetrics={selectedTabMetrics}
        // boolean
        isApiCall={contributionLoader}
        // object
        selectedSalesDF={selectedSalesDF}
        selectedVendorMetricsType={selectedVendorMetricsType}
        // array
        vendorContributionData={contributionData}
        // function
        displaySalesPagination={displaySalesPagination}
        handleOnMetricsTabChange={handleOnMetricsTabChange}
        displayContributionTable={displayContributionTable}
        dispalyContributionSectionHeader={dispalyContributionSectionHeader}
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      <WhiteCard className="mb-3">
        {displayPerformanceSectionHeader()}
        {renderVendorSalesPerformance()}
      </WhiteCard>
      <WhiteCard className="mb-3">{renderSalesVendorContribution()}</WhiteCard>
    </div>
  );
}

export default SalesVendorReporting;
/* ********** MAIN FUNCTION END HERE ********** */

/* ********** Declaring prop types ********** */
SalesVendorReporting.defaultProps = {
  // string
  currencySymbol: '',
  selectedTabMetrics: '',
  // boolean
  salesGraphLoader: false,
  contributionLoader: false,
  // object
  selectedSalesDF: {},
  vendorSalesDifference: {},
  selectedVendorMetrics: {},
  vendorSalesCurrentTotal: {},
  vendorSalesPreviousTotal: {},
  selectedVendorMetricsType: {},
  // array
  contributionData: [],
  vendorSalesMetricsData: [],
  // function
  displaySalesPagination: () => {},
  setSelectedVendorMetrics: () => {},
  handleOnMetricsTabChange: () => {},
  displayContributionTable: () => {},
  renderRecentAndPreviousLabel: () => {},
  displayPerformanceSectionHeader: () => {},
  dispalyContributionSectionHeader: () => {},
  displayDailyWeeklyMonthlyToggleButton: () => {},
};

SalesVendorReporting.propTypes = {
  // string
  currencySymbol: string,
  selectedTabMetrics: string,
  //  boolean
  salesGraphLoader: bool,
  contributionLoader: bool,
  // object
  selectedSalesDF: shape({}),
  vendorSalesDifference: shape({}),
  selectedVendorMetrics: shape({}),
  vendorSalesCurrentTotal: shape({}),
  vendorSalesPreviousTotal: shape({}),
  selectedVendorMetricsType: shape({}),
  // array
  contributionData: arrayOf(shape({})),
  vendorSalesMetricsData: arrayOf(shape({})),
  // function
  displaySalesPagination: func,
  setSelectedVendorMetrics: func,
  handleOnMetricsTabChange: func,
  displayContributionTable: func,
  renderRecentAndPreviousLabel: func,
  displayPerformanceSectionHeader: func,
  dispalyContributionSectionHeader: func,
  displayDailyWeeklyMonthlyToggleButton: func,
};
