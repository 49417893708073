/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, func, number, shape, string } from 'prop-types';

import Theme from '../../../../../theme/Theme';
import { Icon } from '../../../../../theme/Global';
import { OrangeDownloadPdf } from '../../../../../theme/images';
import { getCreativeProjectTableCSV } from '../../../../../api/CompanyPerformanceApi';
import {
  CommonPagination,
  DesktopTable,
  ToggleButton,
} from '../../../../../common';
import {
  projectASINTableHeader,
  dataAfterCompletion,
  projectASINVendorTableHeader,
  projectASINVendorShippedCogsTableHeader,
} from '../../../../../constants';

export default function ProjectAsinsTable({
  projectDetails,
  tableInfoDetails,
  id,
  params,
  filterationData,
  pageNumber,
  asinCounts,
  handlePageChange,
  setShowProjectAsinTable,
  showProjectAsinTable,
  getTotalOfTable,
  setPaginationChecksum,
  setPageNumber,
  paginationChecksum,
  selectedVendorReporting,
}) {
  const [showCollapse, setShowCollapse] = useState({
    orangeProjectHeader: true,
    grayAsinHeader: false,
  });
  const [downloadCsv, setDownloadCsv] = useState(false);

  const tableView = Object.keys(showProjectAsinTable).find(
    (key) => showProjectAsinTable[key],
  );
  const tableHeader =
    params.sub === 'seller'
      ? projectASINTableHeader
      : selectedVendorReporting === 'ordered_revenue'
      ? projectASINVendorTableHeader
      : projectASINVendorShippedCogsTableHeader;

  const displayNumber = (num) => {
    const res = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return res?.toString().replace('-', '');
  };

  const getDownloadedCsv = () => {
    setDownloadCsv(true);
    getCreativeProjectTableCSV(
      id,
      params?.sub === 'seller' ? null : selectedVendorReporting,
      params?.sub || 'seller',
      filterationData,
      tableView?.split('_')?.[0],
    ).then((res) => {
      setDownloadCsv(false);
      if (res?.status === 200) window.location.assign(res?.data?.url);
    });
  };

  useEffect(() => {
    setShowCollapse({
      orangeProjectHeader: {
        [paginationChecksum || projectDetails?.[0]?.asin_checksum]: true,
      },
      grayAsinHeader: { [projectDetails?.[0]?.asin_checksum]: true },
    });
  }, []);

  useEffect(() => {
    if (showProjectAsinTable.project_view) {
      tableHeader[0].label = '';
      tableHeader[0].key = '';
    } else {
      tableHeader[0].label = 'Product';
      tableHeader[0].key = 'product';
    }
  }, [showProjectAsinTable, projectDetails]);

  const returnASINChangeStyle = (value) => {
    const styleObj = { class: 'range mt-1', color: Theme.black };
    if (value === null) {
      return styleObj;
    }
    if (value >= 0) {
      styleObj.class = 'range  increase-rate mt-1';
      styleObj.color = Theme.green;
      return styleObj;
    }
    styleObj.class = 'range  decrease-rate mt-1';
    styleObj.color = Theme.red;
    return styleObj;
  };

  const bindUpDownArrow = (isChangedValue, value) => {
    if (isChangedValue) {
      return (
        <FontAwesomeIcon
          fontSize="12px"
          color={returnASINChangeStyle(value).color}
          icon="fa-light fa-arrow-up"
          style={{
            marginRight: '4px',
            transform: value < 0 ? 'rotate(180deg)' : '',
          }}
        />
      );
    }
    return null;
  };
  const renderProjectViewRowDetail = (
    item,
    completion,
    key,
    prefix,
    suffix,
    isChangedValue,
    changedKey,
    isShow2Decimal,
  ) => {
    const value =
      tableInfoDetails?.[tableView]?.[item?.asin_checksum]?.[
        completion?.value
      ]?.[0]?.[key];

    let changedValue = null;
    if (isChangedValue) {
      changedValue =
        tableInfoDetails?.[tableView]?.[item?.asin_checksum]?.[
          completion?.value
        ]?.[0]?.[changedKey];
    }

    if (value !== undefined && value !== null) {
      return (
        <>
          <div>
            {`${prefix !== '' ? item?.currency : prefix}${displayNumber(
              isShow2Decimal ? value?.toFixed(2) : value,
            )}${suffix}`}
          </div>
          {isChangedValue ? (
            <div className={`${returnASINChangeStyle(changedValue).class} `}>
              {bindUpDownArrow(isChangedValue, changedValue)}
              {`${prefix !== '' ? item?.currency : prefix}${displayNumber(
                isShow2Decimal ? changedValue?.toFixed(2) : changedValue,
              )}${suffix}`}
            </div>
          ) : null}
        </>
      );
    }

    return '-';
  };

  // Asin view - Total row
  const renderAsinViewTotalRow = (item, day) => {
    const isChangedValue = [
      '60_days_after',
      '90_days_after',
      '120_days_after',
    ].includes(day);

    return (
      <tr className="total-product">
        <>
          <td className=" product-col ">TOTAL</td>
          {params.sub === 'seller' ? (
            <>
              <td>
                <div>
                  {item?.currency || '$'}
                  {displayNumber(
                    tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.total_ordered_prod_sales?.toFixed(2),
                  ) || 0}
                </div>

                {isChangedValue &&
                tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                  `${day}_total`
                ]?.change_total_ordered_prod_sales !== null ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.change_total_ordered_prod_sales,
                      ).class
                    }`}
                  >
                    {bindUpDownArrow(
                      true,
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_total_ordered_prod_sales,
                    )}
                    {item?.currency || '$'}
                    {displayNumber(
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_total_ordered_prod_sales?.toFixed(2),
                    ) || 0}
                  </div>
                ) : null}
              </td>
              <td>
                <div>
                  {displayNumber(
                    tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.total_units_ordered,
                  ) || '-'}
                </div>
                {isChangedValue &&
                tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                  `${day}_total`
                ]?.change_total_units_ordered !== null ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.change_total_units_ordered,
                      ).class
                    }`}
                  >
                    {bindUpDownArrow(
                      true,
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_total_units_ordered,
                    )}
                    {displayNumber(
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_total_units_ordered,
                    ) || '-'}
                  </div>
                ) : null}
              </td>
              <td>
                <div>
                  {displayNumber(
                    tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.avg_average_units,
                  ) || '-'}
                </div>
                {isChangedValue &&
                tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                  `${day}_total`
                ]?.change_avg_units_ordered != null ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.change_avg_units_ordered,
                      ).class
                    } `}
                  >
                    {' '}
                    {bindUpDownArrow(
                      true,
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_avg_units_ordered,
                    )}
                    {displayNumber(
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_avg_units_ordered,
                    ) || '-'}
                  </div>
                ) : null}
              </td>
              <td>
                <div>
                  {displayNumber(
                    tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.total_page_view,
                  ) || '-'}
                </div>
                {isChangedValue &&
                tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                  `${day}_total`
                ]?.change_total_page_view !== null ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.change_total_page_view,
                      ).class
                    } `}
                  >
                    {' '}
                    {bindUpDownArrow(
                      true,
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_total_page_view,
                    )}
                    {displayNumber(
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_total_page_view,
                    ) || '-'}
                  </div>
                ) : null}
              </td>
              <td>
                <div>
                  {displayNumber(
                    tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.total_session,
                  ) || '-'}
                </div>
                {isChangedValue &&
                tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                  `${day}_total`
                ]?.change_total_session !== null ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.change_total_session,
                      ).class
                    } `}
                  >
                    {' '}
                    {bindUpDownArrow(
                      true,
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_total_session,
                    )}
                    {displayNumber(
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_total_session,
                    ) || '-'}
                  </div>
                ) : null}
              </td>
              <td>
                <div>
                  {displayNumber(
                    tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.avg_bbe_percentage?.toFixed(2),
                  ) || 0}
                  %
                </div>
                {isChangedValue &&
                tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                  `${day}_total`
                ]?.change_avg_bbe_percentage !== null ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.change_avg_bbe_percentage,
                      ).class
                    } `}
                  >
                    {bindUpDownArrow(
                      true,
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_avg_bbe_percentage,
                    )}
                    {displayNumber(
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_avg_bbe_percentage?.toFixed(2),
                    ) || 0}
                    %
                  </div>
                ) : null}
              </td>
              <td>
                <div>
                  {displayNumber(
                    tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.avg_conversion?.toFixed(2),
                  ) || 0}
                  %
                </div>
                {isChangedValue &&
                tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                  `${day}_total`
                ]?.change_avg_conversion !== null ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.change_avg_conversion,
                      ).class
                    } `}
                  >
                    {bindUpDownArrow(
                      true,
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_avg_conversion,
                    )}
                    {displayNumber(
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_avg_conversion?.toFixed(2),
                    ) || 0}
                    %
                  </div>
                ) : null}
              </td>
            </>
          ) : (
            <>
              <td>
                <div>
                  {item?.currency || '$'}
                  {selectedVendorReporting === 'ordered_revenue'
                    ? displayNumber(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.total_ordered_revenue?.toFixed(2) || 0,
                      )
                    : displayNumber(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.total_shipped_cogs?.toFixed(2),
                      ) || 0}
                </div>

                {isChangedValue &&
                (selectedVendorReporting === 'ordered_revenue'
                  ? tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.change_total_ordered_revenue !== null
                  : tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.change_total_shipped_cogs !== null) ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        selectedVendorReporting === 'ordered_revenue'
                          ? tableInfoDetails?.[tableView]?.[
                              item.asin_checksum
                            ]?.[`${day}_total`]?.change_total_ordered_revenue
                          : tableInfoDetails?.[tableView]?.[
                              item.asin_checksum
                            ]?.[`${day}_total`]?.change_total_shipped_cogs,
                      ).class
                    } `}
                  >
                    {' '}
                    {bindUpDownArrow(
                      true,
                      selectedVendorReporting === 'ordered_revenue'
                        ? tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                            `${day}_total`
                          ]?.change_total_ordered_revenue
                        : tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                            `${day}_total`
                          ]?.change_total_shipped_cogs,
                    )}
                    {item?.currency || '$'}
                    {selectedVendorReporting === 'ordered_revenue'
                      ? displayNumber(
                          tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                            `${day}_total`
                          ]?.change_total_ordered_revenue?.toFixed(2),
                        )
                      : displayNumber(
                          tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                            `${day}_total`
                          ]?.change_total_shipped_cogs?.toFixed(2),
                        ) || 0}
                  </div>
                ) : null}
              </td>
              <td>
                <div>
                  {displayNumber(
                    tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.total_glance_views,
                  ) || '-'}
                </div>
                {isChangedValue &&
                tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                  `${day}_total`
                ]?.change_total_glance_views !== null ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.change_total_glance_views,
                      ).class
                    } `}
                  >
                    {bindUpDownArrow(
                      true,
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_total_glance_views,
                    )}
                    {displayNumber(
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_total_glance_views,
                    ) || '-'}
                  </div>
                ) : null}
              </td>
              <td>
                <div>
                  {displayNumber(
                    tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.avg_conversion_rate?.toFixed(2),
                  ) || 0}
                  %
                </div>
                {isChangedValue &&
                tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                  `${day}_total`
                ]?.change_avg_conversion_rate !== null ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.change_avg_conversion_rate,
                      ).class
                    } `}
                  >
                    {bindUpDownArrow(
                      true,
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_avg_conversion_rate,
                    )}
                    {displayNumber(
                      tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                        `${day}_total`
                      ]?.change_avg_conversion_rate?.toFixed(2),
                    ) || 0}
                    %
                  </div>
                ) : null}
              </td>
              <td>
                <div>
                  {selectedVendorReporting === 'ordered_revenue'
                    ? displayNumber(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.total_ordered_units,
                      ) || '-'
                    : displayNumber(
                        tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                          `${day}_total`
                        ]?.total_shipped_units,
                      ) || '-'}
                </div>
                {isChangedValue &&
                (selectedVendorReporting === 'ordered_revenue'
                  ? tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.change_total_ordered_units !== null
                  : tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                      `${day}_total`
                    ]?.change_total_shipped_units !== null) ? (
                  <div
                    className={`${
                      returnASINChangeStyle(
                        selectedVendorReporting === 'ordered_revenue'
                          ? tableInfoDetails?.[tableView]?.[
                              item.asin_checksum
                            ]?.[`${day}_total`]?.change_total_ordered_units
                          : tableInfoDetails?.[tableView]?.[
                              item.asin_checksum
                            ]?.[`${day}_total`]?.change_total_shipped_units,
                      ).class
                    } `}
                  >
                    {bindUpDownArrow(
                      true,
                      selectedVendorReporting === 'ordered_revenue'
                        ? tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                            `${day}_total`
                          ]?.change_total_ordered_units
                        : tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                            `${day}_total`
                          ]?.change_total_shipped_units,
                    )}{' '}
                    {selectedVendorReporting === 'ordered_revenue'
                      ? displayNumber(
                          tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                            `${day}_total`
                          ]?.change_total_ordered_units,
                        )
                      : displayNumber(
                          tableInfoDetails?.[tableView]?.[item.asin_checksum]?.[
                            `${day}_total`
                          ]?.change_total_shipped_units,
                        ) || '-'}
                  </div>
                ) : null}
              </td>
            </>
          )}
        </>
      </tr>
    );
  };

  const renderAsinViewTableData = (asin, currency) => {
    return (
      <>
        <tr>
          <td className="product-col">
            <div className="d-flex">
              <ReactTooltip
                id={asin?.product_name}
                aria-haspopup="true"
                place="bottom"
                effect="solid"
              />
              <div className="product-number text-regular mr-4">
                {asin?.child_asin}
              </div>
              <span data-tip={asin?.product_name} data-for={asin?.product_name}>
                <div className="product-number text-regular textLimit11 cursor">
                  {asin?.product_name}
                </div>
              </span>
            </div>
          </td>
          {tableHeader?.map((item) => {
            if (item.asin_view)
              return (
                <td>
                  {asin?.[item.asin_view] ? (
                    <>
                      {item?.prefix !== '' ? currency : item?.prefix}
                      {displayNumber(
                        item?.isShow2Decimal
                          ? asin?.[item.asin_view]?.toFixed(2)
                          : asin?.[item.asin_view],
                      )}
                      {item.suffix}
                    </>
                  ) : (
                    '-'
                  )}
                </td>
              );
            return null;
          })}
        </tr>
      </>
    );
  };

  // To display Total row of project view.
  const renderProjectViewTotalRow = (item, completion) => {
    return (
      <tr>
        <td className="product-col">Total</td>
        {tableHeader?.map((field) => {
          if (tableView === 'project_view' && field.key)
            return (
              <td className="pr-0">
                {renderProjectViewRowDetail(
                  item,
                  completion,
                  field.total,
                  field.prefix,
                  field.suffix,
                  ['60_days_after', '90_days_after', '120_days_after'].includes(
                    completion.value,
                  ),
                  field.changedValueKey,
                  field.isShow2Decimal,
                )}
              </td>
            );
          return null;
        })}
      </tr>
    );
  };

  // To display Average row of project view.
  const renderProjectViewAvgRow = (item, completion) => {
    return (
      <tr>
        <td className="product-col">Average</td>
        {tableHeader?.map((field) => {
          if (field.key)
            return (
              <td>
                {renderProjectViewRowDetail(
                  item,
                  completion,
                  field.avg,
                  field.prefix,
                  field.suffix,
                  false, // ['60_days_after', '90_days_after', '120_days_after'].includes(completion.value)
                  null, // change value key
                  true, // isShow2Decimal, // we need to add 2 decimal for all average value -pdv- 8145
                )}
              </td>
            );
          return null;
        })}
      </tr>
    );
  };

  useEffect(() => {
    if (showCollapse.orangeProjectHeader) {
      setPaginationChecksum(Object.keys(showCollapse.orangeProjectHeader)?.[0]);
    }
  }, [showCollapse]);

  const renderTotalAfterCompletionRow = (asinCheckSum, currency) => {
    if (getTotalOfTable?.length) {
      const total = getTotalOfTable?.find((op) => op[asinCheckSum])
        ? Object.values(getTotalOfTable.find((op) => op[asinCheckSum]))?.[0]
        : 0;

      return (
        <tr className="total-product">
          <>
            <td className=" product-col ">TOTAL AFTER COMPLETION</td>
            {params.sub === 'seller' ? (
              <>
                <td>
                  {' '}
                  {currency || '$'}
                  {displayNumber(total?.total_ordered_prod_sales?.toFixed(2)) ||
                    0}
                </td>
                <td>{displayNumber(total?.total_units_ordered) || '-'}</td>
                <td> {displayNumber(total?.avg_average_units) || '-'}</td>
                <td> {displayNumber(total?.total_page_view) || '-'}</td>
                <td> {displayNumber(total?.total_session) || '-'}</td>
                <td>
                  {displayNumber(total?.avg_bbe_percentage?.toFixed(2)) || 0}%
                </td>
                <td>
                  {displayNumber(total?.avg_conversion?.toFixed(2)) || 0}%
                </td>
              </>
            ) : (
              <>
                <td>
                  {' '}
                  {currency || '$'}
                  {selectedVendorReporting === 'ordered_revenue'
                    ? displayNumber(total?.total_ordered_revenue?.toFixed(2)) ||
                      0
                    : displayNumber(total?.total_shipped_cogs?.toFixed(2)) || 0}
                </td>
                <td>{displayNumber(total?.total_glance_views) || 0}</td>
                <td>
                  {displayNumber(total?.avg_conversion_rate?.toFixed(2)) || 0}%
                </td>
                <td>
                  {selectedVendorReporting === 'ordered_revenue'
                    ? displayNumber(total?.total_ordered_units) || 0
                    : displayNumber(total?.total_shipped_units) || 0}
                  %
                </td>
              </>
            )}
          </>
        </tr>
      );
    }
    return null;
  };

  const performanceBreakdownHeader = () => {
    return (
      <div className="row justify-space-between">
        <div className="col-md-6 col-sm-12 black-heading-title mt-2 mb-3">
          Performance Breakdown
        </div>

        <div className="col-md-6 col-sm-12 mb-2 mb-sm-0 creative-project-download">
          <ToggleButton>
            <div className="days-container">
              <ul className="days-tab">
                <li
                  role="presentation"
                  onClick={() => {
                    setShowProjectAsinTable({
                      project_view: true,
                      asins_view: false,
                    });
                  }}
                >
                  <input
                    className="d-none"
                    type="radio"
                    name="flexRadioDefault3"
                    id="projectChecked"
                    checked={showProjectAsinTable.project_view}
                  />
                  <label htmlFor="projectChecked">Project</label>
                </li>
                <li
                  role="presentation"
                  onClick={() => {
                    setShowProjectAsinTable({
                      project_view: false,
                      asins_view: true,
                    });
                  }}
                >
                  <input
                    className="d-none"
                    type="radio"
                    name="flexRadioDefault3"
                    id="AsinChecked"
                    checked={showProjectAsinTable.asins_view}
                  />
                  <label htmlFor="AsinChecked">ASIN</label>
                </li>
              </ul>
            </div>
          </ToggleButton>{' '}
          <div
            className={downloadCsv ? 'ml-2 cursor isDisabled' : 'ml-2 cursor'}
            role="presentation"
            onClick={() => getDownloadedCsv()}
          >
            <span className="download-link" download>
              <img
                width="20px"
                style={{ verticalAlign: 'bottom' }}
                src={OrangeDownloadPdf}
                alt="download"
                role="presentation"
              />
              Download Report
            </span>
          </div>
        </div>
      </div>
    );
  };

  const performanceBreakdownTable = () => {
    return (
      <DesktopTable>
        <thead>
          <tr
            className="gray-border-radius"
            style={{ verticalAlign: 'bottom' }}
          >
            {tableHeader?.map((item) => (
              <th width={item.width}>
                {item.label}
                {item.show_info ? (
                  <Icon
                    style={{
                      position: 'absolute',
                      top: '16px',
                    }}
                  >
                    <FontAwesomeIcon
                      data-for={`${item.label}info-icon`}
                      data-tip={item?.info_message}
                      data-html
                      icon="fa-regular fa-circle-info asin-header-info"
                    />
                    <ReactTooltip
                      className="asian-info-icon"
                      id={`${item.label}info-icon`}
                      aria-haspopup="true"
                      place="top"
                      effect="solid"
                      html
                      insecure
                    />
                  </Icon>
                ) : null}{' '}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {projectDetails?.map((item) => {
            return (
              <>
                <tr>
                  <td
                    className="parent-brand py-3 cursor"
                    colSpan="8"
                    onClick={() => {
                      setShowCollapse({
                        ...showCollapse,
                        orangeProjectHeader: {
                          [item.asin_checksum]:
                            !showCollapse.orangeProjectHeader[
                              item.asin_checksum
                            ],
                        },
                        grayAsinHeader: false,
                      });
                      setPageNumber(1);
                    }}
                    role="presentation"
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={`${
                          showCollapse?.orangeProjectHeader[item?.asin_checksum]
                            ? 'fa-regular fa-minus'
                            : 'fa-regular fa-plus'
                        }`}
                        style={{
                          color: Theme.orange,
                          fontSize: Theme.extraSmall,
                          marginRight: '16px',
                        }}
                      />
                    </span>
                    {item?.marketplace_id} | {item?.project_type} |{' '}
                    {item?.completion_date} |{' '}
                    {item?.asins_listings?.split(',')?.length} ASINs
                  </td>
                </tr>
                {showCollapse?.orangeProjectHeader[item.asin_checksum] ? (
                  <>
                    {dataAfterCompletion.map((completion) => {
                      return (
                        <>
                          <tr className="cursor">
                            <td
                              className="child-brand py-3"
                              colSpan="8"
                              onClick={() =>
                                setShowCollapse({
                                  ...showCollapse,
                                  grayAsinHeader: {
                                    ...showCollapse.grayAsinHeader,
                                    [`${[item?.asin_checksum]}-${[
                                      completion.value,
                                    ]}`]:
                                      !showCollapse.grayAsinHeader[
                                        `${[item?.asin_checksum]}-${[
                                          completion.value,
                                        ]}`
                                      ],
                                  },
                                })
                              }
                              role="presentation"
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={`${
                                    showCollapse?.grayAsinHeader[
                                      `${[item?.asin_checksum]}-${[
                                        completion?.value,
                                      ]}`
                                    ]
                                      ? 'fa-regular fa-plus'
                                      : 'fa-regular fa-minus '
                                  }`}
                                  style={{
                                    color: Theme.black,
                                    fontSize: Theme.extraSmall,
                                    marginRight: '16px',
                                  }}
                                />
                              </span>
                              {completion.label}
                            </td>
                          </tr>
                          {!showCollapse?.grayAsinHeader[
                            `${[item?.asin_checksum]}-${[completion?.value]}`
                          ] ? (
                            <>
                              {tableView === 'project_view' ? (
                                <>
                                  {renderProjectViewTotalRow(item, completion)}
                                  {renderProjectViewAvgRow(item, completion)}
                                </>
                              ) : (
                                // render asin view table
                                <>
                                  {tableInfoDetails?.[tableView]?.[
                                    item.asin_checksum
                                  ]?.[completion?.value]?.map((asin) => {
                                    return (
                                      <>
                                        {renderAsinViewTableData(
                                          asin,
                                          item?.currency,
                                        )}{' '}
                                      </>
                                    );
                                  })}
                                  {renderAsinViewTotalRow(
                                    item,
                                    completion?.value,
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      );
                    })}
                    {renderTotalAfterCompletionRow(
                      item.asin_checksum,
                      item.currency,
                    )}
                    {tableView === 'asins_view' ? (
                      <tr>
                        <td colSpan="8" className="p-0">
                          <CommonPagination
                            count={
                              asinCounts?.length
                                ? asinCounts?.find(
                                    (op) => op[item.asin_checksum],
                                  )
                                  ? Object.values(
                                      asinCounts.find(
                                        (op) => op[item.asin_checksum],
                                      ),
                                    )?.[0]
                                  : null
                                : 1
                            }
                            pageNumber={pageNumber}
                            handlePageChange={handlePageChange}
                          />
                        </td>
                      </tr>
                    ) : null}
                  </>
                ) : (
                  ''
                )}
              </>
            );
          })}
        </tbody>
      </DesktopTable>
    );
  };

  return projectDetails?.length ? (
    <>
      {performanceBreakdownHeader()}

      <ProductTableWrapper className="table-responsive">
        {performanceBreakdownTable()}
      </ProductTableWrapper>
    </>
  ) : (
    <div className="text-center m-2">
      No record found for Performance Breakdown.
    </div>
  );
}

ProjectAsinsTable.defaultProps = {
  projectDetails: [],
  tableInfoDetails: [],
  id: '',
  params: {},
  filterationData: {},
  pageNumber: 1,
  asinCounts: 1,
  handlePageChange: () => {},
  showProjectAsinTable: { project_view: true, asins_view: false },
  setShowProjectAsinTable: () => {},
  getTotalOfTable: [],
  setPaginationChecksum: () => {},
  setPageNumber: () => {},
  paginationChecksum: '',
  selectedVendorReporting: null,
};

ProjectAsinsTable.propTypes = {
  projectDetails: arrayOf(shape({})),
  tableInfoDetails: arrayOf(shape({})),
  id: string,
  params: shape({}),
  filterationData: shape({}),
  pageNumber: number,
  asinCounts: number,
  handlePageChange: func,
  showProjectAsinTable: shape({}),
  setShowProjectAsinTable: func,
  getTotalOfTable: arrayOf(shape({})),
  setPaginationChecksum: func,
  setPageNumber: func,
  paginationChecksum: string,
  selectedVendorReporting: string,
};

const ProductTableWrapper = styled.div`
  thead {
    tr {
      th {
        .asin-value {
          background: ${Theme.gray25};
          border-radius: 6px;
          font-size: ${Theme.textExtraSmall};
          color: ${Theme.gray6};
          padding: 2px 4px;
          width: fit-content;
          margin-bottom: 5px;
        }
      }
    }
  }
  tbody {
    tr {
      &.total-product {
        background: #33ac2e33;
      }
      td {
        &.parent-brand {
          background: ${Theme.lightOrange};
          color: ${Theme.orange};
          text-transform: uppercase;
          font-size: ${Theme.extraSmall};
          font-family: ${Theme.titleFontFamily};
        }

        &.child-brand {
          background: ${Theme.gray8};
          color: ${Theme.gray40};
          text-transform: uppercase;
          font-size: ${Theme.extraSmall};
          font-family: ${Theme.titleFontFamily};
        }

        &.product-col {
          border-right: 1px solid ${Theme.gray11};
        }

        .product-image {
          border-radius: 4px;
          border: 1px solid ${Theme.gray25};
          height: 42px;
          width: 42px;
          margin-right: 12px;
          float: left;
        }
        .product-name {
          font-size: ${Theme.extraNormal};
        }
        .product-number {
          color: ${Theme.gray85};
        }
      }
    }
  }
`;
