import React from 'react';

import PropTypes, { func, shape } from 'prop-types';
import { Collapse } from 'react-collapse';

import NoRecordFound from '../../../common/NoRecordFound';
import { CustomerListTabletView } from './CustomerListStyle';
import { PATH_CUSTOMER_DETAILS, metricsCurrency } from '../../../constants';
import { CommonPagination, PageLoader, WhiteCard } from '../../../common';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  UpDowGrayArrow,
} from '../../../theme/images';

export default function CustomerListTablet({
  data,
  history,
  count,
  pageNumber,
  handlePageChange,
  isLoading,
  showContractDetails,
  showPerformance,
  showAdPerformance,
  showDspAdPerformance,
  selectedAccountTypes,
  generateLogoCompanyNameAndGs,
  generateCompanyStatus,
  generatePerformance,
  showContractsList,
  selectedColumns,
  showCollapseButton,
  showChildContracts,
  displayAccountStatus,
  returnTooltipMessgeLWA,
  redirectToCustomerDetailView,
}) {
  const renderAdPerformanceDifference = (actualValue, grayArrow, matrics) => {
    let flag = '';
    let value = actualValue;
    if (value) {
      if (matrics === 'ACOS') {
        if (value.toString().includes('-')) {
          flag = 'green';
          value = value
            ? `${Number(value.toString().split('-')[1]).toFixed(2)} %`
            : '';
        } else {
          flag = 'red';
          value = value ? `${value.toFixed(2)} %` : '';
        }
      } else if (value.toString().includes('-')) {
        flag = 'red';
        value = value
          ? `${Number(value.toString().split('-')[1]).toFixed(2)} %`
          : '';
      } else {
        flag = 'green';
        value = value ? `${value.toFixed(2)} %` : '';
      }

      if (flag === 'red') {
        return (
          <>
            <span
              className={grayArrow ? 'decrease-rate grey' : 'decrease-rate'}
              style={{ marginLeft: '5px' }}
            >
              {' '}
              <img
                className="red-arrow"
                src={grayArrow ? UpDowGrayArrow : ArrowDownIcon}
                alt="arrow-up"
              />
              {value}
            </span>
          </>
        );
      }
      return (
        <>
          <span
            className={grayArrow ? 'increase-rate grey' : 'increase-rate'}
            style={{ marginLeft: '5px' }}
          >
            <img
              className="green-arrow"
              src={grayArrow ? UpDowGrayArrow : ArrowUpIcon}
              width="14px"
              alt="arrow-up"
            />
            {value}
          </span>
        </>
      );
    }
    if (value === null) {
      return <span className="small-value ml-3">N/A</span>;
    }
    return <span className="small-value ml-3">0%</span>;
  };

  const generateContractDetails = (item, child) => {
    return (
      <WhiteCard className="mt-2">
        {!child ? showCollapseButton(item?.id, item?.account_type) : ''}
        <div style={{ overflow: 'hidden' }}>
          {generateLogoCompanyNameAndGs(
            item,
            item && item.company_name,
            item && item.brand_growth_strategist,
          )}
        </div>
        <div className="clear-fix" />
        <div className=" straight-line horizontal-line pt-3" />
        <div className="row">
          <div className={`${child ? 'col-5' : 'col-5 col-md-6'} mt-3`}>
            <div className="label">Account type</div>
            <div className="label-info">
              {item?.customer_account_type || item?.account_type === 'parent'
                ? displayAccountStatus(
                    item?.account_type,
                    item?.customer_account_type,
                  )
                : ''}
              {Object.keys(item?.lwa).length
                ? returnTooltipMessgeLWA(item)
                : ''}
            </div>
          </div>
          <div className={`${child ? 'col-7' : 'col-7 col-md-6'} mt-3`}>
            <div className="label">Status</div>
            <div>{generateCompanyStatus(item?.status)}</div>
          </div>
        </div>
        <div className=" straight-line horizontal-line pt-3 mb-3 " />
        {showContractsList(item)}
      </WhiteCard>
    );
  };

  // display metrics data
  const displayMetricsData = (item, dashboard, column) => {
    const metricsUnit = metricsCurrency[column?.key]?.type;
    return (
      <>
        {generatePerformance(
          item?.[dashboard]?.current_sum?.[column?.value],
          2,
          'isTwiceReplace',
          metricsUnit === 'currency' ? '$' : '',
          metricsUnit === 'percentage' ? '%' : '',
        )}
        {renderAdPerformanceDifference(
          item?.[dashboard]?.difference_data?.[column?.value],
          false,
          [column?.value],
        )}
      </>
    );
  };

  // display metrics label with value
  const displayMetricsLabelWithValue = (
    item,
    dashboard,
    column,
    labelPrefix,
  ) => {
    return (
      <div className="col-6 pb-2">
        <div className="label">
          {`${labelPrefix ? `${labelPrefix}_` : ''}${column?.label}`}
        </div>
        <div className="label-info ">
          {displayMetricsData(item, dashboard, column)}
        </div>
      </div>
    );
  };

  const renderCustomerDetails = (item) => {
    const dashboard = showPerformance
      ? 'sales_performance'
      : showAdPerformance
      ? 'sponsored_ad_performance'
      : showDspAdPerformance
      ? 'dsp_ad_performance'
      : null;

    const dashboardSC = `${dashboard}_sc`;
    const dashboardVC = `${dashboard}_vc`;

    const isHybrid =
      selectedAccountTypes?.includes('Hybrid') ||
      selectedAccountTypes?.length === 0;

    if (dashboard !== null) {
      return (
        <WhiteCard className="mt-2">
          {showCollapseButton(item?.id, item?.account_type)}
          {generateLogoCompanyNameAndGs(
            item,
            item?.company_name,
            item?.brand_growth_strategist,
          )}
          <div className="clear-fix" />
          <div className=" straight-line horizontal-line pt-3 mb-3 " />

          <div className="row">
            {showAdPerformance
              ? (selectedAccountTypes?.includes('Seller') &&
                  selectedAccountTypes?.includes('Vendor')) ||
                isHybrid
                ? selectedColumns?.map((column) => {
                    return ['TACOS', 'Total AD Spend']?.includes(
                      column?.label,
                    ) ? (
                      displayMetricsLabelWithValue(
                        item,
                        dashboardSC,
                        column,
                        '',
                      )
                    ) : (
                      <>
                        {displayMetricsLabelWithValue(
                          item,
                          dashboardSC,
                          column,
                          'SC',
                        )}
                        {displayMetricsLabelWithValue(
                          item,
                          dashboardVC,
                          column,
                          'VC',
                        )}
                      </>
                    );
                  })
                : selectedAccountTypes?.includes('Seller') || isHybrid
                ? selectedColumns?.map((column) => {
                    return displayMetricsLabelWithValue(
                      item,
                      dashboardSC,
                      column,
                      'SC',
                    );
                  })
                : selectedAccountTypes?.includes('Vendor') || isHybrid
                ? selectedColumns?.map((column) => {
                    return displayMetricsLabelWithValue(
                      item,
                      dashboardVC,
                      column,
                      'VC',
                    );
                  })
                : null
              : selectedColumns?.map((column) => {
                  return displayMetricsLabelWithValue(
                    item,
                    dashboard,
                    column,
                    '',
                  );
                })}
          </div>
        </WhiteCard>
      );
    }

    // for- view contract details
    if (showContractDetails) {
      return <>{generateContractDetails(item)}</>;
    }
    return <>{generateContractDetails(item)}</>;
  };

  // Rendering child brands
  const renderChildContractHtml = (parentCustomer) => {
    const child = true;
    return (
      <Collapse isOpened={showChildContracts.id === parentCustomer?.id}>
        <div className="row">
          {parentCustomer?.child_customer?.map((childBrand) => (
            <div
              key={childBrand.id}
              className="col-12 col-md-6 mb-2"
              onClick={() =>
                history.push(
                  `${PATH_CUSTOMER_DETAILS.replace(
                    ':id',
                    childBrand?.id,
                  )}?tab=performance${
                    childBrand?.customer_account_type
                      ? `&sub=${
                          childBrand.customer_account_type === 'Hybrid' ||
                          childBrand.customer_account_type === 'Seller'
                            ? 'seller'
                            : 'vendor'
                        }`
                      : ''
                  }&nav=${'salePerformance'}`,
                  history?.location?.state,
                )
              }
              role="presentation"
            >
              {showPerformance || showAdPerformance || showDspAdPerformance
                ? renderCustomerDetails(childBrand, child)
                : generateContractDetails(childBrand, child)}
            </div>
          ))}
        </div>
      </Collapse>
    );
  };

  return (
    <CustomerListTabletView
      showPerformance={
        !!(showPerformance || showAdPerformance || showDspAdPerformance)
      }
    >
      {isLoading.loader && isLoading.type === 'page' ? (
        <PageLoader color="#FF5933" type="page" />
      ) : data?.length === 0 ? (
        <NoRecordFound
          title="We looked high and low, but…"
          subTitle="No records found."
        />
      ) : (
        <div className="container-fluid">
          <div className="row cursor">
            {data?.map((item) => (
              <>
                <div
                  key={item?.id}
                  className="col-12"
                  onClick={() => {
                    redirectToCustomerDetailView(item);
                  }}
                  role="presentation"
                >
                  {renderCustomerDetails(item)}
                </div>
                <div className="col-12" style={{ marginTop: '8px' }}>
                  {renderChildContractHtml(item)}
                </div>
              </>
            ))}
          </div>

          {isLoading.loader && isLoading.type === 'page' ? (
            <PageLoader color="#FF5933" type="page" />
          ) : (
            <CommonPagination
              count={count}
              pageNumber={pageNumber}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
      )}
    </CustomerListTabletView>
  );
}

CustomerListTablet.defaultProps = {
  count: null,
  pageNumber: 1,
  handlePageChange: () => {},
  showContractDetails: true,
  showPerformance: false,
  showAdPerformance: false,
  showDspAdPerformance: false,
  selectedColumns: [],
  selectedAccountTypes: [],
  showCollapseButton: () => {},
  showChildContracts: {},
  displayAccountStatus: () => {},
  returnTooltipMessgeLWA: () => {},
  redirectToCustomerDetailView: () => {},
};

CustomerListTablet.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.string,
    }),
  }).isRequired,
  count: PropTypes.number,
  pageNumber: PropTypes.number,
  handlePageChange: PropTypes.func,
  isLoading: PropTypes.shape({
    loader: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
  showContractDetails: PropTypes.bool,
  showPerformance: PropTypes.bool,
  showAdPerformance: PropTypes.bool,
  showDspAdPerformance: PropTypes.bool,
  selectedAccountTypes: PropTypes.arrayOf(shape({})),
  generateLogoCompanyNameAndGs: PropTypes.func.isRequired,
  generateCompanyStatus: PropTypes.func.isRequired,
  generatePerformance: PropTypes.func.isRequired,
  showContractsList: PropTypes.func.isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.shape({})),
  showCollapseButton: func,
  showChildContracts: shape({}),
  displayAccountStatus: func,
  returnTooltipMessgeLWA: func,
  redirectToCustomerDetailView: func,
};
