import React, { useEffect, useState, useCallback } from 'react';

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { arrayOf, shape, string } from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import Theme from '../../../../../theme/Theme';
import InvoiceAdjustmentList from './InvoiceAdjustmentList';
import { getDSPContact } from '../../../../../api';
import { InvoiceAdjustPauseModal } from './InvoiceAdjustmentModals';
import { WhiteCard, Button, PageLoader } from '../../../../../common';
import {
  SpreetailRole,
  SpreetailRoleRestrictMessage,
  dspSignOffRoles,
} from '../../../../../constants';

const InvoiceAdjustmentsContainer = ({ id, bpName, memberData }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const userInfo = useSelector((state) => state.userState.userInfo);
  const customerState = useSelector((state) => state.customerState.data);
  const isSpreetailUser = userInfo?.role === SpreetailRole;

  const [showInvoiceAdjustmentModal, setShowInvoiceAdjustmentModal] =
    useState(false);
  const [isAllowToCreateAdjustment, setIsAllowToCreateAdjustment] =
    useState(false);
  const [adjustmentListApi, setAdjustmentListApi] = useState(false);
  const [dspButtonLoader, setDSPButtonLoader] = useState(false);

  // ***********PLEASE NOT REMOVE THIS COMMENTED CODE***********
  // const getDSPContactInfo = useCallback(() => {
  //   if (dspSignOffRoles.userRole[userInfo?.role]) {
  //     setIsAllowToCreateAdjustment(true);
  //   } else {
  //     for (const user of memberData) {
  //       if (user.user) {
  //         if (
  //           dspSignOffRoles.grpRole[user?.role_group?.name] &&
  //           user?.user?.id === userInfo?.id
  //         ) {
  //           setIsAllowToCreateAdjustment(true);
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }, [memberData, userInfo]);

  const getDSPContactInfo = useCallback(() => {
    setDSPButtonLoader(true);
    getDSPContact(id).then((res) => {
      if (res?.status === 200) {
        setDSPButtonLoader(false);
        if (res?.data?.allow_dsp_invoice_creation) {
          for (const user of memberData) {
            if (user.user) {
              if (
                (dspSignOffRoles.grpRole[user?.role_group?.name] &&
                  user?.user?.id === userInfo?.id) ||
                dspSignOffRoles.userRole[userInfo?.role]
              ) {
                setIsAllowToCreateAdjustment(true);
                break;
              }
            }
          }
        } else {
          setDSPButtonLoader(false);
          setIsAllowToCreateAdjustment(false);
        }
      } else {
        setDSPButtonLoader(false);
        setIsAllowToCreateAdjustment(false);
      }
    });
  }, [id, memberData, userInfo]);

  useEffect(() => {
    getDSPContactInfo();
  }, [getDSPContactInfo]);

  return (
    <Wrapper>
      {!isMobile ? (
        <WhiteCard className="mb-3">
          <div className="row mb-3">
            <div className="col-5">
              <p
                style={{ marginTop: '0px' }}
                className="black-heading-title mb-0 mt-3"
              >
                Invoice Requests
              </p>
            </div>
            {customerState?.account_type !== 'parent' ? (
              <div className="col-7  text-right">
                <Button
                  data-tip={SpreetailRoleRestrictMessage}
                  data-for={isSpreetailUser ? 'spreetailRetrictDspBudget' : ''}
                  disabled={!isAllowToCreateAdjustment}
                  onClick={() => {
                    setShowInvoiceAdjustmentModal(true);
                    setAdjustmentListApi(false);
                  }}
                  type="button"
                  className="btn-primary invoice-adjustment"
                >
                  {dspButtonLoader ? (
                    <PageLoader color={Theme.white} type="button" />
                  ) : (
                    'Submit Invoice Request'
                  )}
                </Button>
              </div>
            ) : null}
            <ReactTooltip
              id="spreetailRetrictDspBudget"
              aria-haspopup="true"
              place="top"
              effect="solid"
              html
            />
          </div>

          <InvoiceAdjustmentList
            customerId={id}
            isAllowToCreateAdjustment={isAllowToCreateAdjustment}
            invokeAdjustmentListApi={adjustmentListApi}
          />

          <div className="straight-line horizontal-line spacing " />
        </WhiteCard>
      ) : (
        <>
          <div className="row mb-3">
            <div className="col-5">
              <p
                style={{ marginTop: '0px' }}
                className="black-heading-title mb-0 mt-3"
              >
                Invoice Requests
              </p>
            </div>
            {customerState?.account_type !== 'parent' ? (
              <div className="col-7  text-right">
                <Button
                  data-tip={SpreetailRoleRestrictMessage}
                  data-for={
                    isSpreetailUser ? 'spreetailRetrictDspBudgetMobile' : ''
                  }
                  disabled={!isAllowToCreateAdjustment}
                  onClick={() => {
                    setShowInvoiceAdjustmentModal(true);
                    setAdjustmentListApi(false);
                  }}
                  type="button"
                  className="btn-primary invoice-adjustment"
                >
                  {dspButtonLoader ? (
                    <PageLoader color={Theme.white} type="button" />
                  ) : (
                    'Submit Invoice Request'
                  )}
                </Button>
              </div>
            ) : null}
            <ReactTooltip
              id="spreetailRetrictDspBudgetMobile"
              aria-haspopup="true"
              place="top"
              effect="solid"
              html
            />
          </div>
          <InvoiceAdjustmentList
            invokeAdjustmentListApi={adjustmentListApi}
            customerId={id}
            isAllowToCreateAdjustment={isAllowToCreateAdjustment}
          />
        </>
      )}

      {showInvoiceAdjustmentModal && (
        <InvoiceAdjustPauseModal
          id="BT-invoiceAdjustmentModal"
          customerId={id}
          isOpen={showInvoiceAdjustmentModal}
          onModalClose={() => {
            setShowInvoiceAdjustmentModal(false);
          }}
          onApply={(apply = false) => {
            setAdjustmentListApi(apply);
            setShowInvoiceAdjustmentModal(false);
          }}
          bpName={bpName}
        />
      )}
    </Wrapper>
  );
};

export default InvoiceAdjustmentsContainer;

InvoiceAdjustmentsContainer.defaultProps = {
  id: '',
  bpName: '',
};

InvoiceAdjustmentsContainer.propTypes = {
  id: string,
  bpName: string,
  memberData: arrayOf(shape({})).isRequired,
};

const Wrapper = styled.div`
  td {
    padding: 20px 10px 3px 0px !important;
  }
  .statusContainer {
    margin-top: 0px !important;
  }
`;
