import React from 'react';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, func, string } from 'prop-types';

import Theme from '../theme/Theme';

const Status = ({
  label,
  className,
  backgroundColor,
  pointColor,
  labelColor,
  icon,
  marginBottom,
  marginTop,
  width,
  iconType,
  fontIcon,
  type,
  cursor,
  handleOnClick,
  iconAfterLabel = false,
}) => {
  return (
    <StatusWrapper
      pointColor={pointColor}
      style={{ backgroundColor, marginTop, marginBottom, width, cursor }}
      className={['status_container', className]}
      onClick={() => handleOnClick()}
    >
      {pointColor ? (
        <div
          style={{ backgroundColor: pointColor }}
          className="status_symbol"
        />
      ) : icon ? (
        iconType ? (
          <>
            <img className="orange-clock-icon" src={iconType} alt={iconType} />
          </>
        ) : fontIcon ? (
          <>
            <FontAwesomeIcon
              icon={fontIcon}
              color={labelColor}
              className="mr-2"
            />
          </>
        ) : (
          ''
        )
      ) : null}
      <span
        style={{ color: labelColor }}
        className={
          type === 'agreement' ? 'status_text' : 'status_text status-bold'
        }
      >
        {label ? label?.charAt(0)?.toUpperCase() + label?.slice(1) : ''}
        {'  '}
        {iconAfterLabel ? (
          <FontAwesomeIcon icon={fontIcon} color={labelColor} />
        ) : null}
      </span>
    </StatusWrapper>
  );
};

export default Status;

const StatusWrapper = styled.div`
  float: left;
  border-radius: 5px;
  width: fit-content;
  min-width: 70px;
  padding: 5px 10px;
  margin-top: 8px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .status_symbol {
    border-radius: 100%;
    width: 8px;
    height: 8px;
    z-index: 20 !important;
  }

  &.capluse-status {
    border-radius: 12px;
    float: initial;
    font-size: ${Theme.extraSmall};
    color: ${Theme.gray80};
    .status_text {
      font-size: ${Theme.extraSmall} !important;
    }
  }
  span.status_text {
    margin-left: ${(props) => (props.pointColor ? 8 : 0)}px;
    font-size: ${Theme.extraNormal};
    font-weight: 500;
    text-align: center;
  }
  .orange-clock-icon {
    width: 12px;
    margin-right: 7px;
  }
  .status-bold {
    text-transform: uppercase;
    font-size: ${Theme.verySmall} !important;
  }
`;

Status.defaultProps = {
  iconAfterLabel: false,
  backgroundColor: Theme.lightYellow,
  className: '',
  labelColor: Theme.black,
  pointColor: '',
  icon: '',
  label: '',
  marginBottom: '',
  marginTop: '',
  width: '',
  iconType: '',
  fontIcon: '',
  type: '',
  cursor: 'default',
  handleOnClick: () => {},
};

Status.propTypes = {
  iconAfterLabel: bool,
  label: string,
  backgroundColor: string,
  pointColor: string,
  className: string,
  labelColor: string,
  icon: string,
  marginBottom: string,
  marginTop: string,
  width: string,
  iconType: string,
  fontIcon: string,
  type: string,
  cursor: string,
  handleOnClick: func,
};
