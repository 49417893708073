import React from 'react';

import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InventoryScoreGraph from '../../../Customer/CustomerDetailView/CompanyPerformance/SalesPerformance/SellerReporting/InventoryScoreGraph';
import useFormatNumber from '../../../../hooks/useFormatNumber';
import Theme from '../../../../theme/Theme';
import PdfViewer from '../../../../common/PdfViewer';
import SaleAdDspPerformanceGraph from './SaleAdDspPerformanceGraph';
import BbPercentChart from '../../../Customer/CustomerDetailView/CompanyPerformance/SalesPerformance/SellerReporting/BbPercentChart';
import {
  DesktopTable,
  GreyCard,
  NoData,
  ReportTextEditor,
  WhiteCard,
} from '../../../../common';
import { MainBodyReports, ReportPDFContainer } from './CreateReportStyle';
import ReportPDFWithHperlink from './ReportPDFWithHperlink';
import {
  childAsinTableFields,
  currencyAxis,
  customReportGraphColorSet,
  customReportGraphTooltipNames,
  dataScrappedMsg,
  overstockInventoryTableFields,
  parentAsinTableFields,
  percentageAxis,
  strandedInventoryTableFields,
  understockInventoryTableFields,
} from '../../../../constants';

const ReportLeftSidePanel = ({
  graphDetails,
  setGraphDetails,
  commanFieldsDetails,
  graphApiResponseData,
  reportStatus,
  customerName,
  dateToShow,
  reportSummary,
  html2pdfRef,
  currentEndDate,
  showReportPDFTemplate,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const accountType = commanFieldsDetails?.accountType?.value;
  // report pdf header
  const pdfHeader = {
    customerName,
    accountType,
    marketplace: commanFieldsDetails?.selectedMarketplace,
    dateRange: `From - ${dayjs(dateToShow?.startDate).format(
      'MMM D, YYYY',
    )} To - ${dayjs(dateToShow?.endDate).format('MMM D, YYYY')}`,
    reportSummary,
    pdfName: `${customerName} ${commanFieldsDetails?.reportTitle} ${dayjs(
      new Date(),
    ).format('DD MMM YYYY')}.pdf`.replaceAll(' ', '_'),
  };

  const executeScroll = (eleId) => {
    const element = document.getElementById(eleId);
    const offset = isDesktop ? -150 : isMobile ? -220 : -220;
    const y =
      element?.getBoundingClientRect()?.top + window.pageYOffset + offset;
    window.scrollTo({ top: y });
  };

  const handleGraphDetailsOnChange = (
    graphIndex,
    keyName,
    fieldName,
    value,
  ) => {
    const newGraph = JSON.parse(JSON.stringify(graphDetails));
    newGraph[graphIndex][fieldName][keyName] = value;
    if (newGraph[graphIndex][fieldName][keyName] !== undefined) {
      setGraphDetails(newGraph);
    }
  };

  const renderEditComponent = (
    _graphData,
    graphIndex,
    fieldName,
    keyName,
    placeholder,
    isCharLimit,
  ) => {
    // let data = graphData[fieldName][keyName];
    let data =
      commanFieldsDetails?.editorDetails[graphIndex][fieldName][keyName];

    // setData is used to set the editor content in variable. This method called on_state_change of edior.
    // we are storing that value in a tempary varibale (editorDetails).
    // and when user click on procced button it will be store on orignal object (graphDetails).
    // so that orignal varible not update on every key stroke and graph will not be refresh.

    return (
      <div
        id={`${fieldName}${keyName}${graphIndex}`}
        className={showReportPDFTemplate ? 'pb-0' : 'pb-5'}
      >
        <ReportTextEditor
          id={`${fieldName}${keyName}${graphIndex}`}
          setData={(value) => {
            data = value;
            const newEditorObject = JSON.parse(
              JSON.stringify(commanFieldsDetails?.editorDetails),
            );
            newEditorObject[graphIndex][fieldName][keyName] = value;
            if (newEditorObject[graphIndex][fieldName][keyName] !== undefined) {
              commanFieldsDetails.setEditorDetails(newEditorObject);
            }
          }}
          onFocusOut={(value) => {
            handleGraphDetailsOnChange(graphIndex, keyName, fieldName, value);
          }}
          data={data}
          placeholder={showReportPDFTemplate ? ' ' : placeholder}
          editorFor="customisedReport"
          editorClassName="report-pdf-editor"
          isCharLimit={isCharLimit}
        />
      </div>
    );
  };

  // calculate past and future months
  const getYesterdayOfCurrentDate = (date) => {
    date.setDate(date?.getDate() - 1);

    return date;
  };

  // metrics value change style
  const renderMetricsValueChangeStyle = (key, value) => {
    const styleObj = { class: 'range mt-1', color: Theme.black };
    if (key[value] == null) {
      return styleObj;
    }
    if (key[value] >= 0) {
      styleObj.class = 'range  increase-rate mt-1';
      styleObj.color = Theme.green;
      return styleObj;
    }
    styleObj.class = 'range  decrease-rate mt-1';
    styleObj.color = Theme.red;
    return styleObj;
  };

  // render asins metrics value
  const renderMetricsValueWithChange = (
    dataItem,
    value,
    valueChange,
    percentChange,
    changeType,
    formatNumber,
    showDecimalPoint,
    prefix,
    postfix,
    showValue = true,
    isMetricsTable = false,
  ) => {
    const itemValue = showDecimalPoint
      ? formatNumber(value, prefix, postfix, false, false, 2)
      : formatNumber(value, prefix, postfix);

    const changeInValue = showDecimalPoint
      ? formatNumber(Math.abs(valueChange), prefix, postfix, false, false, 2)
      : formatNumber(Math.abs(valueChange), prefix, postfix);

    const changeInPercentage = formatNumber(Math.abs(percentChange), '', '%');
    const showChangeInValueAndPercent = (
      <>
        {changeInValue}({changeInPercentage})
      </>
    );
    const showMetricsTableChangeInValueAndPercent = (
      <>
        {changeInValue}&nbsp;
        {showReportPDFTemplate ? (
          <div>{`(${changeInPercentage})`}</div>
        ) : (
          `(${changeInPercentage})`
        )}
      </>
    );

    return (
      <>
        {showValue ? (
          <div className="mr-2">{value == null ? 'N/A' : itemValue}</div>
        ) : null}
        {commanFieldsDetails.selectedTimeFrame.value !== 'custom' ? (
          <div
            className={`${
              renderMetricsValueChangeStyle(dataItem, changeType).class
            } `}
          >
            {changeInValue !== 'N/A' && changeInPercentage !== 'N/A' ? (
              <>
                <FontAwesomeIcon
                  style={{
                    marginRight: '4px',
                  }}
                  fontSize="12px"
                  color={
                    renderMetricsValueChangeStyle(dataItem, changeType).color
                  }
                  icon={`fa-light ${
                    valueChange < 0 ? 'fa-arrow-down' : 'fa-arrow-up'
                  }`}
                />
                {isMetricsTable
                  ? showMetricsTableChangeInValueAndPercent
                  : showChangeInValueAndPercent}
              </>
            ) : (
              'N/A'
            )}
          </div>
        ) : null}
      </>
    );
  };

  // get modified performance graph data
  const getLineGraphDataWithDateRange = (graphData) => {
    const graphDataNewLength = graphData?.length;
    const tempGraphData = [];
    const lastIndex = graphDataNewLength - 1;

    if (graphDataNewLength > 0) {
      graphData.map((item, index) => {
        let yesterday = null;
        const startDate = item?.date
          ? dayjs(new Date(item?.date)).format('MMM DD, YYYY')
          : null;

        if (index < lastIndex) {
          const getDate = graphData?.at(index + 1)?.date;
          if (getDate) {
            yesterday = dayjs(
              getYesterdayOfCurrentDate(new Date(getDate)),
            ).format('MMM DD, YYYY');
          } else {
            yesterday = dayjs(new Date(currentEndDate)).format('MMM DD, YYYY');
          }
        } else if (index === lastIndex) {
          const getDate = graphData?.at(lastIndex)?.date;
          if (getDate) {
            yesterday = dayjs(new Date(currentEndDate)).format('MMM DD, YYYY');
          } else {
            yesterday = startDate;
          }
        }

        tempGraphData.push({
          ...item,
          dateRange: {
            startDate,
            endDate: yesterday,
          },
        });

        return tempGraphData;
      });
    }

    return tempGraphData;
  };

  // render line graph
  const renderSalesAdDspGraph = (
    selectedFieldsData,
    graphIndex,
    metricsData,
    fieldName,
  ) => {
    const selectedMetricCount = Object.keys(
      selectedFieldsData?.[fieldName]?.selectedMetrics,
    );

    const dateFrequency =
      graphDetails?.[graphIndex]?.[fieldName]?.graphFrequency;

    return (
      <div
        key={`${fieldName}${graphIndex}`}
        className="pb-2"
        id={`${fieldName}${graphIndex}`}
      >
        {selectedMetricCount.length && metricsData?.length ? (
          <>
            <WhiteCard className="mb-3">
              <ul className="rechart-item">
                <li>
                  <div className="weeks">
                    <span
                      className={
                        selectedMetricCount.length === 1
                          ? `adSales circle`
                          : 'darkGray circle'
                      }
                    />
                    <span>Recent</span>
                  </div>
                </li>
                {selectedMetricCount.length <= 2 &&
                commanFieldsDetails?.selectedTimeFrame?.value !== 'custom' &&
                selectedFieldsData[fieldName]?.showPreviousDataPoint ? (
                  <li>
                    <div className="weeks">
                      <ul className="dashed-line">
                        <li
                          className={
                            selectedMetricCount.length === 1
                              ? `adSales block `
                              : 'darkGray block '
                          }
                        />
                        <li
                          className={
                            selectedMetricCount.length === 1
                              ? `adSales block  `
                              : 'darkGray block '
                          }
                        />
                      </ul>

                      <span>Previous</span>
                    </div>
                  </li>
                ) : null}
              </ul>
            </WhiteCard>
            <SaleAdDspPerformanceGraph
              chartId={`${fieldName}${graphIndex}chart`}
              chartData={metricsData}
              currencySymbol={commanFieldsDetails.marketplaceCurrency}
              selectedMetrics={selectedFieldsData[fieldName]?.selectedMetrics}
              selectedDF={commanFieldsDetails?.selectedTimeFrame?.value}
              showPrevoisPoint={
                selectedFieldsData[fieldName]?.showPreviousDataPoint
              }
              dateFrequency={dateFrequency}
              currentEndDate={currentEndDate}
            />

            <WhiteCard>
              <ul className="rechart-item">
                {selectedMetricCount.map((option, index) => {
                  return (
                    <li key={option}>
                      <div className="weeks">
                        <span
                          className="circle"
                          style={{
                            background: customReportGraphColorSet[index],
                          }}
                        />
                        <span>{customReportGraphTooltipNames[option]}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </WhiteCard>
          </>
        ) : showReportPDFTemplate ? null : (
          <div className="graph-flied mb-5">
            {selectedMetricCount.length
              ? 'Data not available'
              : 'Add Graph here'}
          </div>
        )}
      </div>
    );
  };

  const renderMetricsTable = (
    graphData,
    indexNumber,
    fieldName,
    showTableFor,
    showTotalFor,
    formatNumber,
  ) => {
    let numberOfTableColumn = 0;
    let calculatedWidth = 0;
    const headerContent = [];
    const TotalHeaderContent = [];
    const tableRowContainer = [];
    const selectedMetrics = Object.keys(graphData[fieldName].selectedMetrics);
    let tableData = [];
    let tableTotalData = [];
    if (graphApiResponseData && graphApiResponseData.length) {
      tableData = getLineGraphDataWithDateRange(
        graphApiResponseData[indexNumber][fieldName][showTableFor],
      );
      tableTotalData =
        graphApiResponseData[indexNumber][fieldName][showTotalFor];
    }

    numberOfTableColumn = selectedMetrics.length;
    if (graphData[fieldName].showPreviousDataPoint) {
      numberOfTableColumn *= 3;
    }
    calculatedWidth = 100 / (numberOfTableColumn + 1);

    const columnHeaders = ['date'];
    if (graphData[fieldName].showPreviousDataPoint) {
      for (const option of selectedMetrics) {
        columnHeaders.push(`${option}Current`);
        columnHeaders.push(`${option}Previous`);
        columnHeaders.push(`${option}Comparison`);
      }
    } else {
      for (const option of selectedMetrics) {
        columnHeaders.push(`${option}Current`);
      }
    }

    for (let i = 0; i < selectedMetrics.length; i += 1) {
      if (!i) {
        headerContent.push(
          <th width={`${calculatedWidth}%`} key={`${showTableFor}${i}`}>
            date
          </th>,
        );
        TotalHeaderContent.push(
          <td
            width={`${calculatedWidth}%`}
            key={`${showTableFor}${i}Total`}
            className="label-info-dark label-info"
          >
            Total
          </td>,
        );
      }
      headerContent.push(
        <th width={`${calculatedWidth}%`} key={`${showTableFor}${i}current`}>
          {`${customReportGraphTooltipNames[selectedMetrics[i]]} Current`}
        </th>,
      );
      TotalHeaderContent.push(
        <td
          width={`${calculatedWidth}%`}
          key={`${showTableFor}${i}current`}
          style={{ color: customReportGraphColorSet[i] }}
        >
          {formatNumber(
            tableTotalData[`${selectedMetrics[i]}Current`],
            currencyAxis.includes(selectedMetrics[i])
              ? commanFieldsDetails?.marketplaceCurrency
              : '',
            percentageAxis.includes(selectedMetrics[i]) ? '%' : '',
            false,
            false,
            2,
          )}
        </td>,
      );

      if (graphData[fieldName].showPreviousDataPoint) {
        headerContent.push(
          <>
            <th
              width={`${calculatedWidth}%`}
              key={`${showTableFor}${i}previous`}
            >
              {`${customReportGraphTooltipNames[selectedMetrics[i]]} Previous`}
            </th>
            <th
              width={`${calculatedWidth}%`}
              key={`${showTableFor}${i}comparison`}
            >
              {`${
                customReportGraphTooltipNames[selectedMetrics[i]]
              } Comparison`}
            </th>
          </>,
        );

        TotalHeaderContent.push(
          <>
            <td
              width={`${calculatedWidth}%`}
              key={`${showTableFor}${i}previousTotal`}
              style={{ color: customReportGraphColorSet[i] }}
            >
              {formatNumber(
                tableTotalData[`${selectedMetrics[i]}Previous`],
                currencyAxis.includes(selectedMetrics[i])
                  ? commanFieldsDetails?.marketplaceCurrency
                  : '',
                percentageAxis.includes(selectedMetrics[i]) ? '%' : '',
                false,
                false,
                2,
              )}
            </td>
            <td
              width={`${calculatedWidth}%`}
              key={`${showTableFor}${i}comparisonTotal`}
              style={{ color: customReportGraphColorSet[i] }}
            >
              {renderMetricsValueWithChange(
                tableTotalData,
                null,
                tableTotalData[`${selectedMetrics[i]}ChangeInValue`],
                tableTotalData[`${selectedMetrics[i]}ChangeInPercent`],
                `${selectedMetrics[i]}ChangeInValue`,
                formatNumber,
                true,
                currencyAxis.includes(selectedMetrics[i])
                  ? commanFieldsDetails?.marketplaceCurrency
                  : '',
                percentageAxis.includes(selectedMetrics[i]) ? '%' : '',
                false,
                true,
              )}
            </td>
          </>,
        );
      }
    }
    const dateFrequency =
      graphDetails?.[indexNumber]?.[fieldName]?.graphFrequency;
    // bind the <td>
    if (tableData && tableData?.length) {
      tableData.forEach((element) => {
        const tableDataContainer = [];
        for (let i = 0; i < selectedMetrics.length; i += 1) {
          if (element?.date) {
            if (!i) {
              tableDataContainer.push(
                dateFrequency === 'weekly' ? (
                  <td key={`td${showTableFor}`}>
                    {element?.dateRange?.startDate} -{' '}
                    {element?.dateRange?.endDate}
                  </td>
                ) : (
                  <td key={`td${showTableFor}`}>{element.date}</td>
                ),
              );
            }

            tableDataContainer.push(
              <td
                key={`td${showTableFor}${i}current`}
                style={{ color: customReportGraphColorSet[i] }}
              >
                {formatNumber(
                  element[`${selectedMetrics[i]}CurrentLabel`],
                  currencyAxis.includes(selectedMetrics[i])
                    ? commanFieldsDetails?.marketplaceCurrency
                    : '',
                  percentageAxis.includes(selectedMetrics[i]) ? '%' : '',
                  false,
                  false,
                  2,
                )}
              </td>,
            );
            if (graphData[fieldName].showPreviousDataPoint) {
              tableDataContainer.push(
                <>
                  <td
                    key={`td${showTableFor}${i}previous`}
                    style={{ color: customReportGraphColorSet[i] }}
                  >
                    {formatNumber(
                      element[`${selectedMetrics[i]}Previous`],
                      currencyAxis.includes(selectedMetrics[i])
                        ? commanFieldsDetails?.marketplaceCurrency
                        : '',
                      percentageAxis.includes(selectedMetrics[i]) ? '%' : '',
                      false,
                      false,
                      2,
                    )}
                  </td>
                  <td
                    key={`td${showTableFor}${i}comparison`}
                    style={{ color: customReportGraphColorSet[i] }}
                  >
                    {renderMetricsValueWithChange(
                      element,
                      null,
                      element[`${selectedMetrics[i]}ChangeInValue`],
                      element[`${selectedMetrics[i]}ChangeInPercent`],
                      `${selectedMetrics[i]}ChangeInValue`,
                      formatNumber,
                      true,
                      currencyAxis.includes(selectedMetrics[i])
                        ? commanFieldsDetails?.marketplaceCurrency
                        : '',
                      percentageAxis.includes(selectedMetrics[i]) ? '%' : '',
                      false,
                      true,
                    )}
                  </td>
                </>,
              );
            }
          }
        }
        tableRowContainer.push(tableDataContainer);
      });
    }

    return (
      <>
        <h4 className="mt-3 graph-title text-bold">Metrics Table</h4>
        <div id={`${fieldName}Metrics${indexNumber}`} className="pb-1">
          <DesktopTable
            className="mt-2 metrics-table"
            style={{
              border: '1px solid #e0e6e8',
              borderBottom: 'none',
              borderSpacing: showReportPDFTemplate ? 'revert' : 0,
            }}
          >
            <thead>
              <tr>{headerContent}</tr>
            </thead>
            {graphData[fieldName]?.graphDataTableView === 'detailedView' ? (
              <tbody>
                {tableRowContainer?.length !== 0 &&
                  tableRowContainer?.map((option) => {
                    return option?.length ? (
                      <tr key={Math.random()}>{option}</tr>
                    ) : null;
                  })}
              </tbody>
            ) : null}
          </DesktopTable>
          <DesktopTable
            className="mb-3 metrics-table-total"
            style={{
              border: '1px solid #e0e6e8',
              borderBottom: 'none',
              borderTop: 'none',
              borderSpacing: showReportPDFTemplate ? 'revert' : 0,
            }}
          >
            <tbody>
              <tr>{TotalHeaderContent}</tr>
            </tbody>
          </DesktopTable>
          {tableRowContainer?.length === 0 ? (
            <NoData>Metrics Data Not Found</NoData>
          ) : null}
        </div>
      </>
    );
  };

  // render parent child asins table html
  const renderParentChildAsinsTable = (
    indexNumber,
    fieldName,
    showTableFor,
    tableHeading,
    formatNumber,
    graphData = null,
  ) => {
    const headerContent = [];
    let tableData = [];
    if (graphApiResponseData && graphApiResponseData.length) {
      tableData =
        graphApiResponseData[indexNumber]?.sellerDetails[showTableFor];
    }

    const showParentASINsTable = [
      'topParentAsins',
      'bottomParentAsins',
      'allParentAsins',
    ].includes(showTableFor);
    const showChildASINsTable = [
      'topChildAsins',
      'bottomChildAsins',
      'allChildAsins',
    ].includes(showTableFor);

    if (showParentASINsTable) {
      headerContent.push(
        parentAsinTableFields?.map((option) => {
          return (
            <th width={option.width} key={Math.random()}>
              {option.name}
            </th>
          );
        }),
      );
    }

    if (showChildASINsTable) {
      headerContent.push(
        childAsinTableFields?.map((option) => {
          return (
            <th width={option.width} key={Math.random()}>
              {option.name}
            </th>
          );
        }),
      );
    }

    return (
      <div id={`${fieldName}${showTableFor}${indexNumber}`} className="pb-1">
        <h4 className="mt-2 graph-title text-bold">{tableHeading}</h4>

        <DesktopTable
          id={`${fieldName}${showTableFor}${indexNumber}table`}
          className="mt-2 mb-3"
          style={{
            border: '1px solid #e0e6e8',
            borderBottom: 'none',
            borderSpacing: showReportPDFTemplate ? 'revert' : 0,
          }}
        >
          <thead>
            <tr>{headerContent}</tr>
          </thead>
          <tbody>
            {tableData && tableData?.length !== 0
              ? tableData?.map((item) => {
                  return (
                    <React.Fragment key={item?.asin}>
                      <tr
                        key={item?.asin}
                        className="text-medium-font report-child-asin"
                      >
                        <td
                          className={
                            showChildASINsTable &&
                            graphData?.sellerDetails?.showChildProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          {item?.asin}
                        </td>
                        <td
                          className={
                            showChildASINsTable &&
                            graphData?.sellerDetails?.showChildProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          {renderMetricsValueWithChange(
                            item,
                            item?.current_revenue,
                            item?.revenue_change,
                            item?.revenue_percent_change,
                            'revenue_change',
                            formatNumber,
                            true,
                            commanFieldsDetails?.marketplaceCurrency,
                            '',
                          )}
                        </td>
                        <td
                          className={
                            showChildASINsTable &&
                            graphData?.sellerDetails?.showChildProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          {renderMetricsValueWithChange(
                            item,
                            item?.current_traffic,
                            item?.traffic_change,
                            item?.traffic_percent_change,
                            'traffic_change',
                            formatNumber,
                            true,
                            '',
                            '',
                          )}
                        </td>
                        <td
                          className={
                            showChildASINsTable &&
                            graphData?.sellerDetails?.showChildProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          {renderMetricsValueWithChange(
                            item,
                            item?.current_units_sold,
                            item?.units_sold_change,
                            item?.units_sold_percent_change,
                            'units_sold_change',
                            formatNumber,
                            false,
                            '',
                            '',
                          )}
                        </td>
                        <td
                          className={
                            showChildASINsTable &&
                            graphData?.sellerDetails?.showChildProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          {renderMetricsValueWithChange(
                            item,
                            item.current_conversion,
                            item?.conversion_change,
                            item?.conversion_percent_change,
                            'conversion_change',
                            formatNumber,
                            true,
                            '',
                            '%',
                          )}
                        </td>
                        <td
                          className={
                            showChildASINsTable &&
                            graphData?.sellerDetails?.showChildProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          {renderMetricsValueWithChange(
                            item,
                            item?.current_buy_box_percentage,
                            item?.buy_box_change,
                            item?.buy_box_percentage_change,
                            'buy_box_change',
                            formatNumber,
                            true,
                            '',
                            '%',
                          )}
                        </td>
                      </tr>

                      {showChildASINsTable &&
                      graphData?.sellerDetails?.showChildProductName ? (
                        <tr className="child-asin-text">
                          <td className="pt-0" colSpan="7">
                            <GreyCard className="mr-3">
                              {item?.product_name}
                            </GreyCard>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })
              : null}
          </tbody>
        </DesktopTable>

        {tableData?.length === 0 ? (
          <NoData>{tableHeading} Data Not Found</NoData>
        ) : null}
      </div>
    );
  };

  const renderBBPgraph = (indexNumber) => {
    return (
      <div>
        <h4 className="mt-2 graph-title text-bold">Buy Box %</h4>
        <WhiteCard>
          <ul className="rechart-item bbpercent-graph-label">
            <li>
              <div className="weeks">
                <span className="black label-block" />
                <span>Daily %</span>
              </div>
            </li>
            <li>
              <div className="weeks">
                <span className="gray label-block" />
                <span> Average</span>
              </div>
            </li>
          </ul>
        </WhiteCard>
        <BbPercentChart
          chartId={`BbPercentchartReport${indexNumber}chart`}
          chartData={graphApiResponseData[indexNumber]?.sellerDetails?.bbepData}
        />
      </div>
    );
  };

  const renderBBEPTable = (
    graphData,
    indexNumber,
    fieldName,
    showTableFor,
    formatNumber,
  ) => {
    const headerContent = [];
    let tableData = '';

    headerContent.push(<th width="50%">Date</th>);
    headerContent.push(<th width="50%">Value</th>);
    tableData = graphApiResponseData[indexNumber]?.sellerDetails?.bbepData;
    const averageValue = tableData?.[0]?.avg;

    return (
      <div id={`${fieldName}${showTableFor}${indexNumber}`} className="pb-1">
        <h4 className="mt-2 graph-title text-bold">Buy Box % Table</h4>
        <DesktopTable
          className="mt-2"
          style={{
            border: '1px solid #e0e6e8',
            borderBottom: 'none',
            borderSpacing: showReportPDFTemplate ? 'revert' : 0,
          }}
        >
          <thead>
            <tr>{headerContent}</tr>
          </thead>
          {graphData[fieldName]?.bbpTableView === 'detailedView' ? (
            <tbody>
              {tableData?.map((option) => {
                return (
                  <tr key={option?.date}>
                    <td>
                      <div>{option?.date}</div>
                    </td>
                    <td>
                      <div>
                        {formatNumber(option?.value, '', '%', false, false, 2)}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : null}
        </DesktopTable>
        <DesktopTable
          className="mb-3 metrics-table-total"
          style={{
            border: '1px solid #e0e6e8',
            borderBottom: 'none',
            borderTop: 'none',
            borderSpacing: showReportPDFTemplate ? 'revert' : 0,
          }}
        >
          <tbody>
            <tr>
              <td width="50%" className="label-info-dark label-info">
                Average
              </td>
              <td width="50%">
                <div>
                  {formatNumber(averageValue, '', '%', false, false, 2)}
                </div>
              </td>
            </tr>
          </tbody>
        </DesktopTable>
        {tableData?.length === 0 ? (
          <NoData>Buy Box % Data Not Found</NoData>
        ) : null}
      </div>
    );
  };

  const renderPositiveFeedbackPanel = (indexNumber) => {
    const localData =
      graphApiResponseData[indexNumber]?.sellerDetails?.pfoiData;
    return (
      <div
        className="col-md-4 col-sm-12 mb-3"
        key={`sellerFeedback${indexNumber}`}
      >
        <WhiteCard className="fix-height">
          <div className="black-heading-title mt-0">Positive Feedback</div>
          <div className="sub-heading extra-small-text text-medium pt-1  mb-3">
            {dataScrappedMsg}
          </div>
          <div className="seller-health positive">
            {localData && localData?.feedback_30
              ? localData?.feedback_30 === 'nan'
                ? 'N/A'
                : `${localData?.feedback_30}%`
              : 'N/A'}
          </div>
          <div className="seller-update mb-3">Last 30 days</div>
          <div className="seller-health positive ">
            {localData && localData?.feedback_365
              ? localData?.feedback_365 === 'nan'
                ? 'N/A'
                : `${localData?.feedback_365}%`
              : 'N/A'}
          </div>
          <div className="seller-update mb-5">Last 12 months</div>
          <div className="last-update ">
            Last updated: {localData?.latest_date}
          </div>
        </WhiteCard>
      </div>
    );
  };

  const renderOrderIssuesPanel = (indexNumber) => {
    const localData =
      graphApiResponseData[indexNumber]?.sellerDetails?.pfoiData;
    return (
      <div
        className="col-md-4 col-sm-12 mb-3"
        key={`sellerOrderIssue${indexNumber}`}
      >
        <WhiteCard className="fix-height">
          {' '}
          <div className="black-heading-title mt-0">Order Issues</div>
          <div className="sub-heading extra-small-text text-medium pt-1  mb-3">
            {dataScrappedMsg}
          </div>
          <div className="seller-health">
            {localData && localData?.order_defect_fba
              ? localData?.order_defect_fba === 'nan'
                ? 'N/A'
                : localData?.order_defect_fba
              : 'N/A'}
          </div>
          <div className="seller-update mb-3">Order Defect Rate</div>
          <div className="seller-health  ">
            {localData && localData?.policy_issues
              ? localData?.policy_issues === 'nan'
                ? 'N/A'
                : localData?.policy_issues
              : 'N/A'}
          </div>
          <div className="seller-update mb-5">Policy Violations</div>
          <div className="last-update ">
            Last updated: {localData?.latest_date}
          </div>
        </WhiteCard>
      </div>
    );
  };

  const renderInventoryScorePanel = (indexNumber) => {
    return (
      <div
        className="col-md-4 col-sm-12 mb-3"
        key={`sellerInventoryScore${indexNumber}`}
      >
        <WhiteCard className="fix-height ">
          <div className="black-heading-title mt-0">Inventory Score (IPI)</div>
          <div className="sub-heading extra-small-text text-medium pt-1  mb-3">
            {dataScrappedMsg}
          </div>
          <InventoryScoreGraph
            chartId={`inventory-score-graph${indexNumber}`}
            pieData={
              graphApiResponseData[indexNumber]?.sellerDetails
                ?.inventoryScoreData
            }
          />
          <div className="average">
            {graphApiResponseData[indexNumber]?.sellerDetails
              ?.inventoryScoreData &&
            !Number.isNaN(
              graphApiResponseData[indexNumber]?.sellerDetails
                ?.inventoryScoreData[0]?.value,
            )
              ? graphApiResponseData[indexNumber]?.sellerDetails
                  ?.inventoryScoreData[0]?.value
              : 'N/A'}

            <div className="out-off">Out of 1000</div>
          </div>
          <div className="last-update mt-3 ">
            Last updated:{' '}
            {
              graphApiResponseData[indexNumber]?.sellerDetails?.pfoiData
                ?.latest_date
            }
          </div>
        </WhiteCard>
      </div>
    );
  };

  // render stranded inventory table
  const renderStrandedInventoryTable = (
    indexNumber,
    fieldName,
    showTableFor,
    tableHeading,
    numberFormat,
    graphData,
  ) => {
    const headerContent = [];
    let tableData = [];
    if (graphApiResponseData && graphApiResponseData.length) {
      tableData = graphApiResponseData[indexNumber][fieldName][showTableFor];
    }

    headerContent.push(
      strandedInventoryTableFields?.map((option) => {
        return (
          <th width={option.width} key={Math.random()}>
            {option.name}
          </th>
        );
      }),
    );

    return (
      <div id={`${fieldName}${showTableFor}${indexNumber}`} className="pb-1">
        <h4 className="mt-2 graph-title text-bold">{tableHeading}</h4>

        <DesktopTable
          id={`${fieldName}${showTableFor}${indexNumber}`}
          className="mt-2 mb-3"
          style={{
            border: '1px solid #e0e6e8',
            borderBottom: 'none',
            borderSpacing: showReportPDFTemplate ? 'revert' : 0,
          }}
        >
          <thead>
            <tr>{headerContent}</tr>
          </thead>
          <tbody>
            {tableData && tableData.length !== 0
              ? tableData.map((itemData) => {
                  return (
                    <React.Fragment key={`${itemData?.sku}${Math.random()}`}>
                      <tr
                        key={itemData?.sku}
                        className="cursor report-child-asin "
                      >
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          {itemData?.sku}
                        </td>
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          {itemData?.asin}
                        </td>
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          <div className="marketplace">
                            {numberFormat(itemData?.available, '', '')}
                          </div>
                        </td>
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          {commanFieldsDetails.selectedTimeFrame.value ===
                            'custom' || itemData?.percent_change === null ? (
                            'N/A'
                          ) : itemData?.percent_change === 0 ? (
                            <div className=" text-extra-medium increase-rate gray80">
                              {numberFormat(itemData?.percent_change, '', '%')}
                            </div>
                          ) : itemData?.percent_change > 0 ? (
                            <div className=" text-extra-medium increase-rate">
                              <FontAwesomeIcon
                                fontSize="12px"
                                color={Theme.green}
                                icon={`fa-light ${
                                  itemData?.percent_change < 0
                                    ? 'fa-arrow-down'
                                    : 'fa-arrow-up'
                                }`}
                                style={{
                                  marginRight: '4px',
                                }}
                              />
                              {numberFormat(itemData?.percent_change, '', '%')}
                            </div>
                          ) : (
                            <div className=" text-extra-medium decrease-rate">
                              <FontAwesomeIcon
                                fontSize="12px"
                                color={Theme.red}
                                icon={`fa-light ${
                                  itemData?.percent_change < 0
                                    ? 'fa-arrow-down'
                                    : 'fa-arrow-up'
                                }`}
                                style={{
                                  marginRight: '4px',
                                }}
                              />
                              {numberFormat(
                                Math.abs(itemData?.percent_change),
                                '',
                                '%',
                              )}
                            </div>
                          )}
                        </td>
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          <div className="marketplace">
                            {' '}
                            {itemData?.stranded_event_date !== null
                              ? dayjs(itemData?.stranded_event_date).format(
                                  'MM/DD/YY',
                                )
                              : 'N/A'}
                          </div>
                        </td>
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          <div className="marketplace">
                            {itemData?.stranded_reason || 'N/A'}
                          </div>
                        </td>
                      </tr>
                      {graphData[fieldName]?.showInventoryProductName ? (
                        <tr className="child-asin-text">
                          <td className="pt-0" colSpan="7">
                            <GreyCard className="mr-3">
                              {itemData?.product_name}
                            </GreyCard>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })
              : null}
          </tbody>
        </DesktopTable>
        {tableData?.length === 0 ? (
          <NoData>{tableHeading} Data Not Found</NoData>
        ) : null}
      </div>
    );
  };

  const renderUnderstockOverstockTable = (
    indexNumber,
    fieldName,
    showTableFor,
    tableHeading,
    numberFormat,
    graphData,
  ) => {
    const headerContent = [];
    let tableData = [];
    if (graphApiResponseData && graphApiResponseData.length) {
      tableData = graphApiResponseData[indexNumber][fieldName][showTableFor];
    }

    if (showTableFor === 'overstockInventory') {
      headerContent.push(
        overstockInventoryTableFields?.map((option) => {
          return (
            <th width={option.width} key={Math.random()}>
              {option.name}
            </th>
          );
        }),
      );
    }

    if (showTableFor === 'understockInventory') {
      headerContent.push(
        understockInventoryTableFields?.map((option) => {
          return (
            <th width={option.width} key={Math.random()}>
              {option.name}
            </th>
          );
        }),
      );
    }

    return (
      <div id={`${fieldName}${showTableFor}${indexNumber}`} className="pb-1">
        <h4 className="mt-2 graph-title text-bold">{tableHeading}</h4>

        <DesktopTable
          id={`${fieldName}${showTableFor}${indexNumber}`}
          className="mt-2 mb-3"
          style={{
            border: '1px solid #e0e6e8',
            borderBottom: 'none',
            borderSpacing: showReportPDFTemplate ? 'revert' : 0,
          }}
        >
          <thead>
            <tr>{headerContent}</tr>
          </thead>
          <tbody>
            {tableData && tableData?.length !== 0
              ? tableData.map((itemData) => {
                  return (
                    <React.Fragment key={`${itemData?.asin}${Math.random()}`}>
                      <tr key={itemData?.asin} className="report-child-asin">
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          <div className="marketplace">
                            {itemData?.merchant_sku || 'N/A'}
                          </div>
                        </td>
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          {itemData?.asin || 'N/A'}
                        </td>
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          <div className="marketplace">
                            {numberFormat(
                              itemData?.sales_last_30_days,
                              commanFieldsDetails?.marketplaceCurrency,
                            ) || 'N/A'}
                          </div>
                        </td>
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          <div className="marketplace">
                            {itemData?.units_sold_last_30_days || 'N/A'}
                          </div>
                        </td>
                        <td
                          className={
                            graphData[fieldName]?.showInventoryProductName
                              ? 'border-none'
                              : ''
                          }
                        >
                          <div className="marketplace">
                            {itemData?.total_days_of_supply || 'N/A'}
                          </div>
                        </td>

                        {showTableFor === 'understockInventory' ? (
                          <>
                            <td
                              className={
                                graphData[fieldName]?.showInventoryProductName
                                  ? 'border-none'
                                  : ''
                              }
                            >
                              <div className="marketplace">
                                {itemData?.recommended_replenishment_quantity ||
                                  'N/A'}
                              </div>
                            </td>
                            <td
                              className={
                                graphData[fieldName]?.showInventoryProductName
                                  ? 'border-none'
                                  : ''
                              }
                            >
                              <div className="marketplace">
                                {itemData?.recommended_ship_date !== null
                                  ? dayjs(
                                      itemData?.recommended_ship_date,
                                    ).format('MM/DD/YYYY')
                                  : 'N/A'}
                              </div>
                            </td>
                          </>
                        ) : null}
                      </tr>
                      {graphData[fieldName]?.showInventoryProductName ? (
                        <tr className="child-asin-text ">
                          <td className="pt-0" colSpan="7">
                            <GreyCard className="mr-3">
                              {itemData?.product_name}
                            </GreyCard>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })
              : null}
          </tbody>
        </DesktopTable>
        {tableData?.length === 0 ? (
          <NoData>{tableHeading} Data Not Found</NoData>
        ) : null}
      </div>
    );
  };

  // render seller panel
  const renderSellerPanel = (graphData, graphIndex) => {
    return (
      <MainBodyReports>
        <div className="text-container noah-font">
          <div id="newPage">
            <div id={`graph${graphIndex}-seller-panel`}>
              {commanFieldsDetails.accountType.isHybrid ? (
                <h1
                  className="medium-size-page-heading text-bold pb-1"
                  id="sellerHeader"
                >
                  SELLER
                </h1>
              ) : null}
              {/* graph title editor */}
              {renderEditComponent(
                graphData,
                graphIndex,
                'sellerDetails',
                'graphTitle',
                '   Add Title Here',
                true,
              )}

              {/* metrics graph */}
              <div
                id={`collapseSellerGraph${graphIndex}`}
                className="customized-report-graph"
              >
                {graphData.sellerDetails.showMetricsGraph
                  ? renderSalesAdDspGraph(
                      graphData,
                      graphIndex,
                      graphApiResponseData[graphIndex]?.sellerDetails
                        ?.sellerData,
                      'sellerDetails',
                    )
                  : null}
              </div>
              <div id={`collapseSellerGraphTable${graphIndex}`}>
                {/* metrics table */}
                {graphData.sellerDetails.showDataTable &&
                Object.keys(graphData.sellerDetails.selectedMetrics).length
                  ? renderMetricsTable(
                      graphData,
                      graphIndex,
                      'sellerDetails',
                      'sellerData',
                      'sellerTotalData',
                      useFormatNumber,
                    )
                  : null}
              </div>
              {Object.keys(graphData.sellerDetails.selectedMetrics).length ||
              graphData.sellerDetails.showDataTable ? (
                <div>
                  {/* metric message editor */}
                  {renderEditComponent(
                    graphData,
                    graphIndex,
                    'sellerDetails',
                    'metricMessage',
                    '   Add Insight Summary Here',
                  )}
                </div>
              ) : null}
              {/* ******************************************* */}
              {/* Parent Top Asins */}
              <div id={`collapseSellerParentTop${graphIndex}`}>
                {graphData.sellerDetails.isParentTopAsins
                  ? renderParentChildAsinsTable(
                      graphIndex,
                      'sellerDetails',
                      'topParentAsins',
                      'Top Parent ASINs',
                      useFormatNumber,
                    )
                  : null}
              </div>
              <div id={`collapseSellerParentBottom${graphIndex}`}>
                {/* Parent bottom Asins */}
                {graphData.sellerDetails.isParentBottomAsins
                  ? renderParentChildAsinsTable(
                      graphIndex,
                      'sellerDetails',
                      'bottomParentAsins',
                      'Bottom Parent ASINs',
                      useFormatNumber,
                    )
                  : null}
              </div>
              <div id={`collapseSellerParentAll${graphIndex}`}>
                {/* Parent all Asins */}
                {graphData.sellerDetails.isParentAllAsins
                  ? renderParentChildAsinsTable(
                      graphIndex,
                      'sellerDetails',
                      'allParentAsins',
                      'All Parent ASINs',
                      useFormatNumber,
                    )
                  : null}
              </div>
              {[
                graphData.sellerDetails.isParentTopAsins,
                graphData.sellerDetails.isParentBottomAsins,
                graphData.sellerDetails.isParentAllAsins,
              ].filter(Boolean).length >= 1 ? (
                <div>
                  {/* parent asin message editor */}
                  {renderEditComponent(
                    graphData,
                    graphIndex,
                    'sellerDetails',
                    'parentAsinMessage',
                    '   Add Insight Summary Here',
                  )}
                </div>
              ) : null}
              {/* child top asins */}
              <div id={`collapseSellerChildTop${graphIndex}`}>
                {graphData.sellerDetails.isChildTopAsins
                  ? renderParentChildAsinsTable(
                      graphIndex,
                      'sellerDetails',
                      'topChildAsins',
                      'Top Child ASINs',
                      useFormatNumber,
                      graphData,
                    )
                  : null}
              </div>
              <div id={`collapseSellerChildBottom${graphIndex}`}>
                {/* child bottom asins */}
                {graphData.sellerDetails.isChildBottomAsins
                  ? renderParentChildAsinsTable(
                      graphIndex,
                      'sellerDetails',
                      'bottomChildAsins',
                      'Bottom Child ASINs',
                      useFormatNumber,
                      graphData,
                    )
                  : null}
              </div>
              <div id={`collapseSellerChildAll${graphIndex}`}>
                {/* child all asins */}
                {graphData.sellerDetails.isChildAllAsins
                  ? renderParentChildAsinsTable(
                      graphIndex,
                      'sellerDetails',
                      'allChildAsins',
                      'All Child ASINs',
                      useFormatNumber,
                      graphData,
                    )
                  : null}
              </div>
              {[
                graphData?.sellerDetails?.isChildTopAsins,
                graphData?.sellerDetails?.isChildBottomAsins,
                graphData?.sellerDetails?.isChildAllAsins,
              ].filter(Boolean).length >= 1 ? (
                <div>
                  {/* child asin message editor */}
                  {renderEditComponent(
                    graphData,
                    graphIndex,
                    'sellerDetails',
                    'childAsinMessage',
                    '   Add Insight Summary Here',
                  )}
                </div>
              ) : null}

              {[
                graphData.sellerDetails.buyBoxGraph,
                graphData.sellerDetails.showBuyBoxGraphTable,
                graphData.sellerDetails.inventoryScore,
                graphData.sellerDetails.positiveFeedback,
                graphData.sellerDetails.orderIssue,
              ].filter(Boolean).length >= 1 ? (
                <h4
                  className="mt-3 pb-1 graph-title text-bold"
                  id="sellerHealthHeader"
                >
                  Seller Health
                </h4>
              ) : null}

              {/* need to show bbep graph and any one panel in one row, if one one panel is selected. 
                      If multiple panel selected then graph will be seperated */}

              <div id={`collapseSellerBBEPgraph${graphIndex}`}>
                {[
                  graphData.sellerDetails.inventoryScore,
                  graphData.sellerDetails.positiveFeedback,
                  graphData.sellerDetails.orderIssue,
                ].filter(Boolean).length === 1 ? (
                  <div
                    className="row mt-3 pb-1"
                    id={`BbPercentchartReport${graphIndex}`}
                  >
                    {graphData.sellerDetails.inventoryScore
                      ? renderInventoryScorePanel(graphIndex)
                      : null}
                    {graphData.sellerDetails.positiveFeedback
                      ? renderPositiveFeedbackPanel(graphIndex)
                      : null}
                    {graphData.sellerDetails.orderIssue
                      ? renderOrderIssuesPanel(graphIndex)
                      : null}

                    <div className="col-8">
                      {graphData.sellerDetails.buyBoxGraph
                        ? renderBBPgraph(graphIndex)
                        : null}
                    </div>
                  </div>
                ) : (
                  <>
                    {graphData.sellerDetails.buyBoxGraph ? (
                      <div
                        id={`BbPercentchartReport${graphIndex}`}
                        className="pb-1"
                      >
                        {renderBBPgraph(graphIndex)}
                      </div>
                    ) : null}
                  </>
                )}
              </div>

              <div id={`collapseSellerBBEPTable${graphIndex}`}>
                {graphData?.sellerDetails?.showBuyBoxGraphTable
                  ? renderBBEPTable(
                      graphData,
                      graphIndex,
                      'sellerDetails',
                      'bbep',
                      useFormatNumber,
                    )
                  : null}
              </div>

              <div id={`collapseSellerHealth${graphIndex}`}>
                {[
                  graphData.sellerDetails.inventoryScore,
                  graphData.sellerDetails.positiveFeedback,
                  graphData.sellerDetails.orderIssue,
                ].filter(Boolean).length !== 1 ? (
                  <div
                    className="row mt-3 pb-1"
                    id={`sellerHealth${graphIndex}`}
                  >
                    {graphData?.sellerDetails?.inventoryScore
                      ? renderInventoryScorePanel(graphIndex)
                      : null}
                    {graphData?.sellerDetails?.positiveFeedback
                      ? renderPositiveFeedbackPanel(graphIndex)
                      : null}
                    {graphData?.sellerDetails?.orderIssue
                      ? renderOrderIssuesPanel(graphIndex)
                      : null}
                  </div>
                ) : null}
              </div>

              {/* if any once fileds selected from seller helth, 
            then and then only show the insight message editor */}
              {[
                graphData?.sellerDetails?.buyBoxGraph,
                graphData?.sellerDetails?.showBuyBoxGraphTable,
                graphData?.sellerDetails?.inventoryScore,
                graphData?.sellerDetails?.positiveFeedback,
                graphData?.sellerDetails?.orderIssue,
              ].filter(Boolean).length >= 1 ? (
                <div>
                  {/* sellerHeathMessge message editor */}
                  {renderEditComponent(
                    graphData,
                    graphIndex,
                    'sellerDetails',
                    'sellerHeathMessge',
                    '   Add Insight Summary Here',
                  )}
                </div>
              ) : null}

              {/* ********** inventory ********** */}
              {[
                graphData?.sellerDetails?.strandedInventory,
                graphData?.sellerDetails?.overstockInventory,
                graphData?.sellerDetails?.understockInventory,
              ].filter(Boolean).length >= 1 ? (
                <div id="invenotryManagementHeader" className="pt-2">
                  <h4 className="graph-title text-bold pb-1">
                    Inventory Management
                  </h4>
                  <div className="mt-3 pb-3">
                    {commanFieldsDetails?.inventoryDatesMessage}
                  </div>
                </div>
              ) : null}
              {/* stranded inventory */}
              <div id={`collapseSellerStranded${graphIndex}`}>
                {graphData?.sellerDetails?.strandedInventory
                  ? renderStrandedInventoryTable(
                      graphIndex,
                      'sellerDetails',
                      'strandedInventory',
                      'Stranded Inventory',
                      useFormatNumber,
                      graphData,
                    )
                  : null}
              </div>
              <div id={`collapseSellerOverstock${graphIndex}`}>
                {/* overstock inventory */}
                {graphData?.sellerDetails?.overstockInventory
                  ? renderUnderstockOverstockTable(
                      graphIndex,
                      'sellerDetails',
                      'overstockInventory',
                      'Overstock Inventory',
                      useFormatNumber,
                      graphData,
                    )
                  : null}
              </div>
              <div id={`collapseSellerUnderstock${graphIndex}`}>
                {/* understock inventory */}
                {graphData?.sellerDetails?.understockInventory
                  ? renderUnderstockOverstockTable(
                      graphIndex,
                      'sellerDetails',
                      'understockInventory',
                      'Understock Inventory',
                      useFormatNumber,
                      graphData,
                    )
                  : null}
              </div>
              {/* inventory message editor */}
              {[
                graphData?.sellerDetails?.strandedInventory,
                graphData?.sellerDetails?.overstockInventory,
                graphData?.sellerDetails?.understockInventory,
              ].filter(Boolean).length >= 1 ? (
                <div className="">
                  {renderEditComponent(
                    graphData,
                    graphIndex,
                    'sellerDetails',
                    'inventoryManagementMessage',
                    '   Add Insight Summary Here',
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </MainBodyReports>
    );
  };

  // render vendor panel data
  const renderVendorPanel = (graphData, graphIndex) => {
    return (
      <MainBodyReports key={`venodrReport${graphIndex + 1}`}>
        <div className="text-container noah-font ">
          <div id="newPage">
            <div id={`graph${graphIndex}-vendor-panel`}>
              {commanFieldsDetails.accountType.isHybrid ? (
                <>
                  <h1
                    className="medium-size-page-heading text-bold pb-2"
                    id="vendorHeader"
                  >
                    VENDOR
                  </h1>
                </>
              ) : null}
              {/* graph title editor */}
              {renderEditComponent(
                graphData,
                graphIndex,
                'vendorDetails',
                'graphTitle',
                '   Add Title Here',
                true,
              )}
              {/* metrics graph */}
              <div id={`collapseVendorGraph${graphIndex}`}>
                {graphData.vendorDetails.showMetricsGraph
                  ? renderSalesAdDspGraph(
                      graphData,
                      graphIndex,
                      graphApiResponseData[graphIndex]?.vendorDetails
                        ?.vendorData,
                      'vendorDetails',
                    )
                  : null}
              </div>
              <div id={`collapseVendorGraphTable${graphIndex}`}>
                {/* metrics table */}
                {graphData.vendorDetails.showDataTable &&
                Object.keys(graphData.vendorDetails.selectedMetrics).length
                  ? renderMetricsTable(
                      graphData,
                      graphIndex,
                      'vendorDetails',
                      'vendorData',
                      'vendorTotalData',
                      useFormatNumber,
                    )
                  : null}
              </div>
              {Object.keys(graphData.vendorDetails.selectedMetrics).length ||
              graphData.vendorDetails.showDataTable ? (
                <div className="">
                  {/* metric message editor */}
                  {renderEditComponent(
                    graphData,
                    graphIndex,
                    'vendorDetails',
                    'metricMessage',
                    '   Add Insight Summary Here',
                  )}
                </div>
              ) : null}
              {[
                graphData.vendorDetails.strandedInventory,
                graphData.vendorDetails.overstockInventory,
                graphData.vendorDetails.understockInventory,
              ].filter(Boolean).length >= 1 ? (
                <>
                  <div id="vendorInvenotryManagementHeader" className="pb-2">
                    <h4 className="graph-title text-bold pb-1">
                      Inventory Management
                    </h4>
                    <div className="mt-3 pb-3">
                      {commanFieldsDetails.inventoryDatesMessage}
                    </div>
                  </div>
                </>
              ) : null}
              <div id={`collapseVendorStranded${graphIndex}`}>
                {/* stranded inventory */}
                {graphData.vendorDetails.strandedInventory
                  ? renderStrandedInventoryTable(
                      graphIndex,
                      'vendorDetails',
                      'strandedInventory',
                      'Stranded Inventory',
                      useFormatNumber,
                      graphData,
                    )
                  : null}
              </div>
              <div id={`collapseVendorOverstock${graphIndex}`}>
                {/* overstock inventory */}
                {graphData.vendorDetails.overstockInventory
                  ? renderUnderstockOverstockTable(
                      graphIndex,
                      'vendorDetails',
                      'overstockInventory',
                      'Overstock Inventory',
                      useFormatNumber,
                      graphData,
                    )
                  : null}
              </div>
              {/* understock inventory */}
              <div id={`collapseVendorUnderstock${graphIndex}`}>
                {graphData.vendorDetails.understockInventory
                  ? renderUnderstockOverstockTable(
                      graphIndex,
                      'vendorDetails',
                      'understockInventory',
                      'Understock Inventory',
                      useFormatNumber,
                      graphData,
                    )
                  : null}
              </div>
              {/* inventory message editor */}
              {[
                graphData?.vendorDetails?.strandedInventory,
                graphData?.vendorDetails?.overstockInventory,
                graphData?.vendorDetails?.understockInventory,
              ].filter(Boolean).length >= 1 ? (
                <div>
                  {renderEditComponent(
                    graphData,
                    graphIndex,
                    'vendorDetails',
                    'inventoryManagementMessage',
                    '   Add Insight Summary Here',
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </MainBodyReports>
    );
  };

  // render graph detail html
  const renderGraphDetailHTML = () => {
    return graphDetails?.map((graphData, graphIndex) => {
      return (
        <div key={`sellerReport${graphIndex + 1}`}>
          {/* for seller account type */}
          {commanFieldsDetails?.accountType?.isSeller ||
          commanFieldsDetails?.accountType?.isHybrid
            ? renderSellerPanel(graphData, graphIndex)
            : null}

          {/* for vendor account type */}
          {commanFieldsDetails?.accountType?.isVendor ||
          commanFieldsDetails?.accountType?.isHybrid
            ? renderVendorPanel(graphData, graphIndex)
            : null}
        </div>
      );
    });
  };

  // checking is user selected seller field or not
  const isSellerMFieldSelected = (sellerDetails) => {
    const showMetricsGraph = Object.keys(sellerDetails.selectedMetrics).length;

    const showChildAsins =
      sellerDetails.isChildTopAsins ||
      sellerDetails.isChildBottomAsins ||
      sellerDetails.isChildAllAsins;

    const showParentAsins =
      sellerDetails.isParentTopAsins ||
      sellerDetails.isParentBottomAsins ||
      sellerDetails.isParentAllAsins;

    const showSellerHealth =
      sellerDetails.buyBoxGraph ||
      sellerDetails.showBuyBoxGraphTable ||
      sellerDetails.inventoryScore ||
      sellerDetails.positiveFeedback ||
      sellerDetails.orderIssue;

    const showInventory =
      sellerDetails.strandedInventory ||
      sellerDetails.overstockInventory ||
      sellerDetails.understockInventory;

    const showSellerData =
      showMetricsGraph ||
      showChildAsins ||
      showParentAsins ||
      showSellerHealth ||
      showInventory;
    return showSellerData;
  };

  // render pdf seller panel data
  const renderPDFSellerPanelData = (graphData, graphIndex) => {
    const { isHybrid } = commanFieldsDetails?.accountType;

    const showMetricsGraph = Object.keys(
      graphData?.sellerDetails?.selectedMetrics,
    ).length;

    const showChildAsins =
      graphData?.sellerDetails?.isChildTopAsins ||
      graphData?.sellerDetails?.isChildBottomAsins ||
      graphData?.sellerDetails?.isChildAllAsins;

    const showParentAsins =
      graphData?.sellerDetails?.isParentTopAsins ||
      graphData?.sellerDetails?.isParentBottomAsins ||
      graphData?.sellerDetails?.isParentAllAsins;

    const showSellerHealth =
      graphData?.sellerDetails?.buyBoxGraph ||
      graphData?.sellerDetails?.showBuyBoxGraphTable ||
      graphData?.sellerDetails?.inventoryScore ||
      graphData?.sellerDetails?.positiveFeedback ||
      graphData?.sellerDetails?.orderIssue;

    const showInventory =
      graphData?.sellerDetails?.strandedInventory ||
      graphData?.sellerDetails?.overstockInventory ||
      graphData?.sellerDetails?.understockInventory;

    const showSellerData = isSellerMFieldSelected(graphData?.sellerDetails);

    let applyPageBreak = false;

    if (graphIndex === 0) {
      applyPageBreak = false;
    } else {
      applyPageBreak = true;
    }

    return showSellerData ? (
      <>
        {/* ********** Adding report pdf page break ********** */}
        {applyPageBreak ? (
          <div className="pdf-page-break" style={{ height: '1px' }} />
        ) : null}
        {/* ********** seller heading ********** */}
        {isHybrid ? (
          <h1
            className="medium-size-page-heading text-bold pb-1"
            id="sellerHeader"
          >
            SELLER
          </h1>
        ) : null}
        {/* ********** graph title editor ********** */}
        {graphData?.sellerDetails?.graphTitle?.length > 8
          ? renderEditComponent(
              graphData,
              graphIndex,
              'sellerDetails',
              'graphTitle',
              '   Add Title Here',
              true,
            )
          : null}
        {/* ********** seller performance metrics graph ********** */}
        {showMetricsGraph && graphData?.sellerDetails?.showMetricsGraph
          ? renderSalesAdDspGraph(
              graphData,
              graphIndex,
              graphApiResponseData[graphIndex]?.sellerDetails?.sellerData,
              'sellerDetails',
            )
          : null}
        {/* ********** seller performance metrics table data ********** */}
        {graphData?.sellerDetails?.showDataTable && showMetricsGraph ? (
          <>
            {graphData?.sellerDetails?.showMetricsGraph &&
            graphData?.sellerDetails?.graphDataTableView === 'detailedView' ? (
              <div className="pdf-page-break" style={{ height: '1px' }} />
            ) : null}
            {renderMetricsTable(
              graphData,
              graphIndex,
              'sellerDetails',
              'sellerData',
              'sellerTotalData',
              useFormatNumber,
            )}
          </>
        ) : null}
        {/* ********** metrics message editor ********** */}
        {(showMetricsGraph || graphData?.sellerDetails?.showDataTable) &&
        graphData?.sellerDetails?.metricMessage.length > 8
          ? renderEditComponent(
              graphData,
              graphIndex,
              'sellerDetails',
              'metricMessage',
              '   Add Insight Summary Here',
            )
          : null}
        {/* ********** Parent Top Asins ********** */}
        {graphData?.sellerDetails?.isParentTopAsins
          ? renderParentChildAsinsTable(
              graphIndex,
              'sellerDetails',
              'topParentAsins',
              'Top Parent ASINs',
              useFormatNumber,
            )
          : null}
        {/* ********** Parent bottom Asins ********** */}
        {graphData?.sellerDetails?.isParentBottomAsins
          ? renderParentChildAsinsTable(
              graphIndex,
              'sellerDetails',
              'bottomParentAsins',
              'Bottom Parent ASINs',
              useFormatNumber,
            )
          : null}
        {/* ********** Parent all Asins ********** */}
        {graphData?.sellerDetails?.isParentAllAsins
          ? renderParentChildAsinsTable(
              graphIndex,
              'sellerDetails',
              'allParentAsins',
              'All Parent ASINs',
              useFormatNumber,
            )
          : null}
        {/* ********** parent asin message editor ********** */}
        {showParentAsins &&
        graphData?.sellerDetails?.parentAsinMessage.length > 8
          ? renderEditComponent(
              graphData,
              graphIndex,
              'sellerDetails',
              'parentAsinMessage',
              '   Add Insight Summary Here',
            )
          : null}
        {/* ********** child top asins ********** */}
        {graphData?.sellerDetails?.isChildTopAsins
          ? renderParentChildAsinsTable(
              graphIndex,
              'sellerDetails',
              'topChildAsins',
              'Top Child ASINs',
              useFormatNumber,
              graphData,
            )
          : null}
        {/* ********** child bottom asins ********** */}
        {graphData?.sellerDetails?.isChildBottomAsins
          ? renderParentChildAsinsTable(
              graphIndex,
              'sellerDetails',
              'bottomChildAsins',
              'Bottom Child ASINs',
              useFormatNumber,
              graphData,
            )
          : null}
        {graphData?.sellerDetails?.isParentAllAsins &&
        graphData?.sellerDetails?.isChildAllAsins &&
        graphData?.sellerDetails?.showChildProductName ? (
          <div className="pdf-page-break" style={{ height: '1px' }} />
        ) : null}
        {/* ********** child all asins ********** */}
        {graphData.sellerDetails.isChildAllAsins
          ? renderParentChildAsinsTable(
              graphIndex,
              'sellerDetails',
              'allChildAsins',
              'All Child ASINs',
              useFormatNumber,
              graphData,
            )
          : null}
        {/* ********** child asin message editor ********** */}
        {showChildAsins && graphData?.sellerDetails?.childAsinMessage.length > 8
          ? renderEditComponent(
              graphData,
              graphIndex,
              'sellerDetails',
              'childAsinMessage',
              '   Add Insight Summary Here',
            )
          : null}
        {/* ********** seller health data ********** */}
        <div id={`collapseSellerBBEPgraph${graphIndex}`}>
          {[graphData?.sellerDetails?.buyBoxGraph].filter(Boolean).length > 0 ||
          [graphData?.sellerDetails?.showBuyBoxGraphTable].filter(Boolean)
            .length > 0 ? (
            <h4 className="my-3 graph-title text-bold" id="sellerHealthHeader">
              Seller Health
            </h4>
          ) : null}
          {[
            graphData?.sellerDetails?.inventoryScore,
            graphData?.sellerDetails?.positiveFeedback,
            graphData?.sellerDetails?.orderIssue,
          ].filter(Boolean).length === 1 ? (
            <div className="row my-2" id={`BbPercentchartReport${graphIndex}`}>
              {graphData?.sellerDetails?.inventoryScore
                ? renderInventoryScorePanel(graphIndex)
                : null}
              {graphData?.sellerDetails?.positiveFeedback
                ? renderPositiveFeedbackPanel(graphIndex)
                : null}
              {graphData?.sellerDetails?.orderIssue
                ? renderOrderIssuesPanel(graphIndex)
                : null}

              <div className="col-8">
                {graphData?.sellerDetails?.buyBoxGraph
                  ? renderBBPgraph(graphIndex)
                  : null}
              </div>
            </div>
          ) : (
            <>
              {graphData?.sellerDetails?.buyBoxGraph ? (
                <div id={`BbPercentchartReport${graphIndex}`} className="pb-1">
                  {renderBBPgraph(graphIndex)}
                </div>
              ) : null}
            </>
          )}
        </div>
        <div id={`collapseSellerBBEPTable${graphIndex}`}>
          {graphData?.sellerDetails?.showBuyBoxGraphTable
            ? renderBBEPTable(
                graphData,
                graphIndex,
                'sellerDetails',
                'bbep',
                useFormatNumber,
              )
            : null}
        </div>
        <div id={`collapseSellerHealth${graphIndex}`}>
          {[
            graphData?.sellerDetails?.buyBoxGraph,
            graphData?.sellerDetails?.showBuyBoxGraphTable,
          ].filter(Boolean).length === 0 &&
          [
            graphData?.sellerDetails?.inventoryScore,
            graphData?.sellerDetails?.positiveFeedback,
            graphData?.sellerDetails?.orderIssue,
          ].filter(Boolean).length > 1 ? (
            <h4 className="my-3 graph-title text-bold" id="sellerHealthHeader">
              Seller Health
            </h4>
          ) : null}

          {[
            graphData?.sellerDetails?.inventoryScore,
            graphData?.sellerDetails?.positiveFeedback,
            graphData?.sellerDetails?.orderIssue,
          ].filter(Boolean).length !== 1 ? (
            <div className="row my-2" id={`sellerHealth${graphIndex}`}>
              {graphData?.sellerDetails?.inventoryScore
                ? renderInventoryScorePanel(graphIndex)
                : null}
              {graphData?.sellerDetails?.positiveFeedback
                ? renderPositiveFeedbackPanel(graphIndex)
                : null}
              {graphData?.sellerDetails?.orderIssue
                ? renderOrderIssuesPanel(graphIndex)
                : null}
            </div>
          ) : null}
        </div>
        {/* ********** seller health message editor ********** */}
        {showSellerHealth &&
        graphData?.sellerDetails?.sellerHeathMessge.length > 8
          ? renderEditComponent(
              graphData,
              graphIndex,
              'sellerDetails',
              'sellerHeathMessge',
              '   Add Insight Summary Here',
            )
          : null}
        {/* ********** seller inventory ********** */}
        <div>
          {[
            graphData?.sellerDetails?.strandedInventory,
            graphData?.sellerDetails?.overstockInventory,
            graphData?.sellerDetails?.understockInventory,
          ].filter(Boolean).length >= 1 ? (
            <h4 className="graph-title text-bold pb-1">Inventory Management</h4>
          ) : null}

          {/* ********** stranded inventory ********** */}
          {graphData?.sellerDetails?.strandedInventory
            ? renderStrandedInventoryTable(
                graphIndex,
                'sellerDetails',
                'strandedInventory',
                'Stranded Inventory',
                useFormatNumber,
                graphData,
              )
            : null}

          {/* ********** overstock inventory ********** */}
          {graphData?.sellerDetails?.overstockInventory
            ? renderUnderstockOverstockTable(
                graphIndex,
                'sellerDetails',
                'overstockInventory',
                'Overstock Inventory',
                useFormatNumber,
                graphData,
              )
            : null}

          {/* ********** understock inventory ********** */}
          {graphData?.sellerDetails?.understockInventory
            ? renderUnderstockOverstockTable(
                graphIndex,
                'sellerDetails',
                'understockInventory',
                'Understock Inventory',
                useFormatNumber,
                graphData,
              )
            : null}
        </div>
        {/* ********** inventory message editor ********** */}
        {showInventory &&
        graphData?.sellerDetails?.inventoryManagementMessage.length > 8
          ? renderEditComponent(
              graphData,
              graphIndex,
              'sellerDetails',
              'inventoryManagementMessage',
              '   Add Insight Summary Here',
            )
          : null}
      </>
    ) : null;
  };

  // render pdf vendor panel data
  const renderPDFVendorPanelData = (graphData, graphIndex) => {
    const { isHybrid } = commanFieldsDetails?.accountType;

    let applyPageBreak = false;
    if (accountType === 'Vendor') {
      if (graphIndex === 0) {
        applyPageBreak = false;
      } else {
        applyPageBreak = true;
      }
    }

    if (accountType === 'Hybrid') {
      if (graphIndex === 0) {
        const showSellerData = isSellerMFieldSelected(graphData?.sellerDetails);
        if (showSellerData) {
          applyPageBreak = true;
        } else {
          applyPageBreak = false;
        }
      } else {
        applyPageBreak = true;
      }
    }

    const showMetricsGraph = Object.keys(
      graphData?.vendorDetails?.selectedMetrics,
    ).length;

    const showInventory =
      graphData?.vendorDetails?.strandedInventory ||
      graphData?.vendorDetails?.overstockInventory ||
      graphData?.vendorDetails?.understockInventory;

    const showVendorData = showMetricsGraph || showInventory;

    return showVendorData ? (
      <>
        {/* ********** Adding report pdf page break ********** */}
        {applyPageBreak ? (
          <div className="pdf-page-break" style={{ height: '1px' }} />
        ) : null}

        {/* ********** vendor heading ********** */}
        {isHybrid ? (
          <>
            <h1
              className="medium-size-page-heading text-bold pb-2"
              id="vendorHeader"
            >
              VENDOR
            </h1>
          </>
        ) : null}

        {/* ********** graph title editor ********** */}
        {graphData?.vendorDetails?.graphTitle?.length > 8
          ? renderEditComponent(
              graphData,
              graphIndex,
              'vendorDetails',
              'graphTitle',
              '   Add Title Here',
              true,
            )
          : null}

        {/* ********** vendor performance metrics graph ********** */}
        {showMetricsGraph && graphData?.vendorDetails?.showMetricsGraph
          ? renderSalesAdDspGraph(
              graphData,
              graphIndex,
              graphApiResponseData[graphIndex]?.vendorDetails?.vendorData,
              'vendorDetails',
            )
          : null}

        {/* ********** vendor performance metrics table data ********** */}
        {graphData?.vendorDetails?.showDataTable && showMetricsGraph ? (
          <>
            {graphData?.vendorDetails?.showMetricsGraph &&
            graphData?.vendorDetails?.graphDataTableView === 'detailedView' ? (
              <div className="pdf-page-break" style={{ height: '1px' }} />
            ) : null}
            {renderMetricsTable(
              graphData,
              graphIndex,
              'vendorDetails',
              'vendorData',
              'vendorTotalData',
              useFormatNumber,
            )}
          </>
        ) : null}

        {/* ********** metrics message editor ********** */}
        {(showMetricsGraph || graphData?.vendorDetails?.showDataTable) &&
        graphData?.vendorDetails?.metricMessage?.length > 8
          ? renderEditComponent(
              graphData,
              graphIndex,
              'vendorDetails',
              'metricMessage',
              '   Add Insight Summary Here',
            )
          : null}

        {/* ********** vendor inventory ********** */}
        <div>
          {[
            graphData?.vendorDetails?.strandedInventory,
            graphData?.vendorDetails?.overstockInventory,
            graphData?.vendorDetails?.understockInventory,
          ].filter(Boolean).length >= 1 ? (
            <h4 className="graph-title text-bold pb-1">Inventory Management</h4>
          ) : null}

          {/* ********** stranded inventory ********** */}
          {graphData?.vendorDetails?.strandedInventory
            ? renderStrandedInventoryTable(
                graphIndex,
                'vendorDetails',
                'strandedInventory',
                'Stranded Inventory',
                useFormatNumber,
                graphData,
              )
            : null}

          {/* ********** overstock inventory ********** */}
          {graphData?.vendorDetails?.overstockInventory
            ? renderUnderstockOverstockTable(
                graphIndex,
                'vendorDetails',
                'overstockInventory',
                'Overstock Inventory',
                useFormatNumber,
                graphData,
              )
            : null}

          {/* ********** understock inventory ********** */}
          {graphData?.vendorDetails?.understockInventory
            ? renderUnderstockOverstockTable(
                graphIndex,
                'vendorDetails',
                'understockInventory',
                'Understock Inventory',
                useFormatNumber,
                graphData,
              )
            : null}
        </div>
        {/* ********** inventory message editor ********** */}
        {showInventory &&
        graphData?.vendorDetails?.inventoryManagementMessage?.length > 8
          ? renderEditComponent(
              graphData,
              graphIndex,
              'vendorDetails',
              'inventoryManagementMessage',
              '   Add Insight Summary Here',
            )
          : null}
      </>
    ) : null;
  };

  // render report pdf body html
  const renderReportPDFBodyContent = () => {
    return graphDetails?.map((graphData, graphIndex) => {
      return (
        <React.Fragment key={`sellerReport${graphIndex + 1}`}>
          {/* for seller account type */}
          {commanFieldsDetails?.accountType?.isSeller ||
          commanFieldsDetails?.accountType?.isHybrid
            ? renderPDFSellerPanelData(graphData, graphIndex)
            : null}

          {/* for vendor account type */}
          {commanFieldsDetails?.accountType?.isVendor ||
          commanFieldsDetails?.accountType?.isHybrid
            ? renderPDFVendorPanelData(graphData, graphIndex)
            : null}
        </React.Fragment>
      );
    });
  };

  // render Report PDF with Hyperlink
  const renderReportPDFWithHyperlink = () => {
    return (
      <ReportPDFContainer>
        <ReportPDFWithHperlink
          html2pdfRef={html2pdfRef}
          pdfHeader={pdfHeader}
          renderReportPDFBodyContent={renderReportPDFBodyContent}
        />
      </ReportPDFContainer>
    );
  };

  return (
    <div id="ReportLeftSidePanel">
      {reportStatus === 'readyToEdit' || reportStatus === 'view' ? (
        <div className="on-boarding-container">
          <PdfViewer
            pdf={commanFieldsDetails?.reportPresignedUrl}
            loadingMsg="Loading Report Document..."
            setShowAddendum={() => {}}
            executeScroll={executeScroll}
          />
        </div>
      ) : (
        <>
          {showReportPDFTemplate ? renderReportPDFWithHyperlink() : null}
          {renderGraphDetailHTML()}
        </>
      )}
    </div>
  );
};

export default ReportLeftSidePanel;

ReportLeftSidePanel.defaultProps = {
  customerName: '',
  reportStatus: '',
  reportSummary: '',
  currentEndDate: '',
  showReportPDFTemplate: false,
  commanFieldsDetails: {},
  graphApiResponseData: {},
  dateToShow: {},
  html2pdfRef: {},
  graphDetails: [],
  setGraphDetails: () => {},
};

ReportLeftSidePanel.propTypes = {
  customerName: string,
  reportStatus: string,
  reportSummary: string,
  currentEndDate: string,
  showReportPDFTemplate: bool,
  dateToShow: shape({}),
  html2pdfRef: shape({}),
  graphApiResponseData: arrayOf(shape({})),
  commanFieldsDetails: shape({}),
  graphDetails: arrayOf(shape({})),
  setGraphDetails: func,
};
