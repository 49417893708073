import { string, number, bool } from 'prop-types';

export default function AddThousandSeparator({
  value,
  prefix,
  suffix,
  showPlusSign,
}) {
  const handleThousandSeperator = () => {
    if (value !== null && value !== 0 && value !== undefined) {
      value = Number(value.toFixed(2));
      return `${value < 0 ? '-' : showPlusSign ? '+' : ''}${prefix || ''}${value
        .toString()
        .replace('-', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${suffix || ''}`;
    }
    return 0.0;
  };

  /* ********** main return statement of this component ********** */
  return handleThousandSeperator();
}

AddThousandSeparator.defaultProps = {
  prefix: '',
  suffix: '',
  value: null,
  showPlusSign: false,
};

AddThousandSeparator.propTypes = {
  prefix: string,
  suffix: string,
  value: number,
  showPlusSign: bool,
};
