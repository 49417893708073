/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef } from 'react';

import dayjs from 'dayjs';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { arrayOf, bool, shape, string } from 'prop-types';

import {
  currencyAxis,
  customReportGraphColorSet,
  numberAxis,
  percentageAxis,
  customReportGraphTooltipNames as tooltipNames,
} from '../../../../constants';

am4core.useTheme(am4themes_dataviz);
am4core.color('red');

const _ = require('lodash');

export default function SaleAdDspPerformanceGraph({
  chartId,
  chartData,
  currencySymbol,
  selectedMetrics,
  selectedDF,
  showPrevoisPoint,
  dateFrequency,
  currentEndDate,
}) {
  const chart = useRef(null);
  // calculate past and future months
  const getYesterdayOfCurrentDate = (date) => {
    date.setDate(date?.getDate() - 1);

    return date;
  };

  // generate chart data with date range
  const chartDataWithDateRange = useCallback(
    (graphData) => {
      const graphDataNewLength = graphData?.length;
      const tempGraphData = [];
      const lastIndex = graphDataNewLength - 1;

      if (graphDataNewLength > 0) {
        graphData.map((item, index) => {
          let yesterday = null;
          const startDate = item?.date
            ? dayjs(new Date(item?.date)).format('MMM DD, YYYY')
            : null;

          if (index < lastIndex) {
            const getDate = graphData?.at(index + 1)?.date;
            if (getDate) {
              yesterday = dayjs(
                getYesterdayOfCurrentDate(new Date(getDate)),
              ).format('MMM DD, YYYY');
            } else {
              yesterday = dayjs(new Date(currentEndDate)).format(
                'MMM DD, YYYY',
              );
            }
          } else if (index === lastIndex) {
            const getDate = graphData?.at(lastIndex)?.date;
            if (getDate) {
              yesterday = dayjs(new Date(currentEndDate)).format(
                'MMM DD, YYYY',
              );
            } else {
              yesterday = startDate;
            }
          }

          tempGraphData.push({
            ...item,
            dateRange: {
              startDate,
              endDate: yesterday,
            },
          });

          return tempGraphData;
        });
      }

      return tempGraphData;
    },
    [currentEndDate],
  );

  useEffect(() => {
    // create value axis function
    function createValueAxis(axisColor) {
      const localValueAxis = chart.current.yAxes.push(
        new am4charts.ValueAxis(),
      );
      localValueAxis.renderer.grid.template.disabled = true;
      localValueAxis.cursorTooltipEnabled = false;
      localValueAxis.renderer.baseGrid.disabled = true;
      localValueAxis.numberFormatter = new am4core.NumberFormatter();
      localValueAxis.numberFormatter.numberFormat = `#.#a`;
      localValueAxis.extraMax = 0.005;
      localValueAxis.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: 'K' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
      ];
      localValueAxis.numberFormatter.smallNumberPrefixes = [];
      localValueAxis.stroke = am4core.color(axisColor);
      localValueAxis.strokeOpacity = 0.5;
      return localValueAxis;
    }
    // function to generate complete tooltip html
    function generateToolTipHTML(dateRange, tooltipValue) {
      let html = '';
      if (dateFrequency === 'weekly') {
        html = `<div style="font-size: 14px; font-family: Noah-Medium; padding:5px 0">
          From - ${dateRange?.startDate} To - ${dateRange?.endDate}
        </div>
        ${tooltipValue}`;
      } else {
        html = tooltipValue;
      }

      return html;
    }

    function renderTooltip(name, color, value, currency, percent, formatter) {
      const tooltipText = `<ul style="padding:0; margin: 5px 0; max-width: 280px;">
      <li style="display: inline-block;">
        <div style="background-color: ${color};
        border: 1px solid white;
        border-radius: ${name === 'Previous' ? '2px' : '100%'};
        width: 10px;
        height: 10px;" />
      </li>
      <li style="display: inline-block;">
        <div style="color: #f4f6fc;
        text-transform: uppercase;
        font-size: 11px;
        padding-left: 5px;">${name} </div>
      </li>
      <li style="display: inline-block; float: right; margin-left: 25px;">
        <div style=" color: white;
        font-size: 16px; text-align: right;
       
        ">${currency !== null ? currency : ''}${
        formatter !== null
          ? `{${value}.formatNumber('${formatter}')}`
          : `{${value}}`
      }${percent !== null ? percent : ''}
      </div>
      </li>
    </ul>
  
    `;

      return tooltipText;
    }

    function bindValueAxisFormatter(item) {
      let format = '';
      if (currencyAxis.includes(item)) {
        format = `${currencySymbol}#.#a`;
      } else if (numberAxis.includes(item)) {
        format = `#.#a`;
      } else if (percentageAxis.includes(item)) {
        format = `#.#'%'`;
      }
      return format;
    }

    chart.current = am4core.create(chartId, am4charts.XYChart);
    chart.current.data = chartDataWithDateRange(chartData);
    chart.current.paddingRight = 20;
    chart.current.logo.disabled = true; // disable amchart logo
    chart.current.zoomOutButton.disabled = true; // disabled zoomout button

    // render X axis
    const dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.labels.template.location = 0.5;
    dateAxis.dy = 10;
    dateAxis.gridIntervals.setAll([
      { timeUnit: 'day', count: 1 },
      { timeUnit: 'day', count: 5 },
      { timeUnit: 'day', count: 10 },
      { timeUnit: 'day', count: 15 },
      { timeUnit: 'day', count: 20 },
      { timeUnit: 'week', count: 1 },
      { timeUnit: 'week', count: 5 },
      { timeUnit: 'week', count: 10 },
      { timeUnit: 'month', count: 1 },
      { timeUnit: 'month', count: 2 },
      { timeUnit: 'month', count: 3 },
      { timeUnit: 'month', count: 6 },
    ]);
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.periodChangeDateFormats.setKey('month', 'MMM');

    // create object of first value axis
    let valueAxis = createValueAxis();

    // Add cursor
    chart.current.cursor = new am4charts.XYCursor();
    chart.current.cursor.lineY.disabled = true;
    chart.current.cursor.lineX.disabled = true;
    chart.current.cursor.behavior = 'none';

    const selectedMatricsFlag = _.size(selectedMetrics) <= 2;

    if (selectedMatricsFlag) {
      // create object of 2nd value axis
      const snapToSeries = [];
      let tooltipValue = '';

      // loop for generate tooltip
      _.keys(selectedMetrics).map((item, index) => {
        const currentLabel = `${item}CurrentLabel`;
        const previousLabel = `${item}PreviousLabel`;
        const colorCode = customReportGraphColorSet[index];

        if (selectedDF === 'custom' || !showPrevoisPoint) {
          if (currencyAxis.includes(item)) {
            tooltipValue = `${tooltipValue} ${renderTooltip(
              tooltipNames[item],
              colorCode,
              currentLabel,
              currencySymbol,
              null,
              '#,###',
            )}`;
          } else if (numberAxis.includes(item)) {
            tooltipValue = `${tooltipValue} ${renderTooltip(
              tooltipNames[item],
              colorCode,
              currentLabel,
              null,
              null,
              '#,###',
            )}`;
          } else if (percentageAxis.includes(item)) {
            tooltipValue = `${tooltipValue} ${renderTooltip(
              tooltipNames[item],
              colorCode,
              currentLabel,
              null,
              '%',
              null,
            )}`;
          }
        } else {
          tooltipValue = `${tooltipValue} ${tooltipNames[item]}`;
          if (currencyAxis.includes(item)) {
            tooltipValue = `${tooltipValue} ${renderTooltip(
              'Recent',
              colorCode,
              currentLabel,
              currencySymbol,
              null,
              '#,###',
            )}`;

            tooltipValue = `${tooltipValue} ${renderTooltip(
              'Previous',
              colorCode,
              previousLabel,
              currencySymbol,
              null,
              '#,###',
            )}`;
          } else if (numberAxis.includes(item)) {
            tooltipValue = `${tooltipValue} ${renderTooltip(
              'Recent',
              colorCode,
              currentLabel,
              null,
              null,
              '#,###',
            )}`;

            tooltipValue = `${tooltipValue} ${renderTooltip(
              'Previous',
              colorCode,
              previousLabel,
              null,
              null,
              '#,###',
            )}`;
          } else if (percentageAxis.includes(item)) {
            tooltipValue = `${tooltipValue} ${renderTooltip(
              'Recent',
              colorCode,
              currentLabel,
              null,
              '%',
              null,
            )}`;

            tooltipValue = `${tooltipValue} ${renderTooltip(
              'Previous',
              colorCode,
              previousLabel,
              null,
              '%',
              null,
            )}`;
          }
        }
        return '';
      });

      _.keys(selectedMetrics).map((item, index) => {
        const series = chart.current.series.push(new am4charts.LineSeries());
        let series2 = null;
        if (showPrevoisPoint) {
          series2 = chart.current.series.push(new am4charts.LineSeries());
        }

        const currentValue = `${item}Current`;
        const previousValue = `${item}Previous`;
        const seriesName = `${item}Series`;
        const colorCode = customReportGraphColorSet[index];

        valueAxis = createValueAxis(colorCode);
        series.yAxis = valueAxis;
        if (showPrevoisPoint) {
          series2.yAxis = valueAxis;
        }
        valueAxis.numberFormatter.numberFormat = bindValueAxisFormatter(item);

        if (index === 1) {
          valueAxis.renderer.opposite = true;
        }

        series.dataFields.valueY = currentValue;
        series.dataFields.dateX = 'date';
        series.name = seriesName;
        series.strokeWidth = 2;
        series.stroke = am4core.color(colorCode);
        series.fill = am4core.color('#2e384d');
        series.adapter.add('tooltipHTML', (text, target) => {
          const dateRange = target?._tooltipDataItem?._dataContext?.dateRange;
          return generateToolTipHTML(dateRange, tooltipValue);
        });

        const circleBullet = series.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.fill = am4core.color(colorCode);
        circleBullet.circle.strokeWidth = 1;
        circleBullet.circle.radius = 5;

        if (showPrevoisPoint) {
          series2.dataFields.valueY = previousValue;
          series2.name = previousValue;
          series2.dataFields.dateX = 'date';
          series2.strokeWidth = 2;
          series2.stroke = am4core.color(colorCode);
          series2.fill = am4core.color('#2e384d');
          series2.strokeDasharray = '8,4';
          series2.adapter.add('tooltipHTML', (text, target) => {
            const dateRange =
              target?._tooltipDataItem?._dataContext?.previousDateRange;
            return generateToolTipHTML(dateRange, tooltipValue);
          });

          const bullet = series2.bullets.push(new am4charts.Bullet());
          const square = bullet.createChild(am4core.Rectangle);
          square.fill = am4core.color(colorCode);
          square.width = 8;
          square.height = 8;
          square.horizontalCenter = 'middle';
          square.verticalCenter = 'middle';
        }

        snapToSeries.push(series);
        if (showPrevoisPoint) {
          snapToSeries.push(series2);
        }
        return '';
      });

      chart.current.cursor.snapToSeries = snapToSeries;
    } else {
      // else part- for multiple metrics selected
      const snapToSeries = [];
      let tooltipValue = '';

      // loop for genearate tooltip
      _.keys(selectedMetrics).map((item, index) => {
        const currentLabel = `${item}CurrentLabel`;
        const colorCode = customReportGraphColorSet[index];

        if (currencyAxis.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            colorCode,
            currentLabel,
            currencySymbol,
            null,
            '#,###',
          )}`;
        } else if (numberAxis.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            colorCode,
            currentLabel,
            null,
            null,
            '#,###',
          )}`;
        } else if (percentageAxis.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            colorCode,
            currentLabel,
            null,
            '%',
            null,
          )}`;
        }
        return '';
      });

      _.keys(selectedMetrics).map((item, index) => {
        const series = chart.current.series.push(new am4charts.LineSeries());
        const colorCode = customReportGraphColorSet[index];

        valueAxis = createValueAxis(colorCode);
        series.yAxis = valueAxis;
        valueAxis.numberFormatter.numberFormat = bindValueAxisFormatter(item);
        if (index === 1 || index === 3) {
          valueAxis.renderer.opposite = true;
        }

        series.dataFields.valueY = `${item}Current`;
        series.dataFields.dateX = 'date';
        series.name = `${item}Series`;
        series.strokeWidth = 2;
        series.stroke = am4core.color(colorCode);
        series.fill = am4core.color('#2e384d');
        series.adapter.add('tooltipHTML', (text, target) => {
          const dateRange = target?._tooltipDataItem?._dataContext?.dateRange;
          return generateToolTipHTML(dateRange, tooltipValue);
        });

        const circleBullet = series.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.fill = am4core.color(colorCode);
        circleBullet.circle.strokeWidth = 1;
        circleBullet.circle.radius = 5;

        snapToSeries.push(series);
        return '';
      });

      chart.current.cursor.snapToSeries = snapToSeries;
    }
    return () => chart.current && chart.current.dispose();
  }, [
    chartData,
    chartDataWithDateRange,
    chartId,
    currencySymbol,
    dateFrequency,
    selectedDF,
    selectedMetrics,
    showPrevoisPoint,
  ]);

  return <div id={chartId} style={{ width: '100%', height: '510px' }} />;
}

SaleAdDspPerformanceGraph.defaultProps = {
  dateFrequency: '',
  currentEndDate: '',
  showPrevoisPoint: false,
  chartData: [],
  currencySymbol: '',
  selectedMetrics: {},
  selectedDF: '',
};

SaleAdDspPerformanceGraph.propTypes = {
  dateFrequency: string,
  currentEndDate: string,
  showPrevoisPoint: bool,
  chartId: string.isRequired,
  chartData: arrayOf(shape({})),
  currencySymbol: string,
  selectedMetrics: shape({}),
  selectedDF: string,
};
