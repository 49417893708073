import React, { useState, useEffect, useCallback } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { func, shape } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../../../../theme/Theme';
import useFormatNumber from '../../../../hooks/useFormatNumber';
import { getCancellationBillingData } from '../../../../api';
import {
  invoiceTooltipMessages,
  pausingRequestInvoiceDetails,
  pausingRequestInvoiceTypes,
  pendingAgreementInvoiceDetails,
} from '../../../../constants';
import {
  AllocateBar,
  HeaderDownloadFuntionality,
  ModalBox,
  PageLoader,
} from '../../../../common';
import { InfoIcons } from '../../../../theme/images';

export default function PendingAgreementModal({
  setShowModal,
  selectedAgreement,
}) {
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const isCancellationRquest = [
    'pending for cancellation',
    'pending cancellation approval',
    'cancel',
  ].includes(selectedAgreement?.contractStatus);

  const invoiceTypeLabel = {
    'rev share': 'Last Rev Share Date:',
    retainer: 'Last Retainer Date:',
    'dsp service': 'Last DSP Date:',
    'amc service': 'Last AMC Date:',
  };

  const cancellationBillingData = useCallback(() => {
    if (selectedAgreement?.id) {
      getCancellationBillingData(selectedAgreement?.id).then((res) => {
        if (res?.status === 200) {
          setInvoiceDetails(res?.data);
          // PDV-8928 - point no. 5 - show Is ending early - YES/NO by default
          // if (!res?.data?.is_off_boarded) {
          //   const checkEarly = pendingAgreementInvoiceDetails.filter(
          //     (op) =>
          //       op.key === 'ending_early_label' || op.key === 'is_ending_early',
          //   );
          //   if (checkEarly.length) {
          //     pendingAgreementInvoiceDetails.pop();
          //     pendingAgreementInvoiceDetails.pop();
          //   }
          // }
          if (res?.data?.is_off_boarded) {
            const checkServiceDate = pendingAgreementInvoiceDetails.filter(
              (op) => op.key === 'last_service_date',
            );
            if (checkServiceDate?.length === 0) {
              pendingAgreementInvoiceDetails.push(
                {
                  key: 'last_date_label',
                  property: 'col-6 mb-2',
                  subProperty: 'normal-text-black text-bold',
                  label: 'Last Service Date:',
                },
                {
                  key: 'last_service_date',
                  property: 'col-6 mb-2 text-right',
                  subProperty: 'normal-text-black text-bold out-door',
                  type: 'date',
                },
              );
            }
          } else {
            const checkServiceDate = pendingAgreementInvoiceDetails.filter(
              (op) =>
                op?.key === 'last_service_date' ||
                op?.key === 'last_date_label',
            );
            if (checkServiceDate?.length > 0) {
              pendingAgreementInvoiceDetails.pop();
              pendingAgreementInvoiceDetails.pop();
            }
          }
        }
        setIsLoading({ loader: false, type: 'page' });
      });
    }
  }, [selectedAgreement.id]);

  useEffect(() => {
    cancellationBillingData();
  }, [cancellationBillingData]);

  // get tooltip DataTip
  const getTooltipDataTip = (tooltipMessage) => {
    return `<div style="max-width:300px">${tooltipMessage}</div>`;
  };

  // display react tooltip with info icon
  const displayTooltipWithInfoIcon = (dataTip, tooltipId, place) => {
    return (
      <>
        <img
          width="12px"
          src={InfoIcons}
          className="ml-1 cursor"
          alt={tooltipId}
          style={{
            verticalAlign: 'middle',
            fontWeight: '300',
          }}
          data-tip={getTooltipDataTip(dataTip)}
          data-for={tooltipId}
        />
        <ReactTooltip
          className="cancel-agr-tooltip"
          id={tooltipId}
          aria-haspopup="true"
          place={place}
          effect="solid"
          insecure
          html
        />
      </>
    );
  };

  const renderCancellationRequestModal = (formatNumber) => {
    return (
      <>
        <HeaderDownloadFuntionality>
          <FontAwesomeIcon
            icon="fa-light fa-times"
            style={{ color: '#000', fontSize: '20px' }}
            className="float-right cursor px-3"
            onClick={() => setShowModal(false)}
          />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="header-title"> Cancellation Request</div>
                <small className="capitalize">
                  {selectedAgreement?.type} Agreement
                </small>
              </div>
              {invoiceDetails?.is_off_boarded ? (
                <div className="col-12">
                  <br />
                  <small className="capitalize" style={{ color: Theme.orange }}>
                    This was the only ACTIVE agreement left for this Brand
                    Partner. Hence it will automatically offboard the Brand
                    Partner.
                  </small>
                </div>
              ) : null}
            </div>
          </div>
        </HeaderDownloadFuntionality>
        <InvoiceBody>
          <div className="invoice-details">
            <div className="row mt-4">
              {pendingAgreementInvoiceDetails.map((item) => (
                <div className={item?.property} key={item?.id}>
                  <div className={item?.subProperty}>
                    {item?.type === 'date'
                      ? invoiceDetails?.[item?.key]
                        ? dayjs(invoiceDetails?.[item?.key]).format(
                            'MM/DD/YYYY',
                          )
                        : dayjs(selectedAgreement?.endDate).format('MM/DD/YYYY')
                      : item?.key === 'requestor'
                      ? `${invoiceDetails?.[item?.key]?.[0]} ${
                          invoiceDetails?.[item?.key]?.[1]
                        }`
                      : invoiceDetails?.[item?.key] === true
                      ? 'Yes'
                      : invoiceDetails?.[item?.key] === false
                      ? 'No'
                      : invoiceDetails?.[item?.key] || item?.label || '-'}
                  </div>
                </div>
              ))}

              {invoiceDetails?.cancelling_note ? (
                <AllocateBar className="mt-2 mb-3 mx-2 creativeProject-allocate-bar">
                  <div className="remaing-label">
                    {invoiceDetails?.cancelling_note}
                  </div>
                </AllocateBar>
              ) : null}
            </div>
          </div>
        </InvoiceBody>
        <div className="my-3 mx-4" style={{ backgroundColor: '#f4f6fc' }}>
          <InvoiceBody>
            <div className="invoice-details">
              <div className="row mt-4">
                <div className="col-12 mt-3 mb-3">
                  <div className="label">BILLING DATES</div>
                </div>
                {invoiceDetails?.invoice_type?.map((item) => {
                  return (
                    <React.Fragment key={item?.id}>
                      <div className="col-6 mb-2">
                        <div className="normal-text-black text-bold">
                          {invoiceTypeLabel?.[item?.invoice_type] || ''}
                        </div>
                      </div>
                      <div className="col-6 mb-2 text-right">
                        <div className="normal-text-black">
                          {item?.end_date
                            ? dayjs(item?.end_date).format('MM/DD/YYYY')
                            : '-'}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="horizontal-line-dotted mt-2 mb-3 " />
            </div>
          </InvoiceBody>
          <InvoiceBody>
            <div className="invoice-details">
              <div className="row">
                <div className="col-12 mt-1 mb-3">
                  <div className="label">BILLING AMOUNTS</div>
                </div>
                {invoiceDetails?.invoice_type?.map((item) => {
                  return (
                    <React.Fragment key={item?.id}>
                      <div className="col-6 mb-2">
                        <div className="normal-text-black text-bold">
                          {item?.invoice_type === 'rev share'
                            ? 'Rev Share:'
                            : item?.invoice_type === 'retainer'
                            ? 'Retainer:'
                            : item?.invoice_type === 'dsp service'
                            ? 'DSP:'
                            : item?.invoice_type === 'amc service'
                            ? 'AMC:'
                            : ''}
                        </div>
                      </div>
                      <div className="col-6 mb-2 text-right">
                        <div className="normal-text-black">
                          {item?.invoice_type === 'rev share'
                            ? 'Dependent on Sales'
                            : item?.invoice_amount
                            ? formatNumber(item?.invoice_amount, item?.currency)
                            : '-'}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </InvoiceBody>
        </div>
      </>
    );
  };

  const renderPauseRequestModal = (formatNumber) => {
    return (
      <>
        <HeaderDownloadFuntionality>
          <FontAwesomeIcon
            icon="fa-light fa-times"
            style={{ color: '#000', fontSize: '20px' }}
            className="float-right cursor px-3"
            onClick={() => setShowModal(false)}
          />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="header-title"> Pausing Request</div>
                <small className="capitalize">
                  {selectedAgreement?.type} Agreement
                </small>
              </div>
            </div>
          </div>
        </HeaderDownloadFuntionality>
        {/* render Requestor, request date and invoice type */}
        <div
          style={{
            maxHeight: '650px',
            overflowX: 'auto',
            overflowY: 'scroll',
          }}
        >
          <InvoiceBody>
            <div className="invoice-details">
              <div className="row mt-4">
                {pausingRequestInvoiceDetails.map((item) => (
                  <div className={item?.property} key={item?.id}>
                    <div className={item?.subProperty}>
                      {item?.type === 'date'
                        ? invoiceDetails?.[item?.key]
                          ? dayjs(invoiceDetails?.[item?.key]).format(
                              'MM/DD/YYYY',
                            )
                          : dayjs(selectedAgreement?.endDate).format(
                              'MM/DD/YYYY',
                            )
                        : item?.key === 'requestor'
                        ? `${invoiceDetails?.[item?.key]?.[0]} ${
                            invoiceDetails?.[item?.key]?.[1]
                          }`
                        : invoiceDetails?.[item?.key] || item?.label || '-'}
                    </div>
                  </div>
                ))}
              </div>

              {/* render invoice type */}
              <div className="row mt-4">
                <div className="col-12 mt-3 mb-3">
                  <div className="label">Invoice Types</div>
                </div>
                {pausingRequestInvoiceTypes?.map((item) => {
                  const invoiceTypeData = invoiceDetails?.invoice_type.filter(
                    (ele) => ele?.invoice_type === item?.dbKey,
                  );

                  if (invoiceTypeData?.length)
                    return (
                      <React.Fragment key={item?.id}>
                        <div className="col-6 mb-2">
                          <div className="normal-text-black text-bold">
                            {`${item?.label} Paused? (Y/N):`}
                            {/* https://bbe.atlassian.net/browse/PDV-9438 */}
                            {invoiceTypeData[0]?.invoice_type === 'dsp service'
                              ? displayTooltipWithInfoIcon(
                                  invoiceTooltipMessages?.dspStandardInfo,
                                  'dspServiceInfo',
                                  'top',
                                )
                              : null}
                          </div>
                        </div>
                        <div className="col-6 mb-2 text-right">
                          <div className="normal-text-black">
                            {invoiceTypeData[0]?.is_pause ? 'Yes' : 'No'}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  return null;
                })}
              </div>
              <div className="horizontal-line-dotted mt-2 mb-3 " />
            </div>
          </InvoiceBody>

          {/* render last invoice and first invoice details */}
          <div className="my-3 mx-4" style={{ backgroundColor: '#f4f6fc' }}>
            <InvoiceBody>
              <div className="invoice-details">
                {/* render last invoice details */}
                <div className="row mt-4">
                  <div className="col-12 mt-3 mb-3">
                    <div className="label">
                      LAST INVOICE BEFORE PAUSING PERIOD
                    </div>
                  </div>
                  {pausingRequestInvoiceTypes?.map((item) => {
                    const lastInvoiceData = invoiceDetails?.invoice_type.filter(
                      (ele) => ele?.invoice_type === item?.dbKey,
                    );

                    if (lastInvoiceData?.length)
                      return (
                        <React.Fragment key={item?.id}>
                          <div className="col-6 mb-2">
                            <div className="normal-text-black text-bold">
                              {`${item?.label}:`}
                            </div>
                          </div>
                          <div className="col-6 mb-2 text-right">
                            <div className="normal-text-black">
                              {lastInvoiceData[0]?.start_date !== null
                                ? dayjs(lastInvoiceData[0]?.start_date).format(
                                    'MM/DD/YYYY',
                                  )
                                : 'N/A'}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    return null;
                  })}
                </div>

                <div className="horizontal-line-dotted mt-2 mb-2 " />

                {/* render first invoice details */}
                <div className="row mt-2">
                  <div className="col-12 mt-3 mb-3">
                    <div className="label">
                      FIRST INVOICE AFTER PAUSING PERIOD
                    </div>
                  </div>
                  {pausingRequestInvoiceTypes?.map((item) => {
                    const firstInvoiceData =
                      invoiceDetails?.invoice_type.filter(
                        (ele) => ele?.invoice_type === item?.dbKey,
                      );
                    if (firstInvoiceData?.length)
                      return (
                        <React.Fragment key={item?.id}>
                          <div className="col-6 mb-2">
                            <div className="normal-text-black text-bold">
                              {`${item?.label}:`}
                            </div>
                          </div>
                          <div className="col-6 mb-2 text-right">
                            <div className="normal-text-black">
                              {firstInvoiceData[0]?.end_date !== null
                                ? dayjs(firstInvoiceData[0]?.end_date).format(
                                    'MM/DD/YYYY',
                                  )
                                : 'N/A'}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    return null;
                  })}
                </div>
              </div>
            </InvoiceBody>
          </div>
          {/* render billing details */}
          <div
            className="my-3 mx-4 mt-2"
            style={{ backgroundColor: '#f4f6fc' }}
          >
            <InvoiceBody>
              <div className="invoice-details">
                <div className="row">
                  <div className="col-12 mt-1 mb-3">
                    <div className="label">BILLING AMOUNTS</div>
                  </div>
                  {pausingRequestInvoiceTypes?.map((item) => {
                    const invoiceAmountData =
                      invoiceDetails?.invoice_type.filter(
                        (ele) => ele?.invoice_type === item?.dbKey,
                      );
                    if (invoiceAmountData?.length)
                      return (
                        <React.Fragment key={item?.id}>
                          <div className="col-6 mb-2">
                            <div className="normal-text-black text-bold">
                              {`${item?.label}:`}
                            </div>
                          </div>
                          <div className="col-6 mb-2 text-right">
                            <div className="normal-text-black">
                              {invoiceAmountData[0]?.invoice_type ===
                              'rev share'
                                ? 'Dependent on Sales'
                                : formatNumber(
                                    invoiceAmountData[0]?.invoice_amount,
                                    invoiceAmountData[0]?.currency,
                                  )}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    return null;
                  })}
                </div>
              </div>
            </InvoiceBody>
          </div>
        </div>
      </>
    );
  };
  return (
    <ModalBox>
      {isLoading.loader && isLoading.type === 'page' ? (
        <PageLoader
          component="Notes-modal-loader"
          color="#FF5933"
          type="page"
        />
      ) : (
        <>
          {isCancellationRquest
            ? renderCancellationRequestModal(useFormatNumber)
            : renderPauseRequestModal(useFormatNumber)}
        </>
      )}
    </ModalBox>
  );
}

PendingAgreementModal.defaultProps = {
  selectedAgreement: {},
  setShowModal: () => {},
};

PendingAgreementModal.propTypes = {
  selectedAgreement: shape({}),
  setShowModal: func,
};

const InvoiceBody = styled.div`
  .invoice-details {
    padding: 0 30px;
  }

  .out-door {
    color: ${Theme.green};
  }
  .capsule-status {
    border-radius: 12px;
    font-family: ${Theme.baseMediumFontFamily};
    font-size: 10px;
    width: fit-content;
    padding: 3px 8px;

    &.small-text {
      font-size: 8px;
    }
    &.service-type {
      background-color: ${Theme.green};
      color: ${Theme.white};
      vertical-align: middle;
    }
  }
  .sub-text {
    color: ${Theme.gray35};
    font-size: ${Theme.extraSmall};
  }

  .total-subtotal {
    font-size: ${Theme.title};
    color: ${Theme.black};
  }
  .table-container {
    max-height: 45vh;
    padding-bottom: 70px;
    overflow-x: auto;
  }
`;
