import React from 'react';

import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import { bool, func, shape, string, instanceOf } from 'prop-types';

import ModalBox from './ModalBox';
import Button from './Button';
import CustomDateRange from './MonthPicket';
import { CloseIcon } from '../theme/images';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '420px ',
    width: '100% ',
    minHeight: '390px',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const currentDate = new Date();

const CustomMonthRangeModal = ({
  id,
  className,
  title,
  isOpen,
  minDate,
  maxDate,
  startMonth,
  endMonth,
  onDateChange,
  onClick,
  onApply,
  style,
  helperText,
  selected = true,
}) => {
  return (
    <Modal
      id={id}
      isOpen={isOpen}
      style={{ ...customStyles, ...style }}
      ariaHideApp={false}
      contentLabel="Edit modal"
    >
      <img
        src={CloseIcon}
        alt="close"
        className="float-right cursor cross-icon"
        onClick={onClick}
        role="presentation"
      />
      <ModalBox>
        <div className="modal-body">
          <h4 className="mb-3">{title}</h4>
          <CustomDateRange id={id} className={className}>
            <div className={`text-left ${className}`}>
              <DatePicker
                className="red-border"
                selected={!selected ? selected : startMonth}
                onChange={(date) => onDateChange(date)}
                maxDate={maxDate || null}
                minDate={minDate || new Date('2021-01-01')}
                startDate={startMonth}
                endDate={endMonth}
                selectsRange
                inline
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
              {helperText ? (
                <div style={{ color: 'red', textAlign: 'center' }}>
                  {helperText}
                </div>
              ) : null}
            </div>
          </CustomDateRange>
          <div className="text-center mt-3">
            <Button
              onClick={onApply}
              type="button"
              className="btn-primary on-boarding   w-100"
            >
              Apply
            </Button>
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
};

export default CustomMonthRangeModal;

CustomMonthRangeModal.defaultProps = {
  title: 'Select Month Range',
  id: '',
  className: '',
  helperText: '',
  isOpen: false,
  minDate: new Date('2021-01-01'),
  maxDate: currentDate,
  startMonth: currentDate,
  endMonth: currentDate,
  selected: true,
  style: {},
  onClick: () => {},
  onDateChange: () => {},
  onApply: () => {},
};

CustomMonthRangeModal.propTypes = {
  id: string,
  className: string,
  title: string,
  helperText: string,
  isOpen: bool,
  selected: bool,
  onClick: func,
  onDateChange: func,
  onApply: func,
  style: shape({}),
  minDate: instanceOf(Date),
  maxDate: instanceOf(Date),
  startMonth: instanceOf(Date),
  endMonth: instanceOf(Date),
};
