import React from 'react';

import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Collapse } from 'react-collapse';

import Theme from '../../../../../../theme/Theme';
import useValueWithChangeAndPercent from '../../../../../../hooks/useValueWithChangeAndPercent';
import { dspAsinTableConstant } from '../../../../../../constants';

import {
  CommonPagination,
  DesktopTable,
  NoData,
  PageLoader,
  VisibleScrollableTable,
} from '../../../../../../common';

function DspCampaignPerformanceTable({
  isCustom,
  campaignAsinLoader,
  campaignCollapseLoader,
  isTablet,
  handleSortingEvents,
  orderBy,
  orderByKey,
  campaignListData,
  campaignAsinData,
  currencySymbol,
  asinMessage,
  setCampaignListData,
  campaignPageCount,
  campaignPageNumber,
  campaignAsinPageCount,
  campaignAsinPageNumber,
  handleCampaignPaginationEvents,
  handleCampaignAsinPaginationEvents,
  handleCollapseClick,
}) {
  // display sortable up and down arrow icon
  const displaySortableUpDownArrow = (key) => {
    return (
      <FontAwesomeIcon
        className="ml-2 cursor"
        color={orderBy === key ? Theme.orange : Theme.gray40}
        icon={`far ${
          orderByKey === `-${key}` ? 'fa-chevron-up' : 'fa-chevron-down'
        }`}
        style={{
          fontSize: '12px',
          verticalAlign: isTablet ? 'bottom' : 'middle',
        }}
      />
    );
  };

  const changeCollapseFlag = (campaignId, flag = false) => {
    const localDspCampaignData = [...campaignListData];
    localDspCampaignData.forEach((value, index) => {
      if (value?.id === campaignId) {
        localDspCampaignData[index].isOpen = flag;
      } else {
        localDspCampaignData[index].isOpen = false;
      }
    });

    return localDspCampaignData;
  };

  // display collapse Icon
  const displayCollapseIcon = (campaignId, top, right, left) => {
    const localCampaign = campaignListData?.find(
      (item) => item?.id === campaignId,
    );
    return (
      <FontAwesomeIcon
        icon={`${
          localCampaign?.isOpen ? 'fa-regular fa-minus' : 'fa-regular fa-plus'
        }`}
        style={{
          color: Theme.orange,
          fontSize: Theme.small,
          position: 'absolute',
          top,
          right,
          left,
        }}
        onClick={() => {
          setCampaignListData(
            changeCollapseFlag(campaignId, !!localCampaign?.isOpen),
          );
        }}
      />
    );
  };

  // display react tooltip
  const displayReactTooltip = (id, place) => {
    return (
      <ReactTooltip
        className="asian-info-icon"
        id={id}
        aria-haspopup="true"
        place={place}
        effect="solid"
        style={{ maxWidth: '300px' }}
        html
      />
    );
  };

  // display product name with tooltip
  const displayProductNameWithTooltip = (productName, index, link) => {
    const textLimit = 50;
    return (
      <div
        className={isTablet ? 'pr-3' : 'pr-4'}
        style={{ position: 'relative' }}
      >
        <div
          className="text-ellipsis text-ellipsis-200"
          data-tip={`<div style="max-width:500px">${productName}</div>`}
          data-for={`product${index}`}
          data-cy="BT-dsp-asinData-productName"
        >
          {productName?.length > textLimit
            ? `${productName?.slice(0, textLimit)}...`
            : productName}
        </div>
        {displayReactTooltip(`product${index}`, 'top')}
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          data-cy="BT-dsp-asinData-pdpLink"
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <FontAwesomeIcon
            className="ml-2 cursor"
            color={Theme.orange}
            icon="far fa-arrow-up-right-from-square"
            style={{ fontSize: '14px' }}
          />
        </a>
      </div>
    );
  };

  // display campaign collapse header row
  const displayCampaignCollpaseHead = (campaignDetails, index) => {
    const marketplace = campaignDetails?.marketpalceValue || '';
    const campaignName = campaignDetails?.campaign || '';
    const asinCount = campaignDetails?.numberOfAsins || '';

    return (
      <tr
        className="cursor bg-orange-light"
        role="presentation"
        type="button"
        data-cy={`BT-dsp-campaignCollapse-${index}`}
        onClick={() => {
          setCampaignListData(
            changeCollapseFlag(campaignDetails?.id, !campaignDetails?.isOpen),
          );
          handleCollapseClick(campaignDetails?.id);
        }}
      >
        <td
          key={`${campaignDetails?.id}${marketplace}`}
          className="sticky-collapse py-2 bg-orange-light border-none"
          colSpan="5"
        >
          <span style={{ paddingRight: '35px' }} role="presentation">
            {displayCollapseIcon(campaignDetails?.id, '15px', null, '20px')}
          </span>
          <span data-cy={`BT-dsp-campaignCollapse-${index}-campaignLabel`}>
            {marketplace ? `${marketplace} | ` : ''}
            {campaignName ? `${campaignName} | ` : ''}
            {asinCount || ''}
          </span>
        </td>
        <td colSpan="2" className="border-none" />
      </tr>
    );
  };

  // display campaign collapse Body row
  const displayCampaignCollpaseBody = (
    campaignDetails,
    numberFormatter,
    tableId,
    campaignIndex,
  ) => {
    // Note --> Width of <td> should be match with the width mentioned in dspAsinTableConstant for column alignment
    return (
      <tr>
        <td colSpan="7" style={{ padding: '0' }}>
          <Collapse isOpened={campaignDetails?.isOpen}>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
              }}
            >
              <tbody className="collapse" id="collapseExample">
                {campaignAsinLoader ? (
                  <tr>
                    <td
                      colSpan="11"
                      style={{
                        padding: '0',
                      }}
                    >
                      <div
                        style={{
                          width: document.getElementById(tableId)?.offsetWidth,
                          position: 'sticky',
                          left: '0',
                        }}
                      >
                        <PageLoader
                          component="performance-graph"
                          color={Theme.orange}
                          type="detail"
                          width={40}
                          height={40}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {campaignAsinData?.asinData?.length > 0
                      ? campaignAsinData?.asinData?.map((item, index) => {
                          return (
                            <tr
                              key={item?.asin}
                              data-cy={`BT-dsp-campaignCollapse-${campaignIndex}-asinData-${index}`}
                            >
                              <td width="13%" data-cy="BT-dsp-asinData-asinId">
                                {item?.asin}
                              </td>
                              <td width="18%">
                                {displayProductNameWithTooltip(
                                  item?.productName,
                                  index,
                                  item?.pdpLink,
                                )}
                              </td>
                              <td width="5%" />
                              <td
                                width="16%"
                                data-cy="BT-dsp-asinData-totalProductSales"
                              >
                                {numberFormatter(
                                  item?.totalProductSalesCurrent,
                                  item?.totalProductSalesChangeInValue,
                                  item?.totalProductSalesChangeInPercent,
                                  2,
                                  isCustom,
                                  currencySymbol,
                                  '',
                                )}
                              </td>
                              <td
                                width="16%"
                                data-cy="BT-dsp-asinData-ttlNewBrandPurchases"
                              >
                                {numberFormatter(
                                  item?.ttlNewBrandPurchasesCurrent,
                                  item?.ttlNewBrandPurchasesChangeInValue,
                                  item?.ttlNewBrandPurchasesChangeInPercent,
                                  2,
                                  isCustom,
                                  '',
                                  '%',
                                )}
                              </td>
                              <td
                                width="16%"
                                data-cy="BT-dsp-asinData-totalDetailPageViews"
                              >
                                {numberFormatter(
                                  item?.totalDetailPageViewsCurrent,
                                  item?.totalDetailPageViewsChangeInValue,
                                  item?.totalDetailPageViewsChangeInPercent,
                                  2,
                                  isCustom,
                                  '',
                                  '',
                                )}
                              </td>
                              <td
                                width="16%"
                                data-cy="BT-dsp-asinData-totalAddToCart"
                              >
                                {numberFormatter(
                                  item?.totalAddToCartCurrent,
                                  item?.totalAddToCartChangeInValue,
                                  item?.totalAddToCartChangeInPercent,
                                  2,
                                  isCustom,
                                  '',
                                  '',
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                    {/*  bind the TOTAL row */}
                    {Object.keys(campaignAsinData?.asinTotal)?.length > 0 ? (
                      <tr
                        key="dsp-total-row"
                        className="bg-green-light"
                        data-cy={`BT-dsp-campaignCollapse-${campaignIndex}-asinTotal`}
                      >
                        <td>
                          <div className="black-heading-title">
                            {campaignAsinData?.asinTotal?.asin}
                          </div>
                        </td>
                        <td />
                        <td />
                        <td
                          width="16%"
                          data-cy="BT-dsp-asinTotal-totalProductSales"
                        >
                          {numberFormatter(
                            campaignAsinData?.asinTotal
                              ?.totalProductSalesCurrent,
                            campaignAsinData?.asinTotal
                              ?.totalProductSalesChangeInValue,
                            campaignAsinData?.asinTotal
                              ?.totalProductSalesChangeInPercent,
                            2,
                            isCustom,
                            currencySymbol,
                            '',
                          )}
                        </td>
                        <td
                          width="16%"
                          data-cy="BT-dsp-asinTotal-ttlNewBrandPurchases"
                        >
                          {numberFormatter(
                            campaignAsinData?.asinTotal
                              ?.ttlNewBrandPurchasesCurrent,
                            campaignAsinData?.asinTotal
                              ?.ttlNewBrandPurchasesChangeInValue,
                            campaignAsinData?.asinTotal
                              ?.ttlNewBrandPurchasesChangeInPercent,
                            2,
                            isCustom,
                            '',
                            '%',
                          )}
                        </td>
                        <td
                          width="16%"
                          data-cy="BT-dsp-asinTotal-totalDetailPageViews"
                        >
                          {numberFormatter(
                            campaignAsinData?.asinTotal
                              ?.totalDetailPageViewsCurrent,
                            campaignAsinData?.asinTotal
                              ?.totalDetailPageViewsChangeInValue,
                            campaignAsinData?.asinTotal
                              ?.totalDetailPageViewsChangeInPercent,
                            2,
                            isCustom,
                            '',
                            '',
                          )}
                        </td>
                        <td
                          width="16%"
                          data-cy="BT-dsp-asinTotal-totalAddToCart"
                        >
                          {numberFormatter(
                            campaignAsinData?.asinTotal?.totalAddToCartCurrent,
                            campaignAsinData?.asinTotal
                              ?.totalAddToCartChangeInValue,
                            campaignAsinData?.asinTotal
                              ?.totalAddToCartChangeInPercent,
                            2,
                            isCustom,
                            '',
                            '',
                          )}
                        </td>
                      </tr>
                    ) : null}
                    {campaignAsinPageCount > 10 ? (
                      <tr>
                        <td
                          colSpan="11"
                          style={{
                            padding: '0',
                          }}
                        >
                          <div
                            style={{
                              width:
                                document.getElementById(tableId)?.offsetWidth,
                              position: 'sticky',
                              left: '0',
                            }}
                            data-cy={`BT-dsp-campaignCollapse-${campaignIndex}-pagination`}
                          >
                            <CommonPagination
                              count={campaignAsinPageCount}
                              pageNumber={campaignAsinPageNumber}
                              handlePageChange={(event) =>
                                handleCampaignAsinPaginationEvents(
                                  event,
                                  campaignDetails,
                                )
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </>
                )}
              </tbody>
            </table>
          </Collapse>
        </td>
      </tr>
    );
  };

  // dispaly campaign table data
  const displayCampaignTable = (tableId) => {
    return campaignCollapseLoader ? (
      <tr>
        <td
          colSpan="11"
          style={{
            padding: '0',
          }}
        >
          <PageLoader
            component="performance-graph"
            color={Theme.orange}
            type="detail"
            width={40}
            height={40}
          />
        </td>
      </tr>
    ) : (
      <tbody>
        {campaignListData?.map((campaignDetails, index) => {
          return (
            <>
              {/* render campaign table heading */}
              {displayCampaignCollpaseHead(campaignDetails, index)}
              {/* render campaign table body */}
              {displayCampaignCollpaseBody(
                campaignDetails,
                useValueWithChangeAndPercent,
                tableId,
                index,
              )}
              <tr style={{ height: '15px' }} />
            </>
          );
        })}
      </tbody>
    );
  };

  const displayCampaignPerformanceContent = () => {
    const tableMinWidth = '1200px';
    const tableDivId = 'BT-dsp-campaign-table';
    return (
      <VisibleScrollableTable
        tableMinWidth={tableMinWidth}
        id={tableDivId}
        data-cy="BT-dsp-campaignTable"
      >
        <DesktopTable
          className={`metrics-table ${
            campaignCollapseLoader || campaignAsinLoader ? 'disabled' : ''
          }`}
          style={{ tableLayout: 'fixed' }}
        >
          <thead>
            <tr>
              {dspAsinTableConstant?.map((item) => {
                return (
                  <th
                    key={item?.key}
                    style={{ width: item?.width }}
                    colSpan={item?.colSpan}
                    onClick={() => {
                      if (item?.isSortable) handleSortingEvents(item?.key);
                    }}
                  >
                    {item?.label}
                    {item?.isSortable
                      ? displaySortableUpDownArrow(item?.key)
                      : null}
                  </th>
                );
              })}
            </tr>
          </thead>
          {displayCampaignTable(tableDivId)} {/* render campaign table data */}
        </DesktopTable>
        {campaignPageCount > 10 ? (
          <div data-cy="BT-dsp-campaign-pagination">
            <CommonPagination
              count={campaignPageCount}
              pageNumber={campaignPageNumber}
              handlePageChange={handleCampaignPaginationEvents}
            />
          </div>
        ) : null}
      </VisibleScrollableTable>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      {campaignCollapseLoader ? (
        <PageLoader
          component="performance-graph"
          color={Theme.orange}
          type="detail"
          width={40}
          height={40}
        />
      ) : campaignListData?.length > 0 ? (
        displayCampaignPerformanceContent() // render tables
      ) : (
        <NoData data-cy="BT-dsp-campignNoData-message">{asinMessage}</NoData>
      )}
    </>
  );
}

export default DspCampaignPerformanceTable;

DspCampaignPerformanceTable.defaultProps = {
  isCustom: false,
  campaignAsinLoader: false,
  campaignCollapseLoader: false,
  isTablet: false,
  campaignPageCount: 0,
  campaignPageNumber: 0,
  campaignAsinPageCount: 0,
  campaignAsinPageNumber: 0,
  orderBy: '',
  orderByKey: '',
  currencySymbol: '',
  asinMessage: '',
  campaignListData: [],
  campaignAsinData: {},
  handleSortingEvents: () => {},
  setCampaignListData: () => {},
  handleCampaignPaginationEvents: () => {},
  handleCampaignAsinPaginationEvents: () => {},
  handleCollapseClick: () => {},
};
DspCampaignPerformanceTable.propTypes = {
  isCustom: bool,
  campaignAsinLoader: bool,
  campaignCollapseLoader: bool,
  isTablet: bool,
  campaignPageCount: number,
  campaignPageNumber: number,
  campaignAsinPageCount: number,
  campaignAsinPageNumber: number,
  orderBy: string,
  orderByKey: string,
  currencySymbol: string,
  asinMessage: string,
  campaignListData: arrayOf(shape({})),
  campaignAsinData: shape({}),
  handleSortingEvents: func,
  setCampaignListData: func,
  handleCampaignPaginationEvents: func,
  handleCampaignAsinPaginationEvents: func,
  handleCollapseClick: func,
};
