/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import getSymbolFromCurrency from 'currency-symbol-map';
import { arrayOf, shape, string } from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import InventoryManagementFilters from './InventoryManagementFilters';
import RestockLimitsContainer from './RestockLimitsContainer';
import UnderStockOverStockASIN from './UnderStockOverStockASIN';
import InventoryManagementStrandedTable from './InventoryManagementStrandedTable';
import EstimatedLostSales from './EstimatedLostSales';
// import LongTermStorageFees from './LongTermStorageFees';
import { storageTypeConstants, timeFilterOptions } from '../../../../constants';
import { CustomSingleDateModal, WhiteCard } from '../../../../common';
import {
  getChildBps,
  getEstimatedLostSalesReasonListAPIData,
  getRestockLimitsData,
  getMarketPlaceList,
  getRestockInventoryData,
  getRestockInventoryReportData,
  getStrandedInventoryData,
} from '../../../../api';

export default function InventoryManagementContainer({
  marketplaceChoices,
  id,
  childBPList,
}) {
  // const userInfo = useSelector((state) => state.userState.userInfo);
  // const isCustomer = userInfo?.role?.includes('Customer');
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const customerState = useSelector((state) => state?.customerState?.data);
  const isSuperAdmin = userInfo?.role === 'Super Admin';
  const isThirdPartyBrand = customerState?.is_third_party_potential_brand;
  const hideEstimatedLostSales = !isSuperAdmin && !isThirdPartyBrand;

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const mounted = useRef(false);
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const restockMindate = new Date('2022-03-21');
  const [selectedMarketplace, setSelectedMarketplace] = useState();
  const [marketplaceOptions, setMarketplaceOptions] = useState([]);
  const [restockLimitsGraphData, setRestockLimitsGraphData] = useState([]);
  const [strandedInvetoryData, setStrandedInvetoryData] = useState([]);
  const [stockedData, setStockedData] = useState([]);
  const [strandedDataCount, setStrandedDataCount] = useState(0);
  const [stockedCount, setStockedCount] = useState(0);
  const [isCustomDateApply, setIsCustomDateApply] = useState(false);
  const [strandedOrderByFlag, setStrandedOrderBy] = useState(true);
  const [strandedOrderByKey, setStrandedOrderByKey] = useState(
    'stranded_event_date',
  );
  const [stockedOrderByFlag, setStockedOrderBy] = useState(true);

  const [restockLimitsLoader, setRestockLimitsLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [stockedLoader, setStockedLoader] = useState(false);
  const [stockedReportLoader, setStockedReportLoader] = useState(false);
  const currentDate = new Date();
  const [customDateState, setCustomDateState] = useState(currentDate);
  const [selectedCustomDateState, setSelectedCustomDateState] =
    useState(currentDate);
  const [dataAvailableDate, setDataAvailableDate] = useState(currentDate);
  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState({
    value: 'yesterday',
    label: 'Today',
    sub: 'vs Yesterday',
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [stockedPageNumber, setStockedPageNumber] = useState(1);
  const stockedRef = useRef(null);
  const history = useHistory();

  const setTab = () => {
    const { state } = history?.location;
    if (state === 'overstocked') {
      return { tab: 'overstocked', orderBy: 'sales_last_30_days' };
    }
    return { tab: 'understocked', orderBy: 'poos_date' };
  };

  const [stockedOrderByKey, setStockedOrderByKey] = useState(setTab()?.orderBy);
  const [activeASINTab, setActiveASINTab] = useState(setTab()?.tab);
  const [childBrandOptions, setChildBrandOptions] = useState(childBPList);
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    childBPList?.length ? childBPList?.[0]?.value : id,
  );
  const [estimatedLostSalesReasonList, setEstimatedLostSalesReasonList] =
    useState([]);

  let todaysDate = new Date();
  todaysDate = dayjs(todaysDate).format('YYYY-MM-DD');
  const selectedCustomDate = dayjs(customDateState).format('YYYY-MM-DD');

  // no data message as per mst timezone
  let isMST12to10AM = false;
  let noDataMessage = 'No Data Found';

  const todayDateInMST = new Date().toLocaleString('en-US', {
    timeZone: 'MST',
  });
  const getMSTHour = new Date(todayDateInMST).getHours();
  const getMSTMinutes = new Date(todayDateInMST).getMinutes();

  // MST time limit logic for restock limit, stranded inventory
  if (
    selectedTimeFrame?.value === 'custom' &&
    selectedCustomDate < todaysDate
  ) {
    noDataMessage = 'No Data Found';
    isMST12to10AM = false;
  } else if (getMSTHour < 9 || (getMSTHour === 9 && getMSTMinutes === 0)) {
    noDataMessage = 'Today’s data is currently being updated.';
    isMST12to10AM = true;
  } else {
    noDataMessage = 'No Data Found';
    isMST12to10AM = false;
  }

  // MST time limit logic for estimated lost sales and understock/overstock inventory
  let isMST12to10AMForELS = false;
  let noDataMessageForELS = 'No Data Found';
  if (getMSTHour < 9 || (getMSTHour === 9 && getMSTMinutes === 0)) {
    noDataMessageForELS = 'Today’s data is currently being updated.';
    isMST12to10AMForELS = true;
  } else {
    noDataMessageForELS = 'No Data Found';
    isMST12to10AMForELS = false;
  }

  const bindRestockLimitGraphData = (resData) => {
    const bindedRes = [];

    if (resData?.length) {
      storageTypeConstants.map((ele) => {
        const storageType = resData[0]?.storage?.[ele.key];
        bindedRes.push({
          storageType: ele?.label,
          onhand: storageType?.on_hand || null,
          openShipment: storageType?.open_shipment || null,
          maximumShipment: storageType?.max_shipment || null,
          utilization: storageType?.utilization || null,

          previousOnhand: storageType?.previous_on_hand || null,
          previousOpenShipment: storageType?.previous_open_shipment || null,
          previousMaximumShipment: storageType?.previous_max_shipment || null,
          previousUtilization: storageType?.previous_utilization || null,

          changeOnhand: storageType?.on_hand_change || null,
          changeOpenShipment: storageType?.open_shipment_change || null,
          changeMaximumShipment: storageType?.max_shipment_change || null,
          changeUtilization: storageType?.utilization_change || null,

          onhandLabel: storageType?.on_hand || 0,
          openShipmentLabel: storageType?.open_shipment || 0,
          maximumShipmentLabel: storageType?.max_shipment || 0,
          utilizationLabel: storageType?.utilization || 0,
          previousOnhandLabel: storageType?.previous_on_hand || 0,
          previousOpenShipmentLabel: storageType?.previous_open_shipment || 0,
          previousMaximumShipmentLabel: storageType?.previous_max_shipment || 0,
          previousUtilizationLabel: storageType?.previous_utilization || 0,
          changeOnhandLabel: storageType?.on_hand_change || 0,
          changeOpenShipmentLabel: storageType?.open_shipment_change || 0,
          changeMaximumShipmentLabel: storageType?.max_shipment_change || 0,
          changeUtilizationLabel: storageType?.utilization_change || 0,
        });
        return null;
      });
    }
    return bindedRes;
  };

  // use effect hook
  useEffect(() => {
    if (childBPList.length) {
      setChildBrandOptions(childBPList);
      setSelectedCustomerId(childBPList[0]?.value);
    }
  }, [childBPList]);

  useEffect(() => {
    const { state } = history?.location;

    if (
      stockedRef?.current &&
      (state === 'understocked' || state === 'overstocked')
    ) {
      stockedRef.current.scrollIntoView();
    }
  }, [history]);

  const getRestockLimitsGraphData = useCallback(
    (custId, timeframe, marketplace, selectedDate, isMSTTime = false) => {
      setRestockLimitsLoader(true);
      setRestockLimitsGraphData([]);
      getRestockLimitsData(custId, timeframe, marketplace, selectedDate).then(
        (res) => {
          if (mounted.current) {
            if (res?.status === 200) {
              if (!isMSTTime) {
                const bindData = bindRestockLimitGraphData(res?.data?.results);
                setRestockLimitsGraphData(bindData);
                if (
                  res?.data?.available_date &&
                  res?.data?.available_date !== null
                ) {
                  setDataAvailableDate(new Date(res?.data?.available_date));
                  if (selectedTimeFrame.value !== 'custom')
                    setSelectedCustomDateState(
                      new Date(res?.data?.available_date),
                    );
                } else {
                  setDataAvailableDate(currentDate);
                }
              } else {
                setDataAvailableDate(currentDate);
              }
            } else {
              setRestockLimitsLoader(false);
              setRestockLimitsGraphData([]);
              setDataAvailableDate(currentDate);
            }
            setRestockLimitsLoader(false);
          }
        },
      );
    },
    [selectedTimeFrame],
  );

  const getStrandedData = useCallback(
    (
      custId,
      timeframe,
      marketplace,
      selectedDate,
      page,
      orderBykey,
      orderByFlag,
      isMSTTime = false,
    ) => {
      setTableLoader(true);
      setStrandedInvetoryData([]);
      setStrandedDataCount(0);
      getStrandedInventoryData(
        custId,
        timeframe,
        marketplace,
        selectedDate,
        page,
        orderBykey,
        orderByFlag,
      ).then((res) => {
        if (mounted.current) {
          if (res?.status === 200) {
            if (!isMSTTime) {
              setStrandedInvetoryData(res?.data?.results || []);
              setStrandedDataCount(res?.data?.count || 0);
              setTableLoader(false);
            }
          } else {
            setTableLoader(false);
            setStrandedInvetoryData([]);
            setStrandedDataCount(0);
          }
          setTableLoader(false);
        }
      });
    },
    [],
  );

  const getUnderOverStockedASINData = useCallback(
    (custId, marketplace, page, orderBykey, orderByFlag, activeTab) => {
      setStockedLoader(true);
      setStockedData([]);
      setStockedCount(0);
      getRestockInventoryData(
        custId,
        marketplace,
        page,
        orderBykey,
        orderByFlag,
        activeTab,
      )
        .then((res) => {
          if (mounted.current) {
            if (res?.status === 200) {
              if (!isMST12to10AM) {
                setStockedData(res?.data?.results || []);
                setStockedCount(res?.data?.count || 0);
                setStockedLoader(false);
              }
            }
            setStockedLoader(false);
          }
        })
        .catch(() => {
          setStockedLoader(false);
          setStockedData([]);
          setStockedCount(0);
        });
    },
    [],
  );

  useEffect(() => {
    mounted.current = true;
    let marketPlaceList = [];
    if (mounted.current && marketplaceChoices.length) {
      for (const option of marketplaceChoices) {
        marketPlaceList.push({
          ...option,
          value: option.name,
          label: option.country_currency.country,
          currency: option.country_currency.currency,
        });
      }

      if (marketPlaceList.length === 2) {
        marketPlaceList = marketPlaceList.filter(
          (item) => item.value !== 'all',
        );
      }

      let initialMarketplace = {};
      setMarketplaceOptions(marketPlaceList);
      if (customerState?.account_type === 'parent') {
        initialMarketplace =
          marketPlaceList.find((value) => value.is_primary) ||
          marketPlaceList?.[0];
      } else {
        initialMarketplace =
          marketPlaceList.length > 1 ? marketPlaceList[0] : marketPlaceList[0];
      }

      setSelectedMarketplace(initialMarketplace);
      setCurrencySymbol(getSymbolFromCurrency(initialMarketplace?.currency));
    }
    return () => {
      mounted.current = false;
    };
  }, [customerState?.account_type, marketplaceChoices]);

  useEffect(() => {
    if (selectedMarketplace && selectedMarketplace?.value !== '') {
      getRestockLimitsGraphData(
        selectedCustomerId,
        selectedTimeFrame.value,
        selectedMarketplace?.value,
        customDateState,
        isMST12to10AM,
      );
    }
  }, [
    getRestockLimitsGraphData,
    selectedMarketplace,
    selectedTimeFrame,
    customDateState,
    selectedCustomerId,
    isMST12to10AM,
  ]);

  useEffect(() => {
    if (selectedMarketplace && selectedMarketplace?.value !== '') {
      setPageNumber(1);

      getStrandedData(
        selectedCustomerId,
        selectedTimeFrame.value,
        selectedMarketplace?.value,
        customDateState,
        1,
        strandedOrderByKey,
        strandedOrderByFlag,
        isMST12to10AM,
      );
    }
  }, [
    selectedCustomerId,
    customDateState,
    getStrandedData,
    selectedMarketplace,
    selectedTimeFrame,
    strandedOrderByFlag,
    strandedOrderByKey,
    isMST12to10AM,
  ]);

  useEffect(() => {
    if (selectedMarketplace && selectedMarketplace?.value !== '') {
      setStockedPageNumber(1);
      if (!isMST12to10AMForELS) {
        getUnderOverStockedASINData(
          selectedCustomerId,
          selectedMarketplace?.value,
          1,
          stockedOrderByKey,
          stockedOrderByFlag,
          activeASINTab,
        );
      }
    }
  }, [
    getUnderOverStockedASINData,
    selectedMarketplace,
    stockedOrderByKey,
    stockedOrderByFlag,
    activeASINTab,
    selectedCustomerId,
    isMST12to10AMForELS,
  ]);

  // bind the child brand list
  const bindChidBrands = (childBrandResponse) => {
    const localChildBrand = [];

    if (childBPList?.length > 0) {
      childBPList.forEach((element) => {
        const matchElement = childBrandResponse.find(
          (op) => op.value === element.value,
        );
        if (matchElement !== undefined) {
          localChildBrand.push({
            ...element,
            isDisabled: false,
          });
        } else {
          localChildBrand.push({
            ...element,
            isDisabled: true,
          });
        }
        return true;
      });
    }

    setChildBrandOptions(localChildBrand);
  };

  // API - get the child brand list based on selected marketplace
  const getChildBpList = (customerId, marketplaceId) => {
    getChildBps(customerId, marketplaceId).then((res) => {
      if (res?.status === 200) {
        if (res?.data.length) {
          bindChidBrands(res?.data);
        }
      }
    });
  };

  // get the child brand list based on selected marketplace
  useEffect(() => {
    if (
      childBPList.length &&
      customerState?.account_type === 'parent' &&
      selectedMarketplace &&
      selectedMarketplace?.value
    ) {
      if (selectedMarketplace?.value === 'all') {
        bindChidBrands(childBPList);
      } else {
        getChildBpList(id, selectedMarketplace?.value);
      }
    }
  }, [selectedMarketplace, childBPList]);

  // bind the marketplace options
  const bindMarketplaceOptions = (responseData) => {
    const localMarketplaceList = [];

    marketplaceChoices.forEach((element) => {
      const matchElement = responseData.find((op) => op.name === element.name);

      if (matchElement !== undefined) {
        localMarketplaceList.push({
          ...element,
          isDisabled: false,
          value: element?.name,
          label: element?.country_currency.country,
          currency: element?.country_currency.currency,
        });
      } else {
        localMarketplaceList.push({
          ...element,
          isDisabled: element?.name !== 'all',
          value: element?.name,
          label: element?.country_currency.country,
          currency: element?.country_currency.currency,
        });
      }

      return true;
    });

    setMarketplaceOptions(localMarketplaceList);
  };

  // API- get the marketplace list based on selected child brand
  const getMarketPlace = useCallback((custId) => {
    getMarketPlaceList(custId).then((res) => {
      if (res?.status === 200) {
        bindMarketplaceOptions(res?.data);
      }
    });
  }, []);

  // get the marketplace list based on selected child brand
  useEffect(() => {
    getMarketPlace(selectedCustomerId);
  }, [selectedCustomerId, getMarketPlace]);

  const getUnderOverStockedReportData = useCallback(
    (custID) => {
      setStockedReportLoader(true);
      getRestockInventoryReportData(
        custID,
        selectedMarketplace.value,
        activeASINTab,
      )
        .then((res) => {
          if (mounted.current) {
            if (res?.status === 200) {
              const csvLink = res?.data?.url;
              if (csvLink) window.open(csvLink);
              if (res?.data?.message)
                toast.error(res?.data?.message, {
                  position: isDesktop ? 'top-center' : 'top-right',
                });
              setStockedReportLoader(false);
            }
            setStockedReportLoader(false);
          }
        })
        .catch(() => {
          toast.error('Something went wrong', {
            position: isDesktop ? 'top-center' : 'top-right',
          });
          setStockedReportLoader(false);
        });
    },
    [activeASINTab, id, isDesktop, selectedMarketplace],
  );

  // bind estimated reason list data
  const bindEstimatedLostSalesReasonList = useCallback((apiData) => {
    const tempReasonData = [
      {
        label: 'All Reasons',
        value: '',
      },
    ];

    if (apiData?.length) {
      for (const item of apiData) {
        tempReasonData.push({
          label: item,
          value: item,
        });
      }
    }
    return tempReasonData;
  });

  // get estimated lost sales reason list
  const getEstimatedLostSalesReasonList = useCallback(
    (customerId, marketplace) => {
      getEstimatedLostSalesReasonListAPIData(customerId, marketplace).then(
        (response) => {
          if (response?.status === 200) {
            const getData = bindEstimatedLostSalesReasonList(response?.data);
            setEstimatedLostSalesReasonList(getData);
          } else {
            setEstimatedLostSalesReasonList([]);
          }
        },
      );
    },
    [],
  );

  // useEffect for reason list
  useEffect(() => {
    if (selectedCustomerId !== null && selectedMarketplace?.value !== null)
      getEstimatedLostSalesReasonList(
        selectedCustomerId,
        selectedMarketplace?.value,
      );
  }, [selectedCustomerId, selectedMarketplace?.value]);

  const handleTimeFrame = (event) => {
    const { value } = event;
    if (value !== selectedTimeFrame.value) {
      setIsCustomDateApply(false);
      if (value !== 'custom') {
        setSelectedTimeFrame(event);
        setCustomDateState(currentDate);
        setSelectedCustomDateState(dataAvailableDate);
      }
    }
    if (value === 'custom') {
      setShowCustomDateModal(true);
    }
  };

  const handleMarketplace = (event) => {
    if (event.value !== selectedMarketplace.value) {
      setSelectedMarketplace(event);
      setCurrencySymbol(getSymbolFromCurrency(event?.currency));
    }
  };

  const handleChildBrandOptions = (event) => {
    if (event.value !== selectedCustomerId) {
      setSelectedCustomerId(event.value);
    }
  };

  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getStrandedData(
      selectedCustomerId,
      selectedTimeFrame.value,
      selectedMarketplace.value,
      customDateState,
      currentPage,
      strandedOrderByKey,
      strandedOrderByFlag,
    );
  };

  const handleStockedPageChange = (currentPage) => {
    setStockedPageNumber(currentPage);
    getUnderOverStockedASINData(
      selectedCustomerId,
      selectedMarketplace.value,
      currentPage,
      stockedOrderByKey,
      stockedOrderByFlag,
      activeASINTab,
    );
  };

  const handleSortFilters = (value) => {
    if (value === strandedOrderByKey) {
      setStrandedOrderBy(!strandedOrderByFlag);
    } else {
      setStrandedOrderBy(true);
      setStrandedOrderByKey(value);
    }
  };

  const handleStockedSortFilters = (value) => {
    if (value === stockedOrderByKey) {
      setStockedOrderBy(!stockedOrderByFlag);
    } else {
      setStockedOrderBy(true);
      setStockedOrderByKey(value);
    }
  };

  const applyCustomDate = () => {
    setIsCustomDateApply(true);
    setSelectedTimeFrame({
      value: 'custom',
      label: 'Custom',
      sub: 'Select date',
    });
    setCustomDateState(selectedCustomDateState);
    setShowCustomDateModal(false);
  };

  return (
    <div className="col-12">
      {/* ********** PDV-7917 - Expose AMC and other Sections to BPs ********** */}
      {/* ********** PLEASE DO NOT REMOVE BELOW CODE. ********** */}
      {/* {!userInfo?.role?.includes('Customer') ||
      (userInfo?.role?.includes('Customer') &&
        customerState?.display_inventory_management) ? ( */}
      <>
        <InventoryManagementFilters
          marketplaceDefaultValue={
            marketplaceOptions && marketplaceOptions.length
              ? marketplaceOptions[0]
              : {}
          }
          marketplaceOptions={marketplaceOptions}
          handleMarketplaceOptions={handleMarketplace}
          isApiCall={restockLimitsLoader || tableLoader}
          selectedMarketplace={selectedMarketplace}
          childBrandOptions={childBrandOptions}
          selectedCustomerId={selectedCustomerId}
          handleChildBrand={handleChildBrandOptions}
        />
        <WhiteCard className="mb-3 mb-lg-0">
          <RestockLimitsContainer
            restockLimitsGraphData={restockLimitsGraphData}
            selectedTimeFrame={selectedTimeFrame}
            dateOptions={timeFilterOptions}
            handleDailyFact={handleTimeFrame}
            isApiCall={restockLimitsLoader}
            customDateState={customDateState}
            selectedMarketplace={selectedMarketplace}
            isCustomDateApply={isCustomDateApply}
            noDataMessage={noDataMessage}
          />
          <InventoryManagementStrandedTable
            strandedData={strandedInvetoryData}
            isLoader={tableLoader}
            isDesktop={isDesktop}
            count={strandedDataCount}
            pageNumber={pageNumber}
            noDataMessage={noDataMessage}
            isMST12to10AM={isMST12to10AM}
            strandedOrderByFlag={strandedOrderByFlag}
            strandedOrderByKey={strandedOrderByKey}
            selectedTimeFrame={selectedTimeFrame}
            handlePageChange={handlePageChange}
            handleSortFilters={handleSortFilters}
          />
        </WhiteCard>
      </>
      {/* ********** PDV-7917 - Expose AMC and other Sections to BPs ********** */}
      {/* ********** PLEASE DO NOT REMOVE BELOW CODE. ********** */}
      {/* ) : null}


      {(!userInfo?.role?.includes('Customer') &&
        userInfo?.is_restockinventory_visible) ||
      (userInfo?.role?.includes('Customer') &&
        customerState?.display_under_over_stocked_inventory) ? ( */}
      <UnderStockOverStockASIN
        stockedRef={stockedRef}
        stockedData={stockedData}
        isLoader={stockedLoader}
        isDesktop={isDesktop}
        count={stockedCount}
        pageNumber={stockedPageNumber}
        noDataMessage={noDataMessageForELS}
        isMST12to10AM={isMST12to10AMForELS}
        stockedOrderByFlag={stockedOrderByFlag}
        stockedOrderByKey={stockedOrderByKey}
        selectedTimeFrame={selectedTimeFrame}
        handlePageChange={handleStockedPageChange}
        handleSortFilters={handleStockedSortFilters}
        activeASINTab={activeASINTab}
        setActiveASINTab={setActiveASINTab}
        marketPlace={selectedMarketplace}
        setStockedOrderByKey={setStockedOrderByKey}
        setStockedOrderBy={setStockedOrderBy}
        reportLoader={stockedReportLoader}
        onDownloadStockedReport={() => {
          getUnderOverStockedReportData(selectedCustomerId);
        }}
      />
      {/* ) : null} */}
      {/* {!isCustomer ? ( */}
      {/* https://bbe.atlassian.net/browse/PDV-9525 - FE 2 - BP / Internal /
      Partner List Views - Stop pulling Seller and Vendor data via Amazon APIs
      Logic - If user is not Super Admin AND BP is BBE BP i.e. not Spreetail
      bp then hide estimated lost sales. */}
      <>
        {hideEstimatedLostSales ? null : (
          <EstimatedLostSales
            customerId={selectedCustomerId}
            currencySymbol={currencySymbol}
            noDataMessage={noDataMessageForELS}
            isMST12to10AM={isMST12to10AMForELS}
            selectedMarketplace={selectedMarketplace?.value}
            estimatedLostSalesReasonList={estimatedLostSalesReasonList}
          />
        )}
        {/* <LongTermStorageFees
          currencySymbol={currencySymbol}
          customerId={selectedCustomerId}
          noDataMessage={noDataMessageForELS}
          selectedMarketplace={selectedMarketplace?.value}
        /> */}
      </>
      {/* ) : null} */}
      <CustomSingleDateModal
        id="BT-inventoryManagementDashboard-date"
        isOpen={showCustomDateModal}
        selectedDate={selectedCustomDateState}
        minDate={restockMindate}
        onClick={() => {
          setShowCustomDateModal(false);
          if (selectedTimeFrame.value !== 'custom')
            setSelectedCustomDateState(dataAvailableDate);
        }}
        onChange={(item) => {
          setSelectedCustomDateState(item);
        }}
        onApply={() => applyCustomDate()}
        currentDate={dataAvailableDate}
      />
    </div>
  );
}

InventoryManagementContainer.defaultProps = {
  marketplaceChoices: [],
  id: '',
  childBPList: [],
};

InventoryManagementContainer.propTypes = {
  marketplaceChoices: arrayOf(shape({})),
  id: string,
  childBPList: arrayOf(shape({})),
};
