export const AgreementDetails = [
  {
    key: 'contract_type',
    label: 'Agreement Type',
    type: 'text',
    placeholder: 'Enter Agreement Type',
    isMandatory: true,
    part: 'agreement',
    error: false,
  },

  {
    key: 'start_date',
    label: 'Agreement Start Date',
    type: 'date',
    placeholder: 'Select Date',
    isMandatory: true,
    part: 'agreement',
    error: false,
    message:
      'The pre-populated agreement start date will automatically change based on the BP’s signed date',
  },

  {
    key: 'change_agreement_start_date',
    label: 'Change Agreement Start Date',
    type: 'checkbox',
    isMandatory: false,
    part: 'agreement',
    error: false,
  },

  {
    key: 'addendum_start_date',
    label: 'Addendum Start Date',
    type: 'date',
    placeholder: 'Select Date',
    isMandatory: false,
    part: 'agreement',
    error: false,
    message:
      'The pre-populated addendum start date will automatically change based on the BP’s signed date',
  },

  {
    key: 'change_addendum_start_date',
    label: 'Change Addendum Start Date',
    type: 'checkbox',
    isMandatory: false,
    part: 'agreement',
    error: false,
  },

  {
    key: 'length',
    label: 'Agreement Length',
    type: 'choice',
    placeholder: 'Select Agreement Length',
    isMandatory: true,
    part: 'agreement',
    error: false,
  },
  {
    key: 'company_name',
    label: 'Company Name',
    type: 'text',
    placeholder: 'Enter Company Name',
    isMandatory: true,
    part: 'agreement',
    error: false,
    field: 'customer',
  },
  {
    key: 'contract_address',
    label: 'Company Address',
    sections: [
      {
        key: 'address',
        type: 'text',
        placeholder: 'Enter Address',
        isMandatory: true,
        part: 'agreement',
        error: false,
        field: 'customer',
      },
      {
        key: 'state',
        type: 'text',
        placeholder: 'Enter State',
        isMandatory: true,
        part: 'agreement',
        error: false,
        field: 'customer',
      },
      {
        key: 'city',
        type: 'text',
        placeholder: 'Enter City',
        isMandatory: true,
        part: 'agreement',
        error: false,
        field: 'customer',
      },
      {
        key: 'zip_code',
        type: 'text',
        placeholder: 'Enter Postcode',
        isMandatory: true,
        part: 'agreement',
        error: false,
        field: 'customer',
      },
    ],
  },
];

export const ListingOptimization = [
  {
    key: 'content_optimization',
    label: 'Copy',
    type: 'text',
  },
  {
    key: 'design_optimization',
    label: 'Design',
    type: 'text',
  },
  // {
  //   key: 'photography_optimization',
  //   label: 'Photography',
  //   type: 'text',
  // },
];

export const ListingTranslationFields = [
  {
    key: 'copy_listing_translation',
    label: 'Copy',
    type: 'text',
  },
  {
    key: 'creative_listing_translation',
    label: 'Creative',
    type: 'text',
  },
];

export const remainingFieldsOfContract = [
  {
    key: 'contract_status',
    label: 'Agreement Status',
    type: 'text',
  },
  {
    key: 'fee_type',
    label: 'Fee Type',
    type: 'text',
  },
  {
    key: 'threshold_type',
    label: 'Threshold Type',
    type: 'text',
  },
  {
    key: 'vendor_billing_report',
    label: 'Vendor Billing Report',
    type: 'text',
  },
  {
    key: 'discount_type',
    label: 'Discount Type',
    type: 'text',
  },
  {
    key: 'discount_amount',
    label: 'Discount Amount',
    type: 'number-currency',
  },
  {
    key: 'sales_threshold',
    label: 'Sales Threshold',
    type: 'number-currency',
  },
  {
    key: 'monthly_rev_share',
    label: 'monthly Rev Share',
    type: 'object',
  },
  {
    key: 'quarterly_rev_share',
    label: 'Quarterly Rev Share',
    type: 'object',
  },
  {
    key: 'billing_minimum',
    label: 'BILLING MINIMUM ',
    type: 'number-currency',
  },
];

export const StatementDetails = [
  {
    key: 'monthly_retainer',
    label: 'Monthly Retainer',
    type: 'number-currency',
    placeholder: 'Enter Monthly Retainer',
    isMandatory: true,
    part: 'statement',
  },
  {
    key: 'primary_marketplace',
    label: 'Primary MarketPlace',
    type: 'choice',
    placeholder: 'Select Primary Marketplace',
    isMandatory: true,
    part: 'statement',
  },
  {
    key: 'rev_share',
    label: 'Revenue Share',
    type: 'choice',
    placeholder: 'Select Revenue Share',
    isMandatory: true,
    part: 'statement',
  },
  {
    key: 'billing_cap',
    label: 'BILLING CAP',
    type: 'number-currency',
    placeholder: 'Enter Billing Cap',
    isMandatory: false,
    part: 'statement',
  },
];

export const DSPAddendumDetails = [
  {
    key: 'start_date',
    label: 'Advertising Start Date',
    type: 'date',
    placeholder: 'Enter Advertising Start Date',
    info: 'The advertising start date is auto populated based on the agreement start date and amended to fall on the 1st of the month.',
    isMandatory: false,
    part: 'dsp',
  },
  {
    key: 'changed_dsp_start_date',
    label: 'Change Advertising Start Date',
    type: 'checkbox',
    placeholder: 'Enter Changed Advertising Start Date',
    isMandatory: false,
    part: 'dsp',
  },
  {
    key: 'dsp_fee',
    label: 'Monthly Ad Budget',
    type: 'number-currency',
    placeholder: 'Enter budget',
    isMandatory: true,
    part: 'dsp',
  },
  {
    key: 'is_dynamic_dsp_fee',
    label: 'Change to Dynamic Monthly Ad Budget',
    type: 'checkbox',
    isMandatory: false,
    part: 'dsp',
  },

  {
    key: 'agency_fee',
    label: 'Agency Fee Percentage',
    type: 'choice',
    placeholder: 'Enter Agency Fee',
    isMandatory: false,
    part: 'dsp',
    info: 'If the agency fee percentage is below 15%, an approval will be required.',
    // info: 'The minimum monthly budget is $10,000.',
  },

  {
    key: 'dsp_length',
    label: 'DSP service length',
    type: 'choice',
    placeholder: 'Select length',
    isMandatory: true,
    part: 'dsp',
  },

  {
    key: 'dsp_marketplaces',
    label: 'DSP Marketplaces',
    type: 'choice',
    placeholder: 'Select Marketplaces',
    isMandatory: true,
    part: 'dsp',
  },
];

export const newAgreementTypes = [
  {
    value: 'recurring',
    label: 'Recurring Service Agreement',
    sub: 'Standard Cancellation',
    type: 'recurring',
  },
  {
    value: 'recurring (90 day notice)',
    label: 'Recurring Service Agreement',
    sub: '90 Day Cancellation',
    type: 'ninetydays',
  },
  { value: 'dsp only', label: 'DSP-Only Agreement', sub: null, type: 'dsp' },
];

export const additionaMarketplaceAmount = 1500;

export const accountTypeOptions = [
  { value: 'Seller', label: 'Seller' },
  { value: 'Vendor', label: 'Vendor' },
  { value: 'Hybrid', label: 'Hybrid' },
];

export const feeTypeOptions = [
  {
    value: 'Retainer Only',
    label: 'Retainer Only',
    key: 'monthly_retainer',
    type: 'number-currency',
  },
  {
    value: 'Revenue Share Only',
    label: 'Revenue Share Only',
    key: 'rev_share',
    type: 'choice',
  },
  {
    value: 'Retainer + % Rev Share',
    label: 'Retainer + Rev Share',
    key: 'retinaer_rev_share',
    type: 'choice',
  },
];

// Fee structure

export const feeStructureContainerDetails = [
  {
    key: 'primary_marketplace',
    label: 'Primary MarketPlace',
    type: 'choice',
    placeholder: 'Select Primary Marketplace',
    isMandatory: true,
    part: 'fee structure',
  },
  {
    key: 'seller_type',
    label: 'Account Type',
    type: 'choice',
    placeholder: 'Select Account Type',
    isMandatory: true,
    part: 'fee structure',
  },
];

export const revShareDetails = [
  {
    key: 'rev_share',
    label: 'Revenue Share',
    type: 'choice',
    placeholder: 'Select Revenue Share',
    isMandatory: true,
    suffix: '%',
  },
  {
    key: 'rev_share_threshold',
    label: 'Revenue Share Threshold',
    type: 'threshold',
    isMandatory: false,
    prefix: '$',
  },
  {
    key: 'billing_minimum',
    label: 'BILLING MINIMUM (OPTIONAL)',
    type: 'number-currency',
    placeholder: 'Enter Billing Minimum',
    isMandatory: false,
    subtitle:
      'We will charge the greater of the value entered here or the % of revenue',
    prefix: '$',
  },
  {
    key: 'billing_cap',
    label: 'BILLING CAP (OPTIONAL)',
    type: 'number-currency',
    placeholder: 'Enter Billing Cap',
    isMandatory: false,
    subtitle: ' We will charge no more than the amount entered here.',
    prefix: '$',
  },
];

export const revShareQuarterDetails = [
  {
    key: 'rev_share',
    label: 'Revenue Share',
    type: 'choice',
    placeholder: 'Select Revenue Share',
    isMandatory: true,
    suffix: '%',
  },
  {
    key: 'rev_share_threshold',
    label: 'Revenue Share Threshold',
    type: 'threshold',
    isMandatory: false,
    prefix: '$',
  },
  {
    key: 'quarter',
    type: 'threshold',
    prefix: '$',
  },
  {
    key: 'billing_minimum',
    label: 'BILLING MINIMUM (OPTIONAL)',
    type: 'number-currency',
    placeholder: 'Enter Billing Minimum',
    isMandatory: false,
    subtitle:
      'We will charge the greater of the value entered here or the % of revenue',
    prefix: '$',
  },
  {
    key: 'billing_cap',
    label: 'BILLING CAP (OPTIONAL)',
    type: 'number-currency',
    placeholder: 'Enter Billing Cap',
    isMandatory: false,
    subtitle: ' We will charge no more than the amount entered here.',
    prefix: '$',
  },
];

export const revShareMonthDetails = [
  {
    key: 'rev_share',
    label: 'Revenue Share',
    type: 'choice',
    placeholder: 'Select Revenue Share',
    isMandatory: true,
    suffix: '%',
  },
  {
    key: 'rev_share_threshold',
    label: 'Revenue Share Threshold',
    type: 'threshold',
    isMandatory: false,
    prefix: '$',
  },
  {
    key: 'month',
    type: 'threshold',
    prefix: '$',
  },
  {
    key: 'billing_minimum',
    label: 'BILLING MINIMUM (OPTIONAL)',
    type: 'number-currency',
    placeholder: 'Enter Billing Minimum',
    isMandatory: false,
    subtitle:
      'We will charge the greater of the value entered here or the % of revenue',
    prefix: '$',
  },
  {
    key: 'billing_cap',
    label: 'BILLING CAP (OPTIONAL)',
    type: 'number-currency',
    placeholder: 'Enter Billing Cap',
    isMandatory: false,
    subtitle: ' We will charge no more than the amount entered here.',
    prefix: '$',
  },
];

export const revShareAndRetainerDetails = [
  {
    value: 'monthly_retainer',
    label: 'Retainer',
    key: 'monthly_retainer',
    type: 'retainer',
    isMandatory: true,
    prefix: '$',
  },
  {
    key: 'rev_share',
    label: 'Revenue Share',
    type: 'choice',
    placeholder: 'Select Revenue Share',
    isMandatory: true,
    suffix: '%',
  },
  {
    key: 'rev_share_threshold',
    label: 'Revenue Share Threshold',
    type: 'threshold',
    isMandatory: false,
    prefix: '$',
  },
  {
    key: 'billing_cap',
    label: 'BILLING CAP (OPTIONAL)',
    type: 'number-currency',
    placeholder: 'Enter Billing Cap',
    isMandatory: false,
    subtitle: ' We will charge no more than the amount entered here.',
    prefix: '$',
  },
];

export const revShareAndRetainerQuarterDetails = [
  {
    value: 'monthly_retainer',
    label: 'Monthly Retainer',
    key: 'monthly_retainer',
    type: 'number-currency',
    isMandatory: true,
    prefix: '$',
  },
  {
    key: 'rev_share',
    label: 'Revenue Share',
    type: 'choice',
    placeholder: 'Select Revenue Share',
    isMandatory: true,
    suffix: '%',
  },
  {
    key: 'rev_share_threshold',
    label: 'Revenue Share Threshold',
    type: 'threshold',
    isMandatory: false,
    prefix: '$',
  },
  {
    key: 'quarter',
    type: 'threshold',
    prefix: '$',
  },
  {
    key: 'billing_cap',
    label: 'BILLING CAP (OPTIONAL)',
    type: 'number-currency',
    placeholder: 'Enter Billing Cap',
    isMandatory: false,
    subtitle: ' We will charge no more than the amount entered here.',
    prefix: '$',
  },
];
export const revShareAndRetainerMonthDetails = [
  {
    value: 'monthly_retainer',
    label: 'Monthly Retainer',
    key: 'monthly_retainer',
    type: 'number-currency',
    isMandatory: true,
    prefix: '$',
  },
  {
    key: 'rev_share',
    label: 'Revenue Share',
    type: 'choice',
    placeholder: 'Select Revenue Share',
    isMandatory: true,
    suffix: '%',
  },
  {
    key: 'rev_share_threshold',
    label: 'Revenue Share Threshold',
    type: 'threshold',
    isMandatory: false,
    prefix: '$',
  },
  {
    key: 'month',
    type: 'threshold',
    prefix: '$',
  },
  {
    key: 'billing_cap',
    label: 'BILLING CAP (OPTIONAL)',
    type: 'number-currency',
    placeholder: 'Enter Billing Cap',
    isMandatory: false,
    subtitle: ' We will charge no more than the amount entered here.',
    prefix: '$',
  },
];

export const quarterlyThresholdOptions = [
  {
    key: '1st quarterly',
    label: '1st Quarter',
    type: 'number-currency',
    placeholder: 'Enter 1st Quarter',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'Q1 Threshold',
  },
  {
    key: '2nd quarterly',
    label: '2nd Quarter',
    type: 'number-currency',
    placeholder: 'Enter 2nd Quarter',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'Q2 Threshold',
  },
  {
    key: '3rd quarterly',
    label: '3rd Quarter',
    type: 'number-currency',
    placeholder: 'Enter 3rd Quarter',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'Q3 Threshold',
  },
  {
    key: '4th quarterly',
    label: '4th Quarter',
    type: 'number-currency',
    placeholder: 'Enter 4th Quarter',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'Q4 Threshold',
  },
];

export const monthlyThresholdOptions = [
  {
    key: 'january month',
    label: 'January',
    type: 'number-currency',
    placeholder: 'Enter January',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'January Threshold',
  },
  {
    key: 'february month',
    label: 'February',
    type: 'number-currency',
    placeholder: 'Enter February',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'February Threshold',
  },
  {
    key: 'march month',
    label: 'March',
    type: 'number-currency',
    placeholder: 'Enter March',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'March Threshold',
  },
  {
    key: 'april month',
    label: 'April',
    type: 'number-currency',
    placeholder: 'Enter April',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'April Threshold',
  },
  {
    key: 'may month',
    label: 'May',
    type: 'number-currency',
    placeholder: 'Enter May',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'May Threshold',
  },
  {
    key: 'june month',
    label: 'June',
    type: 'number-currency',
    placeholder: 'Enter June',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'June Threshold',
  },
  {
    key: 'july month',
    label: 'July',
    type: 'number-currency',
    placeholder: 'Enter July',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'July Threshold',
  },
  {
    key: 'august month',
    label: 'August',
    type: 'number-currency',
    placeholder: 'Enter August',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'August Threshold',
  },
  {
    key: 'september month',
    label: 'September',
    type: 'number-currency',
    placeholder: 'Enter September',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'September Threshold',
  },
  {
    key: 'october month',
    label: 'October',
    type: 'number-currency',
    placeholder: 'Enter October',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'October Threshold',
  },
  {
    key: 'november month',
    label: 'November',
    type: 'number-currency',
    placeholder: 'Enter November',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'November Threshold',
  },
  {
    key: 'december month',
    label: 'December',
    type: 'number-currency',
    placeholder: 'Enter December',
    isMandatory: false,
    prefix: '$',
    fieldOf: 'threshold',
    detail: 'December Threshold',
  },
];

export const vendorReportOptions = [
  { value: 'Shipped COGS', label: 'Shipped COGS' },
  { value: 'Ordered Revenue', label: 'Ordered Revenue' },
];

export const rachelDetails = {
  firstName: 'Rachel',
  lastName: 'Libby',
  email: 'rlibby@buyboxexperts.com',
  title: 'Director of Marketing',
};

export const thadDetails = {
  firstName: 'Brad',
  lastName: 'Saksons',
  email: 'thad.hay@spreetail.com',
  title: 'VP, Growth',
};

// invoice constants
export const invoiceConstants = [
  {
    invoiceLabel: 'Monthly Retainer',
    invoiceKey: 'monthlyRetainer',
    invoiceType: 'retainer',
    infoId: 'retainerInfo',
  },
  {
    invoiceLabel: 'Rev Share',
    invoiceKey: 'revShare',
    invoiceType: 'rev share',
    infoId: 'revShareInfo',
  },
  {
    invoiceLabel: 'DSP',
    invoiceKey: 'dsp',
    invoiceType: 'dsp service',
    infoId: 'dspInfo',
  },
  {
    invoiceLabel: 'AMC',
    invoiceKey: 'amcService',
    invoiceType: 'amc service',
    infoId: 'amcInfo',
  },
];

// https://bbe.atlassian.net/browse/PDV-9280 - adding pause request note
export const pauseRequestNote =
  'If the pause request has been approved in the middle of the pausing period, please reach out to Finance should there be invoices that need to be voided.';

export const invoiceTooltipMessages = {
  lastBillDate:
    'This is the last date on which we should send an invoice. If you pick a past date, we will not send any more invoices.',
  fromDate:
    'This is the last date on which we should send an invoice. If you pick a past date, we will not send any more invoices.',
  toDate:
    'This is the date on which we should send the first invoice bill after the pausing period. Select a date that falls a month after the last date on which we should send out the last retainer invoice before the pausing period.',
  monthlyRetainer:
    'This is the last retainer invoice to be sent out. It must lie on the same date on which we bill this BP for retainer. You can edit the amount of that invoice provided it still hasn’t been sent out.',
  monthlyRetainerAmount:
    'This is the last retainer invoice to be sent out. You can edit the last retainer invoice’s amount.',
  revShare:
    'This is the last rev share invoice to be sent out. The date must lie on the 9th as we create rev share invoices then.',
  dsp: 'This is the last standard DSP invoice. The date must lie on the 11th as we create standard DSP invoices then.',
  amc: 'This is the last AMC invoice to be sent out. It must lie on the same date in which we bill the BP for AMC.',
  amcService:
    'This is the last AMC invoice to be sent out. It must lie on the same date in which we bill the BP for AMC.',
  dspStandardInfo: 'This applies to DSP Standard Invoices only.',
};

// https://bbe.atlassian.net/browse/PDV-9280
// static messages for pause agreement
const pastBillDateMessageForPauseAgreement =
  'When a past bill date is selected where the invoice has already been issued to the BP, please reach out to     Finance for any pausing, voiding or refund requests.';

export const pauseAgreementStaticMesssages = {
  monthlyRetainer: pastBillDateMessageForPauseAgreement,
  revShare:
    'Rev share invoices are sent out on every 9th of the month. Billed amount is based on BP’s previous month’s      sales. Please reach out to Finance for any pausing, voiding or refund requests.',
  dsp: pastBillDateMessageForPauseAgreement,
  amcService: pastBillDateMessageForPauseAgreement,
};
// edit button message for invoice
export const editBtnMessageForInvoice =
  'Edit button is disabled as you have selected an invoice date where the invoice has already been issued to the BP, please reach out to Finance for any cancellation, voiding or refund requests.';

// static messages for cancel agreement
const pastBillDateMessageForCancelAgreement =
  'When a past bill date is selected where the invoice has already been issued to the BP, please reach out to    Finance for any cancellation, voiding or refund requests.';

export const cancelAgreementStaticMesssages = {
  futureInvoiceDateMessage:
    'This is a future invoice which will no longer be issued to the BP.',
  monthlyRetainer: pastBillDateMessageForCancelAgreement,
  revShare:
    'Rev share invoices are sent out on every 9th of the month. Billed amount is based on BP’s previous month’s sales. Please reach out to Finance for any cancellation, voiding or refund requests.',
  dsp: pastBillDateMessageForCancelAgreement,
  amcService:
    'AMC invoice is sent out every month based on the signed date of the BP. Please reach out to Finance for any cancellation, voiding or refunding requests.',
};

export const errorMessages = {
  retainerAmount:
    'Please enter an amount that is equal to or less than the total retainer amount.',
  monthSpan: 'All last bill dates need to lie within a 3 month span.',
  isEndingEarlyNoMessage:
    'Cancelling a contract more that 3 months before its contract end date is not permitted. Should you wish to cancel the contract, select YES to end the contract early and enter the last service date to proceed to the next steps.',
};

export const quarterlyRetainerOptions = [
  {
    key: '1st quarterly',
    label: '1st Quarter',
    type: 'number-currency',
    placeholder: 'Enter 1st Quarter',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'Q1 Retainer',
    shortForm: 'Q1',
    range: [0, 1, 2],
  },
  {
    key: '2nd quarterly',
    label: '2nd Quarter',
    type: 'number-currency',
    placeholder: 'Enter 2nd Quarter',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'Q2 Retainer',
    shortForm: 'Q2',
    range: [3, 4, 5],
  },
  {
    key: '3rd quarterly',
    label: '3rd Quarter',
    type: 'number-currency',
    placeholder: 'Enter 3rd Quarter',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'Q3 Retainer',
    shortForm: 'Q3',
    range: [6, 7, 8],
  },
  {
    key: '4th quarterly',
    label: '4th Quarter',
    type: 'number-currency',
    placeholder: 'Enter 4th Quarter',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'Q4 Retainer',
    shortForm: 'Q4',
    range: [9, 10, 11],
  },
];

export const monthlyRetainerOptions = [
  {
    key: 'january month',
    label: 'January',
    type: 'number-currency',
    placeholder: 'Enter January',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'January Retainer',
    shortForm: 'Jan',
  },
  {
    key: 'february month',
    label: 'February',
    type: 'number-currency',
    placeholder: 'Enter February',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'February Retainer',
    shortForm: 'Feb',
  },
  {
    key: 'march month',
    label: 'March',
    type: 'number-currency',
    placeholder: 'Enter March',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'March Retainer',
    shortForm: 'Mar',
  },
  {
    key: 'april month',
    label: 'April',
    type: 'number-currency',
    placeholder: 'Enter April',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'April Retainer',
    shortForm: 'Apr',
  },
  {
    key: 'may month',
    label: 'May',
    type: 'number-currency',
    placeholder: 'Enter May',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'May Retainer',
    shortForm: 'May',
  },
  {
    key: 'june month',
    label: 'June',
    type: 'number-currency',
    placeholder: 'Enter June',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'June Retainer',
    shortForm: 'Jun',
  },
  {
    key: 'july month',
    label: 'July',
    type: 'number-currency',
    placeholder: 'Enter July',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'July Retainer',
    shortForm: 'Jul',
  },
  {
    key: 'august month',
    label: 'August',
    type: 'number-currency',
    placeholder: 'Enter August',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'August Retainer',
    shortForm: 'Aug',
  },
  {
    key: 'september month',
    label: 'September',
    type: 'number-currency',
    placeholder: 'Enter September',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'September Retainer',
    shortForm: 'Sep',
  },
  {
    key: 'october month',
    label: 'October',
    type: 'number-currency',
    placeholder: 'Enter October',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'October Retainer',
    shortForm: 'Oct',
  },
  {
    key: 'november month',
    label: 'November',
    type: 'number-currency',
    placeholder: 'Enter November',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'November Retainer',
    shortForm: 'Nov',
  },
  {
    key: 'december month',
    label: 'December',
    type: 'number-currency',
    placeholder: 'Enter December',
    isMandatory: true,
    prefix: '$',
    fieldOf: 'retainer',
    detail: 'December Retainer',
    shortForm: 'Dec',
  },
];

export const agreementRetainer = [
  {
    key: 'fee_type',
    label: 'Fee Type',
    type: 'text',
  },
  {
    value: 'monthly_retainer',
    label: 'Retainer',
    key: 'monthly_retainer',
    type: 'number-currency',
    isMandatory: true,
    prefix: '$',
    subType: 'retainer_type',
  },
];

export const agreementRevShare = [
  {
    key: 'fee_type',
    label: 'Fee Type',
    type: 'text',
  },
  {
    key: 'rev_share',
    label: 'Rev Share %',
    type: 'choice',
    placeholder: 'Select Revenue Share',
    isMandatory: true,
    suffix: '%',
  },
  {
    key: 'sales_threshold',
    label: 'Rev Share Threshold',
    type: 'threshold',
    isMandatory: false,
    prefix: '$',
    subType: 'threshold_type',
  },
  {
    key: 'billing_minimum',
    label: 'BILLING MIN',
    type: 'number-currency',
    placeholder: 'Enter Billing Minimum',
    isMandatory: false,
    subtitle:
      'We will charge the greater of the value entered here or the % of revenue',
    prefix: '$',
  },
  {
    key: 'billing_cap',
    label: 'BILLING CAP',
    type: 'number-currency',
    placeholder: 'Enter Billing Cap',
    isMandatory: false,
    subtitle: ' We will charge no more than the amount entered here.',
    prefix: '$',
  },
];

export const agreementRetainerRevShare = [
  {
    key: 'fee_type',
    label: 'Fee Type',
    type: 'text',
  },
  {
    value: 'monthly_retainer',
    label: 'Retainer',
    key: 'monthly_retainer',
    type: 'number-currency',
    isMandatory: true,
    prefix: '$',
    subType: 'retainer_type',
  },
  {
    key: 'rev_share',
    label: 'Rev Share %',
    type: 'choice',
    placeholder: 'Select Revenue Share',
    isMandatory: true,
    suffix: '%',
  },
  {
    key: 'sales_threshold',
    label: 'Rev Share Threshold',
    type: 'threshold',
    isMandatory: false,
    prefix: '$',
    subType: 'threshold_type',
  },
  {
    key: 'billing_cap',
    label: 'BILLING CAP',
    type: 'number-currency',
    placeholder: 'Enter Billing Cap',
    isMandatory: false,
    subtitle: ' We will charge no more than the amount entered here.',
    prefix: '$',
  },
];

export const agreementDSP = [
  {
    key: 'dsp_fee',
    label: 'Monthly Ad Budget',
    type: 'number-currency',
    prefix: '$',
  },
  {
    key: 'agency_fee',
    label: 'Agency Fee',
    suffix: '%',
  },
];

export const agreementToggleFeeType = [
  {
    key: 'seller',
    name: 'Seller',
    id: 'negative',
  },
  {
    key: 'vendor',
    name: 'Vendor',
    id: 'positive',
  },
];

export const agreementDSPAdv = [
  {
    key: 'dsp_marketplaces',
    label: 'DSP Marketplace(s)',
    type: 'choice',
    placeholder: 'Select Marketplaces',
    isMandatory: true,
    part: 'dsp',
  },
  {
    key: 'dsp_length',
    label: 'DSP service length',
    type: 'text',
    placeholder: 'Select length',
    isMandatory: true,
    part: 'dsp',
  },
  {
    key: 'dsp_fee',
    label: 'Monthly Ad Budget',
    type: 'number-currency',
    prefix: '$',
  },
  {
    key: 'agency_fee',
    label: 'Agency Fee',
    type: 'text',
    suffix: '%',
  },
];
export const combinedRevOptions = [
  {
    key: 'rev_share',
    label: 'Rev Share %',
    type: 'choice',
    placeholder: 'Select Revenue Share',
    isMandatory: true,
    suffix: '%',
  },
  {
    key: 'sales_threshold',
    label: 'Rev Share Threshold',
    type: 'threshold',
    isMandatory: false,
    prefix: '$',
  },
];

export const newChildAgreementTypes = [
  {
    value: 'recurring',
    label: 'Recurring Service Agreement',
    sub: 'Standard Cancellation',
  },
  {
    value: 'recurring (90 day notice)',
    label: 'Recurring (90 day notice) Service Agreement',
    sub: '90 Day Cancellation',
  },
  { value: 'dsp only', label: 'DSP-Only Agreement', sub: null },
  { value: 'one time', label: 'One-Time Service Agreement', sub: null },
];

// https://bbe.atlassian.net/browse/PDV-9280
// option constant for pending pause request
export const pendingPauseRequestOptions = [
  { label: ' Approving the pending pause request', value: 'approved' },
  { label: ' Rejecting the pending pause request', value: 'rejected' },
  {
    label: ' Cancelling the pending pause request and submit a new one',
    value: 'cancelled',
  },
];

export const offBoardedRadioButtons = [
  {
    label: 'Yes',
    value: 'yes',
    name: 'is_off_boarded',
    key: 'is_off_boarded-yes',
  },
  {
    label: 'No',
    value: 'no',
    name: 'is_off_boarded',
    key: 'is_off_boarded-no',
  },
  {
    label: 'Yes',
    value: 'yes',
    name: 'is_ending_early',
    key: 'is_ending_early-yes',
  },
  {
    label: 'No',
    value: 'no',
    name: 'is_ending_early',
    key: 'is_ending_early-no',
  },
];
export const pendingAgreementInvoiceDetails = [
  {
    key: 'requestor_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black text-bold',
    label: 'Requestor:',
  },
  {
    key: 'requestor',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black',
  },
  {
    key: 'date_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black text-bold',
    label: 'Request Date:',
  },
  {
    key: 'created_at',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black',
    type: 'date',
  },
  {
    key: 'offboarding_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black text-bold',
    label: 'Off-boarding BP?:',
  },
  {
    key: 'is_off_boarded',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black',
  },
  {
    key: 'ending_early_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black text-bold',
    label: 'Ending Early?:',
  },
  {
    key: 'is_ending_early',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black',
  },
  // {
  //   key: 'last_date_label',
  //   property: 'col-6 mb-2',
  //   subProperty: 'normal-text-black text-bold',
  //   label: 'Last Service Date:',
  // },
  // {
  //   key: 'last_service_date',
  //   property: 'col-6 mb-2 text-right',
  //   subProperty: 'normal-text-black text-bold out-door',
  // },
];

export const pausingRequestInvoiceDetails = [
  {
    key: 'requestor_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black text-bold',
    label: 'Requestor:',
  },
  {
    key: 'requestor',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black',
  },
  {
    key: 'date_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black text-bold',
    label: 'Request Date:',
  },
  {
    key: 'created_at',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black',
    type: 'date',
  },
];

export const pausingRequestInvoiceTypes = [
  {
    key: 'retainer',
    label: 'Retainer',
    dbKey: 'retainer',
  },
  {
    key: 'revShare',
    label: 'Rev Share',
    dbKey: 'rev share',
  },
  {
    key: 'dsp',
    label: 'DSP',
    dbKey: 'dsp service',
  },
  {
    key: 'amc',
    label: 'AMC',
    dbKey: 'amc service',
  },
];

export const contractStatusIcons = {
  active: 'fa-light fa-signature',
  renewed: 'fa-light fa-file-contract',
  pause: 'fa-light fa-pause',
  cancel: 'fa-light fa-ban',
  'pending for cancellation': 'fa-light fa-file-invoice-dollar',
  'pending cancellation approval': 'fa-light fa-file-circle-xmark',
  'pending contract signature': 'fa-light fa-file-signature',
  'pending contract approval': 'fa-light fa-file-circle-check',
  'pending contract': 'fa-light fa-file-pen',
  completed: 'fa-light fa-handshake',
  'active pending for pause': 'fa-light fa-pause',
  'active partially pause': 'fa-light fa-pause',
};

export const agreementStatusNames = {
  active: 'Signed',
  'pending contract': 'Pending Agreement',
  'pending contract approval': 'Pending Agreement Approval',
  'pending contract signature': 'Pending Agreement Signature',
  cancel: 'Cancelled',
  pause: 'Paused',
  'pending for cancellation': 'Cancelled - Pending for Billing',
  'pending cancellation approval': 'Pending Cancellation Approval',
  'active partially pause': 'Active - Partially Paused',
};

// PDV-8316 - Contract Changes - language update and new BBE logo
// Define the default values as mentioned in this ticket
export const monthlyRetainerLimit = 6500; // old value wass 3000
export const monthlyRetainerLimitInString = '6500';
export const revShareLimit = 4; // old value was 3
export const revShareLimitInString = '4'; // old value was 3
export const revShareThresholdLimit = 375000; // newly added limit - 375,000
export const revShareThresholdLimitInString = '375000';

// display fee type information
export const displayFeeTypeInfo = `You will only be billed for the monthly retainer and revenue share % displayed below.`;

// display revenue share information
export const displayRevenueShareInfo = `A percentage of all Managed Channel Sales (retail dollars, net customer returns) for all sales each month through the Amazon Seller Central and/or Vendor Central account(s) that BBE manages for Client.`;

// display fix retainer information
export const displayFixedRetainerInfo = `The fee for the main Amazon marketplace as a retainer for services.This retainer is billed in advance for the month in which services are to be rendered.`;

// display quartely retainer information
export const displayQuarterlyRetainerInfo = `Quarterly fee for the main Amazon marketplace as a retainer for services. This retainer is billed in advance for the month in which services are to be rendered.`;

// display monthly retainer information
export const displayMonthlyRetainerInfo = `Monthly fee for the main Amazon marketplace as a retainer for services. This retainer is billed in advance for the month in which services are to be rendered.`;

// display rev share thrshold information
export const displayFixedThresholdInfo = `We will bill our revenue share % on any value above this threshold for Amazon Channel Sales (retail dollars, net customer returns) each month through the Amazon Seller Central and Vendor Central account(s) that BBE manages for Client.`;

// display billing cap info
export const displayBillingCapInfo = `We will charge no more than the amount listed here.`;

// display billing minimum info
export const displayBillingMinimumInfo = `We will charge the greater of the value shown here or the % of revenue listed above.`;

export const contactFields = [
  {
    key: 'first_name',
    type: 'text',
    placeholder: 'First Name',
    classname: 'col-6 ',
  },
  {
    key: 'last_name',
    type: 'text',
    placeholder: 'Last Name',
    classname: 'col-6 ',
  },
  {
    key: 'role',
    type: 'text',
    placeholder: 'Role',
    classname: 'col-12',
  },
  {
    key: 'email',
    type: 'email',
    placeholder: 'Email',
    classname: 'col-6',
  },
  {
    key: 'phone_number',
    type: 'text',
    placeholder: 'Phone Number',
    classname: 'col-6',
  },
];

export const OptimizationArray = [
  {
    label: 'Content',
    key: 'content_optimization',
    section: 1,
  },
  { label: 'Design', key: 'design_optimization', section: 1 },
  { label: 'Photography', key: 'photography_optimization', section: 1 },
  { label: 'Copy Listing', key: 'copy_listing_translation', section: 2 },
  { label: 'Creative', key: 'creative_listing_translation', section: 2 },
];
