import React from 'react';

import styled from 'styled-components';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  shape,
  string,
} from 'prop-types';

import AdPerformanceChart from './AdPerformanceChart';
import SpAsinAndCampaignContainer from './SpAsinAndCampaignContainer';
import SponsoredAdPerformanceGoalsChart from './SponsoredAdPerformanceGoalsChart';
import SponsoredAdMetrics from '../../../../../BrandPartner/AdManagerAdminDashboard/SponsoredDashboard/SponsoredAdMetrics';
import {
  WhiteCard,
  PageLoader,
  ToggleButton,
  DropDowns,
  ToggleSwitch,
  GrayMessageBar,
  MultipleDashedLineLabel,
  MultiSelectDropdownWithoutAll,
} from '../../../../../../common';
import {
  ADCOS,
  ADPERFORMANCE,
  ADSPEND,
  AdTypesOptions,
  CUSTOM,
  NTBORDERS,
  NTBSALES,
  PERCENTAGENTBORDERS,
  PERCENTAGENTBSALES,
  SELLERSMALL,
  VENDORSMALL,
  dailyWeeklyMonthlyOptions,
  noGraphDataMessage,
  performanceInsightMessage,
  sponoredMetricsList,
  sponsoredTooltipDataHTML,
} from '../../../../../../constants';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const _ = require('lodash');

export default function SponsoredPerformanceContainer({
  isCallSPAsinApi,
  customerId,
  childBrandId,
  currencySymbol,
  sponsoredAdType,
  setSelectedAdBox,
  selectedAdBox,
  adCurrentTotal,
  adPreviousTotal,
  adDifference,
  adGraphLoader,
  adChartData,
  selectedAdDF,
  adGroupBy,
  handleAdGroupBy,
  handleAdType,
  selectedAdType,
  getAdTypesSelectComponents,
  customDateError,
  subViewComponent,
  dateOptions,
  handleAdDailyFact,
  isApiCall,
  getSelectComponents,
  daysDiff,
  selectedMarketplace,
  adState,
  currentDateForSponsored,
  accountType,
  sponsoredGoalsMetricData,
  sponsoredGoalsToggle,
  setIsCallSPAsinApi,
  setSponsoredGoalsToggle,
  sponsoredAdGoalChartData,
  renderDataAvailableChildList,
  sponsoredAdMultiSelected,
  setSponsoredAdMultiSelected,
  handledSponsoredMetricsOnMenuClose,
}) {
  let options = [...dateOptions];
  if (daysDiff && options) {
    const updatedOption = options.map((obj) => {
      if (obj.value === 'week' && daysDiff < 7) {
        obj.isDisabled = true;
      }
      if (
        obj.value === 'month' &&
        daysDiff < currentDateForSponsored?.getDate()
      ) {
        obj.isDisabled = true;
      }
      if (obj.value === '30days' && daysDiff < 30) {
        obj.isDisabled = true;
      }

      return obj;
    });
    options = [...updatedOption];
  }
  const dashboardDynamicError = useSelector(
    (state) => state.userState?.showDynamicMsg,
  );

  const getAdTypeOptions = () => {
    let localAdType = AdTypesOptions;
    if (
      selectedMarketplace?.value !== 'Amazon.com' &&
      selectedMarketplace?.value !== 'all'
    ) {
      localAdType = AdTypesOptions.map((ele) => {
        return {
          value: ele?.value,
          label: ele?.label,
          key: ele?.key,
          isDisabled: ele?.value === 'television_campaign_report',
        };
      });
    }
    return localAdType;
  };

  const getSponsoredMetricOptions = () => {
    let localAdMetricsOptions = sponoredMetricsList;

    localAdMetricsOptions = sponoredMetricsList.map((ele) => {
      // PDV-9017  NTBOrders, NTBSales, percentageNtbSales, percentageNtbOrders will only be enabled
      //  if ad type filter is set to ALL, Sponsored Brands+Video, Sponsored Display otherwise, it will be grayed out.
      if (
        ([
          NTBORDERS,
          NTBSALES,
          PERCENTAGENTBORDERS,
          PERCENTAGENTBSALES,
        ].includes(ele?.value) &&
          ['product_campaign_report', 'television_campaign_report'].includes(
            selectedAdType?.value,
          )) ||
        // at least four option should be selected.
        (sponsoredAdMultiSelected?.length === 4 &&
          (sponsoredAdMultiSelected[0]?.value === ele?.value ||
            sponsoredAdMultiSelected[1]?.value === ele?.value ||
            sponsoredAdMultiSelected[2]?.value === ele?.value ||
            sponsoredAdMultiSelected[3]?.value === ele?.value))
      ) {
        return {
          value: ele?.value,
          label: ele?.label,
          isDisabled: true,
        };
      }

      // PDV-9017- if 8 metrics are already selected, the other metrics will be grayed out
      const isDisabled =
        sponsoredAdMultiSelected.length === 8 &&
        sponsoredAdMultiSelected.find((o) => o?.value === ele?.value) ===
          undefined;

      return {
        value: ele?.value,
        label: ele?.label,
        isDisabled,
      };
    });

    return localAdMetricsOptions;
  };

  // handle sponsored goals toggle events
  const handleSponsoredGoalsToggleEvents = () => {
    setSponsoredGoalsToggle(!sponsoredGoalsToggle);

    const isAdSpend = sponsoredAdMultiSelected?.some(
      (item) => item?.value === ADSPEND,
    );
    const isAdCos = sponsoredAdMultiSelected?.some(
      (item) => item?.value === ADCOS,
    );

    // if multiselected dropdown contain adspend or acos/roas metrics selected then make them TRUE in setSelectedAdbox and other metrics will be disabled otherwise setSponsoredGoalsToggle to false.
    if (isAdSpend) {
      setSelectedAdBox({ adSpend: true });
    } else if (isAdCos) {
      setSelectedAdBox({ adCos: true });
    } else {
      setSponsoredGoalsToggle(false);
    }
  };

  const renderSponsoredFilters = () => {
    let sponsoredDynamicErrorMessage = null;
    const sellerPlaceholder =
      dashboardDynamicError?.sponsored_performance_seller?.placeholder;
    const vendorPlaceholder =
      dashboardDynamicError?.sponsored_performance_vendor?.placeholder;
    if (accountType === SELLERSMALL && sellerPlaceholder) {
      sponsoredDynamicErrorMessage = sellerPlaceholder;
    }
    if (accountType === VENDORSMALL && vendorPlaceholder) {
      sponsoredDynamicErrorMessage = vendorPlaceholder;
    }

    return (
      <>
        <div className="col-md-4 col-12">
          <div className="black-heading-title mt-2 mb-4">
            Sponsored Ad Performance{' '}
            <FontAwesomeIcon
              icon="fal fa-info-circle"
              fontSize="13px"
              data-tip={sponsoredTooltipDataHTML}
              data-for="adSponsored"
            />
          </div>
          <ReactTooltip
            class="initialTextTransform"
            id="adSponsored"
            aria-haspopup="true"
            place="top"
            effect="solid"
            multiline
            html
          />
        </div>
        <div className="col-md-8 col-12">
          <ul className="ad-performance-nav">
            {/* render ad type drop-down */}
            <li
              className="ad-performance"
              id="BT-adperformancedata-alltypesfilter"
            >
              {DropDowns(
                'BT-SponsoredPerformanceAdTypes',
                'days-performance',
                getAdTypeOptions(),
                AdTypesOptions[0].label,
                getAdTypesSelectComponents,
                AdTypesOptions[0],
                handleAdType,
                adGraphLoader,
                null,
                selectedAdType,
              )}
            </li>
            <li
              className={`ad-performance ${
                subViewComponent === VENDORSMALL ? 'd-none' : ''
              }`}
              id="BT-adperformancedata-MetricsSelection"
            >
              {' '}
              <MultiSelectDropdownWithoutAll
                options={getSponsoredMetricOptions()}
                label="Metrics"
                multiSelectedValues={sponsoredAdMultiSelected}
                setMultiSelectedValues={setSponsoredAdMultiSelected}
                isDisabled={isApiCall}
                handleOnMenuClose={() => {
                  handledSponsoredMetricsOnMenuClose();
                }}
                multiselectLabel={null}
              />
            </li>
            <li className="ad-performance">
              {DropDowns(
                'BT-AdPerformanceTimeFrame',
                'days-performance',
                options,
                options[0].label,
                getSelectComponents,
                options[0],
                handleAdDailyFact,
                isApiCall,
                null,
                selectedAdDF,
                false,
              )}
            </li>
          </ul>{' '}
        </div>
        <div className="col-12">
          {sponsoredDynamicErrorMessage !== null ? (
            <GrayMessageBar message={sponsoredDynamicErrorMessage} />
          ) : null}
        </div>
      </>
    );
  };

  const addThousandComma = (num, decimalDigits = 2) => {
    if (num !== undefined && num !== null) {
      return num.toFixed(decimalDigits).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return num;
  };

  const renderErrorMessage = () => {
    let { fromDate } = customDateError;
    let { toDate } = customDateError;

    fromDate = dayjs(fromDate).format('MM/DD/YYYY');
    toDate = dayjs(toDate).subtract(1, 'day').format('MM/DD/YYYY');

    return (
      <div className="row">
        <div className="col-12 mt-2 mb-3 mr-3">
          <div className="normal-text-black first-white-card">
            <GrayMessageBar
              message={`There is no advertising data available from ${fromDate} to
            ${toDate}.`}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderAdGroupBy = () => {
    const previousClassName =
      _.size(selectedAdBox) === 1 ? `adSales block` : 'darkGray block';
    return (
      <>
        {_.size(selectedAdBox) <= 2 ? (
          <div className="col-md-6 col-sm-12 order-md-1 order-2 mt-2">
            <ul className="rechart-item">
              <li id="BT-adperformance-recentgraph" key="recent">
                <div className="weeks">
                  <span
                    className={
                      _.size(selectedAdBox) === 1
                        ? `adSales circle`
                        : 'darkGray circle'
                    }
                  />

                  <span>{sponsoredGoalsToggle ? 'Metrics' : 'Recent'}</span>
                </div>
              </li>
              {selectedAdDF.value !== CUSTOM ? (
                <li id="BT-adperformance-perviousgraph" key="previous">
                  <MultipleDashedLineLabel
                    label="Previous"
                    className={previousClassName}
                  />
                </li>
              ) : sponsoredGoalsToggle ? (
                <li id="BT-adperformance-perviousgraph" key="goals">
                  <MultipleDashedLineLabel
                    label="Goals"
                    className={previousClassName}
                  />
                </li>
              ) : null}
            </ul>
          </div>
        ) : (
          <div className="col-md-6 col-sm-12 order-md-1 order-2 mt-2" />
        )}
        <div className="col-md-6 col-sm-12 order-md-2 order-1">
          <ToggleButton>
            <div className="days-container ">
              <ul
                className={adGraphLoader ? 'disabled-tab days-tab' : 'days-tab'}
              >
                {dailyWeeklyMonthlyOptions?.map((item) => {
                  return (
                    <li key={item?.id}>
                      <input
                        className="d-none"
                        type="radio"
                        id={item?.id}
                        name="flexRadioDefault"
                        value={item?.value}
                        checked={adGroupBy === item?.value}
                        onChange={() => handleAdGroupBy(item?.value)}
                      />
                      <label htmlFor={item?.id}>{item?.label}</label>
                    </li>
                  );
                })}
              </ul>
            </div>
          </ToggleButton>
        </div>
      </>
    );
  };

  const showGoalsToggle = () => {
    return (
      <ToggleSwitch
        className="custom-switch mt-2"
        // key={`sellerDetails${graphIndex}${option.id}`}
      >
        <label
          htmlFor="goalsToggle"
          className={`label-name ${
            selectedAdDF.value === CUSTOM
              ? Object.keys(sponsoredGoalsMetricData)?.length
                ? ''
                : 'disabled'
              : 'disabled'
          }`}
          // key={`${idName}${graphIndex}`}
        >
          Goals
          <input
            type="checkbox"
            className="label-name"
            id="goalsToggle"
            onClick={() => {
              handleSponsoredGoalsToggleEvents();
            }}
            onChange={() => {}}
            checked={sponsoredGoalsToggle}
          />
          <span className="slider round" />
        </label>
      </ToggleSwitch>
    );
  };

  // dislplay sponsored asin and campaign performance section
  const displaySPCampaignAndAsinSection = () => {
    return (
      <SpAsinAndCampaignContainer
        isCallSPAsinApi={isCallSPAsinApi}
        customerId={customerId}
        childBrandId={childBrandId}
        selectedDF={selectedAdDF?.value}
        accountType={accountType}
        currencySymbol={currencySymbol}
        selectedMarketplace={selectedMarketplace?.value}
        sponsoredAdType={sponsoredAdType}
        adState={adState}
        setIsCallSPAsinApi={setIsCallSPAsinApi}
      />
    );
  };

  return (
    <WhiteCard>
      <div className="row">{renderSponsoredFilters()}</div>
      {renderDataAvailableChildList('sponsored')}
      {selectedAdDF.value !== CUSTOM ? (
        <GrayMessageBar message={performanceInsightMessage?.sponsored} />
      ) : null}
      <SponsoredAdMetrics
        currencySymbol={currencySymbol}
        setSelectedAdMetrics={setSelectedAdBox}
        selectedAdMetrics={selectedAdBox}
        adCurrentTotal={adCurrentTotal}
        addThousandComma={addThousandComma}
        adPreviousTotal={adPreviousTotal}
        adDifference={adDifference}
        isApiCall={isApiCall}
        sponsoredGoalsMetricData={sponsoredGoalsMetricData}
        sponsoredGoalsToggle={sponsoredGoalsToggle}
        sponsoredAdMultiSelected={sponsoredAdMultiSelected}
      />
      {showGoalsToggle()}
      {selectedAdDF.value === CUSTOM &&
      subViewComponent === customDateError?.accountType &&
      customDateError.selectedTab === ADPERFORMANCE
        ? renderErrorMessage()
        : null}
      <div className="row mt-4 mb-3">{renderAdGroupBy()}</div>
      {adGraphLoader ? (
        <PageLoader
          component="performance-graph"
          color="#FF5933"
          type="detail"
          width={40}
          height={40}
        />
      ) : adChartData.length >= 1 && !sponsoredGoalsToggle ? (
        <AdPerformanceChart
          chartId="adChart"
          chartData={adChartData}
          currencySymbol={currencySymbol}
          selectedMetrics={selectedAdBox}
          selectedDF={selectedAdDF?.value}
          groupBy={adGroupBy}
        />
      ) : sponsoredAdGoalChartData?.length && sponsoredGoalsToggle ? (
        <SponsoredAdPerformanceGoalsChart
          chartId="goalsSponsoredAdChart"
          chartData={sponsoredAdGoalChartData}
          currencySymbol={currencySymbol}
          selectedMetrics={selectedAdBox}
          selectedDF={selectedAdDF.value}
          groupBy={adGroupBy}
        />
      ) : (
        <NoData>{noGraphDataMessage}</NoData>
      )}
      <div className="straight-line horizontal-line mb-4 mt-3" />
      {displaySPCampaignAndAsinSection()}
    </WhiteCard>
  );
}

SponsoredPerformanceContainer.defaultProps = {
  isCallSPAsinApi: false,
  customerId: '',
  childBrandId: null,
  sponsoredAdType: 'all',
  sponsoredGoalsToggle: false,
  adGraphLoader: false,
  isApiCall: false,
  daysDiff: 0,
  currencySymbol: '',
  subViewComponent: '',
  accountType: '',
  adGroupBy: '',
  selectedAdBox: {},
  adCurrentTotal: {},
  adPreviousTotal: {},
  adDifference: {},
  adChartData: {},
  selectedAdDF: {},
  selectedMarketplace: {},
  customDateError: {},
  sponsoredGoalsMetricData: {},
  sponsoredAdGoalChartData: [],
  selectedAdType: {},
  dateOptions: [{}],
  sponsoredAdMultiSelected: [{}],
  currentDateForSponsored: null,
  adState: [],
  setIsCallSPAsinApi: () => {},
  setSelectedAdBox: () => {},
  setSponsoredGoalsToggle: () => {},
  getAdTypesSelectComponents: () => {},
  getSelectComponents: () => {},
  handleAdDailyFact: () => {},
  handleAdGroupBy: () => {},
  handleAdType: () => {},
  renderDataAvailableChildList: () => {},
  setSponsoredAdMultiSelected: () => {},
  handledSponsoredMetricsOnMenuClose: () => {},
};

SponsoredPerformanceContainer.propTypes = {
  isCallSPAsinApi: bool,
  customerId: string,
  childBrandId: string,
  sponsoredAdType: string,
  sponsoredGoalsToggle: bool,
  adGraphLoader: bool,
  isApiCall: bool,
  currencySymbol: string,
  subViewComponent: string,
  adGroupBy: string,
  daysDiff: number,
  accountType: string,
  selectedMarketplace: shape({}),
  dateOptions: arrayOf(Array),
  adState: arrayOf(shape()),
  selectedAdBox: instanceOf(Object),
  adCurrentTotal: instanceOf(Object),
  adPreviousTotal: instanceOf(Object),
  adDifference: instanceOf(Object),
  adChartData: arrayOf(Array),
  selectedAdDF: instanceOf(Object),
  customDateError: shape({}),
  selectedAdType: shape({}),
  sponsoredAdMultiSelected: arrayOf(shape()),
  currentDateForSponsored: instanceOf(Date),
  sponsoredGoalsMetricData: instanceOf(Object),
  sponsoredAdGoalChartData: instanceOf(Object),
  setIsCallSPAsinApi: func,
  getAdTypesSelectComponents: func,
  handleAdDailyFact: func,
  getSelectComponents: func,
  setSponsoredGoalsToggle: func,
  handleAdGroupBy: func,
  handleAdType: func,
  renderDataAvailableChildList: func,
  setSelectedAdBox: func,
  setSponsoredAdMultiSelected: func,
  handledSponsoredMetricsOnMenuClose: func,
};
const NoData = styled.div`
  margin: 3em;
  text-align: center;
`;
