import dayjs from 'dayjs';

// bind the all(sales, sponsores, dsp, shippedcogs, ordered) metrics responce to show the graph and table
const BindGraphDataApiResponse = (response, acType, selectedState) => {
  const tempData = [];
  let sellerVendorField = {};

  const previousKey =
    acType === 'seller' ? response.previous_seller : response.previous_vendor;
  const currentKey =
    acType === 'seller' ? response.current_seller : response.current_vendor;

  const previousTotalKey =
    acType === 'seller'
      ? response?.previous_seller_total
      : response?.previous_vendor_total;
  const currentTotalKey =
    acType === 'seller'
      ? response?.current_seller_total
      : response?.current_vendor_total;

  const currentChangeInValueKey =
    acType === 'seller'
      ? response?.current_seller_change
      : response?.current_vendor_change;
  const currentChangeInPercentKey =
    acType === 'seller'
      ? response?.current_seller_percent_change
      : response?.current_vendor_percent_change;

  const totalChangeInValueKey =
    acType === 'seller'
      ? response?.seller_total_change_data
      : response?.vendor_total_change_data;
  const totalChangeInPercentKey =
    acType === 'seller'
      ? response?.seller_total_percent_change_data
      : response?.vendor_total_percent_change_data;

  // get a last date of the week
  const getYesterday = (reportDate) => {
    const yesterday = new Date(reportDate);
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  };

  const getEndDate = (index, graphDataArray) => {
    return dayjs(
      index + 1 < graphDataArray?.length
        ? getYesterday(graphDataArray[index + 1]?.report_date)
        : response?.previous_end_date,
    ).format('MMM D YYYY');
  };

  // filterout previous data in one temporary object.
  if (previousKey?.length) {
    previousKey.forEach((item, index) => {
      const previousDate = selectedState;

      if (acType === 'seller') {
        sellerVendorField = {
          salesRevenueTotalPrevious: item?.revenue,
          salesRevenuePpcPrevious: item?.revenue_ppc,
          salesRevenueDspPrevious: item?.revenue_dsp,
          salesTacosPrevious: item?.tacos,
          salesUnitSoldPrevious: item?.units_sold,
          salesTrafficPrevious: item?.traffic,
          salesConversionPrevious: item?.conversion,
          salesAdSpendTotalPrevious: item?.ad_spend_total,
          salesAdSpendPpcPrevious: item?.sales_ad_spend,
          salesAdSpendDspPrevious: item?.sales_dsp_spends,

          salesRevenueTotalPreviousLabel:
            item?.revenue !== null ? item.revenue.toFixed(2) : '0.00',
          salesRevenuePpcPreviousLabel:
            item?.revenue_ppc !== null ? item.revenue_ppc.toFixed(2) : '0.00',
          salesRevenueDspPreviousLabel:
            item?.revenue_dsp !== null ? item.revenue_dsp.toFixed(2) : '0.00',
          salesTacosPreviousLabel:
            item?.tacos !== null ? item.tacos.toFixed(2) : '0.00',
          salesUnitSoldPreviousLabel:
            item?.units_sold !== null ? item.units_sold.toFixed(0) : '0.00',
          salesTrafficPreviousLabel:
            item?.traffic !== null ? item.traffic.toFixed(0) : '0.00',
          salesConversionPreviousLabel:
            item?.conversion !== null ? item.conversion.toFixed(2) : '0.00',
          salesAdSpendTotalPreviousLabel:
            item?.ad_spend_total !== null
              ? item.ad_spend_total.toFixed(2)
              : '0.00',
          salesAdSpendPpcPreviousLabel:
            item?.sales_ad_spend !== null
              ? item.sales_ad_spend.toFixed(2)
              : '0.00',
          salesAdSpendDspPreviousLabel:
            item?.sales_dsp_spends !== null
              ? item.sales_dsp_spends.toFixed(2)
              : '0.00',
        };
      } else {
        sellerVendorField = {
          salesOrderedRevenuePrevious: item?.ordered_revenue,
          salesOrderedGlanceViewsPrevious: item?.glance_views,
          salesOrderedConversionRatePrevious: item?.conversion_rate,
          SalesOrderedUnitsPrevious: item?.ordered_units,
          salesShippedCogsPrevious: item?.shipped_cogs,
          salesShippedGlanceViewsPrevious: item?.glance_views,
          salesShippedConversionRatePrevious: item?.conversion_rate,
          salesShippedCogsUnitPrevious: item?.shipped_units,

          salesOrderedRevenuePreviousLabel:
            item?.ordered_revenue !== null
              ? item.ordered_revenue.toFixed(2)
              : '0.00',
          salesOrderedGlanceViewsPreviousLabel:
            item?.glance_views !== null ? item.glance_views.toFixed(0) : '0.00',
          salesOrderedConversionRatePreviousLabel:
            item?.conversion_rate !== null
              ? item.conversion_rate.toFixed(2)
              : '0.00',
          salesOrderedUnitsPreviousLabel:
            item?.ordered_units !== null
              ? item.ordered_units.toFixed(0)
              : '0.00',
          salesShippedCogsPreviousLabel:
            item?.shipped_cogs !== null ? item.shipped_cogs.toFixed(2) : '0.00',
          salesShippedGlanceViewsPreviousLabel:
            item?.glance_views !== null ? item.glance_views.toFixed(0) : '0.00',
          salesShippedUnitsPreviousLabel:
            item?.shipped_units !== null
              ? item.shipped_units.toFixed(0)
              : '0.00',
          salesShippedConversionRatePreviousLabel:
            item?.conversion_rate !== null
              ? item.conversion_rate.toFixed(2)
              : '0.00',
        };
      }

      tempData.push({
        ...sellerVendorField,

        /* ********** Binding the All sponsored previous keys ********** */
        adSalesPrevious: item?.all_ad_sales,
        adSpendPrevious: item?.all_ad_spend,
        adConversionRatePrevious: item?.all_ad_conversion_rate,
        adImpressionsPrevious: item?.all_impressions,
        adAcosPrevious: item?.all_acos,
        adRoasPrevious: item?.all_roas,
        adClicksPrevious: item?.all_clicks,
        adClickThroughRatePrevious: item?.all_ctr,
        adCostPerClickPrevious: item?.all_cost_per_click,

        /* ********** Binding the sponsored product previous keys ********** */
        adSalesSPPrevious: item?.product_campaign_report_ad_sales,
        adSpendSPPrevious: item?.product_campaign_report_ad_spend,
        adConversionRateSPPrevious:
          item?.product_campaign_report_ad_conversion_rate,
        adImpressionsSPPrevious: item?.product_campaign_report_impressions,
        adAcosSPPrevious: item?.product_campaign_report_acos,
        adRoasSPPrevious: item?.product_campaign_report_roas,
        adClicksSPPrevious: item?.product_campaign_report_clicks,
        adClickThroughRateSPPrevious: item?.product_campaign_report_ctr,
        adCostPerClickSPPrevious: item?.product_campaign_report_cost_per_click,

        /* ********** Binding the sponsored brand previous keys ********** */
        adSalesSBPrevious: item?.brand_campaign_report_ad_sales,
        adSpendSBPrevious: item?.brand_campaign_report_ad_spend,
        adConversionRateSBPrevious:
          item?.brand_campaign_report_ad_conversion_rate,
        adImpressionsSBPrevious: item?.brand_campaign_report_impressions,
        adAcosSBPrevious: item?.brand_campaign_report_acos,
        adRoasSBPrevious: item?.brand_campaign_report_roas,
        adClicksSBPrevious: item?.brand_campaign_report_clicks,
        adClickThroughRateSBPrevious: item?.brand_campaign_report_ctr,
        adCostPerClickSBPrevious: item?.brand_campaign_report_cost_per_click,

        /* ********** Binding the sponsored video previous keys ********** */
        adSalesSBVPrevious: item?.brand_video_campaign_report_ad_sales,
        adSpendSBVPrevious: item?.brand_video_campaign_report_ad_spend,
        adConversionRateSBVPrevious:
          item?.brand_video_campaign_report_ad_conversion_rate,
        adImpressionsSBVPrevious: item?.brand_video_campaign_report_impressions,
        adAcosSBVPrevious: item?.brand_video_campaign_report_acos,
        adRoasSBVPrevious: item?.brand_video_campaign_report_roas,
        adClicksSBVPrevious: item?.brand_video_campaign_report_clicks,
        adClickThroughRateSBVPrevious: item?.brand_video_campaign_report_ctr,
        adCostPerClickSBVPrevious:
          item?.brand_video_campaign_report_cost_per_click,

        /* ********** Binding the sponsored display previous keys ********** */
        adSalesSDPrevious: item?.sponsored_display_campaign_report_ad_sales,
        adSpendSDPrevious: item?.sponsored_display_campaign_report_ad_spend,
        adConversionRateSDPrevious:
          item?.sponsored_display_campaign_report_ad_conversion_rate,
        adImpressionsSDPrevious:
          item?.sponsored_display_campaign_report_impressions,
        adAcosSDPrevious: item?.sponsored_display_campaign_report_acos,
        adRoasSDPrevious: item?.sponsored_display_campaign_report_roas,
        adClicksSDPrevious: item?.sponsored_display_campaign_report_clicks,
        adClickThroughRateSDPrevious:
          item?.sponsored_display_campaign_report_ctr,
        adCostPerClickSDPrevious:
          item?.sponsored_display_campaign_report_cost_per_click,

        /* ********** Binding the dsp previous keys ********** */
        dspImpressionsPrevious: item?.dsp_impressions,
        dspSpendPrevious: item?.dsp_dsp_spend,
        dspTotalProductSalesPrevious: item?.total_product_sales,
        dspTotalRoasPrevious: item?.total_dsp_roas,
        dspTotalDpvrPrevious: item?.total_dpvr,
        dspTotalNewBrandPurchasesPrevious: item?.ttl_new_brand_purchases,
        dspProductSalesPrevious: item?.product_sales,
        dspRoasPrevious: item?.dsp_roas,

        previousDate,
        previousDateRange: {
          startDate: dayjs(item?.report_date).format('MMM D YYYY'),
          endDate: getEndDate(index, previousKey, 'previous'),
        },

        /* ********** Binding the All sponsored previous label keys ********** */
        adSalesPreviousLabel:
          item?.all_ad_sales !== null ? item.all_ad_sales.toFixed(2) : '0.00',
        adSpendPreviousLabel:
          item?.all_ad_spend !== null ? item.all_ad_spend.toFixed(2) : '0.00',
        adConversionRatePreviousLabel:
          item?.all_ad_conversion_rate !== null
            ? item.all_ad_conversion_rate.toFixed(2)
            : '0.00',
        adImpressionsPreviousLabel:
          item?.all_impressions !== null ? item.all_impressions : '0.00',
        adAcosPreviousLabel:
          item?.all_acos !== null ? item.all_acos.toFixed(2) : '0.00',
        adRoasPreviousLabel:
          item?.all_roas !== null ? item.all_roas.toFixed(2) : '0.00',
        adClicksPreviousLabel:
          item?.all_clicks !== null ? item.all_clicks : '0.00',
        adClickThroughRatePreviousLabel:
          item?.all_ctr !== null ? item.all_ctr.toFixed(2) : '0.00',
        adCostPerClickPreviousLabel:
          item?.all_cost_per_click !== null
            ? item.all_cost_per_click.toFixed(2)
            : '0.00',

        /* ********** Binding the sponsored product previous label keys ********** */
        adSalesSPPreviousLabel:
          item?.product_campaign_report_ad_sales !== null
            ? item.product_campaign_report_ad_sales.toFixed(2)
            : '0.00',
        adSpendSPPreviousLabel:
          item?.product_campaign_report_ad_spend !== null
            ? item.product_campaign_report_ad_spend.toFixed(2)
            : '0.00',
        adConversionRateSPPreviousLabel:
          item?.product_campaign_report_ad_conversion_rate !== null
            ? item.product_campaign_report_ad_conversion_rate.toFixed(2)
            : '0.00',
        adImpressionsSPPreviousLabel:
          item?.product_campaign_report_impressions !== null
            ? item.product_campaign_report_impressions
            : '0.00',
        adAcosSPPreviousLabel:
          item?.product_campaign_report_acos !== null
            ? item.product_campaign_report_acos.toFixed(2)
            : '0.00',
        adRoasSPPreviousLabel:
          item?.product_campaign_report_roas !== null
            ? item.product_campaign_report_roas.toFixed(2)
            : '0.00',
        adClicksSPPreviousLabel:
          item?.product_campaign_report_clicks !== null
            ? item.product_campaign_report_clicks
            : '0.00',
        adClickThroughRateSPPreviousLabel:
          item?.product_campaign_report_ctr !== null
            ? item.product_campaign_report_ctr.toFixed(2)
            : '0.00',
        adCostPerClickSPPreviousLabel:
          item?.product_campaign_report_cost_per_click !== null
            ? item.product_campaign_report_cost_per_click.toFixed(2)
            : '0.00',

        /* ********** Binding the sponsored brand previous label keys ********** */
        adSalesSBPreviousLabel:
          item?.brand_campaign_report_ad_sales !== null
            ? item.brand_campaign_report_ad_sales.toFixed(2)
            : '0.00',
        adSpendSBPreviousLabel:
          item?.brand_campaign_report_ad_spend !== null
            ? item.brand_campaign_report_ad_spend.toFixed(2)
            : '0.00',
        adConversionRateSBPreviousLabel:
          item?.brand_campaign_report_ad_conversion_rate !== null
            ? item.brand_campaign_report_ad_conversion_rate.toFixed(2)
            : '0.00',
        adImpressionsSBPreviousLabel:
          item?.brand_campaign_report_impressions !== null
            ? item.brand_campaign_report_impressions
            : '0.00',
        adAcosSBPreviousLabel:
          item?.brand_campaign_report_acos !== null
            ? item.brand_campaign_report_acos.toFixed(2)
            : '0.00',
        adRoasSBPreviousLabel:
          item?.brand_campaign_report_roas !== null
            ? item.brand_campaign_report_roas.toFixed(2)
            : '0.00',
        adClicksSBPreviousLabel:
          item?.brand_campaign_report_clicks !== null
            ? item.brand_campaign_report_clicks
            : '0.00',
        adClickThroughRateSBPreviousLabel:
          item?.brand_campaign_report_ctr !== null
            ? item.brand_campaign_report_ctr.toFixed(2)
            : '0.00',
        adCostPerClickSBPreviousLabel:
          item?.brand_campaign_report_cost_per_click !== null
            ? item.brand_campaign_report_cost_per_click.toFixed(2)
            : '0.00',

        /* ********** Binding the sponsored video previous label keys ********** */
        adSalesSBVPreviousLabel:
          item?.brand_video_campaign_report_ad_sales !== null
            ? item.brand_video_campaign_report_ad_sales.toFixed(2)
            : '0.00',
        adSpendSBVPreviousLabel:
          item?.brand_video_campaign_report_ad_spend !== null
            ? item.brand_video_campaign_report_ad_spend.toFixed(2)
            : '0.00',
        adConversionRateSBVPreviousLabel:
          item?.brand_video_campaign_report_ad_conversion_rate !== null
            ? item.brand_video_campaign_report_ad_conversion_rate.toFixed(2)
            : '0.00',
        adImpressionsSBVPreviousLabel:
          item?.brand_video_campaign_report_impressions !== null
            ? item.brand_video_campaign_report_impressions
            : '0.00',
        adAcosSBVPreviousLabel:
          item?.brand_video_campaign_report_acos !== null
            ? item.brand_video_campaign_report_acos.toFixed(2)
            : '0.00',
        adRoasSBVPreviousLabel:
          item?.brand_video_campaign_report_roas !== null
            ? item.brand_video_campaign_report_roas.toFixed(2)
            : '0.00',
        adClicksSBVPreviousLabel:
          item?.brand_video_campaign_report_clicks !== null
            ? item.brand_video_campaign_report_clicks
            : '0.00',
        adClickThroughRateSBVPreviousLabel:
          item?.brand_video_campaign_report_ctr !== null
            ? item.brand_video_campaign_report_ctr.toFixed(2)
            : '0.00',
        adCostPerClickSBVPreviousLabel:
          item?.brand_video_campaign_report_cost_per_click !== null
            ? item.brand_video_campaign_report_cost_per_click.toFixed(2)
            : '0.00',

        /* ********** Binding the sponsored display previous label keys ********** */
        adSalesSDPreviousLabel:
          item?.sponsored_display_campaign_report_ad_sales !== null
            ? item.sponsored_display_campaign_report_ad_sales.toFixed(2)
            : '0.00',
        adSpendSDPreviousLabel:
          item?.sponsored_display_campaign_report_ad_spend !== null
            ? item.sponsored_display_campaign_report_ad_spend.toFixed(2)
            : '0.00',
        adConversionRateSDPreviousLabel:
          item?.sponsored_display_campaign_report_ad_conversion_rate !== null
            ? item.sponsored_display_campaign_report_ad_conversion_rate.toFixed(
                2,
              )
            : '0.00',
        adImpressionsSDPreviousLabel:
          item?.sponsored_display_campaign_report_impressions !== null
            ? item.sponsored_display_campaign_report_impressions
            : '0.00',
        adAcosSDPreviousLabel:
          item?.sponsored_display_campaign_report_acos !== null
            ? item.sponsored_display_campaign_report_acos.toFixed(2)
            : '0.00',
        adRoasSDPreviousLabel:
          item?.sponsored_display_campaign_report_roas !== null
            ? item.sponsored_display_campaign_report_roas.toFixed(2)
            : '0.00',
        adClicksSDPreviousLabel:
          item?.sponsored_display_campaign_report_clicks !== null
            ? item.sponsored_display_campaign_report_clicks
            : '0.00',
        adClickThroughRateSDPreviousLabel:
          item?.sponsored_display_campaign_report_ctr !== null
            ? item.sponsored_display_campaign_report_ctr.toFixed(2)
            : '0.00',
        adCostPerClickSDPreviousLabel:
          item?.sponsored_display_campaign_report_cost_per_click !== null
            ? item.sponsored_display_campaign_report_cost_per_click.toFixed(2)
            : '0.00',

        /* ********** Binding the dsp previous label keys ********** */
        dspImpressionsPreviousLabel:
          item?.dsp_impressions !== null
            ? item.dsp_impressions.toFixed(2)
            : '0.00',
        dspSpendPreviousLabel:
          item?.dsp_dsp_spend !== null ? item.dsp_dsp_spend.toFixed(2) : '0.00',
        dspTotalProductSalesPreviousLabel:
          item?.total_product_sales !== null
            ? item.total_product_sales.toFixed(2)
            : '0.00',
        dspTotalRoasPreviousLabel:
          item?.total_dsp_roas !== null
            ? item.total_dsp_roas.toFixed(2)
            : '0.00',
        dspTotalDpvrPreviousLabel:
          item?.total_dpvr !== null ? item.total_dpvr.toFixed(2) : '0.00',
        dspTotalNewBrandPurchasesPreviousLabel:
          item?.ttl_new_brand_purchases !== null
            ? item.ttl_new_brand_purchases.toFixed(2)
            : '0.00',
        dspProductSalesPreviousLabel:
          item?.product_sales !== null ? item.product_sales.toFixed(2) : '0.00',
        dspRoasPreviousLabel:
          item?.dsp_roas !== null ? item.dsp_roas.toFixed(2) : '0.00',
      });
    });
  }

  // filterout current data in one temporary object.
  if (currentKey && currentKey.length) {
    currentKey.forEach((item, index) => {
      const currentReportDate = dayjs(item.report_date).format('MMM D YYYY');
      // add the current data at same index of prevoius in temporary object
      if (previousKey && index < previousKey.length) {
        if (acType === 'seller') {
          // bind seller metrics current value
          tempData[index].salesRevenueTotalCurrent = item?.revenue;
          tempData[index].salesRevenuePpcCurrent = item?.revenue_ppc;
          tempData[index].salesRevenueDspCurrent = item?.revenue_dsp;
          tempData[index].salesTacosCurrent = item?.tacos;
          tempData[index].salesUnitSoldCurrent = item?.units_sold;
          tempData[index].salesTrafficCurrent = item?.traffic;
          tempData[index].salesConversionCurrent = item?.conversion;
          tempData[index].salesAdSpendTotalCurrent = item?.ad_spend_total;
          tempData[index].salesAdSpendPpcCurrent = item?.sales_ad_spend;
          tempData[index].salesAdSpendDspCurrent = item?.sales_dsp_spends;

          // bind seller metrics current value for tooltip
          tempData[index].salesRevenueTotalCurrentLabel =
            item?.revenue !== null ? item.revenue.toFixed(2) : '0.00';
          tempData[index].salesRevenuePpcCurrentLabel =
            item?.revenue_ppc !== null ? item.revenue_ppc.toFixed(2) : '0.00';
          tempData[index].salesRevenueDspCurrentLabel =
            item?.revenue_dsp !== null ? item.revenue_dsp.toFixed(2) : '0.00';
          tempData[index].salesTacosCurrentLabel =
            item?.tacos !== null ? item.tacos.toFixed(2) : '0.00';
          tempData[index].salesUnitSoldCurrentLabel =
            item?.units_sold !== null ? item.units_sold.toFixed(0) : '0.00';
          tempData[index].salesTrafficCurrentLabel =
            item?.traffic !== null ? item.traffic.toFixed(0) : '0.00';
          tempData[index].salesConversionCurrentLabel =
            item?.conversion !== null ? item.conversion.toFixed(2) : '0.00';
          tempData[index].salesAdSpendTotalCurrentLabel =
            item?.ad_spend_total !== null
              ? item.ad_spend_total.toFixed(2)
              : '0.00';
          tempData[index].salesAdSpendPpcCurrentLabel =
            item?.sales_ad_spend !== null
              ? item.sales_ad_spend.toFixed(2)
              : '0.00';
          tempData[index].salesAdSpendDspCurrentLabel =
            item?.sales_dsp_spends !== null
              ? item.sales_dsp_spends.toFixed(2)
              : '0.00';
        } else {
          // bind vendor metrics current value
          tempData[index].salesOrderedRevenueCurrent = item?.ordered_revenue;
          tempData[index].salesOrderedGlanceViewsCurrent = item?.glance_views;
          tempData[index].salesOrderedUnitsCurrent = item?.ordered_units;
          tempData[index].salesOrderedConversionRateCurrent =
            item?.conversion_rate;
          tempData[index].salesShippedCogsCurrent = item?.shipped_cogs;
          tempData[index].salesShippedGlanceViewsCurrent = item?.glance_views;
          tempData[index].salesShippedConversionRateCurrent =
            item?.conversion_rate;
          tempData[index].salesShippedUnitsCurrent = item?.shipped_units;

          // bind vendor metrics current value for tooltip
          tempData[index].salesOrderedRevenueCurrentLabel =
            item?.ordered_revenue !== null
              ? item.ordered_revenue.toFixed(2)
              : '0.00';
          tempData[index].salesOrderedGlanceViewsCurrentLabel =
            item?.glance_views !== null ? item.glance_views.toFixed(0) : '0.00';
          tempData[index].salesOrderedUnitsCurrentLabel =
            item?.ordered_units !== null
              ? item.ordered_units.toFixed(0)
              : '0.00';
          tempData[index].salesOrderedConversionRateCurrentLabel =
            item?.conversion_rate !== null
              ? item.conversion_rate.toFixed(2)
              : '0.00';
          tempData[index].salesShippedCogsCurrentLabel =
            item?.shipped_cogs !== null ? item.shipped_cogs.toFixed(2) : '0.00';
          tempData[index].salesShippedUnitsCurrentLabel =
            item?.shipped_units !== null
              ? item.shipped_units.toFixed(0)
              : '0.00';
          tempData[index].salesShippedGlanceViewsCurrentLabel =
            item?.glance_views !== null ? item.glance_views.toFixed(0) : '0.00';
          tempData[index].salesShippedConversionRateCurrentLabel =
            item?.conversion_rate !== null
              ? item.conversion_rate.toFixed(2)
              : '0.00';
        }

        tempData[index].date = currentReportDate;

        /* ********** Binding the All sponsored current keys ********** */
        tempData[index].adSalesCurrent = item?.all_ad_sales;
        tempData[index].adSpendCurrent = item?.all_ad_spend;
        tempData[index].adConversionRateCurrent = item?.all_ad_conversion_rate;
        tempData[index].adImpressionsCurrent = item?.all_impressions;
        tempData[index].adAcosCurrent = item?.all_acos;
        tempData[index].adRoasCurrent = item?.all_roas;
        tempData[index].adClicksCurrent = item?.all_clicks;
        tempData[index].adClickThroughRateCurrent = item?.all_ctr;
        tempData[index].adCostPerClickCurrent = item?.all_cost_per_click;

        /* ********** Binding the sponsored product current keys ********** */
        tempData[index].adSalesSPCurrent =
          item?.product_campaign_report_ad_sales;
        tempData[index].adSpendSPCurrent =
          item?.product_campaign_report_ad_spend;
        tempData[index].adConversionRateSPCurrent =
          item?.product_campaign_report_ad_conversion_rate;
        tempData[index].adImpressionsSPCurrent =
          item?.product_campaign_report_impressions;
        tempData[index].adAcosSPCurrent = item?.product_campaign_report_acos;
        tempData[index].adRoasSPCurrent = item?.product_campaign_report_roas;
        tempData[index].adClicksSPCurrent =
          item?.product_campaign_report_clicks;
        tempData[index].adClickThroughRateSPCurrent =
          item?.product_campaign_report_ctr;
        tempData[index].adCostPerClickSPCurrent =
          item?.product_campaign_report_cost_per_click;

        /* ********** Binding the sponsored brand current keys ********** */
        tempData[index].adSalesSBCurrent = item?.brand_campaign_report_ad_sales;
        tempData[index].adSpendSBCurrent = item?.brand_campaign_report_ad_spend;
        tempData[index].adConversionRateSBCurrent =
          item?.brand_campaign_report_ad_conversion_rate;
        tempData[index].adImpressionsSBCurrent =
          item?.brand_campaign_report_impressions;
        tempData[index].adAcosSBCurrent = item?.brand_campaign_report_acos;
        tempData[index].adRoasSBCurrent = item?.brand_campaign_report_roas;
        tempData[index].adClicksSBCurrent = item?.brand_campaign_report_clicks;
        tempData[index].adClickThroughRateSBCurrent =
          item?.brand_campaign_report_ctr;
        tempData[index].adCostPerClickSBCurrent =
          item?.brand_campaign_report_cost_per_click;

        /* ********** Binding the sponsored video current keys ********** */
        tempData[index].adSalesSBVCurrent =
          item?.brand_video_campaign_report_ad_sales;
        tempData[index].adSpendSBVCurrent =
          item?.brand_video_campaign_report_ad_spend;
        tempData[index].adConversionRateSBVCurrent =
          item?.brand_video_campaign_report_ad_conversion_rate;
        tempData[index].adImpressionsSBVCurrent =
          item?.brand_video_campaign_report_impressions;
        tempData[index].adAcosSBVCurrent =
          item?.brand_video_campaign_report_acos;
        tempData[index].adRoasSBVCurrent =
          item?.brand_video_campaign_report_roas;
        tempData[index].adClicksSBVCurrent =
          item?.brand_video_campaign_report_clicks;
        tempData[index].adClickThroughRateSBVCurrent =
          item?.brand_video_campaign_report_ctr;
        tempData[index].adCostPerClickSBVCurrent =
          item?.brand_video_campaign_report_cost_per_click;

        /* ********** Binding the sponsored display current keys ********** */
        tempData[index].adSalesSDCurrent =
          item?.sponsored_display_campaign_report_ad_sales;
        tempData[index].adSpendSDCurrent =
          item?.sponsored_display_campaign_report_ad_spend;
        tempData[index].adConversionRateSDCurrent =
          item?.sponsored_display_campaign_report_ad_conversion_rate;
        tempData[index].adImpressionsSDCurrent =
          item?.sponsored_display_campaign_report_impressions;
        tempData[index].adAcosSDCurrent =
          item?.sponsored_display_campaign_report_acos;
        tempData[index].adRoasSDCurrent =
          item?.sponsored_display_campaign_report_roas;
        tempData[index].adClicksSDCurrent =
          item?.sponsored_display_campaign_report_clicks;
        tempData[index].adClickThroughRateSDCurrent =
          item?.sponsored_display_campaign_report_ctr;
        tempData[index].adCostPerClickSDCurrent =
          item?.sponsored_display_campaign_report_cost_per_click;

        /* ********** Binding the dsp current keys ********** */
        tempData[index].dspImpressionsCurrent = item?.dsp_impressions;
        tempData[index].dspSpendCurrent = item?.dsp_dsp_spend;
        tempData[index].dspTotalProductSalesCurrent = item?.total_product_sales;
        tempData[index].dspTotalRoasCurrent = item?.total_dsp_roas;
        tempData[index].dspTotalDpvrCurrent = item?.total_dpvr;
        tempData[index].dspTotalNewBrandPurchasesCurrent =
          item?.ttl_new_brand_purchases;
        tempData[index].dspProductSalesCurrent = item?.product_sales;
        tempData[index].dspRoasCurrent = item?.dsp_roas;

        /* ********** Binding the All sponsored current label keys ********** */
        tempData[index].adSalesCurrentLabel =
          item?.all_ad_sales !== null ? item.all_ad_sales.toFixed(2) : '0.00';
        tempData[index].adSpendCurrentLabel =
          item?.all_ad_spend !== null ? item.all_ad_spend.toFixed(2) : '0.00';
        tempData[index].adConversionRateCurrentLabel =
          item?.all_ad_conversion_rate !== null
            ? item.all_ad_conversion_rate.toFixed(2)
            : '0.00';
        tempData[index].adImpressionsCurrentLabel =
          item?.all_impressions !== null ? item.all_impressions : '0.00';
        tempData[index].adAcosCurrentLabel =
          item?.all_acos !== null ? item.all_acos.toFixed(2) : '0.00';
        tempData[index].adRoasCurrentLabel =
          item?.all_roas !== null ? item.all_roas.toFixed(2) : '0.00';
        tempData[index].adClicksCurrentLabel =
          item?.all_clicks !== null ? item.all_clicks : '0.00';
        tempData[index].adClickThroughRateCurrentLabel =
          item?.all_ctr !== null ? item.all_ctr.toFixed(2) : '0.00';
        tempData[index].adCostPerClickCurrentLabel =
          item?.all_cost_per_click !== null
            ? item.all_cost_per_click.toFixed(2)
            : '0.00';

        /* ********** Binding the sponsored product current label keys ********** */
        tempData[index].adSalesSPCurrentLabel =
          item?.product_campaign_report_ad_sales !== null
            ? item.product_campaign_report_ad_sales.toFixed(2)
            : '0.00';
        tempData[index].adSpendSPCurrentLabel =
          item?.product_campaign_report_ad_spend !== null
            ? item.product_campaign_report_ad_spend.toFixed(2)
            : '0.00';
        tempData[index].adConversionRateSPCurrentLabel =
          item?.product_campaign_report_ad_conversion_rate !== null
            ? item.product_campaign_report_ad_conversion_rate.toFixed(2)
            : '0.00';
        tempData[index].adImpressionsSPCurrentLabel =
          item?.product_campaign_report_impressions !== null
            ? item.product_campaign_report_impressions
            : '0.00';
        tempData[index].adAcosSPCurrentLabel =
          item?.product_campaign_report_acos !== null
            ? item.product_campaign_report_acos.toFixed(2)
            : '0.00';
        tempData[index].adRoasSPCurrentLabel =
          item?.product_campaign_report_roas !== null
            ? item.product_campaign_report_roas.toFixed(2)
            : '0.00';
        tempData[index].adClicksSPCurrentLabel =
          item?.product_campaign_report_clicks !== null
            ? item.product_campaign_report_clicks
            : '0.00';
        tempData[index].adClickThroughRateSPCurrentLabel =
          item?.product_campaign_report_ctr !== null
            ? item.product_campaign_report_ctr.toFixed(2)
            : '0.00';
        tempData[index].adCostPerClickSPCurrentLabel =
          item?.product_campaign_report_cost_per_click !== null
            ? item.product_campaign_report_cost_per_click.toFixed(2)
            : '0.00';

        /* ********** Binding the sponsored brand current label keys ********** */
        tempData[index].adSalesSBCurrentLabel =
          item?.brand_campaign_report_ad_sales !== null
            ? item.brand_campaign_report_ad_sales.toFixed(2)
            : '0.00';
        tempData[index].adSpendSBCurrentLabel =
          item?.brand_campaign_report_ad_spend !== null
            ? item.brand_campaign_report_ad_spend.toFixed(2)
            : '0.00';
        tempData[index].adConversionRateSBCurrentLabel =
          item?.brand_campaign_report_ad_conversion_rate !== null
            ? item.brand_campaign_report_ad_conversion_rate.toFixed(2)
            : '0.00';
        tempData[index].adImpressionsSBCurrentLabel =
          item?.brand_campaign_report_impressions !== null
            ? item.brand_campaign_report_impressions
            : '0.00';
        tempData[index].adAcosSBCurrentLabel =
          item?.brand_campaign_report_acos !== null
            ? item.brand_campaign_report_acos.toFixed(2)
            : '0.00';
        tempData[index].adRoasSBCurrentLabel =
          item?.brand_campaign_report_roas !== null
            ? item.brand_campaign_report_roas.toFixed(2)
            : '0.00';
        tempData[index].adClicksSBCurrentLabel =
          item?.brand_campaign_report_clicks !== null
            ? item.brand_campaign_report_clicks
            : '0.00';
        tempData[index].adClickThroughRateSBCurrentLabel =
          item?.brand_campaign_report_ctr !== null
            ? item.brand_campaign_report_ctr.toFixed(2)
            : '0.00';
        tempData[index].adCostPerClickSBCurrentLabel =
          item?.brand_campaign_report_cost_per_click !== null
            ? item.brand_campaign_report_cost_per_click.toFixed(2)
            : '0.00';

        /* ********** Binding the sponsored video current label keys ********** */
        tempData[index].adSalesSBVCurrentLabel =
          item?.brand_video_campaign_report_ad_sales !== null
            ? item.brand_video_campaign_report_ad_sales.toFixed(2)
            : '0.00';
        tempData[index].adSpendSBVCurrentLabel =
          item?.brand_video_campaign_report_ad_spend !== null
            ? item.brand_video_campaign_report_ad_spend.toFixed(2)
            : '0.00';
        tempData[index].adConversionRateSBVCurrentLabel =
          item?.brand_video_campaign_report_ad_conversion_rate !== null
            ? item.brand_video_campaign_report_ad_conversion_rate.toFixed(2)
            : '0.00';
        tempData[index].adImpressionsSBVCurrentLabel =
          item?.brand_video_campaign_report_impressions !== null
            ? item.brand_video_campaign_report_impressions
            : '0.00';
        tempData[index].adAcosSBVCurrentLabel =
          item?.brand_video_campaign_report_acos !== null
            ? item.brand_video_campaign_report_acos.toFixed(2)
            : '0.00';
        tempData[index].adRoasSBVCurrentLabel =
          item?.brand_video_campaign_report_roas !== null
            ? item.brand_video_campaign_report_roas.toFixed(2)
            : '0.00';
        tempData[index].adClicksSBVCurrentLabel =
          item?.brand_video_campaign_report_clicks !== null
            ? item.brand_video_campaign_report_clicks
            : '0.00';
        tempData[index].adClickThroughRateSBVCurrentLabel =
          item?.brand_video_campaign_report_ctr !== null
            ? item.brand_video_campaign_report_ctr.toFixed(2)
            : '0.00';
        tempData[index].adCostPerClickSBVCurrentLabel =
          item?.brand_video_campaign_report_cost_per_click !== null
            ? item.brand_video_campaign_report_cost_per_click.toFixed(2)
            : '0.00';

        /* ********** Binding the sponsored display current label keys ********** */
        tempData[index].adSalesSDCurrentLabel =
          item?.sponsored_display_campaign_report_ad_sales !== null
            ? item.sponsored_display_campaign_report_ad_sales.toFixed(2)
            : '0.00';
        tempData[index].adSpendSDCurrentLabel =
          item?.sponsored_display_campaign_report_ad_spend !== null
            ? item.sponsored_display_campaign_report_ad_spend.toFixed(2)
            : '0.00';
        tempData[index].adConversionRateSDCurrentLabel =
          item?.sponsored_display_campaign_report_ad_conversion_rate !== null
            ? item.sponsored_display_campaign_report_ad_conversion_rate.toFixed(
                2,
              )
            : '0.00';
        tempData[index].adImpressionsSDCurrentLabel =
          item?.sponsored_display_campaign_report_impressions !== null
            ? item.sponsored_display_campaign_report_impressions
            : '0.00';
        tempData[index].adAcosSDCurrentLabel =
          item?.sponsored_display_campaign_report_acos !== null
            ? item.sponsored_display_campaign_report_acos.toFixed(2)
            : '0.00';
        tempData[index].adRoasSDCurrentLabel =
          item?.sponsored_display_campaign_report_roas !== null
            ? item.sponsored_display_campaign_report_roas.toFixed(2)
            : '0.00';
        tempData[index].adClicksSDCurrentLabel =
          item?.sponsored_display_campaign_report_clicks !== null
            ? item.sponsored_display_campaign_report_clicks
            : '0.00';
        tempData[index].adClickThroughRateSDCurrentLabel =
          item?.sponsored_display_campaign_report_ctr !== null
            ? item.sponsored_display_campaign_report_ctr.toFixed(2)
            : '0.00';
        tempData[index].adCostPerClickSDCurrentLabel =
          item?.sponsored_display_campaign_report_cost_per_click !== null
            ? item.sponsored_display_campaign_report_cost_per_click.toFixed(2)
            : '0.00';

        /* ********** Binding the dsp current label keys ********** */
        tempData[index].dspImpressionsCurrentLabel =
          item?.dsp_impressions !== null
            ? item.dsp_impressions.toFixed(2)
            : '0.00';
        tempData[index].dspSpendCurrentLabel =
          item?.dsp_dsp_spend !== null ? item.dsp_dsp_spend.toFixed(2) : '0.00';
        tempData[index].dspTotalProductSalesCurrentLabel =
          item?.total_product_sales !== null
            ? item.total_product_sales.toFixed(2)
            : '0.00';
        tempData[index].dspTotalRoasCurrentLabel =
          item?.total_dsp_roas !== null
            ? item.total_dsp_roas.toFixed(2)
            : '0.00';
        tempData[index].dspTotalDpvrCurrentLabel =
          item?.total_dpvr !== null ? item.total_dpvr.toFixed(2) : '0.00';
        tempData[index].dspTotalNewBrandPurchasesCurrentLabel =
          item?.ttl_new_brand_purchases !== null
            ? item.ttl_new_brand_purchases.toFixed(2)
            : '0.00';
        tempData[index].dspProductSalesCurrentLabel =
          item?.product_sales !== null ? item.product_sales.toFixed(2) : '0.00';
        tempData[index].dspRoasCurrentLabel =
          item?.dsp_roas !== null ? item.dsp_roas.toFixed(2) : '0.00';

        // to add the dotted line. we have to check null matrix and add the dummy number like 8
      } else {
        // if current data count is larger than previous count then
        // generate separate key for current data and defien previous value null and previous label 0

        if (acType === 'seller') {
          sellerVendorField = {
            // bind seller metrics current value
            salesRevenueTotalCurrent: item?.revenue,
            salesRevenuePpcCurrent: item?.revenue_ppc,
            salesRevenueDspCurrent: item?.revenue_dsp,
            salesTacosCurrent: item?.tacos,
            salesUnitSoldCurrent: item?.units_sold,
            salesTrafficCurrent: item?.traffic,
            salesConversionCurrent: item?.conversion,
            salesAdSpendTotalCurrent: item?.ad_spend_total,
            salesAdSpendPpcCurrent: item?.sales_ad_spend,
            salesAdSpendDspCurrent: item?.sales_dsp_spends,

            // bind seller metrics previous value
            salesRevenueTotalPrevious: null,
            salesRevenuePpcPrevious: null,
            salesRevenueDspPrevious: null,
            salesTacosPrevious: null,
            salesUnitSoldPrevious: null,
            salesTrafficPrevious: null,
            salesConversionPrevious: null,
            salesAdSpendTotalPrevious: null,
            salesAdSpendPpcPrevious: null,
            salesAdSpendDspPrevious: null,

            // bind seller metrics current value for tooltip
            salesRevenueTotalCurrentLabel:
              item?.revenue !== null ? item.revenue.toFixed(2) : '0.00',
            salesRevenuePpcCurrentLabel:
              item?.revenue_ppc !== null ? item.revenue_ppc.toFixed(2) : '0.00',
            salesRevenueDspCurrentLabel:
              item?.revenue_dsp !== null ? item.revenue_dsp.toFixed(2) : '0.00',
            salesTacosCurrentLabel:
              item?.tacos !== null ? item.tacos.toFixed(2) : '0.00',
            salesUnitSoldCurrentLabel:
              item?.units_sold !== null ? item.units_sold.toFixed(0) : '0.00',
            salesTrafficCurrentLabel:
              item?.traffic !== null ? item.traffic.toFixed(0) : '0.00',
            salesConversionCurrentLabel:
              item?.conversion !== null ? item.conversion.toFixed(2) : '0.00',
            salesAdSpendTotalCurrentLabel:
              item?.ad_spend_total !== null
                ? item.ad_spend_total.toFixed(2)
                : '0.00',
            salesAdSpendPpcCurrentLabel:
              item?.sales_ad_spend !== null
                ? item.sales_ad_spend.toFixed(2)
                : '0.00',
            salesAdSpendDspCurrentLabel:
              item?.sales_dsp_spends !== null
                ? item.sales_dsp_spends.toFixed(2)
                : '0.00',

            // bind seller metrics previous value for tooltip
            salesRevenueTotalPreviousLabel: '0.0',
            salesRevenuePpcPreviousLabel: '0.0',
            salesRevenueDspPreviousLabel: '0.0',
            salesTacosPreviousLabel: '0.0',
            salesUnitSoldPreviousLabel: '0.00',
            salesTrafficPreviousLabel: '0.00',
            salesConversionPreviousLabel: '0.00',
            salesAdSpendTotalPreviousLabel: '0.00',
            salesAdSpendPpcPreviousLabel: '0.00',
            salesAdSpendDspPreviousLabel: '0.00',
          };
        } else {
          sellerVendorField = {
            // bind vendor metrics current value
            salesOrderedRevenueCurrent: item?.ordered_revenue,
            salesOrderedGlanceViewsCurrent: item?.glance_views,
            salesOrderedUnitsCurrent: item?.ordered_units,
            salesOrderedConversionRateCurrent: item?.conversion_rate,
            salesShippedCogsCurrent: item?.shipped_cogs,
            salesShippedGlanceViewsCurrent: item?.glance_views,
            salesShippedConversionRateCurrent: item?.conversion_rate,
            salesShippedUnitsCurrent: item?.shipped_units,

            // bind vendor metrcis previous value
            salesOrderedRevenuePrevious: null,
            salesOrderedGlanceViewsPrevious: null,
            SalesOrderedUnitsPrevious: null,
            salesOrderedConversionRatePrevious: null,
            salesShippedCogsPrevious: null,
            salesShippedGlanceViewsPrevious: null,
            salesShippedUnitsPrevious: null,
            salesShippedConversionRatePrevious: null,

            // bind vendor metrcis current value for tooltip
            salesOrderedRevenueCurrentLabel:
              item?.ordered_revenue !== null
                ? item.ordered_revenue.toFixed(2)
                : '0.00',
            salesOrderedGlanceViewsCurrentLabel:
              item?.glance_views !== null
                ? item.glance_views.toFixed(0)
                : '0.00',
            salesOrderedUnitsCurrentLabel:
              item?.ordered_units !== null
                ? item.ordered_units.toFixed(0)
                : '0.00',
            salesOrderedConversionRateCurrentLabel:
              item?.conversion_rate !== null
                ? item.conversion_rate.toFixed(2)
                : '0.00',
            salesShippedCogsCurrentLabel:
              item?.shipped_cogs !== null
                ? item.shipped_cogs.toFixed(2)
                : '0.00',
            salesShippedGlanceViewsCurrentLabel:
              item?.glance_views !== null
                ? item.glance_views.toFixed(0)
                : '0.00',
            salesShippedUnitsCurrentLabel:
              item?.shipped_units !== null
                ? item.shipped_units.toFixed(0)
                : '0.00',
            salesShippedConversionRateCurrentLabel:
              item?.conversion_rate !== null
                ? item.conversion_rate.toFixed(2)
                : '0.00',

            // bind vendor metrics previous value for tooltip
            salesOrderedRevenuePreviousLabel: '0.00',
            salesOrderedGlanceViewsPreviousLabel: '0.00',
            salesOrderedUnitsPreviousLabel: '0.00',
            salesOrderedConversionRatePreviousLabel: '0.00',
            salesShippedCogsPreviousLabel: '0.00',
            salesShippedGanceViewsPreviousLabel: '0.00',
            salesShippedUnitsPreviousLabel: '0.00',
            salesShippedConversionRatePreviousLabel: '0.00',
          };
        }
        tempData.push({
          ...sellerVendorField,

          /* ********** Binding the All sponsored current keys ********** */
          adSalesCurrent: item?.all_ad_sales,
          adSpendCurrent: item?.all_ad_spend,
          adConversionRateCurrent: item?.all_ad_conversion_rate,
          adImpressionsCurrent: item?.all_impressions,
          adAcosCurrent: item?.all_acos,
          adRoasCurrent: item?.all_roas,
          adClicksCurrent: item?.all_clicks,
          adClickThroughRateCurrent: item?.all_ctr,
          adCostPerClickCurrent: item?.all_cost_per_click,

          /* ********** Binding the sponsored product current keys ********** */
          adSalesSPCurrent: item?.product_campaign_report_ad_sales,
          adSpendSPCurrent: item?.product_campaign_report_ad_spend,
          adConversionRateSPCurrent:
            item?.product_campaign_report_ad_conversion_rate,
          adImpressionsSPCurrent: item?.product_campaign_report_impressions,
          adAcosSPCurrent: item?.product_campaign_report_acos,
          adRoasSPCurrent: item?.product_campaign_report_roas,
          adClicksSPCurrent: item?.product_campaign_report_clicks,
          adClickThroughRateSPCurrent: item?.product_campaign_report_ctr,
          adCostPerClickSPCurrent: item?.product_campaign_report_cost_per_click,

          /* ********** Binding the sponsored brand current keys ********** */
          adSalesSBCurrent: item?.brand_campaign_report_ad_sales,
          adSpendSBCurrent: item?.brand_campaign_report_ad_spend,
          adConversionRateSBCurrent:
            item?.brand_campaign_report_ad_conversion_rate,
          adImpressionsSBCurrent: item?.brand_campaign_report_impressions,
          adAcosSBCurrent: item?.brand_campaign_report_acos,
          adRoasSBCurrent: item?.brand_campaign_report_roas,
          adClicksSBCurrent: item?.brand_campaign_report_clicks,
          adClickThroughRateSBCurrent: item?.brand_campaign_report_ctr,
          adCostPerClickSBCurrent: item?.brand_campaign_report_cost_per_click,

          /* ********** Binding the sponsored video current keys ********** */
          adSalesSBVCurrent: item?.brand_video_campaign_report_ad_sales,
          adSpendSBVCurrent: item?.brand_video_campaign_report_ad_spend,
          adConversionRateSBVCurrent:
            item?.brand_video_campaign_report_ad_conversion_rate,
          adImpressionsSBVCurrent:
            item?.brand_video_campaign_report_impressions,
          adAcosSBVCurrent: item?.brand_video_campaign_report_acos,
          adRoasSBVCurrent: item?.brand_video_campaign_report_roas,
          adClicksSBVCurrent: item?.brand_video_campaign_report_clicks,
          adClickThroughRateSBVCurrent: item?.brand_video_campaign_report_ctr,
          adCostPerClickSBVCurrent:
            item?.brand_video_campaign_report_cost_per_click,

          /* ********** Binding the sponsored display current keys ********** */
          adSalesSDCurrent: item?.sponsored_display_campaign_report_ad_sales,
          adSpendSDCurrent: item?.sponsored_display_campaign_report_ad_spend,
          adConversionRateSDCurrent:
            item?.sponsored_display_campaign_report_ad_conversion_rate,
          adImpressionsSDCurrent:
            item?.sponsored_display_campaign_report_impressions,
          adAcosSDCurrent: item?.sponsored_display_campaign_report_acos,
          adRoasSDCurrent: item?.sponsored_display_campaign_report_roas,
          adClicksSDCurrent: item?.sponsored_display_campaign_report_clicks,
          adClickThroughRateSDCurrent:
            item?.sponsored_display_campaign_report_ctr,
          adCostPerClickSDCurrent:
            item?.sponsored_display_campaign_report_cost_per_click,

          /* ********** Binding the dsp current keys ********** */
          dspImpressionsCurrent: item?.dsp_impressions,
          dspSpendCurrent: item?.dsp_dsp_spend,
          dspTotalProductSalesCurrent: item?.total_product_sales,
          dspTotalRoasCurrent: item?.total_dsp_roas,
          dspTotalDpvrCurrent: item?.total_dpvr,
          dspTotalNewBrandPurchasesCurrent: item?.ttl_new_brand_purchases,
          dspProductSalesCurrent: item?.product_sales,
          dspRoasCurrent: item?.dsp_roas,

          date: currentReportDate,

          adSalesPrevious: null,
          adSpendPrevious: null,
          adConversionPrevious: null,
          adImpressionsPrevious: null,
          adAcosPrevious: null,
          adRoasPrevious: null,
          adClicksPrevious: null,
          adClickThroughRatePrevious: null,
          adCostPerClickPrevious: null,

          adSalesSPPrevious: null,
          adSpendSPPrevious: null,
          adConversionSPPrevious: null,
          adImpressionsSPPrevious: null,
          adAcosSPPrevious: null,
          adRoasSPPrevious: null,
          adClicksSPPrevious: null,
          adClickThroughRateSPPrevious: null,
          adCostPerClickSPPrevious: null,

          adSalesSBPrevious: null,
          adSpendSBPrevious: null,
          adConversionSBPrevious: null,
          adImpressionsSBPrevious: null,
          adAcosSBPrevious: null,
          adRoasSBPrevious: null,
          adClicksSBPrevious: null,
          adClickThroughRateSBPrevious: null,
          adCostPerClickSBPrevious: null,

          adSalesSBVPrevious: null,
          adSpendSBVPrevious: null,
          adConversionSBVPrevious: null,
          adImpressionsSBVPrevious: null,
          adAcosSBVPrevious: null,
          adRoasSBVPrevious: null,
          adClicksSBVPrevious: null,
          adClickThroughRateSBVPrevious: null,
          adCostPerClickSBVPrevious: null,

          adSalesSDPrevious: null,
          adSpendSDPrevious: null,
          adConversionSDPrevious: null,
          adImpressionsSDPrevious: null,
          adAcosSDPrevious: null,
          adRoasSDPrevious: null,
          adClicksSDPrevious: null,
          adClickThroughRateSDPrevious: null,
          adCostPerClickSDPrevious: null,

          dspImpressionsPrevious: null,
          dspSpendPrevious: null,
          dspTotalProductSalesPrevious: null,
          dspTotalRoasPrevious: null,
          dspTotalDpvrPrevious: null,
          dspTotalNewBrandPurchasesPrevious: null,
          dspProductSalesPrevious: null,
          dspRoasPrevious: null,

          /* ********** Binding the All sponsored current label keys ********** */
          adSalesCurrentLabel:
            item?.all_ad_sales !== null ? item.all_ad_sales.toFixed(2) : '0.00',
          adSpendCurrentLabel:
            item?.all_ad_spend !== null ? item.all_ad_spend.toFixed(2) : '0.00',
          adConversionRateCurrentLabel:
            item?.all_ad_conversion_rate !== null
              ? item.all_ad_conversion_rate.toFixed(2)
              : '0.00',
          adImpressionsCurrentLabel:
            item?.all_impressions !== null ? item.all_impressions : '0.00',
          adAcosCurrentLabel:
            item?.all_acos !== null ? item.all_acos.toFixed(2) : '0.00',
          adRoasCurrentLabel:
            item?.all_roas !== null ? item.all_roas.toFixed(2) : '0.00',
          adClicksCurrentLabel:
            item?.all_clicks !== null ? item.all_clicks : '0.00',
          adClickThroughRateCurrentLabel:
            item?.all_ctr !== null ? item.all_ctr.toFixed(2) : '0.00',
          adCostPerClickCurrentLabel:
            item?.all_cost_per_click !== null
              ? item.all_cost_per_click.toFixed(2)
              : '0.00',

          /* ********** Binding the sponsored product current label keys ********** */
          adSalesSPCurrentLabel:
            item?.product_campaign_report_ad_sales !== null
              ? item.product_campaign_report_ad_sales.toFixed(2)
              : '0.00',
          adSpendSPCurrentLabel:
            item?.product_campaign_report_ad_spend !== null
              ? item.product_campaign_report_ad_spend.toFixed(2)
              : '0.00',
          adConversionRateSPCurrentLabel:
            item?.product_campaign_report_ad_conversion_rate !== null
              ? item.product_campaign_report_ad_conversion_rate.toFixed(2)
              : '0.00',
          adImpressionsSPCurrentLabel:
            item?.product_campaign_report_impressions !== null
              ? item.product_campaign_report_impressions
              : '0.00',
          adAcosSPCurrentLabel:
            item?.product_campaign_report_acos !== null
              ? item.product_campaign_report_acos.toFixed(2)
              : '0.00',
          adRoasSPCurrentLabel:
            item?.product_campaign_report_roas !== null
              ? item.product_campaign_report_roas.toFixed(2)
              : '0.00',
          adClicksSPCurrentLabel:
            item?.product_campaign_report_clicks !== null
              ? item.product_campaign_report_clicks
              : '0.00',
          adClickThroughRateSPCurrentLabel:
            item?.product_campaign_report_ctr !== null
              ? item.product_campaign_report_ctr.toFixed(2)
              : '0.00',
          adCostPerClickSPCurrentLabel:
            item?.product_campaign_report_cost_per_click !== null
              ? item.product_campaign_report_cost_per_click.toFixed(2)
              : '0.00',

          /* ********** Binding the sponsored brand current label keys ********** */
          adSalesSBCurrentLabel:
            item?.brand_campaign_report_ad_sales !== null
              ? item.brand_campaign_report_ad_sales.toFixed(2)
              : '0.00',
          adSpendSBCurrentLabel:
            item?.brand_campaign_report_ad_spend !== null
              ? item.brand_campaign_report_ad_spend.toFixed(2)
              : '0.00',
          adConversionRateSBCurrentLabel:
            item?.brand_campaign_report_ad_conversion_rate !== null
              ? item.brand_campaign_report_ad_conversion_rate.toFixed(2)
              : '0.00',
          adImpressionsSBCurrentLabel:
            item?.brand_campaign_report_impressions !== null
              ? item.brand_campaign_report_impressions
              : '0.00',
          adAcosSBCurrentLabel:
            item?.brand_campaign_report_acos !== null
              ? item.brand_campaign_report_acos.toFixed(2)
              : '0.00',
          adRoasSBCurrentLabel:
            item?.brand_campaign_report_roas !== null
              ? item.brand_campaign_report_roas.toFixed(2)
              : '0.00',
          adClicksSBCurrentLabel:
            item?.brand_campaign_report_clicks !== null
              ? item.brand_campaign_report_clicks
              : '0.00',
          adClickThroughRateSBCurrentLabel:
            item?.brand_campaign_report_ctr !== null
              ? item.brand_campaign_report_ctr.toFixed(2)
              : '0.00',
          adCostPerClickSBCurrentLabel:
            item?.brand_campaign_report_cost_per_click !== null
              ? item.brand_campaign_report_cost_per_click.toFixed(2)
              : '0.00',

          /* ********** Binding the sponsored video current label keys ********** */
          adSalesSBVCurrentLabel:
            item?.brand_video_campaign_report_ad_sales !== null
              ? item.brand_video_campaign_report_ad_sales.toFixed(2)
              : '0.00',
          adSpendSBVCurrentLabel:
            item?.brand_video_campaign_report_ad_spend !== null
              ? item.brand_video_campaign_report_ad_spend.toFixed(2)
              : '0.00',
          adConversionRateSBVCurrentLabel:
            item?.brand_video_campaign_report_ad_conversion_rate !== null
              ? item.brand_video_campaign_report_ad_conversion_rate.toFixed(2)
              : '0.00',
          adImpressionsSBVCurrentLabel:
            item?.brand_video_campaign_report_impressions !== null
              ? item.brand_video_campaign_report_impressions
              : '0.00',
          adAcosSBVCurrentLabel:
            item?.brand_video_campaign_report_acos !== null
              ? item.brand_video_campaign_report_acos.toFixed(2)
              : '0.00',
          adRoasSBVCurrentLabel:
            item?.brand_video_campaign_report_roas !== null
              ? item.brand_video_campaign_report_roas.toFixed(2)
              : '0.00',
          adClicksSBVCurrentLabel:
            item?.brand_video_campaign_report_clicks !== null
              ? item.brand_video_campaign_report_clicks
              : '0.00',
          adClickThroughRateSBVCurrentLabel:
            item?.brand_video_campaign_report_ctr !== null
              ? item.brand_video_campaign_report_ctr.toFixed(2)
              : '0.00',
          adCostPerClickSBVCurrentLabel:
            item?.brand_video_campaign_report_cost_per_click !== null
              ? item.brand_video_campaign_report_cost_per_click.toFixed(2)
              : '0.00',

          /* ********** Binding the sponsored display current label keys ********** */
          adSalesSDCurrentLabel:
            item?.sponsored_display_campaign_report_ad_sales !== null
              ? item.sponsored_display_campaign_report_ad_sales.toFixed(2)
              : '0.00',
          adSpendSDCurrentLabel:
            item?.sponsored_display_campaign_report_ad_spend !== null
              ? item.sponsored_display_campaign_report_ad_spend.toFixed(2)
              : '0.00',
          adConversionRateSDCurrentLabel:
            item?.sponsored_display_campaign_report_ad_conversion_rate !== null
              ? item.sponsored_display_campaign_report_ad_conversion_rate.toFixed(
                  2,
                )
              : '0.00',
          adImpressionsSDCurrentLabel:
            item?.sponsored_display_campaign_report_impressions !== null
              ? item.sponsored_display_campaign_report_impressions
              : '0.00',
          adAcosSDCurrentLabel:
            item?.sponsored_display_campaign_report_acos !== null
              ? item.sponsored_display_campaign_report_acos.toFixed(2)
              : '0.00',
          adRoasSDCurrentLabel:
            item?.sponsored_display_campaign_report_roas !== null
              ? item.sponsored_display_campaign_report_roas.toFixed(2)
              : '0.00',
          adClicksSDCurrentLabel:
            item?.sponsored_display_campaign_report_clicks !== null
              ? item.sponsored_display_campaign_report_clicks
              : '0.00',
          adClickThroughRateSDCurrentLabel:
            item?.sponsored_display_campaign_report_ctr !== null
              ? item.sponsored_display_campaign_report_ctr.toFixed(2)
              : '0.00',
          adCostPerClickSDCurrentLabel:
            item?.sponsored_display_campaign_report_cost_per_click !== null
              ? item.sponsored_display_campaign_report_cost_per_click.toFixed(2)
              : '0.00',

          /* ********** Binding the dsp current label keys ********** */
          dspImpressionsCurrentLabel:
            item?.dsp_impressions !== null
              ? item.dsp_impressions.toFixed(2)
              : '0.00',
          dspSpendCurrentLabel:
            item?.dsp_dsp_spend !== null
              ? item.dsp_dsp_spend.toFixed(2)
              : '0.00',
          dspTotalProductSalesCurrentLabel:
            item?.total_product_sales !== null
              ? item.total_product_sales.toFixed(2)
              : '0.00',
          dspTotalRoasCurrentLabel:
            item?.total_dsp_roas !== null
              ? item.total_dsp_roas.toFixed(2)
              : '0.00',
          dspTotalDpvrCurrentLabel:
            item?.total_dpvr !== null ? item.total_dpvr.toFixed(2) : '0.00',
          dspTotalNewBrandPurchasesCurrentLabel:
            item?.ttl_new_brand_purchases !== null
              ? item.ttl_new_brand_purchases.toFixed(2)
              : '0.00',
          dspProductSalesCurrentLabel:
            item?.product_sales !== null
              ? item.product_sales.toFixed(2)
              : '0.00',
          dspRoasCurrentLabel:
            item?.dsp_roas !== null ? item.dsp_roas.toFixed(2) : '0.00',

          adSalesPreviousLabel: '0.00',
          adSpendPreviousLabel: '0.00',
          adConversionPreviousLabel: '0.00',
          adImpressionsPreviousLabel: '0.00',
          adAcosPreviousLabel: '0.00',
          adRoasPreviousLabel: '0.00',
          adClicksPreviousLabel: '0.00',
          adClickThroughRatePreviousLabel: '0.00',
          adCostPerClickPreviousLabel: '0.00',

          adSalesSPPreviousLabel: '0.00',
          adSpendSPPreviousLabel: '0.00',
          adConversionSPPreviousLabel: '0.00',
          adImpressionsSPPreviousLabel: '0.00',
          adAcosSPPreviousLabel: '0.00',
          adRoasSPPreviousLabel: '0.00',
          adClicksSPPreviousLabel: '0.00',
          adClickThroughRateSPPreviousLabel: '0.00',
          adCostPerClickSPPreviousLabel: '0.00',

          adSalesSBPreviousLabel: '0.00',
          adSpendSBPreviousLabel: '0.00',
          adConversionSBPreviousLabel: '0.00',
          adImpressionsSBPreviousLabel: '0.00',
          adAcosSBPreviousLabel: '0.00',
          adRoasSBPreviousLabel: '0.00',
          adClicksSBPreviousLabel: '0.00',
          adClickThroughRateSBPreviousLabel: '0.00',
          adCostPerClickSBPreviousLabel: '0.00',

          adSalesSBVPreviousLabel: '0.00',
          adSpendSBVPreviousLabel: '0.00',
          adConversionSBVPreviousLabel: '0.00',
          adImpressionsSBVPreviousLabel: '0.00',
          adAcosSBVPreviousLabel: '0.00',
          adRoasSBVPreviousLabel: '0.00',
          adClicksSBVPreviousLabel: '0.00',
          adClickThroughRateSBVPreviousLabel: '0.00',
          adCostPerClickSBVPreviousLabel: '0.00',

          adSalesSDPreviousLabel: '0.00',
          adSpendSDPreviousLabel: '0.00',
          adConversionSDPreviousLabel: '0.00',
          adImpressionsSDPreviousLabel: '0.00',
          adAcosSDPreviousLabel: '0.00',
          adRoasSDPreviousLabel: '0.00',
          adClicksSDPreviousLabel: '0.00',
          adClickThroughRateSDPreviousLabel: '0.00',
          adCostPerClickSDPreviousLabel: '0.00',

          dspImpressionsPreviousLabel: '0.00',
          dspSpendPreviousLabel: '0.00',
          dspTotalProductSalesPreviousLabel: '0.00',
          dspTotalRoasPreviousLabel: '0.00',
          dspTotalDpvrPreviousLabel: '0.00',
          dspTotalNewBrandPurchasesPreviousLabel: '0.00',
          dspProductSalesPreviousLabel: '0.00',
          dspRoasPreviousLabel: '0.00',
        });
      }
    });
  }

  // filterout change in value data in one temporary object.
  if (currentChangeInValueKey?.length) {
    currentChangeInValueKey.forEach((item, index) => {
      // add the current change in value data at same index of current data in temporary object
      if (currentKey && index < currentKey?.length) {
        if (acType === 'seller') {
          // bind seller metrics comparison change in value
          tempData[index].salesRevenueTotalChangeInValue =
            item?.revenue !== null ? item.revenue : null;
          tempData[index].salesRevenuePpcChangeInValue =
            item?.revenue_ppc !== null ? item.revenue_ppc : null;
          tempData[index].salesRevenueDspChangeInValue =
            item?.revenue_dsp !== null ? item.revenue_dsp : null;
          tempData[index].salesTacosChangeInValue =
            item?.tacos !== null ? item.tacos : null;
          tempData[index].salesUnitSoldChangeInValue =
            item?.units_sold !== null ? item.units_sold : null;
          tempData[index].salesTrafficChangeInValue =
            item?.traffic !== null ? item.traffic : null;
          tempData[index].salesConversionChangeInValue =
            item?.conversion !== null ? item.conversion : null;
          tempData[index].salesAdSpendTotalChangeInValue =
            item?.ad_spend_total !== null ? item.ad_spend_total : null;
          tempData[index].salesAdSpendPpcChangeInValue =
            item?.sales_ad_spend !== null ? item.sales_ad_spend : null;
          tempData[index].salesAdSpendDspChangeInValue =
            item?.sales_dsp_spends !== null ? item.sales_dsp_spends : null;
        } else {
          // bind vendor metrics comparison change in value
          tempData[index].salesOrderedRevenueChangeInValue =
            item?.ordered_revenue !== null ? item.ordered_revenue : null;
          tempData[index].salesOrderedGlanceViewsChangeInValue =
            item?.glance_views !== null ? item.glance_views : null;
          tempData[index].salesOrderedUnitsChangeInValue =
            item?.ordered_units !== null ? item.ordered_units : null;
          tempData[index].salesOrderedConversionRateChangeInValue =
            item?.conversion_rate !== null ? item.conversion_rate : null;
          tempData[index].salesShippedCogsChangeInValue =
            item?.shipped_cogs !== null ? item.shipped_cogs : null;
          tempData[index].salesShippedGlanceViewsChangeInValue =
            item?.glance_views !== null ? item.glance_views : null;
          tempData[index].salesShippedConversionRateChangeInValue =
            item?.conversion_rate !== null ? item.conversion_rate : null;
          tempData[index].salesShippedUnitsChangeInValue =
            item?.shipped_units !== null ? item.shipped_units : null;
        }

        // bind sponsored all metrics comparison change in value
        tempData[index].adSalesChangeInValue =
          item?.all_ad_sales !== null ? item.all_ad_sales : null;
        tempData[index].adSpendChangeInValue =
          item?.all_ad_spend !== null ? item.all_ad_spend : null;
        tempData[index].adConversionRateChangeInValue =
          item?.all_ad_conversion_rate !== null
            ? item.all_ad_conversion_rate
            : null;
        tempData[index].adImpressionsChangeInValue =
          item?.all_impressions !== null ? item.all_impressions : null;
        tempData[index].adAcosChangeInValue =
          item?.all_acos !== null ? item.all_acos : null;
        tempData[index].adRoasChangeInValue =
          item?.all_roas !== null ? item.all_roas : null;
        tempData[index].adClicksChangeInValue =
          item?.all_clicks !== null ? item.all_clicks : null;
        tempData[index].adClickThroughRateChangeInValue =
          item?.all_ctr !== null ? item.all_ctr : null;
        tempData[index].adCostPerClickChangeInValue =
          item?.all_cost_per_click !== null ? item.all_cost_per_click : null;

        // bind sponsored product metrics comparison change in value
        tempData[index].adSalesSPChangeInValue =
          item?.product_campaign_report_ad_sales !== null
            ? item.product_campaign_report_ad_sales
            : null;
        tempData[index].adSpendSPChangeInValue =
          item?.product_campaign_report_ad_spend !== null
            ? item.product_campaign_report_ad_spend
            : null;

        tempData[index].adConversionRateSPChangeInValue =
          item?.product_campaign_report_ad_conversion_rate !== null
            ? item.product_campaign_report_ad_conversion_rate
            : null;
        tempData[index].adImpressionsSPChangeInValue =
          item?.product_campaign_report_impressions !== null
            ? item.product_campaign_report_impressions
            : null;
        tempData[index].adAcosSPChangeInValue =
          item?.product_campaign_report_acos !== null
            ? item.product_campaign_report_acos
            : null;
        tempData[index].adRoasSPChangeInValue =
          item?.product_campaign_report_roas !== null
            ? item.product_campaign_report_roas
            : null;
        tempData[index].adClicksSPChangeInValue =
          item?.product_campaign_report_clicks !== null
            ? item.product_campaign_report_clicks
            : null;
        tempData[index].adClickThroughRateSPChangeInValue =
          item?.product_campaign_report_ctr !== null
            ? item.product_campaign_report_ctr
            : null;
        tempData[index].adCostPerClickSPChangeInValue =
          item?.product_campaign_report_cost_per_click !== null
            ? item.product_campaign_report_cost_per_click
            : null;

        // bind sponsored brand metrics comparison change in value
        tempData[index].adSalesSBChangeInValue =
          item?.brand_campaign_report_ad_sales !== null
            ? item.brand_campaign_report_ad_sales
            : null;
        tempData[index].adSpendSBChangeInValue =
          item?.brand_campaign_report_ad_spend !== null
            ? item.brand_campaign_report_ad_spend
            : null;
        tempData[index].adConversionRateSBChangeInValue =
          item?.brand_campaign_report_ad_conversion_rate !== null
            ? item.brand_campaign_report_ad_conversion_rate
            : null;
        tempData[index].adImpressionsSBChangeInValue =
          item?.brand_campaign_report_impressions !== null
            ? item.brand_campaign_report_impressions
            : null;
        tempData[index].adAcosSBChangeInValue =
          item?.brand_campaign_report_acos !== null
            ? item.brand_campaign_report_acos
            : null;
        tempData[index].adRoasSBChangeInValue =
          item?.brand_campaign_report_roas !== null
            ? item.brand_campaign_report_roas
            : null;
        tempData[index].adClicksSBChangeInValue =
          item?.brand_campaign_report_clicks !== null
            ? item.brand_campaign_report_clicks
            : null;
        tempData[index].adClickThroughRateSBChangeInValue =
          item?.brand_campaign_report_ctr !== null
            ? item.brand_campaign_report_ctr
            : null;
        tempData[index].adCostPerClickSBChangeInValue =
          item?.brand_campaign_report_cost_per_click !== null
            ? item.brand_campaign_report_cost_per_click
            : null;

        // bind sponsored video metrics comparison change in value
        tempData[index].adSalesSBVChangeInValue =
          item?.brand_video_campaign_report_ad_sales !== null
            ? item.brand_video_campaign_report_ad_sales
            : null;
        tempData[index].adSpendSBVChangeInValue =
          item?.brand_video_campaign_report_ad_spend !== null
            ? item.brand_video_campaign_report_ad_spend
            : null;
        tempData[index].adConversionRateSBVChangeInValue =
          item?.brand_video_campaign_report_ad_conversion_rate !== null
            ? item.brand_video_campaign_report_ad_conversion_rate
            : null;
        tempData[index].adImpressionsSBVChangeInValue =
          item?.brand_video_campaign_report_impressions !== null
            ? item.brand_video_campaign_report_impressions
            : null;
        tempData[index].adAcosSBVChangeInValue =
          item?.brand_video_campaign_report_acos !== null
            ? item.brand_video_campaign_report_acos
            : null;
        tempData[index].adRoasSBVChangeInValue =
          item?.brand_video_campaign_report_roas !== null
            ? item.brand_video_campaign_report_roas
            : null;
        tempData[index].adClicksSBVChangeInValue =
          item?.brand_video_campaign_report_clicks !== null
            ? item.brand_video_campaign_report_clicks
            : null;
        tempData[index].adClickThroughRateSBVChangeInValue =
          item?.brand_video_campaign_report_ctr !== null
            ? item.brand_video_campaign_report_ctr
            : null;
        tempData[index].adCostPerClickSBVChangeInValue =
          item?.brand_video_campaign_report_cost_per_click !== null
            ? item.brand_video_campaign_report_cost_per_click
            : null;

        // bind sponsored display metrics comparison change in value
        tempData[index].adSalesSDChangeInValue =
          item?.sponsored_display_campaign_report_ad_sales !== null
            ? item.sponsored_display_campaign_report_ad_sales
            : null;
        tempData[index].adSpendSDChangeInValue =
          item?.sponsored_display_campaign_report_ad_spend !== null
            ? item.sponsored_display_campaign_report_ad_spend
            : null;
        tempData[index].adConversionRateSDChangeInValue =
          item?.sponsored_display_campaign_report_ad_conversion_rate !== null
            ? item.sponsored_display_campaign_report_ad_conversion_rate
            : null;

        tempData[index].adImpressionsSDChangeInValue =
          item?.sponsored_display_campaign_report_impressions !== null
            ? item.sponsored_display_campaign_report_impressions
            : null;
        tempData[index].adAcosSDChangeInValue =
          item?.sponsored_display_campaign_report_acos !== null
            ? item.sponsored_display_campaign_report_acos
            : 0;
        tempData[index].adRoasSDChangeInValue =
          item?.sponsored_display_campaign_report_roas !== null
            ? item.sponsored_display_campaign_report_roas
            : null;
        tempData[index].adClicksSDChangeInValue =
          item?.sponsored_display_campaign_report_clicks !== null
            ? item.sponsored_display_campaign_report_clicks
            : null;
        tempData[index].adClickThroughRateSDChangeInValue =
          item?.sponsored_display_campaign_report_ctr !== null
            ? item.sponsored_display_campaign_report_ctr
            : null;
        tempData[index].adCostPerClickSDChangeInValue =
          item?.sponsored_display_campaign_report_cost_per_click !== null
            ? item.sponsored_display_campaign_report_cost_per_click
            : null;

        // bind dsp metrics comparison change in value
        tempData[index].dspImpressionsChangeInValue =
          item?.dsp_impressions !== null ? item.dsp_impressions : null;
        tempData[index].dspSpendChangeInValue =
          item?.dsp_dsp_spend !== null ? item.dsp_dsp_spend : null;
        tempData[index].dspTotalProductSalesChangeInValue =
          item?.total_product_sales !== null ? item.total_product_sales : null;
        tempData[index].dspTotalRoasChangeInValue =
          item?.total_dsp_roas !== null ? item.total_dsp_roas : null;
        tempData[index].dspTotalDpvrChangeInValue =
          item?.total_dpvr !== null ? item.total_dpvr : null;
        tempData[index].dspTotalNewBrandPurchasesChangeInValue =
          item?.ttl_new_brand_purchases !== null
            ? item.ttl_new_brand_purchases
            : null;
        tempData[index].dspProductSalesChangeInValue =
          item?.product_sales !== null ? item.product_sales : null;
        tempData[index].dspRoasChangeInValue =
          item?.dsp_roas !== null ? item.dsp_roas : null;
      }
    });
  }

  // filterout current data in one temporary object.
  if (currentChangeInPercentKey?.length) {
    currentChangeInPercentKey.forEach((item, index) => {
      // add the current change in percentage data at same index of current in temporary object
      if (currentKey && index < currentKey?.length) {
        if (acType === 'seller') {
          // bind seller metrics comparison change in percent
          tempData[index].salesRevenueTotalChangeInPercent =
            item?.revenue !== null ? item.revenue : null;
          tempData[index].salesRevenuePpcChangeInPercent =
            item?.revenue_ppc !== null ? item.revenue_ppc : null;
          tempData[index].salesRevenueDspChangeInPercent =
            item?.revenue_dsp !== null ? item.revenue_dsp : null;
          tempData[index].salesTacosChangeInPercent =
            item?.tacos !== null ? item.tacos : null;
          tempData[index].salesUnitSoldChangeInPercent =
            item?.units_sold !== null ? item.units_sold : null;
          tempData[index].salesTrafficChangeInPercent =
            item?.traffic !== null ? item.traffic : null;
          tempData[index].salesConversionChangeInPercent =
            item?.conversion !== null ? item.conversion : null;
          tempData[index].salesAdSpendTotalChangeInPercent =
            item?.ad_spend_total !== null ? item.ad_spend_total : null;
          tempData[index].salesAdSpendPpcChangeInPercent =
            item?.sales_ad_spend !== null ? item.sales_ad_spend : null;
          tempData[index].salesAdSpendDspChangeInPercent =
            item?.sales_dsp_spends !== null ? item.sales_dsp_spends : null;
        } else {
          // bind vendor metrics comparison change in percent
          tempData[index].salesOrderedRevenueChangeInPercent =
            item?.ordered_revenue !== null ? item.ordered_revenue : null;
          tempData[index].salesOrderedGlanceViewsChangeInPercent =
            item?.glance_views !== null ? item.glance_views : null;
          tempData[index].salesOrderedUnitsChangeInPercent =
            item?.ordered_units !== null ? item.ordered_units : null;
          tempData[index].salesOrderedConversionRateChangeInPercent =
            item?.conversion_rate !== null ? item.conversion_rate : null;
          tempData[index].salesShippedCogsChangeInPercent =
            item?.shipped_cogs !== null ? item.shipped_cogs : null;
          tempData[index].salesShippedGlanceViewsChangeInPercent =
            item?.glance_views !== null ? item.glance_views : null;
          tempData[index].salesShippedConversionRateChangeInPercent =
            item?.conversion_rate !== null ? item.conversion_rate : null;
          tempData[index].salesShippedUnitsChangeInPercent =
            item?.shipped_units !== null ? item.shipped_units : null;
        }

        // bind sponsored all metrics comparison change in percent
        tempData[index].adSalesChangeInPercent =
          item?.all_ad_sales !== null ? item.all_ad_sales : null;
        tempData[index].adSpendChangeInPercent =
          item?.all_ad_spend !== null ? item.all_ad_spend : null;
        tempData[index].adConversionRateChangeInPercent =
          item?.all_ad_conversion_rate !== null
            ? item.all_ad_conversion_rate
            : null;
        tempData[index].adImpressionsChangeInPercent =
          item?.all_impressions !== null ? item.all_impressions : null;
        tempData[index].adAcosChangeInPercent =
          item?.all_acos !== null ? item.all_acos : null;
        tempData[index].adRoasChangeInPercent =
          item?.all_roas !== null ? item.all_roas : null;
        tempData[index].adClicksChangeInPercent =
          item?.all_clicks !== null ? item.all_clicks : null;
        tempData[index].adClickThroughRateChangeInPercent =
          item?.all_ctr !== null ? item.all_ctr : null;
        tempData[index].adCostPerClickChangeInPercent =
          item?.all_cost_per_click !== null ? item.all_cost_per_click : null;

        // bind sponsored product metrics comparison change in percent
        tempData[index].adSalesSPChangeInPercent =
          item?.product_campaign_report_ad_sales !== null
            ? item.product_campaign_report_ad_sales
            : null;
        tempData[index].adSpendSPChangeInPercent =
          item?.product_campaign_report_ad_spend !== null
            ? item.product_campaign_report_ad_spend
            : null;
        tempData[index].adConversionRateSPChangeInPercent =
          item?.product_campaign_report_ad_conversion_rate !== null
            ? item.product_campaign_report_ad_conversion_rate
            : null;
        tempData[index].adImpressionsSPChangeInPercent =
          item?.product_campaign_report_impressions !== null
            ? item.product_campaign_report_impressions
            : null;
        tempData[index].adAcosSPChangeInPercent =
          item?.product_campaign_report_acos !== null
            ? item.product_campaign_report_acos
            : null;
        tempData[index].adRoasSPChangeInPercent =
          item?.product_campaign_report_roas !== null
            ? item.product_campaign_report_roas
            : null;
        tempData[index].adClicksSPChangeInPercent =
          item?.product_campaign_report_clicks !== null
            ? item.product_campaign_report_clicks
            : null;
        tempData[index].adClickThroughRateSPChangeInPercent =
          item?.product_campaign_report_ctr !== null
            ? item.product_campaign_report_ctr
            : null;
        tempData[index].adCostPerClickSPChangeInPercent =
          item?.product_campaign_report_cost_per_click !== null
            ? item.product_campaign_report_cost_per_click
            : null;

        // bind sponsored brand metrics comparison change in percent
        tempData[index].adSalesSBChangeInPercent =
          item?.brand_campaign_report_ad_sales !== null
            ? item.brand_campaign_report_ad_sales
            : null;
        tempData[index].adSpendSBChangeInPercent =
          item?.brand_campaign_report_ad_spend !== null
            ? item.brand_campaign_report_ad_spend
            : null;
        tempData[index].adConversionRateSBChangeInPercent =
          item?.brand_campaign_report_ad_conversion_rate !== null
            ? item.brand_campaign_report_ad_conversion_rate
            : null;
        tempData[index].adImpressionsSBChangeInPercent =
          item?.brand_campaign_report_impressions !== null
            ? item.brand_campaign_report_impressions
            : null;
        tempData[index].adAcosSBChangeInPercent =
          item?.brand_campaign_report_acos !== null
            ? item.brand_campaign_report_acos
            : null;
        tempData[index].adRoasSBChangeInPercent =
          item?.brand_campaign_report_roas !== null
            ? item.brand_campaign_report_roas
            : null;
        tempData[index].adClicksSBChangeInPercent =
          item?.brand_campaign_report_clicks !== null
            ? item.brand_campaign_report_clicks
            : null;
        tempData[index].adClickThroughRateSBChangeInPercent =
          item?.brand_campaign_report_ctr !== null
            ? item.brand_campaign_report_ctr
            : null;
        tempData[index].adCostPerClickSBChangeInPercent =
          item?.brand_campaign_report_cost_per_click !== null
            ? item.brand_campaign_report_cost_per_click
            : null;

        // bind sponsored video metrics comparison change in percent
        tempData[index].adSalesSBVChangeInPercent =
          item?.brand_video_campaign_report_ad_sales !== null
            ? item.brand_video_campaign_report_ad_sales
            : null;
        tempData[index].adSpendSBVChangeInPercent =
          item?.brand_video_campaign_report_ad_spend !== null
            ? item.brand_video_campaign_report_ad_spend
            : null;
        tempData[index].adConversionRateSBVChangeInPercent =
          item?.brand_video_campaign_report_ad_conversion_rate !== null
            ? item.brand_video_campaign_report_ad_conversion_rate
            : null;
        tempData[index].adImpressionsSBVChangeInPercent =
          item?.brand_video_campaign_report_impressions !== null
            ? item.brand_video_campaign_report_impressions
            : null;
        tempData[index].adAcosSBVChangeInPercent =
          item?.brand_video_campaign_report_acos !== null
            ? item.brand_video_campaign_report_acos
            : null;
        tempData[index].adRoasSBVChangeInPercent =
          item?.brand_video_campaign_report_roas !== null
            ? item.brand_video_campaign_report_roas
            : null;
        tempData[index].adClicksSBVChangeInPercent =
          item?.brand_video_campaign_report_clicks !== null
            ? item.brand_video_campaign_report_clicks
            : null;
        tempData[index].adClickThroughRateSBVChangeInPercent =
          item?.brand_video_campaign_report_ctr !== null
            ? item.brand_video_campaign_report_ctr
            : null;
        tempData[index].adCostPerClickSBVChangeInPercent =
          item?.brand_video_campaign_report_cost_per_click !== null
            ? item.brand_video_campaign_report_cost_per_click
            : null;

        // bind sponsored display metrics comparison change in percent
        tempData[index].adSalesSDChangeInPercent =
          item?.sponsored_display_campaign_report_ad_sales !== null
            ? item.sponsored_display_campaign_report_ad_sales
            : null;
        tempData[index].adSpendSDChangeInPercent =
          item?.sponsored_display_campaign_report_ad_spend !== null
            ? item.sponsored_display_campaign_report_ad_spend
            : null;

        tempData[index].adConversionRateSDChangeInPercent =
          item?.sponsored_display_campaign_report_ad_conversion_rate !== null
            ? item.sponsored_display_campaign_report_ad_conversion_rate
            : null;
        tempData[index].adImpressionsSDChangeInPercent =
          item?.sponsored_display_campaign_report_impressions !== null
            ? item.sponsored_display_campaign_report_impressions
            : null;
        tempData[index].adAcosSDChangeInPercent =
          item?.sponsored_display_campaign_report_acos !== null
            ? item.sponsored_display_campaign_report_acos
            : null;
        tempData[index].adRoasSDChangeInPercent =
          item?.sponsored_display_campaign_report_roas !== null
            ? item.sponsored_display_campaign_report_roas
            : null;
        tempData[index].adClicksSDChangeInPercent =
          item?.sponsored_display_campaign_report_clicks !== null
            ? item.sponsored_display_campaign_report_clicks
            : null;
        tempData[index].adClickThroughRateSDChangeInPercent =
          item?.sponsored_display_campaign_report_ctr !== null
            ? item.sponsored_display_campaign_report_ctr
            : null;
        tempData[index].adCostPerClickSDChangeInPercent =
          item?.sponsored_display_campaign_report_cost_per_click !== null
            ? item.sponsored_display_campaign_report_cost_per_click
            : null;

        // bind dsp metrics comparison change in value
        tempData[index].dspImpressionsChangeInPercent =
          item?.dsp_impressions !== null ? item.dsp_impressions : null;
        tempData[index].dspSpendChangeInPercent =
          item?.dsp_dsp_spend !== null ? item.dsp_dsp_spend : null;
        tempData[index].dspTotalProductSalesChangeInPercent =
          item?.total_product_sales !== null ? item.total_product_sales : null;
        tempData[index].dspTotalRoasChangeInPercent =
          item?.total_dsp_roas !== null ? item.total_dsp_roas : null;
        tempData[index].dspTotalDpvrChangeInPercent =
          item?.total_dpvr !== null ? item.total_dpvr : null;
        tempData[index].dspTotalNewBrandPurchasesChangeInPercent =
          item?.ttl_new_brand_purchases !== null
            ? item.ttl_new_brand_purchases
            : null;
        tempData[index].dspProductSalesChangeInPercent =
          item?.product_sales !== null ? item.product_sales : null;
        tempData[index].dspRoasChangeInPercent =
          item?.dsp_roas !== null ? item.dsp_roas : null;
      }
    });
  }

  // set metrics total
  let localCurrrentTotal = {};
  if (acType === 'seller') {
    localCurrrentTotal = {
      // bind seller metrics previous total value
      salesRevenueTotalPrevious: previousTotalKey?.revenue,
      salesRevenuePpcPrevious: previousTotalKey?.revenue_ppc,
      salesRevenueDspPrevious: previousTotalKey?.revenue_dsp,
      salesTacosPrevious: previousTotalKey?.tacos,
      salesUnitSoldPrevious: previousTotalKey?.units_sold,
      salesTrafficPrevious: previousTotalKey?.traffic,
      salesConversionPrevious: previousTotalKey?.conversion,
      salesAdSpendTotalPrevious: previousTotalKey?.ad_spend_total,
      salesAdSpendPpcPrevious: previousTotalKey?.sales_ad_spend,
      salesAdSpendDspPrevious: previousTotalKey?.sales_dsp_spends,

      // bind seller metrics current total value
      salesRevenueTotalCurrent: currentTotalKey?.revenue,
      salesRevenuePpcCurrent: currentTotalKey?.revenue_ppc,
      salesRevenueDspCurrent: currentTotalKey?.revenue_dsp,
      salesTacosCurrent: currentTotalKey?.tacos,
      salesUnitSoldCurrent: currentTotalKey?.units_sold,
      salesTrafficCurrent: currentTotalKey?.traffic,
      salesConversionCurrent: currentTotalKey?.conversion,
      salesAdSpendTotalCurrent: currentTotalKey?.ad_spend_total,
      salesAdSpendPpcCurrent: currentTotalKey?.sales_ad_spend,
      salesAdSpendDspCurrent: currentTotalKey?.sales_dsp_spends,

      // bind seller metrics total comparison change in value
      salesRevenueTotalChangeInValue: totalChangeInValueKey?.revenue,
      salesRevenuePpcChangeInValue: totalChangeInValueKey?.revenue_ppc,
      salesRevenueDspChangeInValue: totalChangeInValueKey?.revenue_dsp,
      salesTacosChangeInValue: totalChangeInValueKey?.tacos,
      salesUnitSoldChangeInValue: totalChangeInValueKey?.units_sold,
      salesTrafficChangeInValue: totalChangeInValueKey?.traffic,
      salesConversionChangeInValue: totalChangeInValueKey?.conversion,
      salesAdSpendTotalChangeInValue: totalChangeInValueKey?.ad_spend_total,
      salesAdSpendPpcChangeInValue: totalChangeInValueKey?.sales_ad_spend,
      salesAdSpendDspChangeInValue: totalChangeInValueKey?.sales_dsp_spends,

      // bind seller metrics total comparison change in percentage
      salesRevenueTotalChangeInPercent: totalChangeInPercentKey?.revenue,
      salesRevenuePpcChangeInPercent: totalChangeInPercentKey?.revenue_ppc,
      salesRevenueDspChangeInPercent: totalChangeInPercentKey?.revenue_dsp,
      salesTacosChangeInPercent: totalChangeInPercentKey?.tacos,
      salesUnitSoldChangeInPercent: totalChangeInPercentKey?.units_sold,
      salesTrafficChangeInPercent: totalChangeInPercentKey?.traffic,
      salesConversionChangeInPercent: totalChangeInPercentKey?.conversion,
      salesAdSpendTotalChangeInPercent: totalChangeInPercentKey?.ad_spend_total,
      salesAdSpendPpcChangeInPercent: totalChangeInPercentKey?.sales_ad_spend,
      salesAdSpendDspChangeInPercent: totalChangeInPercentKey?.sales_dsp_spends,
    };
  } else if (acType === 'vendor') {
    localCurrrentTotal = {
      // bind vendor metrics previous total value
      salesOrderedRevenuePrevious: previousTotalKey?.ordered_revenue,
      salesOrderedGlanceViewsPrevious: previousTotalKey?.glance_views,
      salesOrderedConversionRatePrevious: previousTotalKey?.conversion_rate,
      SalesOrderedUnitsPrevious: previousTotalKey?.ordered_units,
      salesShippedCogsPrevious: previousTotalKey?.shipped_cogs,
      salesShippedGlanceViewsPrevious: previousTotalKey?.glance_views,
      salesShippedConversionRatePrevious: previousTotalKey?.conversion_rate,
      salesShippedCogsUnitPrevious: previousTotalKey?.shipped_units,

      // bind vendor metrics current total value
      salesOrderedRevenueCurrent: currentTotalKey.ordered_revenue,
      salesOrderedGlanceViewsCurrent: currentTotalKey.glance_views,
      salesOrderedUnitsCurrent: currentTotalKey.ordered_units,
      salesOrderedConversionRateCurrent: currentTotalKey.conversion_rate,
      salesShippedCogsCurrent: currentTotalKey.shipped_cogs,
      salesShippedGlanceViewsCurrent: currentTotalKey.glance_views,
      salesShippedConversionRateCurrent: currentTotalKey.conversion_rate,
      salesShippedUnitsCurrent: currentTotalKey.shipped_units,

      // bind vendor metrics total comparison change in value
      salesOrderedRevenueChangeInValue: totalChangeInValueKey?.ordered_revenue,
      salesOrderedGlanceViewsChangeInValue: totalChangeInValueKey?.glance_views,
      salesOrderedUnitsChangeInValue: totalChangeInValueKey?.ordered_units,
      salesOrderedConversionRateChangeInValue:
        totalChangeInValueKey?.conversion_rate,
      salesShippedCogsChangeInValue: totalChangeInValueKey?.shipped_cogs,
      salesShippedGlanceViewsChangeInValue: totalChangeInValueKey?.glance_views,
      salesShippedConversionRateChangeInValue:
        totalChangeInValueKey?.conversion_rate,
      salesShippedUnitsChangeInValue: totalChangeInValueKey?.shipped_units,

      // bind vendor metrics total comparison change in percentage
      salesOrderedRevenueChangeInPercent:
        totalChangeInPercentKey?.ordered_revenue,
      salesOrderedGlanceViewsChangeInPercent:
        totalChangeInPercentKey?.glance_views,
      salesOrderedUnitsChangeInPercent: totalChangeInPercentKey?.ordered_units,
      salesOrderedConversionRateChangeInPercent:
        totalChangeInPercentKey?.conversion_rate,
      salesShippedCogsChangeInPercent: totalChangeInPercentKey?.shipped_cogs,
      salesShippedGlanceViewsChangeInPercent:
        totalChangeInPercentKey?.glance_views,
      salesShippedConversionRateChangeInPercent:
        totalChangeInPercentKey?.conversion_rate,
      salesShippedUnitsChangeInPercent: totalChangeInPercentKey?.shipped_units,
    };
  }

  localCurrrentTotal = {
    ...localCurrrentTotal,

    adSalesPrevious: previousTotalKey?.all_ad_sales,
    adSpendPrevious: previousTotalKey?.all_ad_spend,
    adConversionRatePrevious: previousTotalKey?.all_ad_conversion_rate,
    adImpressionsPrevious: previousTotalKey?.all_impressions,
    adAcosPrevious: previousTotalKey?.all_acos,
    adRoasPrevious: previousTotalKey?.all_roas,
    adClicksPrevious: previousTotalKey?.all_clicks,
    adClickThroughRatePrevious: previousTotalKey?.all_ctr,
    adCostPerClickPrevious: previousTotalKey?.all_cost_per_click,

    adSalesSPPrevious: previousTotalKey?.product_campaign_report_ad_sales,
    adSpendSPPrevious: previousTotalKey?.product_campaign_report_ad_spend,
    adConversionRateSPPrevious:
      previousTotalKey?.product_campaign_report_ad_conversion_rate,
    adImpressionsSPPrevious:
      previousTotalKey?.product_campaign_report_impressions,
    adAcosSPPrevious: previousTotalKey?.product_campaign_report_acos,
    adRoasSPPrevious: previousTotalKey?.product_campaign_report_roas,
    adClicksSPPrevious: previousTotalKey?.product_campaign_report_clicks,
    adClickThroughRateSPPrevious: previousTotalKey?.product_campaign_report_ctr,
    adCostPerClickSPPrevious:
      previousTotalKey?.product_campaign_report_cost_per_click,

    adSalesSBPrevious: previousTotalKey?.brand_campaign_report_ad_sales,
    adSpendSBPrevious: previousTotalKey?.brand_campaign_report_ad_spend,
    adConversionRateSBPrevious:
      previousTotalKey?.brand_campaign_report_ad_conversion_rate,
    adImpressionsSBPrevious:
      previousTotalKey?.brand_campaign_report_impressions,
    adAcosSBPrevious: previousTotalKey?.brand_campaign_report_acos,
    adRoasSBPrevious: previousTotalKey?.brand_campaign_report_roas,
    adClicksSBPrevious: previousTotalKey?.brand_campaign_report_clicks,
    adClickThroughRateSBPrevious: previousTotalKey?.brand_campaign_report_ctr,
    adCostPerClickSBPrevious:
      previousTotalKey?.brand_campaign_report_cost_per_click,

    adSalesSBVPrevious: previousTotalKey?.brand_video_campaign_report_ad_sales,
    adSpendSBVPrevious: previousTotalKey?.brand_video_campaign_report_ad_spend,
    adConversionRateSBVPrevious:
      previousTotalKey?.brand_video_campaign_report_ad_conversion_rate,
    adImpressionsSBVPrevious:
      previousTotalKey?.brand_video_campaign_report_impressions,
    adAcosSBVPrevious: previousTotalKey?.brand_video_campaign_report_acos,
    adRoasSBVPrevious: previousTotalKey?.brand_video_campaign_report_roas,
    adClicksSBVPrevious: previousTotalKey?.brand_video_campaign_report_clicks,
    adClickThroughRateSBVPrevious:
      previousTotalKey?.brand_video_campaign_report_ctr,
    adCostPerClickSBVPrevious:
      previousTotalKey?.brand_video_campaign_report_cost_per_click,

    adSalesSDPrevious:
      previousTotalKey?.sponsored_display_campaign_report_ad_sales,
    adSpendSDPrevious:
      previousTotalKey?.sponsored_display_campaign_report_ad_spend,
    adConversionRateSDPrevious:
      previousTotalKey?.sponsored_display_campaign_report_ad_conversion_rate,
    adImpressionsSDPrevious:
      previousTotalKey?.sponsored_display_campaign_report_impressions,
    adAcosSDPrevious: previousTotalKey?.sponsored_display_campaign_report_acos,
    adRoasSDPrevious: previousTotalKey?.sponsored_display_campaign_report_roas,
    adClicksSDPrevious:
      previousTotalKey?.sponsored_display_campaign_report_clicks,
    adClickThroughRateSDPrevious:
      previousTotalKey?.sponsored_display_campaign_report_ctr,
    adCostPerClickSDPrevious:
      previousTotalKey?.sponsored_display_campaign_report_cost_per_click,

    dspImpressionsPrevious: previousTotalKey?.dsp_impressions,
    dspSpendPrevious: previousTotalKey?.dsp_dsp_spend,
    dspTotalProductSalesPrevious: previousTotalKey?.total_product_sales,
    dspTotalRoasPrevious: previousTotalKey?.total_dsp_roas,
    dspTotalDpvrPrevious: previousTotalKey?.total_dpvr,
    dspTotalNewBrandPurchasesPrevious:
      previousTotalKey?.ttl_new_brand_purchases,
    dspProductSalesPrevious: previousTotalKey?.product_sales,
    dspRoasPrevious: previousTotalKey?.dsp_roas,

    // bind sponsored all metrics current total value
    adSalesCurrent: currentTotalKey?.all_ad_sales,
    adSpendCurrent: currentTotalKey?.all_ad_spend,
    adConversionRateCurrent: currentTotalKey?.all_ad_conversion_rate,
    adImpressionsCurrent: currentTotalKey?.all_impressions,
    adAcosCurrent: currentTotalKey?.all_acos,
    adRoasCurrent: currentTotalKey?.all_roas,
    adClicksCurrent: currentTotalKey?.all_clicks,
    adClickThroughRateCurrent: currentTotalKey?.all_ctr,
    adCostPerClickCurrent: currentTotalKey?.all_cost_per_click,

    // bind sponsored product metrics current total value
    adSalesSPCurrent: currentTotalKey?.product_campaign_report_ad_sales,
    adSpendSPCurrent: currentTotalKey?.product_campaign_report_ad_spend,
    adConversionRateSPCurrent:
      currentTotalKey?.product_campaign_report_ad_conversion_rate,
    adImpressionsSPCurrent:
      currentTotalKey?.product_campaign_report_impressions,
    adAcosSPCurrent: currentTotalKey?.product_campaign_report_acos,
    adRoasSPCurrent: currentTotalKey?.product_campaign_report_roas,
    adClicksSPCurrent: currentTotalKey?.product_campaign_report_clicks,
    adClickThroughRateSPCurrent: currentTotalKey?.product_campaign_report_ctr,
    adCostPerClickSPCurrent:
      currentTotalKey?.product_campaign_report_cost_per_click,

    adSalesSBCurrent: currentTotalKey?.brand_campaign_report_ad_sales,
    adSpendSBCurrent: currentTotalKey?.brand_campaign_report_ad_spend,
    adConversionRateSBCurrent:
      currentTotalKey?.brand_campaign_report_ad_conversion_rate,
    adImpressionsSBCurrent: currentTotalKey?.brand_campaign_report_impressions,
    adAcosSBCurrent: currentTotalKey?.brand_campaign_report_acos,
    adRoasSBCurrent: currentTotalKey?.brand_campaign_report_roas,
    adClicksSBCurrent: currentTotalKey?.brand_campaign_report_clicks,
    adClickThroughRateSBCurrent: currentTotalKey?.brand_campaign_report_ctr,
    adCostPerClickSBCurrent:
      currentTotalKey?.brand_campaign_report_cost_per_click,

    adSalesSBVCurrent: currentTotalKey?.brand_video_campaign_report_ad_sales,
    adSpendSBVCurrent: currentTotalKey?.brand_video_campaign_report_ad_spend,
    adConversionRateSBVCurrent:
      currentTotalKey?.brand_video_campaign_report_ad_conversion_rate,
    adImpressionsSBVCurrent:
      currentTotalKey?.brand_video_campaign_report_impressions,
    adAcosSBVCurrent: currentTotalKey?.brand_video_campaign_report_acos,
    adRoasSBVCurrent: currentTotalKey?.brand_video_campaign_report_roas,
    adClicksSBVCurrent: currentTotalKey?.brand_video_campaign_report_clicks,
    adClickThroughRateSBVCurrent:
      currentTotalKey?.brand_video_campaign_report_ctr,
    adCostPerClickSBVCurrent:
      currentTotalKey?.brand_video_campaign_report_cost_per_click,

    adSalesSDCurrent:
      currentTotalKey?.sponsored_display_campaign_report_ad_sales,
    adSpendSDCurrent:
      currentTotalKey?.sponsored_display_campaign_report_ad_spend,
    adConversionRateSDCurrent:
      currentTotalKey?.sponsored_display_campaign_report_ad_conversion_rate,
    adImpressionsSDCurrent:
      currentTotalKey?.sponsored_display_campaign_report_impressions,
    adAcosSDCurrent: currentTotalKey?.sponsored_display_campaign_report_acos,
    adRoasSDCurrent: currentTotalKey?.sponsored_display_campaign_report_roas,
    adClicksSDCurrent:
      currentTotalKey?.sponsored_display_campaign_report_clicks,
    adClickThroughRateSDCurrent:
      currentTotalKey?.sponsored_display_campaign_report_ctr,
    adCostPerClickSDCurrent:
      currentTotalKey?.sponsored_display_campaign_report_cost_per_click,

    // bind sponsored all metrics total comparison change in value
    adSalesChangeInValue: totalChangeInValueKey?.all_ad_sales,
    adSpendChangeInValue: totalChangeInValueKey?.all_ad_spend,
    adConversionRateChangeInValue:
      totalChangeInValueKey?.all_ad_conversion_rate,
    adImpressionsChangeInValue: totalChangeInValueKey?.all_impressions,
    adAcosChangeInValue: totalChangeInValueKey?.all_acos,
    adRoasChangeInValue: totalChangeInValueKey?.all_roas,
    adClicksChangeInValue: totalChangeInValueKey?.all_clicks,
    adClickThroughRateChangeInValue: totalChangeInValueKey?.all_ctr,
    adCostPerClickChangeInValue: totalChangeInValueKey?.all_cost_per_click,

    // bind sponsored product metrics total comparison change in value
    adSalesSPChangeInValue:
      totalChangeInValueKey?.product_campaign_report_ad_sales,
    adSpendSPChangeInValue:
      totalChangeInValueKey?.product_campaign_report_ad_spend,
    adConversionRateSPChangeInValue:
      totalChangeInValueKey?.product_campaign_report_ad_conversion_rate,
    adImpressionsSPChangeInValue:
      totalChangeInValueKey?.product_campaign_report_impressions,
    adAcosSPChangeInValue: totalChangeInValueKey?.product_campaign_report_acos,
    adRoasSPChangeInValue: totalChangeInValueKey?.product_campaign_report_roas,
    adClicksSPChangeInValue:
      totalChangeInValueKey?.product_campaign_report_clicks,
    adClickThroughRateSPChangeInValue:
      totalChangeInValueKey?.product_campaign_report_ctr,
    adCostPerClickSPChangeInValue:
      totalChangeInValueKey?.product_campaign_report_cost_per_click,

    // bind sponsored brand metrics total comparison change in value
    adSalesSBChangeInValue:
      totalChangeInValueKey?.brand_campaign_report_ad_sales,
    adSpendSBChangeInValue:
      totalChangeInValueKey?.brand_campaign_report_ad_spend,
    adConversionRateSBChangeInValue:
      totalChangeInValueKey?.brand_campaign_report_ad_conversion_rate,
    adImpressionsSBChangeInValue:
      totalChangeInValueKey?.brand_campaign_report_impressions,
    adAcosSBChangeInValue: totalChangeInValueKey?.brand_campaign_report_acos,
    adRoasSBChangeInValue: totalChangeInValueKey?.brand_campaign_report_roas,
    adClicksSBChangeInValue:
      totalChangeInValueKey?.brand_campaign_report_clicks,
    adClickThroughRateSBChangeInValue:
      totalChangeInValueKey?.brand_campaign_report_ctr,
    adCostPerClickSBChangeInValue:
      totalChangeInValueKey?.brand_campaign_report_cost_per_click,

    // bind sponsored video metrics total comparison change in value
    adSalesSBVChangeInValue:
      totalChangeInValueKey?.brand_video_campaign_report_ad_sales,
    adSpendSBVChangeInValue:
      totalChangeInValueKey?.brand_video_campaign_report_ad_spend,
    adConversionRateSBVChangeInValue:
      totalChangeInValueKey?.brand_video_campaign_report_ad_conversion_rate,
    adImpressionsSBVChangeInValue:
      totalChangeInValueKey?.brand_video_campaign_report_impressions,
    adAcosSBVChangeInValue:
      totalChangeInValueKey?.brand_video_campaign_report_acos,
    adRoasSBVChangeInValue:
      totalChangeInValueKey?.brand_video_campaign_report_roas,
    adClicksSBVChangeInValue:
      totalChangeInValueKey?.brand_video_campaign_report_clicks,
    adClickThroughRateSBVChangeInValue:
      totalChangeInValueKey?.brand_video_campaign_report_ctr,
    adCostPerClickSBVChangeInValue:
      totalChangeInValueKey?.brand_video_campaign_report_cost_per_click,

    // bind sponsored display metrics total comparison change in value
    adSalesSDChangeInValue:
      totalChangeInValueKey?.sponsored_display_campaign_report_ad_sales,
    adSpendSDChangeInValue:
      totalChangeInValueKey?.sponsored_display_campaign_report_ad_spend,
    adConversionRateSDChangeInValue:
      totalChangeInValueKey?.sponsored_display_campaign_report_ad_conversion_rate,
    adImpressionsSDChangeInValue:
      totalChangeInValueKey?.sponsored_display_campaign_report_impressions,
    adAcosSDChangeInValue:
      totalChangeInValueKey?.sponsored_display_campaign_report_acos,
    adRoasSDChangeInValue:
      totalChangeInValueKey?.sponsored_display_campaign_report_roas,
    adClicksSDChangeInValue:
      totalChangeInValueKey?.sponsored_display_campaign_report_clicks,
    adClickThroughRateSDChangeInValue:
      totalChangeInValueKey?.sponsored_display_campaign_report_ctr,
    adCostPerClickSDChangeInValue:
      totalChangeInValueKey?.sponsored_display_campaign_report_cost_per_click,

    // bind sponsored all metrics total comparison change in percentage
    adSalesChangeInPercent: totalChangeInPercentKey?.all_ad_sales,
    adSpendChangeInPercent: totalChangeInPercentKey?.all_ad_spend,
    adConversionRateChangeInPercent:
      totalChangeInPercentKey?.all_ad_conversion_rate,
    adImpressionsChangeInPercent: totalChangeInPercentKey?.all_impressions,
    adAcosChangeInPercent: totalChangeInPercentKey?.all_acos,
    adRoasChangeInPercent: totalChangeInPercentKey?.all_roas,
    adClicksChangeInPercent: totalChangeInPercentKey?.all_clicks,
    adClickThroughRateChangeInPercent: totalChangeInPercentKey?.all_ctr,
    adCostPerClickChangeInPercent: totalChangeInPercentKey?.all_cost_per_click,

    // bind sponsored product metrics total comparison change in percentage
    adSalesSPChangeInPercent:
      totalChangeInPercentKey?.product_campaign_report_ad_sales,
    adSpendSPChangeInPercent:
      totalChangeInPercentKey?.product_campaign_report_ad_spend,
    adConversionRateSPChangeInPercent:
      totalChangeInPercentKey?.product_campaign_report_ad_conversion_rate,
    adImpressionsSPChangeInPercent:
      totalChangeInPercentKey?.product_campaign_report_impressions,
    adAcosSPChangeInPercent:
      totalChangeInPercentKey?.product_campaign_report_acos,
    adRoasSPChangeInPercent:
      totalChangeInPercentKey?.product_campaign_report_roas,
    adClicksSPChangeInPercent:
      totalChangeInPercentKey?.product_campaign_report_clicks,
    adClickThroughRateSPChangeInPercent:
      totalChangeInPercentKey?.product_campaign_report_ctr,
    adCostPerClickSPChangeInPercent:
      totalChangeInPercentKey?.product_campaign_report_cost_per_click,

    // bind sponsored brand metrics total comparison change in percentage
    adSalesSBChangeInPercent:
      totalChangeInPercentKey?.brand_campaign_report_ad_sales,
    adSpendSBChangeInPercent:
      totalChangeInPercentKey?.brand_campaign_report_ad_spend,
    adConversionRateSBChangeInPercent:
      totalChangeInPercentKey?.brand_campaign_report_ad_conversion_rate,
    adImpressionsSBChangeInPercent:
      totalChangeInPercentKey?.brand_campaign_report_impressions,
    adAcosSBChangeInPercent:
      totalChangeInPercentKey?.brand_campaign_report_acos,
    adRoasSBChangeInPercent:
      totalChangeInPercentKey?.brand_campaign_report_roas,
    adClicksSBChangeInPercent:
      totalChangeInPercentKey?.brand_campaign_report_clicks,
    adClickThroughRateSBChangeInPercent:
      totalChangeInPercentKey?.brand_campaign_report_ctr,
    adCostPerClickSBChangeInPercent:
      totalChangeInPercentKey?.brand_campaign_report_cost_per_click,

    // bind sponsored video metrics total comparison change in percentage
    adSalesSBVChangeInPercent:
      totalChangeInPercentKey?.brand_video_campaign_report_ad_sales,
    adSpendSBVChangeInPercent:
      totalChangeInPercentKey?.brand_video_campaign_report_ad_spend,
    adConversionRateSBVChangeInPercent:
      totalChangeInPercentKey?.brand_video_campaign_report_ad_conversion_rate,
    adImpressionsSBVChangeInPercent:
      totalChangeInPercentKey?.brand_video_campaign_report_impressions,
    adAcosSBVChangeInPercent:
      totalChangeInPercentKey?.brand_video_campaign_report_acos,
    adRoasSBVChangeInPercent:
      totalChangeInPercentKey?.brand_video_campaign_report_roas,
    adClicksSBVChangeInPercent:
      totalChangeInPercentKey?.brand_video_campaign_report_clicks,
    adClickThroughRateSBVChangeInPercent:
      totalChangeInPercentKey?.brand_video_campaign_report_ctr,
    adCostPerClickSBVChangeInPercent:
      totalChangeInPercentKey?.brand_video_campaign_report_cost_per_click,

    // bind sponsored display metrics total comparison change in percentage
    adSalesSDChangeInPercent:
      totalChangeInPercentKey?.sponsored_display_campaign_report_ad_sales,
    adSpendSDChangeInPercent:
      totalChangeInPercentKey?.sponsored_display_campaign_report_ad_spend,
    adConversionRateSDChangeInPercent:
      totalChangeInPercentKey?.sponsored_display_campaign_report_ad_conversion_rate,
    adImpressionsSDChangeInPercent:
      totalChangeInPercentKey?.sponsored_display_campaign_report_impressions,
    adAcosSDChangeInPercent:
      totalChangeInPercentKey?.sponsored_display_campaign_report_acos,
    adRoasSDChangeInPercent:
      totalChangeInPercentKey?.sponsored_display_campaign_report_roas,
    adClicksSDChangeInPercent:
      totalChangeInPercentKey?.sponsored_display_campaign_report_clicks,
    adClickThroughRateSDChangeInPercent:
      totalChangeInPercentKey?.sponsored_display_campaign_report_ctr,
    adCostPerClickSDChangeInPercent:
      totalChangeInPercentKey?.sponsored_display_campaign_report_cost_per_click,

    // bind dsp metrics current total value
    dspImpressionsCurrent: currentTotalKey?.dsp_impressions,
    dspSpendCurrent: currentTotalKey?.dsp_dsp_spend,
    dspTotalProductSalesCurrent: currentTotalKey?.total_product_sales,
    dspTotalRoasCurrent: currentTotalKey?.total_dsp_roas,
    dspTotalDpvrCurrent: currentTotalKey?.total_dpvr,
    dspTotalNewBrandPurchasesCurrent: currentTotalKey?.ttl_new_brand_purchases,
    dspProductSalesCurrent: currentTotalKey?.product_sales,
    dspRoasCurrent: currentTotalKey?.dsp_roas,

    // bind dsp metrics comparison change in value
    dspImpressionsChangeInValue: totalChangeInValueKey?.dsp_impressions,
    dspSpendChangeInValue: totalChangeInValueKey?.dsp_dsp_spend,
    dspTotalProductSalesChangeInValue:
      totalChangeInValueKey?.total_product_sales,
    dspTotalRoasChangeInValue: totalChangeInValueKey?.total_dsp_roas,
    dspTotalDpvrChangeInValue: totalChangeInValueKey?.total_dpvr,
    dspTotalNewBrandPurchasesChangeInValue:
      totalChangeInValueKey?.ttl_new_brand_purchases,
    dspProductSalesChangeInValue: totalChangeInValueKey?.product_sales,
    dspRoasChangeInValue: totalChangeInValueKey?.dsp_roas,

    // bind dsp metrics comparison change in percentage
    dspImpressionsChangeInPercent: totalChangeInPercentKey?.dsp_impressions,
    dspSpendChangeInPercent: totalChangeInPercentKey?.dsp_dsp_spend,
    dspTotalProductSalesChangeInPercent:
      totalChangeInPercentKey?.total_product_sales,
    dspTotalRoasChangeInPercent: totalChangeInPercentKey?.total_dsp_roas,
    dspTotalDpvrChangeInPercent: totalChangeInPercentKey?.total_dpvr,
    dspTotalNewBrandPurchasesChangeInPercent:
      totalChangeInPercentKey?.ttl_new_brand_purchases,
    dspProductSalesChangeInPercent: totalChangeInPercentKey?.product_sales,
    dspRoasChangeInPercent: totalChangeInPercentKey?.dsp_roas,
  };

  return { tempData, metricsTotal: localCurrrentTotal };
};

export default BindGraphDataApiResponse;
