/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback, useRef } from 'react';

import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import PdfViewer from '../../common/PdfViewer';
import AgreementSidePanel from './AgreementRightSideSections/AgreementSidePanelContainer';
import ContractFooter from './ContractFooter/ContractFooter';
import THAD_SIGN_IMG from '../../constants/ThadSignImg';
import { getAccountDetails } from '../../store/actions/accountState';
import { getCustomerDetails } from '../../store/actions/customerState';
import { ContractTab } from '../../theme/AgreementStyle';
import { getNonUSStdServicesOfAddendum } from './Common/commonFunctionsForUniqueServices';

import {
  BBEStandardLogo,
  CloseIcon,
  OrangeDownloadPdf,
} from '../../theme/images';
import {
  Agreement,
  DSPAddendum,
  Addendum,
  Statement,
} from './AgreementLeftSideSections';
import {
  RequestSignatureModal,
  DiscardChangesConfirmation,
  DiscountModal,
  ContractEditConfirmation,
} from './ContractModals';
import {
  PageLoader,
  PageNotFound,
  Button,
  HeaderDownloadFuntionality,
  imageToDataURL,
} from '../../common';
import {
  agreementTemplate,
  getcontract,
  getServicesFee,
  getServiceTypes,
  getDiscount,
  saveFeeStructure,
  getFeeStructure,
  saveStdServices,
  fetchAdvertisingServices,
  createAMCServicesBulk,
} from '../../api/AgreementApi';
import {
  updateAccountDetails,
  createMarketplace,
  updateMarketplace,
  createAddendum,
  getAddendum,
  updateAddendum,
  createMarketplaceBulk,
  createAdditionalServiceBulk,
  getMarketplaces,
  getContractActivityLog,
  getDocumentList,
  createContractDesign,
  updateCustomerDetails,
  getOneTimeService,
  getThresholdType,
  getYoyPercentage,
  saveDiscount,
  getStandardServices,
  createStandardNonUSServiceBulk,
  createDSPMarketplacesBulk,
  getAgencyFee,
  getContractAddendum,
  saveDraftAddendum,
  getNonUSStandardServicesMarketplaces,
} from '../../api';
import {
  AgreementDetails,
  feeStructureContainerDetails,
  DSPAddendumDetails,
  additionaMarketplaceAmount,
  AgreementSign,
  AddendumSign,
  RecurringLanguage,
  Recurring90DaysLanguage,
  PATH_CUSTOMER_DETAILS,
  PATH_CUSTOMER_LIST,
  thadDetails,
  PATH_AGREEMENT,
  displayFixedThresholdInfo,
  displayFixedRetainerInfo,
  displayQuarterlyRetainerInfo,
  displayMonthlyRetainerInfo,
  displayFeeTypeInfo,
  displayRevenueShareInfo,
  displayBillingMinimumInfo,
  displayBillingCapInfo,
  revShareLimit,
  revShareThresholdLimit,
  monthlyRetainerLimit,
  SpreetailRole,
  SpreetailRoleRestrictMessage,
} from '../../constants';

import {
  oneTimeSpecificServices,
  oneTimeSpecificServicesFees,
  returnOneTimeServiceNames,
  returnOneTimeServiceFees,
  returnOneTimeServiceFeesTotal,
} from '../../common/OneTimeServiceSubTabFunctions';
import AddedServicesEffectivityDatesModal from './ContractModals/AddedServicesEffectivityDatesModal';

const nonDraftCustomStyles = {
  width: '50%',
  margin: '0',
  paddingLeft: '0',
  paddingRight: '0',
};

export default function ContractContainer() {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const styleForChangedValues = `font-weight: 800; font-family: Helvetica-bold; background: #ffe5df; padding: 4px 9px;`;
  const defaultEffectivityDateText =
    '[choose either date of addendum or custom date]';
  const [logoURL, setLogoURL] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const { id } = useParams();
  const [showEffetivityDatesModal, setShowEffetivityDatesModal] = useState({
    show: false,
    data: [],
  });
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [formData, setFormData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [updatedFormData, setUpdatedFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [amazonStoreCustom, setAmazonStoreCustom] = useState(false);
  const [showAmazonPlanDropdown, setShowAmazonPlanDropdown] = useState(false);
  const [showAmazonStorefrontDropdown, setShowAmazonStorefrontDropdown] =
    useState(false);

  const [showDiscardModal, setShowDiscardModal] = useState({
    clickedBtn: '',
    show: false,
  });
  const [isEditContract, setIsEditContract] = useState(false);
  const [details, setDetails] = useState({});
  const [marketplacesResult, setMarketplacesResult] = useState([]);

  const [editContractFlag, setEditContractFlag] = useState(true);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [
    showEditContractConfirmationModal,
    setShowEditContractConfirmationModal,
  ] = useState(false);

  const [isFooter, showFooter] = useState(false);
  const [newAddendumData, setNewAddendum] = useState(null);
  const [originalAddendumData, setOriginalAddendumData] = useState(null);

  const [showEditor, setShowEditor] = useState(false);
  const [pdfData, setPDFData] = useState('');
  const [startDate, setStartDate] = useState();

  const [notIncludedOneTimeServices, setNotIncludedOneTimeServices] = useState(
    [],
  );
  const [notIncludedMonthlyServices, setNotIncludedMonthlyServices] = useState(
    [],
  );
  const [additionalMonthlyServices, setMonthlyAdditionalServices] = useState(
    [],
  );

  const [additionalMarketplacesData, setAdditionalMarketplace] = useState({});
  const [additionalOnetimeServices, setAdditionalOnetimeServices] = useState(
    [],
  );

  const [apiError, setApiError] = useState({});
  const [sectionError, setSectionError] = useState({
    vendor: { feeType: 0 },
    seller: { feeType: 0 },
  });
  const [additionalMarketplaceError, setAdditionalMarketplaceError] = useState(
    {},
  );
  const [additionalMonthlySerError, setAdditionalMonthlySerError] = useState(
    {},
  );
  const [amcServiceError, setAmcServiceError] = useState({});
  const [nonUsServiceError, setNonUSserviceError] = useState({});

  const [additionalOnetimeSerError, setAdditionalOnetimeSerError] = useState(
    {},
  );
  const [customerError, setCustomerErrors] = useState({});

  const [contractError, setContractError] = useState({});
  const [feeStructureErrors, setFeeStructureErrors] = useState({});

  const [showAdditionalMarketplace, setShowAdditionalMarketplace] = useState({
    Seller: { showDropdown: false },
    Vendor: { showDropdown: false },
  });
  const [downloadApiCall, setDownloadApiCall] = useState(false);
  const [isDocRendered, setIsDocRendered] = useState(false);
  const [discountData, setDiscountData] = useState([]);
  const [showPageNotFound, setPageNotFoundFlag] = useState(false);
  const [showSection, setShowCollpase] = useState({
    addendum: false,
    dspAddendum: false,
  });
  const [openCollapse, setOpenCollapse] = useState({
    agreement: true,
    statement: false,
    addendum: false,
    dspAddendum: false,
  });

  const [loaderFlag, setLoaderFlag] = useState(true);
  const [contractDesignLoader, setContractDesignLoader] = useState(null);
  const [calculatedDate, setCalculatedDate] = useState(null);
  const [firstMonthDate, setFirstMonthDate] = useState(null);
  const [secondMonthDate, setSecondMonthDate] = useState(null);
  const [thirdMonthDate, setThirdMonthDate] = useState(null);
  const [endMonthDate, setEndDate] = useState(null);
  const [dspAdvertisingMaxDate, setDspAdvertisingMaxDate] = useState(null);
  const [tabInResponsive, setShowtabInResponsive] = useState('view-contract');
  const [discountFlag, setDiscountFlag] = useState({});
  const [selectedDiscount, setSelectedDiscount] = useState({});

  const [marketPlaces, setMarketPlaces] = useState([]);
  const [additionalMarketplaces, setAdditionalMarketplaces] = useState([]);
  const [dspAdditionalMarketplaces, setDspAdditionalMarketplaces] = useState(
    [],
  );
  const [activityData, setActivityData] = useState([]);
  const [activityCount, setActivityCount] = useState([]);
  const [pageNumber, setPageNumber] = useState();
  const [images, setImages] = useState([]);
  const [activityLoader, setActivityLoader] = useState(false);
  const [isApicalled, setIsApicalled] = useState(false);
  const [showSignSuccessMsg, setShowSignSuccessMsg] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [oneTimeService, setOneTimeService] = useState([]);
  const [monthlyService, setMonthlyService] = useState([]);
  const [AmazonStoreOptions, setAmazonStoreOptions] = useState(false);
  const [thresholdTypeOptions, setThresholdTypeOptions] = useState(false);
  const [yoyPercentageOptions, setYoyPercentageOptions] = useState(false);
  const [servicesFees, setServicesFees] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState('$ (USD)');
  const [stdServices, setStdServices] = useState([]);
  const [originalStandardServices, setOriginalStandardServices] = useState([]);
  const [agencyFeeOptions, setAgencyFeeOptions] = useState([]);
  const [disableCombineRevShare, setDisableCombineRevShare] = useState(true);
  const [contractAddendumDetails, setContractAddendumDetails] = useState({});
  const [showAddendum, setShowAddendum] = useState(false);
  const [addendumDetails, setAddendumDetails] = useState({});
  const [checkAddendumData, setCheckAddendumData] = useState(false);
  const [changedDspStartDateFlag, setChangedDspStartDateFlag] = useState(false);
  const [dspMarketpalcesError, setDspMarketpalcesError] = useState({});
  const [helloSignEnterDateTextFlag, setHelloSignEnterDateTextFlag] =
    useState(false);
  const [enableDownloadBtn, setEnableDownloadBtn] = useState(false);
  const [vatMarketplaces, setVatMarketplaces] = useState([]);
  const [combinedOneTimeServices, setcombinedOneTimeServices] = useState([]);
  const [notIncludedOneTimeServicesNew, setNotIncludedOneTimeServicesNew] =
    useState([]);

  const [savedMarketplaces, setSavedMarketplaces] = useState([
    {
      dsp: [],
      recurring: [],
      localDsp: [],
      localPrimaryRecurring: [],
      localAdditionalRecurring: [],
    },
  ]);
  const [advertisingServices, setAdvertisingService] = useState([]);

  const detailsId = details?.id;
  const contractStatusValue = details?.contract_status?.value;
  const contractTypeDsp = formData?.contract_type
    ?.toLowerCase()
    .includes('dsp');
  const contractTypeRecurring = formData?.contract_type
    ?.toLowerCase()
    .includes('recurring');
  const contractTypeOne = formData?.contract_type
    ?.toLowerCase()
    .includes('one');
  const accountType = formData?.seller_type?.value;

  const primaryMarketplaceName = details?.primary_marketplace?.name;
  const customerCompanyName = formData?.customer_id?.company_name;
  const customerAddress = formData?.customer_id?.address;
  const customerState = formData?.customer_id?.state;
  const customerCity = formData?.customer_id?.city;
  const customerZipCode = formData?.customer_id?.zip_code;
  const multipleAgreement = useSelector(
    (state) => state.accountState.multipleAgreement,
  );

  useEffect(() => {
    dispatch(getAccountDetails(id));
    dispatch(getCustomerDetails(id));
  }, []);

  // get bbe logo base64 url
  const getBase64Image = useCallback(() => {
    imageToDataURL(BBEStandardLogo).then((response) => {
      setLogoURL(response?.data);
    });
  }, []);

  // useeffect
  useEffect(() => {
    getBase64Image();
  }, []);

  // (For dont disable) Remove marketplace name of addendum contracts for himself.
  const checkAddendumStatusForMarketPlaces = (
    addendumStatus,
    option,
    formDataId,
  ) => {
    if (formDataId !== undefined) {
      if (
        option?.contract_addendum?.[0]?.addendum_status === addendumStatus &&
        option?.id !== formDataId
      ) {
        return (
          option?.contract_addendum?.[0]?.addendum_status === addendumStatus
        );
      }
    }
    return false;
  };

  // Filter all the marketplaces according to status for same marketplaces disabled functionality
  const getSavedMarketplaces = () => {
    const contractStatusForAddendum = [
      'pending addendum approval',
      'pending addendum',
    ];

    const checkAddendumStatusForMarketPlacesTemp = (option, formDataId) => {
      for (const addendumStatusMarketPlaces of contractStatusForAddendum) {
        return checkAddendumStatusForMarketPlaces(
          addendumStatusMarketPlaces,
          option,
          formDataId,
        );
      }
      return false;
    };

    const filteredAgreementTemp = multipleAgreement.filter(
      (op) =>
        op?.contract_status?.value === 'pending contract signature' ||
        ((op?.contract_status?.value === 'active' ||
          op?.contract_status?.value === 'renewed') &&
          (op?.contract_addendum
            ? op?.contract_addendum?.[0]?.addendum_status ===
                'pending addendum signature' ||
              op?.contract_addendum?.[0]?.addendum_status === 'active' ||
              checkAddendumStatusForMarketPlacesTemp(
                op,
                formData?.id?.startsWith('COA')
                  ? formData?.contract
                  : formData?.id,
              )
            : true)) ||
        op?.contract_addendum?.[0]?.addendum_status ===
          'pending addendum signature' ||
        op?.contract_addendum?.[0]?.addendum_status === 'active' ||
        checkAddendumStatusForMarketPlacesTemp(
          op,
          formData?.id?.startsWith('COA') ? formData?.contract : formData?.id,
        ),
    );

    // Remove contracts whose status are cancel and inactive.
    const filteredAgreementcancel = filteredAgreementTemp?.filter(
      (op) => op?.contract_status?.value !== 'cancel',
    );

    const filteredAgreement = filteredAgreementcancel?.filter(
      (op) => op?.contract_status?.value !== 'inactive',
    );

    const dspMarketplaces = [];
    const localAdditionalMarketplaces = [];
    const primaryMarketplace = [];
    for (const item of filteredAgreement) {
      // get all the dsp marketplaces of active/pending sign agreement
      if (item?.dsp_marketplaces?.length) {
        for (const dsp of item.dsp_marketplaces) {
          dspMarketplaces.push(dsp.name);
        }
      }

      // get all the dsp marketplaces of addendum pending signature agreement
      if (
        item?.contract_addendum?.[0]?.addendum_status ===
          'pending addendum signature' &&
        item?.contract_addendum[0]?.dsp_marketplaces?.length
      ) {
        for (const dsp of item?.contract_addendum[0]?.dsp_marketplaces) {
          dspMarketplaces.push(dsp.name);
        }
      }

      // get all the additional marketplaces of active/pending sign agreement according to account type
      if (item?.additional_marketplaces?.length) {
        for (const additional of item.additional_marketplaces) {
          localAdditionalMarketplaces.push({
            type: additional.account_type?.toLowerCase(),
            name: additional.name,
            is_primary: false,
          });
        }
      }

      // get all the additional marketplaces of addendum pending signature agreement
      if (
        item?.contract_addendum?.[0]?.addendum_status ===
          'pending addendum signature' &&
        item?.contract_addendum?.length &&
        item?.contract_addendum[0]?.additional_marketplaces
      ) {
        for (const additional of item?.contract_addendum[0]
          ?.additional_marketplaces) {
          localAdditionalMarketplaces.push({
            type: additional.account_type?.toLowerCase(),
            name: additional.name,
            is_primary: false,
          });
        }
      }

      // get all the primary marketplace of active/pending sign agreement according to account type
      if (
        item?.primary_marketplace &&
        Object.keys(item.primary_marketplace)?.length
      ) {
        if (item?.seller_type?.value === 'Hybrid') {
          primaryMarketplace.push(
            {
              type: 'seller',
              name: item?.primary_marketplace?.name,
              is_primary: true,
            },
            {
              type: 'vendor',
              name: item?.primary_marketplace?.name,
              is_primary: true,
            },
          );
        } else {
          primaryMarketplace.push({
            type: item?.seller_type?.value?.toLowerCase(),
            name: item?.primary_marketplace?.name,
            is_primary: true,
          });
        }
      }
    }

    setSavedMarketplaces({
      dsp: dspMarketplaces,
      recurring: [...primaryMarketplace, ...localAdditionalMarketplaces],
    });
  };

  useEffect(() => {
    getSavedMarketplaces();
  }, [multipleAgreement, formData?.id, formData?.contract]);

  useEffect(() => {
    // check for unique dsp marketplaces before request approval/sign
    const dspMarketplacesInFormData = formData?.dsp_marketplaces?.map(
      (item) => item?.name,
    );
    let sameDspMarketPlacesTemp1 = [];
    let samePrimaryMarketPlacesTemp = [];
    let sameAdditionalMarketPlacesTemp = [];

    if (savedMarketplaces?.dsp?.length) {
      const sameDspMarketPlacesTemp = savedMarketplaces?.dsp?.filter((item1) =>
        dspMarketplacesInFormData?.some((item2) => item1 === item2),
      );
      sameDspMarketPlacesTemp1 = sameDspMarketPlacesTemp?.length
        ? sameDspMarketPlacesTemp
        : [];
    }

    // check for unique primary marketplaces before request approval/sign

    if (
      savedMarketplaces?.recurring?.length &&
      formData?.primary_marketplace?.name
    ) {
      samePrimaryMarketPlacesTemp = savedMarketplaces?.recurring?.filter(
        (item) =>
          item.name === formData?.primary_marketplace?.name &&
          item?.is_primary === formData?.primary_marketplace?.is_primary,
      );
      setSavedMarketplaces({
        ...savedMarketplaces,
        localPrimaryRecurring: samePrimaryMarketPlacesTemp,
        localAdditionalRecurring: sameAdditionalMarketPlacesTemp,
        localDsp: sameDspMarketPlacesTemp1,
      });
    }

    // check for unique additional marketplaces before request approval/sign
    if (
      savedMarketplaces?.recurring?.length &&
      formData?.additional_marketplaces?.length
    ) {
      sameAdditionalMarketPlacesTemp = savedMarketplaces?.recurring?.filter(
        (elem) =>
          formData?.additional_marketplaces?.some(
            ({ name, account_type: localAccountType }) =>
              elem.name === name &&
              elem.type?.toLowerCase() === localAccountType?.toLowerCase(),
          ),
      );

      setTimeout(() => {
        setSavedMarketplaces({
          ...savedMarketplaces,
          localPrimaryRecurring: samePrimaryMarketPlacesTemp,
          localAdditionalRecurring: sameAdditionalMarketPlacesTemp,
          localDsp: sameDspMarketPlacesTemp1,
        });
      }, 1000);
    }
  }, [
    savedMarketplaces.recurring,
    formData.additional_marketplaces,
    formData.primary_marketplace,
    savedMarketplaces.dsp,
    formData.dsp_marketplaces,
  ]);

  // new code for not Included one time services
  const filterNotIncludedOneTimeServices = () => {
    let includedServices = [];
    // eslint-disable-next-line no-unused-vars
    const includedServicesTemp = formData?.additional_one_time_services?.filter(
      (service) => {
        if (service?.quantity) {
          includedServices.push(service?.service?.name || service?.name);
        }
        return null;
      },
    );
    includedServices = includedServices?.filter(Boolean);
    const servicesNotIncluded = oneTimeService?.filter((service) => {
      return !includedServices?.some((service1) => {
        return service?.label === service1;
      });
    });
    setNotIncludedOneTimeServicesNew(servicesNotIncluded);
  };
  useEffect(() => {
    filterNotIncludedOneTimeServices();
  }, [formData.additional_one_time_services, oneTimeService]);

  useEffect(() => {
    const availableAdditionalMarketplaces =
      formData?.additional_marketplaces?.map((item) => item.account_type);
    if (!availableAdditionalMarketplaces?.length) {
      setShowAdditionalMarketplace({
        Seller: { showDropdown: false },
        Vendor: { showDropdown: false },
      });
    }
  }, [formData?.additional_marketplaces]);

  const displayMarketplaceName = (value, type, side) => {
    const marketplaceArray =
      type === 'primary'
        ? marketPlaces
        : type === 'vat'
        ? vatMarketplaces
        : type === 'dsp'
        ? dspAdditionalMarketplaces
        : additionalMarketplaces;
    const filteredMarketplace = marketplaceArray?.find(
      (op) => value === op.value,
    )?.label;
    return side === 'lhs'
      ? filteredMarketplace
        ? `${filteredMarketplace} Marketplace Management`
        : ''
      : filteredMarketplace;
  };

  useEffect(() => {
    displayMarketplaceName();
  }, [marketPlaces, additionalMarketplaces, formData, vatMarketplaces]);

  useEffect(() => {
    if (formData?.dsp_start_date) {
      setFirstMonthDate(new Date(formData?.dsp_start_date));
    }
  }, [formData?.dsp_start_date]);

  // set second and third month dsp dates
  const getFutureMonths = (theDate, addMonths) => {
    return new Date(theDate.getFullYear(), theDate.getMonth() + addMonths, 1);
  };
  useEffect(() => {
    if (formData?.dsp_start_date) {
      const secondMonth = getFutureMonths(
        new Date(formData?.dsp_start_date),
        1,
      );
      const thirdMonth = getFutureMonths(new Date(formData?.dsp_start_date), 2);
      setSecondMonthDate(new Date(secondMonth));
      setThirdMonthDate(new Date(thirdMonth));
    }
  }, [
    formData?.dsp_start_date,
    formData?.changed_dsp_start_date,
    formData?.start_date,
    formData?.additional_monthly_services,
    firstMonthDate,
  ]);

  const findNotIncludedOneTimeServices = () => {
    // combine the sub categories from DB and non selected
    const savedSubOneTimeServices =
      details?.additional_one_time_services?.filter(
        (op) =>
          op?.service?.name?.includes('Infographics') ||
          op?.service?.name?.includes('Amazon Storefront') ||
          op?.service?.name?.includes('A+'),
      );
    let original = oneTimeService?.filter(
      (op) =>
        op?.label?.includes('Infographics') ||
        op?.label?.includes('Amazon Storefront') ||
        op?.label?.includes('A+'),
    );
    original = original?.filter((op) => op?.label !== 'A+ Content');

    const formattedSubService = [];
    for (const item of original) {
      formattedSubService.push({
        name: item.label,
        quantity: 0,
        service_id: item.value,
        contract_id: formData.id,
      });
    }
    const uniqueResult = formattedSubService?.filter((obj) => {
      return !savedSubOneTimeServices?.some((obj2) => {
        return (
          obj.name === obj2.service?.name &&
          obj.quantity !== 0 &&
          obj2.quantity !== 0
        );
      });
    });

    const mostUniqueResult = uniqueResult?.filter((obj) => {
      return !savedSubOneTimeServices?.some((obj2) => {
        return obj?.name === obj2?.service?.name;
      });
    });

    const subTabNameTemp1 = [];
    // eslint-disable-next-line no-unused-vars
    const subTabNameTemp2 = savedSubOneTimeServices?.map((obj) =>
      subTabNameTemp1.push(obj?.service?.name),
    );
    const subTabNameTemp3 = [];
    // eslint-disable-next-line no-unused-vars
    const subTabNameTemp4 = subTabNameTemp1?.map((obj) =>
      subTabNameTemp3.push(...obj.split(' ', 1)),
    );
    // eslint-disable-next-line no-unused-vars
    const addAPlusTemp = subTabNameTemp3?.map((item) => {
      if (item === 'Premium') subTabNameTemp3.push('A+');
      return null;
    });
    const subTabNameTemp5 = [...new Set(subTabNameTemp3)];

    let mostUniqueResult1 = mostUniqueResult?.filter((obj) => {
      return subTabNameTemp5?.some((obj2) => {
        return obj?.name.includes(obj2);
      });
    });

    mostUniqueResult1 = mostUniqueResult1?.filter(
      (op) => op?.name !== 'A+ Content',
    );

    setcombinedOneTimeServices(mostUniqueResult1);
    if (formData?.additional_one_time_services?.length) {
      const combined = [
        ...mostUniqueResult1,
        ...formData.additional_one_time_services,
      ];
      setFormData({
        ...formData,
        additional_one_time_services: combined,
      });
      if (formData?.contract_type?.includes('one')) {
        // setAdditionalOnetimeServices({
        //   ...additionalOnetimeServices,
        //   create: [
        //     ...formData.additional_one_time_services,
        //     ...mostUniqueResult1,
        //   ],
        // });
      } else {
        setAdditionalOnetimeServices({
          ...additionalOnetimeServices,
          create: [...additionalOnetimeServices.create, ...mostUniqueResult1],
        });
      }
    }
  };
  useEffect(() => {
    findNotIncludedOneTimeServices();
    if (formData?.contract_type?.includes('one')) {
      setTimeout(() => {
        findNotIncludedOneTimeServices();
      }, 500);
    }
  }, [
    details.additional_one_time_services,
    oneTimeService,
    formData.contract_type,
  ]);

  const displayNumber = (num) => {
    const res = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return res;
  };

  const executeScroll = (eleId) => {
    const element = document.getElementById(eleId);

    if (element) {
      element.scrollIntoView(true);
    }
  };

  if (
    isLoading.loader === true &&
    isLoading.type === 'page' &&
    detailsId &&
    loaderFlag === true
  ) {
    setIsLoading({ loader: false, type: 'page' });
    setLoaderFlag(false);
  }

  const fetchUncommonOptions = (options, alreadySelected, type) => {
    let result = [];
    let resultDataForMonthly = [];
    if (alreadySelected?.length && options && options.length) {
      if (type === 'monthly_service') {
        const SellerSelectedServices = alreadySelected.filter(
          (item) => item.account_type === 'Seller',
        );

        const VendorSelectedServices = alreadySelected.filter(
          (item) => item.account_type === 'Vendor',
        );

        let notFoundSeller = [];
        let notfoundVendor = [];

        if (options?.length && SellerSelectedServices?.length) {
          for (const option of options) {
            let isFound = true;

            for (const service of SellerSelectedServices) {
              if (
                service?.service?.name
                  ? service.service.name !== option.name
                  : service.name !== option.name
              ) {
                isFound = false;
              } else {
                isFound = true;
                break;
              }
            }

            if (isFound === false) {
              notFoundSeller.push(option);
            }
          }
        } else {
          notFoundSeller = options;
        }

        if (options?.length && VendorSelectedServices?.length) {
          for (const option of options) {
            let isFound = true;

            for (const service of VendorSelectedServices) {
              if (
                service?.service?.name
                  ? service.service.name !== option.name
                  : service.name !== option.name
              ) {
                isFound = false;
              } else {
                isFound = true;
                break;
              }
            }

            if (isFound === false) {
              notfoundVendor.push(option);
            }
          }
        } else {
          notfoundVendor = options;
        }

        if (details?.seller_type?.value === 'Seller') {
          resultDataForMonthly = notFoundSeller;
        }
        if (details?.seller_type?.value === 'Vendor') {
          resultDataForMonthly = notfoundVendor;
        }
        if (details?.seller_type?.value === 'Hybrid') {
          resultDataForMonthly = notFoundSeller.filter((o1) => {
            // filter out (!) items in result2
            return notfoundVendor.some((o2) => {
              return o1.name === o2.name;
              // assumes unique id
            });
          });
        }
      }

      if (type === 'one_time_service') {
        for (const option of options) {
          let isFound = true;
          for (const service of alreadySelected) {
            if (
              service?.service?.id
                ? service.service.id !== option.value
                : service.service_id !== option.value
            ) {
              isFound = false;
            } else {
              isFound = true;
              break;
            }
          }

          if (isFound === false) {
            if (type === 'monthly_service') {
              result.push(option);
            } else if (
              alreadySelected.find((item) =>
                item.name
                  ? item.name.includes('Amazon Storefront')
                  : item.service.name.includes('Amazon Storefront'),
              )
            ) {
              if (!option.label.includes('Amazon Storefront')) {
                result.push(option);
              }
            } else if (!option.label.includes('Amazon Storefront')) {
              result.push(option);
            } else if (
              result.find((item) => item.label.includes('Amazon Storefront'))
            ) {
              // dfgdfgh
            }
          }
        }
      }
    } else if (type === 'monthly_service') {
      result = options;
    } else {
      // Change Label for 3 amazon Storefront onetime services
      result = options?.filter(
        (item) => !item?.label?.includes('Amazon Storefront'),
      );
    }
    if (type === 'one_time_service') {
      if (setNotIncludedOneTimeServices) {
        setNotIncludedOneTimeServices(result);
      }
    }
    if (type === 'monthly_service') {
      if (setNotIncludedMonthlyServices) {
        setNotIncludedMonthlyServices(
          resultDataForMonthly?.length ? resultDataForMonthly : result,
        );
      }
    }
  };

  const splittedPath = location?.pathname?.split('/');

  const getContractActivityLogInfo = useCallback(
    (currentPage) => {
      if (userInfo?.role !== 'Customer') {
        setActivityLoader(true);
        getContractActivityLog(
          currentPage,
          contractAddendumDetails?.notActive
            ? contractAddendumDetails?.id
            : splittedPath && splittedPath[4],
          contractAddendumDetails?.notActive ? 'contract-addendum' : 'contract',
        ).then((response) => {
          setActivityData(response?.data?.results);
          setActivityCount(response?.data?.count);
          setPageNumber(currentPage);
          getDocumentList().then((picResponse) => {
            setImages(picResponse);
          });
          setActivityLoader(false);
          setIsApicalled(true);
        });
      }
    },
    [splittedPath, contractAddendumDetails],
  );

  useEffect(() => {
    if (contractAddendumDetails && Object.keys(contractAddendumDetails)?.length)
      getContractActivityLogInfo();
  }, [contractAddendumDetails]);

  if (!isLoading.loader && !activityLoader) {
    if (showSignSuccessMsg) {
      toast.success('Signature Requested Successfully!');
      setShowSignSuccessMsg(false);
    }
  }

  const checkContractStatus = () => {
    if (details?.contract_status) {
      if (
        contractStatusValue === 'pending account setup' ||
        contractStatusValue === 'active' ||
        contractStatusValue === 'renewed' ||
        contractStatusValue === 'cancel' ||
        contractStatusValue === 'pending for cancellation' ||
        contractStatusValue === 'pending cancellation approval' ||
        contractStatusValue === 'active pending for pause' ||
        contractStatusValue === 'pause' ||
        contractStatusValue === 'inactive' ||
        contractStatusValue === 'completed'
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  if (isDocRendered && formData?.id) {
    if (checkContractStatus()) {
      setIsDocRendered(false);
      setDownloadApiCall(true);
    }
    if (
      (contractTypeDsp || (contractTypeRecurring && showSection.dspAddendum)) &&
      (!formData.start_date ||
        (formData.start_date &&
          firstMonthDate &&
          secondMonthDate &&
          thirdMonthDate))
    ) {
      setIsDocRendered(false);
      setDownloadApiCall(true);
    }
    if (!contractTypeDsp && contractTypeRecurring && !showSection.dspAddendum) {
      setIsDocRendered(false);
      setDownloadApiCall(true);
    }
    if (contractTypeOne) {
      setIsDocRendered(false);
      setDownloadApiCall(true);
    }
  }

  const setMandatoryFieldsErrors = (contract) => {
    let agreementErrors = 0;
    let feeStructureErrorCount = 0;
    const statementErrors = 0;
    let dspErrors = 0;

    if (
      contract?.contract_type?.toLowerCase().includes('one') &&
      (contract.additional_one_time_services === null ||
        !contract.additional_one_time_services.length)
    ) {
      agreementErrors += 1;

      setAdditionalMonthlySerError({
        ...additionalMonthlySerError,
        required: 'At least 1 one time service required',
      });
    }

    AgreementDetails.forEach((item) => {
      if (item.key !== 'contract_address') {
        if (
          !(item.key === 'length' && contract?.contract_type === 'one time')
        ) {
          if (
            item.isMandatory &&
            item.field === 'customer' &&
            !(contract?.customer_id && contract.customer_id[item.key])
          ) {
            agreementErrors += 1;
            item.error = true;
          }
          if (
            item.isMandatory &&
            item.field !== 'customer' &&
            !(contract && contract[item.key])
          ) {
            agreementErrors += 1;
            item.error = true;
          }
        }
      } else {
        return (
          item &&
          item.sections.forEach((subItem) => {
            if (
              subItem &&
              subItem.isMandatory &&
              !(contract?.customer_id && contract.customer_id[subItem.key])
            ) {
              subItem.error = true;
              agreementErrors += 1;
            }
          })
        );
      }
      return null;
    });

    feeStructureContainerDetails.forEach((item) => {
      if (item.isMandatory && !(contract && contract[item.key])) {
        feeStructureErrorCount += 1;
        item.error = true;
      }
    });

    DSPAddendumDetails.forEach((item) => {
      if (item.isMandatory && !(contract && contract[item.key])) {
        if (contract?.contract_type?.toLowerCase().includes('dsp')) {
          dspErrors += 1;
          item.error = true;
        }

        if (
          contract?.contract_type?.toLowerCase().includes('recurring') &&
          item.key === 'dsp_length'
        ) {
          dspErrors += 1;
          item.error = true;
        } else if (!contract?.contract_type?.toLowerCase().includes('dsp')) {
          dspErrors += 1;
          item.error = true;
        }
      }
    });
    setSectionError({
      ...sectionError,
      agreement: agreementErrors,
      feeStructure: feeStructureErrorCount,
      statement: statementErrors,
      dsp: dspErrors,
    });
  };

  const getMonthlyServices = (type) => {
    getServiceTypes({ account_type: type }).then((res) => {
      const filteredMonthlyServices = {};

      if (res?.data?.Seller) {
        filteredMonthlyServices.Seller = res?.data?.Seller?.filter(
          (op) => !op.is_deleted,
        );
      }
      if (res?.data?.Vendor) {
        filteredMonthlyServices.Vendor = res?.data?.Vendor?.filter(
          (op) => !op.is_deleted,
        );
      }
      setMonthlyService(filteredMonthlyServices);
    });
  };
  const getAdvertisingServices = () => {
    fetchAdvertisingServices().then((res) => {
      setAdvertisingService(res?.data);
    });
  };
  const getDiscountData = (contractId) => {
    getDiscount(contractId).then((res) => {
      if (res && res?.status === 200) {
        if (res?.data?.results?.length) {
          const result = res?.data?.results.map(
            (item) => Object.values(item)[0],
          );
          setDiscountData(result);
        }
      }
    });
  };

  const checkFixedRetainer = (section, contractData) => {
    if (
      (contractData?.fee_structure?.[section]?.retainer_type === null ||
        contractData?.fee_structure?.[section]?.retainer_type === 'Fixed') &&
      contractData?.fee_structure?.[section]?.monthly_retainer
    ) {
      return true;
    }

    if (
      contractData?.fee_structure?.[section]?.retainer_type === 'quarterly' &&
      contractData?.fee_structure?.[section]?.quarterly_monthly_retainer &&
      Object.keys(
        contractData?.fee_structure?.[section]?.quarterly_monthly_retainer,
      )?.length
    ) {
      return true;
    }

    if (
      contractData?.fee_structure?.[section]?.retainer_type === 'monthly' &&
      contractData?.fee_structure?.[section]?.monthly_monthly_retainer &&
      Object.keys(
        contractData?.fee_structure?.[section]?.monthly_monthly_retainer,
      )?.length
    ) {
      return true;
    }

    return false;
  };

  const checkMandatoryFieldsOfFeeType = (contractData, section) => {
    if (
      contractData &&
      contractData?.fee_structure?.[section]?.fee_type === 'Retainer Only' &&
      !checkFixedRetainer(section, contractData)
    ) {
      return false;
    }

    if (
      contractData &&
      contractData?.fee_structure?.[section]?.fee_type ===
        'Revenue Share Only' &&
      !contractData?.fee_structure?.[section]?.rev_share
    ) {
      return false;
    }

    if (
      contractData &&
      contractData?.fee_structure?.[section]?.fee_type ===
        'Retainer + % Rev Share'
    ) {
      if (
        !contractData?.fee_structure?.[section]?.rev_share ||
        !checkFixedRetainer(section, contractData)
      ) {
        // errorCount = 2;
        return false;
      }
    }

    return true;
  };
  const manageErrorCount = (type, contractData) => {
    if (type === 'Seller') {
      if (!(contractData && contractData?.fee_structure?.seller?.fee_type)) {
        setSectionError((prevErrors) => ({
          ...prevErrors,
          seller: { feeType: 1 },
          vendor: { feeType: 0 },
        }));
      } else {
        checkMandatoryFieldsOfFeeType(contractData, 'seller');
      }
    }

    if (type === 'Vendor') {
      if (
        !(
          contractData &&
          contractData?.fee_structure?.vendor?.vendor_billing_report
        )
      ) {
        setSectionError((prevErrors) => ({
          ...prevErrors,
          vendor: { feeType: 1 },
          seller: { feeType: 0 },
        }));
      }

      if (!(contractData && contractData?.fee_structure?.vendor?.fee_type)) {
        setSectionError((prevErrors) => ({
          ...prevErrors,
          vendor: {
            feeType:
              (prevErrors?.vendor?.feeType ? prevErrors?.vendor?.feeType : 0) +
              1,
          },
          seller: { feeType: 0 },
        }));
      } else {
        checkMandatoryFieldsOfFeeType(contractData, 'vendor');
      }
    }
    if (type === 'Hybrid') {
      let sellerErrorcount = 0;
      let vendorErrorcount = 0;

      if (!(contractData && contractData?.fee_structure?.seller?.fee_type)) {
        sellerErrorcount += 1;
        setSectionError((prevErrors) => ({
          ...prevErrors,
          seller: { feeType: sellerErrorcount },
        }));
      } else {
        checkMandatoryFieldsOfFeeType(contractData, 'seller');
      }

      if (!(contractData && contractData?.fee_structure?.vendor?.fee_type)) {
        vendorErrorcount += 1;
        setSectionError((prevErrors) => ({
          ...prevErrors,
          vendor: { feeType: vendorErrorcount },
        }));
      } else {
        checkMandatoryFieldsOfFeeType(contractData, 'vendor');
      }

      if (
        !(
          contractData &&
          contractData?.fee_structure?.vendor?.vendor_billing_report
        )
      ) {
        setSectionError((prevErrors) => ({
          ...prevErrors,
          vendor: { feeType: prevErrors?.vendor?.feeType + 1 },
          seller: { feeType: prevErrors?.seller?.feeType },
        }));
      }
    }
  };

  const getServicesAccordingToAccType = (services, option) => {
    const result =
      services && services.filter((item) => item.account_type === option);
    return result;
  };

  const getData = (mrktplaceData, option) => {
    const result = getServicesAccordingToAccType(mrktplaceData, option);
    const multi = [];
    if (result && result.length) {
      for (const month of result) {
        const displayName = displayMarketplaceName(month.name);
        multi.push({
          label: displayName,
          value: month.name,
        });
      }
    }
    return { [option]: multi };
  };

  const setMarketplaceDropdownData = (
    sellerType = accountType,
    contract = formData,
  ) => {
    if (sellerType === 'Seller' || sellerType === 'Vendor') {
      const filterData = getData(contract?.additional_marketplaces, sellerType);
      if (filterData?.[sellerType]?.length) {
        setShowAdditionalMarketplace({
          [sellerType]: { showDropdown: true },
        });
      } else {
        setShowAdditionalMarketplace({
          [sellerType]: { showDropdown: false },
        });
      }
    }
    if (sellerType === 'Hybrid') {
      const sellerMarketplaceResult = getData(
        contract?.additional_marketplaces,
        'Seller',
      );

      const vendorMarketplaceResult = getData(
        contract?.additional_marketplaces,
        'Vendor',
      );

      if (
        sellerMarketplaceResult?.Seller?.length &&
        vendorMarketplaceResult?.Vendor?.length
      ) {
        setShowAdditionalMarketplace({
          Seller: { showDropdown: true },
          Vendor: { showDropdown: true },
        });
      } else if (sellerMarketplaceResult?.Seller?.length) {
        setShowAdditionalMarketplace({
          Seller: { showDropdown: true },
          Vendor: { showDropdown: false },
        });
      } else if (vendorMarketplaceResult?.Vendor?.length) {
        setShowAdditionalMarketplace({
          Vendor: { showDropdown: true },
          Seller: { showDropdown: false },
        });
      }
    }
  };
  const getFeeStructureDetails = (type, contract) => {
    if (!contract?.contract_type?.toLowerCase()?.includes('one')) {
      getFeeStructure(
        contract?.id,
        type === 'Hybrid' ? ['Seller', 'Vendor'] : type,
      ).then((res) => {
        if (res?.status === 500) {
          setFormData({
            ...contract,
            fee_structure: {
              seller: {},
              vendor: {},
            },
          });
          manageErrorCount(contract?.seller_type?.value, {
            ...contract,
            fee_structure: {
              seller: {},
              vendor: {},
            },
          });
        } else {
          setFormData({
            ...contract,
            fee_structure: {
              seller: res?.data?.seller || {},
              vendor: res?.data?.vendor || {},
            },
          });
          manageErrorCount(contract?.seller_type?.value, {
            ...contract,
            fee_structure: {
              seller: res?.data?.seller || {},
              vendor: res?.data?.vendor || {},
            },
          });
          if (res?.status === 200 && type === 'Hybrid')
            setDisableCombineRevShare(false);
          setMarketplaceDropdownData(type, contract);
        }
      });
    }
  };

  const onEditcontract = () => {
    setShowEditContractConfirmationModal(true);
  };

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      setIsLoading({ loader: true, type: 'page' });
      setTimeout(() => setIsLoading({ loader: false, type: 'page' }), 500);
    } else {
      isMounted.current = true;
    }
  }, [isTablet, isMobile]);

  useEffect(() => {
    // whenever the account type is changed, run this code and show only the account management type from the choices API

    let tempService = [];
    const service = originalStandardServices;

    if (formData?.seller_type?.label === 'Seller') {
      tempService = service.filter(
        (ele) =>
          ele.value !== 'Vendor Account Management' &&
          ele.value !== 'Hybrid Account Management',
      );
    } else if (formData?.seller_type?.label === 'Vendor') {
      tempService = service.filter(
        (ele) =>
          ele.value !== 'Seller Account Management' &&
          ele.value !== 'Hybrid Account Management',
      );
    } else {
      tempService = service.filter(
        (ele) =>
          ele.value !== 'Seller Account Management' &&
          ele.value !== 'Vendor Account Management',
      );
    }
    setStdServices(tempService);
  }, [formData?.seller_type]);

  const showTabInResponsive = (section) => {
    if (section === 'edit-fields') {
      setShowtabInResponsive('edit-fields');
    }
    if (section === 'view-contract') {
      setShowtabInResponsive('view-contract');
    }
  };

  useEffect(() => {
    if (contractAddendumDetails.notActive) {
      executeScroll('draft-addendum');
      setShowCollpase({ ...showSection, addendum: true });
      setOpenCollapse({ ...openCollapse, addendum: true });
    }
  }, []);

  const onEditAddendum = () => {
    setShowEditor(true);
    if (isTablet || isMobile) {
      showTabInResponsive('view-contract');
      setTimeout(() => executeScroll('addendum'), 500);
    }
  };

  const clearError = () => {
    setApiError({});
    setAdditionalMarketplaceError({});
    setAdditionalMonthlySerError({});
    setAdditionalOnetimeSerError({});
    setContractError({});
    setCustomerErrors({});
    setFeeStructureErrors({});
    setNonUSserviceError({});
  };

  const handleNewDate = (monthDateOrder) => {
    const newDate = new Date(
      new Date(
        new Date(monthDateOrder).getFullYear(),
        new Date(monthDateOrder).getMonth() + 1,
        0,
      ),
    );
    return newDate;
  };

  const returnDspMonthAndDays = () => {
    return `${
      formData?.changed_dsp_start_date
        ? `<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>3 months and additional partial period</span>`
        : `<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>3 months</span>`
    }`;
  };

  const calculateTotalDays = (flag = '') => {
    let firstMonthdays = 0;
    if (new Date(firstMonthDate).getDate() !== 1) {
      const totalDays = handleNewDate(firstMonthDate).getDate();
      const currentDate = new Date(firstMonthDate).getDate();
      firstMonthdays = totalDays - currentDate;
    } else {
      firstMonthdays = handleNewDate(firstMonthDate).getDate();
    }

    let extraDays = 0;
    if (new Date(firstMonthDate).getDate() !== 1) {
      extraDays = handleNewDate(endMonthDate).getDate();
    }
    const secondMonthdays = handleNewDate(secondMonthDate).getDate();
    const thirdMonthdays = handleNewDate(thirdMonthDate).getDate();
    const totaldays =
      firstMonthdays + extraDays + secondMonthdays + thirdMonthdays;

    if (flag === 'initial') {
      if (totaldays < 105) {
        return '3';
      }
      return '3.5';
    }
    if (totaldays < 105) {
      return `90 days (3 months)`;
    }
    return `105 days (3.5 months)`;
  };

  const mapDefaultValues = (key, label, type) => {
    if (
      type === 'amazon' &&
      key === 'seller_type' &&
      formData?.seller_type?.label === 'Hybrid'
    )
      return 'Seller';
    if (key === 'company_name') {
      return details && details.customer_id && details.customer_id[key]
        ? details && details.customer_id && details.customer_id[key]
        : `Client Name`;
    }

    if (key === 'length' && label === 'DSP service length') {
      if (contractTypeDsp) {
        return calculateTotalDays('initial');
      }
      if (
        formData[key] === undefined ||
        formData[key] === '' ||
        formData[key] === null
      ) {
        return `Enter ${label}`;
      }
      return formData?.length?.label && parseInt(formData.length.label, 10);
    }
    if (key === 'length') {
      return formData?.length?.label
        ? formData.length.label
        : formData.length
        ? formData.length
        : 'Select Length';
    }
    if (key === 'primary_marketplace') {
      if (details?.primary_marketplace) {
        return primaryMarketplaceName
          ? displayMarketplaceName(
              details.primary_marketplace.name,
              'primary',
              'lhs',
            )
          : displayMarketplaceName(
              details.primary_marketplace,
              'primary',
              'lhs',
            );
      }
      return `Enter ${label}.`;
    }

    if (key === 'start_date') {
      return startDate !== null
        ? `<span style="padding: 4px 9px; font-weight: bold";>${dayjs(
            startDate,
          ).format('MM / DD / YYYY')}</span>`
        : 'Select Date';
    }
    if (key === 'addendum_start_date') {
      return formData?.addendum_start_date !== null
        ? `<span style="padding: 4px 9px; font-weight: bold";>${dayjs(
            formData?.addendum_start_date,
          ).format('MM / DD / YYYY')}</span>`
        : 'Select Date';
    }
    if (key === 'current_date') {
      return dayjs(Date()).format('MM / DD / YYYY');
    }
    if (key === 'calculated_no_of_days') {
      return calculateTotalDays();
    }

    if (key === 'address') {
      if (
        (customerAddress === '' || customerAddress === null) &&
        (customerState === '' || customerState === null) &&
        (customerCity === '' || customerCity === null) &&
        (customerZipCode === '' || customerZipCode === null)
      ) {
        return `Enter Location`;
      }
      return `${customerAddress || ''}${
        customerAddress && (customerState || customerCity || customerZipCode)
          ? ','
          : ''
      }
       ${customerCity || ''}${
        customerCity && (customerState || customerZipCode) ? ',' : ''
      }
      ${customerState || ''}${customerState && customerZipCode ? ',' : ''}     
      ${customerZipCode || ''}
      `;
    }

    if (details[key] === undefined || details[key] === '') {
      if (
        label === 'Dsp Fee' ||
        label === 'Dsp Fee Second' ||
        label === 'Dsp Fee Third'
      ) {
        return `Enter DSP Budget`;
      }
      return `Enter ${label}`;
    }

    if (key === 'threshold_type') {
      if (details && details[key] === 'YoY') {
        return 'YoY: All channel sales above previous year channel sales.';
      }
      if (details && details[key] === 'YoY + %') {
        return `YoY + %: All channel sales above a <span style=" background:#ffe5df;padding: 4px 9px;font-weight: bold">${
          details && details.yoy_percentage
            ? details && details.yoy_percentage
            : `Enter ${label}.`
        }</span> growth on previous year channel sales.`;
      }
      if (details && details[key] === 'Fixed') {
        return `Fixed: <span style=" background:#ffe5df;padding: 4px 9px;font-weight: bold">${
          details && details.sales_threshold
            ? `${selectedCurrency}${displayNumber(details?.sales_threshold)}`
            : `Enter ${label}.`
        }</span>`;
      }
    }

    if (type && type.includes('number')) {
      if (details && details[key]) {
        return `${
          type === 'number-currency' ? selectedCurrency : '%'
        }${displayNumber(details[key])}`;
      }
      return `Enter ${label}`;
    }

    const result =
      key === 'rev_share' || key === 'seller_type'
        ? details && details[key] && details[key].label
          ? details && details[key] && details[key].label
          : (details && details[key] === null) ||
            (details && details[key] === '') ||
            (details && details[key] === undefined)
          ? `Enter ${label}`
          : details && details[key]
        : details && details[key];

    return result;
  };

  useEffect(() => {
    if (
      history?.location?.search?.includes('verify-document') ||
      history?.location?.search?.includes('select-contact')
    ) {
      setHelloSignEnterDateTextFlag(true);
    } else {
      setHelloSignEnterDateTextFlag(false);
    }
  }, [history.location.search, formData.start_date, originalData.id]);

  useEffect(() => {
    if (
      (history?.location?.search?.includes('verify-document') ||
        history?.location?.search?.includes('select-contact')) &&
      originalData?.id
    ) {
      history.push(
        PATH_AGREEMENT.replace(':id', id).replace(
          ':contract_id',
          originalData?.id,
        ),
      );
    }
  }, [originalData.id]);

  const ShowRetainerThresholdValues = (
    type,
    key,
    quartelyKey,
    monthlyKey,
    option,
    label,
  ) => {
    return `${
      (formData?.fee_structure?.[type]?.[option] === null ||
        formData?.fee_structure?.[type]?.[option] === 'None') &&
      option === 'threshold_type'
        ? ''
        : formData?.fee_structure?.[type]?.[option] === 'Fixed' ||
          formData?.fee_structure?.[type]?.[option] === null
        ? ` <tr  style="vertical-align: text-top;">
              <td style="border: 1px solid black;padding: 13px;"> <span style=" font-weight: bold";> Fixed ${label} </span></td>
               <td style="border: 1px solid black;padding: 13px;"><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${displayNumber(
            formData?.fee_structure?.[type]?.[key] || 0,
          )} </span> </td>
              <td style="border: 1px solid black;padding: 13px;">${
                label === 'Threshold'
                  ? displayFixedThresholdInfo
                  : displayFixedRetainerInfo
              } </td></tr>`
        : formData?.fee_structure?.[type]?.[option] === 'quarterly'
        ? ` <tr  style="vertical-align: text-top;">
             <td style="border: 1px solid black;padding: 13px;"> <span style=" font-weight: bold";>Quarterly ${label} </span></td>
             <td style="border: 1px solid black;padding: 13px;">
                 1st Quarter:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['1st quarterly'],
            ) || 0
          } </span><br>
                  2nd Quarter:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['2nd quarterly'],
            ) || 0
          } </span><br>
                  3rd Quarter:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['3rd quarterly'],
            ) || 0
          }</span><br>
                  4th Quarter:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['4th quarterly'],
            ) || 0
          }</span><br>
               </td>
            <td style="border: 1px solid black;padding: 13px;">>${
              label === 'Threshold'
                ? displayFixedThresholdInfo
                : displayQuarterlyRetainerInfo
            }
             </td>
          </tr>`
        : formData?.fee_structure?.[type]?.[option] === 'monthly'
        ? ` <tr  style="vertical-align: text-top;">
             <td style="border: 1px solid black;padding: 13px; line-height: 1.5;"> <span style=" font-weight: bold";>Monthly ${label} </span></td>
             <td style="border: 1px solid black;padding: 13px;">
                 January:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['january month'],
            ) || 0
          } </span><br>
                  February:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['february month'],
            ) || 0
          } </span><br>
                  March:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['march month'],
            ) || 0
          }</span><br>
                  April:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['april month'],
            ) || 0
          }</span><br>
                   May:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['may month'],
            ) || 0
          } </span><br>
                  June:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['june month'],
            ) || 0
          } </span><br>
                  July:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['july month'],
            ) || 0
          }</span><br>
                  August:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['august month'],
            ) || 0
          }</span><br>
                   September:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.[
                'september month'
              ],
            ) || 0
          } </span><br>
                  October:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['october month'],
            ) || 0
          } </span><br>
                  November:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['november month'],
            ) || 0
          }</span><br>
                  December:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['december month'],
            ) || 0
          }</span><br>
               </td>
            <td style="border: 1px solid black;padding: 13px;">${
              label === 'Threshold'
                ? displayFixedThresholdInfo
                : displayMonthlyRetainerInfo
            }
             </td>
          </tr>`
        : ''
    }`;
  };

  const feeStructure = (type) => {
    if (formData?.fee_structure?.[type]?.fee_type === 'Retainer Only') {
      return `
      <div class="table-responsive">
          <table class="contact-list mb-3" style="width: 100%;overflow: auto;border-collapse: collapse;">
            <tr>
              <td style="border: 1px solid black;padding: 13px;"> <span style=" font-weight: bold";>Fee Type</span></td>
               <td style="border: 1px solid black;padding: 13px;"><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold;white-space: nowrap;"> Retainer Only  (${
                 formData?.fee_structure?.[type]?.retainer_type === undefined ||
                 formData?.fee_structure?.[type]?.retainer_type === null
                   ? 'Fixed'
                   : formData?.fee_structure?.[type]?.retainer_type
               } Retainer) </span> </td>
              <td style="border: 1px solid black;padding: 13px;">${displayFeeTypeInfo}</td>
            </tr>
              ${ShowRetainerThresholdValues(
                type,
                'monthly_retainer',
                'quarterly_monthly_retainer',
                'monthly_monthly_retainer',
                'retainer_type',
                'Retainer',
              )}
          </table>
          </div>
        `;
    }
    if (formData?.fee_structure?.[type]?.fee_type === 'Revenue Share Only') {
      return `<div class="table-responsive">
          <table class="contact-list mb-3 " style="width: 100%;overflow: auto;border-collapse: collapse;">
            <tr>
              <td style="border: 1px solid black;padding: 13px;"> <span style=" font-weight: bold";>Fee Type</span></td>
               <td style="border: 1px solid black;padding: 13px;"><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold;white-space: nowrap;"> Revenue Share Only  </span> </td>
              <td style="border: 1px solid black;padding: 13px;">${displayFeeTypeInfo}.</td>
            </tr>
             <tr  style="vertical-align: text-top;">
              <td style="border: 1px solid black;padding: 13px;"> <span style=" font-weight: bold";> Revenue Share %</span></td>
               <td style="border: 1px solid black;padding: 13px;"><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${displayNumber(
                 formData?.fee_structure?.[type]?.rev_share || 0,
               )}% </span> </td>
              <td style="border: 1px solid black;padding: 13px;">${displayRevenueShareInfo}</td></tr>
              ${ShowRetainerThresholdValues(
                type,
                'sales_threshold',
                'quarterly_rev_share',
                'monthly_rev_share',
                'threshold_type',
                'Threshold',
              )}
               ${
                 formData?.fee_structure?.[type]?.billing_minimum
                   ? `<tr  style="vertical-align: text-top;">
              <td style="border: 1px solid black;padding: 13px;"> <span style=" font-weight: bold";> Billing Minimum</span></td>
               <td style="border: 1px solid black;padding: 13px;"><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${displayNumber(
                       formData?.fee_structure?.[type]?.billing_minimum || 0,
                     )} </span> </td>
              <td style="border: 1px solid black;padding: 13px;">${displayBillingMinimumInfo}</td></tr>`
                   : ''
               }
              ${
                formData?.fee_structure?.[type]?.billing_cap
                  ? `<tr  style="vertical-align: text-top;">
              <td style="border: 1px solid black;padding: 13px;"> <span style=" font-weight: bold";> Billing Cap</span></td>
               <td style="border: 1px solid black;padding: 13px;"><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${displayNumber(
                      formData?.fee_structure?.[type]?.billing_cap || 0,
                    )} </span> </td>
              <td style="border: 1px solid black;padding: 13px;">${displayBillingCapInfo}</td></tr>`
                  : ''
              }
          </table>
        </div>`;
    }
    if (
      formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share'
    ) {
      return `<div class="table-responsive">
          <table class="contact-list mb-3" style="width: 100%;overflow: auto;border-collapse: collapse;">
            <tr>
              <td style="border: 1px solid black;padding: 13px;"> <span style=" font-weight: bold";>Fee Type</span></td>
               <td style="border: 1px solid black;padding: 13px;"><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold;white-space: nowrap;"> Retainer (${
                 formData?.fee_structure?.[type]?.retainer_type === undefined ||
                 formData?.fee_structure?.[type]?.retainer_type === null
                   ? 'Fixed'
                   : formData?.fee_structure?.[type]?.retainer_type
               } Retainer) + Rev Share 
               (${
                 formData?.fee_structure?.[type]?.threshold_type ===
                   undefined ||
                 formData?.fee_structure?.[type]?.threshold_type === null ||
                 formData?.fee_structure?.[type]?.threshold_type === 'None'
                   ? 'No'
                   : formData?.fee_structure?.[type]?.threshold_type
               } Threshold)   </span> </td>
              <td style="border: 1px solid black;padding: 13px;">${displayFeeTypeInfo}</td>
            </tr>
            ${ShowRetainerThresholdValues(
              type,
              'monthly_retainer',
              'quarterly_monthly_retainer',
              'monthly_monthly_retainer',
              'retainer_type',
              'Retainer',
            )} 
             
             <tr  style="vertical-align: text-top;">
              <td style="border: 1px solid black;padding: 13px;"> <span style=" font-weight: bold";> Revenue Share %</span></td>
               <td style="border: 1px solid black;padding: 13px;"><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${displayNumber(
                 formData?.fee_structure?.[type]?.rev_share || 0,
               )}% </span> </td>
              <td style="border: 1px solid black;padding: 13px;">${displayRevenueShareInfo}</td></tr>
            ${ShowRetainerThresholdValues(
              type,
              'sales_threshold',
              'quarterly_rev_share',
              'monthly_rev_share',
              'threshold_type',
              'Threshold',
            )}
              ${
                formData?.fee_structure?.[type]?.billing_cap
                  ? `<tr  style="vertical-align: text-top;">
              <td style="border: 1px solid black;padding: 13px;"> <span style=" font-weight: bold";> Billing Cap</span></td>
               <td style="border: 1px solid black;padding: 13px;"><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${displayNumber(
                      formData?.fee_structure?.[type]?.billing_cap || 0,
                    )} </span> </td>
              <td style="border: 1px solid black;padding: 13px;">${displayBillingCapInfo}</td></tr>`
                  : ''
              }
          </table>
        </div>`;
    }
    return '';
  };

  const showRevTable = () => {
    if (formData?.seller_type?.label === 'Seller')
      return feeStructure('seller');
    if (formData?.seller_type?.label === 'Vendor')
      return feeStructure('vendor');
    if (formData?.seller_type?.label === 'Hybrid') {
      return `${feeStructure('seller')} 
         <div class=" text-center BT-SOW-sales-commission mt-5" style="text-align: center; margin-top: 3rem!important;">
      <span style="font-weight: 800;
        font-family: Helvetica-bold;" >Fees & Sales Commissions for Amazon Vendor Account
       </span>
        <br> Sales billed in arrears for the prior month in which sales were made on the account and calculated as follows:
    </div></br>${feeStructure('vendor')} `;
    }
    return '';
  };

  const mapThadSignImg = () => {
    return THAD_SIGN_IMG;
  };

  const customAmazonStorePrice = (fee) => {
    return `<td style="border: 1px solid black;padding: 13px;">
      <span style=" background:#ffe5df; padding: 4px 9px;"> 
        ${
          fee
            ? `${selectedCurrency}${displayNumber(fee)}`
            : `${selectedCurrency}0`
        }
        </span>
      </td>`;
  };
  const tdService = (service, fee) => {
    return `<td style="border: 1px solid black; padding: 13px;">
      <span style=" background:#ffe5df; padding: 4px 9px;"> 
          ${
            service.quantity && fee
              ? `${selectedCurrency}${displayNumber(service.quantity * fee)}`
              : `${selectedCurrency}0`
          }
          </span>
        </td>
        `;
  };

  const displayNumberOfASINs = (item) => {
    if (item?.name === 'A+ Content') {
      return `(<span style=" background:#ffe5df; padding: 4px 9px;">${item?.quantity}</span> ASINs)`;
    }
    if (item?.service?.name === 'A+ Content') {
      return `(<span style=" background:#ffe5df; padding: 4px 9px;">${item?.quantity} </span> ASINs)`;
    }
    return '';
  };
  const checkIsAPlusService = (item) => {
    if (item?.name && item?.name === 'A+ Content') {
      return true;
    }

    if (item?.service?.name && item?.service?.name === 'A+ Content') {
      return true;
    }
    return false;
  };

  // return the onetime service table row
  const showOneTimeServiceSubTabRow = (fields, serviceName) => {
    const oneTimeSpecificServicesTemp = oneTimeSpecificServices(
      formData,
      serviceName,
    );
    const oneTimeSpecificServicesFeesTemp = oneTimeSpecificServicesFees(
      servicesFees,
      serviceName,
    );

    if (oneTimeSpecificServicesTemp?.length)
      fields.push(`<tr>
      <td style="border: 1px solid black;padding: 13px; ">
             <span style=" background:#ffe5df; padding: 4px 9px;">

        ${oneTimeSpecificServicesTemp?.length ? '1' : ''}
        </span>
      </td>
      <td style="border: 1px solid black;padding: 13px; ">
        ${
          serviceName === 'A+' ? 'A+ Content' : serviceName
        } ${returnOneTimeServiceNames(oneTimeSpecificServicesTemp)}
      </td>
      <td style="border: 1px solid black;padding: 13px; ">
             <span style="background:#ffe5df; padding: 4px 9px; line-height: 1.7;">
          ${returnOneTimeServiceFees(
            servicesFees,
            selectedCurrency,
            oneTimeSpecificServicesTemp,
            oneTimeSpecificServicesFeesTemp,
          )}

        </span>
      </td>
      <td style="border: 1px solid black;padding: 13px; ">
      <span style="background:#ffe5df; padding: 4px 9px;">
      ${returnOneTimeServiceFeesTotal(
        servicesFees,
        selectedCurrency,
        oneTimeSpecificServicesTemp,
        oneTimeSpecificServicesFeesTemp,
      )}

      </td>
      <tr>`);
  };

  const mapMonthlyServices = (key, monthlyServicesData, tableType) => {
    const fields = [];
    if (key === 'amc_services') {
      if (monthlyServicesData) {
        if (
          (formData?.is_dsp_advertising &&
            !contractAddendumDetails?.notActive &&
            !formData?.contract_type?.includes('dsp')) ||
          (contractAddendumDetails?.notActive &&
            formData?.is_dsp_advertising &&
            formData?.is_dsp_advertising !== originalData?.is_dsp_advertising)
        ) {
          fields.push(
            `<tr>
                <td style="border: 1px solid black;padding: 13px;">DSP Advertising</td>
                ${
                  tableType === 'removed'
                    ? ''
                    : contractAddendumDetails?.notActive &&
                      `<td style="border: 1px solid black;padding: 13px;"> N/A</td>`
                }
                <td style="border: 1px solid black;padding: 13px;"> <span style=" background:#ffe5df; padding: 4px 9px;">N/A </span> </td>
                </tr>`,
          );
        }
        for (const item of monthlyServicesData) {
          fields.push(
            `<tr>
                <td style="border: 1px solid black;padding: 13px;">AMC Management ${
                  item.name
                }</td>

                ${
                  tableType === 'removed'
                    ? ''
                    : contractAddendumDetails?.notActive
                    ? `<td style="border: 1px solid black;padding: 13px;">${
                        item?.name === 'DSP Advertising' ||
                        item?.service?.name === 'DSP Advertising'
                          ? 'N/A'
                          : item?.id && item?.effective_date
                          ? item?.effective_date
                          : item?.id && !item?.effective_date
                          ? formData?.contract_signed_date
                          : defaultEffectivityDateText
                      } </td>`
                    : ''
                }

                <td style="border: 1px solid black;padding: 13px;">  ${
                  item?.fee
                    ? `<span style=" background:#ffe5df; padding: 4px 9px;">${selectedCurrency}${displayNumber(
                        item?.fee,
                      )} /month </span>`
                    : item?.name === 'Standard Dashboard' ||
                      (item?.fee === 0 && item?.name === 'Advanced Dashboard')
                    ? ` <span style=" background:#ffe5df; padding: 4px 9px;"> FOC </span>`
                    : item?.fee === 0
                    ? `<span style=" background:#ffe5df; padding: 4px 9px;">$0 /month </span>`
                    : ''
                }</td>
                </tr>`,
          );
        }
      }
    } else if (key !== 'additional_one_time_services') {
      if (monthlyServicesData) {
        for (const item of monthlyServicesData) {
          const fixedFee = servicesFees.filter((n) => n.id === item.service_id);
          if (
            (item.service && item.service.name !== undefined) ||
            item.name !== undefined
          ) {
            if (
              item.name
                ? item.name !== 'Inventory Reconciliation'
                : item.service.name !== 'Inventory Reconciliation'
            ) {
              fields.push(
                `<tr>
                <td style="border: 1px solid black;padding: 13px;">${
                  item.service ? item.service.name : item?.name
                } ${displayNumberOfASINs(item, fixedFee)}</td>
                ${
                  tableType === 'removed'
                    ? ''
                    : contractAddendumDetails?.notActive &&
                      `<td style="border: 1px solid black;padding: 13px;">${
                        item?.name === 'DSP Advertising' ||
                        item?.service?.name === 'DSP Advertising'
                          ? 'N/A'
                          : item?.id && item?.effective_date
                          ? item?.effective_date
                          : item?.id && !item?.effective_date
                          ? formData?.contract_signed_date
                          : defaultEffectivityDateText
                      } </td>`
                }
                ${
                  item.service?.fee
                    ? `<td style="border: 1px solid black;padding: 13px;">
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 
                    
                    ${selectedCurrency}${
                        item.service
                          ? `${displayNumber(
                              item?.service?.name === 'A+ Content'
                                ? item.service.fee * item?.quantity
                                : item.service.fee,
                            )}`
                          : item.fee
                          ? displayNumber(
                              item?.name === 'A+ Content'
                                ? item.fee * item?.quantity
                                : item.fee,
                            )
                          : ''
                      } /month
                    </span>
                  </td>`
                    : (
                        item.name
                          ? item.name === 'DSP Advertising'
                          : item.service.name === 'DSP Advertising'
                      )
                    ? `<td style="border: 1px solid black;padding: 13px;">
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 
                    
                    N/A</span></td>`
                    : `<td>
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 

                    ${
                      fixedFee && fixedFee[0] && fixedFee[0].fee
                        ? `${selectedCurrency}${displayNumber(
                            checkIsAPlusService(item)
                              ? fixedFee[0].fee * item?.quantity
                              : fixedFee[0].fee,
                          )} /month`
                        : `${selectedCurrency}0`
                    }
                    </span>
                    </td>`
                }
                </tr>`,
              );
            }
          }
        }
      }
    } else if (formData && formData.additional_one_time_services) {
      formData.additional_one_time_services
        .filter((item) =>
          item?.name
            ? !item?.name?.includes('Amazon Storefront') &&
              !item?.name?.includes('Infographics') &&
              !item?.name?.includes('A+')
            : !item?.service?.name?.includes('Amazon Storefront') &&
              !item?.service?.name?.includes('Infographics') &&
              !item?.service?.name?.includes('A+'),
        )
        .forEach((service) => {
          const fixedFee = servicesFees.filter(
            (n) => n.id === service.service_id,
          );
          return fields.push(
            `<tr>
            <td style="border: 1px solid black;padding: 13px;">
              <span style=" background:#ffe5df; padding: 4px 9px;"> 
              ${service.quantity ? displayNumber(service.quantity) : 0}
              </span>
            </td>                 
            <td style="border: 1px solid black;padding: 13px;">
              ${
                service.name
                  ? service.name
                  : service.service?.name
                  ? service.service.name
                  : ''
              }
            </td>            
            ${
              (
                service && service.name
                  ? service.name.includes('Amazon Store Package Custom')
                  : service?.service?.name.includes(
                      'Amazon Store Package Custom',
                    )
              )
                ? service.custom_amazon_store_price
                  ? `<td style="border: 1px solid black;padding: 13px;">
                    <span style=" background:#ffe5df; padding: 4px 9px;"> 
                  ${selectedCurrency}${displayNumber(
                      service.custom_amazon_store_price,
                    )}</span></td>`
                  : customAmazonStorePrice(
                      fixedFee && fixedFee[0] && fixedFee[0].fee,
                    )
                : service?.service?.fee
                ? customAmazonStorePrice(service?.service?.fee)
                : customAmazonStorePrice(
                    fixedFee && fixedFee[0] && fixedFee[0].fee,
                  )
            }
            ${
              (
                service && service.name
                  ? service.name !== 'Amazon Store Package Custom'
                  : service &&
                    service.service?.name !== 'Amazon Store Package Custom'
              )
                ? service.quantity && service.service?.fee
                  ? tdService(service, service.service?.fee)
                  : tdService(
                      service,
                      fixedFee && fixedFee[0] && fixedFee[0].fee,
                    )
                : service.quantity && service.custom_amazon_store_price
                ? tdService(service, service.custom_amazon_store_price)
                : tdService(service, fixedFee && fixedFee[0] && fixedFee[0].fee)
            }                
          </tr>`,
          );
        });
      showOneTimeServiceSubTabRow(fields, 'Amazon Storefront');
      showOneTimeServiceSubTabRow(fields, 'Infographics');
      showOneTimeServiceSubTabRow(fields, 'A+');
    }
    return fields.length ? fields.toString().replaceAll('>,<', '><') : '';
  };

  const mapAdditionalMarketPlaces = (marketplaceData, tableType) => {
    const fields = [];
    if (marketplaceData) {
      for (const item of marketplaceData) {
        fields.push(
          `<tr> 
            <td style="border: 1px solid black;padding: 13px;">${displayMarketplaceName(
              item?.name || item?.service?.name,
              '',
              'lhs',
            )}</td>
             ${
               tableType === 'removed'
                 ? ''
                 : contractAddendumDetails?.notActive &&
                   `<td style="border: 1px solid black;padding: 13px;">${
                     item?.id && item?.effective_date
                       ? item?.effective_date
                       : item?.id && !item?.effective_date
                       ? formData?.contract_signed_date
                       : defaultEffectivityDateText
                   }</td> `
             }
            ${
              item?.fee
                ? `<td style="border: 1px solid black;padding: 13px;">
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 

                ${selectedCurrency}${
                    item.service
                      ? displayNumber(item.service.fee)
                      : item.fee
                      ? displayNumber(item.fee)
                      : ''
                  }
                /month
                </span>
              </td>`
                : `<td style="border: 1px solid black;padding: 13px;">
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 
                
                ${selectedCurrency}${displayNumber(
                    additionaMarketplaceAmount,
                  )} /month
                </span>
                </td>`
            }
          </tr>`,
        );
      }
    }
    return fields.length ? fields.toString().replaceAll('>,<', '><') : '';
  };
  const mapVariableMonthlyService = (monthlyServicesData) => {
    const fields = [
      `<tr ><td colspan="3" style ="text-align: center; border: 1px solid black;padding: 13px; font-weight: 800">
                  Variable Monthly Services</td>
                  </tr>`,
    ];
    if (monthlyServicesData) {
      for (const item of monthlyServicesData) {
        if (
          item?.service?.name === 'Inventory Reconciliation' ||
          item?.name === 'Inventory Reconciliation'
        ) {
          fields.push(
            `<tr>
                 <td style="border: 1px solid black;padding: 13px;">${
                   item.service ? item.service.name : item?.name
                 }</td>
                 <td style="border: 1px solid black;padding: 13px;"></td>
                    <td style="border: 1px solid black;padding: 13px;"> 
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 

                  25% of recovered ${selectedCurrency}<span>&#39;</span>s
                  </span>
                  </td>
                    </tr>`,
          );
        }
      }
      return fields.length > 1 ? fields.toString().replaceAll(',', '') : '';
    }
    return '';
  };

  const calculateTotalFee = (type, serviceData, marketplaceData, accntType) => {
    let oneTimeSubTotal = 0;
    let monthlySubTotal = 0;
    let oneTimeDiscount = 0;
    let monthlyDiscount = 0;
    let additionalMarketplacesTotal = 0;
    if (formData) {
      if (type === 'monthly') {
        // caculate the total of additional monthly serviece
        if (serviceData) {
          if (!accntType) {
            serviceData.forEach((item) => {
              if (item?.fee) monthlySubTotal += Number(item?.fee);
            });
          } else {
            serviceData.forEach((item) => {
              if (item && item.service) {
                if (item?.service?.name === 'A+ Content') {
                  monthlySubTotal += item.service.fee * item?.quantity;
                } else {
                  monthlySubTotal += item.service.fee;
                }
              } else {
                const fixedFee = servicesFees.filter(
                  (n) => n.id === item.service_id,
                );

                if (item?.name === 'A+ Content') {
                  monthlySubTotal += fixedFee[0].fee * item?.quantity;
                } else {
                  monthlySubTotal += fixedFee[0].fee;
                }
              }
            });
          }
        }
        if (accntType) {
          if (marketplaceData && marketplaceData !== null) {
            // calculate the total of additional marketplaces
            marketplaceData.forEach((item) => {
              if (item && item.fee) {
                additionalMarketplacesTotal += item.fee;
              } else {
                additionalMarketplacesTotal += additionaMarketplaceAmount;
              }
            });

            monthlySubTotal += additionalMarketplacesTotal;
          }
        }
        let monthlyDiscountData = 0;
        if (accountType === '') {
          monthlyDiscountData = discountData.find(
            (item) => item.is_amc_service_discount,
          );
        } else {
          monthlyDiscountData = discountData.find(
            (item) =>
              item.service_type === 'monthly service' &&
              item?.account_type?.toLowerCase() === accntType?.toLowerCase(),
          );
        }

        if (monthlyDiscountData !== null) {
          const discountType = monthlyDiscountData?.type;
          if (discountType === 'percentage') {
            monthlyDiscount =
              (monthlySubTotal * monthlyDiscountData?.amount) / 100;
          } else if (discountType === 'fixed amount') {
            monthlyDiscount = monthlyDiscountData?.amount;
          }
        } else {
          monthlyDiscount = monthlyDiscountData?.amount;
        }
        return {
          monthlySubTotal,
          monthlyAmountAfterDiscount: monthlyDiscount,
          monthlyTotal: monthlySubTotal - monthlyDiscount,
          monthlyDiscountType: monthlyDiscountData?.type,
          monthlyDiscount: monthlyDiscountData?.amount,
        };
      }
      if (type === 'onetime' && formData.additional_one_time_services) {
        formData.additional_one_time_services.forEach((item) => {
          const { quantity } = item;

          if (item.custom_amazon_store_price) {
            oneTimeSubTotal += item.custom_amazon_store_price * quantity;
          } else if (item?.service) {
            oneTimeSubTotal += item.service.fee * quantity;
          } else {
            let fixedFee = servicesFees.filter((n) => n.id === item.service_id);
            fixedFee =
              fixedFee && fixedFee[0] && fixedFee[0].fee ? fixedFee[0].fee : 0;
            oneTimeSubTotal += fixedFee * quantity;
          }
        });

        const oneTimeDiscountData = discountData.find(
          (item) => item.service_type === 'one time service',
        );

        if (oneTimeDiscountData !== null) {
          const discountType = oneTimeDiscountData?.type;
          if (discountType === 'percentage') {
            oneTimeDiscount =
              (oneTimeSubTotal * oneTimeDiscountData?.amount) / 100;
          } else if (discountType === 'fixed amount') {
            oneTimeDiscount = oneTimeDiscountData?.amount;
          }
        } else {
          oneTimeDiscount = oneTimeDiscountData?.amount;
        }

        return {
          oneTimeSubTotal,
          oneTimeAmountAfterDiscount: oneTimeDiscount,
          oneTimeTotal: oneTimeSubTotal - oneTimeDiscount,
          oneTimeDiscountType: oneTimeDiscountData?.type,
          oneTimeDiscount: oneTimeDiscountData?.amount,
        };
      }
    }
    return 0;
  };

  const mapServiceTotal = () => {
    const totalFees = calculateTotalFee('onetime');
    return `${selectedCurrency}${(totalFees?.oneTimeTotal
      ? totalFees?.oneTimeTotal
      : 0
    )
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  const getAddedOrRemovedAMCServices = (array1, array2) => {
    return array1?.filter((object1) => {
      return !array2?.some((object2) => {
        return (
          object1?.name === object2?.name &&
          Number(object1?.fee) === Number(object2?.fee)
        );
      });
    });
  };

  const mapMonthlyServiceTotal = (
    monthlyServicesData,
    marketplaceData,
    accntType,
  ) => {
    const totalFees = calculateTotalFee(
      'monthly',
      monthlyServicesData,
      marketplaceData,
      accntType,
    );

    return `
    ${
      totalFees.monthlyAmountAfterDiscount > 0
        ? `<tr style=" border: 1px solid black;">
            <td class="total-service" style="border-bottom: hidden;border-left: 1px solid black; padding: 5px 13px"> Sub-total</td>
            ${
              contractAddendumDetails?.notActive
                ? `<td  class="total-service" style="border-bottom: hidden;border-left: 1px solid black; padding: 5px 13px">   </td>`
                : ''
            }
            <td class="total-service text-right" style="border-bottom: hidden; border-left: 1px solid black; padding: 5px 13px; text-align:right">
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 

            ${selectedCurrency}${displayNumber(
            totalFees?.monthlySubTotal?.toFixed(2),
          )}
            </span>
            </td>
         </tr>`
        : ''
    }
    ${
      totalFees.monthlyAmountAfterDiscount > 0
        ? `<tr style=" border: 1px solid black;">
            <td class="total-service" style="border-bottom: hidden;border-left: 1px solid black; padding: 5px 13px"> Discount ${
              totalFees?.monthlyAmountAfterDiscount &&
              totalFees?.monthlyDiscountType === 'percentage'
                ? `(${totalFees?.monthlyDiscount}%)`
                : ''
            }</td>
             ${
               contractAddendumDetails?.notActive
                 ? `<td class="total-service" style="border-bottom: hidden;border-left: 1px solid black; padding: 5px 13px"></td>`
                 : ''
             }
            <td class="total-service text-right"style="border-bottom: hidden; border-left: 1px solid black; padding: 5px 13px; text-align:right">
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 
            
            -${selectedCurrency}${
            totalFees?.monthlyAmountAfterDiscount > 0
              ? displayNumber(totalFees?.monthlyAmountAfterDiscount?.toFixed(2))
              : 0
          }
            </span>
            </td>
         </tr>`
        : ''
    }
      <tr>
        <td class="total-service" style="border: 1px solid black;padding: 5px 13px; font-weight: 800"> Total</td>
         ${
           contractAddendumDetails?.notActive
             ? `<td class="total-service" style="border: 1px solid black;padding: 5px 13px; font-weight: 800"></td>`
             : ''
         }
        <td class="total-service text-right" style="border: 1px solid black;padding: 5px 13px ;font-weight: 800; text-align:right"> 
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 

          ${selectedCurrency}${
      totalFees?.monthlyTotal
        ? displayNumber(totalFees.monthlyTotal?.toFixed(2))
        : 0
    }
          </span>
        </td>
      </tr>`;
  };

  const mapVATRegistrationMarketplaces = () => {
    const marketplaces =
      formData?.standard_non_us_services?.length &&
      formData?.standard_non_us_services
        .filter((item) => item.name === 'VAT Registration')
        .map((filteredItem) => filteredItem.marketplace);
    const result = [];
    if (marketplaces?.length) {
      marketplaces.map((item) => {
        result.push(`${displayMarketplaceName(item, 'vat', 'lhs')}`);
        return null;
      });
    }
    return result.length ? result.toString().replaceAll(',', ', ') : '';
  };

  const mapVATFilingMarketplaces = () => {
    const marketplaces =
      formData?.standard_non_us_services?.length &&
      formData?.standard_non_us_services.filter(
        (item) =>
          item.name === 'VAT Filing' &&
          item.marketplace !== null &&
          item.frequency !== null,
      );
    const result = [];
    if (marketplaces?.length) {
      marketplaces.map((item) => {
        result.push(
          `${displayMarketplaceName(item.marketplace, 'vat', 'lhs')} (${
            item?.frequency
          })`,
        );
        return null;
      });
    }
    return result.length ? result.toString().replaceAll(',', ', ') : '';
  };

  const mapRemainingServices = () => {
    const result = [];
    const customerService =
      formData?.standard_non_us_services?.length &&
      formData?.standard_non_us_services.filter(
        (item) => item.name === 'International Customer Service',
      );

    const logistics =
      formData?.standard_non_us_services?.length &&
      formData?.standard_non_us_services.filter(
        (item) => item.name === 'International Logistics',
      );

    if (customerService?.length)
      result.push(
        `<td style="border: 1px solid black;padding: 13px;">${customerService?.[0]?.name}</td>`,
      );
    if (logistics?.length)
      result.push(
        `<td style="border: 1px solid black;padding: 13px;">${logistics?.[0]?.name}</td>`,
      );

    if (formData?.copy_listing_translation) {
      result.push(
        `<td style="border: 1px solid black;padding: 13px;">Listing Copy Translation -<span style="font-weight: 800"> ASIN's per month : <span style=" background:#ffe5df; padding: 4px 9px";> ${formData?.copy_listing_translation}</span></span></td>`,
      );
    }
    if (formData?.creative_listing_translation) {
      result.push(
        `<td style="border: 1px solid black;padding: 13px;">Listing Creative Translation - <span style="font-weight: 800"> ASIN's per month : <span style=" background:#ffe5df; padding: 4px 9px";> ${formData?.creative_listing_translation}</span></span></td>`,
      );
    }
    return result.length ? result.toString().replaceAll(',', '') : '';
  };

  const showStandardServicesNONUSMarketplacesTable = () => {
    return (!formData?.standard_non_us_services ||
      formData?.standard_non_us_services?.length === 0) &&
      !formData.copy_listing_translation &&
      !formData.creative_listing_translation
      ? ''
      : `<div class="table-responsive" style="margin-top: 40px">
        <table class=" contact-list " style="width: 100%; overflow:auto; border-collapse: collapse;">
          <tr style=" border: 1px solid black;"><th colspan="5" style="text-align: left; border: 1px solid black;padding: 13px;">Service Components (Non-US Marketplaces)   </th></tr>
         ${
           formData?.standard_non_us_services?.length &&
           formData?.standard_non_us_services.filter(
             (item) => item.name === 'VAT Registration',
           )?.length
             ? `<tr style=" border: 1px solid black;">
               <td colspan="5" style="border: 1px solid black;padding: 13px;">VAT Registration: ${mapVATRegistrationMarketplaces()}</td>
             </tr>`
             : ''
         }
          ${
            formData?.standard_non_us_services?.length &&
            formData?.standard_non_us_services.filter(
              (item) => item.name === 'VAT Filing',
            )?.length
              ? `<tr style=" border: 1px solid black;"><td colspan="5" style="border: 1px solid black;padding: 13px;">VAT Filing: ${mapVATFilingMarketplaces()}</td></tr>`
              : ''
          }
          <tr style=" border: 1px solid black;">${mapRemainingServices()}</tr>
          </table>
      </div>
          `;
  };

  const mapOnetimeServiceTotal = () => {
    const totalFees = calculateTotalFee('onetime');
    return `
    ${
      totalFees?.oneTimeAmountAfterDiscount > 0
        ? `<tr style=" border: 1px solid black;">
            <td  class="total-service" colspan="3" style="border-bottom: hidden;border-left: 1px solid black; padding: 5px 13px;"> Sub-total</td>
            <td class="total-service text-right" style="border-bottom: hidden;border-left: 1px solid black; padding: 5px 13px; text-align:right">
            <span style=" background:#ffe5df; padding: 4px 9px;"> 
            ${selectedCurrency}${displayNumber(
            totalFees?.oneTimeSubTotal?.toFixed(2),
          )}
            </span>
            </td>
         </tr>`
        : ''
    }
    ${
      totalFees?.oneTimeAmountAfterDiscount > 0
        ? `<tr style=" border: 1px solid black;">
            <td class="total-service" colspan="3" style="border-bottom: hidden; border-left: 1px solid black;padding: 5px 13px;"> Discount ${
              totalFees?.oneTimeAmountAfterDiscount &&
              totalFees?.oneTimeDiscountType === 'percentage'
                ? `(${totalFees?.oneTimeDiscount}%)`
                : ''
            }</td>
            <td class="total-service text-right" style="border-bottom: hidden;border-left: 1px solid black; padding: 5px 13px; text-align:right">
            <span style=" background:#ffe5df; padding: 4px 9px;"> 
            -${selectedCurrency}${displayNumber(
            totalFees?.oneTimeAmountAfterDiscount?.toFixed(2),
          )}
            </span>
            </td>
         </tr>`
        : ''
    }        
         <tr>
            <td class="total-service" colspan="3" style="border: 1px solid black;padding: 5px 13px ; font-weight: 800"> Total</td>
            <td  class="total-service text-right" style="border: 1px solid black;padding: 5px 13px ;font-weight: 800; text-align:right">
            <span style=" background:#ffe5df; padding: 4px 9px;"> 
            ${selectedCurrency}${displayNumber(
      totalFees?.oneTimeTotal?.toFixed(2),
    )}
            </span>
            </td>
         </tr>
         `;
  };

  const showAMCServicesTable = () => {
    if (formData?.is_dsp_advertising || formData?.is_amc_management) {
      return `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center">
      <span style="font-weight: 800; font-family: Helvetica-bold;">Additional Advertising Services </span>
      <br> The following additional monthly services will be provided to Client in addition to the Monthly Retainer.</div><br>
      <div class="table-responsive">
        <table class="contact-list " style="width: 100%; overflow:auto; border-collapse: collapse;">
          <tr>
            <th>Service</th>
            <th>Service Fee</th>
          </tr>
            ${mapMonthlyServices('amc_services', formData?.amc_services)}
          ${mapMonthlyServiceTotal(formData?.amc_services, '', '')}

        </table>
      </div>`;
    }
    return '';
  };

  const showMonthlyServiceTable = (
    accntTYpe,
    monthlyServicesData,
    marketplaceData,
  ) => {
    if (
      monthlyServicesData?.length ||
      formData?.additional_marketplaces?.length
    ) {
      return `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center">
      <span style="font-weight: 800; font-family: Helvetica-bold;">Additional Monthly Services for Amazon ${accntTYpe} Account </span>
      <br> The following additional monthly services will be provided to Client in addition to the Monthly Retainer.</div><br>
      <div class="table-responsive">
        <table  style="width: 100%; border-collapse: collapse " class="contact-list ">
          <tr>
            <th style=" text-align: left; border: 1px solid black; padding: 13px;">Service</th>
            <th style=" text-align: left; border: 1px solid black; padding: 13px;">Service Fee</th>
          </tr>
            ${mapMonthlyServices(
              'additional_monthly_services',
              monthlyServicesData,
            )}
          ${mapAdditionalMarketPlaces(marketplaceData)}
            ${mapMonthlyServiceTotal(
              monthlyServicesData,
              marketplaceData,
              accntTYpe,
            )}
          ${mapVariableMonthlyService(monthlyServicesData)}   
        </table>
      </div>`;
    }
    return '';
  };

  const showMonthlyServiceTablesAccordingToAccType = () => {
    if (accountType === 'Seller') {
      const sellerResult = getServicesAccordingToAccType(
        formData?.additional_monthly_services,
        'Seller',
      );
      const marketplaceResult = getServicesAccordingToAccType(
        formData?.additional_marketplaces,
        'Seller',
      );
      return showMonthlyServiceTable('Seller', sellerResult, marketplaceResult);
    }
    if (accountType === 'Vendor') {
      const vendorResult = getServicesAccordingToAccType(
        formData?.additional_monthly_services,
        'Vendor',
      );
      const marketplaceResult = getServicesAccordingToAccType(
        formData?.additional_marketplaces,
        'Vendor',
      );
      return showMonthlyServiceTable('Vendor', vendorResult, marketplaceResult);
    }
    if (accountType === 'Hybrid') {
      const sellerResult = getServicesAccordingToAccType(
        formData?.additional_monthly_services,
        'Seller',
      );

      const vendorResult = getServicesAccordingToAccType(
        formData?.additional_monthly_services,
        'Vendor',
      );

      const sellerMarketplaceResult = getServicesAccordingToAccType(
        formData?.additional_marketplaces,
        'Seller',
      );

      const vendorMarketplaceResult = getServicesAccordingToAccType(
        formData?.additional_marketplaces,
        'Vendor',
      );
      if (
        (sellerResult && sellerResult.length) ||
        (vendorResult && vendorResult.length) ||
        (sellerMarketplaceResult && sellerMarketplaceResult.length) ||
        (vendorMarketplaceResult && vendorMarketplaceResult.length)
      ) {
        const sellerTable = showMonthlyServiceTable(
          'Seller',
          sellerResult,
          sellerMarketplaceResult,
        );
        const venderTable = showMonthlyServiceTable(
          'Vendor',
          vendorResult,
          vendorMarketplaceResult,
        );

        return sellerTable + venderTable;
      }

      if (sellerResult && sellerResult.length) {
        return showMonthlyServiceTable(
          'Seller',
          sellerResult,
          sellerMarketplaceResult,
        );
      }

      if (vendorResult && vendorResult.length) {
        return showMonthlyServiceTable(
          'Vendor',
          vendorResult,
          vendorMarketplaceResult,
        );
      }
    }
    return '';
  };

  const showOneTimeTable = () => {
    if (details?.additional_one_time_services?.length) {
      return `<table
    class="contact-list "
    style="width: 100%;
    border-collapse: collapse;
">
    <tr
      style="display: table-row;
    vertical-align: inherit;
    border-color: inherit;">
      <th style="text-align: left;border: 1px solid black;padding: 13px;">Quantity</th>
      <th style="text-align: left;border: 1px solid black;padding: 13px;">Service</th>
      <th style="text-align: left;border: 1px solid black;padding: 13px;">Service Fee</th>
      <th style="text-align: left;border: 1px solid black;padding: 13px;">Total Service Fee</th>
    </tr>
    ${mapMonthlyServices('additional_one_time_services', 'One Time Services')}
    ${mapOnetimeServiceTotal()}
  </table>`;
    }
    return '';
  };

  const showOneTimeServiceTable = () => {
    if (details?.additional_one_time_services?.length) {
      return `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center;"><span style="font-weight: 800;
    font-family: Helvetica-bold;">Additional One Time Services </span><br>The following additional monthly services will be provided to Client as a one time service in addition to the Monthly Retainer and any Additional Monthly services.</div><br>${showOneTimeTable()}`;
    }
    return '';
  };

  const showBillingCap = () => {
    if (formData?.billing_cap) {
      return `<br><br><div class=" text-center " style="text-align: center;"><span style="font-weight: 800;
      font-family: Helvetica-bold;"> Billing Cap </span> </div><div style="text-align: center;">Maximum amount that will be charged between the monthly retainer and revenue share.</div>
      <div class=" text-center input-contact-value mt-3" style="margin-top: 1rem!important; text-align: center;"><span style="background:#ffe5df;padding: 4px 9px; font-weight: bold"> 
      ${mapDefaultValues(
        'billing_cap',
        'Billing Cap',
        'number-currency',
      )}</span></div>`;
    }
    return '';
  };

  // PDV-8477 - Remove the table enumerating the “services not included” portion
  const showNotIncludedServicesTable = () => {
    if (
      notIncludedMonthlyServices?.length ||
      notIncludedOneTimeServices?.length
    ) {
      return `<div class="text-center mt-4" style="margin-top: 1.5rem !important; text-align: center;">
      <span style="font-weight: 800; font-family: Helvetica-bold;">Additional Services Not Included</span>
      <br>Anything not listed in this statement of work can be purchased at the fee scheduled in place at the time of the request.</div>`;
    }
    return '';
  };

  const getAgreementAccorType = (index) => {
    if (data && details?.contract_type?.toLowerCase().includes('one')) {
      return (
        data?.one_time_service_agreement &&
        data.one_time_service_agreement[index]
      );
    }
    return (
      data?.recurring_service_agreement &&
      data.recurring_service_agreement[index]
    );
  };

  const renderDspMarketplaces = (marketplaces) => {
    const fields = [];
    for (const item of marketplaces) {
      fields.push(
        `<th ${
          formData?.changed_dsp_start_date ? `colspan="2"` : ''
        } width: 33%  style="border: 1px solid black;padding: 13px; ">
            <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold; line-height: 2;">
              ${displayMarketplaceName(item?.name, 'dsp', 'lhs')}
            </span>
          </th>`,
      );
    }
    return fields;
  };

  const renderDspBudgetMarketplaces = (marketplaces) => {
    const fields = [];
    for (const item of marketplaces) {
      fields.push(
        `${
          formData?.changed_dsp_start_date
            ? `<td width: 33%  style="border: 1px solid black;padding: 13px; ">
             <span style="text-align: center!important; margin-bottom: 3rem!important; line-height: 1.9;">One Time</span> <br>
            <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
              ${selectedCurrency}${displayNumber(item.one_time_budget || 0)}
            </span>
          </td>`
            : ''
        } <td width: 33%  style="border: 1px solid black;padding: 13px; ">
         <span style="text-align: center!important; margin-bottom: 3rem!important; line-height: 1.9;">Monthly</span> <br>
            <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
               ${selectedCurrency}${displayNumber(item.monthly_budget || 0)}
            </span>
          </td>`,
      );
    }
    return fields;
  };

  const showDynamicBudgetAllocation = () => {
    const fields = [];
    for (const item of formData?.dsp_marketplaces) {
      fields.push(`
          <tr><td
          style="border: 1px solid black;
    padding: 13px;">
   
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
                   ${displayMarketplaceName(item?.name, 'dsp', 'lhs')}

     </span>
        </td>
       
       ${
         formData?.changed_dsp_start_date
           ? `<td
          style="border: 1px solid black;
    padding: 13px;">
    
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     ${selectedCurrency}${displayNumber(item.one_time_budget || 0)}
     </span>
        </td>`
           : ''
       }
        <td
          style="border: 1px solid black;
    padding: 13px;">

    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     ${selectedCurrency}${displayNumber(item.monthly_budget || 0)}
     </span>
        </td>
        <td
          style="border: 1px solid black;
    padding: 13px;">

    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     ${selectedCurrency}${displayNumber(item.monthly_budget_second || 0)}
     </span>
        </td>
        <td
          style="border: 1px solid black;
    padding: 13px;">

    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     ${selectedCurrency}${displayNumber(item.monthly_budget_third || 0)}
     </span>
        </td></tr> 
        `);
    }
    return fields;
  };

  const returnDspMonthlyDates = (theDate, addMonths) => {
    if (theDate) {
      const tempDate = getFutureMonths(theDate, addMonths);
      return dayjs(tempDate).format('MMMM YYYY');
    }
    return `MM-DD-YYYY`;
  };

  const displayMarketplace = () => {
    const result = [];

    const temp = [...formData?.dsp_marketplaces];
    if (formData?.dsp_marketplaces?.length) {
      if (formData?.is_dynamic_dsp_fee) {
        result.push(
          `<tr><th style="text-align: left; border: 1px solid black;
                      padding: 13px;">DSP Marketplace</th>
                      
                      ${
                        formData?.changed_dsp_start_date
                          ? `<th style="text-align: left; border: 1px solid black;
                      padding: 13px;">One Time Ad Budget</th>`
                          : ''
                      }
                       <th style="text-align: left; border: 1px solid black;
                      padding: 13px;">${
                        !formData?.changed_dsp_start_date
                          ? dayjs(firstMonthDate).format('MMMM YYYY')
                          : returnDspMonthlyDates(new Date(firstMonthDate), 1)
                      }</th> <th style="text-align: left; border: 1px solid black;
                      padding: 13px;">${
                        !formData?.changed_dsp_start_date
                          ? dayjs(secondMonthDate).format('MMMM YYYY')
                          : returnDspMonthlyDates(new Date(secondMonthDate), 1)
                      }</th> <th style="text-align: left; border: 1px solid black;
                      padding: 13px;">${
                        !formData?.changed_dsp_start_date
                          ? dayjs(thirdMonthDate).format('MMMM YYYY')
                          : returnDspMonthlyDates(new Date(thirdMonthDate), 1)
                      }</th></tr>${showDynamicBudgetAllocation()}`,
        );
      } else {
        const formattedData = new Array(Math.ceil(temp.length / 3))
          .fill()
          .map(() => temp.splice(0, 3));

        for (const item of formattedData) {
          result.push(`
          <tr>${renderDspMarketplaces(item)}</tr> 
          <tr>${renderDspBudgetMarketplaces(item)}</tr> 
        `);
        }
      }
    }
    return result.length ? result.toString().replaceAll('>,', '>') : '';
  };

  const displayMarketplacesTable = () => {
    return formData?.dsp_marketplaces?.length
      ? `
      <div className=" text-center mt-5 mb-4 " style="text-align: center; margin-top: 3rem!important; margin-bottom: 1.5rem!important; " >
                    <span  style="font-weight: 800;
    font-family: Helvetica-bold;"> Marketplace(s) Details </span></br>
    The initial budget for every marketplace will be set in advance by the Parties without adjustments during the initial period ("Initial Period") 
    based on start date, unless the Client wishes to increase the ad spend from the original budget at any point during that Initial Period. 
    The Parties agree that the initial budget breakdown for every marketplace will be as follows:
    </div> <table class="contact-list " style="width: 100%;
    border-collapse: collapse;">
    <tbody>${displayMarketplace()}</tbody>
    </table><p style="margin-top: 12px;">The Agency fee for Amazon DSP advertising is <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";> ${
      formData?.agency_fee?.label
        ? formData?.agency_fee?.label
        : `${formData?.agency_fee}%`
    } </span> of ad spend. Amazon charges their own platform and audience fees.</p>
    `
      : '';
  };
  const mapDspDetails = () => {
    return `<tr>
        <td style="border: 1px solid black; padding: 13px;">
          <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
            ${
              firstMonthDate
                ? dayjs(firstMonthDate).format('MM-DD-YYYY')
                : 'MM-DD-YYYY'
            }
          </span>
        </td>
        <td style="border: 1px solid black;
          padding: 13px;"><span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
            ${
              formData?.dsp_length
                ? formData?.dsp_length?.value || formData?.dsp_length
                : `<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold">Enter DSP Service Length </span>`
            }
        </td>
       ${
         formData?.is_dynamic_dsp_fee
           ? `
          <td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     DSP_FEE
     </span>
        </td>
        <td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     SECOND_DSP
     </span>
        </td>
        <td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     THIRD_DSP
     </span>
        </td>
        `
           : `<td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
     DSP_FEE
     </span>
        </td>`
       }
         ${
           formData?.changed_dsp_start_date
             ? `<td style="border: 1px solid black; padding: 13px;">
          <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>DSP_ONE_TIME_FEE</span>
        </td>`
             : ''
         }
      </tr>`;
  };

  // break first month date range for DSP Table
  const displayFirstMonthDateRange = () => {
    if (firstMonthDate) {
      const currentDate = firstMonthDate.getDate();
      const lastDay = new Date(
        firstMonthDate.getFullYear(),
        firstMonthDate.getMonth() + 1,
        0,
      );
      const lastDate = lastDay.getDate();
      if (currentDate === 1) {
        return dayjs(firstMonthDate).format('MMMM YYYY');
      }
      if (currentDate === lastDate) {
        return dayjs(firstMonthDate).format('MMM D, YYYY');
      }
      return `${
        firstMonthDate
          ? `${dayjs(firstMonthDate).format('MMM D, YYYY')} -
              ${dayjs(lastDay).format('MMM D, YYYY')}`
          : 'MM-DD-YYYY'
      }`;
    }
    return 'MM-DD-YYYY';
  };

  const mapBudgetBreakdownTable = () => {
    return `
    
    <div class="table-responsive">  <table class="contact-list " style="width: 100%;
    border-collapse: collapse;"><tr>
    ${
      formData?.changed_dsp_start_date
        ? `<th style="text-align: left; border: 1px solid black;
    padding: 13px;">${displayFirstMonthDateRange()} </th>`
        : ``
    } 
    <th style="text-align: left; border: 1px solid black;
    padding: 13px;">${
      !formData?.changed_dsp_start_date
        ? dayjs(firstMonthDate).format('MMMM YYYY')
        : returnDspMonthlyDates(new Date(firstMonthDate), 1)
    } </th><th style="text-align: left; border: 1px solid black;
    padding: 13px;">${
      secondMonthDate
        ? !formData?.changed_dsp_start_date
          ? dayjs(secondMonthDate).format('MMMM YYYY')
          : returnDspMonthlyDates(new Date(secondMonthDate), 1)
        : 'MM-DD-YYYY'
    }</th><th style="text-align: left; border: 1px solid black;
    padding: 13px;">${
      thirdMonthDate
        ? !formData?.changed_dsp_start_date
          ? dayjs(thirdMonthDate).format('MMMM YYYY')
          : returnDspMonthlyDates(new Date(thirdMonthDate), 1)
        : 'MM-DD-YYYY'
    } </th></tr>
    <tr>
    ${
      formData?.changed_dsp_start_date
        ? `<td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
               DSP_ONE_TIME_FEE
</span>
        </td>`
        : ''
    }
     <td style="border: 1px solid black;
    padding: 13px;"> <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";> DSP_FEE </span></td>
        <td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
               ${formData?.is_dynamic_dsp_fee ? 'SECOND_DSP' : 'DSP_FEE'}
               </span>

        </td><td
          style="border: 1px solid black;
    padding: 13px;">
    <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold";>
                             ${
                               formData?.is_dynamic_dsp_fee
                                 ? 'THIRD_DSP'
                                 : 'DSP_FEE'
                             }

        </td>
      </tr>
      
      </table>
     </div>`;
  };

  const checkQuarterly = (feeDetails, type, value) => {
    if (
      feeDetails?.[type] === 'quarterly' &&
      (!feeDetails?.[value]?.['1st quarterly'] ||
        !feeDetails?.[value]?.['2nd quarterly'] ||
        !feeDetails?.[value]?.['3rd quarterly'] ||
        !feeDetails?.[value]?.['4th quarterly'])
    )
      return false;
    return true;
  };

  const checkMonthly = (feeDetails, type, value) => {
    if (
      feeDetails?.[type] === 'monthly' &&
      (!feeDetails?.[value]?.['january month'] ||
        !feeDetails?.[value]?.['february month'] ||
        !feeDetails?.[value]?.['march month'] ||
        !feeDetails?.[value]?.['april month'] ||
        !feeDetails?.[value]?.['may month'] ||
        !feeDetails?.[value]?.['june month'] ||
        !feeDetails?.[value]?.['july month'] ||
        !feeDetails?.[value]?.['august month'] ||
        !feeDetails?.[value]?.['september month'] ||
        !feeDetails?.[value]?.['october month'] ||
        !feeDetails?.[value]?.['november month'] ||
        !feeDetails?.[value]?.['december month'])
    )
      return false;
    return true;
  };
  const checkMissinInfoForFeeStructure = (type) => {
    const feeDetails = formData?.fee_structure?.[type];

    if (formData?.combined_rev_share) {
      if (
        feeStructureErrors?.seller &&
        Object.values(feeStructureErrors?.seller)?.find((item) => item !== '')
      ) {
        return false;
      }
    } else if (
      (feeStructureErrors?.vendor &&
        Object.values(feeStructureErrors?.vendor)?.find(
          (item) => item !== '',
        )) ||
      (feeStructureErrors?.seller &&
        Object.values(feeStructureErrors?.seller)?.find((item) => item !== ''))
    ) {
      return false;
    }

    if (feeDetails?.fee_type === 'Retainer Only') {
      if (
        (!feeDetails?.retainer_type || feeDetails.retainer_type === 'Fixed') &&
        !feeDetails?.monthly_retainer
      ) {
        return false;
      }
      if (feeDetails?.retainer_type === 'quarterly')
        return checkQuarterly(
          feeDetails,
          'retainer_type',
          'quarterly_monthly_retainer',
        );
      if (feeDetails?.retainer_type === 'monthly')
        return checkMonthly(
          feeDetails,
          'retainer_type',
          'monthly_monthly_retainer',
        );
    } else if (feeDetails?.fee_type === 'Revenue Share Only') {
      if (!feeDetails?.rev_share) return false;
    } else if (feeDetails?.fee_type === 'Retainer + % Rev Share') {
      if (!feeDetails?.rev_share) return false;
      if (
        (!feeDetails?.retainer_type || feeDetails.retainer_type === 'Fixed') &&
        !feeDetails?.monthly_retainer
      ) {
        return false;
      }
      if (feeDetails?.retainer_type === 'quarterly')
        return checkQuarterly(
          feeDetails,
          'retainer_type',
          'quarterly_monthly_retainer',
        );
      if (feeDetails?.retainer_type === 'monthly')
        return checkMonthly(
          feeDetails,
          'retainer_type',
          'monthly_monthly_retainer',
        );
    }
    if (feeDetails?.fee_type) return true;
    return false;
  };

  const checkMonthlyOneTimBudget = (key) => {
    if (formData?.dsp_marketplaces?.length > 1) {
      const budgetArray = [];
      for (const market of formData?.dsp_marketplaces) {
        market[key] =
          typeof market[key] === 'string'
            ? market?.[key]?.replaceAll(',', '')
            : market[key];
        budgetArray.push(Number(market?.[key]) || 0);
      }
      return budgetArray.reduce((temp1, temp2) => temp1 + temp2);
    }
    return key === 'monthly_budget'
      ? Number(formData?.dsp_fee || 0)
      : Number(formData?.dsp_one_time_fee || 0);
  };

  const checkMonthlyBudget = () => {
    const monthlyBudgetFirst = [];
    const monthlyBudgetSecond = [];
    const monthlyBudgetThird = [];

    let totalBudgetFirst = 0;
    let totalBudgetSecond = 0;
    let totalBudgetThird = 0;

    if (formData?.dsp_marketplaces?.length > 1) {
      for (const market of formData?.dsp_marketplaces) {
        market.monthly_budget =
          typeof market?.monthly_budget === 'string'
            ? market?.monthly_budget?.replaceAll(',', '')
            : market?.monthly_budget;
        monthlyBudgetFirst.push(Number(market?.monthly_budget) || 0);
        market.monthly_budget_second =
          typeof market?.monthly_budget_second === 'string'
            ? market?.monthly_budget_second?.replaceAll(',', '')
            : market?.monthly_budget_second;
        monthlyBudgetSecond.push(Number(market?.monthly_budget_second) || 0);
        market.monthly_budget_third =
          typeof market?.monthly_budget_third === 'string'
            ? market?.monthly_budget_third?.replaceAll(',', '')
            : market?.monthly_budget_third;
        monthlyBudgetThird.push(Number(market?.monthly_budget_third) || 0);
      }
      totalBudgetFirst = monthlyBudgetFirst.reduce(
        (temp1, temp2) => temp1 + temp2,
      );
      totalBudgetSecond = monthlyBudgetSecond.reduce(
        (temp1, temp2) => temp1 + temp2,
      );
      totalBudgetThird = monthlyBudgetThird.reduce(
        (temp1, temp2) => temp1 + temp2,
      );

      if (
        formData?.is_dynamic_dsp_fee
          ? totalBudgetFirst === Number(formData?.dsp_fee) &&
            totalBudgetSecond === Number(formData?.dsp_fee_second) &&
            totalBudgetThird === Number(formData?.dsp_fee_third)
          : totalBudgetFirst === Number(formData?.dsp_fee) &&
            totalBudgetSecond === Number(formData?.dsp_fee) &&
            totalBudgetThird === Number(formData?.dsp_fee)
      ) {
        return true;
      }
      return false;
    }
    return Number(formData?.dsp_fee || 0);
  };

  const showRightTick = (section) => {
    if (section === 'service_agreement') {
      if (Object.values(customerError)?.find((item) => item !== '')) {
        return false;
      }
      if (contractTypeOne) {
        if (
          customerCompanyName &&
          formData.start_date &&
          customerAddress &&
          customerState &&
          customerCity &&
          customerZipCode &&
          (formData.additional_one_time_services !== null ||
            formData.additional_one_time_services?.length)
        ) {
          return true;
        }
      } else if (
        customerCompanyName &&
        formData.start_date &&
        formData.length &&
        customerState &&
        customerCity &&
        customerZipCode
      ) {
        return true;
      }
    }

    if (section === 'feeStructure') {
      if (contractTypeOne || contractTypeDsp) {
        return true;
      }
      if (!contractTypeOne && !contractTypeDsp) {
        if (!formData?.primary_marketplace || !formData?.seller_type)
          return false;
        if (formData?.seller_type?.label === 'Seller') {
          return checkMissinInfoForFeeStructure('seller');
        }
        if (formData?.seller_type?.label === 'Vendor') {
          if (!formData?.fee_structure?.vendor?.vendor_billing_report)
            return false;
          return checkMissinInfoForFeeStructure('vendor');
        }
        if (formData?.seller_type?.label === 'Hybrid') {
          if (!formData?.fee_structure?.vendor?.vendor_billing_report)
            return false;
          return (
            checkMissinInfoForFeeStructure('seller') &&
            checkMissinInfoForFeeStructure('vendor')
          );
        }
      }
    }

    if (section === 'statement') {
      if (
        Object.values(additionalMarketplaceError)?.find(
          (item) => item !== '',
        ) ||
        // Object.values(additionalMonthlySerError)?.find((item) => item !== '') ||
        (formData?.contract_type?.includes('one') &&
          !formData?.additional_one_time_services?.length) ||
        Object.values(nonUsServiceError)?.find((item) => item !== '') ||
        Object.values(additionalOnetimeSerError)?.find((item) => item !== '')
          ?.length ||
        Object.values(amcServiceError)?.find((item) => item !== '')?.length
      ) {
        return false;
      }
      if (!contractTypeOne && !contractTypeDsp) {
        return true;
      }
      return true;
    }

    if (section === 'dspAddendum') {
      if (showSection?.dspAddendum && !contractTypeOne) {
        if (
          formData?.start_date &&
          formData.dsp_fee &&
          formData.dsp_length &&
          (formData?.is_dynamic_dsp_fee
            ? formData.dsp_fee &&
              formData.dsp_fee_second &&
              formData.dsp_fee_third
            : true) &&
          (!contractAddendumDetails?.notActive &&
          formData?.changed_dsp_start_date
            ? formData?.dsp_one_time_fee
            : true) &&
          formData?.dsp_marketplaces?.length &&
          (!contractAddendumDetails?.notActive
            ? !formData?.dsp_marketplaces?.filter((op) => !op.monthly_budget)
                ?.length
            : true) &&
          (!contractAddendumDetails?.notActive &&
          Number(formData?.dsp_one_time_fee)
            ? !formData?.dsp_marketplaces?.filter((op) => !op.one_time_budget)
                ?.length
            : true) &&
          (!contractAddendumDetails?.notActive &&
          !formData?.monthly_budget_distribution_equally
            ? checkMonthlyBudget('monthly_budget')
            : true) &&
          (!contractAddendumDetails?.notActive &&
          !formData?.one_time_budget_distribution_equally &&
          formData?.changed_dsp_start_date
            ? checkMonthlyOneTimBudget('one_time_budget') ===
              Number(formData?.dsp_one_time_fee)
            : true)
        ) {
          return true;
        }
      } else {
        return true;
      }
    }

    return false;
  };

  const renderStdServiceRow = (service) => {
    const fields = [];
    for (const item of service) {
      if (!item.value && !item.label) {
        fields.push(
          `<td style="border: 1px solid black; padding: 13px;">${item.name}</td>`,
        );
      } else {
        fields.push(
          `<td style="border: 1px solid black; padding: 13px;">Listing Optimization - ${item.label} <br>
              *May vary by strategic priorities, <span style="font-weight: 800;">up to  <span style=" background:#ffe5df; padding: 4px 9px; font-weight: bold"> 
                ${item.value}
              </span>ASINs
              </span>per month
            </td>`,
        );
      }
    }
    return fields;
  };

  const mapStandardServices = () => {
    if (
      (formData?.standard_us_services &&
        formData?.standard_us_services.length) ||
      formData?.content_optimization ||
      formData?.design_optimization
      // formData?.photography_optimization
    ) {
      let content = {};
      let design = {};
      // let photo = {};

      if (formData?.content_optimization) {
        content = {
          label: 'Copy',
          value: formData.content_optimization,
        };
      }
      if (formData?.design_optimization) {
        design = {
          label: 'Design',
          value: formData.design_optimization,
        };
      }
      // if (formData?.photography_optimization) {
      //   photo = {
      //     label: 'Photography',
      //     value: formData.photography_optimization,
      //   };
      // }
      const fields = [];
      // combine the standard services with listing optimization to show it properly in the table dynamically.
      let temp = [];
      if (formData?.standard_us_services?.length)
        temp = [...temp, ...formData.standard_us_services];
      if (content && Object.keys(content).length !== 0)
        temp = [...temp, content];
      if (design && Object.keys(design).length !== 0) temp = [...temp, design];
      // if (photo && Object.keys(photo).length !== 0) temp = [...temp, photo];

      const formattedData = new Array(Math.ceil(temp.length / 3))
        .fill()
        .map(() => temp.splice(0, 3));
      for (const item of formattedData) {
        fields.push(`<tr> ${renderStdServiceRow(item)} </tr>`);
      }
      return fields.length ? fields.toString().replaceAll(',', '') : '';
    }
    return '';
  };

  // pdv-8551 - Add one line to this section whenever listing optimization is added to the contract.
  // This line is added in listing optimisation info --> If the child listings require different infographics and/or copy, or if there are more than 5 child listings, additional fees may apply.

  // display lisint optimisation content
  const displayListingOptimisationInfo = () => {
    return `<p>*The number of Listing Optimizations per month may vary according to changing strategic priorities and   serves as a guideline, not a guarantee. Listing Optimization ASINs may be applied to all children in a single parent ASIN only if unique copy and infographics are not required. If the child listings require different infographics and/or copy, or if there are more than 5 child listings, additional fees may apply. Infographics include up to 5 screens per ASIN.</p><br />   
    <p>Listing Optimization Services may be substituted for services of similar value as agreed upon in writing by both the Brand Growth Strategist and Client. These services may include but are not limited to: SEO Keyword Optimization, A+ Content Design, Brand Story, Style Guides, Improvement or Optimization, Premium A+ Content Design or Variations, Amazon Store Front Design or Optimization, Infographic creation or optimization, Graphic & Copy Translation Services. </p><br />
    <p>Should a set amount of optimization or creative services not be delivered within a set month, those agreed upon deliverables may be completed by the end of the contract term at the latest.</p><br />
    <p>Delivering the scheduled number of optimization services requires the Client to deliver assets to BBE, approve a brand sample, and respond to requests for feedback in a timely manner. Each Creative Deliverable is allowed 2 free revisions in order to maintain an effective delivery schedule. Delays in delivering assets, providing feedback or requests for more than 2 revisions may delay deliverables. Please see Revisions Policy Guidelines next section for more information.</p>`;
  };

  // display revision content in document
  const displayRevisionPolicyInfo = () => {
    return `<p><span style="font-weight: 800; font-family: Helvetica-bold;">What is a Revision?</span></p>
        <p>A revision is any change or ‘edit’ requested by Client to a deliverable before upload.</p>
        <ul>
          <li>Changes to previously uploaded BBE deliverables are considered ‘updates’ or ‘refreshes’.</li>
        </ul>
        <p><span style="font-weight: 800;
        font-family: Helvetica-bold;">Revision Policy</span></p>
        <p>Client will receive 2 free rounds of revisions to a deliverable prior to upload.</p>
        <ul>
          <li>If Client wants further revisions to a deliverable, they can be substituted for contracted creative work or purchased at the fee schedule in place at the time of the request.
          </li>
          <li>When a change or 'edit' to a deliverable is requested, that is considered the 1st round. A request for a change to the deliverable after that will be regarded as the 2nd round.</li>
        </ul>
        <p>All revision notes or ‘edits’ should be submitted at the same time.</p>
        <ul>
          <li>After creative work is delivered, Client should take 1-2 weeks to review the deliverables with all the stakeholders on their end and submit all revision notes at the same time.</li>
        </ul>
      `;
  };

  const showStandardServicesTable = () => {
    if (
      (formData.standard_us_services?.length === 0 ||
        formData.standard_us_services === null) &&
      !formData.content_optimization &&
      !formData.design_optimization &&
      !formData.photography_optimization
    )
      return ``;
    return `<div class="text-center BT-SOW-standard-service mt-4" style="margin-top: 1.5rem !important; text-align:center;"><span style="font-weight: 800; font-family: Helvetica-bold;">Standard Services</span>
       <br> The following services will be provided to client during the term of the contract.</div><br/>
      <div class="table-responsive">
        <table class="contact-list" style="width: 100%; overflow:auto; border-collapse: collapse;">
          <tr><th colspan="3" style="text-align: left; border: 1px solid black;padding: 13px;">Service Components</th></tr>
            ${mapStandardServices()}
        </table>
        ${
          formData?.design_optimization ||
          formData?.content_optimization ||
          formData?.photography_optimization
            ? `<br />
            ${displayListingOptimisationInfo()}
            <br />
            ${displayRevisionPolicyInfo()}`
            : ''
        }
      </div>
    `;
  };

  const mapLanguage = () => {
    if (
      details?.contract_type
        ?.toLowerCase()
        .includes('recurring (90 day notice)')
    ) {
      return Recurring90DaysLanguage;
    }
    return RecurringLanguage;
  };

  const mapStandardServicesTable = (servicesData, tableType) => {
    const fields = [];
    servicesData.map((item) => {
      fields.push(`<tr>
                <td style="border: 1px solid black;padding: 13px;">${
                  item.name
                } </td>
                 ${
                   tableType === 'added'
                     ? `<td style="border: 1px solid black;padding: 13px;">${
                         item?.id && item?.effective_date
                           ? item?.effective_date
                           : item?.id && !item?.effective_date
                           ? formData?.contract_signed_date
                           : defaultEffectivityDateText
                       } </td>`
                     : ''
                 }
                </tr>`);
      return null;
    });
    return fields;
  };

  const renderAddendumStandardUsServices = (result, tableType) => {
    const returnData = [];
    returnData.push(
      `<div class="table-responsive" style="margin-top:10px;">
        <table class=" contact-list " style="width: 100%; overflow:auto; border-collapse: collapse;">
           <tr>
            <th style=" text-align: left; border: 1px solid black; padding: 13px;">Services</th>
            ${
              tableType === 'added'
                ? `<th style=" text-align: left; border: 1px solid black; padding: 13px;">Effective Date</th>`
                : ''
            }
          </tr>
               
          ${result?.length ? mapStandardServicesTable(result, tableType) : ''}
          </table>
          </div>`,
    );
    return returnData;
  };

  const renderListingOptimizationServices = (param, service1, service2) => {
    if (service1 !== service2) {
      return `<li>${param} to ${service2 !== null ? service2 : 0} from ${
        service1 !== null ? service1 : 0
      }</li>`;
    }
    return '';
  };

  function getDifference(array1, array2, field) {
    return array1?.filter((object1) => {
      return !array2?.some((object2) => {
        if (field === 'marketplace') {
          return object1?.name === object2?.name;
        }
        if (object1?.quantity !== object2?.quantity)
          return object1?.quantity === object2?.quantity;
        return object1?.service?.id === object2?.service?.id;
      });
    });
  }

  const displayMonthlyServices = (result, type) => {
    if (result?.length) {
      const list = result.map(
        (item) =>
          `<li>${
            type === 'dsp'
              ? displayMarketplaceName(item?.service?.name, 'dsp', 'lhs') ||
                displayMarketplaceName(item?.name, 'dsp', 'lhs')
              : item?.service?.name || item?.name
          } ${
            item?.service?.name === 'A+ Content' || item?.name === 'A+ Content'
              ? `(${item?.quantity || 1})`
              : ''
          }</li>`,
      );
      return list;
    }
    return '';
  };

  const displayMonthlyServiceTable = (
    serviceAddedResult,
    marketplacesAddedResult,
    serviceRemovedResult,
    marketplacesRemovedResult,
    accntTYpe,
    tableType,
  ) => {
    const localAccountType = accntTYpe === 'seller' ? 'Seller' : 'Vendor';

    const originalservice = getServicesAccordingToAccType(
      originalData?.additional_monthly_services,
      localAccountType,
    );
    const addendumService = getServicesAccordingToAccType(
      formData?.additional_monthly_services,
      localAccountType,
    );

    const addendumMarketplaces = getServicesAccordingToAccType(
      formData?.additional_marketplaces,
      localAccountType,
    );
    const originalMarketplaces = getServicesAccordingToAccType(
      originalData?.additional_marketplaces,
      localAccountType,
    );

    const returnData = [];
    returnData.push(
      `
 <div class="table-responsive" style="margin-top:10px;">
        <table class=" contact-list " style="width: 100%; overflow:auto; border-collapse: collapse;">
         ${
           tableType !== 'removed'
             ? getDifference(originalservice, serviceRemovedResult)?.length ||
               getDifference(
                 originalMarketplaces,
                 marketplacesRemovedResult,
                 'marketplace',
               )?.length
               ? `<tr>
            <th  style="text-align: left; border: 1px solid black;padding: 13px;">Existing Service</th>
            <th  style="text-align: left; border: 1px solid black;padding: 13px;">Effective Date</th>
            <th  style="text-align: left; border: 1px solid black;padding: 13px;">Service Fee</th>
          </tr>`
               : ''
             : `<tr>
            <th  style="text-align: left; border: 1px solid black;padding: 13px;">Existing Service</th>
            <th  style="text-align: left; border: 1px solid black;padding: 13px;">Service Fee</th>
          </tr>`
         }
          
          ${
            tableType !== 'removed'
              ? mapMonthlyServices(
                  'additional_monthly_services',
                  getDifference(originalservice, serviceRemovedResult),
                )
              : ''
          }
         ${
           tableType !== 'removed'
             ? mapAdditionalMarketPlaces(
                 getDifference(
                   originalMarketplaces,
                   marketplacesRemovedResult,
                   'marketplace',
                 ),
               )
             : ''
         }
<tr>
  ${
    (serviceAddedResult?.length || marketplacesAddedResult?.length) &&
    tableType !== 'removed'
      ? ` <th style="text-align: left; border: 1px solid black;padding: 13px;">Additional Service</th>
      <th style="text-align: left; border: 1px solid black;padding: 13px;">Effective Date</th>
            <th style="text-align: left; border: 1px solid black;padding: 13px;">Service Fee</th>
         `
      : ''
  }
</tr>
${mapMonthlyServices(
  'additional_monthly_services',
  tableType !== 'removed' ? serviceAddedResult : serviceRemovedResult,
  tableType,
)}
         ${mapAdditionalMarketPlaces(
           tableType !== 'removed'
             ? marketplacesAddedResult
             : marketplacesRemovedResult,
           tableType,
         )}

         ${
           tableType !== 'removed'
             ? mapMonthlyServiceTotal(
                 addendumService,
                 addendumMarketplaces,
                 accntTYpe,
               )
             : ''
         }
          ${mapVariableMonthlyService(
            tableType !== 'removed'
              ? getDifference(addendumService, serviceRemovedResult)
              : serviceRemovedResult,
          )} 
        </table>
      </div>`,
    );
    return returnData.length
      ? returnData.toString().replaceAll('>,<', '><')
      : '';
  };

  const getMonthlyServicesofContract = (type, status) => {
    let result = [];
    const localAccountType = type === 'seller' ? 'Seller' : 'Vendor';
    const addendumService = getServicesAccordingToAccType(
      formData?.additional_monthly_services,
      localAccountType,
    );
    const originalservice = getServicesAccordingToAccType(
      originalData?.additional_monthly_services,
      localAccountType,
    );

    if (status === 'added') {
      result = getDifference(addendumService, originalservice);
    } else {
      result = getDifference(originalservice, addendumService);
    }
    return result;
  };

  const getDifferenceMarketplaces = (type, status) => {
    let result = [];
    const localAccountType = type === 'seller' ? 'Seller' : 'Vendor';

    const addendumService = getServicesAccordingToAccType(
      formData?.additional_marketplaces,
      localAccountType,
    );
    const originalservice = getServicesAccordingToAccType(
      originalData?.additional_marketplaces,
      localAccountType,
    );

    if (status === 'added') {
      result = getDifference(addendumService, originalservice, 'marketplace');
    } else {
      result = getDifference(originalservice, addendumService, 'marketplace');
    }

    return result;
  };
  const displayMontlyServiceAddendum = (type, fields) => {
    const serviceAddedResult = getMonthlyServicesofContract(type, 'added');
    const serviceRemovedResult = getMonthlyServicesofContract(type, 'removed');
    const marketplacesAddedResult = getDifferenceMarketplaces(type, 'added');
    const marketplacesRemovedResult = getDifferenceMarketplaces(
      type,
      'removed',
    );
    const localAccountType = type === 'seller' ? 'Seller' : 'Vendor';

    const isDiscountAdded = discountData?.filter((item) => {
      return item?.account_type === localAccountType;
    });
    const originalservice = getServicesAccordingToAccType(
      originalData?.additional_monthly_services,
      localAccountType,
    );

    const originalMarketplaces = getServicesAccordingToAccType(
      originalData?.additional_marketplaces,
      localAccountType,
    );

    if (
      serviceAddedResult?.length ||
      serviceRemovedResult?.length ||
      marketplacesAddedResult?.length ||
      marketplacesRemovedResult?.length ||
      (isDiscountAdded?.length &&
        isDiscountAdded?.[0]?.is_addendum_discount_changed)
    ) {
      fields.push(
        `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center;">
        <span style="font-weight: 800; font-family: Helvetica-bold;">Monthly Services for Amazon ${
          type === 'seller' ? 'Seller' : 'Vendor'
        } Account</span></div>`,
      );
    }
    if (
      serviceAddedResult?.length === 0 &&
      marketplacesAddedResult?.length === 0 &&
      getDifference(originalservice, serviceRemovedResult)?.length === 0 &&
      getDifference(
        originalMarketplaces,
        marketplacesRemovedResult,
        'marketplace',
      )?.length === 0
    ) {
      if (
        !(
          isDiscountAdded?.length &&
          isDiscountAdded?.[0]?.is_addendum_discount_changed
        )
      )
        fields.push(``);
    } else if (
      (isDiscountAdded?.length &&
        isDiscountAdded?.[0]?.is_addendum_discount_changed) ||
      serviceAddedResult?.length ||
      marketplacesAddedResult?.length
    ) {
      fields.push(`
       • The Service Order / Statement of Work is updated to add the following  <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">Monthly Service(s)</span>.<br>
          
         ${displayMonthlyServiceTable(
           serviceAddedResult,
           marketplacesAddedResult,
           serviceRemovedResult,
           marketplacesRemovedResult,
           type,
         )}
          `);
    }

    if (serviceRemovedResult?.length || marketplacesRemovedResult?.length) {
      fields.push(
        `• The Service Order / Statement of Work is updated to remove the following<span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">Monthly Service(s)</span>.<br>
        
        ${displayMonthlyServiceTable(
          serviceAddedResult,
          marketplacesAddedResult,
          serviceRemovedResult,
          marketplacesRemovedResult,
          type,
          'removed',
        )}        
        `,
      );
    }
    return fields.length
      ? fields
          .toString()
          .replaceAll('>,<', '><')
          .replaceAll('.,', '.')
          .replaceAll(' ,', '')
          .replaceAll('•', '<br>&bull;')
          .replaceAll(', <br>', '<br>')
          .replaceAll(',<br>', '<br>')
      : '';
  };
  const ShowAddendumRetainerThresholdValues = (
    type,
    quartelyKey,
    monthlyKey,
    option,
  ) => {
    return `${
      formData?.fee_structure?.[type]?.[option] === 'quarterly'
        ? ` <li>1st Quarter from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[quartelyKey]?.[
                '1st quarterly'
              ],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['1st quarterly'],
            ) || 0
          }</span> </li>
              <li>2nd Quarter from  <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[quartelyKey]?.[
                '2nd quarterly'
              ],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['2nd quarterly'],
            ) || 0
          }</span></li>
            <li> 3rd Quarter from  <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[quartelyKey]?.[
                '3rd quarterly'
              ],
            ) || 0
          }</span> to  <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['3rd quarterly'],
            ) || 0
          } </span> </li>
                  <li>4th Quarter from  <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[quartelyKey]?.[
                '4th quarterly'
              ],
            ) || 0
          }</span> to  <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[quartelyKey]?.['4th quarterly'],
            ) || 0
          }</span> </li>
              `
        : formData?.fee_structure?.[type]?.[option] === 'monthly'
        ? ` 
                <li>January from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'january month'
              ],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['january month'],
            ) || 0
          } </span> </li>
            <li>February from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'february month'
              ],
            ) || 0
          }</span>  to  <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['february month'],
            ) || 0
          } </span> </li>
            <li>March from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'march month'
              ],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['march month'],
            ) || 0
          } </span> </li>
                <li>  April from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'april month'
              ],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['april month'],
            ) || 0
          } </span> </li>
                <li>May from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.['may month'],
            ) || 0
          }</span>  to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['may month'],
            ) || 0
          }  </span> </li>
              <li>June from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.['june month'],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['june month'],
            ) || 0
          } </span> </li>
<li>July from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.['july month'],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['july month'],
            ) || 0
          } </span> </li>
<li>August from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'august month'
              ],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['august month'],
            ) || 0
          } </span> </li>
<li> September from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'september month'
              ],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.[
                'september month'
              ],
            ) || 0
          } </span> </li>
<li>October from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'october month'
              ],
            ) || 0
          }</span>  to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['october month'],
            ) || 0
          } </span> </li>
<li>November from <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'november month'
              ],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['november month'],
            ) || 0
          } </span> </li>
<li>December from  <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${
            displayNumber(
              originalData?.fee_structure?.[type]?.[monthlyKey]?.[
                'december month'
              ],
            ) || 0
          }</span> to <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${
            displayNumber(
              formData?.fee_structure?.[type]?.[monthlyKey]?.['december month'],
            ) || 0
          } </span> </li>
            `
        : ''
    }`;
  };

  const getObjectDifference = (obj1, obj2) => {
    const combinedObj = { ...obj1, ...obj2 };
    const diff = Object.entries(combinedObj).reduce((acc, [key, value]) => {
      if (
        (obj1 && !Object.values(obj1).includes(value)) ||
        (obj2 && !Object.values(obj2).includes(value))
      )
        acc[key] = value;
      return acc;
    }, {});
    if (diff && Object.values(diff)?.length > 0) {
      return true;
    }
    return false;
  };

  const checkConditions = (param1, param2) => {
    param1 = Number(param1);
    param2 = Number(param2);
    if (
      (!param1 ? 0 : parseFloat(param1, 10)) !==
      (!param2 ? 0 : parseFloat(param2, 10))
    ) {
      return true;
    }
    return false;
  };

  const accountContractAddendum = (type) => {
    const fields = [];
    // if fee type is changed.
    if (
      originalData.fee_structure?.[type]?.fee_type === 'Retainer Only' &&
      formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share'
    ) {
      fields.push(`• The Service Order / Statement of Work is updated to add the <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">Revenue Share</span> invoices value of <span style="${styleForChangedValues}"> ${selectedCurrency}${displayNumber(
        formData?.fee_structure?.[type]?.monthly_retainer || 0,
      )}</span> for managing ${type} account. This change is effective from ${
        formData?.fee_structure?.[type]?.effective_date
          ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
              'MM / DD / YYYY',
            )
          : defaultEffectivityDateText
      }. That's parallel to the Retainer invoices that are issued monthly for ${type} account.<br>`);
    }
    if (
      originalData.fee_structure?.[type]?.fee_type === 'Revenue Share Only' &&
      formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share'
    ) {
      fields.push(`• The Service Order / Statement of Work is updated to add the <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">Retainer invoices</span> value of <span style="${styleForChangedValues}"> ${selectedCurrency}${displayNumber(
        formData?.fee_structure?.[type]?.monthly_retainer || 0,
      )}</span> for managing ${type} account. This change is effective from ${
        formData?.fee_structure?.[type]?.effective_date
          ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
              'MM / DD / YYYY',
            )
          : defaultEffectivityDateText
      }. That's parallel to the Rev Share invoices that are issued monthly for ${type} account.<br>`);
    }

    // if retainer value is changed.
    if (
      (formData?.fee_structure?.[type]?.retainer_type === 'Fixed' ||
        formData?.fee_structure?.[type]?.retainer_type === null) &&
      originalData?.fee_structure?.[type]?.monthly_retainer !==
        formData?.fee_structure?.[type]?.monthly_retainer
    ) {
      fields.push(`• The Service Order / Statement of Work is updated to change the Monthly Retainer to <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${displayNumber(
        formData?.fee_structure?.[type]?.monthly_retainer || 0,
      )}</span> This change is effective from ${
        formData?.fee_structure?.[type]?.effective_date
          ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
              'MM / DD / YYYY',
            )
          : defaultEffectivityDateText
      }.<br>`);
    }
    if (
      (formData?.fee_structure?.[type]?.retainer_type === 'quarterly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.quarterly_monthly_retainer || {},
          formData?.fee_structure?.[type]?.quarterly_monthly_retainer || {},
        )) ||
      (formData?.fee_structure?.[type]?.retainer_type === 'monthly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.monthly_monthly_retainer || {},
          formData?.fee_structure?.[type]?.monthly_monthly_retainer || {},
        ))
    ) {
      fields.push(
        `
           • The Service Order / Statement of Work is updated to change the <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">  ${
      formData?.fee_structure?.[type]?.retainer_type === 'quarterly'
        ? 'quarterly'
        : 'monthly'
    } retainer</span> invoice value for ${type} account.This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>  <ul style="list-style-type: circle;">${ShowAddendumRetainerThresholdValues(
          type,
          'quarterly_monthly_retainer',
          'monthly_monthly_retainer',
          'retainer_type',
        )}</ul>`,
      );
    }

    // if rev share changed.
    if (
      checkConditions(
        originalData?.fee_structure?.[type]?.rev_share,
        formData?.fee_structure?.[type]?.rev_share,
      )
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to change the Monthly Revenue share to  <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${displayNumber(
      formData?.fee_structure?.[type]?.rev_share || 0,
    )}%</span> This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>`,
      );
    }

    if (
      formData?.fee_structure?.[type]?.threshold_type === 'None' &&
      originalData?.fee_structure?.[type]?.threshold_type !== 'None' &&
      originalData?.fee_structure?.[type]?.threshold_type !== null
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to change the Revenue Share Threshold  <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">None </span> This change is effective from ${
      formData?.fee_structure?.[type]?.effective_date
        ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
            'MM / DD / YYYY',
          )
        : defaultEffectivityDateText
    }.<br>`,
      );
    }
    const formDataFixedThresholdType =
      (formData?.fee_structure?.[type]?.threshold_type === 'Fixed' ||
        formData?.fee_structure?.[type]?.threshold_type === null) &&
      originalData?.fee_structure?.[type]?.sales_threshold;
    if (
      checkConditions(
        formDataFixedThresholdType,
        formData?.fee_structure?.[type]?.sales_threshold,
      ) &&
      formData?.fee_structure?.[type]?.threshold_type === 'Fixed'
    ) {
      fields.push(`• To The Service Order / Statement of Work is updated to change the fixed threshold to  <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${displayNumber(
        formData?.fee_structure?.[type]?.sales_threshold || 0,
      )}</span>  for managing ${type} account.  This change is effective from ${
        formData?.fee_structure?.[type]?.effective_date
          ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
              'MM / DD / YYYY',
            )
          : defaultEffectivityDateText
      }.<br>`);
    }

    if (
      (formData?.fee_structure?.[type]?.threshold_type === 'quarterly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.quarterly_rev_share || {},
          formData?.fee_structure?.[type]?.quarterly_rev_share || {},
        )) ||
      (formData?.fee_structure?.[type]?.threshold_type === 'monthly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.monthly_rev_share || {},
          formData?.fee_structure?.[type]?.monthly_rev_share || {},
        ))
    ) {
      fields.push(
        `
           • To The Service Order / Statement of Work is updated to change the <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${
      formData?.fee_structure?.[type]?.threshold_type === 'quarterly'
        ? 'quarterly'
        : 'monthly'
    } revenue share </span>invoice value for ${type} account.This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>  <ul style="list-style-type: circle;">${ShowAddendumRetainerThresholdValues(
          type,
          'quarterly_rev_share',
          'monthly_rev_share',
          'threshold_type',
        )}</ul>`,
      );
    }

    // if billing cap/minimum changed.

    if (
      checkConditions(
        originalData?.fee_structure?.[type]?.billing_cap,
        formData?.fee_structure?.[type]?.billing_cap,
      )
    ) {
      fields.push(
        `•  To The Service Order / Statement of Work is updated to change the  billing cap to <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${displayNumber(
          formData?.fee_structure?.[type]?.billing_cap || 0,
        )}</span> for managing ${type} account; This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>`,
      );
    }

    if (
      checkConditions(
        originalData?.fee_structure?.[type]?.billing_minimum,
        formData?.fee_structure?.[type]?.billing_minimum,
      )
    ) {
      fields.push(
        `• To The Service Order / Statement of Work is updated to change the billing minimum to <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;"> ${selectedCurrency}${displayNumber(
          formData?.fee_structure?.[type]?.billing_minimum || 0,
        )}</span> for managing ${type} account; This change is effective from ${
          formData?.fee_structure?.[type]?.effective_date
            ? dayjs(formData?.fee_structure?.[type]?.effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.<br>`,
      );
    }

    // if combined rev share is changed.

    if (
      originalData?.combined_rev_share !== formData?.combined_rev_share &&
      type === 'seller'
    ) {
      if (formData?.combined_rev_share) {
        fields.push(`• BBE is going to calculate the <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">Revenue Share by combining</span> the seller and vendor accounts sales 
        revenue. This is starting from 3rd of month.`);
      } else {
        fields.push(`• BBE is going to calculate the <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">Revenue Share</span> of the seller account sales <span style="font-weight: 800;
    font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">separately</span> 
         from the vendor accounts sales revenue for managing  ${selectedCurrency}${displayNumber(
          formData?.fee_structure?.[type]?.sales_threshold || 0,
        )}</span> account. This is starting from 3rd of every month.
`);
      }
    }

    // 2.1 If monthly services are added

    displayMontlyServiceAddendum(type, fields);

    return fields.length
      ? fields
          .toString()
          .replaceAll('>,<', '><')
          .replaceAll('.,', '.')
          .replaceAll(' ,', '')
          .replaceAll('•', '<br>&bull;')
          .replaceAll(', <br>', '<br>')
          .replaceAll(',<br>', '<br>')
          .replaceAll('</div>,', '</div>')
      : '';
  };

  const displayDspFeesForAddendum = () => {
    if (checkConditions(originalData?.dsp_fee, formData?.dsp_fee)) {
      if (!originalData?.dsp_fee) {
        return originalData?.dsp_fee !== formData?.dsp_fee
          ? `<li>Fee Budget from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
              originalData?.dsp_fee || 0,
            )}</span> to <span style="font-weight: 800;
          font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
              formData?.dsp_fee,
            )}</span></li>`
          : '';
      }
      return originalData?.dsp_fee !== formData?.dsp_fee
        ? `<li>Fee Budget from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${displayNumber(
            originalData?.dsp_fee || 0,
          )}</span> to <span style="font-weight: 800;
            font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${selectedCurrency}${
            formData?.dsp_fee ? displayNumber(formData?.dsp_fee) : 0
          }</span></li>`
        : '';
    }
    return '';
  };

  const renderAddendumAMCServices = (serviceData, flag) => {
    const returnData = [];
    const removedAMCServices = getAddedOrRemovedAMCServices(
      originalData?.amc_services,
      formData?.amc_services,
    );
    const existingServices = getAddedOrRemovedAMCServices(
      originalData?.amc_services,
      removedAMCServices,
    );

    returnData.push(
      `<div class="table-responsive" style="margin-top:10px;">
        <table class="contact-list" style="width: 100%; overflow:auto; border-collapse: collapse;">
          ${
            flag === 'added'
              ? existingServices?.length
                ? `<tr >
            <th style="text-align: left; border: 1px solid black;padding: 13px;">Existing Service</th>
            <th style="text-align: left; border: 1px solid black;padding: 13px;">Effective Date</th>
            <th style="text-align: left; border: 1px solid black;padding: 13px;">Service Fee</th>
          </tr>`
                : ''
              : `<tr >
            <th style="text-align: left; border: 1px solid black;padding: 13px;">Existing Service</th>
            <th style="text-align: left; border: 1px solid black;padding: 13px;">Service Fee</th>
          </tr>`
          }
            ${
              flag !== 'removed'
                ? mapMonthlyServices('amc_services', existingServices, flag)
                : ''
            }

             <tr >
           ${
             serviceData?.length && flag === 'added'
               ? ` <th style="text-align: left; border: 1px solid black;padding: 13px;">Additional Service</th>
            <th style="text-align: left; border: 1px solid black;padding: 13px;">Effective Date</th>

            <th style="text-align: left; border: 1px solid black;padding: 13px;">Service Fee</th>
          `
               : ''
           }
            ${mapMonthlyServices('amc_services', serviceData, flag)}

           </tr>
          ${
            flag === 'added'
              ? mapMonthlyServiceTotal(formData?.amc_services, '', '')
              : ''
          }
        </table>
      </div>`,
    );
    return returnData.length
      ? returnData.toString().replaceAll('>,<', '><')
      : '';
  };

  const nonAccountContractAddendum = () => {
    const fields = [];

    // If LISTING OPTIMIZATIONS (ASIN’S PER MONTH) changed
    if (
      checkConditions(
        originalData?.content_optimization,
        formData?.content_optimization,
      ) ||
      checkConditions(
        originalData?.design_optimization,
        formData?.design_optimization,
      ) ||
      checkConditions(
        originalData?.photography_optimization,
        formData?.photography_optimization,
      )
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to change the following <span style="${styleForChangedValues}">Listing Optimizations</span> (asin’s per month). This change is effective from ${
          formData?.listing_optimization_effective_date
            ? dayjs(formData?.listing_optimization_effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.
        <ol>
        ${renderListingOptimizationServices(
          'Copy Optimization',
          originalData?.content_optimization,
          formData?.content_optimization,
        )}
        ${renderListingOptimizationServices(
          'Design Optimization',
          originalData?.design_optimization,
          formData?.design_optimization,
        )}
        </ol>`,
      );
    }

    // If LISTING TRANSLATION changed
    if (
      checkConditions(
        originalData?.copy_listing_translation,
        formData?.copy_listing_translation,
      ) ||
      checkConditions(
        originalData?.creative_listing_translation,
        formData?.creative_listing_translation,
      )
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to change the following <span style="${styleForChangedValues}">Listing Translation</span>(asin’s per month). This change is effective from ${
          formData?.listing_translation_effective_date
            ? dayjs(formData?.listing_translation_effective_date).format(
                'MM / DD / YYYY',
              )
            : defaultEffectivityDateText
        }.
      <ol>
      ${renderListingOptimizationServices(
        'Copy Translation',
        originalData?.copy_listing_translation,
        formData?.copy_listing_translation,
      )}
      ${renderListingOptimizationServices(
        'Creative Translation',
        originalData?.creative_listing_translation,
        formData?.creative_listing_translation,
      )}
      </ol>`,
      );
    }
    // 1.2. If Standard services are Removed
    const removedStandardUsServices =
      originalData?.standard_us_services?.filter((obj) => {
        return !formData?.standard_us_services?.some((obj2) => {
          return obj?.name === obj2?.name;
        });
      });

    if (removedStandardUsServices && removedStandardUsServices?.length) {
      fields.push(
        `• The Service Order / Statement of Work is updated to remove the following <span style="${styleForChangedValues}">Standard Service(s)</span>. This change is effective on the addendum signature date.
        ${renderAddendumStandardUsServices(
          removedStandardUsServices,
          'removed',
        )}`,
      );
    }

    // if Statement Of Work are changed.
    // 1.1 If Standard services are Added

    const standardUsServicesUniqueResult =
      formData?.standard_us_services?.filter((obj) => {
        return !originalData?.standard_us_services?.some((obj2) => {
          return obj.name === obj2.name;
        });
      });

    if (
      standardUsServicesUniqueResult &&
      standardUsServicesUniqueResult?.length
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">Standard Service(s)</span>.
        ${renderAddendumStandardUsServices(
          standardUsServicesUniqueResult,
          'added',
        )}
        `,
      );
    }

    // If Standard non-us services are changed
    // for Non Us Services
    const saperateFormDataNonUsmarketplaces = (dataInfo) => {
      return (
        dataInfo?.length &&
        dataInfo
          .filter((item) => item.name === 'VAT Registration')
          .map((filteredItem) => filteredItem)
      );
    };

    const seperateVATFilingMarketplaces = (dataInfo) => {
      const marketplaces =
        dataInfo?.length &&
        dataInfo.filter(
          (item) =>
            item.name === 'VAT Filing' &&
            item.marketplace !== null &&
            item.frequency !== null,
        );

      // const result = [];
      // if (marketplaces?.length) {
      //   marketplaces.map((item) => {
      //     result.push(
      //       `${displayMarketplaceName(item.marketplace, 'vat', 'lhs')} (${
      //         item?.frequency
      //       })`,
      //     );
      //     return null;
      //   });
      // }
      return marketplaces?.length ? marketplaces : [];
    };

    const seperateFormDataInternationalNonUsServices = (dataInfo) => {
      return (
        dataInfo?.length &&
        dataInfo.filter((item) => item?.name?.includes('International'))
      );
    };

    const addedRemovedStandardServices = (data1, data2) => {
      return data1?.filter((item) => {
        return !data2.some((item1) => {
          return item?.marketplace === item1?.marketplace;
        });
      });
    };

    const uniqueInternationalNonUsServices = (data1, data2) =>
      data1?.filter((item) => {
        return !data2.some((item1) => {
          return item.name === item1.name;
        });
      });
    // 1. for Vat Regrestration
    const formDataNonUsmarketplaces =
      saperateFormDataNonUsmarketplaces(
        formData?.standard_non_us_services?.length &&
          formData?.standard_non_us_services,
      ) || [];

    const originalDataNonUsmarketplaces =
      saperateFormDataNonUsmarketplaces(
        originalData?.standard_non_us_services?.length &&
          originalData?.standard_non_us_services,
      ) || [];

    const uniqueNonUsServices = addedRemovedStandardServices(
      formDataNonUsmarketplaces,
      originalDataNonUsmarketplaces,
    );

    const removedNonUsServices = addedRemovedStandardServices(
      originalDataNonUsmarketplaces,
      formDataNonUsmarketplaces,
    );

    // 2. for Vat Filling
    const formDataVATFilingMarketplaces =
      seperateVATFilingMarketplaces(
        formData?.standard_non_us_services?.length &&
          formData?.standard_non_us_services,
      ) || [];

    const originalDataVATFilingMarketplaces =
      seperateVATFilingMarketplaces(
        originalData?.standard_non_us_services?.length &&
          originalData?.standard_non_us_services,
      ) || [];

    const addedVatFillingNonUsServices = addedRemovedStandardServices(
      formDataVATFilingMarketplaces,
      originalDataVATFilingMarketplaces,
    );
    const removedVatFillingNonUsServices = addedRemovedStandardServices(
      originalDataVATFilingMarketplaces,
      formDataVATFilingMarketplaces,
    );

    // 3. for international service
    const formDataInternationalNonUsServices =
      seperateFormDataInternationalNonUsServices(
        formData?.standard_non_us_services?.length &&
          formData?.standard_non_us_services,
      ) || [];

    const originalDataInternationalNonUsServices =
      seperateFormDataInternationalNonUsServices(
        originalData?.standard_non_us_services?.length &&
          originalData?.standard_non_us_services,
      ) || [];

    const addedInternationalNonUsServices = uniqueInternationalNonUsServices(
      formDataInternationalNonUsServices,
      originalDataInternationalNonUsServices,
    );
    const remmovedInternationalNonUsServices = uniqueInternationalNonUsServices(
      originalDataInternationalNonUsServices,
      formDataInternationalNonUsServices,
    );

    // DSP service length (MONTHS), field,  in DSP Advertising
    const showDspLength = () => {
      if (formData?.dsp_length?.value) {
        return `${
          originalData?.dsp_length?.value !== formData?.dsp_length?.value
            ? `<li>DSP service length from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${
          originalData?.dsp_length?.label || originalData?.dsp_length || 0
        }</span> to <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${
          formData?.dsp_length?.label || formData?.dsp_length || 0
        }</span></li>`
            : ''
        }`;
      }
      return `${
        originalData?.dsp_length?.value !== formData?.dsp_length
          ? `<li>DSP service length from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${
          originalData?.dsp_length?.label || originalData?.dsp_length || 0
        }</span> to <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${
          formData?.dsp_length || formData?.dsp_length || 0
        }</span></li>`
          : ''
      }`;
    };

    const addedAMCServices = getAddedOrRemovedAMCServices(
      formData?.amc_services,
      originalData?.amc_services,
    );
    const removedAMCServices = getAddedOrRemovedAMCServices(
      originalData?.amc_services,
      formData?.amc_services,
    );

    // DSP Advertising service and AMC services
    if (
      (formData?.is_dsp_advertising &&
        formData?.is_dsp_advertising !== originalData?.is_dsp_advertising) ||
      addedAMCServices?.length ||
      discountData?.find(
        (item) =>
          item?.is_addendum_discount_changed &&
          item?.amount !== null &&
          item?.type !== null,
      )
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">Advertising Service(s)</span>.
        ${renderAddendumAMCServices(addedAMCServices, 'added')}
        `,
      );
    }
    if (
      (!formData?.is_dsp_advertising &&
        formData?.is_dsp_advertising !== originalData?.is_dsp_advertising) ||
      removedAMCServices?.length
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to remove the following <span style="${styleForChangedValues}">Advertising Service(s)</span>.
        ${renderAddendumAMCServices(removedAMCServices, 'removed')}
        `,
      );
    }

    const setDspAdvertisingEffectiveDate = () => {
      // const service = formData?.additional_monthly_services?.find(
      //   (item) =>
      //     item?.name === 'DSP Advertising' ||
      //     item?.service?.name === 'DSP Advertising',
      // );
      if (formData?.dsp_start_date) {
        return dayjs(formData?.dsp_start_date).format('MM / DD / YYYY');
      }
      return defaultEffectivityDateText;
    };

    const mapVatRegistrationTable = (servicesData, type, tableType) => {
      const field = [];
      servicesData.map((item) => {
        field.push(`<tr>
                <td style="border: 1px solid black;padding: 13px;">${
                  type === 'internationService' ? item.name : ''
                }
                 ${
                   type === 'vatRegistration'
                     ? `VAT Registration: ${displayMarketplaceName(
                         item?.marketplace,
                         'vat',
                         'lhs',
                       )}`
                     : ''
                 } 
                 ${
                   type === 'vatFiling'
                     ? `VAT Filing: ${displayMarketplaceName(
                         item?.marketplace,
                         'vat',
                         'lhs',
                       )} (${item?.frequency})`
                     : ''
                 } 
                </td>
                 ${
                   tableType === 'added'
                     ? `<td style="border: 1px solid black;padding: 13px;">${
                         item?.id && item?.effective_date
                           ? item?.effective_date
                           : item?.id && !item?.effective_date
                           ? formData?.contract_signed_date
                           : defaultEffectivityDateText
                       } </td>`
                     : ''
                 }
                </tr>`);
        return null;
      });
      return field;
    };

    const renderNonUSStandardServices = (
      vatReg,
      vatFiling,
      internationService,
      tableType,
    ) => {
      const returnData = [];
      returnData.push(
        `<div class="table-responsive" style="margin-top:10px;">
        <table class=" contact-list " style="width: 100%; overflow:auto; border-collapse: collapse;">
           <tr>
            <th style=" text-align: left; border: 1px solid black; padding: 13px;">Services</th>
           ${
             tableType === 'added'
               ? `<th style=" text-align: left; border: 1px solid black; padding: 13px;">Effective Date</th>`
               : ''
           }
          </tr>
               
          ${
            vatReg?.length
              ? mapVatRegistrationTable(vatReg, 'vatRegistration', tableType)
              : ''
          }
          ${
            vatFiling?.length
              ? mapVatRegistrationTable(vatFiling, 'vatFiling', tableType)
              : ''
          }
          ${
            internationService?.length
              ? mapVatRegistrationTable(
                  internationService,
                  'internationService',
                  tableType,
                )
              : ''
          }
          </table>
          </div>`,
      );
      return returnData;
    };
    const showDspAgencyFees = (param1, param2) => {
      if (param1?.value) {
        return `${(param2?.value !== param1?.value
          ? `<li>Agency Fee from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${
          param2?.value >= 0 ? `${param2?.value}%` : param2 ? `${param2}%` : 0

          // `${param2?.value}%` || `${param2}%` || 0
        }</span> to <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${
          param1?.value >= 0 ? `${param1?.value}%` : param1 ? `${param1}%` : 0
        }</span></li>`
          : ''
        ).replaceAll('[object Object]', 0)}`;
      }
      if (!param1?.value) {
        return `${((!param2?.value ? 0 : param2?.value) !==
        (!param1 ? 0 : param1)
          ? `<li>Agency Fee from <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${
          param2?.value ? `${param2?.value}%` : param2 ? `${param2}%` : 0
          // `${param2?.value}%` || `${param2}%` || 0
        }</span> to <span style="font-weight: 800;
        font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">${
          `${param1}%` ? `${param1}%` : 0
        }</span></li>`
          : ''
        ).replaceAll('[object Object]', 0)}`;
      }
      return '';
    };

    // Display result if Standard non us services are added
    if (
      uniqueNonUsServices?.length ||
      addedVatFillingNonUsServices?.length ||
      addedInternationalNonUsServices?.length
    ) {
      fields.push(`• The Service Order / Statement of Work is updated to add the following <span style="${styleForChangedValues}">Standard Services</span>
      (for non-us marketplaces).
      ${renderNonUSStandardServices(
        uniqueNonUsServices,
        addedVatFillingNonUsServices,
        addedInternationalNonUsServices,
        'added',
      )}
      `);
    }

    // Display result if standard non us services are removed
    if (
      removedNonUsServices?.length ||
      removedVatFillingNonUsServices?.length ||
      remmovedInternationalNonUsServices?.length
    ) {
      fields.push(`•  The Service Order / Statement of Work is updated to remove the following <span style="${styleForChangedValues}">Standard Services</span> (for non-us marketplaces). This change is effective on the addendum signature date.
       ${renderNonUSStandardServices(
         removedNonUsServices,
         removedVatFillingNonUsServices,
         remmovedInternationalNonUsServices,
         'removed',
       )}
    `);
    }

    // if dsp Adv.
    if (
      originalData?.dsp_fee !== formData?.dsp_fee ||
      (originalData?.dsp_length?.value
        ? originalData?.dsp_length?.value
        : 0) !==
        (formData?.dsp_length?.value
          ? formData?.dsp_length?.value
          : formData?.dsp_length
          ? formData?.dsp_length
          : 0) ||
      (originalData?.agency_fee?.value
        ? originalData?.agency_fee?.value
        : 0) !==
        (formData?.agency_fee?.value
          ? formData?.agency_fee?.value
          : formData?.agency_fee
          ? formData?.agency_fee
          : 0)
    ) {
      fields.push(
        `• The Service Order / Statement of Work is updated to add the following <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">DSP Advertising</span>, for 
        <ol> ${displayDspFeesForAddendum()}
        ${
          formData?.contract_type?.toLowerCase()?.includes('recurring')
            ? // dsp service length (MONTHS), field,  in DSP Advertising
              showDspLength(formData?.dsp_fee, originalData?.dsp_fee)
            : ''
        }
       ${showDspAgencyFees(
         formData?.agency_fee,
         originalData?.agency_fee,
       )}</ol>  This change is effective from ${setDspAdvertisingEffectiveDate()}.<br>.
        `,
      );
    }
    // if dsp marketplaces
    const serviceAddedResult = getDifference(
      formData?.dsp_marketplaces,
      originalData?.dsp_marketplaces,
      'marketplace',
    );
    const serviceRemovedResult = getDifference(
      originalData?.dsp_marketplaces,
      formData?.dsp_marketplaces,
      'marketplace',
    );

    if (serviceAddedResult?.length || serviceRemovedResult?.length) {
      fields.push(
        `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center;">
        <span style="font-weight: 800; font-family: Helvetica-bold;">DSP Marketplaces for Amazon ${formData?.seller_type?.label} Account</span></div>`,
      );
    }
    if (serviceAddedResult?.length) {
      fields.push(` • The Service Order / Statement of Work is updated to add the following <span style="font-weight: 800;
      font-family: Helvetica-bold; background: #ffe5df;padding: 4px 9px;">DSP marketplaces</span>, This change is effective from ${setDspAdvertisingEffectiveDate()}.<br>e.
        <ol>
         ${displayMonthlyServices(serviceAddedResult, 'dsp')}
           </ol>`);
    }

    if (serviceRemovedResult?.length) {
      fields.push(
        `• The Service Order / Statement of Work is updated to remove the following <span style="${styleForChangedValues}">DSP marketplaces</span>, This change is effective on the addendum signature date.
         <ol>
        ${displayMonthlyServices(serviceRemovedResult, 'dsp')} </ol>
        
        `,
      );
    }
    return fields.length
      ? fields
          .toString()
          .replaceAll('>,<', '><')
          .replaceAll('.,', '.')
          .replaceAll(' ,', '')
          .replaceAll('•', '<br>&bull;')
          .replaceAll(', <br>', '<br>')
          .replaceAll(',<br>', '<br>')
      : '';
  };

  const contractAddendumChanges = () => {
    if (contractAddendumDetails.notActive) {
      if (formData?.seller_type?.label === 'Hybrid') {
        return `${accountContractAddendum('seller')}
          ${accountContractAddendum('vendor')} ${nonAccountContractAddendum()}`;
      }
      if (formData?.seller_type?.label === 'Seller') {
        return `${accountContractAddendum(
          'seller',
        )} ${nonAccountContractAddendum()}`;
      }
      if (formData?.seller_type?.label === 'Vendor') {
        return `${accountContractAddendum(
          'vendor',
        )} ${nonAccountContractAddendum()}`;
      }
    }
    return '';
  };

  // display bbe logo
  const displayBBELogo = () => {
    // 150px width of bbe logo as mentioned in PDV-8247
    return `<img style="width:150px; height:auto;" src=${logoURL} alt='bbe-logo'/>`;
  };
  const showAMCServiceTable = () => {
    if (formData?.contract_type?.includes('dsp')) {
      if (formData?.is_amc_management) {
        return `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center">
      <span style="font-weight: 800; font-family: Helvetica-bold;">Additional Advertising Services </span>
      <br> The following additional monthly services will be provided to Client in addition to the Monthly Retainer.</div><br>
      <div class="table-responsive">
        <table class="contact-list "style="width: 100%;
                      border-collapse: collapse;">
          <tr>
            <th style="text-align: left; border: 1px solid black;
                      padding: 13px;">Service</th>
            <th style="text-align: left; border: 1px solid black;
                      padding: 13px;">Service Fee</th>
          </tr>
            ${mapMonthlyServices('amc_services', formData?.amc_services)}
          ${mapMonthlyServiceTotal(formData?.amc_services, '', '')}

        </table>
      </div>`;
      }
      return '';
    }
    return '';
  };

  const createAgreementDoc = () => {
    const agreementStartDate = formData?.change_agreement_start_date;
    const changeAgreementStartDate =
      agreementStartDate === null || agreementStartDate === undefined
        ? false
        : agreementStartDate;

    const addendumStartDate = formData?.change_addendum_start_date;
    const changeAddendumStartDate =
      addendumStartDate === null || addendumStartDate === undefined
        ? false
        : addendumStartDate;

    setContractDesignLoader(true);
    const serviceData = getAgreementAccorType(0);
    const agreementData =
      serviceData &&
      serviceData
        .replace('BBE_LOGO', displayBBELogo())
        .replace(
          'CUSTOMER_NAME',
          mapDefaultValues('company_name', 'Customer Name'),
        )
        .replace(
          '{{Signdate}}',
          changeAgreementStartDate
            ? mapDefaultValues('start_date', 'Start Date')
            : '{{Signdate}}',
        )
        .replace('CUSTOMER_ADDRESS', mapDefaultValues('address', 'Address, '))
        .replace(
          'AGREEMENT_LENGTH',
          mapDefaultValues('length', 'Contract Length'),
        )
        .replace('ONE_TIME_SERVICE_TABLE', showOneTimeTable())
        .replace(
          'ADDITIONAL_ONE_TIME_SERVICES_TOTAL',
          `${mapServiceTotal('additional_one_time_services')}`,
        )
        .replace('LANGUAGE_ACCORDING_TO_CONTRACT_TYPE', `${mapLanguage()}`);

    const agreementSignatureData = AgreementSign.replace(
      'CUSTOMER_NAME',
      mapDefaultValues('company_name', 'Customer Name'),
    )
      .replace('CUSTOMER_ADDRESS', mapDefaultValues('address', 'Address, '))
      .replace('BBE_DATE', mapDefaultValues('current_date', 'Current Date'))
      .replace('THAD_SIGN', mapThadSignImg())
      .replace(
        'PRINTED_NAME',
        `${thadDetails?.firstName} ${thadDetails?.lastName} `,
      )
      .replace('TITLE', `${thadDetails?.title}`);

    const statmentData =
      data?.statement_of_work &&
      data?.statement_of_work[0]
        .replace('BBE_LOGO', displayBBELogo())
        .replace(
          'CUSTOMER_NAME',
          mapDefaultValues('company_name', 'Customer Name'),
        )
        .replaceAll(
          '{{Signdate}}',
          changeAgreementStartDate
            ? mapDefaultValues('start_date', 'Start Date')
            : '{{Signdate}}',
        )
        .replace(
          'SELLER_TYPE',
          mapDefaultValues('seller_type', 'Amazon Account Type'),
        )
        .replace('BILLING_CAP_AMOUNT', showBillingCap())
        .replace('REV_SHARE_TABLE', showRevTable())
        .replace('REVENUE_SHARE', mapDefaultValues('rev_share', 'Rev Share'))
        .replace(
          'REV_THRESHOLD',
          mapDefaultValues('threshold_type', 'Rev Threshold'),
        )
        .replace(
          'AMAZON_ACCOUNT_TYPE',
          mapDefaultValues('seller_type', 'Seller Type', 'amazon'),
        )
        .replace(
          'ACCOUNT_TYPE',
          mapDefaultValues('seller_type', 'Amazon Account Type'),
        )
        .replace(
          'PRIMARY_MARKETPLACE',
          mapDefaultValues('primary_marketplace', 'Primary Marketplace'),
        )
        .replace(
          'MAP_MONTHLY_SERVICES',
          showMonthlyServiceTablesAccordingToAccType(),
        )
        .replace('MAP_AMC_MONTHLY_SERVICES', showAMCServicesTable())
        .replace('ONE_TIME_SERVICES', showOneTimeServiceTable())
        .replace('MAP_STANDARD_SERVICE_TABLE', showStandardServicesTable())
        .replace(
          'ADDITIONAL_SERVICES_NOT_INCLUDED',
          showNotIncludedServicesTable(),
        )
        .replace(
          'MAP_STANDARD_SERV_FOR_NON_US_MARKETPLACES_TABLE',
          showStandardServicesNONUSMarketplacesTable(),
        )
        .replace(
          'BILLING_CAP_AMOUNT',
          mapDefaultValues('billing_cap', 'Billing Cap', 'number-currency'),
        );

    const dspAddendum = showSection?.dspAddendum
      ? data?.dsp_addendum &&
        data?.dsp_addendum[0]
          .replace('BBE_LOGO', displayBBELogo())
          .replace(
            'CUSTOMER_NAME',
            mapDefaultValues('company_name', 'Customer Name'),
          )
          .replace(
            '{{Signdate}}',
            changeAgreementStartDate
              ? mapDefaultValues('start_date', 'Start Date')
              : '{{Signdate}}',
          )
          .replace(
            'DSP_DETAIL_TABLE',
            `<table class="contact-list " style="width: 100%;
                      border-collapse: collapse;"><tr><th style="text-align: left; border: 1px solid black;
                      padding: 13px;">Start Date</th>
                      <th style="text-align: left; border: 1px solid black;
                      padding: 13px;">DSP Service Length</th>
                 ${
                   formData?.is_dynamic_dsp_fee
                     ? `
                       <th
                         style="text-align: left; border: 1px solid black;padding: 13px;">
    ${
      !formData?.changed_dsp_start_date
        ? dayjs(firstMonthDate).format('MMMM YYYY')
        : returnDspMonthlyDates(new Date(firstMonthDate), 1)
    }<br /> Monthly Ad Budget
                       </th>
                       <th
                         style="text-align: left; border: 1px solid black;
                  padding: 13px;">
                            ${
                              !formData?.changed_dsp_start_date
                                ? dayjs(secondMonthDate).format('MMMM YYYY')
                                : returnDspMonthlyDates(
                                    new Date(secondMonthDate),
                                    1,
                                  )
                            }<br /> Monthly Ad Budget
                       </th>
                       <th
                         style="text-align: left; border: 1px solid black;
                  padding: 13px;">
                           ${
                             !formData?.changed_dsp_start_date
                               ? dayjs(thirdMonthDate).format('MMMM YYYY')
                               : returnDspMonthlyDates(
                                   new Date(thirdMonthDate),
                                   1,
                                 )
                           } <br /> Monthly Ad Budget
                       </th>
                     `
                     : `<th
                       style="text-align: left; border: 1px solid black;
                  padding: 13px;">
                       Monthly Ad Budget
                     </th>`
                 }  ${
              formData?.changed_dsp_start_date
                ? `<th style="text-align: left; border: 1px solid black;
    padding: 13px;">One Time Ad Budget</th>`
                : ''
            }</tr>${mapDspDetails()
              .replaceAll(
                'DSP_FEE',
                mapDefaultValues('dsp_fee', 'Dsp Fee', 'number-currency'),
              )
              .replaceAll(
                'SECOND_DSP',
                mapDefaultValues(
                  'dsp_fee_second',
                  'Dsp Fee',
                  'number-currency',
                ),
              )
              .replaceAll(
                'THIRD_DSP',
                mapDefaultValues('dsp_fee_third', 'Dsp Fee', 'number-currency'),
              )
              .replace(
                'DSP_ONE_TIME_FEE',
                mapDefaultValues(
                  'dsp_one_time_fee',
                  'Dsp Fee',
                  'number-currency',
                ),
              )}</table>`,
          )
          .replace(
            'BUDGET_BREAKDOWN_TABLE',
            `${mapBudgetBreakdownTable()
              .replaceAll(
                'DSP_FEE',
                mapDefaultValues('dsp_fee', 'Dsp Fee', 'number-currency'),
              )
              .replaceAll(
                'SECOND_DSP',
                mapDefaultValues(
                  'dsp_fee_second',
                  'Dsp Fee',
                  'number-currency',
                ),
              )
              .replaceAll(
                'THIRD_DSP',
                mapDefaultValues('dsp_fee_third', 'Dsp Fee', 'number-currency'),
              )
              .replace(
                'DSP_ONE_TIME_FEE',
                mapDefaultValues(
                  'dsp_one_time_fee',
                  'Dsp Fee',
                  'number-currency',
                ),
              )}
              ${displayMarketplacesTable().replaceAll(' ,', '')}`,
          )
          .replaceAll('CONTRACT_LENGTH', returnDspMonthAndDays(false))
          .replace('AMC_SERVICE_TABLE', showAMCServiceTable())
          .replace('NO_OF_DAYS_BASED_ON_DATE', returnDspMonthAndDays(true))
      : '';

    const dspAddendumSignature = showSection?.dspAddendum
      ? AddendumSign.replace(
          'CUSTOMER_NAME',
          mapDefaultValues('company_name', 'Customer Name'),
        )
          .replace('BBE_DATE', mapDefaultValues('current_date', 'Current Date'))
          .replace('THAD_SIGN', mapThadSignImg())
          .replace(
            'PRINTED_NAME',
            `${thadDetails?.firstName} ${thadDetails?.lastName} `,
          )
          .replace('CONTRACT_ADDENDUM_CHANGES', '')
          .replace('TITLE', `${thadDetails?.title}`)
      : '';

    const addendumData = data?.addendum?.[0]
      .replace('BBE_LOGO', displayBBELogo())
      .replace(
        'CUSTOMER_NAME',
        mapDefaultValues('company_name', 'Customer Name'),
      )
      .replaceAll(
        'AGREEMENT_DATE',
        mapDefaultValues('start_date', 'Start Date'),
      )
      .replace(
        '{{Signdate}}',
        changeAddendumStartDate
          ? mapDefaultValues('addendum_start_date', 'Start Date')
          : '{{Signdate}}',
      )
      .replace(
        '<h3 style="BREAK_PAGE;',
        `${
          contractAddendumDetails?.notActive
            ? '<h3 style="BREAK_PAGE;'
            : '<h3 style="page-break-before: always;'
        }`,
      );

    const newAddendumAddedData = newAddendumData?.addendum
      ? newAddendumData.addendum.replaceAll('<p>', '<p style="margin:0">')
      : '';

    const addendumSignatureData = AddendumSign.replace(
      'CUSTOMER_NAME',
      mapDefaultValues('company_name', 'Customer Name'),
    )
      .replace('BBE_DATE', mapDefaultValues('current_date', 'Current Date'))
      .replace('THAD_SIGN', mapThadSignImg())
      .replace(
        'PRINTED_NAME',
        `${thadDetails?.firstName} ${thadDetails?.lastName} `,
      )
      .replace(
        'CONTRACT_ADDENDUM_CHANGES',
        contractAddendumChanges('addendum_changes'),
      )
      .replace('TITLE', `${thadDetails?.title}`);

    const finalContractAgreement = `<meta charset="utf-8" />${agreementData} ${agreementSignatureData} ${
      details?.contract_type?.toLowerCase().includes('one') ||
      details?.contract_type?.toLowerCase().includes('dsp')
        ? ''
        : statmentData
    } ${
      details?.contract_type?.toLowerCase().includes('one') ? '' : dspAddendum
    } ${
      details?.contract_type?.toLowerCase().includes('one')
        ? ''
        : dspAddendumSignature
    } ${
      newAddendumAddedData
        ? newAddendumAddedData.length <= 24
          ? ''
          : addendumData + newAddendumAddedData + addendumSignatureData
        : ''
    } `;

    const finalAddendumAgreement = `<meta charset="utf-8" />${
      newAddendumAddedData
        ? addendumData + newAddendumAddedData + addendumSignatureData
        : addendumData + addendumSignatureData
    }`;

    setPDFData(
      contractAddendumDetails.notActive
        ? finalAddendumAgreement
        : finalContractAgreement,
    );

    const localAddendumDetails = {
      contract_addendum: contractAddendumDetails?.id,
      contract_addendum_data: finalAddendumAgreement
        .replaceAll('CUSTOMER_PRINTED_NAME', '')
        .replace('CUSTOMER_ROLE', ''),
      // .replaceAll('€', '&#8364;')
      // .replaceAll('£', '&#163;'),
    };
    const contractData = {
      contract: detailsId,
      contract_data: finalContractAgreement
        .replaceAll('CUSTOMER_PRINTED_NAME', '')
        .replace('CUSTOMER_ROLE', ''),
      // .replaceAll('€', '&#8364;')
      // .replaceAll('£', '&#163;'),
    };

    createContractDesign(
      contractAddendumDetails.notActive ? localAddendumDetails : contractData,
    ).then(() => {
      setContractDesignLoader(false);
    });
  };

  useEffect(() => {
    if (downloadApiCall) {
      createAgreementDoc();
      setDownloadApiCall(false);
    }
  }, [downloadApiCall]);

  useEffect(() => {
    createAgreementDoc();
  }, [helloSignEnterDateTextFlag, formData]);

  useEffect(() => {
    const sectionFlag = { ...showSection };

    if (details?.additional_monthly_services?.length) {
      if (contractAddendumDetails?.notActive) {
        if (addendumDetails?.additional_monthly_services) {
          setMonthlyAdditionalServices({
            create: [...addendumDetails?.additional_monthly_services],
            delete: [],
          });
        } else {
          setMonthlyAdditionalServices({
            create: [],
            delete: [],
          });
        }
      } else {
        setMonthlyAdditionalServices({
          create: [...details.additional_monthly_services],
          delete: [],
        });
      }
    } else {
      setMonthlyAdditionalServices({
        create: [],
        delete: [],
      });
    }
    if (details?.additional_marketplaces?.length) {
      if (
        contractAddendumDetails?.notActive &&
        addendumDetails?.additional_marketplaces
      ) {
        setAdditionalMarketplace({
          create: [...addendumDetails.additional_marketplaces],
          delete: [],
        });
      } else {
        setAdditionalMarketplace({
          create: [...details.additional_marketplaces],
          delete: [],
        });
      }
    } else {
      setAdditionalMarketplace({
        create: [],
        delete: [],
      });
    }
    if (details?.additional_one_time_services?.length) {
      setAdditionalOnetimeServices({
        create: [...details.additional_one_time_services],
        delete: [],
      });
    } else {
      setAdditionalOnetimeServices({
        create: [],
        delete: [],
      });
    }

    if (primaryMarketplaceName) {
      const marketplacesWithoutPrimary = marketplacesResult.filter(
        (op) => op.value !== details.primary_marketplace.name,
      );
      setAdditionalMarketplaces(marketplacesWithoutPrimary);
      setDspAdditionalMarketplaces(marketplacesResult);
    } else {
      setAdditionalMarketplaces(marketplacesResult);
      setDspAdditionalMarketplaces(marketplacesResult);
    }

    if (details?.additional_marketplaces) {
      setMarketPlaces(
        marketplacesResult.filter(
          (choice) =>
            !(details?.additional_marketplaces).some(
              (item) => item.name === choice.value,
            ),
        ),
      );
    } else {
      setMarketPlaces(marketplacesResult);
    }
    if (details?.additional_one_time_services) {
      fetchUncommonOptions(
        oneTimeService,
        details.additional_one_time_services,
        'one_time_service',
      );
    }
    if (details?.additional_monthly_services) {
      fetchUncommonOptions(
        details?.seller_type?.value === 'Hybrid'
          ? monthlyService?.Seller
          : monthlyService?.[details?.seller_type?.value],
        details.additional_monthly_services,
        'monthly_service',
      );
    }

    if (
      !details?.additional_one_time_services ||
      !details?.additional_monthly_services
    ) {
      fetchUncommonOptions(
        oneTimeService,
        details?.additional_one_time_services,
        'one_time_service',
      );

      fetchUncommonOptions(
        details?.seller_type?.value === 'Hybrid'
          ? monthlyService?.Seller
          : monthlyService?.[details?.seller_type?.value],
        details?.additional_monthly_services,
        'monthly_service',
      );
    }

    const contractToFilter = contractAddendumDetails?.notActive
      ? addendumDetails
      : details;
    const checkDspAdevertisingisSelected = contractToFilter?.is_dsp_advertising;

    const flagDSPAdevertising = checkDspAdevertisingisSelected;

    if (!contractTypeOne && !contractTypeDsp && flagDSPAdevertising) {
      sectionFlag.dspAddendum = true;
    } else {
      sectionFlag.dspAddendum = false;
    }

    if (details?.contract_type?.toLowerCase().includes('dsp')) {
      sectionFlag.dspAddendum = true;
    }

    setTimeout(() => {
      setIsDocRendered(true);
    }, 7000);

    setShowCollpase(sectionFlag);
  }, [details, monthlyService, addendumDetails]);

  useEffect(() => {
    const sectionFlag = { ...showSection };
    if (newAddendumData?.id) {
      if (newAddendumData.addendum?.length <= 7) {
        sectionFlag.addendum = false;
      } else {
        sectionFlag.addendum = true;
      }
    }
    setShowCollpase(sectionFlag);
  }, [newAddendumData]);

  const updateDiscount = (totalFees, type, typeOfService = null) => {
    if (Math.sign(totalFees.monthlyTotal) === -1) {
      const contractPostData = {
        contract: details?.id,
        account_type: type,
        service_type: 'monthly service',
        type: null,
        amount: null,
      };
      const addendumPostData = {
        contract_addendum: contractAddendumDetails?.id,
        account_type: type,
        service_type: 'monthly service',
        type: null,
        amount: null,
      };
      if (typeOfService === 'amc_services') {
        contractPostData.is_amc_service_discount = true;
        addendumPostData.is_amc_service_discount = true;
      }

      const result =
        discountData?.length &&
        discountData.filter(
          (item) =>
            item.service_type === 'monthly service' &&
            item.account_type === type,
        );
      if (!result?.[0]?.id) {
        addendumPostData.is_addendum_discount_changed = true;
      }
      saveDiscount(
        result?.[0]?.id,
        contractAddendumDetails?.notActive
          ? addendumPostData
          : contractPostData,
      );
    }
  };

  const handleDiscount = (typeOfService = null) => {
    if (typeOfService === 'amc_services') {
      const totalFees = calculateTotalFee(
        'monthly',
        formData?.amc_services,
        '',
        '',
      );
      updateDiscount(totalFees, '', typeOfService);
    } else {
      if (accountType === 'Seller') {
        const sellerResult = getServicesAccordingToAccType(
          formData?.additional_monthly_services,
          'Seller',
        );
        const marketplaceResult = getServicesAccordingToAccType(
          formData?.additional_marketplaces,
          'Seller',
        );
        const totalFees = calculateTotalFee(
          'monthly',
          sellerResult,
          marketplaceResult,
          accountType,
        );

        updateDiscount(totalFees, accountType);
      }

      if (accountType === 'Vendor') {
        const vendorResult = getServicesAccordingToAccType(
          formData?.additional_monthly_services,
          'Vendor',
        );
        const marketplaceResult = getServicesAccordingToAccType(
          formData?.additional_marketplaces,
          'Vendor',
        );
        const totalFees = calculateTotalFee(
          'monthly',
          vendorResult,
          marketplaceResult,
          accountType,
        );
        updateDiscount(totalFees, accountType);
      }

      if (accountType === 'Hybrid') {
        const sellerResult = getServicesAccordingToAccType(
          formData?.additional_monthly_services,
          'Seller',
        );

        const vendorResult = getServicesAccordingToAccType(
          formData?.additional_monthly_services,
          'Vendor',
        );

        const sellerMarketplaceResult = getServicesAccordingToAccType(
          formData?.additional_marketplaces,
          'Seller',
        );

        const vendorMarketplaceResult = getServicesAccordingToAccType(
          formData?.additional_marketplaces,
          'Vendor',
        );

        const sellerTotalFees = calculateTotalFee(
          'monthly',
          sellerResult,
          sellerMarketplaceResult,
          'Seller',
        );
        updateDiscount(sellerTotalFees, 'Seller');

        const vendorTotalFees = calculateTotalFee(
          'monthly',
          vendorResult,
          vendorMarketplaceResult,
          'Vendor',
        );

        updateDiscount(vendorTotalFees, 'Vendor');
      }
    }
  };

  const getUpdatedFeeStructure = (type) => {
    const updatedFields = Object.keys(formData?.fee_structure?.[type]);
    const result = {};
    if (formData?.fee_structure?.[type].vendor_billing_report) {
      result.vendor_billing_report =
        formData?.fee_structure?.[type].vendor_billing_report;
    }
    if (formData?.fee_structure?.[type]?.vendor_same_as_seller) {
      result.vendor_same_as_seller =
        formData?.fee_structure?.[type].vendor_same_as_seller;
    }
    if (!formData?.fee_structure?.[type]?.vendor_same_as_seller) {
      result.vendor_same_as_seller =
        formData?.fee_structure?.[type].vendor_same_as_seller;
    }
    if (updatedFields.includes('fee_type')) {
      if (
        formData?.combined_rev_share &&
        formData?.seller_type?.label === 'Hybrid'
      ) {
        result.rev_share = formData?.fee_structure?.seller?.rev_share;
        result.sales_threshold =
          formData?.fee_structure?.seller?.sales_threshold;
        result.threshold_type = formData?.fee_structure?.seller?.threshold_type;
      }
      const selectedFeeType = formData?.fee_structure?.[type].fee_type;
      if (selectedFeeType === null) {
        result.fee_type = selectedFeeType;
      }
      if (selectedFeeType === 'Retainer Only') {
        result.fee_type = selectedFeeType;
        const selectedRetainerType =
          formData?.fee_structure?.[type].retainer_type;
        result.retainer_type = selectedRetainerType;
        if (selectedRetainerType === 'Fixed' || !selectedRetainerType) {
          result.monthly_retainer =
            formData?.fee_structure?.[type].monthly_retainer;
          result.retainer_type = 'Fixed';
        }
        if (selectedRetainerType === 'quarterly') {
          result.quarterly_monthly_retainer = {
            ...formData?.fee_structure?.[type].quarterly_monthly_retainer,
          };
        }
        if (selectedRetainerType === 'monthly') {
          result.monthly_monthly_retainer = {
            ...formData?.fee_structure?.[type].monthly_monthly_retainer,
          };
        }
      }

      if (selectedFeeType === 'Revenue Share Only') {
        const selectedThresholdType =
          formData?.fee_structure?.[type].threshold_type;
        result.fee_type = selectedFeeType;
        result.rev_share = formData?.fee_structure?.[type].rev_share;
        result.billing_cap = formData?.fee_structure?.[type].billing_cap;
        result.billing_minimum =
          formData?.fee_structure?.[type].billing_minimum;
        result.threshold_type = selectedThresholdType;
        if (selectedThresholdType === 'Fixed') {
          result.sales_threshold =
            formData?.fee_structure?.[type].sales_threshold;
        }

        if (selectedThresholdType === 'quarterly') {
          result.quarterly_rev_share = {
            ...formData?.fee_structure?.[type].quarterly_rev_share,
          };
        }
        if (selectedThresholdType === 'monthly') {
          result.monthly_rev_share = {
            ...formData?.fee_structure?.[type].monthly_rev_share,
          };
        }
      }
      if (selectedFeeType === 'Retainer + % Rev Share') {
        result.fee_type = selectedFeeType;
        const selectedThresholdType =
          formData?.fee_structure?.[type].threshold_type;
        const selectedRetainerType =
          formData?.fee_structure?.[type].retainer_type;
        result.retainer_type = selectedRetainerType;
        result.rev_share = formData?.fee_structure?.[type].rev_share;
        result.billing_cap = formData?.fee_structure?.[type].billing_cap;

        if (selectedRetainerType === 'Fixed' || !selectedRetainerType) {
          result.monthly_retainer =
            formData?.fee_structure?.[type].monthly_retainer;
          result.retainer_type = 'Fixed';
        }
        if (selectedRetainerType === 'quarterly') {
          result.quarterly_monthly_retainer = {
            ...formData?.fee_structure?.[type].quarterly_monthly_retainer,
          };
        }
        if (selectedRetainerType === 'monthly') {
          result.monthly_monthly_retainer = {
            ...formData?.fee_structure?.[type].monthly_monthly_retainer,
          };
        }

        result.threshold_type = selectedThresholdType;
        if (selectedThresholdType === 'Fixed') {
          result.sales_threshold =
            formData?.fee_structure?.[type].sales_threshold;
        }

        if (selectedThresholdType === 'quarterly') {
          result.quarterly_rev_share = {
            ...formData?.fee_structure?.[type].quarterly_rev_share,
          };
        }
        if (selectedThresholdType === 'monthly') {
          result.monthly_rev_share = {
            ...formData?.fee_structure?.[type].monthly_rev_share,
          };
        }
      }
    }

    if (
      formData?.combined_rev_share &&
      formData?.seller_type?.label === 'Hybrid'
    ) {
      result.rev_share = formData?.fee_structure?.seller?.rev_share;
      result.sales_threshold = formData?.fee_structure?.seller?.sales_threshold;
      result.threshold_type = formData?.fee_structure?.seller?.threshold_type;
    }
    return result;
  };

  const isBillingCapExists = () => {
    if (
      formData?.seller_type?.value === 'Seller' ||
      formData?.seller_type?.value === 'Vendor'
    ) {
      const type = formData?.seller_type?.value?.toLowerCase();

      if (
        formData?.fee_structure?.[type]?.fee_type === 'Revenue Share Only' ||
        formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share'
      ) {
        if (
          formData?.fee_structure?.seller?.billing_cap ||
          formData?.fee_structure?.vendor?.billing_cap
        ) {
          return true;
        }
      }
    }

    if (formData?.seller_type?.value === 'Hybrid') {
      if (
        formData?.fee_structure?.seller?.fee_type === 'Revenue Share Only' ||
        formData?.fee_structure?.seller?.fee_type === 'Retainer + % Rev Share'
      ) {
        if (
          formData?.fee_structure?.seller?.billing_cap ||
          formData?.fee_structure?.vendor?.billing_cap
        ) {
          return true;
        }
      }
      if (
        formData?.fee_structure?.vendor?.fee_type === 'Revenue Share Only' ||
        formData?.fee_structure?.vendor?.fee_type === 'Retainer + % Rev Share'
      ) {
        if (
          formData?.fee_structure?.seller?.billing_cap ||
          formData?.fee_structure?.vendor?.billing_cap
        ) {
          return true;
        }
      }
    }
    return false;
  };

  // checking rev share conditions for approval
  // PDV-8316 - Rev share limit changes from 3 to 4
  const checkRevShareApprovalCondition = () => {
    if (
      formData?.seller_type?.value === 'Seller' ||
      formData?.seller_type?.value === 'Vendor'
    ) {
      const type = formData?.seller_type?.value?.toLowerCase();
      if (
        formData?.fee_structure?.[type]?.fee_type === 'Revenue Share Only' ||
        formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share'
      ) {
        if (formData?.fee_structure?.[type]?.rev_share < revShareLimit) {
          return true;
        }
      }
    }

    if (formData?.seller_type?.value === 'Hybrid') {
      if (
        formData?.fee_structure?.seller?.fee_type === 'Revenue Share Only' ||
        formData?.fee_structure?.seller?.fee_type === 'Retainer + % Rev Share'
      ) {
        if (formData?.fee_structure?.seller?.rev_share < revShareLimit) {
          return true;
        }
      }
      if (
        formData?.fee_structure?.vendor?.fee_type === 'Revenue Share Only' ||
        formData?.fee_structure?.vendor?.fee_type === 'Retainer + % Rev Share'
      ) {
        if (formData?.fee_structure?.vendor?.rev_share < revShareLimit) {
          return true;
        }
      }
    }
    return false;
  };

  // checking monthly retainer condition for approval
  // PDV-8316 - monthly retainer limit changes from $3000 to $6500
  const checkMonthlyRetainerApprovalCondition = () => {
    if (
      formData?.seller_type?.value === 'Seller' ||
      formData?.seller_type?.value === 'Vendor'
    ) {
      const type = formData?.seller_type?.value?.toLowerCase();
      if (
        formData?.fee_structure?.[type]?.fee_type === 'Retainer Only' ||
        formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share'
      ) {
        const checkForQuarterly =
          formData?.fee_structure?.[type]?.quarterly_monthly_retainer &&
          Object.values(
            formData?.fee_structure?.[type]?.quarterly_monthly_retainer,
          ).filter((op) => op < monthlyRetainerLimit);
        const checkForMonthly =
          formData?.fee_structure?.[type]?.monthly_monthly_retainer &&
          Object.values(
            formData?.fee_structure?.[type]?.monthly_monthly_retainer,
          ).filter((op) => op < monthlyRetainerLimit);

        if (
          (formData?.fee_structure?.[type]?.monthly_retainer <
            monthlyRetainerLimit &&
            formData?.fee_structure?.[type]?.retainer_type === 'Fixed') ||
          (checkForQuarterly?.length > 0 &&
            formData?.fee_structure?.[type]?.retainer_type === 'quarterly') ||
          (checkForMonthly?.length > 0 &&
            formData?.fee_structure?.[type]?.retainer_type === 'monthly')
        ) {
          return true;
        }
      }
    }

    if (formData?.seller_type?.value === 'Hybrid') {
      if (
        formData?.fee_structure?.seller?.fee_type === 'Retainer Only' ||
        formData?.fee_structure?.seller?.fee_type === 'Retainer + % Rev Share'
      ) {
        const checkForQuarterly =
          formData?.fee_structure?.seller?.quarterly_monthly_retainer &&
          Object.values(
            formData.fee_structure?.seller?.quarterly_monthly_retainer,
          ).filter((op) => op < monthlyRetainerLimit);
        const checkForMonthly =
          formData?.fee_structure?.seller?.monthly_monthly_retainer &&
          Object.values(
            formData?.fee_structure?.seller?.monthly_monthly_retainer,
          ).filter((op) => op < monthlyRetainerLimit);
        if (
          (formData?.fee_structure?.seller?.monthly_retainer <
            monthlyRetainerLimit &&
            formData?.fee_structure?.seller?.retainer_type === 'Fixed') ||
          (checkForQuarterly?.length > 0 &&
            formData?.fee_structure?.seller?.retainer_type === 'quarterly') ||
          (checkForMonthly?.length > 0 &&
            formData?.fee_structure?.seller?.retainer_type === 'monthly')
        ) {
          return true;
        }
      }
      if (
        formData?.fee_structure?.vendor?.fee_type === 'Retainer Only' ||
        formData?.fee_structure?.vendor?.fee_type === 'Retainer + % Rev Share'
      ) {
        const checkForQuarterly =
          formData?.fee_structure?.vendor?.quarterly_monthly_retainer &&
          Object.values(
            formData?.fee_structure?.vendor?.quarterly_monthly_retainer,
          ).filter((op) => op < monthlyRetainerLimit);
        const checkForMonthly =
          formData?.fee_structure?.vendor?.monthly_monthly_retainer &&
          Object.values(
            formData?.fee_structure?.vendor?.monthly_monthly_retainer,
          ).filter((op) => op < monthlyRetainerLimit);
        if (
          (formData?.fee_structure?.vendor?.monthly_retainer <
            monthlyRetainerLimit &&
            formData?.fee_structure?.vendor?.retainer_type === 'Fixed') ||
          (checkForQuarterly?.length > 0 &&
            formData?.fee_structure?.vendor?.retainer_type === 'quarterly') ||
          (checkForMonthly?.length > 0 &&
            formData?.fee_structure?.vendor?.retainer_type === 'monthly')
        ) {
          return true;
        }
      }
    }
    return false;
  };

  // check revenue share threshold limit by seller - vendor account type
  const checkThresholdLimitByAccType = (accType, thresholdLimit) => {
    if (
      formData?.fee_structure?.[accType]?.fee_type === 'Revenue Share Only' ||
      formData?.fee_structure?.[accType]?.fee_type === 'Retainer + % Rev Share'
    ) {
      const checkForQuarterly =
        formData?.fee_structure?.[accType]?.quarterly_rev_share &&
        Object.values(
          formData?.fee_structure?.[accType]?.quarterly_rev_share,
        ).filter((op) => op < thresholdLimit);
      const checkForMonthly =
        formData?.fee_structure?.[accType]?.monthly_rev_share &&
        Object.values(
          formData?.fee_structure?.[accType]?.monthly_rev_share,
        ).filter((op) => op < thresholdLimit);

      if (
        (formData?.fee_structure?.[accType]?.sales_threshold < thresholdLimit &&
          formData?.fee_structure?.[accType]?.threshold_type === 'Fixed') ||
        (checkForQuarterly?.length > 0 &&
          formData?.fee_structure?.[accType]?.threshold_type === 'quarterly') ||
        (checkForMonthly?.length > 0 &&
          formData?.fee_structure?.[accType]?.threshold_type === 'monthly')
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  // check the revenue share threshold limit
  // PDV-8316 - contract language updates - revenue share threshold limit is $375,000
  const checkRevenueShareThresholdLimit = () => {
    // checking seller threshold limit
    if (accountType === 'Seller' || accountType === 'Hybrid') {
      return checkThresholdLimitByAccType('seller', revShareThresholdLimit);
    }
    // checking vendor threshold
    if (accountType === 'Vendor' || accountType === 'Hybrid') {
      return checkThresholdLimitByAccType('vendor', revShareThresholdLimit);
    }

    return false;
  };

  // check for retainer agreement
  const checkForRetainerAgreement = () => {
    const feeStructureTemp = formData?.fee_structure;
    if (
      feeStructureTemp?.seller?.fee_type === 'Retainer Only' ||
      feeStructureTemp?.vendor?.fee_type === 'Retainer Only'
    ) {
      return true;
    }
    return false;
  };

  const checkDSPFeeCondition = () => {
    const dspFee = Number(details?.dsp_fee);
    const dspFeeSecond = Number(details?.dsp_fee_second);
    const dspFeeThird = Number(details?.dsp_fee_third);

    if (formData?.is_dynamic_dsp_fee) {
      if (dspFee < 10000 || dspFeeSecond < 10000 || dspFeeThird < 10000) {
        return true;
      }
    } else if (dspFee < 10000) {
      return true;
    }
    return false;
  };

  // check approval condition
  //  PDV-8316 - Contract should meet the following deal conditions. Sales manager can send the contract to the BP for signature if deal is within the standard terms. Otherwise, approval is needed from the sales admin (Liz adamson).
  const checkApprovalCondition = () => {
    const contractTermLength = parseInt(details?.length?.value, 10);
    if (details?.contract_type?.toLowerCase().includes('recurring')) {
      if (details?.draft_from) {
        return true;
      }

      if (
        (showSection.dspAddendum && checkDSPFeeCondition()) ||
        checkRevShareApprovalCondition() ||
        contractTermLength < 6 ||
        isBillingCapExists() ||
        checkMonthlyRetainerApprovalCondition() ||
        checkForRetainerAgreement() ||
        checkRevenueShareThresholdLimit()
      ) {
        return true;
      }
    }
    if (
      details?.draft_from &&
      details?.contract_type?.toLowerCase().includes('dsp')
    ) {
      return true;
    }

    if (showSection.dspAddendum && details?.agency_fee?.value < 15) return true;

    if (
      details?.contract_type?.toLowerCase().includes('dsp') &&
      checkDSPFeeCondition()
    ) {
      return true;
    }

    if (contractAddendumDetails?.notActive) return true;

    if (newAddendumData?.addendum?.length > 7) {
      return true;
    }
    return false;
  };

  const setParams = (param) => {
    const stringified =
      queryString &&
      queryString.stringify({
        ...params,
        step: param,
      });

    history.push({
      pathname: `${history.location.pathname}`,
      search: `${stringified}`,
      state: `${history.location.state}`,
    });
  };

  const feeStructureAddendumChanges = (
    type,
    updatedFields,
    updatedformFields,
    modalFields,
    label,
  ) => {
    // variable for fee type change from retainer only to retainer +%REv Share
    const feeTypeChangeFromRetainer =
      originalData?.fee_structure?.[type]?.fee_type === 'Retainer Only' &&
      formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share';

    // variable for fee type change from rev share only to retainer +%REv Share
    const feeTypechangeFromRevShare =
      originalData.fee_structure?.[type]?.fee_type === 'Revenue Share Only' &&
      formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share';

    // if retainer value is changed
    const retainerValueChanged =
      (formData?.fee_structure?.[type]?.retainer_type === 'Fixed' ||
        formData?.fee_structure?.[type]?.retainer_type === null ||
        !formData?.fee_structure?.[type]?.retainer_type) &&
      checkConditions(
        originalData?.fee_structure?.[type]?.monthly_retainer,
        formData?.fee_structure?.[type]?.monthly_retainer,
      );
    // if retainer value is changed for querterly and monthly
    const retainerValueChangedForMonthlyOrQuertery =
      (formData?.fee_structure?.[type]?.retainer_type === 'quarterly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.quarterly_monthly_retainer || {},
          formData?.fee_structure?.[type]?.quarterly_monthly_retainer || {},
        )) ||
      (formData?.fee_structure?.[type]?.retainer_type === 'monthly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.monthly_monthly_retainer || {},
          formData?.fee_structure?.[type]?.monthly_monthly_retainer || {},
        ));

    // if rev share changed.
    const revShareChanged = checkConditions(
      originalData?.fee_structure?.[type]?.rev_share,
      formData?.fee_structure?.[type]?.rev_share,
    );

    // thresholdtype if none
    const thresholdchangedNone =
      formData?.fee_structure?.[type]?.threshold_type === 'None' &&
      originalData?.fee_structure?.[type]?.threshold_type !== 'None' &&
      originalData?.fee_structure?.[type]?.threshold_type !== null;

    // threshold fixed type
    const formDataFixedThresholdType =
      (formData?.fee_structure?.[type]?.threshold_type === 'Fixed' ||
        formData?.fee_structure?.[type]?.threshold_type === null) &&
      originalData?.fee_structure?.[type]?.sales_threshold;

    const fixedThresholdType =
      checkConditions(
        formDataFixedThresholdType,
        formData?.fee_structure?.[type]?.sales_threshold,
      ) && formData?.fee_structure?.[type]?.threshold_type === 'Fixed';

    // threshold querterly and monthly
    const quarterlyandMonthlyThresholdType =
      (formData?.fee_structure?.[type]?.threshold_type === 'quarterly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.quarterly_rev_share || {},
          formData?.fee_structure?.[type]?.quarterly_rev_share || {},
        )) ||
      (formData?.fee_structure?.[type]?.threshold_type === 'monthly' &&
        getObjectDifference(
          originalData?.fee_structure?.[type]?.monthly_rev_share || {},
          formData?.fee_structure?.[type]?.monthly_rev_share || {},
        ));

    // if billing cap changed.

    const billingCapchanged = checkConditions(
      originalData?.fee_structure?.[type]?.billing_cap,
      formData?.fee_structure?.[type]?.billing_cap,
    );

    // if billing minimum changed
    const billingMinimumChanged = checkConditions(
      originalData?.fee_structure?.[type]?.billing_minimum,
      formData?.fee_structure?.[type]?.billing_minimum,
    );

    // if combined rev share is changed.
    const combinedRevshareChanged =
      originalData?.combined_rev_share !== formData?.combined_rev_share &&
      type === 'seller';

    if (
      feeTypeChangeFromRetainer ||
      feeTypechangeFromRevShare ||
      retainerValueChanged ||
      retainerValueChangedForMonthlyOrQuertery ||
      revShareChanged ||
      thresholdchangedNone ||
      fixedThresholdType ||
      quarterlyandMonthlyThresholdType ||
      billingCapchanged ||
      billingMinimumChanged ||
      combinedRevshareChanged
    ) {
      updatedFields.fee_structure = {
        ...updatedFormData?.fee_structure,
        [type]: {
          ...updatedFormData?.fee_structure?.[type],
          effective_date: updatedFormData?.fee_structure?.[type]?.effective_date
            ? updatedFormData?.fee_structure?.[type]?.effective_date
            : dayjs(new Date()).format('YYYY-MM-DD'),
        },
      };

      updatedformFields.fee_structure = {
        ...formData?.fee_structure,
        [type]: {
          ...formData?.fee_structure?.[type],
          effective_date: formData?.fee_structure?.[type]?.effective_date
            ? formData?.fee_structure?.[type]?.effective_date
            : dayjs(new Date()).format('YYYY-MM-DD'),
        },
      };

      modalFields.push({
        label: `${label} Fee Structure`,
        key: ['fee_structure'],
      });
    }
  };

  const monthlyServicesChanges = (type, updatedFields, updatedformFields) => {
    const serviceAddedResult = getMonthlyServicesofContract(type, 'added');
    const marketplacesAddedResult = getDifferenceMarketplaces(type, 'added');

    if (serviceAddedResult?.length || marketplacesAddedResult?.length) {
      if (updatedFormData.additional_marketplaces?.create?.length) {
        updatedFormData.additional_marketplaces.create.forEach((obj) => {
          if (!obj?.id) {
            obj.effective_date = obj?.effective_date
              ? obj?.effective_date
              : dayjs(new Date()).format('YYYY-MM-DD');
          }
        });
      }

      if (updatedFormData.additional_monthly_services?.create?.length) {
        updatedFormData.additional_monthly_services.create.forEach((obj) => {
          if (!obj?.id) {
            obj.effective_date = obj?.effective_date
              ? obj?.effective_date
              : obj?.name === 'DSP Advertising'
              ? dayjs(new Date(formData?.dsp_start_date)).format('YYYY-MM-DD')
              : dayjs(new Date()).format('YYYY-MM-DD');
          }
        });
      }

      if (formData.additional_marketplaces?.length) {
        formData.additional_marketplaces.forEach((obj) => {
          if (!obj?.id) {
            obj.effective_date = obj?.effective_date
              ? obj?.effective_date
              : dayjs(new Date()).format('YYYY-MM-DD');
          }
        });
      }
      if (formData.additional_monthly_services?.length) {
        formData.additional_monthly_services.forEach((obj) => {
          if (!obj?.id) {
            obj.effective_date = obj?.effective_date
              ? obj?.effective_date
              : obj?.name === 'DSP Advertising'
              ? dayjs(new Date(formData?.dsp_start_date)).format('YYYY-MM-DD')
              : dayjs(new Date()).format('YYYY-MM-DD');
          }
        });
      }

      updatedFields.additional_monthly_services = {
        ...updatedFormData?.additional_monthly_services,
        create: updatedFields.additional_monthly_services?.create?.length
          ? [
              ...updatedFields.additional_monthly_services?.create,
              ...serviceAddedResult,
            ]
          : [...serviceAddedResult],
      };

      updatedFields.additional_marketplaces = {
        ...updatedFormData?.additional_marketplaces,
        create:
          updatedFields?.additional_marketplaces?.create?.length &&
          marketplacesAddedResult?.length
            ? [
                ...updatedFields?.additional_marketplaces?.create,
                ...marketplacesAddedResult,
              ]
            : marketplacesAddedResult?.length
            ? [...marketplacesAddedResult]
            : [],
      };

      updatedformFields.additional_monthly_services =
        formData?.additional_monthly_services;

      updatedformFields.additional_marketplaces =
        formData?.additional_marketplaces;
    }
  };

  const handleErrorCount = (type, responseData) => {
    let errorCount = 0;

    if (
      responseData?.data?.[type]?.billing_cap &&
      !(
        feeStructureErrors?.[type]?.billing_cap ||
        feeStructureErrors?.[type]?.billing_minimum
      )
    ) {
      errorCount += 1;
    }

    if (
      responseData?.data?.[type]?.quarterly_rev_share &&
      !feeStructureErrors?.[type]?.quarterly_rev_share
    ) {
      errorCount += 1;
    }

    if (
      responseData?.data?.[type]?.monthly_rev_share &&
      !feeStructureErrors?.[type]?.monthly_rev_share
    ) {
      errorCount += 1;
    }
    if (
      responseData?.data?.[type]?.sales_threshold &&
      !feeStructureErrors?.[type]?.sales_threshold
    ) {
      errorCount += 1;
    }

    return errorCount;
  };

  const showEditView = (contract) => {
    setIsEditContract(true);
    setMandatoryFieldsErrors(contract);
    if (contractAddendumDetails.notActive) {
      executeScroll('draft-addendum');
      setShowCollpase({ ...showSection, addendum: true });
      setOpenCollapse({ ...openCollapse, addendum: true });
    }
  };

  const checkEditFlagComeFromOuterFile = (res) => {
    if (history?.location?.showEditView) {
      if (
        res?.data?.contract_status?.value === 'pending contract signature' ||
        res?.data?.contract_addendum?.[0]?.addendum_status?.value ===
          'pending addendum signature'
      ) {
        onEditcontract();
      } else {
        showEditView(res?.data);
      }

      const historyState = { ...history.location };
      delete historyState.showEditView;
      history.replace({ ...historyState });
    }
  };

  const getContractAddendumDetails = (addendumId, addendumStatus) => {
    if (addendumId) {
      executeScroll('draft-addendum');
      setOpenCollapse({ ...openCollapse, addendum: true });
      setShowCollpase({ ...showSection, addendum: true });
      setContractAddendumDetails({
        id: addendumId,
        status: addendumStatus,
        notActive: addendumStatus !== 'active',
      });
      if (addendumStatus !== 'active') {
        getContractAddendum(addendumId).then((res) => {
          setFormData(res?.data);
          setAddendumDetails(res?.data);
          executeScroll('draft-addendum');
          checkEditFlagComeFromOuterFile(res);

          setContractAddendumDetails({
            id: res?.data?.id,
            status: res?.data?.addendum_status?.value,
            notActive: res?.data?.addendum_status?.value !== 'active',
            addendum_start_date: res?.data?.addendum_start_date,
            change_addendum_start_date: res?.data?.change_addendum_start_date,
          });
        });
      }
    }
  };

  const getContractDetails = (showSuccessToastr = false) => {
    setEnableDownloadBtn(false);
    setIsLoading({ loader: true, type: 'page' });
    if (splittedPath) {
      getcontract(splittedPath[4]).then((res) => {
        if (res && res.status === 200) {
          getMonthlyServices(res?.data?.seller_type?.value);
          getAdvertisingServices();
          setStartDate(res?.data?.start_date);

          getDiscountData(
            res?.data?.contract_addendum &&
              res?.data?.contract_addendum?.[0]?.addendum_status?.value !==
                'active'
              ? res?.data?.contract_addendum?.[0]?.id
              : res?.data?.id,
          );
          setDetails(res.data);
          if (res?.data?.contract_addendum?.[0]?.id) {
            getContractAddendumDetails(
              res?.data?.contract_addendum?.[0]?.id,
              res?.data?.contract_addendum?.[0]?.addendum_status?.value,
            );
            executeScroll('draft-addendum');
          } else {
            setFormData(res?.data);
            getFeeStructureDetails(res?.data?.seller_type?.value, res?.data);
            checkEditFlagComeFromOuterFile(res);
          }
          setSelectedCurrency(res?.data?.currency?.label?.split(' ')?.[0]);
          setOriginalData(res?.data);

          if (showSuccessToastr) {
            setShowSignSuccessMsg(showSuccessToastr);
          }

          const contractData = {
            customer_id: id,
            contract_id: res?.data?.id,
          };
          const addendumData = {
            customer_id: id,
            contract_addendum_id: res?.data?.contract_addendum?.[0]?.id,
          };
          getAddendum(
            res?.data?.contract_addendum &&
              res?.data?.contract_addendum?.[0]?.addendum_status !== 'active'
              ? addendumData
              : contractData,
          ).then((addendum) => {
            setNewAddendum(
              addendum?.data?.results?.length && addendum.data.results[0],
            );
            setOriginalAddendumData(
              addendum?.data?.results?.length && addendum.data.results[0],
            );
          });

          setTimeout(() => {
            setEnableDownloadBtn(true);
          }, 7000);
        } else {
          setIsLoading({ loader: false, type: 'page' });
          setLoaderFlag(false);
          setPageNotFoundFlag(true);
        }
      });
      getContractActivityLogInfo();
      setLoaderFlag(true);
    } else {
      const path = location.pathname.slice(
        0,
        location.pathname.lastIndexOf('/'),
      );
      history.push(path);
    }
  };
  const createPrimaryMarketplace = () => {
    const statementData = {
      id: details?.primary_marketplace?.id || '',
      contract: detailsId,
      name: updatedFormData && updatedFormData.primary_marketplace,
      is_primary: true,
    };

    if (details.primary_marketplace?.id) {
      updateMarketplace(details.primary_marketplace.id, statementData).then(
        (updateMarketplaceRes) => {
          if (updateMarketplaceRes?.status === 200) {
            setFormData({
              ...formData,
              primary_marketplace: updateMarketplaceRes?.data,
            });
            if (updatedFormData?.primary_marketplace) {
              delete updatedFormData.primary_marketplace;
              setUpdatedFormData({ ...updatedFormData });

              if (!Object.keys(updatedFormData).length) {
                showFooter(false);
                setIsEditContract(false);
                getContractDetails();
                if (contractAddendumDetails?.notActive) {
                  getContractAddendumDetails(
                    contractAddendumDetails?.id,
                    contractAddendumDetails?.status,
                  );
                  executeScroll('draft-addendum');
                }
                toast.success('Changes Saved!');
                setShowEffetivityDatesModal({ show: true, data: [] });
              }
            }
          }
        },
      );
    } else {
      createMarketplace(statementData).then((createMarketplaceRes) => {
        setIsLoading({ loader: false, type: 'button' });
        setIsLoading({ loader: false, type: 'page' });
        if (createMarketplaceRes && createMarketplaceRes.status === 201) {
          setFormData({
            ...formData,
            primary_marketplace: createMarketplaceRes?.data,
          });
          if (updatedFormData?.primary_marketplace) {
            delete updatedFormData.primary_marketplace;
            setUpdatedFormData({ ...updatedFormData });
            if (!Object.keys(updatedFormData).length) {
              showFooter(false);
              setIsEditContract(false);
              getContractDetails();
              if (contractAddendumDetails?.notActive) {
                getContractAddendumDetails(
                  contractAddendumDetails?.id,
                  contractAddendumDetails?.status,
                );
                executeScroll('draft-addendum');
              }
              createAgreementDoc();
              toast.success('Changes Saved!');
              setShowEffetivityDatesModal({ show: true, data: [] });
            }
          }
        }
      });
    }
    setUpdatedFormData({ ...updatedFormData });
  };

  const saveChanges = (apis) => {
    axios
      .all(apis)
      .then(
        axios.spread((...responses) => {
          const additionalMonthlySerRes = responses[0];
          const additionalOneTimeServRes = responses[1];
          const addendumRes = responses[2];
          const updateCustomerRes = responses[3];
          const sellerFeeStructureRes = responses[4];
          const vendorFeeStructureRes = responses[5];
          const contractRes = responses[6];
          const standardServicesRes = responses[7];
          const standardNonUSServiceRes = responses[8];
          const dspMarketplacesRes = responses[9];
          const amcServicesRes = responses[10];

          const filteredRes = responses.filter((item) => item !== null);

          if (filteredRes?.length) {
            setIsLoading({ loader: false, type: 'button' });
            setIsLoading({ loader: false, type: 'page' });

            const failApis = filteredRes.filter((item) => item?.status === 400);
            if (failApis?.length) {
              toast.error(
                'Changes have not been saved. Please fix errors and try again',
              );
              if (Object.keys(openCollapse)?.[0] === 'feeStructure')
                executeScroll('statement');
              else executeScroll(Object.keys(openCollapse));
            } else {
              toast.success('Changes Saved!');
              setShowEffetivityDatesModal({ show: true, data: [] });

              showFooter(false);
              setUpdatedFormData({});
              getContractDetails();
              setIsEditContract(false);
              if (contractAddendumDetails?.notActive) {
                getContractAddendumDetails(
                  contractAddendumDetails?.id,
                  contractAddendumDetails?.status,
                );
                executeScroll('draft-addendum');
              }
            }
          }

          if (
            !contractTypeOne &&
            !updatedFormData?.changed_dsp_start_date &&
            (updatedFormData?.dsp_marketplaces === undefined ||
              !updatedFormData?.dsp_marketplaces) &&
            formData?.dsp_marketplaces?.length
            // New conditions because of storefront error
            // originalData?.changed_dsp_start_date &&
            // !updatedFormData?.changed_dsp_start_date &&
            // formData?.dsp_marketplaces?.length
          ) {
            createDSPMarketplacesBulk({
              create: formData?.dsp_marketplaces,
            }).then(() => {
              if (updatedFormData?.dsp_marketplaces) {
                delete updatedFormData.dsp_marketplaces;
                setChangedDspStartDateFlag(false);
                setUpdatedFormData({ ...updatedFormData });
                getContractDetails();
              }
            });
          }

          if (amcServicesRes?.status === 200) {
            if (updatedFormData?.amc_services) {
              delete updatedFormData.amc_services;
            }
          }

          if (additionalMonthlySerRes?.status === 200) {
            // for Dsp marketplaces bulk update
            if (
              updatedFormData?.dsp_marketplaces &&
              updatedFormData?.additional_monthly_services
            ) {
              createDSPMarketplacesBulk(updatedFormData.dsp_marketplaces).then(
                () => {
                  if (updatedFormData?.dsp_marketplaces) {
                    delete updatedFormData.dsp_marketplaces;
                    setUpdatedFormData({ ...updatedFormData });
                    // getContractDetails();
                  }
                },
              );
            }
            setFormData({
              ...formData,
              additional_monthly_services: additionalMonthlySerRes?.data,
            });
            setOriginalData({
              ...originalData,
              additional_monthly_services: additionalMonthlySerRes?.data,
            });
            if (updatedFormData?.additional_monthly_services) {
              delete updatedFormData.additional_monthly_services;
            }
            setChangedDspStartDateFlag(false);

            setUpdatedFormData({ ...updatedFormData });
          }

          if (dspMarketplacesRes?.status === 200) {
            // getContractDetails();
            if (contractAddendumDetails?.notActive) {
              getContractAddendumDetails(
                contractAddendumDetails?.id,
                contractAddendumDetails?.status,
              );
            }
            if (updatedFormData?.dsp_marketplaces) {
              delete updatedFormData.dsp_marketplaces;
            }
            if (updatedFormData?.additional_one_time_services?.create?.length) {
              setFormData({
                ...formData,
                additional_one_time_services: [
                  ...updatedFormData.additional_one_time_services.create,
                ],
              });
            }
          }
          if (dspMarketplacesRes?.status === 400) {
            setDspMarketpalcesError(dspMarketplacesRes?.data);
          }

          if (standardNonUSServiceRes?.status === 200) {
            if (contractAddendumDetails?.notActive) {
              getContractAddendumDetails(
                contractAddendumDetails?.id,
                contractAddendumDetails?.status,
              );
            }
            if (updatedFormData?.standard_non_us_services) {
              delete updatedFormData.standard_non_us_services;
            }
          }

          if (standardServicesRes?.status === 200) {
            if (contractAddendumDetails?.notActive) {
              getContractAddendumDetails(
                contractAddendumDetails?.id,
                contractAddendumDetails?.status,
              );
            }
            if (updatedFormData?.standard_us_services) {
              delete updatedFormData.standard_us_services;
            }
          }

          if (additionalOneTimeServRes?.status === 200) {
            setFormData({
              ...formData,
              additional_one_time_services: additionalOneTimeServRes?.data,
            });
            setOriginalData({
              ...originalData,
              additional_one_time_services: additionalOneTimeServRes?.data,
            });

            const service =
              additionalOneTimeServRes?.data?.length &&
              additionalOneTimeServRes.data.find(
                (item) =>
                  item?.service?.name &&
                  item.service.name === 'Amazon Store Package Custom',
              );
            if (service) {
              setAmazonStoreCustom(true);
            } else {
              setAmazonStoreCustom(false);
            }
            if (updatedFormData?.additional_one_time_services) {
              delete updatedFormData.additional_one_time_services;
            }
          }

          if (addendumRes?.status === 200 || addendumRes?.status === 201) {
            if (addendumRes?.status === 201) {
              setNewAddendum(addendumRes?.data);
            }
            setNewAddendum(addendumRes?.data);
            setOriginalAddendumData(addendumRes?.data);
            setShowEditor(false);
            if (updatedFormData?.addendum) {
              delete updatedFormData.addendum;
            }
          }

          if (updateCustomerRes?.status === 200) {
            const customerData = updateCustomerRes.data;
            setFormData({ ...formData, customer_id: { ...customerData } });
            delete updatedFormData.company_name;
            delete updatedFormData.address;
            delete updatedFormData.city;
            delete updatedFormData.state;
            delete updatedFormData.zip_code;
          }

          if (
            accountType === 'Seller' &&
            (sellerFeeStructureRes?.status === 200 ||
              sellerFeeStructureRes?.status === 201)
          ) {
            delete updatedFormData.fee_structure;
          }

          if (
            accountType === 'Vendor' &&
            (vendorFeeStructureRes?.status === 200 ||
              vendorFeeStructureRes?.status === 201)
          ) {
            delete updatedFormData.fee_structure;
          }

          if (accountType === 'Hybrid') {
            if (
              sellerFeeStructureRes?.status === 200 ||
              sellerFeeStructureRes?.status === 201
            ) {
              delete updatedFormData.fee_structure?.seller;
            }
            if (
              vendorFeeStructureRes?.status === 200 ||
              vendorFeeStructureRes?.status === 201
            ) {
              delete updatedFormData.fee_structure?.vendor;
            }
          }

          if (contractRes && contractRes.status === 200) {
            setChangedDspStartDateFlag(false);
            const updatedKeys = Object.keys(updatedFormData);
            if (updatedKeys && updatedKeys.length) {
              const fieldsToDeleteList = updatedKeys
                .filter((item) => item !== 'additional_one_time_services')
                .filter((item) => item !== 'additional_monthly_services')
                .filter((item) => item !== 'additional_marketplaces')
                .filter((item) => item !== 'primary_marketplace')
                .filter((item) => item !== 'addendum')
                .filter((item) => item !== 'company_name')
                .filter((item) => item !== 'address')
                .filter((item) => item !== 'city')
                .filter((item) => item !== 'state')
                .filter((item) => item !== 'zip_code')
                .filter((item) => item !== 'dsp_marketplaces')
                .filter((item) => item !== 'fee_structure')
                .filter((item) => item !== 'standard_non_us_services');

              for (const item of fieldsToDeleteList) {
                delete updatedFormData[item];
              }
            }
          }

          setUpdatedFormData({ ...updatedFormData });

          let agreementErrCount = 0;
          let statementErrCount = 0;
          let dspErrCount = 0;

          if (standardNonUSServiceRes?.status === 400) {
            if (standardNonUSServiceRes.data) {
              if (
                Object.keys(standardNonUSServiceRes.data).includes(
                  'VAT Filing',
                ) &&
                !nonUsServiceError?.['VAT Filing']
              ) {
                statementErrCount += Object.keys(
                  standardNonUSServiceRes.data,
                ).length;
              }
            }

            setNonUSserviceError({
              ...nonUsServiceError,
              ...standardNonUSServiceRes.data,
            });
          }

          if (amcServicesRes?.status === 400) {
            setAmcServiceError({
              ...amcServiceError,
              ...amcServicesRes?.data,
            });

            if (amcServicesRes.data) {
              if (formData?.contract_type?.includes('dsp')) {
                dspErrCount += Object.keys(amcServicesRes.data)?.length;
              } else {
                statementErrCount += Object.keys(amcServicesRes.data)?.length;
              }
            }
          }
          if (additionalMonthlySerRes?.status === 400) {
            setAdditionalMonthlySerError({
              ...additionalMonthlySerError,
              ...additionalMonthlySerRes.data,
            });

            if (additionalMonthlySerRes.data) {
              statementErrCount += Object.keys(
                additionalMonthlySerRes.data,
              ).length;
            }
          }
          if (additionalOneTimeServRes?.status === 400) {
            if (additionalOneTimeServRes.data) {
              if (
                Object.keys(additionalOneTimeServRes.data).includes(
                  'quantity',
                ) &&
                Object.keys(additionalOnetimeSerError)?.includes('quantity') &&
                !additionalOnetimeSerError?.quantity
              ) {
                statementErrCount += Object.keys(
                  additionalOneTimeServRes.data,
                ).length;
              }
            }
            setAdditionalOnetimeSerError({
              ...additionalOnetimeSerError,
              ...additionalOneTimeServRes.data,
            });
            if (updatedFormData?.additional_one_time_services?.create?.length) {
              setFormData({
                ...formData,
                additional_one_time_services: [
                  ...updatedFormData.additional_one_time_services.create,
                ],
              });
            }
          }

          if (updateCustomerRes?.status === 400) {
            setCustomerErrors({
              ...customerError,
              ...updateCustomerRes.data,
            });
            if (
              updateCustomerRes.data &&
              Object.keys(updateCustomerRes.data).length
            ) {
              if (
                Object.keys(updateCustomerRes.data).includes('zip_code') &&
                !customerError.zip_code
              )
                agreementErrCount += 1;
            }
          }
          if (contractRes?.status === 400) {
            setContractError({
              ...contractError,
              ...contractRes.data,
            });

            if (contractRes.data) {
              if (Object.keys(contractRes.data).length) {
                if (Object.keys(contractRes.data).includes('monthly_retainer'))
                  statementErrCount += 1;
                if (
                  Object.keys(contractRes.data).includes('content_optimization')
                )
                  statementErrCount += 1;
                if (
                  Object.keys(contractRes.data).includes('design_optimization')
                )
                  statementErrCount += 1;
                if (Object.keys(contractRes.data).includes('yoy_percentage'))
                  statementErrCount += 1;
                if (Object.keys(contractRes.data).includes('sales_threshold'))
                  statementErrCount += 1;
                if (
                  Object.keys(contractRes.data).includes('dsp_fee') &&
                  !contractError.dsp_fee
                )
                  dspErrCount += 1;
              }
            }
          }
          const feeErrors = {
            feeStructure: sectionError?.feeStructure,
            ...sectionError,
          };

          const feeBEErros = {
            ...feeStructureErrors,
          };

          if (sellerFeeStructureRes?.status === 400) {
            const errorKeys = Object.keys(sellerFeeStructureRes.data);

            feeBEErros.seller = {
              ...sellerFeeStructureRes.data?.seller,
            };

            if (errorKeys.length) {
              if (errorKeys.includes('seller')) {
                feeErrors.seller = {
                  feeType:
                    (sectionError?.seller?.feeType
                      ? sectionError?.seller?.feeType
                      : 0) + handleErrorCount('seller', sellerFeeStructureRes),
                };
              }
            }
          }

          if (vendorFeeStructureRes?.status === 400) {
            const errorKeys = Object.keys(vendorFeeStructureRes.data);

            feeBEErros.vendor = {
              ...vendorFeeStructureRes.data?.vendor,
            };

            if (errorKeys.length) {
              if (errorKeys.includes('vendor')) {
                feeErrors.vendor = {
                  feeType:
                    (sectionError?.vendor?.feeType
                      ? sectionError?.vendor?.feeType
                      : 0) + handleErrorCount('vendor', vendorFeeStructureRes),
                };
              }
            }
          }
          setFeeStructureErrors({
            ...feeBEErros,
          });
          setSectionError({
            ...feeErrors,
            agreement: agreementErrCount + sectionError.agreement,
            statement: statementErrCount + sectionError.statement,
            dsp: dspErrCount + sectionError.dsp,
          });
        }),
      )
      .catch(() => {});
  };

  const updateMarketplaces = async () => {
    let flag = false;
    const result = await createMarketplaceBulk(
      updatedFormData.additional_marketplaces,
    ).then((updateMarketplacesRes) => {
      flag = true;
      if (updateMarketplacesRes?.status === 200) {
        setFormData({
          ...formData,
          additional_marketplaces: updateMarketplacesRes?.data,
        });
        setOriginalData({
          ...originalData,
          additional_marketplaces: updateMarketplacesRes?.data,
        });
      }
    });
    if (flag) {
      if (updatedFormData?.additional_marketplaces) {
        delete updatedFormData.additional_marketplaces;
        setUpdatedFormData({ ...updatedFormData });
      }
      setMarketplaceDropdownData();
      if (updatedFormData?.primary_marketplace) {
        createPrimaryMarketplace();
      } else if (!Object.keys(updatedFormData).length) {
        showFooter(false);
        setIsEditContract(false);
        getContractDetails();
        if (contractAddendumDetails?.notActive) {
          getContractAddendumDetails(
            contractAddendumDetails?.id,
            contractAddendumDetails?.status,
          );
          executeScroll('draft-addendum');
        }
        createAgreementDoc();
        toast.success('Changes Saved!');
        setShowEffetivityDatesModal({ show: true, data: [] });
      }
    }
    return result;
  };

  const nextStep = async () => {
    let additionalMonthlyApi = null;
    let additionalOneTimeApi = null;
    let AccountApi = null;
    let AddendumApi = null;
    let updateCustomerApi = null;
    let sellerFeeStructureApi = null;
    let vendorFeeStructureApi = null;
    let stdServicesApi = null;
    let standardNonUSServiceApi = null;
    let dspMarketplacesApi = null;
    let amcServicesApi = null;

    if (updatedFormData && Object.keys(updatedFormData).length) {
      // for start date
      if (updatedFormData?.start_date) {
        updatedFormData.start_date = dayjs(updatedFormData.start_date).format(
          'YYYY-MM-DD',
        );
      }
      setIsLoading({ loader: true, type: 'page' });
      setContractDesignLoader(true);
      // for additionL montlhy service
      if (updatedFormData.additional_monthly_services) {
        additionalMonthlyApi = createAdditionalServiceBulk(
          updatedFormData.additional_monthly_services,
        );
      }
      //  for additional one time service
      if (updatedFormData.additional_one_time_services) {
        additionalOneTimeApi = createAdditionalServiceBulk(
          updatedFormData.additional_one_time_services,
        );
      }
      // for StandardnonUSMarketplaceService
      if (updatedFormData?.standard_non_us_services) {
        standardNonUSServiceApi = createStandardNonUSServiceBulk(
          updatedFormData.standard_non_us_services,
        );
      }

      if (updatedFormData?.standard_us_services) {
        stdServicesApi = saveStdServices(updatedFormData.standard_us_services);
      }

      if (
        updatedFormData?.fee_structure ||
        updatedFormData?.combined_rev_share
      ) {
        const type = formData?.seller_type?.label?.toLowerCase();
        if (type === 'hybrid') {
          if (
            updatedFormData?.fee_structure?.seller &&
            Object.keys(updatedFormData?.fee_structure?.seller)?.length > 0
          ) {
            const addendumDetail = {
              ...getUpdatedFeeStructure('seller'),
              account_type: 'Seller',
              effective_date:
                updatedFormData?.fee_structure?.seller?.effective_date,
              contract_addendum: contractAddendumDetails?.id,
              currency: formData?.currency,
            };
            const contractDetail = {
              ...getUpdatedFeeStructure('seller'),
              account_type: 'Seller',
              contract: formData.id,
              currency: formData?.currency,
            };
            sellerFeeStructureApi = saveFeeStructure(
              formData?.fee_structure?.seller?.id,
              contractAddendumDetails.notActive
                ? addendumDetail
                : contractDetail,
            );
          }
          if (
            updatedFormData?.fee_structure?.vendor &&
            Object.keys(updatedFormData?.fee_structure?.vendor)?.length > 0
          ) {
            const contractDetail = {
              ...getUpdatedFeeStructure('vendor'),
              account_type: 'Vendor',
              contract: formData.id,
              currency: formData?.currency,
            };
            const addendumDetail = {
              ...getUpdatedFeeStructure('vendor'),
              account_type: 'Vendor',
              effective_date:
                updatedFormData?.fee_structure?.vendor?.effective_date,

              currency: formData?.currency,
              contract_addendum: contractAddendumDetails?.id,
            };
            vendorFeeStructureApi = saveFeeStructure(
              formData?.fee_structure?.vendor?.id,
              contractAddendumDetails?.notActive
                ? addendumDetail
                : contractDetail,
            );
          }
        } else {
          if (type === 'seller') {
            const contractDetail = {
              ...getUpdatedFeeStructure('seller'),

              account_type: type === 'seller' ? 'Seller' : 'Vendor',
              contract: formData.id,
              currency: formData?.currency,
            };
            const addendumDetail = {
              ...getUpdatedFeeStructure('seller'),
              account_type: type === 'seller' ? 'Seller' : 'Vendor',
              effective_date:
                updatedFormData?.fee_structure?.seller?.effective_date,

              contract_addendum: contractAddendumDetails?.id,
              currency: formData?.currency,
            };
            sellerFeeStructureApi = saveFeeStructure(
              formData?.fee_structure?.[type]?.id,
              contractAddendumDetails?.notActive
                ? addendumDetail
                : contractDetail,
            );
          }
          if (type === 'vendor') {
            const addendumDetail = {
              ...getUpdatedFeeStructure('vendor'),
              account_type: type === 'seller' ? 'Seller' : 'Vendor',
              contract_addendum: contractAddendumDetails?.id,
              effective_date:
                updatedFormData?.fee_structure?.vendor?.effective_date,

              currency: formData?.currency,
            };
            const contractDetail = {
              ...getUpdatedFeeStructure('vendor'),
              account_type: type === 'seller' ? 'Seller' : 'Vendor',
              contract: formData.id,
              currency: formData?.currency,
            };
            vendorFeeStructureApi = saveFeeStructure(
              formData?.fee_structure?.[type]?.id,
              contractAddendumDetails?.notActive
                ? addendumDetail
                : contractDetail,
            );
          }
        }
      }

      if (
        Object.keys(updatedFormData).includes('company_name') ||
        Object.keys(updatedFormData).includes('address') ||
        Object.keys(updatedFormData).includes('city') ||
        Object.keys(updatedFormData).includes('state') ||
        Object.keys(updatedFormData).includes('zip_code')
      ) {
        const customerData = {
          company_name: updatedFormData.company_name,
          address: updatedFormData.address,
          city: updatedFormData.city,
          state: updatedFormData.state,
          zip_code: updatedFormData.zip_code,
        };
        updateCustomerApi = updateCustomerDetails(id, customerData);
      }
      // for 'monthly_retainer', 'dsp_fee', 'sales_threshold'
      const num = [
        'monthly_retainer',
        'dsp_fee',
        'dsp_one_time_fee',
        'sales_threshold',
        'billing_cap',
      ];
      for (const val of num) {
        if (updatedFormData && updatedFormData[val]) {
          updatedFormData[val] = updatedFormData[val].replace(/,/g, '');
        }
      }

      const updatedContractFields = cloneDeep(updatedFormData);
      delete updatedContractFields.additional_one_time_services;
      delete updatedContractFields.additional_monthly_services;
      delete updatedContractFields.additional_marketplaces;
      delete updatedContractFields.primary_marketplace;
      delete updatedContractFields.addendum;
      delete updatedContractFields.company_name;
      delete updatedContractFields.address;
      delete updatedContractFields.city;
      delete updatedContractFields.state;
      delete updatedContractFields.zip_code;
      delete updatedContractFields.fee_structure;
      delete updatedContractFields.standard_non_us_services;
      delete updatedContractFields.standard_us_services;
      delete updatedContractFields.dsp_marketplaces;
      delete updatedContractFields.amc_services;

      // for set discount back to null if discount less than subtotal
      if (
        updatedFormData.additional_monthly_services ||
        updatedFormData.additional_marketplaces
      ) {
        handleDiscount();
      }

      if (updatedFormData?.amc_services) {
        handleDiscount('amc_services');
      }
      if (updatedFormData.additional_one_time_services) {
        const totalFees = calculateTotalFee('onetime');
        if (Math.sign(totalFees.oneTimeTotal) === -1) {
          const contractPostData = {
            contract: details?.id,
            account_type: '',
            service_type: 'one time service',
            type: null,
            amount: null,
          };

          const addendumPostData = {
            contract_addendum: contractAddendumDetails?.id,
            account_type: '',
            service_type: 'one time service',
            type: null,
            amount: null,
          };

          const result =
            discountData?.length &&
            discountData.filter(
              (item) => item.service_type === 'one time service',
            );

          saveDiscount(
            result?.[0]?.id,
            contractAddendumDetails?.id ? addendumPostData : contractPostData,
          );
        }
      }

      const detail = {
        ...updatedContractFields,
      };
      if (Object.keys(detail).length) {
        AccountApi = contractAddendumDetails?.notActive
          ? saveDraftAddendum(contractAddendumDetails?.id, detail)
          : updateAccountDetails(details.id, detail);
      }

      if (newAddendumData?.id && updatedFormData.addendum) {
        const contractData = {
          addendum: newAddendumData && newAddendumData.addendum,
          contract: details.id,
        };
        const addendumData = {
          addendum: newAddendumData && newAddendumData.addendum,
          contract_addendum: contractAddendumDetails?.id,
        };
        AddendumApi = updateAddendum(
          newAddendumData.id,
          contractAddendumDetails?.notActive ? addendumData : contractData,
        );
      } else if (updatedFormData && updatedFormData.addendum) {
        const addendumData = {
          customer_id: id,
          addendum: newAddendumData && newAddendumData.addendum,
          contract_addendum: contractAddendumDetails?.id,
        };
        const contractData = {
          customer_id: id,
          addendum: newAddendumData && newAddendumData.addendum,
          contract: details.id,
        };
        AddendumApi = createAddendum(
          contractAddendumDetails?.notActive ? addendumData : contractData,
        );
      }
      // for Dsp marketplaces bulk update
      if (
        updatedFormData?.dsp_marketplaces &&
        !updatedFormData?.additional_monthly_services
      ) {
        dspMarketplacesApi = createDSPMarketplacesBulk(
          updatedFormData.dsp_marketplaces,
        );
      }

      // for AMC Dashboard bulk update
      if (updatedFormData?.amc_services) {
        amcServicesApi = createAMCServicesBulk(updatedFormData.amc_services);
      }

      const apis = [
        additionalMonthlyApi,
        additionalOneTimeApi,
        AddendumApi,
        updateCustomerApi,
        sellerFeeStructureApi,
        vendorFeeStructureApi,
        AccountApi,
        stdServicesApi,
        standardNonUSServiceApi,
        dspMarketplacesApi,
        amcServicesApi,
      ];

      if (
        updatedFormData?.primary_marketplace ||
        updatedFormData?.additional_marketplaces
      ) {
        if (
          updatedFormData?.primary_marketplace &&
          !Object.keys(updatedFormData).includes('additional_marketplaces')
        ) {
          createPrimaryMarketplace();
        } else {
          await updateMarketplaces();
        }
      }

      saveChanges(apis);
    }
  };

  const handleAddendumModal = (openModal = false) => {
    const modalFields = [];
    const updatedFields = {};
    const updatedformFields = {};
    // DSP Advertising
    const sellerMonthlyAddedservices = getMonthlyServicesofContract(
      'seller',
      'added',
    );
    const vendorMonthlyAddedservices = getMonthlyServicesofContract(
      'vendor',
      'added',
    );
    if (
      formData?.is_dsp_advertising !== originalData?.is_dsp_advertising &&
      formData?.is_dsp_advertising
      // sellerMonthlyAddedservices?.find(
      //   (item) =>
      //     item?.name === 'DSP Advertising' ||
      //     item?.service?.name === 'DSP Advertising',
      // ) ||
      // vendorMonthlyAddedservices?.find(
      //   (item) =>
      //     item?.name === 'DSP Advertising' ||
      //     item?.service?.name === 'DSP Advertising',
      // )
    ) {
      modalFields.push({
        label: 'DSP Advertising',
        key: ['agency_fee', 'dsp_fee', 'dsp_length', 'dsp_marketplaces'],
      });
    }

    // Listing optimzation
    if (
      checkConditions(
        originalData?.content_optimization,
        formData?.content_optimization,
      ) ||
      checkConditions(
        originalData?.design_optimization,
        formData?.design_optimization,
      ) ||
      checkConditions(
        originalData?.photography_optimization,
        formData?.photography_optimization,
      )
    ) {
      const keys = [];
      const listingOptimizationData = [];
      if (
        checkConditions(
          originalData?.content_optimization,
          formData?.content_optimization,
        )
      ) {
        keys.push({
          value: 'content_optimization',
          label: 'Copy',
        });
        listingOptimizationData.push({
          name: 'Copy',
          asin: formData?.content_optimization,
        });
      }
      if (
        checkConditions(
          originalData?.design_optimization,
          formData?.design_optimization,
        )
      ) {
        keys.push({
          value: 'design_optimization',
          label: 'Design',
        });
        listingOptimizationData.push({
          name: 'Desgin',
          asin: formData?.design_optimization,
        });
      }
      if (
        checkConditions(
          originalData?.photography_optimization,
          formData?.photography_optimization,
        )
      ) {
        keys.push({
          value: 'photography_optimization',
          label: 'Photography',
        });
        listingOptimizationData.push({
          name: 'Photography',
          asin: formData?.photography_optimization,
        });
      }
      modalFields.push({
        label: 'Listing Optimization',
        keys,
        fieldSet: listingOptimizationData,
      });
      updatedFields.listing_optimization_effective_date =
        updatedFormData?.listing_optimization_effective_date
          ? updatedFormData?.listing_optimization_effective_date
          : dayjs(new Date()).format('YYYY-MM-DD');
      updatedformFields.listing_optimization_effective_date =
        formData?.listing_optimization_effective_date
          ? formData?.listing_optimization_effective_date
          : dayjs(new Date()).format('YYYY-MM-DD');
    }

    // Listing Transaltion
    if (
      checkConditions(
        originalData?.copy_listing_translation,
        formData?.copy_listing_translation,
      ) ||
      checkConditions(
        originalData?.creative_listing_translation,
        formData?.creative_listing_translation,
      )
    ) {
      const keys = [];
      const listingTranslationData = [];
      if (
        checkConditions(
          originalData?.copy_listing_translation,
          formData?.copy_listing_translation,
        )
      ) {
        keys.push({
          value: 'copy_listing_translation',
          label: 'Copy',
        });
        listingTranslationData.push({
          name: 'Copy',
          asin: formData?.copy_listing_translation,
        });
      }
      if (
        checkConditions(
          originalData?.creative_listing_translation,
          formData?.creative_listing_translation,
        )
      ) {
        keys.push({
          value: 'creative_listing_translation',
          label: 'Creative',
        });
        listingTranslationData.push({
          name: 'Creative',
          asin: formData?.creative_listing_translation,
        });
      }

      updatedFields.listing_translation_effective_date =
        updatedFormData?.listing_translation_effective_date
          ? updatedFormData?.listing_translation_effective_date
          : dayjs(new Date()).format('YYYY-MM-DD');
      updatedformFields.listing_translation_effective_date =
        formData?.listing_translation_effective_date
          ? formData?.listing_translation_effective_date
          : dayjs(new Date()).format('YYYY-MM-DD');

      modalFields.push({
        label: 'Listing Translation',
        keys,
        fieldSet: listingTranslationData,
      });
    }

    // Advertising services
    const addedAMCServices = getAddedOrRemovedAMCServices(
      formData?.amc_services,
      originalData?.amc_services,
    );
    if (addedAMCServices?.length) {
      addedAMCServices.forEach((service) => {
        service.effective_date = service?.effective_date
          ? service?.effective_date
          : dayjs(new Date()).format('YYYY-MM-DD');
      });

      if (formData.amc_services?.length) {
        formData.amc_services.forEach((obj) => {
          if (!obj?.id) {
            obj.effective_date = obj?.effective_date
              ? obj?.effective_date
              : dayjs(new Date()).format('YYYY-MM-DD');
          }
        });
      }

      updatedFields.amc_services = {
        ...updatedFormData?.amc_services,
        create: formData.amc_services,
      };

      updatedformFields.amc_services = formData?.amc_services;

      modalFields.push({
        label: 'Advertising Services',
        key: ['amc_services'],
        data: [...addedAMCServices],
      });
    }

    // Standard service
    const standardUsServicesUniqueResult =
      formData?.standard_us_services?.filter((obj) => {
        return !originalData?.standard_us_services?.some((obj2) => {
          return obj.name === obj2.name;
        });
      });
    if (standardUsServicesUniqueResult?.length) {
      standardUsServicesUniqueResult.forEach((service) => {
        service.effective_date = service?.effective_date
          ? service?.effective_date
          : dayjs(new Date()).format('YYYY-MM-DD');
      });

      if (formData.standard_us_services?.length) {
        formData.standard_us_services.forEach((obj) => {
          if (!obj?.id) {
            obj.effective_date = obj?.effective_date
              ? obj?.effective_date
              : dayjs(new Date()).format('YYYY-MM-DD');
          }
        });
      }

      updatedFields.standard_us_services = {
        ...updatedFormData?.standard_us_services,
        create: standardUsServicesUniqueResult,
      };
      updatedformFields.standard_us_services = formData?.standard_us_services;

      // if (standardUsServicesUniqueResult?.length) {
      modalFields.push({
        label: 'Standard Service',
        key: ['standard_us_services'],
        data: standardUsServicesUniqueResult,
      });
      // }
    }

    // Standard non us servicess
    const nonUSAddedServices = getNonUSStdServicesOfAddendum(
      formData,
      originalData,
    );

    if (
      nonUSAddedServices?.vatRegistration?.length ||
      nonUSAddedServices?.vatFilling?.length ||
      nonUSAddedServices?.internationalService?.length
    ) {
      if (updatedFormData.standard_non_us_services?.create?.length) {
        updatedFormData.standard_non_us_services.create.forEach((obj) => {
          if (!obj?.id) {
            obj.effective_date = obj?.effective_date
              ? obj?.effective_date
              : dayjs(new Date()).format('YYYY-MM-DD');
          }
        });
      }

      if (formData.standard_non_us_services?.length) {
        formData.standard_non_us_services.forEach((obj) => {
          if (!obj?.id) {
            obj.effective_date = obj?.effective_date
              ? obj?.effective_date
              : dayjs(new Date()).format('YYYY-MM-DD');
          }
        });
      }
      updatedFields.standard_non_us_services = {
        ...updatedFormData?.standard_non_us_services,
        create: [
          ...nonUSAddedServices?.vatRegistration,
          ...nonUSAddedServices?.vatFilling,
          ...nonUSAddedServices?.internationalService,
        ],
      };

      updatedformFields.standard_non_us_services =
        formData?.standard_non_us_services;

      modalFields.push({
        label: 'Standard Non-US Service',
        key: ['standard_non_us_services'],
        data: [
          ...nonUSAddedServices?.vatRegistration,
          ...nonUSAddedServices?.vatFilling,
          ...nonUSAddedServices?.internationalService,
        ],
      });
    }

    // fee structure -------------
    if (formData?.seller_type?.label === 'Hybrid') {
      feeStructureAddendumChanges(
        'seller',
        updatedFields,
        updatedformFields,
        modalFields,
        'Seller',
      );
      feeStructureAddendumChanges(
        'vendor',
        updatedFields,
        updatedformFields,
        modalFields,
        'Vendor',
      );
    }
    if (formData?.seller_type?.label === 'Seller') {
      feeStructureAddendumChanges(
        'seller',
        updatedFields,
        updatedformFields,
        modalFields,
        'Seller',
      );
    }
    if (formData?.seller_type?.label === 'Vendor') {
      feeStructureAddendumChanges(
        'vendor',
        updatedFields,
        updatedformFields,
        modalFields,
        'Vendor',
      );
    }

    // additional monthly services or marketplaces
    if (formData?.seller_type?.label === 'Hybrid') {
      monthlyServicesChanges(
        'seller',
        updatedFields,
        updatedformFields,
        modalFields,
        'Seller',
      );
      monthlyServicesChanges(
        'vendor',
        updatedFields,
        updatedformFields,
        modalFields,
        'Vendor',
      );
    }
    if (formData?.seller_type?.label === 'Seller') {
      monthlyServicesChanges(
        'seller',
        updatedFields,
        updatedformFields,
        modalFields,
        'Seller',
      );
    }
    if (formData?.seller_type?.label === 'Vendor') {
      monthlyServicesChanges(
        'vendor',
        updatedFields,
        updatedformFields,
        modalFields,
        'Vendor',
      );
    }

    const sellerMarketplacesAddedResult = getDifferenceMarketplaces(
      'seller',
      'added',
    );
    if (
      sellerMonthlyAddedservices?.length ||
      sellerMarketplacesAddedResult?.length
    ) {
      let sellerService = sellerMonthlyAddedservices;
      if (
        sellerMonthlyAddedservices?.find((item) =>
          item?.name
            ? item?.name === 'DSP Advertising'
            : item?.service?.name === 'DSP Advertising',
        )
      ) {
        sellerService = sellerMonthlyAddedservices?.filter((item) =>
          item?.name
            ? item?.name !== 'DSP Advertising'
            : item?.service?.name !== 'DSP Advertising',
        );
      }
      let list = [];
      if (sellerService?.length) {
        list = [...list, ...sellerService];
      }
      if (sellerMarketplacesAddedResult?.length) {
        list = [...list, ...sellerMarketplacesAddedResult];
      }
      if (list?.length) {
        modalFields.push({
          label: 'Seller Monthly Service',
          key: ['additional_marketplaces', 'additional_monthly_services'],
          data: [...list],
        });
      }
    }

    const vendorMarketplacesAddedResult = getDifferenceMarketplaces(
      'vendor',
      'added',
    );

    if (
      vendorMonthlyAddedservices?.length ||
      vendorMarketplacesAddedResult?.length
    ) {
      let vendorService = vendorMonthlyAddedservices;
      if (
        vendorMonthlyAddedservices?.find((item) =>
          item?.name
            ? item?.name === 'DSP Advertising'
            : item?.service?.name === 'DSP Advertising',
        )
      ) {
        vendorService = vendorMonthlyAddedservices?.filter((item) =>
          item?.name
            ? item?.name !== 'DSP Advertising'
            : item?.service?.name !== 'DSP Advertising',
        );
      }

      let list = [];
      if (vendorService?.length) {
        list = [...list, ...vendorService];
      }
      if (vendorMarketplacesAddedResult?.length) {
        list = [...list, ...vendorMarketplacesAddedResult];
      }
      if (list?.length) {
        modalFields.push({
          label: 'Vendor Monthly Service',
          key: ['additional_marketplaces', 'additional_monthly_services'],
          data: [...list],
        });
      }
    }

    setUpdatedFormData({
      ...updatedFormData,
      ...updatedFields,
    });
    setFormData({
      ...formData,
      ...updatedformFields,
    });

    if (openModal) {
      if (modalFields?.length) {
        setShowEffetivityDatesModal({ show: true, data: modalFields });
      } else {
        nextStep();
      }
    } else {
      setShowEffetivityDatesModal({ show: false, data: modalFields });
    }
  };

  useEffect(() => {
    agreementTemplate().then((response) => {
      setData(response?.data?.results && response.data.results[0]);
      getContractDetails();
      if (contractAddendumDetails?.notActive) {
        getContractAddendumDetails(
          contractAddendumDetails?.id,
          contractAddendumDetails?.status,
        );
        executeScroll('draft-addendum');
      }
    });

    getThresholdType().then((thresholdType) => {
      setThresholdTypeOptions(thresholdType);
    });

    getYoyPercentage().then((yoyPercentage) => {
      setYoyPercentageOptions(yoyPercentage);
    });

    getMarketplaces().then((market) => {
      setMarketPlaces(market?.data);
      setAdditionalMarketplaces(market?.data);
      setMarketplacesResult(market?.data);
    });

    getNonUSStandardServicesMarketplaces().then((res) => {
      setVatMarketplaces(res);
    });

    getServicesFee().then((res) => {
      setServicesFees(res?.data);
    });

    getStandardServices().then((service) => {
      setOriginalStandardServices(service?.data);
      setStdServices(service?.data);
    });

    getAgencyFee().then((fee) => {
      setAgencyFeeOptions(fee?.data);
    });

    getOneTimeService().then((r) => {
      setOneTimeService(r?.data?.filter((op) => !op.is_deleted));

      if (r?.data?.length) {
        const result = [];
        r.data.forEach((item) => {
          if (item.label.includes('Amazon Store Package')) {
            result.push({ value: item.value, label: item.label });
          }
        });
        const list = result.filter((item) =>
          item.label.includes('Amazon Store Package'),
        );
        list.filter((item) => {
          const serviceName = item.label.split(' ')[3];
          item.label = serviceName;
          return item;
        });
        setAmazonStoreOptions(list);
      }
    });
  }, [dispatch, id]);

  const discardAgreementChanges = (flag) => {
    if (flag === 'No') {
      if (
        contractAddendumDetails?.notActive &&
        showDiscardModal?.openAfterEffectivityDateModal
      ) {
        setShowEffetivityDatesModal({
          ...showEffetivityDatesModal,
          show: true,
        });
      }
      setShowDiscardModal({ ...showDiscardModal, show: false, clickedBtn: '' });
    }
    if (flag === 'Yes') {
      setShowDiscardModal({ ...showDiscardModal, show: false, clickedBtn: '' });
      setUpdatedFormData({});
      if (showDiscardModal.clickedBtn === 'back') {
        history.push(PATH_CUSTOMER_DETAILS.replace(':id', id));
      }
      setSectionError({});
      setFormData(details);
      showFooter(false);
      setIsEditContract(false);
      setShowEditor(false);
      setStartDate('');
      setNewAddendum(originalAddendumData);
      clearError();
      setShowAmazonPlanDropdown(false);
      setAmazonStoreCustom(false);

      setCalculatedDate(null);
      setFirstMonthDate(null);
      setSecondMonthDate(null);
      setThirdMonthDate(null);
      setEndDate(null);
      setMarketplaceDropdownData();
      getContractDetails();
      if (contractAddendumDetails?.notActive) {
        getContractAddendumDetails(
          contractAddendumDetails?.id,
          contractAddendumDetails?.status,
        );
        executeScroll('draft-addendum');
      }
      setShowAmazonStorefrontDropdown(false);
    }
  };

  const editAgreementChanges = (flag) => {
    if (flag === 'No') {
      setShowEditContractConfirmationModal(false);
    }
    if (flag === 'Yes') {
      const contractDataToUpdate = {
        contract_status: 'pending contract',
      };
      const addendumDataToUpdate = {
        addendum_status: 'pending addendum',
      };
      setIsLoading({ loader: true, type: 'page' });
      setLoaderFlag(false);
      const contractOrAddendum = contractAddendumDetails?.notActive
        ? saveDraftAddendum(addendumDetails.id, addendumDataToUpdate)
        : updateAccountDetails(details.id, contractDataToUpdate);
      contractOrAddendum.then((response) => {
        if (response && response.status === 200) {
          getContractDetails();
          if (contractAddendumDetails?.notActive) {
            getContractAddendumDetails(
              contractAddendumDetails?.id,
              contractAddendumDetails?.status,
            );
            executeScroll('draft-addendum');
          }
          setIsEditContract(true);
        }
      });
      setShowEditContractConfirmationModal(false);
    }
  };

  const viewContract = () => {
    if (!contractAddendumDetails.notActive) {
      return (
        <div className="text-container ">
          <div id="agreement">
            <Agreement
              formData={formData}
              details={details}
              templateData={data}
              servicesFees={servicesFees}
              discountData={discountData}
              mapServiceTotal={mapServiceTotal}
              selectedCurrency={selectedCurrency}
              displayBBELogo={displayBBELogo}
              startDate={startDate}
            />
          </div>

          {details?.contract_type?.toLowerCase().includes('one') ||
          details?.contract_type?.toLowerCase().includes('dsp') ? null : (
            <div id="statement">
              <Statement
                formData={formData}
                details={details}
                templateData={data}
                notIncludedOneTimeServices={notIncludedOneTimeServices}
                notIncludedMonthlyServices={notIncludedMonthlyServices}
                servicesFees={servicesFees}
                discountData={discountData}
                selectedCurrency={selectedCurrency}
                displayMarketplaceName={displayMarketplaceName}
                notIncludedOneTimeServicesNew={notIncludedOneTimeServicesNew}
                displayListingOptimisationInfo={displayListingOptimisationInfo}
                displayRevisionPolicyInfo={displayRevisionPolicyInfo}
                displayBBELogo={displayBBELogo}
                startDate={startDate}
              />
            </div>
          )}

          {details?.contract_type?.toLowerCase().includes('dsp') ||
          (showSection.dspAddendum &&
            !details?.contract_type?.toLowerCase().includes('one')) ? (
            <div id="dspAddendum">
              <DSPAddendum
                formData={formData}
                templateData={data}
                setCalculatedDate={setCalculatedDate}
                firstMonthDate={firstMonthDate}
                secondMonthDate={secondMonthDate}
                thirdMonthDate={thirdMonthDate}
                endMonthDate={endMonthDate}
                selectedCurrency={selectedCurrency}
                setFormData={setFormData}
                setUpdatedFormData={setUpdatedFormData}
                updatedFormData={updatedFormData}
                showFooter={showFooter}
                originalData={originalData}
                setDspAdvertisingMaxDate={setDspAdvertisingMaxDate}
                changedDspStartDateFlag={changedDspStartDateFlag}
                displayFirstMonthDateRange={displayFirstMonthDateRange}
                returnDspMonthlyDates={returnDspMonthlyDates}
                returnDspMonthAndDays={returnDspMonthAndDays}
                displayMarketplaceName={displayMarketplaceName}
                displayBBELogo={displayBBELogo}
                startDate={startDate}
                mapMonthlyServices={mapMonthlyServices}
                mapMonthlyServiceTotal={mapMonthlyServiceTotal}
              />
            </div>
          ) : (
            ''
          )}
          {showSection?.addendum ? (
            <div id="addendum">
              <Addendum
                formData={formData}
                details={details}
                templateData={data}
                notIncludedOneTimeServices={notIncludedOneTimeServices}
                notIncludedMonthlyServices={notIncludedMonthlyServices}
                newAddendumData={newAddendumData}
                setNewAddendum={setNewAddendum}
                showEditor={showEditor}
                showFooter={showFooter}
                setShowEditor={setShowEditor}
                onEditAddendum={onEditAddendum}
                setUpdatedFormData={setUpdatedFormData}
                updatedFormData={updatedFormData}
                contractAddendumDetails={contractAddendumDetails}
                originalData={originalData}
                selectedCurrency={selectedCurrency}
                getServicesAccordingToAccType={getServicesAccordingToAccType}
                mapMonthlyServices={mapMonthlyServices}
                mapAdditionalMarketPlaces={mapAdditionalMarketPlaces}
                mapMonthlyServiceTotal={mapMonthlyServiceTotal}
                mapVariableMonthlyService={mapVariableMonthlyService}
                addendumDetails={addendumDetails}
                displayMarketplaceName={displayMarketplaceName}
                setVatMarketplaces={setVatMarketplaces}
                discountData={discountData}
                displayBBELogo={displayBBELogo}
                startDate={startDate}
                firstMonthDate={firstMonthDate}
                secondMonthDate={secondMonthDate}
                thirdMonthDate={thirdMonthDate}
                returnDspMonthlyDates={returnDspMonthlyDates}
                getAddedOrRemovedAMCServices={getAddedOrRemovedAMCServices}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      );
    }
    return (
      <div className="text-container ">
        <div id="draft-addendum">
          <Addendum
            formData={formData}
            details={details}
            templateData={data}
            notIncludedOneTimeServices={notIncludedOneTimeServices}
            notIncludedMonthlyServices={notIncludedMonthlyServices}
            newAddendumData={newAddendumData}
            setNewAddendum={setNewAddendum}
            showEditor={showEditor}
            showFooter={showFooter}
            setShowEditor={setShowEditor}
            onEditAddendum={onEditAddendum}
            setUpdatedFormData={setUpdatedFormData}
            updatedFormData={updatedFormData}
            contractAddendumDetails={contractAddendumDetails}
            originalData={originalData}
            selectedCurrency={selectedCurrency}
            getServicesAccordingToAccType={getServicesAccordingToAccType}
            mapMonthlyServices={mapMonthlyServices}
            mapAdditionalMarketPlaces={mapAdditionalMarketPlaces}
            mapMonthlyServiceTotal={mapMonthlyServiceTotal}
            mapVariableMonthlyService={mapVariableMonthlyService}
            addendumDetails={addendumDetails}
            displayMarketplaceName={displayMarketplaceName}
            setVatMarketplaces={setVatMarketplaces}
            discountData={discountData}
            displayBBELogo={displayBBELogo}
            startDate={startDate}
            firstMonthDate={firstMonthDate}
            secondMonthDate={secondMonthDate}
            thirdMonthDate={thirdMonthDate}
            returnDspMonthlyDates={returnDspMonthlyDates}
            getAddedOrRemovedAMCServices={getAddedOrRemovedAMCServices}
          />
        </div>
      </div>
    );
  };

  const renderEditContractBtn = (btnClass) => {
    return (
      <>
        <Button
          data-tip={SpreetailRoleRestrictMessage}
          data-for={isSpreetailUser ? 'spreetailRetrictEditAgreement' : ''}
          className={`${btnClass} on-boarding  mt-3  ${
            isEditContract ? 'w-sm-50' : 'w-sm-100'
          }
        `}
          onClick={() => showEditView(formData)}
          disabled={isSpreetailUser}
        >
          {`${
            contractAddendumDetails.notActive
              ? 'Edit Addendum'
              : 'Edit Agreement'
          }`}
        </Button>
        {isSpreetailUser ? (
          <ReactTooltip
            id="spreetailRetrictEditAgreement"
            aria-haspopup="true"
            place="top"
            effect="solid"
            html
          />
        ) : null}
      </>
    );
  };

  const displayRightSidePanel = () => {
    return (
      <AgreementSidePanel
        id={id}
        setFormData={setFormData}
        formData={formData}
        apiError={apiError}
        loader={isLoading.loader}
        setContractLoading={setIsLoading}
        agreementData={details}
        editContractFlag={editContractFlag}
        setEditContractFlag={setEditContractFlag}
        setNotIncludedOneTimeServices={setNotIncludedOneTimeServices}
        notIncludedOneTimeServices={notIncludedOneTimeServices}
        showFooter={showFooter}
        newAddendumData={newAddendumData}
        setNewAddendum={setNewAddendum}
        showEditor={showEditor}
        setShowEditor={setShowEditor}
        onEditAddendum={onEditAddendum}
        setApiError={setApiError}
        executeScroll={executeScroll}
        showSection={showSection}
        setShowCollpase={setShowCollpase}
        updatedFormData={updatedFormData}
        setUpdatedFormData={setUpdatedFormData}
        additionalMonthlyServices={additionalMonthlyServices}
        setMonthlyAdditionalServices={setMonthlyAdditionalServices}
        originalData={originalData}
        additionalMarketplacesData={additionalMarketplacesData}
        setAdditionalMarketplace={setAdditionalMarketplace}
        additionalOnetimeServices={additionalOnetimeServices}
        setAdditionalOnetimeServices={setAdditionalOnetimeServices}
        notIncludedMonthlyServices={notIncludedMonthlyServices}
        additionalMarketplaceError={additionalMarketplaceError}
        setAdditionalMarketplaceError={setAdditionalMarketplaceError}
        additionalMonthlySerError={additionalMonthlySerError}
        setAdditionalMonthlySerError={setAdditionalMonthlySerError}
        additionalOnetimeSerError={additionalOnetimeSerError}
        setAdditionalOnetimeSerError={setAdditionalOnetimeSerError}
        contractError={contractError}
        setContractError={setContractError}
        setOriginalAddendumData={setOriginalAddendumData}
        openCollapse={openCollapse}
        setOpenCollapse={setOpenCollapse}
        amazonStoreCustom={amazonStoreCustom}
        setAmazonStoreCustom={setAmazonStoreCustom}
        showAmazonPlanDropdown={showAmazonPlanDropdown}
        setShowAmazonPlanDropdown={setShowAmazonPlanDropdown}
        showAdditionalMarketplace={showAdditionalMarketplace}
        setShowAdditionalMarketplace={setShowAdditionalMarketplace}
        startDate={startDate}
        setStartDate={setStartDate}
        setShowDiscountModal={setShowDiscountModal}
        sectionError={sectionError}
        setSectionError={setSectionError}
        setDiscountFlag={setDiscountFlag}
        isEditContract={isEditContract}
        setMarketplacesResult={setMarketplacesResult}
        marketplacesResult={marketplacesResult}
        marketPlaces={marketPlaces}
        setMarketPlaces={setMarketPlaces}
        additionalMarketplaces={additionalMarketplaces}
        setAdditionalMarketplaces={setAdditionalMarketplaces}
        firstMonthDate={firstMonthDate}
        setPageNumber={setPageNumber}
        getContractActivityLogInfo={getContractActivityLogInfo}
        activityLoader={activityLoader}
        activityData={activityData}
        images={images}
        activityCount={activityCount}
        pageNumber={pageNumber}
        isApicalled={isApicalled}
        getContractDetails={getContractDetails}
        setIsEditContract={setIsEditContract}
        customerError={customerError}
        setCustomerErrors={setCustomerErrors}
        isDocRendered={downloadApiCall}
        oneTimeService={oneTimeService}
        monthlyService={monthlyService}
        AmazonStoreOptions={AmazonStoreOptions}
        fetchUncommonOptions={fetchUncommonOptions}
        originalAddendumData={originalAddendumData}
        thresholdTypeOptions={thresholdTypeOptions}
        yoyPercentageOptions={yoyPercentageOptions}
        checkContractStatus={checkContractStatus}
        setMarketplaceDropdownData={setMarketplaceDropdownData}
        discountData={discountData}
        setSelectedDiscount={setSelectedDiscount}
        feeStructureErrors={feeStructureErrors}
        setFeeStructureErrors={setFeeStructureErrors}
        getMonthlyServices={getMonthlyServices}
        showRightTick={showRightTick}
        getFeeStructureDetails={getFeeStructureDetails}
        manageErrorCount={manageErrorCount}
        checkMandatoryFieldsOfFeeType={checkMandatoryFieldsOfFeeType}
        servicesFees={servicesFees}
        getData={getData}
        setSelectedCurrency={setSelectedCurrency}
        selectedCurrency={selectedCurrency}
        stdServices={stdServices}
        setNonUSserviceError={setNonUSserviceError}
        nonUsServiceError={nonUsServiceError}
        agencyFeeOptions={agencyFeeOptions}
        dspAdditionalMarketplaces={dspAdditionalMarketplaces}
        showAmazonStorefrontDropdown={showAmazonStorefrontDropdown}
        setShowAmazonStorefrontDropdown={setShowAmazonStorefrontDropdown}
        details={details}
        disableCombineRevShare={disableCombineRevShare}
        contractAddendumDetails={contractAddendumDetails}
        addendumDetails={addendumDetails}
        calculatedDate={calculatedDate}
        setCalculatedDate={setCalculatedDate}
        setFirstMonthDate={setFirstMonthDate}
        secondMonthDate={secondMonthDate}
        setSecondMonthDate={setSecondMonthDate}
        thirdMonthDate={thirdMonthDate}
        setThirdMonthDate={setThirdMonthDate}
        endMonthDate={endMonthDate}
        setEndDate={setEndDate}
        setCheckAddendumData={setCheckAddendumData}
        checkAddendumData={checkAddendumData}
        role={userInfo.role}
        dspAdvertisingMaxDate={dspAdvertisingMaxDate}
        setDspAdvertisingMaxDate={setDspAdvertisingMaxDate}
        changedDspStartDateFlag={changedDspStartDateFlag}
        setChangedDspStartDateFlag={setChangedDspStartDateFlag}
        dspMarketpalcesError={dspMarketpalcesError}
        setDspMarketpalcesError={setDspMarketpalcesError}
        displayMarketplaceName={displayMarketplaceName}
        setVatMarketplaces={setVatMarketplaces}
        combinedOneTimeServices={combinedOneTimeServices}
        filterNotIncludedOneTimeServices={filterNotIncludedOneTimeServices}
        savedMarketplaces={savedMarketplaces}
        returnDspMonthlyDates={returnDspMonthlyDates}
        advertisingServices={advertisingServices}
        amcServiceError={amcServiceError}
        setAmcServiceError={setAmcServiceError}
        getAddedOrRemovedAMCServices={getAddedOrRemovedAMCServices}
      />
    );
  };

  const displayFooter = () => {
    return (
      <ContractFooter
        details={details}
        setParams={setParams}
        setShowModal={setShowModal}
        isEditContract={isEditContract}
        onEditcontract={onEditcontract}
        isLoading={isLoading}
        isFooter={isFooter}
        formData={formData}
        newAddendumData={newAddendumData}
        updatedFormData={updatedFormData}
        setUpdatedFormData={setUpdatedFormData}
        showEditor={showEditor}
        nextStep={nextStep}
        setShowDiscardModal={setShowDiscardModal}
        checkApprovalCondition={checkApprovalCondition}
        showRightTick={showRightTick}
        setIsEditContract={setIsEditContract}
        renderEditContractBtn={renderEditContractBtn}
        showDiscardModal={showDiscardModal}
        createAgreementDoc={createAgreementDoc}
        setIsLoading={setIsLoading}
        getContractDetails={getContractDetails}
        contractAddendumDetails={contractAddendumDetails}
        getContractActivityLogInfo={getContractActivityLogInfo}
        setFormData={setFormData}
        getMonthlyServicesofContract={getMonthlyServicesofContract}
        getDifferenceMarketplaces={getDifferenceMarketplaces}
        originalData={originalData}
        displayMarketplaceName={displayMarketplaceName}
        additionalMarketplaces={additionalMarketplaces}
        handleAddendumModal={handleAddendumModal}
        showEffetivityDatesModal={showEffetivityDatesModal}
        setShowEffetivityDatesModal={setShowEffetivityDatesModal}
      />
    );
  };

  const closeDiscountModal = () => {
    const discountUpdatedData = {
      monthly_discount_amount: details.monthly_discount_amount,
      monthly_discount_type: details.monthly_discount_type,
      one_time_discount_amount: details.one_time_discount_amount,
      one_time_discount_type: details.one_time_discount_type,
    };
    setFormData({ ...formData, ...discountUpdatedData });
    setShowDiscountModal(false);
  };

  const removeParams = (item) => {
    delete params[item];
    const stringified =
      queryString &&
      queryString.stringify({
        ...params,
      });
    history.push({
      pathname: `${history.location.pathname}`,
      search: `${stringified}`,
      state: `${history.location.state}`,
    });
    setIsEditContract(false);
  };

  const renderContractTabHtml = () => {
    return (
      <>
        <ContractTab className="d-lg-none d-block">
          <ul style={{ textAlign: 'center' }} className="tabs">
            <li
              style={nonDraftCustomStyles}
              className={tabInResponsive === 'view-contract' ? 'active' : ''}
              role="presentation"
              onClick={() => showTabInResponsive('view-contract')}
            >
              View Contract
            </li>

            <li
              style={nonDraftCustomStyles}
              className={tabInResponsive === 'edit-fields' ? 'active' : ''}
              role="presentation"
              onClick={() => showTabInResponsive('edit-fields')}
            >
              {isEditContract ? 'Edit Fields' : 'Activity'}
            </li>
          </ul>
        </ContractTab>
      </>
    );
  };

  const handledOnCloseAgreement = () => {
    if (history?.location?.state && history?.location?.state !== 'undefined') {
      if (history?.location?.state.includes('agreement')) {
        const baseUrl = history?.location?.pathname.split('agreement')?.[0];
        history.push(`${baseUrl}${history?.location?.state}`, 'agreement');
      } else {
        history.push(history?.location?.state);
      }
    } else {
      history.push(PATH_CUSTOMER_LIST);
    }
  };

  const isShowEditIconInAddendum = () => {
    if (contractAddendumDetails?.notActive) {
      if (isEditContract) {
        // DSP Service

        const dspServiceAdded =
          formData?.is_dsp_advertising &&
          formData?.is_dsp_advertising !== originalData?.is_dsp_advertising;

        // Listing optimization
        const listingOptimizationAdded =
          checkConditions(
            originalData?.content_optimization,
            formData?.content_optimization,
          ) ||
          checkConditions(
            originalData?.design_optimization,
            formData?.design_optimization,
          ) ||
          checkConditions(
            originalData?.photography_optimization,
            formData?.photography_optimization,
          );

        // Listing Translation
        const listingTranslation =
          checkConditions(
            originalData?.copy_listing_translation,
            formData?.copy_listing_translation,
          ) ||
          checkConditions(
            originalData?.creative_listing_translation,
            formData?.creative_listing_translation,
          );

        // Advertising services
        const addedAMCServices = getAddedOrRemovedAMCServices(
          formData?.amc_services,
          originalData?.amc_services,
        );

        // Standard US services
        const standardUsServicesUniqueResult =
          formData?.standard_us_services?.filter((obj) => {
            return !originalData?.standard_us_services?.some((obj2) => {
              return obj.name === obj2.name;
            });
          });
        const standardUSServicesAdded = standardUsServicesUniqueResult?.length;

        // Standard non us servicess
        const nonUSAddedServices = getNonUSStdServicesOfAddendum(
          formData,
          originalData,
        );

        const standardNonUsServicesAdded =
          nonUSAddedServices?.vatRegistration?.length ||
          nonUSAddedServices?.vatFilling?.length ||
          nonUSAddedServices?.internationalService?.length;

        // Monthly services and marketplaces
        const serviceAddedResult = getMonthlyServicesofContract(
          'seller',
          'added',
        );
        const marketplacesAddedResult = getDifferenceMarketplaces(
          'seller',
          'added',
        );
        const vendorServiceAddedResult = getMonthlyServicesofContract(
          'vendor',
          'added',
        );
        const vendorMarketplacesAddedResult = getDifferenceMarketplaces(
          'vendor',
          'added',
        );

        const monthlyServices =
          serviceAddedResult?.length ||
          marketplacesAddedResult?.length ||
          vendorServiceAddedResult?.length ||
          vendorMarketplacesAddedResult?.length;

        // fee structure
        const feeStructureAdded =
          formData?.fee_structure?.seller?.effective_date ||
          formData?.fee_structure?.vendor?.effective_date;

        if (
          dspServiceAdded ||
          listingOptimizationAdded ||
          listingTranslation ||
          standardUSServicesAdded ||
          standardNonUsServicesAdded ||
          feeStructureAdded ||
          monthlyServices ||
          addedAMCServices
        ) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const renderHeaderDownloadFuntionality = () => {
    return (
      <>
        <HeaderDownloadFuntionality>
          <div
            className={
              userInfo && userInfo.role?.includes('Customer')
                ? ' customer-pdf'
                : ''
            }
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-sm-12">Agreement Management</div>
                <div className="col-md-6 col-sm-12">
                  <ul className="contract-download-nav ">
                    {isShowEditIconInAddendum() ? (
                      <>
                        <li>
                          {' '}
                          <FontAwesomeIcon
                            icon="fa-regular fa-pen"
                            style={{ color: '#FF5933', fontSize: '12px' }}
                            className="cursor  pl-1"
                            onClick={() => handleAddendumModal(true)}
                            data-tip="Edit a previously saved effective date"
                            data-for="contract-edit-effectivity-date"
                          />
                          <ReactTooltip
                            id="contract-edit-effectivity-date"
                            aria-haspopup="true"
                            place="bottom"
                            effect="solid"
                            html
                          />
                        </li>
                        <li>
                          <span className="divide-arrow hide-mobile" />
                        </li>
                      </>
                    ) : (
                      ''
                    )}
                    {isFooter ||
                    (newAddendumData?.id &&
                      showEditor &&
                      updatedFormData?.addendum) ? (
                      ''
                    ) : (
                      <>
                        {details?.contract_url ? (
                          <>
                            <li
                              className={
                                detailsId &&
                                contractDesignLoader !== null &&
                                !contractDesignLoader &&
                                enableDownloadBtn
                                  ? 'download-pdf'
                                  : 'download-pdf disabled'
                              }
                            >
                              <a
                                className="download-pdf-link"
                                href={
                                  details?.contract_url
                                    ? details?.contract_url
                                    : null
                                }
                                onClick={() =>
                                  setHelloSignEnterDateTextFlag(false)
                                }
                                download
                              >
                                <img
                                  src={OrangeDownloadPdf}
                                  alt="download"
                                  className="download-pdf-icon "
                                  role="presentation"
                                />
                                Download
                              </a>
                            </li>
                            <li>
                              <span className="divide-arrow hide-mobile" />
                            </li>
                          </>
                        ) : null}
                      </>
                    )}

                    <li>
                      <img
                        width="18px"
                        src={CloseIcon}
                        alt="close"
                        className="float-right cursor remove-cross-icon"
                        onClick={() => {
                          handledOnCloseAgreement();
                        }}
                        role="presentation"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </HeaderDownloadFuntionality>
      </>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      {showPageNotFound ? (
        <PageNotFound />
      ) : checkContractStatus() ? (
        <>
          {renderContractTabHtml()}
          <div className="on-boarding-container">
            {renderHeaderDownloadFuntionality()}
            {isLoading.loader && isLoading.type === 'page' ? (
              <PageLoader
                className="modal-loader"
                color="#FF5933"
                type="page"
                width={40}
                component="agreement"
              />
            ) : (
              <>
                <>
                  {isDesktop ||
                  (isTablet && tabInResponsive === 'view-contract') ||
                  (isMobile && tabInResponsive === 'view-contract') ? (
                    <>
                      <div>
                        <PdfViewer
                          pdf={details?.contract_url}
                          loadingMsg="Loading Contract Document..."
                          setShowAddendum={setShowAddendum}
                          executeScroll={executeScroll}
                          contractAddendumDetails={contractAddendumDetails}
                        />
                      </div>
                      {contractAddendumDetails?.notActive && showAddendum ? (
                        <>{viewContract()}</>
                      ) : null}
                    </>
                  ) : (
                    ''
                  )}
                </>
                {isDesktop ||
                (isTablet && tabInResponsive === 'edit-fields') ||
                (isMobile && tabInResponsive === 'edit-fields') ||
                contractAddendumDetails.notActive
                  ? displayRightSidePanel()
                  : ''}
                {contractStatusValue === 'pending for cancellation' ||
                contractStatusValue === 'pending cancellation approval' ||
                contractStatusValue === 'active pending for pause' ||
                contractAddendumDetails.notActive
                  ? displayFooter()
                  : ''}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {renderContractTabHtml()}
          <div className="on-boarding-container">
            {renderHeaderDownloadFuntionality()}
            {(isLoading.loader && isLoading.type === 'page') ||
            activityLoader ? (
              <PageLoader
                className="modal-loader"
                color="#FF5933"
                type="page"
                width={40}
                component="agreement"
              />
            ) : detailsId ? (
              <>
                {isDesktop ||
                (isTablet && tabInResponsive === 'view-contract') ||
                (isMobile && tabInResponsive === 'view-contract')
                  ? viewContract()
                  : ''}
                {isDesktop ||
                (isTablet && tabInResponsive === 'edit-fields') ||
                (isMobile && tabInResponsive === 'edit-fields')
                  ? displayRightSidePanel()
                  : ''}
                {(detailsId &&
                  isLoading.loader &&
                  isLoading.type === 'button') ||
                (!isLoading.loader && isLoading.type === 'page')
                  ? displayFooter()
                  : null}
              </>
            ) : (
              ''
            )}
          </div>
        </>
      )}
      <RequestSignatureModal
        showModal={showModal}
        setShowModal={setShowModal}
        removeParams={removeParams}
        setShowEditor={setShowEditor}
        id={id}
        details={details}
        pdfData={pdfData}
        setOpenCollapse={setOpenCollapse}
        getContractDetails={getContractDetails}
        setIsLoading={setIsLoading}
        setIsEditContract={setIsEditContract}
        contractAddendumDetails={contractAddendumDetails}
        originalData={originalData}
        setHelloSignEnterDateTextFlag={setHelloSignEnterDateTextFlag}
        newAddendumData={newAddendumData}
        agreementFormData={formData}
        selectedCurrency={selectedCurrency}
      />

      <DiscardChangesConfirmation
        showDiscardModal={showDiscardModal}
        discardAgreementChanges={discardAgreementChanges}
      />

      <DiscountModal
        showDiscountModal={showDiscountModal}
        closeDiscountModal={closeDiscountModal}
        details={details}
        setShowDiscountModal={setShowDiscountModal}
        formData={formData}
        setFormData={setFormData}
        discountFlag={discountFlag}
        setIsEditContract={setIsEditContract}
        setDetails={setDetails}
        discountData={discountData}
        selectedDiscount={selectedDiscount}
        getDiscountData={getDiscountData}
        updatedFormData={updatedFormData}
        getServicesAccordingToAccType={getServicesAccordingToAccType}
        getContractActivityLogInfo={getContractActivityLogInfo}
        selectedCurrency={selectedCurrency}
        contractAddendumDetails={contractAddendumDetails}
        originalData={originalData}
        getContractDetails={getContractDetails}
      />

      <ContractEditConfirmation
        showEditContractConfirmationModal={showEditContractConfirmationModal}
        editAgreementChanges={editAgreementChanges}
        contractAddendumDetails={contractAddendumDetails}
      />

      {showEffetivityDatesModal.show &&
      showEffetivityDatesModal?.data?.length ? (
        <AddedServicesEffectivityDatesModal
          showEffetivityDatesModal={showEffetivityDatesModal}
          isLoading={isLoading}
          setShowEffetivityDatesModal={setShowEffetivityDatesModal}
          nextStep={nextStep}
          updatedFormData={updatedFormData}
          setUpdatedFormData={setUpdatedFormData}
          formData={formData}
          setFormData={setFormData}
          getMonthlyServicesofContract={getMonthlyServicesofContract}
          getDifferenceMarketplaces={getDifferenceMarketplaces}
          contractAddendumDetails={contractAddendumDetails}
          originalData={originalData}
          additionalMarketplaces={additionalMarketplaces}
          setShowDiscardModal={setShowDiscardModal}
          showDiscardModal={showDiscardModal}
          checkConditions={checkConditions}
          getObjectDifference={getObjectDifference}
          getAddedOrRemovedAMCServices={getAddedOrRemovedAMCServices}
        />
      ) : null}
    </>
  );
}
