import React from 'react';

import Modal from 'react-modal';
import dayjs from 'dayjs';
import { ToastContainer } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { bool, func, shape, string } from 'prop-types';

import {
  getInvoiceStatusKey,
  invoiceStatusIcons,
  StatusColorSet,
} from '../../../../../../constants';
import { ModalBox, Status } from '../../../../../../common';
import {
  ArrowRightBlackIcon,
  ArrowRightIcon,
  CloseIcon,
  LeftArrowIcon,
} from '../../../../../../theme/images';

const getSymbolFromCurrency = require('currency-symbol-map');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '600px ',
    width: '100% ',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const InvoiceRequestViewModal = ({
  id,
  isOpen,
  style,
  onClick,
  onApply,
  adjustmentDetails,
}) => {
  const isOneTime = adjustmentDetails?.dsp_invoice_subtype === 'one time';
  const isPauseInvoice = adjustmentDetails?.is_sent_for_pause;
  const previousMonth = new Date(adjustmentDetails?.applicable_from);
  previousMonth.setMonth(previousMonth.getMonth() - 1);

  const status = adjustmentDetails?.status
    ? adjustmentDetails?.status
    : 'created';

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const generateAmount = (value, valueFor, currencySymbol) => {
    if (value && value !== null && value !== 0) {
      value = Number(value.toFixed(2));
      return `${
        value < 0 ? '-' : valueFor === 'change' ? '+' : ''
      }${currencySymbol}${value
        .toString()
        .replace('-', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }

    return valueFor === 'change' ? '-' : `${currencySymbol}0`;
  };

  const renderDesktopView = () => {
    return (
      <div>
        <div className="row">
          {isOneTime ? (
            <>
              <div className="col-6 text-left">
                <div className="label">Marketplace</div>
              </div>
              <div className="col-6 text-right">
                <div className="label">Additional Amount</div>
              </div>
            </>
          ) : (
            <>
              <div className="col-4 text-left">
                <div className="label">Marketplace</div>
              </div>
              <div className="col-3 text-left">
                <div className="label">From</div>
              </div>
              <div className="col-2 text-left">
                <div className="label">To</div>
              </div>
              <div className="col-3 text-left">
                <div className="label">Change</div>
              </div>
            </>
          )}
        </div>
        <div className=" straight-line horizontal-line pt-1 mb-2 " />
        {adjustmentDetails?.adjustments &&
        adjustmentDetails?.adjustments.length >= 1
          ? adjustmentDetails?.adjustments.map((item, index) => (
              <div key={item.id} className={index ? 'row mt-1' : 'row'}>
                <div className="col-4 text-left">
                  <div
                    className={`normal-text ${
                      item?.new_budget - item?.old_budget ? 'text-bold' : null
                    } `}
                  >
                    {item?.marketplace}
                  </div>
                </div>
                {!isOneTime ? (
                  <>
                    <div className="col-2 text-left">
                      <div
                        className={`normal-text ${
                          item?.new_budget - item?.old_budget
                            ? 'text-bold'
                            : null
                        } `}
                      >
                        {generateAmount(
                          item?.old_budget,
                          'from',
                          getSymbolFromCurrency(item?.local_currency),
                        )}
                      </div>
                    </div>
                    <div className="col-1 text-left">
                      <div className="normal-text">
                        <img
                          src={ArrowRightBlackIcon}
                          width="18px"
                          alt="arrow"
                        />{' '}
                      </div>
                    </div>
                  </>
                ) : null}

                <div
                  className={isOneTime ? 'col-8 text-right' : 'col-2 text-left'}
                >
                  <div
                    className={`normal-text ${
                      item?.new_budget - item?.old_budget ? 'text-bold' : null
                    } `}
                  >
                    {generateAmount(
                      item?.new_budget,
                      'to',
                      getSymbolFromCurrency(item?.local_currency),
                    )}
                  </div>
                </div>

                {!isOneTime ? (
                  <div className="col-3 text-left">
                    <div
                      className={`normal-text ${
                        item?.new_budget - item?.old_budget ? 'text-bold' : null
                      } `}
                    >
                      {generateAmount(
                        item?.new_budget - item?.old_budget,
                        'change',
                        getSymbolFromCurrency(item?.local_currency),
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            ))
          : null}

        <div className=" straight-line horizontal-line pt-1 mb-2 " />
        <div className="row">
          <div className="col-4 text-left">
            <div className="normal-text text-bold">
              {isOneTime ? 'One-time invoice' : 'Total invoice'}
            </div>
          </div>
          {!isOneTime ? (
            <>
              <div
                className={isOneTime ? 'col-4 text-left' : 'col-2 text-left'}
              >
                <div className="normal-text text-bold">
                  {generateAmount(
                    adjustmentDetails?.from_amount,
                    '',
                    getSymbolFromCurrency(adjustmentDetails?.global_currency),
                  )}
                </div>
              </div>

              <div className="col-1 text-left">
                <div className="normal-text">
                  <img src={ArrowRightBlackIcon} width="18px" alt="arrow" />{' '}
                </div>
              </div>
            </>
          ) : null}
          {isOneTime ? <div className="col-4 text-left" /> : null}

          <div className={isOneTime ? 'col-4 text-right' : 'col-2 text-left'}>
            <div className="normal-text text-bold">
              {generateAmount(
                adjustmentDetails?.to_amount,
                '',
                getSymbolFromCurrency(adjustmentDetails?.global_currency),
              )}
            </div>
          </div>
          {!isOneTime ? (
            <div className="col-3 text-left">
              <div className="normal-text text-bold">
                {generateAmount(
                  adjustmentDetails?.to_amount - adjustmentDetails?.from_amount,
                  'change',
                  getSymbolFromCurrency(adjustmentDetails?.global_currency),
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <div className="d-md-none d-block">
        {adjustmentDetails?.adjustments &&
        adjustmentDetails?.adjustments.length >= 1
          ? adjustmentDetails?.adjustments.map((item) => (
              <>
                <div key={item.id} className="row">
                  <div className="col-12 text-left">
                    <div className="label">Marketplace</div>
                    <div
                      className={`normal-text ${
                        item?.new_budget - item?.old_budget ? 'text-bold' : null
                      } `}
                    >
                      {item?.marketplace}
                    </div>
                  </div>

                  <div className="col-4 text-left">
                    <div className="label">From</div>
                    <div
                      className={`normal-text ${
                        item?.new_budget - item?.old_budget ? 'text-bold' : null
                      } `}
                    >
                      {generateAmount(
                        item?.old_budget,
                        'from',
                        getSymbolFromCurrency(item?.local_currency),
                      )}
                    </div>
                  </div>

                  <div className="col-2 text-left">
                    <div className="mt-3">
                      <img src={ArrowRightBlackIcon} width="18px" alt="arrow" />{' '}
                    </div>
                  </div>

                  <div className="col-3 text-left">
                    <div className="label">To</div>
                    <div
                      className={`normal-text ${
                        item?.new_budget - item?.old_budget ? 'text-bold' : null
                      } `}
                    >
                      {generateAmount(
                        item?.new_budget,
                        'to',
                        getSymbolFromCurrency(item?.local_currency),
                      )}
                    </div>
                  </div>

                  <div className="col-3 text-left">
                    <div className="label">Change</div>
                    <div
                      className={`normal-text ${
                        item?.new_budget - item?.old_budget ? 'text-bold' : null
                      } `}
                    >
                      {generateAmount(
                        item?.new_budget - item?.old_budget,
                        'change',
                        getSymbolFromCurrency(item?.local_currency),
                      )}
                    </div>
                  </div>
                </div>
                <div className=" straight-line horizontal-line mt-2 mb-2 " />
              </>
            ))
          : null}

        <div className="row">
          <div className="col-12 mb-2 text-left">
            <div className="normal-text text-bold">Total Invoice</div>
          </div>

          <div className="col-4 text-left">
            <div className="label">From </div>
            <div className="normal-text text-bold">
              {' '}
              {generateAmount(
                adjustmentDetails?.from_amount,
                '',
                getSymbolFromCurrency(adjustmentDetails?.global_currency),
              )}
            </div>
          </div>

          <div className="col-2 text-left">
            <div className="mt-3">
              <img src={ArrowRightIcon} width="18px" alt="arrow" />{' '}
            </div>
          </div>

          <div className="col-3 text-left">
            <div className="label">To</div>
            <div className="normal-text text-bold">
              {' '}
              {generateAmount(
                adjustmentDetails?.to_amount,
                '',
                getSymbolFromCurrency(adjustmentDetails?.global_currency),
              )}
            </div>
          </div>

          <div className="col-3 text-left">
            <div className="label">Change</div>
            <div className="normal-text text-bold">
              {generateAmount(
                adjustmentDetails?.to_amount - adjustmentDetails?.from_amount,
                'change',
                getSymbolFromCurrency(adjustmentDetails?.global_currency),
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      <Modal
        id={id}
        isOpen={isOpen}
        style={{ ...customStyles, ...style }}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={onClick}
          role="presentation"
        />
        <ModalBox>
          <div className="modal-body pb-0 ">
            <div
              className="pb-3"
              style={{ display: 'flex', wordBreak: 'break-all' }}
            >
              <h4>
                {' '}
                <img
                  className="modal-back-arrow"
                  src={LeftArrowIcon}
                  role="presentation"
                  alt="back arrow"
                  onClick={() => onApply()}
                />
                Invoice Adjustment
              </h4>
              <div style={{ marginTop: '-7px ', marginLeft: '10px' }}>
                <Status
                  label={status}
                  backgroundColor={StatusColorSet[getInvoiceStatusKey(status)]}
                  iconAfterLabel
                  fontIcon={
                    invoiceStatusIcons[getInvoiceStatusKey(status)]?.icon
                  }
                  labelColor={
                    invoiceStatusIcons[getInvoiceStatusKey(status)]?.iconColor
                  }
                />
              </div>
            </div>
            <div className="body-content">
              <div className=" straight-line horizontal-line  mb-2 " />
              {isMobile && !isOneTime
                ? renderMobileView()
                : renderDesktopView()}

              <div className=" straight-line horizontal-line mt-2 mb-2 " />

              {isPauseInvoice || status === 'cancelled' ? null : isOneTime ? (
                <p className="normal-text mb-3 mt-1">
                  This will be a one-off invoice, providing additional budget to
                  spend in the{' '}
                  <b>
                    {dayjs(adjustmentDetails.applicable_from).format('MMMM')}{' '}
                    only.
                  </b>{' '}
                </p>
              ) : (
                <p className="normal-text">
                  The new invoice amount will be available to spend from{' '}
                  {dayjs(adjustmentDetails.applicable_from).format('MMMM')}{' '}
                  onwards.
                  <br /> The first bill for this amount will be sent{' '}
                  {dayjs(previousMonth).format('MMMM')} 11.
                </p>
              )}
            </div>
          </div>
        </ModalBox>
      </Modal>
    </>
  );
};

export default InvoiceRequestViewModal;

InvoiceRequestViewModal.defaultProps = {
  isOpen: false,
  id: '',
  style: {},
  adjustmentDetails: [],
  onClick: () => {},
  onApply: () => {},
};

InvoiceRequestViewModal.propTypes = {
  isOpen: bool,
  id: string,
  adjustmentDetails: shape([]),
  style: shape({}),
  onClick: func,
  onApply: func,
};
