import React, { useState } from 'react';

import dayjs from 'dayjs';
import Select, { components } from 'react-select';
import { string, func, shape } from 'prop-types';

import CustomMonthRangeModal from '../../../../../common/CustomMonthRangeModal';
import { DropdownIndicator } from '../../../../../common';

const filterCustomStyles = {
  valueContainer: (css) => ({
    ...css,
    color: '#2E384D',
    overflow: 'hidden',
    'white-space': 'nowrap',
    'flex-wrap': 'nowrap',
    'text-overflow': 'ellipsis',
    'max-width': '130px',
    'font-size': '12px',
    'font-family': 'Noah-Medium',
  }),
};

function COSMonthPicker({
  cosAccessOfBP,
  customStartDate,
  setCustomStartDate,
  customEndDate,
  setCustomEndDate,
  customMonth,
  setCustomMonth,
  isSingleMonthSelected,
}) {
  const { Option, SingleValue } = components;
  const dateFilterOptions = [
    {
      value: 'custom',
      label: 'Custom Range',
      sub: 'Select start and end dates',
    },
  ];

  const [dateType, setDateType] = useState(dateFilterOptions[0]);

  const [appliedDate, setAppliedDate] = useState(customMonth);
  const [showCustomMonthRangeModal, setShowCustomMonthRangeModal] =
    useState(false);

  // render the selected custom date or month as a sub label in the time filter
  const renderCustomDateSubLabel = (props) => {
    if (dateType?.value === 'custom') {
      if (isSingleMonthSelected()) {
        return `${dayjs(customStartDate).format('MMM, YYYY')}`;
      }
      return `${dayjs(customStartDate).format('MMM, YYYY')} - ${dayjs(
        customEndDate,
      ).format('MMM, YYYY')}`;
    }
    return props?.data?.sub;
  };

  const singleFilterOption = (props) => {
    return (
      <Option {...props}>
        <div className="pb-2" style={{ fontSize: '14px', color: '#2e384d' }}>
          {props.data.label}
        </div>
        <div className="pb-2" style={{ fontSize: '12px', color: '#556178' }}>
          {renderCustomDateSubLabel(props)}
        </div>
      </Option>
    );
  };

  const singleFilterValue = (props) => {
    return (
      <SingleValue {...props}>
        <span style={{ fontSize: '14px', color: '#2e384d' }}>
          {renderCustomDateSubLabel(props)}
        </span>
      </SingleValue>
    );
  };

  // render get select component for dropdown
  const getSelectComponents = () => {
    return {
      Option: singleFilterOption,
      SingleValue: singleFilterValue,
      DropdownIndicator,
    };
  };

  // handle date filter events
  const handleDateFilterEvents = (event) => {
    const { value } = event;
    setDateType(event);

    if (value === 'custom') {
      setShowCustomMonthRangeModal(true);
    }
  };

  // renderGoaldateFilter
  const renderCOSDateFilter = () => {
    return (
      <Select
        classNamePrefix="react-select"
        className="active cursor"
        fontSize="14px"
        options={dateFilterOptions}
        components={getSelectComponents()}
        value={dateType}
        defaultValue={dateType}
        onChange={(event) => handleDateFilterEvents(event)}
        styles={filterCustomStyles}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: '#1A1A1A',
            primary: 'none',
            primary25: 'none',
            primary50: 'none',
          },
        })}
      />
    );
  };

  // set default date
  const setDefaultDate = () => {
    setCustomMonth({
      startMonth: appliedDate?.startMonth,
      endMonth: appliedDate?.endMonth,
    });
  };

  // Handeling the custom month range selection
  const onMonthChange = (date) => {
    const [startMonth, endMonth] = date;

    setCustomMonth({
      startMonth,
      endMonth,
    });
  };

  // calling goals api when date changes
  const applyDateChangeEvents = (startDate, endDate) => {
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
  };

  // handle custom date range changes events
  const handleCustomDateRangeEvents = (startDate, endDate, dateFlag) => {
    const newEndDate = endDate || startDate;
    let tempStartDate = startDate;
    let tempEndDate = newEndDate;

    if (dateFlag === 'custom') {
      tempStartDate = dayjs(tempStartDate).format('YYYY-MM-DD');
      tempEndDate = dayjs(tempEndDate).format('YYYY-MM-DD');
      applyDateChangeEvents(tempStartDate, tempEndDate);
    }
  };

  // apply custom month range
  const applyCustomMonthRange = () => {
    setAppliedDate(customMonth);
    handleCustomDateRangeEvents(
      customMonth?.startMonth,
      customMonth?.endMonth,
      'custom',
    );
    setShowCustomMonthRangeModal(false);
  };

  // render modals
  const renderModals = () => {
    return (
      <CustomMonthRangeModal
        id="COSMonthPicker"
        isOpen={showCustomMonthRangeModal}
        title="Select Month Range"
        className="custom-month-range"
        minDate={new Date(cosAccessOfBP?.start_date)}
        maxDate={new Date(cosAccessOfBP?.end_date)}
        startMonth={customMonth?.startMonth}
        endMonth={customMonth?.endMonth}
        onClick={() => {
          setShowCustomMonthRangeModal(false);
          setDefaultDate();
        }}
        onDateChange={onMonthChange}
        onApply={() => {
          applyCustomMonthRange();
        }}
      />
    );
  };

  return (
    <div>
      {renderCOSDateFilter()}
      {showCustomMonthRangeModal ? renderModals() : null}
    </div>
  );
}

export default COSMonthPicker;

COSMonthPicker.defaultProps = {
  cosAccessOfBP: {},
  customStartDate: '',
  setCustomStartDate: () => {},
  customEndDate: '',
  setCustomEndDate: () => {},
  customMonth: {},
  setCustomMonth: () => {},
  data: {},
  isSingleMonthSelected: () => {},
};

COSMonthPicker.propTypes = {
  cosAccessOfBP: shape({}),
  customStartDate: string,
  setCustomStartDate: func,
  customEndDate: string,
  setCustomEndDate: func,
  customMonth: shape({}),
  setCustomMonth: func,
  data: shape({}),
  isSingleMonthSelected: func,
};
