/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { shape, func, string, bool } from 'prop-types';

import RevenueThreshold from './RevenueThreshold';
import {
  ContractInputSelect,
  InputFormField,
  ErrorMsg,
} from '../../../../common';
import {
  feeTypeOptions,
  monthlyRetainerLimitInString,
  revShareAndRetainerDetails,
  revShareDetails,
  revShareLimitInString,
  revShareThresholdLimitInString,
} from '../../../../constants';
import RetainerOnly from './RetainerOnly';

export default function SellerFeeStructure({
  setFormData,
  updatedFormData,
  setUpdatedFormData,
  formData,
  DropdownIndicator,
  revShareOptions,
  section,
  vendorSameAsSeller,
  showFooter,
  setSectionError,
  sectionError,
  feeStructureErrors,
  setFeeStructureErrors,
  selectedCurrency,
  combinedRevShareFlag,
  contractAddendumDetails,
  checkAddendumData,
  addendumDetails,
}) {
  const addendumFeeType = [...feeTypeOptions];
  const [isTriggered, setIsTriggered] = useState(false);

  const [updateFeeTypeOptions, setUpdateFeeTypeOptions] = useState({
    feeType: feeTypeOptions,
    revShare: revShareDetails,
    revShareAndRetainer: revShareAndRetainerDetails,
    addendumOption: addendumFeeType.filter(
      (op) => op.value === 'Retainer + % Rev Share',
    ),
  });

  const [originalData] = useState(formData?.fee_structure?.[section]?.fee_type);

  useEffect(() => {
    if (updatedFormData) {
      const tempKeys = Object.keys(updatedFormData);
      if (tempKeys && tempKeys.length) {
        const feeStructureKey = tempKeys.find((item) => {
          return item === 'fee_structure';
        });
        if (feeStructureKey && !checkAddendumData) {
          delete updatedFormData.fee_structure;
        }
      }
    }
  }, [checkAddendumData]);

  useEffect(() => {
    if (
      formData?.combined_rev_share &&
      formData?.seller_type?.label === 'Hybrid'
    ) {
      const feeTypes = [...feeTypeOptions];
      const rev = [...revShareDetails];
      const revShareAndRetainer = [
        revShareAndRetainerDetails[0],
        revShareAndRetainerDetails[revShareAndRetainerDetails.length - 1],
      ];

      setUpdateFeeTypeOptions({
        ...updateFeeTypeOptions,
        feeType: feeTypes.slice(1),
        revShare: rev.slice(2),
        revShareAndRetainer,
      });
      if (contractAddendumDetails?.notActive) {
        if (
          !addendumDetails?.combined_rev_share &&
          !formData?.combined_rev_share
        ) {
          setFormData((prevState) => ({
            ...prevState,
            fee_structure: {
              ...prevState?.fee_structure,
              [section]: {
                ...prevState?.fee_structure?.[section],
                rev_share: null,
                sales_threshold: null,
                threshold_type: null,
              },
            },
          }));
        }
      } else if (
        !addendumDetails?.combined_rev_share &&
        !formData?.combined_rev_share
      ) {
        setFormData((prevState) => ({
          ...prevState,
          fee_structure: {
            ...prevState?.fee_structure,
            [section]: {
              ...prevState?.fee_structure?.[section],
              rev_share: null,
              sales_threshold: null,
              threshold_type: null,
            },
          },
        }));
      }

      if (combinedRevShareFlag) {
        setUpdatedFormData((prevState) => ({
          ...prevState,
          fee_structure: {
            ...prevState?.fee_structure,
            [section]: {
              ...prevState?.fee_structure?.[section],
              rev_share: null,
              sales_threshold: null,
              threshold_type: null,
            },
          },
        }));
      }
    } else
      setUpdateFeeTypeOptions({
        ...updateFeeTypeOptions,
        feeType: feeTypeOptions,
        revShare: revShareDetails,
        revShareAndRetainer: revShareAndRetainerDetails,
      });
    if (
      formData?.combined_rev_share &&
      formData?.seller_type?.label === 'Hybrid' &&
      formData?.fee_structure?.[section]?.fee_type === 'Retainer Only'
    ) {
      let errorCount = sectionError?.[section]?.feeType;

      if (
        section === 'vendor' &&
        !formData?.fee_structure?.vendor?.vendor_billing_report
      ) {
        errorCount += 1;
      }

      setSectionError((prevState) => ({
        ...prevState,
        [section]: { feeType: errorCount },
      }));

      setFormData((prevState) => ({
        ...prevState,
        fee_structure: {
          ...prevState?.fee_structure,
          [section]: {
            ...prevState?.fee_structure?.[section],
            fee_type: null,
          },
        },
      }));
      setUpdatedFormData((prevState) => ({
        ...prevState,
        fee_structure: {
          ...prevState?.fee_structure,
          [section]: {
            ...prevState?.fee_structure?.[section],
            fee_type: null,
          },
        },
      }));
    }
  }, [formData.combined_rev_share]);

  const checkFixedRetainer = (contractData) => {
    if (
      (!contractData?.fee_structure?.[section]?.retainer_type ||
        contractData?.fee_structure?.[section]?.retainer_type === 'Fixed') &&
      contractData?.fee_structure?.[section]?.monthly_retainer
    ) {
      return true;
    }

    if (
      contractData?.fee_structure?.[section]?.retainer_type === 'quarterly' &&
      contractData?.fee_structure?.[section]?.quarterly_monthly_retainer &&
      Object.keys(
        contractData?.fee_structure?.[section]?.quarterly_monthly_retainer,
      )?.length &&
      !Object.values(
        contractData?.fee_structure?.[section]?.quarterly_monthly_retainer,
      )?.includes('')
    ) {
      return true;
    }

    if (
      contractData?.fee_structure?.[section]?.retainer_type === 'monthly' &&
      contractData?.fee_structure?.[section]?.monthly_monthly_retainer &&
      Object.keys(
        contractData?.fee_structure?.[section]?.monthly_monthly_retainer,
      )?.length &&
      !Object.values(
        contractData?.fee_structure?.[section]?.monthly_monthly_retainer,
      )?.includes('')
    ) {
      return true;
    }

    return false;
  };

  const checkMandatoryFieldsOfFeeType = (event, contractData = formData) => {
    let errorCount = sectionError?.[section]?.feeType;
    const isCombined =
      contractData?.combined_rev_share &&
      contractData?.seller_type?.label === 'Hybrid';
    if (isCombined) {
      if (!(contractData && contractData?.fee_structure?.seller?.rev_share)) {
        errorCount = 1;
      } else {
        errorCount = 0;
      }
      if (
        section === 'vendor' &&
        !(
          contractData &&
          contractData?.fee_structure?.vendor?.vendor_billing_report
        )
      ) {
        errorCount += 1;
      }
      setSectionError({
        ...sectionError,
        seller: { feeType: errorCount },
      });
    } else {
      if (event?.value === 'Retainer Only') {
        if (!checkFixedRetainer(contractData)) {
          errorCount = 1;
        } else {
          errorCount = 0;
        }
      }

      if (event?.value === 'Revenue Share Only') {
        if (
          !(contractData && contractData?.fee_structure?.[section]?.rev_share)
        ) {
          errorCount = 1;
        } else {
          errorCount = 0;
        }
      }
      if (event?.value === 'Retainer + % Rev Share') {
        if (
          !checkFixedRetainer(contractData) &&
          !(contractData && contractData?.fee_structure?.[section]?.rev_share)
        ) {
          errorCount = 2;
        } else if (!checkFixedRetainer(contractData)) {
          errorCount = 1;
        } else if (
          !(contractData && contractData?.fee_structure?.[section]?.rev_share)
        ) {
          errorCount = 1;
        } else {
          errorCount = 0;
        }
      }

      if (
        section === 'vendor' &&
        !(
          contractData &&
          contractData?.fee_structure?.vendor?.vendor_billing_report
        )
      ) {
        errorCount += 1;
      }

      if (
        contractData &&
        contractData?.fee_structure?.vendor?.vendor_same_as_seller
      ) {
        setSectionError({
          ...sectionError,
          [isCombined ? 'selller' : section]: { feeType: errorCount },
          vendor: {
            feeType: !(
              contractData &&
              contractData?.fee_structure?.vendor?.vendor_billing_report
            )
              ? errorCount + 1
              : errorCount,
          },
        });
      } else if (
        feeStructureErrors?.[section]?.sales_threshold ||
        feeStructureErrors?.[section]?.quarterly_rev_share ||
        feeStructureErrors?.[section]?.monthly_rev_share
      ) {
        setSectionError({
          ...sectionError,
          [section]: { feeType: errorCount + 1 },
        });
      } else {
        setSectionError({
          ...sectionError,
          [section]: { feeType: errorCount },
        });
      }
    }
  };

  const clearErrorCount = (event, key) => {
    if (key === 'fee_type') {
      if (formData && formData?.fee_structure?.[section]?.[key]) {
        setSectionError({
          ...sectionError,
          [section]: {
            feeType: sectionError?.[section].feeType
              ? sectionError?.[section].feeType - 1
              : 0,
          },
        });
      }

      checkMandatoryFieldsOfFeeType(event);
    }

    if (key === 'rev_share') {
      if (!(formData && formData?.fee_structure?.[section]?.[key])) {
        if (
          formData &&
          formData?.fee_structure?.vendor?.vendor_same_as_seller
        ) {
          setSectionError({
            ...sectionError,
            [section]: {
              feeType: sectionError?.[section]?.feeType
                ? sectionError?.[section]?.feeType - 1
                : 0,
            },
            vendor: {
              feeType: !(
                formData &&
                formData?.fee_structure?.vendor?.vendor_billing_report
              )
                ? (sectionError?.[section]?.feeType
                    ? sectionError?.[section]?.feeType - 1
                    : 0) + 1
                : sectionError?.[section]?.feeType
                ? sectionError?.[section]?.feeType - 1
                : 0,
            },
          });
          setFeeStructureErrors((prevState) => ({
            ...prevState,
            seller: {
              ...feeStructureErrors?.[section],
              rev_share: '',
            },
            vendor: {
              ...feeStructureErrors?.[section],
              rev_share: '',
            },
          }));
        } else {
          setSectionError({
            ...sectionError,
            [section]: {
              feeType: sectionError?.[section]?.feeType
                ? sectionError?.[section]?.feeType - 1
                : 0,
            },
          });
          setFeeStructureErrors((prevState) => ({
            ...prevState,
            [section]: {
              ...feeStructureErrors?.[section],
              rev_share: '',
            },
          }));
        }
      }
    }
  };

  const clearMonthlyServiceError = (event, key) => {
    let errorCount = sectionError?.[section]?.feeType;

    if (event && event.value) {
      if (
        key === 'monthly_retainer' &&
        !(formData && formData?.fee_structure?.[section]?.[key])
      ) {
        errorCount -= 1;
      }
    } else if (
      key === 'monthly_retainer' &&
      formData &&
      formData?.fee_structure?.[section]?.[key]
    ) {
      errorCount += 1;
    }

    if (
      formData &&
      formData?.fee_structure?.vendor?.vendor_same_as_seller &&
      section === 'seller'
    ) {
      setSectionError({
        ...sectionError,
        [section]: { feeType: errorCount },
        vendor: {
          feeType: !(
            formData && formData?.fee_structure?.vendor?.vendor_billing_report
          )
            ? errorCount + 1
            : errorCount,
        },
      });

      setFeeStructureErrors((prevState) => ({
        ...prevState,
        seller: {
          ...feeStructureErrors?.[section],
          monthly_retainer: '',
        },
        vendor: {
          ...feeStructureErrors?.[section],
          monthly_retainer: '',
        },
      }));
    } else {
      setSectionError({
        ...sectionError,
        [section]: { feeType: errorCount },
      });
      setFeeStructureErrors((prevState) => ({
        ...prevState,
        [section]: {
          ...feeStructureErrors?.[section],
          monthly_retainer: '',
        },
      }));
    }

    if (key.includes('quarterly')) {
      if (event?.value) {
        if (
          formData?.fee_structure?.[section]?.quarterly_monthly_retainer &&
          (formData?.fee_structure?.[section]?.quarterly_monthly_retainer?.[
            '1st quarterly'
          ] ||
            key === '1st quarterly') &&
          (formData?.fee_structure?.[section]?.quarterly_monthly_retainer?.[
            '2nd quarterly'
          ] ||
            key === '2nd quarterly') &&
          (formData?.fee_structure?.[section]?.quarterly_monthly_retainer?.[
            '3rd quarterly'
          ] ||
            key === '3rd quarterly') &&
          (formData?.fee_structure?.[section]?.quarterly_monthly_retainer?.[
            '4th quarterly'
          ] ||
            key === '4th quarterly')
        ) {
          if (
            !formData?.fee_structure?.[section]?.quarterly_monthly_retainer?.[
              key
            ]
          ) {
            if (
              formData &&
              formData?.fee_structure?.vendor?.vendor_same_as_seller &&
              formData?.seller_type?.value === 'Hybrid'
            ) {
              setSectionError({
                ...sectionError,
                [section]: { feeType: sectionError?.[section]?.feeType - 2 },
              });
            } else {
              setSectionError({
                ...sectionError,
                [section]: { feeType: sectionError?.[section]?.feeType - 1 },
              });
            }

            setFeeStructureErrors((prevState) => ({
              ...prevState,
              [section]: {
                ...feeStructureErrors?.[section],
                quarterly_monthly_retainer: '',
                monthly_retainer: '',
                monthly_monthly_retainer: '',
              },
            }));
          }
        }
      } else if (
        formData?.fee_structure?.[section]?.quarterly_monthly_retainer &&
        !Object.values(
          formData?.fee_structure?.[section]?.quarterly_monthly_retainer,
        )?.filter((item) => item === '')?.length
      ) {
        if (
          formData &&
          formData?.fee_structure?.vendor?.vendor_same_as_seller &&
          formData?.seller_type?.value === 'Hybrid'
        ) {
          setSectionError({
            ...sectionError,
            [section]: { feeType: sectionError?.[section]?.feeType + 2 },
          });
        } else {
          setSectionError({
            ...sectionError,
            [section]: { feeType: sectionError?.[section]?.feeType + 1 },
          });
        }
      }
    }

    if (key.includes('month')) {
      if (event?.value) {
        if (
          formData?.fee_structure?.[section]?.monthly_monthly_retainer &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'january month'
          ] ||
            key === 'january month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'february month'
          ] ||
            key === 'february month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'march month'
          ] ||
            key === 'march month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'april month'
          ] ||
            key === 'april month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'may month'
          ] ||
            key === 'may month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'june month'
          ] ||
            key === 'june month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'july month'
          ] ||
            key === 'july month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'august month'
          ] ||
            key === 'august month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'september month'
          ] ||
            key === 'september month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'october month'
          ] ||
            key === 'october month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'november month'
          ] ||
            key === 'november month') &&
          (formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
            'december month'
          ] ||
            key === 'december month')
        ) {
          if (
            !formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[key]
          ) {
            setSectionError({
              ...sectionError,
              [section]: { feeType: sectionError?.[section]?.feeType - 1 },
            });

            setFeeStructureErrors((prevState) => ({
              ...prevState,
              [section]: {
                ...feeStructureErrors?.[section],
                quarterly_monthly_retainer: '',
                monthly_retainer: '',
                monthly_monthly_retainer: '',
              },
            }));
          }
        }
      } else if (
        formData?.fee_structure?.[section]?.monthly_monthly_retainer &&
        !Object.values(
          formData?.fee_structure?.[section]?.monthly_monthly_retainer,
        )?.filter((item) => item === '')?.length
      ) {
        setSectionError({
          ...sectionError,
          [section]: { feeType: sectionError?.[section]?.feeType + 1 },
        });

        setFeeStructureErrors((prevState) => ({
          ...prevState,
          [section]: {
            ...feeStructureErrors?.[section],
            quarterly_monthly_retainer: '',
            monthly_retainer: '',
            monthly_monthly_retainer: '',
          },
        }));
      }
    }
  };

  const validateBillingCap = (event, key) => {
    if (
      (key === 'billing_cap' || key === 'billing_minimum') &&
      formData?.fee_structure?.[section]?.fee_type === 'Revenue Share Only'
    ) {
      if (
        key === 'billing_cap' &&
        formData?.fee_structure?.[section]?.billing_minimum
      ) {
        if (
          parseInt(formData?.fee_structure?.[section]?.billing_minimum, 10) >
          parseInt(event.value, 10)
        ) {
          setFeeStructureErrors({
            ...feeStructureErrors,
            [section]: {
              ...feeStructureErrors?.[section],
              billing_cap: 'Billing CAP must be greater than Billing Minimum',
              billing_minimum: '',
            },
          });

          if (
            !feeStructureErrors?.[section]?.billing_cap &&
            !feeStructureErrors?.[section]?.billing_minimum
          ) {
            setSectionError((prevState) => ({
              ...prevState,
              [section]: { feeType: prevState?.[section]?.feeType + 1 },
            }));
          }
        } else {
          setFeeStructureErrors({
            ...feeStructureErrors,
            [section]: {
              ...feeStructureErrors?.[section],
              billing_cap: '',
              billing_minimum: '',
            },
          });

          if (
            feeStructureErrors?.[section]?.billing_cap ||
            feeStructureErrors?.[section]?.billing_minimum
          ) {
            setSectionError((prevState) => ({
              ...prevState,
              [section]: { feeType: prevState?.[section]?.feeType - 1 },
            }));
          }
        }
      }

      if (
        key === 'billing_minimum' &&
        formData?.fee_structure?.[section]?.billing_cap
      ) {
        if (
          parseInt(event.value, 10) >
          parseInt(formData?.fee_structure?.[section]?.billing_cap, 10)
        ) {
          setFeeStructureErrors({
            ...feeStructureErrors,
            [section]: {
              ...feeStructureErrors?.[section],
              billing_minimum: 'Billing Minimum must be less than Billing CAP',
              billing_cap: '',
            },
          });

          if (
            !feeStructureErrors?.[section]?.billing_minimum &&
            !feeStructureErrors?.[section]?.billing_cap
          ) {
            setSectionError((prevState) => ({
              ...prevState,
              [section]: { feeType: prevState?.[section]?.feeType + 1 },
            }));
          }
        } else {
          setFeeStructureErrors({
            ...feeStructureErrors,
            [section]: {
              ...feeStructureErrors?.[section],
              billing_cap: '',
              billing_minimum: '',
            },
          });

          if (
            feeStructureErrors?.[section]?.billing_cap ||
            feeStructureErrors?.[section]?.billing_minimum
          ) {
            setSectionError((prevState) => ({
              ...prevState,
              [section]: { feeType: prevState?.[section]?.feeType - 1 },
            }));
          }
        }
      }
    }
  };

  const handleChange = (event, key, type, fieldOf) => {
    showFooter(true);
    if (key === 'fee_type') {
      setFeeStructureErrors((prevState) => ({
        ...prevState,
        [section]: {},
      }));
    }

    if (fieldOf === 'threshold' && key.includes('quarterly')) {
      let changedValue = {
        ...formData?.fee_structure?.[section].quarterly_rev_share,
      };
      if (event?.value) {
        changedValue = {
          ...formData?.fee_structure?.[section].quarterly_rev_share,
          [key]: event.value,
        };
      } else {
        delete formData?.fee_structure?.[section].quarterly_rev_share?.[key];
        changedValue = {
          ...formData?.fee_structure?.[section].quarterly_rev_share,
        };
      }

      setFormData({
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          [section]: {
            ...formData?.fee_structure?.[section],
            quarterly_rev_share: {
              ...changedValue,
            },
          },
        },
      });
      setUpdatedFormData({
        ...updatedFormData,
        fee_structure: {
          ...updatedFormData?.fee_structure,
          [section]: {
            ...updatedFormData?.fee_structure?.[section],
            quarterly_rev_share: {
              ...changedValue,
            },
          },
        },
      });
    } else if (fieldOf === 'threshold' && key.search(/\bmonth\b/) >= 0) {
      let changedValue = {
        ...formData?.fee_structure?.[section].monthly_rev_share,
      };
      if (event?.value) {
        changedValue = {
          ...formData?.fee_structure?.[section].monthly_rev_share,
          [key]: event.value,
        };
      } else {
        delete formData?.fee_structure?.[section].monthly_rev_share?.[key];
        changedValue = {
          ...formData?.fee_structure?.[section].monthly_rev_share,
        };
      }

      setFormData({
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          [section]: {
            ...formData?.fee_structure?.[section],
            monthly_rev_share: {
              ...changedValue,
            },
          },
        },
      });
      setUpdatedFormData({
        ...updatedFormData,
        fee_structure: {
          ...updatedFormData?.fee_structure,
          [section]: {
            ...updatedFormData?.fee_structure?.[section],
            monthly_rev_share: {
              ...changedValue,
            },
          },
        },
      });
    } else if (fieldOf === 'retainer' && key.includes('quarterly')) {
      let changedValue = {
        ...formData?.fee_structure?.[section].quarterly_monthly_retainer,
      };

      if (event?.value) {
        changedValue = {
          ...formData?.fee_structure?.[section].quarterly_monthly_retainer,
          [key]: event.value,
        };
      } else {
        delete formData?.fee_structure?.[section].quarterly_monthly_retainer?.[
          key
        ];
        changedValue = {
          ...formData?.fee_structure?.[section].quarterly_monthly_retainer,
        };
      }
      setFormData({
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          [section]: {
            ...formData?.fee_structure?.[section],
            quarterly_monthly_retainer: {
              ...changedValue,
            },
            monthly_monthly_retainer: {},
          },
        },
      });
      setUpdatedFormData({
        ...updatedFormData,
        fee_structure: {
          ...updatedFormData?.fee_structure,
          [section]: {
            ...updatedFormData?.fee_structure?.[section],
            quarterly_monthly_retainer: {
              ...changedValue,
            },
          },
        },
      });
      clearMonthlyServiceError(event, key, {
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          [section]: {
            ...formData?.fee_structure?.[section],
            quarterly_monthly_retainer: {
              ...formData?.fee_structure?.[section].quarterly_monthly_retainer,
              [key]: event.value,
            },
            monthly_monthly_retainer: {},
          },
        },
      });
    } else if (fieldOf === 'retainer' && key.search(/\bmonth\b/) >= 0) {
      let changedValue = {
        ...formData?.fee_structure?.[section].monthly_monthly_retainer,
      };
      if (event?.value) {
        changedValue = {
          ...formData?.fee_structure?.[section].monthly_monthly_retainer,
          [key]: event.value,
        };
      } else {
        delete formData?.fee_structure?.[section].monthly_monthly_retainer?.[
          key
        ];
        changedValue = {
          ...formData?.fee_structure?.[section].monthly_monthly_retainer,
        };
      }

      setFormData({
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          [section]: {
            ...formData?.fee_structure?.[section],
            monthly_monthly_retainer: {
              ...changedValue,
            },
            quarterly_monthly_retainer: {},
          },
        },
      });
      setUpdatedFormData({
        ...updatedFormData,
        fee_structure: {
          ...updatedFormData?.fee_structure,
          [section]: {
            ...updatedFormData?.fee_structure?.[section],
            monthly_monthly_retainer: {
              ...changedValue,
            },
          },
        },
      });
      clearMonthlyServiceError(event, key, {
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          [section]: {
            ...formData?.fee_structure?.[section],
            monthly_monthly_retainer: {
              ...formData?.fee_structure?.[section]?.monthly_monthly_retainer,
              [key]: event?.value,
            },
            quarterly_monthly_retainer: {},
          },
        },
      });
    } else {
      const retainerType = formData?.fee_structure?.[section]?.retainer_type;
      const noRetainerType =
        !retainerType || retainerType === null || retainerType === undefined;

      const revShare = formData?.fee_structure?.[section]?.rev_share;
      const noRevShare =
        !revShare || revShare === null || revShare === undefined;

      if (
        key === 'fee_type' &&
        event?.value === 'Retainer Only' &&
        noRetainerType
      ) {
        setFormData({
          ...formData,
          fee_structure: {
            ...formData?.fee_structure,
            [section]: {
              ...formData?.fee_structure?.[section],
              [key]:
                type === 'choices' || type === 'numberformat'
                  ? event?.value
                    ? event?.value
                    : null
                  : event?.target?.value
                  ? event?.target?.value
                  : null,
              retainer_type: 'Fixed',
              monthly_retainer: monthlyRetainerLimitInString,
            },
          },
        });

        setUpdatedFormData({
          ...updatedFormData,
          fee_structure: {
            ...updatedFormData?.fee_structure,
            [section]: {
              ...updatedFormData?.fee_structure?.[section],
              [key]:
                type === 'choices' || type === 'numberformat'
                  ? event?.value
                    ? event?.value
                    : null
                  : event?.target?.value
                  ? event.target.value
                  : null,
              retainer_type: 'Fixed',
              monthly_retainer: monthlyRetainerLimitInString,
            },
          },
        });
      } else if (
        key === 'fee_type' &&
        event?.value === 'Revenue Share Only' &&
        noRevShare
      ) {
        setFormData({
          ...formData,
          fee_structure: {
            ...formData?.fee_structure,
            [section]: {
              ...formData?.fee_structure?.[section],
              [key]:
                type === 'choices' || type === 'numberformat'
                  ? event?.value
                    ? event?.value
                    : null
                  : event?.target?.value
                  ? event?.target?.value
                  : null,
              rev_share: revShareLimitInString,
              sales_threshold: revShareThresholdLimitInString,
              threshold_type: 'Fixed',
            },
          },
        });

        setUpdatedFormData({
          ...updatedFormData,
          fee_structure: {
            ...updatedFormData?.fee_structure,
            [section]: {
              ...updatedFormData?.fee_structure?.[section],
              [key]:
                type === 'choices' || type === 'numberformat'
                  ? event?.value
                    ? event?.value
                    : null
                  : event?.target?.value
                  ? event.target.value
                  : null,

              rev_share: revShareLimitInString,
              sales_threshold: revShareThresholdLimitInString,
              threshold_type: 'Fixed',
            },
          },
        });
      } else if (
        key === 'fee_type' &&
        event?.value === 'Retainer + % Rev Share' &&
        (noRevShare || noRetainerType)
      ) {
        setFormData({
          ...formData,
          fee_structure: {
            ...formData?.fee_structure,
            [section]: {
              ...formData?.fee_structure?.[section],
              [key]:
                type === 'choices' || type === 'numberformat'
                  ? event?.value
                    ? event?.value
                    : null
                  : event?.target?.value
                  ? event?.target?.value
                  : null,
              retainer_type: 'Fixed',
              monthly_retainer: monthlyRetainerLimitInString,
              rev_share: revShareLimitInString,
              sales_threshold: revShareThresholdLimitInString,
              threshold_type: 'Fixed',
            },
          },
        });

        setUpdatedFormData({
          ...updatedFormData,
          fee_structure: {
            ...updatedFormData?.fee_structure,
            [section]: {
              ...updatedFormData?.fee_structure?.[section],
              [key]:
                type === 'choices' || type === 'numberformat'
                  ? event?.value
                    ? event?.value
                    : null
                  : event?.target?.value
                  ? event.target.value
                  : null,
              retainer_type: 'Fixed',
              monthly_retainer: monthlyRetainerLimitInString,
              rev_share: revShareLimitInString,
              sales_threshold: revShareThresholdLimitInString,
              threshold_type: 'Fixed',
            },
          },
        });
      } else {
        setFormData({
          ...formData,
          fee_structure: {
            ...formData?.fee_structure,
            [section]: {
              ...formData?.fee_structure?.[section],
              [key]:
                type === 'choices' || type === 'numberformat'
                  ? event?.value
                    ? event?.value
                    : null
                  : event?.target?.value
                  ? event?.target?.value
                  : null,
            },
          },
        });

        setUpdatedFormData({
          ...updatedFormData,
          fee_structure: {
            ...updatedFormData?.fee_structure,
            [section]: {
              ...updatedFormData?.fee_structure?.[section],
              [key]:
                type === 'choices' || type === 'numberformat'
                  ? event?.value
                    ? event?.value
                    : null
                  : event?.target?.value
                  ? event.target.value
                  : null,
            },
          },
        });
      }

      if (type === 'choices') {
        clearErrorCount(event, key, type);
      }

      if (type === 'numberformat') {
        clearMonthlyServiceError(event, key);
      }

      if (key === 'retainer_type') {
        checkMandatoryFieldsOfFeeType(
          {
            value: formData?.fee_structure?.[section]?.fee_type,
          },

          {
            ...formData,
            fee_structure: {
              ...formData?.fee_structure,
              [section]: {
                ...formData?.fee_structure?.[section],

                [key]:
                  type === 'choices' || type === 'numberformat'
                    ? event?.value
                      ? event?.value
                      : null
                    : event?.target?.value
                    ? event?.target?.value
                    : null,
              },
            },
          },
        );

        setFeeStructureErrors((prevState) => ({
          ...prevState,
          [section]: {
            ...feeStructureErrors?.[section],
            quarterly_monthly_retainer: '',
            monthly_retainer: '',
            monthly_monthly_retainer: '',
          },
        }));
      }
    }

    if (type === 'numberformat') {
      clearMonthlyServiceError(event, key, type);
    }

    if (
      (key === 'threshold_type' &&
        (feeStructureErrors?.[section]?.quarterly_rev_share ||
          feeStructureErrors?.[section]?.monthly_rev_share ||
          feeStructureErrors?.[section]?.sales_threshold)) ||
      (formData?.fee_structure?.[section]?.quarterly_rev_share &&
        Object.keys(formData?.fee_structure?.[section]?.quarterly_rev_share)
          .length === 3 &&
        event.value &&
        feeStructureErrors?.[section]?.quarterly_rev_share) ||
      (formData?.fee_structure?.[section]?.monthly_rev_share &&
        Object.keys(formData?.fee_structure?.[section]?.monthly_rev_share)
          .length === 11 &&
        event.value &&
        feeStructureErrors?.[section]?.monthly_rev_share) ||
      (feeStructureErrors?.[section]?.sales_threshold &&
        (formData?.fee_structure?.[section]?.sales_threshold || event.value))
    ) {
      if (
        formData &&
        formData?.fee_structure?.vendor?.vendor_same_as_seller &&
        formData?.seller_type?.value === 'Hybrid'
      ) {
        setSectionError({
          ...sectionError,
          seller: {
            feeType: sectionError?.seller?.feeType
              ? sectionError?.seller?.feeType - 1
              : 0,
          },
          vendor: {
            feeType: !(
              formData && formData?.fee_structure?.vendor?.vendor_billing_report
            )
              ? sectionError?.seller?.feeType - 1 + 1
              : sectionError?.seller?.feeType - 1,
          },
        });

        setFeeStructureErrors((prevState) => ({
          ...prevState,
          seller: {
            ...feeStructureErrors?.seller,
            quarterly_rev_share: '',
            billing_cap: '',
            monthly_rev_share: '',
            sales_threshold: '',
          },
          vendor: {
            ...feeStructureErrors?.vendor,
            quarterly_rev_share: '',
            billing_cap: '',
            monthly_rev_share: '',
            sales_threshold: '',
          },
        }));
      } else {
        setSectionError({
          ...sectionError,
          [section]: { feeType: sectionError?.[section]?.feeType - 1 },
        });

        setFeeStructureErrors((prevState) => ({
          ...prevState,
          [section]: {
            ...feeStructureErrors?.[section],
            quarterly_rev_share: '',
            billing_cap: '',
            monthly_rev_share: '',
          },
        }));

        if (key === 'monthly_retainer') {
          setFeeStructureErrors((prevState) => ({
            ...prevState,
            [section]: {
              ...feeStructureErrors?.[section],
              monthly_retainer: '',
            },
          }));
        }

        if (key === 'sales_threshold') {
          setFeeStructureErrors((prevState) => ({
            ...prevState,
            [section]: {
              ...feeStructureErrors?.[section],
              sales_threshold: '',
            },
          }));
        }

        if (key === 'rev_share') {
          setFeeStructureErrors((prevState) => ({
            ...prevState,
            [section]: {
              ...feeStructureErrors?.[section],
              rev_share: '',
            },
          }));
        }
      }
    }
    if (vendorSameAsSeller && formData?.seller_type?.value === 'Hybrid') {
      const vendorId = formData?.fee_structure?.vendor?.id;
      setFormData((prevState) => ({
        ...prevState,
        fee_structure: {
          ...prevState?.fee_structure,
          vendor: {
            ...prevState?.fee_structure?.seller,
            id: vendorId,
            vendor_billing_report:
              prevState?.fee_structure?.vendor?.vendor_billing_report,
          },
        },
      }));
      setUpdatedFormData((prevState) => ({
        ...prevState,
        fee_structure: {
          ...prevState?.fee_structure,
          vendor: prevState?.fee_structure?.seller,
        },
      }));
    }
    validateBillingCap(event, key);
  };

  const generateDropdown = (item) => {
    return (
      <Select
        classNamePrefix={
          !(formData && formData?.fee_structure?.[section]?.[item.key]) &&
          item.isMandatory
            ? 'react-select  form-control-error'
            : 'react-select'
        }
        styles={{
          control: (base, state) => ({
            ...base,
            zIndex: 9999,
            background:
              !(formData && formData?.fee_structure?.[section]?.[item.key]) &&
              item.isMandatory
                ? '#FBF2F2'
                : '#F4F6FC',
            // match with the menu
            // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
            // Overwrittes the different states of border
            borderColor:
              !(formData && formData?.fee_structure?.[section]?.[item.key]) &&
              item.isMandatory
                ? '#D63649'
                : '#D5D8E1',

            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
              // Overwrittes the different states of border
              boxShadow: state.isFocused ? null : null,
              outlineColor: state.isFocused ? null : null,
            },
          }),
        }}
        placeholder={item.placeholder || 'Select'}
        value={
          formData?.fee_structure?.[section]?.[item.key]
            ? {
                value: formData?.fee_structure?.[section]?.[item.key],
                label:
                  formData?.fee_structure?.[section]?.[item.key] ===
                  'Retainer + % Rev Share'
                    ? 'Retainer +  Rev Share'
                    : formData?.fee_structure?.[section]?.[item.key],
              }
            : null
        }
        options={
          item.key === 'fee_type'
            ? contractAddendumDetails.notActive
              ? updateFeeTypeOptions.addendumOption
              : updateFeeTypeOptions.feeType
            : revShareOptions
        }
        name={item.key}
        components={{ DropdownIndicator }}
        onChange={(event) => handleChange(event, item.key, 'choices')}
        isDisabled={
          contractAddendumDetails.notActive &&
          item.key === 'fee_type' &&
          originalData === 'Retainer + % Rev Share'
        }
      />
    );
  };

  const generateInput = (item) => {
    return (
      <input
        className={
          formData?.fee_structure?.[section]?.[item.key]
            ? 'form-control'
            : 'form-control form-control-error'
        }
        disabled={item.key === 'contract_type'}
        type="text"
        placeholder={item.placeholder || item.label}
        onChange={(event) => handleChange(event, item.key)}
        name={item.key}
        defaultValue={formData?.fee_structure?.[section]?.[item.key]}
      />
    );
  };

  const checkAllFieldsOFRetainerPresent = (item) => {
    if (
      (formData?.fee_structure?.[section]?.retainer_type === 'Fixed' ||
        !formData?.fee_structure?.[section]?.retainer_type) &&
      formData?.fee_structure?.[section]?.monthly_retainer
    ) {
      return true;
    }

    if (
      formData?.fee_structure?.[section]?.retainer_type === 'quarterly' &&
      formData?.fee_structure?.[section]?.quarterly_monthly_retainer?.[
        item?.key
      ]
    ) {
      return true;
    }

    if (
      formData?.fee_structure?.[section]?.retainer_type === 'monthly' &&
      formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[item?.key]
    ) {
      return true;
    }

    return false;
  };

  const generateHTML = (item) => {
    if (item?.type?.includes('number')) {
      return (
        <>
          <NumberFormat
            className={
              (item?.fieldOf !== 'retainer' &&
                !(formData && formData?.fee_structure?.[section]?.[item.key]) &&
                item.isMandatory) ||
              (item?.fieldOf === 'retainer' &&
                !checkAllFieldsOFRetainerPresent(item)) ||
              (feeStructureErrors?.[section]?.quarterly_rev_share &&
                !formData?.fee_structure?.[section]?.quarterly_rev_share?.[
                  item.key
                ] &&
                item.fieldOf === 'threshold') ||
              (feeStructureErrors?.[section]?.monthly_rev_share &&
                !formData?.fee_structure?.[section]?.monthly_rev_share?.[
                  item.key
                ] &&
                item.fieldOf === 'threshold') ||
              (feeStructureErrors?.[section]?.sales_threshold &&
                !formData?.fee_structure?.[section]?.sales_threshold &&
                item.fieldOf === 'threshold') ||
              (feeStructureErrors?.[section]?.quarterly_monthly_retainer &&
                !formData?.fee_structure?.[section]
                  ?.quarterly_monthly_retainer?.[item.key] &&
                item.fieldOf === 'retainer') ||
              (feeStructureErrors?.[section]?.monthly_monthly_retainer &&
                !formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
                  item.key
                ] &&
                item.fieldOf === 'retainer') ||
              (feeStructureErrors?.[section]?.monthly_retainer &&
                !formData?.fee_structure?.[section]?.monthly_retainer &&
                item.fieldOf === 'retainer') ||
              (item.key === 'billing_cap' &&
                feeStructureErrors?.[section]?.billing_cap) ||
              (item.key === 'billing_minimum' &&
                feeStructureErrors?.[section]?.billing_minimum)
                ? 'form-control  form-control-error'
                : 'form-control '
            }
            name={item.key}
            id={item.key}
            value={
              item.key.includes('quarterly')
                ? formData?.fee_structure?.[section]?.[
                    item.fieldOf === 'retainer'
                      ? 'quarterly_monthly_retainer'
                      : 'quarterly_rev_share'
                  ]?.[item.key]
                : item.key.search(/\bmonth\b/) >= 0
                ? formData.fee_structure?.[section]?.[
                    item.fieldOf === 'retainer'
                      ? 'monthly_monthly_retainer'
                      : 'monthly_rev_share'
                  ]?.[item.key]
                : formData?.fee_structure?.[section]?.[item.key]
            }
            placeholder={item.placeholder || item.label}
            suffix={item.type === 'number-percent' ? '%' : ''}
            onValueChange={(event) => {
              if (isTriggered)
                handleChange(event, item.key, 'numberformat', item.fieldOf);
            }}
            onChange={() => setIsTriggered(true)}
            onBlur={() => setIsTriggered(true)}
            onFocus={() => setIsTriggered(true)}
            thousandSeparator
            decimalScale={2}
            isAllowed={(values) => {
              const { formattedValue, floatValue } = values;
              if (floatValue == null) {
                return formattedValue === '';
              }
              return floatValue <= 100000000;
            }}
            allowNegative={false}
          />
        </>
      );
    }
    if (item.type === 'choice') {
      return (
        <ContractInputSelect>{generateDropdown(item)}</ContractInputSelect>
      );
    }
    if (item.type === 'text') return <>{generateInput(item)}</>;
    return '';
  };

  return (
    <>
      {formData?.seller_type?.label === 'Hybrid' && section !== 'vendor' ? (
        <div className=" liner-titles spacing mt-2 mb-3">
          Seller Fee Structure
        </div>
      ) : (
        ''
      )}
      <ContractInputSelect
        className={
          section === 'vendor' &&
          formData?.seller_type?.label === 'Hybrid' &&
          (vendorSameAsSeller ||
            formData?.fee_structure?.vendor?.vendor_same_as_seller)
            ? '  disabled'
            : ''
        }
      >
        <label htmlFor="fee type">FEE TYPE</label>
        {generateDropdown({
          key: 'fee_type',
          label: 'Fee type',
          type: 'choice',
          placeholder: 'Select Fee Type',
          isMandatory: true,
        })}
        {formData?.fee_structure?.[section]?.fee_type === 'Retainer Only' ? (
          <InputFormField className="mt-3">
            <label htmlFor="retainer">Retainer</label>
            <RetainerOnly
              formData={formData}
              handleChange={handleChange}
              generateHTML={generateHTML}
              setFormData={setFormData}
              section={section}
              feeStructureErrors={feeStructureErrors}
              selectedCurrency={selectedCurrency}
            />
          </InputFormField>
        ) : formData?.fee_structure?.[section]?.fee_type ===
          'Revenue Share Only' ? (
          <>
            {updateFeeTypeOptions?.revShare?.map((field) => (
              <>
                <InputFormField className="mt-3" key={field.key}>
                  <label htmlFor={field.key}>{field.label}</label>
                  {field.type === 'threshold' ? (
                    <RevenueThreshold
                      formData={formData}
                      handleChange={handleChange}
                      generateHTML={generateHTML}
                      setFormData={setFormData}
                      section={section}
                      feeStructureErrors={feeStructureErrors}
                      selectedCurrency={selectedCurrency}
                    />
                  ) : (
                    <>
                      <div className="input-container">
                        {field.prefix ? (
                          <span className="input-icon">
                            {selectedCurrency}{' '}
                          </span>
                        ) : (
                          ''
                        )}
                        {generateHTML(field)}
                        {field.suffix ? (
                          <span className="input-icon end">
                            {field.suffix}{' '}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <p style={{ color: '#556178' }} className="mt-1 mb-0">
                        {field.subtitle}
                      </p>
                    </>
                  )}
                </InputFormField>
                {field.key === 'billing_cap' ? (
                  <ErrorMsg>
                    {feeStructureErrors?.[section]?.billing_cap}
                  </ErrorMsg>
                ) : (
                  ''
                )}
                {field.key === 'billing_minimum' ? (
                  <ErrorMsg>
                    {feeStructureErrors?.[section]?.billing_minimum}
                  </ErrorMsg>
                ) : (
                  ''
                )}
                {field.key === 'rev_share' ? (
                  <ErrorMsg>
                    {feeStructureErrors?.[section]?.rev_share}
                  </ErrorMsg>
                ) : (
                  ''
                )}
              </>
            ))}
          </>
        ) : formData?.fee_structure?.[section]?.fee_type ===
          'Retainer + % Rev Share' ? (
          <>
            {updateFeeTypeOptions?.revShareAndRetainer?.map((field) => (
              <>
                <InputFormField className="mt-3" key={field.key}>
                  <label htmlFor={field.key}>{field.label}</label>

                  {field.type === 'threshold' ? (
                    <RevenueThreshold
                      formData={formData}
                      handleChange={handleChange}
                      generateHTML={generateHTML}
                      setFormData={setFormData}
                      section={section}
                      feeStructureErrors={feeStructureErrors}
                      selectedCurrency={selectedCurrency}
                    />
                  ) : field.type === 'retainer' ? (
                    <RetainerOnly
                      formData={formData}
                      handleChange={handleChange}
                      generateHTML={generateHTML}
                      setFormData={setFormData}
                      section={section}
                      feeStructureErrors={feeStructureErrors}
                      selectedCurrency={selectedCurrency}
                    />
                  ) : (
                    <>
                      <div className="input-container">
                        {field.prefix ? (
                          <span className="input-icon ">
                            {selectedCurrency}{' '}
                          </span>
                        ) : (
                          ''
                        )}
                        {generateHTML(field)}
                        {field.suffix ? (
                          <span className="input-icon end">
                            {field.suffix}{' '}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  )}

                  <p style={{ color: '#556178' }} className="mt-1 mb-0">
                    {field.subtitle}
                  </p>
                </InputFormField>

                {field.key === 'rev_share' ? (
                  <ErrorMsg>
                    {feeStructureErrors?.[section]?.rev_share}
                  </ErrorMsg>
                ) : (
                  ''
                )}
                {field.key === 'billing_cap' ? (
                  <ErrorMsg>
                    {feeStructureErrors?.[section]?.billing_cap}
                  </ErrorMsg>
                ) : (
                  ''
                )}
              </>
            ))}
          </>
        ) : (
          ''
        )}
      </ContractInputSelect>
    </>
  );
}

SellerFeeStructure.defaultProps = {
  setUpdatedFormData: () => {},
  setFormData: () => {},
  formData: {},
  updatedFormData: {},
  DropdownIndicator: {},
  revShareOptions: {},
  showFooter: () => {},
  setSectionError: () => {},
  sectionError: {},
  vendorSameAsSeller: false,
  feeStructureErrors: {},
  setFeeStructureErrors: () => {},
  selectedCurrency: '$ (USD)',
  combinedRevShareFlag: false,
  contractAddendumDetails: {},
  checkAddendumData: false,
  addendumDetails: {},
};

SellerFeeStructure.propTypes = {
  formData: shape({
    fee_structure: shape({}),
  }),
  DropdownIndicator: func,
  setUpdatedFormData: func,
  setFormData: func,
  updatedFormData: shape({}),
  revShareOptions: func,
  section: string.isRequired,
  vendorSameAsSeller: bool,
  showFooter: func,
  setSectionError: func,
  sectionError: shape({}),
  feeStructureErrors: shape({}),
  setFeeStructureErrors: func,
  selectedCurrency: string,
  combinedRevShareFlag: bool,
  contractAddendumDetails: shape({}),
  checkAddendumData: bool,
  addendumDetails: shape({}),
};
