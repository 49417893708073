import dayjs from 'dayjs';

const BindSalesGraphResponseData = (response) => {
  const tempData = [];
  // get a last date of the week
  const getOneDayBack = (reportDateTemp) => {
    const reportDateResultTemp = new Date(reportDateTemp);
    reportDateResultTemp.setDate(reportDateResultTemp.getDate() - 1);
    return reportDateResultTemp;
  };

  const nextReportDate = (indexTemp, graphDataArray, dateParam) => {
    return dayjs(
      indexTemp + 1 < graphDataArray?.length
        ? getOneDayBack(graphDataArray[indexTemp + 1]?.report_date)
        : response?.daily_facts?.dates?.[
            dateParam === 'current' ? 'current_end_date' : 'prevous_end_date'
          ],
    ).format('MMM D YYYY');
  };

  if (response?.daily_facts?.previous?.length) {
    // filterout previous data in one temporary object.
    response.daily_facts.previous.forEach((item, index) => {
      const previousDate = dayjs(item?.report_date).format('MMM D YYYY');
      tempData.push({
        revenueTotalPrevious: item.revenue,
        revenueOrganicPrevious: item.revenue_organic,
        revenuePpcPrevious: item.revenue_ppc,
        revenueDspPrevious: item.revenue_dsp,
        unitsSoldPrevious: item.units_sold,
        trafficPrevious: item.traffic,
        conversionPrevious: item.conversion,
        // ad spend, tacos starts
        adSpendTotalPrevious: item?.ad_spend_total,
        adSpendPpcPrevious: item?.sponsored_ad_spend,
        adSpendDspPrevious: item?.dsp_spends,
        tacosPrevious: item?.tacos,
        // ad spend, tacos ends
        adSpendPrevious: item.sponsored_ad_spend,
        previousDate,
        previousEndDate: nextReportDate(
          index,
          response?.daily_facts?.previous,
          'previous',
        ),

        revenueTotalPreviousLabel:
          item.revenue !== null ? item.revenue.toFixed(2) : '0.00',
        revenueOrganicPreviousLabel:
          item.revenue_organic !== null
            ? item.revenue_organic.toFixed(2)
            : '0.00',
        revenuePpcPreviousLabel:
          item.revenue_ppc !== null ? item.revenue_ppc.toFixed(2) : '0.00',
        revenueDspPreviousLabel:
          item.revenue_dsp !== null ? item.revenue_dsp.toFixed(2) : '0.00',
        unitsSoldPreviousLabel:
          item.units_sold !== null ? item.units_sold.toFixed(0) : '0',
        trafficPreviousLabel:
          item.traffic !== null ? item.traffic.toFixed(0) : '0',
        conversionPreviousLabel:
          item.conversion !== null ? item.conversion.toFixed(2) : '0',
        // ad spend, tacos starts
        adSpendTotalPreviousLabel:
          item?.ad_spend_total !== null
            ? item?.ad_spend_total?.toFixed(2)
            : '0.00',
        adSpendPpcPreviousLabel:
          item.sponsored_ad_spend !== null
            ? item?.sponsored_ad_spend?.toFixed(2)
            : '0.00',
        adSpendDspPreviousLabel:
          item.dsp_spends !== null ? item?.dsp_spends?.toFixed(2) : '0.00',
        tacosPreviousLabel:
          item?.tacos !== null ? item?.tacos?.toFixed(2) : '0',
        // ad spend, tacos ends
        adSpendPreviousLabel:
          item.sponsored_ad_spend !== null
            ? item.sponsored_ad_spend.toFixed(2)
            : '0',
      });
    });
  }

  // filterout current data in one temporary object.
  if (response?.daily_facts?.current?.length) {
    response.daily_facts.current.forEach((item, index) => {
      const currentReportDate = dayjs(item?.report_date).format('MMM D YYYY');
      let indexNumber = index;

      // add the current data at same index of prevoius in temporary object
      if (
        response.daily_facts.previous &&
        index < response.daily_facts.previous.length
      ) {
        tempData[index].date = currentReportDate;
        tempData[index].revenueTotalCurrent = item.revenue;
        tempData[index].revenueOrganicCurrent = item.revenue_organic;
        tempData[index].revenuePpcCurrent = item.revenue_ppc;
        tempData[index].revenueDspCurrent = item.revenue_dsp;
        tempData[index].unitsSoldCurrent = item.units_sold;
        tempData[index].trafficCurrent = item.traffic;
        tempData[index].conversionCurrent = item.conversion;
        // ad spend, tacos starts
        tempData[index].adSpendTotalCurrent = item?.ad_spend_total;
        tempData[index].adSpendPpcCurrent = item?.sponsored_ad_spend;
        tempData[index].adSpendDspCurrent = item?.dsp_spends;
        tempData[index].tacosCurrent = item?.tacos;
        // ad spend, tacos ends
        tempData[index].adSpendCurrent = item.sponsored_ad_spend;
        tempData[index].endDate = nextReportDate(
          index,
          response?.daily_facts?.current,
          'current',
        );

        tempData[index].revenueTotalCurrentLabel =
          item.revenue !== null ? item.revenue.toFixed(2) : '0.00';
        tempData[index].revenueOrganicCurrentLabel =
          item.revenue_organic !== null
            ? item.revenue_organic.toFixed(2)
            : '0.00';
        tempData[index].revenuePpcCurrentLabel =
          item.revenue_ppc !== null ? item.revenue_ppc.toFixed(2) : '0.00';
        tempData[index].revenueDspCurrentLabel =
          item.revenue_dsp !== null ? item.revenue_dsp.toFixed(2) : '0.00';
        tempData[index].unitsSoldCurrentLabel =
          item.units_sold !== null ? item.units_sold.toFixed(0) : '0';
        tempData[index].trafficCurrentLabel =
          item.traffic !== null ? item.traffic.toFixed(0) : '0';
        tempData[index].conversionCurrentLabel =
          item.conversion !== null ? item.conversion.toFixed(2) : '0';
        // ad spend, tacos starts
        tempData[index].adSpendTotalCurrentLabel =
          item?.ad_spend_total !== null
            ? item?.ad_spend_total?.toFixed(2)
            : '0.00';
        tempData[index].adSpendPpcCurrentLabel =
          item?.sponsored_ad_spend !== null
            ? item?.sponsored_ad_spend?.toFixed(2)
            : '0.00';
        tempData[index].adSpendDspCurrentLabel =
          item?.dsp_spends !== null ? item?.dsp_spends?.toFixed(2) : '0.00';
        tempData[index].tacosCurrentLabel =
          item?.tacos !== null ? item?.tacos?.toFixed(2) : '0';
        // ad spend, tacos ends
        tempData[index].adSpendCurrentLabel =
          item.sponsored_ad_spend !== null
            ? item.sponsored_ad_spend.toFixed(2)
            : '0';

        // to add the dotted line. we have to check null matrix and add the dummy number like 8
        if (index > 0) {
          indexNumber = index - 1;
        } else {
          indexNumber = index;
        }
        tempData[indexNumber].revenueTotalDashLength =
          item.revenue === null ? 8 : null;
        tempData[indexNumber].revenueOrganicDashLength =
          item.revenue_organic === null ? 8 : null;
        tempData[indexNumber].revenuePpcDashLength =
          item.revenue_ppc === null ? 8 : null;
        tempData[indexNumber].revenueDspDashLength =
          item.revenue_dsp === null ? 8 : null;
        tempData[indexNumber].unitsSoldDashLength =
          item.units_sold === null ? 8 : null;
        tempData[indexNumber].trafficDashLength =
          item.traffic === null ? 8 : null;
        tempData[indexNumber].conversionDashLength =
          item.conversion === null ? 8 : null;
        // ad spend, tacos starts
        tempData[indexNumber].adSpendTotalDashLength =
          item?.ad_spend_total === null ? 8 : null;
        tempData[indexNumber].adSpendPpcDashLength =
          item?.sponsored_ad_spend === null ? 8 : null;
        tempData[indexNumber].adSpendDspDashLength =
          item?.dsp_spends === null ? 8 : null;
        tempData[indexNumber].tacosDashLength = item?.tacos === null ? 8 : null;
        // ad spend, tacos ends
        tempData[indexNumber].adSpendDashLength =
          item.sponsored_ad_spend === null ? 8 : null;
      } else {
        // if current data count is larger than previous count then
        // generate separate key for current data and defien previous value null and previous label 0
        tempData.push({
          revenueTotalCurrent: item.revenue,
          revenueOrganicCurrent: item.revenue_organic,
          revenuePpcCurrent: item.revenue_ppc,
          revenueDspCurrent: item.revenue_dsp,
          unitsSoldCurrent: item.units_sold,
          trafficCurrent: item.traffic,
          conversionCurrent: item.conversion,
          // ad spend, tacos starts
          adSpendTotalCurrent: item?.ad_spend_total,
          adSpendPpcCurrent: item?.sponsored_ad_spend,
          adSpendDspCurrent: item?.dsp_spends,
          tacosCurrent: item?.tacos,
          // ad spend, tacos ends
          adSpendCurrent: item.sponsored_ad_spend,
          date: currentReportDate,
          endDate: nextReportDate(
            index,
            response?.daily_facts?.current,
            'current',
          ),

          revenueTotalPrevious: null,
          revenueOrganicPrevious: null,
          revenuePpcPrevious: null,
          revenueDspPrevious: null,
          unitsSoldPrevious: null,
          trafficPrevious: null,
          conversionPrevious: null,
          // ad spend, tacos starts
          adSpendTotalPrevious: null,
          adSpendPpcPrevious: null,
          adSpendDspPrevious: null,
          tacosPrevious: null,
          // ad spend, tacos ends
          adSpendPrevious: null,

          revenueTotalCurrentLabel:
            item.revenue !== null ? item.revenue.toFixed(2) : '0.00',
          revenueOrganicCurrentLabel:
            item.revenue_organic !== null
              ? item.revenue_organic.toFixed(2)
              : '0.00',
          revenuePpcCurrentLabel:
            item.revenue_ppc !== null ? item.revenue_ppc.toFixed(2) : '0.00',
          revenueDspCurrentLabel:
            item.revenue_dsp !== null ? item.revenue_dsp.toFixed(2) : '0.00',
          unitsSoldCurrentLabel:
            item.units_sold !== null ? item.units_sold.toFixed(0) : '0',
          trafficCurrentLabel:
            item.traffic !== null ? item.traffic.toFixed(0) : '0',
          conversionCurrentLabel:
            item.conversion !== null ? item.conversion.toFixed(2) : '0',
          // ad spend, tacos starts
          adSpendTotalCurrentLabel:
            item?.ad_spend_total !== null
              ? item?.ad_spend_total?.toFixed(2)
              : '0.00',
          adSpendPpcCurrentLabel:
            item?.sponsored_ad_spend !== null
              ? item?.sponsored_ad_spend?.toFixed(2)
              : '0.00',
          adSpendDspCurrentLabel:
            item?.dsp_spends !== null ? item?.dsp_spends?.toFixed(2) : '0.00',
          tacosCurrentLabel:
            item?.tacos !== null ? item?.tacos?.toFixed(2) : '0',
          // ad spend, tacos ends
          adSpendCurrentLabel:
            item.sponsored_ad_spend !== null
              ? item.sponsored_ad_spend.toFixed(2)
              : '0',

          revenueTotalPreviousLabel: '0.0',
          revenueOrganicPreviousLabel: '0.0',
          revenuePpcPreviousLabel: '0.0',
          revenueDspPreviousLabel: '0.0',
          unitsSoldPreviousLabel: '0',
          trafficPreviousLabel: '0',
          conversionPreviousLabel: '0',
          // ad spend, tacos starts
          adSpendTotalPreviousLabel: '0.0',
          adSpendPpcPreviousLabel: '0.0',
          adSpendDspPreviousLabel: '0.0',
          tacosPreviousLabel: '0',
          // ad spend, tacos ends
          adSpendPreviousLabel: '0',
        });
      }
    });
  }
  return tempData;
};

export default BindSalesGraphResponseData;
