import React from 'react';

import ReactTooltip from 'react-tooltip';
import { func, string, shape, arrayOf, number } from 'prop-types';

import { Button, GetInitialName } from '../../../common';
import { CaretUp, PlusIcon } from '../../../theme/images';

export default function ShowTeamMembers({
  role,
  memberData,
  setShowMemberList,
  setShowNotesModal,
  isChildBPList,
}) {
  return (
    <>
      <div className="text-right">
        {!role.includes('Customer') ? (
          <div className="add-more-people">
            {memberData?.map((item) =>
              item.id ? (
                <React.Fragment key={item.id}>
                  <div
                    className="add-more-people cursor "
                    data-tip
                    data-for={item.id}
                    onClick={() =>
                      setShowMemberList({
                        show: true,
                        add: false,
                        modal: true,
                      })
                    }
                    role="presentation"
                  >
                    <GetInitialName userInfo={item?.user} type="team" />
                  </div>

                  <ReactTooltip
                    place="bottom"
                    id={item.id}
                    aria-haspopup="true"
                  >
                    <strong>
                      {item?.user?.first_name || ' '}{' '}
                      {item?.user?.last_name || ' '}
                    </strong>
                    <p style={{ color: 'white', fontSize: '11px' }}>
                      {item?.role_group?.name}
                    </p>
                  </ReactTooltip>
                </React.Fragment>
              ) : null,
            )}
            {!isChildBPList ? (
              <div
                className="add-more-people btn-add-team cursor mr-4 mb-2"
                role="presentation"
                onClick={() =>
                  setShowMemberList({
                    show: true,
                    add: true,
                    modal: true,
                  })
                }
              >
                <img src={PlusIcon} alt="add" />
              </div>
            ) : null}
            <Button
              className="notes-btn cursor "
              onClick={() =>
                setShowNotesModal({
                  modal: true,
                  apiCall: false,
                })
              }
            >
              <img src={CaretUp} alt="caret" />
              Notes
            </Button>{' '}
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

ShowTeamMembers.defaultProps = {
  isChildBPList: 0,
  setShowMemberList: () => {},
  setShowNotesModal: () => {},
};

ShowTeamMembers.propTypes = {
  isChildBPList: number,
  role: string.isRequired,
  memberData: arrayOf(shape({})).isRequired,
  setShowMemberList: func,
  setShowNotesModal: func,
};
