import queryString from 'query-string';
import axiosInstance from '../axios';
import {
  API_REPORTS_SECTION,
  API_CUSTOMER,
  API_REPORTS_TYPES,
  API_REPORTS_DOWNLOAD,
  API_CUSTOMIZED_REPORTS,
  API_CUSTOMIZED_REPORTS_ACCESS,
  API_CUSTOMIZED_REPORTS_METRICS_DATA,
  API_ASIN_PERFORMANCE,
  API_CUSTOM_REPORTS_NOTIFICATION,
  API_ASIN_PERFORMANCE_DATA_COUNT,
  API_CUSTOMISED_REPORT_STATUS,
  API_CUSTOMIZED_REPORTS_TEMPLATE,
  API_PARTNER_OPPORTUNITY_DROPDOWN_LIST,
  OBJECTIVE,
  TITLE,
  CUSTOM,
} from '../constants';

// PENDING = 'pending';
// ACTIVE = 'active';
// AT_RISK = 'at risk';
// PENDING_CANCELLATION = 'pending cancellation';
// INACTIVE = 'inactive';
// PENDING_ACCOUNT_SETUP = 'pending account setup';

export async function getBPUserList(query) {
  let params = {};
  let statusParams = {};
  const availableStatus = [
    'active',
    'at risk',
    'pending cancellation',
    'in collections',
    'out of contract',
  ];
  statusParams = queryString.stringify({
    status: availableStatus,
  });

  if (query) {
    params = { q: query };
  } else {
    params = {
      no_page: 'True',
      fields: 'id,company_name,customer_account_type',
    };
  }
  let result = {};
  result = await axiosInstance
    .get(
      `${API_CUSTOMER}?${
        statusParams && statusParams.length ? statusParams : ''
      }`,
      { params },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getReportTypes() {
  let result = {};
  result = await axiosInstance
    .get(API_REPORTS_TYPES)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getReportsSectionData(
  reportType,
  searchString,
  page,
  customerId,
  dailyFacts,
  marketplace,
  startDate,
  endDate,
) {
  let params = {
    page,
    marketplace,
    date_range: reportType === 'account_audit' ? 'all-time' : dailyFacts,
  };

  if (searchString !== '') {
    params = {
      ...params,
      q: searchString,
    };
  }

  if (customerId && customerId !== 'Select-BP') {
    params = {
      ...params,
      customer: customerId,
    };
  }

  if (dailyFacts === CUSTOM) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }

  if (reportType !== 'reportType') {
    params = {
      ...params,
      report_type: reportType,
    };
  }

  let result = {};
  result = await axiosInstance
    .get(`${API_REPORTS_SECTION}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getReportDownload(
  selctedUser,
  timeFilter,
  startDate,
  endDate,
  reportID,
  marketplace,
  selectedTitle,
) {
  let result = {};

  let params = {
    customer: selctedUser,
    date_range: timeFilter,
    report: reportID,
    marketplace,
  };

  if (timeFilter === CUSTOM) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }

  if (selectedTitle?.value !== TITLE) {
    params = {
      ...params,
      partner_opportunity: selectedTitle?.value,
    };
  }
  result = await axiosInstance
    .get(API_REPORTS_DOWNLOAD, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get the dropdown options in partner opprotunity modal
export async function getPartnerOpportunitiesDropdownOptions(
  timeframe,
  customDateState,
  selectedProduct,
  selectedObjective,
  type,
) {
  let result = {};

  let params = {
    date_range: timeframe,
    start_date: customDateState?.startDate,
    end_date: customDateState?.endDate,
  };

  if (type === OBJECTIVE) {
    params = {
      ...params,
      product: selectedProduct?.value,
    };
  }
  if (type === TITLE) {
    params = {
      ...params,
      product: selectedProduct?.value,
      objective: selectedObjective?.value,
    };
  }

  result = await axiosInstance
    .get(API_PARTNER_OPPORTUNITY_DROPDOWN_LIST, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCustomzedReportData(page, searchString, OrderBy) {
  let params = { page };

  if (searchString !== '') {
    params = {
      ...params,
      q: searchString,
    };
  }

  if (OrderBy) {
    params = {
      ...params,
      'order-by': OrderBy,
    };
  }

  let result = {};
  result = await axiosInstance
    .get(`${API_CUSTOMIZED_REPORTS}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getReportGraphSelectedFileds(reportId) {
  let result = {};
  result = await axiosInstance
    .get(`${API_CUSTOMIZED_REPORTS}${reportId}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getReportTemplate(templateId) {
  let result = {};
  result = await axiosInstance
    .get(`${API_CUSTOMIZED_REPORTS_TEMPLATE}${templateId}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function saveReport(reportId, data, saveType) {
  const params = { ...data };
  if (reportId !== null && saveType === 'replaceExisting') {
    const result = await axiosInstance
      .patch(`${API_CUSTOMIZED_REPORTS + reportId}/`, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return result;
  }
  const result = await axiosInstance
    .post(API_CUSTOMIZED_REPORTS, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getSalesSponsoredDspMetricsData(
  customerId,
  marketplace,
  selectedTimeFrame,
  startDate,
  endDate,
  accountType,
  graphFrequency,
  // adType,
) {
  let params = {
    customer_id: customerId,
    marketplace,
    daily_facts: selectedTimeFrame,
    account_type: accountType.toLowerCase(),
    group_by: graphFrequency,
    // sponsored_type: adType,
  };

  if (selectedTimeFrame === CUSTOM) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }
  let result = {};
  result = await axiosInstance
    .get(API_CUSTOMIZED_REPORTS_METRICS_DATA, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function checkReportStatusToEdit(reportId) {
  let result = {};
  result = await axiosInstance
    .get(`${API_CUSTOMISED_REPORT_STATUS}${reportId}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function changeReportStatus(reportId, reportStatus) {
  let result = {};
  const params = {
    report_status: reportStatus,
  };
  result = await axiosInstance
    .patch(`${API_CUSTOMISED_REPORT_STATUS}${reportId}/`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCustomisedReportAccess(customerId) {
  const params = {
    customer: customerId,
  };

  let result = {};
  result = await axiosInstance
    .get(API_CUSTOMIZED_REPORTS_ACCESS, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCutomReportASINPerformanceData(
  customer,
  marketplace,
  asinType,
  orderBy,
  timefilterType,
  startDate,
  endDate,
  asinCount,
) {
  let params = {
    customer,
    marketplace,
    'order-by': orderBy,
    asin_type: asinType,
    daily_facts: timefilterType,
  };

  if (asinCount !== null) {
    params = {
      ...params,
      asin_count: asinCount,
    };
  }

  if (timefilterType === CUSTOM) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }

  const result = await axiosInstance.get(`${API_ASIN_PERFORMANCE}`, {
    params,
  });
  return result;
}

export async function getCustomReportActivity(actions, reportID) {
  const params = {
    actions,
    report_id: reportID,
  };

  const result = await axiosInstance.get(`${API_CUSTOM_REPORTS_NOTIFICATION}`, {
    params,
  });
  return result;
}

export async function getAsinDataCount(customer, marketplace, timefilterType) {
  const params = {
    customer,
    marketplace,
    daily_facts: timefilterType,
  };

  const result = await axiosInstance.get(`${API_ASIN_PERFORMANCE_DATA_COUNT}`, {
    params,
  });
  return result;
}

export async function getReportTemplateList(accountType) {
  const params = {
    account_type: accountType.toLowerCase(),
  };

  const result = await axiosInstance.get(`${API_CUSTOMIZED_REPORTS_TEMPLATE}`, {
    params,
  });
  return result;
}
