/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';

import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { arrayOf, shape, string } from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import {
  restockLimitsColorCodes as colorCode,
  storageSubTypes,
} from '../../../../constants';

am4core.useTheme(am4themes_dataviz);
am4core.color('red');

export default function RestockLimitsGraph({ selectedDF, chartId, chartData }) {
  const chart = useRef(null);
  const isTablet = useMediaQuery({ minWidth: 768 });
  useEffect(() => {
    function renderTooltipSeriesValue(
      name,
      value,
      color,
      formatter,
      showIndicator,
    ) {
      return `<ul style="padding:0; margin: 0 0 4px 0; max-width: 240px;">
          ${
            showIndicator
              ? `<li style="display: inline-block;">
                {' '}
                <div
                  style="background-color: ${color};
                    border: 1px solid white;
                    border-radius: ${name === 'Previous' ? '2px' : '100%'};
                    width: 10px;
                    height: 10px;"
                />
              </li>`
              : ''
          }
          <li style="display: inline-block;">
            <div style="color: ${colorCode?.tooltipText};
            text-transform: uppercase;
            font-size: 11px;
            padding-left: 5px;">${name} </div>
          </li>
          <li style="display: inline-block; float: right; margin-left: 50px;">
            <div style=" color: white;
            font-size: 16px; text-align: right;
            ">${
              formatter !== null
                ? `{${value}.formatNumber('${formatter}')}%`
                : `{${value}}`
            }
            </div>
          </li>
        </ul>`;
    }

    function renderTooltip() {
      const tooltipText = `
        <div style="font-size: 14px; font-family: Noah-Medium; padding:5px 0; text-transform: uppercase">
          {categoryX}
        </div>
        <hr />

        <strong style="font-size: 14px; padding:5px 0">
          ONHAND 
        </strong>
        ${renderTooltipSeriesValue(
          'Current',
          'onhandLabel',
          colorCode?.onhand,
          null,
          true,
        )}
        ${
          selectedDF?.value !== 'custom'
            ? renderTooltipSeriesValue(
                'Previous',
                'previousOnhandLabel',
                colorCode?.previousOnhand,
                null,
                true,
              )
            : ''
        }
        <div style="margin-bottom: 10px;"> </div>

        <strong style="font-size: 14px; padding:5px 0">
          OPEN SHIPMENTS
        </strong>
        ${renderTooltipSeriesValue(
          'Current',
          'openShipmentLabel',
          colorCode?.openShipment,
          null,
          true,
        )}
        ${
          selectedDF?.value !== 'custom'
            ? renderTooltipSeriesValue(
                'Previous',
                'previousOpenShipmentLabel',
                colorCode?.previousOpenShipment,
                null,
                true,
              )
            : ''
        }
        <div style="margin-bottom: 10px;"> </div>

        <strong style="font-size: 14px; padding:5px 0">
          MAXIMUM SHIPMENTS
        </strong>
        ${renderTooltipSeriesValue(
          'Current',
          'maximumShipmentLabel',
          colorCode?.maximumShipment,
          null,
          true,
        )}
        ${
          selectedDF?.value !== 'custom'
            ? renderTooltipSeriesValue(
                'Previous',
                'previousMaximumShipmentLabel',
                colorCode?.previousMaximumShipment,
                null,
                true,
              )
            : ''
        }
        <div style="margin-bottom: 10px;"> </div>

        <strong style="font-size: 14px; padding:5px 0">
          UTILIZATION 
        </strong>
        ${renderTooltipSeriesValue(
          'Current',
          'utilizationLabel',
          colorCode?.onhand,
          '#.#',
          false,
        )}
        ${
          selectedDF?.value !== 'custom'
            ? renderTooltipSeriesValue(
                'Previous',
                'previousUtilizationLabel',
                colorCode?.previousOnhand,
                '#.#',
                false,
              )
            : ''
        }
      `;

      return tooltipText;
    }

    chart.current = am4core.create(chartId, am4charts.XYChart);
    chart.current.data = chartData;
    chart.current.padding = (20, 20, 0, 0);
    chart.current.logo.disabled = true; // disable amchart logo
    chart.current.zoomOutButton.disabled = true; // disabled zoomout button

    chart.current.cursor = new am4charts.XYCursor();
    chart.current.cursor.lineY.disabled = true;
    chart.current.cursor.lineX.disabled = true;
    chart.current.cursor.behavior = 'none';
    chart.current.cursor.maxTooltipDistance = -1;

    const categoryAxis = chart.current.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'storageType';
    categoryAxis.title.text = 'Storage Type';
    categoryAxis.title.dy = 15;
    categoryAxis.title.fontWeight = 600;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.labels.template.adapter.add('textOutput', (text) => {
      return text?.toUpperCase();
    });

    const label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 100;

    categoryAxis.events.on('sizechanged', (ev) => {
      const axis = ev.target;
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      axis.renderer.labels.template.maxWidth = cellWidth;
    });

    const valueAxis = chart.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = 'Allowed Storage Capacity';
    valueAxis.title.fontWeight = 600;
    valueAxis.renderer.grid.template.strokeDasharray = '3,3';
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = `#.#a`;
    valueAxis.extraMax = 0.005;
    valueAxis.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: 'K' },
      { number: 1e6, suffix: 'M' },
      { number: 1e9, suffix: 'B' },
    ];
    valueAxis.numberFormatter.smallNumberPrefixes = [];

    const currentSeriesName = ['onhand', 'openShipment', 'maximumShipment'];
    const previousSeriesName = [
      'previousOnhand',
      'previousOpenShipment',
      'previousMaximumShipment',
    ];
    const snapToSeries = [];
    // Create series
    function createSeries(field, name, stacked, color, dx = false) {
      const series = chart.current.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'storageType';
      series.name = name;
      series.stacked = stacked;
      series.columns.template.width = am4core.percent(
        selectedDF?.value === 'custom' ? 100 : 100,
      );

      series.columns.template.fill = am4core.color(color);
      series.stroke = am4core.color(color);

      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(
        colorCode?.tooltipBackground,
      );
      series.tooltipHTML = renderTooltip();
      series.tooltip.pointerOrientation = 'down';
      series.tooltip.ignoreBounds = true;
      series.columns.template.dx = dx ? 1 : 0;

      // corner Radius for Top-Right
      series.columns.template.column.adapter.add(
        'cornerRadiusTopRight',
        (radius, item) => {
          const { dataItem } = item;
          let lastSeries;
          // for current_value series
          if (currentSeriesName.includes(field)) {
            chart.current.series.each((localSeries) => {
              if (currentSeriesName.includes(localSeries.dataFields.valueY)) {
                if (
                  dataItem.dataContext[localSeries.dataFields.valueY] &&
                  !localSeries.isHidden &&
                  !localSeries.isHiding
                ) {
                  lastSeries = localSeries;
                }
              }
            });
          } else {
            // for previous_value series
            chart.current.series.each((localSeries) => {
              if (previousSeriesName.includes(localSeries.dataFields.valueY)) {
                if (
                  dataItem.dataContext[localSeries.dataFields.valueY] &&
                  !localSeries.isHidden &&
                  !localSeries.isHiding
                ) {
                  lastSeries = localSeries;
                }
              }
            });
          }

          // If current series is the one, use rounded corner
          return dataItem.component === lastSeries ? 10 : radius;
        },
      );

      // corner Radius for Top-Left
      series.columns.template.column.adapter.add(
        'cornerRadiusTopLeft',
        (radius, item) => {
          const { dataItem } = item;
          let lastSeries;
          // for current_value series
          if (currentSeriesName.includes(field)) {
            chart.current.series.each((localSeries) => {
              if (currentSeriesName.includes(localSeries.dataFields.valueY)) {
                if (
                  dataItem.dataContext[localSeries.dataFields.valueY] &&
                  !localSeries.isHidden &&
                  !localSeries.isHiding
                ) {
                  lastSeries = localSeries;
                }
              }
            });
          } else {
            // for previous_value series
            chart.current.series.each((localSeries) => {
              if (previousSeriesName.includes(localSeries.dataFields.valueY)) {
                if (
                  dataItem.dataContext[localSeries.dataFields.valueY] &&
                  !localSeries.isHidden &&
                  !localSeries.isHiding
                ) {
                  lastSeries = localSeries;
                }
              }
            });
          }

          // If current series is the one, use rounded corner
          return dataItem.component === lastSeries ? 10 : radius;
        },
      );

      // corner Radius for Bottom-Right
      series.columns.template.column.adapter.add(
        'cornerRadiusBottomRight',
        (radius, item) => {
          const { dataItem } = item;
          let lastSeries = null;
          // for current_value series
          if (currentSeriesName.includes(field)) {
            chart.current.series.each((localSeries) => {
              if (
                lastSeries === null &&
                currentSeriesName.includes(localSeries.dataFields.valueY)
              ) {
                if (
                  dataItem.dataContext[localSeries.dataFields.valueY] &&
                  !localSeries.isHidden &&
                  !localSeries.isHiding
                ) {
                  lastSeries = localSeries;
                }
              }
            });
          } else {
            // for previous_value series
            chart.current.series.each((localSeries) => {
              if (
                lastSeries === null &&
                previousSeriesName.includes(localSeries.dataFields.valueY)
              ) {
                if (
                  dataItem.dataContext[localSeries.dataFields.valueY] &&
                  !localSeries.isHidden &&
                  !localSeries.isHiding
                ) {
                  lastSeries = localSeries;
                }
              }
            });
          }
          // If current series is the one, use rounded corner
          return dataItem.component === lastSeries ? 10 : radius;
        },
      );

      // corner Radius for Bottom-Left
      series.columns.template.column.adapter.add(
        'cornerRadiusBottomLeft',
        (radius, item) => {
          const { dataItem } = item;
          let lastSeries = null;
          // for current_value series
          if (currentSeriesName.includes(field)) {
            chart.current.series.each((localSeries) => {
              if (
                lastSeries === null &&
                currentSeriesName.includes(localSeries.dataFields.valueY)
              ) {
                if (
                  dataItem.dataContext[localSeries.dataFields.valueY] &&
                  !localSeries.isHidden &&
                  !localSeries.isHiding
                ) {
                  lastSeries = localSeries;
                }
              }
            });
          } else {
            // for previous_value series
            chart.current.series.each((localSeries) => {
              if (
                lastSeries === null &&
                previousSeriesName.includes(localSeries.dataFields.valueY)
              ) {
                if (
                  dataItem.dataContext[localSeries.dataFields.valueY] &&
                  !localSeries.isHidden &&
                  !localSeries.isHiding
                ) {
                  lastSeries = localSeries;
                }
              }
            });
          }

          // If current series is the one, use rounded corner
          return dataItem.component === lastSeries ? 10 : radius;
        },
      );

      snapToSeries.push(series);
      return series;
    }

    // render series for current value
    storageSubTypes.map((subType) => {
      createSeries(
        subType?.key,
        subType?.label,
        subType?.isStacked,
        subType?.bgColor,
        false, // previousDx
      );
      return null;
    });

    // render series for current value
    if (selectedDF?.value !== 'custom') {
      storageSubTypes.map((subType) => {
        createSeries(
          subType?.previousKey,
          subType?.previousLabel,
          subType?.previousIsStacked,
          subType?.previousBgColor,
          subType?.previousDx,
        );
        return null;
      });
    }

    chart.current.cursor.snapToSeries = snapToSeries;

    return () => chart.current && chart.current.dispose();
  }, [chartData, chartId, selectedDF]);

  return (
    <div className={isTablet ? '' : 'horizontal-scroll-visible mb-3'}>
      <div
        id={chartId}
        style={{
          width: '100%',
          height: isTablet ? '400px' : '600px',
          paddingTop: '20px',
          minWidth: '700px',
          marginBottom: '15px',
        }}
      />
    </div>
  );
}

RestockLimitsGraph.defaultProps = {
  selectedDF: {},
  chartData: [],
};

RestockLimitsGraph.propTypes = {
  chartId: string.isRequired,
  chartData: arrayOf(shape({})),
  selectedDF: shape({}),
};
