import React from 'react';

import Select from 'react-select';
import { arrayOf, shape, func, bool } from 'prop-types';

import { DropdownIndicator } from '../../../../common';

function AdditionalDspMarketplaces({
  dspAdditionalMarketplaces,
  formData,
  item,
  showFooter,
  setFormData,
  setUpdatedFormData,
  updatedFormData,
  originalData,
  sectionError,
  setSectionError,
  contractAddendumDetails,
  addendumDetails,
  dspMarketpalcesError,
  setDspMarketpalcesError,
  displayMarketplaceName,
}) {
  const checkClickedElementAlreadyExists = (event, value) => {
    if (dspMarketpalcesError && Object.keys(dspMarketpalcesError).length) {
      setDspMarketpalcesError({
        ...dspMarketpalcesError,
        monthly_budget: '',
        one_time_buget: '',
      });
    }
    let clickedValue = '';
    if (value.action === 'select-option') {
      clickedValue = value?.option?.value;
    }
    if (value.action === 'remove-value') {
      clickedValue = value?.removedValue.value;
    }

    const contractData = contractAddendumDetails?.notActive
      ? addendumDetails
      : originalData;
    const itemInFormData =
      contractData?.dsp_marketplaces?.length &&
      contractData?.dsp_marketplaces?.find(
        (originalItem) => originalItem && originalItem?.name === clickedValue,
      );
    return itemInFormData;
  };

  const handleError = (event, value) => {
    if (
      !formData?.dsp_marketplaces ||
      (event?.length === 1 && value?.action === 'select-option')
    ) {
      setSectionError({
        ...sectionError,
        dsp: sectionError.dsp - 1,
      });
    }

    if (!event) {
      setSectionError({
        ...sectionError,
        dsp: sectionError.dsp + 1,
      });
    }
  };

  const handleChange = (event, value) => {
    showFooter(true);
    if (dspMarketpalcesError && Object.keys(dspMarketpalcesError).length)
      setDspMarketpalcesError({
        ...dspMarketpalcesError,
        monthly_budget: '',
        one_time_buget: '',
      });
    if (value.action === 'select-option') {
      const itemInFormData = checkClickedElementAlreadyExists(event, value);
      // checked whether checked item present in newly created list
      if (
        !(
          updatedFormData?.dsp_marketplaces?.create &&
          updatedFormData?.dsp_marketplaces?.create.find(
            (updatedFormItem) => updatedFormItem?.name === value?.option?.value,
          )
        )
      ) {
        // if checked item not found in newly created list then  again check whether it is present in original formData variable because if it is found in formData then we need to add that found item in newly created list bcoz we need id and all of that item to push in newly created list.
        // here we check whether checked item present in orginal formDAta list then add that found item in newly created list
        if (itemInFormData) {
          setFormData({
            ...formData,
            dsp_marketplaces: [...formData?.dsp_marketplaces, itemInFormData],
          });

          setUpdatedFormData({
            ...updatedFormData,
            dsp_marketplaces: {
              ...updatedFormData?.dsp_marketplaces,
              create: [...formData?.dsp_marketplaces, itemInFormData],
              delete: updatedFormData?.dsp_marketplaces?.delete?.filter(
                (updatedFormItem) => updatedFormItem !== itemInFormData.id,
              ),
            },
          });
        }
        // else we create dict as BE required for new item and we push that in newly created list
        else {
          const newContractData = {
            contract_id: originalData && originalData.id,
            name: value?.option?.value,
          };
          const newAddendumData = {
            contract_addendum_id: contractAddendumDetails?.id,
            name: value?.option?.value,
          };
          setFormData({
            ...formData,
            dsp_marketplaces: formData?.dsp_marketplaces?.length
              ? [
                  ...formData?.dsp_marketplaces,
                  contractAddendumDetails?.notActive
                    ? newAddendumData
                    : newContractData,
                ]
              : [
                  contractAddendumDetails?.notActive
                    ? newAddendumData
                    : newContractData,
                ],
          });

          setUpdatedFormData({
            ...updatedFormData,
            dsp_marketplaces: {
              ...updatedFormData?.dsp_marketplaces,
              create: formData?.dsp_marketplaces?.length
                ? [
                    ...formData?.dsp_marketplaces,
                    contractAddendumDetails?.notActive
                      ? newAddendumData
                      : newContractData,
                  ]
                : [
                    contractAddendumDetails?.notActive
                      ? newAddendumData
                      : newContractData,
                  ],
            },
          });
        }
      }
    }
    if (value.action === 'remove-value') {
      const itemInFormData = checkClickedElementAlreadyExists(event, value);

      // if unchecked item found in original list then add its id to newly created delte list
      if (itemInFormData?.id) {
        const removeBudgetAllocations = [...formData.dsp_marketplaces];
        for (const market of removeBudgetAllocations) {
          market.monthly_budget = null;
          market.one_time_buget = null;
        }
        setFormData({
          ...formData,
          dsp_marketplaces: formData?.dsp_marketplaces?.filter(
            (marketplace) => marketplace.id !== itemInFormData?.id,
          ),
        });

        setUpdatedFormData({
          ...updatedFormData,
          dsp_marketplaces: {
            ...updatedFormData?.dsp_marketplaces,
            create: formData?.dsp_marketplaces?.filter(
              (marketplace) => marketplace.id !== itemInFormData?.id,
            ),
            delete: updatedFormData?.dsp_marketplaces?.delete?.length
              ? [
                  ...updatedFormData?.dsp_marketplaces?.delete,
                  itemInFormData.id,
                ]
              : [itemInFormData.id],
          },
        });
      } else {
        const updatedCreateList =
          updatedFormData?.dsp_marketplaces?.create?.filter(
            (marketplace) => marketplace.name !== value.removedValue.value,
          );

        const updatedVATData = updatedCreateList;

        setFormData({
          ...formData,
          dsp_marketplaces: updatedVATData,
        });

        setUpdatedFormData({
          ...updatedFormData,
          dsp_marketplaces: {
            ...updatedFormData?.dsp_marketplaces,
            create: updatedVATData,
          },
        });
      }
    }
    handleError(event, value);
  };

  const displayMrketplaces = () => {
    const result = [];

    if (formData?.dsp_marketplaces?.length) {
      for (const marketplace of formData?.dsp_marketplaces) {
        result.push({
          label: displayMarketplaceName(marketplace.name, 'dsp'),
          value: marketplace.name,
        });
      }
    }
    return result;
  };

  return (
    <>
      <label htmlFor={item.key}>DSP Marketplaces</label>
      <Select
        classNamePrefix="react-select"
        options={dspAdditionalMarketplaces}
        styles={{
          control: (base, state) => ({
            ...base,
            background:
              (!(formData && formData[item.key]) && item.isMandatory) ||
              !formData?.[item.key]?.length
                ? '#FBF2F2'
                : '#F4F6FC',
            // match with the menu
            // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
            // Overwrittes the different states of border
            borderColor:
              (!(formData && formData[item.key]) && item.isMandatory) ||
              !formData?.[item.key]?.length
                ? '#D63649'
                : '#D5D8E1',

            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
              // Overwrittes the different states of border
              boxShadow: state.isFocused ? null : null,
              outlineColor: state.isFocused ? null : null,
            },
          }),
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: '556178',
            };
          },
          option: (styles, { isDisabled }) => {
            return {
              ...styles,
              color: isDisabled ? '556178' : '556178',
            };
          },
        }}
        isMulti
        name="dsp_marketplaces"
        onChange={(event, value) => handleChange(event, value)}
        defaultValue={
          formData?.dsp_marketplaces?.length ? displayMrketplaces() : null
        }
        components={{ DropdownIndicator }}
        isClearable={false}
      />
    </>
  );
}

export default AdditionalDspMarketplaces;

AdditionalDspMarketplaces.defaultProps = {
  dspAdditionalMarketplaces: [],
  formData: {},
  item: {},
  showFooter: () => {},
  setFormData: () => {},
  setUpdatedFormData: () => {},
  updatedFormData: {},
  originalData: {},
  sectionError: {},
  setSectionError: () => {},
  contractAddendumDetails: {},
  addendumDetails: {},
  dspMarketpalcesError: {},
  setDspMarketpalcesError: () => {},
  displayMarketplaceName: () => {},
  // savedMarketplaces: [],
};
AdditionalDspMarketplaces.propTypes = {
  dspAdditionalMarketplaces: arrayOf(shape({})),
  formData: shape({}),
  item: shape({}),
  showFooter: func,
  setFormData: func,
  setUpdatedFormData: func,
  updatedFormData: shape({}),
  originalData: shape({}),
  sectionError: shape({}),
  setSectionError: func,
  contractAddendumDetails: shape({ notActive: bool }),
  addendumDetails: shape({}),
  dspMarketpalcesError: shape({}),
  setDspMarketpalcesError: func,
  displayMarketplaceName: func,
  //  savedMarketplaces: arrayOf(shape({})),
};
