import React, { useCallback, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { bool, string } from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';

import Theme from '../../../../../theme/Theme';
import useFormatNumber from '../../../../../hooks/useFormatNumber';
import { CloseIcon } from '../../../../../theme/images';
import { InvoiceRequestViewModal } from './InvoiceAdjustmentModals';
import {
  cancelInvoiceRequest,
  getInvoiceAdjustmentData,
} from '../../../../../api';
import {
  getInvoiceStatusKey,
  invoiceStatusIcons,
  InvoiceTypeNames,
  StatusColorSet,
} from '../../../../../constants';
import {
  PageLoader,
  Status,
  Table,
  NoData,
  TableMobileView,
  ModalBox,
  Button,
  CommonPagination,
} from '../../../../../common';

const getSymbolFromCurrency = require('currency-symbol-map');

const InvoiceAdjustmentList = ({
  customerId,
  isAllowToCreateAdjustment,
  invokeAdjustmentListApi,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const mounted = useRef(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [adjustmentDetails, setAdjustmentDetails] = useState();
  const [invoiceRequestDataCount, setInvoiceRequestDataCount] = useState();

  const [invoicesAdjustmentData, setInvoicesAdjustmentData] = useState();
  const [invoiceAdjustmentLoader, setInvoiceAdjustmentLoader] = useState(false);
  const [isCancelRequest, setIsCancelRequest] = useState(false);

  const [showViewInvoiceRequestModal, setShowViewInvoiceRequestModal] =
    useState(false);
  const [showInvoiceCancelRequestModal, setShowInvoiceCancelRequestModal] =
    useState(false);
  const [invoiceCancelRequestDetails, setInvoiceCancelRequestDetails] =
    useState();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      overlay: ' {zIndex: 1000}',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const getAdjustmentData = useCallback(
    (page) => {
      setInvoiceAdjustmentLoader(true);
      getInvoiceAdjustmentData(customerId, page).then((res) => {
        if (mounted.current) {
          if (
            (res && res.status === 500) ||
            res.status === 400 ||
            res.status === 404
          ) {
            setInvoiceAdjustmentLoader(false);
            setInvoicesAdjustmentData(null);
          }

          if (res && res.status === 200) {
            if (res.data && res.data.results) {
              setInvoiceRequestDataCount(res?.data.count);
              setInvoicesAdjustmentData(res?.data?.results);
            } else {
              setInvoicesAdjustmentData(null);
            }
            setInvoiceAdjustmentLoader(false);
          }
        }
      });
    },
    [customerId],
  );

  useEffect(() => {
    mounted.current = true;
    getAdjustmentData(1);
    return () => {
      mounted.current = false;
    };
  }, [getAdjustmentData]);

  useEffect(() => {
    if (invokeAdjustmentListApi) {
      getAdjustmentData(1);
    }
  }, [getAdjustmentData, invokeAdjustmentListApi]);

  const setAdjustmentsData = (adjustments) => {
    const result = [];
    if (adjustments?.length) {
      for (const item of adjustments) {
        result.push({
          id: item.id,
          pause_approved: false,
        });
      }
    }
    return result;
  };

  const onCancelRequest = useCallback(
    (requestDetails) => {
      setIsCancelRequest(true);
      const data = {};
      if (requestDetails?.is_sent_for_pause) {
        data.pause_approved = false;
        data.adjustments = setAdjustmentsData(requestDetails.adjustments);
      }
      data.budget_approved = false;
      data.status = 'cancelled';
      data.customer = customerId;
      cancelInvoiceRequest(requestDetails.id, data).then((res) => {
        if (mounted.current) {
          if (res && res.status === 200) {
            setShowInvoiceCancelRequestModal(false);
            toast.success('Invoice cancel request has been sent successfully.');
            getAdjustmentData(1);
            setPageNumber(1);
          } else {
            toast.error('Something went wrong');
          }
          setIsCancelRequest(false);
        }
      });
    },
    [customerId, getAdjustmentData],
  );

  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getAdjustmentData(currentPage);
  };

  const handleMobileViewORCancelRequest = (selectedColumn, item) => {
    if (selectedColumn === 'view') {
      setAdjustmentDetails(item);
      setShowViewInvoiceRequestModal(true);
    } else if (selectedColumn === 'cancel') {
      setInvoiceCancelRequestDetails(item);
      setShowInvoiceCancelRequestModal(true);
    }
  };

  const renderDateWithFormat = (
    dateType,
    fromDate,
    toDate,
    invoiceType = '',
  ) => {
    if (dateType === 'from') {
      if (invoiceType === 'one time' || invoiceType === 'Pause') {
        return dayjs(fromDate).format('MMMM');
      }
      if (toDate === 'Ongoing') {
        return dayjs(fromDate).format("MMMM 'YYYY");
      }
      return `${dayjs(fromDate).format("MMM 'YY")} -`;
    }
    if (dateType === 'to') {
      if (invoiceType === 'one time' || invoiceType === 'Pause') {
        return dayjs(toDate).format('YYYY');
      }
      if (toDate !== 'Ongoing') {
        return dayjs(toDate).format("MMM 'YY");
      }
    }
    return toDate;
  };

  const renderTableHeader = () => {
    return (
      <tr>
        <th width="31%" className="product-header">
          Type / Marketplace
        </th>
        <th width="17%" className="product-header">
          Month(s)
        </th>
        <th width="17%" className="product-header">
          Amount
        </th>
        <th width="15%" className="product-header">
          Status
        </th>
        <th width="20%" className="product-header text-right pr-3">
          {' '}
        </th>
      </tr>
    );
  };

  const renderTableData = (item, numberFormat) => {
    const status = item?.status ? item?.status : 'created';

    return (
      <tr className="product-body" key={item.id}>
        <td width="30%" className="small-label-text">
          <div className="type">
            {InvoiceTypeNames[item?.dsp_invoice_subtype.toLowerCase()]}
          </div>
          <div className="marketplace">{item?.marketplaces}</div>
        </td>
        <td width="20%" className="small-label-text">
          {renderDateWithFormat(
            'from',
            item.applicable_from,
            item.to_date,
            item?.dsp_invoice_subtype,
          )}
          <div
            className={
              item.to_date === 'Ongoing' ||
              item?.dsp_invoice_subtype === 'one time' ||
              item?.dsp_invoice_subtype === 'Pause'
                ? 'marketplace'
                : ''
            }
          >
            {renderDateWithFormat(
              'to',
              item.applicable_from,
              item.to_date,
              item?.dsp_invoice_subtype,
            )}
          </div>
        </td>
        <td width="15%" className="small-label-text">
          {numberFormat(
            item.to_amount === null ? 0 : item.to_amount,
            getSymbolFromCurrency(
              item?.global_currency ? item?.global_currency : 'USD',
            ),
          )}
        </td>
        <td width="15%" className="small-label-text">
          <Status
            label={status}
            backgroundColor={StatusColorSet[getInvoiceStatusKey(status)]}
            iconAfterLabel
            fontIcon={invoiceStatusIcons[getInvoiceStatusKey(status)]?.icon}
            labelColor={
              invoiceStatusIcons[getInvoiceStatusKey(status)]?.iconColor
            }
          />
        </td>
        <td width="20%" className="orange-text-label">
          <ul className="pending-status text-right">
            {status === 'pending' && isAllowToCreateAdjustment ? (
              <>
                <li>
                  <p
                    className="orange-text-label cursor"
                    role="presentation"
                    onClick={() => {
                      setShowInvoiceCancelRequestModal(true);
                      setInvoiceCancelRequestDetails(item);
                    }}
                  >
                    Cancel
                  </p>
                </li>
                <li className="divide-gap" />
              </>
            ) : null}

            <li>
              <p
                className="orange-text-label cursor"
                role="presentation"
                onClick={() => {
                  setAdjustmentDetails(item);
                  setShowViewInvoiceRequestModal(true);
                }}
              >
                View
              </p>
            </li>
          </ul>
        </td>
      </tr>
    );
  };

  const renderDesktopView = () => {
    return (
      <>
        {invoiceAdjustmentLoader ? (
          <PageLoader
            component="performance-graph"
            type="detail"
            color={Theme.orange}
            width={40}
            height={40}
          />
        ) : (
          <>
            <div className="straight-line horizontal-line spacing " />
            <Table className="mt-0">
              <thead>{renderTableHeader()}</thead>
              {invoicesAdjustmentData && invoicesAdjustmentData.length >= 1 ? (
                <tbody>
                  {invoicesAdjustmentData.map((item) =>
                    renderTableData(item, useFormatNumber),
                  )}
                </tbody>
              ) : null}
            </Table>

            {invoiceRequestDataCount > 0 ? (
              <>
                <div
                  className={
                    invoicesAdjustmentData.length < 9 &&
                    invoiceRequestDataCount < 10
                      ? ''
                      : 'straight-line horizontal-line mt-3'
                  }
                />
                <CommonPagination
                  count={invoiceRequestDataCount}
                  pageNumber={pageNumber}
                  handlePageChange={handlePageChange}
                />
              </>
            ) : null}

            {!invoicesAdjustmentData ||
            (invoicesAdjustmentData && invoicesAdjustmentData.length === 0) ? (
              <NoData>No Invoice Adjustments Found</NoData>
            ) : null}
          </>
        )}
      </>
    );
  };

  const renderMobileInvoiceRequestList = (numberFormat) => {
    return invoicesAdjustmentData.map((item) => {
      const status = item?.status ? item?.status : 'created';
      return (
        <>
          <TableMobileView
            key={item.id}
            className="mb-3"
            invoiceType={
              InvoiceTypeNames[item?.dsp_invoice_subtype.toLowerCase()]
            }
            invoiceId={null}
            marketplaces={item?.marketplaces}
            status={status}
            statusColor={StatusColorSet[getInvoiceStatusKey(status)]}
            statusIcon={invoiceStatusIcons[getInvoiceStatusKey(status)]?.icon}
            statusLabelColor={
              invoiceStatusIcons[getInvoiceStatusKey(status)]?.iconColor
            }
            iconAfterLabel
            label="Month(s)"
            labelInfo={renderDateWithFormat(
              'from',
              item.applicable_from,
              item.to_date,
              item?.dsp_invoice_subtype,
            )}
            sublabel={renderDateWithFormat(
              'to',
              item.applicable_from,
              item.to_date,
              item?.dsp_invoice_subtype,
            )}
            label1="Amount"
            labelInfo1={numberFormat(
              item.to_amount === null ? 0 : item.to_amount,
              getSymbolFromCurrency(
                item?.global_currency ? item?.global_currency : 'USD',
              ),
            )}
            label2=""
            labelInfo2="View"
            isColumnOnClick
            onColumnClick={(option) => {
              handleMobileViewORCancelRequest(option, item);
            }}
            isCancelRequest={status === 'pending' && isAllowToCreateAdjustment}
          />
        </>
      );
    });
  };

  const renderMobileView = () => {
    return (
      <>
        {invoiceAdjustmentLoader ? (
          <PageLoader
            component="performance-graph"
            type="detail"
            color={Theme.orange}
            width={40}
            height={40}
          />
        ) : invoicesAdjustmentData && invoicesAdjustmentData.length >= 1 ? (
          <>
            {renderMobileInvoiceRequestList(useFormatNumber)}
            <>
              <div
                className={
                  invoiceRequestDataCount < 10
                    ? ''
                    : 'straight-line horizontal-line mt-3'
                }
              />
              <CommonPagination
                count={invoiceRequestDataCount}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
              />
            </>
          </>
        ) : (
          <NoData>No Invoices Found</NoData>
        )}
      </>
    );
  };

  const renderCancelRequestModal = () => {
    return (
      <Modal
        id={customerId}
        isOpen={showInvoiceCancelRequestModal}
        style={{ ...customStyles }}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => setShowInvoiceCancelRequestModal(false)}
          role="presentation"
        />
        <ModalBox>
          <div className="modal-body">
            <div
              className="pb-3"
              style={{ display: 'flex', wordBreak: 'break-all' }}
            >
              <h4> Cancel Invoice Request</h4>
            </div>

            <div className="alert-msg ">
              <span>Are you sure, you want to cancel the invoice request?</span>
            </div>
            <div className="text-center ">
              <Button
                onClick={() => onCancelRequest(invoiceCancelRequestDetails)}
                type="button"
                className="btn-primary on-boarding  mr-2 pb-2 mb-1"
              >
                {isCancelRequest ? (
                  <PageLoader color="#fff" type="button" />
                ) : (
                  'Yes'
                )}
              </Button>
              <Button
                onClick={() => setShowInvoiceCancelRequestModal(false)}
                type="button"
                className=" btn-transparent w-50 on-boarding "
              >
                Don&apos;t Cancel
              </Button>
            </div>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  return (
    <Wrapper>
      {!isMobile ? renderDesktopView() : renderMobileView(useFormatNumber)}

      {showViewInvoiceRequestModal ? (
        <InvoiceRequestViewModal
          id="BT-viewAndReminderInvoiceModal"
          isOpen={showViewInvoiceRequestModal}
          adjustmentDetails={adjustmentDetails}
          onClick={() => {
            setShowViewInvoiceRequestModal(false);
          }}
          onApply={() => {
            setShowViewInvoiceRequestModal(false);
          }}
        />
      ) : null}

      {showInvoiceCancelRequestModal ? renderCancelRequestModal() : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
    </Wrapper>
  );
};

export default InvoiceAdjustmentList;

InvoiceAdjustmentList.defaultProps = {
  customerId: '',
  isAllowToCreateAdjustment: false,
  invokeAdjustmentListApi: bool,
};

InvoiceAdjustmentList.propTypes = {
  customerId: string,
  isAllowToCreateAdjustment: bool,
  invokeAdjustmentListApi: bool,
};

const Wrapper = styled.div`
  td {
    padding: 20px 10px 3px 0px !important;
  }
  .statusContainer {
    margin-top: 0px !important;
  }

  .pending-status {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }

      &.divide-gap {
        border-right: 1px solid #bfc5d2;
        width: 2px;
        height: 12px;
      }
    }
  }
`;
