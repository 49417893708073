import React from 'react';

import { arrayOf, shape } from 'prop-types';

import SummaryInventoryScoreGraph from './SummaryInventoryScoreGraph';
import { WhiteCard } from '../../../../../common';

const InventoryScore = ({ pieData, dspData }) => {
  return (
    <div className="col-md-3 col-sm-12 mb-3">
      <WhiteCard className="fix-height ">
        <p className="black-heading-title mt-0 mb-4">Inventory Score (IPI)</p>
        <SummaryInventoryScoreGraph
          chartId="inventory-score-graph"
          pieData={pieData}
        />
        <div className="average">
          {pieData?.length && !Number.isNaN(pieData[0].value)
            ? pieData[0].value
            : 'N/A'}
          <div className="out-off">Out of 1000</div>
        </div>
        <div className="last-update mt-3">
          Last updated: {dspData?.latest_date}
        </div>
      </WhiteCard>
    </div>
  );
};

export default InventoryScore;

InventoryScore.defaultProps = { dspData: null, pieData: [{}] };

InventoryScore.propTypes = {
  dspData: shape({}),
  pieData: arrayOf(shape({})),
};
