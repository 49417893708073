import React, { useCallback, useEffect, useState, useRef } from 'react';

import styled from 'styled-components';
import dayjs from 'dayjs';
import copy from 'copy-to-clipboard';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast, ToastContainer } from 'react-toastify';
import { arrayOf, bool, shape, string } from 'prop-types';

import Theme from '../../../../../theme/Theme';
import useFormatNumber from '../../../../../hooks/useFormatNumber';
import InvoiceBreakdownModal from '../../Modals/InvoiceBreakdownModal';
import InvoiceAdjustmentsContainer from './InvoiceAdjustmentsContainer';
import { getInvoiceData } from '../../../../../api';
import {
  StatusColorSet,
  InvoiceTypeNames,
  invoiceStatusIcons,
  getInvoiceStatusKey,
} from '../../../../../constants';
import {
  CommonPagination,
  PageLoader,
  Status,
  Table,
  WhiteCard,
  NoData,
  TableMobileView,
} from '../../../../../common';

const getSymbolFromCurrency = require('currency-symbol-map');

const InvoiceList = ({ loader, invoiceType, id, bpName, memberData }) => {
  const isDSPService = invoiceType === 'dsp service';
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [invoicesData, setInvoicesData] = useState();
  const [pastInvoiceLoader, setPastInvoiceLoader] = useState(false);
  const [isApicall, setIsApiCall] = useState(false);
  const [invoiceTotalCount, setInvoiceTotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [showInvoiceModal, setshowInvoiceModal] = useState({
    modal: false,
  });
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const mounted = useRef(false);
  const invoiceDataLength = invoicesData?.length;

  const getDSPInvoicesData = useCallback(
    (type, currentPage) => {
      setPastInvoiceLoader(true);
      getInvoiceData(type, id, currentPage).then((res) => {
        if (mounted.current) {
          if (
            res?.status === 500 ||
            res?.status === 400 ||
            res?.status === 404
          ) {
            setPastInvoiceLoader(false);
            setInvoicesData(null);
          }

          if (res?.status === 200) {
            if (res.data?.results) {
              setInvoicesData(res.data.results);
              setInvoiceTotalCount(res.data.count);
              setPageNumber(currentPage);
            } else {
              setInvoicesData(null);
              setInvoiceTotalCount(null);
              setPageNumber(1);
            }
            setPastInvoiceLoader(false);
          } else {
            setInvoicesData(null);
            setInvoiceTotalCount(null);
            setPageNumber(1);
          }
          setPastInvoiceLoader(false);
        }
      });
    },
    [id],
  );

  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getDSPInvoicesData(invoiceType, currentPage);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    mounted.current = true;

    if (!isApicall) {
      getDSPInvoicesData(invoiceType);
      setIsApiCall(true);
    }
    return () => {
      mounted.current = false;
    };
  }, [getDSPInvoicesData, invoiceType, isApicall, setIsApiCall]);

  const renderMobilePastInvoice = (numberFormat) => {
    return invoicesData.map((item) => {
      let sentInvoiceType = '';
      let invoiceMonth = '';
      if (item?.description?.budget_type !== null) {
        sentInvoiceType = item?.description?.budget_type;
      }
      if (item?.month !== null) {
        invoiceMonth = item?.month;
      }
      return (
        <TableMobileView
          key={item?.id}
          className="mb-3"
          invoiceType={
            isDSPService
              ? `${sentInvoiceType} (${invoiceMonth})`
              : item?.invoice_type
          }
          invoiceId={`#${item?.next_invoiced_id}`}
          marketplaces={
            isDSPService && item?.description?.marketplaces
              ? ` | ${item?.description?.marketplaces}`
              : null
          }
          statusLabelColor="#000"
          status={item?.invoice_status}
          statusIcon={
            invoiceStatusIcons[getInvoiceStatusKey(item?.invoice_status)]?.icon
          }
          statusColor={
            StatusColorSet[getInvoiceStatusKey(item?.invoice_status)]
          }
          iconAfterLabel
          label="Amount"
          labelInfo={`${getSymbolFromCurrency(
            item?.local_currency,
          )}${numberFormat(item?.monthly_budget)}`}
          label1="Created on"
          labelInfo1={dayjs(item?.generated_at).format('MM/DD/YY')}
          label2="Due"
          labelInfo2={dayjs(item?.due_date).format('MM/DD/YY')}
          isShowCopyIcon={
            item?.invoice_status !== 'void' &&
            item?.invoice_status !== 'paid' &&
            item?.payment_link !== null
          }
          copyText={item?.payment_link}
        />
      );
    });
  };

  const renderMobileView = () => {
    return (
      <>
        <WhiteCard className="mb-3">
          <div className="row">
            <div className="col-12">
              <div className="black-heading-title">
                {invoiceType === 'rev share'
                  ? 'Revenue Share Invoices'
                  : invoiceType === 'upsell'
                  ? 'Upsell Invoices'
                  : invoiceType === 'retainer'
                  ? 'Monthly Retainer Invoices'
                  : 'Sent Invoices'}
              </div>
            </div>
          </div>
        </WhiteCard>

        <div className="straight-line horizontal-line spacing d-md-block d-none " />
        {pastInvoiceLoader && isDSPService ? (
          <PageLoader
            component="performance-graph"
            type="detail"
            color={Theme.orange}
            width={40}
            height={40}
          />
        ) : invoiceDataLength >= 1 ? (
          <>
            {renderMobilePastInvoice(useFormatNumber)}

            <>
              <div
                className={
                  invoiceDataLength < 9 && invoiceTotalCount < 10
                    ? ''
                    : 'straight-line horizontal-line mt-3'
                }
              />
              <CommonPagination
                count={invoiceTotalCount}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
              />
            </>
          </>
        ) : (
          <NoData>
            No{' '}
            {invoiceType === 'rev share'
              ? 'Revenue Share Invoices'
              : invoiceType === 'upsell'
              ? 'Upsell Invoices'
              : invoiceType === 'retainer'
              ? 'Monthly Retainer Invoices'
              : 'DSP Invoices'}{' '}
            Found
          </NoData>
        )}
      </>
    );
  };

  const renderTableHeader = () => {
    return (
      <tr>
        <th width="31%" className="product-header">
          {isDSPService ? 'Type/ #' : 'Invoice Type / Number'}
        </th>
        <th width="17%" className="product-header pl-2">
          Amount
        </th>
        <th width="17%" className="product-header">
          Created On
        </th>
        <th width="15%" className="product-header">
          Due
        </th>
        <th width="20%" className="product-header text-right pr-3">
          Status
        </th>
      </tr>
    );
  };

  const renderPastInvoiceTableData = (item, numberFormat, index) => {
    const pastInvoicetype = item?.description?.budget_type;
    return (
      <>
        <tr
          key={item.id}
          onClick={() => {
            setshowInvoiceModal({
              modal: true,
              previous: invoicesData[index === 0 ? null : index - 1],
              next: invoicesData[
                index === invoiceDataLength - 1 ? null : index + 1
              ],
            });
            setInvoiceDetails(item);
          }}
          className="cursor"
        >
          <td className="product-body">
            <div className="company-name">
              {isDSPService
                ? `${
                    pastInvoicetype !== null
                      ? InvoiceTypeNames[pastInvoicetype]
                      : ''
                  } ${item?.month !== null ? `(${item?.month})` : ''}`
                : item?.invoice_type}
              {item?.invoice_status !== 'void' &&
              item?.invoice_status !== 'paid' &&
              item?.payment_link !== null ? (
                <FontAwesomeIcon
                  className="pl-2 cursor"
                  icon="fa-light fa-copy"
                  style={{ color: '#ff4817' }}
                  onClick={() => {
                    copy(item?.payment_link);
                    toast.success('copied');
                  }}
                />
              ) : null}
            </div>
            <div className="status">
              #{item?.next_invoiced_id}{' '}
              {isDSPService && item?.description?.marketplaces
                ? `| ${item?.description?.marketplaces}`
                : null}
            </div>
          </td>
          <td className="product-table-body text-medium pl-2">
            <div className="notification-bell pl-2">
              {getSymbolFromCurrency(item?.local_currency)}
              {numberFormat(item?.monthly_budget)}
            </div>
          </td>
          <td className="product-table-body light-font">
            {dayjs(item?.generated_at).format('MM/DD/YY')}
          </td>
          <td className="product-table-body light-font ">
            {dayjs(item?.due_date).format('MM/DD/YY')}
          </td>
          <td className="product-table-body text-right">
            <Status
              className="float-right"
              label={item?.invoice_status}
              backgroundColor={
                StatusColorSet[getInvoiceStatusKey(item?.invoice_status)]
              }
              iconAfterLabel
              fontIcon={
                invoiceStatusIcons[getInvoiceStatusKey(item?.invoice_status)]
                  ?.icon
              }
              labelColor={
                invoiceStatusIcons[getInvoiceStatusKey(item?.invoice_status)]
                  ?.iconColor
              }
            />
          </td>
        </tr>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <>
        <WhiteCard className="mb-3">
          <p style={{ marginTop: '0px' }} className="black-heading-title mb-4">
            {invoiceType === 'rev share'
              ? 'Revenue Share Invoices'
              : invoiceType === 'upsell'
              ? 'Upsell Invoices'
              : invoiceType === 'retainer'
              ? 'Monthly Retainer Invoices'
              : 'Sent Invoices'}
          </p>

          <div className="straight-line horizontal-line spacing d-md-block d-none " />
          {pastInvoiceLoader ? (
            <PageLoader
              component="performance-graph"
              type="detail"
              color={Theme.orange}
              width={40}
              height={40}
            />
          ) : (
            <>
              <Table className="mt-0">
                <thead>{renderTableHeader()}</thead>
                {invoiceDataLength >= 1 ? (
                  <tbody>
                    {invoicesData.map((item, index) =>
                      renderPastInvoiceTableData(item, useFormatNumber, index),
                    )}
                  </tbody>
                ) : null}
              </Table>

              {invoiceDataLength > 0 ? (
                <>
                  <div
                    className={
                      invoiceDataLength < 9 && invoiceTotalCount < 10
                        ? ''
                        : 'straight-line horizontal-line mt-3'
                    }
                  />
                  <CommonPagination
                    count={invoiceTotalCount}
                    pageNumber={pageNumber}
                    handlePageChange={handlePageChange}
                  />
                </>
              ) : null}
              {!invoicesData || invoiceDataLength === 0 ? (
                <NoData>
                  No{' '}
                  {invoiceType === 'rev share'
                    ? 'Revenue Share Invoices'
                    : invoiceType === 'upsell'
                    ? 'Upsell Invoices'
                    : invoiceType === 'retainer'
                    ? 'Monthly Retainer Invoices'
                    : 'DSP Invoices'}{' '}
                  Found
                </NoData>
              ) : null}
            </>
          )}
        </WhiteCard>
      </>
    );
  };

  return (
    <Wrapper>
      {loader ? (
        <PageLoader
          component="performance-graph"
          color={Theme.orange}
          type="detail"
          width={40}
          height={40}
        />
      ) : !isMobile ? (
        renderDesktopView()
      ) : (
        renderMobileView()
      )}
      {isDSPService ? (
        <InvoiceAdjustmentsContainer
          id={id}
          bpName={bpName}
          memberData={memberData}
        />
      ) : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      {showInvoiceModal.modal ? (
        <InvoiceBreakdownModal
          showInvoiceModal={showInvoiceModal}
          setshowInvoiceModal={setshowInvoiceModal}
          invoiceDetails={invoiceDetails}
          setInvoiceDetails={setInvoiceDetails}
          invoicesData={invoicesData}
        />
      ) : null}
    </Wrapper>
  );
};

export default InvoiceList;

InvoiceList.defaultProps = {
  invoiceType: 'rev share',
  id: '',
  bpName: '',
};

InvoiceList.propTypes = {
  loader: bool.isRequired,
  invoiceType: string,
  id: string,
  bpName: string,
  memberData: arrayOf(shape({})).isRequired,
};

const Wrapper = styled.div`
  td {
    padding: 20px 10px 3px 0px !important;
  }
  .statusContainer {
    margin-top: 0px !important;
  }
`;
