import React, { useEffect, useRef } from 'react';

// third party imports
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, func, shape, string, bool } from 'prop-types';

// tlocal file imports
import Theme from '../../../../../theme/Theme';
import { CheckBox } from '../../../../../common';

function MarketplaceDropdown({
  marketplaceOptions,
  handleChange,
  selectedMarketplaces,
  dropdownLabel,
  isMarketplaceDropdownOpen,
  setIsMarketplaceDropdownOpen,
}) {
  const customerData = useSelector((state) => state?.customerState?.data);
  const isPPC = customerData?.having_contract_type?.is_having_ppc;
  const isDSP = customerData?.having_contract_type?.is_having_dsp;
  const isDspOnlyBP = isDSP && !isPPC;
  function useOutsideAlerter(ref, type) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsMarketplaceDropdownOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, type]);
  }

  const revenueRef = useRef(null);
  useOutsideAlerter(revenueRef, 'revenue');

  return (
    <GoalFilterMarketPlace
      id="BT-RevenuBreakDownBP"
      ref={revenueRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="dropdown-goal-filter"
        role="presentation"
        onClick={() => {
          setIsMarketplaceDropdownOpen(!isMarketplaceDropdownOpen);
        }}
        id="clickbox"
      >
        <span className="selected-list"> {dropdownLabel}</span>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-down"
          className="dropdown-arrow-icon"
          style={{
            transform: isMarketplaceDropdownOpen ? 'rotate(180deg)' : '',
          }}
        />
      </div>

      {isMarketplaceDropdownOpen ? (
        <div
          className="dropdown-option-menu"
          style={{ cursor: 'initial' }}
          data-cy="BT-Goals-MarketplaceFilter-DropdownMenu"
        >
          <ul className="options-list">
            {marketplaceOptions.map((item) => {
              return (
                <li key={item?.uniqueId}>
                  <CheckBox className="mt-3 mb-3">
                    <label
                      className="check-container customer-pannel "
                      htmlFor={item?.uniqueId}
                    >
                      <input
                        type="checkbox"
                        id={item?.uniqueId}
                        name={item?.value}
                        value={item?.value}
                        checked={
                          selectedMarketplaces?.length &&
                          selectedMarketplaces.find(
                            (marketplace) =>
                              marketplace?.value === item?.value &&
                              marketplace?.accountType === item?.accountType,
                          )?.value
                        }
                        onChange={(event) => {
                          handleChange(event, item);
                        }}
                      />
                      <span className="checkmark" />
                      {item?.label}{' '}
                      {!isDspOnlyBP && item?.accountType ? (
                        <span style={{ fontSize: '12px' }}>
                          ({item.accountType})
                        </span>
                      ) : (
                        ''
                      )}
                    </label>
                  </CheckBox>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </GoalFilterMarketPlace>
  );
}

export default MarketplaceDropdown;

MarketplaceDropdown.defaultProps = {
  dropdownLabel: '',
  isMarketplaceDropdownOpen: false,
  marketplaceOptions: [],

  selectedMarketplaces: [],
  handleChange: () => {},
  setIsMarketplaceDropdownOpen: () => {},
};

MarketplaceDropdown.propTypes = {
  dropdownLabel: string,
  isMarketplaceDropdownOpen: bool,
  handleChange: func,
  setIsMarketplaceDropdownOpen: func,
  marketplaceOptions: arrayOf(shape({})),
  selectedMarketplaces: arrayOf(shape({})),
};

const GoalFilterMarketPlace = styled.div`
  .dropdown-goal-filter {
    border-radius: 6px;
    position: relative;
    border: 1px solid ${Theme.gray45};
    width: 100%;
    min-width: 93px;
    text-align: left;
    font-size: ${Theme.extraNormal};
    border-radius: 6px;
    min-height: 40px;
    color: ${Theme.gray90};
    font-size: 14px;
    padding: 11px 7px;
    cursor: pointer;
    span.selected-list {
      position: fixed;
      color: ${Theme.gray90};
      white-space: nowrap;
      background-color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 75%;
      margin-left: 4px;
      position: absolute;
    }
    .dropdown-arrow-icon {
      position: absolute;
      right: 9px;
      top: 13px;
    }
  }
  .dropdown-option-menu {
    background-color: ${Theme.white};
    border-radius: 8px;
    box-shadow: 0 5px 15px 0 rgba(68, 68, 79, 0.4);
    padding: 0 14px;
    position: absolute;
    z-index: 99999;
    top: 35px;
    color: ${Theme.gray90};
    text-align: left;
    top: 55px;
    width: -webkit-fill-available;
    overflow: auto;
    max-height: 300px;
    max-width: 200px;

    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }
    .options-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        display: block;
        padding: 0;
      }
    }
    @media only screen and (max-width: 776px) {
      width: 95%;
    }
  }
`;
