import React, { useState } from 'react';

import dayjs from 'dayjs';
import Select from 'react-select';
import { shape, func, arrayOf, bool } from 'prop-types';
import { CheckBox } from '../../../../common';
import { BorderLessDropDown } from './PlaybookStyle';
import { playbookSummaryMonthOptions } from '../../../../constants';
import PlaybookSummaryDeliverableCollapse from './PlaybookSummaryDeliverableCollapse';
import Theme from '../../../../theme/Theme';
import {
  renderCollapseDropdownIcon,
  handleMonthDropdown,
} from './CommonFunctions';

function PlaybookSummaryServiceCollapse({
  serviceData,
  handleChangeOfServiceCheckbox,
  updatedServicesOfPlaybookSummary,
  setUpdatedServicesOfPlaybookSummary,
  marketplacesList,
  playbookCreateAccess,
}) {
  const [showTaskCollapse, setShowTaskCollpase] = useState(false);
  const tableStyle = {
    borderTop: '1px solid #e0e6e8',
    borderBottom: 'none',
  };

  const setMonthDropdownValue = () => {
    const service = updatedServicesOfPlaybookSummary?.create?.find(
      (item) => item?.id === serviceData?.id,
    );

    return playbookSummaryMonthOptions?.find(
      (item) =>
        item?.value === (service ? service?.frequency : serviceData?.frequency),
    );
  };

  // render playbook task data collapse
  const renderPlaybookDeliverablesCollapse = () => {
    return serviceData?.deliverable?.length && showTaskCollapse ? (
      <>
        {serviceData?.deliverable?.map((item) => {
          return (
            <PlaybookSummaryDeliverableCollapse
              deliverableData={item}
              marketplacesList={marketplacesList}
              setMonthDropdownValue={setMonthDropdownValue}
            />
          );
        })}
      </>
    ) : null;
  };

  const displayMonthData = () => {
    return playbookCreateAccess ? (
      <BorderLessDropDown>
        <Select
          classNamePrefix="react-select"
          options={playbookSummaryMonthOptions}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
          placeholder="Select.."
          value={setMonthDropdownValue()}
          onChange={(event) =>
            handleMonthDropdown(
              event,
              serviceData,
              setUpdatedServicesOfPlaybookSummary,
              updatedServicesOfPlaybookSummary,
            )
          }
        />
      </BorderLessDropDown>
    ) : (
      serviceData?.frequency
    );
  };

  return (
    <>
      <tr>
        <td style={tableStyle} className="pt-0">
          <CheckBox>
            <label className="check-container mb-0 " htmlFor={serviceData?.id}>
              <input
                className="checkboxes"
                type="checkbox"
                id={serviceData?.id}
                onChange={(event) => {
                  handleChangeOfServiceCheckbox(
                    serviceData,
                    event?.target?.checked,
                  );
                }}
                checked={updatedServicesOfPlaybookSummary?.create?.find(
                  (item) => item?.id === serviceData?.id,
                )}
                defaultChecked={updatedServicesOfPlaybookSummary?.create?.find(
                  (item) => item?.id === serviceData?.id,
                )}
              />
              <span className="checkmark " />
            </label>
          </CheckBox>
        </td>
        <td
          style={tableStyle}
          className="pl-1"
          role="presentation"
          onClick={() => {
            setShowTaskCollpase(!showTaskCollapse);
          }}
        >
          {serviceData?.deliverable?.length
            ? renderCollapseDropdownIcon(showTaskCollapse)
            : null}
          <span className="pl-2 text-bold">{serviceData?.task_name} </span>
        </td>
        <td style={tableStyle}>
          {
            marketplacesList?.find(
              (marketplace) => marketplace?.value === serviceData?.marketplace,
            )?.label
          }
        </td>
        <td style={tableStyle}>{displayMonthData(serviceData)}</td>
        <td style={tableStyle}>
          {dayjs(serviceData?.start_date).format('MMM')}
        </td>
        <td style={tableStyle}>{serviceData?.team}</td>
      </tr>
      {renderPlaybookDeliverablesCollapse()}
    </>
  );
}

export default PlaybookSummaryServiceCollapse;

PlaybookSummaryServiceCollapse.defaultProps = {
  serviceData: {},
  marketplacesList: [],
  handleChangeOfServiceCheckbox: () => {},
  updatedServicesOfPlaybookSummary: {},
  playbookCreateAccess: false,
  setUpdatedServicesOfPlaybookSummary: () => {},
};

PlaybookSummaryServiceCollapse.propTypes = {
  serviceData: shape({}),
  playbookCreateAccess: bool,
  marketplacesList: arrayOf(shape({})),
  handleChangeOfServiceCheckbox: func,
  updatedServicesOfPlaybookSummary: shape({}),
  setUpdatedServicesOfPlaybookSummary: func,
};
