import React from 'react';

import { bool, func, number, shape, string } from 'prop-types';

import { WhiteCard } from '../../../../../../common';
import { ORDEREDREVENUE, SHIPPEDCOGS } from '../../../../../../constants';

const VendorASINPerformanceMobileView = ({
  index,
  metricsData,
  metricsType,
  formatNumber,
  currencySymbol,
  isAppliedCustom,
  customerState,
  displayProductNameWithTooltip,
}) => {
  let item = {};
  if (metricsType === ORDEREDREVENUE) {
    item = {
      currencyLabel: 'Ordered Revenue',
      currencyValue: metricsData?.current_ordered_revenue,
      currencyChange: metricsData?.ordered_revenue_change,
      currencyPercentChange: metricsData?.ordered_revenue_percent_change,

      unitLabel: 'Ordered Units',
      unitValue: metricsData?.current_ordered_unit,
      unitChange: metricsData?.ordered_units_change,
      unitPercentChange: metricsData?.ordered_units_percent_change,
    };
  } else if (metricsType === SHIPPEDCOGS) {
    item = {
      currencyLabel: 'Shipped COGS',
      currencyValue: metricsData?.current_shipped_cogs,
      currencyChange: metricsData?.shipped_cogs_change,
      currencyPercentChange: metricsData?.shipped_cogs_percent_change,

      unitLabel: 'Shipped Units',
      unitValue: metricsData?.current_shipped_unit,
      unitChange: metricsData?.shipped_unit_change,
      unitPercentChange: metricsData?.shipped_unit_percent_change,
    };
  }

  return (
    <WhiteCard className="card-with-border mb-3">
      <div className="row text-medium-font">
        <div className="col-6 mb-3">
          <div className="label">ASIN</div>
          <div className="normal-text-black ">{metricsData?.asin}</div>
          {customerState?.account_type === 'parent' ? (
            <div className="text-extra-small text-medium-font">
              {metricsData?.bp_name}
            </div>
          ) : null}
        </div>

        <div className="col-6 mb-3">
          <div className="label">Product Name</div>
          <div className="normal-text-black">
            {displayProductNameWithTooltip(metricsData?.product_name, index)}
          </div>
        </div>

        <div className="col-6 mb-3">
          <div className="label">{item?.currencyLabel}</div>
          <div className="normal-text-black ">
            {item?.currencyValue === null
              ? 'N/A'
              : formatNumber(
                  item?.currencyValue,
                  isAppliedCustom ? null : item?.currencyChange,
                  isAppliedCustom ? null : item?.currencyPercentChange,
                  2,
                  currencySymbol,
                  '',
                  'small-font mt-1',
                )}
          </div>
        </div>

        <div className="col-6 mb-3">
          <div className="label">Glance Views</div>
          <div className="normal-text-black ">
            {metricsData?.current_glance_view === null
              ? 'N/A'
              : formatNumber(
                  metricsData?.current_glance_view,
                  isAppliedCustom ? null : metricsData?.glance_views_change,
                  isAppliedCustom
                    ? null
                    : metricsData?.glance_views_percent_change,
                  0,
                  '',
                  '',
                  'small-font mt-1',
                )}
          </div>
        </div>

        <div className="col-6 mb-3">
          <div className="label">{item?.unitLabel}</div>
          <div className="normal-text-black ">
            {item?.unitValue === null
              ? 'N/A'
              : formatNumber(
                  item?.unitValue,
                  isAppliedCustom ? null : item?.unitChange,
                  isAppliedCustom ? null : item?.unitPercentChange,
                  0,
                  '',
                  '',
                  'small-font mt-1',
                )}
          </div>
        </div>
        <div className="col-6 mb-3">
          <div className="label">Conversion Rate</div>
          <div className="normal-text-black ">
            {metricsData?.current_conversion === null
              ? 'N/A'
              : formatNumber(
                  metricsData?.current_conversion,
                  isAppliedCustom ? null : metricsData?.conversion_change,
                  isAppliedCustom
                    ? null
                    : metricsData?.conversion_percent_change,
                  2,
                  '',
                  '%',
                  'small-font mt-1',
                )}
          </div>
        </div>
      </div>
    </WhiteCard>
  );
};

export default VendorASINPerformanceMobileView;

VendorASINPerformanceMobileView.defaultProps = {
  formatNumber: () => {},
  asin: '',
  metricsType: '',
  index: '',
  currencySymbol: null,
  isAppliedCustom: false,
  customerState: {},
  metricsData: {},
  displayProductNameWithTooltip: () => {},
};

VendorASINPerformanceMobileView.propTypes = {
  index: number,
  metricsType: string,
  formatNumber: func,
  asin: string,
  currencySymbol: string,
  isAppliedCustom: bool,
  customerState: shape({}),
  metricsData: shape({}),
  displayProductNameWithTooltip: func,
};
