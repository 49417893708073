// react imports
import React from 'react';

// third party imports
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { bool, func, shape, string } from 'prop-types';

// local file imports
import Theme from '../../../../theme/Theme';
import { TabletViewManager } from '../../../../theme/Global';
import {
  PATH_CUSTOMER_DETAILS,
  noGraphDataMessage,
  contributionColorSet,
  dspPacingTableHeader,
  dspPacingKeyTabOptions,
  dspPacingMetricsConstants,
} from '../../../../constants';
import {
  PageLoader,
  Status,
  Table,
  WhiteCard,
  ToggleButton,
  NoData,
  BrandPartnerProfile,
} from '../../../../common';

const DSPPacing = ({
  loader,
  currencySymbol,
  month,
  selectedOption,
  dspPacingData,
  handleSpendingOptions,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const history = useHistory();
  const dspPacingAllData = dspPacingData?.dsp_pacing_all[0];
  const dspPacingTableData = dspPacingData?.dsp_pacing;

  const isBBEInternalUser = useSelector(
    (state) => state?.userState?.userInfo?.isInternalUser,
  );
  const isBBEInternalUserStatus = isBBEInternalUser ? 'internal' : 'external';

  const returnFormatedNumber = (value, type) => {
    const decimalDigits = 2;
    if (value) {
      return `${value < 0 ? '-' : ''}${
        type === 'currency' ? currencySymbol : ''
      }${value
        .toFixed(decimalDigits)
        .replace('-', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
        type === 'percentage' ? '%' : ''
      }`;
    }
    return `${currencySymbol}0`;
  };

  // redirect to customer details view
  const redirectToCutomerDetailsView = (itemData) => {
    history.push(
      `${PATH_CUSTOMER_DETAILS.replace(
        ':id',
        itemData?.customer_id,
      )}?tab=performance&nav=dspPerformance&access=${isBBEInternalUserStatus}`,
      'dspPerformance',
    );
  };

  // display Brad Patner Profile
  const displayBrandPartnerProfile = (itemData) => {
    return (
      <BrandPartnerProfile
        bpName={itemData?.customer_name}
        assignedUser={itemData?.ad_manager}
        logoURL={itemData?.document}
      />
    );
  };

  // display dsp pacing mobile view
  const renderDspPacingTabletView = (dspData) => {
    return (
      <TabletViewManager className="d-lg-none d-md-block d-sm-block">
        <div className="container-fluid">
          <div className="row cursor">
            {dspData?.map((itemData) => (
              <div
                className="col-md-6 col-12 mt-4"
                role="presentation"
                key={itemData?.customer_id}
                onClick={() => redirectToCutomerDetailsView(itemData)}
              >
                {displayBrandPartnerProfile(itemData)}
                <div className="straight-line horizontal-line pt-3 mb-3 " />
                <div className="row">
                  <div className="col-6 pb-3">
                    <div className="label">PLANNED</div>
                    <div className="label-info">
                      {returnFormatedNumber(
                        itemData?.planned_spend_to_date,
                        'currency',
                      )}
                    </div>
                  </div>
                  <div className="col-6 pb-3">
                    <div className="label">ACTUAL</div>
                    <div className="label-info">
                      {returnFormatedNumber(
                        itemData?.actual_spend_to_date,
                        'currency',
                      )}
                    </div>
                  </div>
                  <div className="col-6 pb-3">
                    <div className="label">DIFFERENCE</div>
                    <div className="label-info">
                      <div className="decrease-rate large">
                        {returnFormatedNumber(
                          Math.abs(itemData?.change_to_date),
                          'currency',
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-6 pb-3">
                    <div className="label">REMAINING</div>
                    <div className="label-info">
                      {returnFormatedNumber(
                        itemData?.remaining_to_date,
                        'currency',
                      )}
                    </div>
                  </div>
                  <div className="col-6 pb-3">
                    <div className="label">CONTRIBUTION</div>
                    <Status
                      label={itemData?.contribution_bracket}
                      backgroundColor={
                        contributionColorSet[itemData?.contribution_bracket]
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </TabletViewManager>
    );
  };

  // display dsp pacing table head
  const renderDspPacingTableHeader = () => {
    const tableHeader = dspPacingTableHeader?.slice();
    const spendLabel =
      selectedOption === 'overspending' ? 'OVERSPEND' : 'UNDERSPEND';
    const spendObj = {
      label: spendLabel,
      key: spendLabel,
      width: '15%',
    };

    tableHeader.splice(3, 0, spendObj);

    return (
      <tr>
        {tableHeader?.map((item) => {
          return (
            <th width={item?.width} key={item?.key} className="product-header">
              {item?.label}
            </th>
          );
        })}
      </tr>
    );
  };

  // display dsp pacing table body
  const renderDspPacingTableData = (itemData) => {
    return (
      <tr
        key={itemData?.customer_id}
        className="cursor"
        onClick={() => {
          redirectToCutomerDetailsView(itemData);
        }}
      >
        <td className="product-body">{displayBrandPartnerProfile(itemData)}</td>
        <td className="product-body">
          {returnFormatedNumber(itemData?.planned_spend_to_date, 'currency')}
        </td>
        <td className="product-body">
          {returnFormatedNumber(itemData?.actual_spend_to_date, 'currency')}
        </td>
        <td className="product-body">
          <div className="decrease-rate large">
            {returnFormatedNumber(
              Math.abs(itemData?.change_to_date),
              'currency',
            )}
          </div>
        </td>
        <td className="product-body">
          <div className="decrease-rate large">
            {returnFormatedNumber(itemData?.remaining_to_date, 'currency')}
          </div>
        </td>
        <td className="product-body">
          <Status
            label={itemData?.contribution_bracket}
            backgroundColor={
              contributionColorSet[itemData?.contribution_bracket]
            }
          />
        </td>
      </tr>
    );
  };

  // display dsp pacing desktop view
  const renderDesktopDSPPacingData = (dspData) => {
    return (
      <Table className="mt-0 d-md-none d-sm-none d-lg-block">
        <thead>{renderDspPacingTableHeader()}</thead>
        {dspData ? (
          <tbody>{dspData.map((item) => renderDspPacingTableData(item))}</tbody>
        ) : (
          <NoData>{noGraphDataMessage}</NoData>
        )}
      </Table>
    );
  };

  // display underspending and overspending toggle buttons
  const renderDspPacingOptions = () => {
    return (
      <ToggleButton>
        <div className="days-container spending">
          <ul className={loader ? 'days-tab disabled' : 'days-tab'}>
            {dspPacingKeyTabOptions?.map((item) => (
              <li key={item.id}>
                <input
                  className="d-none"
                  type="radio"
                  id={item?.id}
                  name="flexRadioDefault2"
                  value={selectedOption}
                  checked={item?.id === selectedOption}
                  onClick={() => handleSpendingOptions(item?.id)}
                  onChange={() => {}}
                />
                <label htmlFor={item?.id}>{item?.label}</label>
              </li>
            ))}
          </ul>
        </div>
      </ToggleButton>
    );
  };

  // display Dsp Pacing desktop and mobile view
  const displayDspPacingData = () => {
    return loader ? (
      <PageLoader
        component="performance-graph"
        color={Theme.orange}
        type="detail"
        width={40}
        height={40}
      />
    ) : dspPacingTableData?.length > 0 ? (
      isDesktop ? (
        renderDesktopDSPPacingData(dspPacingTableData)
      ) : (
        renderDspPacingTabletView(dspPacingTableData)
      )
    ) : (
      <NoData>{noGraphDataMessage}</NoData>
    );
  };

  // display monthly budget pacing
  const displayMonthlyBudgetPacingHTML = () => {
    return (
      <p className="gray-normal-text mb-2 mt-1">
        Monthly Budget Pacing ({month}):
        <span style={{ cursor: 'auto' }} className="orange-text">
          <span style={{ textTransform: 'capitalize' }}>
            {dspPacingAllData?.dsp_pacing_flag_all === 1
              ? 'Overspending'
              : dspPacingAllData?.dsp_pacing_flag_all === -1
              ? 'Underspending'
              : 'N/A'}
          </span>
        </span>
      </p>
    );
  };

  // display dsp pacing metrics data
  const renderDspPacingMetricsHTML = (pacingMetrics) => {
    const metricsName =
      pacingMetrics?.key === 'changeSpend'
        ? `${
            dspPacingAllData?.dsp_pacing_flag_all === 1
              ? 'Overspend'
              : 'Underspend'
          } to date`
        : pacingMetrics?.label;
    const metricsValue = dspPacingAllData?.[pacingMetrics?.metricsKey];
    const percentValue = dspPacingAllData?.[pacingMetrics?.metricsPercentKey];
    const type = pacingMetrics?.type;
    const rangeClass = pacingMetrics?.rangeClass;
    const showPercentValue = pacingMetrics?.showPercentValue;

    return (
      <>
        <div className="label">{metricsName}</div>
        <div className={`label-range ${rangeClass}`}>
          {loader ? (
            'N/A'
          ) : metricsValue ? (
            <>
              {returnFormatedNumber(
                pacingMetrics?.key === 'remainingBudget'
                  ? metricsValue
                  : Math.abs(metricsValue),
                type,
              )}{' '}
              {showPercentValue
                ? percentValue === 0
                  ? '(0%)'
                  : percentValue
                  ? `(${returnFormatedNumber(percentValue, 'percentage')})`
                  : '(N/A)'
                : ''}
            </>
          ) : (
            'N/A'
          )}
        </div>
      </>
    );
  };

  // display dsp pacing metrics section
  const displayDspPacingMetricsSection = () => {
    return (
      <ul className="dsp-spent-date">
        <li id="BT-dsp-planned_spend_to_date" className="mb-3 mb-md-0">
          {renderDspPacingMetricsHTML(dspPacingMetricsConstants?.plannedSpend)}
        </li>
        <li id="BT-dsp-actual_spend_to_date" className="mb-3 mb-md-0">
          {renderDspPacingMetricsHTML(dspPacingMetricsConstants?.actualSpend)}
        </li>
        <li id="BT-dsp-change_to_date">
          {renderDspPacingMetricsHTML(dspPacingMetricsConstants?.changeSpend)}
        </li>
        <li id="BT-dsp-remaining_budget">
          {renderDspPacingMetricsHTML(
            dspPacingMetricsConstants?.remainingBudget,
          )}
        </li>
      </ul>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <Wrapper id="BT-dsp-pacing">
      <WhiteCard className="mb-3">
        <div className="row">
          <div className="col-md-8 col-sm-12 pr-0 mb-3">
            <p className="black-heading-title mt-2 mb-0">DSP Pacing</p>
            {displayMonthlyBudgetPacingHTML()}
          </div>
          <div className="col-md-4 col-sm-12 pl-0 mt-2 mb-3">
            {renderDspPacingOptions()}
          </div>
          <div className="straight-line horizontal-line mb-3" />
          <div className="col-md-12 col-sm-12 pr-0 mb-3">
            {displayDspPacingMetricsSection()}
          </div>
          <div className="straight-line horizontal-line " />
        </div>
        {displayDspPacingData()}
      </WhiteCard>
    </Wrapper>
  );
};

export default DSPPacing;

DSPPacing.defaultProps = {
  loader: false,
  currencySymbol: '$',
  handleSpendingOptions: () => {},
  selectedOption: 'overSpending',
  dspPacingData: {},
};

DSPPacing.propTypes = {
  loader: bool,
  currencySymbol: string,
  selectedOption: string,
  month: string.isRequired,
  dspPacingData: shape({}),
  handleSpendingOptions: func,
};

const Wrapper = styled.div`
  .statusContainer {
    margin-top: 0px !important;
    padding: 5px 30px !important;
  }
`;
