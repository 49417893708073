import React, { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import Theme from '../../../../../../theme/Theme';
import useValueWithChangeAndPercent from '../../../../../../hooks/useValueWithChangeAndPercent';
import {
  asinStatiMessages,
  sponsoredAsinTableConstant,
} from '../../../../../../constants';
import {
  getSpAdCampaignDownloadReport,
  getSponsoredAdCampaignAsinData,
  getSponsoredAdCampaignData,
} from '../../../../../../api';
import {
  CommonPagination,
  DesktopTable,
  GrayMessageBar,
  NoData,
  PageLoader,
  VisibleScrollableTable,
} from '../../../../../../common';

function SponsoredCampaignReport({
  isCallSPAsinApi,
  campaignDataLoader,
  downloadReportLoader,
  campaignAsinDataLoader,
  orderBy,
  orderByKey,
  customerId,
  childBrandId,
  selectedDF,
  accountType,
  currencySymbol,
  campaignSearchQuery,
  selectedCampaignSortBy,
  selectedMarketplace,
  sponsoredAdType,
  adState,
  setOrderBy,
  setOrderByKey,
  setIsCallSPAsinApi,
  setCampaignDataLoader,
  setIsAsinDataAvailable,
  setDownloadReportLoader,
  setCampaignAsinDataLoader,
  displaySponsoredAsinTable,
  bindSpAsinPerformanceData,
}) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isCustom = selectedDF === 'custom';
  const [pageCount, setPageCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [asinPageCount, setAsinPageCount] = useState(1);
  const [asinPageNumber, setAsinPageNumber] = useState(1);
  const [spCampaignCollapseData, setSpCampaignCollapseData] = useState([]);
  const [spCampaignAsinData, setSpCampaignAsinData] = useState({
    campaignAsinData: [],
    campaignAsinTotal: {},
  });
  const [selectedCampaignCollapse, setSelectedCampaignCollapse] = useState({});

  // bind sponsored campaign asin total response data
  const bindSpCampaignAsinTotal = useCallback((responseData) => {
    let tempCampaignAsinTotal = {};
    if (responseData) {
      tempCampaignAsinTotal = {
        asin: 'Total',
        adSpendCurrentTotal: responseData?.current?.ad_spend,
        adSalesCurrentTotal: responseData?.current?.ad_sales,
        acosCurrentTotal: responseData?.current?.acos,
        roasCurrentTotal: responseData?.current?.roas,
        ctrCurrentTotal: responseData?.current?.ctr,
        adConversionCurrentTotal: responseData?.current?.ad_conversion,
        clicksCurrentTotal: responseData?.current?.clicks,
        costPerClickCurrentTotal: responseData?.current?.cost_per_click,
        salesPerClickCurrentTotal: responseData?.current?.sales_per_click,
        impressionsCurrentTotal: responseData?.current?.impressions,

        adSpendChangeInValueTotal: responseData?.change?.ad_spend,
        adSalesChangeInValueTotal: responseData?.change?.ad_sales,
        acosChangeInValueTotal: responseData?.change?.acos,
        roasChangeInValueTotal: responseData?.change?.roas,
        ctrChangeInValueTotal: responseData?.change?.ctr,
        adConversionChangeInValueTotal: responseData?.change?.ad_conversion,
        clicksChangeInValueTotal: responseData?.change?.clicks,
        costPerClickChangeInValueTotal: responseData?.change?.cost_per_click,
        salesPerClickChangeInValueTotal: responseData?.change?.sales_per_click,
        impressionsChangeInValueTotal: responseData?.change?.impressions,

        adSpendChangeInPercentTotal: responseData?.per_change?.ad_spend,
        adSalesChangeInPercentTotal: responseData?.per_change?.ad_sales,
        acosChangeInPercentTotal: responseData?.per_change?.acos,
        roasChangeInPercentTotal: responseData?.per_change?.roas,
        ctrChangeInPercentTotal: responseData?.per_change?.ctr,
        adConversionChangeInPercentTotal:
          responseData?.per_change?.ad_conversion,
        clicksChangeInPercentTotal: responseData?.per_change?.clicks,
        costPerClickChangeInPercentTotal:
          responseData?.per_change?.cost_per_click,
        salesPerClickChangeInPercentTotal:
          responseData?.per_change?.sales_per_click,
        impressionsChangeInPercentTotal: responseData?.per_change?.impressions,
      };
    }

    return tempCampaignAsinTotal;
  }, []);

  // callback function for get sponsored campaign asin performance data
  const getSponsoredCampaignAsinData = useCallback(
    (
      campaignId,
      campaignStatus,
      asinCount,
      marketplaceId,
      orderByApiKey,
      page,
      startDate,
      endDate,
    ) => {
      setCampaignAsinDataLoader(true);

      setSpCampaignAsinData({
        campaignAsinData: [],
        campaignAsinTotal: {},
      });

      const startDateLocal = dayjs(startDate).format('DD-MM-YYYY');
      const endDateLocal = dayjs(endDate).format('DD-MM-YYYY');

      getSponsoredAdCampaignAsinData(
        customerId,
        accountType,
        sponsoredAdType,
        campaignId,
        campaignStatus,
        asinCount,
        marketplaceId,
        orderByApiKey,
        page,
        selectedDF,
        startDateLocal,
        endDateLocal,
        childBrandId,
      ).then((response) => {
        if (response?.status === 200) {
          setAsinPageCount(response?.data?.count);

          setSpCampaignAsinData({
            campaignAsinData: bindSpAsinPerformanceData(
              response?.data?.results,
            ),
            campaignAsinTotal: bindSpCampaignAsinTotal(response?.data?.total),
            adsReportType: response?.data?.ads_report_type,
          });
        } else {
          setSpCampaignAsinData({
            campaignAsinData: [],
            campaignAsinTotal: {},
          });
        }

        setCampaignAsinDataLoader(false);
      });
    },
    [
      accountType,
      bindSpAsinPerformanceData,
      bindSpCampaignAsinTotal,
      childBrandId,
      customerId,
      selectedDF,
      setCampaignAsinDataLoader,
      sponsoredAdType,
    ],
  );

  // bind sponsored campaign response data
  const bindSponsoredCampaignData = useCallback((responseData) => {
    const tempCampaignData = [];
    if (responseData?.length > 0) {
      responseData.forEach((dataItem, index) => {
        tempCampaignData.push({
          marketplace: dataItem?.marketplace,
          marketplaceId: dataItem?.marketplace_id,
          campaignStatus: dataItem?.campaign_status,
          campaignName: dataItem?.campaign_name,
          campaignId: dataItem?.campaign_id,
          asiCount: dataItem?.asin_count,
          dataIndex: index,
        });
      });
    }
    return tempCampaignData;
  }, []);

  // callback function for get sponsored campaign data
  const getSponsoredCampaignCollapseData = useCallback(
    (orderByApiKey, page, startDate, endDate) => {
      setCampaignDataLoader(true);
      setSpCampaignCollapseData([]);
      setSelectedCampaignCollapse({});

      const startDateLocal = dayjs(startDate).format('DD-MM-YYYY');
      const endDateLocal = dayjs(endDate).format('DD-MM-YYYY');

      getSponsoredAdCampaignData(
        customerId,
        accountType,
        selectedMarketplace,
        sponsoredAdType,
        selectedDF,
        selectedCampaignSortBy?.value,
        campaignSearchQuery,
        orderByApiKey,
        page,
        startDateLocal,
        endDateLocal,
        childBrandId,
      ).then((response) => {
        if (response?.status === 200) {
          setPageCount(response?.data?.count);
          setAsinPageNumber(1);

          if (response?.data?.results?.length > 0) {
            const formattedResponseData = bindSponsoredCampaignData(
              response?.data?.results,
            );
            setSpCampaignCollapseData(formattedResponseData);

            const dataItem = response?.data?.results?.[0];
            setSelectedCampaignCollapse(formattedResponseData?.[0]);
            getSponsoredCampaignAsinData(
              dataItem?.campaign_id,
              dataItem?.campaign_status,
              dataItem?.asin_count,
              dataItem?.marketplace_id,
              orderByApiKey,
              1,
              startDate,
              endDate,
            );
            setIsAsinDataAvailable(true);
          } else {
            setIsAsinDataAvailable(false);
            setSpCampaignAsinData({
              campaignAsinData: [],
              campaignAsinTotal: {},
            });
          }
        } else {
          setPageCount(0);
          setAsinPageNumber(1);
          setSpCampaignCollapseData([]);
          setIsAsinDataAvailable(false);
        }
        setCampaignDataLoader(false);
      });
    },
    [
      accountType,
      bindSponsoredCampaignData,
      campaignSearchQuery,
      childBrandId,
      customerId,
      getSponsoredCampaignAsinData,
      selectedCampaignSortBy,
      selectedDF,
      selectedMarketplace,
      setCampaignDataLoader,
      setIsAsinDataAvailable,
      sponsoredAdType,
    ],
  );

  // use effect
  useEffect(() => {
    if (!isCallSPAsinApi) {
      getSponsoredCampaignCollapseData(
        orderByKey,
        1,
        adState?.[0]?.startDate,
        adState?.[0]?.endDate,
      );
    }
    setPageNumber(1);
    setIsCallSPAsinApi(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSponsoredCampaignCollapseData, isCallSPAsinApi]);

  // const handle download report events
  const handleDownloadReportEvents = useCallback(
    (
      selectedAccountType,
      marketplace,
      spAdType,
      timeframe,
      campaignStatus,
      searchQuery,
      orderByApiKey,
      selectedChildBrand,
      startDate,
      endDate,
    ) => {
      const startDateLocal = dayjs(startDate).format('DD-MM-YYYY');
      const endDateLocal = dayjs(endDate).format('DD-MM-YYYY');

      getSpAdCampaignDownloadReport(
        customerId,
        selectedAccountType,
        marketplace,
        spAdType,
        timeframe,
        campaignStatus,
        searchQuery,
        orderByApiKey,
        startDateLocal,
        endDateLocal,
        selectedChildBrand,
        true,
      ).then((response) => {
        if (response?.status === 200) {
          const csvLink = response?.data?.url;
          if (csvLink) window.open(csvLink);
          if (response?.data?.message) toast.success(response?.data?.message);
        } else {
          toast.error('Oops! Something went wrong...');
        }
        setDownloadReportLoader(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDownloadReportLoader],
  );

  useEffect(() => {
    if (downloadReportLoader) {
      handleDownloadReportEvents(
        accountType,
        selectedMarketplace,
        sponsoredAdType,
        selectedDF,
        selectedCampaignSortBy?.value,
        campaignSearchQuery,
        orderByKey,
        childBrandId,
        adState?.[0]?.startDate,
        adState?.[0]?.endDate,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportLoader, handleDownloadReportEvents, selectedDF]);

  // handle sorting events of asin table
  const handleSortingEvents = (key) => {
    let orderByApiKey = key;
    setOrderBy(key);
    if (orderByKey === `-${key}`) {
      setOrderByKey(key);
      orderByApiKey = key;
    } else {
      setOrderByKey(`-${key}`);
      orderByApiKey = `-${key}`;
    }
    getSponsoredCampaignAsinData(
      selectedCampaignCollapse?.campaignId,
      selectedCampaignCollapse?.campaignStatus,
      selectedCampaignCollapse?.asiCount,
      selectedCampaignCollapse?.marketplaceId,
      orderByApiKey,
      asinPageNumber,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
    );
  };

  // handle campaign collapse events
  const handleCampaignCollapseEvents = (dataItem) => {
    if (selectedCampaignCollapse?.dataIndex !== dataItem?.dataIndex) {
      setSelectedCampaignCollapse(dataItem);
      getSponsoredCampaignAsinData(
        dataItem?.campaignId,
        dataItem?.campaignStatus,
        dataItem?.asiCount,
        dataItem?.marketplaceId,
        orderByKey,
        asinPageNumber,
        adState?.[0]?.startDate,
        adState?.[0]?.endDate,
      );
    } else {
      setSelectedCampaignCollapse();
    }
  };

  // handle collapse pagination events
  const handleCollapsePaginationEvents = (currentPage) => {
    setPageNumber(currentPage);
    getSponsoredCampaignCollapseData(
      orderByKey,
      currentPage,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
    );
  };

  // handle asin pagination events
  const handleAsinPaginationEvents = (currentPage) => {
    setAsinPageNumber(currentPage);
    getSponsoredCampaignAsinData(
      selectedCampaignCollapse?.campaignId,
      selectedCampaignCollapse?.campaignStatus,
      selectedCampaignCollapse?.asiCount,
      selectedCampaignCollapse?.marketplaceId,
      orderByKey,
      currentPage,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
    );
  };

  // display page loader
  const displayPageLoader = () => {
    return (
      <PageLoader
        component="performance-graph"
        color={Theme.orange}
        type="detail"
        width={40}
        height={40}
      />
    );
  };

  // display static campaign asin messages inside campaign table
  const displayStaticAsinMessageInsideTable = (type, tableDivId) => {
    return (
      <tr>
        <td
          colSpan="11"
          style={{
            padding: '0',
          }}
        >
          <div
            style={{
              width: document.getElementById(tableDivId)?.offsetWidth,
              position: 'sticky',
              left: '0',
            }}
          >
            <GrayMessageBar
              className="my-3"
              message={asinStatiMessages?.[type]}
            />
          </div>
        </td>
      </tr>
    );
  };

  // display sortable up and down arrow icon
  const displaySortableUpDownArrow = (key) => {
    return (
      <FontAwesomeIcon
        className="ml-2 cursor"
        color={orderBy === key ? Theme.orange : Theme.gray40}
        icon={`far ${
          orderByKey === `-${key}` ? 'fa-chevron-up' : 'fa-chevron-down'
        }`}
        style={{
          fontSize: '12px',
          verticalAlign: isTablet ? 'bottom' : 'middle',
        }}
      />
    );
  };

  // display sponsored campaign collapse Icon
  const displaySpCampaignCollapseIcon = (dataIndex) => {
    return (
      <FontAwesomeIcon
        icon={`${
          selectedCampaignCollapse?.dataIndex === dataIndex
            ? 'fa-regular fa-minus'
            : 'fa-regular fa-plus'
        }`}
        style={{
          color: Theme.orange,
          fontSize: Theme.small,
          position: 'relative',
        }}
      />
    );
  };

  // display sponsored campaign collapse panel
  const displaySpCampaignCollapsePanel = (numberFormatter) => {
    const tableDivId = 'BT-sp-campaign-table';
    const tableMinWidth = '1900px';
    return (
      <VisibleScrollableTable id={tableDivId} tableMinWidth={tableMinWidth}>
        <DesktopTable
          className="metrics-table"
          style={{ tableLayout: 'fixed' }}
        >
          <thead>
            <tr
              className={
                campaignDataLoader || campaignAsinDataLoader ? 'disabled' : ''
              }
            >
              {sponsoredAsinTableConstant.slice(0, 12)?.map((item) => {
                return (
                  <th
                    className={`${item?.isSortable ? 'cursor' : ''} ${
                      item?.className
                    }`}
                    key={item?.key}
                    onClick={() => {
                      if (item?.isSortable) handleSortingEvents(item?.key);
                    }}
                    style={{ width: item?.width }}
                  >
                    {item?.label}
                    {item?.isSortable
                      ? displaySortableUpDownArrow(item?.key)
                      : null}
                  </th>
                );
              })}
            </tr>
          </thead>

          {spCampaignCollapseData?.map((dataItem) => {
            const asinTotal = spCampaignAsinData?.campaignAsinTotal;
            const asinData = spCampaignAsinData?.campaignAsinData;

            return (
              <>
                <tbody>
                  <tr
                    className={`bg-orange-light cursor ${
                      campaignAsinDataLoader ? 'disabled' : ''
                    }`}
                    role="presentation"
                    onClick={() => {
                      handleCampaignCollapseEvents(dataItem);
                    }}
                  >
                    <td colSpan="6" className="sticky-collapse py-2">
                      <span className="mr-3">
                        {displaySpCampaignCollapseIcon(dataItem?.dataIndex)}
                      </span>
                      {dataItem?.campaignStatus !== null
                        ? `${dataItem?.campaignStatus}${' '}`
                        : ''}
                      {dataItem?.marketplace !== null
                        ? `| ${dataItem?.marketplace}${' '}`
                        : ''}
                      {dataItem?.campaignName !== null
                        ? `| ${dataItem?.campaignName}${' '}`
                        : ''}
                      {dataItem?.asiCount !== null
                        ? `| ${dataItem?.asiCount} `
                        : ''}
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                  {dataItem?.dataIndex ===
                  selectedCampaignCollapse?.dataIndex ? (
                    <>
                      {campaignAsinDataLoader ? (
                        <tr>
                          <td colSpan="12">
                            <div
                              style={{
                                width:
                                  document.getElementById(tableDivId)
                                    ?.offsetWidth,
                                position: 'sticky',
                                left: '0',
                              }}
                            >
                              {displayPageLoader()}
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {asinData?.length > 0
                            ? asinData?.map((item, index) => {
                                return displaySponsoredAsinTable(
                                  item,
                                  index,
                                  numberFormatter,
                                );
                              })
                            : null}
                          {/* Display static user message for SP Brand + Video and SP Television campaign */}
                          {asinData?.length === 0 &&
                          spCampaignAsinData?.adsReportType ===
                            'brand_campaign_report'
                            ? displayStaticAsinMessageInsideTable(
                                'brand_campaign_report',
                                tableDivId,
                              )
                            : null}
                          {asinData?.length === 0 &&
                          spCampaignAsinData?.adsReportType ===
                            'television_campaign_report'
                            ? displayStaticAsinMessageInsideTable(
                                'television_campaign_report',
                                tableDivId,
                              )
                            : null}
                          {Object.keys(asinTotal)?.length > 0 ? (
                            <tr key="total" className="bg-green-light">
                              <td
                                className="sticky-column text-bold border-right-gray bg-green-light"
                                style={{ textTransform: 'uppercase' }}
                              >
                                {asinTotal?.asin}
                              </td>
                              <td className="bg-green-light" />
                              <td className="bg-green-light">
                                {numberFormatter(
                                  asinTotal?.adSpendCurrentTotal,
                                  asinTotal?.adSpendChangeInValueTotal,
                                  asinTotal?.adSpendChangeInPercentTotal,
                                  2,
                                  isCustom,
                                  currencySymbol,
                                  '',
                                )}
                              </td>
                              <td className="bg-green-light">
                                {numberFormatter(
                                  asinTotal?.adSalesCurrentTotal,
                                  asinTotal?.adSalesChangeInValueTotal,
                                  asinTotal?.adSalesChangeInPercentTotal,
                                  2,
                                  isCustom,
                                  currencySymbol,
                                  '',
                                )}
                              </td>
                              <td className="bg-green-light">
                                {numberFormatter(
                                  asinTotal?.acosCurrentTotal,
                                  asinTotal?.acosChangeInValueTotal,
                                  asinTotal?.acosChangeInPercentTotal,
                                  2,
                                  isCustom,
                                  '',
                                  '%',
                                )}
                              </td>
                              <td className="bg-green-light">
                                {numberFormatter(
                                  asinTotal?.roasCurrentTotal,
                                  asinTotal?.roasChangeInValueTotal,
                                  asinTotal?.roasChangeInPercentTotal,
                                  2,
                                  isCustom,
                                  currencySymbol,
                                  '',
                                )}
                              </td>
                              <td className="bg-green-light">
                                {numberFormatter(
                                  asinTotal?.ctrCurrentTotal,
                                  asinTotal?.ctrChangeInValueTotal,
                                  asinTotal?.ctrChangeInPercentTotal,
                                  2,
                                  isCustom,
                                  '',
                                  '%',
                                )}
                              </td>
                              <td className="bg-green-light">
                                {numberFormatter(
                                  asinTotal?.adConversionCurrentTotal,
                                  asinTotal?.adConversionChangeInValueTotal,
                                  asinTotal?.adConversionChangeInPercentTotal,
                                  2,
                                  isCustom,
                                  '',
                                  '%',
                                )}
                              </td>
                              <td className="bg-green-light">
                                {numberFormatter(
                                  asinTotal?.costPerClickCurrentTotal,
                                  asinTotal?.costPerClickChangeInValueTotal,
                                  asinTotal?.costPerClickChangeInPercentTotal,
                                  2,
                                  isCustom,
                                  currencySymbol,
                                  '',
                                )}
                              </td>
                              <td className="bg-green-light">
                                {numberFormatter(
                                  asinTotal?.salesPerClickCurrentTotal,
                                  asinTotal?.salesPerClickChangeInValueTotal,
                                  asinTotal?.salesPerClickChangeInPercentTotal,
                                  2,
                                  isCustom,
                                  currencySymbol,
                                  '',
                                )}
                              </td>
                              <td className="bg-green-light">
                                {numberFormatter(
                                  asinTotal?.clicksCurrentTotal,
                                  asinTotal?.clicksChangeInValueTotal,
                                  asinTotal?.clicksChangeInPercentTotal,
                                  0,
                                  isCustom,
                                  '',
                                  '',
                                )}
                              </td>
                              <td className="bg-green-light">
                                {numberFormatter(
                                  asinTotal?.impressionsCurrentTotal,
                                  asinTotal?.impressionsChangeInValueTotal,
                                  asinTotal?.impressionsChangeInPercentTotal,
                                  0,
                                  isCustom,
                                  '',
                                  '',
                                )}
                              </td>
                            </tr>
                          ) : null}
                          {asinData?.length === 0 &&
                          Object.keys(asinTotal)?.length === 0 &&
                          (spCampaignAsinData?.adsReportType !==
                            'brand_campaign_report' ||
                            spCampaignAsinData?.adsReportType !==
                              'television_campaign_report') ? (
                            <tr>
                              <td
                                colSpan="12"
                                style={{
                                  padding: '0',
                                }}
                              >
                                <div
                                  style={{
                                    width:
                                      document.getElementById(tableDivId)
                                        ?.offsetWidth,
                                    position: 'sticky',
                                    left: '0',
                                  }}
                                >
                                  <NoData>
                                    {
                                      asinStatiMessages?.asinDataAvailabilityMessage
                                    }
                                  </NoData>
                                </div>
                              </td>
                            </tr>
                          ) : null}
                          {!campaignAsinDataLoader && asinPageCount > 10 ? (
                            <tr>
                              <td
                                colSpan="12"
                                style={{
                                  padding: '0',
                                }}
                              >
                                <div
                                  style={{
                                    width:
                                      document.getElementById(tableDivId)
                                        ?.offsetWidth,
                                    position: 'sticky',
                                    left: '0',
                                  }}
                                >
                                  <CommonPagination
                                    count={asinPageCount}
                                    pageNumber={asinPageNumber}
                                    handlePageChange={
                                      handleAsinPaginationEvents
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : null}
                        </>
                      )}
                    </>
                  ) : null}
                  <tr style={{ height: '15px' }} />
                </tbody>
              </>
            );
          })}
        </DesktopTable>
      </VisibleScrollableTable>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      {campaignDataLoader ? (
        displayPageLoader()
      ) : spCampaignCollapseData?.length > 0 ? (
        <>
          {displaySpCampaignCollapsePanel(useValueWithChangeAndPercent)}
          {!campaignDataLoader && pageCount > 10 ? (
            <div className={campaignAsinDataLoader ? 'disabled' : ''}>
              <CommonPagination
                count={pageCount}
                pageNumber={pageNumber}
                handlePageChange={handleCollapsePaginationEvents}
              />
            </div>
          ) : null}
        </>
      ) : (
        <NoData>{asinStatiMessages?.campaignDataAvailabilityMessage}</NoData>
      )}
    </div>
  );
}

export default SponsoredCampaignReport;

SponsoredCampaignReport.defaultProps = {
  isCallSPAsinApi: false,
  campaignDataLoader: false,
  downloadReportLoader: false,
  campaignAsinDataLoader: false,
  orderBy: 'ad_sales',
  orderByKey: '-ad_sales',
  childBrandId: null,
  selectedDF: '',
  accountType: '',
  currencySymbol: '$',
  selectedMarketplace: 'all',
  sponsoredAdType: 'all',
  campaignSearchQuery: '',
  selectedCampaignSortBy: {},
  adState: [],
  setOrderBy: () => {},
  setOrderByKey: () => {},
  setIsCallSPAsinApi: () => {},
  setCampaignDataLoader: () => {},
  setIsAsinDataAvailable: () => {},
  setDownloadReportLoader: () => {},
  setCampaignAsinDataLoader: () => {},
  displaySponsoredAsinTable: () => {},
  bindSpAsinPerformanceData: () => {},
};

SponsoredCampaignReport.propTypes = {
  isCallSPAsinApi: bool,
  campaignDataLoader: bool,
  downloadReportLoader: bool,
  campaignAsinDataLoader: bool,
  orderBy: string,
  orderByKey: string,
  childBrandId: string,
  selectedDF: string,
  accountType: string,
  currencySymbol: string,
  selectedMarketplace: string,
  sponsoredAdType: string,
  customerId: string.isRequired,
  campaignSearchQuery: string,
  selectedCampaignSortBy: shape({}),
  adState: arrayOf(shape()),
  setOrderBy: func,
  setOrderByKey: func,
  setIsCallSPAsinApi: func,
  setCampaignDataLoader: func,
  setIsAsinDataAvailable: func,
  setDownloadReportLoader: func,
  setCampaignAsinDataLoader: func,
  displaySponsoredAsinTable: func,
  bindSpAsinPerformanceData: func,
};
