import React from 'react';

import DatePicker from 'react-date-picker';
import ReactTooltip from 'react-tooltip';
import { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import Theme from '../../../../../../theme/Theme';
import DSPAudienceMobileView from './DSPAudienceMobileView';
import { TaskStatus } from '../../../Playbook/PlaybookStyle';
import { TableWrapper } from '../../../GoalsAndCOS/GoalsStyle';
import {
  DSPAudienceHeaders,
  audienceExpansionTypeOptions,
  refreshRateDaysOptions,
} from '../../../../../../constants';
import {
  TrashIcons,
  InfoRedIcon,
  InfoIcons,
} from '../../../../../../theme/images';
import {
  DesktopTable,
  DropdownIndicator,
  InputFormField,
  SingleSelectDropdown,
  ToggleSwitch,
} from '../../../../../../common';

function DSPAudienceTable({
  showAMCAudienceLookalike,
  QueryOptions,
  getSelectDropdownIndicator,
  isEditFlagOn,
  dspAudienceData,
  newDSPAudienceData,
  setNewDSPAudienceData,
  dspAudienceDefaultData,
}) {
  const isDesktop = useMediaQuery({ minWidth: 767 });
  const { Option } = components;

  // https://bbe.atlassian.net/browse/PDV-9060
  // Adding new column - Audience Expansion Type in dsp audience table header
  const indexofRefreshRate = DSPAudienceHeaders?.findIndex(
    (item) => item?.key === 'refresh_rate_days',
  );

  let newDspAudienceHeaders = [...DSPAudienceHeaders];
  if (showAMCAudienceLookalike) {
    newDspAudienceHeaders = DSPAudienceHeaders?.toSpliced(
      indexofRefreshRate + 1,
      0,
      {
        label: 'Audience Expansion Type',
        type: 'choice',
        key: 'expansion_type',
      },
    );
  }

  const handleDropdownChange = (event, key) => {
    setNewDSPAudienceData({ ...newDSPAudienceData, [key]: event?.value });
  };

  // get dropdown options for expansion type dropdwon
  const expansionTypeDropdwonOptions = (props) => {
    return (
      <Option {...props}>
        <div>
          <span style={{ fontSize: '14px' }}>
            {props?.data?.label}
            {props?.data?.tooltipInfo ? (
              <>
                <FontAwesomeIcon
                  icon="fal fa-info-circle"
                  color={props?.isSelected ? Theme.white : Theme.black}
                  fontSize="12px"
                  style={{ marginLeft: '10px' }}
                  data-tip={`<div style='max-width:300px'>${props?.data?.tooltipInfo}</div>`}
                  data-for={props?.data?.value}
                />
                <ReactTooltip
                  class="initialTextTransform"
                  id={props?.data?.value}
                  aria-haspopup="true"
                  place="right"
                  effect="solid"
                  html
                />
              </>
            ) : null}
          </span>
        </div>
      </Option>
    );
  };

  // get dropdown components for audience expansion type filter
  const getDropdownComponentsForExpansionType = () => {
    return { Option: expansionTypeDropdwonOptions, DropdownIndicator };
  };

  const displayQueryDropdown = (audienceHeader, headerIndex) => {
    // https://bbe.atlassian.net/browse/PDV-9060
    // get modified dropdown options for refresh rate days from index 7 to 21 when user clicked on AMCAudienceLookalike toggle button
    let modifiedRefreshRateDaysOptions = [...refreshRateDaysOptions];
    if (showAMCAudienceLookalike) {
      modifiedRefreshRateDaysOptions = refreshRateDaysOptions.slice(6);
    }
    const options =
      audienceHeader?.key === 'query'
        ? QueryOptions
        : modifiedRefreshRateDaysOptions;

    return audienceHeader?.key === 'expansion_type' ? (
      <SingleSelectDropdown
        id={`${headerIndex}`}
        placeholder={`Select `}
        filterId={`${headerIndex}`}
        isApiCall={false}
        dropdownOptions={audienceExpansionTypeOptions}
        selectedValue={
          audienceExpansionTypeOptions?.find(
            (item) => item?.value === newDSPAudienceData?.[audienceHeader?.key],
          ) || {}
        }
        onChangeHandler={(event) =>
          handleDropdownChange(event, audienceHeader?.key)
        }
        dropdownComponents={getDropdownComponentsForExpansionType}
        isSearchable={false}
      />
    ) : (
      <SingleSelectDropdown
        id={`${headerIndex}`}
        placeholder={`Select `}
        filterId={`${headerIndex}`}
        className={
          !newDSPAudienceData?.refresh_time_window &&
          audienceHeader?.key === 'refresh_rate_days'
            ? 'disabled'
            : ''
        }
        isApiCall={false}
        dropdownOptions={options}
        selectedValue={
          options?.find(
            (option) =>
              option.value === newDSPAudienceData?.[audienceHeader?.key],
          )
            ? options?.find(
                (option) =>
                  option.value === newDSPAudienceData?.[audienceHeader?.key],
              )
            : !newDSPAudienceData?.refresh_time_window &&
              audienceHeader?.key === 'refresh_rate_days'
            ? [{ label: '0', value: '0' }]
            : [{}]
        }
        onChangeHandler={(event) =>
          handleDropdownChange(event, audienceHeader?.key)
        }
        dropdownComponents={getSelectDropdownIndicator}
        isSearchable={false}
      />
    );
  };

  // render progress label className
  const renderProgressLabelClassName = (status) => {
    let className = '';
    switch (status) {
      case 'started':
        className = 'progress-label gray-label';
        break;

      case 'running':
        className = 'progress-label orange-label';
        break;

      case 'pending':
        className = 'progress-label yellow-label';
        break;

      case 'failed':
        className = 'progress-label red-label';
        break;
      case 'successful':
        className = 'progress-label green-label';
        break;

      default:
        break;
    }
    return className;
  };

  const displayStatus = (dspAudienceItem, rowIndex) => {
    return (
      <TaskStatus
        className={`${renderProgressLabelClassName(dspAudienceItem?.status)} `}
        role="presentation"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div>
          {dspAudienceItem?.status || '-'}
          {dspAudienceItem?.status === 'failed' ? (
            <>
              {' '}
              <img
                className="ml-1 cursor"
                style={{
                  verticalAlign: 'text-bottom',
                  fontWeight: '300',
                }}
                width="14px"
                src={InfoRedIcon}
                alt="info"
                data-tip={dspAudienceItem?.execution_description}
                data-for={`${dspAudienceItem?.status}${rowIndex}`}
              />{' '}
              <ReactTooltip
                id={`${dspAudienceItem?.status}${rowIndex}`}
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />{' '}
            </>
          ) : (
            ''
          )}
        </div>
      </TaskStatus>
    );
  };

  const handleRadioBtn = (event, key) => {
    if (event?.target?.checked) {
      setNewDSPAudienceData({
        ...newDSPAudienceData,
        [key]: event?.target?.checked,
        refresh_rate_days: 21,
      });
    } else {
      setNewDSPAudienceData({
        ...newDSPAudienceData,
        [key]: event?.target?.checked,
        refresh_rate_days: 0,
      });
    }
  };

  const displayToggleOfRefreshTimeWindow = (audienceHeader, headerIndex) => {
    return (
      <div>
        <ToggleSwitch className="custom-switch marginTop10 ml-4">
          <label className={`label-name px-0 `} htmlFor={`${headerIndex}`}>
            <span
              className={`ml-n4 ${
                newDSPAudienceData?.refresh_time_window ? 'disabled' : ''
              } `}
            >
              {' '}
              False
            </span>
            <input
              type="checkbox"
              className="label-name"
              id={`${headerIndex}`}
              onChange={(event) => {
                handleRadioBtn(event, audienceHeader?.key);
              }}
              defaultChecked={newDSPAudienceData?.refresh_time_window}
            />
            <span className="slider round ml-2" />
            <span
              className={`ml-4 pl-1 ${
                newDSPAudienceData?.refresh_time_window ? '' : 'disabled'
              }`}
            >
              {' '}
              True
            </span>
          </label>
        </ToggleSwitch>
      </div>
    );
  };

  const handleInputChange = (event) => {
    setNewDSPAudienceData({
      ...newDSPAudienceData,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const handleDateChange = (event, key) => {
    setNewDSPAudienceData({
      ...newDSPAudienceData,
      [key]: event,
    });
  };

  const setMinDate = (key) => {
    const d = new Date();
    if (key === 'time_window_start') {
      d.setFullYear(d.getFullYear() - 1);
      return d;
    }
    if (key === 'time_window_end') {
      if (newDSPAudienceData?.time_window_start) {
        return newDSPAudienceData?.time_window_start;
      }
    }
    return null;
  };

  const setMaxDate = (key) => {
    if (key === 'time_window_start') {
      if (newDSPAudienceData?.time_window_end) {
        return newDSPAudienceData?.time_window_end;
      }
    }
    const d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  };

  const displayInputFields = (audienceHeader, headerIndex) => {
    switch (audienceHeader?.type) {
      case 'choice':
        return displayQueryDropdown(audienceHeader, headerIndex);
      case 'radio':
        return displayToggleOfRefreshTimeWindow(audienceHeader, headerIndex);
      case 'status':
        return '-';
      case 'date':
        return audienceHeader?.key === 'created_at' ? (
          '-'
        ) : (
          <InputFormField backgroundTransparent="transparent">
            <DatePicker
              className="form-control modal-input-control mt-0 openCalendar"
              placeholderText="Select Date"
              value={newDSPAudienceData?.[audienceHeader?.key]}
              format="MM/dd/yyyy"
              maxDate={setMaxDate(audienceHeader?.key)}
              minDate={setMinDate(audienceHeader?.key)}
              clearIcon={null}
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              onChange={(date) => {
                handleDateChange(date, audienceHeader?.key);
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </InputFormField>
        );

      default:
        return (
          <div className="dsp-audience-inputbox">
            {audienceHeader?.key === 'advertiser_id' ? (
              <span className="dsp-audienece-trash-icon">
                <img
                  src={TrashIcons}
                  alt="delete"
                  width="20px"
                  height="20px"
                  className="trash cursor"
                  onClick={() => setNewDSPAudienceData({})}
                  role="presentation"
                />{' '}
              </span>
            ) : (
              ''
            )}
            {audienceHeader?.autoPopulatedField ? (
              <span
                style={
                  audienceHeader?.key === 'advertiser_id'
                    ? { marginTop: '3px' }
                    : {}
                }
              >
                {newDSPAudienceData?.[audienceHeader?.key]}
              </span>
            ) : (
              <InputFormField backgroundTransparent="transparent">
                <label htmlFor="dsp-audience">
                  <input
                    className="form-control modal-input-control mt-0"
                    type="text"
                    placeholder={`Enter ${audienceHeader?.label}`}
                    maxLength={audienceHeader?.charLimit}
                    name={`${audienceHeader?.key}`}
                    defaultValue={newDSPAudienceData?.[audienceHeader?.key]}
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                  />
                </label>
              </InputFormField>
            )}
          </div>
        );
    }
  };

  const getMSTTime = (value) => {
    const dateSplitterarray = value?.split('T');
    const timeSplitterarray = dateSplitterarray[1]?.split('-');
    let timeValue = timeSplitterarray?.[0];
    if (timeSplitterarray?.[0]?.includes('.')) {
      timeValue = timeSplitterarray?.[0]?.split('.')?.[0];
    }
    return `${dateSplitterarray?.[0]} T ${timeValue}`;
  };

  const displayValue = (dspAudienceItem, audienceHeader) => {
    if (audienceHeader?.key === 'status') {
      return displayStatus(dspAudienceItem);
    }

    if (audienceHeader?.key === 'instance_id') {
      return dspAudienceDefaultData?.instance_id;
    }
    if (audienceHeader?.key === 'advertiser_id') {
      return dspAudienceDefaultData?.advertiser_id;
    }

    if (audienceHeader?.key === 'query') {
      return dspAudienceItem?.query_detail?.name;
    }

    if (audienceHeader?.key === 'refresh_time_window') {
      return dspAudienceItem?.refresh_time_window === true ? 'True' : 'False';
    }

    if (
      audienceHeader?.key === 'created_at' ||
      audienceHeader?.key === 'time_window_start' ||
      audienceHeader?.key === 'time_window_end'
    ) {
      return (
        <>
          <div>{getMSTTime(dspAudienceItem?.[audienceHeader?.key])}</div>
          {audienceHeader?.key === 'created_at' ? (
            <div className="dsp-audience-createdBy-user">
              <FontAwesomeIcon
                icon="fa-light fa-file-contract"
                className="add-icon ml-2 mr-2"
              />
              created by {dspAudienceItem?.created_by_details?.first_name}{' '}
            </div>
          ) : (
            ''
          )}
        </>
      );
    }

    return <>{dspAudienceItem?.[audienceHeader?.key]}</>;
  };

  const displayTableData = (dspAudienceItem) => {
    return (
      <tr>
        {newDspAudienceHeaders?.map((audienceHeader, headerIndex) => {
          return (
            <td
              className={
                headerIndex === 0
                  ? 'rh'
                  : headerIndex === newDspAudienceHeaders?.length - 1
                  ? 'last-column'
                  : ''
              }
              width="200px"
            >
              {displayValue(dspAudienceItem, audienceHeader)}
            </td>
          );
        })}
      </tr>
    );
  };

  const displayNewlyAddedRow = () => {
    return (
      <tr>
        {newDspAudienceHeaders?.map((audienceHeader, headerIndex) => {
          return (
            <td
              className={
                headerIndex === 0
                  ? 'rh'
                  : headerIndex === newDspAudienceHeaders?.length - 1
                  ? 'last-column'
                  : ''
              }
              width="200px"
            >
              {displayInputFields(audienceHeader, headerIndex)}
            </td>
          );
        })}
      </tr>
    );
  };

  const displayTable = () => {
    return (
      <div className="modal-body">
        <TableWrapper>
          {/* <div style={{ overflow: 'auto' }}> */}
          <DesktopTable
            className="dsp-audience-table"
            style={{ tableLayout: 'fixed' }}
          >
            <thead id="new-dsp-row">
              <tr id="sticky-header" className="gray-border-radius">
                {newDspAudienceHeaders?.map((item, i) => {
                  return (
                    <th
                      width="200px"
                      className={
                        i === 0
                          ? 'th-sticky'
                          : i === newDspAudienceHeaders?.length - 1
                          ? 'last-header'
                          : ''
                      }
                    >
                      {item?.label}
                      {item?.info ? (
                        <>
                          {' '}
                          <img
                            className="ml-1 cursor"
                            style={{
                              verticalAlign: 'text-bottom',
                              fontWeight: '300',
                            }}
                            width="12px"
                            src={InfoIcons}
                            alt="info"
                            data-tip={`<div style="width:300px">${item?.info}</div>`}
                            data-for={`${item?.label}`}
                          />{' '}
                          <ReactTooltip
                            id={`${item?.label}`}
                            aria-haspopup="true"
                            place="top"
                            effect="solid"
                            html
                          />{' '}
                        </>
                      ) : (
                        ''
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {isEditFlagOn && Object.keys(newDSPAudienceData)?.length
                ? displayNewlyAddedRow()
                : ''}
              {dspAudienceData?.map((dspAudienceItem) => {
                return displayTableData(dspAudienceItem);
              })}
            </tbody>
          </DesktopTable>
          {/* </div> */}
        </TableWrapper>
      </div>
    );
  };

  return isDesktop ? (
    <div>{displayTable()}</div>
  ) : (
    <DSPAudienceMobileView
      dspAudienceData={dspAudienceData}
      isEditFlagOn={isEditFlagOn}
      displayInputFields={displayInputFields}
      DSPAudienceHeaders={newDspAudienceHeaders}
      displayValue={displayValue}
      newDSPAudienceData={newDSPAudienceData}
    />
  );
}

export default DSPAudienceTable;

DSPAudienceTable.defaultProps = {
  isSelected: false,
  showAMCAudienceLookalike: false,
  data: shape({}),
  QueryOptions: [],
  getSelectDropdownIndicator: () => {},
  isEditFlagOn: false,
  dspAudienceData: [],
  newDSPAudienceData: {},
  setNewDSPAudienceData: () => {},
  dspAudienceDefaultData: {},
};
DSPAudienceTable.propTypes = {
  isSelected: bool,
  showAMCAudienceLookalike: bool,
  data: shape({
    label: string,
  }),
  QueryOptions: arrayOf(shape()),
  getSelectDropdownIndicator: func,
  isEditFlagOn: bool,
  dspAudienceData: arrayOf(shape()),
  newDSPAudienceData: shape({}),
  setNewDSPAudienceData: func,
  dspAudienceDefaultData: shape({}),
};
