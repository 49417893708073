/* ********** React imports ********** */
import React from 'react';

/* ********** Third party package imports ********** */
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { components } from 'react-select';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* ********** Local component file imports ********** */
import Theme from '../../../../theme/Theme';
import SalesFilter from './SalesFilter';
import SalesSellerReporting from './SellerReporting/SalesSellerReporting';
import SalesVendorReporting from './VendorReporting/SalesVendorReporting';
import {
  SalesPerformanceContainerDefaultProps,
  SalesPerformanceContainerPropTypes,
} from './SalesPropTypes';
import {
  CommonPagination,
  CustomDateModal,
  CustomMonthRangeModal,
  DropdownIndicator,
  GrayMessageBar,
  PageLoader,
  SingleSelectDropdown,
  ToggleButton,
} from '../../../../common';
import {
  CUSTOM,
  REVENUEDSP,
  REVENUEPPC,
  SELLERSMALL,
  VENDORSMALL,
  YEAROVERYEAR,
  dateOptionsWithYearOverYear,
  performanceInsightMessage,
  vendorSalesMetricsTypeOptions,
} from '../../../../constants';

// accessing lodash library
const _ = require('lodash');

/* ********** MAIN FUNCTION START HERE ********** */
function SalesPerformanceContainer({
  // string
  salesGroupBy,
  currencySymbol,
  customStartDate,
  selectedTabMetrics,
  selectedRevenueType,
  selectedAdSpendType,
  // number
  pageNumber,
  contributionCount,
  // boolean
  isBGSAdmin,
  isBGSManager,
  isAdTeamManager,
  isSpreetailRole,
  isAdManagerAdmin,
  salesGraphLoader,
  isCustomDateApply,
  contributionLoader,
  isDisabledTimeFrame,
  downloadReportLoader,
  showCustomMonthModal,
  showAdCustomDateModal,
  showAdYearOverYearDateModal,
  // object
  currentDate,
  customDateError,
  selectedSalesDF,
  salesDifference,
  isMetricsDisabled,
  salesCurrentTotal,
  salesPreviousTotal,
  selectedMarketplace,
  currentDateForVendor,
  selectedSalesMetrics,
  selectedBrandPartner,
  selectedAccountType,
  selectedVendorMetrics,
  vendorSalesDifference,
  vendorSalesCurrentTotal,
  vendorSalesPreviousTotal,
  selectedVendorMetricsType,
  selectedRevenueDropDownOption,
  selectedAdSpendDropDownOption,
  // array
  userList,
  selectedUser,
  salesChartData,
  managerUserList,
  customDateState,
  customMonthRange,
  contributionData,
  marketplaceOptions,
  selectedManagerUser,
  brandPartnerOptions,
  goalsPerformanceData,
  yearOverYearDateState,
  vendorSalesMetricsData,
  // function
  applyCustomDate,
  setSelectedUser,
  handlePageChange,
  handleResetFilter,
  handleMarketplace,
  setCustomDateState,
  handleSalesGroupBy,
  handleSalesDailyFact,
  applyCustomMonthRange,
  setSelectedManagerUser,
  setIsDisabledTimeFrame,
  setSelectedAdSpendType,
  setSelectedRevenueType,
  setSelectedSalesMetrics,
  setSelectedVendorMetrics,
  handleOnMetricsTabChange,
  setShowCustomMonthModal,
  setShowAdCustomDateModal,
  setYearOverYearDateState,
  displayContributionTable,
  handleBgsUserFilterEvents,
  handleUserOnMenuCloseEvents,
  handleGoalsDateChangeEvents,
  renderRecentAndPreviousLabel,
  handleCustomMonthRangeEvents,
  handleManagerUserFilterEvents,
  handleSellerVendorFilterEvents,
  handleBrandPartnerFilterEvents,
  setShowAdYearOverYearDateModal,
  setSelectedRevenueDropDownOption,
  setSelectedAdSpendDropDownOption,
  handleManagerUserOnMenuCloseEvents,
  handleKeyContributorsDownloadReport,
  handleVendorMetricsTypeFilterEvents,
}) {
  const dashboardDynamicError = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );

  const { Option, SingleValue } = components;

  const defaultCurrentDate =
    selectedAccountType?.value === VENDORSMALL
      ? currentDateForVendor
      : currentDate;

  // custom date option
  const dateOptionsForGoals = [
    {
      value: CUSTOM,
      label: 'Custom Range',
      sub: 'Select start and end dates',
    },
  ];

  // adding thousand separator
  const addThousandComma = (number, decimalDigits = 2) => {
    if (number !== undefined && number !== null) {
      return number
        .toFixed(decimalDigits)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return number;
  };

  // get dropdwon indicator for dropdown filter
  const getSelectDropdownIndicator = () => {
    return {
      DropdownIndicator,
    };
  };

  // display sub label for custom date
  const renderCustomDateSubLabel = (props) => {
    if (selectedSalesDF?.value === CUSTOM && isCustomDateApply) {
      return `From- ${dayjs(customDateState[0]?.startDate).format(
        'MMM D, YYYY',
      )}  To- ${dayjs(customDateState[0]?.endDate).format('MMM D, YYYY')}`;
    }
    if (selectedSalesDF?.value === YEAROVERYEAR && isCustomDateApply) {
      return `From ${dayjs(yearOverYearDateState[0]?.startDate).format(
        'D MMM',
      )}  To ${dayjs(yearOverYearDateState[0]?.endDate).format('D MMM')}`;
    }

    return props?.data?.sub || '';
  };

  // react select single value component for sales custom date filter
  const singleFilterValue = (props) => {
    return (
      <SingleValue {...props}>
        <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
        <div style={{ fontSize: '10px' }}>
          {renderCustomDateSubLabel(props)}
        </div>
      </SingleValue>
    );
  };

  // react select option component for sales custom date filter
  const singleFilterOption = (props) => {
    return (
      <Option {...props}>
        <div style={{ fontSize: '14px' }}>
          {props?.data?.label}
          {props?.isDisabled === true ? (
            <>
              <FontAwesomeIcon
                icon="fal fa-info-circle"
                color={Theme.orange}
                fontSize="13px"
                style={{ marginLeft: '20px' }}
                data-tip="Selected metrics has no data for this period."
                data-for="adSpendTotalInfo"
              />
              <ReactTooltip
                class="initialTextTransform"
                id="adSpendTotalInfo"
                aria-haspopup="true"
                place="right"
                effect="solid"
              />
            </>
          ) : null}
        </div>

        <div style={{ fontSize: '10px' }}>{props?.data?.sub}</div>
      </Option>
    );
  };

  // configuring the dropdown component for sales custom date filter
  const getSelectComponents = () => {
    return {
      Option: singleFilterOption,
      SingleValue: singleFilterValue,
      DropdownIndicator,
    };
  };

  // render the selected custom date or month as a sub label in the time filter
  const renderCustomDateGoalsSubLabel = () => {
    return `${dayjs(customStartDate).format('MMM, YYYY')}`;
  };

  // react select single value for goals custom month filter
  const goalsSingleFilterValue = (props) => {
    return (
      <SingleValue {...props}>
        <span style={{ fontSize: '14px', color: Theme.gray90 }}>
          {renderCustomDateGoalsSubLabel(props)}
        </span>
      </SingleValue>
    );
  };

  // react select option component for goals custom month filter
  const goalsFilterOption = (props) => {
    return (
      <Option {...props}>
        <div style={{ fontSize: '14px' }}>{props?.data?.label}</div>
        <div style={{ fontSize: '12px' }}>
          {renderCustomDateGoalsSubLabel(props)}
        </div>
      </Option>
    );
  };

  // configuring the dropdown component for goals custom month filter
  const getTimeFilterSelectGoalsComponents = () => {
    return {
      Option: goalsFilterOption,
      SingleValue: goalsSingleFilterValue,
      DropdownIndicator,
    };
  };

  // display error message
  const renderErrorMessage = () => {
    let { fromDate } = customDateError;
    let { toDate } = customDateError;

    fromDate = dayjs(fromDate).format('MM/DD/YYYY');
    toDate = dayjs(toDate).subtract(1, 'day').format('MM/DD/YYYY');

    return (
      <div className="col-12 mt-2 mb-3">
        <div className="normal-text-black first-white-card">
          <GrayMessageBar
            message={`There is no sales data available from ${fromDate}
      to ${toDate}.`}
          />
        </div>
      </div>
    );
  };

  // generate html for single select dropdown
  const generateSingleSelectDropdownHTML = (
    filterId,
    className,
    dropdownOptions,
    selectedValue,
    onChangeHandler,
    dropdownComponents,
    isSearchable = false,
  ) => {
    return (
      <div style={{ position: 'relative' }}>
        <SingleSelectDropdown
          filterId={filterId}
          className={className}
          isApiCall={salesGraphLoader || contributionLoader}
          dropdownOptions={dropdownOptions}
          selectedValue={selectedValue}
          onChangeHandler={onChangeHandler}
          dropdownComponents={dropdownComponents}
          isSearchable={isSearchable}
        />
      </div>
    );
  };

  // display time frame filter filter
  const displayTimeFrameDropdownFilter = (dateFilterOptions) => {
    return generateSingleSelectDropdownHTML(
      'BT-SalesTimeFrameFilter',
      'single-select-dropdown',
      dateFilterOptions,
      selectedSalesDF,
      handleSalesDailyFact,
      getSelectComponents,
      false,
    );
  };

  // render vendor metrics type filter
  const displayVendorMetricsTypeFilter = () => {
    return generateSingleSelectDropdownHTML(
      'BT-SalesVendorMetricsTypeFilter',
      '',
      vendorSalesMetricsTypeOptions,
      selectedVendorMetricsType,
      handleVendorMetricsTypeFilterEvents,
      getSelectDropdownIndicator,
      false,
    );
  };

  // render vendor metrics type filter
  const displayDateFilterForGoals = () => {
    return generateSingleSelectDropdownHTML(
      'BT-MonthPickerforGoals',
      '',
      dateOptionsForGoals,
      dateOptionsForGoals?.[0],
      handleGoalsDateChangeEvents,
      getTimeFilterSelectGoalsComponents,
      false,
    );
  };

  // display performance header filter
  const displayPerformanceHeaderfilters = () => {
    const localDateOptionsWithYear = dateOptionsWithYearOverYear;

    // PDV-6572 If PPC or DSP selected then disabled the year_to_date option
    if (selectedAccountType?.value === SELLERSMALL) {
      localDateOptionsWithYear[3].isDisabled = _.keys(
        selectedSalesMetrics,
      )?.some((item) => [REVENUEPPC, REVENUEDSP].includes(item));
    }

    return (
      <div className="dashboard-header-filter">
        <div className="row">
          <div className="col-12 col-md-6 pr-md-0 mb-3 mb-md-0">
            {selectedAccountType?.value === VENDORSMALL
              ? displayVendorMetricsTypeFilter()
              : null}
          </div>
          <div className="col-12 col-md-6">
            {selectedTabMetrics === 'goals'
              ? displayDateFilterForGoals()
              : displayTimeFrameDropdownFilter(localDateOptionsWithYear)}
          </div>
        </div>
      </div>
    );
  };

  // display performance header
  const displayPerformanceSectionHeader = () => {
    let insightMessage = '';

    if (selectedAccountType?.value === SELLERSMALL) {
      insightMessage = performanceInsightMessage?.sales;
    } else {
      insightMessage = performanceInsightMessage?.vendorSales;
    }

    return (
      <div className="row">
        <div className="col-md-4 col-12">
          <p className="black-heading-title mt-2 mb-3">Sales Performance </p>
        </div>
        <div className="col-md-8 col-12 mb-3">
          {displayPerformanceHeaderfilters()}
        </div>
        <div className="col-12">
          {dashboardDynamicError?.sales_performance?.placeholder ? (
            <GrayMessageBar
              message={dashboardDynamicError?.sales_performance?.placeholder}
            />
          ) : null}
        </div>
        {selectedSalesDF?.value !== CUSTOM ? (
          <div className="col-12">
            <GrayMessageBar message={insightMessage} />
          </div>
        ) : null}
        {selectedSalesDF?.value === CUSTOM && customDateError !== null
          ? renderErrorMessage()
          : null}
      </div>
    );
  };

  // display daily weekly monthly toggle filter
  const displayDailyWeeklyMonthlyToggleButton = () => {
    return (
      <ToggleButton>
        <div className="days-container ">
          <ul
            className={salesGraphLoader ? 'disabled-tab days-tab' : 'days-tab'}
          >
            <li id="BT-salesDashboard-days">
              <input
                className="d-none"
                type="radio"
                id="daysCheck"
                name="flexRadioDefault"
                value={salesGroupBy}
                checked={salesGroupBy === 'daily'}
                onClick={() => handleSalesGroupBy('daily')}
                onChange={() => {}}
              />
              <label htmlFor="daysCheck">Daily</label>
            </li>

            <li id=" BT-adperformance-weekly">
              <input
                className="d-none"
                type="radio"
                id="weeklyCheck"
                name="flexRadioDefault"
                value={salesGroupBy}
                checked={salesGroupBy === 'weekly'}
                onChange={() => handleSalesGroupBy('weekly')}
              />
              <label htmlFor="weeklyCheck">Weekly</label>
            </li>

            <li id=" BT-adperformance-monthly">
              <input
                className=" d-none"
                type="radio"
                id="monthlyCheck"
                name="flexRadioDefault"
                value={salesGroupBy}
                checked={salesGroupBy === 'monthly'}
                onChange={() => handleSalesGroupBy('monthly')}
              />
              <label htmlFor="monthlyCheck">Monthly</label>
            </li>
          </ul>
        </div>
      </ToggleButton>
    );
  };

  // display contribution section header
  const dispalyContributionSectionHeader = () => {
    return (
      <div className="row">
        <div className="col-md-6 col-12">
          <p className="black-heading-title mt-2 mb-4">Contributors</p>
        </div>
        <div className="col-md-6 col-12 pr-2 text-right">
          <div
            className={`black-heading-title text-medium-font m-0 cursor ${
              salesGraphLoader ||
              contributionLoader ||
              downloadReportLoader ||
              selectedSalesDF?.value === CUSTOM
                ? 'disabled'
                : ''
            }`}
            role="presentation"
            onClick={() => {
              handleKeyContributorsDownloadReport();
            }}
            style={{ maxWidth: '150px' }}
          >
            {downloadReportLoader ? (
              <PageLoader
                component="sidebar-loader"
                type="button"
                color={Theme?.orange}
              />
            ) : (
              <>
                <FontAwesomeIcon
                  className="mr-2"
                  color={Theme?.orange}
                  icon="far fa-arrow-to-bottom"
                />
                Download Report
              </>
            )}
          </div>
        </div>
        <div className="col-12">
          {dashboardDynamicError?.sales_performance?.contribution ? (
            <GrayMessageBar
              message={dashboardDynamicError?.sales_performance?.contribution}
            />
          ) : null}
        </div>
      </div>
    );
  };

  // display sales pagination
  const displaySalesPagination = () => {
    return contributionCount > 0 ? (
      <CommonPagination
        count={contributionCount}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
      />
    ) : null;
  };

  // render seller reporting section
  const renderSellerReportingSection = () => {
    return (
      <div id="salesSellerReportingSection">
        <SalesSellerReporting
          // string
          currencySymbol={currencySymbol}
          salesGroupBy={salesGroupBy}
          selectedTabMetrics={selectedTabMetrics}
          selectedRevenueType={selectedRevenueType}
          selectedAdSpendType={selectedAdSpendType}
          // boolean
          salesGraphLoader={salesGraphLoader}
          isMetricsDisabled={isMetricsDisabled}
          contributionLoader={contributionLoader}
          isDisabledTimeFrame={isDisabledTimeFrame}
          // object
          selectedSalesDF={selectedSalesDF}
          salesDifference={salesDifference}
          salesCurrentTotal={salesCurrentTotal}
          salesPreviousTotal={salesPreviousTotal}
          selectedSalesMetrics={selectedSalesMetrics}
          selectedMarketplace={selectedMarketplace}
          selectedRevenueDropDownOption={selectedRevenueDropDownOption}
          selectedAdSpendDropDownOption={selectedAdSpendDropDownOption}
          // array
          salesChartData={salesChartData}
          contributionData={contributionData}
          goalsPerformanceData={goalsPerformanceData}
          // function
          addThousandComma={addThousandComma}
          displaySalesPagination={displaySalesPagination}
          setIsDisabledTimeFrame={setIsDisabledTimeFrame}
          setSelectedAdSpendType={setSelectedAdSpendType}
          setSelectedRevenueType={setSelectedRevenueType}
          setSelectedSalesMetrics={setSelectedSalesMetrics}
          handleOnMetricsTabChange={handleOnMetricsTabChange}
          displayContributionTable={displayContributionTable}
          renderRecentAndPreviousLabel={renderRecentAndPreviousLabel}
          displayPerformanceSectionHeader={displayPerformanceSectionHeader}
          setSelectedRevenueDropDownOption={setSelectedRevenueDropDownOption}
          setSelectedAdSpendDropDownOption={setSelectedAdSpendDropDownOption}
          dispalyContributionSectionHeader={dispalyContributionSectionHeader}
          displayDailyWeeklyMonthlyToggleButton={
            displayDailyWeeklyMonthlyToggleButton
          }
        />
      </div>
    );
  };

  // render vendor reporting section
  const renderVendorReportingSection = () => {
    return (
      <div id="salesVendorReportingSection">
        <SalesVendorReporting
          // string
          salesGroupBy={salesGroupBy}
          currencySymbol={currencySymbol}
          selectedTabMetrics={selectedTabMetrics}
          // boolean
          salesGraphLoader={salesGraphLoader}
          contributionLoader={contributionLoader}
          // object
          selectedSalesDF={selectedSalesDF}
          vendorSalesDifference={vendorSalesDifference}
          selectedVendorMetrics={selectedVendorMetrics}
          vendorSalesCurrentTotal={vendorSalesCurrentTotal}
          vendorSalesPreviousTotal={vendorSalesPreviousTotal}
          selectedVendorMetricsType={selectedVendorMetricsType}
          // array
          contributionData={contributionData}
          vendorSalesMetricsData={vendorSalesMetricsData}
          // function
          displaySalesPagination={displaySalesPagination}
          setSelectedVendorMetrics={setSelectedVendorMetrics}
          handleOnMetricsTabChange={handleOnMetricsTabChange}
          displayContributionTable={displayContributionTable}
          renderRecentAndPreviousLabel={renderRecentAndPreviousLabel}
          displayPerformanceSectionHeader={displayPerformanceSectionHeader}
          dispalyContributionSectionHeader={dispalyContributionSectionHeader}
          displayDailyWeeklyMonthlyToggleButton={
            displayDailyWeeklyMonthlyToggleButton
          }
        />
      </div>
    );
  };

  // render left side panel - sales filter section
  const renderSalesFilterSection = () => {
    return (
      <SalesFilter
        // boolean
        isApiCall={salesGraphLoader || contributionLoader}
        isBGSAdmin={isBGSAdmin}
        isBGSManager={isBGSManager}
        isAdTeamManager={isAdTeamManager}
        isSpreetailRole={isSpreetailRole}
        isAdManagerAdmin={isAdManagerAdmin}
        // objects
        selectedMarketplace={selectedMarketplace}
        selectedAccountType={selectedAccountType}
        selectedBrandPartner={selectedBrandPartner}
        // array
        userList={userList}
        selectedUser={selectedUser}
        managerUserList={managerUserList}
        marketplaceOptions={marketplaceOptions}
        selectedManagerUser={selectedManagerUser}
        brandPartnerOptions={brandPartnerOptions}
        // function
        setSelectedUser={setSelectedUser}
        handleResetFilter={handleResetFilter}
        handleMarketplace={handleMarketplace}
        setSelectedManagerUser={setSelectedManagerUser}
        handleBgsUserFilterEvents={handleBgsUserFilterEvents}
        handleUserOnMenuCloseEvents={handleUserOnMenuCloseEvents}
        handleManagerUserFilterEvents={handleManagerUserFilterEvents}
        handleSellerVendorFilterEvents={handleSellerVendorFilterEvents}
        handleBrandPartnerFilterEvents={handleBrandPartnerFilterEvents}
        handleManagerUserOnMenuCloseEvents={handleManagerUserOnMenuCloseEvents}
      />
    );
  };

  // render right side panel - sales dashboard section
  const renderSalesDashboardSection = () => {
    return (
      <>
        {selectedAccountType?.value === SELLERSMALL
          ? renderSellerReportingSection()
          : renderVendorReportingSection()}
      </>
    );
  };

  // display sales custom date modal
  const displaySalesCustomDateModal = () => {
    return (
      <CustomDateModal
        id="BT-salesSponsoredDashboard-daterange"
        isOpen={showAdCustomDateModal}
        range={customDateState}
        onClick={() => {
          setShowAdCustomDateModal(false);
          if (selectedSalesDF?.value !== CUSTOM) {
            setCustomDateState([
              {
                startDate: defaultCurrentDate,
                endDate: defaultCurrentDate,
                key: 'adSelection',
              },
            ]);
          }
        }}
        onChange={(item) => {
          setCustomDateState([item?.adSelection]);
        }}
        onApply={() => applyCustomDate(CUSTOM)}
        currentDate={
          selectedAccountType?.value === SELLERSMALL
            ? currentDate
            : currentDateForVendor
        }
        minDate={new Date('01/01/1901')}
      />
    );
  };

  // display sales custom year over year modal
  const displaySalesCustomYOYModal = () => {
    return (
      <CustomDateModal
        id="BT-year-over-year-daterange"
        isOpen={showAdYearOverYearDateModal}
        range={yearOverYearDateState}
        onClick={() => {
          setShowAdYearOverYearDateModal(false);
          if (selectedSalesDF?.value !== YEAROVERYEAR) {
            setYearOverYearDateState([
              {
                startDate: defaultCurrentDate,
                endDate: defaultCurrentDate,
                key: 'yearOverYearSelection',
              },
            ]);
          }
        }}
        onChange={(item) => {
          setYearOverYearDateState([item?.yearOverYearSelection]);
        }}
        onApply={() => applyCustomDate(YEAROVERYEAR)}
        currentDate={
          selectedAccountType?.value === SELLERSMALL
            ? currentDate
            : currentDateForVendor
        }
        minDate={new Date('01/01/1901')}
      />
    );
  };

  // render custom month range modal
  const renderCustomMonthRangeModal = () => {
    return (
      <CustomMonthRangeModal
        id="goals-month-range"
        isOpen={showCustomMonthModal}
        title="Select Month Range"
        className="custom-month-range"
        maxDate={new Date()}
        minDate={new Date('01/01/1901')}
        startMonth={customMonthRange?.[0]?.startMonth}
        endMonth={customMonthRange?.[0]?.endMonth}
        onClick={() => {
          setShowCustomMonthModal(false);
        }}
        onDateChange={handleCustomMonthRangeEvents}
        onApply={() => applyCustomMonthRange()}
        currentMonth={new Date()}
        selected={false}
      />
    );
  };

  // display sales modal
  const displaySalesModals = () => {
    return (
      <>
        {displaySalesCustomDateModal()}
        {displaySalesCustomYOYModal()}
        {renderCustomMonthRangeModal()}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div className="row mt-3">
      <div className="col-xl-2 col-lg-3 col-md-12 col-12">
        {renderSalesFilterSection()}
      </div>
      <div className="col-xl-10 col-lg-9 col-md-12 col-12">
        <div>{renderSalesDashboardSection()}</div>
        <div>{displaySalesModals()}</div>
      </div>
    </div>
  );
}

export default SalesPerformanceContainer;
/* ********** MAIN FUNCTION END HERE ********** */

/* ********** Declaring prop types ********** */
SalesPerformanceContainer.defaultProps = SalesPerformanceContainerDefaultProps;
SalesPerformanceContainer.propTypes = SalesPerformanceContainerPropTypes;
