import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { shape, func, bool, string, arrayOf } from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GetInitialName } from '../../../../../common';
import ChangeTaskAssigneeModal from '../PlaybookModals/ChangeTaskAssigneeModal';
import { renderAssigneeTooltip } from '../CommonFunctions';

export default function PlaybookTaskAssignee({
  ID,
  taskId,
  customerId,
  playbookTab,
  assigneeData,
  playbookAPICall,
  isUserAssignedToBP,
}) {
  const [showChangeTaskAssigneeModal, setShowChangeTaskAssigneeModal] =
    useState(false);
  const userInfo = useSelector((state) => state.userState.userInfo);

  // handle is allowed to change the task assignee events
  const handleChangeTaskAsigneePermission = () => {
    if (isUserAssignedToBP || userInfo?.onboarding_playbook_access) {
      if (playbookTab === 'onboarding-gray') {
        setShowChangeTaskAssigneeModal(false);
      } else {
        setShowChangeTaskAssigneeModal(true);
      }
    } else {
      toast.error(
        `Only assigned users to this BP can assign tasks. Please contact the BGS Manager for this account.`,
      );
    }
  };

  // get initial letter from first name and last name of user name
  const renderGetInitialName = (item) => {
    return (
      <GetInitialName userInfo={item} property="small-AvatarName ml-0 mb-1" />
    );
  };

  // render task assignee
  const renderTaskAssignee = (item) => {
    const firstName = item?.first_name;
    const lastName = item?.last_name;
    const showAssigneeTooltip = firstName !== null && lastName !== null;

    return showAssigneeTooltip ? (
      <div
        key={item?.taskId}
        className={`task-assignee ${
          playbookTab === 'onboarding-gray' ? '' : 'cursor'
        }`}
        style={{
          display: 'inline-block',
        }}
        role="presentation"
        onClick={() => {
          handleChangeTaskAsigneePermission();
        }}
        data-tip
        data-for={taskId}
      >
        {renderGetInitialName(item)}
        {renderAssigneeTooltip(item, taskId, 'task-assignee-tooltip')}
      </div>
    ) : null;
  };

  // render plus sign circle
  const renderPlusSign = () => {
    return (
      <div
        className="cursor task-assignee add-task-assignee"
        style={{
          display: 'inline-block',
          padding: '2px 0px',
          textAlign: 'center',
        }}
        role="presentation"
        onClick={() => {
          handleChangeTaskAsigneePermission();
        }}
      >
        <FontAwesomeIcon
          icon="fa-regular fa-plus"
          color="#2E384D"
          fontSize="10px"
        />
      </div>
    );
  };

  // render task assignee and plus sign
  const renderTaskAssigneeData = () => {
    return assigneeData?.length
      ? assigneeData?.map((item) => {
          return renderTaskAssignee(item);
        })
      : playbookTab === 'onboarding-gray'
      ? null
      : renderPlusSign();
  };

  // render change assignee modal
  const renderChangeTaskAssigneeModal = () => {
    return (
      <ChangeTaskAssigneeModal
        modalId="task-assignee-modal"
        isOpen={showChangeTaskAssigneeModal}
        ID={ID}
        taskId={taskId}
        customerId={customerId}
        playbookTab={playbookTab}
        afterSubmitAPI={() => {
          playbookAPICall();
          setShowChangeTaskAssigneeModal(false);
        }}
      />
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      {renderTaskAssigneeData()}
      {showChangeTaskAssigneeModal ? renderChangeTaskAssigneeModal() : null}
    </>
  );
}

PlaybookTaskAssignee.defaultProps = {
  playbookTab: '',
  playbookAPICall: () => {},
};

PlaybookTaskAssignee.propTypes = {
  playbookTab: string,
  ID: string.isRequired,
  taskId: string.isRequired,
  customerId: string.isRequired,
  isUserAssignedToBP: bool.isRequired,
  playbookAPICall: func,
  assigneeData: arrayOf(shape()).isRequired,
};
