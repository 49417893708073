// This is the main container file of the pending Approval dashboard.
// this file contain the all variable, API calls and handle functions.

// react imports
import React, { useCallback, useEffect, useRef, useState } from 'react';

// third party imports
import Modal from 'react-modal';
import { func } from 'prop-types';
import { toast } from 'react-toastify';

// local file imports
import Theme from '../../../../theme/Theme';
import PendingApprovalsDashboardFilters from './PendingApprovalsDashboardFilters';
import PendingAgreementModal from '../../../Customer/CustomerDetailView/Modals/PendingAgreementModal';
import PendingApprovalsDashboardView from './PendingApprovalsDashboardView';
import { CloseIcon } from '../../../../theme/images';
import {
  getPendingApprovalRequests,
  archivedPendingApprovalRequest,
} from '../../../../api/PendingApprovalsDashboardApi';
import {
  pendingApprovalOrderOptions,
  customStyles,
} from '../../../../constants';
import {
  Button,
  CommonPagination,
  ModalBox,
  NoData,
  PageLoader,
  WhiteCard,
} from '../../../../common';

const PendingApprovalsDashboardContainer = ({ setCallPendingApprovalAPI }) => {
  const mounted = useRef(false);
  const [apiCallLoader, setApiCallLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [pendingApprovalListData, setPendingApprovalListData] = useState();
  const [pendingApprovalListDataCount, setPendingApprovalListDataCount] =
    useState(0);
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [selectedSortByOrder, setSelectedRequestOrder] = useState(
    pendingApprovalOrderOptions[0],
  );
  const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] =
    useState(false);
  const [showCancellationSummaryModal, setShowCancellationSummaryModal] =
    useState({
      modal: false,
      type: null,
      id: null,
    });
  const [archiveLoader, setArchiveLoader] = useState(false);
  const [idToArchiveRequest, setIdToArchiveRequest] = useState(false);

  // get pending approval request data
  const getPendingApprovalData = useCallback(
    (requestOrder, searchBy, pagenumber = 1) => {
      setApiCallLoader(true);
      getPendingApprovalRequests(requestOrder, searchBy, pagenumber).then(
        (res) => {
          if (mounted.current) {
            if (res?.status === 200) {
              setPendingApprovalListData(res?.data?.results);
              setPendingApprovalListDataCount(res?.data?.count || 0);
            } else {
              setPendingApprovalListData(0);
              setPendingApprovalListData();
            }
            setApiCallLoader(false);
          }
        },
      );
    },
    [],
  );

  // use effect hook for getPendingApprovalData
  useEffect(() => {
    mounted.current = true;
    getPendingApprovalData(
      selectedSortByOrder?.value,
      searchQuery,
      selectedPageNumber,
    );
    return () => {
      mounted.current = false;
    };
  }, [
    selectedSortByOrder,
    selectedPageNumber,
    getPendingApprovalData,
    searchQuery,
  ]);

  // function - handle archive confirmation activity
  const handledOnConfirmArchiveClick = () => {
    setArchiveLoader(true);
    archivedPendingApprovalRequest(idToArchiveRequest).then(() => {
      toast.success(`Request is archived successfully.`);
      setShowArchiveConfirmationModal(false);
      setIdToArchiveRequest(null);
      setArchiveLoader(false);
      setCallPendingApprovalAPI(true);
      getPendingApprovalData(
        selectedSortByOrder?.value,
        searchQuery,
        selectedPageNumber,
      );
    });
  };

  // handle search input field
  const handleSearch = (event) => {
    setSelectedPageNumber(1);
    setSearchQuery(event?.target?.value);
  };

  // when change the sort-by dropdown
  const handleSortByFilter = (event) => {
    setSelectedRequestOrder(event);
  };

  // when click on refresh button
  const handleRefreshClick = () => {
    setSearchQuery('');
    setSelectedPageNumber(1);
    setSelectedRequestOrder(pendingApprovalOrderOptions[0]);
  };

  // displat archive modal html
  const renderArchiveModal = () => {
    return (
      <Modal
        isOpen={showArchiveConfirmationModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            setShowArchiveConfirmationModal(false);
          }}
          role="presentation"
        />
        <ModalBox>
          <div className="modal-body">
            <div className="alert-msg pb-3 ">
              Are you sure you want to archive?
              <div className="sure-to-proceed">
                This will no longer be displayed on the dashboard once it has
                been archived.
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-6 mt-4">
                <Button
                  className="btn-primary w-100"
                  onClick={() => {
                    handledOnConfirmArchiveClick();
                  }}
                >
                  {archiveLoader ? (
                    <PageLoader color="#fff" type="button" />
                  ) : (
                    'Confirm'
                  )}
                </Button>
              </div>
              <div className="col-6 mt-4">
                <Button
                  className="btn-transparent w-100"
                  onClick={() => {
                    setIdToArchiveRequest(null);
                    setShowArchiveConfirmationModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  // display pending agreement modal
  const renderCancellationSummaryModal = () => {
    return (
      <Modal
        isOpen={showCancellationSummaryModal.modal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Add team modal"
      >
        <PendingAgreementModal
          setShowModal={setShowCancellationSummaryModal}
          selectedAgreement={showCancellationSummaryModal}
        />
      </Modal>
    );
  };

  // display pending approval dashboard filters
  const displayPendingApprovalDashboardFilter = () => {
    return (
      <PendingApprovalsDashboardFilters
        handleSortByFilter={handleSortByFilter}
        selectedSortByOrder={selectedSortByOrder}
        apiCallLoader={apiCallLoader}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
        handleRefreshClick={handleRefreshClick}
      />
    );
  };

  // display pending approval dashboard data
  const displayPendingApprovalDashboardData = () => {
    return (
      <WhiteCard id="BT-PendingApprovalTable">
        {apiCallLoader ? (
          <PageLoader
            component="performance-graph"
            color={Theme.orange}
            type="detail"
            width={40}
            height={40}
          />
        ) : pendingApprovalListData?.length ? (
          <>
            <PendingApprovalsDashboardView
              apiCallLoader={apiCallLoader}
              pendingApprovalListData={pendingApprovalListData}
              setIdToArchiveRequest={setIdToArchiveRequest}
              setShowCancellationSummaryModal={setShowCancellationSummaryModal}
              setShowArchiveConfirmationModal={setShowArchiveConfirmationModal}
            />

            {pendingApprovalListDataCount > 10 ? (
              <CommonPagination
                count={pendingApprovalListDataCount}
                pageNumber={selectedPageNumber}
                handlePageChange={(value) => {
                  setSelectedPageNumber(value);
                }}
              />
            ) : null}
          </>
        ) : (
          <NoData>No Pending Approval Request Found.</NoData>
        )}
      </WhiteCard>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div key="pending-approval-dashboard">
      {displayPendingApprovalDashboardFilter()}
      {displayPendingApprovalDashboardData()}
      {renderArchiveModal()}
      {renderCancellationSummaryModal()}
    </div>
  );
};

export default PendingApprovalsDashboardContainer;

// prop types
PendingApprovalsDashboardContainer.defaultProps = {
  setCallPendingApprovalAPI: () => {},
};

PendingApprovalsDashboardContainer.propTypes = {
  setCallPendingApprovalAPI: func,
};
