import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';
import styled from 'styled-components';
import { string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Theme from '../../../../theme/Theme';
import { feedbackOptions } from '../../../../constants';
import { showOnboardingMsg } from '../../../../store/actions/userState';
import { AccountSetupIcon } from '../../../../theme/images';
import { getCustomerFeedback, saveCustomerFeedback } from '../../../../api';
import { ModalBox, Button, FormField, PageLoader } from '../../../../common';

export default function OtherModals({ id }) {
  const dispatch = useDispatch();
  const AccountSetupcustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '480px ',
      width: '100% ',
      minHeight: '200px',
      overlay: ' {zIndex: 1000}',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const showOnBoardingSuccessMsg = useSelector(
    (state) => state.userState.showForgotMsg,
  );
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState({ loader: false, type: 'button' });

  useEffect(() => {
    getCustomerFeedback(id).then((res) => {
      setFormData(res?.data?.[0]);
    });
  }, [id]);

  const saveFeedback = () => {
    setIsLoading({ loader: true, type: 'button' });
    const details = {
      customer: id,
      survey_name: 'Next Account Setup Survey',
      feedback: formData?.feedback || null,
      ratings: formData?.ratings,
      status: 'answered',
    };
    saveCustomerFeedback(formData?.id || null, details, id).then((response) => {
      if (response?.status === 201 || response?.status === 200) {
        setIsLoading({ loader: false, type: 'button' });
        dispatch(showOnboardingMsg(false));
      }
    });
  };

  return (
    <>
      <Modal
        isOpen={showOnBoardingSuccessMsg}
        style={AccountSetupcustomStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <ModalBox>
          <div className="modal-body account-setup-complete">
            <img className="mt-2" src={AccountSetupIcon} alt="company-icon" />

            <h3 className="page-heading mb-3 mt-3 ">Account Set Up Complete</h3>
            <p className="extra-bold ">
              {' '}
              Congratulations on completing your account setup with Buy Box
              Experts! Expect to hear from your On-boarding Specialist in the
              next 24 hours to walk through final set up items and get you in
              contact with your Brand Growth Strategist.
            </p>

            <p className="extra-bold mt-2">
              If you have any questions in the meantime please reach out to{' '}
              <br />
              <a
                className="link-url"
                target="_BLANK"
                rel="noopener noreferrer"
                href="https://www.buyboxexperts.com/"
              >
                onboarding@buyboxexperts.com
              </a>
            </p>
            <p style={{ color: 'black', fontWeight: 600 }}>
              Overall, How easy or difficult was the account setup?
            </p>

            <FeedbackContainer>
              <ul>
                {feedbackOptions.map((item) => (
                  <li
                    className={
                      formData?.ratings === item.key ? 'active mr-3' : 'mr-3'
                    }
                    key={item.key}
                  >
                    <input
                      className="d-none"
                      type="radio"
                      id={item.key}
                      name="radio"
                      value={item.key}
                      defaultChecked={formData?.ratings}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          ratings: event.target.value,
                        })
                      }
                    />
                    <label htmlFor={item.key}>{item.label}</label>
                  </li>
                ))}
              </ul>
            </FeedbackContainer>

            <FormField className="mt-3">
              <textarea
                className="text-area"
                defaultValue={formData?.feedback}
                style={{ color: Theme.black, fontSize: '14px', resize: 'none' }}
                rows="6"
                placeholder="Provide more details here.."
                onChange={(event) =>
                  setFormData({ ...formData, feedback: event.target.value })
                }
              />
            </FormField>
            <Button
              className="btn-primary w-100 on-boarding mt-3"
              onClick={() => saveFeedback()}
              disabled={!formData?.ratings}
            >
              {isLoading.loader && isLoading.type === 'button' ? (
                <PageLoader color="#fff" type="button" />
              ) : (
                'Submit feedback'
              )}
            </Button>
          </div>
        </ModalBox>
      </Modal>
    </>
  );
}

const FeedbackContainer = styled.div`
  ul {
    text-decoration: 'none';
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      width: 40px;
      height: 40px;
      border: 1px solid ${Theme.gray8};
      background: ${Theme.gray8};
      color: ${Theme.black};
      text-transform: Uppercase;
      display: inline-block;
      font-size: ${Theme.extraMedium};
      font-weight: 600;
      padding-top: 10px;

      &.active {
        border: 1px solid ${Theme.orange};
        background: ${Theme.lightOrange};
      }
      label,
      input {
        cursor: pointer;
      }
    }
  }
`;

OtherModals.propTypes = {
  id: string.isRequired,
};

// brand assets hidden for now
//  const showBrandAssetSuccessMsg = useSelector(
//    (state) => state.customerState.showBrandAssetMsg,
//  );
// <Modal
//   isOpen={showBrandAssetSuccessMsg}
//   style={AccountSetupcustomStyles}
//   ariaHideApp={false}
//   contentLabel="Edit modal">
//   <ModalBox>
//     <div className="modal-body account-setup-complete">
//       <img className="mt-2" src={AccountSetupIcon} alt="company-icon" />

//       <h3 className="page-heading mb-3 mt-3 ">Brand Assets Received</h3>
//       <p className="extra-bold ">
//         {' '}
//         Thank you for uploading your brand assets. Once you’ve spoken with our
//         Brand Growth Strategist and Creative Strategist, they will review your
//         products and request assets for the products we will use in our brand
//         sample, which will act as a guide for future optimization work. You
//         will receive an email as well as a notification in NEXT that will take
//         you to where you can upload the requested product assets.
//       </p>

//       <Button
//         className="btn-primary w-100 on-boarding mt-3"
//         onClick={() => dispatch(showBrandAsset(false))}>
//         Ok. Got it!
//       </Button>
//     </div>
//   </ModalBox>
// </Modal>;
