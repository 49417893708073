import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import {
  arrayOf,
  shape,
  oneOfType,
  object,
  func,
  bool,
  string,
} from 'prop-types';

import PlaybookTaskCollapse from './PlaybookTaskCollapse';
import PlaybookTaskAssignee from './PlaybookTaskAssignee';
import PlaybookTaskStatus from './PlaybookTaskStatus';
import { InnerCollapseRow } from '../PlaybookStyle';
import { JIRA_TICKET_BASE_URL } from '../../../../../constants';
import { renderCollapseDropdownIcon } from '../CommonFunctions';

export default function PlaybookDeliverableCollapse({
  ID,
  customerId,
  isCustomer,
  playbookTab,
  playbookAPICall,
  deliverableData,
  isUserAssignedToBP,
  isCurrentMonthTask,
}) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const [showTaskCollapse, setShowTaskCollpase] = useState(false);
  const deliverableID = deliverableData?.id;
  const taskData = {
    taskId: deliverableID,
    taskName: deliverableData?.deliverable_name,
    jiraStatus: deliverableData?.jira_status,
  };

  // render deliverable data
  const renderDeliverableData = () => {
    return (
      <>
        <InnerCollapseRow
          key={deliverableID}
          className={playbookTab === 'playbook' ? 'first-layer-collapse' : ''}
        >
          <td
            width={playbookTab === 'playbook' ? '40%' : '50%'}
            className={` ${deliverableData?.tasks?.length ? 'cursor' : null}`}
            style={{
              borderBottom: 'none',
            }}
            role="presentation"
            onClick={() => {
              setShowTaskCollpase(!showTaskCollapse);
            }}
          >
            {deliverableData?.tasks?.length
              ? renderCollapseDropdownIcon(showTaskCollapse)
              : null}

            {deliverableData?.jira_id !== null ? (
              <a
                key={deliverableID}
                className="capitalize"
                target="_BLANK"
                rel="noopener noreferrer"
                href={`${JIRA_TICKET_BASE_URL}${deliverableData?.jira_id}`}
              >
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{
                    __html: deliverableData?.deliverable_name,
                  }}
                />
                {/* {deliverableData?.deliverable_name} */}
              </a>
            ) : (
              <span key={deliverableID} className="capitalize">
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{
                    __html: deliverableData?.deliverable_name,
                  }}
                />
                {/* {deliverableData?.deliverable_name} */}
              </span>
            )}
          </td>
          {playbookTab === 'playbook' ? (
            <td
              style={{
                borderBottom: 'none',
                width: '15%',
              }}
            >
              {deliverableData?.marketplace}
            </td>
          ) : null}
          <td
            style={{
              borderBottom: 'none',
              width: '10%',
            }}
          >
            {deliverableData?.team}
          </td>
          {isCustomer ? null : (
            <td
              style={{
                borderBottom: 'none',
                width: '10%',
              }}
            >
              {deliverableData?.due_date}
            </td>
          )}
          {isCustomer ? null : (
            <td
              className={isCurrentMonthTask ? '' : 'disabled'}
              style={{
                borderBottom: 'none',
                width: '10%',
              }}
            >
              <PlaybookTaskAssignee
                ID={ID}
                customerId={customerId}
                playbookTab={playbookTab}
                taskId={deliverableID}
                playbookAPICall={playbookAPICall}
                isUserAssignedToBP={isUserAssignedToBP}
                assigneeData={deliverableData?.assignee}
              />
            </td>
          )}

          <td
            className={isCurrentMonthTask ? '' : 'disabled'}
            style={{
              borderBottom: 'none',
              width: '15%',
            }}
          >
            <PlaybookTaskStatus
              ID={ID}
              taskData={taskData}
              isCustomer={isCustomer}
              customerId={customerId}
              playbookTab={playbookTab}
              playbookAPICall={playbookAPICall}
            />
          </td>
        </InnerCollapseRow>
      </>
    );
  };

  // render playbook task data collapse
  const renderPlaybookTaskCollapse = () => {
    return deliverableData?.tasks?.length && showTaskCollapse ? (
      <>
        {deliverableData?.tasks?.map((item) => {
          return (
            <PlaybookTaskCollapse
              ID={ID}
              taskData={item}
              isCustomer={isCustomer}
              customerId={customerId}
              playbookTab={playbookTab}
              playbookAPICall={playbookAPICall}
              isUserAssignedToBP={isUserAssignedToBP}
              renderCollapseDropdownIcon={renderCollapseDropdownIcon}
              isCurrentMonthTask={isCurrentMonthTask}
            />
          );
        })}
      </>
    ) : null;
  };

  // render playbook task mobile field data html
  const renderPlaybookTaskMobileDataHTML = (label, dataValue, taskId) => {
    return (
      <div className={`${label === 'Deliverables' ? 'col-12' : 'col-6'} mb-3`}>
        <div className="label">{label}</div>
        <div className="normal-text-black">
          {label === 'Assignee' ? (
            <PlaybookTaskAssignee
              ID={ID}
              taskId={taskId}
              customerId={customerId}
              assigneeData={dataValue}
              playbookTab={playbookTab}
              playbookAPICall={playbookAPICall}
              isUserAssignedToBP={isUserAssignedToBP}
            />
          ) : label === 'Status' ? (
            <PlaybookTaskStatus
              ID={ID}
              taskData={dataValue}
              isCustomer={isCustomer}
              customerId={customerId}
              playbookTab={playbookTab}
              playbookAPICall={playbookAPICall}
            />
          ) : (
            dataValue
          )}
        </div>
      </div>
    );
  };

  // render playbook task table mobile fields
  const renderPlaybookTaskMobileData = (key) => {
    return (
      <>
        <div className="row" key={key}>
          <div
            className="col-12 mb-3"
            role="presentation"
            onClick={() => {
              setShowTaskCollpase(!showTaskCollapse);
            }}
          >
            <div className="label">Deliverables</div>
            {playbookTab === 'playbook' && deliverableData?.jira_id !== null ? (
              <a
                className="normal-text-black capitalize"
                href={`${JIRA_TICKET_BASE_URL}${deliverableData?.jira_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{
                    __html: deliverableData?.deliverable_name,
                  }}
                />
              </a>
            ) : (
              <div className="normal-text-black capitalize">
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{
                    __html: deliverableData?.deliverable_name,
                  }}
                />
              </div>
            )}
          </div>
          <span className="parent-collapse mobile-collapse" />
          {playbookTab === 'playbook'
            ? renderPlaybookTaskMobileDataHTML(
                'Marketplace',
                deliverableData?.marketplace,
              )
            : null}

          {renderPlaybookTaskMobileDataHTML('Team', deliverableData?.team)}
          {isCustomer
            ? null
            : renderPlaybookTaskMobileDataHTML(
                'Due Date',
                deliverableData?.due_date,
              )}
          {isCustomer
            ? null
            : renderPlaybookTaskMobileDataHTML(
                'Assignee',
                deliverableData?.assignee,
                deliverableID,
              )}
          {renderPlaybookTaskMobileDataHTML('Status', taskData)}
        </div>
        {deliverableData?.tasks?.length && showTaskCollapse
          ? deliverableData?.tasks?.map((item) => {
              return (
                <PlaybookTaskCollapse
                  ID={ID}
                  taskData={item}
                  isCustomer={isCustomer}
                  customerId={customerId}
                  playbookTab={playbookTab}
                  playbookAPICall={playbookAPICall}
                  isUserAssignedToBP={isUserAssignedToBP}
                  renderCollapseDropdownIcon={renderCollapseDropdownIcon}
                  isCurrentMonthTask={isCurrentMonthTask}
                />
              );
            })
          : null}
      </>
    );
  };

  // render playbook deliverabel collapse movile view
  const renderPlaybookDeliverableMobileView = () => {
    return (
      <>
        {deliverableData?.tasks?.length ? (
          <div className="playbook-collapse-grid">
            <div
              className={`${deliverableData?.tasks?.length ? 'cursor' : null}`}
              role="presentation"
              onClick={() => {
                setShowTaskCollpase(!showTaskCollapse);
              }}
            >
              {renderCollapseDropdownIcon(showTaskCollapse)}
            </div>
            <div>
              <span className="parent-collapse" />
              {renderPlaybookTaskMobileData(deliverableID)}
            </div>
          </div>
        ) : (
          <div style={{ marginTop: '10px' }}>
            {renderPlaybookTaskMobileData(deliverableID)}
          </div>
        )}
      </>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      {isTablet ? (
        <>
          {renderDeliverableData()}
          {renderPlaybookTaskCollapse()}
        </>
      ) : (
        renderPlaybookDeliverableMobileView()
      )}
    </>
  );
}

PlaybookDeliverableCollapse.defaultProps = {
  deliverableData: [],
  playbookAPICall: () => {},
};

PlaybookDeliverableCollapse.propTypes = {
  ID: string.isRequired,
  customerId: string.isRequired,
  playbookTab: string.isRequired,
  isCustomer: bool.isRequired,
  isUserAssignedToBP: bool.isRequired,
  isCurrentMonthTask: bool.isRequired,
  playbookAPICall: func,
  deliverableData: oneOfType([arrayOf(shape()), object]),
};
