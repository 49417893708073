import React from 'react';

import {
  arrayOf,
  shape,
  string,
  oneOfType,
  object,
  bool,
  func,
} from 'prop-types';

import ServicesPlaybook from './ServicesPlaybook';
import OnboardingPlaybook from './OnboardingPlaybook';
import { PageLoader, WhiteCard } from '../../../../common';
import PlaybookServicesTab from './PlaybookServicesTab';

function PlaybookTabDetails({
  dateType,
  isCustomer,
  customerId,
  playbookId,
  playbookTab,
  accountType,
  customStartDate,
  customEndDate,
  selectedService,
  playbookTaskData,
  playbookAPIError,
  isUserAssignedToBP,
  playbookDataLoader,
  selectedTeamMembers,
  selectedMarketplaces,
  onboardingPlaybookLoader,
  onboardingPlaybookData,
  getOnboardingPlaybook,
  getPlaybookTaskAndServices,
  onboardingPBTaskUpdateID,
  onCreatePlaybookClick,
  createPlaybookBtnLoader,
  updatedServicesOfPlaybookSummary,
  setUpdatedServicesOfPlaybookSummary,
  showSummaryPage,
  setShowSummaryPage,
  marketplacesList,
  servicePlaybookErrorMsg,
  singleSelectedMarketplaces,
}) {
  // render onboarding playbook
  const renderOnboardingPlaybook = () => {
    return onboardingPlaybookLoader ? (
      <PageLoader
        component="performance-graph"
        type="detail"
        width={40}
        height={40}
        color="#FF5933"
      />
    ) : onboardingPlaybookData?.length ? (
      <OnboardingPlaybook
        isCustomer={isCustomer}
        customerId={customerId}
        playbookTab={playbookTab}
        isUserAssignedToBP={isUserAssignedToBP}
        onboardingPlaybookData={onboardingPlaybookData}
        onboardingPBTaskUpdateID={onboardingPBTaskUpdateID}
        playbookAPICall={() => {
          getOnboardingPlaybook();
        }}
      />
    ) : (
      <WhiteCard className="pt-2">
        <p className="black-heading-title text-regular mt-2 mb-0 text-center">
          {playbookAPIError}
        </p>
      </WhiteCard>
    );
  };

  // render playbook services services
  const renderServicesTab = () => {
    return (
      <PlaybookServicesTab
        customerId={customerId}
        playbookTab={playbookTab}
        selectedMarketplaces={selectedMarketplaces}
        selectedTeamMembers={selectedTeamMembers}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        accountType={accountType}
        onCreatePlaybookClick={onCreatePlaybookClick}
        createPlaybookBtnLoader={createPlaybookBtnLoader}
        singleSelectedMarketplaces={singleSelectedMarketplaces}
      />
    );
  };

  // render services playbook
  const servicesPlaybook = () => {
    return (
      <>
        <ServicesPlaybook
          isCustomer={isCustomer}
          customerId={customerId}
          playbookTab={playbookTab}
          playbookTaskData={playbookTaskData}
          playbookId={playbookId}
          isUserAssignedToBP={isUserAssignedToBP}
          selectedMarketplaces={selectedMarketplaces}
          selectedService={selectedService}
          selectedTeamMembers={selectedTeamMembers}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          accountType={accountType}
          onCreatePlaybookClick={onCreatePlaybookClick}
          createPlaybookBtnLoader={createPlaybookBtnLoader}
          updatedServicesOfPlaybookSummary={updatedServicesOfPlaybookSummary}
          setUpdatedServicesOfPlaybookSummary={
            setUpdatedServicesOfPlaybookSummary
          }
          showSummaryPage={showSummaryPage}
          setShowSummaryPage={setShowSummaryPage}
          playbookAPICall={() => {
            getPlaybookTaskAndServices(
              accountType?.value,
              selectedService?.value,
              customStartDate,
              customEndDate,
              selectedTeamMembers,
              selectedMarketplaces,
            );
          }}
          marketplacesList={marketplacesList}
          servicePlaybookErrorMsg={servicePlaybookErrorMsg}
        />
      </>
    );
  };

  // render Playbook Services Details
  const renderPlaybookTabDetails = () => {
    return playbookTab === 'playbook' ? (
      playbookDataLoader ? (
        <PageLoader
          component="performance-graph"
          type="detail"
          width={40}
          height={40}
          color="#FF5933"
        />
      ) : (
        servicesPlaybook()
      )
    ) : playbookTab === 'onboarding' || playbookTab === 'onboarding-gray' ? (
      renderOnboardingPlaybook()
    ) : playbookTab === 'standard-monthly' ||
      playbookTab === 'additional-one-time' ? (
      renderServicesTab()
    ) : null;
  };

  /* ********** main return statement of this component ********** */
  return <>{renderPlaybookTabDetails()}</>;
}

export default PlaybookTabDetails;

PlaybookTabDetails.defaultProps = {
  playbookId: '',
  playbookAPIError: '',
  onboardingPBTaskUpdateID: '',
  isUserAssignedToBP: false,
  playbookDataLoader: false,
  onboardingPlaybookLoader: false,
  dateType: [],
  playbookTaskData: [],
  onboardingPlaybookData: [],
  getOnboardingPlaybook: () => {},
  getPlaybookTaskAndServices: () => {},
  onCreatePlaybookClick: () => {},
  createPlaybookBtnLoader: false,
  updatedServicesOfPlaybookSummary: {},
  setUpdatedServicesOfPlaybookSummary: () => {},
  showSummaryPage: false,
  setShowSummaryPage: () => {},
  marketplacesList: [],
  servicePlaybookErrorMsg: '',
  singleSelectedMarketplaces: {},
};

PlaybookTabDetails.propTypes = {
  playbookId: string,
  playbookAPIError: string,
  onboardingPBTaskUpdateID: string,
  customerId: string.isRequired,
  playbookTab: string.isRequired,
  customStartDate: string.isRequired,
  customEndDate: string.isRequired,
  playbookDataLoader: bool,
  onboardingPlaybookLoader: bool,
  isUserAssignedToBP: bool,
  isCustomer: bool.isRequired,
  getOnboardingPlaybook: func,
  getPlaybookTaskAndServices: func,
  accountType: shape({}).isRequired,
  selectedService: shape({}).isRequired,
  selectedTeamMembers: arrayOf(shape({})).isRequired,
  selectedMarketplaces: arrayOf(shape({})).isRequired,
  playbookTaskData: oneOfType([arrayOf(shape()), object]),
  onboardingPlaybookData: oneOfType([arrayOf(shape()), object]),
  onCreatePlaybookClick: func,
  createPlaybookBtnLoader: bool,
  updatedServicesOfPlaybookSummary: shape({}),
  setUpdatedServicesOfPlaybookSummary: func,
  showSummaryPage: bool,
  setShowSummaryPage: func,
  marketplacesList: arrayOf(shape({})),
  servicePlaybookErrorMsg: string,
  singleSelectedMarketplaces: shape({}),
};
