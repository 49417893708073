import axiosInstance from '../axios';

import {
  API_BILLING_OFF_BOARDING_APPROVE_REJECT,
  API_SUSPEND_AGREEMENT,
} from '../constants';

export const updateBillingOffBoardingApproveReject = async (
  status,
  id = '',
) => {
  const body = {
    request_status: status,
  };
  const result = await axiosInstance
    .patch(`${API_BILLING_OFF_BOARDING_APPROVE_REJECT}/${id}/`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};

export const getCancellationBillingData = async (id) => {
  const result = await axiosInstance
    .get(`${API_SUSPEND_AGREEMENT + id}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
};
