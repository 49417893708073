// rect imports
import React from 'react';

// third party imports
import { string } from 'prop-types';

// local file imports
import AllocateBar from './AllocateBar';

function GrayMessageBar({ className, message, dataCy }) {
  return (
    <AllocateBar className={className || 'mb-3'}>
      <div className="row">
        <div className="col-12">
          <div className="remaing-label" data-cy={dataCy}>
            <span className="orange-dot" />
            {message}
          </div>
        </div>
      </div>
    </AllocateBar>
  );
}

export default GrayMessageBar;

GrayMessageBar.defaultProps = {
  className: '',
  message: null,
  dataCy: '',
};

GrayMessageBar.propTypes = {
  className: string,
  message: string,
  dataCy: string,
};
