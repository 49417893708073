import styled from 'styled-components';
import Theme from '../theme/Theme';

const CardMetrics = styled.div`
  .order-chart-box {
    display: inline-block;
    background-color: ${Theme.white};
    border: 1px solid ${Theme.gray45};
    border-radius: 8px;
    max-width: 197px;
    width: 100%;
    padding: 18px 15px;
    cursor: pointer;

    &.small-metrics-card {
      border: 1px solid ${Theme.gray11};
      padding: 13px 16px;
    }

    &.no-hover {
      &:hover {
        border: 1px solid ${Theme.gray45};
        cursor: auto;
      }
    }

    .chart-name {
      color: ${Theme.gray40};
      font-size: 11px;
      text-transform: uppercase;
      font-family: ${Theme.titleFontFamily};
      padding-bottom: 15px;
    }

    .metric-taget {
      text-align: center;
      .target-percent-capsule {
        font-size: 8px;
        font-family: ${Theme.baseMediumFontFamily};
        background: ${Theme.gray9};
        color: ${Theme.orange};
        text-transform: uppercase;
        padding: 3px 10px;
        border-radius: 20px;
      }
      .under-target {
        border-radius: 1px;
        background: ${Theme.darkRed};
        padding: 6px 10px;
        color: ${Theme.white};
        font-family: ${Theme.titleFontFamily};
        font-size: 10px;
        text-transform: uppercase;
      }
      .on-target {
        border-radius: 1px;
        background: ${Theme.green};
        padding: 6px 10px;
        font-family: ${Theme.titleFontFamily};
        font-size: 10px;
        text-transform: uppercase;
        color: ${Theme.white};
      }
      .underspend {
        border-radius: 1px;
        background: ${Theme.yellow};
        padding: 6px 10px;
        color: ${Theme.white};
        font-family: ${Theme.titleFontFamily};
        font-size: 10px;
        text-transform: uppercase;
      }
    }

    .perentage-value {
      color: ${Theme.lighterGreen};
      font-size: ${Theme.extraNormal};
      font-weight: 300;
      margin-left: -2px;

      &.down {
        color: ${Theme.red};
      }
      .red-arrow {
        width: 14px;
        transform: rotate(180deg);
        vertical-align: middle;
        margin-left: -2px;
        margin-right: 3px;
      }
      .green-arrow {
        width: 14px;
        vertical-align: bottom;
        vertical-align: middle;
        margin-left: -2px;
        margin-right: 3px;
      }

      img {
        width: 14px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border: 1px solid ${Theme.orange};
    }

    &.active {
      border: 1px solid ${Theme.orange};
    }

    &.ad-spend-total-active {
      border: 1px solid #33beffd6;
      .chart-name {
        color: #33beffd6;
      }
    }

    &.ad-spend-ppc-active {
      border: 1px solid #a47b40d6;
      .chart-name {
        color: #a47b40d6;
      }
    }

    &.ad-spend-dsp-active {
      border: 1px solid #52d045d6;
      .chart-name {
        color: #52d045d6;
      }
    }

    &.ad-sales-active {
      border: 1px solid #0045b4;
      .chart-name {
        color: #0045b4;
      }
    }

    &.revenue-organic-active {
      border: 1px solid #74b035;
      .chart-name {
        color: #74b035;
      }
    }

    &.ad-spend-active {
      border: 1px solid #8c54ff;
      .chart-name {
        color: #8c54ff;
      }
    }

    &.ad-conversion-active {
      border: 1px solid #30a8bd;
      .chart-name {
        color: #30a8bd;
      }
    }

    &.impression-active {
      border: 1px solid #d6a307;
      .chart-name {
        color: #d6a307;
      }
    }

    &.ad-cos-active {
      border: 1px solid #e05d37;
      .chart-name {
        color: #e05d37;
      }
    }

    &.ad-roas-active {
      border: 1px solid #89a43c;
      .chart-name {
        color: #89a43c;
      }
    }

    &.ad-click-active {
      border: 1px solid #c84ec6;
      .chart-name {
        color: #c84ec6;
      }
    }

    &.ad-clickrate-active {
      border: 1px solid #a04848;
      .chart-name {
        color: #a04848;
      }
    }

    &.costPerClick-active {
      border: 1px solid #89a43c;
      .chart-name {
        color: #89a43c;
      }
    }

    &.sponsoredAdSpend-active {
      border: 1px solid #89a43c;
      .chart-name {
        color: #89a43c;
      }
    }

    &.dspSpend-active {
      border: 1px solid #0045b4;
      .chart-name {
        color: #0045b4;
      }
    }

    &.sales-adSpend-active {
      border: 1px solid #89a43c;
      .chart-name {
        color: #89a43c;
      }
    }
    &.sales-tacos-active {
      border: 1px solid #00be8e;
      .chart-name {
        color: #00be8e;
      }
    }
  }
  .amc-dashboard-metrics-box {
    padding: 12px;
  }

  @media only screen and (min-width: 1600px) {
    .order-chart-box {
      max-width: 100%;
    }
  }

  @media only screen and (min-width: 320px) {
    .order-chart-box {
      max-width: 100% !important;
    }
  }
`;

export default CardMetrics;
