// react imports
import React from 'react';

// third party imports
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, shape } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// local file imports
import { DesktopTable } from '../../../../../common';
import { TableWrapper } from '../../../../Customer/CustomerDetailView/GoalsAndCOS/GoalsStyle';
import { TaskStatus } from '../../../../Customer/CustomerDetailView/Playbook/PlaybookStyle';
import { InfoRedIcon, InfoIcons } from '../../../../../theme/images';
import AMCAudienceSummaryMobileView from './AMCAudienceSummaryMobileView';
import {
  AMCAudienceSummaryHeaders,
  PATH_CUSTOMER_DETAILS,
} from '../../../../../constants';

function AMCAudienceTable({
  showAMCAudienceLookalike,
  amcAudienceSummaryData,
}) {
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: 767 });
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const isBBEInternalUser = userInfo?.isInternalUser ? 'internal' : 'external';

  // https://bbe.atlassian.net/browse/PDV-9060
  // Adding new column - Audience Expansion Type in dsp audience table header
  const indexofRefreshRate = AMCAudienceSummaryHeaders?.findIndex(
    (item) => item?.key === 'refresh_rate_days',
  );

  let newAMCAudienceSummaryHeaders = [...AMCAudienceSummaryHeaders];
  if (showAMCAudienceLookalike) {
    newAMCAudienceSummaryHeaders = AMCAudienceSummaryHeaders?.toSpliced(
      indexofRefreshRate + 1,
      0,
      {
        label: 'Audience Expansion Type',
        type: 'choice',
        key: 'expansion_type',
      },
    );
  }

  // render progress label className
  const renderProgressLabelClassName = (status) => {
    let className = '';
    switch (status) {
      case 'started':
        className = 'progress-label gray-label';
        break;

      case 'running':
        className = 'progress-label orange-label';
        break;

      case 'pending':
        className = 'progress-label yellow-label';
        break;

      case 'failed':
        className = 'progress-label red-label';
        break;
      case 'successful':
        className = 'progress-label green-label';
        break;

      default:
        break;
    }
    return className;
  };

  // display tooltip data
  const displayTooltipData = (dataTip, dataFor, imgIcon) => {
    return (
      <>
        <img
          className="ml-1 cursor"
          style={{
            verticalAlign: 'text-bottom',
            fontWeight: '300',
          }}
          width="14px"
          src={imgIcon}
          alt="info"
          data-tip={dataTip}
          data-for={dataFor}
        />
        <ReactTooltip
          id={dataFor}
          aria-haspopup="true"
          place="top"
          effect="solid"
          html
        />
      </>
    );
  };

  // display audience status
  const displayStatus = (dspAudienceItem, rowIndex) => {
    return (
      <TaskStatus
        className={`${renderProgressLabelClassName(dspAudienceItem?.status)} `}
        role="presentation"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div>
          {dspAudienceItem?.status || '-'}
          {dspAudienceItem?.status === 'failed'
            ? displayTooltipData(
                dspAudienceItem?.execution_description,
                `${dspAudienceItem?.status}${rowIndex}`,
                InfoRedIcon,
              )
            : ''}
        </div>
      </TaskStatus>
    );
  };

  // get mst time format
  const getMSTTime = (value) => {
    const dateSplitterarray = value?.split('T');
    const timeSplitterarray = dateSplitterarray[1]?.split('-');
    let timeValue = timeSplitterarray?.[0];
    if (timeSplitterarray?.[0]?.includes('.')) {
      timeValue = timeSplitterarray?.[0]?.split('.')?.[0];
    }
    return `${dateSplitterarray?.[0]} T ${timeValue}`;
  };

  // display amc audience data value
  const displayValue = (dspAudienceItem, audienceHeader) => {
    const accountType =
      dspAudienceItem?.account_type === 'Vendor' ? 'vendor' : 'seller';
    if (audienceHeader?.key === 'status') {
      return displayStatus(dspAudienceItem);
    }
    if (audienceHeader?.key === 'bp_name') {
      return (
        <div
          className="cursor"
          role="presentation"
          onClick={() => {
            history.push(
              `${PATH_CUSTOMER_DETAILS.replace(
                ':id',
                dspAudienceItem?.customer,
              )}?tab=performance&sub=${accountType}&nav=amcInsights&subNav=dsp-audience&access=${isBBEInternalUser}`,
              'amcInsights',
            );
          }}
        >
          <div>{dspAudienceItem?.bp_name}</div>
          {dspAudienceItem?.dsp_ad_manager ? (
            <div className="dsp-audience-createdBy-user">
              {dspAudienceItem?.dsp_ad_manager}
            </div>
          ) : (
            ''
          )}
        </div>
      );
    }
    if (audienceHeader?.key === 'instance_id') {
      return dspAudienceItem?.instance_id;
    }
    if (audienceHeader?.key === 'advertiser_id') {
      return dspAudienceItem?.advertiser_id;
    }

    if (audienceHeader?.key === 'query') {
      return dspAudienceItem?.query_detail?.name;
    }

    if (audienceHeader?.key === 'refresh_time_window') {
      return dspAudienceItem?.refresh_time_window === true ? 'True' : 'False';
    }

    if (
      audienceHeader?.key === 'created_at' ||
      audienceHeader?.key === 'time_window_start' ||
      audienceHeader?.key === 'time_window_end'
    ) {
      return (
        <>
          <div>{getMSTTime(dspAudienceItem?.[audienceHeader?.key])}</div>
          {audienceHeader?.key === 'created_at' ? (
            <div className="dsp-audience-createdBy-user">
              <FontAwesomeIcon
                icon="fa-light fa-file-contract"
                className="add-icon ml-2 mr-2"
              />
              created by {dspAudienceItem?.created_by_details?.first_name}{' '}
            </div>
          ) : (
            ''
          )}
        </>
      );
    }

    return <>{dspAudienceItem?.[audienceHeader?.key]}</>;
  };

  // display amc audience table body data
  const displayTableData = (dspAudienceItem) => {
    return (
      <tr>
        {newAMCAudienceSummaryHeaders?.map((audienceHeader, headerIndex) => {
          return (
            <td
              className={
                headerIndex === 0
                  ? 'rh'
                  : headerIndex === newAMCAudienceSummaryHeaders?.length - 1
                  ? 'last-column'
                  : ''
              }
              width="200px"
            >
              {displayValue(dspAudienceItem, audienceHeader)}
            </td>
          );
        })}
      </tr>
    );
  };

  // display amc audience summary table
  const displayTable = () => {
    return (
      <div className="modal-body">
        <TableWrapper>
          <div style={{ overflow: 'auto' }}>
            <DesktopTable
              className="dsp-audience-table"
              style={{ tableLayout: 'fixed' }}
            >
              <thead id="new-dsp-row">
                <tr id="sticky-header" className="gray-border-radius">
                  {newAMCAudienceSummaryHeaders?.map((item, i) => {
                    return (
                      <th
                        width="200px"
                        className={
                          i === 0
                            ? 'th-sticky'
                            : i === newAMCAudienceSummaryHeaders?.length - 1
                            ? 'last-header'
                            : ''
                        }
                      >
                        {item?.label}
                        {item?.info
                          ? displayTooltipData(
                              item?.info,
                              `${item?.label}`,
                              InfoIcons,
                            )
                          : ''}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {amcAudienceSummaryData?.map((dspAudienceItem) => {
                  return displayTableData(dspAudienceItem);
                })}
              </tbody>
            </DesktopTable>
          </div>
        </TableWrapper>
      </div>
    );
  };

  return (
    <div className="mt-3">
      {isDesktop ? (
        displayTable()
      ) : (
        <AMCAudienceSummaryMobileView
          amcAudienceSummaryData={amcAudienceSummaryData}
          AMCAudienceSummaryHeaders={newAMCAudienceSummaryHeaders}
          displayValue={displayValue}
        />
      )}
    </div>
  );
}

export default AMCAudienceTable;

AMCAudienceTable.defaultProps = {
  showAMCAudienceLookalike: false,
  amcAudienceSummaryData: [],
};
AMCAudienceTable.propTypes = {
  showAMCAudienceLookalike: false,
  amcAudienceSummaryData: arrayOf(shape()),
};
