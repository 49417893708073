import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, func, string } from 'prop-types';

import Theme from '../../../theme/Theme';
import { getCustomzedReportData } from '../../../api';

import CustomizedReportsList from './CustomizedReportsList';
import { CustomDropDown } from '../../../common';
import { reportOrderOptions } from '../../../constants';

const CustomizedReportContainer = ({
  selectedBp,
  isUserAssignedToBP,
  searchQuery,
  onEditViewReport,
}) => {
  const mounted = useRef(false);
  const [reportsLoader, setReportsLoader] = useState(false);
  const [customizedReportsData, setCustomizedReportsData] = useState([]);
  const [customizedReportsCount, setCustomizedReportsCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [selectedReportOrder, setSelectedReportOrder] = useState({
    value: '-created_at',
    label: 'Most Recent to Oldest',
  });

  const onGetReportsData = useCallback(
    (page = 1) => {
      setReportsLoader(true);
      setCustomizedReportsData([]);
      setCustomizedReportsCount(0);
      getCustomzedReportData(
        page,
        searchQuery,
        selectedReportOrder?.value,
      ).then((res) => {
        if (mounted.current) {
          if (res && res.status === 400) {
            setReportsLoader(false);
          }
          if (res && res.status === 200) {
            if (res.data && res.data.results) {
              setCustomizedReportsData(res.data.results);
              setCustomizedReportsCount(res.data.count);
            } else {
              setCustomizedReportsData([]);
            }
          }
          setReportsLoader(false);
        }
      });
    },
    [searchQuery, selectedReportOrder],
  );

  useEffect(() => {
    mounted.current = true;

    onGetReportsData(1);
    return () => {
      mounted.current = false;
    };
  }, [onGetReportsData]);

  const handlePageChange = (currentPage) => {
    onGetReportsData(currentPage);
    setPageNumber(currentPage);
  };

  return (
    <>
      <ActivityReports>
        <div className="row mb-3">
          <div className="col-5 d-md-block d-none">
            <div className="black-heading-title mt-2">Activity Reports</div>
          </div>

          <div className="col-md-7 col-12 text-right">
            <ul className="rep-activity">
              <span>Sort by</span>
              <li className="sorted-by ml-2">
                <CustomDropDown
                  id="BT-ReportOrderDropdown"
                  className="cursor w-100"
                  inputColor={Theme.black}>
                  <Select
                    classNamePrefix="react-select"
                    className="active"
                    options={reportOrderOptions}
                    defaultValue={reportOrderOptions && reportOrderOptions[0]}
                    value={selectedReportOrder}
                    onChange={(event) => {
                      setPageNumber(1);
                      setSelectedReportOrder(event);
                    }}
                    placeholder={
                      reportOrderOptions &&
                      reportOrderOptions[0] &&
                      reportOrderOptions[0].label
                    }
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        neutral50: '#fff',
                        primary25: Theme.lightOrange,
                        primary: Theme.orange,
                        primary50: Theme.lightOrange,
                      },
                    })}
                  />
                </CustomDropDown>{' '}
              </li>
              <li>
                <RefreshButton
                  onClick={() => {
                    onGetReportsData(1);
                    setPageNumber(1);
                  }}>
                  <div className="refresh-icon cursor" role="presentation">
                    <FontAwesomeIcon
                      icon="fa-light fa-arrows-rotate"
                      fontSize="14px"
                    />
                  </div>
                </RefreshButton>
              </li>
            </ul>
          </div>
        </div>
        <CustomizedReportsList
          loader={reportsLoader}
          data={customizedReportsData}
          count={customizedReportsCount}
          pageNumber={pageNumber}
          handlePageChange={handlePageChange}
          selectedBp={selectedBp}
          isUserAssignedToBP={isUserAssignedToBP}
          onEditView={onEditViewReport}
          reportTableAPICall={() => {
            onGetReportsData(pageNumber);
          }}
        />
      </ActivityReports>
    </>
  );
};

export default CustomizedReportContainer;

CustomizedReportContainer.defaultProps = {
  searchQuery: '',
  onEditViewReport: () => {},
  isUserAssignedToBP: false,
};

CustomizedReportContainer.propTypes = {
  selectedBp: string.isRequired,
  isUserAssignedToBP: bool,
  searchQuery: string,
  onEditViewReport: func,
};

const RefreshButton = styled.div`
  .refresh-icon {
    border-radius: 6px;
    width: 40px;
    text-align: center;
    height: 40px;
    padding: 9px 7px;
    border: 1px solid #bfc5d2;
    color: ${Theme.black};
    border: 1px solid ${Theme.gray25};
    .refresh-arrow-icon {
      vertical-align: text-top;
      margin-right: 4px;
    }
  }
`;

const ActivityReports = styled.div`
  .rep-activity {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: inline-block;
      margin-right: 15px;
      max-width: 200px;
      width: 100%;

      &:last-child {
        margin-right: 0;
        vertical-align: top;
        max-width: fit-content;
      }
      &.sorted-by {
        .jIDBKP {
          width: 152px !important;
        }
      }
      @media only screen and (max-width: 767px) {
        max-width: inherit;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .rep-activity {
      display: flex;
      li {
        &.sorted-by {
          width: 99% !important;
          .jIDBKP {
            width: 99% !important;
          }
        }
      }
    }
  }
`;
