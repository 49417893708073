import React, { useState, useEffect, useCallback } from 'react';

import Modal from 'react-modal';
import Select, { components } from 'react-select';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { shape, string, arrayOf } from 'prop-types';

import Theme from '../../../../../theme/Theme';
import BillingContactList from './BillingContactList';
import {
  SpreetailRole,
  billingAddress,
  paymentTermValueLabel,
} from '../../../../../constants';
import {
  CloseIcon,
  EditOrangeIcon,
  helpCircleIcon,
} from '../../../../../theme/images';
import {
  getBillingDetails,
  getPaymentTermsDetails,
  getPaymentTermsOptions,
  saveBillingInfo,
  saveDSPContact,
  savePaymentTerms,
} from '../../../../../api';
import {
  Button,
  InputFormField,
  ErrorMsg,
  ModalBox,
  PageLoader,
  WhiteCard,
  CustomDropDown,
  DropdownIndicator,
  ErrorMsgBox,
} from '../../../../../common';

export default function BillingDetails({
  id,
  userInfo,
  onBoardingId,
  customerStatus,
  memberData,
}) {
  const { Option, SingleValue } = components;

  // https://bbe.atlassian.net/browse/PDV-9155 - Removing hard coded user emails and integrating dynamic access to edit payment terms
  const hasEditPaymentTermsAccess =
    ['BGS Admin', 'Finance'].includes(userInfo?.role) ||
    userInfo?.payment_term_team;

  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [showModal, setShowModal] = useState({ type: '', isOpen: false });
  const [showPaymentTermsModal, setShowPaymentTermsModal] = useState(false);
  const [apiError, setApiError] = useState({});
  const [formData, setFormData] = useState({});
  const [paymentTermsData, setPaymentTermsData] = useState([]);
  const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
  const [paymentTermsValue, setPaymentTermsValue] = useState([]);
  const [showBtn, setShowBtn] = useState(false);
  const [showDSPModal, setShowDSPModal] = useState(false);
  const [showDSPErrorTostr, setShowDSPErrorTostr] = useState(false);
  const [isAllowToEdit, setIsAllowToEdit] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [additionContactFormData, setAdditionContactFormData] = useState({});

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      minHeight: '200px',
      overlay: ' {zIndex: 1000}',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const renderDspToolTipInfo = `The payment terms you select here will only apply to one-time DSP invoices.</br>
  (Standard monthly DSP invoices have default payment terms which cannot be changed.)`;

  const billingDetails = useCallback(() => {
    setIsLoading({ loader: true, type: 'page' });
    getBillingDetails(id).then((response) => {
      setData(response?.data);
      setIsLoading({ loader: false, type: 'page' });
      setFormData({
        billing_contact: response?.data?.billing_contact?.[0],
        billing_address: response?.data?.billing_address?.[0],
      });
    });
  }, [id]);
  const getPaymentTerms = useCallback(() => {
    setIsLoading({ loader: true, type: 'page' });
    getPaymentTermsDetails(id).then((response) => {
      setIsLoading({ loader: false, type: 'page' });
      setPaymentTermsData(response?.data?.results);
    });
    getPaymentTermsOptions(id).then((response) => {
      setIsLoading({ loader: false, type: 'page' });
      setPaymentTermsOptions(response?.data);
    });
  }, [id]);

  // https://bbe.atlassian.net/browse/PDV-9155 - Removing hard coded user emails and integrating dynamic access to edit payment terms
  // use effect for edit payment term access
  useEffect(() => {
    if (hasEditPaymentTermsAccess) {
      setIsAllowToEdit(true);
    } else {
      for (const user of memberData) {
        if (user.user) {
          if (
            (user?.role_group?.name === 'BGS Manager' ||
              user?.role_group?.name === 'BGS') &&
            user?.user?.id === userInfo?.id
          ) {
            setIsAllowToEdit(true);
            break;
          }
        }
      }
    }
  }, [hasEditPaymentTermsAccess, memberData, userInfo]);

  useEffect(() => {
    billingDetails();
    getPaymentTerms();
  }, [billingDetails, getPaymentTerms]);

  const mapDefaultValues = (type, key) => {
    if (type === 'dsp_contact') {
      return key;
    }

    if (data?.[type]?.[0]) {
      return data[type][0][key];
    }
    return '';
  };
  const renderPaymentTermLabel = (value) => {
    return paymentTermValueLabel.filter((field) => field.value === value)[0]
      ?.label !== undefined ? (
      paymentTermValueLabel.filter((field) => field.value === value)[0].label
    ) : (
      <div style={{ textTransform: 'uppercase' }}>{value}</div>
    );
  };

  const mapPaymentTermsDefaultValues = (type, label) => {
    const value = paymentTermsData.filter((op) => op.invoice_type === type);
    return value?.length ? (
      <div className="col-6">
        {label === 'Dsp' ? (
          <>
            <div className="label mt-3">
              DSP (one-time)
              <img
                src={helpCircleIcon}
                alt="helpCircleIcon"
                style={{
                  width: '14px',
                  verticalAlign: 'middle',
                  paddingBottom: '3px',
                }}
                data-tip={renderDspToolTipInfo}
                data-for="dspAdditionalInfo"
              />
            </div>
            <ReactTooltip
              id="dspAdditionalInfo"
              aria-haspopup="true"
              place="bottom"
              effect="solid"
              html
            />
          </>
        ) : (
          <div className="label mt-3">{label}</div>
        )}
        <div className="label-info">
          {renderPaymentTermLabel(value?.[0]?.payment_term)}
        </div>
      </div>
    ) : null;
  };

  const handleChange = (event, item, type) => {
    setShowBtn(true);
    setFormData({
      ...formData,
      [type]: {
        ...formData[type],
        [item.key]: event.target.value,
      },
    });
    setApiError({
      ...apiError,
      [type]: {
        ...apiError[type],
        [item.key]: '',
        0: {
          [item.key]: '',
        },
      },
      0: '',
    });
  };
  const handlePaymentTermChange = (event, type) => {
    setShowBtn(true);
    setPaymentTermsValue([
      ...paymentTermsValue,
      {
        invoice_type: type,
        payment_term: event.value,
      },
    ]);
  };

  const generateNumeric = (item, type) => {
    return (
      <NumberFormat
        format={item.format}
        className="form-control"
        onChange={(event) => handleChange(event, item, type)}
        placeholder={`Enter ${item.label}`}
        defaultValue={formData?.[type]?.[item.key]}
        isNumericString
      />
    );
  };

  const generateInput = (item, type) => {
    return (
      <input
        className="form-control"
        placeholder={`Enter ${item.label}`}
        type={item.type}
        defaultValue={data?.[type]?.[0]?.[item.key]}
        onChange={(event) => handleChange(event, item, type)}
        maxLength={item.key === 'postal_code' ? 10 : ''}
      />
    );
  };

  const mapContactDetails = (type) => {
    return (
      <div className="row">
        {billingAddress
          .filter((op) => op.section === 'contact')
          .map((item) => {
            return (
              <div
                className={type === 'dsp' ? 'col-md-12' : 'col-md-6'}
                key={item.key}
              >
                <InputFormField className="mt-3">
                  <label htmlFor={item.label}>
                    {item.label}
                    <br />
                    {item.type === 'number' ? (
                      <>{generateNumeric(item, 'billing_contact')}</>
                    ) : (
                      <>{generateInput(item, 'billing_contact')}</>
                    )}
                  </label>
                </InputFormField>
                <ErrorMsg>
                  {apiError?.billing_contact?.[0]?.[item.key]?.[0]}
                </ErrorMsg>
              </div>
            );
          })}
      </div>
    );
  };

  const mapAddressDetails = () => {
    return (
      <div className="row">
        {billingAddress
          .filter((op) => op.section === 'address')
          .map((item) => {
            return (
              <div
                className="col-md-6"
                key={item.key}
                style={{ opacity: item.key === 'email' ? 0.5 : '' }}
              >
                <InputFormField className="mt-3">
                  <label htmlFor={item.label}>
                    {item.label}
                    <br />
                    {item.type === 'number' ? (
                      <>{generateNumeric(item, 'billing_address')}</>
                    ) : (
                      <>{generateInput(item, 'billing_address')}</>
                    )}
                  </label>
                </InputFormField>
                <ErrorMsg>
                  {apiError?.billing_address?.[item.key]?.[0]}
                </ErrorMsg>
              </div>
            );
          })}
      </div>
    );
  };

  const getOptions = () => {
    const options = paymentTermsOptions.filter((op) => op.value !== 'standard');
    return options;
  };

  const dropdownSingleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props.data.label}</span>

      <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
    </SingleValue>
  );

  const dropdownFilterOption = (props) => (
    <Option {...props}>
      <div>
        <span style={{ fontSize: '14px' }}>{props.data.label}</span>
        <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
      </div>
    </Option>
  );

  const getDropdDownComponents = () => {
    return {
      Option: dropdownFilterOption,
      SingleValue: dropdownSingleFilterOption,
      DropdownIndicator,
    };
  };

  const generateDropdown = (type) => {
    const value = paymentTermsData.filter((op) => op.invoice_type === type);
    const invoiceType = value?.length ? value[0].invoice_type : null;
    const paymentTerm = value?.length ? value[0].payment_term : null;
    return (
      <Select
        classNamePrefix="react-select"
        placeholder={
          value?.length
            ? renderPaymentTermLabel(value?.[0]?.payment_term)
            : 'Select the terms'
        }
        defaultValue={paymentTerm}
        options={getOptions()}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: '#fff',
            primary25: Theme.lightOrange,
            primary: Theme.orange,
            primary50: Theme.lightOrange,
          },
        })}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          option: (provided) => ({
            ...provided,
            color: 'black',
          }),
        }}
        menuPortalTarget={document.body}
        name={invoiceType}
        components={getDropdDownComponents()}
        onChange={(event) => handlePaymentTermChange(event, type)}
      />
    );
  };

  const mapPaymentTermsModalDetails = (type, label) => {
    const paymentTerm = paymentTermsData?.filter(
      (item) => item?.invoice_type === type,
    );
    return paymentTerm?.length ? (
      <div className="col-12" key={paymentTerm[0].invoice_type}>
        <div className="label mt-3">
          {label}
          {paymentTerm[0].invoice_type === 'dsp service' ? (
            <img
              src={helpCircleIcon}
              alt="helpCircleIcon"
              style={{
                width: '14px',
                verticalAlign: 'middle',
                paddingBottom: '3px',
              }}
              data-tip={renderDspToolTipInfo}
              data-for="dspAdditionalInfo"
            />
          ) : null}
        </div>
        <ReactTooltip
          id="dspAdditionalInfo"
          aria-haspopup="true"
          place="bottom"
          effect="solid"
          html
        />
        <CustomDropDown id="billingDetail">
          {generateDropdown(paymentTerm[0].invoice_type)}
        </CustomDropDown>
      </div>
    ) : null;
  };

  const saveBillingData = () => {
    setIsLoading({ loader: true, type: 'button' });
    if (showDSPModal && formData.dsp_contact) {
      saveDSPContact(
        { ...formData.dsp_contact, customer: id },
        formData?.dsp_contact?.id,
      ).then((res) => {
        if (res?.status === 400) {
          setApiError({ ...apiError, dsp_contact: res.data });
          if (
            res.data?.first_name?.[0].includes(
              'This field may not be blank.',
            ) ||
            res.data?.last_name?.[0].includes('This field may not be blank.') ||
            res.data?.email?.[0].includes('This field may not be blank.') ||
            res.data?.first_name?.[0].includes('This field is required.') ||
            res.data?.last_name?.[0].includes('This field is required.') ||
            res.data?.email?.[0].includes('This field is required.')
          ) {
            setShowDSPErrorTostr(true);
            setTimeout(() => {
              setShowDSPErrorTostr(false);
            }, 4000);
          } else setShowDSPErrorTostr(false);

          setIsLoading({ loader: false, type: 'button' });
        }
        if (res?.status === 201 || res?.status === 200) {
          setShowDSPModal(false);
          setIsLoading({ loader: false, type: 'button' });
        }
      });
    } else {
      let details = {
        customer_onboarding:
          data?.customer_onboarding ||
          userInfo.customer_onboarding ||
          onBoardingId,
      };
      if (
        formData?.billing_address &&
        Object.keys(formData.billing_address).length !== 0
      ) {
        details = {
          ...details,
          ...formData,
          billing_address: formData?.billing_address,
        };
      }
      if (
        formData?.billing_contact &&
        Object.keys(formData.billing_contact).length !== 0
      ) {
        const billingContact = formData?.billing_contact || null;
        if (billingContact) {
          billingContact.primary_contact = true;
        }
        details = {
          ...details,
          ...formData,
          billing_contact: [billingContact],
        };
      }

      saveBillingInfo(
        details,
        formData?.old_billinginfo_id ? null : data?.id,
      ).then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setIsLoading({ loader: false, type: 'button' });
          billingDetails();
          setShowModal({ type: '', isOpen: false });
          setShowBtn(false);
        }
        if (res?.status === 400) {
          setIsLoading({ loader: false, type: 'button' });
          setApiError(res?.data);
        }
      });
    }
  };

  const savePaymentTermsData = () => {
    setIsLoading({ loader: true, type: 'button' });
    savePaymentTerms(paymentTermsValue, id).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setIsLoading({ loader: false, type: 'button' });
        getPaymentTerms();
        setShowModal({ type: '', isOpen: false });
        setShowPaymentTermsModal(false);
        setShowBtn(false);
        toast.success('You have successfully changed your payment terms');
      }
      if (res?.status === 400) {
        setIsLoading({ loader: false, type: 'button' });
        setApiError(res && res.data);
      }
    });
  };

  const renderPaymentTermsPanel = () => {
    return (
      <WhiteCard>
        <p className="black-heading-title mt-0 mb-0">Payment Terms</p>
        {isAllowToEdit ? (
          <div
            className="edit-details"
            role="presentation"
            onClick={() => setShowPaymentTermsModal(true)}
          >
            <img src={EditOrangeIcon} alt="" />
            Edit
          </div>
        ) : (
          ''
        )}
        <div className="row">
          {mapPaymentTermsDefaultValues('retainer', 'Monthly Retainer')}
          {mapPaymentTermsDefaultValues('rev share', 'Revenue share')}
          {mapPaymentTermsDefaultValues('dsp service', 'Dsp')}
          {mapPaymentTermsDefaultValues('upsell', 'Upsells')}
          {mapPaymentTermsDefaultValues('amc service', 'AMC')}
        </div>
      </WhiteCard>
    );
  };

  const renderBillingAddressPanel = () => {
    return (
      <WhiteCard className="mt-3">
        <p className="black-heading-title mt-0 mb-0">Billing Address</p>

        {!isSpreetailUser && customerStatus?.value !== 'pending' ? (
          <div
            className="edit-details"
            role="presentation"
            onClick={() =>
              setShowModal({ type: 'billingAddress', isOpen: true })
            }
          >
            <img src={EditOrangeIcon} alt="" />
            Edit
          </div>
        ) : null}
        <div className="row">
          <div className="col-6">
            {' '}
            <div className="label mt-3">Address </div>
            <div className="label-info">
              {mapDefaultValues('billing_address', 'address')}
            </div>
          </div>
          <div className="col-6">
            <div className="label mt-3">State</div>
            <div className="label-info">
              {mapDefaultValues('billing_address', 'state')}
            </div>
          </div>

          <div className="col-6">
            <div className="label mt-3">City </div>
            <div className="label-info">
              {mapDefaultValues('billing_address', 'city')}
            </div>
          </div>
          <div className="col-6">
            <div className="label mt-3">Postal Code</div>
            <div className="label-info">
              {mapDefaultValues('billing_address', 'postal_code')}
            </div>
          </div>
        </div>
      </WhiteCard>
    );
  };

  const renderBillingContactsPanel = () => {
    return (
      <>
        <BillingContactList
          id={id}
          data={data}
          userInfo={userInfo}
          onBoardingId={onBoardingId}
          showContactModal={showContactModal}
          setShowContactModal={setShowContactModal}
          setData={setData}
          setAdditionContactFormData={setAdditionContactFormData}
          additionContactFormData={additionContactFormData}
        />
      </>
    );
  };

  const renderPaymentTermsEditModal = () => {
    return (
      <Modal
        isOpen={showPaymentTermsModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            setShowPaymentTermsModal(false);
            setShowBtn(false);
            setApiError({});
          }}
          role="presentation"
        />
        <ModalBox>
          <div className="modal-body">
            <h4>Payment Terms</h4>
            <div className="body-content mt-3 px-1">
              <div className="row">
                {mapPaymentTermsModalDetails('retainer', 'Monthly Retainer')}
                {mapPaymentTermsModalDetails('rev share', 'Revenue share')}
                {mapPaymentTermsModalDetails('dsp service', 'Dsp (one-time)')}
                {mapPaymentTermsModalDetails('upsell', 'Upsells')}
                {mapPaymentTermsModalDetails('amc service', 'AMC')}
              </div>
            </div>
          </div>

          <div className="footer-line " />
          <div className="modal-footer">
            <Button
              className=" btn-primary mr-4 w-100"
              onClick={() => savePaymentTermsData()}
            >
              {isLoading.loader && isLoading.type === 'button' ? (
                <PageLoader color={Theme.white} type="button" />
              ) : (
                'Save Changes'
              )}
            </Button>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  const renderDSPContactModal = () => {
    return (
      <Modal
        isOpen={showModal?.isOpen || showDSPModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            setShowModal({ type: '', isOpen: false });
            setShowBtn(false);
            setApiError({});
            setShowDSPModal(false);
            billingDetails();
          }}
          role="presentation"
        />
        <ModalBox>
          {' '}
          <div className="modal-body">
            {showDSPModal ? (
              <>
                <h4>Edit DSP Contact</h4>
                <div className="straight-line horizontal-line mt-3" />
                {showDSPErrorTostr ? (
                  <ErrorMsgBox
                    className="danger mt-3"
                    style={{ textAlign: 'center' }}
                  >
                    You need to fill out all required fields before submitting
                    the DSP Contact.
                  </ErrorMsgBox>
                ) : null}
                {mapContactDetails('dsp')}
              </>
            ) : (
              <>
                <h4>Payment Details</h4>
                <div className="body-content  ">
                  <ErrorMsg style={{ textAlign: 'center' }}>
                    {apiError && apiError[0]}
                  </ErrorMsg>
                  <div className="straight-line horizontal-line  mt-3 mb-3" />

                  {showModal?.type === 'billingAddress' ? (
                    <>
                      <h4>Billing Address</h4>
                      {mapAddressDetails()}
                    </>
                  ) : null}
                  {showModal?.type === 'billingContact' ? (
                    <>
                      <h4>Billing Contact</h4>
                      {mapContactDetails('billing')}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
          {showBtn ? (
            <>
              <div className="footer-line " />
              <div className=" col-12  modal-footer">
                <Button
                  className=" btn-primary mr-4"
                  onClick={() => saveBillingData()}
                >
                  {isLoading.loader && isLoading.type === 'button' ? (
                    <PageLoader color={Theme.white} type="button" />
                  ) : (
                    'Confirm'
                  )}
                </Button>
                <Button
                  className=" btn-borderless"
                  onClick={() => {
                    setShowModal({ type: '', isOpen: false });
                    setShowBtn(false);
                    setApiError({});
                    setShowDSPModal(false);
                    billingDetails();
                  }}
                >
                  Discard Changes
                </Button>
              </div>
            </>
          ) : (
            ''
          )}
        </ModalBox>
      </Modal>
    );
  };

  return (
    <>
      {' '}
      {isLoading.loader && isLoading.type === 'page' ? (
        <PageLoader
          component="agrement-details"
          type="detail"
          color={Theme.orange}
          width={40}
          height={40}
        />
      ) : (
        <div className="mt-4">
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-3">
              {paymentTermsData?.length ? renderPaymentTermsPanel() : ''}
              {renderBillingAddressPanel()}
            </div>
            {renderBillingContactsPanel()}
          </div>
        </div>
      )}
      {renderDSPContactModal()}
      {/* payment terms edit modal */}
      {renderPaymentTermsEditModal()}
    </>
  );
}

BillingDetails.defaultProps = {
  onBoardingId: null,
  customerStatus: null,
  memberData: [],
  data: {},
};

BillingDetails.propTypes = {
  id: string.isRequired,
  userInfo: shape({
    customer_onboarding: string,
  }).isRequired,
  onBoardingId: string,
  customerStatus: shape({}),
  memberData: arrayOf(shape({})),
  data: shape({
    label: string,
    sub: string,
  }),
};
