import React from 'react';

import dayjs from 'dayjs';
import { InnerCollapseRow } from './PlaybookStyle';

function PlaybookSummaryDeliverableCollapse({
  deliverableData,
  marketplacesList,
  setMonthDropdownValue,
}) {
  // render  playbook task data
  const renderPlaybookSummaryDeliverableData = () => {
    return (
      <>
        <InnerCollapseRow
          key={deliverableData?.id}
          className="checkbox-column-table "
        >
          <td>
            <span> </span>
          </td>
          <td
            width="40%"
            className={`${
              deliverableData?.sub_tasks?.length ? 'cursor' : null
            } `}
            role="presentation"
          >
            <span className="curve-line curve-line-parent" />

            <span className="pl-2">{deliverableData?.task_name}</span>
          </td>

          <td width="15%">
            {
              marketplacesList?.find(
                (marketplace) =>
                  marketplace?.value === deliverableData?.marketplace,
              )?.label
            }
          </td>
          <td width="15%">{setMonthDropdownValue()?.label}</td>
          <td width="15%">
            {dayjs(deliverableData?.start_date).format('MMM')}
          </td>
          <td width="15%">{deliverableData?.team}</td>
        </InnerCollapseRow>
      </>
    );
  };
  return renderPlaybookSummaryDeliverableData();
}

export default PlaybookSummaryDeliverableCollapse;
