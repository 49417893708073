/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import $ from 'jquery';
import axios from 'axios';
import queryString from 'query-string';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { string, shape, bool, func, number, arrayOf } from 'prop-types';

import { userMe } from '../../store/actions';
import {
  OnBoardingBody,
  InputFormField,
  Button,
  PageLoader,
  ErrorMsg,
} from '../../common';
import {
  askSomeoneData,
  updateAskSomeoneData,
  updateUserMe,
  saveBillingInfo,
} from '../../api';
import {
  PATH_SUMMARY,
  PATH_THANKS,
  stepPath,
  billingInformation,
} from '../../constants';
import AdditionalBillingContact from './AdditionalBillingContact';

export default function BillingInfo({
  setIsLoading,
  assignedToSomeone,
  stepData,
  verifiedStepData,
  userInfo,
  isLoading,
  data,
  isChecked,
  summaryData,
  summaryDetails,
  showDSPContact,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = queryString.parse(history.location.search);
  const [formData, setFormData] = useState({});
  const [apiError, setApiError] = useState({});
  const [contactApiErrors, setContactApiErrors] = useState({});

  const getIncompleteStep = summaryData.find(
    (op) =>
      Object.keys(op)[0] !== 'billing information' &&
      Object.values(op)[0] === false,
  );

  const CheckStep = (step) => {
    if (step === 'amazon account' || getIncompleteStep === undefined) {
      history.push(PATH_SUMMARY);
    } else {
      for (const item of stepPath) {
        if (Object.keys(getIncompleteStep)[0] === item.key) {
          history.push(item.view);
        }
      }
    }
  };

  useEffect(() => {
    $('.checkboxes input:checkbox').prop(
      'checked',
      showDSPContact.sameAsBilling || true,
    );

    setFormData({
      ...formData,
      billing_address: data?.billing_address?.[0] || {},
      billing_contact: data?.billing_contact?.length
        ? data?.billing_contact
        : [],
    });
  }, [data]);

  const saveDetails = () => {
    setIsLoading({ loader: true, type: 'button' });
    if (
      (stepData === undefined ||
        (stepData && Object.keys(stepData)?.length === 0)) &&
      verifiedStepData &&
      Object.keys(verifiedStepData)?.length === 0
    ) {
      const detail = {
        is_completed: true,
        email: userInfo.email,
        step: 'billing information',
        customer_onboarding: userInfo.customer_onboarding,
      };
      askSomeoneData(detail).then((stepResponse) => {
        if (stepResponse?.status === 201) {
          if (assignedToSomeone) {
            const stringified = queryString?.stringify({
              name: verifiedStepData.user_name,
            });
            history.push({
              pathname: PATH_THANKS,
              search: `${stringified}`,
            });
          } else {
            CheckStep('billing information');
          }
          updateUserMe(userInfo.id || verifiedStepData.user_id, {
            step: {
              ...(userInfo.step || verifiedStepData.user_step),
              [userInfo.customer || verifiedStepData.customer_id]: 3,
            },
          }).then((user) => {
            if (user?.status === 200) {
              if (assignedToSomeone) {
                localStorage.removeItem('match');
              } else dispatch(userMe());
            }
          });
          setIsLoading({ loader: false, type: 'button' });
        }
      });
    } else {
      updateAskSomeoneData(stepData?.id || verifiedStepData.step_id, {
        token: assignedToSomeone ? params?.key : '',
        is_completed: true,
      }).then((response) => {
        if (response?.status === 200) {
          if (assignedToSomeone) {
            const stringified = queryString?.stringify({
              name: verifiedStepData.user_name,
            });
            history.push({
              pathname: PATH_THANKS,
              search: `${stringified}`,
            });
          } else {
            CheckStep('billing information');
          }
          updateUserMe(userInfo.id || verifiedStepData.user_id, {
            step: {
              ...(userInfo.step || verifiedStepData.user_step),
              [userInfo.customer || verifiedStepData.customer_id]: 3,
            },
          }).then((user) => {
            if (user?.status === 200) {
              if (assignedToSomeone) {
                localStorage.removeItem('match');
              } else dispatch(userMe());
            }
          });
          setIsLoading({ loader: false, type: 'button' });
        }
      });
    }
    summaryDetails(
      userInfo.customer_onboarding || verifiedStepData.customer_onboarding_id,
    );
  };

  const checkIfContactAlreadyPresent = () => {
    if (formData?.billing_contact?.length) {
      const id = formData?.billing_contact?.filter((item) => item?.id);
      return id;
    }
    return null;
  };

  const saveBillingData = () => {
    setIsLoading({ loader: true, type: 'button' });

    if (formData?.billing_contact?.length) {
      // eslint-disable-next-line no-unused-vars
      const deletedMobileNumber = formData?.billing_contact?.map((contact) =>
        contact?.phone_number === '' || contact?.phone_number === null
          ? delete contact.phone_number
          : contact.phone_number,
      );
    }

    let details = {
      ...formData,
      billing_address: formData.billing_address,
      billing_contact: formData.billing_contact,
      customer_onboarding:
        userInfo.customer_onboarding || verifiedStepData.customer_onboarding_id,
    };

    let newContactDataDetails;
    if (data?.id && formData?.billing_contact?.length) {
      const newContactData = formData?.billing_contact?.filter(
        (contact) => contact.uniqueKey,
      );

      newContactDataDetails = {
        ...formData,
        billing_address: formData.billing_address,
        billing_contact: newContactData,
        customer_onboarding:
          userInfo.customer_onboarding ||
          verifiedStepData.customer_onboarding_id,
      };

      const oldContactData = formData?.billing_contact?.filter(
        (contact) => !contact.uniqueKey,
      );

      details = {
        ...formData,
        billing_contact: oldContactData,
      };
    }

    axios.all([saveBillingInfo(details, data?.id || null, '')]).then(
      axios.spread((...res) => {
        const emptyObj = {};
        if (
          res?.[0]?.status === 400 &&
          newContactDataDetails?.billing_contact?.length
        ) {
          saveBillingInfo(
            newContactDataDetails,
            res?.data?.id,
            'newContactDataDetails',
          ).then((response1) => {
            if (response1?.status === 400) {
              let contact1 = {};

              if (res?.[0]?.data?.billing_contact?.length) {
                contact1 = [];
                for (
                  let i = 0;
                  i < res?.[0]?.data?.billing_contact?.length;
                  // eslint-disable-next-line no-plusplus
                  i++
                ) {
                  contact1.push(emptyObj);
                }
                const tempContact1 = response1?.data?.billing_contact;
                contact1 = [...contact1, ...tempContact1];
                setContactApiErrors({
                  billing_contact: contact1,
                });
              } else {
                contact1 = response1?.data?.billing_contact;
                setContactApiErrors({
                  billing_contact: contact1,
                });
              }
            }
          });
        }
        if (res?.[0]?.status === 201 || res?.[0]?.status === 200) {
          if (newContactDataDetails?.billing_contact?.length) {
            saveBillingInfo(
              newContactDataDetails,
              res?.data?.id,
              'newContactDataDetails',
            ).then((response) => {
              if (response?.status === 201) {
                saveDetails();
                if (assignedToSomeone) {
                  const stringified = queryString?.stringify({
                    name: verifiedStepData.user_name,
                  });
                  history.push({
                    pathname: PATH_THANKS,
                    search: `${stringified}`,
                  });
                } else {
                  CheckStep('billing information');
                }
              }
              // for error binding
              if (response?.status === 400) {
                let contact = [];

                setIsLoading({ loader: false, type: 'button' });
                const existingContacts =
                  formData?.billing_contact?.length &&
                  formData?.billing_contact?.filter((item) => item?.id);
                if (existingContacts?.length) {
                  // eslint-disable-next-line no-plusplus
                  for (let i = 0; i < existingContacts?.length; i++) {
                    contact.push(emptyObj);
                  }
                  const newError = response?.data?.billing_contact;
                  contact = [...contact, ...newError];
                  setApiError({
                    ...apiError,
                    billing_contact: contact,
                  });
                } else {
                  contact = response?.data?.billing_contact;
                  setApiError({
                    ...apiError,
                    billing_contact: contact,
                  });
                }
                // }
              }
            });
          } else {
            saveDetails();
            if (assignedToSomeone) {
              const stringified = queryString?.stringify({
                name: verifiedStepData.user_name,
              });
              history.push({
                pathname: PATH_THANKS,
                search: `${stringified}`,
              });
            } else {
              CheckStep('billing information');
            }
          }
        }

        if (res?.[0]?.status === 400) {
          let address = {};
          let contact = {};
          address = res?.[0]?.data?.billing_address;
          contact = res?.[0]?.data?.billing_contact;

          setIsLoading({ loader: false, type: 'button' });
          setApiError({
            billing_address: address,
            billing_contact: contact,
          });

          const uniqueId = checkIfContactAlreadyPresent();
          if (uniqueId?.length) {
            setApiError({
              billing_address: address,
              billing_contact: contact,
            });
          } else {
            setContactApiErrors({
              billing_contact: contact,
            });
          }

          if (
            (res?.data && res.data.billing_address) ||
            (res?.data && res.data[0])
          ) {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          }
        }
      }),
    );
  };

  const handleChange = (event, item, type) => {
    setFormData({
      ...formData,
      [type]: {
        ...formData[type],
        [item.key]: event.target.value,
      },
    });

    setApiError({
      ...apiError,
      [type]: {
        ...apiError[type],
        [item.key]: '',
      },
      0: '',
    });
    if (showDSPContact.sameAsBilling && type === 'billing_contact') {
      setFormData({
        ...formData,
        billing_contact: {
          ...formData.billing_contact,
          [item.key]: event.target.value,
        },
      });
    }
  };

  const generateNumeric = (item, type) => {
    return (
      <NumberFormat
        format={item.format}
        className="form-control"
        onChange={(event) => handleChange(event, item, type)}
        placeholder={`Enter ${item.label}`}
        value={formData?.[type]?.[item.key]}
        isNumericString
      />
    );
  };

  const generateInput = (item, type) => {
    return (
      <input
        className="form-control"
        placeholder={`Enter ${item.label}`}
        type={item.type}
        defaultValue={
          formData?.[type]?.[0]?.[item.key] || data?.[type]?.[0]?.[item.key]
        }
        onChange={(event) => handleChange(event, item, type)}
        maxLength={item.key === 'postal_code' ? 10 : ''}
      />
    );
  };

  const generateBillingAddressHTML = () => {
    return (
      <>
        {billingInformation.map((field) =>
          field?.label !== 'Billing Contact' ? (
            <fieldset className="shape-without-border  w-430 mt-3">
              <p className="account-steps m-0">{field.part}</p>
              <div className="billing-address"> {field.label}</div>
              <p className="mt-2">{field.subTitle}</p>
              <div className="row">
                {field.array.map((item) => (
                  <div className={item.property} key={item.key}>
                    <InputFormField className="mt-3">
                      <label htmlFor={item.key}>
                        {item.label}
                        <br />
                        {item.type === 'number' ? (
                          <>{generateNumeric(item, field.key)}</>
                        ) : (
                          <>{generateInput(item, field.key)}</>
                        )}
                      </label>
                    </InputFormField>
                    <ErrorMsg>
                      {apiError?.[field.key]?.[item.key]?.[0]}
                    </ErrorMsg>
                  </div>
                ))}
              </div>
            </fieldset>
          ) : (
            ''
          ),
        )}
      </>
    );
  };

  // Checkdisabled confition for Billing contact
  const disabledConditionForBillingContact = () => {
    let arr = [];
    // Remove phone number from billing contact, because phone number is not mandatory
    const billingContactWithoutPhone = formData?.billing_contact?.map(
      // eslint-disable-next-line camelcase
      ({ phone_number, ...rest }) => {
        return rest;
      },
    );

    // eslint-disable-next-line no-unused-vars
    const tempBillingContactWithoutPhone = billingContactWithoutPhone?.map(
      // eslint-disable-next-line array-callback-return
      (item) => {
        const currentValues = Object.values(item);
        arr = [...arr, ...currentValues];
      },
    );

    const disabledFlag = arr?.some((item) => item === '');
    if (disabledFlag) {
      return true;
    }
    return false;
  };

  const disableWhenError = () => {
    const contactVariable = disabledConditionForBillingContact();

    if (contactVariable) {
      return true;
    }

    if (
      !formData?.billing_address ||
      !formData?.billing_address?.address ||
      !formData?.billing_address?.city ||
      !formData?.billing_address?.state ||
      !formData?.billing_address?.postal_code
    )
      return true;
    if (
      apiError?.billing_address &&
      Object.values(apiError.billing_address)?.find((op) => op !== '')
    )
      return true;
    return false;
  };

  return (
    <>
      <OnBoardingBody className="body-white">
        {generateBillingAddressHTML()}
        <AdditionalBillingContact
          data={data}
          formData={formData}
          setFormData={setFormData}
          apiError={apiError}
          setApiError={setApiError}
          disabledConditionForBillingContact={
            disabledConditionForBillingContact
          }
          contactApiErrors={contactApiErrors}
          setContactApiErrors={setContactApiErrors}
        />
        <div className="white-card-base panel gap-none">
          {isChecked ? (
            ''
          ) : (
            <Button
              className="btn-primary w-100  mt-3 mb-4"
              onClick={() => saveBillingData()}
              disabled={disableWhenError()}
            >
              {' '}
              {isLoading.loader && isLoading.type === 'button' ? (
                <PageLoader color="#fff" type="button" />
              ) : (
                <>{assignedToSomeone ? 'Submit' : 'Continue'} </>
              )}
            </Button>
          )}
        </div>
      </OnBoardingBody>
    </>
  );
}

BillingInfo.defaultProps = {
  stepData: {},
  summaryDetails: () => {},
};

BillingInfo.propTypes = {
  userInfo: shape({
    id: string,
    email: string,
    customer: string,
    customer_onboarding: string,
    step: shape({
      step: number,
    }),
  }).isRequired,
  setIsLoading: func.isRequired,
  assignedToSomeone: bool.isRequired,
  stepData: shape({
    id: string,
  }),
  verifiedStepData: shape(
    shape({
      user_name: string,
    }),
  ).isRequired,
  isLoading: shape({
    loader: bool,
    type: string,
  }).isRequired,
  data: shape({
    id: string,
    card_details: shape({}),
    billing_address: shape({
      address: string,
    }),
    billing_contact: shape({
      first_name: string,
    }),
  }).isRequired,
  isChecked: bool.isRequired,
  summaryData: arrayOf(shape({})).isRequired,
  summaryDetails: func,
  showDSPContact: shape({
    sameAsBilling: bool,
    show: bool,
  }).isRequired,
};
