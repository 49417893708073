/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { bool, func, number, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Table } from '../../../common';
import { PATH_CUSTOMER_DETAILS } from '../../../constants';

export default function ChildCustomerListView({
  colSpanValue,
  parentCustomer,
  showContractsList,
  generateCompanyStatus,
  returnTooltipMessgeLWA,
  showPerformance,
  showAdPerformance,
  showDspAdPerformance,
  showContractDetails,
  displayTableData,
  displayAccountStatus,
  displaySPAdTableData,
  displaySalesPerformanceTableData,
}) {
  const history = useHistory();

  const returnTableType = (item) => {
    if (showPerformance) {
      return displaySalesPerformanceTableData('sales_performance', item);
    }
    if (showAdPerformance) {
      return displaySPAdTableData('sponsored_ad_performance', item);
    }
    if (showDspAdPerformance) {
      return displayTableData('dsp_ad_performance', item);
    }
    return [];
  };

  // generate child brand table header
  const generateChildBrandTableHeader = (count) => {
    const tableHeader = [<th width="300px" className="product-header " />];
    if (count > 0) {
      for (let i = 0; i < count - 1; i += 1) {
        tableHeader.push(<th width="150px" className="product-header" />);
      }
    }
    return tableHeader;
  };

  // Render Child brands in a table
  const renderChildCustomerList = () => {
    if (parentCustomer?.child_customer?.length) {
      return parentCustomer?.child_customer?.map((item) => {
        return (
          <>
            <tr
              key={item?.id}
              className="cursor product-body"
              onClick={() => {
                history.push(
                  `${PATH_CUSTOMER_DETAILS.replace(
                    ':id',
                    item?.id,
                  )}?tab=performance${
                    item?.customer_account_type
                      ? `&sub=${
                          item.customer_account_type === 'Hybrid' ||
                          item.customer_account_type === 'Seller'
                            ? 'seller'
                            : 'vendor'
                        }`
                      : ''
                  }&nav=${'salePerformance'}`,
                  history.location.state,
                );
              }}
            >
              <td className="product-body" style={{ paddingLeft: '6px' }}>
                <div className="border-left-for-child" />
                <div className=" straight-line horizontal-line left-line" />
                {item?.company_name}
                <br />
                <small
                  className="p-5"
                  style={{ color: 'black', fontWeight: 'lighter' }}
                >
                  {item?.brand_growth_strategist?.first_name}{' '}
                  {item?.brand_growth_strategist?.last_name}
                </small>
              </td>
              {showContractDetails ? (
                <>
                  <td className="product-body">
                    {item?.customer_account_type
                      ? displayAccountStatus(
                          item?.account_type,
                          item?.customer_account_type,
                        )
                      : ''}
                    {Object.keys(item?.lwa).length
                      ? returnTooltipMessgeLWA(item)
                      : ''}
                    {/* <div className="status">{item?.customer_account_type}</div> */}
                  </td>
                  <td className="product-body">
                    {generateCompanyStatus(item?.status)}
                  </td>
                  <td className="product-body">{showContractsList(item)}</td>
                </>
              ) : (
                returnTableType(item)
              )}
            </tr>
          </>
        );
      });
    }
    return 'No Child Customers yet';
  };

  return (
    <Table className="customer-list" style={{ marginTop: '0' }}>
      <thead>
        <tr
          className="customer-list-table-header "
          style={{ visibility: 'collapse' }}
        >
          {showPerformance || showAdPerformance || showDspAdPerformance ? (
            generateChildBrandTableHeader(colSpanValue)?.map((thead) => {
              return thead;
            })
          ) : (
            <>
              <th width="300px" className="product-header" />
              <th width="100px" className="product-header" />
              <th width="110px" className="product-header" />
              <th width="250px" className="product-header" />
            </>
          )}
        </tr>
      </thead>
      <tbody>{renderChildCustomerList()}</tbody>
    </Table>
  );
}

ChildCustomerListView.defaultProps = {
  colSpanValue: 1,
  parentCustomer: {},
  showContractsList: () => {},
  generateCompanyStatus: () => {},
  returnTooltipMessgeLWA: () => {},
  showPerformance: false,
  showAdPerformance: false,
  showDspAdPerformance: false,
  showContractDetails: true,
  displayTableData: () => {},
  displayAccountStatus: () => {},
  displaySPAdTableData: () => {},
  displaySalesPerformanceTableData: () => {},
};

ChildCustomerListView.propTypes = {
  colSpanValue: number,
  parentCustomer: shape({}),
  showContractsList: func,
  generateCompanyStatus: func,
  returnTooltipMessgeLWA: func,
  showPerformance: bool,
  showAdPerformance: bool,
  showDspAdPerformance: bool,
  showContractDetails: bool,
  displayTableData: func,
  displayAccountStatus: func,
  displaySPAdTableData: func,
  displaySalesPerformanceTableData: func,
};
