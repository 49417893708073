import React, { useState } from 'react';

import dayjs from 'dayjs';
import Modal from 'react-modal';
import { shape, bool } from 'prop-types';

import SuggestUpdateModal from './SuggestUpdateModal';
import { Button, PageLoader, NoRecordFound } from '../../common';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '600px ',
    width: '100% ',
    minHeight: '200px',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
  },
};

function Article({
  selectedArticle,
  sectionLoader,
  isDesktop,
  isTablet,
  isMobile,
  tabView,
  mobileView,
}) {
  const [showModal, setShowModal] = useState(false);
  const [showContinueMsg, setShowContinueMsg] = useState(false);

  const handleContent = () => {
    return ` <h2 class="primary-heading">
                          ${
                            (selectedArticle &&
                              selectedArticle.preferredPhrase) ||
                            ''
                          }
                        </h2>
        ${(selectedArticle && selectedArticle.content) || ''}`;
  };

  const getInitials = () => {
    const firstName = selectedArticle?.owner?.firstName?.charAt(0) || '';
    const lastName = selectedArticle?.owner?.lastName?.charAt(0) || '';
    return firstName + lastName;
  };

  return (
    <div className="article-details-text" id="blogBox">
      {isDesktop ||
      (isTablet && tabView) ||
      (isMobile && mobileView.article) ? (
        sectionLoader?.articleLoader ? (
          <div style={{ top: '30%', position: 'relative' }}>
            <PageLoader
              component="performance-graph"
              type="detail"
              width={40}
              height={40}
              color="red"
            />
          </div>
        ) : Object.keys(selectedArticle)?.length ? (
          <div className="row mt-lg-5 mt-4">
            <div className="col-md-6 ">
              <div className="edit-profile-text float-left" role="presentation">
                {selectedArticle?.owner?.profilePicUrl ? (
                  <img src={selectedArticle?.owner?.profilePicUrl} alt="logo" />
                ) : (
                  <div className="avatarName mr-2">{getInitials()}</div>
                )}

                <div className="name-email">
                  <div className="team-member-name">
                    {`${selectedArticle?.owner?.firstName} ${selectedArticle?.owner?.lastName}`}
                  </div>
                  Last Updated on{' '}
                  {selectedArticle &&
                    dayjs(selectedArticle.lastModified).format('MMM DD, YYYY')}
                </div>
              </div>
            </div>
            <div
              className="col-md-6 
                    text-right"
            >
              <Button
                onClick={() => {
                  setShowModal(true);
                  setShowContinueMsg(false);
                }}
                className=" btn-with-radius  "
              >
                {' '}
                Suggest an update
              </Button>
              <Modal
                isOpen={showModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Edit modal"
              >
                <SuggestUpdateModal
                  setShowModal={setShowModal}
                  showContinueMsg={showContinueMsg}
                  selectedArticle={selectedArticle}
                  setShowContinueMsg={setShowContinueMsg}
                />
              </Modal>
            </div>

            <div
              className="col-12 remove-author"
              dangerouslySetInnerHTML={{
                __html: handleContent(),
              }}
            />
          </div>
        ) : (
          <div className="row">
            <NoRecordFound
              type="KB-article"
              title=""
              subTitle="Please Select a Specific article."
            />
          </div>
        )
      ) : (
        ''
      )}
    </div>
  );
}

export default Article;

Article.defaultProps = {
  selectedArticle: {},
  sectionLoader: {},
  isDesktop: false,
  isTablet: false,
  isMobile: false,
  tabView: false,
  mobileView: {},
};

Article.propTypes = {
  selectedArticle: shape({}),
  sectionLoader: shape({}),
  isDesktop: bool,
  isTablet: bool,
  isMobile: bool,
  tabView: bool,
  mobileView: shape({}),
};
