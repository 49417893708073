const convertImageToBase64 = async (imgUrl) => {
  const data = await fetch(imgUrl);
  const blob = await data?.blob();

  const base64Url = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(blob);
    fileReader.onload = () => resolve({ data: fileReader?.result });
    fileReader.onerror = (error) => reject(error);
  });
  return base64Url;
};

export default async function imageToDataURL(image) {
  const result = await convertImageToBase64(image)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
  return result;
}
