// react improrts
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// third party imports
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { getDate } from 'date-fns';
import { func, number, string } from 'prop-types';

// local files imports
import Theme from '../../../../theme/Theme';
import useFormatNumber from '../../../../hooks/useFormatNumber';
import { EditInput, InputDateDropDown } from '../CustomerDetailStyles';
import {
  errorMessages,
  offBoardedRadioButtons,
  cancelAgreementStaticMesssages,
  editBtnMessageForInvoice,
  invoiceTooltipMessages,
} from '../../../../constants';
import {
  getAgreementData,
  agreementCancelPauseRequest,
  getPauseAgreementDSPAmounts,
} from '../../../../api';
import {
  EditExternalLinkOrange,
  InfoIcons,
  LeftArrowIcon,
  OrangeCheckMark,
} from '../../../../theme/images';
import {
  ModalBox,
  Button,
  InputFormField,
  PageLoader,
  ErrorMsg,
  FormField,
  ModalRadioCheck,
} from '../../../../common';

const _ = require('lodash');

export default function AgreementCancelModal({
  agreementId,
  saveDetails,
  contractType,
  agreementCount,
}) {
  const mounted = useRef(false);
  const modalContentRef = useRef();
  const [modalContentHeight, setModalContentHeight] = useState(0);
  // https://bbe.atlassian.net/browse/PDV-9195 - Contract Cancellation Logic Improvement

  // According to the new requirnmemts, request date is the date at which user raise a request for cancelling the agreement and it will be today's date.

  // FIRST AGREEMENT TERM --> starting from CONTRACT SIGNED DATE upto the CONTRACT FIRST RENEWED DATE - 1.
  // SECOND AGREEMENT TERM --> starting from  CONTRACT FIRST RENEWED DATE upto the END OF CONTRACT FIRST RENEWAL i.e. OUT OF CONTRACT DATE - 1
  // THIRD AGREEMENT TERM --> BEYOND OUT OF CONTRACT DATE.
  const requestDate = useMemo(() => {
    return new Date();
  }, []);

  const [loader, setLoader] = useState({ page: false });
  const [isDisabledEdit, setIsDisabledEdit] = useState(true);
  const [isAllowToEdit, setIsAllowToEdit] = useState(false);
  const [agreementData, setAgreementData] = useState({});
  const [cancelDateDetails, setCancelDateDetails] = useState({
    monthlyRetainer: null,
    revShare: null,
    dsp: null,
    amc: null, // PDV-8948 - Contract Cancellation - AMC Invoice
  });
  const [dspAmount, setDspAmount] = useState(0);
  const [pauseModalStep, setPauseModalStep] = useState(1);
  const [monthlyRetainerAmount, setMonthlyRetainerAmount] = useState(0);
  const [amcInvoiceAmount, setAmcInvoiceAmount] = useState(0);
  const [largerAmountError, setLargerAmountError] = useState(false);
  const [spanMonthError, setSpanMonthError] = useState(false);
  const [offBordedAnswers, setOffBordedAnswers] = useState({
    is_off_boarded: null,
    is_ending_early: null,
    last_service_date: null,
    cancelling_note: null,
  });

  const [isRetainerInvoiceHasFutureDate, setIsRetainerInvoiceHasFutureDate] =
    useState(false);
  const [isDspInvoiceHasFutureDate, setIsDspInvoiceHasFutureDate] =
    useState(false);
  const [isAmcInvoiceHasFutureDate, setIsAmcInvoiceHasFutureDate] =
    useState(false);

  // PDV-8559 - cancel agreement logic
  const invoiceDayOfMonthlyRetainer = new Date(
    agreementData?.monthlyRetainer?.lastBillDate,
  )?.getDate();

  const retainerDates = [29, 30, 31, 1];
  const invoiceDayOfRevShare = 9;
  const invoiceDayOfDsp = 11;
  // PDV-8948 - Contract Cancellation - AMC Invoice
  const invoiceDayOfAmc = new Date(agreementData?.amc?.lastBillDate)?.getDate();
  // const [selectedRevShareMonth, setSelectedRevShareMonth] = useState(); commented due to- PDV-8464 - changed date logic

  // use effect for modal content height
  useEffect(() => {
    // get updated modal content height
    const updateContentHeight = () => {
      if (modalContentRef.current) {
        setModalContentHeight(modalContentRef.current.clientHeight);
      }
    };

    // Set initial height
    updateContentHeight();

    // Create a new MutationObserver instance and set up a mutation observer to update the height when content changes
    const observer = new MutationObserver((mutationsList) => {
      // This callback runs whenever a mutation is observed
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'subtree') {
          // Update the height when children or subtree are modified
          updateContentHeight();
        }
      }
    });

    // set childList and subtree as true in observer to observe the mutation
    if (modalContentRef.current) {
      observer.observe(modalContentRef.current, {
        childList: true,
        subtree: true,
      });
    }

    // Cleanup observer on component unmount
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [modalContentRef]);

  // function - bind invoice api data of agreement
  const bindInvoiceDataOfAgreement = useCallback((invoiceKey, dataItem) => {
    const lastBillDate = dataItem?.start_date
      ? new Date(dataItem?.start_date)
      : invoiceKey === 'revShare'
      ? new Date(new Date().setDate(9))
      : invoiceKey === 'dsp'
      ? new Date(new Date().setDate(11))
      : new Date();

    const invoiceObj = {
      lastBillDate,
      invoiceAmount: dataItem?.invoice_amount || null,
      currency: dataItem?.currency || '$',
      originalLastBillDate: lastBillDate,
    };

    return invoiceObj;
  }, []);

  // function - bind agreement api data
  const bindAgreementDataResponse = useCallback(
    (data) => {
      let dummyAgreementData = {};
      for (const item of data) {
        if (item?.invoice_type === 'retainer') {
          dummyAgreementData = {
            ...dummyAgreementData,
            monthlyRetainer: bindInvoiceDataOfAgreement(
              'monthlyRetainer',
              item,
            ),
          };

          if (item?.invoice_amount !== null) {
            setMonthlyRetainerAmount(item?.invoice_amount);
          } else {
            setMonthlyRetainerAmount(0);
          }
        }
        if (item?.invoice_type === 'rev share') {
          dummyAgreementData = {
            ...dummyAgreementData,
            revShare: bindInvoiceDataOfAgreement('revShare', item),
          };
        }
        if (item?.invoice_type === 'dsp service') {
          dummyAgreementData = {
            ...dummyAgreementData,
            dsp: bindInvoiceDataOfAgreement('dsp', item),
          };
        }
        // PDV-8948 - Contract Cancellation - AMC Invoice
        if (item?.invoice_type === 'amc service') {
          dummyAgreementData = {
            ...dummyAgreementData,
            amc: bindInvoiceDataOfAgreement('amc', item),
          };

          if (item?.invoice_amount !== null) {
            setAmcInvoiceAmount(item?.invoice_amount);
          } else {
            setAmcInvoiceAmount(0);
          }
        }
      }
      return dummyAgreementData;
    },
    [bindInvoiceDataOfAgreement],
  );

  // function - get cancel agreement data
  const getCancelAgreementData = useCallback(
    (id) => {
      setLoader({ page: true });
      getAgreementData(id, 'cancel').then((res) => {
        if (mounted.current) {
          if (res && res.status === 400) {
            setLoader({ page: false });
          }
          if (res && res.status === 200) {
            if (res?.data?.invoice_type && res?.data?.invoice_type.length) {
              setAgreementData(
                bindAgreementDataResponse(res?.data?.invoice_type),
              );
              const renewdDate = new Date(res?.data?.renewed_date);
              const outOfcontractDate = new Date(
                res?.data?.out_of_contract_date,
              );

              const endDateOfContractFirstTerm = new Date(
                res?.data?.renewed_date,
              );
              endDateOfContractFirstTerm.setDate(
                endDateOfContractFirstTerm.getDate() - 1,
              );

              const endDateOfContractSecondTerm = new Date(
                res?.data?.out_of_contract_date,
              );
              endDateOfContractSecondTerm.setDate(
                endDateOfContractSecondTerm.getDate() - 1,
              );
              setOffBordedAnswers({
                is_off_boarded:
                  res?.data?.is_off_boarded === true
                    ? 'yes'
                    : res?.data?.is_off_boarded === false
                    ? 'no'
                    : null,
                is_ending_early:
                  res?.data?.is_ending_early === true
                    ? 'yes'
                    : res?.data?.is_ending_early === false ||
                      new Date() > outOfcontractDate
                    ? 'no'
                    : null,
                last_service_date: res?.data?.last_service_date
                  ? new Date(res?.data?.last_service_date)
                  : null,
                cancelling_note: res?.data?.cancelling_note || null,
                contractStartDate:
                  res?.data?.contract_start_date !== null
                    ? new Date(res?.data?.contract_start_date)
                    : new Date(),
                contractRenewedDate: renewdDate,
                outOfcontractDate,
                endDateOfContractFirstTerm,
                endDateOfContractSecondTerm,
                isAgreementFirstTerm: requestDate <= endDateOfContractFirstTerm,
                isAgreementSecondTerm:
                  endDateOfContractFirstTerm < requestDate &&
                  requestDate <= endDateOfContractSecondTerm,
                isAgreementThirdTerm: requestDate > endDateOfContractSecondTerm,
              });
            } else {
              setAgreementData({});
            }
          }

          setLoader({ page: false });
        }
      });
    },
    [bindAgreementDataResponse, requestDate],
  );

  // function  - get dsp amount from api
  const getDspAmounts = useCallback((id, startDate, endDate) => {
    setLoader({ page: true });
    getPauseAgreementDSPAmounts(id, startDate, endDate).then((res) => {
      if (mounted.current) {
        if (res?.status === 400) {
          setLoader({ page: false });
        }
        if (res && res.status === 200) {
          setDspAmount(res?.data?.start_date_amount);
        }
        setLoader({ page: false });
      }
    });
  }, []);

  // function - save cancel agreement data
  const agreementCancelRequest = useCallback(
    (id, data, finalRequestOffboardedAnswers) => {
      setLoader({ ...loader, button: true });
      agreementCancelPauseRequest(
        id,
        data,
        finalRequestOffboardedAnswers,
        'cancel',
      ).then((res) => {
        if (mounted.current) {
          if (res?.status === 400) {
            setLoader({ ...loader, button: false });
          }
          if (res?.status === 201) {
            saveDetails();
          }

          setLoader({ ...loader, button: false });
        }
      });
    },
    [loader, saveDetails],
  );

  // function - bind cancel agreement final invoice data
  const bindFinalInvoiceData = (invoiceType, invoiceKey) => {
    const invoiceObj = {
      invoice_type: invoiceType,
      end_date: dayjs(cancelDateDetails?.[invoiceKey]).format('YYYY-MM-DD'),
    };
    let finalInvoiceObj = { ...invoiceObj };

    if (invoiceKey === 'monthlyRetainer') {
      finalInvoiceObj = {
        ...invoiceObj,
        invoice_amount: monthlyRetainerAmount,
      };
    }
    if (invoiceKey === 'amcService') {
      finalInvoiceObj = {
        ...invoiceObj,
        invoice_amount: amcInvoiceAmount,
      };
    }

    return finalInvoiceObj;
  };

  // function bind cancel agreement data
  const bindCancelRequest = () => {
    const retainer = cancelDateDetails?.monthlyRetainer;
    const revShare = cancelDateDetails?.revShare;
    const dsp = cancelDateDetails?.dsp;
    const amc = cancelDateDetails?.amc;

    if (agreementCount === 1)
      setOffBordedAnswers({ ...offBordedAnswers, is_off_boarded: 'yes' });
    if (pauseModalStep === 1) {
      setPauseModalStep(2);
      return;
    }

    if (pauseModalStep === 2) {
      if (agreementData?.dsp) {
        getDspAmounts(
          agreementId,
          dayjs(dsp?.lastBillDate).format('YYYY-MM-DD'),
          null,
        );
      }
      // PDV-8990 - set AMC invoice amount to 0 if AMC invoice has future date.
      if (isAmcInvoiceHasFutureDate) {
        setAmcInvoiceAmount(0);
      } else {
        setAmcInvoiceAmount(agreementData?.amc?.invoiceAmount || 0);
      }
      setPauseModalStep(3);
      return;
    }

    const finalRequestOffboardedAnswers = {
      is_off_boarded:
        offBordedAnswers?.is_off_boarded === null
          ? false
          : offBordedAnswers?.is_off_boarded,
      is_ending_early: offBordedAnswers?.is_ending_early || null,
      last_service_date:
        dayjs(offBordedAnswers?.last_service_date)?.format('YYYY-MM-DD') ||
        null,
      cancelling_note: offBordedAnswers?.cancelling_note || '',
    };

    const finalRequestData = [];
    if (retainer) {
      finalRequestData.push(
        bindFinalInvoiceData('retainer', 'monthlyRetainer'),
      );
    }
    if (revShare) {
      finalRequestData.push(bindFinalInvoiceData('rev share', 'revShare'));
    }
    if (dsp) {
      finalRequestData.push(bindFinalInvoiceData('dsp service', 'dsp'));
    }

    // PDV-8948 - Contract Cancellation - AMC Invoice
    if (amc) {
      finalRequestData.push(bindFinalInvoiceData('amc service', 'amcService'));
    }

    // calling agreement cancel request function
    agreementCancelRequest(
      agreementId,
      finalRequestData,
      finalRequestOffboardedAnswers,
    );
  };

  // function - month validation for invoices
  const spanMonthValidation = useCallback((selectedDateDetails) => {
    let retainerDate = selectedDateDetails?.monthlyRetainer;
    let revShareDate = selectedDateDetails?.revShare;
    let dspDate = selectedDateDetails?.dsp;
    let amcDate = selectedDateDetails?.amc;

    const agreements = [];
    if (retainerDate !== null) {
      retainerDate = new Date(dayjs(retainerDate).format('MM/DD/YY'));
      agreements.push(retainerDate);
    }
    if (revShareDate !== null) {
      revShareDate = new Date(dayjs(revShareDate).format('MM/DD/YY'));
      agreements.push(revShareDate);
    }
    if (dspDate !== null) {
      dspDate = new Date(dayjs(dspDate).format('MM/DD/YY'));
      agreements.push(dspDate);
    }
    if (amcDate !== null) {
      amcDate = new Date(dayjs(amcDate).format('MM/DD/YY'));
      agreements.push(amcDate);
    }

    let minDate = agreements[0];
    let maxDate = agreements[0];

    for (const item of agreements) {
      const DifferenceInDaysMin = dayjs(minDate).diff(item, 'day');
      const DifferenceInDaysMax = dayjs(maxDate).diff(item, 'day');
      if (DifferenceInDaysMin > 0) {
        minDate = item;
      }
      if (DifferenceInDaysMax < 0) {
        maxDate = item;
      }
    }

    if (minDate !== undefined && maxDate !== undefined) {
      minDate = dayjs(minDate).set('date', 11);
      maxDate = dayjs(maxDate).set('date', 11);
      const diff = dayjs(maxDate).diff(minDate, 'month', true);
      if (diff > 2) {
        setSpanMonthError(true);
      } else {
        setSpanMonthError(false);
      }
    }
  }, []);

  useEffect(() => {
    mounted.current = true;
    getCancelAgreementData(agreementId);
    return () => {
      mounted.current = false;
    };
  }, [agreementId, getCancelAgreementData]);

  useEffect(() => {
    spanMonthValidation(cancelDateDetails);
  }, [cancelDateDetails, spanMonthValidation]);

  // get day from last bill date of retainer and amc
  const isRetainerAndAMCBillingDate = (date, invoiceKey) => {
    const day = getDate(date);
    const dayOfLastBillDate =
      agreementData?.[invoiceKey]?.lastBillDate.getDate();
    if (retainerDates.includes(dayOfLastBillDate)) return day === 2;
    return day === dayOfLastBillDate;
  };

  // get day from last bill date of rev share invoice
  const isRevShareBillingDate = (date) => {
    const day = getDate(date);
    return day === 9;
  };

  // get day from last bill date of dsp invoice
  const isDSPBillingDate = (date) => {
    const day = getDate(date);
    return day === 11;
  };

  // function - modal button validation
  const checkButtonValidation = () => {
    // if below condition are satisfy then return true otherwise false
    if (pauseModalStep === 1) {
      if (offBordedAnswers?.last_service_date === null) {
        return true;
      }
      return false;
    }

    if (pauseModalStep === 2) {
      if (
        _.isEmpty(agreementData) ||
        (agreementData?.monthlyRetainer &&
          cancelDateDetails?.monthlyRetainer === null) ||
        (agreementData?.revShare && cancelDateDetails?.revShare === null) ||
        (agreementData?.dsp && cancelDateDetails?.dsp === null) ||
        (agreementData?.amc && cancelDateDetails?.amc === null)
      ) {
        return true;
      }
      return false;
    }
    // pause modal step 3
    if (
      // !bgsManagerCheck ||
      largerAmountError ||
      spanMonthError ||
      monthlyRetainerAmount === undefined ||
      monthlyRetainerAmount === ''
    ) {
      return true;
    }
    return false;
  };

  // function handle date change of invoice
  const handleDateChange = (date, type) => {
    const selectedDate = new Date(date);
    if (type === 'revShare') {
      selectedDate.setDate(9);
    } else if (type === 'dsp') {
      selectedDate.setDate(11);
    } else if (type === 'amc') {
      // PDV-8948 - Contract Cancellation - AMC Invoice
      const amcInvoiceData = agreementData?.amc;
      const lastBillDate = new Date(amcInvoiceData?.lastBillDate);
      selectedDate.setDate(
        type === 'amc' && [(29, 30, 31, 1)].includes(lastBillDate.getDate())
          ? 2
          : lastBillDate.getDate(),
      );
    } else {
      if (date > new Date()) {
        setIsDisabledEdit(false);
      } else {
        setIsDisabledEdit(true);
        setMonthlyRetainerAmount(
          agreementData?.monthlyRetainer?.invoiceAmount || 0,
        );
        setIsAllowToEdit(false);
        setLargerAmountError(false);
      }
      const agreement = agreementData?.monthlyRetainer;
      const lastBillDate = new Date(agreement?.lastBillDate);
      selectedDate.setDate(
        type === 'monthlyRetainer' &&
          [(29, 30, 31, 1)].includes(lastBillDate.getDate())
          ? 2
          : lastBillDate.getDate(),
      );
    }
    setCancelDateDetails({
      ...cancelDateDetails,
      [type]: selectedDate,
    });
  };

  // Compare paramaters date with todays date, if its smallar than todays date then select similar date of next months date.
  const compareAndSetDates = (dateParam, type) => {
    // const monthCount = type === 'dsp' ? 0 : 1; commented due to- PDV-8464 - we can select previoe month for all invoice type.
    const monthCount = type === 'dsp' ? 0 : 0;
    if (dateParam < new Date()) {
      return new Date(dateParam.setMonth(dateParam.getMonth() + monthCount));
    }
    return dateParam;
  };

  // Set dates for dsp/retainer/rev share
  useEffect(() => {
    // get selected date for monthly retainer invoice
    let monthlyRetainerselectedDate = new Date();
    setMonthlyRetainerAmount(
      agreementData?.monthlyRetainer?.invoiceAmount || 0,
    );

    const agreement = _.cloneDeep(agreementData?.monthlyRetainer);
    const lastBillDate = new Date(agreement?.lastBillDate);
    if (lastBillDate < new Date()) {
      monthlyRetainerselectedDate.setDate(lastBillDate.getDate());
      monthlyRetainerselectedDate = compareAndSetDates(
        monthlyRetainerselectedDate,
        '',
      );
    } else {
      monthlyRetainerselectedDate = lastBillDate;
    }

    if (monthlyRetainerselectedDate > new Date()) {
      setIsDisabledEdit(false);
    } else {
      setIsDisabledEdit(true);
      setMonthlyRetainerAmount(
        agreementData?.monthlyRetainer?.invoiceAmount || 0,
      );
      setIsAllowToEdit(false);
      setLargerAmountError(false);
    }
    // get selected date for dsp invoice
    const dspSelectedDate = compareAndSetDates(
      new Date(new Date().setDate(11)),
      'dsp',
    );
    // get selected date for rev share invoice
    const revShareSelectedDate = compareAndSetDates(
      new Date(new Date().setDate(9)),
      '',
    );

    // PDV-8948 - Contract Cancellation - AMC Invoice
    // get selected date for amc invoice
    let amcSelectedDate = new Date();
    const amcInvoiceData = _.cloneDeep(agreementData?.amc);
    const amcLastBillDate = new Date(amcInvoiceData?.lastBillDate);

    if (amcLastBillDate < new Date()) {
      amcSelectedDate.setDate(amcLastBillDate.getDate());
      amcSelectedDate = compareAndSetDates(amcSelectedDate, '');
    } else {
      amcSelectedDate = amcLastBillDate;
    }

    setCancelDateDetails({
      ...cancelDateDetails,
      dsp: agreementData?.dsp ? dspSelectedDate : null,
      monthlyRetainer: agreementData?.monthlyRetainer
        ? monthlyRetainerselectedDate
        : null,
      revShare: agreementData?.revShare ? revShareSelectedDate : null,
      amc: agreementData?.amc ? amcSelectedDate : null, // PDV-8948 - Contract Cancellation - AMC Invoice
    });

    // Set min/max date limits for retainer, dsp, rev share datepicker
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementData]);

  // function handle invoice amount change
  const handleAmountChange = (event) => {
    event.preventDefault();
    const actualAmount = agreementData?.monthlyRetainer?.invoiceAmount;
    let { value } = event.target;
    value = value.replace(agreementData?.monthlyRetainer?.currency, '');
    value = value.replaceAll(',', '');
    setMonthlyRetainerAmount(value);
    if (value > actualAmount) {
      setLargerAmountError(true);
    } else {
      setLargerAmountError(false);
    }
  };

  // PDV-8559 - cancel agreement logic
  // get Invoice generated Day by invoice key
  const getInvoiceDay = (invoiceKey) => {
    const invoiceDay =
      invoiceKey === 'monthlyRetainer'
        ? invoiceDayOfMonthlyRetainer
        : invoiceKey === 'revShare'
        ? invoiceDayOfRevShare
        : invoiceKey === 'dsp'
        ? invoiceDayOfDsp
        : invoiceKey === 'amc'
        ? invoiceDayOfAmc
        : 1;

    return invoiceDay;
  };

  // checking id invoice has future bill date or not
  const isInvoiceHasFutureDate = (lastDate, invoiceKey) => {
    // Logic ---> If invoice bill date is greater than today's date and selected last service date i.e. invoice has future bill date and hasn't issued yet to the BP, then disabled the date picker for that invoice.
    const lastServiceDate = new Date(lastDate);

    const lastBillDate =
      agreementData?.[invoiceKey]?.originalLastBillDate !== null
        ? new Date(agreementData?.[invoiceKey]?.originalLastBillDate)
        : null;

    const hasFutureDate =
      lastBillDate !== null
        ? lastServiceDate <= lastBillDate && lastBillDate >= new Date()
        : false;

    return hasFutureDate;
  };

  // PDV-8559 - cancel agreement logic
  // calculate deafult max dates for invoice types when user select date of date previuos month as last service date
  const getDefaultSelectedDateForInvoices = (key, selectedDate) => {
    const dayOfLastServiceDate = selectedDate?.getDate();
    const invoiceDay = getInvoiceDay(key);
    const monthLimit =
      key === 'revShare'
        ? dayOfLastServiceDate >= invoiceDay
          ? 1
          : 0
        : dayOfLastServiceDate >= invoiceDay
        ? 0
        : -1;

    const getMonth = new Date(selectedDate)?.getMonth() + monthLimit;
    const getCurrentYear = new Date(selectedDate)?.getFullYear();
    const finalDate = new Date(getCurrentYear, getMonth, invoiceDay);

    return finalDate;
  };

  // calculate the available max date for invoice type
  const getMaxDateForInvoices = (key, selectedDate) => {
    // PDV-8559 - Billing Cancellation Logic Improvement
    const lastServiceDate = new Date(selectedDate);
    const currentMonth = new Date().getMonth();
    const dayOfLastServiceDate = lastServiceDate?.getDate();
    const monthOfLastServiceDate = lastServiceDate?.getMonth();
    const invoiceDay = getInvoiceDay(key);
    let monthLimit = 3;

    if (lastServiceDate < new Date()) {
      monthLimit =
        key === 'revShare'
          ? dayOfLastServiceDate >= invoiceDayOfRevShare
            ? 1
            : 0
          : key === 'dsp' || key === 'monthlyRetainer' || key === 'amc'
          ? monthOfLastServiceDate === currentMonth
            ? dayOfLastServiceDate >= invoiceDay
              ? 0
              : -1
            : 0
          : 3;
    } else {
      monthLimit =
        key === 'revShare'
          ? dayOfLastServiceDate >= invoiceDayOfRevShare
            ? 1
            : 0
          : key === 'dsp' || key === 'monthlyRetainer' || key === 'amc'
          ? dayOfLastServiceDate >= invoiceDay
            ? 0
            : -1
          : 3;
    }

    const getFutureMonths =
      lastServiceDate !== null
        ? new Date(lastServiceDate).getMonth() + monthLimit
        : new Date().getMonth() + monthLimit;
    const getCurrentYear =
      lastServiceDate !== null
        ? new Date(lastServiceDate).getFullYear()
        : new Date().getFullYear();
    const finalMaxDate = new Date(getCurrentYear, getFutureMonths, invoiceDay);

    return finalMaxDate;
  };

  // get max date when user select is ending early = 'no'
  const setMaxDate = (date, monthLimit) => {
    const getFutureMonths = new Date(date).getMonth() + monthLimit || 3;
    const getCurrentYear = new Date(date).getFullYear();
    return new Date(getCurrentYear, getFutureMonths, 0);
  };

  // calculate the min date for invoices
  const getMinDateForInvoices = (key, selectedDate, isEndingEarly) => {
    let monthLimit = 1;
    const lastServiceDate = new Date(selectedDate);
    const currentMonth = new Date().getMonth();
    const dayOfLastServiceDate = lastServiceDate?.getDate();
    const monthOfLastServiceDate = lastServiceDate?.getMonth();

    const invoiceDay = getInvoiceDay(key);

    if (isEndingEarly === 'yes' && lastServiceDate < new Date()) {
      monthLimit =
        key === 'dsp'
          ? monthOfLastServiceDate === currentMonth
            ? dayOfLastServiceDate >= invoiceDay
              ? -1
              : -2
            : -1
          : key === 'revShare'
          ? dayOfLastServiceDate >= invoiceDay
            ? 1
            : -1
          : key === 'monthlyRetainer' || key === 'amc'
          ? dayOfLastServiceDate >= invoiceDay
            ? 0
            : -1
          : 1;
    } else {
      if (key === 'dsp' || key === 'revShare') {
        monthLimit = new Date().getDate() > invoiceDay ? 0 : -1;
      }

      if (key === 'monthlyRetainer' || key === 'amc') {
        monthLimit = invoiceDay >= new Date().getDate() ? -1 : 0;
      }
    }

    const getFutureMonths =
      lastServiceDate < new Date()
        ? lastServiceDate?.getMonth() + monthLimit
        : new Date().getMonth() + monthLimit;
    const getCurrentYear = new Date().getFullYear();
    const finalMinDate = new Date(getCurrentYear, getFutureMonths, invoiceDay);
    return finalMinDate;
  };

  // get min date
  const setMinDate = (key) => {
    const getFutureMonths = new Date().getMonth() + key;
    const getCurrentYear = new Date().getFullYear();
    return new Date(getCurrentYear, getFutureMonths, 1);
  };

  // PDV-8928 - Contract Cancellation Logic Improvement
  // get min date limit for last service date
  const getMinDateLimitForLastServiceDate = () => {
    let tempMinDate = new Date();
    const getMinDate = setMinDate(-1);
    const contractStartDate = new Date(offBordedAnswers?.contractStartDate);
    const outOfcontractDate = new Date(offBordedAnswers?.outOfcontractDate);

    if (offBordedAnswers?.is_ending_early === 'yes') {
      if (getMinDate <= contractStartDate) {
        tempMinDate = contractStartDate;
      } else {
        tempMinDate = getMinDate;
      }
    } else if (requestDate > outOfcontractDate) {
      tempMinDate = getMinDate;
    } else {
      outOfcontractDate.setDate(outOfcontractDate?.getDate() - 1);
      tempMinDate = outOfcontractDate;
    }
    return tempMinDate;
  };

  // PDV-8928 - Contract Cancellation Logic Improvement
  // https://bbe.atlassian.net/browse/PDV-9195 - Contract Cancellation Logic Improvement
  // get max date limit for last service date
  const getMaxDateLimitForLastServiceDate = () => {
    const isEndingEarly = offBordedAnswers?.is_ending_early;
    const isAgreementFirstTerm = offBordedAnswers?.isAgreementFirstTerm;
    const isAgreementSecondTerm = offBordedAnswers?.isAgreementSecondTerm;
    const isAgreementThirdTerm = offBordedAnswers?.isAgreementThirdTerm;
    const endDateOfContractFirstTerm = new Date(
      offBordedAnswers?.endDateOfContractFirstTerm,
    );
    const endDateOfContractSecondTerm = new Date(
      offBordedAnswers?.endDateOfContractSecondTerm,
    );
    const outOfcontractDate = new Date(offBordedAnswers?.outOfcontractDate);
    let tempMaxDate = new Date();

    // if request date is beyond SECOND AGREEMENT TERM then allow user to select max date upto the 3 month beyond requestDate
    if (isAgreementThirdTerm) {
      tempMaxDate = setMaxDate(requestDate, 3);
    } else if (isEndingEarly === 'yes') {
      if (isAgreementFirstTerm) {
        // if request date is within FIRST AGREEMENT TERM then allow user to select max date upto the endDateOfContractFirstTerm
        endDateOfContractFirstTerm.setDate(
          endDateOfContractFirstTerm?.getDate() - 1,
        );
        tempMaxDate = endDateOfContractFirstTerm;
      }

      // if request date is is within SECOND AGREEMENT TERM then allow user to select max date upto the end date of first contract renewal i.e. endDateOfContractSecondTerm
      if (isAgreementSecondTerm) {
        endDateOfContractSecondTerm.setDate(
          endDateOfContractSecondTerm?.getDate() - 1,
        );
        tempMaxDate = endDateOfContractSecondTerm;
      }
    } else {
      if (isAgreementFirstTerm) {
        // if request date is within FIRST AGREEMENT TERM then allow user to select max date upto the endDateOfContractFirstTerm
        tempMaxDate = endDateOfContractFirstTerm;
      }

      // if request date is is within SECOND AGREEMENT TERM then allow user to select max date upto the 3 month beyond endDateOfContractSecondTerm
      if (isAgreementSecondTerm) {
        tempMaxDate = setMaxDate(outOfcontractDate, 3);
      }
    }

    return tempMaxDate;
  };

  // handle last service date filter events
  const handleLastServiceDate = (date) => {
    const lastServiceDate = new Date(date);
    setOffBordedAnswers({
      ...offBordedAnswers,
      last_service_date: lastServiceDate,
    });

    // PDV-8559 - cancel agreement logic
    // set cancel date details when user select last service date
    let dspSelectedDate = agreementData?.dsp ? cancelDateDetails?.dsp : null;
    let revShareSelectedDate = agreementData?.revShare
      ? cancelDateDetails?.revShare
      : null;
    let monthlyRetainerSelectedDate = agreementData?.monthlyRetainer
      ? cancelDateDetails?.monthlyRetainer
      : null;
    let amcSelectedDate = agreementData?.amc ? cancelDateDetails?.amc : null;

    // checking if invoice bill date is future or not -- this is not applicable for rev share
    const isRetainerHasFutureDate = isInvoiceHasFutureDate(
      date,
      'monthlyRetainer',
    );
    const isDspHasFutureDate = isInvoiceHasFutureDate(date, 'dsp');
    const isAmcHasFutureDate = isInvoiceHasFutureDate(date, 'amc');

    setIsRetainerInvoiceHasFutureDate(isRetainerHasFutureDate);
    setIsDspInvoiceHasFutureDate(isDspHasFutureDate);
    setIsAmcInvoiceHasFutureDate(isAmcHasFutureDate);

    // when user select is ending early = 'yes' and last service date then set the pre-selected dates for the invoices
    if (lastServiceDate < new Date()) {
      if (!isDspHasFutureDate) {
        dspSelectedDate = getDefaultSelectedDateForInvoices(
          'dsp',
          lastServiceDate,
        );
      }

      revShareSelectedDate = getDefaultSelectedDateForInvoices(
        'revShare',
        lastServiceDate,
      );

      if (!isRetainerHasFutureDate) {
        monthlyRetainerSelectedDate = getDefaultSelectedDateForInvoices(
          'monthlyRetainer',
          lastServiceDate,
        );
      }

      if (!isAmcHasFutureDate) {
        amcSelectedDate = getDefaultSelectedDateForInvoices(
          'amc',
          lastServiceDate,
        );
      }
    } else {
      if (!isDspHasFutureDate) {
        dspSelectedDate = getMaxDateForInvoices('dsp', lastServiceDate);
      }

      revShareSelectedDate = getMaxDateForInvoices('revShare', lastServiceDate);

      if (!isRetainerHasFutureDate) {
        monthlyRetainerSelectedDate = getMaxDateForInvoices(
          'monthlyRetainer',
          lastServiceDate,
        );
      }

      if (!isAmcHasFutureDate) {
        amcSelectedDate = getMaxDateForInvoices('amc', lastServiceDate);
      }
    }

    // not allow to edit monthly retainer amount if retainer invoice is already issues.
    if (monthlyRetainerSelectedDate > new Date()) {
      setIsDisabledEdit(false);
    } else {
      setIsDisabledEdit(true);
      setIsAllowToEdit(false);
    }

    setCancelDateDetails({
      ...cancelDateDetails,
      dsp: agreementData?.dsp ? dspSelectedDate : null,
      monthlyRetainer: agreementData?.monthlyRetainer
        ? monthlyRetainerSelectedDate
        : null,
      revShare: agreementData?.revShare ? revShareSelectedDate : null,
      amc: agreementData?.amc ? amcSelectedDate : null,
    });
  };

  // function - handle events when user click on radio buttons
  const handleChangeRadioButtons = (radioName, radioValue) => {
    const endDateOfContractFirstTerm = new Date(
      offBordedAnswers?.endDateOfContractFirstTerm,
    );
    const outOfcontractDate = new Date(offBordedAnswers?.outOfcontractDate);
    const isAgreementFirstTerm = offBordedAnswers?.isAgreementFirstTerm;

    if (radioName === 'is_off_boarded' && radioValue === 'no') {
      setOffBordedAnswers({
        ...offBordedAnswers,
        [radioName]: radioValue,
        is_ending_early: null,
        last_service_date: null,
      });
    } else if (radioName === 'is_ending_early') {
      // when user select 'NO'
      if (radioValue === 'no') {
        // if isAgreementFirstTerm then last service date will be the end date of agreement first term
        if (isAgreementFirstTerm) {
          setOffBordedAnswers({
            ...offBordedAnswers,
            [radioName]: radioValue,
            last_service_date: endDateOfContractFirstTerm,
          });
        } else {
          // last service date will be the out od contract date - 1
          outOfcontractDate.setDate(outOfcontractDate?.getDate() - 1);
          setOffBordedAnswers({
            ...offBordedAnswers,
            [radioName]: radioValue,
            last_service_date: outOfcontractDate,
          });
        }
      } else {
        // when user select 'YES'
        let lastDay = null;
        let lastServiceDateOfDsp = _.cloneDeep(cancelDateDetails?.dsp);
        if (contractType === 'dsp only' && lastServiceDateOfDsp) {
          lastServiceDateOfDsp = new Date(
            lastServiceDateOfDsp.setMonth(lastServiceDateOfDsp?.getMonth() + 1),
          );
          const y = lastServiceDateOfDsp.getFullYear();
          const m = lastServiceDateOfDsp.getMonth();
          lastDay = contractType === new Date(y, m + 1, 0);
        }
        setOffBordedAnswers({
          ...offBordedAnswers,
          [radioName]: radioValue,
          last_service_date: lastDay,
        });
      }
    } else {
      setOffBordedAnswers({
        ...offBordedAnswers,
        [radioName]: radioValue,
        last_service_date: null,
      });
    }
  };

  // PDV-8559 - cancel agreement logic
  // display invoive static messages
  const displayInvoiceStaticMessages = (invoiceMessage) => {
    return (
      <>
        <br />
        <small className="my-2 text-regular" style={{ color: 'red' }}>
          {invoiceMessage}
        </small>
      </>
    );
  };

  // get tooltip DataTip
  const getTooltipDataTip = (tooltipMessage) => {
    return `<div style="max-width:300px">${tooltipMessage}</div>`;
  };

  // display react tooltip with info icon
  const displayTooltipWithInfoIcon = (dataTip, tooltipId) => {
    return (
      <>
        <img
          width="12px"
          src={InfoIcons}
          className="ml-1 cursor"
          alt={tooltipId}
          style={{
            verticalAlign: 'middle',
            fontWeight: '300',
          }}
          data-tip={getTooltipDataTip(dataTip)}
          data-for={tooltipId}
        />
        <ReactTooltip
          className="cancel-agr-tooltip"
          id={tooltipId}
          aria-haspopup="true"
          place="bottom"
          effect="solid"
          insecure
          html
        />
      </>
    );
  };

  // PDV-8559 - cancel agreement logic
  // display invoice types with dates
  const displayInvoiceTypeWithDates = (
    invoiceTypeLabel,
    invoiceTypeKey,
    filterDate,
    tooltipId,
    isDisabled,
  ) => {
    const invoiceLastDate =
      agreementData?.[invoiceTypeKey]?.originalLastBillDate !== null
        ? new Date(agreementData?.[invoiceTypeKey]?.originalLastBillDate)
        : null;
    const invoiceMessage = cancelAgreementStaticMesssages?.[invoiceTypeKey];
    const tooltipMessage = invoiceTooltipMessages?.[invoiceTypeKey];
    const cancelDate = new Date(cancelDateDetails?.[invoiceTypeKey]);

    return (
      <>
        <div className="row align-items-center">
          <div
            key={invoiceTypeKey}
            className="col-md-8 col-6 text-left text-bold normal-text-black"
          >
            <label htmlFor="amount">
              {invoiceTypeLabel}
              {displayTooltipWithInfoIcon(tooltipMessage, tooltipId)}
            </label>
            {isDisabled ? (
              displayInvoiceStaticMessages(
                cancelAgreementStaticMesssages?.futureInvoiceDateMessage,
              )
            ) : (
              <>
                {invoiceTypeKey === 'monthlyRetainer' &&
                cancelDate <= new Date()
                  ? displayInvoiceStaticMessages(invoiceMessage)
                  : null}
                {invoiceTypeKey === 'revShare'
                  ? displayInvoiceStaticMessages(invoiceMessage)
                  : null}
                {invoiceTypeKey === 'dsp' && cancelDate <= new Date()
                  ? displayInvoiceStaticMessages(invoiceMessage)
                  : null}
                {/* PDV-8948 - Contract Cancellation - AMC Invoice */}
                {invoiceTypeKey === 'amc' && cancelDate <= new Date()
                  ? displayInvoiceStaticMessages(invoiceMessage)
                  : null}
              </>
            )}
          </div>
          <div className="col-md-4 col-6 text-right">
            <InputDateDropDown className={isDisabled ? 'disabled' : ''}>
              <DatePicker
                selected={cancelDate}
                onChange={(date) => handleDateChange(date, invoiceTypeKey)}
                filterDate={(date) => filterDate(date, invoiceTypeKey)}
                // minDate={setMinDate(0)} commented due to- PDV-8464 - changed date logic
                minDate={
                  invoiceTypeKey !== 'revShare' &&
                  new Date(offBordedAnswers?.last_service_date) > new Date() &&
                  invoiceLastDate !== null &&
                  invoiceLastDate > new Date()
                    ? invoiceLastDate
                    : getMinDateForInvoices(
                        invoiceTypeKey,
                        offBordedAnswers?.last_service_date,
                        offBordedAnswers?.is_ending_early,
                      )
                }
                maxDate={getMaxDateForInvoices(
                  invoiceTypeKey,
                  offBordedAnswers?.last_service_date,
                )}
                dateFormat="MM/dd/yyyy"
                previousMonthAriaLabel={invoiceTypeKey}
                placeholderText="MM/DD/YY"
                disabled={isDisabled} // if invoice bill date is future date than selected last service date then disabled the date picker
              />
            </InputDateDropDown>
          </div>
        </div>
        <div className="horizontal-line straight-line mb-3 mt-3" />
      </>
    );
  };

  // display agreement types dates with tooltip
  const renderAgreementServicesDates = () => {
    return (
      <>
        <div className="horizontal-line straight-line my-3" />
        <div className="row align-items-center">
          <div className="col-4">
            <div className="label" htmlFor="amount">
              invoice type{' '}
            </div>
          </div>
          <div className="col-8 text-right">
            <div className="label" htmlFor="amount text-right">
              Last Bill Date
              {displayTooltipWithInfoIcon(
                invoiceTooltipMessages?.lastBillDate,
                'lastBillInfo',
              )}
            </div>
          </div>
        </div>
        <div className="horizontal-line straight-line mb-3 mt-2" />
        {agreementData?.monthlyRetainer
          ? displayInvoiceTypeWithDates(
              'Monthly Retainer',
              'monthlyRetainer',
              isRetainerAndAMCBillingDate,
              'retainerInfo',
              isRetainerInvoiceHasFutureDate,
            )
          : null}
        {agreementData?.revShare
          ? displayInvoiceTypeWithDates(
              'Rev Share',
              'revShare',
              isRevShareBillingDate,
              'revShareInfo',
              false,
            )
          : null}
        {agreementData?.dsp
          ? displayInvoiceTypeWithDates(
              'DSP',
              'dsp',
              isDSPBillingDate,
              'dspInfo',
              isDspInvoiceHasFutureDate,
            )
          : null}
        {/* PDV-8948 - Contract Cancellation - AMC Invoice */}
        {agreementData?.amc
          ? displayInvoiceTypeWithDates(
              'AMC',
              'amc',
              isRetainerAndAMCBillingDate,
              'amcInfo',
              isAmcInvoiceHasFutureDate,
            )
          : null}
      </>
    );
  };

  // function - display invoice with amount
  const displayInvoiceWithAmount = (invoiceKey, label, amount) => {
    return (
      <>
        <div className="col-md-8 col-6 text-left text-bold normal-text-black">
          <label htmlFor="amount">{label}</label>
        </div>
        <div className="col-md-4 col-6 text-right">
          <div className="normal-text-black">
            {invoiceKey === 'revShare' ? 'Dependent on Sales' : amount}
          </div>
        </div>

        <div className="horizontal-line straight-line mb-3 mt-3" />
      </>
    );
  };

  // display icons for invoice
  const displayIconForInvoice = (isDisabled, iconLink, type) => {
    return (
      <img
        className={`external-edit ${isDisabled ? 'disabled ' : ''}`}
        role="presentation"
        onClick={() => setIsAllowToEdit(type === 'edit')}
        src={iconLink}
        alt={type}
      />
    );
  };

  // function html for cancel agreement - invoice amounts
  const renderAgreementServicesAmount = (formatNumber) => {
    return loader?.page ? (
      <div className="mt-2 mb-5" style={{ minHeight: '50px' }}>
        <PageLoader color={Theme.baseColor} type="page" />
      </div>
    ) : (
      <div className="row align-items-center">
        <div className="horizontal-line straight-line my-3" />
        <div className="col-4">
          <div className="label" htmlFor="amount">
            invoice type{' '}
          </div>
        </div>
        <div className="col-8 text-right ">
          <div className="label" htmlFor="amount text-right">
            Last Bill Amount
          </div>
        </div>
        <div className="horizontal-line straight-line mb-3 mt-2" />
        {agreementData?.monthlyRetainer ? (
          <>
            <div
              key="monthlyRetainer"
              className="col-md-8 mt-2 col-6 text-left text-bold normal-text-black"
            >
              <label htmlFor="amount">
                Monthly Retainer{' '}
                {!isDisabledEdit
                  ? displayTooltipWithInfoIcon(
                      invoiceTooltipMessages?.monthlyRetainerAmount,
                      'retainerInfo',
                    )
                  : null}
              </label>
            </div>
            <div className="col-md-4 col-6 text-right">
              <InputFormField>
                <div
                  className={`input-date-container ${
                    !isAllowToEdit ? 'disabled' : ''
                  }`}
                >
                  <NumberFormat
                    name="amount"
                    className="form-control modal-input-control  borderless-input"
                    prefix={agreementData?.monthlyRetainer?.currency}
                    placeholder="Enter Value"
                    onChange={(event) => handleAmountChange(event)}
                    value={monthlyRetainerAmount}
                    defaultValue={agreementData?.monthlyRetainer?.invoiceAmount}
                    thousandSeparator
                    allowNegative={false}
                  />
                </div>
                <EditInput>
                  {!isAllowToEdit
                    ? displayIconForInvoice(
                        isDisabledEdit,
                        EditExternalLinkOrange,
                        'edit',
                      )
                    : displayIconForInvoice(
                        largerAmountError,
                        OrangeCheckMark,
                        'check',
                      )}
                </EditInput>
              </InputFormField>
            </div>
            {largerAmountError ? (
              <div className="col-12">
                <ErrorMsg>{errorMessages?.retainerAmount}</ErrorMsg>
              </div>
            ) : null}
            <div className="horizontal-line straight-line mb-3 mt-3" />
          </>
        ) : null}

        {agreementData?.revShare
          ? displayInvoiceWithAmount('revShare', 'Rev Share', null)
          : null}

        {agreementData?.dsp
          ? displayInvoiceWithAmount(
              'dsp',
              'DSP',
              dspAmount !== null
                ? formatNumber(dspAmount, agreementData?.dsp?.currency)
                : '-',
            )
          : null}

        {/* PDV-8948 - Contract Cancellation - AMC Invoice */}
        {agreementData?.amc
          ? displayInvoiceWithAmount(
              'amc',
              'AMC',
              amcInvoiceAmount !== null
                ? formatNumber(amcInvoiceAmount, agreementData?.amc?.currency)
                : '-',
            )
          : null}
        {cancelDateDetails?.monthlyRetainer && isDisabledEdit ? (
          <small className="m-2" style={{ color: 'red' }}>
            {editBtnMessageForInvoice}
          </small>
        ) : null}
      </div>
    );
  };

  // fucntion - display radio buttons for cancel agreement
  const renderRadioButtonsForCancelModal = (radioName) => {
    const radioButtons = offBoardedRadioButtons?.map((radio) => {
      if (radio?.name === radioName) {
        return (
          <ModalRadioCheck
            className="mt-3 mb-3 d-inline-block mr-3"
            key={radio?.key}
          >
            <label
              className={`checkboxes radio-container customer-list ${
                radio?.name === 'is_ending_early' &&
                radio?.value === 'yes' &&
                new Date() >= new Date(offBordedAnswers?.outOfcontractDate)
                  ? 'disabled'
                  : ''
              }`}
              htmlFor={radio?.key}
            >
              {radio?.label}
              <input
                type="radio"
                name={radio?.name}
                id={radio?.key}
                label={radio?.label}
                value={radio?.value}
                checked={
                  radio?.name === 'is_off_boarded'
                    ? offBordedAnswers?.is_off_boarded === radio?.value
                      ? radio?.value
                      : null
                    : offBordedAnswers?.is_ending_early === radio?.value
                    ? radio?.value
                    : null
                }
                onChange={() =>
                  handleChangeRadioButtons(radio?.name, radio?.value)
                }
              />
              <span className="checkmark checkmark-customer-list" />
            </label>
          </ModalRadioCheck>
        );
      }
      return '';
    });
    return radioButtons;
  };

  // function - date picker for last servbice date
  const displayDatePickerForLastserviceDate = () => {
    // if user select 'NO' and contract is running in its first agreement term then disabled the date picker
    const isDisabled =
      offBordedAnswers?.is_ending_early === 'no' &&
      offBordedAnswers?.isAgreementFirstTerm;

    return (
      <InputDateDropDown className="last_service_date">
        <DatePicker
          selected={offBordedAnswers?.last_service_date}
          onChange={(date) => handleLastServiceDate(date, 'last_service_date')}
          minDate={getMinDateLimitForLastServiceDate()}
          maxDate={getMaxDateLimitForLastServiceDate()}
          dateFormat="MM/dd/yyyy"
          previousMonthAriaLabel="retainer"
          placeholderText="MM/DD/YY"
          disabled={isDisabled}
        />
      </InputDateDropDown>
    );
  };

  // display step 1/3 - cancel agreement - reason
  const renderCancelAgreementReason = () => {
    return (
      <>
        <div className="horizontal-line straight-line mt-3" />
        <div className="row align-items-center">
          {loader?.page ? (
            <div className="mt-2 mb-5" style={{ minHeight: '50px' }}>
              <PageLoader color={Theme.baseColor} type="page" />
            </div>
          ) : (
            <>
              {agreementCount === 1 ? (
                <div className="col-12 mt-3">
                  <p style={{ color: Theme.orange, margin: 0 }}>
                    This is the only remaining ACTIVE agreement for this Brand
                    Partner. <br />
                    Cancelling this agreement will automatically offboard the
                    Brand Partner.
                  </p>
                </div>
              ) : null}
              <div className="col-12 mt-3">
                <div className="label">
                  Request Date:
                  <span
                    className="text-medium-font gray-box"
                    style={{
                      marginLeft: '10px',
                    }}
                  >
                    {dayjs(requestDate).format('MM/DD/YYYY')}
                  </span>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="label">Is the brand partner ending early?</div>
                {renderRadioButtonsForCancelModal('is_ending_early')}
              </div>
              {offBordedAnswers?.is_ending_early ? (
                <div className="col-12">
                  <div className="label">What is the last service date?</div>
                  {displayDatePickerForLastserviceDate()}
                </div>
              ) : (
                ''
              )}

              <div className="col-12 mt-3">
                <FormField>
                  <textarea
                    className="form-control text-area black"
                    type="text"
                    width="100%"
                    maxLength="250"
                    placeholder="Why are we cancelling this agreement?"
                    defaultValue={offBordedAnswers?.cancelling_note}
                    onChange={(event) =>
                      setOffBordedAnswers({
                        ...offBordedAnswers,
                        cancelling_note: event.target.value,
                      })
                    }
                  />
                </FormField>
                <div className="text-right">
                  {`${offBordedAnswers?.cancelling_note?.length || 0}/250`}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  // function - back step button for modal
  const backStepButton = (pauseModalStepNumber) => {
    return (
      <img
        role="presentation"
        onClick={() => {
          setPauseModalStep(pauseModalStepNumber);
          setMonthlyRetainerAmount(
            agreementData?.monthlyRetainer?.invoiceAmount || 0,
          );
          setAmcInvoiceAmount(agreementData?.amc?.invoiceAmount || 0);
          setLargerAmountError(false);
          setIsAllowToEdit(false);
        }}
        className="modal-back-arrow"
        src={LeftArrowIcon}
        alt="back-arrow"
      />
    );
  };

  // function - modal footer
  const renderFooter = () => {
    return (
      <div className="modal-footer">
        {spanMonthError ? (
          <ErrorMsg className="mb-4"> {errorMessages.monthSpan}</ErrorMsg>
        ) : null}

        <div className="text-center">
          <Button
            onClick={() => {
              bindCancelRequest();
            }}
            type="button"
            className={`btn-primary on-boarding w-100 ${
              checkButtonValidation() || loader?.page ? 'disabled' : ''
            }`}
          >
            {loader.button ? (
              <PageLoader color={Theme.white} type="button" />
            ) : pauseModalStep === 1 || pauseModalStep === 2 ? (
              'Next'
            ) : (
              'Confirm and Cancel Agreement'
            )}
          </Button>
        </div>
      </div>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <ModalBox>
      <div className="modal-body pb-1">
        <div
          className={modalContentHeight > 500 ? 'addendum-service-modal' : ''}
          ref={modalContentRef}
        >
          <div className="uppercase-text">
            {pauseModalStep === 1 ? (
              'Step 1/3: cancel agreement: Reason'
            ) : pauseModalStep === 2 ? (
              <>
                {backStepButton(1)}
                Step 2/3: cancel agreement: Dates
              </>
            ) : (
              <>
                {backStepButton(2)}
                Step 3/3: cancel agreement: Amounts
              </>
            )}
          </div>
          {/* PDV-8559 - cancel agreement logic */}
          {pauseModalStep === 1
            ? renderCancelAgreementReason()
            : pauseModalStep === 2
            ? renderAgreementServicesDates()
            : renderAgreementServicesAmount(useFormatNumber)}
        </div>
      </div>

      {renderFooter()}
    </ModalBox>
  );
}

// proptypes
AgreementCancelModal.defaultProps = {
  agreementId: '',
  contractType: '',
  agreementCount: 1,
  saveDetails: () => {},
};

AgreementCancelModal.propTypes = {
  agreementId: string,
  contractType: string,
  agreementCount: number,
  saveDetails: func,
};
