import dayjs from 'dayjs';
import axiosInstance from '../axios';

import {
  API_ESTIMATED_LOST_SALES_ARCHIVE,
  API_ESTIMATED_LOST_SALES_ARCHIVE_RESTORE_ASIN,
  API_ESTIMATED_LOST_SALES_INACTIVE,
  API_ESTIMATED_LOST_SALES_REASON_LIST,
  API_ESTIMATED_LOST_SALES_STRANDED,
  API_INVENTORY_RESTOCK_LIMITS,
  API_INVENTORY_RESTOCK_LIMITS_DASHBOARD,
  API_LONG_TERM_STORAGE_FEES,
  API_RESTOCK_INVENTORY,
  API_RESTOCK_REPORTS_DOWNLOAD,
  API_STRANDED_INVENTORY,
  CUSTOM,
} from '../constants';

export async function getRestockData(
  isBGS,
  isBGSAdmin,
  isBGSManager,
  brandPartner,
  marketplace,
  storageType,
  users,
  managerUsers,
  selectedTimeFrame,
  selectedDate,
  orderByKey,
  pageNumber,
  isDownloadReport,
) {
  let params = {
    customer: brandPartner,
    marketplace,
    storage_type: storageType,
    ordering: orderByKey,
    page: pageNumber,
    daily_facts: selectedTimeFrame,
    download_report: isDownloadReport,
  };

  if (selectedTimeFrame === CUSTOM) {
    params = {
      ...params,
      timeframe: dayjs(selectedDate).format('YYYY-MM-DD'),
    };
  }

  if (isBGS) {
    params = {
      ...params,
      bgs_manager: 'all',
      bgs: users,
    };
  } else if (isBGSManager || isBGSAdmin) {
    params = {
      ...params,
      bgs_manager: managerUsers,
      bgs: users,
    };
  } else {
    params = {
      ...params,
      bgs: managerUsers,
    };
  }

  const result = await axiosInstance
    .get(API_INVENTORY_RESTOCK_LIMITS_DASHBOARD, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
}

export async function getStrandedInventoryTableData(
  isBGS,
  isBGSAdmin,
  isBGSManager,
  brandPartner,
  marketplace,
  managerUsers,
  users,
  timeFrame,
  customDate,
  orderByApiKey,
  pageNumber,
  isRestockInventory,
  isDownloadReport = false,
) {
  let params = {
    customer: brandPartner,
    dashboard: 'inventory',
    download_report: isDownloadReport,
    marketplace,
    ordering: orderByApiKey,
    page: pageNumber,
    daily_facts: timeFrame,
    is_restockinventory_visible: isRestockInventory,
  };

  if (timeFrame === CUSTOM) {
    params = {
      ...params,
      timeframe: dayjs(customDate).format('YYYY-MM-DD'),
    };
  }

  if (isBGS) {
    params = {
      ...params,
      bgs_manager: 'all',
      bgs: users,
    };
  } else if (isBGSManager || isBGSAdmin) {
    params = {
      ...params,
      bgs_manager: managerUsers,
      bgs: users,
    };
  } else {
    params = {
      ...params,
      bgs: managerUsers,
    };
  }

  const result = await axiosInstance
    .get(API_STRANDED_INVENTORY, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
}

export async function getRestockLimitsData(
  customer,
  dailyFacts,
  marketplace,
  seletedDate,
) {
  let params = {};
  params = {
    customer,
    daily_facts: dailyFacts,
    marketplace,
  };

  if (dailyFacts === CUSTOM) {
    params = {
      ...params,
      timeframe: dayjs(seletedDate).format('YYYY-MM-DD'),
    };
  }

  const result = await axiosInstance
    .get(`${API_INVENTORY_RESTOCK_LIMITS}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getStrandedInventoryData(
  customer,
  dailyFacts,
  marketplace,
  seletedDate,
  page,
  orderByKey,
  orderByFlag,
) {
  let params = { customer, daily_facts: dailyFacts, marketplace };

  if (page === 'noPage') {
    params = { ...params, no_page: true };
  } else {
    params = { ...params, page };
  }
  params = {
    ...params,
    ordering: orderByFlag ? `-${orderByKey}` : orderByKey,
  };

  if (dailyFacts === CUSTOM) {
    params = {
      ...params,
      timeframe: dayjs(seletedDate).format('YYYY-MM-DD'),
    };
  }

  const result = await axiosInstance
    .get(`${API_STRANDED_INVENTORY}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getRestockInventoryData(
  customer,
  marketplace,
  page,
  orderByKey,
  orderByFlag,
  inventoryType,
) {
  let params = {
    customer,
    marketplace,
    status: inventoryType,
  };

  if (page === 'noPage') {
    params = { ...params, no_page: true };
  } else {
    params = { ...params, page };
  }

  params = {
    ...params,
    'order-by': orderByFlag ? `-${orderByKey}` : orderByKey,
  };

  const result = await axiosInstance.get(`${API_RESTOCK_INVENTORY}`, {
    params,
  });
  return result;
}

export async function getRestockInventoryReportData(
  customer,
  marketplace,
  inventoryType,
) {
  const params = {
    customer,
    marketplace,
    status: inventoryType,
  };

  const result = await axiosInstance.get(`${API_RESTOCK_REPORTS_DOWNLOAD}`, {
    params,
  });
  return result;
}

/* ********** GET API DATA FOR ESTIMATED LOST SALES INVENTORY ********** */
export async function getEstimatedLostSalesAPIData(
  customer,
  marketplace,
  selectedTab,
  orderBy,
  date,
  reason,
  status,
  pageNumber,
  action,
) {
  let API = '';
  let params = {
    customer,
    marketplace,
    'order-by': orderBy,
    timeframe: date,
    page: pageNumber,
  };

  if (selectedTab === 'inactive') {
    params = {
      ...params,
    };
    API = API_ESTIMATED_LOST_SALES_INACTIVE;
  }

  if (selectedTab === 'stranded') {
    params = {
      ...params,
      stranded_reason: reason,
    };
    API = API_ESTIMATED_LOST_SALES_STRANDED;
  }

  if (selectedTab === 'archive') {
    params = {
      ...params,
      status,
    };
    API = API_ESTIMATED_LOST_SALES_ARCHIVE;
  }

  if (action === 'downloadReport') {
    params = { ...params, page: null, download_report: true };
  }

  const result = await axiosInstance
    .get(`${API}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
}

/* ********** GET API DATA FOR ESTIMATED LOST SALES INVENTORY REASON LIST ********** */
export async function getEstimatedLostSalesReasonListAPIData(
  customer,
  marketplace,
) {
  const params = {
    customer,
    marketplace,
  };

  const result = await axiosInstance
    .get(`${API_ESTIMATED_LOST_SALES_REASON_LIST}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

/* ********** POST API OF ARCHIVE AND RESTORE ASIN OF ESTIMATED LOST SALES INVENTORY ********** */
export async function postAsinArchiveAndRestoreAPIData(
  customer,
  asin,
  sku,
  status,
  action,
) {
  const params = {
    customer,
    asin,
    sku,
    status,
    action,
  };

  const result = await axiosInstance
    .post(`${API_ESTIMATED_LOST_SALES_ARCHIVE_RESTORE_ASIN}`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

/* ********** GET API DATA FOR LONG TERM STORAGE FEES INVENTORY ********** */
export async function getLongTermStoargeFeesAPIData(
  customerId,
  marketplace,
  orderBy,
  startDate,
  pageNumber,
  action,
) {
  let params = {
    customer: customerId,
    marketplace,
    order_by: orderBy,
    start_date: startDate,
    page: pageNumber,
  };
  if (action === 'downloadReport') {
    params = { ...params, page: null, download_report: true };
  }

  const result = await axiosInstance
    .get(`${API_LONG_TERM_STORAGE_FEES}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
