/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SellerASINPerformanceMobileView from './SellerASINPerformanceMobileView';
import VendorASINPerformanceMobileView from './VendorASINPerformanceMobileView';
import useValueWithChangeAndPercent from '../../../../../../hooks/useValueWithChangeAndPercent';
import Theme from '../../../../../../theme/Theme';
import { Icon } from '../../../../../../theme/Global';
import { getASINPerformanceData } from '../../../../../../api';
import {
  CommonPagination,
  DesktopTable,
  GrayMessageBar,
  NoData,
  PageLoader,
  ToggleButton,
  ViewData,
} from '../../../../../../common';
import {
  ASIN,
  CUSTOM,
  ORDEREDREVENUE,
  PARENT,
  SELLERSMALL,
  SHIPPEDCOGS,
  VENDORSMALL,
  asinTabOptions,
  childASINTableheader,
  parentASINTableheader,
  vendorOrderRevenueASINTableheader,
  vendorShippedCogsASINTableheader,
} from '../../../../../../constants';

const ASINPerformance = ({
  selectedPeriod,
  customDate,
  isSelectCustom,
  customerId,
  marketplace,
  currencySymbol,
  selectedChildBrand,
  selectedAccountType,
  metricsType = '',
  isAsinApiCallCustom,
}) => {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isExtraLargeDesktop = useMediaQuery({ minWidth: 1600 });
  const mounted = useRef(false);

  // PDV-8037 - ASIN TABLE on Sales Performance Tab: Add PDP Link
  // PARENT, CHILD, STANDALONE ASIN should be link to their respective Amazon PDP (Product Detail Page - https://www.amazon.com/dp/(ASIN)
  const baseURLForPdpLink = 'https://www.amazon.com/dp/';

  // https://bbe.atlassian.net/browse/PDV-8930
  // Show dynamic messages in ASIN performance
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  const salesAsinMessage =
    selectedAccountType === SELLERSMALL
      ? dynamicMessages?.sales_performance_seller_asin?.placeholder
      : selectedAccountType === VENDORSMALL
      ? dynamicMessages?.sales_performance_vendor_asin?.placeholder
      : null;

  const customerState = useSelector((state) => state.customerState.data);
  const [activeASINTab, setActiveASINTab] = useState('parent_asin');
  const [asinLoader, setASINLoader] = useState(false);
  const [asinPerformanceData, setAsinPerformanceData] = useState([]);
  const [asinPerformanceFlag, setASINPerformanceFlag] = useState(true);
  const [asinPerformanceCount, setASINPerformanceCount] = useState(null);
  const [asinPerformancePageNumber, setASINPerformancePageNumber] = useState(1);
  const [downloadReportLoader, setDownloadReportLoader] = useState(false);
  const isParentASINActive = activeASINTab === 'parent_asin';
  const isAppliedCustom = selectedPeriod?.value === CUSTOM && isSelectCustom;

  const [asinOrderByKey, setASINOrderByKey] = useState(() => {
    return selectedAccountType === SELLERSMALL
      ? 'current_revenue'
      : metricsType === ORDEREDREVENUE
      ? 'current_ordered_revenue'
      : 'current_shipped_cogs';
  });

  useEffect(() => {
    mounted.current = true;
    if (selectedAccountType === VENDORSMALL && metricsType === ORDEREDREVENUE)
      setASINOrderByKey('current_ordered_revenue');
    else if (selectedAccountType === VENDORSMALL && metricsType === SHIPPEDCOGS)
      setASINOrderByKey('current_shipped_cogs');
    return () => {
      mounted.current = false;
    };
  }, [metricsType, selectedAccountType]);

  const getPerformanceASINData = useCallback(
    (
      asinType,
      orderKey,
      orderFlag,
      selectedMarketPlace,
      childBrand,
      vendorMetricsType,
      start,
      end,
      pagenumber,
      downloadReport,
    ) => {
      setASINLoader(true);
      setAsinPerformanceData([]);
      setASINPerformanceCount(0);
      getASINPerformanceData(
        customerId,
        selectedMarketPlace,
        childBrand,
        pagenumber,
        orderKey,
        orderFlag,
        asinType,
        selectedPeriod.value,
        start,
        end,
        selectedAccountType,
        vendorMetricsType,
        downloadReport,
      )
        .then((res) => {
          if (mounted.current) {
            if (res?.status === 200) {
              setAsinPerformanceData(res.data?.results);
              setASINPerformanceCount(res.data?.count || 0);
              setASINLoader(false);
            }
            setASINLoader(false);
          }
        })
        .catch(() => {
          setASINPerformanceCount(0);
          setAsinPerformanceData([]);
          setASINLoader(false);
        });
    },
    [customerId, selectedAccountType, selectedPeriod],
  );

  // called if custom date is not selected
  useEffect(() => {
    mounted.current = true;
    if (!isAppliedCustom && marketplace) {
      setASINPerformancePageNumber(1);
      getPerformanceASINData(
        activeASINTab,
        asinOrderByKey,
        asinPerformanceFlag,
        marketplace,
        selectedChildBrand,
        metricsType,
      );
    }

    return () => {
      mounted.current = false;
    };
  }, [
    activeASINTab,
    asinOrderByKey,
    asinPerformanceFlag,
    getPerformanceASINData,
    isAppliedCustom,
    isSelectCustom,
    marketplace,
    selectedPeriod,
    selectedChildBrand,
  ]);

  // called if custom date is selected
  useEffect(() => {
    mounted.current = true;
    if (isAsinApiCallCustom || (isAppliedCustom && marketplace)) {
      const localCustomDate = [];

      // only for vendor account type
      if (selectedAccountType !== SELLERSMALL) {
        // PDV-9198 - seller have T-2 logic and Vendor have T-3 logic
        // so if T-2 date is selected for seller and switch to vendor then its should be T-3.
        // PDV-9198 - START //

        const localMaxDate = new Date();
        localMaxDate.setDate(localMaxDate.getDate() - 3);

        // set the start date
        localCustomDate.push(
          customDate[0]?.startDate > localMaxDate
            ? localMaxDate
            : customDate[0]?.startDate,
        );

        // set the end date
        localCustomDate.push(
          customDate[0]?.endDate > localMaxDate
            ? localMaxDate
            : customDate[0]?.endDate,
        );
      } else {
        // for seller account type
        localCustomDate.push(customDate[0]?.startDate);
        localCustomDate.push(customDate[0]?.endDate);
      }
      // PDV-9198 - END //

      setASINPerformancePageNumber(1);
      getPerformanceASINData(
        activeASINTab,
        asinOrderByKey,
        asinPerformanceFlag,
        marketplace,
        selectedChildBrand,
        metricsType,
        dayjs(localCustomDate[0]).format('DD-MM-YYYY'),
        dayjs(localCustomDate[1]).format('DD-MM-YYYY'),
        1,
      );
    }
    return () => {
      mounted.current = false;
    };
  }, [
    isAsinApiCallCustom,
    activeASINTab,
    asinOrderByKey,
    asinPerformanceFlag,
    getPerformanceASINData,
    isAppliedCustom,
    isSelectCustom,
    marketplace,
    selectedPeriod,
    selectedChildBrand,
  ]);

  const onGetPerformanceASINData = (value) => {
    getPerformanceASINData(
      activeASINTab,
      asinOrderByKey,
      asinPerformanceFlag,
      marketplace,
      selectedChildBrand,
      metricsType,
      dayjs(customDate[0]?.startDate).format('DD-MM-YYYY'),
      dayjs(customDate[0]?.endDate).format('DD-MM-YYYY'),
      value,
    );
  };

  const showASINToolTipContent = `
      <ul style="max-width: auto;padding-top: 0; padding-left: 20px;  margin: 0; font-size: 14px; line-height: 22px; list-style-type: disc;">
        <li >Absolute Change = Recent Period Value - Previous Period Value </li>
        <li>
          % Change = [(Recent Period Value - Previous Period Values)/</br>Previous
          Period Value] x 100
        </li>
      </ul>
    `;

  const handleDownloadReportEvents = () => {
    setDownloadReportLoader(true);

    getASINPerformanceData(
      customerId,
      marketplace,
      selectedChildBrand,
      1,
      asinOrderByKey,
      asinPerformanceFlag,
      activeASINTab,
      selectedPeriod.value,
      dayjs(customDate[0]?.startDate).format('DD-MM-YYYY'),
      dayjs(customDate[0]?.endDate).format('DD-MM-YYYY'),
      selectedAccountType,
      metricsType,
      true,
    ).then((response) => {
      if (response?.status === 200) {
        const csvLink = response?.data?.url;
        if (csvLink) window.open(csvLink, '_blank');
      }
      setDownloadReportLoader(false);
    });
  };

  // display download report button
  const displayDownloadReportButton = () => {
    return (
      <div
        className={`black-heading-title text-medium-font m-0 cursor ${
          asinPerformanceData?.length ? '' : 'disabled'
        }`}
        role="presentation"
        onClick={() => {
          handleDownloadReportEvents();
        }}
        style={{ maxWidth: '150px' }}
      >
        {downloadReportLoader ? (
          <PageLoader
            component="sidebar-loader"
            type="button"
            color={Theme?.orange}
          />
        ) : (
          <>
            <FontAwesomeIcon
              className="mr-2"
              color={Theme?.orange}
              icon="far fa-arrow-to-bottom"
            />
            Download Report
          </>
        )}
      </div>
    );
  };

  const renderASINTabs = () => {
    return (
      <ViewData>
        <div className="row">
          <div className="col-md-5 col-sm1-12 pr-0">
            <p className="black-heading-title d-inline-block">
              ASIN Performance
            </p>
            <Icon>
              <FontAwesomeIcon
                data-for="info-icon"
                data-tip={showASINToolTipContent}
                data-html
                icon="fa-regular fa-circle-info"
              />
              <ReactTooltip
                className="asian-info-icon"
                id="info-icon"
                aria-haspopup="true"
                place="bottom"
                effect="solid"
                html
                insecure
              />
            </Icon>
          </div>
          <div className="col-md-7 col-sm1-12 pl-0">
            <div className="view-data-filter-list">
              <ul>
                <li>
                  {selectedAccountType === SELLERSMALL ? (
                    <ToggleButton>
                      <div className="days-container  ">
                        <ul className="days-tab">
                          {asinTabOptions.map((item) => {
                            return (
                              <li
                                key={item.value}
                                id={`BT-${item.value}-toggle`}
                              >
                                <input
                                  className="d-none"
                                  type="radio"
                                  id={item.value}
                                  name={item.value}
                                  value={item.value}
                                  checked={activeASINTab === item.value}
                                  onClick={() => {
                                    setASINOrderByKey('current_revenue');
                                    setASINPerformanceFlag(true);
                                    setActiveASINTab(item.value);
                                  }}
                                  onChange={() => {}}
                                />
                                <label htmlFor={item.value}>{item.label}</label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </ToggleButton>
                  ) : null}
                </li>
                <li className=" sales-Performance-download-btn">
                  {displayDownloadReportButton()}
                </li>
              </ul>
            </div>
          </div>
        </div>{' '}
        {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in ASIN performance */}
        {salesAsinMessage ? (
          <GrayMessageBar message={salesAsinMessage} />
        ) : null}
      </ViewData>
    );
  };

  const handleSortFilters = (value) => {
    if (value === asinOrderByKey) {
      setASINPerformanceFlag(!asinPerformanceFlag);
    } else {
      setASINPerformanceFlag(true);
      setASINOrderByKey(value);
    }
  };

  // asin table soring keys
  const tableHeaderKeysForSorting = [
    'parent_asin',
    'child_asin',
    'sku',
    'product_name',
    'shipped_cogs_asin',
    'ordered_revenue_asin',
  ];

  const renderTableHeader = () => {
    let header = [];
    if (selectedAccountType === SELLERSMALL) {
      header = isParentASINActive
        ? parentASINTableheader
        : childASINTableheader;
    } else {
      header =
        metricsType === ORDEREDREVENUE
          ? vendorOrderRevenueASINTableheader
          : vendorShippedCogsASINTableheader;
    }
    return (
      <thead>
        <tr className={asinLoader ? 'disabled' : null}>
          {header.map((item) => {
            return (
              <th key={item?.key} width={item.width}>
                <span
                  className={
                    !tableHeaderKeysForSorting?.includes(item?.key)
                      ? 'cursor'
                      : ''
                  }
                  onClick={() => {
                    if (!tableHeaderKeysForSorting?.includes(item?.key)) {
                      handleSortFilters(item.key);
                    }
                  }}
                  aria-hidden="true"
                >
                  {item.key === ASIN
                    ? isParentASINActive
                      ? 'Parent Asin'
                      : 'Child Asin'
                    : item.label}
                  {!tableHeaderKeysForSorting?.includes(item?.key) && (
                    <FontAwesomeIcon
                      icon="far fa-chevron-down"
                      style={{
                        marginLeft: '4px',
                        fontSize: '12px',
                        cursor: 'pointer',
                        transform:
                          asinOrderByKey === item.key && asinPerformanceFlag
                            ? 'rotate(180deg)'
                            : '',
                        color:
                          asinOrderByKey === item.key
                            ? Theme.orange
                            : Theme.gray40,
                      }}
                    />
                  )}
                  {item?.key === 'current_buy_box_percentage' ? (
                    <Icon>
                      <FontAwesomeIcon
                        style={{
                          verticalAlign: 'text-bottom',
                          fontWeight: '300',
                        }}
                        className="ml-1 cursor "
                        data-for={`${item?.key}`}
                        data-tip="There is slight data discrepancy when date range filter is used due to API limitation."
                        data-html
                        icon="fa-regular fa-circle-info"
                      />
                      <ReactTooltip
                        className="w-10"
                        id={`${item?.key}`}
                        aria-haspopup="true"
                        place="bottom"
                        effect="solid"
                        html
                        insecure
                      />
                    </Icon>
                  ) : null}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  // display react tooltip
  const displayReactTooltip = (id, place) => {
    return (
      <ReactTooltip
        className="asian-info-icon"
        id={id}
        aria-haspopup="true"
        place={place}
        effect="solid"
        html
      />
    );
  };

  // display product name with tooltip
  const displayProductNameWithTooltip = (
    productName,
    index,
    link,
    showTooltip,
    isAsin,
  ) => {
    const textLimit = isExtraLargeDesktop ? 70 : isTablet ? 50 : 30;
    return (
      <div
        className={isExtraLargeDesktop ? 'pr-5' : isTablet ? 'pr-4' : 'pr-3'}
        style={{ position: 'relative' }}
      >
        <div
          className="text-ellipsis text-ellipsis-200"
          data-tip={`<div style="max-width:500px">${productName}</div>`}
          data-for={`product${index}`}
        >
          {productName?.length > textLimit
            ? `${productName?.slice(0, textLimit)}...`
            : productName}

          {showTooltip ? displayReactTooltip(`product${index}`, 'top') : null}
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              position: isAsin ? 'relative' : 'absolute',
              right: isAsin ? null : isTablet ? '10px' : 0,
              top: 0,
            }}
          >
            <FontAwesomeIcon
              className="ml-2 cursor"
              color={Theme.orange}
              icon="far fa-arrow-up-right-from-square"
              style={{ fontSize: '14px' }}
            />
          </a>
        </div>
      </div>
    );
  };

  // display seller asin table
  const renderSellerAsinTable = (item, index, formatNumber) => {
    const pdpLink = `${baseURLForPdpLink}${item?.asin}`;
    return (
      <tr key={item?.asin} className="text-medium-font">
        <td>
          {isParentASINActive
            ? displayProductNameWithTooltip(
                item?.asin,
                index,
                pdpLink,
                false,
                true,
              )
            : item?.asin}
          {customerState?.account_type === PARENT ? (
            <div className="text-extra-small text-medium-font">
              {item?.bp_name || ''}
            </div>
          ) : null}
        </td>
        {!isParentASINActive ? (
          <td>
            {displayProductNameWithTooltip(
              item?.product_name,
              index,
              pdpLink,
              true,
              false,
            )}
          </td>
        ) : null}

        <td>
          <div className="mr-2">
            {item?.current_revenue === null
              ? 'N/A'
              : formatNumber(
                  item?.current_revenue,
                  isAppliedCustom ? null : item?.revenue_change,
                  isAppliedCustom ? null : item?.revenue_percent_change,
                  2,
                  isSelectCustom,
                  currencySymbol,
                  '',
                  'small-font mt-1',
                )}
          </div>
        </td>
        <td>
          <div className="mr-2">
            {item?.current_traffic === null
              ? 'N/A'
              : formatNumber(
                  item?.current_traffic,
                  isAppliedCustom ? null : item?.traffic_change,
                  isAppliedCustom ? null : item?.traffic_percent_change,
                  0,
                  isSelectCustom,
                  '',
                  '',
                  'small-font mt-1',
                )}
          </div>
        </td>
        <td>
          <div className="mr-2">
            {item?.current_units_sold === null
              ? 'N/A'
              : formatNumber(
                  item?.current_units_sold,
                  isAppliedCustom ? null : item?.units_sold_change,
                  isAppliedCustom ? null : item?.units_sold_percent_change,
                  0,
                  isSelectCustom,
                  '',
                  '',
                  'small-font mt-1',
                )}
          </div>
        </td>
        <td>
          <div className="mr-2">
            {item?.current_conversion === null
              ? 'N/A'
              : formatNumber(
                  item?.current_conversion,
                  isAppliedCustom ? null : item?.conversion_change,
                  isAppliedCustom ? null : item?.conversion_percent_change,
                  2,
                  isSelectCustom,
                  '',
                  '%',
                  'small-font mt-1',
                )}
          </div>
        </td>
        <td>
          <div className="mr-2">
            {item?.current_buy_box_percentage === null
              ? 'N/A'
              : formatNumber(
                  item?.current_buy_box_percentage,
                  isAppliedCustom ? null : item?.buy_box_change,
                  isAppliedCustom ? null : item?.buy_box_percentage_change,
                  2,
                  isSelectCustom,
                  '',
                  '%',
                  'small-font mt-1',
                )}
          </div>
        </td>
      </tr>
    );
  };

  const renderVendorAsinTable = (itemData, index, formatNumber) => {
    const pdpLink = `${baseURLForPdpLink}${itemData?.asin}`;
    let item = {};
    if (metricsType === ORDEREDREVENUE) {
      item = {
        currencyValue: itemData?.current_ordered_revenue,
        currencyChange: itemData?.ordered_revenue_change,
        currencyPercentChange: itemData?.ordered_revenue_percent_change,

        unitValue: itemData?.current_ordered_unit,
        unitChange: itemData?.ordered_units_change,
        unitPercentChange: itemData?.ordered_units_percent_change,
      };
    } else if (metricsType === SHIPPEDCOGS) {
      item = {
        currencyValue: itemData?.current_shipped_cogs,
        currencyChange: itemData?.shipped_cogs_change,
        currencyPercentChange: itemData?.shipped_cogs_percent_change,

        unitValue: itemData?.current_shipped_unit,
        unitChange: itemData?.shipped_unit_change,
        unitPercentChange: itemData?.shipped_unit_percent_change,
      };
    }
    return (
      <tr key={itemData?.asin} className="text-medium-font">
        <td>
          {itemData?.asin}
          {customerState?.account_type === PARENT ? (
            <div className="text-extra-small text-medium-font">
              {itemData?.bp_name || ''}
            </div>
          ) : null}
        </td>

        <td>
          {displayProductNameWithTooltip(
            itemData?.product_name,
            index,
            pdpLink,
            true,
            false,
          )}
        </td>

        <td>
          <div className="mr-2">
            {item?.currencyValue === null
              ? 'N/A'
              : formatNumber(
                  item?.currencyValue,
                  isAppliedCustom ? null : item?.currencyChange,
                  isAppliedCustom ? null : item?.currencyPercentChange,
                  2,
                  isSelectCustom,
                  currencySymbol,
                  '',
                  'small-font mt-1',
                )}
          </div>
        </td>
        <td>
          <div className="mr-2">
            {itemData?.current_glance_view === null
              ? 'N/A'
              : formatNumber(
                  itemData?.current_glance_view,
                  isAppliedCustom ? null : itemData?.glance_views_change,
                  isAppliedCustom
                    ? null
                    : itemData?.glance_views_percent_change,
                  0,
                  isSelectCustom,
                  '',
                  '',
                  'small-font mt-1',
                )}
          </div>
        </td>
        <td>
          <div className="mr-2">
            {item?.unitValue === null
              ? 'N/A'
              : formatNumber(
                  item?.unitValue,
                  isAppliedCustom ? null : item?.unitChange,
                  isAppliedCustom ? null : item?.unitPercentChange,
                  0,
                  isSelectCustom,
                  '',
                  '',
                  'small-font mt-1',
                )}
          </div>
        </td>
        <td>
          <div className="mr-2">
            {itemData?.current_conversion === null
              ? 'N/A'
              : formatNumber(
                  itemData?.current_conversion,
                  isAppliedCustom ? null : itemData?.conversion_change,
                  isAppliedCustom ? null : itemData?.conversion_percent_change,
                  2,
                  isSelectCustom,
                  '',
                  '%',
                  'small-font mt-1',
                )}
          </div>
        </td>
      </tr>
    );
  };

  const renderDesktopView = (formatNumber) => {
    return (
      <>
        <DesktopTable className="mt-0">
          {renderTableHeader()}
          <tbody>
            {asinPerformanceData?.length
              ? asinPerformanceData.map((item, index) => {
                  return selectedAccountType === SELLERSMALL
                    ? renderSellerAsinTable(item, index, formatNumber)
                    : renderVendorAsinTable(item, index, formatNumber);
                })
              : null}
          </tbody>
        </DesktopTable>
      </>
    );
  };

  return (
    <div>
      <div className="straight-line horizontal-line mb-4 mt-3" />
      {renderASINTabs()}
      {isTablet ? (
        renderDesktopView(useValueWithChangeAndPercent)
      ) : (
        <>
          {asinPerformanceData?.map((item, index) => {
            return (
              <Fragment key={item?.asin}>
                {selectedAccountType === SELLERSMALL ? (
                  <SellerASINPerformanceMobileView
                    index={index}
                    isParentASINActive={isParentASINActive}
                    asin={item?.asin}
                    pdpLink={`${baseURLForPdpLink}${item?.asin}`}
                    productName={item?.product_name || 'Not available'}
                    buyBox={item?.current_buy_box_percentage}
                    buyBoxChange={item?.buy_box_change}
                    buyBoxPercentChange={item?.buy_box_percentage_change}
                    childBpName={item?.bp_name || ''}
                    revenue={item?.current_revenue}
                    revenueChange={item?.revenue_change}
                    revenuePercentChange={item?.revenue_percent_change}
                    traffic={item?.current_traffic}
                    trafficChange={item?.traffic_change}
                    trafficPercentChange={item?.traffic_percent_change}
                    units={item?.current_units_sold}
                    unitsChange={item?.units_sold_change}
                    unitsPercentChange={item?.units_sold_percent_change}
                    conversion={item.current_conversion}
                    conversionChange={item?.conversion_change}
                    conversionPercentChange={item?.conversion_percent_change}
                    currencySymbol={currencySymbol}
                    isAppliedCustom={isAppliedCustom}
                    customerState={customerState}
                    formatNumber={useValueWithChangeAndPercent}
                    displayProductNameWithTooltip={
                      displayProductNameWithTooltip
                    }
                  />
                ) : (
                  <VendorASINPerformanceMobileView
                    index={index}
                    metricsData={item}
                    metricsType={metricsType}
                    currencySymbol={currencySymbol}
                    isAppliedCustom={isAppliedCustom}
                    customerState={customerState}
                    formatNumber={useValueWithChangeAndPercent}
                    displayProductNameWithTooltip={
                      displayProductNameWithTooltip
                    }
                  />
                )}
              </Fragment>
            );
          })}
        </>
      )}

      {!asinLoader &&
      (!asinPerformanceData ||
        (asinPerformanceData && asinPerformanceData.length === 0)) ? (
        <NoData>No ASIN Performance Data Found</NoData>
      ) : null}

      {asinLoader ? (
        <PageLoader
          component="performance-graph"
          color={Theme.orange}
          type="detail"
          width={40}
          height={40}
        />
      ) : null}

      {asinPerformanceCount > 10 ? (
        <CommonPagination
          count={asinPerformanceCount}
          pageNumber={asinPerformancePageNumber}
          handlePageChange={(value) => {
            setASINPerformancePageNumber(value);
            onGetPerformanceASINData(value);
          }}
        />
      ) : null}
    </div>
  );
};

export default ASINPerformance;

ASINPerformance.defaultProps = {
  isAsinApiCallCustom: 0,
  marketplace: '',
  selectedAccountType: '',
  metricsType: '',
  currencySymbol: null,
  selectedChildBrand: {},
};

ASINPerformance.propTypes = {
  isAsinApiCallCustom: number,
  customerId: string.isRequired,
  marketplace: string,
  selectedAccountType: string,
  currencySymbol: string,
  metricsType: string,
  selectedChildBrand: shape({}),
  isSelectCustom: bool.isRequired,
  selectedPeriod: shape({}).isRequired,
  customDate: arrayOf(shape({})).isRequired,
};
