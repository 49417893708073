import dayjs from 'dayjs';
import axiosInstance from '../axios';
import {
  API_AD_MANAGER_ADMIN_DASHBOARD,
  API_AD_DASHBOARD,
  API_DSP_INVOICES,
  API_DSP_BILLING,
  API_CUSTOMER_CONTRACT,
  API_SALES_DASHBOARD,
  metricsNameForAPI,
  API_BGS_COMMISSION_DETAILS,
  API_BGS_COMMISSION_INDIVIDUALS,
  API_BGS_COMMISSION_MATRICS,
  API_BGS_COMMISSION_GROUP_BY_MANAGER,
  API_BBE_GOAL_DASHBOARD_CONTRIBUTION,
  API_BBE_GOAL_METRICS,
  API_GOAL_TARGET_MSG,
  API_GOAL_PERFORMANCE_DATA,
  API_VENDOR_ORDERED_REVENUE_SALES_DASHBOARD,
  API_VENDOR_SHIPPED_COGS_SALES_DASHBOARD,
  CUSTOM,
  ORDEREDREVENUE,
  SHIPPEDCOGS,
  SELLERSMALL,
  SELLER,
  VENDOR,
  API_LEADERSHIP_CUSTOMERS,
  API_KEY_CONTRIBUTORS_DOWNLOAD_REPORT,
  YEAROVERYEAR,
} from '../constants';

// get goals data of leadership dashboard
export async function getGoalPerformanceData(
  orderBy,
  brandPartner,
  marketplaces,
  startDate,
  endDate,
  managerUserString,
  bgsUserString,
  pageNumber,
  isBGS,
  isBGSManager,
  isBGSAdmin,
  includeInactiveBPs,
  includeSpreetailBrands,
) {
  let params = {
    customer: brandPartner !== 'all' ? brandPartner : null,
    order_by: orderBy,
    marketplace: marketplaces,
    start_date: startDate,
    end_date: endDate,
    user: bgsUserString,
    page: pageNumber,
    show_inactive_bps: includeInactiveBPs,
    show_third_party_potential_brands: includeSpreetailBrands,
  };

  if (isBGS) {
    params = {
      ...params,
      bgs_manager: 'all',
    };
  }
  if (isBGSManager || isBGSAdmin) {
    params = {
      ...params,
      bgs_manager: managerUserString,
    };
  }

  const result = await axiosInstance
    .get(API_GOAL_PERFORMANCE_DATA, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
}

// https://bbe.atlassian.net/browse/PDV-8757 - leadership dashboard improvements
// Async funtion for get brand partners list in leadership dashboard
export async function getLeadershipBrandPartners(
  role,
  dashboardName,
  managerUserId,
  userId,
  includeInactiveBPs,
  includeSpreetailBrands,
) {
  let params = {
    'order-by': 'company_name',
    dashboard: dashboardName,
    user: userId,
    show_inactive_bps: includeInactiveBPs,
    show_third_party_potential_brands: includeSpreetailBrands,
  };

  if (role === 'BGS Admin' || role === 'BGS Manager') {
    params = {
      ...params,
      bgs_manager: managerUserId,
    };
  }

  if (role === 'Ad Manager Admin' || role === 'Ad Team Manager') {
    params = {
      ...params,
      ad_team_manager: managerUserId,
    };
  }

  const result = await axiosInstance
    .get(API_LEADERSHIP_CUSTOMERS, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// https://bbe.atlassian.net/browse/PDV-8757 - leadership dashboard improvements
// Async funtion for get key contributors download report
export async function getKeyContributorsdownloadReport(
  isBGSAdmin,
  isBGSManager,
  isBGS,
  isAdManagerAdmin,
  isAdTeamManager,
  isAdManager,
  dashboardName,
  dailyFacts,
  brandPartner,
  marketplace,
  metricName,
  managerUsers,
  users,
  startDate,
  endDate,
  includeInactiveBPs,
  includeSpreetailBrands,
  accountType = null,
) {
  // configuring API params
  let params = {
    dashboard: dashboardName,
    customer: brandPartner !== 'all' ? brandPartner : null,
    daily_facts: dailyFacts,
    marketplace,
    metric: metricsNameForAPI[metricName],
    user: users,
    no_pagination: true,
    show_inactive_bps: includeInactiveBPs,
    show_third_party_potential_brands: includeSpreetailBrands,
    customer_account_type:
      dashboardName === 'sponsored_ad_dashboard'
        ? accountType === SELLERSMALL
          ? SELLER
          : VENDOR
        : null,
  };

  if (dailyFacts === YEAROVERYEAR || metricName === 'goals') {
    params = { ...params, start_date: startDate, end_date: endDate };
  }

  if (isBGS) {
    params = {
      ...params,
      bgs_manager: 'all',
    };
  }
  if (isBGSManager || isBGSAdmin) {
    params = {
      ...params,
      bgs_manager: managerUsers,
    };
  }
  if (isAdManager) {
    params = {
      ...params,
      ad_team_manager: 'all',
    };
  }
  if (isAdTeamManager || isAdManagerAdmin) {
    params = {
      ...params,
      ad_team_manager: managerUsers,
    };
  }

  // await method
  const result = await axiosInstance
    .get(API_KEY_CONTRIBUTORS_DOWNLOAD_REPORT, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
  return result;
}

export async function getAdManagerAdminGraphData(
  dashboardType,
  adType,
  dailyFacts,
  groupBy,
  brandPartner,
  marketplace,
  performanceType,
  managerUser,
  bgsUser,
  isBGSAdmin,
  isBGSManager,
  isBGS,
  isAdManagerAdmin,
  isAdTeamManager,
  isAdManager,
  startDate,
  endDate,
  includeInactiveBPs,
  includeSpreetailBrands,
) {
  let params = {
    daily_facts: dailyFacts,
    group_by: groupBy,
    marketplace,
    user: bgsUser,
  };

  if (brandPartner !== 'all') {
    params = { ...params, customer: brandPartner };
  }

  if (dashboardType !== 'dsp-dashboard') {
    params = { ...params, account_type: performanceType };
  }

  if (isBGS) {
    params = {
      ...params,
      bgs_manager: 'all',
    };
  }
  if (isBGSManager || isBGSAdmin) {
    params = {
      ...params,
      bgs_manager: managerUser,
    };
  }
  if (isAdManager) {
    params = {
      ...params,
      ad_team_manager: 'all',
    };
  }
  if (isAdTeamManager || isAdManagerAdmin) {
    params = {
      ...params,
      ad_team_manager: managerUser,
    };
  }

  if (startDate && endDate) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }

  if (dashboardType === 'sponsored-dashboard') {
    params = {
      ...params,
      sponsored_type: adType,
    };
  }

  params = {
    ...params,
    show_inactive_bps: includeInactiveBPs,
    show_third_party_potential_brands: includeSpreetailBrands,
  };

  const result = await axiosInstance
    .get(`${API_AD_DASHBOARD}${dashboardType}/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getKeyContributionData(
  dashboardType,
  adType,
  dailyFacts,
  brandPartner,
  marketplace,
  performanceType,
  managerUser,
  bgsUser,
  isBGSAdmin,
  isBGSManager,
  isBGS,
  isAdManagerAdmin,
  isAdTeamManager,
  isAdManager,
  selectedMetric,
  startDate,
  endDate,
  pageNumber,
  includeInactiveBPs,
  includeSpreetailBrands,
) {
  const metricName = metricsNameForAPI[selectedMetric];

  let params = {
    dashboard: dashboardType,
    daily_facts: dailyFacts,
    marketplace,
    page: pageNumber,
    user: bgsUser,
  };

  if (brandPartner !== 'all') {
    params = { ...params, customer: brandPartner };
  }

  if (dashboardType !== 'dsp_ad_performance') {
    params = {
      ...params,
      customer_account_type: performanceType === SELLERSMALL ? SELLER : VENDOR,
    };
  }

  if (isBGS) {
    params = {
      ...params,
      bgs_manager: 'all',
    };
  }
  if (isBGSManager || isBGSAdmin) {
    params = {
      ...params,
      bgs_manager: managerUser,
    };
  }
  if (isAdManager) {
    params = {
      ...params,
      ad_team_manager: 'all',
    };
  }
  if (isAdTeamManager || isAdManagerAdmin) {
    params = {
      ...params,
      ad_team_manager: managerUser,
    };
  }

  if (startDate && endDate) {
    params = {
      ...params,
      start_date: dayjs(startDate).format('DD-MM-YYYY'),
      end_date: dayjs(endDate).format('DD-MM-YYYY'),
    };
  }

  params = {
    ...params,
    metric: metricName,
  };

  if (dashboardType === 'sponsored_ad_dashboard') {
    params = {
      ...params,
      sponsored_type: adType,
    };
  }

  params = {
    ...params,
    show_inactive_bps: includeInactiveBPs,
    show_third_party_potential_brands: includeSpreetailBrands,
  };

  let result = {};
  result = await axiosInstance
    .get(`${API_AD_MANAGER_ADMIN_DASHBOARD}key-contributors/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDspPacingDahboardData(
  brandPartner,
  marketplace,
  managerUser,
  bgsUser,
  spendingType,
  isBGSAdmin,
  isBGSManager,
  isBGS,
  isAdManagerAdmin,
  isAdTeamManager,
  isAdManager,
  includeInactiveBPs,
  includeSpreetailBrands,
) {
  let params = {
    daily_facts: 'month',
    marketplace,
    order_by: spendingType,
    user: bgsUser,
  };

  if (brandPartner !== 'all') {
    params = { ...params, customer: brandPartner };
  }

  if (isBGS) {
    params = {
      ...params,
      bgs_manager: 'all',
    };
  }
  if (isBGSManager || isBGSAdmin) {
    params = {
      ...params,
      bgs_manager: managerUser,
    };
  }

  if (isAdManager) {
    params = {
      ...params,
      ad_team_manager: 'all',
    };
  }

  if (isAdTeamManager || isAdManagerAdmin) {
    params = {
      ...params,
      ad_team_manager: managerUser,
    };
  }

  params = {
    ...params,
    show_inactive_bps: includeInactiveBPs,
    show_third_party_potential_brands: includeSpreetailBrands,
  };

  let result = {};
  result = await axiosInstance
    .get(`${API_AD_DASHBOARD}dsp-pacing-dashboard/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// async function for sales dashboard - metrics and graph data
export async function getSalesGraphData(
  dailyFacts,
  groupBy,
  brandPartner,
  marketplace,
  managerUser,
  bgsUser,
  isBGSAdmin,
  isBGSManager,
  isBGS,
  isAdManagerAdmin,
  isAdTeamManager,
  isAdManager,
  startDate,
  endDate,
  includeInactiveBPs,
  includeSpreetailBrands,
) {
  let params = {
    customer: brandPartner !== 'all' ? brandPartner : null,
    daily_facts: dailyFacts,
    group_by: groupBy,
    marketplace,
    user: bgsUser,
  };

  if (isBGS) {
    params = {
      ...params,
      bgs_manager: 'all',
    };
  }
  if (isBGSManager || isBGSAdmin) {
    params = {
      ...params,
      bgs_manager: managerUser,
    };
  }
  if (isAdManager) {
    params = {
      ...params,
      ad_team_manager: 'all',
    };
  }
  if (isAdTeamManager || isAdManagerAdmin) {
    params = {
      ...params,
      ad_team_manager: managerUser,
    };
  }

  if (startDate && endDate) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }
  params = {
    ...params,
    show_inactive_bps: includeInactiveBPs,
    show_third_party_potential_brands: includeSpreetailBrands,
  };

  const result = await axiosInstance
    .get(`${API_AD_DASHBOARD}${API_SALES_DASHBOARD}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// async function for sales dashboard - contribution data
export async function getSalesKeyContributionData(
  dailyFacts,
  brandPartner,
  marketplace,
  managerUser,
  bgsUser,
  selectedMetric,
  vendorMetricsType,
  isBGSAdmin,
  isBGSManager,
  isBGS,
  isAdManagerAdmin,
  isAdTeamManager,
  isAdManager,
  startDate,
  endDate,
  pageNumber,
  includeInactiveBPs,
  includeSpreetailBrands,
) {
  const metricName = metricsNameForAPI[selectedMetric];

  let params = {
    customer: brandPartner !== 'all' ? brandPartner : null,
    daily_facts: dailyFacts,
    dashboard:
      vendorMetricsType === ORDEREDREVENUE
        ? 'ordered_revenue'
        : vendorMetricsType === SHIPPEDCOGS
        ? 'shipped_cogs'
        : 'sales_performance',
    marketplace,
    user: bgsUser,
    metric: metricName,
    page: pageNumber,
    show_inactive_bps: includeInactiveBPs,
    show_third_party_potential_brands: includeSpreetailBrands,
  };

  if (isBGS) {
    params = {
      ...params,
      bgs_manager: 'all',
    };
  }
  if (isBGSManager || isBGSAdmin) {
    params = {
      ...params,
      bgs_manager: managerUser,
    };
  }

  if (isAdManager) {
    params = {
      ...params,
      ad_team_manager: 'all',
    };
  }
  if (isAdTeamManager || isAdManagerAdmin) {
    params = {
      ...params,
      ad_team_manager: managerUser,
    };
  }

  if (startDate && endDate) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }

  const result = await axiosInstance
    .get(`${API_AD_MANAGER_ADMIN_DASHBOARD}key-contributors/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
}

// async function for get vendor sales dashboard  - metrics and graph data
export async function getVendorSalesPerformanceData(
  dailyFacts,
  groupBy,
  brandPartner,
  marketplace,
  managerUser,
  bgsUser,
  isBGSAdmin,
  isBGSManager,
  isBGS,
  isAdManagerAdmin,
  isAdTeamManager,
  isAdManager,
  startDate,
  endDate,
  includeInactiveBPs,
  includeSpreetailBrands,
  vendorMetricsType,
) {
  let params = {
    customer: brandPartner !== 'all' ? brandPartner : null,
    daily_facts: dailyFacts,
    group_by: groupBy,
    marketplace,
    user: bgsUser,
  };

  if (isBGS) {
    params = {
      ...params,
      bgs_manager: 'all',
    };
  }
  if (isBGSManager || isBGSAdmin) {
    params = {
      ...params,
      bgs_manager: managerUser,
    };
  }
  if (isAdManager) {
    params = {
      ...params,
      ad_team_manager: 'all',
    };
  }
  if (isAdTeamManager || isAdManagerAdmin) {
    params = {
      ...params,
      ad_team_manager: managerUser,
    };
  }

  if (startDate && endDate) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }
  params = {
    ...params,
    show_inactive_bps: includeInactiveBPs,
    show_third_party_potential_brands: includeSpreetailBrands,
  };

  let API = '';
  if (vendorMetricsType === ORDEREDREVENUE) {
    API = API_VENDOR_ORDERED_REVENUE_SALES_DASHBOARD;
  } else {
    API = API_VENDOR_SHIPPED_COGS_SALES_DASHBOARD;
  }

  const result = await axiosInstance
    .get(`${API}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getFinanceInvoices(
  type,
  searchKey,
  status,
  sortBy,
  timeFilterType,
  timeFilter,
  page,
  selectedNavigation,
) {
  let params = {
    page,
    type,
    invoice_type: selectedNavigation,
  };

  if (sortBy !== '') {
    if (type === 'partner') {
      params = {
        ...params,
        sort: `-${sortBy}`,
      };
    } else {
      params = {
        ...params,
        sort_by: `-${sortBy}`,
      };
    }
  }

  if (searchKey !== '') {
    params = {
      ...params,
      q: searchKey,
    };
  }

  if (timeFilterType === CUSTOM) {
    params = {
      ...params,
      timeframe: timeFilterType,
      start_month_year: timeFilter.startDate,
      end_month_year: timeFilter.endDate,
    };
  }

  if (status !== 'any') {
    params = {
      ...params,
      invoice_status: status,
    };
  }

  let result = {};
  result = await axiosInstance
    .get(`${API_DSP_INVOICES}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDSPFinances(
  timeFilter,
  startDate,
  endDate,
  selectedNavigation,
) {
  let params = {
    invoice_type: selectedNavigation,
  };

  if (timeFilter === CUSTOM) {
    params = {
      ...params,
      start_month_year: startDate,
      end_month_year: endDate,
      timeframe: timeFilter,
    };
  }

  let result = {};
  result = await axiosInstance
    .get(`${API_DSP_INVOICES}finance-detail/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getBills(
  searchKey,
  vendor,
  sortBy,
  page,
  timeFilterType,
  timeFilter,
) {
  let params = {
    page,
    vendor,
  };

  if (sortBy !== '') {
    params = {
      ...params,
      'order-by': `-${sortBy}`,
    };
  }

  if (searchKey !== '') {
    params = {
      ...params,
      q: searchKey,
    };
  }

  if (timeFilterType === CUSTOM) {
    params = {
      ...params,
      timeframe: timeFilterType,
      start_month_year: timeFilter.startDate,
      end_month_year: timeFilter.endDate,
    };
  }

  let result = {};
  result = await axiosInstance
    .get(`${API_DSP_BILLING}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDSPBillingMetrics(timeFilter, startDate, endDate) {
  let params = {};

  if (timeFilter === CUSTOM) {
    params = {
      ...params,
      start_month_year: startDate,
      end_month_year: endDate,
      timeframe: timeFilter,
    };
  }

  let result = {};
  result = await axiosInstance
    .get(`${API_DSP_BILLING}bill-detail/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getEnableInvoices(page) {
  const params = { is_invoicing_enable: 'False', page };
  let result = {};

  result = await axiosInstance
    .get(`${API_CUSTOMER_CONTRACT}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function setEnableInvoices(id) {
  const result = await axiosInstance
    .patch(`${API_CUSTOMER_CONTRACT}${id}/`, { is_invoicing_enable: true })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getBgsCommissionMatrics(date) {
  const params = {
    start_date: `${date[0].getFullYear()}-${
      date[0].getMonth() + 1
    }-${date[0].getDate()}`,
    end_date: `${date[1].getFullYear()}-${
      date[1].getMonth() + 1
    }-${date[1].getDate()}`,
  };

  const result = await axiosInstance
    .get(API_BGS_COMMISSION_MATRICS, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getBgsCommissionGroupByTable(
  date,
  orderBy = 'full_name',
) {
  const params = {
    start_date: `${date[0].getFullYear()}-${
      date[0].getMonth() + 1
    }-${date[0].getDate()}`,
    end_date: `${date[1].getFullYear()}-${
      date[1].getMonth() + 1
    }-${date[1].getDate()}`,
    'order-by': `${orderBy}`,
  };

  const result = await axiosInstance
    .get(API_BGS_COMMISSION_GROUP_BY_MANAGER, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getBgsCommissionTableIndividualsData(date, orderBy) {
  const params = {
    start_date: `${date[0].getFullYear()}-${
      date[0].getMonth() + 1
    }-${date[0].getDate()}`,
    end_date: `${date[1].getFullYear()}-${
      date[1].getMonth() + 1
    }-${date[1].getDate()}`,
    'order-by': `${orderBy}`,
  };

  const result = await axiosInstance
    .get(API_BGS_COMMISSION_INDIVIDUALS, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getBgsBrandPartners(bgs, startDate, endDate) {
  const { isBgsManager, managerId, id } = bgs;
  const params = {
    start_date: startDate,
    end_date: endDate,
    manager_id: !isBgsManager ? managerId : id,
  };

  const result = await axiosInstance
    .get(`${API_BGS_COMMISSION_DETAILS + id}/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getRevShareContributionData(month) {
  const params = {
    date: dayjs(month).format('YYYY-MM-DD'),
  };

  const result = await axiosInstance
    .get(`${API_BBE_GOAL_DASHBOARD_CONTRIBUTION}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
}

export async function getBBEGoalMetrics(monthYear) {
  const params = {
    date: dayjs(monthYear).format('YYYY-MM-DD'),
  };

  const result = await axiosInstance
    .get(API_BBE_GOAL_METRICS, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
}

export async function getGoalTargetInsightMsg() {
  const result = await axiosInstance
    .get(API_GOAL_TARGET_MSG)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
}
