/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';

import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { string, func, shape, arrayOf, bool } from 'prop-types';

import AgreementAprovalConditions from './AgreementAprovalConditions';
// import { ThadProfileImg } from '../../../theme/images';
import { PATH_CUSTOMER_DETAILS } from '../../../constants';
import {
  createTransactionData,
  getApprovalUserDetails,
  updateAccountDetails,
} from '../../../api';
import {
  FormField,
  PageLoader,
  DropDownIndicator,
  CustomDropDown,
} from '../../../common';

function RequestApproval({
  selectedContact,
  agreementData,
  setIsLoading,
  setShowModal,
  removeParams,
  getContractDetails,
  userInfo,
  pdfData,
  isLoading,
  renderbuttonHtml,
  contractAddendumDetails,
  originalData,
  formData,
  newAddendumData,
  selectedCurrency,
  contactInfo,
  id,
  contactInfoLoading,
}) {
  const multipleAgreement = useSelector(
    (state) => state.accountState.multipleAgreement,
  );

  // commented as per ticekt- 8409 - handled approval user on BE flag
  const firstRequestApprovalUser = userInfo?.is_primary_request_approvers;
  const dspOrAmcRequestApprovalUser =
    userInfo?.is_dsp_amc_primary_request_approvers;
  const [approvalNote, setApprovalNote] = useState({});
  const [approvalCondition, setApprovalCondition] = useState(null);
  const [contactOptions, setContactOptions] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [isApiCall, setIsApiCall] = useState(false);
  const [selectedPrimaryApprovalUser, setSelectedPrimaryApprovalUser] =
    useState(null);
  const [selectedFinalApprovalUser, setSelectedFinalApprovalUser] =
    useState(null);
  const [finalApprovalUsersList, setFinalApprovalUsersList] = useState(null);
  const [approvalUserOptions, setApprovalUserOptions] = useState(null);

  const { Option } = components;

  const getPrimaryApprovalUser = useCallback(() => {
    setIsApiCall(true);
    getApprovalUserDetails().then((res) => {
      if (res?.status === 200) {
        const primaryApprovalUsers = [];
        const finalApprovalUsers = [];
        const dspAmcApprovalUsers = [];

        //  bind the primary request approvers
        for (const user of res?.data?.primary_request_approvers) {
          let profilePic = null;
          if (user?.documents.length) {
            const picObject = Object.keys(user?.documents?.[0]);
            profilePic = user?.documents?.[0][picObject[0]];
          }

          primaryApprovalUsers.push({
            firstName: user?.first_name,
            lastName: user?.last_name,
            email: user.email,
            label: `${user?.first_name} ${user?.last_name}`,
            value: user?.id,
            profilePic,
          });
        }

        //  bind the Final request approvers
        for (const user of res?.data?.final_request_approvers) {
          let profilePic = null;
          if (user?.documents.length) {
            const picObject = Object.keys(user?.documents?.[0]);
            profilePic = user?.documents?.[0][picObject[0]];
          }

          finalApprovalUsers.push({
            firstName: user?.first_name,
            lastName: user?.last_name,
            email: user.email,
            label: `${user?.first_name} ${user?.last_name}`,
            value: user?.id,
            profilePic,
          });
        }

        //  bind the DSP/AMC request approvers
        for (const user of res?.data?.dsp_amc_primary_request_approvers) {
          let profilePic = null;
          if (user?.documents.length) {
            const picObject = Object.keys(user?.documents?.[0]);
            profilePic = user?.documents?.[0][picObject[0]];
          }

          dspAmcApprovalUsers.push({
            firstName: user?.first_name,
            lastName: user?.last_name,
            email: user.email,
            label: `${user?.first_name} ${user?.last_name}`,
            value: user?.id,
            profilePic,
          });
        }

        // if contract is dsp OR amc only and
        // logged in user has a dsp_amc_approval flag set as true then
        // we need to display final approvers list
        if (
          dspOrAmcRequestApprovalUser &&
          ['dsp only', 'amc only'].includes(agreementData?.contract_type)
        ) {
          setApprovalUserOptions(finalApprovalUsers);
        } else if (
          !firstRequestApprovalUser &&
          ['dsp only', 'amc only'].includes(agreementData?.contract_type)
        ) {
          // if contract is dsp OR amc only then
          // we need to display primary approver + DSP AMC approver
          setApprovalUserOptions([
            ...primaryApprovalUsers,
            ...dspAmcApprovalUsers,
          ]);
        } else {
          // regardless of contract type, show list as per primary and final approver flag.
          setApprovalUserOptions(
            firstRequestApprovalUser
              ? finalApprovalUsers
              : primaryApprovalUsers,
          );
        }
        setFinalApprovalUsersList(finalApprovalUsers);
        setSelectedFinalApprovalUser({
          firstName: res?.data?.final_request_approvers?.[0]?.first_name,
          lastName: res?.data?.final_request_approvers?.[0]?.last_name,
          email: res?.data?.final_request_approvers?.[0]?.email,
          label: `${res?.data?.final_request_approvers?.[0]?.first_name} ${res?.data?.final_request_approvers?.[0]?.last_name}`,
          value: res?.data?.final_request_approvers?.[0]?.id,
          profilePic: res?.data?.final_request_approvers?.[0]?.documents.length
            ? res?.data?.final_request_approvers?.[0]?.documents?.[0][
                Object.keys(
                  res?.data?.final_request_approvers?.[0]?.documents?.[0],
                )
              ]
            : null,
        });

        setSelectedPrimaryApprovalUser({
          firstName: res?.data?.primary_request_approvers?.[0]?.first_name,
          lastName: res?.data?.primary_request_approvers?.[0]?.last_name,
          email: res?.data?.primary_request_approvers?.[0]?.email,
          label: `${res?.data?.primary_request_approvers?.[0]?.first_name} ${res?.data?.primary_request_approvers?.[0]?.last_name}`,
          value: res?.data?.primary_request_approvers?.[0]?.id,
          profilePic: res?.data?.primary_request_approvers?.[0]?.documents
            .length
            ? res?.data?.primary_request_approvers?.[0]?.documents?.[0][
                Object.keys(
                  res?.data?.primary_request_approvers?.[0]?.documents?.[0],
                )
              ]
            : null,
        });
        setIsApiCall(false);
      } else {
        setApprovalUserOptions([]);
        setIsApiCall(false);
      }
    });
  }, [id]);

  useEffect(() => {
    getPrimaryApprovalUser();
  }, []);

  // bind the agreement recipient dropdown options
  useEffect(() => {
    const contacts = [];
    for (const contact of contactInfo) {
      contacts.push({
        value: contact.id,
        label: `${contact?.first_name} ${contact?.last_name}`,
        email: contact.email,
      });
    }
    setContactOptions(contacts);
  }, [contactInfo]);

  const onRequestApprovalChange = (event) => {
    setApprovalNote({
      ...approvalNote,
      [event.target.name]: event.target.value,
    });
  };

  const mapValue = (key) => {
    if (key === 'printed_name') {
      return `${
        selectedContact?.first_name ? selectedContact.first_name : ''
      } ${selectedContact?.last_name ? selectedContact.last_name : ''} `;
    }
    if (key === 'customer_role') {
      return `${selectedContact?.role ? selectedContact.role : ''}`;
    }
    return '';
  };

  const noSignedAgreement = () => {
    if (
      multipleAgreement.find(
        (op) =>
          op?.contract_status?.value === 'active' ||
          op?.contract_status?.value === 'renewed',
      )
    )
      return true;
    return false;
  };

  const selectOption = (dataProps) => {
    return (
      <div>
        <Option {...dataProps}>
          <label
            htmlFor={dataProps.value}
            className="check-container customer-pannel">
            {dataProps.label}
          </label>
          <br />
          <small>{dataProps?.data?.email}</small>
        </Option>
      </div>
    );
  };

  const getAllComponents = () => {
    return {
      Option: selectOption,
      DropDownIndicator,
    };
  };

  const displayApprovalInfo = () => {
    const userlist = approvalUserOptions?.length
      ? approvalUserOptions
      : finalApprovalUsersList;
    return (
      <>
        {noSignedAgreement() || contractAddendumDetails.notActive ? (
          <>
            {userlist.map((user) => {
              return (
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="edit-profile-text mb-3">
                      <>
                        {user?.profilePic ? (
                          <img
                            src={user?.profilePic}
                            className="default-user-activity"
                            alt="pic"
                          />
                        ) : (
                          <>
                            {user?.firstName ? (
                              <div className="avatarName mr-3">
                                {(user?.firstName &&
                                  user?.firstName.charAt(0)) ||
                                  ''}
                                {(user?.lastName && user?.lastName.charAt(0)) ||
                                  ''}
                              </div>
                            ) : null}
                          </>
                        )}
                      </>

                      <div className="name-email">
                        <div className="team-member-name">
                          {`${user?.firstName} ${user?.lastName}`}
                        </div>
                        <span>{user?.email}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {finalApprovalUsersList.map((user) => {
              return (
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="edit-profile-text mb-3">
                      <>
                        {user?.profilePic ? (
                          <img
                            src={user?.profilePic}
                            className="default-user-activity"
                            alt="pic"
                          />
                        ) : (
                          <>
                            {user?.firstName ? (
                              <div className="avatarName mr-2">
                                {(user?.firstName &&
                                  user?.firstName.charAt(0)) ||
                                  ''}
                                {(user?.lastName && user?.lastName.charAt(0)) ||
                                  ''}
                              </div>
                            ) : null}
                          </>
                        )}
                      </>

                      <div className="name-email">
                        <div className="team-member-name">
                          {`${user?.firstName} ${user?.lastName}`}
                        </div>
                        <span>{user?.email}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}

        {approvalCondition?.length ? (
          <div style={{ color: 'black' }}>
            <FontAwesomeIcon
              icon=" fal fa-info-circle"
              style={{
                fontSize: '15px',
                color: '#FF5933',
                marginLeft: '5px',
                marginRight: '6px',
                verticalAlign: 'middle',
              }}
            />
            Agreement conditions which require approval(s):
            <ul>
              {approvalCondition.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        ) : null}
        {!contactInfoLoading ? (
          <div className="row mb-3 mt-2">
            <div className="col-12">
              <CustomDropDown>
                <Select
                  classNamePrefix="react-select"
                  placeholder="Select Agreement Recipient *"
                  options={contactOptions}
                  components={getAllComponents()}
                  onChange={(event) => setSelectedRecipient(event)}
                  value={selectedRecipient}
                  isDisabled={contactOptions?.length === 0}
                />
                {contactOptions?.length === 0 ? (
                  <small style={{ fontSize: '13px' }}>
                    Please add company contacts to select the agreement
                    recipient
                    <a
                      style={{ fontSize: '13px', textTransform: 'lowercase' }}
                      href={`${PATH_CUSTOMER_DETAILS.replace(
                        ':id',
                        id,
                      )}?tab=company-details&access=internal`}>
                      here
                    </a>
                    .
                  </small>
                ) : null}
              </CustomDropDown>
            </div>
          </div>
        ) : null}
        <FormField>
          <textarea
            className="form-control "
            type="text"
            rows="3"
            placeholder="Add personal note to recipient"
            name="note"
            onChange={(event) => onRequestApprovalChange(event)}
          />
        </FormField>
      </>
    );
  };

  const sendRequestApproval = () => {
    const addendumData = {
      contract_addendum: contractAddendumDetails?.id,
      contract: originalData?.id,
      contract_status: 'pending addendum approval',
      addendum_status: 'pending addendum approval',
    };
    const contractData = {
      contract: agreementData.id,
      contract_status: 'pending contract approval',
    };

    const requestApprovalData = contractAddendumDetails.notActive
      ? {
          ...approvalNote,
          ...addendumData,
          user: userInfo.id,
          contract_data: pdfData
            .replaceAll('CUSTOMER_PRINTED_NAME', mapValue('printed_name'))
            .replace('CUSTOMER_ROLE', mapValue('customer_role')),
        }
      : {
          ...approvalNote,
          ...contractData,
          user: userInfo.id,
          contract_data: pdfData
            .replaceAll('CUSTOMER_PRINTED_NAME', mapValue('printed_name'))
            .replace('CUSTOMER_ROLE', mapValue('customer_role')),
        };

    if (noSignedAgreement() || contractAddendumDetails.notActive) {
      requestApprovalData.primary_email = firstRequestApprovalUser
        ? selectedFinalApprovalUser.email
        : selectedPrimaryApprovalUser.email;
    } else {
      requestApprovalData.primary_email = selectedFinalApprovalUser?.email;
    }
    setIsLoading({ loader: true, type: 'button' });
    updateAccountDetails(originalData?.id, {
      suggested_recipient: selectedRecipient?.value,
    }).then((res) => {
      if (res?.status === 200) {
        createTransactionData(requestApprovalData).then((response) => {
          if (response && response.status === 400) {
            setIsLoading({ loader: false, type: 'button' });
          } else if (response && response.status === 201) {
            setShowModal(false);
            removeParams('step');
            toast.success('Approval Requested!');
            getContractDetails();
            setIsLoading({ loader: false, type: 'button' });
          }
        });
      }
    });
  };

  return (
    <>
      <>
        <div className="modal-body on-boarding">
          <h4 className="on-boarding mb-4">Request Approval</h4>
          {isApiCall || (isLoading.loader && isLoading.type === 'page') ? (
            <PageLoader
              className="modal-loader"
              color="#FF5933"
              type="page"
              width={40}
            />
          ) : (
            <>
              {displayApprovalInfo()}
              <div className="mt-4">
                {renderbuttonHtml(
                  !selectedRecipient,
                  () => sendRequestApproval(),
                  'Request Approval',
                )}
              </div>
            </>
          )}
        </div>
      </>
      <AgreementAprovalConditions
        formData={formData}
        newAddendumData={newAddendumData}
        approvalCondition={approvalCondition}
        setApprovalCondition={setApprovalCondition}
        selectedCurrency={selectedCurrency}
      />
    </>
  );
}

export default RequestApproval;

RequestApproval.defaultProps = {
  selectedContact: {},
  agreementData: {},
  contractAddendumDetails: {},
  setIsLoading: () => {},
  setShowModal: () => {},
  removeParams: () => {},
  getContractDetails: () => {},
  userInfo: {},
  pdfData: '',
  id: '',
  isLoading: {},
  renderbuttonHtml: () => {},
  originalData: {},
  newAddendumData: {},
  formData: {},
  selectedCurrency: '$ (USD)',
  contactInfo: [],
  contactInfoLoading: false,
};

RequestApproval.propTypes = {
  selectedContact: shape({}),
  agreementData: shape({}),
  contractAddendumDetails: shape({}),

  setIsLoading: func,
  setShowModal: func,
  removeParams: func,
  getContractDetails: func,
  userInfo: shape({}),
  pdfData: string,
  id: string,
  isLoading: shape({}),
  renderbuttonHtml: func,
  originalData: shape({ id: string }),
  newAddendumData: shape({}),
  formData: shape({}),
  selectedCurrency: string,
  contactInfo: arrayOf(shape({})),
  contactInfoLoading: bool,
};
