import React, { useState } from 'react';

import queryString from 'query-string';
import { func, shape } from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';

import { InputSearchWithRadius } from '../../common';
import { SearchIcon, RemoveIcon, ReadBookIcon } from '../../theme/images';
import { getArticleSearch, getArticleBoards } from '../../api';
import { PATH_ARTICLE_DETAILS } from '../../constants';

export default function ArticleSearch({
  getArticle,
  setSelectedItems,
  setParams,
  setSubFolderData,
  isMobile,
  setMobileView,
  isTablet,
  setTabView,
  setLoaderFlag,
  setFolderData,
  setBoardData,
}) {
  const history = useHistory();
  const { collectionId } = useParams();
  const params = queryString?.parse(history?.location?.search);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchedData, setSearchedData] = useState([]);
  const [searchCount, setSearchCount] = useState(null);
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });

  const handleSearch = (event) => {
    setSearchQuery(event?.target?.value);
    event.persist();
    setIsLoading({ loader: true, type: 'button' });
    setTimeout(() => {
      getArticleSearch(event?.target?.value, collectionId || null).then(
        (response) => {
          setSearchedData(response?.data?.result);
          setSearchCount(response?.data?.count);
          setIsLoading({ loader: false, type: 'button' });
        },
      );
    }, 1000);
  };
  const getBoardData = (boardId, item) => {
    setLoaderFlag('folder', true);
    getArticleBoards(boardId, 'board').then((res) => {
      if (res?.status === 200) {
        setFolderData(res && res?.data);
        getArticle(item?.id);
      }
      setLoaderFlag('folder', false);
    });
  };

  const redirectToArticle = (item) => {
    if (collectionId) {
      const stringified = queryString.stringify({
        article: item?.id,
      });
      setParams(stringified);
      setSelectedItems({});
      setSubFolderData([]);
      if (isMobile)
        setMobileView({
          board: false,
          section: false,
          article: true,
        });
      if (isTablet) setTabView(true);

      getArticleBoards(collectionId, 'collection').then((response) => {
        setBoardData(response && response.data);
        setSelectedItems((prevSelectedItems) => ({
          ...prevSelectedItems,
          board: params?.board || response?.data?.[0]?.id,
          article: params?.article,
        }));
        const boardId = params?.board || response?.data?.[0]?.id;

        getBoardData(boardId, item);
      });
    } else {
      history.push({
        pathname: `${PATH_ARTICLE_DETAILS.replace(
          ':collectionId',
          item.collection.id,
        )}`,
        state: item,
      });
    }

    setSearchQuery('');
  };

  const generateSearchHTML = () => {
    const options = [];
    if (searchedData) {
      for (const item of searchedData) {
        options.push(
          <li
            className="cursor"
            role="presentation"
            onClick={() => redirectToArticle(item)}
            key={item.id}
          >
            <img className="book-list-icon" src={ReadBookIcon} alt="" />
            <div className="header-list">
              {item?.preferredPhrase}

              <div className="sub-list">
                {item?.collection?.name} | {item?.boards?.[0]?.title}
              </div>
            </div>
          </li>,
        );
      }
    }
    return options;
  };

  return (
    <InputSearchWithRadius className="mb-3">
      <DebounceInput
        debounceTimeout={500}
        className={
          searchQuery?.length > 0 &&
          !isLoading?.loader &&
          isLoading?.type === 'button'
            ? 'article-search pb-5  form-control search-filter'
            : ' form-control search-filter'
        }
        placeholder="Search"
        onChange={(event) => handleSearch(event)}
        value={searchQuery || ''}
      />
      {searchQuery?.length > 1 ? (
        <div className="drop-down-option">
          <ul>
            {isLoading?.loader && isLoading?.type === 'button' ? (
              <p className="search-loader">Loading....</p>
            ) : (
              <>
                <div className="result-found  ml-3">
                  {searchCount} results found
                </div>
                {generateSearchHTML()}
              </>
            )}
          </ul>
        </div>
      ) : (
        ''
      )}

      {searchQuery ? (
        <img
          className="remove-icon"
          src={RemoveIcon}
          alt=""
          onClick={() => setSearchQuery('')}
          role="presentation"
        />
      ) : (
        ''
      )}

      <img src={SearchIcon} alt="search" className="search-input-icon" />
    </InputSearchWithRadius>
  );
}

ArticleSearch.defaultProps = {
  getArticle: () => {},
  setSelectedItems: () => {},
  setParams: () => {},
  setSubFolderData: () => {},
  isMobile: {},
  setMobileView: () => {},
  isTablet: {},
  setTabView: () => {},
  setLoaderFlag: () => {},
  setFolderData: () => {},
  setBoardData: () => {},
};

ArticleSearch.propTypes = {
  getArticle: func,
  setSelectedItems: func,
  setParams: func,
  setSubFolderData: func,
  isMobile: shape({}),
  setMobileView: func,
  isTablet: shape({}),
  setTabView: func,
  setLoaderFlag: func,
  setFolderData: func,
  setBoardData: func,
};
