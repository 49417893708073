import React, { useCallback, useEffect, useRef, useState } from 'react';

import { shape } from 'prop-types';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import InvoiceContainer from './Invoice/InvoiceContainer';
import DSPBillingContainer from './DSPBilling/DSPBillingContainer';
import PendingApprovalsDashboardContainer from '../AdManagerAdminDashboard/PendingApprovalsDashboard/PendingApprovalsDashboardContainer';
import { getPendingApprovalRequests } from '../../../api/PendingApprovalsDashboardApi';
import { Tabs } from '../../../common';
import { DashboardCard } from '../../../theme/Global';
import {
  financeNavigationOptions,
  financeAPIRoutes,
  financeDefaultTab,
  PATH_FINANCE_DASHBOARD,
  pendingApprovalOrderOptions,
  pendingApprovalAccessEmails,
} from '../../../constants';

export default function FinanceDashboardContainer({ userInfo }) {
  const mounted = useRef(false);
  const history = useHistory();

  const [viewComponent, setViewComponent] = useState(
    financeDefaultTab[window.location.pathname.split('/').pop()] || 'retainer',
  );
  const [pendingApprovalListDataCount, setPendingApprovalListDataCount] =
    useState(0);
  const [callPendingApprovalAPI, setCallPendingApprovalAPI] = useState(true);
  const isHavePendingApprovalAccess =
    pendingApprovalAccessEmails.includes(userInfo?.email) ||
    userInfo?.is_primary_request_approvers ||
    userInfo?.is_final_request_approvers;

  // to disaply the pending aprroval count, we need to call API
  const getPendingApprovalData = useCallback(() => {
    getPendingApprovalRequests(pendingApprovalOrderOptions[0]?.value, '', 1)
      .then((res) => {
        if (mounted.current) {
          if (res?.status === 200) {
            setPendingApprovalListDataCount(res?.data?.count || 0);
          } else {
            setPendingApprovalListDataCount(0);
          }
          setCallPendingApprovalAPI(false);
        }
      })
      .catch(() => {
        setPendingApprovalListDataCount(0);
      });
  }, []);

  useEffect(() => {
    if (isHavePendingApprovalAccess && callPendingApprovalAPI) {
      mounted.current = true;
      getPendingApprovalData();
    }
    return () => {
      mounted.current = false;
    };
  }, [
    getPendingApprovalData,
    isHavePendingApprovalAccess,
    callPendingApprovalAPI,
  ]);

  return (
    <DashboardCard>
      <div className="dashboard-container-body">
        <Tabs className="mb-3">
          <ul className="tabs">
            {financeNavigationOptions.map((navigation) => (
              <>
                {navigation.key === 'pendingApprovals' &&
                !isHavePendingApprovalAccess ? null : (
                  <li
                    key={navigation.key}
                    className={viewComponent === navigation.key ? 'active' : ''}
                    onClick={() => {
                      setViewComponent(navigation.key);
                      history.push(
                        `${PATH_FINANCE_DASHBOARD}${
                          financeAPIRoutes[navigation.key]
                        }`,
                      );
                    }}
                    role="presentation"
                  >
                    {navigation.value}
                    {navigation.key === 'pendingApprovals' ? (
                      <span className="circle-dot">
                        {pendingApprovalListDataCount}
                      </span>
                    ) : null}
                  </li>
                )}
              </>
            ))}
          </ul>

          <Switch>
            <Route exact path={`${PATH_FINANCE_DASHBOARD}retainer`}>
              <InvoiceContainer
                selectedNavigation={viewComponent}
                key={viewComponent}
              />
            </Route>
            <Route path={`${PATH_FINANCE_DASHBOARD}rev-share`}>
              <InvoiceContainer
                selectedNavigation={viewComponent}
                key={viewComponent}
              />
            </Route>
            <Route exact path={`${PATH_FINANCE_DASHBOARD}up-sell`}>
              <InvoiceContainer
                selectedNavigation={viewComponent}
                key={viewComponent}
              />
            </Route>
            <Route path={`${PATH_FINANCE_DASHBOARD}dsp`}>
              <InvoiceContainer
                selectedNavigation={viewComponent}
                key={viewComponent}
              />
            </Route>

            <Route path={`${PATH_FINANCE_DASHBOARD}dsp-billing`}>
              <DSPBillingContainer />
            </Route>

            <Route path={`${PATH_FINANCE_DASHBOARD}amc`}>
              <InvoiceContainer
                selectedNavigation={viewComponent}
                key={viewComponent}
              />
            </Route>

            {isHavePendingApprovalAccess ? (
              <Route path={`${PATH_FINANCE_DASHBOARD}pendingApprovals`}>
                <PendingApprovalsDashboardContainer
                  setCallPendingApprovalAPI={setCallPendingApprovalAPI}
                />
              </Route>
            ) : null}

            <Route
              path={`${PATH_FINANCE_DASHBOARD}`}
              render={() => (
                <Redirect
                  to={`${PATH_FINANCE_DASHBOARD}${financeAPIRoutes[viewComponent]}`}
                >
                  <InvoiceContainer
                    selectedNavigation={viewComponent}
                    key={viewComponent}
                  />
                </Redirect>
              )}
            />
          </Switch>
        </Tabs>
      </div>
    </DashboardCard>
  );
}

FinanceDashboardContainer.defaultProps = { userInfo: {} };
FinanceDashboardContainer.propTypes = {
  userInfo: shape({}),
};
