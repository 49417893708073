import React, { useState, useEffect } from 'react';

import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import { shape, string, func, arrayOf } from 'prop-types';

import { getCustomerDetails } from '../../store/actions/customerState';
import { getAllMembers, addCustomerMembers } from '../../api';
import { CloseIcon, SearchIcon, LeftArrowIcon } from '../../theme/images';
import { SpreetailRole, SpreetailRoleRestrictMessage } from '../../constants';
import {
  Button,
  ModalBox,
  PageLoader,
  GetInitialName,
  CommonPagination,
  InputSearchWithRadius,
} from '../../common';

// Both the Showing Current Assigned Team Members & New Members to add are in this component
// There are two lists to handle here 1.assignedMemebers (current team members) &  2. newly Added member list which we will submit on confirm (bulk-create)

const TeamMembers = ({
  customerID,
  currentMembers,
  setShowMemberList,
  getCustomerMemberList,
}) => {
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [assignedMembers, setAssignedMembers] = useState(currentMembers);
  const [showCureentTeam, setShowCureentTeam] = useState(true);
  const [selectedRole, setSelectedRole] = useState({});
  const [unassignedMembers, setUnassignedMembers] = useState([]);
  const [newMembers, setNewMembers] = useState([]);
  const [pageNumber, setPageNumber] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const { id } = useParams();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;

  useEffect(() => {
    // Get un-assigned members for current BP
    if (!showCureentTeam) {
      setLoading(true);
      getAllMembers(selectedRole.id, true, pageNumber, searchQuery).then(
        (res) => {
          setTotalCount(res.data.count);
          setUnassignedMembers(res.data.results);
          setLoading(false);
        },
      );
    }
  }, [showCureentTeam, selectedRole, pageNumber, searchQuery]);

  // Show un-assigned members for current BP
  const showUnassignedMembers = (member) => {
    setSelectedRole({ ...member.role_group, ...member });
    setShowCureentTeam(false);
  };

  // Redirect back to the original team members
  const backToTeamMembers = () => {
    setShowCureentTeam(true);
    setSearchQuery('');
  };

  // Add New members to Team
  const addNewMembers = (member) => {
    // Add New members in temporary
    const tempMembers = [...newMembers];
    tempMembers.push({
      user: member.id,
      role_group: selectedRole.id,
      type: selectedRole?.type,
    });

    // Also add newly added member in Current Assigned List (Main List)
    const newlyAssigned = assignedMembers.map((oldMember) => {
      if (oldMember.role_group.id === selectedRole.id) {
        return {
          id: Math.random(),
          user: {
            id: member.id,
            first_name: member.first_name,
            last_name: member.last_name,
            email: member.email,
            documents: member.documents,
          },
          role_group: {
            id: selectedRole.id,
            name: selectedRole.name,
          },
          type: selectedRole?.type,
        };
      }
      return oldMember;
    });

    // Set New Members to old Team List & updated list (data submit)
    setAssignedMembers([...newlyAssigned]);
    setNewMembers([...tempMembers]);
    setPageNumber();
    setSearchQuery('');
    setShowCureentTeam(true);
  };

  // Remove Team members
  const removeTeamMember = (member) => {
    const membersAfterRemove = assignedMembers.map((oldMember) => {
      if (oldMember.id === member.id) {
        return {
          role_group: oldMember.role_group,
          type: member?.type,
        };
      }
      return oldMember;
    });

    // Set it in temporary list when we submit data
    const tempMembers = [...newMembers];
    tempMembers.push({ role_group: member.role_group.id, type: member?.type });

    setAssignedMembers([...membersAfterRemove]);
    setNewMembers([...tempMembers]);
  };

  // Confirm all the changes made to team
  const saveTeamChanges = () => {
    const newMembersData = {
      customer: customerID,
      members: newMembers,
    };
    setButtonLoader(true);
    addCustomerMembers(newMembersData).then(() => {
      setButtonLoader(false);
      setShowMemberList({ add: false, show: false, modal: false });
      toast.success('Team Member(s) updated.');
      getCustomerMemberList();
      dispatch(getCustomerDetails(id, false));
    });
  };

  // Clear all the changes made to team
  const discardChanges = () => {
    setAssignedMembers(currentMembers);
    setNewMembers([]);
  };

  // Handle Pagination
  const handlePageChange = (currentPage) => {
    setLoading(true);
    setPageNumber(currentPage);
    getAllMembers(selectedRole.id, true, currentPage, searchQuery).then(
      (res) => {
        setTotalCount(res.data.count);
        setUnassignedMembers(res.data.results);
        setLoading(false);
      },
    );
  };

  // Handle Search
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    event.persist();
    setLoading(true);
    setTimeout(() => {
      getAllMembers(selectedRole.id, true, '', event.target.value).then(
        (res) => {
          setTotalCount(res.data.count);
          setUnassignedMembers(res.data.results);
          setLoading(false);
        },
      );
    }, 1000);
  };

  const displayTeamMembers = (member) => {
    return (
      <React.Fragment key={Math.random()}>
        <div className="col-8 mb-3 ">
          <div className="edit-profile-text" role="presentation">
            <GetInitialName
              userInfo={member?.user}
              property={`mr-3 ${member?.id ? '' : 'unassigned'}`}
            />

            <div className="name-email">
              <div className="label m-0">{member?.role_group?.name}</div>
              {member.id ? (
                <div className="team-member-name text-medium">
                  {`${member?.user?.first_name} ${member?.user?.last_name}`}
                </div>
              ) : (
                <div className="team-member-name text-medium disabled-tab">
                  Unassigned
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-4 text-right">
          {member.id ? (
            <>
              <Button
                data-tip={SpreetailRoleRestrictMessage}
                data-for={
                  isSpreetailUser
                    ? `spreetailRetrictRemoveTeamMember${member?.id}`
                    : ''
                }
                className={`btn-add-items gray-text   roleName mt-3 `}
                disabled={isSpreetailUser}
                onClick={() => removeTeamMember(member)}
              >
                Remove
              </Button>
              <ReactTooltip
                id={`spreetailRetrictRemoveTeamMember${member?.id}`}
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />
            </>
          ) : (
            <>
              <Button
                data-tip={SpreetailRoleRestrictMessage}
                data-for={
                  isSpreetailUser ? 'spreetailRetrictAddTeamMember' : ''
                }
                className="btn-add-items mt-3"
                role="presentation"
                onClick={() => showUnassignedMembers(member)}
                disabled={isSpreetailUser}
              >
                Add team member
              </Button>
              <ReactTooltip
                id="spreetailRetrictAddTeamMember"
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />
            </>
          )}

          <div className="clear-fix" />
        </div>
      </React.Fragment>
    );
  };

  return (
    <ModalBox>
      <img
        src={CloseIcon}
        alt="close"
        className=" cursor cross-icon mr-3 float-right"
        onClick={() =>
          setShowMemberList({ add: false, show: false, modal: false })
        }
        role="presentation"
      />
      <div className="modal-body pb-0">
        {showCureentTeam ? (
          <h4 className="mb-4">Team Members</h4>
        ) : (
          <Button
            className="btn-add-items back-btn"
            onClick={() => backToTeamMembers()}
          >
            {' '}
            <img
              width="16px"
              style={{ verticalAlign: 'bottom' }}
              className="left-arrow back-to-members mr-1"
              src={LeftArrowIcon}
              alt="arrow"
            />
            <span className="back-to-members">Back To Team Members</span>
          </Button>
        )}

        {/* List of current Team members with specific Role */}
        {showCureentTeam ? (
          assignedMembers?.length ? (
            <>
              <div className="body-content ">
                <div className=" liner-titles spacing mt-4 mb-3">
                  Default Roles
                </div>
                <div className="row">
                  {assignedMembers
                    ?.filter((item) => item?.type === 'Default')
                    ?.map((member) => {
                      return displayTeamMembers(member);
                    })}
                </div>

                <div className=" liner-titles spacing mt-4 mb-3">
                  Optional Roles
                </div>
                <div className="row">
                  {assignedMembers
                    ?.filter((item) => item?.type === 'Optional')
                    ?.filter((item) => item?.role_group?.name !== 'Photography') // PDV-9295 Hide Photography NEXT User Role from FE only
                    ?.map((member) => {
                      return displayTeamMembers(member);
                    })}
                </div>
              </div>

              <div className="footer-line " />
              <div className="modal-footer">
                <div className="row">
                  <div
                    className="col-12 col-md-6 px-0"
                    data-tip={SpreetailRoleRestrictMessage}
                    data-for={
                      isSpreetailUser ? 'spreetailRetrictTeamMember' : ''
                    }
                  >
                    <Button
                      className={`btn-primary w-sm-100 ${
                        newMembers.length === 0 ? 'disabled' : ''
                      }`}
                      onClick={() => saveTeamChanges()}
                      disabled={isSpreetailUser}
                    >
                      {buttonLoader ? (
                        <PageLoader color="#fff" type="button" />
                      ) : (
                        <>Confirm</>
                      )}
                    </Button>
                  </div>
                  <div className="col-12 col-md-6 text-center">
                    <Button
                      className=" btn-borderless w-sm-100"
                      onClick={() => discardChanges()}
                    >
                      {' '}
                      Discard Changes
                    </Button>
                  </div>
                </div>
                <ReactTooltip
                  id="spreetailRetrictTeamMember"
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                  html
                />
              </div>
            </>
          ) : (
            <div className="text-center no-result-found mt-5">
              No User Found.
            </div>
          )
        ) : (
          <>
            {/* $$$$$$$$$$$$$$$$ Add or Search New Team members for selected Role $$$$$$$$$$$$$$$$$$$ */}
            <div className="body-content ">
              <InputSearchWithRadius
                id="BT-addTeamMembers-search-input"
                className="customer-list-header w-80 mt-3"
              >
                <DebounceInput
                  className=" form-control search-filter"
                  placeholder="Search"
                  onChange={(event) => handleSearch(event)}
                  value={searchQuery || ''}
                />
                <img
                  src={SearchIcon}
                  alt="search"
                  className="search-input-icon"
                />
              </InputSearchWithRadius>{' '}
              {loading ? (
                <PageLoader
                  component="member"
                  color="#FF5933"
                  type="page"
                  height={40}
                />
              ) : unassignedMembers?.length === 0 ? (
                <div className="text-center no-result-found mt-5">
                  No User Found.
                </div>
              ) : (
                <>
                  <div className="horizontal-line straight-line mt-3 mb-3" />
                  <div className="row">
                    {unassignedMembers.map((member) => {
                      return (
                        <React.Fragment key={member.id}>
                          <div className="col-8 mb-3">
                            <div
                              className="edit-profile-text "
                              role="presentation"
                            >
                              <GetInitialName
                                userInfo={member}
                                property="mr-3"
                              />
                              <div className="name-email">
                                <div className="team-member-name text-medium">
                                  {`${member?.first_name} ${member?.last_name}`}
                                </div>
                                {member.role}
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-right">
                            <Button
                              className="btn-add-items   mt-3"
                              role="presentation"
                              onClick={() => addNewMembers(member)}
                            >
                              Add
                            </Button>
                            <div className="clear-fix" />
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            {!loading && unassignedMembers?.length !== 0 && totalCount > 10 ? (
              <div className="footer-sticky">
                <div className="straight-line horizontal-line" />
                <CommonPagination
                  count={totalCount}
                  pageNumber={pageNumber}
                  handlePageChange={handlePageChange}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </ModalBox>
  );
};

TeamMembers.propTypes = {
  customerID: string.isRequired,
  currentMembers: arrayOf(shape({})).isRequired,
  setShowMemberList: func.isRequired,
  getCustomerMemberList: func.isRequired,
};

export default TeamMembers;
