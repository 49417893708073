// react imports
import React, { useCallback } from 'react';

// third party imports
import { arrayOf, bool, shape } from 'prop-types';

// local file imports
import DifferenceAdMetric from '../../DifferenceAdMetric';
import CardMetrics from '../../../../../common/CardMetrics';
import { AMCAudienceMetricsConstants } from '../../../../../constants';

function AMCAudienceMetrics({ showAMCAudienceLookalike, amcAudienceData }) {
  const addThousandComma = useCallback((number, decimalDigits = 2) => {
    if (number !== undefined && number !== null) {
      return number
        ?.toFixed(decimalDigits)
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return number;
  }, []);

  // display metrics box html
  const displayMetricsBoxHTML = (
    className,
    id,
    metricsName,
    currentValue,
    previousValue,
    differenceValue,
    prefix,
    suffix,
    decimalDigits,
  ) => {
    return (
      <div className={`col-lg-4 col-md-4 col-6 mb-2 ${className}`}>
        <CardMetrics className="fix-height">
          <div id={id} role="presentation" className={` order-chart-box`}>
            <div className="chart-name">{metricsName}</div>
            <div className="number-rate">
              {currentValue
                ? `${prefix}${addThousandComma(
                    currentValue,
                    decimalDigits,
                  )}${suffix}`
                : `${prefix}${decimalDigits === 0 ? '0' : '0.00'}${suffix}`}
            </div>
            <div className="vs">
              {previousValue
                ? `vs ${prefix}${addThousandComma(
                    previousValue,
                    decimalDigits,
                  )}${suffix}`
                : `vs ${prefix}${decimalDigits === 0 ? '0' : '0.00'}${suffix}`}
            </div>
            <div className="justify-space-between">
              {differenceValue ? (
                <DifferenceAdMetric
                  value={differenceValue}
                  type="amcAudience"
                />
              ) : (
                <div className="perentage-value down mt-3 pt-1">N/A</div>
              )}
            </div>
          </div>
        </CardMetrics>
      </div>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      <div className="row mr-1 ml-1">
        {AMCAudienceMetricsConstants?.map((item) => {
          return displayMetricsBoxHTML(
            item?.className,
            item?.metricsId,
            `${item?.metricsLabel} ${
              showAMCAudienceLookalike ? 'Lookalike' : ''
            }`,
            amcAudienceData?.current_data?.[item?.metricsKey],
            amcAudienceData?.previous_data?.[item?.metricsKey],
            amcAudienceData?.percentage_change_data?.[item?.metricsKey],
            '',
            '',
            0,
          );
        })}
      </div>
    </div>
  );
}

export default AMCAudienceMetrics;

AMCAudienceMetrics.defaultProps = {
  showAMCAudienceLookalike: false,
  amcAudienceData: [],
};
AMCAudienceMetrics.propTypes = {
  showAMCAudienceLookalike: bool,
  amcAudienceData: arrayOf(shape()),
};
