import React from 'react';

import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { shape, func, string } from 'prop-types';

import RevenueThreshold from '../FeeStructure/RevenueThreshold';
import { combinedRevOptions } from '../../../../constants';
import { ContractInputSelect, InputFormField } from '../../../../common';

export default function CombinedRevShare({
  formData,
  setFormData,
  selectedCurrency,
  revShareOptions,
  DropdownIndicator,
  setUpdatedFormData,
  updatedFormData,
  feeStructureErrors,
  setFeeStructureErrors,
  showFooter,
  sectionError,
  setSectionError,
  section,
}) {
  const handleChange = (event, key, type) => {
    showFooter(true);
    if (key.includes('quarterly')) {
      let changedValue = {
        ...formData?.fee_structure?.seller.quarterly_rev_share,
      };
      if (event?.value) {
        changedValue = {
          ...formData?.fee_structure?.seller.quarterly_rev_share,
          [key]: event.value,
        };
      } else {
        delete formData?.fee_structure?.seller.quarterly_rev_share?.[key];
        changedValue = {
          ...formData?.fee_structure?.seller.quarterly_rev_share,
        };
      }
      setFormData({
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          seller: {
            ...formData?.fee_structure?.seller,
            quarterly_rev_share: { ...changedValue },
          },
          vendor: {
            ...formData?.fee_structure?.vendor,
            quarterly_rev_share: {
              ...changedValue,
            },
          },
        },
      });
      setUpdatedFormData({
        ...updatedFormData,
        fee_structure: {
          ...updatedFormData?.fee_structure,
          seller: {
            ...updatedFormData?.fee_structure?.seller,
            quarterly_rev_share: {
              ...changedValue,
            },
          },
          vendor: {
            ...updatedFormData?.fee_structure?.vendor,
            quarterly_rev_share: {
              ...changedValue,
            },
          },
        },
      });
    } else if (key.search(/\bmonth\b/) >= 0) {
      let changedValue = {
        ...formData?.fee_structure?.seller.monthly_rev_share,
      };
      if (event?.value) {
        changedValue = {
          ...formData?.fee_structure?.seller.monthly_rev_share,
          [key]: event.value,
        };
      } else {
        delete formData?.fee_structure?.seller.monthly_rev_share?.[key];
        changedValue = {
          ...formData?.fee_structure?.seller.monthly_rev_share,
        };
      }
      setFormData({
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          seller: {
            ...formData?.fee_structure?.seller,
            monthly_rev_share: {
              ...changedValue,
            },
          },
          vendor: {
            ...formData?.fee_structure?.vendor,
            monthly_rev_share: {
              ...changedValue,
            },
          },
        },
      });
      setUpdatedFormData({
        ...updatedFormData,
        fee_structure: {
          ...updatedFormData?.fee_structure,
          seller: {
            ...updatedFormData?.fee_structure?.seller,
            monthly_rev_share: {
              ...changedValue,
            },
          },
          vendor: {
            ...updatedFormData?.fee_structure?.vendor,
            monthly_rev_share: {
              ...changedValue,
            },
          },
        },
      });
    } else {
      setFormData({
        ...formData,
        fee_structure: {
          ...formData?.fee_structure,
          seller: {
            ...formData?.fee_structure?.seller,
            [key]:
              type === 'choices' || type === 'numberformat'
                ? event?.value
                  ? event?.value
                  : null
                : event?.target?.value
                ? event?.target?.value
                : null,
          },
          vendor: {
            ...formData?.fee_structure?.vendor,
            [key]:
              type === 'choices' || type === 'numberformat'
                ? event?.value
                  ? event?.value
                  : null
                : event?.target?.value
                ? event?.target?.value
                : null,
          },
        },
      });

      setUpdatedFormData({
        ...updatedFormData,
        fee_structure: {
          ...updatedFormData?.fee_structure,
          seller: {
            ...updatedFormData?.fee_structure?.seller,
            [key]:
              type === 'choices' || type === 'numberformat'
                ? event?.value
                  ? event?.value
                  : null
                : event?.target?.value
                ? event.target.value
                : null,
          },
          vendor: {
            ...updatedFormData?.fee_structure?.vendor,
            [key]:
              type === 'choices' || type === 'numberformat'
                ? event?.value
                  ? event?.value
                  : null
                : event?.target?.value
                ? event.target.value
                : null,
          },
        },
      });
    }

    if (
      (key === 'threshold_type' &&
        (feeStructureErrors?.seller?.quarterly_rev_share ||
          feeStructureErrors?.seller?.monthly_rev_share ||
          feeStructureErrors?.seller?.sales_threshold)) ||
      (formData?.fee_structure?.seller?.quarterly_rev_share &&
        Object.keys(formData?.fee_structure?.seller?.quarterly_rev_share)
          .length === 3 &&
        event.value &&
        feeStructureErrors?.seller?.quarterly_rev_share) ||
      (formData?.fee_structure?.seller?.monthly_rev_share &&
        Object.keys(formData?.fee_structure?.seller?.monthly_rev_share)
          .length === 11 &&
        event.value &&
        feeStructureErrors?.seller?.monthly_rev_share) ||
      (feeStructureErrors?.seller?.sales_threshold &&
        (formData?.fee_structure?.seller?.sales_threshold || event.value))
    ) {
      if (
        formData &&
        formData?.fee_structure?.vendor?.vendor_same_as_seller &&
        formData?.seller_type?.value === 'Hybrid'
      ) {
        setSectionError({
          ...sectionError,
          seller: {
            feeType: sectionError?.seller?.feeType
              ? sectionError?.seller?.feeType - 1
              : 0,
          },
          vendor: {
            feeType: !(
              formData && formData?.fee_structure?.vendor?.vendor_billing_report
            )
              ? sectionError?.seller?.feeType - 1 + 1
              : sectionError?.seller?.feeType - 1,
          },
        });

        setFeeStructureErrors((prevState) => ({
          ...prevState,
          seller: {
            ...feeStructureErrors?.seller,
            quarterly_rev_share: '',
            billing_cap: '',
            monthly_rev_share: '',
            sales_threshold: '',
          },
          vendor: {
            ...feeStructureErrors?.vendor,
            quarterly_rev_share: '',
            billing_cap: '',
            monthly_rev_share: '',
            sales_threshold: '',
          },
        }));
      } else {
        setSectionError({
          ...sectionError,
          seller: { feeType: sectionError?.seller?.feeType - 1 },
        });

        setFeeStructureErrors((prevState) => ({
          ...prevState,
          seller: {
            ...feeStructureErrors?.seller,
            quarterly_rev_share: '',
            billing_cap: '',
            monthly_rev_share: '',
            sales_threshold: '',
          },
        }));
      }
    }
  };

  const generateDropdown = (item) => {
    return (
      <Select
        classNamePrefix="react-select"
        styles={{
          control: (base, state) => ({
            ...base,
            zIndex: 9999,
            background:
              !(formData && formData?.fee_structure?.seller?.[item.key]) &&
              item.isMandatory
                ? '#FBF2F2'
                : '#F4F6FC',
            // match with the menu
            // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
            // Overwrittes the different states of border
            borderColor:
              !(formData && formData?.fee_structure?.seller?.[item.key]) &&
              item.isMandatory
                ? '#D63649'
                : '#D5D8E1',

            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
              // Overwrittes the different states of border
              boxShadow: state.isFocused ? null : null,
              outlineColor: state.isFocused ? null : null,
            },
          }),
        }}
        placeholder={item.placeholder || 'Select'}
        value={
          formData?.fee_structure?.seller?.[item.key]
            ? {
                value: formData?.fee_structure?.seller?.[item.key],
                label:
                  formData?.fee_structure?.seller?.[item.key] ===
                  'Retainer + % Rev Share'
                    ? 'Retainer +  Rev Share'
                    : formData?.fee_structure?.seller?.[item.key],
              }
            : null
        }
        options={revShareOptions}
        name={item.key}
        components={{ DropdownIndicator }}
        onChange={(event) => handleChange(event, item.key, 'choices')}
      />
    );
  };

  const checkAllFieldsOFRetainerPresent = (item) => {
    if (
      (formData?.fee_structure?.[section]?.retainer_type === 'Fixed' ||
        !formData?.fee_structure?.[section]?.retainer_type) &&
      formData?.fee_structure?.[section]?.monthly_retainer
    ) {
      return true;
    }

    if (
      formData?.fee_structure?.[section]?.retainer_type === 'quarterly' &&
      formData?.fee_structure?.[section]?.quarterly_monthly_retainer?.[
        item?.key
      ]
    ) {
      return true;
    }

    if (
      formData?.fee_structure?.[section]?.retainer_type === 'monthly' &&
      formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[item?.key]
    ) {
      return true;
    }

    return false;
  };
  const generateHTML = (item) => {
    if (item?.type?.includes('number')) {
      return (
        <>
          <NumberFormat
            className={
              (item?.fieldOf !== 'retainer' &&
                !(formData && formData?.fee_structure?.[section]?.[item.key]) &&
                item.isMandatory) ||
              (item?.fieldOf === 'retainer' &&
                !checkAllFieldsOFRetainerPresent(item)) ||
              (feeStructureErrors?.[section]?.quarterly_rev_share &&
                !formData?.fee_structure?.[section]?.quarterly_rev_share?.[
                  item.key
                ] &&
                item.fieldOf === 'threshold') ||
              (feeStructureErrors?.[section]?.monthly_rev_share &&
                !formData?.fee_structure?.[section]?.monthly_rev_share?.[
                  item.key
                ] &&
                item.fieldOf === 'threshold') ||
              (feeStructureErrors?.[section]?.sales_threshold &&
                !formData?.fee_structure?.[section]?.sales_threshold &&
                item.fieldOf === 'threshold') ||
              (feeStructureErrors?.[section]?.quarterly_monthly_retainer &&
                !formData?.fee_structure?.[section]
                  ?.quarterly_monthly_retainer?.[item.key] &&
                item.fieldOf === 'retainer') ||
              (feeStructureErrors?.[section]?.monthly_monthly_retainer &&
                !formData?.fee_structure?.[section]?.monthly_monthly_retainer?.[
                  item.key
                ] &&
                item.fieldOf === 'retainer') ||
              (feeStructureErrors?.[section]?.monthly_retainer &&
                !formData?.fee_structure?.[section]?.monthly_retainer &&
                item.fieldOf === 'retainer') ||
              (item.key === 'billing_cap' &&
                feeStructureErrors?.[section]?.billing_cap) ||
              (item.key === 'billing_minimum' &&
                feeStructureErrors?.[section]?.billing_minimum)
                ? 'form-control  form-control-error'
                : 'form-control '
            }
            name={item.key}
            id={item.key}
            value={
              item.key.includes('quarterly')
                ? formData?.fee_structure?.seller?.[
                    item.fieldOf === 'retainer'
                      ? 'quarterly_monthly_retainer'
                      : 'quarterly_rev_share'
                  ]?.[item.key]
                : item.key.search(/\bmonth\b/) >= 0
                ? formData.fee_structure?.seller?.[
                    item.fieldOf === 'retainer'
                      ? 'monthly_monthly_retainer'
                      : 'monthly_rev_share'
                  ]?.[item.key]
                : formData?.fee_structure?.seller?.[item.key]
            }
            placeholder={item.placeholder || item.label}
            suffix={item.type === 'number-percent' ? '%' : ''}
            onValueChange={(event) =>
              handleChange(event, item.key, 'numberformat', item.fieldOf)
            }
            thousandSeparator
            decimalScale={2}
            isAllowed={(values) => {
              const { formattedValue, floatValue } = values;
              if (floatValue == null) {
                return formattedValue === '';
              }
              return floatValue <= 100000000;
            }}
            allowNegative={false}
          />
        </>
      );
    }
    return null;
  };

  return (
    <>
      {' '}
      <div className=" liner-titles spacing mt-3 mb-3">Combined Rev Share</div>
      <p>
        By summing the sales of the seller and vendor accounts and applying the
        rev share percentage on the amount above the combined threshold,
        BBE&apos;s rev share is evaluated.
      </p>
      {combinedRevOptions.map((field) => (
        <InputFormField className="mt-3" key={field.key}>
          <label htmlFor={field.key}>{field.label}</label>

          {field.type === 'threshold' ? (
            <RevenueThreshold
              formData={formData}
              handleChange={handleChange}
              generateHTML={generateHTML}
              setFormData={setFormData}
              feeStructureErrors={feeStructureErrors}
              selectedCurrency={selectedCurrency}
              section="seller"
            />
          ) : (
            <ContractInputSelect>{generateDropdown(field)}</ContractInputSelect>
          )}
        </InputFormField>
      ))}
    </>
  );
}

CombinedRevShare.defaultProps = {
  setUpdatedFormData: () => {},
  setFormData: () => {},
  formData: {},
  updatedFormData: {},
  DropdownIndicator: {},
  revShareOptions: {},
  showFooter: () => {},
  setSectionError: () => {},
  sectionError: {},
  feeStructureErrors: {},
  setFeeStructureErrors: () => {},
  selectedCurrency: '$ (USD)',
  section: '',
};

CombinedRevShare.propTypes = {
  formData: shape({
    fee_structure: shape({}),
  }),
  DropdownIndicator: func,
  setUpdatedFormData: func,
  setFormData: func,
  updatedFormData: shape({}),
  revShareOptions: func,
  showFooter: func,
  setSectionError: func,
  sectionError: shape({}),
  feeStructureErrors: shape({}),
  setFeeStructureErrors: func,
  selectedCurrency: string,
  section: string,
};
