import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AdvertisingDashboardContainer from './AdManagerAdminDashboard/AdvertisingDashboardContainer';
import FinanceDashboardContainer from './FinanceDashboard/FinanceDashboardContainer';
import PendingApprovalsContainerForOtherRole from './PendingApprovalsForOtherRole/PendingApprovalsContainerForOtherRole';
import { BrandPartnerDashboard } from '../../theme/Global';
import { GlobalNavbar } from '../../common';
import { dynamicErrorMsg } from '../../store/actions/userState';
import { pendingApprovalAccessEmails } from '../../constants';

const _ = require('lodash');

function DashboardContainer() {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isHavePendingApprovalAccess =
    pendingApprovalAccessEmails.includes(userInfo?.email) ||
    ((userInfo?.is_primary_request_approvers ||
      userInfo?.is_final_request_approvers ||
      userInfo?.is_dsp_amc_primary_request_approvers) &&
      userInfo?.role !== 'Customer');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dynamicErrorMsg());
  }, [dispatch]);

  const dashboardRole = {
    'Ad Manager': '',
    'Ad Team Manager': '',
    'Ad Manager Admin': '',
    'Ad Specialist': '',
    'BGS Manager': '',
    'BGS Admin': '',
    BGS: '',
    Spreetail: '',
  };

  return (
    <>
      <GlobalNavbar />
      <BrandPartnerDashboard>
        {_.has(dashboardRole, userInfo?.role) ? (
          <AdvertisingDashboardContainer userInfo={userInfo} />
        ) : userInfo?.role?.includes('Finance') ? (
          <FinanceDashboardContainer userInfo={userInfo} />
        ) : isHavePendingApprovalAccess ? (
          <PendingApprovalsContainerForOtherRole />
        ) : null}
      </BrandPartnerDashboard>
    </>
  );
}

export default DashboardContainer;
