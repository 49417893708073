import React from 'react';

import { arrayOf, bool, func, instanceOf, shape, string } from 'prop-types';

import PerformanceReport from './SellerReporting/PerformanceReport';
import VendorSalesPerformanceContainer from './VendorReporting/VendorSalesPerformanceContainer';
import { SELLERSMALL, VENDORSMALL } from '../../../../../constants';

function SalesPerformanceContainer({
  customerId,
  currency,
  currencySymbol,
  subViewComponent,
  sellerSalesMinDate,
  vendorSalesMinDate,

  minDateLoader,

  currentDate,
  dspPpcFlag,
  customDateError,
  selectedTimeFrame,
  selectedChildBrand,
  selectedAccountType,
  selectedMarketplace,

  childBrandOptions,
  selectedState,
  marketplaceOptions,
  goalsMarketplaceIds,
  goalsMarketplaceOptions,

  setCurrency,
  bindErrorMessage,
  setSelectedState,
  setCurrencySymbol,
  setSelectedTimeFrame,
  setSelectedChildBrand,
  setSelectedMarketplace,
  displayAccountTypeFilter,
  renderDataAvailableChildList,
  getDataAvailabeleChildBrandList,
  createMarektplaceIdsListOfGoals,
}) {
  // display seller sales performance
  const displaySellerSalesPerformance = () => {
    return (
      <PerformanceReport
        id={customerId}
        currency={currency}
        currencySymbol={currencySymbol}
        subViewComponent={subViewComponent}
        salesMinDate={sellerSalesMinDate}
        dspPpcFlag={dspPpcFlag}
        minDateLoader={minDateLoader}
        currentDate={currentDate}
        customDateError={customDateError}
        selectedTimeFrame={selectedTimeFrame}
        selectedChildBrand={selectedChildBrand}
        selectedAccountType={selectedAccountType}
        selectedMarketplace={selectedMarketplace}
        selectedState={selectedState}
        childBrandOptions={childBrandOptions}
        marketplaceOptions={marketplaceOptions}
        goalsMarketplaceIds={goalsMarketplaceIds}
        goalsMarketplaceOptions={goalsMarketplaceOptions}
        setCurrency={setCurrency}
        bindErrorMessage={bindErrorMessage}
        setSelectedState={setSelectedState}
        setCurrencySymbol={setCurrencySymbol}
        setSelectedTimeFrame={setSelectedTimeFrame}
        setSelectedChildBrand={setSelectedChildBrand}
        setSelectedMarketplace={setSelectedMarketplace}
        displayAccountTypeFilter={displayAccountTypeFilter}
        renderDataAvailableChildList={renderDataAvailableChildList}
        getDataAvailabeleChildBrandList={getDataAvailabeleChildBrandList}
        createMarektplaceIdsListOfGoals={createMarektplaceIdsListOfGoals}
      />
    );
  };

  // display vendor sales performance
  const displayVendorSalesPerformance = () => {
    return (
      <VendorSalesPerformanceContainer
        id={customerId}
        currencySymbol={currencySymbol}
        subViewComponent={subViewComponent}
        salesMinDate={vendorSalesMinDate}
        dspPpcFlag={dspPpcFlag}
        minDateLoader={minDateLoader}
        currentDate={currentDate}
        customDateError={customDateError}
        selectedSalesDF={selectedTimeFrame}
        selectedChildBrand={selectedChildBrand}
        selectedMarketplace={selectedMarketplace}
        selectedState={selectedState}
        childBrandOptions={childBrandOptions}
        marketplaceOptions={marketplaceOptions}
        goalsMarketplaceIds={goalsMarketplaceIds}
        goalsMarketplaceOptions={goalsMarketplaceOptions}
        setCurrency={setCurrency}
        bindErrorMessage={bindErrorMessage}
        setSelectedState={setSelectedState}
        setCurrencySymbol={setCurrencySymbol}
        setSelectedSalesDF={setSelectedTimeFrame}
        setSelectedChildBrand={setSelectedChildBrand}
        setSelectedMarketplace={setSelectedMarketplace}
        displayAccountTypeFilter={displayAccountTypeFilter}
        renderDataAvailableChildList={renderDataAvailableChildList}
        getDataAvailabeleChildBrandList={getDataAvailabeleChildBrandList}
        createMarektplaceIdsListOfGoals={createMarektplaceIdsListOfGoals}
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      {subViewComponent === SELLERSMALL
        ? displaySellerSalesPerformance()
        : subViewComponent === VENDORSMALL
        ? displayVendorSalesPerformance()
        : null}
    </div>
  );
}

export default SalesPerformanceContainer;

SalesPerformanceContainer.defaultProps = {
  customerId: '',
  currency: '',
  currencySymbol: '',
  subViewComponent: '',
  sellerSalesMinDate: '',
  vendorSalesMinDate: '',

  minDateLoader: false,

  currentDate: new Date(),
  dspPpcFlag: {},
  customDateError: {},
  selectedTimeFrame: {},
  selectedChildBrand: {},
  selectedAccountType: {},
  selectedMarketplace: {},

  selectedState: [],
  childBrandOptions: [],
  marketplaceOptions: [],
  goalsMarketplaceIds: [],
  goalsMarketplaceOptions: [],

  setCurrency: () => {},
  bindErrorMessage: () => {},
  setSelectedState: () => {},
  setCurrencySymbol: () => {},
  setSelectedTimeFrame: () => {},
  setSelectedChildBrand: () => {},
  setSelectedMarketplace: () => {},
  displayAccountTypeFilter: () => {},
  renderDataAvailableChildList: () => {},
  getDataAvailabeleChildBrandList: () => {},
  createMarektplaceIdsListOfGoals: () => {},
};

SalesPerformanceContainer.propTypes = {
  customerId: string,
  currency: string,
  currencySymbol: string,
  subViewComponent: string,
  sellerSalesMinDate: string,
  vendorSalesMinDate: string,

  minDateLoader: bool,

  currentDate: instanceOf(Date),
  dspPpcFlag: shape({}),
  customDateError: shape({}),
  selectedTimeFrame: shape({}),
  selectedChildBrand: shape({}),
  selectedAccountType: shape({}),
  selectedMarketplace: shape({}),

  selectedState: arrayOf(shape()),
  childBrandOptions: arrayOf(shape()),
  marketplaceOptions: arrayOf(shape()),
  goalsMarketplaceIds: arrayOf(string),
  goalsMarketplaceOptions: arrayOf(shape({})),

  setCurrency: func,
  bindErrorMessage: func,
  setSelectedState: func,
  setCurrencySymbol: func,
  setSelectedTimeFrame: func,
  setSelectedChildBrand: func,
  setSelectedMarketplace: func,
  displayAccountTypeFilter: func,
  renderDataAvailableChildList: func,
  getDataAvailabeleChildBrandList: func,
  createMarektplaceIdsListOfGoals: func,
};
