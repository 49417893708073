import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { shape, bool, func } from 'prop-types';

import { updateArticle } from '../../api';
import { CloseIcon } from '../../theme/images';
import { Button, ModalBox, FormField, PageLoader } from '../../common';

function SuggestUpdateModal({
  setShowModal,
  showContinueMsg,
  selectedArticle,
  setShowContinueMsg,
}) {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState({ loader: false, type: 'button' });

  const saveArticle = () => {
    setIsLoading({ loader: true, type: 'button' });

    const data = {
      ...formData,
      article_title: selectedArticle?.preferredPhrase,
      card_id: selectedArticle?.slug,
    };

    updateArticle(data).then((response) => {
      if (response && response?.status === 201) {
        setShowContinueMsg(true);
        setIsLoading({ loader: false, type: 'button' });
      } else {
        toast.error('Something went wrong.');
      }
    });
  };

  return (
    <ModalBox>
      <img
        src={CloseIcon}
        alt="close"
        className="float-right cursor cross-icon"
        onClick={() => setShowModal(false)}
        role="presentation"
      />
      {!showContinueMsg ? (
        <div className="modal-body">
          <h4>Suggest an Update</h4>
          <p className="text-detail-modal">
            See something that&apos;s not correct in this article? Simply send
            us a note and we will update the content.
          </p>

          <FormField className="mt-3">
            <textarea
              className="text-area"
              rows="6"
              placeholder="Please provide details of the suggested update."
              name="description"
              onChange={(event) =>
                setFormData({
                  ...formData,
                  description: event?.target?.value,
                })
              }
            />
          </FormField>
          <Button
            className={
              !formData.description
                ? 'btn-primary mt-4 w-100 disabled'
                : 'btn-primary mt-4 w-100'
            }
            onClick={() => saveArticle()}
            disabled={!formData?.description}
          >
            {isLoading.loader && isLoading.type === 'button' ? (
              <PageLoader color="#fff" type="button" />
            ) : (
              'Submit'
            )}
          </Button>
        </div>
      ) : (
        <div className="modal-body">
          <h5 className="mt-4">Thanks for your help!</h5>
          <p className="text-detail-modal pt-0 pb-0">
            One of our knowledge experts will now review your submission. Thanks
            again for helping us make our knowledge base great.
          </p>

          <Button
            className="btn-primary mt-3 w-100"
            onClick={() => setShowModal(false)}
          >
            Continue
          </Button>
        </div>
      )}
    </ModalBox>
  );
}

export default SuggestUpdateModal;

SuggestUpdateModal.defaultProps = {
  setShowModal: () => {},
  showContinueMsg: false,
  selectedArticle: {},
  setShowContinueMsg: () => {},
};

SuggestUpdateModal.propTypes = {
  setShowModal: func,
  showContinueMsg: bool,
  selectedArticle: shape({}),
  setShowContinueMsg: func,
};
