import React from 'react';

import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import {
  WhiteCard,
  DropdownIndicator,
  ViewData,
  DropDowns,
} from '../../../../common';

const InventoryManagementFilters = ({
  marketplaceDefaultValue,
  marketplaceOptions,
  handleMarketplaceOptions,
  isApiCall,
  selectedMarketplace,
  childBrandOptions,
  selectedCustomerId,
  handleChildBrand,
}) => {
  const customerState = useSelector((state) => state.customerState.data);
  const { Option, SingleValue } = components;

  const dropdownSingleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props.data.label}</span>

      <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
    </SingleValue>
  );

  const dropdownFilterOption = (props) => (
    <Option {...props}>
      <div>
        <span style={{ fontSize: '14px' }}>{props.data.label}</span>

        <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
      </div>
    </Option>
  );

  const getDropdownComponents = () => {
    return {
      Option: dropdownFilterOption,
      SingleValue: dropdownSingleFilterOption,
      DropdownIndicator,
    };
  };

  return (
    <WhiteCard className="mb-3">
      <ViewData>
        <div className="row">
          <div className="col-md-4  col-12 ">
            <div className="view-data-for mt-2 pt-2 mb-md-0 mb-2 ">
              View data for:
            </div>{' '}
          </div>
          {customerState?.account_type === 'parent' ? (
            <div className="col-md-4 col-12 pl-0">
              {' '}
              {DropDowns(
                'BT-inventoryManagement-childBrandFilter',
                'cursor',
                childBrandOptions,
                childBrandOptions?.find((op) => op.value === selectedCustomerId)
                  ?.label,
                getDropdownComponents,
                '',
                handleChildBrand,
                isApiCall,
                null,
                childBrandOptions?.find(
                  (op) => op.value === selectedCustomerId,
                ),
              )}
            </div>
          ) : (
            <div className="col-md-4  mt-2 pt-1 pl-0"> </div>
          )}

          <div className="col-md-4 col-12 pl-0 mb-2">
            {DropDowns(
              'BT-InventoryBPMarketplacesFilter',
              'cursor',
              marketplaceOptions,
              marketplaceDefaultValue && marketplaceDefaultValue[0],
              getDropdownComponents,
              marketplaceDefaultValue && marketplaceDefaultValue[0],
              handleMarketplaceOptions,
              isApiCall,
              null,
              selectedMarketplace,
            )}
          </div>
        </div>
      </ViewData>
    </WhiteCard>
  );
};

export default InventoryManagementFilters;

InventoryManagementFilters.defaultProps = {
  isApiCall: false,
  isCustomDateApply: false,
  marketplaceDefaultValue: {},
  marketplaceOptions: {},
  data: {},
  selectedMarketplace: {},
  selectedCustomerId: '',
  handleMarketplaceOptions: () => {},
  childBrandOptions: [],
  handleChildBrand: () => {},
};

InventoryManagementFilters.propTypes = {
  isApiCall: bool,
  isCustomDateApply: bool,
  marketplaceDefaultValue: shape({}),
  marketplaceOptions: arrayOf(Array),
  data: shape({}),
  selectedCustomerId: string,
  selectedMarketplace: shape({}),
  handleMarketplaceOptions: func,
  childBrandOptions: arrayOf(shape({})),
  handleChildBrand: func,
};
