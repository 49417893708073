import { SELLERSMALL, VENDORSMALL } from './CommanVariableConstants';
import { PATH_DASHBOARD, PATH_FINANCE_DASHBOARD } from './RouteConstants';

export const financeNavigationOptions = [
  { key: 'retainer', value: 'Monthly Retainer' },
  { key: 'rev share', value: 'Rev Share' },
  { key: 'upsell', value: 'Upsell' },
  { key: 'dsp service', value: 'DSP' },
  { key: 'dspBilling', value: 'DSP Billing' },
  { key: 'amc service', value: 'AMC' },
  { key: 'pendingApprovals', value: 'Pending Approvals' },
];

export const pendingApprovalTab = [
  { key: 'pendingApprovals', value: 'Pending Approvals' },
];

export const dspColorSet = {
  dspImpressions: '#0045B4',
  dspSpend: '#8C54FF',
  dspTotalProductSales: '#30A8BD',
  dspTotalRoas: '#D6A307',
  dspTotalDpvr: '#E05D37',
  dspTtlNewBrandPurchases: '#89A43C',
  dspProductSales: '#C84EC6',
  dspRoas: '#A04848',
};

export const dspTabOptions = {
  dspSpend: 'DSP Spend',
  dspImpressions: 'Impressions',
  dspProductSales: 'Product Sales',
  dspRoas: 'ROAS',
  dspTotalDpvr: 'Total DPVR',
  dspTtlNewBrandPurchases: 'TTL New Brand Purchases',
  dspTotalProductSales: 'Total Product Sales',
  dspTotalRoas: 'Total ROAS',
};

export const contributionColorSet = {
  High: '#E3F2D2',
  Medium: '#FDF3D7',
  Low: '#F4F6FC',
};

export const InvoicesStatusOptions = [
  { value: 'any', label: 'Any' },
  { value: 'open', label: 'Open' },
  { value: 'overdue', label: 'Overdue' },
  { value: 'paid', label: 'Paid' },
  { value: 'partially paid', label: 'Partially Paid' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'partially refunded', label: 'Partially Refunded' },
  { value: 'voided', label: 'Voided' },
];

export const PartnersStatusOptions = [
  { value: 'any', label: 'Any' },
  { value: 'open', label: 'Open' },
  { value: 'overdue', label: 'Overdue' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'partially refunded', label: 'Partially Refunded' },
  { value: 'voided', label: 'Voided' },
];

export const InvoicesSortByOptions = [
  { value: '', label: 'Status' },
  { value: 'monthly_budget', label: 'Amount' },
  { value: 'generated_at', label: 'Creation Date' },
  { value: 'due_date', label: 'Due Date' },
];

export const PartnersSortByOptions = [
  { value: 'total_outstanding', label: 'Total Outstanding' },
  { value: 'total_overdue', label: 'Total Overdue' },
  { value: 'avg_days_past_due', label: 'Average Days Past Due' },
  { value: 'paid_by_due_date', label: 'Paid By Due Date' },
];

export const FinanceDateTypeOptions = [
  { value: 'allTime', label: 'All-Time' },
  { value: 'custom', label: 'Custom Range' },
];

export const metricsNameForAPI = {
  adSales: 'ad_sales',
  adSpend: 'ad_spend',
  adConversion: 'ad_conversion_rate',
  impressions: 'impressions',
  adCos: 'acos',
  adRoas: 'roas',
  adClicks: 'clicks',
  adClickRate: 'ctr',
  dspImpressions: 'impressions',
  dspSpend: 'dsp_spend',
  dspTotalProductSales: 'total_product_sales',
  dspTotalRoas: 'total_roas',
  dspTotalDpvr: 'total_dpvr',
  dspTtlNewBrandPurchases: 'ttl_new_brand_purchases',
  dspProductSales: 'product_sales',
  dspRoas: 'roas',
  costPerClick: 'cost_per_click',
  salesPerClick: 'sales_per_click',
  revenue: 'revenue',
  traffic: 'traffic',
  unitsSold: 'units_sold',
  conversion: 'conversion',
  orderedRevenue: 'ordered_revenue',
  shippedCOGs: 'shipped_cogs',
  glanceViews: 'glance_views',
  conversionRate: 'conversion_rate',
  orderedUnits: 'ordered_units',
  shippedUnits: 'shipped_units',
  percentageNtbOrders: 'ntb_orders_percent',
  percentageNtbSales: 'ntb_sales_percent',
  ntbOrders: 'ntb_orders',
  ntbSales: 'ntb_sales',
  goals: 'goals',
};

export const DSPFinanceMetrics = [
  {
    value: 'openInvoices',
    key: 'open_invoices',
    label: 'OPEN INVOICES',
    titleColor: '#333333',
    prefix: '',
    postfix: '',
  },
  {
    value: 'totalOverdue',
    key: 'total_overdue',
    label: 'TOTAL OVERDUE',
    titleColor: '#D60000',
    prefix: '$',
    postfix: '',
  },
  {
    value: 'expectedByEOM',
    key: 'expected_by_end_of_month',
    label: 'EXPECTED BY END OF MONTH(S)',
    titleColor: '#333333',
    prefix: '$',
    postfix: '',
  },
  {
    value: '%pastDue',
    key: 'percentage_past_due',
    label: '% PAST DUE',
    titleColor: '#333333',
    prefix: '',
    postfix: '%',
  },
  {
    value: 'avgDaysPastDue',
    key: 'avg_days_past_due',
    label: 'AVG. DAYS PAST DUE',
    titleColor: '#333333',
    prefix: '',
    postfix: ' days',
  },
];

export const DSPBillingMetrics = [
  {
    value: 'amazonAdvertisingLLC',
    key: 'Amazon Advertising LLC',
    label: 'Amazon Advertising LLC',
    titleColor: '#333333',
    prefix: '$',
    postfix: '',
  },
  {
    value: 'amazonOnlineUKLimited',
    key: 'Amazon Online UK Limited',
    label: 'Amazon Online UK Limited',
    titleColor: '#333333',
    prefix: '£',
    postfix: '',
  },
  {
    value: 'amazonOnlineUKLimitedEur',
    key: 'Amazon Online UK Limited (EUR)',
    label: 'Amazon Online UK Limited (EUR)',
    titleColor: '#D60000',
    prefix: '€',
    postfix: '',
  },
  {
    value: 'amazonCommercialServicesPtyLtd',
    key: 'Amazon Commercial Services Pty Ltd.',
    label: 'Amazon Commercial Services Pty Ltd',
    titleColor: '#333333',
    prefix: 'A$',
    postfix: '',
  },
];

export const StatusColorSet = {
  open: '#FDF3D7',
  draft: '#FDF377',
  overdue: '#ffebe6',
  paid: '#E3F2D2',
  late: '#E3F2D2',
  created: '#d7f8c2',
  pending: '#fdf3d7',
  refunded: '#eceef1',
  voided: '#ffe6f2',
  approved: '#E3F2D2',
  rejected: '#F7D7DB',
  void: '#ffe6f2',
  cancelled: '#f7d9dd',
  partially: '#fbe7e9',
  paused: '#E3F2D2',
};

export const BillingVendorOptions = [
  { value: 'Amazon Advertising LLC', label: 'Amazon Advertising LLC' },
  { value: 'Amazon Online UK Limited', label: 'Amazon Online UK Limited' },
  {
    value: 'Amazon Online UK Limited (EUR)',
    label: 'Amazon Online UK Limited (EUR)',
  },
  {
    value: 'Amazon Commercial Services Pty Ltd.',
    label: 'Amazon Commercial Services Pty Ltd.',
  },
];

export const BillingSortByOptions = [
  { value: '', label: 'Status' },
  { value: 'amount', label: 'Amount' },
  { value: 'bill_date', label: 'Bill Date' },
  { value: 'due_date', label: 'Due Date' },
];

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const roleURLs = [
  'sales',
  'sponsored',
  'dsp',
  'amcDashboard',
  'finance',
  'inventory',
  'pendingApprovals',
];

export const dashboardRole = [
  'Ad Manager',
  'Ad Team Manager',
  'Ad Manager Admin',
  'Ad Specialist',
  'BGS Manager',
  'BGS Admin',
  'BGS',
  'Finance',
  'BGS',
  'Spreetail',
];

export const dashboardRolePaths = {
  'Ad Manager': PATH_DASHBOARD,
  'Ad Team Manager': PATH_DASHBOARD,
  'Ad Manager Admin': PATH_DASHBOARD,
  'Ad Specialist': PATH_DASHBOARD,
  'BGS Manager': PATH_DASHBOARD,
  'BGS Admin': PATH_DASHBOARD,
  BGS: PATH_DASHBOARD,
  Finance: PATH_FINANCE_DASHBOARD,
  Spreetail: PATH_DASHBOARD,
};

export const financeAPIRoutes = {
  retainer: 'retainer',
  'rev share': 'rev-share',
  upsell: 'up-sell',
  'dsp service': 'dsp',
  dspBilling: 'dsp-billing',
  'amc service': 'amc',
  pendingApprovals: 'pendingApprovals',
};

export const financeDefaultTab = {
  retainer: 'retainer',
  'rev-share': 'rev share',
  'up-sell': 'upsell',
  dsp: 'dsp service',
  'amc service': 'amc',
  'dsp-billing': 'dspBilling',
};

export const advertisingDefaultTab = {
  sales: 'sales',
  sponsored: 'sponsored',
  dsp: 'dsp',
  amcDashboard: 'amcDashboard',
  awsQuicksight: 'awsQuicksight',
  inventory: 'inventory',
  pendingApprovals: 'pendingApprovals',
};

export const performanceTypeOptions = [
  {
    label: 'Seller Reporting',
    value: SELLERSMALL,
  },
  {
    label: 'Vendor Reporting',
    value: VENDORSMALL,
  },
];

export const performanceTypeOptionsForInventory = [
  {
    label: 'Seller Reporting',
    value: SELLERSMALL,
  },
  {
    label: 'Vendor Reporting',
    value: VENDORSMALL,
    isDisabled: true,
  },
];

export const allValueObj = [
  {
    value: 'all',
    label: 'All',
  },
];

export const allBPObj = {
  label: 'All Brand Partners',
  value: 'all',
};

export const salesContributionTabOptions = [
  { value: 'revenue', label: 'Total Sales' },
  { value: 'traffic', label: 'Traffic' },
  { value: 'conversion', label: 'Conversion' },
  { value: 'unitsSold', label: 'Units Sold' },
  { value: 'goals', label: 'Forecast' },
];

export const salesGoalsTableHeader = [
  {
    label: 'Customer',
    key: 'customer_name',
    width: '16%',
    showTooltip: false,
  },
  {
    label: 'Sales',
    key: 'overall_sales_goals',
    width: '12%',
    showTooltip: false,
  },
  {
    label: 'PPC Ad Spend',
    key: 'ppc_ad_budget',
    width: '12%',
    showTooltip: false,
  },
  {
    label: 'DSP Ad Spend',
    key: 'dsp_ad_budget',
    width: '12%',
    showTooltip: false,
  },
  {
    label: 'Tacos',
    key: 'tacos',
    width: '12%',
    showTooltip: false,
  },
  {
    label: 'Rev share',
    key: 'rev_share',
    width: '12%',
    showTooltip: true,
    tooltipId: 'revShareAdditionalInfo',
    tooltipInfo:
      'Revenue Sharing values are updated on <br/>the 9th of each month for the previous <br/> month',
  },
  {
    label: 'Traffic',
    key: 'traffic',
    width: '12%',
    showTooltip: false,
  },
  {
    label: 'Conversion',
    key: 'conversion',
    width: '12%',
    showTooltip: false,
  },
];

export const vendorOrderedRevenueMetrics = [
  {
    label: 'Ordered Revenue',
    key: 'orderedRevenue',
    metricsClass: 'ad-sales-active',
    className: 'pr-1',
  },
  {
    label: 'Glance Views',
    key: 'glanceViews',
    metricsClass: 'ad-spend-active',
    className: 'pl-1 pr-md-1',
  },
  {
    label: 'Conversion Rate',
    key: 'conversionRate',
    metricsClass: 'ad-conversion-active',
    className: 'pl-md-1 pr-1',
  },
  {
    label: 'Ordered Units',
    key: 'orderedUnits',
    metricsClass: 'impression-active',
    className: 'pl-1',
  },
];

export const vendorShippedCOGsMetrics = [
  {
    label: 'Shipped COGs',
    key: 'shippedCOGs',
    metricsClass: 'ad-sales-active',
    className: 'pr-1',
  },
  {
    label: 'Glance Views',
    key: 'glanceViews',
    metricsClass: 'ad-spend-active',
    className: 'pl-1 pr-md-1',
  },
  {
    label: 'Conversion Rate',
    key: 'conversionRate',
    metricsClass: 'ad-conversion-active',
    className: 'pl-md-1 pr-1',
  },
  {
    label: 'Shipped Units',
    key: 'shippedUnits',
    metricsClass: 'impression-active',
    className: 'pl-1',
  },
];

export const vendorOrderedRevenueMetricsTabs = [
  {
    label: 'Ordered Revenue',
    value: 'orderedRevenue',
  },
  {
    label: 'Glance Views',
    value: 'glanceViews',
  },
  {
    label: 'Conversion Rate',
    value: 'conversionRate',
  },
  {
    label: 'Ordered Units',
    value: 'orderedUnits',
  },
];

export const vendorShippedCOGsMetricsTabs = [
  {
    label: 'Shipped COGs',
    value: 'shippedCOGs',
  },
  {
    label: 'Glance Views',
    value: 'glanceViews',
  },
  {
    label: 'Conversion Rate',
    value: 'conversionRate',
  },
  {
    label: 'Shipped Units',
    value: 'shippedUnits',
  },
];

export const contributionTableHeaderByTab = [
  { label: 'Customer', key: 'customer', width: '40%' },
  { label: 'This Period', key: ' this-period', width: '17%' },
  { label: 'Prev. Period', key: 'prev-period', width: '17%' },
  { label: 'Change', key: 'change', width: '16%' },
  { label: 'Contribution', key: 'contribution', width: '10%' },
];

export const vendorContributionTableByKeyMetrics = {
  orderedRevenue: [
    { label: 'Customer', key: 'customer', width: '40%' },
    {
      label: 'Ordered Revenue',
      key: 'orderedRevenue',
      width: '15%',
    },
    {
      label: 'Glance Views',
      key: 'glanceViews',
      width: '15%',
    },
    {
      label: 'Conversion Rate',
      key: 'conversionRate',
      width: '15%',
    },
    {
      label: 'Ordered Units',
      key: 'orderedUnits',
      width: '15%',
    },
  ],
  shippedCOGs: [
    { label: 'Customer', key: 'customer', width: '40%' },
    {
      label: 'Shipped COGs',
      key: 'shippedCOGs',
      width: '15%',
    },
    {
      label: 'Glance Views',
      key: 'glanceViews',
      width: '15%',
    },
    {
      label: 'Conversion Rate',
      key: 'conversionRate',
      width: '15%',
    },
    {
      label: 'Shipped Units',
      key: 'shippedUnits',
      width: '15%',
    },
  ],
};

export const sponsoredTabOptions = {
  adSales: 'Ad Sales',
  adSpend: 'Ad Spend',
  adConversion: 'Ad Conversion Rate',
  impressions: 'Impressions',
  adCos: 'ACOS',
  adRoas: 'ROAS',
  adClicks: 'Clicks',
  adClickRate: 'Click Through Rate',
  costPerClick: 'Cost Per Click',
  salesPerClick: 'Sales Per Click',
  percentageNtbOrders: '% Of Orders New-to-brand (NTB)',
  percentageNtbSales: '% Of Sales New-to-brand (NTB)',
  ntbOrders: 'New-to-brand (NTB) Orders',
  ntbSales: 'New-to-brand (NTB) Sales',
};

export const sponsoredKeyContributionTableHeader = [
  {
    label: 'CUSTOMER',
    key: 'customer-name',
    width: '38%',
  },
  {
    label: 'AD SALES',
    key: 'ad-sales',
    width: '18%',
  },
  {
    label: 'AD SPEND',
    key: 'ad-spend',
    width: '18%',
  },
  {
    label: 'AD IMPRESSIONS',
    key: 'ad-impressions',
    width: '18%',
  },
  {
    label: 'ACOS',
    key: 'acos',
    width: '28%',
  },
];

export const dspKeyContributionTableHeader = [
  {
    label: 'CUSTOMER',
    key: 'customer-name',
    width: '38%',
  },
  {
    label: 'Impressions',
    key: 'impressions',
    width: '18%',
  },
  {
    label: 'DSP SPEND',
    key: 'dsp-spend',
    width: '18%',
  },
  {
    label: 'Total Product Sales',
    key: 'total-product-sales',
    width: '18%',
  },
  {
    label: 'Total ROAS',
    key: 'roas',
    width: '28%',
  },
];

export const dspPacingKeyTabOptions = [
  {
    id: 'overspending',
    label: 'Overspending',
  },
  {
    id: 'underspending',
    label: 'Underspending',
  },
];

export const dspPacingTableHeader = [
  {
    label: 'CUSTOMER',
    key: 'customer-name',
    width: '40%',
  },
  {
    label: 'PLANNED',
    key: 'planned',
    width: '15%',
  },
  {
    label: 'ACTUAL',
    key: 'actual',
    width: '15%',
  },
  {
    label: 'REMAINING',
    key: 'remaining',
    width: '15%',
  },
  {
    label: 'CONTRIBUTION',
    key: 'contribution',
    width: '15%',
  },
];

export const pendingApprovalOrderOptions = [
  { label: 'Most Recent to Oldest', value: '-updated_at' },
  { label: 'Oldest to Most Recent', value: 'updated_at' },
];

export const agreementTypes = {
  recurring: 'Recurring Service Agreement',
  'dsp only': 'DSP Service Agreement',
  'recurring (90 day notice)': 'Recurring Service Agreement',
  'one time': 'One Time Service Agreement',
};

// PDV-8910 removed Liz email. and didn't added Thad's signature
// bcoz role of Thad is BGS Admin so BGS Admin role already have access to Pending Approval tab
// and hard coded emails is not good practice
export const pendingApprovalAccessEmails = [];
