import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faPoll,
  faInfoCircle,
  faCopy,
  faFileInvoiceDollar,
  faBuilding as falFaBuilding,
  faFileContract,
  faBoxes,
  faMonitorWaveform,
  faExchange,
  faTachometerFast,
  faBox,
  faBook,
  faArrowsRotate,
  faShapes,
  faSquareArrowUpRight,
  faArrowUp,
  faArrowDown,
  faMessage,
  faTimes,
  faDownload,
  faFileChartColumn,
  faBullseyeArrow,
  faArrowDownToLine,
  faPen as falFaPen,
  faArrowLeft,
  faRectangleAd,
  faBookOpen,
  faMapLocationDot,
  faLink,
  faCodeMerge,
  faCodeCommit,
  faArrowUpRightFromSquare,
  faChevronRight,
  faChevronLeft,
  faHandshake,
  faSignature,
  faFilePen,
  faFileCircleXmark,
  faPause,
  faBan,
  faFileSignature,
  faFileCircleCheck,
  faFile,
  faEye,
  faEyeSlash,
  faCoins,
} from '@fortawesome/pro-light-svg-icons';

import { faAmazon } from '@fortawesome/free-brands-svg-icons';

export default library.add(
  faPoll,
  faCopy,
  faFileInvoiceDollar,
  falFaBuilding,
  faFileContract,
  faInfoCircle,
  faBoxes,
  faMonitorWaveform,
  faExchange,
  faTachometerFast,
  faBox,
  faBook,
  faArrowsRotate,
  faAmazon,
  faShapes,
  faSquareArrowUpRight,
  faArrowUp,
  faArrowDown,
  faMessage,
  faTimes,
  faDownload,
  faFileChartColumn,
  faBullseyeArrow,
  faArrowDownToLine,
  falFaPen,
  faArrowLeft,
  faRectangleAd,
  faBookOpen,
  faMapLocationDot,
  faLink,
  faCodeMerge,
  faCodeCommit,
  faArrowUpRightFromSquare,
  faChevronRight,
  faChevronLeft,
  faArrowDown,
  faHandshake,
  faSignature,
  faFilePen,
  faFileCircleXmark,
  faPause,
  faBan,
  faFileSignature,
  faFileCircleCheck,
  faFile,
  faEye,
  faEyeSlash,
  faCoins,
);
