import { shape } from 'prop-types';
import React from 'react';
import { WhiteCard } from '../../../../../common';

const PositiveFeedback = ({ dspData }) => {
  return (
    <div className="col-md-3 col-sm-12 mb-3">
      <WhiteCard className="fix-height">
        <p className="black-heading-title mt-0 mb-4">Positive Feedback</p>
        <div className="seller-health positive">
          {dspData?.feedback_30
            ? dspData.feedback_30 === 'nan'
              ? 'N/A'
              : `${dspData?.feedback_30}%`
            : 'N/A'}
        </div>
        <div className="seller-update mb-3">Last 30 days</div>
        <div className="seller-health positive ">
          {dspData?.feedback_365
            ? dspData.feedback_365 === 'nan'
              ? 'N/A'
              : `${dspData?.feedback_365}%`
            : 'N/A'}
        </div>
        <div className="seller-update mb-5">Last 12 months</div>
        <div className="last-update ">Last updated: {dspData?.latest_date}</div>
      </WhiteCard>
    </div>
  );
};

export default PositiveFeedback;

PositiveFeedback.defaultProps = { dspData: null };

PositiveFeedback.propTypes = {
  dspData: shape({}),
};
