/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';

import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, func, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';

import CardMetricsGraph from './CardMetricsGraph';
import CreativeProjectChart from './CreativeProjectChart';
import Theme from '../../../../../theme/Theme';
import { Icon } from '../../../../../theme/Global';
import {
  creativeAfterDaysOptions,
  noGraphDataMessage,
  daysConstant,
  SpreetailRoleRestrictMessage,
  SELLERSMALL,
  VENDORSMALL,
} from '../../../../../constants';
import {
  WhiteCard,
  AllocateBar,
  CustomDropDown,
  DropdownIndicator,
  NoData,
  ToggleButton,
  GrayMessageBar,
} from '../../../../../common';

export default function CreativeProjectPerformanceSummary({
  hideUploadCreativeProjectCTA,
  afterDaysDateDifference,
  afterDaysValue,
  setAfterDaysValue,
  isLoadingGraph,
  groupBy,
  multiSelectedValues,
  creativeProjectData,
  creativeProjectChartData,
  allowedToUploadDeleteProject,
  assignedBGSManager,
  loggedInRole,
  setShowCreativeUploadScreen,
  selectedProjects,
  selectedCreativeProjectMetrics,
  setMultiSelectedValues,
  accountType,
  selectedVendorReporting,
  currencySymbolForGraph,
  setSelectedCreativeProjectMetrics,
  handleGroupBy,
  params,
  isAllowToDeleteProject,
  getCreativeMonths,
  customerId,
  setIsSelectProjectDropdownOption,
  globalFiltersOptions,
  setGlobalFiltersOptions,
  setCallGraphApi,
  isSpreetailUser,
}) {
  // https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in Creative project
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );

  const creativeProjectMessage =
    accountType === SELLERSMALL
      ? dynamicMessages?.creative_project_seller?.placeholder
      : accountType === VENDORSMALL
      ? dynamicMessages?.creative_project_vendor?.placeholder
      : null;

  // after_days_filter_option disabled conditions
  const checkAfterDaysDisabledCondition = (daysValue) => {
    let disabledCondition = true;

    if (daysValue === '30') {
      disabledCondition = false;
    } else if (daysValue === '60' && Number(afterDaysDateDifference) > 30) {
      disabledCondition = false;
    } else if (daysValue === '90' && Number(afterDaysDateDifference) > 60) {
      disabledCondition = false;
    } else if (daysValue === '120' && Number(afterDaysDateDifference) > 90) {
      disabledCondition = false;
    }

    return disabledCondition;
  };

  // render Info icon message of after_days_filter
  const showAfterDaysMessage = useCallback(() => {
    let infoToolTipMessage = '';
    if (afterDaysDateDifference && afterDaysDateDifference < 91) {
      infoToolTipMessage = `Information is available for ${afterDaysDateDifference} days only`;
    }
    if (!afterDaysDateDifference) {
      infoToolTipMessage = `Information is available for some days only`;
    }

    return infoToolTipMessage;
  }, [afterDaysDateDifference]);

  // render after_days_filter
  const renderAfterDaysDropdown = () => {
    return (
      <div
        className="col-md-4 col-sm1-12 mb-2 mb-sm-0"
        style={{ paddingRight: '30px' }}
      >
        <CustomDropDown>
          <Select
            classNamePrefix="react-select"
            className="active"
            options={creativeAfterDaysOptions}
            components={{
              DropdownIndicator,
            }}
            // defaultValue={creativeAfterDaysOptions[0]}
            value={creativeAfterDaysOptions.find(
              (item) => item?.value === afterDaysValue,
            )}
            isSearchable={false}
            isOptionDisabled={(option) =>
              checkAfterDaysDisabledCondition(option.value)
            }
            onChange={(e) => {
              setAfterDaysValue(e.value);
              setCallGraphApi(true);
            }}
            isDisabled={isLoadingGraph?.loader}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral50: '#fff',
                primary25: Theme.lightOrange,
                primary: Theme.orange,
                primary50: Theme.lightOrange,
              },
            })}
          />
        </CustomDropDown>
        {showAfterDaysMessage() ? (
          <Icon style={{ position: 'absolute', right: 10, top: 9 }}>
            <FontAwesomeIcon
              data-for="info-icon"
              data-tip={showAfterDaysMessage()}
              data-html
              icon="fa-regular fa-circle-info"
            />
            <ReactTooltip
              className="asian-info-icon"
              id="info-icon"
              aria-haspopup="true"
              place="top"
              effect="solid"
              html
              insecure
            />
          </Icon>
        ) : (
          ''
        )}
      </div>
    );
  };

  // Show weekly, monthly, quarterly toggle button above graph
  const renderDaysToogleButton = () => {
    return daysConstant?.map((button) => {
      return (
        <li>
          <input
            className="d-none"
            type="radio"
            id={button?.id}
            name="flexRadioDefault"
            value={groupBy}
            checked={groupBy === button?.key}
            onClick={() => handleGroupBy(button?.key)}
            onChange={() => {}}
          />
          <label htmlFor={button?.id}>{button?.label}</label>
        </li>
      );
    });
  };

  // generate completion Date label
  const getProjectNameOnCompletionDate = useCallback(() => {
    const lattestProject = multiSelectedValues?.projects?.find(
      (project) =>
        project?.completion_date ===
          creativeProjectData?.dates?.current_start_date &&
        project?.marketplace_id ===
          creativeProjectData?.graph_country_currency?.country,
    );
    return lattestProject?.label;
  }, [creativeProjectData]);

  // render completion Date label and toogle btn
  const renderCompletionDateAndGroupBytoogleBtn = () => {
    return (
      <div className="row">
        <div className="col-md-5 col-sm-12 order-md-2 order-1 pl-3 mb-2 mb-sm-0">
          {creativeProjectData?.dates?.current_start_date &&
          !isLoadingGraph?.loader ? (
            <>
              <span className="black-heading-title">Completion Date:</span>{' '}
              <span className="vs">{getProjectNameOnCompletionDate()}</span>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="col-md-7 col-sm-12 order-md-2 order-1 pl-0 text-right mb-2 mb-sm-0">
          <ToggleButton>
            <div className="days-container quarterly">
              <ul
                className={
                  isLoadingGraph?.loader ? 'disabled-tab days-tab' : 'days-tab'
                }
              >
                {renderDaysToogleButton()}
              </ul>
            </div>
          </ToggleButton>
        </div>
      </div>
    );
  };

  const showMessageForGraph = () => {
    const selectedProjectCompletionDates = multiSelectedValues?.projects
      ?.map((item) => item?.completion_date)
      ?.filter(Boolean)
      ?.sort();
    if (
      selectedProjectCompletionDates?.length > 1 &&
      creativeProjectChartData?.length
    ) {
      return (
        <div className="row">
          <div className="col-12">
            <AllocateBar className="mb-4">
              <div className="remaing-label">
                <span className="orange-dot" />
                Graph is displaying the latest project selected only, for :{' '}
                {
                  selectedProjectCompletionDates[
                    selectedProjectCompletionDates?.length - 1
                  ]
                }
              </div>
            </AllocateBar>
          </div>
        </div>
      );
    }
    return '';
  };

  return (
    <WhiteCard className="mb-4">
      <div className="row">
        <div className="col-md-8 col-sm1-12">
          <p className="black-heading-title mt-2 pt-1 mb-4">
            Performance Summary
            {hideUploadCreativeProjectCTA ? null : (
              <>
                <span
                  data-tip={SpreetailRoleRestrictMessage}
                  data-for={
                    isSpreetailUser ? 'spreetailRetrictUploadCreative' : ''
                  }
                  className={
                    !isSpreetailUser &&
                    (allowedToUploadDeleteProject ||
                      assignedBGSManager ||
                      loggedInRole === 'BGS Admin')
                      ? 'cursor ml-2'
                      : 'disabled-slider-btn cursor ml-2'
                  }
                  style={{ color: '#ff5933', fontWeight: '400' }}
                  onClick={() =>
                    !isSpreetailUser &&
                    (allowedToUploadDeleteProject ||
                      assignedBGSManager ||
                      loggedInRole === 'BGS Admin')
                      ? setShowCreativeUploadScreen(true)
                      : null
                  }
                  role="presentation"
                >
                  Upload Completed Creative Project
                  <FontAwesomeIcon
                    icon="fa-regular fa-chevron-right"
                    style={{
                      marginLeft: '4px',
                      fontSize: '12px',
                      cursor: 'pointer',
                      color: '#FF5933 ',
                    }}
                  />{' '}
                </span>
                <ReactTooltip
                  id="spreetailRetrictUploadCreative"
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                  html
                />

                {allowedToUploadDeleteProject ||
                assignedBGSManager ||
                loggedInRole === 'BGS Admin' ? null : (
                  <>
                    <FontAwesomeIcon
                      className="ml-1"
                      data-for="info-icon"
                      data-tip="Only authorized users are allowed to upload completed creative projects."
                      data-html
                      icon="fa-regular fa-circle-info"
                      style={{ verticalAlign: '-3px' }}
                    />
                    <ReactTooltip
                      className="asian-info-icon"
                      id="info-icon"
                      effect="solid"
                    />
                  </>
                )}
              </>
            )}
          </p>
        </div>
        {renderAfterDaysDropdown()}
      </div>
      {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in Creative project */}
      {creativeProjectMessage ? (
        <GrayMessageBar message={creativeProjectMessage} />
      ) : null}
      {creativeProjectData?.available_days &&
      multiSelectedValues?.projects?.length === 1 ? (
        <GrayMessageBar
          message={`Displayed metrics in the card tiles are available for
                ${creativeProjectData?.available_days} days only after the
                completion date.`}
        />
      ) : null}
      <CardMetricsGraph
        projectDetails={selectedProjects.search}
        selectedCreativeProjectMetrics={selectedCreativeProjectMetrics}
        setSelectedCreativeProjectMetrics={setSelectedCreativeProjectMetrics}
        creativeProjectData={creativeProjectData}
        multiSelectedValues={multiSelectedValues}
        setMultiSelectedValues={setMultiSelectedValues}
        isLoadingGraph={isLoadingGraph}
        accountType={accountType}
        selectedVendorReporting={selectedVendorReporting}
        isAllowToDeleteProject={isAllowToDeleteProject}
        getCreativeMonths={getCreativeMonths}
        customerId={customerId}
        setIsSelectProjectDropdownOption={setIsSelectProjectDropdownOption}
        globalFiltersOptions={globalFiltersOptions}
        setGlobalFiltersOptions={setGlobalFiltersOptions}
        setCallGraphApi={setCallGraphApi}
      />{' '}
      {showMessageForGraph()}
      {renderCompletionDateAndGroupBytoogleBtn()}
      {isLoadingGraph?.loader ? null : creativeProjectChartData?.length ? (
        <CreativeProjectChart
          chartId="creative-project-chart"
          chartData={creativeProjectChartData}
          currencySymbol={currencySymbolForGraph}
          selectedMetrics={selectedCreativeProjectMetrics}
          groupBy={groupBy}
          completionDate={creativeProjectData?.dates?.current_start_date}
          accountType={params.sub}
          selectedVendorReporting={selectedVendorReporting}
        />
      ) : (
        <NoData>{noGraphDataMessage}</NoData>
      )}
    </WhiteCard>
  );
}

CreativeProjectPerformanceSummary.defaultProps = {
  hideUploadCreativeProjectCTA: false,
  isAllowToDeleteProject: false,
  assignedBGSManager: false,
  isSpreetailUser: false,
  allowedToUploadDeleteProject: false,

  loggedInRole: '',
  afterDaysDateDifference: '',
  afterDaysValue: '',
  groupBy: '',
  accountType: '',
  selectedVendorReporting: '',
  currencySymbolForGraph: '',
  customerId: '',

  multiSelectedValues: {},
  creativeProjectData: {},
  creativeProjectChartData: {},
  selectedProjects: {},
  isLoadingGraph: {},
  selectedCreativeProjectMetrics: {},
  params: {},

  globalFiltersOptions: [],

  getCreativeMonths: () => {},
  setSelectedCreativeProjectMetrics: () => {},
  handleGroupBy: () => {},
  setAfterDaysValue: () => {},
  setIsSelectProjectDropdownOption: () => {},
  setMultiSelectedValues: () => {},
  setShowCreativeUploadScreen: () => {},
  setGlobalFiltersOptions: () => {},
  setCallGraphApi: () => {},
};

CreativeProjectPerformanceSummary.propTypes = {
  hideUploadCreativeProjectCTA: bool,
  isAllowToDeleteProject: bool,
  assignedBGSManager: bool,
  isSpreetailUser: bool,
  allowedToUploadDeleteProject: bool,

  loggedInRole: string,
  afterDaysDateDifference: string,
  afterDaysValue: string,
  groupBy: string,
  accountType: string,
  selectedVendorReporting: string,
  currencySymbolForGraph: string,
  customerId: string,

  multiSelectedValues: shape({}),
  creativeProjectData: shape({}),
  creativeProjectChartData: shape({}),
  selectedProjects: shape({}),
  isLoadingGraph: shape({}),
  selectedCreativeProjectMetrics: shape({}),
  params: shape({}),

  globalFiltersOptions: shape([]),

  getCreativeMonths: func,
  setSelectedCreativeProjectMetrics: func,
  handleGroupBy: func,
  setAfterDaysValue: func,
  setIsSelectProjectDropdownOption: func,
  setMultiSelectedValues: func,
  setShowCreativeUploadScreen: func,
  setGlobalFiltersOptions: func,
  setCallGraphApi: func,
};
