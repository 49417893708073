/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import $ from 'jquery';
import { func, arrayOf, shape, bool } from 'prop-types';

import { CheckBox, InputFormField } from '../../../../common';

export default function StandardServices({
  formData,
  stdServices,
  updatedFormData,
  setFormData,
  setUpdatedFormData,
  showFooter,
  contractAddendumDetails,
  addendumDetails,
  originalData,
}) {
  const selectedStdServices =
    (contractAddendumDetails?.notActive
      ? addendumDetails?.standard_us_services
      : originalData?.standard_us_services) || [];

  const [allSelected, setAllSelected] = useState(
    formData?.standard_us_services?.length === 16 ? false : true || true,
  );

  const removeServicesWithId = (tempServices) => {
    let serviceLength = tempServices?.length || 0;
    while (serviceLength--) {
      for (const item of selectedStdServices) {
        if (
          tempServices[serviceLength] &&
          tempServices[serviceLength].name === item.name
        ) {
          tempServices.splice(serviceLength, 1);
        }
      }
    }
  };

  const selectAllStdServices = () => {
    if (allSelected) showFooter(true);
    const originalServices = [];
    const tempServices = [];

    $('.checkboxes-std-services input:checkbox').prop('checked', true);
    setAllSelected(false);

    for (const item of stdServices) {
      const addendumData = {
        name: item.label,
        contract_addendum: formData?.id,
      };
      const contractData = {
        name: item.label,
        contract: formData?.id,
      };
      if (
        selectedStdServices?.find((service) => service?.name === item?.label)
          ?.id
      ) {
        addendumData.id = selectedStdServices?.find(
          (service) => service?.name === item?.label,
        )?.id;
        contractData.id = selectedStdServices?.find(
          (service) => service?.name === item?.label,
        )?.id;
      }
      tempServices.push(
        contractAddendumDetails?.notActive ? addendumData : contractData,
      );

      originalServices.push(
        contractAddendumDetails?.notActive ? addendumData : contractData,
      );
    }

    if (updatedFormData?.standard_us_services?.delete?.length === 16) {
      setFormData({
        ...formData,
        standard_us_services: originalServices,
      });
      setUpdatedFormData({
        ...updatedFormData,
        standard_us_services: {
          ...updatedFormData.standard_us_services,
          delete: [],
        },
      });
    } else {
      // remove the servies which are already in DB.
      removeServicesWithId(tempServices);

      setFormData({
        ...formData,
        standard_us_services: originalServices,
      });
      if (tempServices?.length) {
        setUpdatedFormData({
          ...updatedFormData,
          standard_us_services: {
            ...updatedFormData.standard_us_services,
            create: [...tempServices],
            delete: [],
          },
        });
      }
    }
    setFormData({
      ...formData,
      standard_us_services: originalServices,
      selectAllStdServices,
    });
  };

  useEffect(() => {}, [formData?.seller_type]);

  const handleChange = (event, selectedService) => {
    showFooter(true);
    let showServiceInTable = [];
    if (formData?.standard_us_services?.length)
      showServiceInTable = [...formData?.standard_us_services];

    if (!event.target.checked) {
      // add the selected service in the delete array and remove the service from the agreement table (on LHS).
      const tempService = updatedFormData?.standard_us_services?.delete?.find(
        (ele) => ele === selectedService?.id,
      );
      if (!tempService) {
        let tempDeletedServices = [];
        if (updatedFormData?.standard_us_services?.delete) {
          tempDeletedServices = [
            ...updatedFormData.standard_us_services?.delete,
          ];
        }
        showServiceInTable = formData?.standard_us_services?.filter(
          (op) => op.name !== event.target.name,
        );
        let createServices = updatedFormData?.standard_us_services?.create
          ?.length
          ? [...updatedFormData?.standard_us_services?.create]
          : [];
        createServices = updatedFormData?.standard_us_services?.create?.filter(
          (op) => op.name !== event.target.name,
        );

        setFormData({
          ...formData,
          standard_us_services: showServiceInTable,
        });
        setUpdatedFormData({
          ...updatedFormData,
          standard_us_services: {
            create: createServices,
            delete: [...tempDeletedServices, selectedService?.id],
          },
        });
      }
    }
    if (event.target.checked) {
      let tempCreateServices = [];
      if (updatedFormData?.standard_us_services?.create) {
        tempCreateServices = [...updatedFormData.standard_us_services?.create];
      }

      // if selected service not in DB, add it in create array and show the service in agreement table (on LHS).
      if (!selectedService?.id) {
        const addendumData = {
          name: event.target.name,
          contract_addendum: formData?.id,
        };
        const contractData = {
          name: event.target.name,
          contract: formData?.id,
        };
        showServiceInTable.push(
          contractAddendumDetails?.notActive ? addendumData : contractData,
        );

        setFormData({
          ...formData,
          standard_us_services: showServiceInTable,
        });
        const createContractData = contractAddendumDetails?.notActive
          ? {
              name: event.target.name,
              contract_addendum: formData?.id,
            }
          : { name: event.target.name, contract: formData?.id };
        setUpdatedFormData({
          ...updatedFormData,
          standard_us_services: {
            ...updatedFormData.standard_us_services,
            create: [...tempCreateServices, createContractData],
          },
        });
      }
      // if selected service in DB, remove the selected ID from the delete array and from the agreement table (on LHS).
      if (selectedService?.id) {
        const contractData = {
          name: event?.target?.name,
          contract: formData?.id,
        };
        const addendumData = {
          name: event?.target?.name,
          contract_addendum: formData?.id,
        };
        showServiceInTable.push(
          contractAddendumDetails?.notActive ? addendumData : contractData,
        );
        setFormData({
          ...formData,
          standard_us_services: showServiceInTable,
        });
        const tempDeletedServices =
          updatedFormData.standard_us_services?.delete?.filter(
            (op) => op !== selectedService?.id,
          );
        setUpdatedFormData({
          ...updatedFormData,
          standard_us_services: {
            ...updatedFormData.standard_us_services,
            delete: tempDeletedServices,
          },
        });
      }
    }
  };

  const deSelectAllStdServices = () => {
    showFooter(true);
    const serviceIds = [];
    selectedStdServices.filter(({ id }) => {
      if (id) serviceIds.push(id);
      return null;
    });
    setAllSelected(true);
    $('.checkboxes-std-services input:checkbox').prop('checked', false);
    setFormData({
      ...formData,
      standard_us_services: [],
    });
    setUpdatedFormData({
      ...updatedFormData,
      standard_us_services: {
        delete: serviceIds,
      },
    });
  };
  return (
    <div className="mb-4">
      <InputFormField className="mb-3">
        <label htmlFor="additional_one_time_services">Standard Services</label>
        <div
          className="add-discount"
          role="presentation"
          onClick={() =>
            allSelected ? selectAllStdServices() : deSelectAllStdServices()
          }
        >
          {allSelected ? 'Select All' : 'Deselect All'}
        </div>
      </InputFormField>
      {stdServices?.map((serviceData) => (
        <CheckBox key={serviceData?.value}>
          <label
            className="check-container checkboxes-std-services customer-pannel"
            htmlFor={serviceData?.value}
          >
            {serviceData?.label}
            <input
              type="checkbox"
              name={serviceData?.value}
              id={serviceData?.value}
              onClick={(event) =>
                handleChange(
                  event,
                  selectedStdServices?.length &&
                    selectedStdServices.find(
                      (item) => item?.name === serviceData?.label,
                    ),
                )
              }
              defaultChecked={
                formData?.standard_us_services?.length &&
                formData.standard_us_services.find(
                  (item) => item?.name === serviceData?.label,
                )
              }
            />
            <span className="checkmark" />
          </label>
        </CheckBox>
      ))}
    </div>
  );
}

StandardServices.defaultProps = {
  formData: {},
  stdServices: [],
  updatedFormData: {},
  setFormData: () => {},
  setUpdatedFormData: () => {},
  showFooter: () => {},
  contractAddendumDetails: {},
  addendumDetails: {},
  originalData: {},
};

StandardServices.propTypes = {
  formData: shape({
    standard_us_services: arrayOf(shape({})),
  }),
  stdServices: arrayOf(shape({})),
  updatedFormData: shape({}),
  setFormData: func,
  setUpdatedFormData: func,
  showFooter: func,
  contractAddendumDetails: shape({ notActive: bool }),
  addendumDetails: shape({}),
  originalData: shape({}),
};
