import React from 'react';

import Select from 'react-select';

import { CustomDropDown } from '../../../../common';
import Theme from '../../../../theme/Theme';

export const DropDown = (
  id,
  className,
  options,
  placeholder,
  components,
  handleOnChange,
  isDisabled,
  reff = null,
  selectedValue,
  isSearchable = true,
  isLoading = false,
) => {
  const YearOverYearOptionStyles = {
    menuList: (styles) => ({
      ...styles,
      maxHeight: '340px !important',
    }),
  };

  return (
    <CustomDropDown
      id={id}
      className={isDisabled ? `${className} disabled` : className}
      inputColor={isSearchable ? Theme.black : Theme.white}
    >
      <Select
        classNamePrefix="react-select"
        className="active"
        options={options}
        ref={reff}
        placeholder={placeholder}
        components={components !== null ? components() : null}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: '#fff',
            primary25: Theme.lightOrange,
            primary: Theme.orange,
            primary50: Theme.lightOrange,
          },
        })}
        value={selectedValue}
        onChange={(event) => handleOnChange(event)}
        styles={{
          YearOverYearOptionStyles,
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        menuPortalTarget={document.body}
        isLoading={isLoading}
        isOptionDisabled={(option) => option.isDisabled}
        isSearchable={isSearchable}
      />
    </CustomDropDown>
  );
};

export default DropDown;
