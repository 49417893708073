/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { OnBoardingBody, Button, PageLoader, CheckBox } from '../../common';

import {
  amazonAccountManual,
  PATH_SUMMARY,
  PATH_THANKS,
} from '../../constants';

import { userMe } from '../../store/actions';
import { AmazonAccountPermission } from './OnBoardingStyles';
import {
  askSomeoneData,
  getMarketplaces,
  updateAmazonAccount,
  updateAskSomeoneData,
  updateUserMe,
} from '../../api';

export default function AmazonDeveloperAccess({
  setIsLoading,
  assignedToSomeone,
  stepData,
  verifiedStepData,
  userInfo,
  isLoading,
  isChecked,
  data,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(data);
  const [marketplacesList, setMarketplacesList] = useState([]);
  const params = queryString.parse(history.location.search);

  useEffect(() => {
    getMarketplaces().then((res) => {
      setMarketplacesList(res?.data);

      setFormData(data);
      const isPrimary = data?.marketplaces?.filter(
        (op) => !op.account_type,
      )?.[0];
      const isSecondary = data?.marketplaces?.filter((op) => op.account_type);
      let combinedMarketplaces = [];
      if (data?.account_type === 'Hybrid') {
        if (isPrimary && isSecondary) {
          delete isPrimary.account_type;
          const isPrimarySeller = { ...isPrimary, account_type: 'Seller' };
          const isPrimaryVendor = { ...isPrimary, account_type: 'Vendor' };
          combinedMarketplaces = [
            ...isSecondary,
            isPrimarySeller,
            isPrimaryVendor,
          ];
          setFormData({
            ...formData,
            ...data,
            marketplaces: combinedMarketplaces,
          });
        }
      } else {
        if (isPrimary && isSecondary) {
          isPrimary.account_type = data?.account_type;
          combinedMarketplaces = [...isSecondary, isPrimary];
          setFormData({
            ...formData,
            ...data,
            marketplaces: combinedMarketplaces,
          });
        }
      }
    });
  }, [data]);

  const saveDetails = () => {
    setIsLoading({ loader: true, type: 'button' });
    if (
      (stepData === undefined ||
        (stepData &&
          Object.keys(stepData) &&
          Object.keys(stepData).length === 0)) &&
      verifiedStepData &&
      Object.keys(verifiedStepData) &&
      Object.keys(verifiedStepData).length === 0
    ) {
      const detail = {
        is_completed: true,
        email: userInfo.email,
        step: 'amazon account',
        customer_onboarding: userInfo.customer_onboarding,
      };
      askSomeoneData(detail).then((stepResponse) => {
        if (stepResponse && stepResponse.status === 201) {
          if (assignedToSomeone) {
            const stringified =
              queryString &&
              queryString.stringify({
                name: verifiedStepData.user_name,
              });
            history.push({
              pathname: PATH_THANKS,
              search: `${stringified}`,
            });
          } else {
            history.push(PATH_SUMMARY);
          }
          updateUserMe(userInfo.id, {
            step: { ...userInfo.step, [userInfo.customer]: 5 },
          }).then((user) => {
            if (user && user.status === 200) {
              dispatch(userMe());
            }
          });
          localStorage.removeItem('match');
          setIsLoading({ loader: false, type: 'button' });
        }
      });
    } else {
      updateAskSomeoneData(
        (stepData && stepData.id) || verifiedStepData.step_id,
        {
          token: assignedToSomeone ? params && params.key : '',
          is_completed: true,
        },
      ).then((response) => {
        if (response && response.status === 200) {
          if (assignedToSomeone) {
            const stringified =
              queryString &&
              queryString.stringify({
                name: verifiedStepData.user_name,
              });
            history.push({
              pathname: PATH_THANKS,
              search: `${stringified}`,
            });
          } else {
            history.push(PATH_SUMMARY);
          }
          updateUserMe(userInfo.id, {
            step: { ...userInfo.step, [userInfo.customer]: 5 },
          }).then((user) => {
            if (user && user.status === 200) {
              dispatch(userMe());
            }
          });
          localStorage.removeItem('match');
          setIsLoading({ loader: false, type: 'button' });
        }
      });
    }
  };

  const saveAmazonAccount = () => {
    delete formData?.seller_manual;
    delete formData?.vendor_manual;
    delete formData?.customer;

    updateAmazonAccount(data?.id || formData?.id, formData).then((res) => {
      if (res?.status === 200) {
        saveDetails();
      }
    });
  };

  const disableBtnUntilAllChecked = () => {
    if (formData?.account_type === 'Seller' && formData?.no_seller_account)
      return false;
    if (formData?.account_type === 'Vendor' && formData?.no_vendor_account)
      return false;
    if (formData?.account_type === 'Hybrid') {
      if (formData?.no_seller_account && formData?.no_vendor_account)
        return false;
      if (
        formData?.no_seller_account &&
        formData?.marketplaces?.filter(
          (op) => op.account_type === 'Vendor' && !op.permitted,
        )?.length === 0
      )
        return false;
      if (
        formData?.no_vendor_account &&
        formData?.marketplaces?.filter(
          (op) => op.account_type === 'Seller' && !op.permitted,
        )?.length === 0
      )
        return false;
    }
    const allPermitted = formData?.marketplaces?.filter((op) => !op.permitted);
    if (allPermitted?.length) return true;
    return false;
  };

  const generateCheckBoxForNoAccount = (type) => {
    return (
      <CheckBox className="mb-4">
        <label className="check-container customer-pannel " htmlFor={type}>
          {`I don't have ${type} Central Account Yet`}
          <input
            type="checkbox"
            id={type}
            name={type}
            defaultChecked={
              type === 'Seller'
                ? formData?.no_seller_account
                : formData?.no_vendor_account
            }
            onChange={() => {
              const marketplaces = [...formData?.marketplaces];
              marketplaces.filter((op) => {
                if (type === op.account_type) {
                  op.permitted = false;
                }
                return null;
              });
              setFormData({
                ...formData,
                marketplaces: [...marketplaces],
                [type === 'Seller' ? 'no_seller_account' : 'no_vendor_account']:
                  !(type === 'Seller'
                    ? formData?.no_seller_account
                    : formData?.no_vendor_account),
              });
            }}
            readOnly
          />
          <span className="checkmark" />
        </label>
      </CheckBox>
    );
  };

  const displayMarketplacesName = (value) => {
    return marketplacesList?.find((op) => value === op.value)?.label;
  };

  const generateHTML = (type) => {
    return (
      <fieldset className="shape-without-border  w-430 mt-3 mb-1">
        <div className="black-heading-title mt-1">{type} Central Account</div>
        <p className="normal-text-black line-height mt-2 mb-3">
          Please select all marketrplaces that you’ve invited us to proceed.
        </p>
        {formData?.marketplaces?.map((field) => {
          if (field.account_type === type || !field.account_type)
            return (
              <React.Fragment key={field?.name}>
                <div
                  className={
                    (type === 'Seller' && formData?.no_seller_account) ||
                    (type === 'Vendor' && formData?.no_vendor_account)
                      ? 'justify-space-between isDisabled'
                      : 'justify-space-between'
                  }
                >
                  <CheckBox>
                    <label
                      className="check-container customer-pannel "
                      htmlFor={`${type}-${field?.name}`}
                    >
                      {displayMarketplacesName(field?.name)}
                      <input
                        className="checkboxes"
                        type="checkbox"
                        id={`${type}-${field?.name}`}
                        name={`${type}-${field?.name}`}
                        readOnly
                        defaultChecked={field?.permitted}
                        onChange={() => {
                          const marketplaces = [...formData?.marketplaces];
                          marketplaces.filter((op) => {
                            if (
                              op.account_type === field.account_type &&
                              op.name === field.name
                            ) {
                              op.permitted = !field.permitted;
                            }
                            return null;
                          });
                          setFormData({
                            ...formData,
                            marketplaces: [...marketplaces],
                          });
                        }}
                        disabled={
                          (type === 'Seller' && formData?.no_seller_account) ||
                          (type === 'Vendor' && formData?.no_vendor_account)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </CheckBox>
                </div>
                <div className="horizontal-line straight-line check-box mt-2 mb-3" />
              </React.Fragment>
            );
          return null;
        })}
      </fieldset>
    );
  };

  return (
    <OnBoardingBody className="body-white w-430 pb-4 mt-3">
      <AmazonAccountPermission>
        {formData?.account_type === 'Hybrid' ||
        formData?.account_type === null ? (
          <>
            {generateCheckBoxForNoAccount('Seller')}
            {generateCheckBoxForNoAccount('Vendor')}
          </>
        ) : (
          generateCheckBoxForNoAccount(formData?.account_type)
        )}
        {((formData?.account_type === 'Hybrid' ||
          formData?.account_type === null) &&
          formData?.no_seller_account &&
          formData?.no_vendor_account) ||
        (formData?.account_type === 'Vendor' && formData?.no_vendor_account) ||
        (formData?.account_type === 'Seller' &&
          formData?.no_seller_account) ? null : (
          <>
            <div className="horizontal-line straight-line mt-3 mb-3" />
            <div className="justify-space-between">
              <div className="black-heading-title">
                Grant us access to your Amazon account(s) to start managing your
                Amazon business
              </div>
            </div>
            <p className=" mt-2 mb-0 gray-normal-text ">
              {' '}
              For every marketplace listed below, please follow these steps
            </p>
            {amazonAccountManual.map((item) => (
              <div className="d-flex  align-items-center">
                <FontAwesomeIcon
                  icon={item.icon}
                  color="#FF5933"
                  style={{
                    marginRight: '12px',
                    border: '1px solid #FF5933',
                    padding: '4px 8px',
                    borderRadius: '50%',
                    fontSize: '14px',
                  }}
                />
                <div key={item.label}>
                  <p className="normal-text-black mt-2 mb-0 line-height">
                    {item.info}
                  </p>
                </div>
              </div>
            ))}
            <div className="horizontal-line straight-line mt-3 mb-3" />
          </>
        )}

        {formData?.account_type === 'Hybrid' ||
        formData?.account_type === null ? (
          <>
            {formData?.no_seller_account ? null : <>{generateHTML('Seller')}</>}
            {formData?.no_vendor_account ? null : <>{generateHTML('Vendor')}</>}
          </>
        ) : (
          <>
            {(formData?.account_type === 'Seller' &&
              formData?.no_seller_account) ||
            (formData?.account_type === 'Vendor' &&
              formData?.no_vendor_account) ? null : (
              <>{generateHTML(formData?.account_type)}</>
            )}
          </>
        )}

        {isChecked ? (
          ''
        ) : (
          <Button
            className="btn-primary w-100 mt-4"
            onClick={() => saveAmazonAccount()}
            disabled={
              formData?.account_type === null
                ? false
                : disableBtnUntilAllChecked()
            }
          >
            {' '}
            {isLoading.loader && isLoading.type === 'button' ? (
              <PageLoader color="#fff" type="button" />
            ) : (
              <>{assignedToSomeone ? 'Submit' : 'Continue'} </>
            )}
          </Button>
        )}
      </AmazonAccountPermission>
    </OnBoardingBody>
  );
}

AmazonDeveloperAccess.defaultProps = {
  stepData: {},
};

AmazonDeveloperAccess.propTypes = {
  userInfo: shape({
    id: string,
    email: string,
    customer: string,
    customer_onboarding: string,
    step: shape({
      step: number,
    }),
  }).isRequired,
  setIsLoading: func.isRequired,
  assignedToSomeone: bool.isRequired,
  stepData: shape({
    id: string,
  }),
  verifiedStepData: shape({
    user_name: string,
  }).isRequired,
  isLoading: shape({
    loader: bool,
    type: string,
  }).isRequired,
  data: arrayOf(shape({ id: string })).isRequired,
  isChecked: bool.isRequired,
};
