// React imports
import React, { useState } from 'react';

// Third party imports
import dayjs from 'dayjs';
import Select, { components } from 'react-select';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { useMediaQuery } from 'react-responsive';

// Local file imports
import Theme from '../../../../theme/Theme';
import { CaretUp } from '../../../../theme/images';
import {
  CUSTOM,
  dashboardTimeFilterOptions,
  performanceTypeOptionsForInventory,
} from '../../../../constants';
import {
  WhiteCard,
  DropdownIndicator,
  CustomDropDown,
  DropDowns,
  MultiSelectSearchableDropdown,
  SingleSelectDropdown,
} from '../../../../common';

const InventoryFilter = ({
  // boolean
  isApiCall,
  isBGSAdmin,
  isSpreetailRole,
  isBGSManager,
  isCustomDateApply,
  // objects
  customDateState,
  selectedTimeFrame,
  selectedMarketplace,
  selectedPerformanceType,
  selectedBrandPartner,
  // array
  userList,
  managerUserList,
  selectedUser,
  selectedManagerUser,
  marketplaceOptions,
  brandPartnerOptions,
  // function
  handleDailyFact,
  handleResetFilter,
  handleMarketplace,
  setSelectedUser,
  setSelectedManagerUser,
  handleBgsUserFilterEvents,
  handleUserOnMenuCloseEvents,
  handleManagerUserFilterEvents,
  handleManagerUserOnMenuCloseEvents,
  handleSellerVendorFilterEvents,
  handleBrandPartnerFilterEvents,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const { Option, SingleValue } = components;

  // render multi select dropdown for bgs and ad manager
  const renderUserDropdown = () => {
    return (
      <div className="col-lg-12 col-md-6 col-12">
        <div className="label mt-3">
          {isBGSAdmin || isBGSManager || isSpreetailRole ? 'BGS' : 'Ad Manager'}
        </div>

        <div id="user-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-InventoryUserFilter"
            options={userList?.filter((option) => option?.value !== 'all')}
            label=""
            value="user"
            multiSelectedValues={{ user: selectedUser }}
            setMultiSelectedValues={setSelectedUser}
            handleMultiSelectChange={handleBgsUserFilterEvents}
            handleOnMenuClose={handleUserOnMenuCloseEvents}
            isLoading={isApiCall}
            isSearchable
          />
        </div>
      </div>
    );
  };

  // render multi select dropdown for bgs manager and ad team manager
  const renderManagerUserDropdown = (label) => {
    return (
      <div className="col-lg-12 col-md-6 col-12">
        <div className="label mt-3">{label}</div>
        <div id="manager-user-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-InventoryManagerUserFilter"
            options={managerUserList?.filter(
              (option) => option?.value !== 'all',
            )}
            label=""
            value="managerUser"
            multiSelectedValues={{ managerUser: selectedManagerUser }}
            setMultiSelectedValues={setSelectedManagerUser}
            handleMultiSelectChange={handleManagerUserFilterEvents}
            handleOnMenuClose={handleManagerUserOnMenuCloseEvents}
            isLoading={isApiCall}
            isSearchable
          />
        </div>
      </div>
    );
  };

  const renderCustomDateSubLabel = (props) => {
    if (selectedTimeFrame?.value === CUSTOM && isCustomDateApply) {
      return `${dayjs(customDateState).format('MMM D, YYYY')}`;
    }
    return props?.data?.sub;
  };

  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>

      <div style={{ fontSize: '10px' }}>{renderCustomDateSubLabel(props)}</div>
    </SingleValue>
  );

  const filterOption = ({ data, ...props }) => (
    <Option {...props}>
      <div>
        <span style={{ fontSize: '14px' }}>{data?.label}</span>
        <div style={{ fontSize: '10px' }}>{data?.sub}</div>
      </div>
    </Option>
  );

  const getTimeFrameDropDownComponents = () => {
    return {
      Option: filterOption,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  // render custom dropdown filter components
  const renderCustomDropdownFilter = (
    filterId,
    optionsList,
    selectedValue,
    onChangeHandler,
  ) => {
    return (
      <CustomDropDown
        id={filterId}
        inputColor={Theme.black}
        className={isApiCall ? 'cursor  disabled' : 'cursor'}
      >
        <Select
          classNamePrefix="react-select"
          className="active"
          value={selectedValue}
          options={optionsList}
          defaultValue={optionsList?.[0]}
          components={{ DropdownIndicator }}
          onChange={(event) => onChangeHandler(event)}
          placeholder={optionsList?.[0]?.label}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
          isOptionDisabled={(option) => option?.isDisabled}
          isLoading={isApiCall}
        />
      </CustomDropDown>
    );
  };

  // render performance type dropdwon filter
  const renderPerformanceDropdownFilter = () => {
    return (
      <div className="col-lg-12 col-md-6 col-12">
        <div className="label mt-3">Performance</div>
        {renderCustomDropdownFilter(
          'BT-InventoryPerformanceFilter',
          performanceTypeOptionsForInventory,
          selectedPerformanceType,
          handleSellerVendorFilterEvents,
        )}
      </div>
    );
  };

  // render marketplace dropdown filter
  const renderMarketplaceDropdownFilter = () => {
    return (
      <div className="col-lg-12 col-md-6 col-12">
        <div className="label mt-3">Marketplace</div>
        {renderCustomDropdownFilter(
          'BT-InventoryMarketplaceFilter',
          marketplaceOptions,
          selectedMarketplace,
          handleMarketplace,
        )}
      </div>
    );
  };

  const renderTimeFrameFilter = (className) => {
    return (
      <div className="col-lg-12 col-md-6 col-12">
        <div className="label mt-3">TimeFrame</div>
        {DropDowns(
          'BT-InventoryTimeFrameFilter',
          className,
          dashboardTimeFilterOptions,
          dashboardTimeFilterOptions?.[0]?.label,
          getTimeFrameDropDownComponents,
          dashboardTimeFilterOptions?.[0],
          handleDailyFact,
          isApiCall,
          null,
          selectedTimeFrame,
          false,
        )}
      </div>
    );
  };

  // get SingleValue component for selected value from dropdown options
  const dropdownFilterSelectedValue = (props) => {
    return (
      <SingleValue {...props}>
        <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
      </SingleValue>
    );
  };

  // get Option component for dropdown options
  const dropdownFilterOptions = (props) => {
    return (
      <Option {...props}>
        <div className={props?.isDisabled ? 'disabled' : ''}>
          <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
        </div>
      </Option>
    );
  };

  // configuring the dropdown select components
  const getDropdownSelectComponents = () => {
    return {
      Option: dropdownFilterOptions,
      SingleValue: dropdownFilterSelectedValue,
      DropdownIndicator,
    };
  };

  // render Brand Partner type dropdwon filter
  const renderBrandPartnerDropdownFilter = () => {
    return (
      <div className="col-12">
        <div className="label mt-3">Brand Partner</div>
        <div
          id="sales-performance-type-filter"
          style={{ position: 'relative' }}
        >
          <SingleSelectDropdown
            filterId="BT-SalesBrandPartnerTypeFilter"
            className="single-select-dropdown"
            dropdownOptions={brandPartnerOptions}
            selectedValue={selectedBrandPartner}
            onChangeHandler={handleBrandPartnerFilterEvents}
            dropdownComponents={getDropdownSelectComponents}
            isApiCall={isApiCall}
            isSearchable
          />
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <>
        {renderBrandPartnerDropdownFilter()}
        {renderPerformanceDropdownFilter()}
        {renderMarketplaceDropdownFilter()}
        {renderTimeFrameFilter()}
        {isBGSAdmin || isSpreetailRole
          ? renderManagerUserDropdown(
              isBGSAdmin || isSpreetailRole ? 'BGS Manager' : 'Ad Team Manager',
            )
          : null}
        {isBGSManager || isBGSAdmin || isSpreetailRole
          ? renderUserDropdown(
              isBGSAdmin || isBGSManager || isSpreetailRole
                ? 'BGS'
                : 'Ad Manager',
            )
          : null}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      <WhiteCard
        onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        className={
          isDesktop ? 'mb-3 d-lg-block d-none' : 'mb-3 d-lg-none d-block'
        }
      >
        <div className="row  ">
          <div className="col-6">
            <div className="black-heading-title ">Filters</div>
          </div>
          <div className={isDesktop ? 'col-6 text-right' : 'col-6 text-right'}>
            <p
              onClick={() => handleResetFilter()}
              style={{ width: 'fit-content' }}
              role="presentation"
              className="gray-normal-text cursor mt-0"
            >
              Reset filters
              {!isDesktop && (
                <span
                  style={{
                    borderLeft: '1px solid #e2e2ea',
                    marginLeft: '10px',
                    marginRight: '20px',
                  }}
                >
                  <span
                    role="presentation"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsCollapseOpen(!isCollapseOpen);
                    }}
                  >
                    <img
                      src={CaretUp}
                      alt="caret"
                      style={{
                        transform: isCollapseOpen ? 'rotate(180deg)' : '',
                        width: '25px',
                        height: '25px',
                        position: 'absolute',
                        right: '5px',
                        top: '-3px',
                      }}
                    />
                  </span>
                </span>
              )}
            </p>
          </div>

          {isDesktop
            ? renderFilters()
            : isCollapseOpen
            ? renderFilters()
            : null}
        </div>
      </WhiteCard>
    </div>
  );
};

export default InventoryFilter;

InventoryFilter.defaultProps = {
  // boolean
  isApiCall: false,
  isBGSAdmin: false,
  isSpreetailRole: false,
  isBGSManager: false,
  isCustomDateApply: false,
  isDisabled: false,
  // objects
  data: {},
  selectProps: {},
  customDateState: {},
  selectedTimeFrame: {},
  selectedMarketplace: {},
  selectedPerformanceType: {},
  selectedBrandPartner: {},
  // array
  userList: [],
  managerUserList: [],
  selectedUser: [],
  selectedManagerUser: [],
  marketplaceOptions: [],
  brandPartnerOptions: [],
  // function
  handleDailyFact: () => {},
  handleResetFilter: () => {},
  handleMarketplace: () => {},
  setSelectedUser: () => {},
  setSelectedManagerUser: () => {},
  handleBgsUserFilterEvents: () => {},
  handleUserOnMenuCloseEvents: () => {},
  handleManagerUserFilterEvents: () => {},
  handleManagerUserOnMenuCloseEvents: () => {},
  handleSellerVendorFilterEvents: () => {},
  handleBrandPartnerFilterEvents: () => {},
};

InventoryFilter.propTypes = {
  // boolean
  isApiCall: bool,
  isBGSAdmin: bool,
  isSpreetailRole: bool,
  isBGSManager: bool,
  isCustomDateApply: bool,
  isDisabled: bool,
  // objects
  data: shape({
    label: string,
    sub: string,
  }),
  selectProps: shape({
    menuIsOpen: bool,
  }),
  customDateState: shape({}),
  selectedTimeFrame: shape({}),
  selectedMarketplace: shape({}),
  selectedPerformanceType: shape({}),
  selectedBrandPartner: shape({}),
  // array
  userList: arrayOf(shape({})),
  managerUserList: arrayOf(shape({})),
  selectedUser: arrayOf(shape({})),
  selectedManagerUser: arrayOf(shape({})),
  marketplaceOptions: arrayOf(shape({})),
  brandPartnerOptions: arrayOf(shape({})),
  // function
  handleDailyFact: func,
  handleResetFilter: func,
  handleMarketplace: func,
  setSelectedUser: func,
  setSelectedManagerUser: func,
  handleBgsUserFilterEvents: func,
  handleUserOnMenuCloseEvents: func,
  handleManagerUserFilterEvents: func,
  handleManagerUserOnMenuCloseEvents: func,
  handleSellerVendorFilterEvents: func,
  handleBrandPartnerFilterEvents: func,
};
