import React, { useState, useEffect, useCallback } from 'react';

import { shape, string, bool, func, arrayOf } from 'prop-types';

import Theme from '../../../theme/Theme';
import useActivityLog from '../../../hooks/useActivityLog';
import { GroupUser } from '../../../theme/Global';
import { getCustomerActivityLog, getDocumentList } from '../../../api';
import {
  ContractEmailIcon,
  DefaultUser,
  NextActivityLogo,
} from '../../../theme/images';
import {
  CommonPagination,
  DropdownIndicator,
  GetInitialName,
  PageLoader,
  SingleSelectDropdown,
  Status,
  ViewData,
  WhiteCard,
} from '../../../common';

export default function Activity({
  isLoading,
  viewComponent,
  setIsLoading,
  id,
  childBPList,
  customerType,
}) {
  const activityDetail = useActivityLog(viewComponent);

  const [activityData, setActivityData] = useState(null);
  const [activityCount, setActivityCount] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [images, setImages] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    childBPList?.length ? childBPList?.[0]?.value : id,
  );

  useEffect(() => {
    if (childBPList?.length) setSelectedCustomerId(childBPList?.[0]?.value);
  }, [childBPList]);

  const bindActivityLogData = useCallback((responseData) => {
    const tempActivityData = [];
    if (responseData?.length) {
      for (const item of responseData) {
        tempActivityData.push({
          ...item,
          isAllowForShowMoreAndLess:
            !!item?.history_change_reason?.includes('DSP_NOTES_START'),
          showMore: false,
        });
      }
    }

    return tempActivityData;
  }, []);

  useEffect(() => {
    setIsLoading({ loader: true, type: 'activityPage' });
    getCustomerActivityLog(pageNumber, selectedCustomerId).then((response) => {
      setActivityData(bindActivityLogData(response?.data?.results));
      setActivityCount(response?.data?.count);
      setPageNumber(pageNumber);
      getDocumentList().then((picResponse) => {
        setImages(picResponse);
      });
      setIsLoading({ loader: false, type: 'activityPage' });
    });
  }, [selectedCustomerId, setIsLoading, pageNumber, bindActivityLogData]);

  // https://bbe.atlassian.net/browse/PDV-9350 - DSP Notes - Activity Logs and Billing Overlay
  // function for updating activity data array
  const handleShowMoreLessEvents = (itemIndex) => {
    const updatedActivityData = [...activityData];
    updatedActivityData[itemIndex].showMore =
      !updatedActivityData[itemIndex].showMore;

    setActivityData(updatedActivityData);
  };

  const getActivityInitials = (user) => {
    if (user) {
      const userDetail = {
        first_name: user.split(' ')[0],
        last_name: user.split(' ')[1],
      };
      return <GetInitialName userInfo={userDetail} type="activity" />;
    }
    return '';
  };

  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getCustomerActivityLog(currentPage);
  };

  // handle child bp filter events
  const handleChildBPFilterEvents = (event) => {
    if (event?.value !== selectedCustomerId) {
      setSelectedCustomerId(event?.value);
    }
  };

  // get single select dropdown components
  const getSingleSelectComponents = () => {
    return {
      DropdownIndicator,
    };
  };

  // display child bp filter
  const displayChildBPFilter = () => {
    return (
      <SingleSelectDropdown
        filterId="BT-amc-childBrandFilter"
        dropdownOptions={childBPList}
        selectedValue={childBPList?.find(
          (op) => op?.value === selectedCustomerId,
        )}
        onChangeHandler={handleChildBPFilterEvents}
        dropdownComponents={getSingleSelectComponents}
        // isApiCall={isApiCall}
        isSearchable={false}
      />
    );
  };

  return (
    <>
      <div className="col-12 mb-3">
        {childBPList?.length &&
        customerType === 'parent' &&
        !isLoading.loader ? (
          <WhiteCard className="mb-3">
            <ViewData>
              <div className="row">
                <div className="col-md-4  col-sm-12 ">
                  <div className="view-data-for mt-2 pt-1 ">View data for:</div>{' '}
                </div>
                <div className="col-md-4  mt-2 pt-1 pl-0"> </div>
                <div className="col-md-4 col-12 pl-0">
                  {displayChildBPFilter()}
                </div>
              </div>
            </ViewData>
          </WhiteCard>
        ) : null}
        <WhiteCard className="activity-card">
          {(isLoading.loader && isLoading.type === 'activityPage') ||
          activityData === null ? (
            <PageLoader
              component="activity"
              color="#FF5933"
              type="page"
              width={40}
              height={40}
            />
          ) : (
            <>
              <p className="black-heading-title mt-0 mb-4"> Recent Activity</p>
              {activityData &&
                activityData.map((item, index) => (
                  <GroupUser className="mb-2" key={Math.random()}>
                    {(images.find(
                      (op) => op.entity_id === item.history_user_id,
                    ) &&
                      images.find((op) => op.entity_id === item.history_user_id)
                        .presigned_url) ||
                    (item.history_change_reason &&
                      item.history_change_reason.split(' ').slice(0, 2) &&
                      item.history_change_reason.split(' ').slice(0, 2)[0] ===
                        'System' &&
                      item.history_change_reason
                        .split(' ')
                        .slice(0, 2)[1]
                        .toLowerCase() === 'user') ||
                    item?.status !== undefined ? (
                      <div
                        className={
                          item?.status !== undefined ? 'contract-email' : ''
                        }
                      >
                        <img
                          src={
                            isLoading.loader && isLoading.type === 'page'
                              ? DefaultUser
                              : item.history_change_reason
                                  .split(' ')
                                  .slice(0, 2) &&
                                item.history_change_reason
                                  .split(' ')
                                  .slice(0, 2)[0] === 'System'
                              ? NextActivityLogo
                              : item?.status !== undefined
                              ? ContractEmailIcon
                              : images.find(
                                  (op) => op.entity_id === item.history_user_id,
                                ).presigned_url
                          }
                          className={
                            item && item.status !== undefined
                              ? 'default-user-activity contract-mail'
                              : 'default-user-activity '
                          }
                          alt="pic"
                        />
                      </div>
                    ) : (
                      <div className=" float-left mr-3">
                        {getActivityInitials(item.history_change_reason)}
                      </div>
                    )}
                    <div className="activity-user mb-3">
                      {activityDetail(
                        item,
                        true,
                        index,
                        handleShowMoreLessEvents,
                      )}

                      <div className="time-date mt-1">
                        {item?.history_date ? item.history_date : ''}
                      </div>
                      {item?.status ? (
                        <>
                          <Status
                            label={item.status}
                            backgroundColor={
                              item.status === 'delivered'
                                ? Theme.lightGreen
                                : item.status === 'processed'
                                ? Theme.lightYellow
                                : Theme.lightRed
                            }
                            pointColor={
                              item.status === 'delivered'
                                ? Theme.green
                                : item.status === 'processed'
                                ? Theme.yellow
                                : Theme.red
                            }
                          />
                          {item.status === 'delivered' ? (
                            <div className="email-clicks">
                              <span className="email-opens">
                                Opens: {item.opens || 0}
                              </span>
                              <span className="email-opens">
                                Clicks: {item.clicks || 0}
                              </span>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="clear-fix" />
                  </GroupUser>
                ))}
              <div>
                {activityData && activityData.length === 0 ? (
                  <div>No Activity Log found.</div>
                ) : (
                  ''
                )}
                <CommonPagination
                  count={activityCount}
                  pageNumber={pageNumber}
                  handlePageChange={handlePageChange}
                />
              </div>
            </>
          )}
        </WhiteCard>
      </div>
    </>
  );
}

Activity.defaultProps = {
  childBPList: [],
  customerType: 'standalone',
};

Activity.propTypes = {
  isLoading: shape({
    loader: bool,
    type: string,
  }).isRequired,
  id: string.isRequired,
  viewComponent: string.isRequired,
  setIsLoading: func.isRequired,
  childBPList: arrayOf(shape({})),
  customerType: string,
};
