import styled from 'styled-components';
import Theme from '../../../../../theme/Theme';

export const AmcLabelWithDash = styled.div`
  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 15px;
      padding-bottom: 5px;
      color: #000000;
      font-family: ${Theme.baseFontFamily};
      font-size: 15px;

      span.dash-bullet {
        width: 14px;
        height: 2px;
        margin: 0 5px 4px 0;
        display: inline-block;
      }
    }
    li:last-child {
      margin-right: 0px;
    }
  }
  @media only screen and (min-width: 1200px) {
    ul {
      margin-top: 10px;
    }
  }
`;

export const AMCStyle = styled.div`
  .orange-dot {
    background: ${Theme.orange};
    width: 6px;
    height: 6px;
    box-shadow: 0px 0px 14px 0px ${Theme.orange};
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 1px;
  }
  .second-level-navigation {
    display: inline-flex;
    padding-left: 0px;
    margin: 0;

    li {
      padding: 12px;
      list-style-type: none;
      border-radius: 6px;
      color: black;
      cursor: pointer;
    }
    .selected {
      background-color: #ff5933;
      color: white;
    }
  }
`;

export const AMCTable = styled.table`
  position: relative;
  width: 100%;
  border-spacing: 0;

  tr {
    text-align: left;
    background: ${Theme.white};
    border-radius: 1px;
    font-family: ${Theme.baseFontFamily};
    width: 100%;
    &.border-bottom td {
      border-bottom: 1px solid ${Theme.gray11};
    }

    th {
      padding: 5px 2px;
      text-transform: uppercase;
      color: ${Theme.black};
      font-size: 11px;
      background: ${Theme.white};
      font-weight: 600;
      font-family: ${Theme.baseMediumFontFamily};

      &.product-header {
        border-bottom: 1px solid ${Theme.gray11};
      }
    }

    td {
      padding: 5px 2px;
      vertical-align: middle;
      position: relative;
      color: ${Theme.black};
      font-size: ${Theme.extraSmall};
    }
  }
`;

export const AMCVerticleTable = styled.table`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-spacing: 0;
  thead {
    width: 100%;
    min-width: 100px;
    max-width: 100px;
  }
  tbody {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  tr {
    display: flex;
    flex-direction: column;
    text-align: left;
    background: ${Theme.white};
    border-radius: 1px;
    font-family: ${Theme.baseFontFamily};
    width: 100%;
    max-width: 100px;
    &.border-bottom td {
      border-right: 2px solid ${Theme.gray11};
    }

    th {
      padding: 10px 7px;
      text-transform: uppercase;
      color: ${Theme.black};
      font-size: ${Theme.extraNormal};
      background: ${Theme.white};
      font-weight: 600;
      font-family: ${Theme.baseMediumFontFamily};

      &.product-header {
        border-right: 2px solid ${Theme.gray11};
      }
      &.date {
        border-bottom: 2px solid ${Theme.gray11};
      }
    }

    td {
      padding: 10px 5px;
      vertical-align: middle;
      position: relative;
      color: ${Theme.black};
      font-size: ${Theme.extraNormal};
      &.date {
        font-weight: 600;
        border-bottom: 2px solid ${Theme.gray11};
      }
    }
  }
`;
