/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */

import axiosInstance from '../axios';
import {
  API_PLAYBOOK_QUARTER_DATA,
  API_CUSTOMER_PLAYBOOK,
  API_CREATE_PLAYBOOK,
  API_UPDATE_TASK_STATUS,
  API_PLAYBOOK_CONTRACT_SERVICES,
  API_PLAYBOOK_SERVICES_LIST,
  API_GET_ASSIGNEE,
  API_UPDATE_ASSIGNEE,
  API_ONBOARDING_PLAYBOOK_DATA,
  API_PLAYBOOK_TEAM,
  API_PLAYBOOK_SUMMARY,
  API_PLAYBOOK_SERVICES,
  API_SEND_FOR_APPROVAL,
} from '../constants';

// get playbook task and service data
export async function getPlaybookTaskAndServicesData(params) {
  const result = await axiosInstance
    .get(`${API_PLAYBOOK_QUARTER_DATA}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get playbook team
export async function getPlaybookTeamData() {
  const result = await axiosInstance
    .get(`${API_PLAYBOOK_TEAM}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get playbook service list
export async function getPlaybookServicesListData(customerId) {
  const params = {
    customer: customerId,
  };
  const result = await axiosInstance
    .get(`${API_PLAYBOOK_SERVICES_LIST}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// Get the update status id for onboarding playbook
export async function getOnboardingPBTaskUpdateID(params) {
  const result = await axiosInstance
    .get(`${API_CUSTOMER_PLAYBOOK}/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// save the update status of playbook
export async function updateTaskStatus(id, data) {
  const result = await axiosInstance
    .patch(`${API_UPDATE_TASK_STATUS}${id}/`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get playbook contract services
export async function getPlaybookContractServices(customerId, month) {
  const params = {
    customer: customerId,
    month,
  };
  const result = await axiosInstance
    .get(`${API_PLAYBOOK_CONTRACT_SERVICES}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// create playbook epic and task automaticaaly
export async function createCustomerPlaybook(customerId, team) {
  const params = {
    customer_id: customerId,
    team,
  };
  const result = await axiosInstance
    .post(`${API_CREATE_PLAYBOOK}`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get assigned users of customer
export async function getBBEUsersData(customerId, searchQuery, currentPage) {
  const params = {
    customer: customerId,
    search_user: searchQuery || '',
    page: currentPage || 1,
  };
  const result = await axiosInstance
    .get(`${API_GET_ASSIGNEE}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// update Playbook Task Assignee
export async function updatePlaybookTaskAssignee(quarterId, params) {
  const result = await axiosInstance
    .patch(`${API_UPDATE_ASSIGNEE}${quarterId}/`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get onboarding playbook data
export async function getOnboardingPlaybookData(customerId) {
  const params = {
    customer: customerId,
  };
  const result = await axiosInstance
    .get(`${API_ONBOARDING_PLAYBOOK_DATA}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get playbook summary data
export async function getPlaybookSummaryData(
  customerId,
  selectedMarketplaces,
  selectedService,
  selectedTeamMembers,
  customStartDate,
  // customEndDate,
  accountType,
) {
  let marketplacesData = {};
  const list = [];
  // eslint-disable-next-line no-unused-vars
  const marketplace = selectedMarketplaces
    ?.filter((mrktplc) => {
      if (mrktplc?.value !== 'all') return mrktplc;
    })
    ?.map((item) => {
      list.push(item.value);
      return item;
    });

  if (list?.length) {
    marketplacesData = list?.toString();
    // queryString.stringify(list);
  }

  let teamsString = '';
  const tempTeamMembers = [];

  // formatting selected teams data
  const AllTeamsSelected = selectedTeamMembers?.find(
    (option) => option?.value === 'all',
  );
  if (AllTeamsSelected) {
    teamsString = 'all';
  } else {
    for (const item of selectedTeamMembers) {
      tempTeamMembers.push(item?.value);
    }
    teamsString = tempTeamMembers?.toString();
  }

  const params = {
    customer: customerId,
    account_type: accountType?.value,
    service_name: selectedService?.value,
    start_date: customStartDate,
    end_date: customStartDate,
    teams: teamsString,
  };
  const result = await axiosInstance
    .get(
      `${API_PLAYBOOK_SUMMARY}?${
        marketplacesData && marketplacesData?.length
          ? `&marketplace=${marketplacesData}`
          : ''
      }`,
      { params },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// create playbook epic and task automaticaaly
export async function createCustomerPlaybookFromSummary(customerId, data) {
  const params = {
    ...data,
  };
  const result = await axiosInstance
    .post(
      `${API_CUSTOMER_PLAYBOOK}/${customerId}/approve-create-playbook/`,
      params,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get playbook services data
export async function getPlaybookServicesData(
  customerId,
  playbookTab,
  selectedMarketplaces,
  selectedTeamMembers,
  customStartDate,
  accountType,
) {
  const marketplacesData = selectedMarketplaces?.value;

  let teamsString = '';
  const tempTeamMembers = [];

  // formatting selected teams data
  const AllTeamsSelected = selectedTeamMembers?.find(
    (option) => option?.value === 'all',
  );
  if (AllTeamsSelected) {
    teamsString = 'all';
  } else {
    for (const item of selectedTeamMembers) {
      tempTeamMembers.push(item?.value);
    }
    teamsString = tempTeamMembers?.toString();
  }

  const params = {
    customer_id: customerId,
    service_duration:
      playbookTab === 'standard-monthly'
        ? 'Standard Monthly'
        : 'Additional One-Time',
    start_date: customStartDate,
    teams: teamsString,
  };
  if (playbookTab === 'standard-monthly') {
    params.account_type = accountType;
  }

  const result = await axiosInstance
    .get(
      `${API_PLAYBOOK_SERVICES}?${
        marketplacesData && marketplacesData?.length
          ? `marketplace=${marketplacesData}`
          : ''
      }`,
      { params },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// send for approval
export async function sendForApprovalOfPlaybook(data) {
  const result = await axiosInstance
    .post(`${API_SEND_FOR_APPROVAL}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
