export const PendingApprovalsTableHeader = [
  {
    key: 'bp_name',
    label: 'BP NAME',
    width: '15%',
    className: '',
  },

  {
    key: 'contract_type',
    label: 'Contract Type',
    width: '15%',
    className: '',
  },
  {
    key: 'requested_by',
    label: 'Requested By',
    width: '15%',
    className: '',
  },
  {
    key: 'request_type',
    label: 'Request Type',
    width: '15%',
    className: '',
  },

  {
    key: 'approval_needed_from',
    label: `Approval Needed From`,
    width: '15%',
    className: '',
  },

  {
    key: 'request_status',
    label: 'Request Status',
    width: '15%',
    className: '',
  },
  {
    key: 'action',
    label: 'Action',
    width: '10%',
    className: 'text-center',
  },
];

export const PAStatusColorSet = {
  'Approval Request Sent': { labelColor: '#64c354', background: '#e2ffdd' },
  'Cancellation Approval Request Sent': {
    labelColor: '#f7c137',
    background: '#fdf3d7',
  },
  'Cancellation Approval Reminder Sent': {
    labelColor: '#d60000',
    background: '#f7cccc',
  },
  'Addendum Approval Request Sent': {
    labelColor: '#ff5933',
    background: '#ffded6',
  },
};
