import React from 'react';

import styled from 'styled-components';
import { string, node } from 'prop-types';

import Theme from '../theme/Theme';
import { TelescopeIcon } from '../theme/images';

export default function NoRecordFound({
  type,
  titleClassName,
  subTitleClassName,
  title,
  subTitle,
  image,
  imageClassName,
}) {
  return (
    <NoRecord type={type}>
      <div
        className={
          type === 'brand'
            ? 'NoRecordFound no-record-found'
            : 'text-center no-record-found'
        }
      >
        {type === 'creative-noData' ? null : (
          <img className={imageClassName} src={image} alt="No record found" />
        )}
        <p className={titleClassName}>{title} </p>
        <strong className={subTitleClassName}>{subTitle}</strong>
      </div>
    </NoRecord>
  );
}

NoRecordFound.defaultProps = {
  type: '',
  titleClassName: '',
  subTitleClassName: '',
  title: '',
  subTitle: '',
  image: TelescopeIcon,
  imageClassName: '',
};

NoRecordFound.propTypes = {
  type: string,
  titleClassName: string,
  subTitleClassName: string,
  title: string,
  subTitle: string,
  image: node,
  imageClassName: string,
};

const NoRecord = styled.div`
  position: ${(props) =>
    props.type === 'brand' || props.type === 'creative' ? 'unset' : 'absolute'};
  right: 0;
  left: 0;
  top: ${(props) =>
    props.type === 'feedback'
      ? 0
      : props.type === 'creative-noData'
      ? '270px'
      : props.type === 'creative'
      ? '130px'
      : '0'};
  padding-top: 100px;
  margin: ${(props) => (props.type === 'brand' ? 'auto' : '')};

  .NoRecordFound {
    margin: auto;
    text-align: center;
  }

  .no-record-found {
    img {
      width: 240px;
      height: 240px;
    }
    p {
      font-size: 16px;
      color: ${Theme.gray90};
    }
    .customize-Image {
      width: 64px;
      height: 64px;
    }
    strong {
      font-size: 24px;
      color: ${Theme.gray90};
    }
    .customize-text {
      font-size: 22px;
      color: ${Theme.black};
      font-weight: bold;
      margin: 24px 0 5px 0;
    }
    .sub-title-text {
      font-size: 14px;
      color: ${Theme.gray85};
    }
  }
`;
