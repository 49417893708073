import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { bool, func, string } from 'prop-types';

import Theme from '../theme/Theme';

function ReportTextEditor({
  id,
  setData,
  data,
  placeholder = null,
  wrapperClassName,
  toolbarClassName,
  editorClassName,
  onFocusOut,
  isCharLimit,
}) {
  const [editorState, setEditorState] = useState(null);
  const [contentState, setContentState] = useState(null);
  const maxLength = 2000;

  const editotIconOptions = [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    // 'remove',
    'history',
    'link',
    'colorPicker',
    'image',
  ];

  const fontFamilyOptions = [
    'Noah Regular',
    'Noah-Medium',
    'Noah-Bold',
    'Arial',
    'Georgia',
    'Impact',
    'Tahoma',
    'Times New Roman',

    'Verdana',
    'Open Sans',
    'Helvetica-Regular',
  ];

  const hyperlinkOptions = {
    inDropdown: false,
    defaultTargetOption: '_blank',
    options: ['link', 'unlink'],
  };

  const colorPickerOptions = [
    'rgb(0,0,0)',
    'rgb(97,189,109)',
    'rgb(26,188,156)',
    'rgb(84,172,210)',
    'rgb(44,130,201)',
    'rgb(147,101,184)',
    'rgb(71,85,119)',
    'rgb(204,204,204)',
    'rgb(65,168,95)',
    'rgb(0,168,133)',
    'rgb(61,142,185)',
    'rgb(41,105,176)',
    'rgb(85,57,130)',
    'rgb(40,50,78)',
    'rgb(247,218,100)',
    'rgb(251,160,38)',
    'rgb(235,107,86)',
    'rgb(226,80,65)',
    'rgb(163,143,132)',
    'rgb(239,239,239)',
    'rgb(255,255,255)',
    'rgb(250,197,28)',
    'rgb(243,121,52)',
    'rgb(209,72,65)',
    'rgb(184,49,47)',
    'rgb(124,112,107)',
    'rgb(209,213,216)',
    'rgb(255,255,255)',
  ];
  const listOptions = {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered'],
    // options: ['unordered', 'ordered', 'indent', 'outdent'],
    unordered: {
      className: undefined,
    },
    ordered: {
      className: undefined,
    },
    indent: {
      className: undefined,
    },
    outdent: {
      className: undefined,
    },
  };

  // use effect function
  useEffect(() => {
    let contentBlock;

    if (data) {
      contentBlock = htmlToDraft(data);
    } else {
      contentBlock = htmlToDraft('<p></p>');
    }
    const content = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    setEditorState(EditorState.createWithContent(content));
    setContentState(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle on editor state change events
  const onEditorStateChange = (editorData) => {
    setEditorState(editorData);
    const content = editorData.getCurrentContent();
    const info = draftToHtml(convertToRaw(content))?.replace(/&nbsp;/g, '');
    setData(info);
  };

  // handle cpoy-paste text
  const handlePastedText = () => {
    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
    );
    onEditorStateChange(
      EditorState.push(editorState, newContent, 'insert-characters'),
    );
    return true;
  };

  // handle image upload events
  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({ data: { link: reader?.result } });
      reader.onerror = (error) => reject(error);
    });
  }

  // image upload option
  const imageUploadOptions = {
    className: isCharLimit ? 'd-none' : undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: false,
    uploadEnabled: true,
    alignmentEnabled: false,
    uploadCallback: uploadImageCallBack,
    previewImage: true,
    inputAccept:
      'image/gif,image/jpeg,image/jpg,image/png,image/svg,image/svg+xml,image/webp,image/avif',
    alt: { present: true, mandatory: false },
    defaultSize: { width: 'auto', height: 'auto' },
  };

  const displayEditor = () => {
    return (
      <EditorContainer className="editor-container">
        <Editor
          id={id}
          editorState={editorState}
          contentState={contentState}
          wrapperClassName={wrapperClassName || 'wrapperClassName'}
          toolbarClassName={toolbarClassName || 'toolbarClassName'}
          editorClassName={editorClassName || 'editorClassName'}
          onBlur={(_event, editorStates) => {
            const content = editorStates.getCurrentContent();
            const info = draftToHtml(convertToRaw(content))?.replace(
              /&nbsp;/g,
              '',
            );
            onFocusOut(info);
          }}
          onEditorStateChange={(event) => onEditorStateChange(event)}
          handleBeforeInput={(val) => {
            const textLength = editorState
              .getCurrentContent()
              .getPlainText().length;
            if (val && textLength >= maxLength) {
              return 'handled';
            }
            return 'not-handled';
          }}
          handlePastedText={(event) => {
            handlePastedText(event);
          }}
          stripPastedStyles
          placeholder={placeholder === null ? '   Enter note' : placeholder}
          toolbar={{
            options: editotIconOptions,
            link: hyperlinkOptions,
            fontFamily: {
              options: fontFamilyOptions,
            },
            colorPicker: {
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              colors: colorPickerOptions,
            },
            list: listOptions,
            image: imageUploadOptions,
          }}
        />
      </EditorContainer>
    );
  };

  return <div>{displayEditor()}</div>;
}

export default ReportTextEditor;
ReportTextEditor.defaultProps = {
  id: '',
  wrapperClassName: '',
  toolbarClassName: '',
  editorClassName: '',
  data: '',
  placeholder: '',
  isCharLimit: false,
  setData: () => {},
  onFocusOut: () => {},
};

ReportTextEditor.propTypes = {
  id: string,
  data: string,
  placeholder: string,
  wrapperClassName: string,
  toolbarClassName: string,
  editorClassName: string,
  isCharLimit: bool,
  setData: func,
  onFocusOut: func,
};

export const EditorContainer = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    text-transform: unset;
    letter-spacing: unset;
    font-family: inherit;
    text-decoration: inherit;
    text-decoration-thickness: 2px !important;
    text-underline-position: under;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 10px 0;
  }

  h1 {
    font-size: 40px;
    line-height: 40px;
  }
  h2 {
    font-size: 32px;
    line-height: 32px;
  }
  h3 {
    font-size: 28px;
    line-height: 28px;
  }
  h4 {
    font-size: 24px;
    line-height: 24px;
  }
  h5 {
    font-size: 20px;
    line-height: 20px;
  }
  h6 {
    font-size: 18px;
    line-height: 18px;
  }

  h1 span {
    background-color: inherit;
    padding: inherit;
    font-size: inherit !important;
    vertical-align: inherit;
    font-family: inherit;
    font-weight: inherit;
    text-transform: inherit;
    text-align: inherit;
    color: inherit;
    border-radius: inherit;
    letter-spacing: inherit;
    margin-left: inherit;
  }

  .DraftEditor-editorContainer {
    line-height: 18px;
  }
  .rdw-editor-wrapper {
    border-radius: 4px;
  }
  .rdw-editor-toolbar {
    border: none;
    padding: 0;
  }
  .rdw-link-decorator-icon {
    left: auto;
    margin-left: 2px;
  }
  .rdw-editor-main {
    background: ${Theme.gray8};
    border: 1px solid ${Theme.gray45};
    padding: 10px 0;
    min-height: 30px;
    margin: 0 5px;
  }

  .rdw-colorpicker-modal {
    width: 200px;
    height: 200px;
  }
  .rdw-colorpicker-modal-header {
    margin: 10px 0 !important;
  }
  .rdw-colorpicker-modal-style-label {
    font-size: 14px;
    margin: 0 !important;
  }
  .rdw-colorpicker-modal-style-label-active {
    color: #000000;
    font-weight: bold;
    border-bottom: 2px solid #ff5933;
  }
  ul.rdw-dropdown-optionwrapper {
    width: 150px;
    padding: 5px 0 5px 5px;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    li {
      color: #000000;
      font-size: 14px;
      font-family: ${Theme.baseFontFamily};
      padding: 0px 5px 0 10px;
    }

    li.rdw-dropdownoption-active {
      color: #ffffff !important;
      background: ${Theme.orange};
      font-weight: bold;
    }
    li:hover {
      color: #000000 !important;
      background: ${Theme.lightOrange};
      font-weight: bold;
    }
    li.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-reset.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR::before {
      content: '.';
      position: absolute;
      width: 6px;
      left: -16px;
      color: inherit;
      height: 6px;
      display: inline-block;
      background: #000000;
      border-radius: 50%;
      top: 30%;
    }
  }
  .rdw-dropdown-wrapper.rdw-fontsize-dropdown {
    ul {
      width: 50px;
    }
  }
  ul.public-DraftStyleDefault-ol {
    padding: 5px 0 5px 5px;
  }
  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    left: -30px;
  }
  .rdw-link-modal {
    width: 300px;
    height: 170px;
    border: none;
    border-radius: 5px;
    font-family: ${Theme.baseFontFamily};
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2);
  }
  .rdw-link-modal-target-option {
    display: none;
  }
  .rdw-link-modal-label {
    font-size: 16px;
    color: #000000;
    font-family: ${Theme.baseMediumFontFamily};
  }
  .rdw-link-modal-input,
  .rdw-image-modal-alt-input,
  .rdw-image-modal-size-input {
    margin: 5px 0 10px 0;
    background: #f4f6fc;
    height: auto;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    padding: 5px;
  }
  .rdw-link-modal-buttonsection {
    margin: 10px auto 0 auto;
  }
  .rdw-link-modal-btn,
  .rdw-image-modal-btn {
    width: 100px;
    height: 32px;
    border: none;
    border-radius: 5px;
    background: ${Theme.orange};
    color: #ffffff;
    font-family: ${Theme.baseMediumFontFamily};
  }
  .public-DraftStyleDefault-block {
    min-height: 0px !important;
  }
  .rdw-image-modal {
    width: 300px;
  }
  .rdw-image-modal-size {
    margin: 0;
  }
  .rdw-image-modal-header-option {
    font-size: 16px;
    color: #000000;
  }
  .rdw-image-modal-header-label-highlighted {
    background: #ff5933;
    border-bottom: 2px solid #ff5933;
  }
  .rdw-image-modal-upload-option-label {
    font-size: 14px;
  }
  .rdw-image-modal-alt-lbl {
    font-size: 14px;
    color: #000000;
  }
  .rdw-image-modal-alt-input {
    width: 75%;
  }
  figure {
    padding: 0px;
    margin: 0;
  }
  figure img {
    max-width: 100%;
    height: auto;
  }
  .rdw-image-center {
    justify-content: left;
  }
`;
