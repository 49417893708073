export const InvoiceApprovalHeader = {
  standard: 'Proposed DSP Invoice Adjustment',
  'permanent additional': 'Proposed DSP Invoice Adjustment',
  'one time': 'Proposed One-Time DSP Invoice Adjustment',
  pause: 'Proposed Pause of DSP Invoice',
};

export const InvoiceTypeNames = {
  standard: 'Standard',
  'permanent additional': 'Permanent Additional',
  'one time': 'One-Time',
  pause: 'Pause',
};

export const InvoiceCurrentMonthHeader = {
  standard: 'Current monthly DSP invoice amount',
  'permanent additional': 'Current monthly DSP invoice amount',
  'one time': 'One-time DSP Invoice',
  pause: 'Current monthly DSP invoice amount',
};

export const InvoiceNewMonthHeader = {
  standard: 'New monthly DSP invoice',
  'permanent additional': 'New monthly DSP invoice',
  'one time': 'One-time DSP Invoice',
  pause: 'New DSP invoice for ',
};

export const InvoiceInfo = {
  standard: {
    mainHeading: 'This will be your new, ongoing invoice amount. ',
    boldHeading:
      'The first bill for this amount will be due APPLICABLE_MONTH APPLICABLE_DATE.',
  },
  'permanent additional': {
    mainHeading: 'This will be your new, ongoing invoice amount. ',
    boldHeading:
      'The first bill for this amount will be due APPLICABLE_MONTH APPLICABLE_DATE.',
  },
  'one time': {
    mainHeading:
      'This will be a one-off invoice, providing additional budget for ',
    boldHeading: 'APPLICABLE_MONTH only. ',
    mainHeading2:
      'This invoice will be sent as soon as you approve the proposal.',
  },
  pause: {
    mainHeading: 'The changes will apply to ',
    boldHeading: 'APPLICABLE_MONTH only',
  },
};

export const invoiceApprovalFlag = [
  { value: 'yes', label: 'I approve the new DSP invoice amount' },
  { value: 'no', label: 'I want to reject the proposal' },
];

export const TotalInvoiceHeader = {
  standard: 'Total',
  'permanent additional': 'Total',
  'one time': 'One-time total',
  pause: 'Total invoice',
};

export const dspContactInputFields = [
  {
    key: 'first_name',
    label: 'First name',
    type: 'text',
    property: 'col-6 pr-2',
    section: 'contact',
  },
  {
    key: 'last_name',
    label: 'Last name',
    type: 'text',
    property: 'col-6 pl-2',
    section: 'contact',
  },

  {
    key: 'email',
    label: 'Email',
    type: 'email',
    property: 'col-6 pr-2',
    section: 'contact',
  },
  {
    key: 'phone_number',
    label: 'Phone number',
    type: 'number',
    property: 'col-6 pl-2',
    section: 'contact',
    removeForDSP: true,
  },
];

export const dspSignOffRoles = {
  grpRole: {
    BGS: true,
    'BGS Manager': true,
    'DSP Ad Manager': true,
  },
  userRole: {
    'BGS Admin': true,
    'Ad Manager Admin': true,
  },
};

export const invoiceStatusIcons = {
  paid: {
    icon: 'fa-regular fa-check',
    iconColor: '#016907',
  },
  late: {
    icon: 'fa-regular fa-alarm-exclamation',
    iconColor: '#016907',
  },
  voided: {
    icon: 'fa-regular fa-circle-exclamation',
    iconColor: '#b20a3d',
  },
  void: {
    icon: 'fa-regular fa-circle-exclamation',
    iconColor: '#b20a3d',
  },
  overdue: {
    icon: 'fa-regular fa-clock-rotate-left',
    iconColor: '#ff5933',
  },
  partially: {
    icon: 'fa-regular fa-calendar-xmark',
    iconColor: '#d63649',
  },
  refunded: {
    icon: 'fa-regular fa-trash-can-undo',
    iconColor: '#000000',
  },
  created: {
    icon: 'fa-regular fa-file-plus',
    iconColor: '#016907',
  },
  cancelled: {
    icon: 'fa-regular fa-xmark',
    iconColor: '#d63649',
  },
  pending: {
    icon: 'fa-regular fa-hourglass-start',
    iconColor: '#d2a225',
  },
  open: {
    icon: 'fa-regular fa-file',
    iconColor: '#000000f7',
  },
  draft: {
    icon: 'fa-regular fa-file',
    iconColor: '#33ac2e',
  },
  approved: {
    icon: 'fa-regular fa-thumbs-up',
    iconColor: '#33ac2e',
  },
  rejected: {
    icon: 'fa-regular fa-xmark',
    iconColor: '#d63649',
  },
  paused: {
    icon: 'fa-regular fa-circle-pause',
    iconColor: '#8798ad',
  },
};

export const getInvoiceStatusKey = (invoiceStatus) => {
  let statusKey = '';
  if (invoiceStatus.toLowerCase().includes('paid partially'))
    statusKey = 'partially';
  else if (invoiceStatus.toLowerCase().includes('refunded'))
    statusKey = 'refunded';
  else if (invoiceStatus.includes('late')) statusKey = 'late';
  else statusKey = invoiceStatus.split(' ')[0].toLowerCase();

  return statusKey;
};

export const invoiceBreakdownOptions = [
  {
    key: 'invoice_number_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black text-bold',
    label: 'Invoice Number',
  },
  {
    key: 'next_invoiced_id',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black text-bold',
  },
  {
    key: 'customer_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black text-bold',
    label: 'Customer',
  },
  {
    key: 'customer',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black text-bold out-door',
  },
  {
    key: 'status_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black',
    label: 'Status',
  },
  {
    key: 'invoice_status',
    property: 'col-6 mb-2 text-right',
    subProperty: 'capsule-status uppercase',
  },
  {
    key: 'payment_term_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black',
    label: 'Payment Terms',
  },
  {
    key: 'payment_terms',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black capitalize',
  },
  {
    key: 'generated_date_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black',
    label: 'Date',
  },
  {
    key: 'generated_at',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black',
  },
  {
    key: 'due_date_label',
    property: 'col-6 mb-2',
    subProperty: 'normal-text-black',
    label: 'Due Date',
  },
  {
    key: 'due_date',
    property: 'col-6 mb-2 text-right',
    subProperty: 'normal-text-black',
  },
];

export const paymentsMethod = {
  other: 'Other',
  credit_card: 'Credit Card',
  cash: 'Cash',
  check: 'Cheque',
  direct_debit: 'Direct Debit',
  eft: 'ETF',
  paypal: 'PayPal',
  wire_transfer: 'Wire Transfer',
};
