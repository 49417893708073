export const timeFilterOptions = [
  { value: 'yesterday', label: 'Today', sub: 'vs Yesterday' },
  { value: 'week', label: 'Today', sub: 'vs 7 days ago' },
  { value: '30days', label: 'Today', sub: 'vs 30 days ago' },
  {
    value: 'custom',
    label: 'Custom',
    sub: 'Select date',
  },
];

export const dashboardTimeFilterOptions = [
  { value: 'yesterday', label: 'Today vs Yesterday' },
  { value: 'week', label: 'Today vs 7 days ago' },
  { value: '30days', label: 'Today vs 30 days ago' },
  {
    value: 'custom',
    label: 'Custom ',
    sub: 'Select date',
  },
];

// inventory no data messages
export const inventoryNoDataMessage = {
  restockLimit: 'No Restock Limits Data Found.',
  stranded: 'No Stranded Inventory Data Found.',
  strandedRestock: 'No Stranded & Restock Inventory Data Found.',
};

export const restockLimitTypeInfo = [
  {
    label: 'Onhand: ',
    value: 'onhand',
    subLabel: 'Inventory on stock.',
    class: '',
  },
  {
    label: 'Open Shipments: ',
    value: 'openShipments',
    subLabel:
      'This is the capacity used by your shipments that have not been received by Amazon, including shipments that are in Working, Shipped, In transit, Delivered, Checked in or Receiving status.',
    class: '',
  },
  {
    label: 'Maximum Shipments: ',
    value: 'maximumShipments',
    subLabel:
      'This is the capacity available for you to send shipments to Amazon fulfillment centers.',
    class: '',
  },

  {
    label: 'Calculations: ',
    value: 'calculations',
    subLabel:
      'Utilization = (Onhand + Open Shipment)/(Onhand + Open Shipment + Max Shipment) x 100',
    class: 'text-italic',
  },
];

export const timeFrameAPiValue = (value) => {
  switch (value) {
    case 'yesterday':
      return 1;

    case 'week':
      return 7;

    case '30days':
      return 30;

    default:
      return 1;
  }
};

export const dateOptionsWithYearmm = [
  { value: 'week', label: 'Current 7 days', sub: 'vs Previous 7 days' },
  { value: 'month', label: 'Current Month', sub: 'vs Previous month' },
  { value: '30days', label: 'Current 30 Days', sub: 'vs Previous 30 days' },
  { value: 'year', label: 'Year to Date', sub: 'vs Previous year' },
  {
    value: 'custom',
    label: 'Custom Range',
    sub: 'Select start and end dates',
  },
];

export const defaultRestockInventoryOrderBy = {
  utilization: true,
  max_shipment: false,
  open_shipment: false,
  on_hand: false,
};

export const defaultStrandedInventoryOrderBy = {
  available: false,
  change: false,
  percent_change: true,
  understock: false,
  overstock: false,
  stranded: false,
  company_name: true,
};

export const defaultTimeFrameForInventory = {
  value: '30days',
  label: 'Today vs 30 days ago',
};

export const storageTypeOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Standard Size Storage',
    value: 'standard',
  },
  {
    label: 'Oversize Storage',
    value: 'oversize',
  },
  {
    label: 'Apparel Storage',
    value: 'apparel',
  },
  {
    label: 'Footwear Storage',
    value: 'footwear',
  },

  {
    label: 'Extra Large Storage',
    value: 'extra_large',
  },

  {
    label: 'Flammable Storage',
    value: 'flammable',
  },

  {
    label: 'Aerosol Storage',
    value: 'aerosol',
  },
];

export const restockLimitTableheader = [
  {
    key: 'customer',
    label: 'Customer',
    width: '28%',
  },
  {
    key: 'utilization',
    label: 'Utilization',
    width: '18%',
  },
  {
    key: 'max_shipment',
    label: 'Max shipment',
    width: '18%',
  },
  {
    key: 'open_shipment',
    label: 'Open shipment',
    width: '18%',
  },
  {
    key: 'on_hand',
    label: 'Onhand',
    width: '18%',
  },
];

export const restockInventoryTableheader = [
  {
    key: 'customer',
    label: 'Customer',
    width: '40%',
  },
  {
    key: 'understocked',
    label: 'Understocked ASINs',
    width: '20%',
  },
  {
    key: 'overstocked',
    label: 'Overstocked ASINs',
    width: '20%',
  },
  {
    key: 'available',
    label: 'Stranded ASINs',
    width: '20%',
  },
];

export const strandedInventoryTableheader = [
  {
    key: 'customer',
    label: 'Customer',
    width: '34%',
  },
  {
    key: 'available',
    label: 'Number of Stranded SKUs',
    width: '26%',
  },
  {
    key: 'sku_change',
    label: 'Change',
    width: '20%',
  },
  {
    key: 'percent_change',
    label: '% Change',
    width: '20%',
  },
];

export const underStockTableheader = [
  {
    key: 'merchant_sku',
    label: 'SKU',
    width: '160px',
    sort: false,
  },
  {
    key: 'asin',
    label: 'ASIN',
    width: '120px',
    sort: false,
  },

  {
    key: 'product_name',
    label: 'Product Name',
    width: '140px',
    sort: false,
  },
  {
    key: 'sales_last_30_days',
    label: `Sales \n(last 30 days)`,
    width: '150px',
    sort: true,
  },
  {
    key: 'units_sold_last_30_days',
    label: `Units Sold\n(last 30 days)`,
    width: '130px',
    sort: true,
  },
  {
    key: 'total_days_of_supply',
    label: `Total days of\nSupply`,
    width: '130px',
    sort: true,
  },
  {
    key: 'available_in_stock',
    label: `Available in Stock`,
    width: '130px',
    sort: true,
  },
  {
    key: 'poos_date',
    label: `Potential OOS Date`,
    width: '150px',
    sort: true,
  },
  {
    key: 'recommended_replenishment_quantity',
    label: `Recommended Replenishment\nQuantity`,
    width: '150px',
    sort: true,
  },
  {
    key: 'recommended_ship_date',
    label: `Recommended\nShip Date`,
    width: '150px',
    sort: true,
  },
  {
    label: 'Actions',
    key: 'action',
    width: '100px',
    sort: false,
    tooltip: false,
  },
];

export const OverStockTableheader = [
  {
    key: 'merchant_sku',
    label: 'SKU',
    width: '170px',
    sort: false,
  },
  {
    key: 'asin',
    label: 'ASIN',
    width: '170px',
    sort: false,
  },

  {
    key: 'product_name',
    label: 'Product Name',
    width: '180px',
    sort: false,
  },
  {
    key: 'sales_last_30_days',
    label: `Sales \n(last 30 days)`,
    width: '160px',
    sort: true,
  },
  {
    key: 'units_sold_last_30_days',
    label: `Units Sold\n(last 30 days)`,
    width: '160px',
    sort: true,
  },

  {
    key: 'total_days_of_supply',
    label: `Total days of Supply`,
    width: '160px',
    sort: true,
  },
  {
    label: 'Actions',
    key: 'action',
    width: '100px',
    sort: false,
    tooltip: false,
  },
];

export const stockedTooltip = {
  recommended_ship_date: 'This is Amazon’s recommended date',
  recommended_replenishment_quantity: 'This is Amazon’s recommended quantity',
};

export const estimatedLostSalesTabsConstants = [
  { label: 'Inactive', value: 'inactive' },
  { label: 'Stranded', value: 'stranded' },
  { label: 'Archive', value: 'archive' },
];

export const estimatedLostSalesDateFilterOptions = [
  { label: 'Past 7 days', value: '7days' },
  { label: 'Past 30 days', value: '30days' },
  { label: 'Past 3 months', value: '3months' },
  { label: 'Past 6 months', value: '6months' },
  { label: 'Past 12 months', value: '12months' },
];
export const estimatedLostSalesStatusFilterOptions = [
  { label: 'All Status', value: '' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Stranded', value: 'stranded' },
];

export const estimatedLostSalesTableHeaderConstants = {
  inactiveTableHeader: [
    {
      label: 'ASIN',
      value: 'asin',
      width: '140px',
      sorting: false,
      tooltip: false,
    },
    {
      label: 'Product Name',
      value: 'product_name',
      width: '150px',
      sorting: false,
      tooltip: false,
    },

    {
      label: 'Total Sales',
      value: 'total_sales',
      width: '170px',
      sorting: true,
      tooltip: true,
      tooltipMessage: 'Amount shown has 2 days lag',
    },
    {
      label: 'Avg. Daily Sales',
      value: 'average_daily_sales',
      width: '170px',
      sorting: true,
      tooltip: false,
    },
    {
      label: 'Unsold Since',
      value: 'last_sold_date',
      width: '170px',
      sorting: true,
      tooltip: false,
    },

    {
      label: 'Est Lost Sales',
      value: 'estimated_lost_sales',
      width: '170px',
      sorting: true,
      tooltip: false,
    },
    {
      label: 'Actions',
      value: 'actions',
      width: '170px',
      sorting: false,
      tooltip: false,
    },
  ],
  strandedTableHeader: [
    {
      label: 'ASIN',
      value: 'asin',
      width: '150px',
      sorting: false,
      tooltip: false,
    },
    {
      label: 'Product Name',
      value: 'product_name',
      width: '150px',
      sorting: false,
      tooltip: false,
    },
    {
      label: 'Reason',
      value: 'reason',
      width: '165px',
      sorting: false,
      tooltip: false,
    },
    {
      label: 'Total Sales',
      value: 'total_sales',
      width: '165px',
      sorting: true,
      tooltip: true,
      tooltipMessage: 'Amount shown has 2 days lag',
    },
    {
      label: 'Avg. Daily Sales',
      value: 'average_daily_sales',
      width: '165px',
      sorting: true,
      tooltip: false,
    },

    {
      label: 'Stranded Since',
      value: 'stranded_since',
      width: '165px',
      sorting: true,
      tooltip: false,
    },

    {
      label: 'Est Lost Sales',
      value: 'estimated_lost_sales',
      width: '165px',
      sorting: true,
      tooltip: false,
    },
    {
      label: 'Actions',
      value: 'actions',
      width: '165px',
      sorting: false,
      tooltip: false,
    },
  ],
  archiveTableHeader: [
    {
      label: 'ASIN',
      value: 'asin',
      width: '160px',
      sorting: false,
      tooltip: false,
    },
    {
      label: 'Product Name',
      value: 'product_name',
      width: '160px',
      sorting: false,
      tooltip: false,
    },

    {
      label: 'Status',
      value: 'status',
      width: '185px',
      sorting: true,
      tooltip: false,
    },

    {
      label: 'Actions',
      value: 'actions',
      width: '185px',
      sorting: false,
      tooltip: false,
    },
  ],
};

export const longTermStorageFeesTableHeaderConstants = [
  {
    label: 'SKU',
    value: 'sku',
    width: '130px',
    sorting: false,
    tooltip: false,
  },
  {
    label: 'ASIN',
    value: 'asin',
    width: '130px',
    sorting: false,
    tooltip: false,
  },
  {
    label: 'Product Name',
    value: 'product_name',
    width: '130px',
    sorting: false,
    tooltip: false,
  },
  {
    label: 'Qty Charged',
    value: 'quantity_charged',
    width: '110px',
    sorting: true,
    tooltip: false,
  },
  {
    label: 'Recurring Since',
    value: 'monthly_number',
    width: '150px',
    sorting: true,
    tooltip: false,
  },
  {
    label: 'Total Storage Fees',
    value: 'total_storage_fee',
    width: '170px',
    sorting: true,
    tooltip: false,
  },
  {
    label: 'Removal fees',
    value: 'removal_fee',
    width: '150px',
    sorting: true,
    tooltip: false,
  },
  {
    label: 'Actions',
    value: 'actions',
    width: '100px',
    sorting: false,
    tooltip: false,
  },
];

export const inventoryManagementInfoIconConstants = [
  {
    id: 'restock',
    message: 'Data is updated daily at 10 am MST',
  },
  {
    id: 'understocked_asins',
    message: 'Data is updated daily at 10 am MST',
  },
  {
    id: 'overstocked_asins',
    message: 'Data is updated daily at 10 am MST',
  },
  {
    id: 'estimated_lost_sales_table',
    message: 'Data is updated daily at 10 am MST',
  },
  {
    id: 'long_term_storage_fees_table',
    message:
      'Data is updated every 16-17th of the current month to pull previous month’s report',
  },
  {
    id: 'stranded_and_restock_table',
    message: 'Data is updated daily at 10 am MST',
  },
  {
    id: 'restock_limits_table',
    message: 'Data is updated daily at 10 am MST',
  },
];

export const storageTypeConstants = [
  { key: 'standard', label: 'Standard Size' },
  { key: 'oversize', label: 'Over Size' },
  { key: 'apparel', label: 'Apparel' },
  { key: 'footwear', label: 'Footwear' },
  { key: 'extra_large', label: 'Extra Large' },
  { key: 'flammable', label: 'Flammable' },
  { key: 'aerosol', label: 'Aerosol' },
];

export const restockLimitsColorCodes = {
  onhand: '#8c54ff',
  openShipment: '#c5a9ff',
  maximumShipment: '#e8ddff',

  previousOnhand: '#f7c137',
  previousOpenShipment: '#fbe09b',
  previousMaximumShipment: '#fdf3d7',
  current: '#8c54ff',
  previous: '#f7c137',
  tooltipBackground: '#2e384d',
  tooltipText: '#f4f6fc',
};

export const storageSubTypes = [
  {
    key: 'onhand',
    label: 'Onhand',
    bgColor: restockLimitsColorCodes.onhand,
    isStacked: true,

    previousKey: 'previousOnhand',
    previousLabel: 'Previous Onhand',
    previousBgColor: restockLimitsColorCodes.previousOnhand,
    previousIsStacked: false,
    previousDx: true,
  },
  {
    key: 'openShipment',
    label: 'Open Shipment',
    bgColor: restockLimitsColorCodes.openShipment,
    isStacked: true,

    previousKey: 'previousOpenShipment',
    previousLabel: 'Previous Open Shipment',
    previousBgColor: restockLimitsColorCodes.previousOpenShipment,
    previousIsStacked: true,
    previousDx: true,
  },
  {
    key: 'maximumShipment',
    label: 'Maximum Shipment',
    bgColor: restockLimitsColorCodes.maximumShipment,
    isStacked: true,

    previousKey: 'previousMaximumShipment',
    previousLabel: 'Previous Maximum Shipment',
    previousBgColor: restockLimitsColorCodes.previousMaximumShipment,
    previousIsStacked: true,
    previousDx: true,
  },
];
