/* eslint-disable no-lonely-if */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import { shape, string, func, instanceOf } from 'prop-types';

import { CheckBox, ErrorMsg, InputFormField } from '../../../../common';

export default function MonthlyDSPBudgetAllocation({
  item,
  formData,
  type,
  selectedCurrency,
  setSectionError,
  showError,
  setShowError,
  updatedFormData,
  setUpdatedFormData,
  runValidateBudget,
  showFooter,
  setFormData,
  dspMarketpalcesError,
  setDspMarketpalcesError,
  displayMarketplaceName,
  firstMonthDate,
  secondMonthDate,
  thirdMonthDate,
  returnDspMonthlyDates,
}) {
  const budgetType = type === 'monthly' ? 'Monthly' : 'One time';
  const formattedFirstDateForBE = !formData?.changed_dsp_start_date
    ? dayjs(firstMonthDate).format('YYYY-MM-DD')
    : dayjs(returnDspMonthlyDates(new Date(firstMonthDate), 1)).format(
        'YYYY-MM-DD',
      );

  const formattedSecondDateForBE = !formData?.changed_dsp_start_date
    ? dayjs(secondMonthDate).format('YYYY-MM-DD')
    : dayjs(returnDspMonthlyDates(new Date(secondMonthDate), 1)).format(
        'YYYY-MM-DD',
      );
  const formattedThirdDateForBE = !formData?.changed_dsp_start_date
    ? dayjs(thirdMonthDate).format('YYYY-MM-DD')
    : dayjs(returnDspMonthlyDates(new Date(thirdMonthDate), 1)).format(
        'YYYY-MM-DD',
      );

  const monthsList = [
    {
      key: 'monthly_budget',
      label: !formData?.changed_dsp_start_date
        ? dayjs(firstMonthDate).format('MMMM YYYY')
        : returnDspMonthlyDates(new Date(firstMonthDate), 1),
      formattedDateForBE: !formData?.changed_dsp_start_date
        ? dayjs(firstMonthDate).format('YYYY-MM-DD')
        : dayjs(returnDspMonthlyDates(new Date(firstMonthDate), 1)).format(
            'YYYY-MM-DD',
          ),
      type: 'number-currency',
      placeholder: `Enter ${dayjs(
        !formData?.changed_dsp_start_date
          ? dayjs(firstMonthDate).format('MMMM YYYY')
          : returnDspMonthlyDates(new Date(firstMonthDate), 1),
      ).format('MMMM')}`,
      isMandatory: true,
      part: 'dsp',
      isDynamicMonthlyBudget: true,
    },
    {
      key: 'monthly_budget_second',
      label: !formData?.changed_dsp_start_date
        ? dayjs(secondMonthDate).format('MMMM YYYY')
        : returnDspMonthlyDates(new Date(secondMonthDate), 1),
      formattedDateForBE: !formData?.changed_dsp_start_date
        ? dayjs(secondMonthDate).format('YYYY-MM-DD')
        : dayjs(returnDspMonthlyDates(new Date(secondMonthDate), 1)).format(
            'YYYY-MM-DD',
          ),
      type: 'number-currency',
      placeholder: `Enter ${dayjs(
        !formData?.changed_dsp_start_date
          ? dayjs(secondMonthDate).format('MMMM YYYY')
          : returnDspMonthlyDates(new Date(secondMonthDate), 1),
      ).format('MMMM')}`,
      isMandatory: true,
      part: 'dsp',
      isDynamicMonthlyBudget: true,
    },
    {
      key: 'monthly_budget_third',
      label: !formData?.changed_dsp_start_date
        ? dayjs(thirdMonthDate).format('MMMM YYYY')
        : returnDspMonthlyDates(new Date(thirdMonthDate), 1),
      formattedDateForBE: !formData?.changed_dsp_start_date
        ? dayjs(thirdMonthDate).format('YYYY-MM-DD')
        : dayjs(returnDspMonthlyDates(new Date(thirdMonthDate), 1)).format(
            'YYYY-MM-DD',
          ),
      type: 'number-currency',
      placeholder: `Enter ${dayjs(
        !formData?.changed_dsp_start_date
          ? dayjs(thirdMonthDate).format('MMMM YYYY')
          : returnDspMonthlyDates(new Date(thirdMonthDate), 1),
      ).format('MMMM')}`,
      isMandatory: true,
      part: 'dsp',
      isDynamicMonthlyBudget: true,
    },
  ];

  useEffect(() => {
    if (formData?.dsp_marketplaces?.length) {
      for (const market of formData?.dsp_marketplaces) {
        market.dsp_fee = formData?.dsp_fee || null;
        market.dsp_fee_second = formData?.is_dynamic_dsp_fee
          ? formData?.dsp_fee_second
          : formData?.dsp_fee || null;
        market.dsp_fee_third = formData?.is_dynamic_dsp_fee
          ? formData?.dsp_fee_third
          : formData?.dsp_fee || null;

        if (formData?.dsp_marketplaces?.length === 1) {
          market.monthly_budget = formData?.is_dynamic_dsp_fee
            ? formData?.dsp_fee
            : formData?.dsp_fee;

          market.monthly_budget_second = formData?.is_dynamic_dsp_fee
            ? formData?.dsp_fee_second
            : formData?.dsp_fee;

          market.monthly_budget_third = formData?.is_dynamic_dsp_fee
            ? formData?.dsp_fee_third
            : formData?.dsp_fee;
        } else {
          let budgetValue = '';
          let firstMonthDistributedValue = '';
          let secondMonthDistributedValue = '';
          let thirdMonthDistributedValue = '';

          if (formData?.is_dynamic_dsp_fee) {
            firstMonthDistributedValue = market?.monthly_budget
              ? Number(market?.monthly_budget)
              : formData?.dsp_fee / formData?.dsp_marketplaces?.length;
            secondMonthDistributedValue = market?.monthly_budget_second
              ? Number(market?.monthly_budget_second)
              : formData?.dsp_fee_second / formData?.dsp_marketplaces?.length;

            thirdMonthDistributedValue = market?.monthly_budget_third
              ? Number(market?.monthly_budget_third)
              : formData?.dsp_fee_third / formData?.dsp_marketplaces?.length;
          } else {
            budgetValue =
              formData?.dsp_fee / formData?.dsp_marketplaces?.length;
            market.monthly_budget = budgetValue
              ?.toFixed(2)
              ?.replaceAll('.00', '');
          }
          market.monthly_budget = formData?.is_dynamic_dsp_fee
            ? firstMonthDistributedValue?.toFixed(2)?.replaceAll('.00', '')
            : budgetValue?.toFixed(2)?.replaceAll('.00', '');

          market.monthly_budget_second = formData?.is_dynamic_dsp_fee
            ? secondMonthDistributedValue?.toFixed(2)?.replaceAll('.00', '')
            : budgetValue?.toFixed(2)?.replaceAll('.00', '');

          market.monthly_budget_third = formData?.is_dynamic_dsp_fee
            ? thirdMonthDistributedValue?.toFixed(2)?.replaceAll('.00', '')
            : budgetValue?.toFixed(2)?.replaceAll('.00', '');
        }

        market.monthly_budget_date = formattedFirstDateForBE;

        market.monthly_budget_second_date = formattedSecondDateForBE;

        market.monthly_budget_third_date = formattedThirdDateForBE;

        market.dsp_one_time_fee = formData?.dsp_one_time_fee || null;
        market.monthly_budget_distribution_equally =
          formData?.monthly_budget_distribution_equally || false;
        market.one_time_budget_distribution_equally =
          formData?.one_time_budget_distribution_equally || false;
        market.changed_dsp_start_date =
          formData.changed_dsp_start_date || false;
      }

      setShowError((prevState) => ({
        ...prevState,
        firstBudgetAllocationError: false,
        secondBudgetAllocationError: false,
        thirdBudgetAllocationError: false,
      }));
    }
  }, [
    formData.dsp_marketplaces,
    formData.is_dynamic_dsp_fee,
    formData.changed_dsp_start_date,
    formData.monthly_budget_distribution_equally,
    formData.dsp_fee,
    formData.dsp_fee_second,
    formData.dsp_fee_third,
  ]);

  const getDspNumber = (section) => {
    if (section.dsp === 0) return 1;
    return section.dsp;
  };

  const getErrorCount = () => {
    if (
      showError[`${type}_length`] ||
      showError?.firstBudgetAllocationError ||
      showError?.secondBudgetAllocationError ||
      showError?.thirdBudgetAllocationError ||
      showError?.oneTime
    ) {
      setSectionError((prevState) => ({
        ...prevState,
        dsp: prevState.dsp + 1,
      }));
    }
    if (
      !showError[`${type}_length`] ||
      (!showError?.firstBudgetAllocationError &&
        !showError?.secondBudgetAllocationError &&
        !showError?.thirdBudgetAllocationError) ||
      !showError?.oneTime
    ) {
      setSectionError((prevState) => ({
        ...prevState,
        dsp: getDspNumber(prevState) - 1,
      }));
    }
  };

  const validateBudget = (key) => {
    const monthlyBudgetFirst = [];
    const monthlyBudgetSecond = [];
    const monthlyBudgetThird = [];

    let totalBudgetFirst = 0;
    let totalBudgetSecond = 0;
    let totalBudgetThird = 0;

    if (formData?.dsp_marketplaces?.length) {
      for (const market of formData?.dsp_marketplaces) {
        market.monthly_budget =
          typeof market?.monthly_budget === 'string'
            ? market?.monthly_budget?.replaceAll(',', '')
            : market?.monthly_budget;
        monthlyBudgetFirst.push(Number(market?.monthly_budget) || 0);

        market.monthly_budget_second =
          typeof market?.monthly_budget_second === 'string'
            ? market?.monthly_budget_second?.replaceAll(',', '')
            : market?.monthly_budget_second;
        monthlyBudgetSecond.push(Number(market?.monthly_budget_second || 0));

        market.monthly_budget_third =
          typeof market?.monthly_budget_third === 'string'
            ? market?.monthly_budget_third?.replaceAll(',', '')
            : market?.monthly_budget_third;
        monthlyBudgetThird.push(Number(market?.monthly_budget_third || 0));
      }

      totalBudgetFirst = monthlyBudgetFirst.reduce(
        (temp1, temp2) => temp1 + temp2,
      );
      totalBudgetSecond = monthlyBudgetSecond.reduce(
        (temp1, temp2) => temp1 + temp2,
      );
      totalBudgetThird = monthlyBudgetThird.reduce(
        (temp1, temp2) => temp1 + temp2,
      );
      // }

      if (
        formData?.dsp_marketplaces?.filter((op) => !op?.monthly_budget)
          ?.length &&
        formData?.dsp_marketplaces?.filter((op) =>
          formData?.is_dynamic_dsp_fee
            ? !op?.monthly_budget_second?.length
            : !op?.monthly_budget?.length,
        ) &&
        formData?.dsp_marketplaces?.filter((op) =>
          formData?.is_dynamic_dsp_fee
            ? !op?.monthly_budget_third?.length
            : !op?.monthly_budget?.length,
        )
      ) {
        setShowError((prevState) => ({
          ...prevState,
          firstBudgetAllocationError: false,
          secondBudgetAllocationError: false,
          thirdBudgetAllocationError: false,
        }));
      } else {
        if (totalBudgetFirst !== Number(formData?.dsp_fee || 0)) {
          // check the total budget matches the allocated budget
          setShowError((prevState) => ({
            ...prevState,
            firstBudgetAllocationError: true,
          }));
          getErrorCount();
        } else {
          setShowError((prevState) => ({
            ...prevState,
            firstBudgetAllocationError: false,
          }));
          getErrorCount();
        }

        if (
          formData?.is_dynamic_dsp_fee
            ? totalBudgetSecond !== Number(formData?.dsp_fee_second || 0)
            : totalBudgetSecond !== Number(formData?.dsp_fee || 0)
        ) {
          // check the total budget matches the allocated budget
          setShowError((prevState) => ({
            ...prevState,
            secondBudgetAllocationError: true,
          }));
          getErrorCount();
        } else {
          setShowError((prevState) => ({
            ...prevState,
            secondBudgetAllocationError: false,
          }));
          getErrorCount();
        }

        if (
          formData?.is_dynamic_dsp_fee
            ? totalBudgetThird !== Number(formData?.dsp_fee_third || 0)
            : totalBudgetThird !== Number(formData?.dsp_fee || 0)
        ) {
          // check the total budget matches the allocated budget
          setShowError((prevState) => ({
            ...prevState,
            thirdBudgetAllocationError: true,
          }));
          getErrorCount();
        } else {
          setShowError((prevState) => ({
            ...prevState,
            thirdBudgetAllocationError: false,
          }));
          getErrorCount();
        }
      }
    }

    // check the allocation bugdet lengths
    if (formData?.dsp_marketplaces?.filter((op) => !op?.[key])?.length !== 0) {
      setShowError((prevState) => ({
        ...prevState,
        [`${type}_length`]: true,
      }));
      getErrorCount();
    } else {
      setShowError((prevState) => ({
        ...prevState,
        [`${type}_length`]: false,
      }));
      getErrorCount();
    }
  };

  const budgetTypeValidation = () => {
    if (type === 'monthly') {
      validateBudget('monthly_budget');
    }
  };

  useEffect(() => {
    budgetTypeValidation();
    getErrorCount();
  }, [
    runValidateBudget,
    formData.dsp_marketplaces,
    formData.dsp_one_time_fee,
    formData.changed_dsp_start_date,
    formData.dsp_fee,
    formData.dsp_fee_second,
    formData.dsp_fee_third,
    formData.is_dynamic_dsp_fee,
    formData.monthly_budget_distribution_equally,
  ]);

  const convertMarketplacesToNumber = (key, updatedDspMarketplace) => {
    return updatedDspMarketplace.filter((market) => {
      market[key] =
        typeof market[key] === 'string'
          ? market[key]?.replaceAll(',', '')
          : market[key];
      return Number(market[key]);
    });
  };

  const handleChange = (field, key, value, name, monthData, marketplace) => {
    showFooter(true);
    if (dspMarketpalcesError && Object.keys(dspMarketpalcesError).length)
      setDspMarketpalcesError({ ...dspMarketpalcesError, [key]: '' });
    const updatedDspMarketplace = [...formData?.dsp_marketplaces];
    if (field === 'budget') {
      for (const market of formData?.dsp_marketplaces) {
        if (market.name === marketplace) {
          market[name] = value?.replace(/,/g, '')?.replace(/^0+/, '');
          if (monthData?.key === 'monthly_budget') {
            market.monthly_budget_date = monthData?.formattedDateForBE;
          }
          if (monthData?.key === 'monthly_budget_second') {
            market.monthly_budget_second_date = monthData?.formattedDateForBE;
          }
          if (monthData?.key === 'monthly_budget_third') {
            market.monthly_budget_third_date = monthData?.formattedDateForBE;
          }
        }
        market.dsp_fee = formData?.dsp_fee || null;
        market.dsp_fee_second = formData?.is_dynamic_dsp_fee
          ? formData?.dsp_fee_second
          : formData?.dsp_fee || null;
        market.dsp_fee_third = formData?.is_dynamic_dsp_fee
          ? formData?.dsp_fee_third
          : formData?.dsp_fee || null;
        market.dsp_one_time_fee = formData?.dsp_one_time_fee || null;
        market.monthly_budget_distribution_equally =
          formData?.monthly_budget_distribution_equally || false;
        market.one_time_budget_distribution_equally =
          formData?.one_time_budget_distribution_equally || false;
        market.changed_dsp_start_date =
          formData.changed_dsp_start_date || false;
      }
      convertMarketplacesToNumber(key, updatedDspMarketplace);
      setUpdatedFormData({
        ...updatedFormData,
        dsp_marketplaces: {
          ...updatedFormData.dsp_marketplaces,
          create: updatedDspMarketplace,
        },
      });
    }
    if (field === 'checkbox') {
      let budgetValue = '';
      let firstMonthDistributedValue = '';
      let secondMonthDistributedValue = '';
      let thirdMonthDistributedValue = '';

      if (formData?.is_dynamic_dsp_fee) {
        firstMonthDistributedValue =
          formData?.dsp_fee / formData?.dsp_marketplaces?.length;
        secondMonthDistributedValue =
          formData?.dsp_fee_second / formData?.dsp_marketplaces?.length;

        thirdMonthDistributedValue =
          formData?.dsp_fee_third / formData?.dsp_marketplaces?.length;
      } else {
        budgetValue =
          formData?.[marketplace] / formData?.dsp_marketplaces?.length;
        for (const market of formData?.dsp_marketplaces) {
          market.monthly_budget = budgetValue
            ?.toFixed(2)
            ?.replaceAll('.00', '');
        }
      }

      for (const market of formData?.dsp_marketplaces) {
        market.monthly_budget = formData?.is_dynamic_dsp_fee
          ? firstMonthDistributedValue?.toFixed(2)?.replaceAll('.00', '')
          : budgetValue?.toFixed(2)?.replaceAll('.00', '');

        market.monthly_budget_second = formData?.is_dynamic_dsp_fee
          ? secondMonthDistributedValue?.toFixed(2)?.replaceAll('.00', '')
          : budgetValue?.toFixed(2)?.replaceAll('.00', '');

        market.monthly_budget_third = formData?.is_dynamic_dsp_fee
          ? thirdMonthDistributedValue?.toFixed(2)?.replaceAll('.00', '')
          : budgetValue?.toFixed(2)?.replaceAll('.00', '');

        market.monthly_budget_date = formattedFirstDateForBE;
        market.monthly_budget_second_date = formattedSecondDateForBE;
        market.monthly_budget_third_date = formattedThirdDateForBE;

        market.dsp_fee = formData?.dsp_fee;
        market.dsp_fee_second = formData?.dsp_fee_second;
        market.dsp_fee_third = formData?.dsp_fee_third;
      }
      convertMarketplacesToNumber(key, updatedDspMarketplace);
      setUpdatedFormData({
        ...updatedFormData,
        [key]: value,
        dsp_marketplaces: {
          ...updatedFormData.dsp_marketplaces,
          create: updatedDspMarketplace,
        },
      });
      setFormData({ ...formData, [key]: value });
    }
  };

  const generateInput = () => {
    return formData?.dsp_marketplaces?.map((field) => (
      <>
        <InputFormField className="mt-2">
          <label htmlFor={`${type}_${field.name}`}>
            {displayMarketplaceName(field.name, 'dsp', 'lhs')}
            {monthsList?.map((monthData) => {
              return (
                <InputFormField key={item.key}>
                  <div className="row">
                    <div className="col-4 pr-0 mt-3">
                      <label
                        className="quartely-threshold"
                        htmlFor="Monthly Retainer"
                      >
                        {monthData?.label}
                      </label>
                    </div>
                    <div className="col-8">
                      <div className="input-container">
                        <span className="input-icon">{selectedCurrency} </span>
                        <NumberFormat
                          name={`${monthData?.key}`}
                          key={`${monthData?.key}`}
                          className={`${
                            formData?.monthly_budget_distribution_equally
                              ? 'disabled'
                              : ''
                          } ${
                            (monthData?.key === 'monthly_budget' &&
                              showError?.firstBudgetAllocationError) ||
                            (monthData?.key === 'monthly_budget_second' &&
                              showError?.secondBudgetAllocationError) ||
                            (monthData?.key === 'monthly_budget_third' &&
                              showError?.thirdBudgetAllocationError) ||
                            showError[`${type}_length`]
                              ? 'form-control form-control-error modal-input-control'
                              : 'form-control modal-input-control'
                          }
                          `}
                          placeholder={monthData?.placeholder}
                          onChange={(event) => {
                            handleChange(
                              'budget',
                              type === 'monthly'
                                ? 'monthly_budget'
                                : 'one_time_budget',
                              event.target.value,
                              event.target.name,
                              monthData,
                              field.name,
                              event,
                            );
                            budgetTypeValidation();
                          }}
                          value={
                            formData?.dsp_marketplaces?.length === 1
                              ? type === 'monthly'
                                ? field?.[monthData?.key]
                                : formData?.dsp_one_time_fee
                              : type === 'monthly'
                              ? field?.[monthData?.key]
                              : field.one_time_budget
                          }
                          thousandSeparator
                          allowNegative={false}
                          disabled={formData?.dsp_marketplaces?.length === 1}
                          decimalScale={2}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            if (floatValue == null) {
                              return formattedValue === '';
                            }
                            return floatValue > 0;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </InputFormField>
              );
            })}
          </label>
        </InputFormField>
      </>
    ));
  };

  return (
    <>
      {!formData?.dsp_marketplaces?.length ? null : (
        <>
          <div className=" liner-titles spacing mt-3 mb-3" key={item.key}>
            {`${budgetType} Budget Allocation`}
          </div>
          <p>
            {`Allocate the ${budgetType} budget over the DSP marketplaces to create
        the DSP invoice accordingly.`}
          </p>
          {formData?.dsp_marketplaces?.length === 1 ? null : (
            <CheckBox>
              <label
                className="check-container customer-pannel"
                htmlFor={budgetType}
              >
                Distribute Budget Equally
                <input
                  type="checkbox"
                  name={budgetType}
                  id={budgetType}
                  onChange={(event) => {
                    handleChange(
                      'checkbox',
                      'monthly_budget_distribution_equally',
                      event.target.checked,
                      'checkbox-----',
                      {},
                      type === 'monthly' ? 'dsp_fee' : 'dsp_one_time_fee',
                      type === 'monthly' ? 'monthly_budget' : 'one_time_budget',
                    );
                    if (
                      dspMarketpalcesError &&
                      Object.keys(dspMarketpalcesError).length
                    )
                      setDspMarketpalcesError({
                        ...dspMarketpalcesError,
                        [type === 'monthly'
                          ? 'monthly_budget'
                          : 'one_time_budget']: '',
                      });
                  }}
                  defaultChecked={
                    formData?.[
                      type === 'monthly'
                        ? 'monthly_budget_distribution_equally'
                        : 'one_time_budget_distribution_equally'
                    ]
                  }
                  checked={
                    formData?.[
                      type === 'monthly'
                        ? 'monthly_budget_distribution_equally'
                        : 'one_time_budget_distribution_equally'
                    ]
                  }
                />
                <span className="checkmark" />
              </label>
            </CheckBox>
          )}

          <>
            {generateInput()}
            <ErrorMsg>
              {
                dspMarketpalcesError?.[
                  type === 'monthly' ? 'monthly_budget' : 'one_time_budget'
                ]
              }
            </ErrorMsg>
          </>

          {dspMarketpalcesError &&
          Object.keys(dspMarketpalcesError).length === 0 &&
          (showError?.firstBudgetAllocationError ||
            showError?.secondBudgetAllocationError ||
            showError?.thirdBudgetAllocationError) ? (
            <ErrorMsg>
              {`Please allocate marketplaces amount equal to the ${budgetType} budget amount.`}
            </ErrorMsg>
          ) : null}
        </>
      )}
    </>
  );
}

MonthlyDSPBudgetAllocation.defaultProps = {
  formData: {},
  item: {},
  type: 'monthly',
  selectedCurrency: '$ (USD)',
  setFormData: () => {},
  setSectionError: () => {},
  showError: {
    monthly: false,
    oneTime: false,
  },
  setShowError: () => {},
  updatedFormData: {},
  setUpdatedFormData: () => {},
  runValidateBudget: {},
  showFooter: () => {},
  dspMarketpalcesError: {},
  setDspMarketpalcesError: () => {},
  displayMarketplaceName: () => {},
  returnDspMonthlyDates: () => {},
};
MonthlyDSPBudgetAllocation.propTypes = {
  formData: shape({}),
  item: shape({}),
  type: string,
  selectedCurrency: string,
  setFormData: func,
  setSectionError: func,
  showError: shape({}),
  setShowError: func,
  updatedFormData: shape({}),
  setUpdatedFormData: func,
  runValidateBudget: shape({}),
  showFooter: func,
  dspMarketpalcesError: shape({}),
  setDspMarketpalcesError: func,
  displayMarketplaceName: func,
  firstMonthDate: instanceOf(Date).isRequired,
  secondMonthDate: instanceOf(Date).isRequired,
  thirdMonthDate: instanceOf(Date).isRequired,
  returnDspMonthlyDates: func,
};
