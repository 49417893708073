import styled from 'styled-components';
import Theme from '../../../../theme/Theme';

export const InventoryTableWrapper = styled.div`
  th.product-header {
    position: relative;
    img {
      position: absolute;
      top: 10px;
      transform: rotate(270deg);
    }
    .rotate {
      transform: rotate(90deg);
      top: 10px;
    }
  }
`;

export const InventoryStyle = styled.div`
  .card-staus-right {
    float: right;
  }

  .label-bold {
    font-weight: bold;
  }
  .sub-label-text {
    font-size: ${Theme.extraNormal};
    color: ${Theme.black};
  }
`;
