import dayjs from 'dayjs';

const BindSalesGoalsGraphResponse = (response) => {
  const finalData = [];
  // get a last date of the week
  const getOneDayBack = (reportDateTemp) => {
    const reportDateResultTemp = new Date(reportDateTemp);
    reportDateResultTemp.setDate(reportDateResultTemp.getDate() - 1);
    return reportDateResultTemp;
  };

  const nextReportDate = (indexTemp, graphDataArray, dateParam) => {
    return dayjs(
      indexTemp + 1 < graphDataArray?.length
        ? getOneDayBack(graphDataArray[indexTemp + 1]?.report_date)
        : response?.dates?.[
            dateParam === 'current' ? 'current_end_date' : 'prevous_end_date'
          ],
    ).format('MMM D YYYY');
  };

  if (response?.daily_facts?.current?.length) {
    response.daily_facts.current.forEach((item, index) => {
      const tempData = {};
      const currentReportDate = dayjs(item.report_date).format('MMM D YYYY');
      const adSpendTotal = item?.sponsored_ad_spend + item?.dsp_spends;
      const adSpendTotalGoals =
        item?.goal_sponsored_ad_spend + item?.goal_dsp_spends;
      tempData.date = currentReportDate;
      tempData.adSpendTotalCurrent = adSpendTotal;
      tempData.adSpendTotalGoals = adSpendTotalGoals;

      tempData.adSpendPpcCurrent = item?.sponsored_ad_spend;
      tempData.adSpendPpcGoals = item?.goal_sponsored_ad_spend;

      tempData.adSpendDspCurrent = item?.dsp_spends;
      tempData.adSpendDspGoals = item?.goal_dsp_spends;

      tempData.revenueTotalCurrent = item.revenue;
      tempData.revenueTotalGoals = item?.goal_revenue;

      tempData.revenuePpcCurrent = item.revenue_ppc;
      tempData.revenuePpcGoals = item?.goal_revenue;

      tempData.revenueDspCurrent = item.revenue_dsp;
      tempData.revenueDspGoals = item?.goal_revenue;

      tempData.tacosCurrent = item?.tacos;
      tempData.tacosGoals = item?.goal_tacos;

      tempData.trafficCurrent = item?.traffic;
      tempData.trafficGoals = item?.goal_traffic;

      tempData.conversionCurrent = item?.conversion;
      tempData.conversionGoals = item?.goal_conversion;

      tempData.endDate = nextReportDate(
        index,
        response?.daily_facts?.current,
        'previous',
      );

      tempData.adSpendTotalCurrentLabel =
        adSpendTotal !== null ? adSpendTotal?.toFixed(2) : '0.00';
      tempData.adSpendTotalGoalsLabel =
        adSpendTotalGoals !== null ? adSpendTotalGoals?.toFixed(2) : '0.00';
      tempData.adSpendPpcCurrentLabel =
        item?.sponsored_ad_spend !== null
          ? item?.sponsored_ad_spend?.toFixed(2)
          : '0.00';
      tempData.adSpendPpcGoalsLabel =
        item?.goal_sponsored_ad_spend !== null
          ? item?.goal_sponsored_ad_spend?.toFixed(2)
          : '0.00';

      tempData.adSpendDspCurrentLabel =
        item?.dsp_spends !== null ? item?.dsp_spends?.toFixed(2) : '0.00';
      tempData.adSpendDspGoalsLabel =
        item?.goal_dsp_spends !== null
          ? item?.goal_dsp_spends?.toFixed(2)
          : '0.00';

      tempData.revenueTotalCurrentLabel =
        item.revenue !== null ? item.revenue.toFixed(2) : '0.00';
      tempData.revenueTotalGoalsLabel =
        item?.goal_revenue !== null ? item?.goal_revenue?.toFixed(2) : '0.00';

      tempData.revenuePpcCurrentLabel =
        item.revenue !== null ? item.revenue.toFixed(2) : '0.00';
      tempData.revenuePpcGoalsLabel =
        item?.goal_revenue !== null ? item?.goal_revenue?.toFixed(2) : '0.00';

      tempData.revenueDspCurrentLabel =
        item.revenue !== null ? item.revenue.toFixed(2) : '0.00';
      tempData.revenueDspGoalsLabel =
        item?.goal_revenue !== null ? item?.goal_revenue?.toFixed(2) : '0.00';

      tempData.tacosCurrentLabel =
        item?.tacos !== null ? item.tacos.toFixed(2) : '0.00';
      tempData.tacosGoalsLabel =
        item?.goal_tacos !== null ? item?.goal_tacos?.toFixed(2) : '0.00';

      tempData.trafficCurrentLabel =
        item?.traffic !== null ? item.traffic.toFixed(2) : '0.00';
      tempData.trafficGoalsLabel =
        item?.goal_traffic !== null ? item?.goal_traffic?.toFixed(2) : '0.00';

      tempData.conversionCurrentLabel =
        item?.conversion !== null ? item.conversion.toFixed(2) : '0.00';
      tempData.conversionGoalsLabel =
        item?.goal_conversion !== null
          ? item?.goal_conversion?.toFixed(2)
          : '0.00';
      // to add the dotted line. we have to check null matrix and add the dummy number like 8

      tempData.revenueTotalDashLength = item.revenue === null ? 8 : null;
      tempData.adSpendTotalDashLength =
        item.sponsored_ad_spend === null ? 8 : null;
      finalData.push(tempData);
    });
  }
  return finalData;
};

export default BindSalesGoalsGraphResponse;
