import React from 'react';

import NumberFormat from 'react-number-format';
import { string, func, arrayOf, shape, number } from 'prop-types';

import { CheckBox, ErrorMsg, InputFormField } from '../../../../common';

function AdvertisingDashboards({
  advertisingDashboards,
  selectedCurrency,
  formData,
  setFormData,
  updatedFormData,
  setUpdatedFormData,
  monthlyAdBudget,
  details,
  contractAddendumDetails,
  showFooter,
  amcServiceError,
  setAmcServiceError,
  getAddedOrRemovedAMCServices,
  originalData,
}) {
  const handleAmcDashboard = (event) => {
    showFooter(true);
    const dataToBeSearchOn = contractAddendumDetails.notActive
      ? formData
      : details;

    const standardDashboardObj = dataToBeSearchOn?.amc_services?.find((item) =>
      item?.name.includes('Standard Dashboard'),
    )
      ? [
          dataToBeSearchOn?.amc_services?.find((item) =>
            item?.name.includes('Standard Dashboard'),
          ),
        ]
      : [
          {
            name: 'Standard Dashboard',
            fee: 0,
            contract: contractAddendumDetails.notActive ? null : details?.id,
            contract_addendum: contractAddendumDetails.notActive
              ? contractAddendumDetails?.id
              : null,
          },
        ];

    const advancedDashboardObj = dataToBeSearchOn?.amc_services?.find((item) =>
      item?.name.includes('Advanced Dashboard'),
    )
      ? [
          dataToBeSearchOn?.amc_services?.find((item) =>
            item?.name.includes('Advanced Dashboard'),
          ),
        ]
      : [
          {
            name: 'Advanced Dashboard',
            contract: contractAddendumDetails.notActive ? null : details?.id,
            contract_addendum: contractAddendumDetails.notActive
              ? contractAddendumDetails?.id
              : null,
            fee: monthlyAdBudget && Number(monthlyAdBudget) < 50000 ? 425 : 0,
          },
        ];

    const CustomizedDashboardObj = dataToBeSearchOn?.amc_services?.find(
      (item) => item?.name.includes('Customized Dashboard'),
    )
      ? [
          dataToBeSearchOn?.amc_services?.find((item) =>
            item?.name.includes('Customized Dashboard'),
          ),
        ]
      : [
          {
            name: 'Customized Dashboard',
            contract: contractAddendumDetails.notActive ? null : details?.id,
            contract_addendum: contractAddendumDetails.notActive
              ? contractAddendumDetails?.id
              : null,
          },
        ];

    const removedAMCServices = getAddedOrRemovedAMCServices(
      originalData?.amc_services,
      formData?.amc_services,
    );

    let list = [];
    if (contractAddendumDetails.notActive) {
      list = updatedFormData?.amc_services?.delete?.length
        ? updatedFormData?.amc_services?.delete
        : [];
    } else {
      list = removedAMCServices?.map((item) => item?.id);
    }

    let amcData = {
      create: formData?.amc_services ? [...formData?.amc_services] : [],
      delete: list?.length ? [...list] : [],
    };

    if (event?.target?.checked) {
      if (event?.target?.name === 'Standard Dashboard') {
        amcData = {
          ...amcData,
          create: formData?.amc_services?.length
            ? [...formData?.amc_services, ...standardDashboardObj]
            : [...standardDashboardObj],
        };
      }

      if (event?.target?.name === 'Advanced Dashboard') {
        if (monthlyAdBudget && Number(monthlyAdBudget) < 50000) {
          amcData = {
            ...amcData,
            create: formData?.amc_services?.length
              ? [...formData?.amc_services, ...advancedDashboardObj]
              : [...advancedDashboardObj],
          };
        }
        if (monthlyAdBudget && Number(monthlyAdBudget) >= 50000) {
          amcData = {
            ...amcData,
            create: formData?.amc_services?.length
              ? [...formData?.amc_services, ...advancedDashboardObj]
              : [...advancedDashboardObj],
          };
        }
      }
      if (event?.target?.name === 'Customized Dashboard') {
        if (monthlyAdBudget && Number(monthlyAdBudget) < 50000) {
          amcData = {
            ...amcData,
            create: formData?.amc_services?.length
              ? [...formData?.amc_services, ...CustomizedDashboardObj]
              : [...CustomizedDashboardObj],
          };
        }
        if (monthlyAdBudget && Number(monthlyAdBudget) >= 50000) {
          amcData = {
            ...amcData,
            create: formData?.amc_services?.length
              ? [...formData?.amc_services, ...CustomizedDashboardObj]
              : [...CustomizedDashboardObj],
          };
        }
      }
    } else {
      let remainingamcServices = [];
      const deletedServices = [];
      if (event?.target?.name === 'Standard Dashboard') {
        const serviceItem = dataToBeSearchOn?.amc_services?.find((item) =>
          item?.name.includes('Standard Dashboard'),
        );
        remainingamcServices = formData?.amc_services?.filter(
          (item) => item?.name !== 'Standard Dashboard',
        );
        if (serviceItem) {
          deletedServices.push(serviceItem?.id);
        }
      }
      if (event?.target?.name === 'Advanced Dashboard') {
        const serviceItem = dataToBeSearchOn?.amc_services?.find((item) =>
          item?.name.includes('Advanced Dashboard'),
        );

        remainingamcServices = formData?.amc_services?.filter(
          (item) => item?.name !== 'Advanced Dashboard',
        );
        if (serviceItem) {
          deletedServices.push(serviceItem?.id);
        }
      }
      if (event?.target?.name === 'Customized Dashboard') {
        const serviceItem = dataToBeSearchOn?.amc_services?.find((item) =>
          item?.name.includes('Customized Dashboard'),
        );

        remainingamcServices = formData?.amc_services?.filter(
          (item) => item?.name !== 'Customized Dashboard',
        );
        if (serviceItem) {
          deletedServices.push(serviceItem?.id);
        }
      }
      amcData = {
        ...amcData,
        create: [...remainingamcServices],
        delete: amcData?.delete?.length
          ? [...amcData?.delete, ...deletedServices]
          : [...deletedServices],
      };
    }

    setFormData({
      ...formData,
      amc_services: [...amcData?.create],
    });
    setUpdatedFormData({
      ...updatedFormData,
      amc_services: { ...amcData },
    });
  };

  const handleChange = (event) => {
    showFooter(true);

    setAmcServiceError({});
    const customizedDashboardData = formData?.amc_services?.filter(
      (item) => item?.name === 'Customized Dashboard',
    );
    customizedDashboardData[0].fee = event?.target?.value?.replace(/,/g, '');

    const remainingamcServices = formData?.amc_services?.filter(
      (item) => item?.name !== 'Customized Dashboard',
    );

    setFormData({
      ...formData,
      amc_services: [
        ...remainingamcServices,
        { ...customizedDashboardData[0] },
      ],
    });
    setUpdatedFormData({
      ...updatedFormData,
      amc_services: {
        create: [...remainingamcServices, { ...customizedDashboardData[0] }],
        delete: updatedFormData?.amc_services?.delete
          ? [...updatedFormData?.amc_services?.delete]
          : [],
      },
    });
  };
  return (
    <>
      {advertisingDashboards?.length
        ? advertisingDashboards?.map((dashboard) => {
            return (
              <>
                <div
                  className={`col-12 ${
                    formData?.is_amc_management ? '' : 'disabled'
                  }`}
                >
                  <div className="row">
                    <div className="col-7">
                      <CheckBox style={{ padding: `0px 0px 0px 23px` }}>
                        <label
                          className="check-container customer-pannel"
                          htmlFor={`${dashboard?.label}`}
                        >
                          {dashboard?.label}
                          <input
                            type="checkbox"
                            name={`${dashboard?.label}`}
                            id={`${dashboard?.label}`}
                            onClick={(event) => {
                              handleAmcDashboard(event);
                            }}
                            checked={
                              formData?.amc_services?.length &&
                              formData?.amc_services?.find((item) =>
                                item?.name?.includes(dashboard?.label),
                              )?.name === dashboard?.label
                            }
                          />
                          <span className="checkmark" />
                        </label>
                      </CheckBox>
                    </div>
                    <div className="col-5">
                      {(dashboard?.label === 'Customized Dashboard' &&
                        formData?.amc_services?.find(
                          (item) => item?.name === 'Customized Dashboard',
                        )) ||
                      (dashboard?.label === 'Advanced Dashboard' &&
                        formData?.amc_services?.find(
                          (item) => item?.name === 'Advanced Dashboard',
                        ) &&
                        monthlyAdBudget < 50000) ? (
                        <>
                          <InputFormField>
                            <div className="input-container">
                              <span className="input-icon amc-dashboard">
                                {selectedCurrency || '$'}
                              </span>

                              <NumberFormat
                                // style={{ height: '20px' }}
                                name={dashboard?.name}
                                className={` amc-dashboard ${
                                  dashboard?.label === 'Advanced Dashboard'
                                    ? 'disabled'
                                    : ''
                                } form-control modal-input-control  ${
                                  dashboard?.label === 'Customized Dashboard' &&
                                  amcServiceError?.fee
                                    ? 'form-control-error'
                                    : ''
                                }`}
                                placeholder="Enter Amount"
                                thousandSeparator
                                defaultValue={
                                  dashboard?.label === 'Advanced Dashboard'
                                    ? monthlyAdBudget &&
                                      Number(monthlyAdBudget) < 50000
                                      ? '425'
                                      : '0'
                                    : formData?.amc_services?.find(
                                        (item) =>
                                          item?.name === 'Customized Dashboard',
                                      )
                                    ? formData?.amc_services?.filter(
                                        (item) =>
                                          item?.name === 'Customized Dashboard',
                                      )?.[0]?.fee
                                    : ''
                                }
                                id={dashboard?.label}
                                onChange={(event) => handleChange(event)}
                                isAllowed={(values) => {
                                  const { formattedValue, floatValue } = values;
                                  if (floatValue == null) {
                                    return formattedValue === '';
                                  }
                                  return floatValue > 0;
                                }}
                                allowNegative={false}
                              />
                            </div>
                          </InputFormField>

                          <div className="col-12">
                            {dashboard?.label === 'Customized Dashboard' &&
                            amcServiceError?.fee?.length ? (
                              <ErrorMsg className="mb-3">
                                {amcServiceError?.fee}
                              </ErrorMsg>
                            ) : (
                              ''
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            );
          })
        : null}

      {/* {subTabOptions?.length &&
        showSubTabs?.isOpen &&
        showSubTabs?.account_type === accountType
          ? [displaySubTabs()]
          : ''} */}
    </>
  );
}

export default AdvertisingDashboards;

AdvertisingDashboards.defaultProps = {
  advertisingDashboards: [],
  formData: {},
  setFormData: () => {},
  updatedFormData: {},
  setUpdatedFormData: () => {},
  selectedCurrency: '',
  monthlyAdBudget: null,
  contractAddendumDetails: {},
  details: {},
  showFooter: () => {},
  amcServiceError: {},
  setAmcServiceError: () => {},
  getAddedOrRemovedAMCServices: () => {},
  originalData: {},
};

AdvertisingDashboards.propTypes = {
  advertisingDashboards: arrayOf(shape({})),
  formData: shape({}),
  setFormData: func,
  updatedFormData: shape({}),
  setUpdatedFormData: func,
  selectedCurrency: string,
  monthlyAdBudget: number,
  contractAddendumDetails: shape({}),
  details: shape({}),
  showFooter: func,
  amcServiceError: shape({}),
  setAmcServiceError: func,
  getAddedOrRemovedAMCServices: func,
  originalData: shape({}),
};
