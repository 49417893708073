import React from 'react';

import Modal from 'react-modal';
import { Calendar } from 'react-date-range';
import { enGB } from 'react-date-range/src/locale';
import {
  bool,
  func,
  shape,
  string,
  instanceOf,
  arrayOf,
  oneOfType,
} from 'prop-types';

import ModalBox from './ModalBox';
import Button from './Button';
import Theme from '../theme/Theme';
import { CloseIcon } from '../theme/images';

const todaysDate = new Date();
todaysDate.setDate(todaysDate.getDate() - 2);

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '420px ',
    width: '100% ',
    minHeight: '390px',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const CustomSingleDateModal = ({
  id,
  isOpen,
  style,
  onClick,
  onChange,
  onApply,
  selectedDate,
  title,
  currentDate,
  color,
  minDate,
  ...props
}) => {
  return (
    <Modal
      id={id}
      isOpen={isOpen}
      style={{ ...customStyles, ...style }}
      ariaHideApp={false}
      contentLabel="Edit modal"
    >
      <img
        src={CloseIcon}
        alt="close"
        className="float-right cursor cross-icon"
        onClick={onClick}
        role="presentation"
      />
      <ModalBox>
        <div className="modal-body">
          <h4>{title}</h4>
          <Calendar
            editableDateInputs
            onChange={onChange}
            date={selectedDate}
            showDateDisplay={false}
            minDate={minDate}
            maxDate={currentDate}
            rangeColors={[Theme.orange]}
            weekdayDisplayFormat="EEEEE"
            locale={enGB}
            color={color}
            {...props}
          />
          <div className="text-center mt-3">
            <Button
              onClick={onApply}
              type="button"
              className="btn-primary on-boarding w-100"
            >
              Apply
            </Button>
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
};

export default CustomSingleDateModal;

CustomSingleDateModal.defaultProps = {
  id: '',
  isOpen: false,
  title: 'Select Date Range',
  color: Theme.orange,
  style: {},
  currentDate: todaysDate,
  minDate: null,
  onClick: () => {},
  onChange: () => {},
  onApply: () => {},
};

CustomSingleDateModal.propTypes = {
  id: string,
  title: string,
  color: string,
  isOpen: bool,
  onClick: func,
  onChange: func,
  onApply: func,
  minDate: instanceOf(Date),
  currentDate: instanceOf(Date),
  style: shape({}),
  selectedDate: oneOfType([arrayOf(Array), shape({})]).isRequired,
};
