import React, { useCallback, useEffect, useState } from 'react';

import { string, arrayOf, shape, func, bool } from 'prop-types';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DesktopTable,
  WhiteCard,
  Button,
  PageLoader,
  CheckBox,
} from '../../../../common';
import PlaybookSummaryServiceCollapse from './PlaybookSummaryServiceCollapse';
import {
  PlaybookSummaryMobileView,
  BorderLessDropDown,
  TableSummaryWrapper,
  SummaryMobileSelect,
} from './PlaybookStyle';
import { getPlaybookSummaryData } from '../../../../api';
import Theme from '../../../../theme/Theme';
import { playbookSummaryMonthOptions } from '../../../../constants';
import { handleMonthDropdown } from './CommonFunctions';

const PlaybookSummary = ({
  customerId,
  selectedMarketplaces,
  selectedService,
  selectedTeamMembers,
  customStartDate,
  accountType,
  onCreatePlaybookClick,
  createPlaybookBtnLoader,
  updatedServicesOfPlaybookSummary,
  setUpdatedServicesOfPlaybookSummary,
  marketplacesList,
  playbookCreateAccess,
}) => {
  const [playbookSummaryData, setPlaybookSummaryData] = useState({});
  const [playbookSummaryLoader, setPlaybookSummaryLoader] = useState(false);
  const isDesktop = useMediaQuery({ maxWidth: 767 });
  const [collapseShowData, setCollapseShowData] = useState(false);

  const setValue = useCallback(
    (summaryData) => {
      const list = summaryData?.customer_playbook_data?.map((item) => {
        return { id: item?.id, frequency: item?.frequency };
      });
      setUpdatedServicesOfPlaybookSummary({
        create: list,
        delete: [],
      });
    },
    [setUpdatedServicesOfPlaybookSummary],
  );

  useEffect(() => {
    setPlaybookSummaryLoader(true);
    getPlaybookSummaryData(
      customerId,
      selectedMarketplaces,
      selectedService,
      selectedTeamMembers,
      customStartDate,
      accountType,
    ).then((res) => {
      setPlaybookSummaryData(res?.data);
      setValue(res?.data);
      setPlaybookSummaryLoader(false);
    });
  }, [
    customerId,
    setValue,
    selectedMarketplaces,
    selectedService,
    selectedTeamMembers,
    customStartDate,
    accountType,
  ]);

  const handleChangeOfServiceCheckbox = (serviceData, isChecked) => {
    const checkedList = [...updatedServicesOfPlaybookSummary.create];
    const uncheckedList = [...updatedServicesOfPlaybookSummary.delete];

    if (isChecked) {
      if (
        !updatedServicesOfPlaybookSummary.create?.find(
          (item) => item?.id === serviceData?.id,
        )
      ) {
        checkedList.push({
          id: serviceData.id,
          frequency: serviceData?.frequency,
        });
      }

      const index = uncheckedList.indexOf(serviceData.id);
      if (index > -1) {
        uncheckedList.splice(index, 1);
      }
      setUpdatedServicesOfPlaybookSummary({
        create: checkedList,
        delete: uncheckedList,
      });
    } else {
      if (!updatedServicesOfPlaybookSummary.delete?.includes(serviceData?.id)) {
        uncheckedList.push(serviceData.id);
      }

      const index = checkedList?.findIndex(
        (item) => item?.id === serviceData?.id,
      );
      if (index > -1) {
        checkedList.splice(index, 1);
      }

      setUpdatedServicesOfPlaybookSummary({
        create: checkedList,
        delete: uncheckedList,
      });
    }
  };

  // render deliverable collapse
  const renderPlaybookServiceCollapse = (dataItem) => {
    return (
      <PlaybookSummaryServiceCollapse
        serviceData={dataItem}
        handleChangeOfServiceCheckbox={handleChangeOfServiceCheckbox}
        updatedServicesOfPlaybookSummary={updatedServicesOfPlaybookSummary}
        setUpdatedServicesOfPlaybookSummary={
          setUpdatedServicesOfPlaybookSummary
        }
        marketplacesList={marketplacesList}
        playbookCreateAccess={playbookCreateAccess}
      />
    );
  };

  const setMonthDropdownValue = (customerPlaybook) => {
    const service = updatedServicesOfPlaybookSummary?.create?.find(
      (item) => item?.id === customerPlaybook?.id,
    );
    return playbookSummaryMonthOptions?.find(
      (item) =>
        item?.value ===
        (service ? service?.frequency : customerPlaybook?.frequency),
    );
  };

  const displayMonthData = (customerPlaybook) => {
    return playbookCreateAccess ? (
      <BorderLessDropDown>
        <SummaryMobileSelect
          classNamePrefix="react-select"
          options={playbookSummaryMonthOptions}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
          placeholder="Select.."
          value={setMonthDropdownValue(customerPlaybook)}
          onChange={(event) =>
            handleMonthDropdown(
              event,
              customerPlaybook,
              setUpdatedServicesOfPlaybookSummary,
              updatedServicesOfPlaybookSummary,
            )
          }
        />
      </BorderLessDropDown>
    ) : (
      customerPlaybook?.frequency
    );
  };

  return playbookSummaryLoader ? (
    <PageLoader
      component="performance-graph"
      color="#FF5933"
      type="detail"
      width={40}
      height={40}
    />
  ) : (
    <div>
      {!isDesktop ? (
        playbookSummaryData?.customer_playbook_data?.length ? (
          <WhiteCard>
            <div className="justify-space-between ">
              <div className="black-heading-title mb-3">Playbook Summary</div>
              <Button
                className="btn-primary mb-3"
                onClick={() => onCreatePlaybookClick()}
              >
                {createPlaybookBtnLoader ? (
                  <PageLoader color="#fff" type="button" />
                ) : (
                  'Create'
                )}
              </Button>
            </div>
            <TableSummaryWrapper>
              <DesktopTable>
                <thead>
                  <tr>
                    <th>
                      {' '}
                      {/* <CheckBox>
                        <label
                          className="check-container mb-0 "
                          htmlFor="playbookSummaryAllCheckbox"
                        >
                          <input
                            className="checkboxes"
                            type="checkbox"
                            id="playbookSummaryAllCheckbox"
                            onClick={(event) => {
                              handleAllCheckServices(event?.target?.checked);
                            }}
                            checked={
                              updatedServicesOfPlaybookSummary?.create
                                ?.length ===
                              playbookSummaryData?.customer_playbook_data
                                ?.length
                            }
                          />
                          <span className="checkmark mt-n1" />
                        </label>
                      </CheckBox>{' '} */}
                    </th>
                    <th className="pl-1" width="40%">
                      <span className="pl-2">SERVICES </span>
                    </th>
                    <th width="15%">Marketplace</th>
                    <th width="15%">MONTHS</th>
                    <th width="15%">Start Date</th>
                    <th width="15%">DEPARTMENT</th>
                  </tr>
                </thead>
                <tbody>
                  {playbookSummaryData?.customer_playbook_data?.map(
                    (customerPlaybook) => {
                      return renderPlaybookServiceCollapse(customerPlaybook);
                    },
                  )}
                </tbody>
              </DesktopTable>
            </TableSummaryWrapper>
          </WhiteCard>
        ) : (
          <WhiteCard className="pt-2">
            <p className="black-heading-title text-regular mt-2 mb-0 text-center">
              No Playbook Summary exists
            </p>
          </WhiteCard>
        )
      ) : (
        <PlaybookSummaryMobileView>
          <div className="row">
            <div className="col-6">
              <div className="black-heading-title mt-2">Playbook Summary</div>
            </div>
            <div className="col-6 text-right">
              <Button
                className="btn-primary mb-3"
                onClick={() => onCreatePlaybookClick()}
              >
                {createPlaybookBtnLoader ? (
                  <PageLoader color="#fff" type="button" />
                ) : (
                  'Create'
                )}
              </Button>
            </div>
          </div>
          {playbookSummaryData?.customer_playbook_data?.map(
            (customerPlaybook) => {
              return (
                <WhiteCard className="mb-3">
                  <div className="row">
                    <div className="col-12">
                      <CheckBox>
                        <label
                          className="check-container mb-0 "
                          htmlFor={customerPlaybook?.id}
                        >
                          <input
                            className="checkboxes"
                            type="checkbox"
                            id={customerPlaybook?.id}
                            onChange={(event) => {
                              handleChangeOfServiceCheckbox(
                                customerPlaybook,
                                event?.target?.checked,
                              );
                            }}
                            checked={updatedServicesOfPlaybookSummary?.create?.find(
                              (item) => item?.id === customerPlaybook?.id,
                            )}
                            defaultChecked={updatedServicesOfPlaybookSummary?.create?.find(
                              (item) => item?.id === customerPlaybook?.id,
                            )}
                          />
                          <span className="checkmark " />
                        </label>
                      </CheckBox>

                      <span
                        className="ml-3 pl-3 text-bold uppercase-text "
                        role="presentation"
                        onClick={() => {
                          setCollapseShowData(!collapseShowData);
                        }}
                      >
                        <span className="collapse-icon">
                          <FontAwesomeIcon icon="fa-regular fa-chevron-right" />
                        </span>
                        {customerPlaybook?.task_name}
                      </span>

                      {collapseShowData ? (
                        <>
                          {customerPlaybook?.deliverable?.map((deliverable) => {
                            return (
                              <>
                                <div className="sub-task-outer mb-4">
                                  <span className="curve-line curve-line-child" />
                                  <span className="normal-text-black ">
                                    {deliverable?.task_name}
                                  </span>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        ''
                      )}
                    </div>

                    <>
                      <div className="horizontal-line straight-line  " />
                      <div className="col-6">
                        <div className="label mb-0">MARKETPLACE</div>
                      </div>
                      <div className="col-6 text-right">
                        <div className="normal-text-black text-medium">
                          {
                            marketplacesList?.find(
                              (marketplace) =>
                                marketplace?.value ===
                                customerPlaybook?.marketplace,
                            )?.label
                          }
                        </div>
                      </div>
                      <div className="horizontal-line straight-line " />

                      <div className="col-6">
                        <div className="label mb-0">MONTHS</div>
                      </div>
                      <div className="col-6 text-right">
                        <div className="normal-text-black text-medium">
                          {displayMonthData(customerPlaybook)}
                        </div>
                      </div>
                      <div className="horizontal-line straight-line " />
                      <div className="col-6">
                        <div className="label mb-0">START DATE</div>
                      </div>
                      <div className="col-6 text-right">
                        <div className="normal-text-black text-medium">
                          {dayjs(customerPlaybook?.task_start_date).format(
                            'MMM',
                          )}
                        </div>
                      </div>
                      <div className="horizontal-line straight-line " />
                      <div className="col-6 ">
                        <div className="label mb-0">DEPARTMENT</div>
                      </div>
                      <div className="col-6 text-right">
                        <div className="normal-text-black text-medium">
                          {customerPlaybook?.team}
                        </div>
                      </div>
                    </>
                  </div>
                </WhiteCard>
              );
            },
          )}
        </PlaybookSummaryMobileView>
      )}
    </div>
  );
};

export default PlaybookSummary;

PlaybookSummary.defaultProps = {
  customerId: '',
  selectedMarketplaces: [],
  selectedService: [],
  selectedTeamMembers: [],
  marketplacesList: [],
  customStartDate: '',
  accountType: {},
  onCreatePlaybookClick: () => {},
  createPlaybookBtnLoader: false,
  playbookCreateAccess: false,
  updatedServicesOfPlaybookSummary: {},
  setUpdatedServicesOfPlaybookSummary: () => {},
};

PlaybookSummary.propTypes = {
  customerId: string,
  selectedMarketplaces: arrayOf(shape({})),
  selectedService: arrayOf(shape({})),
  marketplacesList: arrayOf(shape({})),
  selectedTeamMembers: arrayOf(shape({})),
  customStartDate: string,
  accountType: shape({}),
  onCreatePlaybookClick: func,
  createPlaybookBtnLoader: bool,
  playbookCreateAccess: bool,
  updatedServicesOfPlaybookSummary: shape({}),
  setUpdatedServicesOfPlaybookSummary: func,
};
