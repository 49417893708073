import React, { useState, useEffect, useCallback } from 'react';

import queryString from 'query-string';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { arrayOf, bool, shape } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AddUpdateNewQuery from './AddUpdateNewQuery';
import DSPAudienceTable from './DSPAudienceTable';
import { InfoIcons } from '../../../../../../theme/images';
import {
  WhiteCard,
  Button,
  NoRecordFound,
  DropdownIndicator,
  PageLoader,
  CommonPagination,
  ViewData,
  SingleSelectDropdown,
  GrayMessageBar,
} from '../../../../../../common';
import {
  getQueries,
  getDSPAudienceDefaultData,
  getDSPAudienceData,
  saveDSPAudience,
} from '../../../../../../api';
import Theme from '../../../../../../theme/Theme';
import { statusFilterOptions } from '../../../../../../constants';

function DSPAudienceContainer({ showAMCAudienceLookalike, memberData }) {
  const { id } = useParams();
  const history = useHistory();

  const params = queryString.parse(history.location.search);

  // https://bbe.atlassian.net/browse/PDV-8278
  // Removing the hard coded user emails and making it as dynamic access for authorized users.
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const allowedToAddUpdateQuery =
    userInfo?.allowed_to_add_update_dsp_audience_query_fe;
  const allowedToEditDspAudience = userInfo?.allowed_to_edit_dsp_audience_fe;

  // https://bbe.atlassian.net/browse/PDV-8930
  // Show dynamic messages in AMC
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  const amcAudienceMessage =
    dynamicMessages?.amc_audience_bp_view?.placeholder || null;

  const [isEditFlagOn, setEditFlag] = useState(false);
  const [loader, setLoader] = useState({ type: 'btn', flag: false });
  const [queryOptions, setQueryOptions] = useState([]);
  const [showAddNewQueryModal, setShowAddNewQueryModal] = useState(false);
  const [dspAudienceData, setDSPAudienceData] = useState();
  const [newDSPAudienceData, setNewDSPAudienceData] = useState({});
  const [dspAudienceDefaultData, setDSPAudienceDefaultData] = useState();
  const [isAuthorizedUser, setIsAuthorizedUser] = useState(false);
  const [dspAudienceCount, setDSPAudienceCount] = useState(null);
  const [pageNumber, setPageNumber] = useState();
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(
    statusFilterOptions[0],
  );
  const ViewDataFilterOptions = [
    {
      label: 'Most Recent to Oldest',
      value: '-created_at',
    },
    {
      label: 'Most Oldest to Recent',
      value: 'created_at',
    },
  ];
  const [selectedViewDataFilter, setSelectedViewDataFilter] = useState(
    ViewDataFilterOptions[0],
  );

  const checkPermission = useCallback(
    (members) => {
      if (allowedToEditDspAudience) {
        setIsAuthorizedUser(true);
      } else if (members) {
        for (const user of members) {
          if (user.user) {
            if (
              (user?.role_group?.name === 'DSP Ad Manager' ||
                user?.role_group?.name === 'DSP Specialist') &&
              user?.user?.id === userInfo?.id
            ) {
              setIsAuthorizedUser(true);
            }
          }
        }
      }
    },
    [userInfo, allowedToEditDspAudience],
  );

  useEffect(() => {
    checkPermission(memberData);
  }, [checkPermission, memberData]);

  const getQueriesData = useCallback(() => {
    getQueries().then((res) => {
      const options = res?.data?.map((item) => {
        return { label: item?.name, value: item?.id, query: item?.query };
      });
      setQueryOptions(options);
    });
  }, []);

  useEffect(() => {
    getQueriesData();
  }, [getQueriesData]);

  // this use effect will run after every 5 seconds to get new updated amc audience data
  useEffect(() => {
    let intervalId = '';
    let restrictMultipleApiCalls = null;

    if (!restrictMultipleApiCalls) {
      intervalId = setInterval(async () => {
        if (
          dspAudienceDefaultData?.id &&
          params?.nav === 'amcInsights' &&
          params?.subNav === 'dsp-audience' &&
          !restrictMultipleApiCalls
        ) {
          await getDSPAudienceData(
            dspAudienceDefaultData?.id,
            1,
            selectedViewDataFilter,
            selectedStatusFilter,
            showAMCAudienceLookalike,
          ).then((res) => {
            restrictMultipleApiCalls = '1234';
            setDSPAudienceCount(res?.data?.count);
            setPageNumber(1);
            setDSPAudienceData(res?.data?.results);
          });
        }
      }, 300000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [
    dspAudienceDefaultData,
    params,
    selectedStatusFilter,
    selectedViewDataFilter,
    showAMCAudienceLookalike,
  ]);

  // get amc audience data
  const getAudienceData = useCallback(
    (
      audienceId,
      page,
      sortBy = selectedViewDataFilter,
      status = selectedStatusFilter,
      AMCAudienceLookalike,
    ) => {
      setLoader({ type: 'page', flag: true });

      getDSPAudienceData(
        audienceId,
        page,
        sortBy,
        status,
        AMCAudienceLookalike,
      ).then((res) => {
        setLoader({ type: 'page', flag: false });
        setDSPAudienceCount(res?.data?.count);
        setDSPAudienceData(res?.data?.results);
      });
    },
    [selectedViewDataFilter, selectedStatusFilter],
  );

  // use effect for get amc audience data
  useEffect(() => {
    setLoader({ type: 'page', flag: true });
    setEditFlag(false);
    setNewDSPAudienceData({});
    getDSPAudienceDefaultData(id).then((res) => {
      setLoader({ type: 'page', flag: false });

      if (res?.status === 200) {
        if (res?.data?.results?.[0]) {
          setDSPAudienceDefaultData(res?.data?.results?.[0]);
          getAudienceData(
            res?.data?.results?.[0]?.id,
            1,
            selectedViewDataFilter,
            selectedStatusFilter,
            showAMCAudienceLookalike,
          );
        }
      }
    });
  }, [
    id,
    getAudienceData,
    selectedViewDataFilter,
    selectedStatusFilter,
    showAMCAudienceLookalike,
  ]);

  // get dropdwon indicator for  dropdown filter
  const getSelectDropdownIndicator = () => {
    return {
      DropdownIndicator,
    };
  };

  const onSaveClick = () => {
    setLoader({ type: 'btn', flag: true });
    saveDSPAudience(newDSPAudienceData).then((res) => {
      setLoader({ type: 'btn', flag: false });

      if (res?.status === 201) {
        getAudienceData(
          dspAudienceDefaultData?.id,
          1,
          selectedViewDataFilter,
          selectedStatusFilter,
          showAMCAudienceLookalike,
        );
        toast.success('DSP Audience Added successfully!');
        setPageNumber(1);

        setEditFlag(false);
        setNewDSPAudienceData({});
      }
      if (res?.status === 403) {
        toast.error(res?.data?.detail);
      }
    });
  };

  // validate whether user filled all the fields in newly added dsp audience row
  const checkIfAllColumnHaveData = () => {
    if (isEditFlagOn && Object.keys(newDSPAudienceData)?.length) {
      const copyObjOfNew = { ...newDSPAudienceData };
      delete copyObjOfNew.refresh_time_window;
      delete copyObjOfNew.refresh_rate_days;
      delete copyObjOfNew?.amc_audience_lookalike;

      return Object.values(copyObjOfNew).every((value) => value);
    }
    return false;
  };

  const executeScroll = (eleId) => {
    const element = document.getElementById(eleId);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  };

  // display edit and save button
  const displayEditAndSaveButton = () => {
    return isEditFlagOn ? (
      <>
        <Button
          type="button"
          data-tip={
            checkIfAllColumnHaveData() ? null : 'All fields are mandatory.'
          }
          data-for="save-cos-disabled-info"
          className={`btn-primary  extraNormal-text `}
          style={
            checkIfAllColumnHaveData()
              ? {
                  width: 'fit-content',
                  borderRadius: '6px',
                }
              : {
                  pointerEvents: 'all',
                  opacity: 0.6,
                  cursor: 'not-allowed',
                  width: 'fit-content',
                }
          }
          onClick={() => {
            if (checkIfAllColumnHaveData()) onSaveClick();
          }}
        >
          {loader?.flag && loader?.type === 'btn' ? (
            <PageLoader color="#FFFFFF" type="button" />
          ) : (
            <>
              <FontAwesomeIcon
                className="mr-2"
                icon="fa-regular fa-check"
                color="#fff"
                fontSize="12px"
              />
              Save
            </>
          )}
        </Button>
        {checkIfAllColumnHaveData() ? null : (
          <ReactTooltip
            id="save-cos-disabled-info"
            aria-haspopup="true"
            place="top"
          />
        )}
      </>
    ) : (
      <Button
        type="button"
        className="btn-primary extraNormal-text"
        style={{
          width: 'fit-content',
          borderRadius: '6px',
        }}
        onClick={() => {
          setEditFlag(true);
          executeScroll('dsp-audience-table-id');
        }}
      >
        <FontAwesomeIcon
          icon="fa-regular fa-pen"
          style={{ color: '#fff', fontSize: '12px' }}
          className="cursor  pl-1"
        />{' '}
        Edit
      </Button>
    );
  };

  // auto scroll to specific table cell
  const autoScrollToElement = useCallback((elId) => {
    const element = document.getElementById(elId);
    if (element !== null) {
      element.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });
    }
  }, []);

  const addNewRow = () => {
    const updatedNewDSPAudienceData = {
      ...newDSPAudienceData,
      instance_id: dspAudienceDefaultData?.instance_id,
      advertiser_id: dspAudienceDefaultData.advertiser_id,
      refresh_rate_days: 21,
      status: 'started',
      dsp_audience: dspAudienceDefaultData?.id,
      refresh_time_window: true,
      customer: id,
      description: '',
      name: '',
      query: '',
      time_window_start: '',
      time_window_end: '',
    };
    setNewDSPAudienceData(updatedNewDSPAudienceData);

    // https://bbe.atlassian.net/browse/PDV-9060
    // if user select AMC audience lookalike toggle then add two new fields in newDSPAudienceData
    if (showAMCAudienceLookalike) {
      setNewDSPAudienceData({
        ...updatedNewDSPAudienceData,
        expansion_type: '',
        amc_audience_lookalike: showAMCAudienceLookalike,
      });
    }
    autoScrollToElement('new-dsp-row');
  };

  // display Add DSP Audience button
  const displayAddNewRowBtn = () => {
    return (
      <div className="sticky-column add-new-cos-btn ">
        <Button
          className="btn-add-contact extraNormal-text"
          onClick={() => {
            addNewRow();
          }}
          style={{ textTransform: 'uppercase', minHeight: '0' }}
        >
          <FontAwesomeIcon
            color="#FF5933"
            fontSize="12px"
            icon="fa-regular fa-plus"
          />{' '}
          {showAMCAudienceLookalike
            ? 'Add DSP Audience Lookalike'
            : 'Add DSP Audience'}
        </Button>
      </div>
    );
  };

  const displayTableLabelRow = () => {
    return (
      <div className="row mb-3" id="dsp-audience-table-id">
        <div className="col-8 DSP-audience-label pr-0">
          <div className="COS-view-label">
            Request Logs{' '}
            <>
              {' '}
              <img
                className="ml-1 cursor"
                style={{
                  verticalAlign: 'text-bottom',
                  fontWeight: '300',
                }}
                width="14px"
                src={InfoIcons}
                alt="info"
                data-tip="New audiences typically take 1-2 days to be active on the ADSP console."
                data-for="dsp-audience-info"
              />{' '}
              <ReactTooltip
                id="dsp-audience-info"
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />{' '}
            </>
          </div>
          {allowedToAddUpdateQuery ? (
            <AddUpdateNewQuery
              setShowAddNewQueryModal={setShowAddNewQueryModal}
              showAddNewQueryModal={showAddNewQueryModal}
              QueryOptions={queryOptions}
              getSelectDropdownIndicator={getSelectDropdownIndicator}
              getQueriesData={getQueriesData}
            />
          ) : (
            ''
          )}
        </div>
        {isAuthorizedUser ? (
          <div className="col-4 text-right ">{displayEditAndSaveButton()}</div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getAudienceData(dspAudienceDefaultData?.id, currentPage);
  };

  const handleViewDataForFilterChange = (event) => {
    setSelectedViewDataFilter(event);
  };
  const handleStatusFilterChange = (event) => {
    setSelectedStatusFilter(event);
  };

  const displayviewDataforRow = () => {
    return (
      <ViewData className="mb-3">
        <div className="row">
          <div className="col-md-3 col-12">
            <div className="view-data-for mt-md-2 pt-md-1">View data for:</div>
          </div>
          <div className="col-md-9 col-12">
            <div className="row">
              <div className="col-lg-4 col-md-2 mt-md-2 pt-md-1 pl-md-0 text-sm-left text-right">
                Sort by
              </div>
              <div className="col-lg-4 col-md-5 col-12 mb-3 mb-md-0 pl-md-0">
                <SingleSelectDropdown
                  id="view-data-for-dsp-audience-filter"
                  placeholder={`Select `}
                  className="single-select-dropdown "
                  isApiCall={false}
                  dropdownOptions={ViewDataFilterOptions}
                  selectedValue={selectedViewDataFilter}
                  onChangeHandler={(event) =>
                    handleViewDataForFilterChange(event)
                  }
                  dropdownComponents={getSelectDropdownIndicator}
                  isSearchable={false}
                />
              </div>
              <div className="col-lg-4 col-md-5 col-12 pl-md-0">
                <SingleSelectDropdown
                  id="status-filter-amc-audience-dashboard"
                  placeholder={`Select `}
                  className="single-select-dropdown "
                  isApiCall={false}
                  dropdownOptions={statusFilterOptions}
                  selectedValue={selectedStatusFilter}
                  onChangeHandler={(event) => handleStatusFilterChange(event)}
                  dropdownComponents={getSelectDropdownIndicator}
                  isSearchable={false}
                />
              </div>
            </div>
          </div>
        </div>
      </ViewData>
    );
  };

  return (
    <WhiteCard>
      {loader?.flag && loader?.type === 'page' ? (
        <PageLoader
          component="performance-graph"
          color={Theme.orange}
          type="detail"
          width={40}
          height={40}
        />
      ) : dspAudienceDefaultData && dspAudienceDefaultData?.is_active ? (
        <>
          {displayviewDataforRow()}{' '}
          {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in AMC */}
          {amcAudienceMessage ? (
            <GrayMessageBar message={amcAudienceMessage} />
          ) : null}
          {displayTableLabelRow()}
          {dspAudienceData?.length || Object.keys(newDSPAudienceData).length ? (
            <>
              <div className="dps-Audience-Table">
                <DSPAudienceTable
                  showAMCAudienceLookalike={showAMCAudienceLookalike}
                  QueryOptions={queryOptions}
                  getSelectDropdownIndicator={getSelectDropdownIndicator}
                  isEditFlagOn={isEditFlagOn}
                  dspAudienceData={dspAudienceData}
                  displayTableLabelRow={displayTableLabelRow}
                  displayAddNewRowBtn={displayAddNewRowBtn}
                  newDSPAudienceData={newDSPAudienceData}
                  setNewDSPAudienceData={setNewDSPAudienceData}
                  dspAudienceDefaultData={dspAudienceDefaultData}
                  dspAudienceCount={dspAudienceCount}
                  pageNumber={pageNumber}
                  handlePageChange={handlePageChange}
                />
              </div>
              <CommonPagination
                count={dspAudienceCount}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
              />
            </>
          ) : isEditFlagOn ? (
            ''
          ) : (
            <div className="row">
              <NoRecordFound
                type="brand"
                title=""
                subTitle="Engage your brand on DSP to gain access to AMC audience."
              />
            </div>
          )}
          {isEditFlagOn && !Object.keys(newDSPAudienceData)?.length ? (
            <div>{displayAddNewRowBtn()}</div>
          ) : (
            ''
          )}
        </>
      ) : (
        <div className="row">
          <NoRecordFound
            type="brand"
            subTitle="Engage your brand on DSP to gain access to AMC audience."
          />
        </div>
      )}
    </WhiteCard>
  );
}

export default DSPAudienceContainer;

DSPAudienceContainer.defaultProps = {
  showAMCAudienceLookalike: false,
  memberData: [],
};

DSPAudienceContainer.propTypes = {
  showAMCAudienceLookalike: bool,
  memberData: arrayOf(shape({})),
};
