import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPen,
  faAngleDown,
  faShareNodes,
  faCodeMerge,
  faClock,
  faChevronDown,
  faArrowToBottom,
  faQuestionCircle,
  faCheck,
  faUser,
  faRectangleAd,
  faNewspaper,
  faBuilding as farFaBuilding,
  faPenRuler,
  faUserHeadset,
  faChevronUp,
  faBookAtlas,
  faWarehouseFull,
  faMegaphone,
  faLocationQuestion,
  faScrewdriverWrench,
  faPiggyBank,
  faChartLineUp,
  faLaptopMobile,
  faBoxesStacked,
  faHandshakeAngle,
  // faTrash as farFaTrash,
  faPlus,
  faTrash,
  fa1,
  fa2,
  fa3,
  faRepeat,
  faChevronRight,
  faMinus,
  faArrowRight,
  faArrowLeft,
  faMagnifyingGlass,
  faCircleXmark,
  faCalendar,
  faEye,
  faCircleInfo,
  faPenToSquare,
  faTimes,
  faCircleExclamation,
  faClockRotateLeft,
  faCalendarXmark,
  faTrashCanUndo,
  faFilePlus,
  faXmark,
  faHourglassStart,
  faFile,
  faAlarmExclamation,
  faThumbsUp,
  faCirclePause,
  faExclamation,
  faBarsProgress,
  faUpload,
  faRefresh,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-regular-svg-icons';

export default library.add(
  faPen,
  faAngleDown,
  faShareNodes,
  faCodeMerge,
  faClock,
  faChevronDown,
  faArrowToBottom,
  faQuestionCircle,
  faCheck,
  faUser,
  faRectangleAd,
  faNewspaper,
  farFaBuilding,
  faPenRuler,
  faUserHeadset,
  faChevronUp,
  faBookAtlas,
  faWarehouseFull,
  faMegaphone,
  faLocationQuestion,
  faScrewdriverWrench,
  faPiggyBank,
  faChartLineUp,
  faLaptopMobile,
  faBoxesStacked,
  faHandshakeAngle,
  // farFaTrash,
  faTrash,
  faPlus,
  fa1,
  fa2,
  fa3,
  faRepeat,
  faChevronRight,
  faMinus,
  faArrowRight,
  faArrowLeft,
  faMagnifyingGlass,
  faCircleXmark,
  faCalendar,
  faEye,
  faCircleInfo,
  faPenToSquare,
  faTimes,
  faCircleExclamation,
  faClockRotateLeft,
  faCalendarXmark,
  faTrashCanUndo,
  faFilePlus,
  faXmark,
  faHourglassStart,
  faFile,
  faAlarmExclamation,
  faThumbsUp,
  faCirclePause,
  faExclamation,
  faBarsProgress,
  faUpload,
  faRefresh,
  faArrowUpRightFromSquare,
);
