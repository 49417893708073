import React, { useEffect, useRef } from 'react';

import styled from 'styled-components';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../theme/Theme';
import CheckBox from './CheckBox';

function CustomMultiSelectDropDownFilter({
  filterId,
  customStyle,
  dropdownLabel,
  options,
  onChangeHandler,
  selectedValues,
  isDropdownMenuOpen,
  setIsDropdownMenuOpen,
  dropdownIconStyle,
  dropdownOptionClass,
  isDropdownMenuDisabled,
}) {
  const dropdownRef = useRef(null);

  function useOutsideAlerter(ref, type) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsDropdownMenuOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, type]);
  }

  useOutsideAlerter(dropdownRef, 'revenue');

  // handle is option checked or unchecked
  const isOptionchecked = (item) => {
    const isChecked = selectedValues?.some(
      (option) => option?.value === item?.value,
    );
    return isChecked;
  };

  /* ********** main return statement of this component ********** */
  return (
    <MultiSelectDropDown
      id={filterId}
      ref={dropdownRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        style={customStyle}
        className={`${isDropdownMenuOpen ? 'onFocus' : ''} ${
          isDropdownMenuDisabled ? 'disabled' : ''
        } dropDownValuecontainer`}
        role="presentation"
        onClick={() => {
          setIsDropdownMenuOpen(!isDropdownMenuOpen);
        }}
        id="clickbox"
      >
        <span className="selected-list">{dropdownLabel}</span>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-down"
          className="dropdown-arrow-icon pt-1"
          style={{
            ...dropdownIconStyle,
            transform: isDropdownMenuOpen ? 'rotate(180deg)' : '',
          }}
        />
      </div>

      {isDropdownMenuOpen ? (
        <div className="dropdown-option-menu" style={{ cursor: 'initial' }}>
          <ul
            className={`options-list ${
              isDropdownMenuDisabled ? 'disabled' : ''
            }`}
          >
            {options?.map((item) => {
              return (
                <li
                  key={item.id}
                  className={
                    selectedValues?.length === 1 && isOptionchecked(item)
                      ? dropdownOptionClass
                      : ''
                  }
                >
                  <CheckBox className="mt-3 mb-3">
                    <label
                      htmlFor={item.label}
                      className="check-container customer-pannel"
                    >
                      <input
                        type="checkbox"
                        id={item.label}
                        name={item?.value}
                        value={item?.value}
                        checked={isOptionchecked(item)}
                        onChange={(event) => {
                          onChangeHandler(event, item);
                        }}
                      />
                      <span className="checkmark" />
                      {item.label}
                    </label>
                  </CheckBox>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </MultiSelectDropDown>
  );
}

export default CustomMultiSelectDropDownFilter;

CustomMultiSelectDropDownFilter.defaultProps = {
  dropdownLabel: '',
  dropdownOptionClass: '',
  isDropdownMenuOpen: false,
  isDropdownMenuDisabled: false,
  options: [],
  customStyle: {},
  dropdownIconStyle: {},
  selectedValues: [],
  onChangeHandler: () => {},
  setIsDropdownMenuOpen: () => {},
};

CustomMultiSelectDropDownFilter.propTypes = {
  dropdownLabel: string,
  filterId: string.isRequired,
  dropdownOptionClass: string,
  isDropdownMenuOpen: bool,
  isDropdownMenuDisabled: bool,
  onChangeHandler: func,
  setIsDropdownMenuOpen: func,
  customStyle: shape({}),
  dropdownIconStyle: shape({}),
  options: arrayOf(shape({})),
  selectedValues: arrayOf(shape({})),
};

const MultiSelectDropDown = styled.div`
  .dropDownValuecontainer {
    border-radius: 6px;
    position: relative;
    border: 1px solid ${Theme.gray45};
    width: 100%;
    min-width: 93px;
    text-align: left;
    font-size: ${Theme.extraNormal};
    font-family: ${Theme.baseMediumFontFamily};
    border-radius: 6px;
    min-height: 40px;
    color: ${Theme.gray90};
    // background-color: ${Theme.gray6};
    padding: 11px 7px;

    cursor: pointer;
    span.selected-list {
      position: fixed;
      color: ${Theme.gray90};
      white-space: nowrap;
      // background-color: #fafafb;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 65%;
      margin-left: 4px;
      position: absolute;
    }
    .dropdown-arrow-icon {
      position: absolute;
      right: 9px;
      top: 7px;
    }

    &.onFocus {
      border: 2px solid ${Theme.orange};
    }
  }

  /* &#AMCDashboardBpFilter {
    .dropdown-arrow-icon {
      right: 11px;
      top: 11px;
      color: ${Theme.black};
      font-size: 16px;
    }
  } */
  .dropdown-option-menu {
    background-color: ${Theme.white};
    border-radius: 8px;
    box-shadow: 0 5px 15px 0 rgba(68, 68, 79, 0.4);
    padding: 0 14px;
    position: absolute;
    z-index: 9;
    color: ${Theme.gray90};
    text-align: left;
    top: 55px;
    width: 100%;
    overflow: auto;
    max-height: 300px;

    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }
    .options-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        display: block;
        padding: 0;
        margin: 0;
      }
    }
  }
`;
