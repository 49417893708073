import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';
import dayjs from 'dayjs';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, func, shape, string } from 'prop-types';

import Theme from '../../../../theme/Theme';
import { getCurrencyOptions } from '../../../../api';
import {
  invoiceBreakdownOptions,
  paymentsMethod,
  StatusColorSet,
} from '../../../../constants';
import {
  DesktopTable,
  HeaderDownloadFuntionality,
  ModalBox,
  PageLoader,
} from '../../../../common';

export default function InvoiceBreakdownModal({
  showInvoiceModal,
  setshowInvoiceModal,
  invoiceDetails,
  setInvoiceDetails,
  invoicesData,
}) {
  const customStyles = {
    content: {
      top: '50%',
      right: '0px',
      bottom: 'auto',
      maxWidth: '720px ',
      width: '100% ',
      maxHeight: '100%',
      overlay: ' {zIndex: 1000}',
      inset: '0% 0% 0% auto',
      marginRight: '0',
      borderRadius: '0px !important',
    },
  };

  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [lineItems, setLineItems] = useState([]);

  // calculate total sum of array elements values
  const calculateSumOfArrayValues = (arrayData, key) => {
    let total = null;
    if (arrayData?.length > 0) {
      total = arrayData
        .map((item) => item?.[key])
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
    }
    return total;
  };
  const invoiceTaxes = invoiceDetails?.taxes;
  const totalTaxAmount = calculateSumOfArrayValues(invoiceTaxes, 'amount');
  const subTotal = calculateSumOfArrayValues(invoiceDetails?.items, 'amount');

  const totalDiscount =
    invoiceDetails?.discount?.length > 0
      ? calculateSumOfArrayValues(invoiceDetails?.discount, 'amount')
      : invoiceDetails?.discounts
      ? Number(invoiceDetails?.discounts)
      : null;

  const monthlyBudget =
    (subTotal || 0) + (totalTaxAmount || 0) - Number(totalDiscount);

  // use effect for bind line items data
  useEffect(() => {
    const invoicelineItems =
      invoiceDetails?.items?.length > 0
        ? invoiceDetails?.items
        : invoiceDetails?.line_items;

    const tempLineItems = [];
    if (invoicelineItems?.length > 0) {
      for (const item of invoicelineItems) {
        tempLineItems.push({
          ...item,
          type: item?.service_type || item?.type,
          name: item?.name,
          quantity: item?.quantity,
          description: item?.description,
          amount: item?.amount,
        });
      }
    }
    setLineItems(tempLineItems);
  }, [invoiceDetails]);

  useEffect(() => {
    setIsLoading({ loader: true, type: 'page' });
    getCurrencyOptions().then((currency) => {
      setSelectedCurrency(
        currency?.data
          ?.find((op) => op.value === invoiceDetails?.local_currency)
          ?.label?.split(' ')?.[0],
      );
    });
    setIsLoading({ loader: false, type: 'page' });
  }, [invoiceDetails]);

  const displayNumber = (num) => {
    const res = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return res;
  };

  const getIndexOfInvoiceData = () => {
    return invoicesData?.findIndex((op) => invoiceDetails?.id === op.id);
  };

  // tooltip data for showing various tax amount
  const generateTooltipHTML = () => {
    const toolTipDataArray = invoiceTaxes;
    return `<div style="text-align:left">
        ${toolTipDataArray
          ?.map((item) => {
            return `<div style="margin:2px 0">${item?.name} ${
              item?.is_percent ? `(${item?.value}%)` : ''
            }: ${selectedCurrency}${item?.amount?.toFixed(2)}</div>`;
          })
          ?.join('')}
      </div>`;
  };

  // display tooltip icon
  const displayToolTipIcon = (dataText, dataFor) => {
    return (
      <FontAwesomeIcon
        icon="fa-solid fa-circle-info"
        color={Theme.green}
        style={{ fontSize: '12px' }}
        data-tip={dataText}
        data-for={dataFor}
      />
    );
  };

  // display tooltip information
  const displayToolTipInfo = (dataId) => {
    return (
      <ReactTooltip
        id={dataId}
        aria-haspopup="true"
        place="top"
        effect="solid"
        backgroundColor="#2e384d"
        fontSize="14px"
        className="dspPacingTooltip"
        isOpen
        html
      />
    );
  };

  // display invoice balance
  const displayInvoiceBalance = () => {
    const amountPaid = Number(invoiceDetails?.amount_paid || 0);
    const amountRefunded = Number(invoiceDetails?.amount_refunded || 0);
    let invoiceBalance = 0;
    if (monthlyBudget >= amountPaid) {
      invoiceBalance = monthlyBudget - amountPaid - amountRefunded;
    } else {
      invoiceBalance = amountPaid - monthlyBudget - amountRefunded;
    }

    return (
      <>
        {selectedCurrency}
        {displayNumber(invoiceBalance?.toFixed(2))}
      </>
    );
  };

  // display invoice field
  const displayInvoiceField = (label, amount, isTextBold, key) => {
    const showTooltip = key === 'taxes';
    const tooltipData = showTooltip ? generateTooltipHTML(key) : null;
    return (
      <>
        <div className="col-8 mt-1">
          <div
            className={`normal-text-black text-center ${
              isTextBold ? 'text-bold' : ''
            }`}
          >
            {label}
            {showTooltip ? (
              <span style={{ marginLeft: '5px' }}>
                {displayToolTipIcon(tooltipData, key)}
                {displayToolTipInfo(key)}
              </span>
            ) : null}
          </div>
        </div>
        <div className="col-4 mt-1">
          <div
            className={`normal-text-black text-right ${
              isTextBold ? 'text-bold' : 'text-medium'
            }`}
          >
            {selectedCurrency}
            {displayNumber(amount ? Number(amount)?.toFixed(2) : '0.00')}
          </div>
        </div>
      </>
    );
  };

  const renderInvoiceDetails = () => {
    return (
      <div className="row mt-4">
        <div className="col-12">
          <div className="normal-text-black text-bold text-center">
            {' '}
            {getIndexOfInvoiceData() === 0 ? null : (
              <span
                className="cursor"
                onClick={() => {
                  setInvoiceDetails(
                    invoicesData[
                      getIndexOfInvoiceData() === 0
                        ? null
                        : getIndexOfInvoiceData() - 1
                    ] || showInvoiceModal.previous,
                  );
                  setshowInvoiceModal({
                    ...showInvoiceModal,
                    previous:
                      invoicesData[
                        getIndexOfInvoiceData() === 0
                          ? null
                          : getIndexOfInvoiceData() - 1
                      ],
                    next: invoicesData[
                      getIndexOfInvoiceData() === invoicesData?.length - 1
                        ? null
                        : getIndexOfInvoiceData() + 1
                    ],
                  });
                }}
                role="presentation"
              >
                <FontAwesomeIcon
                  icon="fa-light fa-arrow-left"
                  className=" float-left"
                  style={{ fontSize: '14px', color: '#ff4817' }}
                />{' '}
              </span>
            )}
            {invoiceDetails?.invoice_type}
            <span className="text-regular">
              {' '}
              #{invoiceDetails?.invoiced_id}{' '}
            </span>
            {getIndexOfInvoiceData() === invoicesData?.length - 1 ? null : (
              <span
                className="cursor"
                onClick={() => {
                  setInvoiceDetails(
                    invoicesData[
                      getIndexOfInvoiceData() === invoicesData?.length - 1
                        ? null
                        : getIndexOfInvoiceData() + 1
                    ] || showInvoiceModal.next,
                  );
                  setshowInvoiceModal({
                    ...showInvoiceModal,
                    previous:
                      invoicesData[
                        getIndexOfInvoiceData() === 0
                          ? null
                          : getIndexOfInvoiceData() - 1
                      ],
                    next: invoicesData[
                      getIndexOfInvoiceData() === invoicesData?.length - 1
                        ? null
                        : getIndexOfInvoiceData() + 1
                    ],
                  });
                }}
                role="presentation"
              >
                <FontAwesomeIcon
                  icon="fa-light fa-arrow-left"
                  className=" float-right"
                  style={{
                    fontSize: '14px',
                    color: '#ff4817',
                    transform: 'rotate(180deg)',
                  }}
                />{' '}
              </span>
            )}
          </div>
        </div>
        <div className="clear-fix" />
        <div className="col-12 mt-3 mb-3">
          <div className="label">INVOICE DETAILS</div>
        </div>
        {invoiceBreakdownOptions.map((item) => (
          <div className={item.property} key={item.id}>
            <div className={item.subProperty}>
              {item.key === 'customer'
                ? invoiceDetails?.[item.key].name
                : invoiceDetails?.[item.key] || item.label}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderLineItems = () => {
    return (
      <>
        <div className="normal-text-black text-bold mb-3 mt-1">Line Items</div>

        {invoiceDetails?.line_items?.length ? (
          <div className="table-container">
            <DesktopTable>
              <thead>
                <tr className="gray-border-radius">
                  <th width="50%">ITEM</th>
                  <th width="10%">QTY</th>
                  <th width="20%">RATE</th>
                  <th className="text-right pr-3" width="20% ">
                    AMOUNT
                  </th>
                </tr>
              </thead>
              <tbody>
                {lineItems?.map((item) => (
                  <tr
                    key={item?.id || item?.invoiced_id || item?.invoice_item_id}
                  >
                    <td>
                      <div className="large-text text-bold capitalize">
                        <span className="capsule-status service-type text-regular mr-2 small-text uppercase">
                          {item?.type}
                        </span>{' '}
                        {item?.name}
                      </div>
                      <div className="sub-text mt-2">
                        {item?.description || ''}
                      </div>
                      {/* <div className="sub-text">
                              <span className="text-bold">
                                {' '}
                                Marketplace(s) managed:
                              </span>{' '}
                              United States
                            </div> */}
                    </td>
                    <td> {item?.quantity || 0}</td>
                    <td>
                      {selectedCurrency}
                      {displayNumber(item?.amount)}
                    </td>
                    <td className="text-bold text-right pr-3">
                      {selectedCurrency}
                      {displayNumber(item?.quantity * item?.amount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </DesktopTable>

            <div className="row mt-2 m-auto">
              <div className="col-6" />
              <div className="col-6">
                {/* ********** Sub Total ********** */}
                <div className="row mt-1">
                  {displayInvoiceField('Subtotal', subTotal, true, 'subTotal')}
                </div>

                {/* ********** Discount ********** */}
                {totalDiscount ? (
                  <div className="row">
                    {displayInvoiceField(
                      'Discount',
                      totalDiscount,
                      false,
                      'discounts',
                    )}
                  </div>
                ) : null}
                {/* ********** Taxes ********** */}
                {invoiceTaxes?.length > 0 ? (
                  <div className="row">
                    {displayInvoiceField(
                      'Taxes',
                      totalTaxAmount,
                      false,
                      'taxes',
                    )}
                  </div>
                ) : null}

                <div className="horizontal-line straight-line mt-2 mb-1" />

                <div className="row">
                  {/* ********** total monthly budget ********** */}
                  {displayInvoiceField(
                    'Total',
                    monthlyBudget,
                    true,
                    'monthly_budget',
                  )}
                  {/* ********** Amount paid ********** */}
                  {displayInvoiceField(
                    'Amount Paid',
                    invoiceDetails?.amount_paid,
                    false,
                    'amount_paid',
                  )}
                  {/* ********** Amount refunded ********** */}
                  {displayInvoiceField(
                    'Amount Refunded',
                    invoiceDetails?.amount_refunded,
                    false,
                    'amount_refunded',
                  )}
                </div>
                <div className="horizontal-line straight-line mt-2 mb-2" />
                {/* ********** Balance ********** */}
                <div className="row my-2">
                  <div className="col-8">
                    <div className=" total-subtotal text-center  text-bold">
                      Balance
                    </div>
                  </div>
                  <div className="col-4">
                    <div className=" total-subtotal text-bold text-right ">
                      {displayInvoiceBalance()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">No Line Item Record Found.</div>
        )}
      </>
    );
  };

  // https://bbe.atlassian.net/browse/PDV-9350 - DSP Notes - Activity Logs and Billing Overlay
  const displayDspNotes = (note) => {
    return (
      <div className="row mt-2">
        <div className="col-12">
          <div className="horizontal-line-dotted mb-3" />
          <h3 className="medium-text-title text-bold mb-3 mt-1 text-center">
            Note added to the Brand Partner
          </h3>

          <div
            className="normal-text-black mb-2"
            style={{
              borderRadius: '10px',
            }}
          >
            {note}
          </div>
        </div>
      </div>
    );
  };

  const renderPaymentDetails = () => {
    return (
      <div className="row mt-2">
        <div className="col-12">
          <div className="horizontal-line-dotted mb-3" />
          <h3 className="medium-text-title text-bold mb-3 mt-1 text-center">
            Installment payments made
          </h3>
          <div className="normal-text-black text-bold mb-3 mt-1">Payments</div>

          {invoiceDetails?.payments?.length ? (
            <div className="table-container">
              <DesktopTable>
                <thead>
                  <tr className="gray-border-radius">
                    <th width="40%">Date</th>
                    <th width="40%">Method</th>
                    <th className="text-right pr-3" width="20%">
                      AMOUNT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceDetails?.payments?.map((invoice) => (
                    <tr>
                      <td>
                        {dayjs(new Date(invoice?.date)).format('MMM DD, YYYY')}
                      </td>
                      <td style={{ 'text-transform': 'capitalize' }}>
                        {paymentsMethod?.[invoice?.method]
                          ? paymentsMethod?.[invoice?.method]
                          : invoice?.method}
                      </td>
                      <td className="text-right pr-3">
                        {selectedCurrency}
                        {displayNumber(invoice?.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </DesktopTable>
            </div>
          ) : (
            <div className="text-center">No Payments Record Found.</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={showInvoiceModal.modal}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="invoice breakdown modal"
    >
      <ModalBox>
        {isLoading.loader && isLoading.type === 'page' ? (
          <PageLoader
            component="Notes-modal-loader"
            color="#FF5933"
            type="page"
          />
        ) : (
          <>
            <HeaderDownloadFuntionality>
              <FontAwesomeIcon
                icon="fa-light fa-times"
                style={{ color: '#000', fontSize: '20px' }}
                onClick={() => setshowInvoiceModal(false)}
                className="float-right cursor px-3"
              />
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="header-title">
                      {invoiceDetails?.invoice_type || 'Retainer'} Invoice
                    </div>
                  </div>
                </div>
              </div>
            </HeaderDownloadFuntionality>
            <InvoiceBody status={invoiceDetails?.invoice_status}>
              <div className="invoice-details">
                {renderInvoiceDetails()}
                <div className="horizontal-line-dotted mt-2 mb-3" />
                {renderLineItems()}
                {/* https://bbe.atlassian.net/browse/PDV-9350 - DSP Notes - Activity Logs and Billing Overlay  */}
                {invoiceDetails?.invoice_type === 'DSP Service' &&
                invoiceDetails?.notes
                  ? displayDspNotes(invoiceDetails?.notes)
                  : null}
                {renderPaymentDetails()}
              </div>
            </InvoiceBody>
          </>
        )}
      </ModalBox>
    </Modal>
  );
}

InvoiceBreakdownModal.defaultProps = {
  showInvoiceModal: { modal: false, id: '' },
  setshowInvoiceModal: () => {},
  invoiceDetails: {},
  setInvoiceDetails: () => {},
  invoicesData: {},
};

InvoiceBreakdownModal.propTypes = {
  showInvoiceModal: shape({
    modal: bool,
    id: string,
    index: string,
  }),
  setshowInvoiceModal: func,
  invoiceDetails: shape({ id: string }),
  setInvoiceDetails: func,
  invoicesData: shape({ id: string }),
};

const InvoiceBody = styled.div`
  .invoice-details {
    padding: 0 30px 30px 30px;
    overflow: scroll;
    max-height: 95vh;
  }

  .out-door {
    color: ${Theme.green};
  }
  .capsule-status {
    border-radius: 12px;
    background: ${(props) =>
      props.status.toLowerCase().includes('paid')
        ? Theme.green
        : props.status.toLowerCase().includes('refunded')
        ? StatusColorSet.refunded
        : StatusColorSet[props.status.split(' ')[0].toLowerCase()]};
    font-family: ${Theme.baseMediumFontFamily};
    font-size: 10px;
    width: fit-content;
    color: ${(props) =>
      props.status.toLowerCase().includes('paid') ? Theme.white : Theme.black};
    padding: 3px 8px;

    &.small-text {
      font-size: 8px;
    }
    &.service-type {
      background-color: ${Theme.green};
      color: ${Theme.white};
      vertical-align: middle;
    }
  }
  .sub-text {
    color: ${Theme.gray35};
    font-size: ${Theme.extraSmall};
  }

  .total-subtotal {
    font-size: ${Theme.title};
    color: ${Theme.black};
  }
  .table-container {
    // max-height: 45vh;
    padding-bottom: 50px;
  }
`;
