import React from 'react';

import { number, string } from 'prop-types';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  UpDowGrayArrow,
} from '../../../theme/images';

const DifferenceAdMetric = ({ value, type }) => {
  if (value >= 0 || (type === 'dpvr' && value < 0)) {
    return (
      <div
        className={`perentage-value ${
          type === 'spend' ? '' : type === 'acos' ? 'down' : ''
        } mt-3 pt-1`}
      >
        {value === 0 ? null : (
          <img
            className="green-arrow"
            src={
              type === 'spend'
                ? UpDowGrayArrow
                : type === 'acos'
                ? ArrowDownIcon
                : ArrowUpIcon
            }
            alt="arrow-down"
          />
        )}
        {type === 'amcAudience' && !Number.isInteger(value)
          ? value?.toFixed(2).toString().replace('-', '')
          : value.toString().replace('-', '')}
        {type === 'roas' || type === 'totalRoas' ? '' : '%'}
      </div>
    );
  }
  return (
    <div
      className={`perentage-value ${
        type === 'spend' ? '' : type === 'acos' ? '' : 'down'
      }  mt-3 pt-1`}
    >
      <img
        className="red-arrow"
        src={
          type === 'spend'
            ? UpDowGrayArrow
            : type === 'acos'
            ? ArrowUpIcon
            : ArrowDownIcon
        }
        alt="arrow-down"
      />
      {type === 'amcAudience' && !Number.isInteger(value)
        ? value?.toFixed(2).toString().replace('-', '')
        : value.toString().replace('-', '')}
      {type === 'roas' || type === 'totalRoas' ? '' : '%'}
    </div>
  );
};

export default DifferenceAdMetric;

DifferenceAdMetric.defaultProps = {
  value: '',
  type: '',
};

DifferenceAdMetric.propTypes = {
  value: number,
  type: string,
};
