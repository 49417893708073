export const contractChoices = [
  { value: 'recurring', label: 'Recurring Only' },
  { value: 'recurring dsp', label: 'Recurring With DSP' },
  { value: 'recurring (90 day notice)', label: 'Recurring (90 days) Only' },
  {
    value: 'recurring (90 day notice) dsp',
    label: 'Recurring (90 days) With DSP',
  },
  { value: 'one time', label: 'One Time Only' },
  { value: 'dsp only', label: 'DSP Only' },
];
export const contractStatus = [
  { value: 'active', label: 'Signed' },
  { value: 'renewed', label: 'Renewed' },
  { value: 'pause', label: 'Paused' },
  { value: 'cancel', label: 'Cancelled' },

  {
    value: 'pending for cancellation',
    label: 'Cancelled - Pending for Billing',
  },
  {
    value: 'pending cancellation approval',
    label: 'Pending Cancellation Approval',
  },
  { value: 'pending contract signature', label: 'Pending Agreement Signature' },
  { value: 'pending contract approval', label: 'Pending Approval' },
  { value: 'pending contract', label: 'Pending Agreement' },
  {
    value: 'active pending for pause',
    label: 'Active - Pending for Pause',
  },
  {
    value: 'active pending for pause request',
    label: 'Active - Pending for Pause Request',
  },
  { value: 'active partially pause', label: 'Active - Partially Paused' },
];

export const marketplacesCode = [
  {
    country: 'Brazil',
    code: 'BR',
    marketplace_id: 'A2Q3Y263D00KWC',
    currency: 'BRL',
    value: 'amazon.com.br',
    region: 'NA',
  },
  {
    country: 'Canada',
    code: 'CA',
    marketplace_id: 'A2EUQ1WTGCTBG2',
    currency: 'CAD',
    value: 'amazon.ca',
    region: 'NA',
  },
  {
    country: 'Mexico',
    code: 'MX',
    marketplace_id: 'A1AM78C64UM0Y8',
    currency: 'MXN',
    value: 'amazon.com.mx',
    region: 'NA',
  },
  {
    country: 'United States',
    code: 'US',
    marketplace_id: 'ATVPDKIKX0DER',
    currency: 'USD',
    value: 'amazon.com',
    region: 'NA',
  },
  {
    country: 'United Arab Emirates (U.A.E.)',
    code: 'AE',
    marketplace_id: 'A2VIGQ35RCS4UG',
    currency: 'AED',
    value: 'amazon.ae',
    region: 'EU',
  },
  {
    country: 'Germany',
    code: 'DE',
    marketplace_id: 'A1PA6795UKMFR9',
    currency: 'EUR',
    value: 'amazon.de',
    region: 'EU',
  },
  {
    country: 'Egypt',
    code: 'EG',
    marketplace_id: 'ARBP9OOSHTCHU',
    currency: 'EGP',
    value: 'amazon.eg',
    region: 'EU',
  },
  {
    country: 'Spain',
    code: 'ES',
    marketplace_id: 'A1RKKUPIHCS9HS',
    currency: 'EUR',
    value: 'amazon.es',
    region: 'EU',
  },
  {
    country: 'France',
    code: 'FR',
    marketplace_id: 'A13V1IB3VIYZZH',
    currency: 'EUR',
    value: 'amazon.fr',
    region: 'EU',
  },
  {
    country: 'United Kingdom',
    code: 'UK',
    marketplace_id: 'A1F83G8C2ARO7P',
    currency: 'GBP',
    value: 'amazon.co.uk',
    region: 'EU',
  },
  {
    country: 'India',
    code: 'IN',
    marketplace_id: 'A21TJRUUN4KGV',
    currency: 'INR',
    value: 'amazon.in',
    region: 'EU',
  },
  {
    country: 'Italy',
    code: 'IT',
    marketplace_id: 'APJ6JRA9NG5V4',
    currency: 'EUR',
    value: 'amazon.it',
    region: 'EU',
  },
  {
    country: 'Netherlands',
    code: 'NL',
    marketplace_id: 'A1805IZSGTT6HS',
    currency: 'EUR',
    value: 'amazon.nl',
    region: 'EU',
  },
  {
    country: 'Poland',
    code: 'PL',
    marketplace_id: 'A1C3SOZRARQ6R3',
    currency: 'PLN',
    value: 'amazon.pl',
    region: 'EU',
  },
  {
    country: 'Saudi Arabia',
    code: 'SA',
    marketplace_id: 'A17E79C6D8DWNP',
    currency: 'SAR',
    value: 'amazon.sa',
    region: 'EU',
  },
  {
    country: 'Sweden',
    code: 'SE',
    marketplace_id: 'A2NODRKZP88ZB9',
    currency: 'SEK',
    value: 'amazon.se',
    region: 'EU',
  },
  {
    country: 'Turkey',
    code: 'TR',
    marketplace_id: 'A33AVAJ2PDY3EV',
    currency: 'TRY',
    value: 'amazon.com.tr',
    region: 'EU',
  },
  {
    country: 'Singapore',
    code: 'SG',
    marketplace_id: 'A19VAU5U5O7RUS',
    currency: 'SGD',
    value: 'amazon.sg',
    region: 'FE',
  },
  {
    country: 'Australia',
    code: 'AU',
    marketplace_id: 'A39IBJ37TRP1C6',
    currency: 'AUD',
    value: 'amazon.com.au',
    region: 'FE',
  },
  {
    country: 'Japan',
    code: 'JP',
    marketplace_id: 'A1VC38T7YXB528',
    currency: 'JPY',
    value: 'amazon.co.jp',
    region: 'FE',
  },
];
