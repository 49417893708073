import axiosInstance from '../axios';

import {
  API_TRIPLE_OVERLAP_VALUES,
  API_TRIPLE_OVERLAP_TABLE_VALUES,
  API_DSP_PPC_OVERLAP_ANALYSIS,
  API_TRIPLE_OVERLAP_ANALYSIS,
  API_NTB_GEOGRAPHIC,
  API_NTB_GEOGRAPHIC_TABLE,
  API_LIFE_TIME_VALUE,
  API_NTB_SALES_ANALYSIS,
  API_GET_QUICKSIGHT_DASHBOARD_URL,
} from '../constants';

// Async function for triple overlap analysis
export async function getTripleOverlapValues(
  customerID,
  adExposureType,
  startDate,
  endDate,
  showExposureTripleUnknownData,
  showExposureDoubleUnknownData,
) {
  const params = {
    customer: customerID,
    ad_exposure_type: adExposureType,
    start_date: startDate,
    end_date: endDate,
    exp_group_unknown: showExposureTripleUnknownData,
    expo_dual_unknown: showExposureDoubleUnknownData,
  };

  const result = await axiosInstance
    .get(`${API_TRIPLE_OVERLAP_VALUES}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// Async function for triple overlap table analysis
export async function getTripleOverlapTableValues(
  customerID,
  adExposure,
  startDate,
  endDate,
  pageNumber,
  orderBy,
  sequence,
) {
  const params = {
    customer: customerID,
    ad_exposure_type: adExposure,
    start_date: startDate,
    end_date: endDate,
    page: pageNumber,
    order_by: orderBy,
    sequence,
  };

  const result = await axiosInstance
    .get(`${API_TRIPLE_OVERLAP_TABLE_VALUES}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// Async function for triple overlap analysis
export async function getTripleOverlapAnalysisData(
  customerID,
  adExposureType,
  startDate,
  endDate,
  showLineGraphUnknownData,
  showPieChartUnknownData,
  showBarGraphUnknownData,
) {
  const params = {
    customer: customerID,
    ad_exposure_type: adExposureType,
    start_date: startDate,
    end_date: endDate,
    line_graph_unknown: showLineGraphUnknownData,
    pie_chart_unknown: showPieChartUnknownData,
    bar_graph_unknown: showBarGraphUnknownData,
  };

  const result = await axiosInstance
    .get(`${API_TRIPLE_OVERLAP_ANALYSIS}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// Async function for dsp ppc overlap analysis
export async function getDspPpcOverlapAnalysisData(
  customerID,
  adExposureType,
  startDate,
  endDate,
  showLineGraphUnknownData,
  showPieChartUnknownData,
  showBarGraphUnknownData,
) {
  const params = {
    customer: customerID,
    ad_exposure_type: adExposureType,
    start_date: startDate,
    end_date: endDate,
    line_graph_unknown: showLineGraphUnknownData,
    pie_chart_unknown: showPieChartUnknownData,
    bar_graph_unknown: showBarGraphUnknownData,
  };

  const result = await axiosInstance
    .get(`${API_DSP_PPC_OVERLAP_ANALYSIS}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// Async function for dsp ppc overlap analysis
export async function getLifeTimeValueData(customerID, startDate, endDate) {
  const params = {
    customer: customerID,
    start_date: startDate,
    end_date: endDate,
  };

  const result = await axiosInstance
    .get(`${API_LIFE_TIME_VALUE}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// Async function for dsp ppc overlap analysis
export async function getNTBSalesAnalysisData(customerID, startDate, endDate) {
  const params = {
    customer: customerID,
    start_date: startDate,
    end_date: endDate,
  };

  const result = await axiosInstance
    .get(`${API_NTB_SALES_ANALYSIS}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// Async function for NTB Geographic analysis
export async function getNtbGeographicAnalysis(customerID, startDate, endDate) {
  const params = {
    customer: customerID,
    start_date: startDate,
    end_date: endDate,
  };

  const result = await axiosInstance
    .get(`${API_NTB_GEOGRAPHIC}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// Async function for NTB Geographic Table analysis
export async function getNtbGeographicTableValues(
  customerID,
  startDate,
  endDate,
  pageNumber,
  orderBy,
  sequence,
) {
  const params = {
    customer: customerID,
    start_date: startDate,
    end_date: endDate,
    page: pageNumber,
    order_by: orderBy,
    sequence,
  };

  const result = await axiosInstance
    .get(`${API_NTB_GEOGRAPHIC_TABLE}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get embedded dashboard url of aws quicksight
export async function getEmbeddedQuicksightDashboardURL(params) {
  const result = await axiosInstance
    .get(`${API_GET_QUICKSIGHT_DASHBOARD_URL}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
