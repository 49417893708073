import React from 'react';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { arrayOf, bool, func, instanceOf, number, shape } from 'prop-types';

import { PARENT } from '../../../../../../constants';
import {
  WhiteCard,
  ViewData,
  DropdownIndicator,
  DropDowns,
  DropdownOptions,
  DropdownSingleValue,
} from '../../../../../../common';

const VendorSalesPerformanceFilters = ({
  dspPpcFlag,
  selectedMarketplace,
  marketplaceOptions,
  handleMarketplaceOptions,
  dateOptions,
  getSelectComponents,
  handleDailyFact,
  selectedSalesDF,
  isApiCall,
  daysDiff,
  currentDate,
  selectedChildBrand,
  childBrandOptions,
  handleChildBrandOptions,
  displayAccountTypeFilter,
}) => {
  const customerState = useSelector((state) => state?.customerState?.data);

  let options = [...dateOptions];
  if (daysDiff && options) {
    const updatedOption = options.map((obj) => {
      if (obj.value === 'week' && daysDiff < 7) {
        obj.isDisabled = true;
      }
      if (obj.value === 'month' && daysDiff < currentDate?.getDate()) {
        obj.isDisabled = true;
      }
      if (obj.value === '30days' && daysDiff < 30) {
        obj.isDisabled = true;
      }

      if (
        obj.value === 'year' &&
        daysDiff <
          dayjs(currentDate).diff(
            dayjs(`${currentDate?.getFullYear()}-01-01`),
            'days',
          )
      ) {
        obj.isDisabled = true;
      }

      return obj;
    });
    options = [...updatedOption];
  }

  // get dropdown components
  const getDropDownComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: DropdownSingleValue,
      DropdownIndicator,
    };
  };

  return (
    <WhiteCard className="mb-3">
      <ViewData>
        <div className="row">
          <div className="col-12 col-md-3">
            <div className="view-data-for mt-2">View data for:</div>
          </div>
          <div className="col-12 col-md-9">
            <div className="row">
              {customerState?.account_type === PARENT ? (
                <div className="col-lg-3 col-md-4 col-12 pl-md-0 mb-3 mb-xl-0">
                  {' '}
                  {DropDowns(
                    'BT-salesperformancedata-childBrandFilter-vendor',
                    'cursor',
                    childBrandOptions,
                    selectedChildBrand?.label,
                    getDropDownComponents,
                    '',
                    handleChildBrandOptions,
                    isApiCall,
                    null,
                    selectedChildBrand,
                  )}
                </div>
              ) : (
                <div className="col-lg-3 col-md-4 col-12" />
              )}
              <div className="col-lg-3 col-md-4 col-12 pl-md-0 mb-3 mb-xl-0">
                {displayAccountTypeFilter(isApiCall)}
              </div>
              <div className="col-lg-3 col-md-4 col-12 pl-md-0 mb-3 mb-xl-0">
                {dspPpcFlag?.isHavingDsp && !dspPpcFlag?.isHavingPpc
                  ? null
                  : DropDowns(
                      'BT-adperformancedata-countryfilter-vendor',
                      'cursor',
                      marketplaceOptions,
                      selectedMarketplace?.label,
                      getDropDownComponents,
                      '',
                      handleMarketplaceOptions,
                      isApiCall,
                      null,
                      selectedMarketplace,
                    )}{' '}
              </div>
              <div className="col-lg-3 col-md-4 col-12 pl-md-0 mb-3 mb-xl-0">
                {DropDowns(
                  'BT-VendorSalesPerformanceTimeFrame',
                  'un-searchable',
                  dateOptions,

                  dateOptions[0].label,
                  getSelectComponents,
                  dateOptions[0],
                  handleDailyFact,
                  isApiCall,
                  null,
                  selectedSalesDF,
                  false,
                )}
              </div>
            </div>
          </div>
        </div>
      </ViewData>
    </WhiteCard>
  );
};

export default VendorSalesPerformanceFilters;

VendorSalesPerformanceFilters.defaultProps = {
  selectedSalesDF: {},
  marketplaceOptions: {},
  dspPpcFlag: {},
  selectedMarketplace: {},
  dateOptions: {},
  isApiCall: false,
  daysDiff: 0,
  currentDate: null,
  selectedChildBrand: {},
  childBrandOptions: [],
  getSelectComponents: () => {},
  handleMarketplaceOptions: () => {},
  handleDailyFact: () => {},
  handleChildBrandOptions: () => {},
  displayAccountTypeFilter: () => {},
};

VendorSalesPerformanceFilters.propTypes = {
  isApiCall: bool,
  dspPpcFlag: shape({}),
  selectedMarketplace: shape({}),
  selectedSalesDF: instanceOf(Object),
  marketplaceOptions: arrayOf(Array),
  dateOptions: arrayOf(Array),
  selectedChildBrand: shape({}),
  childBrandOptions: arrayOf(shape({})),
  daysDiff: number,
  currentDate: instanceOf(Date),
  handleMarketplaceOptions: func,
  getSelectComponents: func,
  handleDailyFact: func,
  handleChildBrandOptions: func,
  displayAccountTypeFilter: func,
};
