import React from 'react';

import dayjs from 'dayjs';
import { DebounceInput } from 'react-debounce-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { bool, func, shape, string } from 'prop-types';

import Theme from '../../../../../../theme/Theme';
import { SearchIcon } from '../../../../../../theme/images';
import { Icon } from '../../../../../../theme/Global';
import {
  spCampaignAsinToggleOptions,
  campaignSortingOptions,
  asinStatiMessages,
  CAMPAIGN,
  ASIN,
  SELLERSMALL,
  VENDORSMALL,
} from '../../../../../../constants';
import {
  DropdownIndicator,
  DropdownOptions,
  DropdownSingleValue,
  GrayMessageBar,
  InputSearchWithRadius,
  PageLoader,
  SingleSelectDropdown,
  ToggleButton,
  ViewData,
} from '../../../../../../common';

function SpCampaignAsinFilters({
  isAsinDataAvailable,
  downloadReportLoader,
  asinPerformanceLoader,
  accountType,
  sponsoredAdType,
  selectedAsinToggle,
  campaignSearchQuery,
  selectedCampaignSortBy,
  displayReactTooltip,
  setCampaignSearchQuery,
  setDownloadReportLoader,
  handleCampaignSearchEvents,
  handleCampaignAsinToggleEvents,
  handleCampaignStatusChangeEvents,
}) {
  // https://bbe.atlassian.net/browse/PDV-8930
  // Show dynamic messages in SP CAMPAIGN & ASIN performance
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  const spAsinMessage =
    accountType === SELLERSMALL
      ? dynamicMessages?.sponsored_performance_seller_asin?.placeholder
      : accountType === VENDORSMALL
      ? dynamicMessages?.sponsored_performance_vendor_asin?.placeholder
      : null;
  const spCampaignMessage =
    accountType === SELLERSMALL
      ? dynamicMessages?.sponsored_performance_seller_campaign?.placeholder
      : accountType === VENDORSMALL
      ? dynamicMessages?.sponsored_performance_vendor_campaign?.placeholder
      : null;

  const asinTooltipContent = `Available data is from June 1, 2023 onwards`;
  // PDV-8401 - Logic - point no. 1 - One October 2023 logic
  const todaysDate = dayjs(new Date()).format('YYYY-MM-DD');
  const oneOctober2023 = dayjs(new Date(2023, 9, 1)).format('YYYY-MM-DD');

  // display input dearch with radius
  const displayInputSearchWithRadius = () => {
    return (
      <InputSearchWithRadius
        id="BT-order-customerlist-search-input"
        className="customer-list-header"
      >
        <DebounceInput
          // minLength={2}
          debounceTimeout={600}
          className="form-control search-filter form-control-info"
          placeholder="Search By Campaign Name"
          onChange={(event) => {
            handleCampaignSearchEvents(event);
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleCampaignSearchEvents(event);
            }
          }}
          value={campaignSearchQuery || ''}
        />

        <img src={SearchIcon} alt="search" className="search-input-icon" />
        {campaignSearchQuery !== '' ? (
          <FontAwesomeIcon
            icon="fal fa-times"
            className="close-icon cursor"
            onClick={() => setCampaignSearchQuery('')}
          />
        ) : null}
      </InputSearchWithRadius>
    );
  };

  // get dropdown components
  const getDropdDownComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: DropdownSingleValue,
      DropdownIndicator,
    };
  };

  // display campaign status filters
  const displayCampaignSortByFilter = (isApiCall) => {
    return (
      <div
        id="campaign-sorting-filter"
        style={{ position: 'relative', minWidth: '200px' }}
      >
        <SingleSelectDropdown
          filterId="BT-CampaignSortingFilter"
          isApiCall={isApiCall}
          dropdownOptions={campaignSortingOptions}
          selectedValue={selectedCampaignSortBy}
          onChangeHandler={handleCampaignStatusChangeEvents}
          dropdownComponents={getDropdDownComponents}
          isSearchable={false}
        />
      </div>
    );
  };

  // display campaign/asin toggle button
  const displayCampaignAsinToggleButton = () => {
    return (
      <ToggleButton>
        <div className="days-container asin-toggle-button">
          <ul className="days-tab">
            {spCampaignAsinToggleOptions?.map((option) => {
              return (
                <li id={`BT-Sp-${option?.value}-toggle`}>
                  <input
                    className="d-none"
                    type="radio"
                    id={option?.id}
                    name={`${option?.value}RadioDefault`}
                    value={option?.value}
                    checked={option?.value === selectedAsinToggle}
                    onClick={() =>
                      handleCampaignAsinToggleEvents(option?.value)
                    }
                  />
                  <label htmlFor={option?.id}>{option?.label}</label>
                </li>
              );
            })}
          </ul>
        </div>
      </ToggleButton>
    );
  };

  // display download report button
  const displayDownloadReportButton = () => {
    return (
      <div
        className={`black-heading-title text-medium-font m-0 cursor ${
          !isAsinDataAvailable || asinPerformanceLoader ? 'disabled' : ''
        }`}
        role="presentation"
        onClick={() => {
          setDownloadReportLoader(true);
        }}
        style={{ maxWidth: '150px' }}
      >
        {downloadReportLoader ? (
          <PageLoader
            component="sidebar-loader"
            type="button"
            color={Theme?.orange}
          />
        ) : (
          <>
            <FontAwesomeIcon
              className="mr-2"
              color={Theme?.orange}
              icon="far fa-arrow-to-bottom"
            />
            Download Report
          </>
        )}
      </div>
    );
  };

  // display statc asin message -  PDV-8401 - Logic - point no. 3
  const displayStaticAsinMessage = (type) => {
    return <GrayMessageBar message={asinStatiMessages?.[type]} />;
  };

  /* ********** Main return statement of this component ********** */
  return (
    <ViewData className={asinPerformanceLoader ? 'disabled' : ''}>
      <div className="row">
        <div className="col-6 col-lg-3 mb-2 pr-0">
          <div className="black-heading-title d-inline-block mt-md-2">
            {selectedAsinToggle === CAMPAIGN
              ? 'Campaign and ASIN Performance'
              : 'ASIN Performance'}
          </div>
          {todaysDate < oneOctober2023 ? (
            <Icon>
              <FontAwesomeIcon
                data-for="info-icon"
                data-tip={asinTooltipContent}
                data-html
                icon="fa-regular fa-circle-info"
              />
              {displayReactTooltip('info-icon', 'top')}
            </Icon>
          ) : null}
        </div>

        <div className="col-6 d-lg-none pl-0 text-right">
          {displayDownloadReportButton()}
        </div>
        <div className="col-12 col-lg-9">
          <div className="view-data-filter-list">
            <ul>
              {selectedAsinToggle === CAMPAIGN ? (
                <>
                  <li>{displayInputSearchWithRadius()}</li>
                  <li>
                    <ul className="inline-list">
                      <li>Sort By</li>
                      <li>{displayCampaignSortByFilter()}</li>
                    </ul>
                  </li>
                </>
              ) : null}

              <li>{displayCampaignAsinToggleButton()}</li>
              <li className="asin-download-button">
                {displayDownloadReportButton()}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in DSP CAMPAIGN & ASIN performance */}
      {selectedAsinToggle === ASIN && spAsinMessage ? (
        <GrayMessageBar message={spAsinMessage} />
      ) : null}
      {selectedAsinToggle === CAMPAIGN && spCampaignMessage ? (
        <GrayMessageBar message={spCampaignMessage} />
      ) : null}

      {(sponsoredAdType === 'brand_campaign_report' ||
        sponsoredAdType === 'all') &&
      selectedAsinToggle === ASIN
        ? displayStaticAsinMessage('brand_campaign_report')
        : null}
      {(sponsoredAdType === 'television_campaign_report' ||
        sponsoredAdType === 'all') &&
      selectedAsinToggle === CAMPAIGN
        ? displayStaticAsinMessage('television_campaign_report')
        : null}
    </ViewData>
  );
}

export default SpCampaignAsinFilters;

SpCampaignAsinFilters.defaultProps = {
  isAsinDataAvailable: false,
  downloadReportLoader: false,
  asinPerformanceLoader: false,
  accountType: '',
  sponsoredAdType: 'all',
  campaignSearchQuery: '',
  selectedCampaignSortBy: {},
  displayReactTooltip: () => {},
  setCampaignSearchQuery: () => {},
  setDownloadReportLoader: () => {},
  handleCampaignSearchEvents: () => {},
  handleCampaignAsinToggleEvents: () => {},
  handleCampaignStatusChangeEvents: () => {},
};

SpCampaignAsinFilters.propTypes = {
  isAsinDataAvailable: bool,
  downloadReportLoader: bool,
  asinPerformanceLoader: bool,
  accountType: string,
  sponsoredAdType: string,
  selectedAsinToggle: string.isRequired,
  campaignSearchQuery: string,
  selectedCampaignSortBy: shape({}),
  displayReactTooltip: func,
  setCampaignSearchQuery: func,
  setDownloadReportLoader: func,
  handleCampaignSearchEvents: func,
  handleCampaignAsinToggleEvents: func,
  handleCampaignStatusChangeEvents: func,
};
