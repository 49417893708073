import React, { useCallback } from 'react';

import Select, { components } from 'react-select';
import { arrayOf, func, bool, shape, string } from 'prop-types';

import Theme from '../theme/Theme';
import CheckBox from './CheckBox';
import CustomDropDown from './CustomDropDown';
import DropDownIndicator from './DropDownIndicator';

export default function MultiSelectSearchableDropdown({
  filterId,
  options,
  label,
  value,
  multiSelectedValues,
  setMultiSelectedValues,
  handleMultiSelectChange,
  isLoading,
  isSearchable,
  handleOnMenuClose,
  allObjectOptionDisabled = false,
}) {
  const { Option, ValueContainer } = components;
  const allObj = { label: `All ${label}`, value: 'all' };

  // custom html for dropdwon menu options
  const selectOption = (dataProps) => {
    return (
      <div className={`option-with-checkbox ${isLoading ? 'disabled' : ''}`}>
        <Option {...dataProps}>
          <CheckBox>
            <label
              htmlFor={dataProps?.value}
              className={`check-container customer-pannel ${
                dataProps?.isDisabled ? 'is-option-disabled' : ''
              }`}
            >
              <input
                type="checkbox"
                name={dataProps?.value}
                id={dataProps?.value}
                checked={dataProps?.isSelected}
              />
              <span className="checkmark" />
              {dataProps?.label}
            </label>
          </CheckBox>
        </Option>
      </div>
    );
  };

  // custom html for selected values from dropdwon options
  const valueContainer = useCallback(
    ({ children, getValue, ...dataProps }) => {
      const updatedValue = [...children];
      const { length } = getValue();
      const inputValue = dataProps?.selectProps?.inputValue;

      if (inputValue?.length > 0) {
        updatedValue[0] = '';
      } else {
        switch (length) {
          case 0:
            updatedValue[0] = `Select ${label}`;
            break;
          case 1:
            updatedValue[0] = dataProps?.selectProps?.value?.[0]?.label;
            break;
          default:
            updatedValue[0] = `${
              dataProps?.selectProps?.value?.[0]?.value === 'all'
                ? `All ${label}`
                : `Multiple ${label}`
            } `;
            break;
        }
      }

      return (
        <ValueContainer {...dataProps}>
          <div style={{ position: 'relative', minHeight: '17px' }}>
            {updatedValue}
          </div>
        </ValueContainer>
      );
    },
    [label],
  );

  // get react-select components
  const getAllComponents = () => {
    return {
      Option: selectOption,
      ValueContainer: valueContainer,
      DropdownIndicator: DropDownIndicator,
    };
  };

  // handle dropdown change events
  const handleChange = (event, action) => {
    const userAction = action?.action;
    const selectedValue = action?.option?.value;

    if (userAction === 'select-option') {
      if (selectedValue === 'all')
        setMultiSelectedValues({
          ...multiSelectedValues,
          [value]: [allObj, ...options],
        });
      if (selectedValue !== 'all')
        setMultiSelectedValues((prevState) => ({
          ...prevState,
          [value]:
            event?.length === options?.length ? [allObj, ...event] : event,
        }));
    }
    if (userAction === 'deselect-option') {
      if (selectedValue === 'all')
        setMultiSelectedValues({
          ...multiSelectedValues,
          [value]: [],
        });
      if (selectedValue !== 'all')
        setMultiSelectedValues({
          ...multiSelectedValues,
          [value]: event?.filter((op) => op?.value !== 'all'),
        });
    }
  };

  return (
    <>
      <CustomDropDown id={filterId}>
        <Select
          classNamePrefix="react-select"
          className={`active multi-select-searchable-dropdown ${
            isLoading || options?.length <= 1 ? 'dropdown-disabled' : ''
          }`}
          options={
            options?.length > 1
              ? [allObj, ...options]
              : options?.length === 1
              ? options
              : []
          }
          isMulti
          value={multiSelectedValues?.[value]}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={getAllComponents()}
          styles={{
            option: ({ isSelected }) => {
              return {
                backgroundColor: isSelected ? 'white' : null,
                margin: ' 15px',
              };
            },
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.white,
              primary: Theme.orange,
              primary50: Theme.white,
            },
          })}
          isClearable={false}
          backspaceRemovesValue={false}
          isSearchable={isSearchable}
          placeholder={`Select ${label}`}
          onChange={(event, action) => {
            handleChange(event, action);
            handleMultiSelectChange(event, action);
          }}
          onMenuClose={() => handleOnMenuClose()}
          isOptionDisabled={(op) => {
            if (allObjectOptionDisabled && op.label === 'All Campaigns')
              return true;
            return op?.isDisabled;
          }}
          name={value}
          isLoading={isLoading}
        />
      </CustomDropDown>
    </>
  );
}

MultiSelectSearchableDropdown.defaultProps = {
  isLoading: false,
  isSearchable: false,
  allObjectOptionDisabled: false,

  handleMultiSelectChange: () => {},
  handleOnMenuClose: () => {},
};

MultiSelectSearchableDropdown.propTypes = {
  isLoading: bool,
  isSearchable: bool,
  allObjectOptionDisabled: bool,

  filterId: string.isRequired,
  value: string.isRequired,
  label: string.isRequired,

  multiSelectedValues: shape({}).isRequired,

  options: arrayOf(shape({})).isRequired,

  setMultiSelectedValues: func.isRequired,
  handleMultiSelectChange: func,
  handleOnMenuClose: func,
};
