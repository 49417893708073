import React, { useState } from 'react';

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../../../theme/Theme';
import { getCustomReportActivity, getReportDownload } from '../../../api';
import PartnerOpportunitiesReportModal from './PartnerOpportunitiesReportModal';
import {
  CommonPagination,
  DesktopTable,
  NoData,
  PageLoader,
} from '../../../common';
import {
  SpreetailRole,
  SpreetailRoleRestrictMessage,
  cashflowStandardReportsKeys,
  objectiveDefalutList,
  productDefalutList,
  standardReportMessages,
  titleDefalutList,
  tooltipMsg,
} from '../../../constants';

const DOWNLOAD_ACTION = 'downloaded';

const ReportSectionList = ({
  selectedReportTypeValue,
  reportsData,
  loader,
  selectedBp,
  count,
  pageNumber,
  handlePageChange,
  isCustomer,
  selectedTimeFrame,
  generateCustomDate,
  selectedMarketplaceOption,
  customDateState,
  customerId,
}) => {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const [
    openPartnerOpportunitiesReportModal,
    setOpenPartnerOpportunitiesReportModal,
  ] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 767 });

  const isMTDorCustomDate =
    selectedTimeFrame?.value === 'custom' ||
    selectedTimeFrame?.value === 'month';

  const [itemLoader, setItemLoader] = useState({});
  const [downloadedReportDetails, setDownloadedReportDetails] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(productDefalutList);
  const [selectedObjective, setSelectedObjective] =
    useState(objectiveDefalutList);
  const [selectedTitle, setSelectedTitle] = useState(titleDefalutList);
  const [description, setDescription] = useState('');
  const [callToAction, setCallToAction] = useState('');

  // calling report download activity api function
  const onGetCustomReportActivity = (action, reportID) => {
    getCustomReportActivity(action, reportID).then(() => {});
  };

  const resetDropDownFilters = () => {
    setOpenPartnerOpportunitiesReportModal(false);
    setDownloadedReportDetails(null);
    setSelectedProduct(productDefalutList);
    setSelectedObjective(objectiveDefalutList);
    setSelectedTitle(titleDefalutList);
    setDescription('');
    setCallToAction('');
  };

  // Downloading the CSV report
  const onDownload = (id, index) => {
    let sd = null;
    let ed = null;

    const userId = isCustomer
      ? customerId
      : selectedReportTypeValue === 'finance'
      ? null
      : selectedBp;

    if (selectedTimeFrame?.value === 'custom') {
      sd = generateCustomDate(customDateState[0].startDate);
      ed = generateCustomDate(customDateState[0].endDate);
    }

    // https://bbe.atlassian.net/browse/PDV-7655
    // bind marketplace value
    const marketplace =
      selectedMarketplaceOption !== 'select' ? selectedMarketplaceOption : null;
    setItemLoader({ ...itemLoader, [index]: true });
    getReportDownload(
      userId,
      selectedTimeFrame?.value,
      sd,
      ed,
      id,
      marketplace,
      selectedTitle,
    ).then((res) => {
      if (res?.status === 200) {
        const csvLink = res?.data?.url;
        if (csvLink) {
          window.open(csvLink);
        }
        if (res?.data?.message) {
          toast.error(res?.data?.message, {
            position: isDesktop ? 'top-center' : 'top-right',
          });
        }
        onGetCustomReportActivity(DOWNLOAD_ACTION, id);
      } else {
        toast.error('Something went wrong', {
          position: isDesktop ? 'top-center' : 'top-right',
        });
      }
      setItemLoader((oldLoadingState) => ({
        ...oldLoadingState,
        [index]: false,
      }));
      resetDropDownFilters();
    });
  };

  const renderDesktopTableHeader = () => {
    return (
      <thead>
        <tr>
          <th width="25%">NAME/TYPE</th>
          <th width="60%">DESCRIPTION</th>
          <th width="15%">Action</th>
        </tr>
      </thead>
    );
  };

  // convert sentence to capitalize
  const returnReportType = (value) => {
    return value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase(),
    );
  };

  // display download report button
  const displayDownloadReportButton = (item, index, label, view) => {
    return itemLoader[index] ? (
      <PageLoader
        color={Theme.orange}
        component="table-download-btn"
        type="button"
        width={20}
        height={20}
      />
    ) : (
      <>
        <span
          onClick={() => {
            if (item?.report_type === 'finance' && isSpreetailUser) {
              return;
            }
            if (
              ((selectedBp !== 'Select-BP' || isCustomer) &&
                item?.is_data_available) ||
              (item?.is_data_available && item?.report_type === 'finance')
            ) {
              if (
                item?.report_name === 'Monthly Partner Opportunities Report'
              ) {
                setOpenPartnerOpportunitiesReportModal(true);
                setDownloadedReportDetails({ id: item?.id, index });
              } else {
                onDownload(item?.id, index);
              }
            }
          }}
          aria-hidden="true"
          className={
            view === 'desktop'
              ? (item?.report_type === 'finance' && isSpreetailUser) ||
                (item?.sub_report_type === 'sp_advertising' &&
                  !isMTDorCustomDate)
                ? 'inActive-download'
                : item?.is_data_available
                ? 'active-download'
                : 'inActive-download'
              : 'icon-with-border d-block mt-3'
          }
          data-tip={
            item?.report_type === 'finance' && isSpreetailUser
              ? SpreetailRoleRestrictMessage
              : !item?.is_data_available
              ? tooltipMsg
              : null
          }
          data-for="downloadReportInfo"
          style={
            view === 'desktop'
              ? (item?.report_type === 'finance' && isSpreetailUser) ||
                (item?.sub_report_type === 'sp_advertising' &&
                  !isMTDorCustomDate)
                ? { cursor: 'not-allowed' }
                : item?.is_data_available
                ? { cursor: 'pointer' }
                : { cursor: 'not-allowed' }
              : (item?.report_type === 'finance' && isSpreetailUser) ||
                (item?.sub_report_type === 'sp_advertising' &&
                  !isMTDorCustomDate)
              ? {
                  cursor: 'not-allowed',
                  border: `1px solid ${Theme.gray25}`,
                }
              : item?.is_data_available
              ? {
                  cursor: 'pointer',
                  border: `1px solid ${Theme.orange}`,
                }
              : {
                  cursor: 'not-allowed',
                  border: `1px solid ${Theme.gray25}`,
                }
          }
        >
          <FontAwesomeIcon
            icon="fal fa-download"
            data-for="downloadReportInfo"
            color={
              item?.report_type === 'finance' && isSpreetailUser
                ? Theme.gray25
                : item?.is_data_available
                ? Theme.orange
                : Theme.gray25
            }
            style={{
              marginRight: '5px',
            }}
          />
          {label !== null ? label : ''}
        </span>

        {!item?.is_data_available ? (
          <ReactTooltip
            class="initialTextTransform text-left"
            id="downloadReportInfo"
            aria-haspopup="true"
            place="bottom"
            effect="solid"
            html
          />
        ) : null}
      </>
    );
  };

  // display tooltip message and icon
  const displayTooltipMessageWithIcon = (dataTip) => {
    return (
      <>
        <FontAwesomeIcon
          className="ml-1"
          data-for="info-icon"
          data-tip={dataTip}
          data-html
          icon="fa-regular fa-circle-info"
          style={{ verticalAlign: '-3px' }}
        />
        <ReactTooltip
          className="asian-info-icon"
          id="info-icon"
          aria-haspopup="true"
          place="bottom"
          effect="solid"
          html
          insecure
        />
      </>
    );
  };

  // display report type tooltip
  const displayTooltipByReportType = (item) => {
    const reportKey = [
      'Weekly Return and Review Report',
      'Monthly Partner Opportunities Report',
      'Sponsored Television Report',
    ].includes(item?.report_name)
      ? item?.report_name
      : ['sp_advertising', 'reconciliation'].includes(item?.sub_report_type)
      ? item?.sub_report_type
      : selectedTimeFrame?.value === 'custom' &&
        cashflowStandardReportsKeys?.includes(item?.sub_report_type)
      ? 'finance_custom'
      : item?.report_type;

    // 28/12/2023 - https://bbe.atlassian.net/browse/PDV-8442 - add another sentence on finance report tooltip as: Select the entire month range to download past monthly report.
    return standardReportMessages?.[reportKey]
      ? displayTooltipMessageWithIcon(standardReportMessages[reportKey])
      : '';
  };

  const renderDesktopTable = () => {
    return (
      <tbody>
        {reportsData?.map((item, index) => {
          return (
            <tr key={item?.id}>
              <td className="pr-5">
                <div className="large-text">{item?.report_name} </div>
                <p className="basic-small-text">
                  {returnReportType(item?.report_type.replace('_', ' '))}
                  {displayTooltipByReportType(item)}
                </p>
              </td>
              <td className="pr-5">
                <div className="large-text ">{item?.report_description}</div>
              </td>
              <td>
                {displayDownloadReportButton(
                  item,
                  index,
                  'Download',
                  'desktop',
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const renderMobileView = () => {
    return (
      <ReportsMobiveView>
        {reportsData?.map((item, index) => {
          return (
            <div key={item?.id}>
              <div className="row ">
                <div className="col-9">
                  <p
                    className="normal-text-black  mb-2 "
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.report_type.replace('_', ' ')}
                    {displayTooltipByReportType(item)}
                  </p>
                  <h6 className="black-heading-title  mb-2 ">
                    {item?.report_name}
                  </h6>
                  <p className="normal-text-black  mt-2">
                    {item?.report_description}
                  </p>
                </div>
                <div className="col-3 text-right">
                  {displayDownloadReportButton(item, index, null, 'mobile')}
                </div>
              </div>
              <div className="horizontal-line straight-line mt-2" />
            </div>
          );
        })}
      </ReportsMobiveView>
    );
  };

  return (
    <>
      {loader ? (
        <PageLoader
          component="performance-graph"
          color={Theme.orange}
          type="detail"
          width={40}
          height={40}
        />
      ) : isDesktop ? (
        <>
          <DesktopTable>
            {renderDesktopTableHeader()}
            {renderDesktopTable()}
          </DesktopTable>
        </>
      ) : (
        renderMobileView()
      )}

      {reportsData.length >= 1 ? (
        <CommonPagination
          count={count}
          pageNumber={pageNumber}
          handlePageChange={handlePageChange}
        />
      ) : null}

      {reportsData.length === 0 && !loader ? (
        <NoData>No reports data found.</NoData>
      ) : null}
      {openPartnerOpportunitiesReportModal ? (
        <PartnerOpportunitiesReportModal
          modalId="partner-opportunities-modal"
          isOpen={openPartnerOpportunitiesReportModal}
          isDownloading={itemLoader[downloadedReportDetails?.index]}
          selectedProduct={selectedProduct}
          selectedObjective={selectedObjective}
          selectedTitle={selectedTitle}
          description={description}
          callToAction={callToAction}
          setSelectedProduct={setSelectedProduct}
          setSelectedObjective={setSelectedObjective}
          setSelectedTitle={setSelectedTitle}
          setDescription={setDescription}
          setCallToAction={setCallToAction}
          onDownload={() => {
            onDownload(
              downloadedReportDetails?.id,
              downloadedReportDetails?.index,
            );
          }}
          onModalClose={() => {
            resetDropDownFilters();
          }}
          customDateState={{
            startDate: generateCustomDate(customDateState[0].startDate),
            endDate: generateCustomDate(customDateState[0].endDate),
          }}
        />
      ) : null}
    </>
  );
};

export default ReportSectionList;

ReportSectionList.defaultProps = {
  selectedReportTypeValue: '',
  isCustomer: false,
  count: 0,
  pageNumber: 1,
  handlePageChange: () => {},
  generateCustomDate: () => {},
  customerId: '',
};

ReportSectionList.propTypes = {
  selectedReportTypeValue: string,
  pageNumber: number,
  count: number,
  loader: bool.isRequired,
  isCustomer: bool,
  selectedBp: string.isRequired,
  customerId: string,
  reportsData: arrayOf(Array).isRequired,
  handlePageChange: func,
  generateCustomDate: func,
  selectedTimeFrame: shape({}).isRequired,
  selectedMarketplaceOption: string.isRequired,
  customDateState: arrayOf(shape({})).isRequired,
};

const ReportsMobiveView = styled.div`
  .icon-with-border {
    border-radius: 8px;
    border: 1px solid #bfc5d2;
    height: 38px;
    width: 38px;
    padding: 10px;
  }
`;
