import React, { useCallback, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { shape, string } from 'prop-types';
import { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SummaryRestockLimits from './SummaryRestockLimits';
import InventoryMetrics from './InventoryMetrics';
import useFormatNumber from '../../../../../hooks/useFormatNumber';
import Theme from '../../../../../theme/Theme';
import { DropDown } from '../../CompanyPerformance/DropDown';
import { getRestockLimitsData, getSummaryASIN } from '../../../../../api';
import { setCustomerSelectedTab } from '../../../../../store/actions/customerState';
import {
  PATH_CUSTOMER_DETAILS,
  timeFilterOptions,
} from '../../../../../constants';
import {
  WhiteCard,
  DropdownIndicator,
  CustomSingleDateModal,
} from '../../../../../common';

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() - 1);
export default function SummaryInventoryView({ id, selectedMarketplace }) {
  const mounted = useRef(false);

  const isDesktop = useMediaQuery({ minWidth: 767 });

  const history = useHistory();

  const userInfo = useSelector((state) => state.userState.userInfo);
  const isCustomer = userInfo?.role?.includes('Customer');

  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customerState.data);

  const [selectedCustomDateState, setSelectedCustomDateState] =
    useState(currentDate);
  const [customDateState, setCustomDateState] = useState(currentDate);

  const [selectedTimeFrame, setSelectedTimeFrame] = useState({
    value: 'yesterday',
    label: 'Today',
    sub: 'vs Yesterday',
  });

  const [metricsData, setMetricsData] = useState(null);

  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [isCustomDateApply, setIsCustomDateApply] = useState(false);
  const [restockLoader, setRestockLoader] = useState(false);
  const [asinLoader, setAsinLoader] = useState(false);

  const [restockData, setRestockData] = useState({});

  const getASINMetricsData = useCallback(
    (timeframe, marketplace, selectedDate) => {
      setMetricsData(null);
      setAsinLoader(true);
      getSummaryASIN(id, marketplace, timeframe, selectedDate)
        .then((res) => {
          if (mounted.current) {
            if (res?.status === 200) {
              setAsinLoader(false);
              setMetricsData(res?.data?.results[0] || null);
            }
            setAsinLoader(false);
          }
        })
        .catch(() => {
          setAsinLoader(false);
          setMetricsData(null);
        });
    },
    [id],
  );

  const getRestockLimitsDataLocal = useCallback(
    (timeframe, marketplace, selectedDate) => {
      setRestockLoader(true);
      setRestockData({});
      getRestockLimitsData(id, timeframe, marketplace, selectedDate).then(
        (res) => {
          if (mounted.current) {
            if (
              res?.status === 403 ||
              res?.status === 400 ||
              res?.status === 500
            ) {
              setRestockLoader(false);
              setRestockData({});
            }
            if (res?.status === 200) {
              setRestockData(res?.data?.results[0] || {});
              setRestockLoader(false);
            }
            setRestockLoader(false);
          }
        },
      );
    },
    [id],
  );

  useEffect(() => {
    mounted.current = true;
    if (selectedMarketplace !== '') {
      getRestockLimitsDataLocal(
        selectedTimeFrame.value,
        selectedMarketplace,
        customDateState,
      );

      getASINMetricsData(
        selectedTimeFrame.value,
        selectedMarketplace,
        customDateState,
      );
    }

    return () => {
      mounted.current = false;
    };
  }, [
    getRestockLimitsDataLocal,
    getASINMetricsData,
    selectedMarketplace,
    selectedTimeFrame,
    customDateState,
  ]);

  const { Option, SingleValue } = components;

  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: Theme.normal, color: Theme.black }}>
        {props.data.label}
      </span>

      <div style={{ fontSize: Theme.extraSmall, color: Theme.gray40 }}>
        {selectedTimeFrame.value === 'custom' && isCustomDateApply
          ? `${dayjs(customDateState).format('MMM D, YYYY')}`
          : props.data.sub}
      </div>
    </SingleValue>
  );

  const filterOption = (props) => (
    <Option {...props}>
      <div className="pb-2">
        <span style={{ fontSize: '15px', color: '#000000' }}>
          {props.data.label}
        </span>

        <div style={{ fontSize: '12px', color: '#556178' }}>
          {props.data.sub}
        </div>
      </div>
    </Option>
  );

  const getSelectComponents = () => {
    return {
      Option: filterOption,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  const handleDailyFact = (event) => {
    const { value } = event;
    if (value !== selectedTimeFrame.value) {
      setIsCustomDateApply(false);
      if (value !== 'custom') {
        setSelectedTimeFrame(event);
        setCustomDateState(currentDate);
      }
    }
    if (value === 'custom') {
      setShowCustomDateModal(true);
    }
  };

  const applyCustomDate = () => {
    setIsCustomDateApply(true);
    setSelectedTimeFrame({
      value: 'custom',
      label: 'Custom',
      sub: 'Select date',
    });
    setShowCustomDateModal(false);
    setCustomDateState(selectedCustomDateState);
  };

  return (
    <WhiteCard className="mt-2">
      <div className="row">
        <div className="col-md-4 col-12 ">
          <span className="black-heading-title cursor">
            Inventory{' '}
            {isCustomer &&
            (customer?.display_inventory_management ||
              customer?.display_under_over_stocked_inventory) ? (
              <FontAwesomeIcon
                onClick={() => {
                  dispatch(setCustomerSelectedTab('inventoryManagement'));
                  history.push(
                    PATH_CUSTOMER_DETAILS.replace(':id', customer?.id),
                    'inventory',
                  );
                }}
                icon="fa-light fa-square-arrow-up-right"
                style={{
                  fontSize: '16px',
                  color: Theme.orange,
                  marginLeft: '8px',
                }}
              />
            ) : null}
          </span>
        </div>
        <div className="col-md-4   pt-1 pl-0"> </div>
        <div
          className="col-md-4 col-12 pl-md-0  mb-md-0 mt-md-0 mt-2"
          id="BT-summary-inventory-daysfilter"
        >
          {DropDown(
            'BT-InventoryBPTimeframe',
            'days-performance',
            timeFilterOptions,
            timeFilterOptions[0].label,
            getSelectComponents,
            handleDailyFact,
            restockLoader || asinLoader,
            null,
            selectedTimeFrame,
          )}
        </div>
      </div>

      <InventoryMetrics
        isCustomer={isCustomer}
        metricsData={metricsData}
        formatNumber={useFormatNumber}
      />

      <SummaryRestockLimits
        restockData={restockData}
        restockLoader={restockLoader}
        isDesktop={isDesktop}
        formatNumber={useFormatNumber}
        selectedTimeFrame={selectedTimeFrame}
      />
      <CustomSingleDateModal
        id="BT-salesSponsoredDashboard-daterange"
        isOpen={showCustomDateModal}
        selectedDate={selectedCustomDateState}
        onClick={() => {
          setShowCustomDateModal(false);
        }}
        onChange={(item) => {
          setSelectedCustomDateState(item);
        }}
        onApply={() => applyCustomDate()}
        currentDate={currentDate}
      />
    </WhiteCard>
  );
}

SummaryInventoryView.defaultProps = {
  data: {},
};

SummaryInventoryView.propTypes = {
  id: string.isRequired,
  selectedMarketplace: string.isRequired,
  data: shape({}),
};
