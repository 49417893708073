// react imports
import React from 'react';

// local file imports
import { ArrowDownIcon, ArrowUpIcon, UpDowGrayArrow } from '../theme/images';

const useValueWithChangeAndPercent = (
  value,
  changeInValue,
  changeInPercent,
  decimalDigits,
  isCustom,
  prefix,
  suffix,
  className,
) => {
  const showPrefix = prefix || '';
  const showSuffix = suffix || '';
  const showValue = !(
    value === null ||
    value === undefined ||
    isNaN(Number(value))
  );

  const showChangeInValue = !(
    changeInValue === null ||
    changeInValue === undefined ||
    isNaN(Number(changeInValue))
  );

  const showChangeInPercent = !(
    changeInPercent === null ||
    changeInPercent === undefined ||
    isNaN(Number(changeInPercent))
  );

  const changeType =
    changeInValue?.toString().includes('-') ||
    changeInPercent?.toString().includes('-')
      ? '-ve'
      : '+ve';

  let showUpDownArrow = false;
  if (showChangeInValue && showChangeInPercent) {
    showUpDownArrow = !(changeInValue === 0 && changeInPercent === 0);
  } else if (showChangeInValue && !showChangeInPercent) {
    showUpDownArrow = !(showChangeInValue === 0);
  } else if (!showChangeInValue && showChangeInPercent) {
    showUpDownArrow = !(changeInPercent === 0);
  } else {
    showUpDownArrow = false;
  }

  const upDownArrowHTML = showUpDownArrow ? (
    changeType === '+ve' ? (
      <img
        className="green-arrow"
        src={className === 'grey' ? UpDowGrayArrow : ArrowUpIcon}
        alt="arrow-up"
      />
    ) : (
      <img
        className="red-arrow"
        src={className === 'grey' ? UpDowGrayArrow : ArrowDownIcon}
        alt="arrow-down"
      />
    )
  ) : (
    ''
  );

  if (showValue) {
    let formatedMetricsValue = '';
    if (value === 0) {
      formatedMetricsValue = `${showPrefix}${value}${showSuffix}`;
    } else {
      const [splitedValue, decimalValue] = value.toString()?.split('.');

      formatedMetricsValue = `${showPrefix}${splitedValue.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ',',
      )}${
        decimalDigits > 0
          ? decimalValue > 0
            ? `.${decimalValue.slice(0, decimalDigits)}`
            : '.00'
          : ''
      }${showSuffix}`;
    }

    let formattedChangeInValue = '';
    if (showChangeInValue) {
      const spliitedChangeInValue = changeInValue.toString().includes('-')
        ? changeInValue.toString().split('-')[1]
        : changeInValue;
      if (spliitedChangeInValue === 0) {
        formattedChangeInValue = `${showPrefix}${changeInValue}${showSuffix}`;
      } else {
        const [splitedValue, decimalValue] = spliitedChangeInValue
          .toString()
          ?.split('.');

        formattedChangeInValue = `${showPrefix}${splitedValue.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ',',
        )}${
          decimalDigits > 0
            ? decimalValue > 0
              ? `.${decimalValue.slice(0, decimalDigits)}`
              : '.00'
            : ''
        }${showSuffix}`;
      }
    }

    const formattedChangeInPercent = showChangeInPercent
      ? changeInPercent === 0
        ? '0%'
        : changeInPercent.toString().includes('-')
        ? `${Number(changeInPercent.toString().split('-')[1]).toFixed(2)}%`
        : `${Number(changeInPercent.toString()).toFixed(2)}%`
      : 'N/A';

    return (
      <div data-cy="BT-numberFormatter">
        <span data-cy="BT-formattedValue">{formatedMetricsValue}</span>
        {isCustom ? null : (
          <div
            className={`${
              changeType === '+ve' ? 'increase-rate' : 'decrease-rate'
            } ${className || ''}`}
          >
            {upDownArrowHTML}
            <span data-cy="BT-formattedChangeValue">
              {formattedChangeInValue}{' '}
            </span>
            {showChangeInValue ? (
              <span data-cy="BT-formattedPercentValue">
                ({formattedChangeInPercent})
              </span>
            ) : (
              <span data-cy="BT-formattedPercentValue">
                {formattedChangeInPercent}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }

  return 'N/A';
};

export default useValueWithChangeAndPercent;
