import axiosInstance from '../axios';
import { API_PENDING_APPROVALS, API_TRANSACTION_DATA } from '../constants';

export async function getPendingApprovalRequests(
  orderBy,
  searchBy,
  pageNumber,
) {
  let params = {
    'order-by': orderBy,
    page: pageNumber,
  };
  if (searchBy !== '' && searchBy !== null) {
    params = {
      ...params,
      q: searchBy,
    };
  }

  const result = await axiosInstance
    .get(API_PENDING_APPROVALS, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function archivedPendingApprovalRequest(id) {
  const result = await axiosInstance
    .patch(`${API_TRANSACTION_DATA + id}/`, { archive: true })
    .then(() => {
      return true;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
