/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';

import Select, { components } from 'react-select';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import Theme from '../theme/Theme';
import CheckBox from './CheckBox';
import CustomDropDown from './CustomDropDown';
import DropDownIndicator from './DropDownIndicator';

export default function MultiSelectDropdown({
  options,
  label,
  value,
  multiSelectedValues,
  setMultiSelectedValues,
  handleMultiSelectChange,
  isDisabled = false,
  handleOnMenuClose,
}) {
  const { Option, ValueContainer } = components;

  const selectOption = (dataProps) => {
    return (
      <div className="option-with-checkbox">
        <Option {...dataProps}>
          <CheckBox>
            <label
              htmlFor={dataProps.value}
              className="check-container customer-pannel"
            >
              <input
                type="checkbox"
                name={dataProps?.value}
                id={dataProps?.value}
                checked={dataProps?.isSelected}
                disabled={multiSelectedValues?.[value]?.length === 1}
              />
              <span className="checkmark" />
              {dataProps?.label}
            </label>
          </CheckBox>
        </Option>
      </div>
    );
  };

  const valueContainer = useCallback(
    ({ children, getValue, ...dataProps }) => {
      const updatedValue = [...children];
      const { length } = getValue();
      switch (length) {
        case 0:
          updatedValue[0] = `Select ${label}`;
          break;
        case 1:
          updatedValue[0] = dataProps?.selectProps?.value?.[0]?.label;
          break;
        default:
          updatedValue[0] = `${
            dataProps?.selectProps?.value?.[0]?.label === 'All' ||
            dataProps?.selectProps?.value?.[0]?.value === 'all'
              ? `All ${label}`
              : `Multiple ${label}`
          } `;
          break;
      }
      return (
        <ValueContainer {...dataProps}>
          <span>{updatedValue}</span>
        </ValueContainer>
      );
    },
    [label, value],
  );

  const getAllComponents = () => {
    return {
      Option: selectOption,
      ValueContainer: valueContainer,
      DropDownIndicator,
    };
  };

  const handleChange = (event, values) => {
    if (values.action === 'select-option') {
      if (values.option?.value === 'all')
        setMultiSelectedValues({
          ...multiSelectedValues,
          [value]: [{ label: 'All', value: 'all' }, ...options],
        });
      if (values.option?.value !== 'all')
        setMultiSelectedValues((prevState) => ({
          ...prevState,
          [value]:
            event?.length === options?.length
              ? [{ label: 'All', value: 'all' }, ...event]
              : event,
        }));
    }
    if (values.action === 'deselect-option') {
      if (values.option?.value === 'all')
        setMultiSelectedValues({
          ...multiSelectedValues,
          [value]: [options[0]],
        });
      if (values.option?.value !== 'all')
        setMultiSelectedValues({
          ...multiSelectedValues,
          [value]: event.filter((op) => op.value !== 'all'),
        });
    }
  };

  const renderValue = (type) => {
    // if - for default 'all' option should be selected.
    if (
      type === 'projects' &&
      options?.length > 1 &&
      options?.length === multiSelectedValues?.projects?.length
    ) {
      return [{ label: 'All', value: 'all' }, ...multiSelectedValues?.[value]];
    }
    return multiSelectedValues?.[value];
  };
  return (
    <>
      <CustomDropDown>
        <Select
          classNamePrefix="react-select"
          className={isDisabled ? 'disabled' : ''}
          options={
            options?.length > 1
              ? [{ label: 'All', value: 'all' }, ...options]
              : options?.length === 1
              ? options
              : []
          }
          isMulti
          value={renderValue(value)}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={getAllComponents()}
          styles={{
            option: ({ isSelected }) => {
              return {
                backgroundColor: isSelected ? 'white' : null,
                margin: ' 15px',
              };
            },
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.white,
              primary: Theme.orange,
              primary50: Theme.white,
            },
          })}
          isClearable={false}
          isSearchable={false}
          placeholder={`Select ${label}`}
          onChange={(event, values) => {
            handleChange(event, values);
            handleMultiSelectChange(event, value, values);
          }}
          isOptionDisabled={(op) =>
            multiSelectedValues?.[value]?.length === 1
              ? op.value === multiSelectedValues?.[value]?.[0]?.value
              : false
          }
          name={value}
          onMenuClose={() => handleOnMenuClose()}
        />
      </CustomDropDown>
    </>
  );
}

MultiSelectDropdown.defaultProps = {
  isDisabled: false,
  handleMultiSelectChange: () => {},
  handleOnMenuClose: () => {},
};

MultiSelectDropdown.propTypes = {
  isDisabled: bool,
  options: arrayOf(shape({})).isRequired,
  value: string.isRequired,
  label: string.isRequired,
  multiSelectedValues: arrayOf(shape({})).isRequired,
  setMultiSelectedValues: func.isRequired,
  handleMultiSelectChange: func,
  handleOnMenuClose: func,
};
