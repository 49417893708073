// React imports
import React from 'react';

// Third party imports
import { useMediaQuery } from 'react-responsive';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

// Local file imports
import StrandedInventoryMobileView from './StrandedInventoryMobileView';
import { InventoryTableWrapper } from './InventoryStyle';
import { CommonPagination, Table, WhiteCard } from '../../../../common';
import {
  inventoryNoDataMessage,
  strandedInventoryTableheader,
} from '../../../../constants';

const StrandedInventory = ({
  // boolean
  loader,
  // number
  count,
  pageNumber,
  // string
  orderByApi,
  // object
  orderBy,
  // array
  strandedInventoryData,
  // function
  onClickOrder,
  formatNumber,
  onHandlePageChange,
  displayNoDataMessage,
  displayPerformanceLoader,
  displayDownloadReportLoader,
  displayCompanyNameLogoAndBGS,
  redirectingToCustomerDetailview,
  displayUpDownArrowforTableHeader,
  displayMetricsChangeValueWithArrow,
}) => {
  const isTablet = useMediaQuery({ minWidth: 768 });

  // function - display inventory table header
  const renderTableHeader = () => {
    return (
      <>
        <tr>
          {strandedInventoryTableheader.map((item) => {
            return (
              <th
                id={item?.key}
                key={item?.key}
                width={item.width}
                onClick={() => {
                  if (item?.key !== 'customer') onClickOrder(item?.key);
                }}
                className={`product-header ${
                  item?.key !== 'customer' ? 'cursor' : ''
                } `}
              >
                {item?.label}
                {item?.key !== 'customer'
                  ? displayUpDownArrowforTableHeader(
                      item?.key,
                      orderBy,
                      orderByApi,
                    )
                  : null}
              </th>
            );
          })}
        </tr>
      </>
    );
  };

  // function - display inventory table data
  const renderInventoryData = (item) => {
    return (
      <>
        <tr
          className="cursor"
          onClick={() => {
            redirectingToCustomerDetailview(item?.customer_id);
          }}
          key={item?.customer_id}
        >
          <td className="product-body">{displayCompanyNameLogoAndBGS(item)}</td>
          <td className="product-body">
            {formatNumber(item?.available, '', '', true)}
          </td>
          <td className="product-body">
            {displayMetricsChangeValueWithArrow(
              item?.change,
              formatNumber,
              '',
              '',
            )}
          </td>
          <td className="product-body">
            {displayMetricsChangeValueWithArrow(
              item?.percent_change,
              formatNumber,
              '',
              '%',
            )}
          </td>
        </tr>
      </>
    );
  };

  // function - display inventory desktop view
  const renderStandedInventoryDesktopView = () => {
    return (
      <>
        <InventoryTableWrapper>
          <div className="straight-line horizontal-line spacing d-md-block d-none" />
          <Table className="inventory-table">
            <thead className={loader ? 'disabled' : ''}>
              {renderTableHeader()}
            </thead>
            {strandedInventoryData?.length ? (
              <tbody>
                {strandedInventoryData?.map((item) => {
                  return renderInventoryData(item);
                })}
              </tbody>
            ) : null}
          </Table>
          {loader ? displayPerformanceLoader() : null}
          {!loader && !strandedInventoryData?.length
            ? displayNoDataMessage(inventoryNoDataMessage?.stranded)
            : null}
        </InventoryTableWrapper>
      </>
    );
  };

  // function - display inventory mobile view
  const renderStandedInventoryMobileView = () => {
    return (
      <>
        {loader ? (
          displayPerformanceLoader()
        ) : strandedInventoryData?.length ? (
          <StrandedInventoryMobileView
            loader={loader}
            strandedInventoryData={strandedInventoryData}
            formatNumber={formatNumber}
            displayCompanyNameLogoAndBGS={displayCompanyNameLogoAndBGS}
            redirectingToCustomerDetailview={redirectingToCustomerDetailview}
            displayMetricsChangeValueWithArrow={
              displayMetricsChangeValueWithArrow
            }
          />
        ) : (
          displayNoDataMessage('No Stranded Inventory Data Found')
        )}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      <WhiteCard className="mb-3">
        <div className="row mb-3">
          <div className="col-lg-10 col-md-9 col-12 black-heading-title mb-3">
            Stranded Inventory
          </div>
          <div
            className={`col-md-2 col-sm1-12 pr-2 text-right pt-2 ${
              loader ? 'disabled' : ''
            }`}
          >
            {displayDownloadReportLoader('stranded-restock')}
          </div>
        </div>
        {isTablet
          ? renderStandedInventoryDesktopView()
          : renderStandedInventoryMobileView()}
        {count > 10 ? (
          <>
            <div className="straight-line horizontal-line mt-3" />
            <CommonPagination
              count={count}
              pageNumber={pageNumber}
              handlePageChange={onHandlePageChange}
            />
          </>
        ) : null}
      </WhiteCard>
    </>
  );
};

export default StrandedInventory;

StrandedInventory.defaultProps = {
  // boolean
  loader: false,
  // number
  count: 0,
  pageNumber: 1,
  // string
  orderByApi: '',
  // object
  orderBy: {},
  // array
  strandedInventoryData: [],
  // function
  onClickOrder: () => {},
  formatNumber: () => {},
  onHandlePageChange: () => {},
  displayNoDataMessage: () => {},
  displayPerformanceLoader: () => {},
  displayDownloadReportLoader: () => {},
  displayCompanyNameLogoAndBGS: () => {},
  redirectingToCustomerDetailview: () => {},
  displayUpDownArrowforTableHeader: () => {},
  displayMetricsChangeValueWithArrow: () => {},
};

StrandedInventory.propTypes = {
  // boolean
  loader: bool,
  // number
  count: number,
  pageNumber: number,
  // string
  orderByApi: string,
  // object
  orderBy: shape({}),
  // array
  strandedInventoryData: arrayOf(shape({})),
  // function
  onClickOrder: func,
  formatNumber: func,
  onHandlePageChange: func,
  displayNoDataMessage: func,
  displayPerformanceLoader: func,
  displayDownloadReportLoader: func,
  displayCompanyNameLogoAndBGS: func,
  redirectingToCustomerDetailview: func,
  displayUpDownArrowforTableHeader: func,
  displayMetricsChangeValueWithArrow: func,
};
