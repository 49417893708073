import axiosInstance from '../axios';

import {
  API_DSP_AUDIENCE_QUERY,
  API_DSP_AUDIENCE,
  API_DSP_ADVERTISER_DETAIL,
  API_DSP_ADVERTISER_DASHBOARD,
  API_BP_LIST_DSP_AUDIENCE_DASHBOARD,
  CUSTOM,
} from '../constants';

// Async function for triple overlap analysis
export async function getQueries() {
  const result = await axiosInstance
    .get(`${API_DSP_AUDIENCE_QUERY}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function createNewQuery(data) {
  const result = await axiosInstance
    .post(`${API_DSP_AUDIENCE_QUERY}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateQuery(id, data) {
  const result = await axiosInstance
    .patch(`${API_DSP_AUDIENCE_QUERY}${id}/`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function deleteQuery(id) {
  const result = await axiosInstance
    .delete(`${API_DSP_AUDIENCE_QUERY}${id}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDSPAudienceDefaultData(id) {
  const params = { customer: id, marketplace: 'amazon.com' };
  const result = await axiosInstance
    .get(`${API_DSP_AUDIENCE}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getActiveTabs(id) {
  const params = { customer: id };
  const result = await axiosInstance
    .get(`${API_DSP_AUDIENCE}active-tabs/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDSPAudienceData(
  id,
  page,
  sortBy,
  status,
  showAMCAudienceLookalike,
) {
  const params = {
    dsp_audience: id,
    page,
    'order-by': sortBy?.value,
    status: status?.value,
    amc_audience_lookalike: showAMCAudienceLookalike ? true : null,
  };
  if (status?.value === 'all') delete params.status;

  const result = await axiosInstance
    .get(`${API_DSP_ADVERTISER_DETAIL}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function saveDSPAudience(data) {
  const result = await axiosInstance
    .post(`${API_DSP_ADVERTISER_DETAIL}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDSPAudienceDashboardData(data) {
  const params = {
    dsp_ad_manager: 'all',
    page: data?.page,
    'order-by': data?.orderBy,
    customer: 'all',
    marketplace: 'all',
    timeframe: data?.timeframe,
    status: data?.status,
    download_report: data?.downloadFlag,
    amc_audience_lookalike: data?.showAMCAudienceLookalike ? true : null,
  };
  if (params?.status === 'all') delete params?.status;
  if (params?.timeframe === CUSTOM) {
    params.start_date = data?.start_date;
    params.end_date = data?.end_date;
  }
  if (data?.marketplace) {
    params.marketplace = data?.marketplace?.value;
  }

  if (data?.dsp_ad_manager) {
    const list = data?.dsp_ad_manager?.map((item) => item?.value);

    if (list?.length) {
      if (list.includes('all')) {
        params.dsp_ad_manager = 'all';
      } else {
        params.dsp_ad_manager = list?.toString();
      }
    }
  }
  if (data?.customer) {
    const list = data?.customer?.map((item) => item?.value);

    if (list?.length) {
      if (list.includes('all')) {
        params.customer = 'all';
      } else {
        params.customer = list?.toString();
      }
    }
  }

  const result = await axiosInstance
    .get(`${API_DSP_ADVERTISER_DASHBOARD}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getBpListInAMCAudienceDashboard() {
  const result = await axiosInstance
    .get(`${API_BP_LIST_DSP_AUDIENCE_DASHBOARD}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
