import dayjs from 'dayjs';

const BindSponsoredAdGoalsGraphData = (response) => {
  const finalData = [];
  if (response?.daily_facts?.current?.length) {
    response.daily_facts.current.forEach((item) => {
      const tempData = {};
      const currentReportDate = dayjs(item?.revised_date).format('MMM D YYYY');
      tempData.date = currentReportDate;
      tempData.adSpendCurrent = item.ad_spend;
      tempData.adSpendGoals = item?.goal_ad_spend;
      tempData.adRoasCurrent = item.roas;
      tempData.adRoasGoals = item?.goal_roas;
      tempData.adCosCurrent = item.acos;
      tempData.adCosGoals = item?.goal_acos;

      tempData.adSpendCurrentLabel =
        item.sponsored_ad_spend !== null ? item.ad_spend.toFixed(2) : '0';
      tempData.adSpendGoalsLabel =
        item?.goal_ad_spend !== null ? item?.goal_ad_spend?.toFixed(2) : '0';
      tempData.adRoasCurrentLabel =
        item.roas !== null ? item.roas.toFixed(2) : '0.00';
      tempData.adRoasGoalsLabel =
        item?.goal_roas !== null ? item?.goal_roas?.toFixed(2) : '0.00';
      tempData.adCosCurrentLabel =
        item.acos !== null ? item.acos.toFixed(2) : '0.00';
      tempData.adCosGoalsLabel =
        item?.goal_acos !== null ? item?.goal_acos?.toFixed(2) : '0.00';

      // to add the dotted line. we have to check null matrix and add the dummy number like 8

      // tempData.revenueTotalDashLength = item.revenue === null ? 8 : null;
      // tempData.adSpendDashLength =
      //   item.sponsored_ad_spend === null ? 8 : null;
      finalData.push(tempData);
    });
  }
  return finalData;
};

export default BindSponsoredAdGoalsGraphData;
