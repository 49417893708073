/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';

import Select from 'react-select';
import { Collapse } from 'react-collapse';
import { shape, func, bool, string } from 'prop-types';

import SellerFeeStructure from './SellerFeeStructure';
import VendorFeeStructure from './VendorFeeStructure';
import CombinedRevShare from '../StatementOfWork/CombinedRevShare';
import { CurrencyIcon } from '../../../../theme/images';

import {
  accountTypeOptions,
  feeStructureContainerDetails,
} from '../../../../constants';
import { Button, CheckBox, ContractInputSelect } from '../../../../common';

export default function FeeStructureContainer({
  formData,
  setFormData,
  executeScroll,
  setOpenCollapse,
  openCollapse,
  renderCollapseBtnErrorHtml,
  loader,
  nextStep,
  DropdownIndicator,
  marketPlacesOptions,
  setUpdatedFormData,
  updatedFormData,
  setAdditionalMarketplaces,
  revShareOptions,
  showFooter,
  sectionError,
  setSectionError,
  feeStructureErrors,
  setFeeStructureErrors,
  getMonthlyServices,
  showRightTick,
  getFeeStructureDetails,
  manageErrorCount,
  selectedCurrency,
  disableCombineRevShare,
  contractAddendumDetails,
  checkAddendumData,
  addendumDetails,
  displayMarketplaceName,
}) {
  const [vendorSameAsSeller, setVendorSameAsSeller] = useState(
    formData?.fee_structure?.vendor?.vendor_same_as_seller || false,
  );
  const [combinedRevShareFlag, setCombinedRevShareFlag] = useState(false);

  const clearError = (event, key) => {
    if (event && event.value) {
      if (
        (key === 'seller_type' && !(formData && formData[key]?.value)) ||
        !(formData && formData[key])
      ) {
        setSectionError({
          ...sectionError,
          feeStructure: sectionError.feeStructure
            ? sectionError.feeStructure - 1
            : 0,
        });
      }
    }
  };

  const handleChange = (event, key) => {
    showFooter(true);
    if (key === 'primary_marketplace') {
      setAdditionalMarketplaces(
        marketPlacesOptions.filter((op) => op.value !== event.value),
      );
      setFormData({ ...formData, [key]: event?.value });
      setUpdatedFormData({
        ...updatedFormData,
        [key]: event?.value,
      });
    } else if (key === 'combined_rev_share') {
      setFormData({ ...formData, combined_rev_share: event.target.checked });
      setUpdatedFormData({
        ...updatedFormData,
        combined_rev_share: event.target.checked,
      });
      setCombinedRevShareFlag(event.target.checked);
      setFeeStructureErrors({});
    } else if (key === 'seller_type') {
      if (
        formData?.fee_structure?.vendor?.vendor_same_as_seller &&
        formData.seller_type?.value !== 'Hybrid' &&
        event.value !== 'Hybrid'
      )
        setVendorSameAsSeller(false);

      setFormData({
        ...formData,
        seller_type: { value: event?.value, label: event?.value },
        vendor_same_as_seller: false,
      });
      getFeeStructureDetails(event.label, {
        ...formData,
        seller_type: { value: event?.value, label: event?.value },
        vendor_same_as_seller: false,
      });
      setUpdatedFormData({
        ...updatedFormData,
        seller_type: event?.value,
      });
      setFeeStructureErrors({});
      getMonthlyServices(event?.value);
    } else {
      setFormData({ ...formData, [key]: event?.value });
      setUpdatedFormData({
        ...updatedFormData,
        [key]: event?.value,
      });
    }
    clearError(event, key);
  };

  const generateDropdown = (item) => {
    return (
      <Select
        classNamePrefix={
          (!(formData && formData[item.key]) && item.isMandatory) ||
          (item.key === 'seller_type' &&
            !(formData && formData[item.key]?.value) &&
            item.isMandatory)
            ? 'react-select  form-control-error'
            : 'react-select'
        }
        styles={{
          control: (base, state) => ({
            ...base,
            background:
              (!(formData && formData[item.key]) && item.isMandatory) ||
              (item.key === 'seller_type' &&
                !(formData && formData[item.key]?.value) &&
                item.isMandatory)
                ? '#FBF2F2'
                : '#F4F6FC',
            // match with the menu
            // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
            // Overwrittes the different states of border
            borderColor:
              (!(formData && formData[item.key]) && item.isMandatory) ||
              (item.key === 'seller_type' &&
                !(formData && formData[item.key]?.value) &&
                item.isMandatory)
                ? '#D63649'
                : '#D5D8E1',

            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
              // Overwrittes the different states of border
              boxShadow: state.isFocused ? null : null,
              outlineColor: state.isFocused ? null : null,
            },
            option: (styles, { isDisabled }) => {
              return {
                ...styles,
                color: isDisabled ? '556178' : '556178',
              };
            },
          }),
        }}
        placeholder={item.placeholder || 'Select'}
        defaultValue={
          item.key === 'primary_marketplace'
            ? formData.primary_marketplace?.name
              ? {
                  value: formData.primary_marketplace.name,
                  label: displayMarketplaceName(
                    formData.primary_marketplace.name,
                    'primary',
                  ),
                }
              : formData?.primary_marketplace
              ? {
                  value: formData.primary_marketplace,
                  label: displayMarketplaceName(
                    formData.primary_marketplace,
                    'primary',
                  ),
                }
              : null
            : formData[item.key] && formData[item.key].label
            ? {
                value: formData[item.key].label,
                label: formData[item.key].value,
              }
            : null
        }
        options={
          item.key === 'primary_marketplace'
            ? marketPlacesOptions
            : accountTypeOptions
        }
        name={item.key}
        components={{ DropdownIndicator }}
        onChange={(event) => handleChange(event, item.key)}
        isDisabled={contractAddendumDetails.notActive}
      />
    );
  };
  const displayErrorCount = () => {
    if (formData?.seller_type?.value === 'Seller') {
      return sectionError.feeStructure + sectionError?.seller?.feeType;
    }
    if (formData?.seller_type?.value === 'Vendor') {
      return sectionError.feeStructure + sectionError?.vendor?.feeType;
    }
    if (formData?.seller_type?.value === 'Hybrid') {
      return (
        sectionError.feeStructure +
        sectionError?.seller?.feeType +
        sectionError?.vendor?.feeType
      );
    }
    return sectionError.feeStructure;
  };

  const showCombineRevShareBox = () => {
    return (
      <CheckBox>
        <label
          className={
            disableCombineRevShare
              ? 'isDisabled check-container checkboxes-std-services customer-pannel'
              : 'check-container checkboxes-std-services customer-pannel'
          }
          htmlFor="combineRevShare"
        >
          Calculate the Rev Share by combining the Seller Account Sales & Vendor
          Account Sales
          <input
            type="checkbox"
            name="combineRevShare"
            id="combineRevShare"
            onChange={(event) => handleChange(event, 'combined_rev_share')}
            checked={formData?.combined_rev_share || false}
            disabled={disableCombineRevShare}
          />
          <span className="checkmark" />
        </label>
      </CheckBox>
    );
  };

  return (
    <>
      <div className="straight-line horizontal-line  " />
      <div
        className="collapse-btn "
        role="presentation"
        type="button"
        onClick={() => {
          if (contractAddendumDetails?.notActive)
            executeScroll('draft-addendum');
          else executeScroll('statement');
          setOpenCollapse({
            feeStructure: !openCollapse.feeStructure,
          });
        }}
      >
        <img className="service-agre" src={CurrencyIcon} alt="pdf" />
        <h4
          className={
            displayErrorCount()
              ? 'sendar-details error-container'
              : 'sendar-details '
          }
        >
          Fee Structure
          {renderCollapseBtnErrorHtml(
            false,
            displayErrorCount(),
            openCollapse.feeStructure,
            'feeStructure',
          )}
        </h4>
        <div className="clear-fix" />
      </div>
      <Collapse isOpened={openCollapse.feeStructure}>
        {loader ? null : (
          <ul className="collapse-inner">
            <li>
              {feeStructureContainerDetails?.map((item) => (
                <ContractInputSelect
                  className={item.key !== 'primary_marketplace' ? 'mt-3' : ''}
                  key={item.key}
                >
                  <label htmlFor={item.key}>{item.label}</label>
                  {generateDropdown(item)}
                </ContractInputSelect>
              ))}
            </li>
            <li>
              {formData?.seller_type?.label === 'Seller' ? (
                <SellerFeeStructure
                  setFormData={setFormData}
                  updatedFormData={updatedFormData}
                  setUpdatedFormData={setUpdatedFormData}
                  formData={formData}
                  DropdownIndicator={DropdownIndicator}
                  revShareOptions={revShareOptions}
                  section="seller"
                  showFooter={showFooter}
                  setSectionError={setSectionError}
                  sectionError={sectionError}
                  feeStructureErrors={feeStructureErrors}
                  setFeeStructureErrors={setFeeStructureErrors}
                  vendorSameAsSeller={vendorSameAsSeller}
                  selectedCurrency={selectedCurrency}
                  combinedRevShareFlag={combinedRevShareFlag}
                  contractAddendumDetails={contractAddendumDetails}
                  checkAddendumData={checkAddendumData}
                  addendumDetails={addendumDetails}
                />
              ) : formData?.seller_type?.label === 'Vendor' ? (
                <VendorFeeStructure
                  setFormData={setFormData}
                  updatedFormData={updatedFormData}
                  setUpdatedFormData={setUpdatedFormData}
                  formData={formData}
                  DropdownIndicator={DropdownIndicator}
                  revShareOptions={revShareOptions}
                  section="vendor"
                  showFooter={showFooter}
                  setSectionError={setSectionError}
                  sectionError={sectionError}
                  feeStructureErrors={feeStructureErrors}
                  setFeeStructureErrors={setFeeStructureErrors}
                  vendorSameAsSeller={vendorSameAsSeller}
                  setVendorSameAsSeller={setVendorSameAsSeller}
                  manageErrorCount={manageErrorCount}
                  selectedCurrency={selectedCurrency}
                  contractAddendumDetails={contractAddendumDetails}
                  checkAddendumData={checkAddendumData}
                  addendumDetails={addendumDetails}
                />
              ) : formData?.seller_type?.label === 'Hybrid' ? (
                <>
                  {formData?.seller_type?.label === 'Hybrid' ? (
                    <>{showCombineRevShareBox()}</>
                  ) : null}

                  <SellerFeeStructure
                    setFormData={setFormData}
                    updatedFormData={updatedFormData}
                    setUpdatedFormData={setUpdatedFormData}
                    formData={formData}
                    DropdownIndicator={DropdownIndicator}
                    revShareOptions={revShareOptions}
                    section="seller"
                    showFooter={showFooter}
                    setSectionError={setSectionError}
                    sectionError={sectionError}
                    feeStructureErrors={feeStructureErrors}
                    setFeeStructureErrors={setFeeStructureErrors}
                    vendorSameAsSeller={vendorSameAsSeller}
                    selectedCurrency={selectedCurrency}
                    combinedRevShareFlag={combinedRevShareFlag}
                    contractAddendumDetails={contractAddendumDetails}
                    checkAddendumData={checkAddendumData}
                    addendumDetails={addendumDetails}
                  />
                  <VendorFeeStructure
                    setFormData={setFormData}
                    updatedFormData={updatedFormData}
                    setUpdatedFormData={setUpdatedFormData}
                    formData={formData}
                    DropdownIndicator={DropdownIndicator}
                    revShareOptions={revShareOptions}
                    section="vendor"
                    showFooter={showFooter}
                    setSectionError={setSectionError}
                    sectionError={sectionError}
                    feeStructureErrors={feeStructureErrors}
                    setFeeStructureErrors={setFeeStructureErrors}
                    vendorSameAsSeller={vendorSameAsSeller}
                    setVendorSameAsSeller={setVendorSameAsSeller}
                    manageErrorCount={manageErrorCount}
                    selectedCurrency={selectedCurrency}
                    contractAddendumDetails={contractAddendumDetails}
                    checkAddendumData={checkAddendumData}
                    addendumDetails={addendumDetails}
                  />
                  {formData?.combined_rev_share ? (
                    <CombinedRevShare
                      setFormData={setFormData}
                      updatedFormData={updatedFormData}
                      setUpdatedFormData={setUpdatedFormData}
                      formData={formData}
                      DropdownIndicator={DropdownIndicator}
                      revShareOptions={revShareOptions}
                      showFooter={showFooter}
                      setSectionError={setSectionError}
                      sectionError={sectionError}
                      feeStructureErrors={feeStructureErrors}
                      setFeeStructureErrors={setFeeStructureErrors}
                      vendorSameAsSeller={vendorSameAsSeller}
                      setVendorSameAsSeller={setVendorSameAsSeller}
                      manageErrorCount={manageErrorCount}
                      selectedCurrency={selectedCurrency}
                      section="seller"
                      contractAddendumDetails={contractAddendumDetails}
                      checkAddendumData={checkAddendumData}
                    />
                  ) : null}
                </>
              ) : null}
            </li>

            <li>
              <Button
                className="btn-primary sidepanel btn-next-section mt-2 mb-3 w-100"
                disabled={!showRightTick('feeStructure')}
                onClick={() => nextStep('statement')}
              >
                Proceed to Next Section
              </Button>
            </li>
          </ul>
        )}
      </Collapse>
    </>
  );
}

FeeStructureContainer.defaultProps = {
  setOpenCollapse: () => {},
  executeScroll: () => {},
  nextStep: () => {},
  renderCollapseBtnErrorHtml: () => {},
  setUpdatedFormData: () => {},
  setFormData: () => {},
  setAdditionalMarketplaces: () => {},
  formData: {},
  updatedFormData: {},
  openCollapse: {},
  DropdownIndicator: {},
  marketPlacesOptions: {},
  revShareOptions: {},
  loader: false,
  showFooter: false,
  sectionError: {},
  setSectionError: () => {},
  feeStructureErrors: {},
  setFeeStructureErrors: () => {},
  getMonthlyServices: () => {},
  showRightTick: () => {},
  getFeeStructureDetails: () => {},
  manageErrorCount: () => {},
  selectedCurrency: '$ (USD)',
  disableCombineRevShare: false,
  contractAddendumDetails: {},
  checkAddendumData: false,
  addendumDetails: {},
  displayMarketplaceName: () => {},
};

FeeStructureContainer.propTypes = {
  executeScroll: func,
  setOpenCollapse: func,
  nextStep: func,
  renderCollapseBtnErrorHtml: func,
  formData: shape({
    seller_type: string,
    fee_type: string,
  }),
  openCollapse: shape({
    feeStructure: bool,
  }),
  loader: bool,
  showFooter: bool,
  DropdownIndicator: func,
  setUpdatedFormData: func,
  setFormData: func,
  updatedFormData: func,
  marketPlacesOptions: func,
  setAdditionalMarketplaces: func,
  revShareOptions: func,
  sectionError: shape({
    feeStructure: bool,
  }),
  setSectionError: func,
  feeStructureErrors: shape({}),
  setFeeStructureErrors: func,
  getMonthlyServices: func,
  showRightTick: func,
  getFeeStructureDetails: func,
  manageErrorCount: func,
  selectedCurrency: string,
  disableCombineRevShare: bool,
  contractAddendumDetails: shape({}),
  checkAddendumData: bool,
  addendumDetails: shape({}),
  displayMarketplaceName: func,
};
