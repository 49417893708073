import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Collapse } from 'react-collapse';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, shape } from 'prop-types';

import {
  CommonPagination,
  NoRecordFound,
  PageLoader,
  Table,
} from '../../../common';
import Theme from '../../../theme/Theme';
import {
  PATH_AGREEMENT,
  PATH_CUSTOMER_DETAILS,
  metricsCurrency,
} from '../../../constants';
import { AccountStatus } from '../../../theme/Global';
import { setCustomerSelectedTab } from '../../../store/actions/customerState';
import ChildCustomerListView from './ChildCustomerListView';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  AuthorizedSC,
  AuthorizedVC,
  CheckFileIcon,
  CountDayClock,
  EditFileIcon,
  FileIcon,
  SortUp,
  UnauthorizedSC,
  UnauthorizedVC,
  UpDowGrayArrow,
} from '../../../theme/images';
import CustomerListTablet from './CustomerListTablet';

function CustomerListRightSidePanel({
  totalPageCount,
  pageNumber,
  orderByFlag,
  isCustomTimeFrame,
  showContractDetails,
  showPerformance,
  showAdPerformance,
  showDspAdPerformance,
  params,
  isLoading,
  selectedValue,
  selectedColumns,
  customerListData,
  getCustomer,
  handlePageChange,
  handleSortFilters,
  redirectIfContractExists,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const selectedAccountTypes = JSON.parse(
    localStorage.getItem('filters'),
  )?.customer_account_type;

  const [showContracts, setShowContracts] = useState(false);
  const [showChildContracts, setShowChildContracts] = useState({ id: null });
  const isSeller = selectedAccountTypes?.includes('Seller');
  const isVendor = selectedAccountTypes?.includes('Vendor');
  const isHybrid =
    (isSeller && isVendor) || selectedAccountTypes?.includes('Hybrid');
  const colSpanCount =
    showAdPerformance && (isHybrid || selectedAccountTypes?.length === 0)
      ? selectedColumns?.length * 2 + 1
      : selectedColumns?.length + 1;

  const countDays = (item) => {
    const date1 = new Date();
    const date2 = new Date(item);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const generateLogoCompanyNameAndGs = (item, name, bgs) => {
    return (
      <>
        <div
          className="company-info-details customer-details"
          style={{ display: 'inline-block' }}
        >
          <div className="company-name">{name}</div>
          <div className="user-name">
            {bgs.first_name} {bgs.last_name}
            <div className="child-user-name">
              {item?.child_customer?.length
                ? `${item?.child_customer?.length} Child Brands`
                : item?.account_type === 'parent'
                ? '0 Child Brands'
                : ''}
            </div>
          </div>
        </div>
      </>
    );
  };

  // Return class name according to Account status
  const returnAccountStatusClass = (statusClass) => {
    switch (statusClass) {
      case 'pending':
      case 'pending account setup':
        return 'pending';
      case 'pending cancellation':
        return 'pending-cancellation';
      case 'active':
        return 'active';
      case 'inactive':
        return 'inactive';
      case 'out of contract':
        return 'outOfContract';
      case 'in collections':
        return 'inCollections';
      case 'at risk':
        return 'atRisk';
      default:
        return '';
    }
  };

  const showSellerVendorCentral = (
    centralArray,
    sellerArray,
    vendorArray,
    centralMarketplacesArray,
    item,
    // eslint-disable-next-line consistent-return
  ) => {
    const toolTip = (param, message, classStatus) => {
      const displayMarketplaces = () => {
        return `
        ${
          message?.length
            ? `Unauthorized marketplaces of ${param} :`
            : `BP has successfully authorized ${param} data access.`
        }
            <ul style="padding: 0px 15px; margin: 9px 4px">
              ${message?.map(
                (item1) => `<li style="padding: 3px 0">${item1}</li>`,
              )}
              </ul>`.replaceAll(',', '');
      };

      return (
        <>
          <img
            src={classStatus}
            style={{
              marginRight: param === 'Sc' ? '4px' : '0',
              verticalAlign: 'middle',
            }}
            alt={classStatus}
            data-tip={displayMarketplaces()}
            data-for={`${item?.id}-${param}`}
            onClickCapture={(event) => event.stopPropagation()}
          />
          <ReactTooltip
            id={`${item?.id}-${param}`}
            key={`${item?.id}-${param}`}
            class="initialTextTransform"
            aria-haspopup="true"
            place="bottom"
            effect="solid"
            html
          />
        </>
      );
    };

    if (item?.customer_account_type === 'Hybrid') {
      return (
        <>
          {toolTip(
            'Sc',
            [...sellerArray, ...centralMarketplacesArray],
            !sellerArray?.length && !centralMarketplacesArray?.length
              ? AuthorizedSC
              : UnauthorizedSC,
          )}
          {toolTip(
            'Vc',
            [...vendorArray, ...centralMarketplacesArray],
            !vendorArray?.length && !centralMarketplacesArray?.length
              ? AuthorizedVC
              : UnauthorizedVC,
          )}
        </>
      );
    }
    if (item?.customer_account_type === 'Seller') {
      return (
        <>
          {toolTip(
            'Sc',
            [...sellerArray, ...centralMarketplacesArray],
            !sellerArray?.length ? AuthorizedSC : UnauthorizedSC,
          )}
        </>
      );
    }
    if (item?.customer_account_type === 'Vendor') {
      return (
        <>
          {toolTip(
            'Vc',
            [...vendorArray, ...centralMarketplacesArray],
            !vendorArray?.length ? AuthorizedVC : UnauthorizedVC,
          )}
        </>
      );
    }
  };

  const returnTooltipMessgeLWA = (item) => {
    const centralArray = [];
    const sellerArray = [];
    const vendorArray = [];
    const centralMarketplacesArray = [];

    for (const [key, value] of Object.entries(item?.lwa)) {
      if (typeof value === 'boolean') {
        centralArray.push(value);
        if (!value) centralMarketplacesArray.push(key);
      }
      if (typeof value === 'object') {
        if (
          item?.customer_account_type === 'Seller' ||
          item?.customer_account_type === 'Hybrid'
        ) {
          centralArray.push(value.seller);
          if (!value?.seller && 'seller' in value) {
            sellerArray.push(key);
          }
        }
        if (
          item?.customer_account_type === 'Vendor' ||
          item?.customer_account_type === 'Hybrid'
        ) {
          centralArray.push(value.vendor);
          if (!value?.vendor && 'vendor' in value) {
            vendorArray.push(key);
          }
        }
      }
    }

    return showSellerVendorCentral(
      centralArray,
      sellerArray,
      vendorArray,
      centralMarketplacesArray,
      item,
    );
  };

  // genetae company status
  const generateCompanyStatus = (companyStatus) => {
    return (
      <AccountStatus className={`${returnAccountStatusClass(companyStatus)}`}>
        {companyStatus === 'at risk' ? (
          <div style={{ display: 'inline' }}>At Risk</div>
        ) : (
          <div style={{ textTransform: 'capitalize', display: 'inline' }}>
            {companyStatus}
          </div>
        )}
      </AccountStatus>
    );
  };

  // display account status
  const displayAccountStatus = (accountType, accountStatus) => {
    return (
      <AccountStatus className="account-type">
        {accountType === 'parent' ? (
          <>
            <FontAwesomeIcon icon="fa-light fa-code-commit" /> Parent Brand
          </>
        ) : (
          accountStatus
        )}
      </AccountStatus>
    );
  };

  const showCollapseButton = (contractId, contractAccountType) => {
    return contractAccountType === 'parent' ? (
      <FontIconWrapper>
        <FontAwesomeIcon
          className={`icon ${
            showChildContracts.id === contractId ? 'active' : ''
          }`}
          icon={`fa-regular ${
            showChildContracts.id === contractId
              ? 'fa-chevron-up'
              : 'fa-chevron-down'
          }`}
          onClickCapture={(e) => {
            e.stopPropagation();
            setShowChildContracts({
              id: showChildContracts?.id !== contractId ? contractId : null,
            });
          }}
        />
      </FontIconWrapper>
    ) : (
      ''
    );
  };

  const getAgreementName = (agreementStatus) => {
    return agreementStatus === 'active'
      ? 'Signed'
      : agreementStatus === 'pending contract'
      ? 'Pending Agreement'
      : agreementStatus === 'pending contract approval'
      ? 'Pending Agreement Approval'
      : agreementStatus === 'pending contract signature'
      ? 'Pending Agreement Signature'
      : agreementStatus === 'cancel'
      ? 'Cancelled'
      : agreementStatus === 'pause'
      ? 'Paused'
      : agreementStatus === 'pending for cancellation'
      ? 'Cancelled - Pending for Billing'
      : agreementStatus === 'pending cancellation approval'
      ? 'Pending Cancellation Approval'
      : agreementStatus;
  };

  const generateContractHTML = (type, id) => {
    if (
      countDays(type.end_date) <= 90 &&
      type.contract_type !== 'one time' &&
      type.contract_status !== 'inactive'
    ) {
      return (
        <li
          key={type.contract_id}
          data-tip={getAgreementName(type.contract_status)}
          onClickCapture={(e) => {
            getCustomer(id);
            e.stopPropagation();
            history.push({
              pathname: `${PATH_AGREEMENT.replace(':id', id).replace(
                ':contract_id',
                type.contract_id,
              )}`,
              state: `${history.location.pathname}`,
            });
          }}
          role="presentation"
        >
          <div className="recurring-service count-days">
            {type.contract_type === 'dsp only'
              ? 'DSP only Service Agreement'
              : `${type.contract_type} Service Agreement`}

            <span className="count-clock-icon active-contract-icon">
              <img className="clock-icon" src={CountDayClock} alt="clock" />
              {countDays(type.end_date)}d
            </span>
          </div>
        </li>
      );
    }
    if (type && type.contract_status === 'pending contract') {
      return (
        <li
          key={type.contract_id}
          onClickCapture={(e) => {
            getCustomer(id);
            e.stopPropagation();
            history.push({
              pathname: `${PATH_AGREEMENT.replace(':id', id).replace(
                ':contract_id',
                type.contract_id,
              )}`,
              state: `${history.location.pathname}`,
            });
          }}
          role="presentation"
          data-tip="Pending Agreement"
          style={{ textTransform: 'capitalize' }}
        >
          <div className="recurring-service file">
            {type.contract_type === 'dsp only'
              ? 'DSP only Service Agreement'
              : `${type.contract_type} Service Agreement`}

            <span className=" active-contract-icon file-icon">
              <img src={FileIcon} alt="file" />
            </span>
          </div>
        </li>
      );
    }
    if (type && type.contract_status === 'pending contract approval') {
      return (
        <li
          key={type.contract_id}
          onClickCapture={(e) => {
            getCustomer(id);
            e.stopPropagation();
            history.push({
              pathname: `${PATH_AGREEMENT.replace(':id', id).replace(
                ':contract_id',
                type.contract_id,
              )}`,
              state: `${history.location.pathname}`,
            });
          }}
          role="presentation"
          data-tip="Pending Agreement Approval"
          style={{ textTransform: 'capitalize' }}
        >
          <div className="recurring-service file-check">
            {type.contract_type === 'dsp only'
              ? 'DSP only Service Agreement'
              : `${type.contract_type} Service Agreement`}

            <span className=" active-contract-icon file-check-icon ">
              <img src={CheckFileIcon} alt="check-file" />
            </span>
          </div>
        </li>
      );
    }
    if (type && type.contract_status === 'pending contract signature') {
      return (
        <li
          key={type.contract_id}
          onClickCapture={(e) => {
            getCustomer(id);
            e.stopPropagation();
            history.push({
              pathname: `${PATH_AGREEMENT.replace(':id', id).replace(
                ':contract_id',
                type.contract_id,
              )}`,
              state: `${history.location.pathname}`,
            });
          }}
          role="presentation"
          data-tip="Pending Agreement Signature"
          style={{ textTransform: 'capitalize' }}
        >
          <div className="recurring-service edit">
            {type.contract_type === 'dsp only'
              ? 'DSP only Service Agreement'
              : `${type.contract_type} Service Agreement`}

            <span className="edit-file-icon  active-contract-icon">
              <img width="16px" src={EditFileIcon} alt="edit" />{' '}
            </span>
          </div>
        </li>
      );
    }
    if (type && type.contract_status === 'active') {
      return (
        <li
          key={type.contract_id}
          data-tip="Signed"
          style={{ textTransform: 'capitalize' }}
          onClickCapture={(e) => {
            getCustomer(id);
            e.stopPropagation();
            redirectIfContractExists(type, id);
          }}
          role="presentation"
        >
          <div className="recurring-service agreement">
            {type.contract_type === 'dsp only'
              ? 'DSP only Service Agreement'
              : `${type.contract_type} Service Agreement`}
          </div>
        </li>
      );
    }

    return (
      <li
        key={type.contract_id}
        onClickCapture={(e) => {
          getCustomer(id);
          e.stopPropagation();
          redirectIfContractExists(type, id);
        }}
        role="presentation"
        data-tip={getAgreementName(type.contract_status)}
        style={{ textTransform: 'capitalize' }}
      >
        <div className="recurring-service agreement">
          {type.contract_type} Service Agreement
        </div>
      </li>
    );
  };

  const getChildActiveContracts = (childBps) => {
    const fields = [];

    if (childBps && childBps !== null && childBps !== undefined)
      for (const item of childBps) {
        if (item?.contract?.length) {
          for (const contract of item?.contract) {
            if (
              contract?.contract_status === 'active' ||
              contract?.contract_status === 'renewed'
            ) {
              fields.push(contract);
            }
          }
        }
      }
    return fields?.length || 0;
  };

  const showContractsList = (item) => {
    const contractLength = item.contract.length;
    const reduceContractLength = item.contract.length - 1;

    return (
      <ul className="recurring-contact" style={{ textTransform: 'capitalize' }}>
        {item && item.contract && item.contract.length ? (
          <>
            {!showContracts[item.id]
              ? item.contract.slice(0, 1).map((type) => (
                  <React.Fragment key={type.contract_id}>
                    <ReactTooltip />
                    {generateContractHTML(type, item.id)}
                  </React.Fragment>
                ))
              : item.contract.map((type) => (
                  <React.Fragment key={type.contract_id}>
                    <ReactTooltip />
                    {generateContractHTML(type, item.id)}
                  </React.Fragment>
                ))}
          </>
        ) : (
          <>
            {item?.account_type === 'parent' ? (
              <li className="no-active-contract">
                {`${getChildActiveContracts(
                  item?.child_customer,
                )} active agreement(s)`}
              </li>
            ) : (
              <li className="no-active-contract">No active agreements</li>
            )}
          </>
        )}
        {reduceContractLength > 0 ? (
          <span
            style={{ fontSize: '14px', color: '#000000', fontWeight: '300 ' }}
            onClickCapture={(e) => {
              e.stopPropagation();
              setShowContracts({ [item.id]: !showContracts[item.id] });
            }}
            aria-hidden="true"
          >
            {showContracts[item.id]
              ? 'show less'
              : `+ ${contractLength - 1} more`}
          </span>
        ) : (
          ''
        )}
      </ul>
    );
  };

  const generatePerformance = (
    value,
    toFixedValue,
    isTwiceReplace,
    prefix,
    suffix,
  ) => {
    if (isTwiceReplace) {
      return value
        ? `${
            prefix +
            value
              .toFixed(toFixedValue)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replace('.00', '')
          }${suffix}`
        : `${prefix}0${suffix}`;
    }
    return value
      ? `${prefix}${value
          .toFixed(toFixedValue)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${suffix}`
      : `${prefix}0${suffix}`;
  };

  const renderAdPerformanceDifference = (actualValue, grayArrow, matrics) => {
    const value = actualValue;
    let selectedClass = '';
    let selectedArrow = '';

    if (value) {
      if (matrics === 'ACOS') {
        if (value.toString().includes('-')) {
          selectedClass = 'increase-rate';
          selectedArrow = ArrowUpIcon;
        } else {
          selectedClass = 'decrease-rate';
          selectedArrow = ArrowDownIcon;
        }
      } else if (grayArrow) {
        if (value.toString().includes('-')) {
          selectedClass = 'decrease-rate grey';
          selectedArrow = UpDowGrayArrow;
        } else {
          selectedClass = 'increase-rate grey';
          selectedArrow = UpDowGrayArrow;
        }
      } else if (value.toString().includes('-')) {
        selectedClass = 'decrease-rate';
        selectedArrow = ArrowDownIcon;
      } else {
        selectedClass = 'increase-rate';
        selectedArrow = ArrowUpIcon;
      }

      if (value.toString().includes('-')) {
        return (
          <>
            <div className={selectedClass}>
              <img className="red-arrow" src={selectedArrow} alt="arrow-up" />
              {`${Number(value.toString().split('-')[1]).toFixed(2)} %`}
            </div>
          </>
        );
      }

      return (
        <>
          <div className={selectedClass}>
            <img
              className="green-arrow"
              src={selectedArrow}
              width="14px"
              alt="arrow-up"
            />
            {value} %
          </div>
        </>
      );
    }
    if (value === 0) {
      return <div className="small-value">0%</div>;
    }
    return <div className="small-value">N/A</div>;
  };

  const getTableHeaderLabel = (label, item, accountType) => {
    return (
      <th
        width="150px"
        className="product-header "
        style={{
          cursor: 'pointer',
          position: 'relative',
          color:
            item?.value === selectedValue['order-by'] &&
            selectedValue['order-by-account-type'] === accountType
              ? Theme.red
              : Theme.gray40,
        }}
      >
        {label}
        <img
          className={`sort-arrow-up ${
            item?.value === selectedValue['order-by'] &&
            orderByFlag &&
            selectedValue['order-by-account-type'] === accountType
              ? 'rotate'
              : ''
          }`}
          src={SortUp}
          alt="arrow-up"
          role="presentation"
          onClick={() => handleSortFilters(item?.value, accountType)}
        />
      </th>
    );
  };

  // display customer list table headers
  const renderHeaders = () => {
    if (showPerformance || showDspAdPerformance) {
      return selectedColumns.map((item) =>
        getTableHeaderLabel(item?.label, item),
      );
    }
    if (showAdPerformance) {
      if (
        (selectedAccountTypes?.includes('Seller') &&
          selectedAccountTypes?.includes('Vendor')) ||
        selectedAccountTypes?.includes('Hybrid') ||
        selectedAccountTypes?.length === 0
      ) {
        return selectedColumns.map((item) => (
          <>
            {['TACOS', 'Total AD Spend']?.includes(item?.label) ? (
              getTableHeaderLabel(`${item?.label}`, item, 'Seller')
            ) : (
              <>
                {' '}
                {getTableHeaderLabel(`SC_${item?.label}`, item, 'Seller')}
                {getTableHeaderLabel(`VC_${item?.label}`, item, 'Vendor')}
              </>
            )}
          </>
        ));
      }
      if (selectedAccountTypes?.includes('Seller')) {
        return selectedColumns.map((item) =>
          ['TACOS', 'Total AD Spend']?.includes(item?.label)
            ? getTableHeaderLabel(`${item?.label}`, item, 'Seller')
            : getTableHeaderLabel(`SC_${item?.label}`, item, 'Seller'),
        );
      }
      if (selectedAccountTypes?.includes('Vendor')) {
        return selectedColumns.map((item) =>
          getTableHeaderLabel(`VC_${item?.label}`, item, 'Vendor'),
        );
      }
    }
    return (
      <>
        <th width="100px" className="product-header ">
          Account Type
        </th>
        <th width="110px" className="product-header ">
          Status
        </th>
        <th width="250px" className="product-header ">
          Active Agreements
        </th>
      </>
    );
  };

  const getTableData = (column, item, dashboard) => {
    const metricsUnit = metricsCurrency[column?.key]?.type;
    return (
      <td className="product-body">
        {generatePerformance(
          item[dashboard]?.current_sum?.[column?.value],
          2,
          'isTwiceReplace',
          metricsUnit === 'currency' ? '$' : '',
          metricsUnit === 'percentage' ? '%' : '',
        )}
        {isCustomTimeFrame
          ? null
          : renderAdPerformanceDifference(
              item[dashboard]?.difference_data?.[column?.value],
              false,
              column?.value,
            )}
      </td>
    );
  };

  // display customer list table data
  const displayTableData = (dashboard, item) => {
    const tableData = [];

    selectedColumns.map((column) => {
      tableData.push(getTableData(column, item, dashboard));
      return null;
    });
    return tableData;
  };

  // display customer list table data
  const displaySalesPerformanceTableData = (dashboard, item) => {
    const tableData = [];

    selectedColumns.map((column) => {
      if (Object.keys(column).includes('dashboard')) {
        tableData.push(getTableData(column, item, column?.dashboard));
      } else {
        tableData.push(getTableData(column, item, dashboard));
      }
      return null;
    });
    return tableData;
  };

  // display customer list table data
  const displaySPAdTableData = (dashboard, item) => {
    const tableData = [];
    const dashboardSC = `${dashboard}_sc`;
    const dashboardVC = `${dashboard}_vc`;

    if (
      (selectedAccountTypes?.includes('Seller') &&
        selectedAccountTypes?.includes('Vendor')) ||
      selectedAccountTypes?.includes('Hybrid') ||
      selectedAccountTypes?.length === 0
    ) {
      selectedColumns.map((column) => {
        if (['TACOS', 'Total AD Spend']?.includes(column?.label)) {
          tableData.push(getTableData(column, item, dashboardSC));
        } else {
          tableData.push(getTableData(column, item, dashboardSC));
          tableData.push(getTableData(column, item, dashboardVC));
        }
        return null;
      });
    } else if (selectedAccountTypes?.includes('Seller')) {
      selectedColumns.map((column) => {
        tableData.push(getTableData(column, item, dashboardSC));
        return null;
      });
    } else if (selectedAccountTypes?.includes('Vendor')) {
      selectedColumns.map((column) => {
        tableData.push(getTableData(column, item, dashboardVC));
        return null;
      });
    }
    return tableData;
  };

  // Rendering child brands
  const renderChildContractHtml = (parentCustomer, colSpanValue) => {
    return (
      <tr className="cursor product-body">
        <td className="p-0" colSpan={colSpanValue} width="100%">
          <Collapse isOpened={showChildContracts.id === parentCustomer?.id}>
            <ChildCustomerListView
              colSpanValue={colSpanValue}
              parentCustomer={parentCustomer}
              showContractsList={showContractsList}
              generateCompanyStatus={generateCompanyStatus}
              returnAccountStatusClass={returnAccountStatusClass}
              returnTooltipMessgeLWA={returnTooltipMessgeLWA}
              showPerformance={showPerformance}
              showAdPerformance={showAdPerformance}
              showDspAdPerformance={showDspAdPerformance}
              showContractDetails={showContractDetails}
              displayTableData={displayTableData}
              displayAccountStatus={displayAccountStatus}
              displaySalesPerformanceTableData={
                displaySalesPerformanceTableData
              }
              displaySPAdTableData={displaySPAdTableData}
              selectedColumns={selectedColumns}
            />
          </Collapse>
        </td>
      </tr>
    );
  };

  // display partner list and contract details
  const generateContractDetails = (item) => {
    return (
      <>
        <tr
          className="cursor product-body "
          key={item?.id}
          onClick={() => {
            dispatch(setCustomerSelectedTab('performance'));
            history.push(
              `${PATH_CUSTOMER_DETAILS.replace(
                ':id',
                item.id,
              )}?tab=performance&${
                item.customer_account_type
                  ? `&sub=${
                      item.customer_account_type === 'Hybrid' ||
                      item.customer_account_type === 'Seller'
                        ? 'seller'
                        : 'vendor'
                    }`
                  : ''
              }&nav=${params?.nav ? params?.nav : 'salePerformance'}`,
            );

            localStorage.setItem(
              'noteFilters',
              JSON.stringify({
                archived: 'hide',
                team: [],
                notes: 'All Notes',
                q: '',
              }),
            );
          }}
        >
          <td width="25.5%  " className=" product-body">
            {showCollapseButton(item?.id, item?.account_type)}
            {generateLogoCompanyNameAndGs(
              item,
              item?.company_name,
              item?.brand_growth_strategist,
            )}
          </td>
          <td width="16%" className="product-body">
            {item?.customer_account_type || item?.account_type === 'parent'
              ? displayAccountStatus(
                  item?.account_type,
                  item?.customer_account_type,
                )
              : ''}
            {item?.lwa && Object.keys(item?.lwa).length
              ? returnTooltipMessgeLWA(item)
              : ''}
          </td>
          <td width="17%" className="product-body">
            {generateCompanyStatus(item?.status)}
          </td>
          <td width="41%" className="product-body">
            {showContractsList(item)}
          </td>
        </tr>
        {item?.child_customer?.length ? (
          renderChildContractHtml(item, 4)
        ) : showChildContracts?.id === item?.id ? (
          <p className="no-brand pl-4">No child Brands Available</p>
        ) : (
          ''
        )}
        <tr>
          <td colSpan="4" className=" p-0">
            <div className=" straight-line horizontal-line w-100" />
          </td>
        </tr>
      </>
    );
  };

  // redirect to customer detail view
  const redirectToCustomerDetailView = (item) => {
    dispatch(setCustomerSelectedTab('performance'));
    history.push(
      `${PATH_CUSTOMER_DETAILS.replace(':id', item?.id)}?tab=performance&${
        item?.customer_account_type
          ? `&sub=${
              item.customer_account_type === 'Hybrid' ||
              item.customer_account_type === 'Seller'
                ? 'seller'
                : 'vendor'
            }`
          : ''
      }&nav=${params?.nav ? params?.nav : 'salePerformance'}`,
    );
  };

  // display partner list and metrics table data for sales, sponsord ad and dsp performance view
  const renderCustomerDetails = (item) => {
    if (showPerformance) {
      return (
        <>
          <tr
            className="cursor"
            key={item?.id}
            onClick={() => {
              redirectToCustomerDetailView(item);
            }}
          >
            <td className=" product-body">
              {showCollapseButton(item?.id, item?.account_type)}
              {generateLogoCompanyNameAndGs(
                item,
                item?.company_name,
                item?.brand_growth_strategist,
              )}
            </td>

            {displaySalesPerformanceTableData('sales_performance', item)}
          </tr>
          {item?.child_customer?.length ? (
            renderChildContractHtml(item, colSpanCount)
          ) : showChildContracts?.id === item?.id ? (
            <p className="no-brand pl-4">No child Brands Available</p>
          ) : (
            ''
          )}
          <tr>
            <td colSpan={colSpanCount} className=" p-0">
              <div className=" straight-line horizontal-line w-100" />
            </td>
          </tr>
        </>
      );
    }
    if (showAdPerformance) {
      return (
        <>
          <tr
            className="cursor"
            key={item?.id}
            onClick={() => {
              redirectToCustomerDetailView(item);
            }}
          >
            <td width="300px" className="product-body">
              {showCollapseButton(item?.id, item?.account_type)}
              {generateLogoCompanyNameAndGs(
                item,
                item?.company_name,
                item?.ad_manager,
              )}
            </td>
            {displaySPAdTableData('sponsored_ad_performance', item)}
          </tr>
          {item?.child_customer?.length ? (
            renderChildContractHtml(item, colSpanCount)
          ) : showChildContracts?.id === item?.id ? (
            <p className="no-brand pl-4">No child Brands Available</p>
          ) : (
            ''
          )}
          <tr>
            <td colSpan={colSpanCount} className=" p-0">
              <div className=" straight-line horizontal-line w-100" />
            </td>
          </tr>
        </>
      );
    }
    if (showDspAdPerformance) {
      return (
        <>
          <tr
            className="cursor"
            key={item?.id}
            onClick={() => {
              redirectToCustomerDetailView(item);
            }}
          >
            <td width="300px" className=" product-body">
              {showCollapseButton(item?.id, item?.account_type)}
              {generateLogoCompanyNameAndGs(
                item,
                item?.company_name,
                item?.ad_manager,
              )}
            </td>
            {displayTableData('dsp_ad_performance', item)}
          </tr>
          {item?.child_customer?.length ? (
            renderChildContractHtml(item, colSpanCount)
          ) : showChildContracts?.id === item?.id ? (
            <p className="no-brand pl-4">No child Brands Available</p>
          ) : (
            ''
          )}
          <tr>
            <td colSpan={colSpanCount} className=" p-0">
              <div className=" straight-line horizontal-line w-100" />
            </td>
          </tr>
        </>
      );
    }
    if (showContractDetails) {
      return <>{generateContractDetails(item)}</>;
    }
    return <>{generateContractDetails(item)}</>;
  };

  // display pagination
  const displayPagination = () => {
    return customerListData?.length > 0 ? (
      <div className="footer-sticky">
        <div
          className={
            customerListData?.length < 9 && totalPageCount < 10
              ? ''
              : 'straight-line horizontal-line'
          }
        />

        <CommonPagination
          count={totalPageCount}
          pageNumber={JSON.parse(localStorage.getItem('page')) || pageNumber}
          handlePageChange={handlePageChange}
        />
      </div>
    ) : null;
  };

  // display customer list desktop view
  const displayCustomerListDesktopView = () => {
    return (
      <div>
        <div className="table-responsive">
          <div className="table-part">
            {isLoading.loader && isLoading.type === 'page' ? (
              <PageLoader
                component="customer-list-loader"
                color={Theme.baseColor}
                type="page"
              />
            ) : (
              <Table className="customer-list">
                <thead>
                  <tr className="customer-list-table-header ">
                    <th width="300px" className="product-header ">
                      Partner
                    </th>
                    {renderHeaders()}
                  </tr>
                </thead>
                <tbody>
                  {customerListData?.length === 0 ? (
                    <NoRecordFound
                      type="customer-list"
                      title="We looked high and low, but…"
                      subTitle="No records found."
                    />
                  ) : (
                    customerListData?.map((item) => renderCustomerDetails(item))
                  )}
                </tbody>
              </Table>
            )}
          </div>
        </div>
        {displayPagination()}
      </div>
    );
  };

  // display customer list tablet view
  const displayCustomerMobileandTabletView = () => {
    return (
      <CustomerListTablet
        count={totalPageCount}
        pageNumber={pageNumber}
        showContractDetails={showContractDetails}
        showPerformance={showPerformance}
        showAdPerformance={showAdPerformance}
        showDspAdPerformance={showDspAdPerformance}
        history={history}
        isLoading={isLoading}
        showChildContracts={showChildContracts}
        selectedColumns={selectedColumns}
        data={customerListData}
        selectedAccountTypes={selectedAccountTypes}
        handlePageChange={handlePageChange}
        showContractsList={showContractsList}
        showCollapseButton={showCollapseButton}
        generatePerformance={generatePerformance}
        generateCompanyStatus={generateCompanyStatus}
        returnTooltipMessgeLWA={returnTooltipMessgeLWA}
        displayAccountStatus={displayAccountStatus}
        returnAccountStatusClass={returnAccountStatusClass}
        generateLogoCompanyNameAndGs={generateLogoCompanyNameAndGs}
        redirectToCustomerDetailView={redirectToCustomerDetailView}
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      {isDesktop
        ? displayCustomerListDesktopView()
        : displayCustomerMobileandTabletView()}
    </div>
  );
}

export default CustomerListRightSidePanel;
CustomerListRightSidePanel.defaultProps = {
  totalPageCount: null,
  pageNumber: 1,

  orderByFlag: false,
  isCustomTimeFrame: false,
  showContractDetails: true,
  showPerformance: false,
  showAdPerformance: false,
  showDspAdPerformance: false,

  params: {},
  isLoading: {},
  selectedValue: {},

  selectedColumns: [],
  customerListData: [],

  getCustomer: () => {},
  handlePageChange: () => {},
  handleSortFilters: () => {},
  redirectIfContractExists: () => {},
};

CustomerListRightSidePanel.propTypes = {
  totalPageCount: number,
  pageNumber: number,

  orderByFlag: bool,
  isCustomTimeFrame: bool,
  showContractDetails: bool,
  showPerformance: bool,
  showAdPerformance: bool,
  showDspAdPerformance: bool,

  params: shape({}),
  isLoading: shape({}),
  selectedValue: shape({}),

  selectedColumns: arrayOf(shape({})),
  customerListData: arrayOf(shape({})),

  getCustomer: func,
  handlePageChange: func,
  handleSortFilters: func,
  redirectIfContractExists: func,
};

const FontIconWrapper = styled.div`
  float: left;
  padding-right: 13px;

  .icon {
    width: 10px;
    height: 15px;
    vertical-align: bottom;
    padding: 0px 3px;
    color: ${Theme.black};
    border-radius: 2px;

    &:hover,
    &.active {
      background: ${Theme.gray8};
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 769px) {
    .icon {
      position: absolute;
      left: -1px;
      top: 23px;
    }
  }
`;
