import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import {
  arrayOf,
  shape,
  oneOfType,
  object,
  func,
  bool,
  string,
} from 'prop-types';

import PlaybookTaskAssignee from './PlaybookTaskAssignee';
import PlaybookTaskStatus from './PlaybookTaskStatus';
import PlaybookDeliverableCollapse from './PlaybookDeliverableCollapse';
import { JIRA_TICKET_BASE_URL } from '../../../../../constants';
import { renderCollapseDropdownIcon } from '../CommonFunctions';

export default function PlaybookServicesCollapse({
  ID,
  customerId,
  isCustomer,
  playbookTab,
  playbookAPICall,
  serviceData,
  isUserAssignedToBP,
}) {
  const isPlaybookTab = playbookTab === 'playbook';
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isCurrentMonthTask =
    new Date(serviceData?.start_date)?.getUTCMonth() ===
      new Date()?.getUTCMonth() && isPlaybookTab;

  const [showTaskCollapse, setShowTaskCollpase] = useState(false);
  const serviceID = serviceData?.id;
  const taskData = {
    taskId: serviceID,
    taskName: serviceData?.service_name,
    jiraStatus: serviceData?.jira_status,
  };

  // render deliverable data
  const renderServiceData = () => {
    return (
      <tr key={serviceID}>
        <td
          width={isPlaybookTab ? '40%' : '50%'}
          className={`text-bold ${
            serviceData?.deliverables?.length ? 'cursor' : null
          }`}
          style={{
            borderTop: '1px solid #e0e6e8',
            borderBottom: 'none',
          }}
          role="presentation"
          onClick={() => {
            setShowTaskCollpase(!showTaskCollapse);
          }}
        >
          {serviceData?.deliverables?.length
            ? renderCollapseDropdownIcon(showTaskCollapse)
            : null}
          {isPlaybookTab && serviceData?.jira_id !== null ? (
            <a
              key={serviceID}
              className="capitalize"
              href={`${JIRA_TICKET_BASE_URL}${serviceData?.jira_id}`}
              target="_blank"
              rel=" noopener noreferrer"
            >
              <div
                className="d-inline"
                dangerouslySetInnerHTML={{
                  __html: serviceData?.service_name,
                }}
              />
              {/* {serviceData?.service_name} */}
            </a>
          ) : (
            <span key={serviceID} className="capitalize">
              <div
                className="d-inline"
                dangerouslySetInnerHTML={{
                  __html: serviceData?.service_name,
                }}
              />
              {/* {serviceData?.service_name} */}
            </span>
          )}
        </td>
        {isPlaybookTab ? (
          <td
            style={{
              borderTop: '1px solid #e0e6e8',
              borderBottom: 'none',
              width: '15%',
            }}
          >
            {serviceData?.marketplace}
          </td>
        ) : null}
        <td
          style={{
            borderTop: '1px solid #e0e6e8',
            borderBottom: 'none',
            width: '10%',
          }}
        >
          {serviceData?.team}
        </td>
        {isCustomer ? null : (
          <td
            style={{
              borderTop: '1px solid #e0e6e8',
              borderBottom: 'none',
              width: '10%',
            }}
          >
            {serviceData?.due_date}
          </td>
        )}
        {isCustomer ? null : (
          <td
            className={isCurrentMonthTask ? '' : 'disabled'}
            style={{
              borderTop: '1px solid #e0e6e8',
              borderBottom: 'none',
              width: '10%',
            }}
          >
            <PlaybookTaskAssignee
              ID={ID}
              customerId={customerId}
              playbookTab={playbookTab}
              taskId={serviceID}
              playbookAPICall={playbookAPICall}
              isUserAssignedToBP={isUserAssignedToBP}
              assigneeData={serviceData?.assignee}
            />
          </td>
        )}

        <td
          className={isCurrentMonthTask ? '' : 'disabled'}
          style={{
            borderTop: '1px solid #e0e6e8',
            borderBottom: 'none',
            width: '15%',
          }}
        >
          <PlaybookTaskStatus
            ID={ID}
            taskData={taskData}
            isCustomer={isCustomer}
            customerId={customerId}
            playbookTab={playbookTab}
            playbookAPICall={playbookAPICall}
          />
        </td>
      </tr>
    );
  };

  // render playbook task data collapse
  const renderPlaybookDeliverablesCollapse = () => {
    return serviceData?.deliverables?.length && showTaskCollapse ? (
      <>
        {serviceData?.deliverables?.map((item) => {
          return (
            <PlaybookDeliverableCollapse
              ID={ID}
              deliverableData={item}
              isCustomer={isCustomer}
              customerId={customerId}
              playbookTab={playbookTab}
              playbookAPICall={playbookAPICall}
              isUserAssignedToBP={isUserAssignedToBP}
              renderCollapseDropdownIcon={renderCollapseDropdownIcon}
              isCurrentMonthTask={isCurrentMonthTask}
            />
          );
        })}
      </>
    ) : null;
  };

  // render playbook task mobile field data html
  const renderPlaybookTaskMobileDataHTML = (label, dataValue) => {
    return (
      <div className={`${label === 'Deliverables' ? 'col-12' : 'col-6'} mb-3`}>
        <div className="label">{label}</div>
        <div className="normal-text-black">
          {label === 'Assignee' ? (
            <PlaybookTaskAssignee
              ID={ID}
              taskId={serviceID}
              customerId={customerId}
              assigneeData={dataValue}
              playbookTab={playbookTab}
              playbookAPICall={playbookAPICall}
              isUserAssignedToBP={isUserAssignedToBP}
            />
          ) : label === 'Status' ? (
            <PlaybookTaskStatus
              ID={ID}
              taskData={dataValue}
              isCustomer={isCustomer}
              customerId={customerId}
              playbookTab={playbookTab}
              playbookAPICall={playbookAPICall}
            />
          ) : (
            dataValue
          )}
        </div>
      </div>
    );
  };

  // render playbook task table mobile fields
  const renderPlaybookTaskMobileData = (key) => {
    return (
      <>
        <div className="row" key={key}>
          <div
            className="col-12 mb-3"
            role="presentation"
            onClick={() => {
              setShowTaskCollpase(!showTaskCollapse);
            }}
          >
            <div className="label">Deliverables</div>
            {isPlaybookTab && serviceData?.jira_id !== null ? (
              <a
                className="normal-text-black capitalize"
                href={`${JIRA_TICKET_BASE_URL}${serviceData?.jira_id}`}
                target="_blank"
                rel=" noopener noreferrer"
              >
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{
                    __html: serviceData?.service_name,
                  }}
                />
              </a>
            ) : (
              <div className="normal-text-black capitalize">
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{
                    __html: serviceData?.service_name,
                  }}
                />
              </div>
            )}
          </div>
          <span className="parent-collapse" />
          {isPlaybookTab
            ? renderPlaybookTaskMobileDataHTML(
                'Marketplace',
                serviceData?.marketplace,
              )
            : null}

          {renderPlaybookTaskMobileDataHTML('Team', serviceData?.team)}
          {isCustomer
            ? null
            : renderPlaybookTaskMobileDataHTML(
                'Due Date',
                serviceData?.due_date,
              )}
          {isCustomer
            ? null
            : renderPlaybookTaskMobileDataHTML(
                'Assignee',
                serviceData?.assignee,
                serviceID,
              )}
          {renderPlaybookTaskMobileDataHTML('Status', taskData)}
        </div>
        {serviceData?.deliverables?.length && showTaskCollapse
          ? serviceData?.deliverables?.map((item) => {
              return (
                <PlaybookDeliverableCollapse
                  ID={ID}
                  deliverableData={item}
                  isCustomer={isCustomer}
                  customerId={customerId}
                  playbookTab={playbookTab}
                  playbookAPICall={playbookAPICall}
                  isUserAssignedToBP={isUserAssignedToBP}
                  renderCollapseDropdownIcon={renderCollapseDropdownIcon}
                  isCurrentMonthTask={isCurrentMonthTask}
                />
              );
            })
          : null}
      </>
    );
  };

  // render playbook deliverabel collapse movile view
  const renderPlaybookDeliverableMobileView = () => {
    return (
      <>
        {serviceData?.deliverables?.length ? (
          <div className="playbook-collapse-grid">
            <div
              className={`${
                serviceData?.deliverables?.length ? 'cursor' : null
              }`}
              role="presentation"
              onClick={() => {
                setShowTaskCollpase(!showTaskCollapse);
              }}
            >
              {renderCollapseDropdownIcon(showTaskCollapse)}
            </div>
            <div>
              <span className="parent-collapse" />
              {renderPlaybookTaskMobileData(serviceID)}
            </div>
          </div>
        ) : (
          <div style={{ marginTop: '10px' }}>
            {renderPlaybookTaskMobileData(serviceID)}
          </div>
        )}
      </>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      {isTablet ? (
        <>
          {renderServiceData()}
          {renderPlaybookDeliverablesCollapse()}
        </>
      ) : (
        renderPlaybookDeliverableMobileView()
      )}
    </>
  );
}

PlaybookServicesCollapse.defaultProps = {
  serviceData: [],
  playbookAPICall: () => {},
};

PlaybookServicesCollapse.propTypes = {
  ID: string.isRequired,
  customerId: string.isRequired,
  playbookTab: string.isRequired,
  isCustomer: bool.isRequired,
  isUserAssignedToBP: bool.isRequired,
  playbookAPICall: func,
  serviceData: oneOfType([arrayOf(shape()), object]),
};
