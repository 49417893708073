import React from 'react';

import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import Theme from '../../../../theme/Theme';
import {
  CheckBox,
  InfoMsg,
  ToggleButton,
  ToggleSwitch,
} from '../../../../common';
import {
  AdTypesOptions,
  buyBoxPercentFieldOptions,
  graphToggleOptions,
  groupByFields,
  inventoryManagementFields,
  inventoryProductNameToggleOption,
  productNameToggleOption,
  sellerAdspendMetricsFields,
  sellerDspMetricsFields,
  sellerHealthFields,
  sellerRevenueMetricsFields,
  sellerSalesMetricsFields,
  sellerSponsoredMetricsFields,
  sponoredDspKeys,
} from '../../../../constants';

const RenderSellerFields = ({
  graphData,
  graphIndex,
  accountType,
  handleGraphDetailsOnChange,
  renderCheckBox,
  getAdTypesSelectComponents,
  renderRadioButtons,
  renderNumberOfAsins,
  selectedTimeFrame,
  enabledGroupFrequencyList,
  reportStatus,
  checkSelectedKeys,
  renderErrorMessage,
  showMetricsTooltip,
  renderDataTableViewButton,
  metricsLoader,
}) => {
  const renderHeadingAndCollapse = () => {
    return (
      <>
        <div className="collapse-btn">
          <h4 className="graph-title text-bold">Seller </h4>
          {graphData?.isSellerOpen ? (
            <FontAwesomeIcon
              className="icon-collapse"
              icon="fa-regular fa-minus"
              onClick={() => {
                handleGraphDetailsOnChange(
                  graphIndex,
                  'sellerDetails',
                  'isSellerOpen',
                  false,
                  false, // isMetrics
                );
              }}
            />
          ) : (
            <FontAwesomeIcon
              className="icon-collapse"
              icon="fa-regular fa-plus"
              onClick={() => {
                handleGraphDetailsOnChange(
                  graphIndex,
                  'sellerDetails',
                  'isSellerOpen',
                  true,
                  false, // isMetrics
                );
              }}
            />
          )}
          <div className=" clear-fix" />
        </div>
      </>
    );
  };

  const rednerGraphFrequency = () => {
    return (
      <>
        <div className="label" htmlFor="">
          Graph Frequency
        </div>
        <ToggleButton
          className={`${metricsLoader ? 'disabled' : ''} mb-4 pb-1`}
        >
          <div className="days-container ">
            <ul className="days-tab">
              {groupByFields.map((option) => {
                return (
                  <li
                    className={`p-0 
                    ${
                      (reportStatus === 'readyToEdit' &&
                        graphData?.graphFrequency !== option?.name) ||
                      !enabledGroupFrequencyList.includes(option?.name)
                        ? 'disabled'
                        : ''
                    }
                    ${
                      graphData?.graphFrequency === option?.name
                        ? 'activeGroupBy'
                        : ''
                    }`}
                    key={`seller${graphIndex}${option?.name}`}
                  >
                    <input
                      className="d-none"
                      type="radio"
                      id={`${graphIndex}${option?.id}`}
                      name={`sellerGraphFrequency${graphIndex}`}
                      checked={graphData?.graphFrequency === option?.name}
                      onChange={() => {
                        handleGraphDetailsOnChange(
                          graphIndex,
                          'sellerDetails',
                          'graphFrequency',
                          option?.name,
                          false, // isMetrics
                        );
                      }}
                    />
                    <label htmlFor={`${graphIndex}${option?.id}`}>
                      {option?.label}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </ToggleButton>
      </>
    );
  };

  // render metrics tooltip
  const renderMetricsTooltip = (option) => {
    const showTooltip = showMetricsTooltip?.find(
      (item) => item?.[option?.keyName],
    );

    return showTooltip ? (
      <ReactTooltip
        id={`${option.id}${graphIndex}`}
        className="metrics-tooltip"
        aria-haspopup="true"
        place="right"
        effect="solid"
        backgroundColor="#2e384d"
      />
    ) : null;
  };

  const renderSalesPanelAndToggleOptions = () => {
    const showRevenueMetrics =
      checkSelectedKeys(graphData, [
        'salesRevenueTotal',
        'salesRevenuePpc',
        'salesRevenueDsp',
      ])?.length > 0;

    const showSalesMetrics =
      checkSelectedKeys(graphData, [
        'salesTacos',
        'salesUnitSold',
        'salesTraffic',
        'salesConversion',
      ])?.length > 0;

    const showAdSpendMetrics =
      checkSelectedKeys(graphData, [
        'salesAdSpendTotal',
        'salesAdSpendPpc',
        'salesAdSpendDsp',
      ])?.length > 0;

    return (
      <>
        {graphToggleOptions.map((option) => {
          if (
            selectedTimeFrame?.value === 'custom' &&
            option.id === 'previous'
          ) {
            return null;
          }
          return (
            <ToggleSwitch
              className="custom-switch mt-2"
              key={`sellerDetails${graphIndex}${option?.id}`}
            >
              {renderCheckBox(
                graphIndex,
                graphData,
                `seller${graphIndex}${option?.id}`,
                option?.label,
                option?.fieldName,
                option?.keyName,
                option?.className,
                option?.spanClass,
                option?.isMetrics,
              )}
            </ToggleSwitch>
          );
        })}
        {checkSelectedKeys(graphData, ['showDataTable'])?.length === 0
          ? null
          : renderDataTableViewButton(
              graphData,
              graphIndex,
              'sellerDetails',
              'graphDataTableView',
              'metrics-table',
            )}

        {reportStatus === 'readyToEdit' ? null : (
          <InfoMsg className="mt-2 mb-2">
            You can select a maximum of 4 metrics only in one graph.
          </InfoMsg>
        )}
        {reportStatus === 'readyToEdit' &&
        !showRevenueMetrics &&
        !showSalesMetrics &&
        !showAdSpendMetrics ? null : (
          <>
            <div className="label-heading mt-3 mb-2">
              Sales Performance metrics
            </div>
            {renderErrorMessage(
              'salesPeformance',
              graphData,
              graphIndex,
              'sellerDetails',
            )}

            {reportStatus === 'readyToEdit' && !showSalesMetrics ? null : (
              <>
                {sellerSalesMetricsFields.map((option) => {
                  return (
                    <span key={`${option?.id}${graphIndex}`}>
                      <CheckBox
                        key={`${option.id}${graphIndex}`}
                        data-tip={option?.message}
                        data-for={`${option?.id}${graphIndex}`}
                      >
                        {renderCheckBox(
                          graphIndex,
                          graphData,
                          option.id,
                          option.label,
                          option.fieldName,
                          option.keyName,
                          option.className,
                          option.spanClass,
                          option.isMetrics,
                          option.isSubType,
                          option?.message,
                        )}
                      </CheckBox>
                      {renderMetricsTooltip(option)}
                    </span>
                  );
                })}
              </>
            )}

            {reportStatus === 'readyToEdit' && !showRevenueMetrics ? null : (
              <>
                <p className="basic-text mb-3">Total Revenue</p>
                {sellerRevenueMetricsFields.map((option) => {
                  return (
                    <div className="parent-nested-collapse " key={option?.id}>
                      <div className="parent-collapse" />
                      <span className="curve-line curve-line-parent" />
                      <CheckBox
                        key={`${option.id}${graphIndex}`}
                        data-tip={option?.message}
                        data-for={`${option?.id}${graphIndex}`}
                      >
                        {renderCheckBox(
                          graphIndex,
                          graphData,
                          option?.id,
                          option?.label,
                          option?.fieldName,
                          option?.keyName,
                          option?.className,
                          option?.spanClass,
                          option?.isMetrics,
                          option?.isSubType,
                        )}
                      </CheckBox>
                      {renderMetricsTooltip(option)}
                    </div>
                  );
                })}
              </>
            )}

            {reportStatus === 'readyToEdit' && !showAdSpendMetrics ? null : (
              <>
                <p className="basic-text my-3">Total Ad Spend</p>
                {sellerAdspendMetricsFields.map((option) => {
                  return (
                    <div className="parent-nested-collapse " key={option?.id}>
                      <div className="parent-collapse" />
                      <span className="curve-line curve-line-parent" />
                      <CheckBox
                        key={`${option.id}${graphIndex}`}
                        data-tip={option?.message}
                        data-for={`${option?.id}${graphIndex}`}
                      >
                        {renderCheckBox(
                          graphIndex,
                          graphData,
                          option?.id,
                          option?.label,
                          option?.fieldName,
                          option?.keyName,
                          option?.className,
                          option?.spanClass,
                          option?.isMetrics,
                          option?.isSubType,
                          option?.message,
                        )}
                      </CheckBox>
                      {renderMetricsTooltip(option)}
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const renderAdPerformancePanel = () => {
    return reportStatus === 'readyToEdit' &&
      checkSelectedKeys(graphData, Object.keys(sponoredDspKeys))?.length ===
        0 ? null : (
      <div
        className={
          selectedTimeFrame && selectedTimeFrame?.value === 'year'
            ? 'disabled'
            : ''
        }
      >
        <div
          className={`label mt-4 ${
            ['year'].includes(selectedTimeFrame?.value) ? 'mb-0' : ''
          }`}
          htmlFor=""
        >
          Ad performance metrics
        </div>
        {['year'].includes(selectedTimeFrame?.value) ? (
          <InfoMsg className="mb-2">
            We don&apos;t have data for selected time frame filter.
          </InfoMsg>
        ) : null}

        <div className="thershold mb-2">
          <ul className="days-tab">
            <li
              role="presentation"
              style={{ width: '50%' }}
              className={`p-0 ${
                graphData?.adPerformance === 'sponsored'
                  ? 'thresholdChecked'
                  : ''
              }`}
              onClick={() =>
                handleGraphDetailsOnChange(
                  graphIndex,
                  'sellerDetails',
                  'adPerformance',
                  'sponsored',
                  false, // isMetrics
                )
              }
            >
              {renderRadioButtons(
                `sellerSponsored${graphIndex}`,
                `${graphIndex}sellerAdype`,
                graphData?.adPerformance,
                graphData?.adPerformance === 'sponsored',
                'Sponsored Ad',
                'radio-container customer-list',
              )}
            </li>
            <li
              role="presentation"
              style={{ width: '50%' }}
              className={`p-0 ${
                graphData?.adPerformance === 'dsp' ? 'thresholdChecked' : ''
              }`}
              onClick={() =>
                handleGraphDetailsOnChange(
                  graphIndex,
                  'sellerDetails',
                  'adPerformance',
                  'dsp',
                  false, // isMetrics
                )
              }
            >
              {renderRadioButtons(
                `sellerDsp${graphIndex}`,
                `${graphIndex}sellerAdype`,
                graphData?.adPerformance,
                graphData?.adPerformance === 'dsp',
                'DSP Ad',
                'radio-container customer-list',
              )}
            </li>
          </ul>
        </div>

        {renderErrorMessage(
          'adPeformance',
          graphData,
          graphIndex,
          'sellerDetails',
        )}

        {graphData.adPerformance === 'sponsored' ? (
          <>
            <div className="label mt-4" htmlFor="">
              Sponsored Ad type filter
            </div>
            <div className="thershold mb-3">
              <Select
                classNamePrefix="react-select form-control-error"
                options={AdTypesOptions}
                placeholder={graphData.sponsoredType.label}
                components={getAdTypesSelectComponents()}
                value={graphData.sponsoredType}
                isSearchable={false}
                onChange={(event) => {
                  handleGraphDetailsOnChange(
                    graphIndex,
                    'sellerDetails',
                    'sponsoredType',
                    event,
                    false, // isMetrics
                  );
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: Theme.white,
                    primary25: Theme.lightOrange,
                    primary: Theme.orange,
                    primary50: Theme.lightOrange,
                  },
                })}
              />
            </div>

            {sellerSponsoredMetricsFields.map((option) => {
              return (
                <CheckBox key={`${option.id}${graphData?.sponsoredType?.key}`}>
                  {renderCheckBox(
                    graphIndex,
                    graphData,
                    `${option.id}${graphData?.sponsoredType?.key}`,
                    option.label,
                    option.fieldName,
                    `${option.id}${graphData?.sponsoredType?.key}`,
                    option.className,
                    option.spanClass,
                    option.isMetrics,
                  )}
                </CheckBox>
              );
            })}
          </>
        ) : (
          sellerDspMetricsFields.map((option) => {
            return (
              <CheckBox key={option?.id}>
                {renderCheckBox(
                  graphIndex,
                  graphData,
                  option?.id,
                  option?.label,
                  option?.fieldName,
                  option?.keyName,
                  option?.className,
                  option?.spanClass,
                  option?.isMetrics,
                )}
              </CheckBox>
            );
          })
        )}
      </div>
    );
  };

  const renderAsinsPanel = () => {
    return (
      <>
        {reportStatus === 'readyToEdit' &&
        checkSelectedKeys(graphData, [
          'isParentTopAsins',
          'isParentBottomAsins',
          'isParentAllAsins',
        ]).length === 0 ? null : (
          <>
            <div className="liner-titles spacing mt-4 mb-3">
              ASIN Performance
            </div>
            <div className="label">Parent ASINs</div>
            <div
              className={`row mt-2 ${
                graphData?.isParentAllAsins ? 'disabled' : ''
              }`}
            >
              <div className="col-7">
                <CheckBox>
                  {renderCheckBox(
                    graphIndex,
                    graphData,
                    'parentTopAsins',
                    'Top ASINs',
                    'sellerDetails',
                    'isParentTopAsins',
                    'check-container checkboxes-std-services customer-pannel',
                    'checkmark',
                    false,
                  )}
                </CheckBox>
              </div>
              <div
                className={`col-5 pl-0 text-end ${
                  !graphData?.isParentTopAsins ? 'disabled' : ''
                }`}
              >
                {renderNumberOfAsins(
                  graphIndex,
                  graphData,
                  'sellerDetails',
                  'parentTopAsins',
                )}
              </div>
              <div className="col-7 ">
                <CheckBox>
                  {renderCheckBox(
                    graphIndex,
                    graphData,
                    'parentBottomAsins',
                    'Bottom ASINs',
                    'sellerDetails',
                    'isParentBottomAsins',
                    'check-container checkboxes-std-services customer-pannel',
                    'checkmark',
                    false,
                  )}
                </CheckBox>
              </div>
              <div
                className={`col-5 pl-0 text-end ${
                  !graphData?.isParentBottomAsins ? 'disabled' : ''
                }`}
              >
                {renderNumberOfAsins(
                  graphIndex,
                  graphData,
                  'sellerDetails',
                  'parentBottomAsins',
                )}
              </div>
            </div>

            <CheckBox
              className={`${
                graphData?.isParentTopAsins || graphData?.isParentBottomAsins
                  ? 'disabled'
                  : ''
              }`}
            >
              {renderCheckBox(
                graphIndex,
                graphData,
                'parentAllAsins',
                'All ASINs',
                'sellerDetails',
                'isParentAllAsins',
                'check-container checkboxes-std-services customer-pannel',
                'checkmark',
                false,
              )}
            </CheckBox>
          </>
        )}

        {reportStatus === 'readyToEdit' &&
        checkSelectedKeys(graphData, [
          'isChildTopAsins',
          'isChildBottomAsins',
          'isChildAllAsins',
        ]).length === 0 ? null : (
          <>
            <div className="row mt-4">
              <div className="col-6 label">Child ASINs</div>
              <div className="col-6 text-end">
                {productNameToggleOption.map((option) => {
                  return (
                    <ToggleSwitch
                      className={`custom-switch ${
                        graphData?.isChildBottomAsins ||
                        graphData?.isChildTopAsins ||
                        graphData?.isChildAllAsins
                          ? ''
                          : 'disabled'
                      }`}
                      key={`sellerDetails${graphIndex}${option?.id}`}
                    >
                      {renderCheckBox(
                        graphIndex,
                        graphData,
                        `seller${graphIndex}${option?.id}`,
                        option?.label,
                        option?.fieldName,
                        option?.keyName,
                        option?.className,
                        option?.spanClass,
                        option?.isMetrics,
                      )}
                    </ToggleSwitch>
                  );
                })}
              </div>
            </div>
            <div
              className={`row mt-2 ${
                graphData?.isChildAllAsins ? 'disabled' : ''
              }`}
            >
              <div className="col-7 ">
                <CheckBox>
                  {renderCheckBox(
                    graphIndex,
                    graphData,
                    'ChildTopAsins',
                    'Top ASINs',
                    'sellerDetails',
                    'isChildTopAsins',
                    'check-container checkboxes-std-services customer-pannel',
                    'checkmark',
                    false,
                  )}
                </CheckBox>
              </div>
              <div
                className={`col-5 pl-0 text-end ${
                  !graphData?.isChildTopAsins ? 'disabled' : ''
                }`}
              >
                {renderNumberOfAsins(
                  graphIndex,
                  graphData,
                  'sellerDetails',
                  'childTopAsins',
                )}
              </div>
              <div className="col-7 ">
                <CheckBox>
                  {renderCheckBox(
                    graphIndex,
                    graphData,
                    'ChildBottomAsins',
                    'Bottom ASINs',
                    'sellerDetails',
                    'isChildBottomAsins',
                    'check-container checkboxes-std-services customer-pannel',
                    'checkmark',
                    false,
                  )}
                </CheckBox>
              </div>
              <div
                className={`col-5 pl-0 text-end ${
                  !graphData.isChildBottomAsins ? 'disabled' : ''
                }`}
              >
                {renderNumberOfAsins(
                  graphIndex,
                  graphData,
                  'sellerDetails',
                  'childBottomAsins',
                )}
              </div>
            </div>

            <CheckBox
              className={`${
                graphData.isChildTopAsins || graphData.isChildBottomAsins
                  ? 'disabled'
                  : ''
              }`}
            >
              {renderCheckBox(
                graphIndex,
                graphData,
                'ChildAllAsins',
                'All ASINs',
                'sellerDetails',
                'isChildAllAsins',
                'check-container checkboxes-std-services customer-pannel',
                'checkmark',
                false,
              )}
            </CheckBox>
          </>
        )}
      </>
    );
  };

  const renderSellerHealth = () => {
    return reportStatus === 'readyToEdit' &&
      checkSelectedKeys(graphData, [
        'buyBoxGraph',
        'showBuyBoxGraphTable',
        'inventoryScore',
        'positiveFeedback',
        'orderIssue',
      ])?.length === 0 ? null : (
      <>
        <div className="liner-titles spacing mt-4 mb-3">Seller health</div>
        {sellerHealthFields.map((option) => {
          return (
            <CheckBox key={option.id}>
              {renderCheckBox(
                graphIndex,
                graphData,
                option.id,
                option.label,
                option.fieldName,
                option.keyName,
                option.className,
                option.spanClass,
                option.isMetrics,
              )}
            </CheckBox>
          );
        })}

        {buyBoxPercentFieldOptions.map((option) => {
          return (
            <ToggleSwitch className="custom-switch mt-2 ml-4" key={option.id}>
              {renderCheckBox(
                graphIndex,
                graphData,
                option.id,
                option.label,
                option.fieldName,
                option.keyName,
                option.className,
                option.spanClass,
                option.isMetrics,
              )}
            </ToggleSwitch>
          );
        })}
        {checkSelectedKeys(graphData, ['showBuyBoxGraphTable'])?.length ===
        0 ? null : (
          <>
            {renderDataTableViewButton(
              graphData,
              graphIndex,
              'sellerDetails',
              'bbpTableView',
              'bbp-table',
            )}
          </>
        )}
        <div className="clear-fix" />
      </>
    );
  };

  const renderInventoryManagement = () => {
    return reportStatus === 'readyToEdit' &&
      checkSelectedKeys(graphData, [
        'strandedInventory',
        'overstockInventory',
        'understockInventory',
      ])?.length === 0 ? null : (
      <div
        className={
          (selectedTimeFrame && selectedTimeFrame.value === 'year') ||
          (selectedTimeFrame && selectedTimeFrame.value === 'month')
            ? 'disabled'
            : ''
        }
      >
        <div className="liner-titles spacing mt-4 mb-3">
          Inventory Management
        </div>
        {['year', 'month'].includes(selectedTimeFrame?.value) ? (
          <InfoMsg className="mb-3">
            We don&apos;t have data for selected time frame filter.
          </InfoMsg>
        ) : null}

        {inventoryProductNameToggleOption.map((option) => {
          return (
            <ToggleSwitch
              className={`custom-switch mb-2 ${
                graphData?.strandedInventory ||
                graphData?.overstockInventory ||
                graphData?.understockInventory
                  ? ''
                  : 'disabled'
              }`}
              key={`sellerDetails${graphIndex}${option?.id}`}
            >
              {renderCheckBox(
                graphIndex,
                graphData,
                `seller${graphIndex}${option.id}`,
                option.label,
                'sellerDetails',
                option.keyName,
                option.className,
                option.spanClass,
                option.isMetrics,
              )}
            </ToggleSwitch>
          );
        })}

        {inventoryManagementFields.map((option) => {
          return (
            <CheckBox key={`seller${option.id}${graphIndex}`}>
              {renderCheckBox(
                graphIndex,
                graphData,
                `seller${option.id}${graphIndex}`,
                option.label,
                option.fieldName,
                option.keyName,
                option.className,
                option.spanClass,
                option.isMetrics,
              )}
            </CheckBox>
          );
        })}
        {accountType.isHybrid ? (
          <div className="horizontal-line straight-line mt-3" />
        ) : null}
      </div>
    );
  };

  return (
    <React.Fragment key={`seller${graphIndex}`}>
      {accountType.isHybrid ? renderHeadingAndCollapse() : null}

      {graphData !== null ? (
        <Collapse
          isOpened={graphData?.isSellerOpen}
          key={`seller${graphIndex}`}
        >
          <ul className="collapse-inner">
            <li>{rednerGraphFrequency()}</li>
            <li>
              {renderSalesPanelAndToggleOptions()}
              {renderAdPerformancePanel()}
              {renderAsinsPanel()}
              {renderSellerHealth()}
              {renderInventoryManagement()}
            </li>
          </ul>
        </Collapse>
      ) : null}
    </React.Fragment>
  );
};

export default RenderSellerFields;

RenderSellerFields.defaultProps = {
  metricsLoader: false,
  graphIndex: 0,
  reportStatus: '',
  selectedTimeFrame: {},
  graphData: {},
  accountType: {},
  enabledGroupFrequencyList: [],
  showMetricsTooltip: [],
  handleGraphDetailsOnChange: () => {},
  renderCheckBox: () => {},
  getAdTypesSelectComponents: () => {},
  renderRadioButtons: () => {},
  renderNumberOfAsins: () => {},
  checkSelectedKeys: () => {},
  renderErrorMessage: () => {},
  renderDataTableViewButton: () => {},
};

RenderSellerFields.propTypes = {
  metricsLoader: bool,
  graphIndex: number,
  reportStatus: string,
  renderCheckBox: func,
  renderRadioButtons: func,
  renderNumberOfAsins: func,
  checkSelectedKeys: func,
  renderErrorMessage: func,
  renderDataTableViewButton: func,
  getAdTypesSelectComponents: func,
  handleGraphDetailsOnChange: func,
  graphData: shape({}),
  accountType: shape({}),
  selectedTimeFrame: shape({}),
  enabledGroupFrequencyList: arrayOf(Array),
  showMetricsTooltip: arrayOf(shape({})),
};
