import React, { useEffect, useState, useCallback, useMemo } from 'react';

// third party imports
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { bool, func, shape, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

// local file imports
import Goals from './Goals';
import GoalsAndStrategyFilters from './GoalsAndStrategyFilters';
import CustomMonthRangeModal from '../../../../../common/CustomMonthRangeModal';
import { getCustomerDetails } from '../../../../../store/actions/customerState';
import {
  GrayMessageBar,
  PageLoader,
  Tabs,
  WhiteCard,
} from '../../../../../common';
import {
  getGoals,
  getGoalsMarketPlaceList,
  updateGoal,
  deleteGoal,
  getGoalsReport,
} from '../../../../../api';
import {
  HYBRID,
  SELLER,
  VENDOR,
  accountTypeOptionsForGoals,
  calculateMonthDifference,
  dateFilterOptions,
  getPastAndfutureDate,
  getPastAndfutureMonths,
  rivianaAndChildBrandIds,
  vendorSubTabOptions,
} from '../../../../../constants';

function GoalsAndStrategyContainer({
  downloadReportLoader,
  viewComponent,
  clickOnRefreshBtnFlag,
  goalsAccountTypeFlag,
  setClickOnRefreshBtn,
  setIsDataLoading,
  setIsDataAvailable,
  setDownloadReportLoader,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isTablet = useMediaQuery({ minWidth: 768 });
  const params = queryString.parse(history.location.search);
  const customerData = useSelector((state) => state?.customerState?.data);
  const isPPC = customerData?.having_contract_type?.is_having_ppc;
  const isDSP = customerData?.having_contract_type?.is_having_dsp;
  const isDspOnlyBP = isDSP && !isPPC;

  const goalsStartDate = customerData?.goal_set?.start_date;
  const goalsEndDate = customerData?.goal_set?.end_date;
  const { id } = useParams();

  // https://bbe.atlassian.net/browse/PDV-8930
  // Show dynamic messages in goals and cos section
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  const goalsHybridMessage = dynamicMessages?.forecast_hybrid?.placeholder;
  const goalsSellerMessage = dynamicMessages?.forecast_seller?.placeholder;
  const goalsVendorMessage = dynamicMessages?.forecast_vendor?.placeholder;

  // collapse by marketplace hooks
  const [sellerMarketplaceCollapse, setSellerMarketplaceCollapse] = useState(
    [],
  );
  const [shippedCOGsMarketplaceCollapse, setShippedCOGsMarketplaceCollapse] =
    useState([]);
  const [
    orderedRevenueMarketplaceCollapse,
    setOrderedRevenueMarketplaceCollapse,
  ] = useState([]);

  const [marketplaceOptions, setMarketplaceOptions] = useState([]);
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([]);
  const [marketplaceChoices, setMarketplaceChoices] = useState([]);
  const [isMarketplaceDropdownOpen, setIsMarketplaceDropdownOpen] =
    useState(false);
  const [isPrimaryMarketplaceSame, setIsPrimaryMarketplaceSame] =
    useState(false);

  // edit goal hooks
  const [isEditSellerGoals, setIsEditSellerGoals] = useState(false);
  const [isEditOrderedRevenueGoals, setIsEditOrderedRevenueGoals] =
    useState(false);
  const [isEditShippedCOGsGoals, setIsEditShippedCOGsGoals] = useState(false);
  const [isNewGoalAdded, setIsNewGoalAdded] = useState(false);

  // marketplace for new goal hooks
  const [marketplaceForSellerNewGoal, setMarketplaceForSellerNewGoal] =
    useState({});
  const [
    marketplaceForOrderedRevenuNewGoal,
    setMarketplaceForOrderedRevenueNewGoal,
  ] = useState({});
  const [
    marketplaceForShippedCOGsNewGoal,
    setMarketplaceForShippedCOGsNewGoal,
  ] = useState({});

  const [goalLoader, setGoalLoader] = useState(false);
  const [goalsActivityLoader, setGoalsActivityLoader] = useState(false);

  // goals data by acount types
  const [editedSellerGoalsData, setEditedSellerGoalsData] = useState([]);
  const [editedShippedCOGsGoalsData, setEditedShippedCOGsGoalsData] = useState(
    [],
  );
  const [editedOrderedRevenueGoalsData, setEditedOrderedRevenueGoalsData] =
    useState([]);

  // new goals data by acount types
  const [sellerNewGoalsData, setSellerNewGoalsData] = useState([]);
  const [orderedRevenueNewGoalsData, setOrderedRevenueNewGoalsData] = useState(
    [],
  );
  const [shippedCOGsNewGoalsData, setShippedCOGsNewGoalsData] = useState([]);

  const [dropdownLabel, setDropdownLabel] = useState('All Marketplace');
  const [showMonthLimitMsg, setShowMonthLimitMsg] = useState(null);
  const [showCustomMonthRangeModal, setShowCustomMonthRangeModal] =
    useState(false);
  const [isCustomMonthApply, setIsCustomMonthApply] = useState(true);
  const [dateType, setDateType] = useState(dateFilterOptions?.[0]);
  const [customMonthNames, setCustomMonthNames] = useState();
  const [customDate, setCustomDate] = useState({
    startDate: '',
    endDate: '',
  });
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customMonth, setCustomMonth] = useState([
    {
      startMonth: customDate?.startDate,
      endMonth: customDate?.endDate,
    },
  ]);
  const [appliedDate, setAppliedDate] = useState(customMonth);
  const [accountTypeFilterOptions, setAccountTypeFilterOptions] = useState([]);
  const hasSellerGoals =
    goalsAccountTypeFlag?.seller || goalsAccountTypeFlag?.hybrid;
  const hasVendorGoals =
    goalsAccountTypeFlag?.vendor || goalsAccountTypeFlag?.hybrid;
  const hasHybridGoals = goalsAccountTypeFlag?.hybrid || false;
  const InitialGoalsAccountType = hasHybridGoals
    ? HYBRID
    : hasSellerGoals
    ? SELLER
    : hasVendorGoals
    ? VENDOR
    : null;

  const [selectedAccountType, setSelectedAccountType] = useState(
    hasHybridGoals
      ? accountTypeOptionsForGoals?.[0]
      : hasSellerGoals
      ? accountTypeOptionsForGoals?.[1]
      : hasVendorGoals
      ? accountTypeOptionsForGoals?.[2]
      : {},
  );

  // Comfort Products --> CMwIJql
  // Riviana Parent Company and its child brands --> rivianaAndChildBrands,
  const comfortProductsId = ['CMwIJql'];
  const BPsListToHideOrderedRevenue = comfortProductsId.concat(
    rivianaAndChildBrandIds,
  );
  const hideOrderedRevenue = BPsListToHideOrderedRevenue?.some(
    (item) => item === id,
  );

  const [selectedVendorSubTab, setSelectedVendorSubTab] = useState(
    hideOrderedRevenue ? vendorSubTabOptions?.[1] : vendorSubTabOptions?.[0],
  );

  const todaysDate = useMemo(() => {
    const date = new Date();
    date.setDate(1);
    return date;
  }, []);

  const [contractStartDate, setContractstartDate] = useState(todaysDate);
  // const [contractEndDate, setContractEndDate] = useState(todaysDate);

  const [currentMonth, setCurrentMonth] = useState();
  const [goalsDataLoader, setGoalsDataLoader] = useState(false);

  // generate month names
  const bindCustomDateFilterData = useCallback(() => {
    setGoalsDataLoader(true);
    const tempStartDate = new Date(goalsStartDate);
    const tempEndDate = new Date(goalsEndDate);

    const timezoneOffset = tempStartDate?.getTimezoneOffset();
    if (timezoneOffset > 0) {
      tempStartDate.setHours(tempStartDate.getHours() + 12);
      tempEndDate.setHours(tempEndDate.getHours() + 12);
    }

    const startDate = getPastAndfutureDate(tempStartDate, '01');
    const endDate = getPastAndfutureDate(tempEndDate, '01');
    setContractstartDate(startDate);
    //  setContractEndDate(endDate);

    const startMonthDiff = calculateMonthDifference(startDate, todaysDate);
    const endMonthDiff = calculateMonthDifference(todaysDate, endDate);

    const tempMonthNames = [];
    const minLimit = 4;
    const maxLimit = 7;
    let minMonthLimit = 0;
    let maxMonthLimit = 12;
    if (startMonthDiff >= minLimit && endMonthDiff >= maxLimit) {
      minMonthLimit = minLimit;
      maxMonthLimit = maxLimit;
    } else if (startMonthDiff < minLimit && endMonthDiff < maxLimit) {
      minMonthLimit = startMonthDiff;
      maxMonthLimit = endMonthDiff;
    } else if (startMonthDiff >= minLimit && endMonthDiff < maxLimit) {
      minMonthLimit = minLimit;
      maxMonthLimit = endMonthDiff;
    } else {
      minMonthLimit = startMonthDiff;
      if (minLimit - startMonthDiff > endMonthDiff - maxLimit) {
        maxMonthLimit = maxLimit + (endMonthDiff - maxLimit);
      } else {
        maxMonthLimit = maxLimit + (minLimit - startMonthDiff);
      }
    }

    // get past months
    for (let i = minMonthLimit; i > 0; i -= 1) {
      const newDate = new Date();
      newDate.setDate(15);
      const pastMonth = getPastAndfutureMonths(newDate, -i);
      tempMonthNames.push({
        label: pastMonth,
        month: pastMonth?.getMonth() + 1,
        year: pastMonth?.getFullYear(),
        key: i === 1 ? 'scrollToElement' : '',
      });
    }

    // get current month
    const newDate = new Date();
    newDate.setDate(15);
    const todaysMonth = getPastAndfutureMonths(newDate, 0);
    setCurrentMonth(todaysMonth);
    tempMonthNames.push({
      label: todaysMonth,
      month: todaysMonth?.getMonth() + 1,
      year: todaysMonth?.getFullYear(),
    });

    // get future month
    for (let i = 1; i <= maxMonthLimit; i += 1) {
      const newDate1 = new Date();
      newDate1.setDate(15);
      const futureMonth = getPastAndfutureMonths(newDate1, i);
      tempMonthNames.push({
        label: futureMonth,
        month: futureMonth?.getMonth() + 1,
        year: futureMonth?.getFullYear(),
      });
    }

    const lastMonthElement = tempMonthNames?.slice(-1);

    setCustomDate({
      startDate: tempMonthNames?.[0]?.label,
      endDate: lastMonthElement?.[0]?.label,
    });

    const todayDate = new Date();
    const todayMonth = todayDate?.getMonth() + 1;
    const todaysYear = todayDate?.getFullYear();

    const currentMonthData = (element) =>
      element?.month === todayMonth && element?.year === todaysYear;

    const index = tempMonthNames.findIndex(currentMonthData);

    if (viewComponent === 'goals') {
      setCustomStartDate(
        dayjs(tempMonthNames?.[0]?.label).format('YYYY-MM-DD'),
      );
      setCustomEndDate(
        dayjs(lastMonthElement?.[0]?.label).format('YYYY-MM-DD'),
      );
    } else {
      setCustomStartDate(
        dayjs(tempMonthNames?.[index]?.label).format('YYYY-MM-DD'),
      );
      setCustomEndDate(
        dayjs(tempMonthNames?.[index + 3]?.label).format('YYYY-MM-DD'),
      );
    }

    setGoalsDataLoader(false);
  }, [todaysDate, goalsStartDate, goalsEndDate, viewComponent]);

  useEffect(() => {
    bindCustomDateFilterData();
  }, [bindCustomDateFilterData]);

  // set marketplace collapse
  useEffect(() => {
    const sellerMarketplaces = selectedMarketplaces
      ?.filter((mrktplc) =>
        mrktplc?.id ? mrktplc && mrktplc?.accountType === SELLER : null,
      )
      ?.map((item) => {
        return {
          marketplace: item?.id,
          accountType: item?.accountType,
          collapse: true,
        };
      });
    const vendorMarketplaces = selectedMarketplaces
      ?.filter((mrktplc) =>
        mrktplc?.id ? mrktplc && mrktplc?.accountType === VENDOR : null,
      )
      ?.map((item) => {
        return {
          marketplace: item?.id,
          accountType: item?.accountType,
          collapse: true,
        };
      });
    setSellerMarketplaceCollapse(sellerMarketplaces);
    setOrderedRevenueMarketplaceCollapse(vendorMarketplaces);
    setShippedCOGsMarketplaceCollapse(vendorMarketplaces);
  }, [selectedMarketplaces]);

  // bind the dropdown options for account type filter
  const bindAccountTypeFilterOptions = useCallback(
    (sellerFlag, vendorFlag, hybridFlag) => {
      const tempOptions = [];
      accountTypeOptionsForGoals.forEach((item) => {
        tempOptions.push({
          id: item?.id,
          label: item?.label,
          value: item?.value,
          isDisabled:
            item?.value === HYBRID
              ? !hybridFlag
              : item?.value === SELLER
              ? !sellerFlag
              : item?.value === VENDOR
              ? !vendorFlag
              : true,
          dataCy: `BT-Goals-AccountTypeFilter-${item?.value}`,
        });
        return tempOptions;
      });
      setAccountTypeFilterOptions(tempOptions);
      return tempOptions;
    },
    [],
  );

  // generate custom month names
  const generateMonthNames = useCallback((response) => {
    const responseData = response?.length ? response : [];
    const todayDate = new Date();
    const todaysMonth = todayDate?.getMonth();
    const todaysYear = todayDate?.getFullYear();
    const tempMonthNames = [];
    const milestone = responseData?.[0]?.milestones?.[0]?.milestone
      ? responseData?.[0]?.milestones?.[0]?.milestone
      : [];
    const timezoneOffset = new Date()?.getTimezoneOffset();
    if (milestone?.length) {
      for (const item of milestone) {
        const milestoneDate = new Date(`${item?.year}-${item?.month}-01`);
        if (timezoneOffset > 0) {
          milestoneDate.setHours(milestoneDate.getHours() + 12);
        }
        tempMonthNames.push({
          id: `${item?.month}${item?.year}`,
          label: milestoneDate,
          month: item?.month,
          year: item?.year,
          key:
            item?.year === todaysYear &&
            item?.month === todaysMonth &&
            milestone?.length > 4
              ? 'scrollToElement'
              : '',
        });
      }
    }
    return tempMonthNames;
  }, []);

  // get marketplace list
  const getMarketPlace = useCallback(
    (accType) => {
      let finalMarketplaces;
      setGoalsActivityLoader(true);
      setIsDataLoading(true);
      getGoalsMarketPlaceList(id, accType).then((res) => {
        if (res?.status === 200 && res?.data?.length) {
          if (res?.data?.length > 1) {
            setDropdownLabel('All Marketplaces');
          } else {
            const defaultMarketplace = res?.data?.[0];
            setDropdownLabel(
              `${defaultMarketplace?.country_currency?.country} ${
                defaultMarketplace?.is_primary ? '(Primary)' : ''
              } ${isDspOnlyBP ? '' : `(${defaultMarketplace?.account_type})`}`,
            );
          }
          finalMarketplaces = res?.data;

          finalMarketplaces.splice(0, 0, {
            name: 'all',
            country_currency: { country: 'All Marketplaces' },
          });

          let marketPlaceList = [];
          if (finalMarketplaces?.length) {
            for (const option of finalMarketplaces) {
              marketPlaceList.push({
                value: option.name,
                label: `${option?.country_currency?.country} ${
                  option?.is_primary ? '(Primary)' : ''
                }`,
                id: option?.id,
                collapse: true,
                accountType: option?.account_type,
                isPrimary: option?.is_primary,
                uniqueId: `${option?.account_type}-${option?.id}`,
                dataCy: `BT-Goals-${option?.id}`,
              });
            }

            if (marketPlaceList.length === 2) {
              marketPlaceList = marketPlaceList.filter(
                (item) => item.value !== 'all',
              );
            }
            if (accType === 'Hybrid') {
              const sellerPrimaryMarketplace = marketPlaceList?.find(
                (item) => item?.accountType === SELLER && item?.isPrimary,
              );
              const vendorPrimaryMarketplace = marketPlaceList?.find(
                (item) => item?.accountType === VENDOR && item?.isPrimary,
              );
              if (
                sellerPrimaryMarketplace?.id === vendorPrimaryMarketplace?.id
              ) {
                setIsPrimaryMarketplaceSame(true);
              } else {
                setIsPrimaryMarketplaceSame(false);
              }
            } else {
              setIsPrimaryMarketplaceSame(false);
            }

            setMarketplaceOptions(marketPlaceList);
            setSelectedMarketplaces(marketPlaceList);
            setMarketplaceChoices(
              marketPlaceList.filter((item, index) =>
                index !== 0 ? item : null,
              ),
            );
          }
        } else {
          setMarketplaceOptions([]);
          setSelectedMarketplaces([]);
          setMarketplaceChoices([]);
          setDropdownLabel('No Marketplace Available');
        }
        setGoalsActivityLoader(false);
      });
    },
    [id, isDspOnlyBP, setIsDataLoading],
  );

  // callback function get bp goals data
  const getGoalsOfBP = useCallback(
    (
      marketplaces,
      accType,
      startDate,
      endDate,
      sameMarketplaceFlag = false,
    ) => {
      setGoalLoader(true);

      let primaryMarketplace = null;
      if (sameMarketplaceFlag) {
        const sellerPrimaryMarketplace = marketplaces?.find(
          (item) => item?.accountType === SELLER && item?.isPrimary,
        );
        const vendorPrimaryMarketplace = marketplaces?.find(
          (item) => item?.accountType === VENDOR && item?.isPrimary,
        );

        if (sellerPrimaryMarketplace?.id === vendorPrimaryMarketplace?.id) {
          primaryMarketplace = null;
        } else {
          if (sellerPrimaryMarketplace) {
            primaryMarketplace = `${sellerPrimaryMarketplace?.id}-${sellerPrimaryMarketplace?.accountType}`;
          }
          if (vendorPrimaryMarketplace) {
            primaryMarketplace = `${vendorPrimaryMarketplace?.id}-${vendorPrimaryMarketplace?.accountType}`;
          }
        }
      }

      setCustomMonthNames([]);
      if (marketplaces?.length > 0) {
        getGoals(
          id,
          accType,
          marketplaces,
          startDate,
          endDate,
          primaryMarketplace,
        ).then((res) => {
          if (res?.status === 200 && res?.data?.length) {
            const sellerGoals = res?.data?.filter(
              (item) => item?.contract_account_type === 'Seller',
            );
            const orderedRevenueGoals = res?.data?.filter(
              (item) => item?.vendor_sub_type === 'Ordered Revenue',
            );
            const shippedCogsGoals = res?.data?.filter(
              (item) => item?.vendor_sub_type === 'Shipped COGs',
            );

            setIsDataAvailable(true);
            setEditedSellerGoalsData(sellerGoals);
            setEditedShippedCOGsGoalsData(shippedCogsGoals);
            setEditedOrderedRevenueGoalsData(orderedRevenueGoals);
            setCustomMonthNames(generateMonthNames(res?.data));
          }
          setGoalLoader(false);
          setGoalsActivityLoader(false);
          setIsDataLoading(false);
        });
      }
    },
    [id, setIsDataLoading, setIsDataAvailable, generateMonthNames],
  );

  // set marketplace custom label
  const setMarketplaceLabel = (list, item) => {
    if (item?.value === 'all') {
      if (list?.length === 0) {
        setDropdownLabel('Select Marketplace');
      } else {
        setDropdownLabel('All Marketplaces');
      }
    } else if (list?.length === 1) {
      setDropdownLabel(`${list[0]?.label} (${list[0]?.accountType})`);
    } else if (list?.length === 0) {
      setDropdownLabel('Select Marketplace');
    } else if (list?.length === marketplaceOptions?.length) {
      setDropdownLabel('All Marketplaces');
    } else {
      setDropdownLabel('Mulitple');
    }
  };

  // use effect for refresh button
  useEffect(() => {
    if (clickOnRefreshBtnFlag) {
      if (params?.nav === 'goals') {
        getGoalsOfBP(
          selectedMarketplaces,
          InitialGoalsAccountType,
          customStartDate,
          customEndDate,
          isPrimaryMarketplaceSame,
        );
        bindAccountTypeFilterOptions(
          hasSellerGoals,
          hasVendorGoals,
          hasHybridGoals,
        );
        setIsNewGoalAdded(false);
        setIsEditSellerGoals(false);
        setIsEditShippedCOGsGoals(false);
        setIsEditOrderedRevenueGoals(false);
        setSellerNewGoalsData([]);
        setShippedCOGsNewGoalsData([]);
        setOrderedRevenueNewGoalsData([]);
        setSelectedMarketplaces([...marketplaceOptions]);
        setMarketplaceLabel(marketplaceOptions, {});
      }
      setClickOnRefreshBtn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clickOnRefreshBtnFlag,
    customEndDate,
    customStartDate,
    getGoalsOfBP,
    marketplaceOptions,
    params,
    selectedMarketplaces,
    setClickOnRefreshBtn,
    bindAccountTypeFilterOptions,
    hasSellerGoals,
    hasVendorGoals,
    hasHybridGoals,
  ]);

  // download bp goals report
  const downloadGoalsReport = useCallback(() => {
    getGoalsReport(id).then((response) => {
      if (response?.status === 200) {
        const csvLink = response?.data?.url;
        if (csvLink) window.open(csvLink);
        if (response?.data?.message) toast.success(response?.data?.message);
      } else {
        toast.error('Oops! Something went wrong...');
      }
      setDownloadReportLoader(false);
    });
  }, [id, setDownloadReportLoader]);

  // use effect for download report
  useEffect(() => {
    if (downloadReportLoader) {
      downloadGoalsReport();
      setDownloadReportLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadGoalsReport, downloadReportLoader]);

  const deleteAddedGoal = (milestone) => {
    setGoalsActivityLoader(true);
    deleteGoal(milestone?.id).then(() => {
      toast.success('Goal deleted successfully!');
      getGoalsOfBP(
        selectedMarketplaces,
        selectedAccountType?.value,
        customStartDate,
        customEndDate,
        isPrimaryMarketplaceSame,
      );
    });
  };

  // use effect
  useEffect(() => {
    getMarketPlace(InitialGoalsAccountType);
    bindAccountTypeFilterOptions(
      hasSellerGoals,
      hasVendorGoals,
      hasHybridGoals,
    );
  }, [
    InitialGoalsAccountType,
    bindAccountTypeFilterOptions,
    getMarketPlace,
    hasHybridGoals,
    hasSellerGoals,
    hasVendorGoals,
  ]);

  // use effect
  useEffect(() => {
    if (selectedMarketplaces?.length > 0) {
      getGoalsOfBP(
        selectedMarketplaces,
        selectedAccountType?.value,
        customStartDate,
        customEndDate,
        isPrimaryMarketplaceSame,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customEndDate,
    customStartDate,
    getGoalsOfBP,
    selectedAccountType,
    selectedMarketplaces,
  ]);

  // save the goals data
  const saveGoalsData = (updatedGoalsData) => {
    let api = '';
    if (viewComponent === 'goals') {
      setGoalsActivityLoader(true);
      api = updateGoal(
        id,
        updatedGoalsData,
        selectedMarketplaces,
        selectedAccountType?.value,
        customStartDate,
        customEndDate,
      );
    }

    api.then((res) => {
      if (res?.status === 400) {
        toast.error(res?.data?.error);
      }
      if (res?.status === 200) {
        if (viewComponent === 'goals')
          toast.success('Goal updated successfully!');
      }
      setIsNewGoalAdded(false);
      setIsEditSellerGoals(false);
      setIsEditShippedCOGsGoals(false);
      setIsEditOrderedRevenueGoals(false);
      if (viewComponent === 'goals') {
        dispatch(getCustomerDetails(id, false));
        setSellerNewGoalsData([]);
        setShippedCOGsNewGoalsData([]);
        setOrderedRevenueNewGoalsData([]);
        getGoalsOfBP(
          selectedMarketplaces,
          selectedAccountType?.value,
          customStartDate,
          customEndDate,
          isPrimaryMarketplaceSame,
        );
      }
      setGoalsActivityLoader(false);
    });
  };

  // calling goals api when date changes
  const applyDateChangeEvents = (startDate, endDate) => {
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
  };

  // handle custom date range changes events
  const handleCustomDateRangeEvents = (startDate, endDate, dateFlag) => {
    const newEndDate = endDate || startDate;
    let tempStartDate = startDate;
    let tempEndDate = newEndDate;

    if (dateFlag === 'custom') {
      tempStartDate = dayjs(startDate).format('YYYY-MM-DD');
      tempEndDate = dayjs(newEndDate).format('YYYY-MM-DD');
      applyDateChangeEvents(tempStartDate, tempEndDate);
    }
  };

  // apply custom month range
  const applyCustomMonthRange = () => {
    setIsCustomMonthApply(true);
    setAppliedDate(customMonth);
    handleCustomDateRangeEvents(
      customMonth?.[0]?.startMonth,
      customMonth?.[0]?.endMonth,
      'custom',
    );
    setShowCustomMonthRangeModal(false);
  };

  // Handeling the custom month range selection
  const onMonthChange = (date) => {
    const [startMonth, endMonth] = date;

    let updatedEndMonth = endMonth ? new Date(endMonth) : null;

    if (startMonth && endMonth) {
      const selectedMonth = calculateMonthDifference(startMonth, endMonth);

      if (selectedMonth > 11) {
        const updatedDate = new Date(startMonth);
        updatedEndMonth = getPastAndfutureMonths(updatedDate, 11);
        setShowMonthLimitMsg('You can select max 12 months at a time.');
      } else {
        setShowMonthLimitMsg(null);
      }
    }

    setCustomMonth([
      {
        startMonth,
        endMonth: updatedEndMonth,
      },
    ]);
  };

  // set default date
  const setDefaultDate = () => {
    if (isCustomMonthApply) {
      setCustomMonth([
        {
          startMonth: appliedDate?.[0]?.startMonth,
          endMonth: appliedDate?.[0]?.endMonth,
        },
      ]);
    }
  };

  // handle account type change events
  const handleAccountTypeOnChange = (event) => {
    setSelectedAccountType(event);
    getMarketPlace(event?.value);
  };

  // handle date filter events
  const handleDateFilterEvents = (event) => {
    const { value } = event;
    setDateType(event);

    if (value === 'custom') {
      setShowCustomMonthRangeModal(true);
    }
  };

  // handle marketplace filter events
  const handleOnChangeOfMarketplaces = (event, item) => {
    setMarketplaceForSellerNewGoal({});
    setMarketplaceForShippedCOGsNewGoal({});
    setMarketplaceForOrderedRevenueNewGoal({});
    if (
      selectedMarketplaces?.find((marketplace) => marketplace?.value === 'all')
    ) {
      setDropdownLabel('All Marketplaces');
    }

    if (event?.target?.checked) {
      setIsMarketplaceDropdownOpen(false);
      if (item?.value === 'all') {
        setSelectedMarketplaces([...marketplaceOptions]);
        setMarketplaceLabel([...marketplaceOptions], item);
      } else {
        const list = [...selectedMarketplaces];
        list.push(item);

        if (marketplaceChoices?.length === list?.length) {
          list.push(marketplaceOptions[0]);
        }
        setSelectedMarketplaces(list);
        setMarketplaceLabel(list, item);
      }
    }
    if (!event?.target?.checked) {
      if (item?.value === 'all') {
        setIsMarketplaceDropdownOpen(true);
        setSelectedMarketplaces([]);
        setMarketplaceLabel([], item);
      } else {
        const list = selectedMarketplaces?.filter(
          (marketplace) =>
            marketplace?.uniqueId !== item?.uniqueId &&
            marketplace?.value !== 'all',
        );

        setSelectedMarketplaces([...list]);
        setMarketplaceLabel(list, item);
        if (list?.length > 0) {
          setIsMarketplaceDropdownOpen(false);
        } else {
          setIsMarketplaceDropdownOpen(true);
        }
      }
    }
  };

  // update the edited goals data
  const updateGoalsData = () => {
    let updatedGoalsData = [];

    if (editedSellerGoalsData?.length > 0) {
      updatedGoalsData = updatedGoalsData.concat(editedSellerGoalsData);
    }
    if (editedOrderedRevenueGoalsData?.length > 0) {
      updatedGoalsData = updatedGoalsData.concat(editedOrderedRevenueGoalsData);
    }
    if (editedShippedCOGsGoalsData?.length > 0) {
      updatedGoalsData = updatedGoalsData.concat(editedShippedCOGsGoalsData);
    }

    saveGoalsData(updatedGoalsData);
  };

  // update the goals data with newly added goals
  const updateNewGoalsData = (updatedGoals, goalsType) => {
    let newGoalsData = [];
    if (goalsType === 'seller') {
      newGoalsData = newGoalsData.concat(
        updatedGoals,
        editedOrderedRevenueGoalsData,
        editedShippedCOGsGoalsData,
      );
    }
    if (goalsType === 'orderedRevenue') {
      newGoalsData = newGoalsData.concat(
        editedSellerGoalsData,
        updatedGoals,
        editedShippedCOGsGoalsData,
      );
    }
    if (goalsType === 'shippedCOGs') {
      newGoalsData = newGoalsData.concat(
        editedSellerGoalsData,
        editedOrderedRevenueGoalsData,
        updatedGoals,
      );
    }
    saveGoalsData(newGoalsData);
  };

  // display goals dashboard
  const displayGoalsDashboard = (
    goalsType,
    editedGoalsData,
    setEditedGoalsData,
    newGoalsData,
    setNewGoalsData,
    isEditGoals,
    marketplaceForNewGoal,
    setMarketplaceforNewGoal,
    marketplaceCollapse,
    setMarketplaceCollapse,
  ) => {
    return (
      <Goals
        goalsType={goalsType}
        monthNames={customMonthNames}
        isEditGoals={isEditGoals}
        selectedMarketplaces={selectedMarketplaces}
        goalLoader={goalLoader}
        setGoalLoader={setGoalLoader}
        editedGoalsData={editedGoalsData}
        setEditedGoalsData={setEditedGoalsData}
        updateGoals={updateNewGoalsData}
        newGoalsData={newGoalsData}
        setNewGoalsData={setNewGoalsData}
        deleteAddedGoal={deleteAddedGoal}
        isNewGoalAdded={isNewGoalAdded}
        setIsNewGoalAdded={setIsNewGoalAdded}
        marketplaceCollapse={marketplaceCollapse}
        setMarketplaceCollapse={setMarketplaceCollapse}
        marketplaceForNewGoal={marketplaceForNewGoal}
        setMarketplaceforNewGoal={setMarketplaceforNewGoal}
      />
    );
  };

  // display goals seller dashboard
  const displayGoalsSellerDashboard = () => {
    return displayGoalsDashboard(
      'seller',
      editedSellerGoalsData,
      setEditedSellerGoalsData,
      sellerNewGoalsData,
      setSellerNewGoalsData,
      isEditSellerGoals,
      marketplaceForSellerNewGoal,
      setMarketplaceForSellerNewGoal,
      sellerMarketplaceCollapse,
      setSellerMarketplaceCollapse,
    );
  };

  // display goals seller panel
  const displayGoalsSellerPanel = () => {
    return isTablet ? (
      <WhiteCard className="mb-3">
        {isDspOnlyBP ? (
          <div className="black-heading-title mb-3">DSP Forecast</div>
        ) : (
          <div className="black-heading-title mb-3">Seller Forecast</div>
        )}
        {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in goals and cos section */}
        {goalsSellerMessage ? (
          <GrayMessageBar message={goalsSellerMessage} className="my-3" />
        ) : null}
        {displayGoalsSellerDashboard()}
      </WhiteCard>
    ) : (
      <>
        {isDspOnlyBP ? (
          <div className="black-heading-title mb-3">DSP Forecast</div>
        ) : (
          <WhiteCard className="mb-3">
            <div className="black-heading-title">Seller Forecast</div>
          </WhiteCard>
        )}
        {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in goals and cos section */}
        {goalsSellerMessage ? (
          <GrayMessageBar message={goalsSellerMessage} className="my-3" />
        ) : null}
        {displayGoalsSellerDashboard()}
      </>
    );
  };

  // display vendor goals tabs
  const displayVendorGoalsTab = () => {
    return (
      <>
        <div className="black-heading-title mb-3">Vendor Forecast</div>

        <Tabs>
          <ul className="tabs sub-tabs border-none mb-3">
            {vendorSubTabOptions?.map((option) => {
              return (
                <li
                  key={option?.value}
                  className={`${
                    option?.value === 'orderedRevenue' && hideOrderedRevenue
                      ? 'd-none'
                      : ''
                  }
                    ${
                      selectedVendorSubTab?.value === option?.value
                        ? 'active'
                        : ''
                    }`}
                  onClick={() => {
                    setSelectedVendorSubTab(option);
                  }}
                  role="presentation"
                >
                  {option?.label}
                </li>
              );
            })}
          </ul>
        </Tabs>
        {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in goals and cos section */}
        {goalsVendorMessage ? (
          <GrayMessageBar message={goalsVendorMessage} />
        ) : null}
      </>
    );
  };

  // display ordered revenue dashboard
  const displayOrderedRevenueDashboard = () => {
    return displayGoalsDashboard(
      'orderedRevenue',
      editedOrderedRevenueGoalsData,
      setEditedOrderedRevenueGoalsData,
      orderedRevenueNewGoalsData,
      setOrderedRevenueNewGoalsData,
      isEditOrderedRevenueGoals,
      marketplaceForOrderedRevenuNewGoal,
      setMarketplaceForOrderedRevenueNewGoal,
      orderedRevenueMarketplaceCollapse,
      setOrderedRevenueMarketplaceCollapse,
    );
  };

  // display shipped cogs dashboard
  const displayShippedCOGsDashboard = () => {
    return displayGoalsDashboard(
      'shippedCOGs',
      editedShippedCOGsGoalsData,
      setEditedShippedCOGsGoalsData,
      shippedCOGsNewGoalsData,
      setShippedCOGsNewGoalsData,
      isEditShippedCOGsGoals,
      marketplaceForShippedCOGsNewGoal,
      setMarketplaceForShippedCOGsNewGoal,
      shippedCOGsMarketplaceCollapse,
      setShippedCOGsMarketplaceCollapse,
    );
  };

  // display goals vendor panel
  const displayGoalsVendorPanel = () => {
    return isTablet ? (
      <WhiteCard className="mb-3">
        {displayVendorGoalsTab()}
        {selectedVendorSubTab?.value === 'orderedRevenue'
          ? displayOrderedRevenueDashboard()
          : displayShippedCOGsDashboard()}
      </WhiteCard>
    ) : (
      <>
        <WhiteCard className="mb-3">{displayVendorGoalsTab()}</WhiteCard>
        {selectedVendorSubTab?.value === 'orderedRevenue'
          ? displayOrderedRevenueDashboard()
          : displayShippedCOGsDashboard()}
      </>
    );
  };

  // display goals filters
  const displayGoalsFilters = () => {
    const isEditGoals =
      isEditSellerGoals || isEditShippedCOGsGoals || isEditOrderedRevenueGoals;
    const newGoalsDataLength =
      sellerNewGoalsData?.length > 0 ||
      orderedRevenueNewGoalsData?.length > 0 ||
      shippedCOGsNewGoalsData?.length > 0;
    const editedGoalsDataLength =
      editedSellerGoalsData?.length ||
      editedShippedCOGsGoalsData?.length ||
      editedOrderedRevenueGoalsData?.length;
    return (
      <GoalsAndStrategyFilters
        accountTypeOptions={accountTypeFilterOptions}
        selectedAccountType={selectedAccountType}
        marketplaceOptions={marketplaceOptions}
        selectedMarketplaces={selectedMarketplaces}
        dropdownLabel={dropdownLabel}
        isMarketplaceDropdownOpen={isMarketplaceDropdownOpen}
        dateType={dateType}
        isCustomMonthApply={isCustomMonthApply}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        dateFilterOptions={dateFilterOptions}
        updateGoals={updateGoalsData}
        isEditGoals={isEditGoals}
        setEditGoals={() => {
          setIsEditSellerGoals(true);
          setIsEditShippedCOGsGoals(true);
          setIsEditOrderedRevenueGoals(true);
        }}
        newGoalsDataLength={newGoalsDataLength}
        editedGoalsDataLength={editedGoalsDataLength}
        handleDateFilterEvents={handleDateFilterEvents}
        setMarketplaceforNewGoal={() => {
          setMarketplaceForSellerNewGoal({});
          setMarketplaceForShippedCOGsNewGoal({});
          setMarketplaceForOrderedRevenueNewGoal({});
        }}
        handleAccountTypeOnChange={handleAccountTypeOnChange}
        handleOnChangeOfMarketplaces={handleOnChangeOfMarketplaces}
        setIsMarketplaceDropdownOpen={setIsMarketplaceDropdownOpen}
      />
    );
  };

  // render goals month range modals
  const renderGoalsMonthRangeModals = () => {
    // We have provided random future date - 2100/12/31 to maxDate prop in custom month picker
    return (
      <CustomMonthRangeModal
        id="GoalsMonthPicker"
        isOpen={showCustomMonthRangeModal}
        title="Select Month Range"
        className="custom-month-range"
        minDate={contractStartDate}
        // maxDate={contractEndDate}
        maxDate={new Date('2100/12/31')} // PDV-8978 - allow user to select future month
        startMonth={customMonth?.[0]?.startMonth}
        endMonth={customMonth?.[0]?.endMonth}
        onClick={() => {
          setShowCustomMonthRangeModal(false);
          setDefaultDate();
        }}
        onDateChange={onMonthChange}
        onApply={() => {
          applyCustomMonthRange();
          setMarketplaceForSellerNewGoal({});
          setMarketplaceForShippedCOGsNewGoal({});
          setMarketplaceForOrderedRevenueNewGoal({});
        }}
        currentMonth={currentMonth}
        helperText={showMonthLimitMsg}
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div className="cutomer-middle-panel">
      <div className={goalLoader || goalsActivityLoader ? 'disabled' : ''}>
        {displayGoalsFilters()}
      </div>
      {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in goals and cos section */}
      {selectedAccountType?.value === HYBRID && goalsHybridMessage ? (
        <GrayMessageBar message={goalsHybridMessage} className="my-3" />
      ) : null}
      {params?.nav === 'goals' ? (
        goalLoader || goalsDataLoader ? (
          <PageLoader
            component="agrement-details"
            color="#FF5933"
            type="detail"
            width={40}
            height={40}
          />
        ) : hasHybridGoals ||
          hasSellerGoals ||
          hasVendorGoals ||
          selectedMarketplaces?.length > 0 ? (
          <div className={goalLoader || goalsActivityLoader ? 'disabled' : ''}>
            {selectedAccountType?.value === SELLER ||
            selectedAccountType?.value === HYBRID
              ? displayGoalsSellerPanel()
              : null}
            {selectedAccountType?.value === VENDOR ||
            selectedAccountType?.value === HYBRID
              ? displayGoalsVendorPanel()
              : null}
          </div>
        ) : (
          <WhiteCard className="mb-3">
            <p
              className="gray-medium-text my-2 text-center"
              data-cy="BT-NoDataMessages-Goals"
            >
              No Goals created
            </p>
          </WhiteCard>
        )
      ) : null}
      {showCustomMonthRangeModal ? renderGoalsMonthRangeModals() : null}
    </div>
  );
}

export default GoalsAndStrategyContainer;

GoalsAndStrategyContainer.defaultProps = {
  downloadReportLoader: false,
  viewComponent: '',
  clickOnRefreshBtnFlag: false,
  goalsAccountTypeFlag: {},
  setClickOnRefreshBtn: () => {},
  setIsDataLoading: () => {},
  setIsDataAvailable: () => {},
  setDownloadReportLoader: () => {},
};

GoalsAndStrategyContainer.propTypes = {
  downloadReportLoader: bool,
  viewComponent: string,
  clickOnRefreshBtnFlag: bool,
  goalsAccountTypeFlag: shape({}),
  setClickOnRefreshBtn: func,
  setIsDataLoading: func,
  setIsDataAvailable: func,
  setDownloadReportLoader: func,
};
