/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';

import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { arrayOf, shape, string } from 'prop-types';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  CONVERSIONRATE,
  GLANCEVIEWS,
  ORDEREDREVENUE,
  ORDEREDUNITS,
  SHIPPEDCOGS,
  SHIPPEDUNITS,
  VendorMetricsColorSet,
  VendorMetricsNames,
} from '../../../../../../constants';

am4core.useTheme(am4themes_dataviz);
am4core.color('red');

const _ = require('lodash');

function VendorSalePerformanceGoalsChart({
  chartId,
  chartData,
  currencySymbol,
  selectedMetrics,
  selectedDF,
}) {
  const chart = useRef(null);
  useEffect(() => {
    const currencyAxisValue = [ORDEREDREVENUE, SHIPPEDCOGS];
    const percentageAxisValue = [CONVERSIONRATE];
    const unitAxisValues = [GLANCEVIEWS, ORDEREDUNITS, SHIPPEDUNITS];
    chart.current = am4core.create(chartId, am4charts.XYChart);
    chart.current.data = chartData;
    chart.current.paddingRight = 10;
    chart.current.paddingLeft = 0;
    chart.current.margin(0);
    chart.current.logo.disabled = true; // disable amchart logo
    // render X axis
    const dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.labels.template.location = 0.5;
    dateAxis.dy = 10;
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.periodChangeDateFormats.setKey('month', 'MMM');

    function createValueAxis(axisColor) {
      const localValueAxis = chart.current.yAxes.push(
        new am4charts.ValueAxis(),
      );
      localValueAxis.renderer.grid.template.disabled = true;
      localValueAxis.cursorTooltipEnabled = false;
      localValueAxis.renderer.baseGrid.disabled = true;
      localValueAxis.numberFormatter = new am4core.NumberFormatter();
      localValueAxis.numberFormatter.numberFormat = `#.#a`;
      localValueAxis.extraMax = 0.005;
      localValueAxis.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: 'K' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
      ];
      localValueAxis.numberFormatter.smallNumberPrefixes = [];
      localValueAxis.stroke = am4core.color(axisColor);
      localValueAxis.strokeOpacity = 0.5;
      return localValueAxis;
    }

    let valueAxis = createValueAxis();

    // Add cursor
    chart.current.cursor = new am4charts.XYCursor();
    chart.current.cursor.lineY.disabled = true;
    chart.current.cursor.lineX.disabled = true;
    chart.current.cursor.behavior = 'none';

    function renderTooltip(name, color, value, currency, percent, formatter) {
      const tooltipText = ` <ul style="padding:0; margin: 0 0 4px 0; max-width: 240px;">
      <li style="display: inline-block;">
        {' '}
        <div style="background-color: ${color};
        border: 1px solid white;
        border-radius: ${name === 'Previous' ? '2px' : '100%'};
        width: 10px;
        height: 10px;" />
      </li>
      <li style="display: inline-block;">
        <div style="color: #f4f6fc;
        text-transform: uppercase;
        font-size: 11px;
        padding-left: 5px;">${name} </div>
      </li>
      <li style="display: inline-block; float: right; margin-left: 25px;">
        <div style=" color: white;
        font-size: 16px; text-align: right;
       
        ">${currency !== null ? currency : ''}${
        formatter !== null
          ? `{${value}.formatNumber('${formatter}')}`
          : `{${value}}`
      }${percent !== null ? percent : ''}
      </div>
      </li>
    </ul>
  
    `;

      return tooltipText;
    }

    function bindValueAxisFormatter(item) {
      let format = '';
      if (currencyAxisValue.includes(item)) {
        format = `${currencySymbol}#.#a`;
      } else if (unitAxisValues.includes(item)) {
        format = `#.#a`;
      } else if (percentageAxisValue.includes(item)) {
        format = `#.#'%'`;
      }
      return format;
    }

    const selectedMatricsFlag = _.size(selectedMetrics) <= 2;

    if (selectedMatricsFlag) {
      // create object of 2nd value axis

      const snapToSeries = [];
      let tooltipValue = '';
      let lableName = '';

      // loop for generate tooltip
      _.keys(selectedMetrics).map((item) => {
        lableName = VendorMetricsNames[item];
        const currentLabel = `${item}CurrentLabel`;
        const goalsLabel = `${item}GoalsLabel`;
        const colorCode = VendorMetricsColorSet[item];

        tooltipValue = `${tooltipValue} ${lableName}`;
        if (currencyAxisValue.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            'Recent',
            colorCode,
            currentLabel,
            currencySymbol,
            null,
            null,
          )}`;

          tooltipValue = `${tooltipValue} ${renderTooltip(
            'Goals',
            colorCode,
            goalsLabel,
            currencySymbol,
            null,
            null,
          )}`;
        } else if (unitAxisValues.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            'Recent',
            colorCode,
            currentLabel,
            null,
            null,
            null,
          )}`;

          tooltipValue = `${tooltipValue} ${renderTooltip(
            'Goals',
            colorCode,
            goalsLabel,
            null,
            null,
            null,
          )}`;
        } else if (percentageAxisValue.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            'Recent',
            colorCode,
            currentLabel,
            null,
            '%',
            null,
          )}`;

          tooltipValue = `${tooltipValue} ${renderTooltip(
            'Goals',
            colorCode,
            goalsLabel,
            null,
            '%',
            null,
          )}`;
        }
        return '';
      });

      _.keys(selectedMetrics).map((item, index) => {
        const series = chart.current.series.push(new am4charts.LineSeries());
        const series2 = chart.current.series.push(new am4charts.LineSeries());
        const currentValue = `${item}Current`;
        const goalsValue = `${item}Goals`;
        const seriesName = `${item}Series`;
        const colorCode = VendorMetricsColorSet[item];

        valueAxis = createValueAxis(colorCode);
        series.yAxis = valueAxis;
        series2.yAxis = valueAxis;
        valueAxis.numberFormatter.numberFormat = bindValueAxisFormatter(item);

        if (index === 1) {
          valueAxis.renderer.opposite = true;
        }

        series.dataFields.valueY = currentValue;
        series.dataFields.dateX = 'date';
        series.name = seriesName;
        series.strokeWidth = 2;
        series.tooltipHTML = `${tooltipValue}`;
        series.stroke = am4core.color(colorCode);
        series.fill = am4core.color('#2e384d');

        const circleBullet = series.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.fill = am4core.color(colorCode);
        circleBullet.circle.strokeWidth = 1;
        circleBullet.circle.radius = 5;

        series2.dataFields.valueY = goalsValue;
        series2.name = goalsValue;
        series2.dataFields.dateX = 'date';
        series2.strokeWidth = 2;
        series2.stroke = am4core.color(colorCode);
        series2.tooltipHTML = `${tooltipValue}`;
        series2.fill = am4core.color('#2e384d');
        series2.strokeDasharray = '8,4';
        // series2.propertyFields.strokeDasharray = dashLine;

        const bullet = series2.bullets.push(new am4charts.Bullet());
        const square = bullet.createChild(am4core.Rectangle);
        square.fill = am4core.color(colorCode);
        square.width = 8;
        square.height = 8;
        square.horizontalCenter = 'middle';
        square.verticalCenter = 'middle';

        snapToSeries.push(series);
        snapToSeries.push(series2);
        return '';
      });

      chart.current.cursor.snapToSeries = snapToSeries;
    } else {
      // else part- for multiple metrics selected

      const snapToSeries = [];
      let tooltipValue = '';

      // loop for genearate tooltip
      _.keys(selectedMetrics).map((item) => {
        const currentLabel = `${item}CurrentLabel`;

        if (currencyAxisValue.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            VendorMetricsNames[item],
            VendorMetricsColorSet[item],
            currentLabel,
            currencySymbol,
            null,
            null,
          )}`;
        } else if (unitAxisValues.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            VendorMetricsNames[item],
            VendorMetricsColorSet[item],
            currentLabel,
            null,
            null,
            null,
          )}`;
        } else if (percentageAxisValue.includes(item)) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            VendorMetricsNames[item],
            VendorMetricsColorSet[item],
            currentLabel,
            null,
            '%',
            null,
          )}`;
        }
        return '';
      });

      _.keys(selectedMetrics).map((item, index) => {
        const series = chart.current.series.push(new am4charts.LineSeries());

        valueAxis = createValueAxis(VendorMetricsColorSet[item]);
        series.yAxis = valueAxis;
        valueAxis.numberFormatter.numberFormat = bindValueAxisFormatter(item);
        if (index === 1 || index === 3) {
          valueAxis.renderer.opposite = true;
        }

        series.dataFields.valueY = `${item}Current`;
        series.dataFields.dateX = 'date';
        series.name = `${item}Series`;
        series.strokeWidth = 2;
        series.tooltipHTML = `${tooltipValue}`;
        series.stroke = am4core.color(VendorMetricsColorSet[item]);
        series.fill = am4core.color('#2e384d');

        const circleBullet = series.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.fill = am4core.color(VendorMetricsColorSet[item]);
        circleBullet.circle.strokeWidth = 1;
        circleBullet.circle.radius = 5;

        snapToSeries.push(series);
        return '';
      });

      chart.current.cursor.snapToSeries = snapToSeries;
    }
    return () => chart.current && chart.current.dispose();
  }, [chartData, chartId, currencySymbol, selectedDF, selectedMetrics]);

  return <div id={chartId} style={{ width: '100%', height: '510px' }} />;
}

export default VendorSalePerformanceGoalsChart;

VendorSalePerformanceGoalsChart.defaultProps = {
  currencySymbol: '',
  selectedDF: '',
  chartData: [],
  selectedMetrics: {},
};

VendorSalePerformanceGoalsChart.propTypes = {
  currencySymbol: string,
  selectedDF: string,
  chartId: string.isRequired,
  chartData: arrayOf(shape({})),
  selectedMetrics: shape({}),
};
