// React imports
import React from 'react';

// Third party imports
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

// Local file imports
import StrandedAndRestockInventoryMobileView from './StrandedAndRestockInventoryMobileView';
import InventoryManagementInfoIcon from '../../../Customer/CustomerDetailView/InventoryManagement/InventoryManagementInfoIcon';
import { InventoryTableWrapper } from './InventoryStyle';
import {
  inventoryNoDataMessage,
  restockInventoryTableheader,
} from '../../../../constants';
import {
  CommonPagination,
  GrayMessageBar,
  Table,
  WhiteCard,
} from '../../../../common';

const StrandedAndRestockInventory = ({
  // boolean
  loader,
  // number
  count,
  pageNumber,
  // string
  orderByApi,
  // object
  orderBy,
  // array
  strandedInventoryData,
  // function
  formatNumber,
  onClickOrder,
  onHandlePageChange,
  displayNoDataMessage,
  displayPerformanceLoader,
  displayDownloadReportLoader,
  displayCompanyNameLogoAndBGS,
  redirectingToCustomerDetailview,
  displayUpDownArrowforTableHeader,
  displayMetricsChangeValueWithArrow,
}) => {
  const noDataMessage = inventoryNoDataMessage?.strandedRestock;
  const isTablet = useMediaQuery({ minWidth: 768 });
  const dashboardDynamicError = useSelector(
    (state) => state.userState?.showDynamicMsg,
  );
  const strandedMessage =
    dashboardDynamicError?.stranded_inventory?.placeholder;

  // display metrics value with change
  const displayMetricsValueWithChange = (value, percentValue) => {
    return (
      <>
        <span className={`d-inline-block ${isTablet ? 'width30' : ''}`}>
          {formatNumber(value, '', '', true)}
        </span>
        {displayMetricsChangeValueWithArrow(
          percentValue,
          formatNumber,
          isTablet ? 'd-inline-block' : 'text-extra-medium ml-2',
          '',
        )}
      </>
    );
  };

  // display stranded & restock inventory table head
  const renderTableHeader = () => {
    return (
      <>
        <tr>
          {restockInventoryTableheader.map((item) => {
            return (
              <th
                key={item?.key}
                id={item?.key}
                width={item?.width}
                onClick={() => {
                  if (item?.key !== 'customer') onClickOrder(item?.key);
                }}
                className={`product-header ${
                  item?.key !== 'customer' ? 'cursor' : ''
                } `}
              >
                {item?.label}
                {item?.key !== 'customer'
                  ? displayUpDownArrowforTableHeader(
                      item?.key,
                      orderBy,
                      orderByApi,
                    )
                  : null}
              </th>
            );
          })}
        </tr>
      </>
    );
  };

  // display stranded and restock inventory table body
  const renderInventoryData = (item) => {
    return (
      <>
        <tr
          className="cursor"
          onClick={() => {
            redirectingToCustomerDetailview(item?.customer_id);
          }}
          key={item?.customer_id}
        >
          <td className="product-body">{displayCompanyNameLogoAndBGS(item)}</td>
          <td className="product-body">
            {displayMetricsValueWithChange(
              item?.understocked,
              item?.understocked_change,
            )}
          </td>
          <td className="product-body">
            {displayMetricsValueWithChange(
              item?.overstocked,
              item?.overstocked_change,
            )}
          </td>
          <td className="product-body">
            {displayMetricsValueWithChange(item?.available, item?.change)}
          </td>
        </tr>
      </>
    );
  };

  // function - display inventory desktop view
  const displayStrandedRestockDesktopView = () => {
    return (
      <>
        <InventoryTableWrapper>
          <div className="straight-line horizontal-line spacing d-md-block d-none" />
          <Table className="inventory-table">
            <thead className={loader ? 'disabled' : ''}>
              {renderTableHeader()}
            </thead>
            {strandedInventoryData?.length ? (
              <tbody>
                {strandedInventoryData?.map((item) => {
                  return renderInventoryData(item);
                })}
              </tbody>
            ) : null}
          </Table>
          {loader ? displayPerformanceLoader() : null}
          {!loader && !strandedInventoryData?.length
            ? displayNoDataMessage(noDataMessage)
            : null}
        </InventoryTableWrapper>
      </>
    );
  };

  // function - display inventory mobile view
  const displayStandedRestockMobileView = () => {
    return (
      <>
        {loader ? (
          displayPerformanceLoader()
        ) : strandedInventoryData?.length ? (
          <StrandedAndRestockInventoryMobileView
            loader={loader}
            strandedInventoryData={strandedInventoryData}
            formatNumber={formatNumber}
            displayCompanyNameLogoAndBGS={displayCompanyNameLogoAndBGS}
            displayMetricsValueWithChange={displayMetricsValueWithChange}
            redirectingToCustomerDetailview={redirectingToCustomerDetailview}
          />
        ) : (
          displayNoDataMessage(noDataMessage)
        )}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      <WhiteCard className="mb-3">
        <div className="row">
          <div className="col-md-10 col-12 black-heading-title mb-3">
            Stranded & Restock Inventory
            <InventoryManagementInfoIcon infoIconId="stranded_and_restock_table" />
          </div>
          <div
            className={`col-md-2 col-12 pr-md-2 text-right pt-md-2 ${
              loader ? 'disabled' : ''
            }`}
          >
            {displayDownloadReportLoader('stranded-restock')}
          </div>
        </div>
        {strandedMessage ? <GrayMessageBar message={strandedMessage} /> : null}
        {isTablet
          ? displayStrandedRestockDesktopView()
          : displayStandedRestockMobileView()}
        {count > 10 ? (
          <>
            <div className="straight-line horizontal-line mt-3" />
            <CommonPagination
              count={count}
              pageNumber={pageNumber}
              handlePageChange={onHandlePageChange}
            />
          </>
        ) : null}
      </WhiteCard>
    </>
  );
};

export default StrandedAndRestockInventory;

StrandedAndRestockInventory.defaultProps = {
  // boolean
  loader: false,
  // number
  count: 0,
  pageNumber: 1,
  // string
  orderByApi: '',
  // object
  orderBy: {},
  // array
  strandedInventoryData: [],
  // function
  onClickOrder: () => {},
  formatNumber: () => {},
  onHandlePageChange: () => {},
  displayNoDataMessage: () => {},
  displayPerformanceLoader: () => {},
  displayDownloadReportLoader: () => {},
  displayCompanyNameLogoAndBGS: () => {},
  redirectingToCustomerDetailview: () => {},
  displayUpDownArrowforTableHeader: () => {},
  displayMetricsChangeValueWithArrow: () => {},
};

StrandedAndRestockInventory.propTypes = {
  // boolean
  loader: bool,
  // number
  count: number,
  pageNumber: number,
  // string
  orderByApi: string,
  // object
  orderBy: shape({}),
  // array
  strandedInventoryData: arrayOf(shape({})),
  // function
  formatNumber: func,
  onClickOrder: func,
  onHandlePageChange: func,
  displayNoDataMessage: func,
  displayPerformanceLoader: func,
  displayDownloadReportLoader: func,
  displayCompanyNameLogoAndBGS: func,
  redirectingToCustomerDetailview: func,
  displayUpDownArrowforTableHeader: func,
  displayMetricsChangeValueWithArrow: func,
};
