export const cosViewTypes = [
  {
    label: 'General',
    value: 'general',
  },
  { label: 'Design', value: 'design' },
  { label: 'Copy', value: 'copy' },
];

export const cosDatetype = [
  {
    label: 'Current 7 Days',
    value: '7days',
  },
];

export const stickyColumnHeaders = [
  {
    columns: [
      {
        label: 'Status',
        key: 'parent_status',
        id: 'parent_status',
        // width: '70px',
        type: 'checkbox',
        // className: ' sticky-column header sticky-panel',
        options: [
          { label: 'Waiting on assets', value: 'waiting on assets' },
          { label: 'In Progress - Creative', value: 'in progress - creative' },
          { label: 'Ready for Upload', value: 'ready for upload' },
          { label: 'Uploaded', value: 'uploaded' },
        ],
      },
      {
        label: 'Parent ASINS',
        key: 'parent_asin',
        id: 'parent_asin',
        // width: '150px',
        type: 'text',
        charLimit: 10,
        // className: 'sticky-column header sticky-panel col-left-70 ',
        info: 'ASIN is a combination of numbers and characters fixed at 10 digits. Input N/A if ASIN is not needed.',
      },
      {
        label: 'Status',
        key: 'child_status',
        id: 'child_status',
        // width: '70px',
        type: 'checkbox',
        // className: 'sticky-column header sticky-panel col-left-190 ',

        options: [
          { label: 'Waiting on assets', value: 'waiting on assets' },
          { label: 'In Progress - Creative', value: 'in progress - creative' },
          { label: 'Ready for Upload', value: 'ready for upload' },
          { label: 'Uploaded', value: 'uploaded' },
        ],
      },
      {
        label: 'Child ASINS',
        key: 'child_asin',
        id: 'child_asin',
        // width: '150px',
        type: 'text',
        charLimit: 10,
        info: 'ASIN is a combination of numbers and characters fixed at 10 digits. Input N/A if ASIN is not needed.',
        // className: 'sticky-column header sticky-panel col-left-260 ',
      },
    ],
  },
];

// sticky-column header sticky-panel
export const tableHeaders = [
  {
    view: 'general',
    columns: [
      {
        label: 'SKU',
        key: 'sku',
        id: 'general',
        width: '200px',
        type: 'text',
        charLimit: 40,
        info: 'The total combination of numbers and characters including hyphens, should not exceed 40 digits.',
      },
      {
        label: 'Product Title',
        key: 'product_title',
        width: '200px',
        type: 'text',
        charLimit: 200,
      },
      { label: 'PDP URL', key: 'pdp_url', width: '200px', type: 'text' },
      { label: 'BP URL', key: 'bp_url', width: '200px', type: 'text' },
      {
        label: 'Links to Assets',
        key: 'link_to_assets',
        width: '200px',
        type: 'text',
      },
      { label: 'Month/Batch', key: 'month', width: '200px', type: 'date' },
      {
        label: 'Assets Received',
        key: 'asset_recieved',
        width: '200px',
        type: 'radio',
      },
    ],
  },
  {
    view: 'design',
    columns: [
      {
        label: 'Assigned Designer',
        key: 'assigned_designer',
        valueKey: 'assigned_designer_info',
        id: 'design',
        width: '200px',
        type: 'avtar',
      },
      {
        label: 'Design Project Type',
        key: 'design_project_type',
        width: '200px',
        type: 'choice',
        options: [
          { label: 'Brand Sample', value: 'brand sample' },
          { label: 'Infographics', value: 'infographics' },
          { label: 'A+', value: 'a+' },
          { label: 'Premium A+', value: 'premium a+' },
          { label: 'Storefront', value: 'storefront' },
          { label: 'Brand Story', value: 'brand story' },
          { label: 'DSP Ads', value: 'dsp ads' },
          { label: 'Revisions', value: 'revisions' },
          { label: 'Main Images ', value: 'main images' },
          { label: 'Product Documents', value: 'product documents' },
          { label: 'Email Inserts', value: 'email inserts' },
          { label: 'Brand Posts ', value: 'brand posts' },
          { label: 'Video', value: 'video' },
        ],
      },
      {
        label: 'Design Optimization Level',
        key: 'design_optimization_level',
        width: '200px',
        type: 'choice',
        options: [
          { label: 'Original Listing', value: 'original listing' },
          { label: 'Similar Listing', value: 'similar listing' },
        ],
      },
      {
        label: 'Assigned Production Designer',
        key: 'assigned_production_designer',
        valueKey: 'assigned_production_designer_info',
        width: '200px',
        type: 'avtar',
      },
      {
        label: 'Design Notes',
        key: 'design_notes',
        width: '200px',
        type: 'text',
        charLimit: 140,
        info: 'Maximum of 140 characters is allowed.',
      },
      {
        label: 'Design Project Status',
        key: 'design_project_status',
        width: '200px',
        type: 'choice',
        options: [
          { label: 'In Process', value: 'in process' },
          { label: 'On Hold', value: 'on hold' },
          { label: 'Sent to Copyeditor', value: 'sent to copyeditor' },
          { label: 'Sent to Copywriter', value: 'sent to copywriter' },
          { label: 'Completed', value: 'completed' },
          { label: 'Revision', value: 'revision' },
        ],
      },
      {
        label: 'Link to Completed Design Deliverables ',
        key: 'link_to_completed_design_deliverables',
        width: '200px',
        type: 'text',
      },
      {
        label: 'Design Due Date',
        key: 'design_due_date',
        width: '200px',
        type: 'date',
      },
      {
        label: 'Design Date Completed',
        key: 'design_date_completed',
        width: '200px',
        type: 'date',
      },
      {
        label: 'Design Link to Jira Project',
        key: 'design_link_to_jira_project',
        width: '200px',
        type: 'text',
      },
      {
        label: 'Design: Published',
        key: 'design_published',
        width: '200px',
        type: 'radio',
      },
      {
        label: 'Design: Published Date',
        key: 'design_published_date',
        width: '200px',
        type: 'date',
      },
    ],
  },
  {
    view: 'copy',
    columns: [
      {
        label: 'Copy Project Type',
        key: 'copy_project_type',
        id: 'copy',
        width: '200px',
        type: 'choice',
        options: [
          { label: 'Listing Copy', value: 'listing copy' },
          { label: 'A+', value: 'a+' },
          { label: 'Premium A+ ', value: 'premium a+' },
          { label: 'Infographics Copy ', value: 'infographics copy' },
          { label: 'Revision ', value: 'revision' },
          { label: 'Ads Copy ', value: 'ads copy' },
          {
            label: 'Back-End Search Terms Only ',
            value: 'back-end search terms only',
          },
          { label: 'Keyword Research Only ', value: 'keyword research only' },
          {
            label: 'Brand Refresh - Brand Sample ',
            value: 'brand refresh - brand sample',
          },
          { label: 'Brand Story ', value: 'brand story' },
          { label: 'Brand Post ', value: 'brand post' },
          { label: 'Re-Optimization ', value: 're-optimization' },
          { label: 'Storefront ', value: 'storefront' },
        ],
      },
      {
        label: 'Copy Optimization Level',
        key: 'copy_optimization_level',
        width: '200px',
        type: 'choice',
        options: [
          { label: 'Original Listing', value: 'original listing' },
          { label: 'Similar Listing', value: 'similar listing' },
          { label: 'Title-Only Variation', value: 'title-only variation' },
        ],
      },
      {
        label: 'Copy Notes',
        key: 'copy_notes',
        width: '200px',
        type: 'text',
        charLimit: 140,
        info: 'Maximum of 140 characters is allowed.',
      },
      {
        label: 'Copy Project Status',
        key: 'copy_project_status',
        width: '200px',
        type: 'choice',
        options: [
          { label: 'Keyword Research', value: 'keyword research' },
          { label: 'Copywriting', value: 'copywriting' },
          { label: 'Sub Editing', value: 'sub editing' },
          { label: 'Copy/Proof Editing', value: 'copy/proof editing' },
          { label: 'Completed', value: 'completed' },
          { label: 'On Hold', value: 'on hold' },
          { label: 'Waiting for Design', value: 'waiting for design' },
        ],
      },
      {
        label: 'Link to Completed Copy Deliverables',
        key: 'link_to_completed_copy_deliverables',
        width: '200px',
        type: 'text',
      },
      {
        label: 'Link to Jira Project',
        key: 'copy_link_to_jira_project',
        width: '200px',
        type: 'text',
      },
      {
        label: 'Due Date',
        key: 'copy_due_date',
        width: '200px',
        type: 'date',
      },
      {
        label: 'Date Completed',
        key: 'copy_date_completed',
        width: '200px',
        type: 'date',
      },
      {
        label: 'Published',
        key: 'copy_published',
        width: '200px',
        type: 'radio',
      },
      {
        label: 'Published Date',
        key: 'copy_published_date',
        width: '200px',
        type: 'date',
      },
    ],
  },
];

export const defaultCOSData = {
  parent_asin: '',
  child_asin: '',
  sku: null,
  product_title: null,
  pdp_url: '',
  bp_url: '',
  link_to_assets: null,
  month: null,
  asset_recieved: false,
  assigned_designer: null,
  assigned_designer_info: null,
  design_project_type: null,
  design_optimization_level: null,
  assigned_production_designer: null,
  assigned_production_designer_info: null,
  design_notes: null,
  design_project_status: null,
  link_to_completed_design_deliverables: null,
  design_due_date: null,
  design_date_completed: null,
  design_link_to_jira_project: null,
  design_published: false,
  design_published_date: null,
  copy_project_type: null,
  copy_optimization_level: null,
  copy_notes: null,
  copy_project_status: null,
  link_to_completed_copy_deliverables: null,
  copy_link_to_jira_project: null,
  copy_due_date: null,
  copy_date_completed: null,
  copy_published: false,
  copy_published_date: null,
};

export const dateFilterOptions = [
  {
    value: 'custom',
    label: 'Custom Range',
    sub: 'Select start and end dates',
  },
];

export const distributorViewOptions = [
  {
    value: 'manufacturingView',
    label: 'Manufacturing View',
    id: 'manufacturingView',
  },
  {
    value: 'sourcingView ',
    label: 'Sourcing View',
    id: 'sourcingView',
  },
];

// calculate month difference
export const calculateMonthDifference = (date1, date2) => {
  const timeDiff = Math.abs(date2?.getTime() - date1?.getTime());
  const monthDiff = timeDiff / (1000 * 60 * 60 * 24 * 30);

  return Math.trunc(monthDiff);
};

// calculate past and future months
export const getPastAndfutureMonths = (date, months) => {
  date.setMonth(date?.getMonth() + months);
  date.setDate(1);

  return date;
};

// calculate past and future date and month
export const getPastAndfutureDate = (date, value) => {
  date.setDate(value);
  return date;
};

export const vendorSubTabOptions = [
  {
    value: 'orderedRevenue',
    label: 'Ordered Revenue',
    id: 'orderedRevenue',
    dataCy: 'BT-Tab-OrderedRevenue',
  },
  {
    value: 'shippedCOGs',
    label: 'Shipped COGs',
    id: 'shippedCOGs',
    dataCy: 'BT-Tab-ShippedCOGs',
  },
];

export const goalsTabConstant = [
  { label: 'Forecast', key: 'goals', class: '', dataCy: 'BT-GoalsTab' },
  { label: 'COS', key: 'cos', class: 'd-none', dataCy: 'BT-COSTab' },
];
export const goalsDataType = [
  {
    label: '$',
    type: 'dollar',
  },
  {
    label: '%',
    type: 'percentage',
  },
  {
    label: 'Text',
    type: 'text',
  },
];

export const accountTypeOptionsForGoals = [
  {
    value: 'Hybrid',
    label: 'Hybrid',
    id: 'hybrid',
  },
  {
    value: 'Seller',
    label: 'Seller',
    id: 'seller',
  },
  {
    value: 'Vendor',
    label: 'Vendor',
    id: 'vendor',
  },
];
