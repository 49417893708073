/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react';

import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { shape, string, arrayOf } from 'prop-types';
import { useHistory } from 'react-router-dom';

import DSPAudienceContainer from './DSPAudience/DSPAudienceContainer';
import AwsQuicksightContainer from '../AwsQuicksight/AwsQuicksightContainer';
import { AMCStyle } from './AmcInsightStyle';
import { getActiveTabs } from '../../../../../api';
import {
  secondLevelNavBar,
  secondLevelNavBarForCustomer,
  PATH_CUSTOMER_DETAILS,
} from '../../../../../constants';
import {
  NoRecordFound,
  PageLoader,
  WhiteCard,
  SingleSelectDropdown,
  DropdownIndicator,
  ToggleSwitch,
} from '../../../../../common';

export default function AmcInsightContainer({
  customerID,
  memberData,
  isBBEInternalUser,
}) {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const dashboardOptions = {
    customized: [
      { label: 'Standard Dashboard', value: 'Standard Dashboard' },
      { label: 'Advanced Dashboard', value: 'Advanced Dashboard' },
    ],
    sample: [
      {
        label: 'Standard Dashboard-Sample',
        value: 'Standard Dashboard-Sample',
      },
      {
        label: 'Advanced/Customized Dashboard-Sample',
        value: 'Customized Dashboard-Sample',
      },
    ],
  };
  const [selectedSecondLevelNav, setSelectedSecondLevelNav] = useState(
    params?.subNav || 'standard',
  );
  const [activeTabLoader, setActiveTabLoader] = useState(true);
  const [activeTabs, setActiveTabs] = useState({});
  const [selectedDashboardFilterOption, setSelectedDashboardFilterOption] =
    useState(dashboardOptions[selectedSecondLevelNav]?.[0]);
  const [showAMCAudienceLookalike, setShowAMCAudienceLookalike] = useState(
    params?.amcAudience === 'lookalike' || false,
  );

  useEffect(() => {
    setActiveTabLoader(true);
    getActiveTabs(customerID).then((res) => {
      if (res?.status === 200) {
        setActiveTabs(res?.data);
        setActiveTabLoader(false);
      } else {
        setActiveTabLoader(false);
      }
    });
  }, [customerID, setActiveTabs]);

  const displayNoData = (type) => {
    return userInfo?.role === 'Customer' ? (
      !activeTabs?.standard_tab &&
      !activeTabs?.customized_tab &&
      !activeTabs?.advanced_tab ? (
        <div className="row">
          <NoRecordFound
            type="brand"
            title=""
            subTitle="Please reach out to your Brand Growth Strategist to gain eligibility for AMC."
          />
        </div>
      ) : (
        <div className="row">
          <NoRecordFound
            type="brand"
            title=""
            subTitle="Please reach out to your Brand Growth Strategist to gain eligibility."
          />
        </div>
      )
    ) : !activeTabs?.standard_tab &&
      !activeTabs?.customized_tab &&
      !activeTabs?.advanced_tab ? (
      <div className="row">
        <NoRecordFound
          type="brand"
          title=""
          subTitle={`Engage your brand on DSP to gain access to the ${type} AMC dashboard.`}
        />
      </div>
    ) : (
      <div className="row">
        <NoRecordFound
          type="brand"
          title=""
          subTitle="Speak to your DSP Manager to gain access"
        />
      </div>
    );
  };

  const displayAMCDashboards = () => {
    if (selectedSecondLevelNav === 'standard') {
      if (activeTabs?.standard_tab) {
        return (
          <AwsQuicksightContainer
            customerId={customerID}
            id="standardDashboard"
            dashboardId="ceb3af57-7075-4a4d-9df7-dcd73ba44e0c"
          />
        );
      }
      return displayNoData('Standard');
    }

    if (selectedSecondLevelNav === 'customized') {
      if (
        activeTabs?.customized_tab &&
        selectedDashboardFilterOption?.value === 'Standard Dashboard'
      ) {
        return (
          <AwsQuicksightContainer
            customerId={customerID}
            id="StandardCustomizedDashboard"
            dashboardId="1b68dc12-94e0-4b31-9b5c-11fc85e3ec73"
          />
        );
      }
      if (
        activeTabs?.customized_tab &&
        selectedDashboardFilterOption?.value === 'Advanced Dashboard'
      ) {
        return (
          <AwsQuicksightContainer
            customerId={customerID}
            id="AdvancedCustomizedDashboard"
            dashboardId="fd43c6fe-7ff0-4ec7-b67a-80189f0cbec2"
          />
        );
      }
      return displayNoData('Customized');
    }

    if (selectedSecondLevelNav === 'sample') {
      if (
        selectedDashboardFilterOption?.value === 'Standard Dashboard-Sample'
      ) {
        return (
          <AwsQuicksightContainer
            customerId={customerID}
            id="StandardDashboardSample"
            dashboardId="15a527bb-4cbc-427e-ba84-fae6464f79c3"
          />
        );
      }
      if (
        selectedDashboardFilterOption?.value === 'Customized Dashboard-Sample'
      ) {
        return (
          <AwsQuicksightContainer
            customerId={customerID}
            id="CustomizedDashboardSample"
            dashboardId="cba056e7-abba-4b0a-9c68-721f1df3ebda"
          />
        );
      }
      // return displayNoData('Customized');
    }

    if (selectedSecondLevelNav === 'advanced') {
      if (activeTabs?.advanced_tab) {
        return (
          <AwsQuicksightContainer
            customerId={customerID}
            id="advancedDashboard"
            dashboardId="c267f622-343d-4392-8450-efd652da9243"
          />
        );
      }
      return displayNoData('Advanced');
    }
    return null;
  };

  // get dropdwon indicator for  dropdown filter
  const getSelectDropdownIndicator = () => {
    return {
      DropdownIndicator,
    };
  };

  const handleDashboardFilter = (event) => {
    setSelectedDashboardFilterOption(event);
  };

  const dashboardDropdown = (key) => {
    return (
      <SingleSelectDropdown
        id="view-data-for-dsp-audience-filter"
        placeholder={`Select `}
        className="single-select-dropdown "
        isApiCall={false}
        dropdownOptions={dashboardOptions[key]}
        selectedValue={selectedDashboardFilterOption}
        onChangeHandler={(event) => handleDashboardFilter(event)}
        dropdownComponents={getSelectDropdownIndicator}
        isSearchable={false}
      />
    );
  };

  const setParams = (param, showLookalike) => {
    const paramsData = {};

    if (showLookalike) {
      paramsData.amcAudience = param;
    } else {
      delete paramsData?.amcAudience;
      delete params?.amcAudience;
    }

    const stringified =
      queryString &&
      queryString.stringify({
        ...params,
        ...paramsData,
      });

    history.push({
      pathname: `${history.location.pathname}`,
      search: `${stringified}`,
      state: `${history.location.state}`,
    });
  };

  // https://bbe.atlassian.net/browse/PDV-9060 - AMC Audience Lookalike improvements
  // display AMC Audience Lookalike toggle button
  const displayAMCAudienceLookalikeToggle = () => {
    return (
      <ToggleSwitch
        className="custom-switch cursor mb-3 mb-md-0"
        key="include-spreetail-brands-toggle"
        style={{ marginTop: '12px' }}
      >
        <label
          htmlFor="spreetailBrancds"
          className="label-name"
          key="include-spreetail-brands"
        >
          AMC Audience Lookalike
          <input
            type="checkbox"
            className="label-name"
            id="spreetailBrancds"
            onClick={(event) => {
              setParams('lookalike', event?.target?.checked);
              setShowAMCAudienceLookalike(!showAMCAudienceLookalike);
            }}
            onChange={() => {}}
            checked={showAMCAudienceLookalike}
          />
          <span className="slider round" />
        </label>
      </ToggleSwitch>
    );
  };

  /* **********  main return statement of this componant ********** */
  return (
    <AMCStyle>
      <WhiteCard className="mb-3 p-0">
        <div className="row">
          <div
            className="col-12 col-md-8 col-lg-9"
            style={{ overflowX: 'auto' }}
          >
            <ul
              className="second-level-navigation"
              style={{ minWidth: '500px' }}
            >
              {(userInfo?.role === 'Customer'
                ? secondLevelNavBarForCustomer
                : secondLevelNavBar
              )?.map((item) => {
                return (
                  <li
                    role="presentation"
                    onClick={() => {
                      if (item?.value === 'dsp-audience') {
                        setShowAMCAudienceLookalike(false);
                      }
                      setSelectedSecondLevelNav(item?.value);
                      setSelectedDashboardFilterOption(
                        dashboardOptions[item?.value]?.[0],
                      );

                      history.push(
                        `${PATH_CUSTOMER_DETAILS.replace(
                          ':id',
                          customerID,
                        )}?tab=${params.tab}&nav=amcInsights&subNav=${
                          item?.value
                        }&access=${isBBEInternalUser}`,
                        'amcInsights',
                        item?.label,
                      );
                    }}
                    className={
                      selectedSecondLevelNav === item?.value ? 'selected' : ''
                    }
                  >
                    {item?.label}
                  </li>
                );
              })}
            </ul>
          </div>

          {selectedSecondLevelNav === 'customized' ||
          selectedSecondLevelNav === 'sample' ? (
            <div className="col-12 col-md-4 col-lg-3">
              <div className="mr-3">
                {dashboardDropdown(selectedSecondLevelNav)}
              </div>
            </div>
          ) : null}

          {selectedSecondLevelNav === 'dsp-audience' ? (
            <div className="col-12 col-md-4 col-lg-3 d-flex justify-content-end">
              <div className="mr-3">{displayAMCAudienceLookalikeToggle()}</div>
            </div>
          ) : null}
        </div>
      </WhiteCard>

      {activeTabLoader ? (
        <PageLoader
          component="performance-graph"
          type="detail"
          width={40}
          height={40}
          color="#FF5933"
        />
      ) : (
        displayAMCDashboards()
      )}

      {selectedSecondLevelNav === 'dsp-audience' &&
      userInfo?.role !== 'Customer' ? (
        <DSPAudienceContainer
          showAMCAudienceLookalike={showAMCAudienceLookalike}
          memberData={memberData}
        />
      ) : (
        ''
      )}
    </AMCStyle>
  );
}

AmcInsightContainer.defaultProps = {
  memberData: [],
  isBBEInternalUser: 'internal',
};

AmcInsightContainer.propTypes = {
  customerID: string.isRequired,
  memberData: arrayOf(shape({})),
  isBBEInternalUser: string,
};
