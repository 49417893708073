import React, { useState, useEffect, useCallback } from 'react';

import styled from 'styled-components';
import { string } from 'prop-types';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { getEmbeddedQuicksightDashboardURL } from '../../../../api';
import { PageLoader, WhiteCard } from '../../../../common';

function AwsQuicksightContainer({ id, dashboardId }) {
  const [dashboardLoader, setDashboardLoader] = useState(false);

  // async function for embedding the quicksight dashboard automatically
  const getQuicksightDashboard = useCallback(
    async (URL) => {
      let dashboard = null;
      const { createEmbeddingContext } = QuickSightEmbedding;

      const embeddingContext = await createEmbeddingContext();
      const { embedDashboard } = embeddingContext;

      const containerDiv = document.getElementById(id);

      // basic dashboard options
      const dashboardOptions = {
        url: URL,
        container: containerDiv,
        scrolling: 'no',
        width: '100%',
        height: '1280px',
        parameters: {
          country: 'United States',
        },
      };

      // filtered dashboard params
      const otherOptions = {
        toolbarOptions: {
          export: true,
        },
      };
      dashboard = await embedDashboard(dashboardOptions, otherOptions)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });

      return dashboard;
    },
    [id],
  );

  // get embedded url of quicksight dashboard
  const getDashboardURL = useCallback(() => {
    setDashboardLoader(true);
    const params = {
      dashboard_id: dashboardId,
    };
    getEmbeddedQuicksightDashboardURL(params).then((response) => {
      if (response?.status === 200) {
        setDashboardLoader(false);
        getQuicksightDashboard(response?.data?.EmbedUrl);
      } else {
        setDashboardLoader(false);
      }
    });
  }, [dashboardId, getQuicksightDashboard]);

  useEffect(() => {
    getDashboardURL();
  }, [getDashboardURL]);

  /* ********** Main return statement of this component ********** */
  return (
    <WhiteCard className="mt-3 mb-5">
      <AWSQuicksightsDashboard style={{ minHeight: '600px' }}>
        {dashboardLoader ? (
          <PageLoader
            component="performance-graph"
            type="detail"
            width={40}
            height={40}
            color="#FF5933"
          />
        ) : (
          <div id={id} />
        )}
        <div id="footer-strip" />
      </AWSQuicksightsDashboard>
    </WhiteCard>
  );
}

AwsQuicksightContainer.defaultProps = {
  dashboardId: '',
};

AwsQuicksightContainer.propTypes = {
  dashboardId: string,
  id: string.isRequired,
};

export default AwsQuicksightContainer;
const AWSQuicksightsDashboard = styled.div`
  #footer-strip {
    height: 30px;
    background: #ffffff;
    margin-top: -25px;
    position: relative;
  }
`;
