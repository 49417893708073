export const creativeAfterDaysOptions = [
  { value: '30', label: 'After 30 Days' },
  { value: '60', label: 'After 60 Days' },
  { value: '90', label: 'After 90 Days' },
  { value: '120', label: 'After 120 Days' },
];

export const projectASINTableHeader = [
  {
    label: 'Products',
    key: 'product',
    width: '10px',
    total: '',
    avg: '',
    prefix: '',
    suffix: '',
    show_info: false,
    asin_view: '',
    changedValueKey: '',
    isShow2Decimal: false,
  },
  {
    label: 'Ordered Product Sales',
    key: 'ordered product sales',
    width: '20%',
    total: 'total_ordered_prod_sales',
    avg: 'avg_ordered_prod_sale',
    prefix: '$',
    suffix: '',
    show_info: false,
    asin_view: 'total_ordered_prod_sales',
    changedValueKey: 'abs_change_total_ordered_prod_sales',
    isShow2Decimal: true,
  },

  {
    label: 'Units Ordered',
    key: 'units ordered',
    width: '10%',
    total: 'total_units_ordered',
    avg: 'avg_units_ordered',
    prefix: '',
    suffix: '',
    show_info: false,
    asin_view: 'total_units_ordered',
    changedValueKey: 'abs_change_total_units_ordered',
    isShow2Decimal: false,
  },

  {
    label: 'Average Units',
    key: 'average units',
    width: '10%',
    avg: 'avg_average_units',
    suffix: '',
    prefix: '',
    show_info: false,
    asin_view: 'avg_average_units',
    changedValueKey: 'abs_change_avg_average_units',
    isShow2Decimal: false,
  },

  {
    label: 'Page Views',
    key: 'page views',
    width: '10%',
    total: 'total_page_view',
    avg: 'avg_page_view',
    suffix: '',
    prefix: '',
    show_info: true,
    asin_view: 'total_page_view',
    info_message:
      "There might be some discrepancy, double check with Amazon SC/VC. We're working on 100% accuracy.",
    changedValueKey: 'abs_change_total_page_view',
    isShow2Decimal: false,
  },

  {
    label: 'Sessions',
    key: 'sessions',
    width: '10%',
    total: 'total_session',
    avg: 'avg_session',
    suffix: '',
    prefix: '',
    show_info: true,
    asin_view: 'total_session',
    info_message:
      "There might be some discrepancy, double check with Amazon SC/VC. We're working on 100% accuracy.",
    changedValueKey: 'abs_change_total_session',
    isShow2Decimal: false,
  },

  {
    label: 'Buy Box%',
    key: 'buy box%',
    width: '10%',
    avg: 'avg_bbe_percentage',
    suffix: '%',
    prefix: '',
    show_info: true,
    asin_view: 'avg_bbe_percentage',
    info_message:
      'There might be some discrepancy(as this column is dependent on page view and session).',
    changedValueKey: 'abs_change_avg_bbe_percentage',
    isShow2Decimal: true,
  },

  {
    label: 'Conversion',
    key: 'conversion',
    width: '10%',
    avg: 'avg_conversion',
    suffix: '%',
    prefix: '',
    show_info: true,
    asin_view: 'avg_conversion',
    info_message:
      'There might be some discrepancy(as this column is dependent on page view and session).',
    changedValueKey: 'abs_change_avg_conversion',
    isShow2Decimal: true,
  },
];

export const dataAfterCompletion = [
  { value: '30_days_prior', label: '30 Days prior completion' },
  { value: '30_days_after', label: '30 Days after completion' },
  { value: '60_days_after', label: '60 Days after completion' },
  { value: '90_days_after', label: '90 Days after completion' },
  { value: '120_days_after', label: '120 Days after completion' },
];

export const PerformanceSumarryTiles = [
  {
    key: 'ordered_product_sales',
    name: 'Ordered Product Sales',
    color: '#0045b4',
    symbol: 'currency',
    dataAvailabletoolTip:
      'Sum the sales revenue that taken place after a selected duration',
    decimal: true,
  },
  {
    key: 'units_ordered',
    name: 'UNITS ORDERED',
    color: '#74b035',
    symbol: 'none',
    dataAvailabletoolTip:
      'Sum the units ordered that taken place after a selected duration',
    decimal: false,
  },
  {
    key: 'units_sold',
    name: 'AVERAGE UNITS PER ORDER',
    color: '#C84EC6',
    symbol: 'none',
    dataAvailabletoolTip:
      'Average units per order that taken place after a selected duration',
    decimal: true,
  },
  {
    key: 'page_views',
    name: 'PAGE VIEWS',
    color: '#E05D37',
    symbol: 'none',
    dataAvailabletoolTip:
      'Sum the page views that taken place after a selected duration',
    decimal: false,
  },
  {
    key: 'sessions',
    name: 'SESSIONS',
    color: '#89A43C',
    symbol: 'none',
    dataAvailabletoolTip: '',
    decimal: false,
  },
  {
    key: 'buy_box_percentage',
    name: 'BUY BOX %',
    color: '#A04848',
    symbol: 'percentage',
    dataAvailabletoolTip: '',
    decimal: true,
  },
  {
    key: 'conversion',
    name: 'CONVERSION',
    color: '#8C54FF',
    symbol: 'percentage',
    dataAvailabletoolTip: '',
    decimal: true,
  },
];

export const daysConstant = [
  {
    key: 'weekly',
    label: 'Weekly',
    id: 'weeklyCheck',
  },
  {
    key: 'monthly',
    label: 'Monthly',
    id: 'monthlyCheck',
  },
  {
    key: 'quarterly',
    label: 'Quarterly',
    id: 'quarterlyCheck',
  },
];

export const vendorReportTypes = [
  { value: 'shipped_cogs', label: 'Shipped COGS' },
  { value: 'ordered_revenue', label: 'Ordered Revenue' },
];

export const orderedVendorPerformanceSumarryTiles = [
  {
    key: 'ordered_revenue',
    name: 'Ordered Revenue',
    color: '#0045b4',
    symbol: 'currency',
    decimal: true,
    dataAvailabletoolTip: '',
  },
  {
    key: 'glance_views',
    name: 'Glance Views',
    color: '#74b035',
    symbol: 'none',
    decimal: false,
    dataAvailabletoolTip: '',
  },

  {
    key: 'conversion_rate',
    name: 'CONVERSION RATE',
    color: '#8C54FF',
    symbol: 'percentage',
    dataAvailabletoolTip: '',
    decimal: true,
  },
  {
    key: 'ordered_units',
    name: 'ORDERED UNITS',
    color: '#74b035',
    symbol: 'none',
    decimal: false,
    dataAvailabletoolTip: '',
  },
];

export const shippedVendorPerformanceSumarryTiles = [
  {
    key: 'shipped_cogs',
    name: 'Shipped COGS',
    color: '#0045b4',
    symbol: 'currency',
    decimal: true,
    dataAvailabletoolTip: '',
  },
  {
    key: 'glance_views',
    name: 'Glance Views',
    color: '#74b035',
    symbol: 'none',
    decimal: false,
    dataAvailabletoolTip: '',
  },

  {
    key: 'conversion_rate',
    name: 'CONVERSION RATE',
    color: '#8C54FF',
    symbol: 'percentage',
    dataAvailabletoolTip: '',
    decimal: true,
  },
  {
    key: 'shipped_units',
    name: 'SHIPPED UNITS',
    color: '#74b035',
    symbol: 'none',
    decimal: false,
    dataAvailabletoolTip: '',
  },
];

export const projectASINVendorTableHeader = [
  {
    label: 'Products',
    key: 'product',
    width: '20%',
    total: '',
    avg: '',
    prefix: '',
    suffix: '',
    show_info: false,
    asin_view: '',
    changedValueKey: '',
    isShow2Decimal: false,
  },
  {
    label: 'Ordered Revenue',
    key: 'ordered revenue',
    width: '20%',
    total: 'total_ordered_revenue',
    avg: 'avg_ordered_revenue',
    prefix: '$',
    suffix: '',
    show_info: false,
    asin_view: 'total_ordered_revenue',
    changedValueKey: 'abs_change_total_ordered_revenue',
    isShow2Decimal: true,
  },
  {
    label: 'Glance Views',
    key: 'glance views',
    width: '20%',
    total: 'total_glance_views',
    avg: 'avg_glance_views',
    suffix: '',
    prefix: '',
    show_info: false,
    asin_view: 'total_glance_views',
    changedValueKey: 'abs_change_total_glance_views',
    isShow2Decimal: false,
  },

  {
    label: 'Conversion Rate',
    key: 'conversion',
    width: '20%',
    avg: 'avg_conversion_rate',
    suffix: '%',
    prefix: '',
    show_info: true,
    asin_view: 'avg_conversion_rate',
    changedValueKey: 'abs_change_avg_conversion_rate',
    isShow2Decimal: true,
  },

  {
    label: 'Ordered Units',
    key: 'ordered units',
    width: '20%',
    total: 'total_ordered_units',
    avg: 'avg_ordered_units',
    prefix: '',
    suffix: '',
    show_info: false,
    asin_view: 'total_ordered_units',
    changedValueKey: 'abs_change_total_ordered_units',
    isShow2Decimal: false,
  },
];
export const projectASINVendorShippedCogsTableHeader = [
  {
    label: 'Products',
    key: 'product',
    width: '20%',
    total: '',
    avg: '',
    prefix: '',
    suffix: '',
    show_info: false,
    asin_view: '',
    changedValueKey: '',
    isShow2Decimal: false,
  },
  {
    label: 'Shipped COGS',
    key: 'shipped cogs',
    width: '20%',
    total: 'total_shipped_cogs',
    avg: 'avg_shipped_cogs',
    prefix: '$',
    suffix: '',
    show_info: false,
    asin_view: 'total_shipped_cogs',
    changedValueKey: 'abs_change_total_shipped_cogs',
    isShow2Decimal: true,
  },
  {
    label: 'Glance Views',
    key: 'glance views',
    width: '20%',
    total: 'total_glance_views',
    avg: 'avg_glance_views',
    suffix: '',
    prefix: '',
    show_info: false,
    asin_view: 'total_glance_views',
    changedValueKey: 'abs_change_total_glance_views',
    isShow2Decimal: false,
  },

  {
    label: 'Conversion Rate',
    key: 'conversion',
    width: '20%',
    avg: 'avg_conversion_rate',
    suffix: '%',
    prefix: '',
    show_info: true,
    asin_view: 'avg_conversion_rate',
    changedValueKey: 'abs_change_avg_conversion_rate',
    isShow2Decimal: true,
  },

  {
    label: 'Shipped Units',
    key: 'Shipped units',
    width: '20%',
    total: 'total_shipped_units',
    avg: 'avg_shipped_units',
    prefix: '',
    suffix: '',
    show_info: false,
    asin_view: 'total_shipped_units',
    changedValueKey: 'abs_change_total_shipped_units',
    isShow2Decimal: false,
  },
];

export const projectDeleteMessage =
  "You are about to delete a project. Once you delete this project, ALL ASIN's under this project will be removed as well.";

export const asinsDeleteMessage = 'You are about to delete an ASIN.';
