import React, { useState } from 'react';

import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CheckBox,
  CustomDropDown,
  WhiteCard,
  ToggleButton,
} from '../../../common';

import { SideContent, EditColumn } from './CustomerListStyle';
import {
  CaretUp,
  CloseIcon,
  TableColumns,
  InfoIcons,
} from '../../../theme/images';
import {
  columnsForSalesPerformance,
  columnsForSponsoredPerformance,
  columnsForDSPPerformance,
  vendorSalesPerformanceOptions,
  columnsForVendorSalesPerformance,
} from '../../../constants';

function CustomerListFilters({
  loader,
  handleFilters,
  generateDropdown,
  filters,
  showContractDetails,
  showPerformance,
  showAdPerformance,
  showDspAdPerformance,
  showOrderOption,
  selectedColumns,
  setSelectedColumns,
  showCustomizeColModal,
  setShowCustomiseColModal,
  isCsv,
  setIsCsv,
  displayInputSearchWithRadius,
  displayManagerFilter,
  displayRadioCheckFilters,
  displayPartnerCheckboxesFilter,
  displayPartnerAgreementTypesFilters,
  setSelectedVendorDashboard,
  activeVendorTab,
  setActiveVendorTab,
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const selectedAccountTypes = JSON.parse(
    localStorage.getItem('filters'),
  )?.customer_account_type;

  const handleSelectedColumns = (event, column) => {
    if (event?.target?.checked) {
      $(`.${column?.value} input:checkbox`).prop('checked', true);
      const filterData = {
        ...filters,
        selectedColumns: [...selectedColumns, column],
      };
      setSelectedColumns([...selectedColumns, column]);
      if (Object.keys(column).includes('dashboard')) {
        filterData.vendor_dashboard = column.dashboard;
        setSelectedVendorDashboard(column.dashboard);
      }

      localStorage.setItem(
        'filters',
        JSON.stringify({
          ...filterData,
        }),
      );
    } else if (selectedColumns?.length > 1) {
      $(`.${column?.value} input:checkbox`).prop('checked', false);

      const result = selectedColumns?.filter(
        (item) => item?.value !== column?.value,
      );
      localStorage.setItem(
        'filters',
        JSON.stringify({
          ...filters,
          selectedColumns: [...result],
        }),
      );
      setSelectedColumns([...result]);
    }
  };

  const mapSelectedColumns = (column) => {
    const checkedItem = selectedColumns?.find(
      (item) => item.value === column?.value,
    );
    if (checkedItem) {
      return Object.keys(checkedItem)?.length;
    }
    return false;
  };

  const displayColumns = (columnList) => {
    return columnList?.map((column) => (
      <React.Fragment key={column?.value}>
        <div className="col-6 ">
          <CheckBox key={column?.value}>
            <label
              className={`check-container customer-pannel customise-column `}
              htmlFor={column?.value}
            >
              {column?.label}{' '}
              <input
                type="checkbox"
                name={column?.value}
                id={column?.value}
                onClick={(event) => handleSelectedColumns(event, column)}
                checked={mapSelectedColumns(column)}
              />
              <span className="checkmark" />
              {column?.info ? (
                <>
                  {' '}
                  <img
                    className="ml-1 cursor"
                    style={{
                      verticalAlign: 'text-bottom',
                      fontWeight: '300',
                    }}
                    width="14px"
                    src={InfoIcons}
                    alt="info"
                    data-tip={column?.info}
                    data-for={column?.value}
                  />{' '}
                  <ReactTooltip
                    id={`${column?.value}`}
                    aria-haspopup="true"
                    place="top"
                    effect="solid"
                    html
                  />{' '}
                </>
              ) : (
                ''
              )}
            </label>
          </CheckBox>
        </div>
      </React.Fragment>
    ));
  };

  function getDifference(array1, array2) {
    return array1?.filter((object1) => {
      return !array2?.some((object2) => {
        return (
          object1?.value === object2?.value &&
          object1?.dashboard === object2?.dashboard
        );
      });
    });
  }

  const handleToggleToggleBtn = (item) => {
    setActiveVendorTab(item);
    const orderedRevenueColumns =
      columnsForVendorSalesPerformance.ordered_revenue_tab;
    const shippedCOGsColumns =
      columnsForVendorSalesPerformance.shipped_cogs_tab;

    let list = [];
    if (item?.value === 'ordered_revenue_tab') {
      if (
        selectedAccountTypes?.length === 1 &&
        selectedAccountTypes.includes('Vendor')
      ) {
        list = [...orderedRevenueColumns];
      } else {
        list = getDifference(selectedColumns, shippedCOGsColumns);
      }
    }
    if (item?.value === 'shipped_cogs_tab') {
      if (
        selectedAccountTypes?.length === 1 &&
        selectedAccountTypes.includes('Vendor')
      ) {
        list = [...shippedCOGsColumns];
      } else {
        list = getDifference(selectedColumns, orderedRevenueColumns);
      }
    }
    setSelectedColumns([...list]);
  };

  const displayVendorSalesPerformanceView = () => {
    return (
      <>
        <ToggleButton className="col-12 mb-3 ">
          <div className="days-container spending">
            <ul className="days-tab">
              {vendorSalesPerformanceOptions?.map((item) => {
                return (
                  <li id={`BT-${item?.value}-toggle`}>
                    <input
                      className="d-none"
                      type="radio"
                      id={item?.id}
                      name={`${item?.value}RadioDefault`}
                      value={item?.value}
                      checked={item?.value === activeVendorTab?.value}
                      onClick={() => {
                        handleToggleToggleBtn(item);
                        setSelectedVendorDashboard(item?.dashboard);
                        localStorage.setItem(
                          'filters',
                          JSON.stringify({
                            ...filters,
                            vendor_dashboard: item?.dashboard,
                          }),
                        );
                      }}
                    />
                    <label htmlFor={item?.id}>{item?.label}</label>
                  </li>
                );
              })}
            </ul>
          </div>
        </ToggleButton>
        {displayColumns(
          columnsForVendorSalesPerformance?.[activeVendorTab?.value],
        )}
      </>
    );
  };

  const handleSelectAllColumn = (type) => {
    $('.customise-column input:checkbox').prop('checked', true);

    if (filters?.showPerformance) {
      if (type === 'vendor') {
        const orderedRevenueColumns =
          columnsForVendorSalesPerformance?.ordered_revenue_tab;
        const shippedCOGsColumns =
          columnsForVendorSalesPerformance?.shipped_cogs_tab;

        let newColumnsToAdd = [];

        if (activeVendorTab?.value === 'ordered_revenue_tab') {
          const list = getDifference(selectedColumns, shippedCOGsColumns);
          newColumnsToAdd = [...list, ...orderedRevenueColumns];
        }
        if (activeVendorTab?.value === 'shipped_cogs_tab') {
          const list = getDifference(selectedColumns, orderedRevenueColumns);
          newColumnsToAdd = [...list, ...shippedCOGsColumns];
        }

        const dateArray = [...selectedColumns, ...newColumnsToAdd];

        const uniqueColumns = Array.from(
          new Set(dateArray.map((d) => JSON.stringify(d))),
        ).map((s) => {
          const d = JSON.parse(s);
          return { ...d };
        });
        setSelectedColumns([...uniqueColumns]);
        setSelectedVendorDashboard(activeVendorTab?.dashboard);
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            selectedColumns: [...uniqueColumns],
            vendor_dashboard: activeVendorTab?.dashboard,
          }),
        );
      } else {
        const arrayList = [...columnsForSalesPerformance, ...selectedColumns];
        const uniqueColumns = Array.from(
          new Set(arrayList.map((d) => JSON.stringify(d))),
        ).map((s) => {
          const d = JSON.parse(s);
          return { ...d };
        });
        setSelectedColumns([...uniqueColumns]);
        localStorage.setItem(
          'filters',
          JSON.stringify({
            ...filters,
            selectedColumns: [...uniqueColumns],
          }),
        );
      }
    }
    if (filters?.showAdPerformance) {
      setSelectedColumns([...columnsForSponsoredPerformance]);
      localStorage.setItem(
        'filters',
        JSON.stringify({
          ...filters,
          selectedColumns: [...columnsForSponsoredPerformance],
        }),
      );
    }
    if (filters?.showDspAdPerformance) {
      setSelectedColumns([...columnsForDSPPerformance]);
      localStorage.setItem(
        'filters',
        JSON.stringify({
          ...filters,
          selectedColumns: [...columnsForDSPPerformance],
        }),
      );
    }
  };

  const displayCustomiseColumns = () => {
    let columnList = [];
    if (filters?.showPerformance) {
      columnList = columnsForSalesPerformance;

      if (
        (selectedAccountTypes?.includes('Seller') &&
          selectedAccountTypes?.includes('Vendor')) ||
        selectedAccountTypes?.includes('Hybrid') ||
        selectedAccountTypes?.length === 0
      ) {
        return (
          <>
            <div className=" liner-titles spacing mb-3">Seller</div>
            <div className="row">{displayColumns(columnList)}</div>
            <div className=" liner-titles spacing ">Vendor</div>
            <div className=" column-body">
              <p
                className="gray-normal-text mt-1 mb-3 pb-1 cursor"
                role="presentation"
                onClick={() => handleSelectAllColumn('vendor')}
              >
                Select All
              </p>
            </div>
            <div className="row">{displayVendorSalesPerformanceView()}</div>
          </>
        );
      }

      if (selectedAccountTypes?.includes('Vendor'))
        return (
          <>
            <div className=" liner-titles spacing mb-3">Vendor</div>
            <div className="row">{displayVendorSalesPerformanceView()}</div>
          </>
        );
      if (selectedAccountTypes?.includes('Seller'))
        return (
          <>
            <div className=" liner-titles spacing mb-3">Seller</div>
            <div className="row">{displayColumns(columnList)}</div>
          </>
        );
    }
    if (filters?.showAdPerformance) {
      columnList = columnsForSponsoredPerformance;
      if (
        selectedAccountTypes?.includes('Vendor') &&
        selectedAccountTypes?.length === 1
      ) {
        columnList = columnsForSponsoredPerformance.filter(
          (item) => item?.label !== 'TACOS' && item?.label !== 'Total AD Spend',
        );
      }

      return (
        <>
          <div className=" liner-titles spacing mb-3">
            {(selectedAccountTypes?.includes('Seller') &&
              selectedAccountTypes?.includes('Vendor')) ||
            selectedAccountTypes?.includes('Hybrid') ||
            selectedAccountTypes?.length === 0
              ? 'Hybrid'
              : selectedAccountTypes?.includes('Vendor')
              ? 'Vendor'
              : selectedAccountTypes?.includes('Seller')
              ? 'Seller'
              : ''}{' '}
          </div>
          <div className="row">{displayColumns(columnList)}</div>
        </>
      );
    }
    if (filters?.showDspAdPerformance) {
      columnList = columnsForDSPPerformance;
      return <div className="row">{displayColumns(columnList)}</div>;
    }
    return null;
  };

  const displayCustomiseColumnsFeature = () => {
    return (
      <div className={loader ? 'disabled' : ''}>
        {showContractDetails ? (
          ''
        ) : (
          <div
            className="sub-heading mb-3 cursor"
            style={{ color: '#FF5933' }}
            role="presentation"
            onClick={() => {
              setShowCustomiseColModal(!showCustomizeColModal);
            }}
          >
            <img
              src={TableColumns}
              alt="customise-column"
              width="14px"
              className="mr1 mt1"
              style={{ verticalAlign: 'bottom' }}
            />{' '}
            Edit Columns
          </div>
        )}
        {showCustomizeColModal ? (
          <EditColumn>
            <div className="column-header">
              <div className="black-heading-title text-medium-font">
                Edit Columns
              </div>
              <img
                className=" cursor customise-column-cross"
                src={CloseIcon}
                alt="close"
                width="15"
                height="15"
                role="presentation"
                onClick={() => {
                  setShowCustomiseColModal(false);
                }}
              />
            </div>
            <div className="column-body">
              <p
                className="gray-normal-text mt-1 mb-3 pb-1 cursor"
                role="presentation"
                onClick={() => handleSelectAllColumn()}
              >
                Select All
              </p>
              <div>{displayCustomiseColumns()}</div>
            </div>
          </EditColumn>
        ) : (
          ''
        )}
      </div>
    );
  };

  // display partner dropdown filter
  const displyPartnerDropdownFilter = (dropdownKey) => {
    return (
      <CustomDropDown
        id={`BT-${dropdownKey}-customerlist-dropdown`}
        className="customer-list-header"
      >
        {generateDropdown(dropdownKey)}
      </CustomDropDown>
    );
  };

  // display download report button
  const displayDownloadReportButton = () => {
    return (
      <div className={loader ? 'disabled' : ''}>
        <div
          style={{
            verticalAlign: 'bottom',
            paddingRight: isDesktop ? '10px' : 0,
          }}
          className={`cursor ${isDesktop ? 'mt-3' : 'mt-1'} ${
            isCsv ? 'isDisabled' : ''
          }`}
          role="presentation"
          onClick={() => setIsCsv(true)}
        >
          <span className="download-link" download>
            <FontAwesomeIcon
              icon="fal fa-download"
              style={{ marginRight: '5px' }}
            />
            Download
          </span>
        </div>
      </div>
    );
  };

  // display partner dropdown filter row
  const displayPartnerDropdownFilterRow = () => {
    return (
      <div className="row">
        <div
          className={
            isDesktop
              ? showAdPerformance || showDspAdPerformance || showPerformance
                ? 'col-2'
                : 'col-5'
              : 'col-12'
          }
        >
          <p
            className={`black-heading-title m-0 ${isDesktop ? 'pt-3' : 'pt-1'}`}
          >
            Partners
          </p>
          {displayCustomiseColumnsFeature()}
        </div>
        <div
          className={`col-lg-3 col-md-6 col-12 ${
            isDesktop ? 'mb-2 px-2' : isTablet ? 'mb-1 pr-2' : 'mb-1'
          }`}
        >
          {displyPartnerDropdownFilter('view')}
        </div>
        {showAdPerformance || showDspAdPerformance || showPerformance ? (
          <div
            className={`col-lg-3 col-md-6 col-12 ${
              isDesktop ? 'mb-2 px-2' : isTablet ? 'mb-1 pl-1' : 'mb-1'
            }`}
          >
            {displyPartnerDropdownFilter('stats')}
          </div>
        ) : null}

        <div
          className={`col-lg-3 col-md-6 col-12 ${
            isDesktop ? 'mb-2 px-2' : isTablet ? 'mb-1 pr-2' : 'mb-1'
          }`}
        >
          {displyPartnerDropdownFilter('sort')}
        </div>

        {!isDesktop &&
        ((showOrderOption && showAdPerformance) ||
          showDspAdPerformance ||
          showPerformance) ? (
          <div
            className={`col-lg-3 col-md-6 col-12 ${
              isDesktop ? 'mb-2 px-2' : isTablet ? 'mb-1 pl-1' : 'mb-1'
            }`}
          >
            {displyPartnerDropdownFilter('order')}
          </div>
        ) : null}
        <div className={`col-lg-1 col-md-6 col-12 ${isDesktop ? 'px-0 ' : ''}`}>
          {displayDownloadReportButton()}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="customer-list-header-sticky">
        {isDesktop ? displayPartnerDropdownFilterRow() : ''}
        <div className="container-fluid">
          <WhiteCard className="mb-3 d-lg-none d-block ">
            <div className="row">
              <div
                className={
                  showContractDetails ? 'col-lg-3 col-md-12' : 'col-lg-3 col-12'
                }
              >
                <div
                  className="cursor mb-2"
                  aria-hidden="true"
                  onClick={() => setIsCollapseOpen(!isCollapseOpen)}
                >
                  <div className="row">
                    <div className={isDesktop ? 'col-6' : 'col-5'}>
                      <p className="black-heading-title  m-0"> Filters</p>
                    </div>

                    <div className="col-6 text-right">
                      <div className=" gray-normal-text m-0 d-lg-none d-block ">
                        <label
                          className=" cursor "
                          htmlFor="tabletmenu-check"
                          id="responsive-button"
                          onClickCapture={(event) => {
                            event.stopPropagation();
                            handleFilters(event, 'unselected');
                          }}
                        >
                          Reset filters
                        </label>
                      </div>
                    </div>
                    {!isDesktop ? (
                      <div role="presentation">
                        <div className="black-heading-title ">
                          <img
                            src={CaretUp}
                            alt="caret"
                            style={{
                              transform: isCollapseOpen ? 'rotate(180deg)' : '',
                              width: '25px',
                              height: '25px',
                              position: ' absolute',
                              right: '7px',
                              top: '-5px',
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {isCollapseOpen ? (
                  <SideContent>
                    <div className="row">
                      <div className="col-12 mb-3">
                        {/* ********** input search filter ********** */}
                        {displayInputSearchWithRadius()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        {/* ********** bgs, ad, dsp manager filters ********** */}
                        {displayManagerFilter()}
                        {/* ********** partner types filters ********** */}
                        {displayRadioCheckFilters(
                          'Partner Type',
                          'bpAccount',
                          'mobile',
                        )}
                        {/* ********** partner account status filters ********** */}
                        {displayPartnerCheckboxesFilter(
                          'Partner Status',
                          'status',
                        )}
                        {/* ********** partner pipeline filters ********** */}
                        {displayRadioCheckFilters(
                          'Partner Pipeline',
                          'pipeline',
                          'mobile',
                        )}
                        {/* ********** partner account types filters ********** */}
                        {displayPartnerCheckboxesFilter(
                          'Account Type',
                          'customer_account_type',
                        )}
                      </div>
                      <div className="col-6">
                        {/* ********** partner agreement types filters ********** */}
                        {displayPartnerAgreementTypesFilters('mobile')}
                        {/* ********** partner agreement status filters ********** */}
                        {displayPartnerCheckboxesFilter(
                          'Agreement Status',
                          'contract_status',
                        )}
                        {/* ********** partner agreement addendum status filters ********** */}
                        {displayPartnerCheckboxesFilter(
                          'Addendum Status',
                          'addendum_status',
                        )}
                      </div>
                    </div>
                  </SideContent>
                ) : null}
              </div>
            </div>
          </WhiteCard>
        </div>
      </div>
      <div className="container-fluid">
        {isDesktop ? null : (
          <WhiteCard className="d-lg-none d-block">
            {displayPartnerDropdownFilterRow()}
          </WhiteCard>
        )}
      </div>

      <div className="straight-line horizontal-line mt-n2 d-lg-block d-none" />
    </>
  );
}

export default CustomerListFilters;
CustomerListFilters.defaultProps = {
  loader: false,
  handleFilters: () => {},
  generateDropdown: () => {},
  showContractDetails: true,
  showPerformance: false,
  showAdPerformance: false,
  showDspAdPerformance: false,
  selectedColumns: [],
  setSelectedColumns: () => {},
  showCustomizeColModal: false,
  setShowCustomiseColModal: () => {},
  isCsv: false,
  setIsCsv: () => {},
  displayInputSearchWithRadius: () => {},
  displayManagerFilter: () => {},
  displayRadioCheckFilters: () => {},
  displayPartnerCheckboxesFilter: () => {},
  displayPartnerAgreementTypesFilters: () => {},
  setSelectedVendorDashboard: () => {},
  activeVendorTab: {},
  setActiveVendorTab: () => {},
};

CustomerListFilters.propTypes = {
  loader: bool,
  handleFilters: func,
  generateDropdown: func,
  filters: shape({}).isRequired,
  showContractDetails: bool,
  showPerformance: bool,
  showAdPerformance: bool,
  showDspAdPerformance: bool,
  showOrderOption: bool.isRequired,
  selectedColumns: arrayOf(string),
  setSelectedColumns: func,
  showCustomizeColModal: bool,
  setShowCustomiseColModal: func,
  isCsv: bool,
  setIsCsv: func,
  displayInputSearchWithRadius: func,
  displayManagerFilter: func,
  displayRadioCheckFilters: func,
  displayPartnerCheckboxesFilter: func,
  displayPartnerAgreementTypesFilters: func,
  setSelectedVendorDashboard: func,
  activeVendorTab: shape({}),
  setActiveVendorTab: func,
};
