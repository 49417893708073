import React, { useState, useEffect, useCallback } from 'react';

import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, func } from 'prop-types';

import COSTable from './COSTable';
import COSMonthPicker from './COSMonthPicker';
import { GoalsStyle, GoalsNav, COSAsins } from '../GoalsStyle';
import {
  SpreetailRole,
  SpreetailRoleRestrictMessage,
  cosViewTypes,
} from '../../../../../constants';
import {
  getCOS,
  getBPsCOSAccess,
  updateCOS,
  getTotalAsinsCount,
} from '../../../../../api';
import {
  Button,
  DropdownIndicator,
  SingleSelectDropdown,
  ViewData,
  WhiteCard,
  PageLoader,
  GrayMessageBar,
} from '../../../../../common';

function CreativeOptimizationScheduleContainer({
  clickOnRefreshBtnFlag,
  setClickOnRefreshBtn,
}) {
  // https://bbe.atlassian.net/browse/PDV-8930
  // Show dynamic messages in goals and cos section
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  const cosDynamicMessage = dynamicMessages?.forecast_cos?.placeholder;

  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;

  const { id } = useParams();
  const [cosLoader, setCOSLoader] = useState(false);
  const [cosAccessOfBP, setCOSAccessOfBP] = useState({});
  const [cosData, setCOSResponse] = useState([]);
  const [totalAsinsCount, setTotalAsinsCountData] = useState({
    total_asin: 0,
    total_contractual_asin: 0,
  });
  const [selectedViewType, setSelectedViewType] = useState({
    label: 'General',
    value: 'general',
  });
  const [isEditFlagOn, setEditFlag] = useState(false);
  const [newRowFlag, setNewRowFlag] = useState(false);
  const [asinsList, setAsinsList] = useState([]);
  const [errors, setError] = useState({});
  const defaultDate = new Date();
  const [customStartDate, setCustomStartDate] = useState(
    `${defaultDate?.getFullYear()}-${defaultDate?.getMonth() + 1}-${1}`,
  );
  const [customEndDate, setCustomEndDate] = useState(
    `${defaultDate?.getFullYear()}-${defaultDate?.getMonth() + 1}-${1}`,
  );
  const [customMonth, setCustomMonth] = useState({
    startMonth: new Date(customStartDate),
    endMonth: new Date(customEndDate),
  });

  // auto scroll to specific table cell
  const autoScrollToElement = useCallback((elId) => {
    const element = document.getElementById(elId);
    if (element !== null) {
      element.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });
    }
  }, []);

  const getAsinCountData = useCallback(() => {
    getTotalAsinsCount(id, customStartDate, customEndDate).then((res) => {
      if (res?.status === 200) {
        setTotalAsinsCountData(res?.data);
      } else {
        setTotalAsinsCountData({});
      }
    });
  }, [id, customStartDate, customEndDate]);

  const getCOSData = useCallback(() => {
    setCOSLoader(true);
    getCOS(id, customStartDate, customEndDate).then((res) => {
      setCOSLoader(false);
      setClickOnRefreshBtn(false);
      setNewRowFlag(false);
      setEditFlag(false);
      setError({});
      setSelectedViewType({
        label: 'General',
        value: 'general',
      });
      if (res?.status === 200) {
        setCOSResponse(res?.data);
      } else {
        setCOSResponse([]);
      }
    });

    getAsinCountData();
  }, [
    id,
    customStartDate,
    customEndDate,
    setClickOnRefreshBtn,
    getAsinCountData,
  ]);

  useEffect(() => {
    getBPsCOSAccess(id).then((res) => {
      if (res?.status === 200) {
        setCOSAccessOfBP(res?.data);
        if (res?.data?.start_date || res?.data?.end_date) getCOSData();
      }
    });
  }, [id, getCOSData, clickOnRefreshBtnFlag]);

  useEffect(() => {
    setAsinsList(cosData?.map((cos) => [...cos?.asins]).flat());
  }, [cosData, setAsinsList]);

  // handle view type filter events
  const handleViewTypeFilterEvents = (event) => {
    const { value } = event;
    if (value !== selectedViewType?.value) {
      setSelectedViewType(event);
      autoScrollToElement(value);
    }
  };

  // display cos header asins count
  const displayASINsCount = () => {
    return (
      <COSAsins>
        <div className="asin-count-label">
          <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
            Number of contractual ASINs:
          </span>{' '}
          {totalAsinsCount?.total_contractual_asin || 0}{' '}
          {totalAsinsCount?.total_contractual_asin === 'N/A'
            ? ''
            : 'Asins per month'}
        </div>
        <div>
          <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
            Number of actual ASINs:
          </span>{' '}
          {totalAsinsCount?.total_asin || 0} Asins per month
        </div>
      </COSAsins>
    );
  };

  // calculate month difference
  const isSingleMonthSelected = () => {
    const startDate = new Date(customStartDate);
    const endDate = new Date(customEndDate);
    return startDate?.getMonth() === endDate?.getMonth();
  };

  const saveCOS = () => {
    let data = [];
    if (isSingleMonthSelected()) {
      data = [
        {
          ...cosData[0],
          asins: asinsList,
        },
      ];
    } else {
      data = [...cosData];
    }

    updateCOS(id, customStartDate, customEndDate, data).then((res) => {
      if (res?.status === 200) {
        getCOSData();
        setEditFlag(false);
        setNewRowFlag(false);
        toast.success('COS Added/Updated successfully!');
      } else if (res?.status === 400) {
        toast.error('Something went wrong');
        setError(res?.data);
        document.getElementById('0stickyTdparent_status').click();
      }
    });
  };

  const checkIfAllRowsHaveData = () => {
    const list = [];
    asinsList.forEach((asin) => {
      let flag = false;
      Object.values(asin).forEach((value) => {
        if (value) {
          flag = true;
        }
      });
      if (flag) list.push(asin);
    });

    if (asinsList?.length !== 0 && list.length === asinsList?.length)
      return true;
    return false;
  };

  // display edit and save button
  const displayEditAndSaveButton = () => {
    return isEditFlagOn ? (
      <>
        <Button
          type="button"
          data-tip={
            checkIfAllRowsHaveData()
              ? null
              : 'Input text or values to enable save button.'
          }
          data-for="save-cos-disabled-info"
          className={`btn-primary  extraNormal-text ${
            checkIfAllRowsHaveData() ? null : ''
          }`}
          style={
            checkIfAllRowsHaveData()
              ? {
                  width: 'fit-content',
                }
              : {
                  pointerEvents: 'all',
                  opacity: 0.6,
                  cursor: 'not-allowed',
                  width: 'fit-content',
                }
          }
          onClick={() => {
            if (checkIfAllRowsHaveData()) saveCOS();
          }}
        >
          <FontAwesomeIcon
            className="mr-2"
            icon="fa-regular fa-check"
            color="#fff"
            fontSize="12px"
          />
          Save
        </Button>
        {checkIfAllRowsHaveData() ? null : (
          <ReactTooltip
            id="save-cos-disabled-info"
            aria-haspopup="true"
            place="top"
          />
        )}
      </>
    ) : (
      <>
        <Button
          data-tip={SpreetailRoleRestrictMessage}
          data-for={isSpreetailUser ? 'spreetailRetrictEditCOS' : ''}
          type="button"
          className="btn-primary extraNormal-text"
          style={{
            width: 'fit-content',
          }}
          onClick={() => {
            setEditFlag(true);
          }}
          disabled={isSpreetailUser}
        >
          <FontAwesomeIcon
            icon="fa-regular fa-pen"
            style={{ color: '#fff', fontSize: '12px' }}
            className="cursor  pl-1"
          />{' '}
          Edit
        </Button>
        <ReactTooltip
          id="spreetailRetrictEditCOS"
          aria-haspopup="true"
          place="top"
          effect="solid"
          html
        />
      </>
    );
  };

  // get dropdwon indicator for  dropdown filter
  const getSelectDropdownIndicator = () => {
    return {
      DropdownIndicator,
    };
  };

  const displayTable = () => {
    return (
      <COSTable
        isEditFlagOn={isEditFlagOn}
        cosData={cosData}
        asinsList={asinsList}
        setAsinsList={setAsinsList}
        selectedViewType={selectedViewType}
        isSingleMonthSelected={isSingleMonthSelected}
        cosAccessOfBP={cosAccessOfBP}
        errors={errors}
        setError={setError}
        id={id}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        newRowFlag={newRowFlag}
        setNewRowFlag={setNewRowFlag}
        saveCOS={saveCOS}
        getCOSData={getCOSData}
        setEditFlag={setEditFlag}
        getAsinCountData={getAsinCountData}
      />
    );
  };

  // display cos header section
  const displayCOSHeader = () => {
    return (
      <WhiteCard className="mb-3 mt-3">
        <ViewData>
          <div className="row">
            <div className="col-md-4 col-12 ">
              <div className="view-data-for mt-2 mb-md-0 mb-2 d-md-block ">
                {displayASINsCount()}
              </div>{' '}
            </div>
            <div className="col-md-8 col-12 ">
              <GoalsNav className="text-right">
                <ul className="inline-content">
                  <li className="action-btn">
                    {cosData?.length && cosAccessOfBP?.show
                      ? displayEditAndSaveButton()
                      : ''}
                  </li>
                  <li>
                    <div id="cos-view-filter" style={{ position: 'relative' }}>
                      <SingleSelectDropdown
                        filterId="BT-COSViewFilter"
                        className="single-select-dropdown"
                        isApiCall={false}
                        dropdownOptions={cosViewTypes}
                        selectedValue={selectedViewType}
                        onChangeHandler={handleViewTypeFilterEvents}
                        dropdownComponents={getSelectDropdownIndicator}
                        isSearchable={false}
                      />
                    </div>
                  </li>
                  <li>
                    <div id="cos-view-filter" style={{ position: 'relative' }}>
                      <COSMonthPicker
                        cosAccessOfBP={cosAccessOfBP}
                        customStartDate={customStartDate}
                        setCustomStartDate={setCustomStartDate}
                        customEndDate={customEndDate}
                        setCustomEndDate={setCustomEndDate}
                        customMonth={customMonth}
                        setCustomMonth={setCustomMonth}
                        isSingleMonthSelected={isSingleMonthSelected}
                      />
                    </div>
                  </li>
                </ul>
                {isSingleMonthSelected() ? null : (
                  <>
                    <br />
                    <strong>
                      Option to add new row will not be visible when multiple
                      months is selected.
                    </strong>{' '}
                  </>
                )}
              </GoalsNav>
            </div>
          </div>
        </ViewData>
      </WhiteCard>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in goals and cos section */}
      {cosDynamicMessage ? (
        <GrayMessageBar message={cosDynamicMessage} className="my-3" />
      ) : null}
      {cosLoader ? (
        <PageLoader
          component="agrement-details"
          color="#FF5933"
          type="detail"
          width={40}
          height={40}
        />
      ) : cosData?.length ? (
        <>
          {displayCOSHeader()}
          <GoalsStyle>{displayTable()}</GoalsStyle>
        </>
      ) : (
        <p className=" gray-medium-text my-2 text-center">No COS created</p>
      )}
    </>
  );
}

export default CreativeOptimizationScheduleContainer;

CreativeOptimizationScheduleContainer.defaultProps = {
  clickOnRefreshBtnFlag: false,
  setClickOnRefreshBtn: () => {},
};

CreativeOptimizationScheduleContainer.propTypes = {
  clickOnRefreshBtnFlag: bool,
  setClickOnRefreshBtn: func,
};
