// account types
export const VENDOR = 'Vendor';
export const SELLER = 'Seller';
export const HYBRID = 'Hybrid';
export const VENDORSMALL = 'vendor';
export const SELLERSMALL = 'seller';
export const HYBRIDSMALL = 'hybrid';

// performance tabs
export const AMCINSIGHTS = 'amcInsights';
export const CREATIVEPROJECTS = 'creativeProjects';
export const ADPERFORMANCE = 'adPerformance';
export const SALEPERFORMANCE = 'salePerformance';
export const DSPPERFORMANCE = 'dspPerformance';

// general
export const PARENT = 'parent';

// time frames
export const YEAROVERYEAR = 'yearOverYear';
export const CUSTOM = 'custom';

// sales metrics - seller
export const UNITSSOLD = 'unitsSold';
export const TRAFFIC = 'traffic';
export const CONVERSION = 'conversion';
export const TACOS = 'tacos';
export const REVENUETOTAL = 'revenueTotal';
export const REVENUEORGANIC = 'revenueOrganic';
export const REVENUEPPC = 'revenuePpc';
export const REVENUEDSP = 'revenueDsp';

// sales metrics - vendor
export const ORDEREDREVENUE = 'orderedRevenue';
export const SHIPPEDCOGS = 'shippedCOGs';
export const GLANCEVIEWS = 'glanceViews';
export const CONVERSIONRATE = 'conversionRate';
export const SHIPPEDUNITS = 'shippedUnits';
export const ORDEREDUNITS = 'orderedUnits';

// sponsored metrics
export const ADSALES = 'adSales';
export const ADSPENDTOTAL = 'adSpendTotal';
export const ADSPENDPPC = 'adSpendPpc';
export const ADSPENDDSP = 'adSpendDsp';
export const ADSPEND = 'adSpend';
export const SPONSOREDADSPEND = 'sponsoredAdSpend';
export const DSPSPEND = 'dspSpend';
export const NTBORDERS = 'ntbOrders';
export const NTBSALES = 'ntbSales';
export const PERCENTAGENTBORDERS = 'percentageNtbOrders';
export const PERCENTAGENTBSALES = 'percentageNtbSales';
export const ACOS = 'acos';
export const ROAS = 'roas';
export const ADROAS = 'adRoas';
export const ADCOS = 'adCos';
export const SALESPERCLICK = 'salesPerClick';
export const COSTPERCLICK = 'costPerClick';
export const ADCONVERSION = 'adConversion';
export const ADCLICKRATE = 'adClickRate';
export const IMPRESSIONS = 'impressions';

// Asin and campaign
export const ASIN = 'asin';
export const CAMPAIGN = 'campaign';

// sales goals metrics key
export const OVERALLSALESGOALS = 'overall_sales_goals';
export const PPCADBUDGET = 'ppc_ad_budget';
export const DSPADBUDGET = 'dsp_ad_budget';
export const REVSHARE = 'rev_share';

// Dashboard
export const PERFORMANCE = 'performance';
export const KEYMETRICS = 'keyMetrics';
export const CONTRIBUTION = 'contribution';
export const POSITIVE = 'positive';
