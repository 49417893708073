// react imports
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';

// third party imports
import $ from 'jquery';
import dayjs from 'dayjs';
import getSymbolFromCurrency from 'currency-symbol-map';
import { toast } from 'react-toastify';

// local file imports
import DspDashboardFilter from './DspDashboardFilter';
import DspRightHandSideSection from './DspRightHandSideSection';
import useBindDSPResponseData from '../../../../hooks/useBindDspData';
import {
  CUSTOM,
  YEAROVERYEAR,
  allBPObj,
  allValueObj,
} from '../../../../constants';
import {
  DspDashboardContainerDefaultProps,
  DspDashboardContainerPropTypes,
} from './DspPropTypes';
import {
  getAdManagerAdminGraphData,
  getDspPacingDahboardData,
  getKeyContributionData,
  getManagersList,
  getBgsUserList,
  getMinDateForDashboard,
  getLeadershipBrandPartners,
  getKeyContributorsdownloadReport,
} from '../../../../api';

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() - 1);

const DspDashboardContainer = ({
  // string
  responseID,
  currencySymbol,
  // boolean
  isBGS,
  isBGSAdmin,
  isBGSManager,
  isAdManager,
  isSpreetailRole,
  isAdTeamManager,
  isAdManagerAdmin,
  isCustomDateApply,
  includeInactiveBPs,
  includeSpreetailBrands,
  // object
  userInfo,
  customDate,
  selectedDspDF,
  // array
  adState,
  loggedInUser,
  marketplaceChoices,
  // function
  setAdState,
  setResponseID,
  setCustomDate,
  setSelectedDspDF,
  bindBrandPartners,
  setCurrencySymbol,
  checkIsUserExist,
  bindUserListData,
  setIsCustomDateApply,
  generateStringOfValues,
  displayContributionTable,
  bindMetricsContributionData,
  renderRecentAndPreviousLabel,
  handleMultiSelectDropdownFilterEvents,
}) => {
  const mounted = useRef(false);
  const [dspGraphLoader, setDspGraphLoader] = useState(false);
  const [keyContributionLoader, setKeyContributionLoader] = useState(false);
  const [showAdCustomDateModal, setShowAdCustomDateModal] = useState(false);
  const [dspPacingLoader, setDspPacingLoader] = useState(false);
  const [downloadReportLoader, setDownloadReportLoader] = useState(false);
  const [selectedBrandPartner, setSelectedBrandPartner] = useState(allBPObj);

  // useState - selected manager user is used for BGS Manager and Ad Team Manager
  const [selectedManagerUser, setSelectedManagerUser] = useState(
    isBGSManager || isAdTeamManager ? loggedInUser : allValueObj,
  );
  // useState - selected user is used for BGS  and Ad Manager
  const [selectedUser, setSelectedUser] = useState(
    isBGS || isAdManager ? loggedInUser : allValueObj,
  );

  const [selectedMarketplace, setSelectedMarketplace] = useState({
    value: 'all',
    label: 'All Marketplaces',
    currency: 'USD',
  });

  // use state for bgs list, bgs manager list, ad manager list, ad team manager list,
  const [userList, setUserList] = useState([]);
  const [userApiList, setUserApiList] = useState([]);
  const [managerUserList, setManagerUserList] = useState([]);
  const [managerUserApiList, setManagerUserApiList] = useState([]);
  const [isUsersSelected, setIsUsersSelected] = useState(false);
  const [isManagerUsersSelected, setIsManagerUsersSelected] = useState(false);
  const [brandPartnerOptions, setBrandPartnerOptions] = useState([]);
  const [contributionData, setContributionData] = useState(null);
  const [tempDspChartData, setTempDSPChartData] = useState(null);
  const [dspPacingData, setDspPacingData] = useState(null);
  const [responseId, setResponseId] = useState(null);
  const [isManagerAPICalled, setIsManagerAPICalled] = useState(
    !(isAdManagerAdmin || isBGSAdmin || isSpreetailRole),
  );
  const [isBGSAPICalled, setIsBGSAPICalled] = useState(
    !(isBGSAdmin || isBGSManager || isSpreetailRole),
  );
  const [marketplaceOptions, setMarketplaceOptions] =
    useState(marketplaceChoices);
  const [pageNumber, setPageNumber] = useState();
  const [contributionCount, setContributionCount] = useState(null);
  const [dspGroupBy, setDSPGroupBy] = useState('daily');
  const [customDateError, setCustomDateError] = useState(null);
  const [selectedSpendingOption, setSelectedSpendingOption] =
    useState('overspending');
  const [selectedDspMetrics, setSelectedDspMetrics] = useState({
    dspImpressions: true,
  });
  const [selectedTabMetrics, setSelectedTabMetrics] =
    useState('dspImpressions');

  // all brand partner object
  const allBrandPartnerObj = {
    ...allBPObj,
    marketplaces: marketplaceChoices,
  };

  // default all value array of object
  const allObj = useMemo(() => {
    return allValueObj;
  }, []);

  // use effect for set marketplace options
  useEffect(() => {
    setMarketplaceOptions(marketplaceChoices);
    setSelectedMarketplace(marketplaceChoices?.[0]);
  }, [marketplaceChoices]);

  const { dspChartData, dspCurrentTotal, dspPreviousTotal, dspDifference } =
    useBindDSPResponseData(tempDspChartData);

  // get bp list
  const getLeadershipBrandPartnersList = useCallback(
    (callType, managersUser, bgsUser, selectedBp) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);

      getLeadershipBrandPartners(
        userInfo?.role,
        'dsp_ad_performance',
        managerUserString,
        bgsUserString,
        includeInactiveBPs,
        includeSpreetailBrands,
      ).then((response) => {
        if (response?.status === 200 && response?.data?.length) {
          const bpList = bindBrandPartners(response?.data);

          setBrandPartnerOptions(bpList);
          const brandPartner =
            callType === 'useEffect' ? selectedBp : bpList?.[0];
          setSelectedBrandPartner(brandPartner);
        } else {
          setBrandPartnerOptions([]);
          setSelectedBrandPartner({});
        }
      });
    },
    [
      bindBrandPartners,
      generateStringOfValues,
      includeInactiveBPs,
      includeSpreetailBrands,
      setSelectedBrandPartner,
      userInfo,
    ],
  );

  // get user list - bgs and ad manager
  const getBgsList = useCallback(
    (role, managers, users, callType, selectedDailyFact) => {
      setDspGraphLoader(true);
      setKeyContributionLoader(selectedDailyFact !== CUSTOM);
      setDspPacingLoader(true);
      const managersString = generateStringOfValues(managers);

      let API = '';
      if (role === 'BGS') {
        API = getBgsUserList(role, managersString);
      }
      if (role === 'Ad Manager') {
        API = getManagersList(role, 'sales_performance', managersString);
      }

      API.then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const formatedApiData = bindUserListData(response?.data, role);
            setUserList(formatedApiData?.userListWithAll);
            setUserApiList(formatedApiData?.userList);

            const isUserExist = checkIsUserExist(
              formatedApiData?.userList,
              users,
            );

            if (callType !== 'bgsFilter') {
              if (
                isUserExist &&
                callType !== 'resetFilter' &&
                callType !== 'managerUserFilter'
              ) {
                setSelectedUser(users);
              } else {
                setSelectedUser(formatedApiData?.userListWithAll);
              }
            }

            setIsBGSAPICalled(true);
          } else {
            setUserList([]);
            setSelectedUser([]);
            setUserApiList([]);
          }
        }
      });
    },

    [
      bindUserListData,
      checkIsUserExist,
      generateStringOfValues,
      setSelectedUser,
    ],
  );

  // get manager user list - bgs manager and ad team manager
  const getManagerUserList = useCallback(
    (role, localManagerUsers, localUsers, callType, selectedDailyFact) => {
      setDspGraphLoader(true);
      setKeyContributionLoader(selectedDailyFact !== CUSTOM);
      setDspPacingLoader(true);

      let API = '';
      if (role === 'BGS Manager') {
        API = getBgsUserList(role);
      }
      if (role === 'Ad Team Manager') {
        API = getManagersList(role, 'sales_performance');
      }

      API.then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const formatedApiData = bindUserListData(response?.data);
            setManagerUserApiList(formatedApiData?.userList);
            setManagerUserList(formatedApiData?.userListWithAll);

            const isManagerUserExist = checkIsUserExist(
              formatedApiData?.userList,
              localManagerUsers,
            );
            let managerUser = null;

            if (
              (isBGSAdmin || isAdManagerAdmin || isSpreetailRole) &&
              isManagerUserExist &&
              callType !== 'resetFilter'
            ) {
              setSelectedManagerUser(localManagerUsers);
              managerUser = localManagerUsers;
            } else {
              setSelectedManagerUser(formatedApiData?.userListWithAll);
              managerUser =
                formatedApiData?.userList?.length > 1
                  ? null
                  : formatedApiData?.userList;
            }
            if (isBGSAdmin || isSpreetailRole) {
              getBgsList(
                'BGS',
                managerUser,
                localUsers,
                callType,
                selectedDailyFact,
              );
            }
            if (isAdManagerAdmin) {
              getBgsList(
                'Ad Manager',
                managerUser,
                localUsers,
                callType,
                selectedDailyFact,
              );
            }

            setIsManagerAPICalled(true);
          } else {
            setManagerUserApiList([]);
            setManagerUserList([]);
            setSelectedManagerUser([]);
          }
        }
      });
    },

    [
      bindUserListData,
      checkIsUserExist,
      getBgsList,
      isAdManagerAdmin,
      isBGSAdmin,
      isSpreetailRole,
      setSelectedManagerUser,
    ],
  );

  // calling bgs and advertising user api
  const callingBgsAndAdUserApi = useCallback(
    (callType, localManagerUsers, localUsers, selectedDailyFact) => {
      //  advertising api
      if (isAdManagerAdmin) {
        getManagerUserList(
          'Ad Team Manager',
          localManagerUsers,
          localUsers,
          callType,
          selectedDailyFact,
        );
      }

      if (isAdTeamManager) {
        getBgsList(
          'Ad Manager',
          loggedInUser,
          localUsers,
          callType,
          selectedDailyFact,
        );
      }

      // bgs api
      if (isBGSAdmin || isSpreetailRole) {
        getManagerUserList(
          'BGS Manager',
          localManagerUsers,
          localUsers,
          callType,
          selectedDailyFact,
        );
      }

      if (isBGSManager) {
        getBgsList(
          'BGS',
          loggedInUser,
          localUsers,
          callType,
          selectedDailyFact,
        );
      }
    },
    [
      getBgsList,
      getManagerUserList,
      isAdManagerAdmin,
      isAdTeamManager,
      isBGSAdmin,
      isBGSManager,
      isSpreetailRole,
      loggedInUser,
    ],
  );

  // use effect for bgs and advertising team
  useEffect(() => {
    mounted.current = true;
    callingBgsAndAdUserApi(
      'useEffect',
      selectedManagerUser,
      selectedUser,
      selectedDspDF?.value,
    );
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingBgsAndAdUserApi]);

  const getDSPData = useCallback(
    (
      selectedDailyFact,
      selectedGroupBy,
      brandPartner,
      marketplace,
      managersUser,
      bgsUser,
      startDate = null,
      endDate = null,
      inactiveBPs,
      spreetailBrands,
    ) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);

      setDspGraphLoader(true);
      setIsUsersSelected(false);
      setIsManagerUsersSelected(false);
      setTempDSPChartData(null);

      getAdManagerAdminGraphData(
        'dsp-dashboard',
        null,
        selectedDailyFact,
        selectedGroupBy,
        brandPartner,
        marketplace,
        null,
        managerUserString,
        bgsUserString,
        isBGSAdmin || isSpreetailRole,
        isBGSManager,
        isBGS,
        isAdManagerAdmin,
        isAdTeamManager,
        isAdManager,
        startDate,
        endDate,
        inactiveBPs || includeInactiveBPs,
        spreetailBrands || includeSpreetailBrands,
      ).then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            if (response?.data?.result) {
              setTempDSPChartData(response?.data?.result);
            } else {
              setTempDSPChartData(null);
            }
            setDspGraphLoader(false);
          } else {
            setTempDSPChartData(null);
            setDspGraphLoader(false);
          }
        }
      });
    },
    [
      generateStringOfValues,
      isBGSAdmin,
      isSpreetailRole,
      isBGSManager,
      isBGS,
      isAdManagerAdmin,
      isAdTeamManager,
      isAdManager,
      includeInactiveBPs,
      includeSpreetailBrands,
    ],
  );

  // callback function for get dsp contribution data
  const getContributionData = useCallback(
    (
      selectedDailyFact,
      brandPartner,
      marketplace,
      managersUser,
      bgsUser,
      selectedMatrics,
      startDate = null,
      endDate = null,
      page,
      inactiveBPs,
      spreetailBrands,
    ) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);

      setKeyContributionLoader(true);
      setIsUsersSelected(false);
      setIsManagerUsersSelected(false);
      setContributionData([]);

      getKeyContributionData(
        'dsp_ad_performance',
        null,
        selectedDailyFact,
        brandPartner,
        marketplace,
        null,
        managerUserString,
        bgsUserString,
        isBGSAdmin || isSpreetailRole,
        isBGSManager,
        isBGS,
        isAdManagerAdmin,
        isAdTeamManager,
        isAdManager,
        selectedMatrics,
        startDate,
        endDate,
        page,
        inactiveBPs || includeInactiveBPs,
        spreetailBrands || includeSpreetailBrands,
      ).then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const responseData =
              response?.data?.result || response?.data?.results;
            if (responseData) {
              const formatedData = bindMetricsContributionData(responseData);
              setContributionData(formatedData);
              setContributionCount(response?.data?.count);
            } else {
              setContributionData([]);
              setPageNumber(page);
            }
            setKeyContributionLoader(false);
          } else {
            setContributionData([]);
            setPageNumber(page);
            setKeyContributionLoader(false);
          }
        }
      });
    },
    [
      generateStringOfValues,
      isBGSAdmin,
      isSpreetailRole,
      isBGSManager,
      isBGS,
      isAdManagerAdmin,
      isAdTeamManager,
      isAdManager,
      includeInactiveBPs,
      includeSpreetailBrands,
      bindMetricsContributionData,
    ],
  );

  // get dsp pacing dashboard data
  const getDSPPacingData = useCallback(
    (
      brandPartner,
      marketplace,
      managersUser,
      bgsUser,
      spendingOption,
      inactiveBPs,
      spreetailBrands,
    ) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);

      setDspPacingLoader(true);
      setIsUsersSelected(false);
      setIsManagerUsersSelected(false);
      setDspPacingData(null);

      getDspPacingDahboardData(
        brandPartner,
        marketplace,
        managerUserString,
        bgsUserString,
        spendingOption,
        isBGSAdmin || isSpreetailRole,
        isBGSManager,
        isBGS,
        isAdManagerAdmin,
        isAdTeamManager,
        isAdManager,
        inactiveBPs || includeInactiveBPs,
        spreetailBrands || includeSpreetailBrands,
      ).then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const result = response?.data?.result;
            const results = response?.data?.results;
            if (result) {
              setDspPacingData(result);
            } else if (results) {
              setDspPacingData(results);
            } else {
              setDspPacingData(null);
            }
            setDspPacingLoader(false);
          } else {
            setDspPacingData(null);
            setDspPacingLoader(false);
          }
        }
      });
    },

    [
      generateStringOfValues,
      isBGSAdmin,
      isSpreetailRole,
      isBGSManager,
      isBGS,
      isAdManagerAdmin,
      isAdTeamManager,
      isAdManager,
      includeInactiveBPs,
      includeSpreetailBrands,
    ],
  );

  // bind the error message if data not available for selecetd custom date
  // API to get the data available date for sales
  const getMinDateForSponsoredDashboard = useCallback(() => {
    const dates = [];
    const selectedUserString = generateStringOfValues(selectedUser);
    const selectedManagerUserString =
      generateStringOfValues(selectedManagerUser);

    getMinDateForDashboard(
      selectedMarketplace?.value,
      selectedManagerUserString,
      selectedUserString,
      null,
      'dsp_dashboard',
    ).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.result?.length) {
          dates.push(
            new Date(dayjs(adState[0]?.startDate).format('MM/DD/YYYY')),
          );
          dates.push(
            new Date(
              dayjs(res?.data?.result[0]?.dsp_performance_date).format(
                'MM/DD/YYYY',
              ),
            ),
          );

          // if selected  date is lower that data_availeble_date then we need to show the error message
          if (dates[0] < dates[1]) {
            setCustomDateError({
              fromDate: dates[0],
              toDate: dates[1],
            });
          } else {
            setCustomDateError(null);
          }
        }
      }
    });
  }, [
    adState,
    generateStringOfValues,
    selectedManagerUser,
    selectedMarketplace,
    selectedUser,
  ]);

  // get metrics, data, key contribution data, get brand partner and dsp pacing data
  // - called only at the begining.
  useEffect(() => {
    mounted.current = true;

    if (
      (responseId === null || responseID === 'response') &&
      isManagerAPICalled &&
      isBGSAPICalled
    ) {
      const startDate = customDate?.startDate;
      const endDate = customDate?.endDate;
      const dateType = customDate?.dateType;

      // get brand partners list
      getLeadershipBrandPartnersList(
        'useEffect',
        isBGSManager || isAdTeamManager ? loggedInUser : allValueObj,
        isBGS || isAdManager ? loggedInUser : allValueObj,
        allBPObj,
      );

      getMinDateForSponsoredDashboard();

      if (dateType === CUSTOM) {
        getDSPData(
          selectedDspDF?.value,
          dspGroupBy,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          startDate,
          endDate,
          includeInactiveBPs,
          includeSpreetailBrands,
        );
      }
      if (
        dateType === null &&
        selectedDspDF?.value !== 'year' &&
        selectedDspDF?.value !== YEAROVERYEAR
      ) {
        getDSPData(
          selectedDspDF?.value,
          dspGroupBy,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          null,
          null,
          includeInactiveBPs,
          includeSpreetailBrands,
        );
        getContributionData(
          selectedDspDF?.value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          selectedTabMetrics,
          null,
          null,
          pageNumber,
          includeInactiveBPs,
          includeSpreetailBrands,
        );
      }
      getDSPPacingData(
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        selectedSpendingOption,
        includeInactiveBPs,
        includeSpreetailBrands,
      );

      setResponseId('12345');
      setResponseID(null);
    }
    return () => {
      mounted.current = false;
    };
  }, [
    customDate,
    dspGroupBy,
    getContributionData,
    getDSPData,
    getDSPPacingData,
    getMinDateForSponsoredDashboard,
    includeInactiveBPs,
    includeSpreetailBrands,
    isAdManagerAdmin,
    isBGSAPICalled,
    isBGSAdmin,
    isSpreetailRole,
    isManagerAPICalled,
    pageNumber,
    responseID,
    responseId,
    selectedDspDF,
    selectedManagerUser,
    selectedMarketplace,
    selectedSpendingOption,
    selectedTabMetrics,
    selectedUser,
    setResponseID,
    selectedBrandPartner,
    getLeadershipBrandPartnersList,
    isBGSManager,
    isAdTeamManager,
    loggedInUser,
    isBGS,
    isAdManager,
  ]);

  // called only if the time frame is selected for "year" and "year OverYear"
  useEffect(() => {
    mounted.current = true;
    if (
      selectedDspDF?.value === 'year' ||
      selectedDspDF?.value === YEAROVERYEAR
    ) {
      setSelectedDspDF({
        value: 'week',
        label: 'Current 7 days',
        sub: 'vs Previous 7 days',
      });
      getDSPData(
        'week',
        dspGroupBy,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        null,
        null,
        includeInactiveBPs,
        includeSpreetailBrands,
      );
      getContributionData(
        'week',
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        selectedTabMetrics,
        null,
        null,
        pageNumber,
        includeInactiveBPs,
        includeSpreetailBrands,
      );
    }
    return () => {
      mounted.current = false;
    };
  }, [
    dspGroupBy,
    getContributionData,
    getDSPData,
    includeInactiveBPs,
    includeSpreetailBrands,
    pageNumber,
    selectedBrandPartner.value,
    selectedDspDF,
    selectedManagerUser,
    selectedMarketplace,
    selectedTabMetrics,
    selectedUser,
    setSelectedDspDF,
  ]);

  // handle dsp custom date change events
  // This function take 6 params and handle the api calls for dsp performance data
  const DSPYearAndCustomDateFilter = (
    startDate,
    endDate,
    value,
    marketplace = selectedMarketplace?.value,
    managerUser,
    bgsUser,
    brandPartner,
  ) => {
    let sd = startDate;
    let ed = endDate;
    setDSPGroupBy('daily');
    sd = `${startDate.getDate()}-${
      startDate.getMonth() + 1
    }-${startDate.getFullYear()}`;
    ed = `${endDate.getDate()}-${
      endDate.getMonth() + 1
    }-${endDate.getFullYear()}`;

    setCustomDate({
      startDate: sd,
      endDate: ed,
      dateType: value,
    });

    getDSPData(
      value,
      'daily',
      brandPartner,
      marketplace,
      managerUser,
      bgsUser,
      sd,
      ed,
    );
  };

  // calling leadership BP and dashboard performance api when filter value changes
  const callingBPandDashboardAPI = (
    callType,
    managerUsers,
    users,
    selectedBp,
  ) => {
    let brandPartner = {};
    let marketplace = {};

    const managerUserString = generateStringOfValues(managerUsers);
    const userString = generateStringOfValues(users);

    getLeadershipBrandPartners(
      userInfo?.role,
      'dsp_ad_performance',
      managerUserString,
      userString,
      includeInactiveBPs,
      includeSpreetailBrands,
    ).then((response) => {
      if (response?.status === 200 && response?.data?.length) {
        setPageNumber(1);

        const bpList = bindBrandPartners(response?.data);
        setBrandPartnerOptions(bpList);

        if (callType === 'bpFilter') {
          if (selectedBp?.value === 'all') {
            brandPartner = allBrandPartnerObj;
          } else {
            brandPartner = selectedBp;
          }
        } else if (bpList?.length === 1) {
          brandPartner = bpList?.[0];
        } else {
          brandPartner = allBrandPartnerObj;
        }

        marketplace = brandPartner?.marketplaces?.[0];
        setSelectedBrandPartner(brandPartner);
        setSelectedMarketplace(marketplace);
        setMarketplaceOptions(brandPartner?.marketplaces);
        setSelectedTabMetrics('dspImpressions');

        if (selectedDspDF?.value === CUSTOM) {
          DSPYearAndCustomDateFilter(
            adState[0]?.startDate,
            adState[0]?.endDate,
            CUSTOM,
            marketplace?.value,
            managerUsers,
            users,
            brandPartner?.value,
          );
        } else {
          getDSPData(
            selectedDspDF?.value,
            dspGroupBy,
            brandPartner?.value,
            marketplace?.value,
            managerUsers,
            users,
          );
          getContributionData(
            selectedDspDF?.value,
            brandPartner?.value,
            marketplace?.value,
            managerUsers,
            users,
            selectedTabMetrics,
            null,
            null,
            1,
          );
        }

        getDSPPacingData(
          brandPartner?.value,
          marketplace?.value,
          managerUsers,
          users,
          selectedSpendingOption,
        );
      } else {
        setDspGraphLoader(false);
        setKeyContributionLoader(false);
        setDspPacingLoader(false);
        setIsUsersSelected(false);
        setIsManagerUsersSelected(false);
        setBrandPartnerOptions([]);
        setSelectedBrandPartner({});
        setTempDSPChartData(null);
        setContributionData([]);
        setDspPacingData(null);
      }
    });
  };

  // handle marketplace filter events
  const handleMarketplace = (event) => {
    setSelectedMarketplace(event);
    setCurrencySymbol(getSymbolFromCurrency(event?.currency));
    if (selectedDspDF?.value === CUSTOM) {
      DSPYearAndCustomDateFilter(
        adState[0]?.startDate,
        adState[0]?.endDate,
        CUSTOM,
        event?.value,
        selectedManagerUser,
        selectedUser,
        selectedBrandPartner?.value,
      );
    } else {
      getDSPData(
        selectedDspDF?.value,
        dspGroupBy,
        selectedBrandPartner?.value,
        event?.value,
        selectedManagerUser,
        selectedUser,
      );
      getContributionData(
        selectedDspDF?.value,
        selectedBrandPartner?.value,
        event?.value,
        selectedManagerUser,
        selectedUser,
        selectedTabMetrics,
        null,
        null,
        1,
      );
      setPageNumber(1);
    }
    getDSPPacingData(
      selectedBrandPartner?.value,
      event?.value,
      selectedManagerUser,
      selectedUser,
      selectedSpendingOption,
    );
  };

  // handle brand partner reporting filter events
  const handledBrandPartnerFilterEvents = (event) => {
    // Handeling the states and API calls when user select specific Brand Partner
    if (event?.value !== selectedBrandPartner?.value) {
      setSelectedBrandPartner(event);

      // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
      let assignedUser = [];
      let assignedMangerUser = [];

      if (event?.value === 'all') {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        const userApiListWithAll = [...userApiList];
        if (userApiListWithAll?.length) {
          userApiListWithAll.unshift(allObj?.[0]);
        }

        const managerUserApiListWithAll = [...managerUserApiList];
        if (managerUserApiListWithAll?.length)
          managerUserApiListWithAll.unshift(allObj?.[0]);

        assignedUser = selectedUser?.length ? selectedUser : userApiListWithAll;
        assignedMangerUser = selectedManagerUser?.length
          ? selectedManagerUser
          : managerUserApiListWithAll;

        if (selectedManagerUser?.length === 0)
          setManagerUserList(managerUserApiListWithAll);
        if (selectedUser?.length === 0) setUserList(userApiListWithAll);
      } else if (isBGSAdmin || isBGSManager || isBGS || isSpreetailRole) {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        assignedUser = event?.bgs !== null ? [event?.bgs] : [];
        assignedMangerUser =
          event?.bgsManager !== null ? [event?.bgsManager] : [];
      } else {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        assignedUser = event?.adManager !== null ? [event?.adManager] : [];
        assignedMangerUser =
          event?.adTeamManager !== null ? [event?.adTeamManager] : [];
      }

      // set assigned bgs manager/ad team manager, bgs/ad manager of selected BP to multi-select bgs manager/ad team manager, bgs/ad manager dropdown filter.
      setSelectedManagerUser(assignedMangerUser);
      setSelectedUser(assignedUser);

      // if selected brand partner do not have assigned bgs manager/ad team manager and bgs/ad manager then clear the multiselect manager user and user dropdown filter list

      if (assignedMangerUser?.length === 0) {
        setManagerUserList([]);
      } else {
        const managerUserApiListWithAll = [...managerUserApiList];
        managerUserApiListWithAll.unshift(allObj?.[0]);
        setManagerUserList(managerUserApiListWithAll);
      }
      if (assignedUser?.length === 0) setUserList([]);

      // if selected brand partner have assigned bgs manager/ad team manager and bgs/ad manager then get its the bgs/ad manager list
      if (assignedMangerUser?.length) {
        if (isBGSAdmin || isSpreetailRole) {
          getBgsList(
            'BGS',
            assignedMangerUser,
            assignedUser,
            'BPFilter',
            selectedDspDF?.value,
          );
        }

        if (isAdManagerAdmin) {
          getBgsList(
            'Ad Manager',
            assignedMangerUser,
            assignedUser,
            'BPFilter',
            selectedDspDF?.value,
          );
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI(
        'bpFilter',
        assignedMangerUser?.length ? assignedMangerUser : allObj,
        assignedUser?.length ? assignedUser : allObj,
        event,
      );
    }
  };

  // handle bgs manager/ad team manager filter change events
  const handleManagerUserFilterEvents = (event, action) => {
    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedManagerUser,
      managerUserApiList,
    );
    setSelectedManagerUser(selectedValues);
    setIsManagerUsersSelected(true);
  };

  // handle bgs manager/ad team manager on dropdwon menu close events
  const handleManagerUserOnMenuCloseEvents = () => {
    if (isManagerUsersSelected) {
      const managerUsers =
        selectedManagerUser?.length > 0 ? selectedManagerUser : managerUserList;
      setSelectedManagerUser(managerUsers);
      const isAllUser = managerUsers?.some((user) => user?.value === 'all');
      const users = allObj;

      if (isBGSAdmin || isSpreetailRole) {
        if (isAllUser) {
          getBgsList(
            'BGS',
            null,
            users,
            'managerUserFilter',
            selectedDspDF?.value,
          );
        } else {
          getBgsList(
            'BGS',
            managerUsers,
            users,
            'managerUserFilter',
            selectedDspDF?.value,
          );
        }
      }

      if (isAdManagerAdmin) {
        if (isAllUser) {
          getBgsList(
            'Ad Manager',
            null,
            users,
            'managerUserFilter',
            selectedDspDF?.value,
          );
        } else {
          getBgsList(
            'Ad Manager',
            managerUsers,
            users,
            'managerUserFilter',
            selectedDspDF?.value,
          );
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI(
        'managerUsersFilter',
        isAllUser ? allObj : managerUsers,
        users,
        null,
      );
    }
  };

  // handle bgs/ad manager filter change events
  const handleUserFilterEvents = (event, action) => {
    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedUser,
      userApiList,
    );

    setSelectedUser(selectedValues);
    setIsUsersSelected(true);
  };

  // handle bgs/ad manager on dropdwon menu close events
  const handleUserOnMenuCloseEvents = () => {
    if (isUsersSelected) {
      let managerUsers = selectedManagerUser;
      const users = selectedUser?.length > 0 ? selectedUser : userList;

      setSelectedUser(users);
      // When bgs manager and ad team manager filter set to all then Automatically set the bgs manager or ad team manager assigned to selected bgs or ad team manager

      if (
        (isBGSAdmin || isAdManagerAdmin || isSpreetailRole) &&
        selectedUser?.length === 1
      ) {
        const userExisted = userList?.find(
          (bgsUser) =>
            bgsUser?.value === selectedUser?.[0]?.value &&
            bgsUser?.managerUser !== null,
        );

        if (userExisted !== undefined) {
          const managerUserObject = {
            value: userExisted?.managerUser?.id,
            label: `${userExisted?.managerUser?.first_name} ${userExisted?.managerUser?.last_name}`,
          };
          managerUsers = [managerUserObject];
          setSelectedManagerUser([managerUserObject]);
          if (isBGSAdmin || isSpreetailRole) {
            getBgsList(
              'BGS',
              managerUsers,
              users,
              'bgsFilter',
              selectedDspDF?.value,
            );
          } else {
            getBgsList(
              'Ad Manager',
              managerUsers,
              users,
              'bgsFilter',
              selectedDspDF?.value,
            );
          }
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI('usersFilter', managerUsers, users, null);
    }
  };

  // download key contributors report
  const handleKeyContributorsDownloadReport = () => {
    setDownloadReportLoader(true);

    const userString = generateStringOfValues(selectedUser);
    const managerUserString = generateStringOfValues(selectedManagerUser);

    const startDate = dayjs(adState[0]).format('YYYY-MM-DD');
    const endDate = dayjs(adState[0]).format('YYYY-MM-DD');

    getKeyContributorsdownloadReport(
      isBGSAdmin,
      isBGSManager,
      isBGS,
      isAdManagerAdmin,
      isAdTeamManager,
      isAdManager,
      'dsp_ad_performance',
      selectedDspDF?.value,
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedTabMetrics,
      managerUserString,
      userString,
      startDate,
      endDate,
      includeInactiveBPs,
      includeSpreetailBrands,
    ).then((response) => {
      if (response?.status === 200) {
        const csvLink = response?.data?.url;
        if (csvLink) window.open(csvLink);
        if (response?.data?.message) toast.success(response?.data?.message);
        setDownloadReportLoader(false);
      } else {
        toast.error('Oops! Something went wrong...');
        setDownloadReportLoader(false);
      }
    });
  };

  // reset button events - reset all bgs and advertising user filter values to 'all'
  const handleResetFilter = () => {
    $('.checkboxes input:radio').filter("[value='all']").prop('checked', true);

    setSelectedBrandPartner(allBPObj);
    setCurrencySymbol(getSymbolFromCurrency('USD'));
    setSelectedMarketplace(marketplaceChoices?.[0]);

    // set assigned bgs manager/ad team manager, bgs/ad manager of selected BP to multi-select bgs manager/ad team manager, bgs/ad manager dropdown filter.
    const managerUser = isBGSManager || isAdTeamManager ? loggedInUser : allObj;
    const localUser = isBGS || isAdManager ? loggedInUser : allObj;
    setSelectedUser(localUser);
    setSelectedManagerUser(managerUser);

    // calling Leadership-customer api
    getLeadershipBrandPartnersList(
      'resetFilter',
      managerUser,
      localUser,
      allBrandPartnerObj,
    );

    // calling bgs and advertising user api
    callingBgsAndAdUserApi(
      'resetFilter',
      managerUser,
      localUser,
      selectedDspDF?.value,
    );

    setCustomDateError(null);

    // send reset valeus to api
    if (selectedDspDF.value === CUSTOM) {
      DSPYearAndCustomDateFilter(
        adState[0]?.startDate,
        adState[0]?.endDate,
        CUSTOM,
        'all',
        managerUser,
        localUser,
        selectedBrandPartner?.value,
      );
    } else {
      getDSPData(
        selectedDspDF?.value,
        dspGroupBy,
        'all',
        'all',
        managerUser,
        localUser,
      );
      getContributionData(
        selectedDspDF?.value,
        'all',
        'all',
        managerUser,
        localUser,
        selectedTabMetrics,
        null,
        null,
        1,
      );
      setPageNumber(1);
    }
    getDSPPacingData(
      'all',
      'all',
      managerUser,
      localUser,
      selectedSpendingOption,
    );
  };

  // function - handle time frame filter events
  const handleDspDailyFact = (event) => {
    const { value } = event;

    if (value !== CUSTOM) {
      setCustomDateError(null);
      setSelectedDspDF(event);
      setIsCustomDateApply(false);
      setAdState([
        {
          startDate: currentDate,
          endDate: currentDate,
          key: 'adSelection',
        },
      ]);
      setDSPGroupBy('daily');
      getDSPData(
        value,
        'daily',
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
      );
      getContributionData(
        value,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        selectedTabMetrics,
        null,
        null,
        pageNumber,
      );
    } else if (value === CUSTOM) {
      setShowAdCustomDateModal(true);
    }
  };

  // function - apply custom date
  const applyCustomDate = () => {
    if (selectedDspDF?.value !== CUSTOM)
      setSelectedDspDF({
        value: CUSTOM,
        label: 'Custom Range',
        sub: 'Select start & end date',
      });
    setIsCustomDateApply(true);
    DSPYearAndCustomDateFilter(
      adState[0]?.startDate,
      adState[0]?.endDate,
      CUSTOM,
      selectedMarketplace?.value,
      selectedManagerUser,
      selectedUser,
      selectedBrandPartner?.value,
    );
    setCustomDateError(null);
    setShowAdCustomDateModal(false);
  };

  // function - handle dealy weekly and monthly toggle events
  const handleDSPGroupBy = (value) => {
    if (value !== dspGroupBy) {
      setDSPGroupBy(value);

      if (selectedDspDF?.value === CUSTOM) {
        const { startDate } = adState[0];
        const { endDate } = adState[0];
        let sd = startDate;
        let ed = endDate;
        sd = `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()}`;
        ed = `${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`;

        getDSPData(
          selectedDspDF?.value,
          value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          sd,
          ed,
        );
      } else {
        getDSPData(
          selectedDspDF?.value,
          value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
        );
      }
    }
  };

  // function - handle on metrics tab change events
  const handleOnMatricsTabChange = (value) => {
    if (value !== selectedTabMetrics) {
      setSelectedTabMetrics(value);
      getContributionData(
        selectedDspDF?.value,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        value,
        null,
        null,
        pageNumber,
      );
    }
  };

  // function - handle pagination events of key contributors
  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getContributionData(
      selectedDspDF?.value,
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedManagerUser,
      selectedUser,
      selectedTabMetrics,
      null,
      null,
      currentPage,
    );
  };

  // function handle dsp spending toggle events
  const handleSpendingOptions = (type) => {
    if (type !== selectedSpendingOption) {
      setSelectedSpendingOption(type);
      getDSPPacingData(
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        type,
      );
    }
  };

  // display dsp filter section
  const displayDspFilterSection = () => {
    return (
      <DspDashboardFilter
        // boolean
        isApiCall={dspGraphLoader || keyContributionLoader}
        isBGSAdmin={isBGSAdmin}
        isSpreetailRole={isSpreetailRole}
        isBGSManager={isBGSManager}
        isAdManagerAdmin={isAdManagerAdmin}
        isAdTeamManager={isAdTeamManager}
        // objects
        selectedMarketplace={selectedMarketplace}
        selectedBrandPartner={selectedBrandPartner}
        // array
        userList={userList}
        managerUserList={managerUserList}
        selectedUser={selectedUser}
        selectedManagerUser={selectedManagerUser}
        marketplaceOptions={marketplaceOptions}
        brandPartnerOptions={brandPartnerOptions}
        // function
        handleResetFilter={handleResetFilter}
        handleMarketplace={handleMarketplace}
        setSelectedUser={setSelectedUser}
        setSelectedManagerUser={setSelectedManagerUser}
        handleUserFilterEvents={handleUserFilterEvents}
        handleUserOnMenuCloseEvents={handleUserOnMenuCloseEvents}
        handleManagerUserFilterEvents={handleManagerUserFilterEvents}
        handleManagerUserOnMenuCloseEvents={handleManagerUserOnMenuCloseEvents}
        handledBrandPartnerFilterEvents={handledBrandPartnerFilterEvents}
      />
    );
  };

  // display dsp right hand side section
  const displayDspRightHandSideSection = () => {
    return (
      <DspRightHandSideSection
        // number
        pageNumber={pageNumber}
        contributionCount={contributionCount}
        // boolean
        isCustomDateApply={isCustomDateApply}
        dspGraphLoader={dspGraphLoader}
        dspPacingLoader={dspPacingLoader}
        downloadReportLoader={downloadReportLoader}
        keyContributionLoader={keyContributionLoader}
        showAdCustomDateModal={showAdCustomDateModal}
        // string
        currencySymbol={currencySymbol}
        dspGroupBy={dspGroupBy}
        selectedTabMetrics={selectedTabMetrics}
        selectedSpendingOption={selectedSpendingOption}
        // object
        selectedDspDF={selectedDspDF}
        dspPacingData={dspPacingData}
        customDateError={customDateError}
        selectedDspMetrics={selectedDspMetrics}
        selectedBrandPartner={selectedBrandPartner}
        // array
        dspChartData={dspChartData}
        dspCurrentTotal={dspCurrentTotal}
        dspPreviousTotal={dspPreviousTotal}
        dspDifferenceData={dspDifference}
        contributionData={contributionData}
        dspCustomDateState={adState}
        // function
        applyCustomDate={applyCustomDate}
        handleDSPGroupBy={handleDSPGroupBy}
        handlePageChange={handlePageChange}
        handleDspDailyFact={handleDspDailyFact}
        handleSpendingOptions={handleSpendingOptions}
        setDspCustomDateState={setAdState}
        setSelectedDspMetrics={setSelectedDspMetrics}
        setShowAdCustomDateModal={setShowAdCustomDateModal}
        handleOnMatricsTabChange={handleOnMatricsTabChange}
        displayContributionTable={displayContributionTable}
        renderRecentAndPreviousLabel={renderRecentAndPreviousLabel}
        handleKeyContributorsDownloadReport={
          handleKeyContributorsDownloadReport
        }
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div className="row mt-3">
      <div className="col-xl-2 col-lg-3 col-md-12 col-12">
        {displayDspFilterSection()}
      </div>
      <div className="col-xl-10 col-lg-9 col-md-12 col-12">
        {displayDspRightHandSideSection()}
      </div>
    </div>
  );
};

export default DspDashboardContainer;
/* ********** Declaring prop types ********** */
DspDashboardContainer.defaultProps = DspDashboardContainerDefaultProps;
DspDashboardContainer.propTypes = DspDashboardContainerPropTypes;
