import React from 'react';

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { func, string, bool } from 'prop-types';

import Theme from '../../../../theme/Theme';
import { WhiteCard } from '../../../../common';
import { stockedTooltip } from '../../../../constants';

const UnderStockOverStockASINMobileView = ({
  dataIndex,
  className,
  label,
  labelInfo,
  label1,
  label2,
  label3,
  label4,
  label5,
  label6,
  label7,
  label8,
  label9,
  labelInfo1,
  labelInfo2,
  labelInfo3,
  labelInfo4,
  labelInfo5,
  labelInfo6,
  labelInfo7,
  labelInfo8,
  labelInfo9,
  onClick,
  showBorder,
  labelProductName,
  labelInfoProductName,
}) => {
  const renderASINsHTML = (
    key,
    value,
    valueClass,
    showTooltip,
    tooltipInfo,
  ) => {
    return (
      <div className="col-6 mb-3">
        <div className="label">
          {key}
          {showTooltip ? (
            <>
              <FontAwesomeIcon
                color={Theme.gray40}
                icon="far fa-question-circle"
                style={{
                  marginLeft: '4px',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
                data-tip={tooltipInfo}
                data-for={`${key}${dataIndex}`}
              />

              <ReactTooltip
                class="initialTextTransform"
                id={`${key}${dataIndex}`}
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />
            </>
          ) : null}
        </div>
        <div className={`label-info ${valueClass}`}>{value}</div>
      </div>
    );
  };

  const renderTableView = () => {
    return (
      <>
        <div className="row">
          {renderASINsHTML(label, labelInfo, 'label-bold')}
          {renderASINsHTML(label1, labelInfo1)}
          {renderASINsHTML(labelProductName, labelInfoProductName)}
          {renderASINsHTML(label2, labelInfo2)}
          {label3 ? renderASINsHTML(label3, labelInfo3) : null}
          {label4 ? renderASINsHTML(label4, labelInfo4) : null}
          {label5 ? renderASINsHTML(label5, labelInfo5) : null}
          {label6 ? renderASINsHTML(label6, labelInfo6) : null}
          {label7
            ? renderASINsHTML(
                label7,
                labelInfo7,
                '',
                true,
                stockedTooltip?.recommended_replenishment_quantity,
              )
            : null}
          {label8
            ? renderASINsHTML(
                label8,
                labelInfo8,
                '',
                true,
                stockedTooltip?.recommended_ship_date,
              )
            : null}
          <div className="col-6 mb-3">
            <div className="label">{label9}</div>
            <div className="label-info">
              <span>
                <a
                  href={`https://www.amazon.com/dp/${labelInfo9}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Amazon
                </a>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <TableMobileViewWrapper className={[className]} onClick={onClick}>
      <WhiteCard className={showBorder ? 'p-0' : null}>
        {showBorder ? (
          <fieldset className="shape-without-border">
            {renderTableView()}
          </fieldset>
        ) : (
          renderTableView()
        )}
      </WhiteCard>
    </TableMobileViewWrapper>
  );
};

export default UnderStockOverStockASINMobileView;

const TableMobileViewWrapper = styled.div`
  .label-bold {
    font-weight: bold;
  }
  .sub-label-text {
    font-size: ${Theme.extraNormal};
    color: ${Theme.black};
  }
`;

UnderStockOverStockASINMobileView.defaultProps = {
  showBorder: false,
  dataIndex: '',
  className: '',
  label: '',
  labelInfo: '',
  label1: '',
  labelInfo1: '',
  label2: '',
  labelInfo2: '',
  label3: '',
  labelInfo3: '',
  label4: '',
  labelInfo4: '',
  label5: '',
  labelInfo5: '',
  label6: '',
  labelInfo6: '',
  label7: '',
  labelInfo7: '',
  label8: '',
  labelInfo8: '',
  label9: '',
  labelInfo9: '',
  labelProductName: '',
  labelInfoProductName: '',
  onClick: () => {},
};

UnderStockOverStockASINMobileView.propTypes = {
  showBorder: bool,
  dataIndex: string,
  className: string,
  label: string,
  labelInfo: string,
  label1: string,
  labelInfo1: string,
  label2: string,
  labelInfo2: string,
  label3: string,
  labelInfo3: string,
  label4: string,
  labelInfo4: string,
  label5: string,
  labelInfo5: string,
  label6: string,
  labelInfo6: string,
  label7: string,
  labelInfo7: string,
  label8: string,
  labelInfo8: string,
  label9: string,
  labelInfo9: string,
  labelProductName: string,
  labelInfoProductName: string,
  onClick: func,
};
