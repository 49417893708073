import { marketplacesCode } from '../constants';

export const getMaketplaceCode = (marketplaceValue) => {
  const result = marketplacesCode.filter(
    (marketplace) => marketplace?.value === marketplaceValue?.toLowerCase(),
  );
  return result?.[0]?.code;
};

export const getMaketplaceRegion = (marketplaceValue) => {
  const result = marketplacesCode.filter(
    (marketplace) => marketplace?.value === marketplaceValue?.toLowerCase(),
  );
  return result?.[0]?.region;
};
