import styled from 'styled-components';
import Theme from '../../../../theme/Theme';

export const CreateReportSidePanel = styled.div`
  .red-cross-icon {
    position: absolute;
    right: 21px;
    top: 25px;
  }
  .graph-title {
    font-size: ${Theme.extraMedium};
    font-family: ${Theme.titleFontFamily};
    height: 24px;
    letter-spacing: 0px;
    color: ${Theme.black};

    &.textLimit11 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 125px;
    }
  }

  .delete-graph {
    position: absolute;
    top: 15px;
    right: 39px;
  }

  .days-container {
    max-width: 100%;
    width: 100%;
    &.spending {
      max-width: fit-content;
      .days-tab {
        li {
          label {
            padding: 7px 10px;
          }
        }
      }
    }

    .days-tab {
      #daysCheck:checked + label,
      #weeklyCheck:checked + label,
      #monthlyCheck:checked + label {
        color: ${Theme.orange};
        width: 100%;
        text-align: center;
      }

      li {
        width: 33.33%;
        text-align: center;
      }
    }
  }
  .metrics-tooltip {
    margin: -10px 0 0 -100px !important;
    @media only screen and (min-width: 1500px) {
      margin: -10px 0 0 -120px !important;
    }
  }
`;
export const MainBodyReports = styled.div`
  .graph-flied {
    background: #f9fafe;
    border-radius: 0px;
    min-height: 268px;
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-family: ${Theme.titleFontFamily};
    font-size: ${Theme.subTitle};
    margin-top: 48px;
  }
`;

export const ReportPDFContainer = styled.div`
  width: 100%;
  background: #eef3f5;
  padding: 50px 0;
  @media only screen and (min-width: 1024px) {
    width: calc(100% - 336px);
  }
  @media only screen and (min-width: 1500px) {
    width: calc(100% - 406px);
  }
`;

// report pdf style
export const ReportPDFHeader = styled.div`
  .report-pdf-header {
    .pdf-header-list {
      font-size: 14px;
      font-family: 'Noah-Medium';
      ul {
        margin: 0;
        padding: 0;
        li {
          padding-bottom: 5px;
          color: #2e384d;
        }
      }
    }
  }
`;

export const ReportPDFBody = styled.div`
  table {
    width: 99.5%;
  }
  .rdw-editor-toolbar.toolbarClassName {
    display: none;
  }
  .rdw-editor-main {
    background: #ffffff;
    border: none;
    padding: 8px 0;
    min-height: 0px;
    margin: 0px;
  }
`;

export default CreateReportSidePanel;
