export const revenuMetricsSubTypes = [
  {
    id: 1,
    label: 'Total',
    value: 'revenueTotal',
  },

  {
    id: 3,
    label: 'PPC',
    value: 'revenuePpc',
  },
  {
    id: 4,
    label: 'DSP',
    value: 'revenueDsp',
  },
];

export const adSpendMetricsSubTypesOptions = [
  {
    id: 1,
    label: 'Total',
    value: 'adSpendTotal',
  },

  {
    id: 3,
    label: 'PPC',
    value: 'adSpendPpc',
  },
  {
    id: 4,
    label: 'DSP',
    value: 'adSpendDsp',
  },
];

export const adSpendMetricsSubTypes = [
  {
    id: 1,
    label: 'Total',
    value: 'adSpendTotal',
    isShowTooltip: true,
    message: 'Sum of PPC and DSP Spend',
  },
  {
    id: 3,
    label: 'PPC',
    value: 'sponsoredAdSpend',
    isShowTooltip: false,
  },
  {
    id: 4,
    label: 'DSP',
    value: 'dspSpend',
    isShowTooltip: false,
  },
];

export const SummaryGraphColorSet = {
  revenueTotal: '#0045b4',
  revenueOrganic: '#74b035',
  revenuePpc: '#C84EC6',
  revenueDsp: '#E05D37',
  sponsoredAdSpend: '#89A43C',
  dspSpend: '#A04848',
  unitsSold: '#8C54FF',
  traffic: '#30A8BD',
  conversion: '#D6A307',
  adSpendTotal: '#33beffd6',
  // ad spend
  adSpendPpc: '#a47b40d6',
  adSpendDsp: '#52d045d6',
  tacos: '#00be8e',
};

export const tooltipNames = {
  revenueTotal: 'revenue Total',
  revenueOrganic: 'Revenue Organic ',
  revenuePpc: 'Revenue PPC',
  revenueDsp: 'Revenue DSP',
  sponsoredAdSpend: 'Sponsored Ad Spend',
  dspSpend: 'DSP Spend',
  unitsSold: 'Units Sold',
  traffic: 'Traffic',
  conversion: 'Conversion',
  adSpendTotal: 'Ad Spend Total',
  // ad spend
  adSpendPpc: 'Ad Spend PPC',
  adSpendDsp: 'Ad Spend DSP',
  tacos: 'Tacos',
};

export const metricsResponseKey = {
  revenue: 'revenue',
  revenueTotal: 'revenue',
  revenueOrganic: 'revenue_organic',
  revenuePpc: 'revenue_ppc',
  revenueDsp: 'revenue_dsp',
  sponsoredAdSpend: 'sponsored_ad_spend',
  dspSpend: 'dsp_spends',
  unitsSold: 'units_sold',
  traffic: 'traffic',
  conversion: 'conversion',
  adSpendTotal: 'ad_spend_total',
  adSpend: 'sponsored_ad_spend',
  // ad spend
  adSpendPpc: 'sponsored_ad_spend',
  adSpendDsp: 'dsp_spends',
  tacos: 'tacos',
};

export const metricsClasses = {
  revenueTotal: 'ad-sales-active',
  revenueOrganic: 'revenue-organic-active',
  revenuePpc: 'ad-click-active',
  revenueDsp: 'ad-cos-active',
  revenue: 'ad-sales-active',
  sponsoredAdSpend: 'sponsoredAdSpend-active',
  dspSpend: 'ad-clickrate-active',
  traffic: 'ad-conversion-active',
  unitsSold: 'ad-spend-active',
  conversion: 'impression-active',
  adSpendTotal: 'ad-spend-total-active',
  // ad spend
  adSpendPpc: 'ad-spend-ppc-active',
  adSpendDsp: 'ad-spend-dsp-active',
  tacos: 'sales-tacos-active',
};

export const salesMetricsClasses = {
  revenueTotal: 'ad-sales-active',
  revenueOrganic: 'revenue-organic-active',
  revenuePpc: 'ad-click-active',
  revenueDsp: 'ad-cos-active',
  revenue: 'ad-sales-active',
  sponsoredAdSpend: 'sponsoredAdSpend-active',
  dspSpend: 'ad-clickrate-active',
  traffic: 'ad-conversion-active',
  unitsSold: 'ad-spend-active',
  conversion: 'impression-active',
  adSpendTotal: 'ad-spend-total-active',
  // ad spend
  adSpendPpc: 'ad-spend-ppc-active',
  adSpendDsp: 'ad-spend-dsp-active',
  tacos: 'sales-tacos-active',
};

export const metricsTootlNames = {
  revenue: 'revenue',
  revenueTotal: 'total sales',
  revenueOrganic: 'organic',
  revenuePpc: 'PPC',
  revenueDsp: 'DSP',
  sponsoredAdSpend: 'PPC',
  dspSpend: 'DSP',
  unitsSold: 'units sold',
  traffic: 'traffic',
  conversion: 'conversion',
  adSpendTotal: 'Ad Spend',
  adSpend: 'sponsored ad spend',
  // ad spend
  adSpendPpc: 'PPC Spend ',
  adSpendDsp: 'DSP Spend ',
  tacos: 'TACOS',
};

export const summaryRestockLimitHeader = [
  {
    key: 'storage_type',
    label: 'Storage Type',
    width: '20%',
  },
  {
    key: 'utilization',
    label: 'Utilization',
    width: '20%',
  },
  {
    key: 'max_shipment',
    label: 'Max shipment',
    width: '20%',
  },
  {
    key: 'open_shipment',
    label: 'Open shipment',
    width: '20%',
  },
  {
    key: 'on_hand',
    label: 'Onhand',
    width: '20%',
  },
];

export const summaryRestockLimitStorageType = [
  {
    key: 'standard',
    label: 'Standard Size',
  },
  {
    key: 'oversize',
    label: 'Oversize',
  },
  {
    key: 'apparel',
    label: 'Apparel',
  },
  {
    key: 'footwear',
    label: 'Footwear',
  },
  {
    key: 'extra_large',
    label: 'Extra Large',
  },

  {
    key: 'flammable',
    label: 'Flammable',
  },

  {
    key: 'aerosol',
    label: 'Aerosol',
  },
];

export const asinMetrics = [
  {
    key: 'overstocked',
    label: 'Overstocked ASINS',
    asinValue: 'overstocked',
    percentChnage: 'overstocked_percent_change',
  },
  {
    key: 'understocked',
    label: 'Understocked ASINS',
    asinValue: 'understocked',
    percentChnage: 'understocked_percent_change',
  },
  {
    key: 'standed',
    label: 'Stranded ASINS',
    asinValue: 'available',
    percentChnage: 'percent_change',
  },
];
