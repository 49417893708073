import React, { useState, useEffect, useCallback, useRef } from 'react';

import dayjs from 'dayjs';
import DatePicker from 'react-date-picker';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { func, string, shape } from 'prop-types';

import Theme from '../../../theme/Theme';
import AgreementDetailOuterContainer from './Agreement/AgreementDetailsStyled';
import { getCustomerDetails } from '../../../store/actions/customerState';
import {
  InvoiceIssueOptions,
  customerStatusNote,
} from '../../../constants/CustomerConstants';
import {
  getcontractForIncollections,
  saveNotes,
  updateCustomerDetails,
} from '../../../api';
import {
  RecurringIcon,
  RightArrowIcon,
  DisabledRecurring,
  DspOnlyIcon,
} from '../../../theme/images';
import {
  Button,
  ModalBox,
  FormField,
  PageLoader,
  ErrorMsg,
  ModalRadioCheck,
  CheckBox,
} from '../../../common';

export default function CustomerStatus({ type, setStatusModal, customer }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const mounted = useRef(false);
  const [isLoading, setIsLoading] = useState({ loader: false, type: 'button' });
  const [startDate, setStartDate] = useState(new Date());
  const [formData, setFormData] = useState({});
  const [selectedAgreement, setSelectedAgreement] = useState([]);
  const [selectedInvoiceIssueOptions, setSelectedInvoiceIssueOptions] =
    useState(InvoiceIssueOptions[0].value);
  const [contractLoader, setContractLoader] = useState(false);
  const [multipleActiveAgreement, setMultipleActiveAgreement] = useState([]);

  useEffect(() => {
    if (
      customer?.status?.value === 'pending cancellation' &&
      type === 'inactive'
    ) {
      setStartDate(customer?.end_date && new Date(customer.end_date));
    }
  }, [customer, type]);

  const getContractList = useCallback(() => {
    setContractLoader(true);
    getcontractForIncollections(id).then((res) => {
      if (res?.status === 200) {
        setMultipleActiveAgreement(res?.data.results);
        setContractLoader(false);
      } else {
        setContractLoader(false);
      }
    });
  }, [id]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current && type === 'in collections') getContractList();

    return () => {
      mounted.current = false;
    };
  }, [getContractList, type]);

  const generateText = () => {
    if (Object.keys(customerStatusNote).includes(type)) {
      return customerStatusNote?.[type];
    }
    return '';
  };

  const handleChange = (event, field) => {
    if (field === 'end_date') {
      setStartDate(event);
      setFormData({ ...formData, [field]: event });
    } else {
      setFormData({ ...formData, [field]: event.target.value });
    }
  };

  const saveStatus = () => {
    if (formData?.end_date) {
      formData.end_date = dayjs(formData.end_date).format('YYYY-MM-DD');
    }

    const checkStatus = () => {
      if (formData.end_date >= dayjs(new Date()).format('YYYY-MM-DD')) {
        return 'pending cancellation';
      }
      return 'inactive';
    };

    setIsLoading({ loader: true, type: 'button' });
    let dataFields = {
      status: type === 'inactive' ? checkStatus() : type,
      end_date: formData?.end_date,
    };
    if (type === 'in collections') {
      dataFields = {
        status: type,
        contracts: selectedAgreement,
        in_collections: selectedInvoiceIssueOptions === 'yes',
      };
    }

    updateCustomerDetails(customer.id, dataFields).then((response) => {
      if (response?.status === 200) {
        saveNotes({
          note: formData?.note,
          customer: customer.id,
          is_pinned: true,
        }).then(() => {
          toast.success('Status Updated!');
          dispatch(getCustomerDetails(customer.id));
          setStatusModal({ show: false, type });
          setIsLoading({ loader: false, type: 'button' });
        });
      }
    });
  };

  const renderExistigContracts = () => {
    return (
      <div>
        {multipleActiveAgreement.map((agreement) => {
          const contractType = agreement?.contract_type?.toLowerCase();
          // render only renewed and active contract
          return (
            <CheckBox key={agreement?.id}>
              <label
                className="check-container customer-pannel pl-0"
                htmlFor={agreement?.id}
              >
                <input
                  type="checkbox"
                  id={agreement?.id}
                  name={agreement?.id}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSelectedAgreement([
                        ...selectedAgreement,
                        event.target.id,
                      ]);
                    } else {
                      setSelectedAgreement(
                        selectedAgreement.filter(
                          (op) => op !== event.target.id,
                        ),
                      );
                    }
                  }}
                  checked={selectedAgreement.find((op) => op === agreement?.id)}
                />
                <span className="checkmark left-content checkmark-large mt-4" />
                <AgreementDetailOuterContainer>
                  <div className=" col-12 p-0">
                    <ul
                      className="in-collcetion "
                      style={{
                        border: selectedAgreement.find(
                          (op) => op === agreement?.id,
                        )
                          ? `1px solid ${Theme.orange}`
                          : '1px solid #e0e7ff',
                      }}
                    >
                      <li>
                        <p className="black-heading-title text-medium-font mt-0 mb-0">
                          <img
                            width="48px"
                            className="solid-icon"
                            src={
                              agreement?.contract_status?.value === 'pause' ||
                              agreement?.contract_status?.value === 'draft'
                                ? DisabledRecurring
                                : contractType?.includes('dsp')
                                ? DspOnlyIcon
                                : RecurringIcon
                            }
                            alt="logo"
                          />{' '}
                          {contractType?.includes('90')
                            ? 'Recurring (90 day notice) Service Agreement'
                            : contractType?.includes('dsp')
                            ? 'DSP Service Agreement'
                            : 'Recurring Service Agreement'}
                        </p>
                        <div style={{ display: 'flow-root' }}>
                          {agreement?.end_date
                            ? `Expires ${dayjs(agreement?.end_date).format(
                                'MMM DD, YYYY',
                              )}`
                            : ''}{' '}
                          {agreement?.primary_marketplace?.name ? (
                            <>
                              <span className="collection-dot" />
                              <span className="pl-4">
                                {agreement?.primary_marketplace?.name} (Primary)
                              </span>
                            </>
                          ) : null}
                        </div>
                      </li>
                    </ul>
                  </div>
                </AgreementDetailOuterContainer>
              </label>
            </CheckBox>
          );
        })}
      </div>
    );
  };

  const renderStatusIncollectionBody = () => {
    return (
      <>
        {/* add conditon- if have multiple agreement then show the below section.  */}
        {multipleActiveAgreement.length > 1 ? (
          <ErrorMsg className="text-medium-font pb-2 pt-2">
            This Brand Partner has multiple ACTIVE contracts, please select
            which is &quot;IN COLLECTIONS&quot;.
          </ErrorMsg>
        ) : null}
        {renderExistigContracts()}
        <div className=" col-12 pl-0 black-heading-title text-medium-font">
          <p>
            Do we continue to automatically issue invoices to the Brand Partner?
          </p>
        </div>
        <div className=" col-12 pl-0 ">
          <ul className="invoice-adj-radio pb-2">
            {InvoiceIssueOptions.map((item) => (
              <li key={item.value}>
                {' '}
                <ModalRadioCheck>
                  <label
                    className=" checkboxes radio-container customer-list"
                    htmlFor={item.value}
                  >
                    {item.label}
                    <input
                      type="radio"
                      name="radio1"
                      id={item.value}
                      checked={selectedInvoiceIssueOptions === item.value}
                      value={item?.value}
                      onChange={(event) =>
                        setSelectedInvoiceIssueOptions(event.target.value)
                      }
                      defaultChecked={selectedInvoiceIssueOptions}
                    />
                    <span className="checkmark checkmark-customer-list" />
                  </label>
                </ModalRadioCheck>
              </li>
            ))}
          </ul>
        </div>
        <ErrorMsg className="text-medium-font pt-2">
          For any refund, void, cancellation and manual billing requests, please
          reach out to the Finance team directly.
        </ErrorMsg>
      </>
    );
  };

  const confirmBtnDisabled = () => {
    if (formData?.note === undefined || formData?.note === '') {
      return 'disabled';
    }
    if (type === 'in collections') {
      if (
        selectedAgreement?.length &&
        formData?.note &&
        formData?.note !== ''
      ) {
        return '';
      }
      return 'disabled';
    }
    return '';
  };

  return (
    <ModalBox style={{ overflow: 'auto', maxHeight: '600px' }}>
      {contractLoader && type === 'in collections' ? (
        <PageLoader
          component="performance-graph"
          color={Theme.orange}
          type="detail"
          width={40}
          height={40}
        />
      ) : (
        <>
          <div className="modal-body">
            <h4>
              Account Status
              <img src={RightArrowIcon} alt="" className="arrow-right" />
              {type}
            </h4>
            <p>{generateText()}</p>

            {type === 'inactive' ? (
              <FormField className="mt-3">
                <label htmlFor="date">
                  Select Date
                  <br />
                  <DatePicker
                    minDate={new Date()}
                    className="form-control"
                    id="date"
                    value={
                      startDate ||
                      (customer?.end_date && new Date(customer.end_date))
                    }
                    onChange={(date) => handleChange(date, 'end_date')}
                    format="MM-dd-yyyy"
                    clearIcon={null}
                  />
                </label>
              </FormField>
            ) : (
              ''
            )}

            <FormField className="mt-3">
              <label htmlFor="note">
                {type === 'in collections' ? 'Add Note*' : 'Add Note'}
                <br />
                <textarea
                  className="form-control text-area"
                  rows="3"
                  placeholder="Add a note"
                  onChange={(date) => handleChange(date, 'note')}
                  defaultValue={
                    customer?.status?.value === type
                      ? customer?.note
                      : customer?.status?.value === 'pending cancellation' &&
                        type === 'inactive'
                      ? customer?.note
                      : null
                  }
                />
              </label>
            </FormField>
            {type === 'in collections' ? renderStatusIncollectionBody() : null}
          </div>

          <div className=" col-12 modal-footer">
            <Button
              className={`btn-primary mr-3 ${confirmBtnDisabled()}`}
              onClick={() => saveStatus()}
            >
              {isLoading.loader && isLoading.type === 'button' ? (
                <PageLoader color="#fff" type="button" />
              ) : (
                'Confirm'
              )}
            </Button>

            <Button
              type="button"
              className="btn-transparent w-25"
              onClick={() => setStatusModal(false)}
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </ModalBox>
  );
}

CustomerStatus.defaultProps = {
  type: '',
};

CustomerStatus.propTypes = {
  type: string,
  setStatusModal: func.isRequired,
  customer: shape({
    id: string,
    note: string,
    end_date: string,
    status: shape({
      value: string,
    }),
  }).isRequired,
};
