// React imports
import React from 'react';

// third party imports
import { arrayOf, bool, func, shape } from 'prop-types';

const RestockLimitsMobileView = ({
  restockLoader,
  restockData,
  displayCompanyNameLogoAndBGS,
  displayValueWithPercentChange,
  redirectingToCustomerDetailview,
}) => {
  // display metrics name, value with percent
  const displayMetricsNameAndValue = (label, value, percentValue) => {
    return (
      <div className="col-6 mb-3" key={label}>
        <div className="label">{label}</div>
        <div className="label-info label-bold">
          {displayValueWithPercentChange(value, percentValue)}
        </div>
      </div>
    );
  };

  // display inventory mobile view
  const displayInventoryMobileView = () => {
    return restockData?.map((item) => {
      return (
        <>
          <div className="straight-line horizontal-line mb-3" />
          <div
            role="presentation"
            onClick={() => {
              redirectingToCustomerDetailview(item?.customer_id);
            }}
            key={item?.customer_id}
          >
            {displayCompanyNameLogoAndBGS(item)}
            <div className="row" key={item?.customer_id}>
              {displayMetricsNameAndValue(
                'Utilization',
                item?.storage?.utilization,
                item?.storage?.utilization_change,
              )}
              {displayMetricsNameAndValue(
                'Max Shipment',
                item?.storage?.max_shipment,
                item?.storage?.max_shipment_change,
              )}
              {displayMetricsNameAndValue(
                'Open Shipment',
                item?.storage?.open_shipment,
                item?.storage?.open_shipment_change,
              )}
              {displayMetricsNameAndValue(
                'Onhand',
                item?.storage?.on_hand,
                item?.storage?.on_hand_change,
              )}
            </div>
          </div>
        </>
      );
    });
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div className={restockLoader ? 'mb-1 disabled' : 'mb-1'}>
      {displayInventoryMobileView()}
    </div>
  );
};

export default RestockLimitsMobileView;

RestockLimitsMobileView.defaultProps = {
  restockLoader: false,
  restockData: [],
  displayCompanyNameLogoAndBGS: () => {},
  displayValueWithPercentChange: () => {},
  redirectingToCustomerDetailview: () => {},
};

RestockLimitsMobileView.propTypes = {
  restockLoader: bool,
  restockData: arrayOf(shape({})),
  displayCompanyNameLogoAndBGS: func,
  displayValueWithPercentChange: func,
  redirectingToCustomerDetailview: func,
};
