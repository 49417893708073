import React from 'react';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import Theme from '../../../../theme/Theme';
import useFormatNumber from '../../../../hooks/useFormatNumber';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../theme/images';
import {
  AllocateBar,
  CommonPagination,
  NoData,
  PageLoader,
  Table,
  TableMobileView,
} from '../../../../common';

const InventoryManagementStrandedTable = ({
  strandedData,
  isDesktop,
  isLoader,
  count,
  pageNumber,
  noDataMessage,
  strandedOrderByFlag,
  strandedOrderByKey,
  selectedTimeFrame,
  handlePageChange,
  handleSortFilters,
}) => {
  const dashboardDynamicError = useSelector(
    (newState) => newState.userState?.showDynamicMsg,
  );

  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          <th width="12%" className="product-header">
            SKU
          </th>
          <th width="13%" className="product-header">
            ASIN
          </th>
          <th width="17%" className="product-header">
            Product Name
          </th>

          <th
            width="15%"
            className="product-header cursor"
            onClick={() => handleSortFilters('available')}
          >
            AVAILABLE
            <FontAwesomeIcon
              icon="far fa-chevron-down"
              style={{
                marginLeft: '4px',
                fontSize: '12px',
                cursor: 'pointer',
                transform:
                  strandedOrderByKey === 'available' && strandedOrderByFlag
                    ? 'rotate(180deg)'
                    : '',
                color:
                  strandedOrderByKey === 'available'
                    ? Theme.orange
                    : Theme.gray40,
              }}
            />
          </th>
          <th
            width="12%"
            className="product-header cursor"
            onClick={() => handleSortFilters('percent_change')}
          >
            {' '}
            CHANGE
            <FontAwesomeIcon
              icon="far fa-chevron-down"
              style={{
                marginLeft: '4px',
                fontSize: '12px',
                cursor: 'pointer',
                transform:
                  strandedOrderByKey === 'percent_change' && strandedOrderByFlag
                    ? 'rotate(180deg)'
                    : '',
                color:
                  strandedOrderByKey === 'percent_change'
                    ? Theme.orange
                    : Theme.gray40,
              }}
            />
          </th>
          <th
            width="15%"
            className="product-header cursor"
            onClick={() => handleSortFilters('stranded_event_date')}
          >
            {' '}
            DATE OF STRANDED EVENT
            <FontAwesomeIcon
              icon="far fa-chevron-down"
              style={{
                marginLeft: '4px',
                fontSize: '12px',
                cursor: 'pointer',
                transform:
                  strandedOrderByKey === 'stranded_event_date' &&
                  strandedOrderByFlag
                    ? 'rotate(180deg)'
                    : '',
                color:
                  strandedOrderByKey === 'stranded_event_date'
                    ? Theme.orange
                    : Theme.gray40,
              }}
            />
          </th>
          <th
            width="16%"
            className="product-header cursor"
            onClick={() => handleSortFilters('stranded_reason')}
          >
            {' '}
            STRANDED REASON
            <FontAwesomeIcon
              icon="far fa-chevron-down"
              style={{
                marginLeft: '4px',
                fontSize: '12px',
                cursor: 'pointer',
                transform:
                  strandedOrderByKey === 'stranded_reason' &&
                  strandedOrderByFlag
                    ? 'rotate(180deg)'
                    : '',
                color:
                  strandedOrderByKey === 'stranded_reason'
                    ? Theme.orange
                    : Theme.gray40,
              }}
            />
          </th>
        </tr>
      </thead>
    );
  };

  const renderTableData = (itemData, numberFormat) => {
    return (
      <tr key={itemData?.sku} className="cursor product-body">
        <td className="small-label-text">{itemData?.sku}</td>
        <td className="small-label-text">{itemData?.asin}</td>
        <td
          className="product-table-body light-font"
          data-tip={itemData?.product_name}
          data-for="productname"
        >
          <ReactTooltip
            class="initialTextTransform"
            id="productname"
            aria-haspopup="true"
            place="bottom"
            effect="solid"
          />
          <div className="marketplace textLimit11">
            {itemData?.product_name || 'N/A'}
          </div>
        </td>
        <td className="product-table-body light-font ">
          {' '}
          <div className="marketplace">
            {numberFormat(itemData?.available, '', '')}
          </div>
        </td>
        <td className="small-label-text">
          {selectedTimeFrame.value === 'custom' ||
          itemData?.percent_change === null ? (
            'N/A'
          ) : itemData?.percent_change === 0 ? (
            <div
              className=" text-extra-medium 
              increase-rate gray80"
            >
              {numberFormat(itemData?.percent_change, '', '%')}
            </div>
          ) : itemData?.percent_change > 0 ? (
            <div
              className=" text-extra-medium 
              increase-rate"
            >
              <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
              {numberFormat(itemData?.percent_change, '', '%')}
            </div>
          ) : (
            <div className=" text-extra-medium decrease-rate">
              <img className="red-arrow" src={ArrowDownIcon} alt="arrow-up" />
              {numberFormat(Math.abs(itemData?.percent_change), '', '%')}
            </div>
          )}
        </td>
        <td className="product-table-body light-font ">
          <div className="marketplace">
            {' '}
            {itemData?.stranded_event_date !== null
              ? dayjs(itemData?.stranded_event_date).format('MM/DD/YY')
              : 'N/A'}
          </div>
        </td>
        <td className="product-table-body light-font ">
          <div className="marketplace">
            {itemData?.stranded_reason || 'N/A'}
          </div>
        </td>
      </tr>
    );
  };

  const renderMobileView = (numberFormat) => {
    return strandedData.map((itemData) => (
      <TableMobileView
        showBorder
        className="mb-3"
        label="SKU"
        labelInfo={itemData?.sku}
        label1="ASIN"
        labelInfo1={itemData?.asin}
        label2="Available"
        labelInfo2={numberFormat(itemData?.available)}
        label3="Date of Stranded Event"
        labelInfo3={
          itemData?.stranded_event_date !== null
            ? dayjs(itemData?.stranded_event_date).format('MM/DD/YY')
            : 'N/A'
        }
        label4="Stranded Reason"
        labelInfo4={itemData?.stranded_reason || 'N/A'}
        label5="Change"
        labelInfo5={
          selectedTimeFrame.value === 'custom' ||
          itemData?.percent_change === null
            ? 'N/A'
            : itemData?.percent_change > 0
            ? numberFormat(itemData?.percent_change, '', '%')
            : numberFormat(Math.abs(itemData?.percent_change), '', '%')
        }
        isInventory
        isLabelInfo5Positive={itemData?.percent_change > 0}
      />
    ));
  };

  return (
    <>
      {' '}
      {isDesktop ? (
        <div className="mt-4">
          <div className="row">
            <div className="col-12">
              <div className="black-heading-title mb-3">Stranded Inventory</div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              {dashboardDynamicError?.inventory?.stranded_inventory ? (
                <AllocateBar className="mb-4">
                  <div className="row">
                    <div className="col-12">
                      {' '}
                      <div className="remaing-label">
                        <span className="orange-dot" />
                        {dashboardDynamicError?.inventory?.stranded_inventory}
                      </div>{' '}
                    </div>
                  </div>
                </AllocateBar>
              ) : null}
            </div>
          </div>

          <div className=" straight-line horizontal-line pb-2 " />
          <Wrapper>
            <Table className="mt-0 ">
              {renderTableHeader()}
              {strandedData.length >= 1 ? (
                <>
                  <tbody>
                    {strandedData &&
                      strandedData.map((item) =>
                        renderTableData(item, useFormatNumber),
                      )}
                  </tbody>
                </>
              ) : null}
            </Table>
            {isLoader ? (
              <PageLoader
                component="performance-graph"
                color="#FF5933"
                type="detail"
                width={40}
                height={40}
              />
            ) : strandedData.length === 0 ? (
              <NoData>{noDataMessage}</NoData>
            ) : null}
          </Wrapper>

          {count > 10 ? (
            <>
              <div className="straight-line horizontal-line mt-3" />
              <CommonPagination
                count={count}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
              />
            </>
          ) : null}
        </div>
      ) : (
        <div className="mt-4">
          <div className="row">
            <div className="col-12">
              <div className="black-heading-title mb-md-3 mb-1">
                Stranded Inventory
              </div>
            </div>
          </div>
          {dashboardDynamicError?.inventory?.stranded_inventory ? (
            <AllocateBar className="mb-4">
              <div className="row">
                <div className="col-12">
                  {' '}
                  <div className="remaing-label">
                    <span className="orange-dot" />
                    {dashboardDynamicError?.inventory?.stranded_inventory}
                  </div>{' '}
                </div>
              </div>
            </AllocateBar>
          ) : null}
          {isLoader ? (
            <PageLoader
              component="performance-graph"
              color="#FF5933"
              type="detail"
              width={40}
              height={40}
            />
          ) : strandedData.length >= 1 ? (
            <div className="mt-3 ">{renderMobileView(useFormatNumber)}</div>
          ) : (
            <NoData>No Stranded Inventory Data Found</NoData>
          )}{' '}
          <CommonPagination
            count={count}
            pageNumber={pageNumber}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default InventoryManagementStrandedTable;

InventoryManagementStrandedTable.defaultProps = {
  isDesktop: false,
  isLoader: false,
  strandedOrderByFlag: false,
  count: 1,
  pageNumber: 1,
  strandedOrderByKey: '',
  noDataMessage: 'No Data Found.',
  selectedTimeFrame: {},
  handlePageChange: () => {},
  handleSortFilters: () => {},
};

InventoryManagementStrandedTable.propTypes = {
  isDesktop: bool,
  isLoader: bool,
  strandedOrderByFlag: bool,
  count: number,
  pageNumber: number,
  strandedOrderByKey: string,
  noDataMessage: string,
  selectedTimeFrame: shape({}),
  strandedData: arrayOf(shape({})).isRequired,
  handlePageChange: func,
  handleSortFilters: func,
};
const Wrapper = styled.div`
  td {
    padding: 20px 10px 3px 0px !important;
  }
  tr {
    th {
      .caretUpDown {
        vertical-align: middle;
        position: absolute;
        margin-left: 2px;
        margin-top: -2px;
      }
    }
  }
`;
