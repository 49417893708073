import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import {
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DesktopTable, WhiteCard } from '../../../../common';
import {
  playbookTableHeader,
  playbookTableHeaderBPView,
} from '../../../../constants';
import PlaybookSummary from './PlaybookSummary';
import PlaybookServicesCollapse from './PlaybookDataTable/PlaybookServicesCollapse';
import { PlaybookMobileView, PlaybookSummaryLink } from './PlaybookStyle';

function ServicesPlaybook({
  isCustomer,
  customerId,
  playbookId,
  playbookTab,
  playbookAPICall,
  playbookTaskData,
  isUserAssignedToBP,
  selectedMarketplaces,
  selectedService,
  selectedTeamMembers,
  customStartDate,
  customEndDate,
  accountType,
  onCreatePlaybookClick,
  createPlaybookBtnLoader,
  updatedServicesOfPlaybookSummary,
  setUpdatedServicesOfPlaybookSummary,
  showSummaryPage,
  setShowSummaryPage,
  marketplacesList,
  servicePlaybookErrorMsg,
}) {
  const isDesktop = useMediaQuery({ minWidth: 767 });
  const userInfo = useSelector((state) => state.userState.userInfo);
  const playbookCreateAccess =
    userInfo?.role?.includes('BGS') ||
    userInfo?.role?.includes('BGS Admin') ||
    userInfo?.role?.includes('Ad Manager Admin');

  // render deliverable collapse
  const renderPlaybookServicesCollapse = (dataItem) => {
    return (
      <PlaybookServicesCollapse
        ID={playbookId}
        serviceData={dataItem}
        isCustomer={isCustomer}
        customerId={customerId}
        playbookTab={playbookTab}
        playbookAPICall={playbookAPICall}
        isUserAssignedToBP={isUserAssignedToBP}
      />
    );
  };

  // render playbook services table for desktop view
  const renderServicesPlaybookDesktopView = () => {
    return (
      <div>
        {playbookCreateAccess ? (
          <div role="presentation">
            <PlaybookSummaryLink
              className="ml-3 mb-4 mt-1 "
              onClick={() => {
                setShowSummaryPage(!showSummaryPage);
              }}>
              {showSummaryPage ? (
                <>
                  <FontAwesomeIcon
                    className="chevron-icon mr-1"
                    icon="fa-light fa-chevron-left "
                  />
                  Back to Playbook
                </>
              ) : (
                <>
                  Playbook Summary
                  <FontAwesomeIcon
                    className="chevron-icon"
                    icon="fa-light fa-chevron-right"
                  />
                </>
              )}
            </PlaybookSummaryLink>
          </div>
        ) : null}

        {showSummaryPage ? (
          <PlaybookSummary
            customerId={customerId}
            selectedMarketplaces={selectedMarketplaces}
            selectedService={selectedService}
            selectedTeamMembers={selectedTeamMembers}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            accountType={accountType}
            onCreatePlaybookClick={onCreatePlaybookClick}
            createPlaybookBtnLoader={createPlaybookBtnLoader}
            updatedServicesOfPlaybookSummary={updatedServicesOfPlaybookSummary}
            setUpdatedServicesOfPlaybookSummary={
              setUpdatedServicesOfPlaybookSummary
            }
            marketplacesList={marketplacesList}
            playbookCreateAccess={playbookCreateAccess}
          />
        ) : playbookTaskData?.length ? (
          <DesktopTable className="services-playbook-table">
            <thead>
              <tr className="gray-border-radius">
                {isCustomer
                  ? playbookTableHeaderBPView?.map((item) => {
                      return (
                        <th key={item?.label} width={item?.width}>
                          {item?.label}
                        </th>
                      );
                    })
                  : playbookTableHeader?.map((item) => {
                      return (
                        <th key={item?.label} width={item?.width}>
                          {item?.label}
                        </th>
                      );
                    })}
              </tr>
            </thead>
            <tbody className="position-relative">
              {playbookTaskData?.map((item) => {
                return renderPlaybookServicesCollapse(item);
              })}
            </tbody>
          </DesktopTable>
        ) : (
          <WhiteCard className="pt-2">
            <p className="black-heading-title text-regular mt-2 mb-0 text-center">
              {servicePlaybookErrorMsg || 'No Playbook Created'}
            </p>
          </WhiteCard>
        )}
      </div>
    );
  };

  // render playbook services table for mobile view
  const renderServicesPlaybookMobileView = () => {
    return (
      <div>
        {playbookCreateAccess ? (
          <div role="presentation">
            <PlaybookSummaryLink
              className="ml-3 mb-4 mt-1 "
              onClick={() => {
                setShowSummaryPage(!showSummaryPage);
              }}>
              {showSummaryPage ? (
                <>
                  <FontAwesomeIcon
                    className="chevron-icon mr-1"
                    icon="fa-light fa-chevron-left "
                  />
                  Back to Playbook
                </>
              ) : (
                <>
                  Playbook Summary
                  <FontAwesomeIcon
                    className="chevron-icon"
                    icon="fa-light fa-chevron-right"
                  />
                </>
              )}
            </PlaybookSummaryLink>
          </div>
        ) : null}

        {showSummaryPage ? (
          <PlaybookSummary
            customerId={customerId}
            selectedMarketplaces={selectedMarketplaces}
            selectedService={selectedService}
            selectedTeamMembers={selectedTeamMembers}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            accountType={accountType}
            onCreatePlaybookClick={onCreatePlaybookClick}
            createPlaybookBtnLoader={createPlaybookBtnLoader}
            updatedServicesOfPlaybookSummary={updatedServicesOfPlaybookSummary}
            setUpdatedServicesOfPlaybookSummary={
              setUpdatedServicesOfPlaybookSummary
            }
            marketplacesList={marketplacesList}
            playbookCreateAccess={playbookCreateAccess}
          />
        ) : (
          <PlaybookMobileView>
            {playbookTaskData?.map((item) => {
              return (
                <WhiteCard
                  className="card-with-border mb-3"
                  key={item?.deliverableData?.deliverable_id}>
                  {renderPlaybookServicesCollapse(item)}
                </WhiteCard>
              );
            })}
          </PlaybookMobileView>
        )}
      </div>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      {isDesktop
        ? renderServicesPlaybookDesktopView()
        : renderServicesPlaybookMobileView()}
    </>
  );
}

export default ServicesPlaybook;

ServicesPlaybook.defaultProps = {
  playbookId: '',
  playbookTab: '',
  isUserAssignedToBP: false,
  playbookTaskData: [],
  playbookAPICall: () => {},
  selectedMarketplaces: [],
  selectedService: '',
  selectedTeamMembers: '',
  customStartDate: '',
  customEndDate: '',
  accountType: {},
  onCreatePlaybookClick: () => {},
  createPlaybookBtnLoader: false,
  updatedServicesOfPlaybookSummary: {},
  setUpdatedServicesOfPlaybookSummary: () => {},
  showSummaryPage: false,
  setShowSummaryPage: () => {},
  marketplacesList: [],
  servicePlaybookErrorMsg: '',
};

ServicesPlaybook.propTypes = {
  playbookTab: string,
  playbookId: string,
  customerId: string.isRequired,
  isUserAssignedToBP: bool,
  isCustomer: bool.isRequired,
  playbookAPICall: func,
  playbookTaskData: oneOfType([arrayOf(shape()), object]),
  selectedMarketplaces: arrayOf(shape()),
  selectedService: string,
  selectedTeamMembers: string,
  customStartDate: string,
  customEndDate: string,
  accountType: shape({}),
  onCreatePlaybookClick: func,
  createPlaybookBtnLoader: bool,
  updatedServicesOfPlaybookSummary: shape({}),
  setUpdatedServicesOfPlaybookSummary: func,
  showSummaryPage: bool,
  setShowSummaryPage: func,
  marketplacesList: arrayOf(shape({})),
  servicePlaybookErrorMsg: string,
};
