/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from 'react';

import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { components } from 'react-select';
import { useSelector } from 'react-redux';
import { arrayOf, bool, func, instanceOf, shape, string } from 'prop-types';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import AdPerformanceFilters from './AdPerformanceFilters';
import SponsoredPerformanceContainer from './SponsoredAdPerformance/SponsoredPerformanceContainer';
import Theme from '../../../../../theme/Theme';
import BindSponsoredAdGraphData from './BindSponsoredAdGraphData';
import BindSponsoredAdGoalsGraphData from './BindSponsoredAdGoalsGraphData';
import AdPerformanceNav from './AdPerformanceStyle';
import { getAdPerformance } from '../../../../../api';
import {
  CustomDateModal,
  DropdownIndicator,
  DropdownSingleValue,
} from '../../../../../common';
import {
  ADPERFORMANCE,
  AdTypesOptions,
  CUSTOM,
  SELLERSMALL,
  VENDORSMALL,
  dateOptions,
  sponoredMetricsList,
} from '../../../../../constants';

const _ = require('lodash');
const getSymbolFromCurrency = require('currency-symbol-map');

export default function AdPerformance({
  id,
  dspPpcFlag,
  isCallSPAsinApi,
  accountType,
  selectedAdDF,
  setSelectedAdDF,
  adState,
  setAdState,
  currentDate,
  selectedMarketplace,
  setSelectedMarketplace,
  marketplaceOptions,
  goalsMarketplaceOptions,
  currencySymbol,
  setCurrencySymbol,
  adMinDate,
  selectedAdType,
  setSelectedAdType,
  customDateError,
  subViewComponent,
  bindErrorMessage,
  minDateLoader,
  goalsMarketplaceIds,
  childBrandOptions,
  selectedChildBrand,
  setIsCallSPAsinApi,
  setSelectedChildBrand,
  displayAccountTypeFilter,
  renderDataAvailableChildList,
  getDataAvailabeleChildBrandList,
  createMarektplaceIdsListOfGoals,
}) {
  const isSponsoredDashboardDisabled = !dspPpcFlag?.isHavingPpc;
  const mounted = useRef(false);
  const customerState = useSelector((state) => state?.customerState?.data);

  const { Option, SingleValue } = components;

  const [sponsoredGoalsToggle, setSponsoredGoalsToggle] = useState(false);
  const [selectedAdBox, setSelectedAdBox] = useState({ adSales: true });

  const [sponsoredAdGoalChartData, setSponsoredAdGoalChartData] = useState([]);

  const [adGroupBy, setAdGroupBy] = useState('daily');

  const [adChartData, setAdChartData] = useState([]);
  const [adCurrentTotal, setAdCurrentTotal] = useState([]);
  const [adPreviousTotal, setAdPreviousTotal] = useState([]);
  const [adDifference, setAdDifference] = useState([]);
  const [adGraphLoader, setAdGraphLoader] = useState(false);
  const [sponsoredAdMultiSelected, setSponsoredAdMultiSelected] = useState(
    sponoredMetricsList.slice(0, 8),
  );
  const [sponsoredGoalsMetricData, setSponsoredGoalsMetricData] = useState([]);
  const [isCustomDateApply, setIsCustomDateApply] = useState(false);
  const [showAdCustomDateModal, setShowAdCustomDateModal] = useState(false);

  // need to reset the multi-selected drop-down and selected metrics,
  //  when we changed the account type filter to vendor- because metrics multi select functionality only for seller (PDV-9017)
  useEffect(() => {
    if (subViewComponent === VENDORSMALL) {
      setSponsoredAdMultiSelected(sponoredMetricsList.slice(0, 8));
      setSelectedAdBox({ adSales: true });
    }
  }, [subViewComponent, selectedAdType]);

  // need to reset the multi-selected drop-down and selected metrics,
  //  when we changed the sponsored type filter
  useEffect(() => {
    setSponsoredAdMultiSelected(sponoredMetricsList.slice(0, 8));
    setSelectedAdBox({ adSales: true });
  }, [selectedAdType]);

  // get sposored ad performnace data
  const getAdData = useCallback(
    (
      adType,
      selectedDailyFact,
      selectedGroupBy,
      marketplace,
      goalsMarketplaces,
      onGroupByClick = false,
      childBrand,
      startDate = null,
      endDate = null,
    ) => {
      setAdGraphLoader(true);
      getDataAvailabeleChildBrandList(
        id,
        'sponsored',
        accountType,
        marketplace,
        adType,
        selectedDailyFact,
        startDate,
        endDate,
      );
      getAdPerformance(
        id,
        adType,
        selectedDailyFact,
        selectedGroupBy,
        marketplace,
        goalsMarketplaces,
        childBrand,
        startDate,
        endDate,
        accountType,
      ).then((res) => {
        if (mounted.current) {
          if (res?.status === 200) {
            if (res?.data?.daily_facts) {
              const adGraphData = BindSponsoredAdGraphData(res?.data);
              setAdChartData(adGraphData);
              const dailyFacts = res?.data?.daily_facts;

              setAdCurrentTotal(dailyFacts?.current_sum || []);
              setAdPreviousTotal(dailyFacts?.previous_sum || []);
              setAdDifference(dailyFacts?.difference_data || []);

              if (!onGroupByClick) {
                setSponsoredGoalsMetricData(
                  res?.data?.daily_facts?.goal_metric,
                );
              }
              if (selectedDailyFact === CUSTOM) {
                const sponsoredAdGoalsGraphData = BindSponsoredAdGoalsGraphData(
                  res?.data,
                );
                setSponsoredAdGoalChartData(sponsoredAdGoalsGraphData);
              }
            } else {
              setAdChartData([]);
              setAdPreviousTotal([]);
              setAdCurrentTotal([]);
              setAdDifference([]);
            }
            setAdGraphLoader(false);
          } else {
            setAdChartData([]);
            setAdPreviousTotal([]);
            setAdCurrentTotal([]);
            setAdDifference([]);
            setAdGraphLoader(false);
          }
        }
      });
    },
    [getDataAvailabeleChildBrandList, id, accountType],
  );

  const ADYearAndCustomDateFilter = (
    startDate,
    endDate,
    dailyFactFlag,
    marketplace,
    adType,
  ) => {
    const temp = 'daily';
    let sd = startDate;
    let ed = endDate;
    setAdGroupBy(temp);

    sd = `${startDate.getDate()}-${
      startDate.getMonth() + 1
    }-${startDate.getFullYear()}`;
    ed = `${endDate.getDate()}-${
      endDate.getMonth() + 1
    }-${endDate.getFullYear()}`;

    getAdData(
      adType,
      dailyFactFlag,
      temp,
      marketplace,
      goalsMarketplaceIds,
      false,
      selectedChildBrand,
      sd,
      ed,
    );
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current && selectedMarketplace?.value) {
      if (selectedAdDF?.value === CUSTOM) {
        setIsCustomDateApply(true);

        ADYearAndCustomDateFilter(
          adState[0].startDate,
          adState[0].endDate,
          CUSTOM,
          selectedMarketplace?.value,
          selectedAdType.value,
        );
      } else {
        getAdData(
          selectedAdType?.value,
          selectedAdDF?.value,
          adGroupBy,
          selectedMarketplace?.value,
          goalsMarketplaceIds,
          false,
          selectedChildBrand,
        );
      }
    }

    return () => {
      mounted.current = false;
    };
  }, [selectedMarketplace, selectedChildBrand, goalsMarketplaceIds]);

  const applyCustomDate = () => {
    if (selectedAdDF.value !== CUSTOM)
      setSelectedAdDF({
        value: CUSTOM,
        label: 'Custom Range',
        sub: 'Select start & end date',
      });
    setIsCallSPAsinApi(true);
    setIsCustomDateApply(true);
    ADYearAndCustomDateFilter(
      adState[0].startDate,
      adState[0].endDate,
      CUSTOM,
      selectedMarketplace?.value,
      selectedAdType.value,
    );

    bindErrorMessage(ADPERFORMANCE, adMinDate);
    setShowAdCustomDateModal(false);
  };

  const renderCustomDateSubLabel = (props) => {
    if (selectedAdDF.value === CUSTOM && isCustomDateApply) {
      return `From- ${dayjs(adState[0].startDate).format(
        'MMM D, YYYY',
      )}  To- ${dayjs(adState[0].endDate).format('MMM D, YYYY')}`;
    }

    return props.data.sub;
  };

  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props.data.label}</span>

      <div style={{ fontSize: '10px' }}>{renderCustomDateSubLabel(props)}</div>
    </SingleValue>
  );

  const filterOption = (props) => {
    return (
      <Option {...props}>
        <div>
          <span style={{ fontSize: '14px' }}>
            {props.data.label}
            {props?.isDisabled === true ? (
              <>
                <FontAwesomeIcon
                  icon=" fal fa-info-circle"
                  color={Theme.orange}
                  fontSize="13px"
                  style={{ marginLeft: '20px' }}
                  data-tip="Selected metrics has no data for this period."
                  data-for="adSpendTotalInfo"
                />
                <ReactTooltip
                  class="initialTextTransform"
                  id="adSpendTotalInfo"
                  aria-haspopup="true"
                  place="right"
                  effect="solid"
                />
              </>
            ) : null}
          </span>

          <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
        </div>
      </Option>
    );
  };

  const adTypefilterOption = (props) => {
    return (
      <Option {...props}>
        <div style={{ cursor: props.isDisabled ? 'not-allowed' : 'pointer' }}>
          <span style={{ fontSize: '14px' }}>{props.data.label}</span>

          <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
        </div>
      </Option>
    );
  };

  const getSelectComponents = () => {
    return {
      Option: filterOption,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  const getAdTypesSelectComponents = () => {
    return {
      Option: adTypefilterOption,
      SingleValue: DropdownSingleValue,
      DropdownIndicator,
    };
  };

  const handleMarketplaceOptions = (event) => {
    if (event?.value !== selectedMarketplace?.value) {
      // PDV-8954
      // when Non-US markeplace selected then need to disabled the
      // television_campaign_report and all_ad _type option of sponsred ad type filter.
      // if television_campaign_report and all_ad _type is already selected then
      // first uncheck that option and then disabled that.

      // Improvements 27/11/2023 :: PDV-9024 - Show all ad types for non-US marketplaces. Removing the all_ad_type condition from below code.
      // ---------------------start---------------------
      if (
        selectedAdType?.value === 'television_campaign_report' &&
        event?.value !== 'Amazon.com'
      ) {
        setSelectedAdType(AdTypesOptions[0]);
      }
      // ---------------------end---------------------

      setSelectedMarketplace(event);
      createMarektplaceIdsListOfGoals(event, goalsMarketplaceOptions);
      setCurrencySymbol(getSymbolFromCurrency(event?.currency));
      setIsCallSPAsinApi(true);
    }
  };

  const handleAdGroupBy = (value) => {
    if (value !== adGroupBy) {
      setAdGroupBy(value);

      if (selectedAdDF.value === CUSTOM) {
        const { startDate } = adState[0];
        const { endDate } = adState[0];
        let sd = startDate;
        let ed = endDate;
        sd = `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()}`;
        ed = `${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`;

        getAdData(
          selectedAdType.value,
          selectedAdDF.value,
          value,
          selectedMarketplace?.value,
          goalsMarketplaceIds,
          true,
          selectedChildBrand,
          sd,
          ed,
        );
      } else {
        getAdData(
          selectedAdType.value,
          selectedAdDF.value,
          value,
          selectedMarketplace?.value,
          goalsMarketplaceIds,
          true,
          selectedChildBrand,
        );
      }
    }
  };

  const handleAdType = (event) => {
    const { value } = event;
    setSelectedAdType(event);

    if (selectedAdDF.value === CUSTOM) {
      ADYearAndCustomDateFilter(
        adState[0].startDate,
        adState[0].endDate,
        CUSTOM,
        selectedMarketplace?.value,
        value,
      );
    } else {
      getAdData(
        value,
        selectedAdDF.value,
        adGroupBy,
        selectedMarketplace?.value,
        goalsMarketplaceIds,
        false,
        selectedChildBrand,
      );
    }
  };

  const handleAdDailyFact = (event) => {
    const { value } = event;
    if (value !== CUSTOM) {
      setSponsoredGoalsToggle(false);
      setIsCustomDateApply(false);
      setSelectedAdDF(event);
      setAdState([
        {
          startDate: currentDate,
          endDate: currentDate,
          key: 'selection',
        },
      ]);

      bindErrorMessage(ADPERFORMANCE, adMinDate);
      setIsCallSPAsinApi(true);
    }

    if (value === CUSTOM) {
      setShowAdCustomDateModal(true);
    } else {
      setAdGroupBy('daily');

      getAdData(
        selectedAdType.value,
        value,
        'daily',
        selectedMarketplace?.value,
        goalsMarketplaceIds,
        false,
        selectedChildBrand,
      );
    }
  };

  const handledSponsoredMetricsOnMenuClose = () => {
    // set sponsored goals toggle to false to avoid conflicts between goals metrics and non goals metrics
    setSponsoredGoalsToggle(false);
    // return metrics which is selected in drop-down and in graph variable also.
    const localMetrics = sponsoredAdMultiSelected.filter((ele) =>
      Object.prototype.hasOwnProperty.call(selectedAdBox, ele?.value),
    );

    // we have acos and roas in same metrics (same for COS & SPC) &
    // sponsoredAdMultiSelected has only adcos and cos field
    // so, if user select the roas or SPC then its not ramain selected.
    // so,  need to write seperate logic for both metrics (roas, SPC)

    if (
      sponsoredAdMultiSelected.filter((ele) => ele?.value === 'adCos').length &&
      Object.prototype.hasOwnProperty.call(selectedAdBox, 'adRoas')
    ) {
      localMetrics.push({ value: 'adRoas', label: 'ROAS', isDisabled: false });
    }

    if (
      sponsoredAdMultiSelected.filter((ele) => ele?.value === 'costPerClick')
        .length &&
      Object.prototype.hasOwnProperty.call(selectedAdBox, 'salesPerClick')
    ) {
      localMetrics.push({
        value: 'salesPerClick',
        label: 'Sales Per Click',
        isDisabled: false,
      });
    }

    // if metrics is selected and its removed from metrics-dropdown then need to remove from selectedAdBox
    if (localMetrics?.length) {
      const localSelectedMetrics = {};
      localMetrics.map((ele) => {
        _.assign(localSelectedMetrics, { [ele?.value]: true });
        return null;
      });
      setSelectedAdBox(localSelectedMetrics);
    } else {
      setSelectedAdBox({ [sponsoredAdMultiSelected[0]?.value]: true });
    }
  };

  const returnMinDate = () => {
    return adMinDate === null ? new Date('08/01/2022') : new Date(adMinDate);
  };

  const daysDiffForSponsored = dayjs(currentDate).diff(
    dayjs(adMinDate),
    'days',
  );

  // render custom date modal
  const renderCustomDateModal = () => {
    return (
      <CustomDateModal
        id="BT-adperformance-daterange"
        isOpen={showAdCustomDateModal}
        ranges={adState}
        onClick={() => {
          setShowAdCustomDateModal(false);

          if (selectedAdDF.value !== CUSTOM) {
            setAdState([
              {
                startDate: currentDate,
                endDate: currentDate,
                key: 'selection',
              },
            ]);
          }
        }}
        onChange={(item) => {
          setAdState([item.selection]);
        }}
        onApply={() => {
          applyCustomDate('AD');
        }}
        currentDate={currentDate}
        minDate={returnMinDate()}
      />
    );
  };

  // display ad performance filter section
  const displayAdPerformanceFilters = () => {
    return (
      <AdPerformanceFilters
        dspPpcFlag={dspPpcFlag}
        marketplaceOptions={marketplaceOptions}
        handleMarketplaceOptions={handleMarketplaceOptions}
        isApiCall={adGraphLoader || minDateLoader}
        selectedMarketplace={selectedMarketplace}
        customerState={customerState}
        childBrandOptions={childBrandOptions}
        selectedChildBrand={selectedChildBrand}
        setSelectedChildBrand={setSelectedChildBrand}
        displayAccountTypeFilter={displayAccountTypeFilter}
      />
    );
  };

  // display sponsord performance section
  const displaySponsoredPerformance = () => {
    return (
      <div className={isSponsoredDashboardDisabled ? 'disabled' : ''}>
        <SponsoredPerformanceContainer
          isCallSPAsinApi={isCallSPAsinApi}
          customerId={id}
          childBrandId={selectedChildBrand?.value}
          currencySymbol={currencySymbol}
          sponsoredAdType={selectedAdType?.value}
          setSelectedAdBox={setSelectedAdBox}
          selectedAdBox={selectedAdBox}
          adCurrentTotal={adCurrentTotal}
          adPreviousTotal={adPreviousTotal}
          adDifference={adDifference}
          adGraphLoader={adGraphLoader || minDateLoader}
          adChartData={adChartData}
          selectedAdDF={selectedAdDF}
          adGroupBy={adGroupBy}
          handleAdGroupBy={handleAdGroupBy}
          handleAdType={handleAdType}
          selectedAdType={selectedAdType}
          getAdTypesSelectComponents={getAdTypesSelectComponents}
          customDateError={customDateError}
          subViewComponent={subViewComponent}
          dateOptions={dateOptions}
          handleAdDailyFact={handleAdDailyFact}
          isApiCall={adGraphLoader}
          getSelectComponents={getSelectComponents}
          daysDiff={daysDiffForSponsored}
          accountType={accountType}
          selectedMarketplace={selectedMarketplace}
          adState={adState}
          currentDateForSponsored={currentDate}
          sponsoredGoalsMetricData={sponsoredGoalsMetricData}
          sponsoredGoalsToggle={sponsoredGoalsToggle}
          setIsCallSPAsinApi={setIsCallSPAsinApi}
          setSponsoredGoalsToggle={setSponsoredGoalsToggle}
          sponsoredAdGoalChartData={sponsoredAdGoalChartData}
          renderDataAvailableChildList={renderDataAvailableChildList}
          sponsoredAdMultiSelected={sponsoredAdMultiSelected}
          setSponsoredAdMultiSelected={setSponsoredAdMultiSelected}
          handledSponsoredMetricsOnMenuClose={
            handledSponsoredMetricsOnMenuClose
          }
        />
      </div>
    );
  };

  // render ad performance modals
  const renderAdPerformanceModals = () => {
    return <>{renderCustomDateModal()}</>;
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      <AdPerformanceNav>
        {displayAdPerformanceFilters()}
        {renderAdPerformanceModals()}
        {/* sponsored ad performance panel */}
        {displaySponsoredPerformance()}
      </AdPerformanceNav>
    </>
  );
}
AdPerformance.defaultProps = {
  marketplaceChoices: {},
  id: '',
  minDateLoader: false,
  isCallSPAsinApi: false,
  subViewComponent: '',
  accountType: SELLERSMALL,
  data: {},
  dspPpcFlag: {},
  selectedAdDF: {},
  setSelectedAdDF: () => {},
  adState: [],
  setAdState: () => {},
  currentDate: new Date(),
  selectedMarketplace: {},
  customDateError: {},
  setSelectedMarketplace: () => {},
  marketplaceOptions: [],
  goalsMarketplaceOptions: [],
  childBrandOptions: [],
  selectedChildBrand: {},
  currencySymbol: '',
  setCurrencySymbol: () => {},
  selectedAdType: {},
  setSelectedAdType: () => {},
  adMinDate: '',
  bindErrorMessage: () => {},
  setSelectedChildBrand: () => {},
  isDisabled: false,
  goalsMarketplaceIds: [],
  marketplaceIds: [],
  setIsCallSPAsinApi: () => {},
  displayAccountTypeFilter: () => {},
  renderDataAvailableChildList: () => {},
  getDataAvailabeleChildBrandList: () => {},
  createMarektplaceIdsListOfGoals: () => {},
};

AdPerformance.propTypes = {
  marketplaceChoices: instanceOf(Object),
  id: string,
  minDateLoader: bool,
  isCallSPAsinApi: bool,
  subViewComponent: string,
  accountType: string,
  data: shape({
    label: string,
    sub: string,
  }),

  dspPpcFlag: shape({}),
  isDisabled: bool,
  selectedAdDF: shape({}),
  setSelectedAdDF: func,
  adState: arrayOf(shape()),
  setAdState: func,
  currentDate: instanceOf(Date),
  selectedChildBrand: shape({}),
  selectedMarketplace: shape({}),
  customDateError: shape({}),
  childBrandOptions: arrayOf(shape({})),
  setSelectedMarketplace: func,
  marketplaceOptions: arrayOf(shape({})),
  goalsMarketplaceOptions: arrayOf(shape({})),
  currencySymbol: string,
  setCurrencySymbol: func,
  selectedAdType: shape({}),
  setSelectedAdType: func,
  adMinDate: instanceOf(Date),
  bindErrorMessage: func,
  marketplaceIds: arrayOf(string),
  goalsMarketplaceIds: arrayOf(string),
  setSelectedChildBrand: func,
  setIsCallSPAsinApi: func,
  displayAccountTypeFilter: func,
  renderDataAvailableChildList: func,
  getDataAvailabeleChildBrandList: func,
  createMarektplaceIdsListOfGoals: func,
};
