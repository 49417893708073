export const timeFrameOptions = [
  { value: 'week', label: 'Current 7 days' },
  { value: '30days', label: 'Current 30 Days' },
  { value: 'month', label: 'Month to Date' },
  { value: 'year', label: 'Year to Date' },
  {
    value: 'custom',
    label: 'Custom Range',
    sub: 'Select start and end dates',
  },
];

export const standardReportMessages = {
  finance: 'Only authorized users can access this report.',
  finance_custom:
    'Only authorized users can download this report. Select the entire month range to download the past monthly report. Past monthly reports are available from April 2023 onwards.',
  account_audit: 'This report is available for Seller accounts only.',
  'Weekly Return and Review Report': 'This report gets updated every Tuesday.',
  sp_advertising:
    'Select the entire month date range to download <br> past monthly report within recent 4 months',
  reconciliation:
    'This report is available every 12th of the month for previous month’s data. Select the entire month date range to download past monthly report.',
  'Monthly Partner Opportunities Report':
    'Select the entire month date range to download updated reports based on the month selected.',
  'Sponsored Television Report':
    'This report is available from September 2023 onwards.',
};

export const cashflowStandardReportsKeys = [
  'retainer_cashflow',
  'revshare_cashflow',
  'dsp_cashflow',
  'finance',
];

export const tooltipMsg = `We are not currently pulling data <br> for this report based on the selected filters`;

export const bpAccountType = {
  Hybrid: [
    { label: 'Seller', value: 'Seller' },
    { label: 'Vendor', value: 'Vendor' },
    { label: 'Hybrid', value: 'Hybrid' },
  ],
  Seller: [{ label: 'Seller', value: 'Seller' }],
  Vendor: [{ label: 'Vendor', value: 'Vendor' }],
};

export const commanFieldConstant = [
  {
    id: 'marketplace',
    label: 'marketplace filter',
    placeholder: 'Marketplace',
    value: 'selectedMarketplace',
  },
  {
    id: 'accountType',
    label: 'account type',
    placeholder: 'Account Type',
    value: 'accType',
  },
];

// customized report table head constants
export const customizedReportTableHeadConstants = [
  { label: 'BP Name', width: '15%' },
  { label: 'Report Title', width: '15%' },
  { label: 'Description', width: '15%' },
  { label: 'Creation Date', width: '20%' },
  { label: 'Status', width: '14%' },
  { label: 'Action', width: '22%' },
];

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '600px ',
    width: '100% ',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const sellerDetails = {
  isSellerOpen: true,
  graphTitle: '',
  selectedMetrics: {},
  selectedMetricsData: {},
  graphFrequency: '',
  showPreviousDataPoint: false,
  showDataTable: false,
  graphDataTableView: 'detailedView',
  showMetricsGraph: true,

  metricMessage: '',
  salesRevenueTotal: false,
  salesRevenuePpc: false,
  salesRevenueDsp: false,
  salesTacos: false,
  salesUnitSold: false,
  salesTraffic: false,
  salesConversion: false,
  salesAdSpendTotal: false,
  salesAdSpendPpc: false,
  salesAdSpendDsp: false,

  adPerformance: 'sponsored',
  sponsoredType: { value: 'all', label: 'All Ad Types', key: '' },

  // if sponsored type is 'all'
  adSales: false,
  adSpend: false,
  adConversionRate: false,
  adConversionRateSp: false,
  adImpressions: false,
  adAcos: false,
  adRoas: false,
  adCostPerClick: false,
  adClicks: false,
  adClickThroughRate: false,

  // if sponsored type is 'Sponsored Products'
  adSalesSP: false,
  adSpendSP: false,
  adConversionRateSP: false,
  adConversionRateSpSP: false,
  adImpressionsSP: false,
  adAcosSP: false,
  adRoasSP: false,
  adCostPerClickSP: false,
  adClicksSP: false,
  adClickThroughRateSP: false,

  // if sponsored type is 'Sponsored Brands'
  adSalesSB: false,
  adSpendSB: false,
  adConversionRateSB: false,
  adConversionRateSpSB: false,
  adImpressionsSB: false,
  adAcosSB: false,
  adRoasSB: false,
  adCostPerClickSB: false,
  adClicksSB: false,
  adClickThroughRateSB: false,

  // if sponsored type is 'Sponsored Brands Video'
  adSalesSBV: false,
  adSpendSBV: false,
  adConversionRateSBV: false,
  adConversionRateSpSBV: false,
  adImpressionsSBV: false,
  adAcosSBV: false,
  adRoasSBV: false,
  adCostPerClickSBV: false,
  adClicksSBV: false,
  adClickThroughRateSBV: false,

  // if sponsored type is 'Sponsored Display'
  adSalesSD: false,
  adSpendSD: false,
  adConversionRateSD: false,
  adConversionRateSpSD: false,
  adImpressionsSD: false,
  adAcosSD: false,
  adRoasSD: false,
  adCostPerClickSD: false,
  adClicksSD: false,
  adClickThroughRateSD: false,

  dspImpressions: false,
  dspSpend: false,
  dspTotalProductSales: false,
  dspTotalRoas: false,
  dspTotalDpvr: false,
  dspTotalNewBrandPurchases: false,
  dspProductSales: false,
  dspRoas: false,

  isParentTopAsins: false,
  isParentBottomAsins: false,
  isParentAllAsins: false,
  parentTopAsins: 0,
  parentBottomAsins: 0,
  parentAsinMessage: '',

  isChildTopAsins: false,
  isChildBottomAsins: false,
  isChildAllAsins: false,
  childTopAsins: 0,
  childBottomAsins: 0,
  childAsinMessage: '',
  showChildProductName: false,

  buyBoxGraph: false,
  showBuyBoxGraphTable: false,
  bbpTableView: 'detailedView',
  showBuyBoxGraph: true,
  buyBoxGraphData: {},
  inventoryScore: false,
  positiveFeedback: false,
  orderIssue: false,
  sellerHeathMessge: '',

  strandedInventory: false,
  overstockInventory: false,
  understockInventory: false,
  inventoryManagementMessage: '',
  showInventoryProductName: false,

  footerMessage: '',
};

export const vendorDetails = {
  isVendorOpen: true,
  graphTitle: '',
  selectedMetrics: {},
  selectedMetricsData: {},
  graphFrequency: '',
  showPreviousDataPoint: false,
  showDataTable: false,
  graphDataTableView: 'detailedView',
  showMetricsGraph: true,

  saleType: 'shippedCogs',
  salesShippedCogs: false,
  salesShippedGlanceViews: false,
  salesShippedConversionRate: false,
  salesShippedUnits: false,
  metricMessage: '',

  salesOrderedRevenue: false,
  salesOrderedGlanceViews: false,
  salesOrderedConversionRate: false,
  salesOrderedUnits: false,

  adPerformance: 'sponsored',
  sponsoredType: { value: 'all', label: 'All Ad Types', key: '' },
  // if sponsored type is 'all'
  adSales: false,
  adSpend: false,
  adConversionRate: false,
  adConversionRateSp: false,
  adImpressions: false,
  adAcos: false,
  adRoas: false,
  adCostPerClick: false,
  adClicks: false,
  adClickThroughRate: false,

  // if sponsored type is 'Sponsored Products'
  adSalesSP: false,
  adSpendSP: false,
  adConversionRateSP: false,
  adConversionRateSpSP: false,
  adImpressionsSP: false,
  adAcosSP: false,
  adRoasSP: false,
  adCostPerClickSP: false,
  adClicksSP: false,
  adClickThroughRateSP: false,

  // if sponsored type is 'Sponsored Brands'
  adSalesSB: false,
  adSpendSB: false,
  adConversionRateSB: false,
  adConversionRateSpSB: false,
  adImpressionsSB: false,
  adAcosSB: false,
  adRoasSB: false,
  adCostPerClickSB: false,
  adClicksSB: false,
  adClickThroughRateSB: false,

  // if sponsored type is 'Sponsored Brands Video'
  adSalesSBV: false,
  adSpendSBV: false,
  adConversionRateSBV: false,
  adConversionRateSpSBV: false,
  adImpressionsSBV: false,
  adAcosSBV: false,
  adRoasSBV: false,
  adCostPerClickSBV: false,
  adClicksSBV: false,
  adClickThroughRateSBV: false,

  // if sponsored type is 'Sponsored Display'
  adSalesSD: false,
  adSpendSD: false,
  adConversionRateSD: false,
  adConversionRateSpSD: false,
  adImpressionsSD: false,
  adAcosSD: false,
  adRoasSD: false,
  adCostPerClickSD: false,
  adClicksSD: false,
  adClickThroughRateSD: false,

  dspImpressions: false,
  dspSpend: false,
  dspTotalProductSales: false,
  dspTotalRoas: false,
  dspTotalDpvr: false,
  dspTotalNewBrandPurchases: false,
  dspProductSales: false,
  dspRoas: false,

  strandedInventory: false,
  overstockInventory: false,
  understockInventory: false,
  inventoryManagementMessage: '',
  showInventoryProductName: false,

  footerMessage: '',
};

export const sellerDetailsEditor = {
  graphTitle: '',
  metricMessage: '',
  parentAsinMessage: '',
  childAsinMessage: '',
  sellerHeathMessge: '',
  inventoryManagementMessage: '',
};

export const vendorDetailsEditor = {
  graphTitle: '',
  metricMessage: '',
  inventoryManagementMessage: '',
};

export const groupByFields = [
  {
    id: 'daysCheck',
    name: 'daily',
    label: 'Daily',
  },
  {
    id: 'weeklyCheck',
    name: 'weekly',
    label: 'Weekly',
  },
  {
    id: 'monthlyCheck',
    name: 'monthly',
    label: 'Monthly',
  },
];

export const vendorGroupByFields = [
  {
    id: 'vendorDaily',
    name: 'daily',
    label: 'Daily',
  },
  {
    id: 'vendorweekly',
    name: 'weekly',
    label: 'Weekly',
  },
  {
    id: 'vendormonthly',
    name: 'monthly',
    label: 'Monthly',
  },
];

export const sellerSalesMetricsFields = [
  {
    id: 'unitSold',
    fieldName: 'sellerDetails',
    keyName: 'salesUnitSold',
    label: 'Unit Sold',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
    isSubType: false,
  },
  {
    id: 'traffic',
    fieldName: 'sellerDetails',
    keyName: 'salesTraffic',
    label: 'Traffic',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
    isSubType: false,
  },
  {
    id: 'conversion',
    fieldName: 'sellerDetails',
    keyName: 'salesConversion',
    label: 'Conversion',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
    isSubType: false,
  },
  {
    id: 'tacos',
    fieldName: 'sellerDetails',
    keyName: 'salesTacos',
    label: 'TACOS',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
    isSubType: false,
    message: 'No TACOS available for this period.',
  },
];

export const sellerRevenueMetricsFields = [
  {
    id: 'salesRevenueTotal',
    fieldName: 'sellerDetails',
    keyName: 'salesRevenueTotal',
    label: 'Total Revenue',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
    isSubType: true,
  },
  {
    id: 'salesRevenuePpc',
    fieldName: 'sellerDetails',
    keyName: 'salesRevenuePpc',
    label: 'PPC Revenue',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
    isSubType: true,
    message: 'No PPC Revenue spend available for this period.',
  },
  {
    id: 'salesRevenueDsp',
    fieldName: 'sellerDetails',
    keyName: 'salesRevenueDsp',
    label: 'DSP Revenue',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
    isSubType: true,
    message: 'No DSP Revenue available for this period.',
  },
];

export const sellerAdspendMetricsFields = [
  {
    id: 'salesAdSpendTotal',
    fieldName: 'sellerDetails',
    keyName: 'salesAdSpendTotal',
    label: 'Total Spend',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
    isSubType: true,
  },
  {
    id: 'salesAdSpendPpc',
    fieldName: 'sellerDetails',
    keyName: 'salesAdSpendPpc',
    label: 'PPC Spend',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
    isSubType: true,
    message: 'No PPC spend available for this period.',
  },
  {
    id: 'salesAdSpendDsp',
    fieldName: 'sellerDetails',
    keyName: 'salesAdSpendDsp',
    label: 'DSP Spend',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
    isSubType: true,
    message: 'No DSP spend available for this period.',
  },
];

export const vendorSalesMetricsFields = {
  shippedCogs: [
    {
      id: 'shippedCogs',
      fieldName: 'vendorDetails',
      keyName: 'salesShippedCogs',
      label: 'Shipped Cogs',
      className: 'check-container checkboxes-std-services customer-pannel',
      spanClass: 'checkmark',
      isMetrics: true,
    },
    {
      id: 'shippedGlanceView',
      fieldName: 'vendorDetails',
      keyName: 'salesShippedGlanceViews',
      label: 'Glance View',
      className: 'check-container checkboxes-std-services customer-pannel',
      spanClass: 'checkmark',
      isMetrics: true,
    },
    {
      id: 'shippedConversionRate',
      fieldName: 'vendorDetails',
      keyName: 'salesShippedConversionRate',
      label: 'Conversion Rate',
      className: 'check-container checkboxes-std-services customer-pannel',
      spanClass: 'checkmark',
      isMetrics: true,
    },
    {
      id: 'shippedUnit',
      fieldName: 'vendorDetails',
      keyName: 'salesShippedUnits',
      label: 'Shipped Unit',
      className: 'check-container checkboxes-std-services customer-pannel',
      spanClass: 'checkmark',
      isMetrics: true,
    },
  ],
  orderedRevenue: [
    {
      id: 'vendorOrderedRevenue',
      fieldName: 'vendorDetails',
      keyName: 'salesOrderedRevenue',
      label: 'Ordered Revenue',
      className: 'check-container checkboxes-std-services customer-pannel',
      spanClass: 'checkmark',
      isMetrics: true,
    },
    {
      id: 'orderedGlanceView',
      fieldName: 'vendorDetails',
      keyName: 'salesOrderedGlanceViews',
      label: 'Glance View',
      className: 'check-container checkboxes-std-services customer-pannel',
      spanClass: 'checkmark',
      isMetrics: true,
    },
    {
      id: 'orderedConversionRate',
      fieldName: 'vendorDetails',
      keyName: 'salesOrderedConversionRate',
      label: 'Conversion Rate',
      className: 'check-container checkboxes-std-services customer-pannel',
      spanClass: 'checkmark',
      isMetrics: true,
    },
    {
      id: 'orderedUnit',
      fieldName: 'vendorDetails',
      keyName: 'salesOrderedUnits',
      label: 'Ordered Unit',
      className: 'check-container checkboxes-std-services customer-pannel',
      spanClass: 'checkmark',
      isMetrics: true,
    },
  ],
};

export const graphToggleOptions = [
  {
    id: 'previous',
    fieldName: 'sellerDetails',
    keyName: 'showPreviousDataPoint',
    label: 'Show Previous Data Points',
    className: 'label-name',
    spanClass: 'slider round',
    isMetrics: false,
  },
  {
    id: 'metricsGraph',
    fieldName: 'sellerDetails',
    keyName: 'showMetricsGraph',
    label: 'Show Graph',
    className: 'label-name',
    spanClass: 'slider round',
    isMetrics: false,
  },
  {
    id: 'dataTable',
    fieldName: 'sellerDetails',
    keyName: 'showDataTable',
    label: 'Show Data Table',
    className: 'label-name',
    spanClass: 'slider round',
    isMetrics: false,
  },
];

export const productNameToggleOption = [
  {
    id: 'childProductName',
    fieldName: 'sellerDetails',
    keyName: 'showChildProductName',
    label: 'Show Product Name',
    className: 'label-name',
    spanClass: 'slider round',
    isMetrics: false,
  },
];

export const inventoryProductNameToggleOption = [
  {
    id: 'inventoryProductName',
    keyName: 'showInventoryProductName',
    label: 'Show Product Name',
    className: 'label-name',
    spanClass: 'slider round',
    isMetrics: false,
  },
];

export const sellerSponsoredMetricsFields = [
  {
    id: 'adSales',
    fieldName: 'sellerDetails',
    keyName: 'adSales',
    label: 'Ad Sales',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'adSpend',
    fieldName: 'sellerDetails',
    keyName: 'adSpend',
    label: 'Ad Spend',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'adConversionRate',
    fieldName: 'sellerDetails',
    keyName: 'adConversionRate',
    label: 'Ad Conversion Rate',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'adImpressions',
    fieldName: 'sellerDetails',
    keyName: 'adImpressions',
    label: 'Impressions',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },

  {
    id: 'adAcos',
    fieldName: 'sellerDetails',
    keyName: 'adAcos',
    label: 'ACOS',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'adRoas',
    fieldName: 'sellerDetails',
    keyName: 'adRoas',
    label: 'ROAS',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'adCostPerClick',
    fieldName: 'sellerDetails',
    keyName: 'adCostPerClick',
    label: 'Cost Per Click',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'adClicks',
    fieldName: 'sellerDetails',
    keyName: 'adClicks',
    label: 'Clicks',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'adClickThroughRate',
    fieldName: 'sellerDetails',
    keyName: 'adClickThroughRate',
    label: 'Click Through Rate',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
];

export const sellerDspMetricsFields = [
  {
    id: 'dspImpressions',
    fieldName: 'sellerDetails',
    keyName: 'dspImpressions',
    label: 'Impressions',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'dspSpend',
    fieldName: 'sellerDetails',
    keyName: 'dspSpend',
    label: 'DSP Spend',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'dspTotalProductSales',
    fieldName: 'sellerDetails',
    keyName: 'dspTotalProductSales',
    label: 'Total Product Sales',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'dspTotalRoas',
    fieldName: 'sellerDetails',
    keyName: 'dspTotalRoas',
    label: 'Total ROAS',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },

  {
    id: 'dspTotalDpvr',
    fieldName: 'sellerDetails',
    keyName: 'dspTotalDpvr',
    label: 'Total DPVR',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'dspTotalNewBrandPurchases',
    fieldName: 'sellerDetails',
    keyName: 'dspTotalNewBrandPurchases',
    label: 'Total New Brand Purchases',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'dspProductSales',
    fieldName: 'sellerDetails',
    keyName: 'dspProductSales',
    label: 'Product Sales',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
  {
    id: 'dspRoas',
    fieldName: 'sellerDetails',
    keyName: 'dspRoas',
    label: 'ROAS',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: true,
  },
];

export const sellerHealthFields = [
  {
    id: 'inventoryScore',
    fieldName: 'sellerDetails',
    keyName: 'inventoryScore',
    label: 'Inventory Score(IPI)',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: false,
  },
  {
    id: 'positiveFeedback',
    fieldName: 'sellerDetails',
    keyName: 'positiveFeedback',
    label: 'Positive Feedback',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: false,
  },
  {
    id: 'orderIssue',
    fieldName: 'sellerDetails',
    keyName: 'orderIssue',
    label: 'Order Issue',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: false,
  },
  {
    id: 'buyBoxPercent',
    fieldName: 'sellerDetails',
    keyName: 'isBuyBoxGraph',
    label: 'Buy Box %',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: false,
  },
];

export const buyBoxPercentFieldOptions = [
  {
    id: 'showBBPGraph',
    fieldName: 'sellerDetails',
    keyName: 'buyBoxGraph',
    label: 'Show Graph',
    className: 'label-name',
    spanClass: 'slider round',
    isMetrics: false,
  },
  {
    id: 'showBBPTable',
    fieldName: 'sellerDetails',
    keyName: 'showBuyBoxGraphTable',
    label: 'Show Data Table',
    className: 'label-name',
    spanClass: 'slider round',
    isMetrics: false,
  },
];

export const inventoryManagementFields = [
  {
    id: 'strandedInventory',
    fieldName: 'sellerDetails',
    keyName: 'strandedInventory',
    label: 'Stranded Inventory',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: false,
  },
  {
    id: 'overstockInventory',
    fieldName: 'sellerDetails',
    keyName: 'overstockInventory',
    label: 'Overstock Inventory',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: false,
  },
  {
    id: 'understockInventory',
    fieldName: 'sellerDetails',
    keyName: 'understockInventory',
    label: 'Understock Inventory',
    className: 'check-container checkboxes-std-services customer-pannel',
    spanClass: 'checkmark',
    isMetrics: false,
  },
];

export const metricsTooltipArray = [
  'salesTacos',
  'salesRevenuePpc',
  'salesAdSpendPpc',
  'salesRevenueDsp',
  'salesAdSpendDsp',
];

export const reportOrderOptions = [
  { label: 'Most Recent to Oldest', value: '-created_at' },
  { label: 'Oldest to Most Recent', value: 'created_at' },
];

export const reportTabOptions = [
  { key: 'standard', label: 'Standard Report', class: '' },
  { key: 'customized', label: 'Customized Report', class: 'd-none' },
  { key: 'summary', label: 'Summary', class: 'd-none' },
];

export const saveReportOptions = [
  {
    id: 'keepExistingOption',
    isDefaultChecked: true,
    name: 'keepExisting',
    label: 'Create a new report ',
    className: 'checkboxes radio-container customer-list',
    value: 'keepExisting',
  },
  {
    id: 'replaceExistingOption',
    isDefaultChecked: false,
    name: 'keepExisting',
    label: 'Update the existing report ',
    className: 'checkboxes radio-container customer-list',
    value: 'replaceExisting',
  },
];

export const setTimeFrame = {
  week: {
    value: 'week',
    label: 'Current 7 days',
    sub: 'vs Previous 7 days',
    isDisabled: false,
  },
  month: {
    value: 'month',
    label: 'Current Month',
    sub: 'vs Previous month',
    isDisabled: false,
  },
  '30days': {
    value: '30days',
    label: 'Current 30 Days',
    sub: 'vs Previous 30 days',
    isDisabled: false,
  },
  year: {
    value: 'year',
    label: 'Year to Date',
    sub: 'vs Previous year',
    isDisabled: false,
  },
  custom: {
    value: 'custom',
    label: 'Custom Range',
    sub: 'Select start and end dates',
    isDisabled: false,
  },
};

export const sponoredDspKeys = {
  adSales: 'adSales',
  adSpend: 'adSpend',
  adConversionRate: 'adConversionRate',
  adImpressions: 'adImpressions',
  adAcos: 'adAcos',
  adRoas: 'adRoas',
  adCostPerClick: 'adCostPerClick',
  adClicks: 'adClicks',
  adClickThroughRate: 'adClickThroughRate',

  adSalesSP: 'adSalesSP',
  adSpendSP: 'adSpendSP',
  adConversionRateSP: 'adConversionRateSP',
  adImpressionsSP: 'adImpressionsSP',
  adAcosSP: 'adAcosSP',
  adRoasSP: 'adRoasSP',
  adCostPerClickSP: 'adCostPerClickSP',
  adClicksSP: 'adClicksSP',
  adClickThroughRateSP: 'adClickThroughRateSP',

  adSalesSB: 'adSalesSB',
  adSpendSB: 'adSpendSB',
  adConversionRateSB: 'adConversionRateSB',
  adImpressionsSB: 'adImpressionsSB',
  adAcosSB: 'adAcosSB',
  adRoasSB: 'adRoasSB',
  adCostPerClickSB: 'adCostPerClickSB',
  adClicksSB: 'adClicksSB',
  adClickThroughRateSB: 'adClickThroughRateSB',

  adSalesSBV: 'adSalesSBV',
  adSpendSBV: 'adSpendSBV',
  adConversionRateSBV: 'adConversionRateSBV',
  adImpressionsSBV: 'adImpressionsSBV',
  adAcosSBV: 'adAcosSBV',
  adRoasSBV: 'adRoasSBV',
  adCostPerClickSBV: 'adCostPerClickSBV',
  adClicksSBV: 'adClicksSBV',
  adClickThroughRateSBV: 'adClickThroughRateSBV',

  adSalesSD: 'adSalesSD',
  adSpendSD: 'adSpendSD',
  adConversionRateSD: 'adConversionRateSD',
  adImpressionsSD: 'adImpressionsSD',
  adAcosSD: 'adAcosSD',
  adRoasSD: 'adRoasSD',
  adCostPerClickSD: 'adCostPerClickSD',
  adClicksSD: 'adClicksSD',
  adClickThroughRateSD: 'adClickThroughRateSD',

  dspImpressions: 'dspImpressions',
  dspSpend: 'dspSpend',
  dspTotalProductSales: 'dspTotalProductSales',
  dspTotalRoas: 'dspTotalRoas',
  dspTotalDpvr: 'dspTotalDpvr',
  dspTotalNewBrandPurchases: 'dspTotalNewBrandPurchases',
  dspProductSales: 'dspProductSales',
  dspRoas: 'dspRoas',
};

export const inventoryKeys = {
  strandedInventory: 'strandedInventory',
  overstockInventory: 'overstockInventory',
  understockInventory: 'understockInventory',
};

export const initalStateBP = {
  value: null,
  label: 'BP Name',
};

export const initalStateTimeFrame = {
  value: 'week',
  label: 'Current 7 days',
};

export const initialStateReportType = {
  value: 'reportType',
  label: 'Report Type',
};

export const initialStateAccountType = {
  value: 'accountType',
  label: 'Account Type',
};

export const currencyAxis = [
  'salesRevenueTotal',
  'salesRevenuePpc',
  'salesRevenueDsp',
  'salesAdSpendTotal',
  'salesAdSpendPpc',
  'salesAdSpendDsp',
  'adSales',
  'adCostPerClick',
  'adRoas',
  'adSpend',

  'adSalesSP',
  'adCostPerClickSP',
  'adRoasSP',
  'adSpendSP',

  'adSalesSB',
  'adCostPerClickSB',
  'adRoasSB',
  'adSpendSB',

  'adSalesSBV',
  'adCostPerClickSBV',
  'adRoasSBV',
  'adSpendSBV',

  'adSalesSD',
  'adCostPerClickSD',
  'adRoasSD',
  'adSpendSD',

  'dspSpend',
  'dspTotalProductSales',
  'dspTotalRoas',
  'dspProductSales',
  'dspRoas',
  'salesOrderedRevenue',
  'salesShippedCogs',
];

export const percentageAxis = [
  'salesTacos',
  'salesConversion',
  'adConversionRate',
  'adAcos',
  'adClickThroughRate',

  'adConversionRateSP',
  'adAcosSP',
  'adClickThroughRateSP',

  'adConversionRateSB',
  'adAcosSB',
  'adClickThroughRateSB',

  'adConversionRateSBV',
  'adAcosSBV',
  'adClickThroughRateSBV',
  'adConversionRateSD',
  'adAcosSD',
  'adClickThroughRateSD',

  'dspTotalDpvr',
  'dspTotalNewBrandPurchases',
  'salesOrderedConversionRate',
  'salesShippedConversionRate',
];

export const numberAxis = [
  'salesUnitSold',
  'salesTraffic',
  'adImpressions',
  'adClicks',
  'adImpressionsSP',
  'adClicksSP',
  'adImpressionsSB',
  'adClicksSB',
  'adImpressionsSBV',
  'adClicksSBV',
  'adImpressionsSD',
  'adClicksSD',
  'dspImpressions',
  'salesOrderedGlanceViews',
  'salesOrderedUnits',
  'salesShippedGlanceViews',
  'salesShippedUnits',
];

export const customReportGraphColorSet = [
  '#0045b4',
  '#74b035',
  '#C84EC6',
  '#E05D37',
  '#8C54FF',
  '#30A8BD',
  '#D6A307',
  '#89A43C',
];

export const customReportGraphTooltipNames = {
  salesRevenueTotal: 'Total Revenue',
  salesRevenuePpc: 'PPC Revenue',
  salesRevenueDsp: 'DSP Revenue',
  salesTacos: 'TACOS',
  salesUnitSold: 'Units Sold',
  salesTraffic: 'Traffic',
  salesConversion: 'Conversion',
  salesAdSpendTotal: 'Ad Spend Total',
  salesAdSpendPpc: 'Ad Spend PPC',
  salesAdSpendDsp: 'Ad Spend DSP',
  salesShippedCogs: 'Shipped COGs',
  salesShippedGlanceViews: 'Shipped Glance Views',
  salesShippedConversionRate: 'Shipped Conversion Rate',
  salesShippedUnits: 'Shipped Units',
  salesOrderedRevenue: 'Ordered Revenue',
  salesOrderedGlanceViews: 'Ordered Glance Views',
  salesOrderedConversionRate: 'Ordered Conversion Rate',
  salesOrderedUnits: 'Ordered Units',

  adSales: 'Ad Sales',
  adSpend: 'Ad Spend',
  adConversionRate: 'Ad Conversion Rate',
  adImpressions: 'Ad Impressions',
  adAcos: 'Ad ACOS',
  adRoas: 'Ad ROAS',
  adCostPerClick: 'Ad Cost Per Click',
  adClicks: 'Ad Clicks',
  adClickThroughRate: 'Ad Click Through Rate',

  adSalesSP: 'SP Ad Sales',
  adSpendSP: 'SP Ad Spend',
  adConversionRateSP: 'SP Ad Conversion Rate',
  adImpressionsSP: 'SP Ad Impressions',
  adAcosSP: 'SP Ad ACOS',
  adRoasSP: 'SP Ad ROAS',
  adCostPerClickSP: 'SP Ad Cost Per Click',
  adClicksSP: 'SP Ad Clicks',
  adClickThroughRateSP: 'SP Ad Click Through Rate',

  adSalesSB: 'SBV Ad Sales',
  adSpendSB: 'SBV Ad Spend',
  adConversionRateSB: 'SBV Ad Conversion Rate',
  adImpressionsSB: 'SBV Ad Impressions',
  adAcosSB: 'SBV Ad ACOS',
  adRoasSB: 'SBV Ad ROAS',
  adCostPerClickSB: 'SBV Ad Cost Per Click',
  adClicksSB: 'SBV Ad Clicks',
  adClickThroughRateSB: 'SBV Ad Click Through Rate',

  adSalesSBV: 'SBV Ad Sales',
  adSpendSBV: 'SBV Ad Spend',
  adConversionRateSBV: 'SBV Ad Conversion Rate',
  adImpressionsSBV: 'SBV Ad Impressions',
  adAcosSBV: 'SBV Ad ACOS',
  adRoasSBV: 'SBV Ad ROAS',
  adCostPerClickSBV: 'SBV Ad Cost Per Click',
  adClicksSBV: 'SBV Ad Clicks',
  adClickThroughRateSBV: 'SBV Ad Click Through Rate',

  adSalesSD: 'SD Ad Sales',
  adSpendSD: 'SD Ad Spend',
  adConversionRateSD: 'SD Ad Conversion Rate',
  adImpressionsSD: 'SD Ad Impressions',
  adAcosSD: 'SD Ad ACOS',
  adRoasSD: 'SD Ad ROAS',
  adCostPerClickSD: 'SD Ad Cost Per Click',
  adClicksSD: 'SD Ad Clicks',
  adClickThroughRateSD: 'SD Ad Click Through Rate',

  dspImpressions: 'DSP Impressions',
  dspSpend: 'DSP Spend',
  dspTotalProductSales: 'Total Product Sales',
  dspTotalRoas: 'Total ROAS',
  dspTotalDpvr: 'Total DPVR',
  dspTotalNewBrandPurchases: 'Total New Brand Purchases',
  dspProductSales: 'Product Sales',
  dspRoas: 'DSP ROAS',
};

export const buttonDisabledMsg =
  'Only assigned team member to the BP is allowed to create a report.';

export const selleCollapseIds = {
  graphFrequency: 'collapseSellerGraph',
  showPreviousDataPoint: 'collapseSellerGraph',
  showDataTable: 'collapseSellerGraphTable',
  showMetricsGraph: 'collapseSellerGraphTable',
  salesRevenueTotal: 'collapseSellerGraph',
  salesRevenuePpc: 'collapseSellerGraph',
  salesRevenueDsp: 'collapseSellerGraph',
  salesTacos: 'collapseSellerGraph',
  salesAdSpendTotal: 'collapseSellerGraph',
  salesAdSpendPpc: 'collapseSellerGraph',
  salesAdSpendDsp: 'collapseSellerGraph',
  salesUnitSold: 'collapseSellerGraph',
  salesTraffic: 'collapseSellerGraph',
  salesConversion: 'collapseSellerGraph',
  adPerformance: 'collapseSellerGraph',
  sponsoredType: 'collapseSellerGraph',
  adSales: 'collapseSellerGraph',
  adSpend: 'collapseSellerGraph',
  adConversionRate: 'collapseSellerGraph',
  adImpressions: 'collapseSellerGraph',
  adAcos: 'collapseSellerGraph',
  adRoas: 'collapseSellerGraph',
  adCostPerClick: 'collapseSellerGraph',
  adClicks: 'collapseSellerGraph',
  adClickThroughRate: 'collapseSellerGraph',

  adSalesSP: 'collapseSellerGraph',
  adSpendSP: 'collapseSellerGraph',
  adConversionRateSP: 'collapseSellerGraph',
  adImpressionsSP: 'collapseSellerGraph',
  adAcosSP: 'collapseSellerGraph',
  adRoasSP: 'collapseSellerGraph',
  adCostPerClickSP: 'collapseSellerGraph',
  adClicksSP: 'collapseSellerGraph',
  adClickThroughRateSP: 'collapseSellerGraph',

  adSalesSB: 'collapseSellerGraph',
  adSpendSB: 'collapseSellerGraph',
  adConversionRateSB: 'collapseSellerGraph',
  adImpressionsSB: 'collapseSellerGraph',
  adAcosSB: 'collapseSellerGraph',
  adRoasSB: 'collapseSellerGraph',
  adCostPerClickSB: 'collapseSellerGraph',
  adClicksSB: 'collapseSellerGraph',
  adClickThroughRateSB: 'collapseSellerGraph',

  adSalesSBV: 'collapseSellerGraph',
  adSpendSBV: 'collapseSellerGraph',
  adConversionRateSBV: 'collapseSellerGraph',
  adImpressionsSBV: 'collapseSellerGraph',
  adAcosSBV: 'collapseSellerGraph',
  adRoasSBV: 'collapseSellerGraph',
  adCostPerClickSBV: 'collapseSellerGraph',
  adClicksSBV: 'collapseSellerGraph',
  adClickThroughRateSBV: 'collapseSellerGraph',

  adSalesSD: 'collapseSellerGraph',
  adSpendSD: 'collapseSellerGraph',
  adConversionRateSD: 'collapseSellerGraph',
  adImpressionsSD: 'collapseSellerGraph',
  adAcosSD: 'collapseSellerGraph',
  adRoasSD: 'collapseSellerGraph',
  adCostPerClickSD: 'collapseSellerGraph',
  adClicksSD: 'collapseSellerGraph',
  adClickThroughRateSD: 'collapseSellerGraph',

  dspImpressions: 'collapseSellerGraph',
  dspSpend: 'collapseSellerGraph',
  dspTotalProductSales: 'collapseSellerGraph',
  dspTotalRoas: 'collapseSellerGraph',
  dspTotalDpvr: 'collapseSellerGraph',
  dspTotalNewBrandPurchases: 'collapseSellerGraph',
  dspProductSales: 'collapseSellerGraph',
  dspRoas: 'collapseSellerGraph',

  parentTopAsins: 'collapseSellerParentTop',
  parentBottomAsins: 'collapseSellerParentBottom',
  isParentAllAsins: 'collapseSellerParentAll',

  childTopAsins: 'collapseSellerChildTop',
  childBottomAsins: 'collapseSellerChildBottom',
  isChildAllAsins: 'collapseSellerChildAll',
  showChildProductName: 'collapseSellerChildTop',

  buyBoxGraph: 'collapseSellerBBEPgraph',
  showBuyBoxGraphTable: 'collapseSellerBBEPTable',
  showBuyBoxGraph: 'collapseSellerBBEPTable',
  inventoryScore: 'collapseSellerHealth',
  positiveFeedback: 'collapseSellerHealth',
  orderIssue: 'collapseSellerHealth',

  strandedInventory: 'collapseSellerStranded',
  overstockInventory: 'collapseSellerOverstock',
  understockInventory: 'collapseSellerUnderstock',
  showInventoryProductName: 'collapseSellerStranded',
};

export const vendorCollapseIds = {
  graphFrequency: 'collapseVendorGraph',
  showPreviousDataPoint: 'collapseVendorGraph',
  showDataTable: 'collapseVendorGraphTable',
  showMetricsGraph: 'collapseVendorGraphTable',

  salesShippedCogs: 'collapseVendorGraph',
  salesShippedGlanceViews: 'collapseVendorGraph',
  salesShippedConversionRate: 'collapseVendorGraph',
  salesShippedUnits: 'collapseVendorGraph',
  salesOrderedRevenue: 'collapseVendorGraph',
  salesOrderedGlanceViews: 'collapseVendorGraph',
  salesOrderedConversionRate: 'collapseVendorGraph',
  salesOrderedUnits: 'collapseVendorGraph',

  adPerformance: 'collapseVendorGraph',
  sponsoredType: 'collapseVendorGraph',
  adSales: 'collapseVendorGraph',
  adSpend: 'collapseVendorGraph',
  adConversionRate: 'collapseVendorGraph',
  adImpressions: 'collapseVendorGraph',
  adAcos: 'collapseVendorGraph',
  adRoas: 'collapseVendorGraph',
  adCostPerClick: 'collapseVendorGraph',
  adClicks: 'collapseVendorGraph',
  adClickThroughRate: 'collapseVendorGraph',

  adSalesSP: 'collapseVendorGraph',
  adSpendSP: 'collapseVendorGraph',
  adConversionRateSP: 'collapseVendorGraph',
  adImpressionsSP: 'collapseVendorGraph',
  adAcosSP: 'collapseVendorGraph',
  adRoasSP: 'collapseVendorGraph',
  adCostPerClickSP: 'collapseVendorGraph',
  adClicksSP: 'collapseVendorGraph',
  adClickThroughRateSP: 'collapseVendorGraph',

  adSalesSB: 'collapseVendorGraph',
  adSpendSB: 'collapseVendorGraph',
  adConversionRateSB: 'collapseVendorGraph',
  adImpressionsSB: 'collapseVendorGraph',
  adAcosSB: 'collapseVendorGraph',
  adRoasSB: 'collapseVendorGraph',
  adCostPerClickSB: 'collapseVendorGraph',
  adClicksSB: 'collapseVendorGraph',
  adClickThroughRateSB: 'collapseVendorGraph',

  adSalesSBV: 'collapseVendorGraph',
  adSpendSBV: 'collapseVendorGraph',
  adConversionRateSBV: 'collapseVendorGraph',
  adImpressionsSBV: 'collapseVendorGraph',
  adAcosSBV: 'collapseVendorGraph',
  adRoasSBV: 'collapseVendorGraph',
  adCostPerClickSBV: 'collapseVendorGraph',
  adClicksSBV: 'collapseVendorGraph',
  adClickThroughRateSBV: 'collapseVendorGraph',

  adSalesSD: 'collapseVendorGraph',
  adSpendSD: 'collapseVendorGraph',
  adConversionRateSD: 'collapseVendorGraph',
  adImpressionsSD: 'collapseVendorGraph',
  adAcosSD: 'collapseVendorGraph',
  adRoasSD: 'collapseVendorGraph',
  adCostPerClickSD: 'collapseVendorGraph',
  adClicksSD: 'collapseVendorGraph',
  adClickThroughRateSD: 'collapseVendorGraph',

  dspImpressions: 'collapseVendorGraph',
  dspSpend: 'collapseVendorGraph',
  dspTotalProductSales: 'collapseVendorGraph',
  dspTotalRoas: 'collapseVendorGraph',
  dspTotalDpvr: 'collapseVendorGraph',
  dspTotalNewBrandPurchases: 'collapseVendorGraph',
  dspProductSales: 'collapseVendorGraph',
  dspRoas: 'collapseVendorGraph',

  strandedInventory: 'collapseVendorStranded',
  overstockInventory: 'collapseVendorOverstock',
  understockInventory: 'collapseVendorUnderstock',
};

export const parentAsinTableFields = [
  {
    name: 'Parent Asin',
    width: '16.6%',
  },
  {
    name: 'sales',
    width: '16.6%',
  },
  {
    name: 'traffic',
    width: '16.6%',
  },
  {
    name: 'units',
    width: '16.6%',
  },
  {
    name: 'conversion',
    width: '16.6%',
  },
  {
    name: 'Buy Box %',
    width: '16.6%',
  },
];

export const childAsinTableFields = [
  {
    name: 'Child Asin',
    width: '15%',
  },
  {
    name: 'sales',
    width: '17%',
  },
  {
    name: 'traffic',
    width: '17%',
  },
  {
    name: 'units',
    width: '17%',
  },
  {
    name: 'conversion',
    width: '17%',
  },
  {
    name: 'Buy Box %',
    width: '17%',
  },
];

export const strandedInventoryTableFields = [
  { name: 'SKU', width: '18%' },
  {
    name: 'ASIN',
    width: '16%',
  },
  {
    name: 'AVAILABLE',
    width: '16%',
  },
  {
    name: 'CHANGE',
    width: '16%',
  },
  {
    name: 'DATE OF STRANDED EVENT',
    width: '14%',
  },
  {
    name: 'STRANDED REASON',
    width: '18%',
  },
];

export const overstockInventoryTableFields = [
  { name: 'SKU', width: '22%' },
  {
    name: 'ASIN',
    width: '20%',
  },
  {
    name: 'Sales \n(last 30 days)',
    width: '20%',
  },
  {
    name: 'Units Sold\n(last 30 days)',
    width: '20%',
  },
  {
    name: 'Total days of Supply',
    width: '18%',
  },
];

export const understockInventoryTableFields = [
  { name: 'SKU', width: '16%' },
  {
    name: 'ASIN',
    width: '14%',
  },
  {
    name: 'Sales \n(last 30 days)',
    width: '14%',
  },
  {
    name: 'Units Sold\n(last 30 days)',
    width: '14%',
  },
  {
    name: 'Total days of Supply',
    width: '14%',
  },
  {
    name: 'Recommended Replenishment\nQuantity',
    width: '14%',
  },
  {
    name: 'Recommended\nShip Date',
    width: '14%',
  },
];

// if metrics is disabled set value to TRUE, otherwise FALSE
export const metricsDisabledOnAgreement = {
  // ppc only - before 1 aug 2022
  salesTacosBeforePpc: true,
  salesAdSpendTotalBeforePpc: true,
  salesAdSpendPpcBeforePpc: true,
  salesAdSpendDspBeforePpc: true,

  // dsp only - before 1 aug 2022
  salesTacosBeforeDsp: false,
  salesAdSpendTotalBeforeDsp: false,
  salesAdSpendPpcBeforeDsp: true,
  salesAdSpendDspBeforeDsp: false,

  // ppc and dsp - before 1 aug 2022
  salesTacosBeforePpcDsp: true,
  salesAdSpendTotalBeforePpcDsp: true,
  salesAdSpendPpcBeforePpcDsp: true,
  salesAdSpendDspBeforePpcDsp: false,

  // ppc only - after 1 aug 2022
  salesTacosAfterPpc: false,
  salesAdSpendTotalAfterPpc: false,
  salesAdSpendPpcAfterPpc: false,
  salesAdSpendDspAfterPpc: true,

  // dsp only - after 1 aug 2022
  salesTacosAfterDsp: false,
  salesAdSpendTotalAfterDsp: false,
  salesAdSpendPpcAfterDsp: true,
  salesAdSpendDspAfterDsp: false,

  // ppc and dsp - after 1 aug 2022
  salesTacosAfterPpcDsp: false,
  salesAdSpendTotalAfterPpcDsp: false,
  salesAdSpendPpcAfterPpcDsp: false,
  salesAdSpendDspAfterPpcDsp: false,
};

export const PRODUCT = 'product';
export const OBJECTIVE = 'objective';
export const TITLE = 'title';

export const productDefalutList = { label: 'Select Product', value: 'product' };
export const objectiveDefalutList = {
  label: 'Select Objective',
  value: 'objective',
};
export const titleDefalutList = { label: 'Select Title', value: 'title' };
