// react imports
import React from 'react';

// third party imports
import { arrayOf, bool, func, shape, string } from 'prop-types';

// local file imports
import ToggleButton from './ToggleButton';

function DailyWeeklyMonthlyToggle({
  // boolean
  loader,
  // string
  toggleId,
  toggleState,
  // array
  toggleOptions,
  // function
  toggleEventHandler,
}) {
  return (
    <ToggleButton>
      <div id={toggleId} className="days-container">
        <ul className={loader ? 'disabled-tab days-tab' : 'days-tab'}>
          {toggleOptions?.map((item) => {
            return (
              <li key={item?.id}>
                <input
                  className="d-none"
                  type="radio"
                  id={item?.id}
                  name="flexRadioDefault1"
                  value={item?.value}
                  checked={toggleState === item?.value}
                  onChange={() => {
                    toggleEventHandler(item?.value);
                  }}
                />
                <label htmlFor={item?.id}>{item?.label}</label>
              </li>
            );
          })}
        </ul>
      </div>
    </ToggleButton>
  );
}

/* ********** Main return statement of this component ********** */
export default DailyWeeklyMonthlyToggle;

DailyWeeklyMonthlyToggle.defaultProps = {
  // boolean
  loader: false,
  // string
  toggleId: '',
  toggleState: 'daily',
  // array
  toggleOptions: [],
  // function
  toggleEventHandler: () => {},
};

DailyWeeklyMonthlyToggle.propTypes = {
  // boolean
  loader: bool,
  // string
  toggleId: string,
  toggleState: string,
  // array
  toggleOptions: arrayOf(shape({})),
  // function
  toggleEventHandler: func,
};
