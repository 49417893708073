import React, { useCallback, useState } from 'react';

import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-toastify';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import {
  saveAmazonDspAccountdetails,
  saveAmazonSellerAccount,
  saveAmazonVendorAccount,
} from '../../../api';
import { ModalBox, PageLoader, Button, InputFormField } from '../../../common';

export default function EditAmazonAccountDetails({
  dataLoader,
  setShowModal,
  generateDropdown,
  generateAccountHTML,
  dspData,
  formData,
  showBtn,
  selectedMarketplace,
  amazonDetails,
  sellerVendorCall,
  setApiError,
  setDspApiError,
  amazonDspDetails,
  sellerAdvertiserIds,
  vendorAdvertiserIds,
  generateDspAccountHTML,
  setSellerAdvertiserIdsError,
  setVendorAdvertiserIdsError,
  initialGetCallOfUseEffect,
}) {
  const [isLoading, setIsLoading] = useState({ loader: false, type: 'button' });

  // bind advertiser ids error
  const bindAdvertiserIdsError = useCallback((response) => {
    const tempData = [];

    const advIdsError = response?.advertiser_ids ? response.advertiser_ids : [];
    advIdsError.forEach((item, index) => {
      tempData.push({
        error: item,
        index,
      });
    });
    return tempData;
  }, []);

  // handle states after api success
  const afterSuccessAPI = () => {
    toast.success('Amazon Account details saved!');
    setIsLoading({ loader: false, type: 'button' });
    setShowModal(false);
    setApiError([]);
    setDspApiError([]);
    setSellerAdvertiserIdsError([]);
    setVendorAdvertiserIdsError([]);
    sellerVendorCall(selectedMarketplace);
  };

  // save dsp account details
  const saveDspAccountDetails = (dsp) => {
    saveAmazonDspAccountdetails(dsp, amazonDspDetails?.id).then((response) => {
      if (response?.status === 201 || response?.status === 200) {
        afterSuccessAPI();
      } else if (response?.status === 400) {
        setDspApiError({ Dsp: response?.data });
      } else {
        toast.error(
          response?.detail ||
            response?.error?.[0] ||
            'Oops... Something went wrong!',
        );
      }
      setIsLoading({ loader: false, type: 'button' });
    });
  };

  // handle autoscroll events
  const handleAutoScrollEvents = () => {
    if (selectedMarketplace?.account_type === 'Hybrid') {
      const div = document.getElementById('scroll');
      $('#scroll').animate(
        {
          scrollTop: div.scrollHeight,
        },
        500,
      );
    }
  };

  // handle seller, vendor, dsp patch api calls
  const handleMultipleAPIRequest = (seller, vendor, dsp) => {
    // configuring the array of API promises.
    // For HYBRID account with DSP is TRUE, there will be 3 API Promises.
    // For HYBRID account with DSP is FALSE, there will be 2 API Promises.
    // For SELLER or VENDOR account with DSP is TRUE, there will be 2 API Promises.
    // For SELLER or VENDOR account with DSP is FALSE, there will be 1 API Promise.

    const accountType = selectedMarketplace?.account_type;
    const isSellerCalls = accountType === 'Seller';
    const isVendorCalls = accountType === 'Vendor';
    const isHybridCalls = accountType === 'Hybrid';
    const isDspCalls = selectedMarketplace?.dspvalue;
    const apiRequest = [];

    if (isSellerCalls && seller) {
      apiRequest.push(
        saveAmazonSellerAccount(seller, amazonDetails?.Seller?.id),
      );
    }
    if (isVendorCalls && vendor) {
      apiRequest.push(
        saveAmazonVendorAccount(vendor, amazonDetails?.Vendor?.id),
      );
    }
    if (isHybridCalls) {
      apiRequest.push(
        saveAmazonSellerAccount(seller, amazonDetails?.Seller?.id),
        saveAmazonVendorAccount(vendor, amazonDetails?.Vendor?.id),
      );
    }
    if (isDspCalls) {
      apiRequest.push(
        saveAmazonDspAccountdetails(dsp, amazonDspDetails?.Dsp?.id),
      );
    }

    if (isHybridCalls) {
      axios.all(apiRequest).then(
        axios.spread((...res) => {
          if (
            isDspCalls &&
            (res?.[0]?.status === 201 || res?.[0]?.status === 200) &&
            (res?.[1]?.status === 201 || res?.[1]?.status === 200) &&
            (res?.[2]?.status === 201 || res?.[2]?.status === 200)
          ) {
            afterSuccessAPI();
          } else if (
            !isDspCalls &&
            (res?.[0]?.status === 201 || res?.[0]?.status === 200) &&
            (res?.[1]?.status === 201 || res?.[1]?.status === 200)
          ) {
            afterSuccessAPI();
          } else if (
            res?.[0]?.status === 400 ||
            res?.[1]?.status === 400 ||
            res?.[2]?.status === 400
          ) {
            let sel = {};
            let ven = {};
            if (res?.[0]?.status === 400) {
              sel = res?.[0]?.data;
              const getError = bindAdvertiserIdsError(res?.[0]?.data);
              setSellerAdvertiserIdsError(getError);
            }
            if (res?.[1]?.status === 400) {
              ven = res?.[1]?.data;
              const getError = bindAdvertiserIdsError(res?.[1]?.data);
              setVendorAdvertiserIdsError(getError);
            }
            if (res?.[2]?.status === 400) {
              setDspApiError({ Dsp: res?.[2]?.data });
            }

            setApiError({ Seller: sel, Vendor: ven });

            handleAutoScrollEvents();
          } else {
            toast.error(
              res?.detail || res?.error?.[0] || 'Oops... Something went wrong!',
            );
          }
          setIsLoading({ loader: false, type: 'button' });
        }),
      );
    } else {
      axios.all(apiRequest).then(
        axios.spread((...res) => {
          if (
            isDspCalls &&
            (res?.[0]?.status === 201 || res?.[0]?.status === 200) &&
            (res?.[1]?.status === 201 || res?.[1]?.status === 200)
          ) {
            afterSuccessAPI();
          } else if (
            !isDspCalls &&
            (res?.[0]?.status === 201 || res?.[0]?.status === 200)
          ) {
            afterSuccessAPI();
          } else if (res?.[0]?.status === 400 || res?.[1]?.status === 400) {
            if (res?.[0]?.status === 400) {
              const getError = bindAdvertiserIdsError(res?.data);
              if (isSellerCalls) {
                setApiError({ Seller: res?.[0]?.data });
                setSellerAdvertiserIdsError(getError);
              }
              if (isVendorCalls) {
                setApiError({ Vendor: res?.[0]?.data });
                setVendorAdvertiserIdsError(getError);
              }
            }

            if (res?.[1]?.status === 400) {
              setDspApiError({ Dsp: res?.[1]?.data });
            }

            handleAutoScrollEvents();
          } else {
            toast.error(
              res?.detail || res?.error?.[0] || 'Oops... Something went wrong!',
            );
          }
          setIsLoading({ loader: false, type: 'button' });
        }),
      );
    }
  };

  const saveAccountDetails = () => {
    setIsLoading({ loader: true, type: 'button' });
    const tempSellerAdvId = [];
    const tempVendorAdvId = [];
    if (sellerAdvertiserIds?.length) {
      for (const item of sellerAdvertiserIds) {
        if (item?.type === 'advIds') tempSellerAdvId.push(item.advertiser_id);
      }
    }

    if (vendorAdvertiserIds?.length) {
      for (const item of vendorAdvertiserIds) {
        if (item?.type === 'advIds') {
          tempVendorAdvId.push(item.advertiser_id);
        }
      }
    }

    const seller = formData.Seller
      ? {
          ...formData.Seller,
          advertiser_ids: tempSellerAdvId?.length ? tempSellerAdvId : null,
          marketplace: selectedMarketplace?.value?.startsWith('MP')
            ? selectedMarketplace?.value
            : null,
        }
      : null;
    const vendor = formData.Vendor
      ? {
          ...formData.Vendor,
          advertiser_ids: tempVendorAdvId?.length ? tempVendorAdvId : null,
          marketplace: selectedMarketplace?.value?.startsWith('MP')
            ? selectedMarketplace?.value
            : null,
        }
      : null;

    const dsp = {
      ...dspData?.Dsp,
      marketplace: selectedMarketplace?.dspvalue,
    };

    if (selectedMarketplace?.account_type) {
      handleMultipleAPIRequest(seller, vendor, dsp);
    } else if (selectedMarketplace?.dspvalue) {
      saveDspAccountDetails(dsp);
    }
  };

  // checking space validation which is not allowed in advertiser ids
  function isEmptyOrSpaces(value) {
    return value === null || value.match(/^ *$/) !== null;
  }

  // input field validation function
  const validateAdvertiserIds = () => {
    let isValidate = false;
    let allEntries = [];
    const accountType = selectedMarketplace?.account_type;

    if (accountType === 'Hybrid' || accountType === 'Seller') {
      for (const item of sellerAdvertiserIds) {
        if (item?.type === 'advIds') {
          const isError = isEmptyOrSpaces(item.advertiser_id);

          if (isError) {
            allEntries = [...allEntries, ' '];
          } else {
            allEntries = [...allEntries, item.advertiser_id];
          }
        }
      }
    }

    if (accountType === 'Hybrid' || accountType === 'Vendor') {
      for (const item of vendorAdvertiserIds) {
        if (item?.type === 'advIds') {
          const isError = isEmptyOrSpaces(item.advertiser_id);

          if (isError) {
            allEntries = [...allEntries, ' '];
          } else {
            allEntries = [...allEntries, item.advertiser_id];
          }
        }
      }
    }

    if (
      allEntries.includes(undefined) ||
      allEntries.includes(null) ||
      allEntries.includes(' ') ||
      allEntries.includes('')
    ) {
      isValidate = true;
    } else {
      isValidate = false;
    }
    return isValidate;
  };

  return (
    <ModalBox>
      <div className={`modal-body ${dataLoader ? 'disabled' : ''}`} id="scroll">
        <div className="row" id="scroll">
          <div className="col-12 modal-heading p-0">
            <h4>Edit Amazon Account Names & IDs</h4>
            <div className="straight-line horizontal-line mt-3 " />
            <div className="body-content pb-0" id="scroll-account-detail">
              <InputFormField>
                {generateDropdown()}
                <div className="straight-line horizontal-line mt-4 mb-3" />
              </InputFormField>
              {generateAccountHTML('edit')}
              {selectedMarketplace?.dspvalue
                ? generateDspAccountHTML('edit')
                : null}
            </div>
          </div>
        </div>
      </div>
      {showBtn ? (
        <>
          <div className="footer-line " />
          <div className="modal-footer">
            <div className="row">
              <div className=" col-6  ">
                <Button
                  className="btn-primary w-100"
                  disabled={validateAdvertiserIds()}
                  onClick={() => saveAccountDetails()}
                >
                  {isLoading.loader && isLoading.type === 'button' ? (
                    <PageLoader color="#fff" type="button" />
                  ) : (
                    'Confirm'
                  )}
                </Button>
              </div>
              <div className=" col-6  ">
                <Button
                  className=" btn-borderless w-100"
                  onClick={() => {
                    setShowModal(false);
                    setApiError({});
                    initialGetCallOfUseEffect();
                  }}
                >
                  Discard Changes
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </ModalBox>
  );
}

EditAmazonAccountDetails.defaultProps = {
  dataLoader: false,
  showBtn: false,
  selectedMarketplace: null,
  dspData: {},
  amazonDspDetails: {},
  formData: [],
  sellerAdvertiserIds: [],
  vendorAdvertiserIds: [],
  // function
  setApiError: () => {},
  setShowModal: () => {},
  setDspApiError: () => {},
  generateDspAccountHTML: () => {},
  setSellerAdvertiserIdsError: () => {},
  setVendorAdvertiserIdsError: () => {},
  initialGetCallOfUseEffect: () => {},
};

EditAmazonAccountDetails.propTypes = {
  dataLoader: bool,
  showBtn: bool,
  selectedMarketplace: shape({
    account_type: string,
    value: string,
  }),
  dspData: shape({}),
  amazonDspDetails: shape({}),
  formData: shape({
    Seller: shape({}),
    Vendor: shape({}),
  }),
  amazonDetails: shape({
    Seller: shape({}),
    Vendor: shape({}),
  }).isRequired,
  sellerAdvertiserIds: arrayOf(shape()),
  vendorAdvertiserIds: arrayOf(shape()),
  // function
  setApiError: func,
  setShowModal: func,
  setDspApiError: func,
  generateDropdown: func.isRequired,
  sellerVendorCall: func.isRequired,
  generateAccountHTML: func.isRequired,
  generateDspAccountHTML: func,
  setSellerAdvertiserIdsError: func,
  setVendorAdvertiserIdsError: func,
  initialGetCallOfUseEffect: func,
};
