import React from 'react';

import { shape } from 'prop-types';

import { WhiteCard } from '../../../../../common';

const OrderIssuesCard = ({ dspData }) => {
  return (
    <div className="col-md-3 col-sm-12 mb-3">
      <WhiteCard className="fix-height">
        {' '}
        <p className="black-heading-title mt-0 mb-4">Order Issues</p>
        <div className="seller-health">
          {dspData?.order_defect_fba
            ? dspData.order_defect_fba === 'nan'
              ? 'N/A'
              : `${dspData.order_defect_fba}`
            : 'N/A'}
        </div>
        <div className="seller-update mb-3">Order Defect Rate</div>
        <div className="seller-health  ">
          {dspData?.policy_issues
            ? dspData.policy_issues === 'nan'
              ? 'N/A'
              : dspData.policy_issues
            : 'N/A'}
        </div>
        <div className="seller-update mb-5">Policy Violations</div>
        <div className="last-update ">Last updated: {dspData?.latest_date}</div>
      </WhiteCard>
    </div>
  );
};

export default OrderIssuesCard;

OrderIssuesCard.defaultProps = { dspData: null };

OrderIssuesCard.propTypes = {
  dspData: shape({}),
};
