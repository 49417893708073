import styled from 'styled-components';

import Theme from '../../../../theme/Theme';

export const COSNav = styled.div`
  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      width: 100%;
      max-width: 216px;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      &.action-btn {
        max-width: fit-content;
        width: fit-content;
      }
    }
  }
`;

export const GoalsNav = styled.div`
  .inline-content {
    margin-top: -6px;
    li {
      width: 160px;
      margin-right: 16px;
      color: ${Theme.black};
      font-size: ${Theme.extraNormal};
      vertical-align: top;
      &.action-btn {
        max-width: fit-content;
        width: fit-content;
        button {
          font-size: 14px;
          min-height: 40px;
          border-radius: 6px;
          padding: 5px 7px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    text-align: left;
    .inline-content {
      li {
        width: ${(props) => (props.show ? '46%' : '37%')};
        margin-right: 8px;

        &.action-btn {
          max-width: 100%;
          width: auto;
        }
      }
    }
  }

  @media only screen and (max-width: 370px) {
    .inline-content {
      li {
        width: ${(props) => (props.show ? '45%' : '34%')};

        .dropdown-goal-filter {
          min-width: auto;
        }
      }
    }
  }
`;

export const SyncButton = styled.div`
  height: 32px;
  width: 32px;
  border: 1px solid ${Theme.gray45};
  border-radius: 6px;
  color: ${Theme.gray90};
  text-align: center;
  padding: 7px;
  font-size: ${Theme.extraNormal};
`;
export const DesktopStickyTable = styled.table`
  position: relative;
  /* width: 100%; */
  border-spacing: 0;
  table {
    position: relative;
  }
  thead {
    tr {
      text-align: left;
      background: ${Theme.gray8};
      font-family: ${Theme.baseFontFamily};
      width: 100%;
      th {
        padding: 17px 0px 17px 10px;
        text-transform: uppercase;
        color: ${Theme.gray40};
        font-size: 11px;
        font-family: ${Theme.titleFontFamily};
        min-height: 48px;

        /* &:last-child {
          padding-right: 16px;
        } */
      }
      &.gray-border-radius {
        th {
          &:first-child {
            border-top-left-radius: 10px;
          }
          /* &:last-child {
            border-top-right-radius: 10px;
          } */
        }
      }
    }
    .showme {
      display: none;
    }
    tr:hover .showme {
      display: block;
    }
  }
  tbody {
    position: relative;

    tr {
      background: ${Theme.gray1};
      /* &:hover {
        background: ${Theme.gray8};
      } */

      td {
        color: ${Theme.black};
        padding: 22px 10px 22px 10px;
        font-size: ${Theme.extraNormal};
        border-bottom: 1px solid ${Theme.gray11};

        .creative-input {
          border: none;
          font-size: 14px;
          &:focus {
            outline: none !important;
            border-width: 0px !important;
            border: none !important;
          }
        }

        &.border-none {
          border-bottom: none;
        }

        &.BPLogo {
          .company-logo {
            border-radius: 10px;
            width: 45px;
            height: 45px;
            margin-right: 14px;
            float: left;
          }

          .company-name {
            min-height: 20px;
            text-transform: capitalize;
            word-break: break-all;
          }

          .status {
            color: ${Theme.gray85};
            font-size: ${Theme.extraNormal};
            font-weight: 300;
            word-break: break-all;
            text-transform: capitalize;
          }
        }

        &.goals-no-data {
          vertical-align: top;
        }

        span.collapse-icon {
          width: 16px;
          height: 16px;
          background: rgb(244, 246, 252);
          margin-right: 10px;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
        }
        .large-text {
          font-size: ${Theme.extraMedium};
          font-family: ${Theme.baseMediumFontFamily};
        }
        .small-text {
          font-size: ${Theme.extraSmall};
        }
        &.text-bold {
          font-family: ${Theme.titleFontFamily};
        }

        .text-extra-small {
          font-size: ${Theme.textExtraSmall};
        }
        &.word-break {
          word-break: break-all;
        }

        div.range {
          font-size: ${Theme.extraSmall};
          font-family: ${Theme.baseMediumFontFamily};
          &.increase-rate {
            color: ${Theme.lighterGreen};
          }
          &.decrease-rate {
            color: ${Theme.red};
          }
          &.normal-font {
            font-size: ${Theme.extraNormal};
          }
        }
        .asin-trash {
          padding-right: 5px;
        }

        .display-flex {
          display: flex;
        }
      }
    }
  }
  &.metrics-table {
    tbody {
      tr:last-child td {
        border-bottom: 2px solid #e0e6e8;
      }
    }
  }

  &.services-playbook-table {
    tbody {
      position: relative;
      tr {
        td {
          a {
            color: #000;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
`;

export const TableWrapper = styled.div`
  .body-content {
    max-height: 75vh !important;
    .sc-cbDGPM {
      margin: initial;
      max-width: 73%;
    }
  }

  .modal-body {
    //vertical scroll bar
    overflow: auto;
    ::-webkit-scrollbar {
      width: 7px;
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${Theme.gray11} !important;
    }
    ::-webkit-scrollbar-track {
      background: ${Theme.white} !important;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e0e6e8;
      &.collapse-body-row {
        background: #fae9e6;
        font-size: ${Theme.extraSmall};
        text-transform: uppercase;
      }

      td {
        .goals-text-data {
          max-width: calc(100% - 40px);
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.marketplace-row {
          border-bottom: 1px solid ${Theme.white};
        }
      }
    }
  }

  .sticky-column {
    position: sticky;
    left: 0;
    z-index: 9;
    background: ${Theme.white};
    &.header {
      background: ${Theme.gray8};
    }
    &.collapse-body {
      background: #fae9e6;
      color: ${Theme.orange};
      font-family: ${Theme.titleFontFamily};
      z-index: 2;
      white-space: nowrap;
    }
    &.sticky-panel {
      background: rgb(250, 250, 250);
    }
    &.br-1 {
      border-right: 1px solid #bfc5d2;
    }
    &.col-left-70 {
      left: 70px;
    }
    &.col-left-190 {
      left: 190px;
    }
    &.col-left-260 {
      left: 260px;
    }
  }
`;

export const GoalsStyle = styled.div`
  .goals-radio-button-list {
    margin-top: 10px;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        display: inline-block;
        margin-right: 15px;
      }
      li:last-child {
        margin-right: 0px;
      }
    }
  }
  .new-item {
    padding: 4px 6px;
    border-radius: 6px;
    text-align: center;
    font-size: 9px;
    &.added {
      color: ${Theme.green};
      background: #d6eed5;
    }
    &.edited {
      color: ${Theme.gray90};
      background: ${Theme.gray8};
    }
  }
  .add-new-cos-btn {
    padding: 24px;
  }
  .goals-gray-box {
    border: 1px solid ${Theme.gray11};
    background: ${Theme.gray8};
    color: #2e384d;
    font-size: 13px;
    border-radius: 1px;
    padding: 5px 6px;
    width: 100%;
    min-height: 28px;
  }
`;

export const BorderLessField = styled.div`
  input {
    border: 1px solid ${Theme.gray11};
    background: transparent;
    color: ${Theme.gray90};
    font-size: ${Theme.extraSmall};
    border-radius: 1px;
    padding: 6px;
    width: 100%;

    &:focus {
      outline: ${Theme.orange};
      border: ${Theme.orange};
      border: 1px solid ${Theme.orange};
    }
  }
`;

export const AsinStatus = styled.div`
  .asin-status {
    background: ${Theme.white};
    border: 1px solid ${Theme.gray35};
    height: 20px;
    width: 20px;
    border-radius: 4px;
  }

  /* .parent-status {
    float: right;
  } */

  .selected-asin-status {
    border: 1px solid #ffffff;
    text-align: center;
    padding-top: 1px;
  }
  .waiting-for-assets {
    background: ${Theme?.lightYellow};
    color: ${Theme?.yellow};
  }

  .in-progress-creative {
    background: ${Theme?.lightOrange};
    color: ${Theme?.orange};
  }
  .ready-for-upload {
    background: #cce0ff;
    color: #0045b4;
  }
  .uploaded {
    background: ${Theme?.lightGreen};
    color: ${Theme?.darkGreen};
  }

  .option-icon {
    padding: 2px;
    text-align: center;
    display: inline-block;
  }

  .option-label {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
  }
  .dropdown-notes-filter {
    background-color: ${Theme.white};
    border-radius: 4px;
    box-shadow: 0 5px 15px 0 rgba(68, 68, 79, 0.4);
    width: 250px;
    padding: 15px;
    position: absolute;
    z-index: 99999;
    /* left: 35px; */
    /* width: 100%; */

    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }
    .notes-option {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding-bottom: 14px;
        cursor: pointer;

        &.checkbox-option {
          padding-bottom: 4px;
        }

        &.teams-title {
          color: ${Theme.gray40};
          text-transform: uppercase;
          font-size: 11px;
          padding: 5px 0 15px 0;
          font-family: ${Theme.titleFontFamily};
        }
      }
    }
  }
`;

export const COSAsins = styled.div`
  .asin-count-label {
    font-size: ${Theme.extraNormal};
  }
`;
