// Third party package imports
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

// local file imports
import { ADSALES } from '../../../../constants';

/* ********** Declaring prop types ********** */
export const SponsoredDashboardContainerDefaultProps = {
  // string
  responseID: null,
  currencySymbol: '',
  // boolean
  isBGS: false,
  isBGSAdmin: false,
  isSpreetailRole: false,
  isBGSManager: false,
  isAdManager: false,
  isAdTeamManager: false,
  isAdManagerAdmin: false,
  isCustomDateApply: false,
  includeInactiveBPs: false,
  includeSpreetailBrands: false,
  // object
  data: {},
  userInfo: {},
  customDate: {},
  selectedAdDF: {},
  selectedMarketplace: {},
  selectedPerformanceType: {},
  selectedBrandPartner: {},
  // array
  loggedInUser: [],
  adState: [],
  selectedUser: [],
  selectedManagerUser: [],
  marketplaceChoices: [],
  sponsoredAdMultiSelected: [],
  // function
  setSelectedBrandPartner: () => {},
  bindBrandPartners: () => {},
  setCustomDate: () => {},
  setSelectedAdDF: () => {},
  setSelectedUser: () => {},
  setCurrencySymbol: () => {},
  setSelectedManagerUser: () => {},
  setAdState: () => {},
  setIsCustomDateApply: () => {},
  setSelectedMarketplace: () => {},
  checkIsUserExist: () => {},
  bindUserListData: () => {},
  renderAllocateBar: () => {},
  generateStringOfValues: () => {},
  setSelectedPerformanceType: () => {},
  displayContributionTable: () => {},
  bindMetricsContributionData: () => {},
  renderRecentAndPreviousLabel: () => {},
  handleMultiSelectDropdownFilterEvents: () => {},
  setSponsoredAdMultiSelected: () => {},
  setResponseID: () => {},
};

export const SponsoredDashboardContainerPropTypes = {
  // string
  responseID: string,
  currencySymbol: string,
  // boolean
  isBGS: bool,
  isBGSAdmin: bool,
  isSpreetailRole: bool,
  isBGSManager: bool,
  isAdManager: bool,
  isAdTeamManager: bool,
  isAdManagerAdmin: bool,
  isCustomDateApply: bool,
  includeInactiveBPs: bool,
  includeSpreetailBrands: bool,
  // object
  data: shape({ sub: string, label: string }),
  userInfo: shape({}),
  customDate: shape({}),
  selectedAdDF: shape({}),
  selectedMarketplace: shape({}),
  selectedPerformanceType: shape({}),
  selectedBrandPartner: shape({}),
  // array
  loggedInUser: arrayOf(shape({})),
  adState: arrayOf(shape({})),
  selectedUser: arrayOf(shape({})),
  selectedManagerUser: arrayOf(shape({})),
  marketplaceChoices: arrayOf(shape({})),
  sponsoredAdMultiSelected: arrayOf(shape({})),
  // function
  setSelectedBrandPartner: func,
  bindBrandPartners: func,
  setCustomDate: func,
  setSelectedAdDF: func,
  setSelectedUser: func,
  setCurrencySymbol: func,
  setSelectedManagerUser: func,
  setAdState: func,
  setIsCustomDateApply: func,
  setSelectedMarketplace: func,
  checkIsUserExist: func,
  bindUserListData: func,
  renderAllocateBar: func,
  generateStringOfValues: func,
  setSelectedPerformanceType: func,
  displayContributionTable: func,
  bindMetricsContributionData: func,
  renderRecentAndPreviousLabel: func,
  handleMultiSelectDropdownFilterEvents: func,
  setResponseID: func,
  setSponsoredAdMultiSelected: func,
};

export const SponsoredRightHandSideSectionDefaultProps = {
  // bool
  isCustomDateApply: false,
  adGraphLoader: false,
  downloadReportLoader: false,
  keyContributionLoader: false,
  // string
  adGroupBy: 'daily',
  currencySymbol: '$',
  selectedTabMetrics: ADSALES,
  // number
  pageNumber: 1,
  contributionCount: 0,
  // object
  selectedAdDF: {},
  adDifference: {},
  adCurrentTotal: {},
  adPreviousTotal: {},
  selectedAdMetrics: {},
  customDateError: {},
  selectedPerformanceType: {},
  data: shape({ sub: '', label: '' }),
  // array
  adState: [],
  adChartData: [],
  selectedAdType: [],
  contributionData: [],
  sponsoredAdMultiSelected: [],
  // function
  handleAdGroupBy: () => {},
  handlePageChange: () => {},
  handleAdDailyFact: () => {},
  setSelectedAdMetrics: () => {},
  handleOnMetricsTabChange: () => {},
  displayContributionTable: () => {},
  setSponsoredAdMultiSelected: () => {},
  renderRecentAndPreviousLabel: () => {},
  handledSponsoredMetricsOnMenuClose: () => {},
  handleKeyContributorsDownloadReport: () => {},
};

export const SponsoredRightHandSideSectionPropTypes = {
  // bool
  isCustomDateApply: bool,
  adGraphLoader: bool,
  downloadReportLoader: bool,
  keyContributionLoader: bool,
  // string
  adGroupBy: string,
  currencySymbol: string,
  selectedTabMetrics: string,
  // number
  pageNumber: number,
  contributionCount: number,
  // object
  selectedAdDF: shape({}),
  adDifference: shape({}),
  adCurrentTotal: shape({}),
  adPreviousTotal: shape({}),
  customDateError: shape({}),
  selectedAdMetrics: shape({}),
  selectedPerformanceType: shape({}),
  data: shape({ sub: string, label: string }),
  // array
  adState: arrayOf(shape({})),
  adChartData: arrayOf(shape({})),
  selectedAdType: arrayOf(shape({})),
  contributionData: arrayOf(shape({})),
  sponsoredAdMultiSelected: arrayOf(shape({})),
  // function
  handleAdGroupBy: func,
  handlePageChange: func,
  handleAdDailyFact: func,
  setSelectedAdMetrics: func,
  handleOnMetricsTabChange: func,
  displayContributionTable: func,
  setSponsoredAdMultiSelected: func,
  renderRecentAndPreviousLabel: func,
  handledSponsoredMetricsOnMenuClose: func,
  handleKeyContributorsDownloadReport: func,
};
