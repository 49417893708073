import React from 'react';

import styled from 'styled-components';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import InventoryManagementInfoIcon from './InventoryManagementInfoIcon';
import RestockLimitsGraph from './RestockLimitsGraph';
import Theme from '../../../../theme/Theme';
import { GroupUser } from '../../../../theme/Global';
import {
  dataScrappedMsg,
  restockLimitTypeInfo,
  restockLimitsColorCodes as colorCode,
  storageSubTypes,
} from '../../../../constants';
import {
  AllocateBar,
  DropdownIndicator,
  DropDowns,
  NoData,
  PageLoader,
} from '../../../../common';

const RestockLimitsContainer = ({
  restockLimitsGraphData,
  selectedTimeFrame,
  dateOptions,
  handleDailyFact,
  isApiCall,
  customDateState,
  isCustomDateApply,
  noDataMessage,
}) => {
  const dashboardDynamicError = useSelector(
    (newState) => newState.userState?.showDynamicMsg,
  );
  const { Option, SingleValue } = components;

  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props.data.label}</span>

      <div style={{ fontSize: '10px' }}>
        {selectedTimeFrame.value === 'custom' && isCustomDateApply
          ? `${dayjs(customDateState).format('MMM D, YYYY')}`
          : props.data.sub}
      </div>
    </SingleValue>
  );

  const filterOption = (props) => (
    <Option {...props}>
      <div>
        <span style={{ fontSize: '14px' }}>{props.data.label}</span>

        <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
      </div>
    </Option>
  );

  const getSelectComponents = () => {
    return {
      Option: filterOption,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  const renderRestockTimeFrameFilter = () => {
    return (
      <div
        className="col-md-4 col-12 pl-md-0  mb-md-0 mb-3 "
        id="BT-inventory-daysfilter"
      >
        {DropDowns(
          'BT-InventoryBPTimeframe',
          'days-performance',
          dateOptions,
          dateOptions[0].label,
          getSelectComponents,
          dateOptions[0],
          handleDailyFact,
          isApiCall,
          null,
          selectedTimeFrame,
        )}
      </div>
    );
  };

  const renderRestockDynamicMessasge = () => {
    return (
      <div className="row">
        <div className="col-12">
          {dashboardDynamicError?.inventory?.restock_limits ? (
            <AllocateBar className="mb-4">
              <div className="row">
                <div className="col-12">
                  {' '}
                  <div className="remaing-label">
                    <span className="orange-dot" />
                    {dashboardDynamicError?.inventory?.restock_limits}
                  </div>{' '}
                </div>
              </div>
            </AllocateBar>
          ) : null}
        </div>
      </div>
    );
  };
  const renderRestockGraphLegends = () => {
    return (
      <div className="row mt-2 mb-3">
        <div className="col-md-5 col-sm-12 order-md-1 order-2 pr-0">
          <ul className="rechart-item">
            <li>
              <div className="weeks">
                <span
                  style={{
                    backgroundColor: colorCode?.current,
                    border: `1px solid ${colorCode?.current}`,
                    borderRadius: '4px',
                    width: '12px',
                    height: '12px',
                    marginRight: '10px',
                  }}
                />

                <span> Current</span>
              </div>
            </li>
            {selectedTimeFrame.value !== 'custom' ? (
              <li>
                <div className="weeks">
                  <span
                    style={{
                      backgroundColor: colorCode?.previous,
                      border: `1px solid ${colorCode?.previous}`,
                      borderRadius: '4px',
                      width: '12px',
                      height: '12px',
                      marginRight: '10px',
                    }}
                  />
                  <span>Previous</span>
                </div>
              </li>
            ) : null}
          </ul>
        </div>

        <div className="col-md-7 col-sm-12 order-md-2 order-1 pl-0 text-right">
          <ul className="rechart-item">
            {storageSubTypes.map((subType) => {
              return (
                <li key={subType.key}>
                  <div className="weeks">
                    <span
                      style={{
                        backgroundColor: subType.bgColor,
                        border: `1px solid ${subType.bgColor}`,
                        borderRadius: '100%',
                        width: '12px',
                        height: '12px',
                        marginRight: '5px',
                      }}
                    />
                    {selectedTimeFrame.value !== 'custom' ? (
                      <span
                        style={{
                          backgroundColor: subType.previousBgColor,
                          border: `1px solid ${subType.previousBgColor}`,
                          borderRadius: '100%',
                          width: '12px',
                          height: '12px',
                          marginRight: '10px',
                        }}
                      />
                    ) : null}

                    <span>{subType.label}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-8  mb-2">
          <div className="black-heading-title">
            {' '}
            Restock Limits <InventoryManagementInfoIcon infoIconId="restock" />
          </div>
          <div className="sub-heading extra-small-text text-medium pt-1 mb-3">
            {dataScrappedMsg}
          </div>
        </div>

        {renderRestockTimeFrameFilter()}
      </div>

      {renderRestockDynamicMessasge()}

      {isApiCall ? (
        <PageLoader
          component="performance-graph"
          color="#FF5933"
          type="detail"
          width={40}
          height={40}
        />
      ) : restockLimitsGraphData?.length ? (
        <div className="row">
          <div className="col-12 col-lg-9 ">
            <>
              {/* render restock limits graph legends */}
              {renderRestockGraphLegends()}

              {/*  render restock limit graph */}
              <RestockLimitsGraph
                chartId="restockLimitsGraph"
                selectedDF={selectedTimeFrame}
                chartData={restockLimitsGraphData}
              />
            </>
          </div>

          {/* reneder restock limits right side info panel  */}
          <div className="col-12 col-lg-3 pl-3 mb-2 mb-sm-0 mt-sm-4">
            <RestockStorageTypeInfo>
              {restockLimitTypeInfo.map((info) => {
                return (
                  <GroupUser className={`${info.class} mb-3`} key={info.value}>
                    <div className="activity-user">
                      <span className="font-bold">{info.label} </span>
                      <span className="note-text">{info.subLabel}</span>
                    </div>
                    <div className="clear-fix" />
                  </GroupUser>
                );
              })}
            </RestockStorageTypeInfo>
          </div>
        </div>
      ) : (
        <NoData>{noDataMessage}</NoData>
      )}
      <div className="horizontal-line-dotted mb-5 mt-5" />
    </>
  );
};

export default RestockLimitsContainer;

RestockLimitsContainer.defaultProps = {
  isApiCall: false,
  isCustomDateApply: false,
  noDataMessage: '',
  selectedTimeFrame: {},
  data: {},
  marketplaceDefaultValue: {},
  marketplaceOptions: {},
  dateOptions: [],
  customDateState: {},
  restockLimitsGraphData: [],
  getSelectComponents: () => {},
  handleDailyFact: () => {},
};

RestockLimitsContainer.propTypes = {
  isApiCall: bool,
  isCustomDateApply: bool,
  noDataMessage: string,
  selectedTimeFrame: shape({}),
  marketplaceDefaultValue: shape({}),
  marketplaceOptions: shape({}),
  data: shape({}),
  customDateState: shape({}),
  dateOptions: arrayOf(shape({})),
  restockLimitsGraphData: arrayOf(Array),
  getSelectComponents: func,
  handleDailyFact: func,
};

const RestockStorageTypeInfo = styled.div`
  border-radius: 8px;
  background: ${Theme.gray11};
  padding: 24px 12px 9px 12px;
  overflow: auto;

  .check-main-label {
    font-size: ${Theme.extraMedium} !important;
    font-family: ${Theme.titleFontFamily};
  }

  .check-child-label {
    font-size: ${Theme.extraMedium} !important;
    font-family: ${Theme.baseFontFamily};
  }
`;
