// React imports
import React from 'react';

// Third party imports
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

// Local file imports
import { InventoryTableWrapper } from './InventoryStyle';
import RestockLimitsMobileView from './RestockLimitsMobileView';
import InventoryManagementInfoIcon from '../../../Customer/CustomerDetailView/InventoryManagement/InventoryManagementInfoIcon';
import {
  dataScrappedMsg,
  inventoryNoDataMessage,
  restockLimitTableheader,
} from '../../../../constants';
import {
  CommonPagination,
  GrayMessageBar,
  Table,
  WhiteCard,
} from '../../../../common';

const RestockLimits = ({
  // bool
  restockLoader,
  // string
  orderByApi,
  // number
  restockCount,
  pageNumber,
  // object
  orderBy,
  // array
  restockData,
  // function
  onClickOrder,
  onHandlePageChange,
  formatNumber,
  displayNoDataMessage,
  renderStorageTypeFilter,
  displayPerformanceLoader,
  displayDownloadReportLoader,
  displayCompanyNameLogoAndBGS,
  redirectingToCustomerDetailview,
  displayUpDownArrowforTableHeader,
  displayMetricsChangeValueWithArrow,
}) => {
  const noDataMessage = inventoryNoDataMessage?.restockLimit;
  const isTablet = useMediaQuery({ minWidth: 768 });
  const dashboardDynamicError = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  const restockLimitMessage =
    dashboardDynamicError?.inventory_restock_limit?.placeholder;

  // display metrics value with percent change
  const displayValueWithPercentChange = (value, percentValue, postFix = '') => {
    return (
      <>
        <span
          className={`metrics-value ${isTablet ? 'd-inline-block w-50' : ''}`}
        >
          {formatNumber(value, '', postFix)}
        </span>
        {displayMetricsChangeValueWithArrow(
          percentValue,
          formatNumber,
          isTablet ? 'd-inline-block' : 'text-extra-medium ml-2',
          '%',
        )}
      </>
    );
  };

  // display restock limit table head
  const renderTableHeader = () => {
    return (
      <>
        <tr>
          {restockLimitTableheader?.map((item) => {
            return (
              <th
                key={item?.key}
                id={item?.key}
                width={item?.width}
                onClick={() => {
                  if (item?.key !== 'customer') onClickOrder(item?.key);
                }}
                className={`product-header ${
                  item?.key !== 'customer' ? 'cursor' : ''
                } `}
              >
                {item?.label}
                {item?.key !== 'customer'
                  ? displayUpDownArrowforTableHeader(
                      item?.key,
                      orderBy,
                      orderByApi,
                    )
                  : null}
              </th>
            );
          })}
        </tr>
      </>
    );
  };

  // display restock limit table body
  const renderInventoryData = (item) => {
    return (
      <>
        <tr
          className="cursor"
          key={item?.customer_id}
          onClick={() => {
            redirectingToCustomerDetailview(item?.customer_id);
          }}
        >
          <td className="product-body">{displayCompanyNameLogoAndBGS(item)}</td>
          <td className="product-body">
            {displayValueWithPercentChange(
              item?.storage?.utilization,
              item?.storage?.utilization_change,
              '%',
            )}
          </td>
          <td className="product-body">
            {displayValueWithPercentChange(
              item?.storage?.max_shipment,
              item?.storage?.max_shipment_change,
              '',
            )}
          </td>
          <td className="product-body">
            {displayValueWithPercentChange(
              item?.storage?.open_shipment,
              item?.storage?.open_shipment_change,
              '',
            )}
          </td>
          <td className="product-body">
            {displayValueWithPercentChange(
              item?.storage?.on_hand,
              item?.storage?.on_hand_change,
              '',
            )}
          </td>
        </tr>
      </>
    );
  };

  // function - display inventory desktop view
  const renderRestockLimitDesktopView = () => {
    return (
      <>
        <InventoryTableWrapper>
          <div className="straight-line horizontal-line spacing d-md-block d-none" />
          <Table className="inventory-table">
            <thead className={restockLoader ? 'disabled' : ''}>
              {renderTableHeader()}
            </thead>
            {restockData?.length ? (
              <tbody>
                {restockData?.map((item) => {
                  return renderInventoryData(item);
                })}
              </tbody>
            ) : null}
          </Table>
          {restockLoader ? displayPerformanceLoader() : null}
          {!restockLoader && !restockData?.length
            ? displayNoDataMessage(noDataMessage)
            : null}
        </InventoryTableWrapper>
      </>
    );
  };

  // function - display inventory mobile view
  const renderRestockLimitMobileView = () => {
    return (
      <>
        {restockLoader ? (
          displayPerformanceLoader()
        ) : restockData?.length ? (
          <RestockLimitsMobileView
            restockLoader={restockLoader}
            restockData={restockData}
            displayCompanyNameLogoAndBGS={displayCompanyNameLogoAndBGS}
            displayValueWithPercentChange={displayValueWithPercentChange}
            redirectingToCustomerDetailview={redirectingToCustomerDetailview}
          />
        ) : (
          displayNoDataMessage(noDataMessage)
        )}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      <WhiteCard className="mb-3">
        <div className="row mb-3">
          <div className="col-lg-6 col-md-4 align-self-center">
            <div className="black-heading-title">
              Restock Limits Table
              <InventoryManagementInfoIcon infoIconId="restock_limits_table" />
            </div>
            <div
              id="BT-InventoryDataMessage"
              className="sub-heading extra-small-text text-medium pt-1"
            >
              {dataScrappedMsg}
            </div>
          </div>
          <div className="col-lg-4 col-md-5 mt-3 mt-md-0">
            <div className="d-flex">
              <div className="align-self-center  normal-text-black text-right d-md-block d-none">
                Storage Type:
              </div>
              <div className="flex-fill ml-md-2">
                {renderStorageTypeFilter()}
              </div>
            </div>
          </div>
          {restockData?.length >= 1 ? (
            <div className="col-lg-2 col-md-3 col-sm1-12 mt-3 mt-md-0 pr-md-2 text-right pt-md-2">
              {displayDownloadReportLoader('restock-limit')}
            </div>
          ) : null}
          <div className="col-12">
            {restockLimitMessage ? (
              <GrayMessageBar message={restockLimitMessage} />
            ) : null}
          </div>
        </div>

        {isTablet
          ? renderRestockLimitDesktopView()
          : renderRestockLimitMobileView()}
        {restockCount > 10 ? (
          <>
            <div className="straight-line horizontal-line mt-3" />
            <CommonPagination
              count={restockCount}
              pageNumber={pageNumber}
              handlePageChange={onHandlePageChange}
            />
          </>
        ) : null}
      </WhiteCard>
    </>
  );
};

export default RestockLimits;

RestockLimits.defaultProps = {
  // bool
  restockLoader: false,
  // string
  orderByApi: '',
  // number
  pageNumber: 1,
  restockCount: 0,
  // object
  orderBy: {},
  // array
  restockData: [],
  // function
  onClickOrder: () => {},
  formatNumber: () => {},
  onHandlePageChange: () => {},
  displayNoDataMessage: () => {},
  renderStorageTypeFilter: () => {},
  displayPerformanceLoader: () => {},
  displayDownloadReportLoader: () => {},
  displayCompanyNameLogoAndBGS: () => {},
  redirectingToCustomerDetailview: () => {},
  displayUpDownArrowforTableHeader: () => {},
  displayMetricsChangeValueWithArrow: () => {},
};

RestockLimits.propTypes = {
  // bool
  restockLoader: bool,
  // string
  orderByApi: string,
  // number
  restockCount: number,
  pageNumber: number,
  // object
  orderBy: shape({}),
  // array
  restockData: arrayOf(shape({})),
  // function
  onClickOrder: func,
  onHandlePageChange: func,
  formatNumber: func,
  displayNoDataMessage: func,
  renderStorageTypeFilter: func,
  displayPerformanceLoader: func,
  displayDownloadReportLoader: func,
  displayCompanyNameLogoAndBGS: func,
  redirectingToCustomerDetailview: func,
  displayUpDownArrowforTableHeader: func,
  displayMetricsChangeValueWithArrow: func,
};
