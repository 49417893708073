import React from 'react';

import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { arrayOf, bool, func, shape, string } from 'prop-types';

import Theme from '../../../../../../theme/Theme';
import {
  DropDowns,
  DropdownIndicator,
  DropdownOptions,
  DropdownSingleValue,
  ViewData,
  WhiteCard,
} from '../../../../../../common';

function DspPerformanceFilters({
  isApiCall,
  isDSPCustomDateApply,
  customerState,
  selectedDspDF,
  selectedChildBrand,
  selectedDspMarketplace,
  dspState,
  childBrandOptions,
  dateFilterOptions,
  dspMarketplacesList,
  handleDSPDailyFact,
  handleChildBrandOptions,
  handleDspMarketplaceFilterEvents,
}) {
  const { Option, SingleValue } = components;

  // collecting the dsp marketplaces dropdown filter components
  const getSelectComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: DropdownSingleValue,
      DropdownIndicator,
    };
  };

  // render dsp marketplace filter
  const displayDspMarketplaceFilter = () => {
    return (
      <div data-cy="BT-dsp-marketplace-filter">
        {DropDowns(
          'BT-dsp-marketplace-filter',
          'cursor ',
          dspMarketplacesList,
          selectedDspMarketplace?.label,
          getSelectComponents,
          '',
          handleDspMarketplaceFilterEvents,
          isApiCall,
          null,
          selectedDspMarketplace,
        )}
      </div>
    );
  };

  const renderDSPCustomDateSubLabel = (props) => {
    if (selectedDspDF?.value === 'custom' && isDSPCustomDateApply) {
      return `From- ${dayjs(dspState?.[0]?.startDate).format(
        'MMM D, YYYY',
      )}  To- ${dayjs(dspState?.[0]?.endDate).format('MMM D, YYYY')}`;
    }

    return props?.data?.sub;
  };

  const singleDSPFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>

      <div style={{ fontSize: '10px' }}>
        {renderDSPCustomDateSubLabel(props)}
      </div>
    </SingleValue>
  );

  const filterOption = (props) => {
    return (
      <Option {...props}>
        <div>
          <span style={{ fontSize: '14px' }}>
            {props?.data?.label}
            {props?.isDisabled === true ? (
              <>
                <FontAwesomeIcon
                  icon=" fal fa-info-circle"
                  color={Theme.orange}
                  fontSize="13px"
                  style={{ marginLeft: '20px' }}
                  data-tip="Selected metrics has no data for this period."
                  data-for="adSpendTotalInfo"
                />
                <ReactTooltip
                  class="initialTextTransform"
                  id="adSpendTotalInfo"
                  aria-haspopup="true"
                  place="right"
                  effect="solid"
                />
              </>
            ) : null}
          </span>

          <div style={{ fontSize: '10px' }}>{props?.data?.sub}</div>
        </div>
      </Option>
    );
  };

  const getDSPSelectComponents = () => {
    return {
      Option: filterOption,
      SingleValue: singleDSPFilterOption,
      DropdownIndicator,
    };
  };

  // render Time frame filter
  const renderTimeFrameFilter = () => {
    return (
      <div data-cy="BT-dsp-timeFrame-filter">
        {DropDowns(
          'BT-DspAdPerformanceTimeFrame',
          '',
          dateFilterOptions,
          dateFilterOptions?.[0]?.label,
          getDSPSelectComponents,
          dateFilterOptions?.[0],
          handleDSPDailyFact,
          isApiCall,
          null,
          selectedDspDF,
          false,
        )}
      </div>
    );
  };

  return (
    <div id="dsp-filters">
      <WhiteCard className="mb-3">
        <ViewData>
          <div className="row">
            <div className="col-12 col-md-3 col-xl-5">
              <div className="view-data-for mt-2">View data for:</div>
            </div>
            <div className="col-12 col-md-9 col-xl-7">
              <div className="row">
                {customerState?.account_type === 'parent' ? (
                  <div className="col-md-4 col-12 pl-md-0 mb-3 mb-md-0">
                    <div data-cy="BT-childBrand-filter">
                      {DropDowns(
                        'BT-adperformancedata-childBrandFilter',
                        'cursor',
                        childBrandOptions,
                        selectedChildBrand?.label,
                        getSelectComponents,
                        '',
                        handleChildBrandOptions,
                        isApiCall,
                        null,
                        selectedChildBrand,
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4 col-12 pl-md-0 mb-3 mb-md-0" />
                )}

                <div className="col-md-4 col-12 pl-md-0 mb-3 mb-md-0">
                  {dspMarketplacesList?.length > 0
                    ? displayDspMarketplaceFilter()
                    : null}
                </div>
                <div className="col-md-4 col-12 pl-md-0 mb-3 mb-md-0">
                  {renderTimeFrameFilter()}
                </div>
              </div>
            </div>
          </div>
        </ViewData>
      </WhiteCard>
    </div>
  );
}

export default DspPerformanceFilters;

DspPerformanceFilters.defaultProps = {
  // boolean
  isDisabled: false,
  isApiCall: false,
  isDSPCustomDateApply: false,
  data: {},
  selectedDspDF: {},
  customerState: {},
  selectedChildBrand: {},
  selectedDspMarketplace: {},
  dspState: [],
  childBrandOptions: [],
  dateFilterOptions: [],
  dspMarketplacesList: [],
  handleDSPDailyFact: () => {},
  handleChildBrandOptions: () => {},
  handleDspMarketplaceFilterEvents: () => {},
};

DspPerformanceFilters.propTypes = {
  // boolean
  isDisabled: bool,
  isApiCall: bool,
  isDSPCustomDateApply: bool,
  data: shape({
    label: string,
    sub: string,
  }),
  selectedDspDF: shape({}),
  customerState: shape({}),
  selectedChildBrand: shape({}),
  selectedDspMarketplace: shape({}),
  dspState: arrayOf(shape({})),
  childBrandOptions: arrayOf(shape({})),
  dateFilterOptions: arrayOf(shape({})),
  dspMarketplacesList: arrayOf(shape({})),
  handleDSPDailyFact: func,
  handleChildBrandOptions: func,
  handleDspMarketplaceFilterEvents: func,
};
