/* ********** Third party package imports ********** */
import React from 'react';

/* ********** Third party package imports ********** */
import { bool, func, string, shape } from 'prop-types';

/* ********** Local component file imports ********** */
import { NoData, PageLoader, Tabs } from '../../../../../common';
import {
  CONVERSIONRATE,
  currencySymbol,
  CUSTOM,
  GLANCEVIEWS,
  noGraphDataMessage,
  ORDEREDREVENUE,
  ORDEREDUNITS,
  SHIPPEDCOGS,
  SHIPPEDUNITS,
  vendorOrderedRevenueMetricsTabs,
  vendorShippedCOGsMetricsTabs,
  VENDORSMALL,
} from '../../../../../constants';

/* ********** MAIN FUNCTION START HERE ********** */
function SalesVendorContribution({
  // string
  selectedTabMetrics,
  // boolean
  isApiCall,
  // object
  selectedSalesDF,

  selectedVendorMetricsType,
  // array
  vendorContributionData,
  // function
  displaySalesPagination,
  handleOnMetricsTabChange,
  displayContributionTable,
  dispalyContributionSectionHeader,
}) {
  // display vendor contribution tabs
  const displayVendorContributionTabs = () => {
    const selectedTab = selectedTabMetrics;
    const vendorContributionTabs =
      selectedVendorMetricsType?.value === ORDEREDREVENUE
        ? vendorOrderedRevenueMetricsTabs
        : vendorShippedCOGsMetricsTabs;
    return (
      <Tabs>
        <ul className={isApiCall ? 'tabs disabled' : 'tabs'}>
          {vendorContributionTabs?.map((item) => (
            <li
              id={`BT-${item?.value}-tab`}
              key={item?.value}
              className={selectedTab === item?.value ? 'active' : ''}
              onClick={() => handleOnMetricsTabChange(item?.value, VENDORSMALL)}
              role="presentation"
            >
              {item?.label}
            </li>
          ))}
        </ul>
      </Tabs>
    );
  };

  // display vendor contribution table
  const dispalyVendorContributionTable = () => {
    let prefix = '';
    let suffix = '';
    let decimalDigits = 2;
    if (
      selectedTabMetrics === ORDEREDREVENUE ||
      selectedTabMetrics === SHIPPEDCOGS
    ) {
      decimalDigits = 2;
      prefix = currencySymbol;
      suffix = '';
    }

    if (
      selectedTabMetrics === GLANCEVIEWS ||
      selectedTabMetrics === ORDEREDUNITS ||
      selectedTabMetrics === SHIPPEDUNITS
    ) {
      decimalDigits = 0;
      prefix = '';
      suffix = '';
    }
    if (selectedTabMetrics === CONVERSIONRATE) {
      decimalDigits = 2;
      prefix = '';
      suffix = '%';
    }

    return (
      <div>
        {isApiCall ? (
          <PageLoader
            component="performance-graph"
            color="#FF5933"
            type="detail"
            width={40}
            height={40}
          />
        ) : selectedSalesDF?.value === CUSTOM ? (
          <NoData>
            Top contributors cannot be calculated when using custom dates.
          </NoData>
        ) : (
          <>
            {vendorContributionData?.length > 0 ? (
              displayContributionTable(
                vendorContributionData,
                prefix,
                suffix,
                decimalDigits,
              )
            ) : (
              <NoData>{noGraphDataMessage}</NoData>
            )}
            {displaySalesPagination()}
          </>
        )}
      </div>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div id="BT-sales-vendor-contribution">
      {dispalyContributionSectionHeader()}
      {displayVendorContributionTabs()}
      {dispalyVendorContributionTable()}
    </div>
  );
}

export default SalesVendorContribution;
/* ********** MAIN FUNCTION END HERE ********** */

/* ********** Declaring prop types ********** */
SalesVendorContribution.defaultProps = {
  // string
  selectedTabMetrics: '',
  // boolean
  isApiCall: false,
  // object
  selectedSalesDF: {},
  selectedVendorMetricsType: {},
  // array
  vendorContributionData: [],
  // function
  displaySalesPagination: () => {},
  handleOnMetricsTabChange: () => {},
  displayContributionTable: () => {},
  dispalyContributionSectionHeader: () => {},
};

SalesVendorContribution.propTypes = {
  // string
  selectedTabMetrics: string,
  // boolean
  isApiCall: bool,
  // object
  selectedSalesDF: shape({}),
  selectedVendorMetricsType: shape({}),
  // aaray
  vendorContributionData: [],
  // function
  displaySalesPagination: func,
  handleOnMetricsTabChange: func,
  displayContributionTable: func,
  dispalyContributionSectionHeader: func,
};
