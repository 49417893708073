/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// third party imports
import queryString from 'query-string';
import { Collapse } from 'react-collapse';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// local imports
import AskSomeone from './AskSomeone';
import CheckSteps from './CheckSteps';
import BillingInfo from './BillingInfo';
import Header from '../../common/Header';
import CreateAccount from './CreateAccount';
import VideoModal from './Modals/VideoModal';
import CompanyDigital from './CompanyDigital';
import AmazonMerchant from './AmazonMerchant';
import NavigationHeader from './NavigationHeader';
import { getCustomerDetails } from '../../store/actions';
import { CollapseContainer } from './OnBoardingStyles';
import { CaretUp, VideoCall } from '../../theme/images';
import {
  OnBoardingBody,
  UnauthorizedHeader,
  GreyCard,
  PageLoader,
  CheckBox,
} from '../../common';
import {
  accountSummary,
  getStepDetails,
  getUserData,
  getVideoLink,
  verifyStepToken,
  getBillingDetails,
  getAmazonAccountDetails,
  getDSPContact,
  getAmazonAccount,
} from '../../api';
import {
  PATH_AMAZON_MERCHANT,
  PATH_BILLING_DETAILS,
  PATH_COMPANY_DETAILS,
  PATH_CUSTOMER_DETAILS,
  PATH_SUMMARY,
  PATH_THANKS,
  PATH_UNAUTHORIZED_COMPANY_DETAILS,
  PATH_UNAUTHORIZED_BILLING_DETAILS,
  PATH_UNAUTHORIZED_AMAZON_MERCHANT,
  PATH_CREATE_PASSWORD,
  PATH_ACCOUNT_SETUP,
  stepPath,
  customVideoStyle,
  delegatedInfo,
  PATH_LOGIN,
  PATH_AMAZON_ACCOUNT,
  PATH_UNAUTHORIZED_AMAZON_ACCOUNT,
  pageTitleConditions,
  whichStep,
} from '../../constants';
import AmazonDeveloperAccess from './AmazonDeveloperAccess';

export default function MainContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = queryString.parse(history?.location?.search);
  const data = useSelector((state) => state?.customerState?.data);
  const loader = useSelector((state) => state?.customerState?.isLoading);
  const [openCollapse, setOpenCollapse] = useState(false);
  // is assigned to someone else checkbox is checked or not is stored in isChecked state variable
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState({ loader: false, type: 'button' });
  // if history.location.pathname.includes('assigned') then assignedToSomeone variable is set to true
  const [assignedToSomeone, setAssignedToSomeone] = useState(false);
  const [stepData, setStepData] = useState({});
  const [verifiedStepData, setVerifiedStepData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [showVideo, setShowVideo] = useState(false);
  const [videoData, setVideoData] = useState({});
  const [summaryData, setSummaryData] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [billingData, setBillingData] = useState({});
  const [apiError, setApiError] = useState({});
  const [formData, setFormData] = useState({});
  const [marketplaceDetails, setMarketplaceDetails] = useState({});
  const [showAmazonVideo, setShowAmazonVideo] = useState({});
  const [dspData, setDspData] = useState([]);
  const [showDSPContact, setshowDSPContact] = useState({
    show: false,
    sameAsBilling: true,
  });
  const [noAmazonAccount, setNoAmazonAccount] = useState(false);
  const [secondaryMarketplaces, setSecondaryMarketplaces] = useState([]);
  const localSeller = JSON.parse(localStorage.getItem('seller')) || [];
  const localVendor = JSON.parse(localStorage.getItem('vendor')) || [];

  // This two contains data of both primary & secondary marketplaces
  const [sellerMarketplaces, setSellerMarketplaces] = useState(localSeller);
  const [vendorMarketplaces, setVendorMarketplaces] = useState(localVendor);
  const [amazonAccountData, setAmazonAccountData] = useState([]);
  const [localNoAmazonAccount, setLocalNoAmazonAccount] = useState({});

  // Call the function if only page url is get changed (Code to add page title)
  useEffect(() => {
    pageTitleConditions(history?.location);
    history.listen((location) => {
      pageTitleConditions(location);
    });
  }, [history]);

  useEffect(() => {
    // Get value of no amazon account from localstorage and set into state variables
    const amazonLocalStorageValue = JSON.parse(
      localStorage.getItem('noAmazonAccount'),
    );
    if (
      amazonLocalStorageValue !== null &&
      amazonLocalStorageValue !== undefined
    ) {
      setLocalNoAmazonAccount({ noAmazonAccount: amazonLocalStorageValue });
      setNoAmazonAccount(localNoAmazonAccount.noAmazonAccount);
    }
  }, [noAmazonAccount]);

  useEffect(() => {
    // get seller and vendor market places from localstorage if available
    if (localSeller?.length) {
      setSellerMarketplaces(localSeller);
    }
    if (localVendor?.length) {
      setVendorMarketplaces(localVendor);
    }
  }, []);

  const getStepName = () => {
    for (const item of whichStep) {
      if (history?.location?.pathname.includes(item?.path)) {
        return item?.key === 'summary' ? '' : item?.key;
      }
    }
    return '';
  };

  const getAccountDetails = (id, showLoader = true) => {
    if (showLoader) setIsLoading({ loader: true, type: 'page' });
    getAmazonAccountDetails(
      'amazon-account-details',
      localStorage.getItem('customer') || id,
    ).then((response) => {
      if (response?.data) {
        if (
          response?.data?.account_type === 'Hybrid' ||
          response?.data?.account_type === null
        ) {
          setNoAmazonAccount(
            Object.keys(localNoAmazonAccount)?.length
              ? localNoAmazonAccount?.localNoAmazonAccount
              : !!(
                  response?.data?.Seller?.no_amazon_account &&
                  response?.data?.Vendor?.no_amazon_account
                ),
          );
        } else {
          setNoAmazonAccount(
            Object.keys(localNoAmazonAccount)?.length
              ? localNoAmazonAccount?.localNoAmazonAccount
              : response?.data?.[response?.data?.account_type]
                  ?.no_amazon_account ?? false,
          );
        }

        // data created for primary marketplace
        setMarketplaceDetails({
          marketplace: response?.data?.marketplace?.name?.label,
          marketplace_name: response?.data?.marketplace?.name?.value,
          customer: localStorage.getItem('customer') || id,
          marketplaceURL: response?.data?.marketplace?.name?.value,
          type: response?.data?.account_type,
          marketplaceId: response?.data?.marketplace?.id,
          Seller: localSeller?.length
            ? localSeller
            : response?.data?.marketplace && response?.data?.Seller,
          Vendor: localVendor?.length
            ? localVendor
            : response?.data?.marketplace && response?.data?.Vendor,
          url: response?.data?.marketplace?.url,
        });

        let sellerData = [];
        let vendorData = [];

        // Combine primary & secondary marketplaces of Seller
        // store secondary marketplace in sellerArray

        const sellerArray = localSeller?.length
          ? localSeller
          : response?.data?.secondary_marketplaces;

        // if primary marketplace seller central no_amazon_account flag is true
        if (response?.data?.Seller?.no_amazon_account) {
          const secondarySellerData = sellerArray?.map((entry) => {
            const formattedData = {
              seller_central_name:
                entry?.doesnt_advertise || entry?.Seller?.doesnt_advertise
                  ? ''
                  : entry?.seller_central_name ||
                    entry?.Seller?.seller_central_name ||
                    '',
              merchant_id:
                entry?.doesnt_advertise || entry?.Seller?.doesnt_advertise
                  ? ''
                  : entry?.merchant_id || entry?.Seller?.merchant_id || '',
              advertiser_name:
                entry?.doesnt_advertise || entry?.Seller?.doesnt_advertise
                  ? ''
                  : entry?.advertiser_name ||
                    entry?.Seller?.advertiser_name ||
                    '',
              advertiser_id:
                entry?.doesnt_advertise || entry?.Seller?.doesnt_advertise
                  ? ''
                  : entry?.advertiser_id || entry?.Seller?.advertiser_id || '',
              doesnt_advertise:
                (entry?.doesnt_advertise || entry?.Seller?.doesnt_advertise) ??
                false,
              marketplace: entry?.id,
              marketplace_name: entry?.name?.value,
              customer: localStorage?.getItem('customer') || id,
              type: entry?.account_type,
            };
            if (entry?.Seller?.id || null)
              formattedData.id = entry?.Seller?.id || null;

            return formattedData;
          });

          const sortedSellerArray = localSeller?.length
            ? localSeller
            : secondarySellerData;

          // const sortedSellerArray = secondarySellerData;

          const sortedSellerData =
            sortedSellerArray?.filter((entry) => entry.type === 'Seller') || [];
          const primaryLocalSeller = sortedSellerArray?.filter(
            (op) => op?.doesnt_advertise === undefined,
          )?.[0];

          const primaryMarketplaceData = {
            seller_central_name:
              primaryLocalSeller?.seller_central_name ||
              response?.data?.Seller?.seller_central_name ||
              '',
            merchant_id:
              primaryLocalSeller?.merchant_id ||
              response?.data?.Seller?.merchant_id ||
              '',
            advertiser_name:
              primaryLocalSeller?.advertiser_name ||
              response?.data?.Seller?.advertiser_name ||
              '',
            advertiser_id:
              primaryLocalSeller?.advertiser_id ||
              response?.data?.Seller?.advertiser_id ||
              '',
            marketplace:
              primaryLocalSeller?.marketplace ||
              response?.data?.marketplace?.id,
            marketplace_name: response?.data?.marketplace?.name?.value,
            customer: localStorage.getItem('customer') || id,
            doesnt_advertise: false,
          };
          if (primaryLocalSeller?.id || response?.data?.Seller?.id)
            primaryMarketplaceData.id =
              primaryLocalSeller?.id || response?.data?.Seller?.id || null;
          sellerData = [...sortedSellerData, primaryMarketplaceData];
        } else {
          // no_amazon_account flag is false for seller centeral primary marketplace
          const secondarySellerData = sellerArray?.map((entry) => {
            // create data the way api want for secondary marketplace .
            const formattedData = {
              seller_central_name:
                entry?.doesnt_advertise || entry?.Seller?.doesnt_advertise
                  ? ''
                  : entry?.seller_central_name ||
                    entry?.Seller?.seller_central_name ||
                    '',
              merchant_id:
                entry?.doesnt_advertise || entry?.Seller?.doesnt_advertise
                  ? ''
                  : entry?.merchant_id || entry?.Seller?.merchant_id || '',
              advertiser_name:
                entry?.doesnt_advertise || entry?.Seller?.doesnt_advertise
                  ? ''
                  : entry?.advertiser_name ||
                    entry?.Seller?.advertiser_name ||
                    '',
              advertiser_id:
                entry?.doesnt_advertise || entry?.Seller?.doesnt_advertise
                  ? ''
                  : entry?.advertiser_id || entry?.Seller?.advertiser_id || '',
              doesnt_advertise:
                (entry?.doesnt_advertise || entry?.Seller?.doesnt_advertise) ??
                false,
              marketplace: entry?.id,
              marketplace_name: entry?.name?.value,
              customer: localStorage.getItem('customer') || id,
              type: entry?.account_type,
            };
            if (entry?.Seller?.id) formattedData.id = entry?.Seller?.id;

            return formattedData;
          });

          const sortedSellerArray = localSeller?.length
            ? localSeller
            : secondarySellerData;

          const sortedSellerData =
            sortedSellerArray?.filter((entry) => entry?.type === 'Seller') ||
            [];

          const primaryLocalSeller = sortedSellerArray?.filter(
            (op) => op?.doesnt_advertise === undefined,
          )?.[0];

          sellerData = [...sortedSellerData];

          const primaryMarketplaceData = {
            seller_central_name:
              primaryLocalSeller?.seller_central_name ||
              response?.data?.Seller?.seller_central_name ||
              '',
            merchant_id:
              primaryLocalSeller?.merchant_id ||
              response?.data?.Seller?.merchant_id ||
              '',
            advertiser_name:
              primaryLocalSeller?.advertiser_name ||
              response?.data?.Seller?.advertiser_name ||
              '',
            advertiser_id:
              primaryLocalSeller?.advertiser_id ||
              response?.data?.Seller?.advertiser_id ||
              '',
            marketplace:
              primaryLocalSeller?.marketplace ||
              response?.data?.marketplace?.id,
            marketplace_name: response?.data?.marketplace?.name?.value,
            customer: localStorage.getItem('customer') || id,
            type: 'Seller',
            doesnt_advertise: false,
          };
          if (primaryLocalSeller?.id || response?.data?.Seller?.id)
            primaryMarketplaceData.id =
              primaryLocalSeller?.id || response?.data?.Seller?.id;

          if (response?.data?.account_type !== null) {
            if (
              response?.data?.account_type === 'Seller' ||
              response?.data?.account_type === 'Hybrid'
            )
              sellerData = [...sortedSellerData, primaryMarketplaceData];
          }
        }

        // Combine primary & secondary marketplaces of Vendor
        const vendorArray = localVendor?.length
          ? localVendor
          : response?.data?.secondary_marketplaces;

        // const vendorArray = response?.data?.secondary_marketplaces;

        if (response?.data?.Vendor?.no_amazon_account) {
          const secondaryVendorData = vendorArray?.map((entry) => {
            const formattedData = {
              vendor_central_name:
                entry?.doesnt_advertise || entry?.Vendor?.doesnt_advertise
                  ? ''
                  : entry?.vendor_central_name ||
                    entry?.Vendor?.vendor_central_name ||
                    '',
              vendor_code:
                entry?.doesnt_advertise || entry?.Vendor?.doesnt_advertise
                  ? ''
                  : entry?.vendor_code || entry?.Vendor?.vendor_code || '',
              advertiser_name:
                entry?.doesnt_advertise || entry?.Vendor?.doesnt_advertise
                  ? ''
                  : entry?.advertiser_name ||
                    entry?.Vendor?.advertiser_name ||
                    '',
              advertiser_id:
                entry?.doesnt_advertise || entry?.Vendor?.doesnt_advertise
                  ? ''
                  : entry?.advertiser_id || entry?.Vendor?.advertiser_id || '',
              doesnt_advertise:
                (entry?.doesnt_advertise || entry?.Vendor?.doesnt_advertise) ??
                false,
              marketplace: entry?.id,
              marketplace_name: entry?.name?.value,
              customer: localStorage.getItem('customer') || id,
              type: entry?.account_type,
            };
            if (entry?.Vendor?.id) formattedData.id = entry?.Vendor?.id;
            return formattedData;
          });
          const sortedVendorArray = localVendor?.length
            ? localVendor
            : secondaryVendorData;

          const sortedVendorData =
            sortedVendorArray?.filter((entry) => entry?.type === 'Vendor') ||
            [];
          const primaryLocalVendor = sortedVendorArray?.filter(
            (op) => op?.doesnt_advertise === undefined,
          )?.[0];

          const primaryVendorMarketplaceData = {
            vendor_central_name:
              primaryLocalVendor?.vendor_central_name ||
              response?.data?.Vendor?.vendor_central_name ||
              '',
            vendor_code:
              primaryLocalVendor?.vendor_code ||
              response?.data?.Vendor?.vendor_code ||
              '',
            advertiser_name:
              primaryLocalVendor?.advertiser_name ||
              response?.data?.Vendor?.advertiser_name ||
              '',
            advertiser_id:
              primaryLocalVendor?.advertiser_id ||
              response?.data?.Vendor?.advertiser_id ||
              '',
            marketplace:
              primaryLocalVendor?.marketplace ||
              response?.data?.marketplace?.id,
            marketplace_name: response?.data?.marketplace?.name?.value,
            customer: localStorage.getItem('customer') || id,
            doesnt_advertise: false,
          };

          if (primaryLocalVendor?.id || response?.data?.Vendor?.id)
            primaryVendorMarketplaceData.id =
              primaryLocalVendor?.id || response?.data?.Vendor?.id;

          vendorData = [...sortedVendorData, primaryVendorMarketplaceData];
        } else {
          const secondaryVendorData = vendorArray?.map((entry) => {
            const formattedData = {
              vendor_central_name:
                entry?.doesnt_advertise || entry?.Vendor?.doesnt_advertise
                  ? ''
                  : entry?.vendor_central_name ||
                    entry?.Vendor?.vendor_central_name ||
                    '',
              vendor_code:
                entry?.doesnt_advertise || entry?.Vendor?.doesnt_advertise
                  ? ''
                  : entry?.vendor_code || entry?.Vendor?.vendor_code || '',

              advertiser_name:
                entry?.doesnt_advertise || entry?.Vendor?.doesnt_advertise
                  ? ''
                  : entry?.advertiser_name ||
                    entry?.Vendor?.advertiser_name ||
                    '',
              advertiser_id: entry?.Vendor?.doesnt_advertise
                ? ''
                : entry?.advertiser_id || entry?.Vendor?.advertiser_id || '',
              doesnt_advertise:
                (entry?.doesnt_advertise || entry?.Vendor?.doesnt_advertise) ??
                false,
              marketplace: entry?.id,
              marketplace_name: entry?.name?.value,
              customer: localStorage.getItem('customer') || id,
              type: entry?.account_type,
            };
            if (entry?.Vendor?.id) formattedData.id = entry?.Vendor?.id || '';
            return formattedData;
          });
          const sortedVendorArray = localVendor?.length
            ? localVendor
            : secondaryVendorData;

          const sortedVendorData =
            sortedVendorArray?.filter((entry) => entry?.type === 'Vendor') ||
            [];
          const primaryLocalVendor = sortedVendorArray?.filter(
            (op) => op?.doesnt_advertise === undefined,
          )?.[0];

          vendorData = [...sortedVendorData];
          const primaryVendorData = {
            vendor_central_name:
              primaryLocalVendor?.vendor_central_name ||
              response?.data?.Vendor?.vendor_central_name ||
              '',
            vendor_code:
              primaryLocalVendor?.vendor_code ||
              response?.data?.Vendor?.vendor_code ||
              '',
            advertiser_name:
              primaryLocalVendor?.advertiser_name ||
              response?.data?.Vendor?.advertiser_name ||
              '',
            advertiser_id:
              primaryLocalVendor?.advertiser_id ||
              response?.data?.Vendor?.advertiser_id ||
              '',
            marketplace:
              primaryLocalVendor?.marketplace ||
              response?.data?.marketplace?.id,
            marketplace_name: response?.data?.marketplace?.name?.value,
            customer: localStorage.getItem('customer') || id,
            type: 'Vendor',
            doesnt_advertise: false,
          };
          if (primaryLocalVendor?.id || response?.data?.Vendor?.id || '')
            primaryVendorData.id =
              primaryLocalVendor?.id || response?.data?.Vendor?.id || '';

          // don't add primary data for DSP only BP.
          // for DSP only BP account type is null in api response
          if (response?.data?.account_type !== null) {
            if (
              response?.data?.account_type === 'Vendor' ||
              response?.data?.account_type === 'Hybrid'
            )
              vendorData = [...sortedVendorData, primaryVendorData];
          }
        }

        const marketplaceNames = [];
        const formattedSecondaryMarketplaces = [];

        if (response?.data?.secondary_marketplaces) {
          for (const marketplace of response?.data?.secondary_marketplaces) {
            if (!marketplaceNames?.includes(marketplace?.name?.value)) {
              marketplaceNames.push(marketplace?.name?.value);
              formattedSecondaryMarketplaces[marketplace?.name?.value] = {
                name: marketplace?.name?.label,
              };
            }
          }

          for (const marketplace of response?.data?.secondary_marketplaces) {
            if (marketplace.account_type === 'Seller') {
              formattedSecondaryMarketplaces[
                marketplace?.name?.value
              ].seller_marketplace = marketplace;
            } else {
              formattedSecondaryMarketplaces[
                marketplace?.name?.value
              ].vendor_marketplace = marketplace;
            }
          }
        }

        const finalSecondaryMarketplace = [];

        // eslint-disable-next-line guard-for-in
        for (const key in formattedSecondaryMarketplaces) {
          finalSecondaryMarketplace.push(formattedSecondaryMarketplaces[key]);
        }

        // Add vendor marketplaces in finalSecondaryMarketplace from localstorage if present
        if (localVendor?.length) {
          let tempVendor = finalSecondaryMarketplace?.map((item) =>
            localVendor?.map((item1) => {
              if (item?.vendor_marketplace?.id === item1?.marketplace) {
                item.vendor_marketplace.Vendor = item1;
              } else return item1;
            }),
          );
        }

        // Add Seller marketplaces in in finalSecondaryMarketplace from localstorage if present

        if (localSeller?.length) {
          let tempSeller = finalSecondaryMarketplace?.map((item) =>
            localSeller?.map((item1) => {
              if (item?.seller_marketplace?.id === item1?.marketplace) {
                item.seller_marketplace.Seller = item1;
              } else return item1;
            }),
          );
        }

        setSellerMarketplaces([...sellerData]);
        setVendorMarketplaces([...vendorData]);
        setSecondaryMarketplaces([...finalSecondaryMarketplace]);

        getVideoLink(localStorage.getItem('customer') || id, 'hybrid').then(
          (res) => {
            setVideoData(res.data);
          },
        );
        setIsLoading({ loader: false, type: 'page' });
      }
    });
  };

  const addSellerID = () => {
    const secondarySellerData = secondaryMarketplaces.map((entry) => {
      const sellerFields = {
        advertiser_name: entry?.seller_marketplace?.Seller?.doesnt_advertise
          ? ' '
          : entry?.seller_marketplace?.Seller?.advertiser_name,
        advertiser_id: entry?.seller_marketplace?.Seller?.doesnt_advertise
          ? ' '
          : entry?.seller_marketplace?.Seller?.advertiser_id,
        doesnt_advertise:
          entry?.seller_marketplace?.Seller?.doesnt_advertise ?? false,
        type: entry?.seller_marketplace?.account_type,
        marketplace: entry?.seller_marketplace?.id,
        marketplace_name: entry?.seller_marketplace?.name?.value || '',
        customer: localStorage.getItem('customer'),
      };

      if (entry?.seller_marketplace?.Seller?.id) {
        sellerFields.id = entry?.seller_marketplace?.Seller?.id;
      }
      return sellerFields;
    });

    const sortedSellerData = secondarySellerData.filter(
      (entry) => entry.type === 'Seller',
    );

    const primarySellerData = [
      {
        seller_central_name: marketplaceDetails?.Seller?.seller_central_name,
        merchant_id: marketplaceDetails?.Seller?.merchant_id,
        advertiser_name: marketplaceDetails?.Seller?.advertiser_name,
        advertiser_id: marketplaceDetails?.Seller?.advertiser_id,
        marketplace: marketplaceDetails?.marketplaceId,
        marketplace_name: marketplaceDetails?.name?.value || '',
        customer: localStorage.getItem('customer'),
      },
    ];

    if (marketplaceDetails?.Seller?.id) {
      primarySellerData[0].id = marketplaceDetails?.Seller?.id;
    }

    let sellerData = [...sortedSellerData];
    if (marketplaceDetails?.type) {
      sellerData = [...sortedSellerData, ...primarySellerData];
    }
    setSellerMarketplaces([...sellerData]);
  };

  const addVendorID = () => {
    const secondaryVendorData = secondaryMarketplaces.map((entry) => {
      const vendorFields = {
        advertiser_name: entry?.vendor_marketplace?.Vendor?.doesnt_advertise
          ? ' '
          : entry?.vendor_marketplace?.Vendor?.advertiser_name,
        advertiser_id: entry?.vendor_marketplace?.Vendor?.doesnt_advertise
          ? ' '
          : entry?.vendor_marketplace?.Vendor?.advertiser_id,
        doesnt_advertise:
          entry?.vendor_marketplace?.Vendor?.doesnt_advertise ?? false,
        type: entry?.vendor_marketplace?.account_type,
        marketplace: entry?.vendor_marketplace?.id,
        marketplace_name: entry?.vendor_marketplace?.name?.value || '',
        customer: localStorage.getItem('customer'),
      };

      if (entry?.vendor_marketplace?.Vendor?.id) {
        vendorFields.id = entry?.vendor_marketplace?.Vendor?.id;
      }
      return vendorFields;
    });

    const sortedVendorData = secondaryVendorData.filter(
      (entry) => entry.type === 'Vendor',
    );

    const primaryVendorData = [
      {
        vendor_central_name: marketplaceDetails?.Vendor?.vendor_central_name,
        vendor_code: marketplaceDetails?.Vendor?.vendor_code,
        advertiser_name: marketplaceDetails?.Vendor?.advertiser_name,
        advertiser_id: marketplaceDetails?.Vendor?.advertiser_id,
        marketplace: marketplaceDetails?.marketplaceId,
        marketplace_name: marketplaceDetails?.name?.value || '',
        customer: localStorage.getItem('customer'),
      },
    ];

    if (marketplaceDetails?.Vendor?.id) {
      primaryVendorData[0].id = marketplaceDetails?.Vendor?.id;
    }

    let vendorData = [...sortedVendorData];

    if (marketplaceDetails?.type) {
      vendorData = [...sortedVendorData, ...primaryVendorData];
    }

    setVendorMarketplaces([...vendorData]);
  };

  const handleUpdate = () => {
    switch (marketplaceDetails?.type) {
      case 'Seller':
        addSellerID();
        break;
      case 'Vendor':
        addVendorID();
        break;
      case 'Hybrid':
        addSellerID();
        addVendorID();
        break;
      case null:
        addSellerID();
        addVendorID();
        break;
      default:
        break;
    }
  };

  const getOnboardingStepData = (customerId) => {
    if (
      history.location.pathname.includes(PATH_UNAUTHORIZED_COMPANY_DETAILS) ||
      history.location.pathname.includes(PATH_COMPANY_DETAILS)
    ) {
      dispatch(getCustomerDetails(customerId));
    } else if (
      history.location.pathname.includes(PATH_UNAUTHORIZED_BILLING_DETAILS) ||
      history.location.pathname.includes(PATH_BILLING_DETAILS)
    ) {
      getDSPContact(customerId).then((res) => {
        setshowDSPContact({
          show: res?.data?.is_dsp_contract,
          sameAsBilling: res?.data?.results?.[0]
            ? res?.data?.results?.[0]?.same_as_billing_contact
            : true,
        });
        setDspData(res?.data?.results?.[0]);
        getBillingDetails(customerId).then((response) => {
          if (response?.status === 200) {
            setBillingData(response?.data);
          }
          if (response?.status === 404) {
            setBillingData({});
          }
        });
      });
    } else if (
      history.location.pathname.includes(PATH_UNAUTHORIZED_AMAZON_MERCHANT) ||
      history.location.pathname.includes(PATH_AMAZON_MERCHANT)
    ) {
      getAccountDetails(customerId);
    } else if (
      history.location.pathname.includes(PATH_UNAUTHORIZED_AMAZON_ACCOUNT) ||
      history.location.pathname.includes(PATH_AMAZON_ACCOUNT)
    ) {
      getAmazonAccount(customerId).then((response) => {
        if (response?.status === 200) {
          setAmazonAccountData(response?.data?.results?.[0]);
        }
      });
    }
  };

  const summaryDetails = (onboardingId) => {
    accountSummary(onboardingId).then((summary) => {
      if (summary?.status === 200) {
        const fields = [];
        stepPath.map((item) => {
          if (summary && summary.data) {
            fields.push({
              [item.key]: summary.data.some((op) => {
                return op.step === item.key ? op.is_completed : false;
              }),
            });
          }
          return '';
        });
        setSummaryData(fields);
        setIsLoading({ loader: false, type: 'page' });
      }
    });
  };

  const getStepsData = (onboardingId, type) => {
    getStepDetails(onboardingId, getStepName()).then((response) => {
      if (response?.data?.results?.[0]) {
        setStepData(response.data.results[0]);
        if (response.data.results[0]?.step === getStepName()) {
          setIsChecked(true);
        }
        if (type === 'callSummary') summaryDetails(onboardingId);
      } else setIsLoading({ loader: false, type: 'page' });
    });
  };

  useEffect(() => {
    if (
      (params && params.openCollapse === null) ||
      (params && params.customer && params.step)
    ) {
      setOpenCollapse(true);
    }
    setIsLoading({ loader: true, type: 'page' });

    if (!history.location.pathname.includes(PATH_CREATE_PASSWORD)) {
      if (
        history.location.pathname.includes(PATH_ACCOUNT_SETUP) &&
        (params?.key ||
          (history.location.pathname.includes(
            PATH_UNAUTHORIZED_AMAZON_MERCHANT,
          ) &&
            params?.spapi_oauth_code))
      ) {
        if (params?.key) localStorage.setItem('match', params.key);
        verifyStepToken(params.key || localStorage.getItem('match')).then(
          (verify) => {
            if (verify && verify.status === 401) {
              const stringified = queryString.stringify({
                step: 'completed',
              });
              history.push({
                pathname: PATH_THANKS,
                search: `${stringified}`,
              });
            }
            localStorage.setItem(
              'customer',
              verify?.data?.customer_id || userInfo?.customer,
            );
            getStepsData(
              verify?.data?.customer_onboarding_id ||
                userInfo?.customer_onboarding,
            );
            getOnboardingStepData(
              verify?.data?.customer_id || userInfo?.customer,
            );
            setVerifiedStepData(verify?.data);
          },
        );
      }

      if (history?.location?.pathname.includes('assigned')) {
        setAssignedToSomeone(true);
      } else {
        getUserData(localStorage?.getItem('customer')).then((res) => {
          if (res?.status === 200) {
            if (params?.customer && params?.step) {
              if (res?.data?.step[params?.customer] === 6) {
                history.push(
                  PATH_CUSTOMER_DETAILS.replace(':id', params.customer),
                );
              }
            }
            setUserInfo(res && res.data);
            getStepsData(
              res?.data?.customer_onboarding ||
                verifiedStepData?.customer_onboarding_id,
              'callSummary',
            );
            summaryDetails(
              res?.data?.customer_onboarding ||
                verifiedStepData?.customer_onboarding_id,
            );
            getOnboardingStepData(
              res?.data?.customer ||
                verifiedStepData?.customer_id ||
                userInfo?.customer,
            );
          } else {
            if (res?.status === 401) {
              let stringified = '';
              if (history?.location?.pathname !== '/') {
                stringified = queryString.stringify({
                  callback:
                    history?.location?.pathname + history?.location?.search,
                });
                history.push({
                  pathname: PATH_LOGIN,
                  search: `${stringified}`,
                });
              }
            }
            setIsLoading({ loader: false, type: 'page' });
          }
        });
        setAssignedToSomeone(false);
      }
    }
  }, [history.location.pathname]);

  const generateHTML = (path) => {
    if (path === 'company-details')
      return (
        <CompanyDigital
          setIsLoading={setIsLoading}
          assignedToSomeone={assignedToSomeone}
          stepData={stepData}
          verifiedStepData={verifiedStepData}
          userInfo={userInfo}
          data={data}
          isLoading={isLoading}
          isChecked={isChecked}
          loader={loader}
          summaryData={summaryData}
        />
      );
    if (path === 'billing-details')
      return (
        <BillingInfo
          setIsLoading={setIsLoading}
          assignedToSomeone={assignedToSomeone}
          stepData={stepData}
          verifiedStepData={verifiedStepData}
          userInfo={userInfo}
          data={billingData}
          isLoading={isLoading}
          isChecked={isChecked}
          summaryData={summaryData}
          summaryDetails={summaryDetails}
          showDSPContact={showDSPContact}
          dspContactDetail={dspData}
          setshowDSPContact={setshowDSPContact}
        />
      );
    if (path === 'amazon-merchant')
      return (
        <AmazonMerchant
          setIsLoading={setIsLoading}
          assignedToSomeone={assignedToSomeone}
          stepData={stepData}
          verifiedStepData={verifiedStepData}
          userInfo={userInfo}
          isLoading={isLoading}
          isChecked={isChecked}
          noAmazonAccount={noAmazonAccount}
          marketplaceDetails={marketplaceDetails}
          showVideo={showAmazonVideo}
          videoData={videoData}
          setShowVideo={setShowAmazonVideo}
          setNoAmazonAccount={setNoAmazonAccount}
          setApiError={setApiError}
          apiError={apiError}
          setMarketplaceDetails={setMarketplaceDetails}
          setFormData={setFormData}
          formData={formData}
          secondaryMarketplaces={secondaryMarketplaces}
          sellerMarketplaces={sellerMarketplaces}
          setSellerMarketplaces={setSellerMarketplaces}
          vendorMarketplaces={vendorMarketplaces}
          setVendorMarketplaces={setVendorMarketplaces}
          history={history}
          localSeller={localSeller}
          localVendor={localVendor}
          summaryData={summaryData}
          localNoAmazonAccount={localNoAmazonAccount}
          getAccountDetails={getAccountDetails}
        />
      );
    if (path === 'amazon-account')
      return (
        <AmazonDeveloperAccess
          setIsLoading={setIsLoading}
          assignedToSomeone={assignedToSomeone}
          stepData={stepData}
          verifiedStepData={verifiedStepData}
          userInfo={userInfo}
          isLoading={isLoading}
          isChecked={isChecked}
          data={amazonAccountData}
        />
      );
    return '';
  };

  const getVideo = () => {
    setIsLoading({ loader: true, type: 'video ' });
    setShowVideo(true);
    getVideoLink(
      verifiedStepData?.customer_id || userInfo?.customer,
      history?.location?.pathname.includes(PATH_COMPANY_DETAILS) ||
        history?.location?.pathname.includes(PATH_UNAUTHORIZED_COMPANY_DETAILS)
        ? 4
        : 2,
    ).then((response) => {
      setVideoData(response?.data);
      setIsLoading({ loader: false, type: 'video ' });
    });
  };

  const getInformation = (item) => {
    if (item.key === 'billing information')
      return `has asked that you provide your companies <strong> ${item.title}</strong>, which will be used by our Finance Team to bill for our services.`;
    if (item.key === 'merchant id')
      return `has asked that you provide <strong> ${item.title} </strong> information, which will be used to access data we will use to best manage your account.`;
    return `has asked that you provide <strong>${item.title}</strong> information, which will be used by our Creative Team to best understand your brand.`;
  };

  const showNoAmazonAccountCheckbox = () => {
    return (
      <CheckBox
        className={
          isLoading?.loader && isLoading?.type === 'check'
            ? ' mb-4 isDisabled'
            : ' mb-4'
        }
      >
        <label
          className="check-container customer-pannel "
          htmlFor="no_amazon_account"
        >
          I don&apos;t have an Amazon account yet
          <input
            type="checkbox"
            id="no_amazon_account"
            name="no_amazon_account"
            onChange={() => {
              setNoAmazonAccount(!noAmazonAccount);
              handleUpdate();
              localStorage.setItem('noAmazonAccount', !noAmazonAccount);
              setLocalNoAmazonAccount({ noAmazonAccount: !noAmazonAccount });
            }}
            readOnly
            checked={
              Object.keys(localNoAmazonAccount)?.length
                ? localNoAmazonAccount?.noAmazonAccount
                : noAmazonAccount
            }
          />
          <span className="checkmark" />
        </label>
      </CheckBox>
    );
  };

  const showAmazonSubTitle = (type) => {
    if (marketplaceDetails?.type === 'Seller')
      return `If you don’t have ${
        type ? 'admin' : ''
      } access to your Amazon Seller Central account then you can use the checkbox below`;
    if (marketplaceDetails?.type === 'Vendor')
      return `If you don’t have ${
        type ? 'admin' : ''
      } access to your Amazon Vendor Central account then you can use the checkbox below`;
    return `If you don’t have ${
      type ? 'admin' : ''
    } access to your Amazon Seller Central and Vender Central accounts then you can use the checkbox below`;
  };

  const generateHeader = (item) => {
    if (history?.location?.pathname.includes(item?.path))
      return (
        <>
          {assignedToSomeone ? (
            <UnauthorizedHeader />
          ) : (
            <Header type="onboarding" userInfo={userInfo} />
          )}
          {assignedToSomeone ? (
            ''
          ) : (
            <NavigationHeader
              bar={item.bar}
              skipStep={item?.skip}
              backStep={item?.back}
              showSuccessMsg={item?.path === 'summary'}
              stepData={stepData}
              verifiedStepData={verifiedStepData}
              stepName={getStepName()}
              userInfo={userInfo}
            />
          )}

          {loader || (isLoading?.loader && isLoading?.type === 'page') ? (
            <PageLoader color="#FF5933" type="page" />
          ) : (
            <OnBoardingBody className="body-start">
              <div className="white-card-base panel ">
                {assignedToSomeone ? (
                  <GreyCard className="yellow-card mt-2 mb-4">
                    <div className="hi-name mb-2">
                      {' '}
                      <span className="video-link ">
                        {' '}
                        {verifiedStepData?.user_email}{' '}
                      </span>
                      <span
                        style={{ color: 'black' }}
                        dangerouslySetInnerHTML={{
                          __html: getInformation(item),
                        }}
                      />
                    </div>
                    {delegatedInfo}
                  </GreyCard>
                ) : (
                  <p className="account-steps m-0">Step {item.stepof} of 6</p>
                )}
                <h3 className="page-heading ">{item.title}</h3>
                {item.path === 'billing-details' ? null : (
                  <p className="info-text-gray mt-0 mb-4 ">
                    {history.location.pathname.includes(
                      PATH_AMAZON_MERCHANT,
                    ) ? (
                      <>
                        {showAmazonSubTitle(false)} to assign this step to
                        someone that does.
                      </>
                    ) : history.location.pathname.includes(
                        PATH_UNAUTHORIZED_AMAZON_MERCHANT,
                      ) ? (
                      <>{showAmazonSubTitle(false)}.</>
                    ) : history.location.pathname.includes(
                        PATH_AMAZON_ACCOUNT,
                      ) ? (
                      <>
                        {showAmazonSubTitle(true)} to assign this step to
                        someone that does.
                      </>
                    ) : history.location.pathname.includes(
                        PATH_UNAUTHORIZED_AMAZON_ACCOUNT,
                      ) ? (
                      <>{showAmazonSubTitle(true)}.</>
                    ) : (
                      item.subTitle
                    )}{' '}
                    <br />
                    {item.video ? (
                      <>
                        <img
                          style={customVideoStyle}
                          src={VideoCall}
                          alt="video"
                        />
                        <span
                          className="video-link cursor"
                          onClick={() => getVideo()}
                          role="presentation"
                        >
                          Click here to watch the video.
                        </span>
                      </>
                    ) : null}
                  </p>
                )}

                {assignedToSomeone ? (
                  ''
                ) : (
                  <AskSomeone
                    setIsChecked={setIsChecked}
                    isChecked={isChecked}
                    step={item.key}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    params={verifiedStepData}
                    stepData={stepData}
                    setStepData={setStepData}
                    userInfo={userInfo}
                    setDisableBtn={setDisableBtn}
                    setOpenCollapse={setOpenCollapse}
                    history={history}
                    noAmazonAccount={noAmazonAccount}
                    accountType={marketplaceDetails.type}
                  />
                )}
                {(!isChecked &&
                  history?.location?.pathname.includes(PATH_AMAZON_MERCHANT)) ||
                history?.location?.pathname?.includes(
                  PATH_UNAUTHORIZED_AMAZON_MERCHANT,
                ) ? (
                  <>{showNoAmazonAccountCheckbox()}</>
                ) : (
                  ''
                )}
                {assignedToSomeone || !isChecked ? (
                  <>{generateHTML(item?.path)}</>
                ) : (
                  ''
                )}
              </div>

              {!assignedToSomeone && isChecked ? (
                <div className="white-card-base panel gap-none">
                  <div
                    className="label-title cursor mt-4"
                    type="button"
                    role="presentation"
                    onClick={() => setOpenCollapse(!openCollapse)}
                  >
                    Expand questions
                    <img
                      className="arrow-up"
                      src={CaretUp}
                      alt="arrow"
                      style={{
                        transform: openCollapse ? 'rotate(180deg)' : '',
                      }}
                    />
                    <div className="clear-fix" />
                  </div>
                  <CollapseContainer>
                    <Collapse isOpened={openCollapse}>
                      {history?.location?.pathname.includes(
                        PATH_AMAZON_MERCHANT,
                      ) ? (
                        <div className="mt-3">
                          {showNoAmazonAccountCheckbox()}
                        </div>
                      ) : (
                        ''
                      )}
                      {generateHTML(item?.path)}
                    </Collapse>
                  </CollapseContainer>
                  <CheckSteps
                    summaryData={summaryData}
                    step={item?.key}
                    disableBtn={disableBtn}
                  />
                </div>
              ) : (
                ''
              )}
            </OnBoardingBody>
          )}
          <VideoModal
            showVideo={showVideo}
            setShowVideo={setShowVideo}
            isLoading={isLoading}
            videoData={videoData}
            history={history}
          />
        </>
      );
    return '';
  };

  return (
    <>
      {history?.location?.pathname.includes('create-password') ? (
        <CreateAccount />
      ) : (
        <>
          {whichStep.map((item) => (
            <React.Fragment key={item?.key}>
              {generateHeader(item)}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}
