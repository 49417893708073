import styled from 'styled-components';

import Theme from '../theme/Theme';

const DropDownSelectMonthPicker = styled.div`
  .dropdown-select-container {
    background-color: rgba(224, 231, 255, 0.2);
    border: 1px solid ${Theme.gray2};
    border-radius: 20px;
    width: 230px;
    height: 40px;
    color: ${Theme.black};
    padding: 11px 2px 0 14px;
    text-align: left;
    cursor: pointer;
    position: relative;

    @media only screen and (max-width: 450px) {
      width: 160px;
    }
    .dropdown-icon {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }

  .month-picker-dropdown {
    background-color: ${Theme.white};
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    position: absolute;
    right: 15px;
    z-index: 22;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
    top: 45px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      text-align: left;
      .date-range {
        display: inline-block;
        text-align: left;
        margin-right: 20px;
      }
    }
    .cross-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 16px;
    }
    @media only screen and (max-width: 500px) {
      width: 300px;
    }
  }
`;

export default DropDownSelectMonthPicker;
