const commonLog = {
  dspSignOfflog: [
    'submitted a standard invoice request for',
    'canceled a standard invoice request for',
    'submitted a one-time invoice request for',
    'canceled a one-time invoice request for',
    'submitted a pause request for',
    'canceled a pause request for',
    'has approved',
    'has paused',
    'has approved pausing',
    'has rejected',
    'has rejected pausing',
  ],
  cancelPauseAggrementLog: [
    'submitted a cancellation request for',
    'submitted a pausing request for',
    'approved a cancellation request for',
    'rejected a cancellation request for',
    'approved a pausing request for',
    'rejected a pausing request for',
  ],
  customizedReportLog: ['created', 'downloaded', 'viewed', 'updated'],
};

export default commonLog;
