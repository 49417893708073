import styled from 'styled-components';
import Theme from '../../theme/Theme';

const ToggleSwitch = styled.div`
  &.custom-switch {
    position: relative;
    display: block;
    border-radius: 10px;

    &.marginTop10 {
      margin-top: 10px;
    }

    .label-name {
      color: #171725;
      font-size: 14px;
      padding-left: 35px;
    }
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${Theme.orange};
      -webkit-transition: 0.4s;
      transition: 0.4s;
      height: 16px;
      width: 28px;
      opacity: 0.4;

      &:before {
        position: absolute;
        content: '';
        height: 12px;
        width: 12px;
        left: 1px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    input:checked + .slider {
      background-color: ${Theme.orange};
      opacity: 1;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px ${Theme.orange};
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(13px);
      -ms-transform: translateX(13px);
      transform: translateX(13px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
`;
export default ToggleSwitch;
