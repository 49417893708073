// react imports
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

// third party imports
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';

// local file imports
import Theme from '../../../theme/Theme';
import DspDashboardContainer from './DspDashboard/DspDashboardContainer';
import SalesDashboardContainer from './SalesDashboard/SalesDashboardContainer';
import InventoryDashboardContainer from './InventoryDashboard/InventoryDashboardContainer';
import SponsoredDashboardContainer from './SponsoredDashboard/SponsoredDashboardContainer';
import AMCAggregatedDashboardContainer from './AMCDashboard/AMCAggregatedDashboardContainer';
import PendingApprovalsDashboardContainer from './PendingApprovalsDashboard/PendingApprovalsDashboardContainer';
import { getPendingApprovalRequests } from '../../../api/PendingApprovalsDashboardApi';
import { DashboardCard, TabletViewManager } from '../../../theme/Global';
import { getMarketPlaceList, getGoalTargetInsightMsg } from '../../../api';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  AuthorizedSC,
  AuthorizedVC,
  CompanyDefaultUser,
  UnauthorizedSC,
  UnauthorizedVC,
  UpDowGrayArrow,
} from '../../../theme/images';
import {
  GrayMessageBar,
  Status,
  Table,
  Tabs,
  ToggleSwitch,
} from '../../../common';
import {
  advertisingDefaultTab,
  allBPObj,
  allValueObj,
  contributionColorSet,
  contributionTableHeaderByTab,
  CUSTOM,
  dashboardRolePaths,
  DSPPERFORMANCE,
  HYBRID,
  PATH_CUSTOMER_DETAILS,
  pendingApprovalAccessEmails,
  pendingApprovalOrderOptions,
  performanceTypeOptions,
  SELLER,
  SELLERSMALL,
  sponoredMetricsList,
  SpreetailRole,
  VENDOR,
  VENDORSMALL,
} from '../../../constants';

export default function AdvertisingDashboardContainer({ userInfo }) {
  const mounted = useRef(false);
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isBBEInternalUser = useSelector(
    (state) => state.userState.userInfo.isInternalUser,
  );
  const isBBEInternalUserStatus = isBBEInternalUser ? 'internal' : 'external';

  /* ********** BGS User hierarchy is BGS Admin -> BGS Manager -> BGS ********** */
  /* **********
  BGS Admin is redirected to BGS Leadership Dashboard and has access to view bgs managers and bgs performance data. BGS Manager is redirected to BGS Manager Dashboard and has access to view bgs performance data. BGS is redirected to BGS Dashboard and has access to view only his own performance data.
  ********** */

  /* ********** Advertising User hierarchy is Ad Manager Admin -> Ad Team Manager (ad_leadership_access === false) -> Ad Manager  ********** */
  /* ********** 
  Ad Manager Admin is redirected to Advertising Leadership Dashboard and has access to view Ad Team managers and Ad Managers performance data. Ad Team managers is redirected to Ad Team Managers Dashboard and has access to view Ad Managers performance data. But if Ad Team Manager has ad_leadership_access === true then he will be redirected to Advertising Leadership Dashboard. Ad Managers is redirected to Ad Managers Dashboard and has access to view only his own performance data.
  ********** */

  // bgs roles
  const isBGSAdmin = userInfo?.role === 'BGS Admin';
  const isSpreetailRole = userInfo?.role === SpreetailRole;
  const isBGSManager = userInfo?.role === 'BGS Manager';
  const isBGS = userInfo?.role === 'BGS';

  // advertising roles
  const advertisingAdminAccess = userInfo?.ad_leadership_access;
  // If user role is Ad Team Manager and this flag -> ad_leadership_access is true in Django admin Then such user can access to Ad Manager Admin Dashboard i.e. "Advertising Leadership Dashboard".
  const isAdManagerAdmin =
    userInfo?.role === 'Ad Manager Admin' ||
    (userInfo?.role === 'Ad Team Manager' && advertisingAdminAccess);
  const isAdTeamManager =
    userInfo?.role === 'Ad Team Manager' && !advertisingAdminAccess;
  const isAdManager =
    userInfo?.role === 'Ad Manager' || userInfo?.role === 'Ad Specialist';

  const isShowAMCAudienceSummaryTab =
    isBGSAdmin ||
    isBGSManager ||
    isBGS ||
    isAdManager ||
    isSpreetailRole ||
    userInfo?.role === 'Ad Manager Admin' ||
    userInfo?.role === 'Ad Team Manager';

  const showInventoryTab =
    isBGSAdmin || isBGSManager || isBGS || isSpreetailRole;

  const isHavePendingApprovalAccess =
    isBGSAdmin ||
    pendingApprovalAccessEmails.includes(userInfo?.email) ||
    userInfo?.is_primary_request_approvers ||
    userInfo?.is_final_request_approvers ||
    userInfo?.is_dsp_amc_primary_request_approvers;

  // https://bbe.atlassian.net/browse/PDV-9459 - Stop pulling Seller and Vendor data via Amazon APIs
  const advertisingTab =
    advertisingDefaultTab[window.location.pathname?.split('/').pop()] ||
    'sales';
  const isSalesOrSponsoredTab =
    advertisingTab === 'sales' || advertisingTab === 'sponsored';
  const [viewComponent, setViewComponent] = useState(advertisingTab);

  const [pendingApprovalListDataCount, setPendingApprovalListDataCount] =
    useState(0);
  const [callPendingApprovalAPI, setCallPendingApprovalAPI] = useState(true);
  const [marketplaceChoices, setMarketplaceChoices] = useState([]);
  const [goalTargetInsightMsg, setGoalTargetInsightMsg] = useState('');
  const [includeInactiveBPs, setIncludeInactiveBPs] = useState(false);
  const [includeSpreetailBrands, setIncludeSpreetailBrands] = useState(
    !!isSalesOrSponsoredTab,
  );
  const [responseID, setResponseID] = useState();
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [isCustomDateApply, setIsCustomDateApply] = useState(false);
  const [selectedMarketplace, setSelectedMarketplace] = useState({
    value: 'all',
    label: 'All Marketplaces',
    currency: 'USD',
  });
  const [selectedPerformanceType, setSelectedPerformanceType] = useState(
    performanceTypeOptions?.[0],
  );
  const [selectedBrandPartner, setSelectedBrandPartner] = useState(allBPObj);
  const [sponsoredAdMultiSelected, setSponsoredAdMultiSelected] = useState(
    sponoredMetricsList.slice(0, 8),
  );
  const [selectedDF, setSelectedDF] = useState({
    value: '30days',
    label: 'Current 30 Days',
    sub: 'vs Previous 30 days',
  });

  // loggedin user
  const loggedInUser = useMemo(() => {
    return [
      {
        label: `${userInfo?.first_name} ${userInfo?.last_name}`,
        value: userInfo?.id,
      },
    ];
  }, [userInfo]);

  // useState - selected manager user is used for BGS Manager and Ad Team Manager
  const [selectedManagerUser, setSelectedManagerUser] = useState(
    isBGSManager || isAdTeamManager ? loggedInUser : allValueObj,
  );
  // useState - selected user is used for BGS  and Ad Manager
  const [selectedUser, setSelectedUser] = useState(
    isBGS || isAdManager ? loggedInUser : allValueObj,
  );

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 2);
  const [customDateState, setCustomDateState] = useState([
    {
      startDate: currentDate,
      endDate: currentDate,
      key: 'adSelection',
    },
  ]);
  const [customDate, setCustomDate] = useState({
    startDate: null,
    endDate: null,
    dateType: null,
  });

  // bind all marketplaces
  const bindAllMarketplaces = useCallback((responseData) => {
    const tempMarketplacesList = [];

    if (responseData?.length > 0) {
      for (const item of responseData) {
        tempMarketplacesList.push({
          value: item?.name || item?.value,
          label: item?.country || item?.label,
          currency: item?.currency,
          id: item?.marketplace_id || item?.id,
        });
      }
    }

    if (tempMarketplacesList?.length > 1) {
      tempMarketplacesList.unshift({
        value: 'all',
        label: 'All Marketplaces',
        currency: 'USD',
      });
    }
    return tempMarketplacesList;
  }, []);

  // get all marketplaces
  const getMarketPlace = useCallback(() => {
    getMarketPlaceList().then((response) => {
      if (mounted.current) {
        if (response?.status === 200) {
          if (response?.data?.length) {
            const formatedApiData = bindAllMarketplaces(response?.data);
            setMarketplaceChoices(formatedApiData);
            setSelectedMarketplace(formatedApiData?.[0]);
          }
        }
      }
    });
  }, [bindAllMarketplaces]);

  // use effect
  useEffect(() => {
    mounted.current = true;
    getMarketPlace();
    return () => {
      mounted.current = false;
    };
  }, [getMarketPlace]);

  // https://bbe.atlassian.net/browse/PDV-8757 - leadership dashboard improvements
  // callback function for binding brand partners list from api response data
  const bindBrandPartners = useCallback(
    (responseData) => {
      const tempBrandPartnerList = [];

      if (responseData?.length > 0) {
        for (const item of responseData) {
          tempBrandPartnerList.push({
            value: item?.id,
            label: item?.company_name,
            customerId: item?.id,
            accountType: item?.customer_account_type,
            marketplaces: bindAllMarketplaces(item?.marketplaces),
            adManager: item?.ad_manager
              ? {
                  label: `${item?.ad_manager?.first_name} ${item?.ad_manager?.last_name}`,
                  value: item?.ad_manager?.id,
                }
              : null,
            adTeamManager: item?.ad_team_manager
              ? {
                  label: `${item?.ad_team_manager?.first_name} ${item?.ad_team_manager?.last_name}`,
                  value: item?.ad_team_manager?.id,
                }
              : null,
            bgs: item?.bgs
              ? {
                  label: `${item?.bgs?.first_name} ${item?.bgs?.last_name}`,
                  value: item?.bgs?.id,
                }
              : null,
            bgsManager: item?.bgs_manager
              ? {
                  label: `${item?.bgs_manager?.first_name} ${item?.bgs_manager?.last_name}`,
                  value: item?.bgs_manager?.id,
                }
              : null,
          });
        }
      }

      if (tempBrandPartnerList?.length > 1) {
        tempBrandPartnerList.unshift(allBPObj);
      }
      return tempBrandPartnerList;
    },
    [bindAllMarketplaces],
  );

  const getGoalTargetMsg = useCallback(() => {
    getGoalTargetInsightMsg().then((res) => {
      setGoalTargetInsightMsg(res?.data?.message);
    });
  }, []);

  useEffect(() => {
    getGoalTargetMsg();
  }, [getGoalTargetMsg]);

  const dashboardHeaders = {
    'Ad Manager': 'Ad Manager Dashboard',
    'Ad Manager Admin': 'Advertising Leadership Dashboard',
    'Ad Team Manager': isAdTeamManager
      ? 'Ad Team Manager Dashboard'
      : 'Advertising Leadership Dashboard',
    'Ad Specialist': 'Ad Specialist Dashboard',
    BGS: 'BGS Dashboard',
    'BGS Manager': 'BGS Manager Dashboard',
    'BGS Admin': 'BGS Leadership Dashboard',
    Finance: 'Dashboard',
    Spreetail: 'BGS Leadership Dashboard',
  };

  // to disaply the pending aprroval count need to call API
  const getPendingApprovalData = useCallback(() => {
    getPendingApprovalRequests(pendingApprovalOrderOptions[0]?.value, '', 1)
      .then((res) => {
        if (mounted.current) {
          if (res?.status === 200) {
            setPendingApprovalListDataCount(res?.data?.count || 0);
          } else {
            setPendingApprovalListDataCount(0);
          }
          setCallPendingApprovalAPI(false);
        }
      })
      .catch(() => {
        setPendingApprovalListDataCount(0);
      });
  }, []);

  useEffect(() => {
    if (isHavePendingApprovalAccess && callPendingApprovalAPI) {
      mounted.current = true;
      getPendingApprovalData();
    }
    return () => {
      mounted.current = false;
    };
  }, [
    getPendingApprovalData,
    isHavePendingApprovalAccess,
    callPendingApprovalAPI,
  ]);

  // bind user list - bgs/bgs manager/ad manager/ad team manager data
  const bindUserListData = useCallback((responseData, userRole) => {
    const tempUserList = [];
    let tempUserListWithAll = [];

    if (responseData?.length > 0) {
      for (const item of responseData) {
        if (userRole === 'BGS' || userRole === 'Ad Manager') {
          tempUserList.push({
            label: `${item?.first_name} ${item?.last_name}`,
            value: item?.id,
            icon:
              item?.documents?.[0] && Object.values(item?.documents?.[0])[0],
            managerUser:
              userRole === 'BGS' ? item?.bgs_manager : item?.ad_team_manager,
          });
        } else {
          tempUserList.push({
            label: `${item?.first_name} ${item?.last_name}`,
            value: item?.id,
            icon:
              item?.documents?.[0] && Object.values(item?.documents?.[0])[0],
          });
        }
      }
      tempUserListWithAll = [...tempUserList];

      if (tempUserList?.length > 1) {
        tempUserListWithAll.unshift({
          value: 'all',
          label: 'All',
        });
      }
    }

    return { userList: tempUserList, userListWithAll: tempUserListWithAll };
  }, []);

  // check if multiple selected user present in api user list or not.
  const checkIsUserExist = useCallback((userListArray, selectedUserArray) => {
    let isUserExist = false;
    const isUserAll = selectedUserArray?.some((item) => item?.value === 'all');
    if (isUserAll) {
      isUserExist = false;
    } else {
      const filterUserList = userListArray?.filter((object) => {
        return selectedUserArray?.find((item) => item?.value === object?.value);
      });
      if (filterUserList?.length === selectedUserArray?.length) {
        isUserExist = true;
      } else {
        isUserExist = false;
      }
    }
    return isUserExist;
  }, []);

  // generate string of multiple values
  const generateStringOfValues = useCallback((dataArray) => {
    let tempDataString = null;
    const tempSelectedValues = [];

    if (dataArray?.length > 0) {
      const isAllUser = dataArray?.some((item) => item?.value === 'all');
      if (isAllUser) {
        tempDataString = 'all';
      } else {
        dataArray.forEach((item) => tempSelectedValues.push(item?.value));
        tempDataString = tempSelectedValues?.toString();
      }
    }
    return tempDataString;
  }, []);

  // handle multi select dropdown filter events
  const handleMultiSelectDropdownFilterEvents = (
    event,
    action,
    selectedValues,
    apiOptions,
  ) => {
    let tempSelectedValues = [];
    const userAction = action?.action;
    const value = action?.option?.value;

    if (userAction === 'select-option') {
      if (value === 'all') {
        tempSelectedValues = [{ value: 'all', label: 'All' }, ...apiOptions];
      } else {
        tempSelectedValues =
          event?.length === apiOptions?.length
            ? [{ value: 'all', label: 'All' }, ...event]
            : event;
      }
    }
    if (userAction === 'deselect-option') {
      if (value === 'all') {
        tempSelectedValues = [];
      } else {
        const optionList = selectedValues?.filter((option) => {
          return option?.value !== value && option?.value !== 'all';
        });
        tempSelectedValues = [...optionList];
      }
    }

    return tempSelectedValues;
  };

  // render include inactive bp toggle button
  const renderIncludeInactiveBPToggle = () => {
    return (
      <ToggleSwitch className="custom-switch" key="include-Inactive-BP-toggle">
        <label
          htmlFor="includeInactiveBPs"
          className="label-name"
          key="include-Inactive-BPs"
        >
          Include inactive BPs
          <input
            type="checkbox"
            className="label-name"
            id="includeInactiveBPs"
            onClick={() => {
              setIncludeInactiveBPs(!includeInactiveBPs);
              setResponseID('response');
            }}
            onChange={() => {}}
            checked={includeInactiveBPs}
          />
          <span className="slider round" />
        </label>
      </ToggleSwitch>
    );
  };

  // PDV-9081-  render include spreetail brands toggle button
  const renderIncludeSpreetailBrandsToggle = () => {
    return (
      <ToggleSwitch
        className="custom-switch"
        key="include-spreetail-brands-toggle"
      >
        <label
          htmlFor="spreetailBrancds"
          className={`label-name ${isSalesOrSponsoredTab ? 'disabled' : ''}`}
          key="include-spreetail-brands"
        >
          {isSalesOrSponsoredTab ? 'Only' : 'Include'} Spreetail Brands
          <input
            type="checkbox"
            className="label-name"
            id="spreetailBrancds"
            onClick={() => {
              if (isSalesOrSponsoredTab) {
                setIncludeSpreetailBrands(true);
              } else {
                setIncludeSpreetailBrands(!includeSpreetailBrands);
              }
              setResponseID('response');
            }}
            onChange={() => {}}
            checked={includeSpreetailBrands}
          />
          <span className="slider round" />
        </label>
      </ToggleSwitch>
    );
  };

  // display vendor metrics value
  const displayVendorMetricsValue = (value, prefix, suffix, decimalDigits) => {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return 'N/A';
    }
    return `${prefix}${value
      .toFixed(decimalDigits)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${suffix}`;
  };

  // display metrics change value with up and down arrow
  const displayMetricsChangeValueWithArrow = (
    value,
    prefix,
    suffix,
    decimalDigits,
    isGrayMetrics,
  ) => {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return 'N/A';
    }
    const positiveChange = !value?.toString().includes('-');

    return positiveChange ? (
      <div className={`increase-rate large ${isGrayMetrics ? 'grey' : ''}`}>
        <img
          className="green-arrow"
          src={isGrayMetrics ? UpDowGrayArrow : ArrowUpIcon}
          alt="arrow"
        />
        {displayVendorMetricsValue(value, prefix, suffix, decimalDigits)}
      </div>
    ) : (
      <div className={`decrease-rate large ${isGrayMetrics ? 'grey' : ''}`}>
        <img
          className="red-arrow"
          src={isGrayMetrics ? UpDowGrayArrow : ArrowDownIcon}
          alt="arrow"
        />
        {value === 0
          ? '0'
          : displayVendorMetricsValue(
              Number(value.toString().split('-')[1]),
              prefix,
              suffix,
              decimalDigits,
            )}
      </div>
    );
  };

  // redirecting to the respective dashboard at single bp view
  const redirectingToSingleBPView = (itemData, dashboardType) => {
    if (dashboardType === DSPPERFORMANCE) {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(
          ':id',
          itemData?.customerId,
        )}?tab=performance&nav=dspPerformance&access=${isBBEInternalUserStatus}`,
        DSPPERFORMANCE,
      );
    } else {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(
          ':id',
          itemData?.customerId,
        )}?tab=performance${
          itemData?.accountType
            ? `&sub=${
                itemData?.accountType === HYBRID ||
                itemData?.accountType === SELLER
                  ? SELLERSMALL
                  : VENDORSMALL
              }`
            : ''
        }&nav=${dashboardType}&access=${isBBEInternalUserStatus}`,
        dashboardType,
      );
    }
  };

  // display company logo
  const displayCompanyLogo = (imageLink) => {
    return (
      <img
        className="company-logo"
        src={imageLink || CompanyDefaultUser}
        alt="logo"
      />
    );
  };

  // https://bbe.atlassian.net/browse/PDV-7954
  // display Seller central / Vendor central Icon
  const displayScVcIcon = (imageLink, altName) => {
    return (
      <img
        src={imageLink}
        alt={altName}
        style={{ marginLeft: '10px', verticalAlign: 'middle' }}
      />
    );
  };

  // https://bbe.atlassian.net/browse/PDV-7954 - display seller central / vendor central type
  // display BP name, logo, status, account type, SC/VC icon
  const displayCustomerProfileWithUserName = (itemData) => {
    const accountType = itemData?.accountType;
    const showSellerTag = accountType === HYBRID || accountType === SELLER;
    const showVendorTag = accountType === HYBRID || accountType === VENDOR;

    // determine if BP has authorized or unauthorized marketplaces
    const lwa = itemData?.lwa;
    let isSellerUnauthorized = null;
    let isVendorUnauthorized = null;
    if (lwa) {
      isSellerUnauthorized = Object.values(lwa)
        ?.map((item) => item?.seller)
        ?.includes(false);
      isVendorUnauthorized = Object.values(lwa)
        ?.map((item) => item?.vendor)
        ?.includes(false);
    }
    return (
      <div>
        {displayCompanyLogo(itemData?.document || null)}
        <div className="company-name">
          {itemData?.customerName}

          {showSellerTag && isSellerUnauthorized !== null
            ? isSellerUnauthorized
              ? displayScVcIcon(UnauthorizedSC, 'UnauthorizedSC')
              : displayScVcIcon(AuthorizedSC, 'AuthorizedSC')
            : null}
          {showVendorTag && isVendorUnauthorized !== null
            ? isVendorUnauthorized
              ? displayScVcIcon(UnauthorizedVC, 'UnauthorizedVC')
              : displayScVcIcon(AuthorizedVC, 'AuthorizedVC')
            : null}
        </div>
        <div className="status">{itemData?.adManager}</div>
        <div className="clear-fix" />
      </div>
    );
  };

  // display vendor contribution table
  const displayContributionTable = (
    tableBodyData,
    prefix,
    suffix,
    decimalDigits,
    isGrayMetrics,
    dashboardType,
    showContributionColumn = selectedBrandPartner?.value === 'all',
  ) => {
    return isDesktop ? (
      <Table className="mt-0">
        <thead>
          <tr>
            {contributionTableHeaderByTab?.map((item) => {
              return item?.key === 'contribution' &&
                !showContributionColumn ? null : (
                <th
                  width={item?.width}
                  key={item?.key}
                  className="product-header"
                >
                  {item?.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableBodyData?.map((itemData) => {
            return (
              <tr
                key={itemData?.customerId}
                className="cursor"
                onClick={() => {
                  redirectingToSingleBPView(itemData, dashboardType);
                }}
              >
                <td className="product-body">
                  {displayCustomerProfileWithUserName(itemData)}
                </td>
                <td className="product-body">
                  {displayVendorMetricsValue(
                    itemData?.currentValue,
                    prefix,
                    suffix,
                    decimalDigits,
                  )}
                </td>
                <td className="product-body">
                  {displayVendorMetricsValue(
                    itemData?.previousValue,
                    prefix,
                    suffix,
                    decimalDigits,
                  )}
                </td>
                <td className="product-body">
                  {displayMetricsChangeValueWithArrow(
                    itemData?.change,
                    prefix,
                    suffix,
                    decimalDigits,
                    isGrayMetrics,
                  )}
                </td>

                {/* show contribution column only when all brand partner is selected */}
                {showContributionColumn ? (
                  <td className="product-body">
                    <Status
                      label={itemData?.contributionLevel}
                      backgroundColor={
                        contributionColorSet[itemData.contributionLevel]
                      }
                    />
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </Table>
    ) : (
      <TabletViewManager className="d-lg-none d-md-block d-sm-block">
        <div className="container-fluid">
          <div className="row cursor">
            {tableBodyData?.map((itemData) => (
              <div
                className="col-md-6 col-12 mt-4"
                role="presentation"
                key={itemData?.customerId}
                onClick={() => {
                  redirectingToSingleBPView(itemData, dashboardType);
                }}
              >
                {displayCustomerProfileWithUserName(itemData)}
                <div className="straight-line horizontal-line pt-3 mb-3" />
                <div className="row">
                  <div className="col-6 pb-3">
                    <div className="label">This Period</div>
                    <div className="label-info ">
                      {displayVendorMetricsValue(
                        itemData?.currentValue,
                        prefix,
                        suffix,
                        decimalDigits,
                      )}
                    </div>
                  </div>
                  <div className="col-6 pb-3">
                    <div className="label">Prev. Period</div>
                    <div className="label-info ">
                      {displayVendorMetricsValue(
                        itemData?.previousValue,
                        prefix,
                        suffix,
                        decimalDigits,
                      )}
                    </div>
                  </div>
                  <div className="col-6 pb-3">
                    <div className="label">Change</div>
                    {displayMetricsChangeValueWithArrow(
                      itemData?.change,
                      prefix,
                      suffix,
                      decimalDigits,
                      isGrayMetrics,
                    )}
                  </div>
                  {/* show contribution column only when all brand partner is selected */}
                  {showContributionColumn ? (
                    <div className="col-6 pb-3">
                      <div className="label">Contribution</div>
                      <Status
                        label={itemData?.contributionLevel}
                        backgroundColor={
                          contributionColorSet[itemData?.contributionLevel]
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </TabletViewManager>
    );
  };

  // bind seller and vendor contribution data
  const bindMetricsContributionData = useCallback((responseData) => {
    const tempContributionData = [];

    if (responseData?.length > 0) {
      for (const item of responseData) {
        tempContributionData.push({
          customerId: item?.customer_id,
          customerName: item?.customer_name,
          accountType: item?.account_type,
          document: item?.document,
          adManager: item?.ad_manager,
          currentValue: item?.current,
          previousValue: item?.previous,
          change: item?.change,
          contribution: item?.contribution,
          contributionLevel: item?.contribution_bracket,
        });
      }
    }
    return tempContributionData;
  }, []);

  // render recent and previous label above graph
  const renderRecentAndPreviousLabel = (selectedMetrics, dateType) => {
    const className =
      Object.keys(selectedMetrics)?.length === 1
        ? `adSales circle`
        : 'darkGray circle';

    return (
      <div>
        <ul className="rechart-item">
          <li>
            <div className="weeks">
              <span className={className} />
              <span>Recent</span>
            </div>
          </li>
          {dateType?.value !== CUSTOM ? (
            <li>
              <div className="weeks">
                <ul className="dashed-line">
                  <li className={className} />
                  <li className={className} />
                </ul>
                <span>Previous</span>
              </div>
            </li>
          ) : null}
        </ul>
      </div>
    );
  };

  // rendor allocate bar
  const renderAllocateBar = (message, className) => {
    return <GrayMessageBar message={message} className={className} />;
  };

  // render sales dashboard
  const renderSalesDashboard = () => {
    return (
      <SalesDashboardContainer
        // string
        responseID={responseID}
        currencySymbol={currencySymbol}
        // boolean
        isBGS={isBGS}
        isBGSAdmin={isBGSAdmin}
        isAdManager={isAdManager}
        isBGSManager={isBGSManager}
        isSpreetailRole={isSpreetailRole}
        isAdTeamManager={isAdTeamManager}
        isAdManagerAdmin={isAdManagerAdmin}
        isCustomDateApply={isCustomDateApply}
        includeInactiveBPs={includeInactiveBPs}
        includeSpreetailBrands={includeSpreetailBrands}
        // object
        userInfo={userInfo}
        customDate={customDate}
        selectedSalesDF={selectedDF}
        selectedMarketplace={selectedMarketplace}
        selectedBrandPartner={selectedBrandPartner}
        selectedPerformanceType={selectedPerformanceType}
        // array
        loggedInUser={loggedInUser}
        selectedUser={selectedUser}
        customDateState={customDateState}
        marketplaceChoices={marketplaceChoices}
        selectedManagerUser={selectedManagerUser}
        // function
        setResponseID={setResponseID}
        setCustomDate={setCustomDate}
        setSelectedUser={setSelectedUser}
        setSelectedSalesDF={setSelectedDF}
        bindUserListData={bindUserListData}
        checkIsUserExist={checkIsUserExist}
        setCurrencySymbol={setCurrencySymbol}
        bindBrandPartners={bindBrandPartners}
        renderAllocateBar={renderAllocateBar}
        setCustomDateState={setCustomDateState}
        setIsCustomDateApply={setIsCustomDateApply}
        setSelectedManagerUser={setSelectedManagerUser}
        setSelectedMarketplace={setSelectedMarketplace}
        generateStringOfValues={generateStringOfValues}
        setSelectedBrandPartner={setSelectedBrandPartner}
        displayContributionTable={displayContributionTable}
        setSelectedPerformanceType={setSelectedPerformanceType}
        bindMetricsContributionData={bindMetricsContributionData}
        renderRecentAndPreviousLabel={renderRecentAndPreviousLabel}
        handleMultiSelectDropdownFilterEvents={
          handleMultiSelectDropdownFilterEvents
        }
      />
    );
  };

  return (
    <>
      {goalTargetInsightMsg ? (
        <PopOverMsg>{goalTargetInsightMsg}</PopOverMsg>
      ) : null}
      <DashboardCard className="ad-manager-dashboard">
        <div className="dashboard-container-body" style={{ paddingTop: 0 }}>
          <h5 className="sub-title-text text-medium mb-4">
            {dashboardHeaders[userInfo?.role]}
          </h5>{' '}
          <Tabs>
            <ul className="tabs">
              <li
                className={viewComponent === 'sales' ? 'active' : ''}
                onClick={() => {
                  setIncludeSpreetailBrands(true);
                  setViewComponent('sales');
                  history.push(`${dashboardRolePaths[userInfo?.role]}sales`);
                  setResponseID();
                }}
                role="presentation"
              >
                Sales
              </li>
              <li
                className={viewComponent === 'sponsored' ? 'active' : ''}
                onClick={() => {
                  setIncludeSpreetailBrands(true);
                  setViewComponent('sponsored');
                  history.push(
                    `${dashboardRolePaths[userInfo?.role]}sponsored`,
                  );
                  setResponseID();
                }}
                role="presentation"
              >
                Sponsored Advertising
              </li>
              <li
                className={viewComponent === 'dsp' ? 'active' : ''}
                onClick={() => {
                  setIncludeSpreetailBrands(false);
                  setViewComponent('dsp');
                  history.push(`${dashboardRolePaths[userInfo?.role]}dsp`);
                  setResponseID();
                }}
                role="presentation"
              >
                DSP Advertising
              </li>
              {isShowAMCAudienceSummaryTab ? (
                <li
                  className={viewComponent === 'amcDashboard' ? 'active' : ''}
                  onClick={() => {
                    setViewComponent('amcDashboard');
                    history.push(
                      `${
                        dashboardRolePaths[userInfo?.role]
                      }amcDashboard?subNav=amc-summary`,
                    );
                  }}
                  role="presentation"
                >
                  AMC Insights
                </li>
              ) : (
                ''
              )}

              {showInventoryTab && (
                <li
                  className={viewComponent === 'inventory' ? 'active' : ''}
                  onClick={() => {
                    setViewComponent('inventory');
                    history.push(
                      `${dashboardRolePaths[userInfo?.role]}inventory`,
                    );
                    setResponseID();
                  }}
                  role="presentation"
                >
                  Inventory
                </li>
              )}

              {isHavePendingApprovalAccess ? (
                <li
                  className={
                    viewComponent === 'pendingApprovals' ? 'active' : ''
                  }
                  onClick={() => {
                    setViewComponent('pendingApprovals');
                    history.push(
                      `${dashboardRolePaths[userInfo?.role]}pendingApprovals`,
                    );
                    setResponseID();
                  }}
                  role="presentation"
                >
                  Pending Approvals
                  <span className="circle-dot">
                    {pendingApprovalListDataCount}
                  </span>
                </li>
              ) : null}

              {/* Adding toggle button for include inactive BPs */}
              {viewComponent === 'sales' ||
              viewComponent === 'sponsored' ||
              viewComponent === 'dsp' ? (
                <>
                  <li className="float-right">
                    {renderIncludeInactiveBPToggle()}
                  </li>
                  <li className="float-right">
                    {renderIncludeSpreetailBrandsToggle()}
                  </li>
                  <div className="clear-fix" />
                </>
              ) : null}
            </ul>
          </Tabs>
          <Switch>
            <Route exact path={`${dashboardRolePaths[userInfo?.role]}sales`}>
              {renderSalesDashboard()}
            </Route>
            <Route path={`${dashboardRolePaths[userInfo?.role]}sponsored`}>
              <SponsoredDashboardContainer
                // string
                responseID={responseID}
                currencySymbol={currencySymbol}
                // boolean
                isBGS={isBGS}
                isBGSAdmin={isBGSAdmin}
                isSpreetailRole={isSpreetailRole}
                isBGSManager={isBGSManager}
                isAdManager={isAdManager}
                isAdTeamManager={isAdTeamManager}
                isAdManagerAdmin={isAdManagerAdmin}
                isCustomDateApply={isCustomDateApply}
                includeInactiveBPs={includeInactiveBPs}
                includeSpreetailBrands={includeSpreetailBrands}
                // object
                userInfo={userInfo}
                customDate={customDate}
                selectedAdDF={selectedDF}
                selectedMarketplace={selectedMarketplace}
                selectedBrandPartner={selectedBrandPartner}
                selectedPerformanceType={selectedPerformanceType}
                // array
                loggedInUser={loggedInUser}
                adState={customDateState}
                selectedUser={selectedUser}
                selectedManagerUser={selectedManagerUser}
                marketplaceChoices={marketplaceChoices}
                sponsoredAdMultiSelected={sponsoredAdMultiSelected}
                // function
                setSelectedBrandPartner={setSelectedBrandPartner}
                bindBrandPartners={bindBrandPartners}
                setCustomDate={setCustomDate}
                setAdState={setCustomDateState}
                setSelectedAdDF={setSelectedDF}
                setSelectedUser={setSelectedUser}
                setCurrencySymbol={setCurrencySymbol}
                setSelectedManagerUser={setSelectedManagerUser}
                setIsCustomDateApply={setIsCustomDateApply}
                setSelectedMarketplace={setSelectedMarketplace}
                checkIsUserExist={checkIsUserExist}
                bindUserListData={bindUserListData}
                renderAllocateBar={renderAllocateBar}
                generateStringOfValues={generateStringOfValues}
                setSelectedPerformanceType={setSelectedPerformanceType}
                displayContributionTable={displayContributionTable}
                bindMetricsContributionData={bindMetricsContributionData}
                renderRecentAndPreviousLabel={renderRecentAndPreviousLabel}
                setSponsoredAdMultiSelected={setSponsoredAdMultiSelected}
                setResponseID={setResponseID}
                handleMultiSelectDropdownFilterEvents={
                  handleMultiSelectDropdownFilterEvents
                }
              />
            </Route>
            <Route path={`${dashboardRolePaths[userInfo?.role]}dsp`}>
              <DspDashboardContainer
                // string
                responseID={responseID}
                currencySymbol={currencySymbol}
                // boolean
                isBGS={isBGS}
                isBGSAdmin={isBGSAdmin}
                isSpreetailRole={isSpreetailRole}
                isBGSManager={isBGSManager}
                isAdManager={isAdManager}
                isAdTeamManager={isAdTeamManager}
                isAdManagerAdmin={isAdManagerAdmin}
                isCustomDateApply={isCustomDateApply}
                includeInactiveBPs={includeInactiveBPs}
                includeSpreetailBrands={includeSpreetailBrands}
                // object
                userInfo={userInfo}
                customDate={customDate}
                selectedDspDF={selectedDF}
                // array
                loggedInUser={loggedInUser}
                adState={customDateState}
                marketplaceChoices={marketplaceChoices}
                // function
                bindBrandPartners={bindBrandPartners}
                setCustomDate={setCustomDate}
                setAdState={setCustomDateState}
                setSelectedDspDF={setSelectedDF}
                setCurrencySymbol={setCurrencySymbol}
                setIsCustomDateApply={setIsCustomDateApply}
                checkIsUserExist={checkIsUserExist}
                bindUserListData={bindUserListData}
                generateStringOfValues={generateStringOfValues}
                displayContributionTable={displayContributionTable}
                bindMetricsContributionData={bindMetricsContributionData}
                renderRecentAndPreviousLabel={renderRecentAndPreviousLabel}
                setResponseID={setResponseID}
                handleMultiSelectDropdownFilterEvents={
                  handleMultiSelectDropdownFilterEvents
                }
              />
            </Route>
            {/*  Newly added path to AMC aggregated Dashboard Component. */}
            {isShowAMCAudienceSummaryTab && (
              <Route path={`${dashboardRolePaths[userInfo?.role]}amcdashboard`}>
                <AMCAggregatedDashboardContainer
                  bindUserListData={bindUserListData}
                  handleMultiSelectDropdownFilterEvents={
                    handleMultiSelectDropdownFilterEvents
                  }
                />
              </Route>
            )}
            {/*  AWS Quicksight Dashboard route. */}
            {/* <Route path={`${dashboardRolePaths[userInfo?.role]}awsQuicksight`}>
              <AwsQuicksightContainer />
                </Route> */}
            {showInventoryTab && (
              <Route path={`${dashboardRolePaths[userInfo?.role]}inventory`}>
                <InventoryDashboardContainer
                  // boolean
                  isBGS={isBGS}
                  isBGSAdmin={isBGSAdmin}
                  isSpreetailRole={isSpreetailRole}
                  isBGSManager={isBGSManager}
                  // object
                  userInfo={userInfo}
                  selectedMarketplace={selectedMarketplace}
                  selectedBrandPartner={selectedBrandPartner}
                  isAdManagerAdmin={isAdManagerAdmin}
                  // array
                  loggedInUser={loggedInUser}
                  selectedUser={selectedUser}
                  selectedManagerUser={selectedManagerUser}
                  marketplaceChoices={marketplaceChoices}
                  // function
                  setSelectedUser={setSelectedUser}
                  setSelectedManagerUser={setSelectedManagerUser}
                  setSelectedMarketplace={setSelectedMarketplace}
                  checkIsUserExist={checkIsUserExist}
                  bindUserListData={bindUserListData}
                  renderAllocateBar={renderAllocateBar}
                  generateStringOfValues={generateStringOfValues}
                  handleMultiSelectDropdownFilterEvents={
                    handleMultiSelectDropdownFilterEvents
                  }
                  bindBrandPartners={bindBrandPartners}
                  setSelectedBrandPartner={setSelectedBrandPartner}
                  includeInactiveBPs={includeInactiveBPs}
                  includeSpreetailBrands={includeSpreetailBrands}
                />
              </Route>
            )}
            {isHavePendingApprovalAccess && (
              <Route
                path={`${dashboardRolePaths[userInfo?.role]}pendingApprovals`}
              >
                <PendingApprovalsDashboardContainer
                  setCallPendingApprovalAPI={setCallPendingApprovalAPI}
                />
              </Route>
            )}
            <Route
              path={dashboardRolePaths[userInfo?.role]}
              render={() => (
                <Redirect
                  to={`${dashboardRolePaths[userInfo?.role]}${viewComponent}`}
                >
                  {renderSalesDashboard()}
                </Redirect>
              )}
            />
          </Switch>
        </div>
      </DashboardCard>
    </>
  );
}

AdvertisingDashboardContainer.defaultProps = {
  userInfo: {},
};
AdvertisingDashboardContainer.propTypes = {
  userInfo: shape({
    role: string.isRequired,
  }),
};

const PopOverMsg = styled.div`
  min-height: 40px;
  background: #fbf2f2;
  color: ${Theme.black};
  font-size: 13px;
  padding: 11px;
  text-align: center;
  margin-top: 45px;
  font-family: ${Theme.baseMediumFontFamily};
`;
