import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { string } from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Icon } from '../../../../theme/Global';
import { inventoryManagementInfoIconConstants } from '../../../../constants';

const InventoryManagementInfoIcon = ({ infoIconId }) => {
  return (
    <Icon>
      <FontAwesomeIcon
        data-for={infoIconId}
        data-tip={
          inventoryManagementInfoIconConstants?.find(
            (item) => item?.id === infoIconId,
          )?.message
        }
        data-html
        icon="fa-regular fa-circle-info"
      />
      <ReactTooltip
        className="info-icon text-transformation-none"
        id={infoIconId}
        aria-haspopup="true"
        place="right"
        effect="solid"
        html
        insecure
      />
    </Icon>
  );
};

export default InventoryManagementInfoIcon;

InventoryManagementInfoIcon.defaultProps = {
  infoIconId: '',
};

InventoryManagementInfoIcon.propTypes = {
  infoIconId: string,
};
