import React, { Fragment, useCallback, useState } from 'react';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number } from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import styled from 'styled-components';
import Theme from '../../../theme/Theme';
import NoRecordFound from '../../../common/NoRecordFound';
import ReportPDFStatusModal from './ReportPDFStatusModal';
import { ServiceAgreementIcon } from '../../../theme/images';
import { getCustomReportActivity, checkReportStatusToEdit } from '../../../api';
import {
  SpreetailRole,
  customizedReportTableHeadConstants,
} from '../../../constants';
import {
  CommonPagination,
  DesktopTable,
  PageLoader,
  Status,
} from '../../../common';

const DOWNLOAD_ACTION = 'downloaded';
const VIEW_ACTION = 'viewed';

const CustomizedReportsList = ({
  pageNumber,
  count,
  loader,
  data,
  handlePageChange,
  onEditView,
  reportTableAPICall,
}) => {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const isDesktop = useMediaQuery({ minWidth: 767 });
  const [itemLoader, setItemLoader] = useState({});
  const [currentPDFUser, setCurrentPDFUser] = useState();
  const [reportPDFstatusModal, setReportPDFStatusModal] = useState(false);

  const onGetCustomReportActivity = useCallback((action, reportID) => {
    getCustomReportActivity(action, reportID).then(() => {});
  }, []);

  // handle download report
  const handleDownload = (url, reportName, id) => {
    setItemLoader({ ...itemLoader, [id]: true });
    fetch(url).then((response) => {
      response
        .blob()
        .then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = reportName;
          alink.click();
          setItemLoader((oldLoadingState) => ({
            ...oldLoadingState,
            [id]: false,
          }));
        })
        .catch(() =>
          setItemLoader((oldLoadingState) => ({
            ...oldLoadingState,
            [id]: false,
          })),
        );
    });

    onGetCustomReportActivity(DOWNLOAD_ACTION, id);
  };

  // render download report html
  const renderDownloadReportHTML = (item) => {
    return (
      <>
        <span
          aria-hidden
          className={
            isDesktop
              ? 'cursor mr-3'
              : !itemLoader[item?.id]
              ? 'icon-with-border cursor'
              : 'cursor'
          }
          onClick={() => {
            handleDownload(
              item?.presigned_url,
              `${item.customer_name}_${item?.name}_${dayjs(
                item?.created_at,
              ).format('DD MMM YYYY')}.pdf`,
              item?.id,
            );
          }}
        >
          {itemLoader[item?.id] ? (
            <PageLoader
              color={Theme.orange}
              component="table-download-btn"
              type="button"
              width={20}
              height={20}
            />
          ) : (
            <>
              <FontAwesomeIcon
                icon="fa-light fa-arrow-down-to-line"
                style={{
                  color: Theme.orange,
                  marginRight: isDesktop ? '8px' : 0,
                }}
              />
              {isDesktop ? 'Download' : ''}
            </>
          )}
        </span>
      </>
    );
  };

  // checking if pdf is editable or not
  const handleIsReportPDFEditable = (item) => {
    checkReportStatusToEdit(item?.id).then((response) => {
      if (response?.status === 200) {
        if (
          response?.data?.status === 'ready'
          // response?.data?.current_user?.id === userInfo?.id // restrict same user to edit the same report
        ) {
          setReportPDFStatusModal(false);
          onEditView(
            item?.id,
            item?.customer,
            item?.customer_name,
            'readyToEdit',
            item?.presigned_url,
            `${item.customer_name}_${item.name}_${dayjs(item.created_at).format(
              'DD MMM YYYY',
            )}`,
          );
        } else {
          const currenUser = `${response?.data?.current_user?.first_name} ${response?.data?.current_user?.last_name}`;
          setCurrentPDFUser(currenUser);
          setReportPDFStatusModal(true);
        }
      }
    });
  };

  // render report pdf edit html
  const renderReportPDFEditHTML = (item) => {
    const mobileStyle = { marginRight: '5px', padding: '8px 9px' };

    return (
      <span
        aria-hidden="true"
        className={isDesktop ? 'cursor mr-3' : 'icon-with-border cursor'}
        style={isDesktop ? null : mobileStyle}
        onClick={() => {
          handleIsReportPDFEditable(item);
        }}
      >
        <FontAwesomeIcon
          icon="fa-light fa-pen"
          style={{
            color: Theme.orange,
            marginRight: isDesktop ? '8px' : 0,
          }}
        />
        {isDesktop ? 'Edit' : ''}
      </span>
    );
  };

  // render report pdf view only html
  const renderReportPDFViewOnlyHTML = (item) => {
    const mobileStyle = { marginRight: '5px', padding: '8px 9px' };
    return (
      <span
        aria-hidden="true"
        className={isDesktop ? 'cursor mr-3' : 'icon-with-border cursor'}
        style={isDesktop ? null : mobileStyle}
        onClick={() => {
          onGetCustomReportActivity(VIEW_ACTION, item?.id);
          onEditView(
            item?.id,
            item?.customer,
            item?.customer_name,
            'view',
            item?.presigned_url,
            `${item?.customer_name}_${item?.name}_${dayjs(
              item?.created_at,
            ).format('DD MMM YYYY')}`,
          );
        }}
      >
        <FontAwesomeIcon
          icon="fa-regular fa-eye"
          style={{
            color: Theme.orange,
            marginRight: isDesktop ? '8px' : 0,
          }}
        />
        {isDesktop ? 'View' : ''}
      </span>
    );
  };

  // render report pdf status
  const renderReportPDFStatus = (status) => {
    const label = status === 'ready' ? 'Ready' : 'In Progress';
    const color = status === 'ready' ? '#407b00' : '#ff5933';
    const bgColor = status === 'ready' ? '#e1f2ce' : '#f6d2c9';

    return (
      <Status
        label={label}
        labelColor={color}
        backgroundColor={bgColor}
        className="text-bold"
      />
    );
  };

  // render report creation date
  const renderReportCreationDate = (item) => {
    return (
      <>
        {item?.created_at}
        {/* {dayjs(item.created_at).format('DD MMM YYYY')} */}
        <UpdatedStatus>
          <FontAwesomeIcon icon="fa-light fa-file-contract" className="mr-1" />
          {item?.upsert_action?.includes('created')
            ? 'Created by'
            : 'Updated by'}
          &nbsp;
          {item?.user?.first_name}&nbsp;{item?.user?.last_name}
        </UpdatedStatus>
      </>
    );
  };

  // const render report table head
  const renderReportTableHead = () => {
    return (
      <thead>
        <tr>
          {customizedReportTableHeadConstants?.map((item) => {
            return (
              <th width={item?.width} key={item?.label}>
                {item?.label}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  // render report table body
  const renderReportTableBody = () => {
    return (
      <tbody>
        {data?.map((item) => {
          return (
            <tr key={item?.id}>
              <td>
                <div className="large-text">{item?.customer_name}</div>
              </td>
              <td>
                <div className="large-text">{item?.name}</div>
              </td>
              <td>{item?.description}</td>
              <td>{renderReportCreationDate(item)}</td>
              <td>{renderReportPDFStatus(item?.status)}</td>
              <td>
                <div className="d-flex">
                  {!isSpreetailUser && item?.is_editable
                    ? renderReportPDFEditHTML(item)
                    : renderReportPDFViewOnlyHTML(item)}
                  {renderDownloadReportHTML(item)}
                </div>
              </td>
            </tr>
          );
        })}

        {loader ? (
          <PageLoader
            component="table-loader"
            color={Theme.orange}
            type="page"
          />
        ) : null}
        {data.length === 0 && !loader ? (
          <NoRecordFound
            feedback
            titleClassName="customize-text"
            title="CUSTOMIZED REPORTS"
            subTitleClassName="sub-title-text"
            image={ServiceAgreementIcon}
            imageClassName="customize-Image"
            subTitle="In the meantime you can create customize reports"
          />
        ) : null}
      </tbody>
    );
  };

  // render report mobile view
  const renderReportMobileView = () => {
    return (
      <ReportsMobiveView>
        {data?.map((item) => {
          return (
            <Fragment key={item?.id}>
              <div className="row">
                <div className="col-8">
                  <p className="black-heading-title my-3">
                    {item?.customer_name}
                  </p>
                </div>
                <div className="col-4 text-right p-0">
                  <div className="d-flex justify-content-end align-items-center mt-3">
                    {item?.is_editable
                      ? renderReportPDFEditHTML(item)
                      : renderReportPDFViewOnlyHTML(item)}
                    {renderDownloadReportHTML(item)}
                  </div>
                </div>
                <div className="col-12 ">
                  <p className="black-heading-title my-3">{item?.name}</p>
                  <p className="basic-text my-3">{item?.description}</p>
                  <p className="basic-text mt-3">
                    {renderReportCreationDate(item)}
                  </p>
                </div>
              </div>
              <div className="horizontal-line straight-line mt-2" />
            </Fragment>
          );
        })}
      </ReportsMobiveView>
    );
  };

  // render report pdf status modal
  const renderReportPDFStatusModal = () => {
    return (
      <ReportPDFStatusModal
        isModalOpen={reportPDFstatusModal}
        currentPDFUser={currentPDFUser}
        afterModalCloseAPI={() => {
          reportTableAPICall();
          setReportPDFStatusModal(false);
        }}
      />
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <div>
      {isDesktop ? (
        <DesktopTable
          style={{ minHeight: data && data?.length === 0 ? '350px' : '' }}
        >
          {renderReportTableHead()}
          {renderReportTableBody()}
        </DesktopTable>
      ) : (
        renderReportMobileView()
      )}

      {data.length >= 1 ? (
        <CommonPagination
          count={count}
          pageNumber={pageNumber}
          handlePageChange={handlePageChange}
        />
      ) : null}

      {reportPDFstatusModal ? renderReportPDFStatusModal() : null}
    </div>
  );
};

export default CustomizedReportsList;

CustomizedReportsList.defaultProps = {
  count: 0,
  pageNumber: 1,
  handlePageChange: () => {},
  loader: false,
  onEditView: () => {},
  reportTableAPICall: () => {},
};

CustomizedReportsList.propTypes = {
  pageNumber: number,
  count: number,
  loader: bool,
  data: arrayOf(Array).isRequired,
  handlePageChange: func,
  onEditView: func,
  reportTableAPICall: func,
};
const ReportsMobiveView = styled.div`
  .icon-with-border {
    border-radius: 8px;
    border: 1px solid ${Theme.gray25};
    height: 38px;
    width: 38px;
    padding: 8px 12px;
  }
`;

const UpdatedStatus = styled.div`
  background: ${Theme.gray8};
  border-radius: 4px;
  padding: 4px;
  font-size: 8px;
  width: fit-content;
  margin-top: 4px;
`;
