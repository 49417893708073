import React from 'react';

import NumberFormat from 'react-number-format';
import { shape, func } from 'prop-types';

import { ListingTranslationFields } from '../../../../constants';
import { PlusIcon, MinusIcon } from '../../../../theme/images';
import { InputFormField } from '../../../../common';
import { checkDisabledConditionForListing } from '../../../../common/ConditionsForStatementOfWork';

function ListingTranslation({
  formData,
  setFormData,
  setUpdatedFormData,
  updatedFormData,
  showFooter,
  handleChange,
}) {
  const handleChangeListTranslation = (key, flag) => {
    showFooter(true);
    let updatedData = 0;

    if (flag === 'minus') {
      if (formData && formData[key]) {
        updatedData =
          parseInt(formData[key], 10) - 1
            ? parseInt(formData[key], 10) - 1
            : null;
      } else {
        updatedData = null;
      }
    }
    if (flag === 'plus') {
      if (formData && formData[key]) {
        if (parseInt(formData[key], 10) < 25) {
          updatedData = parseInt(formData[key], 10) + 1;
        }
      } else {
        updatedData = 1;
      }
    }
    setFormData({
      ...formData,
      [key]: updatedData,
    });
    setUpdatedFormData({
      ...updatedFormData,
      [key]: updatedData,
    });
  };

  const displayListingTranslation = () => {
    return (
      <>
        <InputFormField className="mb-3">
          <div className="row">
            <div className="col-6">
              {' '}
              <label htmlFor="additional_one_time_services ">
                Listing translation
              </label>{' '}
            </div>

            <div className="col-6 text-right">
              <div className="label-card-text ">For Non-US Marketplaces</div>
            </div>
          </div>
        </InputFormField>
        {ListingTranslationFields.map((field) => {
          return (
            <React.Fragment key={Math.random()}>
              <div className="row">
                <div className="col-7 mb-3">
                  <label htmlFor={field.label} className="listing-optimazation">
                    {field && field.label}
                  </label>
                </div>
                <div className="col-5 pl-0 text-end">
                  <button
                    type="button"
                    className="decrement"
                    onClick={() =>
                      handleChangeListTranslation(field.key, 'minus')
                    }
                    disabled={checkDisabledConditionForListing(
                      formData,
                      field?.key,
                    )}
                  >
                    <img className="minus-icon" src={MinusIcon} alt="" />
                  </button>

                  <NumberFormat
                    name={field.key}
                    className="form-control max-min-number"
                    value={(formData && formData[field.key]) || 0}
                    onChange={(event) =>
                      handleChange(event, 'listing_optimization')
                    }
                    allowNegative={false}
                    isAllowed={(values) => {
                      const { formattedValue, floatValue } = values;
                      if (floatValue == null) {
                        return formattedValue === '';
                      }
                      return floatValue <= 25;
                    }}
                  />

                  <button
                    type="button"
                    className="increment"
                    disabled={
                      formData &&
                      formData[field.key] &&
                      parseInt(formData[field.key], 10) === 25
                    }
                    onClick={() =>
                      handleChangeListTranslation(field.key, 'plus')
                    }
                  >
                    <img className="plus-icon" src={PlusIcon} alt="" />
                  </button>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </>
    );
  };
  return <div>{displayListingTranslation()}</div>;
}

export default ListingTranslation;

ListingTranslation.defaultProps = {
  formData: {},
  setFormData: () => {},
  setUpdatedFormData: () => {},
  updatedFormData: {},
  showFooter: () => {},
  handleChange: () => {},
};
ListingTranslation.propTypes = {
  formData: shape({}),
  setFormData: func,
  setUpdatedFormData: func,
  updatedFormData: shape({}),
  showFooter: func,
  handleChange: func,
};
