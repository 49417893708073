/* eslint-disable react/no-danger */
import React from 'react';

import dayjs from 'dayjs';
import {
  string,
  number,
  func,
  oneOfType,
  arrayOf,
  shape,
  instanceOf,
} from 'prop-types';

import {
  additionaMarketplaceAmount,
  displayFixedThresholdInfo,
  displayFixedRetainerInfo,
  displayQuarterlyRetainerInfo,
  displayMonthlyRetainerInfo,
  displayFeeTypeInfo,
  displayRevenueShareInfo,
  displayBillingMinimumInfo,
  displayBillingCapInfo,
} from '../../../constants';
import { StatementParagraph } from '../../../theme/AgreementStyle';

import {
  oneTimeSpecificServices,
  oneTimeSpecificServicesFees,
  returnOneTimeServiceNames,
  returnOneTimeServiceFees,
  returnOneTimeServiceFeesTotal,
} from '../../../common/OneTimeServiceSubTabFunctions';

export default function Statement({
  formData,
  templateData,
  notIncludedOneTimeServices,
  notIncludedMonthlyServices,
  servicesFees,
  discountData,
  selectedCurrency,
  displayMarketplaceName,
  displayListingOptimisationInfo,
  displayRevisionPolicyInfo,
  displayBBELogo,
  startDate,
}) {
  const accountType = formData?.seller_type?.value;

  const displayNumber = (num) => {
    const res = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return res;
  };

  const mapDefaultValues = (key, label, type) => {
    if (
      type === 'amazon' &&
      key === 'seller_type' &&
      formData?.seller_type?.label === 'Hybrid'
    )
      return 'Seller';
    if (key === 'company_name') {
      return formData && formData.customer_id && formData.customer_id[key]
        ? `<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${
            formData && formData.customer_id && formData.customer_id[key]
          }</span>`
        : `Client Name`;
    }
    if (formData[key] === undefined || formData[key] === '') {
      return `Enter ${label}`;
    }
    if (key === 'start_date') {
      return startDate !== null
        ? `<span style="padding: 4px 9px; font-weight: bold";>${
            startDate && dayjs(startDate).format('MM / DD / YYYY')
          }</span>`
        : 'Select Date';
    }
    if (key === 'primary_marketplace') {
      if (formData && formData.primary_marketplace) {
        return formData?.primary_marketplace?.name
          ? displayMarketplaceName(
              formData.primary_marketplace.name,
              'primary',
              'lhs',
            )
          : displayMarketplaceName(
              formData.primary_marketplace,
              'primary',
              'lhs',
            );
      }
      return `Enter ${label}`;
    }
    if (key === 'threshold_type') {
      if (formData && formData[key] === 'YoY') {
        return 'YoY: All channel sales above previous year channel sales.';
      }
      if (formData && formData[key] === 'YoY + %') {
        return `YoY + %: All channel sales above a <span style=" background:#ffe5df;padding: 4px 9px;font-weight: bold">${
          formData && formData.yoy_percentage
            ? formData && formData.yoy_percentage
            : `Enter ${label}`
        }</span> growth on previous year channel sales.`;
      }
      if (formData && formData[key] === 'Fixed') {
        return `Fixed: <span style=" background:#ffe5df;padding: 4px 9px;font-weight: bold">${
          formData && formData.sales_threshold
            ? `${selectedCurrency} ${displayNumber(
                formData && formData.sales_threshold,
              )}`
            : `Enter ${label}`
        }</span>`;
      }
    }
    if (type && type.includes('number')) {
      // ${type === 'number-currency' ? '$' : '%'}
      if (formData && formData[key]) {
        return `${
          type === 'number-currency' ? selectedCurrency : '%'
        }${`${displayNumber(formData[key])}`}`;
      }
      return `Enter ${label}`;
    }

    return key === 'rev_share' || key === 'seller_type'
      ? formData && formData[key] && formData[key].label
        ? formData[key].label
        : formData[key]
        ? formData[key]
        : `Enter ${label}`
      : formData && formData[key];
  };

  const ShowRetainerThresholdValues = (
    type,
    key,
    quartelyKey,
    monthlyKey,
    option,
    label,
  ) => {
    return ` 
              ${
                (formData?.fee_structure?.[type]?.[option] === null ||
                  formData?.fee_structure?.[type]?.[option] === 'None') &&
                option === 'threshold_type'
                  ? ''
                  : formData?.fee_structure?.[type]?.[option] === 'Fixed' ||
                    formData?.fee_structure?.[type]?.[option] === null
                  ? ` <tr  style="vertical-align: text-top;">
              <td> <span style=" font-weight: bold";> Fixed ${label} </span></td>
               <td><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${displayNumber(
                      formData?.fee_structure?.[type]?.[key] || 0,
                    )} </span> </td>
              <td>${
                label === 'Threshold'
                  ? displayFixedThresholdInfo
                  : displayFixedRetainerInfo
              } </td></tr>`
                  : formData?.fee_structure?.[type]?.[option] === 'quarterly'
                  ? ` <tr  style="vertical-align: text-top;">
             <td> <span style=" font-weight: bold";>Quarterly ${label} </span></td>
             <td>
                 1st Quarter:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[quartelyKey]?.[
                          '1st quarterly'
                        ],
                      ) || 0
                    } </span><br>
                  2nd Quarter:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";> ${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[quartelyKey]?.[
                          '2nd quarterly'
                        ],
                      ) || 0
                    } </span><br>
                  3rd Quarter:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[quartelyKey]?.[
                          '3rd quarterly'
                        ],
                      ) || 0
                    }</span><br>
                  4th Quarter:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[quartelyKey]?.[
                          '4th quarterly'
                        ],
                      ) || 0
                    }</span><br>
               </td>
            <td>${
              label === 'Threshold'
                ? displayFixedThresholdInfo
                : displayQuarterlyRetainerInfo
            }
             </td>
          </tr>`
                  : formData?.fee_structure?.[type]?.[option] === 'monthly'
                  ? ` <tr  style="vertical-align: text-top;">
             <td> <span style=" font-weight: bold";>Monthly ${label} </span></td>
             <td>
                 January:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'january month'
                        ],
                      ) || 0
                    } </span><br>
                  February:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";> ${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'february month'
                        ],
                      ) || 0
                    } </span><br>
                  March:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'march month'
                        ],
                      ) || 0
                    }</span><br>
                  April:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'april month'
                        ],
                      ) || 0
                    }</span><br>
                   May:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'may month'
                        ],
                      ) || 0
                    } </span><br>
                  June:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";> ${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'june month'
                        ],
                      ) || 0
                    } </span><br>
                  July:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'july month'
                        ],
                      ) || 0
                    }</span><br>
                  August:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'august month'
                        ],
                      ) || 0
                    }</span><br>
                   September:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'september month'
                        ],
                      ) || 0
                    } </span><br>
                  October:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";> ${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'october month'
                        ],
                      ) || 0
                    } </span><br>
                  November:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'november month'
                        ],
                      ) || 0
                    }</span><br>
                  December:<span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${
                      displayNumber(
                        formData?.fee_structure?.[type]?.[monthlyKey]?.[
                          'december month'
                        ],
                      ) || 0
                    }</span><br>
               </td>
            <td>${
              label === 'Threshold'
                ? displayFixedThresholdInfo
                : displayMonthlyRetainerInfo
            }
             </td>
          </tr>`
                  : ''
              }`;
  };

  const feeStructure = (type) => {
    if (formData?.fee_structure?.[type]?.fee_type === 'Retainer Only') {
      return `<div class="table-responsive">
          <table class="contact-list mb-3 ">
           <tr>
              <td> <span style=" font-weight: bold";>Fee Type</span></td>
               <td><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold;text-transform: capitalize;white-space: nowrap;"> Retainer Only  (${
                 formData?.fee_structure?.[type]?.retainer_type === undefined ||
                 formData?.fee_structure?.[type]?.retainer_type === null
                   ? 'Fixed'
                   : formData?.fee_structure?.[type]?.retainer_type
               } Retainer) </span> </td>
              <td>${displayFeeTypeInfo}</td>
            </tr>
               ${ShowRetainerThresholdValues(
                 type,
                 'monthly_retainer',
                 'quarterly_monthly_retainer',
                 'monthly_monthly_retainer',
                 'retainer_type',
                 'Retainer',
               )}
          </table>
        </div>`;
    }
    if (formData?.fee_structure?.[type]?.fee_type === 'Revenue Share Only') {
      return `<div class="table-responsive">
          <table class="contact-list mb-3 ">
            <tr>
              <td> <span style=" font-weight: bold";>Fee Type</span></td>
               <td><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold;text-transform: capitalize;white-space: nowrap;"> Revenue Share Only  (${
                 formData?.fee_structure?.[type]?.threshold_type ===
                   undefined ||
                 formData?.fee_structure?.[type]?.threshold_type === null ||
                 formData?.fee_structure?.[type]?.threshold_type === 'None'
                   ? 'No'
                   : formData?.fee_structure?.[type]?.threshold_type
               } Threshold) </span> </td>
              <td>${displayFeeTypeInfo}</td>
            </tr>
             <tr  style="vertical-align: text-top;">
              <td> <span style=" font-weight: bold";> Revenue Share %</span></td>
               <td><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${displayNumber(
                 formData?.fee_structure?.[type]?.rev_share || 0,
               )}% </span> </td>
              <td>${displayRevenueShareInfo}</td></tr>
              ${ShowRetainerThresholdValues(
                type,
                'sales_threshold',
                'quarterly_rev_share',
                'monthly_rev_share',
                'threshold_type',
                'Threshold',
              )}
               ${
                 formData?.fee_structure?.[type]?.billing_minimum
                   ? `<tr  style="vertical-align: text-top;">
              <td> <span style=" font-weight: bold";> Billing Minimum</span></td>
               <td><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${displayNumber(
                       formData?.fee_structure?.[type]?.billing_minimum || 0,
                     )} </span> </td>
              <td>${displayBillingMinimumInfo}</td></tr>`
                   : ''
               }
              ${
                formData?.fee_structure?.[type]?.billing_cap
                  ? `<tr  style="vertical-align: text-top;">
              <td> <span style=" font-weight: bold";> Billing Cap</span></td>
              <td><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${displayNumber(
                      formData?.fee_structure?.[type]?.billing_cap || 0,
                    )} </span> </td>
              <td>${displayBillingCapInfo}</td></tr>`
                  : ''
              }
          </table>
        </div>`;
    }
    if (
      formData?.fee_structure?.[type]?.fee_type === 'Retainer + % Rev Share'
    ) {
      return `<div class="table-responsive">
          <table class="contact-list mb-3 ">
            <tr>
              <td> <span style=" font-weight: bold";>Fee Type</span></td>
               <td><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold;text-transform: capitalize;white-space: nowrap;"> Retainer (${
                 formData?.fee_structure?.[type]?.retainer_type === undefined ||
                 formData?.fee_structure?.[type]?.retainer_type === null
                   ? 'Fixed'
                   : formData?.fee_structure?.[type]?.retainer_type
               } Retainer) + Rev Share 
               (${
                 formData?.fee_structure?.[type]?.threshold_type ===
                   undefined ||
                 formData?.fee_structure?.[type]?.threshold_type === null ||
                 formData?.fee_structure?.[type]?.threshold_type === 'None'
                   ? 'No'
                   : formData?.fee_structure?.[type]?.threshold_type
               } Threshold)  </span> </td>
              <td>${displayFeeTypeInfo}</td>
            </tr>
             ${ShowRetainerThresholdValues(
               type,
               'monthly_retainer',
               'quarterly_monthly_retainer',
               'monthly_monthly_retainer',
               'retainer_type',
               'Retainer',
             )} 
             <tr  style="vertical-align: text-top;">
              <td> <span style=" font-weight: bold";> Revenue Share %</span></td>
               <td><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${displayNumber(
                 formData?.fee_structure?.[type]?.rev_share || 0,
               )}% </span> </td>
              <td>${displayRevenueShareInfo}</td></tr>
               ${ShowRetainerThresholdValues(
                 type,
                 'sales_threshold',
                 'quarterly_rev_share',
                 'monthly_rev_share',
                 'threshold_type',
                 'Threshold',
               )}
              ${
                formData?.fee_structure?.[type]?.billing_cap
                  ? `<tr  style="vertical-align: text-top;">
              <td> <span style=" font-weight: bold";> Billing Cap</span></td>
               <td><span style=" background:#ffe5df;padding: 4px 9px; font-weight: bold";>${selectedCurrency}${displayNumber(
                      formData?.fee_structure?.[type]?.billing_cap || 0,
                    )} </span> </td>
              <td>${displayBillingCapInfo}</tr>`
                  : ''
              }
          </table>
        </div>`;
    }
    return '';
  };

  const showRevTable = () => {
    if (formData?.seller_type?.label === 'Seller')
      return feeStructure('seller');
    if (formData?.seller_type?.label === 'Vendor')
      return feeStructure('vendor');
    if (formData?.seller_type?.label === 'Hybrid') {
      return `${feeStructure('seller')} 
         <div class=" text-center BT-SOW-sales-commission mt-5" style="text-align: center; margin-top: 3rem!important;">
      <span style="font-weight: 800;
        font-family: Helvetica-bold;" >Fees & Sales Commissions for Amazon Vendor Account
       </span>
        <br> Sales billed in arrears for the prior month in which sales were made on the account and calculated as follows:
    </div></br>${feeStructure('vendor')} `;
    }
    return '';
  };

  const mapVariableMonthlyService = (data) => {
    const fields = [
      `<tr ><td class="total-service" colspan="2" style ="text-align: center">Variable Monthly Services</td></tr>`,
    ];
    if (data) {
      for (const item of data) {
        if (
          item.name
            ? item.name === 'Inventory Reconciliation'
            : item.service.name === 'Inventory Reconciliation'
        ) {
          fields.push(
            `<tr>
              <td style="border: 1px solid black;padding: 13px;">${
                item.service ? item.service.name : item?.name
              }</td>
              <td style="border: 1px solid black;padding: 13px;">  <span style=" background:#ffe5df; padding: 4px 9px;"> 25% of recovered ${selectedCurrency}<span>&#39;</span>s </span></td>
            </tr>`,
          );
        }
      }
      return fields.length > 1 ? fields.toString().replaceAll(',', '') : '';
    }
    return '';
  };
  const mapAdditionalMarketPlaces = (marketplaceData) => {
    const fields = [];
    if (marketplaceData) {
      for (const item of marketplaceData) {
        fields.push(
          `<tr> 
            <td style="border: 1px solid black;padding: 13px;">${
              item.service
                ? displayMarketplaceName(item.service.name, '', 'lhs')
                : displayMarketplaceName(item?.name, '', 'lhs')
            }</td>
            ${
              item?.fee
                ? `<td style="border: 1px solid black;padding: 13px;">
                 <span style=" background:#ffe5df; padding: 4px 9px;"> 
                ${selectedCurrency}${
                    item.service
                      ? displayNumber(item.service.fee)
                      : item.fee
                      ? displayNumber(item.fee)
                      : ''
                  }
                /month
                </span>
              </td>`
                : `<td>
                 <span style=" background:#ffe5df; padding: 4px 9px;"> 
                ${selectedCurrency}${displayNumber(
                    additionaMarketplaceAmount,
                  )} /month </span></td>`
            }
          </tr>`,
        );
      }
    }
    return fields.length ? fields.toString().replaceAll('>,<', '><') : '';
  };

  const calculateTodalFee = (type, data, marketplaceData, accntType) => {
    let oneTimeSubTotal = 0;
    let monthlySubTotal = 0;
    let oneTimeDiscount = 0;
    let monthlyDiscount = 0;
    let additionalMarketplacesTotal = 0;

    if (formData) {
      if (type === 'monthly') {
        // caculate the total of additional monthly serviece
        if (data && data !== null) {
          if (!accntType) {
            data.forEach((item) => {
              if (item?.fee) monthlySubTotal += Number(item?.fee);
            });
          } else {
            data.forEach((item) => {
              if (item && item.service) {
                if (item?.service?.name === 'A+ Content') {
                  monthlySubTotal += item.service.fee * item?.quantity;
                } else {
                  monthlySubTotal += item.service.fee;
                }
              } else {
                const fixedFee = servicesFees.filter(
                  (n) => n.id === item.service_id,
                );
                if (item?.name === 'A+ Content') {
                  monthlySubTotal += fixedFee[0].fee * item?.quantity;
                } else {
                  monthlySubTotal += fixedFee[0].fee;
                }
              }
            });
          }
        }
        if (accntType) {
          if (marketplaceData !== null) {
            // calculate the total of additional marketplaces
            marketplaceData.forEach((item) => {
              if (item && item.fee) {
                additionalMarketplacesTotal += item.fee;
              } else {
                additionalMarketplacesTotal += additionaMarketplaceAmount;
              }
            });

            monthlySubTotal += additionalMarketplacesTotal;
          }
        }
        let monthlyDiscountData = 0;
        if (accountType === '') {
          monthlyDiscountData = discountData.find(
            (item) => item.is_amc_service_discount,
          );
        } else {
          monthlyDiscountData = discountData.find(
            (item) =>
              item.service_type === 'monthly service' &&
              item?.account_type === accntType,
          );
        }

        if (monthlyDiscountData !== null) {
          const discountType = monthlyDiscountData?.type;
          if (discountType === 'percentage') {
            monthlyDiscount =
              (monthlySubTotal * monthlyDiscountData?.amount) / 100;
          } else if (discountType === 'fixed amount') {
            monthlyDiscount = monthlyDiscountData?.amount;
          }
        } else {
          monthlyDiscount = monthlyDiscountData?.amount;
        }

        return {
          monthlySubTotal,
          monthlyAmountAfterDiscount: monthlyDiscount,
          monthlyTotal: monthlySubTotal - monthlyDiscount,
          monthlyDiscountType: monthlyDiscountData?.type,
          monthlyDiscount: monthlyDiscountData?.amount,
        };
      }
      if (
        type === 'onetime' &&
        formData.additional_one_time_services !== null
      ) {
        formData.additional_one_time_services.forEach((item) => {
          const { quantity } = item;

          if (item.custom_amazon_store_price) {
            oneTimeSubTotal += item.custom_amazon_store_price * quantity;
          } else if (item?.service) {
            oneTimeSubTotal += item.service.fee * quantity;
          } else {
            let fixedFee = servicesFees.filter((n) => n.id === item.service_id);
            fixedFee =
              fixedFee && fixedFee[0] && fixedFee[0].fee ? fixedFee[0].fee : 0;
            oneTimeSubTotal += fixedFee * quantity;
          }
        });

        const oneTimeDiscountData = discountData.find(
          (item) => item.service_type === 'one time service',
        );

        if (oneTimeDiscountData !== null) {
          const discountType = oneTimeDiscountData?.type;
          if (discountType === 'percentage') {
            oneTimeDiscount =
              (oneTimeSubTotal * oneTimeDiscountData?.amount) / 100;
          } else if (discountType === 'fixed amount') {
            oneTimeDiscount = oneTimeDiscountData?.amount;
          }
        } else {
          oneTimeDiscount = oneTimeDiscountData?.amount;
        }

        return {
          oneTimeSubTotal,
          oneTimeAmountAfterDiscount: oneTimeDiscount,
          oneTimeTotal: oneTimeSubTotal - oneTimeDiscount,
          oneTimeDiscountType: oneTimeDiscountData?.type,
          oneTimeDiscount: oneTimeDiscountData?.amount,
        };
      }
    }
    return 0;
  };

  const mapMonthlyServiceTotal = (data, marketplaceData, accntType) => {
    const totalFees = calculateTodalFee(
      'monthly',
      data,
      marketplaceData,
      accntType,
    );
    return `
    ${
      totalFees.monthlyAmountAfterDiscount > 0
        ? `<tr>
            <td class="total-service-bordless"> Sub-total</td>
            <td class="total-service-bordless text-right">
             <span style=" background:#ffe5df; padding: 4px 9px;"> 
            ${selectedCurrency}${displayNumber(totalFees.monthlySubTotal)}
            </span>
            </td>
         </tr>`
        : ''
    }
    ${
      totalFees.monthlyAmountAfterDiscount > 0
        ? `<tr>
            <td class="total-service-bordless"> Discount ${
              totalFees?.monthlyAmountAfterDiscount &&
              totalFees?.monthlyDiscountType === 'percentage'
                ? `(${totalFees?.monthlyDiscount}%)`
                : ''
            }</td>
            <td class="total-service-bordless text-right">
             <span style=" background:#ffe5df; padding: 4px 9px;"> 
            -${selectedCurrency}${
            totalFees?.monthlyAmountAfterDiscount
              ? displayNumber(totalFees?.monthlyAmountAfterDiscount)
              : 0
          }
            </span>
            </td>
         </tr>`
        : ''
    }
      <tr>
        <td class="total-service" style="padding-top: 5px"> Total</td>
        <td class="total-service text-right" style="padding-top: 5px;"> 
         <span style=" background:#ffe5df; padding: 4px 9px;"> 
          ${selectedCurrency}${
      totalFees?.monthlyTotal
        ? displayNumber(totalFees.monthlyTotal?.toFixed(2))
        : 0
    }
          </span>
        </td>
      </tr>`;
  };

  const renderStdServiceRow = (service) => {
    const fields = [];
    for (const item of service) {
      if (!item.value && !item.label) {
        fields.push(
          `<td style="border: 1px solid black; padding: 13px;">${item.name}</td>`,
        );
      } else {
        fields.push(
          `<td style="border: 1px solid black; padding: 13px;">Listing Optimization - ${item.label} <br>
              *May vary by strategic priorities, <span style="font-weight: 800;"> up to <span style=" background:#ffe5df; padding: 4px 9px; font-weight: bold"> 
                ${item.value}
              </span>ASINs 
              </span>per month
            </td>`,
        );
      }
    }
    return fields;
  };

  const mapStandardServices = () => {
    if (
      (formData?.standard_us_services &&
        formData?.standard_us_services.length) ||
      formData?.content_optimization ||
      formData?.design_optimization
      // formData?.photography_optimization
    ) {
      let content = {};
      let design = {};
      // let photo = {};

      if (formData?.content_optimization) {
        content = {
          label: 'Copy',
          value: formData.content_optimization,
        };
      }
      if (formData?.design_optimization) {
        design = {
          label: 'Design',
          value: formData.design_optimization,
        };
      }
      // if (formData?.photography_optimization) {
      //   photo = {
      //     label: 'Photography',
      //     value: formData.photography_optimization,
      //   };
      // }
      const fields = [];
      // combine the standard services with listing optimization to show it properly in the table dynamically.
      let temp = [];
      if (formData?.standard_us_services?.length)
        temp = [...temp, ...formData.standard_us_services];
      if (content && Object.keys(content).length !== 0)
        temp = [...temp, content];
      if (design && Object.keys(design).length !== 0) temp = [...temp, design];
      // if (photo && Object.keys(photo).length !== 0) temp = [...temp, photo];

      const formattedData = new Array(Math.ceil(temp.length / 3))
        .fill()
        .map(() => temp.splice(0, 3));
      for (const item of formattedData) {
        fields.push(`<tr> ${renderStdServiceRow(item)} </tr>`);
      }
      return fields.length ? fields.toString().replaceAll(',', '') : '';
    }
    return '';
  };

  const mapVATRegistrationMarketplaces = () => {
    const marketplaces =
      formData?.standard_non_us_services?.length &&
      formData?.standard_non_us_services
        .filter((item) => item.name === 'VAT Registration')
        .map((filteredItem) => filteredItem.marketplace);
    const result = [];
    if (marketplaces?.length) {
      marketplaces.map((item) => {
        result.push(`${displayMarketplaceName(item, 'vat', 'lhs')}`);
        return null;
      });
    }
    return result.length ? result.toString().replaceAll(',', ', ') : '';
  };

  const mapVATFilingMarketplaces = () => {
    const marketplaces =
      formData?.standard_non_us_services?.length &&
      formData?.standard_non_us_services.filter(
        (item) =>
          item.name === 'VAT Filing' &&
          item.marketplace !== null &&
          item.frequency !== null,
      );

    const result = [];
    if (marketplaces?.length) {
      marketplaces.map((item) => {
        result.push(
          `${displayMarketplaceName(item.marketplace, 'vat', 'lhs')} (${
            item?.frequency
          })`,
        );
        return null;
      });
    }
    return result.length ? result.toString().replaceAll(',', ', ') : '';
  };

  const mapRemainingServices = () => {
    const result = [];
    const customerService =
      formData?.standard_non_us_services?.length &&
      formData?.standard_non_us_services.filter(
        (item) => item.name === 'International Customer Service',
      );

    const logistics =
      formData?.standard_non_us_services?.length &&
      formData?.standard_non_us_services.filter(
        (item) => item.name === 'International Logistics',
      );

    if (customerService?.length)
      result.push(`<td>${customerService?.[0]?.name}</td>`);
    if (logistics?.length) result.push(`<td>${logistics?.[0]?.name}</td>`);

    if (formData?.copy_listing_translation) {
      result.push(
        `<td>Listing Copy Translation -<span style="font-weight: 800"> ASIN's per month : <span style=" background:#ffe5df; padding: 4px 9px";> ${formData?.copy_listing_translation}</span></span></td>`,
      );
    }
    if (formData?.creative_listing_translation) {
      result.push(
        `<td>Listing Creative Translation - <span style="font-weight: 800"> ASIN's per month : <span style=" background:#ffe5df; padding: 4px 9px";> ${formData?.creative_listing_translation}</span></span></td>`,
      );
    }
    return result.length ? result.toString().replaceAll(',', '') : '';
  };

  const showStandardServicesNONUSMarketplacesTable = () => {
    return (!formData?.standard_non_us_services ||
      formData?.standard_non_us_services?.length === 0) &&
      !formData.copy_listing_translation &&
      !formData.creative_listing_translation
      ? ''
      : `<div class="table-responsive" style="margin-top: 40px">
        <table class=" contact-list " style="width: 100%; overflow:auto; border-collapse: collapse;">
          <tr><th colspan="5" style="text-align: left; border: 1px solid black;padding: 13px;">Service Components (Non-US Marketplaces)   </th>
          <tbody style="border: 1px solid black;">
          </tr>
         ${
           formData?.standard_non_us_services?.length &&
           formData?.standard_non_us_services.filter(
             (item) => item.name === 'VAT Registration',
           )?.length
             ? `<tr>
               <td colspan="5">VAT Registration: ${mapVATRegistrationMarketplaces()}</td>
             </tr>`
             : ''
         }
          ${
            formData?.standard_non_us_services?.length &&
            formData?.standard_non_us_services.filter(
              (item) => item.name === 'VAT Filing',
            )?.length
              ? `<tr><td colspan="5">VAT Filing: ${mapVATFilingMarketplaces()}</td></tr>`
              : ''
          }
          <tr>${mapRemainingServices()}</tr>
          </tbody>
          </table>
      </div>
          `;
  };

  // display standard services table and content
  const showStandardServicesTable = () => {
    if (
      (formData.standard_us_services?.length === 0 ||
        formData.standard_us_services === null) &&
      !formData.content_optimization &&
      !formData.design_optimization &&
      !formData.photography_optimization
    )
      return ``;
    return `
        <div class="text-center BT-SOW-standard-service mt-4" style="margin-top: 1.5rem!important; text-align:center;">
          <span style="font-weight: 800; font-family: Helvetica-bold;">Standard Services</span>
          <br> The following services will be provided to client during the term of the contract. 
        </div><br/>
        <div class="table-responsive">
          <table class="contact-list" style="width: 100%; overflow:auto; border-collapse: collapse;">
            <tr>
              <th colspan="3" style="text-align: left; border: 1px solid black;padding: 13px;">Service Components</th>
            </tr>
            ${mapStandardServices()}
          </table>
        ${
          formData?.design_optimization ||
          formData?.content_optimization ||
          formData?.photography_optimization
            ? `<br />
            ${displayListingOptimisationInfo()}
            <br />
            ${displayRevisionPolicyInfo()}`
            : ''
        }     
      </div>
  `;
  };

  const mapOnetimeServiceTotal = () => {
    const totalFees = calculateTodalFee('onetime');
    return `
    ${
      totalFees?.oneTimeAmountAfterDiscount
        ? `<tr>
            <td class="total-service-borderless" style="border-bottom: hidden; padding: 5px 13px" colspan="3"> Sub-total</td>
            <td class="total-service-borderless text-right" style="border-bottom: hidden; padding: 5px 13px">
            <span style=" background:#ffe5df; padding: 4px 9px; ">
            ${selectedCurrency}${displayNumber(
            totalFees?.oneTimeSubTotal?.toFixed(2),
          )}
            </span>
            </td>
         </tr>`
        : ''
    }
    ${
      totalFees?.oneTimeAmountAfterDiscount
        ? `<tr>
            <td class="total-service-borderless"style="border-bottom: hidden; padding: 5px 13px" colspan="3"> Discount ${
              totalFees?.oneTimeAmountAfterDiscount &&
              totalFees?.oneTimeDiscountType === 'percentage'
                ? `(${totalFees?.oneTimeDiscount}%)`
                : ''
            }</td>
            <td class="total-service-borderless text-right" style="border-bottom: hidden; padding: 5px 13px">
            <span style=" background:#ffe5df; padding: 4px 9px; ">
            
            -${selectedCurrency}${displayNumber(
            totalFees?.oneTimeAmountAfterDiscount?.toFixed(2),
          )}
            </span>
            </td>
         </tr>`
        : ''
    }        
         <tr>
            <td class="total-service" colspan="3" style=" padding-top: 5px "> Total</td>
            <td class="total-service text-right"style="padding-top: 5px ">
            <span style=" background:#ffe5df; padding: 4px 9px; ">
            
            ${selectedCurrency}${displayNumber(
      totalFees?.oneTimeTotal?.toFixed(2),
    )}
            </span>
            </td>
         </tr>
         `;
  };
  const customAmazonStorePrice = (fee) => {
    return `<td style="border: 1px solid black;padding: 13px;">
     <span style=" background:#ffe5df; padding: 4px 9px;"> 
                
        ${
          fee
            ? `${selectedCurrency}${displayNumber(fee)}`
            : `${selectedCurrency}0`
        }
        </span>
      </td>`;
  };
  const tdService = (service, fee) => {
    return `<td style="border: 1px solid black; padding: 13px;">
     <span style=" background:#ffe5df; padding: 4px 9px;"> 

          ${
            service.quantity && fee
              ? `${selectedCurrency}${displayNumber(service.quantity * fee)}`
              : `${selectedCurrency}0`
          }
          </span>
        </td>
        `;
  };
  const displayNumberOfASINs = (item) => {
    if (item?.name === 'A+ Content') {
      return `(<span style=" background:#ffe5df; padding: 4px 9px;">${item?.quantity}</span> ASINs)`;
    }
    if (item?.service?.name === 'A+ Content') {
      return `(<span style=" background:#ffe5df; padding: 4px 9px;">${item?.quantity} </span> ASINs)`;
    }
    return '';
  };
  const checkIsAPlusService = (item) => {
    if (item?.name && item?.name === 'A+ Content') {
      return true;
    }

    if (item?.service?.name && item?.service?.name === 'A+ Content') {
      return true;
    }
    return false;
  };

  // return the onetime service table row
  const showOneTimeServiceSubTabRow = (fields, serviceName) => {
    const oneTimeSpecificServicesTemp = oneTimeSpecificServices(
      formData,
      serviceName,
    );
    const oneTimeSpecificServicesFeesTemp = oneTimeSpecificServicesFees(
      servicesFees,
      serviceName,
    );

    if (oneTimeSpecificServicesTemp?.length)
      fields.push(`<tr>
      <td style="border: 1px solid black;padding: 13px; ">
             <span style=" background:#ffe5df; padding: 4px 9px;">

        ${oneTimeSpecificServicesTemp?.length ? '1' : ''}
        </span>
      </td>
      <td style="border: 1px solid black;padding: 13px; ">
        ${
          serviceName === 'A+' ? 'A+ Content' : serviceName
        } ${returnOneTimeServiceNames(oneTimeSpecificServicesTemp)}
      </td>
      <td style="border: 1px solid black;padding: 13px; ">
             <span style="background:#ffe5df; padding: 4px 9px; line-height: 1.7;">
          ${returnOneTimeServiceFees(
            servicesFees,
            selectedCurrency,
            oneTimeSpecificServicesTemp,
            oneTimeSpecificServicesFeesTemp,
          )}

        </span>
      </td>
      <td style="border: 1px solid black;padding: 13px; ">
      <span style="background:#ffe5df; padding: 4px 9px;">
      ${returnOneTimeServiceFeesTotal(
        servicesFees,
        selectedCurrency,
        oneTimeSpecificServicesTemp,
        oneTimeSpecificServicesFeesTemp,
      )}

      </td>
      <tr>`);
  };

  const mapMonthlyServices = (key, data) => {
    const fields = [];
    if (key === 'amc_services') {
      if (data) {
        if (formData?.is_dsp_advertising) {
          fields.push(
            `<tr>
                <td style="border: 1px solid black;padding: 13px;">DSP Advertising</td>
                <td style="border: 1px solid black;padding: 13px;">N/A</td>
                </tr>`,
          );
        }
        for (const item of data) {
          fields.push(
            `<tr>
                <td style="border: 1px solid black;padding: 13px;">AMC Management ${
                  item.name
                }</td>
                <td style="border: 1px solid black;padding: 13px;">  ${
                  item?.fee
                    ? `<span style=" background:#ffe5df; padding: 4px 9px;">${selectedCurrency}${displayNumber(
                        item?.fee,
                      )} /month </span>`
                    : item?.name === 'Standard Dashboard' ||
                      (item?.fee === 0 && item?.name === 'Advanced Dashboard')
                    ? `<span style=" background:#ffe5df; padding: 4px 9px;">FOC</span>`
                    : ' <span style=" background:#ffe5df; padding: 4px 9px;">$0 /month </span>'
                }</td>
                </tr>`,
          );
        }
      }
    } else if (key !== 'additional_one_time_services') {
      if (data) {
        for (const item of data) {
          const fixedFee = servicesFees.filter((n) => n.id === item.service_id);
          if (
            (item.service && item.service.name !== undefined) ||
            item.name !== undefined
          ) {
            if (
              item.name
                ? item.name !== 'Inventory Reconciliation'
                : item.service.name !== 'Inventory Reconciliation'
            ) {
              fields.push(
                `<tr>
                <td style="border: 1px solid black;padding: 13px;">${
                  item.service ? item.service.name : item?.name
                } ${displayNumberOfASINs(item, fixedFee)} </td>
                ${
                  item.service?.fee
                    ? `<td style="border: 1px solid black;padding: 13px;">
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 
                    ${selectedCurrency}${
                        item.service
                          ? `${displayNumber(
                              item?.service?.name === 'A+ Content'
                                ? item.service.fee * item?.quantity
                                : item.service.fee,
                            )}`
                          : item.fee
                          ? displayNumber(
                              item?.name === 'A+ Content'
                                ? item.fee * item?.quantity
                                : item.fee,
                            )
                          : ''
                      } /month
                    </span>
                  </td>`
                    : (
                        item.name
                          ? item.name === 'DSP Advertising'
                          : item.service.name === 'DSP Advertising'
                      )
                    ? `<td>  <span style=" background:#ffe5df; padding: 4px 9px;"> N/A </span></td>`
                    : `<td>
                     <span style=" background:#ffe5df; padding: 4px 9px;"> 
                    ${
                      fixedFee && fixedFee[0] && fixedFee[0].fee
                        ? `${selectedCurrency}${displayNumber(
                            checkIsAPlusService(item)
                              ? fixedFee[0].fee * item?.quantity
                              : fixedFee[0].fee,
                          )} /month`
                        : `${selectedCurrency}0`
                    }
                    </span>
                    </td>`
                }
                </tr>`,
              );
            }
          }
        }
      }
    } else if (formData && formData.additional_one_time_services) {
      formData.additional_one_time_services
        .filter((item) =>
          item?.name
            ? !item?.name?.includes('Amazon Storefront') &&
              !item?.name?.includes('Infographics') &&
              !item?.name?.includes('A+')
            : !item?.service?.name?.includes('Amazon Storefront') &&
              !item?.service?.name?.includes('Infographics') &&
              !item?.service?.name?.includes('A+'),
        )
        .forEach((service) => {
          const fixedFee = servicesFees.filter(
            (n) => n.id === service.service_id,
          );

          fields.push(
            `<tr>
            <td style="border: 1px solid black;padding: 13px; ">
                   <span style=" background:#ffe5df; padding: 4px 9px;"> 

              ${service.quantity ? displayNumber(service.quantity) : 0}
              </span>
            </td>                 
            <td style="border: 1px solid black;padding: 13px;">
              ${
                service.name
                  ? service.name
                  : service.service?.name
                  ? service.service.name
                  : ''
              }
            </td>            
             ${
               (
                 service && service.name
                   ? service.name.includes('Amazon Store Package Custom')
                   : service?.service?.name.includes(
                       'Amazon Store Package Custom',
                     )
               )
                 ? service.custom_amazon_store_price
                   ? `<td>
                   <span style=" background:#ffe5df; padding: 4px 9px; "> 
                  ${selectedCurrency}${displayNumber(
                       service.custom_amazon_store_price,
                     )}</span></td>`
                   : customAmazonStorePrice(
                       fixedFee && fixedFee[0] && fixedFee[0].fee,
                     )
                 : service?.service?.fee
                 ? customAmazonStorePrice(service?.service?.fee)
                 : customAmazonStorePrice(
                     fixedFee && fixedFee[0] && fixedFee[0].fee,
                   )
             } 
            ${
              (
                service && service.name
                  ? service.name !== 'Amazon Store Package Custom'
                  : service &&
                    service.service?.name !== 'Amazon Store Package Custom'
              )
                ? service.quantity && service.service?.fee
                  ? tdService(service, service.service?.fee)
                  : tdService(
                      service,
                      fixedFee && fixedFee[0] && fixedFee[0].fee,
                    )
                : service.quantity && service.custom_amazon_store_price
                ? tdService(service, service.custom_amazon_store_price)
                : tdService(service, fixedFee && fixedFee[0] && fixedFee[0].fee)
            }                
          </tr>`,
          );
          // }

          return fields;
        });
      showOneTimeServiceSubTabRow(fields, 'Amazon Storefront');
      showOneTimeServiceSubTabRow(fields, 'Infographics');
      showOneTimeServiceSubTabRow(fields, 'A+');
      // showAllTableSubtabRow(fields);
    }
    return fields.length
      ? fields
          .toString()
          .replaceAll('>,<', '><')
          .replaceAll(',,', '')
          .replaceAll(',,,', '')
      : '';
  };

  const getServicesAccordingToAccType = (data, option) => {
    const result = data && data.filter((item) => item.account_type === option);
    return result;
  };

  const showAMCServicesTable = () => {
    if (formData?.is_dsp_advertising || formData?.is_amc_management) {
      return `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center">
      <span style="font-weight: 800; font-family: Helvetica-bold;">Additional Advertising Services </span>
      <br> The following additional monthly services will be provided to Client in addition to the Monthly Retainer.</div><br>
      <div class="table-responsive">
        <table class="contact-list ">
          <tr>
            <th>Service</th>
            <th>Service Fee</th>
          </tr>
            ${mapMonthlyServices('amc_services', formData?.amc_services)}
          ${mapMonthlyServiceTotal(formData?.amc_services, '', '')}

        </table>
      </div>`;
    }
    return '';
  };

  const showMonthlyServiceTable = (accntTYpe, data, marketplaceData) => {
    if (data?.length || marketplaceData?.length) {
      return `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center">
      <span style="font-weight: 800; font-family: Helvetica-bold;">Additional Monthly Services for Amazon ${accntTYpe} Account </span>
      <br> The following additional monthly services will be provided to Client in addition to the Monthly Retainer.</div><br>
      <div class="table-responsive">
        <table class="contact-list ">
          <tr>
            <th>Service</th>
            <th>Service Fee</th>
          </tr>
            ${mapMonthlyServices('additional_monthly_services', data)}
          ${mapAdditionalMarketPlaces(marketplaceData)}
          ${mapMonthlyServiceTotal(data, marketplaceData, accntTYpe)}
          ${mapVariableMonthlyService(data)}   
        </table>
      </div>`;
    }
    return '';
  };

  const showMonthlyServiceTablesAccordingToAccType = () => {
    if (accountType === 'Seller') {
      const sellerResult = getServicesAccordingToAccType(
        formData?.additional_monthly_services,
        'Seller',
      );
      const marketplaceResult = getServicesAccordingToAccType(
        formData?.additional_marketplaces,
        'Seller',
      );
      return showMonthlyServiceTable('Seller', sellerResult, marketplaceResult);
    }
    if (accountType === 'Vendor') {
      const vendorResult = getServicesAccordingToAccType(
        formData?.additional_monthly_services,
        'Vendor',
      );
      const marketplaceResult = getServicesAccordingToAccType(
        formData?.additional_marketplaces,
        'Vendor',
      );
      return showMonthlyServiceTable('Vendor', vendorResult, marketplaceResult);
    }
    if (accountType === 'Hybrid') {
      const sellerResult = getServicesAccordingToAccType(
        formData?.additional_monthly_services,
        'Seller',
      );

      const vendorResult = getServicesAccordingToAccType(
        formData?.additional_monthly_services,
        'Vendor',
      );

      const sellerMarketplaceResult = getServicesAccordingToAccType(
        formData?.additional_marketplaces,
        'Seller',
      );

      const vendorMarketplaceResult = getServicesAccordingToAccType(
        formData?.additional_marketplaces,
        'Vendor',
      );

      if (
        (sellerResult && sellerResult.length) ||
        (vendorResult && vendorResult.length) ||
        (sellerMarketplaceResult && sellerMarketplaceResult.length) ||
        (vendorMarketplaceResult && vendorMarketplaceResult.length)
      ) {
        const sellerTable = showMonthlyServiceTable(
          'Seller',
          sellerResult,
          sellerMarketplaceResult,
        );
        const venderTable = showMonthlyServiceTable(
          'Vendor',
          vendorResult,
          vendorMarketplaceResult,
        );

        return sellerTable + venderTable;
      }

      if (sellerResult && sellerResult.length) {
        return showMonthlyServiceTable(
          'Seller',
          sellerResult,
          sellerMarketplaceResult,
        );
      }

      if (vendorResult && vendorResult.length) {
        return showMonthlyServiceTable(
          'Vendor',
          vendorResult,
          vendorMarketplaceResult,
        );
      }
    }
    return '';
  };

  const showOneTimeServiceTable = () => {
    if (formData?.additional_one_time_services?.length) {
      return `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center;">
        <span style="font-weight: 800; font-family: Helvetica-bold;">Additional One Time Services </span>
        <br>The following additional monthly services will be provided to Client as a one time service in addition to the Monthly Retainer and any Additional Monthly services.</div><br>
        <div class="table-responsive">
        <table class="contact-list ">
          <tr>
            <th>Quantity</th>
            <th>Service</th>
            <th>Service Fee</th>
            <th>Total Service Fee</th>
          </tr>
          ${mapMonthlyServices(
            'additional_one_time_services',
            'One Time Services',
          )}
        ${mapOnetimeServiceTotal()}
        </table>
      </div>`;
    }
    return '';
  };

  const showBillingCap = () => {
    if (formData && formData.billing_cap) {
      return `<br><br><div class=" text-center " style="text-align: center;">
      <span style="font-weight: 800; font-family: Helvetica-bold;"> Billing Cap </span> </div>
      <div style="text-align: center;">Maximum amount that will be charged between the monthly retainer and revenue share.</div>
      <div class=" text-center input-contact-value mt-3" style="margin-top: 1rem!important; text-align: center;">
        <span style="background:#ffe5df;padding: 4px 9px; font-weight: bold"> 
          ${mapDefaultValues('billing_cap', 'Billing Cap', 'number-currency')}
        </span>
      </div>`;
    }
    return '';
  };

  // PDV-8477 - Remove the table enumerating the “services not included” portion
  // additional services which is not included in contract
  const showNotIncludedServicesTable = () => {
    if (
      notIncludedMonthlyServices?.length ||
      notIncludedOneTimeServices?.length
    ) {
      return `<div class=" text-center mt-4 " style="margin-top: 1.5rem!important; text-align: center;">
        <span style="font-weight: 800; font-family: Helvetica-bold;">Additional Services Not Included</span>
          <br>Anything not listed in this statement of work can be purchased at the fee scheduled in place at the time of the request.</div>`;
    }
    return '';
  };

  return (
    <>
      <StatementParagraph>
        <p
          className="long-text"
          dangerouslySetInnerHTML={{
            __html:
              templateData.statement_of_work &&
              templateData.statement_of_work[0]
                .replace('BBE_LOGO', displayBBELogo())
                .replace(
                  'CUSTOMER_NAME',
                  mapDefaultValues('company_name', 'Customer Name'),
                )
                .replace(
                  '{{Signdate}}',
                  mapDefaultValues('start_date', 'Start Date'),
                )
                .replace(
                  'SELLER_TYPE',
                  mapDefaultValues('seller_type', 'Amazon Account Type'),
                )
                .replace('BILLING_CAP_AMOUNT', showBillingCap())
                .replace('REV_SHARE_TABLE', showRevTable())
                .replace(
                  'REVENUE_SHARE',
                  mapDefaultValues('rev_share', 'Rev Share'),
                )
                .replace(
                  'REV_THRESHOLD',
                  mapDefaultValues('threshold_type', 'Rev Threshold'),
                )

                .replace(
                  'PRIMARY_MARKETPLACE',
                  mapDefaultValues(
                    'primary_marketplace',
                    'Primary Marketplace',
                  ),
                )
                .replace(
                  'MAP_MONTHLY_SERVICES',
                  showMonthlyServiceTablesAccordingToAccType(),
                )
                .replace('MAP_AMC_MONTHLY_SERVICES', showAMCServicesTable())
                .replace(
                  'AMAZON_ACCOUNT_TYPE',
                  mapDefaultValues('seller_type', 'Seller Type', 'amazon'),
                )
                .replace('ONE_TIME_SERVICES', showOneTimeServiceTable())
                .replace(
                  'MAP_STANDARD_SERVICE_TABLE',
                  showStandardServicesTable(),
                )
                .replace(
                  'MAP_STANDARD_SERV_FOR_NON_US_MARKETPLACES_TABLE',
                  showStandardServicesNONUSMarketplacesTable(),
                )
                .replace(
                  'ADDITIONAL_SERVICES_NOT_INCLUDED',
                  showNotIncludedServicesTable(),
                )
                .replace(
                  'BILLING_CAP_AMOUNT',
                  mapDefaultValues(
                    'billing_cap',
                    'Billing Cap',
                    'number-currency',
                  ),
                ),
          }}
        />
      </StatementParagraph>
    </>
  );
}

Statement.defaultProps = {
  formData: {},
  templateData: {},
  notIncludedOneTimeServices: [],
  notIncludedMonthlyServices: [],
  servicesFees: {},
  discountData: [],
  selectedCurrency: '$ (USD)',
  displayMarketplaceName: () => {},
  displayListingOptimisationInfo: () => {},
  displayRevisionPolicyInfo: () => {},
  displayBBELogo: () => {},
  startDate: '',
};
Statement.propTypes = {
  formData: shape({
    length: oneOfType([
      string,
      shape({
        value: string,
        label: string,
      }),
    ]),
    sales_threshold: string,
    billing_cap: oneOfType([string, number]),
    additional_marketplaces: arrayOf(
      shape({
        service: string,
      }),
    ),
    additional_one_time_services: arrayOf(
      shape({
        service: shape({
          id: string,
        }),
      }),
    ),
    seller_type: shape({
      value: string,
      label: string,
    }),
    primary_marketplace: oneOfType([
      string,
      shape({
        fee: number,
        name: string,
        id: string,
      }),
    ]),
    additional_monthly_services: arrayOf(
      shape({
        service: shape({
          id: string,
        }),
      }),
    ),
    content_optimization: number,
    design_optimization: number,
    customer_id: shape({
      address: string,
      city: string,
      state: string,
      zip_code: string,
    }),
    monthly_discount_amount: oneOfType([string, number]),
    monthly_discount_type: oneOfType([string, number]),
    one_time_discount_type: oneOfType([string, number]),
    one_time_discount_amount: oneOfType([string, number]),
    threshold_type: string,
    yoy_percentage: string,
  }),
  templateData: shape({
    addendum: arrayOf(string),
    statement_of_work: arrayOf(string),
  }),
  notIncludedOneTimeServices: arrayOf(
    shape({
      label: string.isRequired,
    }),
  ),
  notIncludedMonthlyServices: arrayOf(
    shape({
      label: string.isRequired,
    }),
  ),
  servicesFees: arrayOf(
    shape({
      fee: number,
      filter: func,
    }),
  ),
  discountData: arrayOf(shape({})),
  selectedCurrency: string,
  displayMarketplaceName: func,
  displayListingOptimisationInfo: func,
  displayRevisionPolicyInfo: func,
  displayBBELogo: func,
  startDate: instanceOf(Date),
};
