import React from 'react';

import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { shape, string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { setCustomerSelectedTab } from '../../../store/actions/customerState';
import {
  HYBRID,
  PARENT,
  PATH_CUSTOMER_DETAILS,
  SELLER,
  VENDOR,
  SELLERSMALL,
  VENDORSMALL,
} from '../../../constants';

export default function CustomerTabDetails({
  role,
  customer,
  showPlaybook,
  viewComponent,
  playbookTabActive,
  isBBEInternalUser,
  sidePanelToggle,
}) {
  const history = useHistory();
  const customerAccountType = customer?.customer_account_type;
  const dispatch = useDispatch();

  const handleMenuOnclick = (selectedTab, selectedNavTab) => {
    history.push(
      `${PATH_CUSTOMER_DETAILS.replace(
        ':id',
        customer?.id,
      )}?tab=${selectedTab}${
        selectedTab === 'performance'
          ? `&sub=${
              customerAccountType === HYBRID ||
              customerAccountType === SELLER ||
              customer?.account_type === PARENT
                ? SELLERSMALL
                : VENDORSMALL
            }`
          : ''
      }${
        selectedNavTab ? `&nav=${selectedNavTab}` : ''
      }&access=${isBBEInternalUser}`,
      selectedNavTab,
    );
    dispatch(setCustomerSelectedTab(selectedTab || 'performance'));
  };

  // display tooltip box
  const displayTooltipBox = (id) => {
    return (
      <ReactTooltip
        ReactTooltip
        id={id}
        key={id}
        class="initialTextTransform"
        aria-haspopup="true"
        place="right"
        effect="solid"
      />
    );
  };

  const renderCompanyPerformance = () => {
    const tabLabel = 'Performance';
    const tooltipId = 'performance';
    return (
      <li
        onClick={() => {
          handleMenuOnclick('performance', 'salePerformance');
        }}
        role="presentation"
      >
        <div
          style={{ position: 'relative' }}
          className={`left-details ${
            viewComponent === 'performance' ? 'active' : ''
          }`}
        >
          <FontAwesomeIcon
            className="customer-details-tab-icon"
            icon="fa-light fa-monitor-waveform"
            data-tip={tabLabel}
            data-for={tooltipId}
          />
          {displayTooltipBox(tooltipId)}
          <div className="tab-label">{tabLabel}</div>
        </div>
      </li>
    );
  };

  return (
    <ul
      className={`left-details-card d-lg-block d-none ${
        sidePanelToggle?.isToggle ? 'active' : ''
      }`}
    >
      {/* {role === 'Customer' && customer?.display_summary_dashboard ? ( */}
      {role === 'Customer' ? (
        <li
          onClick={() => {
            handleMenuOnclick('summary');
          }}
          role="presentation"
        >
          <div
            className={`left-details ${
              viewComponent === 'summary' ? 'active' : ''
            }`}
          >
            <FontAwesomeIcon
              className="customer-details-tab-icon"
              icon="fa-light fa-shapes"
              data-tip="Summary"
              data-for="summary"
            />
            {displayTooltipBox('summary')}
            <div className="tab-label">Summary</div>
          </div>
        </li>
      ) : null}

      {role === 'Customer' ? (
        <li
          onClick={() => {
            handleMenuOnclick('dashboard');
          }}
          role="presentation"
        >
          <div
            className={`left-details ${
              viewComponent === 'dashboard' ? 'active' : ''
            }`}
          >
            <FontAwesomeIcon
              className="customer-details-tab-icon"
              icon="fal fa-tachometer-fast"
              data-tip="Dashboard"
              data-for="dashboard"
            />
            {displayTooltipBox('dashboard')}
            <div className="tab-label">Dashboard</div>
          </div>
        </li>
      ) : null}

      {/* ********** PDV-7917 - Expose AMC and other Sections to BPs ********** */}
      {/* ********** PLEASE DO NOT REMOVE BELOW CODE. ********** */}
      {/* {role !== 'Customer'
        ? renderCompanyPerformance()
        : role === 'Customer' &&
          (customer?.display_ad_performance ||
            customer?.display_sales_performance ||
            customer?.display_seller_health)
        ? renderCompanyPerformance()
        : null} */}

      {/* ********** performance tab ********** */}
      {renderCompanyPerformance()}

      {/* ********** inventory tab ********** */}
      {/* {role !== 'Customer' ||
      (role === 'Customer' &&
        (customer?.display_inventory_management ||
          customer?.display_under_over_stocked_inventory)) ? ( */}

      {/* PDV-8957 Hide Inventory Management tab for Vendor and Dsp Only BPs */}
      {customerAccountType === null || customerAccountType === VENDOR ? null : (
        <li
          onClick={() => {
            handleMenuOnclick('inventoryManagement');
          }}
          role="presentation"
        >
          <div
            className={`left-details ${
              viewComponent === 'inventoryManagement' ? 'active' : ''
            }`}
          >
            <FontAwesomeIcon
              className="customer-details-tab-icon"
              icon="fal fa-boxes"
              data-tip="Inventory Management"
              data-for="inventory"
            />
            {displayTooltipBox('inventory')}
            <div className="tab-label">Inventory Management</div>
          </div>
        </li>
      )}
      {/* ) : null} */}

      {/* ********** playbook tab ********** */}
      {/* ********** PDV-8166 - Hide the Playbook for the external users or Customer Login ********** */}
      {showPlaybook &&
      customer?.account_type !== PARENT &&
      role !== 'Customer' ? (
        <li
          onClick={() => {
            handleMenuOnclick('playbook', playbookTabActive);
            dispatch(setCustomerSelectedTab('playbook'));
          }}
          role="presentation"
        >
          <div
            style={{ position: 'relative' }}
            className={`left-details ${
              viewComponent === 'playbook' ? 'active' : ''
            }`}
          >
            <FontAwesomeIcon
              className="customer-details-tab-icon"
              icon="fal fa-book"
              data-tip="Playbook"
              data-for="playbook"
            />
            {displayTooltipBox('playbook')}
            <div className="tab-label">Playbook</div>
          </div>
        </li>
      ) : null}

      {/* *****  Goals and strategy ******* */}
      {customer?.account_type !== PARENT ? (
        <li
          onClick={() => {
            handleMenuOnclick('goals', 'goals');
            dispatch(setCustomerSelectedTab('goals'));
          }}
          role="presentation"
        >
          <div
            style={{ position: 'relative' }}
            className={`left-details ${
              viewComponent === 'goals' ? 'active' : ''
            }`}
          >
            <FontAwesomeIcon
              className="customer-details-tab-icon"
              icon="fa-light fa-bullseye-arrow"
              data-tip="Forecast and Strategy"
              data-for="goals"
            />
            {displayTooltipBox('goals')}
            <div className="tab-label">Forecast and Strategy</div>
          </div>
        </li>
      ) : null}

      {/* ********** agreement tab ********** */}
      <li
        onClick={() => {
          handleMenuOnclick('agreement', 'recurring');
          dispatch(setCustomerSelectedTab('agreement'));
        }}
        role="presentation"
      >
        <div
          className={`left-details ${
            viewComponent === 'agreement' ? 'active' : ''
          }`}
        >
          <FontAwesomeIcon
            className="customer-details-tab-icon"
            icon="fa-light fa-file-contract"
            data-tip="Agreements"
            data-for="agreements"
          />
          {displayTooltipBox('agreements')}
          <div className="tab-label">Agreements</div>
        </div>
      </li>

      <li
        onClick={() => {
          handleMenuOnclick('company-details');
          dispatch(setCustomerSelectedTab('company-details'));
        }}
        role="presentation"
      >
        <div
          className={`left-details ${
            viewComponent === 'company-details' ? 'active' : ''
          }`}
        >
          <FontAwesomeIcon
            className="customer-details-tab-icon"
            icon="fal fa-building"
            data-tip="Company Details"
            data-for="company-details"
          />
          {displayTooltipBox('company-details')}
          <div className="tab-label">Company Details</div>
        </div>
      </li>
      <li
        onClick={() => handleMenuOnclick('billing', 'billing-details')}
        role="presentation"
      >
        <div
          className={`left-details ${
            viewComponent === 'billing' ? 'active' : ''
          }`}
        >
          <FontAwesomeIcon
            className="customer-details-tab-icon"
            icon="fa-light fa-file-invoice-dollar"
            data-tip="Billing"
            data-for="billing"
          />
          {displayTooltipBox('billing')}
          <div className="tab-label">Billing</div>
        </div>
      </li>
      {role !== 'Customer' ? (
        <>
          <li
            onClick={() => {
              handleMenuOnclick('activity');
              dispatch(setCustomerSelectedTab('activity'));
            }}
            role="presentation"
          >
            <div
              className={`left-details ${
                viewComponent === 'activity' ? 'active' : ''
              }`}
            >
              <FontAwesomeIcon
                className="customer-details-tab-icon"
                icon="fal fa-exchange"
                data-tip="Activity"
                data-for="activity"
              />
              {displayTooltipBox('activity')}
              <div className="tab-label">Activity</div>
            </div>
          </li>
          <li
            onClick={() => {
              handleMenuOnclick('feedback');
              dispatch(setCustomerSelectedTab('feedback'));
            }}
            role="presentation"
          >
            <div
              className={`left-details ${
                viewComponent === 'feedback' ? 'active' : ''
              }`}
            >
              <FontAwesomeIcon
                className="customer-details-tab-icon"
                icon="fal fa-message"
                data-tip="Partner Feedback"
                data-for="partner-feedback"
              />
              {displayTooltipBox('partner-feedback')}
              <div className="tab-label">Partner Feedback</div>
            </div>
          </li>
        </>
      ) : null}

      {role === 'Customer' ? (
        <li
          onClick={() => {
            handleMenuOnclick('reports');
          }}
          role="presentation"
        >
          <div
            className={`left-details ${
              viewComponent === 'reports' ? 'active' : ''
            }`}
          >
            <FontAwesomeIcon
              className="customer-details-tab-icon"
              icon="fa-light fa-file-chart-column"
              data-tip="Reports"
              data-for="reports"
            />
            {displayTooltipBox('reports')}
            <div className="tab-label">Reports</div>
          </div>
        </li>
      ) : null}
    </ul>
  );
}

CustomerTabDetails.defaultProps = {
  showPlaybook: false,
  playbookTabActive: '',
  isBBEInternalUser: 'internal',
  sidePanelToggle: {},
};

CustomerTabDetails.propTypes = {
  role: string.isRequired,
  playbookTabActive: string,
  viewComponent: string.isRequired,
  customer: shape({
    id: string,
  }).isRequired,
  showPlaybook: bool,
  isBBEInternalUser: string,
  sidePanelToggle: shape({}),
};

// brand assets tab don't remove this code.

//  {
//    customer && customer.brand_assets && customer.brand_assets.is_completed ? (
//      <li
//        onClick={() => {
//          handleMenuOnclick('brand asset');
//          // dispatch(setCustomerSelectedTab('brand asset'));
//        }}
//        role="presentation">
//        <div
//          className={`left-details ${
//            viewComponent === 'brand asset' ? 'active' : ''
//          }`}>
//          <FontAwesomeIcon
//            className="customer-details-tab-icon"
//            icon="fa-light fa-box"
//          />
//          Brand Assets
//        </div>
//      </li>
//    ) : (
//      ''
//    );
//  }

//  waiting for MWS token
//   {role === 'Customer' ? (
//                           ''
//                         ) : (
//                           <li
//                             onClick={() => {
//                               handleMenuOnclick('product catalog');
//                               dispatch(
//                                 setCustomerSelectedTab('product catalog'),
//                               );
//                             }}
//                             role="presentation">
//                             <div
//                               className={`left-details ${
//                                 viewComponent === 'product catalog'
//                                   ? 'active'
//                                   : ''
//                               }`}>
//                               <img
//                                 className="file-contract"
//                                 src={CatalogBox}
//                                 alt=""
//                               />
//                               Product Catalog
//                             </div>
//                           </li>
//                         )}
