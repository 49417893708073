import styled from 'styled-components';

import Theme from '../../theme/Theme';

export const ThanksPage = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: 0 auto;

  .info {
    color: ${Theme.gray90};
    font-size: ${Theme.title};
    font-family: ${Theme.baseFontFamily};
  }
`;

export const PageNotFounds = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .not-found {
    color: ${Theme.gray90};
    font-size: 16px;
    text-align: center;
  }
`;
