import React, { useState, useCallback, useEffect, useRef } from 'react';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Select, { components } from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, string, shape, bool, func } from 'prop-types';

import Theme from '../../../../theme/Theme';
import PlaybookTabDetails from './PlaybookTabDetails';
import { DropDown } from '../../../../common/DropDowns';
import { PlaybookNavbar, PlaybookStyle, RefreshButton } from './PlaybookStyle';
import {
  CustomDropDown,
  Tabs,
  DropdownIndicator,
  WhiteCard,
  ViewData,
  CustomMonthRangeModal,
  MultiSelectDropdown,
  GrayMessageBar,
} from '../../../../common';
import {
  playbookAccountTypeOptions,
  PATH_CUSTOMER_DETAILS,
  playbookTabsConstants,
  HYBRID,
  SELLER,
  VENDOR,
} from '../../../../constants';
import {
  createCustomerPlaybook,
  getOnboardingPlaybookData,
  getPlaybookTaskAndServicesData,
  getOnboardingPBTaskUpdateID,
  createCustomerPlaybookFromSummary,
} from '../../../../api';

const PlaybookContainer = ({
  isCustomer,
  customerId,
  playbookEpicId,
  marketplacesList,
  playbookTeamsList,
  playbookTabActive,
  isBBEInternalUser,
  isUserAssignedToBP,
  customerAccountType,
  playbookServiceList,
  originalMarketplaceList,
  playbookAccess,
  onboardingPlaybookAccess,
}) => {
  const history = useHistory();
  const mounted = useRef(false);

  // https://bbe.atlassian.net/browse/PDV-8930
  // Show dynamic messages in playbook
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );

  const { search } = history?.location;
  const isTablet = useMediaQuery({ minWidth: 768 });
  const { Option, SingleValue } = components;
  const [createPlaybookBtnLoader, setCreatePlaybookBtnLoader] = useState(false);
  const [
    updatedServicesOfPlaybookSummary,
    setUpdatedServicesOfPlaybookSummary,
  ] = useState({ create: [], delete: [] });
  const [showSummaryPage, setShowSummaryPage] = useState(false);

  const getAccountType =
    customerAccountType === HYBRID ? SELLER : customerAccountType;
  const [playbookTab, setPlaybookTab] = useState(
    playbookTabActive === 'onboarding' && search?.includes('nav=playbook')
      ? 'playbook'
      : playbookTabActive === 'onboarding' && search?.includes('nav=onboarding')
      ? 'onboarding'
      : search?.includes('nav=standard-monthly')
      ? 'standard-monthly'
      : search?.includes('nav=additional-one-time')
      ? 'additional-one-time'
      : search?.includes('nav=onboarding-gray')
      ? 'onboarding-gray'
      : playbookTabActive,
  );

  // marketplace filter use states
  const [selectedMarketplaces, setSelectedMarketplaces] =
    useState(marketplacesList);

  const [singleSelectedMarketplaces, setSingleSelectedMarteplace] = useState(
    originalMarketplaceList[0],
  );

  // team member filter use states
  const [selectedTeamMembers, setSelectedTeamMembers] =
    useState(playbookTeamsList);

  // service use states
  const [selectedService, setSelectedService] = useState(
    playbookServiceList?.[0],
  );
  const [accountType, setAccountType] = useState({
    label: getAccountType,
    value: getAccountType,
  });

  const [onboardingPlaybookLoader, setOnboardingPlaybookLoader] =
    useState(false);
  const [playbookDataLoader, setPlaybookDataLoader] = useState(false);
  const [servicePlaybookErrorMsg, setServicePlaybookErrorMsg] = useState('');
  const [onboardingPlaybookData, setOnboardingPlaybookData] = useState([]);
  const [onboardingPBTaskUpdateID, setOnboardingPBTaskUpdateID] = useState();
  const [playbookTaskData, setPlaybookTaskData] = useState([]);
  const [playbookId, setPlaybookId] = useState(null);
  const [playbookAPIError, setPlaybookAPIError] = useState(null);
  const [showCustomMonthModal, setShowCustomMonthModal] = useState(false);
  const newDate = new Date();
  newDate.setDate(1);

  const todaysDate = dayjs(newDate).format('YYYY-MM-DD');

  const [customStartDate, setCustomStartDate] = useState(todaysDate);
  const [customEndDate, setCustomEndDate] = useState(todaysDate);
  const currentDate = new Date();
  currentDate.setDate(1);
  const [customMonthRange, setCustomMonthRange] = useState([
    {
      startMonth: currentDate,
      endMonth: currentDate,
      key: 'playbookMonthSelection',
    },
  ]);

  const dateFilterOptions = [
    {
      label: 'Custom Month Range',
      value: 'custom-month',
      subLabel: 'Select Start and End Month',
    },
  ];

  const [dateType, setDateType] = useState(dateFilterOptions?.[0]);

  // calculate past and future months
  const getPastAndfutureDate = (date, type, value) => {
    if (type === 'date') {
      date.setDate(date.getDate() - value);
    }
    if (type === 'month') {
      date.setMonth(date.getMonth() - value);
      date.setDate('01');
    }

    return dayjs(date).format('YYYY-MM-DD');
  };

  const yesterday = getPastAndfutureDate(new Date(), 'date', 1);

  // calculte the last day of month
  const getLastDayOfMonth = (year, month, index) => {
    const lastDay = new Date(year, month, index);
    return lastDay;
  };

  const pastMonthDate = {
    firstDay: getPastAndfutureDate(new Date(), 'month', 1),
    lastDay: dayjs(
      getLastDayOfMonth(newDate?.getFullYear(), newDate?.getMonth(), 0),
    ).format('YYYY-MM-DD'),
  };
  const past3MonthDate = {
    firstDay: getPastAndfutureDate(new Date(), 'month', 3),
    lastDay: dayjs(
      getLastDayOfMonth(newDate?.getFullYear(), newDate?.getMonth(), 0),
    ).format('YYYY-MM-DD'),
  };

  // get id for changing the task status
  const getChangeTaskStatusId = useCallback(() => {
    const apiParams = {
      customer: customerId,
      onboarding: true,
    };
    setOnboardingPBTaskUpdateID();
    getOnboardingPBTaskUpdateID(apiParams).then((response) => {
      if (mounted.current) {
        if (response?.status === 200) {
          setOnboardingPBTaskUpdateID(response?.data?.results?.[0]?.id);
        } else {
          setOnboardingPBTaskUpdateID();
        }
      }
    });
  }, [customerId]);

  // bind subtask and hyperlink
  const bindTaskNameWithDocUrl = (task, label) => {
    if (task?.hyperlink_text && task?.link?.length) {
      const nameWithLink = `<a className="initialTextTransform hyperlink-text" href=${task?.link?.[0]} target="_blank" style="text-transform: initial; color: ${Theme.orange}" >
          ${task?.hyperlink_text}
        </a>`;
      const returnString = task?.[label]?.replace(
        task?.hyperlink_text,
        nameWithLink,
      );
      return returnString;
    }

    return task?.[label];
  };

  // bind onboarding playbook response data
  const bindPlaybookTaskData = useCallback((response) => {
    const responseData = response?.length ? response : [];
    const tempPlaybookdata = [];
    let deliverableAssignee = {};
    let taskAssignee = {};
    let subTaskAssignee = {};

    for (const item of responseData) {
      const tasks = [];
      if (item?.tasks?.length) {
        for (const task of item?.tasks) {
          const subTasks = [];

          // binding sub task data
          if (task?.sub_tasks?.length) {
            for (const subTask of task?.sub_tasks) {
              if (subTask?.first_name === null && subTask?.last_name === null) {
                subTaskAssignee = {};
              } else {
                subTaskAssignee = [
                  {
                    first_name: subTask?.first_name,
                    last_name: subTask?.last_name,
                  },
                ];
              }

              subTasks.push({
                id: subTask?.id,
                sub_task_id: subTask?.sub_task_id,
                sub_task_name: bindTaskNameWithDocUrl(subTask, 'sub_task_name'),
                team: subTask?.team?.replace(/[[\]']+/g, ''),
                due_date:
                  subTask?.due_date === null
                    ? ''
                    : dayjs(subTask?.due_date).format('DD MMM'),
                jira_id: subTask?.jira_id,
                jira_status: subTask?.jira_status,
                assignee: subTaskAssignee,
              });
            }
          }

          // binding task data
          if (task?.first_name === null && task?.last_name === null) {
            taskAssignee = {};
          } else {
            taskAssignee = [
              {
                first_name: task?.first_name,
                last_name: task?.last_name,
              },
            ];
          }

          tasks.push({
            id: task?.id,
            task_id: task?.task_id,
            task_name: task?.task_name,
            team: task?.team?.replace(/[[\]']+/g, ''),
            due_date:
              task?.due_date === null
                ? ''
                : dayjs(task?.due_date).format('DD MMM'),
            jira_id: task?.jira_id,
            jira_status: task?.jira_status,
            assignee: taskAssignee,
            sub_tasks: subTasks,
          });
        }
      }

      // binding deliverable data
      if (item?.first_name === null && item?.last_name === null) {
        deliverableAssignee = {};
      } else {
        deliverableAssignee = [
          {
            first_name: item?.first_name,
            last_name: item?.last_name,
          },
        ];
      }

      tempPlaybookdata.push({
        id: item?.id,
        deliverable_id: item?.deliverable_task_id,
        deliverable_name: item?.task_name?.toLowerCase(),
        team: item?.team?.replace(/[[\]']+/g, ''),
        due_date:
          item?.due_date === null ? '' : dayjs(item?.due_date).format('DD MMM'),
        jira_id: item?.jira_id,
        jira_status: item?.jira_status,
        assignee: deliverableAssignee,
        tasks,
      });
    }
    return tempPlaybookdata;
  }, []);

  // bind service playbook response data
  const bindServicePlaybookTaskData = useCallback(
    (response) => {
      const responseData = response?.length ? response : [];
      const tempPlaybookdata = [];
      let serviceAssignee = {};
      let deliverableAssignee = {};
      let taskAssignee = {};
      let subTaskAssignee = {};

      for (const item of responseData) {
        const deliverables = [];
        if (item?.deliverables?.length) {
          for (const deliverable of item?.deliverables) {
            const tasks = [];
            if (deliverable?.tasks?.length) {
              for (const task of deliverable?.tasks) {
                const subTasks = [];

                // binding sub task data
                if (task?.sub_tasks?.length) {
                  for (const subTask of task?.sub_tasks) {
                    if (
                      subTask?.first_name === null &&
                      subTask?.last_name === null
                    ) {
                      subTaskAssignee = {};
                    } else {
                      subTaskAssignee = [
                        {
                          first_name: subTask?.first_name,
                          last_name: subTask?.last_name,
                        },
                      ];
                    }

                    subTasks.push({
                      id: subTask?.id,
                      sub_task_id: subTask?.sub_task_id,
                      sub_task_name: bindTaskNameWithDocUrl(
                        subTask,
                        'sub_task_name',
                      ),
                      team: subTask?.team?.replace(/[[\]']+/g, ''),
                      due_date:
                        subTask?.due_date === null
                          ? ''
                          : dayjs(subTask?.due_date).format('DD MMM'),
                      jira_id: subTask?.jira_id,
                      marketplace: marketplacesList?.find(
                        (marketplace) =>
                          marketplace?.value === subTask?.marketplace,
                      )?.label,
                      jira_status: subTask?.jira_status,
                      assignee: subTaskAssignee,
                    });
                  }
                }

                // binding task data
                if (task?.first_name === null && task?.last_name === null) {
                  taskAssignee = {};
                } else {
                  taskAssignee = [
                    {
                      first_name: task?.first_name,
                      last_name: task?.last_name,
                    },
                  ];
                }

                tasks.push({
                  id: task?.id,
                  task_id: task?.task_id,
                  task_name: bindTaskNameWithDocUrl(task, 'task_name'),
                  team: task?.team?.replace(/[[\]']+/g, ''),
                  due_date:
                    task?.due_date === null
                      ? ''
                      : dayjs(task?.due_date).format('DD MMM'),
                  jira_id: task?.jira_id,
                  jira_status: task?.jira_status,
                  assignee: taskAssignee,
                  marketplace: marketplacesList?.find(
                    (marketplace) => marketplace?.value === task?.marketplace,
                  )?.label,
                  sub_tasks: subTasks,
                });
              }
            }

            // binding deliverable data
            if (
              deliverable?.first_name === null &&
              deliverable?.last_name === null
            ) {
              deliverableAssignee = {};
            } else {
              deliverableAssignee = [
                {
                  first_name: deliverable?.first_name,
                  last_name: deliverable?.last_name,
                },
              ];
            }
            deliverables.push({
              id: deliverable?.id,
              deliverable_id: deliverable?.deliverable_task_id,
              deliverable_name: bindTaskNameWithDocUrl(
                deliverable,
                'task_name',
              ),
              team: deliverable?.team?.replace(/[[\]']+/g, ''),
              marketplace: marketplacesList?.find(
                (marketplace) =>
                  marketplace?.value === deliverable?.marketplace,
              )?.label,
              due_date:
                deliverable?.due_date === null
                  ? ''
                  : dayjs(deliverable?.due_date).format('DD MMM'),
              jira_id: deliverable?.jira_id,
              jira_status: deliverable?.jira_status,
              assignee: deliverableAssignee,
              tasks,
            });
          }
        }

        // binding service data
        if (item?.first_name && item?.last_name) {
          serviceAssignee = [
            {
              first_name: item?.first_name,
              last_name: item?.last_name,
            },
          ];
        } else {
          serviceAssignee = {};
        }

        tempPlaybookdata.push({
          id: item?.id,
          start_date: item?.start_date,
          service_id: item?.service_task_id,
          service_name: item?.task_name?.toLowerCase(),
          team: item?.team?.replace(/[[\]']+/g, ''),
          due_date: item?.due_date
            ? item?.due_date === null
              ? ''
              : dayjs(item?.due_date).format('DD MMM')
            : '',
          jira_id: item?.jira_id,
          jira_status: item?.jira_status,
          marketplace: marketplacesList?.find(
            (marketplace) => marketplace?.value === item?.marketplace,
          )?.label,
          assignee: serviceAssignee,
          deliverables,
        });
      }
      return tempPlaybookdata;
    },
    [marketplacesList],
  );

  // get onboarding playbook data
  const getOnboardingPlaybook = useCallback(() => {
    setOnboardingPlaybookLoader(true);
    setOnboardingPlaybookData([]);
    setPlaybookAPIError(null);

    getOnboardingPlaybookData(customerId).then((response) => {
      if (mounted.current) {
        if (response?.status === 200) {
          setOnboardingPlaybookData(bindPlaybookTaskData(response?.data));
          setPlaybookAPIError(null);
        } else {
          setOnboardingPlaybookLoader(false);
          setOnboardingPlaybookData([]);
          setPlaybookAPIError(response?.data?.error);
        }
        setOnboardingPlaybookLoader(false);
      }
    });
  }, [bindPlaybookTaskData, customerId]);

  // get service playbook data
  const getPlaybookTaskAndServices = useCallback(
    (accountName, service, startDate, endDate, teams, marketplaces) => {
      setPlaybookDataLoader(true);
      setPlaybookTaskData([]);
      setPlaybookId(null);
      const tempMarketplaces = [];
      const tempTeamMembers = [];
      let marketplacesString = '';
      let teamsString = '';

      // formatting selected marketplace data
      if (marketplaces?.length) {
        for (const item of marketplaces) {
          if (item?.value !== 'all') {
            tempMarketplaces.push(item?.value);
          }
        }
        marketplacesString = tempMarketplaces?.toString();
      }

      // formatting selected teams data
      const AllTeamsSelected = teams?.find((option) => option?.value === 'all');
      if (AllTeamsSelected) {
        teamsString = 'all';
      } else {
        for (const item of teams) {
          tempTeamMembers.push(item?.value);
        }
        teamsString = tempTeamMembers?.toString();
      }
      const apiParams = {
        customer: customerId,
        // current_year: currentYear,
        account_type: accountName,
        service_name: service,
        start_date: startDate,
        end_date: endDate,
        teams: teamsString,
        marketplace: marketplacesString,
      };

      // calling the API
      getPlaybookTaskAndServicesData(apiParams).then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            setServicePlaybookErrorMsg(null);
            setPlaybookId(response?.data?.customer_playbook_id);
            setPlaybookTaskData(
              bindServicePlaybookTaskData(
                response?.data?.customer_playbook_data,
              ),
            );
            setPlaybookDataLoader(false);
          } else {
            setPlaybookDataLoader(false);
            setPlaybookTaskData([]);
            setPlaybookId(null);
            setServicePlaybookErrorMsg(response?.data?.error);
          }
        }
      });
    },
    [bindServicePlaybookTaskData, customerId],
  );

  const onCreatePlaybookClick = (data = updatedServicesOfPlaybookSummary) => {
    setCreatePlaybookBtnLoader(true);

    createCustomerPlaybookFromSummary(customerId, data).then((res) => {
      setCreatePlaybookBtnLoader(false);

      if (res?.status === 200) {
        toast.success('Playbook Created successfully.');
        setShowSummaryPage(false);
        history.push(
          `${PATH_CUSTOMER_DETAILS.replace(
            ':id',
            customerId,
          )}?tab=playbook&nav=playbook`,
          'main-playbook',
        );

        getPlaybookTaskAndServices(
          accountType?.value,
          playbookServiceList?.[0]?.value,
          todaysDate,
          todaysDate,
          playbookTeamsList,
          marketplacesList,
        );
      } else {
        toast.error('Something went wrong.');
      }
    });
  };

  useEffect(() => {
    if (
      playbookTabActive === 'onboarding' &&
      search?.includes('nav=playbook')
    ) {
      setPlaybookTab('playbook');
    } else if (
      playbookTabActive === 'onboarding' &&
      search?.includes('nav=onboarding')
    ) {
      setPlaybookTab('onboarding');
    } else if (search?.includes('nav=standard-monthly')) {
      setPlaybookTab('standard-monthly');
    } else if (search?.includes('nav=additional-one-time')) {
      setPlaybookTab('additional-one-time');
    } else if (search?.includes('nav=onboarding-gray')) {
      setPlaybookTab('onboarding-gray');
    } else {
      setPlaybookTab(playbookTabActive);
    }
  }, [customerId, playbookTabActive, search]);

  // useEffect function call
  useEffect(() => {
    mounted.current = true;

    if (playbookTabActive === 'playbook') {
      const tempTeamData = [
        {
          label: 'All Team Members',
          value: 'all',
        },
      ];
      const tempServicesData = [
        {
          label: 'All Services',
          value: 'all',
        },
      ];
      getPlaybookTaskAndServices(
        accountType?.value,
        tempServicesData?.[0]?.value,
        todaysDate,
        todaysDate,
        tempTeamData,
        marketplacesList,
      );
    }
    if (
      playbookTabActive === 'onboarding' ||
      playbookTabActive === 'onboarding-gray'
    ) {
      getOnboardingPlaybook();
    }
    if (playbookTabActive === 'onboarding') {
      getChangeTaskStatusId();
    }

    return () => {
      mounted.current = false;
    };
  }, [
    accountType,
    getChangeTaskStatusId,
    getOnboardingPlaybook,
    getPlaybookTaskAndServices,
    marketplacesList,
    playbookServiceList,
    playbookTabActive,
    playbookTeamsList,
    todaysDate,
  ]);

  const setSelectedtMarketplacesAccordingToActiveTab = useCallback(() => {
    if (playbookTab === 'playbook') {
      setSelectedMarketplaces([...marketplacesList]);
    }
    if (
      playbookTab === 'standard-monthly' ||
      playbookTab === 'additional-one-time'
    ) {
      setSingleSelectedMarteplace(originalMarketplaceList?.[0]);
      setSelectedMarketplaces([originalMarketplaceList?.[0]]);
    }
  }, [playbookTab, marketplacesList, originalMarketplaceList]);

  // useEffect function call
  useEffect(() => {
    mounted.current = true;
    setSelectedService(playbookServiceList?.[0]);
    setSelectedTeamMembers(playbookTeamsList);
    // setSelectedMarketplaces(marketplacesList);
    setSelectedtMarketplacesAccordingToActiveTab();
    return () => {
      mounted.current = false;
    };
  }, [
    playbookServiceList,
    playbookTeamsList,
    marketplacesList,
    setSelectedtMarketplacesAccordingToActiveTab,
  ]);

  // create customer playbook
  const createPlaybook = useCallback(() => {
    setPlaybookDataLoader(true);
    createCustomerPlaybook(customerId, 'BGS').then((response) => {
      if (response?.status === 200 || response?.status === 201) {
        toast.success('Playbook created succesfully!');
        window.location.reload();
        history.push(
          `${PATH_CUSTOMER_DETAILS.replace(
            ':id',
            customerId,
          )}?tab=playbook&nav=playbook`,
          'main-playbook',
        );
      } else if (response?.status === 400) {
        toast.error('Only BGS assigned to the BP can create playbook.');
        setPlaybookDataLoader(false);
      } else {
        toast.error('Oops! some error occurred.');
        setPlaybookDataLoader(false);
      }
    });
  }, [customerId, history]);

  // handle playbook tab change events
  const handlePlaybookTabChangeEvents = (key) => {
    setPlaybookTab(key);
    setShowSummaryPage(false);
    setSelectedtMarketplacesAccordingToActiveTab();
    setSelectedTeamMembers(playbookTeamsList);

    history.push(
      `${PATH_CUSTOMER_DETAILS.replace(
        ':id',
        customerId,
      )}?tab=playbook&nav=${key}&access=${isBBEInternalUser}`,
    );

    if (key === 'onboarding' || key === 'onboarding-gray') {
      getOnboardingPlaybook();
    }
    if (key === 'playbook') {
      getPlaybookTaskAndServices(
        accountType?.value,
        selectedService?.value,
        todaysDate,
        todaysDate,
        playbookTeamsList,
        marketplacesList,
      );
    }
    setCustomMonthRange([
      {
        startMonth: currentDate,
        endMonth: currentDate,
        key: 'playbookMonthSelection',
      },
    ]);
    setCustomStartDate(todaysDate);
    setCustomEndDate(todaysDate);
  };

  // handle account type change events
  const handleAccountTypeChangeEvents = (event) => {
    setAccountType(event);
    setSelectedtMarketplacesAccordingToActiveTab();
    // setSelectedMarketplaces(marketplacesList);
    setSelectedTeamMembers(playbookTeamsList);
    setSelectedService(playbookServiceList?.[0]);
    setDateType(dateFilterOptions?.[0]);
    setCustomStartDate(todaysDate);
    setCustomEndDate(todaysDate);
    setCustomMonthRange([
      {
        startMonth: currentDate,
        endMonth: currentDate,
        key: 'playbookMonthSelection',
      },
    ]);
    getPlaybookTaskAndServices(
      event?.value,
      playbookServiceList?.[0]?.value,
      todaysDate,
      todaysDate,
      playbookTeamsList,
      marketplacesList,
    );
  };

  // handle Marketplace Change Events
  const handleDropdownChangeEvents = (event, type, action) => {
    let selectedValues = [];
    if (type === 'marketplaces') {
      selectedValues =
        action.action === 'select-option' && action?.option?.value === 'all'
          ? [{ value: 'all', label: 'All' }, ...marketplacesList]
          : action.action === 'deselect-option' &&
            action?.option?.value === 'all'
          ? [event[0]]
          : event?.filter((op) => op.value !== 'all');
      setSelectedMarketplaces(selectedValues);
      getPlaybookTaskAndServices(
        accountType?.value,
        selectedService?.value,
        customStartDate,
        customEndDate,
        selectedTeamMembers,
        selectedValues,
      );
    } else if (type === 'teamMembers') {
      selectedValues =
        action.action === 'select-option' && action?.option?.value === 'all'
          ? [{ value: 'all', label: 'All' }, ...playbookTeamsList]
          : action.action === 'deselect-option' &&
            action?.option?.value === 'all'
          ? [event[0]]
          : event?.filter((op) => op.value !== 'all');
      setSelectedTeamMembers(selectedValues);
      getPlaybookTaskAndServices(
        accountType?.value,
        selectedService?.value,
        customStartDate,
        customEndDate,
        selectedValues,
        selectedMarketplaces,
      );
    }
  };

  // handle service change events
  const handleServiceChangeEvents = (event) => {
    setSelectedService(event);

    getPlaybookTaskAndServices(
      accountType?.value,
      event?.value,
      customStartDate,
      customEndDate,
      selectedTeamMembers,
      selectedMarketplaces,
    );
  };

  // calling playbook task and services api when date changes
  const applyDateChangeEvents = (startDate, endDate) => {
    setCustomStartDate(startDate);
    setCustomEndDate(endDate || todaysDate);

    getPlaybookTaskAndServices(
      accountType?.value,
      selectedService?.value,
      startDate,
      endDate || todaysDate,
      selectedTeamMembers,
      selectedMarketplaces,
    );
  };

  // handle date change events
  const handleDateChangeEvents = (event) => {
    const value = event?.value;
    setDateType(event);

    if (value === 'today') {
      applyDateChangeEvents(todaysDate);
    }
    if (value === 'yesterday') {
      applyDateChangeEvents(yesterday, yesterday);
    }
    if (value === 'past-month') {
      applyDateChangeEvents(pastMonthDate?.firstDay, pastMonthDate?.lastDay);
    }
    if (value === 'past-3-months') {
      applyDateChangeEvents(past3MonthDate?.firstDay, past3MonthDate?.lastDay);
    }
    if (value === 'custom-month') {
      setShowCustomMonthModal(true);
    } else {
      setCustomMonthRange([
        {
          startMonth: currentDate,
          endMonth: currentDate,
          key: 'playbookMonthSelection',
        },
      ]);
      setShowCustomMonthModal(false);
    }
  };

  // handle custom date range changes events
  const handleCustomDateRangeEvents = (startDate, endDate, dateFlag) => {
    const newendDate = endDate || startDate;
    const lastDayOfEndMonth = getLastDayOfMonth(
      newendDate?.getFullYear(),
      newendDate?.getMonth() + 1,
      0,
    );

    let tempStartDate = startDate;
    let tempEndDate = lastDayOfEndMonth;

    if (dateFlag === 'custom-month') {
      tempStartDate = dayjs(startDate).format('YYYY-MM-DD');
      tempEndDate = dayjs(lastDayOfEndMonth).format('YYYY-MM-DD');
      applyDateChangeEvents(tempStartDate, tempEndDate);
    }
  };

  // Apply selected custom date range
  const applyCustomMonthRange = () => {
    handleCustomDateRangeEvents(
      customMonthRange?.[0]?.startMonth,
      customMonthRange?.[0]?.endMonth,
      'custom-month',
    );
    setShowCustomMonthModal(false);
  };

  // handle custom month range change events
  const handleCustomMonthRangeEvents = (date) => {
    const [startMonth, endMonth] = date;

    if (playbookTab === 'playbook') {
      setCustomMonthRange([
        {
          startMonth,
          endMonth,
          key: 'playbookMonthSelection',
        },
      ]);
    } else {
      const tempStartMonth = endMonth === null ? startMonth : endMonth;
      setCustomMonthRange([
        {
          startMonth: tempStartMonth,
          endMonth,
          key: 'playbookMonthSelection',
        },
      ]);
    }
  };

  // handle refresh button events
  const handleRefreshButtonEvents = () => {
    window.location.reload();

    if (playbookTab === 'onboarding') {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(
          ':id',
          customerId,
        )}?tab=onboarding&nav=onboarding`,
        'onboarding',
      );
    } else {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(
          ':id',
          customerId,
        )}?tab=playbook&nav=playbook`,
        'main-playbook',
      );
    }
  };

  // custome style for dropdown filters
  const filterCustomStyles = {
    control: (css) => ({
      ...css,
      'min-height': '32px !important',
      background: '#fafafb !important',
    }),

    valueContainer: (css) => ({
      ...css,
      color: '#2E384D',
      overflow: 'hidden',
      'white-space': 'nowrap',
      'flex-wrap': 'nowrap',
      'text-overflow': 'ellipsis',
      'max-width': '140px',
      'font-size': '14px',
    }),
  };

  // custom style for account type dropdown filter
  const accountTypleFilterCustomStyles = {
    control: (css) => ({
      ...css,
      background: '#ffeeea !important',
      border: 'none',
      'min-height': '32px',
    }),

    singleValue: (css) => ({
      ...css,
      color: '#ff5933',
    }),
  };

  // render the selected custom date or month as a sub label in the time filter
  const renderCustomDateSubLabel = () => {
    return `From - ${dayjs(customStartDate).format('YYYY MMM')} To - ${dayjs(
      customEndDate,
    ).format('YYYY MMM')}`;
  };

  // component for custom dropdwon filter single value selected
  const customDropdownFilterSingleValue = (props) => {
    return (
      <SingleValue {...props}>
        <span style={{ fontSize: '14px', fontFamily: 'Noah-Medium' }}>
          {props?.data?.label}
        </span>
      </SingleValue>
    );
  };

  // component for custom dropdown single filter menu option
  const customDropdownSingleFilterOption = (props) => {
    return (
      <Option {...props}>
        <div className="pb-1">
          <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
          {props.isSelected ? (
            <FontAwesomeIcon
              className="float-right"
              fontSize="14px"
              icon="fa-regular fa-check"
              color="#FF5933"
            />
          ) : null}
        </div>
        <div style={{ fontSize: '10px' }}>
          {/* {renderCustomDateSubLabel(props)} */}
          {props?.data?.subLabel}
        </div>
      </Option>
    );
  };

  // getting the custom dropdown components
  const customDropdownFilterComponents = () => {
    return {
      Option: customDropdownSingleFilterOption,
      SingleValue: customDropdownFilterSingleValue,
      DropdownIndicator,
    };
  };

  // render custom dropdown filter using react-select
  const renderCustomDropdownFilter = (
    filterId,
    filterOptions,
    value,
    handleChangeEventsFunction,
  ) => {
    const filterStyle =
      filterId === 'playbook-account-type-filter'
        ? accountTypleFilterCustomStyles
        : filterCustomStyles;

    return (
      <CustomDropDown
        id="playbook-account-type-filter"
        className="cursor w-100"
        inputColor={Theme.black}
      >
        <Select
          classNamePrefix="react-select"
          className="active "
          styles={filterStyle}
          components={customDropdownFilterComponents()}
          options={filterOptions}
          defaultValue={value}
          placeholder={
            filterOptions && filterOptions[0] && filterOptions[0].label
          }
          value={value}
          onChange={(event) => handleChangeEventsFunction(event)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
        />
      </CustomDropDown>
    );
  };

  // render playbook account type filter
  const renderAccountTypeFilter = () => {
    return renderCustomDropdownFilter(
      'playbook-account-type-filter',
      playbookAccountTypeOptions,
      accountType,
      handleAccountTypeChangeEvents,
    );
  };

  const handleSingleSelectMarketplaceChangeEvents = (event) => {
    setSingleSelectedMarteplace(event);
    setSelectedMarketplaces([event]);
  };

  // render marketplace filter
  const renderMarketplacesFilter = () => {
    return playbookTab === 'playbook' ? (
      <>
        <MultiSelectDropdown
          options={marketplacesList.filter(
            (marketplace) => marketplace.value !== 'all',
          )}
          label="Marketplaces"
          value="marketplaces"
          multiSelectedValues={{ marketplaces: selectedMarketplaces }}
          setMultiSelectedValues={setSelectedMarketplaces}
          handleMultiSelectChange={handleDropdownChangeEvents}
        />
      </>
    ) : (
      <CustomDropDown
        id="BT-ReportTypeDropdown"
        className="cursor w-100"
        inputColor={Theme.black}
      >
        <Select
          classNamePrefix="react-select"
          className="active "
          components={customDropdownFilterComponents()}
          options={originalMarketplaceList}
          defaultValue={singleSelectedMarketplaces}
          placeholder={
            originalMarketplaceList &&
            originalMarketplaceList[0] &&
            originalMarketplaceList[0].label
          }
          value={singleSelectedMarketplaces}
          onChange={(event) => handleSingleSelectMarketplaceChangeEvents(event)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
        />
      </CustomDropDown>
    );
  };

  // render playbook services filter
  const renderServiceFilter = () => {
    return (
      <CustomDropDown
        id="playbook-service-filter"
        className="cursor w-100"
        inputColor={Theme.black}
      >
        <Select
          classNamePrefix="react-select"
          className="active "
          components={customDropdownFilterComponents()}
          options={playbookServiceList}
          defaultValue={selectedService}
          placeholder={
            selectedService && selectedService[0] && selectedService[0].label
          }
          value={selectedService}
          onChange={(event) => handleServiceChangeEvents(event)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
        />
      </CustomDropDown>
    );
  };

  // render team member filter
  const renderTeamMembersFilter = () => {
    return (
      <MultiSelectDropdown
        options={playbookTeamsList.filter(
          (playbookTeams) => playbookTeams.value !== 'all',
        )}
        label="Team Members"
        value="teamMembers"
        multiSelectedValues={{ teamMembers: selectedTeamMembers }}
        setMultiSelectedValues={setSelectedTeamMembers}
        handleMultiSelectChange={handleDropdownChangeEvents}
      />
    );
  };

  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px', color: '#000000' }}>
        {props.data.label}
      </span>

      <div style={{ fontSize: '12px', color: '#556178' }}>
        {renderCustomDateSubLabel(props)}
      </div>
    </SingleValue>
  );

  const filterOption = (props) => (
    <Option {...props}>
      <div className="pb-2">
        <span style={{ fontSize: '14px', color: '#000000' }}>
          {props.data.label}
        </span>

        <div style={{ fontSize: '12px', color: '#556178' }}>
          {props.data.sub}
        </div>
      </div>
    </Option>
  );

  // render get select component for dropdown
  const getTimeFilterSelectComponents = () => {
    return {
      Option: filterOption,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  // render month month filter
  const renderCustomDateFilter = () => {
    return DropDown(
      'BT-playbookFilter',
      '',
      dateFilterOptions,
      dateFilterOptions[0].label,
      getTimeFilterSelectComponents,
      dateFilterOptions[0],
      handleDateChangeEvents,
      false,
      // isApiCall,
      null,
      dateType,
      false,
    );
  };

  const setMaxDate = () => {
    const currentD = new Date();
    return currentD.setMonth(currentD.getMonth() + 3);
  };

  // render custom month range modal
  const renderCustomMonthRangeModal = () => {
    return (
      <CustomMonthRangeModal
        id="playbook-month-range"
        isOpen={showCustomMonthModal}
        title="Select Month Range"
        className="custom-month-range"
        maxDate={setMaxDate()}
        minDate={playbookTab !== 'playbook' ? currentDate : null}
        startMonth={customMonthRange?.[0]?.startMonth}
        endMonth={
          playbookTab !== 'playbook' ? null : customMonthRange?.[0]?.endMonth
        }
        onClick={() => {
          setShowCustomMonthModal(false);
        }}
        onDateChange={handleCustomMonthRangeEvents}
        onApply={() => applyCustomMonthRange()}
        currentMonth={currentDate}
      />
    );
  };

  // render refresh button
  const renderRefreshButton = () => {
    return (
      <RefreshButton>
        <div
          className="refresh-icon cursor"
          role="presentation"
          onClick={() => {
            handleRefreshButtonEvents();
          }}
        >
          <FontAwesomeIcon icon="fa-light fa-arrows-rotate" fontSize="12px" />
        </div>
      </RefreshButton>
    );
  };

  // render playbook tabs
  const renderPlaybookTabs = () => {
    let playbookTabsToShow = [];

    if (playbookAccess) {
      playbookTabsToShow = playbookTabsConstants.filter(
        (item) => item.type === 'main-playbook',
      );
    }
    if (onboardingPlaybookAccess) {
      playbookTabsToShow = playbookTabsConstants.filter(
        (item) => item.type === 'onboarding-playbook',
      );
    }

    if (playbookAccess && onboardingPlaybookAccess) {
      playbookTabsToShow = playbookTabsConstants;
    }

    return (
      <Tabs>
        <ul
          className="tabs scrollable-container border-none"
          id="playbook-tabs"
        >
          {playbookTabsToShow?.map((item) => {
            return (
              <li
                key={item?.key}
                className={`${
                  playbookTab === item?.key
                    ? 'active scrollable-tab'
                    : 'scrollable-tab'
                } ${
                  (playbookTabActive === 'playbook' &&
                    item?.key === 'onboarding') ||
                  (playbookTabActive === 'onboarding' &&
                    item?.key === 'onboarding-gray') ||
                  (playbookTabActive === 'onboarding-gray' &&
                    item?.key === 'onboarding')
                    ? 'd-none'
                    : item?.key === 'onboarding-gray'
                    ? 'onboarding-gray'
                    : ''
                }`}
                onClick={() => {
                  handlePlaybookTabChangeEvents(item.key);
                }}
                role="presentation"
              >
                {item?.label}
              </li>
            );
          })}
        </ul>
      </Tabs>
    );
  };

  // render playbook filters
  const renderPlaybookDesktopFilters = () => {
    return (
      <WhiteCard className="mt-3">
        <ViewData>
          <div className="row">
            <div className="col-12 col-md-2 pr-1">
              <div className="view-data-for mt-3 pr-md-0">View data for:</div>
            </div>

            <div className="col-12 col-md-10 pl-0 text-md-right text-right">
              <PlaybookNavbar
                style={{
                  margin:
                    playbookTab !== 'onboarding'
                      ? '-10px 0 10px 0'
                      : '0 0 10px 0',
                }}
              >
                <ul className="playbook-nav">
                  {playbookTab !== 'onboarding' ? (
                    <>
                      <li className="playbookMultiselectFilter">
                        {renderMarketplacesFilter()}
                      </li>
                      {/* {playbookTab === 'playbook' ? (
                        <li className=" filter-by-service">
                          {renderServiceFilter()}
                        </li>
                      ) : null}{' '} */}
                      <li className="playbookMultiselectFilter">
                        {renderTeamMembersFilter()}
                      </li>
                    </>
                  ) : null}
                  <li className=" dateFilterWidth ">
                    {renderCustomDateFilter()}
                  </li>
                </ul>
              </PlaybookNavbar>
            </div>
          </div>
        </ViewData>
      </WhiteCard>
    );
  };

  // render Playbook Filters MobileView
  const renderPlaybookMobileFilters = () => {
    return (
      <WhiteCard className="mt-3 pt-1">
        <ViewData>
          <div className="row mt-2">
            <div className="col-12">
              <div className="view-data-for mt-1">View data for:</div>
            </div>
            {playbookTab === 'playbook' ? (
              <>
                <div className="col-6 col-sm-6 d-md-none mt-2 pr-1">
                  {renderAccountTypeFilter()}
                </div>
                <div className="col-2 col-sm-2 d-md-none mt-2 pl-1">
                  {renderRefreshButton()}
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-2 pr-1">
                  {renderMarketplacesFilter()}
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-2 pl-1 pr-md-1">
                  {renderServiceFilter()}
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-2 pr-1 pl-md-1">
                  {renderTeamMembersFilter()}
                </div>
              </>
            ) : null}

            <div className="col-6 col-sm-6 col-md-3 mt-2 pl-1">
              {renderCustomDateFilter()}
            </div>
          </div>
        </ViewData>
      </WhiteCard>
    );
  };

  // render playbook tab header filter
  const renderPlaybookTabHeaderFilter = () => {
    const showRefreshButton = !isCustomer;
    return (
      <PlaybookNavbar>
        <ul className="playbook-nav">
          {customerAccountType === 'Hybrid' &&
          (playbookTab === 'playbook' || playbookTab === 'standard-monthly') ? (
            <li>{renderAccountTypeFilter()}</li>
          ) : null}
          {showRefreshButton ? (
            <li className="refresh-btn">{renderRefreshButton()}</li>
          ) : null}
        </ul>
      </PlaybookNavbar>
    );
  };

  // render playbook tabs header
  const renderPlaybookTabsHeader = () => {
    return (
      <div className="row">
        <div className="col-12 col-md-8">{renderPlaybookTabs()}</div>
        <div className="col-12 col-md-4 d-none d-md-block text-right">
          {renderPlaybookTabHeaderFilter()}
        </div>
        <div className="col-12">
          <div className="horizontal-line straight-line d-none d-lg-block" />
        </div>
      </div>
    );
  };

  // https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in playbook
  // display playbook dynamic messages
  const displayPlaybookDynamicMessage = () => {
    let playbookDynamicMessage = null;
    if (playbookTab === 'playbook') {
      playbookDynamicMessage =
        accountType?.value === SELLER
          ? dynamicMessages?.main_playbook_seller?.placeholder
          : accountType?.value === VENDOR
          ? dynamicMessages?.main_playbook_vendor?.placeholder
          : null;
    }
    if (playbookTab === 'onboarding' || playbookTab === 'onboarding-gray') {
      playbookDynamicMessage =
        dynamicMessages?.onboarding_playbook?.placeholder;
    }
    return playbookDynamicMessage ? (
      <GrayMessageBar message={playbookDynamicMessage} className="mt-3 mb-0" />
    ) : null;
  };

  // render playbook filter white card
  const renderPlaybookFilterWhiteCard = () => {
    return playbookTab !== 'onboarding' ? (
      <div className="row">
        {isTablet ? (
          <div className="col-12">{renderPlaybookDesktopFilters()}</div>
        ) : (
          <div className="col-12">{renderPlaybookMobileFilters()}</div>
        )}
      </div>
    ) : null;
  };

  // render playbook Tab details component
  const renderPlaybookTabDetails = () => {
    return (
      <div className="row mt-3" style={{ marginBottom: '150px' }}>
        <div className="col-12">
          <PlaybookTabDetails
            dateType={dateType}
            isCustomer={isCustomer}
            customerId={customerId}
            playbookId={playbookId}
            playbookTab={playbookTab}
            accountType={accountType}
            createPlaybook={createPlaybook}
            playbookEpicId={playbookEpicId}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            selectedService={selectedService}
            playbookTaskData={playbookTaskData}
            playbookAPIError={playbookAPIError}
            isUserAssignedToBP={isUserAssignedToBP}
            playbookDataLoader={playbookDataLoader}
            selectedTeamMembers={selectedTeamMembers}
            selectedMarketplaces={selectedMarketplaces}
            onboardingPlaybookData={onboardingPlaybookData}
            onboardingPBTaskUpdateID={onboardingPBTaskUpdateID}
            onboardingPlaybookLoader={onboardingPlaybookLoader}
            getOnboardingPlaybook={getOnboardingPlaybook}
            getPlaybookTaskAndServices={getPlaybookTaskAndServices}
            onCreatePlaybookClick={onCreatePlaybookClick}
            createPlaybookBtnLoader={createPlaybookBtnLoader}
            updatedServicesOfPlaybookSummary={updatedServicesOfPlaybookSummary}
            setUpdatedServicesOfPlaybookSummary={
              setUpdatedServicesOfPlaybookSummary
            }
            showSummaryPage={showSummaryPage}
            setShowSummaryPage={setShowSummaryPage}
            marketplacesList={marketplacesList}
            servicePlaybookErrorMsg={servicePlaybookErrorMsg}
            singleSelectedMarketplaces={singleSelectedMarketplaces}
          />
        </div>
      </div>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      <div className="col-12">
        <PlaybookStyle>
          {renderPlaybookTabsHeader()}
          {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in playbook */}
          {displayPlaybookDynamicMessage()}
          {renderPlaybookFilterWhiteCard()}
          {renderPlaybookTabDetails()}
        </PlaybookStyle>
        {renderCustomMonthRangeModal()}
      </div>
    </>
  );
};

export default PlaybookContainer;

PlaybookContainer.defaultProps = {
  value: '',
  playbookTabActive: 'playbook',
  isBBEInternalUser: '',
  playbookEpicId: null,
  isUserAssignedToBP: false,
  isSelected: true,
  data: {},
  selectProps: {},
  selectedPlaybookQuarter: {},
  playbookTeamsList: [],
  playbookServiceList: [],
  marketplacesList: [],
  quarterMonths: [],
  playbookQuarterOptions: [],
  marketplaceChoices: [],
  setMarketplaceChoices: () => {},
  originalMarketplaceList: [],
};

PlaybookContainer.propTypes = {
  value: string,
  playbookEpicId: string,
  isBBEInternalUser: string,
  playbookTabActive: string,
  customerId: string.isRequired,
  currentYear: string.isRequired,
  currentMonth: string.isRequired,
  startMonthYear: string.isRequired,
  customerAccountType: string.isRequired,
  isSelected: bool,
  isUserAssignedToBP: bool,
  playbookAccess: bool.isRequired,
  onboardingPlaybookAccess: bool.isRequired,
  isCustomer: bool.isRequired,
  data: shape({}),
  selectProps: shape({}),
  selectedPlaybookQuarter: shape(),
  playbookTeamsList: arrayOf(shape({})),
  playbookServiceList: arrayOf(shape()),
  marketplacesList: arrayOf(shape()),
  quarterMonths: arrayOf(shape()),
  playbookQuarterOptions: arrayOf(shape()),
  marketplaceChoices: arrayOf(shape({})),
  setMarketplaceChoices: func,
  originalMarketplaceList: arrayOf(shape({})),
};
