import React from 'react';

import { bool, func } from 'prop-types';

import { CustomerLeftPannel } from './CustomerListStyle';

function CustomerListLeftSidePanel({
  loader,
  handleFilters,
  displayInputSearchWithRadius,
  displayManagerFilter,
  displayRadioCheckFilters,
  displayPartnerCheckboxesFilter,
  displayPartnerAgreementTypesFilters,
}) {
  /* ********** Main return statement of this component ********** */
  return (
    <>
      <CustomerLeftPannel className="d-none d-lg-block" style={{ zIndex: '9' }}>
        <div className="mb-5 customer-list-pannel">
          <div className="container-fluid">
            <div className="row mt-2 ">
              <div className="col-4">
                <div className="customer-list-filter">Filters</div>
              </div>
              <div className="col-8 text-right">
                <div
                  className={`clear-filter ${loader ? 'disabled' : ''}`}
                  onClick={(event) =>
                    handleFilters(event, 'unselected', 'status')
                  }
                  role="presentation"
                >
                  Reset filters
                </div>
              </div>

              {/* ********** input search filter ********** */}
              <div className="col-12 mt-3">
                {displayInputSearchWithRadius()}
              </div>
            </div>
            <div className={loader ? 'disabled' : ''}>
              {/* ********** bgs, ad, dsp manager filters ********** */}
              {displayManagerFilter()}
              {/* ********** partner types filters ********** */}
              {displayRadioCheckFilters('Partner Type', 'bpAccount', 'desktop')}
              {/* ********** partner account  status filters ********** */}
              {displayPartnerCheckboxesFilter('Partner Status', 'status')}
              {/* ********** partner pipeline filters ********** */}
              {displayRadioCheckFilters(
                'Partner Pipeline',
                'pipeline',
                'desktop',
              )}
              {/* ********** partner account types filters ********** */}
              {displayPartnerCheckboxesFilter(
                'Account Type',
                'customer_account_type',
              )}
              {/* ********** partner agreement types filters ********** */}
              {displayPartnerAgreementTypesFilters('desktop')}
              {/* ********** partner agreement status filters ********** */}
              {displayPartnerCheckboxesFilter(
                'Agreement Status',
                'contract_status',
              )}
              {/* ********** partner agreement addendum status filters ********** */}
              {displayPartnerCheckboxesFilter(
                'Addendum Status',
                'addendum_status',
              )}
            </div>
          </div>
        </div>
      </CustomerLeftPannel>
    </>
  );
}

CustomerListLeftSidePanel.defaultProps = {
  loader: false,

  handleFilters: () => {},
  displayInputSearchWithRadius: () => {},
  displayManagerFilter: () => {},
  displayRadioCheckFilters: () => {},
  displayPartnerCheckboxesFilter: () => {},
  displayPartnerAgreementTypesFilters: () => {},
};
export default CustomerListLeftSidePanel;
CustomerListLeftSidePanel.propTypes = {
  loader: bool,

  handleFilters: func,
  displayInputSearchWithRadius: func,
  displayManagerFilter: func,
  displayRadioCheckFilters: func,
  displayPartnerCheckboxesFilter: func,
  displayPartnerAgreementTypesFilters: func,
};
