import React from 'react';

import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { bool, func } from 'prop-types';

import CreativeOptimizationScheduleContainer from './CreativeOptimizationScheduleContainer';

function COSContainer({ clickOnRefreshBtnFlag, setClickOnRefreshBtn }) {
  const history = useHistory();
  const params = queryString.parse(history.location.search);

  /* ********** Main return statement of this component ********** */
  return (
    <div className="col-12 cutomer-middle-panel">
      {params?.nav === 'cos' ? (
        <CreativeOptimizationScheduleContainer
          clickOnRefreshBtnFlag={clickOnRefreshBtnFlag}
          setClickOnRefreshBtn={setClickOnRefreshBtn}
        />
      ) : null}
    </div>
  );
}

export default COSContainer;

COSContainer.defaultProps = {
  clickOnRefreshBtnFlag: false,

  setClickOnRefreshBtn: () => {},
};

COSContainer.propTypes = {
  clickOnRefreshBtnFlag: bool,

  setClickOnRefreshBtn: func,
};
