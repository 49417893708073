/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import queryString from 'query-string';
import axiosInstance from '../axios';
import {
  API_GOALS,
  API_CUSTOMER,
  API_MILESTONE,
  API_GOALS_ACCOUNT_TYPE_FLAG,
  API_GOALS_REPORT,
} from '../constants';

// get goals
export async function getGoals(
  customerID,
  accountType,
  marketplaces,
  startDate,
  endDate,
  primaryMarketplace,
) {
  let marketplacesData = {};
  const list = [];
  // eslint-disable-next-line no-unused-vars
  const marketplace = marketplaces
    ?.filter((mrktplc) => {
      if (mrktplc?.id) return mrktplc;
    })
    ?.map((item) => {
      list.push(item.id);
      return item;
    });

  if (list?.length) {
    marketplacesData = list?.toString();
  }

  const params = {
    customer: customerID,
    account_type: accountType,
    start_date: startDate,
    end_date: endDate,
    primary_marketplace: primaryMarketplace,
  };
  const result = await axiosInstance
    .get(
      `${API_CUSTOMER + customerID + API_GOALS}?${
        marketplacesData && marketplacesData?.length
          ? `&marketplace=${marketplacesData}`
          : ''
      }`,
      { params },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get goals download report
export async function getGoalsReport(customerID) {
  const params = {
    customer: customerID,
  };

  const result = await axiosInstance
    .get(API_GOALS_REPORT, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function createGoals(data) {
  if (data && Object.keys(data).length > 0) {
    const result = await axiosInstance
      .post(`${API_GOALS}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return result;
  }
  return null;
}

export async function updateGoal(
  id,
  data,
  marketplaces,
  accountType,
  startDate,
  endDate,
) {
  let marketplacesData = {};

  const list = [];
  // eslint-disable-next-line no-unused-vars
  const marketplace = marketplaces
    ?.filter((mrktplc) => {
      if (mrktplc?.id) return mrktplc;
    })
    ?.map((item) => {
      list.push(item.id);
      return item;
    });

  if (list?.length) {
    marketplacesData = list?.toString();
  }

  const startDateStr = queryString.stringify({ start_date: startDate });
  const endDateStr = queryString.stringify({ end_date: endDate });
  const accType = queryString.stringify({ account_type: accountType });

  const result = await axiosInstance
    .patch(
      `${API_CUSTOMER + id + API_GOALS}?${
        marketplacesData && marketplacesData?.length
          ? `&marketplace=${marketplacesData}`
          : ''
      }${startDateStr ? `&${startDateStr}` : ''}${
        endDateStr ? `&${endDateStr}` : ''
      }&${accType}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function deleteGoal(id) {
  const result = await axiosInstance
    .delete(`${API_MILESTONE + id}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get bp goals account type flag
export async function getGoalsAccountTypeFlag(customerId) {
  const params = {
    customer_id: customerId,
  };
  const result = await axiosInstance
    .get(API_GOALS_ACCOUNT_TYPE_FLAG, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
  return result;
}
