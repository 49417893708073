import React from 'react';

import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import Theme from '../../../../../../theme/Theme';
import useValueWithChangeAndPercent from '../../../../../../hooks/useValueWithChangeAndPercent';
import { dspAsinTableConstant } from '../../../../../../constants';
import {
  CommonPagination,
  DesktopTable,
  NoData,
  PageLoader,
} from '../../../../../../common';

function DspAsinPerformanceTable({
  isCustom,
  asinPerformanceLoader,
  dspAsinData,
  isTablet,
  asinPageCount,
  asinPageNumber,
  handleAsinPaginationEvents,
  asinMessage,
  handleSortingEvents,
  currencySymbol,
  orderBy,
  orderByKey,
}) {
  // display react tooltip
  const displayReactTooltip = (id, place) => {
    return (
      <ReactTooltip
        className="asian-info-icon"
        id={id}
        aria-haspopup="true"
        place={place}
        effect="solid"
        style={{ maxWidth: '300px' }}
        html
      />
    );
  };

  // display product name with tooltip
  const displayProductNameWithTooltip = (productName, index, link) => {
    const textLimit = 50;
    return (
      <div
        className={isTablet ? 'pr-3' : 'pr-4'}
        style={{ position: 'relative' }}
      >
        <div
          className="text-ellipsis text-ellipsis-200"
          data-tip={`<div style="max-width:500px">${productName}</div>`}
          data-for={`product${index}`}
          data-cy="BT-dsp-asinData-productName"
        >
          {productName?.length > textLimit
            ? `${productName?.slice(0, textLimit)}...`
            : productName}
        </div>
        {displayReactTooltip(`product${index}`, 'top')}
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          data-cy="BT-dsp-asinData-pdpLink"
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <FontAwesomeIcon
            className="ml-2 cursor"
            color={Theme.orange}
            icon="far fa-arrow-up-right-from-square"
            style={{ fontSize: '14px' }}
          />
        </a>
      </div>
    );
  };

  // display sortable up and down arrow icon
  const displaySortableUpDownArrow = (key) => {
    return (
      <FontAwesomeIcon
        className="ml-2 cursor"
        color={orderBy === key ? Theme.orange : Theme.gray40}
        icon={`far ${
          orderByKey === `-${key}` ? 'fa-chevron-up' : 'fa-chevron-down'
        }`}
        style={{
          fontSize: '12px',
          verticalAlign: isTablet ? 'bottom' : 'middle',
        }}
      />
    );
  };

  const renderTableRow = (data, numberFormatter) => {
    return (
      <>
        {data?.map((item, index) => {
          return (
            <tr key={item?.asin}>
              <td className="" data-cy="BT-dsp-asinData-asinId">
                {item?.asin}
              </td>
              <td>
                {displayProductNameWithTooltip(
                  item?.productName,
                  index,
                  item?.pdpLink,
                )}
              </td>
              <td />
              <td data-cy="BT-dsp-asinData-totalProductSales">
                {numberFormatter(
                  item?.totalProductSalesCurrent,
                  item?.totalProductSalesChangeInValue,
                  item?.totalProductSalesChangeInPercent,
                  2,
                  isCustom,
                  currencySymbol,
                  '',
                )}
              </td>
              <td data-cy="BT-dsp-asinData-ttlNewBrandPurchases">
                {numberFormatter(
                  item?.ttlNewBrandPurchasesCurrent,
                  item?.ttlNewBrandPurchasesChangeInValue,
                  item?.ttlNewBrandPurchasesChangeInPercent,
                  2,
                  isCustom,
                  '',
                  '%',
                )}
              </td>
              <td data-cy="BT-dsp-asinData-totalDetailPageViews">
                {numberFormatter(
                  item?.totalDetailPageViewsCurrent,
                  item?.totalDetailPageViewsChangeInValue,
                  item?.totalDetailPageViewsChangeInPercent,
                  2,
                  isCustom,
                  '',
                  '',
                )}
              </td>
              <td data-cy="BT-dsp-asinData-totalAddToCart">
                {numberFormatter(
                  item?.totalAddToCartCurrent,
                  item?.totalAddToCartChangeInValue,
                  item?.totalAddToCartChangeInPercent,
                  2,
                  isCustom,
                  '',
                  '',
                )}
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  // display asin performance table
  const DisplayAsinPerformnaceTable = (numberFormatter) => {
    return (
      <DesktopTable
        className="metrics-table"
        style={{ tableLayout: 'fixed' }}
        data-cy="BT-dsp-asinTable"
      >
        <thead>
          <tr>
            {dspAsinTableConstant?.map((item) => {
              return (
                <th
                  className={`${item?.isSortable ? 'cursor' : ''} ${
                    item?.className ? item.className : ''
                  }`}
                  key={item?.key}
                  onClick={() => {
                    if (item?.isSortable) handleSortingEvents(item?.key);
                  }}
                  style={{ width: item?.width }}
                >
                  {item?.label}
                  {item?.isSortable
                    ? displaySortableUpDownArrow(item?.key)
                    : null}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{renderTableRow(dspAsinData, numberFormatter)}</tbody>
      </DesktopTable>
    );
  };

  // display asin performance mobile view
  const displayAsinPerformanceMobileView = (numberFormatter) => {
    return (
      <div className="mt-3">
        {dspAsinData?.map((item, index) => {
          return (
            <div className="pt-2">
              <fieldset className="performance-fielset mb-4">
                <div className="row">
                  <div className="col-5 pr-0">
                    <div className="label">Asin</div>
                    <div className="normal-text-black text-medium">
                      {item?.asin}
                    </div>
                  </div>
                  <div className="col-7 text-right">
                    <div className="label">Product Name</div>
                    <div
                      className="normal-text-black text-medium"
                      style={{ position: 'relative' }}
                    >
                      {displayProductNameWithTooltip(
                        item?.productName,
                        index,
                        item?.pdpLink,
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6 pr-0">
                    <div className="label">
                      Total Product Sales{' '}
                      {displaySortableUpDownArrow('ad_spend')}
                    </div>
                    <div className="normal-text-black text-medium">
                      {numberFormatter(
                        item?.totalProductSalesCurrent,
                        item?.totalProductSalesChangeInValue,
                        item?.totalProductSalesChangeInPercent,
                        2,
                        isCustom,
                        currencySymbol,
                        '',
                      )}
                    </div>
                  </div>
                  <div className="col-6 text-right">
                    <div className="label">
                      TTL New Brand Purchases{' '}
                      {displaySortableUpDownArrow('ad_sales')}
                    </div>
                    <div className="normal-text-black text-medium">
                      {numberFormatter(
                        item?.ttlNewBrandPurchasesCurrent,
                        item?.ttlNewBrandPurchasesChangeInValue,
                        item?.ttlNewBrandPurchasesChangeInPercent,
                        2,
                        isCustom,
                        '',
                        '%',
                      )}
                    </div>
                  </div>
                </div>

                <div className="fieldset-collapse">
                  <div className="row mt-3">
                    <div className="col-6 pr-0">
                      <div className="label">
                        Total Detail Page Views{' '}
                        {displaySortableUpDownArrow('acos')}
                      </div>
                      <div className="normal-text-black text-medium">
                        {numberFormatter(
                          item?.totalDetailPageViewsCurrent,
                          item?.totalDetailPageViewsChangeInValue,
                          item?.totalDetailPageViewsChangeInPercent,
                          2,
                          isCustom,
                          '',
                          '',
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-right">
                      <div className="label">
                        Total Add to Cart
                        {displaySortableUpDownArrow('roas')}
                      </div>
                      <div className="normal-text-black text-medium">
                        {numberFormatter(
                          item?.totalAddToCartCurrent,
                          item?.totalAddToCartChangeInValue,
                          item?.totalAddToCartChangeInPercent,
                          2,
                          isCustom,
                          '',
                          '',
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          );
        })}
      </div>
    );
  };

  // display asin performance content
  const displayAsinPerformanceContent = () => {
    return (
      <>
        {asinPerformanceLoader ? (
          <PageLoader
            component="performance-graph"
            color={Theme.orange}
            type="detail"
            width={40}
            height={40}
          />
        ) : dspAsinData?.length > 0 ? (
          <>
            <div data-cy="BT-dsp-asin-table">
              {isTablet
                ? DisplayAsinPerformnaceTable(useValueWithChangeAndPercent)
                : displayAsinPerformanceMobileView(
                    useValueWithChangeAndPercent,
                  )}
            </div>
            {!asinPerformanceLoader && asinPageCount > 10 ? (
              <div data-cy="BT-dsp-asin-pagination">
                <CommonPagination
                  count={asinPageCount}
                  pageNumber={asinPageNumber}
                  handlePageChange={handleAsinPaginationEvents}
                />
              </div>
            ) : null}
          </>
        ) : (
          <NoData data-cy="BT-dsp-asinNoData-message">{asinMessage}</NoData>
        )}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return <div>{displayAsinPerformanceContent()}</div>;
}

export default DspAsinPerformanceTable;

DspAsinPerformanceTable.defaultProps = {
  isCustom: false,
  asinPerformanceLoader: false,
  isTablet: false,
  asinPageCount: 0,
  asinPageNumber: 0,
  dspAsinData: [],
  handleAsinPaginationEvents: '',
  asinMessage: '',
  handleSortingEvents: '',
  currencySymbol: '',
  orderBy: '',
  orderByKey: '',
};
DspAsinPerformanceTable.propTypes = {
  isCustom: bool,
  asinPerformanceLoader: bool,
  isTablet: bool,
  asinPageCount: number,
  asinPageNumber: number,

  asinMessage: string,
  currencySymbol: string,
  orderBy: string,
  orderByKey: string,
  dspAsinData: arrayOf(shape({})),
  handleAsinPaginationEvents: func,

  handleSortingEvents: func,
};
