/* ********** Third party package imports ********** */
import React from 'react';

/* ********** Third party package imports ********** */
import { useMediaQuery } from 'react-responsive';
import { arrayOf, bool, func, shape, string } from 'prop-types';

/* ********** Local component file imports ********** */
import SalesSellerMetrics from './SalesSellerMetrics';
import SalesSellerContribution from './SalesSellerContribution';
import SalePerformanceChart from '../../../../Customer/CustomerDetailView/CompanyPerformance/SalesPerformance/SellerReporting/SalePerformanceChart';
import { noGraphDataMessage } from '../../../../../constants';
import { NoData, PageLoader, WhiteCard } from '../../../../../common';

const _ = require('lodash');

/* ********** MAIN FUNCTION START HERE ********** */
function SalesSellerReporting({
  // string
  currencySymbol,
  salesGroupBy,
  selectedTabMetrics,
  selectedRevenueType,
  selectedAdSpendType,
  // boolean
  salesGraphLoader,
  contributionLoader,
  isDisabledTimeFrame,
  // object
  isMetricsDisabled,
  selectedSalesDF,
  salesDifference,
  salesCurrentTotal,
  salesPreviousTotal,
  selectedSalesMetrics,
  selectedMarketplace,
  selectedRevenueDropDownOption,
  selectedAdSpendDropDownOption,
  // array
  salesChartData,
  contributionData,
  goalsPerformanceData,
  // function
  addThousandComma,
  displaySalesPagination,
  setIsDisabledTimeFrame,
  setSelectedAdSpendType,
  setSelectedRevenueType,
  setSelectedSalesMetrics,
  handleOnMetricsTabChange,
  displayContributionTable,
  renderRecentAndPreviousLabel,
  displayPerformanceSectionHeader,
  setSelectedRevenueDropDownOption,
  setSelectedAdSpendDropDownOption,
  dispalyContributionSectionHeader,
  displayDailyWeeklyMonthlyToggleButton,
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });

  // render sales metrics section
  const renderSalesMetrics = () => {
    return (
      <SalesSellerMetrics
        currencySymbol={currencySymbol}
        selectedSalesMetrics={selectedSalesMetrics}
        setSelectedSalesMetrics={setSelectedSalesMetrics}
        salesCurrentTotal={salesCurrentTotal}
        addThousandComma={addThousandComma}
        salesPreviousTotal={salesPreviousTotal}
        salesDifference={salesDifference}
        selectedRevenueType={selectedRevenueType}
        setSelectedRevenueType={setSelectedRevenueType}
        isMetricsDisabled={isMetricsDisabled}
        isDisabledTimeFrame={isDisabledTimeFrame}
        setIsDisabledTimeFrame={setIsDisabledTimeFrame}
        selectedRevenueDropDownOption={selectedRevenueDropDownOption}
        setSelectedRevenueDropDownOption={setSelectedRevenueDropDownOption}
        salesGraphLoader={salesGraphLoader}
        selectedAdSpendDropDownOption={selectedAdSpendDropDownOption}
        setSelectedAdSpendDropDownOption={setSelectedAdSpendDropDownOption}
        selectedAdSpendType={selectedAdSpendType}
        setSelectedAdSpendType={setSelectedAdSpendType}
      />
    );
  };

  // render daily, weekly, monthly filter
  const renderSalesGroupBy = () => {
    return (
      <div className="row mt-4 mb-3">
        {_.size(selectedSalesMetrics) <= 2 ? (
          <div className="col-md-6 col-sm-12 order-md-1 order-2 mt-2">
            {renderRecentAndPreviousLabel(
              selectedSalesMetrics,
              selectedSalesDF,
            )}
          </div>
        ) : (
          <div className="col-md-6 col-sm-12 order-md-1 order-2 mt-2" />
        )}
        <div className="col-md-6 col-sm-12 order-md-2 order-1">
          {displayDailyWeeklyMonthlyToggleButton()}
        </div>
      </div>
    );
  };

  // render sales performance graph section
  const renderSalesPerformanceGraph = () => {
    return salesGraphLoader ? (
      <PageLoader
        component="performance-graph"
        color="#FF5933"
        type="detail"
        width={40}
        height={40}
      />
    ) : salesChartData?.length >= 1 ? (
      <SalePerformanceChart
        chartId="adChart"
        chartData={salesChartData}
        currencySymbol={currencySymbol}
        selectedMetrics={selectedSalesMetrics}
        selectedDF={selectedSalesDF.value}
        isDashboard
        groupBy={salesGroupBy}
      />
    ) : (
      <NoData>{noGraphDataMessage}</NoData>
    );
  };

  // render sales key contribution section
  const renderSalesKeyContribution = () => {
    return (
      <SalesSellerContribution
        // string
        currencySymbol={currencySymbol}
        selectedTabMetrics={selectedTabMetrics}
        // boolean
        isDesktop={isDesktop}
        isApiCall={contributionLoader}
        // object
        selectedSalesDF={selectedSalesDF}
        selectedMarketplace={selectedMarketplace}
        // array
        contributionData={contributionData}
        goalsPerformanceData={goalsPerformanceData}
        // function
        displaySalesPagination={displaySalesPagination}
        handleOnMetricsTabChange={handleOnMetricsTabChange}
        displayContributionTable={displayContributionTable}
        dispalyContributionSectionHeader={dispalyContributionSectionHeader}
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      <WhiteCard className="mb-3">
        {displayPerformanceSectionHeader()}
        {renderSalesMetrics()}
        {renderSalesGroupBy()}
        {renderSalesPerformanceGraph()}
      </WhiteCard>
      {renderSalesKeyContribution()}
    </div>
  );
}

export default SalesSellerReporting;
/* ********** MAIN FUNCTION END HERE ********** */

/* ********** Declaring prop types ********** */
SalesSellerReporting.defaultProps = {
  // string
  currencySymbol: '',
  salesGroupBy: '',
  selectedTabMetrics: '',
  selectedRevenueType: '',
  selectedAdSpendType: '',
  // boolean
  salesGraphLoader: false,
  contributionLoader: false,
  isDisabledTimeFrame: false,
  // object
  isMetricsDisabled: {},
  selectedSalesDF: {},
  salesDifference: {},
  salesCurrentTotal: {},
  salesPreviousTotal: {},
  selectedMarketplace: {},
  selectedSalesMetrics: {},
  selectedRevenueDropDownOption: {},
  selectedAdSpendDropDownOption: {},
  // array
  salesChartData: [],
  contributionData: [],
  goalsPerformanceData: [],
  // function
  addThousandComma: () => {},
  displaySalesPagination: () => {},
  setIsDisabledTimeFrame: () => {},
  setSelectedAdSpendType: () => {},
  setSelectedRevenueType: () => {},
  setSelectedSalesMetrics: () => {},
  handleOnMetricsTabChange: () => {},
  displayContributionTable: () => {},
  renderRecentAndPreviousLabel: () => {},
  displayPerformanceSectionHeader: () => {},
  setSelectedRevenueDropDownOption: () => {},
  setSelectedAdSpendDropDownOption: () => {},
  dispalyContributionSectionHeader: () => {},
  displayDailyWeeklyMonthlyToggleButton: () => {},
};

SalesSellerReporting.propTypes = {
  // string
  currencySymbol: string,
  salesGroupBy: string,
  selectedTabMetrics: string,
  selectedRevenueType: string,
  selectedAdSpendType: string,
  // boolean
  salesGraphLoader: bool,
  contributionLoader: bool,
  isDisabledTimeFrame: bool,
  // object
  isMetricsDisabled: shape({}),
  selectedSalesDF: shape({}),
  salesDifference: shape({}),
  salesCurrentTotal: shape({}),
  salesPreviousTotal: shape({}),
  selectedSalesMetrics: shape({}),
  selectedMarketplace: shape({}),
  selectedRevenueDropDownOption: shape({}),
  selectedAdSpendDropDownOption: shape({}),
  // array
  salesChartData: arrayOf(shape({})),
  contributionData: arrayOf(shape({})),
  goalsPerformanceData: arrayOf(shape({})),
  // function
  addThousandComma: func,
  displaySalesPagination: func,
  setIsDisabledTimeFrame: func,
  setSelectedAdSpendType: func,
  setSelectedRevenueType: func,
  setSelectedSalesMetrics: func,
  handleOnMetricsTabChange: func,
  displayContributionTable: func,
  renderRecentAndPreviousLabel: func,
  displayPerformanceSectionHeader: func,
  setSelectedRevenueDropDownOption: func,
  setSelectedAdSpendDropDownOption: func,
  dispalyContributionSectionHeader: func,
  displayDailyWeeklyMonthlyToggleButton: func,
};
