/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Collapse } from 'react-collapse';
import {
  string,
  bool,
  number,
  func,
  oneOfType,
  arrayOf,
  shape,
  object,
  objectOf,
} from 'prop-types';

import AdditionalOneTimeServices from '../StatementOfWork/AdditionalOneTimeServices';
import { AgreementDetails } from '../../../../constants';
import { ServiceAgreementIcon } from '../../../../theme/images';
import { Button, InputFormField } from '../../../../common';

function ServiceAgreement({
  setFormData,
  setAdditionalOnetimeServices,
  updatedFormData,
  setUpdatedFormData,
  executeScroll,
  setOpenCollapse,
  openCollapse,
  agreementErrCount,
  statementErrCount,
  renderCollapseBtnErrorHtml,
  loader,
  generateHTML,
  displayError,
  showRightTick,
  formData,
  nextStep,
  onAddDiscount,
  additionalMonthlySerError,
  oneTimeService,
  handleChange,
  changeQuantity,
  additionalOnetimeSerError,
  setShowAmazonPlanDropdown,
  amazonService,
  showAmazonPlanDropdown,
  AmazonStoreOptions,
  amazonStoreCustom,
  setAmazonStoreCustom,
  showFooter,
  additionalOnetimeServices,
  clearOneTimeQntyError,
  updateAdditionalOnetimeServicesSelectedData,
  discountData,
  servicesFees,
  selectedCurrency,
  notIncludedOneTimeServices,
  setNotIncludedOneTimeServices,
  showAmazonStorefrontDropdown,
  setShowAmazonStorefrontDropdown,
  setAdditionalOnetimeSerError,
  details,
  updateStatementErrorValue,
  sectionError,
  contractAddendumDetails,
}) {
  const contractType = formData?.contract_type;
  const additionalOneTimeServicesLength =
    formData?.additional_one_time_services?.length;

  let serviceAgreementArray;

  if (formData?.customer_id?.pipeline === 'europe') {
    let agreementWithCurrencyDetails = [];

    agreementWithCurrencyDetails = [...AgreementDetails];
    agreementWithCurrencyDetails.splice(3, 0, {
      key: 'currency',
      label: 'Agreement Currency',
      type: 'choice',
      placeholder: 'Select Agreement Currency',
      isMandatory: true,
      part: 'agreement',
    });
    serviceAgreementArray = [...agreementWithCurrencyDetails];
  } else {
    serviceAgreementArray = [...AgreementDetails];
  }

  return (
    <>
      <div
        className="collapse-btn "
        role="presentation"
        type="button"
        onClick={() => {
          if (contractAddendumDetails?.notActive)
            executeScroll('draft-addendum');
          else executeScroll('agreement');
          setOpenCollapse({ agreement: !openCollapse.agreement });
        }}
      >
        <img className="service-agre" src={ServiceAgreementIcon} alt="pdf" />

        <h4
          className={
            agreementErrCount ||
            (statementErrCount && contractType?.toLowerCase()?.includes('one'))
              ? 'sendar-details error-container'
              : 'sendar-details'
          }
        >
          {contractType?.toLowerCase()?.includes('one') ? (
            <>
              One Time Service Agreement
              {renderCollapseBtnErrorHtml(
                'isOneTimeService',
                agreementErrCount || statementErrCount,
                openCollapse.agreement,
                'service_agreement',
              )}
            </>
          ) : (
            <>
              Service Agreement
              {renderCollapseBtnErrorHtml(
                false,
                agreementErrCount,
                openCollapse.agreement,
                'service_agreement',
              )}
            </>
          )}
        </h4>

        <div className="clear-fix" />
      </div>
      <Collapse isOpened={openCollapse.agreement}>
        {loader ? null : (
          <ul className="collapse-inner">
            {serviceAgreementArray.map((item) =>
              item.key !== 'contract_address' ? (
                <React.Fragment key={item.key}>
                  {item.key === 'length' &&
                  contractType === 'one time' ? null : item?.type ===
                    'checkbox' ? (
                    item?.key === 'change_agreement_start_date' &&
                    !contractAddendumDetails?.notActive ? (
                      <li>{generateHTML(item)}</li>
                    ) : item?.key === 'change_addendum_start_date' &&
                      contractAddendumDetails?.notActive ? (
                      <li>
                        {generateHTML(item)}
                        <strong>
                          If the ADDENDUM EFFECTIVITY DATE entered is ON or
                          BEFORE the signed <u>day</u> of the original contract,
                          this will automatically be included in the current
                          month&apos;s retainer invoice since invoice has not
                          yet been issued to the BP.
                        </strong>
                        <br />
                        <br />
                        <strong>
                          If the ADDENDUM EFFECTIVITY DATE entered is AFTER the
                          signed <u>day</u> of the original contract, please
                          reach out to Finance and Ops team for any retainer
                          billing adjustment as this month&apos;s retainer
                          invoice has already been issued to the BP.
                        </strong>
                      </li>
                    ) : null
                  ) : item?.key === 'addendum_start_date' &&
                    contractAddendumDetails?.notActive ? (
                    <li>
                      <InputFormField>
                        <label htmlFor={item?.key}>{item?.label}</label>
                        {generateHTML(item)}

                        <div className="mt-2">{item?.message}</div>
                      </InputFormField>
                    </li>
                  ) : item?.key !== 'addendum_start_date' ? (
                    <li>
                      <InputFormField>
                        <label htmlFor={item?.key}>{item?.label}</label>
                        {generateHTML(item)}
                        {displayError(item)}

                        {item?.key === 'start_date' &&
                        !contractAddendumDetails?.notActive &&
                        !formData?.change_agreement_start_date ? (
                          <div className="mt-2">{item?.message}</div>
                        ) : null}
                      </InputFormField>
                    </li>
                  ) : null}
                </React.Fragment>
              ) : (
                <React.Fragment key={item.key}>
                  <li>
                    <InputFormField>
                      <label htmlFor={item.key}>{item.label}</label>
                      {item.sections.map((subFields) => (
                        <React.Fragment key={subFields.key}>
                          <label htmlFor={subFields.key}>
                            {generateHTML(subFields)}
                          </label>
                          {displayError(subFields)}
                        </React.Fragment>
                      ))}
                    </InputFormField>
                  </li>
                  {contractType?.toLowerCase()?.includes('one') &&
                  !formData?.draft_from ? (
                    <>
                      <AdditionalOneTimeServices
                        setFormData={setFormData}
                        setAdditionalOnetimeServices={
                          setAdditionalOnetimeServices
                        }
                        updatedFormData={updatedFormData}
                        setUpdatedFormData={setUpdatedFormData}
                        onAddDiscount={onAddDiscount}
                        formData={formData}
                        additionalMonthlySerError={additionalMonthlySerError}
                        oneTimeService={oneTimeService}
                        handleChange={handleChange}
                        changeQuantity={changeQuantity}
                        additionalOnetimeSerError={additionalOnetimeSerError}
                        setShowAmazonPlanDropdown={setShowAmazonPlanDropdown}
                        amazonService={amazonService}
                        showAmazonPlanDropdown={showAmazonPlanDropdown}
                        AmazonStoreOptions={AmazonStoreOptions}
                        amazonStoreCustom={amazonStoreCustom}
                        displayError={displayError}
                        setAmazonStoreCustom={setAmazonStoreCustom}
                        showFooter={showFooter}
                        additionalOnetimeServices={additionalOnetimeServices}
                        clearOneTimeQntyError={clearOneTimeQntyError}
                        updateAdditionalOnetimeServicesSelectedData={
                          updateAdditionalOnetimeServicesSelectedData
                        }
                        discountData={discountData}
                        servicesFees={servicesFees}
                        selectedCurrency={selectedCurrency}
                        notIncludedOneTimeServices={notIncludedOneTimeServices}
                        setNotIncludedOneTimeServices={
                          setNotIncludedOneTimeServices
                        }
                        showAmazonStorefrontDropdown={
                          showAmazonStorefrontDropdown
                        }
                        setShowAmazonStorefrontDropdown={
                          setShowAmazonStorefrontDropdown
                        }
                        setAdditionalOnetimeSerError={
                          setAdditionalOnetimeSerError
                        }
                        details={details}
                        updateStatementErrorValue={updateStatementErrorValue}
                        sectionError={sectionError}
                        contractAddendumDetails={contractAddendumDetails}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              ),
            )}

            <li>
              <Button
                className="btn-primary sidepanel btn-next-section mt-2 mb-3 w-100"
                disabled={
                  !showRightTick('service_agreement') ||
                  (additionalOneTimeServicesLength &&
                    formData.additional_one_time_services.find(
                      (item) => item.name === 'Amazon Store Package',
                    ) &&
                    contractType?.toLowerCase()?.includes('one'))
                }
                onClick={() =>
                  nextStep(
                    contractType?.toLowerCase()?.includes('one')
                      ? 'addendum'
                      : contractType?.toLowerCase()?.includes('dsp')
                      ? 'dspAddendum'
                      : contractType?.toLowerCase()?.includes('recurring')
                      ? 'feeStructure'
                      : 'statement',
                  )
                }
              >
                Proceed to Next Section
              </Button>
            </li>
          </ul>
        )}
      </Collapse>
    </>
  );
}

export default ServiceAgreement;

ServiceAgreement.defaultProps = {
  setFormData: () => {},
  setAdditionalOnetimeServices: () => {},
  updatedFormData: () => {},
  setUpdatedFormData: () => {},
  executeScroll: () => {},
  setOpenCollapse: () => {},
  openCollapse: {},
  agreementErrCount: 0,
  statementErrCount: 0,
  renderCollapseBtnErrorHtml: () => {},
  loader: false,
  generateHTML: () => {},
  displayError: () => {},
  showRightTick: () => {},
  formData: {},
  nextStep: () => {},
  onAddDiscount: () => {},
  additionalMonthlySerError: {},
  oneTimeService: [],
  handleChange: () => {},
  changeQuantity: () => {},
  additionalOnetimeSerError: {},
  setShowAmazonPlanDropdown: () => {},
  amazonService: {},
  showAmazonPlanDropdown: false,
  AmazonStoreOptions: [],
  amazonStoreCustom: false,
  setAmazonStoreCustom: () => {},
  showFooter: () => {},
  additionalOnetimeServices: {},
  clearOneTimeQntyError: () => {},
  updateAdditionalOnetimeServicesSelectedData: () => {},
  discountData: [],
  servicesFees: [],
  selectedCurrency: '$ (USD)',
  notIncludedOneTimeServices: [],
  setNotIncludedOneTimeServices: () => {},
  showAmazonStorefrontDropdown: false,
  setShowAmazonStorefrontDropdown: () => {},
  setAdditionalOnetimeSerError: () => {},
  details: {},
  updateStatementErrorValue: () => {},
  sectionError: {},
  contractAddendumDetails: {},
};

ServiceAgreement.propTypes = {
  setFormData: func,
  setAdditionalOnetimeServices: func,
  updatedFormData: {},
  setUpdatedFormData: func,
  executeScroll: func,
  setOpenCollapse: func,
  openCollapse: shape({
    agreement: bool,
    statement: bool,
    addendum: bool,
    dspAddendum: bool,
  }),
  agreementErrCount: number,
  statementErrCount: number,
  renderCollapseBtnErrorHtml: func,
  loader: bool,
  generateHTML: func,
  displayError: func,
  showRightTick: func,
  formData: shape({
    additional_one_time_services: arrayOf(shape({})),
    draft_from: oneOfType([string, objectOf(object)]),
  }),
  nextStep: func,
  onAddDiscount: func,
  additionalMonthlySerError: oneOfType([string, object]),
  oneTimeService: arrayOf(shape({})),
  handleChange: func,
  changeQuantity: func,
  additionalOnetimeSerError: shape({
    quantity: number,
    custom_amazon_store_price: number,
  }),
  setShowAmazonPlanDropdown: func,
  amazonService: shape({}),
  showAmazonPlanDropdown: bool,
  AmazonStoreOptions: arrayOf(shape({})),
  amazonStoreCustom: bool,
  setAmazonStoreCustom: func,
  showFooter: func,
  additionalOnetimeServices: shape({
    create: arrayOf(shape({})),
    delete: arrayOf(string, shape({})),
  }),
  clearOneTimeQntyError: func,
  updateAdditionalOnetimeServicesSelectedData: func,
  discountData: arrayOf(shape({})),
  servicesFees: arrayOf(shape({})),
  selectedCurrency: string,
  notIncludedOneTimeServices: arrayOf(shape({})),
  setNotIncludedOneTimeServices: func,
  showAmazonStorefrontDropdown: bool,
  setShowAmazonStorefrontDropdown: func,
  setAdditionalOnetimeSerError: func,
  details: shape({}),
  updateStatementErrorValue: func,
  sectionError: shape({}),
  contractAddendumDetails: shape({}),
};
