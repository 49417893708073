// react imports
import React, { useRef, Fragment } from 'react';

// third party imports
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, instanceOf, shape, string } from 'prop-types';

// local file imports
import DifferenceAdMetric from '../DifferenceAdMetric';
import CardMetrics from '../../../../common/CardMetrics';
import ToogleMetrics from '../../../../common/ToogleMetrics';
import { Icon } from '../../../../theme/Global';
import {
  ACOS,
  ADCOS,
  ADROAS,
  COSTPERCLICK,
  CPCandSPCToggleMetrics,
  ROAS,
  SALESPERCLICK,
  showMetricsTooltipForSponsored,
  sponosredMetricsTooltipInfo,
  sponsoredMetricsActiveClasses,
  sponsoredMetricsClasses,
  sponsoredMetricsDetails,
  toogleMetricsData,
} from '../../../../constants';

// thrid party variable
const _ = require('lodash');

const SponsoredAdMetrics = ({
  currencySymbol,
  selectedAdMetrics,
  setSelectedAdMetrics,
  adCurrentTotal,
  addThousandComma,
  adPreviousTotal,
  adDifference,
  isApiCall,
  sponsoredGoalsMetricData,
  sponsoredGoalsToggle,
  sponsoredAdMultiSelected,
}) => {
  const currencySign = currencySymbol;
  const toogleMetrics = useRef(ACOS);
  const toggleCPCandSPCMetrics = useRef(COSTPERCLICK);

  const returnDisbaledClass = (current, previous) => {
    return !current && !previous ? 'disabled' : null;
  };

  // set sp metrics box classes
  const setBoxClasses = (name) => {
    let selectedClass = '';
    if (Object.prototype.hasOwnProperty.call(selectedAdMetrics, name)) {
      const metricsIndex = Object.keys(selectedAdMetrics).indexOf(name);
      selectedClass = `order-chart-box ${sponsoredMetricsActiveClasses[metricsIndex]} fix-height`;
    } else if (_.size(selectedAdMetrics) === 4) {
      selectedClass = 'order-chart-box fix-height disabled';
    } else {
      selectedClass = 'order-chart-box fix-height';
    }
    return selectedClass;
  };

  // handle sp metrics box toggle
  const setBoxToggle = (name) => {
    if (
      Object.prototype.hasOwnProperty.call(selectedAdMetrics, name) &&
      _.size(selectedAdMetrics) > 1
    ) {
      setSelectedAdMetrics(_.omit(selectedAdMetrics, [name]));
    } else if (_.size(selectedAdMetrics) < 4) {
      if (ADCOS in selectedAdMetrics && name === ADROAS) {
        const values = selectedAdMetrics;
        delete values.adCos;
      } else if (ADROAS in selectedAdMetrics && name === ADCOS) {
        const values = selectedAdMetrics;
        delete values.adRoas;
      }

      if (COSTPERCLICK in selectedAdMetrics && name === SALESPERCLICK) {
        const values = selectedAdMetrics;
        delete values.costPerClick;
      } else if (SALESPERCLICK in selectedAdMetrics && name === COSTPERCLICK) {
        const values = selectedAdMetrics;
        delete values.salesPerClick;
      }

      if (
        _.size(_.assign(selectedAdMetrics, { [name]: true })) === 2 &&
        sponsoredGoalsToggle
      ) {
        toast.info('Maximum two goals at a time');
      }
      setSelectedAdMetrics(
        _.omit(_.assign(selectedAdMetrics, { [name]: true })),
      );
    }
  };

  const onToggleMetrics = () => {
    if (toogleMetrics.current === ACOS) {
      setBoxToggle(ADCOS);
    } else if (toogleMetrics.current === ROAS) {
      setBoxToggle(ADROAS);
    }
  };

  // handle toggeling of CPC and SPC metrics
  const handleCPCandSPCToggleEvents = () => {
    if (toggleCPCandSPCMetrics.current === COSTPERCLICK) {
      setBoxToggle(COSTPERCLICK);
    } else if (toggleCPCandSPCMetrics.current === SALESPERCLICK) {
      setBoxToggle(SALESPERCLICK);
    }
  };

  const returnTooltipMessge = (key = '') => {
    return `No ${key} available for this period.`;
  };

  // display goals info
  const displayGoalsInfoOnCardMetrics = (metricName) => {
    const target = sponsoredGoalsMetricData?.[metricName]?.target;
    return sponsoredGoalsMetricData?.[metricName] ? (
      <div className="justify-space-between">
        <div className="metric-taget">
          <div className="target-percent-capsule">
            Target{' '}
            {sponsoredGoalsMetricData?.[metricName]?.sign === 'dollar'
              ? '$'
              : null}
            {sponsoredGoalsMetricData?.[metricName]?.value
              ?.toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            {sponsoredGoalsMetricData?.[metricName]?.sign === 'percentage'
              ? '%'
              : null}
          </div>
          <div
            className={`${
              target === 'on target' || target === 'on track'
                ? 'on-target'
                : target === 'underspend' || target === 'underspending'
                ? 'underspend'
                : 'under-target'
            } mt-1`}
          >
            {' '}
            {target}
          </div>
        </div>
      </div>
    ) : null;
  };

  // display metrics box html
  const displayMetricsBoxHTML = (
    className,
    id,
    metricsName,
    metricsKey,
    metricsNameKey,
    classNameKey,
    currentValue,
    previousValue,
    differenceValue,
    prefix,
    suffix,
    decimalDigits,
    hasGoalsToggle,
    differenceType = null,
    tooltipInfo,
  ) => {
    return (
      <div
        className={`col-lg-3 col-md-6 col-12 mb-2 ${className}`}
        key={`sponsoredAd${metricsKey}`}
      >
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(metricsName)}
          data-for={`sponsoredAd${metricsKey}`}
        >
          <div
            id={id}
            onClick={() => setBoxToggle(metricsNameKey, 'ad')}
            role="presentation"
            className={`${setBoxClasses(
              metricsNameKey,
              classNameKey,
            )} ${returnDisbaledClass(currentValue, previousValue)} ${
              !hasGoalsToggle && sponsoredGoalsToggle ? 'disabled' : ''
            }`}
          >
            <div className="justify-space-between">
              <div className="chart-name">{metricsName}</div>
              <div>
                <Icon>
                  <FontAwesomeIcon
                    icon="fa-regular fa-circle-info"
                    data-tip={tooltipInfo}
                    data-for={metricsKey}
                    data-html
                  />
                </Icon>
                <ReactTooltip
                  class="initialTextTransform"
                  id={metricsKey}
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                />
              </div>
            </div>
            <div className="number-rate">
              {currentValue
                ? `${prefix}${addThousandComma(
                    currentValue,
                    decimalDigits,
                  )}${suffix}`
                : `${prefix}${decimalDigits === 0 ? '0' : '0.00'}${suffix}`}
            </div>
            <div className="vs">
              {previousValue
                ? `vs ${prefix}${addThousandComma(
                    previousValue,
                    decimalDigits,
                  )}${suffix}`
                : `vs ${prefix}${decimalDigits === 0 ? '0' : '0.00'}${suffix}`}
            </div>
            <div className="justify-space-between">
              {differenceValue || differenceValue === 0 ? (
                <DifferenceAdMetric
                  value={differenceValue}
                  type={differenceType}
                />
              ) : (
                <div className="perentage-value down mt-3 pt-1">N/A</div>
              )}
              {displayGoalsInfoOnCardMetrics(metricsKey)}
            </div>
          </div>
        </CardMetrics>
      </div>
    );
  };

  // display metrics value
  const displayMetricsValue = (metricsValue) => {
    return metricsValue
      ? `${currencySign}${addThousandComma(metricsValue)}`
      : `${currencySign}0.00`;
  };

  // display acos and roas toggle metrics card
  const displayAcosAndRoasToggleMetrics = (index) => {
    return (
      <div
        className={`col-lg-3 col-md-6 col-12 mb-2 ${sponsoredMetricsClasses[index]}`}
      >
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(
            toogleMetrics.current === ACOS ? 'ACOS' : 'ROAS',
          )}
          data-for="sponsoredAdacos"
        >
          <div
            id="BT-sponsored-AcosRoascard"
            onClick={onToggleMetrics}
            role="presentation"
            className={`${
              toogleMetrics.current === ACOS
                ? setBoxClasses(ADCOS, 'ad-cos-active')
                : setBoxClasses(ADROAS, 'ad-cos-active')
            } ${returnDisbaledClass(
              adCurrentTotal?.ad_spend,
              adPreviousTotal?.ad_spend,
            )}`}
          >
            <div className="d-flex">
              <div
                style={{
                  position: 'relative',
                  width: 'calc(100% - 18px)',
                  marginRight: '5px',
                }}
              >
                <ToogleMetrics
                  data={toogleMetricsData}
                  onToogle={(val) => {
                    toogleMetrics.current = val;
                  }}
                  value={toogleMetrics.current}
                  toggleName="toggleMetrics"
                />
              </div>
              <div style={{ position: 'relative', width: '13px' }}>
                <Icon>
                  <FontAwesomeIcon
                    icon="fa-regular fa-circle-info"
                    data-tip={
                      sponosredMetricsTooltipInfo[toogleMetrics?.current]
                    }
                    data-for={toogleMetrics?.current}
                    data-html
                  />
                </Icon>
                <ReactTooltip
                  class="initialTextTransform"
                  id={toogleMetrics?.current}
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                />
              </div>
            </div>

            <div className="number-rate">
              {toogleMetrics.current === ACOS
                ? adCurrentTotal && adCurrentTotal.acos
                  ? `${addThousandComma(adCurrentTotal.acos)}%`
                  : `0.00%`
                : displayMetricsValue(adCurrentTotal?.roas)}
            </div>
            <div className="vs">
              {toogleMetrics.current === ACOS
                ? adPreviousTotal && adPreviousTotal.acos
                  ? `vs ${addThousandComma(adPreviousTotal.acos)}%`
                  : `vs 0.00%`
                : `vs ${displayMetricsValue(adPreviousTotal?.roas)}`}
            </div>
            <div className="justify-space-between">
              {toogleMetrics.current === ACOS ? (
                adDifference?.acos || adDifference?.acos === 0 ? (
                  <DifferenceAdMetric value={adDifference.acos} type="acos" />
                ) : (
                  <div className="perentage-value down mt-3 pt-1">N/A</div>
                )
              ) : adDifference?.roas || adDifference?.roas === 0 ? (
                <DifferenceAdMetric value={adDifference.roas} />
              ) : (
                <div className="perentage-value down mt-3 pt-1">N/A</div>
              )}
              {displayGoalsInfoOnCardMetrics(
                toogleMetrics.current === ACOS ? ACOS : ROAS,
              )}
            </div>
          </div>
        </CardMetrics>
      </div>
    );
  };

  // display acos and roas toggle metrics card
  const displayCPCandSPCToggleMetrics = (index) => {
    return (
      <div
        className={`col-lg-3 col-md-6 col-12 mb-2  ${sponsoredMetricsClasses[index]}`}
      >
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(
            toggleCPCandSPCMetrics.current === COSTPERCLICK
              ? 'COST PER CLICK (CPC)'
              : 'SALES PER CLICK (CPC)',
          )}
          data-for="sponsoredAdcost_per_click"
        >
          <div
            id="BT-sponsored-CpcSpcCard"
            onClick={handleCPCandSPCToggleEvents}
            role="presentation"
            className={`${
              toggleCPCandSPCMetrics.current === COSTPERCLICK
                ? setBoxClasses(COSTPERCLICK, 'costPerClick-active')
                : setBoxClasses(SALESPERCLICK, 'costPerClick-active')
            } ${returnDisbaledClass(
              adCurrentTotal?.ad_spend,
              adPreviousTotal?.ad_spend,
            )} ${sponsoredGoalsToggle ? 'disabled' : ''}`}
          >
            <div className="d-flex">
              <div
                style={{
                  position: 'relative',
                  width: 'calc(100% - 18px)',
                  marginRight: '5px',
                }}
              >
                <ToogleMetrics
                  data={CPCandSPCToggleMetrics}
                  onToogle={(val) => {
                    toggleCPCandSPCMetrics.current = val;
                  }}
                  value={toggleCPCandSPCMetrics.current}
                  toggleName="CPCandSPCToggleMetrics"
                />
              </div>
              <div style={{ position: 'relative', width: '13px' }}>
                <Icon>
                  <FontAwesomeIcon
                    icon="fa-regular fa-circle-info"
                    data-tip={
                      sponosredMetricsTooltipInfo[
                        toggleCPCandSPCMetrics?.current
                      ]
                    }
                    data-for={toggleCPCandSPCMetrics?.current}
                    data-html
                  />
                </Icon>
                <ReactTooltip
                  class="initialTextTransform"
                  id={toggleCPCandSPCMetrics?.current}
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                />
              </div>
            </div>

            <div className="number-rate">
              {toggleCPCandSPCMetrics.current === COSTPERCLICK
                ? displayMetricsValue(adCurrentTotal?.cost_per_click)
                : displayMetricsValue(adCurrentTotal?.sales_per_click)}
            </div>
            <div className="vs">
              {toggleCPCandSPCMetrics.current === COSTPERCLICK
                ? `vs ${displayMetricsValue(adPreviousTotal?.cost_per_click)}`
                : `vs ${displayMetricsValue(adPreviousTotal?.sales_per_click)}`}
            </div>
            <div className="justify-space-between">
              {toggleCPCandSPCMetrics.current === COSTPERCLICK ? (
                adDifference?.cost_per_click ||
                adDifference?.cost_per_click === 0 ? (
                  <DifferenceAdMetric
                    value={adDifference.cost_per_click}
                    type="costPerClick"
                  />
                ) : (
                  <div className="perentage-value down mt-3 pt-1">N/A</div>
                )
              ) : adDifference?.sales_per_click ||
                adDifference?.sales_per_click === 0 ? (
                <DifferenceAdMetric
                  value={adDifference.sales_per_click}
                  type="salesPerClick"
                />
              ) : (
                <div className="perentage-value down mt-3 pt-1">N/A</div>
              )}
            </div>
          </div>
        </CardMetrics>
      </div>
    );
  };

  // display sp ad metrics
  const renderAdMetrics = () => {
    return (
      <div className="row mx-0">
        {sponsoredAdMultiSelected?.map((ele, index) =>
          ele?.value === ADCOS
            ? displayAcosAndRoasToggleMetrics(index)
            : ele?.value === COSTPERCLICK
            ? displayCPCandSPCToggleMetrics(index)
            : displayMetricsBoxHTML(
                sponsoredMetricsClasses[index],
                sponsoredMetricsDetails[ele?.value]?.id,
                sponsoredMetricsDetails[ele?.value]?.label,
                sponsoredMetricsDetails[ele?.value]?.metricsKey,
                sponsoredMetricsDetails[ele?.value]?.key,
                sponsoredMetricsActiveClasses[index],
                adCurrentTotal[sponsoredMetricsDetails[ele?.value]?.metricsKey],
                adPreviousTotal[
                  sponsoredMetricsDetails[ele?.value]?.metricsKey
                ],
                adDifference[sponsoredMetricsDetails[ele?.value]?.metricsKey],
                sponsoredMetricsDetails[ele?.value]?.hasCurrency
                  ? currencySymbol
                  : '',
                sponsoredMetricsDetails[ele?.value]?.hasPercentage,
                sponsoredMetricsDetails[ele?.value]?.decimalDigits,
                sponsoredMetricsDetails[ele?.value]?.hasGoals,
                sponsoredMetricsDetails[ele?.value]?.differenceType,
                sponsoredMetricsDetails[ele?.value]?.tooltipInfo,
              ),
        )}
      </div>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      {showMetricsTooltipForSponsored?.map((key) => {
        return (
          <Fragment key={key}>
            {!adCurrentTotal?.[key] && !adPreviousTotal?.[key] && !isApiCall ? (
              <ReactTooltip
                id={`sponsoredAd${key}`}
                class="initialTextTransform"
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />
            ) : null}
          </Fragment>
        );
      })}
      {renderAdMetrics()}
    </>
  );
};

export default SponsoredAdMetrics;

SponsoredAdMetrics.defaultProps = {
  currencySymbol: '',
  sponsoredAdMultiSelected: [{}],
  setSelectedAdMetrics: () => {},
  selectedAdMetrics: {},
  adCurrentTotal: {},
  addThousandComma: () => {},
  adPreviousTotal: {},
  adDifference: {},
  sponsoredGoalsMetricData: {},
  isApiCall: false,
  sponsoredGoalsToggle: false,
};

SponsoredAdMetrics.propTypes = {
  currencySymbol: string,
  sponsoredAdMultiSelected: arrayOf(shape({})),
  setSelectedAdMetrics: func,
  selectedAdMetrics: instanceOf(Object),
  adCurrentTotal: instanceOf(Object),
  addThousandComma: func,
  adPreviousTotal: instanceOf(Object),
  adDifference: instanceOf(Object),
  sponsoredGoalsMetricData: instanceOf(Object),
  isApiCall: bool,
  sponsoredGoalsToggle: bool,
};
