import React, { useCallback, useEffect, useState } from 'react';

import Modal from 'react-modal';

import { bool, func, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../../../theme/Theme';
import { CloseIcon } from '../../../theme/images';
import { getPartnerOpportunitiesDropdownOptions } from '../../../api';
import {
  OBJECTIVE,
  PRODUCT,
  TITLE,
  objectiveDefalutList,
  productDefalutList,
  titleDefalutList,
} from '../../../constants';
import {
  Button,
  ErrorMsg,
  InputFormField,
  ModalBox,
  PageLoader,
  SingleSelectDropdown,
  DropdownIndicator,
} from '../../../common';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '600px ',
    minHeight: '220px',
    width: '100% ',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function PartnerOpportunitiesReportModal({
  modalId,
  isOpen,
  isDownloading,
  selectedProduct,
  setSelectedProduct,
  selectedObjective,
  description,
  callToAction,
  setSelectedObjective,
  selectedTitle,
  setSelectedTitle,
  setDescription,
  setCallToAction,
  onDownload,
  onModalClose,
  customDateState,
}) {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [objectiveList, setObjectiveList] = useState([]);
  const [titleList, setTitleList] = useState([]);

  // bind list label
  const bindDropdownLabel = useCallback((label) => {
    const afterReplace = label?.replaceAll('_', ' ').toLowerCase();

    const afterSplit = afterReplace.split(' ');

    return afterSplit
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');
  }, []);

  // callback function for bind product list data
  const bindDropdownOptionsList = useCallback(
    (responseData) => {
      const localList = [];
      if (responseData?.length) {
        for (const item of responseData) {
          localList.push({
            label: bindDropdownLabel(item),
            value: item,
          });
        }
      }
      return localList;
    },
    [bindDropdownLabel],
  );

  // get the dropdown options
  const getDropdownList = useCallback(
    (field, product, objective) => {
      setIsDataLoading(true);
      getPartnerOpportunitiesDropdownOptions(
        'custom',
        customDateState,
        product,
        objective,
        field,
      ).then((res) => {
        if (res?.status === 200) {
          if (field === PRODUCT) {
            const localList = bindDropdownOptionsList(res?.data);
            setProductList(localList);
          }
          if (field === OBJECTIVE) {
            const localList = bindDropdownOptionsList(res?.data);
            setObjectiveList(localList);
          }
          if (field === TITLE) {
            const localList = [];
            if (res?.data?.length) {
              for (const item of res?.data) {
                localList.push({
                  label: item?.title,
                  value: item?.id,
                  description: item?.description,
                  callToAction: item?.call_to_action,
                });
              }
            }
            setTitleList(localList);
          }
        } else {
          if (field === PRODUCT) {
            setProductList([productDefalutList]);
          }
          if (field === OBJECTIVE) {
            setObjectiveList([objectiveDefalutList]);
          }
          if (field === TITLE) {
            setTitleList([titleDefalutList]);
            setDescription('');
            setCallToAction('');
          }
        }
        setIsDataLoading(false);
      });
    },
    [bindDropdownOptionsList, customDateState, setCallToAction, setDescription],
  );

  useEffect(() => {
    getDropdownList(PRODUCT, selectedProduct, selectedObjective);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectDropdownIndicator = () => {
    return {
      DropdownIndicator,
    };
  };

  const handleProductDropdown = (event) => {
    setSelectedProduct(event);
    if (event?.value === PRODUCT) {
      setSelectedObjective(objectiveDefalutList);
      setSelectedTitle(titleDefalutList);
      setDescription('');
      setCallToAction('');
      setObjectiveList([]);
      setTitleList([]);
    } else if (event.value !== selectedProduct?.value) {
      getDropdownList(OBJECTIVE, event, selectedObjective);
      setSelectedProduct(event);
      setSelectedObjective(objectiveDefalutList);
      setSelectedTitle(titleDefalutList);
      setDescription('');
      setCallToAction('');
    }
  };

  const handleObjectiveDropdown = (event) => {
    setSelectedObjective(event);
    if (event?.value === OBJECTIVE) {
      setSelectedTitle(titleDefalutList);
      setDescription('');
      setCallToAction('');
      setTitleList([]);
    } else if (event.value !== selectedObjective?.value) {
      setSelectedTitle(titleDefalutList);
      setDescription('');
      setCallToAction('');
      getDropdownList(TITLE, selectedProduct, event);
    }
  };

  const handleTitleDropdown = (event) => {
    setSelectedTitle(event);
    if (event.value === TITLE) {
      setDescription('');
      setCallToAction('');
    } else if (event.value !== selectedTitle?.value) {
      setDescription(event?.description);
      setCallToAction(event?.callToAction);
    }
  };

  const renderDropDownFilters = () => {
    return (
      <div className="row mt-3">
        <div className="col-md-4 col-12 mb-3 ">
          <SingleSelectDropdown
            filterId="productListDropdown"
            className="single-select-dropdown"
            isApiCall={isDownloading}
            dropdownOptions={productList}
            selectedValue={selectedProduct}
            onChangeHandler={(event) => {
              handleProductDropdown(event);
            }}
            dropdownComponents={getSelectDropdownIndicator}
            isSearchable={false}
            defaultValue={selectedProduct}
          />
        </div>
        <div className="col-md-4 col-12 mb-3 ">
          <SingleSelectDropdown
            filterId="objectiveListDropdown"
            className="single-select-dropdown"
            isApiCall={isDownloading}
            dropdownOptions={objectiveList}
            selectedValue={selectedObjective}
            onChangeHandler={(event) => {
              handleObjectiveDropdown(event);
            }}
            dropdownComponents={getSelectDropdownIndicator}
            isSearchable={false}
            defaultValue={selectedObjective}
          />
        </div>
        <div className="col-md-4 col-12 mb-3 ">
          <SingleSelectDropdown
            filterId="titleListDropdown"
            className="single-select-dropdown"
            isApiCall={isDownloading}
            dropdownOptions={titleList}
            selectedValue={selectedTitle}
            onChangeHandler={(event) => {
              handleTitleDropdown(event);
            }}
            dropdownComponents={getSelectDropdownIndicator}
            isSearchable={false}
            defaultValue={selectedTitle}
          />
        </div>
      </div>
    );
  };

  const renderDescriptionFiled = () => {
    return (
      <div className="pb-3">
        <InputFormField>
          <label htmlFor="reportDescription">Description</label>
          <input
            id="reportDescription"
            className={`form-control ${isDownloading ? 'disabled' : ''}`}
            type="text"
            placeholder="Description Comes Here"
            value={description}
            onChange={() => {}}
          />
        </InputFormField>

        {selectedProduct.value !== PRODUCT &&
        selectedObjective.value !== OBJECTIVE &&
        selectedTitle.value !== TITLE &&
        description === '' ? (
          <ErrorMsg>This is a required field.</ErrorMsg>
        ) : null}
      </div>
    );
  };

  const renderCallToActionField = () => {
    return (
      <div className="pb-3">
        <InputFormField>
          <label htmlFor="reportCallToAction">Call To Action</label>
          <input
            id="reportCallToAction"
            className={`form-control ${isDownloading ? 'disabled' : ''}`}
            type="text"
            placeholder="Call To Action Comes Here"
            value={callToAction}
            onChange={() => {}}
          />
        </InputFormField>

        {selectedProduct.value !== PRODUCT &&
        selectedObjective.value !== OBJECTIVE &&
        selectedTitle.value !== TITLE &&
        callToAction === '' ? (
          <ErrorMsg>This is a required field.</ErrorMsg>
        ) : null}
      </div>
    );
  };

  const renderInfoMessage = () => {
    return (
      <div className="row mt-1 mb-2">
        <div className="col-12">
          <div className="normal-text-black d-flex align-items-center">
            <FontAwesomeIcon
              icon=" fa-solid fa-exclamation-circle"
              className="mr-2"
              color={Theme.red}
              fontSize="25px"
            />
            Dropdown selection depends on which reports are updated based on the
            month selected.{' '}
          </div>
        </div>
      </div>
    );
  };
  // render modal footer
  const renderModalFooter = () => {
    return (
      <div className="modal-footer">
        <div className="row">
          <div className="col-12">
            <Button
              className={
                isDataLoading ||
                selectedProduct?.value === PRODUCT ||
                selectedObjective?.value === OBJECTIVE ||
                selectedTitle?.value === TITLE
                  ? 'btn-primary on-boarding  w-100 disabled'
                  : 'btn-primary on-boarding  w-100'
              }
              onClick={() => onDownload()}
              type="button"
            >
              {isDownloading ? (
                <PageLoader color="#fff" type="button" />
              ) : (
                'Proceed'
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      <Modal
        id={modalId}
        isOpen={isOpen}
        style={{ ...customStyles }}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            onModalClose();
            setProductList([]);
            setObjectiveList([]);
            setTitleList([]);
          }}
          role="presentation"
        />
        <ModalBox>
          <>
            <div className="modal-body pb-0">
              <h4>Download Report</h4>
              <div className="footer-line" />
              <div
                className="body-content mt-2"
                style={{ minHeight: '50px', maxHeight: '450px' }}
              >
                {isDataLoading ? (
                  <PageLoader
                    type="page"
                    width={40}
                    height={55}
                    color="#FF5933"
                    component="escrow-modal-loader"
                  />
                ) : (
                  <>
                    {renderDropDownFilters()}
                    {renderDescriptionFiled()}
                    {renderCallToActionField()}
                    {renderInfoMessage()}
                  </>
                )}
              </div>
            </div>

            {renderModalFooter()}
          </>
        </ModalBox>
      </Modal>
    </>
  );
}

export default PartnerOpportunitiesReportModal;

PartnerOpportunitiesReportModal.defaultProps = {
  isOpen: false,
  isDownloading: false,
  modalId: '',
  description: '',
  callToAction: '',
  selectedProduct: {},
  selectedObjective: {},
  selectedTitle: {},
  customDateState: {},
  setSelectedObjective: () => {},
  setCallToAction: () => {},
  setSelectedTitle: () => {},
  setSelectedProduct: () => {},
  setDescription: () => {},
  onDownload: () => {},
  onModalClose: () => {},
};

PartnerOpportunitiesReportModal.propTypes = {
  isOpen: bool,
  isDownloading: bool,
  modalId: string,
  description: string,
  callToAction: string,
  selectedProduct: shape({}),
  selectedObjective: shape({}),
  selectedTitle: shape({}),
  customDateState: shape({}),
  setSelectedObjective: func,
  setCallToAction: func,
  setSelectedTitle: func,
  setSelectedProduct: func,
  setDescription: func,
  onDownload: func,
  onModalClose: func,
};
