import dayjs from 'dayjs';

const BindVendorSalesGraphData = (response, selectedMetrics) => {
  const tempData = [];
  // filterout previous data in one temporary object.
  if (response?.result?.previous?.length) {
    response.result.previous.forEach((item) => {
      const previousDate = dayjs(item?.report_date).format('MMM D YYYY');
      if (selectedMetrics === 'orderedRevenue') {
        tempData.push({
          orderedRevenuePrevious: item?.ordered_revenue,
          glanceViewsPrevious: item?.glance_views,
          conversionRatePrevious: item?.conversion_rate,
          orderedUnitsPrevious: item?.ordered_units,
          previousDate,

          orderedRevenuePreviousLabel:
            item?.ordered_revenue !== null
              ? item?.ordered_revenue.toFixed(2)
              : '0.00',
          glanceViewsPreviousLabel:
            item?.glance_views !== null ? item.glance_views.toFixed(0) : '0',
          orderedUnitsPreviousLabel:
            item?.ordered_units !== null ? item.ordered_units.toFixed(0) : '0',
          conversionRatePreviousLabel:
            item?.conversion_rate !== null
              ? item.conversion_rate.toFixed(2)
              : '0',
        });
      } else {
        tempData.push({
          shippedCOGsPrevious: item?.shipped_cogs,
          glanceViewsPrevious: item?.glance_views,
          conversionRatePrevious: item?.conversion_rate,
          shippedUnitsPrevious: item?.shipped_units,
          previousDate,

          shippedCOGsPreviousLabel:
            item?.shipped_cogs !== null ? item.shipped_cogs.toFixed(2) : '0.00',
          glanceViewsPreviousLabel:
            item?.glance_views !== null ? item.glance_views.toFixed(0) : '0',
          shippedUnitsPreviousLabel:
            item?.shipped_units !== null ? item.shipped_units.toFixed(0) : '0',
          conversionRatePreviousLabel:
            item?.conversion_rate !== null
              ? item.conversion_rate.toFixed(2)
              : '0',
        });
      }
    });
  }

  // filterout current data in one temporary object.
  if (response?.result?.current?.length) {
    response.result.current.forEach((item, index) => {
      const currentReportDate = dayjs(item?.report_date).format('MMM D YYYY');
      // let indexNumber = index;
      // add the current data at same index of prevoius in temporary object
      if (response.result.previous && index < response.result.previous.length) {
        if (selectedMetrics === 'orderedRevenue') {
          tempData[index].date = currentReportDate;
          tempData[index].orderedRevenueCurrent = item?.ordered_revenue;
          tempData[index].glanceViewsCurrent = item?.glance_views;
          tempData[index].orderedUnitsCurrent = item?.ordered_units;
          tempData[index].conversionRateCurrent = item?.conversion_rate;

          tempData[index].orderedRevenueCurrentLabel =
            item?.ordered_revenue !== null
              ? item.ordered_revenue.toFixed(2)
              : '0.00';
          tempData[index].glanceViewsCurrentLabel =
            item?.glance_views !== null ? item.glance_views.toFixed(0) : '0';
          tempData[index].orderedUnitsCurrentLabel =
            item?.ordered_units !== null ? item.ordered_units.toFixed(0) : '0';
          tempData[index].conversionRateCurrentLabel =
            item?.conversion_rate !== null
              ? item.conversion_rate.toFixed(2)
              : '0';
        } else {
          tempData[index].date = currentReportDate;
          tempData[index].shippedCOGsCurrent = item?.shipped_cogs;
          tempData[index].glanceViewsCurrent = item?.glance_views;
          tempData[index].shippedUnitsCurrent = item?.shipped_units;
          tempData[index].conversionRateCurrent = item?.conversion_rate;

          tempData[index].shippedCOGsCurrentLabel =
            item?.shipped_cogs !== null ? item.shipped_cogs.toFixed(2) : '0.00';
          tempData[index].glanceViewsCurrentLabel =
            item?.glance_views !== null ? item.glance_views.toFixed(0) : '0';
          tempData[index].shippedUnitsCurrentLabel =
            item?.shipped_units !== null ? item.shipped_units.toFixed(0) : '0';
          tempData[index].conversionRateCurrentLabel =
            item?.conversion_rate !== null
              ? item.conversion_rate.toFixed(2)
              : '0';
        }
      } else if (selectedMetrics === 'orderedRevenue') {
        // if current data count is larger than previous count then
        // generate separate key for current data and defien previous value null and previous label 0

        tempData.push({
          orderedRevenueCurrent: item?.ordered_revenue,
          glanceViewsCurrent: item?.glance_views,
          orderedUnitsCurrent: item?.ordered_units,
          conversionRateCurrent: item?.conversion_rate,
          date: currentReportDate,

          orderedRevenuePrevious: null,
          glanceViewsPrevious: null,
          orderedUnitsPrevious: null,
          conversionRatePrevious: null,

          orderedRevenueCurrentLabel:
            item?.ordered_revenue !== null
              ? item.ordered_revenue.toFixed(2)
              : '0.00',
          glanceViewsCurrentLabel:
            item?.glance_views !== null ? item.glance_views.toFixed(0) : '0',
          orderedUnitsCurrentLabel:
            item?.ordered_units !== null ? item.ordered_units.toFixed(0) : '0',
          conversionRateCurrentLabel:
            item?.conversion_rate !== null
              ? item.conversion_rate.toFixed(2)
              : '0',

          orderedRevenuePreviousLabel: '0.00',
          glanceViewsPreviousLabel: '0',
          orderedUnitsPreviousLabel: '0',
          conversionRatePreviousLabel: '0',
        });
      } else {
        tempData.push({
          shippedCOGsCurrent: item?.shipped_cogs,
          glanceViewsCurrent: item?.glance_views,
          shippedUnitsCurrent: item?.shipped_units,
          conversionRateCurrent: item?.conversion_rate,
          date: currentReportDate,

          shippedCOGsPrevious: null,
          glanceViewsPrevious: null,
          shippedUnitsPrevious: null,
          conversionRatePrevious: null,

          shippedCOGsCurrentLabel:
            item?.shipped_cogs !== null ? item.shipped_cogs.toFixed(2) : '0.00',
          glanceViewsCurrentLabel:
            item?.glance_views !== null ? item.glance_views.toFixed(0) : '0',
          shippedUnitsCurrentLabel:
            item?.shipped_units !== null ? item.shipped_units.toFixed(0) : '0',
          conversionRateCurrentLabel:
            item?.conversion_rate !== null
              ? item.conversion_rate.toFixed(2)
              : '0',

          shippedCOGsPreviousLabel: '0.00',
          glanceViewsPreviousLabel: '0',
          shippedUnitsPreviousLabel: '0',
          conversionRatePreviousLabel: '0',
        });
      }
    });
  }

  return tempData;
};

export default BindVendorSalesGraphData;
