/* ********** React imports ********** */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

/* ********** Third party package imports ********** */
import $ from 'jquery';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

/* ********** Local component file imports ********** */
import 'react-date-range/dist/theme/default.css'; // theme css file
import DashboardStyle from '../DashboardStyle';
import 'react-date-range/dist/styles.css'; // main style file
import SalesPerformanceContainer from './SalesPerformanceContainer';
import BindVendorSalesPerformanceData from './VendorReporting/BindVendorSalesPerformanceData';
import BindSellerSalesPerformanceData from './SellerReporting/BindSellerSalesPerformanceData';
import {
  SalesDashboardContainerDefaultProps,
  SalesDashboardContainerPropTypes,
} from './SalesPropTypes';
import {
  getManagersList,
  getSalesGraphData,
  getSalesKeyContributionData,
  getBgsUserList,
  getMinDateForDashboard,
  getGoalPerformanceData,
  getVendorSalesPerformanceData,
  getLeadershipBrandPartners,
  getKeyContributorsdownloadReport,
} from '../../../../api';
import {
  ADSPENDTOTAL,
  CUSTOM,
  HYBRID,
  ORDEREDREVENUE,
  PERFORMANCE,
  REVENUETOTAL,
  SELLERSMALL,
  SHIPPEDCOGS,
  VENDOR,
  VENDORSMALL,
  YEAROVERYEAR,
  allValueObj,
  metricsResponseKey,
  performanceTypeOptions,
  salesMetricsResponseKey,
  vendorSalesMetricsTypeOptions,
} from '../../../../constants';

const getSymbolFromCurrency = require('currency-symbol-map');
const _ = require('lodash');

/* ********** MAIN FUNCTION START HERE ********** */
export default function SalesDashboardContainer({
  // string
  responseID,
  currencySymbol,
  // boolean
  isBGS,
  isBGSAdmin,
  isAdManager,
  isBGSManager,
  isSpreetailRole,
  isAdTeamManager,
  isAdManagerAdmin,
  isCustomDateApply,
  includeInactiveBPs,
  includeSpreetailBrands,
  // object
  userInfo,
  customDate,
  selectedSalesDF,
  selectedMarketplace,
  selectedBrandPartner,
  selectedPerformanceType,
  // array
  loggedInUser,
  selectedUser,
  customDateState,
  marketplaceChoices,
  selectedManagerUser,
  // function
  setResponseID,
  setCustomDate,
  setSelectedUser,
  setSelectedSalesDF,
  bindUserListData,
  checkIsUserExist,
  setCurrencySymbol,
  bindBrandPartners,
  renderAllocateBar,
  setCustomDateState,
  setIsCustomDateApply,
  setSelectedManagerUser,
  setSelectedMarketplace,
  generateStringOfValues,
  setSelectedBrandPartner,
  displayContributionTable,
  setSelectedPerformanceType,
  bindMetricsContributionData,
  renderRecentAndPreviousLabel,
  handleMultiSelectDropdownFilterEvents,
}) {
  const mounted = useRef(false);

  // https://bbe.atlassian.net/browse/PDV-9198
  // seller date logic - current date - 2 days
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 2);
  // seller date logic - current date - 3 days
  const currentDateForVendor = new Date();
  currentDateForVendor.setDate(currentDateForVendor.getDate() - 3);

  const [salesChartData, setSalesChartData] = useState([]);
  const [salesCurrentTotal, setSalesCurrentTotal] = useState({});
  const [salesPreviousTotal, setSalesPreviousTotal] = useState({});
  const [salesDifference, setSalesDifference] = useState({});
  const [showAdCustomDateModal, setShowAdCustomDateModal] = useState(false);
  const [showAdYearOverYearDateModal, setShowAdYearOverYearDateModal] =
    useState(false);
  const [salesGroupBy, setSalesGroupBy] = useState('daily');
  const [pageNumber, setPageNumber] = useState(1);
  const [contributionCount, setContributionCount] = useState(null);
  const [selectedRevenueType, setSelectedRevenueType] = useState(REVENUETOTAL);
  const [isDisabledTimeFrame, setIsDisabledTimeFrame] = useState(false);
  const [isMetricsDisabled, setIsMetricsDisabled] = useState({});

  // use state for bp list, marketplace list, bgs list, bgs manager list, ad manager list, ad team manager list,
  const [brandPartnerOptions, setBrandPartnerOptions] = useState([]);
  const [marketplaceOptions, setMarketplaceOptions] =
    useState(marketplaceChoices);
  const [userList, setUserList] = useState([]);
  const [userApiList, setUserApiList] = useState([]);
  const [managerUserList, setManagerUserList] = useState([]);
  const [managerUserApiList, setManagerUserApiList] = useState([]);
  const [isUsersSelected, setIsUsersSelected] = useState(false);
  const [isManagerUsersSelected, setIsManagerUsersSelected] = useState(false);

  const [responseId, setResponseId] = useState(null);
  const [salesGraphLoader, setSalesGraphLoader] = useState(false);
  const [selectedTabMetrics, setSelectedTabMetrics] = useState(
    selectedPerformanceType?.value === SELLERSMALL ? 'revenue' : ORDEREDREVENUE,
  );
  const [contributionData, setContributionData] = useState([]);
  const [contributionLoader, setContributionLoader] = useState(false);
  const [customDateError, setCustomDateError] = useState(null);
  const [isManagerAPICalled, setIsManagerAPICalled] = useState(
    !(isAdManagerAdmin || isBGSAdmin || isSpreetailRole),
  );
  const [isBGSAPICalled, setIsBGSAPICalled] = useState(
    !(isBGSAdmin || isBGSManager || isSpreetailRole),
  );

  const [selectedSalesMetrics, setSelectedSalesMetrics] = useState({
    revenueTotal: true,
  });

  const [yearOverYearDateState, setYearOverYearDateState] = useState([
    {
      startDate: currentDate,
      endDate: currentDate,
      key: 'yearOverYearSelection',
    },
  ]);

  const [selectedRevenueDropDownOption, setSelectedRevenueDropDownOption] =
    useState({
      id: 1,
      name: REVENUETOTAL,
      label: 'Total',
      value: REVENUETOTAL,
    });

  const [selectedAdSpendDropDownOption, setSelectedAdSpendDropDownOption] =
    useState({
      id: 1,
      name: ADSPENDTOTAL,
      label: 'Total',
      value: ADSPENDTOTAL,
    });
  const [selectedAdSpendType, setSelectedAdSpendType] = useState(ADSPENDTOTAL);

  // vendor const and variables
  const [selectedVendorMetricsType, setSelectedVendorMetricsType] = useState(
    vendorSalesMetricsTypeOptions?.[0],
  );
  const [selectedVendorMetrics, setSelectedVendorMetrics] = useState({
    orderedRevenue: true,
  });
  const [vendorSalesMetricsData, setVendorSalesMetricsData] = useState([]);
  const [vendorSalesCurrentTotal, setVendorSalesCurrentTotal] = useState({});
  const [vendorSalesPreviousTotal, setVendorSalesPreviousTotal] = useState({});
  const [vendorSalesDifference, setVendorSalesDifference] = useState({});

  // goals state variables started
  const [goalsPerformanceData, setGoalsPerformanceData] = useState([]);
  const [showCustomMonthModal, setShowCustomMonthModal] = useState(false);
  const [customMonthRange, setCustomMonthRange] = useState([
    {
      startMonth: new Date(),
      endMonth: new Date(),
      key: 'GoalsToPerformance',
    },
  ]);
  // usestate hook for download report
  const [downloadReportLoader, setDownloadReportLoader] = useState(false);

  const newDate = new Date();
  newDate.setDate(1);

  const todaysDate = dayjs(newDate).format('YYYY-MM-DD');

  const date = new Date();
  const firstDay = dayjs(
    new Date(date.getFullYear(), date.getMonth(), 1),
  ).format('YYYY-MM-DD');
  const lastDay = dayjs(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ).format('YYYY-MM-DD');

  const [customStartDate, setCustomStartDate] = useState(firstDay);
  const [customEndDate, setCustomEndDate] = useState(lastDay);

  const sdd = new Date(customStartDate);
  const edd = new Date(customEndDate);

  const startDateOfGoalsForSalesApi = `${sdd.getDate()}-${
    sdd.getMonth() + 1
  }-${sdd.getFullYear()}`;
  const endDateOfGoalsForSalesApi = `${edd.getDate()}-${
    edd.getMonth() + 1
  }-${edd.getFullYear()}`;
  // end Goals state variables

  // all brand partner object
  const allBrandPartnerObj = {
    label: 'All brand Partners',
    value: 'all',
    accountType: HYBRID,
    marketplaces: marketplaceChoices,
  };

  // default all value array of object
  const allObj = useMemo(() => {
    return allValueObj;
  }, []);

  // use effect for set marketplace options
  useEffect(() => {
    setMarketplaceOptions(marketplaceChoices);
  }, [marketplaceChoices]);

  // callback functions for get brand partners list
  const getLeadershipBrandPartnersList = useCallback(
    (callType, managersUser, bgsUser, selectedBp) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);

      getLeadershipBrandPartners(
        userInfo?.role,
        'sales_performance',
        managerUserString,
        bgsUserString,
        includeInactiveBPs,
        includeSpreetailBrands,
      ).then((response) => {
        if (response?.status === 200 && response?.data?.length) {
          const bpList = bindBrandPartners(response?.data);

          setBrandPartnerOptions(bpList);
          const brandPartner =
            callType === 'useEffect' ? selectedBp : bpList?.[0];
          setSelectedBrandPartner(brandPartner);

          if (bpList?.length === 1 && brandPartner?.accountType === VENDOR) {
            setSelectedPerformanceType(performanceTypeOptions?.[1]);
          } else {
            setSelectedPerformanceType(performanceTypeOptions?.[0]);
          }
        } else {
          setBrandPartnerOptions([]);
          setSelectedBrandPartner({});
          setSelectedPerformanceType({});
        }
      });
    },
    [
      bindBrandPartners,
      generateStringOfValues,
      includeInactiveBPs,
      includeSpreetailBrands,
      setSelectedBrandPartner,
      setSelectedPerformanceType,
      userInfo,
    ],
  );

  // get user list - bgs and ad manager
  const getBgsList = useCallback(
    (role, managers, users, callType, selectedDailyFact) => {
      setSalesGraphLoader(true);
      setContributionLoader(selectedDailyFact !== CUSTOM);

      const managersString = generateStringOfValues(managers);

      let API = '';
      if (role === 'BGS') {
        API = getBgsUserList(role, managersString);
      }
      if (role === 'Ad Manager') {
        API = getManagersList(role, 'sales_performance', managersString);
      }

      API.then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const formatedApiData = bindUserListData(response?.data, role);
            setUserList(formatedApiData?.userListWithAll);
            setUserApiList(formatedApiData?.userList);

            const isUserExist = checkIsUserExist(
              formatedApiData?.userList,
              users,
            );

            if (callType !== 'bgsFilter') {
              if (
                isUserExist &&
                callType !== 'resetFilter' &&
                callType !== 'managerUserFilter'
              ) {
                setSelectedUser(users);
              } else {
                setSelectedUser(formatedApiData?.userListWithAll);
              }
            }

            setIsBGSAPICalled(true);
          } else {
            setUserList([]);
            setSelectedUser([]);
            setUserApiList([]);
          }
        }
      });
    },

    [
      bindUserListData,
      checkIsUserExist,
      generateStringOfValues,
      setSelectedUser,
    ],
  );

  // get manager user list - bgs manager and ad team manager
  const getManagerUserList = useCallback(
    (role, managerUsers, users, callType, selectedDailyFact) => {
      setSalesGraphLoader(true);
      setContributionLoader(selectedDailyFact !== CUSTOM);

      let API = '';
      if (role === 'BGS Manager') {
        API = getBgsUserList(role);
      }
      if (role === 'Ad Team Manager') {
        API = getManagersList(role, 'sales_performance');
      }

      API.then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const formatedApiData = bindUserListData(response?.data);
            setManagerUserApiList(formatedApiData?.userList);
            setManagerUserList(formatedApiData?.userListWithAll);

            const isManagerUserExist = checkIsUserExist(
              formatedApiData?.userList,
              managerUsers,
            );
            let managerUser = null;

            if (
              (isBGSAdmin || isAdManagerAdmin || isSpreetailRole) &&
              isManagerUserExist &&
              callType !== 'resetFilter'
            ) {
              setSelectedManagerUser(managerUsers);
              managerUser = managerUsers;
            } else {
              setSelectedManagerUser(formatedApiData?.userListWithAll);
              managerUser =
                formatedApiData?.userList?.length > 1
                  ? null
                  : formatedApiData?.userList;
            }
            if (isBGSAdmin || isSpreetailRole) {
              getBgsList(
                'BGS',
                managerUser,
                users,
                callType,
                selectedDailyFact,
              );
            }
            if (isAdManagerAdmin) {
              getBgsList(
                'Ad Manager',
                managerUser,
                users,
                callType,
                selectedDailyFact,
              );
            }

            setIsManagerAPICalled(true);
          } else {
            setManagerUserApiList([]);
            setManagerUserList([]);
            setSelectedManagerUser([]);
          }
        }
      });
    },

    [
      bindUserListData,
      checkIsUserExist,
      getBgsList,
      isAdManagerAdmin,
      isBGSAdmin,
      isSpreetailRole,
      setSelectedManagerUser,
    ],
  );

  // calling bgs and advertising user api
  const callingBgsAndAdUserApi = useCallback(
    (callType, managerUsers, users, selectedDailyFact) => {
      //  advertising api
      if (isAdManagerAdmin) {
        getManagerUserList(
          'Ad Team Manager',
          managerUsers,
          users,
          callType,
          selectedDailyFact,
        );
      }

      if (isAdTeamManager) {
        getBgsList(
          'Ad Manager',
          loggedInUser,
          users,
          callType,
          selectedDailyFact,
        );
      }

      // bgs api
      if (isBGSAdmin || isSpreetailRole) {
        getManagerUserList(
          'BGS Manager',
          managerUsers,
          users,
          callType,
          selectedDailyFact,
        );
      }

      if (isBGSManager) {
        getBgsList('BGS', loggedInUser, users, callType, selectedDailyFact);
      }
    },
    [
      isAdManagerAdmin,
      isAdTeamManager,
      isBGSAdmin,
      isSpreetailRole,
      isBGSManager,
      getManagerUserList,
      getBgsList,
      loggedInUser,
    ],
  );

  // use effect for bgs and advertising team
  useEffect(() => {
    mounted.current = true;
    callingBgsAndAdUserApi(
      'useEffect',
      selectedManagerUser,
      selectedUser,
      selectedSalesDF?.value,
    );
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingBgsAndAdUserApi]);

  // bind the error message if data not available for selecetd custom date
  // API to get the data available date for sales
  const getMinDateForSalesDashboard = useCallback(() => {
    const dates = [];
    const selectedUserString = generateStringOfValues(selectedUser);
    const selectedManagerUserString =
      generateStringOfValues(selectedManagerUser);

    getMinDateForDashboard(
      selectedMarketplace?.value,
      selectedManagerUserString,
      selectedUserString,
      null,
      'sales_dashboard',
    ).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.result && res?.data?.result.length) {
          dates.push(
            new Date(dayjs(customDateState[0].startDate).format('MM/DD/YYYY')),
          );
          dates.push(
            new Date(
              dayjs(res?.data?.result[0]?.sales_performance_date).format(
                'MM/DD/YYYY',
              ),
            ),
          );

          // if selected  date is lower that data_availeble_date then we need to show the error messages
          if (dates[0] < dates[1]) {
            setCustomDateError({
              fromDate: dates[0],
              toDate: dates[1],
            });
          } else {
            setCustomDateError(null);
          }
        }
      }
    });
  }, [
    customDateState,
    generateStringOfValues,
    selectedManagerUser,
    selectedMarketplace,
    selectedUser,
  ]);

  const checkMetricsAvailability = useCallback((response, timeFrame) => {
    const tempData = [];

    setIsDisabledTimeFrame(false);
    // if we get total value of any metrics is 0, then disabled that metrics
    // we setting the disable flag for each metrics
    let localMetricDisabled = {};

    _.keys(salesMetricsResponseKey).filter((option) => {
      if (
        (timeFrame === CUSTOM &&
          response?.current_sum[metricsResponseKey[option]] === 0) ||
        (response?.current_sum[metricsResponseKey[option]] === 0 &&
          response?.previous_sum[metricsResponseKey[option]] === 0)
      ) {
        localMetricDisabled = { ...localMetricDisabled, [option]: true };
      } else if (timeFrame === 'year') {
        // PDV-6572 If year_to_date option selected then disabled PPC and DSP
        localMetricDisabled = { ...localMetricDisabled, revenuePpc: true };
        localMetricDisabled = { ...localMetricDisabled, revenueDsp: true };
      } else {
        localMetricDisabled = { ...localMetricDisabled, [option]: false };
      }
      return null;
    });

    // if we don't have data for PPC then we need to disabled the PPC and organic also.

    if (
      (timeFrame === CUSTOM &&
        response?.current_sum[metricsResponseKey.revenuePpc] === 0) ||
      (response?.current_sum[metricsResponseKey.revenuePpc] === 0 &&
        response?.previous_sum[metricsResponseKey.revenuePpc] === 0)
    ) {
      localMetricDisabled = {
        ...localMetricDisabled,
        revenueOrganic: true,
      };
    }

    // if we get total value of any metrics is 0 when we change the Time frame, then disabled that Tiem frame filter
    _.keys(salesMetricsResponseKey).filter((option) => {
      if (
        _.keys(selectedSalesMetrics).includes(option) &&
        localMetricDisabled[option] === true
      ) {
        setIsDisabledTimeFrame(true);
      }
      return null;
    });

    // disabled tacos if selected date is less than 1st August 2022
    if (response?.current?.length) {
      const isHavingDspPPC = {
        isHavingDsp: response?.is_having_dsp,
        isHavingPpc: response?.is_having_ppc,
      };

      const lastElement = response?.current?.at(-1);
      if (
        timeFrame === CUSTOM &&
        new Date(dayjs(lastElement.report_date).format('MM D YYYY')) <
          new Date(dayjs('2022-08-01').format('MM D YYYY'))
      ) {
        const tacosData = response?.daily_facts?.current_sum?.tacos;

        if (isHavingDspPPC?.isHavingDsp && !isHavingDspPPC?.isHavingPpc) {
          if (tacosData === 0) {
            localMetricDisabled = {
              ...localMetricDisabled,
              tacos: true,
            };
          } else {
            localMetricDisabled = {
              ...localMetricDisabled,
              tacos: false,
            };
          }
        } else {
          localMetricDisabled = {
            ...localMetricDisabled,
            tacos: true,
          };
        }
      }
    }

    setIsMetricsDisabled(localMetricDisabled);
    return tempData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle before and after sales performance api call
  const handleBeforAndAfterApiCall = useCallback((key) => {
    if (key === PERFORMANCE) {
      setSalesChartData([]);
      setSalesPreviousTotal({});
      setSalesCurrentTotal({});
      setSalesDifference({});
      setIsMetricsDisabled({
        revenueTotal: true,
        revenueOrganic: true,
        revenuePpc: true,
        revenueDsp: true,
        unitsSold: true,
        traffic: true,
        conversion: true,
        adSpendTotal: true,
        adSpendPpc: true,
        adSpendDsp: true,
        tacos: true,
      });

      setVendorSalesMetricsData([]);
      setVendorSalesCurrentTotal({});
      setVendorSalesPreviousTotal({});
      setVendorSalesDifference({});

      setVendorSalesMetricsData([]);
      setVendorSalesCurrentTotal({});
      setVendorSalesPreviousTotal({});
      setVendorSalesDifference({});
    }
  }, []);

  // get sales graph data
  const getSalesData = useCallback(
    (
      selectedDailyFact,
      selectedGroupBy,
      brandPartner,
      marketplace,
      managersUser,
      bgsUser,
      performanceType,
      vendorMetricsType,
      startDate = null,
      endDate = null,
      inactiveBPs = includeInactiveBPs,
      spreetailBrands = includeSpreetailBrands,
    ) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);

      setSalesGraphLoader(true);
      setIsUsersSelected(false);
      setIsManagerUsersSelected(false);

      // get seller data
      if (performanceType === SELLERSMALL) {
        getSalesGraphData(
          selectedDailyFact,
          selectedGroupBy,
          brandPartner,
          marketplace,
          managerUserString,
          bgsUserString,
          isBGSAdmin || isSpreetailRole,
          isBGSManager,
          isBGS,
          isAdManagerAdmin,
          isAdTeamManager,
          isAdManager,
          startDate,
          endDate,
          inactiveBPs,
          spreetailBrands,
        ).then((response) => {
          if (mounted.current) {
            if (response?.status === 200 && response?.data?.result) {
              const responseData = response?.data?.result;
              const salesGraphData =
                BindSellerSalesPerformanceData(responseData);
              setSalesChartData(salesGraphData);
              setSalesCurrentTotal(responseData?.current_sum);
              setSalesPreviousTotal(responseData?.previous_sum);
              setSalesDifference(responseData?.difference_data);
              checkMetricsAvailability(responseData, selectedDailyFact);

              setSalesGraphLoader(false);
            } else {
              handleBeforAndAfterApiCall(PERFORMANCE);
              setSalesGraphLoader(false);
            }
          }
        });
      } else if (performanceType === VENDORSMALL) {
        getVendorSalesPerformanceData(
          selectedDailyFact,
          selectedGroupBy,
          brandPartner,
          marketplace,
          managerUserString,
          bgsUserString,
          isBGSAdmin || isSpreetailRole,
          isBGSManager,
          isBGS,
          isAdManagerAdmin,
          isAdTeamManager,
          isAdManager,
          startDate,
          endDate,
          inactiveBPs,
          spreetailBrands,
          vendorMetricsType,
        ).then((response) => {
          if (mounted.current) {
            if (response?.status === 200 && response?.data?.result) {
              const responseData = response?.data?.result;
              const vendorData = BindVendorSalesPerformanceData(
                responseData,
                vendorMetricsType,
                selectedDailyFact,
              );
              setVendorSalesMetricsData(vendorData?.metricsData);
              setVendorSalesCurrentTotal(vendorData?.salesCurrentTotal);
              setVendorSalesPreviousTotal(vendorData?.salesPreviousTotal);
              setVendorSalesDifference(vendorData?.salesDifference);

              setSalesGraphLoader(false);
            } else {
              handleBeforAndAfterApiCall(PERFORMANCE);
              setSalesGraphLoader(false);
            }
          }
        });
      } else {
        setSalesGraphLoader(false);
      }
    },
    [
      includeInactiveBPs,
      includeSpreetailBrands,
      generateStringOfValues,
      isBGSAdmin,
      isSpreetailRole,
      isBGSManager,
      isBGS,
      isAdManagerAdmin,
      isAdTeamManager,
      isAdManager,
      checkMetricsAvailability,
      handleBeforAndAfterApiCall,
    ],
  );

  // get contribution and key metris contribution data
  const getContributionData = useCallback(
    (
      selectedDailyFact,
      brandPartner,
      marketplace,
      managersUser,
      bgsUser,
      selectedMetrics,
      performanceType,
      vendorMetricsType = null,
      startDate = null,
      endDate = null,
      page,
      inactiveBPs = includeInactiveBPs,
      spreetailBrands = includeSpreetailBrands,
    ) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);

      setContributionLoader(true);
      setIsUsersSelected(false);
      setIsManagerUsersSelected(false);

      const vendorMetrics =
        performanceType === VENDORSMALL ? vendorMetricsType : null;

      getSalesKeyContributionData(
        selectedDailyFact,
        brandPartner,
        marketplace,
        managerUserString,
        bgsUserString,
        selectedMetrics,
        vendorMetrics,
        isBGSAdmin || isSpreetailRole,
        isBGSManager,
        isBGS,
        isAdManagerAdmin,
        isAdTeamManager,
        isAdManager,
        startDate,
        endDate,
        page,
        inactiveBPs || includeInactiveBPs,
        spreetailBrands || includeSpreetailBrands,
      ).then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const responseData =
              response?.data?.result || response?.data?.results;
            if (responseData) {
              setContributionData(bindMetricsContributionData(responseData));
              setContributionCount(response?.data?.count);
            } else {
              setContributionData([]);
              setPageNumber(page);
            }
          } else {
            setContributionData([]);
            setPageNumber(page);
          }
        }
        setContributionLoader(false);
      });
    },
    [
      includeInactiveBPs,
      includeSpreetailBrands,
      generateStringOfValues,
      isBGSAdmin,
      isSpreetailRole,
      isBGSManager,
      isBGS,
      isAdManagerAdmin,
      isAdTeamManager,
      isAdManager,
      bindMetricsContributionData,
    ],
  );

  const salesYearAndCustomDateFilter = (
    startDate,
    endDate,
    dailyFactFlag,
    brandPartner,
    marketplace,
    managerUser,
    bgsUser,
    performanceType,
    vendorMetricsType,
  ) => {
    const temp = 'daily';
    let sd = startDate;
    let ed = endDate;
    setSalesGroupBy(temp);

    sd = `${startDate.getDate()}-${
      startDate.getMonth() + 1
    }-${startDate.getFullYear()}`;
    ed = `${endDate.getDate()}-${
      endDate.getMonth() + 1
    }-${endDate.getFullYear()}`;

    setCustomDate({
      startDate: sd,
      endDate: ed,
      dateType: dailyFactFlag,
    });

    getSalesData(
      dailyFactFlag,
      temp,
      brandPartner,
      marketplace,
      managerUser,
      bgsUser,
      performanceType,
      vendorMetricsType,
      sd,
      ed,
    );

    if (dailyFactFlag === YEAROVERYEAR) {
      getContributionData(
        dailyFactFlag,
        brandPartner,
        marketplace,
        managerUser,
        bgsUser,
        selectedTabMetrics,
        performanceType,
        vendorMetricsType,
        sd,
        ed,
        1,
      );
    }
    setPageNumber(1);
  };

  // get goals data
  const getGoalsData = useCallback(
    (
      orderBy,
      brandPartner,
      marketplaces,
      startDate,
      endDate,
      bgsManagerList,
      bgsList,
      page,
      inactiveBp = includeInactiveBPs,
      spreetailBrands = includeSpreetailBrands,
    ) => {
      setContributionLoader(true);

      const bgsUserString = generateStringOfValues(bgsList);
      const managerUserString = generateStringOfValues(bgsManagerList);

      getGoalPerformanceData(
        orderBy,
        brandPartner,
        marketplaces,
        startDate,
        endDate,
        managerUserString,
        bgsUserString,
        page,
        isBGS,
        isBGSManager,
        isBGSAdmin || isSpreetailRole,
        inactiveBp,
        spreetailBrands,
      ).then((response) => {
        setContributionLoader(false);

        if (response?.status === 200) {
          setGoalsPerformanceData(
            response?.data?.result || response?.data?.results,
          );
          setContributionCount(response?.data?.count);
          setPageNumber(page);
        } else {
          setGoalsPerformanceData([]);
        }
      });
    },
    [
      includeInactiveBPs,
      includeSpreetailBrands,
      generateStringOfValues,
      isBGS,
      isBGSManager,
      isBGSAdmin,
      isSpreetailRole,
    ],
  );

  // get metrics, data and key contribution data
  // - called only at the begining.
  useEffect(() => {
    mounted.current = true;
    if (
      (responseId === null || responseID === 'response') &&
      isManagerAPICalled &&
      isBGSAPICalled
    ) {
      const startDate = customDate?.startDate;
      const endDate = customDate?.endDate;
      const dateType = customDate?.dateType;

      getMinDateForSalesDashboard();

      // get brand partners list
      getLeadershipBrandPartnersList(
        'useEffect',
        selectedManagerUser,
        selectedUser,
        selectedBrandPartner,
      );
      const vendorMetricsType =
        selectedPerformanceType?.value === VENDORSMALL ? ORDEREDREVENUE : null;

      if (selectedTabMetrics !== 'goals') {
        if (dateType === CUSTOM || dateType === YEAROVERYEAR) {
          getSalesData(
            selectedSalesDF?.value,
            salesGroupBy,
            selectedBrandPartner?.value,
            selectedMarketplace?.value,
            selectedManagerUser,
            selectedUser,
            selectedPerformanceType?.value,
            ORDEREDREVENUE,
            startDate,
            endDate,
          );
        }
        if (dateType === YEAROVERYEAR) {
          getContributionData(
            selectedSalesDF?.value,
            selectedBrandPartner?.value,
            selectedMarketplace?.value,
            selectedManagerUser,
            selectedUser,
            selectedTabMetrics,
            selectedPerformanceType?.value,
            vendorMetricsType,
            startDate,
            endDate,
            pageNumber,
          );
        }
        if (dateType === null) {
          getSalesData(
            selectedSalesDF?.value,
            salesGroupBy,
            selectedBrandPartner?.value,
            selectedMarketplace?.value,
            selectedManagerUser,
            selectedUser,
            selectedPerformanceType?.value,
            ORDEREDREVENUE,
            null,
            null,
          );
          getContributionData(
            selectedSalesDF?.value,
            selectedBrandPartner?.value,
            selectedMarketplace?.value,
            selectedManagerUser,
            selectedUser,
            selectedTabMetrics,
            selectedPerformanceType?.value,
            vendorMetricsType,
            null,
            null,
            pageNumber,
          );
        }
      } else {
        getGoalsData(
          'highest_sales',
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          customStartDate,
          customEndDate,
          selectedManagerUser,
          selectedUser,
          pageNumber,
          includeInactiveBPs,
          includeSpreetailBrands,
        );
        getSalesData(
          CUSTOM,
          salesGroupBy,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          selectedPerformanceType?.value,
          ORDEREDREVENUE,
          startDateOfGoalsForSalesApi,
          endDateOfGoalsForSalesApi,
          includeInactiveBPs,
          includeSpreetailBrands,
        );
      }
      setResponseId('12345');
      setResponseID(null);
    }
    return () => {
      mounted.current = false;
    };
  }, [
    customDate,
    customEndDate,
    customStartDate,
    getContributionData,
    getGoalsData,
    getMinDateForSalesDashboard,
    getSalesData,
    includeInactiveBPs,
    includeSpreetailBrands,
    isAdManagerAdmin,
    isBGSAPICalled,
    isBGSAdmin,
    isSpreetailRole,
    isManagerAPICalled,
    pageNumber,
    responseID,
    responseId,
    salesGroupBy,
    selectedManagerUser,
    selectedMarketplace,
    selectedPerformanceType,
    selectedSalesDF,
    selectedTabMetrics,
    selectedUser,
    endDateOfGoalsForSalesApi,
    startDateOfGoalsForSalesApi,
    setResponseID,
    selectedBrandPartner,
    getLeadershipBrandPartnersList,
  ]);

  // https://bbe.atlassian.net/browse/PDV-8757 - leadership dashboard improvements
  // download key contributors report
  const handleKeyContributorsDownloadReport = () => {
    setDownloadReportLoader(true);

    const userString = generateStringOfValues(selectedUser);
    const managerUserString = generateStringOfValues(selectedManagerUser);

    const dashboard =
      selectedPerformanceType?.value === VENDORSMALL
        ? selectedVendorMetricsType?.value === ORDEREDREVENUE
          ? 'ordered_revenue'
          : 'shipped_cogs'
        : 'sales_performance';

    const startDate = dayjs(customStartDate).format('YYYY-MM-DD');
    const endDate = dayjs(customEndDate).format('YYYY-MM-DD');

    getKeyContributorsdownloadReport(
      isBGSAdmin,
      isBGSManager,
      isBGS,
      isAdManagerAdmin,
      isAdTeamManager,
      isAdManager,
      dashboard,
      selectedSalesDF?.value,
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedTabMetrics,
      managerUserString,
      userString,
      startDate,
      endDate,
      includeInactiveBPs,
      includeSpreetailBrands,
    ).then((response) => {
      if (response?.status === 200) {
        const csvLink = response?.data?.url;
        if (csvLink) window.open(csvLink);
        if (response?.data?.message) toast.success(response?.data?.message);
        setDownloadReportLoader(false);
      } else {
        toast.error('Oops! Something went wrong...');
        setDownloadReportLoader(false);
      }
    });
  };

  // handle custom date states
  const handleCustomDateStates = (tempStartDate, tempEndDate) => {
    setCustomDateState([
      {
        startDate: tempStartDate,
        endDate: tempEndDate,
        key: 'adSelection',
      },
    ]);
  };

  // handle custom date states
  const handleYOYDateStates = (tempStartDate, tempEndDate) => {
    setYearOverYearDateState([
      {
        startDate: tempStartDate,
        endDate: tempEndDate,
        key: 'yearOverYearSelection',
      },
    ]);
  };

  // get custom dates for api
  const getcustomDatesForAPI = (dateType) => {
    const dateState =
      dateType === CUSTOM ? customDateState : yearOverYearDateState;
    const dateStateHandler =
      dateType === CUSTOM ? handleCustomDateStates : handleYOYDateStates;

    let apiStartDate = dateState?.[0]?.startDate;
    let apiEndDate = dateState?.[0]?.endDate;

    const sellerDate = dayjs(currentDate).format('YYYY-MM-DD');

    if (dateType === CUSTOM) {
      handleYOYDateStates(currentDateForVendor, currentDateForVendor);
    }
    if (dateType === YEAROVERYEAR) {
      handleCustomDateStates(currentDateForVendor, currentDateForVendor);
    }

    const tempStartDate = dayjs(dateState?.[0]?.startDate).format('YYYY-MM-DD');
    const tempEndDate = dayjs(dateState?.[0]?.endDate).format('YYYY-MM-DD');

    if (tempStartDate === sellerDate) {
      apiStartDate = currentDateForVendor;
      apiEndDate = currentDateForVendor;
      dateStateHandler(currentDateForVendor, currentDateForVendor, 'main');
    } else if (tempEndDate === sellerDate) {
      apiStartDate = dateState?.[0]?.startDate;
      apiEndDate = currentDateForVendor;
      dateStateHandler(dateState?.[0]?.startDate, currentDateForVendor, 'main');
    }

    return { apiStartDate, apiEndDate };
  };

  // calling leadership BP and dashboard performance api when filter value changes
  const callingBPandDashboardAPI = (
    callType,
    managerUsers,
    users,
    selectedBp,
  ) => {
    let selectedTab = '';
    let brandPartner = {};
    let marketplace = {};
    let performanceType = {};
    let vendorMetricsType = {};

    const managerUserString = generateStringOfValues(managerUsers);
    const userString = generateStringOfValues(users);

    getLeadershipBrandPartners(
      userInfo?.role,
      'sales_performance',
      managerUserString,
      userString,
      includeInactiveBPs,
      includeSpreetailBrands,
    ).then((response) => {
      if (response?.status === 200 && response?.data?.length) {
        setPageNumber(1);

        const bpList = bindBrandPartners(response?.data);
        setBrandPartnerOptions(bpList);

        if (callType === 'bpFilter') {
          if (selectedBp?.value === 'all') {
            brandPartner = allBrandPartnerObj;
          } else {
            brandPartner = selectedBp;
          }
        } else if (bpList?.length === 1) {
          brandPartner = bpList?.[0];
        } else {
          brandPartner = allBrandPartnerObj;
        }

        marketplace = brandPartner?.marketplaces?.[0];
        setSelectedBrandPartner(brandPartner);
        setSelectedMarketplace(marketplace);
        setMarketplaceOptions(brandPartner?.marketplaces);

        let apiStartDate = null;
        let apiEndDate = null;

        if (selectedSalesDF?.value === CUSTOM) {
          apiStartDate = customDateState?.[0]?.startDate;
          apiEndDate = customDateState?.[0]?.endDate;
        }

        if (selectedSalesDF?.value === YEAROVERYEAR) {
          apiStartDate = yearOverYearDateState?.[0]?.startDate;
          apiEndDate = yearOverYearDateState?.[0]?.endDate;
        }

        // Handeling the default states based on account type of selected BP.
        if (brandPartner?.accountType === VENDOR) {
          // handle default states when selected brand partner has Vendor account type
          selectedTab = ORDEREDREVENUE;
          performanceType = performanceTypeOptions?.[1];
          vendorMetricsType = vendorSalesMetricsTypeOptions?.[0];

          setSelectedTabMetrics(selectedTab);
          setSelectedPerformanceType(performanceType);
          setSelectedVendorMetricsType(vendorMetricsType);
          setSelectedVendorMetrics({ orderedRevenue: true });

          // handeling default T - 3 date logic for vendor
          if (
            selectedSalesDF?.value !== CUSTOM &&
            selectedSalesDF?.value !== YEAROVERYEAR
          ) {
            handleCustomDateStates(currentDateForVendor, currentDateForVendor);
            handleYOYDateStates(currentDateForVendor, currentDateForVendor);
          } else {
            let apiCustomDate = { apiStartDate: null, apiEndDate: null };
            // handeling default CUSTOM date T - 3 date logic for vendor
            if (selectedSalesDF?.value === CUSTOM) {
              apiCustomDate = getcustomDatesForAPI(CUSTOM);
            }

            // handeling default YOY T - 3 date logic for vendor
            if (selectedSalesDF?.value === YEAROVERYEAR) {
              apiCustomDate = getcustomDatesForAPI(YEAROVERYEAR);
            }

            apiStartDate = apiCustomDate?.apiStartDate;
            apiEndDate = apiCustomDate?.apiEndDate;
          }
        } else {
          // handle default states when selected brand partner has Seller account type
          selectedTab = 'revenue';
          performanceType = performanceTypeOptions?.[0];
          setSelectedTabMetrics('revenue');
          setSelectedPerformanceType(performanceType);
        }

        if (selectedSalesDF?.value === CUSTOM) {
          salesYearAndCustomDateFilter(
            apiStartDate,
            apiEndDate,
            selectedSalesDF?.value,
            brandPartner?.value,
            marketplace?.value,
            managerUsers,
            users,
            performanceType?.value,
            vendorMetricsType?.value,
          );
        } else if (selectedSalesDF?.value === YEAROVERYEAR) {
          salesYearAndCustomDateFilter(
            apiStartDate,
            apiEndDate,
            selectedSalesDF?.value,
            brandPartner?.value,
            marketplace?.value,
            managerUsers,
            users,
            performanceType?.value,
            vendorMetricsType?.value,
          );
        } else {
          getSalesData(
            selectedSalesDF?.value,
            salesGroupBy,
            brandPartner?.value,
            marketplace?.value,
            managerUsers,
            users,
            performanceType?.value,
            vendorMetricsType?.value,
          );
          getContributionData(
            selectedSalesDF?.value,
            brandPartner?.value,
            marketplace?.value,
            managerUsers,
            users,
            selectedTab,
            performanceType?.value,
            vendorMetricsType?.value,
            null,
            null,
            1,
          );
        }
      } else {
        setSalesGraphLoader(false);
        setContributionLoader(false);
        setIsUsersSelected(false);
        setIsManagerUsersSelected(false);
        setBrandPartnerOptions([]);
        setSelectedBrandPartner({});
        setSelectedPerformanceType({});
        setContributionData([]);
        handleBeforAndAfterApiCall(PERFORMANCE);
      }
    });
  };

  // https://bbe.atlassian.net/browse/PDV-8757 - leadership dashboard improvements
  // handle brand partner reporting filter events
  const handleBrandPartnerFilterEvents = (event) => {
    // Handeling the states and API calls when user select specific Brand Partner
    if (event?.value !== selectedBrandPartner?.value) {
      setSelectedBrandPartner(event);

      // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
      let assignedUser = [];
      let assignedMangerUser = [];

      if (event?.value === 'all') {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        const userApiListWithAll = [...userApiList];
        if (userApiListWithAll?.length) {
          userApiListWithAll.unshift(allObj?.[0]);
        }

        const managerUserApiListWithAll = [...managerUserApiList];
        if (managerUserApiListWithAll?.length)
          managerUserApiListWithAll.unshift(allObj?.[0]);

        assignedUser = selectedUser?.length ? selectedUser : userApiListWithAll;
        assignedMangerUser = selectedManagerUser?.length
          ? selectedManagerUser
          : managerUserApiListWithAll;

        if (selectedManagerUser?.length === 0)
          setManagerUserList(managerUserApiListWithAll);
        if (selectedUser?.length === 0) setUserList(userApiListWithAll);
      } else if (isBGSAdmin || isBGSManager || isBGS || isSpreetailRole) {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        assignedUser = event?.bgs !== null ? [event?.bgs] : [];
        assignedMangerUser =
          event?.bgsManager !== null ? [event?.bgsManager] : [];
      } else {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        assignedUser = event?.adManager !== null ? [event?.adManager] : [];
        assignedMangerUser =
          event?.adTeamManager !== null ? [event?.adTeamManager] : [];
      }

      // set assigned bgs manager/ad team manager, bgs/ad manager of selected BP to multi-select bgs manager/ad team manager, bgs/ad manager dropdown filter.
      setSelectedManagerUser(assignedMangerUser);
      setSelectedUser(assignedUser);

      // if selected brand partner do not have assigned bgs manager/ad team manager and bgs/ad manager then clear the multiselect manager user and user dropdown filter list

      if (assignedMangerUser?.length === 0) {
        setManagerUserList([]);
      } else {
        const managerUserApiListWithAll = [...managerUserApiList];
        managerUserApiListWithAll.unshift(allObj?.[0]);
        setManagerUserList(managerUserApiListWithAll);
      }
      if (assignedUser?.length === 0) setUserList([]);

      // if selected brand partner have assigned bgs manager/ad team manager and bgs/ad manager then get its the bgs/ad manager list
      if (assignedMangerUser?.length) {
        if (isBGSAdmin || isSpreetailRole) {
          getBgsList(
            'BGS',
            assignedMangerUser,
            assignedUser,
            'BPFilter',
            selectedSalesDF?.value,
          );
        }

        if (isAdManagerAdmin) {
          getBgsList(
            'Ad Manager',
            assignedMangerUser,
            assignedUser,
            'BPFilter',
            selectedSalesDF?.value,
          );
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI(
        'bpFilter',
        assignedMangerUser?.length ? assignedMangerUser : allObj,
        assignedUser?.length ? assignedUser : allObj,
        event,
      );
    }
  };

  // handle seller & vendor reporting filter events
  const handleSellerVendorFilterEvents = (event) => {
    let selectedTab = '';

    let apiStartDate = null;
    let apiEndDate = null;

    if (selectedSalesDF?.value === CUSTOM) {
      apiStartDate = customDateState?.[0]?.startDate;
      apiEndDate = customDateState?.[0]?.endDate;
    }

    if (selectedSalesDF?.value === YEAROVERYEAR) {
      apiStartDate = yearOverYearDateState?.[0]?.startDate;
      apiEndDate = yearOverYearDateState?.[0]?.endDate;
    }

    if (event?.value !== selectedPerformanceType?.value) {
      setSelectedPerformanceType(event);
      if (event?.value === SELLERSMALL) {
        selectedTab = 'revenue';
        setSelectedTabMetrics('revenue');

        if (
          selectedSalesDF?.value !== CUSTOM &&
          selectedSalesDF?.value !== YEAROVERYEAR
        ) {
          handleCustomDateStates(currentDate, currentDate);
        }
      }

      if (event?.value === VENDORSMALL) {
        setSelectedVendorMetrics({ orderedRevenue: true });
        selectedTab = ORDEREDREVENUE;
        setSelectedTabMetrics(ORDEREDREVENUE);
        setSelectedVendorMetricsType(vendorSalesMetricsTypeOptions?.[0]);

        // handeling default T - 3 date logic for vendor
        if (
          selectedSalesDF?.value !== CUSTOM &&
          selectedSalesDF?.value !== YEAROVERYEAR
        ) {
          handleCustomDateStates(currentDateForVendor, currentDateForVendor);
          handleYOYDateStates(currentDateForVendor, currentDateForVendor);
        } else {
          let apiCustomDate = { apiStartDate: null, apiEndDate: null };
          // handeling default CUSTOM date T - 3 date logic for vendor
          if (selectedSalesDF?.value === CUSTOM) {
            apiCustomDate = getcustomDatesForAPI(CUSTOM);
          }

          // handeling default YOY T - 3 date logic for vendor
          if (selectedSalesDF?.value === YEAROVERYEAR) {
            apiCustomDate = getcustomDatesForAPI(YEAROVERYEAR);
          }

          apiStartDate = apiCustomDate?.apiStartDate;
          apiEndDate = apiCustomDate?.apiEndDate;
        }
      }

      if (selectedSalesDF?.value === CUSTOM) {
        salesYearAndCustomDateFilter(
          apiStartDate,
          apiEndDate,
          selectedSalesDF?.value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          event?.value,
          ORDEREDREVENUE,
        );
      } else if (selectedSalesDF?.value === YEAROVERYEAR) {
        salesYearAndCustomDateFilter(
          apiStartDate,
          apiEndDate,
          selectedSalesDF?.value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          event?.value,
          ORDEREDREVENUE,
        );
      } else {
        getSalesData(
          selectedSalesDF?.value,
          salesGroupBy,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          event?.value,
          ORDEREDREVENUE,
        );
        getContributionData(
          selectedSalesDF?.value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          selectedTab,
          event?.value,
          selectedTab,
          null,
          null,
          1,
        );
        setPageNumber(1);
      }
    }
  };

  // handle marketplace filter events
  const handleMarketplace = (event) => {
    if (event?.value !== selectedMarketplace?.value) {
      setSelectedMarketplace(event);
      setCurrencySymbol(getSymbolFromCurrency(event?.currency));

      if (selectedSalesDF?.value === CUSTOM) {
        salesYearAndCustomDateFilter(
          customDateState[0]?.startDate,
          customDateState[0]?.endDate,
          selectedSalesDF?.value,
          selectedBrandPartner?.value,
          event?.value,
          selectedManagerUser,
          selectedUser,
          selectedPerformanceType?.value,
          selectedVendorMetricsType?.value,
        );
      } else if (selectedSalesDF?.value === YEAROVERYEAR) {
        salesYearAndCustomDateFilter(
          yearOverYearDateState[0]?.startDate,
          yearOverYearDateState[0]?.endDate,
          selectedSalesDF?.value,
          selectedBrandPartner?.value,
          event?.value,
          selectedManagerUser,
          selectedUser,
          selectedPerformanceType?.value,
          selectedVendorMetricsType?.value,
        );
      } else if (selectedTabMetrics === 'goals') {
        if (event?.value !== 'all') {
          setSelectedTabMetrics('revenue');
          getSalesData(
            selectedSalesDF?.value,
            salesGroupBy,
            selectedBrandPartner?.value,
            event?.value,
            selectedManagerUser,
            selectedUser,
            selectedPerformanceType?.value,
            selectedVendorMetricsType?.value,
          );
          getContributionData(
            selectedSalesDF?.value,
            selectedBrandPartner?.value,
            event?.value,
            selectedManagerUser,
            selectedUser,
            'revenue',
            selectedPerformanceType?.value,
            selectedVendorMetricsType?.value,
            null,
            null,
            1,
          );
        } else {
          getGoalsData(
            'highest_sales',
            selectedBrandPartner?.value,
            event?.value,
            customStartDate,
            customEndDate,
            selectedManagerUser,
            selectedUser,
            pageNumber,
          );
          getSalesData(
            CUSTOM,
            salesGroupBy,
            selectedBrandPartner?.value,
            event?.value,
            selectedManagerUser,
            selectedUser,
            selectedPerformanceType?.value,
            selectedVendorMetricsType?.value,
            startDateOfGoalsForSalesApi,
            endDateOfGoalsForSalesApi,
            includeInactiveBPs,
            includeSpreetailBrands,
          );
        }
      } else {
        getSalesData(
          selectedSalesDF?.value,
          salesGroupBy,
          selectedBrandPartner?.value,
          event?.value,
          selectedManagerUser,
          selectedUser,
          selectedPerformanceType?.value,
          selectedVendorMetricsType?.value,
        );
        getContributionData(
          selectedSalesDF?.value,
          selectedBrandPartner?.value,
          event?.value,
          selectedManagerUser,
          selectedUser,
          selectedTabMetrics,
          selectedPerformanceType?.value,
          selectedVendorMetricsType?.value,
          null,
          null,
          1,
        );
        setPageNumber(1);
      }
    }
  };

  // handle sales date filter events
  const handleSalesDailyFact = (event) => {
    const { value } = event;

    const defaultCurrentDate =
      selectedPerformanceType?.value === VENDORSMALL
        ? currentDateForVendor
        : currentDate;

    if (value !== CUSTOM && selectedSalesDF?.value !== CUSTOM) {
      setCustomDateError(null);
      setCustomDateState([
        {
          startDate: defaultCurrentDate,
          endDate: defaultCurrentDate,
          key: 'adSelection',
        },
      ]);
    } else if (
      value !== YEAROVERYEAR &&
      selectedSalesDF?.value !== YEAROVERYEAR
    ) {
      setYearOverYearDateState([
        {
          startDate: defaultCurrentDate,
          endDate: defaultCurrentDate,
          key: 'yearOverYearSelection',
        },
      ]);
    }

    if (value === CUSTOM) {
      setShowAdCustomDateModal(true);
    } else if (value === YEAROVERYEAR) {
      setShowAdYearOverYearDateModal(true);
    } else {
      setCustomDateError(null);
      let groupByOption = 'daily';
      if (value === 'year') {
        groupByOption = 'weekly';
      }
      setSelectedSalesDF(event);
      setIsCustomDateApply(false);
      setSalesGroupBy(groupByOption);
      getSalesData(
        value,
        groupByOption,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        selectedPerformanceType?.value,
        selectedVendorMetricsType?.value,
      );
      getContributionData(
        value,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        selectedTabMetrics,
        selectedPerformanceType?.value,
        selectedVendorMetricsType?.value,
        null,
        null,
        pageNumber,
      );
      setCustomDate({
        startDate: null,
        endDate: null,
        dateType: null,
      });
    }
  };

  // handle bgs manager/ad team managerfilter events
  const handleManagerUserFilterEvents = (event, action) => {
    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedManagerUser,
      managerUserApiList,
    );
    setSelectedManagerUser(selectedValues);
    setIsManagerUsersSelected(true);
  };

  // handle bgs manager/ad team manager on dropdwon menu close events
  const handleManagerUserOnMenuCloseEvents = () => {
    if (isManagerUsersSelected) {
      const managerUsers =
        selectedManagerUser?.length > 0 ? selectedManagerUser : managerUserList;
      setSelectedManagerUser(managerUsers);
      const isAllUser = managerUsers?.some((user) => user?.value === 'all');
      const users = allObj;

      if (isBGSAdmin || isSpreetailRole) {
        if (isAllUser) {
          getBgsList(
            'BGS',
            null,
            users,
            'managerUserFilter',
            selectedSalesDF?.value,
          );
        } else {
          getBgsList(
            'BGS',
            managerUsers,
            users,
            'managerUserFilter',
            selectedSalesDF?.value,
          );
        }
      }

      if (isAdManagerAdmin) {
        if (isAllUser) {
          getBgsList(
            'Ad Manager',
            null,
            users,
            'managerUserFilter',
            selectedSalesDF?.value,
          );
        } else {
          getBgsList(
            'Ad Manager',
            managerUsers,
            users,
            'managerUserFilter',
            selectedSalesDF?.value,
          );
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI(
        'managerUsersFilter',
        isAllUser ? allObj : managerUsers,
        users,
        null,
      );
    }
  };

  // handle bgs/ad manager filter events
  const handleBgsUserFilterEvents = (event, action) => {
    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedUser,
      userApiList,
    );

    setSelectedUser(selectedValues);
    setIsUsersSelected(true);
  };

  // handle bgs/ad manager on dropdwon menu close events
  const handleUserOnMenuCloseEvents = () => {
    if (isUsersSelected) {
      let managerUsers = selectedManagerUser;
      const users = selectedUser?.length > 0 ? selectedUser : userList;

      setSelectedUser(users);

      // When bgs manager and ad team manager filter set to all then Automatically set the bgs manager or ad team manager assigned to selected bgs or ad team manager
      if (
        (isBGSAdmin || isAdManagerAdmin || isSpreetailRole) &&
        selectedUser?.length === 1
      ) {
        const userExisted = userList?.find(
          (bgsUser) =>
            bgsUser?.value === selectedUser?.[0]?.value &&
            bgsUser?.managerUser !== null,
        );

        if (userExisted !== undefined) {
          const managerUserObject = {
            value: userExisted?.managerUser?.id,
            label: `${userExisted?.managerUser?.first_name} ${userExisted?.managerUser?.last_name}`,
          };
          managerUsers = [managerUserObject];
          setSelectedManagerUser([managerUserObject]);
          if (isBGSAdmin || isSpreetailRole) {
            getBgsList(
              'BGS',
              managerUsers,
              users,
              'bgsFilter',
              selectedSalesDF?.value,
            );
          } else {
            getBgsList(
              'Ad Manager',
              managerUsers,
              users,
              'bgsFilter',
              selectedSalesDF?.value,
            );
          }
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI('usersFilter', managerUsers, users, null);
    }
  };

  // handeling vendor metrics type filter events
  const handleVendorMetricsTypeFilterEvents = (event) => {
    const { value } = event;

    setSelectedVendorMetricsType(event);
    if (value === ORDEREDREVENUE) {
      setSelectedVendorMetrics({ orderedRevenue: true });
      setSelectedTabMetrics(ORDEREDREVENUE);
    } else {
      setSelectedVendorMetrics({ shippedCOGs: true });
      setSelectedTabMetrics(SHIPPEDCOGS);
    }

    if (selectedSalesDF?.value === CUSTOM) {
      salesYearAndCustomDateFilter(
        customDateState[0]?.startDate,
        customDateState[0]?.endDate,
        selectedSalesDF?.value,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        VENDORSMALL,
        value,
      );
    } else if (selectedSalesDF.value === YEAROVERYEAR) {
      salesYearAndCustomDateFilter(
        yearOverYearDateState[0]?.startDate,
        yearOverYearDateState[0]?.endDate,
        selectedSalesDF?.value,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        VENDORSMALL,
        value,
      );
    } else {
      getSalesData(
        selectedSalesDF?.value,
        salesGroupBy,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        VENDORSMALL,
        value,
      );
      getContributionData(
        selectedSalesDF?.value,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        value,
        VENDORSMALL,
        value,
        null,
        null,
        1,
      );
      setPageNumber(1);
    }
  };

  // reset button events - reset all bgs and advertising user filter values to 'all'
  const handleResetFilter = () => {
    $('.checkboxes input:radio').filter("[value='all']").prop('checked', true);

    // set default filter values
    setSelectedPerformanceType(performanceTypeOptions?.[0]);
    setCurrencySymbol(getSymbolFromCurrency('USD'));
    setSelectedMarketplace(marketplaceChoices?.[0]);
    setSelectedTabMetrics('revenue');
    setSelectedBrandPartner(allBrandPartnerObj);

    // set assigned bgs manager/ad team manager, bgs/ad manager of selected BP to multi-select bgs manager/ad team manager, bgs/ad manager dropdown filter.
    const managerUser = isBGSManager || isAdTeamManager ? loggedInUser : allObj;
    const user = isBGS || isAdManager ? loggedInUser : allObj;
    setSelectedUser(user);
    setSelectedManagerUser(managerUser);
    // calling Leadership-customer api
    getLeadershipBrandPartnersList(
      'resetFilter',
      managerUser,
      user,
      allBrandPartnerObj,
    );
    // calling bgs and advertising user api
    callingBgsAndAdUserApi(
      'resetFilter',
      managerUser,
      user,
      selectedSalesDF?.value,
    );
    setCustomDateError(null);

    // calling dashboard api
    if (selectedSalesDF?.value === CUSTOM) {
      salesYearAndCustomDateFilter(
        customDateState[0]?.startDate,
        customDateState[0]?.endDate,
        selectedSalesDF?.value,
        'all',
        'all',
        managerUser,
        user,
        SELLERSMALL,
        ORDEREDREVENUE,
      );
    } else if (selectedSalesDF?.value === YEAROVERYEAR) {
      salesYearAndCustomDateFilter(
        yearOverYearDateState[0]?.startDate,
        yearOverYearDateState[0]?.endDate,
        selectedSalesDF?.value,
        'all',
        'all',
        managerUser,
        user,
        SELLERSMALL,
        ORDEREDREVENUE,
      );
    } else {
      getSalesData(
        selectedSalesDF?.value,
        salesGroupBy,
        'all',
        'all',
        managerUser,
        user,
        SELLERSMALL,
        ORDEREDREVENUE,
      );
      getContributionData(
        selectedSalesDF?.value,
        'all',
        'all',
        managerUser,
        user,
        'revenue',
        SELLERSMALL,
        ORDEREDREVENUE,
        null,
        null,
        1,
      );
      setPageNumber(1);
    }
  };

  // handle group by filter events
  const handleSalesGroupBy = (value) => {
    if (value !== salesGroupBy) {
      setSalesGroupBy(value);

      if (
        selectedSalesDF?.value === CUSTOM ||
        selectedSalesDF?.value === YEAROVERYEAR
      ) {
        const { startDate } =
          selectedSalesDF?.value === CUSTOM
            ? customDateState[0]
            : yearOverYearDateState[0];
        const { endDate } =
          selectedSalesDF.value === CUSTOM
            ? customDateState[0]
            : yearOverYearDateState[0];
        let sd = startDate;
        let ed = endDate;
        sd = `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()}`;
        ed = `${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`;

        getSalesData(
          selectedSalesDF?.value,
          value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          selectedPerformanceType?.value,
          selectedVendorMetricsType?.value,
          sd,
          ed,
        );
      } else {
        getSalesData(
          selectedSalesDF?.value,
          value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          selectedPerformanceType?.value,
          selectedVendorMetricsType?.value,
        );
      }
    }
  };

  // handle metrics tab change events
  const handleOnMetricsTabChange = (value) => {
    if (value !== selectedTabMetrics) {
      setSelectedTabMetrics(value);
      setPageNumber(1);

      if (value !== 'goals') {
        if (selectedSalesDF?.value === YEAROVERYEAR) {
          const { startDate } = yearOverYearDateState[0];
          const { endDate } = yearOverYearDateState[0];
          let sd = startDate;
          let ed = endDate;
          sd = `${startDate.getDate()}-${
            startDate.getMonth() + 1
          }-${startDate.getFullYear()}`;
          ed = `${endDate.getDate()}-${
            endDate.getMonth() + 1
          }-${endDate.getFullYear()}`;

          getContributionData(
            selectedSalesDF?.value,
            selectedBrandPartner?.value,
            selectedMarketplace?.value,
            selectedManagerUser,
            selectedUser,
            value,
            selectedPerformanceType?.value,
            selectedVendorMetricsType?.value,
            sd,
            ed,
            1,
          );
        } else {
          getContributionData(
            selectedSalesDF?.value,
            selectedBrandPartner?.value,
            selectedMarketplace?.value,
            selectedManagerUser,
            selectedUser,
            value,
            selectedPerformanceType?.value,
            selectedVendorMetricsType?.value,
            null,
            null,
            1,
          );
          if (selectedTabMetrics === 'goals') {
            getSalesData(
              selectedSalesDF?.value,
              salesGroupBy,
              selectedBrandPartner?.value,
              selectedMarketplace?.value,
              selectedManagerUser,
              selectedUser,
              selectedPerformanceType?.value,
              selectedVendorMetricsType?.value,
            );
          }
        }
      } else {
        setSelectedSalesDF({
          value: '30days',
          label: 'Current 30 Days',
          sub: 'vs Previous 30 days',
        });
        getGoalsData(
          'highest_sales',
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          customStartDate,
          customEndDate,
          selectedManagerUser,
          selectedUser,
          1,
        );
        getSalesData(
          CUSTOM,
          salesGroupBy,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedManagerUser,
          selectedUser,
          selectedPerformanceType?.value,
          selectedVendorMetricsType?.value,
          startDateOfGoalsForSalesApi,
          endDateOfGoalsForSalesApi,
          includeInactiveBPs,
          includeSpreetailBrands,
        );
      }
    }
  };

  // apply custom start and end dates
  const applyCustomDate = (type) => {
    if (type === CUSTOM) {
      if (setSelectedSalesDF.value !== CUSTOM) {
        setSelectedSalesDF({
          value: CUSTOM,
          label: 'Custom Range',
          sub: 'Select start & end date',
        });
      }
      setIsCustomDateApply(true);
      salesYearAndCustomDateFilter(
        customDateState[0]?.startDate,
        customDateState[0]?.endDate,
        CUSTOM,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        selectedPerformanceType?.value,
        selectedVendorMetricsType?.value,
      );
      setCustomDateError(null);
      setShowAdCustomDateModal(false);
    }
    if (type === YEAROVERYEAR) {
      if (selectedSalesDF.value !== YEAROVERYEAR) {
        setSelectedSalesDF({
          value: YEAROVERYEAR,
          label: 'Year Over Year',
          sub: 'Select start and end dates',
        });
      }
      setIsCustomDateApply(true);
      salesYearAndCustomDateFilter(
        yearOverYearDateState[0]?.startDate,
        yearOverYearDateState[0]?.endDate,
        YEAROVERYEAR,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        selectedPerformanceType?.value,
        selectedVendorMetricsType?.value,
      );
      setShowAdYearOverYearDateModal(false);
    }
  };

  // handle sales pagination events
  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    if (
      selectedTabMetrics === 'goals' &&
      selectedPerformanceType?.value !== VENDORSMALL
    ) {
      // https://bbe.atlassian.net/browse/PDV-9131 - Handeling goals table pagination only for seller BPs.
      getGoalsData(
        'highest_sales',
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        customStartDate,
        customEndDate,
        selectedManagerUser,
        selectedUser,
        currentPage,
      );
    } else {
      getContributionData(
        selectedSalesDF?.value,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        selectedTabMetrics,
        selectedPerformanceType?.value,
        selectedVendorMetricsType?.value,
        null,
        null,
        currentPage,
      );
    }
  };

  // handle goals date change events of month filter
  const handleGoalsDateChangeEvents = () => {
    setShowCustomMonthModal(true);
  };

  // handle custom month range change events
  const handleCustomMonthRangeEvents = (selectedDate) => {
    const [startMonth, endMonth] = selectedDate;
    const tempStartMonth = endMonth === null ? startMonth : endMonth;
    setCustomMonthRange([
      {
        startMonth: tempStartMonth,
        endMonth: tempStartMonth,
        key: 'GoalsToPerformance',
      },
    ]);
  };

  // calculte the last day of month
  const getLastDayOfMonth = (year, month, index) => {
    return new Date(year, month, index);
  };

  // set selected start and end date
  const applyDateChangeEvents = (startDate, endDate) => {
    setCustomStartDate(startDate);
    setCustomEndDate(endDate || todaysDate);

    getGoalsData(
      'highest_sales',
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      startDate,
      endDate,
      selectedManagerUser,
      selectedUser,
      pageNumber,
    );
    // const startDateOfGoal = dayjs(new Date(startDate)).format('YYYY-MM-DD');
    // const endDateOfGoal = dayjs(new Date(endDate)).format('YYYY-MM-DD');
    const startDateOfGoal = new Date(startDate);
    const endDateOfGoal = new Date(endDate);

    const tempStartDate = `${startDateOfGoal.getDate()}-${
      startDateOfGoal.getMonth() + 1
    }-${startDateOfGoal.getFullYear()}`;
    const tempEndDate = `${endDateOfGoal.getDate()}-${
      endDateOfGoal.getMonth() + 1
    }-${endDateOfGoal.getFullYear()}`;

    getSalesData(
      CUSTOM,
      salesGroupBy,
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedManagerUser,
      selectedUser,
      selectedPerformanceType?.value,
      selectedVendorMetricsType?.value,
      tempStartDate,
      tempEndDate,
      includeInactiveBPs,
      includeSpreetailBrands,
    );
  };

  // handle custom date range changes events
  const handleCustomDateRangeEvents = (startDate, endDate, dateFlag) => {
    const newendDate = endDate || startDate;
    const lastDayOfEndMonth = getLastDayOfMonth(
      newendDate?.getFullYear(),
      newendDate?.getMonth() + 1,
      0,
    );

    let tempStartDate = startDate;
    let tempEndDate = lastDayOfEndMonth;

    if (dateFlag === 'custom-month') {
      tempStartDate = dayjs(startDate).format('YYYY-MM-DD');
      tempEndDate = dayjs(lastDayOfEndMonth).format('YYYY-MM-DD');
      applyDateChangeEvents(tempStartDate, tempEndDate);
    }
  };

  // Apply selected custom date range
  const applyCustomMonthRange = () => {
    handleCustomDateRangeEvents(
      customMonthRange?.[0]?.startMonth,
      customMonthRange?.[0]?.endMonth,
      'custom-month',
    );
    setShowCustomMonthModal(false);
  };

  // display sales performance container
  const displaySalesPerformanceContainer = () => {
    return (
      <SalesPerformanceContainer
        // string
        salesGroupBy={salesGroupBy}
        currencySymbol={currencySymbol}
        selectedTabMetrics={selectedTabMetrics}
        selectedRevenueType={selectedRevenueType}
        selectedAdSpendType={selectedAdSpendType}
        // number
        pageNumber={pageNumber}
        contributionCount={contributionCount}
        // boolean
        isBGSAdmin={isBGSAdmin}
        isBGSManager={isBGSManager}
        isAdTeamManager={isAdTeamManager}
        isSpreetailRole={isSpreetailRole}
        isAdManagerAdmin={isAdManagerAdmin}
        salesGraphLoader={salesGraphLoader}
        isCustomDateApply={isCustomDateApply}
        contributionLoader={contributionLoader}
        isDisabledTimeFrame={isDisabledTimeFrame}
        downloadReportLoader={downloadReportLoader}
        showCustomMonthModal={showCustomMonthModal}
        showAdCustomDateModal={showAdCustomDateModal}
        showAdYearOverYearDateModal={showAdYearOverYearDateModal}
        // object
        currentDate={currentDate}
        customStartDate={customStartDate}
        customDateError={customDateError}
        selectedSalesDF={selectedSalesDF}
        salesDifference={salesDifference}
        isMetricsDisabled={isMetricsDisabled}
        salesCurrentTotal={salesCurrentTotal}
        salesPreviousTotal={salesPreviousTotal}
        selectedMarketplace={selectedMarketplace}
        currentDateForVendor={currentDateForVendor}
        selectedSalesMetrics={selectedSalesMetrics}
        selectedBrandPartner={selectedBrandPartner}
        selectedAccountType={selectedPerformanceType}
        vendorSalesDifference={vendorSalesDifference}
        selectedVendorMetrics={selectedVendorMetrics}
        vendorSalesCurrentTotal={vendorSalesCurrentTotal}
        vendorSalesPreviousTotal={vendorSalesPreviousTotal}
        selectedVendorMetricsType={selectedVendorMetricsType}
        selectedRevenueDropDownOption={selectedRevenueDropDownOption}
        selectedAdSpendDropDownOption={selectedAdSpendDropDownOption}
        // array
        userList={userList}
        selectedUser={selectedUser}
        salesChartData={salesChartData}
        managerUserList={managerUserList}
        customDateState={customDateState}
        customMonthRange={customMonthRange}
        contributionData={contributionData}
        marketplaceOptions={marketplaceOptions}
        selectedManagerUser={selectedManagerUser}
        brandPartnerOptions={brandPartnerOptions}
        goalsPerformanceData={goalsPerformanceData}
        yearOverYearDateState={yearOverYearDateState}
        vendorSalesMetricsData={vendorSalesMetricsData}
        // function
        applyCustomDate={applyCustomDate}
        setSelectedUser={setSelectedUser}
        handlePageChange={handlePageChange}
        handleResetFilter={handleResetFilter}
        handleMarketplace={handleMarketplace}
        renderAllocateBar={renderAllocateBar}
        handleSalesGroupBy={handleSalesGroupBy}
        setCustomDateState={setCustomDateState}
        handleSalesDailyFact={handleSalesDailyFact}
        applyCustomMonthRange={applyCustomMonthRange}
        setSelectedManagerUser={setSelectedManagerUser}
        setIsDisabledTimeFrame={setIsDisabledTimeFrame}
        setSelectedAdSpendType={setSelectedAdSpendType}
        setSelectedRevenueType={setSelectedRevenueType}
        setSelectedSalesMetrics={setSelectedSalesMetrics}
        setSelectedVendorMetrics={setSelectedVendorMetrics}
        handleOnMetricsTabChange={handleOnMetricsTabChange}
        setShowCustomMonthModal={setShowCustomMonthModal}
        setShowAdCustomDateModal={setShowAdCustomDateModal}
        setYearOverYearDateState={setYearOverYearDateState}
        displayContributionTable={displayContributionTable}
        handleBgsUserFilterEvents={handleBgsUserFilterEvents}
        handleUserOnMenuCloseEvents={handleUserOnMenuCloseEvents}
        handleGoalsDateChangeEvents={handleGoalsDateChangeEvents}
        renderRecentAndPreviousLabel={renderRecentAndPreviousLabel}
        handleCustomMonthRangeEvents={handleCustomMonthRangeEvents}
        handleManagerUserFilterEvents={handleManagerUserFilterEvents}
        handleSellerVendorFilterEvents={handleSellerVendorFilterEvents}
        handleBrandPartnerFilterEvents={handleBrandPartnerFilterEvents}
        setShowAdYearOverYearDateModal={setShowAdYearOverYearDateModal}
        setSelectedRevenueDropDownOption={setSelectedRevenueDropDownOption}
        setSelectedAdSpendDropDownOption={setSelectedAdSpendDropDownOption}
        handleManagerUserOnMenuCloseEvents={handleManagerUserOnMenuCloseEvents}
        handleKeyContributorsDownloadReport={
          handleKeyContributorsDownloadReport
        }
        handleVendorMetricsTypeFilterEvents={
          handleVendorMetricsTypeFilterEvents
        }
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return <DashboardStyle>{displaySalesPerformanceContainer()}</DashboardStyle>;
}
/* ********** MAIN FUNCTION END HERE ********** */

/* ********** Declaring prop types ********** */
SalesDashboardContainer.defaultProps = SalesDashboardContainerDefaultProps;
SalesDashboardContainer.propTypes = SalesDashboardContainerPropTypes;
