import React, { useEffect, useRef } from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

// eslint-disable-next-line camelcase
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import { string, shape } from 'prop-types';
import dayjs from 'dayjs';

am4core.useTheme(am4themes_dataviz);
am4core.color('red');
const _ = require('lodash');

export default function CreativeProjectChart({
  chartId,
  chartData,
  currencySymbol,
  selectedMetrics,
  groupBy,
  completionDate,
  accountType,
  selectedVendorReporting,
}) {
  const chart = useRef(null);
  useEffect(() => {
    const colorSet =
      accountType === 'seller'
        ? {
            ordered_product_sales: '#0045b4',
            units_ordered: '#74b035',
            units_sold: '#C84EC6',
            page_views: '#E05D37',
            sessions: '#89A43C',
            buy_box_percentage: '#A04848',
            conversion: '#8C54FF',
          }
        : selectedVendorReporting === 'ordered_revenue'
        ? {
            ordered_revenue: '#0045b4',
            glance_views: '#74b035',
            conversion_rate: '#C84EC6',
            ordered_units: '#E05D37',
          }
        : {
            shipped_cogs: '#0045b4',
            glance_views: '#74b035',
            conversion_rate: '#C84EC6',
            shipped_units: '#E05D37',
          };

    const tooltipNames =
      accountType === 'seller'
        ? {
            ordered_product_sales: 'Ordered Product Sales',
            units_ordered: 'Units Ordered',
            units_sold: 'Average Units Pper Order',
            page_views: 'Page Views',
            sessions: 'Sessions',
            buy_box_percentage: 'Buy Box %',
            conversion: 'Conversion',
          }
        : selectedVendorReporting === 'ordered_revenue'
        ? {
            ordered_revenue: 'Ordered Revenue',
            glance_views: 'Glance Views',
            conversion_rate: 'Conversion Rate',
            ordered_units: 'Ordered Units',
          }
        : {
            shipped_cogs: 'Shipped COGS',
            glance_views: 'Glance Views',
            conversion_rate: 'Conversion Rate',
            shipped_units: 'Shipped Units',
          };
    const completionDateColor = 'red';

    // Compare two dates, current node date on graph and completion date of thet project
    // If Completion date and current node date of the graph are same then return true
    const compareTwoDates = (startDateParam) => {
      const startDateTemp = new Date(startDateParam);
      const completionDateTemp = new Date(completionDate);
      const sameDate =
        startDateTemp?.getFullYear() === completionDateTemp?.getFullYear() &&
        startDateTemp?.getMonth() === completionDateTemp?.getMonth() &&
        startDateTemp?.getDate() === completionDateTemp?.getDate();

      return sameDate;
    };

    chart.current = am4core.create(chartId, am4charts.XYChart);
    chart.current.data = chartData;
    chart.current.paddingRight = 20;
    chart.current.logo.disabled = true; // disable amchart logo
    chart.current.zoomOutButton.disabled = true; // disabled zoomout button
    // render X axis
    const dateAxis = chart.current.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.labels.template.location = 0.5;
    dateAxis.dy = 10;
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.periodChangeDateFormats.setKey('month', 'MMM');
    dateAxis.gridIntervals.setAll([
      { timeUnit: 'day', count: 1 },
      { timeUnit: 'day', count: 5 },
      { timeUnit: 'day', count: 10 },
      { timeUnit: 'day', count: 15 },
      { timeUnit: 'day', count: 20 },
      { timeUnit: 'week', count: 1 },
      { timeUnit: 'week', count: 5 },
      { timeUnit: 'week', count: 10 },
      { timeUnit: 'month', count: 1 },
      { timeUnit: 'month', count: 2 },
      { timeUnit: 'month', count: 3 },
      { timeUnit: 'month', count: 6 },
    ]);
    // create object of first value axis
    const valueAxis = chart.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.extraMax = 0.005;
    valueAxis.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: 'K' },
      { number: 1e6, suffix: 'M' },
      { number: 1e9, suffix: 'B' },
    ];
    valueAxis.numberFormatter.smallNumberPrefixes = [];
    valueAxis.min = 0;
    valueAxis.numberFormatter.numberFormat = `#.#a`;

    // Add cursor
    chart.current.cursor = new am4charts.XYCursor();
    chart.current.cursor.lineY.disabled = true;
    chart.current.cursor.lineX.disabled = true;
    chart.current.cursor.behavior = 'none';

    // function to generate complete tooltip html
    function generateToolTipHTML(tooltipValue, startDate, endDate) {
      let html = '';
      if (
        groupBy === 'weekly' ||
        groupBy === 'monthly' ||
        groupBy === 'quarterly'
      ) {
        const sameDate = compareTwoDates(startDate);
        if (sameDate) {
          html = `<div style="font-size: 14px; font-family: Noah-Medium; padding:5px 0">COMPLETION DATE: ${dayjs(
            completionDate,
          ).format('MM/DD/YYYY')} </div>
          <div style="font-size: 14px; font-family: Noah-Medium; padding:5px 0">
          From - ${startDate} : To - ${endDate}
        </div>
        ${tooltipValue}`;
        } else {
          html = `<div style="font-size: 14px; font-family: Noah-Medium; padding:5px 0">
          From - ${startDate} : To - ${endDate}
          </div>
          ${tooltipValue}`;
        }
      } else {
        html = tooltipValue;
      }

      return html;
    }

    function renderTooltip(name, color, value, currency, percent, formatter) {
      const tooltipText = ` <ul style="padding:0; margin: 0 0 4px 0; max-width: 250px;">
        <li style="display: inline-block;">
          {' '}
          <div style="background-color: ${color};
          border: 1px solid white;
          border-radius: ${name === 'Previous' ? '2px' : '100%'};
          width: 10px;
          height: 10px;" />
        </li>
        <li style="display: inline-block;">
          <div style="color: #f4f6fc;
          text-transform: uppercase;
          font-size: 11px;
          padding-left: 5px;">${name} </div>
        </li>
        <li style="display: inline-block; float: right; margin-left: 25px;">
          <div style=" color: white;
          font-size: 16px; text-align: right;
         
          ">${currency !== null ? currency : ''}${
        formatter !== null
          ? `{${value}.formatNumber('${formatter}')}`
          : `{${value}}`
      }${percent !== null ? percent : ''}
        </div>
        </li>
      </ul>
      `;
      return tooltipText;
    }

    function bindValueAxisFormatter(item) {
      let format = '';
      if (
        item === 'ordered_product_sales' ||
        item === 'ordered_revenue' ||
        item === 'shipped_cogs'
      ) {
        format = `${currencySymbol}#.#a`;
      } else if (
        item === 'buy_box_percentage' ||
        item === 'conversion' ||
        item === 'conversion_rate'
      ) {
        format = `#.#'%'`;
      } else {
        format = `#.#a`;
      }
      return format;
    }

    const selectedMatricsFlag = _.size(selectedMetrics) <= 2;

    if (selectedMatricsFlag) {
      // create object of 2nd value axis
      let valueAxis2;
      const snapToSeries = [];
      let tooltipValue = '';
      let firstAxis = '';

      // loop for genearate tooltip
      _.keys(selectedMetrics).map((item) => {
        const value = `${item}CurrentLabel`;
        // tooltipValue = `${tooltipValue} ${_.startCase(item)}`;
        if (
          item === 'ordered_product_sales' ||
          item === 'ordered_revenue' ||
          item === 'shipped_cogs'
        ) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            colorSet[item],
            value,
            currencySymbol,
            null,
            '#,###',
          )}`;
        } else if (
          item === 'buy_box_percentage' ||
          item === 'conversion' ||
          item === 'conversion_rate'
        ) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            colorSet[item],
            value,
            null,
            '%',
            null,
          )}`;
        } else {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            colorSet[item],
            value,
            null,
            null,
            '#,###',
          )}`;
        }
        return '';
      });

      // Loop for series bind (dotted line and line)
      _.keys(selectedMetrics).map((item, index) => {
        const series = chart.current.series.push(new am4charts.LineSeries());
        const currentValue = `${item}Current`;
        const seriesName = `${item}Series`;
        const colorCode = colorSet[item];

        if (index === 0) {
          series.yAxis = valueAxis;
          valueAxis.numberFormatter.numberFormat = bindValueAxisFormatter(item);

          if (
            item === 'units_ordered' ||
            item === 'page_views' ||
            item === 'units_sold' ||
            item === 'sessions' ||
            item === 'shipped_units' ||
            item === 'ordered_units' ||
            item === 'glaced_views'
          ) {
            firstAxis = 'unit';
          }
          if (
            item === 'buy_box_percentage' ||
            item === 'conversion' ||
            item === 'conversion_rate'
          ) {
            firstAxis = 'percentage';
          }
        }
        if (index === 1) {
          if (
            firstAxis === 'unit' &&
            (item === 'units_ordered' ||
              item === 'page_views' ||
              item === 'units_sold' ||
              item === 'sessions' ||
              item === 'shipped_units' ||
              item === 'ordered_units' ||
              item === 'glaced_views')
          ) {
            series.yAxis = valueAxis;
          } else if (
            firstAxis === 'percentage' &&
            (item === 'buy_box_percentage' ||
              item === 'conversion' ||
              item === 'conversion_rate')
          ) {
            series.yAxis = valueAxis;
          } else {
            valueAxis2 = chart.current.yAxes.push(new am4charts.ValueAxis());
            valueAxis2.renderer.grid.template.disabled = true;
            valueAxis2.renderer.baseGrid.disabled = true;
            valueAxis2.cursorTooltipEnabled = false;
            valueAxis2.numberFormatter = new am4core.NumberFormatter();
            valueAxis2.numberFormatter.numberFormat = `#.#a`;
            valueAxis2.extraMax = 0.005;
            valueAxis2.numberFormatter.bigNumberPrefixes = [
              { number: 1e3, suffix: 'K' },
              { number: 1e6, suffix: 'M' },
              { number: 1e9, suffix: 'B' },
            ];
            valueAxis2.numberFormatter.smallNumberPrefixes = [];
            valueAxis2.min = 0;
            series.yAxis = valueAxis2;
            valueAxis2.renderer.opposite = true;
            valueAxis2.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
          }
        }

        series.dataFields.valueY = currentValue;
        series.dataFields.dateX = 'date';
        series.name = seriesName;
        series.strokeWidth = 2;
        series.tooltipHTML = `${tooltipValue}`;
        series.stroke = am4core.color(colorCode);
        series.fill = am4core.color('#2e384d');
        series.adapter.add('tooltipHTML', (text, target) => {
          const dateRange = target.tooltipDataItem.dataContext;
          return generateToolTipHTML(
            tooltipValue,
            dateRange?.date,
            dateRange?.endDate,
          );
        });

        const circleBullet = series.bullets.push(new am4charts.CircleBullet());
        // circleBullet.circle.fill = am4core.color(colorCode);
        // Change the color of the particular node which haves the completion date
        if (chartData?.length > 1) {
          circleBullet.adapter.add('fill', (fill, target) => {
            const dateRange = target.tooltipDataItem.dataContext;
            if (compareTwoDates(dateRange?.date)) {
              return am4core.color(completionDateColor);
            }
            return am4core.color(colorCode);
          });
        } else {
          circleBullet.circle.fill = am4core.color(colorCode);
        }
        circleBullet.circle.strokeWidth = 1;
        circleBullet.circle.radius = 5;

        snapToSeries.push(series);
        return '';
      });

      chart.current.cursor.snapToSeries = snapToSeries;
    } else {
      // else part- for multiple metrics (More than 2) selected

      let firstAxis = null;
      let secondAxis = null;
      let thirdAxis = null;

      // create object of 2nd value axis
      const valueAxis2 = chart.current.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.renderer.grid.template.disabled = true;
      valueAxis2.renderer.baseGrid.disabled = true;
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.numberFormatter = new am4core.NumberFormatter();
      valueAxis2.numberFormatter.numberFormat = `#.#a`;
      valueAxis2.extraMax = 0.005;
      valueAxis2.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: 'K' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
      ];
      valueAxis2.min = 0;
      valueAxis2.numberFormatter.smallNumberPrefixes = [];

      const valueAxis3 = chart.current.yAxes.push(new am4charts.ValueAxis());
      valueAxis3.renderer.grid.template.disabled = true;
      valueAxis3.renderer.baseGrid.disabled = true;
      valueAxis3.cursorTooltipEnabled = false;
      valueAxis3.numberFormatter = new am4core.NumberFormatter();
      valueAxis3.numberFormatter.numberFormat = `#.#a`;
      valueAxis3.extraMax = 0.005;
      valueAxis3.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: 'K' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
      ];
      valueAxis3.numberFormatter.smallNumberPrefixes = [];
      valueAxis3.min = 0;

      // create object of 4th value axis
      const valueAxis4 = chart.current.yAxes.push(new am4charts.ValueAxis());
      valueAxis4.renderer.grid.template.disabled = true;
      valueAxis4.renderer.baseGrid.disabled = true;
      valueAxis4.cursorTooltipEnabled = false;
      valueAxis4.numberFormatter = new am4core.NumberFormatter();
      valueAxis4.numberFormatter.numberFormat = `#.#a`;
      valueAxis4.extraMax = 0.005;
      valueAxis4.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: 'K' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
      ];
      valueAxis4.numberFormatter.smallNumberPrefixes = [];
      valueAxis4.min = 0;

      const snapToSeries = [];
      let tooltipValue = '';

      // loop for genearate tooltip
      _.keys(selectedMetrics).map((item) => {
        const value = `${item}CurrentLabel`;

        if (
          item === 'ordered_product_sales' ||
          item === 'ordered_revenue' ||
          item === 'shipped_cogs'
        ) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            colorSet[item],
            value,
            currencySymbol,
            null,
            '#,###',
          )}`;
        } else if (
          item === 'buy_box_percentage' ||
          item === 'conversion' ||
          item === 'conversion_rate'
        ) {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            colorSet[item],
            value,
            null,
            '%',
            null,
          )}`;
        } else {
          tooltipValue = `${tooltipValue} ${renderTooltip(
            tooltipNames[item],
            colorSet[item],
            value,
            null,
            null,
            '#,###',
          )}`;
        }
        return '';
      });

      _.keys(selectedMetrics).map((item) => {
        const series = chart.current.series.push(new am4charts.LineSeries());
        if (
          item === 'units_ordered' ||
          item === 'page_views' ||
          item === 'units_sold' ||
          item === 'sessions' ||
          item === 'shipped_units' ||
          item === 'ordered_units' ||
          item === 'glaced_views'
        ) {
          if (firstAxis === null || firstAxis === 'unit') {
            series.yAxis = valueAxis;
            firstAxis = 'unit';
            valueAxis.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
          } else if (secondAxis === null || secondAxis === 'unit') {
            series.yAxis = valueAxis2;
            valueAxis2.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
            valueAxis2.renderer.opposite = true;
            secondAxis = 'unit';
          } else if (thirdAxis === null || thirdAxis === 'unit') {
            series.yAxis = valueAxis3;
            valueAxis3.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
            valueAxis3.renderer.opposite = true;

            valueAxis.renderer.line.strokeOpacity = 0;
            valueAxis2.renderer.line.strokeOpacity = 0;
            valueAxis3.renderer.line.strokeOpacity = 0;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis2.renderer.labels.template.disabled = true;
            valueAxis3.renderer.labels.template.disabled = true;
            thirdAxis = 'unit';
          } else {
            series.yAxis = valueAxis4;
            valueAxis4.renderer.opposite = true;
            valueAxis4.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
            valueAxis.renderer.line.strokeOpacity = 0;
            valueAxis2.renderer.line.strokeOpacity = 0;
            valueAxis3.renderer.line.strokeOpacity = 0;
            valueAxis4.renderer.line.strokeOpacity = 0;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis2.renderer.labels.template.disabled = true;
            valueAxis3.renderer.labels.template.disabled = true;
            valueAxis4.renderer.labels.template.disabled = true;
          }
        } else if (
          item === 'buy_box_percentage' ||
          item === 'conversion' ||
          item === 'conversion_rate'
        ) {
          if (firstAxis === null || firstAxis === 'percentage') {
            series.yAxis = valueAxis;
            firstAxis = 'percentage';
            valueAxis.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
          } else if (secondAxis === null || secondAxis === 'percentage') {
            series.yAxis = valueAxis2;
            valueAxis2.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
            valueAxis2.renderer.opposite = true;
            secondAxis = 'percentage';
          } else if (thirdAxis === null || thirdAxis === 'percentage') {
            series.yAxis = valueAxis3;
            valueAxis3.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
            valueAxis3.renderer.opposite = true;

            valueAxis.renderer.line.strokeOpacity = 0;
            valueAxis2.renderer.line.strokeOpacity = 0;
            valueAxis3.renderer.line.strokeOpacity = 0;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis2.renderer.labels.template.disabled = true;
            valueAxis3.renderer.labels.template.disabled = true;
            thirdAxis = 'percentage';
          } else {
            series.yAxis = valueAxis4;
            valueAxis4.renderer.opposite = true;
            valueAxis4.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
            valueAxis.renderer.line.strokeOpacity = 0;
            valueAxis2.renderer.line.strokeOpacity = 0;
            valueAxis3.renderer.line.strokeOpacity = 0;
            valueAxis4.renderer.line.strokeOpacity = 0;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis2.renderer.labels.template.disabled = true;
            valueAxis3.renderer.labels.template.disabled = true;
            valueAxis4.renderer.labels.template.disabled = true;
          }
        } else if (
          item === 'ordered_product_sales' ||
          item === 'ordered_revenue' ||
          item === 'shipped_cogs'
        ) {
          // for currency metrics
          if (firstAxis === null) {
            series.yAxis = valueAxis;
            firstAxis = 'currency';
            valueAxis.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
          } else if (secondAxis === null) {
            series.yAxis = valueAxis2;
            valueAxis2.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
            valueAxis2.renderer.opposite = true;
            secondAxis = 'currency';
          } else if (thirdAxis === null) {
            series.yAxis = valueAxis3;
            valueAxis3.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
            valueAxis3.renderer.opposite = true;

            valueAxis.renderer.line.strokeOpacity = 0;
            valueAxis2.renderer.line.strokeOpacity = 0;
            valueAxis3.renderer.line.strokeOpacity = 0;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis2.renderer.labels.template.disabled = true;
            valueAxis3.renderer.labels.template.disabled = true;
            thirdAxis = 'currency';
          } else {
            series.yAxis = valueAxis4;
            valueAxis4.renderer.opposite = true;
            valueAxis4.numberFormatter.numberFormat =
              bindValueAxisFormatter(item);
            valueAxis.renderer.line.strokeOpacity = 0;
            valueAxis2.renderer.line.strokeOpacity = 0;
            valueAxis3.renderer.line.strokeOpacity = 0;
            valueAxis4.renderer.line.strokeOpacity = 0;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis2.renderer.labels.template.disabled = true;
            valueAxis3.renderer.labels.template.disabled = true;
            valueAxis4.renderer.labels.template.disabled = true;
          }
        }

        const currentValue = `${item}Current`;
        const seriesName = `${item}Series`;

        series.dataFields.valueY = currentValue;
        series.dataFields.dateX = 'date';
        series.name = seriesName;
        series.strokeWidth = 2;
        series.tooltipHTML = `${tooltipValue}`;
        series.stroke = am4core.color(colorSet[item]);
        series.fill = am4core.color('#2e384d');
        series.adapter.add('tooltipHTML', (text, target) => {
          const dateRange = target.tooltipDataItem.dataContext;
          return generateToolTipHTML(
            tooltipValue,
            dateRange?.date,
            dateRange?.endDate,
          );
        });

        const circleBullet = series.bullets.push(new am4charts.CircleBullet());
        // circleBullet.circle.fill = am4core.color(colorSet[item]);
        // Change the color of the particular node which haves the completion date
        if (chartData?.length > 1) {
          circleBullet.adapter.add('fill', (fill, target) => {
            const dateRange = target.tooltipDataItem.dataContext;
            if (compareTwoDates(dateRange?.date)) {
              return am4core.color(completionDateColor);
            }
            return am4core.color(colorSet[item]);
          });
        } else {
          circleBullet.circle.fill = am4core.color(colorSet[item]);
        }
        circleBullet.circle.strokeWidth = 1;
        circleBullet.circle.radius = 5;

        snapToSeries.push(series);
        return '';
      });

      chart.current.cursor.snapToSeries = snapToSeries;
    }
    return () => chart.current && chart.current.dispose();
  }, [
    chartId,
    chartData,
    currencySymbol,
    selectedMetrics,
    groupBy,
    completionDate,
    accountType,
    selectedVendorReporting,
  ]);

  return (
    <>
      <div id={chartId} style={{ width: '100%', height: '500px' }} />
    </>
  );
}

CreativeProjectChart.defaultProps = {
  chartId: '',
  chartData: {},
  currencySymbol: '',
  selectedMetrics: {},
  groupBy: '',
  completionDate: '',
  accountType: 'seller',
  selectedVendorReporting: 'ordered_revenue',
};

CreativeProjectChart.propTypes = {
  chartId: string,
  chartData: shape({}),
  currencySymbol: string,
  selectedMetrics: {},
  groupBy: string,
  completionDate: string,
  accountType: string,
  selectedVendorReporting: string,
};
