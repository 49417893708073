import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

import { arrayOf, bool, func, shape, string } from 'prop-types';

import DspAsinAndCampaignFilters from './DspAsinAndCampaignFilters';
import DspAsinPerformanceTable from './DspAsinPerformanceTable';
import DspCampaignPerformanceTable from './DspCampaignPerformanceTable';
import {
  getDspCampaignList,
  getDspAdAsinPerformanceData,
  getDspCampaignPerformanceData,
  getDspCampaignDownloadReport,
} from '../../../../../../api';
import {
  ASIN,
  CUSTOM,
  asinStatiMessages,
  campaignSortingOptions,
  dspCampaignAsinToggleOptions,
} from '../../../../../../constants';

function DspAsinAndCampaignContainer({
  customerId,
  childBrandId,
  selectedDF,
  selectedMarketplace,
  adState,
  currencySymbol,
  isCallDSPAsinApi,
  setIsCallDSPAsinApi,
}) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isCustom = selectedDF === 'custom';
  const [orderBy, setOrderBy] = useState('total_product_sales');
  const [orderByKey, setOrderByKey] = useState('-total_product_sales');
  const [asinPageCount, setAsinPageCount] = useState(1);
  const [asinPageNumber, setAsinPageNumber] = useState(1);
  const [dspAsinData, setDspAsinData] = useState([]);
  const [asinPerformanceLoader, setAsinPerformanceLoader] = useState(false);
  const [campaignCollapseLoader, setCampaignCollapseLoader] = useState(false);
  const [campaignAsinLoader, setCampaignAsinLoader] = useState(false);
  const [downloadReportLoader, setDownloadReportLoader] = useState(false);
  const [campaignListData, setCampaignListData] = useState([]);
  const [campaignAsinData, setCampaignAsinData] = useState({
    asinData: [],
    asinTotal: {},
  });

  const [dspCampaignSearchQuery, setDspCampaignSearchQuery] = useState('');
  const [campaignPageCount, setCampaignPageCount] = useState(1);
  const [campaignPageNumber, setCampaignPageNumber] = useState(1);
  const [campaignAsinPageCount, setCampaignAsinPageCount] = useState(1);
  const [campaignAsinPageNumber, setCampaignAsinPageNumber] = useState(1);
  const [selectedSortByOrder, setSelectedSortByOrder] = useState(
    campaignSortingOptions?.[0],
  );
  const [selectedCampaignAsinToggle, setSelectedCampaignAsinToggle] = useState(
    dspCampaignAsinToggleOptions[0]?.value,
  );
  const [asinMessage, setAsinMessage] = useState(
    asinStatiMessages?.asinDataAvailabilityMessage,
  );

  // PDV-8401 - Logic - point no. 1 - One October 2023 logic
  const todaysDate = dayjs(new Date()).format('YYYY-MM-DD');
  const oneOctober2023 = dayjs(new Date(2023, 9, 1)).format('YYYY-MM-DD');

  // PDV-8422 - Logic - when user select custom end date 4 months before today
  const fourMonthBeforeToday = useMemo(() => {
    const today = new Date();
    today.setMonth(today.getMonth() - 4);
    return today;
  }, []);

  const showAsinTooltipMessage = adState?.[0]?.startDate < fourMonthBeforeToday;

  // bind dsp ad asin performance data
  const bindDspAsinPerformanceData = useCallback((responseData) => {
    const tempAsinData = [];

    if (responseData?.length > 0) {
      responseData.forEach((item) => {
        tempAsinData.push({
          asin: item?.asin,
          productName: item?.product_name || 'Not available',
          pdpLink: item?.pdp_link,

          totalProductSalesCurrent: item?.current?.total_product_sales,
          ttlNewBrandPurchasesCurrent: item?.current?.ttl_new_brand_purchases,
          totalDetailPageViewsCurrent: item?.current?.total_detail_page_views,
          totalAddToCartCurrent: item?.current?.total_add_to_cart,

          totalProductSalesChangeInValue: item?.change?.total_product_sales,
          ttlNewBrandPurchasesChangeInValue:
            item?.change?.ttl_new_brand_purchases,
          totalDetailPageViewsChangeInValue:
            item?.change?.total_detail_page_views,
          totalAddToCartChangeInValue: item?.change?.total_add_to_cart,

          totalProductSalesChangeInPercent:
            item?.per_change?.total_product_sales,
          ttlNewBrandPurchasesChangeInPercent:
            item?.per_change?.ttl_new_brand_purchases,
          totalDetailPageViewsChangeInPercent:
            item?.per_change?.total_detail_page_views,
          totalAddToCartChangeInPercent: item?.per_change?.total_add_to_cart,
        });
        return tempAsinData;
      });
    }
    return tempAsinData;
  }, []);

  // bind DSP campaign asin total response data
  const bindDspCampaignAsinTotal = useCallback((responseData) => {
    let tempCampaignAsinTotal = {};
    if (responseData !== null) {
      tempCampaignAsinTotal = {
        asin: 'Total',
        totalProductSalesCurrent: responseData?.current?.total_product_sales,
        ttlNewBrandPurchasesCurrent:
          responseData?.current?.ttl_new_brand_purchases,
        totalDetailPageViewsCurrent:
          responseData?.current?.total_detail_page_views,
        totalAddToCartCurrent: responseData?.current?.total_add_to_cart,

        totalProductSalesChangeInValue:
          responseData?.change?.total_product_sales,
        ttlNewBrandPurchasesChangeInValue:
          responseData?.change?.ttl_new_brand_purchases,
        totalDetailPageViewsChangeInValue:
          responseData?.change?.total_detail_page_views,
        totalAddToCartChangeInValue: responseData?.change?.total_add_to_cart,

        totalProductSalesChangeInPercent:
          responseData?.per_change?.total_product_sales,
        ttlNewBrandPurchasesChangeInPercent:
          responseData?.per_change?.ttl_new_brand_purchases,
        totalDetailPageViewsChangeInPercent:
          responseData?.per_change?.total_detail_page_views,
        totalAddToCartChangeInPercent:
          responseData?.per_change?.total_add_to_cart,
      };
    }

    return tempCampaignAsinTotal;
  }, []);

  // bind  Campaign list data
  const bindCampaignListData = useCallback((responseData) => {
    const tempCampaignData = [];

    if (responseData?.length > 0) {
      responseData.forEach((item, index) => {
        tempCampaignData.push({
          id: item?.order_id,
          marketpalceLabel: item?.country,
          marketpalceValue: item?.marketplace_name,
          campaign: item?.order_name,
          numberOfAsins: item?.asin_count,
          isOpen: index === 0,
        });
        return tempCampaignData;
      });
    }

    return tempCampaignData;
  }, []);

  // callback function for get dsp ad asin performance data
  const getAsinPerformanceData = useCallback(
    (orderByApiKey, page, startDate, endDate) => {
      setAsinPerformanceLoader(true);
      setDspAsinData([]);

      const startDateLocal = dayjs(startDate).format('DD-MM-YYYY');
      const endDateLocal = dayjs(endDate).format('DD-MM-YYYY');

      if (endDate <= fourMonthBeforeToday) {
        setDspAsinData([]);
        setAsinMessage(asinStatiMessages?.asinHistoricalDataMessage);
        setAsinPerformanceLoader(false);
      } else {
        getDspAdAsinPerformanceData(
          customerId,
          selectedMarketplace,
          orderByApiKey,
          page,
          selectedDF,
          startDateLocal,
          endDateLocal,
          childBrandId,
        ).then((response) => {
          if (response?.status === 200 && response?.data?.results?.length > 0) {
            setAsinPageCount(response?.data?.count);
            setDspAsinData(bindDspAsinPerformanceData(response?.data?.results));
          } else {
            setDspAsinData([]);
            setAsinPageCount(1);
            setAsinMessage(asinStatiMessages?.asinDataAvailabilityMessage);
          }

          setAsinPerformanceLoader(false);
        });
      }
    },

    [
      bindDspAsinPerformanceData,
      childBrandId,
      customerId,
      fourMonthBeforeToday,
      selectedDF,
      selectedMarketplace,
    ],
  );

  // callback function for get dsp campaign- asin performance data - (campaign details)
  const getCampaignAsinPerformanceData = useCallback(
    (orderByApiKey, page, startDate, endDate, campaignOrderId) => {
      setCampaignAsinLoader(true);
      setCampaignAsinData({
        asinData: [],
        asinTotal: {},
      });
      const startDateLocal = dayjs(startDate).format('DD-MM-YYYY');
      const endDateLocal = dayjs(endDate).format('DD-MM-YYYY');

      getDspCampaignPerformanceData(
        customerId,
        selectedMarketplace,
        orderByApiKey,
        page,
        selectedDF,
        startDateLocal,
        endDateLocal,
        childBrandId,
        null,
        campaignOrderId,
      ).then((response) => {
        if (response?.status === 200) {
          setCampaignAsinPageCount(response?.data?.count);

          setCampaignAsinData({
            asinData: bindDspAsinPerformanceData(
              response?.data?.results?.asin_data,
            ),
            asinTotal: bindDspCampaignAsinTotal(
              response?.data?.results?.asin_total,
            ),
          });
        } else {
          setCampaignAsinData({
            asinData: [],
            asinTotal: {},
          });
          setCampaignAsinPageCount(1);
          setAsinMessage(asinStatiMessages?.asinDataAvailabilityMessage);
        }

        setCampaignAsinLoader(false);
      });
    },

    [
      bindDspAsinPerformanceData,
      bindDspCampaignAsinTotal,
      childBrandId,
      customerId,
      selectedDF,
      selectedMarketplace,
    ],
  );

  // callback function for get campaign data
  const getCampaignListData = useCallback(
    (orderByApiKey, page, startDate, endDate) => {
      setCampaignCollapseLoader(true);
      setCampaignListData([]);

      const startDateLocal = dayjs(startDate).format('DD-MM-YYYY');
      const endDateLocal = dayjs(endDate).format('DD-MM-YYYY');
      if (endDate <= fourMonthBeforeToday) {
        setDspAsinData([]);
        setAsinMessage(asinStatiMessages?.asinHistoricalDataMessage);
        setCampaignCollapseLoader(false);
      } else {
        getDspCampaignList(
          customerId,
          selectedMarketplace,
          orderByApiKey,
          page,
          selectedDF,
          dspCampaignSearchQuery,
          childBrandId,
          startDateLocal,
          endDateLocal,
        ).then((response) => {
          if (response?.status === 200) {
            setCampaignPageCount(response?.data?.count);
            const localCampaignData = bindCampaignListData(
              response?.data?.results,
            );
            setCampaignListData(localCampaignData);
            if (localCampaignData?.length) {
              getCampaignAsinPerformanceData(
                orderByApiKey,
                1,
                startDate,
                endDate,
                localCampaignData?.[0]?.id,
              );
            } else {
              setAsinMessage(
                asinStatiMessages?.campaignDataAvailabilityMessage,
              );
            }
          } else {
            setCampaignPageCount(1);
            setCampaignListData([]);
            setCampaignAsinData({
              asinData: [],
              asinTotal: {},
            });
            setAsinMessage(asinStatiMessages?.campaignDataAvailabilityMessage);
          }
          setCampaignCollapseLoader(false);
        });
      }
    },

    [
      bindCampaignListData,
      childBrandId,
      customerId,
      dspCampaignSearchQuery,
      fourMonthBeforeToday,
      getCampaignAsinPerformanceData,
      selectedDF,
      selectedMarketplace,
    ],
  );

  // use effect
  useEffect(() => {
    if (!isCallDSPAsinApi && selectedDF !== CUSTOM) {
      if (selectedCampaignAsinToggle === ASIN) {
        getAsinPerformanceData(
          '-total_product_sales',
          1,
          adState?.[0]?.startDate,
          adState?.[0]?.endDate,
        );
        setAsinPageNumber(1);
      } else {
        getCampaignListData(
          '-total_product_sales',
          1,
          adState?.[0]?.startDate,
          adState?.[0]?.endDate,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getAsinPerformanceData,
    getCampaignListData,
    isCallDSPAsinApi,
    selectedDF,
  ]);

  // use effect when apply custom date filter
  useEffect(() => {
    if (isCallDSPAsinApi) {
      if (selectedCampaignAsinToggle === ASIN) {
        getAsinPerformanceData(
          orderByKey,
          1,
          adState?.[0]?.startDate,
          adState?.[0]?.endDate,
        );
        setAsinPageNumber(1);
        setIsCallDSPAsinApi(false);
      } else {
        getCampaignListData(
          orderByKey,
          1,
          adState?.[0]?.startDate,
          adState?.[0]?.endDate,
        );
        setIsCallDSPAsinApi(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAsinPerformanceData, getCampaignListData, isCallDSPAsinApi]);

  // handle download report events
  const handleDownloadReportEvents = () => {
    setDownloadReportLoader(true);
    const startDate = dayjs(adState?.[0]?.startDate).format('DD-MM-YYYY');
    const endDate = dayjs(adState?.[0]?.endDate).format('DD-MM-YYYY');

    if (selectedCampaignAsinToggle === ASIN) {
      getDspAdAsinPerformanceData(
        customerId,
        selectedMarketplace,
        orderByKey,
        asinPageNumber,
        selectedDF,
        startDate,
        endDate,
        childBrandId,
        true,
      ).then((response) => {
        if (response?.status === 200) {
          const csvLink = response?.data?.url;
          if (csvLink) window.open(csvLink);
          if (response?.data?.message) toast.success(response?.data?.message);
          setDownloadReportLoader(false);
        } else {
          toast.error('Oops! Something went wrong...');
          setDownloadReportLoader(false);
        }
      });
    } else {
      getDspCampaignDownloadReport(
        customerId,
        selectedMarketplace,
        selectedDF,
        orderByKey,
        dspCampaignSearchQuery,
        startDate,
        endDate,
        childBrandId,
      ).then((response) => {
        if (response?.status === 200) {
          const csvLink = response?.data?.url;
          if (csvLink) window.open(csvLink);
          if (response?.data?.message) toast.success(response?.data?.message);
          setDownloadReportLoader(false);
        } else {
          toast.error('Oops! Something went wrong...');
          setDownloadReportLoader(false);
        }
      });
    }
  };

  // handle campaign list collapse
  const handleCollapseClick = (campaignOrderId) => {
    getCampaignAsinPerformanceData(
      orderByKey,
      1,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
      campaignOrderId,
    );
    setCampaignAsinPageNumber(1);
  };

  // handle sorting(order by- column header) events of asin table
  const handleSortingEvents = (key) => {
    let orderByApiKey = key;
    setOrderBy(key);
    if (orderByKey === `-${key}`) {
      setOrderByKey(key);
      orderByApiKey = key;
    } else {
      setOrderByKey(`-${key}`);
      orderByApiKey = `-${key}`;
    }
    if (selectedCampaignAsinToggle === ASIN) {
      getAsinPerformanceData(
        orderByApiKey,
        asinPageNumber,
        adState?.[0]?.startDate,
        adState?.[0]?.endDate,
      );
    } else {
      getCampaignListData(
        orderByApiKey,
        asinPageNumber,
        adState?.[0]?.startDate,
        adState?.[0]?.endDate,
      );
    }
  };

  // handle Asin toggle pagination events
  const handleAsinPaginationEvents = (currentPage) => {
    setAsinPageNumber(currentPage);
    getAsinPerformanceData(
      orderByKey,
      currentPage,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
    );
  };

  // handle Campaign toggle pagination events
  const handleCampaignPaginationEvents = (currentPage) => {
    setCampaignPageNumber(currentPage);
    getCampaignListData(
      orderByKey,
      currentPage,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
    );
  };

  // handle Campaign-Asin toggle pagination events
  const handleCampaignAsinPaginationEvents = (currentPage, campaignDetails) => {
    setCampaignAsinPageNumber(currentPage);
    getCampaignAsinPerformanceData(
      orderByKey,
      currentPage,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
      campaignDetails?.id,
    );
  };

  // handle sort by filter (enabled, paused, archived)
  const handleSortByFilter = (event) => {
    setSelectedSortByOrder(event);
    getCampaignListData(
      orderByKey,
      asinPageNumber,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
    );
  };

  // handlesort campaign and asin toogle options
  const handleCampaignAsinToogle = (type) => {
    setSelectedCampaignAsinToggle(type);
    if (type === ASIN) {
      setSelectedSortByOrder(campaignSortingOptions?.[0]);
      setDspCampaignSearchQuery('');
      getAsinPerformanceData(
        orderByKey,
        asinPageNumber,
        adState?.[0]?.startDate,
        adState?.[0]?.endDate,
      );
    } else {
      getCampaignListData(
        orderByKey,
        asinPageNumber,
        adState?.[0]?.startDate,
        adState?.[0]?.endDate,
      );
    }
  };

  const handleCampaignSearch = (event) => {
    setDspCampaignSearchQuery(event?.target?.value);
    getCampaignListData(
      orderByKey,
      asinPageNumber,
      adState?.[0]?.startDate,
      adState?.[0]?.endDate,
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      <DspAsinAndCampaignFilters
        showAsinTooltipMessage={showAsinTooltipMessage}
        dspAsinData={
          selectedCampaignAsinToggle === ASIN ? dspAsinData : campaignListData
        }
        asinPerformanceLoader={
          selectedCampaignAsinToggle === ASIN
            ? asinPerformanceLoader
            : campaignCollapseLoader || campaignAsinLoader
        }
        handleDownloadReportEvents={handleDownloadReportEvents}
        downloadReportLoader={downloadReportLoader}
        todaysDate={todaysDate}
        oneOctober2023={oneOctober2023}
        handleSortByFilter={handleSortByFilter}
        handleCampaignAsinToogle={handleCampaignAsinToogle}
        isCallDSPAsinApi={isCallDSPAsinApi}
        isTablet={isTablet}
        selectedCampaignAsinToggle={selectedCampaignAsinToggle}
        selectedSortByOrder={selectedSortByOrder}
        dspCampaignSearchQuery={dspCampaignSearchQuery}
        setDspCampaignSearchQuery={setDspCampaignSearchQuery}
        handleCampaignSearch={handleCampaignSearch}
      />

      {selectedCampaignAsinToggle === ASIN ? (
        <DspAsinPerformanceTable
          isCustom={isCustom}
          asinPerformanceLoader={asinPerformanceLoader}
          dspAsinData={dspAsinData}
          isTablet={isTablet}
          asinPageCount={asinPageCount}
          asinPageNumber={asinPageNumber}
          handleAsinPaginationEvents={handleAsinPaginationEvents}
          asinMessage={asinMessage}
          handleSortingEvents={handleSortingEvents}
          currencySymbol={currencySymbol}
          orderBy={orderBy}
          orderByKey={orderByKey}
        />
      ) : (
        <DspCampaignPerformanceTable
          isCustom={isCustom}
          campaignAsinLoader={campaignAsinLoader}
          campaignCollapseLoader={campaignCollapseLoader}
          isTablet={isTablet}
          asinMessage={asinMessage}
          handleSortingEvents={handleSortingEvents}
          currencySymbol={currencySymbol}
          orderBy={orderBy}
          orderByKey={orderByKey}
          campaignListData={campaignListData}
          campaignAsinData={campaignAsinData}
          setCampaignListData={setCampaignListData}
          campaignPageCount={campaignPageCount}
          campaignPageNumber={campaignPageNumber}
          campaignAsinPageCount={campaignAsinPageCount}
          campaignAsinPageNumber={campaignAsinPageNumber}
          handleCampaignPaginationEvents={handleCampaignPaginationEvents}
          handleCampaignAsinPaginationEvents={
            handleCampaignAsinPaginationEvents
          }
          handleCollapseClick={handleCollapseClick}
        />
      )}
    </div>
  );
}

export default DspAsinAndCampaignContainer;

DspAsinAndCampaignContainer.defaultProps = {
  isCallDSPAsinApi: false,
  childBrandId: null,
  selectedDF: '',
  currencySymbol: '',
  selectedMarketplace: 'all',
  adState: [],
  setIsCallDSPAsinApi: () => {},
};
DspAsinAndCampaignContainer.propTypes = {
  isCallDSPAsinApi: bool,
  childBrandId: string,
  selectedDF: string,
  selectedMarketplace: string,
  currencySymbol: string,
  customerId: string.isRequired,
  adState: arrayOf(shape()),
  setIsCallDSPAsinApi: func,
};
