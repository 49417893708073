import React, { useEffect, useState, useCallback, useRef } from 'react';

import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';

import { components } from 'react-select';
import { arrayOf, bool, func, instanceOf, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../../../../../../theme/Theme';
import BindVendorSalesGraphData from './BindVendorSalesGraphData';
import BindVendorSalesGoalsGraphData from './BindVendorSalesGoalsGraphData';
import VendorSalesPerformancePanel from './VendorSalesPerformancePanel';
import VendorSalesPerformanceFilters from './VendorSalesPerformanceFilters';
import { getVendorReportingData } from '../../../../../../api';
import { CustomDateModal, DropdownIndicator } from '../../../../../../common';
import {
  CUSTOM,
  ORDEREDREVENUE,
  SALEPERFORMANCE,
  SHIPPEDCOGS,
  VENDORSMALL,
  dateOptionsWithYear,
} from '../../../../../../constants';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const getSymbolFromCurrency = require('currency-symbol-map');

export default function VendorSalesPerformanceContainer({
  id,
  dspPpcFlag,
  selectedSalesDF,
  setSelectedSalesDF,
  selectedState,
  setSelectedState,
  currentDate,
  selectedMarketplace,
  setSelectedMarketplace,
  marketplaceOptions,
  goalsMarketplaceIds,
  goalsMarketplaceOptions,
  setCurrency,
  currencySymbol,
  setCurrencySymbol,
  salesMinDate,
  customDateError,
  bindErrorMessage,
  minDateLoader,
  childBrandOptions,
  selectedChildBrand,
  setSelectedChildBrand,
  displayAccountTypeFilter,
  renderDataAvailableChildList,
  getDataAvailabeleChildBrandList,
  createMarektplaceIdsListOfGoals,
}) {
  // PDV-8274 - FE - Disable "Ordered Revenue" from single BP view for Riviana and Comfort Products
  // Comfort Products --> CMwIJql
  // Riviana Foods --> ’CMQ44sG’
  // Riviana Foods child brands --> 'CMm4jj8', 'CMrQttI', 'CMQJEW3', 'CMasKLQ', 'CMtCWXm', 'CMPNrYy',
  // Riviana Parent Company --> 'CMS0uTu' , PDV-8354 -Disable ordered rev and show shipped cogs by default message for Riviana Parent profile
  const BPsListToHideOrderedRevenue = [
    'CMwIJql',
    'CMQ44sG',
    'CMm4jj8',
    'CMrQttI',
    'CMQJEW3',
    'CMasKLQ',
    'CMtCWXm',
    'CMPNrYy',
    'CMS0uTu',
  ];
  const hideOrderedRevenue = BPsListToHideOrderedRevenue?.some(
    (item) => item === id,
  );

  const mounted = useRef(false);
  const { Option, SingleValue } = components;
  const [isSPCustomDateApply, setIsSPCustomDateApply] = useState(
    selectedSalesDF.value === CUSTOM,
  );
  const [salesChartData, setSalesChartData] = useState([]);
  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [groupBy, setGroupBy] = useState('daily');
  const [salesCurrentTotal, setSalesCurrentTotal] = useState({});
  const [salesPreviousTotal, setSalesPreviousTotal] = useState({});
  const [salesDifference, setSalesDifference] = useState({});
  const [goalsMetricData, setGoalsMetricData] = useState({});
  const [salesGoalChartData, setSalesGoalChartData] = useState([]);
  const [goalsToggle, setGoalsToggle] = useState(false);
  const [salesGraphLoader, setSalesGraphLoader] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [activeSales, setActiveSales] = useState(
    hideOrderedRevenue ? { shippedCOGs: true } : { orderedRevenue: true },
  );
  const [isAsinApiCallCustom, setIsAsinApiCallCustom] = useState(0);
  const [selectedVendorMetricsType, setSelectedVendorMetricsType] = useState(
    hideOrderedRevenue
      ? { value: SHIPPEDCOGS, label: 'Shipped COGs' }
      : {
          value: ORDEREDREVENUE,
          label: 'Ordered Revenue',
        },
  );

  const [customDate, setCustomDate] = useState(selectedState);

  // if we changed the T-3 logic - applay that change in useEffect of ASINPerformance.js file.
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() - 3); // PDV-8793 - Update Vendor Sales data to T-3

  // get vendor sales metrics and graph data
  const getData = useCallback(
    (
      selectedDailyFact,
      selectedGroupBy,
      marketplace,
      goalMarketplaces,
      metricsType,
      childBrand,
      startDate = null,
      endDate = null,
      onGroupByClick = false,
    ) => {
      setIsApiCall(true);
      setSalesGraphLoader(true);
      getDataAvailabeleChildBrandList(
        id,
        metricsType,
        VENDORSMALL,
        marketplace?.value,
        null,
        selectedDailyFact,
        startDate,
        endDate,
      );
      getVendorReportingData(
        id,
        selectedDailyFact,
        selectedGroupBy,
        marketplace,
        goalMarketplaces,
        childBrand,
        startDate,
        endDate,
        metricsType,
      ).then((res) => {
        if (mounted.current) {
          if (res?.status === 400 || res?.status === 500) {
            setIsApiCall(false);
            setSalesGraphLoader(false);
          }
          if (res?.status === 200) {
            if (res?.data?.result) {
              const salesGraphData = BindVendorSalesGraphData(
                res?.data,
                metricsType,
              );
              setSalesChartData(salesGraphData);
              // filterout the dsp current total, previous total, and diffrence
              const responseData = res?.data;
              setSalesCurrentTotal(responseData?.result?.current_sum || {});
              setSalesPreviousTotal(responseData?.result?.previous_sum || {});
              setSalesDifference(responseData?.result?.difference_data || {});

              // get goals line graph data
              if (selectedDailyFact === CUSTOM) {
                const salesGoalsGraphData = BindVendorSalesGoalsGraphData(
                  res?.data,
                  metricsType,
                );
                setSalesGoalChartData(salesGoalsGraphData);
              }
              // get goals matrics data
              if (!onGroupByClick) {
                const goalMetric = res?.data?.result?.goal_metric;
                const goalMetricLength = Object.keys(goalMetric)?.length;

                if (goalMetricLength > 0) {
                  const tempGoalMetricsData = {
                    ...goalMetric,
                    orderedRevenue: goalMetric?.ordered_revenue,
                    shippedCOGs: goalMetric?.shipped_cogs,
                    glanceViews: goalMetric?.glance_views,
                    conversionRate: goalMetric?.conversion_rate,
                  };

                  setGoalsMetricData(tempGoalMetricsData);
                } else {
                  setGoalsMetricData({});
                }
              } else {
                setGoalsMetricData({});
              }
            } else {
              setGoalsMetricData({});
              setSalesChartData([]);
              setSalesGoalChartData([]);
            }
            setIsApiCall(false);
            setSalesGraphLoader(false);
          }
        }
      });
    },
    [getDataAvailabeleChildBrandList, id],
  );

  // function call only when selected option is custom
  const checkDifferenceBetweenDates = useCallback(
    (
      startDate,
      endDate,
      flag = null,
      marketplace = selectedMarketplace,
      metricsType = selectedVendorMetricsType.value,
    ) => {
      const temp = 'daily';
      let sd = startDate;
      let ed = endDate;
      setGroupBy(temp);
      sd = `${startDate.getDate()}-${
        startDate.getMonth() + 1
      }-${startDate.getFullYear()}`;
      ed = `${endDate.getDate()}-${
        endDate.getMonth() + 1
      }-${endDate.getFullYear()}`;

      setCustomDate([{ startDate, endDate }]);
      getData(
        flag,
        temp,
        marketplace,
        goalsMarketplaceIds,
        metricsType,
        selectedChildBrand,
        sd,
        ed,
        false,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [goalsMarketplaceIds],
  );

  useEffect(() => {
    mounted.current = true;

    if (mounted.current && selectedMarketplace?.value) {
      // PDV-9198 - seller have T-2 logic and Vendor have T-3 logic
      // so if T-2 date is selected for seller and switch to vendor then its should be T-3.
      // PDV-9198 - START //
      const localCustomDate = [];

      // set the start date
      localCustomDate.push(
        selectedState[0]?.startDate > maxDate
          ? maxDate
          : selectedState[0]?.startDate,
      );

      // set the end date
      localCustomDate.push(
        selectedState[0]?.endDate > maxDate
          ? maxDate
          : selectedState[0]?.endDate,
      );

      setSelectedState([
        {
          startDate: localCustomDate[0],
          endDate: localCustomDate[1],
          key: 'selection',
        },
      ]);
      setCustomDate([
        {
          startDate: localCustomDate[0],
          endDate: localCustomDate[1],
          key: 'selection',
        },
      ]);
      // PDV-9198 - END //

      if (selectedSalesDF.value === CUSTOM) {
        setIsSPCustomDateApply(true);
        checkDifferenceBetweenDates(
          localCustomDate[0],
          localCustomDate[1],
          CUSTOM,
          selectedMarketplace,
        );
      } else {
        getData(
          selectedSalesDF.value,
          groupBy,
          selectedMarketplace,
          goalsMarketplaceIds,
          selectedVendorMetricsType?.value,
          selectedChildBrand,
          null,
          null,
          false,
        );
      }
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedMarketplace,
    selectedChildBrand,
    goalsMarketplaceIds,
    checkDifferenceBetweenDates,
    getData,
  ]);

  const renderCustomDateSubLabel = (props, flag) => {
    if (flag === 'sp') {
      if (selectedSalesDF.value === CUSTOM && isSPCustomDateApply) {
        return `From- ${dayjs(selectedState[0].startDate).format(
          'MMM D, YYYY',
        )}  To- ${dayjs(selectedState[0].endDate).format('MMM D, YYYY')}`;
      }
    }

    return props.data.sub;
  };

  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props.data.label}</span>

      <div style={{ fontSize: '10px' }}>
        {renderCustomDateSubLabel(props, 'sp')}
      </div>
    </SingleValue>
  );

  const filterOption = (props) => (
    <Option {...props}>
      <div>
        <span style={{ fontSize: '14px' }}>
          {props.data.label}
          {props?.isDisabled === true ? (
            <>
              <FontAwesomeIcon
                icon=" fal fa-info-circle"
                color={Theme.orange}
                fontSize="13px"
                style={{ marginLeft: '20px' }}
                data-tip="Selected metrics has no data for this period."
                data-for="adSalesTotalInfo"
              />
              <ReactTooltip
                class="initialTextTransform"
                id="adSalesTotalInfo"
                aria-haspopup="true"
                place="right"
                effect="solid"
              />
            </>
          ) : null}
        </span>

        <div style={{ fontSize: '10px' }}>{props.data.sub}</div>
      </div>
    </Option>
  );

  const getSelectComponents = () => {
    return {
      Option: filterOption,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  const handleDailyFact = (event) => {
    const { value } = event;

    if (value !== CUSTOM) {
      setGoalsToggle(false);
      let groupByOption = 'daily';
      if (value === 'year') {
        groupByOption = 'weekly';
      }
      setSelectedSalesDF(event);
      setIsSPCustomDateApply(false);
      setSelectedState([
        {
          startDate: maxDate,
          endDate: maxDate,
          key: 'selection',
        },
      ]);
      setGroupBy(groupByOption);
      bindErrorMessage(SALEPERFORMANCE, salesMinDate);
      getData(
        value,
        groupByOption,
        selectedMarketplace,
        goalsMarketplaceIds,
        selectedVendorMetricsType.value,
        selectedChildBrand,
        null,
        null,
        false,
      );
      setIsAsinApiCallCustom(0);
    } else if (value === CUSTOM) {
      setShowCustomDateModal(true);
    }
  };

  const handleMetricsType = (event) => {
    const { value } = event;

    if (value === ORDEREDREVENUE) {
      setActiveSales({ orderedRevenue: true });
      setSelectedVendorMetricsType({
        value: ORDEREDREVENUE,
        label: 'Ordered Revenue',
      });
    } else {
      setActiveSales({ shippedCOGs: true });
      setSelectedVendorMetricsType({
        value: SHIPPEDCOGS,
        label: 'Shipped COGs',
      });
    }

    if (selectedSalesDF.value === CUSTOM) {
      checkDifferenceBetweenDates(
        selectedState[0].startDate,
        selectedState[0].endDate,
        CUSTOM,
        selectedMarketplace,
        value,
      );
    } else {
      getData(
        selectedSalesDF.value,
        groupBy,
        selectedMarketplace,
        goalsMarketplaceIds,
        value,
        selectedChildBrand,
        null,
        null,
        false,
      );
    }
  };

  const handleMarketplaceOptions = (event) => {
    if (event.value !== selectedMarketplace?.value) {
      setSelectedMarketplace(event);
      createMarektplaceIdsListOfGoals(event, goalsMarketplaceOptions);
      setCurrency(event.currency);
      setCurrencySymbol(getSymbolFromCurrency(event.currency));
    }
  };

  const handleChildBrandOptions = (event) => {
    if (event.value !== selectedChildBrand?.value) {
      setSelectedChildBrand(event);
    }
  };

  const handleGroupBy = (value) => {
    if (value !== groupBy) {
      setGroupBy(value);
      if (selectedSalesDF.value === CUSTOM) {
        let sd = customDate[0]?.startDate;
        let ed = customDate[0]?.endDate;

        sd = `${customDate[0]?.startDate.getDate()}-${
          customDate[0]?.startDate.getMonth() + 1
        }-${customDate[0]?.startDate.getFullYear()}`;
        ed = `${customDate[0]?.endDate.getDate()}-${
          customDate[0]?.endDate.getMonth() + 1
        }-${customDate[0]?.endDate.getFullYear()}`;

        getData(
          selectedSalesDF.value,
          value,
          selectedMarketplace,
          goalsMarketplaceIds,
          selectedVendorMetricsType.value,
          selectedChildBrand,
          sd,
          ed,
          true,
        );
      } else {
        getData(
          selectedSalesDF.value,
          value,
          selectedMarketplace,
          goalsMarketplaceIds,
          selectedVendorMetricsType.value,
          selectedChildBrand,
          null,
          null,
          true,
        );
      }
    }
  };

  const applyCustomDate = () => {
    if (selectedSalesDF.value !== CUSTOM)
      setSelectedSalesDF({
        value: CUSTOM,
        label: 'Custom Range',
        sub: 'Select start & end date',
      });
    bindErrorMessage(SALEPERFORMANCE, salesMinDate);
    setShowCustomDateModal(false);
    setIsSPCustomDateApply(true);
    setIsAsinApiCallCustom(Math.random());
    checkDifferenceBetweenDates(
      selectedState[0].startDate,
      selectedState[0].endDate,
      CUSTOM,
      selectedMarketplace,
    );
  };

  const returnMinDate = () => {
    return salesMinDate === null
      ? new Date('01/01/1901')
      : new Date(salesMinDate);
  };

  const daysDiffForSales = dayjs(currentDate).diff(dayjs(salesMinDate), 'days');

  return (
    <>
      <VendorSalesPerformanceFilters
        dspPpcFlag={dspPpcFlag}
        selectedMarketplace={selectedMarketplace}
        marketplaceOptions={marketplaceOptions}
        handleMarketplaceOptions={handleMarketplaceOptions}
        dateOptions={dateOptionsWithYear}
        getSelectComponents={getSelectComponents}
        DropdownIndicator={{ DropdownIndicator }}
        selectedSalesDF={selectedSalesDF}
        handleDailyFact={handleDailyFact}
        isApiCall={isApiCall || minDateLoader}
        daysDiff={daysDiffForSales}
        currentDate={currentDate}
        selectedChildBrand={selectedChildBrand}
        childBrandOptions={childBrandOptions}
        handleChildBrandOptions={handleChildBrandOptions}
        displayAccountTypeFilter={displayAccountTypeFilter}
      />

      <VendorSalesPerformancePanel
        activeSales={activeSales}
        currencySymbol={currencySymbol}
        setActiveSales={setActiveSales}
        handleMetricsType={handleMetricsType}
        isApiCall={isApiCall}
        selectedValue={selectedSalesDF}
        handleGroupBy={handleGroupBy}
        goalsToggle={goalsToggle}
        setGoalsToggle={setGoalsToggle}
        groupBy={groupBy}
        goalsMetricData={goalsMetricData}
        salesGoalChartData={salesGoalChartData}
        salesCurrentTotal={salesCurrentTotal}
        salesDifference={salesDifference}
        salesPreviousTotal={salesPreviousTotal}
        salesGraphLoader={salesGraphLoader}
        salesChartData={salesChartData}
        metricsType={selectedVendorMetricsType}
        customDateError={customDateError}
        renderDataAvailableChildList={renderDataAvailableChildList}
        customDate={customDate}
        customerId={id}
        selectedMarketplace={selectedMarketplace}
        selectedChildBrand={selectedChildBrand}
        isSPCustomDateApply={isSPCustomDateApply}
        isAsinApiCallCustom={isAsinApiCallCustom}
        hideOrderedRevenue={hideOrderedRevenue}
      />

      {/* custom date modal for sale performance graph */}
      <CustomDateModal
        id="BT-vendorPerformanceReport-daterange-SPdate"
        isOpen={showCustomDateModal}
        ranges={selectedState}
        currentDate={maxDate}
        onChange={(item) => {
          setSelectedState([item.selection]);
        }}
        onApply={() => applyCustomDate()}
        onClick={() => {
          setShowCustomDateModal(false);
          if (selectedSalesDF.value !== CUSTOM) {
            setSelectedState([
              {
                startDate: maxDate,
                endDate: maxDate,
                key: 'selection',
              },
            ]);
          }
        }}
        minDate={returnMinDate()}
      />
    </>
  );
}

VendorSalesPerformanceContainer.defaultProps = {
  id: '',
  salesMinDate: '',
  minDateLoader: false,
  isDisabled: false,
  data: {},
  dspPpcFlag: {},
  marketplaceChoices: {},
  selectedSalesDF: {},
  setSelectedSalesDF: () => {},
  selectedState: [],
  childBrandOptions: [],
  selectedChildBrand: {},
  setSelectedState: () => {},
  currentDate: new Date(),
  selectedMarketplace: {},
  customDateError: {},
  setSelectedMarketplace: () => {},
  goalsMarketplaceIds: [],
  marketplaceOptions: [],
  goalsMarketplaceOptions: [],
  currency: '',
  setCurrency: () => {},
  currencySymbol: '',
  setCurrencySymbol: () => {},
  bindErrorMessage: () => {},
  setSelectedChildBrand: () => {},
  displayAccountTypeFilter: () => {},
  renderDataAvailableChildList: () => {},
  getDataAvailabeleChildBrandList: () => {},
  createMarektplaceIdsListOfGoals: () => {},
};

VendorSalesPerformanceContainer.propTypes = {
  id: string,
  salesMinDate: string,
  minDateLoader: bool,
  isDisabled: bool,
  data: shape({}),
  dspPpcFlag: shape({}),
  marketplaceChoices: instanceOf(Object),
  selectedSalesDF: shape({}),
  customDateError: shape({}),
  selectedChildBrand: shape({}),
  childBrandOptions: arrayOf(shape({})),
  setSelectedSalesDF: func,
  selectedState: arrayOf(shape()),
  setSelectedState: func,
  currentDate: instanceOf(Date),
  selectedMarketplace: shape({}),
  setSelectedMarketplace: func,
  goalsMarketplaceIds: arrayOf(string),
  marketplaceOptions: arrayOf(shape({})),
  goalsMarketplaceOptions: arrayOf(shape({})),
  currency: string,
  setCurrency: func,
  currencySymbol: string,
  setCurrencySymbol: func,
  bindErrorMessage: func,
  setSelectedChildBrand: func,
  displayAccountTypeFilter: func,
  renderDataAvailableChildList: func,
  getDataAvailabeleChildBrandList: func,
  createMarektplaceIdsListOfGoals: func,
};
