import { string } from 'prop-types';

import React from 'react';

function MultipleDashedLineLabel({ label, className }) {
  return (
    <div className="weeks">
      <ul className="dashed-line">
        <li key="dashed-line-1" className={className} />
        <li key="dashed-line-2" className={className} />
      </ul>
      <span>{label}</span>
    </div>
  );
}

export default MultipleDashedLineLabel;

MultipleDashedLineLabel.defaultProps = {
  label: '',
  className: '',
};

MultipleDashedLineLabel.propTypes = {
  label: string,
  className: string,
};
