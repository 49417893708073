/* eslint-disable no-else-return */
import queryString from 'query-string';

import axiosInstance from '../axios';

import {
  API_PERFORMANCE,
  API_AD_PERFORMANCE,
  API_VENDOR_ORDERED,
  API_VENDOR_SHIPPED,
  API_ALLOCATE_BALANCE,
  API_ESCROW_BALANCE_MARKETPLACE,
  API_ESCROW_REALLOCATION,
  API_ASIN_PERFORMANCE,
  API_PERFORMANCE_MIN_DATE,
  API_DATA_AVAIBALE_CHILD_BRANDS,
  API_CREATIVE_PROJECT,
  API_CREATIVE_PROJECT_TABLE_INFO,
  API_CREATIVE_PROJECT_MONTHS,
  API_CREATIVE_PROJECT_CSV,
  API_VENDOR_ORDERED_ASIN_PERFORMANCE,
  API_VENDOR_SHIPPED_COGS_ASIN_PERFORMANCE,
  API_PROJECT_TYPES,
  API_COMPLETED_CREATIVE_PROJECT,
  API_SPONSORED_ASIN_PERFORMANCE,
  API_DSP_ASIN_PERFORMANCE,
  API_SPONSORED_CAMPAIGN_DATA,
  API_SPONSORED_CAMPAIGN_ASIN_PERFORMANCE,
  API_DSP_CAMPAIGN_LIST,
  API_DSP_CAMPAIGN_DOWNLOAD_REPORT,
  API_SP_CAMPAIGN_DOWNLOAD_REPORT,
  SELLERSMALL,
  CUSTOM,
  ORDEREDREVENUE,
  SHIPPEDCOGS,
} from '../constants';

export async function getPerformance(
  customer,
  dailyFacts,
  groupBy,
  marketplace,
  goalsMarketplaceIds,
  childBrandId,
  startDate,
  endDate,
) {
  let marketplacesData = '';
  if (goalsMarketplaceIds?.length) {
    marketplacesData = goalsMarketplaceIds?.toString();
    // queryString.stringify(list);
  }

  let params = {
    daily_facts: dailyFacts,
    group_by: groupBy,
    marketplace,
  };
  if (startDate && endDate) {
    params = {
      daily_facts: dailyFacts,
      group_by: groupBy,
      marketplace,
      start_date: startDate,
      end_date: endDate,
    };
  }

  if (childBrandId !== null) {
    params = {
      ...params,
      childBrandId: childBrandId?.value,
    };
  }

  if (marketplacesData && marketplacesData?.length) {
    params = {
      ...params,
      goal_marketplace: marketplacesData,
    };
  }
  const result = await axiosInstance
    .get(`${API_PERFORMANCE + customer}/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getVendorReportingData(
  customer,
  dailyFacts,
  groupBy,
  marketplace,
  goalsMarketplaceIds,
  childBrandId,
  startDate,
  endDate,
  metricsType,
) {
  const api =
    metricsType === ORDEREDREVENUE ? API_VENDOR_ORDERED : API_VENDOR_SHIPPED;
  const goalMarketplacesIds =
    goalsMarketplaceIds?.length > 0 ? goalsMarketplaceIds?.toString() : null;
  let params = {
    daily_facts: dailyFacts,
    group_by: groupBy,
    marketplace: marketplace?.value,
    goal_marketplace: goalMarketplacesIds,
  };

  if (startDate && endDate) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }

  if (childBrandId !== null) {
    params = {
      ...params,
      childBrandId: childBrandId?.value,
    };
  }

  const result = await axiosInstance
    .get(`${api + customer}/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getBuyBoxChartData(
  customer,
  marketplace,
  bbDailyFact,
  groupBy,
  childBrandId,
  startDate,
  endDate,
) {
  let params = {};
  if (startDate && endDate) {
    params = {
      daily_facts: 'week',
      group_by: groupBy,
      marketplace,
      bbep_interval: bbDailyFact,
      start_date: startDate,
      end_date: endDate,
    };
  } else {
    params = {
      daily_facts: 'week',
      group_by: groupBy,
      marketplace,
      bbep_interval: bbDailyFact,
    };
  }

  if (childBrandId !== null) {
    params = {
      ...params,
      childBrandId: childBrandId?.value,
    };
  }

  const result = await axiosInstance
    .get(`${API_PERFORMANCE + customer}/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getAdPerformance(
  customer,
  adType,
  dailyFacts,
  groupBy,
  marketplace,
  goalsMarketplaceIds,
  childBrandId,
  startDate,
  endDate,
  accountType,
) {
  let marketplacesData = '';
  if (goalsMarketplaceIds?.length) {
    marketplacesData = goalsMarketplaceIds?.toString();
    // queryString.stringify(list);
  }

  let params = {};
  if (startDate && endDate) {
    params = {
      sponsored_type: adType,
      daily_facts: dailyFacts,
      group_by: groupBy,
      marketplace,
      start_date: startDate,
      end_date: endDate,
      account_type: accountType,
    };
  } else {
    params = {
      sponsored_type: adType,
      daily_facts: dailyFacts,
      group_by: groupBy,
      marketplace,
      account_type: accountType,
    };
  }

  if (childBrandId !== null) {
    params = {
      ...params,
      childBrandId: childBrandId?.value,
    };
  }
  const result = await axiosInstance
    .get(
      `${API_AD_PERFORMANCE + customer}/?${
        marketplacesData && marketplacesData?.length
          ? `goal_marketplace=${marketplacesData}`
          : ''
      }`,
      { params },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// PDV-8693 - Sponsored campaign report
// async function for get sponsored ad campaign data
export async function getSponsoredAdCampaignData(
  customerId,
  accountType,
  selectedMarketplace,
  sponsoredAdType,
  timeFrame,
  campaignSortBy,
  campaignSearchQuery,
  orderByApiKey,
  pageNumber,
  startDate,
  endDate,
  childBrandId,
) {
  const params = {
    customer: customerId,
    account_type: accountType,
    marketplace: selectedMarketplace,
    ads_report_type: sponsoredAdType,
    daily_facts: timeFrame,
    campaign_status: campaignSortBy,
    q: campaignSearchQuery,
    'order-by': orderByApiKey,
    page: pageNumber,
    start_date: timeFrame === CUSTOM ? startDate : null,
    end_date: timeFrame === CUSTOM ? endDate : null,
    childBrandId: childBrandId !== null ? childBrandId : null,
  };

  const result = await axiosInstance
    .get(API_SPONSORED_CAMPAIGN_DATA, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// PDV-8693 - Sponsored campaign report
// async function for get sponsored ad campaign asin performance data
export async function getSponsoredAdCampaignAsinData(
  customerId,
  accountType,
  sponsoredAdType,
  campaignId,
  campaignStatus,
  asinCount,
  marketplaceId,
  orderByApiKey,
  pageNumber,
  timeFrame,
  startDate,
  endDate,
  childBrandId,
) {
  const params = {
    customer: customerId,
    account_type: accountType,
    ads_report_type: sponsoredAdType,
    campaign_id: campaignId,
    campaign_status: campaignStatus,
    asin_count: asinCount,
    marketplace_id: marketplaceId,
    'order-by': orderByApiKey,
    daily_facts: timeFrame,
    page: pageNumber,
    start_date: timeFrame === CUSTOM ? startDate : null,
    end_date: timeFrame === CUSTOM ? endDate : null,
    childBrandId: childBrandId !== null ? childBrandId : null,
  };

  const result = await axiosInstance
    .get(API_SPONSORED_CAMPAIGN_ASIN_PERFORMANCE, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// async function for get sponsored ad campaign downl;oad report
export async function getSpAdCampaignDownloadReport(
  customerId,
  accountType,
  selectedMarketplace,
  sponsoredAdType,
  timeFrame,
  campaignSortBy,
  campaignSearchQuery,
  orderByApiKey,
  startDate,
  endDate,
  childBrandId,
) {
  const params = {
    customer: customerId,
    account_type: accountType,
    marketplace: selectedMarketplace,
    ads_report_type: sponsoredAdType,
    daily_facts: timeFrame,
    campaign_status: campaignSortBy,
    q: campaignSearchQuery,
    'order-by': orderByApiKey,
    start_date: timeFrame === CUSTOM ? startDate : null,
    end_date: timeFrame === CUSTOM ? endDate : null,
    childBrandId: childBrandId !== null ? childBrandId : null,
  };

  const result = await axiosInstance
    .get(API_SP_CAMPAIGN_DOWNLOAD_REPORT, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// PDV-8401
// async function for get sponsored ad asin performance data
export async function getSponsoredAdAsinPerformanceData(
  customerId,
  accountType,
  selectedMarketplace,
  sponsoredAdType,
  orderByApiKey,
  pageNumber,
  timeFrame,
  startDate,
  endDate,
  childBrandId,
  downloadReport = null,
) {
  const params = {
    customer: customerId,
    account_type: accountType,
    marketplace: selectedMarketplace,
    ads_report_type: sponsoredAdType,
    'order-by': orderByApiKey,
    daily_facts: timeFrame,
    page: downloadReport ? null : pageNumber,
    start_date: timeFrame === CUSTOM ? startDate : null,
    end_date: timeFrame === CUSTOM ? endDate : null,
    childBrandId: childBrandId !== null ? childBrandId : null,
    download_report: downloadReport !== null ? true : null,
  };

  const result = await axiosInstance
    .get(API_SPONSORED_ASIN_PERFORMANCE, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDspAdAsinPerformanceData(
  customerId,
  selectedMarketplace,
  orderByApiKey,
  pageNumber,
  timeFrame,
  startDate,
  endDate,
  childBrandId,
  downloadReport = null,
) {
  const params = {
    customer: customerId,
    marketplace: selectedMarketplace,
    'order-by': orderByApiKey,
    daily_facts: timeFrame,
    page: downloadReport ? null : pageNumber,
    start_date: timeFrame === CUSTOM ? startDate : null,
    end_date: timeFrame === CUSTOM ? endDate : null,
    childBrandId: childBrandId !== null ? childBrandId : null,
    download_report: downloadReport !== null ? true : null,
  };

  const result = await axiosInstance
    .get(API_DSP_ASIN_PERFORMANCE, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// async function for dsp campaign download report
export async function getDspCampaignDownloadReport(
  customerId,
  selectedMarketplace,
  timeFrame,
  orderByApiKey,
  dspCampaignSearchQuery,
  startDate,
  endDate,
  childBrandId,
) {
  const params = {
    customer: customerId,
    marketplace: selectedMarketplace,
    daily_facts: timeFrame,
    'order-by': orderByApiKey,
    q: dspCampaignSearchQuery?.length > 0 ? dspCampaignSearchQuery : null,
    start_date: timeFrame === CUSTOM ? startDate : null,
    end_date: timeFrame === CUSTOM ? endDate : null,
    childBrandId: childBrandId !== null ? childBrandId : null,
    dsp_campaign_report: true,
  };

  const result = await axiosInstance
    .get(API_DSP_CAMPAIGN_DOWNLOAD_REPORT, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDspCampaignList(
  customerId,
  selectedMarketplace,
  orderByApiKey,
  pageNumber,
  timeFrame,
  searchQuery,
  childBrandId,
  startDate,
  endDate,
) {
  let params = {
    customer: customerId,
    marketplace: selectedMarketplace,
    'order-by': orderByApiKey,
    daily_facts: timeFrame,
    page: pageNumber,
    childBrandId: childBrandId !== null ? childBrandId : null,
  };

  if (searchQuery !== '') {
    params = {
      ...params,
      q: searchQuery,
    };
  }
  if (timeFrame === CUSTOM) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }

  const result = await axiosInstance
    .get(API_DSP_CAMPAIGN_LIST, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDspCampaignPerformanceData(
  customerId,
  selectedMarketplace,
  orderByApiKey,
  pageNumber,
  timeFrame,
  startDate,
  endDate,
  childBrandId,
  downloadReport = null,
  campaignOrderId = null,
) {
  let params = {
    customer: customerId,
    marketplace: selectedMarketplace,
    'order-by': orderByApiKey,
    daily_facts: timeFrame,
    page: downloadReport ? null : pageNumber,
    start_date: timeFrame === CUSTOM ? startDate : null,
    end_date: timeFrame === CUSTOM ? endDate : null,
    childBrandId: childBrandId !== null ? childBrandId : null,
    download_report: downloadReport !== null ? true : null,
  };

  if (campaignOrderId !== null) {
    params = {
      ...params,
      order_id: campaignOrderId,
    };
  }

  const result = await axiosInstance
    .get(API_DSP_ASIN_PERFORMANCE, {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDSPPerformance(
  customer,
  dailyFacts,
  groupBy,
  marketplace,
  goalsMarketplaceIds,
  childBrandId,
  startDate,
  endDate,
) {
  let marketplacesData = '';
  if (goalsMarketplaceIds?.length) {
    marketplacesData = goalsMarketplaceIds?.toString();
    // queryString.stringify(list);
  }
  let params = {
    dsp_daily_facts: dailyFacts,
    dsp_group_by: groupBy,
    dsp_marketplace: marketplace,
  };

  if (startDate && endDate) {
    params = {
      ...params,
      dsp_start_date: startDate,
      dsp_end_date: endDate,
    };
  }

  if (childBrandId !== null) {
    params = {
      ...params,
      childBrandId: childBrandId?.value,
    };
  }
  const result = await axiosInstance
    .get(
      `${API_AD_PERFORMANCE + customer}/?${
        marketplacesData && marketplacesData?.length
          ? `goal_marketplace=${marketplacesData}`
          : ''
      }`,
      { params },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDSPPacingGraphData(
  customer,
  marketplace,
  childBrandId,
) {
  let params = { dsp_marketplace: marketplace, dsp_pacing_graph: true };

  if (childBrandId !== null) {
    params = {
      ...params,
      childBrandId: childBrandId?.value,
    };
  }

  const result = await axiosInstance
    .get(`${API_AD_PERFORMANCE + customer}/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDspPacingData(id, marketplace, childBrandId) {
  let params = { dsp_pacing: 'month', dsp_marketplace: marketplace };

  if (childBrandId !== null) {
    params = {
      ...params,
      childBrandId: childBrandId?.value,
    };
  }

  const result = await axiosInstance
    .get(`${API_AD_PERFORMANCE + id}/`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
export async function getEscrowBalanceMarketplaceData(id) {
  const params = { customer_id: id };
  const result = await axiosInstance
    .get(`${API_ESCROW_BALANCE_MARKETPLACE}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
export async function storeAllocatedBudget(data, customerId, marketplace) {
  const allocateMonths = [];
  for (const option of data) {
    allocateMonths.push({
      escrow_allocated_converted_usd: Number(
        option.escrow_allocated_converted_usd,
      ),
      month_year: option.month_year,
    });
  }

  const params = {
    customer_id: customerId,
    marketplace,
    allocate_balance: allocateMonths,
  };

  const result = await axiosInstance
    .post(API_ALLOCATE_BALANCE, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
export async function storeEscrowReallocation(data) {
  const result = await axiosInstance
    .post(API_ESCROW_REALLOCATION, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getASINPerformanceData(
  customer,
  marketplace,
  childBrandId,
  page,
  orderByKey,
  orderByFlag,
  asinType,
  timefilterType,
  startDate,
  endDate,
  selectedAccountType,
  vendorMetricsType,
  downloadReport,
) {
  let params = {
    customer,
    marketplace,
    page,
  };

  params = {
    ...params,
    'order-by': orderByFlag ? `-${orderByKey}` : orderByKey,
    daily_facts: timefilterType,
  };
  if (downloadReport) {
    params.download_report = true;
  }

  if (selectedAccountType === SELLERSMALL) {
    params = {
      ...params,
      asin_type: asinType,
    };
  }

  if (childBrandId !== null) {
    params = {
      ...params,
      childBrandId: childBrandId?.value,
    };
  }

  if (timefilterType === CUSTOM) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }

  let result = null;
  if (selectedAccountType === SELLERSMALL) {
    result = await axiosInstance.get(`${API_ASIN_PERFORMANCE}`, {
      params,
    });
  } else if (vendorMetricsType === ORDEREDREVENUE) {
    result = await axiosInstance.get(`${API_VENDOR_ORDERED_ASIN_PERFORMANCE}`, {
      params,
    });
  } else if (vendorMetricsType === SHIPPEDCOGS) {
    result = await axiosInstance.get(
      `${API_VENDOR_SHIPPED_COGS_ASIN_PERFORMANCE}`,
      {
        params,
      },
    );
  }

  return result;
}

export async function getSalesAndAdMinDate(
  customer,
  marketplace,
  sponsoredType,
  accountType,
) {
  const params = {
    customer,
    marketplace,
    sponsored_type: sponsoredType,
    account_type: accountType,
  };

  const result = await axiosInstance
    .get(`${API_PERFORMANCE_MIN_DATE}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDataAvailabeleChildBrand(
  customer,
  dashboard,
  accountType,
  marketplace,
  sponsoredType,
  timeframe,
  startDate,
  endDate,
) {
  let params = {
    customer,
    account_type: accountType,
    marketplace,
    timeframe,
  };

  if (dashboard === ORDEREDREVENUE) {
    params = {
      ...params,
      dashboard: 'ordered_revenue',
    };
  } else if (dashboard === SHIPPEDCOGS) {
    params = {
      ...params,
      dashboard: 'shipped_cogs',
    };
  } else {
    params = {
      ...params,
      dashboard,
    };
  }

  if (sponsoredType !== null) {
    params = {
      ...params,
      sponsored_type: sponsoredType,
    };
  }

  if (timeframe === CUSTOM) {
    params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }

  const result = await axiosInstance
    .get(`${API_DATA_AVAIBALE_CHILD_BRANDS}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCreativeProjectMonths(customer, accountType) {
  const params = { customer, account_type: accountType };
  const result = await axiosInstance
    .get(API_CREATIVE_PROJECT_MONTHS, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCreativeProjectGlobalFilters(
  customer,
  accountType,
  filteredData,
) {
  const modifiedMonthData = filteredData?.months
    ?.map((op) => op.value)
    .toString();
  const params = {
    customer,
    account_type: accountType,
    month:
      !modifiedMonthData || modifiedMonthData?.includes('all')
        ? null
        : modifiedMonthData,
  };
  const result = await axiosInstance
    .get(API_CREATIVE_PROJECT, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCreativeProjectTableInfo(
  customer,
  vendorReporting,
  accountType,
  filteredData,
  page,
  asinCheckSum,
  asin,
) {
  const modifiedMonthData = filteredData?.months
    ?.map((op) => op.value)
    .toString();
  let localAsinCheckSum = null;

  if (asin !== undefined && asin?.length) {
    localAsinCheckSum = [...asin];
    if (asin[0] === null) {
      localAsinCheckSum.shift();
    }
    localAsinCheckSum = localAsinCheckSum.toString();
  }

  const params = {
    customer,
    vendor_reporting: vendorReporting,
    account_type: accountType,
    page,
    asin_view_checksum: asinCheckSum,
    asins: localAsinCheckSum,
    month:
      !modifiedMonthData || modifiedMonthData?.includes('all')
        ? null
        : modifiedMonthData,
  };
  const result = await axiosInstance
    .get(API_CREATIVE_PROJECT_TABLE_INFO, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

const treamObjectValuesInToArray = (selectedDropdownList) => {
  if (selectedDropdownList?.length) {
    let allDropdownValues = [];
    selectedDropdownList.forEach((item) =>
      allDropdownValues.push(`${item?.value}`),
    );
    allDropdownValues = allDropdownValues?.filter((item) => item !== 'all');
    return allDropdownValues;
  }
  return null;
};

export async function getCreativeProjectGraphData(
  customer,
  multiSelectedValues,
  groupBy,
  afterDaysValue,
  accountType,
  vendorReporting,
) {
  const creativeProjectGraphparams = {
    group_by: groupBy,
    days: afterDaysValue,
    account_type: accountType,
  };
  const monthParams = treamObjectValuesInToArray(multiSelectedValues?.months);
  const marketplaceParams = treamObjectValuesInToArray(
    multiSelectedValues?.marketplaces,
  );
  const projectTypeParams = treamObjectValuesInToArray(
    multiSelectedValues?.project_type,
  );
  const projectChecksumParams = treamObjectValuesInToArray(
    multiSelectedValues?.projects,
  );
  const asinParams = multiSelectedValues?.asin;
  // ?.map(
  //   (item) => item?.split('-')?.[0],
  // );

  if (monthParams?.length) {
    creativeProjectGraphparams.month = monthParams;
  }
  if (marketplaceParams?.length) {
    creativeProjectGraphparams.marketplace = marketplaceParams;
  }
  if (projectTypeParams?.length) {
    creativeProjectGraphparams.project_type = projectTypeParams;
  }
  if (projectChecksumParams?.length) {
    creativeProjectGraphparams.project_checksum = projectChecksumParams;
  }
  if (asinParams?.length) {
    creativeProjectGraphparams.asins = asinParams;
  }
  if (vendorReporting?.length) {
    creativeProjectGraphparams.vendor_reporting = vendorReporting;
  }

  const params = queryString.stringify(creativeProjectGraphparams);

  if (projectChecksumParams && projectChecksumParams?.[0] !== 'undefined') {
    const result = await axiosInstance
      .get(`${API_CREATIVE_PROJECT}${customer}/?${params}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
    return result;
  }
  return null;
}

export async function getCreativeProjectTableCSV(
  customer,
  vendorReporting,
  accountType,
  filteredData,
  viewType,
) {
  const modifiedMonthData = filteredData?.months
    ?.map((op) => op.value)
    .toString();
  const modifiedMarketplaceData = filteredData?.marketplaces
    ?.map((op) => op.value)
    .toString();
  const modifiedProjectTypeData = filteredData?.project_type
    ?.map((op) => op.value)
    .toString();
  const params = {
    customer,
    vendor_reporting: vendorReporting,
    account_type: accountType,
    month:
      !modifiedMonthData || modifiedMonthData?.includes('all')
        ? null
        : modifiedMonthData,
    marketplace:
      !modifiedMarketplaceData || modifiedMarketplaceData?.includes('all')
        ? null
        : modifiedMarketplaceData,

    project_type:
      !modifiedProjectTypeData || modifiedProjectTypeData?.includes('all')
        ? null
        : modifiedProjectTypeData,
    view_type: viewType === 'asins' ? 'asin' : viewType,
  };
  const result = await axiosInstance
    .get(API_CREATIVE_PROJECT_CSV, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getProjectTypes() {
  const result = await axiosInstance
    .get(API_PROJECT_TYPES)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function checkCreativeProjectTypes(data) {
  const params = {
    customer: data.customer,
    project: data.project,
    marketplace: data.marketplace,
    account_type: data.account_type,
    completion_date: data.completion_date,
  };
  const result = await axiosInstance
    .get(API_COMPLETED_CREATIVE_PROJECT, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function uploadCreativeSheet(id, data) {
  if (id) {
    const result = await axiosInstance
      .patch(`${API_COMPLETED_CREATIVE_PROJECT + id}/`, {
        customer: data.customer,
        asins_listings: data.asins_listings,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return result;
  } else {
    const result = await axiosInstance
      .post(API_COMPLETED_CREATIVE_PROJECT, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return result;
  }
}

export async function addCreativeProjectType(data) {
  const result = await axiosInstance
    .post(API_PROJECT_TYPES, { project_type: data })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function deleteCreativeProject(id) {
  const result = await axiosInstance
    .delete(`${API_COMPLETED_CREATIVE_PROJECT + id}/`)
    .then(() => {
      return true;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
