import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bool, func, shape } from 'prop-types';

import Theme from '../../../../../theme/Theme';
import { CardMetrics } from '../../../../../common';
import { asinMetrics, PATH_CUSTOMER_DETAILS } from '../../../../../constants';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../../theme/images';
import { setCustomerSelectedTab } from '../../../../../store/actions/customerState';

const InventoryMetrics = ({ metricsData, formatNumber, isCustomer }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const customer = useSelector((state) => state.customerState.data);

  return (
    <div className="row  mr-1 ml-1">
      {asinMetrics.map((item) => {
        return (
          <div
            key={item.label}
            className="col-lg-4 col-md-6 col-12 pr-1 pl-1 mt-3"
          >
            <CardMetrics className=" fix-height ">
              <div
                id="BT-summary-inventory-metrics"
                className="order-chart-box fix-height no-hover"
                role="presentation"
              >
                <div className="chart-name">{item.label}</div>
                <div className="number-rate">
                  {formatNumber(metricsData?.[item.asinValue])}
                </div>

                <div className="justify-space-between ">
                  {isCustomer &&
                  (customer?.display_inventory_management ||
                    customer?.display_under_over_stocked_inventory) ? (
                    <div
                      onClick={() => {
                        dispatch(setCustomerSelectedTab('inventoryManagement'));
                        history.push(
                          PATH_CUSTOMER_DETAILS.replace(':id', customer?.id),
                          item.key,
                        );
                      }}
                      style={{ color: Theme.orange }}
                      className="vs cursor"
                      aria-hidden="true"
                    >
                      View All
                    </div>
                  ) : null}
                  <div
                    className={
                      metricsData?.[item.percentChnage] > 0
                        ? 'perentage-value '
                        : 'perentage-value down '
                    }
                  >
                    {metricsData?.[item.percentChnage] &&
                    metricsData?.[item.percentChnage] > 0 ? (
                      <img
                        className="green-arrow"
                        src={ArrowUpIcon}
                        alt="arrow-up"
                      />
                    ) : metricsData?.[item.percentChnage] &&
                      metricsData?.[item.percentChnage] < 0 ? (
                      <img
                        className="red-arrow"
                        src={ArrowDownIcon}
                        alt="arrow-down"
                      />
                    ) : (
                      ''
                    )}

                    {metricsData?.[item.percentChnage]
                      ? formatNumber(
                          Math.abs(metricsData?.[item.percentChnage]),
                          '',
                          '%',
                        )
                      : 'N/A'}
                  </div>
                </div>
              </div>
            </CardMetrics>
          </div>
        );
      })}
    </div>
  );
};

export default InventoryMetrics;

InventoryMetrics.defaultProps = { metricsData: null, formatNumber: () => {} };

InventoryMetrics.propTypes = {
  metricsData: shape({}),
  formatNumber: func,
  isCustomer: bool.isRequired,
};
