// This is the filter file of the pending Approval dashboard.
// This file contain the search field, sort-by option and refresh button.

// react imports
import React from 'react';

// third party imports
import styled from 'styled-components';
import { components } from 'react-select';
import { bool, func, shape, string } from 'prop-types';
import { DebounceInput } from 'react-debounce-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// local file imports
import Theme from '../../../../theme/Theme';
import { DropDown } from '../../../Customer/CustomerDetailView/CompanyPerformance/DropDown';
import { pendingApprovalOrderOptions } from '../../../../constants';
import {
  WhiteCard,
  DropDownIndicator,
  InputSearchWithRadius,
  DropdownOptions,
} from '../../../../common';

const PendingApprovalsDashboardFilters = ({
  handleSortByFilter,
  selectedSortByOrder,
  apiCallLoader,
  searchQuery,
  setSearchQuery,
  handleSearch,
  handleRefreshClick,
}) => {
  const { SingleValue } = components;

  // react select - single value
  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '15px', color: '#000000' }}>
        {`${props?.data?.label}`}
      </span>
      <div style={{ fontSize: '12px', color: '#556178' }}>
        {props?.data?.sub}
      </div>
    </SingleValue>
  );

  const getSelectComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: singleFilterOption,
      DropDownIndicator,
    };
  };

  // render the search bar functionality
  const renderSearchInputField = () => {
    return (
      <div className="col-md-4 col-12">
        <div className="black-heading-title" role="presentation">
          <InputSearchWithRadius className="w-sm-100 mb-2">
            <DebounceInput
              id="BT-PendingApprovalInputField"
              debounceTimeout={600}
              className={`form-control search-filter form-control-info max-content ${
                apiCallLoader ? 'disabled' : ''
              }`}
              placeholder="Search By BP Name"
              onChange={(event) => {
                handleSearch(event);
              }}
              value={searchQuery || ''}
            />
            <FontAwesomeIcon
              icon="fa-regular fa-magnifying-glass"
              className="search-icon"
            />
            {searchQuery !== '' ? (
              <FontAwesomeIcon
                icon="fal fa-times"
                className="close-icon cursor"
                onClick={() => setSearchQuery('')}
              />
            ) : null}
          </InputSearchWithRadius>
        </div>
      </div>
    );
  };

  // render the sort by dropdown
  const renderDropDown = () => {
    return DropDown(
      'BT-PendingApprovalDashboardFilter',
      '',
      pendingApprovalOrderOptions,
      pendingApprovalOrderOptions[0].label,
      getSelectComponents,
      handleSortByFilter,
      apiCallLoader, // loader,
      null,
      selectedSortByOrder,
      false,
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      <WhiteCard className="header-table  mt-4">
        <PendingApprovalFilters>
          <div className="row mt-2">
            {renderSearchInputField()}
            <div className="col-md-8 col-12 text-right">
              <ul className="dashboard-filter-list">
                <span>Sort by</span>
                <li className="w-10 ml-2">{renderDropDown()}</li>
                <li>
                  <RefreshButton
                    onClick={() => {
                      handleRefreshClick();
                    }}
                  >
                    <div
                      className={`refresh-icon cursor ${
                        apiCallLoader ? 'disabled' : ''
                      }`}
                      role="presentation"
                    >
                      <FontAwesomeIcon
                        icon="fa-light fa-arrows-rotate"
                        fontSize="14px"
                      />
                    </div>
                  </RefreshButton>
                </li>
              </ul>
            </div>
          </div>
        </PendingApprovalFilters>
      </WhiteCard>
    </>
  );
};

export default PendingApprovalsDashboardFilters;

PendingApprovalsDashboardFilters.defaultProps = {
  apiCallLoader: false,
  searchQuery: '',
  data: {},
  selectedSortByOrder: {},
  handleSearch: () => {},
  setSearchQuery: () => {},
  handleSortByFilter: () => {},
  handleRefreshClick: () => {},
};

PendingApprovalsDashboardFilters.propTypes = {
  apiCallLoader: bool,
  searchQuery: string,
  selectedSortByOrder: shape({}),
  data: shape({ sub: string, label: string }),
  handleSearch: func,
  setSearchQuery: func,
  handleSortByFilter: func,
  handleRefreshClick: func,
};

const PendingApprovalFilters = styled.div`
  .dashboard-filter-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: end;

    li {
      display: inline-block;
      margin-right: 25px;

      &.w-10 {
        max-width: 200px;
        width: 100%;
        @media only screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .dashboard-filter-list {
      display: flex;
      li {
        &.sorted-by {
          width: 99% !important;
          .jIDBKP {
            width: 99% !important;
          }
        }
      }
    }
  }
`;

const RefreshButton = styled.div`
  .refresh-icon {
    border-radius: 6px;
    width: 40px;
    text-align: center;
    height: 40px;
    padding: 9px 7px;
    border: 1px solid #bfc5d2;
    color: ${Theme.black};
    border: 1px solid ${Theme.gray25};
    .refresh-arrow-icon {
      vertical-align: text-top;
      margin-right: 4px;
    }
  }
`;
