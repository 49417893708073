// BBE Double Overlap Summary
export const bbeSummaryOptions = [
  { value: 'bbe-double-overlap-summary', label: 'BBE Double Overlap Summary' },
  { value: 'bbe-metrics', label: 'Metrics' },
  { value: 'bbe-new-to-brand-analysis', label: 'New to Brand Analysis' },
];

// Date filter options
const currentYear = new Date()?.getFullYear();

export const amcDashboardDateOptions = [
  { value: '12months', label: `${currentYear}` },
  {
    value: 'custom',
    label: 'Custom Range',
    sub: 'Select start and end dates',
  },
];

// BBE  Summary Brand Partner Names.
export const bbeProductType = [
  { value: 'all', label: 'All Product Type' },
  { value: 'DSP', label: 'DSP' },
  { value: 'sponsored_display', label: 'Sponsored display' },
  { value: 'sponsored_products', label: 'Sponsored Product' },
];

// no graph data mesage.
export const noGraphMessage =
  'Sorry! No AMC historical data available yet. Once the  AMC Data Insights are available, the reports will be automatically updated in this panel.';

// currency Symbol.
export const currencySymbol = '$';

// constant for BBE Summary double overlap analysis graphs headings and sub headings
export const AMCDashboardGraphHeader = {
  BbeSummaryConversionLineGraph: {
    heading: 'Conversion',
    subHeading: 'Conversion based on unique DSP DPV & unique SP Clicks.',
  },
  BbeSummaryAdSalesPieChart: {
    heading: 'Ad Sales vs Ad Exposure Type',
    subHeading: '',
  },
  BbeSummaryAdSalesLineGraph: {
    heading: 'DSP vs SP Total Product Sales',
    subHeading: '',
  },
  MetricsGraph: {
    heading: 'Metrics',
    subHeading: '',
  },
  NTBGraph: {
    heading: 'NTB Analysis',
    subHeading: 'All sales metrics reflect Ad Sales',
  },
};

export const allGraphLabels = {
  metrixGraphLabel: {
    aov: 'aov',
    asp: 'asp',
    uniqueReach: 'unique reach',
    impressions: 'impressions & clicks',
  },
  ntbGraphLabel: {
    adSalesLineGraph: 'ad sale of ntb vs total sale',
    adProductStackgraph:
      'ad product type % split of ntb sale from total ntb sale',
    adproductXYGraph: '% ntb sale/total sale by ad product type',
  },
};

export const metricsExposureType = [
  {
    id: 1,
    label: 'Total',
    value: 'DSP&SP',
  },

  {
    id: 3,
    label: 'DSP',
    value: 'DSP',
  },
  {
    id: 4,
    label: 'SP',
    value: 'SP',
  },
];
