const Theme = {
  /* Theme Base color */
  white: '#ffffff',
  red: '#D60000',
  lightRed: '#fbf2f2',
  darkRed: '#d63649',
  pink: '#f6d2c9',
  lightPink: '#ffded6',
  yellow: '#f7c137',
  lightYellow: '#f5e8c3',
  extraLightYellow: '#FDF3D7',
  darkYellow: '#ffb700',
  orange: '#FF5933',
  lightOrange: '#ffe5df',
  lighterOrange: '#fff4ec',
  extraLighOrange: '#FFE6D4',
  green: '#33ac2e',
  green20: '#33ac2e33',
  lightGreenBg: '#d6eed5',
  lightGreen: '#E5F1E5',
  lighterGreen: '#407b00',
  darkGreen: '#74b035',
  lightBlue: '#d6ebe8',
  blue: '#5578eb',
  darkBlue: '#162C50',
  lightDarkBlue: '#2e5bff',
  black: '#000000',
  gray90: '#2E384D',
  gray85: '#171725',
  gray80: '#333333',
  gray60: '#69707F',
  gray40: '#556178',
  gray45: '#D5D8E1',
  gray35: '#8798AD',
  gray30: '#b0bac9',
  gray25: '#BFC5D2',
  gray20: '#999999',
  gray15: '#f9faff',
  gray12: '#e2e5ef',
  gray11: '#e0e6e8',
  gray9: '#dee2ed',
  gray8: '#F4F6FC',
  gray7: '#e4e8f0',
  gray6: '#fafafb',
  gray5: '#EFF2FF',
  gray4: '#E2E2EA',
  gray3: '#EEF3F5',
  gray2: '#E0E7FF;',
  gray1: '#f8f8fa',

  /* Colors */
  baseColor: '#FF5933',
  secondaryColor: '#e74b28',

  /* Basic font size */
  textExtraSmall: '8px',
  textSmall: '10px',
  verySmall: '11px',
  extraSmall: '12px',
  small: '13px',
  extraNormal: '14px',
  normal: '15px',
  extraMedium: '16px',
  medium: '17px',
  title: '18px',
  subTitle: '22px',

  verySmallRes: '14px',
  extraSmallRes: '15px',
  smallRes: '16px',
  extraNormalRes: '17px',
  normalRes: '18px',
  extraMediumRes: '19px',
  mediumRes: '20px',
  titleRes: '21px',

  /* Title font size */

  largeTitleFontSize: '48px',
  MediumPageTitleFontSize: '40px',
  primaryTitleFontSize: '36px',
  pageTitleFontSize: '34px',
  MediumTitleFontSize: '32px',
  ExtraMediumTitleFontSize: '26px',
  smallTitleFontSize: '28px',
  secondaryTitleFontSize: '24px',
  textFontSize: '16px',
  largeTitleFontSizeRes: '52px',
  primaryTitleFontSizeRes: '40px',
  pageTitleFontSizeRes: '37px',
  MediumTitleFontSizeRes: '34px',
  smallTitleFontSizeRes: '32px',
  secondaryTitleFontSizeRes: '27px',
  textFontSizeRes: '20px',

  /* Font family */
  baseFontFamily: 'Noah Regular',
  titleFontFamily: 'Noah-Bold',
  baseMediumFontFamily: 'Noah-Medium',
  boldItalicFontFamily: 'Noah-BoldItalic',
  regularItalicFontFamily: 'Noah-RegularItalic',
  boldArialTextFontFamily: 'Arial-Bold',
  regularArialTextFontFamily: 'Arial-Regular',
  contactregularFontFamily: 'Helvetica-Regular',
  contactboldFontFamily: 'Helvetica-Bold',

  /* Box Shadow */
  commonShadow: '0 10px 20px 0 rgba(46, 91, 255, 0.07)',
  boxShadow: '0px 0px 5px 5px rgba(46, 91, 255, 0.04)',
};

export default Theme;
