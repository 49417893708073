/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';

import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { func, string } from 'prop-types';

import { InfoIcons } from '../../../../../theme/images';
import Theme from '../../../../../theme/Theme';
import {
  SpreetailRole,
  SpreetailRoleRestrictMessage,
} from '../../../../../constants';
import {
  addCreativeProjectType,
  checkCreativeProjectTypes,
  getMarketPlaceList,
  getProjectTypes,
  uploadCreativeSheet,
} from '../../../../../api';
import {
  Button,
  CustomDropDown,
  DesktopTable,
  ErrorMsg,
  InputFormField,
  PageLoader,
  WhiteCard,
} from '../../../../../common';

export default function UploadCreativeAsins({
  setShowCreativeUploadScreen,
  id,
  accountType,
  type,
  getCreativeMonths,
}) {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [asinList, setAsinList] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [getDropdownOptions, setGetDropdownOptions] = useState({
    projectTypes: [],
    marketplaces: [],
  });
  const [formCreativeData, setFormCreativeData] = useState({
    customer: id,
    account_type: accountType[0].toUpperCase() + accountType.slice(1),
  });
  const [addProjectType, setAddProjectType] = useState(false);
  const [showUploadScreen, setShowUploadScreen] = useState(true);
  const [unformattedAsins, setUnformattedAsins] = useState(null);
  const [tableRows, setTableRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  let disableSaveBtn = null;
  const [apiError, setApiError] = useState([]);

  const marketplaceInfo = () => {
    return (
      <>
        <img
          className="ml-1 cursor"
          style={{
            verticalAlign: 'text-bottom',
            fontWeight: '300',
            position: 'absolute',
            left: '78%',
            top: '12px',
          }}
          width="14px"
          src={InfoIcons}
          alt="info"
          data-tip="Cannot add marketplace outside of the signed agreement."
          data-for="marketplace"
        />{' '}
        <ReactTooltip
          id="marketplace"
          aria-haspopup="true"
          effect="solid"
          html
        />
      </>
    );
  };

  const getProjectData = () => {
    const projects = [];
    getProjectTypes().then((res) => {
      if (res?.status === 200) {
        for (const item of res?.data) {
          projects.push({ value: item.id, label: item.project_type });
        }
      }
    });
    return projects;
  };

  useEffect(() => {
    const marketplace = [];
    getMarketPlaceList(id, false).then((market) => {
      if (market?.status === 200) {
        for (const item of market?.data) {
          marketplace.push({
            value: item.name,
            label: item.country_currency.country,
          });
        }
        setGetDropdownOptions({
          projectTypes: getProjectData(),
          marketplaces: marketplace,
        });
        setIsLoading({ loader: false, type: 'page' });
      }
    });
  }, [id]);

  const handleAsinFormat = (value) => {
    const asins = value
      ?.replace(/([^,\w\s]|(?<=,)(?=,))+/g, '')
      .replace(/,{2,}/g, '')
      .replace(/\n/g, '')
      .replace(/\s+/g, '');
    setUnformattedAsins(asins);
  };

  useEffect(() => {
    if (unformattedAsins) {
      const formattedAsins = unformattedAsins.split(',');
      const asinListing = [];
      formattedAsins.map((op) => asinListing.push(op.toUpperCase()));
      setAsinList(asinListing);
    }
  }, [setAsinList, unformattedAsins]);

  const generateDropdown = (options, key) => {
    return (
      <div className="col-3">
        <InputFormField>
          <CustomDropDown className="w-100">
            <Select
              className={isEditable ? '' : 'isDisabled'}
              options={options}
              value={
                options.find((op) => op.value === formCreativeData[key]) || ''
              }
              onChange={(event) =>
                setFormCreativeData({
                  ...formCreativeData,
                  [key]: event.value,
                })
              }
              placeholder={
                key === 'marketplace' ? 'All Marketplaces' : 'All Project Types'
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: Theme.white,
                  primary25: Theme.lightOrange,
                  primary: Theme.orange,
                  primary50: Theme.lightOrange,
                },
              })}
            />
            {key === 'marketplace' ? marketplaceInfo() : null}
          </CustomDropDown>
        </InputFormField>
      </div>
    );
  };

  const uploadData = (creativeId, data) => {
    uploadCreativeSheet(creativeId, data).then((creative) => {
      if (creative?.status === 200 || creative?.status === 201) {
        setIsEditable(false);
        setShowUploadScreen(true);
        setFormCreativeData({
          customer: id,
          account_type: accountType[0].toUpperCase() + accountType.slice(1),
        });
        toast.success('ASINs added successfully.');
        setIsLoading({ loader: false, type: 'button' });
        if (type === 'noData') getCreativeMonths();
      } else {
        const duplicateAsins = creative?.data?.asins_listings?.duplicates;
        toast.error(
          Object.values(duplicateAsins)?.length
            ? 'Duplicate ASINs. Some of the ASINs are already in our system.'
            : 'Please resolve the error.',
        );
        setIsLoading({ loader: false, type: 'button' });
        setApiError(creative?.data);
      }
    });
  };

  const saveAsinsData = () => {
    setIsLoading({ loader: true, type: 'button' });
    let formattedAsins = asinList.filter((op) => op !== '');
    checkCreativeProjectTypes(formCreativeData).then((res) => {
      formattedAsins = formattedAsins?.map((op) => op?.toUpperCase());
      if (res?.status === 200) {
        if (res?.data?.results?.length) {
          uploadData(res?.data?.results?.[0]?.id, {
            asins_listings: [
              ...res?.data?.results?.[0]?.asins_listings,
              ...formattedAsins,
            ],
            customer: id,
          });
        } else {
          uploadData(null, {
            ...formCreativeData,
            asins_listings: formattedAsins,
          });
        }
      } else {
        uploadData(null, {
          ...formCreativeData,
          asins_listings: formattedAsins,
        });
        toast.error(
          res?.data?.asins_listings || res?.data?.detail || res?.data,
        );
        setIsLoading({ loader: false, type: 'button' });
      }
    });
  };

  const saveProjectType = () => {
    addCreativeProjectType(formCreativeData.newProjectType).then((res) => {
      if (res.status === 201) {
        getProjectData();
        setGetDropdownOptions({
          ...getDropdownOptions,
          projectTypes: getProjectData(),
        });
        setAddProjectType(false);
        toast.success('New Project Type Added');
      }
    });
  };

  // handle upload asin events
  const handleUploadASINEvents = () => {
    const asinData = asinList.reduce((acc, val, i) => {
      if (i % 5 === 0) acc.push([]);
      acc[acc.length - 1].push(val);
      return acc;
    }, []);

    setTableRows(asinData);

    const formattedAsins = asinData.flat();
    //  Check for duplicate ASINs
    const duplicateAsins = formattedAsins.filter(
      (item, index) => formattedAsins.indexOf(item) !== index,
    );
    // Check for ASIns are not exaclty of 10 characters
    const invalidAsins = formattedAsins.filter(
      (item) => item.length > 1 && item.length !== 10,
    );

    setDisableSaveButton(
      duplicateAsins?.length > 0 || invalidAsins?.length > 0,
    );
    setShowUploadScreen(false);
  };

  const handleTableDataChange = (value, rowIndex, colIndex) => {
    const formattedValue = value
      ?.replace(/([^,\w\s]|(?<=,)(?=,))+/g, '')
      .replace(/,{2,}/g, '')
      .replace(/\n/g, '')
      .replace(/\s+/g, '');

    setDisableSaveButton(false);
    const newData = [...tableRows];
    newData[rowIndex][colIndex] = formattedValue;

    setTableRows(newData);

    //  Check for duplicate ASINs
    const formattedAsins = newData.flat();
    const duplicateAsins = formattedAsins.filter(
      (item, index) => formattedAsins.indexOf(item) !== index,
    );
    if (duplicateAsins.length > 0) {
      setErrorMessage('Duplicate ASINs are not allowed');
    } else {
      setErrorMessage(null);
    }

    // Check for ASIns are not exaclty of 10 characters
    const invalidAsins = formattedAsins.filter(
      (item) => item?.length > 1 && item?.length !== 10,
    );
    if (invalidAsins.length > 0) {
      setErrorMessage('10 alphanumeric characters is required.');
    } else {
      setErrorMessage(null);
    }
    setAsinList(formattedAsins);
  };

  const renderTableData = (value, rowIndex, colIndex) => {
    // remove all the nested arrays
    const formattedAsins = tableRows.flat();

    // get all the duplicate Asins
    const duplicateAsins = formattedAsins
      .filter((item, index) => formattedAsins.indexOf(item) !== index)
      .includes(value);
    // get all the asin if they are not exact 10
    const invalidAsins = value?.length > 1 && value?.length !== 10;
    let apiDuplicateAsins = [];
    if (invalidAsins || duplicateAsins) disableSaveBtn = true;
    if (
      apiError?.asins_listings?.duplicates &&
      Object.values(apiError?.asins_listings?.duplicates)?.length
    ) {
      const errorAsins = [
        ...Object.values(apiError?.asins_listings?.duplicates),
        ...formattedAsins,
      ];
      apiDuplicateAsins = errorAsins
        .filter((item, index) => errorAsins.indexOf(item) !== index)
        .includes(value);
    }

    return (
      <td key={colIndex} style={{ border: 'none', verticalAlign: 'top' }}>
        <input
          className={
            invalidAsins ||
            duplicateAsins ||
            (apiError?.asins_listings?.duplicates &&
              Object.values(apiError?.asins_listings?.duplicates).length &&
              apiDuplicateAsins)
              ? 'creative-input creative-input-error'
              : 'creative-input'
          }
          maxLength="10"
          placeholder="-"
          defaultValue={value}
          value={value}
          style={{ textTransform: 'uppercase', width: '100%' }}
          onChange={(event) =>
            handleTableDataChange(event.target.value, rowIndex, colIndex)
          }
        />
        {duplicateAsins && <ErrorMsg>Duplicate ASINs.</ErrorMsg>}
        {invalidAsins && (
          <ErrorMsg>10 alphanumeric characters is required.</ErrorMsg>
        )}
        {apiError?.asins_listings?.duplicates &&
          Object.values(apiError?.asins_listings?.duplicates).length &&
          apiDuplicateAsins && (
            <ErrorMsg>This ASIN exists in the same project.</ErrorMsg>
          )}
      </td>
    );
  };

  return (
    <>
      {isLoading.loader && isLoading.type === 'page' ? (
        <PageLoader color="#FF5933" type="page" />
      ) : (
        <>
          <WhiteCard className="mb-3">
            <div className="row">
              <div className={type === 'noData' ? 'col-7' : 'col-4'}>
                {type === 'dataPresent' ? (
                  <span
                    onClick={() => setShowCreativeUploadScreen(false)}
                    role="presentation"
                    style={{ lineHeight: 3 }}
                  >
                    <FontAwesomeIcon
                      icon="fa-light fa-chevron-left"
                      style={{
                        marginRight: '8px',
                        fontSize: '12px',
                        cursor: 'pointer',
                        color: '#000',
                      }}
                    />
                    <span className="black-heading-title cursor">
                      Completed Project
                    </span>
                  </span>
                ) : (
                  <span>
                    No creative projects available. Proceed to click on
                    &quot;Edit&quot; button to backfill past completed creative
                    projects.
                  </span>
                )}
              </div>
              <div
                className={
                  type === 'noData' ? 'col-5 text-right' : 'col-8 text-right'
                }
              >
                <Button
                  data-tip={SpreetailRoleRestrictMessage}
                  data-for={
                    isSpreetailUser ? 'spreetailRetrictEditCreativeProject' : ''
                  }
                  style={{
                    width:
                      isLoading.loader && isLoading.type === 'button'
                        ? '70px'
                        : 'fit-content',
                  }}
                  type="button"
                  onClick={() => {
                    if (isEditable) saveAsinsData();
                    else setIsEditable(!isEditable);
                  }}
                  className={
                    isEditable &&
                    (asinList.length === 0 ||
                      errorMessage ||
                      showUploadScreen ||
                      disableSaveBtn)
                      ? 'isDisabled btn-primary extraNormal-text'
                      : 'btn-primary extraNormal-text'
                  }
                  disabled={
                    isSpreetailUser ||
                    (isEditable &&
                      (asinList.length === 0 ||
                        errorMessage ||
                        showUploadScreen ||
                        disableSaveBtn ||
                        disableSaveButton))
                  }
                >
                  {isLoading.loader && isLoading.type === 'button' ? null : (
                    <FontAwesomeIcon
                      icon={
                        isEditable ? 'fa-regular fa-check' : 'fa-regular fa-pen'
                      }
                      style={{ color: '#fff', fontSize: '12px' }}
                      className="cursor  pl-1"
                    />
                  )}{' '}
                  {isEditable ? (
                    isLoading.loader && isLoading.type === 'button' ? (
                      <PageLoader color="#fff" type="button" />
                    ) : (
                      'Save'
                    )
                  ) : (
                    'Edit'
                  )}
                </Button>
                <ReactTooltip
                  id="spreetailRetrictEditCreativeProject"
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                  html
                />
              </div>
              <div className="horizontal-line straight-line mt-3 d-lg-block d-none" />
            </div>

            <div className="row mt-4">
              <div className="col-3" style={{ lineHeight: 3 }}>
                <span
                  className={
                    !isEditable ||
                    (formCreativeData.project &&
                      formCreativeData.marketplace &&
                      formCreativeData.completion_date)
                      ? 'normal-text-black cursor isDisabled'
                      : 'normal-text-black cursor'
                  }
                  role="presentation"
                  onClick={() => setAddProjectType(true)}
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-plus"
                    className="add-icon ml-2"
                    style={{
                      cursor: 'pointer',
                      color: '#ff5933',
                    }}
                  />{' '}
                  Add New Project Type
                </span>
                {addProjectType ? (
                  <div className="creative-add-project text-right">
                    <input
                      type="text"
                      className="px-2"
                      placeholder="Input text here"
                      onChange={(event) =>
                        setFormCreativeData({
                          ...formCreativeData,
                          newProjectType: event.target.value,
                        })
                      }
                    />
                    <span
                      className="mr-2 cursor"
                      onClick={() => saveProjectType()}
                      role="presentation"
                      style={{ color: '#FF5933' }}
                    >
                      Save
                    </span>
                    <span
                      className="mr-2 cursor"
                      onClick={() => setAddProjectType(false)}
                      role="presentation"
                    >
                      Cancel
                    </span>
                  </div>
                ) : null}
              </div>
              {generateDropdown(getDropdownOptions.projectTypes, 'project')}
              {generateDropdown(getDropdownOptions.marketplaces, 'marketplace')}

              <div className="col-3">
                <InputFormField>
                  <DatePicker
                    format="MM/dd/yyyy"
                    className={
                      isEditable
                        ? 'form-control dates'
                        : 'form-control dates isDisabled'
                    }
                    value={
                      formCreativeData?.completion_date
                        ? new Date(
                            formCreativeData?.completion_date?.replace(
                              /-/g,
                              '/',
                            ),
                          )
                        : null
                    }
                    maxDate={type === 'noData' ? new Date() : null}
                    clearIcon={false}
                    placeholder="MM/DD/YYYY"
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    onChange={(event) => {
                      const date = new Date(event);
                      const currentDate = `${date.getFullYear()}-${
                        date.getMonth() + 1
                      }-${date.getDate()}`;
                      setFormCreativeData({
                        ...formCreativeData,
                        completion_date: currentDate,
                      });
                    }}
                  />
                </InputFormField>
              </div>
            </div>
          </WhiteCard>
          {formCreativeData.project &&
          formCreativeData.marketplace &&
          formCreativeData.completion_date ? (
            <WhiteCard className="mt-3 px-0 pt-0 pb-5">
              <div className="row m-0">
                <div className="creative-upload col-6">
                  <strong>ASINS</strong> (Hint: 10 alphanumeric characters is
                  required.)
                </div>

                <div
                  className={
                    !showUploadScreen
                      ? 'creative-upload col-6 text-right cursor'
                      : 'creative-upload col-6'
                  }
                  role="presentation"
                  onClick={() => {
                    setShowUploadScreen(true);
                    setUnformattedAsins(asinList.toString());
                  }}
                >
                  {!showUploadScreen ? (
                    <strong> &lt; Back to uploading ASINs</strong>
                  ) : null}
                </div>
              </div>

              {showUploadScreen ? (
                <>
                  <textarea
                    style={{
                      width: '100%',
                      height: '300px',
                      textTransform: 'uppercase',
                    }}
                    className="mt-3"
                    value={unformattedAsins}
                    onChange={(event) => handleAsinFormat(event.target.value)}
                  />
                  <Button
                    className={
                      !unformattedAsins || unformattedAsins === ''
                        ? 'btn-primary mt-3 w-50 float-right isDisabled'
                        : 'btn-primary mt-3 w-50 float-right'
                    }
                    onClick={() => {
                      handleUploadASINEvents();
                    }}
                    disabled={!unformattedAsins || unformattedAsins === ''}
                  >
                    Upload ASINs
                  </Button>
                </>
              ) : (
                <DesktopTable>
                  <tbody>
                    {tableRows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((value, colIndex) =>
                          renderTableData(value, rowIndex, colIndex),
                        )}
                      </tr>
                    ))}
                  </tbody>
                </DesktopTable>
              )}
            </WhiteCard>
          ) : null}
        </>
      )}
    </>
  );
}

UploadCreativeAsins.defaultProps = {
  setShowCreativeUploadScreen: () => {},
  id: '',
  accountType: 'seller',
  type: '',
  getCreativeMonths: () => {},
};

UploadCreativeAsins.propTypes = {
  setShowCreativeUploadScreen: func,
  id: string,
  accountType: string,
  type: string,
  getCreativeMonths: func,
};
