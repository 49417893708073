// This is the desktop and mobile view file of the pending Approval dashboard.

// react imports
import React from 'react';

// third party imports
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, bool, func, shape } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// local file imports
import { Icon } from '../../../../theme/Global';
import { DesktopTable, Status, WhiteCard } from '../../../../common';
import { agreementTypes, PATH_AGREEMENT } from '../../../../constants';
import {
  PAStatusColorSet,
  PendingApprovalsTableHeader,
} from '../../../../constants/PendingApprovalsConstant';

const PendingApprovalsDashboardView = ({
  apiCallLoader,
  pendingApprovalListData,
  setIdToArchiveRequest,
  setShowCancellationSummaryModal,
  setShowArchiveConfirmationModal,
}) => {
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const isShowActionCol = userInfo?.bgs_director;
  const isShowSummaryIcon = userInfo?.is_summary_icon_visible;

  // redirect to agreement view
  const redirectToAgreementView = (requestDetails) => {
    history.push({
      pathname: `${PATH_AGREEMENT.replace(
        ':id',
        requestDetails?.company_id,
      ).replace(':contract_id', requestDetails?.id)}`,
      state: `${history.location.pathname}`,
    });
  };

  // display aaproval needed from value
  const displayApprovalNeededFromValue = (approvalNeededFrom) => {
    return approvalNeededFrom?.map((item, index) => {
      if (approvalNeededFrom.length - 1 === index) {
        return item;
      }
      return `${item} | `;
    });
  };
  // display request date
  const displayRequestDate = (requestDate) => {
    return (
      <>
        <FontAwesomeIcon
          icon="fa-regular fa-calendar"
          style={{
            color: '#556178',
            marginRight: '6px',
          }}
        />
        {dayjs(requestDate).format('DD MMM YYYY')}
      </>
    );
  };

  // display request status
  const displayRequestStatus = (requestStatus) => {
    return (
      <Status
        className="float-left"
        label={requestStatus}
        labelColor={PAStatusColorSet[requestStatus]?.labelColor}
        backgroundColor={PAStatusColorSet[requestStatus]?.background}
      />
    );
  };

  // display request type with summary icon
  const displayRequestTypeSummaryIcon = (requestDetails) => {
    return (
      <>
        {requestDetails?.request_type}
        {isShowSummaryIcon &&
        requestDetails?.request_type === 'Contract cancellation' ? (
          <FontAwesomeIcon
            icon="fa-regular fa-newspaper"
            style={{
              color: '#ff5933',
              marginLeft: '6px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowCancellationSummaryModal({
                modal: true,
                id: requestDetails?.suspend_contract,
                type: requestDetails?.contract_type,
                endDate: requestDetails?.contract_end_date,
                contractStatus: requestDetails?.contract_status,
              });
            }}
          />
        ) : null}
      </>
    );
  };

  // display tooltip icon for archive action
  const displayTooltipIconForArchiveAction = () => {
    return (
      <Icon>
        <FontAwesomeIcon
          style={{
            verticalAlign: 'text-bottom',
            fontWeight: '300',
          }}
          className="ml-1 cursor "
          data-for="actionCol-desktop"
          data-tip="This is to archive approval requests that are no longer valid."
          data-html
          icon="fa-regular fa-circle-info"
        />
        <ReactTooltip
          className="w-10 text-left initialTextTransform"
          id="actionCol-desktop"
          aria-haspopup="true"
          place="bottom"
          effect="solid"
          html
          insecure
          isOpen
        />
      </Icon>
    );
  };

  // display archive action
  const displayArchiveAction = (requestDetails) => {
    const canRequestArchive = [
      'Approval Request Sent',
      'Addendum Approval Request Sent',
    ].includes(requestDetails?.request_status);
    return (
      <div
        role="presentation"
        style={{ color: canRequestArchive ? '#ff5933' : '#000' }}
        onClick={(e) => {
          e.stopPropagation();
          // https://bbe.atlassian.net/browse/PDV-9379
          if (canRequestArchive) {
            setShowArchiveConfirmationModal(true);
            setIdToArchiveRequest(requestDetails?.object_id);
          }
        }}
      >
        {canRequestArchive ? 'Archive' : 'N/A'}
      </div>
    );
  };

  // displat pending approval table headers
  const renderTableHeader = () => {
    return (
      <thead>
        <tr className={apiCallLoader ? 'disabled' : null}>
          {PendingApprovalsTableHeader.map((item) => {
            if (item.key === 'action' && !isShowActionCol) {
              return null;
            }
            return (
              <th key={item?.key} width={item.width} className={item.className}>
                {item.label}
                {item.key === 'action'
                  ? displayTooltipIconForArchiveAction()
                  : null}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  // displat pending approval table body
  const renderApprovalListTable = (requestDetails) => {
    const contractType = requestDetails?.contract_type?.toLowerCase();

    return (
      <tr
        key={requestDetails?.id}
        className="text-medium-font cursor"
        onClick={(e) => {
          e.stopPropagation();
          redirectToAgreementView(requestDetails);
        }}
      >
        <td>
          {requestDetails?.customer_name}
          <div className="small-text text-medium-font sub-label mt-1">
            {requestDetails?.bgs}
          </div>
        </td>
        <td>
          {agreementTypes?.[contractType]}
          <div className="small-text text-medium-font sub-label mt-1">
            {contractType?.includes('90') ? 'Recurring 90 Days' : ''}
          </div>
        </td>
        <td>
          {requestDetails?.requested_by}
          <div className="small-text text-medium-font sub-label mt-1">
            {displayRequestDate(requestDetails?.requested_at)}
          </div>
        </td>
        <td>{displayRequestTypeSummaryIcon(requestDetails)}</td>
        <td>
          {displayApprovalNeededFromValue(requestDetails?.approval_needed_from)}
        </td>
        <td>{displayRequestStatus(requestDetails?.request_status)}</td>
        {isShowActionCol ? (
          <td className="text-center">
            {displayArchiveAction(requestDetails)}
          </td>
        ) : null}
      </tr>
    );
  };

  // display pending approval table
  const displayPendingApprovalTable = () => {
    return (
      <div key="pending-approval-table">
        {renderTableHeader()}
        <tbody>
          {pendingApprovalListData?.length
            ? pendingApprovalListData.map((item) => {
                return renderApprovalListTable(item);
              })
            : null}
        </tbody>
      </div>
    );
  };

  // display pending approval mobile view
  const displayPendingApprovalMobileView = () => {
    return pendingApprovalListData?.map((requestDetails) => {
      const contractType = requestDetails?.contract_type?.toLowerCase();
      return (
        <WhiteCard
          key={requestDetails?.id}
          className="card-with-border mb-3"
          onClick={(e) => {
            e.stopPropagation();
            redirectToAgreementView(requestDetails);
          }}
        >
          <div className="row text-medium-font">
            <div className="col-6 mb-3">
              <div className="label">BP NAME</div>
              <div className="normal-text-black mb-1">
                {requestDetails?.customer_name}
              </div>
              <div className="text-small-gray text-medium-font">
                {requestDetails?.bgs}
              </div>
            </div>
            <div className="col-6 mb-3 text-right">
              <div className="label">CONTRACT TYPE</div>
              <div className="normal-text-black mb-1">
                {agreementTypes?.[contractType]}
              </div>
              <div className="text-small-gray text-medium-font">
                {contractType?.includes('90') ? 'Recurring 90 Days' : ''}
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="label">REQUESTED BY</div>
              <div className="normal-text-black mb-1">
                {requestDetails?.requested_by}
              </div>
              <div className="text-small-gray text-medium-font">
                {displayRequestDate(requestDetails?.requested_at)}
              </div>
            </div>
            <div className="col-6 mb-3 text-right">
              <div className="label">REQUEST TYPE</div>
              <div className="normal-text-black">
                {displayRequestTypeSummaryIcon(requestDetails)}
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="label">APPROVAL NEEDED FROM</div>
              <div className="normal-text-black">
                {displayApprovalNeededFromValue(
                  requestDetails?.approval_needed_from,
                )}
              </div>
            </div>
            {isShowActionCol ? (
              <div className="col-6 mb-3 text-right">
                <div className="label">
                  ACTION {displayTooltipIconForArchiveAction()}
                </div>
                <div className="normal-text-black">
                  {displayArchiveAction(requestDetails)}
                </div>
              </div>
            ) : null}

            <div className="col-8 mb-3">
              <div className="label">REQUEST STATUS</div>
              <div className="normal-text-black ">
                {displayRequestStatus(requestDetails?.request_status)}
              </div>
            </div>
          </div>
        </WhiteCard>
      );
    });
  };

  /* ********** Main return statement of this component ********** */
  return (
    <DesktopTable className="mt-0">
      {isDesktop
        ? displayPendingApprovalTable()
        : displayPendingApprovalMobileView()}
    </DesktopTable>
  );
};

export default PendingApprovalsDashboardView;

PendingApprovalsDashboardView.defaultProps = {
  apiCallLoader: false,
  pendingApprovalListData: [],
  setIdToArchiveRequest: () => {},
  setShowCancellationSummaryModal: () => {},
  setShowArchiveConfirmationModal: () => {},
};

PendingApprovalsDashboardView.propTypes = {
  apiCallLoader: bool,
  pendingApprovalListData: arrayOf(shape({})),
  setIdToArchiveRequest: func,
  setShowCancellationSummaryModal: func,
  setShowArchiveConfirmationModal: func,
};
