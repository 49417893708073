export function checkDisabledConditionForServices(
  data,
  serviceData,
  disabledLimit,
) {
  const findItemInFormData = data?.find((item) =>
    item?.service_id
      ? item?.service_id === serviceData
      : item?.service?.id === serviceData,
  );

  return findItemInFormData?.quantity
    ? parseInt(findItemInFormData?.quantity, 10) === disabledLimit
    : false;
}

export const checkDisabledConditionForListing = (formData, field) => {
  if (
    formData &&
    formData?.[field] === null &&
    (formData?.[field]
      ? parseInt(formData?.[field], 10) <= 0
      : formData?.[field] === null)
  ) {
    return true;
  }
  return false;
};
