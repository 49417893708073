// React imports
import React from 'react';

// Third party imports
import { arrayOf, bool, func, shape } from 'prop-types';

const StrandedInventoryMobileView = ({
  loader,
  strandedInventoryData,
  formatNumber,
  displayCompanyNameLogoAndBGS,
  redirectingToCustomerDetailview,
  displayMetricsChangeValueWithArrow,
}) => {
  // function - display metrics label with change value
  const displayMetricsLabelWithValue = (label, value, divClass, suffix) => {
    return (
      <div className={divClass}>
        <div className="label">{label}</div>
        <div className="label-info label-bold">
          {displayMetricsChangeValueWithArrow(
            value,
            formatNumber,
            'text-extra-medium',
            suffix,
          )}
        </div>
      </div>
    );
  };
  // display inventory mobile view
  const displayInventoryMobileView = () => {
    return strandedInventoryData?.map((item) => {
      return (
        <>
          <div className="straight-line horizontal-line mb-3" />
          <div
            role="presentation"
            onClick={() => {
              redirectingToCustomerDetailview(item?.customer_id);
            }}
          >
            {displayCompanyNameLogoAndBGS(item)}
            <div className="row">
              <div className="col-8 mb-3">
                <div className="label">Number of Stranded SKUs</div>
                <div className="label-info label-bold">
                  {formatNumber(item?.available, '', '', true)}
                </div>
              </div>
              {displayMetricsLabelWithValue(
                'Change',
                item?.change,
                'col-4 mb-3',
                '',
              )}
              {displayMetricsLabelWithValue(
                '% Change',
                item?.percent_change,
                'col-6 mb-3',
                '%',
              )}
            </div>
          </div>
        </>
      );
    });
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div className={loader ? 'mb-1 disabled' : 'mb-1'}>
      {displayInventoryMobileView()}
    </div>
  );
};

export default StrandedInventoryMobileView;

StrandedInventoryMobileView.defaultProps = {
  loader: false,
  strandedInventoryData: [],
  formatNumber: () => {},
  displayCompanyNameLogoAndBGS: () => {},
  redirectingToCustomerDetailview: () => {},
  displayMetricsChangeValueWithArrow: () => {},
};

StrandedInventoryMobileView.propTypes = {
  loader: bool,
  strandedInventoryData: arrayOf(shape({})),
  formatNumber: func,
  displayCompanyNameLogoAndBGS: func,
  redirectingToCustomerDetailview: func,
  displayMetricsChangeValueWithArrow: func,
};
