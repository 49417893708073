import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import _ from 'lodash';
import dayjs from 'dayjs';
import { components } from 'react-select';
import { bool, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import ReportSectionFilters from './ReportSectionFilters';
import StandardReportContainer from './StandardReports/StandardReportContainer';
import CustomizedReportContainer from './CustomizedReports/CustomizedReportContainer';
import Theme from '../../theme/Theme';
import CreateReportContainer from './CustomizedReports/CreateReport/CreateReportContainer';
import { hideHeader, showHeader } from '../../store/actions/userState';
import { BrandPartnerDashboard, DashboardCard } from '../../theme/Global';
import {
  getBPsAllMArketplaceList,
  getBPUserList,
  getCustomisedReportAccess,
  getReportTemplateList,
  getReportTypes,
} from '../../api';
import {
  WhiteCard,
  DropdownIndicator,
  CustomDateModal,
  GlobalNavbar,
  Tabs,
  InputSearchWithRadius,
} from '../../common';
import {
  bpAccountType,
  initalStateBP,
  initalStateTimeFrame,
  initialStateAccountType,
  initialStateReportType,
  reportTabOptions,
} from '../../constants';
import DashboardReportContainer from './DashboardReports/DashboardReportContainer';

function ReportsSectionContainer() {
  const mounted = useRef(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userState.userInfo);
  const customer = useSelector((state) => state.customerState.data);
  const customerId = customer?.id;
  const isCustomer = userInfo?.role?.includes('Customer');
  const isSuperAdmin = userInfo?.role?.includes('Super Admin');
  const { Option, SingleValue } = components;
  const [isUserAssignedToBP, setIsUserAssignedToBP] = useState(false);
  const [bpList, setBpList] = useState(null);
  const [marketplaceLoader, setMarketplaceLoader] = useState(false);
  const [bpLoader, setBpLoader] = useState(false);
  const [reportTypeLoader, setReportTypeLoader] = useState(false);
  const [isCustomDateApply, setIsCustomDateApply] = useState(false);
  const [showAdCustomDateModal, setShowAdCustomDateModal] = useState(false);
  const [isCreateReport, setIsCreateReport] = useState(false);
  const [selectedBp, setSelectedBp] = useState(initalStateBP);
  const [reportTypeOptions, setReportTypesOptions] = useState([]);
  const [marketplaceChoices, setMarketplaceChoices] = useState([]);
  const [marketplaceDefaultValue, setMarketplaceDefaultValue] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [isEditViewReport, setIsEditViewReport] = useState({});
  const [isUsedReportTemplate, setIsUsedReportTemplate] = useState(false);
  const [selectedReportTemplate, setSelectedReportTemplate] = useState(null);
  const [reportTemplateOption, setReportTemplateOption] = useState([]);
  const [selectedTimeFrame, setSelectedTimeFrame] =
    useState(initalStateTimeFrame);
  const [selectedAccountType, setAccountType] = useState(
    initialStateAccountType,
  );
  const [selectedReportType, setSelectedReportType] = useState(
    initialStateReportType,
  );
  const [selectedReportTab, setSelectedReportTab] = useState(
    isCustomer
      ? 'standard'
      : window.location.pathname.split('/').pop() || 'standard',
  );

  const currentDate = new Date();
  const [customDateState, setCustomDateState] = useState([
    {
      startDate: currentDate,
      endDate: currentDate,
      key: 'reportSection',
    },
  ]);
  const [selectedMarketplaceOption, setSelectedMarketplaceOption] = useState({
    label: 'Select Marketplace',
    value: 'select',
  });

  const generateCustomDate = useCallback((date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }, []);

  const getReportType = useCallback(() => {
    setReportTypeLoader(true);
    getReportTypes().then((res) => {
      if (mounted.current) {
        if (res && res.status === 200) {
          if (res?.data) {
            // https://bbe.atlassian.net/browse/PDV-9229 - hide seller health data for user other than super Admin
            const responseData = res?.data?.filter(
              (item) => item?.value !== 'seller_health',
            );

            const reportOptions = _.concat(
              [
                {
                  value: 'reportType',
                  label: 'Report Type',
                },
              ],
              isSuperAdmin ? res?.data : responseData,
            );
            setReportTypesOptions(reportOptions);
          } else {
            setReportTypesOptions([]);
          }
        }
        setReportTypeLoader(false);
      }
    });
  }, [isSuperAdmin]);

  // to set the customised tab as selected tab
  useEffect(() => {
    if (
      history.location.pathname === '/reports/customized-report' &&
      history.location.search === ''
    ) {
      setSelectedReportTab('customized');
    }
  }, [history.location.pathname, history.location.search]);

  // get the report types for standrard tab
  useEffect(() => {
    mounted.current = true;
    getReportType();
    return () => {
      mounted.current = false;
    };
  }, [getReportType]);

  // get the BP list to show in dropdwn
  const getBpList = useCallback((query) => {
    setBpLoader(true);
    getBPUserList(query).then((res) => {
      if (mounted.current) {
        if (res && res.status === 400) {
          setBpList([]);
          setBpLoader(false);
        }
        if (res && res.status === 200) {
          if (res?.data) {
            const list = [initalStateBP];
            if (res?.data?.length > 0)
              for (const option of res?.data) {
                list.push({
                  value: option.id,
                  label: option.company_name,
                  account_type: option.customer_account_type,
                });
              }
            setBpList(list);
            setBpLoader(false);
          } else {
            setBpList([]);
            setBpLoader(false);
          }
        } else {
          setBpList([]);
          setBpLoader(false);
        }
      }
    });
  }, []);

  // get the BP list API call
  useEffect(() => {
    mounted.current = true;
    if (!isCustomer) {
      getBpList();
    }

    return () => {
      mounted.current = false;
    };
  }, [getBpList, isCustomer]);

  // template list API
  const getTemplateList = useCallback((accountType) => {
    setBpLoader(true);
    getReportTemplateList(accountType).then((res) => {
      if (mounted.current) {
        if (res.status === 400) {
          setReportTemplateOption([]);
          setBpLoader(false);
        }
        if (res?.status === 200 && res?.data?.results) {
          setReportTemplateOption(res?.data?.results);
          setBpLoader(false);
        } else {
          setReportTemplateOption([]);
          setBpLoader(false);
        }
      }
    });
  }, []);

  // get the template according to the account type.
  useEffect(() => {
    mounted.current = true;

    if (selectedAccountType?.value !== 'accountType')
      getTemplateList(selectedAccountType.value);

    return () => {
      mounted.current = false;
    };
  }, [getTemplateList, selectedAccountType.value]);

  const getMarketPlace = useCallback((id) => {
    setMarketplaceLoader(true);
    let finalMarketplaces = [
      {
        value: 'all',
        label: 'All Marketplaces',
        currency: 'USD',
      },
    ];

    // get Marketplace List
    getBPsAllMArketplaceList(id).then((res) => {
      if (mounted.current) {
        if (res?.status === 200 && res?.data && res?.data?.length) {
          let tempMarketPlaceList = [];
          // Sort by Primary marketPlace & prefrence to US marketplace
          tempMarketPlaceList = res?.data.sort((marketplace) =>
            marketplace?.name === 'Amazon.com' && marketplace?.is_primary
              ? -1
              : marketplace?.name === 'Amazon.com'
              ? -1
              : 1,
          );

          // set Marketplace dropdown format
          for (const option of tempMarketPlaceList) {
            finalMarketplaces.push({
              value: option.name,
              label: option.country_currency.country,
              currency: option.country_currency.currency,
              isActive: option?.is_active, // PDV-7818 - handeling of active and inactive marketplaces
            });
          }

          if (finalMarketplaces.length === 2) {
            finalMarketplaces = finalMarketplaces.filter(
              (item) => item.value !== 'all',
            );
          }

          const initialMarketplace =
            finalMarketplaces.length > 1
              ? finalMarketplaces[1]
              : finalMarketplaces[0];
          setMarketplaceDefaultValue(initialMarketplace);
          setSelectedMarketplaceOption(initialMarketplace);
          setMarketplaceChoices(finalMarketplaces);
        } else {
          setSelectedMarketplaceOption({
            label: 'Select Marketplace',
            value: 'select',
          });
          setMarketplaceDefaultValue(null);
          setMarketplaceChoices([]);
        }
        setMarketplaceLoader(false);
      }
    });
  }, []);

  // useeefect to set the marketplace
  useEffect(() => {
    mounted.current = true;
    // for BP view
    if (isCustomer) {
      getMarketPlace(customerId);
    }

    // for inital report lists impact on getReportsData api
    if (!isCustomer && selectedBp?.value === 'Select-BP') {
      setMarketplaceDefaultValue(null);
      setSelectedMarketplaceOption({
        label: 'Select Marketplace',
        value: 'select',
      });
      setMarketplaceChoices([]);
    }

    return () => {
      mounted.current = false;
    };
  }, [customerId, getMarketPlace, isCustomer, selectedBp]);

  // getting permission to create report
  const getCustomerMemberList = useCallback(() => {
    if (selectedBp?.value !== 'Select-BP') {
      getCustomisedReportAccess(selectedBp?.value)
        .then((res) => {
          if (res?.status === 200) {
            setIsUserAssignedToBP(res.data);
          } else {
            setIsUserAssignedToBP(false);
          }
        })
        .catch(() => {
          setIsUserAssignedToBP(false);
        });
    }
  }, [selectedBp]);

  useEffect(() => {
    getCustomerMemberList();
  }, [getCustomerMemberList]);

  const handleBPList = (event) => {
    if (event?.value !== selectedBp?.value) {
      setSelectedBp(event);
      const value = bpAccountType[event?.account_type]?.[0];
      if (event.value === 'Select-BP') {
        setAccountType(initialStateAccountType);
      } else {
        setAccountType(value);
      }
      // for intternal user
      if (!isCustomer && event?.value !== 'Select-BP') {
        getMarketPlace(event?.value);
      }
    }
  };

  const handleAccountType = (event) => {
    if (event.value !== selectedAccountType.value) {
      setAccountType(event);
      setSelectedReportTemplate(null);
      setIsUsedReportTemplate(false);
    }
  };

  const handleReportType = (event) => {
    if (event?.value !== selectedReportType?.value) {
      setSelectedReportType(event);
      if (event?.value === 'finance') {
        // 28/12/2023 - https://bbe.atlassian.net/browse/PDV-8442 - by default, all finance reports will be available for download is MTD filter is selected.
        setSelectedTimeFrame({ value: 'month', label: 'Month to Date' });
        setIsCustomDateApply(false);
      }
    }
  };

  // handle time frame filter event
  const handleTimeFrame = (event) => {
    const { value } = event;
    setIsCustomDateApply(false);
    if (value !== selectedTimeFrame?.value) {
      setSelectedTimeFrame(event);
      setIsCustomDateApply(false);
      if (value !== 'custom') {
        setCustomDateState([
          {
            startDate: currentDate,
            endDate: currentDate,
            key: 'reportSection',
          },
        ]);
      }
    }
    if (value === 'custom') {
      setShowAdCustomDateModal(true);
    }
  };

  const handleMarketplace = (event) => {
    if (event.value !== selectedMarketplaceOption?.value) {
      setSelectedMarketplaceOption(event);
    }
  };

  const applyCustomDate = () => {
    setIsCustomDateApply(true);
    setShowAdCustomDateModal(false);
  };

  const handleTab = (item) => {
    setSearchQuery('');
    setSelectedReportTab(item.key);

    if (!isCustomer) history.push(`/reports/${item.key}`);
  };

  const handleResetFilter = () => {
    if (!isCustomer) {
      setSelectedBp(initalStateBP);
      setSelectedMarketplaceOption({
        label: 'Select Marketplace',
        value: 'select',
      });
      setMarketplaceDefaultValue(null);
      setMarketplaceChoices([]);
    }
    if (selectedReportTab === 'standard') {
      setSelectedTimeFrame(initalStateTimeFrame);
      setSelectedReportType(initialStateReportType);
    } else {
      setAccountType(initialStateAccountType);
    }
    setIsUsedReportTemplate(false);
    setSelectedReportTemplate(null);
  };

  const handleSearch = (event) => {
    if (event?.target?.value && selectedReportTab !== 'standard') {
      handleResetFilter();
    }
    setSearchQuery(event?.target?.value);
  };

  // display selected custom date and month range as sub label
  const renderCustomDateSubLabel = (props) => {
    if (selectedTimeFrame?.value === 'custom' && isCustomDateApply) {
      return `From- ${dayjs(customDateState?.[0]?.startDate).format(
        'MMM D, YYYY',
      )}  To - ${dayjs(customDateState?.[0]?.endDate).format('MMM D, YYYY')}`;
    }
    return props?.data?.sub;
  };

  // time frame filter options
  const timeFrameFilterOption = (props) => {
    // https://bbe.atlassian.net/browse/PDV-8442 - Enabling the MTD option for finance report
    return (
      <div
        className={
          selectedReportType?.value === 'finance'
            ? ['month', 'custom', 'year'].includes(props?.data?.value)
              ? ''
              : 'disabled'
            : ''
        }
      >
        <Option {...props}>
          <div>
            <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
            <div style={{ fontSize: '10px', color: '#556178' }}>
              {props?.data?.sub}
            </div>
          </div>
        </Option>
      </div>
    );
  };

  // time frame filter single value
  const singleTimeFrameFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
      <div style={{ fontSize: '10px', color: Theme.gray35 }}>
        {selectedReportType?.value === 'account_audit'
          ? ''
          : renderCustomDateSubLabel(props)}
      </div>
    </SingleValue>
  );

  // time frame filter components
  const getTimeFrameSelectComponents = () => {
    return {
      Option: timeFrameFilterOption,
      SingleValue: singleTimeFrameFilterOption,
      DropdownIndicator,
    };
  };

  const displayHeader = () => {
    return (
      <div className="dashboard-header-sticky d-none">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <p className="black-heading-title ml-1 pt-1">Reports</p>
            </div>
            <div className="straight-line horizontal-line  d-lg-none d-md-block" />
          </div>
        </div>
      </div>
    );
  };

  // display resport section filters
  const displayReportSectionFilters = () => {
    return (
      <ReportSectionFilters
        bpList={bpList}
        handleBPList={handleBPList}
        isApiCall={marketplaceLoader}
        selectedBp={selectedBp}
        setBpList={setBpList}
        handleTimeFrame={handleTimeFrame}
        selectedTimeFrame={selectedTimeFrame}
        getBpList={getBpList}
        setSelectedBp={setSelectedBp}
        getTimeFrameSelectComponents={getTimeFrameSelectComponents}
        marketplaceChoices={marketplaceChoices}
        marketplaceDefaultValue={marketplaceDefaultValue}
        isCustomer={isCustomer}
        handleMarketplace={handleMarketplace}
        marketplaceLoader={marketplaceLoader}
        handleAccountType={handleAccountType}
        selectedAccountType={selectedAccountType}
        reportTypeLoader={reportTypeLoader}
        reportTypeOptions={reportTypeOptions}
        selectedReportType={selectedReportType}
        selectedReportTab={selectedReportTab}
        handleReportType={handleReportType}
        isUserAssignedToBP={isUserAssignedToBP}
        isUsedReportTemplate={isUsedReportTemplate}
        setIsUsedReportTemplate={setIsUsedReportTemplate}
        selectedReportTemplate={selectedReportTemplate}
        setSelectedReportTemplate={setSelectedReportTemplate}
        reportTemplateOption={reportTemplateOption}
        handleResetFilter={handleResetFilter}
        onCreateReport={() => {
          setIsCreateReport(true);
          dispatch(hideHeader());
          history.push(
            `customized-report?customer=${selectedBp?.value}&report=new`,
          );
        }}
        bpLoader={bpLoader}
        selectedMarketplaceOption={selectedMarketplaceOption}
      />
    );
  };

  // display report section header
  const displayReportSectionHeader = () => {
    return (
      <div className="horizontal-line straight-line mb-3">
        <div className="row">
          <div className="col-md-7 col-12">
            <div className="justify-space-between ">
              <Tabs className="mt-3">
                <ul className="tabs scrollable-container border-none">
                  {reportTabOptions?.map((item) => {
                    return (
                      <Fragment key={item.key}>
                        {item.key === 'standard' && isCustomer ? (
                          <li
                            key={item.key}
                            className={
                              selectedReportTab === item.key
                                ? 'selected scrollable-tab pt-3'
                                : 'scrollable-tab pt-3'
                            }
                            onClick={() => handleTab(item)}
                            role="presentation"
                          >
                            {item.label}
                          </li>
                        ) : !isCustomer ? (
                          <li
                            key={item.key}
                            className={`${isSuperAdmin ? '' : item?.class} 
                              ${
                                selectedReportTab === item.key
                                  ? 'selected scrollable-tab pt-3'
                                  : 'scrollable-tab pt-3'
                              }`}
                            onClick={() => handleTab(item)}
                            role="presentation"
                          >
                            {item.label}
                          </li>
                        ) : null}
                      </Fragment>
                    );
                  })}
                </ul>
              </Tabs>
            </div>
          </div>
          {selectedReportTab === 'summary' ? null : (
            <div className="col-md-5 col-12 mt-md-0 mt-3 pt-3">
              <InputSearchWithRadius className=" w-sm-100  mb-2 ">
                <DebounceInput
                  // minLength={3}
                  debounceTimeout={600}
                  className=" form-control search-filter form-control-info max-content"
                  placeholder={
                    selectedReportTab === 'standard'
                      ? 'Search'
                      : 'Search By BP Name, Title or Description'
                  }
                  onChange={(event) => {
                    handleSearch(event);
                  }}
                  value={searchQuery || ''}
                />
                <FontAwesomeIcon
                  icon="fa-regular fa-magnifying-glass"
                  className="search-icon"
                />
                {searchQuery !== '' ? (
                  <FontAwesomeIcon
                    icon="fal fa-times"
                    className="close-icon cursor"
                    onClick={() => setSearchQuery('')}
                  />
                ) : null}
              </InputSearchWithRadius>
            </div>
          )}
        </div>
      </div>
    );
  };

  // render Dashboard Report Container
  const renderDashboardReportContainer = () => {
    return <DashboardReportContainer />;
  };

  // render Standard Report Container
  const renderStandardReportContainer = () => {
    const selectedCustomDate = customDateState;

    return (
      <StandardReportContainer
        selectedBp={selectedBp?.value}
        selectedTimeFrame={selectedTimeFrame}
        generateCustomDate={generateCustomDate}
        isCustomDateApply={isCustomDateApply}
        selectedMarketplaceOption={selectedMarketplaceOption?.value}
        customDateState={selectedCustomDate}
        selectedReportType={selectedReportType}
        searchQuery={searchQuery}
      />
    );
  };

  // render customized Report Container
  const renderCustomizedReportContainer = () => {
    return (
      <CustomizedReportContainer
        selectedBp={selectedBp?.value}
        isUserAssignedToBP={isUserAssignedToBP}
        searchQuery={searchQuery}
        accountType={selectedAccountType}
        marketplace={selectedMarketplaceOption}
        onEditViewReport={(
          id,
          customerID,
          customerName,
          status,
          url,
          reportName,
        ) => {
          dispatch(hideHeader());
          setIsEditViewReport({
            reportId: id,
            showCustomReport: true,
            customerId: customerID,
            customerName,
            reportStatus: status,
            pdfURL: url,
            reportName,
          });
          history.push(`customized-report?customer=${customerID}&report=${id}`);
        }}
      />
    );
  };

  // render create report component
  const renderCreateReportContainer = () => {
    return (
      <CreateReportContainer
        accType={selectedAccountType?.value}
        marketplace={selectedMarketplaceOption}
        customReportStatus={
          isEditViewReport?.reportStatus
            ? isEditViewReport?.reportStatus
            : 'new'
        }
        onCloseReport={() => {
          dispatch(showHeader());
          setIsEditViewReport();
          setIsCreateReport(false);
        }}
        customerId={
          isEditViewReport?.showCustomReport
            ? isEditViewReport?.customerId
            : selectedBp?.value
        }
        customerName={
          isEditViewReport?.showCustomReport
            ? isEditViewReport?.customerName
            : selectedBp?.label
        }
        reportId={isEditViewReport?.reportId}
        pdfURL={isEditViewReport?.pdfURL}
        customReportPDFName={isEditViewReport?.reportName}
        selectedReportTemplate={selectedReportTemplate}
      />
    );
  };

  // render custom date modal
  const renderCustomDateModal = () => {
    return (
      <CustomDateModal
        id="BT-ReportsSectionDashboard-daterange"
        isOpen={showAdCustomDateModal}
        range={customDateState}
        onClick={() => {
          setShowAdCustomDateModal(false);
          setCustomDateState([
            {
              startDate: currentDate,
              endDate: currentDate,
              key: 'reportSection',
            },
          ]);
        }}
        onChange={(item) => {
          setCustomDateState([item?.reportSection]);
        }}
        onApply={() => applyCustomDate()}
        currentDate={currentDate}
        minDate={new Date('01/01/1901')}
      />
    );
  };

  return (
    <>
      {!isCustomer && !isCreateReport && !isEditViewReport?.showCustomReport ? (
        <GlobalNavbar />
      ) : null}

      {isCreateReport || isEditViewReport?.showCustomReport ? (
        <Switch>
          <Route path="/reports/customized-report">
            {renderCreateReportContainer()}
          </Route>
        </Switch>
      ) : (
        <BrandPartnerDashboard className={isCustomer ? 'col-12' : ''}>
          {!isCustomer ? displayHeader() : null}
          <DashboardCard className={isCustomer ? 'pt-0' : ''}>
            <div className={!isCustomer ? 'dashboard-container-body' : ''}>
              <div className={!isCustomer ? 'row mt-3' : ''}>
                {selectedReportTab === 'summary' ? null : (
                  <div
                    className={!isCustomer ? 'col-xl-2 col-lg-3 col-md-12' : ''}
                  >
                    {displayReportSectionFilters()}
                  </div>
                )}
                <div
                  className={
                    !isCustomer
                      ? selectedReportTab === 'summary'
                        ? 'col-xl-12 col-lg-12 col-md-12'
                        : 'col-xl-10 col-lg-9 col-md-12'
                      : null
                  }
                >
                  <WhiteCard className="mb-3">
                    {displayReportSectionHeader()}

                    <div className="row">
                      <div className="col-md-12 ">
                        {isCustomer && selectedReportTab === 'standard'
                          ? renderStandardReportContainer()
                          : null}
                        <Switch>
                          <Route exact path="/reports/standard">
                            {renderStandardReportContainer()}
                          </Route>
                          <Route path="/reports/customized">
                            {renderCustomizedReportContainer()}
                          </Route>
                          <Route path="/reports/summary">
                            {renderDashboardReportContainer()}
                          </Route>
                          <Route
                            path="/reports"
                            render={() => (
                              <Redirect to={`/reports/${selectedReportTab}`}>
                                {renderStandardReportContainer()}
                              </Redirect>
                            )}
                          />
                        </Switch>
                      </div>
                    </div>
                  </WhiteCard>
                </div>
              </div>
            </div>
            {renderCustomDateModal()}
          </DashboardCard>
        </BrandPartnerDashboard>
      )}
    </>
  );
}

export default ReportsSectionContainer;

ReportsSectionContainer.defaultProps = {
  isSelected: true,
};

ReportsSectionContainer.propTypes = {
  isSelected: bool,
};

ReportsSectionContainer.defaultProps = { data: {} };

ReportsSectionContainer.propTypes = {
  data: shape({ sub: string, label: string }),
};
