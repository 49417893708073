import React, { useState, useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import Select, { components } from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { arrayOf, shape, string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../../../../theme/Theme';
import InventoryManagementInfoIcon from './InventoryManagementInfoIcon';
import { IventoryTableWrapper } from './InventoryStyle';
import {
  getEstimatedLostSalesAPIData,
  postAsinArchiveAndRestoreAPIData,
} from '../../../../api';
import {
  AddThousandSeparator,
  CommonPagination,
  CustomDropDown,
  DesktopTable,
  DropdownIndicator,
  GrayMessageBar,
  NoData,
  PageLoader,
  Tabs,
  WhiteCard,
} from '../../../../common';
import {
  estimatedLostSalesDateFilterOptions,
  estimatedLostSalesStatusFilterOptions,
  estimatedLostSalesTableHeaderConstants,
  estimatedLostSalesTabsConstants,
} from '../../../../constants';

export default function EstimatedLostSales({
  customerId,
  currencySymbol,
  isMST12to10AM,
  noDataMessage,
  selectedMarketplace,
  estimatedLostSalesReasonList,
}) {
  const dashboardDynamicError = useSelector(
    (newState) => newState.userState?.showDynamicMsg,
  );
  const ELSDynamicMessage =
    dashboardDynamicError?.inventory?.estimated_lost_sales;

  const { Option, SingleValue } = components;

  const [selectedReason, setSelectedReason] = useState(
    estimatedLostSalesReasonList?.[0],
  );
  const [selectedStatus, setSelectedStatus] = useState(
    estimatedLostSalesStatusFilterOptions?.[0],
  );
  const [dateType, setDateType] = useState(
    estimatedLostSalesDateFilterOptions?.[0],
  );
  const [estimatedLostSalesTab, setEstimatedLostSalesTab] = useState(
    estimatedLostSalesTabsConstants?.[0],
  );
  const [sortedBy, setSortedBy] = useState({
    key: 'estimated_lost_sales',
    flag: true,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [tableDataCount, setTableDataCount] = useState(0);
  const [estimatedLostSalesTableData, setEstimatedLostSalesTableData] =
    useState([]);
  const [estimatedLostSalesMetricsData, setEstimatedLostSalesMetricsData] =
    useState({
      totalAsins: null,
      totalFees: null,
    });
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [downloadReportLoader, setDownloadReportLoader] = useState(false);

  // use callback fo GET long term storage fees data
  const bindEstimatedLostSalesData = useCallback(
    (apiData) => {
      let tempMetricsData = { totalAsins: null, totalFees: null };
      const tempTableData = [];
      const apiTableData = apiData?.results;

      if (!isMST12to10AM) {
        tempMetricsData = {
          totalAsins: apiData?.total_asins ? apiData.total_asins : null,
          totalSales: apiData?.total_estimate_lost_sales
            ? apiData.total_estimate_lost_sales
            : null,
        };

        if (apiTableData?.length) {
          for (const item of apiTableData) {
            tempTableData.push({
              asin: item?.asin ? item.asin : null,
              sku: item?.sku ? item.sku : null,
              productName: item?.product_name ? item?.product_name : null,
              reason: item?.reason ? item.reason : null,
              lastSoldDate: item?.last_sold_date
                ? dayjs(item.last_sold_date).format('MM/DD/YYYY')
                : 'N/A',
              inactiveDayscount: item?.inactive_day_count,
              strandedSince: item?.stranded_since
                ? dayjs(item.stranded_since).format('MM/DD/YYYY')
                : 'N/A',
              status: item?.status ? item.status : null,
              totalSales: item?.total_sales ? item.total_sales : null,
              avgDailySales: item?.average_daily_sales
                ? item.average_daily_sales
                : null,
              estLostSales: item?.estimated_lost_sales
                ? item.estimated_lost_sales
                : null,
            });
          }
        }
      }
      return { tableData: tempTableData, metricsData: tempMetricsData };
    },
    [isMST12to10AM],
  );

  // use callback fo GET long term storage fees data
  const getEstimatedLostSalesData = useCallback(
    (marketplace, selectedTab, orderBy, date, reason, status, page) => {
      setIsDataLoading(true);
      setEstimatedLostSalesTableData([]);
      setEstimatedLostSalesMetricsData({});
      setTableDataCount(0);

      const orderByKey = orderBy?.flag ? `-${orderBy?.key}` : orderBy?.key;

      getEstimatedLostSalesAPIData(
        customerId,
        marketplace,
        selectedTab,
        orderByKey,
        date,
        reason,
        status,
        page,
      ).then((response) => {
        if (response?.status === 200) {
          const getData = bindEstimatedLostSalesData(response?.data);
          setEstimatedLostSalesTableData(getData?.tableData);
          setEstimatedLostSalesMetricsData(getData?.metricsData);
          setTableDataCount(response?.data?.count || 0);
        } else {
          setEstimatedLostSalesTableData([]);
          setEstimatedLostSalesMetricsData({});
          setTableDataCount(0);

          setIsDataLoading(false);
        }
        setIsDataLoading(false);
      });
    },
    [bindEstimatedLostSalesData, customerId],
  );

  // use effect
  useEffect(() => {
    if (selectedMarketplace && selectedMarketplace?.value !== '') {
      const orderBy = {
        key: 'estimated_lost_sales',
        flag: true,
      };
      setEstimatedLostSalesTab({ label: 'Inactive', value: 'inactive' });
      if (!isMST12to10AM) {
        getEstimatedLostSalesData(
          selectedMarketplace,
          estimatedLostSalesTabsConstants?.[0]?.value,
          orderBy,
          estimatedLostSalesDateFilterOptions?.[0]?.value,
          null,
          estimatedLostSalesStatusFilterOptions?.[0]?.value,
          1,
        );
      }
    }
  }, [getEstimatedLostSalesData, isMST12to10AM, selectedMarketplace]);

  // const handle download report events
  const handleDownloadReportEvents = () => {
    setDownloadReportLoader(true);
    const orderByKey = sortedBy?.flag ? `-${sortedBy?.key}` : sortedBy?.key;

    getEstimatedLostSalesAPIData(
      customerId,
      selectedMarketplace,
      estimatedLostSalesTab?.value,
      orderByKey,
      dateType?.value,
      selectedReason?.value,
      selectedStatus?.value,
      pageNumber,
      'downloadReport',
    ).then((response) => {
      if (response?.status === 200) {
        const csvLink = response?.data?.url;
        if (csvLink) window.open(csvLink);
        if (response?.data?.message) toast.success(response?.data?.message);
        setDownloadReportLoader(false);
      } else {
        toast.error('Oops! Something went wrong...');
        setDownloadReportLoader(false);
      }
    });
  };

  // handle archive and restore asins events
  const handleAsinArchiveAndRestoreEvents = (asin, sku, status, action) => {
    setIsDataLoading(true);

    const activeTab = estimatedLostSalesTab?.value;
    const asinStatus =
      activeTab === 'inactive'
        ? 'inactive'
        : activeTab === 'stranded'
        ? 'stranded'
        : status;
    const actionLabel =
      action === 'archive'
        ? 'ARCHIVED'
        : action === 'restore'
        ? 'RESTORED'
        : 'Updated';

    postAsinArchiveAndRestoreAPIData(
      customerId,
      asin,
      sku,
      asinStatus,
      action,
    ).then((response) => {
      if (response?.status === 200) {
        toast.success(`ASIN ${actionLabel} Successfully`);
        getEstimatedLostSalesData(
          selectedMarketplace,
          estimatedLostSalesTab?.value,
          sortedBy,
          dateType?.value,
          selectedReason?.value,
          selectedStatus?.value,
          pageNumber,
        );
      } else {
        toast.error(response?.data?.detail);
        setIsDataLoading(false);
      }
    });
  };

  // handle reason filter events
  const handleReasonFilterEvents = (event) => {
    const value = event?.value;
    setSelectedReason(event);
    setPageNumber(1);

    getEstimatedLostSalesData(
      selectedMarketplace,
      estimatedLostSalesTab?.value,
      sortedBy,
      dateType?.value,
      value,
      selectedStatus?.value,
      1,
    );
  };

  // handle status filter events
  const handleStatusFilterEvents = (event) => {
    const value = event?.value;
    setSelectedStatus(event);
    setPageNumber(1);

    getEstimatedLostSalesData(
      selectedMarketplace,
      estimatedLostSalesTab?.value,
      sortedBy,
      dateType?.value,
      selectedReason?.value,
      value,
      1,
    );
  };

  // handle date filter events
  const handleDateFilterEvents = (event) => {
    const value = event?.value;
    setDateType(event);
    setPageNumber(1);

    getEstimatedLostSalesData(
      selectedMarketplace,
      estimatedLostSalesTab?.value,
      sortedBy,
      value,
      selectedReason?.value,
      selectedStatus?.value,
      1,
    );
  };

  // handle ELS tab change events
  const handleELSTabChangeEvents = (item) => {
    const value = item?.value;
    setPageNumber(1);
    setEstimatedLostSalesTab(item);
    setSelectedReason(estimatedLostSalesReasonList?.[0]);
    setSelectedStatus(estimatedLostSalesStatusFilterOptions?.[0]);
    const orderBy = {
      key: value === 'archive' ? 'status' : 'estimated_lost_sales',
      flag: true,
    };
    setSortedBy(orderBy);

    getEstimatedLostSalesData(
      selectedMarketplace,
      value,
      orderBy,
      dateType?.value,
      estimatedLostSalesReasonList?.[0]?.value,
      estimatedLostSalesStatusFilterOptions?.[0]?.value,
      1,
    );
  };

  // handle sales data sorting
  const handleELSDataSortingEvents = (value) => {
    let sortingValue = { key: value, flag: false };

    if (value === sortedBy?.key) {
      setSortedBy({ key: value, flag: !sortedBy?.flag });
      sortingValue = { key: value, flag: !sortedBy?.flag };
    } else {
      setSortedBy({ key: value, flag: true });
      sortingValue = { key: value, flag: true };
    }
    setPageNumber(1);

    getEstimatedLostSalesData(
      selectedMarketplace,
      estimatedLostSalesTab?.value,
      sortingValue,
      dateType?.value,
      selectedReason?.value,
      selectedStatus?.value,
      1,
    );
  };

  // handle pagination events
  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);

    getEstimatedLostSalesData(
      selectedMarketplace,
      estimatedLostSalesTab?.value,
      sortedBy,
      dateType?.value,
      selectedReason?.value,
      selectedStatus?.value,
      currentPage,
    );
  };

  // configuring the date filter components - single value
  const getELSDateFilterOptions = (props) => {
    return (
      <Option {...props}>
        <div>
          <span style={{ fontSize: '14px', fontWeight: '500' }}>
            {props.data.label}
          </span>
        </div>
      </Option>
    );
  };

  // configuring the date filter components - option list
  const getELSDateFilterValue = (props) => {
    return (
      <SingleValue {...props}>
        <span style={{ fontSize: '14px' }}>{props.data.label}</span>
      </SingleValue>
    );
  };

  const getELSDateFilterComponents = () => {
    return {
      SingleValue: getELSDateFilterValue,
      Option: getELSDateFilterOptions,
      DropdownIndicator,
    };
  };

  // render reason filter
  const renderReasonFilter = () => {
    return (
      <CustomDropDown
        id="BT-ELS-reason-filter"
        className={`cursor ${isDataLoading ? 'disabled' : ''}`}
      >
        <Select
          classNamePrefix="react-select"
          className="active"
          isSearchable={false}
          value={selectedReason}
          placeholder={
            selectedReason?.label ? selectedReason.label : 'Select...'
          }
          options={estimatedLostSalesReasonList}
          components={getELSDateFilterComponents()}
          onChange={(event) => handleReasonFilterEvents(event)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
        />
      </CustomDropDown>
    );
  };

  // render date filter
  const renderELSDateFilter = () => {
    return (
      <CustomDropDown
        id="BT-ELS-date-filter"
        className={`cursor ${isDataLoading ? 'disabled' : ''}`}
      >
        <Select
          classNamePrefix="react-select"
          className="active"
          isSearchable={false}
          value={dateType}
          defaultValue={dateType}
          placeholder={dateType?.label ? dateType.label : 'Select...'}
          options={estimatedLostSalesDateFilterOptions}
          components={getELSDateFilterComponents()}
          onChange={(event) => handleDateFilterEvents(event)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
        />
      </CustomDropDown>
    );
  };

  // render archive status filter
  const renderArchiveStatusFilter = () => {
    return (
      <CustomDropDown
        id="BT-ELS-status-filter"
        className={`cursor ${isDataLoading ? 'disabled' : ''}`}
      >
        <Select
          classNamePrefix="react-select"
          className="active"
          isSearchable={false}
          value={selectedStatus}
          placeholder={
            selectedStatus?.label ? selectedStatus.label : 'Select...'
          }
          options={estimatedLostSalesStatusFilterOptions}
          components={getELSDateFilterComponents()}
          onChange={(event) => handleStatusFilterEvents(event)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
        />
      </CustomDropDown>
    );
  };

  // render download report button
  const renderDownloadReportButton = () => {
    return (
      <div className="cursor mt-2" role="button">
        <p
          className={`black-heading-title text-medium-font m-0 cursor ${
            isDataLoading ? 'disabled' : ''
          }`}
          role="presentation"
          onClick={() => {
            handleDownloadReportEvents();
          }}
        >
          {downloadReportLoader ? (
            <PageLoader
              component="stock-download-report"
              type="button"
              color={Theme.orange}
            />
          ) : (
            <>
              <FontAwesomeIcon
                className="mr-2"
                color={Theme.orange}
                icon="far fa-arrow-to-bottom"
              />
              Download Report
            </>
          )}
        </p>
      </div>
    );
  };

  // render Estimated Lost Sales Filters
  const renderEstimatedLostSalesFilters = () => {
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-6">
            <div className="row">
              <div className="col-6 col-md-12 pr-0">
                <div className="black-heading-title mt-2">
                  Estimated Lost Sales
                  <InventoryManagementInfoIcon infoIconId="estimated_lost_sales_table" />
                </div>
              </div>
              <div className="col-6 col-md-12 pl-1 d-md-none text-right">
                {renderDownloadReportButton()}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <div className="row">
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                {estimatedLostSalesTab?.value === 'stranded'
                  ? renderReasonFilter()
                  : null}
                {estimatedLostSalesTab?.value === 'archive'
                  ? renderArchiveStatusFilter()
                  : null}
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0 pl-md-0">
                {renderELSDateFilter()}
              </div>
            </div>
          </div>
        </div>
        {ELSDynamicMessage ? (
          <GrayMessageBar message={ELSDynamicMessage} className="my-3" />
        ) : null}
      </>
    );
  };

  // render Estimated Lost Sales Metrics Box
  const renderEstimatedLostSalesMetricsBox = (
    heading,
    value,
    prefix,
    suffix,
    postValueLabel,
  ) => {
    return (
      <fieldset className="shape-without-border">
        <div className="text-bold label-heading mb-3">{heading}</div>
        <h3 className="small-title-heading gray-text text-medium-font">
          {value !== null && value !== undefined ? (
            <>
              <AddThousandSeparator
                value={value}
                prefix={prefix}
                suffix={suffix}
                showPlusSign={false}
              />
              &nbsp;
              {postValueLabel}
            </>
          ) : (
            'N/A'
          )}
        </h3>
      </fieldset>
    );
  };

  // render Estimated Lost Sales Metrics
  const renderEstimatedLostSalesMetrics = () => {
    return (
      <div className="row mt-3">
        <div className="col-12 col-md-6">
          {renderEstimatedLostSalesMetricsBox(
            'Number of ASINs in the table',
            estimatedLostSalesMetricsData.totalAsins,
            '',
            '',
            estimatedLostSalesMetricsData.totalAsins > 1 ? 'ASINs' : 'ASIN',
          )}
        </div>
        <div className="col-12 col-md-6 mt-3 mt-md-0 pl-md-0">
          {renderEstimatedLostSalesMetricsBox(
            'Total estimated lost sales',
            estimatedLostSalesMetricsData.totalSales,
            currencySymbol,
            '',
            '',
          )}
        </div>
      </div>
    );
  };

  // render Estimated Lost Sales Tabs
  const renderEstimatedLostSalesTabs = () => {
    return (
      <div className="justify-space-between mt-4">
        <Tabs>
          <ul className="tabs border-none scrollable-container">
            {estimatedLostSalesTabsConstants?.map((item) => {
              return (
                <li
                  className={`cursor ${
                    item?.value === estimatedLostSalesTab?.value ? 'active' : ''
                  } ${isDataLoading ? 'disabled' : ''}`}
                  role="presentation"
                  onClick={() => {
                    handleELSTabChangeEvents(item);
                  }}
                >
                  {item?.label}
                </li>
              );
            })}
          </ul>
        </Tabs>
        <div className="d-none d-md-block">{renderDownloadReportButton()}</div>
      </div>
    );
  };

  // // render Estimated Lost Sales Table head
  const renderEstimatedLostSalesTableHead = () => {
    let tableHeader = [];
    if (estimatedLostSalesTab?.value === 'inactive') {
      tableHeader = estimatedLostSalesTableHeaderConstants?.inactiveTableHeader;
    }

    if (estimatedLostSalesTab?.value === 'stranded') {
      tableHeader = estimatedLostSalesTableHeaderConstants?.strandedTableHeader;
    }

    if (estimatedLostSalesTab?.value === 'archive') {
      tableHeader = estimatedLostSalesTableHeaderConstants?.archiveTableHeader;
    }

    return (
      <thead>
        <tr className="gray-border-radius">
          {tableHeader?.map((item) => {
            return (
              <th width={item?.width}>
                <span
                  className="cursor"
                  role="presentation"
                  onClick={() => {
                    if (item?.sorting) {
                      handleELSDataSortingEvents(item?.value);
                    }
                  }}
                >
                  {item?.label}
                  {item?.info ? (
                    <FontAwesomeIcon
                      className="ml-2"
                      color={Theme.black}
                      icon="fal fa-info-circle"
                    />
                  ) : null}
                  {item?.tooltip ? (
                    <>
                      <FontAwesomeIcon
                        color={Theme.gray40}
                        icon="far fa-question-circle"
                        style={{
                          marginLeft: '4px',
                          cursor: 'pointer',
                          fontSize: '12px',
                        }}
                        data-tip={item?.tooltipMessage}
                        data-for={item?.value}
                      />
                      <ReactTooltip
                        id={item?.value}
                        aria-haspopup="true"
                        place="top"
                        effect="solid"
                      />
                    </>
                  ) : null}
                  {item?.sorting ? (
                    <FontAwesomeIcon
                      className="ml-2 cursor"
                      color={
                        sortedBy?.key === item?.value
                          ? Theme.orange
                          : Theme.gray40
                      }
                      icon={`far ${
                        sortedBy?.key === item?.value && sortedBy?.flag
                          ? 'fa-chevron-up'
                          : 'fa-chevron-down'
                      }`}
                      style={{ fontSize: '12px' }}
                    />
                  ) : null}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  // render Estimated Lost Sales Table body
  const renderEstimatedLostSalesTableBody = () => {
    const inactiveTab = estimatedLostSalesTab?.value === 'inactive';
    const strandedTab = estimatedLostSalesTab?.value === 'stranded';
    const archiveTab = estimatedLostSalesTab?.value === 'archive';

    return (
      <tbody>
        {estimatedLostSalesTableData?.map((item, index) => {
          return (
            <tr>
              <td>{item?.asin}</td>
              <td>
                <div
                  className="textLimit11"
                  data-tip={item?.productName}
                  data-for={`product${index}`}
                >
                  {item?.productName}
                </div>
                <ReactTooltip
                  class="initialTextTransform"
                  id={`product${index}`}
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                />
              </td>

              {strandedTab ? (
                <td className="capitalize">{item?.reason}</td>
              ) : null}
              {archiveTab ? (
                <td className="capitalize">{item?.status}</td>
              ) : null}
              {inactiveTab || strandedTab ? (
                <td>
                  <AddThousandSeparator
                    value={item?.totalSales}
                    prefix={currencySymbol}
                    suffix=""
                    showPlusSign={false}
                  />
                </td>
              ) : null}
              {inactiveTab || strandedTab ? (
                <td>
                  <AddThousandSeparator
                    value={item?.avgDailySales}
                    prefix={currencySymbol}
                    suffix=""
                    showPlusSign={false}
                  />
                </td>
              ) : null}
              {inactiveTab ? <td>{item?.lastSoldDate}</td> : null}
              {strandedTab ? <td>{item?.strandedSince}</td> : null}
              {inactiveTab || strandedTab ? (
                <td>
                  <AddThousandSeparator
                    value={item?.estLostSales}
                    prefix={currencySymbol}
                    suffix=""
                    showPlusSign={false}
                  />
                </td>
              ) : null}
              <td>
                {archiveTab ? (
                  <span
                    className="action-item cursor"
                    role="presentation"
                    onClick={() => {
                      handleAsinArchiveAndRestoreEvents(
                        item?.asin,
                        item?.sku,
                        item?.status,
                        'restore',
                      );
                    }}
                  >
                    Restore
                  </span>
                ) : (
                  <span
                    className="action-item cursor"
                    role="presentation"
                    onClick={() => {
                      handleAsinArchiveAndRestoreEvents(
                        item?.asin,
                        item?.sku,
                        item?.status,
                        'archive',
                      );
                    }}
                  >
                    Archive
                  </span>
                )}
                <span className="action-item">
                  <a
                    href={`https://www.amazon.com/dp/${item?.asin}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Amazon
                  </a>
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  // render no data message
  const renderNoDataMessage = () => {
    return <NoData>{noDataMessage}</NoData>;
  };

  // render table pagination
  const renderTablePagination = () => {
    return (
      <>
        <div className="straight-line horizontal-line mt-3" />
        <CommonPagination
          count={tableDataCount}
          pageNumber={pageNumber}
          handlePageChange={handlePageChange}
        />
      </>
    );
  };

  // render Estimated Lost Sales Table
  const renderEstimatedLostSalesTable = () => {
    return (
      <IventoryTableWrapper style={{ overflow: 'auto' }} className="mt-3">
        <div className="horizontal-scroll-visible">
          <DesktopTable lassName="mb-3" style={{ tableLayout: 'fixed' }}>
            {renderEstimatedLostSalesTableHead()}
            {renderEstimatedLostSalesTableBody()}
          </DesktopTable>
        </div>
        {estimatedLostSalesTableData?.length === 0
          ? renderNoDataMessage()
          : null}
        {tableDataCount > 0 ? renderTablePagination() : null}
      </IventoryTableWrapper>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      <WhiteCard className="mb-3">
        {renderEstimatedLostSalesFilters()}
        {renderEstimatedLostSalesMetrics()}
        {renderEstimatedLostSalesTabs()}
        <div className="horizontal-line straight-line mb-2" />
        {isDataLoading ? (
          <PageLoader
            component="performance-graph"
            color="#FF5933"
            type="detail"
            width={40}
            height={40}
          />
        ) : (
          renderEstimatedLostSalesTable()
        )}
      </WhiteCard>
    </>
  );
}

EstimatedLostSales.defaultProps = {
  currencySymbol: '$',
  selectedMarketplace: '',
  noDataMessage: 'No Data Found.',
  isMST12to10AM: false,
  data: {},
  estimatedLostSalesReasonList: [],
};

EstimatedLostSales.propTypes = {
  currencySymbol: string,
  selectedMarketplace: string,
  customerId: string.isRequired,
  noDataMessage: string,
  isMST12to10AM: bool,
  data: shape({}),
  estimatedLostSalesReasonList: arrayOf(shape({})),
};
