import dayjs from 'dayjs';

import {
  CONTRIBUTION,
  CUSTOM,
  KEYMETRICS,
  POSITIVE,
  YEAROVERYEAR,
} from './CommanVariableConstants';

export const dateOptions = [
  {
    value: 'week',
    label: 'Current 7 days',
    sub: 'vs Previous 7 days',
    isDisabled: false,
  },
  {
    value: 'month',
    label: 'Current Month',
    sub: 'vs Previous month',
    isDisabled: false,
  },
  {
    value: '30days',
    label: 'Current 30 Days',
    sub: 'vs Previous 30 days',
    isDisabled: false,
  },
  {
    value: CUSTOM,
    label: 'Custom Range',
    sub: 'Select start and end dates',
  },
];

export const sponoredMetricsList = [
  {
    value: 'adSales',
    label: 'Ad Sales',
    isDisabled: false,
  },
  {
    value: 'adSpend',
    label: 'Ad Spend',
    isDisabled: false,
  },
  {
    value: 'adConversion',
    label: 'Ad Conversion',
    isDisabled: false,
  },
  {
    value: 'impressions',
    label: 'Impressions',
    isDisabled: false,
  },

  {
    value: 'adCos',
    label: 'ACOS / ROAS',
    isDisabled: false,
  },
  {
    value: 'costPerClick',
    label: 'Cost Per Click / Sales Per Click',
    isDisabled: false,
  },
  {
    value: 'adClicks',
    label: 'Ad Clicks',
    isDisabled: false,
  },
  {
    value: 'adClickRate',
    label: 'Click Through Rate',
    isDisabled: false,
  },

  {
    value: 'percentageNtbOrders',
    label: '% Of Orders New-to-brand (NTB)',
    isDisabled: false,
  },
  {
    value: 'percentageNtbSales',
    label: '% Of Sales New-to-brand (NTB)',
    isDisabled: false,
  },
  {
    value: 'ntbOrders',
    label: 'New-to-brand (NTB) Orders',
    isDisabled: false,
  },
  {
    value: 'ntbSales',
    label: 'New-to-brand (NTB) Sales',
    isDisabled: false,
  },
];

export const sponosredMetricsTooltipInfo = {
  acos: 'The percentage of your sales that you spend on advertising. This is calculated by dividing your spend by sales attributed to your ads.',
  roas: 'The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend.',
  costPerClick: 'This is the average amount you paid for each click on an ad.',
  salesPerClick: 'This is the sum of advertising sales / sum of clicks.',
};

export const sponsoredMetricsDetails = {
  adSales: {
    id: 'BT-sponsored-adsalescard',
    className: 'pr-1 pl-0',
    label: 'Ad Sales',
    key: 'adSales',
    metricsKey: 'ad_sales',
    activeClassName: 'ad-sales-active',
    hasCurrency: true,
    hasPercentage: '',
    decimalDigits: 2,
    hasGoals: false,
    differenceType: null,
    tooltipInfo:
      'Sales is the total value of products sold to shoppers attributed to your ads. Attribution depends on campaign type.',
  },
  adSpend: {
    id: 'BT-sponsored-adspendcard',
    className: 'pr-0 pr-md-1 pl-1',
    label: 'Ad Spend',
    key: 'adSpend',
    metricsKey: 'ad_spend',
    activeClassName: 'ad-spend-active',
    hasCurrency: true,
    hasPercentage: '',
    decimalDigits: 2,
    hasGoals: true,
    differenceType: 'spend',
    tooltipInfo: 'The total click charges for a product ad.',
  },

  adConversion: {
    id: 'BT-sponsored-adconversioncard',
    className: 'pr-1 pl-0 pl-md-1',
    label: 'Ad Conversion Rate',
    key: 'adConversion',
    metricsKey: 'ad_conversion_rate',
    activeClassName: 'ad-conversion-active',
    hasCurrency: false,
    hasPercentage: '%',
    decimalDigits: 2,
    hasGoals: false,
    differenceType: null,
    tooltipInfo:
      'This is calculated by dividing your number of units to your number of sessions multiplied by 100.',
  },

  impressions: {
    id: 'BT-sponsored-adimpressionscard',
    className: 'pr-0 pl-1',
    label: 'Impressions',
    key: 'impressions',
    metricsKey: 'impressions',
    activeClassName: 'impression-active',
    hasCurrency: false,
    hasPercentage: '',
    decimalDigits: 0,
    hasGoals: false,
    differenceType: null,
    tooltipInfo: 'The number of times ads were displayed.',
  },

  adClicks: {
    id: 'BT-sponsored-clickcard',
    className: 'pr-1 pl-0 pl-md-1',
    label: 'Clicks',
    key: 'adClicks',
    metricsKey: 'clicks',
    activeClassName: 'ad-click-active',
    hasCurrency: false,
    hasPercentage: '',
    decimalDigits: 0,
    hasGoals: false,
    differenceType: null,
    tooltipInfo: 'The number of times your ads were clicked.',
  },

  adClickRate: {
    id: 'BT-sponsored-clickratecard',
    className: 'pr-0 pl-1',
    label: 'Click Through Rate',
    key: 'adClickRate',
    metricsKey: 'ctr',
    activeClassName: 'ad-clickrate-active',
    hasCurrency: false,
    hasPercentage: '%',
    decimalDigits: 2,
    hasGoals: false,
    differenceType: null,
    tooltipInfo:
      'The ratio of how often shoppers click on your ad when displayed. This is calculated as clicks divided by impressions.',
  },

  percentageNtbOrders: {
    id: 'BT-sponsored-percentageNtbOrders',
    className: 'pr-0 pl-1',
    label: '% Of Orders New-to-brand (NTB)',
    key: 'percentageNtbOrders',
    metricsKey: 'ntb_orders_percent',
    activeClassName: 'ad-clickrate-active',
    hasCurrency: false,
    hasPercentage: '%',
    decimalDigits: 2,
    hasGoals: false,
    differenceType: null,
    tooltipInfo:
      'The percentage of total orders which are new-to-brand orders. This metric is available for Sponsored Brands and Sponsored Display campaigns.',
  },

  percentageNtbSales: {
    id: 'BT-sponsored-percentageNtbSales',
    className: 'pr-0 pl-1',
    label: '% Of Sales New-to-brand (NTB)',
    key: 'percentageNtbSales',
    metricsKey: 'ntb_sales_percent',
    activeClassName: 'ad-clickrate-active',
    hasCurrency: false,
    hasPercentage: '%',
    decimalDigits: 2,
    hasGoals: false,
    differenceType: null,
    tooltipInfo:
      'The percentage of total sales (in local currency) that are new-to-brand sales. This metric is available for Sponsored Brands and Sponsored Display campaigns.',
  },

  ntbOrders: {
    id: 'BT-sponsored-ntbOrders',
    className: 'pr-0 pl-1',
    label: 'New-to-brand (NTB) Orders',
    key: 'ntbOrders',
    metricsKey: 'ntb_orders',
    activeClassName: 'impression-active',
    hasCurrency: false,
    hasPercentage: '',
    decimalDigits: 0,
    hasGoals: false,
    differenceType: null,
    tooltipInfo:
      'The number of first-time orders for products within the brand over a 1-year period. This metric is available for Sponsored Brands and Sponsored Display campaigns.',
  },

  ntbSales: {
    id: 'BT-sponsored-ntbSales',
    className: 'pr-0 pr-md-1 pl-1',
    label: 'New-to-brand (NTB) Sales',
    key: 'ntbSales',
    metricsKey: 'ntb_sales',
    activeClassName: 'ad-spend-active',
    hasCurrency: true,
    hasPercentage: '',
    decimalDigits: 2,
    hasGoals: false,
    differenceType: null,
    tooltipInfo:
      'The total sales (in local currency) of new-to-brand orders. This metric is available for Sponsored Brands and Sponsored Display campaigns.',
  },
};

export const sponsoredMetricsActiveClasses = [
  'ad-sales-active',
  'revenue-organic-active',
  'ad-click-active',
  'ad-cos-active',
];

export const sponsoredMetricsClasses = {
  0: 'pl-0 pr-0 pr-md-1',
  1: 'pl-0 pl-md-1 pr-0 pr-lg-1',
  2: 'pl-0 pl-lg-1 pr-0 pr-md-1',
  3: 'pl-0 pl-md-1 pr-0',
  4: 'pl-0 pr-0 pr-md-1',
  5: 'pl-0 pl-md-1 pr-0 pr-lg-1',
  6: 'pl-0 pl-lg-1 pr-0 pr-md-1',
  7: 'pl-0 pl-md-1 pr-0',
};

export const dateOptionsWithYear = [
  {
    value: 'week',
    label: 'Current 7 days',
    sub: 'vs Previous 7 days',
    isDisabled: false,
  },
  {
    value: 'month',
    label: 'Current Month',
    sub: 'vs Previous month',
    isDisabled: false,
  },
  {
    value: '30days',
    label: 'Current 30 Days',
    sub: 'vs Previous 30 days',
    isDisabled: false,
  },
  {
    value: 'year',
    label: 'Year to Date',
    sub: 'vs Previous year',
    isDisabled: false,
  },
  {
    value: CUSTOM,
    label: 'Custom Range',
    sub: 'Select start and end dates',
    isDisabled: false,
  },
];

export const dateOptionsWithYearOverYear = [
  {
    value: 'week',
    label: 'Current 7 days',
    sub: 'vs Previous 7 days',
    isDisabled: false,
  },
  {
    value: 'month',
    label: 'Current Month',
    sub: 'vs Previous month',
    isDisabled: false,
  },
  {
    value: '30days',
    label: 'Current 30 Days',
    sub: 'vs Previous 30 days',
    isDisabled: false,
  },
  {
    value: 'year',
    label: 'Year to Date',
    sub: 'vs Previous year',
    isDisabled: false,
  },
  {
    value: YEAROVERYEAR,
    label: 'Year Over Year',
    sub: 'Select start and end dates',
    isDisabled: false,
  },
  {
    value: CUSTOM,
    label: 'Custom Range',
    sub: 'Select start and end dates',
    isDisabled: false,
  },
];

export const vendorSalesMetricsTypeOptions = [
  { value: 'orderedRevenue', label: 'Ordered Revenue' },
  { value: 'shippedCOGs', label: 'Shipped COGs' },
];

export const VendorMetricsNames = {
  orderedRevenue: 'Ordered Revenue',
  shippedCOGs: 'Shipped COGs',
  glanceViews: 'Glance Views',
  conversionRate: 'Conversion Rate',
  orderedUnits: 'Ordered Units',
  shippedUnits: 'Shipped Units',
};

export const VendorMetricsColorSet = {
  orderedRevenue: '#0045B4',
  shippedCOGs: '#0045B4',
  glanceViews: '#8C54FF',
  conversionRate: '#30A8BD',
  orderedUnits: '#D6A307',
  shippedUnits: '#D6A307',
};

export const bbDateOptions = [
  { value: 'week', label: 'Current 7 days' },
  { value: 'month', label: 'Current Month' },
  { value: '30days', label: 'Current 30 Days' },
  {
    value: CUSTOM,
    label: 'Custom Range',
    sub: 'Select start and end dates',
  },
];

export const AdTypesOptions = [
  { value: 'all', label: 'All Ad Types', key: '', isDisabled: false },
  {
    value: 'product_campaign_report',
    label: 'Sponsored Product',
    key: 'SP',
    isDisabled: false,
  },
  {
    value: 'brand_campaign_report',
    label: 'Sponsored Brand + Video',
    key: 'SB',
    isDisabled: false,
  },
  {
    value: 'sponsored_display_campaign_report',
    label: 'Sponsored Display',
    key: 'SD',
    isDisabled: false,
  },
  {
    value: 'television_campaign_report',
    label: 'Sponsored Television',
    key: 'STV',
    isDisabled: false,
  },
];

export const sponsoredAdTypeOptions = [
  { value: 'all', label: 'All Campaigns', isDisabled: false },
  {
    value: 'product_campaign_report',
    label: 'Product Campaigns',
    isDisabled: false,
  },
  {
    value: 'brand_campaign_report',
    label: 'Brand + Video Campaigns',
    isDisabled: false,
  },
  {
    value: 'sponsored_display_campaign_report',
    label: 'Display Campaigns',
    isDisabled: false,
  },
  {
    value: 'television_campaign_report',
    label: 'Sponsored Television',

    isDisabled: false,
  },
];

export const sponsoredAsinTableConstant = [
  {
    label: 'Asin',
    key: 'asin',
    className: 'sticky-column header',
    width: '140px',
    isSortable: false,
  },
  {
    label: 'Product Name',
    key: 'product_name',
    isSortable: true,
    width: '240px',
  },
  { label: 'Ad Spend', key: 'ad_spend', isSortable: true },
  { label: 'Ad Sales', key: 'ad_sales', isSortable: true },
  { label: 'ACOS', key: 'acos', isSortable: true },
  { label: 'ROAS', key: 'roas', isSortable: true },
  { label: 'CTR', key: 'adClickRate', isSortable: true },
  { label: 'Ad Conversion Rate', key: 'ad_conversion', isSortable: true },
  { label: 'Cost per Click', key: 'cost_per_click', isSortable: true },
  { label: 'Sales per Click', key: 'sales_per_click', isSortable: true },
  { label: 'Clicks', key: 'clicks', isSortable: true },
  { label: 'Impressions', key: 'impressions', isSortable: true },
  { label: '% of orders NTB ', key: 'percentageNtbOrders', isSortable: true },
  { label: '% of sales NTB', key: 'percentageNtbSales', isSortable: true },
  { label: 'NTB orders', key: 'ntbOrders', isSortable: true },
  { label: 'NTB Sales', key: 'ntbSales', isSortable: true },
];

export const dspAsinTableConstant = [
  {
    label: 'Asin',
    key: 'asin',
    // className: 'sticky-column header',
    width: '13%',
    isSortable: false,
  },
  {
    label: 'Product Name',
    key: 'product_name',
    width: '18%',
    isSortable: true,
  },

  {
    label: '',
    key: 'pLink',
    width: '5%',
    isSortable: false,
  },

  {
    label: 'Total Product Sales',
    key: 'total_product_sales',
    width: '16%',
    isSortable: true,
  },
  {
    label: 'TTL New Brand Purchases',
    key: 'ttl_new_brand_purchases',
    width: '16%',
    isSortable: true,
  },
  {
    label: 'Total Detail Page Views',
    key: 'total_detail_page_views',
    width: '16%',
    isSortable: true,
  },
  {
    label: 'Total Add to Cart',
    key: 'total_add_to_cart',
    width: '16%',
    isSortable: true,
  },
];

export const dspMetricsConstants = {
  dspImpressions: {
    id: 'BT-dspad-impressioncard',
    dataCy: 'BT-dsp-impression-card',
    className: 'pr-1 pl-0',
    label: 'Impressions',
    key: 'dspImpressions',
    metricsKey: 'impressions',
    activeClassName: 'ad-sales-active',
    prefix: null,
    suffix: '',
    decimalDigits: 0,
    hasGoals: true,
    differenceType: null,
    tooltipInfo: '',
  },
  dspSpend: {
    id: 'BT-dspad-dspspendcard',
    dataCy: 'BT-dsp-dspSpend-card',
    className: 'pr-0 pr-md-1 pl-1',
    label: 'Dsp Spend',
    key: 'dspSpend',
    metricsKey: 'dsp_spend',
    activeClassName: 'ad-spend-active',
    prefix: 'currency',
    suffix: '',
    decimalDigits: 2,
    hasGoals: false,
    differenceType: 'spend',
    tooltipInfo: '',
  },
  dspTotalProductSales: {
    id: 'BT-dspad-totalproductcard',
    dataCy: 'BT-dsp-totalProduct-card',
    className: 'pr-1 pl-0 pl-md-1',
    label: 'Total Product Sales',
    key: 'dspTotalProductSales',
    metricsKey: 'total_product_sales',
    activeClassName: 'ad-conversion-active',
    prefix: 'currency',
    suffix: '',
    decimalDigits: 2,
    hasGoals: true,
    differenceType: null,
    tooltipInfo: '',
  },
  dspTotalRoas: {
    id: 'BT-dspad-totalroascard',
    dataCy: 'BT-dsp-totalRoas-card',
    className: 'pr-0 pl-1',
    label: 'Total ROAS',
    key: 'dspTotalRoas',
    metricsKey: 'total_roas',
    activeClassName: 'ad-sales-active',
    prefix: 'currency',
    suffix: '',
    decimalDigits: 2,
    hasGoals: true,
    differenceType: 'totalRoas',
    tooltipInfo: '',
  },
  dspTotalDpvr: {
    id: 'BT-dspad-totaldpvrcard',
    dataCy: 'BT-dsp-totalDPVR-card',
    className: 'pr-1 pl-0',
    label: 'Total DPVR',
    key: 'dspTotalDpvr',
    metricsKey: 'total_dpvr',
    activeClassName: 'ad-cos-active',
    prefix: null,
    suffix: '%',
    decimalDigits: 2,
    hasGoals: true,
    differenceType: 'dpvr',
    tooltipInfo: '',
  },
  dspTtlNewBrandPurchases: {
    id: 'BT-dspad-TTLBrandcard',
    dataCy: 'BT-dsp-TTLBrand-card',
    className: 'pr-0 pr-md-1 pl-1',
    label: 'TTL New Brand Purchases',
    key: 'dspTtlNewBrandPurchases',
    metricsKey: 'ttl_new_brand_purchases',
    activeClassName: 'ad-roas-active',
    prefix: null,
    suffix: '%',
    decimalDigits: 2,
    hasGoals: true,
    differenceType: null,
    tooltipInfo: '',
  },
  dspProductSales: {
    id: 'BT-dspad-productsalescard',
    dataCy: 'BT-dsp-productSales-card',
    className: 'pr-1 pl-0 pl-md-1',
    label: 'Product sales',
    key: 'dspProductSales',
    metricsKey: 'product_sales',
    activeClassName: 'ad-click-active',
    prefix: 'currency',
    suffix: '',
    decimalDigits: 2,
    hasGoals: true,
    differenceType: null,
    tooltipInfo: '',
  },
  dspRoas: {
    id: 'BT-dspad-roascard',
    dataCy: 'BT-dsp-roas-card',
    className: 'pr-0 pl-1',
    label: 'ROAS',
    key: 'dspRoas',
    metricsKey: 'roas',
    activeClassName: 'ad-clickrate-active',
    prefix: 'currency',
    suffix: '',
    decimalDigits: 2,
    hasGoals: false,
    differenceType: 'roas',
    tooltipInfo: '',
  },
};

export const dspPacingMetricsConstants = {
  plannedSpend: {
    key: 'plannedSpend',
    label: 'Planned Spend to date',
    metricsKey: 'planned_spend_to_date_all',
    metricsPercentKey: 'planned_spend_to_date_percentage_all',
    type: 'currency',
    rangeClass: '',
    showPercentValue: true,
  },
  actualSpend: {
    key: 'actualSpend',
    label: 'Actual Spend to date',
    metricsKey: 'actual_spend_to_date_all',
    metricsPercentKey: 'actual_spend_to_date_percentage_all',
    type: 'currency',
    rangeClass: '',
    showPercentValue: true,
  },
  changeSpend: {
    key: 'changeSpend',
    label: 'Change to date',
    metricsKey: 'change_to_date_all',
    metricsPercentKey: 'change_to_date_percentage_all',
    type: 'currency',
    rangeClass: 'red-range',
    showPercentValue: true,
  },
  remainingBudget: {
    key: 'remainingBudget',
    label: 'remaining_budget',
    metricsKey: 'remaining_budget_all',
    metricsPercentKey: null,
    type: 'currency',
    rangeClass: '',
    showPercentValue: false,
  },
};

export const noGraphDataMessage =
  'We are not pulling data for this dashboard. If we should, please file a help desk ticket and \n we will resolve this issue as soon as possible.';

export const keyContributionConstant = {
  noManagerSelected: [
    {
      id: POSITIVE,
      label: 'Positive',
    },
    {
      id: 'negative',
      label: 'Negative',
    },
  ],

  managerSelected: [
    {
      id: CONTRIBUTION,
      label: 'Contribution',
    },
    {
      id: KEYMETRICS,
      label: 'Key Metrics',
    },
  ],
};

export const keyContributionHeaders = {
  positive: 'Key Contributors',
  negative: 'Key Contributors',
  contribution: 'Contribution',
  keyMetrics: 'Key Metrics',
};

export const metricsCurrency = {
  // sales metrics
  revenue: { type: 'currency' },
  traffic: { type: 'nocurrency' },
  conversion: { type: 'percentage' },
  unitsSold: { type: 'nocurrency' },
  tacos: { type: 'percentage' },
  adSpendTotal: { type: 'currency' },
  orderedRevenue: { type: 'currency' },
  shippedCOGs: { type: 'currency' },
  glanceViews: { type: 'nocurrency' },
  conversionRate: { type: 'percentage' },
  orderedUnits: { type: 'nocurrency' },
  shippedUnits: { type: 'nocurrency' },
  // sponsored metrics
  adSales: { type: 'currency' },
  adSpend: { type: 'currency' },
  adConversion: { type: 'percentage' },
  impressions: { type: 'nocurrency' },
  adCos: { type: 'percentage' },
  adRoas: { type: 'currency' },
  adClicks: { type: 'nocurrency' },
  adClickRate: { type: 'percentage' },
  costPerClick: { type: 'currency' },
  salesPerClick: { type: 'currency' },
  adOrders: { type: 'nocurrency' },
  ntbOrders: { type: 'nocurrency' },
  ntbSales: { type: 'currency' },
  percentageNtbOrders: { type: 'percentage' },
  percentageNtbSales: { type: 'percentage' },
  // dsp metrics
  dspImpressions: { type: 'nocurrency' },
  dspSpend: { type: 'currency' },
  dspTotalProductSales: { type: 'currency' },
  dspTotalRoas: { type: 'currency' },
  dspTotalDpvr: { type: 'percentage' },
  dspTtlNewBrandPurchases: { type: 'percentage' },
  dspProductSales: { type: 'currency' },
  dspRoas: { type: 'currency' },
};

export const toogleMetricsData = [
  {
    id: 1,
    name: 'acos',
    label: 'ACOS',
  },
  {
    id: 2,
    name: 'roas',
    label: 'ROAS',
  },
];

export const CPCandSPCToggleMetrics = [
  {
    id: 'costPerClick',
    name: 'costPerClick',
    label: 'COST PER CLICK (CPC)',
  },
  {
    id: 'salesPerClick',
    name: 'salesPerClick',
    label: 'SALES PER CLICK (SPC)',
  },
];

export const asinTabOptions = [
  { value: 'parent_asin', label: 'Parent ASIN' },
  { value: 'child_asin', label: 'Child ASIN' },
];

export const salesASINPerformanceSymbol = {
  revenue: 'currency',
  sessions: 'number',
  conversion: 'percent',
  units_sold: 'number',
};
const currentDate = new Date();
currentDate.setDate(currentDate.getDate() - 3);

export const asinPeriod = {
  week: { label: 'Current 7 days', sub: 'vs Previous 7 days' },
  month: {
    label: dayjs(currentDate).format('MMMM'),
    sub: dayjs(currentDate).subtract(1, 'month').format('MMMM'),
  },
  '30days': {
    label: 'Current 30 Days',
    sub: 'vs Previous 30 days',
  },
  year: {
    label: `${dayjs(currentDate).format('YYYY')}`,
    sub: `${dayjs(currentDate).subtract(1, 'year').format('YYYY')}`,
  },
};

export const salesMetricsResponseKey = {
  revenueTotal: 'revenue',
  revenueOrganic: 'revenue_organic',
  revenuePpc: 'revenue_ppc',
  revenueDsp: 'revenue_dsp',
  unitsSold: 'units_sold',
  traffic: 'traffic',
  conversion: 'conversion',
  adSpend: 'adSpend',
  adSpendTotal: 'ad_spend_total',
  adSpendPpc: 'sponsored_ad_spend',
  adSpendDsp: 'dsp_spends',
  tacos: 'tacos',
};

export const salesGraphColorSet = {
  revenueTotal: '#0045b4',
  revenueOrganic: '#74b035',
  revenuePpc: '#C84EC6',
  revenueDsp: '#E05D37',
  unitsSold: '#8C54FF',
  traffic: '#30A8BD',
  conversion: '#D6A307',
  adSpend: '#89A43C',
  adSpendTotal: '#33beffd6',
  adSpendPpc: '#a47b40d6',
  adSpendDsp: '#52d045d6',
  tacos: '#00be8e',
};

export const salesGraphTooltipNames = {
  revenueTotal: 'Revenue Total',
  revenueOrganic: 'Revenue Organic ',
  revenuePpc: 'Revenue PPC',
  revenueDsp: 'Revenue DSP',
  unitsSold: 'Units Sold',
  traffic: 'Traffic',
  conversion: 'Conversion',
  totalSales: 'Total Sales',
  adSpend: 'Ad Spend',
  adSpendTotal: 'Ad Spend Total',
  adSpendPpc: 'Ad Spend PPC',
  adSpendDsp: 'Ad Spend DSP',
  tacos: 'Tacos',
};

export const parentASINTableheader = [
  {
    key: 'parent_asin',
    label: 'Parent ASIN',
    width: '12.5%',
  },

  {
    key: 'current_revenue',
    label: 'Sales',
    width: '12.5%',
  },
  {
    key: 'current_traffic',
    label: 'TRAFFIC',
    width: '12.5%',
  },
  {
    key: 'current_units_sold',
    label: 'UNITS',
    width: '12.5%',
  },

  {
    key: 'current_conversion',
    label: `Conversion`,
    width: '12.5%',
  },

  {
    key: 'current_buy_box_percentage',
    label: 'Buy Box',
    width: '12.5%',
  },
];

export const childASINTableheader = [
  // removed child SKU column - refere ticket- 7962
  {
    key: 'child_asin',
    label: 'Child ASIN',
    width: '10%',
  },
  {
    key: 'product_name',
    label: 'Product Name',
    width: '20%',
  },

  {
    key: 'current_revenue',
    label: 'Sales',
    width: '14%',
  },
  {
    key: 'current_traffic',
    label: 'TRAFFIC',
    width: '14%',
  },
  {
    key: 'current_units_sold',
    label: 'UNITS',
    width: '14%',
  },

  {
    key: 'current_conversion',
    label: `Conversion`,
    width: '14%',
  },
  {
    key: 'current_buy_box_percentage',
    label: 'Buy Box',
    width: '14%',
  },
];

export const vendorOrderRevenueASINTableheader = [
  {
    key: 'ordered_revenue_asin',
    label: 'ASIN',
    width: '10%',
  },

  {
    key: 'product_name',
    label: 'Product Name',
    width: '20%',
  },
  {
    key: 'current_ordered_revenue',
    label: 'ORDERED REVENUE',
    width: '15%',
  },
  {
    key: 'current_glance_view',
    label: 'GLANCE VIEWS',
    width: '15%',
  },

  {
    key: 'current_ordered_unit',
    label: `ORDERED UNITS`,
    width: '15%',
  },

  {
    key: 'current_conversion',
    label: 'CONVERSION RATE',
    width: '15%',
  },
];

export const vendorShippedCogsASINTableheader = [
  {
    key: 'shipped_cogs_asin',
    label: 'ASIN',
    width: '10%',
  },

  {
    key: 'product_name',
    label: 'Product Name',
    width: '20%',
  },
  {
    key: 'current_shipped_cogs',
    label: 'SHIPPED COGS',
    width: '15%',
  },
  {
    key: 'current_glance_view',
    label: 'GLANCE VIEWS',
    width: '15%',
  },

  {
    key: 'current_shipped_unit',
    label: `SHIPPED UNITS`,
    width: '15%',
  },

  {
    key: 'current_conversion',
    label: 'CONVERSION RATE',
    width: '15%',
  },
];

export const showMetricsTooltipForSponsored = [
  'ad_sales',
  'ad_spend',
  'ad_conversion_rate',
  'impressions',
  'acos',
  'cost_per_click',
  'clicks',
  'ctr',
  'percentage_ntb_orders',
  'percentage_ntb_sales',
  'ntb_orders',
  'ntb_sales',
];

export const showMetricsTooltipForDSP = [
  'impressions',
  'dsp_spend',
  'total_product_sales',
  'total_roas',
  'total_dpvr',
  'ttl_new_brand_purchases',
  'product_sales',
  'roas',
];

export const showMetricsTooltipForSales = [
  'revenue',
  'revenue_ppc',
  'revenue_dsp',
  'revenue_organic',
  'units_sold',
  'traffic',
  'conversion',
  'sponsored_ad_spend',
  'ad_spend_total',
  'dsp_spends',
  'tacos',
];

export const revenuSubTypeKeys = {
  revenueTotal: 'revenue',
  revenuePpc: 'revenue_ppc',
  revenueDsp: 'revenue_dsp',
  revenueOrganic: 'revenue_organic',
  adSpendTotal: 'ad_spend_total',
  dspSpend: 'dsp_spends',
  sponsoredAdSpend: 'sponsored_ad_spend',
  adSpendPpc: 'sponsored_ad_spend',
};

export const showMetricsTooltipForVendorSales = [
  'ordered_revenue',
  'glance_views',
  'conversion_rate',
  'revenue_organic',
  'ordered_units',
  'shipped_cogs',
  'shipped_units',
];

export const performanceInsightMessage = {
  sales: 'Sales data displayed has a two day lag.',
  vendorSales: 'Vendor sales data displayed has three day lag.',
  sponsored: 'Sponsored advertising data displayed has a two day lag.',
  dsp: 'DSP advertising data displayed has a one day lag.',
};

export const metricsVendorSalesKey = {
  orderedRevenue: 'ordered_revenue',
  glanceViews: 'glance_views',
  conversionRate: 'conversion_rate',
  orderedUnits: 'ordered_units',
  shippedUnits: 'shipped_units',
  shippedCOGs: 'shipped_cogs',
};

export const childBrandDataAvailableMessage = {
  sales: 'sales data available.',
  sponsored: 'sponsored advertising data available.',
  dsp: 'DSP advertising data available.',
};

export const rivianaAndChildBrandIds = [
  'CMS0uTu',
  'CMQ44sG',
  'CMm4jj8',
  'CMrQttI',
  'CMQJEW3',
  'CMasKLQ',
  'CMtCWXm',
  'CMPNrYy',
];

// daily weekly monthly toggle options
export const dailyWeeklyMonthlyOptions = [
  {
    label: 'Daily',
    value: 'daily',
    id: 'daysCheck',
    dspId: 'dspDaysCheck',
    spId: 'spDaysCheck',
  },
  {
    label: 'Weekly',
    value: 'weekly',
    id: 'weeklyCheck',
    dspId: 'dspWeeklyCheck',
    spId: 'spWeeklyCheck',
  },
  {
    label: 'Monthly',
    value: 'monthly',
    id: 'monthlyCheck',
    dspId: 'dspMonthlyCheck',
    spId: 'sponthlyCheck',
  },
];

export const campaignSortingOptions = [
  {
    label: 'All Statuses',
    value: 'all',
  },
  {
    label: 'Enabled',
    value: 'enabled',
  },
  {
    label: 'Paused',
    value: 'paused',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];

export const spCampaignAsinToggleOptions = [
  {
    id: 'spCampaignCheck',
    value: 'campaign',
    label: 'Campaign',
  },
  { id: 'spAsinCheck', value: 'asin', label: 'ASIN' },
];

export const dspCampaignAsinToggleOptions = [
  {
    id: 'dspCampaignCheck',
    value: 'campaign',
    label: 'Campaign',
    dataCy: 'BT-dsp-campaign-toggle',
  },
  {
    id: 'dspAsinCheck',
    value: 'asin',
    label: 'ASIN',
    dataCy: 'BT-dsp-asin-toggle',
  },
];

export const asinStatiMessages = {
  brand_campaign_report:
    'SB+Video and STV Report by ASIN is currently unavailable from Amazon Ads API.',
  television_campaign_report:
    'STV is currently available for US marketplace only.',
  campaignDataAvailabilityMessage: 'No Campaign Data Found.',
  asinDataAvailabilityMessage: 'No ASIN Performance Data Found.',
  asinHistoricalDataMessage:
    'Available historical data is up to 4 months only.',
  asinTooltipMessage:
    'Historical data displayed will not include more than 4 months of data.',
  asinOneJuneMessage: `Available data is from June 1, 2023 onwards.`, // PDV-8401 - One October 2023 logic
};

export const sponsoredTooltipDataHTML = `<ul style="padding-left:15px; margin:0">
                  <li style="padding-bottom:5px">
                    Historical Sponsored Advertising data for US region is
                    available from Aug 1, 2022 onwards
                  </li>
                  <li>
                    Historical Sponsored Advertising data for non-US region is
                    available from Oct 1, 2022 onwards
                  </li>
                </ul>`;
