/* ********** Third party package imports ********** */
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

/* ********** Declaring prop types ********** */
export const DspDashboardContainerDefaultProps = {
  // string
  responseID: null,
  currencySymbol: '',
  // boolean
  isBGS: false,
  isBGSAdmin: false,
  isSpreetailRole: false,
  isBGSManager: false,
  isAdManager: false,
  isAdTeamManager: false,
  isAdManagerAdmin: false,
  isCustomDateApply: false,
  includeInactiveBPs: false,
  includeSpreetailBrands: false,
  // object
  // data: {},
  userInfo: {},
  customDate: {},
  selectedDspDF: {},
  // array
  adState: [],
  loggedInUser: [],
  marketplaceChoices: [],
  // function
  setAdState: () => {},
  setCustomDate: () => {},
  setResponseID: () => {},
  bindUserListData: () => {},
  checkIsUserExist: () => {},
  setSelectedDspDF: () => {},
  setCurrencySymbol: () => {},
  bindBrandPartners: () => {},
  setIsCustomDateApply: () => {},
  generateStringOfValues: () => {},
  displayContributionTable: () => {},
  bindMetricsContributionData: () => {},
  renderRecentAndPreviousLabel: () => {},
  handleMultiSelectDropdownFilterEvents: () => {},
};

export const DspDashboardContainerPropTypes = {
  // string
  responseID: string,
  currencySymbol: string,
  // boolean
  isBGS: bool,
  isBGSAdmin: bool,
  isBGSManager: bool,
  isAdManager: bool,
  isSpreetailRole: bool,
  isAdTeamManager: bool,
  isAdManagerAdmin: bool,
  isCustomDateApply: bool,
  includeInactiveBPs: bool,
  includeSpreetailBrands: bool,
  // object
  userInfo: shape({}),
  customDate: shape({}),
  selectedDspDF: shape({}),
  // array
  adState: arrayOf(shape({})),
  loggedInUser: arrayOf(shape({})),
  marketplaceChoices: arrayOf(shape({})),
  // function
  setAdState: func,
  setCustomDate: func,
  setResponseID: func,
  setSelectedDspDF: func,
  checkIsUserExist: func,
  bindUserListData: func,
  bindBrandPartners: func,
  setCurrencySymbol: func,
  setIsCustomDateApply: func,
  generateStringOfValues: func,
  displayContributionTable: func,
  bindMetricsContributionData: func,
  renderRecentAndPreviousLabel: func,
  handleMultiSelectDropdownFilterEvents: func,
};

export const DspRightHandSideSectionDefaultProps = {
  // number
  pageNumber: 0,
  contributionCount: 0,
  // boolean
  isCustomDateApply: false,
  dspGraphLoader: false,
  dspPacingLoader: false,
  downloadReportLoader: false,
  keyContributionLoader: false,
  showAdCustomDateModal: false,
  // string
  currencySymbol: '',
  dspGroupBy: '',
  selectedTabMetrics: 'dspImpressions',
  selectedSpendingOption: 'overspending',
  // object
  dspPacingData: {},
  selectedDspDF: {},
  customDateError: {},
  dspCurrentTotal: {},
  dspPreviousTotal: {},
  dspDifferenceData: {},
  selectedDspMetrics: {},
  selectedBrandPartner: {},
  // array
  dspChartData: [],
  contributionData: [],
  dspCustomDateState: [],
  // function
  applyCustomDate: () => {},
  handleDSPGroupBy: () => {},
  handlePageChange: () => {},
  handleDspDailyFact: () => {},
  handleSpendingOptions: () => {},
  setDspCustomDateState: () => {},
  setSelectedDspMetrics: () => {},
  setShowAdCustomDateModal: () => {},
  handleOnMatricsTabChange: () => {},
  displayContributionTable: () => {},
  renderRecentAndPreviousLabel: () => {},
  handleKeyContributorsDownloadReport: () => {},
};

export const DspRightHandSideSectionPropTypes = {
  // number
  pageNumber: number,
  contributionCount: number,
  // boolean
  isCustomDateApply: bool,
  dspGraphLoader: bool,
  dspPacingLoader: bool,
  downloadReportLoader: bool,
  keyContributionLoader: bool,
  showAdCustomDateModal: bool,
  // string
  currencySymbol: string,
  dspGroupBy: string,
  selectedTabMetrics: string,
  selectedSpendingOption: string,
  // object
  dspPacingData: shape({}),
  selectedDspDF: shape({}),
  customDateError: shape({}),
  dspCurrentTotal: shape({}),
  dspPreviousTotal: shape({}),
  dspDifferenceData: shape({}),
  selectedDspMetrics: shape({}),
  selectedBrandPartner: shape({}),
  // array
  dspChartData: arrayOf(shape({})),
  contributionData: arrayOf(shape({})),
  dspCustomDateState: arrayOf(shape({})),
  // function
  applyCustomDate: func,
  handleDSPGroupBy: func,
  handlePageChange: func,
  handleDspDailyFact: func,
  handleSpendingOptions: func,
  setDspCustomDateState: func,
  setSelectedDspMetrics: func,
  setShowAdCustomDateModal: func,
  handleOnMatricsTabChange: func,
  displayContributionTable: func,
  renderRecentAndPreviousLabel: func,
  handleKeyContributorsDownloadReport: func,
};
