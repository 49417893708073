/* ********** React imports ********** */
import React, { Fragment } from 'react';

/* ********** Third party package imports ********** */
import ReactTooltip from 'react-tooltip';
import { arrayOf, bool, func, shape, string } from 'prop-types';

/* ********** Local component file imports ********** */
import { CardMetrics, NoData, PageLoader } from '../../../../../common';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../../theme/images';
import VendorSalePerformanceChart from '../../../../Customer/CustomerDetailView/CompanyPerformance/SalesPerformance/VendorReporting/VendorSalePerformanceChart';
import {
  metricsVendorSalesKey,
  noGraphDataMessage,
  showMetricsTooltipForVendorSales,
  VendorMetricsNames,
  vendorOrderedRevenueMetrics,
  vendorShippedCOGsMetrics,
} from '../../../../../constants';

/* ********** MAIN FUNCTION START HERE ********** */
export default function VendorSalesPerformance({
  // string
  currencySymbol,
  // boolean
  isApiCall,
  // object
  selectedSalesDF,
  vendorSalesDifference,
  vendorSalesCurrentTotal,
  vendorSalesPreviousTotal,
  selectedVendorMetrics,
  selectedVendorMetricsType,
  // array
  vendorSalesMetricsData,
  // function
  setSelectedVendorMetrics,
  renderRecentAndPreviousLabel,
  displayDailyWeeklyMonthlyToggleButton,
}) {
  // display tooltip message for metrics
  const renderMetricsTooltipMessge = (key = '') => {
    return `No ${key} available for this period.`;
  };

  // render selected metrics box classname
  const renderSelectedMetricsBoxClass = (metricsName, metricsClass) => {
    let tempClass = '';
    const isMetricsSelected = Object.prototype.hasOwnProperty.call(
      selectedVendorMetrics,
      metricsName,
    );
    if (isMetricsSelected) {
      tempClass = `order-chart-box ${metricsClass} fix-height`;
    } else if (selectedVendorMetrics?.length === 4) {
      tempClass = 'order-chart-box fix-height disabled';
    } else {
      tempClass = 'order-chart-box fix-height';
    }
    return tempClass;
  };

  // render disabled class
  const renderDisbaledClass = (current, previous) => {
    return !current && !previous ? 'disabled' : null;
  };

  // handle metrixs box toggle events
  const handleMetricsBoxToggleEvents = (metricsName) => {
    const isMetricsSelected = Object.prototype.hasOwnProperty.call(
      selectedVendorMetrics,
      metricsName,
    );

    if (isMetricsSelected) {
      delete selectedVendorMetrics?.[metricsName];
      setSelectedVendorMetrics({ ...selectedVendorMetrics });
    } else {
      setSelectedVendorMetrics({
        ...selectedVendorMetrics,
        [metricsName]: true,
      });
    }
  };

  // display formated metrics value
  const displayMetricsValue = (metricsName, metricsValue, prefix, suffix) => {
    if (metricsValue === 0) {
      return `${prefix || ''}0${suffix || ''}`;
    }
    if (metricsValue) {
      const valueString =
        metricsName === 'orderedRevenue' ||
        metricsName === 'shippedCOGs' ||
        metricsName === 'conversionRate'
          ? metricsValue.toFixed(2)
          : metricsValue.toString();
      const [splitedValue, decimalValue] = valueString?.split('.');

      return (
        <span style={{ fontSize: '26px' }}>
          {prefix || ''}
          {splitedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          <span style={{ fontSize: suffix === '%' ? '26px' : '16px' }}>
            {decimalValue ? `.${decimalValue.slice(0, 2)}` : ''}
          </span>
          {suffix || ''}
        </span>
      );
    }
    return 'N/A';
  };

  // display metrics previous vale
  const displayMetricsPreviousValue = (
    previousValue,
    metricsName,
    prefix,
    suffix,
  ) => {
    const displayPreviousValue =
      previousValue === 0
        ? `${prefix || ''}0${suffix || ''}`
        : previousValue
        ? `${prefix || ''}${
            metricsName === 'conversionRate'
              ? previousValue.toFixed(2)
              : metricsName === 'orderedRevenue' ||
                metricsName === 'shippedCOGs'
              ? previousValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : previousValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }${suffix || ''}`
        : 'N/A';
    return displayPreviousValue;
  };

  // display metrics box
  const displayMetricsBox = (
    metricsName,
    className,
    metricsClass,
    prefix,
    suffix,
  ) => {
    const metricsKey = metricsVendorSalesKey?.[metricsName];
    const metricsKeyName = VendorMetricsNames?.[metricsName];
    const currentTotal = vendorSalesCurrentTotal?.[metricsKey];
    const previousTotal = vendorSalesPreviousTotal?.[metricsKey];
    const difference = vendorSalesDifference?.[metricsKey];

    return (
      <div className={`col-6 col-md-3 mb-2 ${className}`}>
        <CardMetrics
          id={`BT-${metricsName}-card`}
          className="fix-height"
          data-tip={renderMetricsTooltipMessge(metricsKeyName)}
          data-for={metricsKey}
        >
          <div
            className={`${renderSelectedMetricsBoxClass(
              metricsName,
              metricsClass,
            )} ${renderDisbaledClass(currentTotal, previousTotal)}`}
            onClick={() => handleMetricsBoxToggleEvents(metricsName)}
            role="presentation"
          >
            <div className="chart-name">{metricsKeyName?.toUpperCase()}</div>
            <div className="number-rate">
              {displayMetricsValue(metricsName, currentTotal, prefix, suffix)}
            </div>
            <div className="vs">
              vs
              {displayMetricsPreviousValue(
                previousTotal,
                metricsName,
                prefix,
                suffix,
              )}
            </div>
            {difference === 0 ? (
              <div className="perentage-value mt-3 pt-1">0%</div>
            ) : difference ? (
              difference > 0 ? (
                <div className="perentage-value mt-3 pt-1">
                  <img
                    className="green-arrow"
                    src={ArrowUpIcon}
                    alt="arrow-up"
                  />
                  {difference}%
                </div>
              ) : (
                <div className="perentage-value down mt-3 pt-1">
                  <img
                    className="red-arrow"
                    src={ArrowDownIcon}
                    alt="arrow-down"
                  />
                  {difference.toString().replace('-', '')}%
                </div>
              )
            ) : (
              <div className="perentage-value grey mt-3 pt-1">N/A</div>
            )}
          </div>
        </CardMetrics>
      </div>
    );
  };

  // display vendor metrics
  const displayVendorMetricsSection = () => {
    return (
      <div className="row">
        {selectedVendorMetricsType?.value === 'orderedRevenue' ? (
          <>
            {vendorOrderedRevenueMetrics?.map((item) => {
              return displayMetricsBox(
                item?.key,
                item?.className,
                item?.metricsClass,
                item?.key === 'orderedRevenue' ? currencySymbol : null,
                item?.key === 'conversionRate' ? '%' : null,
              );
            })}
          </>
        ) : (
          <>
            {vendorShippedCOGsMetrics?.map((item) => {
              return displayMetricsBox(
                item?.key,
                item?.className,
                item?.metricsClass,
                item?.key === 'shippedCOGs' ? currencySymbol : null,
                item?.key === 'conversionRate' ? '%' : null,
              );
            })}
          </>
        )}
      </div>
    );
  };

  // display sales group by section
  const displaySaleGroupBy = () => {
    return (
      <div className="row my-2">
        {Object.keys(selectedVendorMetrics)?.length <= 2 ? (
          <div className="col-md-5 col-sm-12 order-md-1 order-2 mt-2">
            {renderRecentAndPreviousLabel(
              selectedVendorMetrics,
              selectedSalesDF,
            )}
          </div>
        ) : (
          <div className="col-md-5 col-sm-12 order-md-1 order-2 mt-2" />
        )}
        <div className="col-md-7 col-sm-12 order-md-2 order-1">
          {displayDailyWeeklyMonthlyToggleButton()}
        </div>
      </div>
    );
  };

  // display vendor sales performance graph
  const displaySalesPerformanceGraph = () => {
    return isApiCall ? (
      <PageLoader
        component="performance-graph"
        color="#FF5933"
        type="detail"
        width={40}
        height={40}
      />
    ) : vendorSalesMetricsData?.length !== 0 ? (
      <VendorSalePerformanceChart
        chartId="vendorSalesPerformanceGraph"
        chartData={vendorSalesMetricsData}
        currencySymbol={currencySymbol}
        selectedMetrics={selectedVendorMetrics}
        selectedDF={selectedSalesDF?.value}
      />
    ) : (
      <NoData>{noGraphDataMessage}</NoData>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div className="vendor-metrics-section">
      {showMetricsTooltipForVendorSales?.map((key) => {
        return (
          <Fragment key={key}>
            {!vendorSalesCurrentTotal?.[key] &&
            !vendorSalesPreviousTotal?.[key] &&
            !isApiCall ? (
              <ReactTooltip
                id={key}
                class="initialTextTransform"
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />
            ) : null}
          </Fragment>
        );
      })}
      {displayVendorMetricsSection()}
      {displaySaleGroupBy()}
      {displaySalesPerformanceGraph()}
    </div>
  );
}

/* ********** MAIN FUNCTION END HERE ********** */

/* ********** Declaring prop types ********** */
VendorSalesPerformance.defaultProps = {
  // string
  currencySymbol: '$',
  // boolean
  isApiCall: false,
  // object
  selectedSalesDF: {},
  vendorSalesDifference: {},
  selectedVendorMetrics: {},
  vendorSalesCurrentTotal: {},
  vendorSalesPreviousTotal: {},
  selectedVendorMetricsType: {},
  // array
  vendorSalesMetricsData: [],
  // function
  setSelectedVendorMetrics: () => {},
  renderRecentAndPreviousLabel: () => {},
  displayDailyWeeklyMonthlyToggleButton: () => {},
};

VendorSalesPerformance.propTypes = {
  // string
  currencySymbol: string,
  // boolean
  isApiCall: bool,
  // object
  selectedSalesDF: shape({}),
  vendorSalesDifference: shape({}),
  selectedVendorMetrics: shape({}),
  vendorSalesCurrentTotal: shape({}),
  vendorSalesPreviousTotal: shape({}),
  selectedVendorMetricsType: shape({}),
  // array
  vendorSalesMetricsData: arrayOf(shape({})),
  // function
  setSelectedVendorMetrics: func,
  renderRecentAndPreviousLabel: func,
  displayDailyWeeklyMonthlyToggleButton: func,
};
