// react imports
import React, { useState, useEffect, useCallback } from 'react';

// third party imports
import dayjs from 'dayjs';
import styled from 'styled-components';
import { components } from 'react-select';
import { arrayOf, bool, shape } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

// local file imports
import Theme from '../../../../../theme/Theme';
import AMCAudienceMetrics from './AMCAudienceMetrics';
import AMCAudienceTable from './AMCAudienceTable';
import { getDSPAudienceDashboardData } from '../../../../../api';
import {
  orderByFilterOptions,
  statusFilterOptions,
  timeFrameOptionsOfAMCAudience,
} from '../../../../../constants';
import {
  WhiteCard,
  SingleSelectDropdown,
  ViewData,
  DropdownIndicator,
  NoRecordFound,
  PageLoader,
  CommonPagination,
  CustomMonthRangeModal,
  GrayMessageBar,
  DropdownOptions,
} from '../../../../../common';

function AMCAudienceSummaryContainer({
  showAMCAudienceLookalike,
  selectedBp,
  selectedDSPManager,
  selectedMarketplace,
}) {
  const { SingleValue } = components;
  const [selectedViewDataFilter, setSelectedViewDataFilter] = useState(
    orderByFilterOptions[0],
  );
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(
    statusFilterOptions[0],
  );
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(
    timeFrameOptionsOfAMCAudience[0],
  );
  const [amcAudienceSummaryData, setAMCAudienceSummaryData] = useState([]);
  const [amcAudienceData, setAMCAudienceData] = useState([]);
  const [loader, setLoader] = useState({
    type: 'page',
    flag: false,
  });
  const [dspAudienceCount, setDSPAudienceCount] = useState(null);
  const [pageNumber, setPageNumber] = useState();
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [showCustomMonthModal, setShowCustomMonthModal] = useState(false);
  const [isCustomMonthApply, setIsCustomMonthApply] = useState(true);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customMonth, setCustomMonth] = useState([
    {
      startMonth: new Date(),
      endMonth: null,
    },
  ]);
  const [appliedDate, setAppliedDate] = useState(customMonth);

  // https://bbe.atlassian.net/browse/PDV-8930
  // Show dynamic messages in AMC
  const dynamicMessages = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  const amcAudienceMessage = dynamicMessages?.amc_audience?.placeholder || null;

  // callback function for get dsp audience data
  const getAudienceData = useCallback(
    (currentPage) => {
      setLoader({ type: 'page', flag: true });

      getDSPAudienceDashboardData({
        page: currentPage,
        downloadFlag: false,
        status: selectedStatusFilter?.value,
        timeframe: selectedTimeFilter?.value,
        orderBy: selectedViewDataFilter?.value,
        start_date: customStartDate,
        end_date: customEndDate,
        dsp_ad_manager: selectedDSPManager,
        marketplace: selectedMarketplace,
        customer: selectedBp,
        showAMCAudienceLookalike,
      }).then((res) => {
        setLoader({ type: 'page', flag: false });

        if (res?.status === 200) {
          setAMCAudienceSummaryData(res?.data?.results);
          setAMCAudienceData(res?.data);
          setDSPAudienceCount(res?.data?.count);
          setPageNumber(currentPage);
        }
      });
    },
    [
      selectedStatusFilter,
      selectedTimeFilter,
      selectedViewDataFilter,
      customStartDate,
      customEndDate,
      selectedDSPManager,
      selectedMarketplace,
      selectedBp,
      showAMCAudienceLookalike,
    ],
  );

  useEffect(() => {
    getAudienceData(1);
  }, [getAudienceData]);

  // get dropdwon indicator for  dropdown filter
  const getSelectDropdownIndicator = () => {
    return {
      DropdownIndicator,
    };
  };

  // handle downlod button events
  const handleDownloadBtn = () => {
    setDownloadLoader(true);
    const link = document.createElement('a');
    getDSPAudienceDashboardData({
      page: pageNumber,
      downloadFlag: true,
      status: selectedStatusFilter?.value,
      timeframe: selectedTimeFilter?.value,
      orderBy: selectedViewDataFilter?.value,
      start_date: customStartDate,
      end_date: customEndDate,
      dsp_ad_manager: selectedDSPManager,
      marketplace: selectedMarketplace,
      customer: selectedBp,
      showAMCAudienceLookalike,
    }).then((res) => {
      setDownloadLoader(false);

      if (res?.status === 200) {
        setDownloadUrl(res?.data?.url);
        link.href = res?.data?.url;
        link.click();
      }
    });
  };

  // handle sorting filter events
  const handleViewDataForFilterChange = (event) => {
    setSelectedViewDataFilter(event);
  };

  // handle status filter events
  const handleStatusFilterChange = (event) => {
    setSelectedStatusFilter(event);
  };

  // handle pagination events
  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getAudienceData(currentPage);
  };

  // handle time frame filter events
  const handleTimeFrameFilter = (event) => {
    const { value } = event;
    if (value !== 'custom') {
      setSelectedTimeFilter(event);
      setIsCustomMonthApply(false);
    } else if (value === 'custom') {
      setShowCustomMonthModal(true);
    }
  };

  // calling goals api when date changes
  const applyDateChangeEvents = (startDate, endDate) => {
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
  };

  // handle custom date range changes events
  const handleCustomDateRangeEvents = (startDate, endDate, dateFlag) => {
    const newEndDate = endDate || startDate;
    let tempStartDate = startDate;
    let tempEndDate = newEndDate;
    if (dateFlag === 'custom') {
      tempStartDate = dayjs(startDate).format('DD-MM-YYYY');
      tempEndDate = dayjs(tempEndDate).format('DD-MM-YYYY');
      applyDateChangeEvents(tempStartDate, tempEndDate);
    }
  };

  // apply custom month range
  const applyCustomMonthRange = () => {
    if (selectedTimeFilter?.value !== 'custom')
      setSelectedTimeFilter({
        value: 'custom',
        label: 'Custom Range',
        sub: 'Select start & end date',
      });

    setIsCustomMonthApply(true);
    setAppliedDate(customMonth);
    handleCustomDateRangeEvents(
      customMonth?.[0]?.startMonth,
      customMonth?.[0]?.endMonth,
      'custom',
    );
    setShowCustomMonthModal(false);
  };

  // Handeling the custom month range selection
  const onMonthChange = (date) => {
    const [startMonth, endMonth] = date;

    setCustomMonth([
      {
        startMonth,
        endMonth,
      },
    ]);
  };

  // set default date
  const setDefaultDate = () => {
    if (isCustomMonthApply) {
      setCustomMonth([
        {
          startMonth: appliedDate?.[0]?.startMonth,
          endMonth: appliedDate?.[0]?.endMonth,
        },
      ]);
    }
  };

  // display custom start and end date
  const renderCustomDateSubLabel = (data) => {
    if (selectedTimeFilter.value === 'custom' && isCustomMonthApply) {
      return `From- ${dayjs(appliedDate?.[0]?.startMonth).format(
        'MMM, YYYY',
      )}  To- ${dayjs(
        appliedDate?.[0]?.endMonth
          ? appliedDate?.[0]?.endMonth
          : appliedDate?.[0]?.startMonth,
      ).format('MMM, YYYY')}`;
    }
    return data.sub;
  };

  const singleFilterOption = ({ data, ...props }) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{data?.label}</span>
      <div style={{ fontSize: '10px' }}>{renderCustomDateSubLabel(data)}</div>
    </SingleValue>
  );

  const getSelectComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  // display reat select dropdown filter
  const displayDropdownFilter = (
    filterId,
    selectedValue,
    dropdownOptions,
    onChangeHandler,
    dropdownComponents,
  ) => {
    return (
      <SingleSelectDropdown
        filterId={filterId}
        placeholder={`Select `}
        className="single-select-dropdown"
        isApiCall={loader?.flag}
        dropdownOptions={dropdownOptions}
        selectedValue={selectedValue}
        onChangeHandler={(event) => onChangeHandler(event)}
        dropdownComponents={dropdownComponents}
        isSearchable={false}
      />
    );
  };

  // display dropdown button html
  const displayDownloadBtn = () => {
    return (
      <DownloadReport>
        <a
          onClick={() => {
            handleDownloadBtn();
          }}
          className="download-pdf-link"
          href={downloadUrl}
          download
        >
          {downloadLoader ? (
            <PageLoader color="#FF5933" type="button" />
          ) : (
            <>
              <FontAwesomeIcon
                icon="fal fa-download"
                data-for="downloadReportInfo"
                color={Theme.orange}
                style={{
                  marginRight: '5px',
                }}
              />
              Download Report
            </>
          )}
        </a>
      </DownloadReport>
    );
  };

  // render modals
  const renderModals = () => {
    return (
      <CustomMonthRangeModal
        id="BT-AmcAudiennnceMonthPicker"
        isOpen={showCustomMonthModal}
        title="Select Month Range"
        className="custom-month-range"
        minDate={new Date('06-01-2023')}
        maxDate={new Date('06-01-3023')}
        startMonth={customMonth?.[0]?.startMonth}
        endMonth={customMonth?.[0]?.endMonth}
        onClick={() => {
          setShowCustomMonthModal(false);
          setDefaultDate();
        }}
        onDateChange={onMonthChange}
        onApply={() => {
          applyCustomMonthRange();
        }}
      />
    );
  };

  // display amc audience Left Hand Side content
  const displayAMCAudienceLeftHandSideSection = () => {
    return (
      <WhiteCard className="mb-3">
        <ViewData>
          <div className="row">
            <div className="col-md-3 col-12">
              <div className="row mb-2 mb-md-0">
                <div className="col-6 col-md-12">
                  <div className="view-data-for mt-md-2 pt-md-1">
                    View data for:
                  </div>
                </div>
                <div className="col-6 col-md-12 d-md-none text-right">
                  {amcAudienceSummaryData?.length ? displayDownloadBtn() : ''}
                </div>
              </div>
            </div>
            <div className="col-md-9 col-12">
              <div className="row">
                <div className="col-md-3 col-12 text-right pt-md-2 cursor">
                  <div className="d-none d-md-block">
                    {amcAudienceSummaryData?.length ? displayDownloadBtn() : ''}
                  </div>
                </div>
                <div className="col-md-3 col-12 mb-3 mb-md-0 pl-md-0">
                  {displayDropdownFilter(
                    'BT-DspAudienceFilter',
                    selectedViewDataFilter,
                    orderByFilterOptions,
                    handleViewDataForFilterChange,
                    getSelectDropdownIndicator,
                  )}
                </div>
                <div className="col-md-3 col-12 mb-3 mb-md-0 pl-md-0">
                  {displayDropdownFilter(
                    'BT-AmcStatusFilter',
                    selectedStatusFilter,
                    statusFilterOptions,
                    handleStatusFilterChange,
                    getSelectDropdownIndicator,
                  )}
                </div>
                <div className="col-md-3 col-12 pl-md-0">
                  {displayDropdownFilter(
                    'BT-AmcTimeFrameFilter',
                    selectedTimeFilter,
                    timeFrameOptionsOfAMCAudience,
                    handleTimeFrameFilter,
                    getSelectComponents,
                  )}
                </div>
              </div>
            </div>
          </div>
        </ViewData>
      </WhiteCard>
    );
  };

  // display amc audience right Hand Side content
  const displayAMCAudienceRightHandSideSection = () => {
    return (
      <WhiteCard>
        {/* https://bbe.atlassian.net/browse/PDV-8930 - Show dynamic messages in AMC */}
        {amcAudienceMessage ? (
          <GrayMessageBar message={amcAudienceMessage} />
        ) : null}
        {amcAudienceSummaryData?.length ||
        Object.keys(amcAudienceSummaryData).length ? (
          <>
            <AMCAudienceMetrics
              showAMCAudienceLookalike={showAMCAudienceLookalike}
              amcAudienceData={amcAudienceData}
            />
            <div id="BT-DspAudienceTableSection">
              <AMCAudienceTable
                showAMCAudienceLookalike={showAMCAudienceLookalike}
                amcAudienceSummaryData={amcAudienceSummaryData}
              />
            </div>
            <CommonPagination
              count={dspAudienceCount}
              pageNumber={pageNumber}
              handlePageChange={handlePageChange}
            />
          </>
        ) : (
          <div className="row">
            <NoRecordFound
              type="brand"
              title="We looked high and low, but…"
              subTitle="No records found."
            />
          </div>
        )}
        {renderModals()}
      </WhiteCard>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div key="amc-audience-summary-dashboard">
      {loader?.flag && loader?.type === 'page' ? (
        <PageLoader
          component="performance-graph"
          color={Theme.orange}
          type="detail"
          width={40}
          height={40}
        />
      ) : (
        <>
          {displayAMCAudienceLeftHandSideSection()}
          {displayAMCAudienceRightHandSideSection()}
        </>
      )}
    </div>
  );
}

export default AMCAudienceSummaryContainer;

AMCAudienceSummaryContainer.defaultProps = {
  showAMCAudienceLookalike: false,
  selectedBp: [],
  selectedDSPManager: [],
  selectedMarketplace: {},
};

AMCAudienceSummaryContainer.propTypes = {
  showAMCAudienceLookalike: bool,
  selectedBp: arrayOf(shape({})),
  selectedDSPManager: arrayOf(shape({})),
  selectedMarketplace: shape({}),
};

const DownloadReport = styled.div`
  .download-pdf {
    cursor: pointer;
    font-size: ${Theme.extraNormal};
    .download-pdf-link {
      color: ${Theme.black};
    }

    .download-pdf-icon {
      width: 20px;
      margin-right: 7px;
      vertical-align: text-top;

      &.upload-icon {
        transform: rotate(180deg);
      }
    }
  }
`;
