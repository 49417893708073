import React from 'react';

import { arrayOf, func, number } from 'prop-types';

const OneTimeInvoiceAdjustConfirm = ({
  adjustmentData,
  totalNewBudget,
  formatNumber,
}) => {
  const renderDesktopView = () => {
    return (
      <div>
        <div className="row">
          <div className="col-4 pr-2 text-left">
            <div className="label">Marketplace</div>
          </div>
          <div className="col-4 px-1 text-left" />
          <div className="col-4 pl-1 text-left">
            <div className="label">Additional Budget</div>
          </div>
        </div>
        <div className=" straight-line horizontal-line pt-1 mb-2 " />

        {adjustmentData &&
          adjustmentData.length > 0 &&
          adjustmentData.map((item) => {
            const textClass =
              item.change && item.change !== 0
                ? 'normal-text text-bold'
                : 'gray-normal-text';
            return (
              <div key={item.id} className="row mt-1">
                <div className="col-4 pr-2 text-left">
                  <div className={textClass}>{item.marketplace}</div>
                </div>
                <div className="col-4 px-1 text-left" />
                <div className="col-4 pl-1 text-left">
                  <div className={textClass}>
                    {!item.newAmount || item.newAmount === 0
                      ? '-'
                      : `$${item.newAmount}`}
                  </div>
                </div>
              </div>
            );
          })}

        <div className=" straight-line horizontal-line pt-1 mb-2 " />
        <div className="row">
          <div className="col-4 pr-2 text-left">
            <div className="normal-text text-bold">One-time invoice</div>
          </div>
          <div className="col-4 px-1 text-left" />
          <div className="col-4 pl-1 text-left">
            <div className="normal-text text-bold">
              {formatNumber(totalNewBudget, '$')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <>{renderDesktopView()}</>;
};

export default OneTimeInvoiceAdjustConfirm;

OneTimeInvoiceAdjustConfirm.defaultProps = {
  adjustmentData: [],
};

OneTimeInvoiceAdjustConfirm.propTypes = {
  adjustmentData: arrayOf(Array),
  totalNewBudget: number.isRequired,
  formatNumber: func.isRequired,
};
