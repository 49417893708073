// react imports
import React, { useState } from 'react';

// third party imports
import { useMediaQuery } from 'react-responsive';
import { components } from 'react-select';
import { arrayOf, bool, func, shape, string } from 'prop-types';

// local file imports
import { CaretUp } from '../../../../theme/images';
import {
  WhiteCard,
  DropdownIndicator,
  MultiSelectSearchableDropdown,
  SingleSelectDropdown,
  DropdownOptions,
} from '../../../../common';

const DSPFilter = ({
  // boolean
  isApiCall,
  isBGSAdmin,
  isSpreetailRole,
  isBGSManager,
  isAdManagerAdmin,
  isAdTeamManager,
  // objects
  selectedMarketplace,
  selectedBrandPartner,
  // array
  userList,
  managerUserList,
  selectedUser,
  selectedManagerUser,
  marketplaceOptions,
  brandPartnerOptions,
  // function
  handleResetFilter,
  handleMarketplace,
  setSelectedUser,
  setSelectedManagerUser,
  handleUserFilterEvents,
  handleUserOnMenuCloseEvents,
  handleManagerUserFilterEvents,
  handleManagerUserOnMenuCloseEvents,
  handledBrandPartnerFilterEvents,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const { SingleValue } = components;
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  // react select dropdown components - selected option
  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
    </SingleValue>
  );

  const getSelectComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  // render Brand Partner type dropdwon filter
  const renderBrandPartnerDropdownFilter = () => {
    return (
      <div className="col-12">
        <div className="label mt-3">Brand Partner</div>
        <div style={{ position: 'relative' }}>
          <SingleSelectDropdown
            filterId="BT-SalesBrandPartnerTypeFilter"
            className="single-select-dropdown"
            dropdownOptions={brandPartnerOptions}
            selectedValue={selectedBrandPartner}
            onChangeHandler={handledBrandPartnerFilterEvents}
            dropdownComponents={getSelectComponents}
            isApiCall={isApiCall}
            isSearchable
          />
        </div>
      </div>
    );
  };

  // render multi select dropdown for bgs and ad manager
  const renderUserDropdown = () => {
    return (
      <div className="col-12 col-md-6 col-lg-12">
        <div className="label mt-3">
          {isBGSAdmin || isBGSManager || isSpreetailRole ? 'BGS' : 'Ad Manager'}
        </div>

        <div id="user-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-dspBgsFilter"
            options={userList?.filter((option) => option?.value !== 'all')}
            label=""
            value="user"
            multiSelectedValues={{ user: selectedUser }}
            setMultiSelectedValues={setSelectedUser}
            handleMultiSelectChange={handleUserFilterEvents}
            handleOnMenuClose={handleUserOnMenuCloseEvents}
            isLoading={isApiCall}
            isSearchable
          />
        </div>
      </div>
    );
  };

  // render multi select dropdown for bgs manager and ad team manager
  const renderManagerUserDropdown = (label) => {
    return (
      <div className="col-12 col-md-6 col-lg-12">
        <div className="label mt-3">{label}</div>
        <div id="manager-user-filter" style={{ position: 'relative' }}>
          <MultiSelectSearchableDropdown
            filterId="BT-dspManagerFilter"
            options={managerUserList?.filter(
              (option) => option?.value !== 'all',
            )}
            label=""
            value="managerUser"
            multiSelectedValues={{ managerUser: selectedManagerUser }}
            setMultiSelectedValues={setSelectedManagerUser}
            handleMultiSelectChange={handleManagerUserFilterEvents}
            handleOnMenuClose={handleManagerUserOnMenuCloseEvents}
            isLoading={isApiCall}
            isSearchable
          />
        </div>
      </div>
    );
  };

  // render marketplace dropdown filter
  const renderMarketplaceDropdownFilter = () => {
    return (
      <div className="col-lg-12 col-md-6">
        <div className="label mt-3">Marketplace</div>

        <div style={{ position: 'relative' }}>
          <SingleSelectDropdown
            filterId="BT-dsp-countryfilter"
            className="single-select-dropdown"
            dropdownOptions={marketplaceOptions}
            selectedValue={selectedMarketplace}
            onChangeHandler={handleMarketplace}
            dropdownComponents={getSelectComponents}
            isApiCall={isApiCall}
            isSearchable
          />
        </div>
      </div>
    );
  };

  // render filters
  const renderFilters = () => (
    <>
      {renderBrandPartnerDropdownFilter()}
      {renderMarketplaceDropdownFilter()}
      {isBGSAdmin || isAdManagerAdmin || isSpreetailRole
        ? renderManagerUserDropdown(
            isBGSAdmin || isSpreetailRole ? 'BGS Manager' : 'Ad Team Manager',
          )
        : null}
      {isBGSManager ||
      isBGSAdmin ||
      isAdManagerAdmin ||
      isAdTeamManager ||
      isSpreetailRole
        ? renderUserDropdown(
            isBGSAdmin || isBGSManager || isSpreetailRole
              ? 'BGS'
              : 'Ad Manager',
          )
        : null}
    </>
  );

  /* ********** Main return statement of this component ********** */
  return (
    <div>
      <WhiteCard
        onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        className={
          isDesktop ? 'mb-3 d-lg-block d-none' : 'mb-3 d-lg-none d-block'
        }
      >
        <div className="row ">
          <div className="col-6">
            <div className="black-heading-title">Filters</div>
          </div>
          <div className={isDesktop ? 'col-6 text-right' : 'col-6 text-right'}>
            <p
              onClick={() => handleResetFilter()}
              role="presentation"
              className={`gray-normal-text m-0 cursor ${
                isApiCall ? 'disabled' : ''
              }`}
            >
              Reset filters
              {!isDesktop && (
                <span
                  style={{
                    borderLeft: '1px solid #e2e2ea',
                    marginLeft: '10px',
                    marginRight: '20px',
                  }}
                >
                  <span
                    role="presentation"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsCollapseOpen(!isCollapseOpen);
                    }}
                  >
                    <img
                      src={CaretUp}
                      alt="caret"
                      style={{
                        transform: isCollapseOpen ? 'rotate(180deg)' : '',
                        width: '25px',
                        height: '25px',
                        position: 'absolute',
                        right: '5px',
                        top: '-3px',
                      }}
                    />
                  </span>
                </span>
              )}
            </p>
          </div>

          {isDesktop
            ? renderFilters()
            : isCollapseOpen
            ? renderFilters()
            : null}
        </div>
      </WhiteCard>
    </div>
  );
};

export default DSPFilter;

DSPFilter.defaultProps = {
  // boolean
  isApiCall: false,
  isBGSAdmin: false,
  isSpreetailRole: false,
  isBGSManager: false,
  isAdManagerAdmin: false,
  isAdTeamManager: false,
  // objects
  data: {},
  selectedMarketplace: {},
  selectedBrandPartner: {},
  // array
  userList: [],
  managerUserList: [],
  selectedUser: [],
  selectedManagerUser: [],
  marketplaceOptions: [],
  brandPartnerOptions: [],
  // function
  handleResetFilter: () => {},
  handleMarketplace: () => {},
  setSelectedUser: () => {},
  setSelectedManagerUser: () => {},
  handleUserFilterEvents: () => {},
  handleUserOnMenuCloseEvents: () => {},
  handleManagerUserFilterEvents: () => {},
  handleManagerUserOnMenuCloseEvents: () => {},
  handledBrandPartnerFilterEvents: () => {},
};

DSPFilter.propTypes = {
  // boolean
  isApiCall: bool,
  isBGSAdmin: bool,
  isSpreetailRole: bool,
  isBGSManager: bool,
  isAdManagerAdmin: bool,
  isAdTeamManager: bool,
  // objects
  data: shape({ sub: string, label: string }),
  selectedMarketplace: shape({}),
  selectedBrandPartner: shape({}),
  // array
  userList: arrayOf(shape({})),
  managerUserList: arrayOf(shape({})),
  selectedUser: arrayOf(shape({})),
  selectedManagerUser: arrayOf(shape({})),
  marketplaceOptions: arrayOf(shape({})),
  brandPartnerOptions: arrayOf(shape({})),
  // function
  handleResetFilter: func,
  handleMarketplace: func,
  setSelectedUser: func,
  setSelectedManagerUser: func,
  handleUserFilterEvents: func,
  handleUserOnMenuCloseEvents: func,
  handleManagerUserFilterEvents: func,
  handleManagerUserOnMenuCloseEvents: func,
  handledBrandPartnerFilterEvents: func,
};
