import React, { useEffect, useState } from 'react';

import { arrayOf, func, shape, string } from 'prop-types';

import { CheckBox, ModalRadioCheck } from '../../../../common';

export default function MonthlyServicesSubTabs({
  tabName,
  accountType,
  monthlyService,
  formData,
  setFormData,
  parentServiceName,
  contractAddendumDetails,
  updatedFormData,
  setUpdatedFormData,
  originalData,
  additionalMonthlyServices,
  setMonthlyAdditionalServices,
  showFooter,
  fetchUncommonOptions,
}) {
  const [showSubTabs, setShowSubTabs] = useState({
    isOpen: false,
    account_type: null,
  });
  const [subTabOptions, setSubTabOptions] = useState(null);

  const parentServiceNameForDependencyArray = formData?.[parentServiceName];

  useEffect(() => {
    // If the services are present in the formData then set that sub tab option true.
    if (formData?.[parentServiceName]?.length && tabName) {
      const includesSubTabs = formData?.[parentServiceName]?.filter(
        (service) =>
          (service?.name?.includes(tabName) ||
            service?.service?.name?.includes(tabName)) &&
          service?.account_type === accountType,
      );
      if (includesSubTabs?.length) {
        setShowSubTabs({
          isOpen: true,
          account_type: accountType,
        });
      }
    }

    // Seperate Sub tabs form services.
    if (monthlyService?.length && tabName) {
      const result = monthlyService?.filter((service) =>
        service?.name?.includes(tabName),
      );
      setSubTabOptions(result);
    }
  }, [
    formData,
    parentServiceNameForDependencyArray,
    monthlyService,
    tabName,
    parentServiceName,
    accountType,
  ]);

  useEffect(() => {
    // function to show not added services in contract
    fetchUncommonOptions(
      monthlyService,
      additionalMonthlyServices.create,
      'monthly_service',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyService, additionalMonthlyServices.create]);

  const handleServices = (event, service, type) => {
    showFooter(true);
    if (type === 'radio') {
      const createService = {
        account_type: accountType,
        name: service?.name,
        service_id: service?.id,
      };
      // Based on contract/addendum add contract_id OR contract_addendum_id
      if (contractAddendumDetails?.notActive) {
        createService.contract_addendum_id = contractAddendumDetails?.id;
      } else {
        createService.contract_id = formData?.id;
      }

      // Set service on formData
      if (
        !formData?.[parentServiceName]?.find(
          (item) =>
            (item?.name
              ? item?.name?.includes(tabName)
              : item?.service?.name?.includes(tabName)) &&
            item?.account_type?.includes(accountType),
        )
      ) {
        if (formData?.[parentServiceName]) {
          setFormData({
            ...formData,
            [parentServiceName]: [
              ...formData?.[parentServiceName],
              createService,
            ],
          });
        } else {
          setFormData({
            ...formData,
            [parentServiceName]: [createService],
          });
        }
      } else {
        // If service is not previously added in formData
        // Remove previously added service and add a new service
        const filteredServices = formData?.[parentServiceName]?.filter((item) =>
          item?.name?.includes(tabName) ||
          item?.service?.name?.includes(tabName)
            ? item?.account_type !== accountType
            : item,
        );
        if (filteredServices?.length) {
          setFormData({
            ...formData,
            [parentServiceName]: [...filteredServices, createService],
          });
        } else {
          setFormData({
            ...formData,
            [parentServiceName]: [createService],
          });
        }
      }

      //  Set service in  updatedFormData and setMonthlyAdditionalServices
      if (
        !additionalMonthlyServices?.create?.find(
          (item) =>
            (item?.name
              ? item?.name?.includes(tabName)
              : item?.service?.name?.includes(tabName)) &&
            item?.account_type?.includes(accountType),
        )
      ) {
        if (additionalMonthlyServices?.create?.length) {
          setUpdatedFormData({
            ...updatedFormData,
            [parentServiceName]: {
              ...additionalMonthlyServices,
              create: [...additionalMonthlyServices?.create, createService],
            },
          });
          setMonthlyAdditionalServices({
            ...additionalMonthlyServices,
            create: [...additionalMonthlyServices.create, createService],
          });
        } else {
          setUpdatedFormData({
            ...updatedFormData,
            [parentServiceName]: {
              ...additionalMonthlyServices,
              create: [createService],
            },
          });
          setMonthlyAdditionalServices({
            ...additionalMonthlyServices,
            create: [createService],
          });
        }
      } else {
        // Remove previously added service and add a new service
        const filteredServices = additionalMonthlyServices?.create?.filter(
          (item) =>
            item?.name?.includes(tabName) ||
            item?.service?.name?.includes(tabName)
              ? item?.account_type !== accountType
              : item,
        );
        if (filteredServices?.length) {
          setUpdatedFormData({
            ...updatedFormData,
            [parentServiceName]: {
              ...updatedFormData[parentServiceName],
              create: [...filteredServices, createService],
            },
          });
          setMonthlyAdditionalServices({
            ...additionalMonthlyServices,
            create: [...filteredServices, createService],
          });
        } else {
          setUpdatedFormData({
            ...updatedFormData,
            [parentServiceName]: {
              ...updatedFormData[parentServiceName],
              create: [createService],
            },
          });
          setMonthlyAdditionalServices({
            ...additionalMonthlyServices,
            create: [createService],
          });
        }
      }
    }
  };

  const handleChangeSubTabs = (event) => {
    showFooter(true);
    if (event.target.checked) {
      // Get first subtab name
      const firstSubTabService = subTabOptions?.find(
        (item) => item.name === 'Inventory Management Standard Service',
      );

      const createService = {
        account_type: accountType,
        name: firstSubTabService?.name,
        service_id: firstSubTabService?.id,
      };
      // Based on contract/addendum add contract_id OR contract_addendum_id
      if (contractAddendumDetails?.notActive) {
        createService.contract_addendum_id = contractAddendumDetails?.id;
      } else {
        createService.contract_id = formData?.id;
      }
      const serviceInOriginalData = originalData?.[parentServiceName]?.filter(
        (item) =>
          (item?.name?.includes(tabName) ||
            item?.service?.name?.includes(tabName)) &&
          item?.account_type === accountType,
      );

      // if services is available in original data then add else add new service
      if (serviceInOriginalData?.length) {
        // check and set FormData
        if (formData?.[parentServiceName]?.length) {
          setFormData({
            ...formData,
            [parentServiceName]: [
              ...formData[parentServiceName],
              ...serviceInOriginalData,
            ],
          });
        } else {
          setFormData({
            ...formData,
            [parentServiceName]: [...serviceInOriginalData],
          });
        }
        // check and set Updated formData
        if (updatedFormData?.[parentServiceName]?.create?.length) {
          setUpdatedFormData({
            ...updatedFormData,
            [parentServiceName]: {
              ...updatedFormData[parentServiceName],
              create: [
                ...updatedFormData[parentServiceName].create,
                ...serviceInOriginalData,
              ],
              delete: updatedFormData[parentServiceName]?.delete?.filter(
                (item) => item !== serviceInOriginalData[0].id,
              ),
            },
          });
        } else {
          setUpdatedFormData({
            ...updatedFormData,
            [parentServiceName]: {
              ...updatedFormData[parentServiceName],
              create: [...serviceInOriginalData],
              delete: updatedFormData[parentServiceName]?.delete.filter(
                (item) => item !== serviceInOriginalData[0].id,
              ),
            },
          });
        }
        // check and set monthly additional service
        if (additionalMonthlyServices?.create?.length) {
          setMonthlyAdditionalServices({
            ...additionalMonthlyServices,
            create: [
              ...additionalMonthlyServices.create,
              ...serviceInOriginalData,
            ],
            delete: additionalMonthlyServices.delete.filter(
              (item) => item !== serviceInOriginalData[0].id,
            ),
          });
        } else {
          setMonthlyAdditionalServices({
            ...additionalMonthlyServices,
            create: [...serviceInOriginalData],
            delete: additionalMonthlyServices.delete.filter(
              (item) => item !== serviceInOriginalData[0].id,
            ),
          });
        }
      } else {
        // else add new service
        // check and set FormData
        if (formData?.[parentServiceName]?.length) {
          setFormData({
            ...formData,
            [parentServiceName]: [
              ...formData[parentServiceName],
              createService,
            ],
          });
        } else {
          setFormData({
            ...formData,
            [parentServiceName]: [createService],
          });
        }
        // check and set Updated formData
        if (updatedFormData?.[parentServiceName]?.create?.length) {
          setUpdatedFormData({
            ...updatedFormData,
            [parentServiceName]: {
              ...updatedFormData[parentServiceName],
              create: [
                ...updatedFormData[parentServiceName].create,
                createService,
              ],
            },
          });
        } else {
          setUpdatedFormData({
            ...updatedFormData,
            [parentServiceName]: {
              ...updatedFormData[parentServiceName],
              create: [createService],
            },
          });
        }
        setMonthlyAdditionalServices({
          ...additionalMonthlyServices,
          create: [...additionalMonthlyServices.create, createService],
        });
      }
    } else {
      // if checkbox is unckeck
      setFormData({
        ...formData,
        [parentServiceName]: formData?.[parentServiceName]?.filter(
          (item) =>
            !(
              (item?.name?.includes(tabName) ||
                item?.service?.name?.includes(tabName)) &&
              item?.account_type === accountType
            ),
        ),
      });

      const filterCreateServiceOfUpdatedFormData =
        additionalMonthlyServices?.create?.filter(
          (item) =>
            !(
              (item?.name?.includes(tabName) ||
                item?.service?.name?.includes(tabName)) &&
              item?.account_type === accountType
            ),
        );

      const deleteIdOfUpdatedFormData = originalData?.[parentServiceName]?.find(
        (item) =>
          item?.service?.name?.includes(tabName) &&
          item?.account_type === accountType
            ? item?.id
            : null,
      )?.id;

      if (updatedFormData?.[parentServiceName]?.delete?.length) {
        setUpdatedFormData({
          ...updatedFormData,
          [parentServiceName]: {
            create: filterCreateServiceOfUpdatedFormData?.length
              ? filterCreateServiceOfUpdatedFormData
              : [],
            delete: [
              ...updatedFormData[parentServiceName].delete,
              deleteIdOfUpdatedFormData,
            ],
          },
        });
        setMonthlyAdditionalServices({
          ...additionalMonthlyServices,
          create: filterCreateServiceOfUpdatedFormData?.length
            ? filterCreateServiceOfUpdatedFormData
            : [],
          delete: [
            ...additionalMonthlyServices.delete,
            deleteIdOfUpdatedFormData,
          ],
        });
      } else {
        setUpdatedFormData({
          ...updatedFormData,
          [parentServiceName]: {
            ...updatedFormData?.[parentServiceName],
            create: filterCreateServiceOfUpdatedFormData?.length
              ? filterCreateServiceOfUpdatedFormData
              : [],
            delete: [deleteIdOfUpdatedFormData],
          },
        });
        setMonthlyAdditionalServices({
          ...additionalMonthlyServices,
          create: filterCreateServiceOfUpdatedFormData?.length
            ? filterCreateServiceOfUpdatedFormData
            : [],
          delete: [deleteIdOfUpdatedFormData],
        });
      }
    }
  };

  const findSavedService = () => {
    const savedService = formData?.[parentServiceName]?.find(
      (item) =>
        (item?.name?.includes(tabName) ||
          item?.service?.name?.includes(tabName)) &&
        item?.account_type === accountType,
    );
    return savedService?.name || savedService?.service?.name;
  };

  // Show subtabs of the service
  const displaySubTabs = () => {
    return subTabOptions?.map((service) => {
      return (
        <div className="col-12" key={service?.id}>
          <ModalRadioCheck style={{ padding: `0px 0px 17px 23px` }}>
            <label
              className="checkboxes radio-container customer-list"
              htmlFor={`${service?.name}-${accountType}`}
            >
              {service?.name?.replace(tabName, '')}

              <input
                type="radio"
                name={accountType}
                id={`${service?.name}-${accountType}`}
                value={service?.name}
                onChange={(event) => handleServices(event, service, 'radio')}
                defaultChecked={service?.name === findSavedService()}
              />
              <span className="checkmark checkmark-customer-list" />
            </label>
          </ModalRadioCheck>
        </div>
      );
    });
  };

  return (
    <>
      <div className="col-12">
        <CheckBox>
          <label
            className="check-container customer-pannel"
            htmlFor={`${tabName}-${accountType}`}
          >
            {tabName}
            <input
              type="checkbox"
              id={`${tabName}-${accountType}`}
              onClick={(event) => {
                setShowSubTabs({
                  isOpen: event.target.checked,
                  account_type: accountType,
                });
                handleChangeSubTabs(event);
              }}
              defaultChecked={
                formData?.[parentServiceName]?.length &&
                formData?.[parentServiceName]?.find(
                  (item) =>
                    (item?.name?.includes(tabName) ||
                      item?.service?.name?.includes(tabName)) &&
                    item?.account_type === accountType,
                )
              }
            />
            <span className="checkmark" />
          </label>
        </CheckBox>
      </div>
      {subTabOptions?.length &&
      showSubTabs?.isOpen &&
      showSubTabs?.account_type === accountType
        ? [displaySubTabs()]
        : ''}
    </>
  );
}

MonthlyServicesSubTabs.defaultProps = {
  tabName: '',
  accountType: '',
  monthlyService: [],
  formData: {},
  setFormData: () => {},
  parentServiceName: '',
  contractAddendumDetails: {},
  updatedFormData: {},
  setUpdatedFormData: () => {},
  originalData: {},
  additionalMonthlyServices: {},
  setMonthlyAdditionalServices: () => {},
  showFooter: () => {},
  fetchUncommonOptions: () => {},
};

MonthlyServicesSubTabs.propTypes = {
  tabName: string,
  accountType: string,
  monthlyService: arrayOf(shape({})),
  formData: shape({}),
  setFormData: func,
  parentServiceName: string,
  contractAddendumDetails: shape({}),
  updatedFormData: shape({}),
  setUpdatedFormData: func,
  originalData: shape({}),
  additionalMonthlyServices: shape({}),
  setMonthlyAdditionalServices: func,
  showFooter: func,
  fetchUncommonOptions: func,
};
