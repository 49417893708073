import React from 'react';

import ReactTooltip from 'react-tooltip';
import { string, func, arrayOf, shape } from 'prop-types';

import { InputFormField, CheckBox } from '../../../../common';
import AdvertisingDashboards from './AdvertisingDashboards';

function AdvertisingServices({
  advertisingDashboards,
  formData,
  setFormData,
  updatedFormData,
  setUpdatedFormData,
  selectedCurrency,
  contractAddendumDetails,
  details,
  setShowCollpase,
  showSection,
  showFooter,
  amcServiceError,
  setAmcServiceError,
  onAddDiscount,
  discountData,
  getAddedOrRemovedAMCServices,
  originalData,
}) {
  const standardDashboardObj = details?.amc_services?.find((item) =>
    item?.name.includes('Standard Dashboard'),
  )
    ? [
        details?.amc_services?.find((item) =>
          item?.name.includes('Standard Dashboard'),
        ),
      ]
    : [
        {
          name: 'Standard Dashboard',
          contract: contractAddendumDetails.notActive ? null : details?.id,
          contract_addendum: contractAddendumDetails.notActive
            ? contractAddendumDetails?.id
            : null,
        },
      ];
  const advancedDashboardObj = details?.amc_services?.find((item) =>
    item?.name.includes('Advanced Dashboard'),
  )
    ? [
        details?.amc_services?.find((item) =>
          item?.name.includes('Advanced Dashboard'),
        ),
      ]
    : [
        {
          name: 'Advanced Dashboard',
          contract: contractAddendumDetails.notActive ? null : details?.id,
          contract_addendum: contractAddendumDetails.notActive
            ? contractAddendumDetails?.id
            : null,
        },
      ];

  const advertisingServices = [
    {
      name: 'AMC Management',
      value: 'AMC Management',
      key: 'is_amc_management',
    },
  ];

  if (!formData?.contract_type?.includes('dsp')) {
    advertisingServices.unshift({
      name: 'DSP Advertising',
      value: 'DSP Advertising',
      key: 'is_dsp_advertising',
    });
  }

  const checkDspAdvertisingDisabledCondition = () => {
    if (originalData?.is_dsp_advertising) {
      // This if condition is for checking that -
      // original contract contains dsp service then
      // in addendum we allow user to uncheck and check dsp advertising service.
      return false;
    }
    if (contractAddendumDetails?.notActive) {
      // if its addendum then simply return !formData?.can_add_dsp_service
      // bcoz BE has handled already condition below
      // 'IF BP have DSP only agreement or another recurring agreement with DSP only service do not allow user to add DSP service'
      return !formData?.can_add_dsp_service;
    }
    return false;
  };

  const arrayMax = (arr) => {
    return arr.reduce((p, v) => {
      return p > v ? p : v;
    });
  };

  const getDSPMonthlyAdBudget = () => {
    if (formData?.is_dynamic_dsp_fee) {
      return arrayMax([
        Number(formData?.dsp_fee),
        Number(formData?.dsp_fee_second),
        Number(formData?.dsp_fee_third),
      ]);
    }
    return formData?.dsp_fee;
  };

  const monthlyAdBudget = getDSPMonthlyAdBudget();

  const showDiscountLabel = () => {
    const discount =
      discountData?.length &&
      discountData?.filter((item) =>
        item?.is_amc_service_discount ? item : null,
      );
    if (discount?.length && discount[0]?.type) {
      return 'Edit Discount';
    }
    return 'Add Discount';
  };

  const handleAmcServices = (event, amcData) => {
    showFooter(true);

    if (event?.target?.checked) {
      if (monthlyAdBudget && Number(monthlyAdBudget) < 50000) {
        amcData = {
          ...amcData,
          create: details?.amc_services?.length
            ? [...details?.amc_services]
            : [...standardDashboardObj],
        };
      }
      if (monthlyAdBudget && Number(monthlyAdBudget) >= 50000) {
        amcData = {
          ...amcData,
          create: [...standardDashboardObj, ...advancedDashboardObj],
        };
      }
    } else {
      const deletedServicesIds = [];
      if (formData?.amc_services?.length) {
        for (const item of formData?.amc_services) {
          if (Object.keys(item)?.includes('id')) {
            deletedServicesIds.push(item?.id);
          }
        }
      }

      amcData = {
        ...amcData,
        create: [],
        delete: deletedServicesIds?.length ? [...deletedServicesIds] : [],
      };
    }
    return amcData;
  };

  const handleChange = (event, serviceData) => {
    const dataToUpdate = {};
    let amcData = {
      create: formData?.amc_services ? [...formData?.amc_services] : [],
      delete: [],
    };

    if (serviceData?.key === 'is_dsp_advertising') {
      dataToUpdate[serviceData?.key] = event?.target?.checked;
      setShowCollpase({ ...showSection, dspAddendum: true });

      if (
        (monthlyAdBudget && Number(monthlyAdBudget) < 50000) ||
        (monthlyAdBudget && Number(monthlyAdBudget) >= 50000)
      )
        dataToUpdate.is_amc_management = event?.target?.checked;
      amcData = handleAmcServices(event, amcData);
    }
    if (serviceData?.key === 'is_amc_management') {
      if (
        (monthlyAdBudget && Number(monthlyAdBudget) < 50000) ||
        (monthlyAdBudget && Number(monthlyAdBudget) >= 50000)
      )
        dataToUpdate[serviceData?.key] = event?.target?.checked;
      amcData = handleAmcServices(event, amcData);
    }
    setFormData({
      ...formData,
      ...dataToUpdate,
      amc_services: [...amcData?.create],
    });
    setUpdatedFormData({
      ...updatedFormData,
      ...dataToUpdate,
      amc_services: { ...amcData },
    });
  };

  const checkIfAllSelectedAMCServicesFOC = () => {
    const list = formData?.amc_services;
    const serviceNotFOC = list?.find((item) => item?.fee > 0);
    if (!serviceNotFOC) {
      return true;
    }
    return false;
  };

  return (
    <>
      <InputFormField className="mb-3">
        <label htmlFor="additional_one_time_services ">Monthly Services</label>
        <div
          className={`add-discount ${
            (checkDspAdvertisingDisabledCondition() &&
              !formData?.contract_type?.includes('dsp')) ||
            checkIfAllSelectedAMCServicesFOC()
              ? 'disabled'
              : ''
          }`}
          role="presentation"
          onClick={() => onAddDiscount('monthly service', '', true)}
        >
          {showDiscountLabel()}
        </div>
      </InputFormField>
      <div className="row">
        {advertisingServices?.length &&
          advertisingServices?.map((serviceData) => (
            <React.Fragment key={serviceData?.id}>
              <div
                data-tip={
                  checkDspAdvertisingDisabledCondition() &&
                  serviceData?.key === 'is_dsp_advertising'
                    ? 'Adding DSP Service is not permitted as existing active contract includes this service.'
                    : ''
                }
                data-for="dsp-advertising"
                className="col-7"
              >
                <CheckBox key={serviceData?.name}>
                  <label
                    className={`check-container customer-pannel ${
                      checkDspAdvertisingDisabledCondition() &&
                      serviceData?.key === 'is_dsp_advertising'
                        ? 'disabled'
                        : ''
                    } ${
                      !monthlyAdBudget &&
                      !formData?.is_amc_management &&
                      serviceData?.name === 'AMC Management' &&
                      !formData?.amc_services?.length
                        ? 'disabled'
                        : ''
                    }`}
                    htmlFor={serviceData?.name}
                  >
                    {serviceData?.name}
                    <input
                      type="checkbox"
                      name={serviceData?.name}
                      id={serviceData?.name}
                      onClick={(event) => handleChange(event, serviceData)}
                      defaultChecked={formData?.[serviceData?.key]}
                      checked={formData?.[serviceData?.key]}
                    />
                    <span className="checkmark" />
                  </label>
                  {checkDspAdvertisingDisabledCondition() &&
                  serviceData?.key === 'is_dsp_advertising' ? (
                    <ReactTooltip
                      id="dsp-advertising"
                      aria-haspopup="true"
                      place="top"
                    />
                  ) : null}
                </CheckBox>
              </div>

              {serviceData?.name === 'AMC Management' ? (
                <AdvertisingDashboards
                  selectedCurrency={selectedCurrency}
                  advertisingDashboards={advertisingDashboards}
                  formData={formData}
                  setFormData={setFormData}
                  updatedFormData={updatedFormData}
                  setUpdatedFormData={setUpdatedFormData}
                  monthlyAdBudget={monthlyAdBudget}
                  contractAddendumDetails={contractAddendumDetails}
                  details={details}
                  showFooter={showFooter}
                  amcServiceError={amcServiceError}
                  setAmcServiceError={setAmcServiceError}
                  getAddedOrRemovedAMCServices={getAddedOrRemovedAMCServices}
                  originalData={originalData}
                />
              ) : (
                ''
              )}
            </React.Fragment>
          ))}
      </div>
    </>
  );
}

export default AdvertisingServices;

AdvertisingServices.defaultProps = {
  advertisingDashboards: [],
  formData: {},
  setFormData: () => {},
  updatedFormData: {},
  setUpdatedFormData: () => {},
  selectedCurrency: '',
  contractAddendumDetails: {},
  details: {},
  setShowCollpase: () => {},
  showSection: {},
  showFooter: () => {},
  amcServiceError: {},
  setAmcServiceError: () => {},
  onAddDiscount: () => {},
  discountData: {},
  getAddedOrRemovedAMCServices: () => {},
  originalData: {},
};

AdvertisingServices.propTypes = {
  advertisingDashboards: arrayOf(shape({})),
  formData: shape({}),
  setFormData: func,
  updatedFormData: shape({}),
  setUpdatedFormData: func,
  selectedCurrency: string,
  contractAddendumDetails: shape({}),
  details: shape({}),
  setShowCollpase: func,
  showSection: shape({}),
  showFooter: func,
  amcServiceError: shape({}),
  setAmcServiceError: () => {},
  onAddDiscount: () => {},
  discountData: {},
  getAddedOrRemovedAMCServices: () => {},
  originalData: {},
};
