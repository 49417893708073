/* eslint-disable react/no-danger */
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import {
  string,
  arrayOf,
  shape,
  oneOfType,
  object,
  bool,
  func,
} from 'prop-types';

import PlaybookTaskAssignee from './PlaybookTaskAssignee';
import PlaybookTaskStatus from './PlaybookTaskStatus';
import { JIRA_TICKET_BASE_URL } from '../../../../../constants';
import { InnerCollapseRow } from '../PlaybookStyle';

export default function PlaybookSubTask({
  ID,
  subTaskData,
  customerId,
  isCustomer,
  playbookTab,
  playbookAPICall,
  isUserAssignedToBP,
  renderPlaybookTaskMobileDataHTML,
  isCurrentMonthTask,
}) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const subTaskID = subTaskData?.id;
  const subTaskDataObj = {
    taskId: subTaskID,
    taskName: subTaskData?.sub_task_name,
    jiraStatus: subTaskData?.jira_status,
  };

  // render playbook sub task data
  const renderPlaybookSubTaskData = () => {
    return (
      <InnerCollapseRow
        key={subTaskID}
        className={
          playbookTab === 'playbook'
            ? `third-layer-collapse`
            : 'second-layer-collapse'
        }
      >
        <td width={playbookTab === 'playbook' ? '40%' : '50%'}>
          {subTaskData?.jira_id !== null ? (
            <a
              aria-label="jira"
              href={`${JIRA_TICKET_BASE_URL}${subTaskData?.jira_id}`}
              target="_blank"
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{ __html: subTaskData?.sub_task_name }}
            />
          ) : (
            <span
              dangerouslySetInnerHTML={{ __html: subTaskData?.sub_task_name }}
            />
          )}
        </td>
        {playbookTab === 'playbook' ? (
          <td width="15%">{subTaskData?.marketplace}</td>
        ) : null}

        <td width="10%">{subTaskData?.team}</td>
        {isCustomer ? null : <td width="10%">{subTaskData?.due_date}</td>}
        {isCustomer ? null : (
          <td className={isCurrentMonthTask ? '' : 'disabled'} width="10%">
            <PlaybookTaskAssignee
              ID={ID}
              taskId={subTaskID}
              customerId={customerId}
              playbookTab={playbookTab}
              playbookAPICall={playbookAPICall}
              assigneeData={subTaskData?.assignee}
              isUserAssignedToBP={isUserAssignedToBP}
            />
          </td>
        )}
        <td className={isCurrentMonthTask ? '' : 'disabled'} width="15%">
          <PlaybookTaskStatus
            ID={ID}
            isCustomer={isCustomer}
            customerId={customerId}
            taskData={subTaskDataObj}
            playbookTab={playbookTab}
            playbookAPICall={playbookAPICall}
          />
        </td>
      </InnerCollapseRow>
    );
  };

  // render playbook task table mobile fields
  const renderPlaybookTaskMobileData = (key) => {
    return (
      <>
        <div className="row" key={key}>
          <div className="col-12">
            {playbookTab === 'playbook' && subTaskData?.jira_id !== null ? (
              <a
                href={`${JIRA_TICKET_BASE_URL}${subTaskData?.jira_id}`}
                target="_blank"
                className="label"
                rel="noopener noreferrer"
                aria-label="jira"
                dangerouslySetInnerHTML={{ __html: subTaskData?.sub_task_name }}
              />
            ) : (
              <span
                className="label"
                dangerouslySetInnerHTML={{ __html: subTaskData?.sub_task_name }}
              />
            )}
          </div>

          {playbookTab === 'playbook'
            ? renderPlaybookTaskMobileDataHTML(
                'Marketplace',
                subTaskData?.maketplace,
              )
            : null}

          {renderPlaybookTaskMobileDataHTML('Team', subTaskData?.team)}
          {isCustomer
            ? null
            : renderPlaybookTaskMobileDataHTML(
                'Due Date',
                subTaskData?.due_date,
              )}
          {isCustomer
            ? null
            : renderPlaybookTaskMobileDataHTML(
                'Assignee',
                subTaskData?.assignee,
                subTaskID,
              )}
          {renderPlaybookTaskMobileDataHTML('Status', subTaskDataObj)}
        </div>
      </>
    );
  };

  // render playbook sub task movile view
  const renderPlaybookSubTaskMobileView = () => {
    return (
      <div className="mt-2">{renderPlaybookTaskMobileData(subTaskID)}</div>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      {isTablet
        ? renderPlaybookSubTaskData()
        : renderPlaybookSubTaskMobileView()}
    </>
  );
}

PlaybookSubTask.defaultProps = {
  subTaskData: [],
  playbookAPICall: () => {},
  renderPlaybookTaskMobileDataHTML: () => {},
};

PlaybookSubTask.propTypes = {
  ID: string.isRequired,
  customerId: string.isRequired,
  playbookTab: string.isRequired,
  isCustomer: bool.isRequired,
  isUserAssignedToBP: bool.isRequired,
  isCurrentMonthTask: bool.isRequired,
  playbookAPICall: func,
  renderPlaybookTaskMobileDataHTML: func,
  subTaskData: oneOfType([arrayOf(shape()), object]),
};
