import React, { Fragment } from 'react';

import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { components } from 'react-select';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ASINPerformance from '../SellerReporting/ASINPerformance';
import VendorSalePerformanceChart from './VendorSalePerformanceChart';
import VendorSalePerformanceGoalsChart from './VendorSalePerformanceGoalsChart';
import { ArrowUpIcon, ArrowDownIcon } from '../../../../../../theme/images';
import {
  WhiteCard,
  PageLoader,
  NoData,
  DropdownIndicator,
  ToggleButton,
  CardMetrics,
  DropDowns,
  ToggleSwitch,
  GrayMessageBar,
  MultipleDashedLineLabel,
} from '../../../../../../common';
import {
  vendorSalesMetricsTypeOptions,
  noGraphDataMessage,
  VendorMetricsNames,
  performanceInsightMessage,
  showMetricsTooltipForVendorSales,
  metricsVendorSalesKey,
  dailyWeeklyMonthlyOptions,
  ORDEREDREVENUE,
  SHIPPEDCOGS,
  GLANCEVIEWS,
  CONVERSIONRATE,
  ORDEREDUNITS,
  SHIPPEDUNITS,
  CUSTOM,
  SALEPERFORMANCE,
  VENDORSMALL,
} from '../../../../../../constants';

const _ = require('lodash');

export default function VendorSalesPerformancePanel({
  isApiCall,
  goalsToggle,
  salesGraphLoader,
  hideOrderedRevenue,
  isSPCustomDateApply,

  isAsinApiCallCustom,

  groupBy,
  customerId,
  currencySymbol,

  metricsType,
  activeSales,
  selectedValue,
  goalsMetricData,
  salesDifference,
  customDateError,
  salesCurrentTotal,
  salesPreviousTotal,
  selectedChildBrand,
  selectedMarketplace,

  customDate,
  salesChartData,
  salesGoalChartData,

  setActiveSales,
  handleMetricsType,
  handleGroupBy,
  setGoalsToggle,
  renderDataAvailableChildList,
}) {
  const { Option, SingleValue } = components;

  const metricsTypeFilterOption = (props) => {
    return (
      <Option
        {...props}
        className={
          hideOrderedRevenue && props?.data?.value === ORDEREDREVENUE
            ? 'disabled'
            : ''
        }
      >
        <div>
          <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
        </div>
      </Option>
    );
  };

  const metricsTypeSingleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
    </SingleValue>
  );

  const getMetricTypeSelectComponents = () => {
    return {
      Option: metricsTypeFilterOption,
      SingleValue: metricsTypeSingleFilterOption,
      DropdownIndicator,
    };
  };

  const dashboardDynamicError = useSelector(
    (newState) => newState.userState?.showDynamicMsg,
  );

  const bindValues = (value, name = null) => {
    const decimal = _.split(value, '.', 2);
    if (decimal[1] !== undefined) {
      return (
        <span style={{ fontSize: '26px' }}>
          {name === ORDEREDREVENUE || name === SHIPPEDCOGS
            ? currencySymbol
            : null}
          {decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          <span style={{ fontSize: '16px' }}>.{decimal[1].slice(0, 2)}</span>
        </span>
      );
    }
    return (
      <span style={{ fontSize: '26px' }}>
        {name === ORDEREDREVENUE || name === SHIPPEDCOGS
          ? currencySymbol
          : null}
        {decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    );
  };

  const setSalesBoxClass = (name, classValue) => {
    let selectedClass = '';
    if (Object.prototype.hasOwnProperty.call(activeSales, name)) {
      selectedClass = `order-chart-box ${classValue} fix-height`;
    } else if (_.size(activeSales) === 4) {
      selectedClass = 'order-chart-box fix-height disabled';
    } else if (goalsToggle && _.size(activeSales) === 2) {
      selectedClass = 'order-chart-box fix-height disabled';
    } else {
      selectedClass = 'order-chart-box fix-height';
    }
    return selectedClass;
  };

  const setBoxToggle = (name) => {
    if (
      Object.prototype.hasOwnProperty.call(activeSales, name) &&
      _.size(activeSales) > 1
    ) {
      setActiveSales(_.omit(activeSales, [name]));
    } else if (_.size(activeSales) < 4) {
      setActiveSales(_.omit(_.assign(activeSales, { [name]: true })));
      if (
        _.size(_.assign(activeSales, { [name]: true })) === 2 &&
        goalsToggle
      ) {
        toast.info('Maximum two goals at a time');
      }
    }
  };

  const renderMetricsType = () => {
    return (
      <div className="col-md-6 col-sm1-12  mb-3">
        {DropDowns(
          'BT-VendorSalesPerformanceFilters',
          'days-performance',
          vendorSalesMetricsTypeOptions,
          metricsType?.label,
          getMetricTypeSelectComponents,
          vendorSalesMetricsTypeOptions?.[0],
          handleMetricsType,
          isApiCall,
          null,
          metricsType,
        )}
        <div className="clear-fix" />
      </div>
    );
  };

  const rednerSaleGroupBy = () => {
    const previousClassName =
      _.size(activeSales) === 1
        ? `${_.keys(activeSales)[0]} block`
        : 'darkGray block';
    return (
      <div className="row mt-4 mb-3">
        {_.size(activeSales) <= 2 ? (
          <div className="col-md-5 col-sm-12 order-md-1 order-2 mt-2">
            <ul className="rechart-item">
              <li key="recent">
                <div className="weeks">
                  <span
                    className={
                      _.size(activeSales) === 1
                        ? `${_.keys(activeSales)[0]} adSales circle`
                        : 'darkGray circle'
                    }
                  />
                  <span>Recent</span>
                </div>
              </li>
              {selectedValue.value !== CUSTOM ? (
                <li key="previous">
                  <MultipleDashedLineLabel
                    label="Previous"
                    className={previousClassName}
                  />
                </li>
              ) : goalsToggle ? (
                <li key="goals">
                  <MultipleDashedLineLabel
                    label="Goals"
                    className={previousClassName}
                  />
                </li>
              ) : null}
            </ul>
          </div>
        ) : (
          <div className="col-md-5 col-sm-12 order-md-1 order-2 mt-2" />
        )}
        <div className="col-md-7 col-sm-12 order-md-2 order-1">
          <ToggleButton>
            <div className="days-container ">
              <ul className={isApiCall ? 'disabled-tab days-tab' : 'days-tab'}>
                {dailyWeeklyMonthlyOptions?.map((item) => {
                  return (
                    <li key={item?.id}>
                      <input
                        className="d-none"
                        type="radio"
                        id={item?.id}
                        name="flexRadioDefault"
                        value={item?.value}
                        checked={groupBy === item?.value}
                        onChange={() => handleGroupBy(item?.value)}
                      />
                      <label htmlFor={item?.id}>{item?.label}</label>
                    </li>
                  );
                })}
              </ul>
            </div>
          </ToggleButton>
        </div>
      </div>
    );
  };

  const returnDisbaledClass = (current, previous) => {
    return !current && !previous ? 'disabled' : null;
  };

  const returnTooltipMessge = (key = '') => {
    return `No ${key} available for this period.`;
  };

  // display goal target html
  const displayGoalTargetHTML = (sign, target, value) => {
    return (
      <div className="metric-taget">
        <div className="target-percent-capsule">
          Target {sign === 'dollar' ? '$' : null}
          {value
            ?.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          {sign === 'percentage' ? '%' : null}
        </div>
        <div
          className={`${
            target === 'on target' || target === 'on track'
              ? 'on-target'
              : target === 'underspend' || target === 'underspending'
              ? 'underspend'
              : 'under-target'
          } mt-1`}
        >
          {target}
        </div>
      </div>
    );
  };

  const renderSalesBox = (matricsName, className) => {
    let currentTotal = 0;
    let previousTotal = 0;
    let difference = 0;
    let boxActiveClass = '';
    const name = matricsName;
    let displayMatricsName;

    if (name === ORDEREDREVENUE) {
      currentTotal = salesCurrentTotal?.ordered_revenue
        ? salesCurrentTotal.ordered_revenue
        : 0;
      previousTotal = salesPreviousTotal?.ordered_revenue
        ? salesPreviousTotal.ordered_revenue
        : 0;
      difference = salesDifference?.ordered_revenue
        ? salesDifference.ordered_revenue
        : 'N/A';
      boxActiveClass = 'ad-sales-active';
      displayMatricsName = matricsName;
    } else if (name === SHIPPEDCOGS) {
      currentTotal = salesCurrentTotal?.shipped_cogs
        ? salesCurrentTotal.shipped_cogs
        : 0;
      previousTotal = salesPreviousTotal?.shipped_cogs
        ? salesPreviousTotal.shipped_cogs
        : 0;
      difference = salesDifference?.shipped_cogs
        ? salesDifference.shipped_cogs
        : 'N/A';
      boxActiveClass = 'ad-sales-active';
      displayMatricsName = matricsName;
    } else if (name === GLANCEVIEWS) {
      currentTotal = salesCurrentTotal?.glance_views
        ? salesCurrentTotal.glance_views
        : 0;
      previousTotal = salesPreviousTotal?.glance_views
        ? salesPreviousTotal.glance_views
        : 0;
      difference = salesDifference?.glance_views
        ? salesDifference.glance_views
        : 'N/A';

      boxActiveClass = 'ad-spend-active';
      displayMatricsName = matricsName;
    } else if (name === CONVERSIONRATE) {
      currentTotal = salesCurrentTotal?.conversion_rate
        ? salesCurrentTotal.conversion_rate
        : 0;
      previousTotal = salesPreviousTotal?.conversion_rate
        ? salesPreviousTotal.conversion_rate
        : 0;
      difference = salesDifference?.conversion_rate
        ? salesDifference.conversion_rate
        : 'N/A';

      boxActiveClass = 'ad-conversion-active';
      displayMatricsName = matricsName;
    } else if (name === ORDEREDUNITS) {
      currentTotal = salesCurrentTotal?.ordered_units
        ? salesCurrentTotal.ordered_units
        : 0;
      previousTotal = salesPreviousTotal?.ordered_units
        ? salesPreviousTotal.ordered_units
        : 0;
      difference = salesDifference?.ordered_units
        ? salesDifference.ordered_units
        : 'N/A';

      boxActiveClass = 'impression-active';
      displayMatricsName = matricsName;
    } else if (name === SHIPPEDUNITS) {
      currentTotal = salesCurrentTotal?.shipped_units
        ? salesCurrentTotal.shipped_units
        : 0;
      previousTotal = salesPreviousTotal?.shipped_units
        ? salesPreviousTotal.shipped_units
        : 0;
      difference = salesDifference?.shipped_units
        ? salesDifference.shipped_units
        : 'N/A';

      boxActiveClass = 'impression-active';
      displayMatricsName = matricsName;
    }

    const showGoalsTarget =
      matricsName === ORDEREDREVENUE ||
      matricsName === SHIPPEDCOGS ||
      matricsName === GLANCEVIEWS ||
      matricsName === CONVERSIONRATE;

    return (
      <div className={className}>
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(VendorMetricsNames[displayMatricsName])}
          data-for={metricsVendorSalesKey[matricsName]}
        >
          <div
            className={`${setSalesBoxClass(
              name,
              boxActiveClass,
            )} ${returnDisbaledClass(
              salesCurrentTotal?.[metricsVendorSalesKey?.[matricsName]],
              salesPreviousTotal?.[metricsVendorSalesKey?.[matricsName]],
            )} ${goalsToggle ? (showGoalsTarget ? '' : 'disabled') : ''}`}
            onClick={() => setBoxToggle(name)}
            role="presentation"
          >
            {' '}
            <div className="chart-name">
              {VendorMetricsNames[displayMatricsName].toUpperCase()}
            </div>
            <div className="number-rate">
              {name === CONVERSIONRATE
                ? `${currentTotal.toFixed(2)}%`
                : bindValues(currentTotal, name)}
            </div>
            <div className="vs">
              {' '}
              vs{' '}
              {name === CONVERSIONRATE
                ? `${previousTotal.toFixed(2)}%`
                : name === ORDEREDREVENUE || name === SHIPPEDCOGS
                ? `${
                    currencySymbol !== null ? currencySymbol : ''
                  }${previousTotal
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                : previousTotal
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </div>
            <div className={showGoalsTarget ? 'justify-space-between' : ''}>
              <div
                className={
                  difference > 0
                    ? 'perentage-value mt-3 pt-1'
                    : 'perentage-value down mt-3 pt-1'
                }
              >
                {!Number.isNaN(difference) && difference > 0 ? (
                  <img
                    className="green-arrow"
                    src={ArrowUpIcon}
                    alt="arrow-up"
                  />
                ) : !Number.isNaN(difference) && difference < 0 ? (
                  <img
                    className="red-arrow"
                    src={ArrowDownIcon}
                    alt="arrow-down"
                  />
                ) : (
                  ''
                )}
                {difference !== 'N/A'
                  ? `${difference.toString().replace('-', '')}%`
                  : 'N/A'}
              </div>
              {showGoalsTarget && goalsMetricData?.[matricsName]
                ? displayGoalTargetHTML(
                    goalsMetricData?.[matricsName]?.sign,
                    goalsMetricData?.[matricsName]?.target,
                    goalsMetricData?.[matricsName]?.value,
                  )
                : null}
            </div>
          </div>
        </CardMetrics>
      </div>
    );
  };

  // display goals toggle button
  const showGoalsToggle = () => {
    return (
      <ToggleSwitch className="custom-switch mt-2">
        <label
          htmlFor="goalsToggle"
          className={`label-name ${
            selectedValue?.value === CUSTOM
              ? Object.keys(goalsMetricData)?.length
                ? ''
                : 'disabled'
              : 'disabled'
          }`}
        >
          Goals
          <input
            type="checkbox"
            className="label-name"
            id="goalsToggle"
            onChange={() => {
              setGoalsToggle(!goalsToggle);
              setActiveSales(
                metricsType?.value === ORDEREDREVENUE
                  ? {
                      orderedRevenue: true,
                    }
                  : {
                      shippedCOGs: true,
                    },
              );
            }}
            checked={goalsToggle}
          />
          <span className="slider round" />
        </label>
      </ToggleSwitch>
    );
  };

  const renderErrorMessage = () => {
    let { fromDate } = customDateError;
    let { toDate } = customDateError;

    fromDate = dayjs(fromDate).format('MM/DD/YYYY');
    toDate = dayjs(toDate).subtract(1, 'day').format('MM/DD/YYYY');

    return (
      <div className="row">
        <div className="col-12 mt-2 mb-3">
          <div className="normal-text-black first-white-card">
            <GrayMessageBar
              message={`There is no sales data available from ${fromDate}
      to ${toDate}.`}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSalePerformancePanel = () => {
    return (
      <WhiteCard>
        <>
          <div className="row">
            <div className="col-md-6 col-sm1-12">
              {' '}
              <p className="black-heading-title mt-2 mb-4">
                {' '}
                Sales Performance
              </p>
            </div>
            {renderMetricsType()}
          </div>
          <div className="row">
            <div className="col-12">
              {dashboardDynamicError?.sales_performance_vendor?.placeholder ? (
                <GrayMessageBar
                  message={
                    dashboardDynamicError?.sales_performance_vendor?.placeholder
                  }
                />
              ) : null}
            </div>
          </div>

          {renderDataAvailableChildList('sales')}
          {selectedValue.value !== CUSTOM ? (
            <GrayMessageBar message={performanceInsightMessage.vendorSales} />
          ) : null}

          {metricsType.value === ORDEREDREVENUE ? (
            <div className="row mr-1 ml-1">
              {renderSalesBox(
                ORDEREDREVENUE,
                'col-lg-3 col-md-3 pr-1 pl-0 col-6 mb-3',
              )}
              {renderSalesBox(
                GLANCEVIEWS,
                'col-lg-3 col-md-3 pr-1 pl-1 col-6 mb-3',
              )}
              {renderSalesBox(
                CONVERSIONRATE,
                'col-lg-3 col-md-3 pr-1 pl-1  col-6 mb-3',
              )}
              {renderSalesBox(
                ORDEREDUNITS,
                'col-lg-3 col-md-3 pl-1 pr-0 col-6 mb-3',
              )}
            </div>
          ) : (
            <div className="row mr-1 ml-1">
              {renderSalesBox(
                SHIPPEDCOGS,
                'col-lg-3 col-md-3 pr-1 pl-0 col-6 mb-3',
              )}
              {renderSalesBox(
                GLANCEVIEWS,
                'col-lg-3 col-md-3 pr-1 pl-1 col-6 mb-3',
              )}
              {renderSalesBox(
                CONVERSIONRATE,
                'col-lg-3 col-md-3 pr-1 pl-1  col-6 mb-3',
              )}
              {renderSalesBox(
                SHIPPEDUNITS,
                'col-lg-3 col-md-3 pl-1 pr-0 col-6 mb-3',
              )}
            </div>
          )}
          {showGoalsToggle()}
          {selectedValue.value === CUSTOM &&
          customDateError.accountType === VENDORSMALL &&
          customDateError.selectedTab === SALEPERFORMANCE
            ? renderErrorMessage()
            : null}
          {rednerSaleGroupBy()}
          <div className="clear-fix" />

          {salesGraphLoader ? (
            <PageLoader
              component="performance-graph"
              color="#FF5933"
              type="detail"
              width={40}
              height={40}
            />
          ) : salesChartData?.length !== 0 && !goalsToggle ? (
            <VendorSalePerformanceChart
              chartId="chartdiv"
              chartData={salesChartData}
              currencySymbol={currencySymbol}
              selectedMetrics={activeSales}
              selectedDF={selectedValue.value}
            />
          ) : salesGoalChartData?.length !== 0 && goalsToggle ? (
            <VendorSalePerformanceGoalsChart
              chartId="vendor-sales-goal-chart"
              chartData={salesGoalChartData}
              currencySymbol={currencySymbol}
              selectedMetrics={activeSales}
              selectedDF={selectedValue?.value}
            />
          ) : (
            <NoData>{noGraphDataMessage}</NoData>
          )}
        </>

        <ASINPerformance
          selectedPeriod={selectedValue}
          customDate={customDate}
          isSelectCustom={isSPCustomDateApply}
          currencySymbol={currencySymbol}
          customerId={customerId}
          marketplace={selectedMarketplace?.value}
          selectedChildBrand={selectedChildBrand}
          selectedAccountType="vendor"
          metricsType={metricsType?.value}
          isAsinApiCallCustom={isAsinApiCallCustom}
        />
      </WhiteCard>
    );
  };

  return (
    <>
      {showMetricsTooltipForVendorSales.map((key) => {
        return (
          <Fragment key={key}>
            {!salesCurrentTotal[key] &&
            !salesPreviousTotal[key] &&
            !isApiCall ? (
              <ReactTooltip
                id={key}
                class="initialTextTransform"
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />
            ) : null}
          </Fragment>
        );
      })}
      {renderSalePerformancePanel()}
    </>
  );
}

VendorSalesPerformancePanel.defaultProps = {
  isApiCall: false,
  salesGraphLoader: false,
  isSPCustomDateApply: false,
  hideOrderedRevenue: false,
  goalsToggle: false,

  isAsinApiCallCustom: 0,

  groupBy: '',
  customerId: '',

  currencySymbol: '',

  data: {},
  metricsType: {},
  activeSales: {},
  selectedValue: {},
  goalsMetricData: {},
  salesDifference: {},
  customDateError: {},
  salesCurrentTotal: {},
  salesPreviousTotal: {},
  selectedChildBrand: {},
  selectedMarketplace: {},

  customDate: [],
  salesChartData: [],
  salesGoalChartData: [],

  setActiveSales: () => {},
  handleMetricsType: () => {},
  handleGroupBy: () => {},
  setGoalsToggle: () => {},
  renderDataAvailableChildList: () => {},
};

VendorSalesPerformancePanel.propTypes = {
  isApiCall: bool,
  goalsToggle: bool,
  salesGraphLoader: bool,
  hideOrderedRevenue: bool,
  isSPCustomDateApply: bool,

  isAsinApiCallCustom: number,

  groupBy: string,
  customerId: string,
  currencySymbol: string,

  data: shape({}),
  metricsType: shape({}),
  activeSales: shape({}),
  selectedValue: shape({}),
  goalsMetricData: shape({}),
  salesDifference: shape({}),
  customDateError: shape({}),
  salesCurrentTotal: shape({}),
  salesPreviousTotal: shape({}),
  selectedChildBrand: shape({}),
  selectedMarketplace: shape({}),

  customDate: arrayOf(shape({})),
  salesChartData: arrayOf(shape({})),
  salesGoalChartData: arrayOf(shape({})),

  setActiveSales: func,
  handleMetricsType: func,
  handleGroupBy: func,
  setGoalsToggle: func,
  renderDataAvailableChildList: func,
};
