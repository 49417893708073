import React, { useState, useCallback, useEffect } from 'react';

import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import { string, bool, func, shape } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CloseIcon, SearchIcon } from '../../../../../theme/images';
import { getAllMembers } from '../../../../../api';
import {
  Button,
  CommonPagination,
  GetInitialName,
  InputSearchWithRadius,
  ModalBox,
  PageLoader,
} from '../../../../../common';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '600px ',
    minHeight: '220px',
    width: '100% ',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function ChangeAssigneeModal({
  modalId,
  isOpen,
  afterSubmitAPI,
  handleAddAssignee,
  selectedAssignee,
  setSelectedAssignee,
}) {
  const customerMemberData = useSelector(
    (state) => state.customerState.customerMembers,
  );

  const designerRoleGroupId = customerMemberData.find(
    (item) => item?.role_group?.name === 'Designer',
  )?.role_group?.id;

  const [isSubmitApiCall, setIsSubmitApiCall] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [bbeUsersData, setBbeUsersData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const getDesignerMemberList = useCallback(
    (page, searchString) => {
      setIsDataLoading(true);
      setBbeUsersData([]);
      setTotalCount(0);
      getAllMembers(designerRoleGroupId, true, page, searchString).then(
        (response) => {
          if (response?.status === 200) {
            setBbeUsersData(response?.data?.results);
            setTotalCount(response?.data?.count);
          } else {
            setBbeUsersData([]);
            setTotalCount(0);
          }
          setIsDataLoading(false);
        },
      );
    },
    [designerRoleGroupId],
  );

  useEffect(() => {
    getDesignerMemberList(1, '');
  }, [getDesignerMemberList]);

  // before and after calling api
  const eventsBeforeAndAfterApi = useCallback(() => {
    setBbeUsersData([]);
    setTotalCount(0);
    afterSubmitAPI();
  }, [afterSubmitAPI]);

  // Handle Search events
  const handleSearch = (event) => {
    event.persist();
    setSearchQuery(event.target.value);
    setPageNumber(1);
    setTimeout(() => {
      getDesignerMemberList(1, event?.target?.value);
    }, 1000);
    setSelectedAssignee({});
  };

  // Handle Pagination events
  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getDesignerMemberList(currentPage, searchQuery);
  };

  // render Input Search With Radius
  const renderInputSearchWithRadius = () => {
    return (
      <InputSearchWithRadius
        id="BT-bbeuser-search-input"
        className="customer-list-header w-80 mt-3"
      >
        <DebounceInput
          className="form-control search-filter"
          placeholder="Search"
          onChange={(event) => {
            handleSearch(event);
          }}
          value={searchQuery || ''}
          debounceTimeout={600}
        />
        <img src={SearchIcon} alt="search" className="search-input-icon" />
      </InputSearchWithRadius>
    );
  };
  // render bbe assigned and unassigned users list
  const renderBBEUsersList = () => {
    return bbeUsersData?.map((user) => {
      return (
        <div
          className="row cursor mb-3"
          key={user?.user_id}
          role="presentation"
          onClick={() => {
            setSelectedAssignee(user);
          }}
        >
          <div className="col-8">
            <div className="edit-profile-text" role="presentation">
              <GetInitialName
                userInfo={user}
                property={
                  selectedAssignee?.user_id === user?.user_id
                    ? 'active-user mr-3'
                    : 'mr-3'
                }
              />
              <div className="name-email">
                <div className="team-user-name text-medium-font">
                  {`${user?.first_name} ${user?.last_name}`}
                </div>
                {user?.role}
              </div>
            </div>
          </div>

          <div className="col-4 text-right">
            {selectedAssignee?.id === user?.id ? (
              <div className="pt-2">
                <FontAwesomeIcon
                  icon="fa-regular fa-check"
                  style={{ color: '#ff5933', width: '20px', height: '20px' }}
                />
              </div>
            ) : (
              <Button className="btn-add-items   mt-3" role="presentation">
                Add
              </Button>
            )}
          </div>
        </div>
      );
    });
  };

  // render submit button html
  const renderSubmitButton = () => {
    return (
      <Button
        className={
          isDataLoading || bbeUsersData?.length < 1 || !selectedAssignee?.email
            ? 'btn-primary on-boarding w-100 disabled'
            : 'btn-primary on-boarding w-100'
        }
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setIsSubmitApiCall(false);
          handleAddAssignee(selectedAssignee);
        }}
      >
        {isSubmitApiCall ? <PageLoader color="#fff" type="button" /> : 'Submit'}
      </Button>
    );
  };

  // render cancel button html
  const renderCancelButton = () => {
    return (
      <Button
        className={
          isDataLoading || bbeUsersData?.length < 1 || !selectedAssignee?.email
            ? 'btn-transparent w-100 disabled'
            : 'btn-transparent w-100'
        }
        onClick={(e) => {
          e.stopPropagation();
          eventsBeforeAndAfterApi();
        }}
      >
        Cancel
      </Button>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      <Modal
        id={modalId}
        isOpen={isOpen}
        style={{ ...customStyles }}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            afterSubmitAPI();
            setBbeUsersData([]);
          }}
          role="presentation"
        />
        <ModalBox>
          <>
            <div className="modal-body pb-0">
              <h4>Change Task Assignee</h4>

              {renderInputSearchWithRadius()}
              <div className="horizontal-line straight-line mt-3 mb-3" />
              <div
                className="body-content mt-2"
                style={{ minHeight: '50px', maxHeight: '400px' }}
              >
                <div>
                  {isDataLoading ? (
                    <PageLoader
                      component="member"
                      color="#FF5933"
                      type="page"
                      height={40}
                    />
                  ) : bbeUsersData?.length === 0 ? (
                    <div className="text-center no-result-found my-3">
                      No User Found.
                    </div>
                  ) : (
                    <>{renderBBEUsersList()}</>
                  )}
                </div>
              </div>
              {totalCount > 10 ? (
                <>
                  <div className="horizontal-line straight-line my-3" />
                  <div style={{ margin: '-5px 0 -10px 0' }}>
                    <CommonPagination
                      count={totalCount}
                      pageNumber={pageNumber}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div className="footer-line" />
            <div className="modal-footer">
              <div className="row">
                <div className="col-6">{renderSubmitButton()}</div>
                <div className="col-6">{renderCancelButton()}</div>
              </div>
            </div>
          </>
        </ModalBox>
      </Modal>
    </>
  );
}

export default ChangeAssigneeModal;

ChangeAssigneeModal.defaultProps = {
  modalId: '',
  isOpen: false,
  afterSubmitAPI: () => {},
  handleAddAssignee: () => {},
  selectedAssignee: {},
  setSelectedAssignee: () => {},
};

ChangeAssigneeModal.propTypes = {
  modalId: string,
  isOpen: bool,
  afterSubmitAPI: func,
  handleAddAssignee: func,
  selectedAssignee: shape({}),
  setSelectedAssignee: func,
};
