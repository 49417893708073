import React, { useState, useEffect } from 'react';

import NumberFormat from 'react-number-format';
import { string, number, shape, bool, arrayOf, func } from 'prop-types';

import { CheckBox, ErrorMsg } from '../../../../common';
import { PlusIcon, MinusIcon } from '../../../../theme/images';

function AdditionalOneTimeServicesSubTabs({
  tabName,
  details,
  formData,
  setFormData,
  setUpdatedFormData,
  additionalOneTimeServicesLength,
  oneTimeService,
  contractAddendumDetails,
  updatedFormData,
  additionalOnetimeServices,
  setAdditionalOnetimeServices,
  showFooter,
  additionalOnetimeSerError,
  setAdditionalOnetimeSerError,
  updateAdditionalOnetimeServicesSelectedData,
  clearOneTimeQntyError,
  updateStatementErrorValue,
  sectionError,
  combinedOneTimeServices,
  filterNotIncludedOneTimeServices,
}) {
  const [showOneTimeServicesSubTabs, setShowOneTimeServicesSubTabs] =
    useState(false);
  const [oneTimeServiceSubTabOptions, setOneTimeServiceSubTabOptions] =
    useState(null);

  useEffect(() => {
    // If one time services are present in the formData then set that sub tab option true
    if (formData?.additional_one_time_services?.length && tabName) {
      const includesSubTabs = formData?.additional_one_time_services?.filter(
        (service) =>
          service.name
            ? service?.name?.includes(tabName)
            : service?.service?.name?.includes(tabName),
      );
      if (includesSubTabs?.length) {
        setShowOneTimeServicesSubTabs(true);
      }
    }

    // Seperate Sub tabs form one time services
    if (oneTimeService?.length && tabName) {
      const result = oneTimeService?.filter((service) =>
        service?.label?.includes(tabName),
      );
      setOneTimeServiceSubTabOptions(
        result?.filter((op) => op.label !== 'A+ Content'),
      );
    }
  }, [formData.additional_one_time_services, oneTimeService, tabName]);

  // If one sub tabs value is in formData then add the remaining sub tabs in the formData
  useEffect(() => {
    if (formData?.additional_one_time_services?.length) {
      const combined = [
        ...combinedOneTimeServices,
        ...formData?.additional_one_time_services,
      ];
      setFormData({
        ...formData,
        additional_one_time_services: combined,
      });
      if (formData?.contract_type?.includes('one')) {
        setAdditionalOnetimeServices({
          ...additionalOnetimeServices,
          create: [
            ...formData.additional_one_time_services,
            ...combinedOneTimeServices,
          ],
        });
      } else {
        setAdditionalOnetimeServices({
          ...additionalOnetimeServices,
          create: [
            ...additionalOnetimeServices.create,
            ...combinedOneTimeServices,
          ],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneTimeService, combinedOneTimeServices, formData.contract_type]);

  const returnSubTabName = (tab) => {
    switch (tab) {
      case 'Amazon Storefront':
        return 'Amazon Storefront';
      case 'A+':
        return 'A+ Content';
      case 'Infographics':
        return 'Infographics';
      default:
        return '';
    }
  };

  const clearOneTimeServiceError = () => {
    const objectKeys = [];
    // eslint-disable-next-line no-unused-vars
    const restAdditionalOnetimeSerError =
      additionalOnetimeSerError?.quantity?.map((error) =>
        objectKeys.push(...Object.keys(error)),
      );

    if (objectKeys?.length && parseInt(objectKeys.indexOf(tabName), 10) >= 0) {
      const remainingError = additionalOnetimeSerError?.quantity;
      // eslint-disable-next-line no-unused-vars
      const aa = remainingError?.splice(objectKeys.indexOf(tabName), 1);
      setAdditionalOnetimeSerError({
        ...additionalOnetimeSerError,
        quantity: remainingError,
      });
    }
  };

  const checkDisableCondition = (label, limit) => {
    const filterData =
      additionalOneTimeServicesLength &&
      formData?.additional_one_time_services?.find((item) =>
        item.name
          ? item?.name?.includes(label)
          : item?.service && item?.service?.name?.includes(label),
      );
    return filterData ? parseInt(filterData.quantity, 10) === limit : false;
  };

  const handleOneTimeServiceSubTabQuantity = (flag, serviceData) => {
    if (additionalOnetimeSerError?.quantity) {
      updateStatementErrorValue(
        sectionError?.statement ? sectionError?.statement - 1 : 0,
      );
    }
    clearOneTimeServiceError();

    showFooter(true);
    // start to append new service to array

    const oneServices = formData.additional_one_time_services;
    const list = [];
    if (oneServices?.length) {
      oneServices.map((item) =>
        item.name !== serviceData.name || item.service.name !== serviceData.name
          ? list.push({
              name: serviceData.label,
              quantity: 0,
              service_id: serviceData.value,
              contract_id: formData.id,
            })
          : null,
      );
    }
    let amazonStoreItem = {};
    const selectedData =
      additionalOnetimeServices?.create?.length &&
      additionalOnetimeServices.create.filter((item) => {
        if (
          item.name
            ? item.name.includes(serviceData.label)
            : item.service.name.includes(serviceData.label)
        ) {
          amazonStoreItem = item;
          let itemQuantity = 0;
          if (item.quantity >= 0) {
            itemQuantity = parseInt(item.quantity, 10);
          }

          if (flag === 'add') {
            item.quantity = itemQuantity + 1;
          }
          if (flag === 'minus') {
            if (itemQuantity > 0) {
              item.quantity = itemQuantity - 1;
            }
          }
        }
        return item;
      });

    clearOneTimeQntyError(amazonStoreItem);

    if (selectedData) {
      updateAdditionalOnetimeServicesSelectedData(selectedData);
    }
  };

  const handleOneTimeServiceSubTabNumber = (event) => {
    showFooter(true);
    clearOneTimeServiceError();

    if (formData?.additional_one_time_services?.length) {
      const amazonStorefrontserviceForUpdate =
        formData?.additional_one_time_services?.find((item) =>
          item.name
            ? item.name.includes(event.target.name)
            : item.service.name.includes(event.target.name),
        );
      amazonStorefrontserviceForUpdate.quantity =
        parseInt(event.target.value, 10) || 0;

      setAdditionalOnetimeServices({
        ...additionalOnetimeServices,
        create: formData.additional_one_time_services,
      });

      let existingUpdatedFormData = [];
      if (updatedFormData?.additional_one_time_services) {
        existingUpdatedFormData = updatedFormData?.additional_one_time_services;
      }
      setUpdatedFormData({
        ...updatedFormData,
        additional_one_time_services: {
          ...existingUpdatedFormData,
          create: [...formData.additional_one_time_services],
        },
      });
    }
    filterNotIncludedOneTimeServices();
  };

  const showError = () => {
    let errorMsg = null;
    if (additionalOnetimeSerError?.quantity?.length) {
      for (const obj of additionalOnetimeSerError?.quantity) {
        if (obj[tabName]) {
          errorMsg = obj[tabName];
        }
      }
    } else {
      errorMsg = null;
    }
    if (errorMsg) {
      return (
        <div className="col-12 mb-3">
          <ErrorMsg style={{ paddingLeft: '34px' }}>{errorMsg}</ErrorMsg>
        </div>
      );
    }
    return '';
  };

  const displayOneTimeServicesSubTabs = () => {
    return oneTimeServiceSubTabOptions?.map((service) => {
      return (
        <div className="col-12">
          <div className="row pl-2">
            <div className="col-7">
              <p
                className={
                  contractAddendumDetails?.notActive
                    ? `basic-text isDisabled`
                    : `basic-text`
                }
                style={{ padding: `0px 0px 17px 23px` }}
              >
                {service?.label}
              </p>
            </div>
            <div className="col-5 text-end">
              <button
                type="button"
                className="decrement"
                onClick={() => {
                  handleOneTimeServiceSubTabQuantity('minus', service);
                }}
                disabled={
                  contractAddendumDetails?.notActive
                    ? true
                    : checkDisableCondition(service.label, 0) ||
                      contractAddendumDetails?.notActive
                }
              >
                <img className="minus-icon" src={MinusIcon} alt="" />
              </button>

              <NumberFormat
                name={service.label}
                className={
                  contractAddendumDetails?.notActive
                    ? 'form-control max-min-number isDisabled'
                    : 'form-control max-min-number'
                }
                value={
                  formData?.additional_one_time_services?.find((item) =>
                    item.name
                      ? item.name.includes(service.label)
                      : item.service?.name.includes(service.label),
                  )?.quantity
                }
                id={service.value}
                onChange={(event) => handleOneTimeServiceSubTabNumber(event)}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  if (floatValue == null) {
                    return formattedValue === '';
                  }
                  return floatValue <= 100;
                }}
                disabled={contractAddendumDetails?.notActive}
                allowNegative={false}
              />

              <button
                type="button"
                className="increment"
                disabled={
                  contractAddendumDetails?.notActive
                    ? true
                    : checkDisableCondition(service?.label, 100)
                }
                onClick={() => {
                  handleOneTimeServiceSubTabQuantity('add', service);
                }}
              >
                <img className="plus-icon" src={PlusIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
      );
    });
  };

  // if onetime sub tab's one service is already present in the db and then we check and uncheck the subtab's checkbox then the remaining subtabs should be added in to formdata
  // eslint-disable-next-line consistent-return
  const findRemainingOneTimeServicesOnCheck = () => {
    const result = [];
    oneTimeService.forEach((item) => {
      if (item.label.includes(tabName)) {
        result.push({
          name: item.label,
          quantity: 0,
          service_id: item.value,
          contract_id: formData.id,
        });
      }
      return result;
    });
    let containsAmazonStorefront = [];
    if (details?.additional_one_time_services?.length) {
      containsAmazonStorefront = details?.additional_one_time_services?.filter(
        (item) =>
          item?.name
            ? item?.name?.includes(tabName)
            : item?.service?.name?.includes(tabName),
      );
    }
    if (containsAmazonStorefront?.length) {
      const uniqueResult = result?.filter((obj) => {
        return !containsAmazonStorefront?.some((obj2) => {
          return obj?.name === obj2?.service?.name;
        });
      });

      return uniqueResult;
    }
  };

  const handleChangeOneTimeServicesSubTabs = (event) => {
    clearOneTimeServiceError();

    if (event.target.checked) {
      showFooter(true);
      let defaultOneTimeServiceOptions = [];
      let defaultAmazonStorefrontDeleteIds = [];
      let combineOneTimeServices = null;
      let combineadditionalOnetimeServices = null;
      if (details?.additional_one_time_services?.length) {
        defaultOneTimeServiceOptions =
          details?.additional_one_time_services?.filter((item) =>
            item?.name
              ? item?.name?.includes(tabName)
              : item?.service?.name?.includes(tabName),
          );
        if (defaultOneTimeServiceOptions?.length) {
          let combineadditionalOnetimeServicesNew = [];
          if (additionalOnetimeServices?.create?.length) {
            combineadditionalOnetimeServicesNew = [
              ...additionalOnetimeServices.create,
              ...defaultOneTimeServiceOptions,
            ];
          } else {
            combineadditionalOnetimeServicesNew = [
              ...defaultOneTimeServiceOptions,
            ];
          }

          defaultAmazonStorefrontDeleteIds = defaultOneTimeServiceOptions.map(
            (item) => item.id,
          );

          // check delete new code

          const uniqueDeleteResult =
            updatedFormData?.additional_one_time_services?.delete?.filter(
              (obj) => {
                return !defaultAmazonStorefrontDeleteIds?.some((obj2) => {
                  return obj === obj2;
                });
              },
            );

          const getRemainingServices = findRemainingOneTimeServicesOnCheck();
          setFormData({
            ...formData,
            additional_one_time_services: [
              ...formData?.additional_one_time_services,
              ...defaultOneTimeServiceOptions,
              ...getRemainingServices,
            ],
          });
          setAdditionalOnetimeServices({
            ...additionalOnetimeServices,
            create: [...combineadditionalOnetimeServicesNew],
          });

          let remainingAmazonStorefrontCreatedData = [];
          if (updatedFormData?.additional_one_time_services?.create) {
            remainingAmazonStorefrontCreatedData = [
              ...updatedFormData?.additional_one_time_services?.create,
              ...defaultOneTimeServiceOptions,
              ...getRemainingServices,
            ];
          }
          setUpdatedFormData({
            ...updatedFormData,
            additional_one_time_services: {
              create: [...remainingAmazonStorefrontCreatedData],
              delete: [...uniqueDeleteResult],
            },
          });
          setAdditionalOnetimeServices({
            ...additionalOnetimeServices,
            create: [...remainingAmazonStorefrontCreatedData],
            delete: [...uniqueDeleteResult],
          });
        }
      }
      if (!defaultOneTimeServiceOptions?.length) {
        if (oneTimeServiceSubTabOptions?.length) {
          const list = [];
          oneTimeServiceSubTabOptions.map((item) =>
            list.push({
              name: item.label,
              quantity: 0,
              service_id: item.value,
              contract_id: formData.id,
            }),
          );

          if (formData?.additional_one_time_services?.length) {
            combineOneTimeServices = [
              ...formData.additional_one_time_services,
              ...list,
            ];
          } else {
            combineOneTimeServices = [...list];
          }

          if (additionalOnetimeServices?.create?.length) {
            combineadditionalOnetimeServices = [
              ...additionalOnetimeServices.create,
              ...list,
            ];
          } else {
            combineadditionalOnetimeServices = [...list];
          }

          setFormData({
            ...formData,
            additional_one_time_services: [...combineOneTimeServices],
          });
          setAdditionalOnetimeServices({
            ...additionalOnetimeServices,
            create: [...combineadditionalOnetimeServices],
          });
          setUpdatedFormData({
            ...updatedFormData,
            additional_one_time_services: {
              ...updatedFormData.additional_one_time_services,
              create: [...combineOneTimeServices],
            },
          });
        }
      }
    } else if (!event.target.checked) {
      showFooter(true);
      let oneTimeServiceTemp1;
      const deletedOneTimeServiceId = [];
      if (
        formData &&
        formData.additional_one_time_services &&
        formData.additional_one_time_services.length
      ) {
        oneTimeServiceTemp1 = formData.additional_one_time_services.filter(
          (service) =>
            service?.name
              ? !service?.name?.includes(tabName)
              : !service?.service?.name?.includes(tabName),
        );

        const oneTimeServiceTemp2 =
          formData?.additional_one_time_services?.filter((service) =>
            service?.name
              ? service?.name?.includes(tabName)
              : service?.service?.name?.includes(tabName),
          );
        if (oneTimeServiceTemp2?.length) {
          oneTimeServiceTemp2.map((service) =>
            deletedOneTimeServiceId.push(service.id),
          );
        }
      }

      setFormData({
        ...formData,
        additional_one_time_services: [...oneTimeServiceTemp1],
      });
      let tempDeletedServices = [];
      if (updatedFormData?.additional_one_time_services?.delete) {
        tempDeletedServices = [
          ...updatedFormData.additional_one_time_services?.delete,
        ];
      }

      setUpdatedFormData({
        ...updatedFormData,
        additional_one_time_services: {
          ...updatedFormData.additional_one_time_services,
          create: [...oneTimeServiceTemp1],
          delete: [...tempDeletedServices, ...deletedOneTimeServiceId],
        },
      });
      setAdditionalOnetimeServices({
        ...additionalOnetimeServices,
        create: [...oneTimeServiceTemp1],
        delete: [
          ...additionalOnetimeServices.delete,
          ...deletedOneTimeServiceId,
        ],
      });
    }
  };

  return (
    <>
      <div className="col-12">
        <CheckBox>
          <label
            className={
              contractAddendumDetails?.notActive
                ? 'check-container customer-pannel isDisabled'
                : 'check-container customer-pannel'
            }
            htmlFor={`${tabName}-check`}
          >
            {returnSubTabName(tabName)}
            <input
              type="checkbox"
              id={`${tabName}-check`}
              onClick={(event) => {
                setShowOneTimeServicesSubTabs(event.target.checked);
                handleChangeOneTimeServicesSubTabs(event);
              }}
              defaultChecked={
                formData?.additional_one_time_services?.length &&
                formData?.additional_one_time_services?.find((item) =>
                  item.name
                    ? item.name.includes(tabName)
                    : item.service?.name.includes(tabName),
                )
              }
              disabled={contractAddendumDetails?.notActive}
            />
            <span className="checkmark" />
          </label>
        </CheckBox>
      </div>
      {showOneTimeServicesSubTabs && oneTimeServiceSubTabOptions?.length
        ? [displayOneTimeServicesSubTabs(), showError()]
        : ''}
    </>
  );
}

export default AdditionalOneTimeServicesSubTabs;

AdditionalOneTimeServicesSubTabs.defaultProps = {
  tabName: '',
  details: {},
  additionalOneTimeServicesLength: number,
  formData: {},
  setFormData: () => {},
  setUpdatedFormData: () => {},
  oneTimeService: {},
  contractAddendumDetails: {},
  updatedFormData: {},
  additionalOnetimeServices: {},
  setAdditionalOnetimeServices: () => {},
  showFooter: () => {},
  additionalOnetimeSerError: {},
  setAdditionalOnetimeSerError: () => {},
  updateAdditionalOnetimeServicesSelectedData: () => {},
  clearOneTimeQntyError: () => {},
  sectionError: {},
  updateStatementErrorValue: () => {},
  combinedOneTimeServices: [],
  filterNotIncludedOneTimeServices: () => {},
};

AdditionalOneTimeServicesSubTabs.propTypes = {
  tabName: string,
  details: shape({}),
  additionalOneTimeServicesLength: number,
  formData: shape({}),
  setFormData: shape({}),
  setUpdatedFormData: shape({}),
  oneTimeService: shape({}),
  contractAddendumDetails: shape({ notActive: bool }),
  updatedFormData: shape({}),
  additionalOnetimeServices: shape({
    create: arrayOf(shape({})),
    delete: arrayOf(string, shape({})),
  }),
  setAdditionalOnetimeServices: shape({}),
  showFooter: func,
  additionalOnetimeSerError: shape({}),
  setAdditionalOnetimeSerError: func,
  updateAdditionalOnetimeServicesSelectedData: func,
  clearOneTimeQntyError: func,
  sectionError: shape({}),
  updateStatementErrorValue: func,
  combinedOneTimeServices: arrayOf(shape({})),
  filterNotIncludedOneTimeServices: func,
};
