export const emailRecipientFields = [
  {
    key: 'recipient_first_name',
    type: 'text',
    placeholder: 'First Name',
    classname: 'col-3 ',
  },
  {
    key: 'recipient_last_name',
    type: 'text',
    placeholder: 'Last Name',
    classname: 'col-3 ',
  },
  {
    key: 'recipient_email',
    type: 'email',
    placeholder: 'Email',
    classname: 'col-6',
  },
];

export const emailRecipientOption = [
  {
    label: 'YES',
    value: 'yes',
  },
  {
    label:
      'NO - succceding survey will be sent out to the previous email recipient.',
    value: 'no',
  },
];

export const customStylesForTableModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '600px ',
    width: '100% ',
    overlay: ' {zIndex: 1000}',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
