import React, { useState } from 'react';

import Select, { components } from 'react-select';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { arrayOf, func, shape, string } from 'prop-types';

import { createCustomer, createContract } from '../../../../api';
import { newChildAgreementTypes, PATH_AGREEMENT } from '../../../../constants';
import {
  Button,
  ContractInputSelect,
  ErrorMsg,
  InputFormField,
  ModalBox,
  PageLoader,
  DropDownIndicator,
} from '../../../../common';

export default function AddChildAgreementModal({
  id,
  setShowAgreementModal,
  childBPList,
}) {
  const { register, handleSubmit, errors, clearErrors, control } = useForm();
  const { Option } = components;
  const [isLoading, setIsLoading] = useState({ loader: false, type: 'button' });
  const [selectedAgreements, setSelectedAgreements] = useState({});
  const history = useHistory();
  const childBrand = [
    ...childBPList,
    { value: 'new_bp', label: 'Create a new Child Brand' },
  ];
  const agreementDetails = [
    {
      key: 'contract_type',
      label: 'Select Agreement Type',
      options: newChildAgreementTypes,
    },
    {
      key: 'child_account',
      label: 'Select Child Account',
      options: childBrand,
    },
  ];

  const createAgreement = (data, newCustomerId) => {
    const customerId =
      data?.child_account?.value === 'new_bp'
        ? newCustomerId
        : data?.child_account?.value;
    const agreementData = {
      contract_type: data.contract_type.value,
      customer_id: customerId,
    };
    createContract(agreementData).then((agreementRes) => {
      if (agreementRes?.status === 201) {
        setIsLoading({ loader: false, type: 'button' });
        history.push(
          PATH_AGREEMENT.replace(':id', customerId).replace(
            ':contract_id',
            agreementRes?.data?.id,
          ),
        );
      }
    });
  };

  const onSubmit = (data) => {
    setIsLoading({ loader: true, type: 'button' });
    if (data?.child_account?.value === 'new_bp') {
      createCustomer({
        company_name: data.company_name,
        account_type: 'child',
        child_bp_of: id,
      }).then((res) => {
        if (res?.status === 201) {
          createAgreement(data, res?.data?.id);
        }
      });
    } else {
      createAgreement(data, null);
    }
  };

  const IconOption = (dataProps) => (
    <Option {...dataProps}>
      <div style={{ fontSize: '14px', fontWeight: '500', color: '#000000' }}>
        {dataProps.data.label}
        <div
          style={{ fontSize: '12px', color: '#556178', paddingBottom: '8px' }}
          className="sub-title"
        >
          {' '}
          {dataProps.data.sub}
        </div>{' '}
      </div>
    </Option>
  );

  const getSelectComponents = () => {
    return {
      Option: IconOption,
      DropDownIndicator,
    };
  };

  const generateDropdown = (item) => {
    return (
      <>
        <label htmlFor={item.key}>{item.label}</label>
        <Controller
          name={item.key}
          control={control}
          rules={{
            required: { value: true, message: 'This field is required.' },
          }}
          onChange={(event) =>
            setSelectedAgreements({
              ...selectedAgreements,
              [item.key]: event.value,
            })
          }
          render={({ onChange, ...field }) => (
            <Select
              {...field}
              classNamePrefix="react-select"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              options={item.options}
              placeholder={item.label}
              components={getSelectComponents()}
              componentsValue={{ Option: IconOption }}
              onChange={(event) => {
                onChange(event);
                setSelectedAgreements({
                  ...selectedAgreements,
                  [item.key]: event.value,
                });
                clearErrors(item.key);
              }}
            />
          )}
        />
        <ErrorMsg>{errors?.[item.key]?.message}</ErrorMsg>
        <div className="straight-line horizontal-line mt-4 mb-3" />
      </>
    );
  };

  const generateNewBpHTML = () => {
    return (
      <InputFormField className="mt-3">
        <label htmlFor="company">
          Company Name
          <br />
          <input
            className="form-control"
            type="text"
            placeholder="Enter your company name"
            // onChange={() => handleChange()}
            name="company_name"
            ref={register({
              required: {
                value: true,
                message: 'This field is required.',
              },
            })}
          />
        </label>
        <ErrorMsg>{errors?.company_name?.message}</ErrorMsg>
      </InputFormField>
    );
  };

  return (
    <ModalBox>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div className="row">
            <div className="col-12 modal-heading p-0">
              <h4>Add New Agreement</h4>
              <div className="straight-line horizontal-line mt-3 " />
              <div className="body-content pb-0">
                {agreementDetails.map((item) => (
                  <ContractInputSelect className="mt-3">
                    {generateDropdown(item)}
                  </ContractInputSelect>
                ))}
                {selectedAgreements?.child_account === 'new_bp' ? (
                  <>
                    {generateNewBpHTML()}
                    <div className="straight-line horizontal-line mt-4 mb-3" />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <div className="row">
            <div className=" col-6">
              <Button className="btn-primary w-100" type="submit">
                {isLoading.loader && isLoading.type === 'button' ? (
                  <PageLoader color="#fff" type="button" />
                ) : (
                  'Confirm'
                )}
              </Button>
            </div>
            <div className="col-6">
              <Button
                className="btn-borderless w-100"
                onClick={() => {
                  clearErrors();
                  setShowAgreementModal(false);
                }}
              >
                Discard Changes
              </Button>
            </div>
          </div>
        </div>
      </form>
    </ModalBox>
  );
}

AddChildAgreementModal.defaultProps = {
  id: '',
  setShowAgreementModal: () => {},
  childBPList: [],
};

AddChildAgreementModal.propTypes = {
  id: string,
  setShowAgreementModal: func,
  childBPList: arrayOf(shape({})),
};
