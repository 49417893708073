/* eslint-disable new-cap */

import React, { useCallback, useEffect, useRef, useState } from 'react';

import axios from 'axios';
import styled from 'styled-components';
import dayjs from 'dayjs';
import b64toBlob from 'b64-to-blob';
import html2pdf from 'html2pdf-jspdf2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import { func, shape, string } from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';

import Theme from '../../../../theme/Theme';
import axiosInstance from '../../../../axios';
import ReportPDFStatusModal from '../ReportPDFStatusModal';
import ReportLeftSidePanel from './ReportLeftSidePanel';
import ReportRightSidePanel from './ReportRightSidePanel';
import ConfirmCloseReportModal from '../ConfirmCloseReportModal';
import BindGraphDataApiResponse from './BindGraphDataApiResponse';
import { ContractTab } from '../../../../theme/AgreementStyle';
import { OrangeDownloadPdf } from '../../../../theme/images';
import { HeaderDownloadFuntionality, PageLoader } from '../../../../common';
import {
  API_DOCUMENTS,
  metricsDisabledOnAgreement,
  metricsTooltipArray,
  selleCollapseIds,
  sellerDetails,
  sellerDetailsEditor,
  setTimeFrame,
  vendorDetails,
  vendorDetailsEditor,
} from '../../../../constants';
import {
  getBuyBoxChartData,
  getCutomReportASINPerformanceData,
  getReportGraphSelectedFileds,
  getRestockInventoryData,
  getSalesSponsoredDspMetricsData,
  getStrandedInventoryData,
  saveReport,
  getAsinDataCount,
  checkReportStatusToEdit,
  changeReportStatus,
  getCustomReportActivity,
  getReportTemplate,
} from '../../../../api';

const _ = require('lodash');
const getSymbolFromCurrency = require('currency-symbol-map');

const DOWNLOAD_ACTION = 'downloaded';

const CreateReportContainer = ({
  onCloseReport,
  accType,
  marketplace,
  customReportStatus,
  reportId,
  customerId,
  customerName,
  pdfURL,
  selectedReportTemplate,
}) => {
  const html2pdfRef = useRef();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const nonDraftCustomStyles = {
    width: '50%',
    margin: '0',
    paddingLeft: '0',
    paddingRight: '0',
  };
  const mounted = useRef(false);
  const counterRef = useRef(null);

  const [accountType, setAccountType] = useState({
    isSeller: accType === 'Seller',
    isVendor: accType === 'Vendor',
    isHybrid: accType === 'Hybrid',
    value: accType,
  });

  // PDV-8793 - Update Vendor Sales data to T-3
  const dayLimit = accType === 'Vendor' ? 3 : 2;
  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() - dayLimit);
  const [currentDate] = useState(todayDate);

  const [selectedMarketplace, setSelectedMarketplace] = useState(
    marketplace || { label: 'United State', value: 'amazon.com' },
  );
  const [marketplaceCurrency, setMarketplaceCurrency] = useState(
    getSymbolFromCurrency(marketplace?.currency || 'USD'),
  );
  const [reportStatus, setReportStatus] = useState(customReportStatus || 'new');
  const [customReportId, setCustomReportId] = useState(reportId);
  const [reportSummary, setReportSummary] = useState('');
  const [reportPresignedUrl, setReportPresignedUrl] = useState(pdfURL);
  const [showTabResponsive, setShowTabResponsive] = useState('edit-report');
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(undefined);
  const [getSelectedFieldLoader, setGetSelectedFieldLoader] = useState(false);
  const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);
  const [showSaveReportModal, setShowSaveReportModal] = useState(false);
  const [reportDescription, setReportDescription] = useState('');
  const [reportTitle, setReportTitle] = useState('');
  const [metricDataLoader, setMetricDataLoader] = useState(false);
  const [downloader, setDownloader] = useState(false);
  const [autoScrollId, setAutoScrollId] = useState(null);

  const [countDownTimer, setCountDownTimer] = useState('00:00:00');
  const [inventoryDatesMessage, setInventoryDatesMessage] = useState();
  const [asinDataCount, setAsinData] = useState({});
  const [isRemainderUp, setIsRemainderUp] = useState(false);
  const [isTimeFrameDisabled, setIsTimeFrameDisabled] = useState(
    reportStatus === 'readyToEdit',
  );
  const [metricsDataAvailabilityFlag, setMetricsDataAvailabilityFlag] =
    useState([]);
  const [enabledGroupFrequencyList, setEnabledGroupFrequencyList] = useState([
    'daily',
    'weekly',
    'monthly',
  ]);
  const [selectedState, setSelectedState] = useState([
    {
      startDate: currentDate,
      endDate: currentDate,
      key: 'reportSelection',
    },
  ]);
  const [dateToShow, setDateToShow] = useState({
    startDate: currentDate,
    endDate: currentDate,
  });

  // data variables
  const [graphApiResponseData, setGraphApiResponseData] = useState([]);
  const [currentPDFUser, setCurrentPDFUser] = useState();
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [reportPDFstatusModal, setReportPDFStatusModal] = useState(false);
  const [showReportPDFTemplate, setShowReportPDFTemplate] = useState(false);
  const [templateTimeFrame, setTemplateTimeFrame] = useState(null);
  const [templateReportTitle, setTemplateReportTitle] = useState(null);
  const [templateReportDescrition, setTemplateReportDescrition] =
    useState(null);
  const [showConfirmCloseReportModal, setShowConfirmCloseReportModal] =
    useState(false);
  const [showMetricsTooltip, setShowMetricsTooltip] = useState([]);

  // useeffet to get the asin data
  useEffect(() => {
    if (selectedTimeFrame !== undefined) {
      getAsinDataCount(
        customerId,
        selectedMarketplace?.value,
        selectedTimeFrame?.value,
      ).then((res) => {
        if (mounted.current) {
          if (res?.status === 200) {
            setAsinData(res.data);
          }
        }
      });
    }
  }, [customerId, selectedMarketplace.value, selectedTimeFrame]);

  // useeffet to auto scroll functionality
  useEffect(() => {
    if (autoScrollId !== null) {
      const element = document.getElementById(autoScrollId);
      if (element !== null) element.scrollIntoView({ behavior: 'smooth' });
    }

    return () => setAutoScrollId(null);
  }, [autoScrollId]);

  // update the report status from 'inprogress' to 'ready'
  const updateReportStatus = (theReportStatus, reptId = customReportId) => {
    changeReportStatus(reptId, theReportStatus);
  };

  // Countdown functionality start //
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e, localReportStatus) => {
    const { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setCountDownTimer(
        `${hours > 9 ? hours : `0${hours}`}:${
          minutes > 9 ? minutes : `0${minutes}`
        }:${seconds > 9 ? seconds : `0${seconds}`}`,
      );
    }
    // replace 60000 to 300000- for 1 hr- 55 min
    // calculation-> 1 sec = 1000;
    // 5 min before so, 60*5 =300  (60 sec in min)
    // 300 * 1000 = 3000000
    if (total === 300000 && localReportStatus === 'edit') {
      setShowSaveReportModal(true);
      setIsRemainderUp(true);
    }
    if (total === -1000 && localReportStatus === 'edit') {
      changeReportStatus(customReportId, 'ready').then((res) => {
        if (res?.status === 200) {
          onCloseReport();
          clearInterval(counterRef.current);
        }
      });
    }
  };

  const clearTimer = (e, localReportStatus) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next

    setCountDownTimer('01:00:00'); // for 1 hr-setTimer('01:00:00');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (counterRef.current) clearInterval(counterRef.current);

    const id = setInterval(() => {
      startTimer(e, localReportStatus);
    }, 1000);
    counterRef.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 3600); // 3600 for 1 hr.
    return deadline;
  };

  // useeffet to timer functionality
  useEffect(() => {
    if (reportStatus === 'edit') {
      clearTimer(getDeadTime(), reportStatus);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportStatus]);

  // Countdown functionality END //

  const bindGraphDetails = () => {
    const localGrapgh = [];
    const localEditor = [];
    if (accountType.isSeller) {
      localGrapgh.push({
        isOpen: true,
        sellerDetails,
        vendorDetails: null,
      });
      localEditor.push({
        sellerDetails: sellerDetailsEditor,
        vendorDetails: null,
      });
    } else if (accountType.isVendor) {
      localGrapgh.push({
        isOpen: true,
        sellerDetails: null,
        vendorDetails,
      });
      localEditor.push({
        sellerDetails: null,
        vendorDetails: vendorDetailsEditor,
      });
    } else if (accountType.isHybrid) {
      localGrapgh.push({
        isOpen: true,
        sellerDetails,
        vendorDetails,
      });
      localEditor.push({
        sellerDetails: sellerDetailsEditor,
        vendorDetails: vendorDetailsEditor,
      });
    }

    return { localGrapgh, localEditor };
  };

  const [graphDetails, setGraphDetails] = useState(
    bindGraphDetails().localGrapgh,
  );
  const [editorDetails, setEditorDetails] = useState(
    bindGraphDetails().localEditor,
  );

  const onGetCustomReportActivity = useCallback((action, reportID) => {
    getCustomReportActivity(action, reportID).then(() => {});
  }, []);

  // download report pdf
  const handleDownload = () => {
    if (reportPresignedUrl) {
      setDownloader(true);
      fetch(reportPresignedUrl).then((response) => {
        const pdfName = `${customerName} ${reportTitle} ${dayjs(
          new Date(),
        ).format('DD MMM YYYY')}.pdf`.replaceAll(' ', '_');

        response
          .blob()
          .then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            const alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = pdfName;
            alink.click();
            setDownloader(false);
            onGetCustomReportActivity(DOWNLOAD_ACTION, customReportId);
          })
          .catch(() => setDownloader(false));
      });
    }
  };

  // handle time zone offset
  const handleTimezoneOffset = (startDate, endDate) => {
    const tempStartDate = new Date(startDate);
    const tempEndDate = new Date(endDate);

    const timezoneOffset = tempStartDate?.getTimezoneOffset();
    if (timezoneOffset > 0) {
      tempStartDate.setHours(tempStartDate.getHours() + 12);
      tempEndDate.setHours(tempEndDate.getHours() + 12);
    }
    return { tempStartDate, tempEndDate };
  };

  // Checking show metrics tooltip
  const handleShowMetricsTooltip = (metricsData) => {
    const tempMetricsData = [];

    if (metricsTooltipArray?.length) {
      for (const item of metricsTooltipArray) {
        if (Object.hasOwnProperty.call(metricsData, item))
          tempMetricsData.push({
            [item]: metricsData?.[item] !== true,
          });
      }
    }

    return tempMetricsData;
  };

  // here we checked the metrics shoud be disabled or not in "metricsDisabledOnAgreement[metricsFlag]"
  // but when return value we used '! (not)' because the return value set as metric data availability, not metric disability

  const checkTacosMetricsAvailability = (
    flag,
    selectedTime,
    isHavingDspPPC,
    metricsName,
  ) => {
    const august1st2022 = new Date();
    august1st2022.setMonth(7);
    august1st2022.setDate(1);
    august1st2022.setYear(2022);

    const customEndDate = selectedState?.[0]?.endDate;
    if (!flag || selectedTime !== 'custom') {
      return flag;
    }

    let metricsFlag = metricsName;

    if (customEndDate < august1st2022) {
      metricsFlag = `${metricsFlag}Before`;
      if (isHavingDspPPC?.isHavingPpc) {
        metricsFlag = `${metricsFlag}Ppc`;
      }
      if (isHavingDspPPC?.isHavingDsp) {
        metricsFlag = `${metricsFlag}Dsp`;
      }
    } else if (customEndDate > august1st2022) {
      metricsFlag = `${metricsFlag}After`;
      if (isHavingDspPPC?.isHavingPpc) {
        metricsFlag = `${metricsFlag}Ppc`;
      }
      if (isHavingDspPPC?.isHavingDsp) {
        metricsFlag = `${metricsFlag}Dsp`;
      }
    }

    return !metricsDisabledOnAgreement[metricsFlag];

    // const isMetricsTrue = Object.values(salesPerformanceMetricsFlag).some(
    //   (value) => value === true,
    // );

    // if (customEndDate < august1st2022 && selectedTime === 'custom') {
    //   if (
    //     isHavingDspPPC?.isHavingDsp &&
    //     !isHavingDspPPC?.isHavingPpc &&
    //     isMetricsTrue
    //   ) {
    //     metricsFlag = true;
    //   } else {
    //     metricsFlag = false;
    //   }
    // }
  };

  // bind metrics vailability
  const bindMetricsDataAvailability = (
    response,
    type,
    selectedTime,
    isHavingDspPPC,
  ) => {
    let localMetricsData = {};

    if (
      response !== null &&
      response !== undefined &&
      Object.keys(response).length
    ) {
      if (type === 'seller') {
        localMetricsData = {
          salesRevenueTotal: response?.revenue_flag,
          salesRevenuePpc: response?.revenue_ppc_flag,
          salesRevenueDsp: response?.revenue_dsp_flag,
          salesTacos: checkTacosMetricsAvailability(
            response?.tacos_flag,
            selectedTime,
            isHavingDspPPC,
            'salesTacos',
          ),
          salesUnitSold: response?.units_sold_flag,
          salesTraffic: response?.traffic_flag,
          salesConversion: response?.conversion_flag,
          salesAdSpendTotal: checkTacosMetricsAvailability(
            response?.ad_spend_total_flag,
            selectedTime,
            isHavingDspPPC,
            'salesAdSpendTotal',
          ),
          salesAdSpendPpc: checkTacosMetricsAvailability(
            response?.sales_ad_spend_flag,
            selectedTime,
            isHavingDspPPC,
            'salesAdSpendPpc',
          ),
          salesAdSpendDsp: checkTacosMetricsAvailability(
            response?.sales_dsp_spends_flag,
            selectedTime,
            isHavingDspPPC,
            'salesAdSpendDsp',
          ),

          adSales: response?.all_ad_sales_flag,
          adSpend: response?.all_ad_spend_flag,
          adConversionRate: response?.all_ad_conversion_rate_flag,
          adImpressions: response?.all_impressions_flag,
          adAcos: response?.all_acos_flag,
          adRoas: response?.all_roas_flag,
          adCostPerClick: response?.all_cost_per_click_flag,
          adClicks: response?.all_clicks_flag,
          adClickThroughRate: response?.all_ctr_flag,

          adSalesSP: response?.product_campaign_report_ad_sales_flag,
          adSpendSP: response?.product_campaign_report_ad_spend_flag,
          adConversionRateSP:
            response?.product_campaign_report_ad_conversion_rate_flag,
          adImpressionsSP: response?.product_campaign_report_impressions_flag,
          adAcosSP: response?.product_campaign_report_acos_flag,
          adRoasSP: response?.product_campaign_report_roas_flag,
          adCostPerClickSP:
            response?.product_campaign_report_cost_per_click_flag,
          adClicksSP: response?.product_campaign_report_clicks_flag,
          adClickThroughRateSP: response?.product_campaign_report_ctr_flag,

          adSalesSB: response?.brand_campaign_report_ad_sales_flag,
          adSpendSB: response?.brand_campaign_report_ad_spend_flag,
          adConversionRateSB:
            response?.brand_campaign_report_ad_conversion_rate_flag,
          adImpressionsSB: response?.brand_campaign_report_impressions_flag,
          adAcosSB: response?.brand_campaign_report_acos_flag,
          adRoasSB: response?.brand_campaign_report_roas_flag,
          adCostPerClickSB: response?.brand_campaign_report_cost_per_click_flag,
          adClicksSB: response?.brand_campaign_report_clicks_flag,
          adClickThroughRateSB: response?.brand_campaign_report_ctr_flag,

          adSalesSBV: response?.brand_video_campaign_report_ad_sales_flag,
          adSpendSBV: response?.brand_video_campaign_report_ad_spend_flag,
          adConversionRateSBV:
            response?.brand_video_campaign_report_ad_conversion_rate_flag,
          adImpressionsSBV:
            response?.brand_video_campaign_report_impressions_flag,
          adAcosSBV: response?.brand_video_campaign_report_acos_flag,
          adRoasSBV: response?.brand_video_campaign_report_roas_flag,
          adCostPerClickSBV:
            response?.brand_video_campaign_report_cost_per_click_flag,
          adClicksSBV: response?.brand_video_campaign_report_clicks_flag,
          adClickThroughRateSBV: response?.brand_video_campaign_report_ctr_flag,

          adSalesSD: response?.sponsored_display_campaign_report_ad_sales_flag,
          adSpendSD: response?.sponsored_display_campaign_report_ad_spend_flag,
          adConversionRateSD:
            response?.sponsored_display_campaign_report_ad_conversion_rate_flag,
          adImpressionsSD:
            response?.sponsored_display_campaign_report_impressions_flag,
          adAcosSD: response?.sponsored_display_campaign_report_acos_flag,
          adRoasSD: response?.sponsored_display_campaign_report_roas_flag,
          adCostPerClickSD:
            response?.sponsored_display_campaign_report_cost_per_click_flag,
          adClicksSD: response?.sponsored_display_campaign_report_clicks_flag,
          adClickThroughRateSD:
            response?.sponsored_display_campaign_report_ctr_flag,

          dspImpressions: response?.dsp_impressions_flag,
          dspSpend: response?.dsp_dsp_spend_flag,
          dspTotalProductSales: response?.total_product_sales_flag,
          dspTotalRoas: response?.total_dsp_roas_flag,
          dspTotalDpvr: response?.total_dpvr_flag,
          dspTotalNewBrandPurchases: response?.ttl_new_brand_purchases_flag,
          dspProductSales: response?.product_sales_flag,
          dspRoas: response?.dsp_roas_flag,
        };
      } else {
        localMetricsData = {
          salesShippedCogs: response?.shipped_cogs_flag,
          salesShippedGlanceViews: response?.shipped_glance_views_flag,
          salesShippedConversionRate: response?.shipped_conversion_rate_flag,
          salesShippedUnits: response?.shipped_units_flag,
          salesOrderedRevenue: response?.ordered_revenue_flag,
          salesOrderedGlanceViews: response?.glance_views_flag,
          salesOrderedConversionRate: response?.conversion_rate_flag,
          salesOrderedUnits: response?.ordered_units_flag,

          adSales: response?.all_ad_sales_flag,
          adSpend: response?.all_ad_spend_flag,
          adConversionRate: response?.all_ad_conversion_rate_flag,
          adImpressions: response?.all_impressions_flag,
          adAcos: response?.all_acos_flag,
          adRoas: response?.all_roas_flag,
          adCostPerClick: response?.all_cost_per_click_flag,
          adClicks: response?.all_clicks_flag,
          adClickThroughRate: response?.all_ctr_flag,

          adSalesSP: response?.product_campaign_report_ad_sales_flag,
          adSpendSP: response?.product_campaign_report_ad_spend_flag,
          adConversionRateSP:
            response?.product_campaign_report_ad_conversion_rate_flag,
          adImpressionsSP: response?.product_campaign_report_impressions_flag,
          adAcosSP: response?.product_campaign_report_acos_flag,
          adRoasSP: response?.product_campaign_report_roas_flag,
          adCostPerClickSP:
            response?.product_campaign_report_cost_per_click_flag,
          adClicksSP: response?.product_campaign_report_clicks_flag,
          adClickThroughRateSP: response?.product_campaign_report_ctr_flag,

          adSalesSB: response?.brand_campaign_report_ad_sales_flag,
          adSpendSB: response?.brand_campaign_report_ad_spend_flag,
          adConversionRateSB:
            response?.brand_campaign_report_ad_conversion_rate_flag,
          adImpressionsSB: response?.brand_campaign_report_impressions_flag,
          adAcosSB: response?.brand_campaign_report_acos_flag,
          adRoasSB: response?.brand_campaign_report_roas_flag,
          adCostPerClickSB: response?.brand_campaign_report_cost_per_click_flag,
          adClicksSB: response?.brand_campaign_report_clicks_flag,
          adClickThroughRateSB: response?.brand_campaign_report_ctr_flag,

          adSalesSBV: response?.brand_video_campaign_report_ad_sales_flag,
          adSpendSBV: response?.brand_video_campaign_report_ad_spend_flag,
          adConversionRateSBV:
            response?.brand_video_campaign_report_ad_conversion_rate_flag,
          adImpressionsSBV:
            response?.brand_video_campaign_report_impressions_flag,
          adAcosSBV: response?.brand_video_campaign_report_acos_flag,
          adRoasSBV: response?.brand_video_campaign_report_roas_flag,
          adCostPerClickSBV:
            response?.brand_video_campaign_report_cost_per_click_flag,
          adClicksSBV: response?.brand_video_campaign_report_clicks_flag,
          adClickThroughRateSBV: response?.brand_video_campaign_report_ctr_flag,

          adSalesSD: response?.sponsored_display_campaign_report_ad_sales_flag,
          adSpendSD: response?.sponsored_display_campaign_report_ad_spend_flag,
          adConversionRateSD:
            response?.sponsored_display_campaign_report_ad_conversion_rate_flag,
          adImpressionsSD:
            response?.sponsored_display_campaign_report_impressions_flag,
          adAcosSD: response?.sponsored_display_campaign_report_acos_flag,
          adRoasSD: response?.sponsored_display_campaign_report_roas_flag,
          adCostPerClickSD:
            response?.sponsored_display_campaign_report_cost_per_click_flag,
          adClicksSD: response?.sponsored_display_campaign_report_clicks_flag,
          adClickThroughRateSD:
            response?.sponsored_display_campaign_report_ctr_flag,

          dspImpressions: response?.dsp_impressions_flag,
          dspSpend: response?.dsp_dsp_spend_flag,
          dspTotalProductSales: response?.total_product_sales_flag,
          dspTotalRoas: response?.total_dsp_roas_flag,
          dspTotalDpvr: response?.total_dpvr_flag,
          dspTotalNewBrandPurchases: response?.ttl_new_brand_purchases_flag,
          dspProductSales: response?.product_sales_flag,
          dspRoas: response?.dsp_roas_flag,
        };
      }
    }

    return localMetricsData;
  };

  const createDocument = (newPDF, pdfName, reptId) => {
    let api;
    let blob;
    // eslint-disable-next-line no-unused-expressions
    newPDF?.output('datauristring').then((doc) => {
      const base64ToBlob = doc?.split(',')[1];
      blob = b64toBlob(base64ToBlob, 'application/pdf');
      const formData = {
        original_name: pdfName,
        entity_id: reptId,
        entity_type: 'customreport',
        mime_type: blob.type,
        status: 'requested',
        size: blob.size,
        document_type: 'custom_report',
      };
      api = axiosInstance.post(API_DOCUMENTS, formData);

      api.then((res) => {
        if (res.data?.presigned_url !== '') {
          const request = {
            url: res.data.presigned_url,
            headers: {
              'Content-type': res?.data?.mime_type,
            },
          };
          axios
            .put(request.url, blob, { headers: request.headers })
            .then(() => {
              axiosInstance
                .patch(`${API_DOCUMENTS + res?.data.id}/`, {
                  status: 'available',
                })
                .then((response) => {
                  toast.success('Your report has been saved successfully.');
                  updateReportStatus('ready', reptId);
                  setIsRemainderUp(false);
                  clearInterval(counterRef.current);
                  setCountDownTimer('00:00:00');
                  setShowSaveReportModal(false);
                  setReportStatus('readyToEdit');
                  setSaveDetailsLoader(false);
                  setReportPresignedUrl(response?.data?.presigned_url);
                  setShowReportPDFTemplate(false);
                });
            });
        }
        return res;
      });
    });
  };

  const generatehtml2pdf = (reptId) => {
    if (html2pdfRef?.current) {
      const option = {
        margin: [5, 5, 7, 5],
        filename: 'html2pdf-jspdf2.pdf',
        image: { type: 'svg', quality: 1 },
        html2canvas: {
          useCORS: true,
          scale: 1.5,
          imageTimeout: 0,
          letterRendering: true,
        },

        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
          compress: true,
        },
        pagebreak: {
          mode: ['avoid-all'],
          after: '.pdf-page-break',
        },
      };

      const finalPDF = html2pdf()
        .from(html2pdfRef?.current)
        .set(option)
        .toPdf()
        .get('pdf')
        .then((doc) => {
          // Customizing the pdf footer
          const totalPages = doc.internal.getNumberOfPages();
          const { pageSize } = doc?.internal;

          for (let i = 0; i < totalPages; i += 1) {
            doc.setPage(i + 1);
            doc.setTextColor(140);
            doc.setFontSize(8);
            const copyRightText = '© Buy Box Experts';
            const currentPage = `Page ${i + 1} of ${totalPages}`;
            doc.text(copyRightText, 5, pageSize?.getHeight() - 3, 0, 0, 'left');
            doc.text(
              currentPage,
              pageSize?.getWidth() - 5,
              pageSize?.getHeight() - 3,
              0,
              0,
              'right',
            );
          }
          return doc;
        });
      const pdfName = `${customerName} ${reportTitle} ${dayjs(
        new Date(),
      ).format('DD MMM YYYY')}.pdf`.replaceAll(' ', '_');

      createDocument(finalPDF, pdfName, reptId);
    }
  };

  const getMetricsReportData = (
    indexNumber,
    frequencyValue,
    // adTypeValue,
    selectedTime,
    fieldName,
  ) => {
    setMetricDataLoader(true);

    let sd = '';
    let ed = '';
    const localSd = new Date(selectedState[0].startDate);
    const localEd = new Date(selectedState[0].endDate);

    sd = `${localSd.getDate()}-${
      localSd.getMonth() + 1
    }-${localSd.getFullYear()}`;
    ed = `${localEd.getDate()}-${
      localEd.getMonth() + 1
    }-${localEd.getFullYear()}`;

    getSalesSponsoredDspMetricsData(
      customerId,
      selectedMarketplace?.value,
      selectedTime,
      sd,
      ed,
      accountType?.value,
      frequencyValue,
      // adTypeValue,
    ).then((res) => {
      if (mounted.current) {
        if (res?.status === 200) {
          const newGraph = JSON.parse(JSON.stringify(graphApiResponseData));
          const dataAvailebleMetrics = JSON.parse(
            JSON.stringify(metricsDataAvailabilityFlag),
          );

          if (res?.data?.result) {
            const isHavingDspPPC = {
              isHavingDsp: res?.data?.result?.is_having_dsp,
              isHavingPpc: res?.data?.result?.is_having_ppc,
            };
            if (accountType.isSeller || accountType.isHybrid) {
              const sellerResponse = BindGraphDataApiResponse(
                res?.data?.result,
                'seller',
                selectedState,
              );

              newGraph[indexNumber].sellerDetails.sellerData =
                sellerResponse.tempData;
              newGraph[indexNumber].sellerDetails.sellerTotalData =
                sellerResponse.metricsTotal;

              const returnObject = bindMetricsDataAvailability(
                res?.data?.result?.seller_performance_availability,
                'seller',
                selectedTime,
                isHavingDspPPC,
              );

              dataAvailebleMetrics[indexNumber].sellerDetails = returnObject;
              setShowMetricsTooltip(handleShowMetricsTooltip(returnObject));
            }
            if (accountType.isVendor || accountType.isHybrid) {
              const vendorResponse = BindGraphDataApiResponse(
                res?.data?.result,
                'vendor',
                selectedState,
              );
              newGraph[indexNumber].vendorDetails.vendorData =
                vendorResponse.tempData;
              newGraph[indexNumber].vendorDetails.vendorTotalData =
                vendorResponse.metricsTotal;
              dataAvailebleMetrics[indexNumber].vendorDetails =
                bindMetricsDataAvailability(
                  res?.data?.result?.vendor_performance_availability,
                  'vendor',
                  selectedTime,
                  null,
                );
            }
            setCurrentEndDate(res?.data?.result?.current_end_date);
          } else {
            if (accountType.isSeller || accountType.isHybrid) {
              newGraph[indexNumber].sellerDetails.sellerData = [];
              dataAvailebleMetrics[indexNumber].sellerDetails = [];
            }
            if (accountType.isVendor || accountType.isHybrid) {
              newGraph[indexNumber].vendorDetails.vendorData = [];
              dataAvailebleMetrics[indexNumber].vendorDetails = [];
            }
            setCurrentEndDate();
          }
          setGraphApiResponseData(newGraph);
          setMetricsDataAvailabilityFlag(dataAvailebleMetrics);
          setMetricDataLoader(false);
          if (fieldName === 'sellerDetails') {
            setAutoScrollId(`collapseSellerGraph${indexNumber}`);
          } else {
            setAutoScrollId(`collapseVendorGraph${indexNumber}`);
          }
        } else {
          setMetricDataLoader(false);
        }
      }
    });
  };

  // parent asin data- on change value
  const getSalesAsinsReportData = (
    indexNumber,
    keyName,
    value,
    selectedTime,
  ) => {
    setMetricDataLoader(true);

    let asinType = '';
    let sortBy = '';
    let numberOfAsins = value;
    let sd = '';
    let ed = '';
    const localSd = new Date(selectedState[0].startDate);
    const localEd = new Date(selectedState[0].endDate);

    sd = `${localSd.getDate()}-${
      localSd.getMonth() + 1
    }-${localSd.getFullYear()}`;
    ed = `${localEd.getDate()}-${
      localEd.getMonth() + 1
    }-${localEd.getFullYear()}`;

    if (keyName === 'parentTopAsins') {
      asinType = 'parent_asin';
      sortBy = '-current_revenue';
    }

    if (keyName === 'parentBottomAsins') {
      asinType = 'parent_asin';
      sortBy = 'current_revenue';
    }
    if (keyName === 'isParentAllAsins') {
      asinType = 'parent_asin';
      sortBy = 'current_revenue';
      numberOfAsins = null;
    }
    if (keyName === 'childTopAsins') {
      asinType = 'child_asin';
      sortBy = '-current_revenue';
    }
    if (keyName === 'childBottomAsins') {
      asinType = 'child_asin';
      sortBy = 'current_revenue';
    }
    if (keyName === 'isChildAllAsins') {
      asinType = 'child_asin';
      sortBy = 'current_revenue';
      numberOfAsins = null;
    }

    getCutomReportASINPerformanceData(
      customerId,
      selectedMarketplace.value,
      asinType,
      sortBy,
      selectedTime,
      sd,
      ed,
      numberOfAsins,
    ).then((res) => {
      if (mounted.current) {
        if (res?.status === 200) {
          const newGraph = JSON.parse(JSON.stringify(graphApiResponseData));
          const dataAvailebleMetrics = JSON.parse(
            JSON.stringify(metricsDataAvailabilityFlag),
          );

          if (res?.data) {
            if (keyName === 'parentTopAsins') {
              newGraph[indexNumber].sellerDetails.topParentAsins = res?.data;
            }
            if (keyName === 'parentBottomAsins') {
              newGraph[indexNumber].sellerDetails.bottomParentAsins = res?.data;
            }
            if (keyName === 'isParentAllAsins') {
              newGraph[indexNumber].sellerDetails.allParentAsins =
                res?.data?.results;
            }
            if (keyName === 'childTopAsins') {
              newGraph[indexNumber].sellerDetails.topChildAsins = res?.data;
            }
            if (keyName === 'childBottomAsins') {
              newGraph[indexNumber].sellerDetails.bottomChildAsins = res?.data;
            }
            if (keyName === 'isChildAllAsins') {
              newGraph[indexNumber].sellerDetails.allChildAsins =
                res?.data?.results;
            }
          }

          setGraphApiResponseData(newGraph);
          setMetricsDataAvailabilityFlag(dataAvailebleMetrics);
          setMetricDataLoader(false);
          setAutoScrollId(`${selleCollapseIds[keyName]}${indexNumber}`);
        } else {
          setMetricDataLoader(false);
        }
      }
    });
  };

  // save and update report API fucntion- save btn click
  const saveReportDetails = useCallback(
    (editOption) => {
      setSaveDetailsLoader(true);
      const localGraphDetails = [];
      graphDetails.forEach((element) => {
        localGraphDetails.push({
          seller_detail: element.sellerDetails,
          vendor_detail: element.vendorDetails,
        });
      });

      let localGraphObject = {
        customer: customerId,
        name: reportTitle,
        timeframe: selectedTimeFrame.value,
        marketplace: selectedMarketplace.value,
        account_type: accountType.value,
        title: reportTitle,
        description: reportDescription,
        summary: reportSummary,
        custom_report_graph: localGraphDetails,
      };

      if (selectedTimeFrame.value === 'custom') {
        let sd = '';
        let ed = '';
        const localSd = new Date(selectedState[0].startDate);
        const localEd = new Date(selectedState[0].endDate);

        sd = `${localSd.getFullYear()}-${
          localSd.getMonth() + 1
        }-${localSd.getDate()}`;
        ed = `${localEd.getFullYear()}-${
          localEd.getMonth() + 1
        }-${localEd.getDate()}`;

        localGraphObject = {
          ...localGraphObject,
          start_date: sd,
          end_date: ed,
        };
      }

      saveReport(customReportId, localGraphObject, editOption).then((res) => {
        if (res?.status === 400) {
          setSaveDetailsLoader(false);
        }
        if (res?.status === 201 || res?.status === 200) {
          toast.info('Your downloaded report is being processed.');
          setCustomReportId(res?.data?.id);
          if (editOption === 'keepExisitng' && reportStatus === 'edit') {
            updateReportStatus('ready', customReportId);
          }

          if (res.data.timeframe === 'custom') {
            setSelectedState([
              {
                startDate: new Date(res?.data?.start_date),
                endDate: new Date(res?.data?.end_date),
                key: 'reportSelection',
              },
            ]);
          }

          const apiDate = handleTimezoneOffset(
            res?.data?.start_date,
            res?.data?.end_date,
          );
          setDateToShow({
            startDate: new Date(apiDate?.tempStartDate),
            endDate: new Date(apiDate?.tempEndDate),
          });
          setTimeout(() => {
            generatehtml2pdf(res?.data?.id);
          }, 5000);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      graphDetails,
      reportDescription,
      customReportId,
      reportSummary,
      reportTitle,
      selectedTimeFrame,
    ],
  );

  // according to selected time frame filter, return the enabled graph frequency
  const checkIsGroupFrequencyDisabled = (selectedField) => {
    let enabledList = [];
    if (selectedField === 'week') {
      enabledList = ['daily'];
    }

    if (selectedField === 'month' || selectedField === '30days') {
      enabledList = ['daily', 'weekly'];
    }

    if (selectedField === 'year') {
      const todaysDate = new Date();
      const startDate = new Date(todaysDate.getFullYear(), 0, 1);
      const endDate = currentDate; // alredy set T-2 days lag in global
      let diffDays = null;

      const diffTime = Math.abs(startDate - endDate);
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      if (diffDays > 60) {
        enabledList = ['weekly', 'monthly'];
      } else enabledList = ['daily', 'weekly']; // if (diffDays <= 60)
    }

    if (selectedField === 'custom') {
      let diffDays = null;
      const diffTime = Math.abs(
        selectedState[0].startDate - selectedState[0].endDate,
      );
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      if (diffDays > 60) {
        enabledList = ['weekly', 'monthly'];
      } else enabledList = ['daily', 'weekly']; // if (diffDays <= 60)
    }

    // according to selcted time frame, set the frequency option
    const newGraph = JSON.parse(JSON.stringify(graphDetails));
    graphDetails.forEach((option, index) => {
      if (accountType.isSeller || accountType.isHybrid) {
        if (
          !enabledList.includes(newGraph[index].sellerDetails.graphFrequency)
        ) {
          [newGraph[index].sellerDetails.graphFrequency] = enabledList;
        }
      }
      if (accountType.isVendor || accountType.isHybrid) {
        if (
          !enabledList.includes(newGraph[index].vendorDetails.graphFrequency)
        ) {
          [newGraph[index].vendorDetails.graphFrequency] = enabledList;
        }
      }
    });

    return { enabledList, newGraph };
  };

  // bind the seller helath response data
  const bindSellerHealthData = (localBbepData) => {
    const pieChartData = [];
    let tempBBData = {};
    let pfoiData = {};

    // buy box percentage graph data binding
    if (localBbepData.bbep) {
      const avg =
        localBbepData.bbep
          .filter((record) => record.bbep)
          .reduce((acc, record) => acc + record.bbep, 0) /
          localBbepData.bbep.length || 0;

      tempBBData = localBbepData.bbep.map((data) => {
        return {
          date: dayjs(data.report_date).format('MMM D YYYY'),
          value: data.bbep,
          avg: avg.toFixed(2),
        };
      });
    }

    // positive feedback, order issue and inventory score data binding
    if (localBbepData.pf_oi_is?.length) {
      const lastUpdated = localBbepData.pf_oi_is[0].latest_date;
      localBbepData.pf_oi_is[0].latest_date =
        dayjs(lastUpdated).format('MMM DD YYYY');

      [pfoiData] = localBbepData.pf_oi_is;

      const ipiValue = parseFloat(
        localBbepData.pf_oi_is[0].inventory_performance_index,
      );

      if (Number.isNaN(ipiValue)) {
        pieChartData.push(
          {
            name: 'Inventory',
            value: 'N/A',
          },
          {
            name: 'Total',
            value: 1000,
          },
        );
      } else {
        pieChartData.push(
          {
            name: 'Inventory',
            value: ipiValue,
          },
          {
            name: 'Total',
            value: 1000 - ipiValue,
          },
        );
      }
    } else {
      pieChartData.push(
        { name: 'Inventory', value: 'N/A' },
        { name: 'Total', value: 1000 },
      );
    }

    return [tempBBData, pfoiData, pieChartData];
  };

  // collect the response of multiple API calls
  const saveApiRequests = (apis, graphdata, isAddAdditional, timeFrame) => {
    axios
      .all(apis)
      .then(
        axios.spread((...responses) => {
          let responseIndex = 0;
          let availableDataCount = 0;

          const newGraph = JSON.parse(JSON.stringify(graphApiResponseData));

          const dataAvailebleMetrics = JSON.parse(
            JSON.stringify(metricsDataAvailabilityFlag),
          );

          if (isAddAdditional) {
            availableDataCount = newGraph.length;
          }
          const isHavingDspPPC = {
            isHavingDsp: responses?.[0]?.data?.result?.is_having_dsp,
            isHavingPpc: responses?.[0]?.data?.result?.is_having_ppc,
          };

          graphdata.forEach((element) => {
            if (isAddAdditional || graphApiResponseData.length === 0) {
              newGraph.push({
                sellerDetails: { sellerData: null },
                vendorDetails: { vendorData: null },
              });
            }
            dataAvailebleMetrics.push({
              sellerDetails: null,
              vendorDetails: null,
            });

            if (accountType.isSeller || accountType.isHybrid) {
              // bind metrics response
              const sellerResponse = BindGraphDataApiResponse(
                responses[responseIndex]?.data?.result,
                'seller',
                selectedState,
              );

              newGraph[availableDataCount].sellerDetails.sellerData =
                sellerResponse.tempData;
              newGraph[availableDataCount].sellerDetails.sellerTotalData =
                sellerResponse.metricsTotal;

              const returnObject = bindMetricsDataAvailability(
                responses[responseIndex]?.data?.result
                  ?.seller_performance_availability,
                'seller',
                timeFrame,
                isHavingDspPPC,
              );
              dataAvailebleMetrics[availableDataCount].sellerDetails =
                returnObject;

              responseIndex += 1;
              setShowMetricsTooltip(handleShowMetricsTooltip(returnObject));
              // parent top response
              if (element.sellerDetails.isParentTopAsins) {
                newGraph[availableDataCount].sellerDetails.topParentAsins =
                  responses[responseIndex]?.data;
                responseIndex += 1;
              }

              // parent bottom response
              if (element.sellerDetails.isParentBottomAsins) {
                newGraph[availableDataCount].sellerDetails.bottomParentAsins =
                  responses[responseIndex]?.data;
                responseIndex += 1;
              }

              // parent all response
              if (element.sellerDetails.isParentAllAsins) {
                newGraph[availableDataCount].sellerDetails.allParentAsins =
                  responses[responseIndex]?.data?.results;
                responseIndex += 1;
              }

              // child top response
              if (element.sellerDetails.isChildTopAsins) {
                newGraph[availableDataCount].sellerDetails.topChildAsins =
                  responses[responseIndex]?.data;
                responseIndex += 1;
              }

              // child bottom response
              if (element.sellerDetails.isChildBottomAsins) {
                newGraph[availableDataCount].sellerDetails.bottomChildAsins =
                  responses[responseIndex]?.data;
                responseIndex += 1;
              }

              // child all response
              if (element.sellerDetails.isChildAllAsins) {
                newGraph[availableDataCount].sellerDetails.allChildAsins =
                  responses[responseIndex]?.data?.results;
                responseIndex += 1;
              }

              // bbep graph response
              const localBbepData = responses[responseIndex].data;
              const [tempBBData, pfoiData, pieChartData] =
                bindSellerHealthData(localBbepData);

              newGraph[availableDataCount].sellerDetails.bbepData = tempBBData;
              newGraph[availableDataCount].sellerDetails.pfoiData = pfoiData;
              newGraph[availableDataCount].sellerDetails.inventoryScoreData =
                pieChartData;
              responseIndex += 1;

              // stranded inventory response
              newGraph[availableDataCount].sellerDetails.strandedInventory =
                responses[responseIndex]?.data;
              responseIndex += 1;

              // understock inventory response
              newGraph[availableDataCount].sellerDetails.understockInventory =
                responses[responseIndex]?.data;
              responseIndex += 1;

              // overstock inventory response
              newGraph[availableDataCount].sellerDetails.overstockInventory =
                responses[responseIndex]?.data;
              responseIndex += 1;
            }

            if (accountType.isVendor || accountType.isHybrid) {
              // bind vendor

              const vendorResponse = BindGraphDataApiResponse(
                responses[responseIndex]?.data?.result,
                'vendor',
                selectedState,
              );

              newGraph[availableDataCount].vendorDetails.vendorData =
                vendorResponse.tempData;
              newGraph[availableDataCount].vendorDetails.vendorTotalData =
                vendorResponse.metricsTotal;

              const returnVendorObject = bindMetricsDataAvailability(
                responses[responseIndex]?.data?.result
                  .vendor_performance_availability,
                'vendor',
                timeFrame,
                null,
              );

              dataAvailebleMetrics[availableDataCount].vendorDetails =
                returnVendorObject;
              responseIndex += 1;

              if (accountType.isVendor) {
                // stranded inventory response
                newGraph[availableDataCount].vendorDetails.strandedInventory =
                  responses[responseIndex]?.data;
                responseIndex += 1;

                // understock inventory response
                newGraph[availableDataCount].vendorDetails.understockInventory =
                  responses[responseIndex]?.data;
                responseIndex += 1;

                // overstock inventory response
                newGraph[availableDataCount].vendorDetails.overstockInventory =
                  responses[responseIndex]?.data;
                responseIndex += 1;
              }
            }

            availableDataCount += 1;
          });

          setCurrentEndDate(responses[0]?.data?.result?.current_end_date);
          setGraphApiResponseData(newGraph);
          setMetricsDataAvailabilityFlag(dataAvailebleMetrics);
          setMetricDataLoader(false);
        }),
      )
      .catch(() => {
        setMetricDataLoader(false);
      });
  };

  // this function called when user come for edit report, when changed time frame and when add additional graph.
  const bindMultipleApiRequest = (
    graphdata,
    timeFrame,
    frequency,
    isAddAdditional = false,
    startDate,
    endDate,
    customTemplateFlag,
  ) => {
    const localApiRequests = [];
    setMetricDataLoader(true);

    let sd = '';
    let ed = '';
    // const localSd = new Date(startDate);
    // const localEd = new Date(endDate);
    const localSd = new Date(
      customTemplateFlag ? startDate : selectedState[0]?.startDate,
    );
    const localEd = new Date(
      customTemplateFlag ? endDate : selectedState[0]?.endDate,
    );
    sd = `${localSd.getDate()}-${
      localSd.getMonth() + 1
    }-${localSd.getFullYear()}`;
    ed = `${localEd.getDate()}-${
      localEd.getMonth() + 1
    }-${localEd.getFullYear()}`;

    const localStartDate = dayjs(new Date()).format('MMMM D, YYYY');
    let localEndDate = new Date();
    if (timeFrame === 'week') {
      localEndDate = dayjs(new Date())
        .subtract(7, 'day')
        .format('MMMM D, YYYY');
    }
    if (timeFrame === '30days') {
      localEndDate = dayjs(new Date())
        .subtract(30, 'day')
        .format('MMMM D, YYYY');
    }
    let message = `Based on the timeframe selected, inventory data displayed is
    for ${localStartDate} versus ${localEndDate}.`;

    if (timeFrame === 'custom') {
      message = `Based on the timeframe selected, inventory data displayed is
    for ${dayjs(selectedState[0].endDate).format('MMM D, YYYY')}`;
    }
    setInventoryDatesMessage(message);

    graphdata.forEach((element) => {
      if (accountType.isSeller || accountType.isHybrid) {
        // metrics data api
        localApiRequests.push(
          getSalesSponsoredDspMetricsData(
            customerId,
            selectedMarketplace.value,
            timeFrame,
            sd,
            ed,
            'seller',
            frequency !== null
              ? frequency
              : element.sellerDetails.graphFrequency,
            // element?.sellerDetails?.sponsoredType?.value,
          ),
        );
        // parent top
        if (element.sellerDetails.isParentTopAsins) {
          localApiRequests.push(
            getCutomReportASINPerformanceData(
              customerId,
              selectedMarketplace.value,
              'parent_asin',
              '-current_revenue',
              timeFrame,
              sd,
              ed,
              element.sellerDetails.parentTopAsins,
            ),
          );
        }

        // parent bottom
        if (element.sellerDetails.isParentBottomAsins) {
          localApiRequests.push(
            getCutomReportASINPerformanceData(
              customerId,
              selectedMarketplace.value,
              'parent_asin',
              'current_revenue',
              timeFrame,
              sd,
              ed,
              element.sellerDetails.parentBottomAsins,
            ),
          );
        }

        // parent all
        if (element.sellerDetails.isParentAllAsins) {
          localApiRequests.push(
            getCutomReportASINPerformanceData(
              customerId,
              selectedMarketplace.value,
              'parent_asin',
              'current_revenue',
              timeFrame,
              sd,
              ed,
              null,
            ),
          );
        }

        // child top
        if (element.sellerDetails.isChildTopAsins) {
          localApiRequests.push(
            getCutomReportASINPerformanceData(
              customerId,
              selectedMarketplace.value,
              'child_asin',
              '-current_revenue',
              timeFrame,
              sd,
              ed,
              element.sellerDetails.childTopAsins,
            ),
          );
        }

        // child bottom
        if (element.sellerDetails.isChildBottomAsins) {
          localApiRequests.push(
            getCutomReportASINPerformanceData(
              customerId,
              selectedMarketplace.value,
              'child_asin',
              'current_revenue',
              timeFrame,
              sd,
              ed,
              element.sellerDetails.childBottomAsins,
            ),
          );
        }

        // child all
        if (element.sellerDetails.isChildAllAsins) {
          localApiRequests.push(
            getCutomReportASINPerformanceData(
              customerId,
              selectedMarketplace.value,
              'child_asin',
              'current_revenue',
              timeFrame,
              sd,
              ed,
              null,
            ),
          );
        }

        // bbep graph
        if (timeFrame === 'custom') {
          localApiRequests.push(
            getBuyBoxChartData(
              customerId,
              selectedMarketplace.value,
              timeFrame,
              'daily',
              null,
              sd,
              ed,
            ),
          );
        } else {
          localApiRequests.push(
            getBuyBoxChartData(
              customerId,
              selectedMarketplace.value,
              timeFrame,
              'daily',
              null,
            ),
          );
        }

        // stranded inventory api call
        localApiRequests.push(
          getStrandedInventoryData(
            customerId,
            timeFrame,
            selectedMarketplace.value,
            localEd,
            'noPage',
            'stranded_event_date',
            true,
          ),
        );

        // understock inventory api call
        localApiRequests.push(
          getRestockInventoryData(
            customerId,
            selectedMarketplace.value,
            'noPage',
            'recommended_ship_date',
            true,
            'understocked',
          ),
        );

        // understock inventory api call
        localApiRequests.push(
          getRestockInventoryData(
            customerId,
            selectedMarketplace.value,
            'noPage',
            'sales_last_30_days',
            true,
            'overstocked',
          ),
        );
      }

      if (accountType.isVendor || accountType.isHybrid) {
        localApiRequests.push(
          getSalesSponsoredDspMetricsData(
            customerId,
            selectedMarketplace.value,
            timeFrame,
            sd,
            ed,
            'vendor',
            frequency !== null
              ? frequency
              : element.vendorDetails.graphFrequency,
            // element?.vendorDetails?.sponsoredType?.value,
          ),
        );

        if (accountType.isVendor) {
          // stranded inventory api call
          localApiRequests.push(
            getStrandedInventoryData(
              customerId,
              timeFrame,
              selectedMarketplace.value,
              localEd,
              'noPage',
              'stranded_event_date',
              true,
            ),
          );

          // understock inventory api call
          localApiRequests.push(
            getRestockInventoryData(
              customerId,
              selectedMarketplace.value,
              'noPage',
              'recommended_ship_date',
              true,
              'understocked',
            ),
          );

          // understock inventory api call
          localApiRequests.push(
            getRestockInventoryData(
              customerId,
              selectedMarketplace.value,
              'noPage',
              'sales_last_30_days',
              true,
              'overstocked',
            ),
          );
        }
      }
    });

    saveApiRequests(localApiRequests, graphdata, isAddAdditional, timeFrame);
  };

  // check the status of report
  const checkReportStatus = () => {
    setMetricDataLoader(true);
    checkReportStatusToEdit(customReportId).then((res) => {
      if (mounted.current) {
        if (res?.status === 200) {
          if (
            res?.data.status === 'ready'
            // res?.data?.current_user?.id === userInfo?.id  // restrict same user to edit the same report
          ) {
            updateReportStatus('inprogress'); // api call to change the report status
            setReportStatus('edit');
            bindMultipleApiRequest(graphDetails, selectedTimeFrame.value, null);
            setIsTimeFrameDisabled(false);
          } else if (res?.data.status === 'inprogress') {
            const currenUser = `${res?.data?.current_user?.first_name} ${res?.data?.current_user?.last_name}`;
            setCurrentPDFUser(currenUser);
            setReportPDFStatusModal(true);
            setMetricDataLoader(false);
          }
        }
      }
    });
  };

  const handleGraphCollapse = (indexNumber, flag) => {
    const newGraph = [...graphDetails];
    newGraph.forEach((_value, index) => {
      newGraph[index].isOpen = flag;
      // if collape graph, other will closed
      if (indexNumber === index) {
        newGraph[index].isOpen = flag;
      } else {
        newGraph[index].isOpen = false;
      }
    });
    return newGraph;
  };

  // when user add the new graph this function get called
  const handleAddAdditonalGraph = () => {
    let newGraphObject = {};
    let newEditorObject = {};
    if (accountType.isSeller) {
      newGraphObject = {
        isOpen: true,
        sellerDetails,
        vendorDetails: null,
      };
      newEditorObject = {
        sellerDetails: sellerDetailsEditor,
        vendorDetails: null,
      };

      [newGraphObject.sellerDetails.graphFrequency] = enabledGroupFrequencyList;
    } else if (accountType.isVendor) {
      newGraphObject = {
        isOpen: true,
        sellerDetails: null,
        vendorDetails,
      };
      newEditorObject = {
        sellerDetails: null,
        vendorDetails: vendorDetailsEditor,
      };
      [newGraphObject.vendorDetails.graphFrequency] = enabledGroupFrequencyList;
    } else if (accountType.isHybrid) {
      newGraphObject = {
        isOpen: true,
        sellerDetails,
        vendorDetails,
      };
      newEditorObject = {
        sellerDetails: sellerDetailsEditor,
        vendorDetails: vendorDetailsEditor,
      };
      [newGraphObject.sellerDetails.graphFrequency] = enabledGroupFrequencyList;
      [newGraphObject.vendorDetails.graphFrequency] = enabledGroupFrequencyList;
    }

    bindMultipleApiRequest(
      [newGraphObject],
      selectedTimeFrame.value,
      null,
      true,
    );

    // after adding new graph, if newly added graph should be open and other will close
    const existingObject = handleGraphCollapse(0, false);
    setGraphDetails([...existingObject, newGraphObject]);
    setEditorDetails([...editorDetails, newEditorObject]);
  };

  // PDV- 8212- we are removed the sponsored brand video option from sponsonred ad type,
  // so, for existing report if amy metrics is selected of tht type, then removed that options from selected metrics object.
  const removeSBVtypeMetrics = (data, Acctype) => {
    const sponsoredBrnadVideoMetricsKeys = [
      'adSalesSBV',
      'adSpendSBV',
      'adConversionRateSBV',
      'adImpressionsSBV',
      'adAcosSBV',
      'adRoasSBV',
      'adCostPerClickSBV',
      'adClicksSBV',
      'adClickThroughRateSBV',
    ];

    let selectedMetrics = {};
    let selectedMetricsKeys = [];

    if (Acctype === 'seller') {
      selectedMetrics = data.seller_detail.selectedMetrics;
      selectedMetricsKeys = Object.keys(data?.seller_detail.selectedMetrics);
    } else if (Acctype === 'vendor') {
      selectedMetrics = data.vendor_detail.selectedMetrics;
      selectedMetricsKeys = Object.keys(data?.vendor_detail.selectedMetrics);
    }

    if (selectedMetricsKeys.length) {
      selectedMetricsKeys.filter((keyName) => {
        if (sponsoredBrnadVideoMetricsKeys.includes(keyName)) {
          selectedMetrics = _.omit(selectedMetrics, [keyName]);
        }
        return null;
      });
    }

    return selectedMetrics;
  };

  // get the graph selected fields API function
  const getGraphSelectedFields = useCallback((reptId) => {
    setGetSelectedFieldLoader(true);
    getReportGraphSelectedFileds(reptId).then((res) => {
      if (mounted.current) {
        if (res?.status === 400) {
          setGetSelectedFieldLoader(false);
        }
        if (res?.status === 200) {
          setReportSummary(res?.data?.summary);
          setSelectedTimeFrame(setTimeFrame[res?.data?.timeframe]);
          setReportDescription(res?.data?.description);
          setReportTitle(res?.data?.name);
          setAccountType({
            isSeller: res?.data?.account_type === 'Seller',
            isVendor: res?.data?.account_type === 'Vendor',
            isHybrid: res?.data?.account_type === 'Hybrid',
            value: res?.data?.account_type,
          });

          setSelectedMarketplace({
            label: res?.data?.marketplace_detail.country,
            value: res?.data?.marketplace,
          });

          setMarketplaceCurrency(
            getSymbolFromCurrency(res?.data?.marketplace_detail?.currency),
          );

          const localGraphDetails = [];
          const localEditorDetails = [];
          const groupByOptions = checkIsGroupFrequencyDisabled(
            res?.data?.timeframe,
          );
          setEnabledGroupFrequencyList(groupByOptions?.enabledList);

          // bind the seller and vendor selected fields
          res.data.graph_details.forEach((element, index) => {
            localGraphDetails.push({
              isOpen: index === 0,
              sellerDetails: element?.seller_detail,
              vendorDetails: element?.vendor_detail,
            });

            if (res?.data?.account_type === 'Seller') {
              localEditorDetails.push({
                sellerDetails: {
                  graphTitle: element?.seller_detail?.graphTitle,
                  metricMessage: element?.seller_detail?.metricMessage,
                  parentAsinMessage: element?.seller_detail?.parentAsinMessage,
                  childAsinMessage: element?.seller_detail?.childAsinMessage,
                  sellerHeathMessge: element?.seller_detail?.sellerHeathMessge,
                  inventoryManagementMessage:
                    element?.seller_detail?.inventoryManagementMessage,
                },
                vendorDetails: null,
              });

              localGraphDetails[index].sellerDetails.selectedMetrics =
                removeSBVtypeMetrics(element, 'seller');

              if (
                ['SBV', 'SB'].includes(
                  element?.seller_detail?.sponsoredType?.key,
                )
              ) {
                localGraphDetails[index].sellerDetails.sponsoredType = {
                  key: 'SB',
                  label: 'Sponsored Brand + Video',
                  value: 'brand_campaign_report',
                };
              }
            }
            if (res?.data?.account_type === 'Vendor') {
              localEditorDetails.push({
                sellerDetails: null,
                vendorDetails: {
                  graphTitle: element?.vendor_detail?.graphTitle,
                  metricMessage: element?.vendor_detail?.metricMessage,
                  inventoryManagementMessage:
                    element?.vendor_detail?.inventoryManagementMessage,
                },
              });
              localGraphDetails[index].vendorDetail.selectedMetrics =
                removeSBVtypeMetrics(element, 'vendor');

              if (
                ['SBV', 'SB'].includes(
                  element?.vendor_detail?.sponsoredType?.key,
                )
              ) {
                localGraphDetails[index].vendorDetails.sponsoredType = {
                  key: 'SB',
                  label: 'Sponsored Brand + Video',
                  value: 'brand_campaign_report',
                };
              }
            }
            if (res?.data?.account_type === 'Hybrid') {
              localEditorDetails.push({
                sellerDetails: {
                  graphTitle: element?.seller_detail?.graphTitle,
                  metricMessage: element?.seller_detail?.metricMessage,
                  parentAsinMessage: element?.seller_detail?.parentAsinMessage,
                  childAsinMessage: element?.seller_detail?.childAsinMessage,
                  sellerHeathMessge: element?.seller_detail?.sellerHeathMessge,
                  inventoryManagementMessage:
                    element?.seller_detail?.inventoryManagementMessage,
                },
                vendorDetails: {
                  graphTitle: element?.vendor_detail?.graphTitle,
                  metricMessage: element?.vendor_detail?.metricMessage,
                  inventoryManagementMessage:
                    element?.vendor_detail?.inventoryManagementMessage,
                },
              });
              localGraphDetails[index].sellerDetails.selectedMetrics =
                removeSBVtypeMetrics(element, 'seller');
              localGraphDetails[index].vendorDetails.selectedMetrics =
                removeSBVtypeMetrics(element, 'vendor');
              if (
                ['SBV', 'SB'].includes(
                  element?.seller_detail?.sponsoredType?.key,
                )
              ) {
                localGraphDetails[index].sellerDetails.sponsoredType = {
                  key: 'SB',
                  label: 'Sponsored Brand + Video',
                  value: 'brand_campaign_report',
                };
              }
              if (
                ['SBV', 'SB'].includes(
                  element?.vendor_detail?.sponsoredType?.key,
                )
              ) {
                localGraphDetails[index].vendorDetails.sponsoredType = {
                  key: 'SB',
                  label: 'Sponsored Brand + Video',
                  value: 'brand_campaign_report',
                };
              }
            }
          });

          setGraphDetails(localGraphDetails);
          setEditorDetails(localEditorDetails);
          if (res?.data?.timeframe === 'custom') {
            setSelectedState([
              {
                startDate: new Date(res?.data?.start_date),
                endDate: new Date(res?.data?.end_date),
                key: 'reportSelection',
              },
            ]);
          }

          const apiDate = handleTimezoneOffset(
            res?.data?.start_date,
            res?.data?.end_date,
          );
          setDateToShow({
            startDate: new Date(apiDate?.tempStartDate),
            endDate: new Date(apiDate?.tempEndDate),
          });
          setGetSelectedFieldLoader(false);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get the template fileds
  const getGraphSelectedTemplateFields = useCallback((templateId) => {
    setGetSelectedFieldLoader(true);
    getReportTemplate(templateId).then((res) => {
      if (mounted.current) {
        if (res?.status === 400) {
          setGetSelectedFieldLoader(false);
        }
        if (res?.status === 200) {
          setReportSummary(res?.data?.summary);
          setSelectedTimeFrame(setTimeFrame[res?.data?.timeframe]);
          setTemplateTimeFrame(setTimeFrame[res?.data?.timeframe]);
          setReportDescription(res?.data?.description);

          if (res?.data?.name !== '' && res?.data?.name !== null) {
            const reportName = res?.data?.name.split('(')[0];
            setReportTitle(reportName);
            setTemplateReportTitle(reportName);
          }
          setTemplateReportDescrition(res?.data?.description);

          const localGraphDetails = [];
          const localEditorDetails = [];
          const groupByOptions = checkIsGroupFrequencyDisabled(
            res?.data?.timeframe,
          );
          setEnabledGroupFrequencyList(groupByOptions?.enabledList);

          // bind the seller and vendor selected fields
          res.data.graph_details.forEach((element, index) => {
            localGraphDetails.push({
              isOpen: index === 0,
              sellerDetails: element?.seller_detail,
              vendorDetails: element?.vendor_detail,
            });
            if (res?.data?.account_type === 'seller') {
              localEditorDetails.push({
                sellerDetails: {
                  graphTitle: element?.seller_detail?.graphTitle,
                  metricMessage: element?.seller_detail?.metricMessage,
                  parentAsinMessage: element?.seller_detail?.parentAsinMessage,
                  childAsinMessage: element?.seller_detail?.childAsinMessage,
                  sellerHeathMessge: element?.seller_detail?.sellerHeathMessge,
                  inventoryManagementMessage:
                    element?.seller_detail?.inventoryManagementMessage,
                },
                vendorDetails: null,
              });
            }
            if (res?.data?.account_type === 'vendor') {
              localEditorDetails.push({
                sellerDetails: null,
                vendorDetails: {
                  graphTitle: element?.vendor_detail?.graphTitle,
                  metricMessage: element?.vendor_detail?.metricMessage,
                  inventoryManagementMessage:
                    element?.vendor_detail?.inventoryManagementMessage,
                },
              });
            }
            if (res?.data?.account_type === 'hybrid') {
              localEditorDetails.push({
                sellerDetails: {
                  graphTitle: element?.seller_detail?.graphTitle,
                  metricMessage: element?.seller_detail?.metricMessage,
                  parentAsinMessage: element?.seller_detail?.parentAsinMessage,
                  childAsinMessage: element?.seller_detail?.childAsinMessage,
                  sellerHeathMessge: element?.seller_detail?.sellerHeathMessge,
                  inventoryManagementMessage:
                    element?.seller_detail?.inventoryManagementMessage,
                },
                vendorDetails: {
                  graphTitle: element?.vendor_detail?.graphTitle,
                  metricMessage: element?.vendor_detail?.metricMessage,
                  inventoryManagementMessage:
                    element?.vendor_detail?.inventoryManagementMessage,
                },
              });
            }
          });

          setGraphDetails(localGraphDetails);
          setEditorDetails(localEditorDetails);
          if (res?.data?.timeframe === 'custom') {
            setSelectedState([
              {
                startDate: new Date(res?.data?.start_date),
                endDate: new Date(res?.data?.end_date),
                key: 'reportSelection',
              },
            ]);
          }

          const apiDate = handleTimezoneOffset(
            res?.data?.start_date,
            res?.data?.end_date,
          );

          setDateToShow({
            startDate: new Date(apiDate?.tempStartDate),
            endDate: new Date(apiDate?.tempEndDate),
          });

          bindMultipleApiRequest(
            localGraphDetails,
            res?.data?.timeframe,
            null,
            false,
            res?.data?.start_date,
            res?.data?.end_date,
            true,
          );
          setGetSelectedFieldLoader(false);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (
      mounted.current &&
      selectedReportTemplate !== null &&
      reportStatus === 'new'
    ) {
      getGraphSelectedTemplateFields(selectedReportTemplate);
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGraphSelectedTemplateFields, selectedReportTemplate]);

  useEffect(() => {
    mounted.current = true;
    if (
      mounted.current &&
      reportStatus === 'readyToEdit' &&
      customReportId !== null
    ) {
      getGraphSelectedFields(customReportId);
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGraphSelectedFields, customReportId]);

  const dispalyTimeCounter = () => {
    const splitedTimer = countDownTimer.split(':');
    return (
      <TimmerOut className={reportStatus === 'readyToEdit' ? 'disabled' : ''}>
        <div className="label text-center"> TIME REMAINING</div>
        <div className="timmer-block">
          <span className="pr-md-2"> {splitedTimer[0]}</span> HOUR{' '}
          <span className="px-md-3">:</span>
          <span className="px-md-2">{splitedTimer[1]}</span>MINUTES{' '}
          <span className="px-md-3">:</span>
          <span className="px-md-2">{splitedTimer[2]}</span>SECONDS
        </div>
      </TimmerOut>
    );
  };

  // handle confirm close report modal events
  const handleConfirmCloseReportModalEvents = () => {
    if (reportStatus === 'new') {
      onCloseReport();
      setShowConfirmCloseReportModal(false);
    }
    if (reportStatus === 'edit') {
      changeReportStatus(customReportId, 'ready').then((res) => {
        if (res?.status === 200) {
          onCloseReport();
          clearInterval(counterRef.current);
          setShowConfirmCloseReportModal(false);
        }
      });
    }
  };
  // handle the colse report events
  const handleCloseReportEvents = () => {
    if (reportStatus === 'edit' || reportStatus === 'new') {
      setShowConfirmCloseReportModal(true);
    } else {
      onCloseReport();
    }
  };

  // rende report header
  const renderHeader = () => {
    return (
      <HeaderDownloadFuntionality>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-3 col-lg-3">Report Management</div>
            <div className="col-12 col-md-6 col-lg-6">
              {reportStatus === 'edit' || reportStatus === 'readyToEdit'
                ? dispalyTimeCounter()
                : null}
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 pl-0">
              <ul className="contract-download-nav ">
                <li
                  role="presentation"
                  onClick={handleDownload}
                  className={`download-pdf ${
                    reportStatus === 'readyToEdit' || reportStatus === 'view'
                      ? ''
                      : 'disabled'
                  }`}
                >
                  {downloader ? (
                    <span className="download-pdf-link">
                      <PageLoader
                        color={Theme.orange}
                        component="table-download-btn"
                        type="button"
                        width={20}
                        height={20}
                      />
                    </span>
                  ) : (
                    <>
                      <span className="download-pdf-link">
                        <img
                          src={OrangeDownloadPdf}
                          alt="download"
                          className="download-pdf-icon "
                          role="presentation"
                        />
                        Download Report
                      </span>
                    </>
                  )}
                </li>
                <li>
                  <span className="divide-arrow hide-mobile" />
                </li>
                <li
                  role="presentation"
                  onClick={() => {
                    handleCloseReportEvents();
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa-light fa-times"
                    style={{ color: '#000', fontSize: '20px' }}
                    className=" cursor remove-cross-icon"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </HeaderDownloadFuntionality>
    );
  };

  // render report pdf status modal
  const renderReportPDFStatusModal = () => {
    return (
      <ReportPDFStatusModal
        isModalOpen={reportPDFstatusModal}
        currentPDFUser={currentPDFUser}
        afterModalCloseAPI={() => {
          setReportPDFStatusModal(false);
        }}
      />
    );
  };

  // render confirm close report modal
  const renderConfirmCloseReportModal = () => {
    return (
      <ConfirmCloseReportModal
        isModalOpen={showConfirmCloseReportModal}
        modalConfirmButtonEvents={() => {
          handleConfirmCloseReportModalEvents();
        }}
        afterModalCloseAPI={() => {
          setShowConfirmCloseReportModal(false);
        }}
      />
    );
  };

  return (
    <div>
      {renderHeader()}
      <>
        <ContractTab className="d-lg-none d-block p-0">
          <ul style={{ textAlign: 'center' }} className="tabs">
            <li
              role="presentation"
              className={showTabResponsive === 'view-report' ? 'active' : ''}
              style={nonDraftCustomStyles}
              onClick={() => setShowTabResponsive('view-report')}
            >
              View Report
            </li>
            <li
              className={
                showTabResponsive === 'edit-report'
                  ? 'active view-contract'
                  : 'view-contract'
              }
              role="presentation"
              style={nonDraftCustomStyles}
              onClick={() => setShowTabResponsive('edit-report')}
            >
              Edit Fields
            </li>
          </ul>
        </ContractTab>

        <div>
          {isDesktop || showTabResponsive === 'view-report' ? (
            getSelectedFieldLoader || metricDataLoader ? (
              <PageLoader
                component="custom-report-main-loader"
                color={Theme.orange}
                type="page"
                width={40}
                height={40}
              />
            ) : (
              <ReportLeftSidePanel
                graphDetails={graphDetails}
                setGraphDetails={setGraphDetails}
                commanFieldsDetails={{
                  accountType,
                  selectedTimeFrame,
                  reportPresignedUrl,
                  marketplaceCurrency,
                  selectedState,
                  inventoryDatesMessage,
                  selectedMarketplace: selectedMarketplace.label,
                  editorDetails,
                  setEditorDetails,
                }}
                graphApiResponseData={graphApiResponseData}
                reportStatus={reportStatus}
                customerName={customerName}
                dateToShow={dateToShow}
                reportSummary={reportSummary}
                html2pdfRef={html2pdfRef}
                currentEndDate={currentEndDate}
                showReportPDFTemplate={showReportPDFTemplate}
              />
            )
          ) : null}
          {isDesktop || showTabResponsive === 'edit-report' ? (
            <ReportRightSidePanel
              graphDetails={graphDetails}
              setGraphDetails={setGraphDetails}
              selectedState={selectedState}
              dateToShow={dateToShow}
              setSelectedState={setSelectedState}
              currentDate={currentDate}
              isTimeFrameDisabled={isTimeFrameDisabled}
              setIsTimeFrameDisabled={setIsTimeFrameDisabled}
              commanFieldsDetails={{
                accountType,
                accType: accountType?.value,
                selectedMarketplace: selectedMarketplace.label,
                selectedTimeFrame,
                setSelectedTimeFrame,
                customReportId,
                saveDetailsLoader,
                reportDescription,
                setReportDescription,
                reportTitle,
                setReportTitle,
                saveReportDetails,
                enabledGroupFrequencyList,
                setEnabledGroupFrequencyList,
                checkIsGroupFrequencyDisabled,
                handleAddAdditonalGraph,
                bindMultipleApiRequest,
                showSaveReportModal,
                setShowSaveReportModal,
                getMetricsReportData,
                metricsDataAvailabilityFlag,
                getSalesAsinsReportData,
                graphApiResponseData,
                setGraphApiResponseData,
                asinDataCount,
                metricDataLoader,
                handleGraphCollapse,
                setAutoScrollId,
                isRemainderUp,
                countDownTimer,
                checkReportStatus,
                editorDetails,
              }}
              reportStatus={reportStatus}
              setReportStatus={setReportStatus}
              customerName={customerName}
              reportSummary={reportSummary}
              setReportSummary={setReportSummary}
              showMetricsTooltip={showMetricsTooltip}
              setShowReportPDFTemplate={setShowReportPDFTemplate}
              templateTimeFrame={templateTimeFrame}
              templateReportTitle={templateReportTitle}
              templateReportDescrition={templateReportDescrition}
            />
          ) : null}
          <ToastContainer
            position="top-center"
            className="info-msg"
            autoClose={5000}
            pauseOnFocusLoss={false}
          />
        </div>
      </>
      {reportPDFstatusModal ? renderReportPDFStatusModal() : null}
      {showConfirmCloseReportModal ? renderConfirmCloseReportModal() : null}
    </div>
  );
};

export default CreateReportContainer;

CreateReportContainer.defaultProps = {
  reportId: '',
  customReportStatus: '',
  customerId: '',
  pdfURL: '',
  customerName: '',
  selectedReportTemplate: '',
  marketplace: null,
  onCloseReport: () => {},
};

CreateReportContainer.propTypes = {
  reportId: string,
  customerId: string,
  customReportStatus: string,
  pdfURL: string,
  customerName: string,
  selectedReportTemplate: string,
  accType: string.isRequired,
  marketplace: shape({}),
  onCloseReport: func,
};

const TimmerOut = styled.div`
  margin: 0 auto;
  .timmer-block {
    border-radius: 4px;
    background: ${Theme.lightOrange};
    color: ${Theme.orange};
    padding: 4px 16px;
    color: ${Theme.gray40};
    font-size: ${Theme.extraSmall};
    font-family: ${Theme.titleFontFamily};
    text-transform: uppercase;
    margin: 0 auto;
    width: fit-content;

    span {
      color: ${Theme.orange};
      font-size: ${Theme.secondaryTitleFontSize};
      vertical-align: sub;
    }
  }
  @media only screen and (max-width: 768px) {
    .timmer-block {
      font-size: ${Theme.textSmall};

      span {
        color: ${Theme.orange};
        font-size: ${Theme.title};
      }
    }
  }
`;
