// react imports
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

// third party imports
import $ from 'jquery';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// local file imports
import SponsoredFilter from './SponsoredFilter';
import BindSponsoredAdData from './BindSponsoredAdData';
import {
  SponsoredDashboardContainerDefaultProps,
  SponsoredDashboardContainerPropTypes,
} from './SponsoredDashboardPropTypes';
import { CustomDateModal } from '../../../../common';
import SponsoredRightHandSideSection from './SponsoredRightHandSideSection';
import {
  getManagersList,
  getBgsUserList,
  getAdManagerAdminGraphData,
  getKeyContributionData,
  getMinDateForDashboard,
  getLeadershipBrandPartners,
  getKeyContributorsdownloadReport,
} from '../../../../api';
import {
  sponsoredAdTypeOptions,
  performanceTypeOptions,
  sponoredMetricsList,
  VENDORSMALL,
  CUSTOM,
  SELLERSMALL,
  ADSALES,
  PERFORMANCE,
  YEAROVERYEAR,
  ADROAS,
  ADCOS,
  SALESPERCLICK,
  COSTPERCLICK,
  allValueObj,
  VENDOR,
  HYBRID,
} from '../../../../constants';

// third party variables
const getSymbolFromCurrency = require('currency-symbol-map');
const _ = require('lodash');

export default function SponsoredDashboardContainer({
  // string
  responseID,
  currencySymbol,
  // boolean
  isBGS,
  isBGSAdmin,
  isSpreetailRole,
  isBGSManager,
  isAdManager,
  isAdTeamManager,
  isAdManagerAdmin,
  isCustomDateApply,
  includeInactiveBPs,
  includeSpreetailBrands,
  // object
  userInfo,
  customDate,
  selectedAdDF,
  selectedMarketplace,
  selectedBrandPartner,
  selectedPerformanceType,
  // array
  loggedInUser,
  adState,
  selectedUser,
  selectedManagerUser,
  marketplaceChoices,
  sponsoredAdMultiSelected,
  // function
  setSelectedBrandPartner,
  bindBrandPartners,
  setCustomDate,
  setAdState,
  setSelectedAdDF,
  setSelectedUser,
  setCurrencySymbol,
  setSelectedManagerUser,
  setIsCustomDateApply,
  setSelectedMarketplace,
  checkIsUserExist,
  bindUserListData,
  generateStringOfValues,
  setSelectedPerformanceType,
  displayContributionTable,
  bindMetricsContributionData,
  renderRecentAndPreviousLabel,
  setSponsoredAdMultiSelected,
  setResponseID,
  handleMultiSelectDropdownFilterEvents,
}) {
  const mounted = useRef(false);
  const [adChartData, setAdChartData] = useState([]);
  const [adCurrentTotal, setAdCurrentTotal] = useState({});
  const [adPreviousTotal, setAdPreviousTotal] = useState({});
  const [adDifference, setAdDifference] = useState({});
  const [selectedAdMetrics, setSelectedAdMetrics] = useState({ adSales: true });
  const [selectedAdType, setSelectedAdType] = useState(sponsoredAdTypeOptions);
  const [isAdTypeSelected, setIsAdTypeSelected] = useState(false);
  const [downloadReportLoader, setDownloadReportLoader] = useState(false);

  // use state for bgs list, bgs manager list, ad manager list, ad team manager list,s
  const [userList, setUserList] = useState([]);
  const [userApiList, setUserApiList] = useState([]);
  const [managerUserList, setManagerUserList] = useState([]);
  const [managerUserApiList, setManagerUserApiList] = useState([]);
  const [isUsersSelected, setIsUsersSelected] = useState(false);
  const [isManagerUsersSelected, setIsManagerUsersSelected] = useState(false);
  const [brandPartnerOptions, setBrandPartnerOptions] = useState([]);

  const [responseId, setResponseId] = useState(null);
  const [adGraphLoader, setAdGraphLoader] = useState(false);
  const [selectedTabMetrics, setSelectedTabMetrics] = useState(ADSALES);
  const [keyContributionLoader, setKeyContributionLoader] = useState(false);
  const [contributionData, setContributionData] = useState([]);
  const [adGroupBy, setAdGroupBy] = useState('daily');
  const [pageNumber, setPageNumber] = useState();
  const [contributionCount, setContributionCount] = useState(null);
  const [customDateError, setCustomDateError] = useState(null);
  const [isManagerAPICalled, setIsManagerAPICalled] = useState(
    !(isAdManagerAdmin || isBGSAdmin || isSpreetailRole),
  );
  const [isBGSAPICalled, setIsBGSAPICalled] = useState(
    !(isBGSAdmin || isBGSManager || isSpreetailRole),
  );
  const [marketplaceOptions, setMarketplaceOptions] =
    useState(marketplaceChoices);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 2);
  const [showAdCustomDateModal, setShowAdCustomDateModal] = useState(false);

  // all brand partner object
  const allBrandPartnerObj = {
    label: 'All Brand Partners',
    value: 'all',
    accountType: HYBRID,
    marketplaces: marketplaceChoices,
  };

  // default all value array of object
  const allObj = useMemo(() => {
    return allValueObj;
  }, []);

  // use effect for set marketplace options
  useEffect(() => {
    setMarketplaceOptions(marketplaceChoices);
  }, [marketplaceChoices]);

  // callback functions for get brand partners list
  const getLeadershipBrandPartnersList = useCallback(
    (callType, managersUser, bgsUser, selectedBp) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);

      getLeadershipBrandPartners(
        userInfo?.role,
        'sponsored_ad_dashboard',
        managerUserString,
        bgsUserString,
        includeInactiveBPs,
        includeSpreetailBrands,
      ).then((response) => {
        if (response?.status === 200 && response?.data?.length) {
          const bpList = bindBrandPartners(response?.data);

          setBrandPartnerOptions(bpList);
          const brandPartner =
            callType === 'useEffect' ? selectedBp : bpList?.[0];
          setSelectedBrandPartner(brandPartner);

          if (bpList?.length === 1 && brandPartner?.accountType === VENDOR) {
            setSelectedPerformanceType(performanceTypeOptions?.[1]);
          } else {
            setSelectedPerformanceType(performanceTypeOptions?.[0]);
          }
        } else {
          setBrandPartnerOptions([]);
          setSelectedBrandPartner({});
          setSelectedPerformanceType({});
        }
      });
    },
    [
      bindBrandPartners,
      generateStringOfValues,
      includeInactiveBPs,
      includeSpreetailBrands,
      setSelectedBrandPartner,
      setSelectedPerformanceType,
      userInfo,
    ],
  );

  // get user list - bgs and ad manager
  const getBgsList = useCallback(
    (role, managers, users, callType, selectedDailyFact) => {
      setAdGraphLoader(true);
      setKeyContributionLoader(selectedDailyFact !== CUSTOM);

      const managersString = generateStringOfValues(managers);

      let API = '';
      if (role === 'BGS') {
        API = getBgsUserList(role, managersString);
      }
      if (role === 'Ad Manager') {
        API = getManagersList(role, 'sales_performance', managersString);
      }

      API.then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const formatedApiData = bindUserListData(response?.data, role);
            setUserList(formatedApiData?.userListWithAll);
            setUserApiList(formatedApiData?.userList);

            const isUserExist = checkIsUserExist(
              formatedApiData?.userList,
              users,
            );

            if (callType !== 'bgsFilter') {
              if (
                isUserExist &&
                callType !== 'resetFilter' &&
                callType !== 'managerUserFilter'
              ) {
                setSelectedUser(users);
              } else {
                setSelectedUser(formatedApiData?.userListWithAll);
              }
            }

            setIsBGSAPICalled(true);
          } else {
            setUserList([]);
            setSelectedUser([]);
            setUserApiList([]);
          }
        }
      });
    },

    [
      bindUserListData,
      checkIsUserExist,
      generateStringOfValues,
      setSelectedUser,
    ],
  );

  // get manager user list - bgs manager and ad team manager
  const getManagerUserList = useCallback(
    (role, localManagerUsers, localUsers, callType, selectedDailyFact) => {
      setAdGraphLoader(true);
      setKeyContributionLoader(selectedDailyFact !== CUSTOM);

      let API = '';
      if (role === 'BGS Manager') {
        API = getBgsUserList(role);
      }
      if (role === 'Ad Team Manager') {
        API = getManagersList(role, 'sales_performance');
      }

      API.then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const formatedApiData = bindUserListData(response?.data);
            setManagerUserApiList(formatedApiData?.userList);
            setManagerUserList(formatedApiData?.userListWithAll);

            const isManagerUserExist = checkIsUserExist(
              formatedApiData?.userList,
              localManagerUsers,
            );
            let managerUser = null;

            if (
              (isBGSAdmin || isAdManagerAdmin || isSpreetailRole) &&
              isManagerUserExist &&
              callType !== 'resetFilter'
            ) {
              setSelectedManagerUser(localManagerUsers);
              managerUser = localManagerUsers;
            } else {
              setSelectedManagerUser(formatedApiData?.userListWithAll);
              managerUser =
                formatedApiData?.userList?.length > 1
                  ? null
                  : formatedApiData?.userList;
            }
            if (isBGSAdmin || isSpreetailRole) {
              getBgsList(
                'BGS',
                managerUser,
                localUsers,
                callType,
                selectedDailyFact,
              );
            }
            if (isAdManagerAdmin) {
              getBgsList(
                'Ad Manager',
                managerUser,
                localUsers,
                callType,
                selectedDailyFact,
              );
            }

            setIsManagerAPICalled(true);
          } else {
            setManagerUserApiList([]);
            setManagerUserList([]);
            setSelectedManagerUser([]);
          }
        }
      });
    },

    [
      bindUserListData,
      checkIsUserExist,
      getBgsList,
      isAdManagerAdmin,
      isBGSAdmin,
      isSpreetailRole,
      setSelectedManagerUser,
    ],
  );

  // calling bgs and advertising user api
  const callingBgsAndAdUserApi = useCallback(
    (callType, localManagerUsers, localUsers, selectedDailyFact) => {
      //  advertising api
      if (isAdManagerAdmin) {
        getManagerUserList(
          'Ad Team Manager',
          localManagerUsers,
          localUsers,
          callType,
          selectedDailyFact,
        );
      }

      if (isAdTeamManager) {
        getBgsList(
          'Ad Manager',
          loggedInUser,
          localUsers,
          callType,
          selectedDailyFact,
        );
      }

      // bgs api
      if (isBGSAdmin || isSpreetailRole) {
        getManagerUserList(
          'BGS Manager',
          localManagerUsers,
          localUsers,
          callType,
          selectedDailyFact,
        );
      }

      if (isBGSManager) {
        getBgsList(
          'BGS',
          loggedInUser,
          localUsers,
          callType,
          selectedDailyFact,
        );
      }
    },
    [
      getBgsList,
      getManagerUserList,
      isAdManagerAdmin,
      isAdTeamManager,
      isBGSAdmin,
      isBGSManager,
      isSpreetailRole,
      loggedInUser,
    ],
  );

  // use effect for bgs and advertising team
  useEffect(() => {
    mounted.current = true;
    callingBgsAndAdUserApi(
      'useEffect',
      selectedManagerUser,
      selectedUser,
      selectedAdDF?.value,
    );
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingBgsAndAdUserApi]);

  // bind the error message if data not available for selecetd custom date
  // API to get the data available date for sales
  const getMinDateForSponsoredDashboard = useCallback(
    (marketplace, managerUser, bgsUser, sponsoredType) => {
      const dates = [];
      const selectedUserString = generateStringOfValues(bgsUser);
      const selectedManagerUserString = generateStringOfValues(managerUser);
      const sponsoredAdTypeString = generateStringOfValues(sponsoredType);

      getMinDateForDashboard(
        marketplace,
        selectedManagerUserString,
        selectedUserString,
        sponsoredAdTypeString,
        'sponsored_dashboard',
      ).then((res) => {
        if (res?.status === 200) {
          if (res?.data?.result && res?.data?.result?.length) {
            dates.push(
              new Date(dayjs(adState[0].startDate).format('MM/DD/YYYY')),
            );
            dates.push(
              new Date(
                dayjs(res?.data?.result[0]?.sponsered_performance_date).format(
                  'MM/DD/YYYY',
                ),
              ),
            );

            // if selected  date is lower that data_availeble_date then we need to show the error message
            if (dates[0] < dates[1]) {
              setCustomDateError({
                fromDate: dates[0],
                toDate: dates[1],
              });
            } else {
              setCustomDateError(null);
            }
          }
        }
      });
    },
    [adState, generateStringOfValues],
  );

  // handle before and after sales performance api call
  const handleBeforAndAfterApiCall = useCallback((key) => {
    if (key === PERFORMANCE) {
      setAdChartData([]);
      setAdPreviousTotal({});
      setAdCurrentTotal({});
      setAdDifference({});
    }
  }, []);

  // callback function for getting sponsored performance data
  const getAdData = useCallback(
    (
      adType,
      selectedDailyFact,
      selectedGroupBy,
      brandPratner,
      marketplace,
      performanceType,
      managersUser,
      bgsUser,
      startDate = null,
      endDate = null,
      inactiveBPs,
      spreetailBrands = includeSpreetailBrands,
    ) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);
      const sponsoredAdTypeString = generateStringOfValues(adType);

      setAdGraphLoader(true);
      setIsUsersSelected(false);
      setIsManagerUsersSelected(false);
      setIsAdTypeSelected(false);

      getAdManagerAdminGraphData(
        'sponsored-dashboard',
        sponsoredAdTypeString,
        selectedDailyFact,
        selectedGroupBy,
        brandPratner,
        marketplace,
        performanceType,
        managerUserString,
        bgsUserString,
        isBGSAdmin || isSpreetailRole,
        isBGSManager,
        isBGS,
        isAdManagerAdmin,
        isAdTeamManager,
        isAdManager,
        startDate,
        endDate,
        inactiveBPs || includeInactiveBPs,
        spreetailBrands || includeSpreetailBrands,
      ).then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            if (response?.data?.result) {
              const adGraphData = BindSponsoredAdData(response?.data?.result);
              setAdChartData(adGraphData);
              setAdCurrentTotal(response?.data?.result?.current_sum);
              setAdPreviousTotal(response?.data?.result?.previous_sum);
              setAdDifference(response?.data?.result?.difference_data);
            } else {
              handleBeforAndAfterApiCall(PERFORMANCE);
            }
            setAdGraphLoader(false);
          } else {
            setAdGraphLoader(false);
            handleBeforAndAfterApiCall(PERFORMANCE);
          }
        }
      });
    },
    [
      generateStringOfValues,
      isBGSAdmin,
      isSpreetailRole,
      isBGSManager,
      isBGS,
      isAdManagerAdmin,
      isAdTeamManager,
      isAdManager,
      includeInactiveBPs,
      includeSpreetailBrands,
      handleBeforAndAfterApiCall,
    ],
  );

  // callback function for getting sponsored key contribution data
  const getContributionData = useCallback(
    (
      adType,
      selectedDailyFact,
      brandPartner,
      marketplace,
      performanceType,
      managersUser,
      bgsUser,
      selectedMetrics,
      startDate = null,
      endDate = null,
      page,
      inactiveBPs,
      spreetailBrands = includeSpreetailBrands,
    ) => {
      const bgsUserString = generateStringOfValues(bgsUser);
      const managerUserString = generateStringOfValues(managersUser);
      const sponsoredAdTypeString = generateStringOfValues(adType);

      setKeyContributionLoader(true);
      setIsUsersSelected(false);
      setIsManagerUsersSelected(false);
      setIsAdTypeSelected(false);

      getKeyContributionData(
        'sponsored_ad_dashboard',
        sponsoredAdTypeString,
        selectedDailyFact,
        brandPartner,
        marketplace,
        performanceType,
        managerUserString,
        bgsUserString,
        isBGSAdmin || isSpreetailRole,
        isBGSManager,
        isBGS,
        isAdManagerAdmin,
        isAdTeamManager,
        isAdManager,
        selectedMetrics,
        startDate,
        endDate,
        page,
        inactiveBPs || includeInactiveBPs,
        spreetailBrands || includeSpreetailBrands,
      ).then((response) => {
        if (mounted.current) {
          if (response?.status === 200) {
            const responseData =
              response?.data?.result || response?.data?.results;
            if (responseData) {
              const formatedData = bindMetricsContributionData(responseData);
              setContributionData(formatedData);
              setContributionCount(response?.data?.count);
            } else {
              setContributionData([]);
              setPageNumber(page);
            }
            setKeyContributionLoader(false);
          } else {
            setContributionData([]);
            setPageNumber(page);
            setKeyContributionLoader(false);
          }
        }
      });
    },
    [
      generateStringOfValues,
      isBGSAdmin,
      isSpreetailRole,
      isBGSManager,
      isBGS,
      isAdManagerAdmin,
      isAdTeamManager,
      isAdManager,
      includeInactiveBPs,
      includeSpreetailBrands,
      bindMetricsContributionData,
    ],
  );

  // get metrics, data, key contribution data and dsp pacing data
  // - called only at the begining.
  useEffect(() => {
    mounted.current = true;
    if (
      (responseId === null || responseID === 'response') &&
      isManagerAPICalled &&
      isBGSAPICalled
    ) {
      const startDate = customDate?.startDate;
      const endDate = customDate?.endDate;
      const dateType = customDate?.dateType;

      // get brand partners list
      getLeadershipBrandPartnersList(
        'useEffect',
        selectedManagerUser,
        selectedUser,
        selectedBrandPartner,
      );

      getMinDateForSponsoredDashboard(
        selectedMarketplace?.value,
        selectedManagerUser,
        selectedUser,
        selectedAdType,
      );

      if (dateType === CUSTOM) {
        getAdData(
          selectedAdType,
          selectedAdDF?.value,
          adGroupBy,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedPerformanceType?.value,
          selectedManagerUser,
          selectedUser,
          startDate,
          endDate,
          includeInactiveBPs,
          includeSpreetailBrands,
        );
      }

      if (
        dateType === null &&
        selectedAdDF?.value !== 'year' &&
        selectedAdDF?.value !== YEAROVERYEAR
      ) {
        getAdData(
          selectedAdType,
          selectedAdDF?.value,
          adGroupBy,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedPerformanceType?.value,
          selectedManagerUser,
          selectedUser,
          null,
          null,
          includeInactiveBPs,
          includeSpreetailBrands,
        );
        getContributionData(
          selectedAdType,
          selectedAdDF?.value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedPerformanceType?.value,
          selectedManagerUser,
          selectedUser,
          selectedTabMetrics,
          null,
          null,
          pageNumber,
          includeInactiveBPs,
          includeSpreetailBrands,
        );
      }

      setResponseId('12345');
      setResponseID(null);
    }
    return () => {
      mounted.current = false;
    };
  }, [
    adGroupBy,
    customDate,
    getAdData,
    getContributionData,
    getMinDateForSponsoredDashboard,
    includeInactiveBPs,
    includeSpreetailBrands,
    isAdManagerAdmin,
    isBGSAPICalled,
    isBGSAdmin,
    isSpreetailRole,
    isManagerAPICalled,
    pageNumber,
    responseID,
    responseId,
    selectedAdDF,
    selectedAdType,
    selectedManagerUser,
    selectedMarketplace,
    selectedPerformanceType,
    selectedTabMetrics,
    selectedUser,
    setResponseID,
    selectedBrandPartner,
    getLeadershipBrandPartnersList,
  ]);

  // called only if the time frame is selected for "year" and "year OverYear"
  useEffect(() => {
    if (
      selectedAdDF?.value === 'year' ||
      selectedAdDF?.value === YEAROVERYEAR
    ) {
      setSelectedAdDF({
        value: 'week',
        label: 'Current 7 days',
        sub: 'vs Previous 7 days',
      });
      getAdData(
        selectedAdType,
        'week',
        adGroupBy,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedPerformanceType?.value,
        selectedManagerUser,
        selectedUser,
        null,
        null,
        includeInactiveBPs,
        includeSpreetailBrands,
      );
      getContributionData(
        selectedAdType,
        'week',
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedPerformanceType?.value,
        selectedManagerUser,
        selectedUser,
        selectedTabMetrics,
        null,
        null,
        pageNumber,
        includeInactiveBPs,
        includeSpreetailBrands,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ADYearAndCustomDateFilter = (
    startDate,
    endDate,
    dailyFactFlag,
    marketplace,
    performanceType,
    adType,
    managerUser,
    bgsUser,
    brandPartner,
  ) => {
    let sd = startDate;
    let ed = endDate;
    setAdGroupBy('daily');

    sd = `${startDate.getDate()}-${
      startDate.getMonth() + 1
    }-${startDate.getFullYear()}`;
    ed = `${endDate.getDate()}-${
      endDate.getMonth() + 1
    }-${endDate.getFullYear()}`;

    setCustomDate({
      startDate: sd,
      endDate: ed,
      dateType: dailyFactFlag,
    });

    getAdData(
      adType,
      dailyFactFlag,
      'daily',
      brandPartner,
      marketplace,
      performanceType,
      managerUser,
      bgsUser,
      sd,
      ed,
    );
  };

  // calling leadership BP and dashboard performance api when filter value changes
  const callingBPandDashboardAPI = (
    callType,
    managerUsers,
    users,
    selectedBp,
  ) => {
    let brandPartner = {};
    let marketplace = {};
    let performanceType = {};

    const managerUserString = generateStringOfValues(managerUsers);
    const userString = generateStringOfValues(users);

    getLeadershipBrandPartners(
      userInfo?.role,
      'sponsored_ad_dashboard',
      managerUserString,
      userString,
      includeInactiveBPs,
      includeSpreetailBrands,
    ).then((response) => {
      if (response?.status === 200 && response?.data?.length) {
        setPageNumber(1);

        const bpList = bindBrandPartners(response?.data);
        setBrandPartnerOptions(bpList);

        if (callType === 'bpFilter') {
          if (selectedBp?.value === 'all') {
            brandPartner = allBrandPartnerObj;
          } else {
            brandPartner = selectedBp;
          }
        } else if (bpList?.length === 1) {
          brandPartner = bpList?.[0];
        } else {
          brandPartner = allBrandPartnerObj;
        }

        marketplace = brandPartner?.marketplaces?.[0];
        setSelectedBrandPartner(brandPartner);
        setSelectedMarketplace(marketplace);
        setMarketplaceOptions(brandPartner?.marketplaces);

        // Handeling the default states based on account type of selected BP.
        if (brandPartner?.accountType === VENDOR) {
          performanceType = performanceTypeOptions?.[1];
        } else {
          performanceType = performanceTypeOptions?.[0];
        }
        setSelectedTabMetrics(ADSALES);
        setSelectedPerformanceType(performanceType);

        if (selectedAdDF?.value === CUSTOM) {
          ADYearAndCustomDateFilter(
            adState[0]?.startDate,
            adState[0]?.endDate,
            CUSTOM,
            marketplace?.value,
            performanceType?.value,
            selectedAdType,
            managerUsers,
            users,
            brandPartner?.value,
          );
        } else {
          getAdData(
            selectedAdType,
            selectedAdDF?.value,
            adGroupBy,
            brandPartner?.value,
            marketplace?.value,
            performanceType?.value,
            managerUsers,
            users,
          );
          getContributionData(
            selectedAdType,
            selectedAdDF?.value,
            brandPartner?.value,
            marketplace?.value,
            performanceType?.value,
            managerUsers,
            users,
            selectedTabMetrics,
            null,
            null,
            1,
          );
        }
      } else {
        setAdGraphLoader(false);
        setKeyContributionLoader(false);
        setIsUsersSelected(false);
        setIsManagerUsersSelected(false);
        setBrandPartnerOptions([]);
        setSelectedBrandPartner({});
        setSelectedPerformanceType({});
        setContributionData([]);
        handleBeforAndAfterApiCall(PERFORMANCE);
      }
    });
  };

  // handle seller & vendor reporting filter events
  const handleSellerVendorFilterEvents = (event) => {
    setSelectedPerformanceType(event);

    // need to reset the multi-selected drop-down and selected metrics,
    //  when we changed the account type filter to vendor- because metrics multi select functionality only for seller (PDV-9017)
    if (event?.value === VENDORSMALL) {
      setSponsoredAdMultiSelected(sponoredMetricsList.slice(0, 8));
      setSelectedAdMetrics({ adSales: true });
    }

    if (selectedAdDF.value === CUSTOM) {
      ADYearAndCustomDateFilter(
        adState[0]?.startDate,
        adState[0]?.endDate,
        CUSTOM,
        selectedMarketplace?.value,
        event?.value,
        selectedAdType,
        selectedManagerUser,
        selectedUser,
        selectedBrandPartner?.value,
      );
    } else {
      getAdData(
        selectedAdType,
        selectedAdDF?.value,
        adGroupBy,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        event?.value,
        selectedManagerUser,
        selectedUser,
      );
      getContributionData(
        selectedAdType,
        selectedAdDF?.value,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        event?.value,
        selectedManagerUser,
        selectedUser,
        selectedTabMetrics,
        null,
        null,
        1,
      );
      setPageNumber(1);
    }
  };

  // handle brand partner reporting filter events
  const handledBrandPartnerFilterEvents = (event) => {
    // Handeling the states and API calls when user select specific Brand Partner
    if (event?.value !== selectedBrandPartner?.value) {
      setSelectedBrandPartner(event);

      // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
      let assignedUser = [];
      let assignedMangerUser = [];

      if (event?.value === 'all') {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        const userApiListWithAll = [...userApiList];
        if (userApiListWithAll?.length) {
          userApiListWithAll.unshift(allObj?.[0]);
        }

        const managerUserApiListWithAll = [...managerUserApiList];
        if (managerUserApiListWithAll?.length)
          managerUserApiListWithAll.unshift(allObj?.[0]);

        assignedUser = selectedUser?.length ? selectedUser : userApiListWithAll;
        assignedMangerUser = selectedManagerUser?.length
          ? selectedManagerUser
          : managerUserApiListWithAll;

        if (selectedManagerUser?.length === 0)
          setManagerUserList(managerUserApiListWithAll);
        if (selectedUser?.length === 0) setUserList(userApiListWithAll);
      } else if (isBGSAdmin || isBGSManager || isBGS || isSpreetailRole) {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        assignedUser = event?.bgs !== null ? [event?.bgs] : [];
        assignedMangerUser =
          event?.bgsManager !== null ? [event?.bgsManager] : [];
      } else {
        // get assigned bgs manager/ad team manager, bgs/ad manager of selected BP.
        assignedUser = event?.adManager !== null ? [event?.adManager] : [];
        assignedMangerUser =
          event?.adTeamManager !== null ? [event?.adTeamManager] : [];
      }

      // set assigned bgs manager/ad team manager, bgs/ad manager of selected BP to multi-select bgs manager/ad team manager, bgs/ad manager dropdown filter.
      setSelectedManagerUser(assignedMangerUser);
      setSelectedUser(assignedUser);

      // if selected brand partner do not have assigned bgs manager/ad team manager and bgs/ad manager then clear the multiselect manager user and user dropdown filter list

      if (assignedMangerUser?.length === 0) {
        setManagerUserList([]);
      } else {
        const managerUserApiListWithAll = [...managerUserApiList];
        managerUserApiListWithAll.unshift(allObj?.[0]);
        setManagerUserList(managerUserApiListWithAll);
      }
      if (assignedUser?.length === 0) setUserList([]);

      // if selected brand partner have assigned bgs manager/ad team manager and bgs/ad manager then get its the bgs/ad manager list
      if (assignedMangerUser?.length) {
        if (isBGSAdmin || isSpreetailRole) {
          getBgsList(
            'BGS',
            assignedMangerUser,
            assignedUser,
            'BPFilter',
            selectedAdDF?.value,
          );
        }

        if (isAdManagerAdmin) {
          getBgsList(
            'Ad Manager',
            assignedMangerUser,
            assignedUser,
            'BPFilter',
            selectedAdDF?.value,
          );
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI(
        'bpFilter',
        assignedMangerUser?.length ? assignedMangerUser : allObj,
        assignedUser?.length ? assignedUser : allObj,
        event,
      );
    }
  };

  // handle Sponsored AdType Filter Events
  const handleSponsoredAdTypeFilterEvents = (event, action) => {
    const optionsWithoutAll = sponsoredAdTypeOptions?.filter(
      (option) => option?.value !== 'all',
    );

    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedAdType,
      optionsWithoutAll,
    );

    // need to reset the multi-selected drop-down and selected metrics,
    //  when we changed the account type filter to vendor- because metrics multi select functionality only for seller (PDV-9017)

    setSponsoredAdMultiSelected(sponoredMetricsList.slice(0, 8));
    setSelectedAdMetrics({ adSales: true });

    setSelectedAdType(selectedValues);
    setIsAdTypeSelected(true);
  };

  // handle marketplace filter events
  const handleMarketplace = (event) => {
    if (event.value !== selectedMarketplace?.value) {
      setSelectedMarketplace(event);
      setCurrencySymbol(getSymbolFromCurrency(event?.currency));
      let localSelectedAdType = selectedAdType;

      // PDV-8954
      // when Non-US markeplace selected then need to disabled the
      // television_campaign_report and all_ad _type option of sponsred ad type filter.
      // if television_campaign_report and all_ad _type is already selected then
      // first uncheck that option and then disabled that.
      // ---------------------start---------------------
      if (
        selectedAdType.filter(
          (ele) => ele?.value === 'television_campaign_report',
        ).length &&
        event?.value !== 'amazon.com' &&
        event?.value !== 'all'
      ) {
        handleSponsoredAdTypeFilterEvents(sponsoredAdTypeOptions.pop(), {
          action: 'deselect-option',
          option: {
            value: 'television_campaign_report',
            label: 'Sponsored Television',
            isDisabled: false,
          },
          name: 'sponsoredAdType',
        });
        localSelectedAdType = selectedAdType?.filter((option) => {
          return (
            option?.value !== 'television_campaign_report' &&
            option?.value !== 'all'
          );
        });
      }
      // ---------------------End---------------------

      if (selectedAdDF?.value === CUSTOM) {
        ADYearAndCustomDateFilter(
          adState[0]?.startDate,
          adState[0]?.endDate,
          CUSTOM,
          event?.value,
          selectedPerformanceType?.value,
          localSelectedAdType,
          selectedManagerUser,
          selectedUser,
          selectedBrandPartner?.value,
        );
      } else {
        getAdData(
          localSelectedAdType,
          selectedAdDF?.value,
          adGroupBy,
          selectedBrandPartner?.value,
          event?.value,
          selectedPerformanceType?.value,
          selectedManagerUser,
          selectedUser,
        );
        getContributionData(
          localSelectedAdType,
          selectedAdDF?.value,
          selectedBrandPartner?.value,
          event?.value,
          selectedPerformanceType?.value,
          selectedManagerUser,
          selectedUser,
          selectedTabMetrics,
          null,
          null,
          1,
        );
        setPageNumber(1);
      }
    }
  };

  // handle bgs manager/ad team managerfilter events
  const handleManagerUserFilterEvents = (event, action) => {
    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedManagerUser,
      managerUserApiList,
    );
    setSelectedManagerUser(selectedValues);
    setIsManagerUsersSelected(true);
  };

  // handle bgs manager/ad team manager on dropdwon menu close events
  const handleManagerUserOnMenuCloseEvents = () => {
    if (isManagerUsersSelected) {
      const managerUsers =
        selectedManagerUser?.length > 0 ? selectedManagerUser : managerUserList;
      setSelectedManagerUser(managerUsers);
      const isAllUser = managerUsers?.some((user) => user?.value === 'all');
      const users = allObj;

      if (isBGSAdmin || isSpreetailRole) {
        if (isAllUser) {
          getBgsList(
            'BGS',
            null,
            users,
            'managerUserFilter',
            selectedAdDF?.value,
          );
        } else {
          getBgsList(
            'BGS',
            managerUsers,
            users,
            'managerUserFilter',
            selectedAdDF?.value,
          );
        }
      }

      if (isAdManagerAdmin) {
        if (isAllUser) {
          getBgsList(
            'Ad Manager',
            null,
            users,
            'managerUserFilter',
            selectedAdDF?.value,
          );
        } else {
          getBgsList(
            'Ad Manager',
            managerUsers,
            users,
            'managerUserFilter',
            selectedAdDF?.value,
          );
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI(
        'managerUsersFilter',
        isAllUser ? allObj : managerUsers,
        users,
        null,
      );
    }
  };

  // handle bgs/ad manager filter events
  const handleUserFilterEvents = (event, action) => {
    const selectedValues = handleMultiSelectDropdownFilterEvents(
      event,
      action,
      selectedUser,
      userApiList,
    );

    setSelectedUser(selectedValues);
    setIsUsersSelected(true);
  };

  // handle bgs/ad manager on dropdwon menu close events
  const handleUserOnMenuCloseEvents = () => {
    if (isUsersSelected) {
      let managerUsers = selectedManagerUser;
      const users = selectedUser?.length > 0 ? selectedUser : userList;

      setSelectedUser(users);

      // When bgs manager and ad team manager filter set to all then Automatically set the bgs manager or ad team manager assigned to selected bgs or ad team manager
      if (
        (isBGSAdmin || isAdManagerAdmin || isSpreetailRole) &&
        selectedUser?.length === 1
      ) {
        const userExisted = userList?.find(
          (bgsUser) =>
            bgsUser?.value === selectedUser?.[0]?.value &&
            bgsUser?.managerUser !== null,
        );

        if (userExisted !== undefined) {
          const managerUserObject = {
            value: userExisted?.managerUser?.id,
            label: `${userExisted?.managerUser?.first_name} ${userExisted?.managerUser?.last_name}`,
          };
          managerUsers = [managerUserObject];
          setSelectedManagerUser([managerUserObject]);
          if (isBGSAdmin || isSpreetailRole) {
            getBgsList(
              'BGS',
              managerUsers,
              users,
              'bgsFilter',
              selectedAdDF?.value,
            );
          } else {
            getBgsList(
              'Ad Manager',
              managerUsers,
              users,
              'bgsFilter',
              selectedAdDF?.value,
            );
          }
        }
      }

      // calling leadership BP and dashboard performance api
      callingBPandDashboardAPI('usersFilter', managerUsers, users, null);
    }
  };

  // handle sponsored ad type filter onMenuclose events
  const handleAdTypeFilterOnMenuCloseEvents = () => {
    if (isAdTypeSelected) {
      const optionsWithoutAll = sponsoredAdTypeOptions?.filter(
        (option) => option?.value !== 'all',
      );

      const adTypeSelected =
        selectedAdType?.length > 0 ? selectedAdType : optionsWithoutAll;

      setSelectedAdType(adTypeSelected);

      if (selectedAdDF?.value === CUSTOM) {
        ADYearAndCustomDateFilter(
          adState[0]?.startDate,
          adState[0]?.endDate,
          CUSTOM,
          selectedMarketplace?.value,
          selectedPerformanceType?.value,
          adTypeSelected,
          selectedManagerUser,
          selectedUser,
          selectedBrandPartner?.value,
        );
      } else {
        getAdData(
          adTypeSelected,
          selectedAdDF?.value,
          adGroupBy,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedPerformanceType?.value,
          selectedManagerUser,
          selectedUser,
        );
        getContributionData(
          adTypeSelected,
          selectedAdDF?.value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedPerformanceType?.value,
          selectedManagerUser,
          selectedUser,
          selectedTabMetrics,
          null,
          null,
          1,
        );
        setPageNumber(1);
      }
    }
  };

  // download key contributors report
  const handleKeyContributorsDownloadReport = () => {
    setDownloadReportLoader(true);

    const userString = generateStringOfValues(selectedUser);
    const managerUserString = generateStringOfValues(selectedManagerUser);

    const startDate = dayjs(adState[0]).format('YYYY-MM-DD');
    const endDate = dayjs(adState[0]).format('YYYY-MM-DD');

    getKeyContributorsdownloadReport(
      isBGSAdmin,
      isBGSManager,
      isBGS,
      isAdManagerAdmin,
      isAdTeamManager,
      isAdManager,
      'sponsored_ad_dashboard',
      selectedAdDF?.value,
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedTabMetrics,
      managerUserString,
      userString,
      startDate,
      endDate,
      includeInactiveBPs,
      includeSpreetailBrands,
      selectedPerformanceType?.value,
    ).then((response) => {
      if (response?.status === 200) {
        const csvLink = response?.data?.url;
        if (csvLink) window.open(csvLink);
        if (response?.data?.message) toast.success(response?.data?.message);
        setDownloadReportLoader(false);
      } else {
        toast.error('Oops! Something went wrong...');
        setDownloadReportLoader(false);
      }
    });
  };

  // reset button events - reset all bgs and advertising user filter values to 'all'
  const handleResetFilter = () => {
    $('.checkboxes input:radio').filter("[value='all']").prop('checked', true);

    setSelectedPerformanceType(performanceTypeOptions?.[0]);
    setCurrencySymbol(getSymbolFromCurrency('USD'));
    setSelectedMarketplace(marketplaceChoices?.[0]);
    setSelectedAdType(sponsoredAdTypeOptions);
    setSelectedBrandPartner(allBrandPartnerObj);

    // set assigned bgs manager/ad team manager, bgs/ad manager of selected BP to multi-select bgs manager/ad team manager, bgs/ad manager dropdown filter.
    const managerUser = isBGSManager || isAdTeamManager ? loggedInUser : allObj;
    const localUser = isBGS || isAdManager ? loggedInUser : allObj;
    setSelectedUser(localUser);
    setSelectedManagerUser(managerUser);

    // calling Leadership-customer api
    getLeadershipBrandPartnersList(
      'resetFilter',
      managerUser,
      localUser,
      allBrandPartnerObj,
    );
    // calling bgs and advertising user api
    callingBgsAndAdUserApi(
      'resetFilter',
      managerUser,
      localUser,
      selectedAdDF?.value,
    );
    setCustomDateError(null);

    // send reset valeus to api
    if (selectedAdDF.value === CUSTOM) {
      ADYearAndCustomDateFilter(
        adState[0]?.startDate,
        adState[0]?.endDate,
        CUSTOM,
        'all',
        SELLERSMALL,
        allObj, // ad type
        managerUser,
        localUser,
        selectedBrandPartner?.value,
      );
    } else {
      getAdData(
        allObj,
        selectedAdDF?.value,
        adGroupBy,
        'all',
        'all',
        SELLERSMALL,
        managerUser,
        localUser,
      );
      getContributionData(
        allObj,
        selectedAdDF?.value,
        'all',
        'all',
        SELLERSMALL,
        managerUser,
        localUser,
        selectedTabMetrics,
        null,
        null,
        1,
      );
      setPageNumber(1);
    }
  };

  // handle sp multiselect metrics filter events
  const handledSponsoredMetricsOnMenuClose = () => {
    // return metrics which is selected in drop-down and in graph variable also.
    const localMetrics = sponsoredAdMultiSelected.filter((ele) =>
      Object.prototype.hasOwnProperty.call(selectedAdMetrics, ele?.value),
    );

    // we have acos and roas in same metrics (same for COS & SPC) &
    // sponsoredAdMultiSelected has only adcos and cos field
    // so, if user select the roas or SPC then its not ramain selected.
    // so,  need to write seperate logic for both metrics (roas, SPC)

    if (
      sponsoredAdMultiSelected.filter((ele) => ele?.value === ADCOS).length &&
      Object.prototype.hasOwnProperty.call(selectedAdMetrics, ADROAS)
    ) {
      localMetrics.push({ value: ADROAS, label: 'ROAS', isDisabled: false });
    }

    if (
      sponsoredAdMultiSelected.filter((ele) => ele?.value === COSTPERCLICK)
        .length &&
      Object.prototype.hasOwnProperty.call(selectedAdMetrics, SALESPERCLICK)
    ) {
      localMetrics.push({
        value: SALESPERCLICK,
        label: 'Sales Per Click',
        isDisabled: false,
      });
    }

    // if metrics is selected and its removed from metrics-dropdown then need to remove from selectedAdBox
    if (localMetrics?.length) {
      const localSelectedMetrics = {};
      localMetrics.map((ele) => {
        _.assign(localSelectedMetrics, { [ele?.value]: true });
        return null;
      });
      setSelectedAdMetrics(localSelectedMetrics);
    } else {
      setSelectedAdMetrics({ [sponsoredAdMultiSelected[0]?.value]: true });
    }
  };

  // handle sp timeframe filter events
  const handleAdDailyFact = (event) => {
    const { value } = event;

    if (value !== CUSTOM) {
      setCustomDateError(null);
      setSelectedAdDF(event);
      setIsCustomDateApply(false);
      setAdState([
        {
          startDate: currentDate,
          endDate: currentDate,
          key: 'adSelection',
        },
      ]);
      setAdGroupBy('daily');
      setCustomDate({
        startDate: null,
        endDate: null,
        dateType: null,
      });

      getAdData(
        selectedAdType,
        value,
        'daily',
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedPerformanceType?.value,
        selectedManagerUser,
        selectedUser,
      );
      getContributionData(
        selectedAdType,
        value,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedPerformanceType?.value,
        selectedManagerUser,
        selectedUser,
        selectedTabMetrics,
        null,
        null,
        pageNumber,
      );
    }
    if (value === CUSTOM) {
      setShowAdCustomDateModal(true);
    }
  };

  // handle sp daily-weekly-monthly filter events
  const handleAdGroupBy = (value) => {
    if (value !== adGroupBy) {
      setAdGroupBy(value);

      if (selectedAdDF?.value === CUSTOM) {
        const { startDate } = adState[0];
        const { endDate } = adState[0];
        let sd = startDate;
        let ed = endDate;
        sd = `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()}`;
        ed = `${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`;

        getAdData(
          selectedAdType,
          selectedAdDF?.value,
          value,
          selectedBrandPartner?.value,
          selectedMarketplace.value,
          selectedPerformanceType?.value,
          selectedManagerUser,
          selectedUser,
          sd,
          ed,
        );
      } else {
        getAdData(
          selectedAdType,
          selectedAdDF?.value,
          value,
          selectedBrandPartner?.value,
          selectedMarketplace?.value,
          selectedPerformanceType?.value,
          selectedManagerUser,
          selectedUser,
        );
      }
    }
  };

  // handle sp metrics tab change events
  const handleOnMetricsTabChange = (value) => {
    if (value !== selectedTabMetrics) {
      setSelectedTabMetrics(value);
      getContributionData(
        selectedAdType,
        selectedAdDF?.value,
        selectedBrandPartner?.value,
        selectedMarketplace?.value,
        selectedPerformanceType?.value,
        selectedManagerUser,
        selectedUser,
        value,
        null,
        null,
        pageNumber,
      );
    }
  };

  // handle sp pagination events
  const handlePageChange = (currentPage) => {
    setPageNumber(currentPage);
    getContributionData(
      selectedAdType,
      selectedAdDF?.value,
      selectedBrandPartner?.value,
      selectedMarketplace?.value,
      selectedPerformanceType?.value,
      selectedManagerUser,
      selectedUser,
      selectedTabMetrics,
      null,
      null,
      currentPage,
    );
  };

  // handle sp custom date selection events
  const applyCustomDate = () => {
    if (selectedAdDF?.value !== CUSTOM)
      setSelectedAdDF({
        value: CUSTOM,
        label: 'Custom Range',
        sub: 'Select start & end date',
      });
    setIsCustomDateApply(true);
    ADYearAndCustomDateFilter(
      adState[0]?.startDate,
      adState[0]?.endDate,
      CUSTOM,
      selectedMarketplace?.value,
      selectedPerformanceType?.value,
      selectedAdType,
      selectedManagerUser,
      selectedUser,
      selectedBrandPartner?.value,
    );
    setCustomDateError(null);
    setShowAdCustomDateModal(false);
  };

  // display sponsored filter section
  const displaySponsoredFilterSection = () => {
    return (
      <SponsoredFilter
        // boolean
        isApiCall={adGraphLoader || keyContributionLoader}
        isBGSAdmin={isBGSAdmin}
        isSpreetailRole={isSpreetailRole}
        isBGSManager={isBGSManager}
        isAdManagerAdmin={isAdManagerAdmin}
        isAdTeamManager={isAdTeamManager}
        // objects
        selectedAdType={selectedAdType}
        selectedMarketplace={selectedMarketplace}
        selectedPerformanceType={selectedPerformanceType}
        selectedBrandPartner={selectedBrandPartner}
        // array
        userList={userList}
        managerUserList={managerUserList}
        selectedUser={selectedUser}
        selectedManagerUser={selectedManagerUser}
        marketplaceOptions={marketplaceOptions}
        sponsoredAdTypeOptions={sponsoredAdTypeOptions}
        brandPartnerOptions={brandPartnerOptions}
        // function
        handleResetFilter={handleResetFilter}
        handleMarketplace={handleMarketplace}
        setSelectedAdType={setSelectedAdType}
        setSelectedUser={setSelectedUser}
        setSelectedManagerUser={setSelectedManagerUser}
        handleUserFilterEvents={handleUserFilterEvents}
        handleUserOnMenuCloseEvents={handleUserOnMenuCloseEvents}
        handleManagerUserFilterEvents={handleManagerUserFilterEvents}
        handleManagerUserOnMenuCloseEvents={handleManagerUserOnMenuCloseEvents}
        handleSellerVendorFilterEvents={handleSellerVendorFilterEvents}
        handleSponsoredAdTypeFilterEvents={handleSponsoredAdTypeFilterEvents}
        handleAdTypeFilterOnMenuCloseEvents={
          handleAdTypeFilterOnMenuCloseEvents
        }
        handledBrandPartnerFilterEvents={handledBrandPartnerFilterEvents}
      />
    );
  };

  // display sponsored dashboard RHS
  const displaySponsoredRightHandSideSection = () => {
    return (
      <SponsoredRightHandSideSection
        // bool
        isCustomDateApply={isCustomDateApply}
        adGraphLoader={adGraphLoader}
        downloadReportLoader={downloadReportLoader}
        keyContributionLoader={keyContributionLoader}
        // string
        adGroupBy={adGroupBy}
        currencySymbol={currencySymbol}
        selectedTabMetrics={selectedTabMetrics}
        // number
        pageNumber={pageNumber}
        contributionCount={contributionCount}
        // object
        selectedAdDF={selectedAdDF}
        selectedAdType={selectedAdType}
        selectedAdMetrics={selectedAdMetrics}
        customDateError={customDateError}
        selectedPerformanceType={selectedPerformanceType}
        // array
        adState={adState}
        adChartData={adChartData}
        adDifference={adDifference}
        adCurrentTotal={adCurrentTotal}
        adPreviousTotal={adPreviousTotal}
        contributionData={contributionData}
        sponsoredAdMultiSelected={sponsoredAdMultiSelected}
        // function
        handleAdGroupBy={handleAdGroupBy}
        handlePageChange={handlePageChange}
        handleAdDailyFact={handleAdDailyFact}
        setSelectedAdMetrics={setSelectedAdMetrics}
        handleOnMetricsTabChange={handleOnMetricsTabChange}
        displayContributionTable={displayContributionTable}
        setSponsoredAdMultiSelected={setSponsoredAdMultiSelected}
        renderRecentAndPreviousLabel={renderRecentAndPreviousLabel}
        handledSponsoredMetricsOnMenuClose={handledSponsoredMetricsOnMenuClose}
        handleKeyContributorsDownloadReport={
          handleKeyContributorsDownloadReport
        }
      />
    );
  };

  // display modals
  const displayModals = () => {
    return (
      <CustomDateModal
        id="BT-sponsoreddashboard-daterange"
        isOpen={showAdCustomDateModal}
        range={adState}
        onClick={() => {
          setShowAdCustomDateModal(false);
          if (selectedAdDF?.value !== CUSTOM) {
            setAdState([
              {
                startDate: currentDate,
                endDate: currentDate,
                key: 'adSelection',
              },
            ]);
          }
        }}
        onChange={(item) => {
          setAdState([item.adSelection]);
        }}
        onApply={() => applyCustomDate()}
        currentDate={currentDate}
        minDate={new Date('08/01/2022')}
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div className="row mt-3">
      <div className="col-xl-2 col-lg-3 col-md-12 col-12">
        {displaySponsoredFilterSection()}
      </div>
      <div className="col-xl-10 col-lg-9 col-md-12 col-12">
        {displayModals()}
        {displaySponsoredRightHandSideSection()}
      </div>
    </div>
  );
}
/* ********** Declaring prop types ********** */
SponsoredDashboardContainer.defaultProps =
  SponsoredDashboardContainerDefaultProps;

SponsoredDashboardContainer.propTypes = SponsoredDashboardContainerPropTypes;
