import styled from 'styled-components';
// import Theme from '../../../../theme/Theme';

const DashboardStyle = styled.div`
  .dashboard-header-filter {
    max-width: 540px;
  }

  @media only screen and (min-width: 768px) {
    .dashboard-header-filter {
      margin: 0 0 0 auto;
    }
  }
`;

export default DashboardStyle;
