// react imports
import React from 'react';

// third party imports
import Select from 'react-select';
import { arrayOf, bool, func, string, shape } from 'prop-types';

// local file imports
import Theme from '../theme/Theme';
import CustomDropDown from './CustomDropDown';
import DropDownIndicator from './DropDownIndicator';

const SingleSelectDropdown = ({
  // string
  filterId,
  className,
  // boolean
  isApiCall,
  isSearchable,
  // objects
  selectedValue,
  defaultValue,
  // array
  dropdownOptions,
  // function
  onChangeHandler,
  dropdownComponents,
  placeholder,
}) => {
  // get dropdwon indicator for  dropdown filter
  const getSelectComponents = () => {
    return {
      DropDownIndicator,
    };
  };

  // render custom dropdown filter components
  const renderSingleSelectDropdownFilter = () => {
    return (
      <CustomDropDown
        id={filterId}
        inputColor={Theme.black}
        className={isApiCall ? `cursor disabled` : 'cursor '}
      >
        <Select
          classNamePrefix="react-select"
          className={`single-select-dropdown active ${className || ''}`}
          options={dropdownOptions}
          value={selectedValue || null}
          defaultValue={defaultValue || dropdownOptions?.[0]}
          placeholder={placeholder || dropdownOptions?.[0]?.label}
          components={
            dropdownComponents !== null
              ? dropdownComponents()
              : getSelectComponents()
          }
          onChange={(event) => onChangeHandler(event)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#fff',
              primary25: Theme.lightOrange,
              primary: Theme.orange,
              primary50: Theme.lightOrange,
            },
          })}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            option: (provided) => ({
              ...provided,
              color: 'black',
            }),
          }}
          // menuPortalTarget={document.body}
          isLoading={isApiCall}
          isSearchable={isSearchable}
          isOptionDisabled={(option) => option?.isDisabled}
        />
      </CustomDropDown>
    );
  };

  /* ********** Main return statement of this component ********** */
  return <div>{renderSingleSelectDropdownFilter()}</div>;
};

export default SingleSelectDropdown;

SingleSelectDropdown.defaultProps = {
  // string
  className: '',
  placeholder: '',
  // boolean
  isApiCall: false,
  isSearchable: false,
  // object
  defaultValue: {},
  // function
  dropdownComponents: () => {},
};

SingleSelectDropdown.propTypes = {
  // string
  filterId: string.isRequired,
  placeholder: string,
  className: string,
  // boolean
  isApiCall: bool,
  isSearchable: bool,
  // object
  selectedValue: shape({}).isRequired,
  defaultValue: shape({}),
  // array
  dropdownOptions: arrayOf(shape({})).isRequired,
  // function
  onChangeHandler: func.isRequired,
  dropdownComponents: func,
};
