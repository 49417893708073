import queryString from 'query-string';

import axiosInstance from '../axios';

import {
  API_ACTIVITY_LOG,
  API_AMAZON_DETAILS,
  API_CONTACT,
  API_CREDENTIALS,
  API_CUSTOMER,
  API_CUSTOMER_CONTRACT,
  API_CUSTOMER_MEMBER,
  API_DELETE_MARKETPLACE,
  API_DOCUMENTS,
  API_ACCOUNT_MARKETPLACE,
  API_USER,
  API_SP,
  API_GET_SP_DATA,
  API_MONKEY_SURVEY_FEEDBACK,
  API_CHILD_BPS,
  API_CUSTOMER_CSV,
  API_AGREEMENT_APPROVERS_USER,
  columnsForVendorSalesPerformance,
  columnsForSalesPerformance,
} from '../constants';

export async function getCustomerList(
  pageNumber,
  sort,
  filterOptions,
  searchQuery,
  contractDetails,
  performance,
  adPerformance,
  dspAdPerformance,
  expiringSoon,
  dailyFacts,
  orderByFlag,
  selectedColumns,
  selectedVendorDashboard,
  isCsv,
) {
  let params = {
    page:
      pageNumber === '' || pageNumber === undefined
        ? 1
        : localStorage.getItem('page')
        ? localStorage.getItem('page')
        : pageNumber,
    'order-by': sort['order-by'] || '-created_at',
    bp_list_view:
      filterOptions.bpAccount === 'child'
        ? 'False'
        : sort.bp_list_view || 'True',
  };

  if (expiringSoon) {
    params = {
      ...params,
      expiring_soon: true,
    };
    delete params['order-by'];
  }

  if (searchQuery) {
    params = {
      ...params,
      q: searchQuery,
    };
  }
  if (contractDetails) {
    params = {
      ...params,
    };
  }
  let salesPerformanceDashboards = {};

  if (performance) {
    params = {
      ...params,
      ...dailyFacts,
      ...orderByFlag,
    };

    const list = [];
    selectedColumns.map((item) => list.push(item.value));

    const vendorColumnValues = [
      ...columnsForVendorSalesPerformance.ordered_revenue_tab,
      ...columnsForVendorSalesPerformance.shipped_cogs_tab,
    ].map((item) => item.value);

    const sellerColumnValues = columnsForSalesPerformance.map(
      (item) => item?.value,
    );

    const isAnyVendorDashboardFieldSelected = list.some((element) => {
      return vendorColumnValues.includes(element);
    });

    const isAnySellerDashboardFieldSelected = list.some((element) => {
      return sellerColumnValues.includes(element);
    });

    const dashboardList = [];

    if (isAnyVendorDashboardFieldSelected) {
      dashboardList.push(selectedVendorDashboard);
    }
    if (isAnySellerDashboardFieldSelected) {
      dashboardList.push('sale_performance');
    }

    if (selectedColumns?.length) {
      salesPerformanceDashboards = queryString.stringify({
        dashboard: dashboardList,
      });
    }
  }
  if (adPerformance) {
    params = {
      ...params,
      ...dailyFacts,
      ...orderByFlag,
      dashboard: 'sponsored_ad_dashboard',
      'order-by-account-type': sort['order-by-account-type'],
    };
  }
  if (dspAdPerformance) {
    params = {
      ...params,
      ...dailyFacts,
      ...orderByFlag,
      dashboard: 'dsp_ad_performance',
    };
  }

  let customiseColumns = {};
  const list = [];
  selectedColumns.map((item) => list.push(item.value));
  if (selectedColumns?.length) {
    customiseColumns = queryString.stringify({ field: list });
  }

  let accountTypeParams = {};
  if (filterOptions?.customer_account_type?.length) {
    accountTypeParams = queryString.stringify({
      customer_account_type: filterOptions.customer_account_type,
    });
  }
  let statusParams = {};
  if (filterOptions?.status?.length) {
    statusParams = queryString.stringify({
      status: filterOptions.status,
    });
  }
  let contractStatusParams = {};
  if (filterOptions?.contract_status?.length) {
    const index = filterOptions.contract_status.indexOf('signed');
    if (index !== -1) {
      filterOptions.contract_status[index] = 'active';
    }
    contractStatusParams = queryString.stringify({
      contract_status: filterOptions.contract_status,
    });
  }
  let addendumStatusParams = {};
  if (filterOptions?.addendum_status?.length) {
    const index = filterOptions?.addendum_status.indexOf('signed');
    if (index !== -1) {
      filterOptions.addendum_status[index] = 'active';
    }
    addendumStatusParams = queryString.stringify({
      addendum_status: filterOptions.addendum_status,
    });
  }

  let bgsParams = {};

  if (filterOptions?.cd_user?.length && contractDetails) {
    bgsParams = queryString.stringify({ user: filterOptions.cd_user });
    params = {
      ...params,
    };
  }

  if (filterOptions?.user?.length && performance) {
    bgsParams = queryString.stringify({ user: filterOptions.user });
    params = {
      ...params,
    };
  }

  if (filterOptions?.ad_user?.length && adPerformance) {
    bgsParams = queryString.stringify({ user: filterOptions.ad_user });
  }

  if (filterOptions?.dsp_user?.length && dspAdPerformance) {
    bgsParams = queryString.stringify({ user: filterOptions.dsp_user });
  }

  let contract = {};
  let dspService = {};
  let agreementType = '';
  if (filterOptions?.contract_type?.length) {
    const dspserveiceArray = [];
    let agreementTypes = [...filterOptions.contract_type];

    // pdv-8261- changed the logic of contract_type paramiter
    //     ------ TEST CASES FOR TWO NEW FILTERS ----
    // - 1 recurring
    // - 2 recurring (90)
    // - 5 recurring with dsp
    // - 6 recurring (90) with dsp

    //  ............. cases ..............
    //    1 + 5 or 1 	=> contract_type=recurring
    // 		5		=> contract_type=recurring & dsp_service=recurring

    // 		1 + 6		=> contract_type=recurring & contract_type=recurring (90 day notice) & dsp_service=recurring (90 day notice)
    // 		2 + 6 or 2	=> contract_type=recurring (90 day notice)
    // 		6		=> contract_type=recurring (90 day notice) & dsp_service=recurring (90 day notice)

    // 		2 + 5		=> contract_type=recurring (90 day notice) & contract_type=recurring & dsp_service=recurring

    // 		5 + 6		=> contract_type=recurring & dsp_service=recurring & contract_type=recurring (90 day notice) & dsp_service=recurring (90 day notice)

    if (
      filterOptions.contract_type.includes('recurring dsp') &&
      !filterOptions.contract_type.includes('recurring')
    ) {
      agreementTypes = agreementTypes.filter((ele) => ele !== 'recurring dsp');
      dspserveiceArray.push('recurring');
    } else if (
      filterOptions.contract_type.includes('recurring dsp') &&
      filterOptions.contract_type.includes('recurring')
    ) {
      agreementTypes = agreementTypes.filter((ele) => ele !== 'recurring dsp');
      dspserveiceArray.push('recurring');
    }
    if (
      filterOptions.contract_type.includes('recurring (90 day notice) dsp') &&
      !filterOptions.contract_type.includes('recurring (90 day notice)')
    ) {
      agreementTypes = agreementTypes.filter(
        (ele) => ele !== 'recurring (90 day notice) dsp',
      );
      dspserveiceArray.push('recurring (90 day notice)');
    } else if (
      filterOptions.contract_type.includes('recurring (90 day notice) dsp') &&
      filterOptions.contract_type.includes('recurring (90 day notice)')
    ) {
      agreementTypes = agreementTypes.filter(
        (ele) => ele !== 'recurring (90 day notice) dsp',
      );
      dspserveiceArray.push('recurring (90 day notice)');
    }

    contract = queryString.stringify({
      contract_type: agreementTypes,
    });

    dspService = queryString.stringify({
      dsp_service: dspserveiceArray,
    });
    agreementType = queryString.stringify({
      agreement_type: filterOptions?.agreement_type,
    });
  }

  let pipeline = {};
  if (filterOptions?.pipeline?.length) {
    pipeline = queryString.stringify({
      pipeline:
        filterOptions.pipeline === 'all_pipeline' ? [] : filterOptions.pipeline,
    });
  }

  let bpAccount = {};
  if (filterOptions?.bpAccount?.length) {
    bpAccount = queryString.stringify({
      account_type:
        filterOptions.bpAccount === 'all' ? [] : filterOptions.bpAccount,
    });
  }

  const result = await axiosInstance
    .get(
      `${!isCsv ? API_CUSTOMER : API_CUSTOMER_CSV}?${
        statusParams && statusParams.length ? statusParams : ''
      }${
        customiseColumns && customiseColumns?.length
          ? `&${customiseColumns}`
          : ''
      }${
        salesPerformanceDashboards?.length
          ? `&${salesPerformanceDashboards}`
          : ''
      }${bgsParams && bgsParams.length ? `&${bgsParams}` : ''}${
        contract && contract.length ? `&${contract}` : ''
      }${dspService && dspService.length ? `&${dspService}` : ''}${
        contract?.length || dspService?.length ? `&${agreementType}` : ''
      }${
        contractStatusParams && contractStatusParams.length
          ? `&${contractStatusParams}`
          : ''
      }${
        addendumStatusParams && addendumStatusParams.length
          ? `&${addendumStatusParams}`
          : ''
      }${
        accountTypeParams && accountTypeParams.length
          ? `&${accountTypeParams}`
          : ''
      }${pipeline && pipeline.length ? `&${pipeline}` : ''}${
        bpAccount && bpAccount.length ? `&${bpAccount}` : ''
      }`,
      { params },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateCustomer(data = null, id) {
  if (data && Object.keys(data).length > 0) {
    const result = await axiosInstance
      .post(`${API_CUSTOMER + id}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return result;
  }
  return null;
}

export async function getCustomerDetails(id) {
  const result = await axiosInstance
    .get(`${API_CUSTOMER + id}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDocuments(id, type, docType) {
  const result = await axiosInstance
    .get(
      `${API_DOCUMENTS}?entity_type=${type}&&entity_id=${id}&&document_type=${docType}`,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateCustomerDetails(id, data) {
  const result = await axiosInstance
    .patch(`${API_CUSTOMER + id}/`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getDocumentList() {
  const params = { document_type: 'profile_photo' };
  const result = await axiosInstance
    .get(API_DOCUMENTS, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getAccountDetails(id) {
  const params = { customer: id };
  const result = await axiosInstance
    .get(API_CUSTOMER_CONTRACT, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateAccountDetails(id, data) {
  const result = await axiosInstance
    .patch(`${API_CUSTOMER_CONTRACT + id}/?expand=~all`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getApprovalUserDetails() {
  const result = await axiosInstance
    .get(`${API_AGREEMENT_APPROVERS_USER}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCustomerMembers(id) {
  const result = await axiosInstance
    .get(`${API_CUSTOMER_MEMBER}?customer=${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getAllMembers(
  role,
  findMembers,
  pageNumber,
  searchQuery,
) {
  const page = pageNumber === '' || pageNumber === undefined ? 1 : pageNumber;
  const result = await axiosInstance
    .get(
      `${API_USER}?role=${role}&find-members=${findMembers}&page=${page}&q=${searchQuery}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function addCustomerMembers(data) {
  const result = await axiosInstance
    .post(`${API_CUSTOMER_MEMBER}bulk-create/`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// Remove delete, update, add Customer Meber API's after role enhancement
export async function deleteCustomerMember(id) {
  const result = await axiosInstance
    .delete(`${API_CUSTOMER_MEMBER + id}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateCustomerMember(data) {
  const result = await axiosInstance
    .post(`${API_CUSTOMER_MEMBER}bulk-update/`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function addCustomerMember(data, id) {
  const params = queryString.stringify({ customer_id: id, user_id: data });

  const result = await axiosInstance
    .post(`${API_CUSTOMER_MEMBER}bulk-create/?${params}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCustomerActivityLog(pageNumber, id) {
  const params = {
    page: pageNumber === '' || pageNumber === undefined ? 1 : pageNumber,
    model: 'customer',
    id,
  };
  if (id !== undefined) {
    const result = await axiosInstance
      .get(API_ACTIVITY_LOG, { params })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return result;
  }
  return null;
}

export async function createContactInfo(data) {
  const result = await axiosInstance
    .post(API_CONTACT, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateContactInfo(id, data) {
  const result = await axiosInstance
    .patch(`${API_CONTACT + id}/`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function deleteContactInfo(id) {
  const result = await axiosInstance
    .delete(`${API_CONTACT + id}/`)
    .then(() => {
      return {};
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCredentials(id) {
  const params = {
    customer: id,
  };
  const result = await axiosInstance
    .get(API_CREDENTIALS, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function createCredentials(data) {
  const result = await axiosInstance
    .post(API_CREDENTIALS, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateCredentials(id, data) {
  const result = await axiosInstance
    .patch(`${API_CREDENTIALS + id}/`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function deleteCredentials(id) {
  const result = await axiosInstance
    .delete(`${API_CREDENTIALS + id}/`)
    .then(() => {
      return {};
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function createAmazonDetails(data) {
  const result = await axiosInstance
    .post(API_AMAZON_DETAILS, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateAmazonDetails(id, data, customer) {
  const params = { customer };
  const result = await axiosInstance
    .patch(`${API_AMAZON_DETAILS + id}/`, data, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function deleteAmazonMarketplace(merchant, marketplace) {
  const params = { merchant_id: merchant, marketplace_id: marketplace };
  const result = await axiosInstance
    .post(API_DELETE_MARKETPLACE, '', { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCustomers(
  pageNumber,
  dashboard,
  dailyFacts,
  orderBy,
  sequence,
  startDate,
  endDate,
) {
  let params = {};
  const page =
    pageNumber === '' || pageNumber === undefined
      ? 1
      : localStorage.getItem('page')
      ? localStorage.getItem('page')
      : pageNumber;
  if (startDate && endDate) {
    params = {
      page,
      dashboard,
      daily_facts: dailyFacts,
      'order-by': orderBy,
      sequence,
      start_date: startDate,
      end_date: endDate,
    };
  } else {
    params = {
      page,
      dashboard,
      daily_facts: dailyFacts,
      'order-by': orderBy,
      sequence,
    };
  }
  const result = await axiosInstance
    .get(`${API_CUSTOMER}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getAccountMarketplace(id) {
  const result = await axiosInstance
    .get(API_ACCOUNT_MARKETPLACE.replace(':id', id))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getCustomerContactDetails(id) {
  const params = {
    customer: id,
  };
  const result = await axiosInstance
    .get(API_CONTACT, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function saveSPData(data) {
  const result = await axiosInstance
    .post(API_SP, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getSPData(customer) {
  const params = { customer };
  const result = await axiosInstance
    .get(API_GET_SP_DATA, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getMonkeySurveyData(customer) {
  const params = { customer };
  const result = await axiosInstance
    .get(API_MONKEY_SURVEY_FEEDBACK, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateMonkeySurveyData(id, data) {
  const result = await axiosInstance
    .patch(`${API_MONKEY_SURVEY_FEEDBACK + id}/`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function sendReminderOfCustomerFeedback(data) {
  const result = await axiosInstance
    .post(`${API_MONKEY_SURVEY_FEEDBACK}send_survey_reminder/`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function updateRecipientOfCustomerFeedback(data) {
  const result = await axiosInstance
    .post(`${API_MONKEY_SURVEY_FEEDBACK}update_recipients/`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getChildBps(customer, marketplaceId = null) {
  let params = { customer };

  if (marketplaceId !== null) {
    params = {
      ...params,
      marketplace: marketplaceId,
    };
  }
  const result = await axiosInstance
    .get(API_CHILD_BPS, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function createCustomer(data) {
  const result = await axiosInstance
    .post(API_CUSTOMER, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
