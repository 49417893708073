/* ********** Third party package imports ********** */
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  shape,
  string,
} from 'prop-types';

/* ********** Declaring prop types ********** */
export const SalesDashboardContainerDefaultProps = {
  // string
  responseID: null,
  currencySymbol: '',

  // boolean
  isBGS: false,
  isBGSAdmin: false,
  isDisabled: false,
  isAdManager: false,
  isBGSManager: false,
  isSpreetailRole: false,
  isAdTeamManager: false,
  isAdManagerAdmin: false,
  isCustomDateApply: false,
  includeInactiveBPs: false,
  includeSpreetailBrands: false,

  // object
  data: {},
  userInfo: {},
  customDate: {},
  selectedSalesDF: {},
  selectedMarketplace: {},
  selectedBrandPartner: {},
  selectedPerformanceType: {},

  // array
  loggedInUser: [],
  selectedUser: [],
  customDateState: [],
  marketplaceChoices: [],
  selectedManagerUser: [],

  // function
  setResponseID: () => {},
  setCustomDate: () => {},
  setSelectedUser: () => {},
  bindUserListData: () => {},
  checkIsUserExist: () => {},
  setCurrencySymbol: () => {},
  bindBrandPartners: () => {},
  renderAllocateBar: () => {},
  setSelectedSalesDF: () => {},
  setCustomDateState: () => {},
  setIsCustomDateApply: () => {},
  setSelectedManagerUser: () => {},
  setSelectedMarketplace: () => {},
  generateStringOfValues: () => {},
  setSelectedBrandPartner: () => {},
  displayContributionTable: () => {},
  setSelectedPerformanceType: () => {},
  bindMetricsContributionData: () => {},
  renderRecentAndPreviousLabel: () => {},
  handleMultiSelectDropdownFilterEvents: () => {},
};

export const SalesDashboardContainerPropTypes = {
  // string
  responseID: string,
  currencySymbol: string,

  // boolean
  isBGS: bool,
  isBGSAdmin: bool,
  isDisabled: bool,
  isAdManager: bool,
  isBGSManager: bool,
  isSpreetailRole: bool,
  isAdTeamManager: bool,
  isAdManagerAdmin: bool,
  isCustomDateApply: bool,
  includeInactiveBPs: bool,
  includeSpreetailBrands: bool,

  // object
  userInfo: shape({}),
  customDate: shape({}),
  selectedSalesDF: shape({}),
  selectedMarketplace: shape({}),
  selectedBrandPartner: shape({}),
  selectedPerformanceType: shape({}),
  data: shape({ sub: string, label: string }),

  // array
  loggedInUser: arrayOf(shape({})),
  selectedUser: arrayOf(shape({})),
  customDateState: arrayOf(shape({})),
  marketplaceChoices: arrayOf(shape({})),
  selectedManagerUser: arrayOf(shape({})),

  // function
  setResponseID: func,
  setCustomDate: func,
  setSelectedUser: func,
  bindUserListData: func,
  checkIsUserExist: func,
  setCurrencySymbol: func,
  bindBrandPartners: func,
  renderAllocateBar: func,
  setSelectedSalesDF: func,
  setCustomDateState: func,
  setIsCustomDateApply: func,
  setSelectedManagerUser: func,
  setSelectedMarketplace: func,
  generateStringOfValues: func,
  setSelectedBrandPartner: func,
  displayContributionTable: func,
  setSelectedPerformanceType: func,
  bindMetricsContributionData: func,
  renderRecentAndPreviousLabel: func,
  handleMultiSelectDropdownFilterEvents: func,
};

export const SalesPerformanceContainerDefaultProps = {
  // string
  salesGroupBy: '',
  currencySymbol: '',
  customStartDate: '',
  selectedTabMetrics: '',
  selectedRevenueType: '',
  selectedAdSpendType: '',
  // number
  pageNumber: 1,
  contributionCount: null,
  // boolean
  isBGSAdmin: false,
  isBGSManager: false,
  isAdTeamManager: false,
  isSpreetailRole: false,
  isAdManagerAdmin: false,
  salesGraphLoader: false,
  isCustomDateApply: false,
  contributionLoader: false,
  isDisabledTimeFrame: false,
  downloadReportLoader: false,
  showCustomMonthModal: false,
  showAdCustomDateModal: false,
  showAdYearOverYearDateModal: false,
  // object
  currentDate: {},
  customDateError: {},
  selectedSalesDF: {},
  salesDifference: {},
  isMetricsDisabled: {},
  salesCurrentTotal: {},
  salesPreviousTotal: {},
  selectedMarketplace: {},
  selectedAccountType: {},
  selectedBrandPartner: {},
  currentDateForVendor: {},
  selectedSalesMetrics: {},
  selectedVendorMetrics: {},
  vendorSalesDifference: {},
  vendorSalesCurrentTotal: {},
  vendorSalesPreviousTotal: {},
  selectedVendorMetricsType: {},
  selectedRevenueDropDownOption: {},
  selectedAdSpendDropDownOption: {},
  // array
  userList: [],
  selectedUser: [],
  salesChartData: [],
  managerUserList: [],
  customDateState: [],
  customMonthRange: [],
  contributionData: [],
  marketplaceOptions: [],
  selectedManagerUser: [],
  brandPartnerOptions: [],
  goalsPerformanceData: [],
  yearOverYearDateState: [],
  vendorSalesMetricsData: [],
  // function
  applyCustomDate: () => {},
  setSelectedUser: () => {},
  handlePageChange: () => {},
  addThousandComma: () => {},
  handleResetFilter: () => {},
  handleMarketplace: () => {},
  handleSalesGroupBy: () => {},
  setCustomDateState: () => {},
  handleSalesDailyFact: () => {},
  applyCustomMonthRange: () => {},
  setSelectedManagerUser: () => {},
  setIsDisabledTimeFrame: () => {},
  setSelectedAdSpendType: () => {},
  setSelectedRevenueType: () => {},
  setSelectedSalesMetrics: () => {},
  setSelectedVendorMetrics: () => {},
  handleOnMetricsTabChange: () => {},
  setShowCustomMonthModal: () => {},
  setShowAdCustomDateModal: () => {},
  setYearOverYearDateState: () => {},
  displayContributionTable: () => {},
  handleBgsUserFilterEvents: () => {},
  handleUserOnMenuCloseEvents: () => {},
  handleGoalsDateChangeEvents: () => {},
  renderRecentAndPreviousLabel: () => {},
  handleCustomMonthRangeEvents: () => {},
  handleManagerUserFilterEvents: () => {},
  handleSellerVendorFilterEvents: () => {},
  handleBrandPartnerFilterEvents: () => {},
  setShowAdYearOverYearDateModal: () => {},
  setSelectedRevenueDropDownOption: () => {},
  setSelectedAdSpendDropDownOption: () => {},
  handleManagerUserOnMenuCloseEvents: () => {},
  handleKeyContributorsDownloadReport: () => {},
  handleVendorMetricsTypeFilterEvents: () => {},
};

export const SalesPerformanceContainerPropTypes = {
  // string
  salesGroupBy: string,
  currencySymbol: string,
  customStartDate: string,
  selectedTabMetrics: string,
  selectedRevenueType: string,
  selectedAdSpendType: string,
  // number
  pageNumber: number,
  contributionCount: number,
  // boolean
  isBGSAdmin: bool,
  isBGSManager: bool,
  isAdTeamManager: bool,
  isSpreetailRole: bool,
  isAdManagerAdmin: bool,
  salesGraphLoader: bool,
  isCustomDateApply: bool,
  contributionLoader: bool,
  isDisabledTimeFrame: bool,
  downloadReportLoader: bool,
  showCustomMonthModal: bool,
  showAdCustomDateModal: bool,
  showAdYearOverYearDateModal: bool,
  // object
  currentDate: instanceOf(Date),
  customDateError: shape({}),
  selectedSalesDF: shape({}),
  salesDifference: shape({}),
  isMetricsDisabled: shape({}),
  salesCurrentTotal: shape({}),
  salesPreviousTotal: shape({}),
  selectedMarketplace: shape({}),
  selectedAccountType: shape({}),
  selectedBrandPartner: shape({}),
  currentDateForVendor: shape({}),
  selectedSalesMetrics: shape({}),
  selectedVendorMetrics: shape({}),
  vendorSalesDifference: shape({}),
  vendorSalesCurrentTotal: shape({}),
  vendorSalesPreviousTotal: shape({}),
  selectedVendorMetricsType: shape({}),
  selectedRevenueDropDownOption: shape({}),
  selectedAdSpendDropDownOption: shape({}),
  // array
  userList: arrayOf(shape({})),
  selectedUser: arrayOf(shape({})),
  salesChartData: arrayOf(shape({})),
  managerUserList: arrayOf(shape({})),
  customDateState: arrayOf(shape({})),
  customMonthRange: arrayOf(shape({})),
  contributionData: arrayOf(shape({})),
  marketplaceOptions: arrayOf(shape({})),
  selectedManagerUser: arrayOf(shape({})),
  brandPartnerOptions: arrayOf(shape({})),
  goalsPerformanceData: arrayOf(shape({})),
  yearOverYearDateState: arrayOf(shape({})),
  vendorSalesMetricsData: arrayOf(shape({})),
  // function
  applyCustomDate: func,
  setSelectedUser: func,
  handlePageChange: func,
  addThousandComma: func,
  handleResetFilter: func,
  handleMarketplace: func,
  handleSalesGroupBy: func,
  setCustomDateState: func,
  handleSalesDailyFact: func,
  applyCustomMonthRange: func,
  setSelectedManagerUser: func,
  setIsDisabledTimeFrame: func,
  setSelectedAdSpendType: func,
  setSelectedRevenueType: func,
  setSelectedSalesMetrics: func,
  setSelectedVendorMetrics: func,
  handleOnMetricsTabChange: func,
  setShowCustomMonthModal: func,
  setShowAdCustomDateModal: func,
  setYearOverYearDateState: func,
  displayContributionTable: func,
  handleBgsUserFilterEvents: func,
  handleUserOnMenuCloseEvents: func,
  handleGoalsDateChangeEvents: func,
  renderRecentAndPreviousLabel: func,
  handleCustomMonthRangeEvents: func,
  handleManagerUserFilterEvents: func,
  handleSellerVendorFilterEvents: func,
  handleBrandPartnerFilterEvents: func,
  setShowAdYearOverYearDateModal: func,
  setSelectedRevenueDropDownOption: func,
  setSelectedAdSpendDropDownOption: func,
  handleManagerUserOnMenuCloseEvents: func,
  handleKeyContributorsDownloadReport: func,
  handleVendorMetricsTypeFilterEvents: func,
};
