import React, { useRef, useState } from 'react';

import Dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

import BBEGoalHighLevelMetrics from './BBEGoalHighLevelMetrics';
import BBEGoalRevShareContribution from './BBEGoalRevShareContribution';
import { DashboardCard } from '../../theme/Global';
import {
  Button,
  CustomDateRange,
  DropDownSelectMonthPicker,
  GlobalNavbar,
  NoRecordFound,
} from '../../common';
import { CaretUp, CloseIcon } from '../../theme/images';
import { monthNames } from '../../constants';

const currentDate = new Date();
currentDate.setDate(1);
currentDate.setMonth(currentDate.getMonth() - 1);
export default function BBEGoalDashboardContainer() {
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState({ show: false });

  const [monthYear, setMonthYear] = useState(currentDate);
  const [finalMonthYear, setFinalMonthYear] = useState(currentDate);
  const [updatedDate, setUpdatedDate] = useState(null);

  const handleApply = () => {
    setFinalMonthYear(monthYear);
    setShowDropdown({ show: !showDropdown.show });
  };

  const onDateChange = (date) => {
    setMonthYear(date);
  };

  const renderTimeFilterLabel = () => {
    const currentMonthYearLabel = `${
      monthNames[finalMonthYear.getMonth()]
    } ${finalMonthYear.getFullYear()}`;

    return currentMonthYearLabel;
  };

  const displayTimeFilterOption = () => {
    return showDropdown.show ? (
      <div className="month-picker-dropdown" style={{ zIndex: 200 }}>
        <h4 className="mb-3 text-left">Select Date Range</h4>
        <img
          className="float-right cursor cross-icon"
          src={CloseIcon}
          alt="close"
          role="presentation"
          onClick={() => {
            setShowDropdown({ show: !showDropdown.show });
          }}
        />

        <CustomDateRange id="BT-dspinvoices-daterange">
          <div className="text-left">
            <DatePicker
              selected={finalMonthYear}
              onChange={(date, e) => onDateChange(date, e)}
              maxDate={currentDate}
              minDate={new Date('2021-01-01')}
              inline
              dateFormat="MM/yyyy"
              showMonthYearPicker
              previousMonthAriaLabel="asdasda"
            />
          </div>
        </CustomDateRange>

        <Button
          className="btn-primary w-100 mt-3"
          onClick={() => handleApply()}
        >
          Apply
        </Button>
      </div>
    ) : null;
  };

  const renderTimeFilterDropDown = () => {
    return (
      <DropDownSelectMonthPicker
        id="BT-finance-dash-monthpicker-dropdown"
        ref={dropdownRef}
      >
        <div
          className="dropdown-select-container"
          role="presentation"
          id="clickbox"
          onClick={() => {
            setShowDropdown({ show: !showDropdown.show });
          }}
        >
          {renderTimeFilterLabel()}
          <img
            src={CaretUp}
            alt="caret"
            className="dropdown-icon"
            style={{
              transform: showDropdown.show ? 'rotate(180deg)' : '',
            }}
          />
        </div>
        <div>{displayTimeFilterOption()}</div>
      </DropDownSelectMonthPicker>
    );
  };

  return (
    <>
      <GlobalNavbar />
      <DashboardCard>
        {/* PDV-8739 - [Ad Hoc from Liz] HOME TAB - Temporarily hide the data */}
        <div className="dashboard-container-body d-none">
          <div className="row">
            <div className="col-md-6 col-6">
              <h5 style={{ fontWeight: '500' }} className="sub-title-text">
                Business Performance Snapshot
              </h5>
              {updatedDate && (
                <div className="sub-heading ">Last Updated {updatedDate}</div>
              )}
            </div>
            <div className="col-md-6 col-6 text-right">
              {renderTimeFilterDropDown()}
            </div>
          </div>

          <BBEGoalHighLevelMetrics
            onSendDate={(date) =>
              setUpdatedDate(Dayjs(date).format('DD MMM, YYYY'))
            }
            selectedMonthYear={finalMonthYear}
          />
          <BBEGoalRevShareContribution monthYear={finalMonthYear} />
        </div>
        {/* PDV-8739 - [Ad Hoc from Liz] HOME TAB - Temporarily hide the data */}
        <NoRecordFound
          type="brand"
          subTitle="This page is currently being updated. Please stay tuned."
        />
      </DashboardCard>
    </>
  );
}
