import dayjs from 'dayjs';

import axiosInstance from '../axios';
import {
  API_PERFORMANCE,
  API_STRANDED_INVENTORY,
  API_SUMMARY_DASHBOARD,
} from '../constants';

export async function getSummaryPerformanceData(
  customer,
  dailyFacts,
  groupBy,
  marketplace,
  startDate,
  endDate,
) {
  let params = {};
  if (startDate && endDate) {
    params = {
      daily_facts: dailyFacts,
      group_by: groupBy,
      marketplace,
      customer_id: customer,
      start_date: startDate,
      end_date: endDate,
    };
  } else {
    params = {
      daily_facts: dailyFacts,
      group_by: groupBy,
      marketplace,
      customer_id: customer,
    };
  }
  const result = await axiosInstance
    .get(`${API_SUMMARY_DASHBOARD}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function getSummarySellerPerformance(customer, marketplace) {
  let params = {};
  params = {
    marketplace,
    dashboard: 'summary',
  };
  const result = await axiosInstance.get(`${API_PERFORMANCE + customer}/`, {
    params,
  });

  return result;
}

export async function getSummaryInvoice(customer) {
  let params = {};
  params = {
    customer,
  };
  const result = await axiosInstance.get(`/invoice${API_SUMMARY_DASHBOARD}`, {
    params,
  });

  return result;
}

export async function getSummaryASIN(
  customer,
  marketplace,
  dailyFacts,
  selectedDate,
) {
  let params = {};
  params = {
    customer,
    marketplace,
    dashboard: 'summary',
    daily_facts: dailyFacts,
  };

  if (dailyFacts === 'custom') {
    params = {
      ...params,
      timeframe: dayjs(selectedDate).format('YYYY-MM-DD'),
    };
  }

  const result = await axiosInstance.get(`${API_STRANDED_INVENTORY}`, {
    params,
  });

  return result;
}
