import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Tabs from './Tabs';
import { GlobalMenu } from './Styles/HeaderStyles';
import {
  roleURLs,
  dashboardRole,
  dashboardRolePaths,
  GlobalMenus,
  PATH_DASHBOARD,
} from '../constants';

function GlobalNavbar() {
  const userInfo = useSelector((state) => state.userState.userInfo);

  const isHavePendingApprovalAccess =
    (userInfo?.is_primary_request_approvers ||
      userInfo?.is_final_request_approvers) &&
    userInfo?.role !== 'Customer';

  const userRole = userInfo?.role;
  const history = useHistory();

  const displayGlobalMenu = () => {
    return (
      <GlobalMenu>
        {/* <h3>Globalnav</h3> */}
        <Tabs>
          <ul className="tabs scrollable-container ">
            {GlobalMenus.map((menu) => {
              return menu.label === 'Dashboard' ? (
                dashboardRole.includes(userRole) ||
                isHavePendingApprovalAccess ? (
                  <li
                    className={
                      roleURLs.some((element) =>
                        history.location.pathname?.includes(element),
                      )
                        ? ' cursor active scrollable-tab  global-tab'
                        : ' cursor scrollable-tab'
                    }
                    key={menu.label}
                    role="presentation"
                    onClick={() => {
                      if (dashboardRolePaths[userRole]) {
                        history.push(dashboardRolePaths[userRole]);
                      } else if (isHavePendingApprovalAccess) {
                        history.push(PATH_DASHBOARD);
                      }
                    }}
                  >
                    Dashboard
                  </li>
                ) : null
              ) : (
                <li
                  className={
                    history.location.pathname?.includes(menu.pathname)
                      ? ' cursor active scrollable-tab global-tab'
                      : ' cursor scrollable-tab'
                  }
                  key={menu.label}
                  role="presentation"
                  onClick={() => history.push(menu.pathToRedirect)}
                >
                  {menu.label}
                </li>
              );
            })}
          </ul>
        </Tabs>
      </GlobalMenu>
    );
  };
  return <div>{displayGlobalMenu()}</div>;
}

export default GlobalNavbar;
